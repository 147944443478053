import { mm } from './base-clients';
import { ITag, ISharedIndex, ISharedIndexUsages, IUnit, IRegulation, ISharedIndexOrigin } from 'mm-types';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

export type DefaultSharedIndex = {
  origin: boolean;
  isNew: boolean;
  sharedIndex: Partial<ISharedIndex> | null;
};

export type SharedIndexMap = {
  count: number | undefined;
  className: string;
};

export async function getTags() {
  const response = await mm.get<{ tags: ITag[] }>(`/tags`);
  return response.data.tags;
}

export async function createIndexUsage(token: Partial<ISharedIndexUsages>, options: { beforeUnitUid?: string; afterUnitUid?: string }) {
  const query: string[] = [];
  if (options.beforeUnitUid) {
    query.push('beforeUnitUid=' + options.beforeUnitUid);
  } else if (options.afterUnitUid) {
    query.push('afterUnitUid=' + options.afterUnitUid);
  }

  const response = await mm.post<ISharedIndexUsages>(`/sharedIndexUsages?` + query.join('&'), token);
  return response.data;
}

export function deleteIndexUsage(indexUid: string, unitUid: string) {
  return mm.delete(`/sharedIndexUsages/${indexUid}/${unitUid}`);
}

export async function updateIndexUsage(indexUid: string, unitUid: string, token: Partial<ISharedIndexUsages>) {
  const response = await mm.post<ISharedIndexUsages>(`/sharedIndexUsages/${indexUid}/${unitUid}`, token);
  return response.data;
}

export async function getIndexUsage(indexUid: string, unitUid: string, isDiff: boolean) {
  const response = await mm.get<ISharedIndexUsages>(`/sharedIndexUsages/${indexUid}/${unitUid}?diff=` + isDiff);
  return response.data;
}
export async function getSharedIndexUsageForTocable(tocUid: string, indexUid: string) {
  const response = await mm.get<{ sharedIndexUsages: ISharedIndexUsages[] }>(`/sharedIndexUsages/${indexUid}/tocable/${tocUid}`);
  if (response.data.sharedIndexUsages.length > 0) {
    return response.data.sharedIndexUsages;
  }
  return false;
}
export async function getIndexUsages(options?: { sharedIndexUid?: string; indexUid?: string }) {
  const query: string[] = [];

  if (options && options.sharedIndexUid) {
    query.push('sharedIndexUid=' + options.sharedIndexUid);
  }
  if (options && options.indexUid) {
    query.push('indexUid=' + options.indexUid);
  }

  const response = await mm.get<{ sharedIndexUsages: ISharedIndexUsages[] }>(`/sharedIndexUsages?` + query.join('&'));
  const usageMap: { [name: string]: SharedIndexMap } = {};
  parseIndexUsages(response.data.sharedIndexUsages, usageMap);

  const usages = response.data.sharedIndexUsages.sort((a, b) => {
    if (a.projectName < b.projectName) {
      return -1;
    }
    if (a.projectName > b.projectName) {
      return 1;
    }

    return 0;
  });

  return { usageMap, usages };
}

function parseIndexUsages(usages: ISharedIndexUsages[], usageMap: { [name: string]: SharedIndexMap }) {
  usages.forEach((share) => {
    share.uid = share.sharedIndex.uid + '_' + share.units[0].uid;
    share.origin = false;

    // flatten for sort algo
    share.name = share.sharedIndex.name!;
    share.projectName = share.sharedIndex.originProjectName!;

    if ((share.updateAvailable || share.updateDeferred) && (share.updateStrategy === 'MANUAL' || share.updateStrategy === 'NONE')) {
      _addToUsageTocMap(
        share.units[0],
        share.updateAvailable && !share.updateDeferred ? 'share-usage-updated' : share.updateDeferred ? 'share-usage-deferred' : '',
        usageMap
      );
    }
  });

  return usages;
}

function _addToUsageTocMap(unit: IUnit, className: string, usageMap: { [name: string]: SharedIndexMap }) {
  if (ProjectDefinitionStore.isStructuralType(unit.level || unit.type)) {
    _addTocToMap(unit.uid, className, usageMap);
  }
  if (unit.sectionUid && unit.uid !== unit.sectionUid) {
    // i.e. ad a seciton but not itself again!
    _addTocToMap(unit.sectionUid, className, usageMap);
  }
  if (unit.chapterUid && unit.uid !== unit.chapterUid) {
    _addTocToMap(unit.chapterUid, className, usageMap);
  }
  if (unit.volumeUid && unit.uid !== unit.volumeUid) {
    _addTocToMap(unit.volumeUid, className, usageMap);
  }
}

function _addTocToMap(tocUid: string, className: string, usageMap: { [name: string]: SharedIndexMap }) {
  if (usageMap[tocUid]) {
    usageMap[tocUid] = { count: usageMap[tocUid].count! + 1, className: className };
  } else {
    usageMap[tocUid] = { count: 1, className: className };
  }
}

export async function createIndex(token: Partial<ISharedIndex>) {
  const response = await mm.post<ISharedIndex>(`/sharedIndexes`, token);
  return response.data;
}

export async function getSharedIndices(options?: {
  page: number;
  size: number;
  contentTags?: string[];
  sort?: string[];
  projectDefinitionName?: string;
}) {
  const query: string[] = [];

  if (options && options.contentTags) {
    for (const tag of options.contentTags) {
      query.push('contentTag=' + tag);
    }
  }
  if (options && options.page) {
    query.push('page=' + options.page);
  }
  if (options && options.size) {
    query.push('size=' + options.size);
  }
  if (options && options.sort) {
    query.push('sort=' + options.sort.join(','));
  }
  if (options && options.projectDefinitionName) {
    query.push('projectDefinition=' + options.projectDefinitionName);
  }

  const response = await mm.get<{ sharedIndexes: ISharedIndex[] }>(`/sharedIndexes?` + query.join('&'));
  return response.data.sharedIndexes;
}

export async function getSharedIndex(id: string) {
  const response = await mm.get<ISharedIndex>(`/sharedIndexes/${id}`);
  return response.data;
}

export function removeIndex(id: string) {
  return mm.delete(`/sharedIndexes/${id}`);
}

export async function updateIndex(id: string, token: Partial<ISharedIndex>) {
  await mm.post(`/sharedIndexes/${id}`, token);
}

export function settingDefaults() {
  const toRet: DefaultSharedIndex = {
    origin: true,
    isNew: true,
    sharedIndex: {
      name: '',
      description: '',
      displayType: '',
      startUnitUid: '',
      endUnitUid: '',
      unitCount: 0,
      usageCount: 0,
      tags: [],
      originProjectUid: '',
      originProjectName: '',
      allowDerivatives: false
    }
  };

  return toRet;
}

export function regulationDefaults(): Partial<IRegulation> {
  return {
    isNew: true,
    unitUid: '',
    projectUid: '',
    name: '',
    type: '',
    subType: '',
    reference: '',
    subReference: ''
  };
}

export async function getRegulation(id: string) {
  const response = await mm.get<IRegulation>(`/regulations/${id}`);
  return response.data;
}

export async function updateRegulation(id: string, token: Partial<IRegulation>) {
  const response = await mm.put<IRegulation>(`/regulations/${id}`, token);
  return response.data;
}

export function removeRegulation(id: string) {
  return mm.delete(`/regulations/${id}`);
}

export async function createRegulation(token: Partial<IRegulation>) {
  const response = await mm.post<IRegulation>(`/regulations`, token);
  return response.data;
}

export async function getRegulations(indexUid: string) {
  const response = await mm.get<{ regulations: IRegulation[] }>(`/regulations/indexes/` + indexUid);
  const regs = response.data.regulations;

  // dont have original info on a simple regulation so just mark with same for now
  regs.forEach((reg) => {
    reg.original = {
      type: reg.type,
      subType: reg.subType,
      reference: reg.reference,
      subReference: reg.subReference
    };
  });

  return regs;
}

export async function deleteComplianceTag(
  indexUid: string,
  regUid: string,
  options: { unTagContiguous?: boolean; allowMultiple?: boolean; unitUids?: string[] }
) {
  const query: string[] = [];

  if (options.unTagContiguous) {
    query.push(`unTagContiguous=${options.unTagContiguous}`);
  }
  if (options.allowMultiple) {
    query.push(`allowMultiple=${options.allowMultiple}`);
  }
  if (options.unitUids) {
    query.push(`unitUid=${options.unitUids.join(',')}`);
  }

  await mm.delete(`/complianceTags/indexes/${indexUid}/regulations/${regUid}?${query.join('&')}`);
}

export async function getRegulationForUnit(indexUid: string, unitUid: string) {
  const response = await mm.get<IRegulation>(`/regulations/indexes/${indexUid}/units/${unitUid}`);
  return response.data;
}

export async function getOrigin(id: string) {
  const response = await mm.get<ISharedIndexOrigin>(`/sharedIndexOrigins/${id}`);
  return response.data;
}

export async function updateOrigin(sharedIndexUid: string, token: Partial<ISharedIndexOrigin>) {
  const response = await mm.post<ISharedIndexOrigin>(`/sharedIndexOrigins/${sharedIndexUid}`, token);
  return response.data;
}

export async function revertOrigin(sharedIndexUid: string) {
  const response = await mm.post<{ units: IUnit[] }>(`/sharedIndexOrigins/${sharedIndexUid}/revert`, {});
  return response.data;
}

export async function getOrigins(projectUid: string) {
  const response = await mm.get<{ sharedIndexOrigins: ISharedIndexOrigin[] }>(`/sharedIndexOrigins?projectUid=` + projectUid);
  let shares = response.data.sharedIndexOrigins || [];

  shares.forEach((share) => {
    share.uid = share.sharedIndex.uid!;
    share.origin = true;

    // flatten for sort algo
    share.name = share.sharedIndex.name!;
    share.projectName = share.sharedIndex.originProjectName!;
  });

  shares = shares.sort((a, b) => {
    if (a.projectName < b.projectName) {
      return -1;
    }
    if (a.projectName > b.projectName) {
      return 1;
    }

    return 0;
  });

  return shares;
}

export async function getOriginUnits(sharedIndexUid: string, isDiff: boolean) {
  const response = await mm.get<{ units: IUnit[] }>(`/sharedIndexOrigins/${sharedIndexUid}/units?diff=` + isDiff);
  return response.data.units;
}
