import * as React from 'react';
import RadioButton, { RadioButtonGroup } from 'material-ui/RadioButton';
import DialogSection from '../../dialogs/DialogSection';
import CircularProgress from 'material-ui/CircularProgress';
import { SelectedPdfRange } from './exportDialogTypes';

interface Props {
  canExportPdf: boolean;
  isFetching: boolean;
  showRevisionHighlights: boolean;
  onSelectedPdfRange(range: SelectedPdfRange);
}

// workaround for https://github.com/mui-org/material-ui/issues/6344
const BASE_BUTTONS = [
  {
    id: 'range-selector-current-selection',
    key: 'range-selector-current-selection',
    label: 'Current Selection',
    value: SelectedPdfRange.SELECTION
  },
  {
    id: 'range-selector-entire-document',
    key: 'range-selector-entire-document',
    label: 'Entire Document',
    value: SelectedPdfRange.ENTIRE
  }
];

const BUTTONS_WITH_HIGHLIGHTS = [
  ...BASE_BUTTONS,
  {
    id: 'range-selector-revision-highlights',
    key: 'range-selector-revision-highlights',
    label: 'Revision Highlights',
    value: SelectedPdfRange.REVISION_HIGHLIGHTS
  }
];

export function RangeSection({ canExportPdf, onSelectedPdfRange, showRevisionHighlights, isFetching }: Props) {
  const buttons = showRevisionHighlights ? BUTTONS_WITH_HIGHLIGHTS : BASE_BUTTONS;

  return (
    <DialogSection label="Select Range" id="range">
      <div className="export-row">
        {isFetching && (
          <div style={{ margin: '0 auto' }}>
            <CircularProgress size={24} />
          </div>
        )}
        {!isFetching && (
          <RadioButtonGroup
            className="range-group"
            onChange={(e, value) => {
              onSelectedPdfRange(value as SelectedPdfRange);
            }}
            name="exportRange"
          >
            {buttons.map((buttonValues) => (
              <RadioButton
                id={buttonValues.id}
                disabled={!canExportPdf}
                key={buttonValues.key}
                value={buttonValues.value}
                label={buttonValues.label}
                style={{ width: '33%', display: 'flex' }}
                className="range-item"
              />
            ))}
          </RadioButtonGroup>
        )}
      </div>
    </DialogSection>
  );
}
