type pageTypes = {
  text: string;
  payload: string;
};

type pageSizes = {
  text: string;
  payload: string;
};

type pageBreakOptions = {
  text: string;
  payload: string;
};

export const PAGE_TYPES: pageTypes[] = [
  { text: 'Unspecified', payload: 'unspecified' },
  { text: 'Portrait', payload: 'portrait' },
  { text: 'Landscape', payload: 'landscape' }
];

export const PAGE_SIZES: pageSizes[] = [
  { text: 'Unspecified', payload: 'unspecified' },
  { text: 'A4', payload: 'a4' },
  { text: 'A3', payload: 'a3' }
];

export const PAGE_BREAK_OPTIONS: pageBreakOptions[] = [
  { text: 'Next Page', payload: 'always' },
  { text: 'Left Page', payload: 'left' },
  { text: 'Right Page', payload: 'right' },
  { text: 'Avoid', payload: 'avoid' }
];

export const DEFAULT_SETTINGS = {
  pageType: 'unspecified',
  pageSize: 'unspecified',
  breakAfter: '',
  breakBefore: '',
  avoidBreakInside: '',
  colCount: '',
  colGap: '2em'
};
