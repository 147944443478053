import * as React from 'react';
import { NotificationTemplate, INotification } from 'mm-types';
import NotificationTemplates from '../NotificationTemplates';
import { LinearProgress, IconButton } from 'material-ui';

function _getHeaderMsg(notif: INotification, name: string): string {
  if (notif.processingStatus) {
    return `Exporting ${name}`;
  }
  if (notif.isError) {
    return `${name} Export (error)`;
  }
  if (notif.media?.stale) {
    return `${name} Export (expired)`;
  }
  return `${name} Export`;
}

function _getRevisionMsg(notif: INotification): string {
  return notif.index && notif.index.revisionNumber ? 'for revision ' + notif.index.revisionNumber : '';
}

function _getFriendlyErrorMsg(notif: INotification): string {
  return notif.error && notif.error.friendlyMessage
    ? notif.error.friendlyMessage
    : 'An error occurred' + (notif.retryable ? ', retry?' : '.');
}

function _getMsgs(notif: INotification, name: string) {
  const revision = _getRevisionMsg(notif);
  return {
    header: _getHeaderMsg(notif, name),
    description: NotificationTemplates.generateTitle(notif),
    variantName: NotificationTemplates.getVariantName(notif),
    isReady: `${name} ${revision} ready for download`,
    isError: notif.isError ? _getFriendlyErrorMsg(notif) : '',
    isStale: `${name} export ${revision} expired, retry?`
  };
}

export function createExportTemplate(
  name: 'PDF' | 'Document' | 'Feedback' | 'Read Confirms' | 'Workflow Tasks' | 'Quiz' | 'Publication History' | 'Workflow Actions'
): NotificationTemplate {
  const iconBase = name === 'PDF' ? 'icon-pdf-export-' : name !== 'Document' ? 'icon-export-csv' : 'icon-document-export-';
  return {
    generateDescription(notif) {
      const { isReady, isError, processingStatus } = notif;
      const isStale = notif.media?.stale;
      const msg = _getMsgs(notif, name);

      return (
        <div className="middle-side">
          <div className="popup-title-container">
            <h1>{msg.header}</h1>
            {msg.description}
            {msg.variantName}
          </div>

          {!processingStatus && (
            <div className="notif-item notif-item-done arc-media-stale notif-item-stale">
              {isReady && !isError && !isStale && <span className="info success">{msg.isReady}</span>}
              {isStale && !isError && <span className="info">{msg.isStale}</span>}
              {isError && (
                <span className="info error">
                  <div dangerouslySetInnerHTML={{ __html: msg.isError }}></div>
                </span>
              )}
            </div>
          )}
          {processingStatus && (
            <div className="notif-item notif-item-inprogress">
              <span className="info">{processingStatus.friendlyName}</span>
            </div>
          )}
        </div>
      );
    },
    generateRightSide(notif, callback) {
      const { isReady, isError, retryable, media, receivedFormatAgo, processingStatus, status } = notif;
      const isStale = media?.stale;

      return (
        <div className="right-side">
          <div className="popup-date-container">
            <h5>{receivedFormatAgo}</h5>
          </div>
          {!processingStatus && (
            <div className="notif-item notif-item-done">
              <span
                onClick={() => {
                  callback(notif, 'toggleReadUnread');
                }}
                className="notif-status-toggle"
                title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
                data-qa={notif.status}
              />

              {((isError && retryable) || isStale) && (
                <IconButton
                  onClick={() => {
                    callback(notif, 'retry');
                  }}
                  className="item-action"
                  iconClassName="material-icons"
                  tooltipPosition="bottom-center"
                  tooltipStyles={{ zIndex: 9999, transform: 'translateX(-8px)' }}
                  tooltip={isError ? 'Retry' : 'Refresh'}
                  data-qa={isError ? 'Retry' : 'Refresh'}
                  data-mediauid={notif.media?.uid}
                >
                  refresh
                </IconButton>
              )}
              {isReady && !isError && !isStale && (
                <IconButton
                  onClick={() => {
                    callback(notif, 'download', true);
                  }}
                  className="item-action"
                  iconClassName="material-icons"
                  tooltipPosition="bottom-center"
                  tooltipStyles={{ zIndex: 9999, transform: 'translateX(-8px)' }}
                  tooltip="Download"
                  data-qa="Download"
                  data-mediauid={notif.media?.uid}
                >
                  file_download
                </IconButton>
              )}
            </div>
          )}

          {processingStatus && (
            <div className="notif-item notif-item-inprogress">
              {status === 'IN_PROGRESS' && <LinearProgress data-qa="progress" mode="indeterminate" />}
            </div>
          )}
        </div>
      );
    },
    getNotifIcon(notif) {
      let suffix = ' material-icons';
      if (name === 'PDF' || name === 'Document') {
        suffix = notif.isError ? 'error' : notif.media?.stale ? 'stale' : notif.isReady ? 'success' : 'progress';
      }

      return (
        <div className="left-side">
          <span className={`icon ${iconBase}${suffix}`} />
        </div>
      );
    }
  };
}

export const PROJECT_EXPORT: NotificationTemplate = createExportTemplate('Document');
