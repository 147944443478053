type BrowserName = 'chrome' | 'ie' | 'safari' | 'firefox' | 'opera' | 'edge';
interface BrowserInfo {
  name: BrowserName;
  version: string;
}

class AgentUtil {
  constructor(private navigator: Navigator) {}

  clientBrowserInfo(): BrowserInfo {
    const ua = this.navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'ie', version: tem[1] || null };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem !== null) {
        return { name: tem[1].replace('OPR', 'opera').toLowerCase() as BrowserName, version: tem[2] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [this.navigator.appName, this.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
      M.splice(1, 1, tem[1]);
    }
    return { name: M[0].toLowerCase() as BrowserName, version: M[1] };
  }

  isIE(): boolean {
    return this.clientBrowserInfo().name === 'ie';
  }

  isEditorSupported() {
    const { name, version } = this.clientBrowserInfo();
    return !(name === 'ie' || name === 'edge' || (name === 'chrome' && parseInt(version, 10) < 45));
  }
}

export default new AgentUtil(window.navigator);
