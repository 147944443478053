export type ShareFilterTypes = 'TYPE_SHARED' | 'TYPE_REGULATIONS';
export type ShareFilterOtherTypes = 'TYPE_DEFAULT' | 'TYPE_PARA' | 'TYPE_MIXED' | 'TYPE_LIST' | 'TYPE_WARNING';
export type AllShareFilterTypes = ShareFilterTypes & ShareFilterOtherTypes;

export type Property = {
  icon: string;
  key: string;
  title: string;
  emptyDescription: string;
};

const properties = {
  TYPE_SHARED: {
    icon: 'filter_list',
    key: 'shared',
    title: 'Shared Content',
    emptyDescription: 'The Shared Content Library is empty.'
  } as Property,
  TYPE_REGULATIONS: {
    icon: 'verified_user',
    key: 'regulation',
    title: 'Regulations',
    emptyDescription: 'This is Regulation smart content in the library.'
  } as Property,
  TYPE_PARA: {
    icon: 'receipt',
    key: 'paragraph',
    title: 'Paragraph',
    emptyDescription: 'This is Paragraph smart content in the library.'
  } as Property,
  TYPE_MIXED: {
    icon: 'reorder',
    key: 'mixed',
    title: 'Multiple',
    emptyDescription: 'This is Mixed smart content in the library.'
  } as Property,
  TYPE_LIST: {
    icon: 'list',
    key: 'list',
    title: 'List',
    emptyDescription: 'This is List smart content in the library.'
  } as Property,
  TYPE_WARNING: {
    icon: 'warning',
    key: 'warning',
    title: 'Warning',
    emptyDescription: 'This is Warning smart content in the library.'
  } as Property,
  TYPE_DEFAULT: {
    icon: 'reorder',
    key: 'default',
    title: 'Unrecognized',
    emptyDescription: 'This is Unrecognized smart content in the library.'
  } as Property
};

const keyLookup: { [name: string]: Property } = {};
for (const key in properties) {
  keyLookup[properties[key].key] = properties[key];
}

const filter: Property[] = [];
for (const key of ['TYPE_SHARED', 'TYPE_REGULATIONS']) {
  filter.push(properties[key]);
}

const findByKey = function (key) {
  return keyLookup[key] ? keyLookup[key] : keyLookup['default'];
};

const views = {
  props: properties,
  filter: filter,
  findByKey: findByKey
};

export default views;
