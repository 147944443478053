import * as React from 'react';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import NoProperties from '../../selectedComponent/components/NoProperties';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import FriendlyDOM from '../../../../utils/tinyFacade/FriendlyDOM';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

const AdmonitionEditProps = (props: IGenericEditProps) => {
  const [userOpened, setUserOpened] = React.useState<boolean>();

  const project = ProjectStore.getProject();
  const hasDataAttributes = hasDefinitionDataAttributes(
    props.definition,
    props.parentDefinition?.id,
    getElmDef(props.selectedUnit?.definitionId)
  );

  if (!project) {
    return null;
  }

  if (ProjectDefinitionStore.allowUnitIndentation()) {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        {UnitUtils.isFormatingAvailable(props.unitElement) && (
          <ToggleSection
            title={(FriendlyDOM.translate(props.unitElement, ProjectDefinitionStore) || props.profile.displayName) + ' Formatting'}
            id={'unitProps_' + props.index}
            defaultOpen={false}
            forceClose={!props.isLeaf}
            forceOpen={props.isLeaf || userOpened}
            onToggled={(isOpened) => setUserOpened(isOpened)}
          >
            <MultiFormatting formatNode={props.index === 0 ? null : props.unitElement} unitProfile={props.unitProfile} />
          </ToggleSection>
        )}
      </div>
    );
  } else {
    if (!props.isOneOfMultiple) {
      return <NoProperties />;
    } else {
      return (
        <div className="toggle-section-outer selected-unit-props-container">
          <ToggleSection
            title="Attention Properties"
            id={'unitProps_' + props.index}
            defaultOpen={false}
            forceClose={!props.isLeaf}
            forceOpen={props.isLeaf || userOpened}
            onToggled={(isOpened) => setUserOpened(isOpened)}
            enabled={hasDataAttributes}
          >
            <DataAttributesPropsWrapper {...props} />
          </ToggleSection>
        </div>
      );
    }
  }
};

export default AdmonitionEditProps;
