import * as React from 'react';
import MenuActionItem, { MenuActionItemProps } from '../../EditorMenu/MenuActionItem';
import { MenuAction } from './defaultMenuContents';

interface Props {
  onAction: MenuActionItemProps['onAction'];
  isReadOnly: boolean;
  actions: MenuAction[];
}

const DocActionItems = ({ onAction, isReadOnly, actions }: Props) => {
  return (
    <>
      {actions.map((menuItem, menuItemIndex) => {
        return <MenuActionItem key={menuItemIndex} action={menuItem} onAction={onAction} enabled={menuItem.enabled && !isReadOnly} />;
      })}
    </>
  );
};

export default DocActionItems;
