import * as React from 'react';
import FileIcon from '../misc/icons/FileIcon';
import { UploadType } from '../projects/ImportDocument';
import { getTypeFromMimeType } from '../../utils/FileIcon';

export interface DropzoneFilePreviewProps {
  files: File[] | null;
  multiple?: boolean;
  uploadType?: UploadType;
  onRemove?: (index: number, e: MouseEvent) => any;
}

export default class DropzoneFilePreview extends React.Component<DropzoneFilePreviewProps> {
  constructor(props) {
    super(props);
  }

  private onClick(e, index: number) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onRemove) {
      this.props.onRemove(index, e);
    }
  }

  render() {
    let { files, uploadType, multiple } = this.props;
    files = files || [];
    const hasFiles = !!files.length;
    return (
      <div className="dropzone-file-preview-component">
        {hasFiles && (
          <div className={`file-list ${multiple ? 'multiple' : 'single'}`}>
            {files.map((file, index) => {
              return (
                <div key={`file-${index}`} className="file-list-item animation-fade-in">
                  <FileIcon fileType={getTypeFromMimeType(file)} style={{ fontSize: '18px', marginTop: '-2px' }} />
                  <span className="item-name" onClick={(e) => this.onClick(e, index)}>
                    {file.name}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {!hasFiles && (
          <div className="file-add animation-fade-in">
            <i className="material-icons">add_circle</i>
            <label className="cursor-pointer">
              Drop {!uploadType || uploadType !== 'attachment' ? 'file' : 'files'}, or click to upload
            </label>
          </div>
        )}
      </div>
    );
  }
}
