import * as _ from 'lodash';
/* FRAMEWORK */
import * as React from 'react';

/* UTILS */
import StringUtil from '../../../../../utils/StringUtil';
/* TYPES */
import { IUnitDefinition, IElementDefinition } from 'mm-types';
import { MenuItem } from '../../EditorMenu';
import { DefinitionToMenuPropsType } from '../MenuInsert';

const _isSelf = (definitionId, canInsert) => {
  let isSelf = false;
  if (_.has(canInsert, 'disabled') && !canInsert.disabled && canInsert.insertElementDirectChild) {
    const elementDefinitionId = canInsert.insertElementDirectChild.getAttribute('data-element-definition-id');
    if (elementDefinitionId) {
      isSelf = elementDefinitionId === definitionId;
    }
  }
  return isSelf;
};

type ConvertRuleType = (definitionId: string, validConverts: string[]) => boolean;

type InsertRuleType = (
  elementType: string,
  insertAtLevel: number
) => {
  disabled: boolean;
  insertElement: HTMLElement | null;
  insertElementDirectChild: HTMLElement | null;
};

export const unitDefinitionToMenuItem = (convertRule: ConvertRuleType) => (
  { id, displayName, title }: IUnitDefinition,
  { validConverts }: DefinitionToMenuPropsType
) => {
  return (
    <MenuItem key={id} value={id} disabled={!convertRule(id, validConverts)} className={`insert-${id}`}>
      {title ? title : `${StringUtil.formatForScreen(displayName)}`}
    </MenuItem>
  );
};

export const elementDefinitionToMenuItem = (convertRule: ConvertRuleType, insertRule: InsertRuleType) => (
  { id, displayName, description }: IElementDefinition,
  { validConverts }: DefinitionToMenuPropsType
) => {
  const canInsert = insertRule(id, 1);
  const canConvert = convertRule(id, validConverts);
  const disabled = (_.has(canInsert, 'disabled') && canInsert.disabled) || !canConvert || _isSelf(id, canInsert);
  return (
    <MenuItem key={'el_' + id} value={'el_' + id} disabled={disabled} className={`insert-${id}`} description={description}>
      {displayName ? displayName : `${StringUtil.formatForScreen(id)}`}
    </MenuItem>
  );
};
