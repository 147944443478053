import React from 'react';
import DocumentIcon from '../../documents/kpi/DocumentIcon';

export type WorkflowTasksDetailsProps = {
  title: string;
  revision: string;
  type: string;
  external: boolean;
};

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  container: { display: 'flex' },
  icon: { fontSize: '20px', flex: '0 0 26px', alignSelf: 'flex-start' },
  details: { flex: '1', overflow: 'hidden' },
  title: {
    fontSize: '0.9rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '0'
  },
  revision: {
    color: '#979797',
    fontSize: '0.8rem'
  }
};

const WorkflowTasksDetails = ({ title, revision, type, external }: WorkflowTasksDetailsProps) => {
  return (
    <div style={defaultStyles.container}>
      <DocumentIcon selected={false} type={type} external={external} />
      <div style={defaultStyles.details}>
        <h1 style={defaultStyles.title}>{title}</h1>
        <span style={defaultStyles.revision}>Rev: {revision}</span>
      </div>
    </div>
  );
};

export default WorkflowTasksDetails;
