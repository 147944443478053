import * as React from 'react';
import { MaterialIcon } from '../../../general/MaterialIcon';
import Tooltip from '../../../general/Tooltip';
import Bounce from '../../../general/animations/Bounce';

interface Props {
  reason?: string;
  className?: string;
  show: boolean;
}

export function DisabledPDFWarningIcon({ show, reason, className = '' }: Props) {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const iconEl = React.useRef(null);
  const cls = `display-inline-block ${className}`;

  return (
    <span className={cls} ref={iconEl} style={{ height: 24, width: 24 }}>
      <Bounce show={show} enterDuration={350} exitDuration={350}>
        <MaterialIcon
          className="font-white cursor-pointer"
          data-qa="disabled-pdf-warning-icon"
          iconName="error_outline"
          onClick={() => setShowTooltip(!showTooltip)}
        />
      </Bounce>
      {show && reason && (
        <Tooltip
          open={showTooltip}
          anchorEl={iconEl.current!}
          position="bottom"
          onRequestClose={() => {
            setShowTooltip(false);
          }}
        >
          {reason}
        </Tooltip>
      )}
    </span>
  );
}
