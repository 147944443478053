import TinyFacade from '../TinyFacade';
import * as keyIdentifier from '../../keyIdentifier';
import { isTabKey } from '../../keyIdentifier';
import { Dom } from '../DomUtil';
import { isCursorAtTheEndOfNode, isRangeSelection } from '../TinyFacadeHelpers';
import { CustomEditor } from '../EditorInstanceManager';
import linkUtils from '../../../links/generic/linkService';
import _ from 'lodash';
import { UnitUtils } from '../../units/UnitUtils';
import { IUnitDetails } from 'mm-types';
import { ElementDetails } from '../../units/ElementDetails';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import { TableUtils } from '../../../../../utils';
import { UNIT_ELEMENT_ATTRIBUTE_NAME } from '../../units/const/UnitElementSelectors';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { manageListKeys } from './UnitKeyBehaviors';

export const NBSP_CHAR = '&nbsp;';
export const BLANK_CHAR = '';
export const ZERO_LENGTH_WORD_JOINER = '\u2060';
export const ZERO_WIDTH_NO_BREAK_SPACE = '\uFEFF';
export const NEW_LINE_CHAR = '\n';
/**
 * Handles table cell tab behaviour either to next table cell or insert row after last cell any tables, nested or otherwise
 * Cancels parent element tab key behaviour (list)
 * */
export function tableCellTabBehaviour(selectedNode: HTMLElement, e: React.KeyboardEvent, tinyFacade: TinyFacade | null) {
  if (tinyFacade) {
    keyIdentifier.nukePropagation(e);
    const currentCell = Dom.closestElement(selectedNode, 'td');
    const nextCell = currentCell?.nextSibling;
    const currentRow = currentCell?.parentNode;
    let nextRow = currentRow?.nextSibling;

    if (nextCell) {
      tinyFacade.setSelection($(nextCell).find('.edit-target')[0] ?? nextCell);
    } else if (nextRow) {
      tinyFacade.setSelection($(nextRow).find('.edit-target')[0] ?? nextRow.firstChild);
    } else {
      if (TableUtils.hasSettingEnabled(selectedNode, 'insertRowBelow')) {
        // When inserting row need to get definition for template
        const template = ProjectDefinitionStore.getElementDefinitionById(
          ElementDetails.getDataElementDefinitionId(currentRow as Element) ?? 'TableRow'
        )?.templateHtml;
        tinyFacade.execCommand('mceTableInsertRowAfter', template, null, 'insert_after');
        nextRow = currentCell?.parentNode?.nextSibling;
        if (nextRow) {
          tinyFacade.setCursorLocation($(nextRow).find('.edit-target')[0] ?? nextRow.firstChild);
        }
      }
    }
  }
}

export const isDeletingLastCharInsideParagraph = function (selectedNode, e) {
  if (selectedNode && keyIdentifier.isDeleteKeys(e)) {
    const isParagraph = selectedNode && selectedNode.nodeName === 'P';
    if (isParagraph && selectedNode.innerText.length === 1) {
      const isNotZLC = !selectedNode.innerText.match(ZERO_LENGTH_WORD_JOINER);
      const isNotNBSP = !selectedNode.innerText.match(NBSP_CHAR);
      if (isNotZLC && isNotNBSP) {
        return true;
      }
    }
  }
  return false;
};

export const isSameNodeSelection = function (nodeA, nodeB) {
  return nodeA === nodeB;
};

export const isWholeNodeTextSelected = function (node, anchorOffset, focusOffset) {
  const isRightToLeftMouseDragSelection = anchorOffset < focusOffset;
  if (isRightToLeftMouseDragSelection) {
    return anchorOffset === 0 && node.textContent.length === focusOffset;
  } else {
    return focusOffset === 0 && node.textContent.length === anchorOffset;
  }
};

const isLastListElementSelected = function (editor) {
  const { startContainer, commonAncestorContainer, endContainer } = editor.selection.getRng();
  return (
    isTextNode(startContainer) &&
    isTextNode(commonAncestorContainer) &&
    isTextNode(endContainer) &&
    isSameNodeSelection(startContainer, endContainer)
  );
};

const isFirstOrOtherElementSelected = function (editor) {
  const { startContainer, endContainer } = editor.selection.getRng();
  return (isTextNode(startContainer) && !isTextNode(endContainer)) || (isTextNode(endContainer) && !isTextNode(startContainer));
};

export const isWholeArcUnitTextSelected = function (editor) {
  const { startContainer, commonAncestorContainer, endContainer } = editor.selection.getRng();
  const { anchorOffset, focusOffset } = editor.selection.getSel();
  return (
    isTextNode(startContainer) &&
    isTextNode(endContainer) &&
    isWholeNodeTextSelected(endContainer, anchorOffset, focusOffset) &&
    $(commonAncestorContainer).parent().hasClass('arc-unit')
  );
};

const isCorrectWholeNodeSelection = function (editor) {
  let isCorrectSelection = false;
  // when first, last or other element in a list is selected
  if (isFirstOrOtherElementSelected(editor) || isLastListElementSelected(editor) || isWholeArcUnitTextSelected(editor)) {
    isCorrectSelection = true;
  }
  return isCorrectSelection;
};

const isSelectedContentContainsAllText = function (editor, text) {
  const content = editor.selection.getContent();
  return content.replace(/&nbsp;/g, ' ') === text.replace(/\u00a0/g, ' ');
};

export const isWholeNodeSelected = function (editor) {
  if (isCorrectWholeNodeSelection(editor)) {
    const { startContainer, endContainer } = editor.selection.getRng();
    let fullContainerText;
    if (isTextNode(startContainer)) {
      fullContainerText = startContainer.data;
    } else if (isTextNode(endContainer)) {
      fullContainerText = endContainer.data;
    }
    if (fullContainerText) {
      return isSelectedContentContainsAllText(editor, fullContainerText);
    }
  }
  return false;
};

export const isDeletingSelectedTextRange = function (editor, e, selectedNode) {
  if (selectedNode && keyIdentifier.isDeleteKeys(e)) {
    const isParagraph = selectedNode && selectedNode.nodeName === 'P';
    if (isParagraph) {
      const { anchorNode, anchorOffset, focusNode, focusOffset } = editor.selection.getSel();
      if (
        isRangeSelection(editor) &&
        isSameNodeSelection(anchorNode, focusNode) &&
        isWholeNodeTextSelected(anchorNode, anchorOffset, focusOffset)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const isListNode = function (node): boolean {
  const nodeName = node.length ? node[0].nodeName : node.nodeName;
  return nodeName === 'UL' || nodeName === 'OL';
};

export const isLINode = function (node): boolean {
  const nodeName = node.length ? node[0].nodeName : node.nodeName;
  return nodeName === 'LI';
};

export const isTableNode = function (element?: IUnitDetails): boolean {
  if (!element) {
    return false;
  }
  return ['table', 'TableData', 'TableBody', 'TableHead', 'TableRow', 'TableFoot'].indexOf(element.type) >= 0;
};

const isPNode = function (node): boolean {
  const nodeName = node.length ? node[0].nodeName : node.nodeName;
  return nodeName === 'P';
};

const isEcamNode = function (node): boolean {
  return node.className === 'arc-ecam-data';
};

export const isTextNode = function ({ nodeName }): boolean {
  return nodeName && nodeName === '#text';
};

export const isDIVNode = function (node): boolean {
  const nodeName = node.length ? node[0].nodeName : node.nodeName;
  return nodeName === 'DIV';
};

const hasInlineClass = function (node: Node | null): boolean {
  const element = node as HTMLElement | null;
  return (
    !!element &&
    ((element.classList && (element.classList.contains('arc-inline') || element.classList.contains('arc-effectivity-tag'))) ||
      element.nodeName === 'SPAN')
  );
};

export const isNextFocusNodeEcam = function (editor: CustomEditor, e): boolean {
  const { focusNode } = editor.selection.getSel();
  if (!focusNode) {
    return false;
  }
  if (keyIdentifier.isLeftArrowKey(e)) {
    return isEcamNode(focusNode.previousSibling);
  } else if (keyIdentifier.isRightArrowKey(e)) {
    return isEcamNode(focusNode.nextSibling);
  }
  return false;
};

export const isTableRefIntInSelectedNode = function (editor: CustomEditor, e): boolean {
  const { focusNode } = editor.selection.getSel();
  const selectedNode = editor.selection ? (editor.selection.getNode() as HTMLElement) : null;
  if (focusNode && selectedNode && selectedNode?.querySelectorAll('.arc-refint')?.length > 0) {
    return true;
  }
  return false;
};

export const isNextFocusNodeInline = function (editor: CustomEditor, e): boolean {
  const { focusNode, focusOffset } = editor.selection.getSel();
  if (!focusNode) {
    return false;
  }
  const isOnlyZWCPresent = [ZERO_LENGTH_WORD_JOINER, ZERO_WIDTH_NO_BREAK_SPACE].includes(focusNode?.textContent ?? BLANK_CHAR);
  let isNextSiblingInline = false;
  let isAtTheEdgeOfNode = false;
  if (keyIdentifier.isLeftArrowKey(e)) {
    isNextSiblingInline = hasInlineClass(focusNode.previousSibling);
    if (!isOnlyZWCPresent) {
      isAtTheEdgeOfNode = focusOffset === 0;
    }
  } else if (keyIdentifier.isRightArrowKey(e)) {
    isNextSiblingInline = hasInlineClass(focusNode.nextSibling);
    if (!isOnlyZWCPresent) {
      isAtTheEdgeOfNode = !!focusNode.textContent && focusOffset === focusNode.textContent.length;
    }
  }
  return isTextNode(focusNode) && (isOnlyZWCPresent || isAtTheEdgeOfNode) && isNextSiblingInline;
};

export const isEqualLeftNode = function (node): boolean {
  return node.attributes['data-subtype'] && node.attributes['data-subtype'].value === 'leftEql';
};

export const isEqualRightNode = function (node): boolean {
  return node.parentNode?.parentNode?.attributes['data-subtype']?.value === 'rightEql';
};

export const isDateInput = (node: Element): boolean => {
  return node?.attributes['data-subtype']?.value === 'approval-date';
};

export const isLineBreakAllowed = (): boolean => {
  const elementDefinition = ProjectDefinitionStore.getCurrentIndexDefinition()?.elementDefinitions?.find(
    (elementDefinition) => elementDefinition.id === 'LineBreak'
  );
  return !(elementDefinition && 'userCreatable' in elementDefinition && !elementDefinition?.userCreatable);
};

export const removeLastLI = function (listNode) {
  if (isListNode(listNode)) {
    const children = listNode.children;
    if (children.length > 1) {
      const extraLi = children[children.length - 1];
      if (extraLi) {
        $(extraLi).remove();
      }
    }
  }
};

export const setContent = function (editor, e) {
  editor.selection.setContent(BLANK_CHAR);
};

const addRemovedParagraphs = (editor) => {
  $(editor.bodyElement)
    .find('li > ul:first-child, li > ol:first-child')
    .each((o, element) => {
      prependEmptyParagraph(editor, element);
    });
};

const removeNodes = (node: HTMLElement, ancestorContainer: HTMLElement) => {
  let nestedNode: HTMLElement | null = node;
  let hasSiblingsAfter = false;
  while (!hasSiblingsAfter && nestedNode && nestedNode !== ancestorContainer) {
    const parentNode: HTMLElement | null = nestedNode.parentElement;
    const $nestedNode = $(nestedNode);
    const nextSibling = $nestedNode.next();
    if (!hasSiblingsAfter && ((parentNode && parentNode.innerText.length === 0) || nestedNode.innerText.length === 0)) {
      $nestedNode.remove();
    }
    hasSiblingsAfter = nextSibling.length !== 0;
    nestedNode = parentNode;
  }
};

export const removeSelectedStructure = (editor, e) => {
  const { startContainer, commonAncestorContainer, endContainer } = editor.selection.getRng();

  if (isTextNode(endContainer)) {
    const startLiNode = startContainer.parentElement.parentNode;
    const endLiNode = endContainer.parentElement.parentNode;
    const isNested = $(startLiNode).find(endLiNode).length !== 0;
    const ancestorContainer = isNested ? startLiNode : commonAncestorContainer;
    const $startContainer: JQuery<HTMLElement> = $(startContainer);
    const $startParentNode = $startContainer.closest('li, p, div');
    const startParentNode = $startParentNode.get(0)!;
    const $endContainer = $(endContainer);
    const $endParentNode = $endContainer.closest('li, p, div');
    const endParentNode = $endParentNode.get(0)!;
    let offset: number;
    const differentParentNode = endParentNode !== startParentNode;
    setContent(editor, e);
    const { startElementRemoved, offsetAfterRemoval } = removeManagedLinks($startParentNode, $startContainer, $endContainer);

    if (differentParentNode) {
      offset = $startParentNode.text().length;
      $startParentNode.append($endParentNode.html());
      $endParentNode.text(BLANK_CHAR);
      removeNodes(endParentNode, ancestorContainer);
      Dom.removeEmptyNodes($startParentNode);
      const { node, relativeOffset } = Dom.getTextNodeForGivenOffset(startParentNode, startElementRemoved ? offsetAfterRemoval : offset);
      try {
        editor.selection.setCursorLocation(node, relativeOffset || 0);
        if ($startParentNode.text() === BLANK_CHAR) {
          injectZeroWidthCharInsideTag(editor);
        }
      } catch (e) {
        console.error(e);
      }
    }

    addRemovedParagraphs(editor);
  }
};

const removeManagedLinks = (
  $startParentNode: JQuery<HTMLElement>,
  $startElement: JQuery<HTMLElement>,
  $endElement: JQuery<HTMLElement>
): { startElementRemoved: boolean; offsetAfterRemoval: number } => {
  const startNodeAsLink = linkUtils.isLink($startElement);
  const endNodeAsLink = linkUtils.isLink($endElement);
  let startElementRemoved = false;
  let newOffset = 0;

  if (startNodeAsLink.exists && startNodeAsLink.isManaged) {
    startElementRemoved = true;
    const $element: JQuery<HTMLElement> = startNodeAsLink.element!;
    newOffset = Dom.getEndOffsetForPrevTextNode($startParentNode[0], $element[0]) || 0;
    $element.remove();
  }
  if (endNodeAsLink.exists && endNodeAsLink.isManaged) {
    endNodeAsLink.element!.remove();
  }
  return {
    startElementRemoved,
    offsetAfterRemoval: newOffset
  };
};

export const isSelectionOutsideParagraph = function (editor) {
  const { startContainer, endContainer, commonAncestorContainer, startOffset, endOffset } = editor.selection.getRng();
  if (
    startContainer.nodeName === endContainer.nodeName &&
    startContainer.nodeName === commonAncestorContainer.nodeName &&
    commonAncestorContainer.nodeName === 'P' &&
    startOffset === 1 &&
    endOffset === 1
  ) {
    return true;
  } else {
    return false;
  }
};

export const isImmediateParentAUnit = function ($node: JQuery<HTMLElement>): boolean {
  return $node.parent().parent().hasClass('arc-unit');
};

export const isFirstChild = function ($node: JQuery<HTMLElement>): boolean {
  return $node.index() === 0;
};

export const isCursorAtFirstColumn = function (editor): boolean {
  return editor.selection.getRng().startOffset === 0;
};

const hasNoSiblingsBefore = function ($node: JQuery<HTMLElement>): boolean {
  return _.isUndefined($node.next()[0]);
};

export const hasNoSiblingsAfter = function ($node: JQuery<HTMLElement>): boolean {
  return _.isUndefined($node.next()[0]);
};

export const hasNoText = function ($node: JQuery<HTMLElement>): boolean {
  return $node.text().length === 0;
};

export const handleListDemotion = function (editor: CustomEditor, $selectedNode: JQuery<HTMLElement>): boolean {
  let listItemDemoted = false;
  const { focusNode, focusOffset } = editor.selection.getSel() as any;

  const $previousListItem = $selectedNode.prev('li');

  // First item on the list can't be demoted
  if ($previousListItem.length === 0) {
    return true;
  }
  // find if previous LI has a sublist already, if so add LI at the end of it
  else if ($previousListItem.length > 0) {
    const $previousNestedList = $previousListItem.find('.arc-list');

    if ($previousNestedList.length > 0) {
      $selectedNode.appendTo($previousNestedList[0]);
      $selectedNode.after($selectedNode.children('ul,ol').children('li'));
      $selectedNode.children('ul,ol').remove();
      listItemDemoted = true;
    }
  }

  // otherwise find parent's list subtype, create a new sub-list from the template and move currently selected LI to that sub-list
  if (!listItemDemoted) {
    const $parentLists = $selectedNode.parents('.arc-list');

    if ($parentLists.length > 0) {
      const immediateParent = $parentLists[0];
      const parentListSubtype: string | null = immediateParent.getAttribute('data-subtype');
      const $subList = $selectedNode.find('>ul,>ol');
      let subListNid: string | null = null;
      if ($subList.length) {
        subListNid = $subList[0].getAttribute('data-nid');
      }
      const nestedList = UnitUtils.createListElementContainer(
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getProfileFromElSubType(parentListSubtype!),
        BLANK_CHAR,
        subListNid
      );
      const $parentNode = $selectedNode.prev('li');

      $parentNode[0].appendChild(nestedList!);
      $selectedNode.appendTo(nestedList!);
      $selectedNode.children('ul,ol').children('li').appendTo($parentNode.children('ul,ol'));
      $selectedNode.children('ul,ol').remove();

      UnitUtils.ensureNewListAttributes($selectedNode[0], BLANK_CHAR);
      listItemDemoted = true;
    }
  }

  // Preserve cursor location
  if (listItemDemoted) {
    restoreCursorLocation(editor, focusNode, focusOffset);
  }

  return listItemDemoted;
};

export const handleListPromotion = function (editor, $selectedNode: JQuery<HTMLElement>): boolean {
  let listItemPromoted = false;
  const { focusNode, focusOffset } = editor.selection.getSel();

  const $parentLists = $selectedNode.parents('.arc-list');
  const $listItemParents = $selectedNode.parents('.arc-li');

  // Make sure there is a list above, list item can be promoted to (need 2 lists above selected list item)
  if ($parentLists.length >= 2 && $listItemParents.length > 0) {
    if (hasNoSiblingsBefore($selectedNode)) {
      $selectedNode.insertAfter($listItemParents[0]);
    }
    // In the middle List Item
    else {
      // All items after selected LI
      const $toMoveListItems = $selectedNode.nextAll();

      // Create a list of the same type and put all items after selected LI into it
      const immediateParent = $selectedNode.parent()[0];
      const parentListSubtype: string | null = immediateParent.getAttribute('data-subtype');
      const parentDataNid: string | null = immediateParent.getAttribute('data-nid');
      const $nestedList = $(
        UnitUtils.createListElementContainer(
          ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getProfileFromElSubType(parentListSubtype!),
          BLANK_CHAR,
          parentDataNid
        )!
      );
      $nestedList.append($toMoveListItems);

      // Put newly created list into selecteLI
      $selectedNode[0].appendChild($nestedList[0]);

      // Move selected LI after a List Item which contained original nested list
      $selectedNode.insertAfter($listItemParents[0]);
    }

    // If Selected LIST is empty, remove it
    if ($parentLists[0].childNodes.length === 0) {
      $parentLists[0].remove();
    }

    listItemPromoted = true;
    UnitUtils.ensureNewListAttributes($selectedNode[0], BLANK_CHAR);
  }

  // Preserve cursor location
  if (listItemPromoted) {
    restoreCursorLocation(editor, focusNode, focusOffset);
  }

  return listItemPromoted;
};

const restoreCursorLocation = function (editor, originalFocusNode, originalFocusOffset) {
  setTimeout(() => {
    editor.selection.setCursorLocation(originalFocusNode, originalFocusOffset);
  }, 0);
};

export const insertPara = function (editor: CustomEditor, selectedNode: HTMLElement | null) {
  if (selectedNode) {
    const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph');
    const node = UnitUtils.createElementContainer(profile, selectedNode.innerText);

    if (node) {
      node.innerHTML = ZERO_LENGTH_WORD_JOINER;
      const editSelection = editor.selection;
      selectedNode.appendChild(node);
      editSelection.select(node, true);
      editSelection.collapse(false);
    }
  }
};

const prependEmptyParagraph = function (editor: CustomEditor, selectedNode: HTMLElement | null) {
  if (selectedNode) {
    const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph');
    const node = UnitUtils.createElementContainer(profile, ZERO_LENGTH_WORD_JOINER);

    if (node) {
      selectedNode.parentNode!.insertBefore(node, selectedNode);
    }
  }
};

const focusBeforeElement = function (editor: CustomEditor) {
  const selectedNode = editor.selection.getNode();
  const prev = selectedNode.previousSibling!;
  const offset = $(prev).text().length - 1;
  editor.selection.setCursorLocation(prev as any, offset);
};

const focusAfterElement = function (editor: CustomEditor) {
  const selectedNode = editor.selection.getNode();
  const next = selectedNode.nextSibling!;
  editor.selection.setCursorLocation(next as any, 0);
};

const canInjectZeroWidthChar = function (node: Node | null) {
  return !node || (node && (!node.nodeValue || (node.nodeValue && node.nodeValue[0] !== ZERO_LENGTH_WORD_JOINER)));
};

const injectZeroWidthCharBeforeTag = function (editor: CustomEditor, shouldFocus = true) {
  const selectedNode = editor.selection.getNode();
  if (canInjectZeroWidthChar(selectedNode.previousSibling)) {
    selectedNode.innerHTML = replaceZeroLengthZeroWidthCharacters(selectedNode);
    $(selectedNode).before(ZERO_LENGTH_WORD_JOINER);
    if (shouldFocus) {
      focusBeforeElement(editor);
    }
  }
};

const injectZeroWidthCharInsideTag = function (editor: CustomEditor) {
  const selectedNode = editor.selection.getNode();
  if (canInjectZeroWidthChar(selectedNode.firstChild)) {
    selectedNode.innerHTML = replaceZeroLengthZeroWidthCharacters(selectedNode);
    $(selectedNode).append(ZERO_LENGTH_WORD_JOINER);
  }
};

const injectZeroWidthCharAfterTag = function (editor: CustomEditor, shouldFocus = true) {
  const selectedNode = editor.selection.getNode();
  if (canInjectZeroWidthChar(selectedNode.nextSibling)) {
    $(selectedNode).after(ZERO_LENGTH_WORD_JOINER);
    if (shouldFocus) {
      focusAfterElement(editor);
    }
  }
};

const isFirstCharAZeroLengthChar = function (text: string): boolean {
  return !!text && text[0] === ZERO_LENGTH_WORD_JOINER;
};

const isLastCharAZeroLengthChar = function (text: string): boolean {
  return !!text && text[text.length - 1] === ZERO_LENGTH_WORD_JOINER;
};

export const injectZeroWidthCharBeforeNode = function (node: Node | null): boolean {
  if (node) {
    const { textContent } = node as Element;
    if (!textContent) {
      $(node).text(ZERO_LENGTH_WORD_JOINER + $(node).text().replace(ZERO_LENGTH_WORD_JOINER, BLANK_CHAR));
      return true;
    } else if (!isFirstCharAZeroLengthChar(textContent)) {
      if (!(node as Element).querySelector('span.nested-wc')) {
        (node as Element).innerHTML = ZERO_LENGTH_WORD_JOINER + replaceZeroLengthZeroWidthCharacters(node as Element);
      }
      return true;
    }
  }
  return false;
};

const injectZeroWidthCharAfterNode = function (node: Node | null): boolean {
  if (node) {
    const { textContent } = node;
    if (!textContent || !isLastCharAZeroLengthChar(textContent)) {
      $(node).text($(node).text().replace(ZERO_LENGTH_WORD_JOINER, BLANK_CHAR) + ZERO_LENGTH_WORD_JOINER);
      return true;
    }
  }
  return false;
};

const injectZeroWidthCharBeforeSelectedNode = function (editor: CustomEditor, e): boolean {
  const selectedNode = editor.selection.getNode();
  const isInjected = injectZeroWidthCharBeforeNode(selectedNode);
  if (isInjected) {
    editor.selection.setCursorLocation(selectedNode.childNodes[0] as any, 1);
    keyIdentifier.nukePropagation(e);
  }
  return isInjected;
};

const injectZeroWidthCharAfterSelectedNode = function (editor: CustomEditor, e): boolean {
  const selectedNode = editor.selection.getNode();
  const isInjected = injectZeroWidthCharAfterNode(selectedNode);
  if (isInjected) {
    editor.selection.setCursorLocation(selectedNode.childNodes[0] as any, selectedNode.childNodes[0].textContent!.length - 2);
    keyIdentifier.nukePropagation(e);
  }
  return isInjected;
};

const injectZeroWidthCharInFrontOfInlineTextNode = function (editor: CustomEditor) {
  const { focusNode } = editor.selection.getSel();
  injectZeroWidthCharBeforeNode(focusNode.nextSibling);
};

const injectZeroWidthCharAtTheEndOfInlineTextNode = function (editor: CustomEditor) {
  const { focusNode } = editor.selection.getSel();
  injectZeroWidthCharAfterNode(focusNode.previousSibling);
};

const isCursorAtTheStartOfNode = function (editor: CustomEditor) {
  const node = editor.selection.getNode();
  const { focusOffset, focusNode } = editor.selection.getSel();
  return focusOffset === 0 && focusNode === node.childNodes[0];
};

const isCursorOutsidePreviousNode = function (editor: CustomEditor) {
  const { anchorNode, focusNode, focusOffset } = editor.selection.getSel();
  return focusOffset <= 1 && anchorNode !== focusNode;
};

export const jumpBeforeLeftZeroWidthChar = function (editor: CustomEditor, e) {
  const { anchorNode } = editor.selection.getSel();
  const container = anchorNode.previousSibling;
  if (isCursorOutsidePreviousNode(editor)) {
    focusBeforeElement(editor);
    keyIdentifier.nukePropagation(e);
  } else if (isCursorAtTheStartOfNode(editor) && container) {
    editor.selection.setCursorLocation(container as any, 0);
    keyIdentifier.nukePropagation(e);
  }
};

export const jumpAfterRightZeroWidthChar = function (editor: CustomEditor, e) {
  const { anchorNode } = editor.selection.getSel();
  const container = anchorNode.nextSibling;
  if (isCursorOutsidePreviousNode(editor) && container) {
    editor.selection.setCursorLocation(container as any, 0);
    keyIdentifier.nukePropagation(e);
  } else if (isCursorAtTheEndOfNode(editor)) {
    focusAfterElement(editor);
  }
};

const getFirstTextNode = function (node: Node | null): Node | null {
  return (!!node && !!node.childNodes && node.childNodes[0]) || null;
};

const getLastTextNode = function (node: Node | null): Node | null {
  return (!!node && !!node.childNodes && node.childNodes[node.childNodes.length - 1]) || null; // could be 1 or 2 because might not be set
};

const isLastCharAZeroWidthChar = function (node: Node | null): boolean {
  if (node) {
    const text = $(node).text();
    const length = text.length;
    return text[length - 1] === ZERO_LENGTH_WORD_JOINER;
  }
  return false;
};

const isCursorAtBeginningOfText = function (editor: CustomEditor) {
  const selectedNode = editor.selection.getNode();
  const { focusOffset, focusNode } = editor.selection.getSel();
  const isValidNode = focusNode.nodeName === 'SPAN' || focusNode === getFirstTextNode(selectedNode);
  const isAtTheBeginningOfText = focusOffset === 0 && isValidNode;
  const isAtTheBeginningZeroWidthCharNode = focusNode.textContent === ZERO_LENGTH_WORD_JOINER && isValidNode;
  return isAtTheBeginningOfText || isAtTheBeginningZeroWidthCharNode;
};

const isCursorAtEndOfText = function (editor: CustomEditor) {
  const selectedNode = editor.selection.getNode();
  const { focusOffset, focusNode } = editor.selection.getSel();
  const isValidNode = focusNode.nodeName === 'SPAN' || focusNode === getLastTextNode(selectedNode);
  const nodeTextLength = selectedNode.textContent?.replace(ZERO_LENGTH_WORD_JOINER, BLANK_CHAR).length; // replace zwc to get real offset
  const isAtTheEndOfText = focusOffset === nodeTextLength;
  const isAtTheEndZeroWidthCharNode = focusNode.textContent === ZERO_LENGTH_WORD_JOINER && isValidNode; // get last zwc node
  const isAtTheEndOfTextWhenLastCharIsNotZWC =
    nodeTextLength && focusOffset === nodeTextLength - 1 && !isLastCharAZeroWidthChar(selectedNode);
  return isAtTheEndOfText || isAtTheEndZeroWidthCharNode || isAtTheEndOfTextWhenLastCharIsNotZWC;
};

export const isNestedWc = (node?: Node | null) => {
  if ((node as Element).classList.contains('nested-wc')) {
    return true;
  }
  return false;
};

export const manageInlineLeftArrowKey = function (editor: CustomEditor, e, isCollection: boolean, structureDepth: number) {
  const node = editor.selection.getNode();
  const isSelectedNodeAP = isPNode(node);
  if (isSelectedNodeAP) {
    injectZeroWidthCharAtTheEndOfInlineTextNode(editor);
  } else if (isCursorAtBeginningOfText(editor) && !isCollection && !isNestedWc(node)) {
    const isInjected = injectZeroWidthCharBeforeSelectedNode(editor, e);
    const shouldFocus = !isInjected;
    injectZeroWidthCharBeforeTag(editor, shouldFocus);
  } else if (isCollection && !isFirstOrLastElmInCollection(node, false)) {
    let node = editor.selection.getNode();
    injectZeroWidthCharBeforeNode(node.previousSibling);
  } else if (isCollection && isFirstOrLastElmInCollection(node, false)) {
    let nodeToOperate: Element = node;
    for (let i = 0; i < structureDepth; i++) {
      nodeToOperate = nodeToOperate.parentNode as Element;
    }
    const text = nodeToOperate.previousSibling?.textContent?.slice(-1);
    if (!hasNonPrintableChar(text)) {
      $(nodeToOperate).before(ZERO_LENGTH_WORD_JOINER);
    }
  } else {
    jumpBeforeLeftZeroWidthChar(editor, e);
  }
};

export const manageInlineRightArrowKey = function (editor: CustomEditor, e, isCollection: boolean, structureDepth: number) {
  const node = editor.selection.getNode();
  const isSelectedNodeAP = isPNode(node);
  if (isSelectedNodeAP) {
    injectZeroWidthCharInFrontOfInlineTextNode(editor);
  } else if (isCursorAtEndOfText(editor) && !isCollection && !isNestedWc(node)) {
    const isInjected = injectZeroWidthCharAfterSelectedNode(editor, e);
    const shouldFocus = !isInjected;
    injectZeroWidthCharAfterTag(editor, shouldFocus);
  } else if (isCollection && !isFirstOrLastElmInCollection(node, true)) {
    injectZeroWidthCharBeforeNode(node.nextSibling);
  } else if (isCollection && isFirstOrLastElmInCollection(node, true)) {
    let nodeToOperate: Element = node;
    for (let i = 0; i < structureDepth; i++) {
      nodeToOperate = nodeToOperate.parentNode as Element;
    }
    const text = nodeToOperate.nextSibling?.textContent?.slice(0);
    if (!hasNonPrintableChar(text)) {
      $(nodeToOperate).after(ZERO_LENGTH_WORD_JOINER);
    }
  } else {
    jumpAfterRightZeroWidthChar(editor, e);
  }
};

export const hasNonPrintableChar = (text?: string) => {
  return text && [...text].some((char) => [8288, 0x2060].includes(char.charCodeAt(0)));
};

export const isInlineDataEnum = (el: HTMLElement) => {
  return el.classList.contains('arc-inline-enum');
};

export const replaceZeroLengthZeroWidthCharacters = (
  element: Element,
  character: string | RegExp = ZERO_LENGTH_WORD_JOINER,
  replaceWith = BLANK_CHAR
): string => {
  return element?.innerHTML?.replace(character, replaceWith);
};

const isFirstOrLastElmInCollection = (node: Element, isLast: boolean) => {
  if (isLast) {
    return node.nextSibling === null || (node.nextSibling as Element)?.classList?.length === 0;
  } else {
    return node.previousSibling === null || (node.previousSibling as Element)?.classList?.length === 0;
  }
};

export const elementAllowsTextInputFromDataAttribute = (node: Element): boolean => {
  if (node.getAttribute(UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_DEFINITION_ID)) {
    return node.getAttribute('data-allow-text-input') === 'true';
  } else {
    return node.closest('[data-element-definition-id]')?.getAttribute('data-allow-text-input') === 'true';
  }
};

export const handleTableNodeParagraphBehaviour = (e: React.KeyboardEvent, selectedNode: HTMLElement): boolean | void => {
  const tinyFacade = EditorStore.getEditor().getActiveEditorFacade();
  const selection = TableUtils.getSelectedCells();
  if (keyIdentifier.isDeleteKeys(e) && selection && selection.length > 1) {
    if (selection) {
      for (const c of selection) {
        c.textContent = BLANK_CHAR;
      }
    }
    keyIdentifier.nukePropagation(e);
    return true;
  } else if (isTabKey(e)) {
    tableCellTabBehaviour(selectedNode, e, tinyFacade);
  }
};

export const handleListNodeParaBehaviour = (e: React.KeyboardEvent, selectedNode: HTMLElement, editor: CustomEditor) => {
  if (keyIdentifier.isTabKey(e)) {
    return manageListKeys(editor, e);
  } else if (isDeletingLastCharInsideParagraph(selectedNode, e) || isDeletingSelectedTextRange(editor, e, selectedNode)) {
    setTimeout(() => editor.undoManager.transact(() => (selectedNode.innerText = ZERO_LENGTH_WORD_JOINER)), 10);
    keyIdentifier.nukePropagation(e);
    return true;
  }
};

export const selectedNodeHasNoText = (selectedNode: HTMLElement): boolean => {
  return (
    selectedNode.innerText?.length === 0 ||
    (selectedNode.innerText?.length === 1 &&
      [NBSP_CHAR, ZERO_LENGTH_WORD_JOINER, ZERO_WIDTH_NO_BREAK_SPACE, BLANK_CHAR, NEW_LINE_CHAR].indexOf(selectedNode.innerText) !== -1)
  );
};
