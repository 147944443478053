import React from 'react';
import { CircularProgress } from 'material-ui';
import { IActionTaskEntry } from 'mm-types';
import { UnitTypes } from '../../../utils/units/UnitTypes';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

export type TaskActivityListProps = {
  loading: boolean;
  activityEntries: null | IActionTaskEntry[];
  selected: string | null;
  loadingScroll: boolean;
  handleScroll: () => void;
  handleSelectActivity: (unitTag: IActionTaskEntry) => void;
  ref: React.RefObject<HTMLUListElement>;
};

const TaskActivityList = ({
  loading,
  activityEntries,
  selected,
  loadingScroll,
  handleScroll,
  handleSelectActivity,
  ref
}: TaskActivityListProps) => {
  const getUnitTitle = (type: UnitTypes) => {
    return type === 'tocable'
      ? 'Heading Element'
      : ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(type)?.displayName;
  };

  return (
    <>
      {!loading && activityEntries && activityEntries.length > 0 ? (
        <ul className="activity-list activity-list-tasks" onScroll={() => handleScroll()} ref={ref}>
          {activityEntries.map((unitTag) => {
            return (
              <li onClick={() => handleSelectActivity(unitTag)} key={unitTag.key} className={selected === unitTag.key ? 'selected' : ''}>
                <div className="icon-activity-outer">
                  <div className="icon-activity icon-tasks" style={{ backgroundColor: unitTag.task.colorHex }} />
                </div>

                <div className="ctask-activity-item">
                  <div>
                    <div className="ctask-activity-item-name">
                      {unitTag.ordinal ? unitTag.ordinal + ' ' : ''}
                      {getUnitTitle(unitTag.type)}
                    </div>
                    <div className="ctask-activity-item-description">
                      Task {unitTag.task.identifier}: {unitTag.task.title}
                    </div>
                  </div>
                  <div>
                    <div className="ctask-activity-item-time">{unitTag.createdTimeFormatted}</div>
                    <div className="ctask-activity-item-user">{unitTag.taggerDisplayName}</div>
                  </div>
                </div>
              </li>
            );
          })}
          {loadingScroll ? (
            <li>
              <div className="loading">
                <CircularProgress mode="indeterminate" size={17.85} />
              </div>
            </li>
          ) : undefined}
        </ul>
      ) : undefined}
    </>
  );
};

export default TaskActivityList;
