import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import EditorStore from '../../../flux/editor/EditorStore';

export type Props = {
  onCloseRequest?: () => void;
  data: {
    unitUid: string;
    sharedIndexUid: string;
  };
};

export type State = {};

export default class SharedContentRejectConfirm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  _handleAction(action: 'reject' | 'cancel') {
    if (action === 'reject') {
      EditorStore.rejectSharedContentUpdate(this.props.data.unitUid, this.props.data.sharedIndexUid);
    }

    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  }

  render() {
    return (
      <div className="inner-modal editor-sharedcontent-confirm">
        <div>
          <h1>Rejecting the shared content update will make it a content reference and future updates will require manual editing.</h1>
          <div className="inner-modal-buttons">
            <FlatButton
              label="Cancel"
              labelStyle={{ color: 'white' }}
              onClick={() => {
                this._handleAction('cancel');
              }}
            />
            <FlatButton
              label="Ok"
              labelStyle={{ color: 'white' }}
              onClick={() => {
                this._handleAction('reject');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
