import { IDocUnitProfile } from 'mm-types';
import ListEditProps from '../../../../sidetabs/sub/editComponent/components/ListEditProps';
import { listMenuOverrides, listMenuSelectedOverrides } from '../../const/profileMenuOverrides';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'List',
  getLabel: () => 'LIST',

  editPropsComponent: ListEditProps,
  splittable: true,
  menu: listMenuOverrides,
  menuTextSelected: listMenuSelectedOverrides,
  arcMLDefaults: {
    root: { top: 1, right: 0, bottom: 1, left: 0, ordinal: 0 },
    nested: { top: 1, right: 0, bottom: 1, left: 0, ordinal: 0 }
  }
};
