import React, { useEffect, useState } from 'react';
import { Dialog, FlatButton } from 'material-ui';
import { ShortcutKeyProps } from 'mm-types';
import { Shortcutkeys } from './Shortcutkeys';

interface ShortcutProps {
  title: string;
  keys: ShortcutKeyProps[];
}

interface Props {
  shortcuts: ShortcutProps[];
}

export const ShortcutkeysDialog = (props: Props) => {
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 191 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        setShowHelp(true);
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    return function cleanupListener() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Dialog
      style={{ zIndex: 999 }}
      contentClassName="shortcutkeys-dialog"
      title="Keyboard Shortcuts"
      open={showHelp}
      onRequestClose={() => setShowHelp(false)}
      actions={[
        React.createElement(FlatButton, {
          label: 'Ok',
          primary: true,
          onClick: () => setShowHelp(false)
        })
      ]}
    >
      <div>
        <div className="header-keys">
          <Shortcutkeys keys={[{ label: 'Ctrl' }, { label: '/', type: 'material-icons' }]} />
        </div>
        <ul>
          {props.shortcuts.map(({ title, keys }, index) => (
            <li key={index}>
              {title} <Shortcutkeys keys={keys} />
            </li>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};
