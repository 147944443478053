import * as React from 'react';
import UnitConceptStore from '../../../../flux/editor/UnitConceptStore';
import { IUnit } from 'mm-types';
import useListenToStore from '../../../hooks/useListenToStore';

type Props = {
  unit: IUnit;
};

type State = {
  hasVariants: boolean;
  isEditable: boolean;
};

const DocUnitVariantTag = (props: Props) => {
  const [state, setState] = React.useState<State>({
    hasVariants: UnitConceptStore.getUnitHasVariants(props.unit.uid),
    isEditable: UnitConceptStore.getUnitEditable(props.unit)
  });

  React.useEffect(() => {
    if (!!props.unit?.uid) {
      setState({
        hasVariants: UnitConceptStore.getUnitHasVariants(props.unit.uid),
        isEditable: UnitConceptStore.getUnitEditable(props.unit)
      });
    }
  }, [props.unit?.uid]);

  const onStoreChange = () => {
    const hasVariants = UnitConceptStore.getUnitHasVariants(props.unit.uid);
    const isEditable = UnitConceptStore.getUnitEditable(props.unit);

    // Small optimization
    if (hasVariants === state.hasVariants && isEditable === state.isEditable) {
      return;
    }

    setState({
      hasVariants: hasVariants,
      isEditable: isEditable
    });
  };

  useListenToStore({ store: UnitConceptStore, eventListener: onStoreChange, update: [state.hasVariants, state.isEditable] });

  if (!state.hasVariants) {
    return null;
  }

  return (
    <div className="du-inline-item du-inline-variants">
      <i className={'unit-variant-tag material-icons ' + (state.isEditable ? 'icon-variant-indicator' : 'icon-variant')} />
    </div>
  );
};

export { DocUnitVariantTag };
