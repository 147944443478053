import * as Reflux from 'reflux';
import * as projectsClient from '../../clients/projects';
import * as workspaces from '../../clients/workspaces';
import { IWorkspace, IRole, IUser } from 'mm-types';
import Store from '../Store';
import { ClientError, Cancelled } from '../../clients/base-clients';

export type ErrorTypes = 'GROUP_EXISTS' | 'GROUP_GONE' | 'WORKSPACE_EXISTS' | 'WORKSPACE_GONE' | 'WORKSPACE_INUSE' | 'NO_PERMISSION';

export type State = {
  roles: IRole[];
  workspaces: IWorkspace[];
  userRole: string | IRole | null;
  snackBarMessage: string | null;
};

export type DocTeamStoreEvent = State & { error?: ErrorTypes };

export class DocTeamStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      roles: [],
      workspaces: [],
      userRole: null,
      snackBarMessage: null
    };
  }

  getInitialState() {
    return this.state;
  }

  _getTeamPermission(roles: IRole[], user: IUser) {
    let userRole: string | IRole;
    if (typeof user !== 'string') {
      userRole = user.role;
    } else {
      userRole = user;
    }
    const searchRole = userRole === 'ArconicsAdministrator' ? 'Project Admin' : userRole;
    const permission = roles.filter((role) => {
      return role.name === searchRole;
    });
    return permission.length === 1
      ? permission[0]
      : roles.filter((role) => {
          return !role.permissions.canRead;
        })[0];
  }

  retrieveUserRole(user: IUser) {
    this.state.userRole = this._getTeamPermission(this.state.roles, user);
    const event: DocTeamStoreEvent = this.state;
    this.trigger(event);
  }

  async fetchRoles(user: IUser) {
    try {
      await this.retrieveRoles().then(() => {
        return this.retrieveUserRole(user);
      });
    } catch (e) {
      this.trigger({ error: 'NO_PERMISSION' } as DocTeamStoreEvent);
    }
  }

  async retrieveRoles() {
    try {
      const result = await projectsClient.getRoles();
      if (result instanceof Cancelled) {
        return;
      }

      this.state.roles = result;
      const event: DocTeamStoreEvent = this.state;
      this.trigger(event);
    } catch (err) {
      this.trigger({ error: 'NO_PERMISSION' } as DocTeamStoreEvent);
    }
  }

  getUserRole() {
    return this.state.userRole;
  }

  async retrieveWorkspaces(silent = false) {
    const returnedSpaces = await workspaces.getAll({ canAdministerOnly: true });
    this.state.workspaces = returnedSpaces.filter((w) => w.type === 'TEAM');

    if (!silent) {
      const event: DocTeamStoreEvent = this.state;
      this.trigger(event);
    }
  }

  async createWorkspace(token: Partial<IWorkspace>) {
    try {
      await workspaces.create(token);
      await this.retrieveWorkspaces();
      this.trigger({ snackBarMessage: 'Teamspace created' } as DocTeamStoreEvent);
    } catch (err) {
      if (err instanceof ClientError && err.status === 409) {
        this.trigger({ error: 'WORKSPACE_EXISTS' } as DocTeamStoreEvent);
      } else {
        await this.retrieveWorkspaces();
      }
    }
  }

  async updateWorkspace(uid: string, token: IWorkspace) {
    try {
      await workspaces.update(uid, token);
      await this.retrieveWorkspaces();
      this.trigger({ snackBarMessage: 'Teamspace updated' } as DocTeamStoreEvent);
    } catch (err) {
      if (err instanceof ClientError && err.status === 409) {
        this.trigger({ error: 'WORKSPACE_EXISTS' } as DocTeamStoreEvent);
      } else {
        await this.retrieveWorkspaces();
      }
    }
  }

  async removeWorkspace(workspaceUid: string) {
    try {
      await workspaces.remove(workspaceUid);
      await this.retrieveWorkspaces();
      this.trigger({ snackBarMessage: 'Teamspace deleted' } as DocTeamStoreEvent);
    } catch (err) {
      if (err instanceof ClientError && err.status === 412) {
        this.trigger({ error: 'WORKSPACE_INUSE' } as DocTeamStoreEvent);
      } else {
        this.retrieveWorkspaces();
      }
    }
  }
}

const singleton = Reflux.initStore<DocTeamStore>(DocTeamStore);
export default singleton;
