export const AIRBUS_INLINE_ELEMENT = ['ifinstalled', 'abbreviation'] as const;
export const AIRBUS_EXT_OBJ_ELEMENTS = ['auralwarning', 'launcher', 'extobj'] as const;

export type AirbusInlineDataTypes = typeof AIRBUS_INLINE_ELEMENT[number];

export type AirbusExtObjTypes = typeof AIRBUS_EXT_OBJ_ELEMENTS[number];

export type AirbusTypes = 'sheet' | 'symbol' | AirbusExtObjTypes;

export type ElementTypes = AirbusInlineDataTypes | AirbusTypes | 'link'; // TODO: add more element types as applicable
