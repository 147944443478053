import * as React from 'react';
import * as _ from 'lodash';
import TextField from 'material-ui/TextField';
import { style } from '../../../../../../styles/styleGuard';

let isBlurFromESC = false;

const spanStyle = style({
  display: 'block'
});

export type Props = {
  value: string;
  maxChars?: number;
  onEnter: (val: string) => void;
  onEnterKey?: (val: string) => void;
  onChange?: (val: string) => void;
  name?: string;
  id?: string;
  fullWidth?: boolean;
  multiLine?: boolean;
  className?: string;
  floatingLabelText?: string;
  floatingLabelStyle?: React.CSSProperties;
  disabled?: boolean;
  blockElement?: boolean;
};

const TextInput = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.value || '');
  const txtInput = React.useRef<TextField>(null);
  React.useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const updateValue = (e: React.FormEvent<{}>) => {
    const val = (e.target as HTMLInputElement).value;
    setValue(val);
    if (props.onChange) {
      props.onChange(val);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    // always !
    e.nativeEvent.stopImmediatePropagation();
    const target = e.target as HTMLInputElement;
    e.stopPropagation();

    if (e.keyCode === 27) {
      isBlurFromESC = true;
      target.blur();
      setValue(props.value || '');
    } else if ((e.keyCode === 13 && !e.shiftKey) || (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey))) {
      if (e.keyCode === 13 && !e.shiftKey && props.onEnterKey) {
        props.onEnterKey(target.value);
      }
      target.blur();
      e.preventDefault();
    }
  };

  const handleBlur = () => {
    if (!isBlurFromESC) {
      if (props.onEnter) {
        props.onEnter(value);
      }
    }

    isBlurFromESC = false;
  };

  const passthroughProps = _.omit(props, ['onEnter', 'maxChars', 'onEnterKey', 'blockElement']);
  const style = props.blockElement ? spanStyle : undefined;
  return (
    <span onKeyDown={(e) => onKeyDown(e)} style={style}>
      <TextField
        {...passthroughProps}
        maxLength={props.maxChars || 999}
        onChange={(e) => updateValue(e)}
        onBlur={(e) => handleBlur()}
        value={value}
        ref={txtInput}
      />
    </span>
  );
};

export default TextInput;
