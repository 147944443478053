import { TableEditProps } from '../../../../sidetabs/sub/editComponent/components/TableEditProps/TableEditProps';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Table',
  getLabel: () => 'TABLE',
  identifyingClasses: [
    'arc-table',
    'arc-table-head',
    'arc-table-body',
    'arc-table-foot',
    'arc-table-caption',
    'arc-table-row',
    'arc-table-data'
  ],
  identifyingElements: ['table', 'tbody', 'tfoot', 'thead', 'tr', 'td'],
  outerClass: 'arc-table',
  outerElement: 'table',
  observeElement: ['TABLE', 'TD'],
  openEditPaneOnSelect: true,
  isEncapsulatingElement: true,
  hasColPrintOutput: true,

  template: '',
  editPropsComponent: TableEditProps,

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    editActions: [
      { action: 'Paste', enabled: true },
      { action: 'arcPasteElement', enabled: true }
    ],
    textActions: [{ action: 'arcFontSize', enabled: true, visible: true }],
    specialActions: [{ action: 'SpecialCharacter', enabled: true }],
    justifyActions: [
      { action: 'JustifyLeft', enabled: true },
      { action: 'JustifyCenter', enabled: true },
      { action: 'JustifyRight', enabled: true },
      { action: 'JustifyFull', enabled: false }
    ],
    linkActions: [{ action: 'insertLink', enabled: true }]
  },
  menuTextSelected: {
    editActions: [],
    textActions: [
      { action: 'bold', enabled: true },
      { action: 'italic', enabled: true },
      { action: 'code', enabled: true },
      { action: 'underline', enabled: true },
      { action: 'superscript', enabled: true },
      { action: 'subscript', enabled: true },
      { action: 'arcTextTransform', enabled: false, visible: true }
    ],
    linkActions: [{ action: 'insertLink', enabled: true }]
  },

  editor: {
    formats: {
      fontsize: { selector: 'td,th', styles: { 'font-size': '%value' }, remove_similar: true },
      vertical90: { inline: 'span', wrapper: true, classes: 'arc-rotate-right', exact: true },
      vertical270: { inline: 'span', wrapper: true, classes: 'arc-rotate-left', exact: true },
      underline: { inline: 'span', wrapper: true, classes: 'arc-underline', exact: true },
      subscript: { inline: 'span', classes: 'arc-sub-script', exact: true },
      superscript: { inline: 'span', classes: 'arc-super-script', exact: true },
      capitalize: { inline: 'span', classes: 'arc-capitalize', exact: true },
      uppercase: { inline: 'span', classes: 'arc-uppercase', exact: true },
      lowercase: { inline: 'span', classes: 'arc-lowercase', exact: true },
      forecolor: {
        inline: 'span',
        classes: 'arc-color-text',
        attributes: {
          'data-tinymce-placeholder': 'placeholder'
        },
        links: true,
        remove_similar: false,
        clear_child_styles: true,
        exact: true,
        merge_siblings: false,
        // a custom match format logic for attributes and styles. Match regardless of attributes or styles for hilitecolor.
        onmatch: () => true
      },
      hilitecolor: {
        inline: 'span',
        classes: 'arc-bg-color-text',
        attributes: {
          'data-tinymce-placeholder': 'placeholder'
        },
        links: true,
        remove_similar: false,
        clear_child_styles: true,
        exact: true,
        merge_siblings: false,
        // a custom match format logic for attributes and styles. Match regardless of attributes or styles for hilitecolor.
        onmatch: () => true
      }
    }
  }
};
