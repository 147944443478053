import { EditorMode } from './EditorMode';
import { GetOptions } from '../../../../clients/units';
import { Banner, EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class SpellCheckMode extends EditorMode {
  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.mainmenuTabs, syncQueue);
  }

  getBanner(): Banner {
    return { title: 'Spell Check Mode' };
  }

  getRetrieveUnitParams() {
    return { spellCheck: true } as Partial<GetOptions>;
  }

  getActivatedMenuTabSelections() {
    return { main: null, sub: 'spellCheck' };
  }

  getDeactivatedMenuTabSelections() {
    return { main: null, sub: 'edit' };
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'RELOAD';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    return 'RELOAD';
  }
}
