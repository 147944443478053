import { UnitTypes } from '../components/editor/utils/units/UnitTypes';
import { ElementTypes } from '../components/editor/utils/units/ElementTypes';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

async function getScaledHTML(m, inline: boolean, type: UnitTypes | ElementTypes) {
  let imageHtml: string | undefined;

  if (inline) {
    imageHtml = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
      .getElementProfileByDefinitionId(type)!
      .templateHtml?.replace('%MEDIA_UID%', m.uid)
      .replace('%MEDIA_LOCATION%', m.location);
  } else {
    imageHtml = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
      .getUnitProfileByDefinitionId(type)
      ?.template?.replace('%MEDIA_UID%', m.uid)
      .replace('%MEDIA_LOCATION%', m.location);
  }

  const dimensions = {
    height: parseInt(m.metadata && m.metadata.height ? m.metadata.height : 0, 10),
    width: parseInt(m.metadata && m.metadata.width ? m.metadata.width : 0, 10)
  };

  if (imageHtml) {
    if (dimensions.height && dimensions.width) {
      const image = new Image();
      image.src = m.location;
      await onloadPromise(image);
      if (type === 'graphic') {
        return imageHtml.replace(new RegExp('%DIMENSIONS%', 'g'), `max-width: ${image.naturalWidth}px`);
      } else {
        return imageHtml.replace(new RegExp('%DIMENSION_HEIGHT%', 'g'), '').replace(new RegExp('%DIMENSION_WIDTH%', 'g'), '');
      }
    } else {
      return imageHtml;
    }
  } else {
    throw new Error('Invalid Image HTML');
  }
}

// If this pattern useful elsewhere should be moved into its own helper module
interface Onloadable {
  onload: ((this: HTMLElement, ev: Event) => unknown) | null;
}

function onloadPromise<T extends Onloadable>(obj: T): Promise<T> {
  return new Promise((resolve) => {
    obj.onload = () => resolve(obj);
  });
}

export default {
  getScaledHTML: getScaledHTML
};
