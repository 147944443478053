import * as React from 'react';
import { MenuActionItemProps } from '../../EditorMenu/MenuActionItem';
import MenuActionItemContent from '../../EditorMenu/MenuActionItemContent';
import DropDown, { Choice } from '../../../sidetabs/sub/editComponent/controls/DropDown';
import ToolbarMenu from '../../ToolbarMenu';
import Color from '../../../sidetabs/sub/editComponent/controls/color/Color';
import IconButton from 'material-ui/IconButton';
import SvgIcon from 'material-ui/SvgIcon';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { MenuAction } from './defaultMenuContents';
import { TableUtils } from '../../../../../utils';

interface Props {
  actions: MenuAction[];
  onAction: MenuActionItemProps['onAction'];
  isEditingFocused: boolean;
  disabled: boolean;
  currentTextFormats: string[];
}

interface FormatStyles {
  selected: React.CSSProperties;
  normal: React.CSSProperties;
}

const formatStyles: FormatStyles = {
  normal: {
    background: 'none',
    width: '2em',
    height: '2em',
    lineHeight: '2em'
  },
  selected: {
    borderRadius: '2px',
    backgroundImage: 'linear-gradient(to bottom, #daf1fb , #c4e6f5 )',
    boxShadow: 'inset 0 1px 2px 0 rgba(168, 168, 168, 0.5)',
    border: 'solid 0.5px #00bcd4',
    width: '2em',
    height: '2em',
    lineHeight: '2em'
  }
};

const getFontOptions = (): Choice[] => {
  const label = <span className="material-icons icon-text-size" />;
  const sizes = ['Label', 'Clear', 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24];

  return sizes.map(function (item: string | number) {
    if (['Label', 'Clear'].indexOf(item as string) === -1) {
      return { payload: item + 'pt', text: item };
    } else {
      const itemText = item === 'Label' ? label : item;
      return { payload: item, text: itemText };
    }
  }) as Choice[];
};

const getFontChoice = (): number => {
  let choice = 0;
  if (EditorStore.getEditor().isFocused()) {
    const editorFacade = EditorStore.getEditor().getActiveEditorFacade()!;

    const raw = editorFacade.getCommonFontSize(TableUtils.getSelectedCells());
    choice = getFontOptions()
      .map(function (fontSize) {
        return fontSize.payload;
      })
      .indexOf(raw);
  }
  return choice === -1 ? 0 : choice;
};

const getTextFormatsOptions = (): { label: string; value: string }[] => {
  return [
    { label: 'Capitalize', value: 'capitalize' },
    { label: 'UPPERCASE', value: 'uppercase' },
    { label: 'lowercase', value: 'lowercase' }
  ];
};

const applyFontChoice = (menuItem: { text: string; payload: string }) => {
  const clearFontSize = function () {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand('arcClearFontSize', null);
  };

  if (menuItem.payload === 'Clear') {
    clearFontSize();
  } else if (menuItem.payload !== 'Label') {
    EditorStore.getEditor().getActiveEditorFacade()!.execCommand('arcFontSize', menuItem.payload);
  }

  EditorStore.getEditor().getActiveEditorFacade()!.clearSelection();
};

const deselectCurrentTextTransforms = () => {
  const formats = EditorStore.getEditor().getActiveEditorFacade()?.getActiveFormats() ?? [];
  formats.forEach((format) => {
    EditorStore.getEditor().getActiveEditorFacade()?.execCommand('arcTextTransform', format);
  });
};

const applyTextTransform = (action: string) => {
  if (action) {
    deselectCurrentTextTransforms();
    EditorStore.getEditor().getActiveEditorFacade()?.execCommand('arcTextTransform', action);
  }
  EditorStore.getEditor().getActiveEditorFacade()?.clearSelection();
};

const TextActionItems = ({ actions, onAction, isEditingFocused, disabled, currentTextFormats }: Props) => {
  const fontOptions = getFontOptions();
  return (
    <>
      {actions.map((menuItem, menuItemIndex) => {
        if (menuItem.action === 'arcFontSize') {
          return (
            <MenuActionItemContent key={menuItemIndex} action={menuItem} onAction={onAction} enabled={menuItem.enabled}>
              <DropDown
                editing={isEditingFocused}
                choices={fontOptions}
                currentchoice={getFontChoice}
                applychoice={applyFontChoice}
                rootClass="dropdownfontsize"
              />
            </MenuActionItemContent>
          );
        } else if (menuItem.action === 'arcTextTransform') {
          return (
            <MenuActionItemContent
              key={menuItemIndex}
              action={menuItem}
              onAction={onAction}
              enabled={menuItem.enabled}
              style={{ display: 'inline-flex' }}
            >
              <ToolbarMenu
                title="Text Transform"
                onSelect={applyTextTransform}
                popoverClassname="text-transform-popover"
                selected={currentTextFormats}
                options={getTextFormatsOptions()}
                className="text-transform-dropdown"
              >
                <div>
                  <IconButton disabled={disabled} iconClassName="material-icons icon-text-transform" tooltip="Text Transform" />
                  <SvgIcon>
                    <path d="M7 10l5 5 5-5z" />
                  </SvgIcon>
                </div>
              </ToolbarMenu>
            </MenuActionItemContent>
          );
        } else if (menuItem.action === 'forecolor') {
          return (
            <MenuActionItemContent
              key={menuItemIndex}
              action={menuItem}
              onAction={onAction}
              enabled={menuItem.enabled}
              style={{ marginTop: '-2px' }}
            >
              <Color tooltip={menuItem.tooltip} icon={menuItem.icon} cmd={menuItem.action} style="color" />
            </MenuActionItemContent>
          );
        } else if (menuItem.action === 'hilitecolor') {
          return (
            <MenuActionItemContent
              key={menuItemIndex}
              action={menuItem}
              onAction={onAction}
              enabled={menuItem.enabled}
              style={{ marginTop: '-2px' }}
            >
              <Color tooltip={menuItem.tooltip} icon={menuItem.icon} cmd={menuItem.action} style="backgroundColor" />
            </MenuActionItemContent>
          );
        } else if (menuItem.icon.startsWith('icon-') && !['forecolor', 'hilitecolor', 'arcFontSize'].includes(menuItem.action)) {
          return (
            <MenuActionItemContent
              key={menuItemIndex}
              action={menuItem}
              onAction={onAction}
              enabled={menuItem.enabled}
              style={{ marginTop: '-3px' }}
            >
              <IconButton
                data-qa={menuItem.active ? 'selected' : 'not-selected'}
                disabled={!menuItem.enabled}
                iconClassName={'material-icons ' + menuItem.icon}
                tooltipPosition="bottom-center"
                tooltip={menuItem.tooltip}
                iconStyle={menuItem.active ? formatStyles.selected : formatStyles.normal}
              />
            </MenuActionItemContent>
          );
        } else if (!menuItem.icon.startsWith('icon-') && !['forecolor', 'hilitecolor', 'arcFontSize'].includes(menuItem.action)) {
          return (
            <MenuActionItemContent
              key={menuItemIndex}
              action={menuItem}
              onAction={onAction}
              enabled={menuItem.enabled}
              style={{ marginTop: '0px' }}
            >
              <IconButton
                data-qa={menuItem.active ? 'selected' : 'not-selected'}
                disabled={!menuItem.enabled}
                iconClassName="material-icons"
                tooltipPosition="bottom-center"
                tooltip={menuItem.tooltip}
                iconStyle={menuItem.active ? formatStyles.selected : formatStyles.normal}
              >
                {menuItem.icon}
              </IconButton>
            </MenuActionItemContent>
          );
        }
      })}
    </>
  );
};

export default TextActionItems;
