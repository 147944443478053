import * as React from 'react';
import { IExternalRevisions, INotification, IProject } from 'mm-types';
import ErrorMessage from '../../general/ErrorMessage';
import NotificationsStore from '../../../flux/events/NotificationsStore';
import config from '../../../utils/config';
import QueryUtil from '../../../utils/QueryUtil';
import NotificationUtil from '../../../utils/NotificationUtil';
import { useFindNotificationsApi } from '../../../clients/hooks/notifications/useFindNotificationsApi';
import SystemStore from '../../../flux/common/SystemStore';
import ExternalRevisionDraft from './externalRevisions/ExternalRevisionDraft';
import ExternalRevisionEntry from './externalRevisions/ExternalRevisionEntry';
import { AeroIconButtonDownload, AeroIconButtonPreview } from '../../general/AeroIconButton';
import InProgress from './InProgress';
import { dateUtil } from '../../../utils';
import ProjectPropsStore from '../../../flux/projects/ProjectPropsStore';
import useListenToStore from '../../hooks/useListenToStore';

interface Props {
  revisions: IExternalRevisions | null;
  projectUid: string;
  canDownload: boolean;
  project: IProject;
}

interface PublishState {
  inProgress: boolean;
  error: boolean;
  previewDisabled: boolean;
}

const initialPublishState: PublishState = {
  inProgress: false,
  error: false,
  previewDisabled: false
};

const formatDateShort = (date: string): string => {
  return dateUtil(date).formatDate();
};

const formatDateLong = (date: string): string => {
  return dateUtil(date).formatDateTime(true);
};

const ExternalRevisions = ({ revisions, projectUid, canDownload, project }: Props) => {
  const [expandDraft, setExpandDraft] = React.useState<boolean>(false);
  const [publishState, setPublishState] = React.useState<PublishState>(initialPublishState);
  const { isFetching, response } = useFindNotificationsApi({
    projectUid: projectUid,
    type: 'EXT_AEROSYNC_PREVIEW',
    userUid: SystemStore.getCurrentUserUid()
  });

  useListenToStore({ store: NotificationsStore, eventListener: updateStateFromNotificationStore, update: [revisions] });

  React.useEffect(() => {
    if (revisions) {
      setPublishState({
        ...publishState,
        previewDisabled: isPreviewDisabled(publishState.inProgress, publishState.error)
      });
    }
  }, [revisions]);

  React.useEffect(() => {
    setPublishState({
      ...initialPublishState,
      previewDisabled: isPreviewDisabled(initialPublishState.inProgress, initialPublishState.error)
    });
    if (response) {
      const latestExtNotif = response?.notifications?.length ? response.notifications[0] : undefined;
      if (latestExtNotif) {
        setExternalProjectStatus(latestExtNotif);
      }
    }
  }, [response, projectUid]);

  async function updateStateFromNotificationStore() {
    const notifications = NotificationsStore.getInitialState().notifications;
    const latestExtNotif = notifications.length && isNotifOfExternalPreviewType(notifications[0]) ? notifications[0] : null;
    if (latestExtNotif) {
      setExternalProjectStatus(latestExtNotif);
    }
  }

  const toggleDraftUploadList = () => {
    revisions && revisions.drafts.length === 0 ? setExpandDraft(false) : setExpandDraft(!expandDraft);
  };

  const hasDrafts = (): boolean => {
    return !!revisions && !!revisions.drafts;
  };

  const hasRevisions = (): boolean => {
    return !!revisions && !!revisions.revisions;
  };

  const isNotifOfExternalPreviewType = (notif: INotification): boolean => {
    return NotificationUtil.isAppliedToProject(notif, projectUid) && NotificationUtil.isOfType(notif, 'EXT_AEROSYNC_PREVIEW');
  };

  const setExternalProjectStatus = (notif: INotification) => {
    if (NotificationUtil.isAppliedToProject(notif, projectUid)) {
      const error = NotificationUtil.isCurrentStepOfType(notif, 'FAILED_PUBLICATION');
      const inProgress = NotificationUtil.isInProgress(notif);
      if (NotificationUtil.isOfType(notif, 'EXT_AEROSYNC_PREVIEW')) {
        ProjectPropsStore.clearAndGetRevisions(project);
      }
      setPublishState({
        error,
        inProgress,
        previewDisabled: isPreviewDisabled(inProgress, error)
      });
    }
  };

  const isPreviewDisabled = (publishingInProgress: boolean, publishingError: boolean): boolean => {
    return (
      !revisions ||
      !canDownload ||
      publishingInProgress ||
      publishingError ||
      revisions?.manualUid === 'undefined' ||
      revisions?.drafts?.length === 0
    );
  };

  const showPreview = () => {
    const manualUid = revisions?.manualUid;
    const revision = revisions?.latestRevision;

    if (!manualUid || !manualUid.length || !revision || !revision.length) {
      return;
    }
    window.open(`${config.readerUrl}documents/${manualUid}?revision=${revision}-PREVIEW`, '_blank');
  };

  const downloadLatestDraft = () => {
    download();
  };

  const downloadRevisionByRevId = (revId: string) => {
    download({ revId });
  };

  const downloadRevisionByIndexUid = (indexUid: string) => {
    download({ indexUid });
  };

  const download = (params?: { revId?: string; indexUid?: string }) => {
    window.open(QueryUtil.getUrlWithParams(`${config.apiRoot}/projects/${projectUid}/download`, params));
  };

  return (
    <>
      {publishState.error && <ErrorMessage style={{ marginBottom: 16, marginRight: 16 }}>External Document Publish Error</ErrorMessage>}
      <ul className="document-info-revisions-list" data-qa="revisions-list">
        {isFetching ? (
          <InProgress />
        ) : (
          (publishState.inProgress || !publishState.previewDisabled) && (
            <li>
              <div>
                <label
                  className="draftRev"
                  onClick={() => {
                    toggleDraftUploadList();
                  }}
                >
                  Draft Revision
                </label>
                <span>{revisions && formatDateShort(revisions.lastDraft)}</span>
                <AeroIconButtonDownload
                  style={{ marginTop: '-6px' }}
                  id="download"
                  onClick={downloadLatestDraft}
                  tooltip="Download"
                  tooltipPosition="top-left"
                />

                <AeroIconButtonPreview
                  disabled={publishState.inProgress}
                  style={{ marginTop: '-6px' }}
                  id="preview"
                  onClick={showPreview}
                  tooltip="Preview"
                  tooltipPosition="top-right"
                  className={publishState.inProgress ? 'icon-disabled' : ''}
                />

                {publishState.inProgress && <InProgress dataQa="revision-progress-bar" />}
              </div>
            </li>
          )
        )}
        {expandDraft && (
          <React.Fragment>
            <li>
              <div>
                <hr />
                <label className="draftEntry draftEntryHeader">Upload title </label>
                <label className="draftEntryHeader">Date </label>
              </div>
            </li>
            {revisions &&
              hasDrafts() &&
              revisions.drafts.map((draft, draftIndex) => {
                return (
                  <ExternalRevisionDraft
                    key={draftIndex}
                    fileName={draft.fileName}
                    canDownload={canDownload}
                    created={formatDateLong(draft.created)}
                    onDownloadClick={() => downloadRevisionByRevId(draft.id)}
                  />
                );
              })}
          </React.Fragment>
        )}
        {revisions &&
          hasRevisions() &&
          revisions.revisions.map((revision, revisionIndex) => {
            return (
              <ExternalRevisionEntry
                key={revisionIndex}
                revision={revision}
                canDownload={canDownload}
                downloadRevisionByIndexUid={(indexUid) => downloadRevisionByIndexUid(indexUid)}
                downloadRevisionByRevId={(revUid) => downloadRevisionByRevId(revUid)}
              />
            );
          })}
      </ul>
    </>
  );
};

export default ExternalRevisions;
