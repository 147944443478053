import * as React from 'react';
import { IUnit } from 'mm-types';
import Popover from 'material-ui/Popover';
import { FacetTagDropDown } from '../sub/FacetTagDropdown';
import { SvgDropdownIcon } from '../../../misc/icons/SvgDropdownIcon';

export type Props = {
  unit: IUnit;
  activeTagCount: number;
  inActiveTagCount: number;
  onSelect: (e: React.MouseEvent<HTMLElement>) => void;
};

const TagMenu = ({ unit, activeTagCount = 0, inActiveTagCount = 0, onSelect }: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [zoom, setZoom] = React.useState<number>(1);
  const [anchorEl, setAchorEl] = React.useState<Element>();

  const openDropdown = (e) => {
    onSelect(e);

    // We need to find out if there is a zoom applied to the page as the popup will be appended to the end of the document
    if (e.currentTarget.parentElement.parentElement.parentElement.className.includes('editing-zoom-125')) {
      setZoom(1.25);
    } else if (e.currentTarget.parentElement.parentElement.parentElement.className.includes('editing-zoom-75')) {
      setZoom(0.75);
    } else {
      setZoom(1);
    }
    setIsOpen(true);
    setAchorEl(e.currentTarget.parentElement.parentElement);
  };

  let activetagCount, primaryColor, secondaryColor;
  if (activeTagCount) {
    activetagCount = activeTagCount;
    primaryColor = '#329DE7';
    secondaryColor = '#FFF';
  } else if (!activeTagCount && inActiveTagCount) {
    activetagCount = '';
    primaryColor = '#FFF';
    secondaryColor = '#329DE7';
  }

  return (
    <div className={`du-inline-item du-inline-dropdown du-inline-tags ${isOpen ? 'force-display' : ''}`} onClick={(e) => openDropdown(e)}>
      <SvgDropdownIcon />
      <svg
        className={`tag-counter${!activeTagCount ? ' inactive-tags' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="14"
        viewBox="-1 0 24 14"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill={primaryColor}
            stroke="#329DE7"
            strokeWidth="1.04px"
            fillRule="nonzero"
            d="M17.7271111,0.84 C17.3751111,0.33 16.7884444,0 16.1333333,0 L1.95555556,0.01 C0.88,0.01 0,0.9 0,2 L0,12 C0,13.1 0.88,13.99 1.95555556,13.99 L16.1333333,14 C16.7884444,14 17.3751111,13.67 17.7271111,13.16 L22,7 L17.7271111,0.84 Z"
          />
          <text fill={secondaryColor} fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400">
            <tspan x="9" y="11.5" textAnchor="middle">
              {activetagCount}
            </tspan>
          </text>
        </g>
      </svg>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        onRequestClose={() => setIsOpen(false)}
        animated={true}
        style={{ zoom: zoom }}
        className={'facet-dropdown-container'}
      >
        <FacetTagDropDown unit={unit} onClose={() => setIsOpen(false)} />
      </Popover>
    </div>
  );
};

export { TagMenu };
