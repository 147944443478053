import * as React from 'react';
import { useEffect, useState } from 'react';
import MediaStore from '../../../flux/editor/MediaStore';
import Search from '../../misc/Search';
import { MenuItem, SelectField } from 'material-ui';
import { MediaSort } from '../../../clients/media';
import { MediaFilterType, MediaTypesContants } from './types';

export type Props = {
  showControls: boolean;
  filterType: MediaFilterType;
  hasSymbols: boolean;
};

export type Media = {
  uid: string;
  caption: string;
  description: string;
  filename: string;
  location: string;
  type: string;
};

type TypeOptions = { text: string; payload: MediaFilterType }[];

export type State = {
  query: string;
  sortBy: MediaSort;
  mediaTypes: MediaFilterType;
  typeOptions: TypeOptions;
};

const sortByOptions: { text: string; payload: MediaSort }[] = [
  { text: 'Most recent', payload: 'created' },
  { text: 'Title (A-Z)', payload: 'filename,ASC' },
  { text: 'Title (Z-A)', payload: 'filename,DESC' }
];

const MEDIA_FILTER_TYPE_OPTIONS: TypeOptions = [
  { text: 'All', payload: MediaTypesContants.all },
  { text: 'Image', payload: MediaTypesContants.image },
  { text: 'Video', payload: MediaTypesContants.video },
  { text: 'Symbol', payload: MediaTypesContants.symbol },
  { text: 'Audio', payload: MediaTypesContants.audio },
  { text: 'Other', payload: MediaTypesContants.other }
];

const MediaLibHeader = (props: Props) => {
  let filterTimeout: number;
  const [state, setState] = useState<State>({
    query: MediaStore.getInitialState().searchParams.query,
    sortBy: MediaStore.getInitialState().searchParams.sort,
    mediaTypes: MediaStore.getInitialState().searchParams.type,
    typeOptions: MEDIA_FILTER_TYPE_OPTIONS
  });

  useEffect(() => {
    initialiseTypeOptions();
  }, []);

  useEffect(() => {
    MediaStore.filterMedia(state.query);
  }, [state.query]);

  useEffect(() => {
    MediaStore.sortMedia(state.sortBy);
  }, [state.sortBy]);

  useEffect(() => {
    MediaStore.changeType(state.mediaTypes);
  }, [state.mediaTypes]);

  const initialiseTypeOptions = () => {
    let typeOptions: TypeOptions = state.typeOptions;
    typeOptions = typeOptions.filter((option) => {
      return MediaStore.getMediaFilterTypes().indexOf(option.payload) !== -1;
    });
    setState((prevState) => ({ ...prevState, typeOptions }));
  };

  const performFilter = (query: string) => {
    if (filterTimeout) {
      clearTimeout(filterTimeout);
    }
    filterTimeout = window.setTimeout(() => {
      setState((prevState) => ({ ...prevState, query }));
    }, 250);
  };

  const sortByChange = (value: MediaSort) => {
    setState((prevState) => ({ ...prevState, sortBy: value }));
  };

  const mediaTypesChange = (value: MediaFilterType) => {
    setState((prevState) => ({ ...prevState, mediaTypes: value }));
  };

  return (
    <nav className="page-body-header icon-header">
      <div className="nav-wrapper medialib-nav-wrapper">
        <div className="medialib-nav-header">
          <h1>Media Library</h1>
        </div>
        {props.showControls && (
          <div className="medialib-nav-filter-container">
            <Search onChange={(e) => performFilter(e)} placeholder="Filter Media" defaultValue={state.query} />
            <div className="select-field sort-order-select">
              <SelectField value={state.sortBy} id="sort-order-dropdown" onChange={(e, index, value) => sortByChange(value)}>
                {sortByOptions.map((item) => {
                  return <MenuItem key={item.payload} id={item.payload.replace(',', '-')} value={item.payload} primaryText={item.text} />;
                })}
              </SelectField>
            </div>
            {props.filterType === MediaTypesContants.all && (
              <div className="select-field sort-order-select">
                <SelectField id="sort-order-type-dropdown" value={state.mediaTypes} onChange={(e, index, value) => mediaTypesChange(value)}>
                  {state.typeOptions.map((item) => {
                    return <MenuItem key={item.payload} id={item.payload} value={item.payload} primaryText={item.text} />;
                  })}
                </SelectField>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default MediaLibHeader;
