import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import { default as PopupMenu, Options, Option } from '../../../../misc/PopupMenu';
import EditorStore from '../../../../../flux/editor/EditorStore';
import TocStore, { ActionsType } from '../../../../../flux/editor/TocStore';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import { UnitTypes } from '../../../utils/units/UnitTypes';

export type Props = {
  maxDepth: number;
  onToggleSideBar: () => void;
  onTocManipulation: (type: ActionsType) => void;
  onToggleEditMode: () => void;
  onSetDepth: (depth: string) => void;
  editMode: boolean;
  isBusy: boolean;
  selectedNodeType: UnitTypes | null;
};

export type State = {
  disablePromote: boolean;
  disableDemote: boolean;
  disableDelete: boolean;
  disableDuplicate: boolean;
};

const DEFAULT_STATE = {
  disablePromote: false,
  disableDemote: false,
  disableDelete: false,
  disableDuplicate: false
};

const TocFooter = (props: Props) => {
  const [state, setState] = React.useState<State>(DEFAULT_STATE);
  const depthMenu = React.useRef<PopupMenu>(null);
  const displayOptionsBtn = React.useRef(null);

  const _depthOptions: string[] = [
    'Section',
    'Header level 1',
    'Header level 2',
    'Header level 3',
    'Header level 4',
    'Header level 5',
    'Header level 6'
  ];

  React.useEffect(() => {
    if (props.selectedNodeType?.toLowerCase() == 'frontmatter' || props.selectedNodeType?.toLowerCase() == 'chapter-frontmatter') {
      setState({ disablePromote: true, disableDemote: true, disableDelete: true, disableDuplicate: true });
    } else {
      setState(DEFAULT_STATE);
    }
  }, [props.selectedNodeType]);

  const setDepth = (depth: string) => {
    depthMenu.current?.close();
    props.onSetDepth(depth);
  };

  const onOpenTocDepthMenu = () => {
    depthMenu.current?.setTriggerButton(displayOptionsBtn.current!).open();
  };

  const renderEditMode = () => {
    const currentTocDefId = TocStore.getSelectedItem()?.definitionId;
    const unitDef = ProjectDefinitionStore.getAllUnitDefinitions().find((ud) => ud.id === currentTocDefId);
    const promotionEnabled =
      ProjectDefinitionStore.getCurrentIndexDefinition()?.allowPromotionDemotion !== false &&
      !state.disablePromote &&
      unitDef?.canBePromoted !== false;
    const demotionEnabled =
      ProjectDefinitionStore.getCurrentIndexDefinition()?.allowPromotionDemotion !== false &&
      !state.disableDemote &&
      unitDef?.canBeDemoted !== false;

    return (
      <div className={`col s12 side-container-content-footer ${props.isBusy ? ' toc-busy' : ''}`} style={{ paddingLeft: '20px' }}>
        <i
          title="Duplicate"
          onClick={() => (state.disableDuplicate ? null : props.onTocManipulation('copy'))}
          className={`tiny material-icons leftaligned ${state.disableDuplicate ? 'disabled' : ''}`}
        >
          library_add
        </i>
        <i
          title="Demote"
          onClick={() => (demotionEnabled ? props.onTocManipulation('demote') : null)}
          className={`tiny material-icons leftaligned ${demotionEnabled ? '' : 'disabled'}`}
        >
          format_indent_increase
        </i>
        <i
          title="Promote"
          onClick={() => (promotionEnabled ? props.onTocManipulation('promote') : null)}
          className={`tiny material-icons leftaligned ${promotionEnabled ? '' : 'disabled'}`}
        >
          format_indent_decrease
        </i>
        <i
          title="Delete"
          onClick={() => (state.disableDelete ? null : props.onTocManipulation('delete'))}
          className={`tiny material-icons leftaligned ${state.disableDelete ? 'disabled' : ''}`}
        >
          delete
        </i>
        <FlatButton
          label="close"
          secondary={true}
          style={{ backgroundColor: '#e7e7e7', float: 'right' }}
          onClick={() => props.onToggleEditMode()}
        />
      </div>
    );
  };

  const renderNormalMode = () => {
    let tocEdit: JSX.Element | null = (
      <i onClick={() => props.onToggleEditMode()} className="tiny material-icons">
        edit
      </i>
    );
    if (EditorStore.isReadOnly()) {
      tocEdit = null;
    }

    return (
      <div className={`col s12 side-container-content-footer ${props.isBusy ? ' toc-busy' : ''}`}>
        <i onClick={() => props.onToggleSideBar()} className="tiny material-icons mirrored">
          slideshow
        </i>
        {tocEdit}
        <i onClick={() => onOpenTocDepthMenu()} className="icon-display-options tiny material-icons" ref={displayOptionsBtn}>
          remove_red_eye
        </i>
        <PopupMenu ref={depthMenu} className="display-options-popup" onSelect={(e) => setDepth(e)}>
          <Options>
            {_depthOptions.map((o, i) => (
              <Option dataKey={i.toString()} key={o} hasCheck={props.maxDepth === i}>
                {o}
              </Option>
            ))}
          </Options>
        </PopupMenu>
      </div>
    );
  };

  return props.editMode ? renderEditMode() : renderNormalMode();
};

export default TocFooter;
