type DefferedReducerMiddleware<T> = (data: T[]) => T;
interface DefferedReducerSubscriber<T, K> {
  next(data: T, options?: K): void;
  complete?(data: T): void;
}

interface IDefferedReducer<T, K> {
  next(data: T, options?: K): void;
  subscribe(fn: DefferedReducerSubscriber<T, K>, emitLastValue: boolean): () => void;
}

export class DefferedReducer<T, K> implements IDefferedReducer<T, K> {
  private queue: T[] = [];
  private lastValue?: T;
  private lastOptions?: K;
  private middleware: DefferedReducerMiddleware<T>;
  private defferTime: number;
  private defferFn: any;
  private subscribers: DefferedReducerSubscriber<T, K>[] = [];
  public pending = false;

  constructor(middleware: DefferedReducerMiddleware<T>, defferTime: number) {
    this.middleware = middleware;
    this.defferTime = defferTime;
  }

  next(value: T, options?: K) {
    this.queue.unshift(value);
    this.applyMiddleware(options);
  }

  subscribe(subscriber: DefferedReducerSubscriber<T, K>, emitLastValue = false): () => void {
    this.subscribers.push(subscriber);
    if (emitLastValue && !!this.lastValue) {
      subscriber.next(this.lastValue, this.lastOptions);
    }
    return () => {
      const index = this.subscribers.indexOf(subscriber);
      if (index !== -1) {
        if (this.pending && subscriber.complete) {
          subscriber.complete(this.middleware(this.queue));
        }
        this.subscribers.splice(index, 1);
      }
    };
  }

  private applyMiddleware(options?: K) {
    if (this.defferFn) {
      clearTimeout(this.defferFn);
      this.defferFn = null;
    }
    this.pending = true;
    this.defferFn = setTimeout(() => {
      this.emitValue(this.middleware(this.queue), options);
      this.pending = false;
      this.queue = [];
    }, this.defferTime);
  }

  private emitValue(value: T, options?: K) {
    this.subscribers.forEach((subscriber) => {
      subscriber.next(value, options);
    });
    this.lastValue = value;
    this.lastOptions = options;
  }
}
