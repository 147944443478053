import * as _ from 'lodash';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import Numbering from './components/Numbering';
import SpecialInterestTags from './components/SpecialInterestTags';
import VariantProperties from './components/VariantProperties';
import MultiFormatting from './components/MultiFormatting';
import MetaData from './components/MetaData';
import RepeaterHeadingEditProps from '../editComponent/components/RepeaterHeadingEditProps';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { IDataAttribute, IDefinition, IUnit } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import DataAttributesProps from './components/DataAttributeProps';

export type PropType = {
  title: string;
  id: string;
  enabled: boolean;
  sort_index?: number;
  Component: any;
  componentProps?: {
    withinToggleSection?: string;
    unit?: IUnit;
    units?: IUnit[];
    dataAttributes?: IDataAttribute[];
    targetElement?: HTMLElement;
    definition?: IDefinition;
  };
};

export const selectedUnitPropertiesMap = (): PropType[] => {
  const unit = EditorStore.getSelectedUnit()!;
  const units = EditorStore.getSelectedUnits();
  const dataAttributesProperties: PropType[] = dataAttributesPropertiesMap();

  const selectedUnitProperties: PropType[] = [
    {
      id: 'numbering',
      title: 'Numbering',
      enabled: true,
      sort_index: 1,
      Component: Numbering,
      componentProps: {
        units: units
      }
    },
    {
      id: 'specialInterest',
      title: 'Special Interest Tags',
      enabled: !ProjectStore.isInterim(),
      sort_index: 2,
      Component: SpecialInterestTags,
      componentProps: {
        units: units
      }
    },
    {
      id: 'metadata',
      title: 'Metadata',
      enabled: true,
      sort_index: 4,
      Component: MetaData,
      componentProps: {
        unit: unit
      }
    },
    {
      id: 'formatting',
      title: 'Formatting',
      enabled: true,
      sort_index: 5,
      Component: MultiFormatting
    },
    {
      id: 'repeater-heading',
      title: 'Repeater Properties',
      enabled: true,
      sort_index: 6,
      Component: RepeaterHeadingEditProps,
      componentProps: {
        unit: unit
      }
    },
    {
      id: 'variants',
      title: 'Variants',
      enabled: true,
      sort_index: 7,
      Component: VariantProperties,
      componentProps: {
        units: units
      }
    }
  ];
  const arrObj = [...selectedUnitProperties, ...dataAttributesProperties];
  return _.sortBy(arrObj, 'sort_index');
};

const dataAttributesPropertiesMap = (): PropType[] => {
  const units = EditorStore.getSelectedUnits()!;
  if (!!units && units[0]?.definitionId) {
    const projectDefinedUnit = ProjectDefinitionStore.getProjectDefinedUnitById(units[0].definitionId);
    let dataAttributes = projectDefinedUnit?.dataAttributes;
    const groupedDataAtttributes = _.groupBy(dataAttributes, (dataAttributes) => dataAttributes?.withinToggleSection);

    const parser = new DOMParser();
    let newUnitHtml = parser.parseFromString(units[0].html, 'text/html');

    let dataAttributesPropertiesMap: PropType[] = [];
    Object.keys(groupedDataAtttributes).forEach((dataAttributeToggle, index) => {
      if (dataAttributeToggle !== null) {
        const sort_index = dataAttributeToggle == 'Print Output' ? 3 : undefined;
        const dataAttributes = getDataAttributes(groupedDataAtttributes, dataAttributeToggle, units);
        dataAttributesPropertiesMap.push({
          id: _.camelCase(dataAttributeToggle),
          title: dataAttributeToggle,
          enabled: true,
          sort_index: sort_index,
          Component: DataAttributesProps,
          componentProps: {
            withinToggleSection: dataAttributeToggle,
            dataAttributes: dataAttributes,
            targetElement: newUnitHtml.querySelector(`.arc-unit`) as HTMLElement,
            definition: projectDefinedUnit,
            units: units
          }
        });
      }
    });
    return dataAttributesPropertiesMap;
  } else {
    return [];
  }
};

const getDataAttributes = (groupedDataAtttributes: { [key: string]: any }, dataAttributeToggle: string, units: IUnit[]) => {
  let computedDataAttributes = groupedDataAtttributes[dataAttributeToggle];
  if (dataAttributeToggle == 'Print Output') {
    if (
      units &&
      units[0].canShowPdfSetting &&
      computedDataAttributes.some((el) => el.id === 'pageType') &&
      computedDataAttributes.some((el) => el.id === 'pageSize')
    ) {
      const Note = {
        id: 'note',
        displayName: 'NOTE: Print Output setting will continue onto the end of the current chapter unless set otherwise.',
        type: 'label',
        withinToggleSection: 'Print Output'
      };
      computedDataAttributes.splice(2, 0, Note);
    }
  }
  return computedDataAttributes;
};
