import { Cancelled, mm } from './base-clients';
import { IMedia, IFileAttachment } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;
export const DEFAULT_PAGE_SIZE = 50;

export interface FileAttachmentParams {
  projectUid: string;
  indexUid: string;
}

export interface FileAttachmentResponse {
  media: IMedia[];
}

export async function getAll(data: FileAttachmentParams): Promise<IFileAttachment[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }
  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<FileAttachmentResponse>(`/projects/${data.projectUid}/indexes/${data.indexUid}/attachments`, {
      cancelToken: getAllSource.token
    });
    getAllSource = null;

    return response.data.media;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Removes a attachment
 */
export async function removeAttachment(data: FileAttachmentParams, uid: string | undefined) {
  const response = await mm.delete(`/projects/${data.projectUid}/indexes/${data.indexUid}/attachments/${uid}`, {});
  return response.data;
}

/**
 * Edits media by id
 */
export async function upload(
  searchData: FileAttachmentParams,
  file: Partial<IFileAttachment>,
  onProgress?: (progress: number) => void,
  onUploadStart?: (token: CancelTokenSource) => void
) {
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      console.log(percentCompleted);
      if (onProgress) {
        onProgress(percentCompleted);
      }
    }
  };

  const formData = new FormData();
  formData.set('filename', file.filename!);
  formData.set('visibility', file.visibility!);
  formData.set('description', file.description!);
  formData.set('mimeType', file.mimeType!);
  formData.set('content', file.content!);

  try {
    if (getAllSource && onUploadStart) {
      onUploadStart(getAllSource);
    }
    const response = await mm.post<IFileAttachment>(
      `/projects/${searchData.projectUid}/indexes/${searchData.indexUid}/attachments`,
      formData,
      config
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Edits media by id
 */
export async function set(searchData: FileAttachmentParams, attachment: Partial<IFileAttachment>, onProgress?: () => void) {
  if (onProgress) {
    onProgress();
  }
  const formData = new FormData();
  formData.set('indexUid', searchData.indexUid);
  formData.set('projectUid', searchData.projectUid);
  // formData.set( 'content', file );

  const response = await mm.post<IFileAttachment>(
    `/projects/${searchData.projectUid}/indexes/${searchData.indexUid}/attachments/${attachment.uid}`,
    attachment
  );
  return response.data;
}
