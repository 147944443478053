import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import TocStore from '../../../../../flux/editor/TocStore';
import tocStore, { ActionsType, ManipulationOptions, State as TocState, TocStoreEvent } from '../../../../../flux/editor/TocStore';
import TocTree from './TocTree';
import TocFooter from './TocFooter';
import EditorStore, { RenderOptions } from '../../../../../flux/editor/EditorStore';
import { EventStoreEventType, IEditorStoreEvent, IProject, ITocNode, IUnit } from 'mm-types';
import SmartContentStore from '../../../../../flux/editor/SmartContentStore';
import smartContentStore, { SmartContentStoreEvent } from '../../../../../flux/editor/SmartContentStore';
import UnitConceptStore, { UnitConceptStoreEvent } from '../../../../../flux/editor/UnitConceptStore';
import { fakeFrontmatterParentNode, flattenArray, getFlattenTocs, shouldOpenNewViewOnCreate, shouldOpenNewViewOnDelete } from './tocutils';
import { Dom } from '../../../utils/tinyFacade/DomUtil';
import { CircularProgressLoader } from '../../../../misc/Loaders';
import useListenToStore from '../../../../hooks/useListenToStore';
import appStore from '../../../../../appStore';
import { hideSystemAlert, showSystemAlert } from '../../../../misc/SystemAlert/thunks';
import * as client from '../../../../../clients/toc';
import FlatButton from 'material-ui/FlatButton';

export interface ITocNodeWithDepth extends ITocNode {
  $depth: number;
}

export type Props = {
  project: IProject | null;
  onToggleSidebar: () => void;
};

export type State = {
  editMode: boolean;
  maxDepth: number;
  openNodes: Set<string>;
  selected: ITocNode | null;
  expandTree?: Partial<IUnit> | null;
  scrollToTocUid?: string;
} & TocState;

const TocContainer = (props: Props) => {
  const [state, setState] = useState<State>({
    ...TocStore.getInitialState(),
    editMode: EditorStore.isMode('TOCMAN'),
    maxDepth: 6,
    openNodes: new Set<string>() // set of nodes that are collapsed open
  });

  useEffect(() => {
    return () => {
      // Not resetting EditorMode but unselecting TOCMAN mode.
      // In case there's a different mode turned on while TocContainer component is being unmounted.
      // We don't want to change any other mode than TOCMAN.
      if (EditorStore.isMode('TOCMAN')) {
        EditorStore.toggleMode('TOCMAN');
      }
    };
  }, []);

  useEffect(() => {
    if (state.expandTree?.uid) {
      expandParents(state.expandTree);
    }
  }, [state.expandTree?.uid]);

  useEffect(() => {
    Dom.scrollToElementAndHighlight(document.querySelector(`.toc-entry-container[data-uid="${state.scrollToTocUid}"]`));
  }, [state.scrollToTocUid]);

  useListenToStore({ store: UnitConceptStore, eventListener: onUnitConceptStoreUpdate, update: [state.tableOfContents] });
  useListenToStore({ store: TocStore, eventListener: onTocStoreUpdate });
  useListenToStore({ store: EditorStore, eventListener: onEditorStoreUpdate });
  useListenToStore({ store: SmartContentStore, eventListener: onSmartContentStoreUpdate });

  function onUnitConceptStoreUpdate(newState: UnitConceptStoreEvent) {
    // Listen to remove or add variant event
    let matchedNode: ITocNode | undefined;
    let nodes = getAllTocs();

    if (newState.type === 'variant-added' || newState.type === 'variant-removed') {
      const variantExists = newState.concept?.type === 'variant';
      let outsideArray: ITocNode[] = [];

      if (nodes[0]?.children.length) {
        nodes = flattenArray(nodes, outsideArray);
      }

      if (variantExists) {
        newState.unitUids?.forEach((uid) => {
          matchedNode = nodes.find((node) => uid === node.uid);

          // Refresh if variant unit is a tocable item or appendix
          if (matchedNode && (matchedNode.type === 'tocable' || matchedNode.type === 'appendix-chapter')) {
            TocStore.refreshToc({
              forceDocRefresh: false
            });
          }
        });
      }
    }
  }
  function onTocStoreUpdate(tocStoreEvent: TocStoreEvent) {
    if (tocStoreEvent.type === 'error') {
      setState((prevState) => ({ ...prevState, loading: false }));
    } else if (tocStoreEvent.type === 'tocWithSharedOriginDelete' && tocStoreEvent.shareOriginDeleteInfo) {
      openShareOriginDeleteModal(tocStoreEvent.shareOriginDeleteInfo);
    } else {
      const stateUpdate: Partial<State> = Object.assign({ openNodes: state.openNodes }, tocStoreEvent.data);
      if (stateUpdate.selected) {
        let current = stateUpdate.selected.parent;
        while (current) {
          stateUpdate.openNodes?.add(current.uid);
          current = current.parent;
        }
      }
      setState((prevState) => ({ ...prevState, ...stateUpdate, expandTree: tocStoreEvent.expandTree }));
    }
  }

  function onEditorStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'createUnit' || e.type === 'createBatchUnits') {
      const castEvent = e as IEditorStoreEvent<'createUnit' | 'createBatchUnits'>;

      if (castEvent.data?.unit?.isstructural) {
        const forceDocRefresh = shouldOpenNewViewOnCreate(castEvent.data.unit as IUnit);
        TocStore.refreshToc({
          selectUnit: forceDocRefresh ? castEvent.data?.unit : null,
          expandTree: !forceDocRefresh ? castEvent.data?.unit : null,
          forceDocRefresh
        });
      } else if (castEvent.data?.unit?.istocable) {
        TocStore.refreshToc({ selectUnit: castEvent.data?.unit, forceDocRefresh: false });
      }
    } else if (e.type === 'deleteUnitConfirm' || e.type === 'deleteSelectedUnitsConfirm') {
      const castEvent = e as IEditorStoreEvent<'deleteUnitConfirm' | 'deleteSelectedUnitsConfirm'>;

      // refresh toc, which will force reload of this doc at the appropriate place (which may be a new page)
      if (castEvent.data?.unit?.isstructural) {
        TocStore.refreshToc({
          removedUnit: castEvent.data?.unit,
          forceDocRefresh: shouldOpenNewViewOnDelete(castEvent.data?.unit as IUnit)
        });
      } else if (castEvent.data?.unit?.istocable) {
        TocStore.refreshToc({ removedUnit: castEvent.data?.unit, forceDocRefresh: false });
      }
    } else if (e.type === 'undoredo') {
      const castEvent = e as IEditorStoreEvent<'undoredo'>;
      shouldRefreshTocIfTocableUnit(castEvent.data);
    } else if (e.type === 'updateUnit') {
      const castEvent = e as IEditorStoreEvent<'updateUnit'>;
      if (castEvent.data?.unit?.isstructural || castEvent.data?.unit?.istocable) {
        TocStore.refreshToc({ forceDocRefresh: false });
      }
    } else if (e.type === 'changeModeComplete') {
      setState((prevState) => ({ ...prevState, editMode: EditorStore.isMode('TOCMAN') }));
    } else if (e.type === 'saving') {
      setState((prevState) => ({ ...prevState }));
    }
  }

  function onSmartContentStoreUpdate(event: SmartContentStoreEvent) {
    if (event.type === 'sharedUsageUpdateChange') {
      setState((prevState) => ({ ...prevState }));
    }
  }

  const openShareOriginDeleteModal = ({ projectUid, indexUid, tocableUnitUid, refreshOptions }) => {
    appStore.dispatch<any>(
      showSystemAlert({
        errorTitle: 'WARNING: This TOC contains shared origins',
        errorMessage: 'The content to be deleted contains Share Origins.',
        userMessage: 'Are you sure you wish to delete this TOC and associated Share Origins?',
        errorMessageDetails: (
          <span>
            If you wish to proceed, click <b>Delete</b>.
          </span>
        ),
        errorActions: [
          <FlatButton
            key={1}
            label="Cancel"
            className="error-ok"
            onClick={() => {
              tocStore.reloadAndNotifyUserOfTocManipulationEvent({ type: 'tocManipulation', data: tocStore.state } as TocStoreEvent, null);
              appStore.dispatch<any>(hideSystemAlert());
            }}
          />,
          <FlatButton
            primary={true}
            key={2}
            label="Delete"
            className="error-ok"
            onClick={async () => {
              await client.deleteOrdinableUnit(projectUid, indexUid, tocableUnitUid);
              tocStore.reloadAndNotifyUserOfTocManipulationEvent(
                { type: 'tocManipulation', data: tocStore.state } as TocStoreEvent,
                refreshOptions
              );
              smartContentStore.triggerContentRefresh({ indexUid, projectUid, documentIndexUid: null, tocableUnitUid });
              appStore.dispatch<any>(hideSystemAlert());
            }}
          />
        ]
      })
    );
  };

  const getAllTocs = (): ITocNode[] => {
    let nodes: ITocNode[] = [...state.tableOfContents];
    nodes = nodes.concat(state.appendices);
    nodes = nodes.concat(state.frontMatter);
    return nodes;
  };

  const expandParents = (unit: Partial<IUnit>) => {
    const toc = getFlattenTocs(getAllTocs(), 8).find((node) => node.uid === unit.uid);
    if (!!toc) {
      const openNodes = state.openNodes;
      let parent = toc.parent;
      let i = 0;

      while (parent && i < 50) {
        openNodes.add(parent.uid);
        parent = parent.parent;
        i++;
      }
      setState((prevState) => ({ ...prevState, openNodes, scrollToTocUid: unit.uid }));
    }
  };

  const shouldRefreshTocIfTocableUnit = (event?: { unit?: IUnit; renderBehaviour: RenderOptions }) => {
    if (event?.unit?.istocable) {
      const removedUnit = event.renderBehaviour.isDelete ? event.unit : null;
      const selectUnit = event.renderBehaviour.isDelete ? null : event.unit;
      const forceDocRefresh = shouldDocRefresh(event.unit, event.renderBehaviour);
      TocStore.refreshToc({ removedUnit, selectUnit, forceDocRefresh });
    }
  };

  const shouldDocRefresh = (unit: IUnit, { isCreate }) => {
    const isChapter = unit.level === 'chapter';
    const isSection = unit.level === 'section' && !isCreate;
    const isStructureChange = unit.previousLevel && unit.level && unit.previousLevel !== unit.level;
    const hasNoDocUnitModel = EditorStore.getDocUnitModel(unit.uid) === null;
    return isChapter || isSection || isStructureChange || hasNoDocUnitModel;
  };

  const isEditMode = () => {
    return state.editMode;
  };

  const toggleGlobal = () => {
    if (state.openNodes.size === 0) {
      const nodeUids = getFlattenTocs(getAllTocs()).map((c) => c.uid);
      setState((prevState) => ({ ...prevState, openNodes: new Set(nodeUids) }));
    } else {
      setState((prevState) => ({ ...prevState, openNodes: new Set() }));
    }
  };

  const makeTocTreeFrontmatterHeader = () => {
    return !state.movableFrontmatter && state.frontMatter?.length > 0 ? makeTocTree([fakeFrontmatterParentNode(state.frontMatter)]) : '';
  };

  const makeTocTree = (children) => {
    return (children || []).map((node) => {
      return (
        <TocTree
          key={node.uid}
          onSelect={(node) => onSelect(node)}
          onToggleTree={(node) => toggleTree(node)}
          onToggle={onToggleNode}
          level={1}
          editMode={isEditMode()}
          maxDepth={state.maxDepth + (TocStore.isSingleVolume() ? 1 : 2)}
          onTocManipulation={(type, options) => onTocManipulation(type, options)}
          openNodes={state.openNodes}
          selectedNodeUid={state.selected ? state.selected.uid : null}
          node={node}
        />
      );
    });
  };

  const onSelect = (node) => {
    TocStore.selectTocItem(node);
  };

  const toggleTree = (parent: ITocNode, depth = 7) => {
    const nodeUids = getFlattenTocs([parent], depth).map((c) => c.uid);
    let openNodes = state.openNodes;
    if (!state.openNodes.has(parent.uid)) {
      _.each(nodeUids, (nodeId) => openNodes.add(nodeId));
    } else {
      _.each(nodeUids, (nodeId) => openNodes.delete(nodeId));
    }
    setState((prevState) => ({ ...prevState, openNodes: openNodes }));
  };

  const onToggleNode = (node, e?: React.MouseEvent<HTMLElement>) => {
    const openNodes = state.openNodes;
    if (state.openNodes.has(node.uid)) {
      openNodes.delete(node.uid);
    } else {
      openNodes.add(node.uid);
    }
    setState((prevState) => ({ ...prevState, openNodes: openNodes }));

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onTocManipulation = (type: ActionsType, options?: ManipulationOptions) => {
    if (!state.loading && !EditorStore.isBusy()) {
      setState((prevState) => ({ ...prevState, loading: true }));
      TocStore.tocManipulation(type, options);
    }
  };

  const onToggleEditMode = () => {
    EditorStore.toggleMode('TOCMAN');
  };

  const setDepth = (d) => {
    setState((prevState) => ({ ...prevState, maxDepth: d }));
  };

  return (
    <div className={'mainaction-container tocs-mainaction ' + (isEditMode() ? 'editing' : '')}>
      <h5 onDoubleClick={() => toggleGlobal()}>{props.project ? props.project.name : ''}</h5>
      <div className="editing-tocs-list">
        {makeTocTreeFrontmatterHeader()}
        {makeTocTree(state.tableOfContents)}
        {makeTocTree(state.appendices)}
      </div>
      <TocFooter
        isBusy={state.loading! || EditorStore.isBusy()}
        onTocManipulation={(type) => onTocManipulation(type)}
        onToggleSideBar={() => props.onToggleSidebar()}
        onToggleEditMode={() => onToggleEditMode()}
        onSetDepth={(val) => setDepth(val)}
        maxDepth={state.maxDepth}
        editMode={isEditMode()}
        selectedNodeType={state.selected ? state.selected.type : null}
      />
      <CircularProgressLoader className={'toc-modal toc-modal-content'} visible={state.loading} />
    </div>
  );
};

export default TocContainer;
