import * as React from 'react';
import { FindNotificationsParams, NotificationResponse } from '../../notifications';
import * as notificationsClient from '../../notifications';
import axios from 'axios';
import { dispatchApiRequestFailure, dispatchApiRequestInit, dispatchApiRequestSuccess, responseReducer } from '../useApi';
import { ApiAction, ApiResponseState } from '../../../types/useApi';

export const useFindNotificationsApi = (params: FindNotificationsParams) => {
  const [state, dispatch] = React.useReducer<React.Reducer<ApiResponseState<NotificationResponse>, ApiAction<NotificationResponse>>>(
    responseReducer,
    {
      isError: false,
      isFetching: false
    }
  );

  React.useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    dispatchApiRequestInit(dispatch);
    notificationsClient
      .find(params, cancelTokenSource)
      .then(dispatchApiRequestSuccess.bind(this, dispatch, mounted))
      .catch(dispatchApiRequestFailure.bind(this, dispatch, mounted));

    return () => {
      mounted = false;
      cancelTokenSource.cancel('Cancelling in cleanup');
    };
  }, [params.projectUid]);

  return state;
};
