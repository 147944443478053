import { AlertPayload } from '../components/misc/SystemAlert/reducers';
import { transitionTo } from '../utils/Navigation';

const defaultMessage: AlertPayload = {
  errorTitle: 'Unknown Error',
  errorMessage: 'Unable to perform an action.',
  userMessage: 'Unknown error occured. Please refer to console output'
};

const messages = {
  40310: {
    errorTitle: 'Error',
    errorMessage: 'Unable to perform an action.'
  },
  40405: {
    errorTitle: 'Document not found',
    errorMessage: 'Unable to perform an action.',
    userMessage:
      'The document you are attempting to load cannot be found. Perhaps your url is invalid, or the document was removed by another user?',
    onClose: () => transitionTo('teamspaces/all')
  },
  40403: {
    errorTitle: 'Document not found',
    errorMessage: 'Unable to perform an action.',
    userMessage:
      'The document you are attempting to load cannot be found. Perhaps your url is invalid, or the document was removed by another user?',
    onClose: () => transitionTo('teamspaces/all')
  },
  40406: {
    errorTitle: 'Unit not found',
    errorMessage: 'Unable to perform an action.',
    userMessage: 'Linked Unit is not found in the document.'
  }
};

type FindMessageByErrorCodeProps = {
  errorCode: number;
  serverMessage?: string;
  onClose?: () => {};
};

const findMessageByErrorCode = ({ errorCode, serverMessage, onClose }: FindMessageByErrorCodeProps): AlertPayload => {
  const message = messages[errorCode] ?? defaultMessage;
  return { ...message, errorMessage: serverMessage || message.errorMessage };
};

export default findMessageByErrorCode;
