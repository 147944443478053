import * as React from 'react';
import { CircularProgress, FlatButton } from 'material-ui';
import DocumentList from '../general/document-list/DocumentList';
import NoRecentDocuments from './NoRecentDocuments';
import { transitionTo } from '../../utils/Navigation';
import { IRecentIndex } from 'mm-types';

export type DocumentTileProps = {
  errorMsg?: string | null;
  recentDocuments: IRecentIndex[];
  recentDocumentsLoading?: boolean;
};

const onDocumentSelect = (document) => {
  if (document) {
    transitionTo('editor-edit', {
      projectUid: document.projectUid,
      indexUid: document.indexUid
    });
  }
};

const DocumentTile = ({ errorMsg, recentDocuments, recentDocumentsLoading }: DocumentTileProps) => (
  <div id="recent-docs-tile">
    {recentDocumentsLoading && (
      <div className="dashboard-loading">
        <div className="dashboard-loading--text">Loading</div>
        <CircularProgress mode="indeterminate" size={119} />
      </div>
    )}

    {errorMsg && !recentDocumentsLoading && <div className="dashboard-error">{errorMsg}</div>}

    {recentDocuments.length > 0 && !recentDocumentsLoading && !errorMsg && (
      <div>
        <DocumentList documents={recentDocuments} onDocumentSelect={(document) => onDocumentSelect(document)} />

        <div className="dashboard-notif-read-action">
          <FlatButton
            id="view-all-docs"
            label="VIEW ALL DOCS"
            secondary={true}
            onClick={() => {
              transitionTo('/teamspaces/all');
            }}
          />
        </div>
      </div>
    )}

    {recentDocuments.length === 0 && !recentDocumentsLoading && !errorMsg && <NoRecentDocuments />}
  </div>
);

export default DocumentTile;
