export default class BodyCSSClassUtil {
  public static toggleClassNameOnDragEvent() {
    const EVENTS = 'dragover dragleave drop';
    const CLASS_NAME = 'event-dragover';
    const $window = $(window);
    const body = document.getElementsByTagName('BODY')[0];

    function isDragOver(e) {
      const dt = e.originalEvent.dataTransfer;
      return dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'));
    }

    let isSet = false;

    $window.on(EVENTS, (e) => {
      if (!isSet && isDragOver(e)) {
        body.classList.add(CLASS_NAME);
        isSet = true;
      }

      if ((e.type === 'dragleave' || e.type === 'drop') && isSet) {
        body.classList.remove(CLASS_NAME);
        isSet = false;
      }
    });
  }

  public static addClass(className: string) {
    document.getElementsByTagName('BODY')[0].classList.add(className);
  }
  public static removeClass(className: string) {
    document.getElementsByTagName('BODY')[0].classList.remove(className);
  }
}
