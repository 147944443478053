export function scriptLoader(id: string, src: string, onLoadCallback?: Function, onErrorCallback?: Function) {
  const script = document.getElementById(id);

  if (!script) {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.body.appendChild(script);

    script.onload = () => {
      if (onLoadCallback) {
        onLoadCallback();
      }
    };
    script.onerror = () => {
      if (onErrorCallback) {
        onErrorCallback();
      }
    };
  }

  if (script && onLoadCallback) {
    onLoadCallback();
  }
}
