import * as React from 'react';
import Popover from './PopoverPatched';
import { PopoverProps } from 'material-ui';

type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

interface Props {
  children: React.ReactNode;
  className?: string;
  position: TooltipPosition;
  onRequestClose();
  anchorEl: Element;
  open: boolean;
  useLayerForClickAway?: boolean;
}

function getTooltipPosition(position: TooltipPosition): PopoverProps['anchorOrigin'] {
  switch (position) {
    case 'bottom':
      return {
        horizontal: 'middle',
        vertical: 'bottom'
      };
    case 'top':
      return {
        horizontal: 'middle',
        vertical: 'top'
      };
    case 'left':
      return {
        horizontal: 'left',
        vertical: 'center'
      };
    case 'right':
      return {
        horizontal: 'right',
        vertical: 'center'
      };
  }
}

function getOppositePosition(position: TooltipPosition): TooltipPosition {
  return position === 'top' ? 'bottom' : position === 'bottom' ? 'top' : position === 'left' ? 'right' : 'left';
}

const Tooltip = ({ children, anchorEl, onRequestClose, open, position, useLayerForClickAway = true }: Props) => {
  const oppositePosition = React.useMemo(() => getOppositePosition(position), [position]);
  const anchorPosition = React.useMemo(() => getTooltipPosition(position), [position]);
  const targetPosition = React.useMemo(() => getTooltipPosition(oppositePosition), [oppositePosition]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorPosition}
      targetOrigin={targetPosition}
      onRequestClose={() => {
        onRequestClose();
      }}
      useLayerForClickAway={useLayerForClickAway}
      className={`tooltip tooltip-arrow-${oppositePosition}`}
    >
      {children}
    </Popover>
  );
};

export default React.memo(Tooltip);
