import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PopupContainer from '../misc/PopupContainer';
import { styleMap } from '../../styles/styleGuard';

export type Props = {};

export type State = {
  showEditor: null;
  messages: string[];
};

const styles = styleMap({
  li: {
    height: '100%',
    lineHeight: '2rem'
  },
  container: {
    float: 'none'
  }
});

export default class WarningsPopup extends React.Component<Props, State> {
  private _triggerRef: null;
  private popupContainer: PopupContainer | null;

  constructor(props: Props) {
    super(props);
    this._triggerRef = null;
    this.state = {
      showEditor: null,
      messages: []
    };
  }

  getPopup() {
    return this.popupContainer;
  }

  open(triggerRef, offset) {
    this._triggerRef = triggerRef;
    this.popupContainer
      ?.setTriggerButton(triggerRef)
      .open()
      .setStyle((el) => this._repositionPointer(el, offset));
  }

  showMessages(messages) {
    this.setState({ messages: messages });
    return this;
  }

  _repositionPointer(el, offset) {
    const $trigger = $(ReactDOM.findDOMNode(this._triggerRef!) as Element);

    return {
      popup: { right: 'auto', left: $trigger.offset()!.left - offset ?? 0 },
      pointer: { right: 'auto', left: (offset ?? 0) - 2 }
    };
  }

  render() {
    return (
      <PopupContainer ref={(ref) => (this.popupContainer = ref)} className="popup-list text-align-left" dataQa="warnings-popup-container">
        <ul>
          {this.state.messages.map((message, messageIndex) => {
            return (
              <li key={messageIndex} style={styles.li}>
                <div className="popup-info-container" key={messageIndex} style={styles.container}>
                  <p>{message}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </PopupContainer>
    );
  }
}
