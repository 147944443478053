import * as React from 'react';
import { Dialog } from 'material-ui';
import { AeroIconButtonClose } from '../general/AeroIconButton';
import StringUtil from '../../utils/StringUtil';

export interface WithDialogProps {
  title: string;
  show: boolean;
  actions?: Array<React.ReactElement<any>>;
  onRequestClose();
}

export default function withDialog<T>(Component) {
  return function WithDialog({ title, actions = [], onRequestClose, show, ...componentProps }: WithDialogProps & T) {
    const rootClass = `default-dialog ${StringUtil.plainTextToDash(title)}`;
    return (
      <Dialog
        title={title}
        className={rootClass}
        contentClassName="dialog-content"
        bodyClassName="dialog-body"
        actionsContainerClassName="dialog-actions"
        actions={[...actions, <AeroIconButtonClose key={1} data-qa="close" onClick={onRequestClose} />]}
        open={show}
        onRequestClose={onRequestClose}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
      >
        <Component {...componentProps} />
      </Dialog>
    );
  };
}
