import React, { useEffect } from 'react';
import DashboardPanel from '../DashboardPanel/DashboardPanel';
import DashboardPanelHeader from '../DashboardPanel/DashboardPanelHeader';
import DashboardPanelHeaderTitle from '../DashboardPanel/DashboardPanelHeaderTitle';
import { AeroFlatButtonWithIcon } from '../../general/AeroButton';
import WorkflowActionsTileFilters, { WorkflowActionsFilterValues } from './WorkflowActionsTileFilters';
import { exportWorkflowActions, getWorkflowActions, WorkflowActionResponse } from '../../documents/kpi/kpiClient';
import FontIcon from 'material-ui/FontIcon';
import WorkflowActionRow from './WorkflowActionRow';
import { IDropDownItem } from '../../misc/DropDownSearchable';
import LoadingSpinner from '../../documents/kpi/TableContainer/LoadingSpinner';
import { AxiosError } from 'axios';
import Paging from '../../general/Paging';

export type WorkflowActionsExportParams = {
  userUids?: string[];
  departmentUid?: string;
  orgUid?: string;
  workspaceUid?: string;
  to: Date;
  from: Date;
  page?: number;
  size?: number;
};

const defaultStyles = {
  panel: {
    flex: '1 1 90%',
    position: 'relative',
    overflow: 'scroll'
  } as React.CSSProperties,
  panelHeader: {
    flexWrap: 'wrap',
    height: 'initial',
    minWidth: '1100px' // to match the combined width of all the dropdowns
  } as React.CSSProperties,
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexFlow: 'wrap',
    margin: '0px 0px 25px 0px',
    overflowY: 'auto',
    height: '70%',
    alignContent: 'flex-start',
    width: '100%',
    minWidth: '1100px'
  } as React.CSSProperties,
  exportContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px'
  } as React.CSSProperties,
  fontIcon: {
    fontSize: '48px',
    marginBottom: '12px'
  },
  exportText: {
    color: 'gray',
    marginBottom: '8px'
  }
};

function WorkflowActionsTileContainer() {
  const to = new Date(new Date().setHours(23, 59, 59, 999));
  const FORTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000;
  const from = new Date(new Date().setHours(0, 0, 0, 0) - FORTEEN_DAYS_IN_MS);
  const [filterValues, setFilterValues] = React.useState<WorkflowActionsFilterValues>({
    users: undefined,
    department: undefined,
    org: undefined,
    workspace: undefined,
    from: from,
    to: to,
    page: 0,
    size: 20
  });
  const [workflowActions, setWorkflowActions] = React.useState<WorkflowActionResponse>({ total: 0, actions: [] });
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<AxiosError | undefined>(undefined);
  useEffect(() => {
    setIsLoading(true);
    setIsError(undefined);
    getWorkflowActions({
      departmentUid: filterValues.department?.value,
      from: filterValues.from,
      orgUid: filterValues.org?.value,
      to: filterValues.to,
      userUids: filterValues.users?.[0].value ? [filterValues.users?.[0].value] : [],
      workspaceUid: filterValues.workspace?.value,
      page: filterValues.page ?? 0,
      size: filterValues.size
    })
      .then((res) => {
        setWorkflowActions(res);
      })
      .catch((err: AxiosError) => {
        setIsError(err);
      })
      .finally(() => setIsLoading(false));
  }, [
    filterValues.from,
    filterValues.to,
    filterValues.workspace?.value,
    filterValues.org?.value,
    filterValues.department?.value,
    filterValues.users?.[0].value,
    filterValues.page
  ]);

  return (
    <DashboardPanel style={defaultStyles.panel} dataQa="workflow-actions-tile">
      <DashboardPanelHeader
        style={defaultStyles.panelHeader}
        className={`primary-color-icons${workflowActions.total === 0 ? '-disabled' : ''}`}
      >
        <DashboardPanelHeaderTitle>
          Workflow Actions (<span data-qa="workflow-actions-total">{workflowActions.total}</span>)
        </DashboardPanelHeaderTitle>
        <AeroFlatButtonWithIcon
          iconName="file_download"
          leftIcon={false}
          gray={workflowActions.total === 0}
          onClick={() =>
            exportWorkflowActions({
              departmentUid: filterValues.department?.value,
              from: filterValues.from,
              orgUid: filterValues.org?.value,
              to: filterValues.to,
              userUids: filterValues.users?.[0].value ? [filterValues.users?.[0].value] : [],
              workspaceUid: filterValues.workspace?.value
            })
          }
          data-qa="workflow-actions-export-button"
          disabled={workflowActions.total === 0}
        >
          Export WFA Report
        </AeroFlatButtonWithIcon>
        <WorkflowActionsTileFilters
          setFilterValues={(key, val: IDropDownItem) => {
            if (key === 'to' || key === 'from') {
              setFilterValues({
                ...filterValues,
                users: undefined,
                org: undefined,
                department: undefined,
                workspace: undefined,
                page: 0,
                size: 20,
                [key]: val
              });
            } else {
              setFilterValues({ ...filterValues, page: 0, [key]: val });
            }
          }}
          filterValues={filterValues}
          setIsError={(err) => setIsError(err)}
          resetFilterValues={() =>
            setFilterValues({
              users: undefined,
              department: undefined,
              org: undefined,
              workspace: undefined,
              from: from,
              to: to,
              page: 0,
              size: 20
            })
          }
        />
      </DashboardPanelHeader>
      <div style={defaultStyles.container}>
        {isLoading ? (
          <LoadingSpinner data-qa="workflow-actions-loading-spinner" />
        ) : workflowActions.total === 0 || isError ? (
          <div className="primary-color-icons" style={defaultStyles.exportContainer}>
            <FontIcon
              className={`material-icons icon-${isError ? 'change-task-import-error' : 'workflow'}`}
              style={defaultStyles.fontIcon}
            />
            <div style={defaultStyles.exportText}>
              <em>{isError ? 'Unable to retrieve Workflow Actions' : 'Select criteria from the dropdown filters to'}</em>
              <br />
              <em>{isError ? isError.message : 'export your workflow actions reports'}</em>
            </div>
          </div>
        ) : (
          workflowActions.actions.map((action, index) => {
            return <WorkflowActionRow action={action} key={index} data-qa={`workflow-actions-row_${index}`} />;
          })
        )}
      </div>
      <div>
        <Paging
          moveToPage={(pageNumber) => setFilterValues({ ...filterValues, page: pageNumber ?? 0 })}
          page={{
            pageSize: filterValues.size,
            pageNumber: filterValues.page ?? 0,
            numOfPages: Math.ceil(workflowActions.total / filterValues.size),
            totalElements: workflowActions.total
          }}
          compact={true}
        />
      </div>
    </DashboardPanel>
  );
}

export default WorkflowActionsTileContainer;
