import * as React from 'react';
import * as _ from 'lodash';
import { default as PopupMenu, Options, Option } from '../../../../misc/PopupMenu';

export type FilterOptions = {
  allTypes?: boolean;
  outbound?: boolean;
  inbound?: boolean;
  internal?: boolean;
  external?: boolean;
  invalid?: boolean;
};

export type Props = {
  filterOptions: FilterOptions;
  setFilterOptions: (options: FilterOptions) => void;
};

export type State = {};

export default class DocumentLinkFilterPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    filterOptions: {}
  };

  pick(option: string) {
    const filterOptions = _.cloneDeep(this.props.filterOptions);
    if (option === 'allTypes') {
      this.props.setFilterOptions({ allTypes: !filterOptions.allTypes });
    } else {
      filterOptions[option] = !filterOptions[option];
      filterOptions['allTypes'] = false;
      this.props.setFilterOptions(filterOptions);
    }
  }

  onOpenTocDepthMenu() {
    (this.refs.depthMenu as PopupMenu).setTriggerButton(this.refs.displayOptionsBtn).open();
  }

  render() {
    return (
      <div>
        <i onClick={(e) => this.onOpenTocDepthMenu()} className="icon-display-options tiny material-icons" ref="displayOptionsBtn">
          filter_list
        </i>
        <i className="icon-display-options tiny material-icons" onClick={(e) => this.onOpenTocDepthMenu()}>
          arrow_drop_down
        </i>
        <PopupMenu ref="depthMenu" className="display-options-popup" onSelect={(e) => this.pick(e)}>
          <Options>
            <Option hasCheck={this.props.filterOptions.allTypes} dataKey="allTypes">
              All Types
            </Option>
            <Option hasCheck={this.props.filterOptions.outbound} dataKey="outbound">
              Outbound
            </Option>
            <Option hasCheck={this.props.filterOptions.inbound} dataKey="inbound">
              Inbound
            </Option>
            <Option hasCheck={this.props.filterOptions.internal} dataKey="internal">
              Internal
            </Option>
            <Option hasCheck={this.props.filterOptions.external} dataKey="external">
              External
            </Option>
            <Option hasCheck={this.props.filterOptions.invalid} dataKey="invalid">
              Invalid
            </Option>
          </Options>
        </PopupMenu>
      </div>
    );
  }
}
