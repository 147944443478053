import * as React from 'react';
import EditorStore from '../../../../flux/editor/EditorStore';
import { ISharedIndexUsages, IUnit } from 'mm-types';
import { isActionEnabled, isDiffActionToggled } from './SharePublishMenu';

enum SHARE_ACTION_ACK_TYPES {
  'accept' = 'MANUAL_NOW',
  'accept_ref' = 'NONE_DONE',
  'defer' = 'MANUAL_DEFER',
  'defer_ref' = 'NONE_DEFER'
}

type Props = {
  unit: IUnit;
  sharedIndexUsage: ISharedIndexUsages | null;
  isShareActionAllowed: boolean;
};

export const ShareUsageMenu = (props: Props) => {
  const { unit, sharedIndexUsage, isShareActionAllowed = true } = props;
  const { shareDetails, isVisibleOnEdit } = unit;
  const updateDeferred = sharedIndexUsage && sharedIndexUsage.updateDeferred;
  const allowDerivatives = sharedIndexUsage && sharedIndexUsage.sharedIndex && sharedIndexUsage.sharedIndex.allowDerivatives;

  const isNoUpdateStrategy = shareDetails && shareDetails.updateStrategy === 'NONE';
  const isManualUpdateStrategy = shareDetails && shareDetails.updateStrategy === 'MANUAL';

  const isUpdateAvailable = isVisibleOnEdit && sharedIndexUsage && sharedIndexUsage.updateAvailable;

  let usageMenu: JSX.Element | null = null;

  if (isUpdateAvailable) {
    if (isNoUpdateStrategy) {
      usageMenu = (
        <div className="unit-shared-rhs-menu unit-sharedusage-ref-update-menu">
          <div
            className={isActionEnabled(isShareActionAllowed)}
            onClick={() =>
              EditorStore.acceptSharedContentUpdate(unit.uid, unit.shareDetails?.sharedIndexUid!, SHARE_ACTION_ACK_TYPES['accept_ref'])
            }
          >
            <i className="material-icons">done</i> Done
          </div>
          {updateDeferred ? null : (
            <div
              className={isActionEnabled(isShareActionAllowed)}
              onClick={() =>
                EditorStore.acceptSharedContentUpdate(unit.uid, unit.shareDetails?.sharedIndexUid!, SHARE_ACTION_ACK_TYPES['defer_ref'])
              }
            >
              <i className="material-icons">access_time</i> Defer
            </div>
          )}
          <div
            className={isDiffActionToggled(shareDetails?.inDiffMode)}
            onClick={() => EditorStore.diffSharedContentUpdate(unit.uid, { ...shareDetails!, inDiffMode: !shareDetails?.inDiffMode })}
          >
            <i className="material-icons icon-diffing" /> Diff
          </div>
        </div>
      );
    } else if (isManualUpdateStrategy) {
      usageMenu = (
        <div className="unit-shared-rhs-menu unit-sharedusage-manual-update-menu">
          <div
            className={isActionEnabled(isShareActionAllowed)}
            onClick={() =>
              EditorStore.acceptSharedContentUpdate(unit.uid, unit.shareDetails?.sharedIndexUid!, SHARE_ACTION_ACK_TYPES['accept'])
            }
          >
            <i className="material-icons">done</i> Accept
          </div>
          <div
            className={isActionEnabled(isShareActionAllowed && !!allowDerivatives)}
            onClick={() => !!allowDerivatives && EditorStore.requestRejectSharedContentUpdate(unit.uid, unit.shareDetails?.sharedIndexUid!)}
          >
            <i className="material-icons">clear</i> Reject
          </div>
          {updateDeferred ? null : (
            <div
              className={isActionEnabled(isShareActionAllowed)}
              onClick={() =>
                EditorStore.acceptSharedContentUpdate(unit.uid, unit.shareDetails?.sharedIndexUid!, SHARE_ACTION_ACK_TYPES['defer'])
              }
            >
              <i className="material-icons">access_time</i> Defer
            </div>
          )}
          <div
            className={isDiffActionToggled(shareDetails?.inDiffMode)}
            onClick={() => EditorStore.diffSharedContentUpdate(unit.uid, { ...shareDetails!, inDiffMode: !shareDetails?.inDiffMode })}
          >
            <i className="material-icons icon-diffing"></i> Diff
          </div>
        </div>
      );
    }
  }

  return usageMenu;
};
