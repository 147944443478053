import { IDocUnitProfile } from 'mm-types';
import { listMenuOverrides, listMenuSelectedOverrides } from '../../const/profileMenuOverrides';
import ListItemEditProps from '../../../../sidetabs/sub/editComponent/components/ListItemEditProps';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'List Item',
  getLabel: () => 'LI',
  identifyingClasses: ['arc-li'],

  editPropsComponent: ListItemEditProps,
  splittable: false,
  menu: listMenuOverrides,
  menuTextSelected: listMenuSelectedOverrides,
  outerElement: 'li.arc-li'
};
