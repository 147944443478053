import * as React from 'react';

export type Props = {
  selected: boolean;
  input: string;
  text: string;
  addable: boolean;
  allowDelete: boolean;
  onDelete: (e: React.MouseEvent<HTMLElement>) => void;
};

export type State = {};

export default class Tag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  // helps tests pass
  static defaultProps: Partial<Props> = {
    text: ''
  };

  tagContent() {
    const content: JSX.Element[] = [];
    const startIndex = this.props.text.trim().toLowerCase().indexOf(this.props.input.trim().toLowerCase());
    const endIndex = startIndex + this.props.input.length;

    if (startIndex > 0) {
      content.push(
        <span key={1} className="tf__tag__content--regular">
          {this.props.text.substring(0, startIndex)}
        </span>
      );
    }

    content.push(
      <span key={2} className="tf__tag__content--match">
        {this.props.text.substring(startIndex, endIndex)}
      </span>
    );

    if (endIndex < this.props.text.length) {
      content.push(
        <span key={3} className="tf__tag__content--regular">
          {this.props.text.substring(endIndex)}
        </span>
      );
    }

    return content;
  }

  onDelete(e: React.MouseEvent<HTMLElement>) {
    // Prevents onClick event of the whole tag from being triggered
    e.preventDefault();
    e.stopPropagation();
    this.props.onDelete(e);
  }

  getDeleteBtn() {
    return (
      <div className="tf__tag__delete" onClick={(e) => this.onDelete(e)}>
        <span className="material-icons icon-close"></span>
      </div>
    );
  }

  render() {
    let deleteBtn: JSX.Element | null = null;
    if (this.props.allowDelete) {
      deleteBtn = this.getDeleteBtn();
    }

    const cls = 'tf__tag' + (this.props.selected ? ' tf-selected' : '');

    return (
      <div className={cls}>
        <div className="tf__tag__content">{this.tagContent()}</div>
        {deleteBtn}
      </div>
    );
  }
}
