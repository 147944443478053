import { IDocUnitProfile } from 'mm-types';
import AdmonitionEditProps from '../../../../sidetabs/sub/editComponent/components/AdmonitionEditProps';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Note',
  getLabel: () => 'NOTE',
  identifyingClasses: ['arc-note'],
  outerClass: 'arc-note',

  editPropsComponent: AdmonitionEditProps,
  template: '',

  arcMLDefaults: {
    root: { top: 1, right: 0, bottom: 1, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    textActions: [
      { action: 'bold', enabled: false },
      { action: 'italic', enabled: false },
      { action: 'underline', enabled: false },
      { action: 'superscript', enabled: false },
      { action: 'subscript', enabled: false },
      { action: 'forecolor', enabled: false },
      { action: 'hilitecolor', enabled: false }
    ],
    linkActions: [{ action: 'insertLink', enabled: true }],
    justifyActions: [
      { action: 'JustifyLeft', enabled: false },
      { action: 'JustifyCenter', enabled: false },
      { action: 'JustifyRight', enabled: false },
      { action: 'JustifyFull', enabled: false }
    ],
    specialActions: [{ action: 'SpecialCharacter', enabled: true }],
    editActions: [
      { action: 'Paste', enabled: true },
      { action: 'arcPasteElement', enabled: true },
      { action: 'Indent', enabled: false },
      { action: 'Outdent', enabled: false }
    ]
  },

  menuTextSelected: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    textActions: [
      { action: 'bold', enabled: false },
      { action: 'italic', enabled: false },
      { action: 'underline', enabled: false },
      { action: 'superscript', enabled: true },
      { action: 'subscript', enabled: true },
      { action: 'forecolor', enabled: false },
      { action: 'hilitecolor', enabled: false }
    ],
    linkActions: [{ action: 'insertLink', enabled: true }],
    justifyActions: [
      { action: 'JustifyLeft', enabled: false },
      { action: 'JustifyCenter', enabled: false },
      { action: 'JustifyRight', enabled: false },
      { action: 'JustifyFull', enabled: false }
    ],
    editActions: [
      { action: 'Paste', enabled: true },
      { action: 'Indent', enabled: false },
      { action: 'Outdent', enabled: false }
    ]
  },
  blockOverridesFromParent: true
};
