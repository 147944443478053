import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import Checkbox from 'material-ui/Checkbox';
import UnitConceptStore, { UnitConceptStoreEvent } from '../../../../../../flux/editor/UnitConceptStore';
import { IVariant, IUnit, IEditorStoreEvent } from 'mm-types';
import useListenToStore from '../../../../../hooks/useListenToStore';

export type Props = {
  units: IUnit[];
};

export type State = {
  familyVariants: IVariant[];
  unitVariants: IVariant[];
  family: string;
  isBusy: boolean;
};

const VariantProperties = (props: Props) => {
  const [state, setState] = React.useState<State>({ familyVariants: [], unitVariants: [], family: '', isBusy: false });

  React.useEffect(() => {
    initialRender();
  }, [props.units]);

  const onEditStoreUpdate = (e: IEditorStoreEvent<'editorError'>) => {
    if (e.type === 'editorError') {
      setState({
        familyVariants: [],
        unitVariants: [],
        family: '',
        isBusy: false
      });
    }
  };

  const onUnitVariantStoreUpdate = (event: UnitConceptStoreEvent) => {
    if (event.type === 'retrieved-unit-facet-map') {
      const newState = fetchStoreState();

      // Stop Doc Spinner
      EditorStore.stopBusy();

      setState(newState as State);
    }
  };

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate, update: props.units });
  useListenToStore({ store: UnitConceptStore, eventListener: onUnitVariantStoreUpdate, update: props.units });

  const fetchStoreState = (unit: IUnit = props.units[0]) => {
    return {
      familyVariants: UnitConceptStore.getFamilyVariants(),
      unitVariants: UnitConceptStore.getUnitVariants(unit),
      family: UnitConceptStore.getFamilyName(),
      isBusy: UnitConceptStore.isBusy()
    } as Partial<State>;
  };

  const initialRender = () => {
    if (props.units.length === 0 || !props.units[0].uid) {
      setState({
        familyVariants: [],
        unitVariants: [],
        family: '',
        isBusy: false
      });
    } else {
      const newState: Partial<State> = fetchStoreState(props.units[0]);
      const unit = EditorStore.getDocUnitModel(props.units[0].uid);
      if (unit) {
        setState(newState as State);
      }
    }
  };

  const checkMainUnit = (unitMarkup) => {
    if (unitMarkup.includes('arc-chapter') || unitMarkup.includes('arc-section') || unitMarkup.includes('arc-level')) {
      // Spinner for Document
      EditorStore.docBusy();
    }
  };

  const addVariantToUnit = (variant: IVariant) => {
    setState({ ...state, isBusy: true });
    for (const unit of props.units) {
      const docParams = EditorStore.getDocParams();
      checkMainUnit(unit.html);
      UnitConceptStore.addConceptToUnit(variant, docParams, [unit.uid]);
    }
  };

  const removeVariantFromUnit = (variant: IVariant) => {
    setState({ ...state, isBusy: true });
    for (const unit of props.units) {
      const docParams = EditorStore.getDocParams();
      checkMainUnit(unit.html);
      UnitConceptStore.removeConceptFromUnit(variant, docParams, [unit.uid]);
    }
  };

  const hasVariantSelected = (variant: IVariant) => {
    return !!state.unitVariants.find((v) => v.uid === variant.uid);
  };

  const editable =
    EditorStore.isReadOnly() || (props.units[0].shareDetails && props.units[0].shareDetails.origin)
      ? false
      : UnitConceptStore.getUnitEditable(props.units[0]);

  return (
    <div className={'selected-unit-props-section variant-section' + (!editable ? ' disabled' : '')}>
      <h2>{state.family}</h2>
      {state.familyVariants.map((variant, index) => {
        return (
          <Checkbox
            disabled={!editable || state.isBusy}
            key={`variant-${index}`}
            checked={hasVariantSelected(variant)}
            data-qa-variant-checkbox={variant.name}
            label={variant.name}
            onCheck={() => {
              if (hasVariantSelected(variant)) {
                removeVariantFromUnit(variant);
              } else {
                addVariantToUnit(variant);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default VariantProperties;
