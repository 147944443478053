import * as React from 'react';
import ChangeTasks from './ChangeTasks';
import TaskActivity from './TaskActivity';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import EditorStore from '../../../../../flux/editor/EditorStore';

// persists last accessed list across unmounts
const _persistForIndex: {
  indexUid: string | null;
  selected: string | null;
} = {
  indexUid: null,
  selected: 'list'
};

export type Props = {};

export type State = {
  isTasksSelected: boolean;
};

export default class Tasks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isTasksSelected: this._getSelectedList() === 'list'
    };
  }

  _getSelectedList() {
    const indexUid = EditorStore.getDocParams().indexUid;
    let selectedList = 'list';

    if (_persistForIndex.indexUid === indexUid) {
      selectedList = _persistForIndex.selected!;
    } else {
      _persistForIndex.indexUid = indexUid!;
      _persistForIndex.selected = 'list';
    }

    return selectedList;
  }

  _toggle(action: string) {
    _persistForIndex.selected = action;
    this.setState({ isTasksSelected: action === 'list' });
  }

  render() {
    return (
      <div className="mainaction-container tasks">
        <DropDownIcon
          className={'task-toggle-menu ' + (this.state.isTasksSelected ? 'listWidth' : 'activityWidth')}
          icon=""
          onSelect={(action) => this._toggle(action)}
        >
          <DropDownOption value="list">Task List</DropDownOption>
          <DropDownOption value="activity">Task Activity</DropDownOption>
        </DropDownIcon>
        <div className={'material-icons dropdown-icon ' + (this.state.isTasksSelected ? 'list' : 'activity')}>arrow_drop_down</div>
        {this.state.isTasksSelected ? <ChangeTasks /> : <TaskActivity />}
      </div>
    );
  }
}
