import { IProjectActivity } from 'mm-types';
import React from 'react';

type PropsType = {
  entry: IProjectActivity;
};

export const WorkflowReminder = ({ entry }: PropsType) => {
  const workflowReminderDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    workflowReminderDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  if (entry.workflowTransition) {
    if (entry.workflowTransition.activeStageTitle) {
      workflowReminderDom.push(
        <div key="title">
          <label>Active Stage Title:</label> <span>{entry.workflowTransition.activeStageTitle}</span>
        </div>
      );
    }
  }
  return <div className="settings-details">{workflowReminderDom}</div>;
};
