import React, { useState } from 'react';
import { WorkflowAction } from '../../documents/kpi/kpiClient';
import FontIcon from 'material-ui/FontIcon';
import { dateUtil } from '../../../utils';
import WorkflowActionItemDetail from './WorkflowActionItemDetail';
export type WorkflowActionRowProps = {
  action: WorkflowAction;
};

const defaultStyles = {
  rowRoot: {
    width: '100%',
    display: 'flex',
    marginTop: '4px'
  },
  rowDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '20px'
  },

  detailsWithoutWFSComment: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
};

function WorkflowActionRow({ action }: WorkflowActionRowProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div style={{ ...defaultStyles.rowRoot }} className="workflow-action-row" data-qa="workflow-action-row">
      <FontIcon
        className="material-icons icon-new-doc"
        style={{ paddingTop: '16px', height: '100%', cursor: 'pointer' }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        style={{
          ...(defaultStyles.rowDetailsContainer as React.CSSProperties),
          borderBottom: '1px solid #CCC',
          backgroundColor: isOpen ? '#f7f7f7' : ''
        }}
      >
        <FontIcon
          className="material-icons"
          style={{ cursor: 'pointer', height: '100%' }}
          onClick={() => setIsOpen(!isOpen)}
          data-qa="workflow-action-row-chevron"
        >
          {!isOpen ? 'chevron_right' : 'expand_more'}
        </FontIcon>
        <div style={{ ...(defaultStyles.detailsWithoutWFSComment as React.CSSProperties) }}>
          <WorkflowActionItemDetail
            title="STAFF ID/NAME"
            value={`${action.staffId ?? ''} ${action.userName}`}
            dataQa="workflow-action-row-user-name"
            isOpen={isOpen}
          />
          <WorkflowActionItemDetail
            title="DOCUMENT TITLE"
            value={action.projectName}
            dataQa="workflow-action-row-doc-name"
            isOpen={isOpen}
          />
          <WorkflowActionItemDetail
            title="WORKFLOW STAGE NAME"
            value={action.workflowStageName}
            dataQa="workflow-action-row-wfs-name"
            isOpen={isOpen}
          />
          <WorkflowActionItemDetail
            title="WFS ACTION TAKEN"
            value={action.action}
            dataQa="workflow-action-row-wfs-action-taken"
            isOpen={isOpen}
          />
          {isOpen && (
            <>
              <WorkflowActionItemDetail title="ORGANISATION" value={action.org} dataQa="workflow-action-row-org-name" isOpen={isOpen} />
              <WorkflowActionItemDetail title="DOCUMENT REF" value={action.docRef} dataQa="workflow-action-row-doc-ref" isOpen={isOpen} />
              <WorkflowActionItemDetail
                title="WFS START DATE"
                value={dateUtil(action.workflowStageCreated).formatDate()}
                dataQa="workflow-action-row-wfs-start-date"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail
                title="DEPARTMENT"
                value={action.department}
                dataQa="workflow-action-row-dept-name"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail title="REV NUMBER" value={action.revNumber} dataQa="workflow-action-row-rev-num" isOpen={isOpen} />
              <WorkflowActionItemDetail
                title="WFS USER ADDED DATE"
                value={dateUtil(action.workflowStageAssigned).formatDate()}
                dataQa="workflow-action-row-wfs-user-added-date"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail
                title="WFS ACTION DATE"
                value={dateUtil(action.actionDate).formatDate()}
                dataQa="workflow-action-row-wfs-action-date"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail title="TEAMSPACE" value={action.workspace} dataQa="workflow-action-row-teamspace" isOpen={isOpen} />
              <WorkflowActionItemDetail
                title="REVISION DATE"
                value={dateUtil(action.revDate).formatDate()}
                dataQa="workflow-action-row-rev-date"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail
                title="WFS DURATION DAYS"
                value={action.workflowStageDuration}
                dataQa="workflow-action-row-wfs-duration-days"
                isOpen={isOpen}
              />
              <WorkflowActionItemDetail
                title="WFS DUE DATE"
                value={dateUtil(action.workflowStageDueDate).formatDate()}
                dataQa="workflow-action-row-wfs-due-date"
                isOpen={isOpen}
              />
            </>
          )}
        </div>
        <WorkflowActionItemDetail title="WFS USER COMMENT" value={action.comment} dataQa="workflow-action-row-comment" isOpen={isOpen} />
      </div>
    </div>
  );
}

export default WorkflowActionRow;
