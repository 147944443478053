export enum SESSION_STORAGE_KEYS {
  FEATURES_ON = 'features',
  FEATURES_OFF = 'featuresOff',
  MOCK_QUIZ_API_RESPONSE = 'mockQuiz',
  FIND_REPLACE_OPTIONS = 'findReplaceOptions',
  SHOW_DEV_TOOLS = 'showDevTools',
  SELECTED_ORGANISATION_UID = 'SelectedOrganisationUid',
  SELECTED_ORGANISATION_TEXT = 'SelectedOrganisationText',
  SELECTED_DEPARTMENT_UID = 'SelectedDepartmentUid',
  SELECTED_DEPARTMENT_TEXT = 'SelectedDepartmentText',
  SELECTED_TEAMSPACE_UID = 'SelectedTeamspaceUid',
  SELECTED_TEAMSPACE_TEXT = 'SelectedTeamspaceText',
  MOCKED_RESPONSES = 'mockedResponses',
  FORCE_RELOAD = 'forceReload',
  WEBSOCKET_DEBUG = 'websocketDebug',
  DEBUG_REFLUX_EVENTS = 'trackEvents'
}

export function clearSelectedSessionStorageItems() {
  Object.keys(SESSION_STORAGE_KEYS).forEach((key) => {
    if (key.startsWith('SELECTED')) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS[key]);
    }
  });
}
