import * as React from 'react';
import { ITocNode, IRevision, IVariant } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';
import TocStore from '../../../../../flux/editor/TocStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import NotificationsStore from '../../../../../flux/events/NotificationsStore';
import * as projectClient from '../../../../../clients/project';
import { Dialog, FlatButton } from 'material-ui';
import { VariantsSection } from '../ExportDialog/VariantsSection';
import { RangeSection } from '../ExportDialog/RangeSection';
import { DialogHeader } from '../../dialogs/DialogHeader';
import { RevisionHighlightsSection } from '../ExportDialog/RevisionHighlightsSection';
import VerticalSlide from '../../../../general/animations/VerticalSlide';
import { useRevisionHighlightsCheckApi } from '../../../../../clients/hooks/revisionHighllights/useRevisionHighlightsCheckApi';
import { RevisionHighlightsOption, SelectedPdfRange } from '../ExportDialog/exportDialogTypes';
import { isSubmitDisabled, getVariantUid } from '../ExportDialog/exportDialogUtils';
import { useProjectSettings } from '../../../settings/useProjectSettings';

interface Props {
  show: boolean;
  isDraft: boolean | undefined;
  projectUid: string;
  documentIndexUid: string;
  revisions?: IRevision[] | null;
  variants: IVariant[];
  onRequestClose();
}

const INIT_STATE: RevisionHighlightsOption = {
  checked: false,
  value: 'ALL'
};

export const ExportDialog = (props: Props) => {
  const docParams = EditorStore.getDocParams();
  const project = ProjectStore.getProject()!;
  let canExportPdf = false;
  if (project) {
    canExportPdf = project!.currentUserPermissions!.canExportPdf;
  }

  const [selectedPdfRange, setSelectedPdfRange] = React.useState<SelectedPdfRange>(SelectedPdfRange.NONE);
  const [selectedVariant, setSelectedVariant] = React.useState<string>('');
  const [selectedVariantInFileMenu, setSelectedVariantInFileMenu] = React.useState<string | null>(null);
  const [privateOptions, setPrivateOptions] = React.useState<RevisionHighlightsOption>({ ...INIT_STATE });
  const [generalOptions, setGeneralOptions] = React.useState<RevisionHighlightsOption>({ ...INIT_STATE });
  const hasVariants = !!props.variants && !!props.variants.length && !!project?.variantFamily;
  const isHighlightsRangeSelected = selectedPdfRange === SelectedPdfRange.REVISION_HIGHLIGHTS;
  const submitDisabled = isSubmitDisabled(selectedPdfRange, privateOptions, generalOptions, hasVariants, selectedVariant);

  const { response: highlightsResponse, isFetching, isError } = useRevisionHighlightsCheckApi(
    {
      projectUid: docParams.projectUid!,
      indexUid: docParams.indexUid!
    },
    props.show
  );
  const { settings } = useProjectSettings({ uid: props.projectUid, masterIndexUid: props.documentIndexUid });
  const hasHighlights: boolean =
    !!highlightsResponse &&
    highlightsResponse.unitHighlightsPresent &&
    !isError &&
    (EditorStore.isMode('DIFF') || !!settings?.listOfRevHighlights.enabled);

  const resetState = () => {
    setSelectedPdfRange(SelectedPdfRange.NONE);
    setSelectedVariant('');
    resetOptions();
  };

  const resetOptions = () => {
    setPrivateOptions({ ...INIT_STATE });
    setGeneralOptions({ ...INIT_STATE });
  };

  React.useEffect(() => {
    if (!props.show) {
      resetState();
    } else {
      setSelectedVariantInFileMenu(ProjectStore.getSelectedVariantUid());
    }
  }, [props.show]);

  React.useEffect(() => {
    resetOptions();
  }, [selectedPdfRange]);

  const handleExport = () => {
    EditorStore.blurEditor(() => {
      props.onRequestClose();
      switch (selectedPdfRange) {
        case SelectedPdfRange.SELECTION:
          return exportAsPdf(TocStore.getSelectedItem());
        case SelectedPdfRange.ENTIRE:
          return exportAsPdf();
        case SelectedPdfRange.REVISION_HIGHLIGHTS:
          return exportHighlightsAsPdf();
      }
    });
  };

  const exportHighlightsAsPdf = () => {
    const project = ProjectStore.getProject()!;
    NotificationsStore.startNotifProgress();
    projectClient.exportPdf(project.uid, ProjectStore.getCurrentRevisionUid()!, {
      generator: 'prince',
      variantUid: getVariantUid(props.variants, selectedVariant, selectedVariantInFileMenu),
      privateRevisionHighlightsChecked: privateOptions.checked,
      privateRevisionHighlightsValue: privateOptions.value,
      generalRevisionHighlightsChecked: generalOptions.checked,
      generalRevisionHighlightsValue: generalOptions.value
    });
  };

  const exportAsPdf = (selection: ITocNode | null = null) => {
    const project = ProjectStore.getProject()!;
    if (!project) {
      return;
    }
    NotificationsStore.startNotifProgress();
    fetchPdf(project.uid, selection);
  };

  const fetchPdf = (projectUid: string, selection: ITocNode | null) => {
    projectClient.exportPdf(projectUid, ProjectStore.getCurrentRevisionUid()!, {
      generator: 'prince',
      selectionUid: !!selection && selection.uid ? selection.uid : undefined,
      variantUid: getVariantUid(props.variants, selectedVariant, selectedVariantInFileMenu)
    });
  };

  const closeDialog = () => {
    props.onRequestClose();
  };

  return (
    <Dialog
      bodyStyle={{ padding: '0' }}
      contentStyle={{ width: '30%', minWidth: '250px' }}
      className="export-doc-modal"
      open={props.show}
      onRequestClose={closeDialog}
      repositionOnUpdate={false}
    >
      <DialogHeader label="Export Options" onClose={closeDialog} />
      <div className="inner-content">
        <div id="range-selector" data-qa-selected-value={selectedPdfRange}>
          <RangeSection
            showRevisionHighlights={hasHighlights}
            isFetching={isFetching}
            canExportPdf={canExportPdf}
            onSelectedPdfRange={setSelectedPdfRange}
          />
        </div>
        <VerticalSlide open={isHighlightsRangeSelected}>
          <div>
            <RevisionHighlightsSection
              privateOptions={privateOptions}
              onPrivateOptionChange={setPrivateOptions}
              generalOptions={generalOptions}
              onGeneralOptionChange={setGeneralOptions}
            />
          </div>
        </VerticalSlide>
        {hasVariants && (
          <VariantsSection variants={props.variants} onSelectVariant={setSelectedVariant} selected={selectedVariantInFileMenu} />
        )}

        <div className="buttons-section">
          <FlatButton className="cancel-button" label="Cancel" onClick={closeDialog} />
          <FlatButton
            disabled={submitDisabled || isFetching}
            className="export-button"
            backgroundColor="#59b957"
            hoverColor="#438741"
            label="Export PDF"
            onClick={handleExport}
          />
        </div>
      </div>
    </Dialog>
  );
};
