import { ITocNode, IUnit } from 'mm-types';
import TocStore from '../../../../../flux/editor/TocStore';
import { getSelectedClosestTocable } from '../../../../hoc/common';

export type Tree = {
  parent?: ITocNode;
  current?: ITocNode;
};

/* Dynamic Toc Insert Rules
 * - psl can be placed inside another psl only as a sibling to a psl, if psl has other children cannot insert psl as child
 * - Group can only be placed inside psl, can be sibling of group or invariant. Only can insert an invariant inside group
 * - Invariant can only be placed inside a psl or group, can only be sibling with group or invariant, only place a section inside
 * N.B Inserts happen as normal, straight after selected unit
 */
export function shouldPresentOption(insertUnitType: string, currentUnit: IUnit): null | Tree[] {
  if (insertUnitType === 'psl') {
    return pslInsertHasOptions(currentUnit);
  } else if (insertUnitType === 'invariant') {
    return invariantInsertHasOptions(currentUnit);
  }
  return null;
}

function invariantInsertHasOptions(currentUnit: IUnit): null | Tree[] {
  const currentUnitClosestTocable = getSelectedClosestTocable(currentUnit);
  if (currentUnitClosestTocable) {
    const currentUnitTocInfo = TocStore.getTocItem(currentUnitClosestTocable.uid);
    const validTocInserts = getInvariantValidInsertsFromToc(currentUnitTocInfo, []);
    return validTocInserts;
  }
  return null;
}

function getInvariantValidInsertsFromToc(toc: ITocNode, validTocInserts: Tree[]): Tree[] {
  if (toc.definitionId === 'group' || toc.definitionId === 'psl') {
    // if toc has no children or only group or invariant, can insert inside
    if (tocHasOnlyGroupOrInvariantChildren(toc.children) || toc.children.length == 0) {
      validTocInserts.push({ parent: toc });
    }
  }
  if (toc.parent) {
    return getInvariantValidInsertsFromToc(toc.parent, validTocInserts);
  } else {
    return validTocInserts;
  }
}

function pslInsertHasOptions(currentUnit: IUnit): null | Tree[] {
  const currentUnitClosestTocable = getSelectedClosestTocable(currentUnit);
  if (currentUnitClosestTocable) {
    const currentUnitTocInfo = TocStore.getTocItem(currentUnitClosestTocable.uid);
    const validTocInserts = getPslValidInsertsFromToc(currentUnitTocInfo, []);
    return validTocInserts;
  }
  return null;
}

function getPslValidInsertsFromToc(toc: ITocNode, validTocInserts: Tree[]): Tree[] {
  if (toc.definitionId === 'psl') {
    // If current toc has no children or only psl children. Can insert of current toc
    if (toc.children.length === 0 || tocHasNoNonPslChildren(toc.children)) {
      validTocInserts.push({ parent: toc });
    }
  }
  if (toc.parent) {
    return getPslValidInsertsFromToc(toc.parent, validTocInserts);
  } else {
    // at root level, allow insert of psl at root as sibling
    validTocInserts.push({ current: toc });
    return validTocInserts;
  }
}

export function tocHasNoNonPslChildren(children: ITocNode[]): boolean {
  return children.filter((toc) => toc.definitionId !== 'psl').length === 0;
}
function tocHasOnlyGroupOrInvariantChildren(children: ITocNode[]): boolean {
  return children.filter((toc) => toc.definitionId !== 'group' && toc.definitionId !== 'invariant').length === 0;
}
