import * as React from 'react';
import { IElementDefinition } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

export function useLineBreakElementDefinition(): IElementDefinition | undefined {
  const [lineBreakDefinition, setLineBreakDefinition] = React.useState<IElementDefinition | undefined>(
    ProjectDefinitionStore.getElementDefinitionById('LineBreak')
  );

  React.useEffect(() => {
    setLineBreakDefinition(ProjectDefinitionStore.getElementDefinitionById('LineBreak'));
  }, []);

  return lineBreakDefinition;
}
