import React from 'react';
import RaisedButtonWithTooltip from '../../../../general/RaisedButtonWithTooltip';
import { ActionType } from '../../MenuWorkflow';
import { MenuElement, MenuSeparator } from '../../EditorMenu/MenuElements';

interface Props {
  isWorkflowFinished: boolean;
  isValidForPublish: boolean;
  isReadOnly: boolean;
  indicators: JSX.Element;
  onPerformAction(action: ActionType, label: string): void;
  onPublishWhatsNew(e: React.MouseEvent<any>): void;
}

export function PublishStage(props: Props) {
  return (
    <>
      {props.indicators}

      <MenuSeparator />

      {props.isWorkflowFinished && (
        <MenuElement info={true} onClick={() => props.onPerformAction('SUMMARY', 'Summary')} dataQa="menu-element-workflow-finished">
          <div className="title">Workflow Finished</div>
        </MenuElement>
      )}

      {!props.isWorkflowFinished && !props.isReadOnly && (
        <MenuElement exportButtons={true} actionButtons={true} onRight={true} publishAction={true} dataQa="menu-element-publish">
          <RaisedButtonWithTooltip
            label="Publish"
            labelColor="#FFFFFF"
            backgroundColor="#009688"
            onClick={(e) => props.onPublishWhatsNew(e)}
            iconClass="done"
            disabled={!props.isValidForPublish}
          ></RaisedButtonWithTooltip>
        </MenuElement>
      )}
    </>
  );
}
