import * as React from 'react';
import EditorStore from '../../../../flux/editor/EditorStore';
import { IUnit } from 'mm-types';

type Props = {
  unit: IUnit;
  isShareActionAllowed?: boolean;
};

export const isActionEnabled = (predicate?: boolean): string => `action ${predicate ? '' : 'disabled'}`;
export const isDiffActionToggled = (predicate?: boolean): string => `action diff ${predicate ? 'active' : ''}`;

export const SharePublishMenu = (props: Props) => {
  const { unit, isShareActionAllowed = true } = props;
  const { shareDetails, isVisibleOnEdit } = unit;

  const isOriginDiffersFromSharedIndex = () =>
    shareDetails && shareDetails.uid && shareDetails.origin && shareDetails.isShareStartUnit && shareDetails.originDiffersFromSharedIndex;

  return isVisibleOnEdit && isOriginDiffersFromSharedIndex() ? (
    <div className="unit-shared-rhs-menu unit-shared-publish-menu">
      <div className={isActionEnabled(isShareActionAllowed)} onClick={() => EditorStore.publishSharedOrigin(shareDetails!)}>
        <i className="material-icons">done</i> Push to Library
      </div>
      <div className={isActionEnabled(isShareActionAllowed)} onClick={() => EditorStore.revertSharedOrigin(shareDetails!)}>
        <i className="material-icons">reply</i> Revert
      </div>
      <div
        className={isDiffActionToggled(shareDetails?.inDiffMode)}
        onClick={() => EditorStore.diffSharedContentUpdate(unit.uid, { ...shareDetails!, inDiffMode: !shareDetails?.inDiffMode })}
      >
        <i className="material-icons icon-diffing" /> Diff
      </div>
    </div>
  ) : null;
};
