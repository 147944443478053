import * as React from 'react';
import ChangeTasksStore from '../../flux/editor/ChangeTasksStore';
import PopupContainer from './PopupContainer';
import Checkbox from 'material-ui/Checkbox';
import { ITask } from 'mm-types';
import { SvgDropdownIcon } from './icons/SvgDropdownIcon';

export type Props = {
  title?: string;
  assignedTasks?: ITask[];
  allowChangeDeactivatedTasks?: boolean;
  onChange?: (e: { taskUid: string; task: ITask; assigned: boolean }) => void; // trigger when it changes checks with what was toggled
  onClose?: (mutableAssignedTasks: string[]) => void;
};

export type State = {
  open: boolean;
  projectTasks: ITask[];
  mutableAssignedTasks: string[];
};

export default class ChangeTaskDropDown extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    title: 'Task Tag',
    assignedTasks: [],
    allowChangeDeactivatedTasks: false
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      projectTasks: ChangeTasksStore.getInitialState().tasks,
      mutableAssignedTasks: []
    };
  }

  UNSAFE_componentWillMount() {
    this._resetStateFromProperties(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._resetStateFromProperties(nextProps);
  }

  open(e: React.ReactInstance) {
    if (!this.state.open) {
      this.setState({ open: true }, () => {
        (this.refs.unitTagsPopup as PopupContainer).setTriggerButton(e).open();
      });
    }
  }

  close(e: React.MouseEvent<HTMLElement>) {
    if (this.state.open) {
      this._handleClose();
    }

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  private _resetStateFromProperties(props: Props) {
    const mutableAssignedTasks: string[] = [];
    props.assignedTasks!.forEach((task) => {
      mutableAssignedTasks.push(task.uid);
    });

    this.setState({ mutableAssignedTasks: mutableAssignedTasks });
  }

  private _isTagged(taskUid) {
    return this.state.mutableAssignedTasks.indexOf(taskUid) !== -1;
  }

  private _handleChange(e, task) {
    const tagIndex = this.state.mutableAssignedTasks.indexOf(task.uid);
    if (tagIndex !== -1) {
      this.state.mutableAssignedTasks.splice(tagIndex, 1);
    } else {
      if (this.props.allowChangeDeactivatedTasks || task.active) {
        // only allow 'check' if task is active (and property flag allows)
        this.state.mutableAssignedTasks.push(task.uid);
      }
    }

    this.setState({ mutableAssignedTasks: this.state.mutableAssignedTasks }, () => {
      if (this.props.onChange) {
        this.props.onChange({ taskUid: task.uid, task: task, assigned: tagIndex === -1 });
      }
    });

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  private _handleClose() {
    this.setState({ open: false }, () => {
      if (this.props.onClose) {
        this.props.onClose(this.state.mutableAssignedTasks);
      }
    });
  }

  render() {
    return this.state.open ? (
      <PopupContainer
        ref="unitTagsPopup"
        className="check-dropdown"
        onClose={() => {
          this._handleClose();
        }}
      >
        <div
          className="check-dropdown-title"
          onClick={(e) => {
            this.close(e);
          }}
        >
          <div>{this.props.title}</div>
          <SvgDropdownIcon />
        </div>
        <div className="check-dropdown-inner">
          {this.state.projectTasks.map((task) => {
            return (
              <div
                key={task.uid}
                onClick={(e) => {
                  this._handleChange(e, task);
                }}
                className={task.active ? '' : 'disabled'}
              >
                <div
                  className={'task-tag task-tag-fullsize' + (task.active ? '' : ' task-disabled')}
                  style={{ backgroundColor: task.colorHex }}
                >
                  <span className="icon-tasks"></span>
                  <span id="task-checkbox-identifier">{task.identifier}</span>
                  <span className="icon-task-tag-background"></span>
                </div>
                <Checkbox checked={this._isTagged(task.uid)} disabled={!task.active} />
              </div>
            );
          })}
        </div>
      </PopupContainer>
    ) : null;
  }
}
