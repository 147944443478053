import React from 'react';
import { INotificationListItemProps } from './INotificationListItemProps';

const ExtLinkDeleted = ({ notif, goToDeletedLink }: INotificationListItemProps) => {
  return (
    <div className="notif-item-container">
      <div className="left-side">
        <span className="icon material-icons">delete</span>
      </div>

      <div className="middle-side">
        <div className="popup-title-container">
          <h1 className="warning">Link Target Removed Warning</h1>
          <h2>Inter-document link target removed</h2>
          <div className="info">
            Link <b>{notif.link?.caption}</b> has been deleted in a project <b>{notif.link?.projectName}</b>
          </div>
        </div>
      </div>

      <div className="right-side">
        <div className="popup-date-container">
          <h5>{notif.receivedFormatAgo}</h5>
        </div>
        <div className="notif-item notif-item-done">
          <i
            className="material-icons"
            onClick={() => {
              if (goToDeletedLink) {
                goToDeletedLink(notif);
              }
            }}
          >
            link
          </i>
        </div>
      </div>
    </div>
  );
};

export default ExtLinkDeleted;
