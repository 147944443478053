import * as React from 'react';
import { AeroIconButtonDownload } from '../../../../general/AeroIconButton';
import { Chip, ChipProps, CircularProgress } from 'material-ui';

interface Props {
  filename: string;
  uploading?: boolean;
  onDownloadSource?(): any;
  onDeleteSource?(): any;
}

const SourceItem = (props: Props) => {
  const chipProps: ChipProps = {
    className: 'media-src-pill'
  };
  if (props.onDeleteSource) {
    chipProps.onRequestDelete = props.onDeleteSource;
  }
  return (
    <li>
      <Chip {...chipProps}>
        <span title={props.filename}>{props.filename}</span>
      </Chip>
      {props.uploading ? (
        <CircularProgress size={24} thickness={2} style={{ float: 'right', marginTop: 4, marginRight: 11 }} />
      ) : (
        <AeroIconButtonDownload onClick={props.onDownloadSource} />
      )}
    </li>
  );
};

export default SourceItem;
