import React from 'react';
import { IProject } from 'mm-types';

interface Props {
  project: IProject;
  loading: boolean;
}

const ProjectStatusSummary = ({ project, loading }: Props) => {
  const org = loading ? ' ' : project.org ? project.org.name : '-';
  const department = loading ? ' ' : project.department ? project.department.name : '-';
  return (
    <ul className="info">
      <li>
        <label>Status</label>
      </li>
      <li className="project-status">{project.status}</li>
      <li>
        <label>Created</label>
      </li>
      <li className="project-created" data-qa="doc-info-project-created">
        {project.createdFormatted}
      </li>
      <li>
        <label>Doc Type</label>
      </li>
      <li className="project-doc-type">{project.definitionDescription}</li>
      <li>
        <label>Organization</label>
      </li>
      <li className="project-org" style={{ minHeight: 21 }}>
        {org}
      </li>
      <li>
        <label>Department</label>
      </li>
      <li className="project-department" style={{ minHeight: 21 }}>
        {department}
      </li>
      <li>
        <label>Reference</label>
      </li>
      <li className="project-reference">{project.docReference ? project.docReference.displayName : '-'}</li>
      <li>
        <label>Teamspace</label>
      </li>
      <li className="project-teamspace">{project.workspaceName}</li>
    </ul>
  );
};

export default ProjectStatusSummary;
