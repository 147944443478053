import { UNIT_ELEMENT_ATTRIBUTE_NAME, UNIT_ELEMENT_CLASS_NAME } from '../units/const/UnitElementSelectors';

export namespace CSSSelector {
  export function className(names: string | string[]): string {
    if (!Array.isArray(names)) {
      names = [names];
    }
    return `.${names.join('.')}`;
  }
  export function unit(): string {
    return className(UNIT_ELEMENT_CLASS_NAME.UNIT);
  }

  export function editTarget(): string {
    return className(UNIT_ELEMENT_CLASS_NAME.EDIT_TARGET);
  }

  export function dataNid(nid: string): string {
    return attribute([UNIT_ELEMENT_ATTRIBUTE_NAME.NID, nid]);
  }

  type AttrItem = [string, string?];
  export function attribute(attrs: string | AttrItem | AttrItem[]): string {
    if (!attrs || !attrs.length) {
      throw 'Provide attribute';
    }

    function getSelector(attr: AttrItem) {
      let [name, value] = attr;
      value = value ? `="${value}"` : '';
      return `[${name}${value}]`;
    }

    if (isAttrItem(attrs)) {
      return getSelector(attrs);
    }
    if (isString(attrs)) {
      return getSelector([attrs]);
    }

    let result = '';
    attrs.forEach((attr) => {
      result += getSelector(attr);
    });
    return result;
  }

  function isAttrItem(attr: string | AttrItem | AttrItem[]): attr is AttrItem {
    return isString(attr) ? false : typeof attr[0] === 'string';
  }

  function isString(attr: string | AttrItem | AttrItem[]): attr is string {
    return typeof attr === 'string';
  }
}
