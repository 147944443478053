import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';

export type LightsValue = { text: string };
export type LightsValues = LightsValue[];

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.LIGHTS> = {
  id: NNCIndexMetadataID.LIGHTS,
  toMetadataHTML: (values) => {
    let lights = '';
    values.forEach(({ text }) => {
      lights += `<div class="light-data">${text}</div>`;
    });
    return `<div class="${metadataDefinition.id}">${lights}</div>`;
  },
  toJSON: ($element: JQuery<HTMLElement>) => {
    const result: LightsValues = [];
    $element.children().each(function () {
      result.push({
        text: $(this).text()
      });
    });
    return result;
  }
};
