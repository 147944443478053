import { AATProjectActivity } from '../components/AATProjectActivity';
import { DocTeamDetails } from '../components/DocTeamDetails';
import { WorkflowDetails } from '../components/WorkflowDetails';
import { AttachedFiles } from '../components/AttachedFiles';
import { RevisionDownload } from '../components/RevisionDownload';
import { RevisionUpload } from '../components/RevisionUpload';
import { DiscardedDraft } from '../components/DiscardedDraft';
import { SharedIndex } from '../components/SharedIndex';
import { Project } from '../components/Project';
import { FrontmatterMoved } from '../components/FrontmatterMoved';
import { WorkflowReminder } from '../components/WorkflowReminder';
import { GroupedTaskUpdates } from '../components/GroupedTaskUpdates';
import { Interim } from '../components/Interim';
import { Reg } from '../components/Reg';
import { SpecialInterestGroups } from '../components/SpecialInterestGroups';
import { SharedIndexUsage } from '../components/SharedIndexUsage';
import { TaskUpdate } from '../components/TaskUpdate';
import { TaskReminder } from '../components/TaskReminder';
import { TaskParticipantUpdate } from '../components/TaskParticipantUpdate';
import { LockedStatus } from '../components/LockedStatus';
import { Approval } from '../components/Approval';
import { Publication } from '../components/Publication';
import { WorkflowAssignment } from '../components/WorkflowAssignment';
import { SettingsDetails } from '../components/SettingsDetails';
import { WorkflowReview } from '../components/WorkflowReview';
import { WorkflowTransition } from '../components/WorkflowTransition';
import { WorkflowSettings } from '../components/WorkflowSettings';
import { AutoSkippedWorkflow } from '../components/AutoSkippedWorkflow';
import { SkippedWorkflow } from '../components/SkippedWorkflow';
import { AdminActivityType } from '../../../../../types/models/IProjectActivity';

export function getComponentName(projectActivityType: AdminActivityType) {
  switch (projectActivityType) {
    case 'SETTINGS':
    case 'INDEX_SETTINGS':
    case 'REVISION_SETTINGS':
    case 'PROJECT_SETTINGS':
    case 'PROJECT_CREATED':
      return SettingsDetails;
    case 'DOC_TEAM':
    case 'DOC_TEAM_MEMBER_ADD':
    case 'DOC_TEAM_MEMBER_UPDATE':
    case 'DOC_TEAM_MEMBER_DELETE':
    case 'DOC_TEAM_UPDATE':
      return DocTeamDetails;
    case 'WORKFLOW_TRANSITION':
      return WorkflowTransition;
    case 'WORKFLOW_REVIEW':
      return WorkflowReview;
    case 'WORKFLOW_STAGE_SETTINGS_UPDATED':
    case 'SYSTEM_WORKFLOW_STAGE_SETTINGS_UPDATED':
    case 'SYSTEM_WORKFLOW_SETTINGS_UPDATED':
      return WorkflowSettings;
    case 'WORKFLOW_AUTOMATICALLY_SKIPPED':
      return SkippedWorkflow;
    case 'WORKFLOW_APPROVE_SKIP':
    case 'WORKFLOW_STAGE_AUTOMATICALLY_SKIPPED':
      return AutoSkippedWorkflow;
    case 'WORKFLOW_ASSIGNMENT_ADDED':
    case 'WORKFLOW_ASSIGNMENT_UPDATE':
    case 'WORKFLOW_ASSIGNMENT_REMOVED':
      return WorkflowAssignment;
    case 'PUBLICATION':
      return Publication;
    case 'APPROVAL_INFO_UPDATE':
      return Approval;
    case 'INDEX_LOCKED_STATUS_UPDATED':
      return LockedStatus;
    case 'TASK_PARTICIPANT_UPDATE':
    case 'TASK_PARTICIPANT_DELETE':
    case 'TASK_PARTICIPANT_ADD':
      return TaskParticipantUpdate;
    case 'TASK_NUDGE_REMINDER':
    case 'TASK_POKE_REMINDER':
      return TaskReminder;
    case 'TASK_DELETE':
    case 'TASK_UPDATE':
    case 'TASK_ADD':
      return TaskUpdate;
    case 'SHARED_INDEX_USAGE_CREATED':
    case 'SHARED_INDEX_USAGE_ACTION':
    case 'SHARED_INDEX_USAGE_UNITS_UPDATED':
    case 'SHARED_INDEX_USAGE_UPDATED':
    case 'SHARED_INDEX_USAGE_DELETED':
      return SharedIndexUsage;
    case 'SPECIAL_INTEREST_GROUPS_UPDATED':
    case 'SPECIAL_INTEREST_GROUPS_DELETED':
      return SpecialInterestGroups;
    case 'INTERIM_CREATED':
    case 'INTERIM_DELETED':
      return Interim;
    case 'REG_CREATED':
    case 'REG_UPDATED':
      return Reg;
    case 'GROUPED_TASK_UPDATES':
      return GroupedTaskUpdates;
    case 'WORKFLOW_NUDGE_REMINDER':
    case 'WORKFLOW_POKE_REMINDER':
      return WorkflowReminder;
    case 'FRONTMATTER_MOVED':
      return FrontmatterMoved;
    case 'PROJECT_DELETED':
    case 'PROJECT_TRASHED':
    case 'PROJECT_RESTORED':
      return Project;
    case 'SHARED_INDEX_CREATED':
    case 'SHARED_INDEX_UPDATED':
    case 'SHARED_INDEX_DELETED':
    case 'SHARED_INDEX_CONTENT_UPDATED':
      return SharedIndex;
    case 'DRAFT_DISCARDED':
      return DiscardedDraft;
    case 'EXT_UPLOAD':
      return RevisionUpload;
    case 'EXT_DOWNLOAD':
      return RevisionDownload;
    case 'ATTACHMENT_CREATED':
    case 'ATTACHMENT_UPDATED':
    case 'ATTACHMENT_DELETED':
      return AttachedFiles;
    case 'AAT_UPDATED':
      return AATProjectActivity;
    case 'WORKFLOW':
    default:
      return WorkflowDetails;
  }
}
