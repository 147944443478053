import * as React from 'react';
import EffectivitiesList from './EffectivitiesList';
import ManufacturerRange from './ManufacturerRange';
import { IEffectivity, IMsnRange } from 'mm-types';

export type Props = {
  assignedEffectivities: IEffectivity[];
  availableEffectivities: IEffectivity[];
  msnRange: IMsnRange | null;
  remove: (effectivities: IEffectivity[]) => void;
  add: (effectivities: IEffectivity[]) => void;
  isReadOnly: boolean;
  loading: boolean;
};

const Effectivities = (props: Props) => {
  return (
    <>
      <EffectivitiesList
        loading={props.loading}
        isReadOnly={props.isReadOnly}
        title="Assigned Effectivities"
        effectivities={props.assignedEffectivities}
        action={props.remove}
        icon="remove"
      />
      <EffectivitiesList
        loading={props.loading}
        isReadOnly={props.isReadOnly}
        title="Add Effectivity"
        effectivities={props.availableEffectivities}
        action={props.add}
        icon="add"
      />
      <ManufacturerRange msnRange={props.msnRange} />
    </>
  );
};

export default Effectivities;
