import * as React from 'react';
import Searchbar from '../searchbar/Searchbar';
import Tag from './facetTag';
import FacetedTagsTree from './facetedTagsTree';
import UnitConceptStore from '../../../../../flux/editor/UnitConceptStore';
import { ISortedFacetFamily, IComparableSearchTerm, IUnitConceptMap, IAssignedSearchTerm, IUnit, IEditorStoreEvent } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { CircularProgress } from 'material-ui';
import ProjectStore from '../../../../../flux/editor/ProjectStore';

export type Props = {
  /** The uid of an attachment you want to scroll to when the attachments load */
  attachmentJumpId?: string;
};

export type UnitConceptStoreEvent = {
  type: 'retrieving-unit-facet-map' | 'retrieved-unit-facet-map' | 'retrieved-search-facets';
  state: State;
};

export type State = {
  facetFamilies: ISortedFacetFamily[] | null;
  matchedConcepts: IComparableSearchTerm[];
  unitConceptMaps: { [uid: string]: IUnitConceptMap } | null;
  selectedUnits: IUnit[];
  loading: boolean;
  expandFamilies: boolean;
  searchTerm: string;
};

export default class FacetedTags extends React.Component<Props, State> {
  private unsubscribe: Function;
  private unsubEditor: Function;

  constructor(props: Props) {
    super(props);

    this.unsubscribe = UnitConceptStore.listen(this._onStoreChange, this);
    this.unsubEditor = EditorStore.listen(this._onEditStoreUpdate, this);

    this.state = {
      facetFamilies: [],
      expandFamilies: false,
      unitConceptMaps: null,
      matchedConcepts: [],
      selectedUnits: [],
      loading: false,
      searchTerm: ''
    };
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubEditor();
  }

  componentDidMount() {
    UnitConceptStore.retrieveSearchFacets();

    let filtedUnits = EditorStore.getSelectedUnits();
    filtedUnits = this.getFilteredUnits(filtedUnits);

    this.setState({
      loading: true,
      selectedUnits: filtedUnits
    });
  }

  private getFilteredUnits(units: IUnit[]) {
    return units.filter((u: IUnit) => ['frontmatter', 'removed', 'ghost'].indexOf(u.type) === -1);
  }

  _onStoreChange(event: UnitConceptStoreEvent) {
    switch (event.type) {
      case 'retrieved-search-facets':
        this.setState({
          facetFamilies: event.state.facetFamilies,
          loading: false
        });
        break;

      case 'retrieving-unit-facet-map':
        this.setState({
          unitConceptMaps: event.state.unitConceptMaps,
          loading: true
        });
        break;

      case 'retrieved-unit-facet-map':
        this.setState({
          unitConceptMaps: event.state.unitConceptMaps,
          loading: false
        });
        break;
    }
  }

  private addTag(tag: IComparableSearchTerm) {
    const units = EditorStore.getSelectedUnits();
    const docParams = EditorStore.getDocParams();
    this.setState({ loading: true });

    UnitConceptStore.addConceptToUnit(
      tag,
      docParams,
      units.filter((unit) => unit.type !== 'ghost' && unit.type !== 'removed').map((unit) => unit.uid)
    );
  }

  private _onEditStoreUpdate(e: IEditorStoreEvent<'unitsSelected'>) {
    if (e.type === 'unitsSelected') {
      const filtedUnits = this.getFilteredUnits(e.data!.selectedUnits);
      this.setState({ selectedUnits: filtedUnits });
    }
  }

  private removeTag(deletedTag: IAssignedSearchTerm) {
    const units = this.state.selectedUnits;
    const docParams = EditorStore.getDocParams();
    this.setState({ loading: true });
    UnitConceptStore.removeConceptFromUnit(
      deletedTag,
      docParams,
      units.map((u) => u.uid)
    );
  }

  private filterBySearch(searchTerm: string) {
    const matchedConcepts: IComparableSearchTerm[] = [];
    searchTerm = searchTerm.trim().toLowerCase();

    if (searchTerm === '') {
      this.setState({
        matchedConcepts: matchedConcepts,
        searchTerm: ''
      });

      return;
    } else {
      const families = this.state.facetFamilies!;
      const splittedSearchTerms: string[] = searchTerm.split(' ');
      let regexStr: string;

      if (splittedSearchTerms.length > 1) {
        regexStr = splittedSearchTerms.map((term) => `(${term})`).join('|');
        regexStr = `(${regexStr})`;
      } else {
        regexStr = splittedSearchTerms[0];
      }

      let regex: RegExp | null = null;
      // This can throw errors if the user puts in bad stuff
      regex = new RegExp(regexStr, 'ig');

      // Go through each family and concept
      for (const fam of families) {
        for (const concept of fam.concepts) {
          // Flatten the family name -> alias -> concept name into a single string to test
          const toTest = fam.name + ' > ' + (concept.alias || '') + ' > ' + concept.name;
          let matches = regex ? toTest.match(regex) : [];

          // Now match against the regex & make sure the same number of terms exist
          if (matches && matches.length >= splittedSearchTerms.length) {
            let pureMatch = true;
            const matchedTerms: string[] = matches.map((m) => m.toLowerCase());

            // Make sure the matches match perfectly the search terms
            for (const term of splittedSearchTerms) {
              if (matchedTerms.indexOf(term) === -1) {
                pureMatch = false;
              }
            }

            if (pureMatch) {
              matchedConcepts.push(concept);
            }
          }
        }
      }
    }
    this.setState({
      expandFamilies: true,
      matchedConcepts: matchedConcepts,
      searchTerm: searchTerm
    });
  }

  getTagsMessage(type: string) {
    switch (type) {
      case 'no-families':
        return (
          <div className="no-tags">
            There are no Tag Families assigned to this document. To add Tag Families, go to{' '}
            <i>
              <b>Document Settings / Metadata.</b>
            </i>
          </div>
        );

      case 'no-units':
        return 'No Units selected. Select Units to assign tags to this document';

      case 'no-tags':
        return 'You have no tags assigned. To assign tags to Units, select tags from your tag list below.';
    }
  }

  render() {
    const isInRevMode = EditorStore.isReadOnly() || ProjectStore.isInterim();
    const familiesExist = this.state.facetFamilies && this.state.facetFamilies.length;
    let unitsSelected = this.state.selectedUnits.length;
    let currentSelectedUnits = this.state.selectedUnits.length > 0 ? this.state.selectedUnits : null;
    const terms: IAssignedSearchTerm[] = [];
    const editableTags: IAssignedSearchTerm[] = [];
    const readOnlyTags: IAssignedSearchTerm[] = [];
    const tagsAppliedToAllUnits = {};
    const commonTagIds: string[] = [];
    if (currentSelectedUnits) {
      currentSelectedUnits.forEach((unit) => {
        const unitMap: IUnitConceptMap = UnitConceptStore.getUnitConceptMap(unit.uid);
        if (unitMap) {
          unitMap.searchTerms.forEach((tag) => {
            // Determine which of the tags have been applied directly anw which are inherited
            if (tag.editable) {
              editableTags.push(tag);
            } else {
              readOnlyTags.push(tag);
            }

            // Determine which of the tags are applied to every selected doc unit
            if (!tagsAppliedToAllUnits[tag.uid!]) {
              tagsAppliedToAllUnits[tag.uid!] = [unit.uid];
            } else if (!~tagsAppliedToAllUnits[tag.uid!].indexOf(unit.uid) && tag.editable) {
              tagsAppliedToAllUnits[tag.uid!].push(unit.uid);
            }
          });
        }
      });
      for (const tagId in tagsAppliedToAllUnits) {
        if (tagsAppliedToAllUnits[tagId].length === currentSelectedUnits.length) {
          commonTagIds.push(tagId);
        }
      }

      terms.push(
        ...editableTags.filter((obj, pos, arr) => {
          return arr.map((mapObj) => mapObj.uid).indexOf(obj.uid) === pos;
        })
      );
      terms.push(
        ...readOnlyTags.filter((obj, pos, arr) => {
          return arr.map((mapObj) => mapObj.uid).indexOf(obj.uid) === pos;
        })
      );
    }

    if (isInRevMode) {
      unitsSelected = 0;
      currentSelectedUnits = null;
    }

    return (
      <div className={`faceted-tags subaction-list-container ${this.state.loading ? 'busy' : ''}`}>
        <h5>
          Tag Assignment{' '}
          {this.state.loading ? (
            <div className="facets-loading">
              <CircularProgress size={16} thickness={3} />
            </div>
          ) : undefined}
        </h5>
        <div className="border" />
        <div className="facet-tags-header">
          <div className={`tag-list`}>
            {this.state.loading ? null : !familiesExist ? (
              this.getTagsMessage('no-families')
            ) : !unitsSelected && !isInRevMode && familiesExist ? (
              <div className="no-units">{this.getTagsMessage('no-units')}</div>
            ) : null}
            {unitsSelected && terms.length === 0 && familiesExist ? <div className="no-tags">{this.getTagsMessage('no-tags')}</div> : null}

            {terms.map((tag) => {
              return (
                <Tag
                  term={tag}
                  inRevisionMode={isInRevMode}
                  key={`${tag.uid!}${tag.editable ? '-editable' : '-readOnly'}`}
                  onRemoveTag={(tag) => {
                    if (this.state.loading) {
                      return;
                    }

                    this.removeTag(tag);
                  }}
                />
              );
            })}
          </div>

          {unitsSelected && familiesExist ? (
            <Searchbar
              onSearch={(term) => this.filterBySearch(term)}
              hasCancel={false}
              placeholder="Filter tags"
              triggerOnChange={true}
              onClose={() => this.setState({})}
            />
          ) : undefined}
        </div>
        {unitsSelected && familiesExist ? (
          <FacetedTagsTree
            loading={this.state.loading}
            expandFamilies={this.state.expandFamilies}
            groupSelectedConceptUids={commonTagIds}
            addTag={(tag: IComparableSearchTerm) => this.addTag(tag)}
            families={this.state.facetFamilies}
            matchedConcepts={this.state.searchTerm !== '' ? this.state.matchedConcepts : null}
          />
        ) : undefined}
      </div>
    );
  }
}
