import * as React from 'react';
import revutil from './revutil';
import ProjectStore from '../../../flux/editor/ProjectStore';
import { IElementHighlight } from 'mm-types';

export type Props = {
  elementHighlight: IElementHighlight;
  toggleElement: (nid: string, e: { height: number; top: number }) => void;
};

export type State = {
  height: number;
  top: number;
  hasPosition: boolean;
};

export default class RevBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
      top: 0,
      hasPosition: false
    };
  }

  UNSAFE_componentWillMount() {
    this._initPosition();
  }

  UNSAFE_componentWillReceiveProps() {
    this._initPosition();
  }

  _initPosition() {
    const offsetNode = this._getOffsetNode() as HTMLElement;

    if (!offsetNode) {
      this.setState({ hasPosition: false });
    } else {
      const height = offsetNode.offsetHeight;
      const top = revutil.calc_offset_top(offsetNode, 'document-unit-outer');
      this.setState({ hasPosition: true, top: top, height: height });
    }
  }

  _getOffsetNode() {
    const unitEl = document.getElementById('_' + this.props.elementHighlight.unitUid);
    const found = unitEl?.querySelectorAll("*[data-nid='" + this.props.elementHighlight.nid + "']") ?? null;
    if (found?.length && (found[0].clientHeight > 0 || (found[0] as HTMLElement).offsetHeight)) {
      return found[found.length - 1];
    }
  }

  _handleClick() {
    this.props.toggleElement(this.props.elementHighlight.nid, {
      height: this.state.height,
      top: this.state.top
    });
  }

  render() {
    if (this.state.hasPosition) {
      const className = 'revbar-element' + (this.props.elementHighlight.ignored ? ' ignored' : '');
      const style: React.CSSProperties = {
        height: this.state.height + 'px',
        top: this.state.top + 'px'
      };

      if (
        !ProjectStore.getProject()?.currentUserPermissions?.canAuthor ||
        !ProjectStore.getProject()?.currentUserPermissions.canManageComplianceTags
      ) {
        style.cursor = 'default !important;';
      }

      return (
        <span
          style={style}
          className={className}
          data-revelem={this.props.elementHighlight.nid}
          data-revunit={this.props.elementHighlight.unitUid}
          onClick={(e) => this._handleClick()}
        />
      );
    } else {
      // no offset to attach to, nothing to do
      return <span style={{ display: 'none' }} />;
    }
  }
}
