import { EditorMode } from './EditorMode';
import { Banner, EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class TocManMode extends EditorMode {
  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.submenuTabs | attributes.mainmenuTabs | attributes.shareTab, syncQueue);
  }

  getBanner(): Banner {
    return { title: 'Document Navigator Mode' };
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    if (previousMode === 'EDITING') {
      return 'NONE';
    }

    return 'RELOAD';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    if (nextMode === 'EDITING' || nextMode === 'SHARE_EDIT') {
      return 'NONE';
    } else if (nextMode === 'SMARTCONTENTLIB') {
      return 'SOFT_REFRESH';
    }

    return 'RELOAD';
  }
}
