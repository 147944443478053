import * as React from 'react';
import ToggleSection from '../../../../../misc/ToggleSection';
import { IDefinition, IDocUnitProfile, IUnit } from 'mm-types';
import NoProperties from '../../selectedComponent/components/NoProperties';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export interface IGenericEditProps {
  targetElement: HTMLElement;
  index: number;
  isLeaf: boolean;
  isOneOfMultiple: boolean;
  onToggle: (isOpen: boolean, ref: React.RefObject<HTMLDivElement>) => any;
  profile: IDocUnitProfile;
  subType?: string;
  type: string;
  unitElement: HTMLElement;
  selectedUnit: IUnit | null;
  unitProfile: IDocUnitProfile;
  definition?: IDefinition;
  parentDefinition: IDefinition;
}

const GenericEditProps = (props: IGenericEditProps) => {
  const [userOpened, setUserOpened] = React.useState<boolean>(false);

  const hasDataAttributes = hasDefinitionDataAttributes(
    props.definition,
    props.parentDefinition?.id,
    getElmDef(props.selectedUnit?.definitionId)
  );

  return (
    <div className="toggle-section-outer selected-unit-props-container subaction-edit">
      <ToggleSection
        title={props.definition?.displayName + ' Properties'}
        id={'unitProps_' + props.index}
        defaultOpen={false}
        forceClose={!props.isLeaf}
        forceOpen={hasDataAttributes && (props.isLeaf || userOpened)}
        enabled={hasDataAttributes}
        onToggled={(isOpened) => {
          setUserOpened(isOpened);
        }}
      >
        <div className="selected-unit-props-section p-0">
          <DataAttributesPropsWrapper {...props} />
          <div>{!props.definition?.dataAttributes && <NoProperties short={true} />}</div>
        </div>
      </ToggleSection>
    </div>
  );
};

export default GenericEditProps;
