import * as React from 'react';
import { useState } from 'react';
import ExternalRevisionSubentry from './ExternalRevisionSubentry';
import { IRevision } from 'mm-types';
import { AeroIconButtonDownload } from '../../../general/AeroIconButton';
import withTooltip from '../../../hoc/withTooltip';
import { IconProps, MaterialIconListCheckbox } from '../../../general/MaterialIcon';
import { dateUtil } from '../../../../utils';

const IconListCheckboxWithTooltip = withTooltip<IconProps>(MaterialIconListCheckbox);

interface Props {
  revision: IRevision;
  downloadRevisionByIndexUid: (indexUid: string) => any;
  downloadRevisionByRevId: (revUid: string) => any;
  canDownload: boolean;
}

const ExternalRevisionEntry = ({ revision, canDownload, downloadRevisionByIndexUid, downloadRevisionByRevId }: Props) => {
  const [expandedRevision, setExpandedRevision] = useState<string | null>(null);

  function expandRevision(indexUid: string) {
    setExpandedRevision(indexUid);
  }

  const formatDateLong = (date: string): string => {
    return dateUtil(date).formatDateTimeNoSecs(true);
  };

  return (
    <li>
      <div>
        <label onClick={expandRevision.bind(null, expandedRevision ? null : revision.indexUid)}> Revision {revision.revision}</label>
        {revision.quizUid && (
          <div className="quiz-published" data-qa="quiz-published">
            <IconListCheckboxWithTooltip className="material-icons" tooltipText="Quiz" />
          </div>
        )}
        <span className="dateSpan"> {formatDateLong(revision.created)} </span>

        <AeroIconButtonDownload
          style={{ marginTop: '-6px' }}
          disabled={!canDownload}
          id="downloadRevision"
          onClick={() => downloadRevisionByIndexUid(revision.indexUid)}
          tooltip="Download"
          tooltipPosition="top-left"
        />
      </div>

      {expandedRevision === revision.indexUid && (
        <>
          <li>
            <div>
              <label className="draftEntry draftEntryHeader">Upload title </label>
              <label className="draftEntryHeader dateSpan">Date </label>
            </div>
          </li>
          {revision.subentries.map((draft, draftIndex) => {
            return (
              <ExternalRevisionSubentry
                key={draftIndex}
                fileName={draft.fileName}
                canDownload={canDownload}
                created={formatDateLong(draft.created)}
                onDownloadClick={() => downloadRevisionByRevId(draft.id)}
              />
            );
          })}
        </>
      )}
    </li>
  );
};

export default ExternalRevisionEntry;
