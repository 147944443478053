import * as Reflux from 'reflux';
import * as _ from 'lodash';
import EventClient, { EventUnsubscribeFn } from './EventClient';
import { BroadcastActivityTypes } from './BroadcastActivityTypes';
import Store from '../Store';
import { getUser } from '../../clients/users';
import { IBroadcastType, ISharedIndexOrigin, ISharedIndexUsages, IUnit, IUser } from 'mm-types';

export type State = {};

export type IndexEventStoreEvent = {
  isUserMe: boolean;
  userUid: string;
  activity: BroadcastActivityTypes;
  user: IUser;
  data: {
    stepStatus: string;
    indexUid: string;
    user: IUser; // Not sure if this is meant to be here
    unitUid: string;
    masterIndexUid?: string;
    affectedUnits: IUnit[];
    projectUid: string;
    sharedContentJson: ISharedIndexOrigin | ISharedIndexUsages;
    sharedContent: ISharedIndexUsages;
  };
};

export class IndexEventStore extends Store<State> {
  private editingIndexUid: string | null = null;
  private subscription: EventUnsubscribeFn | null = null;

  constructor() {
    super();
  }

  joinNewIndex(indexUid: string) {
    if (this.editingIndexUid !== null) {
      this.leaveCurrentIndex();
    }
    this.editingIndexUid = indexUid;
    this.publishEvent(indexUid, 'join');
    this.subscribe(indexUid);
  }

  leaveCurrentIndex() {
    this.unsubscribe();

    if (this.editingIndexUid) {
      this.publishEvent(this.editingIndexUid, 'leave');
    }
    this.editingIndexUid = null;
  }

  broadcastToIndex(event: IBroadcastType) {
    if (this.editingIndexUid) {
      this.publishEvent(this.editingIndexUid, 'broadcast', event);
    }
  }

  private publishEvent(indexUid: string, type: 'join' | 'leave' | 'broadcast', body: Object = {}) {
    EventClient.publish(`/indexes/${indexUid}/${type}`, JSON.stringify(body));
  }

  private subscribe(indexUid: string) {
    this.unsubscribe();
    EventClient.onConnected
      .then(() => {
        this.subscription = EventClient.subscribe({
          destination: `/topic/indexes/${indexUid}`,
          callback: (message) => {
            this.triggerEvent(JSON.parse(message.body));
          }
        });
      })
      .catch(() => {
        EventClient.log(`No Editor activities available`);
      });
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription();
    }
  }

  private async triggerEventWithUser(e: IndexEventStoreEvent) {
    e.user = await getUser(e.userUid);
    this.trigger(e);
  }

  private triggerEvent(e: IndexEventStoreEvent) {
    e.isUserMe = EventClient.connectedUser?.uid === e.userUid;
    const { activity, data, isUserMe } = e;
    if (activity === 'locked' || activity === 'unlocked') {
      this.triggerEventWithUser(e);
    } else if (activity === 'published' && _.without(_.values(data.stepStatus), 'COMPLETE').length === 0) {
      e.activity = 'publishCompleted';
      this.triggerEventWithUser(e);
    } else if (['joined', 'left', 'affectedUnits'].indexOf(activity) !== -1) {
      if (!isUserMe) {
        this.triggerEventWithUser(e);
      }
    } else if (['unitEditBegin', 'unitEditEnd', 'tasksChanged', 'unitTaskChanged'].indexOf(activity) !== -1) {
      if (!isUserMe) {
        this.trigger(e);
      }
    } else {
      this.trigger(e as IndexEventStoreEvent);
    }
  }
}

const singleton = Reflux.initStore<IndexEventStore>(IndexEventStore);
export default singleton;
