import * as React from 'react';
import CommentStore from '../../../../flux/editor/CommentStore';
import { IUnit } from 'mm-types';

type Props = {
  unit: IUnit | null;
  commentCount: number;
  onSelect: (e: React.MouseEvent<HTMLElement>) => void;
};

export const CommentMenu = ({ unit = null, commentCount = 0, onSelect }: Props) => {
  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    onSelect(e);
    setTimeout(() => {
      // need to delay, as select on comments list will clear any pre-existing create form
      CommentStore.commentIntent(unit!);
    }, 300);
  };

  return (
    <ul className="du-inline-item du-inline-actions du-inline-comments" onClick={(e) => handleSelect(e)}>
      <li id="commentUnitFromEditor">
        <i className="material-icons">insert_comment</i>
        {commentCount ? <span className="comment-count">{commentCount}</span> : null}
      </li>
    </ul>
  );
};
