/* FRAMEWORK */
import * as _ from 'lodash';
/* UTILS */
import TinyCustomKeyListener from '../tinyFacade/TinyCustomKeyListener';
import { UnitTypes } from './UnitTypes';
import UnitSanitizer from './UnitSanitizer';
import TocableEditSettings from './TocableEditSettings';
/* STORES */
import ProjectStore from '../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

import { IDocUnitProfile, IElementDefinition, IElementProfile, IElementStyle, IUnit, IUnitDefinition } from 'mm-types';
import { CustomEditor } from '../tinyFacade/EditorInstanceManager';
import { defaultMenuOverrides, defaultMenuSelectedOverrides } from './const/profileMenuOverrides';
import { UNIT_EDIT_PROFILES } from '.';
import { definitionToAction } from './unit/inlineElementsLookup';
import { getFeatures } from '../../../featureSwitch/featureSwitchUtils';
import { NNCChecklistAsserts } from './unit/non-normal-checklist-level1/asserts';
import GenericEditProps from '../../sidetabs/sub/editComponent/components/GenericEditProps';
import { mapElementDefIndexSettingsToDocUnitProfileActions } from './unit/elementIndexSettingsUtils';
import { Action, MenuActions, MenuActionTitles } from './const/docUnitProfileMenuTypes';
import baseEditor from './const/BaseEditorConfig';

const _allConverts: UnitTypes[] = ['paragraph', 'heading', 'unordered-list', 'numbered-list', 'note', 'warning', 'caution'];

type DocUnitProfiles = Record<UnitTypes, IDocUnitProfile>;
type DocElementProfiles = Record<string, IElementProfile>;

// FIXME: AER-3514
export class DocUnitEditProfiles {
  private static instanceCache?: DocUnitEditProfiles;
  private _DocUnitEditProfiles: Partial<DocUnitProfiles> = {};
  private _CommonDocUnitEditProfiles: Partial<DocUnitProfiles> = {};
  private _AirbusSectionDocUnitEditProfiles: Record<string, DocUnitProfiles> = {};
  private _DocElementEditProfiles: DocElementProfiles = {};
  private _CommonDocElementEditProfiles: DocElementProfiles = {};
  private _AirbusSectionDocElementEditProfiles: Record<string, DocElementProfiles> = {};
  private _staticDocUnitEditProfiles: Partial<DocUnitProfiles> = UNIT_EDIT_PROFILES;
  private _unitDefinitions: IUnitDefinition[] = [];
  private _allObservedPatterns: string[] = [];

  public static instance(): DocUnitEditProfiles {
    if (!this.instanceCache) {
      this.instanceCache = new this();
    }

    return this.instanceCache;
  }

  setCurrentDocProfiles(currentTocLevel: string | null) {
    this._DocUnitEditProfiles = { ...this._CommonDocUnitEditProfiles, ...this._AirbusSectionDocUnitEditProfiles[currentTocLevel ?? ''] };
    this._DocElementEditProfiles = {
      ...this._CommonDocElementEditProfiles,
      ...this._AirbusSectionDocElementEditProfiles[currentTocLevel ?? '']
    };
  }

  setDocElementEditProfiles(profiles: DocElementProfiles) {
    this._DocElementEditProfiles = profiles;
  }

  getElementProfileByDefinitionId(definitionId: string): IElementProfile | undefined {
    if (this._DocElementEditProfiles.hasOwnProperty(definitionId)) {
      return this._DocElementEditProfiles[definitionId];
    } else {
      return _.values(this._DocElementEditProfiles)
        .filter((dep) => dep.id === ProjectDefinitionStore.toElemDefinitionId(definitionId))
        .pop();
    }
  }

  getUnitProfileByTypes(unitType = '', subType = ''): IDocUnitProfile {
    let profileBySubType: IDocUnitProfile;

    if (subType && subType.length > 0) {
      const profiles: (IDocUnitProfile | undefined)[] = _.values(this._DocUnitEditProfiles);
      profileBySubType = profiles
        .filter((dep) => {
          const dupType = dep?.type || '';
          return dupType.toLowerCase() === unitType.toLowerCase();
        })
        .filter((dep) => dep?.subType?.toLowerCase() === subType.toLowerCase())
        .pop()!;

      return profileBySubType;
    } else {
      return this._DocUnitEditProfiles[unitType.toLowerCase()];
    }
  }

  getUnitProfileByDefinitionId(definitionId: string, unit?: IUnit): IDocUnitProfile {
    const unitDefId = ProjectDefinitionStore.toUnitDefinitionId(definitionId);
    const profile: IDocUnitProfile = this._DocUnitEditProfiles[unitDefId.toLowerCase()];

    if (!profile && unit) {
      // definitionId from another nestedDefinition, find its context and lookup appropriately
      const currentTocLevelWithNestedDefsDefId = ProjectDefinitionStore.getParentWithNestedDefinitionsDefId(
        unit,
        ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition.unitDefinitions
      )!;
      return this._AirbusSectionDocUnitEditProfiles[currentTocLevelWithNestedDefsDefId][unitDefId];
    } else {
      return profile;
    }
  }

  getProfileFromElClass(klass: string) {
    let matchingType: string | null = null;

    _.every(this._DocUnitEditProfiles, function (unit: IDocUnitProfile, type: string) {
      let matches = false;
      if (unit.identifyingClasses) {
        matches = _.indexOf(unit.identifyingClasses, klass) !== -1;
        if (matches) {
          matchingType = type;
        }
      }

      return !matches;
    });

    return matchingType;
  }

  // note: this only exists as a fall back because of Tinymce injecting elements with no class names into the DOM for TABLE & UL/OL elements,
  // and the only elements setup to work with this are TABLE and OL/UL
  getProfileFromElName(name: string) {
    name = name ? name.toLowerCase() : '';

    let matchingType: string | null = null;

    _.every(this._DocUnitEditProfiles, function (unit: IDocUnitProfile, type: string) {
      let matches = false;
      if (unit.identifyingElements) {
        matches = _.indexOf(unit.identifyingElements, name) !== -1;
        if (matches) {
          matchingType = type;
        }
      }

      return !matches;
    });

    return matchingType;
  }

  getProfileFromElSubType(type: string): IDocUnitProfile | undefined {
    return _.find(this._DocUnitEditProfiles, { subType: type });
  }

  identifyTypeFromClassNames(classNames: string) {
    let matchingType = null;
    _.every(
      classNames.split(' ').filter((c) => c.match(/arc-.*/)),
      (arcClass: string) => {
        matchingType = this.getProfileFromElClass(arcClass);
        return !matchingType;
      }
    );

    return matchingType;
  }

  getAllObservedPatterns() {
    return this._allObservedPatterns;
  }

  getAllConverts() {
    return _allConverts;
  }

  get editProfilesFilteredByFeature(): Partial<DocUnitProfiles> {
    const featureNames = Object.keys(getFeatures());

    return _.pickBy(this._staticDocUnitEditProfiles, (profile: IDocUnitProfile) => {
      return !profile.featureTag || (profile.featureTag && featureNames.indexOf(profile.featureTag) !== -1);
    });
  }

  buildProfiles() {
    this._DocUnitEditProfiles = _.cloneDeep(this.editProfilesFilteredByFeature);
    this._unitDefinitions = ProjectDefinitionStore.getAllUnitDefinitions();

    this._unitDefinitions.forEach((ud: IUnitDefinition) => {
      const profileName = ud.id;
      // check if static units match unit definition
      if (this._DocUnitEditProfiles[ud.id]) {
        // apply title config to all tocable items
        if (!NNCChecklistAsserts.isNNCChecklistById(profileName)) {
          if (ud.type === 'tocable') {
            this._DocUnitEditProfiles[profileName] = _.merge(this._DocUnitEditProfiles[profileName], TocableEditSettings.tocable);
          }
          // apply title config to all structural items
          if (ud.structural) {
            this._DocUnitEditProfiles[profileName] = _.merge(this._DocUnitEditProfiles[profileName], TocableEditSettings.structural);
            if (ud.type !== 'tocable') {
              this._DocUnitEditProfiles[profileName] = _.merge(this._DocUnitEditProfiles[profileName], TocableEditSettings.sanitize);
            }
          }
        }
        // apply common and default editor settings to all items
        const elementDefinition = ProjectDefinitionStore.getElementDefinitionById(ud.rootElementId);
        this._baseUnitConfig(this._DocUnitEditProfiles[profileName], elementDefinition);
      }
    });
    // any missed units need to be passed through _baseUnitConfig
    const udIds = this._unitDefinitions.map((ud) => ud.id);
    const untouchedUnitProfileIds = Object.keys(this._DocUnitEditProfiles).filter((key) => _.indexOf(udIds, key) === -1);
    untouchedUnitProfileIds.forEach((udId) => this._baseUnitConfig(this._DocUnitEditProfiles[udId]));
    this._CommonDocUnitEditProfiles = this._DocUnitEditProfiles;
  }

  _baseUnitConfig(unit: IDocUnitProfile, elementDefinition?: IElementDefinition) {
    // ensure defaults for inlineOptions
    const inlineOptions = unit.inlineOptions ? unit.inlineOptions : {};
    unit.inlineOptions = _.extend(
      {
        readonly: false
      },
      inlineOptions
    );
    unit.splittable = unit.splittable ?? false;
    unit.observeElement = unit.observeElement ?? null;

    if (unit.observeElement) {
      this._allObservedPatterns.push(...unit.observeElement);
    }

    // not every unit uses arcML yet, but if they do: these will be their formatting default when formatting applied
    // (and these should be reflected in default css for the units)
    unit.arcMLDefaults = unit.arcMLDefaults
      ? unit.arcMLDefaults
      : {
          root: { top: 1, right: 0, bottom: 1, left: 0, ordinal: 0 },
          nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
        };

    if (!(unit.inlineOptions && unit.inlineOptions.readonly)) {
      // more defaults
      unit.allowInsertContentElementsAfter = _.isUndefined(unit.allowInsertContentElementsAfter)
        ? true
        : unit.allowInsertContentElementsAfter;
      unit.menu = unit.menu ? unit.menu : defaultMenuOverrides;
      unit.menuTextSelected = unit.menuTextSelected ? unit.menuTextSelected : defaultMenuSelectedOverrides;

      this._updateTextActionsMenuFromElementWhitelist(unit, elementDefinition);

      if (_.isUndefined(unit.isEditable) || unit.isEditable) {
        // if isEditable undef: inferred to be true

        unit.editor = unit.editor ? unit.editor : {};
        unit.outerClass = unit.outerClass ? unit.outerClass : ''; // only relevant for items capable of being nested
        unit.sanitize = unit.sanitize ? unit.sanitize : UnitSanitizer;

        if (!unit.editor.setup) {
          unit.editor.setup = function (editor) {
            TinyCustomKeyListener.applyDefault(editor as CustomEditor);
          };
        }

        unit.editor = _.extend(baseEditor(unit), unit.editor);

        if (!unit.editor.plugins) {
          unit.editor.plugins = ['noneditable nonbreaking paste table lists'];
          unit.editor.contextmenu = 'inserttable | cell row column deletetable';
        }
      }
    }
  }

  _updateTextActionsMenuFromElementWhitelist(unit: IDocUnitProfile, elementDefinition?: IElementDefinition) {
    if (elementDefinition) {
      // check element definition whitelist and update menus for inline elements
      // !important to note that if whitelist is empty default values should be used above, hence elementDefinition.elementWhitelist.length > 0 check
      if (elementDefinition.elementWhitelist && elementDefinition.elementWhitelist.length > 0) {
        const elementWhitelist: string[] = elementDefinition.elementWhitelist;
        const textActions: any[] = [];
        elementWhitelist.forEach((element) => {
          const action = definitionToAction[element];
          if (action) {
            const enabled = true;
            textActions.push({ action, enabled });
          }
        });
        unit.menuTextSelected = { textActions };
      }
    }
  }

  async updateProfiles(type?: string) {
    let projectType;
    if (type) {
      projectType = type;
    } else {
      const project = ProjectStore.getProject();
      projectType = project ? project.definitionName : null;
    }

    if (projectType) {
      await ProjectDefinitionStore.setCurrentProjectDefinition(projectType);
      const projectDefinition = ProjectDefinitionStore.getCurrentProjectDefinition();

      if (projectDefinition) {
        // unitDefinitions
        this.updateUnitProfiles(projectDefinition.indexDefinition.unitDefinitions);
        // element definitions
        this.updateElementProfiles(this._DocUnitEditProfiles, projectDefinition.indexDefinition.elementDefinitions);
        this._CommonDocUnitEditProfiles = this._DocUnitEditProfiles;
        this._CommonDocElementEditProfiles = this._DocElementEditProfiles;
      }
    }
  }
  private updateUnitProfiles = (unitDefinitions?: IUnitDefinition[], nestedId?: string) => {
    // unit definitions
    unitDefinitions?.forEach((ud) => {
      const unitDefinition = Object.assign({}, ud);

      let unitProfile = _.cloneDeep(this._DocUnitEditProfiles[unitDefinition.id.toLowerCase()]);
      const mapped: { template: string; getLabel: (unit?: IUnit) => string } = { template: '', getLabel: () => '' };

      if (unitDefinition.templateHtml) {
        mapped.template = unitDefinition.templateHtml;
      }
      if (unitDefinition.displayShort) {
        mapped.getLabel = () => unitDefinition.displayShort;
      }

      if (!unitProfile) {
        // map new subType
        unitProfile = _.cloneDeep(this._DocUnitEditProfiles[unitDefinition.type.toLowerCase()]);
      }

      if (nestedId) {
        this._AirbusSectionDocUnitEditProfiles[nestedId] = {
          ...this._AirbusSectionDocUnitEditProfiles[nestedId],
          [unitDefinition.id.toLowerCase()]: _.merge(unitProfile, unitDefinition, mapped)
        };
      } else {
        this._DocUnitEditProfiles[unitDefinition.id.toLowerCase()] = _.merge(unitProfile, unitDefinition, mapped);
      }

      if (ud.allowUnitDefinitions) {
        this.updateUnitProfiles(ud.allowUnitDefinitions, ud.id);
        if (ud.allowElementDefinitions) {
          this.updateElementProfiles(this._AirbusSectionDocUnitEditProfiles[ud.id], ud.allowElementDefinitions, ud.id);
        }
      }
    });
  };

  private updateElementProfiles = (
    DocUnitEditProfiles: Partial<DocUnitProfiles>,
    elementDefinitions?: IElementDefinition[],
    nestedId?: string
  ) => {
    elementDefinitions?.forEach((elementDefinition) => {
      const elementId = elementDefinition.id.toLowerCase();
      const family = elementDefinition.type.toLowerCase();
      // combine element definition with unit definition if present, if not use family definition
      const elementEditProfile: IElementProfile = {
        ..._.cloneDeep(DocUnitEditProfiles[elementId] ?? DocUnitEditProfiles[family]),
        ..._.cloneDeep(elementDefinition)
      };
      // Update menu settings
      updateMenuSettings(elementEditProfile);
      // NB: This is to override doc unit profile settings i.e edit menu actions ( arcTextTransform etc ), with settings from index definition. Moving forward we should implement settings in BE
      const updatedElementProfile = mapElementDefIndexSettingsToDocUnitProfileActions(elementEditProfile);
      // FIXME SB replumb elementStyle overrides from new home in projectDefinition
      elementEditProfile.overridable = _.some(updatedElementProfile.styles, (style: IElementStyle) => {
        return style.overridable === true;
      });

      // Use GenericEditProps by default, apart from table sub elements.
      const isNotTableElement = ['tablehead', 'tablebody', 'tablefoot', 'tablerow', 'tabledata'].indexOf(elementId) < 0;

      if (isNotTableElement && family !== 'table') {
        elementEditProfile.editPropsComponent = GenericEditProps;
      }

      // Find editPropsComponent in the first corresponding UnitProfile and keep it in this._DocElementEditProfiles[ elementId ]
      // unit definition/profile's rootElementId should match element definition's id
      for (const key in DocUnitEditProfiles) {
        if (DocUnitEditProfiles[key].rootElementId === updatedElementProfile.id) {
          if (DocUnitEditProfiles[key].editPropsComponent) {
            elementEditProfile.editPropsComponent = DocUnitEditProfiles[key].editPropsComponent;
          }
        }
      }

      if (!DocUnitEditProfiles[elementId] && family === 'table') {
        DocUnitEditProfiles[elementId] = {
          editPropsComponent: GenericEditProps,
          ...elementEditProfile
        };
      }

      if (elementEditProfile && !elementEditProfile.editPropsComponent && family === 'table') {
        if (DocUnitEditProfiles[family]?.editPropsComponent) {
          elementEditProfile.editPropsComponent = DocUnitEditProfiles[family]!.editPropsComponent;
        }
      }
      if (nestedId) {
        this._AirbusSectionDocElementEditProfiles[nestedId] = {
          ...this._AirbusSectionDocElementEditProfiles[nestedId],
          [elementId]: elementEditProfile
        };
      } else {
        this._DocElementEditProfiles[elementId] = elementEditProfile;
      }
    });
  };
}

/*
 * Menu settings are set in base unit config for units, when we update element profiles we inherit the menu settings from the unit if there is a unit to match the element or from the family if not.
 * This is not a strict mapping so it may be that the element still does not have the correct menu settings
 * Creating this method in case there is more menu settings that need to be adjusted for elements based on index definitions
 * Currently it only updates menu setting for linkActions
 * */
const updateMenuSettings = (elmProfile: IElementProfile) => {
  updateLinkActions(elmProfile);
};

const updateLinkActions = (elmProfile: IElementProfile) => {
  updateDuRefInsertableFromIndexDef(elmProfile);
};

const updateDuRefInsertableFromIndexDef = (elmProfile: IElementProfile) => {
  const isDuRefInsertEnabled =
    elmProfile.elementWhitelist?.indexOf('DuRef') !== -1 || elmProfile.rootElementWhitelist?.indexOf('DuRef') !== -1;
  updateActionInMenuTypeIfExistsOrPushToActionToMenuType(elmProfile, 'menu', 'linkActions', 'duRefLink', isDuRefInsertEnabled);
  updateActionInMenuTypeIfExistsOrPushToActionToMenuType(elmProfile, 'menuTextSelected', 'linkActions', 'duRefLink', isDuRefInsertEnabled);
};

const updateActionInMenuTypeIfExistsOrPushToActionToMenuType = (
  elmDef: IElementProfile,
  menuType: 'menu' | 'menuTextSelected',
  menuActionTitle: MenuActionTitles,
  menuAction: MenuActions,
  enabled: boolean
) => {
  const actionInMenuIndex = (elmDef?.[menuType]?.[menuActionTitle] as Array<Action>)?.findIndex((action) => action.action === menuAction);
  if (actionInMenuIndex && elmDef[menuType]?.[menuActionTitle]?.[actionInMenuIndex]) {
    elmDef[menuType]![menuActionTitle]![actionInMenuIndex].enabled = enabled;
  } else {
    (elmDef?.[menuType]?.[menuActionTitle] as Array<Action>)?.push({ action: menuAction, enabled: enabled });
  }
};
