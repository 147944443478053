import * as React from 'react';
import NotificationsStore, { NotificationsStoreEvent, State as NotificationsStoreState } from '../../flux/events/NotificationsStore';
import AgentUtil from '../../utils/AgentUtil';
import NotificationsPopup from './NotificationsPopup';
import IconButton from 'material-ui/IconButton';
import CircularProgress from 'material-ui/CircularProgress';
import EventClient, { EventClientStatus } from '../../flux/events/EventClient';
import { classNameHelper } from '../../utils';

export type Props = {};

interface State {
  status: EventClientStatus['status'];
  newNotify: boolean;
  popupOpen: boolean;
}

export default class Notifications extends React.Component<Props, NotificationsStoreState & State> {
  private notifUnsubscribe: Function;
  private eventUnsubscribe: Function;
  private containerRef: React.RefObject<HTMLDivElement>;
  private endAnimationFnId: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...NotificationsStore.getInitialState(),
      status: 'connecting',
      newNotify: false,
      popupOpen: false
    };
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.reconnectEventClientAndGetNewNotifications = this.reconnectEventClientAndGetNewNotifications.bind(this);
    this.containerRef = React.createRef();
  }

  static defaultProps: Partial<Props> = {
    isEditorSupported: AgentUtil.isEditorSupported()
  };

  componentWillUnmount() {
    this.notifUnsubscribe();
    this.eventUnsubscribe();
    document.removeEventListener('mousedown', this.handleDocumentClick);
    this.clearAnimation();
  }

  componentDidMount() {
    this.notifUnsubscribe = NotificationsStore.listen(this.onNotificationsUpdate, this);
    this.eventUnsubscribe = EventClient.listen(this.onEventsUpdate, this);
    NotificationsStore.retrieveNotifs();
    document.addEventListener('mousedown', this.handleDocumentClick);
  }

  handleDocumentClick(event) {
    if (this.isOutsideClick(event) && this.state.popupOpen) {
      this.setState({ popupOpen: false });
    }
  }

  private onEventsUpdate({ status }: EventClientStatus) {
    if (this.state.status !== status) {
      this.setState({
        status
      });
    }
  }

  private onNotificationsUpdate(state: NotificationsStoreEvent) {
    const isNewNotif: boolean = state.info.totalUnseenCount > 0 && state.info.totalUnseenCount > this.state.info.totalUnseenCount;

    this.setState({ notifications: state.notifications, info: state.info }, () => {
      if (isNewNotif && !this.state.popupOpen) {
        this.animateBell();
      }
    });
    if (state.type === 'START_NOTIF_PROGRESS') {
      this.setState({ popupOpen: true });
    }
  }

  reconnectEventClientAndGetNewNotifications() {
    const { status } = this.state;
    if (status !== 'connected' && !this.state.popupOpen) {
      if (status === 'disconnected') {
        EventClient.reconnect();
      }
      NotificationsStore.retrieveNotifs();
    }
  }

  private isOutsideClick(event): boolean {
    return !this.containerRef?.current?.contains(event.target);
  }

  private animateBell() {
    this.setState({
      newNotify: true
    });
    this.clearAnimation();
    this.endAnimationFnId = window.setTimeout(() => {
      this.setState({
        newNotify: false
      });
    }, 1000);
  }

  private clearAnimation() {
    if (this.endAnimationFnId) {
      clearTimeout(this.endAnimationFnId);
    }
  }

  render() {
    const isDisconnected = this.state.status === 'disconnected';
    const buttonClassName = classNameHelper.merge('notifications-button', {
      'animation-debounce': this.state.newNotify,
      'animation-blinking': this.state.status === 'connecting',
      'notifications-button-error': this.state.info.containsInError
    });
    return (
      <div className="notifications-list-container" ref={this.containerRef}>
        <div style={{ display: 'inline-flex' }} onClick={() => this.setState({ popupOpen: !this.state.popupOpen })}>
          {this.state.info.containsInProgress && (
            <CircularProgress
              style={{ cursor: 'pointer', marginLeft: 6, marginTop: 7 }}
              mode="indeterminate"
              size={17.85}
              thickness={3}
              color="#FFFFFF"
              innerStyle={{ display: 'inline-flex' }}
              data-qa="notification-circular-progress"
            />
          )}

          {this.state.info.containsInError && (
            <IconButton className={buttonClassName} iconClassName="material-icons icon-notif-containserror" />
          )}
          {!this.state.info.containsInError && (
            <IconButton
              className={buttonClassName}
              onClick={this.reconnectEventClientAndGetNewNotifications}
              iconClassName="material-icons"
            >
              {isDisconnected ? 'notifications_off' : 'notifications'}
            </IconButton>
          )}

          {this.state.info.totalUnseenCount ? (
            <div className={'popup-counts' + (this.state.info.totalUnseenCount === 0 ? ' no-new' : '')}>
              {this.state.info.totalUnseenCount}
            </div>
          ) : undefined}
        </div>

        <NotificationsPopup
          notifications={this.state.notifications}
          notificationInfo={this.state.info}
          newCount={this.state.info.totalUnseenCount}
          popupOpen={this.state.popupOpen}
          setPopup={(isOpen) => this.setState({ popupOpen: isOpen })}
        />
      </div>
    );
  }
}
