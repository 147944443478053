import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import { ISpecialChar } from 'mm-types';

type Props = {
  handleClick: (e, specialChar: ISpecialChar) => void;
  setCurrent: (specialChar: ISpecialChar | null) => void;
};

const GetSpecialCharsTable = (props: Props) => {
  const { handleClick, setCurrent } = props;
  const specialChars = EditorStore.getSpecialChars();
  const rows: ISpecialChar[][] = [],
    rowLength = 10;

  let offset = 0;
  while (offset < specialChars.length) {
    rows.push(specialChars.slice(offset, offset + rowLength));
    offset += rowLength;
  }

  return (
    <table>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr key={index}>
              {row.map((specialChar, sc_index) => {
                if (specialChar && !specialChar.hidden) {
                  return (
                    <td
                      key={index + sc_index}
                      onClick={(e) => {
                        handleClick(e, specialChar);
                      }}
                      onMouseOver={() => {
                        setCurrent(specialChar);
                      }}
                      onMouseOut={() => {
                        setCurrent(null);
                      }}
                    >
                      <div className="cellInner">{specialChar.unicode?.normalize()}</div>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default GetSpecialCharsTable;
