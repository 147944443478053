import * as React from 'react';
import TextField from 'material-ui/TextField';

export type Props = {
  placeholder?: string;
  defaultValue?: string;
  onChange: (text: string) => void;
};

export type State = {
  showClose: boolean;
  searchText: string;
};

export default class Search extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showClose: false,
      searchText: this.props.defaultValue!
    };
  }

  static defaultProps: Partial<Props> = {
    placeholder: 'Filter',
    defaultValue: ''
  };

  reset() {
    this.setState({ showClose: false, searchText: '' });
  }

  _search(e?: React.FormEvent<{}>) {
    const searchText = e ? (e.target as HTMLInputElement).value : '';
    this.setState({ showClose: searchText.trim().length !== 0, searchText: searchText }, () => {
      this.props.onChange(searchText);
    });
  }

  _clear() {
    this.setState({ showClose: false }, () => {
      this._search();
    });
  }

  render() {
    return (
      <div className="search-field">
        <TextField
          onChange={(e, text) => this._search(e)}
          value={this.state.searchText}
          className="search-field-text"
          id="search-field-text-input"
          hintText={this.props.placeholder}
        />

        <i className="material-icons search">search</i>
        <i className={`material-icons close ${this.state.showClose ? '' : 'disabled'}`} onClick={(e) => this._clear()}>
          close
        </i>
      </div>
    );
  }
}
