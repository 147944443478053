import Menu from './Menu';
import SubMenu from './SubMenu';

export const MENU_CLOSE_DELAY = 200;

class MenuHelper {
  private menuCloseTimeout: number | undefined;
  private submenuCloseTimeout: number | undefined;
  private activeMenu: Menu | null;
  private activeSubmenu: SubMenu | null;

  constructor() {}

  openMenu(menu: Menu, target: Element | undefined) {
    const active = this.activeMenu;
    if (active) {
      this.forceCloseSubmenu();
      if (active.id !== menu.id) {
        this.forceCloseMenu();
      } else if (active.id === menu.id && active.state.open) {
        this.preventClosingMenu();
        return;
      }
    }
    this.activeMenu = menu;
    this.activeMenu.openPopup(target);
  }

  preventClosingMenu() {
    if (!this.activeMenu) {
      return;
    }
    this.clearActiveMenuTimeout();
  }

  closeMenu() {
    if (!this.activeMenu) {
      return;
    }
    this.clearActiveMenuTimeout();
    this.menuCloseTimeout = window.setTimeout(() => {
      this.forceCloseMenu();
      this.forceCloseSubmenu();
    }, MENU_CLOSE_DELAY);
  }

  forceCloseMenu() {
    this.clearActiveMenuTimeout();
    if (this.activeMenu) {
      this.activeMenu.closePopup();
    }
  }

  openSubmenu(submenu: SubMenu) {
    if (this.activeSubmenu) {
      if (this.activeSubmenu.props.value !== submenu.props.value) {
        this.forceCloseSubmenu();
      }
    }
    this.activeSubmenu = submenu;
    this.activeSubmenu.openSubmenu();
  }

  closeSubmenu() {
    this.clearActiveSubmenuTimeout();
    this.submenuCloseTimeout = window.setTimeout(() => {
      this.forceCloseSubmenu();
    }, MENU_CLOSE_DELAY);
  }

  forceCloseSubmenu() {
    this.clearActiveSubmenuTimeout();
    if (this.activeSubmenu) {
      this.activeSubmenu.closeSubmenu();
    }
  }

  preventClosingSubmenu() {
    if (!this.activeSubmenu) {
      return;
    }
    this.clearActiveSubmenuTimeout();
  }

  selectItem(value: string, currentTarget: Element) {
    if (this.activeMenu) {
      this.activeMenu.triggerItemSelect(value, currentTarget);
      this.activeMenu.closePopup();
    }
  }

  private clearActiveMenuTimeout() {
    if (this.activeMenu && this.menuCloseTimeout) {
      clearTimeout(this.menuCloseTimeout);
      this.menuCloseTimeout = undefined;
    }
  }

  private clearActiveSubmenuTimeout() {
    if (this.submenuCloseTimeout) {
      clearTimeout(this.submenuCloseTimeout);
      this.submenuCloseTimeout = undefined;
    }
  }

  resetValues() {
    this.activeMenu = this.activeSubmenu = null;
    this.menuCloseTimeout = this.submenuCloseTimeout = undefined;
  }
}

const menuHelper = new MenuHelper();
export default menuHelper;
