import * as React from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import UnitSpecialInterestTagStore from '../../../../../../flux/editor/UnitSpecialInterestTagStore';
import TagField from '../../../../../general/tag-field/TagField';
import { IUnit, ISpecialInterestGroup } from 'mm-types';
import { SpecialInterestUpdateModel } from '../../../../../../clients/special-interest';
import { CircularProgress } from 'material-ui';
import useListenToStore from '../../../../../hooks/useListenToStore';

export type Props = {
  units: IUnit[];
  isReadOnly?: boolean;
};

export type State = {
  allTags: ISpecialInterestGroup[];
  searchTags: ISpecialInterestGroup[];
  unitSpecialInterestTagMap: ISpecialInterestGroup[];
};

const SpecialInterestTags = (props: Props) => {
  const [state, setState] = React.useState<State>({
    allTags: [],
    searchTags: [],
    unitSpecialInterestTagMap: []
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    initialRender();
  }, [props.units]);

  const onUnitSpecialInterestTagUpdate = (event) => {
    if (event.type === 'retrieveUnitSpecialInterestTagMap' && event.state.unitSpecialInterestTagMap) {
      representSelectedState();
      setLoading(false);
    }
  };

  useListenToStore({ store: UnitSpecialInterestTagStore, eventListener: onUnitSpecialInterestTagUpdate });

  const initialRender = () => {
    if (!props.units[0].uid) {
      setState({
        ...state,
        allTags: [],
        searchTags: [],
        unitSpecialInterestTagMap: []
      });
    } else {
      representSelectedState();
    }
  };

  const representSelectedState = () => {
    setState({
      ...state,
      unitSpecialInterestTagMap: UnitSpecialInterestTagStore.getUnitSpecialInterestTagMaps(props.units),
      allTags: UnitSpecialInterestTagStore.getSpecialInterestGroups(),
      searchTags: []
    });
  };

  const updateAndSaveState = (specialInterestTagsChange: ISpecialInterestGroup[]) => {
    if (state.unitSpecialInterestTagMap.length === 0 && specialInterestTagsChange.length === 0) {
      return;
    }

    setLoading(true);

    const updates: SpecialInterestUpdateModel = {};
    const docParams = EditorStore.getDocParams();

    props.units.forEach((unit) => {
      // previous is in relation to common intersection of selected units
      const previousAssignment = UnitSpecialInterestTagStore.getUnitSpecialInterestTagMaps(props.units);
      const newAssignments: { uid: string }[] = _.difference(specialInterestTagsChange, previousAssignment).map((a) => ({
        uid: a.uid
      }));
      const removedAssignment: { uid: string }[] = _.difference(previousAssignment, specialInterestTagsChange).map((a) => ({ uid: a.uid }));
      const update: {
        add?: { uid: string }[];
        remove?: { uid: string }[];
      } = {};

      if (newAssignments.length > 0) {
        update.add = newAssignments;
      } else if (removedAssignment.length > 0) {
        update.remove = removedAssignment;
      }

      if (newAssignments.length > 0 || removedAssignment.length > 0) {
        updates[unit.uid] = update;
      }
    });

    if (Object.keys(updates).length > 0) {
      UnitSpecialInterestTagStore.assignSpecialInterestTagsToUnit(updates, docParams, false);
    }
  };

  const onTagChange = (newTags: ISpecialInterestGroup[]) => {
    updateAndSaveState(newTags);
  };

  const onTagSearch = (searchText: string) => {
    let searchTags = state.allTags.filter((tag) => {
      return tag.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0;
    });

    if (searchTags.length === 0) {
      searchTags = state.allTags;
    }

    setState({ ...state, searchTags: searchTags });
  };

  return (
    <div className="selected-unit-props-section specialinterestgroups-section">
      <div className="form-container">
        <div className="tags-label">
          Special interest tags
          {loading ? (
            <CircularProgress
              className="special-interest-loading"
              size={14}
              thickness={3}
              style={{ margin: '0 0 0 10px', verticalAlign: 'middle' }}
            />
          ) : undefined}
        </div>
        <TagField
          placeholder={!props.isReadOnly ? 'Type to add tags' : ''}
          allowAdd={!props.isReadOnly}
          allowDelete={!props.isReadOnly}
          allowCreate={false}
          showTags={state.unitSpecialInterestTagMap}
          searchTags={state.searchTags}
          onSearch={(searchText) => onTagSearch(searchText)}
          onChange={(newTags) => onTagChange(newTags as ISpecialInterestGroup[])}
          valueField="name"
          type="SPECIALINTERESTTAGS"
        />
      </div>
    </div>
  );
};

export default SpecialInterestTags;
