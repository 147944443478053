/* FRAMEWORK */
import * as React from 'react';
/* STORES */
import ProjectDefinitionStore, { UserCreatableType } from '../../flux/common/ProjectDefinitionStore';
/* HOC */
/* LOCAL DEPS */
import { InsertRulesFactory } from '../editor/menus/insert/utils/InsertRulesFactory';
import { getElementIsInsertable } from './withInsertRules';
/* TYPES */
import { IElementDefinition, IInsertRules, IUnitDefinition } from 'mm-types';
import { WithSelectedUnitProps, WithSelectedUnitState } from './withSelectedUnit';

export type WithConvertRulesProps = {
  rules: IInsertRules;
  contentMenuUnits: IUnitDefinition[];
  contentMenuElements: IElementDefinition[];
  isElementInsertable(
    elementId: string,
    insertAtLevel: number
  ): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null };
  isConvertable: (definitionId: string, validConverts: string[]) => boolean;
  elementDefinitions: IElementDefinition[];
};

const withRules = (Wrapped: React.ComponentClass<WithConvertRulesProps>) =>
  class WithConvertRules extends React.Component<WithSelectedUnitProps & WithSelectedUnitState, {}> {
    render() {
      const rules = InsertRulesFactory(this.props);

      const contentUnitDefinitions = getMenuItems();
      const contentMenuElementDefinitions = ProjectDefinitionStore.getContentMenuElements();
      const isConvertable = getIsConvertable();
      const elementDefinitions = ProjectDefinitionStore.getAllElementDefinitions();

      const insertRulesMeta = rules.currentlyInsertableElements(this.props.editingNestedChange);
      const isElementInsertable = getElementIsInsertable(insertRulesMeta, this.props.dtdValidatedElements);

      return (
        <Wrapped
          rules={rules}
          contentMenuUnits={contentUnitDefinitions}
          contentMenuElements={contentMenuElementDefinitions}
          isConvertable={isConvertable}
          isElementInsertable={isElementInsertable}
          elementDefinitions={elementDefinitions}
          {...this.props}
        />
      );
    }
  };

const getIsConvertable = () => (definitionId: string, validConverts: string[]): boolean => {
  return checkIfCanConvert(definitionId, validConverts);
};

const checkIfCanConvert = (definitionId: string, validConverts: string[]) => {
  return validConverts.indexOf(definitionId) > -1;
};

const getMenuItems = () => {
  const allUnitDefinitions = ProjectDefinitionStore.getAllUnitDefinitions();
  const structuralDefinitions: IUnitDefinition[] = [];
  const contentDefinitions: IUnitDefinition[] = [];

  allUnitDefinitions.forEach((ud) => {
    if (
      ud.validConverts &&
      ud.validConverts.length > 0 &&
      ud.userCreatable !== UserCreatableType.NEVER &&
      ['ghost', 'removed'].indexOf(ud.type) === -1
    ) {
      ud.structural ? structuralDefinitions.push(ud) : contentDefinitions.push(ud);
    }
  });

  return contentDefinitions;
};

const withConvertRules = (componentToWrap) => withRules(componentToWrap);

export default withConvertRules;
