import QueryUtil, { QueryParams } from './QueryUtil';
import config from './config';
import { CacheClient } from './CacheClient';

export interface LoginPageQueryParams extends QueryParams {
  logout?: string;
  preventRedirectOnFail?: string;
  error?: string;
  forward?: string;
}

class AppNavigator {
  goTo = {
    login: (queryParams?: LoginPageQueryParams) => {
      CacheClient.clearAll().then(() => {
        this.redirect(config.loginUrl, queryParams);
      });
    },
    loginWithRedirectBack: () => {
      CacheClient.clearAll().then(() => {
        this.goTo.login({
          forward: this.currentAppUrl
        });
      });
    },
    logout: () => {
      CacheClient.clearAll().then(() => {
        this.goTo.login({
          logout: 'true'
        });
      });
    }
  };

  get currentAppUrl(): string {
    return encodeURIComponent(window.location.pathname + window.location.search);
  }

  redirect(url: string, queryParams?: QueryParams) {
    location.href = QueryUtil.getUrlWithParams(url, queryParams);
  }
}

const appNavigator = new AppNavigator();
export default appNavigator;
