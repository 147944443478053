import * as React from 'react';
import { NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { NNCMetadataComponentProps } from './MetadataComponentTypes';
import { useArrayValue } from '../useArrayValues';
import { TextField } from 'material-ui';
import keyDown from '../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
import { AeroIconButtonAdd } from '../../../../../general/AeroIconButton';
import { AeroIconButtonRemove } from '../../../../../general/AeroIconButton';
import * as CONSTANTS from '../../../keyIdentifier';

const AddButton = React.memo(AeroIconButtonAdd);
const RemoveButton = React.memo(AeroIconButtonRemove);

type Props = NNCMetadataComponentProps<NNCIndexMetadataID.LIGHTS>;

export function LightsMetadata({ values, onChange, onTab, currentRef }: Props) {
  const { localValues, addValue, updateValue, removeValue } = useArrayValue<Props['values']>(values, onChange);
  const [text, setText] = React.useState<string>('');
  const inputRef = React.useRef<TextField | null>(null);

  function submitNewValue() {
    addValue([{ text }]);
    setText('');
    focusLastInput();
  }
  const focusLastInput = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  return (
    <div>
      <div className="show-form-action">
        <AddButton
          onClick={submitNewValue}
          id="add-lights-button"
          data-qa="add-lights-button"
          tooltip="Add Lights"
          tooltipPosition="top-left"
          onKeyDown={(e) => keyDown(e, onTab, currentRef, !!localValues.length)}
          ref={currentRef}
        />
      </div>
      <ul
        className="position-relative padding-right-l padding-bottom-s margin-top-none margin-bottom-none"
        data-qa="nnc-index-metadata-light-entries-ul"
      >
        {!localValues.length ? (
          <li data-qa="text">
            <div className="font-gray font-size-12">{"Select '+' to add entries to the Lights Index."}</div>
          </li>
        ) : null}

        {localValues.map((value, index) => (
          <li key={index} style={{ display: 'flex' }} className="padding-bottom-s">
            <TextField
              hintText="Add Light Entry Title"
              multiLine
              onChange={(e, text) => updateValue([{ text }], index)}
              fullWidth={true}
              value={value.text}
              className="native-mui-no-padding"
              ref={index === localValues.length - 1 ? inputRef : null}
              data-qa="nnc-index-metadata-lights-entry"
              onKeyDown={(e) => {
                if (e.keyCode === CONSTANTS.KeyCode.enter) {
                  inputRef.current!.blur();
                }
              }}
            />
            <RemoveButton
              onClick={() => removeValue(index)}
              id="remove-lights-button"
              data-qa="remove-lights-button"
              tooltip="Remove Light Entry"
              tooltipPosition="top-left"
              outline={true}
              onKeyDown={(e) => {
                if (index === localValues.length - 1) {
                  keyDown(e, onTab, currentRef);
                }
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
