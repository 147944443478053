import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const AttachedFiles = ({ entry }: PropsType) => {
  return (
    <div className="settings-details">
      {entry.attachment && (
        <div className="document-attachment" key="document-attachment">
          <div id="revision-number">
            <label>Revision Number:</label>
            <span> {entry.indexRevisionNumber}</span>
          </div>
          <div id="description">
            <label>Description:</label>
            <span> {entry.attachment.description}</span>
          </div>
          <div id="permissions">
            <label>Permissions:</label>
            <span> {entry.attachment.visibility}</span>
          </div>
        </div>
      )}
    </div>
  );
};
