import * as React from 'react';
import MenuActionItem, { MenuActionItemProps } from '../../EditorMenu/MenuActionItem';
import { MenuAction } from './defaultMenuContents';

interface Props {
  actions: MenuAction[];
  onAction: MenuActionItemProps['onAction'];
}

const LinkActionItems = ({ actions, onAction }: Props) => {
  return (
    <>
      {actions.map((menuItem, menuItemIndex) => {
        return (
          <MenuActionItem
            key={menuItemIndex}
            action={menuItem}
            enabled={menuItem.enabled}
            onAction={onAction}
            inClassNameIcon={menuItem?.inClassNameIcon}
          />
        );
      })}
    </>
  );
};

export default LinkActionItems;
