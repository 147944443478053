import FontIcon from 'material-ui/FontIcon';
import * as React from 'react';

export type Props = {
  onClose: () => void;
};

const InapplicableDialog = (props: Props) => {
  return (
    <div className="merge-item-dialog">
      <div className="merge-item-dialog__header">
        <div className="merge-item-dialog__icon">
          <FontIcon color="#f67d04" className="material-icons">
            error_outline
          </FontIcon>
        </div>
        <div className="merge-item-dialog__title">Invalid Shared Content Merge Activity</div>
        <div className="merge-item-dialog__close" onClick={() => props.onClose()}>
          <FontIcon color="#9c9c9c" className="material-icons">
            close
          </FontIcon>
        </div>
      </div>

      <div className="merge-item-dialog__content">
        <div>
          Shared Content that is changed in the Interim can not merge back to the Draft. These changes will require the user to update
          manually.
        </div>
        <div>Please acknowledge and proceed.</div>
      </div>
    </div>
  );
};

export default InapplicableDialog;
