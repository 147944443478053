import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { FontIcon } from 'material-ui';
import { ITaskImportResponse } from 'mm-types';
import { List, ListItem } from 'material-ui/List';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import { CSSProperties } from 'react';

export type Props = {
  onClose: () => void;
  onDownloadErrorLog: (errorLog) => void;
  importResponse: null | ITaskImportResponse;
};

export type State = {
  open: boolean;
  showValidation: boolean;
  errorMsg: string;
  errorLogNotDownloaded: boolean;

  model: {
    isUpdate: boolean;
    isUploading: boolean;
    type: string;
  };
};

export default class TaskDetailModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      errorLogNotDownloaded: false,
      errorMsg: '',
      showValidation: false,
      model: { isUpdate: false, type: '', isUploading: false }
    };
  }

  componentDidMount() {
    // need slight delay with open modal for selection above to take hold
    setTimeout(() => {
      this.setState(
        {
          showValidation: false,
          open: true,
          errorLogNotDownloaded: this.props.importResponse && this.props.importResponse.csvErrorLines ? true : false
        },
        () => {}
      );
    }, 100);
  }

  getWidth() {
    if (this.state.model.type === 'cross-document') {
      return '1200px';
    } else {
      return '850px';
    }
  }

  errorLogDownloaded() {
    this.setState({
      errorLogNotDownloaded: false
    });
  }

  getBorderStyle(fontSize: number, color?: string, padding?: number, topProp?: number) {
    return {
      border: 1 + `px solid ${color}`,
      height: 30 + 'px',
      width: 30 + 'px',
      borderRadius: 15 + 'px',
      fontSize: `${fontSize ? fontSize : 20}` + 'px',
      top: `${topProp ? topProp : 1}` + 'px',
      marginLeft: 10 + 'px',
      padding: `${padding ? padding : 0}` + 'px'
    };
  }

  renderErrorLog(err) {
    const errors: JSX.Element[] = [];
    const wordWrapStyling: CSSProperties = { wordWrap: 'break-word', lineHeight: 20 + 'px', whiteSpace: 'normal' };
    err.errors.forEach((e) => {
      errors.push(
        <TableRow>
          <TableRowColumn>{err.lineNum}</TableRowColumn>
          <TableRowColumn>{err.taskIdentifier}</TableRowColumn>
          <TableRowColumn style={wordWrapStyling}>{err.taskTitle}</TableRowColumn>
          <TableRowColumn style={wordWrapStyling}>{e}</TableRowColumn>
        </TableRow>
      );
    });
    return errors;
  }

  render() {
    const results: Partial<ITaskImportResponse> = { ...this.props.importResponse };
    const tableBodyStyle: CSSProperties = { fontWeight: 300 };
    const tableheaderStyle: CSSProperties = { fontSize: 15 + 'px', fontWeight: 'bold', color: 'black' };
    const buttonStyle: CSSProperties = {
      color: 'white',
      marginTop: 20 + 'px',
      marginBottom: 10 + 'px',
      marginRight: 10 + 'px',
      fontWeight: 'lighter'
    };

    return (
      <Dialog
        ref="dialog"
        className="task-dialog detail"
        titleStyle={{ fontSize: 20 + 'px', border: 'none', fontWeight: 900 }}
        style={{ zIndex: 999, width: '100%' }}
        contentStyle={{ height: '98%', maxWidth: 1003 + 'px' }}
        title={'Change Task Import Log'}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        actionsContainerStyle={{ border: 'none' }}
        actions={[
          <FlatButton
            key={1}
            label="Download Error Log"
            backgroundColor={'#009788'}
            disabled={!(this.props.importResponse && this.props.importResponse.csvErrorLines)}
            className={
              this.props.importResponse && this.props.importResponse.csvErrorLines ? 'importTaskButtonActive' : 'importTaskButtonDisabled'
            }
            style={buttonStyle}
            icon={
              <FontIcon style={{ fontSize: 14 + 'px' }} className="material-icons">
                file_download
              </FontIcon>
            }
            onClick={() => {
              this.props.onDownloadErrorLog(this.props.importResponse!.csvErrorLines);
              this.errorLogDownloaded();
            }}
          />,
          <FlatButton
            key={1}
            label="Exit"
            onClick={() => {
              this.props.onClose();
            }}
          />
        ]}
        open={this.state.open}
      >
        <div className="task-modal detail-modal">
          <div className="task-info">
            <List>
              {results.successfulCount! > 0 ? (
                <ListItem
                  leftIcon={
                    <FontIcon style={this.getBorderStyle(20, '#7ED321', 4, -3)} color={'black'} className="material-icons">
                      check
                    </FontIcon>
                  }
                  id="sucessful-count"
                  primaryText={`Number of tasks imported successfully: ${results.successfulCount}`}
                  innerDivStyle={{ fontSize: 18 + 'px' }}
                  style={{ paddingLeft: 57 + 'px', fontSize: 18 + 'px' }}
                  disabled={true}
                />
              ) : undefined}

              {results.errorLines!.length > 0 ? (
                <ListItem
                  leftIcon={
                    <FontIcon style={this.getBorderStyle(16, 'red', 0)} color={'black'} className="icon-change-task-import-error" />
                  }
                  id="error-lines"
                  primaryText={`Number of tasks with errors: ${results.errorLines!.length}`}
                  disabled={true}
                  innerDivStyle={{ fontSize: 18 + 'px' }}
                  style={{ paddingLeft: 57 + 'px', fontSize: 18 + 'px' }}
                  secondaryText="There might be more than one error per line"
                />
              ) : undefined}
            </List>
          </div>
          {results.errorLines!.length > 0 ? (
            <Table>
              <TableHeader style={tableheaderStyle} adjustForCheckbox={false} displaySelectAll={false}>
                <TableRow>
                  <TableHeaderColumn style={tableheaderStyle}>Line Number</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}>Task ID</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}>Task Title</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}>Error</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody style={tableBodyStyle} displayRowCheckbox={false}>
                {results.errorLines && results.errorLines.map((err) => this.renderErrorLog(err))}
              </TableBody>
            </Table>
          ) : undefined}
          {results.skippedLines!.length > 0 ? (
            <div className="task-info">
              <ListItem
                id="skipped-lines"
                leftIcon={
                  <FontIcon style={this.getBorderStyle(20, 'red', 4)} color={'black'} className="material-icons">
                    skip_next
                  </FontIcon>
                }
                primaryText={`Number of skipped tasks: ${results.skippedLines!.length}`}
                disabled={true}
                innerDivStyle={{ fontSize: 18 + 'px' }}
                style={{ paddingLeft: 57 + 'px', fontSize: 18 + 'px' }}
                secondaryText="Task IDs already exist"
              />
            </div>
          ) : undefined}
          {results.skippedLines!.length > 0 ? (
            <Table>
              <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                <TableRow>
                  <TableHeaderColumn style={tableheaderStyle}>Line Number</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}>Task ID</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}>Task Title</TableHeaderColumn>
                  <TableHeaderColumn style={tableheaderStyle}></TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody style={tableBodyStyle} displayRowCheckbox={false}>
                {results.skippedLines &&
                  results.skippedLines.map((skippedItem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableRowColumn>{skippedItem.lineNum}</TableRowColumn>
                        <TableRowColumn>{skippedItem.taskIdentifier}</TableRowColumn>
                        <TableRowColumn style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{skippedItem.taskTitle}</TableRowColumn>
                        <TableRowColumn></TableRowColumn>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : undefined}
        </div>
      </Dialog>
    );
  }
}
