import * as _ from 'lodash';
import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import { IUser } from 'mm-types';

const theme = {
  suggestionsContainerOpen: 'suggestionsContainerOpen',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'react-autosuggest__input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title'
};

export type Props = {
  value?: string;
  displayName?: string;
  renderSuggestion?: (suggestion: IUser) => JSX.Element;
  getSuggestionValue?: (val: string) => void;
  users: IUser[];
  getSuggestions: (term: string) => void;
  clearResultsOnClose?: boolean;
  onSelected: (data: any, username: string) => void;
  onClear: () => void;
  getInputValue?: (data: any) => string;
  placeholder?: string;
  reset?: boolean;
};

export type State = {
  autoSuggestDisplayValue: string;
};

export default class UserAutoSuggest extends React.Component<Props, State> {
  debouncedLoadSuggestions: (value: string) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      autoSuggestDisplayValue: props.displayName || ''
    };

    this.debouncedLoadSuggestions = _.debounce((value) => {
      this.props.getSuggestions(value);
    }, 500); // this determines the delay in the autosuggest field
  }

  static defaultProps: Partial<Props> = {
    placeholder: ''
  };

  componentOnMount() {
    this.setState({ autoSuggestDisplayValue: '' });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.props.reset! && nextProps.reset!) {
      this.setState({
        autoSuggestDisplayValue: ''
      });
    }
  }

  private _onSuggestChange(event, e) {
    if (!e.newValue || e.newValue.trim().length === 0) {
      if (this.props.onClear) {
        this.props.onClear();
      }
    }

    this.setState({
      autoSuggestDisplayValue: e.newValue
    });
  }

  private _getSuggestionValue(user: IUser) {
    return user.displayName; // value of input on select auto suggest
  }

  private _onSuggestionsUpdateRequested(e: any) {
    this.debouncedLoadSuggestions(e.value);
  }

  private _handleUserSearchSelection(e: any, data: any) {
    this.setState(
      {
        autoSuggestDisplayValue: this.props.getInputValue ? this.props.getInputValue(data) : ''
      },
      () => {
        this.props.onSelected(data, this.state.autoSuggestDisplayValue);
      }
    );
  }

  private _onSuggestionsClearRequested() {
    if (this.props.clearResultsOnClose) {
      this.props.onClear();
    }
  }

  render() {
    const sorted: IUser[] = _.sortBy(this.props.users, [(user) => (this._getSuggestionValue(user) || '').toLowerCase()]);

    return (
      <Autosuggest
        theme={theme}
        suggestions={sorted}
        onSuggestionsFetchRequested={(e) => this._onSuggestionsUpdateRequested(e)}
        onSuggestionsClearRequested={() => this._onSuggestionsClearRequested()}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={(suggestion) => this._getSuggestionValue(suggestion)}
        onSuggestionSelected={(e, data) => this._handleUserSearchSelection(e, data)}
        renderSuggestion={(suggestion) => this.props.renderSuggestion!(suggestion)}
        inputProps={{
          placeholder: this.props.placeholder,
          value: this.state.autoSuggestDisplayValue,
          onChange: (event, e) => this._onSuggestChange(event, e)
        }}
      />
    );
  }
}
