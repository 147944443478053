import * as Reflux from 'reflux';
import * as client from '../../clients/comments';
import Store from '../Store';
import { IComment } from 'mm-types';
import NotificationsStore from '../events/NotificationsStore';
import { Cancelled } from '../../clients/base-clients';
import ActiveUserStore from '../common/ActiveUserStore';

export type State = {
  unreadCommentsCount: number;
  comment: IComment | null;
};

export type CommentStatusStoreEvent = {
  type: 'unread-comments-retrieved' | 'comment-read-unread' | 'marked-all-to-read';
  state: Partial<State>;
};

export class CommentStatusStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      unreadCommentsCount: 0,
      comment: null
    };
  }

  getInitialState() {
    return this.state;
  }

  async listAllUnreadComments(projectUid: string, indexUid: string) {
    const userId = ActiveUserStore.getUser()!.uid;
    const statuses = await client.getCommentStatus(projectUid, indexUid, userId);

    if (statuses instanceof Cancelled) {
      return;
    }

    let unreadCommentCount = 0;
    statuses.forEach((comment) => {
      unreadCommentCount = unreadCommentCount + 1;
      if (comment.replies) {
        comment.replies.forEach((reply) => {
          unreadCommentCount = unreadCommentCount + 1;
        });
      }
    });

    this.state.unreadCommentsCount = unreadCommentCount;
    const event: CommentStatusStoreEvent = {
      type: 'unread-comments-retrieved',
      state: this.state
    };

    this.trigger(event);
  }

  async readOrUnreadComment(projectUid: string, indexUid: string, comment: IComment) {
    const userId = ActiveUserStore.getUser()!.uid;
    await client.setCommentStatus(projectUid, indexUid, userId, { uid: comment.uid, unread: !comment.unread });

    this.state.comment = comment;
    const event: CommentStatusStoreEvent = {
      type: 'comment-read-unread',
      state: this.state
    };
    this.trigger(event);
    this.listAllUnreadComments(projectUid, indexUid);
    NotificationsStore.retrieveNotifs();
  }

  async markAllToRead(projectUid: string, indexUid: string) {
    const userId = ActiveUserStore.getUser()!.uid;
    await client.setCommentStatus(projectUid, indexUid, userId, {}, true);

    this.state.comment = null;
    const event: CommentStatusStoreEvent = {
      type: 'marked-all-to-read',
      state: this.state
    };

    this.trigger(event);
    await this.listAllUnreadComments(projectUid, indexUid);
    NotificationsStore.retrieveNotifs();
  }
}

const singleton = Reflux.initStore<CommentStatusStore>(CommentStatusStore);
export default singleton;
