import FontIcon from 'material-ui/FontIcon';
import * as React from 'react';

type Props = {
  onClick?: (e: any) => void;
  isPublic: boolean;
  disabled?: boolean;
};

const VisibilityButton = (props: Props = { isPublic: false, disabled: false }) => {
  const containerClassName = 'visibility-button-component ' + (props.disabled ? 'disabled cursor-not-allowed' : 'cursor-pointer');

  return (
    <span onClick={(e) => (props.onClick && !props.disabled ? props.onClick(e) : '')} className={containerClassName}>
      <FontIcon className="component-icon material-icons lock-member">{props.isPublic ? 'lock_open' : 'lock'}</FontIcon>
      <span className="component-text">{props.isPublic ? 'PUBLIC' : 'INTERNAL'}</span>
    </span>
  );
};

export default VisibilityButton;
