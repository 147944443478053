import { IDocUnitProfile } from 'mm-types';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import { IUnit } from 'mm-types';
import { ZERO_LENGTH_WORD_JOINER } from '../tinyFacade/key_listeners/keyBehaviourUtils';
import { hasNonPrintableChar } from '../tinyFacade/key_listeners/keyBehaviourUtils';

export namespace UnitUtils {
  export const isFormatingAvailable = function (unitElement: HTMLElement | null) {
    return (
      ProjectDefinitionStore.allowUnitIndentation() &&
      ((unitElement?.parentElement?.tagName === 'TD' && unitElement?.tagName !== 'TABLE') ||
        unitElement?.parentElement?.className?.indexOf('arc-unit') !== -1)
    );
  };

  export const createListElementContainer = function (profile: IDocUnitProfile | undefined, content: string, dataNid: string | null) {
    if (!content) {
      // temporary / dummy content (one list item) so sanitizer doesn't remove empty list
      const $list = $(createElementContainer(profile, '<li>test</li>', dataNid)!);
      // remove temporary / dummy list item
      $list.children().remove();
      return $list.get(0);
    } else {
      return createElementContainer(profile, content, dataNid);
    }
  };

  export const createElementContainer = function (profile: IDocUnitProfile | undefined, content: string, dataNid?: string | null) {
    // construct element with splitContents
    const selectedDom = document.createElement('div');
    if (profile?.template) {
      selectedDom.innerHTML = profile?.template;
    }

    const $selectedDom = $(selectedDom);
    $selectedDom.removeAttr('data-nid').find('*').removeAttr('data-nid');
    profile?.template &&
      $selectedDom
        .find('.edit-target')
        .empty()
        .append($(profile?.template).find('.edit-target').html(content).html() === '' ? '' : content);
    const newUnitContent = $selectedDom.html();

    // construct Node from content html string
    const template = document.createElement('template')!;
    template.innerHTML = newUnitContent;
    if (template && template.content.firstElementChild && template.content.firstElementChild.firstElementChild) {
      if (dataNid) {
        template.content.firstElementChild.firstElementChild.setAttribute('data-nid', dataNid);
      }
      return template.content.firstElementChild.firstElementChild; // this is an element not a unit
    }
  };

  export const ensureNewListAttributes = (element: HTMLElement, content) => {
    if (element) {
      // If inside a list there are children other than LI then create LI and put them inside of it.
      if (element.classList.contains('arc-list') && element.classList.contains('editor-instance-container')) {
        if (element.childNodes.length > 0 && element.childNodes[0].nodeName !== 'LI') {
          // Move contents of the list to the newly created LI
          const listItem = document.createElement('LI');

          listItem.innerHTML = element.innerHTML;
          element.innerHTML = '';
          element.appendChild(listItem);

          // Change currently selected element to newly created LI so appropriate list attributes will be applied later
          element = listItem;
        }
      }

      // if new element is a list item then:
      // - make sure "arc-li" class is on the new list item
      // - make sure "arc-list" is on the list item's list (new sub list might be created)
      // - make sure "data-subtype" of parent list is being set on the list item's list (if new list is being
      //      created, we need to copy data-subtype from the parent list)
      if (element.nodeName === 'LI') {
        // create an empty P if nothing inside the LI
        if (element.childNodes.length === 0) {
          const elementContainer = createElementContainer(
            ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph'),
            content
          ) as Node;
          element.appendChild(elementContainer);
        }

        // if inside LI is only a text node and paragraph is a valid child of LI, wrap it into a paragraph container
        const rootElementWhitelist = ProjectDefinitionStore.getElementDefinitionById(
          element.attributes['data-element-definition-id']?.value
        )?.rootElementWhitelist;
        if (
          element.childNodes.length === 1 &&
          element.childNodes[0].nodeType === 3 &&
          (!rootElementWhitelist ||
            rootElementWhitelist?.length == 0 ||
            (rootElementWhitelist?.length > 0 && rootElementWhitelist.indexOf('Paragraph') > -1))
        ) {
          const text: string = element.innerHTML;
          const elementContainer = createElementContainer(
            ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph'),
            text
          ) as Node;
          element.innerHTML = '';
          element.appendChild(elementContainer);
          // TODO: gg - set cursor using editor.selection.setCursorLocation() to the end of the text (needs to pass in editor instance to the function here)
        }

        const $currentElement: JQuery<HTMLElement> = $(element);

        if (!$currentElement.hasClass('arc-li')) {
          $currentElement.addClass('arc-li');
        }

        if (!$currentElement.attr('data-element-definition-id')) {
          $currentElement[0].setAttribute('data-element-definition-id', 'ListItem');
        }

        // Make sure there's only one nested list
        // get all nested list under selected LI element (direct children only thus '>')
        const $nestedLists: JQuery<HTMLElement> = $currentElement.find(' > .arc-list');
        if ($nestedLists.length > 1) {
          // append to the first list all elements from the subsequent lists
          const $firstList: JQuery<HTMLElement> = $nestedLists.first();
          const $rest: JQuery<HTMLElement> = $nestedLists.slice(1);

          $rest.each((index: number, listElement: HTMLElement) => {
            $firstList.append($(listElement).children());
            $(listElement).remove();
          });
        }

        const $currentList: JQuery<HTMLElement> = $currentElement.parent();

        // make sure list item's list has arc-list class name
        if (!$currentList.hasClass('arc-list')) {
          $currentList.addClass('arc-list');
        }

        // make sure list item's list has a "data-subtype" attribute, taken from the parent list.
        // Only if it doesn't have "data-subtype" attribute already.
        const currentListDataSubtype = $currentList.attr('data-subtype');

        if (!currentListDataSubtype) {
          const $parentLists = $currentList.parents('.arc-list');

          // copy data attributes from the
          // TODO: gg once Mark will introduce new data- attributes make sure to update this
          if ($parentLists.length > 0) {
            const family = $parentLists[0].getAttribute('data-element-family') || '';
            const subtype = $parentLists[0].getAttribute('data-subtype') || '';
            const listType = $parentLists[0].getAttribute('data-list-type') || '';

            if (family) {
              $currentList.attr('data-element-family', family);
            }

            if (subtype) {
              $currentList.attr('data-subtype', subtype);
            }

            if (listType) {
              $currentList.attr('data-list-type', listType);
            }
          }
        }
      }
    }
  };

  export const isGeneratedUnit = (unit: IUnit) => {
    return !unit.uid || unit.uid.length > 36 || unit.uid.startsWith('generated');
  };

  export const isVirtualUnitUid = (uid: string): boolean => {
    return !uid || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(uid);
  };

  export const manageEcamFocus = (unitUid: string) => {
    // Add NoBreak; character after and before ecam
    const selectedNode = document.querySelector(`[data-nid='${unitUid}']`);

    if (selectedNode) {
      const ecams = document.querySelectorAll(`[data-nid='${unitUid}'] .arc-ecam-data`);
      ecams?.forEach((existingNode) => {
        const prevChar = existingNode.previousSibling?.textContent?.slice(-1);
        if (!hasNonPrintableChar(prevChar)) {
          existingNode.insertAdjacentText('beforebegin', ZERO_LENGTH_WORD_JOINER);
        }

        const ecamSysChar = existingNode.querySelector(`[data-subtype="ecamSys"]`)?.textContent?.slice(0);
        if (!hasNonPrintableChar(ecamSysChar)) {
          existingNode.querySelector(`[data-subtype="ecamSys"]`)?.insertAdjacentText('afterbegin', ZERO_LENGTH_WORD_JOINER);
        }

        const ecamTitleChar = existingNode.querySelector(`[data-subtype="ecamTitle"]`)?.textContent?.slice(0);
        if (!hasNonPrintableChar(ecamTitleChar)) {
          existingNode.querySelector(`[data-subtype="ecamTitle"]`)?.insertAdjacentText('afterbegin', ZERO_LENGTH_WORD_JOINER);
          existingNode.querySelector(`[data-subtype="ecamTitle"]`)?.insertAdjacentText('beforeend', ZERO_LENGTH_WORD_JOINER);
        }

        const nextChar = existingNode.nextSibling?.textContent?.slice(0);
        if (!hasNonPrintableChar(nextChar)) {
          existingNode.insertAdjacentText('afterend', ZERO_LENGTH_WORD_JOINER);
        }
      });
    }
  };
}
