import * as React from 'react';
import ServerSettingsStore from '../../flux/common/ServerSettingsStore';
import AgentUtil from '../../utils/AgentUtil';
import { IAccount } from 'mm-types';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import Container from './left-panel/Container';
import EditorMetrics from './EditorMetrics';
import DistributionReports from './distribution-reports/DistributionReports';
import { IUser } from 'mm-types';
import { AdminAndMemberOrgs, useOrgAdminAndMembers } from '../hooks/useOrgAdminAndMembers';
import { isFeatureOn } from '../featureSwitch/featureSwitchUtils';

export type SubpageType = 'editor-metrics' | 'distribution-reports';

export type State = {
  userName: string | null;
  userOptions: Partial<IAccount>[];
  isOrgAdmin: boolean;
  subpage: SubpageType;
};

function DashboardPage() {
  const [userOptions, setUserOptions] = React.useState<Partial<IAccount>[]>([
    {
      title: 'Aerodocs Editor',
      icon: 'ch',
      linkTitle: 'Google',
      link: 'https://www.google.com/chrome/browser/desktop/index.html',
      active: true
    },
    {
      title: 'Aerodocs Web Viewer',
      icon: 'icon-webviewer',
      linkTitle: 'Web Viewer',
      link: ServerSettingsStore.getServerSettings().onlineReaderBaseUrl,
      active: false
    },
    {
      title: 'AerDocs Distributor',
      icon: 'icon-distribution',
      linkTitle: 'Distributor',
      link: ServerSettingsStore.getServerSettings().aerosyncBaseUrl,
      active: false
    },
    {
      title: 'Aerodocs Accounts',
      icon: 'settings',
      linkTitle: 'Accounts',
      link: '/accounts',
      active: false
    }
  ]);

  const [userName, setUserName] = React.useState<string | null>(null);
  const [subpage, setSubpage] = React.useState<SubpageType>('editor-metrics');

  const adminAndMemberOrgs: AdminAndMemberOrgs = useOrgAdminAndMembers();
  const isAdmin: boolean = ActiveUserStore.isAdmin();
  const isTeamspaceAdmin: boolean = ActiveUserStore.isTeamspaceAdmin();
  const isTeamspaceMember: boolean = ActiveUserStore.isTeamspaceMember();

  React.useEffect(() => {
    if (!userName) {
      const updateUserOptions = (account: IAccount) => {
        if (account) {
          const newUserOptions = [...userOptions];
          newUserOptions[1].active = account.onlineReaderUser;
          newUserOptions[2].active = account.aerosyncReadAdmin;
          newUserOptions[3].active = account.accountsAdmin;
          setUserOptions(newUserOptions);
        }
      };
      const user: IUser | null = ActiveUserStore.getUser();
      if (user) {
        setUserName(user.displayName);
        updateUserOptions(user.account);
      }
    }

    return () => {};
  }, [userName, userOptions]);

  const selectSubpage = (subpage: SubpageType) => {
    setSubpage(subpage);
  };

  function showMoreThanMetrics() {
    return (
      isAdmin ||
      adminAndMemberOrgs.adminOrgs?.length > 0 ||
      adminAndMemberOrgs.memberOrgs?.length > 0 ||
      isTeamspaceAdmin ||
      isTeamspaceMember
    );
  }

  return (
    <div className="page dashboard">
      <nav className="page-header">
        <div className="logo" />
      </nav>
      {AgentUtil.isEditorSupported() && (
        <div className="page-body dashboard-container row dark-icons">
          <nav className="page-body-header icon-header">
            <div className="nav-wrapper">
              <h1>Dashboard</h1>
            </div>
          </nav>

          <div className="row page-inner">
            {showMoreThanMetrics() && isFeatureOn('kpi') && <Container subpage={subpage} onSelectSubpage={selectSubpage} />}

            {subpage === 'editor-metrics' && (
              <EditorMetrics
                isAdmin={isAdmin}
                isTeamspaceAdmin={isTeamspaceAdmin}
                isTeamspaceMember={isTeamspaceMember}
                adminAndMemberOrgs={adminAndMemberOrgs}
              />
            )}
            {subpage === 'distribution-reports' && showMoreThanMetrics() && <DistributionReports />}
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
