import moment from 'moment';
import ActiveUserStore from '../flux/common/ActiveUserStore';

const _PERSIST_MAX_SIZE = 20;

export type ILogMessage = {
  level: string;
  message: string;
};

class Log {
  info(message) {
    this._persistLog('INFO', message);
    console.info(message);
  }

  error(message) {
    this._persistLog('ERROR', message);
    console.error(message);
  }

  warn(message) {
    this._persistLog('WARN', message);
    console.warn(message);
  }

  trace(message) {
    this._persistLog('TRACE', message);
    console.trace(message);
  }

  debug(message: string) {
    this._persistLog('DEBUG', message);

    // only output to console in non prod env
    if (process.env.NODE_ENV !== 'production') {
      if (console.debug) {
        console.debug(message);
      } else {
        console.info(message);
      }
    }
  }

  trackEvent(message) {
    const trackEvents: string = localStorage.trackEvents || null;
    if (process.env.NODE_ENV !== 'production' && !!trackEvents && message.length >= 2) {
      console.log('\u001b[1;31m _______________');
      console.log('\u001b[1;32m Emitting event: ' + `\u001b[1;33m ${message[0]}`);
      if (message[1] && message[1][0] && 'type' in message[1][0]) {
        console.log('\u001b[1;32m Type: ' + `\u001b[1;34m ${message[1][0].type}`);
      }
      console.log('\u001b[1;32m Arguments: ', message[1]);
    }
  }

  getPersisted() {
    try {
      return { entries: JSON.parse(localStorage.log || '[]') as ILogMessage[] };
    } catch (e) {
      return { entries: [] as ILogMessage[] };
    }
  }

  clearPersisted() {
    localStorage.log = [];
  }

  _persistLog(level: string, message: string) {
    const userUid = ActiveUserStore.getUser() ? ActiveUserStore.getUser()!.uid : 'no userUid';
    const history: ILogMessage[] = JSON.parse(localStorage.log || '[]');

    history.push({
      level: level,
      message: `Date: ${moment(new Date()).format('LLL')}, UserUid: ${userUid},  ${message}`
    });

    if (history.length > _PERSIST_MAX_SIZE) {
      // limit to small list for performance
      history.splice(0, 1);
    }

    localStorage.log = JSON.stringify(history);
  }
}

export default new Log();
