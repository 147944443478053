import React from 'react';
import Store from '../../flux/Store';
export type Props = {
  store: Store<any>;
  eventListener: (any) => any;
  update?: any[];
};

/**
 * Utility hook for subscribe to store, always place before any useEffect that will trigger store event that needs to be subscribed to so that we can listen before the event is triggerd
 * @param store store to listen to
 * @param eventListener function called when store event happens
 * @param update update listener, bit awkward but basically eventListener function only has context based on initial set up, so needs to refreshed if function relies on props passed into component
 * Bit more info on this hook, when defining the eventListener it is important to be aware that the props/state in the function will be from the render that it is defined in
 * this is why we have the update dependency array so it will define the function again if one of those dependecies changes.
 * There is still an issue where if there is multiple dependencies in the update array that change in quick succession we can still end up with props/state in the eventListener function as the function
 * has been redefined in time with the correct state/props. Something to be aware of and currently have no fix for it but can lead to annoying behaviour. (see https://overreacted.io/a-complete-guide-to-useeffect/ old state/prop in effect)
 */
const useListenToStore = ({ store, eventListener, update = [] }: Props) => {
  React.useEffect(() => {
    const unsub = store.listen(eventListener, this);
    return () => unsub();
  }, update);
};

export default useListenToStore;
