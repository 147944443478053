/* FRAMEWORK */
import * as React from 'react';

/* UTILS */
import StringUtil from '../../../../../utils/StringUtil';
/* TYPES */
import { IUnitDefinition, IElementDefinition } from 'mm-types';
import { MenuItem } from '../../EditorMenu';
import { DefinitionToMenuPropsType } from '../MenuInsert';

export const unitDefinitionToMenuItem = (insertRule: (unitType: string) => boolean) => (unitDefinition: IUnitDefinition): JSX.Element => {
  return (
    <MenuItem
      key={unitDefinition.id}
      value={unitDefinition.id}
      disabled={!insertRule(unitDefinition.id)}
      className={`insert-${unitDefinition.id}`}
      description={unitDefinition.description}
    >
      {unitDefinition.title ? unitDefinition.title : `${StringUtil.formatForScreen(unitDefinition.displayName)}`}
    </MenuItem>
  );
};

export const elementDefinitionToMenuItem = (
  isElementInsertable: (
    elementType: string,
    insertAtLevel: number
  ) => {
    disabled: boolean;
    insertElement: HTMLElement | null;
    insertElementDirectChild: HTMLElement | null;
  }
) => (elementDefinition: IElementDefinition, definitionToMenuProps: DefinitionToMenuPropsType) => {
  // insert rule returns whether this elementDefinition can be inserted at all,
  // and if it can the definitionId of the nesting element which permits it.
  // Good god.
  const { disabled } = isElementInsertable(elementDefinition.id, definitionToMenuProps.insertAtLevel);
  return (
    <MenuItem
      key={'el_' + elementDefinition.id}
      value={'el_' + elementDefinition.id}
      disabled={disabled}
      className={`insert-${elementDefinition.id}`}
      description={elementDefinition.description}
    >
      {elementDefinition.displayName ? elementDefinition.displayName : `${StringUtil.formatForScreen(elementDefinition.id)}`}
    </MenuItem>
  );
};
