import * as React from 'react';
import { IMedia } from 'mm-types';

interface Props {
  media: IMedia;
  userAvatarUrl: string | null;
}

const Uploader = (props: Props) => {
  return (
    <span className="media-creating-user">
      {props.userAvatarUrl ? <img src={props.userAvatarUrl} width="40" alt="" /> : <span className="loading-avatar" />}
      <span>
        <span>{props.media.metadata.uploaderDisplayName}</span>
        <span>{props.media.createdFormatted}</span>
      </span>
    </span>
  );
};

export default Uploader;
