import * as React from 'react';
import SystemInfoStore, { SystemInfoStoreEvent } from '../../flux/common/SystemInfoStore';
import { transitions } from 'material-ui/styles';
import { Props } from './ToggleSection';

interface State {
  message: string;
  visible: boolean;
}

export default class SystemInfo extends React.Component<{}, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      message: '',
      visible: false
    };
  }

  componentDidMount() {
    this.unsubscribe = SystemInfoStore.listen(this.onSystemInfoStoreEvent, this);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  onSystemInfoStoreEvent(e: SystemInfoStoreEvent) {
    if (e.systemInfo) {
      this.setState({
        visible: e.systemInfo.visible,
        message: e.systemInfo.message
      });
    }
  }

  getRootStyles(): React.CSSProperties {
    return {
      position: 'fixed',
      left: '50%',
      display: 'flex',
      bottom: 0,
      zIndex: 301,
      fontFamily: 'Roboto, sans-serif',
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      padding: '0px 24px',
      height: '48px',
      lineHeight: '48px',
      borderRadius: '2px',
      maxWidth: '800px',
      minWidth: '288px',
      width: 'auto',
      flexGrow: 0,
      visibility: this.state.visible ? 'visible' : 'hidden',
      transform: this.state.visible ? 'translate(-50%, 0)' : 'translate(-50%, 48px)',
      transition: transitions.easeOut('400ms', 'transform') + ', ' + transitions.easeOut('400ms', 'visibility')
    };
  }
  getContentStyles(): React.CSSProperties {
    return {
      fontSize: 14,
      color: '#fff',
      opacity: this.state.visible ? 1 : 0,
      transition: this.state.visible ? transitions.easeOut('500ms', 'opacity', '100ms') : transitions.easeOut('400ms', 'opacity')
    };
  }

  render() {
    return (
      <div style={this.getRootStyles()} data-qa="system-info-snackbar">
        <div style={this.getContentStyles()}>
          <span>{this.state.message}</span>
        </div>
      </div>
    );
  }
}
