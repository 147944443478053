import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as client from '../../clients/special-interest';
import { SpecialInterestUpdateModel } from '../../clients/special-interest';
import IndexEventStore, { IndexEventStoreEvent } from '../events/IndexEventStore';
import ActiveUserStore from '../common/ActiveUserStore';
import EditorStore from './EditorStore';
import Store from '../Store';
import { DocParams, IComplianceTag, ISpecialInterestGroup, IUnit, IUnitSpecialInterestTag } from 'mm-types';
import { dateUtil } from '../../utils';

export type UnitSpecialInterestTagStoreEvent = {
  type: 'retrieveUnitSpecialInterestTagMap';
  state: State;
};

export type State = {
  unitSpecialInterestTagMap: client.SpecialInterestMapModel;
  specialInterestGroups: ISpecialInterestGroup[];
};

export class UnitSpecialInterestTagStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      unitSpecialInterestTagMap: {},
      specialInterestGroups: []
    };

    this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
  }

  getInitialState() {
    return this.state;
  }

  getUnitSpecialInterestTagMaps(units: IUnit[]) {
    let unitMaps: ISpecialInterestGroup[][] = [];

    if (units && units.length) {
      unitMaps = units.map((unit) => {
        return this.state.unitSpecialInterestTagMap[unit.uid]
          ? (this.state.unitSpecialInterestTagMap[unit.uid].specialInterestGroups as ISpecialInterestGroup[])
          : ([] as ISpecialInterestGroup[]);
      });
    }

    switch (unitMaps.length) {
      case 0:
        return [] as ISpecialInterestGroup[];
      case 1:
        return unitMaps[0];
      default:
        return _.intersectionBy(...unitMaps, 'uid') as ISpecialInterestGroup[];
    }
  }

  // tags used in document
  getSpecialInterestTagUses(): ISpecialInterestGroup[] {
    const specialInterestTagUses: IUnitSpecialInterestTag[][] = Object.values(this.state.unitSpecialInterestTagMap).map((value) => {
      return value.specialInterestGroups;
    });
    return _.uniqBy(_.flatten(specialInterestTagUses) as ISpecialInterestGroup[], 'name') ?? [];
  }

  // list of units tagged with tagUid
  getSpecialInterestTagUnits(tagUid: string) {
    const units: Array<string | null> = Object.values(this.state.unitSpecialInterestTagMap).map((value, index) => {
      return _.filter(value.specialInterestGroups, ['uid', tagUid]).length > 0 ? index.toString() : null;
    });

    // FIXME perhaps API should provide more contextual infos, revisit when time!
    const toRet: Partial<IComplianceTag>[] = _.map(_.compact(units), (unitUid) => {
      const unitObj: Partial<IComplianceTag> = {
        unit: EditorStore.getDocUnitModel(unitUid)!.toJSON(),
        link: null
      };

      unitObj.unit!.timestampFormatted = dateUtil(unitObj.unit?.timestamp).formatDateTimeNoSecs();
      return unitObj;
    });

    return toRet;
  }

  getSpecialInterestGroups() {
    return this.state.specialInterestGroups ? this.state.specialInterestGroups : [];
  }

  // Event Handlers

  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.isUserMe) {
      return;
    }

    if (e.activity === 'unitSpecialInterestTagChanged') {
      this.init({ indexUid: e.data.indexUid, projectUid: e.data.projectUid }, false);
    }
  }

  async init(docParams: DocParams, silent: boolean) {
    const tags = await Promise.all([
      client.getSpecialInterestUnitMap(docParams.projectUid!, docParams.indexUid!),
      client.getSpecialInterests()
    ]);

    this.state = {
      unitSpecialInterestTagMap: tags[0],
      specialInterestGroups: tags[1]
    };

    if (!silent) {
      const event: UnitSpecialInterestTagStoreEvent = {
        type: 'retrieveUnitSpecialInterestTagMap',
        state: this.state
      };
      this.trigger(event);
    }
  }

  async assignSpecialInterestTagsToUnit(specialInterestTags: SpecialInterestUpdateModel, docParams: DocParams, silent: boolean) {
    try {
      await client.addRemove(docParams.projectUid!, docParams.indexUid!, specialInterestTags);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'unitSpecialInterestTagChanged',
        data: {
          indexUid: docParams.indexUid!,
          projectUid: docParams.projectUid!
        }
      });
      await this.init(docParams, silent);
    } catch (err) {
      await this.init(docParams, silent);
    }
  }
}

const singleton = Reflux.initStore<UnitSpecialInterestTagStore>(UnitSpecialInterestTagStore);
export default singleton;
