import { AttachmentType } from 'mm-types';

export function getTypeFromMimeType(file?: File, mimeType?: string): AttachmentType {
  if (!file && !mimeType) {
    return 'other';
  }

  const fileMimeType = file ? file.type : mimeType;

  switch (fileMimeType) {
    case 'application/xml':
      return 'xml';

    case 'application/xhtml+xml':
      return 'xhtml';

    case 'application/rtf':
      return 'rtf';

    case 'text/html':
      return 'html';

    case 'application/pdf':
      return 'pdf';

    case 'application/zip':
      return 'archive';

    case 'video':
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/quicktime':
      return 'video';

    case 'text/plain':
      return 'text';

    case 'audio/mp3':
    case 'audio/mpeg':
      return 'audio';

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'doc';

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'spreadsheet';

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'presentation';

    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/bmp':
    case 'image/gif':
      return 'image';

    default:
      return 'other';
  }
}
