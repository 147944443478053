import Log from '../../app/utils/Log';
import EditorStore from '../../app/flux/editor/EditorStore';
import { browserHistory } from '../browserHistory';
import { ComponentHistory } from './index';

interface Params {
  [prop: string]: string | number | boolean | null | undefined;
}

export function call(method: 'push' | 'replace', name: string, params: Params = {}, getParams: Params = {}) {
  // route name is deprecated!!!  https://github.com/reactjs/react-router/issues/1514
  // TODO https://github.com/reactjs/react-router/blob/master/docs/API.md
  // new way is to transition to urls, we need to start doing this in components
  // not here and drop support for naming routes

  const urlParams = getParams ? '?' + $.param(getParams) : '';

  Log.info('Perform navigation: ' + method + ': ' + name + ': ' + urlParams);
  if (params) {
    Log.info('with params: ' + JSON.stringify(params));
  }

  if (name === 'editor-edit') {
    EditorStore.blurEditor(
      () => {
        if (params.tocableUnitUid) {
          const postfix = params.targetElementNid ? '/element/' + params.targetElementNid : '';

          browserHistory[method](
            '/editor/' +
              params.projectUid +
              '/document/' +
              params.indexUid +
              '/edit/' +
              params.tocableUnitUid +
              '/unit/' +
              params.targetUnitUid +
              postfix +
              urlParams,
            {}
          );
        } else {
          const postfix = params.targetUnitUid ? '/' + params.targetUnitUid : '';
          browserHistory[method]('/editor/' + params.projectUid + '/document/' + params.indexUid + '/edit' + postfix + urlParams, {});
        }
      },
      {},
      true
    );
  } else if (/^doc-.*-settings$/.test(name)) {
    type CustomState = {
      lastComponent: string;
    };

    const subpage = /^doc-(.*)-settings$/.exec(name)![1];
    // Ensure that component history has current location as refresh might have happened since it was last updated resulting in all values being ''
    ComponentHistory.update(location);
    const state = browserHistory.location.state as CustomState;
    const prevPage = state?.lastComponent === 'editor' ? 'editor' : ComponentHistory.current();
    browserHistory[method]('/documents/' + params.projectUid + '/' + params.indexUid + '/configure/' + subpage + urlParams, {
      lastComponent: prevPage
    });
  } else if (/^user-settings$/.test(name)) {
    browserHistory[method]('/user/' + params.userUid + '/configure/form/' + params.type + urlParams, {});
  } else if (/^my-settings$/.test(name)) {
    browserHistory[method]('/my-settings/' + params.type + urlParams, {});
  } else {
    const location = name.indexOf('/') === 0 ? name : '/' + name;
    browserHistory[method](location + urlParams, params);
  }
}

export function transitionTo(name: string, params?: Params, getParams?: Params) {
  call('push', name, params, getParams);
}

export function goBack(fallbackName: string, fallbackParams?: Params, fallbackGetParams?: Params) {
  if (browserHistory.length <= 0) {
    return transitionTo(fallbackName, fallbackParams, fallbackGetParams);
  }
  browserHistory.goBack();
}

export function isInternalEnv(): boolean {
  const name = location.hostname;
  return name === 'localhost' || name === 'aerodocs-dev.aerodocs-nonprod.viasat.io' || name === 'aerodocs-qa.aerodocs-nonprod.viasat.io';
}
