import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import { IEffectivity } from 'mm-types';

export type Props = {
  title: string;
  effectivities: IEffectivity[];
  action: (effectivity: IEffectivity[]) => void;
  icon: string;
  loading: boolean;
  isReadOnly: boolean;
};

const EffectivitiesList = (props: Props) => {
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedEffectivities, setSelectedEffectivities] = React.useState<IEffectivity[]>([]);
  const [lastSelectedEffectivityWithShift, setLastSelectedEffectivityWithShift] = React.useState<IEffectivity | null>(null);

  React.useEffect(() => {
    if (selectedEffectivities.length === 0) {
      setLastSelectedEffectivityWithShift(null);
    }
  }, [selectedEffectivities]);

  React.useEffect(() => {
    setLastSelectedEffectivityWithShift(null);
    setSelectedEffectivities([]);
  }, [props.effectivities]);

  const rowSelect = (e: React.MouseEvent, effectivity: IEffectivity) => {
    if (e.shiftKey) {
      if (!!lastSelectedEffectivityWithShift) {
        // select effectivities in range if there's an effectivty previously selected with the SHIFT key
        selectMultipleWithShift(lastSelectedEffectivityWithShift, effectivity);
      } else {
        // add to selected effectivities and remember it as a last selected effectivity with the SHIFT key
        setSelectedEffectivities([...selectedEffectivities, effectivity]);
        setLastSelectedEffectivityWithShift(effectivity);
      }
    } else {
      const selectedIndex = findEffectivityIndex(effectivity, selectedEffectivities);

      if (e.ctrlKey || e.metaKey) {
        // CTRL key selecting
        if (selectedIndex > -1) {
          // effectivity already selected, remove it from the selectedEffectivities
          setSelectedEffectivities(
            selectedEffectivities.filter((eff) => {
              return eff.reg !== effectivity.reg || eff.msn !== effectivity.msn || eff.model !== effectivity.model;
            })
          );
          setLastSelectedEffectivityWithShift(null);
        } else {
          // effectivity not yet selected, add it to the selectedEffectivities
          setSelectedEffectivities([...selectedEffectivities, effectivity]);
          setLastSelectedEffectivityWithShift(effectivity);
        }
      } else {
        // Single row selection without a modifier key (CTRL or SHIFT)
        setSelectedEffectivities([effectivity]);
        setLastSelectedEffectivityWithShift(effectivity);
      }
    }
  };

  const getEffectivityKey = (effectivity: IEffectivity) => {
    return effectivity.reg + effectivity.msn + effectivity.model;
  };

  const selectMultipleWithShift = (beginEffectivity: IEffectivity, endEffectivity: IEffectivity) => {
    const filteredEffectivities = getFilteredEffectivities();
    let startIndex = findEffectivityIndex(beginEffectivity, filteredEffectivities),
      endIndex = findEffectivityIndex(endEffectivity, filteredEffectivities);

    // Shouldn't happen, effectivities selected with the SHIFT key should always be visible on the screen
    // Also filter change clears out selectedEffectivities & lastSelectedEffectivityWithShift
    if (startIndex === -1 || endIndex === -1) {
      return;
    }

    // reverse indexes if SHIFT selection is from bottom to up
    if (startIndex > endIndex) {
      const savedStartIndex = startIndex;
      startIndex = endIndex;
      endIndex = savedStartIndex;
    }
    // performance trick, have selected effectivities as an array of strings for lookup efficiency
    const selectedEffectivitiesMapped = selectedEffectivities.map((effectivity) => getEffectivityKey(effectivity));

    // find out if at least one effectivity is not selected in the effectivitiesInRange
    const effectivitiesInRange = filteredEffectivities.slice(startIndex, endIndex + 1);
    const notSelectedEffectivities = effectivitiesInRange.filter((effectivity) => {
      // performance trick, using array of string to find an effectivity
      return selectedEffectivitiesMapped.indexOf(getEffectivityKey(effectivity)) === -1;
    });

    // Not all effectivities in selection range are selected, just select those not selected
    if (notSelectedEffectivities.length > 0) {
      setSelectedEffectivities([...selectedEffectivities, ...notSelectedEffectivities]);
    }
    // All effectivities in the selection range are selected, unselect them
    else {
      // performance trick, use an array of strings for quick lookup
      const effectivitiesInRangeMapped = effectivitiesInRange.map((effectivity) => getEffectivityKey(effectivity));
      setSelectedEffectivities(
        selectedEffectivities.filter((effectivity) => {
          return effectivitiesInRangeMapped.indexOf(getEffectivityKey(effectivity)) === -1;
        })
      );
    }
    // Always clear last selected effectivity with the SHIFT key. Regardless of if effectivities in range were selected or not.
    setLastSelectedEffectivityWithShift(null);
  };

  const findEffectivityIndex = (effectivity: IEffectivity, effectivities: IEffectivity[]): number => {
    if (!!effectivities) {
      return effectivities.findIndex((eff) => {
        return eff.reg === effectivity.reg && eff.msn === effectivity.msn && eff.model === effectivity.model;
      });
    }
    return -1;
  };

  const actionOnMultipleEffectivities = () => {
    // Make sure to filter out effectivities which are used somewhere else
    props.action(selectedEffectivities.filter((effectivity) => !isUsedSomewhereElse(effectivity)));
    setSelectedEffectivities([]);
  };

  const getFilteredEffectivities = () => {
    if (!!searchText) {
      const trimmedSearchText = searchText.trim();
      return props.effectivities.filter((effectivity) => {
        return (
          effectivity.reg.includes(trimmedSearchText) ||
          effectivity.msn.includes(trimmedSearchText) ||
          effectivity.model.includes(trimmedSearchText)
        );
      });
    } else {
      return props.effectivities;
    }
  };

  const filterTextChanged = (value: string) => {
    setSearchText(value);
    setLastSelectedEffectivityWithShift(null);
    setSelectedEffectivities([]);
  };

  const isUsedSomewhereElse = (effectivity: IEffectivity) => {
    return !!effectivity.sectionUid;
  };

  const isMultiActionDisabled = (): boolean => {
    if (props.isReadOnly) {
      return true;
    }
    const filteredSelectedEffectivities = selectedEffectivities.filter((effectivity) => {
      return !isUsedSomewhereElse(effectivity);
    });
    return filteredSelectedEffectivities.length <= 1;
  };

  const isActionDisabled = (effectivity: IEffectivity): boolean => {
    if (props.isReadOnly) {
      return true;
    }

    return props.loading || selectedEffectivities.length > 1 || isUsedSomewhereElse(effectivity);
  };

  return (
    <div className="effectivities-list-container" style={{ opacity: props.loading ? 0.5 : 1 }}>
      <h5>{props.title}</h5>
      <div className="effectivities-search-container">
        <input
          type="text"
          value={searchText}
          size={10}
          onChange={(e) => filterTextChanged(e.target.value)}
          placeholder="Type to filter"
          aria-label="Type to filter"
          className="effectivities-list-search-input"
        />
        <div className="loading">{props.loading ? <div className="loader" /> : undefined}</div>
      </div>
      <div className="effectivities-list-header">
        <span>Reg</span>
        <span>MSN</span>
        <span>Model</span>
        <span className="effectivity-list-button">
          <IconButton
            disabled={isMultiActionDisabled()}
            onClick={() => actionOnMultipleEffectivities()}
            iconClassName="material-icons"
            iconStyle={{ fontSize: '16px' }}
          >
            {props.icon}
          </IconButton>
        </span>
      </div>
      <div className="effectivities-list">
        {!!props.effectivities &&
          getFilteredEffectivities().map((effectivity, index) => {
            return (
              <div
                className={
                  'effectivities-list-row-container ' +
                  (isUsedSomewhereElse(effectivity) ? ' disabled ' : '') +
                  (findEffectivityIndex(effectivity, selectedEffectivities) > -1 ? 'selected ' : '')
                }
                key={index}
              >
                <div className={'effectivities-list-row '}>
                  <span onClick={(e) => rowSelect(e, effectivity)}>{effectivity.reg}</span>
                  <span onClick={(e) => rowSelect(e, effectivity)}>{effectivity.msn}</span>
                  <span onClick={(e) => rowSelect(e, effectivity)}>{effectivity.model}</span>
                  <span className="effectivity-list-button" onClick={() => !isActionDisabled(effectivity) && props.action([effectivity])}>
                    <IconButton disabled={isActionDisabled(effectivity)} iconClassName="material-icons" iconStyle={{ fontSize: '16px' }}>
                      {props.icon}
                    </IconButton>
                  </span>
                </div>
                {isUsedSomewhereElse(effectivity) && (
                  <div className="effectivities-list-row-subheading">Used in: {effectivity.sectionHeading}</div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EffectivitiesList;
