import * as React from 'react';
import { NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { useObjectValue } from '../useObjectValues';

import { NNCMetadataComponentProps } from './MetadataComponentTypes';
import { Toggle, ToggleProps, TextField } from 'material-ui';
import keyDown from '../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { AltTitleValues } from '../index';

const SubSectionToggle = (props: ToggleProps) => (
  <Toggle
    className="font-gray-label"
    thumbStyle={{ width: 16, height: 16, marginTop: 2 }}
    trackStyle={{ height: 12, marginTop: 1, width: '90%' }}
    {...props}
  />
);

const labels = {
  fontbold: 'Bold',
  fontlarge: 'Large',
  hide: 'Hide From Content',
  hidetoc: 'Hide From Inline TOC'
};

type Props = NNCMetadataComponentProps<NNCIndexMetadataID.TITLE> & {
  alternativeTitle?: boolean;
};

const TitleMetadata = React.memo(({ values, onChange, alternativeTitle = false, onTab, currentRef }: Props) => {
  const { localValues, updateValues } = useObjectValue<Props['values']>(values, onChange);
  const [disabled, setDisabled] = React.useState<boolean>(!!(alternativeTitle && localValues.text === ''));

  React.useEffect(() => {
    if (alternativeTitle && localValues.text === '') {
      setDisabled(true);
      updateValues({ text: '', fontbold: false, fontlarge: false, qai: false, eicas: false, unann: false, hide: false, hidetoc: false });
    } else {
      setDisabled(false);
    }
  }, [localValues.text]);

  React.useEffect(() => {
    if (localValues.qai || (!localValues.qai && localValues.fontlarge)) {
      updateValues({ fontbold: true });
    }
  }, [localValues.qai, localValues.fontlarge, localValues.fontbold]);

  const blurOnEnter = (e: React.KeyboardEvent<any>) => {
    keyDown(e, onTab, currentRef, !alternativeTitle ? true : localValues.text !== '');
  };

  return (
    <ul className="position-relative margin-top-none">
      <li className="margin-bottom-l" data-qa="text">
        <TextField
          hintText={alternativeTitle ? 'Add Alternative Title' : 'New NNC Checklist'}
          onChange={(e, text) => updateValues({ text: text.trimLeft() })}
          fullWidth={true}
          value={localValues.text === 'New NNC Checklist' ? '' : localValues.text}
          className="native-mui-no-padding"
          ref={currentRef}
          id={alternativeTitle ? 'nnc-index-metadata-alternative-title-text-input' : 'nnc-index-metadata-title-text-input'}
          onKeyDown={(e) => {
            blurOnEnter(e);
          }}
        />
      </li>
      {ProjectDefinitionStore.allowSetting('allowMultipleAlternativeTitles', false) && alternativeTitle && (
        <>
          <li className="margin-bottom-m" data-qa="hide">
            <SubSectionToggle
              label={labels.hide}
              toggled={(localValues as AltTitleValues).hide}
              onToggle={(e, checked) => updateValues({ hide: checked })}
              disabled={disabled}
            />
          </li>
          <li className="margin-bottom-m" data-qa="hide-toc">
            <SubSectionToggle
              label={labels.hidetoc}
              toggled={(localValues as AltTitleValues).hidetoc}
              onToggle={(e, checked) => updateValues({ hidetoc: checked })}
              disabled={disabled}
            />
          </li>
        </>
      )}
      <li className="margin-bottom-m" data-qa="qai">
        <SubSectionToggle
          label={ProjectDefinitionStore?.getCurrentProjectDefinition()?.indexDefinition?.qaiText}
          toggled={localValues.qai}
          onToggle={(e, checked) => updateValues({ qai: checked })}
          disabled={disabled}
        />
      </li>
      <li className="margin-bottom-m" data-qa="unann">
        <SubSectionToggle
          label={ProjectDefinitionStore?.getCurrentProjectDefinition()?.indexDefinition?.unannunciatedText}
          toggled={localValues.unann}
          onToggle={(e, checked) => updateValues({ unann: checked })}
          disabled={disabled}
        />
      </li>
      <li className="margin-bottom-xl" data-qa="eicas">
        <SubSectionToggle
          label={ProjectDefinitionStore?.getCurrentProjectDefinition()?.indexDefinition?.eicasText}
          toggled={localValues.eicas}
          onToggle={(e, checked) => updateValues({ eicas: checked })}
          disabled={disabled}
        />
      </li>

      <li className="margin-bottom-m" data-qa="fontbold">
        <SubSectionToggle
          id={'bold-toggle'}
          label={labels.fontbold}
          toggled={localValues.fontbold}
          data-istoggled={localValues.fontbold}
          onToggle={(e, checked) => updateValues({ fontbold: checked })}
          disabled={disabled}
        />
      </li>
      <li data-qa="fontlarge">
        <SubSectionToggle
          id={'large-toggle'}
          label={labels.fontlarge}
          toggled={localValues.fontlarge}
          data-istoggled={localValues.fontlarge}
          onToggle={(e, checked) => updateValues({ fontlarge: checked })}
          disabled={disabled}
          onKeyDown={(e) => keyDown(e, onTab, currentRef, false, true)}
        />
      </li>
    </ul>
  );
});

export { TitleMetadata };
