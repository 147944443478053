import * as React from 'react';
import { useEffect, useState } from 'react';
import ProjectItemMenu from '../ProjectItemMenu';
import GeneralInfo from './GeneralInfo';
import DocumentLog from './DocumentLog/DocumentLog';
import WorkflowInfo from './WorkflowInfo';
import { transitionTo } from '../../../utils/Navigation';
import IconButton from 'material-ui/IconButton';
import { IProject } from 'mm-types';
import { DocActions } from '../Documents';
import { useProjectSettings } from '../../editor/settings/useProjectSettings';
import ProjectPropsStore from '../../../flux/projects/ProjectPropsStore';

export type ActiveTabType = 'properties' | 'document-log' | 'workflow';

export type Props = {
  project: null | IProject;
  onAction: (type: DocActions, data: null | IProject) => void;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  loading: boolean;
  isReadOnly?: boolean;
  defaultActiveTab?: ActiveTabType;
};

export type State = {
  activeTab: ActiveTabType;
  currentlyOpenMenu: string;
};

const ProjectProps = ({ onAction, project, loading, isReadOnly, onClose, defaultActiveTab }: Props) => {
  const [activeTab, setActiveTab] = useState<string>('properties');
  const [currentlyOpenMenu, setCurrentlyOpenMenu] = useState<boolean>(false);
  const { settings, fetchSettings } = useProjectSettings(project);

  useEffect(() => {
    setCurrentlyOpenMenu(false);
    if (!settings) {
      fetchSettings();
    }
  }, [project?.uid]);

  useEffect(() => {
    return () => {
      ProjectPropsStore.clearCache();
    };
  });

  useEffect(() => {
    if (defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    }
  }, [defaultActiveTab]);

  function openMenu(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    setCurrentlyOpenMenu(!currentlyOpenMenu);
  }

  function closeMenu() {
    setCurrentlyOpenMenu(false);
  }

  function onMenuClick(textContent: string) {
    const transitionParam = {
      projectUid: project?.uid,
      indexUid: project?.masterIndexUid
    };

    const textContentList = {
      Open: transitionTo.bind(null, 'editor-edit', transitionParam),
      View: () => {
        throw new Error('Not implemented');
      },
      'Upload New Draft': onAction.bind(null, 'uploadExternal'),
      'Download Current Draft': onAction.bind(null, 'downloadNonCdmsCurrentDraft'),
      Copy: onAction.bind(null, 'copyDoc'),
      Settings: transitionTo.bind(null, 'doc-general-settings', transitionParam),
      Delete: onAction.bind(null, 'delete'),
      'Export to ArcML': onAction.bind(null, 'exportArcml'),
      'Export to EDB': onAction.bind(null, 'exportEdb'),
      'Export to Airbus': onAction.bind(null, 'exportAirbus'),
      'Load Manual': onAction.bind(null, 'loadManual'),
      'Create Interim Revision': onAction.bind(null, 'createInterim'),
      'Delete Interim Revision': onAction.bind(null, 'deleteInterim'),
      'Discard Current Draft': onAction.bind(null, 'discardDraft')
    };
    textContentList[textContent]?.();

    setCurrentlyOpenMenu(false);
  }

  function handleTab(tabId: ActiveTabType) {
    setActiveTab(tabId);
  }

  if (!project || !project.currentUserPermissions.isVisible) {
    return null;
  }

  return (
    <div className="document-details side-slidein-container">
      <div className="side-slidein-info-container">
        <span onClick={onClose} className="close-icon">
          <IconButton id="closePropsMenuBtn" iconClassName="material-icons">
            clear
          </IconButton>
        </span>

        <h1>{project.name}</h1>

        <div className="info">
          <span data-qa="document-details-edited-date">Edited {project.modifiedFormatted}</span>
          <div
            className="menu-icon"
            onClick={(e) => {
              openMenu(e);
            }}
          >
            <ProjectItemMenu
              open={currentlyOpenMenu}
              isExternal={project.isExternal}
              workspaceUid={project.workspaceUid}
              projectUid={project.uid}
              interimIndexUid={project.interimIndexUid ?? null}
              projectSettings={settings}
              permissions={project.currentUserPermissions}
              onSelected={onMenuClick}
              onClose={closeMenu}
              isReadOnly={isReadOnly}
            />
          </div>
          <div className="col s2 side-container properties-tabs-container">
            <div className="row properties-tabs-container-inner">
              <div className="col s12">
                <ul className="tabs icon-action-buttons ieTooltip">
                  <li
                    className={`tab col s3 general-info ${activeTab === 'properties' ? ' active' : ''}`}
                    data-tabid=""
                    onClick={() => handleTab('properties')}
                    data-qa={'general-info'}
                  >
                    <IconButton iconClassName="material-icons" tooltipPosition="top-left" tooltip="General Info">
                      info
                    </IconButton>
                  </li>
                  {project.currentUserPermissions.isVisible ? (
                    <li
                      className={`tab col s3 document-log ${activeTab === 'document-log' ? ' active' : ''}`}
                      data-tabid=""
                      onClick={() => handleTab('document-log')}
                      data-qa={'document-log'}
                    >
                      <IconButton iconClassName="material-icons" tooltipPosition="top-left" tooltip="Document Log">
                        restore
                      </IconButton>
                    </li>
                  ) : undefined}
                  {project.currentUserPermissions.isVisible ? (
                    <li
                      className={`tab col s3 workflow ${activeTab === 'workflow' ? ' active' : ''}`}
                      data-tabid=""
                      onClick={() => handleTab('workflow')}
                      data-qa={'workflow'}
                    >
                      <IconButton
                        id="external-workflow-tab"
                        className="fontello-menu-item"
                        iconClassName="material-icons icon icon-workflow"
                        tooltipPosition="top-left"
                        tooltip="Workflow"
                      />
                    </li>
                  ) : undefined}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeTab === 'properties' && (
        <div className="col s12 side-container-content general-props-content">
          <GeneralInfo project={project} loading={loading} isReadOnly={isReadOnly} />
        </div>
      )}
      {activeTab === 'document-log' && (
        <div className="col s12 side-container-content">
          <DocumentLog project={project} projectUid={project.uid} indexUid={project.masterIndexUid} />
        </div>
      )}
      {activeTab === 'workflow' && (
        <div className="col s12 side-container-content general-props-content">
          <div className="side-slidein-content-container">
            <WorkflowInfo project={project} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectProps;
