import * as React from 'react';
import { ElementPrintOutput, ElementPrintOutputProps } from './ElementPrintOutput';
import { PrintOutputUtils } from '../../../utils/PrintOutputUtils';

export type TablePrintOutputProps = {
  unitHtml: HTMLTableElement;
  dataRefNid: string;
};

type tableProps = {
  printOuput: ElementPrintOutputProps;
  height: string;
  dataNid: string;
}[];

export const TablePrintOutput = ({ unitHtml, dataRefNid }: TablePrintOutputProps) => {
  const theadHeight = `${
    document.querySelector<HTMLElement>("[data-nid='" + unitHtml?.tHead?.getAttribute('data-nid') + "']")?.offsetHeight || 0
  }px`;

  const captionHeight = `${
    document.querySelector<HTMLElement>("[data-nid='" + unitHtml?.caption?.getAttribute('data-nid') + "']")?.offsetHeight || 0
  }px`;

  const tbodies = [].slice.call(unitHtml.tBodies);

  const tableProps: tableProps = Array.from(tbodies[0].children).map((row: HTMLElement) => {
    const height = PrintOutputUtils.getRowHeight(row);
    return {
      printOuput: PrintOutputUtils.getPrintOutputAttributes(row),
      dataNid: row.getAttribute('data-nid') ?? '',
      height: height + 'px'
    };
  });

  const parentDiv = document.querySelector(`[data-nid="${dataRefNid}"`) as HTMLElement;
  const parentTop = parentDiv?.offsetTop ?? 0;

  return (
    <div className="table-element-page-break ignored" data-ref-nid={dataRefNid + '-table'} style={{ top: `${parentTop}px` }}>
      <div style={{ height: captionHeight, lineHeight: 'normal' }} data-ref-nid={unitHtml?.caption?.getAttribute('data-nid') + '-Caption'}>
        {' '}
      </div>
      <div style={{ height: theadHeight, lineHeight: 'normal' }} data-ref-nid={unitHtml?.tHead?.getAttribute('data-nid') + '-Head'}>
        {' '}
      </div>
      {tableProps.map((value, index) => (
        <div key={index} style={{ height: value.height, lineHeight: 'normal' }} data-ref-nid={value.dataNid + '-Row'}>
          <div className="table-row-page-break">
            <ElementPrintOutput {...value.printOuput} />
          </div>
        </div>
      ))}
    </div>
  );
};
