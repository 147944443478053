import React, { CSSProperties } from 'react';
import { LinearProgress } from 'material-ui';

interface Props {
  style?: CSSProperties;
  dataQa?: string;
}

const InProgress = ({ style, dataQa }: Props) => {
  return (
    <div data-qa={dataQa} style={{ marginRight: '1.5rem', paddingTop: '1rem', clear: 'both', marginBottom: '1.2rem', ...style }}>
      <LinearProgress mode="indeterminate" />
    </div>
  );
};

export default InProgress;
