import { Cancelled, mm } from './base-clients';
import { IElementHighlight, IHighlight, IHighlightCheckResponse, IUnit } from 'mm-types';
import { UnitTypes } from '../components/editor/utils/units/UnitTypes';
import affectedPart from '../components/editor/utils/units/AffectedPartUtil';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';
import { DocUnitWrapper } from '../components/editor/utils/tinyFacade/DocUnitWrapper';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import EditorStore from '../flux/editor/EditorStore';
import { CopyDetails } from '../utils/clipboard/clipboard';
import { makeSimpleSnackbar } from '../components/misc/SystemSnackbar/util';
import { CutCopyPasteUtil } from '../utils/CutCopyPasteUtil';

let getUnitsSource: CancelTokenSource | null = null;
let validateElementPasteSource: CancelTokenSource | null = null;

type CommonOptions = {
  unitsRequired: number;
  includeHeader: boolean;
  spellCheck: boolean;
  diff: boolean;
  diffIndexUid: string;
  diffTimestamp: string;
  findText: string;
  matchCase: boolean;
  wholeWord: boolean;
  offset: number;
};

export type GetOptions = {
  startUnitUid: string;
} & CommonOptions;

export type GetUnitOptions = {
  tocableUnitUid: string;
  offsetUnitUid?: string;
} & CommonOptions;

export type GetOneOptions = {
  diffUnitActivityUid: string;
  diffIndexUid: string;
  reverseDiff: boolean;
  searchPastRevisionsIfNotFound: boolean;
};

export interface FetchBatchResponse {
  refreshView: boolean;
  parts: string[];
  unitUids: string[];
  header?: any;
  units: IUnit[];
}

export async function getHighlights(projectUid: string, indexUid: string, unitUid: string, nested = true) {
  const response = await mm.get<IHighlight>(`/projects/${projectUid}/indexes/${indexUid}/highlights/units/${unitUid}`, {
    params: {
      nested
    }
  });
  return response.data;
}

export async function checkHighlights(params: { projectUid: string; indexUid: string }, cancelToken: CancelTokenSource) {
  try {
    const response = await mm.get<IHighlightCheckResponse>(`/projects/${params.projectUid}/indexes/${params.indexUid}/highlights/check`, {
      cancelToken: cancelToken.token
    });
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }
    throw err;
  }
}

export async function saveHighlights(projectUid: string, indexUid: string, unitUid: string, token: Partial<IHighlight>) {
  const response = await mm.put<IHighlight>(`/projects/${projectUid}/indexes/${indexUid}/highlights/units/${unitUid}`, token);
  return response.data;
}

export async function getElementHighlights(projectUid: string, indexUid: string, unitUid: string, nid: string) {
  const response = await mm.get<IElementHighlight>(
    `/projects/${projectUid}/indexes/${indexUid}/highlights/units/${unitUid}/elements/${nid}`
  );
  return response.data;
}

export async function saveElementHighlights(
  projectUid: string,
  indexUid: string,
  unitUid: string,
  nid: string,
  token: Partial<IElementHighlight>
) {
  const response = await mm.put<IElementHighlight>(
    `/projects/${projectUid}/indexes/${indexUid}/highlights/units/${unitUid}/elements/${nid}`,
    token
  );
  return response.data;
}

export async function savePastedString(projectUid: string, indexUid: string, content: string) {
  const response = await mm.post<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/paste-units`, {
    content: content
  });
  return response.data.units;
}

export async function splitUnit(projectUid: string, indexUid: string, unitUid: string, versionUid?: string, html?: string) {
  const query: string[] = [];

  if (versionUid) {
    query.push('lastVersionUid=' + versionUid);
  }

  const response = await mm.post<{ units: IUnit[] }>(
    `/projects/${projectUid}/indexes/${indexUid}/refactor/split/${unitUid}?${query.join('&')}`,
    { html: html, uid: unitUid, versionUid: versionUid }
  );
  const affected = affectedPart.affected(response.headers);
  return { ...affected, units: response.data.units };
}

export async function mergeUnits(projectUid: string, indexUid: string, units: { uid: string }[]) {
  const response = await mm.post<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/refactor/merge`, {
    units: units
  });
  const affected = affectedPart.affected(response.headers);
  return { ...affected, units: response.data.units };
}

export function parse(unit: IUnit, projectUid?: string, indexUid?: string) {
  if (unit.previousType || unit.definitionId) {
    // some units that are removed will have previousType: use this if available in determining ordinal/structural properties
    const actualType = (unit.previousType ? unit.previousType : unit.definitionId) as UnitTypes;

    // can override / apply ordinal
    unit.isordinable = ProjectDefinitionStore.isOrdinable(actualType, unit.type);

    // can change page type / size
    unit.isPageBreakBeforeControlEnabled = ProjectDefinitionStore.isPageBreakBeforeControlEnabled(unit);
    unit.canHavePrintOutput = ProjectDefinitionStore.canHavePrintOutput((unit.level ? unit.level : actualType) as UnitTypes);
    unit.canShowPdfSetting = ProjectDefinitionStore.canShowPdfSetting((unit.level ? unit.level : actualType) as UnitTypes);
    unit.canHaveVariantOutput = ProjectDefinitionStore.canHaveVariantOutput((unit.level ? unit.level : actualType) as UnitTypes);

    // can have ordinal (really for tables and images that have captions: ideally we would parse them and give it a hasOrdinal, but really we need a containsOrdinal attr in the future)
    unit.canHaveOrdinal = ProjectDefinitionStore.canHaveOrdinal(actualType);
    unit.istocable = ProjectDefinitionStore.isTocableType(actualType, unit.type);
    unit.isstructural = ProjectDefinitionStore.isStructuralType(actualType);

    // repeater heading
    unit.isRepeaterHeading = ProjectDefinitionStore.isRepeaterHeading(actualType);

    unit.isVisibleOnEdit = !(unit.type === 'ghost' || unit.type === 'removed');
  }

  unit.indexUid = indexUid;
  unit.projectUid = projectUid;

  const shareDetails = unit.shareDetails;

  if (shareDetails) {
    // refactor out when time:
    shareDetails.isShareEndUnit = shareDetails.shareEnd;
    shareDetails.isShareStartUnit = shareDetails.position === 0;
    shareDetails.origin = shareDetails.type === 'ORIGIN';
    shareDetails.uid = shareDetails.sharedIndexUid;
  }

  return unit;
}

export async function deleteBatch(projectUid: string, indexUid: string, units: { uid: string; lastVersionUid: string }[]) {
  const response = await mm.delete(`/projects/${projectUid}/indexes/${indexUid}/units/batch`, {
    data: { units: units }
  });
  const affected = affectedPart.affected(response.headers);

  const responseUnits: IUnit[] = response.data.units;
  for (const u of responseUnits) {
    parse(u, projectUid, indexUid);
  }

  return { ...affected, units: responseUnits };
}

export async function deleteUnit(projectUid: string, indexUid: string, unitUid: string, lastVersionUid?: string) {
  const response = await mm.delete(
    `/projects/${projectUid}/indexes/${indexUid}/units/${unitUid}?${lastVersionUid ? 'lastVersionUid=' + lastVersionUid : ''}`
  );
  const affected = affectedPart.affected(response.headers);
  return { ...affected, unit: parse(response.data as IUnit, projectUid, indexUid) };
}

export async function fetchBatch(
  projectUid: string,
  indexUid: string,
  unitUids: string[],
  headerUnitUid?: string
): Promise<FetchBatchResponse> {
  const query: string[] = [];
  if (headerUnitUid) {
    query.push('headerUnitUid=' + headerUnitUid);
  }

  const response = await mm.post<{ units: IUnit[]; header: IUnit }>(
    `/projects/${projectUid}/indexes/${indexUid}/units/batch/get?` + query.join('&'),
    unitUids
  );

  const affected = affectedPart.affected(response.headers);
  const responseUnits: IUnit[] = response.data.units;
  for (const u of responseUnits) {
    parse(u, projectUid, indexUid);
  }

  return { ...affected, units: responseUnits, header: response.data.header };
}

export async function getUnitInfos(projectUid: string, indexUid: string, tocUnitUid: string): Promise<DocUnitWrapper[]> {
  const response = await mm.get<{ unitInfos: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/unitInfos/${tocUnitUid}?flat=true`);

  const responseUnits: IUnit[] = response.data.unitInfos;
  const toRet: DocUnitWrapper[] = [];

  for (const u of responseUnits) {
    parse(u, projectUid, indexUid);
    const wrapper = new DocUnitWrapper(u);
    toRet.push(wrapper);
  }

  return toRet;
}

export function cancelGetUnits() {
  getUnitsSource?.cancel();
}

export async function getUnits(projectUid: string, indexUid: string, options?: Partial<GetUnitOptions>) {
  getUnitsSource = axios.CancelToken.source();

  const queryString: string[] =
    (
      options &&
      Object.keys(options).map((key) => {
        return options[key] ? `${key}=${options[key]}` : '';
      })
    )?.filter((option) => option !== '') ?? [];

  const response = await mm.get<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/units?` + queryString.join('&'), {
    cancelToken: getUnitsSource.token
  });

  if (!response) {
    throw new Error('getUnits api call ended up with no response');
  }

  const affected = affectedPart.affected(response.headers);
  const responseUnits: IUnit[] = response.data.units;
  const toRet: DocUnitWrapper[] = [];

  for (const u of responseUnits) {
    parse(u, projectUid, indexUid);
    const wrapper = new DocUnitWrapper(u);
    toRet.push(wrapper);
  }

  return { ...affected, units: toRet };
}

export async function convertBatch(projectUid: string, indexUid: string, units: IUnit[]) {
  units.forEach((u) => {
    if (u.type === 'heading') {
      u.type = 'tocable';
    }
  });

  const response = await mm.put<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/units/batch`, {
    units: units
  });
  const affected = affectedPart.affected(response.headers);
  const responseUnits: IUnit[] = response.data.units;
  const toRet: DocUnitWrapper[] = [];

  for (const u of responseUnits) {
    toRet.push(new DocUnitWrapper(parse(u, projectUid, indexUid)));
  }

  return { ...affected, units: toRet };
}

export async function getDocUnit(projectUid: string, indexUid: string, unitUid: string, options?: Partial<GetOneOptions>) {
  const query: string[] = [];

  if (options && options.diffIndexUid) {
    query.push('diffIndexUid=' + options.diffIndexUid);
  }
  if (options && options.diffUnitActivityUid) {
    query.push('diffUnitActivityUid=' + options.diffUnitActivityUid);
  }
  if (options && options.reverseDiff) {
    query.push('reverseDiff=' + options.reverseDiff);
  }
  if (options && options.searchPastRevisionsIfNotFound) {
    query.push('searchPastRevisionsIfNotFound=' + options.searchPastRevisionsIfNotFound);
  }

  const response = await mm.get<IUnit>(`/projects/${projectUid}/indexes/${indexUid}/units/${unitUid}?` + query.join('&'));
  const toRet: DocUnitWrapper = new DocUnitWrapper(response.data);
  parse(toRet.unit, projectUid, indexUid);
  return toRet;
}

export async function convertDocUnit(projectUid: string, indexUid: string, token: Partial<IUnit>) {
  if (token.type === 'heading') {
    token.type = 'tocable';
  }

  const response = await mm.put<IUnit>(`/projects/${projectUid}/indexes/${indexUid}/units/${token.uid}`, token);
  const toRet: DocUnitWrapper = new DocUnitWrapper(response.data);
  parse(toRet.unit, projectUid, indexUid);
  const affected = affectedPart.affected(response.headers);
  return { ...affected, wrapper: toRet };
}

export async function updateDocUnit(projectUid: string, indexUid: string, unit: Partial<IUnit>, revertActivityUid?: string) {
  const query: string[] = [];

  if (unit.versionUid) {
    query.push('lastVersionUid=' + unit.versionUid);
  }

  if (revertActivityUid) {
    query.push('revert=' + true);
    query.push('activityUid=' + revertActivityUid);
  }
  const response = await mm.put<IUnit>(`/projects/${projectUid}/indexes/${indexUid}/units/${unit.uid}?${query.join('&')}`, unit);
  const toRet: DocUnitWrapper = new DocUnitWrapper(response.data);
  parse(toRet.unit, projectUid, indexUid);
  const affected = affectedPart.affected(response.headers);
  return { ...affected, wrapper: toRet };
}

export async function saveDocUnit(
  projectUid: string,
  indexUid: string,
  token: Partial<IUnit>,
  options: { beforeUnitUid?: string; afterUnitUid?: string; parentUid?: string }
) {
  const query: string[] = [];

  if (options.beforeUnitUid) {
    query.push('beforeUnitUid=' + options.beforeUnitUid);
  } else if (options.afterUnitUid) {
    query.push('afterUnitUid=' + options.afterUnitUid);
  }
  if (options.parentUid) {
    query.push('parentUid=' + options.parentUid);
  }

  const response = await mm.post<IUnit>(`/projects/${projectUid}/indexes/${indexUid}/units?` + query.join('&'), token);
  const affected = affectedPart.affected(response.headers);
  const toRet: DocUnitWrapper = new DocUnitWrapper(response.data);
  parse(toRet.unit, projectUid, indexUid);
  return { ...affected, unit: toRet };
}

export async function createBatch(
  projectUid: string,
  indexUid: string,
  units: { uid: string }[],
  options: { beforeUnitUid?: string; afterUnitUid?: string }
) {
  const query: string[] = [];

  if (options.beforeUnitUid) {
    query.push('beforeUnitUid=' + options.beforeUnitUid);
  } else if (options.afterUnitUid) {
    query.push('afterUnitUid=' + options.afterUnitUid);
  }

  const response = await mm.post<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/units/batch?` + query.join('&'), {
    units: units
  });
  const affected = affectedPart.affected(response.headers);
  const responseUnits: IUnit[] = response.data.units;
  for (const u of responseUnits) {
    parse(u, projectUid, indexUid);
  }

  return { ...affected, units: responseUnits };
}

export async function validateElementPaste(unitToPasteInto: string, unitsToPaste?: CopyDetails[]): Promise<IUnit | Cancelled | undefined> {
  if (validateElementPasteSource) {
    validateElementPasteSource.cancel();
  }
  validateElementPasteSource = axios.CancelToken.source();

  let wrappedUnits: { html: string }[] = [];

  if (unitsToPaste) {
    if (unitsToPaste[0].isElement) {
      const pasteUnitUnitDef = ProjectDefinitionStore.getUnitDefinitionById('paste-content');

      if (pasteUnitUnitDef?.templateHtml) {
        const pasteUnitTemplate = document.createElement('template');
        pasteUnitTemplate.innerHTML = pasteUnitUnitDef.templateHtml;

        wrappedUnits = unitsToPaste.map((unit) => {
          (pasteUnitTemplate.content.firstElementChild as Element).querySelectorAll('.edit-target')[0].innerHTML = unit.copiedHtml;
          return { html: pasteUnitTemplate.content.firstElementChild?.outerHTML as string };
        });
      }
    } else {
      wrappedUnits = unitsToPaste.map((unit) => {
        return { html: unit.copiedHtml };
      });
    }

    const docParams = EditorStore.getDocParams();
    const currentSelectedUnit = EditorStore.getSelectedUnit();

    const formData = new FormData();
    formData.set(
      'unit',
      new Blob([JSON.stringify({ ...currentSelectedUnit, html: unitToPasteInto })], {
        type: 'application/vnd.arconics.mm.v3.0+json'
      })
    );
    formData.set(
      'units',
      new Blob([JSON.stringify({ units: wrappedUnits })], {
        type: 'application/vnd.arconics.mm.v3.0+json'
      })
    );

    try {
      const response = await mm.post<IUnit>(
        `/projects/${docParams.projectUid}/indexes/${docParams.indexUid}/units/pasteValidate`,
        formData,
        {
          headers: {
            Accept: 'application/vnd.arconics.mm.v3.0+json',
            'Content-Type': 'multipart/form-data'
          },
          cancelToken: validateElementPasteSource.token
        }
      );
      validateElementPasteSource = null;
      return response.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return new Cancelled();
      } else if (axios.isAxiosError(e)) {
        const err = e as AxiosError;
        CutCopyPasteUtil.handlePasteErrors(err);
      }
    }
  } else {
    makeSimpleSnackbar('Please copy something to clipboard before pasting');
  }
}
