import * as React from 'react';
import { RefObject } from 'react';
import * as ReactDOM from 'react-dom';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ActivityLogStore, { ActivityLogStoreEvent } from '../../../../../flux/editor/ActivityLogStore';
import UnitConceptStore, { UnitConceptStoreEvent } from '../../../../../flux/editor/UnitConceptStore';
import TocStore, { TocStoreEvent } from '../../../../../flux/editor/TocStore';
import { default as PopupMenu, Option, Options } from '../../../../misc/PopupMenu';
import ActivityListTypes, { ActivityListViewTypes } from './ActivityListTypes';
import { CircularProgress } from 'material-ui';
import { EventStoreEventType, IActivity, IEditorStoreEvent } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

// persists last accessed list across unmounts
const _persistForIndex: { indexUid: string | null; lastListViewingSelected: ActivityListViewTypes | null } = {
  indexUid: null,
  lastListViewingSelected: null
};

let ACTIVITY_COUNT = 0;
let MAX_SCROLL = false;

export type Props = {};

export type State = {
  listViewing: ActivityListViewTypes;
  loading: boolean;
  loadingScroll: boolean;
  activityEntries: IActivity[] | null;
};

export default class Activity extends React.Component<Props, State> {
  private activityUnsub: Function;
  private unitConceptUnsub: Function;
  private editorUnsub: Function;
  private tocUnsub: Function;
  private displayOptionsMenu: RefObject<PopupMenu>;
  private displayOptionsBtn: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.activityUnsub = ActivityLogStore.listen(this._onActivityStoreUpdate, this);
    this.unitConceptUnsub = UnitConceptStore.listen(this._onUnitConceptChange, this);
    this.editorUnsub = EditorStore.listen(this._onEditStoreUpdate, this);
    this.tocUnsub = TocStore.listen(this._onTocStoreUpdate, this);
    this.displayOptionsMenu = React.createRef();
    this.displayOptionsBtn = React.createRef();

    this.state = {
      listViewing: 'ACTIVITIES_TOC_SELECTED',
      activityEntries: null,
      loadingScroll: false,
      loading: true
    };
  }

  componentWillUnmount() {
    this.activityUnsub();
    this.unitConceptUnsub();
    this.editorUnsub();
    this.tocUnsub();
    MAX_SCROLL = false;
    ACTIVITY_COUNT = 0;
  }

  componentDidMount() {
    this.setState({ listViewing: this._getDefaultListViewing(), loading: true }, () => {
      this._retrieveLogs(true);
    });
    MAX_SCROLL = false;
    ACTIVITY_COUNT = 0;
  }

  UNSAFE_componentWillReceiveProps() {
    const docChanged = ActivityLogStore.getCurrentDocIndex() !== EditorStore.getDocParams().indexUid;

    if (docChanged) {
      this.setState({ listViewing: this._getDefaultListViewing(), loading: true }, () => {
        this._retrieveLogs(false);
      });
    }
  }

  _retrieveLogs(reset = false) {
    ActivityLogStore.retrieveLogs(EditorStore.getDocParams(), this.state.listViewing, reset);
  }
  _onUnitConceptChange(newState: UnitConceptStoreEvent) {
    // Listen to remove or add variant event

    if (newState.type === 'retrieving-unit-facet-map') {
      this.setState({
        loading: true
      });
    }

    if (newState.type === 'retrieved-unit-facet-map') {
      this._retrieveLogs();
    }
  }

  _onActivityStoreUpdate(e: ActivityLogStoreEvent) {
    if (e.state.activityEntries && e.type === 'pagination') {
      if (ACTIVITY_COUNT === e.state.activityEntries.length) {
        MAX_SCROLL = true;
      } else {
        ACTIVITY_COUNT = e.state.activityEntries.length;
      }
    } else {
      MAX_SCROLL = false;
    }

    this.setState({
      activityEntries: e.state.activityEntries,
      loadingScroll: false,
      loading: false
    });
  }

  _scrollListToTop() {
    if (this.refs.activityEntriesList) {
      (ReactDOM.findDOMNode(this.refs.activityEntriesList) as Element).scrollTop = 0;
    }
  }

  // editor change event
  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    // document changed so refresh to get latest most recent activity currently displaying
    if (e.type === 'documentChanged') {
      this._scrollListToTop();

      if (['ACTIVITIES_ALL', 'ACTIVITIES_TOC_SELECTED', 'UNDO_HISTORY'].includes(this.state.listViewing)) {
        this._retrieveLogs();
      }
    } else if (e.type === 'unitsSelected') {
      if (this.state.listViewing === 'ACTIVITIES_UNIT_SELECTED') {
        this._scrollListToTop();
        this._retrieveLogs();
      }
      // this ensures popup menu updates with latest selected unit info
      else {
        this.forceUpdate();
      }
    }
  }

  // toc selection changed
  _onTocStoreUpdate(e: TocStoreEvent) {
    const refreshCurrent = () => {
      this.setState({ listViewing: this._getDefaultListViewing(), loading: true }, () => {
        this._retrieveLogs(true);
      });
    };

    // if viewing based on TOC, then re-render, otherwise leave list as is...
    if (['ACTIVITIES_TOC_SELECTED', 'ACTIVITIES_UNIT_SELECTED'].includes(this.state.listViewing) || e.type === 'tocManipulation') {
      refreshCurrent();
    }
  }

  _isTocFrontMatter() {
    const selectedToc = TocStore.getSelectedItem();
    return selectedToc && selectedToc.type.indexOf('frontmatter') !== -1;
  }

  _displaySelectedUnitOption() {
    return !this._isTocFrontMatter() && EditorStore.getSelectedUnit() !== null;
  }

  _getSelectedTocAsString() {
    const selectedToc = TocStore.getSelectedItem();
    if (selectedToc && selectedToc.level && selectedToc.ordinal) {
      return selectedToc.level != 'dynamic'
        ? selectedToc.level.toLowerCase() + ': ' + selectedToc.ordinal
        : selectedToc.definitionId.toLowerCase() + ': ' + selectedToc.ordinal;
    }
    return '';
  }

  _getSelectedUnitAsString() {
    const selectedUnit = EditorStore.getSelectedUnit();
    return selectedUnit
      ? 'Selected ' +
          ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(selectedUnit.definitionId)?.displayName
      : '';
  }

  _toggleActivityMenu() {
    if (this.displayOptionsBtn.current) {
      (this.displayOptionsMenu.current as PopupMenu).setTriggerButton(this.displayOptionsBtn.current).open();
    }
  }

  _getDefaultListViewing() {
    const indexUid = EditorStore.getDocParams().indexUid!;
    let selectedList: ActivityListViewTypes | null = null;

    if (_persistForIndex.indexUid === indexUid) {
      selectedList = _persistForIndex.lastListViewingSelected;
    } else {
      _persistForIndex.indexUid = indexUid;
      _persistForIndex.lastListViewingSelected = null;
    }

    const lastListViewingSelected =
      selectedList === null ? (this._isTocFrontMatter() ? 'ACTIVITIES_ALL' : 'ACTIVITIES_TOC_SELECTED') : selectedList;

    if (
      this._isTocFrontMatter() &&
      (lastListViewingSelected === 'ACTIVITIES_TOC_SELECTED' || lastListViewingSelected === 'ACTIVITIES_UNIT_SELECTED')
    ) {
      return 'ACTIVITIES_ALL';
    }

    return lastListViewingSelected;
  }

  _getListEmptyDescription() {
    return ActivityListTypes.props[this.state.listViewing].emptyDescription;
  }

  _getListViewingTitle() {
    let title = ActivityListTypes.props[this.state.listViewing].title;

    if (title === null) {
      if (this.state.listViewing === 'ACTIVITIES_TOC_SELECTED') {
        title = this._getSelectedTocAsString();
      } else if (this.state.listViewing === 'ACTIVITIES_UNIT_SELECTED') {
        title = this._getSelectedUnitAsString();
      }
    }

    return title;
  }

  _handleListPage() {
    if (!MAX_SCROLL) {
      this.setState({ loadingScroll: true }, () => {
        ActivityLogStore.retrieveLogsPage(EditorStore.getDocParams(), { next: true }, this.state.listViewing);
      });
    }
  }

  _handleListRefreshRequest() {
    this._retrieveLogs();
  }

  _handleListViewSwitch(type) {
    _persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
    _persistForIndex.lastListViewingSelected = type;

    this.setState({ listViewing: type, loading: true }, () => {
      this._retrieveLogs(true);
    });
  }

  _getListSwitchMenu() {
    const menuItems = [
      {
        key: 'ACTIVITIES_ALL' as ActivityListViewTypes,
        title: 'All document activity',
        checked: this.state.listViewing === 'ACTIVITIES_ALL',
        isDisabled: false
      },
      {
        key: 'ACTIVITIES_TOC_SELECTED' as ActivityListViewTypes,
        title: this._getSelectedTocAsString() + ' activity',
        checked: this.state.listViewing === 'ACTIVITIES_TOC_SELECTED',
        isDisabled: this._isTocFrontMatter()!
      },
      {
        key: 'ACTIVITIES_UNIT_SELECTED' as ActivityListViewTypes,
        title: this._getSelectedUnitAsString() + ' activity',
        checked: this.state.listViewing === 'ACTIVITIES_UNIT_SELECTED',
        isDisabled: !this._displaySelectedUnitOption()!
      },
      {
        key: 'UNDO_HISTORY' as ActivityListViewTypes,
        title: 'Undo History',
        checked: this.state.listViewing === 'UNDO_HISTORY',
        isDisabled: false
      }
    ];

    return (
      <PopupMenu ref={this.displayOptionsMenu} onSelect={(e) => this._handleListViewSwitch(e)} className="display-options-popup">
        <Options>
          <div className="popup-arrow" />
          <div className="popup-menu-title">Show:</div>

          {menuItems.map((menuItem) => {
            return (
              <Option
                canCheckOff={false}
                disabled={menuItem.isDisabled}
                hasCheck={menuItem.checked}
                defaultChecked={menuItem.checked}
                dataKey={menuItem.key}
                key={menuItem.key}
              >
                {menuItem.title}
              </Option>
            );
          })}
        </Options>
      </PopupMenu>
    );
  }

  _getListComponent() {
    if (this.state.listViewing) {
      return React.createElement(ActivityListTypes.props[this.state.listViewing].listComponent, {
        loading: this.state.loading,
        loadingScroll: this.state.loadingScroll,
        activityEntries: this.state.activityEntries,
        onNextPageScroll: () => this._handleListPage(),
        onListRefreshRequest: () => this._handleListRefreshRequest(),
        ref: this.state.listViewing
      });
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="activity-subaction subaction-list-container">
        <h5>Activity Log</h5>
        {!this.state.loading ? (
          <h6>
            <span>{this._getListViewingTitle()}</span>

            <div className="icon-display-options tiny material-icons" onClick={(e) => this._toggleActivityMenu()}>
              arrow_drop_down
            </div>
            <div
              className="icon-display-options tiny material-icons"
              ref={this.displayOptionsBtn}
              onClick={(e) => this._toggleActivityMenu()}
            >
              filter_list
            </div>
          </h6>
        ) : undefined}

        {this._getListSwitchMenu()}

        {this._getListComponent()}

        {this.state.activityEntries && this.state.activityEntries.length === 0 ? (
          <div className="narrow-panel-empty-list panel-sizing">
            <img src={'/assets/images/activity_panel_info.svg'} />

            <span className="empty-title">No Activity</span>
            <span className="empty-details">{this._getListEmptyDescription()}</span>
          </div>
        ) : undefined}

        {this.state.loading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" size={17.85} />
          </div>
        ) : undefined}
      </div>
    );
  }
}
