import * as React from 'react';
import { FlatButton, FontIcon } from 'material-ui';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Dialog from 'material-ui/Dialog';
import Toggle from 'material-ui/Toggle';
import { IPDFExportOptions, IVariant } from 'mm-types';
import DiffExportRow from './DiffExportRow';

export type Props = {
  onClose: () => void;
  onExport: (params: IPDFExportOptions) => void;
  variants: IVariant[];
};

export type State = {
  variantsToggled: boolean;
  additionalToggled: boolean;
  options: IPDFExportOptions;
  errorMsg?: string;
};

const initialOptionsState: IPDFExportOptions = {
  exportRange: 'exportPdfSelection',
  variantUid: null,
  internalCommentsChecked: true,
  internalCommentsValue: 'ALL',
  publicCommentsChecked: false,
  publicCommentsValue: 'ALL',
  privateRevisionHighlightsChecked: false,
  privateRevisionHighlightsValue: 'ALL',
  generalRevisionHighlightsChecked: false,
  generalRevisionHighlightsValue: 'ALL'
};

const getFirstVariantUid = (variants: IVariant[]) => {
  return variants && variants.length > 0 ? variants[0].uid : '';
};

const DiffExportDialog = (props: Props) => {
  // const [variantsToggled, setVariantsToggled] = React.useState<boolean>(true);
  const [additionalToggled, setAdditionalToggled] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<IPDFExportOptions>({
    ...initialOptionsState,
    variantUid: getFirstVariantUid(props.variants)
  });

  const submitExport = () => {
    if (additionalToggled) {
      props.onExport(options);
    } else {
      props.onExport({
        ...options,
        internalCommentsChecked: undefined,
        internalCommentsValue: undefined,
        publicCommentsChecked: undefined,
        publicCommentsValue: undefined,
        privateRevisionHighlightsChecked: undefined,
        privateRevisionHighlightsValue: undefined,
        generalRevisionHighlightsChecked: undefined,
        generalRevisionHighlightsValue: undefined
      });
    }
  };

  /*
  let onVariantsToggle = (toggled: boolean) => {
    setVariantsToggled(toggled);
    if (!toggled) setOptions({...options, variantUid: null});
  };
  */

  return (
    <Dialog
      bodyStyle={{ padding: '0' }}
      contentStyle={{ width: '30%', minWidth: '250px' }}
      className="export-doc-modal export-doc-modal-diff"
      open={true}
      onRequestClose={() => props.onClose()}
    >
      <div className="dialogue-header">
        <div className="close-button right" onClick={() => props.onClose()}>
          <FontIcon className="material-icons icon-close" />
        </div>
        <h5>Export Options</h5>
      </div>
      <div className="inner-content">
        <div className={'export-section'} data-qa="range-section-content">
          <h6>Select Range</h6>

          <div id={'range-selector'} className={'export-row'} data-qa-selected-value={options.exportRange}>
            <RadioButtonGroup
              className="range-group"
              onChange={(e, value) => setOptions(Object.assign({}, options, { exportRange: value }))}
              name="exportRange"
              defaultSelected="exportPdfSelection"
            >
              <RadioButton
                id={'range-selector-current-selection'}
                iconStyle={{ marginRight: '8px' }}
                disabled={false}
                value="exportPdfSelection"
                label="Current Selection"
                className={'range-item'}
              />
              <RadioButton
                id={'range-selector-entire-document'}
                iconStyle={{ marginRight: '8px' }}
                disabled={false}
                value="exportPdf"
                label="Entire Document"
                className={'range-item'}
              />
            </RadioButtonGroup>
          </div>
        </div>

        {props.variants && props.variants.length > 0 ? (
          <div className={'export-section'}>
            {/* commenting out instead of deleting. It's a variant section toggle. Soon we will allow for all variants to be exported
              <Toggle
                id={'variants-toggle'}
                name={'variants'}
                label={'Variants'}
                disabled={false}
                value={''}
                toggled={variantsToggled}
                onToggle={() => onVariantsToggle(!variantsToggled )}
              />

              { variantsToggled ?(
              */}

            <h6>Variants</h6>

            <div className={'export-row'} id={'variants-section'} data-qa-selected-value={options.variantUid}>
              <RadioButtonGroup
                className="range-group variant-option"
                onChange={(e, value) => setOptions(Object.assign({}, options, { variantUid: value }))}
                name="exportVariantsList"
                valueSelected={options.variantUid}
              >
                {props.variants.map((v, index) => {
                  return <RadioButton className={'variant-' + index} key={'variant-' + index} value={v.uid} label={v.name} />;
                })}
              </RadioButtonGroup>
            </div>

            {/* ): null }*/}
          </div>
        ) : null}

        <div className={'export-section'}>
          <Toggle
            id={'additional-info-toggle'}
            name={'additional'}
            label={'Additional Information'}
            disabled={false}
            value={''}
            toggled={additionalToggled}
            onToggle={() => setAdditionalToggled(!additionalToggled)}
          />

          {additionalToggled ? (
            <div>
              <h6>Comments</h6>

              <DiffExportRow
                id={'internal-comments'}
                className={'export-row'}
                checked={options.internalCommentsChecked!}
                name={'Internal'}
                value={options.internalCommentsValue!}
                valueName={'internalCommentsValue'}
                onCheck={() => setOptions(Object.assign({}, options, { internalCommentsChecked: !options.internalCommentsChecked }))}
                onValueChanged={(value: string) => setOptions(Object.assign({}, options, { internalCommentsValue: value }))}
                radioButtons={[
                  { value: 'ALL', label: 'All' },
                  { value: 'RESOLVED', label: 'Resolved' },
                  { value: 'ACTIVE', label: 'Unresolved' }
                ]}
              />

              <DiffExportRow
                id={'public-comments'}
                className={'export-row'}
                checked={options.publicCommentsChecked!}
                name={'Public'}
                value={options.publicCommentsValue!}
                valueName={'publicCommentsValue'}
                onCheck={() => setOptions(Object.assign({}, options, { publicCommentsChecked: !options.publicCommentsChecked }))}
                onValueChanged={(value: string) => setOptions(Object.assign({}, options, { publicCommentsValue: value }))}
                radioButtons={[
                  { value: 'ALL', label: 'All' },
                  { value: 'RESOLVED', label: 'Resolved' },
                  { value: 'ACTIVE', label: 'Unresolved' }
                ]}
              />

              <h6>Revision Highlights</h6>

              <DiffExportRow
                id={'private-revision-highlights'}
                className={'export-row'}
                checked={options.privateRevisionHighlightsChecked!}
                name={'Private'}
                value={options.privateRevisionHighlightsValue!}
                valueName={'privateRevisionHighlightsValue'}
                onCheck={() =>
                  setOptions(
                    Object.assign({}, options, {
                      privateRevisionHighlightsChecked: !options.privateRevisionHighlightsChecked
                    })
                  )
                }
                onValueChanged={(value: string) => setOptions(Object.assign({}, options, { privateRevisionHighlightsValue: value }))}
                radioButtons={[
                  { value: 'ALL', label: 'All' },
                  { value: 'STARRED', label: 'Starred' },
                  { value: 'UNSTARRED', label: 'Unstarred' }
                ]}
              />

              <DiffExportRow
                id={'general-revision-highlights'}
                className={'export-row'}
                checked={options.generalRevisionHighlightsChecked!}
                name={'General'}
                value={options.generalRevisionHighlightsValue!}
                valueName={'generalRevisionHighlightsValue'}
                onCheck={() =>
                  setOptions(
                    Object.assign({}, options, {
                      generalRevisionHighlightsChecked: !options.generalRevisionHighlightsChecked
                    })
                  )
                }
                onValueChanged={(value: string) => setOptions(Object.assign({}, options, { generalRevisionHighlightsValue: value }))}
                radioButtons={[
                  { value: 'ALL', label: 'All' },
                  { value: 'STARRED', label: 'Starred' },
                  { value: 'UNSTARRED', label: 'Unstarred' }
                ]}
              />
            </div>
          ) : null}
        </div>

        <div className="error-msg"></div>
        <div className="buttons-section">
          <FlatButton className="cancel-button" label="Cancel" onClick={() => props.onClose()} />
          <FlatButton
            disabled={false}
            className="export-button"
            backgroundColor="#59b957"
            hoverColor="#438741"
            label="Export PDF"
            onClick={() => submitExport()}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DiffExportDialog;
