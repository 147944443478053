import * as React from 'react';
import { CircularProgress, FontIcon } from 'material-ui';

export type TreeElement = {
  uid: string;
  name: string;
  types?: string[];
  parent?: TreeElement;
  children?: TreeElement[];
};

export enum ExpandStatus {
  fullyExpanded = 'fully_expanded',
  partiallyExpanded = 'partially_expanded',
  fullyCollapsed = 'fully_collapsed'
}

interface Props {
  data: TreeElement[];
  expandStatus: ExpandStatus;
  onTreeExpandStatus: (status: ExpandStatus) => void;
  renderButtons: (element: TreeElement) => void;
  isActionEnabled: boolean;
  canPerformActionOnElement: (element: TreeElement) => boolean;
  filterTerm?: string;
  isLoading?: boolean;
  renderIcons?: (types: string[] | undefined) => JSX.Element;
}

const BASE_PADDING_LEFT = 40;
const BASE_PADDING_LEFT_WITH_CHILDREN = 15;

const TreeView = (props: Props) => {
  const [openedLookup, setOpenedLookup] = React.useState<Set<string>>(new Set());
  const [showLookup, setShowLookup] = React.useState<Set<string>>(new Set());

  React.useEffect(() => {
    const gatherUidsToShow = (elements: TreeElement[]): Array<string> => {
      let uids: Array<string> = [];

      elements.forEach((element: TreeElement) => {
        if (isElementMatchingFilter(element)) {
          uids.push(element.uid);

          if (element.children) {
            uids = uids.concat(gatherUids(element.children));
          }
        } else {
          let childrenUids: Array<string> | null = null;

          if (element.children) {
            childrenUids = gatherUidsToShow(element.children);
          }

          // show current tree element if any of the children need to be shown
          if (childrenUids && childrenUids.length > 0) {
            uids = uids.concat(childrenUids);
            uids.push(element.uid);
          }
        }
      });

      return uids;
    };

    if (isSearchOn()) {
      const uidsToShow: Array<string> = gatherUidsToShow(props.data);
      setOpenedLookup(new Set(uidsToShow));
      setShowLookup(new Set(uidsToShow));
    } else {
      // clean up
      setShowLookup(new Set());
      updateTreeLeaves();
    }
  }, [props.filterTerm, props.data]);

  React.useEffect(() => {
    updateTreeLeaves();
  }, [props.expandStatus, props.data]);

  function updateTreeLeaves() {
    if (props.expandStatus === ExpandStatus.fullyExpanded) {
      setOpenedLookup(new Set(gatherUids(props.data)));
    } else if (props.expandStatus === ExpandStatus.fullyCollapsed) {
      setOpenedLookup(new Set());
    }
  }

  function gatherUids(elements: TreeElement[]): Array<string> {
    let uids: Array<string> = [];

    elements.forEach((element: TreeElement) => {
      uids.push(element.uid);

      if (element.children) {
        uids = uids.concat(gatherUids(element.children));
      }
    });

    return uids;
  }

  function isElementMatchingFilter(element: TreeElement): boolean {
    return !!(props.filterTerm && element.name.toUpperCase().indexOf(props.filterTerm.toUpperCase()) > -1);
  }

  function toggleChildren(element: TreeElement) {
    if (!element.children) {
      return;
    } // no children no toggle
    if (openedLookup.has(element.uid)) {
      openedLookup.delete(element.uid);
      props.onTreeExpandStatus(ExpandStatus.partiallyExpanded);
    } else {
      openedLookup.add(element.uid);
      if ((isSearchOn() && showLookup.size === openedLookup.size) || props.data.length === openedLookup.size) {
        props.onTreeExpandStatus(ExpandStatus.fullyExpanded);
      }
    }
    setOpenedLookup(new Set(openedLookup.values()));
  }

  function isNodeOpened(element: TreeElement): boolean {
    return openedLookup.has(element.uid);
  }

  function countChildren(element: TreeElement): number {
    if (!element.children) {
      return 0;
    }

    const subChildrenCount: number = element.children.reduce((total: number, element: TreeElement) => {
      return total + countChildren(element);
    }, 0);
    return element.children.length + subChildrenCount;
  }

  function isSearchOn() {
    return !!props.filterTerm;
  }

  function renderTreeElement(element: TreeElement, level: number) {
    const basePaddingLeft: number = element.children?.length ? BASE_PADDING_LEFT_WITH_CHILDREN : BASE_PADDING_LEFT;

    const isElementNameNotMatchingFilter = !showLookup.has(element.uid);
    if (isSearchOn() && isElementNameNotMatchingFilter) {
      return undefined;
    }

    const isMatching: boolean = isElementMatchingFilter(element);
    const isActionable: boolean = props.canPerformActionOnElement(element);

    return (
      <div key={element.uid}>
        <div
          className={'tree-view-row ' + (isMatching ? 'tree-view-row--matched' : '')}
          style={{
            paddingLeft: basePaddingLeft + level * 30 + 'px',
            cursor: element.children?.length ? 'pointer' : 'inherit'
          }}
          onClick={() => toggleChildren(element)}
          data-qa-matched={isMatching ? 'true' : 'false'}
          data-qa-exportable={isActionable ? 'true' : 'false'}
        >
          <span style={{ display: 'inline-flex' }}>
            {element.children?.length && !isNodeOpened(element) && <FontIcon className="material-icons">chevron_right</FontIcon>}
            {element.children?.length && isNodeOpened(element) && <FontIcon className="material-icons">expand_more</FontIcon>}
          </span>

          {props.renderIcons && props.renderIcons(element.types)}

          <span className="tree-view-row__title" data-qa="tree-view-row__title">
            {element.name}
          </span>

          {isActionable && props.renderButtons(element)}

          <span className="tree-view-row__children-no">{element.children?.length && countChildren(element)}</span>
        </div>
        {element.children?.length && isNodeOpened(element) && element.children.map((element) => renderTreeElement(element, level + 1))}
      </div>
    );
  }

  return (
    <div className="tree-view">
      {props.isLoading ? (
        <div className="tree-view-empty">
          <CircularProgress size={40} className="loading" />
        </div>
      ) : (
        props.data.map((element) => {
          return renderTreeElement(element, 0);
        })
      )}
    </div>
  );
};

export default TreeView;
