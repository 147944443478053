import * as React from 'react';
import { WorkflowData } from './WorkflowActionModal';
import { dateUtil } from '../../../utils';

export type Props = {
  showOpenTasksWarning?: boolean;
  areOpenTasks: boolean;
  workflowName: string;
  data: Partial<WorkflowData>;
};

export type State = {};

export default class WorkflowActionHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    showOpenTasksWarning: false,
    data: {}
  };

  _getTitle() {
    if (this.props.data && this.props.data!.action!.type! === 'SUMMARY') {
      return 'Workflow Summary';
    } else {
      return this.props.workflowName;
    }
  }

  _getSubTitle() {
    if (this.props.data.activeStage) {
      return `${dateUtil(this.props.data.activeStage.startDate).formatDateTimeNoSecs(true)}`;
    } else if (this.props.data.stages) {
      return `${dateUtil(this.props.data.stages[0].startDate).formatDateTimeNoSecs(true)}`;
    }
  }

  _getWarningClass() {
    // Only for CLOSED_ONLY & ALLOW_OPEN_WITH_WARNING return proper warning class
    if (
      this.props.data.activeStage &&
      this.props.areOpenTasks &&
      ['CLOSED_ONLY', 'ALLOW_OPEN_WITH_WARNING'].indexOf(this.props.data.activeStage.changeTaskEnforcementMethod) > -1
    ) {
      return 'warning-message';
    }
  }

  _getChangeTasksWarning() {
    if (this.props.data.activeStage && this.props.areOpenTasks) {
      let msg = 'There are Open Tasks';
      if (this.props.data.activeStage.changeTaskEnforcementMethod === 'CLOSED_ONLY') {
        msg += ' - STOP';
      } else if (this.props.data.activeStage.changeTaskEnforcementMethod === 'ALLOW_OPEN_WITH_WARNING') {
        msg += ' - WARNING';
      }
      return msg;
    }
  }

  render() {
    return (
      <nav className="page-body-header icon-header workflow-header">
        <div className="nav-wrapper">
          <h1>
            <span>Workflow: </span>
            {this._getTitle()}
          </h1>
          <h1>
            <span>Started: </span>
            {this._getSubTitle()}
          </h1>
          <span className={this._getWarningClass()}>{this._getChangeTasksWarning()}</span>
        </div>
      </nav>
    );
  }
}
