import * as React from 'react';
import * as Dropzone from 'react-dropzone';
import BodyCSSClassUtil from '../../../../utils/BodyCSSClassUtil';
import MediaStore from '../../../../flux/editor/MediaStore';
import { Omit } from '../../../../types/type-fixes/helpers';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

type DropzoneProps = Omit<Dropzone.DropzoneProps, 'children'>;

const EVENT_NAME = 'event-dropzone-dragover';

export default function withMediaStoreSubscription(WrappedDropzone) {
  return class extends React.Component<Partial<DropzoneProps> & { className?: string }, {}> {
    constructor(props) {
      super(props);
    }

    private onDropFiles(accepted: File[], rejected: File[], event: DragEvent) {
      if (this.props.onDrop) {
        this.props.onDrop(accepted, rejected, event as any);
      }
      BodyCSSClassUtil.removeClass(EVENT_NAME);
      MediaStore.uploadFiles(accepted);
    }

    private onDragEnter(event: DragEvent) {
      if (this.props.onDragEnter) {
        this.props.onDragEnter(event as any);
      }
      BodyCSSClassUtil.addClass(EVENT_NAME);
    }
    private onDragLeave(event: DragEvent) {
      if (this.props.onDragLeave) {
        this.props.onDragLeave(event as any);
      }
      BodyCSSClassUtil.removeClass(EVENT_NAME);
    }

    private onDropRejectedFiles(acceptedOrRejected: File[], event: DragEvent) {
      if (this.props.onDropRejected) {
        this.props.onDropRejected(acceptedOrRejected, event as any);
      }
      BodyCSSClassUtil.removeClass(EVENT_NAME);
      MediaStore.onDropRejectedFiles(acceptedOrRejected);
    }

    render() {
      const { children, className } = this.props;
      return (
        <WrappedDropzone
          {...this.props}
          accept={`${ProjectDefinitionStore.getSupportedMediaTypesForIndex()}`}
          onDrop={(accepted, rejected, event) => this.onDropFiles(accepted, rejected, event)}
          onDragEnter={(event) => this.onDragEnter(event)}
          onDragLeave={(event) => this.onDragLeave(event)}
          onDropRejected={(acceptedOrRejected, event) => this.onDropRejectedFiles(acceptedOrRejected, event)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div className={className} {...getRootProps()}>
                <input {...getInputProps()} />
                {children}
              </div>
            );
          }}
        </WrappedDropzone>
      );
    }
  };
}
