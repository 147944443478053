import * as React from 'react';

interface Props {
  selected?: boolean;
  onSelect: () => void;
  label: string;
  icon: string;
  dataqa: string;
}

const MenuItem = (props: Props) => {
  return (
    <div
      onClick={() => props.onSelect()}
      className={`dashboard-left-panel-item ${props.selected ? 'dashboard-left-panel__selected' : ''}`}
      data-qa={props.dataqa}
    >
      <i className={`material-icons ${props.icon}`} />
      <span>{props.label}</span>
    </div>
  );
};

export default MenuItem;
