import * as React from 'react';
import { DataAttributePropsType } from '../DataAttributePropsType';

type PropsType = DataAttributePropsType & {
  type: 'label';
};

export const LabelAttribute = (props: PropsType) => {
  const { dataAttribute } = props;
  return (
    <div className="dimension column" style={{ height: 'auto' }}>
      <div>{dataAttribute.displayName}</div>
    </div>
  );
};
