import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../flux/editor/EditorStore';
import { replaceTemplateDataNidsWithNewOnes } from './TinyFacadeHelpers';

export function linkAnchorHelper() {
  function addLinkAnchor(title: string) {
    let editor = EditorStore.getEditor().getActiveEditorInstance();
    let elmProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getElementProfileByDefinitionId('LinkAnchor');
    let template: HTMLTemplateElement = document.createElement('template');
    if (elmProfile?.templateHtml) {
      template.innerHTML = elmProfile?.templateHtml;
    }
    let elm: Element = template.content.firstElementChild!;
    elm = replaceTemplateDataNidsWithNewOnes($(elm as HTMLElement))[0];
    elm?.setAttribute('data-permanent', 'false');
    elm?.setAttribute('data-title', title);
    elm?.setAttribute('data-type', 'GENERIC');
    editor?.selection.select(editor.selection.getEnd(), true);
    editor?.selection.collapse(false);
    editor?.selection.setNode(elm);
  }

  function editTitle(titleValue: any) {
    EditorStore.getEditor()._getCurrentEditingNode()?.setAttribute('data-title', titleValue);
  }

  function removeLinkAnchor() {
    let editor = EditorStore.getEditor().getActiveEditorInstance();
    let anchor = editor?.selection.getNode();
    let parent = anchor?.parentElement;
    editor?.selection.select(parent ?? editor.getBody());
    anchor?.remove();
  }

  return {
    addLinkAnchor,
    editTitle,
    removeLinkAnchor
  };
}
