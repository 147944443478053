import * as React from 'react';
import { transitionTo } from '../../utils/Navigation';
import { FlatButton } from 'material-ui';

const NoRecentDocuments = () => (
  <div>
    <div className="graphic">
      <img src={'/assets/images/dashboard_no_recent_docs.svg'} />
    </div>
    <div className="description">
      <div className="description__main-line">There are no recently opened</div>
      <div className="description__main-line">documents to display right now</div>
      <div className="description__sub-line">
        All your recent documents will be listed here, both personal docs and those within your teamspaces.
      </div>
      <div className="description__button">
        <FlatButton
          id="view-personal-docs"
          label="VIEW ALL DOCS"
          secondary={true}
          onClick={() => {
            transitionTo('/teamspaces/workspace');
          }}
        />
      </div>
    </div>
  </div>
);

export default NoRecentDocuments;
