import * as React from 'react';
import * as _ from 'lodash';
import LinkTypeSelector from './generic/LinkTypeSelector';
import LinkDisplayInput from './generic/LinkDisplayInput';
import ProjectStore from '../../../flux/editor/ProjectStore';
import TocSelector from '../../general/toc-selector/TocSelector';
import tocSelectorService, { IDisplayNameResponse } from '../../general/toc-selector/tocSelectorService';
import TextField from 'material-ui/TextField';
import { LinkModel } from './generic/linkService';
import { ITocNode, IUnitConceptMap } from 'mm-types';
import TocStore, { INCREASED_TOC_DEPTH } from '../../../flux/editor/TocStore';
import { getUnitMap, IConceptMap } from '../../../clients/concepts';
import { isSuccess } from '../../../clients/base-clients';
import { EditorRouteParams } from '../../ReactRoutes';
import { RouteComponentProps } from 'react-router';
import DuRefLinkOptions from './generic/DuRefLinkOptions';
import CrossReferenceInputs from './generic/CrossReferenceInputs';
import withRouterForwardRef from '../../hoc/withRouterForwardRef';

export interface Props extends RouteComponentProps<EditorRouteParams> {
  model: Partial<LinkModel>;
  showValidation: boolean;
  onUpdateModel: (partial: Partial<LinkModel>) => void;
  onFetching(isFetching: boolean);
  selectedVariantsMap: IUnitConceptMap;
  isHotspot?: boolean;
  isDuRefLink?: boolean;
}

export type State = {
  tocs: ITocNode[];
  tocsLoading: boolean;
  linkHasVariant: boolean;
  variantTags?: IConceptMap[];
};

export class InternalCrossReferenceComponent extends React.Component<Props, State> {
  inputRef: React.RefObject<LinkDisplayInput>;
  constructor(props: Props) {
    super(props);
    this.state = {
      tocs: [],
      tocsLoading: false,
      linkHasVariant: false
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this._fetchTocs();
    if (this.props.isDuRefLink) {
      // if we have text already set to managed or set to unmanaged
      this.props.onUpdateModel({ managed: this.props.model.text === '' });
    }
  }

  _fetchTocs() {
    this.props.onFetching(true);
    this.setState({ tocsLoading: true }, async () => {
      try {
        let { children, appendices } = await TocStore.retrieveToc({
          projectUid: this.props.match.params.projectUid,
          documentIndexUid: this.props.match.params.documentIndexUid,
          depth: INCREASED_TOC_DEPTH,
          includeParagraphs: true,
          includeCaptionUnits: true
        });

        const unitVariantMaps = await getUnitMap(this.props.match.params.documentIndexUid);
        this.props.onFetching(false);
        if (this.props.isDuRefLink) {
          children = children.map((child) => this.removeNonTocables(child)) as ITocNode[];
        }
        this.setState({
          tocsLoading: false,
          variantTags: unitVariantMaps.conceptTags,
          tocs: _.flatten([children, appendices])
        });
      } catch (err) {
        this.setState({
          tocsLoading: false
        });
        this.props.onFetching(false);
      }
    });
  }

  removeNonTocables(toc: ITocNode): ITocNode | undefined {
    if (toc?.type === 'tocable') {
      toc.children = toc.children.map((child) => this.removeNonTocables(child)).filter((t) => !!t) as ITocNode[];
      return toc;
    } else {
      return undefined;
    }
  }

  isValid() {
    return (
      (this.props.isHotspot ||
        this.props.model.managed ||
        (this.inputRef.current && this.inputRef.current.isValid()) ||
        this.props.isDuRefLink) &&
      this.props.model.elementUid
    );
  }

  _sectionSelect(node: ITocNode, hasVariantsApplied: boolean) {
    this.props.onFetching(true);
    const projectUid = ProjectStore.getProject()?.uid;
    let displayName = '';

    if (!!projectUid) {
      tocSelectorService
        .displayName(projectUid, ProjectStore.getCurrentRevisionUid(), node, true)
        .then((response) => {
          if (isSuccess<IDisplayNameResponse>(response)) {
            displayName = response.text;
            this.setState({
              linkHasVariant: hasVariantsApplied
            });
          }
        })
        .finally(() => {
          this.props.onFetching(false);
          this.props.onUpdateModel({
            elementUid: node.uid,
            elementNid: node.elementNid,
            unitType: node?.type,
            elementName: displayName
          });
        });
    }
  }

  linkDisplayErrorText() {
    if (this.props.showValidation && !this.props.model.elementUid) {
      return 'Must select a document element';
    }
  }

  readOnly() {
    return false;
  }

  toggleManaged(e: React.MouseEvent<HTMLElement>) {
    this.props.onUpdateModel({ managed: !this.props.model.managed });
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const title: string = this.props.isHotspot
      ? 'Select link type and link destination'
      : this.props.isDuRefLink
      ? 'Select link role and link destination'
      : 'Select link type and link display text';
    return (
      <div className="link-modal-body-doc">
        <div className="link-modal-col-1">
          <h3>{title}</h3>
          <div className="input">
            <LinkTypeSelector
              value={this.props.model?.type}
              onChange={(type) => this.props.onUpdateModel({ type: type })}
              isDuRefLink={!!this.props.isDuRefLink}
            />
          </div>

          {!this.props.isHotspot && !this.props.isDuRefLink && (
            <CrossReferenceInputs
              model={this.props.model}
              readOnly={this.readOnly}
              showValidation={this.props.showValidation}
              onUpdateModel={(partial) => this.props.onUpdateModel(partial)}
              toggleManaged={(e) => this.toggleManaged(e)}
              isHotspot={this.props.isHotspot}
              refs={this.inputRef}
            />
          )}

          {this.props.isDuRefLink && (
            <DuRefLinkOptions model={this.props.model} onUpdateModel={(partial) => this.props.onUpdateModel(partial)} />
          )}
          <div className="input">
            <TextField
              value={this.props.model.elementName}
              disabled={true}
              multiLine={true}
              errorText={this.linkDisplayErrorText()}
              floatingLabelText="Link Destination"
              id="link-destination-input"
            />
            <div className="info">
              {this.state.linkHasVariant ? <i className="material-icons icon-document-info" /> : null}
              {this.state.linkHasVariant ? ' Inserted link will not display ordinals when target has variants' : ''}
            </div>
          </div>
        </div>

        <div className="link-modal-col-2">
          <h3>Select Link</h3>
          <TocSelector
            variantTags={this.state.variantTags}
            ref="tocSectionPicker"
            allowVariantSelection={true}
            variantsMustMatch={true}
            selectedVariantsMap={this.props.selectedVariantsMap}
            tocs={this.state.tocs}
            tocsLoading={this.state.tocsLoading}
            onSelect={(element, hasVariantsApplied) => this._sectionSelect(element, hasVariantsApplied)}
            selectedUid={this.props.model.elementUid + (this.props.model.elementNid || '')}
            documentIndexUid={this.props.match.params.documentIndexUid}
            isDuRefLink={this.props.isDuRefLink}
          />
        </div>
      </div>
    );
  }
}

export default withRouterForwardRef(InternalCrossReferenceComponent);
