import * as React from 'react';
import { NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { useObjectValue } from '../useObjectValues';
import { NNCMetadataComponentProps } from './MetadataComponentTypes';
import { RadioButton, RadioButtonGroup, RadioButtonProps } from 'material-ui';
import keyDown from '../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
type Props = NNCMetadataComponentProps<NNCIndexMetadataID.SYMBOL>;

const SubSectionRadioButtonStyle: Partial<RadioButtonProps> = {
  style: { fontSize: 12, color: '#727272 !important' },
  labelStyle: { width: 'calc(100% - 45px)' }
};

const options = {
  noValue: 'null',
  bracket: '[]',
  greaterThan: '>'
};

export function SymbolMetadata({ values, onChange, onTab, currentRef }: Props) {
  const { localValues, updateValues } = useObjectValue<Props['values']>(values, onChange);
  function updateSymbol(value: Props['values']['value']) {
    updateValues({
      value
    });
  }

  return (
    <ul className="position-relative margin-top-m">
      <li data-qa="symbol" onKeyDown={(e) => keyDown(e, onTab, currentRef)}>
        <RadioButtonGroup
          name="symbol"
          labelPosition="left"
          defaultSelected={localValues.value || 'null'}
          onChange={(e, value) => updateSymbol(value as Props['values']['value'])}
          ref={currentRef}
        >
          <RadioButton
            {...SubSectionRadioButtonStyle}
            value={options.noValue}
            label="No Symbol"
            className="margin-bottom-m font-gray-label"
            data-qa="nnc-metadata-symbol-index-no-symbol"
          />
          <RadioButton
            {...SubSectionRadioButtonStyle}
            value={options.bracket}
            label={options.bracket}
            className="margin-bottom-m font-gray-label"
          />
          <RadioButton
            {...SubSectionRadioButtonStyle}
            value={options.greaterThan}
            label={options.greaterThan}
            className="font-gray-label"
          />
        </RadioButtonGroup>
      </li>
    </ul>
  );
}
