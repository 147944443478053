import * as React from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'react-fast-compare';
import EditorModes from '../../../flux/editor/EditorModes';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../flux/editor/EditorStore';
import CommentStore, { CommentStoreEvent } from '../../../flux/editor/CommentStore';
import {
  DocUnitComplianceTagMenu,
  DocUnitFacetTag,
  DocUnitSpecialInterestGroupTagMenu,
  DocUnitTaskTagMenu,
  DocUnitVariantTag,
  EditingUser,
  PageBreakSettings,
  PrintOutputMenu
} from './components';
import { EventStoreEventType, IDocUnitProfile, IEditorStoreEvent, IPageBreakSettingsData, IPrintOutputSettingsData, IUnit } from 'mm-types';
import { propsAreEqual } from '../../../utils/prop-compare';
import { UnitTypes } from '../utils/units/UnitTypes';
import { classNameHelper } from '../../../utils';
import { UnitElementFocusUtil } from '../utils/tinyFacade/UnitElementFocusUtil';
import RevBarContainer from '../revisionbars/RevBarContainer';
import { DocUnitHTML } from './components/DocUnitHTML';
import { DocUnitHoverMenu } from './components/DocUnitHoverMenu';
import { ElementPrintLabels } from '../elementPrintOutput/ElementPrintLabels';
import MediaStore, { MediaStoreEvent, MediaStoreEventType } from '../../../flux/editor/MediaStore';
import { MediaInsertUtils } from '../medialib/utils/mediaInsertUtils';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import FileIcon from '../../misc/icons/FileIcon';
import { getTypeFromMimeType } from '../../../utils/FileIcon';
import DocUnitShareDetails, { DocUnitShareDetailsPosition } from '../docUnitShareDetails/DocUnitShareDetails';
import { DocUnitUtils } from './utils/docUnitUtils';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';

export type ActionEvent = {
  data: { insertType?: UnitTypes; unit: IUnit | INestedUnitFocusChangeEvent };
  action: 'insert-docunit' | 'delete-docunit' | 'copy-docunit' | 'paste-docunit' | 'cut-docunit';
  src?: string;
};

export type PermissionsOverride = {
  isUnitInsertableAfter: boolean;
  isUnitEditable: boolean;
  isUnitCommentable: boolean;
  isUnitDeletable: boolean;
  isChangeTasksVisible: boolean;
  deletionAllowed: boolean;
  isShareActionAllowed?: boolean;
};

export type DocUnitProps = {
  unit: IUnit;
  selected?: boolean;
  primary?: boolean;
  forceInefficientUpdate?: boolean;
  isDocReadOnly?: boolean;
  onAction: (e: ActionEvent) => void;
  onClicked?: (unit: IUnit, selected: boolean, e: { isCtrlClick: boolean; isShiftClick: boolean }) => void;
  onFocused?: (unit: IUnit, focusElementUid?: string | null) => void;
  readOnlyOverride?: boolean | null;
  permissionsOverride?: Partial<PermissionsOverride> | null;
  facetedTagsCount?: { [key: string]: number };
  infoIcon?: { type: string; onClicked: () => void };
  isStyleLoaded?: boolean;
  diffUnitsUids: string[] | undefined;
  showRevBars: boolean;
  openLinkModal: (isDuRef: boolean) => void;
  openLinkAnchorModal: () => void;
};

export type State = {
  commentCount: number;
  hovered: boolean;
};

export default class DocUnit extends React.Component<DocUnitProps, State> {
  private _forceNextUpdate: boolean;
  private _unitProfile: IDocUnitProfile | null;
  private _commentUnsubscribe: Function;
  private _mediaUnsubscribe: Function;
  private _editorStoreUnsubscribe: Function;
  private docUnitHtmlRef: React.RefObject<HTMLDivElement>;
  constructor(props: DocUnitProps) {
    super(props);
    this._unitProfile = null;
    this._forceNextUpdate = false;
    this.docUnitHtmlRef = React.createRef();
    this.state = {
      commentCount: this._getCommentCount(),
      hovered: false
    };
    this._handleAction = this._handleAction.bind(this);
    this._handleCommentClick = this._handleCommentClick.bind(this);
    this._handleUnitClick = this._handleUnitClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    if (props?.unit?.definitionId) {
      this._unitProfile =
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(props.unit.definitionId, props.unit) ??
        null;
    }
  }

  static defaultProps: Partial<DocUnitProps> = {
    selected: false,
    primary: false,
    forceInefficientUpdate: false,
    permissionsOverride: null,
    readOnlyOverride: null,
    isDocReadOnly: false,
    facetedTagsCount: {},
    showRevBars: true
  };

  componentDidMount() {
    this._commentUnsubscribe = CommentStore.listen(this._onStoreChange, this);
    this._mediaUnsubscribe = MediaStore.listen(this._onMediaStoreEvent, this);
    this._editorStoreUnsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
    this.refreshMediaAttachmentIcon();
    this.docUnitHtmlRef.current && MediaInsertUtils.checkVideoFormat(this.docUnitHtmlRef.current);
    DocUnitUtils.updateEcamSubtitle(this.docUnitHtmlRef.current);
  }

  componentDidUpdate(prevProps: Readonly<DocUnitProps>, prevState: Readonly<State>): void {
    // some property changes require a permission change, allow this through, and then allow any subsequent state change through
    if (prevProps.isDocReadOnly !== this.props.isDocReadOnly || !propsAreEqual(prevProps.unit.shareDetails, this.props.unit.shareDetails)) {
      this.allowInefficientUpdate();
    }
    this.refreshMediaAttachmentIcon();
    DocUnitUtils.updateEcamSubtitle(this.docUnitHtmlRef.current);
  }

  componentWillUnmount() {
    this._commentUnsubscribe();
    this._mediaUnsubscribe();
    this._editorStoreUnsubscribe();
  }

  allowInefficientUpdate() {
    this._forceNextUpdate = true;
  }

  shouldComponentUpdate(nextProps: DocUnitProps, nextState: State) {
    if (this.state.hovered !== nextState.hovered) {
      return true;
    }

    if (this.props.isStyleLoaded !== nextProps.isStyleLoaded) {
      return true;
    }

    if (this.props.showRevBars !== nextProps.showRevBars) {
      return true;
    }

    if (nextProps.unit.definitionId !== this.props.unit.definitionId) {
      this._unitProfile =
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(nextProps.unit.definitionId) ?? null;
      return true;
    }

    if (this.state.commentCount !== nextState.commentCount) {
      return true;
    }

    if (this.props.forceInefficientUpdate || this._forceNextUpdate) {
      this._forceNextUpdate = false;
      return true;
    }

    if (!isEqual(this.props.facetedTagsCount, nextProps.facetedTagsCount)) {
      return true;
    }

    if (!isEqual(this.props.unit.highlight, nextProps.unit.highlight)) {
      return true;
    }

    // SUPER IMPORTANT:
    // 1. if this unit changes *while already in selected state*, then the only other means of change are because its contents have changed
    // We don't want this change reflected in the UX as WE MAY STILL BE EDITING a unit, and it will replace the TinyMCE instantiated DOM
    // i.e. if we allow a change, react will render changed HTML, overwriting TINYMCE DOM
    // therefore we *never* change this component when its selected, TinyMCE and EditorInstanceManager is responsible from this point
    // for internal data management
    // 2. performance is key where there is a lot of DocUnits, so only update this component when really necessary
    // below achieves both of these steps...
    // 3. we can explicitly drop this efficiency using _forceNextUpdate (allowInefficientUpdate()) for certain operations when editing isn't occurring
    // 4. Is this really worth the hassle? Yes:  performance gets really bad when u cursor up and down as it attempts to modify selected state of all items

    if (
      this.props.selected === nextProps.selected &&
      this.props.primary === nextProps.primary &&
      !(this._unitProfile && this._unitProfile.allowInefficientUpdateWhileSelected)
    ) {
      return false;
    } else {
      return true;
    }
  }

  refreshMediaAttachmentIcon() {
    const elements: HTMLElement[] = Array.from(document.querySelectorAll('.arc-attachment')) as HTMLElement[];
    elements.map((element: HTMLElement) => {
      if (element.hasAttribute('data-type') && element.children.length <= 0) {
        ReactDOM.render(
          <MuiThemeProvider>
            <FileIcon fileType={getTypeFromMimeType(undefined, element.getAttribute('data-type') ?? '')} />
          </MuiThemeProvider>,
          element
        );
      }
    });
  }

  _onMediaStoreEvent(e: MediaStoreEvent<MediaStoreEventType>) {
    if (e.type === 'refreshMediaAttachmentIcon') {
      this.refreshMediaAttachmentIcon();
    }
  }

  _onStoreChange(e: CommentStoreEvent) {
    if (e.type === 'unitCommentMap') {
      this.setState({ commentCount: this._getCommentCount() });
    }
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'nestedUnitFocusChange') {
      DocUnitUtils.updateEcamSubtitle(this.docUnitHtmlRef.current);
    }
  }

  _getClassNames(printOutputSettings: (IPrintOutputSettingsData & IPageBreakSettingsData) | null) {
    const isDocReadOnly = this.props.readOnlyOverride !== null ? this.props.readOnlyOverride : this.props.isDocReadOnly;

    const unit = this.props.unit;
    let isShareUnitReadonly = false;
    let sharedClassName = '';
    if (unit.shareDetails && unit.isVisibleOnEdit) {
      // i.e. a share that has not been removed

      sharedClassName = ' unit-shared' + (unit.shareDetails.origin ? ' unit-shared-origin' : ' unit-shared-usage');

      if (unit.shareDetails.origin) {
        const isSelectedShareInShareEditMode =
          EditorStore.isMode('SHARE_EDIT') &&
          unit.shareDetails.sharedIndexUid === EditorModes.getProperties('SHARE_EDIT').getParams().shareUid;
        if (isSelectedShareInShareEditMode || unit.shareDetails.originDiffersFromSharedIndex || unit.shareDetails.uid === null) {
          sharedClassName += ' unit-shared-origin-unpushed';
        } else if (unit.shareDetails.originDiffersFromLastPublished) {
          // same as lib but last updated in an unpublished doc
          sharedClassName += ' unit-shared-origin-pushed';
        }
      } else {
        // usage
        const updatedSharedUsage = DocUnitUtils.getUpdatedSharedUsage(unit);
        if (updatedSharedUsage) {
          if (updatedSharedUsage.updateAvailable && !updatedSharedUsage.updateDeferred) {
            sharedClassName += ' unit-shared-usage-outdatedmode';
          } else if (updatedSharedUsage.updateDeferred) {
            sharedClassName += ' unit-shared-usage-deferredmode';
          }
        }
      }

      sharedClassName += unit.shareDetails.isShareStartUnit ? ' unit-shared-start' : '';
      sharedClassName += unit.shareDetails.isShareEndUnit ? ' unit-shared-end' : '';

      isShareUnitReadonly = !unit.shareDetails.origin;
    }

    return classNameHelper.merge(`document-unit-outer document-unit-outer-${unit.level ?? unit.type} ${sharedClassName}`, {
      selected: !!this.props.selected,
      primary: !!this.props.primary,
      'reg-selected': !!this.props.selected && EditorStore.isMode('REGULATIONSELECTION'),
      'readonly-document': !!isDocReadOnly,
      'display-none': this.props.isStyleLoaded === false,
      hasComment: !!this.state.commentCount,
      hasBreak: !!printOutputSettings?.breakBefore,
      hasPage: !!printOutputSettings && (printOutputSettings.pageSize !== 'unspecified' || printOutputSettings.pageType !== 'unspecified'),
      'readonly-unit': isShareUnitReadonly || !this._unitProfile || !!this._unitProfile?.inlineOptions?.readonly
    });
  }

  _isReadonlyUnit() {
    const isDiffModeOn = this.props.unit.type === 'diff' || this.props.unit.definitionId === 'diff';
    return !this._unitProfile || this._unitProfile.inlineOptions?.readonly || isDiffModeOn;
  }

  _getCommentCount() {
    const commentUnitMap = CommentStore.getInitialState().unitCommentMap;
    let unitComments = commentUnitMap[this.props.unit.uid];
    unitComments = unitComments ? unitComments : [];

    return (commentUnitMap ? unitComments : []).length;
  }

  _handleMenuSelect(type) {
    this.props.onAction({ data: { insertType: type, unit: this.props.unit }, action: 'insert-docunit' });
  }

  _handleAction(e) {
    const action = $(e.target).data('action');
    if (this.props.onAction) {
      this.props.onAction({ data: { unit: this.props.unit }, action: action, src: 'inlineMenu' });
    }
  }

  _handleUnitClick(e: React.MouseEvent<HTMLDivElement>) {
    e.persist();
    const target = e.target as HTMLElement;
    const targetNid = UnitElementFocusUtil.getElementNid(target);
    e.stopPropagation();
    e.preventDefault();

    // handle link click
    if (target.classList.contains('arc-anchor') && EditorStore.isEditorFocused() && DocUnitUtils.getTargetNidInSelectedUnit(targetNid)) {
      EditorStore.getEditor().selectEditorNode(target);
      this.props.openLinkAnchorModal();
    }

    // handle ref int click
    if (target.classList.contains('arc-refint') && EditorStore.isEditorFocused() && DocUnitUtils.getTargetNidInSelectedUnit(targetNid)) {
      EditorStore.triggerOpenRefIntModal();
    } else if (DocUnitUtils.canTriggerMediaLibModalOnElmDoubleClick(target, targetNid, e)) {
      DocUnitUtils.triggerOpenMediaLibModal(target);
    } else if (target.tagName === 'A' || target.parentElement?.tagName === 'A') {
      // handle internal unit link clicks
      const $linkNode = $(target.tagName === 'A' ? target : target.parentElement!),
        targetData = $linkNode.data();

      // frontmatter link click
      if (
        targetData.targetType === 'internal' &&
        (this.props.unit.type === 'frontmatter' || this.props.unit.type === 'chapter-frontmatter')
      ) {
        EditorStore.openDocumentWithTargets(targetData as any);
      }
      // user generated doc link
      else if ($linkNode.hasClass('arc-link')) {
        if (EditorStore.getEditor().isFocused() && DocUnitUtils.getTargetNidInSelectedUnit(targetNid)) {
          // open menu
          this.props.openLinkModal(targetData.subtype == 'duRef');
        } else if (!EditorStore.getEditor().isFocused()) {
          DocUnitUtils.handleArcLink($linkNode);
        }
      } else {
        this._triggerClickOrFocus(e, targetNid);
      }
    } else {
      this._triggerClickOrFocus(e, targetNid);
    }
  }

  _triggerClickOrFocus(e: React.MouseEvent<HTMLDivElement>, focusElementNid?: string | null) {
    const isCtrlClick = navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey;
    const isShiftClick = e.shiftKey;

    if (this.props.selected && !isCtrlClick && !isShiftClick) {
      if (this.props.onFocused) {
        this.props.onFocused(this.props.unit, focusElementNid);
      }
    } else {
      if (this.props.onClicked) {
        this.props.onClicked(this.props.unit, this.props.selected!, {
          isCtrlClick: isCtrlClick,
          isShiftClick: isShiftClick
        });
      }
    }
  }

  _handleCommentClick(e) {
    const isCtrlClick = navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey;
    const isShiftClick = e.shiftKey;

    // trigger a selection on comment click
    if (this.props.onClicked && !this.props.selected) {
      this.props.onClicked(this.props.unit, this.props.selected!, {
        isCtrlClick: isCtrlClick,
        isShiftClick: isShiftClick
      });
    }
  }

  onMouseEnter() {
    if (!this.state.hovered) {
      this.setState({
        hovered: true
      });
    }
  }
  onMouseLeave(e: React.MouseEvent<HTMLDivElement>) {
    const inlineMenu = (e.target as HTMLDivElement).closest('.du-inline-menu');

    if (
      !inlineMenu ||
      (inlineMenu.previousElementSibling && inlineMenu.previousElementSibling.getAttribute('id') !== `_${this.props.unit.uid}`)
    ) {
      this.setState({
        hovered: false
      });
    }
  }

  render() {
    const inDiffMode = EditorStore.getMode() === 'DIFF';
    const hovered = this.state.hovered;
    const isReadonlyUnit = this._isReadonlyUnit();

    const permissions = DocUnitUtils.getUnitPermissions(this.props);
    let printOutputSettings: (IPrintOutputSettingsData & IPageBreakSettingsData) | null = null;

    if (!isReadonlyUnit) {
      const unit = EditorStore.getDocUnitModel(this.props.unit.uid);
      printOutputSettings = unit ? unit.getPrintOutputSettings() : null;
    }

    return (
      <div
        className={this._getClassNames(printOutputSettings)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseEnter}
      >
        {this.props.showRevBars && this.props.unit.highlight && (
          <RevBarContainer unit={this.props.unit} highlight={this.props.unit.highlight} diffUnitsUids={this.props.diffUnitsUids} />
        )}
        <DocUnitFacetTag
          type={this.props.unit.type}
          isReadonly={isReadonlyUnit}
          label={this._unitProfile?.getLabel?.(this.props.unit) ?? null}
          inDiffMode={inDiffMode}
          activeTagCount={this.props.facetedTagsCount?.activeTagCount ?? 0}
          inActiveTagCount={this.props.facetedTagsCount?.inActiveTagCount ?? 0}
        />
        <DocUnitShareDetails unit={this.props.unit} position={DocUnitShareDetailsPosition.TOP} />
        <EditingUser unit={this.props.unit} />
        {!isReadonlyUnit && printOutputSettings && printOutputSettings.breakBefore && printOutputSettings.breakBefore != 'unspecified' ? (
          <PageBreakSettings
            type="before"
            side={printOutputSettings.breakBefore !== 'always' ? printOutputSettings.breakBefore : ''}
            pageBreakText="Page Break"
          />
        ) : undefined}
        <DocUnitHTML ref={this.docUnitHtmlRef} unit={this.props.unit} onClick={this._handleUnitClick} />
        <ElementPrintLabels unit={this.props.unit} />
        {!isReadonlyUnit && printOutputSettings && printOutputSettings.breakAfter && printOutputSettings.breakAfter != 'unspecified' ? (
          <PageBreakSettings type="after" pageBreakText="Keep With Next" />
        ) : undefined}
        {!isReadonlyUnit && (
          <div className="du-inline-menu" onClick={this._handleAction}>
            <PrintOutputMenu printOutputSettings={printOutputSettings} />
            <DocUnitVariantTag unit={this.props.unit} />
            <DocUnitSpecialInterestGroupTagMenu unit={this.props.unit} />
            <DocUnitComplianceTagMenu unit={this.props.unit} />
            {hovered && (
              <DocUnitHoverMenu
                permissions={permissions}
                inDiffMode={inDiffMode}
                unit={this.props.unit}
                commentCount={this.state.commentCount}
                handleCommentClick={this._handleCommentClick}
                facetedTagsCount={this.props.facetedTagsCount}
              />
            )}
            {!hovered && permissions.isChangeTasksVisible && <DocUnitTaskTagMenu readOnly={true} unit={this.props.unit} />}
          </div>
        )}
        <DocUnitShareDetails
          unit={this.props.unit}
          permissionsOverride={this.props.permissionsOverride}
          position={DocUnitShareDetailsPosition.BOTTOM}
        />
      </div>
    );
  }
}
