import React from 'react';
import { FontIcon } from 'material-ui';

export type WorkflowTasksStageProps = {
  workspaceType: string;
  isPublished: boolean;
  revision: string;
  currentStageName: string | null;
};

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  wrapper: {},
  container: {
    borderRadius: '33px',
    border: 'solid 1px #28ace3',
    padding: '0.2rem 0.8rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center'
  },
  text: {
    marginLeft: '0.8rem',
    fontSize: '1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export function getWorkflowTasksStageDetails(
  workspaceType: string,
  isPublished: boolean,
  revision: string,
  currentStageName: string | null
) {
  let icon: JSX.Element | null = null;
  let documentStage: string | null = null;

  if (workspaceType.toLowerCase() !== 'team') {
    icon = (
      <FontIcon style={defaultStyles.icon} className="material-icons icon">
        person
      </FontIcon>
    );
    documentStage = 'Personal';
  } else if (isPublished) {
    icon = (
      <i style={defaultStyles.icon} className="material-icons icon">
        send
      </i>
    );
    documentStage = 'Published: Rev ' + revision;
  } else {
    icon = <span style={defaultStyles.icon} className="icon icon-workflow material-icons" />;
    documentStage = currentStageName || 'Author / Review';
  }
  return { icon, documentStage };
}

const WorkflowTasksStage = ({ workspaceType, isPublished, revision, currentStageName }: WorkflowTasksStageProps) => {
  const { icon, documentStage } = getWorkflowTasksStageDetails(workspaceType, isPublished, revision, currentStageName);

  return (
    <div style={defaultStyles.container}>
      {icon}
      <span style={defaultStyles.text}>{documentStage}</span>
    </div>
  );
};

export default WorkflowTasksStage;
