import * as React from 'react';

import { Menu, MenuItem, Divider, FontIcon } from 'material-ui';
import { DateTimeInput, DateTimeInputType } from '../../../general/DateTimeInput';
import moment from 'moment';
import * as _ from 'lodash';

import EditorStore from '../../../../flux/editor/EditorStore';
import UnitConceptStore from '../../../../flux/editor/UnitConceptStore';
import { IRevision, IIndex, IProject } from 'mm-types';
import { getUnitMap } from '../../../../clients/concepts';
import { DisabledPDFWarningIcon } from './DisabledPDFWarningIcon';
import { FileExportType } from '../../../../flux/editor/EditorModes';
import ServerSettingsStore from '../../../../flux/common/ServerSettingsStore';
import { dateUtil } from '../../../../utils';
import CloseModeBar from './CloseModeBar';

export type Props = {
  onClose: () => void;
  onDiff: (e: { revision: IIndex | IRevision | null; date: moment.Moment | Date | null }) => void;
  className?: string;
  revisions: IRevision[];
  currentIndex?: IIndex | IRevision | null;
  currentProject: IProject;
  defaultDiffRevision: IRevision | null;
};

export type State = {
  title: string;
  exportToPDF: {
    disabled: boolean;
    reason?: string;
  };
  selected: {
    revision: null | IRevision | IIndex;
    date: Date | null;
  };
};

const EXPORT_TO_PDF_NOT_SUPPORTED_VARIANT_FAMILY_MSG =
  'Export to PDF is not supported where either revision has a variant family assigned.';
const EXPORT_TO_PDF_NOT_SUPPORTED_DATE_MSG = 'Export to PDF is not supported where a point in time has been selected.';

export default class DiffModeBar extends React.Component<Props, State> {
  private dateTimeInputRef;
  constructor(props: Props) {
    super(props);
    this.state = {
      title: '',
      exportToPDF: {
        disabled: false
      },
      selected: {
        revision: null,
        date: null
      }
    };
    this.dateTimeInputRef = React.createRef<DateTimeInputType>();
  }

  static defaultProps: Partial<Props> = {
    revisions: [],
    currentIndex: null,
    defaultDiffRevision: null
  };

  componentDidMount() {
    EditorStore.docBusy();
    this.setState(
      {
        selected: {
          revision: this.props.defaultDiffRevision,
          date: null
        }
      },
      () => {
        this.revisionSelected(this.props.defaultDiffRevision);
      }
    );

    setTimeout(() => {
      if (this._isNoPublishedRevisions()) {
        this.dateTimeInputRef.current?.openDatePicker();
      }
    }, 500);
  }

  _onRevisionChange(revision: IRevision | IIndex) {
    EditorStore.docBusy();
    const diffParams = { revision: revision, date: null };
    this.setState(
      {
        selected: diffParams
      },
      () => {
        this.props.onDiff(diffParams);
        if (this.state.selected.revision) {
          this.revisionSelected(revision);
        }
      }
    );
    setTimeout(() => this.dateTimeInputRef.current?.handleRequestClose(), 1);
  }

  _isRevisionSelected(revision: IRevision | IIndex) {
    if (this.state.selected.revision) {
      const uid = (this.state.selected.revision as IRevision).indexUid
        ? (this.state.selected.revision as IRevision).indexUid
        : this.state.selected.revision.uid;
      const revUid = (revision as IRevision).indexUid ? (revision as IRevision).indexUid : revision.uid;
      return uid === revUid;
    }
    return false;
  }

  _getRevisionMenuItems() {
    const style = { fontSize: '12px', lineHeight: '30px', minHeight: '30px', minWidth: '180px' };
    const iconStyle = { fontSize: '12px', margin: '7px' };
    const tickIcon = (
      <FontIcon className="material-icons" style={iconStyle}>
        done
      </FontIcon>
    );

    let defaultRevisionFound = false;
    let currentAEFFound = false;
    let options: ((IRevision | IIndex) & { defaultRevisionFound?: boolean; currentAEFFound?: boolean })[] = [...this.props.revisions];

    options.forEach((item) => {
      if (this.props.defaultDiffRevision) {
        defaultRevisionFound = !defaultRevisionFound
          ? this.props.defaultDiffRevision.indexUid === (item as IRevision).indexUid || this.props.defaultDiffRevision.indexUid === item.uid
          : defaultRevisionFound;
        currentAEFFound =
          this.props.currentIndex !== null && this.props.currentIndex !== undefined && this.props.currentIndex.uid === item.uid;
      } else {
        defaultRevisionFound = true;
      }
      item.defaultRevisionFound = defaultRevisionFound;
      item.currentAEFFound = currentAEFFound;
    });

    // sort chronologically descending
    options = options.sort((a, b) => {
      if (typeof a.revisionDate === 'string' && typeof b.revisionDate === 'string') {
        const aD = new Date(a.revisionDate);
        const bD = new Date(b.revisionDate);
        return aD > bD ? -1 : aD < bD ? 1 : 0;
      }
      return 0;
    });

    return options.map((item) => {
      const rightIcon: JSX.Element | undefined = this._isRevisionSelected(item) ? tickIcon : undefined;
      return (
        <MenuItem
          key={(item as IRevision).indexUid ? (item as IRevision).indexUid : item.uid}
          primaryText={this._getText(item)}
          disabled={!item.defaultRevisionFound || item.currentAEFFound}
          style={style}
          onClick={() => this._onRevisionChange(item)}
          rightIcon={rightIcon}
        />
      );
    });
  }

  _getText(item: IRevision | IIndex) {
    let text = '';
    if (item.hasOwnProperty('revision')) {
      text = 'Revision ' + (item as IRevision).revision;
    }
    return text;
  }

  _getCurrentRevisionName() {
    if (this.props.currentIndex) {
      if (this.props.currentIndex.hasOwnProperty('isMasterDraft') && (this.props.currentIndex as IIndex).isMasterDraft) {
        return 'Current Draft';
      } else {
        return 'Revision ' + (this.props.currentIndex as IIndex).displayName;
      }
    }
  }

  _getSelectedLabel() {
    if (this.state.selected.revision) {
      return this._getText(this.state.selected.revision);
    } else if (this.state.selected.date) {
      return dateUtil(this.state.selected.date).formatDateTimeNoSecs();
    } else {
      return this._isNoPublishedRevisions() ? 'No Revisions' : 'None';
    }
  }

  _isNoPublishedRevisions() {
    return !this.props.revisions || this.props.revisions.length === 0;
  }

  _onChangeDP = (date: Date) => {
    this._updateDiffParams({ revision: null, date: date });
    setTimeout(() => {
      this.setExportToPDF(true, EXPORT_TO_PDF_NOT_SUPPORTED_DATE_MSG);
    }, 500);
  };

  _onChangeTime = (date: Date) => {
    if (date) {
      this._updateDiffParams({ revision: null, date: date });
      this.setExportToPDF(true, EXPORT_TO_PDF_NOT_SUPPORTED_DATE_MSG);
    }
  };

  _updateDiffParams = (diffParams: { revision: null | IRevision; date: Date }) => {
    if (diffParams.date) {
      const projectCreateDate = moment(this.props.currentProject.created);
      const diffDate = moment(diffParams.date);
      if (projectCreateDate.diff(diffDate) > 0) {
        // selected date before project create date
        // has to be slightly after project create date (back-end revision lookup will return null otherwise)
        diffParams.date = projectCreateDate.add(2, 'seconds').toDate();
      }
    }

    this.setState(
      {
        selected: diffParams
      },
      () => {
        this.props.onDiff(diffParams);
      }
    );
  };

  _isDateTimeDiffEnabled() {
    return this.props.currentIndex && (this.props.currentIndex as IIndex).isMasterDraft;
  }

  _getMinDate() {
    if (this.props.currentProject) {
      const diffableRevisionNumber = ServerSettingsStore.getServerSettings().diffableRevisionNumber;
      if (diffableRevisionNumber && this.props.revisions?.length > diffableRevisionNumber) {
        const ordered = _.orderBy(this.props.revisions, ['snapshotDate'], ['desc']);
        const masterPublishedRevisions = ordered.filter(function (o) {
          return o.status === 'MASTER_PUBLISHED';
        });
        const diffableRevisions = this._extractMajorOrMinorRevisions(masterPublishedRevisions);
        let snapshotDate;
        if (diffableRevisions.length < diffableRevisionNumber) {
          snapshotDate = diffableRevisions[diffableRevisions.length - 1].snapshotDate;
        } else {
          snapshotDate = diffableRevisions[diffableRevisionNumber - 1].snapshotDate;
        }
        return new Date(snapshotDate);
      } else {
        return new Date(this.props.currentProject.created);
      }
    }
  }

  _extractMajorOrMinorRevisions(masterPublishedRevisions: IRevision[]) {
    const revisionType = ServerSettingsStore.getServerSettings().diffableRevisionType;
    const diffableRevisionNumber = ServerSettingsStore.getServerSettings().diffableRevisionNumber;
    if (diffableRevisionNumber === 0) {
      return masterPublishedRevisions;
    }
    const minor: IRevision[] = [];
    const major: IRevision[] = [];
    let previousRevision = masterPublishedRevisions[0];
    for (let i = 1; i < masterPublishedRevisions.length; i++) {
      const x = masterPublishedRevisions[i];
      const revType = this.getRevType(previousRevision, x);
      if (revType === 'MAJOR') {
        major.push(previousRevision);
      } else {
        minor.push(previousRevision);
      }
      previousRevision = x;
    }
    const last = masterPublishedRevisions[masterPublishedRevisions.length - 1];
    major.push(last);
    if ('MAJOR' === revisionType) {
      return major;
    }
    return _.orderBy(_.concat(minor, major), ['snapshotDate'], ['desc']);
  }

  private getRevType(i1: IRevision, i2: IRevision): string {
    const i1Split = i1.revision.split('.');
    const i2Split = i2.revision.split('.');
    if (i1Split[0] !== i2Split[0]) {
      return 'MAJOR';
    }
    if ((i1Split[1], i2Split[1])) {
      return 'MINOR';
    }
    return 'SUB';
  }

  _getMaxDate() {
    return new Date();
  }

  private revisionSelected(revision: IRevision | IIndex | null) {
    const currentIndexVariants = UnitConceptStore.getFamilyVariants();
    if (!!currentIndexVariants && currentIndexVariants.length) {
      return this.setExportToPDF(true, EXPORT_TO_PDF_NOT_SUPPORTED_VARIANT_FAMILY_MSG);
    }
    if (revision) {
      getUnitMap(revision.uid || (revision as IRevision).indexUid)
        .then((result) => {
          const hasVariants = !!result.variantFamily;
          this.setExportToPDF(hasVariants, hasVariants ? EXPORT_TO_PDF_NOT_SUPPORTED_VARIANT_FAMILY_MSG : undefined);
        })
        .catch((error) => {
          console.error(error);
          this.setExportToPDF(true, '');
        });
    } else {
      this.setExportToPDF(false);
    }
  }

  private setExportToPDF = (disabled: boolean, reason?: string) => {
    EditorStore.getModeProperties<'DIFF'>().setFileExportTypeAllowed(FileExportType.PDF, !disabled);
    this.setState({
      exportToPDF: {
        disabled,
        reason
      }
    });
  };

  render() {
    const { exportToPDF } = this.state;
    const isDateTimeDiffDisabled = !this._isDateTimeDiffEnabled();
    return (
      <div className={'mode-bar-inner ' + (this.props.className ? ' ' + this.props.className : '')}>
        <DateTimeInput
          ref={this.dateTimeInputRef}
          value={this.state.selected.date}
          isDateDropdownVisible={true}
          classPrefix={'mode-bar__'}
          isDisabled={isDateTimeDiffDisabled}
          onUpdateDate={this._onChangeDP}
          onUpdateTime={this._onChangeTime}
          datePickerId="diffModeBar-datePicker"
          timePickerId="diffModeBar-timePicker"
          selectedLabel={this._getSelectedLabel()}
          minDate={this._getMinDate()}
          maxDate={this._getMaxDate()}
          iconStyle={{ color: 'white', fontSize: '16px' }}
        >
          <Menu style={{ width: 'auto', fontSize: '12px' }} listStyle={{ width: 'auto', fontSize: '12px' }}>
            {this._isNoPublishedRevisions() ? (
              <MenuItem
                primaryText={'No Revisions:'}
                disabled={true}
                style={{
                  fontSize: '12px',
                  lineHeight: '30px',
                  minHeight: '30px',
                  color: 'rgba(0, 0, 0, 0.870588)'
                }}
              />
            ) : (
              <span>
                <MenuItem
                  primaryText={'Diff to:'}
                  disabled={true}
                  style={{
                    fontSize: '12px',
                    lineHeight: '30px',
                    minHeight: '30px',
                    color: 'rgba(0, 0, 0, 0.870588)'
                  }}
                />
                <Divider />
              </span>
            )}
            {this._getRevisionMenuItems()}
          </Menu>
        </DateTimeInput>

        <div className="mode-bar__title">
          <span className="vertical-align-middle display-inline-block">DIFF MODE</span>
          <DisabledPDFWarningIcon
            show={exportToPDF.disabled}
            className="vertical-align-middle margin-left-m"
            reason={exportToPDF.reason}
          ></DisabledPDFWarningIcon>
        </div>

        <div className="mode-bar__current">
          {this._getCurrentRevisionName()}
          <CloseModeBar showClose={true} onClose={this.props.onClose} />
        </div>
      </div>
    );
  }
}
