import { mm, Cancelled } from './base-clients';
import { IUser, IProjectMembership, IRole } from 'mm-types';
import userUtil from '../components/documents/team/util/user';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;
let getMembersSource: CancelTokenSource | null = null;

export type GetAllOptions = {
  query: string;
  projectUid: string;
  page: number;
  size: number;
};

export async function saveProfile(userId: string, avatarFile: File) {
  const data = new FormData();
  data.set('content', avatarFile);
  data.set('filename', avatarFile.name);

  await mm.post<void>(`/users/${userId}/avatars`, data);
  return true;
}

export async function setSettings(userId: string, data: { lastSeenNotificationTimestamp: number }) {
  await mm.post<void>(`/users/${userId}/settings`, data);
  return true;
}

export async function getUser(id?: string) {
  const result = await mm.get<IUser>(`/users/${id ? id : 'self'}`);
  const user = result.data;
  parseUser(user);
  return user;
}

export async function updateMembers(projectUid: string, tokens: Partial<IProjectMembership>[]) {
  const response = await mm.put<{ members: IProjectMembership[] }>(`/projects/${projectUid}/memberships`, {
    memberships: tokens
  });
  return response.data;
}

export async function getMembers(projectUid: string, options?: { flatten: boolean }): Promise<IProjectMembership[] | Cancelled> {
  if (getMembersSource) {
    getMembersSource.cancel();
  }

  getMembersSource = axios.CancelToken.source();

  const query: string[] = [];
  if (options && options.flatten) {
    query.push('flattenGroups=true');
  }

  try {
    const result = await mm.get<{ memberships: IProjectMembership[] }>(`/projects/${projectUid}/memberships?${query.join('&')}`, {
      cancelToken: getMembersSource.token
    });
    getMembersSource = null;

    const members = result.data.memberships || [];
    members.forEach((member) => {
      member.role = (member.role as IRole).name; // for easier groupby sort

      if (member.user) {
        member.user.avatarUrl = userUtil.imagePath(member.user);
      } else if (member.group) {
        member.group.avatarUrl = userUtil.imagePathGroup();
      }
    });

    return members;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getUsers({
  query = '',
  projectUid = undefined,
  page = 0,
  size = 50
}: Partial<GetAllOptions>): Promise<IUser[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const getUsersQuery: string[] = [];
  getUsersQuery.push('page=' + page);
  if (projectUid) {
    getUsersQuery.push('projectUid=' + projectUid);
  }
  getUsersQuery.push('query=' + query);
  getUsersQuery.push('size=' + size);

  try {
    const result = await mm.get<{ users: IUser[] }>(`/users?${getUsersQuery.join('&')}`, { cancelToken: getAllSource.token });
    getAllSource = null;
    const users = result.data.users;
    for (const user of users) {
      parseUser(user);
    }

    return users;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

function parseUser(user: IUser) {
  user.avatarUrl = userUtil.imagePath(user);

  // for mention capability
  user.id = user.uid;
  user.display = user.displayName;

  if (!user.displayName) {
    const account = user.account;

    if (account && account.firstName) {
      user.displayName = [account.firstName, account.lastName].join(' ');
    } else {
      user.displayName = user.username;
    }
  }
}
