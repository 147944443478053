import * as React from 'react';
import { AdditionalFormActions, CancelButton, SaveButton, MaxLengthExceededMsg } from './index';
import { AdditionalFormActionsProps } from './AdditionalFormActions';

export interface ICommentFormButtonsProps extends AdditionalFormActionsProps {
  maxLength: number;
  maxLengthExceeded: boolean;
  onCancel: () => any;
  onCancelReply: () => any;
  onCreate: () => any;
}

const ReplyButtons = (props: ICommentFormButtonsProps) => {
  return (
    <div className="comment-post-actions">
      <div className="comment-post-actions-inner replies">
        <div className="public-private-container">
          <AdditionalFormActions {...props} />
          {props.maxLengthExceeded && <MaxLengthExceededMsg maxLength={props.maxLength} />}
        </div>
        <div>
          <CancelButton onCancel={() => (props.mode.edit ? props.onCancel() : props.onCancelReply())} cid="comment-form-reply-cancel" />
          <SaveButton onCreate={() => props.onCreate()} cid="comment-form-reply-create" />
        </div>
      </div>
    </div>
  );
};

export default ReplyButtons;
