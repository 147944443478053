import * as _ from 'lodash';
import { DomAssertions } from '../../tinyFacade/DomAssertionsUtil';
import { TinyAction } from 'mm-types';

const INLINE_ELEMENT_SELECTOR = {
  CLASS_NAME: ['arc-super-script', 'arc-sub-script', 'arc-underline', 'arc-overline', 'arc-color-text', 'arc-bg-color-text'],
  TAGS: ['em', 'strong']
};

const ELEMENT_LOOKUP_MAP = [
  {
    definition: 'BoldText',
    defaultAction: 'bold'
  },
  {
    definition: 'CodeText',
    defaultAction: 'code'
  },
  {
    definition: 'ItalicText',
    defaultAction: 'italic'
  },
  {
    definition: 'SubScriptText',
    defaultAction: 'subscript'
  },
  {
    definition: 'SuperScriptText',
    defaultAction: 'superscript'
  },
  {
    definition: 'UnderlineText',
    defaultAction: 'underline'
  },
  {
    definition: 'StyledText',
    defaultAction: 'arcTextTransform',
    actionList: ['overline', 'forecolor', 'hilitecolor', 'arcTextTransform']
  },
  {
    definition: 'ColorText',
    defaultAction: 'forecolor'
  },
  {
    definition: 'BackgroundColorText',
    defaultAction: 'hilitecolor'
  }
];

export const actionToDefinition = ELEMENT_LOOKUP_MAP.reduce((current, next) => {
  const actionListToDefinition = next.actionList
    ? next.actionList.reduce((currentObject, action) => ({ ...currentObject, [action]: next.definition }), {})
    : {};

  return { ...current, [next.defaultAction]: next.definition, ...actionListToDefinition };
}, {});

export const definitionToAction = ELEMENT_LOOKUP_MAP.reduce((current, next) => {
  return { ...current, [next.definition]: next.defaultAction };
}, {});

export const isInlineElement = (element: Element): boolean => {
  const isInline: boolean = _.intersection(INLINE_ELEMENT_SELECTOR.CLASS_NAME, element.classList).length > 0;
  if (!isInline) {
    return DomAssertions.isNodeName(element, INLINE_ELEMENT_SELECTOR.TAGS);
  }
  return isInline;
};

export const isNotInlineElement = (element: Element): boolean => {
  return !isInlineElement(element);
};

export const isInlineMarkupAction = (action: TinyAction): boolean => {
  return !!actionToDefinition[action];
};
