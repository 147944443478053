import { Editor } from 'tinymce';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';
import { ElementDetails } from '../components/editor/utils/units/ElementDetails';
import { IElementSettings } from 'mm-types';
import EditorStore from '../flux/editor/EditorStore';
import { combineSettings } from './ElementSettingUtil';

class TableUtils {
  // selecting first element, if empty then selecting any element, if empty then selecting first cell
  static getFirstTableCellFromSelectedNode(selectedNode: HTMLElement): HTMLElement | null {
    let selection: HTMLElement | null = null;
    if (selectedNode) {
      selection = selectedNode.querySelector('tr:first-child > td .element-content > *:not(br)') as HTMLElement;
      if (!selection) {
        selection = selectedNode.querySelector('tr:first-child > td > *') as HTMLElement;
      }
      if (!selection) {
        selection = selectedNode.querySelector('tr:first-child > td') as HTMLElement;
      }
    }
    return selection;
  }

  // selecting first element, if empty then selecting any element, if empty then selecting first cell
  static getFirstTableCellFromActiveEditor(activeEditor: Editor, selectedNode: Element): Node | undefined {
    let selection: Node | undefined = undefined;
    if (activeEditor && selectedNode) {
      selection = activeEditor?.dom.select('td:first .element-content > *:not(br)', selectedNode)[0] as Node;
      if (!selection) {
        selection = activeEditor?.dom.select('td:first > *', selectedNode)[0] as Node;
      }
      if (!selection) {
        selection = activeEditor?.dom.select('td:first', selectedNode)[0] as Node;
      }
    }
    return selection;
  }

  static collectTableSettingsFromSelectedNode(selectedNode: HTMLElement): IElementSettings[] {
    let collectedSettings: IElementSettings[] = [];
    const currentRow = selectedNode.closest('tr');
    const currentRowParent = currentRow?.parentElement; // thead or tbody
    const currentTable = selectedNode.closest('table');
    const currentRowDefId = ElementDetails.getDataElementDefinitionId(currentRow);
    const currentTheadOrTbodyDefId = ElementDetails.getDataElementDefinitionId(currentRowParent);
    const currentTableDefId = ElementDetails.getDataElementDefinitionId(currentTable);

    const orderedElmDefIdSettings = [currentRowDefId, currentTheadOrTbodyDefId, currentTableDefId].map(
      (elmDefId) => ProjectDefinitionStore.getElementDefinitionById(elmDefId)?.settings ?? []
    );
    collectedSettings = this.collectSettingsFromOrderedArray(orderedElmDefIdSettings);
    return collectedSettings;
  }

  /**
   * Collects settings in order of array passed in
   * @param {IElementSettings[][]} orderedSettingsArray array of settings that need to be collected in order of specificity that the need to be collected, array order should be from most specific to least i.e row to table
   * @return {IElementSettings[]} array of collected settings
   * */
  static collectSettingsFromOrderedArray(orderedSettingsArray: IElementSettings[][]): IElementSettings[] {
    let collectedSettings: IElementSettings[] = [];
    orderedSettingsArray.forEach((elmDefSettings) => {
      collectedSettings = this.collectSettings(collectedSettings, elmDefSettings);
    });
    return collectedSettings;
  }

  /** Adds current elm def settings to prev settings by either adding if setting isnt present already or overwriting setting
   * @param {IElementSettings[]} collectedSettings settings to add to, this should be the settings of the most specific element i.e in order of cell, row, thead/tbody, table
   * @param {IElementSettings[] | undefined} currentSettings settings to check if is already present in collectedSettings, if not add to them.
   * @return {IElementSettings[]} array of collected settings
   * */
  static collectSettings(collectedSettings: IElementSettings[], currentSettings?: IElementSettings[]) {
    currentSettings?.forEach((setting) => {
      const settingIndex = collectedSettings.findIndex((set) => set.id === setting.id);
      if (settingIndex === -1) {
        collectedSettings = [...collectedSettings, setting];
      }
    });
    return collectedSettings;
  }

  static hasSettingEnabled(selectedNode: HTMLElement, settingId: string): boolean {
    const settings = this.collectTableSettingsFromSelectedNode(selectedNode);
    return settings.find((setting) => setting.id === settingId)?.enabled ?? true;
  }

  static combineSelectedCellSettings() {
    const selectedCells = this.getSelectedCells();
    const currentCellSelectionSettings = this.getCellSettingsFromCurrentCellSelection(selectedCells);
    const cellSettings = selectedCells
      .map((cell) => {
        return ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(cell))?.settings;
      })
      .filter((settings): settings is IElementSettings[] => !!settings);
    cellSettings.push(currentCellSelectionSettings);
    return combineSettings(cellSettings);
  }

  /** Get cell settings from current selection, based on AER-9031 we should disable some actions based on cell selection.
   * - Allow everything if single cell selected
   * - Only allow col insert before/after if single col or cell selected
   * - Only allow row insert/paste before/after if single row or cell selected
   * - If row + col selected disable insert/paste row/col before/after */
  static getCellSettingsFromCurrentCellSelection(selectedCells: Element[]): IElementSettings[] {
    if (selectedCells.length === 1) {
      return [];
    } else {
      const settings: IElementSettings[] = [];
      const cellIndices = new Set(selectedCells.map((cell: HTMLTableCellElement) => cell.cellIndex));
      const rowIndices = new Set(selectedCells.map((cell: HTMLTableCellElement) => (cell.parentElement as HTMLTableRowElement).rowIndex));
      if (cellIndices.size > 1) {
        settings.push(
          {
            id: 'insertCellLeft',
            enabled: false
          },
          {
            id: 'insertCellRight',
            enabled: false
          }
        );
      }
      if (rowIndices.size > 1) {
        settings.push(
          {
            id: 'insertRowAbove',
            enabled: false
          },
          {
            id: 'insertRowBelow',
            enabled: false
          },
          {
            id: 'pasteRowBelow',
            enabled: false
          },
          {
            id: 'pasteRowAbove',
            enabled: false
          }
        );
      }
      return settings;
    }
  }

  static combineSelectedRowSettings() {
    const rowSettings = this.getSelectedRows()
      .map((row) => {
        return ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(row))?.settings;
      })
      .filter((settings): settings is IElementSettings[] => !!settings);

    return combineSettings(rowSettings);
  }

  static getSelectedRows(): Element[] {
    const { table, cell: cellElm } = EditorStore.getEditor().getActiveEditorFacade()!.getTableSelectionStartElements();
    const rows: HTMLTableRowElement[] = [];

    if (table) {
      Array.from(table.rows).forEach(function (row) {
        Array.from(row.cells).forEach(function (cell) {
          if (cell.hasAttribute('data-mce-selected') || cell === cellElm) {
            rows.push(row);
            return false;
          }
        });
      });
    }

    return rows;
  }

  static getSelectedCells(): Element[] {
    const editor = EditorStore.getEditor().getActiveEditorInstance();
    let selection: Element[] = editor?.dom.select('td[data-mce-selected],th[data-mce-selected]') ?? [];
    if (selection?.length === 0) {
      // if nothing selected get parent cell of cursor
      if (editor?.selection) {
        selection = [editor.dom.getParent(editor.selection.getStart(), 'th,td') as Element];
      }
    }
    return selection;
  }
}

export default TableUtils;
