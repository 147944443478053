import * as React from 'react';
import * as _ from 'lodash';
import HighlightProperties from './components/HighlightProperties';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import TocStore, { TocStoreEvent } from '../../../../../flux/editor/TocStore';
import NoProperties from './components/NoProperties';
import ToggleSection from '../../../../misc/ToggleSection';
import { IElementSettings } from 'mm-types';
import { Grid } from '../../../../general/layouts/Grid';
import { PropertiesHeader } from './sub/PropertiesHeader';
import RevisionStore from '../../../../../flux/editor/RevisionStore';
import UnitConceptStore from '../../../../../flux/editor/UnitConceptStore';
import { settingEnabled } from '../../../../../utils/ElementSettingUtil';
import { selectedUnitPropertiesMap, PropType } from './SelectedUnitPropertiesMap';

export type Props = {};

export type State = {
  expandAll: boolean;
  shouldCollapse?: boolean;
  settings?: (IElementSettings[] | undefined)[];
  selectedUnits: string[];
  toggled: Object;
};

export default class SelectedUnitProperties extends React.Component<Props, State> {
  private _loader: number;
  private _unsubsribeTocStore: Function;

  constructor(props: Props) {
    super(props);
    let selectedUnitPropertiesMaps: PropType[] = selectedUnitPropertiesMap();
    this.state = {
      expandAll: false,
      settings: [],
      selectedUnits: EditorStore.getSelectedUnits().map((unit) => unit.uid),
      toggled: selectedUnitPropertiesMaps.reduce((accumulator, value, index) => {
        return { ...accumulator, [value.id]: false };
      }, {})
    };
    this.expandCollapseAll = this.expandCollapseAll.bind(this);
  }

  toggleSection(section: string) {
    let toggled = Object.assign({}, this.state.toggled);
    toggled[section] = !toggled[section];
    this.setState({ toggled: toggled });
  }

  getSettings() {
    const settings: (IElementSettings[] | undefined)[] = EditorStore.getSelectedUnits().map((unit) => {
      return ProjectDefinitionStore.getProjectDefinedUnitById(unit.definitionId)?.settings;
    });

    this.setState({
      settings: settings
    });
  }

  componentDidMount() {
    this._unsubsribeTocStore = TocStore.listen(this.onTocStoreUpdated, this);
    this.getSettings();
  }

  componentWillUnmount() {
    clearTimeout(this._loader);
    this._unsubsribeTocStore();
  }

  onTocStoreUpdated(e: TocStoreEvent) {
    if (!this.state.shouldCollapse) {
      const shouldCollapse = e.type === 'selectTocItem' || e.type === 'highlightTocItem';
      this.setState({
        shouldCollapse
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.shouldCollapse) {
      this.setState({
        shouldCollapse: false
      });
    }
    this.getSettings();
    this.setState({
      selectedUnits: EditorStore.getSelectedUnits().map((unit) => unit.uid)
    });
  }

  shouldDisplayRevisionHighlights(): boolean {
    return EditorStore.getSelectedUnits().length === 1 && RevisionStore.isPublishedRevision();
  }

  expandCollapseAll() {
    this.setState({ expandAll: !this.state.expandAll });
  }

  isShareUsageReadonly(): boolean {
    const shareUsageCount = EditorStore.getSelectedUnits().filter((u) => {
      return u.shareDetails && !u.shareDetails.origin && u.shareDetails.updateStrategy !== 'NONE';
    }).length;

    return shareUsageCount > 0;
  }

  shouldDisplay = (id: string): boolean => {
    const project = ProjectStore.getProject();
    const units = EditorStore.getSelectedUnits();

    const ordinalParaSupport = project && ProjectDefinitionStore.allowSetting('allowParagraphNumbering', false);
    const allOrdinable = _.every(units, 'isordinable') as boolean;
    // check settings of all selected units and provide AND result
    const isNumberingEnabledInSettings = this.state.settings?.reduce((previousValue: boolean, settings) => {
      return previousValue && settingEnabled('numbering', settings);
    }, true);

    switch (id) {
      case 'numbering':
        return project &&
          units.length >= 1 &&
          !EditorStore.isReadOnly() &&
          !this.isShareUsageReadonly() &&
          (ordinalParaSupport ? allOrdinable : allOrdinable && !_.some(units, { type: 'paragraph' })) &&
          isNumberingEnabledInSettings
          ? true
          : false;
      case 'specialInterest':
        return (
          ProjectDefinitionStore.allowSetting('metadataAllowSpecialInterestGroupEmail', false) &&
          units.length >= 1 &&
          !EditorStore.isReadOnly() &&
          !this.isShareUsageReadonly()
        );
      case 'printOutput':
        return (
          units.length >= 1 &&
          !EditorStore.isReadOnly() &&
          _.every(units, (unit) => unit.type !== 'ghost') &&
          !this.isShareUsageReadonly() &&
          (ProjectDefinitionStore.getCurrentIndexDefinition()?.allowPrintOutput ?? true)
        );
      case 'metadata':
        return units.length === 1 && units[0].type !== 'ghost';
      case 'formatting':
        return !!(
          units.length >= 1 &&
          !EditorStore.isReadOnly() &&
          project &&
          ProjectDefinitionStore.allowUnitIndentation() &&
          !this.isShareUsageReadonly()
        );
      case 'repeater-heading':
        return !!EditorStore.getSelectedUnit() && !!EditorStore.getSelectedUnit().isRepeaterHeading;
      case 'variants':
        if (units.length < 1) {
          return false;
        }
        if (!units[0].canHaveVariantOutput) {
          return false;
        }
        if (UnitConceptStore.getFamilyVariants().length === 0) {
          return false;
        }
        return !(units[0].shareDetails && units[0].shareDetails.origin);
      case 'no-properties':
        return (
          units.length === 1 &&
          !EditorStore.isReadOnly() &&
          !units[0].isordinable &&
          !units[0].canHavePrintOutput &&
          !this.shouldDisplayRevisionHighlights() &&
          !this.shouldDisplay('formatting') &&
          !this.shouldDisplay('specialInterest')
        );
      default:
        return false;
    }
  };

  render() {
    const unit = EditorStore.getSelectedUnit()!;
    const tocUid = EditorStore.findFirstTocableUnitForSelectedUnit()?.uid;

    return (
      <div className="toggle-section-outer selected-unit-props-container edit-properties">
        <Grid autoRows="min-content" style={{ height: '100%' }}>
          <PropertiesHeader expanded={this.state.expandAll} onToggle={this.expandCollapseAll} />
          <div className="toggle-items">
            {this.shouldDisplayRevisionHighlights() && !!tocUid && (
              <ToggleSection title="Revision Highlights" id="revHighlights" expandAll={this.state.expandAll}>
                <HighlightProperties unitUid={unit.uid} tocUid={tocUid} className="selected-unit-props-section" />
              </ToggleSection>
            )}

            {selectedUnitPropertiesMap().map(({ id, title, Component, componentProps }) => (
              <React.Fragment key={id}>
                {this.shouldDisplay(id) && (
                  <ToggleSection
                    title={title}
                    id={id}
                    defaultOpen={this.state.toggled[id]}
                    expandAll={this.state.expandAll}
                    forceClose={this.state.shouldCollapse}
                    onToggled={() => this.toggleSection(id)}
                  >
                    <Component {...componentProps} />
                  </ToggleSection>
                )}
              </React.Fragment>
            ))}

            {this.shouldDisplay('no-properties') && <NoProperties />}
          </div>
        </Grid>
      </div>
    );
  }
}
