import React from 'react';
import { IGenericEditProps } from './GenericEditProps';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';
import { IDataAttribute } from 'mm-types';
import DataAttributesProps from './DataAttributesProps';
import { withToggleSection } from '../../../../../misc/ToggleSection';
import isEqual from 'react-fast-compare';

export type Props = {
  inline?: boolean;
  titlePrefix?: string;
  displayPrintOutput?: boolean;
  tableEditTabType?: 'props' | 'formatting';
} & IGenericEditProps;

type OrderedDataAttributes = { [key: string]: IDataAttribute[] };

export const DataAttributesPropsWrapper = React.memo((props: Props): JSX.Element | null => {
  const [dataAttributes, setDataAttributes] = React.useState<OrderedDataAttributes>({});
  const [openSections, setOpenSections] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (
      hasDefinitionDataAttributes(props.definition, props.parentDefinition?.id, getElmDef(props.selectedUnit?.definitionId)) &&
      props.definition?.dataAttributes
    ) {
      setDataAttributes(orderDataAttributesbySectionToggle(props.definition.dataAttributes));
    } else {
      setDataAttributes({});
    }
  }, [props.definition?.id]);

  function orderDataAttributesbySectionToggle(unorderedDataAttributes: IDataAttribute[]) {
    let orderedDataAttributes: { [key: string]: IDataAttribute[] } = {};
    unorderedDataAttributes.forEach((dataAttribute) => {
      if (dataAttribute.withinToggleSection) {
        if (orderedDataAttributes[dataAttribute.withinToggleSection]) {
          orderedDataAttributes[dataAttribute.withinToggleSection] = [
            ...orderedDataAttributes[dataAttribute.withinToggleSection],
            dataAttribute
          ];
        } else {
          orderedDataAttributes[dataAttribute.withinToggleSection] = [dataAttribute];
        }
      } else {
        if (orderedDataAttributes['unordered']) {
          orderedDataAttributes['unordered'] = [...orderedDataAttributes['unordered'], dataAttribute];
        } else {
          orderedDataAttributes['unordered'] = [dataAttribute];
        }
      }
    });
    return orderedDataAttributes;
  }

  const handleSectionToggle = (opened, index) => {
    if (opened) {
      setOpenSections([...openSections, index]);
    } else {
      setOpenSections(openSections.filter((openIndex) => openIndex !== index));
    }
  };

  const filterPrintOutput = (section: string) => {
    return !(section === 'Print Output' && (!props.displayPrintOutput || (props.index === 0 && props.type !== 'Table')));
  };

  let attrComponents: (JSX.Element | undefined)[] = Object.keys(dataAttributes)
    .filter((section) => filterPrintOutput(section))
    .map((section, index) => {
      if (section !== 'unordered') {
        const DataAttributePropsSection = withToggleSection(DataAttributesProps);
        return (
          <DataAttributePropsSection
            {...props}
            title={(props.titlePrefix ?? '') + section}
            dataQa={'data-attribute-toggle-section'}
            id={`${section.toLowerCase().replace(' ', '-')}-data-attribute-toggle-section`}
            definition={props.definition}
            inline={props.inline}
            classnames={['force-focus']}
            defaultOpen={openSections.indexOf(index) !== -1}
            onToggled={(opened) => handleSectionToggle(opened, index)}
            contentContainerStyle={{ paddingLeft: 12, backgroundColor: 'white' }}
            key={section}
            dataAttributes={dataAttributes[section]}
            parentDefinition={props.parentDefinition}
          />
        );
      }
    })
    .filter(Boolean);

  // Don't display any unordered data attributes in table cell formatting tab.
  // Currently should only display print output if row selected in formatting tab and print output in props tab if table selected.
  // In future if we decided to add data attributes to rows/cells we will need to revisit this in table edit props.
  if (dataAttributes['unordered'] && props.tableEditTabType !== 'formatting') {
    attrComponents.push(
      <DataAttributesProps
        {...props}
        parentDefinition={props.parentDefinition}
        dataAttributes={dataAttributes['unordered']}
        definition={props.definition}
        inline={props.inline}
        key={'unordered'}
      />
    );
  }
  return <div>{attrComponents}</div>;
}, isEqual);
