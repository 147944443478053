import { IEditorStoreEvent } from 'mm-types';

export enum EditorEventType {
  saving = 'saving',
  initEditor = 'initEditor',
  synchronizeUnitEditAction = 'synchronizeUnitEditAction',
  activeEditorChange = 'activeEditorChange',
  synchronizeEditor = 'synchronizeEditor',
  changeModeStart = 'changeModeStart',
  changeModeComplete = 'changeModeComplete',
  specialCharsUpdated = 'specialCharsUpdated',
  editorIsBusy = 'editorIsBusy',
  nestedUnitFocusChange = 'nestedUnitFocusChange',
  editFocus = 'editFocus',
  openDocumentPage = 'openDocumentPage',
  undoredoError = 'undoredoError',
  retrieveUnit = 'retrieveUnit',
  variantPreviewError = 'variantPreviewError',
  scrollToUnit = 'scrollToUnit',
  selectUnit = 'selectUnit',
  acceptSharedContentInsert = 'acceptSharedContentInsert',
  editTextSelectedEnd = 'editTextSelectedEnd',
  editBlur = 'editBlur',
  deleteUnit = 'deleteUnit',
  unitMoved = 'unitMoved',
  pasteHtmlParsed = 'pasteHtmlParsed',
  selectShareOrigin = 'selectShareOrigin',
  diffSharedContentUpdate = 'diffSharedContentUpdate',
  postUnitRender = 'postUnitRender',
  editorStoresInitialized = 'editorStoresInitialized',
  editorStoresInitializing = 'editorStoresInitializing',
  documentChanged = 'documentChanged',
  requestSharedContentInsert = 'requestSharedContentInsert',
  unitStyleChange = 'unitStyleChange',
  launchEditor = 'launchEditor',
  openGenericDateModal = 'openGenericDateModal',
  requestRejectSharedContentUpdate = 'requestRejectSharedContentUpdate',
  inlineUnitAction = 'inlineUnitAction',
  unitDomChange = 'unitDomChange',
  insertUnit = 'insertUnit',
  createUnit = 'createUnit',
  unitsSelected = 'unitsSelected',
  deleteSelectedUnits = 'deleteSelectedUnits',
  editTextSelected = 'editTextSelected',
  shareSelection = 'shareSelection',
  editorError = 'editorError',
  undoredo = 'undoredo',
  publishSharedOrigin = 'publishSharedOrigin',
  replaceUnit = 'replaceUnit',
  formatSelectedUnits = 'formatSelectedUnits',
  unitsChanged = 'unitsChanged',
  convertBatchUnits = 'convertBatchUnits',
  deleteUnitConfirm = 'deleteUnitConfirm',
  updateUnit = 'updateUnit',
  splitSelectedUnits = 'splitSelectedUnits',
  deleteSelectedUnitsConfirm = 'deleteSelectedUnitsConfirm',
  createBatchUnits = 'createBatchUnits',
  unitsRetrieved = 'unitsRetrieved',
  loadMoreUnits = 'loadMoreUnits',
  retrieveUnitInfos = 'retrieveUnitInfos',
  editor_modals_closed = 'editor-modals-closed',
  modalAction = 'modalAction',
  variantTaggingError = 'variantTaggingError',
  changeInsertPosition = 'changeInsertPosition',
  dataAttributeApplied = 'dataAttributeApplied'
}

export function isEditorEventOfType<T extends EditorEventType>(e: IEditorStoreEvent<EditorEventType>, type: T): e is IEditorStoreEvent<T> {
  return e.type === type;
}
