import { IUnit, IUnitDetails } from 'mm-types';
import { ActionEvent } from '../components/editor/docUnit/DocUnit';
import TocStore from '../flux/editor/TocStore';
import { makeSimpleSnackbar } from '../components/misc/SystemSnackbar/util';
import _ from 'lodash';
import Clipboard, { Clip, ClipPasteUnit, CopyDetails } from './clipboard/clipboard';
import EditorStore from '../flux/editor/EditorStore';
import { Dom } from '../components/editor/utils/tinyFacade/DomUtil';
import { UnitTypes } from '../components/editor/utils/units/UnitTypes';
import { InsertAction } from '../components/editor/menus/insert/content/ContentMenuContainer';
import { validateElementPaste } from '../clients/units';
import { clipboard } from './index';
import UnitSanitizer from '../components/editor/utils/units/UnitSanitizer';
import { Cancelled } from '../clients/base-clients';
import { dom } from 'tinymce';
import { AxiosError } from 'axios';
import { isTextNode } from '../components/editor/utils/tinyFacade/key_listeners/keyBehaviourUtils';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

export interface IElementCopyDetails extends IUnitDetails {
  isVisibleOnEdit: boolean;
  html: string;
  isElement: boolean;
}

export namespace CutCopyPasteUtil {
  export async function cutOrCopy(isSingleUnitOperation: boolean, e: ActionEvent, isCut: boolean) {
    if (isSingleUnitOperation) {
      let unit: IUnit | IElementCopyDetails;
      if (e.data?.unit && e.data.unit.focused) {
        unit = e.data.unit.focused as IElementCopyDetails;
        unit.isVisibleOnEdit = true;
        unit.html = e.data.unit.focused.targetElement.outerHTML;
        unit.isElement = true;
      } else {
        unit = e.data.unit as IUnit;
      }
      const result = isCut ? await cutUnit(unit) : copyUnit(unit);
      if (!result.error) {
        makeSimpleSnackbar(`${result.title} ${isCut ? 'cut' : 'copied'} to clipboard`);
      } else {
        makeSimpleSnackbar(`${result.title} can't be ${isCut ? 'cut' : 'copied'}`);
      }
    } else {
      const result = isCut ? await cutSelectedUnits() : copySelectedUnits();
      if (result.selectedUnitsCount) {
        makeSimpleSnackbar(`${isCut ? 'Cut' : 'Copied'} ${result.selectedUnitsCount} items to clipboard`);
      } else {
        makeSimpleSnackbar(`Cannot be ${isCut ? 'cut' : 'copied'}`);
      }
    }
  }

  function copyUnit(unit: IUnit | IElementCopyDetails) {
    if (TocStore.getFirstSelectedTocableUnit()!.type !== 'frontmatter') {
      const copyDetails = getCopyDetails(unit);
      const title = copyDetails.title;
      if (Clipboard.copyUnit(copyDetails)) {
        return { error: false, title };
      } else {
        return { error: true, title };
      }
    }

    return { error: true, title: 'Frontmatter' };
  }

  function copySelectedUnits() {
    if (TocStore.getFirstSelectedTocableUnit()?.type !== 'frontmatter') {
      const selectedUnits = EditorStore.getSelectedUnits();
      const selectedUnitsCount = selectedUnits.length;

      if (selectedUnitsCount > 1) {
        const selectedUnitsSortedByIndex: CopyDetails[] = _.sortBy(selectedUnits, ['index'], ['asc']).map((u: IUnit) => getCopyDetails(u)); // ensures pasted in order of index

        if (Clipboard.copyUnits(selectedUnitsSortedByIndex)) {
          return { error: false, selectedUnitsCount };
        } else {
          return { error: true, selectedUnitsCount: 0 };
        }
      }
    }

    return { error: true, selectedUnitsCount: 0 };
  }

  function getCopyDetails(unitToCopy: IUnit | IElementCopyDetails): CopyDetails {
    let title: string, definitionId: string;
    if (unitToCopy.definition) {
      title = unitToCopy.definition.displayName;
      definitionId = unitToCopy.definition.id;
    } else {
      const unitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
        (unitToCopy as IUnit).definitionId
      );
      title = unitProfile?.displayName ?? '';
      definitionId = unitProfile?.id ?? '';
    }
    const copiedHtml = UnitSanitizer(unitToCopy.html);
    const type = unitToCopy.type;
    return { title, definitionId, copiedHtml, isVisibleOnEdit: !!unitToCopy.isVisibleOnEdit, isElement: !!unitToCopy.isElement, type };
  }

  async function cutUnit(unit: IUnit | IElementCopyDetails) {
    if (!EditorStore.isBusy() && TocStore.getFirstSelectedTocableUnit()?.type !== 'frontmatter') {
      try {
        const copyDetails = getCopyDetails(unit);

        if (Clipboard.copyUnit(copyDetails)) {
          if (unit.isElement) {
            const editorInstance = EditorStore.getEditor().getActiveEditorInstance();
            const node = editorInstance?.selection.getNode();
            const unitElement = node ? Dom.closestUnitElement(node) : null;
            if (unitElement && unitElement.parentElement) {
              EditorStore.getEditor()?.setCursorLocation(unitElement.parentElement);
              editorInstance?.dom.remove(unitElement);
            }
          } else {
            await EditorStore.deleteUnitConfirm(unit as IUnit, 'Cutting...', null); // skip the confirm prompt for a cut
          }
          return { error: false, title: copyDetails.title };
        } else {
          return { error: true, title: copyDetails.title };
        }
      } catch (title) {
        return { error: true, title };
      }
    }

    return { error: true, title: 'Frontmatter' };
  }

  async function cutSelectedUnits() {
    if (!EditorStore.isBusy() && TocStore.getFirstSelectedTocableUnit()?.type !== 'frontmatter') {
      const unDeletable = EditorStore.getSelectedUnits().filter((u) => !EditorStore.canDeleteUnit(u));

      if (unDeletable.length) {
        return { error: true, selectedUnitsCount: 0 };
      } else {
        const selectedUnits = EditorStore.getSelectedUnits();
        const selectedUnitsCount = selectedUnits.length;
        const selectedUnitsSortedByIndex: CopyDetails[] = _.sortBy(selectedUnits, ['index'], ['asc']).map((u: IUnit) => getCopyDetails(u)); // ensures pasted in order of index

        if (Clipboard.copyUnits(selectedUnitsSortedByIndex)) {
          await EditorStore.deleteSelectedUnitsConfirm('Cutting multiple...', null);
          return { error: false, selectedUnitsCount };
        } else {
          return { error: true, selectedUnitsCount: 0 };
        }
      }
    }

    return { error: true, selectedUnitsCount: 0 };
  }

  export function pasteUnits() {
    const currentClipboard: Clip | null = Clipboard.getLastFromClipboard();
    if (!EditorStore.isBusy() && currentClipboard) {
      const selectedUnit = EditorStore.getSelectedUnit();
      const convertToPartialIUnit = (copyDetails: CopyDetails[]): IUnit | ClipPasteUnit[] => {
        return copyDetails.map((c) => {
          return {
            ...c,
            type: c.type as UnitTypes,
            html: c.copiedHtml,
            template: c.copiedHtml
          };
        });
      };
      if (currentClipboard?.type === Clipboard.DOCUNIT) {
        const pasteUnit: ClipPasteUnit = convertToPartialIUnit(currentClipboard.content)[0];
        EditorStore.createUnit(pasteUnit as ClipPasteUnit, selectedUnit.uid, { launchEditor: false, isPaste: true });
      } else {
        const pasteUnits = convertToPartialIUnit(currentClipboard.content);
        EditorStore.createBatchUnits(pasteUnits as IUnit[]);
      }
    } else {
      if (!currentClipboard) {
        makeSimpleSnackbar('Paste Error: Please copy something to paste');
      } else {
        makeSimpleSnackbar('Paste Error: Unable to paste');
      }
    }
  }

  export async function pasteAsElement(insertPosition: InsertAction) {
    const replacementUnit = await canPasteAsElement(insertPosition);
    const selectedUnit = Dom.closestElement(EditorStore.getEditor().getActiveEditorInstance()?.selection.getNode(), '.arc-unit');

    if (replacementUnit && selectedUnit) {
      // Get current unit tiny instance and replace children with replacement unit equivalent children
      const currentUnitTinyInstanceElm = $(selectedUnit).find(`[id="_${selectedUnit.getAttribute('data-nid')}-tiny-instance"]`)[0];
      const replacementUnitDocEditProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
        (replacementUnit as IUnit).definitionId,
        replacementUnit as IUnit
      );
      const replacementUnitTinyInstanceElmEquivalent = $((replacementUnit as IUnit).html).find(
        replacementUnitDocEditProfile?.targetEditContainerSelector ?? '.edit-target'
      )[0];

      currentUnitTinyInstanceElm.innerHTML = replacementUnitTinyInstanceElmEquivalent.innerHTML;
    }
  }

  export async function canPasteAsElement(insertPosition: InsertAction): Promise<IUnit | Cancelled | undefined> {
    const selection = EditorStore.getEditor().getActiveEditorInstance()?.selection;
    let unitToInsertWithPlaceholder: string | undefined;
    if (selection) {
      unitToInsertWithPlaceholder = insertPasteContentPlaceHolder(selection, insertPosition);
    }

    if (unitToInsertWithPlaceholder) {
      return await validateElementPaste(unitToInsertWithPlaceholder, clipboard.getLastFromClipboard()?.content);
    }
    makeSimpleSnackbar('Paste Error: cannot paste element');
  }

  function insertPasteContentPlaceHolder(selection: dom.Selection, insertPosition: InsertAction): string | undefined {
    const selectedNode = selection.getNode();
    selectedNode.setAttribute('data-attribute-is-currently-selected-node', '');

    const cursorPosition = selection.getSel().anchorOffset;

    const selectedUnit = Dom.closestElement(selectedNode, '.arc-unit');
    const docUnitEditProfile = EditorStore.getSelectedUnit().profile;

    const placeholderSpan = EditorStore.getEditor()
      .getActiveEditorInstance()
      ?.dom.create('span', { class: 'arc-paste-placeholder' }, 'PLACEHOLDERTEXT');

    const copiedSelectedUnit = EditorStore.getEditor().getActiveEditorInstance()?.dom.create('template');

    if (copiedSelectedUnit && selectedUnit && placeholderSpan) {
      copiedSelectedUnit.appendChild(selectedUnit.cloneNode(true));

      const selectedNodeInCopiedSelectedUnit = copiedSelectedUnit?.firstElementChild?.querySelectorAll(
        `[data-attribute-is-currently-selected-node=""]`
      )[0];

      selectedNode.removeAttribute('data-attribute-is-currently-selected-node');

      if (insertPosition === 'insert_before') {
        selectedNodeInCopiedSelectedUnit?.parentNode?.insertBefore(placeholderSpan, selectedNodeInCopiedSelectedUnit);
      } else if (insertPosition === 'insert_after') {
        selectedNodeInCopiedSelectedUnit?.parentNode?.insertBefore(placeholderSpan, selectedNodeInCopiedSelectedUnit.nextSibling);
      } else if (selectedNodeInCopiedSelectedUnit) {
        if (isTextNode(selection.getSel().anchorNode)) {
          const textNodeText = selection.getSel().anchorNode.textContent;
          const textNodeWithPlaceholderInsertedAtCursorPosition =
            textNodeText?.substring(0, cursorPosition) + placeholderSpan.outerHTML + textNodeText?.substring(cursorPosition);

          selectedNodeInCopiedSelectedUnit.innerHTML = selectedNodeInCopiedSelectedUnit.innerHTML.replace(
            textNodeText ?? '',
            textNodeWithPlaceholderInsertedAtCursorPosition
          );
        } else {
          selectedNodeInCopiedSelectedUnit.insertBefore(placeholderSpan, null);
        }
      }

      return docUnitEditProfile?.sanitize
        ? docUnitEditProfile.sanitize((copiedSelectedUnit.firstElementChild as Element).outerHTML)
        : UnitSanitizer((copiedSelectedUnit.firstElementChild as Element).outerHTML);
    }
  }

  export function handlePasteErrors(e: AxiosError) {
    const errorCode = e.response?.data.errors?.[0].code;
    if ([40015, 40030, 40000].indexOf(errorCode) !== -1) {
      const clipboardElmTitle =
        clipboard.getLastFromClipboard()?.type === clipboard.DOCUNIT ? clipboard.getLastFromClipboard()?.content[0].title : 'elements';
      makeSimpleSnackbar(`Paste Error: Unable to paste copied ${clipboardElmTitle} in current position`);
    } else {
      makeSimpleSnackbar(e.response?.data.errors?.[0].message);
    }
  }
}
