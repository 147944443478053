import * as React from 'react';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';

import { Menu, MenuItem } from 'material-ui';
import { SubMenuAnimation } from './MenuAnimation';
import menuHelper from './MenuHelper';
import classNameHelper from '../../../../utils/classNameHelper';

export interface SubMenuProps {
  id?: string;
  className?: string;
  title?: string; // if title is not set that means current menu is a root
  key?: string;
  value: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

interface State {
  active: boolean;
  menuMaxHeight: number | undefined;
}

const TOP_MENU_HEIGHT = 128;

export default class SubMenu extends React.Component<SubMenuProps, State> {
  private itemRef: React.RefObject<MenuItem>;
  private mounted: boolean;

  constructor(props: SubMenuProps) {
    super(props);
    this.state = {
      active: false,
      menuMaxHeight: undefined
    };

    this.onMouseEnterSubmenuPopup = this.onMouseEnterSubmenuPopup.bind(this);
    this.onMouseLeaveSubmenuPopup = this.onMouseLeaveSubmenuPopup.bind(this);
    this.onMouseEnterSubmenuAnchor = this.onMouseEnterSubmenuAnchor.bind(this);
    this.onMouseLeaveSubmenuAnchor = this.onMouseLeaveSubmenuAnchor.bind(this);
    this.setMenuMaxHeight = this.setMenuMaxHeight.bind(this);
    this.onSubmenuClick = this.onSubmenuClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.isRoot()) {
      this.setMenuMaxHeight();
      window.addEventListener('resize', this.setMenuMaxHeight);
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.isRoot()) {
      window.removeEventListener('resize', this.setMenuMaxHeight);
    }
  }

  UNSAFE_componentWillMount() {
    this.itemRef = React.createRef();
  }

  onMouseEnterSubmenuPopup() {
    menuHelper.preventClosingMenu();
    menuHelper.preventClosingSubmenu();
  }

  onMouseLeaveSubmenuPopup() {
    menuHelper.closeMenu();
    menuHelper.closeSubmenu();
  }

  onMouseEnterSubmenuAnchor() {
    menuHelper.preventClosingMenu();
    if (!this.props.disabled) {
      menuHelper.preventClosingSubmenu();
    } else {
      menuHelper.forceCloseSubmenu();
    }
    if (!this.state.active && !this.props.disabled) {
      menuHelper.openSubmenu(this);
    }
  }

  onMouseLeaveSubmenuAnchor() {
    menuHelper.closeMenu();
    menuHelper.closeSubmenu();
  }

  onSubmenuClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  openSubmenu() {
    if (this.itemRef.current) {
      (this.itemRef.current as any).handleClick(event);
    }
    this.setActiveState(true);
  }

  closeSubmenu() {
    if (this.itemRef.current) {
      (this.itemRef.current as any).handleRequestClose();
    }
    this.setActiveState(false);
  }

  setActiveState(active: boolean) {
    if (this.mounted) {
      this.setState({
        active
      });
    }
  }

  get menuItems() {
    if (this.props.disabled) {
      return null;
    }
    return (
      <div
        onMouseEnter={this.onMouseEnterSubmenuPopup}
        onMouseLeave={this.onMouseLeaveSubmenuPopup}
        data-qa={`submenu: ${this.props.value ? this.props.value.toLowerCase() : ''}`}
      >
        {this.props.children}
      </div>
    );
  }

  private isRoot() {
    return !this.props.title;
  }

  private setMenuMaxHeight() {
    const height = window.innerHeight - TOP_MENU_HEIGHT;
    // if root menu height is going to be smaller than 300px unset maxHeight and expand menu to the whole screen
    this.setState({
      menuMaxHeight: height > 300 ? height : undefined
    });
  }

  renderAsItem() {
    const className = classNameHelper.merge('editor-submenu-anchor', {
      active: this.state.active
    });
    return (
      <div
        onMouseEnter={this.onMouseEnterSubmenuAnchor}
        onMouseLeave={this.onMouseLeaveSubmenuAnchor}
        onClick={this.onSubmenuClick}
        className={className}
        data-qa="editor-submenu-anchor"
      >
        <MenuItem
          ref={this.itemRef}
          onClick={this.onSubmenuClick}
          primaryText={this.props.title}
          rightIcon={<ArrowDropRight />}
          value={this.props.value}
          menuItems={this.menuItems}
          animation={SubMenuAnimation}
          disabled={this.props.disabled}
          className={this.props.className}
        />
      </div>
    );
  }

  renderMenu() {
    return (
      <div onMouseOver={this.onMouseEnterSubmenuPopup} onMouseLeave={this.onMouseLeaveSubmenuPopup} className={this.props.className}>
        <Menu desktop={true} width={320} autoWidth={false} maxHeight={this.state.menuMaxHeight}>
          {this.props.children}
        </Menu>
      </div>
    );
  }

  render() {
    return this.isRoot() ? this.renderMenu() : this.renderAsItem();
  }
}
