import { Dom } from '../components/editor/utils/tinyFacade/DomUtil';
import { findElementInHtml } from './ElementUtils';
import { Word } from '../flux/editor/FindReplaceStore';

/*
    Utilities to deal with lists of words and keep track of index and ocurrence
    for consumers like the spellchecker and find and replace

    A word is fetched through jquery and has the same format for a found word
    or a spellchecked word

    word {
        globalIndex: global Index of this word within the currently viewed docunits
        globalOccurrence: the occurrence of this specific word in the global list
        word: the words text

        // fields below mainly used to identify the word back to the server
        parent: the parent docunit within which this word appears
        indexWithinParent: the index for this word solely within the parent docunit
    }
 */

/**
 *  grabs all words wrappen in jquerySelector attaches a click handler with onClickFnc
 *  and returns a list of the found words
 */
export function fetchWords(jquerySelector: string, onClickFnc: (data: Word, e: JQuery.ClickEvent) => boolean): any {
  const words: Word[] = [];

  $('.editing-stage-page-inner ' + jquerySelector).each(function (index) {
    const data = {
      globalIndex: index,
      word: $(this).text(),
      parent: $(this).attr('data-unit-uid')!,
      indexWithinParent: parseInt($(this).attr('data-replacement-index')!)
    };

    words.push(data);

    $(this).data(data);
    $(this).on('click', (e) => onClickFnc(data, e));
  });

  $('.document-unit-outer').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  });

  return _trackOccurrences(words);
}

export function parseSnippet(snippet: string): { unitUid?: string | null; indexWithinParent: number } {
  const element: Element | null = findElementInHtml(snippet, '.arc-matched-text');
  const [replacementIndex, unitUid] = [element?.getAttribute('data-replacement-index'), element?.getAttribute('data-unit-uid')];
  return { unitUid, indexWithinParent: !!replacementIndex ? parseInt(replacementIndex) : 0 };
}

// Todo: gg - remove it once ticket about Spellcheck [AER-8207] will be done
export function highlightOld(jquerySelector: string, word: Word) {
  if (!word) {
    return;
  }
  $('.editing-stage-page-inner ' + jquerySelector).removeClass('highlight');
  const el = $(`.editing-stage-page-inner ${jquerySelector}:eq(${word.globalIndex})`).addClass('highlight')[0];
  return el;
}

export function highlight(jquerySelector: string, unitUid: string, index: number) {
  $('.editing-stage-page-inner ' + jquerySelector).removeClass('highlight');
  return $(`.editing-stage-page-inner #_${unitUid} ${jquerySelector}:eq(${index})`).addClass('highlight')[0];
}

/*
    adds class .highlight to the word and scrolls it into view
 */
export function highlightAndScrollTo(jquerySelector: string, word: Word) {
  const el = highlightOld(jquerySelector, word);
  if (el) {
    Dom.scrollElementIntoView(el);
  }
}

export function wordTextMatches(word1: Word) {
  return function (word2: Word) {
    return word1.word === word2.word;
  };
}

export function wordIndexMatches(word1: Word) {
  return function (word2: Word) {
    return word1.globalIndex === word2.globalIndex;
  };
}

export function wordOccurrenceMatches(word1: Word) {
  return function (word2: Word) {
    return wordTextMatches(word1)(word2) && word1.globalOccurrence === word2.globalOccurrence;
  };
}

function _trackOccurrences(wordList: Word[]) {
  const seen = {};

  function incrementWord(wordText: string) {
    if (!seen[wordText]) {
      seen[wordText] = 1;
    } else {
      seen[wordText] = seen[wordText] + 1;
    }
    return seen[wordText];
  }

  for (const word of wordList) {
    word.globalOccurrence = incrementWord(word.word);
  }

  return wordList;
}
