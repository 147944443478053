import * as CONSTANTS from '../../keyIdentifier';
import { HackyStuffListener } from './HackyStuffListener';
import { CustomTinyEventTypes } from '../CustomTinyEventTypes';
import { CustomEditor } from '../EditorInstanceManager';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import { isCommandCtrlKey } from '../../keyIdentifier';

const detectCommonShortcuts = function (editor, e) {
  if (!e.arconicsEventStopped) {
    if (e.keyCode === CONSTANTS.KeyCode.s && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      editor.fire('ARC_SAVE' as CustomTinyEventTypes);
      CONSTANTS.nukePropagation(e);
    }

    if (e.keyCode === CONSTANTS.KeyCode.k && isCommandCtrlKey(e) && e.shiftKey) {
      const abbreviationDefinitionId = 'Abb';
      if (ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) {
        if (!EditorStore.getInsertableElementRule(abbreviationDefinitionId, 'insert_inside').disabled) {
          const abbreviationDefinition = ProjectDefinitionStore.getAllElementDefinitions().find(
            (def) => def.id === abbreviationDefinitionId
          );
          if (abbreviationDefinition && abbreviationDefinition.templateHtml) {
            const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
            tinyFacade.insertAdditionalElement(abbreviationDefinition);
          }
        }
      }

      CONSTANTS.nukePropagation(e);
    }

    if (e.keyCode === CONSTANTS.KeyCode.esc) {
      editor.fire('ARC_ESCAPE' as CustomTinyEventTypes);
      CONSTANTS.nukePropagation(e);
    }
  }
};

const detectShiftReturnAndInsertBreakKeys = function (editor, e) {
  if (!e.arconicsEventStopped) {
    if (e.keyCode === CONSTANTS.KeyCode.enter && e.shiftKey) {
      editor.execCommand('InsertLineBreak', false, e);
      CONSTANTS.nukePropagation(e);
    } else if (e.keyCode === CONSTANTS.KeyCode.enter) {
      editor.fire('ARC_SPLIT_UNIT' as CustomTinyEventTypes, { blur: true });
      CONSTANTS.nukePropagation(e);
    }
  }
};

const detectCmdReturnAndSaveExitKeys = function (editor, e) {
  if (!e.arconicsEventStopped) {
    if (e.keyCode === CONSTANTS.KeyCode.enter && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      editor.fire('ARC_SAVE' as CustomTinyEventTypes, { blur: true });
      CONSTANTS.nukePropagation(e);
      return true;
    }
  }
  return false;
};

const detectReturnAndSaveExitKey = function (editor, e) {
  if (!e.arconicsEventStopped) {
    if (e.keyCode === CONSTANTS.KeyCode.enter) {
      CONSTANTS.nukePropagation(e);

      // trigger custom save event on a return
      editor.fire('ARC_SAVE' as CustomTinyEventTypes, { blur: true });
      return false;
    }
  }
};

const detectUnitDeleteShortcut = function (editor: CustomEditor, e) {
  if (!e.arconicsEventStopped) {
    if (e.keyCode === CONSTANTS.KeyCode.backspace || e.keyCode === CONSTANTS.KeyCode.del) {
      const content = editor.getContent();

      if (content.length === 0 || content.replace(/[\u2060]/g, '').length === 0) {
        CONSTANTS.nukePropagation(e);
        editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });

        return false;
      }
    }
  }
};

export const CommonKeyListeners = {
  detectCommonShortcuts,

  detectShiftReturnAndInsertBreakKeys,

  detectCmdReturnAndSaveExitKeys,

  detectReturnAndSaveExitKey,

  // if empty and delete clicked trigger custom delete event
  detectUnitDeleteShortcut,

  // hacky stuff to overcome tinymce looser definition of our requirements!
  // applies to all units and their nested units
  detectUnitKeyRestriction: HackyStuffListener
};
