import * as React from 'react';
import { MenuAction } from './defaultMenuContents';
import { DefinitionToMenuPropsType } from '../../insert/MenuInsert';
import ContentDropdown from '../../insert/content/ContentDropdown';
import { elementDefinitionToMenuItem, unitDefinitionToMenuItem } from '../../insert/components/ConvertMenuItem';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../../flux/editor/EditorStore';
import { IElementDefinition, IUnitDefinition } from 'mm-types';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import * as _ from 'lodash';

interface Props {
  isConvertingEnabled: boolean;
  actions: MenuAction[];
  isActivelyEditing: boolean;
  editingNestedChange: null | INestedUnitFocusChangeEvent;
  selectedUnitsProfiles: IUnitDefinition[];
  contentMenuUnits: IUnitDefinition[];
  contentMenuElements: IElementDefinition[];
  isElementInsertable(
    elementId: string,
    insertAtLevel: number
  ): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null };
  isConvertable: (definitionId: string, validConverts: string[]) => boolean;
  handleDropdownClick: (e: { key: string; currentTarget: Element }) => void;
  syncOpenKeysStructure: (openKeys) => void;
  openKeysStructure: any[];
}

const ConvertActionItems = ({
  actions,
  isConvertingEnabled,
  isActivelyEditing,
  editingNestedChange,
  selectedUnitsProfiles,
  contentMenuUnits,
  contentMenuElements,
  isConvertable,
  isElementInsertable,
  handleDropdownClick,
  syncOpenKeysStructure,
  openKeysStructure
}: Props) => {
  const getConvertMenu = () => {
    const disabled: boolean = EditorStore.isMode('TOCMAN');
    const validConverts =
      isActivelyEditing && editingNestedChange
        ? editingNestedChange.focused
          ? getValidElementConverts(editingNestedChange)
          : []
        : getValidUnitConverts(selectedUnitsProfiles);
    const definitionToMenuProps: Partial<DefinitionToMenuPropsType> = {
      validConverts
    };
    return (
      <ContentDropdown
        action="convert"
        disabled={disabled}
        definitions={isActivelyEditing ? contentMenuElements : contentMenuUnits}
        definitionToMenuItem={
          isActivelyEditing ? elementDefinitionToMenuItem(isConvertable, isElementInsertable) : unitDefinitionToMenuItem(isConvertable)
        }
        definitionToMenuProps={definitionToMenuProps}
        disableContentMenu={false}
        handleDropdownClick={(e) => handleDropdownClick(e)}
        syncOpenKeysStructure={syncOpenKeysStructure}
        openKeysStructure={openKeysStructure}
      />
    );
  };

  const getValidElementConverts = (selectedUnitsProfiles): string[] => {
    const { targetElement, unitElement } = selectedUnitsProfiles.focused;
    const isFocusedMatchesSelected = targetElement && unitElement && targetElement.nodeName === unitElement.nodeName;
    return isFocusedMatchesSelected && _.has(selectedUnitsProfiles, 'focused.profile.validConvertsElements')
      ? selectedUnitsProfiles.focused.profile.validConvertsElements
      : [];
  };

  // Returns common to all selectedUnitsProfiles validConverts
  const getValidUnitConverts = (selectedUnitsProfiles: IUnitDefinition[]): string[] => {
    const validConverts: string[] = [];

    const addCount = (counts, convert) => {
      if (counts[convert]) {
        counts[convert] += 1;
      } else {
        counts[convert] = 1;
      }
      return counts;
    };

    if (selectedUnitsProfiles && selectedUnitsProfiles.length > 0 && !_.isUndefined(selectedUnitsProfiles[0])) {
      let counts = {};
      selectedUnitsProfiles.forEach((selectedUnitProfile) => {
        // add all valid unit types this profile's unit type can be converted to
        ProjectStore.getTypeValidConverts(selectedUnitProfile?.id).forEach((convert) => {
          counts = addCount(counts, convert);
        });
        // add itself as well
        counts = addCount(counts, selectedUnitProfile.id);
      });
      // Remove itself if only 1 profile selected
      if (selectedUnitsProfiles.length === 1) {
        counts[selectedUnitsProfiles[0].id!] = 0;
      }

      for (const countKey in counts) {
        if (counts[countKey] === selectedUnitsProfiles.length) {
          validConverts.push(countKey);
        }
      }
    }

    return validConverts;
  };

  return (
    <>
      {actions.map((menuItem, menuItemIndex) => {
        return (
          <li key={menuItemIndex} data-action={menuItem.action} className={`icon-menu-container ${isConvertingEnabled ? '' : 'disabled'}`}>
            <div className="content-menu-container">{getConvertMenu()}</div>
          </li>
        );
      })}
    </>
  );
};

export default ConvertActionItems;
