import { Popover, Paper } from 'material-ui';
import * as React from 'react';
import PopoverAnimationDefault from 'material-ui/Popover/PopoverAnimationDefault';
import { ThemeContext } from '../misc/ThemeProvider';

// there is a bug about initial popover position https://github.com/mui-org/material-ui/issues/8040
Popover.prototype.UNSAFE_componentWillMount = function () {
  this.renderLayer = () => {
    const {
      animated,
      animation,
      anchorEl,
      anchorOrigin,
      autoCloseWhenOffScreen,
      canAutoPosition,
      children,
      onRequestClose,
      style,
      targetOrigin,
      useLayerForClickAway,
      scrollableContainer,
      ...other
    } = this.props;

    let styleRoot = {
      ...style,
      opacity: this.state.setPlacement ? 1 : 0 // MADE EDIT HERE
    };

    if (!animated) {
      styleRoot = {
        position: 'fixed',
        zIndex: this.context.zIndex.popover,
        opacity: this.state.setPlacement ? 1 : 0
      };

      if (!this.state.open) {
        return null;
      }

      return (
        <Paper style={Object.assign(styleRoot, style)} {...other}>
          {children}
        </Paper>
      );
    }

    const Animation = animation || PopoverAnimationDefault;

    return (
      <Animation targetOrigin={targetOrigin} style={styleRoot} {...other} open={this.state.open && !this.state.closing}>
        {children}
      </Animation>
    );
  };
};

Popover.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
  if (nextProps.open === this.props.open) {
    return;
  }

  if (nextProps.open) {
    clearTimeout(this.timeout);
    this.timeout = null;
    this.anchorEl = nextProps.anchorEl || this.props.anchorEl;
    this.setState(
      {
        open: true,
        closing: false,
        setPlacement: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            setPlacement: true
          });
        });
      }
    );
  } else {
    if (nextProps.animated) {
      if (this.timeout !== null) {
        return;
      }
      this.setState({ closing: true });
      this.timeout = setTimeout(() => {
        this.setState(
          {
            open: false
          },
          () => {
            this.timeout = null;
          }
        );
      }, 500);
    } else {
      this.setState({
        open: false
      });
    }
  }
};

export default Popover;
// @ts-ignore
delete Popover.contextTypes;

Popover.contextType = ThemeContext;
