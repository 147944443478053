import _ from 'lodash';
import { IProject, IRevision } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import ActiveUserStore from '../../../../../../flux/common/ActiveUserStore';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';

export const getFileMenuShortcuts = (isDraft: boolean) => {
  const project: IProject | undefined = ProjectStore.getProject();
  if (!project) {
    return [];
  }

  const isDocumentSettingsVisibile = project.currentUserPermissions?.canRead && project.status !== 'TRASHED';
  const isPreviewEnabled = !isDraft ? false : project.currentUserPermissions?.canRead;

  return [
    { action: 'close', tooltip: 'Close', icon: 'close', enabled: true },
    {
      seperator: true,
      hidden: !isDocumentSettingsVisibile
    },
    {
      action: 'settings',
      tooltip: 'Document Settings',
      icon: 'settings',
      enabled: EditorStore.doesModeAllowMenuItem('DOC_SETTINGS'),
      hidden: !isDocumentSettingsVisibile
    },
    {
      seperator: true
    },
    {
      action: 'preview',
      tooltip: 'Preview',
      icon: 'icon-preview-in-viewer',
      enabled: isPreviewEnabled && EditorStore.doesModeAllowMenuItem('PREVIEW')
    },
    {
      seperator: true
    },
    {
      action: 'diff',
      tooltip: 'Diff',
      icon: 'diff',
      className: 'material-icons icon-diffing ',
      enabled: true,
      pressed: EditorStore.isMode('DIFF')
    }
  ];
};

export const getFileMenuInterimShortcuts = (revisions?: IRevision[] | null) => {
  const project: IProject | undefined = ProjectStore.getProject();
  if (!project) {
    return [];
  }

  const index = ProjectStore.getIndex()!;
  // if project is in normal edit mode, or is already locked for merge
  if (!ProjectStore.isReadOnly() || index.isLockedForMerge) {
    if (
      index.interimIndexToMergeUid &&
      (ActiveUserStore.isAdminOfSelectedProjectTeamspace(ProjectStore.getProject()!.workspaceUid) || ActiveUserStore.isAdmin())
    ) {
      // capable of being merged

      const interimRevision = _.find(revisions, { indexUid: index.interimIndexToMergeUid });
      const revisionName = interimRevision ? interimRevision.revision : '';

      if (index.isLockedForMerge) {
        if (index.isLockedForMergeByMe) {
          return [
            { seperator: true },
            {
              action: 'continueInterimMerge',
              tooltip: 'Continue Merge Interim ' + revisionName,
              icon: 'merge_type',
              enabled: true
            }
          ];
        }
      } else {
        return [
          { seperator: true },
          {
            action: 'interimMerge',
            tooltip: 'Merge Interim ' + revisionName,
            icon: 'merge_type',
            enabled: true
          }
        ];
      }
    }
  }
  return [];
};

export const getMediaLibShortcut = () => {
  return [
    { seperator: true },
    {
      dataQa: 'media-lib-menu-item',
      action: 'media-lib',
      tooltip: 'Media Library',
      icon: 'collections',
      enabled: true
    }
  ];
};
