import * as React from 'react';
import * as _ from 'lodash';
import TopSharedLibPanel from './TopSharedLibPanel';
import SearchSharedLibPanel from './SearchSharedLibPanel';
import TagsSharedLibPanel from './TagsSharedLibPanel';
import ProjectStore from '../../../../flux/editor/ProjectStore';

import ContentTypes from '../SharedContentLibTypes';
import { ITag } from 'mm-types';
import SmartContentLibraryStore, { SmartContentLibraryStoreEvent } from '../../../../flux/editor/SmartContentLibraryStore';

export type SearchParams = {
  contentType: string;
  contentTags: (ITag | string)[];
  projectDefinitionName?: string;
};

export const itemCodes = {
  NONE: 0,
  SEARCH: 1,
  TAG: 2
};

let _lastContentTagsSelected: ITag[] = [];
let _lastSearchTabSelection: number = itemCodes.NONE;

export type Props = {
  isLoading?: boolean;
  onSearch: (params?: Partial<SearchParams> | null) => void;
  onClose: () => void;
  contentType: string;
  contentTags: ITag[];
  showPreviousSearch: boolean;
  isReadOnly: boolean;
  isRegulationSelectionMode?: boolean;
};

export type State = {
  selection: { search: boolean; tags: boolean };
  contentType: string;
  contentTagsSelected: ITag[];
  sameProjectType: boolean;
};

export default class SearchSharedLibContainer extends React.Component<Props, State> {
  private smartContentLibOnSub: Function;

  constructor(props: Props) {
    super(props);

    let selection = { search: false, tags: false };
    let contentType = SmartContentLibraryStore.state.contentType;
    if (props.showPreviousSearch) {
      selection = this._getSelection(_lastSearchTabSelection);
      contentType = props.contentType;
    }

    this.state = {
      selection: selection,
      contentType: contentType,
      contentTagsSelected: props.showPreviousSearch ? _lastContentTagsSelected : [],
      sameProjectType: SmartContentLibraryStore.state.isProjectTypeToggled
    };
  }

  componentDidMount() {
    this.smartContentLibOnSub = SmartContentLibraryStore.listen(this.onSmartContentLibraryEvent, this);
  }

  componentWillUnmount() {
    this.smartContentLibOnSub();
  }

  onSmartContentLibraryEvent(e: SmartContentLibraryStoreEvent) {
    if (!e.state) {
      return;
    }
    if (e.type === 'projectTypeToggleChanged') {
      this.setState({ sameProjectType: e.state.isProjectTypeToggled }, () => {
        this._onSearch();
      });
    } else if (e.type === 'contentTypeChanged') {
      this._onContentTypeChange(e.state.contentType);
    } else if (e.type === 'actionChanged') {
      this._onTopPanelAction(e.state.action);
    }
  }

  _getSelection(item: number) {
    return {
      search: item === itemCodes.SEARCH,
      tags: item === itemCodes.TAG
    };
  }

  _close() {
    this._onTopPanelAction(itemCodes.NONE);

    this.setState({ contentTagsSelected: [] }, () => {
      _lastContentTagsSelected = [];
      this.props.onSearch();
    });
  }

  _onSearch(params?: Partial<SearchParams>) {
    let searchParams: Partial<SearchParams> = _.cloneDeep(params) ?? {};
    searchParams.contentType = this.state.contentType;
    searchParams.projectDefinitionName = this.state.sameProjectType ? ProjectStore.getProjectDefinitionType() : undefined;

    if (searchParams.contentTags) {
      const contentTags = searchParams?.contentTags as ITag[];
      this.setState({ contentTagsSelected: contentTags }, () => {
        _lastContentTagsSelected = contentTags;
        searchParams.contentTags = contentTags?.map((contentTag) => {
          return contentTag.value;
        });

        this.props.onSearch(searchParams);
      });
    } else {
      this.props.onSearch(searchParams);
    }
  }

  _onTopPanelAction(item) {
    if (_lastSearchTabSelection === item) {
      this._close();
    } else {
      _lastSearchTabSelection = item;
      this.setState({ selection: this._getSelection(item) });
    }
  }

  _onContentTypeChange(contentType) {
    const selection = contentType === 'regulation' ? { search: false, tags: false } : this.state.selection;
    this.setState({ selection: selection, contentType: contentType }, () => {
      this._onSearch();
    });
  }

  _isRegulationDisabled() {
    const project = ProjectStore.getProject();
    return project && project.workspaceType === 'personal' ? true : false;
  }

  render() {
    return (
      <div className="shared-search">
        <TopSharedLibPanel
          onClose={() => this.props.onClose()}
          contentType={this.state.contentType}
          isLoading={this.props.isLoading!}
          isReadOnly={this.props.isReadOnly}
          isRegulationDisabled={this._isRegulationDisabled()}
          isRegulationSelectionMode={SmartContentLibraryStore.state.contentType === ContentTypes.props.TYPE_REGULATIONS.key}
          isProjectTypeToggled={this.state.sameProjectType}
        />
        {this.state.selection.search ? (
          <SearchSharedLibPanel
            onSearch={(params) => this._onSearch(params)}
            onClose={() => this._close()}
            contentTags={this.props.contentTags}
            contentTagsSelected={this.state.contentTagsSelected}
          />
        ) : null}
        {this.state.selection.tags ? (
          <TagsSharedLibPanel
            onSearch={(params) => this._onSearch(params)}
            onClose={() => this._close()}
            contentTags={this.props.contentTags}
            contentTagsSelected={this.state.contentTagsSelected}
          />
        ) : null}
      </div>
    );
  }
}
