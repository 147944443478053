import * as React from 'react';
import * as _ from 'lodash';
import * as projectClient from '../../../../clients/project';
import MergeRevisionsStore from '../../../../flux/editor/MergeRevisionsStore';
import EditorStore from '../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../flux/editor/ProjectStore';
import TocStore from '../../../../flux/editor/TocStore';
import AppStateStore from '../../../../flux/common/AppStateStore';
import { transitionTo } from '../../../../utils/Navigation';
import UnitConceptStore from '../../../../flux/editor/UnitConceptStore';
import { IConcept, IIndexDefinition, IPDFExportOptions, IRevision, ITocNode, IVariant } from 'mm-types';
import NotificationsStore from '../../../../flux/events/NotificationsStore';
import asyncExportUtil, { FileExtension } from '../../../../utils/asyncExportUtil';
import RevisionStore from '../../../../flux/editor/RevisionStore';
import { ExportDialog } from './components/ExportDialog';
import ProjectDefinitionStore, { PROJECT_DEFINITION_TYPES } from '../../../../flux/common/ProjectDefinitionStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditorRouteParams } from '../../../ReactRoutes';
import OpenMenuElements from './components/menuComponents/OpenMenuElements';
import ExportMenuItems from './components/menuComponents/ExportMenuItems';
import Shortcuts from './components/menuComponents/Shortcuts';
import VariantMenuElements from './components/menuComponents/VariantMenuElements';
import MenuFileDialog from './components/menuComponents/MenuFileDialog';
import MenuFileDiffExportDialog from './components/menuComponents/MenuFileDiffExportDialog';
import MediaStore from '../../../../flux/editor/MediaStore';
import SelectLayerMode from './components/menuComponents/SelectLayerMode';

export type MenuFileOnSelectParams = { menu: string; action: string };

export interface MenuFileProps extends RouteComponentProps<EditorRouteParams> {
  revisions?: IRevision[] | null;
  onselected: (e: MenuFileOnSelectParams) => void;
}

export type State = {
  openKeys: string[];
  openKeysStructure: string[];
  showDialog: boolean;
  showExportDialog: boolean;
  showDiffExportDialog: boolean;
};

export type MenuActions = {
  seperator?: boolean;
  pressed?: boolean;
  icon?: string;
  action?: string;
  tooltip?: string;
  enabled?: boolean;
  className?: string;
  hidden?: boolean;
  dataQa?: string;
};

// noinspection JSMethodCanBeStatic
class MenuFile extends React.Component<MenuFileProps, State> {
  constructor(props: MenuFileProps) {
    super(props);

    this.state = {
      openKeys: [],
      openKeysStructure: [],
      showDialog: false,
      showExportDialog: false,
      showDiffExportDialog: false
    };
  }

  _handleSelected(e, action: string) {
    switch (action) {
      case 'close':
        this._goBackToList();
        break;
      case 'settings':
        this._openSettings();
        break;
      case 'interimMerge':
        this._startInterimMerge();
        break;
      case 'continueInterimMerge':
        this._continueInterimMerge();
        break;
      case 'preview':
        this._startPreview();
        break;
      case 'diff':
        this._showDiff();
        break;
      case 'layers':
        this._showLayers();
        break;
      case 'media-lib':
        MediaStore.triggerOpenMediaLib(true);
        break;
    }
  }

  _handleDropdownExportClick(e: { key: string }) {
    EditorStore.blurEditor(() => {
      switch (e.key) {
        case 'exportToPdf':
          if (EditorStore.isMode('DIFF')) {
            this.setState({ showDiffExportDialog: true });
          } else {
            this.setState({ showExportDialog: true });
          }
          break;
        case 'exportArcml':
          this._exportAsArcml();
          break;
        case 'exportEdb':
          this._exportAsEdb();
          break;
        case 'exportAirbus':
          this._exportAsAirbus();
          break;
        case 'exportHelp':
          this._exportAsHelp();
          break;
      }

      this.setState({
        openKeys: [],
        openKeysStructure: []
      });
    });
  }

  _syncOpenKeys(openKeys: string[]) {
    this.setState({
      openKeys: openKeys
    });
  }

  _syncOpenKeysStructure(openKeys: string[]) {
    this.setState({
      openKeysStructure: openKeys
    });
  }

  _canExport() {
    // if published
    if (!this.isDraft()) {
      // if variant doc a variant must be selected
      if (this.isIndexVariantFamilySet()) {
        return !!(ProjectStore.getSelectedVariantUid() && ProjectStore.getSelectedVariantUid() !== 'showAll');
      }
    }
    return true;
  }

  private isIndexVariantFamilySet(): boolean {
    return UnitConceptStore.getFamilyVariants().length > 0;
  }

  private getIndexVariants(): IConcept[] {
    return UnitConceptStore.getFamilyVariants();
  }

  private canExportEdb(): boolean {
    return ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition?.supportsEdbExport === true;
  }

  private canExportAirbus(): boolean {
    return ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition?.type === PROJECT_DEFINITION_TYPES.airbus;
  }

  private canExportHelp(): boolean {
    return ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition?.type === PROJECT_DEFINITION_TYPES.help;
  }

  _handleDropdownVariantClick(e: { key: string }) {
    EditorStore.blurEditor(() => {
      const variant: IVariant | undefined = _.find(UnitConceptStore.getFamilyVariants(), { uid: e.key });
      ProjectStore.setSelectedVariantUid(e.key);

      EditorStore.changeModeStart('VARIANT', {
        activateParams: {
          variant: variant ? variant : null
        }
      });

      this.setState({
        openKeys: [],
        openKeysStructure: []
      });
    });
  }

  _handleDropdownClick(e: { key: string }) {
    this.setState({
      openKeys: [],
      openKeysStructure: []
    });

    transitionTo('editor-edit', {
      projectUid: this.props.match.params.projectUid,
      indexUid: e.key
    });
  }

  _goBackToList() {
    EditorStore.blurEditor(() => {
      EditorStore.resetMode();
      if (AppStateStore.getPlaceOnDocumentClose() === 'workflow-tasks') {
        transitionTo(AppStateStore.getLastWorkflowTasksUrl());
      } else {
        transitionTo('teamspaces/' + AppStateStore.getLastAccessedWorkspaceUid(), { preserveSorting: true });
      }
    });
  }

  _openSettings() {
    EditorStore.blurEditor(() => {
      transitionTo('doc-general-settings', {
        projectUid: this.props.match.params.projectUid,
        indexUid: this.props.match.params.documentIndexUid
      });
    });
  }

  _exportAsArcml() {
    const { projectUid, documentIndexUid: indexUid } = this.props.match.params;
    asyncExportUtil.export(FileExtension.ZIP, [{ projectUid, indexUid }]);
  }

  _exportAsEdb() {
    const { projectUid, documentIndexUid: indexUid } = this.props.match.params;
    asyncExportUtil.export(FileExtension.EDB, [{ projectUid, indexUid }]);
  }

  _exportAsAirbus() {
    const { projectUid, documentIndexUid: indexUid } = this.props.match.params;
    asyncExportUtil.export(FileExtension.AIRBUS, [{ projectUid, indexUid }]);
  }

  _exportAsHelp() {
    const { projectUid, documentIndexUid: indexUid } = this.props.match.params;
    asyncExportUtil.export(FileExtension.HELP, [{ projectUid, indexUid }]);
  }

  isDraft() {
    const project = ProjectStore.getProject()!;
    if (project) {
      return (
        project.masterIndexUid === this.props.match.params.documentIndexUid ||
        project.interimIndexUid === this.props.match.params.documentIndexUid
      );
    }
    return false;
  }

  _airbusPdfPermissions(index: IIndexDefinition | null) {
    if (EditorStore.isMode('DIFF') && (index?.allowPdfDiffExport ?? true)) {
      return true;
    } else if (index?.allowPdfExport ?? true) {
      return false;
    }
    return false;
  }

  _startInterimMerge() {
    const project = ProjectStore.getProject();

    if (project) {
      MergeRevisionsStore.startMerge(project.uid, ProjectStore.getIndex()!, () => {
        // TODO deal with scenario where already active for this user or another user
      });
    }
  }

  _continueInterimMerge() {
    const project = ProjectStore.getProject();

    if (project) {
      MergeRevisionsStore.continueMerge(project.uid, ProjectStore.getIndex()!, () => {
        // TODO deal with scenario where already active for this user or another user
      });
    }
  }

  // preview dialog functions
  _onPreview() {
    this.setState({ showDialog: false }, () => {
      this._performPreview();
    });
  }

  _onCancel() {
    this.setState({ showDialog: false });
  }

  _performPreview() {
    this.props.onselected({
      menu: 'file',
      action: 'preview'
    });
  }

  _startPreview() {
    const currentlySelectedVariantMode = ProjectStore.getSelectedVariantUid();
    if (this.isIndexVariantFamilySet() && (!currentlySelectedVariantMode || currentlySelectedVariantMode === 'showAll')) {
      EditorStore.variantSelectionError('Please select a variant to preview');
      return;
    }

    this.setState({ showDialog: true });
  }

  _showDiff() {
    this.props.onselected({
      menu: 'file',
      action: 'diff'
    });
  }

  _showLayers() {
    this.props.onselected({
      menu: 'file',
      action: 'layers'
    });
  }

  _exportDiffPDF(options: IPDFExportOptions) {
    const project = ProjectStore.getProject()!;

    EditorStore.blurEditor(() => {
      this.setState({ showDiffExportDialog: false }, () => {
        let selection: null | ITocNode = null;

        if (options.exportRange === 'exportPdfSelection') {
          selection = TocStore.getSelectedItem();
        }

        NotificationsStore.startNotifProgress();

        // Always assume we're in the diff mode here.
        // Need to use a default diff index uid if currently no diff index uid is selected.
        let diffIndexUid: string | null = RevisionStore.getDiffIndexUid();

        if (!diffIndexUid) {
          const diffRevision: IRevision | null = RevisionStore.getDefaultDiffRevision();
          if (diffRevision) {
            diffIndexUid = diffRevision.indexUid;
          }
        }

        projectClient.exportPdf(
          project.uid,
          ProjectStore.getCurrentRevisionUid()!,
          Object.assign({}, options, {
            generator: 'prince',
            selectionUid: selection && selection.uid ? selection.uid : undefined,
            diffIndexUid: diffIndexUid,
            diffIndexTimestamp: RevisionStore.getDiffTimestampFormatted()
          })
        );
      });
    });
  }

  render() {
    const isDraft = this.isDraft();
    return (
      <nav className="page-body-header icon-header editor-menu editor-file-menu no-box-shadow">
        <div className="nav-wrapper">
          <ul className="icon-action-flatbuttons">
            <li className="icon-menu-container file-open-menu">
              <OpenMenuElements
                documentIndexUid={this.props.match.params.documentIndexUid}
                handleDropdownClick={(key) => this._handleDropdownClick(key)}
                syncOpenKeys={(openKeys) => this._syncOpenKeys(openKeys)}
                revisions={this.props.revisions}
                openKeys={this.state.openKeys}
              />
            </li>
            <li className="icon-menu-container file-export-menu">
              <ExportMenuItems
                isDraft={() => this.isDraft()}
                canExport={() => this._canExport()}
                isIndexVariantFamilySet={() => this.isIndexVariantFamilySet()}
                airbusPdfPermissions={(index) => this._airbusPdfPermissions(index)}
                handleDropdownExportClick={(e) => this._handleDropdownExportClick(e)}
                syncOpenKeysStructure={(openKeys) => this._syncOpenKeysStructure(openKeys)}
                openKeysStructure={this.state.openKeysStructure}
                canExportEdb={() => this.canExportEdb()}
                canExportAirbus={() => this.canExportAirbus()}
                canExportHelp={() => this.canExportHelp()}
                revisions={this.props.revisions}
              />
            </li>
            <li className="seperator seperator-nomargin" />
            <Shortcuts
              isDraft={() => this.isDraft()}
              handleSelected={(e, action) => this._handleSelected(e, action)}
              revisions={this.props.revisions}
              type="pre"
            />
            <SelectLayerMode />
            <li className="seperator seperator-nomargin" />
            <li className="icon-menu-container file-variant-menu">
              <VariantMenuElements
                handleDropdownVariantClick={(e) => this._handleDropdownVariantClick(e)}
                syncOpenKeysStructure={(openKeys) => this._syncOpenKeysStructure(openKeys)}
                openKeysStructure={this.state.openKeysStructure}
                isIndexVariantFamilySet={this.isIndexVariantFamilySet}
                getIndexVariants={this.getIndexVariants}
              />
            </li>
            <Shortcuts handleSelected={(e, action) => this._handleSelected(e, action)} type="post" />
          </ul>
        </div>
        <ExportDialog
          show={this.state.showExportDialog}
          isDraft={isDraft}
          revisions={this.props.revisions}
          projectUid={this.props.match.params.projectUid}
          documentIndexUid={this.props.match.params.documentIndexUid}
          onRequestClose={() => this.setState({ showExportDialog: false })}
          variants={this.getIndexVariants()}
        />
        <MenuFileDialog showDialog={this.state.showDialog} onPreview={() => this._onPreview()} onCancel={() => this._onCancel()} />
        <MenuFileDiffExportDialog
          showDiffExportDialog={this.state.showDiffExportDialog}
          getIndexVariants={this.getIndexVariants}
          onClose={() => this.setState({ showDiffExportDialog: false })}
          exportDiffPDF={(options) => this._exportDiffPDF(options)}
        />
      </nav>
    );
  }
}

export default withRouter(MenuFile);
