import { mm } from './base-clients';
import { ISettings } from 'mm-types';
import axios, { Canceler } from 'axios';

const CancelToken = axios.CancelToken;
let cancel: Canceler | null;

export async function get(projectUid: string, indexUid: string) {
  if (cancel) {
    cancel();
  }
  const response = await mm.get<ISettings>(`/projects/${projectUid}/indexes/${indexUid}/settings`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    })
  });
  cancel = null;
  const settings = response.data;
  parse(settings);
  return settings;
}

export async function update(projectUid: string, indexUid: string, token: Partial<ISettings>) {
  const response = await mm.put<ISettings>(`/projects/${projectUid}/indexes/${indexUid}/settings`, token);
  const settings = response.data;
  parse(settings);
  return settings;
}

function parse(settings: ISettings) {
  settings.revisionHighlightsEmailGroup.values = settings.revisionHighlightsEmailGroup.values || [];
  return settings;
}

export const settingsClient = {
  get,
  update
};
