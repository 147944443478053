import React from 'react';
import { INotificationListItemProps } from './INotificationListItemProps';
import IconButton from 'material-ui/IconButton';
import LinearProgress from 'material-ui/LinearProgress';

const PdfPublication = ({ notif, getName, getVariantName, handleNotifAction, refreshing, setRefreshing }: INotificationListItemProps) => {
  return (
    <div className="notif-item-container notif-item-pdfexport">
      <div className="left-side">
        <span className={'icon icon-pdf-export-' + (notif.isError ? 'error' : notif.isReady ? 'success' : 'progress')} />
      </div>

      <div className="middle-side">
        {!notif.processingStatus && (
          <div className="popup-title-container">
            <h1>{notif.isError ? 'Publish Error' : 'Published'}</h1>
            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {notif.processingStatus && (
          <div className="popup-title-container">
            <h1>Publishing PDF</h1>
            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            {notif.isReady && (
              <span className="info">
                PDF {notif.index.revisionNumber ? 'for revision ' + notif.index.revisionNumber : ''} published successfully
              </span>
            )}
            {notif.isError && (
              <span className="info">
                PDF {notif.index.revisionNumber ? 'for revision ' + notif.index.revisionNumber : ''} publish failed
                {notif.retryable ? ', retry?' : '.'}
              </span>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            <span className="info">{notif.processingStatus.friendlyName}</span>
          </div>
        )}
      </div>

      <div className="right-side">
        <div className="popup-date-container">
          <h5>{notif.receivedFormatAgo}</h5>
        </div>

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            <span
              className={'notif-status-toggle'}
              title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
              onClick={() => {
                if (handleNotifAction) {
                  handleNotifAction(notif, 'toggleReadUnread');
                }
              }}
            />

            {notif.isError && notif.retryable && (
              <i
                className="material-icons"
                onClick={() => {
                  if (refreshing) {
                    return;
                  }
                  if (setRefreshing && handleNotifAction) {
                    setRefreshing(true);
                    handleNotifAction(notif, 'retry', false, () => setRefreshing(false));
                  }
                }}
              >
                refresh
              </i>
            )}

            {notif.isReady && (
              <IconButton
                onClick={() => {
                  if (handleNotifAction) {
                    handleNotifAction(notif, 'download', true);
                  }
                }}
                className="item-action"
                iconClassName="material-icons"
                tooltipPosition="bottom-center"
                tooltipStyles={{ zIndex: 9999, transform: 'translateX(-8px)' }}
                tooltip="Download"
                data-qa="Download"
                data-mediauid={notif.media!.uid}
              >
                file_download
              </IconButton>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            {notif.status === 'IN_PROGRESS' && <LinearProgress mode="determinate" max={5} min={0} value={notif.processingStatus.step} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfPublication;
