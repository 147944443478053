import React, { CSSProperties } from 'react';
import { CircularProgress } from 'material-ui';

const defaultStyles: { [prop: string]: CSSProperties } = {
  loading: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffffd4'
  },
  loadingText: { fontSize: '1.5rem', margin: '10px 0' }
};

const LoadingSpinner = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <div style={{ ...defaultStyles.loading, ...style }}>
      <CircularProgress mode="indeterminate" size={119} />
    </div>
  );
};

export default LoadingSpinner;
