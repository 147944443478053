import * as _ from 'lodash';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import loadCustomTinyCommands from './customTinyCommands';
import TinyFacade from './TinyFacade';
import EditorStore from '../../../../flux/editor/EditorStore';
import Log from '../../../../utils/Log';
import CustomTinyEvent, { CustomTinyEventTypes } from './CustomTinyEventTypes';
import { Editor, EditorManager, Events, Shortcuts } from 'tinymce';
import appStore from '../../../../appStore';

import {
  EditBlurData,
  IDocUnitProfile,
  IEditingNestedChangeDOM,
  IUnit,
  IUnitDetails,
  TinyMouseOverEvent,
  TinyNodeChangeEvent,
  TinyScrollIntoViewEvent
} from 'mm-types';
import { UnitTypes } from '../units/UnitTypes';
import * as UnitKeyBehaviors from './key_listeners/UnitKeyBehaviors';
import { isLineBreakAllowed, ZERO_LENGTH_WORD_JOINER } from './key_listeners/keyBehaviourUtils';

import { UnitUtils } from '../units/UnitUtils';
import { getFamily, replaceTemplateDataNidsWithNewOnes, withContentNotEditableAttribute } from './TinyFacadeHelpers';
import { Dom } from './DomUtil';
import BodyCSSClassUtil from '../../../../utils/BodyCSSClassUtil';
import { nukePropagation, shortcutMap } from '../keyIdentifier';
import { UnitElementFocusUtil } from './UnitElementFocusUtil';
import { showSystemAlert } from '../../../misc/SystemAlert/thunks';
import { actionToDefinition } from '../units/unit/inlineElementsLookup';
import { DomAssertions } from './DomAssertionsUtil';
import { elementAllowedByWhitelist } from '../../menus/insert/utils/InsertRulesFactory';
import { nncContentHasChanged } from '../units/NNCIndexMetadata/utils';
import getTableElement = Dom.getTableElement;
import CommandEvent = Events.CommandEvent;

declare const tinymce: EditorManager;
export type CustomEditor = Editor & {
  arconicsUnitType: string;
  bodyElement?: HTMLBodyElement;
  lastRng: Range;
  shortcuts: Shortcuts;
};

const observeNewChildren = (node: HTMLElement, callback: Function): { disconnect: Function } => {
  const config = {
    attributes: false,
    characterData: false,
    characterDataOldValue: false,
    childList: true, // only care about children
    subtree: false
  };

  const observerCallback: MutationCallback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        callback();
      }
    }
  };

  const observer = new MutationObserver(observerCallback);

  observer.observe(node, config);

  return {
    disconnect: () => {
      observer.disconnect();
    }
  };
};

/*
 * Manages the lifecycle of the tinymce instance and where it is applied in the DOM
 * Exposes methods which generally get applied to the appropriate instance
 */

export default class EditorInstanceManager {
  private _activeEditor: null | CustomEditor;
  private _activeEditorFacade: null | TinyFacade;
  private _isInitializing: boolean;
  private _currentEditingNode: null | HTMLElement;
  private _currentEditingRange: null | Range;
  private _currentNodeObserver: null | MutationObserver[];

  // note: abstracted: not tinymce: in our editor we may be "focused" even if tinymce isn't
  private _isEditorFocused: boolean;
  private _isSilentReFocus: boolean;
  private _isFocusedTextSelected: boolean;

  // indicates actual focus/blur on tinymce editor (as opposed to our looser focus/blur concept)
  private _isNativeFocused: boolean;

  private _onEndEventDUQueued: EditBlurData | null;
  private _observer: Function;
  private activeEditorContainer: HTMLDivElement | null = null;
  private isResizeOrScrollEvent = false;
  private initElementUid: string | null = null;
  private _userTriggeredAction: boolean;

  constructor() {
    // tinymce.activeEditor
    this._activeEditor = null;
    this._activeEditorFacade = null;

    this._isInitializing = false;

    this._currentEditingNode = null;
    this._currentEditingRange = null;
    this._currentNodeObserver = null;

    // note: abstracted: not tinymce: in our editor we may be "focused" even if tinymce isn't
    this._isEditorFocused = false;
    this._isSilentReFocus = false;
    this._isFocusedTextSelected = false;

    // indicates actual focus/blur on tinymce editor (as opposed to our looser focus/blur concept)
    this._isNativeFocused = false;
    this._userTriggeredAction = false;
  }

  getIsNativeFocused() {
    return this._isNativeFocused;
  }

  // triggers onCreateNew if a new tinymce instance is actually created (i.e. it may already be instantiated, or another one may be focused in which case editing must blur (end) first)
  create(unit: IUnit, elementUid: string | null = null, onCreateNew) {
    if (this.isFocused()) {
      if (!this._isAlreadyInitialized(unit.uid)) {
        this.blur();
        this.destroyActiveEditor();
      } else {
        console.log('create: attempted to launch an already initialized editor instance');
        setTimeout(() => {
          if (EditorStore.getSelectedUnit()!.type === 'table') {
            this._activeEditorFacade!.clearSelection();
          }

          if (!this._isNativeFocused) {
            // place cursor back to where it was (this affects clicking on outer of unit body where tinymce may not be initialized)
            this.silentReFocus();
          }

          const editSelection = tinymce.activeEditor ? tinymce.activeEditor.selection : null;
          // overcome tinymce bug when you click on selected text, it doesn't fire that text is no longer selected
          if (editSelection && !editSelection.getContent().length && this._isFocusedTextSelected) {
            this._triggerTextSelected(false);
          }
          // overcome tinymce bug if mouse up outside the immediate container after mouse drag selection
          else if (editSelection && editSelection.getContent().length && !this._isFocusedTextSelected) {
            this._currentEditingRange = editSelection.getRng(false);
            this._triggerTextSelected(true, editSelection.getContent());
          }
        }, 1);
      }
    } else {
      if (!EditorStore.isBusy()) {
        this._isInitializing = true;

        onCreateNew(() => {
          this._instantiate(elementUid);
        });
      }
    }
  }

  isInitializing() {
    return this._isInitializing;
  }

  destroyActiveEditor() {
    if (this._activeEditor) {
      this.destroy();

      this._isFocusedTextSelected = false;
      this._activeEditor = null;
      this._isNativeFocused = false;
      this._isEditorFocused = false;
      this._isSilentReFocus = false;
      this._userTriggeredAction = false;
    }
  }

  isInstantiated() {
    return this._activeEditor !== null;
  }

  isFocused() {
    return this._activeEditor !== null && this._isEditorFocused;
  }

  isFocusedTextSelected() {
    return this._isFocusedTextSelected;
  }

  setCursorLocation(node: HTMLElement | null, enhanceNode?: boolean) {
    if (this.isFocused() && this._activeEditorFacade) {
      this._isSilentReFocus = true; // this causes an editor internal focus, so set as silent and it will get ignored
      this._activeEditorFacade.setCursorLocation(node, enhanceNode);
      this._currentEditingNode = node;
    }
  }

  selectEditorNode(node: HTMLElement | null) {
    if (this.isFocused()) {
      // node is null: place cursor at tinymce instance root
      node = node === null ? (tinymce.activeEditor.getElement() as HTMLElement) : node;
      this._isSilentReFocus = true; // this causes an editor internal focus, so set as silent and it will get ignored

      this._activeEditor?.selection.select(node);
      this._currentEditingRange = this._activeEditor?.selection.getRng(false) ?? null;
      this._currentEditingNode = node;
      this._activeEditor?.nodeChanged();
    }
  }

  // sometimes cursor gets lost on a tinymce blur, this just repositions it back to it original location
  setCursorLocationToCurrent() {
    if (this._currentEditingNode) {
      if (this._currentEditingNode.nodeName === 'BR') {
        // sometimes cursor navigates to BR: ensure not to set as selected as will create errors in tinymce mce-insert
        this.setCursorLocation(this._currentEditingNode.parentNode as any, undefined);
      }
    }
  }

  // focus without triggering a focus event on an existing instance (like we were never away :))
  silentReFocus(options?: { preventCursorReposition?: boolean; force?: boolean }) {
    options = options ? options : { preventCursorReposition: false, force: false };

    if (this.isFocused() || options.force) {
      this._userTriggeredAction = true;
      // force focus/blur listeners to make exceptions to the normal way of things
      // as blur and refocus happened due to external clicks
      this._isSilentReFocus = true;

      // focus, and enact the command
      this._activeEditor?.focus(false);

      if (!options.preventCursorReposition && this._currentEditingRange) {
        this._activeEditor?.selection.setRng(this._currentEditingRange, false);
      }
    }
  }

  // to determine if a change occurred, we compare editor outputs (not original api based html)
  isDirty(content) {
    const isDirty = this._getSelectedUnitElement()?.parent().data('sanitizedHTML') !== content;
    return isDirty && this._userTriggeredAction;
  }

  // called to get focused editing contents (usually just before saving)
  getEditingDetails(): EditBlurData {
    const selectedUnit = EditorStore.getSelectedUnit()!;
    const content = this._getContent();

    return {
      isDirty: this.isDirty(content),
      type: selectedUnit.definitionId as UnitTypes,
      uid: selectedUnit.uid,
      html: content
    };
  }

  getActiveEditorInstance(): null | CustomEditor {
    return this._activeEditor;
  }

  getActiveEditorFacade() {
    this._userTriggeredAction = true;
    return this._activeEditorFacade;
  }

  // inline inject original html into dom element:
  // this cannot be done through react as it will only modify DOM if there is a diff, and there is no diff as we've been changing it with TinyMCE under the hood!
  // note: when u focus (edit) this unit again, the init on editor will re-set sanitized data, so all is in sync

  resetSelectedUnitDOMContents() {
    if (this._getSelectedUnitElement()?.[0]) {
      $((this._getSelectedUnitElement() as JQuery<HTMLElement>)[0]).html(EditorStore.getSelectedUnit()!.html);
    }
  }

  // note: blur blurs the editor providing sanitized editor contents (indicating if these contents are changed)
  blur(
    options?: {
      ignoreChanges?: boolean;
      ignoreChangesAndRevert?: boolean;
      applyHTMLContentConvertor?: (unit: string, html: string) => string;
    },
    blurCompleteCallback?: () => void
  ) {
    const ignoreAndRevertNNCChanges =
      EditorStore.getSelectedUnit()?.definitionId === 'non-normal-checklist-level1' && this._getSelectedUnitElement()
        ? !nncContentHasChanged((this._getSelectedUnitElement() as JQuery<HTMLElement>).parent().data('sanitizedHTML'), this._getContent())
        : false;

    options = options
      ? options
      : {
          ignoreChanges: false,
          ignoreChangesAndRevert: ignoreAndRevertNNCChanges
        };

    if (!!EditorStore.areDataAttributesValidationErrors()) {
      const errorsToString = Object.keys(EditorStore.getDataAttributesValidationErrors())
        .map((key) => {
          return EditorStore.getDataAttributesValidationErrors()
            [key].map((da) => da.displayName)
            .join(', ');
        })
        .join(', ');
      appStore.dispatch<any>(
        showSystemAlert({
          errorTitle: 'Validation Errors',
          errorMessage: 'Please resolve errors on the following properties: ' + errorsToString
        })
      );
    } else if (this.isFocused() && this._getSelectedUnitElement()) {
      // also should this not be performed POST a successful SAVE operation??
      // remove any leftover tinymce DOM it failed to clear (table resize selectors, noneditable mirror dom, etc)
      $((this._getSelectedUnitElement() as JQuery<HTMLElement>)[0])
        .find('*[data-mce-bogus]:empty, .mce-offscreen-selection')
        .remove();

      this._currentEditingNode = null;
      this._currentEditingRange = null;
      this._isSilentReFocus = false;
      this._onActiveEditorBlur();

      if (options.ignoreChanges || options.ignoreChangesAndRevert) {
        // trigger blur, ensuring any editor mods aren't flagged
        if (this._onEndEventDUQueued) {
          this._onEndEventDUQueued.isDirty = false;
        }

        this._triggerEditorBlur(blurCompleteCallback, options);

        if (options.ignoreChangesAndRevert && EditorStore.getSelectedUnit()) {
          this.resetSelectedUnitDOMContents();
        }
      } else {
        this._triggerEditorBlur(blurCompleteCallback, options);
      }
    }
  }

  // call to abandon all editing (note: must not contain any async as called during sync process when editor url changed)
  abandon() {
    if (this.isFocused()) {
      this.blur();
      this.destroyActiveEditor();
    }
  }

  _getContent(trustContent?: boolean): string {
    return this._activeEditorFacade?.getContent(trustContent) ?? '';
  }

  _isAlreadyInitialized(editorId) {
    return this._activeEditor && this._activeEditor.id.indexOf(editorId) !== -1;
  }

  _setFocused(focused: boolean) {
    // note: why not do below through state you say: performance: editor should "render" only when totally necessary
    this._isEditorFocused = focused;
    if (this._activeEditorFacade) {
      this._activeEditorFacade.setFocused(focused);
    }
  }

  _triggerEditorBlur(
    blurCompleteCallback?: () => void,
    blurOptions?: { applyHTMLContentConvertor?: (type: string, html: string) => string }
  ) {
    this._setFocused(false);
    this.destroyActiveEditor(); // ensure to destroy before blurCompleteCallback

    if (this._onEndEventDUQueued) {
      if (blurOptions && blurOptions.applyHTMLContentConvertor) {
        this._onEndEventDUQueued.isDirty = true;
        this._onEndEventDUQueued.html = blurOptions.applyHTMLContentConvertor(
          this._onEndEventDUQueued.type!,
          this._onEndEventDUQueued.html!
        );
      }

      EditorStore.editBlur(this._onEndEventDUQueued, blurCompleteCallback);
      this._onEndEventDUQueued = null; // ensures any non immediate call to triggerOnEditBlur will not re-trigger onEditBlur
    } else {
      if (blurCompleteCallback) {
        blurCompleteCallback();
      }
    }
  }

  // returns the real DOM container representing the DU element the editor has selected on (or inside)
  _getSelectedUnitElement(selectedUnit = EditorStore.getSelectedUnit()!): JQuery<HTMLElement> | undefined {
    return this._activeEditorFacade?.getSelectedUnitElement(selectedUnit);
  }

  _isSelectedVisibleInViewport() {
    const jElm = this._getSelectedUnitElement();
    let editorEl: HTMLElement;

    if (typeof $ === 'function' && jElm instanceof $) {
      editorEl = jElm[0];
    }

    const rect = editorEl!.getBoundingClientRect();

    // we only care about vertical for the moment
    return rect.top < window.pageYOffset + window.innerHeight && rect.top + rect.height > window.pageYOffset;
  }

  _triggerTextSelected(isSelected: boolean, selectedText?: string) {
    if (isSelected) {
      // events get triggered for every character in some cases => this increases performance
      if (!this._isFocusedTextSelected) {
        this._isFocusedTextSelected = true;
        EditorStore.editTextSelected({ unit: EditorStore.getSelectedUnit(), text: selectedText });
      }
    } else {
      if (this._isFocusedTextSelected) {
        EditorStore.editTextSelectedEnd({ unit: EditorStore.getSelectedUnit() });
        this._isFocusedTextSelected = false;
      }
    }
  }

  _instantiate(elementUid: string | null) {
    this.initElementUid = elementUid;
    this.destroyActiveEditor(); // only possible to have existing tiny if it didn't cleanup due to an exception - but always do just in case

    const selectedUnit = EditorStore.getSelectedUnit()!;
    const duProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(selectedUnit.definitionId);

    const isDiffModeOff = selectedUnit.type !== 'diff' && selectedUnit.definitionId !== 'diff';

    if (duProfile?.editor && !EditorStore.isReadOnly() && isDiffModeOff) {
      this._observer = this._getMutationObserver();

      const docUnitUid = selectedUnit.uid;
      const tinyInstanceDOMId = '_' + docUnitUid + '-tiny-instance';
      let $tgtEditorElement: JQuery<HTMLElement>;
      try {
        $tgtEditorElement = this.getEditTarget(selectedUnit, duProfile);
      } catch (error) {
        Log.error(error);
        Log.warn(
          `EditorInstanceManager: NOT instantiated editor for unitUid: ${selectedUnit.uid} , type: ${selectedUnit.definitionId}, ${
            duProfile.type || ''
          }`
        );
        return;
      }

      $tgtEditorElement.attr('id', tinyInstanceDOMId).addClass('editor-instance-container'); // add id and useful reference class
      this._activeEditor = new (tinymce as any).Editor(tinyInstanceDOMId, duProfile.editor, (tinymce as any).EditorManager);
      if (this._activeEditor) {
        const type = duProfile?.type ? duProfile.type : selectedUnit.type;
        this._activeEditor.arconicsUnitType = type.toLowerCase();
        this._activeEditorFacade = new TinyFacade(this._activeEditor, EditorStore, ProjectDefinitionStore, UnitKeyBehaviors);
        loadCustomTinyCommands(this._activeEditor, this._activeEditorFacade);

        Log.info(
          `EditorInstanceManager: instantiated editor for unitUid: ${selectedUnit.uid} , type: ${selectedUnit.definitionId}, ${
            duProfile.type || ''
          }`
        );

        // listen for important events, and make available to any parent components
        this._listenToActiveEditorEvents();

        this._activeEditor.render();
      }
    } else {
      console.log('EditorInstanceManager: there is no editor to instantiate for this unit, or readonly mode');
    }

    this._isInitializing = false;
  }

  private getEditTarget(selectedUnit: IUnit, profile: IDocUnitProfile): JQuery<HTMLElement> {
    // make sure only first (top most) .edit-target is used to instantiate editor
    // multiple nested elements might have .edit-target, that's why we need to pick top most only
    const $target = $('#_' + selectedUnit.uid).find(
      profile.targetEditContainerSelector ? profile.targetEditContainerSelector : '.edit-target'
    );

    if ($target.length === 0) {
      throw `No Editor Target Element. Verify "duProfile.targetEditContainerSelector" variable for the type: ${selectedUnit.definitionId}`;
    }

    return $($target[0]);
  }

  destroy() {
    if (this._activeEditor) {
      try {
        this._activeEditor.off('blur');
        this._activeEditor.off('focus');
        this._activeEditor.off('BeforeExecCommand');
        this._activeEditor.off('ExecCommand');
        this._activeEditor.off('NodeChange');
        this._activeEditor.off('keyup');
        this._activeEditor.off('contextMenu');
        this._activeEditor.off('NewBlock');
        this._activeEditor.off('NewCell');
        this._activeEditor.off('NewRow');
        this._activeEditor.off('init');
        this._activeEditor.off('dragstart');
        this._activeEditor.off('drop');
        this._activeEditor.off('scrollIntoView');
        this._activeEditor.off('click');
        this._activeEditor.off('mouseover');
        this.activeEditorContainer?.removeEventListener('scroll', this.setResizeOrScrollEvent);
        this.activeEditorContainer?.removeEventListener('resize', this.setResizeOrScrollEvent);
        window.removeEventListener('resize', this.setResizeOrScrollEvent);
        CustomTinyEvent.forEach((event) => {
          this._activeEditor?.off(event);
        });
        this._cleanObservers();
        this._activeEditor.destroy(false);
      } catch (e) {
        console.log('tinymce destroy exception'); // note: keep as console.log as tiny can go rogue in severe error conditions
      }
    }
  }

  _listenToActiveEditorEvents() {
    if (this._activeEditor) {
      this.activeEditorContainer = document.querySelector('.editing-stage-page-inner');
      this._activeEditor.on('focus', () => this._onActiveEditorFocus());
      this._activeEditor.on('blur', () => this._onActiveEditorBlur());
      this._activeEditor.on('BeforeExecCommand', (e) => this._onActiveEditorBeforeExecCommand(e));
      this._activeEditor.on('ExecCommand', (e) => this._onActiveEditorExecCommand(e));
      this._activeEditor.on('SetAttrib', (e) => this._onActiveEditorSetAttrib(e));
      this._activeEditor.on('NodeChange', (e) => this._onActiveEditorNodeChange(e));
      // remember cursor position while typing (typing not detected by node change event)
      this._activeEditor.on('keyup', () => this._onActiveEditorKeyUp());
      this._activeEditor.on('paste', this.onPaste);
      this._activeEditor.on('PastePreProcess', (e) => this.onPastePreProcess(e));
      this._activeEditor.on('contextMenu', (e) => this._onActiveEditorContextMenu(e));
      this._activeEditor.on('dragstart drop', (e) => e.preventDefault());
      this._activeEditor.on('NewBlock NewCell NewRow', (e) => this._onNewStructureAdded(e));
      // manually focus on newly created TinyMCE component: but only when editor is initialized
      this._activeEditor.on('init', () => this._onActiveEditorInit());
      this._activeEditor.on('scrollIntoView', (e: TinyScrollIntoViewEvent) => e.preventDefault());
      // custom arconics event triggered inside the editor for certain du types
      // for now: cmd-s will save then blur
      this._activeEditor.on('ARC_SAVE' as CustomTinyEventTypes, (e) => this.blur());
      this._activeEditor.on('ARC_ESCAPE' as CustomTinyEventTypes, () => this.blur({ ignoreChangesAndRevert: true }));
      this._activeEditor.on('ARC_DELETE' as CustomTinyEventTypes, () => EditorStore.deleteUnit(EditorStore.getSelectedUnit()!));
      // trigger new unit creation *after* current focused unit has blurred (and possibly saved)
      this._activeEditor.on('ARC_SPLIT_UNIT' as CustomTinyEventTypes, (e) => EditorStore.splitUnitFromKeypress());
      this._activeEditor.on('ARC_MERGE_TO_PREVIOUS' as CustomTinyEventTypes, (e) => EditorStore.mergeSelectedUnitToPrevious());
      this._activeEditor.on('mouseover', (e: TinyMouseOverEvent) => this.onMouseOver(e));
      window.addEventListener('resize', this.setResizeOrScrollEvent);
      this.activeEditorContainer?.addEventListener('scroll', this.setResizeOrScrollEvent);
      this.activeEditorContainer?.addEventListener('resize', this.setResizeOrScrollEvent);
    }
  }

  private setResizeOrScrollEvent = (e: Event) => {
    const className = `event-${e.type}`;
    this.isResizeOrScrollEvent = true;
    BodyCSSClassUtil.addClass(className);
    this.removeResizeOrScrollEvent(className);
  };

  private removeResizeOrScrollEvent = _.debounce((className: string) => {
    this.isResizeOrScrollEvent = false;
    BodyCSSClassUtil.removeClass(className);
  }, 500);

  onMouseOver(e: TinyMouseOverEvent) {
    const tableElement = getTableElement(e.target, this._activeEditor || undefined);
    if (this._activeEditor && this.isResizeOrScrollEvent && !!tableElement) {
      e.stopImmediatePropagation();
    }
  }

  _onActiveEditorFocus() {
    this._isNativeFocused = true;

    if (!this._isSilentReFocus) {
      EditorStore.editFocus(EditorStore.getSelectedUnit()!, this._isEditorFocused);
    }
    UnitUtils.manageEcamFocus(EditorStore.getSelectedUnit().uid);
    this._isSilentReFocus = false;
    this._setFocused(true); // ensure to do this last, order is important
  }

  _onActiveEditorBlur() {
    this._isNativeFocused = false;
    this._onEndEventDUQueued = this.getEditingDetails();

    if (
      this._currentEditingNode &&
      this._currentEditingNode.parentNode &&
      (this._currentEditingNode.nodeName === 'TD' ||
        (this._currentEditingNode.nodeName === 'BR' && this._currentEditingNode.parentNode.nodeName === 'TD'))
    ) {
      const nodeEl = $(this._currentEditingNode).closest('TD');
      if (!$('.upload-image-dialog').is(':visible') && nodeEl && !nodeEl[0].hasAttribute('data-mce-selected')) {
        nodeEl[0].setAttribute('data-mce-selected', '1');
      }
    }
  }

  blockShortcuts = (e: CommandEvent & TinyNodeChangeEvent & { value: Events.Event }) => {
    // get the command executed
    const command: string = e.command.toLowerCase();
    if (!!e.command && Object.keys(shortcutMap).includes(command)) {
      if (EditorStore.getInsertableElementRule(actionToDefinition[command], 'insert_inside').disabled) {
        nukePropagation(e);
      }
    }
  };

  _onActiveEditorBeforeExecCommand(e: CommandEvent & TinyNodeChangeEvent & { value: Events.Event }) {
    this._userTriggeredAction = true;
    this.blockShortcuts(e);
    if (e.command === 'InsertLineBreak') {
      nukePropagation(e);
      const elmDef = EditorStore.getFocusedElementDefinition();
      const brAsNewElement = elementAllowedByWhitelist('LineBreak', elmDef);
      isLineBreakAllowed() && this._activeEditorFacade?.insertBreakLine(brAsNewElement ? new Map([['data-nid', '']]) : undefined);
    }
    if (e.command === 'SelectAll') {
      nukePropagation(e);
      const range = tinymce.activeEditor.dom.createRng();
      const editorBody: any = tinymce.activeEditor.getBody();
      range.selectNodeContents(editorBody);
      tinymce.activeEditor.selection.setRng(range);
      tinymce.activeEditor.focus(false); // refocus to obtain a proper element (it runs tinmce normalizeSelection method under the hood - which is what we want)
      this._onSelectionChanged(e);
    }
  }

  _onActiveEditorSetAttrib(e) {
    if (e.attrName === 'data-tinymce-placeholder') {
      const elementDefinitionId = EditorStore.getColorData()?.elementDefinitionId ?? '';
      const colorData = EditorStore.getColorData();
      if (colorData && ['ColorText', 'BackgroundColorText'].indexOf(colorData.elementDefinitionId) > -1) {
        const isBackground = elementDefinitionId === 'BackgroundColorText';
        e.attrElm[0].setAttribute(`data-${isBackground ? 'bg' : ''}color-day`, colorData.dayColor.id);
        if (colorData.dayColor.id !== colorData.nightColor.id) {
          e.attrElm[0].setAttribute(`data-data-${isBackground ? 'bg' : ''}color-night`, colorData.nightColor.id);
        }
        e.attrElm[0].setAttribute('data-element-definition-id', colorData.elementDefinitionId);
        e.attrElm[0].removeAttribute('setColor');
      }
      e.attrElm[0].removeAttribute('data-tinymce-placeholder');
    }
  }

  _onActiveEditorExecCommand(e) {
    if (this._isTextFormatting(e)) {
      const crmElements = this._getActionCRMElements();
      if (crmElements[0]) {
        this._unwrapSPANs(crmElements);
      } else if (this._isTextColorFormatting(e)) {
        tinymce.activeEditor.selection?.select(tinymce.activeEditor?.selection?.getStart());
        tinymce.activeEditor.selection?.collapse();
        tinymce.activeEditor.nodeChanged();
      }
    }
  }

  private onPaste(event) {
    const pasteHtml = event.clipboardData.getData('text/html');
    if (pasteHtml.indexOf('arc-diff-content') >= 0) {
      event.preventDefault();
      console.log('Paste operation not allowed from diff mode');
      return;
    }
  }

  private onPastePreProcess(event) {
    // need to check current node info, if not paragraph but table cell we need to wrap text in paragraph
    if (this._activeEditor?.selection.getNode().nodeName === 'TD') {
      const tableCellNode = this._activeEditor.selection.getNode();
      // Sometimes when copying, event.content is in the format <meta charset='utf-8'>...text/html, to get around this remove the meta tag and get text content from that html otherwise its just normal text
      let updatedContent: string;
      if ((event.content as String).includes('meta charset')) {
        const textTemplate = document.createElement('div');
        textTemplate.innerHTML = (event.content as String).split("<meta charset='utf-8'>")[1];
        updatedContent = textTemplate.textContent ?? '';
      } else {
        updatedContent = event.content;
      }
      // Paragraph with no nid gets inserted by _insertPara in unitKeyBehaviours.ts
      let para: Element | null = tableCellNode.querySelector('p:not([data-nid])');
      if (para) {
        para.innerHTML = updatedContent;
      } else {
        // we have an empty cell insert para with content into table cell
        const paraTemplate = ProjectDefinitionStore.getElementDefinitionById('Paragraph')?.templateHtml;
        if (paraTemplate && DomAssertions.isEmptyElementWithoutNotEditableContent(tableCellNode as HTMLElement)) {
          const template: HTMLTemplateElement = document.createElement('template');
          template.innerHTML = paraTemplate;
          const paraElm: Element = template.content.firstElementChild!;
          paraElm.innerHTML = updatedContent;
          para = tableCellNode.appendChild(paraElm);
        }
      }
      // set selection to paragraph
      this._activeEditor.selection.setCursorLocation(para!, 1);
      event.content = null;
    }
  }

  private _onActiveEditorNodeChange(e: TinyNodeChangeEvent) {
    UnitUtils.ensureNewListAttributes(e.element, '');
    if (this._isNativeFocused) {
      this._onSelectionChanged(e);
    }
  }

  _onActiveEditorKeyUp() {
    this._userTriggeredAction = true;
    const editSelection = tinymce.activeEditor.selection;
    if (editSelection) {
      this._currentEditingNode = editSelection.getNode() as HTMLElement;
      this._currentEditingRange = editSelection.getRng(false);
    }
  }

  _onActiveEditorContextMenu(e) {
    let canInsertFromContextMenu = true;
    const $srcEl = $(e.srcElement);

    _.each(
      ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('graphic')?.identifyingClasses,
      function (klass) {
        if ($srcEl.hasClass(klass)) {
          canInsertFromContextMenu = false;
        }
      }
    );

    if (!canInsertFromContextMenu) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  _onActiveEditorInit() {
    const editorEl = this._getSelectedUnitElement();
    if (editorEl) {
      this._addWordJoinerCharacterToEditTargetAreas(editorEl);
      this._addArconicsBogusToEmptyTableCells(editorEl);
      this._addAudioImagePlaceholder(editorEl);
      editorEl.parent().data('sanitizedHTML', this._getContent(true));
      UnitElementFocusUtil.focusElement(editorEl[0], this.initElementUid, getFamily(editorEl.children()));
      this.initElementUid = null;
      // listen to all observable elements
      const editorNode = tinymce.activeEditor.getElement();
      this.setupElementObservers(
        editorNode as HTMLElement,
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getAllObservedPatterns()
      );
    }
  }

  _onSelectionChanged(e: TinyNodeChangeEvent) {
    const editSelection = e.target.selection || (tinymce.activeEditor ? tinymce.activeEditor.selection : false);

    if (!editSelection || e?.element?.id === '_mce_caret') {
      return;
    }

    const node = e.parents && e.parents[0] ? e.parents[0] : editSelection.getNode(); // ensure current node is the node that tinymce places the cursor and not us
    this._currentEditingNode = this._getCurrentEditingNode(node);
    this._currentEditingRange = editSelection.getRng();

    const selected = e.command === 'SelectAll' ? tinymce.activeEditor.getContent() : editSelection.getContent();

    if (
      this._currentEditingNode?.nodeName === 'TD' &&
      (this._currentEditingNode?.innerHTML === '' || this._currentEditingNode?.firstChild === null)
    ) {
      this._currentEditingNode.innerHTML = '<br class="arconics-bogus">';
    }
    const nestedTree: IUnitDetails[] = this._activeEditorFacade!.getNestedTreeFromEvent(e);
    const nestedDOM: IEditingNestedChangeDOM[] = this._activeEditorFacade!.getNestedDomFromEvent(e);
    const { focused, parent } = this._activeEditorFacade!.getEventElementsFromEvent(e, nestedTree);

    EditorStore.nestedUnitFocusChange({
      parent,
      focused,
      nestedTree,
      nestedDOM
    } as any);

    const isNotEmptySelection = !!selected.length && selected.length > 0;
    this._triggerTextSelected(isNotEmptySelection, selected);
  }

  private setupElementObservers(currentEditingNode: HTMLElement | null, selectorPatterns?: string[] | null) {
    if (selectorPatterns) {
      selectorPatterns.forEach((selector) => {
        const observedNodes = $(currentEditingNode as HTMLElement)
          .find(selector)
          .addBack(selector);

        Array.from(observedNodes).forEach((node) => {
          if (node) {
            this._observer(node, (mutations) => {
              this._userTriggeredAction = true;
              EditorStore.triggerUnitDomChange({ mutations: mutations[0], node });
            });

            // notify of initial state
            if (_.isEqual(node, currentEditingNode)) {
              EditorStore.triggerUnitDomChange({ node });
            }
          }
        });
      });
    }
  }

  _getMutationObserver() {
    const MutationObserver = window.MutationObserver,
      eventListenerSupported = window.addEventListener;

    return (obj: Node, callback: (mutations: MutationRecord[], observer: MutationObserver) => void) => {
      if (MutationObserver) {
        const obs = new MutationObserver(function (mutations, observer) {
          callback(mutations, observer);
        });
        // have the observer observe for changes in style attrib
        obs.observe(obj, {
          attributes: true,
          attributeOldValue: true,
          childList: false,
          characterData: false,
          characterDataOldValue: false,
          subtree: false,
          attributeFilter: ['style']
        });

        if (!this._currentNodeObserver) {
          this._currentNodeObserver = [];
        }
        this._currentNodeObserver.push(obs);
      } else if (!!eventListenerSupported) {
        obj.addEventListener('DOMAttrModified', callback as any, false);
      }
    };
  }

  _cleanObservers() {
    if (this._currentNodeObserver) {
      this._currentNodeObserver.forEach((observer) => {
        observer.disconnect();
      });
      this._currentNodeObserver = null;
    }
  }

  _getCurrentEditingNode(currentEditingNode: HTMLElement | null = this._currentEditingNode): HTMLElement | null {
    if (currentEditingNode && currentEditingNode.nodeName === 'BR') {
      return currentEditingNode.parentElement;
    }
    return currentEditingNode;
  }

  _onNewStructureAdded(e) {
    if (['newrow', 'newcell'].indexOf(e.type.toLowerCase()) !== -1) {
      const { disconnect } = observeNewChildren(e.node, () => {
        disconnect(); // has to be at the top, otherwise we'll have observe-apocalypse
        const children = $(e.node).find('p[data-nid]').not('td td p[data-nid]'); // make sure we dont include nested table cells paragraphs
        if (children.length) {
          children.removeAttr('data-nid');
        }
        // If we have root defaults then remove any children of the copied table data and replace with root element defaults
        if (e.type.toLowerCase() === 'newcell' && e.node.nodeName === 'TD' && this._activeEditorFacade) {
          const rootElementDefaults = ProjectDefinitionStore.getElementDefinitionById('TableData')?.rootElementDefaults;
          if (rootElementDefaults && rootElementDefaults.length > 0) {
            Array.from((e.node as HTMLElement).children).map((child) => {
              (e.node as HTMLElement).removeChild(child as Node);
            });
            this._addRootElementDefaultsToCell(e.node, rootElementDefaults);
          }
        }
      });
    }
    if (this._activeEditorFacade) {
      this._activeEditorFacade.addWrapperContainer(e);
    }
  }

  _addRootElementDefaultsToCell(node: HTMLElement, rootElementDefaults: string[]) {
    rootElementDefaults.map((id: string) => {
      const rootElmDefaultDef = ProjectDefinitionStore.getElementDefinitionById(id);
      const elmTemplateToHTML = rootElmDefaultDef?.templateHtml;
      if (elmTemplateToHTML) {
        let $contentToInsert: JQuery<HTMLElement> = withContentNotEditableAttribute(elmTemplateToHTML);
        $contentToInsert = replaceTemplateDataNidsWithNewOnes($contentToInsert);
        _.each($contentToInsert.find('.edit-target'), (elm: HTMLElement) => {
          if (elm.children.length === 0) {
            elm.innerText = ZERO_LENGTH_WORD_JOINER;
          }
        });
        node.appendChild($contentToInsert[0]);
      }
    });
  }

  _addWordJoinerCharacterToEditTargetAreas($element: JQuery<HTMLElement>) {
    // works for actions atm
    _.each($element.find('.arc-unit .edit-target:empty'), (element) => (element.innerText = ZERO_LENGTH_WORD_JOINER));
  }

  _addArconicsBogusToEmptyTableCells($element: JQuery<HTMLElement>) {
    _.each($element.find('.arc-unit .arc-table-data:empty'), (element: HTMLElement) => (element.innerHTML = '<br class="arconics-bogus">'));
  }

  _addAudioImagePlaceholder($element: JQuery<HTMLElement>) {
    $element.find('.arc-media-file > audio').before('<span class="aero-icon material-icons audio-placeholder">micro<span/>');
  }

  _isTextFormatting({ command }): boolean {
    return (
      ['bold', 'italic', 'underline', 'overline', 'superscript', 'subscript', 'forecolor', 'hilitecolor'].indexOf(command.toLowerCase()) !==
      -1
    );
  }

  _isTextColorFormatting({ command }): boolean {
    return ['forecolor', 'hilitecolor'].indexOf(command.toLowerCase()) !== -1;
  }

  _getActionCRMElements() {
    return $(tinymce.activeEditor.selection.getNode())
      .closest('.element-content:not(span)')
      .children('.arc-action-challenge,.arc-action-challenge-response,.arc-action-challenge-response-message');
  }

  _unwrapSPANs(crmElements) {
    let unwrapped: null | JQuery<HTMLElement> = null;
    _.each(crmElements, (crmElement) => {
      const $element = $(crmElement).find('.element');
      let span = $element.children('span.element-content.edit-target');
      if (!span[0]) {
        const originalSPAN = $element.find('span.element-content.edit-target');
        const originalSPANParent = originalSPAN.parent();
        originalSPANParent.html(originalSPAN.html());
        span = $('<span class="element-content edit-target"></span>').append($element.children());
        $element.append(span);
        if (!unwrapped) {
          unwrapped = span;
        }
      }
    });
    if (unwrapped) {
      tinymce.activeEditor.selection.select((unwrapped as JQuery<HTMLElement>).children()[0]);
    }
  }
}
