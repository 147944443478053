import React, { useContext, CSSProperties } from 'react';
import { AppTheme, ThemeContext } from '../../misc/ThemeProvider';
import { TableRow, TableRowColumn } from 'material-ui';
import WorkflowTasksDetails from './WorkflowTasksDetails';
import WorkflowTasksStage from './WorkflowTasksStage';
import { IKPIWorkflowTask } from 'mm-types';

export interface WorkflowTasksRowCallback {
  onClickCallback?: (projectUid: string, indexUid: string, workspaceUid: string, external: boolean) => void;
}

function getStyles(theme: AppTheme): { [prop: string]: CSSProperties } {
  return {
    lateDueDate: {
      color: theme.custom.text.color.warning
    }
  };
}

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  row: {
    cursor: 'pointer'
  },
  dueDate: {
    fontSize: '1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  reference: {
    fontSize: '1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  cell: { verticalAlign: 'middle', padding: '0.8rem' }
};

const WorkflowTasksRow = ({
  formattedDueDate,
  isPublished,
  isOverdue,
  project,
  indexEntity,
  onClickCallback,
  workspace,
  currentStage
}: IKPIWorkflowTask & WorkflowTasksRowCallback) => {
  const { uid: projectUid, revision, title, reference, type, external } = project ?? {};
  const { uid: indexUid } = indexEntity ?? {};
  const { name: currentStageName } = currentStage ?? {};
  const { type: workspaceType } = workspace ?? {};
  const { code: referenceCode } = reference ?? {};

  const theme = useContext(ThemeContext);
  const styles = getStyles(theme);

  const dueDateStyle = isOverdue ? { ...defaultStyles.dueDate, ...styles.lateDueDate } : defaultStyles.dueDate;

  return (
    <TableRow
      data-qa="workflow-tasks-tile__row"
      style={defaultStyles.row}
      onRowClick={() => onClickCallback && onClickCallback(projectUid, indexUid, workspace?.uid ?? '', external)}
    >
      <TableRowColumn style={{ ...defaultStyles.cell, width: '45%' }}>
        <WorkflowTasksDetails title={title} revision={revision} type={type} external={external} />
      </TableRowColumn>
      <TableRowColumn style={{ ...defaultStyles.cell, width: '15%' }}>
        <span style={dueDateStyle}>{formattedDueDate}</span>
      </TableRowColumn>
      <TableRowColumn style={{ ...defaultStyles.cell, width: '20%' }}>
        <span style={defaultStyles.reference}>{referenceCode}</span>
      </TableRowColumn>
      <TableRowColumn style={{ ...defaultStyles.cell, width: '20%' }}>
        <WorkflowTasksStage
          workspaceType={workspaceType}
          isPublished={isPublished}
          revision={revision}
          currentStageName={currentStageName}
        />
      </TableRowColumn>
    </TableRow>
  );
};

export default WorkflowTasksRow;
