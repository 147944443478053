import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

interface Props {
  open: boolean;
  onCancel(): void;
  onConfirm(): void;
}

const ReadConfirmDialog = ({ open, onCancel, onConfirm }: Props) => {
  return (
    <Dialog
      className="dialog-read-confirm"
      actions={[
        <FlatButton label="Cancel" key={1} data-qa="dialog-action-cancel" onClick={onCancel} />,
        <FlatButton label="Yes" key={2} data-qa="dialog-action-confirm" onClick={onConfirm} />
      ]}
      modal={false}
      open={open}
      onRequestClose={onCancel}
    >
      <div className="text-align-center">Generating read confirm report for all manuals can take a long time.</div>
      <div className="text-align-center">Would you like to proceed?</div>
    </Dialog>
  );
};

export default ReadConfirmDialog;
