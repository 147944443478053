import React from 'react';
import { IConcept, IPDFExportOptions } from 'mm-types';
import DiffExportDialog from '../DiffExportDialog';

type Props = {
  showDiffExportDialog: boolean;
  getIndexVariants(): IConcept[];
  onClose(): void;
  exportDiffPDF(options: IPDFExportOptions): void;
};

const MenuFileDiffExportDialog = (props: Props) => {
  if (!props.showDiffExportDialog) {
    return null;
  }

  return (
    <DiffExportDialog
      variants={props.getIndexVariants()}
      onClose={() => props.onClose()}
      onExport={(options: IPDFExportOptions) => props.exportDiffPDF(options)}
    />
  );
};

export default MenuFileDiffExportDialog;
