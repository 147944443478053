import React, { Component, ErrorInfo } from 'react';
import ScriptError from '../misc/ScriptError';
import * as Sentry from '@sentry/react';
import { dispatchDefaultErrorSystemAlert } from '../misc/SystemAlert/util';

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class DefaultErrorBoundary extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo
    });
    console.error(error);
    Sentry.withScope((scope) => {
      Sentry.captureException(error);
    });
    dispatchDefaultErrorSystemAlert(false);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return <ScriptError title={this.state.error?.toString() ?? ''} stack={this.state.errorInfo?.componentStack} />;
    }
    return this.props.children;
  }
}
