import { isFeatureOn } from '../../../../featureSwitch/featureSwitchUtils';
import { QuizTypes } from '../QuizTypes';
import { IIndex, IProject } from 'mm-types';

export const requirements: QuizTypes.Requirements = {
  questions: {
    min: 1,
    max: 30,
    maxCharacters: 300
  },
  answers: {
    min: 2,
    max: 5,
    maxCharacters: 300
  }
};

interface RequiredProjectFields {
  isExternal: IProject['isExternal'];
  currentUserPermissions: IProject['currentUserPermissions'];
}
interface RequiredIndexFields {
  isPublished: IIndex['isPublished'];
}

export function verifyQuizAccess(
  project?: RequiredProjectFields,
  index?: RequiredIndexFields
): { visible: boolean; readOnly: boolean; isPublished: boolean } {
  const isPublished = !!index && index.isPublished;
  const permissions = project?.currentUserPermissions;

  if (!permissions || !project) {
    return {
      visible: false,
      readOnly: true,
      isPublished: false
    };
  }
  return {
    visible: isFeatureOn('quiz') && (permissions.canAuthor || permissions.canComment),
    readOnly: isPublished || (!permissions.canAuthor && permissions.canComment),
    isPublished
  };
}
