import React from 'react';
import { UnitTypes } from '../../utils/units/UnitTypes';

interface Props {
  type: UnitTypes;
  isReadonly: boolean;
  inDiffMode: boolean;
  label: string | null;
  inActiveTagCount: number;
  activeTagCount: number;
}

type TagIcon = 'activeTag' | 'inactiveTag' | 'doubleTag' | null;

function getIconAndColor(
  inDiffMode: boolean,
  activeTagCount: number,
  inActiveTagCount: number
): { icon: TagIcon; primaryColor: string; secondaryColor: string; tagCount: string } {
  if (inDiffMode || ((activeTagCount ?? 0) === 0 && (inActiveTagCount ?? 0) === 0)) {
    return {
      icon: null,
      primaryColor: '',
      secondaryColor: '',
      tagCount: ''
    };
  }

  if (activeTagCount) {
    return {
      icon: inActiveTagCount ? 'doubleTag' : 'activeTag',
      tagCount: activeTagCount.toString(),
      primaryColor: '#329DE7',
      secondaryColor: '#FFF'
    };
  }
  return {
    icon: 'inactiveTag',
    tagCount: '',
    primaryColor: '#FFF',
    secondaryColor: '#329DE7'
  };
}

function DocUnitFacetTag({ type, isReadonly, label, inDiffMode, activeTagCount, inActiveTagCount }: Props) {
  const unitIsRemoved = type === 'removed' || type === 'ghost';
  const { icon, tagCount, secondaryColor, primaryColor } = getIconAndColor(inDiffMode, activeTagCount, inActiveTagCount);

  return (
    <>
      {!isReadonly && label && <div className="du-label">{label}</div>}

      {(icon === 'activeTag' || icon === 'inactiveTag') && !unitIsRemoved && (
        <svg className={`unit-facet-tag ${icon}`} xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="-1 0 24 14">
          <g fill="none" fillRule="evenodd">
            <path
              fill={primaryColor}
              stroke="#329DE7"
              strokeWidth="1.04px"
              fillRule="nonzero"
              d="M17.7271111,0.84 C17.3751111,0.33 16.7884444,0 16.1333333,0 L1.95555556,0.01 C0.88,0.01 0,0.9 0,2 L0,12 C0,13.1 0.88,13.99 1.95555556,13.99 L16.1333333,14 C16.7884444,14 17.3751111,13.67 17.7271111,13.16 L22,7 L17.7271111,0.84 Z"
            />
            <text fill={secondaryColor} fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400">
              <tspan x="9" y="11.5" textAnchor="middle">
                {tagCount}
              </tspan>
            </text>
          </g>
        </svg>
      )}

      {icon === 'doubleTag' && !unitIsRemoved && (
        <svg
          id="Layer_1"
          className={`unit-facet-tag ${icon}`}
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          height="15"
          width="25"
          viewBox="0 0 18.92 12.03"
        >
          <path
            fill={secondaryColor}
            stroke={primaryColor}
            strokeWidth="0.6px"
            d="M18.28,8,16,4.76h0a.62.62,0,0,0-.51-.26H7.11a.61.61,0,0,0-.61.61v5.77a.61.61,0,0,0,.61.61h8.36a.63.63,0,0,0,.51-.27Z"
          />
          <path
            fill={primaryColor}
            d="M10.37.48A1.15,1.15,0,0,0,9.44,0H1.14A1.14,1.14,0,0,0,0,1.14V6.86A1.14,1.14,0,0,0,1.14,8h8.3a1.15,1.15,0,0,0,.93-.48L12.87,4Z"
          />
          <text fill={secondaryColor} fontFamily="Roboto-Medium, Roboto" fontSize="7" fontWeight="400">
            <tspan x="5" y="6.5" textAnchor="middle">
              {activeTagCount}
            </tspan>
          </text>
        </svg>
      )}
    </>
  );
}

export { DocUnitFacetTag };
