import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import EditorStore from '../../../flux/editor/EditorStore';
import ChangeTasksStore from '../../../flux/editor/ChangeTasksStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import WorkflowStore, { WorkflowStoreEvent, State as WorkflowStoreState } from '../../../flux/settings/WorkflowStore';
import ExternalApprovalStore, {
  ExternalApprovalStoreEvent,
  State as ExternalApprovalStoreState
} from '../../../flux/settings/ExternalApprovalStore';
import WorkflowActionHeader from './WorkflowActionHeader';
import WorkflowActionContent from './WorkflowActionContent';
import { FlatButton, Dialog, CircularProgress } from 'material-ui';
import { IApproval, IStage, IWorkFlow, IProject } from 'mm-types';
import { WorkflowDecisionModel } from '../../../clients/workflows';
import { getErrorDetails } from '../../../clients/base-clients-interceptors';

export type WorkflowData = {
  callback?: () => void;
  indexUid?: string;
  workflowUid?: string;
  activeStage?: IStage;
  stages?: IStage[];
  warnings?: string[];
  action: {
    type: WorkflowDecisionModel;
    label: string;
  };
};

export type Props = {
  onRequestClose: () => void;
  project: null | IProject;
  data: Partial<WorkflowData>;
};

export type State = {
  isLoading: boolean;
  isPerformingAction: boolean;
  areOpenTasks: boolean;
  showApprovalWarningMsg: boolean;
  skipPredefined: boolean;
  commentRequiredAndInValid: boolean;
  dialog: {
    show: boolean;
    title: string;
    text: string;
  };
  approval: Partial<IApproval>;
} & WorkflowStoreState &
  ExternalApprovalStoreState;

export default class WorkflowActionModal extends React.Component<Props, State> {
  private workflowUnsub: Function;
  private approvalUnsub: Function;

  constructor(props: Props) {
    super(props);

    this.workflowUnsub = WorkflowStore.listen(this.onWorkflowStoreChange, this);
    this.approvalUnsub = ExternalApprovalStore.listen(this._onExternalApprovalStoreChange, this);

    const state: Partial<State> = Object.assign(
      {} as Partial<State>,
      {
        isLoading: true,
        isPerformingAction: false,
        areOpenTasks: false,
        skippedPredefined: false,
        commentRequiredAndInValid: false,
        approval: {
          approvalOrg: '',
          approvalReferenceNumber: '',
          approvalDate: null,
          approverName: '',
          approverTitle: '',
          approvalCertMediaUid: null
        } as IApproval,
        dialog: {
          show: false,
          title: 'Problem encountered',
          text: ''
        }
      },
      WorkflowStore.getInitialState()
    );

    this.state = state as State;
  }

  static defaultProps: Partial<Props> = {
    data: {}
  };

  UNSAFE_componentWillMount() {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      editor.getActiveEditorFacade()!.setContentEditable(false);
    }
  }

  showApprovalWarningMsg(activeStage: IStage, approval: IApproval, actionType: string, callback?: (result) => void) {
    if (activeStage && ['APPROVE'].indexOf(actionType) > -1) {
      const result = this.willCauseTransition(activeStage) && this._isApprovalMissing(approval) && this._isApprovalRequired(activeStage);

      if (callback) {
        callback(result);
      }
      return result;
    }
  }

  willCauseTransition(activeStage: IStage) {
    return activeStage.approvalMethod === 'SINGLE' || this._isLastUser(activeStage);
  }

  _isLastUser(activeStage: IStage) {
    if (activeStage) {
      const usersWithoutReview = activeStage.assignments.filter((a) => !a.reviewResult);
      return usersWithoutReview.length === 1;
    }
  }

  _isApprovalMissing(approval: IApproval) {
    if (approval) {
      const props = ['approvalOrg', 'approvalReferenceNumber', 'approvalDate', 'approverName', 'approverTitle'];

      const empty = props.filter((prop) => !approval[prop]);
      const isEmpty = empty.length > 0;
      return isEmpty;
    }
  }

  _isApprovalRequired(activeStage: IStage) {
    return activeStage && activeStage.mustUploadApprovalArtifacts;
  }

  _refocusEditor(e: React.MouseEvent<HTMLElement>, type?: 'click' | 'mousedown') {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      if (type === 'click') {
        if (!$(e.target).is('input[type=file]')) {
          e.stopPropagation();
          e.preventDefault();
        }
      }

      if (
        !/input/i.test((e.target as HTMLDivElement).tagName) &&
        !/textarea/i.test((e.target as HTMLDivElement).tagName) &&
        !/force-focus/i.test((e.target as HTMLDivElement).className)
      ) {
        if (type === 'mousedown') {
          // only stop mousedowns if not in an input, as we ALLOW mouse down on input
          e.stopPropagation();
          e.preventDefault();
        }

        editor.silentReFocus();
      }
    }
  }

  componentWillUnmount() {
    this.workflowUnsub();
    this.approvalUnsub();

    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      editor.getActiveEditorFacade()!.setContentEditable(true);
      editor.silentReFocus();
    }

    this._close(true);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: true }, () => {
        ChangeTasksStore.retrieveTasks({ indexUid: this.props.data.indexUid }, true).then(() => {
          this.setState({
            areOpenTasks: !!ChangeTasksStore.getTasks().find((task) => !task.resolved)
          });
          WorkflowStore.retrieveWorkflowSummary(ProjectStore.getIndex()!.uid);
        });
      });
    }, 100);

    setTimeout(() => {
      ExternalApprovalStore.init(ProjectStore.getProject()!.uid, this.props.data.indexUid!);
    }, 100);
  }

  _onExternalApprovalStoreChange(state: ExternalApprovalStoreEvent) {
    state.approval.approvalDate = moment(state.approval.approvalDate as string).toDate();
    this.setState(state);
  }

  onWorkflowStoreChange(event: WorkflowStoreEvent) {
    if (!event.error) {
      if (event.type === 'workflowSummary') {
        this.setState(Object.assign({}, this.state, event.state, { isLoading: false }));
      }
    }
  }

  onApprovalChange(e: { showApprovalWarningMsg: boolean; skipPredefined?: boolean | undefined }) {
    // Only update state when there's a change in value
    if (this.state.showApprovalWarningMsg !== e.showApprovalWarningMsg) {
      this.setState({ showApprovalWarningMsg: e.showApprovalWarningMsg });
    }

    if (e.skipPredefined) {
      this.setState({ skipPredefined: e.skipPredefined });
    }
  }

  _cancelLabel() {
    return this._showSubmit() ? 'Cancel' : 'Ok';
  }

  _showSubmit() {
    return this.props.data.action!.type !== 'SUMMARY';
  }

  _isSubmitDisabled() {
    const openTasks =
      this.props.data.action!.type === 'APPROVE' &&
      this.props.data.activeStage &&
      this.props.data.activeStage.changeTaskEnforcementMethod === 'CLOSED_ONLY' &&
      this.state.areOpenTasks;

    return this.state.isPerformingAction || openTasks || this.state.showApprovalWarningMsg ? true : this.state.commentRequiredAndInValid;
  }

  async _handleFooterAction(action: 'cancel' | 'submit') {
    if (action === 'cancel') {
      this._close();
    } else if (action === 'submit') {
      if (this.props.data.activeStage && !this.state.commentRequiredAndInValid) {
        this.setState({ isPerformingAction: true });

        if (this.props.data.activeStage.mustUploadApprovalArtifacts) {
          await ExternalApprovalStore.update(
            (this.refs.content as WorkflowActionContent).getData().approval!,
            ProjectStore.getProject()!.uid,
            this.props.data.indexUid!
          );

          this.setState({ isPerformingAction: false });
        }
        const workflowData = {
          decisionModel: this.state.skipPredefined ? 'APPROVE_SKIP_NEXT' : this.props.data.action!.type,
          comment: (this.refs.content as WorkflowActionContent).getData().comment
        };

        try {
          await WorkflowStore.workflowAction(workflowData, this.props.data.workflowUid!, this.props.data.activeStage.publishStep);

          if (this.props.data.callback) {
            this.props.data.callback();
          }

          await ProjectStore.reInitProject();

          this.props.onRequestClose();
          this.setState({ isPerformingAction: false });
        } catch (err) {
          this.setState({
            isPerformingAction: false,
            dialog: _.extend(this.state.dialog, { show: true, text: getErrorDetails(err).serverErrorMessage })
          });
        }
      }
    }
  }

  _close(isSilent?: boolean) {
    if (!isSilent) {
      this.props.onRequestClose();
    }
  }

  _dialogFinish() {
    this.setState({ dialog: _.extend(this.state.dialog, { show: false }) });
  }

  toggleSkipped() {
    this.setState({ skipPredefined: !this.state.skipPredefined });
  }

  CommentRequiredAndInValid(commentRequiredAndInValid: boolean) {
    this.setState({ commentRequiredAndInValid: commentRequiredAndInValid });
  }

  render() {
    return (
      <div className="editor-fullpage-modal wa-container-outer" onClick={(e) => this._refocusEditor(e)}>
        <div className="wa-content-container-outer">
          <WorkflowActionHeader
            data={this.props.data as WorkflowData}
            workflowName={this.state.workflowSummary.displayName!}
            areOpenTasks={this.state.areOpenTasks}
          />
          <div className="row page-inner page-inner-menu-content">
            <div className="left-space" />
            <WorkflowActionContent
              ref="content"
              project={this.props.project}
              workflowSummary={this.state.workflowSummary!.workflow! as IWorkFlow}
              onSkipChange={() => this.toggleSkipped()}
              onCommentRequiredAndInValid={(e) => this.CommentRequiredAndInValid(e)}
              data={this.props.data as WorkflowData}
              approval={this.state.approval as IApproval}
              isLoading={this.state.isLoading}
              onApprovalChange={(e) => this.onApprovalChange(e)}
            />
          </div>
        </div>

        <div className="actions-footer">
          <div className="buttons">
            <FlatButton
              label={this._cancelLabel()}
              disabled={this.state.isPerformingAction}
              onClick={(e) => {
                this._handleFooterAction('cancel');
              }}
            />
            {this._showSubmit() ? (
              <FlatButton
                label="Submit"
                data-qa="workflow-action-modal-submit"
                data-qa-disabled={this._isSubmitDisabled()}
                disabled={this._isSubmitDisabled()}
                onClick={(e) => {
                  this._handleFooterAction('submit');
                }}
              />
            ) : undefined}
          </div>
        </div>

        {this.state.dialog.show ? (
          <Dialog
            actions={[
              React.createElement(FlatButton, {
                label: 'Ok',
                primary: true,
                onClick: () => this._dialogFinish()
              })
            ]}
            title={this.state.dialog.title}
            open={this.state.dialog.show}
            onRequestClose={() => this._dialogFinish()}
            style={{ zIndex: 999 }}
          >
            <p className="workflow-dialog__body">{this.state.dialog.text}</p>
          </Dialog>
        ) : undefined}

        {this.state.isPerformingAction && (
          <div className="progress-modal">
            <CircularProgress className="loading" style={{ margin: 'auto' }} size={200} thickness={10} />
          </div>
        )}
      </div>
    );
  }
}
