import * as React from 'react';

import { InsertAction } from './ContentMenuContainer';
import { IconButton } from 'material-ui';

interface Props {
  icon: string;
  action: InsertAction;
  label: string;
  selected: boolean;
  onActionUpdate: (newAction: InsertAction) => void;
  enabled: boolean;
}

class InsertPositionComponent extends React.Component<Props, {}> {
  render() {
    return (
      <div className={'insert-position-component'} onClick={() => this.props.enabled && this.props.onActionUpdate(this.props.action)}>
        <IconButton
          iconClassName={
            'material-icons ' +
            (this.props.enabled && this.props.selected ? ' content-menu__active-item ' : '') +
            (!this.props.enabled ? 'content-menu__active-item--disabled' : '')
          }
          iconStyle={{ color: this.props.enabled ? '#28ace3 !important' : 'rgba(0, 0, 0, 0.1) !important' }}
          tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
          tooltipPosition="bottom-left"
          tooltip={this.props.enabled ? this.props.label : ''}
          style={{ padding: '2px', border: this.props.selected && this.props.enabled ? '1px #28ace3 solid' : 'none' }}
        >
          {this.props.icon}
        </IconButton>
      </div>
    );
  }
}

export default InsertPositionComponent;
