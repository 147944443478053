import * as React from 'react';
import { NNCIndexMetadataID } from '../../NNCIndexMetadataTypes';
import { NNCMetadataComponentProps } from '../MetadataComponentTypes';
import { useArrayValue } from '../../useArrayValues';
import { useNNCTocEntries } from '../../useNNCTocEntries';
import { ITocNode } from 'mm-types';
import { AeroIconButtonAdd, AeroIconButtonEdit } from '../../../../../../general/AeroIconButton';
import { ForeignEntryDialog } from './ForeignEntryDialog';
import { ForeignEntryValue } from '../../foreign-entry/metadataDefinition';
import { ForeignEntryItem } from './ForeignEntryItem';
import keyDown from '../../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
const AddButton = React.memo(AeroIconButtonAdd);
const EditButton = React.memo(AeroIconButtonEdit);

type Props = NNCMetadataComponentProps<NNCIndexMetadataID.FOREIGN_ENTRY>;

export interface ForeignEntryValueWithName extends ForeignEntryValue {
  name?: string;
}

function getTocName(uid: string, tocs: ITocNode[]): string | undefined {
  if (!tocs.length) {
    return undefined;
  }
  const toc = tocs.filter((toc) => toc.uid === uid);
  if (toc.length) {
    return `${toc[0].ordinal} ${toc[0].heading}`;
  }
  return undefined;
}

function getVisibleValues(entries: ForeignEntryValue[], tocs: ITocNode[]): ForeignEntryValueWithName[] {
  const result: ForeignEntryValueWithName[] = [];
  for (let i = 0, max = entries.length; i < max; i++) {
    const uid = entries[i].uid;
    result.push({
      uid,
      name: getTocName(uid, tocs)
    });
  }
  return result;
}

export function ForeignEntryMetadata({ values, onChange, onTab, currentRef, unit }: Props) {
  const nncTocEntries: ITocNode[] = useNNCTocEntries(unit!);
  const { localValues, setValues, removeValue } = useArrayValue<Props['values']>(values, onChange);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [visibleValues, setVisibleValues] = React.useState<ForeignEntryValueWithName[]>(getVisibleValues(values, nncTocEntries));

  const showDialog = React.useCallback(() => {
    setIsEdit(true);
  }, [isEdit]);

  const hideDialog = React.useCallback(() => {
    setIsEdit(false);
  }, [isEdit]);

  const saveValues = React.useCallback(
    (values: Props['values']) => {
      setValues(values);
      hideDialog();
    },
    [localValues, setValues, hideDialog]
  );

  const removeUid = React.useCallback(
    (index: number) => {
      removeValue(index);
    },
    [localValues, removeValue]
  );

  React.useEffect(() => {
    setVisibleValues(getVisibleValues(localValues, nncTocEntries));
  }, [localValues, nncTocEntries, setVisibleValues]);

  return (
    <div>
      <div className="show-form-action" onKeyDown={(e) => keyDown(e, onTab, currentRef)}>
        {localValues.length ? (
          <EditButton
            onClick={showDialog}
            id="edit-sections-button"
            data-qa="edit-sections-button"
            style={{ transform: 'scale(.8)' }}
            tooltip="Edit NNC Sections"
            tooltipPosition="top-left"
            disabled={!nncTocEntries.length}
            ref={currentRef}
          />
        ) : (
          <AddButton
            onClick={showDialog}
            id="add-sections-button"
            data-qa="add-sections-button"
            tooltip="Add NNC Sections"
            tooltipPosition="top-left"
            disabled={!nncTocEntries.length}
            ref={currentRef}
          />
        )}
      </div>
      <ul className="position-relative padding-right-l margin-top-none">
        {!localValues.length && (
          <li data-qa="no-indexes-text">
            <div className="font-gray font-size-12" onClick={showDialog}>
              {"Select '+' in other Sections Index."}
            </div>
          </li>
        )}

        {visibleValues.map((value, index) => (
          <ForeignEntryItem key={index} entry={value} onRemove={() => removeUid(index)} />
        ))}
      </ul>
      <ForeignEntryDialog show={isEdit} onCancel={hideDialog} onConfirm={saveValues} tocs={nncTocEntries} selectedTocs={localValues} />
    </div>
  );
}
