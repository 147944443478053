import React from 'react';
import { IHighlight } from 'mm-types';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import HighlightListItem from './HighlightListItem';

interface Props {
  selectedUid?: string;
  highlights?: IHighlight[];
  onHighlightClick(highlight: IHighlight): void;
  onToggleWhatsNew(highlight: IHighlight): void;
}

const HighlightList = ({ highlights, onHighlightClick, onToggleWhatsNew, selectedUid }: Props) => {
  if (!highlights || !highlights.length) {
    return null;
  }
  const project = ProjectStore.getProject();
  const canComment = !!(project && project.currentUserPermissions?.canComment);
  const canEdit: boolean = !EditorStore.isReadOnly() && canComment;
  const withComments = highlights.filter((highlight) => !!highlight.comment) ?? [];
  if (withComments.length === 0) {
    return <div className="comments-none">No comments to display</div>;
  }
  return (
    <div className="comments-box" data-qa="unit-highlight-list">
      {withComments.map((unitHighlight) => {
        return (
          <HighlightListItem
            canEdit={canEdit}
            key={'highlight-' + unitHighlight.unitUid}
            unitHighlight={unitHighlight}
            selected={unitHighlight.unitUid === selectedUid}
            onWhatsNewChanged={() => onToggleWhatsNew(unitHighlight)}
            onClick={() => onHighlightClick(unitHighlight)}
          />
        );
      })}
    </div>
  );
};

export default HighlightList;
