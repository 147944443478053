import { isSuccess } from '../../../../clients/base-clients';
import * as workflowsClient from '../../../../clients/workflows';
import { IWorkFlow } from 'mm-types';

export async function deleteGebWorkflows(setFetching: Function, fetchCompleted: Function, setCompleted: Function, showMessage: Function) {
  showMessage('Deleting projects...');
  setFetching(true);
  try {
    setCompleted(1);
    const response = await workflowsClient.getAll();
    if (isSuccess(response)) {
      const gebWorkflows: IWorkFlow[] = response.filter(
        (workflow) =>
          workflow.name.startsWith('MM_SPA_') ||
          workflow.name.startsWith('abcdefgh') ||
          workflow.name.startsWith('Two_Stage') ||
          workflow.name.split('-').length === 5
      );
      const total = gebWorkflows.length;
      if (!gebWorkflows.length || gebWorkflows.length === 0) {
        fetchCompleted();
        showMessage('No workflows to delete');
      } else {
        for (const workflow of gebWorkflows) {
          const index = gebWorkflows.indexOf(workflow);
          setCompleted(((index + 1) * 100) / total);
          try {
            await workflowsClient.remove(workflow.uid);
          } catch (e) {
            showMessage(`${workflow.name}: ${e.message}`);
          }
        }
      }
      fetchCompleted();
      showMessage('All done!');
    }
  } catch (e) {
    showMessage(e);
    fetchCompleted();
  }
}
