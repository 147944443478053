import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import ProjectStore from '../../../flux/editor/ProjectStore';
import userUtil from '../../documents/team/util/user';
import Checkbox from 'material-ui/Checkbox';
import WorkflowSummary, { rejectCodes } from '../../general/WorkflowSummary';
import UploadImageDialog, { UploadImageDialogComponent } from '../../misc/UploadImageDialog';
import MediaImage from '../../general/MediaImage';
import { CircularProgress, DatePicker, MenuItem, SelectField, TextField } from 'material-ui';
import { IApproval, IProject, IStage, IUser, IWorkFlow } from 'mm-types';
import { WorkflowData } from './WorkflowActionModal';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';
import { dateUtil } from '../../../utils';
import ServerSettingsStore from '../../../flux/common/ServerSettingsStore';

export type Props = {
  onApprovalChange: (e: { showApprovalWarningMsg: boolean }) => void;

  onSkipChange: (skipPredefined: boolean) => void;
  onCommentRequiredAndInValid: (commentRequiredAndValid: boolean) => void;
  workflowSummary: Partial<IWorkFlow>;
  data: WorkflowData;
  project: null | IProject;
  isLoading: boolean;
  approval: IApproval;
};

export type State = {
  comment: string;
  toggleSkippedStages: boolean;
  snackbar: null;
  isLoading: boolean;
  currentUser: IUser;
  showApprovalWarningMsg: boolean;
  approval?: IApproval;
};

export default class WorkflowActionContent extends React.Component<Props, State> {
  private uploadImageDialog: UploadImageDialogComponent | null;
  constructor(props: Props) {
    super(props);

    this.state = {
      comment: '',
      snackbar: null,
      toggleSkippedStages: false,
      isLoading: false,
      currentUser: ActiveUserStore.getUser()!,
      showApprovalWarningMsg: false
    };
  }

  showApprovalWarningMsg(
    activeStage: IStage,
    approval: IApproval,
    actionType: string,
    callback?: (showApprovalWarningMsg: boolean) => void
  ) {
    if (activeStage && ['APPROVE'].indexOf(actionType) > -1) {
      const result = this.willCauseTransition(activeStage) && this._isApprovalMissing(approval) && this._isApprovalRequired(activeStage);

      if (callback) {
        callback(result);
      }

      return result!;
    }

    return false;
  }

  showApprovalCertWarningMsg(
    activeStage: IStage,
    approval: IApproval,
    actionType: string,
    callback?: (showApprovalCertWarningMsg: boolean) => void
  ) {
    if (activeStage && ['APPROVE'].indexOf(actionType) > -1 && approval) {
      const result =
        this.willCauseTransition(activeStage) && this._isApprovalRequired(activeStage) && !this._approvalCertUploaded(approval);

      if (callback) {
        callback(result);
      }

      return result!;
    }

    return false;
  }

  willCauseTransition(activeStage: IStage) {
    return activeStage.approvalMethod === 'SINGLE' || this._isLastUser(activeStage) ? true : false;
  }

  _isLastUser(activeStage: IStage) {
    if (activeStage) {
      const usersWithoutReview = activeStage.assignments.filter((a) => !a.reviewResult);
      return usersWithoutReview.length === 1;
    }
  }

  _approvalCertUploaded(approval: IApproval) {
    if (approval) {
      return approval!.approvalCertMediaUid ? true : false;
    }
    return false;
  }

  _isApprovalMissing(approval: IApproval) {
    if (approval) {
      const props = ['approvalOrg', 'approvalReferenceNumber', 'approvalDate', 'approverName', 'approverTitle'];

      const empty = props.filter((prop) => !approval[prop]);
      const isEmpty = empty.length > 0;
      return isEmpty;
    }

    return false;
  }

  _isApprovalRequired(activeStage: IStage) {
    return activeStage && activeStage.mustUploadApprovalArtifacts ? true : false;
  }

  componentDidMount() {
    this.setState({
      approval: this.props.approval!,
      showApprovalWarningMsg: this._showApprovalWarningMsg()
    });
    this.isValid();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.approval) {
      this.setState({
        approval: nextProps.approval,
        showApprovalWarningMsg: this._showApprovalWarningMsg()
      });
    }
  }

  _getMyAvatarUrl() {
    return userUtil.imagePath(this.state.currentUser);
  }

  _handleCommentChange(event: React.FormEvent<HTMLTextAreaElement>) {
    this.setState({ comment: (event.target as HTMLTextAreaElement).value.substr(0, 999) }, () => {
      this.isValid();
    });
  }

  setLoading(isLoading: boolean) {
    this.setState({ isLoading: isLoading });
  }

  isValid() {
    let invalid: boolean;
    this.props.data.activeStage && this._isCommentRequired() && _.isEmpty(this.state.comment) ? (invalid = true) : (invalid = false);

    this.props.onCommentRequiredAndInValid(invalid);
  }

  _isCommentRequired() {
    const isSkip = this.props.data.action.type === 'SKIP';
    const isCommentRequired = this.props.data.activeStage && this.props.data.activeStage.transitionRequiresComment;
    return isSkip || isCommentRequired;
  }

  getData() {
    return {
      comment: this.state.comment,
      approval: this.state.approval
    };
  }

  _getStatus() {
    return this.props.data.action.label;
  }

  _getStage() {
    return this.props.data.activeStage ? this.props.data.activeStage.title : '';
  }

  // Approve part

  _getApprovalDate() {
    if (this.state.approval && this.state.approval.approvalDate) {
      return this.state.approval.approvalDate;
    } else {
      return moment().toDate();
    }
  }

  _showUploadApprovalArtifacts() {
    if (this.props.data.activeStage) {
      return this.props.data.activeStage.mustUploadApprovalArtifacts;
    }
  }

  _formatDate(date: Date) {
    return dateUtil(date).formatDate();
  }

  _approvalDateChanged(prop: string, e, date: Date) {
    this.state.approval![prop] = date;
    this.setState({ approval: this.state.approval });
  }

  _handleApprovalChange(fieldName: string, value: any) {
    const approval = this.state.approval!;
    approval[fieldName] = value;
    this.setState({
      approval: approval,
      showApprovalWarningMsg: this._showApprovalWarningMsg()
    });
  }

  _uploadImage(type: string) {
    if (this.uploadImageDialog) {
      this.uploadImageDialog.show(type);
    }
  }

  _imageUploaded(mediaObject) {
    this.state.approval!.approvalCertMediaUid = mediaObject.id ? mediaObject.id : mediaObject.uid;
    if (this.uploadImageDialog) {
      this.uploadImageDialog.cancel();
    }

    this.setState({
      approval: this.state.approval
    });
  }

  _deleteImage() {
    this.state.approval!.approvalCertMediaUid = null;
    this.setState({
      approval: this.state.approval
    });
  }

  _getProjectUid() {
    return ProjectStore.getProject()!.uid;
  }

  _showWarnings() {
    return this.props.data.warnings && this.props.data.warnings.length > 0 && this.props.data.action.type !== 'REJECT';
  }

  _showApprovalWarningMsg() {
    const data = this.props.data;
    return this.showApprovalWarningMsg(data.activeStage!, this.state.approval!, data.action.type, (result) =>
      this.props.onApprovalChange({ showApprovalWarningMsg: result })
    );
  }

  _getStageIconName(stage: IStage) {
    if (stage.publishStep && !rejectCodes[stage.status]) {
      return 'send';
    } else {
      const icons = {
        COMPLETED: 'check',
        COMPLETE: 'check',
        REJECTED: 'close',
        SKIPPED: 'remove',
        OTHER: 'more_horiz'
      };

      return icons[stage.status] ? icons[stage.status] : 'more_horiz';
    }
  }

  toggleSkippedStages() {
    this.props.onSkipChange(!this.state.toggleSkippedStages);
    this.setState({ toggleSkippedStages: !this.state.toggleSkippedStages });
  }

  _getStageClassName(stage: IStage) {
    return 'material-icons workflow-icon ' + stage.status + (stage.publishStep && !rejectCodes[stage.status] ? ' oval' : ' square');
  }

  render() {
    let showSkipCheckbox = false;

    if (this.props.project) {
      showSkipCheckbox = this.props.project.currentUserPermissions?.canSkipMultipleStages;
    }

    if (this.props.data && this.props.workflowSummary && this.props.data.activeStage && this.props.workflowSummary.stages) {
      const activeUid = this.props.data.activeStage.uid;
      const stages = this.props.workflowSummary.stages;

      for (let i = 0, l = stages.length; i < l; i++) {
        if (stages[i].uid === activeUid) {
          if (i + 1 < l) {
            // Is stage after active one not skippable? If so then disable
            if (!stages[i + 1].skippableStage) {
              showSkipCheckbox = false;
            }
          } else {
            showSkipCheckbox = false;
          }
          break;
        }
      }
    }

    if (this.props.data.action.type === 'REJECT') {
      showSkipCheckbox = false;
    }

    return (
      <div className={`wa-content-container ${this.props.data.action.type}-action action-content`}>
        {this.props.isLoading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" />
          </div>
        ) : undefined}

        {!this.props.isLoading ? (
          <div>
            <WorkflowSummary
              data={this.props.workflowSummary as IWorkFlow}
              workflowPublishMode={true}
              highlightCurrentStage={false}
              toggleSkippableStages={this.state.toggleSkippedStages}
            />

            <div className="action-area">
              <div data-qa="workflow-stage" className="stage workflow-action-status">
                <div className="left-side">
                  <div className="before-icon" />
                  {this.props.data.activeStage ? (
                    <div className={this._getStageClassName(this.props.data.activeStage!)}>
                      {this._getStageIconName(this.props.data.activeStage!)}
                    </div>
                  ) : undefined}
                </div>
              </div>

              <div className="details">
                <div className="details__row">
                  <div className="details__label">Current Stage:</div>
                  <div className="details__data highlighted">{this._getStage()}</div>
                </div>

                <div className="details__row">
                  <div className="details__label" style={{ fontSize: 14 + 'px' }}>
                    Action:
                  </div>
                  <div className="details__data" style={{ fontSize: 14 + 'px' }}>
                    {this._getStatus()}
                  </div>
                </div>
                {this._getStatus() !== 'Summary' && (
                  <>
                    <div className="details__row checkbox" style={{ marginBottom: '0px' }}>
                      {showSkipCheckbox ? (
                        <Checkbox
                          id={'skipped-stages'}
                          checked={this.state.toggleSkippedStages}
                          onClick={() => this.toggleSkippedStages()}
                          style={{ left: '-4px' }}
                          name={'cb-'}
                          labelStyle={{ color: '#9e9e9e' }}
                          label={
                            this.state.toggleSkippedStages
                              ? 'Skip predefined stage(s) of the workflow.'
                              : 'Skip predefined stage(s) of the workflow.'
                          }
                        />
                      ) : null}
                    </div>

                    {this.state.toggleSkippedStages ? (
                      <label style={{ marginLeft: 36 + 'px', fontWeight: 300, fontSize: 1 + 'rem' }}>
                        Greyed out stages of the workflow will be skipped.
                      </label>
                    ) : null}

                    <div className="comment-area">
                      {this._isCommentRequired() && !this.state.comment ? (
                        <div className="comment-warning">Workflow stage comment is required</div>
                      ) : undefined}

                      <div>
                        <textarea placeholder="Add comment:" value={this.state.comment} onChange={(e) => this._handleCommentChange(e)} />
                      </div>
                    </div>
                  </>
                )}

                {this._showWarnings() ? (
                  <div className="warnings">
                    {this.props.data.warnings!.map((warning, index: number) => {
                      return (
                        <div key={index} className="warnings__item">
                          {warning}
                        </div>
                      );
                    })}
                  </div>
                ) : undefined}

                {this._showUploadApprovalArtifacts() ? (
                  <div className="external-approval-settings">
                    <div className="container-meta-media">
                      <div className="meta-media">
                        <label>Approval Cert</label>

                        <div className={`${this.state.approval!.approvalCertMediaUid ? 'w?th-image' : ''} thumb`}>
                          {this.state.approval!.approvalCertMediaUid ? (
                            <MediaImage projectUid={this._getProjectUid()} mediaUid={this.state.approval!.approvalCertMediaUid!} />
                          ) : undefined}
                          {!this.state.approval!.approvalCertMediaUid ? <span className="empty">NO CERT</span> : undefined}
                        </div>
                        <a
                          onClick={() => {
                            this._uploadImage('headerLogoMedia');
                          }}
                        >
                          (Upload)
                        </a>
                        {this.state.approval!.approvalCertMediaUid ? (
                          <a
                            onClick={() => {
                              this._deleteImage();
                            }}
                          >
                            (Delete)
                          </a>
                        ) : undefined}
                      </div>
                    </div>

                    <UploadImageDialog
                      wrappedComponentRef={(componentRef) => (this.uploadImageDialog = componentRef)}
                      onuploaded={(mediaObject) => this._imageUploaded(mediaObject)}
                      projectUid={this._getProjectUid()}
                    />

                    {this.state.showApprovalWarningMsg ? (
                      <div className="required" data-qa="approval-warning">
                        Please provide all Approval Fields
                      </div>
                    ) : undefined}

                    <div>
                      <SelectField
                        data-qa="approval-authority-dropdown"
                        value={this.state.approval!.approvalOrg}
                        floatingLabelText="Approval Authority"
                        onChange={(e, index, value) => {
                          this._handleApprovalChange('approvalOrg', value);
                        }}
                        disabled={false}
                      >
                        {ServerSettingsStore.getServerSettings().approvalAuthorities!.map((item) => {
                          return <MenuItem key={item.value} value={item.value} primaryText={item.name} />;
                        })}
                      </SelectField>
                    </div>
                    <br />

                    <div>
                      <TextField
                        data-qa="approval-reference-number"
                        value={this.state.approval!.approvalReferenceNumber}
                        floatingLabelText="Approval Reference Number"
                        id="approval-reference-number-input"
                        disabled={false}
                        onChange={(e, text) => {
                          this._handleApprovalChange('approvalReferenceNumber', text);
                        }}
                      />
                    </div>
                    <br />

                    <div>
                      <DatePicker
                        defaultDate={this.state.approval!.approvalDate as Date}
                        floatingLabelText="Approval Date"
                        formatDate={(date) => this._formatDate(date)}
                        onChange={(e, date) => this._approvalDateChanged('approvalDate', e, date)}
                        autoOk={true}
                      />
                    </div>
                    <br />

                    <div>
                      <TextField
                        data-qa="approver-name"
                        value={this.state.approval!.approverName}
                        floatingLabelText="Approver Name"
                        id="approver-name-input"
                        disabled={false}
                        onChange={(e, text) => {
                          this._handleApprovalChange('approverName', text);
                        }}
                      />
                    </div>
                    <br />

                    <div>
                      <TextField
                        data-qa="approver-title"
                        value={this.state.approval!.approverTitle}
                        floatingLabelText="Approver Title"
                        id="approver-title-input"
                        disabled={false}
                        onChange={(e, text) => {
                          this._handleApprovalChange('approverTitle', text);
                        }}
                      />
                    </div>
                    <br />
                  </div>
                ) : undefined}
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}
