import { EditorMode } from './EditorMode';
import { GetOptions } from '../../../../clients/units';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { Banner, ModeAttributes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class FindReplaceMode extends EditorMode {
  private _findWord: string | null = null;
  private _findWordOptions: any | null = null;

  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.submenuTabs | attributes.mainmenuTabs, syncQueue);
  }

  getBanner(): Banner {
    return { title: 'Find/Replace Mode' };
  }

  getRetrieveUnitParams() {
    const retrieveParams: Partial<GetOptions> = {};
    if (this._findWord) {
      retrieveParams.findText = this._findWord;
    }
    if (this._findWordOptions) {
      retrieveParams.matchCase = this._findWordOptions['matchCase'];
      retrieveParams.wholeWord = this._findWordOptions['wholeWord'];
    }

    return retrieveParams;
  }

  activate(currentPageUnits: DocUnitWrapper[], params: any) {
    if (params) {
      this._findWord = params.word;
      this._findWordOptions = params.options;
    }
  }

  deActivate() {
    this._findWord = '';
    this._findWordOptions = {};
  }
}
