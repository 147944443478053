import * as React from 'react';

import { IInvalidElement, IInvalidUnit } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { defaultValidationError, formatGroups, getReasonTextAbbreviated } from '../../../../../utils/InvalidDTDUtils';
import ReasonBox from './ReasonBox';
import { ExpandedUnitElement } from './InvalidUnitsList';

export type Props = {
  invalidUnit: IInvalidUnit;
  invalidElement: IInvalidElement;
  expanded?: ExpandedUnitElement;
  onExpand: (unit?: IInvalidUnit, element?: IInvalidElement) => void;
};

const InvalidElement = (props: Props) => {
  const [reason, setReason] = React.useState<string>('');

  const expandReason = (e: React.MouseEvent<HTMLButtonElement>, invalidElement: IInvalidElement) => {
    e.stopPropagation();

    setReason(formatGroups(invalidElement.reason ?? defaultValidationError, props.invalidUnit.closestTocableUid, false));
    if (isExpanded()) {
      props.onExpand();
    } else {
      props.onExpand(props.invalidUnit, props.invalidElement);
    }
  };

  const isExpanded = () => {
    return props.expanded?.element && props.expanded?.element?.nid === props.invalidElement.nid;
  };

  const openElement = (e: React.MouseEvent<HTMLDivElement>, invalidElement: IInvalidElement) => {
    e.stopPropagation();

    EditorStore.openDocumentWithUnit(
      { uid: props.invalidUnit.unitUid },
      invalidElement.nid,
      undefined,
      undefined,
      props.invalidUnit.closestTocableUid
    );
  };

  return (
    <div className="invalid-element" onClick={(e) => openElement(e, props.invalidElement)}>
      <div className="invalid-element-heading">{props.invalidElement.heading}</div>
      {props.invalidElement.reason ? (
        <div className="invalid-element-reason">
          <span className="invalid-unit-reason-text">{getReasonTextAbbreviated(props.invalidElement.reason)}</span>
          <button onClick={(e) => expandReason(e, props.invalidElement)} className="invalid-element-reason-show">
            {isExpanded() ? 'Collapse' : 'Expand'}
          </button>
          <ReasonBox isOpen={!!isExpanded()}>
            <div dangerouslySetInnerHTML={{ __html: reason }} />
          </ReasonBox>
        </div>
      ) : undefined}
    </div>
  );
};

export default InvalidElement;
