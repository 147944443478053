import { Cancelled, mm } from './base-clients';
import { IComment } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import userUtil from '../components/documents/team/util/user';
import { CommentStatus, ExternalUserType } from '../flux/editor/CommentStore';
import ActiveUserStore from '../flux/common/ActiveUserStore';
import { dateUtil } from '../utils';

let getAllSource: CancelTokenSource | null = null;
let unitCommentMapSource: CancelTokenSource | null = null;
let getStatusesSource: CancelTokenSource | null = null;

export type GetOptions = {
  unread: 'unread' | 'read' | 'all';
  fromDateTime: number | null;
  status: CommentStatus;
  externalUserType: ExternalUserType;
  users: string[] | null;
};

/**
 * Gets all comments
 */
export async function getAll(
  projectUid: string,
  indexUid: string,
  options?: Partial<GetOptions>,
  tocUnitUid?: string
): Promise<Cancelled | IComment[]> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];

  if (options) {
    if (options.unread) {
      options.unread === 'unread' ? query.push('unread=' + true) : query.push('unread=' + false);
    }
    if (options.fromDateTime) {
      query.push('fromDateTime=' + options.fromDateTime);
    }
    if (options.status) {
      query.push('status=' + options.status);
    }
    if (options.externalUserType) {
      query.push('commentType=' + options.externalUserType);
    }
    if (options.users) {
      query.push('userUids=' + options.users.join(','));
    }
    if (tocUnitUid) {
      query.push(`tocUnitUid=${tocUnitUid}`);
    }
  }

  try {
    const response = await mm.get<{ comments: IComment[] }>(`/projects/${projectUid}/indexes/${indexUid}/comments?${query.join('&')}`, {
      cancelToken: getAllSource.token
    });

    getAllSource = null;
    const comments = response.data.comments || [];
    for (const comment of comments) {
      parse(comment);
    }

    return comments;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

function parse(comment: IComment) {
  comment.allowCommentChange = comment.commenter.uid === ActiveUserStore.getUser()!.uid;
  comment.commenter.avatarUrl = userUtil.imagePath(comment.commenter);
  comment.modifiedFormatAgo = dateUtil(comment.modified).fromNow();

  if (!comment.replies) {
    comment.replies = [];
  }

  comment.replies.forEach((reply) => parse(reply));
  return comment;
}

/**
 * Gets all comments
 */
export async function getUnitCommentMap(projectUid: string, indexUid: string): Promise<Cancelled | IComment[]> {
  if (unitCommentMapSource) {
    unitCommentMapSource.cancel();
  }

  unitCommentMapSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ [unitId: string]: string[] }>(`/projects/${projectUid}/indexes/${indexUid}/comments/map`, {
      cancelToken: unitCommentMapSource.token
    });

    unitCommentMapSource = null;

    if (!response) {
      return new Cancelled();
    }
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Gets a single comment
 */
export async function getOne(projectUid: string, indexUid: string, uid: string, options?: { fullThread: boolean }) {
  const query: string[] = [];
  if (options) {
    if (options.fullThread) {
      query.push('fullThread=true');
    }
  }

  const response = await mm.get<IComment>(`/projects/${projectUid}/indexes/${indexUid}/comments/${uid}?${query.join('&')}`);
  const comment = response.data;
  parse(comment);
  return comment;
}

export async function getCommentStatus(projectUid: string, indexUid: string, userUid: string): Promise<Cancelled | IComment[]> {
  if (getStatusesSource) {
    getStatusesSource.cancel();
  }

  getStatusesSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ comments: IComment[] }>(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/comments`, {
      cancelToken: getStatusesSource.token
    });

    if (!response) {
      return new Cancelled();
    }

    return response.data.comments;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function setCommentStatus(projectUid: string, indexUid: string, userUid: string, comment: Partial<IComment>, readAll = false) {
  if (readAll) {
    await mm.post(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/comments/readAll`, comment);
  } else {
    await mm.post(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/comments/${comment.uid}`, comment);
  }
}

/**
 * Updates an comment
 */
export async function update(projectUid: string, indexUid: string, uid: string, token: Partial<IComment>) {
  const response = await mm.put<IComment>(`/projects/${projectUid}/indexes/${indexUid}/comments/${uid}`, token);
  return response.data;
}

/**
 * Creates a new comment
 */
export async function create(projectUid: string, indexUid: string, token: Partial<IComment>) {
  const response = await mm.post<IComment>(`/projects/${projectUid}/indexes/${indexUid}/comments`, token);
  return response.data;
}
