import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Popover from 'material-ui/Popover';
import IconButton from 'material-ui/IconButton';
import DropDownOption from './DropDownOption';

export type Props = {
  icon?: string;
  className?: string;
  containerClassName?: string;
  onSelect: (value) => void;
  children?: React.ReactNode;
};

export type State = {
  open: boolean;
  anchorEl?: React.ReactInstance;
};

export default class DropDownIcon extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    icon: 'more_horiz',
    className: ''
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  close() {
    if (this.state.open) {
      // important, only close *if* open (manages visibility state in its container)
      this._handleClose();
    }
  }

  _renderOptions() {
    const options: JSX.Element[] = [];

    if (this.state.open) {
      React.Children.forEach(this.props.children, (child: any) => {
        if (child.type.constructor === DropDownOption.constructor) {
          if (child.props.show) {
            options.push(
              React.cloneElement(child, {
                onSelect: (value, delayCloseOnSelect) => {
                  this.props.onSelect(value);

                  if (delayCloseOnSelect !== null) {
                    setTimeout(() => {
                      this.close();
                    }, delayCloseOnSelect);
                  } else {
                    this.close();
                  }
                },
                key: child.props.value
              })
            );
          }
        }
      });
    }

    return options;
  }

  _handleOpen(e: React.MouseEvent<{}>) {
    this.setState({ open: true, anchorEl: e.currentTarget as React.ReactInstance });
    e.preventDefault();
    e.stopPropagation();
  }

  _handleClose() {
    this.setState({ open: false });
  }

  _handleBlur() {
    // give next element a tick to take focus
    setTimeout(() => {
      if (this.refs && this.refs.popupContainer) {
        if (!(ReactDOM.findDOMNode(this.refs.popupContainer) as Element).contains(document.activeElement)) {
          this.close();
        }
      }
    }, 1);
  }

  render() {
    return (
      <div className={this.props.className} onBlur={() => this._handleBlur()}>
        <IconButton
          iconClassName="material-icons vert"
          onClick={(e) => {
            this._handleOpen(e);
          }}
        >
          {this.props.icon}
        </IconButton>

        <Popover
          animated={(window as any).runningGebs as boolean}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <div className={this.props.containerClassName + ' arconics-dropdown'} tabIndex={0} ref="popupContainer">
            {this._renderOptions()}
          </div>
        </Popover>
      </div>
    );
  }
}
