import * as React from 'react';
import * as _ from 'lodash';
import { Match, Word } from '../../../../../flux/editor/FindReplaceStore';
import { ILink } from 'mm-types';
import { parseSnippet } from '../../../../../utils/wordlist';

export type Props = {
  selected: Word;
  globalMatchesCount: number;
  globalMatches: Match[];
  onGlobalSearchClick: (link: ILink, indexWithinParent: number) => void;
  getChapterName: (uid: string) => string;
};

export type State = {
  open: Set<string>;
};

export default class SearchResults extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: new Set()
    };
  }

  _findAndOpenChapter(props: Props) {
    if (props.selected && props.globalMatches) {
      const open = props.globalMatches.find((gm) => (gm.matches.find((m) => m.link.unitUid === props.selected.parent) ? true : false));

      // find chapter and add to open state
      if (open) {
        const updateState = this.state.open;
        updateState.add(open.chapter);
        this.setState({ open: updateState });
      }
    }
  }

  UNSAFE_componentWillMount() {
    this._findAndOpenChapter(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(nextProps.selected, this.props.selected)) {
      this._findAndOpenChapter(nextProps);
    }
  }

  renderChildren(i, chapter: Match) {
    if (this.state.open.has(chapter.chapter)) {
      return chapter.matches.map((m, j) => {
        const { unitUid, indexWithinParent } = parseSnippet(m.snippet);
        const selected =
          !!this.props.selected && this.props.selected.parent === unitUid && indexWithinParent === this.props.selected.indexWithinParent;

        return (
          <div id={`match${i}-${j}`} key={`match${i}-${j}`} className={'parent-fragment ' + (selected ? 'highlighted' : '')}>
            <div
              id={`match${i}-${j}`}
              className="fragment"
              onClick={() => this.props.onGlobalSearchClick(m.link, indexWithinParent)}
              dangerouslySetInnerHTML={{ __html: m.snippet }}
            />{' '}
          </div>
        );
      });
    }
  }

  renderBullet(chapter: Match) {
    return <div className={'bullet icon-menu-bullet-' + (this.state.open.has(chapter.chapter) ? 'opened' : 'closed')} />;
  }

  toggle(chapter: Match) {
    const updateState = this.state.open;
    if (updateState.has(chapter.chapter)) {
      updateState.delete(chapter.chapter);
    } else {
      updateState.add(chapter.chapter);
    }

    this.setState({ open: updateState });
  }

  render() {
    return (
      <div className="search-results" ref="root">
        {this.renderSearchResultsHeader()}
        <div className="results">
          {this.props.globalMatches.map((chapter, i) => {
            const chapterName = this.props.getChapterName(chapter.chapter);
            if (chapterName && chapter.matches.length > 0) {
              return (
                <div className="result" key={`chapter${i}`}>
                  <div className="result-header" onClick={() => this.toggle(chapter)}>
                    <div className="title">
                      {this.renderBullet(chapter)}
                      {chapterName}
                    </div>
                    <div className="matches">{chapter.matches.length}</div>
                  </div>
                  <div className="result-list">{this.renderChildren(i, chapter)}</div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }

  renderSearchResultsHeader() {
    return (
      <div className="header">
        <div className="title">Total Matches</div>
        <div className="matches">{this.props.globalMatchesCount}</div>
      </div>
    );
  }
}
