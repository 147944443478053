import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as client from '../../clients/activities';
import TocStore from './TocStore';
import EditorStore from './EditorStore';
import { ActivityListViewTypes } from '../../components/editor/sidetabs/sub/activitylog/ActivityListTypes';
import Store from '../Store';
import { DocParams, IActivity } from 'mm-types';
import ActiveUserStore from '../common/ActiveUserStore';
import { Cancelled } from '../../clients/base-clients';

export type State = {
  activityEntries: IActivity[];
  busy: boolean;
  nextCursor: string | null;
  prevCursor: string | null;
};

export type ActivityLogStoreEvent = {
  type: 'pagination' | null;
  state: State;
};

/*
 * ActivityLogStore: has a state of activityEntries, which can come from one of 4 sources as defined in ActivityListTypes
 */
export class ActivityLogStore extends Store<State> {
  private _currentDocIndexUid: string | null;
  // private _xhrRetrieveUndoEntries: JQueryXHR;

  constructor() {
    super();
    this._currentDocIndexUid = null;

    this.state = {
      busy: false,
      nextCursor: null,
      prevCursor: null,
      activityEntries: [] // can represent differing list of JSON depending on ActivityListType
    };
  }

  getInitialState() {
    return this.state;
  }

  getCurrentDocIndex() {
    return this._currentDocIndexUid;
  }

  // Event Handlers

  retrieveLogs(docParams: DocParams, listType: ActivityListViewTypes, reset = false) {
    if (listType === 'ACTIVITIES_ALL' || listType === 'ACTIVITIES_TOC_SELECTED' || listType === 'ACTIVITIES_UNIT_SELECTED') {
      if (reset) {
        this.state = { ...this.state, nextCursor: null, prevCursor: null, activityEntries: [] };
      }

      this._retrieveActivityEntries(docParams, listType);
    } else if (listType === 'UNDO_HISTORY') {
      this._retrieveUndoEntries(docParams);
    }
  }

  // note: activity (audit) list api only for now
  async retrieveLogsPage(docParams: DocParams, direction: { next?: boolean; previous?: boolean }, listType: ActivityListViewTypes) {
    if (listType === 'ACTIVITIES_ALL' || listType === 'ACTIVITIES_TOC_SELECTED' || listType === 'ACTIVITIES_UNIT_SELECTED') {
      if (!this.state.busy) {
        const params: Partial<client.GetOptions> = {};
        if (listType === 'ACTIVITIES_ALL') {
          params.includeChildren = true;
        } else if (listType === 'ACTIVITIES_TOC_SELECTED') {
          params.unitUid = TocStore.getSelectedItem()!.uid;
          params.includeChildren = true;
        } else if (listType === 'ACTIVITIES_UNIT_SELECTED') {
          params.unitUid = EditorStore.getSelectedUnit()!.uid;
        }

        params.cursor = this.state.nextCursor;

        this.state.busy = true;

        const response = await client.getAll(docParams.projectUid!, docParams.indexUid!, params);

        if (response instanceof Cancelled) {
          return;
        }

        const activities = this._getAsCollapsedJSON(response.activityEntries).filter((a) => {
          return this.state.activityEntries.find((sa) => sa.uid === a.uid) ? false : true;
        });

        this.state = {
          ...this.state,
          nextCursor: response.nextCursor,
          prevCursor: response.previousCursor,
          activityEntries: this.state.activityEntries.concat(activities),
          busy: false
        };

        this.trigger({ type: 'pagination', state: this.state } as ActivityLogStoreEvent);
      }
    }
  }

  private _getAsCollapsedJSON(activities: IActivity[]) {
    const activitiesList = activities;
    const collapsedActivitiesList: IActivity[] = [];

    for (let i = 0; i < activitiesList.length; i++) {
      const currentActivity = activitiesList[i];
      const firstCollapsedEntry = collapsedActivitiesList[0];
      const isGroupActivity = firstCollapsedEntry ? _.isUndefined(firstCollapsedEntry.unitUid) : false;

      // set as children activities
      if (collapsedActivitiesList.length > 0 && !isGroupActivity && firstCollapsedEntry.unitUid === currentActivity.unitUid) {
        // look ahead for next possible children
        while (i < activitiesList.length && firstCollapsedEntry.unitUid === activitiesList[i].unitUid) {
          firstCollapsedEntry.childrenActivities = !firstCollapsedEntry.childrenActivities ? [] : firstCollapsedEntry.childrenActivities;
          firstCollapsedEntry.childrenActivities.push(activitiesList[i]);
          i++;
        }
        i--;
      }
      // set at top level
      else {
        collapsedActivitiesList.unshift(currentActivity);
      }
    }

    collapsedActivitiesList.reverse();
    collapsedActivitiesList.forEach((a) => {
      if (a.childrenActivities && a.childrenActivities.length) {
        a.childrenActivities = _.uniqBy(a.childrenActivities, 'uid');
      }
    });

    return collapsedActivitiesList;
  }

  async _retrieveActivityEntries(docParams: DocParams, listType: ActivityListViewTypes) {
    this._currentDocIndexUid = docParams.indexUid!;

    const params: Partial<client.GetOptions> = {};
    if (listType === 'ACTIVITIES_ALL') {
      params.includeChildren = true;
    } else if (listType === 'ACTIVITIES_TOC_SELECTED') {
      const selectedItem = TocStore.getSelectedItem();

      // sometimes when a page loads selectedUnit could be null temporarily - ignore such scenarios...
      if (selectedItem) {
        params.unitUid = selectedItem!.uid;
      }

      params.includeChildren = true;
    } else if (listType === 'ACTIVITIES_UNIT_SELECTED') {
      const selectedUnit = EditorStore.getSelectedUnit();

      // sometimes when a page loads selectedUnit could be null temporarily - ignore such scenarios...
      if (selectedUnit) {
        params.unitUid = EditorStore.getSelectedUnit()!.uid;
      } else {
        return;
      }
    }

    this.state.busy = true;

    const response = await client.getAll(docParams.projectUid!, docParams.indexUid!, params);

    this.state.busy = false;

    if (response instanceof Cancelled) {
      return;
    }

    const retrievedActivities: IActivity[] = this._getAsCollapsedJSON(response.activityEntries);

    this.state = {
      ...this.state,
      nextCursor: response.nextCursor,
      prevCursor: response.previousCursor,
      activityEntries: retrievedActivities
    };

    this.trigger({ type: null, state: this.state } as ActivityLogStoreEvent);
  }

  async _retrieveUndoEntries(docParams: DocParams) {
    const response = await client.getActions(docParams.projectUid!, docParams.indexUid!, ActiveUserStore.getUser()!.uid);

    if (response instanceof Cancelled) {
      return;
    }

    this.state = { ...this.state, activityEntries: response.activityEntries! };
    this.trigger({ type: null, state: this.state } as ActivityLogStoreEvent);
  }
}

const singleton = Reflux.initStore<ActivityLogStore>(ActivityLogStore);
export default singleton;
