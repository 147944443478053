import * as Reflux from 'reflux';
import * as _ from 'lodash';

import WorkspaceStore from '../common/WorkspaceStore';
import * as projectsClient from '../../clients/projects';
import { bulkUpdate } from '../../clients/projects';
import * as projectClient from '../../clients/project';

import * as workspaces from '../../clients/workspaces';
import * as indexClient from '../../clients/index';
import Store from '../Store';
import AppStateStore from '../common/AppStateStore';
import { IProject, IWorkspace, ProjectStatus } from 'mm-types';
import ProjectDefinitionStore from '../common/ProjectDefinitionStore';
import { StatusChangeType } from '../../components/projects/TeamspacesPage';
import { AxiosError } from 'axios';
import { Cancelled } from '../../clients/base-clients';
import IndexEventStore from '../events/IndexEventStore';
import ActiveUserStore from '../common/ActiveUserStore';

export type SortBy = 'TITLE' | 'TYPE' | 'REFERENCE' | 'OWNER' | 'MODIFIED' | 'WORKSPACE';
export type SortOrder = 'desc' | 'asc';

export type Sort = {
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type Page = {
  pageSize: number;
  pageNumber: number;
  numOfPages: number;
  totalElements: number;
};

export type DocStoreEventType = 'projects-loaded' | 'single-project-loading' | 'other' | 'update-page-size';

export type DocumentsStoreEvent = { type?: DocStoreEventType } & State & { error?: ErrorTypes } & {
    isNewProject?: boolean;
    project?: IProject;
  };

export type State = {
  filterProjectTypes: string[];
  contents: IProject[] | null;
  filter: Partial<DocFilter>;
  sort: Sort;
  page: Page;
  projectTypes: DocType[];
  filterText: string;
  canAdminWorkspaces: IWorkspace[] | null;
  selected: IProject[] | null;
  notfound: boolean;
  nopermission: boolean;

  // Created from elsewhere - seems really bad :S
  selectedWorkspace?: Partial<IWorkspace> | null;
  isLoading?: boolean;
  strings?: any;
  statusChange?: StatusChangeType;
};

export type DocFilter = {
  title: string;
  modifiedDate: Date;
  reference: string;
  ownerUid: string | null;
  username: string | null;
  projectType: string;
  pendingForApproval: boolean;
  hideExternal: boolean;
  hideExteProjectStatus: boolean;
  personalSharedWithMe: boolean;
  status: ProjectStatus;
  workspaceUid: string | null;
  sort: Partial<Sort>;
  page: Partial<Page>;
  firstProjectUid: string | null;
};

export type DocType = {
  id: string;
  name: string;
  selectable: boolean;
};

export type ErrorTypes = 'PROJECT_EXISTS' | 'PROJECT_GONE' | 'INDEX_EXISTS' | 'INDEX_GONE' | 'DRAFT_INDEX_DISCARD_ERROR' | 'NO_PERMISSION';

export const DEFAULT_PAGE_SIZE = 30;

class DocumentsStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      filterProjectTypes: [],
      contents: null,
      filter: {},
      sort: { sortBy: 'TITLE', sortOrder: 'asc' },
      page: { pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE, numOfPages: 0, totalElements: 0 },
      projectTypes: [],
      filterText: '',
      canAdminWorkspaces: null,
      selected: null,
      notfound: false, // i.e. do we get a 404 - state level (not handled as api level error)
      nopermission: false // i.e. do we get a 403 - state level (not handled as api level error)
    };
  }

  getInitialState() {
    return this.state;
  }

  getCanAdminWorkspaces() {
    return this.state.canAdminWorkspaces;
  }

  getProjectItem(projectUid: string) {
    return this.state.contents ? this.getProjectByUid(this.state.contents, projectUid) : undefined;
  }

  getSelectedProjects() {
    return this.state.selected;
  }

  getProjectFilterText() {
    return this.state.filterText;
  }

  // Event Handlers

  projectDefinitionInitSuccess(data?: Partial<State>) {
    if (data && data.projectTypes) {
      this.state.projectTypes = data.projectTypes;
      this.trigger(this.state);
    }
  }

  async retrieveCanAdminWorkspaces() {
    const workspacesReturned = await workspaces.getAll({ canAdministerOnly: true });
    this.state.canAdminWorkspaces = workspacesReturned.filter((w) => w.type === 'TEAM');
  }

  async updateProjectStatus(selectedProjects: IProject[], workspaceUid: string, mode: StatusChangeType) {
    if (selectedProjects && workspaceUid && mode) {
      const projects = selectedProjects.map((project) => {
        return {
          uid: project.uid,
          status: mode
        };
      });

      try {
        const response = await bulkUpdate(projects);

        if (response.errors && Object.keys(response.errors!).length > 0) {
          // leave only errors project selected
          this.state.selected = this.state.selected!.filter((project) => {
            const filtered = Object.keys(response.errors!).filter((projectUid) => projectUid === project.uid);
            return filtered.length > 0;
          });

          this.trigger(this.state as DocumentsStoreEvent);
        } else {
          this.toggleProjectSelect(null);
        }

        // TODO name collision handling Model.name !== newModel.name
        this.trigger({ statusChange: mode, response: response });
        this.retrieveDocs(workspaceUid, this.state.sort);
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response && axiosErr.response.status === 403) {
          this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
        } else {
          this.trigger({ error: 'PROJECT_GONE' } as DocumentsStoreEvent);
        }
      }
    } else {
      this.trigger({ error: 'PROJECT_GONE' } as DocumentsStoreEvent);
    }
  }

  toggleProjectSelect(projectUid: string | null) {
    if (
      projectUid === null ||
      (this.state.selected && this.state.selected.length > 0 && _.find(this.state.selected, { uid: projectUid }))
    ) {
      // if null or already selected
      if (this.state.selected && this.state.selected.length === 1) {
        this.state.selected = null;
      } else {
        this.state.selected =
          this.state.selected?.filter((selected) => {
            return selected.uid !== projectUid;
          }) ?? null;
      }
      this.trigger(this.state as DocumentsStoreEvent);
    } else {
      this.updateProjectSelect([projectUid]);
    }
  }

  toggleAllProjectSelect() {
    if (this.state.selected) {
      this.state.selected = null;
    } else {
      this.state.selected = this._getProjectsAsJSON();
    }

    this.trigger(this.state as DocumentsStoreEvent);
  }

  async updateSingleProjectSelect(project: IProject, clearOthers = false) {
    this.state.selected = [project];
    const ev: DocumentsStoreEvent = this.state;
    ev.type = 'single-project-loading';
    this.trigger(ev);
    return this.updateProjectSelect([project.uid], clearOthers);
  }

  async updateProjectSelect(projectUids: string[] | null, clearOthers = false) {
    if (projectUids && projectUids.constructor !== Array) {
      console.log('WARNING: projectUids should be an array.');
      return;
    }

    if (projectUids === null) {
      this.state.selected = null;
      this.trigger(this.state as DocumentsStoreEvent);
    } else {
      if (clearOthers) {
        this.state.selected =
          this.state.selected?.filter((selected) => {
            return selected.uid === projectUids[0];
          }) ?? null;
      }

      try {
        const projects = await Promise.all(projectUids.map((pUid) => projectClient.getProject(pUid)));

        if (!this.state.selected) {
          this.state.selected = [];
        }

        // Add new or replace existing project in selected
        for (const project of projects) {
          const projectIndexInSelected = this.state.selected.findIndex((elem) => elem.uid === project.uid);

          if (projectIndexInSelected > -1) {
            this.state.selected[projectIndexInSelected] = project;
          } else {
            this.state.selected.push(project);
          }

          const projectIndexInContents = this.state.contents?.findIndex((elem) => elem.uid === project.uid) ?? -1;
          if (this.state.contents && projectIndexInContents > -1) {
            this.state.contents[projectIndexInContents] = project;
          }
        }

        const ev: DocumentsStoreEvent = this.state;
        ev.type = 'projects-loaded';
        this.trigger(ev);
        ev.type = 'other';
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response && axiosErr.response.status === 403) {
          this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
        } else {
          this.trigger({ error: 'PROJECT_GONE' } as DocumentsStoreEvent);
        }
      }
    }
  }

  async createProject(workspace: IWorkspace) {
    let defaultDefinitions = ProjectDefinitionStore.getProjectDefinitions().filter((def) => def.isDefault);

    if (defaultDefinitions.length === 0) {
      defaultDefinitions = ProjectDefinitionStore.getProjectDefinitions().filter((def) => def.userCreatable);
    }

    const definitionName = defaultDefinitions[0].name;

    const newProject = await projectClient.create({ status: 'DRAFT', workspaceUid: workspace.uid, definitionName });
    this.openProjectConfig(newProject);
  }

  openProjectConfig(project?: IProject) {
    if (project) {
      this.trigger({ isNewProject: true, project: project } as DocumentsStoreEvent); // hate doing this as its the only case that breaks the pattern :(
    }
  }

  async discardDraft(project: IProject, comment: string) {
    try {
      await indexClient.discardDraft(project.uid, project.masterIndexUid, { comment: comment });
      IndexEventStore.joinNewIndex(project.masterIndexUid);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'discarded',
        data: {
          indexUid: project.masterIndexUid,
          projectUid: project.uid
        }
      });
      IndexEventStore.leaveCurrentIndex();
      await this.updateProjectSelect([project.uid]);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 403) {
        this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
      } else {
        this.trigger({ error: 'DRAFT_INDEX_DISCARD_ERROR' } as DocumentsStoreEvent);
      }
    }
  }

  async createInterim(project: IProject) {
    try {
      await indexClient.createInterim(project.uid, project.masterIndexUid);
      await this.updateProjectSelect([project.uid]);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: 'INDEX_EXISTS' } as DocumentsStoreEvent);
      } else if (axiosErr.response && axiosErr.response.status === 403) {
        this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
      } else {
        this.trigger({ error: 'INDEX_GONE' } as DocumentsStoreEvent);
      }
    }
  }

  async deleteInterim(project: IProject) {
    try {
      await indexClient.remove(project.uid, project.interimIndexUid);
      IndexEventStore.joinNewIndex(project.interimIndexUid);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'discarded',
        data: {
          indexUid: project.interimIndexUid,
          projectUid: project.uid
        }
      });
      IndexEventStore.leaveCurrentIndex();
      await this.updateProjectSelect([project.uid]);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 403) {
        this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
      } else {
        this.trigger({ error: 'INDEX_GONE' } as DocumentsStoreEvent);
      }
      throw err;
    }
  }

  async cloneProject(clonedProject: { cloneFromProjectUid: string; clonedProjectTitle: string }) {
    try {
      await projectClient.clone(clonedProject.cloneFromProjectUid, {
        name: clonedProject.clonedProjectTitle
      });
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: 'PROJECT_EXISTS' } as DocumentsStoreEvent);
      } else if (axiosErr.response && axiosErr.response.status === 403) {
        this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
      } else {
        this.trigger({ error: 'PROJECT_GONE' } as DocumentsStoreEvent);
      }
    }
  }

  async retrieveDocs(workspaceUid?: string | undefined, sort?: Partial<Sort>, page?: Partial<Page>, selectedProjectUid?: string | null) {
    const newSort: Sort = Object.assign({
      sortBy: sort?.sortBy ?? this.state.sort.sortBy,
      sortOrder: sort?.sortOrder ?? this.state.sort.sortOrder
    });
    const newPage: Page = Object.assign({ pageNumber: 0, pageSize: this.state.page.pageSize, numOfPages: 0, totalElements: 0, ...page });

    let data: Partial<IProject> = {};

    if (workspaceUid === 'all') {
      data = {};
    } else if (workspaceUid === 'shared') {
      data.personalSharedWithMe = true;
    } else if (workspaceUid === 'trash') {
      data.status = 'TRASHED';
    } else if (_.isUndefined(workspaceUid) || workspaceUid === 'workspace') {
      const personalWorkspace = WorkspaceStore.getPersonalWorkspace();
      if (personalWorkspace) {
        data.workspaceUid = personalWorkspace.uid;
      } else {
        // stop retrieving docs, personal workspace is not loaded yet
        return;
      }
    } else {
      data.workspaceUid = workspaceUid;
    }

    // Make sure sort & page are saved in the state before the api request is invoked
    this.state = _.extend(this.state, {
      sort: newSort,
      page: newPage
    });

    AppStateStore.updateMyLibs({ sort: newSort, page: newPage });

    try {
      const projectsResponse = await projectsClient.getAll({
        ...this.state.filter,
        personalSharedWithMe: data.personalSharedWithMe,
        status: data.status,
        workspaceUid: data.workspaceUid,
        sort: newSort,
        page: newPage,
        firstProjectUid: selectedProjectUid
      });

      if (projectsResponse instanceof Cancelled) {
        return;
      }

      const selected: IProject | undefined = this.getProjectByUid(projectsResponse.projects, selectedProjectUid);
      const prevSelected = this.state.selected?.length === 1 ? this.state.selected[0] : null;
      const hasSameUid = prevSelected && prevSelected.uid === selected?.uid;
      this.state = _.extend(this.state, {
        filterProjectTypes: projectsResponse.projectTypes,
        contents: projectsResponse.projects,
        selected: hasSameUid ? [prevSelected] : selected ? [selected] : [], // with every new page of projects reset projects which has been selected so far
        page: projectsResponse.page
      });

      const ev: DocumentsStoreEvent = this.state;
      ev.type = 'projects-loaded';
      this.trigger(ev);
    } catch (err) {
      const axiosErr = err as AxiosError;

      if (axiosErr.response && axiosErr.response.status !== 0) {
        // ignore aborts
        if (axiosErr.response.status === 403) {
          this.trigger({ error: 'NO_PERMISSION' } as DocumentsStoreEvent);
          return;
        } else {
          // see SystemStore ajaxError: this prevents an infinite loop of redirects as when backend goes: 502's leak thru to here
          if (axiosErr.response.status !== 502) {
            this.trigger({ error: 'PROJECT_GONE' } as DocumentsStoreEvent);
            return;
          }
        }
      }
    }
  }

  getProjectByUid(projects: IProject[], projectUid?: string | null): IProject | undefined {
    if (!projects.length || !projectUid) {
      return undefined;
    }
    return projects.find((project) => project.uid === projectUid);
  }

  clearFilter() {
    this.state.filterText = '';
  }

  _getProjectsAsJSON() {
    return this.state.contents;
  }

  updateDocsFilter(filter: Partial<DocFilter>, callback?: () => void) {
    this.state.filter = filter || {};
    this.trigger({ filter: this.state.filter } as DocumentsStoreEvent);

    if (callback) {
      callback();
    }
  }

  clearDocsFilter() {
    this.state.filter = {};
    this.trigger({ filter: this.state.filter } as DocumentsStoreEvent);
  }

  getDocsFilter() {
    return this.state.filter;
  }

  getProjectTypes() {
    return this.state.projectTypes;
  }

  getFilterProjectTypes() {
    return this.state.filterProjectTypes;
  }

  setPageSize(newPageSize: number) {
    this.state.page = { ...this.state.page, pageSize: newPageSize };
    this.trigger({ ...this.state, type: 'update-page-size' } as State);
  }
}

const singleton = Reflux.initStore<DocumentsStore>(DocumentsStore);
export default singleton;
