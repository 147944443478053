import React from 'react';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import { Divider, Menu, MenuItem, SubMenu } from '../../../EditorMenu';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { PROJECT_DEFINITION_TYPES } from '../../../../../../flux/common/ProjectDefinitionStore';

const LAYERS = new Map([
  ['SHOW_ALL', 'Show All'],
  ['L1', 'L1'],
  ['L2', 'L1 & L2'],
  ['L3', 'L1, L2 & L3']
]);

const SelectLayerMode = () => {
  const project = ProjectStore.getProject();
  const [currentSelectedLayers, setCurrentSelectedLayers] = React.useState<string>('SHOW_ALL');
  const mode = EditorStore.getMode();

  React.useEffect(() => {
    if (currentSelectedLayers !== 'SHOW_ALL') {
      EditorStore.blurEditor(() => {
        EditorStore.changeModeStart('LAYERS');
      });
      document.body.classList.add('layer-mode-' + currentSelectedLayers.toLowerCase());
    } else {
      mode == 'LAYERS' && EditorStore.resetMode();
    }
    return () => {
      document.body.classList.remove('layer-mode-' + currentSelectedLayers.toLowerCase());
    };
  }, [currentSelectedLayers]);

  React.useEffect(() => {
    if (mode !== 'LAYERS') {
      setCurrentSelectedLayers('SHOW_ALL');
    }
  }, [mode]);

  const handleDropdownLayerModeClick = (e: { key: string }) => {
    setCurrentSelectedLayers(e.key);
  };

  if (project && project.definitionName == PROJECT_DEFINITION_TYPES.airbus) {
    return (
      <li className="icon-menu-container file-layer-menu">
        <Menu onClick={handleDropdownLayerModeClick} tabIndex="0" title="Layers" iconBeforeText="view_quilt" materialIconName="view_quilt">
          <SubMenu key="variantsubmenu" value="variantsubmenu" className="doc-variant">
            {Array.from(LAYERS.entries()).map(([id, name]) => {
              return (
                <React.Fragment key={id}>
                  <MenuItem value={id} className={currentSelectedLayers?.includes(id) ? 'highlight-menu-item-with-color' : ''}>
                    {name}
                  </MenuItem>
                  {id == 'SHOW_ALL' && <Divider />}
                </React.Fragment>
              );
            })}
          </SubMenu>
        </Menu>
      </li>
    );
  }

  return null;
};

export default SelectLayerMode;
