import { IProjectActivity } from 'mm-types';
import React from 'react';

type PropsType = {
  entry: IProjectActivity;
};

export const FrontmatterMoved = ({ entry }: PropsType) => {
  const frontmatterMovedDom: JSX.Element[] = [];
  frontmatterMovedDom.push(
    <div key="revision">
      <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
    </div>
  );
  return <div className="settings-details">{frontmatterMovedDom}</div>;
};
