import * as React from 'react';
import SharedContentInsertConfirm from '../../sharedcontentlib/SharedContentInsertConfirm';
import SharedContentRejectConfirm from '../../sharedcontentlib/SharedContentRejectConfirm';

type Props = {
  showDocumentOverlay: { type: 'rejectShareConfirm' | 'insertShareConfirm'; data: any } | null;
  onCloseRequest: () => void;
};

export const DocumentOverlay = ({ showDocumentOverlay, onCloseRequest }: Props) => {
  return (
    <>
      {showDocumentOverlay && (
        <div className="document-overlay">
          {showDocumentOverlay.type === 'insertShareConfirm' && (
            <SharedContentInsertConfirm sharedIndex={showDocumentOverlay.data} onCloseRequest={onCloseRequest} />
          )}
          {showDocumentOverlay.type === 'rejectShareConfirm' && (
            <SharedContentRejectConfirm data={showDocumentOverlay.data} onCloseRequest={onCloseRequest} />
          )}
        </div>
      )}
    </>
  );
};
