import React, { useEffect, useState } from 'react';
import DropDownSearchable, { IDropDownItem } from '../../misc/DropDownSearchable';
import DatePicker from 'material-ui/DatePicker';
import dateUtil from '../../../utils/DateUtil';
import { getWorkflowActionFilters } from '../../documents/kpi/kpiClient';
import { AxiosError } from 'axios';
import FlatButton from 'material-ui/FlatButton';

export type WorkflowActionsTileFiltersProps = {
  setFilterValues: (key, value) => void;
  filterValues: WorkflowActionsFilterValues;
  setIsError: (err: AxiosError | undefined) => void;
  resetFilterValues: () => void;
};

export type WorkflowActionsFilterValues = {
  users?: IDropDownItem[];
  department?: WorkflowActionsFilterDeptDropdownItem;
  org?: IDropDownItem;
  workspace?: IDropDownItem;
  to: Date;
  from: Date;
  page: number;
  size: number;
};

export type WorkflowActionsFilterDeptDropdownItem = IDropDownItem & { orgUid: string };

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  root: {
    width: '100%',
    position: 'relative',
    height: '63px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  filter: {
    minWidth: '150px'
  },
  fontWeight: {
    fontWeight: 500,
    paddingRight: '24px'
  },
  dateContainer: {
    display: 'flex'
  },
  dateText: {
    width: '150px'
  },
  menuStyle: {
    width: 'fit-content'
  },
  filterResetButton: {
    borderLeft: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 16,
    marginBottom: 16
  }
};

export const alphaNumDropdownSort = (
  toBeSorted?: IDropDownItem[] | WorkflowActionsFilterDeptDropdownItem[]
): IDropDownItem[] | WorkflowActionsFilterDeptDropdownItem[] | undefined => {
  return toBeSorted?.sort((a, b) => {
    const nameA = a.text.toLowerCase();
    const nameB = b.text.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

function WorkflowActionsTileFilters({ setFilterValues, filterValues, setIsError, resetFilterValues }: WorkflowActionsTileFiltersProps) {
  const [dropdownUsers, setDropdownUsers] = useState<IDropDownItem[]>([]);
  const [orgs, setOrgs] = useState<IDropDownItem[]>([]);
  const [depts, setDepts] = useState<WorkflowActionsFilterDeptDropdownItem[]>([]);
  const [teamspaces, setTeamspaces] = useState<IDropDownItem[]>([]);
  const getAndSetFilters = () => {
    setIsError(undefined);
    getWorkflowActionFilters({
      to: filterValues.to,
      from: filterValues.from,
      departmentUid: filterValues.department?.value,
      orgUid: filterValues.org?.value,
      workspaceUid: filterValues.workspace?.value,
      userUids: filterValues.users?.[0].value ? [filterValues.users?.[0].value] : []
    })
      .then((res) => {
        const formattedDepts: WorkflowActionsFilterDeptDropdownItem[] | undefined = res.departments?.map((dept) => {
          return { value: dept.uid, text: dept.name, orgUid: dept.orgUid };
        });
        setDepts((alphaNumDropdownSort(formattedDepts) as WorkflowActionsFilterDeptDropdownItem[]) ?? []);
        const formattedOrgs: IDropDownItem[] | undefined = res.orgs?.map((org) => {
          return { value: org.uid, text: org.name };
        });
        setOrgs(alphaNumDropdownSort(formattedOrgs) ?? []);
        const formattedWorkspaces: IDropDownItem[] | undefined = res.workspaces?.map((workspace) => {
          return { value: workspace.uid, text: workspace.name };
        });
        setTeamspaces(alphaNumDropdownSort(formattedWorkspaces) ?? []);
        const formattedUsers: IDropDownItem[] | undefined = res.users?.map((user) => {
          return { value: user.uid, text: `${user?.name ?? ''} ${user?.staffId ?? ''}` };
        });
        setDropdownUsers(alphaNumDropdownSort(formattedUsers) ?? []);
      })
      .catch((err: AxiosError) => setIsError(err));
  };

  const getOrgFromDept = (deptOrgUid: string): IDropDownItem => {
    return orgs.filter((org) => org.value === deptOrgUid)[0];
  };

  useEffect(() => {
    // Only need to get filters if 5 or less filter values are set i.e we already have all the filter options even if the 6th one isn't set
    Object.keys(filterValues).forEach((key) => {
      if (!filterValues[key]) {
        delete filterValues[key];
      }
    });
    if (Object.keys(filterValues).length <= 5) {
      getAndSetFilters();
    }
  }, [
    filterValues.from,
    filterValues.to,
    filterValues.workspace?.value,
    filterValues.org?.value,
    filterValues.department?.value,
    filterValues.users?.[0].value
  ]);

  useEffect(() => {
    if (filterValues.department) {
      setFilterValues('org', getOrgFromDept(filterValues.department.orgUid));
    }
  }, [filterValues.department?.value]);

  return (
    <div className="workflow-actions-filters" style={defaultStyles.root}>
      <div style={{ ...defaultStyles.dateContainer }}>
        <DatePicker
          value={filterValues.from}
          floatingLabelText="From"
          onChange={(e, date) => {
            setFilterValues('from', date);
          }}
          formatDate={(d) => dateUtil(d).formatDate()}
          textFieldStyle={{ ...defaultStyles.dateText }}
          data-qa="workflow-actions-from-date"
          dialogContainerStyle={{ color: 'fuchsia' }}
          shouldDisableDate={(day: Date) => day > filterValues.to}
        />
        <DatePicker
          value={filterValues.to}
          floatingLabelText="To"
          onChange={(e, date) => {
            setFilterValues('to', new Date(date.setHours(23, 59, 59, 999)));
          }}
          formatDate={(d) => dateUtil(d).formatDate()}
          textFieldStyle={{ ...defaultStyles.dateText }}
          data-qa="workflow-actions-to-date"
          dialogContainerStyle={{ color: 'fuchsia' }}
          shouldDisableDate={(day: Date) => day < filterValues.from}
        />
      </div>
      <DropDownSearchable
        title="Staff ID/Name"
        placeholder="Staff ID/Name"
        items={dropdownUsers}
        onChange={(item) => {
          setFilterValues('users', [item]);
        }}
        onReset={() => {
          setFilterValues('users', undefined);
        }}
        style={{ ...defaultStyles.fontWeight }}
        autoCompleteStyle={defaultStyles.filter}
        menuStyle={{ ...defaultStyles.menuStyle }}
        fullWidth={true}
        dataQa="workflow-actions-user-dropdown"
        defaultText={filterValues.users?.[0].text ?? ''}
      />
      <DropDownSearchable
        title="Organisation"
        placeholder="Organisation"
        items={orgs}
        onChange={(item) => {
          setFilterValues('org', item);
        }}
        onReset={() => {
          setFilterValues('org', undefined);
        }}
        style={{ ...defaultStyles.fontWeight }}
        autoCompleteStyle={defaultStyles.filter}
        menuStyle={{ ...defaultStyles.menuStyle }}
        fullWidth={true}
        dataQa="workflow-actions-organisation-dropdown"
        defaultText={filterValues.org?.text}
      />
      <DropDownSearchable
        title="Department"
        placeholder="Department"
        items={depts}
        onChange={(item) => {
          setFilterValues('department', item);
        }}
        onReset={() => {
          setFilterValues('department', undefined);
        }}
        style={{ ...defaultStyles.fontWeight }}
        autoCompleteStyle={defaultStyles.filter}
        menuStyle={{ ...defaultStyles.menuStyle }}
        fullWidth={true}
        dataQa="workflow-actions-department-dropdown"
        defaultText={filterValues.department?.text}
      />
      <DropDownSearchable
        title="Teamspace"
        placeholder="Teamspace"
        items={teamspaces}
        onChange={(item) => {
          setFilterValues('workspace', item);
        }}
        onReset={() => {
          setFilterValues('workspace', undefined);
        }}
        style={{ ...defaultStyles.fontWeight }}
        autoCompleteStyle={defaultStyles.filter}
        menuStyle={{ ...defaultStyles.menuStyle }}
        fullWidth={true}
        dataQa="workflow-actions-teamspace-dropdown"
        defaultText={filterValues.workspace?.text}
      />
      <div style={{ ...defaultStyles.filterResetButton }}>
        <FlatButton style={{ width: 'max-content' }} onClick={resetFilterValues} data-qa="workflow-actions-filter-reset-button">
          RESET FILTERS
        </FlatButton>
      </div>
    </div>
  );
}

export default WorkflowActionsTileFilters;
