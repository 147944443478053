import { ElementDetails } from '../../ElementDetails';
import { isEnterKey, isShiftKey } from '../../../keyIdentifier';
import { isCursorAtTheEndOfNode } from '../../../tinyFacade/TinyFacadeHelpers';
import React from 'react';
import { EditorManager } from 'tinymce';
import { CustomEditor } from '../../../tinyFacade/EditorInstanceManager';
declare const tinymce: EditorManager;

export namespace ListItemKeyBehavior {
  export function placeCursorInsideNewListItem(e: React.KeyboardEvent) {
    const { current, parent } = ElementDetails.getFocusedDetails();
    const editor = tinymce.activeEditor as CustomEditor;
    if (
      current &&
      isEnterKey(e) &&
      !isShiftKey(e) &&
      isCursorAtTheEndOfNode(editor) &&
      ElementDetails.isOfType(current, 'paragraph') &&
      parent &&
      ElementDetails.isOfType(parent, 'list-item')
    ) {
      editor.selection.select(current.targetElement);
      editor.selection.collapse(false);
      const rng = editor.selection.getRng(true);
      const emptyText = document.createTextNode(' ');
      editor.dom.insertAfter(emptyText, rng.endContainer);
    }
  }
}
