import { DocUnitEditProfiles } from '../../components/editor/utils/units/DocUnitEditProfiles';
/* FRAMEWORK */
import * as Reflux from 'reflux';
import * as _ from 'lodash';
import Store from '../Store';
/* UTILS */
import DocUnitPlacementRules from '../../components/editor/utils/tinyFacade/DocUnitPlacementRules';
import { arrayToMap, arrayToMapOfLists } from '../../utils';
import TwoWayLookup from '../../utils/TwoWayLookup';

import * as definitionsClient from '../../clients/definitions';

/* TYPES */
import {
  Color,
  IDocumentElementReformat,
  IDocumentUnitReformat,
  IElementDefinition,
  IIndexDefinition,
  IIndexStyle,
  IProjectDefinition,
  IProjectType,
  IUnit,
  IUnitDefinition,
  IUnitDefinitionFilter
} from 'mm-types';
import DocumentsStore from '../projects/DocumentsStore';
import { UnitTypes } from '../../components/editor/utils/units/UnitTypes';
import { AxiosError } from 'axios';
import TocStore from '../editor/TocStore';
import { getSelectedClosestTocable } from '../../components/hoc/common';
import { EditRibbonEnum } from '../../components/editor/utils/units/EditRibbonEnum';
import { INITIAL_BLACK } from '../../components/editor/sidetabs/sub/editComponent/controls/TableBorder';
import { canDisplayInContentMenu } from '../../components/editor/menus/insert/utils/utils';
import { DEFAULT_SUPPORTED_MEDIA } from '../../components/editor/medialib/MediaLibModal';

export enum PROJECT_DEFINITION_TYPES {
  airbus = 'airbus',
  help = 'help'
}

export enum UserCreatableType {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  WITHIN_STRUCTURE = 'WITHIN_STRUCTURE',
  WITHIN_STRUCTURE_ONLY_MULTI_VOLUME = 'WITHIN_STRUCTURE_ONLY_MULTI_VOLUME'
}

export enum FieldOption {
  OPTIONAL = 'optional',
  DISABLED = 'disabled',
  REQUIRED = 'required'
}

export type State = {
  projectDefinitions: IProjectDefinition[];
  indexDefinitions: IIndexDefinition[];
  projectTypes: IProjectType[];
  currentProjectDefinition: IProjectDefinition | null;
  unitDefinitions: IUnitDefinition[];
  elementDefinitions: IElementDefinition[];
  elementDefinitionsById: Map<string, IElementDefinition>;
  elementDefinitionsByType: Map<string, IElementDefinition[]>;
  unitDefinitionsById: Map<string, IUnitDefinition>;
  unitDefinitionsByType: Map<string, IUnitDefinition[]>;
  documentUnitReformats: IDocumentUnitReformat | null;
  documentElementReformats: IDocumentElementReformat | null;
};

export enum errors {
  PROJECT_DEFINITION_EXISTS = 'PROJECT_DEFINITION_EXISTS',
  INDEX_DEFINITION_EXISTS = 'INDEX_DEFINITION_EXISTS',
  INDEX_STYLE_DEFINITION_EXISTS = 'INDEX_STYLE_DEFINITION_EXISTS',
  PROJECT_DEFINITION_INUSE = 'PROJECT_DEFINITION_INUSE',
  INDEX_DEFINITION_INUSE = 'INDEX_DEFINITION_INUSE'
}

export enum ProjectDefinitionStoreEventType {
  SNACKBAR = 'snackbar',
  PROJECT_DEFINITIONS_RETRIEVED = 'projectDefinitionsRetrieved',
  INDEX_DEFINITIONS_RETRIEVED = 'indexDefinitionsRetrieved',
  SET_CURRENT_PROJECT_DEFINITION_SUCCESS = 'setCurrentProjectDefinitionSuccess'
}

export interface ProjectDefinitionStoreEvent {
  type: ProjectDefinitionStoreEventType;
  snackBarMessage?: string;
  error?: errors;
  state?: State;
  definitionUid?: string;
}

export class ProjectDefinitionStore extends Store<State> {
  private loaded: boolean;
  private isBusy: boolean;

  docUnitEditProfiles: DocUnitEditProfiles;

  constructor() {
    super();
    this.loaded = false;
    this.isBusy = false;
    this.docUnitEditProfiles = DocUnitEditProfiles.instance();
    this.clear();
  }

  getInitialState() {
    return this.state;
  }

  init(definitionUid?: string) {
    return this._onInit(definitionUid ? definitionUid : null);
  }

  initProject() {
    return this._onInit();
  }

  reInitProject() {
    this.clear();
    return this._onInit();
  }

  async createIndexStyleDefinition(definitionUid: string, newIndexStyleDefinition: IIndexStyle) {
    try {
      const data = new FormData();
      data.set('file', newIndexStyleDefinition.file!);
      data.set('projectDefinitionUid', definitionUid);

      await definitionsClient.createIndexStyleDefinitions(definitionUid, data);
      this.trigger({
        snackBarMessage: 'Index Style Definition added',
        type: ProjectDefinitionStoreEventType.SNACKBAR
      } as ProjectDefinitionStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: errors.INDEX_STYLE_DEFINITION_EXISTS } as ProjectDefinitionStoreEvent);
      } else {
        this.retrieveIndexDefinitions(definitionUid);
      }
    }
  }

  async retrieveIndexDefinitions(definitionUid: string) {
    this.state.indexDefinitions = await definitionsClient.getIndexDefinitions(definitionUid);
    this.loaded = true;
    this.trigger({
      state: this.state,
      type: ProjectDefinitionStoreEventType.INDEX_DEFINITIONS_RETRIEVED,
      definitionUid: definitionUid
    } as ProjectDefinitionStoreEvent);
  }

  async updateIndexDefinition(definitionUid: string, updatedIndexDefinition: IIndexDefinition) {
    try {
      await definitionsClient.updateIndexDefinition(definitionUid, updatedIndexDefinition);
      this.retrieveIndexDefinitions(definitionUid);
      this.trigger({
        snackBarMessage: 'Index Definition updated',
        type: ProjectDefinitionStoreEventType.SNACKBAR
      } as ProjectDefinitionStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: errors.INDEX_DEFINITION_EXISTS } as ProjectDefinitionStoreEvent);
      } else {
        this.retrieveIndexDefinitions(definitionUid);
      }
    }
  }

  async createIndexDefinition(definitionUid: string, token: Partial<IIndexDefinition> & { file: File }) {
    try {
      const data = new FormData();
      data.set('file', token.file!);
      data.set('description', token?.description ?? '');
      data.set('projectDefinitionUid', definitionUid);

      await definitionsClient.createIndexDefinitions(data);
      this.retrieveIndexDefinitions(definitionUid);
      this.trigger({
        snackBarMessage: 'Index Definition added',
        type: ProjectDefinitionStoreEventType.SNACKBAR
      } as ProjectDefinitionStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: errors.INDEX_DEFINITION_EXISTS } as ProjectDefinitionStoreEvent);
      } else {
        this.retrieveIndexDefinitions(definitionUid);
      }
    }
  }

  async createProjectDefinition(token: Partial<IProjectDefinition>) {
    try {
      const newDefinition = await definitionsClient.createProjectDefinition(token);
      this._onInit(newDefinition.uid);
      this.trigger({
        snackBarMessage: 'Project Definition created',
        type: ProjectDefinitionStoreEventType.SNACKBAR
      } as ProjectDefinitionStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: errors.PROJECT_DEFINITION_EXISTS } as ProjectDefinitionStoreEvent);
      } else {
        this._onInit();
      }
    }
  }

  async updateProjectDefinition(token: Partial<IProjectDefinition>) {
    try {
      await definitionsClient.updateProjectDefinition(token.uid!, token);
      this._onInit();
      this.trigger({ snackBarMessage: 'Project Definition updated', type: ProjectDefinitionStoreEventType.SNACKBAR });
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 409) {
        this.trigger({ error: errors.PROJECT_DEFINITION_EXISTS } as ProjectDefinitionStoreEvent);
      } else {
        this._onInit();
      }
    }
  }

  async removeProjectDefinition(definitionUid: string) {
    try {
      await definitionsClient.removeProjectDefinition(definitionUid);
      this._onInit();
      this.trigger({
        snackBarMessage: 'Project Definition deleted',
        type: ProjectDefinitionStoreEventType.SNACKBAR
      } as ProjectDefinitionStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 412) {
        this.trigger({ error: errors.PROJECT_DEFINITION_INUSE } as ProjectDefinitionStoreEvent);
      } else {
        this._onInit();
      }
    }
  }

  fetchUnitDefinitions(projectDefinitionUid: string) {
    return definitionsClient.getProjectDefinition(projectDefinitionUid);
  }

  async _onInit(definitionUid?: string | null) {
    if (!this.isBusy) {
      this.isBusy = true;
      try {
        const response = await Promise.all([
          definitionsClient.getProjectDefinitions(),
          definitionsClient.getUnitReformats(),
          definitionsClient.getElementReformats()
        ]);

        this.state.projectDefinitions = response[0] as IProjectDefinition[];
        this.state.documentUnitReformats = response[1] as IDocumentUnitReformat;
        this.state.documentElementReformats = response[2] as IDocumentElementReformat;

        this.state.projectTypes = this.state.projectDefinitions.map((projDef) => {
          return {
            id: projDef.name,
            name: projDef.description,
            userCreatable: projDef.userCreatable,
            selectable: projDef.userCreatable,
            personalFieldData: projDef.personalProjectFieldRequirements,
            teamspaceFieldData: projDef.teamspaceProjectFieldRequirements
          };
        });

        this.isBusy = false;

        if (definitionUid) {
          this.loaded = false;
          await this.retrieveIndexDefinitions(definitionUid);
        } else if (this.state.currentProjectDefinition) {
          this.loaded = false;
          await this.retrieveIndexDefinitions(this.state.currentProjectDefinition.uid!);
        } else {
          this.loaded = true;
        }

        this.trigger({
          state: this.state,
          type: 'projectDefinitionsRetrieved',
          definitionUid: definitionUid
        } as ProjectDefinitionStoreEvent);
        DocumentsStore.projectDefinitionInitSuccess(this.state);
      } catch (e) {
        this.loaded = false;
        this.isBusy = false;
      }
    }
  }

  isLoaded() {
    return this.loaded;
  }

  getProjectTypes() {
    return this.state.projectTypes;
  }

  // TODO map these as they become available from definitions api

  allowSetting(settingName: string, defaultValue: boolean): boolean {
    return this.state.currentProjectDefinition?.indexDefinition?.[settingName] ?? defaultValue;
  }

  allowUnitIndentation() {
    return this.state.currentProjectDefinition?.indexDefinition?.allowUnitIndentation ?? false;
  }

  showInvalidUnitsPanel() {
    return this.state.currentProjectDefinition?.indexDefinition?.showInvalidUnitsPanel ?? false;
  }

  isTocableType(type: string, unitType?: string) {
    return this._getIsTocable(type, unitType);
  }

  getDefaultPageWidth() {
    return this.state.currentProjectDefinition?.indexDefinition?.defaultPageWidth ?? '764px';
  }

  _getIsTocable(unitType: string, unitTypeFromActualUnit?: string): boolean {
    const unitDefinition = this.getProjectDefinedUnitById(unitType);
    if (unitDefinition) {
      return unitDefinition.type === 'tocable';
    } else {
      return unitTypeFromActualUnit === 'tocable';
    }
  }

  isStructuralType(type: string) {
    return this._getIsStructuralType(type);
  }

  _getIsStructuralType(unitType): boolean {
    const ud = this.getProjectDefinedUnitById(unitType);
    return ud ? ud.structural : false;
  }

  _getIsServerManagedType(unitDefinitionId: string): boolean {
    const ud = this.getProjectDefinedUnitById(unitDefinitionId);
    return !!(ud && ud.userCreatable === UserCreatableType.NEVER);
  }

  isOrdinable(type: string, unitType: string) {
    return this._getIsOrdinableType(type, unitType);
  }

  _getIsOrdinableType(unitType, unitTypeFromActualUnit): boolean {
    return this._getIsTocable(unitType, unitTypeFromActualUnit) || unitType.match(/paragraph.*|note/) !== null;
  }

  getProjectDefinedUnitByLevel(level: string): IUnitDefinition | undefined {
    const haveCurrentProjDef = this.state.currentProjectDefinition;
    let unitTypeOfProjDef: IUnitDefinition | undefined = undefined;
    if (haveCurrentProjDef) {
      unitTypeOfProjDef = this.state.currentProjectDefinition?.indexDefinition.unitDefinitions?.find((unitDef) => unitDef.level === level);
    }
    return unitTypeOfProjDef;
  }

  getProjectDefinedUnitById(unitId?: string): IUnitDefinition | undefined {
    return unitId ? this.state.unitDefinitionsById.get(unitId) : undefined;
  }

  whitelistOrNotBlacklistedUnitNames(unitDefinition?: IUnitDefinition): string[] {
    if (unitDefinition) {
      const whitelist: string[] | undefined = unitDefinition.structuralFollowWhitelistIds;
      const blacklist: string[] = unitDefinition.structuralFollowBlacklistIds || [];
      const allUnitNames = this.getAllUnitDefinitions(() => true)!.map((ud) => ud.id);

      if (whitelist && whitelist.length > 0) {
        return whitelist;
      } else {
        const nonBlacklistedUnitNames: string[] = _.difference(allUnitNames, blacklist);
        return nonBlacklistedUnitNames;
      }
    }
    return [];
  }

  getAllUnitDefinitions(filter?: IUnitDefinitionFilter) {
    if (this.state.currentProjectDefinition) {
      const allDefinitions = this.state.currentProjectDefinition.indexDefinition.unitDefinitions!;
      if (filter) {
        return allDefinitions.filter(filter);
      } else {
        return allDefinitions;
      }
    } else {
      return [];
    }
  }

  getAllElementDefinitions(filter: (ed: IElementDefinition) => boolean = (ud) => true): IElementDefinition[] {
    const currentIndexDefinition = this.getCurrentIndexDefinition();
    let allIndexElementDefinitions: IElementDefinition[] = [];
    if (currentIndexDefinition && currentIndexDefinition.elementDefinitions) {
      allIndexElementDefinitions = currentIndexDefinition.elementDefinitions.filter(filter);
    }
    return allIndexElementDefinitions;
  }
  getStructuralUnitDefinitions() {
    return this.getAllUnitDefinitions((ud) => ud.structural);
  }

  getTocableUnitDefinitions() {
    return this.getAllUnitDefinitions((ud) => ud.type === 'tocable');
  }

  getUnitIdDefinitionToElementDefinitionIdMap() {
    const allDefinitions = this.state.currentProjectDefinition?.indexDefinition.unitDefinitions;
    return _.reduce(
      allDefinitions,
      (result, { id, rootElementId, type, structural }) => {
        if (rootElementId && rootElementId !== 'Tocable' && type !== 'frontmatter' && !structural) {
          result[id] = rootElementId;
        }
        return result;
      },
      {}
    );
  }

  isPageBreakBeforeControlEnabled(unit: IUnit) {
    if (unit.definitionId === 'section') {
      return !(this.state.currentProjectDefinition && this.state.currentProjectDefinition.name === 'ftid');
    }
    return true;
  }

  canHavePrintOutput(type: UnitTypes) {
    return DocUnitPlacementRules.canHavePrintOutput(type);
  }

  canHaveVariantOutput(type: UnitTypes) {
    // true for all currently
    return true;
  }

  canShowPdfSetting(type: UnitTypes) {
    return DocUnitPlacementRules.canShowPdfSetting(type);
  }

  canHaveOrdinal(type: UnitTypes) {
    return DocUnitPlacementRules.canHaveOrdinal(type);
  }

  isRepeaterHeading(type: UnitTypes) {
    return DocUnitPlacementRules.isRepeaterHeadingType(type);
  }

  getTocableParentType(unitName: string) {
    // .shift is a mutator.
    const whitelist = [...this.getProjectDefinedUnitById(unitName)!.structuralFollowWhitelistIds!];
    let indexOfParent = _.indexOf(whitelist, unitName);
    // we have a reverse order here
    if (unitName.indexOf('normal-') === 0 || unitName === 'non-normal-checklists-chapter') {
      indexOfParent += 1;
    } else if (unitName === 'section') {
      indexOfParent = 0;
      // non FTID doc type
    } else {
      indexOfParent -= 1;
    }
    if (indexOfParent < 0) {
      return false;
    }
    const nextWhitelistDefinitionId = whitelist[indexOfParent];
    return nextWhitelistDefinitionId && !this._getIsServerManagedType(nextWhitelistDefinitionId) ? nextWhitelistDefinitionId : false;
  }

  canFollowTocable(definitionId: string, closestTocableDefId: string) {
    const allowedParentNames = this.getAllowedStructuralParentNames(definitionId);
    return _.includes(allowedParentNames, closestTocableDefId);
  }

  getAllowedStructuralParentNames(definitionId: string) {
    return this.whitelistOrNotBlacklistedUnitNames(this.getProjectDefinedUnitById(definitionId));
  }

  getProjectDefinitions() {
    return this.state.projectDefinitions;
  }

  getProjectDefinition(type: string): IProjectDefinition | undefined {
    return _.find(this.state.projectDefinitions, { name: type });
  }

  getProjectDefinitionByUid(uid: string): IProjectDefinition | undefined {
    return _.find(this.state.projectDefinitions, { uid: uid });
  }

  getIndexDefinitions() {
    return this.state.indexDefinitions;
  }

  getCurrentProjectDefinition() {
    return this.state.currentProjectDefinition;
  }

  isCurrentProjectDefinitionAirbus() {
    return this.state.currentProjectDefinition?.indexDefinition.type === PROJECT_DEFINITION_TYPES.airbus;
  }

  async setCurrentProjectDefinition(definitionName: string) {
    const currentProjectDefinition = this.getProjectDefinition(definitionName);

    if (currentProjectDefinition) {
      const updateState = Object.assign({}, this.state, { currentProjectDefinition: currentProjectDefinition });
      await this.fetchUnitDefinitions(updateState.currentProjectDefinition.uid!).then((res) => {
        this.persistCurrentProjectDefinition(res, updateState);
      });
    }
  }

  isForecolorVisible() {
    return !!this.getElementDefinitionById('ColorText');
  }

  isHilitecolorVisible() {
    return !!this.getElementDefinitionById('BackgroundColorText');
  }

  persistCurrentProjectDefinition(res: IProjectDefinition, updateState: Partial<State>) {
    const _buildValidList = (available: string, attributeName: string) => {
      if (this.state.documentUnitReformats && this.state.documentUnitReformats[available]) {
        Object.keys(this.state.documentUnitReformats[available]).forEach((family) => {
          unitDefinitions!.forEach((unitDef) => {
            if (unitDef.type === family) {
              const found = unitDefinitions!.find((unitDef) => {
                return unitDef[attributeName] && unitDef.type === family;
              });
              if (found) {
                // use previously created for this family
                unitDef[attributeName] = found[attributeName];
              } else {
                // expand family to definitionIds that make up that family or use 'family' directly as definitionId lookup
                const merges: string[] = this.state.documentUnitReformats![available][family];
                const definitionIds: string[] = merges
                  .map((family) => {
                    return updateState?.unitDefinitionsByType?.get(family) ?? [];
                  })
                  .filter((val) => Array.isArray(val) && val[0] != null)
                  .reduce((acc, val) => acc!.concat(val), [])
                  .map((_unitDef) => _unitDef.id);
                unitDef[attributeName] = Array.from(new Set(definitionIds)); // remove dupes
              }
            }
          });
        });
      }
      if (this.state.documentElementReformats && this.state.documentElementReformats[available]) {
        attributeName += 'Elements'; // TODO: to remove after cludgeYeProfiles is removed
        Object.keys(this.state.documentElementReformats[available]).forEach((family) => {
          elementDefinitions!.forEach((elementDef) => {
            if (elementDef.type === family) {
              const found = elementDefinitions!.find((elementDef) => {
                return elementDef[attributeName] && elementDef.type === family;
              });
              if (found) {
                // use previously created for this family
                elementDef[attributeName] = found[attributeName];
              } else {
                // expand family to definitionIds that make up that family or use 'family' directly as definitionId lookup
                const merges: string[] = this.state.documentElementReformats![available][family];
                elementDef[attributeName] = merges
                  .map((family) => {
                    return updateState?.elementDefinitionsByType?.get(family) ?? [];
                  })
                  .filter((val) => Array.isArray(val) && val[0] != null)
                  .reduce((acc, val) => acc!.concat(val), [])
                  .map((_elementDef) => _elementDef.id);
              }
            }
          });
        });
      }
    };

    const { unitDefinitions, elementDefinitions } = res.indexDefinition as IIndexDefinition;
    const updateIndexDefinition = Object.assign({}, updateState?.currentProjectDefinition?.indexDefinition, {
      unitDefinitions,
      elementDefinitions
    });

    if (updateState.currentProjectDefinition) {
      updateState.currentProjectDefinition.indexDefinition = updateIndexDefinition;

      const COLS_COUNT = updateState.currentProjectDefinition.styleDefinition.pageStyles.find((style) => style.id === 'leftIndentColumns')!
        .maxValue!;

      updateState.currentProjectDefinition.styleDefinition['pageMargin'] = {
        EDITOR_PAGE_COLS: COLS_COUNT,
        EDITOR_PAGE_COL_WIDTH: 100 / COLS_COUNT,
        APP_UNIT_PADDING_LEFT: '2.5rem' // === doc-unit.less @app-unit-padding-left
      };
    }

    updateState.elementDefinitionsById = arrayToMap<IElementDefinition, 'id'>(elementDefinitions!, 'id');
    updateState.elementDefinitionsByType = arrayToMapOfLists<IElementDefinition, 'type'>(elementDefinitions!, 'type');

    updateState.unitDefinitionsById = arrayToMap<IUnitDefinition, 'id'>(unitDefinitions!, 'id');
    updateState.unitDefinitionsByType = arrayToMapOfLists<IUnitDefinition, 'type'>(unitDefinitions!, 'type');

    if (this.state.documentUnitReformats) {
      // validConverts: Format element contents, roll unit conversion options into unit definitions to ease
      _buildValidList('availableConversions', 'validConverts');
      // validMerges: Merge Elements button
      _buildValidList('availableMerges', 'validMerges');
    }

    this.setState(updateState as State);
    this.trigger({
      state: updateState,
      type: ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS
    } as ProjectDefinitionStoreEvent);
    this.projectDefinitionDocUnitEditProfiles().buildProfiles();
  }

  updateDefsAndIdsOnUnitSelectedIfNestedDefs(selectedUnit?: IUnit | null, lastSelectedUnit?: IUnit) {
    if (
      selectedUnit &&
      this.isCurrentProjectDefinitionAirbus() &&
      this.getParentWithNestedDefinitionsDefId(selectedUnit, this.state.currentProjectDefinition?.indexDefinition.unitDefinitions) !==
        this.getParentWithNestedDefinitionsDefId(lastSelectedUnit, this.state.currentProjectDefinition?.indexDefinition.unitDefinitions)
    ) {
      const currentTocLevelWithNestedDefsDefId: string | null = this.getParentWithNestedDefinitionsDefId(
        selectedUnit,
        this.state.currentProjectDefinition?.indexDefinition.unitDefinitions
      );
      const prevTocLevelWithNestedDefsDefId: string | null = this.getParentWithNestedDefinitionsDefId(
        lastSelectedUnit,
        this.state.currentProjectDefinition?.indexDefinition.unitDefinitions
      );

      let updateState: State = this.state;

      if (updateState.currentProjectDefinition) {
        let updatedUnitDefs: IUnitDefinition[] = updateState.currentProjectDefinition.indexDefinition.unitDefinitions!;
        let updatedElmDefs: IElementDefinition[] = updateState.currentProjectDefinition.indexDefinition.elementDefinitions!;

        // If prev selected unit toc level allows nested unit definitions, remove that section unit/elm defs from current unit/elm defs
        if (prevTocLevelWithNestedDefsDefId) {
          const prevParentWithNestedDefinitions = updateState.currentProjectDefinition.indexDefinition.unitDefinitions?.find(
            (ud) => ud.id === prevTocLevelWithNestedDefsDefId
          );

          updatedUnitDefs = updatedUnitDefs.filter((ud) => {
            return !prevParentWithNestedDefinitions?.allowUnitDefinitions?.find((prevUd) => prevUd.id === ud.id);
          });

          prevParentWithNestedDefinitions?.allowElementDefinitions?.map((ed) => {
            updatedElmDefs = updatedElmDefs.filter((curEd) => curEd.id !== ed.id);
          });
        }

        // If selected unit toc level allows nested unit definitions, get that toc level unit and elm defs and add to current index otherwise just leave it
        if (currentTocLevelWithNestedDefsDefId) {
          const currentSectionWithNestedDefinitions = updatedUnitDefs.find((ud) => ud.id === currentTocLevelWithNestedDefsDefId);
          currentSectionWithNestedDefinitions?.allowUnitDefinitions?.forEach((ud) => updatedUnitDefs.push(ud));
          currentSectionWithNestedDefinitions?.allowElementDefinitions?.forEach((ed) => updatedElmDefs.push(ed));
        }
        updateState.unitDefinitionsById = arrayToMap<IUnitDefinition, 'id'>(updatedUnitDefs, 'id');
        updateState.unitDefinitionsByType = arrayToMapOfLists<IUnitDefinition, 'type'>(updatedUnitDefs, 'type');
        updateState.elementDefinitionsById = arrayToMap<IElementDefinition, 'id'>(updatedElmDefs, 'id');
        updateState.elementDefinitionsByType = arrayToMapOfLists<IElementDefinition, 'type'>(updatedElmDefs, 'type');

        updateState.currentProjectDefinition.indexDefinition.unitDefinitions = updatedUnitDefs;
        updateState.currentProjectDefinition.indexDefinition.elementDefinitions = updatedElmDefs;

        this.setState(updateState as State);
        this.trigger({
          state: updateState,
          type: ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS
        } as ProjectDefinitionStoreEvent);
        this.projectDefinitionDocUnitEditProfiles().setCurrentDocProfiles(currentTocLevelWithNestedDefsDefId);
      }
    }
  }

  getUnitDefinition(closestTocableUid: string, unitDefinitionId: string) {
    let tocUnitTocable = TocStore.getTocItem(closestTocableUid);
    let currentSection = TocStore.getTocAncestor(tocUnitTocable, 'section');
    let currentUnitDef = this.getCurrentProjectDefinition()?.indexDefinition.unitDefinitions?.find(
      (ud) => ud.id === currentSection?.definitionId
    );
    return currentUnitDef?.allowUnitDefinitions?.find((ud) => ud.id === unitDefinitionId);
  }

  getElementDefinition(closestTocableUid: string, unitDefinitionId: string) {
    let tocUnitTocable = TocStore.getTocItem(closestTocableUid);
    let currentSection = TocStore.getTocAncestor(tocUnitTocable, 'section');
    let currentUnitDef = this.getCurrentProjectDefinition()?.indexDefinition.unitDefinitions?.find(
      (ud) => ud.id === currentSection?.definitionId
    );
    return currentUnitDef?.allowElementDefinitions?.find((ud) => ud.id === unitDefinitionId);
  }

  getParentWithNestedDefinitionsDefId(unit?: IUnit | null, unitDefinitions?: IUnitDefinition[]): null | string {
    if (unit) {
      let currentUnitDef = unitDefinitions?.find((ud) => ud.id === unit.definitionId);
      if (currentUnitDef?.allowUnitDefinitions) {
        return currentUnitDef.id;
      } else if (unit.definitionId === 'invariant' || unit.level === 'dynamic') {
        // at top level so no def will have nested unit definitions
        return null;
      } else {
        let tocUnit = getSelectedClosestTocable(unit);
        if (tocUnit) {
          let tocUnitTocable = TocStore.getTocItem(tocUnit.uid);
          let currentSection = TocStore.getTocAncestor(tocUnitTocable, 'section');
          currentUnitDef = unitDefinitions?.find((ud) => ud.id === currentSection?.definitionId);
          if (currentUnitDef?.allowUnitDefinitions) {
            return currentUnitDef.id;
          }
        }
      }
    }
    return null;
  }

  getCurrentIndexDefinition() {
    const currentProjectDefinition = this.state.currentProjectDefinition;
    if (currentProjectDefinition) {
      return currentProjectDefinition.indexDefinition;
    }
    return null;
  }

  getInlineDataValues() {
    return this.getAllElementDefinitions((ed) => ed.subType === 'measureUnit').reduce(
      (acc, ed) => [...acc, ...ed.inlineElementEnumValues],
      []
    );
  }

  getInlineTechLabelValues() {
    return this.getAllElementDefinitions((ed) => ed.subType === 'techLabelType').reduce(
      (acc, ed) => [...acc, ...ed.inlineElementEnumValues],
      []
    );
  }

  getEditRibbonAdditionalElements(): IElementDefinition[] {
    return this.getAllElementDefinitions(
      (ed) => ed.userCreatable && (ed.editRibbonElement === EditRibbonEnum.DROPDOWN || ed.editRibbonElement === EditRibbonEnum.BOTH)
    );
  }

  getContentMenuElements(): IElementDefinition[] {
    return this.getAllElementDefinitions((ed) => canDisplayInContentMenu(ed));
  }

  getElementDefinitionById(elementId?: string | null): IElementDefinition | undefined {
    return elementId ? this.state.elementDefinitionsById.get(elementId) : undefined;
  }

  getUnitDefinitionById(unitId?: string | null): IUnitDefinition | undefined {
    return unitId ? this.state.unitDefinitionsById.get(unitId) : undefined;
  }
  getElementColors(elementId?: string): Color[] {
    let colors: Color[] | undefined;

    if (!!elementId) {
      const elementDef = this.getElementDefinitionById(elementId);
      if (!!elementDef && !!elementDef.colorPalette) {
        colors = elementDef.colorPalette.colors;
      }
    }

    if (!colors) {
      // get color palette from the main index definition
      colors = this.getCurrentProjectDefinition()?.indexDefinition.colorPalette?.colors;
    }

    return colors ?? [];
  }

  getColorById(colorId: string, dayMode = true, source?: string): Color | null {
    const colorIdLowerCase = colorId.toLowerCase();
    let result = this.getElementColors(source).find((color) => {
      return dayMode ? color.day.id.toLowerCase() === colorIdLowerCase : color.night.id.toLowerCase() === colorIdLowerCase;
    });

    if (!result && ((dayMode && colorIdLowerCase === 'black') || (!dayMode && colorIdLowerCase === 'white'))) {
      result = INITIAL_BLACK;
    }
    return result ?? null;
  }

  getElementDefinitionsByType(elementType = '', elementSubType = ''): IElementDefinition[] {
    const elementDefinitionsByType =
      this.state.elementDefinitionsByType.get(elementType) ||
      this.state.elementDefinitionsByType.get(this.toElemDefinitionId(elementType)) ||
      this.state.elementDefinitionsByType.get(elementType.charAt(0).toUpperCase() + elementType.slice(1)) ||
      [];

    if (
      elementDefinitionsByType &&
      elementDefinitionsByType.length > 1 &&
      elementSubType &&
      elementSubType.toLowerCase &&
      elementSubType.length > 0
    ) {
      // If elementSubType is defined, filter out all those elementDefinitions which do not have a matching subtype.
      return elementDefinitionsByType.filter(
        (el) => el.subType && el.subType.toLowerCase && el.subType.toLowerCase() === elementSubType.toLowerCase()
      );
    } else {
      // If elementSubType is an empty string, filter out all those unit definitions which have a non-empty subtype.
      return elementDefinitionsByType.filter((ed) => (ed.subType ? ed.subType.length === 0 : true));
    }
  }

  // There is a mismatch in how the data-subtype is being set on the DOM.
  // For a unit, the data-family is the unit.type and the data-subtype is the unit.id
  // For an element the data-family is the element.type and the data-subtype is the element.subType
  getUnitDefinitionsByType(unitType = '', unitSubType = ''): IUnitDefinition[] {
    const unitDefinitionsByType = this.state.unitDefinitionsByType.get(unitType.toLowerCase()) || []; // TODO: This .toLowerCase stuff has to go.

    if (unitSubType && unitSubType.toLowerCase && unitSubType.length) {
      // If unitSubType is defined, filter out all those unitDefinitions whose id is not equal to the subtype.
      return unitDefinitionsByType.filter(
        (ud) => ud.subType && ud.subType.toLowerCase && ud.subType.toLowerCase() === unitSubType.toLowerCase()
      );
    } else {
      return unitDefinitionsByType.filter((ud) => (ud.subType ? ud.subType.length === 0 : ud));
    }
  }

  getFormattingValues() {
    return this.state.currentProjectDefinition && this.state.currentProjectDefinition.styleDefinition.pageStyles
      ? this.state.currentProjectDefinition.styleDefinition.pageStyles
      : [];
  }

  getPageMargin() {
    return this.state.currentProjectDefinition && this.state.currentProjectDefinition.styleDefinition.pageMargin
      ? this.state.currentProjectDefinition.styleDefinition.pageMargin
      : {
          EDITOR_PAGE_COLS: 16,
          EDITOR_PAGE_COL_WIDTH: 6.25,
          APP_UNIT_PADDING_LEFT: '2.5em'
        };
  }

  toElemDefinitionId(familyType: string) {
    return this._ensureDefinitionId(familyType, 'element');
  }

  toUnitDefinitionId(familyType: string) {
    return this._ensureDefinitionId(familyType, 'unit');
  }

  getSupportedMediaTypesForIndex(): string {
    return this.getCurrentIndexDefinition()?.supportedMediaTypes || DEFAULT_SUPPORTED_MEDIA;
  }

  private _ensureDefinitionId(familyType: string, target: 'unit' | 'element'): string {
    const definitionId = familyType;

    const def = target === 'element' ? this.getElementDefinitionById(familyType) : this.getProjectDefinedUnitById(familyType);

    if (def) {
      return definitionId;
    } else {
      const unitTypeToElementTypeMap = {
        collection: 'CollectionContent',
        InlineDataElement: 'InlineDataElement',
        list: 'ListContent'
      };

      const unitIdToElementIdMap = this.getUnitIdDefinitionToElementDefinitionIdMap();

      const twLookup = new TwoWayLookup(_.merge(unitTypeToElementTypeMap, unitIdToElementIdMap));
      const lookup: string = target === 'element' ? twLookup.lookup(definitionId) : twLookup.rLookup(definitionId);
      return lookup || (target === 'element' ? familyType : familyType.toLowerCase());
    }
  }

  projectDefinitionDocUnitEditProfiles() {
    return this.docUnitEditProfiles;
  }

  clear() {
    this.loaded = false;
    this.isBusy = false;
    this.state = {
      projectDefinitions: [],
      indexDefinitions: [],
      projectTypes: [],
      currentProjectDefinition: null,
      unitDefinitions: [],
      elementDefinitions: [],
      elementDefinitionsById: new Map<string, IElementDefinition>(),
      elementDefinitionsByType: new Map<string, IElementDefinition[]>(),
      unitDefinitionsById: new Map<string, IUnitDefinition>(),
      unitDefinitionsByType: new Map<string, IUnitDefinition[]>(),
      documentUnitReformats: null,
      documentElementReformats: null
    };
  }
}

const singleton = Reflux.initStore<ProjectDefinitionStore>(ProjectDefinitionStore);
export default singleton;
