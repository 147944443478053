import * as React from 'react';
import PopupContainer from './PopupContainer';
import Checkbox from 'material-ui/Checkbox';
import { SvgDropdownIcon } from './icons/SvgDropdownIcon';

export type ComplianceTag = {
  name: string;
  uid: string;
};

export type Props = {
  isReadOnly?: boolean;
  assignedTags?: ComplianceTag[];
  onClose?: (mutableAssignedTags: string[]) => void;
};

export type State = {
  open: boolean;
  mutableAssignedTags: string[];
};

export default class ComplianceTagsDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      mutableAssignedTags: []
    };
  }

  static defaultProps: Partial<Props> = {
    isReadOnly: false,
    assignedTags: []
  };

  UNSAFE_componentWillMount() {
    this._resetStateFromProperties(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._resetStateFromProperties(nextProps);
  }

  open(e: React.ReactInstance) {
    if (!this.state.open) {
      this.setState({ open: true }, () => {
        (this.refs.unitComplianceTagsPopup as PopupContainer).setTriggerButton(e).open();
      });
    }
  }

  close(e: React.MouseEvent<HTMLElement>) {
    if (this.state.open) {
      this._handleClose();
    }

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  private _resetStateFromProperties(props: Props) {
    const mutableAssignedTags: string[] = [];
    props.assignedTags!.forEach((tag) => {
      mutableAssignedTags.push(tag.uid);
    });

    this.setState({ mutableAssignedTags: mutableAssignedTags });
  }

  private _isTagged(tagUid: string) {
    return this.state.mutableAssignedTags.indexOf(tagUid) !== -1;
  }

  private _handleChange(e: React.MouseEvent<HTMLElement>, tag: ComplianceTag) {
    if (this.props.isReadOnly) {
      return;
    }

    const tagIndex = this.state.mutableAssignedTags.indexOf(tag.uid);
    if (tagIndex !== -1) {
      this.state.mutableAssignedTags.splice(tagIndex, 1);
    } else {
      this.state.mutableAssignedTags.push(tag.uid);
    }

    this.setState({ mutableAssignedTags: this.state.mutableAssignedTags });

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  private _handleClose() {
    this.setState({ open: false }, () => {
      if (this.props.onClose) {
        this.props.onClose(this.state.mutableAssignedTags);
      }
    });
  }

  render() {
    if (this.state.open) {
      const tags = this.props.assignedTags!.map((complianceTag) => {
        return (
          <div
            key={complianceTag.uid}
            onClick={(e) => {
              this._handleChange(e, complianceTag);
            }}
          >
            <Checkbox checked={this._isTagged(complianceTag.uid)} />
            <div className="compliance-tag">{complianceTag.name}</div>
          </div>
        );
      });

      return (
        <PopupContainer ref="unitComplianceTagsPopup" className="check-dropdown" onClose={() => this._handleClose()}>
          <div className="check-dropdown-title" onClick={(e) => this.close(e)}>
            <div>Compliance Tag</div>
            <SvgDropdownIcon />
          </div>

          <div className="check-dropdown-inner">{tags}</div>
        </PopupContainer>
      );
    } else {
      return false;
    }
  }
}
