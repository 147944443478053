import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';

interface Props {
  icon?: string;
  label?: string;
}

const DropzoneExpandingDropArea = (props: Props) => {
  let { icon, label } = props;
  icon = icon || 'cloud_upload';
  label = label || 'Drop files to upload';

  return (
    <div className="dropzone-expanding-drop-area">
      <div className="area-content">
        <div className="area-icon">
          <FontIcon className="material-icons">{icon}</FontIcon>
        </div>
        <div className="area-label">{label}</div>
      </div>
    </div>
  );
};

export default DropzoneExpandingDropArea;
