import * as React from 'react';
import * as _ from 'lodash';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FontIcon from 'material-ui/FontIcon';

import { itemCodes } from './SearchSharedLibContainer';
import ContentTypes from '../SharedContentLibTypes';
import { Toggle } from 'material-ui';
import withTooltip from '../../../hoc/withTooltip';
import SmartContentLibraryStore from '../../../../flux/editor/SmartContentLibraryStore';

export type Props = {
  onClose: () => void;
  contentType: string;
  isLoading: boolean;
  isReadOnly: boolean;
  isRegulationDisabled: boolean;
  isRegulationSelectionMode: boolean;
  isProjectTypeToggled: boolean;
};

export type State = {
  contentType: string;
  enableSearchActions: boolean;
  projectTypeToggleEnabled: boolean;
};

export default class TopSharedLibPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contentType: props.contentType,
      enableSearchActions: !props.isRegulationSelectionMode,
      projectTypeToggleEnabled: SmartContentLibraryStore.state.contentType !== ContentTypes.props.TYPE_REGULATIONS.key
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.contentType !== prevProps.contentType) {
      this.setState({
        contentType: this.props.contentType,
        enableSearchActions: this.props.contentType !== ContentTypes.props.TYPE_REGULATIONS.key,
        projectTypeToggleEnabled: this.props.contentType !== ContentTypes.props.TYPE_REGULATIONS.key
      });
    }
  }

  _onContentTypeChange(e: React.SyntheticEvent<{}>, i: number, value: string) {
    this.setState(
      {
        contentType: value,
        enableSearchActions: value !== ContentTypes.props.TYPE_REGULATIONS.key,
        projectTypeToggleEnabled: value !== ContentTypes.props.TYPE_REGULATIONS.key
      },
      () => {
        SmartContentLibraryStore.onContentTypeChange(value);
      }
    );
  }

  render() {
    const contentTypeMenuItems: JSX.Element[] = [],
      items = ContentTypes.filter;

    for (let i = 0; i < items.length; i++) {
      contentTypeMenuItems.push(
        <MenuItem
          className="shared-content-dropdown_menu_item"
          key={items[i].key}
          value={items[i].key}
          disabled={(this.props.isReadOnly || this.props.isRegulationDisabled) && items[i].key === ContentTypes.props.TYPE_REGULATIONS.key}
          primaryText={items[i].title}
          leftIcon={
            <FontIcon className="material-icons" disabled={false}>
              {items[i].icon}
            </FontIcon>
          }
        />
      );
    }

    const ToggleWithTooltip = withTooltip(() => {
      return (
        <Toggle
          id="share-toggle-by-project-type"
          name="toggleShareByProjectType"
          data-toggle-enabled={this.props.isProjectTypeToggled}
          onToggle={(e, value) => {
            SmartContentLibraryStore.onProjectTypeToggleChange(value);
          }}
          toggled={this.props.isProjectTypeToggled}
          disabled={!this.state.projectTypeToggleEnabled}
        />
      );
    });

    const SharesTagsIconWithTooltip = withTooltip(() => {
      return (
        <i
          className="material-icons icon-doc-reference shared-content-main__icon"
          onClick={() => SmartContentLibraryStore.onIconAction(itemCodes.TAG)}
        ></i>
      );
    });

    const SharesSearchIconWithTooltip = withTooltip(() => {
      return (
        <i
          className="material-icons icon-search shared-content-main__icon"
          onClick={() => SmartContentLibraryStore.onIconAction(itemCodes.SEARCH)}
        ></i>
      );
    });

    const CloseButtonWithTooltip = withTooltip(() => {
      return <i className="material-icons icon-close shared-content-main__icon" onClick={(e) => this.props.onClose()}></i>;
    });

    return (
      <div className="shared-content-main">
        <div className="shared-content-left">
          <SelectField
            autoWidth={true}
            style={{ lineHeight: '14px', fontSize: '14px !important' }}
            labelStyle={{ lineHeight: '25px' }}
            value={this.state.contentType}
            onChange={(e, index, value) => this._onContentTypeChange(e, index, value)}
            disabled={this.props.isLoading}
            id="shared-content-dropdown"
          >
            {contentTypeMenuItems}
          </SelectField>
        </div>
        <div className="shared-content-right">
          {this.state.projectTypeToggleEnabled && (
            <div className="shared-content-toggle">
              <ToggleWithTooltip tooltipText="Filter shares matching your document type" tooltipVerticalPosition="top" />
            </div>
          )}
          {this.state.enableSearchActions && <SharesSearchIconWithTooltip tooltipText="Search" tooltipVerticalPosition="top" />}
          {this.state.enableSearchActions && <SharesTagsIconWithTooltip tooltipText="Tags" tooltipVerticalPosition="top" />}
          <CloseButtonWithTooltip tooltipText="Close" tooltipVerticalPosition="top" />
        </div>
      </div>
    );
  }
}
