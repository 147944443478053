import * as React from 'react';
import classNameHelper from '../../../../utils/classNameHelper';
import { MenuAction } from '../edit/components/defaultMenuContents';

export interface MenuActionItemContentProps {
  children?: React.ReactNode;
  action: MenuAction;
  actionName?: string;
  enabled: boolean;
  onAction(e: React.MouseEvent): void;
  style?: React.CSSProperties;
  inClassNameIcon?: boolean;
}

const MenuActionItemContent = ({ enabled, children, action, actionName, onAction, style }: MenuActionItemContentProps) => {
  const className = classNameHelper.fromObject({
    disabled: !enabled,
    hidden: !action.visible
  });

  return (
    <li data-action={actionName || action.action} onClick={onAction} className={className} style={style}>
      {children}
    </li>
  );
};

export default MenuActionItemContent;
