import React from 'react';

export type Props = {
  isDoubleHeight?: boolean;
};

const UnitLoader = ({ isDoubleHeight = false }: Props) => {
  return (
    <div className="loading__container">
      <div className="loading__wrapper">
        <div className="loading__line loading__line-animate" style={{ height: isDoubleHeight ? '180px' : '80px' }} />
      </div>
    </div>
  );
};

export default UnitLoader;
