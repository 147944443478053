import { IDocUnitProfile } from 'mm-types';
import * as _ from 'lodash';

import * as action from './action';
import * as appendixChapter from './appendix-chapter';
import * as caution from './caution';
import * as chapter from './chapter';
import * as chapterFrontmatter from './chapter-frontmatter';
import * as collection from './collection';
import * as numberedFtidProcActionsGroup from './numbered-ftid-proc-actions-group';
import * as complianceRegTag from './compliance-reg-tag';
import * as complianceTag from './compliance-tag';
import * as diff from './diff';
import * as effectivityTag from './effectivity-tag';
import * as frontmatter from './frontmatter';
import * as ghost from './ghost';
import * as graphic from './graphic';
import * as header from './header';
import * as headerRepeater from './header-repeater';
import * as headerRepeaterLevel1 from './header-repeater-level1';
import * as headerRepeaterLevel2 from './header-repeater-level2';
import * as headerRepeaterLevel3 from './header-repeater-level3';
import * as headerRepeaterLevel4 from './header-repeater-level4';
import * as headerRepeaterLevel5 from './header-repeater-level5';
import * as headerRepeaterLevel6 from './header-repeater-level6';
import * as heading from './heading';
import * as hr from './hr';
import * as inlineStyle from './inline-style';
import * as level1 from './level1';
import * as nonNormalChecklistLevel1 from './non-normal-checklist-level1';
import * as level2 from './level2';
import * as level3 from './level3';
import * as level4 from './level4';
import * as level5 from './level5';
import * as level6 from './level6';
import * as list from './list';
import * as listItem from './list-item';
import * as measureUnit from './measure-unit';
import * as multipartite from './multipartite';
import * as note from './note';
import * as orderedList from './ordered-list';
import * as pageBreak from './page-break';
import * as paragraph from './paragraph';
import * as pre from './pre';
import * as removed from './removed';
import * as section from './section';
import * as table from './table';
import * as tocable from './tocable';
import * as unorderedList from './unordered-list';
import * as video from './video';
import * as volume from './volume';
import * as warning from './warning';

import { UnitTypes } from '../UnitTypes';
import StringUtil from '../../../../../utils/StringUtil';

type DocUnitProfiles = Record<UnitTypes, IDocUnitProfile>;

export const UNITS = {
  action,
  appendixChapter,
  caution,
  chapter,
  chapterFrontmatter,
  collection,
  complianceRegTag,
  complianceTag,
  diff,
  effectivityTag,
  frontmatter,
  ghost,
  graphic,
  header,
  headerRepeater,
  headerRepeaterLevel1,
  headerRepeaterLevel2,
  headerRepeaterLevel3,
  headerRepeaterLevel4,
  headerRepeaterLevel5,
  headerRepeaterLevel6,
  heading,
  hr,
  inlineStyle,
  level1,
  level2,
  level3,
  level4,
  level5,
  level6,
  list,
  listItem,
  measureUnit,
  multipartite,
  note,
  orderedList,
  pageBreak,
  paragraph,
  pre,
  removed,
  section,
  table,
  tocable,
  unorderedList,
  video,
  volume,
  warning,
  numberedFtidProcActionsGroup,
  nonNormalChecklistLevel1
};

export const UNIT_EDIT_PROFILES: Partial<DocUnitProfiles> = _.reduce(
  UNITS,
  (result, value, key) => {
    if (!!value.docUnitProfile) {
      result[StringUtil.camelCaseToDash(key)] = { ...value.docUnitProfile };
    }
    return result;
  },
  {}
);
