const isInt = function (str: string) {
  return !isNaN(+str) && !str.includes('.');
};

/**
 *
 * @param toParse - string containing number and 'px' to parse
 * @return a number value of the string with number and 'px', 0 is returned if no toParse is passed. (e.g. '10px' -> 10, '' -> 0)
 */
const parseIntWithPx = function (toParse: string): number {
  return toParse ? parseInt(toParse.replace('px', ''), 10) : 0;
};

export { isInt, parseIntWithPx };
