import React from 'react';
import { ActionsMenu } from './ActionsMenu';
import { CommentMenu } from './CommentMenu';
import { TagMenu } from './TagMenu';
import { DocUnitTaskTagMenu } from './DocUnitTaskTagMenu';
import { PermissionsOverride } from '../DocUnit';
import { IUnit } from 'mm-types';

type Props = {
  permissions: Partial<PermissionsOverride>;
  inDiffMode: boolean;
  unit: IUnit;
  commentCount: number;
  handleCommentClick(e: any): void;
  facetedTagsCount?: { [p: string]: number };
};

export function DocUnitHoverMenu({ permissions, inDiffMode, unit, commentCount, handleCommentClick, facetedTagsCount }: Props) {
  return (
    <>
      <ActionsMenu
        isInsertableAfter={permissions.isUnitInsertableAfter}
        isEditable={permissions.isUnitEditable}
        isDeletable={permissions.isUnitDeletable}
      />

      {permissions.isUnitCommentable && <CommentMenu unit={unit} commentCount={commentCount} onSelect={handleCommentClick} />}

      {!inDiffMode && (facetedTagsCount?.activeTagCount || facetedTagsCount?.inActiveTagCount) ? (
        <TagMenu
          unit={unit}
          onSelect={handleCommentClick}
          activeTagCount={facetedTagsCount?.activeTagCount ?? 0}
          inActiveTagCount={facetedTagsCount?.inActiveTagCount ?? 0}
        />
      ) : undefined}

      {permissions.isChangeTasksVisible && <DocUnitTaskTagMenu readOnly={!permissions.isUnitEditable} unit={unit} />}
    </>
  );
}
