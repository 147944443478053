import * as React from 'react';
import * as _ from 'lodash';
import Toggle from 'material-ui/Toggle';
import FontColor from '../../../sub/editComponent/controls/FontColor';

export type StyleOverride = { selectorText: string; propertyName: string; value: any; defaultValue: any };
export type StyleOverrideDict = Record<string, StyleOverride>;

import { IElementDefinition } from 'mm-types';

export type Props = {
  unitElement: HTMLElement;
  targetElement: HTMLElement;
  elementProfile: IElementDefinition;
};

export type State = {
  enableStyling: boolean;
  styleOverrides: StyleOverrideDict;
};

const StyleOverrides = (props: Props) => {
  const [unitNid, setUnitNid] = React.useState<string>('');
  const [elementNode, setElementNode] = React.useState<Element | null>(null);
  const [elementUid, setElementUid] = React.useState<string>('');
  const [state, setState] = React.useState<State>({
    enableStyling: false,
    styleOverrides: {}
  });

  React.useEffect(() => {
    buildIdent();
  }, [props.unitElement]);

  const buildIdent = () => {
    setUnitNid(props.unitElement?.closest('.arc-unit')!.getAttribute('data-nid')!);
    setElementNode(props.unitElement.closest('.element-container, .arc-paragraph, .arc-unit'));
    setElementUid(elementNode === props.unitElement ? props.unitElement.getAttribute('data-nid')! : elementNode!.getAttribute('data-nid')!);

    if (elementNode!.querySelector('style')) {
      handleStyleOverrideToggle(true);
    }
  };

  const handleStyleOverrideToggle = (set = false) => {
    const enableStyling = set || !state.enableStyling;
    if (enableStyling) {
      setState({ ...state, enableStyling: enableStyling });
      // TODO SB retrieve from element query service, for now just build each time
      const stylesObj: StyleOverrideDict = {};

      props.elementProfile.styles.forEach((style) => {
        const selectorText = style.selectorTemplate.replace(/{ELEMENT_NID}/, elementUid).replace(/{UNIT_UID}/, unitNid);
        stylesObj[style.id] = {
          selectorText: selectorText,
          propertyName: style.propertyName,
          value: style.defaultValue,
          defaultValue: style.defaultValue
        };
      });

      setState({ ...state, styleOverrides: stylesObj });
      let elementOverrides = elementNode!.querySelector('style');

      if (!elementOverrides) {
        // style tag, if nothing overriden will remove prior to saving
        elementOverrides = document.createElement('style');
        if (elementNode === props.unitElement) {
          props.unitElement.appendChild(elementOverrides);
        } else {
          elementNode!.appendChild(elementOverrides);
        }
      }

      // existing overrides, parse em
      const css = elementOverrides?.sheet;
      if (css instanceof CSSStyleSheet && css) {
        const rules = css.cssRules ? css.cssRules : css.rules;
        if (rules) {
          const styleOverridesObj: StyleOverrideDict = _.cloneDeep(stylesObj);
          _.each(rules, (rule) => {
            if (rule instanceof CSSStyleRule) {
              const foundRule = _.find(styleOverridesObj.find, (styleOverride: StyleOverride) => {
                return (
                  styleOverride.selectorText === rule.selectorText.replace(/"/g, "'") && styleOverride.propertyName === rule.style.item(0)
                );
              });
              if (foundRule) {
                foundRule.value = rule.style[foundRule.propertyName];
              }
            }
          });
          setState({ ...state, styleOverrides: styleOverridesObj });
        }
      }
    } else {
      // strip style overrides?
      const elementOverrides = elementNode!.querySelector('style');
      elementOverrides!.remove();
    }
  };

  const getOverrideValue = (overrideId: string) => {
    const styleOverride: StyleOverride = state.styleOverrides[overrideId];
    return styleOverride ? styleOverride.value : '';
  };

  const setOverrideValue = (overrideId: string, value: string) => {
    const elementOverrides = elementNode!.querySelector('style');
    const styleOverridesObj: StyleOverrideDict = _.cloneDeep(state.styleOverrides);
    const foundRule = styleOverridesObj[overrideId];
    elementOverrides!.innerHTML = `${foundRule.selectorText} \{${foundRule.propertyName}: ${value};\}`;
    styleOverridesObj[overrideId].value = value;
    setState({ ...state, styleOverrides: styleOverridesObj });
  };

  return (
    <div className="style-override-container">
      <div className="editor-side-panel-toggle" onClick={(e) => handleStyleOverrideToggle()}>
        <Toggle
          name="numbertoggle"
          id="numberingPanel"
          label="Override styling"
          data-istoggled={state.enableStyling}
          toggled={state.enableStyling}
        />
      </div>
      {state.enableStyling ? (
        <div>
          <hr className="light" />
          <FontColor
            key="fontColor"
            title="Font Color"
            defaultColor={{ day: { id: '', name: '', value: 'rgb(0,0,0)' }, night: { id: '', name: '', value: 'rgb(0,0,0)' } }}
            color={getOverrideValue('color')}
            onChange={(value) => setOverrideValue('color', value.day.value)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default StyleOverrides;
