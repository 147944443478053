import * as React from 'react';
import { FlatButtonProps, FlatButton, RaisedButton, IconButton } from 'material-ui';
import { MaterialIcon } from './MaterialIcon';
import { AppTheme, ThemeContext } from '../misc/ThemeProvider';
import { useContext, CSSProperties } from 'react';

const FlatButtonMemo = React.memo(FlatButton);

export function AeroCancelButton(props: FlatButtonProps) {
  return <FlatButtonMemo {...props} />;
}
export function AeroStandardButton(props: FlatButtonProps) {
  return <RaisedButton primary={true} backgroundColor="#009688" {...props}></RaisedButton>;
}

export function getButtonColor(theme: AppTheme, gray?: boolean, primary?: boolean, disabled?: boolean): string {
  const {
    custom: { button }
  } = theme;

  if (gray) {
    return disabled ? button.gray.disabledTextColor : button.gray.textColor;
  }
  if (primary) {
    return disabled ? button.primary.disabledTextColor : button.primary.textColor;
  }
  return disabled ? button.standard.disabledTextColor : button.standard.textColor;
}

export type AeroFlatButtonWithIconProps = FlatButtonProps & { iconName: string; leftIcon?: boolean; gray?: boolean };

export const AeroFlatButtonWithIcon = React.forwardRef((props: AeroFlatButtonWithIconProps, ref: React.RefObject<IconButton>) => {
  const theme = useContext(ThemeContext);
  const { iconName, leftIcon = true, children, gray, primary = true, ...rest } = props;
  const iconColor = getButtonColor(theme, gray, primary, props.disabled);
  const icon = (
    <MaterialIcon
      iconName={props.iconName}
      style={{ color: iconColor, verticalAlign: 'middle', margin: leftIcon ? '0 8px 0 0' : '0 0 0 8px' }}
    />
  );
  const pointerEvents: CSSProperties = props.disabled ? { pointerEvents: 'none' } : {};
  return (
    <FlatButtonMemo primary={primary} ref={ref} style={{ paddingLeft: 8, paddingRight: 16, ...pointerEvents, ...props.style }} {...rest}>
      {leftIcon && icon}
      <span
        style={{
          color: iconColor,
          verticalAlign: 'middle',
          paddingTop: 2,
          display: 'inline-block',
          marginLeft: !leftIcon ? 8 : 0
        }}
      >
        {children}
      </span>
      {!leftIcon && icon}
    </FlatButtonMemo>
  );
});

export function AeroConfirmButton({ disabled, ...rest }: FlatButtonProps) {
  const backgroundColor = disabled ? '#eee' : '#59b957';
  const hoverColor = disabled ? '#eee' : '#438741';
  return (
    <FlatButtonMemo
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
      primary={true}
      labelStyle={{ color: '#fff' }}
      disabled={disabled}
      {...rest}
    />
  );
}
