import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as scClient from '../../clients/shared-content';
import { DefaultSharedIndex, settingDefaults, SharedIndexMap } from '../../clients/shared-content';
import * as complianceClient from '../../clients/compliance-tags';
import UnitSpecialInterestTagStore, { UnitSpecialInterestTagStoreEvent } from './UnitSpecialInterestTagStore';
import * as specialInterestClient from '../../clients/special-interest';
import { SharedContentListViewTypes } from '../../components/editor/sidetabs/sub/sharedcontent/SharedContentListTypes';
import EditorStore from './EditorStore';
import SystemStore from '../common/SystemStore';
import IndexEventStore, { IndexEventStoreEvent } from '../events/IndexEventStore';
import UnitTaskStore from './UnitTaskStore';
import alphaNumSort from '../../utils/alphaNumSort';
import * as projectClient from './../../clients/project';
import Store from '../Store';
import {
  DocParams,
  IComplianceTag,
  IRegulation,
  ISharedIndex,
  ISharedIndexOrigin,
  ISharedIndexUsages,
  ISpecialInterestGroup,
  IUnit,
  ITocNode
} from 'mm-types';
import ActiveUserStore from '../common/ActiveUserStore';
import { AxiosError } from 'axios';

// TODO this store is a tad large ;)
export type State = {
  smartContents: (ISpecialInterestGroup | IRegulation | ISharedIndexOrigin | ISharedIndexUsages)[];
  sharedIndexUsages: ISharedIndexUsages[];
  sharedContentCount: number;
  searchSharedContents: string[];
  regulations: IRegulation[];
  unitComplianceTagMap: { [unitUid: string]: string[] };
  unitSpecialInterestTagMap: ISpecialInterestGroup[];
  parentToc: ITocNode | null;
};

export type FilterOptions = {
  type: SharedContentListViewTypes;
};

export type SmartContentStoreEvent = {
  type:
    | 'retrieveUnitSpecialInterestTagMap'
    | 'sharedUsageDeleted'
    | 'retrieveSharedContent'
    | 'retrieveRegulations'
    | 'retrieveUnitComplianceTagMap'
    | 'complianceTagUnits'
    | 'updateRegulation'
    | 'sharedUsageUpdated'
    | 'retrieveDefaultRegModel'
    | 'sharedUsageUpdateChange'
    | 'regulationTaggingError'
    | 'retrieveSharedOriginUnits';
  state?: State;
  sharedContent?: ISharedIndexOrigin | ISharedIndexUsages;
  usage?: ISharedIndexUsages;
  regulationDraft?: IRegulation;
  units?: IUnit[];
};

export class SmartContentStore extends Store<State> {
  private _lastFilterOptions: FilterOptions | null;
  private _currentShareUsageUpdates: { [name: string]: SharedIndexMap };
  private _updatedUsagesMap: { [name: string]: SharedIndexMap };

  constructor() {
    super();
    this.listenTo(UnitSpecialInterestTagStore as any, this.onUnitSpecialInterestTagStoreUpdate);
    this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
    this.state = {
      smartContents: [],
      sharedIndexUsages: [],
      sharedContentCount: 0,
      searchSharedContents: [],
      regulations: [],
      unitComplianceTagMap: {},
      unitSpecialInterestTagMap: [],
      parentToc: null
    };

    this._lastFilterOptions = null;
    this._currentShareUsageUpdates = {};
    this._updatedUsagesMap = {};
  }

  getInitialState() {
    return this.state;
  }

  onUnitSpecialInterestTagStoreUpdate(event: UnitSpecialInterestTagStoreEvent) {
    if (event.type === 'retrieveUnitSpecialInterestTagMap' && event.state.unitSpecialInterestTagMap) {
      const tagMap = UnitSpecialInterestTagStore.getSpecialInterestTagUses();
      this.state.smartContents = this.state.unitSpecialInterestTagMap = this._sortList(tagMap);
      const e: SmartContentStoreEvent = {
        type: 'retrieveUnitSpecialInterestTagMap',
        state: this.state
      };
      this.trigger(e);
    }
  }

  getUsage(firstUnitUid: string, sharedIndexUid: string) {
    return this.state.sharedIndexUsages.find((s) => s.sharedIndex.uid === sharedIndexUid && s.link.unitUid === firstUnitUid)!;
  }

  getUpdatedUsagesTocMap() {
    return this._updatedUsagesMap;
  }

  getEmptySharedIndex() {
    return settingDefaults();
  }

  getEmptyRegulation() {
    return scClient.regulationDefaults();
  }

  getEmptySpecialInterest() {
    return specialInterestClient.settingDefaults();
  }

  getUnitComplianceTags(unitUid: string): string[] {
    const complianceTagUids = this.state.unitComplianceTagMap ? this.state.unitComplianceTagMap[unitUid] : [];
    return complianceTagUids ? complianceTagUids : [];
  }

  // Event Handlers

  /**
   * Called whenever we receive a websocket event
   */
  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.isUserMe) {
      return;
    }

    if (e.activity === 'sharedContentAdded') {
      this.retrieveSmartContent({ indexUid: e.data.indexUid, projectUid: e.data.projectUid }, this._lastFilterOptions!);
    } else if (e.activity === 'sharedContentRemoved') {
      this.triggerContentRemoved({ indexUid: e.data.indexUid, projectUid: e.data.projectUid }, e.data.sharedContentJson);
    } else if (e.activity === 'sharedContentEdited') {
      this.triggerContentRefresh({ indexUid: e.data.indexUid, projectUid: e.data.projectUid });
    }
  }

  triggerContentRemoved(docParams: DocParams, sharedContent: ISharedIndexOrigin | ISharedIndexUsages) {
    this.retrieveSmartContent(docParams, this._lastFilterOptions!).then(() => {
      if (sharedContent && !(sharedContent as ISharedIndexUsages).origin) {
        const e: SmartContentStoreEvent = {
          type: 'sharedUsageDeleted',
          sharedContent: sharedContent
        };
        this.trigger(e);
      }
    });
  }

  triggerContentRefresh(docParams: DocParams) {
    this.retrieveSmartContent(docParams, this._lastFilterOptions!);
  }

  // will trigger: "retrieveSharedContent" or "retrieveRegulations"
  async retrieveSmartContent(docParams: DocParams, options: FilterOptions) {
    try {
      let usages: ISharedIndexUsages[] = [];
      let origins: ISharedIndexOrigin[] = [];
      options = options ? options : { type: 'SHAREDCONTENT_ALL' };
      this._lastFilterOptions = options;

      if (options.type === 'SHAREDCONTENT_REGULATIONS') {
        return this._retrieveRegulations(docParams, false);
      }

      if (options.type === 'SHAREDCONTENT_SPECIALINTEREST') {
        const tagMap = UnitSpecialInterestTagStore.getSpecialInterestTagUses();
        this.state.smartContents = this.state.unitSpecialInterestTagMap = this._sortList(tagMap);

        const e: SmartContentStoreEvent = {
          type: 'retrieveUnitSpecialInterestTagMap',
          state: this.state
        };
        this.trigger(e);
        return this.state;
      }

      if (options.type === 'SHAREDCONTENT_ALL' || options.type === 'SHAREDCONTENT_SOURCE' || options.type === 'SHAREDCONTENT_PENDING') {
        origins = await scClient.getOrigins(docParams.projectUid!);
      }

      if (options.type === 'SHAREDCONTENT_ALL' || options.type === 'SHAREDCONTENT_TARGET') {
        const response = await scClient.getIndexUsages({ indexUid: docParams.indexUid! });
        usages = response.usages;
        this._updatedUsagesMap = response.usageMap;
      }

      this._updateStateFromCollections(origins, usages, options);
      const e: SmartContentStoreEvent = {
        type: 'retrieveSharedContent',
        state: this.state
      };
      this.trigger(e);

      return this.state;
    } catch (err) {
      const e: SmartContentStoreEvent = {
        type: 'retrieveSharedContent',
        state: this.state
      };
      this.trigger(e);

      return this.state;
    }
  }

  retrieveRegulations(docParams: DocParams) {
    return this._retrieveRegulations(docParams);
  }

  private async _retrieveRegulations(docParams: DocParams, isFromRegAction = true) {
    try {
      const regs = await scClient.getRegulations(docParams.indexUid!);
      if (isFromRegAction) {
        this.state.regulations = this._sortList(regs);
      } else {
        this.state.smartContents = this.state.regulations = this._sortList(regs);

        const e: SmartContentStoreEvent = {
          type: 'retrieveRegulations',
          state: this.state
        };
        this.trigger(e);
      }

      return this.state;
    } catch (err) {
      if (isFromRegAction) {
        this.state.regulations = [];
      } else {
        this.state.smartContents = this.state.regulations = [];
        const e: SmartContentStoreEvent = {
          type: 'retrieveRegulations',
          state: this.state
        };
        this.trigger(e);
      }

      return this.state;
    }
  }

  async retrieveUnitComplianceTagMap(docParams: DocParams, options: { silent: boolean } = { silent: false }) {
    const response = await complianceClient.getComplianceMap(docParams.indexUid!);
    this.state.unitComplianceTagMap = response;
    if (!options.silent) {
      const e: SmartContentStoreEvent = {
        type: 'retrieveUnitComplianceTagMap',
        state: this.state
      };
      this.trigger(e);
    }
  }

  async removeRegulationUsageGroup(usage: Partial<IComplianceTag>, indexUid: string) {
    await scClient.deleteComplianceTag(indexUid, usage.regulation!.uid!, {
      unitUids: [usage.unit!.uid!]
    });

    await this.retrieveUnitComplianceTagMap({ indexUid: indexUid });

    // need to do this to get updated usages if UX is displaying this info on RHS
    if (this._lastFilterOptions && this._lastFilterOptions.type === 'SHAREDCONTENT_REGULATIONS') {
      await this.retrieveSmartContent({ indexUid: indexUid }, this._lastFilterOptions);
    }
  }

  async removeComplianceTags(tags: string[], unit: IUnit, indexUid: string) {
    await complianceClient.remove(indexUid, unit.uid, tags);
    await this.retrieveUnitComplianceTagMap({ indexUid: indexUid });

    // need to do this to get updated usages if UX is displaying this info on RHS
    if (this._lastFilterOptions && this._lastFilterOptions.type === 'SHAREDCONTENT_REGULATIONS') {
      await this.retrieveSmartContent({ indexUid: indexUid }, this._lastFilterOptions);
    }
  }

  async complianceTagUnits(regulationUid: string, units: IUnit[], indexUid: string) {
    const unitRegs: Partial<IComplianceTag>[] = [];

    units.forEach((u) => {
      unitRegs.push({ unit: { uid: u.uid }, regulation: { uid: regulationUid } });
    });

    try {
      await complianceClient.create(indexUid, unitRegs);
      await this.retrieveUnitComplianceTagMap({ indexUid: indexUid });
      await this.retrieveSmartContent({ indexUid: indexUid }, this._lastFilterOptions!);
      const e: SmartContentStoreEvent = {
        type: 'complianceTagUnits',
        state: this.state
      };
      this.trigger(e);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 400) {
        const err: SmartContentStoreEvent = {
          type: 'regulationTaggingError',
          state: this.state
        };
        this.trigger(err);
      }
    }
  }

  async addRegulation(regulation: IRegulation, selectedUnits: IUnit[], docParams: DocParams) {
    try {
      const r = await scClient.createRegulation(regulation);
      await this.complianceTagUnits(r.uid, selectedUnits, docParams.indexUid!);
      return {
        state: this.state,
        regulation: r
      };
    } catch (err) {
      this.retrieveSmartContent(docParams, this._lastFilterOptions!);
    }
  }

  async updateRegulation(updatedRegulation: IRegulation, selectedUnits: IUnit[], docParams: DocParams) {
    try {
      const r = await scClient.updateRegulation(updatedRegulation.uid, updatedRegulation);
      if (EditorStore.isMode('REGULATIONSELECTION')) {
        await this.complianceTagUnits(r.uid, selectedUnits, docParams.indexUid!);
        return;
      } else {
        await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
        const e: SmartContentStoreEvent = {
          type: 'updateRegulation',
          state: this.state
        };
        this.trigger(e);
      }
    } catch (err) {
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
    }
  }

  async removeRegulation(removeRegulation: IRegulation, docParams: DocParams) {
    try {
      await scClient.removeRegulation(removeRegulation.uid);
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
    } catch (err) {
      const axiosErr = err as AxiosError;
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
      if (axiosErr.response && axiosErr.response.status === 403) {
        EditorStore.triggerShowEditorError({ axiosErr: axiosErr });
      }
    }
  }

  retrieveComplianceTags(regulationUid: string, indexUid: string) {
    return complianceClient.getForRegulation(indexUid, regulationUid);
  }

  async retrieveTocSharedUsages(tocUid: string | undefined, toc: ITocNode | null, docParams: DocParams) {
    if (tocUid && docParams.indexUid) {
      if (toc && this.state.parentToc && this.isChildToc(toc, this.state.parentToc)) {
        return this.state;
      } else {
        this.state.parentToc = toc;
        if (tocUid.length === 36) {
          const response = await scClient.getSharedIndexUsageForTocable(tocUid, docParams.indexUid);
          if (response) {
            this._updateStateFromCollections([], response, { type: 'SHAREDCONTENT_TARGET' });
          }
        }
        return this.state;
      }
    } else {
      return this.retrieveSharedUsages(docParams);
    }
  }

  isChildToc(toc: ITocNode, parentToc: ITocNode) {
    let isChild = false;
    parentToc.children.forEach((child) => {
      if (child.uid === toc.uid) {
        isChild = true;
      } else {
        return this.isChildToc(toc, child);
      }
    });
    return isChild;
  }

  // retrieve usages only, but update store state while we are at it just to ensure all is uptodate
  async retrieveSharedUsages(docParams: DocParams) {
    const response = await scClient.getIndexUsages({ indexUid: docParams.indexUid! });
    this._updatedUsagesMap = response.usageMap;
    this._updateStateFromCollections([], response.usages, { type: 'SHAREDCONTENT_TARGET' });
    return this.state;
  }

  async addSharedContent(sharedContent: DefaultSharedIndex, docParams: DocParams) {
    try {
      const sharedContentJson = await scClient.createIndex(sharedContent.sharedIndex!);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'sharedContentAdded',
        data: { indexUid: docParams.indexUid!, projectUid: docParams.projectUid!, sharedContentJson: sharedContentJson }
      });
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
      return { state: this.state, sharedContent: sharedContentJson };
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr?.response?.data?.errors?.[0]?.code === 40039) {
        EditorStore.variantSelectionError('You cannot create smart content that contains variants');
      } else {
        SystemStore._triggerSystemError(axiosErr);
      }
      this.retrieveSmartContent(docParams, this._lastFilterOptions!);
    }
  }

  async updateSharedContent(updatedSharedContent: ISharedIndexUsages, docParams: DocParams) {
    if (updatedSharedContent.origin) {
      const sharedIndex: Partial<ISharedIndex> = {
        name: updatedSharedContent.sharedIndex.name!,
        description: updatedSharedContent.sharedIndex.description!,
        tags: updatedSharedContent.sharedIndex.tags!,
        allowDerivatives: updatedSharedContent.sharedIndex.allowDerivatives!,
        isPublic: updatedSharedContent.sharedIndex.isPublic!
      };

      await scClient.updateIndex(updatedSharedContent.sharedIndex.uid!, sharedIndex);
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);

      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'sharedContentEdited',
        data: {
          indexUid: docParams.indexUid!,
          projectUid: docParams.projectUid!,
          sharedcontent: sharedIndex
        }
      });

      this.triggerContentRefresh(docParams);
    } else {
      await this.updateSharedUsage(updatedSharedContent.units[0].uid, updatedSharedContent, docParams, this._lastFilterOptions!);
    }
  }

  async removeSharedContent(removeSharedContent: ISharedIndexOrigin | ISharedIndexUsages, docParams: DocParams) {
    try {
      if ((removeSharedContent as ISharedIndexUsages).origin) {
        await scClient.removeIndex(removeSharedContent.sharedIndex.uid!);
      } else {
        await scClient.deleteIndexUsage(docParams.indexUid!, (removeSharedContent as ISharedIndexUsages).units[0].uid);
      }

      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'sharedContentRemoved',
        data: { indexUid: docParams.indexUid!, projectUid: docParams.projectUid!, sharedcontent: removeSharedContent }
      });
      this.triggerContentRemoved(docParams, removeSharedContent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      await this.retrieveSmartContent(docParams, this._lastFilterOptions!);
      if (axiosErr.response && axiosErr.response.status === 400) {
        const invalidError = axiosErr.response.data?.errors?.filter((e) => e.code === 400329).length > 0;
        invalidError && EditorStore.triggerShowEditorError({ axiosErr: axiosErr });
      }
      if (axiosErr.response && axiosErr.response.status === 403) {
        EditorStore.triggerShowEditorError({ axiosErr: axiosErr });
      }
    }
  }

  // usage info is used globally to indicate new updates in editor - when update it, this ensures store is kept uptodate
  // TODO make this a promise
  async updateSharedUsage(
    unitUid: string,
    sharedIndexUsage: Partial<ISharedIndexUsages>,
    docParams: DocParams,
    filterOptions?: FilterOptions
  ) {
    filterOptions = filterOptions ? filterOptions : this._lastFilterOptions!;

    try {
      const updatedUsage = await scClient.updateIndexUsage(docParams.indexUid!, unitUid, sharedIndexUsage);
      await this.retrieveSmartContent(docParams, filterOptions);
      await UnitTaskStore.init(EditorStore.getDocParams());

      const e: SmartContentStoreEvent = {
        type: 'sharedUsageUpdated',
        sharedContent: updatedUsage
      };
      this.trigger(e);

      return updatedUsage;
    } catch (err) {
      await this.retrieveSmartContent(docParams, filterOptions);
      EditorStore.triggerShowEditorError({ axiosErr: err as AxiosError });
    }
  }

  // note: this event doesn't change store state, as its for a single retrieval
  retrieveSharedIndex(sharedIndexUid: string) {
    return scClient.getSharedIndex(sharedIndexUid);
  }

  retrieveSharedUsage(unitUid: string, docParams: DocParams, isDiff = false) {
    return scClient.getIndexUsage(docParams.indexUid!, unitUid, isDiff);
  }

  async retrieveSharedOriginUnits(sharedIndexUid: string, isDiff = false): Promise<Partial<IUnit>[]> {
    const units = await scClient.getOriginUnits(sharedIndexUid, isDiff);
    if (units) {
      const e: SmartContentStoreEvent = {
        type: 'retrieveSharedOriginUnits',
        units
      };
      this.trigger(e);
    }
    return units;
  }

  async retrieveDefaultRegModel(options: { silent?: boolean; indexUid?: string; unitUid: string; projectUid: string; isSilent?: boolean }) {
    const result = await scClient.getRegulationForUnit(options.indexUid!, options.unitUid);
    result.projectUid = options.projectUid;

    if (!options.isSilent) {
      const e: SmartContentStoreEvent = {
        type: 'retrieveDefaultRegModel',
        regulationDraft: result
      };
      this.trigger(e);
    }

    return result;
  }

  retrieveRegProject(projectUid: string) {
    return projectClient.getProject(projectUid);
  }

  async updateSharedOrigin(sharedIndexOrigin: Partial<ISharedIndexOrigin>) {
    try {
      await scClient.updateOrigin(sharedIndexOrigin.sharedIndex!.uid!, sharedIndexOrigin);
      return;
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.data && axiosErr.response.data.errors && axiosErr.response.data.errors.length > 0) {
        if (axiosErr.response.data.errors[0].code === 400393) {
          EditorStore.variantSelectionError('A share may not include Variant Tagged Elements');
        } else {
          SystemStore._triggerSystemError(axiosErr);
        }
      }
    }
  }

  _updateStateFromCollections(
    origins: ISharedIndexOrigin[],
    usages: ISharedIndexUsages[],
    filterOptions: { type: SharedContentListViewTypes }
  ) {
    filterOptions = filterOptions ? filterOptions : { type: 'SHAREDCONTENT_ALL' };

    let sharedContents: Array<ISharedIndexOrigin | ISharedIndexUsages> = [];
    let sharedContentsUsages: ISharedIndexUsages[] | null = null;
    let sharedContentsOriginsCount = 0;

    if (filterOptions.type === 'SHAREDCONTENT_SOURCE' || filterOptions.type === 'SHAREDCONTENT_PENDING') {
      sharedContents = origins;
      sharedContentsOriginsCount = sharedContents.length;
      if (filterOptions.type === 'SHAREDCONTENT_PENDING') {
        sharedContents = (sharedContents as ISharedIndexOrigin[]).filter((s) => s.originDiffersFromSharedIndex === true);
      }
    } else if (filterOptions.type === 'SHAREDCONTENT_TARGET') {
      sharedContents = usages;
      sharedContentsUsages = usages;
    } else if (filterOptions.type === 'SHAREDCONTENT_ALL') {
      const sharedContentsOrigins = origins;
      sharedContentsOriginsCount = sharedContentsOrigins.length;
      sharedContentsUsages = usages;
      sharedContents = _.union(sharedContentsOrigins, sharedContentsUsages as Array<ISharedIndexOrigin | ISharedIndexUsages>);
    }

    sharedContents = this._sortList(sharedContents);

    this.state = Object.assign({}, this.state, {
      smartContents: sharedContents,
      sharedContentCount: sharedContentsOriginsCount
    });

    // update the latest usages only while we are here for rest of app
    if (sharedContentsUsages) {
      this.state.sharedIndexUsages = sharedContentsUsages;
      this._triggerUsageUpdateChange();
    }
  }

  _triggerUsageUpdateChange() {
    if (!_.isEqual(this._currentShareUsageUpdates, this.getUpdatedUsagesTocMap())) {
      const e: SmartContentStoreEvent = {
        type: 'sharedUsageUpdateChange',
        state: this.state
      };
      this.trigger(e);
    }
    this._currentShareUsageUpdates = this.getUpdatedUsagesTocMap();
  }

  _sortList(list: any[]) {
    list.sort((a, b) => {
      return alphaNumSort(a.name, b.name);
    });

    return list;
  }
}

const singleton = Reflux.initStore<SmartContentStore>(SmartContentStore);
export default singleton;
