import * as React from 'react';
import ProjectUsersStore from '../../../../../flux/editor/ProjectUsersStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import SystemStore from '../../../../../flux/common/SystemStore';
import LookupStore from '../../../../../flux/common/LookupStore';
import ChangeTaskReasonStore from '../../../../../flux/settings/ChangeTaskReasonStore';
import ChangeTasksStore, { ChangeTasksStoreEvent } from '../../../../../flux/editor/ChangeTasksStore';
import ImportTasksStore, { ImportTasksStoreEvent } from '../../../../../flux/editor/ImportTasksStore';
import UnitTaskStore from '../../../../../flux/editor/UnitTaskStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import AutoTagStore from '../../../../../flux/editor/AutoTagStore';
import ChangeTaskNotAssignedModal from './ChangeTaskNotAssignedModal';
import TaskItem from './TaskItem';
import ChangeTaskFilter from '../../../../misc/ChangeTaskFilter';
import { CircularProgress, IconButton } from 'material-ui';
import { IEditorStoreEvent, ITask, ITaskImportResponse } from 'mm-types';
import TaskModal from './TaskModal';
import TaskDetailModal from './TaskDetailModal';
import ActiveUserStore from '../../../../../flux/common/ActiveUserStore';
import appStore from '../../../../../appStore';
import { showSystemSnackbarMessage } from '../../../../misc/SystemSnackbar/thunks';

export type Props = {};

export type State = {
  isLoading: boolean;
  openedTask: null | string;
  openedTaskDetails: ITask | null;
  tasks: null | ITask[];
  showTaskUploadModal: boolean;
  showTaskDetailModal: boolean;
  taskImportResponse: null | ITaskImportResponse;
};

export default class ChangeTasks extends React.Component<Props, State> {
  private editorUnsub: Function;
  private unitTasksUnsub: Function;
  private autoTagUnsub: Function;
  private changeTaskUnsub: Function;
  private importTaskUnsub: Function;

  constructor(props: Props) {
    super(props);

    this.editorUnsub = EditorStore.listen(this._onEditorStoreUpdate, this);
    this.unitTasksUnsub = UnitTaskStore.listen(this._onUnitTaskStoreUpdate, this);
    this.changeTaskUnsub = ChangeTasksStore.listen(this._onTaskStoreChange, this);
    this.importTaskUnsub = ImportTasksStore.listen(this._onImportTaskStoreChange, this);
    this.autoTagUnsub = AutoTagStore.listen(this._onAutoTagChange, this);

    this.state = {
      isLoading: true,
      openedTask: null, // taskUid
      openedTaskDetails: null, // taskObject
      tasks: null,
      showTaskUploadModal: false,
      taskImportResponse: null,
      showTaskDetailModal: false
    };
  }

  componentWillUnmount() {
    this.unitTasksUnsub();
    this.editorUnsub();
    this.changeTaskUnsub();
    this.importTaskUnsub();
    this.autoTagUnsub();
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      Promise.all([
        ProjectUsersStore.retrieve({ projectUid: EditorStore.getDocParams()!.projectUid! }),
        LookupStore.retrieve('safety_params'),
        ChangeTaskReasonStore.retrieveChangeTaskReasons()
      ]).then(() => {
        ChangeTasksStore.retrieveTasks(EditorStore.getDocParams());
      });
    });
  }

  showDetailModal() {
    this.setState({
      showTaskUploadModal: false,
      showTaskDetailModal: !this.state.showTaskDetailModal
    });
  }

  showUploadModal() {
    this.setState({
      showTaskUploadModal: !this.state.showTaskUploadModal,
      showTaskDetailModal: false
    });
  }

  _onUnitTaskStoreUpdate() {
    ChangeTasksStore.retrieveTasks(EditorStore.getDocParams());
  }

  _onEditorStoreUpdate(e: IEditorStoreEvent<'deleteUnitConfirm' | 'updateUnit'>) {
    if (e.type === 'deleteUnitConfirm' || e.type === 'updateUnit') {
      const castEvent = e as IEditorStoreEvent<'deleteUnitConfirm' | 'updateUnit'>;

      if (UnitTaskStore.getUnitTasks(castEvent.data!.unit!.uid!).length > 0) {
        ChangeTasksStore.retrieveTasks(EditorStore.getDocParams());
      }
    }
  }

  _onTaskStoreChange(state: ChangeTasksStoreEvent) {
    if (!state.error) {
      const orderTasks = state.tasks.reverse();

      this.setState({
        isLoading: false,
        openedTask: null,
        openedTaskDetails: null,
        tasks: orderTasks
      });

      AutoTagStore.retrieveTasks(EditorStore.getDocParams(), () => this.setState({ ...this.state }));
    }
  }

  _onImportTaskStoreChange(state: ImportTasksStoreEvent) {
    if (state.taskImportResponse) {
      if (state.taskImportResponse.skippedLines.length === 0 && state.taskImportResponse.errorLines.length === 0) {
        this._showSnackbar(state.taskImportResponse.successfulCount + ' Tasks Successfully Imported');
        this.showUploadModal();
        this.componentDidMount();
      } else {
        this.setState({ taskImportResponse: state.taskImportResponse });
        this.showUploadModal();
        this.showDetailModal();
      }
    }
  }

  _onAutoTagChange() {
    this.setState({ ...this.state });
  }

  _createNew() {
    this.setState({ openedTaskDetails: null, openedTask: 'new' }, () => {});
  }

  _handleFilterChange(e: { taskUid: string; task?: ITask; assigned: boolean }) {
    if (e.assigned && e.task!.active) {
      ChangeTasksStore.isUserAssignedToTask(e.taskUid, SystemStore.getCurrentUserUid()).then((isAssigned) => {
        if (!isAssigned) {
          (this.refs.changeTaskNotAssignedModal as ChangeTaskNotAssignedModal).open(e.task!);
        } else {
          AutoTagStore.addTask(e.taskUid);
        }
      });
    } else {
      AutoTagStore.removeTask(e.taskUid);
    }
  }

  _editPermission() {
    // honor the index locked flag only when you're looking at a published copy
    if (ProjectStore.isIndexLocked() && ProjectStore.getIndex()!.isPublished) {
      // Never!
      return 'none';
    }
    if (ActiveUserStore.isAdmin() || ProjectStore.getProject()?.currentUserPermissions?.canManageChangeTasks) {
      return 'all';
    }
    return 'user';
  }

  _canShowAutoTags() {
    return !this.state.isLoading && !EditorStore.isReadOnly() && this.state.tasks && this.state.tasks.length > 0;
  }

  _handleTaskVisibilityChange(e: { requestToOpen: boolean; taskUid: string }) {
    if (e.requestToOpen) {
      ChangeTasksStore.retrieveTask(e.taskUid).then((task) => {
        this.setState({ openedTaskDetails: task, openedTask: e.taskUid });
      });
    } else {
      this.setState({ openedTaskDetails: null, openedTask: null });
    }
  }

  _calcZIndex(index: number) {
    const baseIndex = 15;
    return baseIndex + (this.state.tasks ? this.state.tasks.length - index : 0);
  }

  _downloadChangeTasksToCSV() {
    location.href = '/api/tasks/download/csv?indexUid=' + EditorStore.getDocParams().indexUid;
  }

  _importChangeTasksCSV(file) {
    ImportTasksStore.importChangeTaskCSV(file, EditorStore.getDocParams().indexUid!);
    this.componentDidMount();
  }

  _showSnackbar(msg: string) {
    appStore.dispatch<any>(
      showSystemSnackbarMessage({
        message: msg,
        open: true,
        className: 'doc-snackbar custom-snackbar',
        bodyStyle: { marginLeft: '30px' },
        autoHideDuration: 10000
      })
    );
  }

  downloadErrorLog(errorLog: string) {
    const errorLogFile = document.createElement('a');
    errorLogFile.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(errorLog));
    errorLogFile.setAttribute('download', 'errorLog.csv');
    errorLogFile.style.display = 'none';
    document.body.appendChild(errorLogFile);
    errorLogFile.click();
    document.body.removeChild(errorLogFile);
    this.componentDidMount();
  }

  render() {
    const noTasksExist = this.state.tasks && this.state.tasks.length < 1 ? true : false;
    // let tasksExist = this.state.tasks && this.state.tasks.length > 1 ? false : true;
    const editorIsReadOnly = EditorStore.isReadOnly();

    return (
      <div className="changetasks-mainaction">
        <h5>Task List</h5>
        <IconButton
          iconClassName={`material-icons icon-export-as-csv ${noTasksExist ? `disabled` : ``}`}
          className={`change-task-options download-csv`}
          onClick={() => {
            this._downloadChangeTasksToCSV();
          }}
          disabled={noTasksExist}
          tooltipPosition="bottom-left"
          tooltipStyles={{ top: 30 + 'px', pointerEvents: 'none' }}
          tooltip="Download csv"
        />
        <IconButton
          id="upload-csv"
          disabled={editorIsReadOnly || this._editPermission() !== 'all'}
          className="change-task-options"
          iconClassName={`material-icons ${editorIsReadOnly || this._editPermission() !== 'all' ? `disabled` : ``}`}
          style={{ left: '14px' }}
          tooltipPosition="bottom-center"
          tooltip="Upload csv"
          tooltipStyles={{ top: 30 + 'px', pointerEvents: 'none' }}
          onClick={() => this.showUploadModal()}
        >
          file_upload
        </IconButton>
        <IconButton
          disabled={editorIsReadOnly || this._editPermission() !== 'all'}
          onClick={() => {
            this._createNew();
          }}
          style={{ left: '29px' }}
          className="change-task-options"
          iconClassName={`material-icons ${editorIsReadOnly || this._editPermission() !== 'all' ? `disabled` : ``}`}
          tooltipPosition="bottom-center"
          tooltipStyles={{ top: 30 + 'px', pointerEvents: 'none' }}
          tooltip="Add Task"
        >
          add_box
        </IconButton>

        {this.state.tasks && this.state.tasks.length === 0 && this.state.openedTask !== 'new' ? (
          <div className="tasks-empty narrow-panel-empty-list changeTask-sizing">
            <img src={'/assets/images/changetask_panel_info.svg'} />

            <span className="empty-title">No Tasks</span>
            <span className="empty-details">
              There are no tasks for this document.
              <br /> When tasks are assigned, in progress <br />
              or complete, you will see status and <br />
              outcomes here.
            </span>
          </div>
        ) : undefined}

        {this.state.isLoading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" size={17.85} />
          </div>
        ) : undefined}

        {this._canShowAutoTags() ? (
          <ChangeTaskFilter
            headerTitle="Auto-tag Tasks"
            title="Auto-tag Task"
            tasks={AutoTagStore.getTasks()}
            zIndex={this.state.tasks!.length + 17}
            onChange={(e) => this._handleFilterChange(e)}
          />
        ) : undefined}

        {!this.state.isLoading ? (
          <ul className={'task-list' + (this._canShowAutoTags() ? '' : ' no-autotags')}>
            {this.state.openedTask === 'new' ? (
              <TaskItem
                editPermission={this._editPermission()}
                isOpened={true}
                isNewTask={true}
                onRequestOpenChange={(e) => this._handleTaskVisibilityChange(e)}
                zIndex={this.state.tasks ? this.state.tasks.length + 16 : 15}
                ref="taskitem_new"
              />
            ) : undefined}

            {this.state.tasks
              ? this.state.tasks.map((task, taskIndex) => {
                  return (
                    <TaskItem
                      key={task.uid}
                      task={
                        this.state.openedTaskDetails !== null && this.state.openedTaskDetails.uid === task.uid
                          ? this.state.openedTaskDetails
                          : task
                      }
                      editPermission={this._editPermission()}
                      isOpened={this.state.openedTask === task.uid}
                      onRequestOpenChange={(e) => this._handleTaskVisibilityChange(e)}
                      zIndex={this._calcZIndex(taskIndex)}
                    />
                  );
                })
              : undefined}
          </ul>
        ) : undefined}
        <ChangeTaskNotAssignedModal ref="changeTaskNotAssignedModal" />
        {this.state.showTaskUploadModal ? (
          <TaskModal onUpload={() => {}} onClose={() => this.showUploadModal()} importCSV={(file) => this._importChangeTasksCSV(file)} />
        ) : undefined}
        {this.state.showTaskDetailModal ? (
          <TaskDetailModal
            onClose={() => this.showDetailModal()}
            onDownloadErrorLog={(errorLog) => this.downloadErrorLog(errorLog)}
            importResponse={this.state.taskImportResponse}
          />
        ) : undefined}
      </div>
    );
  }
}
