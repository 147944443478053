export type ShareUsageUpdateStrategyTypes = 'AUTOMATIC' | 'MANUAL' | 'NONE';

const properties = {
  AUTOMATIC: {
    title: 'Update automatically',
    shortTitle: 'auto'
  },

  MANUAL: {
    title: 'Update with approval',
    shortTitle: 'approval'
  },

  NONE: {
    title: 'Referenced update',
    shortTitle: 'reference'
  }
};

const ShareUsageUpdateStrategy = {
  props: properties
};

export default ShareUsageUpdateStrategy;
