import * as React from 'react';
import CommentStore, { CommentStoreEvent } from '../../../flux/editor/CommentStore';
import MergeRevisionsStore, { MergeRevisionsStoreEvent } from '../../../flux/editor/MergeRevisionsStore';
import MergeActivityPanel from './MergeActivityPanel';
import UnitComments from './UnitComments';
import IconButton from 'material-ui/IconButton';

export type Props = {};

export type State = {
  hasActivities: boolean;
  activeTab: string;
};

export default class MergeActionsPanel extends React.Component<Props, State> {
  private mergeUnsub: Function;
  private commentUnsub: Function;

  constructor(props: Props) {
    super(props);

    this.mergeUnsub = MergeRevisionsStore.listen(this._onMergeStoreUpdate, this);
    this.commentUnsub = CommentStore.listen(this._onCommentStoreUpdate, this);

    this.state = {
      hasActivities: MergeRevisionsStore.getInitialState().activities.length > 0,
      activeTab: 'activity'
    };
  }

  componentWillUnmount() {
    this.mergeUnsub();
    this.commentUnsub();
  }

  _onCommentStoreUpdate(e: CommentStoreEvent) {
    if (e.type === 'commentIntent') {
      if (this.state.activeTab !== 'comment') {
        this._handleTab('comment', () => {
          CommentStore.commentIntent(e.unit!);
        });
      }
    }
  }

  _onMergeStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'mergeComplete') {
      if (this.state.activeTab !== 'activity') {
        this._handleTab('activity');
      }
    }
  }

  _handleTab(tabId: string, onTabbed?: () => void) {
    this.setState({ activeTab: tabId }, onTabbed);
  }

  render() {
    return (
      <div className="col s2 side-container editing-subactions-container">
        <div className="row editing-subactions-tabs">
          <div className="col s12">
            <ul className="tabs icon-action-buttons ieTooltip">
              <li
                className={`tab col s3 ${this.state.activeTab === 'activity' ? ' active' : ''}`}
                onClick={(e) => this._handleTab('activity')}
              >
                <IconButton iconClassName="material-icons" tooltipPosition="top-left" tooltip="Merge Activity">
                  merge_type
                </IconButton>
              </li>
              {this.state.hasActivities ? (
                <li
                  className={`tab col s3 ${this.state.activeTab === 'comment' ? ' active' : ''}`}
                  onClick={(e) => this._handleTab('comment')}
                >
                  <IconButton iconClassName="material-icons" tooltipPosition="top-left" tooltip="Comments">
                    insert_comment
                  </IconButton>
                </li>
              ) : undefined}
            </ul>
          </div>
        </div>

        {this.state.activeTab === 'activity' ? (
          <div className="col s12 side-container-content">
            <MergeActivityPanel />
          </div>
        ) : undefined}
        {this.state.activeTab === 'comment' ? (
          <div className="col s12 side-container-content">
            <UnitComments mergePanel={true} />
          </div>
        ) : undefined}
        <div className="actions-footer"></div>
      </div>
    );
  }
}
