import * as React from 'react';
import { IProjectActivity } from 'mm-types';

type PropsType = {
  entry: IProjectActivity;
};

export const GroupedTaskUpdates = ({ entry }: PropsType) => {
  const groupedTaskUpdatesDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    groupedTaskUpdatesDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  return <div className="settings-details">{groupedTaskUpdatesDom}</div>;
};
