import * as React from 'react';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

const EffectivityProps = (props: IGenericEditProps) => {
  const project = ProjectStore.getProject();
  if (project) {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        <ToggleSection title="Effectivity Formatting" id={'unitProps_' + props.index} defaultOpen={true}>
          {ProjectDefinitionStore.allowUnitIndentation() && UnitUtils.isFormatingAvailable(props.unitElement) && (
            <MultiFormatting formatNode={props.index === 0 ? null : props.unitElement} unitProfile={props.unitProfile} />
          )}
          <DataAttributesPropsWrapper {...props} />
        </ToggleSection>
      </div>
    );
  } else {
    return null;
  }
};

export default EffectivityProps;
