import axios, { CancelTokenSource } from 'axios';
import { Cancelled, mm } from './base-clients';
import { IEffectivity, IMsnRange } from 'mm-types';
import { AircraftModel, UpdateAircraftModel } from '../components/documents/aircraftAllocationTable/types';

export type SectionEffectivities = {
  availableEffectivities: IEffectivity[];
  assignedEffectivities: IEffectivity[];
  manufacturerEffec: IMsnRange;
};

let getSectionEffectivitiesSource: CancelTokenSource | null = null,
  removeSectionEffectivitiesSource: CancelTokenSource | null = null,
  addSectionEffectivitiesSource: CancelTokenSource | null = null;

export async function getSectionEffectivities(
  projectUid: string,
  indexUid: string,
  sectionUid: string
): Promise<Cancelled | SectionEffectivities> {
  if (getSectionEffectivitiesSource) {
    getSectionEffectivitiesSource.cancel();
  }
  getSectionEffectivitiesSource = axios.CancelToken.source();

  try {
    const response = await mm.get<SectionEffectivities>(`/projects/${projectUid}/indexes/${indexUid}/sections/${sectionUid}/metadata`, {
      cancelToken: getSectionEffectivitiesSource.token
    });

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function addSectionEffectivities(
  projectUid: string,
  indexUid: string,
  sectionUid: string,
  msns: string[]
): Promise<Cancelled | void> {
  if (addSectionEffectivitiesSource) {
    addSectionEffectivitiesSource.cancel();
  }
  addSectionEffectivitiesSource = axios.CancelToken.source();

  try {
    await mm.post<void>(
      `/projects/${projectUid}/indexes/${indexUid}/sections/${sectionUid}/metadata/add/effectivity?msns=${msns.join(',')}`,
      {
        cancelToken: addSectionEffectivitiesSource.token
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function removeSectionEffectivities(
  projectUid: string,
  indexUid: string,
  sectionUid: string,
  msns: string[]
): Promise<Cancelled | void> {
  if (removeSectionEffectivitiesSource) {
    removeSectionEffectivitiesSource.cancel();
  }
  removeSectionEffectivitiesSource = axios.CancelToken.source();

  try {
    await mm.post<void>(
      `/projects/${projectUid}/indexes/${indexUid}/sections/${sectionUid}/metadata/remove/effectivity?msns=${msns.join(',')}`,
      {
        cancelToken: removeSectionEffectivitiesSource.token
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getAATEffectivities(projectUid, indexUid): Promise<AircraftModel[]> {
  const res = await mm.get<{ aircraftItems: AircraftModel[] }>(`/projects/${projectUid}/indexes/${indexUid}/metadata/aat/effectivity`);
  return res.data.aircraftItems;
}

export async function addAATEffectivity(
  aircraft: UpdateAircraftModel,
  projectUid?: string | null,
  indexUid?: string | null
): Promise<void> {
  removeEmptyFSNs(aircraft);
  await mm.post(`/projects/${projectUid}/indexes/${indexUid}/metadata/aat/effectivity`, aircraft);
}

export async function removeAATEffectivity(
  aircraft: UpdateAircraftModel | null,
  projectUid?: string | null,
  indexUid?: string | null
): Promise<void> {
  await mm.delete(`/projects/${projectUid}/indexes/${indexUid}/metadata/aat/effectivity?msn=${aircraft?.manufacturerSerialNumber}`);
}

export async function updateAATEffectivity(
  aircraft: UpdateAircraftModel | null,
  projectUid?: string | null,
  indexUid?: string | null
): Promise<void> {
  removeEmptyFSNs(aircraft);
  await mm.put(`/projects/${projectUid}/indexes/${indexUid}/metadata/aat/effectivity`, aircraft);
}

function removeEmptyFSNs(aircraft: UpdateAircraftModel | null): void {
  if (aircraft) {
    aircraft.fleetSerialNumbers = aircraft.fleetSerialNumbers.filter((fsn) => fsn.fleetSerialNumber !== '' && fsn.customerCode !== '');
  }
}
