import React from 'react';
import SystemStore from '../../../flux/common/SystemStore';
import { AxiosError } from 'axios';
import { getErrorDetails } from '../../../clients/base-clients-interceptors';
import appStore from '../../../appStore';
import { hideSystemAlert, showSystemAlert } from '../../misc/SystemAlert/thunks';
import { transitionTo } from '../../../utils/Navigation';
import { makeSimpleSnackbar } from '../../misc/SystemSnackbar/util';
import FlatButton from 'material-ui/FlatButton';

function _makeAlert(title: string, message: string, onClose?: () => void, errorActions?: JSX.Element[]) {
  appStore.dispatch<any>(showSystemAlert({ errorTitle: title, onClose: onClose, errorMessage: message, errorActions }));
}

function _handle400(serverErrorCode: number, error?: AxiosError) {
  // duplicate ordinals
  if (serverErrorCode === 400323) {
    _makeAlert('Ordinal Already In Use', 'The ordinal you have entered is already in use in this document.');
  }

  // invalid uid: only way is user entered crap in url
  else if (serverErrorCode === 40075) {
    _makeAlert("Document doesn't exist", 'The url you entered for this document is invalid.', () => transitionTo('/'));
  } else if (serverErrorCode === 400303) {
    _makeAlert('Document contains broken links', 'You can not publish this document as it contains broken links.');
  }

  // this should never happen, but just in case tiny screws up
  else if (serverErrorCode === 40015 || serverErrorCode === 40016) {
    makeSimpleSnackbar('Error: Could not save this element - invalid data');
  }

  // if inserted in wrong location or conversion results in invalid structure - structural error
  else if (
    serverErrorCode === 40032 ||
    serverErrorCode === 400326 ||
    serverErrorCode === 400327 ||
    serverErrorCode === 400171 ||
    serverErrorCode === 400174 ||
    serverErrorCode === 40017
  ) {
    makeSimpleSnackbar('Error: Cannot complete, this action will cause an invalid document structure');
  }

  // if attempting to remove from project with enforced auto tag
  else if (serverErrorCode === 400108) {
    makeSimpleSnackbar('Error: Cannot complete, a task is required when auto tagging is enforced');
  }

  // if attempting to revert shared usage
  else if (serverErrorCode === 400392) {
    makeSimpleSnackbar('Error: Cannot modify Shared Content or its structure');
  }

  // merge unit failure
  else if (serverErrorCode === 400121) {
    makeSimpleSnackbar(error!.response!.data.errors[0].message);
  }

  // no idea what going on: go nuclear!
  else if (error) {
    SystemStore.showSystemError(error);
  }
}

function _handle403(serverErrorCode: number, error?: AxiosError, additional?: string | null) {
  if (serverErrorCode === 40309) {
    // Document has already been published - websockets didn't get thru for some reason
    _makeAlert(
      'Document Already Published',
      'You can no longer save this document as it has already been published by another user',
      () => location.reload() // will show readonly doc
    );
  } else if (serverErrorCode === 40304) {
    makeSimpleSnackbar('You do not have permission to delete this Shared Content');
  } else if (serverErrorCode === 40310) {
    makeSimpleSnackbar('Cannot perform operation as this element has been updated by another user');
  } else if (error && serverErrorCode === 40306 && additional === 'access_from_another_doc') {
    makeSimpleSnackbar(error.response!.data.errors[0].message);
  }

  // encompasing 403: kick user out of document
  else {
    _makeAlert('No Permission', 'You do not have sufficient permissions to perform this operation', undefined, [
      <FlatButton
        key={1}
        label="Exit"
        onClick={() => {
          transitionTo('/');
          appStore.dispatch<any>(hideSystemAlert());
        }}
      />,
      <FlatButton
        key={1}
        label="Ok"
        primary={true}
        onClick={() => {
          appStore.dispatch<any>(hideSystemAlert());
        }}
      />
    ]);
  }
}

function _handle404(serverErrorCode: number, error?: AxiosError) {
  if (serverErrorCode === 40406) {
    makeSimpleSnackbar('Current title no longer visible, selecting first');
  } else if (serverErrorCode === 404061) {
    _makeAlert('Invalid Action', 'Cannot insert this content, it would be invalid for this document type');
  }
  // if unit activity not found
  else if (serverErrorCode === 40414) {
    makeSimpleSnackbar('Error: Could not find activity in undo history');
  }
  // no idea what going on: go nuclear!
  else if (error) {
    SystemStore.showSystemError(error);
  }
}

function _handle409(serverErrorCode: number, error?: AxiosError) {
  if (serverErrorCode === 40999) {
    _makeAlert(
      'Unable to Change Document',
      'Document is busy processing, or is being modified by another user and cannot be changed right now. Try again in a moment?',
      () => transitionTo('/')
    );
  }

  // e.g. starting workflow when already started by another user
  else if (serverErrorCode === 40918) {
    makeSimpleSnackbar('Warning: Workflow step already completed by another user');
  } else if (error) {
    SystemStore.showSystemError(error);
  }
}

function _handle412(serverErrorCode: number, error?: AxiosError) {
  if (serverErrorCode === 40999) {
    makeSimpleSnackbar('Cannot complete, document is currently locked.');
  } else if (serverErrorCode === 41208) {
    makeSimpleSnackbar(error!.response!.data.errors[0].message);
  } else {
    _makeAlert(
      'Document Locked',
      'Document has been locked by another user. Document will reload as read-only.',
      () => location.reload() // will show readonly doc
    );
  }
}

function _handle418(serverErrorCode: number) {
  if (serverErrorCode === 41800) {
    makeSimpleSnackbar('Current variant view hides commented unit.');
  }
}

export function handle(error?: AxiosError | Error) {
  const err = getErrorDetails(error);

  switch (err.status) {
    case 403:
      _handle403(err.serverErrorCode, error as AxiosError);
      break;

    case 400:
      _handle400(err.serverErrorCode, error as AxiosError);
      break;

    case 404:
      _handle404(err.serverErrorCode, error as AxiosError);
      break;

    case 409:
      _handle409(err.serverErrorCode, error as AxiosError);
      break;

    case 412:
      _handle412(err.serverErrorCode, error as AxiosError);
      break;

    case 418:
      _handle418(err.serverErrorCode);
      break;

    case 0: // sometimes an aborted api isn't caught by SystemStore: ignore such errors here
      break;

    default:
      SystemStore.showSystemError(error);
      break;
  }
}
