import * as React from 'react';
import TagFamily from './facetedTagFamily';
import { ISortedFacetFamily, IComparableSearchTerm } from 'mm-types';

export type Props = {
  families: ISortedFacetFamily[] | null;
  matchedConcepts: IComparableSearchTerm[] | null;
  groupSelectedConceptUids: string[];
  expandFamilies: boolean;
  addTag: (tag: IComparableSearchTerm) => void;
  loading: boolean;
};

export type State = {
  openFamilies: string[];
};

export default class FacetedTagsTree extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const families = this.props.families || [];

    this.state = {
      openFamilies: props.expandFamilies ? families.map((f) => f.uid) : []
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (newProps.expandFamilies !== this.props.expandFamilies) {
      const families = this.props.families || [];
      this.setState({
        openFamilies: newProps.expandFamilies ? families.map((f) => f.uid) : []
      });
    }
  }

  onToggleFamily(uid: string, toggledOpen: boolean) {
    const openFamilies = this.state.openFamilies;

    if (toggledOpen) {
      this.setState({
        openFamilies: this.state.openFamilies.concat(uid)
      });
    } else {
      const filteredFamilies = openFamilies.filter((famUid) => famUid !== uid);
      this.setState({
        openFamilies: filteredFamilies
      });
    }
  }

  render() {
    const families = this.props.families || [];
    const isAllExpanded = this.state.openFamilies.length === families.length;
    const openedUids = this.state.openFamilies;

    return (
      <div className="faceted-tree">
        <div className="facet-heading">
          <h5>All Tags</h5>
          <div
            className={`${isAllExpanded ? 'collapse' : 'expand'}`}
            onClick={(e) => this.setState({ openFamilies: isAllExpanded ? [] : families.map((f) => f.uid) })}
          >
            {isAllExpanded ? <i className="material-icons">unfold_less</i> : <i className="material-icons">unfold_more</i>}
            <span>{isAllExpanded ? 'Collapse All' : 'Expand All'}</span>
          </div>
        </div>

        <div className="tag-families">
          {this.props.families!.map((family, index) => {
            return (
              <TagFamily
                groupSelectedConceptUids={this.props.groupSelectedConceptUids}
                matchedConcepts={this.props.matchedConcepts}
                addTag={(tag: IComparableSearchTerm) => this.props.addTag(tag)}
                key={`fam-${index}`}
                enabled={!this.props.loading}
                family={family}
                onToggled={(uid, open) => this.onToggleFamily(uid, open)}
                expanded={openedUids.indexOf(family.uid) !== -1}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
