import IconButton from 'material-ui/IconButton';
import React from 'react';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ToolbarMenu from '../../ToolbarMenu';
import { MenuEditOnSelectParams } from '../MenuEdit';
import { MenuAction } from './defaultMenuContents';

type JustifyOption = {
  icon: string;
  value: string;
  label: string;
  disabled: boolean;
};

type Props = {
  justifyEnabled: boolean;
  onSelected?: (e: MenuEditOnSelectParams) => void;
  getCurrentTextFormats: () => string[];
  justifyActions: MenuAction[];
};

const JustifyContentItem = ({ justifyEnabled, onSelected, getCurrentTextFormats, justifyActions }: Props) => {
  const applyJustify = (action) => {
    if (action && onSelected) {
      onSelected({ menu: 'edit', action: action });
    }
  };

  const getJustifyOptions = (): JustifyOption[] => {
    return justifyActions.map((action) => {
      return {
        icon: action.icon,
        value: action.action,
        label: action.tooltip,
        disabled: !action.enabled
      };
    });
  };

  const getJustifyIcon = () => {
    let justify = 'JustifyLeft';
    let enabled = false;

    if (EditorStore.getEditor() && EditorStore.getEditor().getActiveEditorFacade()) {
      justify = EditorStore.getEditor().getActiveEditorFacade()!.getAlignment();
    }

    const selectedIcon: MenuAction = justifyActions.find(function (obj) {
      if (obj.action === justify) {
        enabled = obj.enabled;
        return true;
      }

      return false;
    })!;

    return (
      <IconButton disabled={!enabled} iconClassName="material-icons" tooltipPosition="bottom-center" tooltip={selectedIcon.tooltip}>
        {selectedIcon.icon}
      </IconButton>
    );
  };

  return (
    <li className={justifyEnabled ? '' : 'disabled'} style={{ display: 'inline-flex', paddingTop: '8px' }} data-action="justifyContent">
      <ToolbarMenu
        data-action="textAlignment"
        className="alignment"
        onSelect={(action) => applyJustify(action)}
        selected={getCurrentTextFormats()}
        options={getJustifyOptions()}
      >
        {getJustifyIcon()}
      </ToolbarMenu>
    </li>
  );
};

export default JustifyContentItem;
