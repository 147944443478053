import * as React from 'react';
import ToggleSection from '../../../../../misc/ToggleSection';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import { NNCIndexMetadataContainer } from './NNCIndexMetadataContainer';
import { IGenericEditProps } from '../../../../sidetabs/sub/editComponent/components/GenericEditProps';
import { DataAttributesPropsWrapper } from '../../../../sidetabs/sub/editComponent/components/DataAttributesPropsWrapper';

export default (props: IGenericEditProps & { isOn: boolean }) => {
  const [userOpened, setUserOpened] = React.useState(false);

  if (!ProjectStore.getProject() || !props.selectedUnit) {
    return null;
  }
  return (
    <div className="toggle-section-outer selected-unit-props-container">
      <ToggleSection
        title="Index Metadata"
        id="nnc-index-metadata-container-toggle"
        forceClose={!props.isLeaf}
        forceOpen={props.isLeaf || userOpened}
        onToggled={(isOpened) => {
          setUserOpened(isOpened);
        }}
      >
        <NNCIndexMetadataContainer unit={props.selectedUnit} onToggle={props.onToggle} />
        <DataAttributesPropsWrapper {...props} />
      </ToggleSection>
    </div>
  );
};
