import { docUnitProfile as tocable } from '../tocable';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  ...tocable,
  displayName: 'Section',
  getLabel: () => 'SEC',
  editor: {
    formats: {
      ...tocable.editor?.formats,
      subscript: { inline: 'span', classes: 'arc-sub-script', exact: true },
      superscript: { inline: 'span', classes: 'arc-super-script', exact: true }
    },
    forced_root_block: ''
  }
};
