import * as React from 'react';
import PopupContainer from './PopupContainer';
import { ISpecialInterestGroup } from 'mm-types';
import { SvgDropdownIcon } from './icons/SvgDropdownIcon';

export type Props = {
  isReadOnly: boolean;
  assignedTags: ISpecialInterestGroup[];
};

export type State = {
  open: boolean;
};

export default class SpecialInterestGroupTagsDropdown extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    isReadOnly: false,
    assignedTags: []
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  open(e: Element) {
    if (!this.state.open) {
      this.setState({ open: true }, () => {
        (this.refs.unitSpecialInterestGroupTagsPopup as PopupContainer).setTriggerButton(e).open();
      });
    }
  }

  close(e) {
    if (this.state.open) {
      this._handleClose();
    }

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  _handleClose() {
    this.setState({ open: false });
  }

  render() {
    if (this.state.open) {
      const tags = this.props.assignedTags.map((specialInterestGroupTag) => {
        return (
          <div key={specialInterestGroupTag.uid}>
            <div className="interest-tag">{specialInterestGroupTag.name}</div>
          </div>
        );
      });

      return (
        <PopupContainer ref="unitSpecialInterestGroupTagsPopup" className="check-dropdown" onClose={() => this._handleClose()}>
          <div className="check-dropdown-title" onClick={(e) => this.close(e)}>
            <div>Interest Tag</div>
            <SvgDropdownIcon />
          </div>

          <div className="check-dropdown-inner">{tags}</div>
        </PopupContainer>
      );
    } else {
      return false;
    }
  }
}
