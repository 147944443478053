import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import WorkflowStore, { WorkflowStoreEvent } from '../../../flux/settings/WorkflowStore';
import EditorModes from '../../../flux/editor/EditorModes';
import RevisionStore, { RevisionStoreEvent } from '../../../flux/editor/RevisionStore';
import DefaultModeBar from './components/DefaultModeBar';
import DiffModeBar from './components/DiffModeBar';
import { IRevision, IIndex, IEditorStoreEvent, EventStoreEventType } from 'mm-types';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';

export type Props = {
  onClose?: () => void;
  message?: string;
  showClose?: boolean;
  className?: string;
};

export type ModeBarState = {
  message: string;
  showClose: boolean;
  className: string;
};

export type State = {
  modeBar: null | ModeBarState;
  revisions: IRevision[];
};

export default class ModeBarContainer extends React.Component<Props, State> {
  private _editorUnsubscribe: Function;
  private _workflowUnsubscribe: Function;
  private _revisionUnsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this.state = {
      modeBar: null,
      revisions: []
    };
  }

  static defaultProps: Partial<Props> = {
    message: '',
    showClose: false,
    className: ''
  };

  componentDidMount() {
    this._editorUnsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
    this._workflowUnsubscribe = WorkflowStore.listen(this._onWorkflowStoreUpdate, this);
    this._revisionUnsubscribe = RevisionStore.listen(this._onRevisionStoreUpdate, this);
    const revisions = RevisionStore.getRevisions();
    this.setState({
      revisions: revisions ? revisions : []
    });
  }

  componentWillUnmount() {
    this._editorUnsubscribe();
    this._workflowUnsubscribe();
    this._revisionUnsubscribe();
  }

  _onWorkflowStoreUpdate(e: WorkflowStoreEvent) {
    if (e.type === 'workflow' || e.type === 'onWorkflowPublished' || e.type === 'onWhatsNewPublished') {
      this.setState(this._getDefaultModeBarState(e.index));
    }
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'initEditor') {
      this.setState(this._getDefaultModeBarState());
    } else if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;

      const castEventDataTo = castEvent.data?.to;
      const editorModeBanner = castEventDataTo && EditorModes.getProperties(castEventDataTo).getBanner()!;
      if (editorModeBanner) {
        const message = (castEventDataTo === 'EDITING_BLOCKED' ? this.state.modeBar?.message : null) ?? editorModeBanner?.title;
        this.setState({
          modeBar: {
            message: message,
            showClose: editorModeBanner.hasOwnProperty('showClose') ? editorModeBanner.showClose! : true,
            className: editorModeBanner.className!
          }
        });
      } else {
        this.setState(this._getDefaultModeBarState());
      }
    }
  }

  _onRevisionStoreUpdate(e: RevisionStoreEvent) {
    if (e.revisions) {
      this.setState({ revisions: e.revisions });
    }
  }

  // TODO would probably be better if ModeBar became an autonomous self updating component, rather than having this logic here...
  _getDefaultModeBarState(index?: IIndex) {
    const project = ProjectStore.getProject();
    const currentIndex = index || ProjectStore.getIndex()!;
    let modeBarState: Partial<State> = {};

    if (project && (currentIndex.isInterim || currentIndex.isPublished || currentIndex.isLockedForMerge)) {
      if (currentIndex.isInterim) {
        modeBarState = {
          modeBar: {
            message: 'Interim Revision',
            showClose: false,
            className: 'interim'
          }
        };
      } else if (currentIndex.isPublished) {
        modeBarState = {
          modeBar: {
            message: (currentIndex.status === 'INTERIM_PUBLISHED' ? 'Interim ' : '') + 'Revision ' + currentIndex.revisionNumber,
            showClose: false,
            className: ''
          }
        };
      } else if (currentIndex.isLockedForMerge) {
        let message = 'Document locked: Interim Revision(s) are being merged by ' + currentIndex.merger.displayName;
        if (currentIndex.isLockedForMergeByMe) {
          message = 'Document locked as you are merging Interim Revision(s) - Continue Merge?';
        }

        modeBarState = {
          modeBar: {
            message: message,
            showClose: false,
            className: 'merge-locked'
          }
        };
      }
    } else if (
      (['UNLOCKED', 'LOCKED_FOR_WORKFLOW_TO_START', 'LOCKED_WORKFLOW_STAGE_PREVENTS_EDITS'].indexOf(currentIndex?.lockedStatus ?? '') < 0 &&
        currentIndex?.lockedStatus === 'LOCKED_BY_ADMIN' &&
        !ActiveUserStore.isAdmin()) ||
      project?.status !== 'ACTIVE' ||
      ['MASTER_DRAFT', 'INTERIM_DRAFT'].indexOf(currentIndex?.status ?? '') < 0
    ) {
      EditorStore.changeModeStart('EDITING_BLOCKED');
      let message = 'Editing Blocked';
      if (['MASTER_DRAFT', 'INTERIM_DRAFT'].indexOf(currentIndex?.status ?? '') < 0) {
        message = 'Editing blocked - Revision is locked';
      }
      if (project?.status !== 'ACTIVE') {
        message = 'Editing blocked - Project is inactive';
      }
      if (
        ['UNLOCKED', 'LOCKED_FOR_WORKFLOW_TO_START', 'LOCKED_WORKFLOW_STAGE_PREVENTS_EDITS'].indexOf(currentIndex?.lockedStatus ?? '') < 0
      ) {
        message = 'Editing blocked - Project is locked';
      }
      modeBarState = {
        modeBar: {
          message: message,
          showClose: false,
          className: 'editing-blocked red darken-4'
        }
      };
    } else {
      modeBarState.modeBar = null;
    }

    return modeBarState as State;
  }

  _handleCloseEditingModeBar() {
    // Reset selected diff revision on close
    if (EditorStore.isMode('DIFF')) {
      RevisionStore.updateDiffParams(null);
    }

    if (EditorStore.isMode('VARIANT')) {
      ProjectStore.setSelectedVariantUid(null);
    }

    EditorStore.changeModeStart('EDITING');
  }

  _getModeBar() {
    if (EditorStore.isMode('DIFF')) {
      return (
        <DiffModeBar
          onClose={() => this._handleCloseEditingModeBar()}
          onDiff={(params) => this._handleDiff(params)}
          revisions={this.state.revisions}
          defaultDiffRevision={RevisionStore.getDefaultDiffRevision()}
          currentIndex={ProjectStore.getIndex()}
          currentProject={ProjectStore.getProject()!}
        />
      );
    } else if (this.state.modeBar) {
      return (
        <DefaultModeBar
          onClose={() => this._handleCloseEditingModeBar()}
          showClose={this.state.modeBar?.showClose}
          message={this.state.modeBar?.message}
          className={this.state.modeBar?.className}
        />
      );
    }
  }

  _handleDiff(diffParams) {
    if (diffParams) {
      RevisionStore.updateDiffParams(diffParams, () => {
        EditorStore.changeModeStart('DIFF', { silent: false });
      });
    }
  }

  render() {
    const cls =
      'mode-bar-container ' +
      (this.state.modeBar ? ' show ' : '') +
      (this.state.modeBar && this.state.modeBar.className ? this.state.modeBar.className : '');
    return <div className={cls}>{this._getModeBar()}</div>;
  }
}
