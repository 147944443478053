import * as React from 'react';
import { default as PopupMenu, Options, Option } from '../../../../misc/PopupMenu';
import TextField from 'material-ui/TextField';
import FontIcon from 'material-ui/FontIcon';
import FlatButton from 'material-ui/FlatButton';

const BGCOLOR = '#f9f9f9';

export type Props = {
  isReplacing: boolean;
  replaceWord: string;
  options: {
    preserveCase: boolean;
  };
  toggleOption: (opt: {}) => void;
  onReplace: (e: React.KeyboardEvent<{}> | React.MouseEvent<{}>) => void;
  onReplaceAll: (e: React.MouseEvent<{}>) => void;
  onUpdate: (e: React.FormEvent<{}>, type: string) => void;
};

export type State = {};

export default class FindInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  onOpenTocDepthMenu() {
    (this.refs.options as PopupMenu).setTriggerButton(this.refs.menuButton).open();
  }

  toggleOption(opt: {}) {
    this.props.toggleOption(opt);
  }

  render() {
    return (
      <div className="clear">
        <div className="text-with-button">
          <TextField
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.props.onReplace(e);
              }
            }}
            value={this.props.replaceWord ? this.props.replaceWord : ''}
            floatingLabelText="Replace with"
            hintText="replace..."
            id="replace-with-input"
            onChange={(e) => this.props.onUpdate(e, 'replaceWord')}
          />
          <FontIcon
            style={{ fontSize: '0.9em', cursor: 'pointer', marginLeft: '10px' }}
            ref="menuButton"
            className="material-icons"
            onClick={(e) => this.onOpenTocDepthMenu()}
          >
            settings
          </FontIcon>
        </div>
        <PopupMenu ref="options" className="display-options-popup" onSelect={(e) => this.toggleOption(e)}>
          <Options>
            <div className="popup-arrow"></div>
            <div className="popup-menu-title">Replace Options:</div>
            <Option dataKey="preserveCase" hasCheck={this.props.options['preserveCase']} defaultChecked={true}>
              Preserve Case
            </Option>
          </Options>
        </PopupMenu>

        <div className="clear"></div>

        <div className="buttons">
          <FlatButton
            backgroundColor={BGCOLOR}
            disabled={this.props.isReplacing}
            onClick={(e) => this.props.onReplaceAll(e)}
            label="replace all"
          />

          <FlatButton
            backgroundColor={BGCOLOR}
            disabled={this.props.isReplacing}
            onClick={(e) => this.props.onReplace(e)}
            label="replace"
          />
        </div>
      </div>
    );
  }
}
