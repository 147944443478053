import * as Reflux from 'reflux';
import { EventSubscriptionConfig } from './EventClient';
import Store from '../Store';
import { IComment, IIndex, IProject, NotifStep, NotificationReason } from 'mm-types';

export type UserEventStoreEvent = {
  notificationReason?: NotificationReason;
  index: IIndex;
  project: IProject;
  status: string;
  type: UserEventStoreDataType;
  data: CommentData | TocDuplicateData;
};

export enum UserEventStoreDataType {
  COMMENT = 'COMMENT',
  TOC_COPY = 'TOC_COPY'
}

export type CommentData = {
  comment: IComment;
};

export type TocDuplicateData = {
  tocableHeading: string;
  tocableUid: string;
  copiedTocableUid: string;
  error?: { code: number; message: string; friendlyMessage?: string };
  steps: NotifStep[];
};

export class UserEventStore extends Store<{}> {
  constructor() {
    super();
  }

  get subscriptionConfig(): EventSubscriptionConfig {
    return {
      destination: (userUid: string) => `/topic/users/${userUid}`,
      callback: (message) => {
        const parsedMessage = JSON.parse(message.body);
        if (parsedMessage.type === 'COMMENT') {
          parsedMessage.data = { comment: parsedMessage.comment } as CommentData;
        } else {
          parsedMessage.data = {
            tocableHeading: parsedMessage.tocableHeading,
            tocableUid: parsedMessage.tocableUid,
            error: parsedMessage.error,
            steps: parsedMessage.steps
          };
        }
        this.trigger(parsedMessage as UserEventStoreEvent);
      }
    };
  }
}

const singleton = Reflux.initStore<UserEventStore>(UserEventStore);
export default singleton;
