import { DomAssertions } from '../tinyFacade/DomAssertionsUtil';
import { UNIT_ELEMENT_CLASS_NAME } from './const/UnitElementSelectors';
import { isInlineElement } from './unit/inlineElementsLookup';

export function UnitToInlineElementReducer(content: string): string {
  const tmpContainer = document.createElement('div');
  tmpContainer.innerHTML = content;
  let currentNode;
  const finalElements: any[] = [];
  const filteredElements: any[] = [];
  const nodeIterator = document.createNodeIterator(tmpContainer, NodeFilter.SHOW_ALL);

  while ((currentNode = nodeIterator.nextNode())) {
    if (DomAssertions.isTextNode(currentNode)) {
      // do not include textNode which is not editable
      if (
        !currentNode.parentElement ||
        (currentNode.parentElement && !DomAssertions.hasClassName(currentNode.parentElement, UNIT_ELEMENT_CLASS_NAME.NOT_EDITABLE))
      ) {
        filteredElements.push(currentNode);
      }
    } else {
      if (isInlineElement(currentNode as Element)) {
        filteredElements.push(currentNode);
      }
    }
  }

  // do not include textNode which is part of inline style
  if (filteredElements.length) {
    filteredElements.reverse();
    for (let i = 0, max = filteredElements.length; i < max; i += 1) {
      const current = filteredElements[i];
      const next = filteredElements[i + 1];
      if (next && !DomAssertions.isTextNode(next) && (next as Element).contains(current)) {
        continue;
      }
      finalElements.push(current);
    }
  }

  let output = '';
  if (finalElements.length) {
    finalElements.reverse();
    finalElements.forEach((el: Element | Text) => {
      output += DomAssertions.isTextNode(el) ? el.nodeValue : el.outerHTML || '';
    });
  }
  return output.replace(/(\r\n|\n|\r)/gm, ' ');
}
