import * as React from 'react';
import DocUnit, { ActionEvent } from '../docUnit/DocUnit';
import EditorStore from '../../../flux/editor/EditorStore';
import CircularProgress from 'material-ui/CircularProgress';
import { IUnit } from 'mm-types';
import { EditorManager } from 'tinymce';

declare const tinymce: EditorManager;

export type Props = {
  onContentChange: (e: { content: string | undefined }) => void;
  errorInUnit: (units: IUnit[] | undefined) => void;

  reset: () => void;
};

export type State = {
  snackbar: null;
  isLoading: boolean;
  isAnalyzePhase: boolean;
  units: IUnit[];
  editor?: any | null;
};

export default class PowerPasteContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      snackbar: null,
      isLoading: false,
      isAnalyzePhase: true,
      units: [],
      editor: null
    };
  }

  componentDidMount() {
    if (!this.state.editor) {
      this._createEditor();
    }
  }

  componentWillUnmount() {
    if (this.state.editor) {
      this._removeEditor();
    }
  }

  setLoading(isLoading) {
    this.setState({ isLoading: isLoading });
  }

  showInsert() {
    this.setState({ isAnalyzePhase: false });
  }

  disableInsert() {
    this.setState(this.getInitialState(), () => this._createEditor());
  }

  _createEditor() {
    tinymce.init({
      selector: 'div#paste-editor', // change this value according to your HTML
      init_instance_callback: (editor) => {
        this.setState({ editor: editor });
      },
      plugins: 'paste',
      height: '100%',
      paste_preprocess: function (pl, o) {},
      paste_retain_style_properties: '',
      paste_webkit_styles: 'color font-size',
      setup: (editor) => {
        editor.on('NodeChange', (e) => {
          if (this.props.onContentChange) {
            this.props.onContentChange({ content: this.getContent() });
          }
        });

        editor.on('BeforeExecCommand', (e) => {
          if (e.command === 'SelectAll') {
            if (this.props.onContentChange) {
              this.props.onContentChange({ content: this.getContent() });
            }
          }
        });
      },
      paste_remove_styles_if_webkit: true
    } as any);
  }

  _removeEditor() {
    if (this.state.editor && this.state.editor.editorManager) {
      this.state.editor.editorManager.remove();
      this.setState({ editor: null });
    }
  }

  open(triggerRef) {
    this.setState({ isAnalyzePhase: true });

    if (!this.state.editor) {
      this._createEditor();
    }
  }

  close() {
    this.setState({ isAnalyzePhase: true });

    this._removeEditor();
    this.setState({
      units: []
    });
  }

  _createMarkup(unit) {
    return { __html: unit.html };
  }

  getContent() {
    const pasteEditor = tinymce.get('paste-editor');

    if (pasteEditor) {
      return pasteEditor.getContent();
    }
  }

  getUnits() {
    // Filter out units which we were not able to convert
    const filteredUnits = this.state.units.filter(function (unit) {
      return unit.html.indexOf('arc-unknown-paste-content') < 0;
    });

    return filteredUnits;
  }

  setUnits(units) {
    this._removeEditor();
    this.setState({
      units: units,
      isLoading: false
    });
  }

  _insertPasteUnits() {
    EditorStore.createBatchUnits(this.state.units);
    this.close();
  }

  onAction(e: ActionEvent) {
    if (e.action === 'delete-docunit') {
      this.setState(
        {
          units: this.state.units.filter(function (unit) {
            return e.data.unit && (e.data.unit as IUnit).uid ? unit.uid !== (e.data.unit as IUnit).uid : false;
          })
        },
        () => {
          if (this.state.units.length === 0) {
            this.props.reset();
          } else {
            this.props.errorInUnit(this.state.units!);
          }
        }
      );
    }
  }

  render() {
    let loadingSpinner: null | JSX.Element = null,
      editorPart: null | JSX.Element = null,
      reviewPart: null | JSX.Element = null;

    if (this.state.isLoading) {
      loadingSpinner = (
        <div className="loading">
          <CircularProgress mode="indeterminate" />
        </div>
      );
    } else {
      if (this.state.isAnalyzePhase) {
        editorPart = <div id="paste-editor"></div>;
      } else {
        reviewPart = (
          <div className="powerpaste-result-container">
            {this.state.units
              ? this.state.units.map((docUnit) => {
                  return (
                    <DocUnit
                      key={docUnit.uid ? docUnit.uid : 'header'}
                      unit={docUnit}
                      selected={false}
                      ref={'unitComponent_' + docUnit.uid}
                      onAction={(e) => this.onAction(e)}
                      readOnlyOverride={true}
                      permissionsOverride={{
                        isUnitInsertableAfter: false,
                        isUnitEditable: false,
                        isUnitCommentable: false,
                        isUnitDeletable: true
                      }}
                    />
                  );
                })
              : []}
          </div>
        );
      }
    }
    return (
      <div className="powerpaste-content-container">
        {loadingSpinner}
        {editorPart}
        {reviewPart}
      </div>
    );
  }
}
