import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import IconButton from 'material-ui/IconButton';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import { IElementSettings } from 'mm-types';
import useListenToStore from '../../../../../hooks/useListenToStore';
import { TableAlignmentType, CONTENTS } from './TableAlignmentContents';
import TableUtils from '../../../../../../utils/TableUtils';

export type ActionType = {
  action: string;
  tooltip: string;
  icon: string;
  type: string;
  dataAttribute?: string;
  settingId?: string;
};

export type Props = {
  type: TableAlignmentType;
  title: string;
  contents?: ActionType[];
  onChangeCallback?: (text: string) => void;
  dataAttribute?: string;
  settings?: IElementSettings[];
};

export type State = {
  selected: string;
};

export default function TableAlignment({
  type = 'valign',
  title = 'Alignment',
  contents = CONTENTS,
  onChangeCallback,
  dataAttribute,
  settings
}: Props) {
  const [selected, setSelected] = React.useState<string>('');

  useListenToStore({ store: EditorStore, eventListener: tinyUpdateEvent });

  React.useEffect(() => {
    _updateAlignment();
    return () => {};
  }, []);

  React.useEffect(() => {
    const facade = EditorStore.getEditor().getActiveEditorFacade() as any;
    if (facade) {
      facade.execCommand(selected, null);
    }
  }, [selected]);

  function tinyUpdateEvent(e) {
    if (e.type === 'unitDomChange' || e.type === 'nestedUnitFocusChange') {
      _updateAlignment();
    }
  }

  function _updateAlignment() {
    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
    let alignstate = '';

    switch (type) {
      case 'tablealign':
        alignstate = tinyFacade.getAlignStyle(tinyFacade.getSelectedTable());
        if (alignstate) {
          alignstate = 'icon-align-table-' + alignstate;
        } else {
          alignstate = 'icon-align-table-left';
        }
        break;
      case 'valign':
        alignstate = tinyFacade.getCommonVerticalAlignStyles(TableUtils.getSelectedCells());
        if (alignstate) {
          alignstate = 'icon-align-' + alignstate;
        } else {
          alignstate = 'icon-align-middle';
        }
        break;
      case 'rotation':
        alignstate = tinyFacade.getCommonRotation(TableUtils.getSelectedCells());
        if (alignstate !== '') {
          alignstate = 'icon-text-rotation-' + alignstate;
        }
        break;
      case 'halign':
        alignstate =
          contents?.find((item) => item.dataAttribute === dataAttribute && item.type === 'halign')?.icon ?? 'icon-align-table-left';
        break;
    }
    setSelected(alignstate);
  }

  function _handleClick(e) {
    const tgt = $(e.currentTarget),
      action = tgt.data('action');
    if (onChangeCallback) {
      onChangeCallback(tgt.data('attribute-name'));
    }
    setSelected(action);
  }

  return (
    <div className="alignment">
      <div className="manip-group">
        <h6>{title}</h6>
        <ul className="icon-action-buttons">
          {contents!.map(function (item) {
            if (type === item.type) {
              return (
                <li
                  key={item.action}
                  className={`${selected === item.icon ? 'selected' : ''} ${settingEnabled(item.settingId, settings) ? '' : 'disabled'}`}
                  data-action={item.action}
                  data-attribute-name={item.dataAttribute}
                  onClick={(e) => _handleClick(e)}
                >
                  <IconButton
                    iconClassName={'material-icons ' + item.icon}
                    tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                    tooltipPosition="bottom-left"
                    tooltip={item.tooltip}
                    data-qa={item.icon}
                    disabled={!settingEnabled(item.settingId, settings)}
                  >
                    {item.icon.startsWith('icon') ? '' : item.icon}
                  </IconButton>
                </li>
              );
            }
          }, this)}
        </ul>
      </div>
    </div>
  );
}
