import * as React from 'react';
import { useEffect, useState } from 'react';
import ProjectPropsStore, { ProjectPropsStoreEvent } from '../../../flux/projects/ProjectPropsStore';
import ToggleSection from '../../misc/ToggleSection';
import { transitionTo } from '../../../utils/Navigation';
import { IExternalRevisions, IProject, IProjectMembership, IRevision } from 'mm-types';
import ProjectStatusSummary from './ProjectStatusSummary';
import ExternalRevisions from './ExternalRevisions';
import { ProjectTeamDetails } from './GeneralInfo/ProjectTeamDetails';
import RevisionList from './RevisionList';
import useListenToStore from '../../hooks/useListenToStore';

export type Props = {
  project: null | IProject;
  loading: boolean;
  isReadOnly?: boolean;
};

export type State = {
  revisions: null | IExternalRevisions | IRevision[];
  team: {
    [prop: string]: IProjectMembership[];
  } | null;
};

const GeneralInfo = (props: Props) => {
  const [state, setState] = useState<State>({ team: null, revisions: null });
  useListenToStore({ store: ProjectPropsStore, eventListener: onProjectPropsStoreUpdate });

  function onProjectPropsStoreUpdate(event: ProjectPropsStoreEvent) {
    if (event.error) {
      transitionTo('/teamspaces');
    } else {
      setState({ team: event.state.team, revisions: event.state.revisions });
    }
  }

  useEffect(() => {
    getRevisions(props.project);
    return () => {
      props.project && ProjectPropsStore.resetRevisions(props.project);
    };
  }, []);

  useEffect(() => {
    getRevisions(props.project);
  }, [props.project?.uid]);

  const getRevisions = (project: IProject | null) => {
    project && ProjectPropsStore.clearAndGetRevisions(project);
  };

  const navToDocTeam = (e: React.MouseEvent<HTMLElement>) => {
    transitionTo('doc-docteam-settings', {
      projectUid: props.project?.uid,
      indexUid: props.project?.masterIndexUid
    });

    e.preventDefault();
    e.stopPropagation();
  };

  return props.project ? (
    <div className="side-slidein-content-container">
      <div className="side-slidein-content-namevalueslist">
        <ProjectStatusSummary project={props.project} loading={props.loading} />
      </div>

      <div className="toggle-section-outer side-slidein-list-container-outer">
        {!props.project.isExternal && (
          <ToggleSection title="REVISIONS" id="revisions" defaultOpen={true}>
            <div className="side-slidein-list-container">
              <RevisionList
                projectUid={props.project.uid}
                revisions={state.revisions as IRevision[] | null}
                canOpen={props.project.currentUserPermissions?.canRead}
              />
            </div>
          </ToggleSection>
        )}

        {props.project.isExternal && (
          <ToggleSection title="REVISIONS" id="external-revisions" defaultOpen={true}>
            <div className="side-slidein-list-container">
              <ExternalRevisions
                projectUid={props.project.uid}
                canDownload={props.project.currentUserPermissions.canRead}
                revisions={state.revisions as IExternalRevisions}
                project={props.project}
              />
            </div>
          </ToggleSection>
        )}

        {!props.isReadOnly && (
          <ToggleSection
            title="DOC TEAM"
            id="doc-team"
            defaultOpen={false}
            onInlineMenuClick={(e) => navToDocTeam(e)}
            inlineMenu="add_circle"
            enabled={props.project.currentUserPermissions.canEditSettings}
          >
            <div className="side-slidein-list-container">
              <ProjectTeamDetails team={state.team} projectUid={props.project.uid} />
            </div>
          </ToggleSection>
        )}
      </div>
    </div>
  ) : null;
};

export default GeneralInfo;
