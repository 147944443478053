import * as React from 'react';
import * as _ from 'lodash';
import ProjectStore from '../../../flux/editor/ProjectStore';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import EditorStore from '../../../flux/editor/EditorStore';
import ShareUsageUpdateStrategy, { ShareUsageUpdateStrategyTypes } from './ShareUsageUpdateStrategy';
import { ISharedIndex } from 'mm-types';

const _insertTypes = [
  { type: 'after', title: 'Insert after selected' },
  { type: 'replace', title: 'Replace selected' }
];
const _ordinalLevelStrategies = {
  CHILD: { title: 'Child' },
  SIBLING: { title: 'Sibling' },
  chapter: { title: 'Chapter' },
  section: { title: 'Section' },
  level1: { title: 'Header level 1' },
  level2: { title: 'Header level 2' },
  level3: { title: 'Header level 3' },
  level4: { title: 'Header level 4' },
  level5: { title: 'Header level 5' },
  level6: { title: 'Header level 6' }
};

export type Props = {
  onCloseRequest: () => void;
  sharedIndex: ISharedIndex;
};

export type State = {
  shareRelationship: ShareUsageUpdateStrategyTypes;
  insertType: string;
  ordinalLevelStrategy: string;
};

export default class SharedContentInsertConfirm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shareRelationship: 'AUTOMATIC',
      insertType: _insertTypes[0].type,
      ordinalLevelStrategy: _.keys(_ordinalLevelStrategies)[0]
    };
  }

  _handleRelationshipChange(shareRelationship: ShareUsageUpdateStrategyTypes) {
    if (this.state.shareRelationship === 'NONE' && this.state.shareRelationship !== shareRelationship && this.state.insertType === 'none') {
      this.setState({ insertType: _insertTypes[0].type });
    }

    this.setState({ shareRelationship: shareRelationship });
  }

  _handleInsertTypeChange(insertType: string) {
    this.setState({ insertType: insertType });
  }

  _handleInsertOrdTypeChange(type: string) {
    this.setState({ ordinalLevelStrategy: type });
  }

  _handleAction(action: 'insert' | 'cancel') {
    if (action === 'insert') {
      EditorStore.acceptSharedContentInsert(
        this.props.sharedIndex.uid,
        this.state.shareRelationship,
        this.state.insertType,
        this.state.ordinalLevelStrategy
      );
    }

    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  }

  render() {
    const isAggregateProjectType = ProjectStore.getProject()?.definitionName.toLowerCase() === 'aggregate';

    const strategyMenuItems: JSX.Element[] = [];
    for (const strategyType in ShareUsageUpdateStrategy.props) {
      strategyMenuItems.push(
        <MenuItem
          key={strategyType}
          value={strategyType}
          primaryText={ShareUsageUpdateStrategy.props[strategyType].title}
          disabled={
            (('NONE' as ShareUsageUpdateStrategyTypes) === strategyType && !this.props.sharedIndex.allowDerivatives) ||
            (isAggregateProjectType && ('AUTOMATIC' as ShareUsageUpdateStrategyTypes) !== strategyType)
          }
        />
      );
    }

    const ordStratMenuItems: JSX.Element[] = [];
    _.keys(_ordinalLevelStrategies).forEach((ordStrat) => {
      ordStratMenuItems.push(<MenuItem key={ordStrat} value={ordStrat} primaryText={_ordinalLevelStrategies[ordStrat].title} />);
    });

    const insertTypeMenuItems: JSX.Element[] = [];
    for (const i in _insertTypes) {
      const insertType = _insertTypes[i];
      insertTypeMenuItems.push(<MenuItem key={insertType.type} value={insertType.type} primaryText={insertType.title} />);
    }

    insertTypeMenuItems.push(
      <MenuItem key={'none'} value={'none'} primaryText={'Reference selected'} disabled={this.state.shareRelationship !== 'NONE'} />
    );

    return (
      <div className="inner-modal editor-sharedcontent-confirm">
        <div>
          <h1>You are inserting shared content</h1>
          <h3>Select the type of relation with the source content:</h3>

          <div className="origin-relationship-options">
            <SelectField
              id="origin-relationship-1-dropdown"
              labelStyle={{ color: 'white', WebkitTextFillColor: 'white' }}
              value={this.state.shareRelationship}
              onChange={(e, index, value) => {
                this._handleRelationshipChange(value);
              }}
            >
              {strategyMenuItems}
            </SelectField>
          </div>

          <h3>Select how to apply:</h3>
          <div className="origin-relationship-options">
            <SelectField
              id="origin-relationship-2-dropdown"
              labelStyle={{ color: 'white', WebkitTextFillColor: 'white' }}
              value={this.state.insertType}
              onChange={(e, index, value) => {
                this._handleInsertTypeChange(value);
              }}
            >
              {insertTypeMenuItems}
            </SelectField>
          </div>

          <h3>Insert at level:</h3>
          <div className="origin-relationship-options">
            <SelectField
              labelStyle={{ color: 'white', WebkitTextFillColor: 'white' }}
              id="origin-relationship-2-dropdown"
              value={this.state.ordinalLevelStrategy}
              onChange={(e, index, value) => {
                this._handleInsertOrdTypeChange(value);
              }}
            >
              {ordStratMenuItems}
            </SelectField>
          </div>
          <h3>
            <span>Note: </span>Notifications will be sent for all content updates.
          </h3>
          <div className="inner-modal-buttons">
            <FlatButton
              label="Cancel"
              labelStyle={{ color: 'white' }}
              onClick={() => {
                this._handleAction('cancel');
              }}
            />
            <FlatButton
              label="Insert"
              labelStyle={{ color: 'white' }}
              onClick={() => {
                this._handleAction('insert');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
