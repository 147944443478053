import React from 'react';
import { FlatButton } from 'material-ui';

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  container: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffffd4',
    textAlign: 'center',
    zIndex: 1
  },
  iconError: { fontSize: '2.5em', color: 'red' },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px'
  },
  subLineStyles: {
    marginTop: '0.6rem',
    fontSize: '1rem',
    color: '#757575',
    width: '70%',
    display: 'inline-block'
  },
  message: { fontStyle: 'italic', marginTop: '15px' },
  description: { margin: '15px 0' }
};

const RequestError = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <div style={{ ...defaultStyles.container, ...style }}>
      <div className="graphic">
        <i className="material-icons icon-warning" style={defaultStyles.iconError}></i>
      </div>
      <div style={defaultStyles.messageContainer}>
        <div style={{ ...defaultStyles.subLineStyles, ...defaultStyles.message }}>Could not retrieve workflow tasks</div>
        <div style={{ ...defaultStyles.subLineStyles, ...defaultStyles.description }}>Try refreshing your page or try again later</div>
        <FlatButton
          primary
          onClick={() => {
            location.reload();
          }}
        >
          Refresh page
        </FlatButton>
      </div>
    </div>
  );
};

export default RequestError;
