import * as React from 'react';
import ValidatableTextInput from '../../../../general/ValidatableTextInput';
import { FlatButton } from 'material-ui';

interface Props {
  value: string;
  onFormChange(e: React.ChangeEvent<HTMLTextAreaElement>): any;
  onFormSubmit(el: Element | null): any;
  onFormCancel(el: Element | null): any;
}

export default class DescriptionForm extends React.Component<Props> {
  private divRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.divRef = React.createRef<HTMLDivElement>();
  }

  onSubmit() {
    this.props.onFormSubmit(this.divRef ? this.divRef.current : null);
  }

  onCancel(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    this.props.onFormCancel(this.divRef ? this.divRef.current : null);
  }

  render() {
    return (
      <div className="description-form" ref={this.divRef}>
        <div className="doc-settings-textfield extend">
          <ValidatableTextInput
            value={this.props.value}
            floatingLabelText="Description"
            onChange={this.props.onFormChange}
            autoFocus={true}
            nullable={true}
            maxChars={160}
            multiLine={true}
          />
        </div>
        <div className="item-buttons">
          <FlatButton data-qa="submit" label="Save" secondary={true} onClick={this.onSubmit} />
          <FlatButton data-qa="cancel" label="Cancel" secondary={true} onClick={this.onCancel} />
        </div>
      </div>
    );
  }
}
