import { IProjectSettingsState } from './index';

export const initialProjectSettings: IProjectSettingsState = {
  selected: {
    fetching: false
  },
  singleVolume: {
    locked: false,
    enabled: true
  },
  multiVolumeOrdinalStrategy: {
    locked: false,
    value: 'NUM',
    availableValues: ['NUM', 'NONE']
  },
  coverPage: {
    locked: false,
    enabled: false
  },
  approvalCertificate: {
    locked: false,
    enabled: false
  },
  scannedApprovalCertificate: {
    locked: false,
    enabled: false
  },
  toc: {
    locked: false,
    enabled: false
  },
  tocIncludeFrontmatter: {
    locked: false,
    enabled: false
  },
  listOfRevisions: {
    locked: false,
    enabled: false
  },
  listOfRevHighlights: {
    locked: false,
    enabled: false
  },
  revisionHighlightRequired: {
    locked: false,
    enabled: false
  },
  listOfTables: {
    locked: false,
    enabled: false
  },
  listOfGraphics: {
    locked: false,
    enabled: false
  },
  listOfVideos: {
    locked: false,
    enabled: false
  },
  listOfChapters: {
    locked: false,
    enabled: false
  },
  listOfComplianceEntries: {
    locked: false,
    enabled: false
  },
  listOfEffectiveSections: {
    locked: false,
    enabled: false
  },
  displayWatermark: {
    locked: false,
    enabled: false
  },
  enforceUnitTagging: {
    locked: false,
    enabled: false
  },
  riskAssessmentRequiredForTasks: {
    locked: false,
    enabled: false
  },
  artifactsIncludeRevisionBars: {
    locked: false,
    enabled: false
  },
  lorFormat: {
    locked: false,
    value: 'RD,DESC,NUM,PD',
    availableValues: ['RD,DESC,NUM,PD', 'RD,NUM,PD', 'RD,DESC,NUM', 'RD,NUM']
  },
  tocDepth: {
    locked: false,
    value: 'level1',
    availableValues: ['volume', 'chapter', 'section', 'level1', 'level2', 'level3', 'level4', 'level5', 'level6']
  },
  chapterOrdinalsZeroBased: {
    locked: false,
    enabled: false
  },
  frontmatterOrdinal: {
    locked: false,
    value: '0'
  },
  appendixOrdinalFormat: {
    locked: false,
    value: 'A',
    availableValues: ['A', 'A1']
  },
  elementOrdinalFormat: {
    locked: false,
    value: 'CHAP',
    availableValues: ['CHAP', 'SEC']
  },
  headerMargin: {
    locked: false,
    value: '1'
  },
  contentMargin: {
    locked: false,
    value: 0
  },
  watermarkText: {
    locked: false,
    value: ''
  },
  footerText: {
    locked: false,
    value: ''
  },
  footerOwnerOverride: {
    locked: false,
    value: ''
  },
  chapterToc: {
    locked: false,
    enabled: false
  },
  chapterTocDepth: {
    locked: false,
    value: 'level3',
    availableValues: ['volume', 'chapter', 'section', 'level1', 'level2', 'level3', 'level4', 'level5', 'level6']
  },
  oemModel: {
    locked: false,
    value: ''
  },
  headerLogoMedia: {
    locked: false,
    mediaUid: ''
  },
  coverPageMedia: {
    locked: false,
    mediaUid: ''
  },
  approvalCertificateHeaderMedia: {
    locked: false,
    mediaUid: ''
  },
  artifactsIncludeComplianceTags: {
    locked: false,
    enabled: false
  },
  regApprovalSkip: {
    locked: false,
    enabled: true
  },
  revisionHighlightsEmail: {
    locked: false,
    enabled: false
  },
  revisionHighlightsEmailGroup: {
    locked: false,
    values: []
  },
  searchFacets: {
    locked: false,
    values: []
  },
  specialInterestGroupsEmail: {
    locked: false,
    enabled: false
  },
  includedInWhatsNew: {
    locked: false,
    enabled: false
  },
  ignoreDeletionRevbars: {
    locked: false,
    enabled: false
  },
  metadataAllowVariantFamily: true,
  metadataAllowTagFamilies: true,
  allowAttachments: true,
  allowSharedContent: true,
  allowApprovalInfo: true,
  allowInterim: true,
  aircraftAllocationTableFrontmatter: { locked: false, enabled: false },
  melOrCdl: {
    locked: false,
    value: 'MEL',
    availableValues: ['MEL', 'CDL']
  },
  qrhText: {
    locked: false,
    value: ''
  },
  qrhColor: {
    locked: false,
    value: 'Blue',
    availableValues: ['Blue', 'Red', 'Amber', 'Green']
  },
  showAlphaIndex: {
    locked: false,
    enabled: false
  }
};
