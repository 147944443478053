import { ISettings } from 'mm-types';
import { AppThunk } from '../../../appStore';
import { settingsClient } from '../../../clients/settings';

export namespace ActionName {
  export const SET_PROJECT_SETTINGS = 'SetProjectSettings';
  export const RESET_PROJECT_SETTINGS = 'ResetProjectSettings';
  export const FETCHING_PROJECT_SETTINGS = 'FetchingProjectSettings';
}

export namespace Action {
  export const setProjectSettingsAction = (settings: Partial<ISettings>, projectUid: string, masterIndexUid: string) => {
    return <const>{
      type: ActionName.SET_PROJECT_SETTINGS,
      settings,
      projectUid,
      masterIndexUid
    };
  };

  export const resetProjectSettingsAction = () => {
    return <const>{
      type: ActionName.RESET_PROJECT_SETTINGS
    };
  };

  export const fetchingProjectSettingsAction = (projectUid: string, masterIndexUid: string) => {
    return <const>{
      type: ActionName.FETCHING_PROJECT_SETTINGS,
      projectUid,
      masterIndexUid
    };
  };

  export function fetchProjectSettings(projectUid: string, masterIndexUid: string): AppThunk {
    return async (dispatch) => {
      dispatch(Action.fetchingProjectSettingsAction(projectUid, masterIndexUid));
      try {
        const result = await settingsClient.get(projectUid, masterIndexUid);
        dispatch(Action.setProjectSettingsAction(result, projectUid, masterIndexUid));
      } catch (error) {
        dispatch(Action.resetProjectSettingsAction());
      }
    };
  }
}
