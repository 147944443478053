import { LinearProgress, List, ListItem, Subheader } from 'material-ui';
import React, { useState } from 'react';
import { MaterialIcon } from '../../MaterialIcon';
import { deleteGebWorkspaces } from './deleteGebWorkspaces';
import { deleteGebPersonalProjects } from './deleteGebPersonalProjects';
import { deleteGebWorkflows } from './deleteGebWorkflows';
import { deleteGebDocRefs } from './deleteGebDocRefs';

interface Props {
  onShowMessage(text: string): void;
}

const styles = {
  noProgress: { height: 6, width: '100%' },
  list: { maxWidth: '30%', minWidth: 400 }
};

const DevScripts = ({ onShowMessage }: Props) => {
  const [completed, setCompleted] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);

  function fetchCompleted() {
    setFetching(false);
    setCompleted(0);
  }

  async function requestDeleteGebWorkspaces() {
    if (confirm('Are you sure?')) {
      await deleteGebWorkspaces(setFetching, fetchCompleted, setCompleted, onShowMessage);
    }
  }

  async function requestDeleteGebPersonalProjects() {
    if (confirm('Are you sure?')) {
      await deleteGebPersonalProjects(setFetching, fetchCompleted, setCompleted, onShowMessage);
    }
  }

  async function requestDeleteGebWorkflows() {
    if (confirm('Are you sure?')) {
      await deleteGebWorkflows(setFetching, fetchCompleted, setCompleted, onShowMessage);
    }
  }
  async function requestDeleteGebDocRefs() {
    if (confirm('Are you sure?')) {
      await deleteGebDocRefs(setFetching, fetchCompleted, setCompleted, onShowMessage);
    }
  }

  return (
    <div className="padding-l">
      <h5 className="margin-none font-size-16 font-bold">Scripts</h5>
      <div className="padding-vertical-s">
        {fetching ? <LinearProgress mode="determinate" value={completed} style={{ height: 6 }} /> : <div style={styles.noProgress} />}
      </div>
      <List style={styles.list}>
        <Subheader>GEB</Subheader>
        <ListItem
          disabled={fetching}
          primaryText="(GEB) Delete workspaces"
          secondaryText="Deletes workspaces (MM_SPA*, abcd*) and their projects"
          onClick={requestDeleteGebWorkspaces}
          rightIcon={<MaterialIcon iconName="play_circle" outline={true} />}
        />
        <ListItem
          disabled={fetching}
          primaryText="(GEB) Delete projects"
          secondaryText="Deletes projects (MM_SPA*, abcd*) inside personal workspace"
          onClick={requestDeleteGebPersonalProjects}
          rightIcon={<MaterialIcon iconName="play_circle" outline={true} />}
        />
        <ListItem
          disabled={fetching}
          primaryText="(GEB) Delete workflows"
          secondaryText="Deletes workflows (MM_SPA*, abcd*)"
          onClick={requestDeleteGebWorkflows}
          rightIcon={<MaterialIcon iconName="play_circle" outline={true} />}
        />
        <ListItem
          disabled={fetching}
          primaryText="(GEB) Delete Doc references"
          secondaryText="Deletes Doc references (MM_SPA*, abcd*)"
          onClick={requestDeleteGebDocRefs}
          rightIcon={<MaterialIcon iconName="play_circle" outline={true} />}
        />
      </List>
    </div>
  );
};

export default DevScripts;
