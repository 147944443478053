import { Dom } from '../../utils/tinyFacade/DomUtil';
import EditorInstanceManager from '../../utils/tinyFacade/EditorInstanceManager';
import { InsertInfo } from '../../EditorPage';
import { InsertAction } from '../../menus/insert/content/ContentMenuContainer';
import { IMedia, IUnit } from 'mm-types';
import EditorStore from '../../../../flux/editor/EditorStore';
import { AIRBUS_EXT_OBJ_ELEMENTS, ElementTypes } from '../../utils/units/ElementTypes';
import { Media } from '../MediaLibContent';
import { MediaTypes, MediaTypesContants } from '../types';
import { UnitTypes } from '../../utils/units/UnitTypes';
import GraphicScaler from '../../../../utils/GraphicScaler';
import MediaStore from '../../../../flux/editor/MediaStore';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

export namespace MediaInsertUtils {
  export const ELEMENT_TYPES_TO_OPEN_MEDIA_LIB: Array<UnitTypes | ElementTypes> = [
    'graphic',
    'video',
    'sheet',
    'graphref',
    'symbol',
    'auralwarning',
    'launcher'
  ];

  export function currentElementIllustrations(editor: EditorInstanceManager): Element | null {
    return Dom.closestElement(editor.getActiveEditorInstance()?.selection.getNode(), '[data-element-definition-id="Illustration"');
  }

  export function currentInsertPointIsGraphic(insertPoint?: HTMLElement | null): boolean {
    return !!insertPoint?.classList.contains('arc-graphic');
  }

  export function isInsertingElement(editor: EditorInstanceManager, insertInfo: InsertInfo | null): boolean {
    return !!insertInfo && editor.isFocused();
  }

  export function illustrationsPerElementReached(editor: EditorInstanceManager): boolean {
    const illustrationsIfAny = currentElementIllustrations(editor);
    return !!illustrationsIfAny && illustrationsIfAny.querySelectorAll('[data-element-family="Graphic"]').length >= 3;
  }

  /**
   * This is to check element insert for symbol, video, image
   */
  export function canInsertMediaElement(selectedMedia: IMedia, insertPosition?: InsertAction) {
    if (insertPosition) {
      const symbolNotInsertable =
        selectedMedia.type === 'symbol' && EditorStore.getInsertableElementRule('Symbol', insertPosition).disabled;
      const graphicNotInsertable = getGraphicIsInsertable(selectedMedia, insertPosition);
      const videoNotInsertable = selectedMedia.type === 'video' && EditorStore.getInsertableElementRule('Video', insertPosition).disabled;
      return !(symbolNotInsertable || graphicNotInsertable || videoNotInsertable);
    } else {
      return false;
    }
  }

  /**
   * Need to check all image types if is insertable, if one is we can insert the image otherwise throw error
   */
  function getGraphicIsInsertable(selectedMedia: IMedia, insertPosition: InsertAction): boolean {
    return (
      (selectedMedia.type === 'image' &&
        EditorStore.getInsertableElementRule('Graphic', insertPosition).disabled &&
        EditorStore.getInsertableElementRule('Sheet', insertPosition).disabled &&
        EditorStore.getInsertableElementRule('GraphRef', insertPosition).disabled) ??
      false
    );
  }

  export function getCurrentInsertPointType(target: HTMLElement): ElementTypes {
    return target.parentElement?.classList.contains('arc-symbol')
      ? 'symbol'
      : targetOrTargetParentContainsExtObjClass(target)
      ? 'extobj'
      : ('graphref' as ElementTypes);
  }

  function targetOrTargetParentContainsExtObjClass(target: HTMLElement): boolean {
    return target.parentElement?.classList.contains('arc-media-file') || target.classList.contains('arc-media-file');
  }

  export function insertPointTarget(target: HTMLElement): HTMLElement {
    // retarget as click can be on arc-media-file or child i.e arc-graphic
    if (target.classList.contains('arc-media-file') && target.firstElementChild) {
      return target.firstElementChild as HTMLElement;
    }
    return target;
  }

  export function insertPointIsExternalObject(type) {
    return AIRBUS_EXT_OBJ_ELEMENTS.indexOf(type) !== -1;
  }

  export function isSymbol(media: Media): boolean {
    return media.type === MediaTypesContants.symbol;
  }

  export function isAudio(media: Media): boolean {
    return media.type === MediaTypesContants.audio;
  }

  export function isVideo(media: Media): boolean {
    return media.type === MediaTypesContants.video;
  }

  export function isImage(media: Media): boolean {
    return media.type === MediaTypesContants.image;
  }

  export function isExtObjInsert(type?: string | null): boolean {
    return !!type && ['launcher', 'auralwarning'].indexOf(type) !== -1;
  }

  export function isExtObjSameTypeAsCurrentExtObj(type: MediaTypes, insertPoint?: HTMLElement | null): boolean {
    if (insertPoint) {
      switch (type) {
        case MediaTypesContants.image:
          return insertPoint.tagName === 'IMG';
        case MediaTypesContants.video:
          return insertPoint.tagName === 'VIDEO';
        case MediaTypesContants.audio:
          return insertPoint.tagName === 'Audio';
        case MediaTypesContants.other:
          return insertPoint.tagName === 'DIV';
        default:
          return false;
      }
    }
    return false;
  }

  export function getExtObjMediaTypeTemplate(media: IMedia): Element | null {
    const template = document.createElement('template');
    switch (media.type) {
      case MediaTypesContants.image:
        template.innerHTML = getExtObjImgTemplate(media);
        break;
      case MediaTypesContants.video:
        template.innerHTML = getExtObjVidTemplate(media);
        break;
      case MediaTypesContants.audio:
        template.innerHTML = getExtObjAudioTemplate(media);
        break;
      default:
        template.innerHTML = getExtObjOtherTemplate(media);
    }
    return template.content.firstElementChild;
  }

  function getExtObjImgTemplate(media: IMedia) {
    return `<img class="arc-graphic" src="${media.location}"/>`;
  }

  function getExtObjVidTemplate(media: IMedia) {
    return `<video preload="none" onplay="this.play()" controls="true" controlslist="nodownload" muted="true">
              <source src="${media.location}" type="${media.mimeType}"/>
            </video>`;
  }

  function getExtObjAudioTemplate(media: IMedia) {
    return `<span class="aero-icon material-icons audio-placeholder">micro</span>
            <audio preload="none" onplay="this.play()" controls="true" controlslist="nodownload" muted="true" src="${media.location}"/>`;
  }

  function getExtObjOtherTemplate(media: IMedia) {
    return `<div class="arc-attachment" data-type="${media?.mimeType}"/>`;
  }

  export async function insertMediaTemplates(mediaToInsertAsTemplateString: Promise<string | undefined>[], insertInfo: InsertInfo | null) {
    await Promise.all(mediaToInsertAsTemplateString).then((media: (string | undefined)[]) => {
      if (media.length) {
        EditorStore.getEditor()
          .getActiveEditorFacade()
          ?.execCommand('mceInsertContent', media.join(''), insertInfo?.insertPoint, insertInfo?.insertPosition);
      }
      MediaStore.trigger({ type: 'refreshMediaAttachmentIcon' });
    });
  }

  export function insertMedia(media, insertInfo: InsertInfo | null) {
    const mediaToInsert: Promise<string | undefined>[] = [];

    media.forEach((m) => {
      if (m.type === 'video') {
        if (insertInfo?.inline) {
          mediaToInsert.push(
            Promise.resolve(
              ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
                .getElementProfileByDefinitionId('video')
                ?.templateHtml?.replace('%MEDIA_UID%', m.uid)
                .replace('%MEDIA_LOCATION%', m.location)
            )
          );
        } else {
          mediaToInsert.push(
            Promise.resolve(
              ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
                .getUnitProfileByDefinitionId('video')
                ?.template?.replace('%MEDIA_UID%', m.uid)
                .replace('%MEDIA_LOCATION%', m.location)
            )
          );
        }
      } else if (insertInfo?.type) {
        mediaToInsert.push(GraphicScaler.getScaledHTML(m, insertInfo!.inline, insertInfo?.type));
      } else {
        throw new Error(`No type specified for media with uid: ${m.uid} and name: ${m.name}`);
      }
    });
    insertMediaTemplates(mediaToInsert, insertInfo).catch((e) => console.warn(`mediaInsertUtils - insertMedia() error: ${e}`));
  }

  export function insertExtObjectMedia(media: IMedia, insertInfo: InsertInfo | null) {
    const isElementInsert = !!insertInfo?.inline;
    if (insertInfo?.type) {
      const extObjMediaTypeTemplate = MediaInsertUtils.getExtObjMediaTypeTemplate(media);

      const extObjParentTemplate = document.createElement('template');
      let innerHTML;
      // Get element or unit template
      if (isElementInsert) {
        innerHTML = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
          .getElementProfileByDefinitionId(insertInfo.type)
          ?.templateHtml?.replace('%MEDIA_UID%', media.uid)
          .replace('%MEDIA_LOCATION%', media.location);
      } else {
        innerHTML = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
          .getUnitProfileByDefinitionId(insertInfo.type)
          ?.template?.replace('%MEDIA_UID%', media.uid)
          .replace('%MEDIA_LOCATION%', media.location);
      }
      if (innerHTML) {
        extObjParentTemplate.innerHTML = innerHTML;
      }
      extObjParentTemplate.content.querySelector('div.arc-attachment')?.replaceWith(extObjMediaTypeTemplate as Node);

      const extObjTemplateToString = extObjParentTemplate.content.firstElementChild?.outerHTML;
      if (isElementInsert) {
        insertMediaTemplates([Promise.resolve(extObjTemplateToString)], insertInfo).catch((e) =>
          console.warn(`mediaInsertUtil.ts - insertExtObjectMedia() - inserting ext object element error: ${e}`)
        );
      } else {
        EditorStore.createBatchUnits([{ type: insertInfo.type, html: extObjTemplateToString } as IUnit]).catch((e) =>
          console.warn(`mediaInsertUtil.ts - insertExtObjectMedia() - inserting ext object unit error: ${e}`)
        );
      }
    } else {
      throw new Error('MEDIA INSERT: Insert Info has no type');
    }
  }

  export function replaceExtObject(media: IMedia, insertInfo: InsertInfo | null) {
    if (isExtObjSameTypeAsCurrentExtObj(media.type, insertInfo?.insertPoint)) {
      removeNotSupportedOverlay(insertInfo);
      replaceMediaLocationAndUid(media, insertInfo);
    } else {
      replaceMediaHtml(media, insertInfo);
    }
  }

  function replaceMediaHtml(media: IMedia, insertInfo: InsertInfo | null) {
    const mediaTemplate = getExtObjMediaTypeTemplate(media);
    insertInfo?.insertPoint?.replaceWith(mediaTemplate as Node);
    replaceExtObParentMediaUid(media);
  }

  function replaceMediaLocationAndUid(media: IMedia, insertInfo: InsertInfo | null) {
    if (insertInfo?.insertPoint?.getAttribute('src')) {
      insertInfo?.insertPoint?.setAttribute('src', media.location);
    } else if (insertInfo?.insertPoint?.firstElementChild?.getAttribute('src')) {
      insertInfo?.insertPoint?.firstElementChild?.setAttribute('src', media.location);
      if (insertInfo?.insertPoint?.firstElementChild?.getAttribute('type')) {
        insertInfo?.insertPoint?.firstElementChild?.setAttribute('type', media.mimeType);
      }
    }
    replaceExtObParentMediaUid(media);
  }

  const removeNotSupportedOverlay = (insertInfo: InsertInfo | null) => {
    if (insertInfo?.insertPoint?.classList.contains('not-supported-format')) {
      insertInfo?.insertPoint?.classList.remove('not-supported-format');
      if (insertInfo?.insertPoint?.nextElementSibling?.classList.contains('not-supported-overlay-wrapper')) {
        insertInfo?.insertPoint?.nextElementSibling.remove();
      }
    }
  };

  function replaceExtObParentMediaUid(media: IMedia) {
    const currentSelection = EditorStore.getEditor().getActiveEditorInstance()?.selection.getNode();
    currentSelection?.closest('.arc-generic-media')?.setAttribute('data-media-uid', media.uid);
  }

  export function getCurrentMediaDataUid(target: HTMLElement): string | undefined {
    return target.getAttribute('data-media-uid') ?? target.closest('[data-media-uid]')?.getAttribute('data-media-uid') ?? undefined;
  }

  export const checkVideoFormat = (target: HTMLDivElement) => {
    const videoNodeList: NodeListOf<HTMLVideoElement> | null = target?.querySelectorAll('.arc-media-file > video') ?? null;
    const videoElements: HTMLVideoElement[] = videoNodeList ? Array.from(videoNodeList) : [];
    videoElements.map((video) => {
      const type = video.querySelector('source')?.getAttribute('type');
      if (type && video.canPlayType(type) == '' && !video.classList.contains('not-supported-format')) {
        video.classList.add('not-supported-format');
        video.insertAdjacentHTML(
          'afterend',
          '<div class="not-supported-overlay-wrapper"><div class="not-supported-overlayText"><p class="not-supported-placeholder">Video playback is not supported.<br/>Download the file and view it in a video player of your choice.</p></div></div>'
        );
      }
    });
  };
}
