import * as React from 'react';
import * as _ from 'lodash';
import * as ReactDOM from 'react-dom';
import SmartContentLibraryStore, { SmartContentLibraryStoreEvent } from '../../../flux/editor/SmartContentLibraryStore';
import SmartContentStore from '../../../flux/editor/SmartContentStore';
import TagStore, { TagStoreEvent } from '../../../flux/editor/TagStore';
import SecondaryDocumentStore from '../../../flux/editor/SecondaryDocumentStore';
import { PostUnitRenderActionType } from '../../../flux/editor/PostUnitRenderActions';
import EditorModes from '../../../flux/editor/EditorModes';
import EditorStore from '../../../flux/editor/EditorStore';
import Scroller from '../../../utils/Scroller';
import ContentTypes from './SharedContentLibTypes';
import SearchSharedLibContainer, { SearchParams } from './search/SearchSharedLibContainer';
import SharedContentItem from './SharedContentItem';
import RegulatoryItem from './RegulatoryItem';
import CircularProgress from 'material-ui/CircularProgress';
import { SmartContentStoreEvent } from '../../../flux/editor/SmartContentStore';
import { ISharedIndex, IProject, ITag, ISharedIndexUsages, IUnit, SecondaryDocumentModes, IEditorStoreEvent } from 'mm-types';
import { IRouteParams } from 'mm-types';
import { match } from 'react-router';
import * as H from 'history';
import { withRouter } from 'react-router';
import ProjectStore from '../../../flux/editor/ProjectStore';

const SCROLLREQUEST_THRESHOLD = 700; // distance from bottom in px when we request more

let _postRenderItemSelectionUid: string | null = null; // if set this will be selected and scrolled to post render
let _lastSearchResults: (ISharedIndex | IProject)[] = [];
let _lastSearchType = '';
let _lastContentTagsAll: ITag[] = [];
let _lastSearchParams: Partial<SearchParams> | null | undefined = null;
let _lastExpanded: Expanded | null | undefined = {
  loading: false,
  uid: null,
  content: null,
  usages: null
};

export type Expanded = {
  loading: boolean;
  uid: null | string;
  content: null | IUnit[];
  usages: null | ISharedIndexUsages[];
};

export type Props = {
  showPreviousSearch: boolean;
  match: match<IRouteParams>;
  history: H.History;
  location: H.Location<string>;
};

export type State = {
  contentTags: ITag[];
  smartContentSearchResults: (ISharedIndex | IProject)[];
  showingRegulatory?: boolean;
  loading: boolean;
  expanded: Expanded | null | undefined;
};

class SmartContentLib extends React.Component<Props, State> {
  private smartcontentLibUnsub: Function;
  private smartcontentUnsub: Function;
  private tagUnsub: Function;
  private editorUnsub: Function;

  constructor(props: Props) {
    super(props);

    this.smartcontentLibUnsub = SmartContentLibraryStore.listen(this._onSmartContentLibStoreUpdate, this);
    this.smartcontentUnsub = SmartContentStore.listen(this._onSmartContentStoreUpdate, this);
    this.tagUnsub = TagStore.listen(this._onTagsStoreUpdate, this);
    this.editorUnsub = EditorStore.listen(this._onEditStoreUpdate, this);

    this.state = {
      smartContentSearchResults: [],
      loading: false,
      contentTags: [],
      expanded: {
        loading: false,
        uid: null,
        content: null,
        usages: null
      }
    };
  }

  componentWillUnmount() {
    this.smartcontentLibUnsub();
    this.smartcontentUnsub();
    this.tagUnsub();
    this.editorUnsub();
  }

  UNSAFE_componentWillMount() {
    this._handleScroll = _.throttle(this._handleScroll, 500);
  }

  componentDidMount() {
    // prevent initial load if previous search is meant to be shown
    if (this.props.showPreviousSearch) {
      this.setState(
        {
          smartContentSearchResults: _lastSearchResults,
          showingRegulatory: _lastSearchType === 'retrieveRegulatoryDocs',
          contentTags: _lastContentTagsAll,
          expanded: _lastExpanded
        },
        () => {
          this._selectSmartContentOnRender();
        }
      );
    } else {
      this._retrieveSharedContent({ contentType: SmartContentLibraryStore.state.contentType });
      this._retrieveContentTags();
    }
  }

  _retrieveSharedContent(searchParams?: Partial<SearchParams> | null) {
    _lastSearchParams = searchParams;

    this.setState({ loading: true }, () => {
      if (searchParams && searchParams.contentType === 'regulation') {
        SmartContentLibraryStore.retrieveRegulatoryDocs();
      } else {
        if (SmartContentLibraryStore.state.isProjectTypeToggled) {
          searchParams = { ...searchParams, projectDefinitionName: ProjectStore.getProjectDefinitionType() };
        }
        SmartContentLibraryStore.searchSharedContents(searchParams);
      }
    });
  }

  _handleScroll() {
    const smartContentListElement = ReactDOM.findDOMNode(this.refs.smartContentItemList) as Element;
    if (!!smartContentListElement) {
      const listInnerHeight = parseInt(window.getComputedStyle(smartContentListElement, undefined).getPropertyValue('height'));
      if (smartContentListElement.scrollTop + listInnerHeight >= smartContentListElement.scrollHeight - SCROLLREQUEST_THRESHOLD) {
        if (!this.state.showingRegulatory) {
          SmartContentLibraryStore.searchSharedContents(_lastSearchParams, true);
        }
      }
    }
  }

  _retrieveContentTags() {
    TagStore.retrieveContentTags();
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<'acceptSharedContentInsert'>) {
    if (e.type === 'acceptSharedContentInsert') {
      if (!this.state.showingRegulatory) {
        if (this.state.smartContentSearchResults.find((sharedIndex) => sharedIndex.uid === e.data!.sharedIndexUid)) {
          this._retrieveSharedContent(_lastSearchParams);
        }
      }
    }
  }

  _onSmartContentLibStoreUpdate(event: SmartContentLibraryStoreEvent) {
    if (event.type === 'shareSearch' || event.type === 'retrieveRegulatoryDocs') {
      this.setState(
        {
          loading: false,
          smartContentSearchResults: event.type === 'shareSearch' ? event.state!.searchSharedContents : event.state!.regulatoryDocs,
          showingRegulatory: event.type === 'retrieveRegulatoryDocs'
        },
        () => {
          if (this.state.showingRegulatory) {
            // no need for this bool now that we have a dedicated "mode" for same: refactor out
            if (!EditorStore.isMode('REGULATIONSELECTION')) {
              EditorStore.changeModeStart('REGULATIONSELECTION');
            }
          } else {
            if (!EditorStore.isMode('SMARTCONTENTLIB')) {
              EditorStore.changeModeStart('SMARTCONTENTLIB');
            }
          }

          // select a share on render
          if (EditorStore.isMode('SMARTCONTENTLIB')) {
            const smartContentLibModeParams = EditorModes.getProperties(EditorStore.getMode()).getParams();
            if (smartContentLibModeParams.selectItemUid) {
              _postRenderItemSelectionUid = smartContentLibModeParams.selectItemUid;
            }
          }

          this._selectSmartContentOnRender();

          _lastSearchResults = this.state.smartContentSearchResults;
          _lastSearchType = event.type;
        }
      );
    } else if (event.type === 'selectSmartContentLibItem') {
      _postRenderItemSelectionUid = event.itemUid!;
      this._selectSmartContentOnRender();
    }
  }

  _onSmartContentStoreUpdate(event: SmartContentStoreEvent) {
    if (event.type === 'sharedUsageDeleted' || event.type === 'sharedUsageUpdated') {
      if (!this.state.showingRegulatory) {
        if (this.state.smartContentSearchResults.find((sharedIndex) => sharedIndex.uid === event.sharedContent!.uid)) {
          this._retrieveSharedContent(_lastSearchParams);
        }
      }
    }
  }

  _onTagsStoreUpdate(e: TagStoreEvent) {
    if (e.type === 'contentTagsRetrieved') {
      this.setState({ contentTags: e.state.contentTags }, () => {
        _lastContentTagsAll = e.state.contentTags;
      });
    }
  }

  _insertShare(sharedIndex: ISharedIndex) {
    EditorStore.requestSharedContentInsert(sharedIndex);
  }

  async _onExpand(sharedIndex: ISharedIndex, usages, onComplete?: () => void, forceOpen?: boolean) {
    if (!forceOpen && this.state.expanded && this.state.expanded.uid === sharedIndex.uid) {
      await this._saveExpanded({ uid: null, usages: null });
      if (onComplete) {
        onComplete();
      }
    } else {
      await this._saveExpanded({ uid: sharedIndex.uid, loading: true });
      const result = await SmartContentLibraryStore.retrieveSharedIndexUnits(sharedIndex.originProjectUid, sharedIndex.uid);
      this.setState(
        {
          expanded: _.extend(this.state.expanded, { content: result, usages: usages, loading: false })
        },
        onComplete
      );
    }
  }

  async _onLookupUsages(sharedIndex: ISharedIndex) {
    if (this.state.expanded && this.state.expanded.uid === sharedIndex.uid) {
      if (this.state.expanded.usages) {
        this._saveExpanded({ usages: null });
      } else {
        const result = await SmartContentLibraryStore.retrieveSharedIndexUsages(sharedIndex.uid);
        this._saveExpanded({ usages: result });
      }
    } else {
      const result = await SmartContentLibraryStore.retrieveSharedIndexUsages(sharedIndex.uid);
      this._onExpand(sharedIndex, result);
    }
  }

  _selectSmartContentOnRender() {
    // select a smart item on render (currently only works with shares)
    if (_postRenderItemSelectionUid) {
      const sharedItem: ISharedIndex = this.state.smartContentSearchResults.find(
        (s) => s.uid === _postRenderItemSelectionUid
      ) as ISharedIndex;
      if (sharedItem) {
        this._onExpand(
          sharedItem,
          null,
          () => {
            const listEl = ReactDOM.findDOMNode(this.refs.smartContentItemList) as Element;
            Scroller.scrollToElement(listEl, listEl.querySelectorAll('#scl_item_' + sharedItem.uid)[0], 1).catch((e) => console.warn(e));
          },
          true
        );
      }

      _postRenderItemSelectionUid = null; // select and scroll is one time only: ensure it doesn't select again on subsequent mount
    }
  }

  _getContentType() {
    return _lastSearchType === 'retrieveRegulatoryDocs' ? ContentTypes.props.TYPE_REGULATIONS.key : ContentTypes.props.TYPE_SHARED.key;
  }

  // Helper to set expanded info and save it for later
  _saveExpanded(obj: Partial<Expanded>) {
    return new Promise<void>((resolve) => {
      this.setState({ expanded: Object.assign({}, this.state.expanded, obj) }, () => {
        _lastExpanded = this.state.expanded;
        resolve();
      });
    });
  }

  _close() {
    _lastSearchResults = [];
    _lastSearchType = '';
    _lastContentTagsAll = [];
    _lastExpanded = null;
    EditorStore.changeModeStart('EDITING');
  }

  // transitions to the current url but with new GET params
  transitionToParams(getParams: any) {
    const urlParams = getParams ? '?' + $.param(getParams) : '';
    EditorStore.blurEditor(
      () => {
        this.props.history.push(location.pathname + urlParams, {});
      },
      {},
      true
    );
  }

  _openDocumentInline(smartContentUid: string, doc: { projectUid: any; startUnitUid: any }) {
    _postRenderItemSelectionUid = smartContentUid;

    const result = SecondaryDocumentStore.retrieveProject(doc.projectUid);

    result.then(
      (state) => {
        EditorStore.getUnitTocableUnitUid(doc.startUnitUid, {
          projectUid: state.project!.uid,
          indexUid: state.project!.masterIndexUid
        }).then((result) => {
          this.transitionToParams({
            target: 'secondarydoc',
            action: 'OPEN_IN_SECONDARY_DOC' as PostUnitRenderActionType,
            mode: 'SHARE_SELECT_MODE' as SecondaryDocumentModes,
            projectUid: state.project!.uid,
            indexUid: state.project!.masterIndexUid,
            startUnitUid: result.targetVolumeUid,
            unitUid: doc.startUnitUid,
            isStartUnitVolume: result.unit.type === 'volume'
          });
        });
      },
      () => {
        // TODO temp for now until introduce new flow for such a scenario... (which will not open toc)
        alert('You do not have permission to view this document');
      }
    );
  }

  _isReadonly() {
    return EditorStore.isReadOnly();
  }

  _openRegulationDoc(doc) {
    SmartContentStore.retrieveDefaultRegModel({
      projectUid: doc.projectUid,
      indexUid: doc.lastPublishedIndexUid,
      unitUid: doc.startUnitUid
    }).then(() => {
      EditorStore.getUnitTocableUnitUid(doc.startUnitUid, {
        projectUid: doc.projectUid,
        indexUid: doc.lastPublishedIndexUid
      }).then((result) => {
        this.transitionToParams({
          target: 'secondarydoc',
          action: 'OPEN_IN_SECONDARY_DOC' as PostUnitRenderActionType,
          mode: 'REG_SELECT_MODE' as SecondaryDocumentModes,
          projectUid: doc.projectUid,
          indexUid: doc.lastPublishedIndexUid,
          startUnitUid: result.targetVolumeUid,
          unitUid: doc.startUnitUid,
          isStartUnitVolume: result.unit.type === 'volume'
        });
      });
    });
  }

  _handleItemError(r) {
    EditorStore.triggerShowEditorError({ xhr: r });
  }

  render() {
    return (
      <div className="editing-stage-page editing-stage-page-secondary sharedcontent-container">
        <div className="editing-header">
          <div className="editing-header-inner editing-header-inner--shared-search">
            <SearchSharedLibContainer
              onSearch={(searchParams) => this._retrieveSharedContent(searchParams)}
              onClose={() => this._close()}
              contentType={this._getContentType()}
              contentTags={this.state.contentTags}
              showPreviousSearch={this.props.showPreviousSearch}
              isLoading={this.state.loading}
              isReadOnly={this._isReadonly()}
            />
          </div>
        </div>

        {this.state.loading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" size={17.85} />
          </div>
        ) : undefined}

        {!this.state.loading &&
        this.state.showingRegulatory &&
        this.state.smartContentSearchResults &&
        this.state.smartContentSearchResults.length === 0 ? (
          <div className="empty-smart-content">No Regulations documents were found</div>
        ) : undefined}

        {!this.state.loading &&
        !this.state.showingRegulatory &&
        this.state.smartContentSearchResults &&
        this.state.smartContentSearchResults.length === 0 ? (
          <div className="empty-smart-content">No shared content was found</div>
        ) : undefined}

        {!this.state.loading ? (
          <ul className="sharedcontent-container-inner" onScroll={(e) => this._handleScroll()} ref="smartContentItemList">
            {!this.state.showingRegulatory && this.state.smartContentSearchResults
              ? this.state.smartContentSearchResults.map((share: ISharedIndex) => {
                  return (
                    <li key={share.uid} className="sc-item-wrapper">
                      <SharedContentItem
                        item={share}
                        expanded={this.state.expanded}
                        onExpand={(sharedIndex, usages, onComplete, forceOpen) => {
                          this._onExpand(sharedIndex, usages, onComplete, forceOpen);
                        }}
                        onLookupUsages={(sharedIndex) => {
                          this._onLookupUsages(sharedIndex);
                        }}
                        insertShare={(index) => this._insertShare(index)}
                        contentType={this._getContentType()}
                        openDocumentInline={(smartContentUid, doc) => this._openDocumentInline(smartContentUid, doc)}
                      />
                    </li>
                  );
                })
              : undefined}

            {this.state.showingRegulatory && this.state.smartContentSearchResults
              ? this.state.smartContentSearchResults.map((regDoc) => {
                  if (!regDoc.lastPublishedIndexUid || regDoc.lastPublishedIndexUid === '') {
                    return null;
                  }

                  return (
                    <li key={regDoc.uid} className="sc-item-wrapper">
                      <RegulatoryItem
                        item={regDoc as ISharedIndex}
                        openRegDocumentInline={(doc) => this._openRegulationDoc(doc)}
                        onError={(err) => this._handleItemError(err)}
                      />
                    </li>
                  );
                })
              : undefined}
          </ul>
        ) : undefined}
        <div className="col s12 editing-footer"></div>
      </div>
    );
  }
}

export default withRouter(SmartContentLib);
