import * as Reflux from 'reflux';
import * as client from '../../clients/change-tasks';
import Store from '../Store';
import { LookupTypes, ILookup } from 'mm-types';

export type State = {
  lookupCachedData: Partial<{ [key in LookupTypes]: ILookup[] | null }>;
};

export class LookupStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      lookupCachedData: {}
    };
  }

  getInitialState() {
    return this.state;
  }

  getLookupData(type: LookupTypes) {
    return this.state.lookupCachedData[type];
  }

  // Event Handlers
  async retrieve(type: LookupTypes) {
    // note: no need to trigger for now, as this stores a cache of all lookup types, so use onSuccess callback for specific type
    if (this.state.lookupCachedData[type]) {
      return this.state.lookupCachedData[type]!;
    } else {
      try {
        const lookups = await client.getAllSafetyLookups(type);
        this.state.lookupCachedData[type] = lookups;
        return this.state.lookupCachedData[type]!;
      } catch (err) {
        this.state.lookupCachedData[type] = null;
        return null;
      }
    }
  }
}

const singleton = Reflux.initStore<LookupStore>(LookupStore);
export default singleton;
