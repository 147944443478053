import * as React from 'react';
import * as ReactDOM from 'react-dom';

export type Props = {
  onConfirm?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  confirmBtnText: string;
  cancelBtnText: string;
  text: string | string[] | JSX.Element[];
  cb?: (() => void) | null;
};

export type State = {};

export default class DeleteConfirmationOverlay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    ((ReactDOM.findDOMNode(this.refs.self) as Element).parentNode! as HTMLElement).classList.add('dimmed-parent');
  }

  render() {
    return (
      <div ref="self" className="dimmed-body">
        <div className="dimmed-body-center">
          <div className="dimmed-body-center-title" data-qa={'choice-dialog-title'}>
            {this.props.text}
          </div>
          <div className="dimmed-body-center-btns">
            <a data-qa={'choice-dialog-cancel-btn'} onClick={(e) => this.props.onCancel && this.props.onCancel(e)}>
              {this.props.cancelBtnText}
            </a>
            <a data-qa={'choice-dialog-confirm-btn'} onClick={(e) => this.props.onConfirm && this.props.onConfirm(e)}>
              {this.props.confirmBtnText}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
