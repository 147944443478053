import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import * as client from '../../../../../clients/activities';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ActivityPopup, { ActivityPopupType } from './ActivityPopup';
import { CircularProgress } from 'material-ui';
import { IActivity, IActivityDescription, IUnit } from 'mm-types';
import Log from '../../../../../utils/Log';
import { handle as handleError } from '../../../utils/EditorErrorHandler';
import TocStore from '../../../../../flux/editor/TocStore';

const SCROLLREQUEST_THRESHOLD = 700; // higher the number the sooner the next request will be made on scroll down

export type Props = {
  onNextPageScroll: () => void;
  onListRefreshRequest: () => void;
  loading: boolean;
  loadingScroll: boolean;
  activityEntries: IActivity[] | null;
  ref: string;
};

export type State = {
  selected: null | string;
  opened: null | string;
  isMostRecentUnitSelected: boolean;
};

export default class ActivityList extends React.Component<Props, State> {
  private activityDetails: React.RefObject<ActivityPopupType>;
  private activityListEntries: React.RefObject<HTMLUListElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
      opened: null,
      isMostRecentUnitSelected: false
    };
    this.activityDetails = React.createRef();
    this.activityListEntries = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this._handleScroll = _.throttle(this._handleScroll, 500);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      !this.props.loading &&
      prevProps.loading !== this.props.loading &&
      this.state.selected &&
      this[`activity_${this.state.selected}`]?.current
    ) {
      setTimeout(() => {
        this[`activity_${this.state.selected}`]?.current.scrollIntoView(true);
      }, 500);
    }
  }

  _handleScroll() {
    if (this.activityDetails.current && this.activityListEntries.current && this.activityDetails.current?.getPopup()?.isOpen()) {
      this.activityDetails.current.getPopup()?.close();
    }
    if (this.activityListEntries.current) {
      const activitiesListElement = ReactDOM.findDOMNode(this.activityListEntries.current) as Element;
      if (!!activitiesListElement) {
        const listInnerHeight = parseInt(window.getComputedStyle(activitiesListElement, undefined).getPropertyValue('height'));
        if (activitiesListElement.scrollTop + listInnerHeight >= activitiesListElement.scrollHeight - SCROLLREQUEST_THRESHOLD) {
          this.props.onNextPageScroll();
        }
      }
    }
  }

  _handleSelectActivity(e: React.MouseEvent<HTMLElement>, activityEntry: IActivity, childrenItems?: IActivity[]) {
    const selectedState: Partial<State> = {
      selected: activityEntry.uid!,
      isMostRecentUnitSelected: false
    };

    EditorStore.getEditor().blur();

    if (childrenItems && childrenItems.length) {
      selectedState.opened = this.state.opened === activityEntry.uid ? null : activityEntry.uid!;
    }

    if (activityEntry.unitUid) {
      // some activities are groups
      const docUnit = EditorStore.getDocUnitModel(activityEntry.unitUid);
      selectedState.isMostRecentUnitSelected = docUnit ? docUnit.unit.versionUid === activityEntry.unitVersionUid : false;
    }

    this.setState(selectedState as State, () => {
      this.activityDetails.current?.getPopup()?.close();
      if (activityEntry.unitUid) {
        // Check if Activity Item's unit is inside the currently selected TOC Item
        // If not then change tocableUnitUid to the one from the Activity Item (start with sectionUid)
        const selectedTocItemUid = TocStore.getSelectedItem()?.uid;
        const isWithinToc =
          !!selectedTocItemUid &&
          (selectedTocItemUid === activityEntry.sectionUid ||
            selectedTocItemUid === activityEntry.chapterUid ||
            selectedTocItemUid === activityEntry.volumeUid);
        const tocableUnitUid = isWithinToc
          ? selectedTocItemUid
          : activityEntry.sectionUid ?? activityEntry.chapterUid ?? activityEntry.volumeUid;

        EditorStore.openDocumentWithUnit(
          {
            uid: activityEntry.unitUid,
            type: activityEntry.entityType,
            sectionUid: activityEntry.sectionUid,
            chapterUid: activityEntry.chapterUid,
            volumeUid: activityEntry.volumeUid
          } as IUnit,
          null,
          EditorStore.getDocParams()?.projectUid!,
          EditorStore.getDocParams()?.indexUid!,
          tocableUnitUid,
          true
        );
      }
    });
  }

  async _handleOpen(currentEntry: IActivity, prevActivity?: IActivity, parentEntry?: IActivity) {
    if (currentEntry.entityType && this.state.selected === currentEntry.uid) {
      if (!currentEntry.disablePopup) {
        if (
          this.activityDetails.current &&
          this[`activity_${currentEntry.uid}`]?.current &&
          !this.activityDetails.current.getPopup()?.isOpen()
        ) {
          const docParams = EditorStore.getDocParams();

          try {
            const response = await Promise.all([
              client.getOne(docParams.projectUid, docParams.indexUid, currentEntry.uid),
              prevActivity?.uid ? client.getOne(docParams.projectUid, docParams.indexUid, prevActivity?.uid) : undefined
            ]);
            this.activityDetails.current.open(this[`activity_${currentEntry.uid}`].current);

            this.activityDetails.current.showDetails(response[0], response[1], parentEntry);
          } catch (e) {
            Log.info('Editor Page - open activity error');
            handleError(e);
          }
        }
      }
    }
  }

  getName(child: IActivity) {
    const name = child.description
      ? typeof child.description === 'string'
        ? child.description
        : (child.description as IActivityDescription).name
      : '';
    if (child.display === 'Variant Tag Added') {
      return (
        <div className="activity-item-info">
          <div>Variant Label:</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    }

    if (child.display === 'Variant Tag Removed') {
      return (
        <div className="activity-item-info">
          <div>Variant Label:</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    } else if (child.operation === 'unit_concept_tagged' || child.operation === 'unit_concept_untagged') {
      return (
        <div className="activity-item-info">
          <div>Tag Label</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    }
  }

  activityItemHeight(height: number) {
    return { height: height + 'px' };
  }

  render() {
    return (
      <div>
        {!this.props.loading && this.props.activityEntries && this.props.activityEntries.length > 0 ? (
          <ul className="activity-list responsive-list" onScroll={() => this._handleScroll()} ref={this.activityListEntries}>
            {this.props.activityEntries?.map((entry, index) => {
              const displayName = entry.user ? entry.user.displayName : '';
              const shouldExpandHeight = entry.description
                ? entry.description &&
                  ((entry.description as IActivityDescription).text || (entry.description as IActivityDescription).name)
                : null;
              this[`activity_${entry.uid}`] = React.createRef<HTMLLIElement>();
              return (
                <div key={entry.uid}>
                  <li
                    ref={this[`activity_${entry.uid}`]}
                    onClick={(e) => this._handleSelectActivity(e, entry, entry.childrenActivities)}
                    style={shouldExpandHeight ? this.activityItemHeight(75) : this.activityItemHeight(60)}
                    onDoubleClick={() => {
                      this._handleOpen(entry, this.props.activityEntries?.[index - 1]);
                    }}
                    className={`${this.state.selected === entry.uid ? 'selected' : ''} ${
                      entry.childrenActivities && entry.childrenActivities.length ? 'parent-item' : 'non-parent-item'
                    } ${
                      entry.undoRedoAction || entry.entityOrdinal || (entry.description && (entry.description as IActivityDescription).text)
                        ? 'large'
                        : ' '
                    }`}
                  >
                    <div className="icon-activity-outer">
                      <div className={`icon-activity icon-activity-${entry.icon}`} />
                    </div>

                    <div className="activity-items-container">
                      <div className="activity-item">
                        <div title={entry.display} className="activity-item-name">
                          {entry.display}
                        </div>
                        <div className="activity-item-time">{entry.formatted}</div>
                      </div>

                      <div className="activity-item">
                        <div title={entry.title!} className="activity-item-title">
                          {entry.title}
                        </div>
                        <div className="activity-item-user" title={displayName}>
                          {displayName}
                        </div>
                        <div className="activity-item-description">
                          {entry.undoRedoAction ? '(' + entry.undoRedoAction + ') ' : ''}
                          {entry.entityOrdinal ? entry.entityOrdinal + ' ' : ''}{' '}
                          {entry.description && entry.description.hasOwnProperty('text')
                            ? (entry.description as IActivityDescription).text
                            : entry.description && entry.description.hasOwnProperty('name')
                            ? null
                            : entry.description}
                        </div>
                        {entry.revisionNumber && <div className="activity-item-revision"> {entry.revisionNumber}</div>}
                        {entry.description && (entry.description as IActivityDescription).name ? (
                          <div className="activity-item-label"> {this.getName(entry)}</div>
                        ) : null}
                      </div>
                    </div>
                  </li>
                  {entry.childrenActivities && entry.childrenActivities.length ? (
                    <div className={`children-items ${this.state.opened === entry.uid ? 'open' : ''}`}>
                      {entry.childrenActivities.map((child, index) => {
                        const childDisplayName = child.user ? child.user.displayName : '';
                        const childActionName = child.operation === 'promotion' ? 'Promotion To ' + child.entityType : child.display;
                        const shouldExpandChildHeight = child.description
                          ? child.description &&
                            ((child.description as IActivityDescription).text || (child.description as IActivityDescription).name)
                          : null;
                        this[`activity_${child.uid}`] = React.createRef<HTMLLIElement>();
                        return (
                          <li
                            ref={this[`activity_${child.uid}`]}
                            key={child.uid}
                            style={shouldExpandChildHeight ? this.activityItemHeight(75) : this.activityItemHeight(60)}
                            className={`child-item ${this.state.selected === child.uid ? 'selected' : ''} ${
                              child.undoRedoAction || child.entityOrdinal || child.description ? 'large' : ' '
                            }`}
                            onClick={(e) => this._handleSelectActivity(e, child)}
                            onDoubleClick={() => {
                              this._handleOpen(child, entry.childrenActivities[index - 1], entry);
                            }}
                          >
                            <div className="icon-activity-outer">
                              <div className={`icon-activity icon-activity-${child.operation === 'promotion' ? 'promote' : child.icon}`} />
                            </div>

                            <div className="activity-items-container">
                              <div className="activity-item">
                                <div className="activity-item-name" title={childActionName}>
                                  {childActionName}
                                </div>
                                <div className="activity-item-time">
                                  {child.formatted === undefined ? entry.formatted : child.formatted}
                                </div>
                              </div>

                              <div className="activity-item">
                                <div title={child.title!} className="activity-item-title">
                                  {child.title}
                                </div>
                                <div className="activity-item-user" title={childDisplayName}>
                                  {childDisplayName}
                                </div>
                                <div className="activity-item-description">
                                  {child.undoRedoAction ? '(' + child.undoRedoAction + ') ' : ''}
                                  {child.entityOrdinal ? child.entityOrdinal + ' ' : ''}{' '}
                                  {child.description && child.description.hasOwnProperty('text')
                                    ? (child.description as IActivityDescription).text
                                    : child.description && child.description.hasOwnProperty('name')
                                    ? null
                                    : child.description}
                                </div>
                                {child.revisionNumber && <div className="activity-item-revision"> {child.revisionNumber}</div>}
                                {child.description && (child.description as IActivityDescription).name ? (
                                  <div className="activity-item-label"> {this.getName(child)}</div>
                                ) : null}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  ) : undefined}
                </div>
              );
            })}
            {this.props.loadingScroll ? (
              <li>
                <div className="loading">
                  <CircularProgress mode="indeterminate" size={17.85} />
                </div>
              </li>
            ) : undefined}
          </ul>
        ) : undefined}
        <ActivityPopup ref={this.activityDetails} isMostRecentUnitSelected={this.state.isMostRecentUnitSelected} />
      </div>
    );
  }
}
