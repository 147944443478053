import * as React from 'react';
import { IComment, IUnit, IUser } from 'mm-types';

export type FilterType = {
  commentsUnreadFilter: 'unread' | 'read' | 'all';
  range: string | null;
  status: string | null;
  visibility: string | null;
  selectedAuthors: IUser[];
  filterSinceDateTime: number | null;
  date: Date | null;
  time: Date | null;
};

export type CommentsState = {
  newCommentUnit: IUnit | null;
  comments: null | IComment[];
  selectedComment: null | IComment;
  selectedCommentUid: null | string;
  commentsFilterMenuSelected: boolean;
  filterActive: boolean;
  highlightedComments: IComment[];
  followingSelectedComment: null | IComment;
  loading: boolean;
  snackbar: { message: string; open: boolean };
  authors: IUser[];
  filter: FilterType;
};

type CommentsActions =
  | { type: 'setNewCommentUnit'; payload: IUnit | null }
  | { type: 'setComments'; payload: null | IComment[] }
  | { type: 'setCommentReadUnread'; payload: null | IComment }
  | { type: 'markAllToRead'; payload: null }
  | { type: 'setSelectedComment'; payload: null | IComment }
  | { type: 'setSelectedCommentUid'; payload: null | string }
  | { type: 'setCommentsFilterMenuSelected'; payload: boolean }
  | { type: 'setFilterActive'; payload: boolean }
  | { type: 'setHighlightedComments'; payload: IComment[] }
  | { type: 'setFollowingSelectedComment'; payload: null | IComment }
  | { type: 'setLoading'; payload: boolean }
  | { type: 'setSnackbar'; payload: { message: string; open: boolean } }
  | { type: 'setAuthors'; payload: IUser[] }
  | { type: 'setFilter'; payload: FilterType }
  | { type: 'resetState'; payload: CommentsState };

export const INITIAL_COMMENT_STATE: CommentsState = {
  comments: null,
  newCommentUnit: null,
  selectedComment: null,
  commentsFilterMenuSelected: false,
  filterActive: false,
  selectedCommentUid: null,
  highlightedComments: [],
  followingSelectedComment: null,
  loading: true,
  snackbar: { message: '', open: false },
  authors: [],
  filter: {
    commentsUnreadFilter: 'all',
    range: 'tocSelected',
    status: 'allcomments',
    visibility: 'ALL',
    selectedAuthors: [],
    filterSinceDateTime: null,
    date: null,
    time: null
  }
};

export const CommentsReducer: React.Reducer<CommentsState, CommentsActions> = (state, action) => {
  switch (action.type) {
    case 'setNewCommentUnit':
      return { ...state, newCommentUnit: action.payload };
    case 'setComments':
      return { ...state, comments: action.payload };
    case 'setCommentReadUnread':
      state.comments!.forEach((comment) => {
        comment.uid === action.payload!.uid
          ? (comment.unread = !comment.unread)
          : comment.replies.forEach((reply) => {
              if (reply.uid === action.payload!.uid) {
                reply.unread = !reply.unread;
              }
            });
      });
      return { ...state, comments: state.comments };
    case 'markAllToRead':
      state.comments!.forEach((comment) => {
        comment.unread = false;
        comment.replies!.forEach((reply) => {
          reply.unread = false;
        });
      });
      return { ...state, comments: state.comments };
    case 'setSelectedComment':
      return { ...state, selectedComment: action.payload };
    case 'setSelectedCommentUid':
      return { ...state, selectedCommentUid: action.payload };
    case 'setCommentsFilterMenuSelected':
      return { ...state, commentsFilterMenuSelected: action.payload };
    case 'setFilterActive':
      return { ...state, filterActive: action.payload };
    case 'setHighlightedComments':
      return { ...state, highlightedComments: action.payload };
    case 'setFollowingSelectedComment':
      return { ...state, followingSelectedComment: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setSnackbar':
      return { ...state, snackbar: action.payload };
    case 'setAuthors':
      return { ...state, authors: action.payload };
    case 'setFilter':
      return { ...state, filter: action.payload };
    case 'resetState':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
