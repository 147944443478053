import { AlertPayload } from './reducers';

export const SHOW_SYSTEM_ALERT = 'ShowSystemAlert';
export const HIDE_SYSTEM_ALERT = 'HideSystemAlert';

export const ShowSystemAlertAction = (payload: AlertPayload) => {
  return <const>{
    type: SHOW_SYSTEM_ALERT,
    payload
  };
};

export const HideSystemAlertAction = () => {
  return <const>{
    type: HIDE_SYSTEM_ALERT,
    payload: null
  };
};
