/* FRAMEWORK */
import * as React from 'react';
/* STORES */
import EditorStore, { INestedUnitFocusChangeEvent } from '../../flux/editor/EditorStore';
/* HOC */
import withStore, { connect } from './withStore';
/* TYPES */
import { EventStoreEventType, IDocUnitProfile, IEditorStoreEvent, IUnit } from 'mm-types';
import { getSelectedClosestTocable } from './common';
import { dtdAllowedElements, dtdAllowedUnits } from '../editor/menus/insert/utils/dtdValidationUtils';
import { InsertAction } from '../editor/menus/insert/content/ContentMenuContainer';
import { EditorEventType } from '../../flux/editor/EditorStoreAddons/EditorEventType';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';

export type WithSelectedUnitState = {
  selectedUnit: IUnit | null;
  editingDUProfile?: IDocUnitProfile | null;
  editingNestedChange?: INestedUnitFocusChangeEvent | null;
  selectedUnitClosestTocable: IUnit | null;
  isEditingTextSelected: boolean;
  dtdValidatedChildUnits: null | string[];
  dtdValidatedElements: null | string[];
  currentInsertPosition: InsertAction;
  currentEditorStoreAction?: EditorEventType;
};

export type WithSelectedUnitProps = {
  isSelected: boolean;
  isSelectedNotEditing: boolean;
  isActivelyEditing: boolean;
  selectedUnitClosestTocable: IUnit | null;
};
/**
 * Fetches selectedUnit editingDUProfile and editingNestedChange from EditorStore on mounting.
 * Subscribes to changes in those properties from the EditorStore, and passed those down to Wrapped, unmutated.
 * Calculates isActivelyEditing by checking if incoming updates have a truthy editingDUProfile.
 * Passes isActivelyEditing to Wrapped along with rest of incoming props.
 * @param {React.ComponentClass} Wrapped
 */
const selectedUnitWrapper = (Wrapped: React.ComponentClass<WithSelectedUnitProps>) =>
  class WithSelectedUnit extends React.Component<WithSelectedUnitState, {}> {
    render() {
      const isSelected = this.props.selectedUnit !== null;
      const isSelectedNotEditing = isSelected && this.props.editingDUProfile === null;
      const isActivelyEditing = this.props && !!this.props.editingDUProfile;

      return (
        <Wrapped
          isSelected={isSelected}
          isSelectedNotEditing={isSelectedNotEditing}
          isActivelyEditing={isActivelyEditing}
          {...this.props}
        />
      );
    }
  };

function _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
  let updatedState: Partial<WithSelectedUnitState> = {
    currentInsertPosition: this.state.currentInsertPosition,
    currentEditorStoreAction: e.type
  };
  if (e.type === 'editFocus') {
    updatedState.editingDUProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
      (e as any).unit.definitionId
    );
  } else if (e.type === 'editBlur') {
    updatedState.isEditingTextSelected = false;
  } else if (e.type === 'nestedUnitFocusChange') {
    updatedState.editingNestedChange = e.data as INestedUnitFocusChangeEvent;
    updatedState.dtdValidatedElements = dtdAllowedElements(this.state.currentInsertPosition, e.data as INestedUnitFocusChangeEvent);
  } else if (e.type === 'changeInsertPosition') {
    updatedState.currentInsertPosition = (e as IEditorStoreEvent<'changeInsertPosition'>).data?.position ?? 'insert_inside';
    updatedState.dtdValidatedElements = dtdAllowedElements(updatedState.currentInsertPosition, this.state.editingNestedChange);
  } else if (e.type === 'unitsSelected') {
    // only select when 1 unit is selected, de-select currently edited unit's profile
    const selectedUnit = (e as any).data.selectedUnits.length === 1 ? (e as any).data.initialSelectedUnit : null;

    updatedState.selectedUnit = selectedUnit;
    updatedState.selectedUnitClosestTocable = getSelectedClosestTocable(selectedUnit);
    updatedState.dtdValidatedChildUnits = dtdAllowedUnits(selectedUnit);
  }
  this.setState(updatedState);
  return updatedState;
}

function _hitEditorStoreOnDidMount() {
  const selectedUnits = EditorStore.getSelectedUnits();
  const selectedUnit = selectedUnits.length === 1 ? selectedUnits[0] : null;

  if (selectedUnit !== null) {
    const initialState: WithSelectedUnitState = {
      selectedUnit: null,
      editingDUProfile: null,
      editingNestedChange: null,
      selectedUnitClosestTocable: null,
      dtdValidatedChildUnits: null,
      dtdValidatedElements: null,
      isEditingTextSelected: false,
      currentInsertPosition: this.state.currentInsertPosition ?? 'insert_inside'
    };

    initialState.selectedUnit = selectedUnit;
    initialState.selectedUnitClosestTocable = getSelectedClosestTocable(selectedUnit);
    initialState.dtdValidatedChildUnits = dtdAllowedUnits(selectedUnit);

    if (EditorStore.getEditor().isFocused()) {
      initialState.editingDUProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
        selectedUnit.definitionId
      );
      initialState.editingNestedChange = EditorStore.getLastFocusedNestedUnit();
      if (initialState.editingNestedChange) {
        initialState.dtdValidatedElements = dtdAllowedElements(initialState.currentInsertPosition, initialState.editingNestedChange);
      }
    }
    this.setState(initialState);
  }
}

const setSelectedUnitOnMount = connect({ listen: (fn) => fn() }, _hitEditorStoreOnDidMount);
const hitEditStoreOnMount = connect(EditorStore, _hitEditorStoreOnDidMount);
const subscribeToFocusChange = connect(EditorStore, _onEditStoreUpdate);

const withSelectedUnit = (componentToWrap) =>
  withStore(setSelectedUnitOnMount, hitEditStoreOnMount, subscribeToFocusChange)(selectedUnitWrapper(componentToWrap));

export default withSelectedUnit;
