/* FRAMEWORK */
import * as React from 'react';
/* COMPONENTS */
import DropDown from '../controls/DropDown';
import TableAlignment from '../controls/TableAlignment';
import Formatting from '../controls/TableFormatting';
import Dimension from '../controls/TableDimension';
import CellPadding from '../controls/TableCellPadding';
import CellColor from '../controls/TableCellColor';
import TableBorder from '../controls/TableBorder';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
/* TYPES */
import { FormattingTabProps } from 'mm-types';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import ValidatableTextInput from '../../../../../general/ValidatableTextInput';
import ToggleProp from './QRHProps/ToggleProp';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import { DataAttributesPropsWrapper } from '../components/DataAttributesPropsWrapper';
import EditorStore from '../../../../../../flux/editor/EditorStore';

const FormattingTab = React.memo((props: FormattingTabProps) => {
  const currentRowChoice = () => props.getRowTypes().findIndex((rowType) => rowType.payload === props.getRowType().payload);
  return (
    <div key="tabFormatting" className="edit-props-section">
      <div>
        <div className="edit-props-subsection">
          <DropDown
            title="Row Type"
            choices={props.getRowTypes()}
            currentchoice={currentRowChoice}
            applychoice={props.applyRowTypeChoice}
            dataQa={'table-edit-props-row-type'}
            disabled={!settingEnabled('rowType', props.settings)}
          />
          <TableAlignment
            type="valign"
            title="Vertical align"
            onChangeCallback={
              ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()
                ? (text) => props.setDataAttribute(text, 'valign', 'formatting')
                : undefined
            }
            settings={props.settings}
          />
          {ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
            <>
              <TableAlignment
                type="halign"
                title="Horizontal align"
                onChangeCallback={(text) => props.setDataAttribute(text, 'align', 'formatting')}
                dataAttribute={props.dataAttributes.find((attr) => attr.id === 'data-align')?.val ?? ''}
                settings={props.settings}
              />
              <ValidatableTextInput
                floatingLabelText={'Alignment Character'}
                onChange={(e, text) => props.setDataAttribute(text, 'char', 'formatting')}
                value={props.dataAttributes.find((attr) => attr.id === 'data-char')?.val ?? ''}
                style={{ width: '100%' }}
                data-qa={'table-edit-props-alignment-char'}
              />
              <ValidatableTextInput
                floatingLabelText={'Alignment Character Offset'}
                onChange={(e, text) => props.setDataAttribute(text, 'charoff', 'formatting')}
                value={props.dataAttributes.find((attr) => attr.id === 'data-charoff')?.val ?? ''}
                style={{ width: '100%' }}
                data-qa={'table-edit-props-alignment-char-offset'}
              />
            </>
          )}
          {!ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
            <TableAlignment type="rotation" title="Text rotation" settings={props.settings} />
          )}
          <hr className="light" />
          <Formatting onselected={(e) => props.handleClick(e)} settings={props.settings} />

          <hr className="light" />
          <Dimension type="width" title="Width" display="cell" onChangeCallback={props.onUserUnitChange} settings={props.settings} />
          {!ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
            <>
              <Dimension type="height" title="Height" display="cell" onChangeCallback={props.onUserUnitChange} settings={props.settings} />
              <h6>CELL PADDING</h6>
              <div className="cell-padding ieTooltip">
                <CellPadding
                  tooltip="Left Cell Padding"
                  icon="icon-padding-left"
                  side="left"
                  disabled={!settingEnabled('cellPadLeft', props.settings)}
                />
                <CellPadding
                  tooltip="Top Cell Padding"
                  icon="icon-padding-top"
                  side="top"
                  disabled={!settingEnabled('cellPadTop', props.settings)}
                />
                <CellPadding
                  tooltip="Right Cell Padding"
                  icon="icon-padding-right"
                  side="right"
                  disabled={!settingEnabled('cellPadRight', props.settings)}
                />
                <CellPadding
                  tooltip="Bottom Cell Padding"
                  icon="icon-padding-bottom"
                  side="bottom"
                  disabled={!settingEnabled('cellPadBottom', props.settings)}
                />
                <div style={{ clear: 'both' }} />
              </div>

              <hr className="light" />
              <CellColor type="background" disabled={!settingEnabled('background', props.settings)} tooltip={'Cell Background Color'} />
              <CellColor
                type="color"
                disabled={!settingEnabled('fontColor', props.settings)}
                tooltip={'Cell Font Color'}
                icon={'format_color_text'}
              />
            </>
          )}
          <hr className="light" />
          {ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
            <>
              <ToggleProp
                onChange={(val) => props.setDataAttribute(val, 'colsep', 'formatting')}
                val={props.dataAttributes.find((attr) => attr.id === 'data-colsep')?.val !== 'false'}
                title={'Border Right'}
                dataQa={'table-edit-props-border-right'}
              />
              <ToggleProp
                onChange={(val) => props.setDataAttribute(val, 'rowsep', 'formatting')}
                val={props.dataAttributes.find((attr) => attr.id === 'data-rowsep')?.val !== 'false'}
                title={'Border Bottom'}
                dataQa={'table-edit-props-border-bottom'}
              />
              <ToggleProp
                onChange={(val) => props.setDataAttribute(val, 'cell-emphasis', 'formatting')}
                val={props.dataAttributes.find((attr) => attr.id === 'data-cell-emphasis')?.val === 'true'}
                title={'Cell Emphasis'}
                dataQa={'table-edit-props-cell-emphasis'}
              />
            </>
          )}
          {!ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
            <>
              <TableBorder settings={props.settings} />
              <hr className="light" />
              <MaybeMultiFormatting {...props} />
            </>
          )}
          {EditorStore.getEditor()._getCurrentEditingNode()?.getAttribute('data-element-family') == 'TableRow' && (
            <DataAttributesPropsWrapper
              {...props}
              targetElement={EditorStore.getEditor()._getCurrentEditingNode() ?? props.targetElement}
              titlePrefix={`Table Row `}
              tableEditTabType={'formatting'}
              displayPrintOutput={true}
            />
          )}
        </div>
      </div>
    </div>
  );
});

const MaybeMultiFormatting = React.memo((props: FormattingTabProps) => {
  if (props.renderMultiFormatting) {
    const formatNode = props.index === 0 ? null : props.unitElement;
    return <MultiFormatting formatNode={formatNode} unitProfile={props.unitProfile} />;
  }
  return <div />;
});

export default FormattingTab;
