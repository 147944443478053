import { mm } from './base-clients';

export async function convertElement(
  projectUid: string,
  indexUid: string,
  unitUid: string,
  targetType: string,
  targetSubType: string | null = null,
  data: string
) {
  let response;
  try {
    let endpoint = `/projects/${projectUid}/indexes/${indexUid}/units/${unitUid}/elements/convert?targetFamily=${targetType}`;
    if (targetSubType) {
      endpoint += `&targetSubtype=${targetSubType}`;
    }
    response = await mm.post(endpoint, data, {
      headers: { Accept: 'text/html' }
    });
    return response.data;
  } catch (err) {
    const response = err.response.data;
    if (response.errors) {
      for (const e of response.errors) {
        throw new Error(e.message);
      }
    }
  }
}
