import { ProjectDefinitionStore } from '../../../../flux/common/ProjectDefinitionStore';
import * as TinyHelpers from './TinyFacadeHelpers';
import { isEditableListItemElement } from './TinyFacadeHelpers';

const _friendlyDOMElements = {
  TD: 'Cell',
  TR: 'Row',
  TBODY: 'Body',
  THEAD: 'Head',
  TFOOT: 'Foot',
  FIGCAPTION: 'Caption',
  FIGURE: 'Figure',
  IMG: 'Graphic',
  LI: 'List Item',
  PRE: 'Preformatted'
};

const elementTagNames = ['DIV', 'P', 'TABLE', 'UL', 'OL', 'SPAN', 'A', 'HR'];
export default {
  translate(element: HTMLElement, projectDefinitionStore: ProjectDefinitionStore, getElementDetails = false): string | undefined {
    if (element) {
      if (elementTagNames.indexOf(element.nodeName) >= 0 || isEditableListItemElement(element)) {
        const unitDetails = TinyHelpers.htmlElementToDocEditProfile(element, projectDefinitionStore, getElementDetails);

        if (unitDetails.definition && isNotInlineStyleElement(unitDetails.definition.id)) {
          const elementType = TinyHelpers.readUnitTypeFromDOM(element);
          return unitDetails.definition.displayName ?? elementType.subtype ?? elementType.family;
        }
      }

      return _friendlyDOMElements[element.nodeName];
    }
  }
};

function isNotInlineStyleElement(id: string) {
  return (
    [
      'BoldText',
      'CodeText',
      'ItalicText',
      'SubScriptText',
      'SuperScriptText',
      'UnderlineText',
      'StyledText',
      'ColorText',
      'BackgroundColorText'
    ].indexOf(id) === -1
  );
}
