import * as React from 'react';
import EditorStore from '../../../../flux/editor/EditorStore';
import { IUnit, IUser, IEditorStoreEvent } from 'mm-types';
import useListenToStore from '../../../hooks/useListenToStore';

type Props = {
  unit: IUnit;
};

const EditingUser = (props: Props) => {
  const [editingUser, setEditingUser] = React.useState<IUser | null>(null);

  const onStoreChange = (e: IEditorStoreEvent<'synchronizeUnitEditAction'>) => {
    if (e.type === 'synchronizeUnitEditAction' && e.data!.data.unitUid === props.unit.uid) {
      const editingUser = e.data!.activity === 'unitEditBegin' ? e.data!.data.user : null;
      setEditingUser(editingUser);
    }
  };

  useListenToStore({ store: EditorStore, eventListener: onStoreChange, update: [props.unit.uid] });

  if (!editingUser) {
    return null;
  }
  return (
    <div className="unit-editing-user">
      <img className="avatar" src={editingUser.avatarUrl} />
      <h1>{editingUser.displayName} editing</h1>
      <img className="editing-anim-icon" src={'/assets/images/animat-pencil.gif'} />
    </div>
  );
};

export { EditingUser };
