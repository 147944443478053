import * as React from 'react';
import { createRef } from 'react';
import * as _ from 'lodash';
import ProjectProps, { ActiveTabType } from './properties/ProjectProps';
import DocumentsStore, { DEFAULT_PAGE_SIZE, DocumentsStoreEvent, Page, Sort, SortOrder } from '../../flux/projects/DocumentsStore';
import { CircularProgress } from 'material-ui';
import DocumentsFilter from './DocumentsFilter';
import { IProject, IUserPermissions } from 'mm-types';
import { transitionTo } from '../../utils/Navigation';
import Paging from '../general/Paging';
import DocumentsHeader from './documents/DocumentsHeader';
import DocumentsRow from './documents/DocumentsRow';
import { isCommandCtrlKey, isShiftKey } from '../editor/utils/keyIdentifier';
import DocumentIcon from '../documents/kpi/DocumentIcon';
import { Dom } from '../editor/utils/tinyFacade/DomUtil';
import PageNumberDropdown from './PageNumberDropdown';
import scrollElementIntoView = Dom.scrollElementIntoView;

export type Props = {
  contents: IProject[];
  selected: null | IProject[];
  snackBar: (message: string) => void;
  sort: Sort;
  page?: Page;
  notfound?: boolean;
  nopermission?: boolean;
  onOpened: (projectUid: string) => void;
  onDocPropsAction: (type: DocActions, selectedProject: IProject | null) => void;
  isLoading: boolean;
  onRetrieveDocs: (workspaceUid: string) => void;
  onPerformSortingAndPaging: (sort: Sort, page?: Partial<Page>) => void;
  workspaceUid: 'all' | 'trash' | 'shared' | string;
  activeTab: ActiveTabType;
};

export type State = {
  contents: IProject[] | null;
  headers: Header[];
  loadingProjectInfo: boolean;
};

export type Header = {
  sortById: string;
  title: string;
};

export type DocActions =
  | 'copyDoc'
  | 'uploadExternal'
  | 'downloadNonCdmsCurrentDraft'
  | 'doc-general-settings'
  | 'delete'
  | 'exportArcml'
  | 'exportEdb'
  | 'exportAirbus'
  | 'restore'
  | 'delete_forever'
  | 'createInterim'
  | 'deleteInterim'
  | 'discardDraft'
  | 'loadManual';

export default class Documents extends React.Component<Props, State> {
  private _resizePageThrottleFunction: null | (() => void);
  private _docStoreUnsubscribe: Function;
  private _keyProxy: any;
  private isInitialized = false;
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this._keyProxy = this._documentsKeyBinds.bind(this);

    this._resizePageThrottleFunction = null;
    this.state = {
      contents: this.props.contents,
      loadingProjectInfo: false,
      headers: [
        { sortById: 'TITLE', title: 'Title' },
        { sortById: 'TYPE', title: 'Document Type' },
        { sortById: 'REFERENCE', title: 'Reference' },
        { sortById: 'OWNER', title: 'Owner' },
        { sortById: 'MODIFIED', title: 'Modified' },
        { sortById: 'WORKSPACE', title: 'Workspace' }
      ]
    };
    this.containerRef = createRef();
  }

  static defaultProps: Partial<Props> = {
    selected: null,
    sort: { sortBy: 'TITLE', sortOrder: 'asc' },
    notfound: false,
    nopermission: false
  };

  UNSAFE_componentWillMount() {
    $(document).on('keydown', this._keyProxy);
  }

  componentDidMount() {
    this.isInitialized = true;
    this._docStoreUnsubscribe = DocumentsStore.listen(this.onDocumentsStoreChange, this);

    this._resizePageThrottleFunction = _.throttle(() => {
      this._calculateColumnSizes();
    }, 300);

    window.addEventListener('resize', this._resizePageThrottleFunction!);
    const selectedProject = DocumentsStore.getSelectedProjects();
    setTimeout(() => {
      if (selectedProject && selectedProject.length) {
        this._scrollToDocument(selectedProject[0].uid);
      }
    }, 100);
  }

  componentWillUnmount() {
    this.isInitialized = false;
    $(document).off('keydown', this._keyProxy);

    window.removeEventListener('resize', this._resizePageThrottleFunction!);
    this._docStoreUnsubscribe();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.workspaceUid !== this.props.workspaceUid) {
      this.setState({
        contents: []
      });
    }
  }

  setLoadingState(isLoading = true) {
    if (this.isInitialized) {
      this.setState({ loadingProjectInfo: isLoading });
    }
  }

  _scrollToDocument(id: string) {
    setTimeout(() => {
      scrollElementIntoView(document.getElementById(id));
    }, 100);
  }

  onDocumentsStoreChange(state: DocumentsStoreEvent) {
    if (state.type === 'projects-loaded') {
      if (state.contents) {
        this.setState({ contents: state.contents }, () => {
          if (this.containerRef.current && (!state.selected || state.selected.length === 0)) {
            this.containerRef.current.scrollTop = 0;
          }
          setTimeout(() => {
            this._calculateColumnSizes();
          }, 100);
        });
      }
    }
    if (state.type === 'single-project-loading' && state.selected) {
      this._scrollToDocument(state.selected[0].uid);
    }
  }

  _documentsListEmpty() {
    return !this.props.isLoading && !this.props.notfound && !this.props.nopermission && this.state.contents!.length < 1;
  }

  _getIcon(project: IProject) {
    return (
      <DocumentIcon
        selected={this._isSelected(project.uid)}
        type={project.definitionName}
        external={project.isExternal || false}
        hasAttachments={project.hasAttachments}
      />
    );
  }

  _documentsKeyBinds(e) {
    // select all
    if (e.keyCode === 65 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      this._toggleSelectAll();
      e.preventDefault();
      return false;
    }
  }

  _calculateColumnSizes() {
    if ($) {
      const $hdrEls = $('.documents-fixed-header-table tr:first-child>th');
      $('.documents-list-table tr:first-child>td').each((index, el) => {
        const colW = $(el).innerWidth()!;
        $hdrEls.eq(index).css({ width: colW });
      });
    }
  }

  _handleSort(header) {
    let sortOrder: SortOrder = 'asc';

    if (header.sortById === this.props.sort!.sortBy) {
      sortOrder = this.props.sort!.sortOrder === 'asc' ? 'desc' : 'asc';
    }
    this.props.onPerformSortingAndPaging({ sortBy: header.sortById, sortOrder: sortOrder });
  }

  private moveToPage(pageNumber: number) {
    this.props.onPerformSortingAndPaging(this.props.sort, Object.assign({}, this.props.page, { pageNumber: pageNumber }));
  }

  _getUserProjectPermissions(uid: string): Partial<IUserPermissions> {
    const project = DocumentsStore.getProjectItem(uid);
    return project ? project.currentUserPermissions : {};
  }

  _onMenuClick(actionKey: string, uid) {
    const selectedProject = DocumentsStore.getProjectItem(uid)!;

    if (actionKey === 'Open') {
      transitionTo('editor-edit', {
        projectUid: selectedProject.uid,
        indexUid: selectedProject.masterIndexUid
      });
    } else if (actionKey === 'Copy') {
      this.props.onDocPropsAction('copyDoc', selectedProject);
    } else if (actionKey === 'Upload New Draft') {
      this.props.onDocPropsAction('uploadExternal', selectedProject);
    } else if (actionKey === 'Download Current Draft') {
      this.props.onDocPropsAction('downloadNonCdmsCurrentDraft', selectedProject);
    } else if (actionKey === 'Settings') {
      transitionTo('doc-general-settings', {
        projectUid: selectedProject.uid,
        indexUid: selectedProject.masterIndexUid
      });
    } else if (actionKey === 'Delete') {
      this.props.onDocPropsAction('delete', selectedProject);
    } else if (actionKey === 'Export to ArcML') {
      this.props.onDocPropsAction('exportArcml', selectedProject);
    } else if (actionKey === 'Export to EDB') {
      this.props.onDocPropsAction('exportEdb', selectedProject);
    } else if (actionKey === 'Export to Airbus') {
      this.props.onDocPropsAction('exportAirbus', selectedProject);
    } else if (actionKey === 'Restore') {
      this.props.onDocPropsAction('restore', selectedProject);
    } else if (actionKey === 'Delete permanently') {
      this.props.onDocPropsAction('delete_forever', selectedProject);
    }
  }

  _closeDocProps() {
    DocumentsStore.toggleProjectSelect(null);
  }

  _showProps() {
    return !!this.props.selected && this.props.selected.length === 1 && this.props.workspaceUid !== 'trash';
  }

  _isMultiSelect(): boolean {
    return !!this.props.selected && this.props.selected.length >= 2;
  }

  _numberSelected() {
    return this.props.selected ? this.props.selected.length : 0;
  }

  _isSelected(projectUid) {
    return (
      (this.props.selected &&
        _.some(this.props.selected, (selected) => {
          return selected.uid === projectUid;
        })) ??
      false
    );
  }

  _toggleSelectAll() {
    DocumentsStore.toggleAllProjectSelect();
  }

  _selectToProject(projectUid: string) {
    if (this._numberSelected() === 0) {
      // just toggle it (on)
      DocumentsStore.toggleProjectSelect(projectUid);
    } else {
      // select the unselected in contents range to clicked
      // determine additional projects and submit to store
      let projectUids: string[] = [];
      const first = this.props.selected![0];
      const firstPosition = _.findIndex(this.state.contents, { uid: first.uid });
      const last = this.props.selected![this.props.selected!.length - 1];
      const lastPosition = _.findIndex(this.state.contents, { uid: last.uid });

      const clickPosition = _.findIndex(this.state.contents, { uid: projectUid });

      if (clickPosition < firstPosition) {
        // before current selection
        projectUids = this.state.contents!.slice(clickPosition, firstPosition).map((project) => {
          return project.uid;
        });
      } else if (clickPosition > lastPosition) {
        // after current selection
        projectUids = this.state.contents!.slice(lastPosition + 1, clickPosition + 1).map((project) => {
          return project.uid;
        });
      } else {
        // TODO in selection behaviour unclear
      }
      this.updateProjectSelect(projectUids);
    }
  }

  _handleSelected(e: React.MouseEvent<HTMLElement>, project: IProject) {
    e.persist();

    if (isShiftKey(e)) {
      // select to clicked from selected
      this._selectToProject(project.uid);
    } else if (isCommandCtrlKey(e)) {
      // toggle clicked
      DocumentsStore.toggleProjectSelect(project.uid);
    } else {
      // select clicked (edge case unless it's selected already and only selection)
      if (_.findIndex(this.props.selected, { uid: project.uid }) === -1 || (this.props.selected && this.props.selected.length > 1)) {
        this.updateSingleProjectSelect(project);
      }
    }
  }

  private updateSingleProjectSelect(project: IProject) {
    this.setLoadingState();
    DocumentsStore.updateSingleProjectSelect(project, true)
      .then(() => {
        this.setLoadingState(false);
      })
      .catch(() => {
        this.setLoadingState(false);
      });
  }

  private updateProjectSelect(projectUids: string[] | null, clearOthers = false) {
    this.setLoadingState();
    DocumentsStore.updateProjectSelect(projectUids, clearOthers)
      .then(() => {
        this.setLoadingState(false);
      })
      .catch(() => {
        this.setLoadingState(false);
      });
  }

  _handleOpened(project: IProject) {
    const projectUid = project.uid,
      permissions = project.currentUserPermissions;

    if (permissions.isVisible && !permissions.canRead) {
      if (this.props.snackBar) {
        this.props.snackBar('Relevant permissions required');
      }
    } else if (!project.isExternal && this.props.onOpened) {
      this.props.onOpened(projectUid);
    }
  }

  render() {
    return (
      <div className="documents-container">
        {this.props.isLoading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" size={50} style={{ paddingTop: '250px', paddingLeft: '400px' }} />
          </div>
        ) : undefined}

        <DocumentsFilter
          onRetrieveDocs={() => this.props.onPerformSortingAndPaging(this.props.sort)}
          workspaceUid={this.props.workspaceUid}
        />

        <DocumentsHeader
          workspaceUid={this.props.workspaceUid}
          headers={this.state.headers}
          sort={this.props.sort}
          allSelected={
            !!this.props.contents &&
            !!this.props.selected &&
            this.props.selected.length > 0 &&
            this.props.contents!.length === this.props.selected.length
          }
          onSort={(header) => {
            this._handleSort(header);
          }}
          onSelectAll={() => {
            this._toggleSelectAll();
          }}
        />

        {!this._documentsListEmpty() ? (
          <div className="scrollable-region-doc-list" ref={this.containerRef}>
            <table className="table table-hover table-mc-light-blue selection-table documents-list-table">
              <tbody>
                {this.state.contents!.map((project) => {
                  return (
                    <DocumentsRow
                      key={project.uid}
                      project={project}
                      selected={this._isSelected(project.uid)}
                      isMultiSelect={this._isMultiSelect()}
                      workspaceUid={this.props.workspaceUid}
                      permissions={this._getUserProjectPermissions(project.uid)}
                      icon={this._getIcon(project)}
                      onDoubleClick={() => {
                        this._handleOpened(project);
                      }}
                      onClick={(e) => {
                        this._handleSelected(e, project);
                      }}
                      onMenuClick={(e) => this._onMenuClick(e, project.uid)}
                    />
                  );
                })}

                {!this.props.isLoading ? (
                  <tr className="paging-row">
                    <td colSpan={6}>
                      <Paging page={this.props.page} moveToPage={(pageNumber: number) => this.moveToPage(pageNumber)} />
                    </td>
                    <td colSpan={2}>
                      <PageNumberDropdown pageSize={this.props.page?.pageSize ?? DEFAULT_PAGE_SIZE} />
                    </td>
                  </tr>
                ) : undefined}
              </tbody>
            </table>
          </div>
        ) : undefined}

        {this._documentsListEmpty() ? (
          <div className="empty">
            {DocumentsStore.getDocsFilter().title ? (
              <h4>Could not find any projects matching filter: &quot;{DocumentsStore.getDocsFilter().title}&quot;</h4>
            ) : undefined}
            {!DocumentsStore.getDocsFilter().title ? <h4>There are no projects in this Workspace</h4> : undefined}
          </div>
        ) : undefined}
        {this._showProps() && (
          <ProjectProps
            key="item-details"
            loading={this.state.loadingProjectInfo || this.props.isLoading}
            project={this._showProps() ? this.props.selected![0] : null}
            onClose={() => this._closeDocProps()}
            onAction={(doc, proj) => this.props.onDocPropsAction(doc, proj)}
            defaultActiveTab={this.props.activeTab}
          />
        )}
      </div>
    );
  }
}
