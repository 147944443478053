import EditorInstanceManager from '../../utils/tinyFacade/EditorInstanceManager';

export type FootnoteInfo = { nid: string; text: string };

function getFootnoteNids(editorInstanceManager: EditorInstanceManager): FootnoteInfo[] {
  let activeTableCollection = editorInstanceManager
    .getActiveEditorInstance()
    ?.getElement()
    .closest('[data-element-definition-id="TableCollection"]');

  if (activeTableCollection) {
    const footnotes: JQuery<HTMLElement> | undefined = $(activeTableCollection)
      ?.find('[data-element-definition-id="Footnotes"]')
      .find('.arc-ref');

    if (footnotes) {
      return Array.from(footnotes).map((elm) => {
        return { nid: elm.getAttribute('data-nid')!, text: elm.textContent! };
      });
    }
  }
  return [];
}

function updateRefInt(editorInstanceManager: EditorInstanceManager, nid: string, footnoteNum: number): void {
  const currentElement: Element | undefined = editorInstanceManager.getActiveEditorInstance()?.selection.getNode();
  let currentRefInt: Element | undefined;
  if (currentElement?.classList.contains('arc-refint')) {
    currentRefInt = currentElement;
  } else if (currentElement?.children) {
    Array.from(currentElement?.children).map((child) => {
      if (child.classList.contains('arc-refint') && child.innerHTML === '(-)') {
        currentRefInt = child;
      }
    });
  }
  if (currentRefInt) {
    currentRefInt.setAttribute('data-target-element-nid', nid);
    currentRefInt.textContent = `(${footnoteNum + 1})`;
    currentRefInt.setAttribute('style', '');
  }
}

function getRefIntTargetNid(editorInstanceManager: EditorInstanceManager): string | undefined {
  const currentRefIntTargetNid:
    | string
    | undefined
    | null = editorInstanceManager.getActiveEditorInstance()?.selection?.getNode()?.getAttribute('data-target-element-nid');
  return currentRefIntTargetNid === '' || !currentRefIntTargetNid ? undefined : currentRefIntTargetNid;
}
export default {
  getFootnoteNids: getFootnoteNids,
  updateRefInt: updateRefInt,
  getRefIntTargetNid: getRefIntTargetNid
};
