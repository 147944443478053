import * as React from 'react';
import { EditorManager } from 'tinymce';
import { Dom } from '../../../tinyFacade/DomUtil';
import { isBackspaceKey, isDeleteKeys, nukePropagation } from '../../../keyIdentifier';
import { ElementKeyBehavior } from '../../../tinyFacade/key_listeners/elementKeyBehaviors/elementKeyBehaviors';
import { CustomTinyEventTypes } from '../../../tinyFacade/CustomTinyEventTypes';
import { DomAssertions } from '../../../tinyFacade/DomAssertionsUtil';

declare const tinymce: EditorManager;

class NoteHandler {
  parentClass: string;
  titleClass: string;
  bodyClass: string;
  rootUnitSelector: string;

  constructor(parentClass: string, titleClass: string, bodyClass: string, rootUnitSelector: string) {
    this.parentClass = parentClass;
    this.titleClass = titleClass;
    this.bodyClass = bodyClass;
    this.rootUnitSelector = rootUnitSelector;
  }

  isTitle(element: Element): boolean {
    return DomAssertions.hasAnyClass(element, [this.titleClass]);
  }

  isBodyAndHasNoText(element: Element): boolean {
    const parent: Element | null = element.parentNode as Element;
    return (
      (DomAssertions.hasAnyClass(element, [this.bodyClass]) || DomAssertions.hasAnyClass(parent, [this.bodyClass])) &&
      DomAssertions.hasNoText(parent)
    );
  }

  remove(element: Element, event: React.KeyboardEvent) {
    if (this.isTitle(element) || this.isTinyCaret(element) || (this.isBodyAndHasNoText(element) && isBackspaceKey(event))) {
      nukePropagation(event);
      const parent = this.getParent(element);
      const isUnit = !!parent ? DomAssertions.isUnit(parent as HTMLElement) : false;
      if (parent) {
        if (isUnit) {
          tinymce.activeEditor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
        } else {
          tinymce.activeEditor.undoManager.transact(() => {
            tinymce.activeEditor.dom.remove(parent);
          });
        }
      }
    }
  }

  isTinyCaret(element: Element): boolean {
    return element.hasAttribute('data-mce-caret');
  }

  private getParent(element: Element | null, includeSelf = true): Element | null {
    return Dom.closestElement(element, `.${this.parentClass}`, includeSelf);
  }

  test(element: Element): boolean {
    const parent = this.getParent(element);
    return !!parent && parent.getAttribute('data-element-definition-id') === 'Note';
  }
}
const OPS = new NoteHandler('arc-note', 'arc-note-title', 'arc-note-body', '.arc-unit[data-unit-family="Note"]');
const FTID = new NoteHandler('arc-collection', 'element-left', 'element-content', '.arc-unit[data-subtype="Note"]');

function run(element: Element, event: React.KeyboardEvent) {
  if (isDeleteKeys(event)) {
    onDeleteKeys(element, event);
  }
}

function test(element: Element): boolean {
  return OPS.test(element) || FTID.test(element);
}

function onDeleteKeys(element: Element, event: React.KeyboardEvent) {
  OPS.test(element) ? OPS.remove(element, event) : FTID.remove(element, event);
}

export const keyBehavior: ElementKeyBehavior = {
  run,
  test
};
