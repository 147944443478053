import * as React from 'react';
import EditorStore from '../../../../flux/editor/EditorStore';
import SmartContentStore from '../../../../flux/editor/SmartContentStore';
import Log from '../../../../utils/Log';
import { IUnit, IEditorStoreEvent, ISharedIndexUsages } from 'mm-types';
import useListenToStore from '../../../hooks/useListenToStore';
import { DocUnitShareDetailsPosition } from '../DocUnitShareDetails';

type Props = {
  unit: IUnit;
  position?: DocUnitShareDetailsPosition;
};

const getShareMenuUnit = (uid, modifier): JQuery<HTMLElement> =>
  $(`[data-nid="${uid}"]`).parent().siblings(`.unit-shared-menu${modifier}`).parent().parent(); // chaining doesnt return null it returns a jquery object
const isUpDisabled = (uid) =>
  getShareMenuUnit(uid, '.start')
    .prevAll()
    .filter((index, el) => {
      return $(el).find('.document-unit-outer:not(.readonly-unit)').length > 0;
    }).length === 0;
const isDownDisabled = (uid) =>
  getShareMenuUnit(uid, '.end')
    .nextAll()
    .filter((index, el) => {
      return $(el).find('.document-unit-outer:not(.readonly-unit)').length > 0;
    }).length === 0;
const isArrowDisabled = (isDisabledByPredicate = false) => `material-icons edit-mode-icon ${isDisabledByPredicate ? 'arrow-disabled' : ''}`;

export const ShareMenu = (props: Props) => {
  const { unit, position = 'top' } = props;
  const [changingSpan, setChangingSpan] = React.useState<boolean>(false);
  const [upDisabled, setUpDisabled] = React.useState<boolean>(false);
  const [downDisabled, setDownDisabled] = React.useState<boolean>(false);

  useListenToStore({ store: EditorStore, eventListener: _onEditorEvent });

  React.useEffect(() => {
    if (position === 'top') {
      setUpDisabled(isUpDisabled(unit.uid));
    } else {
      setDownDisabled(isDownDisabled(unit.uid));
    }
  });

  function _onEditorEvent(e: IEditorStoreEvent<'selectShareOrigin'>) {
    if (e.type === 'selectShareOrigin') {
      setChangingSpan(false);
    }
  }

  const _getUnitSharedUsage = () => {
    if (unit.shareDetails) {
      return SmartContentStore.getUsage(unit.shareDetails.shareStartUnitUid, unit.shareDetails.sharedIndexUid);
    }
  };

  const _handleSelectShare = () => {
    EditorStore.triggerShareSelection(unit);
  };

  const _handleOpenOrigin = () => {
    const sharedUsage = _getUnitSharedUsage();
    if (sharedUsage) {
      EditorStore.openAnotherDocument(sharedUsage.sharedIndex.originProjectUid!, sharedUsage.sharedIndex.startUnitUid, null, false);
    }
  };

  const _generateTopOriginMenu = () => (
    <div id="start" className="unit-shared-menu start">
      <div className="actions">
        <i
          id="arrowUp"
          className={isArrowDisabled(changingSpan)}
          onClick={() => !upDisabled && _handleShareEditSelection({ isStart: true, isUp: true })}
        >
          arrow_upward
        </i>
        <i
          id="arrowDown"
          className={isArrowDisabled(changingSpan)}
          onClick={() => _handleShareEditSelection({ isStart: true, isUp: false })}
        >
          arrow_downward
        </i>
      </div>
      <span className="icon-shared-content-marker-point"></span>
      <div className="text">START</div>
    </div>
  );

  const _generateTopShareMenu = (sharedUsage: ISharedIndexUsages) => (
    <div className="unit-shared-menu">
      <div className="actions">
        <i className="material-icons" onClick={(e) => _handleSelectShare()}>
          share
        </i>
        <a className="material-icons" title="Open share origin document" onClick={(e) => _handleOpenOrigin()}>
          open_in_new
        </a>
        <i className="material-icons" style={{ cursor: 'default' }}>
          {unit.shareDetails?.updateStrategy === 'NONE' ? 'lock_open' : 'lock'}
        </i>
      </div>
      <div className="text unit-shared-usage-originname-text">
        <a onClick={(e) => _handleOpenOrigin()}>{sharedUsage.sharedIndex.name}</a>
      </div>
      {unit.shareDetails?.updateStrategy === 'NONE' ? <div className="text">REF</div> : null}
      <div className="text unit-shared-usage-outdatedmode-text">OUTDATED</div>
      <div className="text unit-shared-usage-deferredmode-text">DEFERRED</div>
    </div>
  );

  const _generateTopMenu = () => {
    let topMenu: JSX.Element | null = null;
    if (unit.shareDetails && unit.isVisibleOnEdit && unit.shareDetails.isShareStartUnit) {
      if (unit.shareDetails.origin) {
        topMenu = _generateTopOriginMenu();
      } else {
        const sharedUsage = _getUnitSharedUsage();
        if (sharedUsage) {
          topMenu = _generateTopShareMenu(sharedUsage);
        } else {
          Log.error('ShareMenu: unexpectededly unable to find share usage for unit uid: ' + unit.uid);
        }
      }
    }
    return topMenu;
  };

  const _generateBottomMenu = () => {
    let bottomMenu: JSX.Element | null = null;
    if (unit.shareDetails && unit.isVisibleOnEdit && unit.shareDetails.isShareEndUnit && unit.shareDetails.origin) {
      bottomMenu = (
        <div id="end" className="unit-shared-menu end">
          <div className="actions">
            <i
              id="arrowUp"
              className={isArrowDisabled(changingSpan)}
              onClick={() => _handleShareEditSelection({ isStart: false, isUp: true })}
            >
              arrow_upward
            </i>
            <i
              id="arrowDown"
              className={isArrowDisabled(changingSpan)}
              onClick={() => !downDisabled && _handleShareEditSelection({ isStart: false, isUp: false })}
            >
              arrow_downward
            </i>
          </div>
          <span className="icon-shared-content-marker-point"></span>
          <div className="text">END</div>
        </div>
      );
    }
    return bottomMenu;
  };

  const _handleShareEditSelection = (options: { isStart: boolean; isUp: boolean }) => {
    EditorStore.selectShareOrigin(unit, options);
  };

  return position === 'top' ? _generateTopMenu() : _generateBottomMenu();
};
