import * as React from 'react';
import ServerSettingsStore from '../../flux/common/ServerSettingsStore';
import AgentUtil from '../../utils/AgentUtil';
import PopupContainer from '../misc/PopupContainer';
import { IconButton } from 'material-ui';
import { transitionTo } from '../../utils/Navigation';
import { IAccount } from 'mm-types';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import DocFilter from '../../flux/projects/DocumentsStore';
import { ActionType, initialActions } from './MainMenuActions';

export type Props = {
  isEditorSupported?: boolean;
};

const MainMenu = (props: Props) => {
  const [actions, setActions] = React.useState<ActionType[]>(initialActions);
  const isEditorSupported = props.isEditorSupported ?? AgentUtil.isEditorSupported();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    handlePermissions();
  }, []);

  const handlePermissions = () => {
    const user = ActiveUserStore.getUser();
    let account: Partial<IAccount> = user!.account;

    if (!account) {
      account = {};
    }

    const actionsUpdate = actions;

    if (account.manualManagerUser) {
      actionsUpdate[1].className = 'docs';
    }
    if (account.onlineReaderUser) {
      actionsUpdate[2].className = 'icon-webviewer';
    }
    if (account.aerosyncReadAdmin) {
      actionsUpdate[3].className = 'icon-distribution';
    }
    if (account.accountsAdmin) {
      actionsUpdate[4].className = 'settings';
    }

    setActions(actionsUpdate);
  };

  const handleMenuOpen = () => {
    if (isEditorSupported) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelected = (e: React.MouseEvent<HTMLLIElement>, actionClassname: string) => {
    const store: typeof ServerSettingsStore = ServerSettingsStore;
    const currentTarget: HTMLLIElement = e.currentTarget;

    if (actionClassname.includes('disabled')) {
      return false;
    }

    if (currentTarget.id === 'dashboard') {
      setIsOpen(false);
      transitionTo('/dashboard');
    }
    if (currentTarget.id === 'docs') {
      setIsOpen(false);
      DocFilter.clearDocsFilter();
      transitionTo('/teamspaces/all');
    }
    if (currentTarget.id === 'distribution') {
      setIsOpen(false);
      window.open(store.getServerSettings().aerosyncBaseUrl, '_blank');
    }
    if (currentTarget.id === 'webviewer') {
      setIsOpen(false);
      window.open(store.getServerSettings().onlineReaderBaseUrl, '_blank');
    }
    if (currentTarget.id === 'settings') {
      setIsOpen(false);
      window.open(store.getServerSettings().accountsBaseUrl, '_blank');
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="main-menu-container">
      <IconButton
        className="header-menu-button"
        iconClassName="material-icons"
        onClick={(e) => handleMenuOpen()}
        disabled={!isEditorSupported}
      >
        apps
      </IconButton>

      <PopupContainer
        isOpen={isOpen}
        className="app-menu-popup app-switcher-menu"
        closeOnBlur={true}
        handleClose={true}
        mountOnBody={false}
      >
        <ul id="app-switcher-menu" className="app-menu-options">
          {actions.map((action) => {
            return (
              <li key={action.id} id={action.id} className={action.className} onClick={(e) => handleSelected(e, action.className)}>
                <div className="title">{action.title}</div>
              </li>
            );
          })}
        </ul>
      </PopupContainer>
    </div>
  );
};

export default MainMenu;
