import * as Reflux from 'reflux';
import ProjectStore from './ProjectStore';
import * as client from './../../clients/project';
import moment from 'moment';
import Store from '../Store';
import { IRevision, IIndex } from 'mm-types';
import { AxiosError } from 'axios';

export type DiffParams = {
  revision: IRevision;
  date: string;
};

export type State = {
  error?: AxiosError | null;
  revisions?: IRevision[];
  diffParams?: DiffParams | null;
};

export type RevisionStoreEvent = State;

export class RevisionStore extends Store<State> {
  constructor() {
    super();

    this.state = {};
  }

  getInitialState() {
    return this.state;
  }

  async onProjectStoreUpdate() {
    const project = ProjectStore.getProject();
    if (!project) {
      return;
    }

    try {
      this.state.revisions = await client.getRevisions(project.uid, project.masterIndexUid);
      this.state.error = null;
      this.trigger(this.state);
    } catch (err) {
      this.state.error = err as AxiosError;
      this.trigger(this.state);
    }
  }

  updateDiffParams(diffParams: DiffParams | null, callback?: () => void) {
    this.state.diffParams = diffParams;
    if (callback) {
      callback();
    }
  }

  getDefaultDiffRevision() {
    let diffRevision: IRevision | null = null;
    // Default diff revisions:
    // get first revision before current index
    const currentIndex = ProjectStore.getIndex()!;
    if (currentIndex && this.state.revisions) {
      let revisionFound = false;

      diffRevision = this.state.revisions.find((revision) => {
        revisionFound = !revisionFound ? revision.indexUid === currentIndex.uid : revisionFound;
        return revisionFound && revision.indexUid !== currentIndex.uid;
      })!;
    }

    if (!diffRevision && this.state.revisions && this.state.revisions[0]) {
      diffRevision = this.state.revisions[0];
    }

    return diffRevision;
  }

  getDiffRevision(): IRevision | IIndex | null {
    let diffRevision: IRevision | IIndex | null = null;
    if (this.state.diffParams && this.state.diffParams.revision) {
      diffRevision = this.state.diffParams.revision;
    }
    return diffRevision;
  }

  getDiffTimestamp() {
    let diffTimestamp: string | null = null;
    if (this.state.diffParams && this.state.diffParams.date) {
      diffTimestamp = this.state.diffParams.date;
    }
    return diffTimestamp;
  }

  getDiffTimestampFormatted() {
    const diffTimestamp = this.getDiffTimestamp();
    if (diffTimestamp) {
      return moment(diffTimestamp).toISOString();
    }
  }

  getDiffIndexUid() {
    const diffRevision: IRevision | IIndex | null = this.getDiffRevision();
    return diffRevision ? (diffRevision as IRevision).indexUid || diffRevision.uid : null;
  }

  getRevisions() {
    return this.state.revisions;
  }

  getError() {
    return this.state.error;
  }

  getState() {
    return this.state;
  }

  isLoaded() {
    return this.getRevisions() !== undefined || this.getError() !== null;
  }

  isPublishedRevision(): boolean {
    return !!this.getRevisions() && this.getRevisions()!.length > 0;
  }

  // don't like doing this on a store, but must be cleared, otherwise .isLoaded() logic won't work when revisiting a page (it will always be true) (e.g. nav back and then fwd)
  clear() {
    this.state = {};
  }
}

const singleton = Reflux.initStore<RevisionStore>(RevisionStore);
export default singleton;
