import { EditorMode } from './EditorMode';
import { SynchronizationQueue } from './SynchronizationQueue';
import UnitSharedOriginCreations from '../../UnitSharedOriginCreations';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { IShareDetails } from 'mm-types';

export class ShareEditMode extends EditorMode {
  private _isNew: boolean;
  private _initUnitUid: null | string;
  private _shareUid: null | string;
  private _initUnits: null | any[];

  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.shareTab | attributes.editing | attributes.breadcrumb, syncQueue);

    this._isNew = true;
    this._initUnitUid = null;
    this._shareUid = null;
    this._initUnits = null;

    UnitSharedOriginCreations.clear();
  }

  getParams() {
    return {
      shareUid: this._shareUid,
      isNew: this._isNew,
      initUnitUid: this._initUnitUid
    };
  }

  activate(currentPageUnits: DocUnitWrapper[], params: any) {
    if (params) {
      this._isNew = !params.shareUid;
      this._initUnitUid = params.initUnitUid;
      this._shareUid = !params.shareUid ? null : params.shareUid; // ensure null is enforced...
      this._initUnits = params.initUnits;

      UnitSharedOriginCreations.clear();

      if (this._isNew) {
        this._initUnits!.forEach((unit, index) => {
          // find units, and set as newly editable in memory to start us off
          const initialNewShareEditableUnit = currentPageUnits.find((wrapper) => wrapper.unit.uid === unit.uid);

          if (initialNewShareEditableUnit) {
            const newShareDetails: Partial<IShareDetails> = {
              uid: null,
              origin: true,
              isShareStartUnit: index === 0,
              isShareEndUnit: index === this._initUnits!.length - 1
            };

            UnitSharedOriginCreations.add(unit.uid, newShareDetails as IShareDetails);

            initialNewShareEditableUnit.unit.shareDetails = newShareDetails as IShareDetails;
          }
        });
      }
    }
  }

  deActivate(shareUid: string | null = null) {
    this._isNew = true;
    this._initUnitUid = null;
    this._shareUid = shareUid;
    this._initUnits = null;

    UnitSharedOriginCreations.clear();
  }

  onActivationUnitsRetrieved(unitCollection: DocUnitWrapper[]) {
    // set any in memory created UnitSharedOriginCreations back into memory after any re-render
    unitCollection.forEach((wrapper) => {
      const newShareOriginDetail = UnitSharedOriginCreations.get(wrapper.unit.uid);
      if (newShareOriginDetail) {
        wrapper.unit.shareDetails = newShareOriginDetail;
      }
    });
  }

  getActivatedMenuTabSelections() {
    return { main: 'toc', sub: 'shared' };
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'SOFT_REFRESH'; // always soft refresh on activation
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    if (nextMode === 'EDITING' || nextMode === 'SMARTCONTENTLIB') {
      return 'HARD_REFRESH';
    } else {
      return 'NONE';
    }
  }
}
