import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import PopupContainer from './../../misc/PopupContainer';
import { ISpecialChar } from 'mm-types';
import GetSpecialCharsTable from './GetSpecialCharsTable';

export type Props = {
  onClose: () => void;
};

const SpecialCharPopup = React.forwardRef((props: Props, ref: React.RefObject<PopupContainer>) => {
  const [currentSpecialChar, setCurrentSpecialChar] = React.useState<null | ISpecialChar>(null);

  const setCurrent = (specialChar: ISpecialChar | null) => {
    setCurrentSpecialChar(specialChar);
  };

  const handleClick = (e, specialChar: ISpecialChar) => {
    e.stopPropagation();
    e.preventDefault();

    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
    if (tinyFacade !== null) {
      tinyFacade.insertSpecialChar(specialChar);
    }

    if (props.onClose) {
      props.onClose();
    }

    close();
  };

  return (
    <PopupContainer ref={ref} className="special-characters-popup" closeOnBlur={false} onClose={() => props.onClose()}>
      <div className="popup-container-pointer"></div>
      <div>
        <div className="special-characters-list">
          <GetSpecialCharsTable handleClick={handleClick} setCurrent={setCurrent} />
        </div>

        {currentSpecialChar !== null ? (
          <div className="special-characters-current">
            <div className="special-character-current-sign">{currentSpecialChar?.unicode}</div>
            <div className="special-character-current-description">{currentSpecialChar?.description}</div>
          </div>
        ) : undefined}
      </div>
    </PopupContainer>
  );
});

export default SpecialCharPopup;
