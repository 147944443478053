import EditorStore from '../../../../flux/editor/EditorStore';
import { IUnitDetails } from 'mm-types';
import { UnitTypes } from './UnitTypes';
import { UNIT_ELEMENT_ATTRIBUTE_NAME } from './const/UnitElementSelectors';

export namespace ElementDetails {
  export function getFocusedDetails(): { current?: IUnitDetails; parent?: IUnitDetails } {
    const unit = EditorStore.getLastFocusedNestedUnit();
    if (!unit) {
      return {
        current: undefined,
        parent: undefined
      };
    }
    const tree = unit.nestedTree;
    return {
      current: tree[tree.length - 1],
      parent: tree[tree.length - 2]
    };
  }

  export function isOfType<T extends UnitTypes>(details: IUnitDetails, type: T): boolean {
    return details.type?.toLowerCase() === type.toLowerCase();
  }

  export function getDataElementDefinitionId(element?: Element | null): string | null {
    if (element) {
      return element.getAttribute(UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_DEFINITION_ID) ?? getDataElementDefinitionId(element.parentElement);
    }
    return null;
  }

  export function getDataElementFamily(element: Element): string | null {
    return element.getAttribute(UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY);
  }

  export function getDataNid(element?: Element | null): string | null {
    if (element) {
      return element.getAttribute(UNIT_ELEMENT_ATTRIBUTE_NAME.NID) ?? null;
    }
    return null;
  }
}
