import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TocStore, { ActionsType, ManipulationOptions } from '../../../../../flux/editor/TocStore';
import { ITocNode } from 'mm-types';
import { haveSameLevels } from './tocutils';

export type TocManipulationEvent = (type: ActionsType, e: ManipulationOptions) => void;

export type Props = {
  node: ITocNode; // Element & { uid: string; type: string; };
  position: 'below' | 'above';
  onTocManipulation: TocManipulationEvent;
};

export type State = {};

export default class TocSiblingDropPoint extends React.Component<Props, State> {
  private _counter: number;

  constructor(props: Props) {
    super(props);
    this._counter = 0;
  }

  validDropTarget() {
    const droppedItem = TocStore.getDroppedItem();
    const dropTarget = this.props.node;

    if (droppedItem) {
      const siblings = TocStore.getSiblings(droppedItem.uid!);
      const relevantSibling = this.props.position === 'below' ? siblings.prevSibling : siblings.nextSibling;

      if (relevantSibling && relevantSibling.uid === dropTarget.uid) {
        return false;
      }

      return droppedItem.uid !== dropTarget.uid && haveSameLevels(droppedItem, dropTarget);
    }
  }

  onDragEnter(e: React.DragEvent<HTMLElement>) {
    this._counter++;
    (ReactDOM.findDOMNode(this.refs.droppableZone) as Element).classList.add('active');
  }

  onDragLeave(e: React.DragEvent<HTMLElement>) {
    this._counter--;
    if (this._counter === 0) {
      (ReactDOM.findDOMNode(this.refs.droppableZone) as Element).classList.remove('active');
    }
  }

  onDrop(e: React.DragEvent<HTMLElement>) {
    const nodeUid = ['frontmatter'].includes(this.props.node.type) ? this.props.node.uidnid : this.props.node.uid;
    const positionKey = this.props.position === 'below' ? 'afterTocableUnitUid' : 'beforeTocableUnitUid';

    this.props.onTocManipulation('move', {
      [positionKey]: nodeUid,
      selected: TocStore.getDroppedItem()!
    });
    this.onDragLeave(e);
  }

  onDragOver(e: React.DragEvent<HTMLElement>) {
    e.preventDefault(); // this is needed to indicate we are a droppable zone
    // https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Drag_operations#droptargets
  }

  render() {
    if (this.validDropTarget()) {
      return (
        <div
          ref="droppableZone"
          className={'toc-sibling-drop-point ' + this.props.position}
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e)}
          style={{ zIndex: 20 }}
        >
          <div className="inner"></div>
        </div>
      );
    } else {
      return <span />;
    }
  }
}
