import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { IFileAttachment, AttachmentVisibility } from 'mm-types';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import VisibilityButton from '../comments/components/VisibilityButton';
import FilePreview from './FilePreview';

export type Props = {
  file: Partial<IFileAttachment>;
  open: boolean;
  fileCount: number;
  currentIndex: number;
  onAdd: (description: string, visibility: AttachmentVisibility) => void;
  onCancel: () => void;
  onCancelFile: (fileIndex: number) => void;
  permission: string;
};

export type State = {
  errorMsg: string | null;
  description: string;
  visibility: AttachmentVisibility;
  uploadIndex: number | null;
  descriptionCount: number;
};

type AttachmentSummaryActions =
  | { type: 'setErrorMessage'; payload: string | null }
  | { type: 'setDescription'; payload: string }
  | { type: 'setVisiblity'; payload: AttachmentVisibility }
  | { type: 'setUploadIndex'; payload: number | null }
  | { type: 'setDescCount'; payload: number };

const attachmentSummaryReducer: React.Reducer<State, AttachmentSummaryActions> = (state, action) => {
  switch (action.type) {
    case 'setErrorMessage':
      return { ...state, errorMsg: action.payload };
    case 'setDescription':
      return { ...state, description: action.payload };
    case 'setVisiblity':
      return { ...state, visibility: action.payload };
    case 'setUploadIndex':
      return { ...state, uploadIndex: action.payload };
    case 'setDescCount':
      return { ...state, descriptionCount: action.payload };
    default:
      return { ...state };
  }
};
/**
 * A wizard dialogue box that allows users to go through each uploaded attachment from FileImporter
 */
const AttachmentSummary = (props: Props) => {
  const MAX_CHARS = 500;
  const [state, dispatch] = React.useReducer(attachmentSummaryReducer, {
    errorMsg: null,
    visibility: showVisibilityToggle() ? 'INTERNAL' : 'PUBLIC',
    descriptionCount: 0,
    uploadIndex: 0,
    description: ''
  });

  React.useEffect(() => {
    dispatch({ type: 'setErrorMessage', payload: null });
    dispatch({ type: 'setVisiblity', payload: 'INTERNAL' });
    dispatch({ type: 'setDescCount', payload: 0 });
    dispatch({ type: 'setDescription', payload: '' });
  }, [props.file.uid]);

  const onDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const descText = e.target.value;
    const descCount = descText.length;
    dispatch({ type: 'setDescription', payload: descText.length > MAX_CHARS ? descText.substr(0, MAX_CHARS) : descText });
    dispatch({ type: 'setDescCount', payload: descCount > MAX_CHARS ? MAX_CHARS : descCount });
  };

  const onAdd = () => {
    props.onAdd(state.description, state.visibility);
    dispatch({ type: 'setDescription', payload: '' });
  };

  function showVisibilityToggle() {
    return !!(
      ProjectStore.getProject()?.currentUserPermissions?.canManageAttachments &&
      ProjectStore.getProject()?.currentUserPermissions?.canCommentInternal
    );
  }

  return (
    <Dialog
      bodyStyle={{ padding: '0' }}
      id={'attachment-summary'}
      contentStyle={{ width: '30%', minWidth: '250px' }}
      className="external-upload-modal attachment-summary"
      autoDetectWindowHeight={true}
      autoScrollBodyContent={true}
      open={props.open}
    >
      <div className="dialogue-header">
        <div className="close-button right">
          <FontIcon style={{ color: 'black' }} onClick={() => props.onCancel()} className="material-icons icon-close" />
        </div>
        <h5>
          Upload file {props.currentIndex + 1} of {props.fileCount}{' '}
        </h5>
      </div>
      <div className="inner-content">
        {props.file && <FilePreview file={props.file} />}

        <div className="description-section">
          <h6>Description </h6>
          <div className="text-area">
            <textarea
              id={'description-area'}
              style={{ height: '170px' }}
              placeholder="Enter the file description here"
              value={state.description}
              onChange={(e) => onDescChange(e)}
            />
            <span>
              {' '}
              {state.descriptionCount}/{MAX_CHARS}
            </span>
          </div>
        </div>
        {showVisibilityToggle() && (
          <div>
            <div
              id="lock-permission"
              className="permission"
              onClick={() => dispatch({ type: 'setVisiblity', payload: state.visibility === 'INTERNAL' ? 'PUBLIC' : 'INTERNAL' })}
            >
              <VisibilityButton isPublic={props.file && state.visibility === 'PUBLIC'} />
              <span className="margin-left-l font-xs">(tap the lock to set visibility)</span>
            </div>
          </div>
        )}

        <div className="error-msg">{state.errorMsg}</div>
        <div className="buttons-section">
          <span className="margin-right-l">
            <FlatButton label="Cancel File" onClick={() => props.onCancelFile(state.uploadIndex!)} data-qa="attachment-summary-cancel" />
          </span>
          <FlatButton className="upload-button" backgroundColor="#59b957" hoverColor="#438741" label="Add" onClick={() => onAdd()} />
        </div>
      </div>
    </Dialog>
  );
};

export default AttachmentSummary;
