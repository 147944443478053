import * as Reflux from 'reflux';
import EditorStore from '../editor/EditorStore';
import * as workflows from '../../clients/workflows';
import * as indexClient from '../../clients/index';
import ActiveUserStore from '../common/ActiveUserStore';
import ProjectStore from '../../flux/editor/ProjectStore';
import userUtil from '../../components/documents/team/util/user';
import { dateUtil } from '../../utils';
import Store from '../Store';
import { DocParams, IIndex, IProject, IUser, IWorkFlow, IWorkflowAssignments, IWorkflowSummary } from 'mm-types';
import { Cancelled, ClientError } from '../../clients/base-clients';
import { AxiosError } from 'axios';
import NotificationsStore from '../events/NotificationsStore';
import { transitionTo } from '../../utils/Navigation';

export type ErrorTypes = 'WF_GONE' | 'WF_INUSE';

export type State = {
  workflow: IWorkFlow | null;
  workflows: IWorkFlow[] | null;
  workflowSummary: Partial<IWorkflowSummary>;
};

export type WorkflowStoreEvent = {
  type:
    | 'index'
    | 'whatsNewPublish'
    | 'onWhatsNewPublished'
    | 'workflows'
    | 'workflow'
    | 'workflowInstance'
    | 'workflowSummaryNotFound'
    | 'workflowSummary'
    | 'onWorkflowPublished';
  index?: IIndex;
  workflow?: null | IWorkFlow;
  state?: State;
  error?: ErrorTypes;
};

export class WorkflowStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      workflow: null,
      workflows: null,
      workflowSummary: {}
    };
  }

  getInitialState() {
    return this.state;
  }

  // Event Handlers

  async startWorkflow(index: IIndex) {
    try {
      await workflows.startWorkflow(index.uid);
    } catch (err) {
      if (err instanceof ClientError && [404, 403, 400].indexOf(err.status) !== -1) {
        EditorStore.triggerShowEditorError({ xhr: err.data });
      }
    }
  }

  async publishWhatsNew(index: IIndex, project: IProject) {
    try {
      await indexClient.publish(project.uid, index.uid);
      this.trigger({ workflow: null, type: 'whatsNewPublish' } as WorkflowStoreEvent);
    } catch (err) {
      const axiosErr = err as AxiosError;
      EditorStore.triggerShowEditorError({ axiosErr: axiosErr });
    }
  }

  whatsNewPublished() {
    this.trigger({ type: 'onWhatsNewPublished' } as WorkflowStoreEvent);
  }

  async retrieveWorkflows(silent = false) {
    const results = await workflows.getAll();
    if (results instanceof Cancelled) {
      return;
    }

    this.state = { ...this.state, workflows: results };

    if (!silent) {
      this.trigger({ type: 'workflows', state: this.state } as WorkflowStoreEvent);
    }
  }

  regulatoryContentChanges(workflow: IWorkFlow) {
    this._populateActiveStage(workflow);

    if (workflow.activeStage && workflow.activeStage.autoStagesSkip) {
      this._retrieveWorkflowStageAssignments(workflow, { indexUid: ProjectStore.getIndex()!.uid }).then(() => {
        this.state = { ...this.state, workflow: workflow };
      });
    }
  }

  async retrieveWorkflowForIndex(indexUid: string, options: {}) {
    const workflowsReturned = await workflows.getAll({ indexUid });

    if (workflowsReturned instanceof Cancelled) {
      return null;
    }

    if (workflowsReturned.length > 0) {
      const workflow = workflowsReturned[0];
      const assignments = await this._retrieveWorkflowStageAssignments(workflow, Object.assign({}, options, { indexUid: indexUid }));
      return assignments;
    } else {
      return null;
    }
  }

  async retrieveIndex(projectUid: string, indexUid: string) {
    const index = await indexClient.get(projectUid, indexUid);
    this.trigger({ type: 'index', index: index } as WorkflowStoreEvent);
    return index;
  }

  async retrieveWorkflow(wfUid: string, options) {
    try {
      const workflow = await workflows.getOne(wfUid);

      if (options && options.assignments) {
        await this._retrieveWorkflowStageAssignments(workflow, options);
        return workflow;
      } else {
        this._populateActiveStage(workflow);
        this.trigger({ workflow: workflow, type: 'workflow' } as WorkflowStoreEvent);
        return workflow;
      }
    } catch (err) {
      if (err instanceof ClientError && err.status === 404) {
        this.trigger({ error: 'WF_GONE' } as WorkflowStoreEvent);
      }

      return null;
    }
  }

  private async _retrieveWorkflowStageAssignments(workflow: IWorkFlow, options: DocParams) {
    try {
      const result = await workflows.getStageAssignments(workflow.uid, options);
      const workflowStageAssignments = result;

      this._populateAssignmentsAndActiveStage(workflow, workflowStageAssignments);
      this.trigger({ workflow: workflow, type: 'workflowInstance' } as WorkflowStoreEvent);

      return { workflow, workflowStageAssignments };
    } catch (err) {
      if (err instanceof ClientError && err.status === 404) {
        this.trigger({ error: 'WF_GONE' } as WorkflowStoreEvent);
      }

      return null;
    }
  }

  async retrieveIndexesWithWorkflow(projectUid: string) {
    const indexes = await indexClient.getAll(projectUid);

    if (indexes instanceof Cancelled) {
      return null;
    }

    return indexes.filter((index) => {
      return index.workflowUid && index.workflowActive;
    });
  }

  private _populateActiveStage(workflow: IWorkFlow) {
    workflow.activeStage = workflow.stages!.find((stage) => stage.uid === workflow.activeStageUid)!;
  }

  private _populateAssignmentsAndActiveStage(workflow: IWorkFlow, assignments: IWorkflowAssignments) {
    // put assignments into appropriate workflow's stage
    workflow.stages = workflow.stages!.map((stage) => {
      const filtered = assignments.stageAssignments.filter((assignment) => stage.uid === assignment.stageUid);

      stage.assignments = filtered.map((a) => {
        if (a.user) {
          return Object.assign(a.user, {
            avatarUrl: userUtil.imagePath(a.user as IUser),
            comment: a.comment,
            reviewResult: a.reviewResult,
            completionDateFormatted: dateUtil(a.completionDate).formatDateTimeNoSecs(true)
          });
        } else {
          return Object.assign(a.group, { isGroup: true, displayName: a.group.name });
        }
      });

      stage.completionDateFormatted = dateUtil(stage.completionDate).formatDateTime(true);
      stage.dueDateFormatted = dateUtil(stage.dueDate).formatDate();

      return stage;
    });

    this._populateActiveStage(workflow);

    if (workflow.activeStage) {
      const currentUser = ActiveUserStore.getUser()!;
      workflow.isCurrentUserAssignedToStage = !!workflow.activeStage.assignments.find((assignment) => assignment.uid === currentUser.uid);
    }
  }

  async createWorkflow(workflow: Partial<IWorkFlow>) {
    await workflows.create(workflow);
    await this.retrieveWorkflows();
  }

  async removeWorkflow(workflowUid: string, successCallback: () => void) {
    try {
      await workflows.remove(workflowUid);
      await this.retrieveWorkflows();
    } catch (err) {
      if ((err instanceof ClientError && err.status === 412) || err.status === 400) {
        this.trigger({ error: 'WF_INUSE' } as WorkflowStoreEvent);
      } else {
        await this.retrieveWorkflows();
      }
    }
  }

  async updateWorkflow(workflow: Partial<IWorkFlow>) {
    workflow?.uid && (await workflows.update(workflow.uid, workflow));
    await this.retrieveWorkflows();
  }

  async updateWorkflowStageAssignments(workflow: IWorkFlow, options) {
    // Reduce workflow.stages.assignment representation to the flat array with assignments (for api)
    const stageAssignments = workflow.stages!.reduce((assignments: any[], stage) => {
      const assignmentsToAdd = stage.assignments.map((assignment) => {
        const data: Partial<IWorkflowAssignments> = { stageUid: stage.uid };

        if (options.isProject) {
          data.projectUid = options.uid;
        } else {
          data.indexUid = options.uid;
        }

        const userOrGroup = { uid: assignment.uid };
        const toRet: Partial<IWorkflowAssignments> = Object.assign(
          {},
          data,
          assignment.isGroup ? { group: userOrGroup } : { user: userOrGroup }
        );
        return toRet;
      });

      return assignments.concat(assignmentsToAdd);
    }, []);

    const updateAssignments: Partial<IWorkflowAssignments> = { stageAssignments: stageAssignments };
    if (options.isProject) {
      updateAssignments.projectUid = options.uid;
    } else {
      updateAssignments.indexUid = options.uid;
    }

    if (workflow.type === 'TEMPLATE') {
      await workflows.saveStageAssignments(workflow.uid, updateAssignments);
    } else {
      await workflows.update(workflow.uid, workflow);
      await workflows.saveStageAssignments(workflow.uid, updateAssignments);
    }
  }

  async workflowAction(data: workflows.WorkflowActionData, workflowUid: string, isPublishing = false) {
    try {
      const actionNotification = await workflows.saveAction(workflowUid, data);
      const isWorkflowReviewed = await pollWorkflowReviewCompleted(actionNotification.uid);

      if (isPublishing) {
        // if publishing we need to poll to see if review has completed otherwise go to project list page
        if (isWorkflowReviewed) {
          const isInterim: boolean = ProjectStore.isInterim();
          const project: IProject | undefined = ProjectStore.getProject();
          if (project) {
            // Lookup newest index (so published state is updated)
            const index: IIndex | Cancelled = await this.retrieveIndex(
              project.uid,
              isInterim ? project.interimIndexUid : project.masterIndexUid
            );

            this.trigger({
              index: index instanceof Cancelled ? undefined : index,
              type: 'onWorkflowPublished'
            } as WorkflowStoreEvent);
          }
        } else {
          transitionTo('/teamspaces/all');
        }
      }
    } catch (err) {
      throw err.message ? err : new Error('Error happened on the server. Please contact administrator.');
    }
  }

  async retrieveWorkflowSummary(indexUid: string) {
    try {
      const workflowSummary = await workflows.getSummary(indexUid);
      if (workflowSummary instanceof Cancelled) {
        return this.state;
      }

      this._populateActiveStage(workflowSummary.workflow);
      this._populateAssignmentsAndActiveStage(workflowSummary.workflow, workflowSummary.assignments);

      if (workflowSummary.workflow.stages?.[0]?.startDate) {
        workflowSummary.workflow.startDateFormatted = dateUtil(workflowSummary.workflow.stages?.[0]?.startDate).formatDateTimeNoSecs(true);
      }

      this.state = Object.assign({}, this.state, { workflowSummary: workflowSummary });
      this.trigger({ state: this.state, type: 'workflowSummary' } as WorkflowStoreEvent);
      return this.state;
    } catch (err) {
      if (err instanceof ClientError && err.status === 404) {
        this.trigger({ state: this.state, type: 'workflowSummaryNotFound' } as WorkflowStoreEvent);
      }

      return this.state;
    }
  }
}

async function pollWorkflowReviewCompleted(uid: string): Promise<boolean> {
  let checkWorkflowReviewNotification;
  const checkWorkflowReviewNotificationPromise = new Promise<boolean>((resolve) => {
    checkWorkflowReviewNotification = setInterval(() => {
      const isWorkflowReviewed = NotificationsStore.getNotification(uid)?.isReady ?? false;
      if (isWorkflowReviewed) {
        clearInterval(checkWorkflowReviewNotification);
        resolve(true);
      }
    }, 1000);
  });

  const timeout = new Promise<boolean>((resolve) => {
    setTimeout(() => {
      clearInterval(checkWorkflowReviewNotification);
      resolve(false);
    }, 20000);
  });
  return await Promise.race([checkWorkflowReviewNotificationPromise, timeout]);
}

const singleton = Reflux.initStore<WorkflowStore>(WorkflowStore);
export default singleton;
