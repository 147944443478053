import { UnitTypes } from '../../components/editor/utils/units/UnitTypes';
import _ from 'lodash';

export type ClipPasteUnit = {
  type: UnitTypes;
  html?: string;
  template?: string;
};

export type Clip = {
  type: number;
  content: CopyDetails[];
};

export type CopyDetails = {
  title: string;
  definitionId: string;
  copiedHtml: string;
  isVisibleOnEdit: boolean;
  isElement: boolean;
  type: string;
};

export class Clipboard {
  // types
  DOCUNIT = 1;
  MULTIDOCUNITS = 3;

  private _addToClipboard(type: number, content: CopyDetails[]) {
    const newContent: CopyDetails[] = _.cloneDeep(content);
    // clean old data-nid if present
    newContent.forEach((unit) => {
      const unitContent = $(`<div>${unit.copiedHtml}</div>`);
      unitContent.find('[data-nid]').removeAttr('data-nid');
      unit.copiedHtml = unitContent.html() ?? unit.copiedHtml;
    });
    localStorage.setItem('clipboard', JSON.stringify({ type: type, content: newContent }));
    window.dispatchEvent(new Event('storage'));
  }

  public getLastFromClipboard(): Clip | null {
    const clip = localStorage.getItem('clipboard');
    if (clip) {
      return JSON.parse(clip);
    }
    return null;
  }

  public copyUnit(copiedUnitDetails: CopyDetails) {
    if (!copiedUnitDetails.isVisibleOnEdit || ['header'].indexOf(copiedUnitDetails.definitionId) > -1) {
      return false;
    } else {
      this._addToClipboard(this.DOCUNIT, [copiedUnitDetails]);
      return true;
    }
  }

  copyUnits(docUnits: CopyDetails[]) {
    const invalidUnits = docUnits.filter((u) => !u.isVisibleOnEdit || ['header'].indexOf(u.type) > -1);
    if (invalidUnits.length === 0) {
      this._addToClipboard(this.MULTIDOCUNITS, docUnits);
      return true;
    } else {
      return false;
    }
  }
}

export default new Clipboard();
