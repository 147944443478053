import * as React from 'react';
import Document from './Document';
import { IRecentIndex } from 'mm-types';

export type Props = {
  documents: IRecentIndex[];
  onDocumentSelect: (doc: IRecentIndex) => void;
};

export type State = {};

export default class DocumentList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="doc-list">
        {this.props.documents.map((document, index) => {
          if (!document.isDeleted) {
            return <Document key={index} document={document} onDocumentSelect={(doc) => this.props.onDocumentSelect(doc)} />;
          }
        })}
      </div>
    );
  }
}
