import { EditorMode } from './EditorMode';
import { Banner, EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class RegulationSelectionMode extends EditorMode {
  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.dualDocsDisplay | attributes.editing | attributes.shareTab, syncQueue);
  }

  getBanner(): Banner {
    return { title: 'Tag Elements with Regulation', className: 'reg-select-banner' };
  }

  getActivatedMenuTabSelections() {
    return { main: 'toc', sub: 'shared' };
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'SOFT_REFRESH';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    if (nextMode === 'EDITING') {
      return 'HARD_REFRESH';
    }
    return 'SOFT_REFRESH';
  }
}
