import React, { useState } from 'react';
import ProjectItemMenu from '../ProjectItemMenu';
import { IProject, IUserPermissions } from 'mm-types';

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
  selected: boolean;
  isMultiSelect: boolean;
  icon: JSX.Element;
  project: IProject;
  workspaceUid: string;
  currentlyOpenMenu?: string;
  permissions: Partial<IUserPermissions>;
  onMenuClick(e: string, uid: string);
}

const DocumentsRow = ({
  project,
  selected,
  icon,
  workspaceUid,
  isMultiSelect,
  permissions,
  onMenuClick,
  onClick,
  onDoubleClick
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  function sinkEvent(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const { canRead } = project.currentUserPermissions;
  return (
    <tr
      id={project.uid}
      className={`${canRead ? 'clickable-row' : 'pointer-events-none'} ${selected ? ' selected' : ''}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <td data-title="multi">{icon}</td>
      <td>
        <span title={project.name} className="project-name">
          {project.name}
        </span>
      </td>
      <td data-title="Document Type">{project.definitionDescription}</td>
      <td title={project.docReference ? project.docReference.displayName : ''} data-title="Reference">
        {project.referenceDisplayName}
      </td>
      <td data-title="Owner">{project.ownerDisplayName}</td>
      <td data-title="Modified">{project ? project.modifiedFormatted : '-'}</td>
      <td data-title="Modified">{project.workspaceName}</td>
      <td
        data-title="Expand"
        data-name={project.name}
        onClick={(e) => {
          sinkEvent(e);
        }}
      >
        {(!isMultiSelect || !canRead) && (
          <div className="projectDropdown" onClick={openMenu}>
            <ProjectItemMenu
              open={menuOpen}
              isExternal={project.isExternal}
              workspaceUid={workspaceUid}
              projectUid={project.uid}
              permissions={permissions}
              onSelected={(e) => onMenuClick(e, project.uid)}
              onClose={closeMenu}
              hideInterim={true}
              getRevisions={false}
              projectSettings={project.settings}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default DocumentsRow;
