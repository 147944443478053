import axios, { CancelTokenSource } from 'axios';
import { Cancelled, mm } from './base-clients';
import { IDictionaryWord, IFindModel, ISpellcheckHit, IUnit } from 'mm-types';
import QueryUtil from '../utils/QueryUtil';

let source: CancelTokenSource | null = null;
let getWordsSource: CancelTokenSource | null = null;

export type FindOptions = {
  findText: string | null;
  matchCase: boolean;
  wholeWord: boolean;
  startUnitUid: string | null;
};

export type ReplaceOptions = {
  replaceChildren: boolean;
  spellCheck: boolean;
  unitUid: string | null;
  preserveCase: boolean;
  matchCase: boolean;
  wholeWord: boolean;
  index: number;
  replacement: string;
  target: string;
};

export async function findMisspells(indexUid: string, tocableUnitUid: string): Promise<ISpellcheckHit[] | Cancelled> {
  const params: string = QueryUtil.fromObject({
    indexUid,
    tocableUnitUid
  });

  const response = await mm.get<{ incorrectWords: ISpellcheckHit[] }>(`/spellCheck/check/units/${params}`);

  return response.data.incorrectWords;
}
/**
 *Gets a list of suggestions
 */
export async function getSuggestions(word: string) {
  if (source) {
    source.cancel();
  }

  source = axios.CancelToken.source();

  try {
    const respoonse = await mm.get<{ suggestions: string[] }>(`/spellCheck/suggest?word=${encodeURIComponent(word)}`, {
      cancelToken: source.token
    });
    return respoonse.data.suggestions;
  } catch (err) {
    return [];
  }
}

/**
 * Gets an array of dictionary words
 */
export async function getDictionaryWords() {
  if (getWordsSource) {
    getWordsSource.cancel();
  }

  getWordsSource = axios.CancelToken.source();

  try {
    const respoonse = await mm.get<{ words: IDictionaryWord[] }>(`/spellCheck/dictionary`, {
      cancelToken: getWordsSource.token
    });
    return respoonse.data.words;
  } catch (err) {
    return [];
  }
}

/**
 * Adds a new word
 */
export async function addWord(word: string) {
  await mm.post(`/spellCheck/dictionary?word=${encodeURIComponent(word)}`);
}

/**
 * Removes a word
 */
export async function removeWord(word: string) {
  await mm.delete(`/spellCheck/dictionary?word=${encodeURIComponent(word)}`);
}

/**
 * Uploads a file of dictionary words
 */
export async function uploadFile(file: File) {
  const data = new FormData();
  data.append('file', file);
  await mm.post(`/spellCheck/dictionary/import`, data);
}

export async function find(projectUid: string, indexUid: string, options: FindOptions) {
  const query: string[] = [];
  const data: Partial<FindOptions> = {};

  if (options.findText) {
    data.findText = options.findText;
  }
  if (options.matchCase) {
    data.matchCase = options.matchCase;
  }
  if (options.wholeWord) {
    data.wholeWord = options.wholeWord;
  }

  if (options.startUnitUid) {
    query.push('startUnitUid=' + options.startUnitUid);
  }

  const respoonse = await mm.post<{ matches: IFindModel[] }>(
    `/projects/${projectUid}/indexes/${indexUid}/text/find?${query.join('&')}`,
    data
  );

  // if api response match doesnt contain a chapterUid it is a chapter and we need to add one
  respoonse.data.matches.forEach((m, index) => {
    m.link.globalMatchIndex = index;
    if (!m.link.chapterUid) {
      m.link.chapterUid = m.link.unitUid;
    }
  });

  const chapterOrder: string[] = [];
  for (const match of respoonse.data.matches) {
    if (chapterOrder.indexOf(match.link.chapterUid) === -1) {
      chapterOrder.push(match.link.chapterUid);
    }
  }

  const groups: { [chapterId: string]: IFindModel[] } = {};

  for (const match of respoonse.data.matches) {
    if (groups[match.link.chapterUid]) {
      groups[match.link.chapterUid] = groups[match.link.chapterUid].concat(match);
    } else {
      groups[match.link.chapterUid] = [match];
    }
  }

  const sectionIndex = new Map<string, number>();

  const toRet = {
    globalMatches: chapterOrder.map((chapterUid) => {
      groups[chapterUid].forEach((el, i) => {
        if (el.link.sectionUid) {
          if (sectionIndex.has(el.link.sectionUid)) {
            el.link.sectionIndex = sectionIndex.get(el.link.sectionUid)! + 1;
            sectionIndex.set(el.link.sectionUid, el.link.sectionIndex);
          } else {
            el.link.sectionIndex = 0;
            sectionIndex.set(el.link.sectionUid, 0);
          }
        }
        el.link.chapterIndex = i;
      });

      return {
        chapter: chapterUid,
        matches: groups[chapterUid]
      };
    }),
    globalMatchesCount: respoonse.data.matches.length,
    globalMatchesFlat: respoonse.data.matches
  };
  return toRet;
}

export async function replace(projectUid: string, indexUid: string, data: Partial<ReplaceOptions>) {
  const response = await mm.post<{ units: Partial<IUnit>[] }>(`/projects/${projectUid}/indexes/${indexUid}/text/replace`, data);
  return response.data;
}
