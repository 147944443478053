import * as React from 'react';
import * as _ from 'lodash';
import * as ReactDOM from 'react-dom';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ChangeTasksStore, { ChangeTasksStoreEvent } from '../../../../../flux/editor/ChangeTasksStore';
import UnitTaskStore from '../../../../../flux/editor/UnitTaskStore';
import ChangeTaskFilter from '../../../../misc/ChangeTaskFilter';
import { CircularProgress } from 'material-ui';
import { IActionTaskEntry, ITask } from 'mm-types';
import TaskActivityList from './TaskActivityList';

const SCROLLREQUEST_THRESHOLD = 300;
let SCROLLCOUNTER = 0;
let MAX_SCROLL = false;

const _persistForIndex: { indexUid: null | string; filters: ITask[]; selected: null | string } = {
  indexUid: null,
  filters: [],
  selected: null
};

export type Props = {};

export type State = {
  activityEntries: null | IActionTaskEntry[];
  loading: boolean;
  filterTasks: ITask[];
  loadingScroll: boolean;
  selected: string | null;
  isLoading?: boolean;
};

export default class TaskActivity extends React.Component<Props, State> {
  private changeTaskUnsubscribe: Function;
  private unitTaskUnsubscribe: Function;
  private taskActivityListRef = React.createRef<HTMLUListElement>();
  constructor(props: Props) {
    super(props);

    this.changeTaskUnsubscribe = ChangeTasksStore.listen(this._onChangeTasksUpdate, this);
    this.unitTaskUnsubscribe = UnitTaskStore.listen(this._onUnitTaskStoreUpdate, this);

    this.state = {
      activityEntries: null,
      loading: true,
      loadingScroll: false,
      filterTasks: this._getDefaultFilters(),
      selected: this._getDefaultSelected()
    };
  }

  componentWillUnmount() {
    this.changeTaskUnsubscribe();
    this.unitTaskUnsubscribe();
    SCROLLCOUNTER = 0;
    MAX_SCROLL = false;
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this._retrieveLogs();
    });
  }

  UNSAFE_componentWillMount() {
    this._handleScroll = _.throttle(this._handleScroll, 500);
    SCROLLCOUNTER = 0;
    MAX_SCROLL = false;
  }

  _handleScroll() {
    if (!this.state.loadingScroll) {
      const activitiesListElement = ReactDOM.findDOMNode(this.refs.activityEntriesList) as Element;
      if (!!activitiesListElement) {
        const listInnerHeight = parseInt(window.getComputedStyle(activitiesListElement, undefined).getPropertyValue('height'));
        if (activitiesListElement.scrollTop + listInnerHeight >= activitiesListElement.scrollHeight - SCROLLREQUEST_THRESHOLD) {
          SCROLLCOUNTER = SCROLLCOUNTER + 1;
          if (!MAX_SCROLL) {
            this.setState({ loadingScroll: true }, () => {
              ChangeTasksStore.retrieveLogsPage(SCROLLCOUNTER, EditorStore.getDocParams(), _.map(this.state.filterTasks, 'uid'));
            });
          }
        }
      }
    }
  }

  _retrieveLogs() {
    SCROLLCOUNTER = 0;
    MAX_SCROLL = false;
    ChangeTasksStore.retrieveLogs(EditorStore.getDocParams(), _.map(this.state.filterTasks, 'uid'));
  }

  // task to unit mapping changed
  _onUnitTaskStoreUpdate() {
    if (this.refs.activityEntriesList) {
      (ReactDOM.findDOMNode(this.refs.activityEntriesList) as Element).scrollTop = 0;
    }

    this._retrieveLogs();
  }

  _getDefaultFilters(): ITask[] {
    const indexUid = EditorStore.getDocParams().indexUid!;

    if (_persistForIndex.indexUid === indexUid) {
      if (_persistForIndex.filters) {
        const tasks = ChangeTasksStore.getInitialState().tasks;
        const orphanedTasks: ITask[] = [];

        _persistForIndex.filters.forEach((task: ITask) => {
          const updatedTask: ITask = tasks.find(function (t) {
            return t.uid === task.uid;
          })!;

          if (updatedTask) {
            task = _.extend(task, updatedTask);
          } else {
            orphanedTasks.push(task);
          }
        });

        if (orphanedTasks.length) {
          orphanedTasks.forEach((orphanedTask) => {
            _persistForIndex.filters.splice(_.indexOf(_persistForIndex.filters, orphanedTask), 1);
          });
        }
      }

      return _persistForIndex.filters;
    } else {
      // get filters for new index - i.e. empty default
      _persistForIndex.indexUid = indexUid;
      _persistForIndex.filters = [];
      return _persistForIndex.filters;
    }
  }

  _getDefaultSelected() {
    const indexUid = EditorStore.getDocParams().indexUid!;
    return _persistForIndex.indexUid === indexUid ? _persistForIndex.selected : null;
  }

  _onChangeTasksUpdate(state: ChangeTasksStoreEvent) {
    if (state.activityEntries) {
      this.setState({ activityEntries: state.activityEntries, loading: false, loadingScroll: false });
    }
    if (state.pagination === 'PAGINATION-END') {
      this.setState({ loadingScroll: false });
      MAX_SCROLL = true;
    }
  }

  _handleSelectActivity(unitTag: IActionTaskEntry) {
    _persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
    _persistForIndex.selected = unitTag.key;

    this.setState({ selected: unitTag.key }, () => {
      EditorStore.openDocumentWithUnit(unitTag);
    });
  }

  _handleFilterChange(e: { assigned: boolean; taskUid: string; task?: ITask }) {
    // ensure 1 item always for now
    if (e.assigned) {
      this.state.filterTasks.push(e.task!);
    } else {
      let removeIndex = -1;
      this.state.filterTasks.forEach((task, i) => {
        if (task.uid === e.taskUid) {
          removeIndex = i;
        }
      });

      this.state.filterTasks.splice(removeIndex, 1);
    }

    _persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
    _persistForIndex.filters = this.state.filterTasks;

    this.setState(this.state, () => {
      this._retrieveLogs();
    });
  }

  render() {
    return (
      <div className="activity-subaction subaction-list-container">
        <h5>Task Activity</h5>
        <div className="task-activity-list-outer">
          {!this.state.isLoading ? (
            <ChangeTaskFilter
              headerTitle="Task  Filter"
              title="Filter Task"
              emptyText="Showing All Tasks"
              tasks={this.state.filterTasks}
              onChange={(e) => this._handleFilterChange(e)}
            />
          ) : undefined}

          <TaskActivityList
            loading={this.state.loading}
            activityEntries={this.state.activityEntries}
            handleScroll={() => this._handleScroll()}
            handleSelectActivity={(unitTag) => this._handleSelectActivity(unitTag)}
            selected={this.state.selected}
            loadingScroll={this.state.loadingScroll}
            ref={this.taskActivityListRef}
          />
        </div>

        {this.state.activityEntries && this.state.activityEntries.length === 0 ? (
          <div className="narrow-panel-empty-list panel-sizing">
            <img src={'/assets/images/activity_panel_info.svg'} />
            <span className="empty-title">No Task Activity Found</span>
          </div>
        ) : undefined}

        {this.state.loading ? (
          <div className="loading">
            <CircularProgress mode="indeterminate" size={17.85} />
          </div>
        ) : undefined}
      </div>
    );
  }
}
