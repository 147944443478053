export const nncContentHasChanged = (oldNNC: string, newNNC: string): boolean => {
  const oldNNCHtml = document.createElement('template');
  const newNNCHtml = document.createElement('template');
  oldNNCHtml.innerHTML = oldNNC;
  newNNCHtml.innerHTML = newNNC;

  oldNNCHtml.content.querySelector('.arc-tocable-heading')?.remove();
  newNNCHtml.content.querySelector('.arc-tocable-heading')?.remove();

  return oldNNCHtml.content.firstElementChild?.innerHTML !== newNNCHtml.content.firstElementChild?.innerHTML;
};
