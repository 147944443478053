import { AppThunk } from '../../../appStore';
import { SystemAlertActionTypes } from './types';
import { HideSystemAlertAction, ShowSystemAlertAction } from './actions';
import { AlertPayload } from './reducers';

export function showSystemAlert(payload: AlertPayload): AppThunk<SystemAlertActionTypes> {
  return async (dispatch) => {
    return dispatch(ShowSystemAlertAction(payload));
  };
}

export function hideSystemAlert(): AppThunk<SystemAlertActionTypes> {
  return async (dispatch) => {
    return dispatch(HideSystemAlertAction());
  };
}
