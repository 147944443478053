import * as React from 'react';

export type Props = {
  title?: string;
  id?: string;
  inlineMenu?: null | string;
  enabled?: boolean;
  onToggled?: (opened: boolean, ref: React.RefObject<HTMLDivElement>) => void;
  onInlineMenuClick?: (e: React.MouseEvent<HTMLElement>) => void;
  defaultOpen?: boolean;
  forceClose?: boolean;
  forceOpen?: boolean;
  expandAll?: boolean;
  contentContainerStyle?: React.CSSProperties;
  nestedToggle?: boolean;
  dataQa?: string;
  classnames?: string[];
  children?: React.ReactNode;
};

export type State = {
  opened: boolean;
};

/* Usage: wrap any dom like this
 * <ToggleSection title="My Title"> my html </ToggleSection>
 * also parent container of any sections you have must contain class: "toggle-section-outer"
 */
export default class ToggleSection extends React.Component<Props, State> {
  private ref = React.createRef<HTMLDivElement>();

  static defaultProps: Partial<Props> = {
    title: '',
    id: '',
    inlineMenu: null,
    enabled: true,
    defaultOpen: true,
    forceClose: false,
    expandAll: false,
    forceOpen: false,
    classnames: []
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      opened: this.props.defaultOpen! || (this.props.forceOpen! && !this.props.forceClose!)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceOpen || nextProps.expandAll) {
      this.setState({ opened: true });
    } else if (nextProps.forceClose && !nextProps.forceOpen) {
      this.setState({ opened: false });
    } else if (!nextProps.expandAll && this.props.expandAll) {
      this.setState({ opened: false });
    }
  }

  public close() {
    this.setState({ opened: false });
  }

  private _toggle() {
    this.setState({ opened: !this.state.opened }, () => {
      if (this.props.onToggled) {
        this.props.onToggled(this.state.opened, this.ref);
      }
    });
  }

  render() {
    let inlineMenu: JSX.Element | null = null;
    const iconClassName = 'toc-bullet ' + (this.state.opened ? 'icon-menu-bullet-opened' : 'icon-menu-bullet-closed');
    if (this.props.enabled) {
      inlineMenu = this.props.inlineMenu ? (
        <i
          className="material-icons toggle-inline-menuicon"
          onClick={(e) => {
            if (this.props.onInlineMenuClick) {
              this.props.onInlineMenuClick(e);
            }
          }}
        >
          {this.props.inlineMenu}
        </i>
      ) : null;
    }

    return (
      <div
        className={`toggle-section ${!this.props.enabled ? 'disabled' : ''} ${this.state.opened ? 'opened' : 'closed'} ${
          this.props.nestedToggle ? 'nested-toggle' : ''
        }`}
        ref={this.ref}
        id={this.props.id}
        data-qa={this.props.dataQa}
      >
        <h1 onClick={() => this._toggle()} className={this.props.classnames?.join(',') ?? ''}>
          {this.props.title}
          {inlineMenu}
          <span className={iconClassName} data-qa="toggle-section-toc-bullet" />
        </h1>
        {this.state.opened ? this.props.children : null}
      </div>
    );
  }
}

export function withToggleSection<T>(Component: React.ComponentType<T>): React.ComponentType<T & Props> {
  return function WithToggleSection(props: Props) {
    const {
      defaultOpen,
      id,
      title,
      enabled,
      inlineMenu,
      onToggled,
      onInlineMenuClick,
      forceClose,
      forceOpen,
      expandAll,
      contentContainerStyle,
      nestedToggle,
      dataQa,
      classnames,
      ...rest
    } = props;

    return (
      <ToggleSection
        title={title}
        id={id}
        classnames={classnames}
        defaultOpen={defaultOpen}
        enabled={enabled}
        inlineMenu={inlineMenu}
        onToggled={onToggled}
        onInlineMenuClick={onInlineMenuClick}
        forceClose={forceClose}
        forceOpen={forceOpen}
        expandAll={expandAll}
        nestedToggle={nestedToggle}
        dataQa={dataQa}
      >
        <div className="toggle-section-content margin-m" style={contentContainerStyle || { paddingLeft: 29 }}>
          <Component {...(rest as T & Props)} />
        </div>
      </ToggleSection>
    );
  };
}
