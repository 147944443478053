import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import TagField from '../../../general/tag-field/TagField';
import { ITag } from 'mm-types';
import { SearchParams } from './SearchSharedLibContainer';

export type Props = {
  onSearch: (e: Partial<SearchParams>) => void;
  onClose: () => void;
  contentTags: ITag[];
  contentTagsSelected: ITag[];
};

export type State = {
  contentTags: ITag[];
  contentSearchTags: ITag[];
  teamspaceTags: ITag[];
  teamspaceSearchTags: ITag[];
};

export default class SearchSharedLibPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contentTags: props.contentTagsSelected ? props.contentTagsSelected : [],
      contentSearchTags: [],
      teamspaceTags: [],
      teamspaceSearchTags: []
    };
  }

  static defaultProps: Partial<Props> = {
    contentTags: [],
    contentTagsSelected: []
  };

  componentDidMount() {
    (this.refs.contentTagsField as TagField).openPanel();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.contentTagsSelected) {
      this.setState({ contentTags: nextProps.contentTagsSelected });
    }
  }

  _onContentChange(newTags: ITag[]) {
    this.setState({ contentTags: newTags }, () => {
      this._search();
    });
  }

  _getContentSearchTags(searchText: string) {
    const result = this.props.contentTags.filter((tag) => {
      return tag.value.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0;
    });
    return result;
  }

  _onContentSearch(searchText: string) {
    this.setState({ contentSearchTags: this._getContentSearchTags(searchText) });
  }

  // _onTeamspaceChange( newTags ) {
  //   this.setState( { teamspaceTags: newTags } );
  // }

  // _getTeamspaceSearchTags( searchText ) {
  //   // Hardcoded API call to search for existing tags
  //   let result = _allTeamspaces.filter( ( tag ) => {
  //     return tag.title.toLowerCase().indexOf( searchText.trim().toLowerCase() ) >= 0;
  //   } );
  //   return result;
  // }

  // _onTeamspaceSearch( searchText ) {
  //   this.setState( { teamspaceSearchTags: this._getTeamspaceSearchTags( searchText ) } );
  // }
  // end of an example of how tags should be handled

  _search() {
    this.props.onSearch({ contentTags: this.state.contentTags });
  }

  _clear() {
    this.props.onClose();
  }

  render() {
    return (
      <div className="sub-panel shared-content__search-panel">
        <div className="shared-content-search__item shared-content-search__item--content">
          <div className="shared-content-search__label">
            has: <span className="shared-content-search__label--highlight">content tags</span>
          </div>

          <TagField
            placeholder="Content Tags"
            type="CONTENT"
            valueField="value"
            allowAdd={true}
            allowDelete={true}
            showTags={this.state.contentTags}
            searchTags={this.state.contentSearchTags}
            onSearch={(text) => this._onContentSearch(text)}
            onChange={(e) => this._onContentChange(e)}
            ref="contentTagsField"
          />
        </div>

        <div className="shared-content-search__item shared-content-search__item--actions">
          <FlatButton label="CLEAR" primary={true} keyboardFocused={false} onClick={() => this._clear()} ref="clear" />

          <FlatButton label="SEARCH" primary={true} keyboardFocused={false} onClick={() => this._search()} ref="search" />
        </div>
      </div>
    );
  }
}
