import { MetadataValue, NNCIndexMetadataID, NNCIndexMetadataValues, NNCIndexMetadataWithHeaderDefinition } from './NNCIndexMetadataTypes';
import { NNC_INDEX_METADATA_DEFINITION } from '.';
import Log from '../../../../../utils/Log';
import { IElementDefinition } from 'mm-types';
import { DomAssertions } from '../../tinyFacade/DomAssertionsUtil';

const DEFAULT_TITLE_VALUE: MetadataValue<NNCIndexMetadataID.TITLE> = {
  eicas: false,
  fontbold: false,
  fontlarge: false,
  qai: false,
  unann: false,
  text: ''
};

const DEFAULT_ALT_TITLES_VALUE: MetadataValue<NNCIndexMetadataID.ALT_TITLES> = [{ ...DEFAULT_TITLE_VALUE, hide: false, hidetoc: false }];

export const DEFAULT_METADATA_VALUES: NNCIndexMetadataValues = {
  [NNCIndexMetadataID.TITLE]: { ...DEFAULT_TITLE_VALUE },
  [NNCIndexMetadataID.ALT_TITLES]: { ...DEFAULT_ALT_TITLES_VALUE },
  [NNCIndexMetadataID.LIGHTS]: [],
  [NNCIndexMetadataID.SYMBOL]: { value: 'null' },
  [NNCIndexMetadataID.EFFECTIVITY]: [],
  [NNCIndexMetadataID.FOREIGN_ENTRY]: []
};

const METADATA_SELECTOR = '.arc-checklist-content';
const HEADER_SELECTOR = '.arc-tocable-heading';

export class NNCIndexMetadataSerializer {
  static toHTML(
    metadata: NNCIndexMetadataValues,
    definitions: IElementDefinition[],
    $unitHtml: JQuery<HTMLElement>
  ): { html: string; $html: JQuery<HTMLElement> } {
    const $html = $unitHtml.clone();

    $html.find(METADATA_SELECTOR).html(this.toMetadataHtml(metadata));
    $html.find(HEADER_SELECTOR).html(this.toHeaderHtml(metadata, definitions));

    return {
      html: $html[0].outerHTML,
      $html
    };
  }

  private static toMetadataHtml(metadata: NNCIndexMetadataValues): string {
    let result = '';
    Object.keys(metadata).forEach((id) => {
      result += NNC_INDEX_METADATA_DEFINITION[id].toMetadataHTML(metadata[id] as NNCIndexMetadataValues[NNCIndexMetadataID]);
    });
    return result;
  }

  private static toHeaderHtml(metadata: NNCIndexMetadataValues, definitions: IElementDefinition[]): string {
    const headerParts: NNCIndexMetadataID[] = [
      NNCIndexMetadataID.SYMBOL,
      NNCIndexMetadataID.TITLE,
      NNCIndexMetadataID.ALT_TITLES,
      NNCIndexMetadataID.EFFECTIVITY
    ];
    return headerParts
      .map((id): string => {
        return (NNC_INDEX_METADATA_DEFINITION[id] as NNCIndexMetadataWithHeaderDefinition<NNCIndexMetadataID>).toHeaderHTML(
          metadata[id],
          definitions
        );
      })
      .join('');
  }

  static toJSON($rootElement: JQuery<HTMLElement>): NNCIndexMetadataValues {
    const element = $rootElement.find(METADATA_SELECTOR)[0];
    const data: NNCIndexMetadataValues = { ...DEFAULT_METADATA_VALUES };

    if (DomAssertions.hasChildNodes(element)) {
      element.childNodes.forEach((childNode) => {
        if (DomAssertions.isHTMLElement(childNode)) {
          const id = childNode.className;
          try {
            const definition = NNC_INDEX_METADATA_DEFINITION[id];
            if (definition.toJSON) {
              data[id] = definition.toJSON($(childNode));
            }
          } catch (error) {
            Log.error(`Cannot find NNC index metadata definition for id: ${id}`);
          }
        }
      });
    } else {
      Log.error('Cannot find any NNC index metadata nodes for arc-checklist-content node or arc-checklist-content node doesnt exist');
    }
    return data;
  }
}
