import * as React from 'react';
export type Props = {};

export type State = {};

export default class PowerPasteHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <nav className="page-body-header icon-header">
        <div className="nav-wrapper">
          <h1>Insert from external source (Open Office)</h1>
        </div>
      </nav>
    );
  }
}
