import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import * as _ from 'lodash';
import { IElementDefinition } from 'mm-types';

export type EffectivityValue = { text: string; type: string };
export type EffectivityValues = EffectivityValue[];

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.EFFECTIVITY> = {
  id: NNCIndexMetadataID.EFFECTIVITY,
  toMetadataHTML: (values) => {
    let effectivities = '';
    values.forEach(({ text, type }) => {
      effectivities += `<div class="effectivity" type="${type}">${text}</div>`;
    });
    return `<div class="${metadataDefinition.id}">${effectivities}</div>`;
  },
  toHeaderHTML: (values, definitions) => {
    let output = '';
    if (!definitions || !definitions.length) {
      throw 'No Effectivity tag definitions';
    }
    if (!values.length) {
      return '';
    }
    for (let i = 0, max = values.length; i < max; i++) {
      const definition: IElementDefinition | undefined = _.find(definitions, (def) => def.subType === values[i].type);
      if (definition) {
        output += ' ' + $(definition.templateHtml).text(values[i].text)[0].outerHTML;
      }
    }
    return output;
  },
  toJSON: ($element) => {
    const result: EffectivityValues = [];
    $element.children().each(function () {
      const $el = $(this);
      result.push({
        text: $el.text(),
        type: $el.attr('type')!
      });
    });
    return result;
  }
};
