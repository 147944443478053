import { mm, Cancelled } from './base-clients';
import { IIndexMergeActivity } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import { dateUtil } from '../utils';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

let getAllSource: CancelTokenSource | null = null;

export type MergeResponse = {
  activities: IIndexMergeActivity[];
};

export async function getAll(projectUid: string, indexUid: string, userUid: string): Promise<Cancelled | IIndexMergeActivity[]> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ activities: IIndexMergeActivity[] }>(
      `/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge`,
      {
        cancelToken: getAllSource.token
      }
    );

    return parse(response.data);
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Gets one
 */
export async function getOne(
  projectUid: string,
  indexUid: string,
  userUid: string,
  activityUid: string
): Promise<Cancelled | IIndexMergeActivity> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IIndexMergeActivity>(
      `/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge/activities/${activityUid}`,
      {
        cancelToken: getAllSource.token
      }
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * creates a index merge
 */
export async function create(projectUid: string, indexUid: string, userUid: string, activtyEntry?: IIndexMergeActivity) {
  const response = await mm.post<{ activities: IIndexMergeActivity[] }>(
    `/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge`,
    activtyEntry
  );
  return parse(response.data);
}

/**
 * creates a index activity
 */
export async function createActivity(
  projectUid: string,
  indexUid: string,
  userUid: string,
  data: {
    uid: string;
    isAccept: boolean;
    isReject: boolean;
  }
) {
  const response = await mm.post<IIndexMergeActivity>(
    `/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge/activities/${data.uid}/${data.isAccept ? 'accept' : 'reject'}`,
    data
  );
  return response.data;
}

/**
 * updates a index merge
 */
export async function update(projectUid: string, indexUid: string, userUid: string, activtyEntry: IIndexMergeActivity) {
  const response = await mm.put(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge`, {
    uid: activtyEntry.activityUid,
    isAccept: false,
    isReject: false
  });
  return response.data;
}

/**
 * deletes a index merge
 */
export async function deleteMerge(projectUid: string, indexUid: string, userUid: string) {
  const response = await mm.delete(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/merge`);
  return response.data;
}

function parse(r: MergeResponse) {
  if (r && r.activities) {
    let firstMergeableFound = false;

    r.activities.forEach((activity, i) => {
      if (!firstMergeableFound && activity.status === 'UNMERGED') {
        activity.isMergable = true;
        firstMergeableFound = true;
      } else {
        activity.isMergable = false;
      }

      const title = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
        activity.unitActivity.entityType
      )?.displayName;
      activity.formatted = dateUtil(activity.unitActivity.timestamp).formatDateTimeNoSecs();

      switch (activity.operation) {
        case 'ADDED_UNIT':
          activity.colorClass = 'activity-create';
          activity.color = '#c2f28d';
          activity.icon = 'create';
          activity.display = 'Created: ' + title;
          break;
        case 'UPDATED_UNIT':
          activity.colorClass = 'activity-update';
          activity.color = '#e98316';
          activity.icon = 'update';
          activity.display = 'Update: ' + title;
          break;
        case 'DELETED_UNIT':
          activity.colorClass = 'activity-delete';
          activity.color = '#ffd3d9';
          activity.icon = 'delete';
          activity.display = 'Delete: ' + title;
          break;
        case 'TOCABLE_UNIT_PROMOTED':
          activity.colorClass = 'activity-promote';
          activity.color = '#e98316';
          activity.icon = 'promote';
          activity.display = 'Heading Promotion: ' + title;
          break;
        case 'TOCABLE_UNIT_DEMOTED':
          activity.colorClass = 'activity-demote';
          activity.color = '#e98316';
          activity.icon = 'demote';
          activity.display = 'Heading Demotion: ' + title;
          break;
        case 'TOCABLE_UNIT_MOVED':
          activity.colorClass = 'activity-moved';
          activity.color = '#e98316';
          activity.icon = 'update';
          activity.display = 'Update: ' + title;
          break;
        case 'UNIT_CONCEPT_TAGGED':
          activity.colorClass = 'activity-update';
          activity.color = '#e98316';
          activity.icon = 'update';
          activity.display = 'Variant Added: ' + title;
          activity.unitActivity.description = JSON.parse(activity.unitActivity.description).text;
          break;
        case 'UNIT_CONCEPT_UNTAGGED':
          activity.colorClass = 'activity-update';
          activity.color = '#e98316';
          activity.icon = 'update';
          activity.display = 'Variant Removed: ' + title;
          activity.unitActivity.description = JSON.parse(activity.unitActivity.description).text;
          break;
      }
    });

    return r.activities;
  } else {
    return [];
  }
}
