import React from 'react';
import { IconButton, Popover } from 'material-ui';
import { MaterialIcon } from '../../general/MaterialIcon';

type Props = {
  id: string;
  tooltip?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  popoverTooltip?: boolean;
  selectedMenuItem?: EventTarget | null;
};

type State = {
  open: boolean;
  anchorEl?: React.ReactInstance;
};

export const PrimaryTextWithTooltip = (props: Props) => {
  React.useEffect(() => {
    !props?.selectedMenuItem && setState({ open: false });
  }, [props?.selectedMenuItem]);

  const [state, setState] = React.useState<State>({
    open: false
  });

  const showTooltip = (e: React.MouseEvent<{}, MouseEvent>) => {
    setState({ open: !state.open, anchorEl: e.currentTarget as React.ReactInstance });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="display-flex rhs-enum-primary-text">
      <span style={{ flex: 1 }} data-qa={props.id}>
        {props?.children}
      </span>
      {!!props.tooltip && !props.popoverTooltip && (
        <IconButton
          className={`${props.className ?? ''} description-button position-relative`}
          style={{ padding: 0, height: '24px', width: '24px' }}
          tooltip={props.tooltip ? <div style={{ width: '200px', whiteSpace: 'normal', textAlign: 'left' }}>{props.tooltip}</div> : null}
          tooltipPosition="top-left"
        >
          <MaterialIcon className="material-icons" iconName="info_outline" />
        </IconButton>
      )}
      {!!props.tooltip &&
        !!props.popoverTooltip && [
          <IconButton
            key={'iconbutton_' + props.id}
            className={`${props.className ?? ''} description-button position-relative`}
            style={{ padding: 0, height: '24px', width: '24px' }}
            onMouseEnter={showTooltip}
            onMouseLeave={() => setState({ open: false })}
          >
            <MaterialIcon className="material-icons" iconName="info_outline" />
          </IconButton>,
          <Popover
            key={'popover_' + props.id}
            open={state.open}
            anchorEl={state.anchorEl}
            canAutoPosition
            onRequestClose={() => setState({ open: false })}
            useLayerForClickAway={true}
            className={'tooltip popover-tooltip-text'}
          >
            {props.tooltip}
          </Popover>
        ]}
    </div>
  );
};
