import { mm, Cancelled } from './base-clients';
import { IFrontMatter, IToc, ITocNode } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;

export type GetAllOptions = {
  includeGraphics: boolean;
  includeTables: boolean;
  includeParagraphs: boolean;
  includeCaptionUnits: boolean;
  includeChapterFrontmatter: boolean;
  depth: number;
  variant: string | null;
};

export type GetDiffOptions = {
  diffTimestamp: string;
  diffIndexUid: string;
  depth: number;
};

// TODO @HeaderParam(VARIANT_HEADER) String vuid,

export function concatChildrenIds(items: ITocNode[]) {
  function _concatChildrenIds(parent: null | ITocNode, items: ITocNode[]) {
    return items.map(function (item) {
      item.uidnid = item.uid + (item.elementNid ? item.elementNid : '');
      item.parent = parent;
      if (item.children) {
        item.children = _concatChildrenIds(item, item.children);
      }

      return item;
    });
  }

  return _concatChildrenIds(null, items);
}

export async function getToc(options: Partial<GetAllOptions>, projectUid: string, indexUid: string): Promise<IToc | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];

  if (options.includeChapterFrontmatter) {
    query.push('includeChapterFrontmatter=' + options.includeChapterFrontmatter.toString());
  }
  if (options.includeGraphics) {
    query.push('includeGraphics=' + options.includeGraphics.toString());
  }
  if (options.includeTables) {
    query.push('includeTables=' + options.includeTables.toString());
  }
  if (options.includeParagraphs) {
    query.push('includeParagraphs=' + options.includeParagraphs.toString());
  }
  if (options.includeCaptionUnits) {
    query.push('includeCaptionUnits=' + options.includeCaptionUnits.toString());
  }
  if (options.depth) {
    query.push('depth=' + options.depth.toString());
  }

  const headers: any = {};
  if (options.variant) {
    headers['X-Index-Variant'] = options.variant;
  }

  try {
    const response = await mm.get<IToc>(`/projects/${projectUid}/indexes/${indexUid}/metadata/toc?${query.join('&')}`, {
      headers: headers
    });
    getAllSource = null;
    const toc = response.data;
    parse(toc);

    return toc;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getTocDiff(options: Partial<GetDiffOptions>, projectUid?: string, indexUid?: string) {
  const query: string[] = [];

  if (options.diffTimestamp) {
    query.push('diffTimestamp=' + options.diffTimestamp.toString());
  }
  if (options.diffIndexUid) {
    query.push('diffIndexUid=' + options.diffIndexUid.toString());
  }
  if (options.depth) {
    query.push('depth=' + options.depth.toString());
  }

  const response = await mm.get<{ toc: IToc; tocDiffMap: { [id: string]: number } }>(
    `/projects/${projectUid}/indexes/${indexUid}/metadata/toc/diff?${query.join('&')}`
  );

  parse(response.data.toc);
  return response.data;
}

function parse(toc: IToc) {
  if (toc.appendices) {
    toc.appendices.forEach((appendix) => {
      appendix.type = 'appendix-chapter';
    });
  }
}

export function getChildren(toc: IToc) {
  let children: ITocNode[] = [];
  let frontmatter: IFrontMatter | null = null;
  const appendices = concatChildrenIds(toc.appendices) || [];

  let newToc: IToc = toc;

  if (!!toc.movableFrontmatter && toc.children[0].subType == 'frontmatter-placeholder') {
    frontmatter = newToc.children.splice(0, 1)[0] as IFrontMatter;
  }

  if (toc.singleVolume) {
    const child = newToc.children[0];
    children = child ? child.children : [];
    children = concatChildrenIds(children);
  } else {
    children = concatChildrenIds(newToc.children || []);
  }

  return { appendices, children, frontmatter };
}

export function deleteOrdinableUnit(projectUid: string, indexUid: string, ordinableUnitUid: string) {
  return mm.delete(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}`);
}

export async function promoteOrdinableUnit(projectUid: string, indexUid: string, ordinableUnitUid: string) {
  return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/promote`);
}

export async function demoteOrdinableUnit(projectUid: string, indexUid: string, ordinableUnitUid: string) {
  return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/demote`);
}

export async function changeOrdinableUnitTypes(projectUid: string, indexUid: string, ordinableUnitUid: string, unitType: string) {
  return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/changeTypes?unitType=` + unitType);
}

export async function getTocSharedOrigins(projectUid: string, indexUid: string, ordinableUnitUid: string) {
  const res = await mm.get<number>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/sharedContent`);
  return res.data;
}

export async function moveOrdinableUnit(
  projectUid: string,
  indexUid: string,
  ordinableUnitUid: string,
  dest: { beforeTocableUnitUid?: string; afterTocableUnitUid?: string; selected?: ITocNode | null }
) {
  const query: string[] = [];
  if (dest.afterTocableUnitUid) {
    query.push('afterTocableUnitUid=' + dest.afterTocableUnitUid);
  }
  if (dest.beforeTocableUnitUid) {
    query.push('beforeTocableUnitUid=' + dest.beforeTocableUnitUid);
  }

  if (dest?.selected?.type == 'frontmatter') {
    return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${dest.selected.uidnid}/move?${query.join('&')}`);
  } else {
    return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/move?${query.join('&')}`);
  }
}

export async function copyOrdinableUnit(
  projectUid: string,
  indexUid: string,
  ordinableUnitUid: string,
  dest: { beforeTocableUnitUid?: string; afterTocableUnitUid?: string }
) {
  const query: string[] = [];
  if (dest.afterTocableUnitUid) {
    query.push('afterTocableUnitUid=' + dest.afterTocableUnitUid);
  }
  if (dest.beforeTocableUnitUid) {
    query.push('beforeTocableUnitUid=' + dest.beforeTocableUnitUid);
  }

  return mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/ordinableUnits/${ordinableUnitUid}/copy?${query.join('&')}`);
}
