import { keyBehavior as noteKeyBehavior } from '../../../units/unit/note';
import * as React from 'react';

export interface ElementKeyBehavior {
  run(element: Element, event: React.KeyboardEvent): any;
  test(element: Element): boolean;
}

const availableBehaviors: ElementKeyBehavior[] = [noteKeyBehavior];
export function checkElementKeyBehaviors(element: Element, event: React.KeyboardEvent) {
  availableBehaviors.forEach((behavior) => {
    if (behavior.test(element)) {
      behavior.run(element, event);
    }
  });
}
