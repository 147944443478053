import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import ActiveEditorPopup, { ActiveEditorPopupType } from './components/ActiveEditorPopup';
import Avatar from 'material-ui/Avatar';
import { IEditorStoreEvent, EventStoreEventType } from 'mm-types';
import useListenToStore from '../../hooks/useListenToStore';
import { ActiveEditorType } from './components/ActiveEditorDetails';

export type State = {
  activeEditors: ActiveEditorType[];
  activeEditingCount: number;
  loading: boolean;
};

const ActiveEditors = () => {
  const activeEditorPopup = React.useRef<ActiveEditorPopupType>(null);
  const activeEditorAvatarGroup = React.useRef<Avatar>(null);
  const activeEditorAvatar = React.useRef<Avatar[]>([]);

  const [state, setState] = React.useState<State>({
    activeEditors: [],
    activeEditingCount: 0,
    loading: true
  });
  useListenToStore({ store: EditorStore, eventListener: onEditorEvent });

  function onEditorEvent(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'activeEditorChange') {
      setState((prevState) => ({
        ...prevState,
        loading: false
      }));
      const activeEditors = EditorStore.getActiveEditors();
      setState((prevState) => ({
        ...prevState,
        activeEditors: activeEditors.list,
        activeEditingCount: activeEditors.authorCount
      }));
    }
  }

  const getEditorCounts = () => {
    // TODO use new intl lib for this ;)
    let AUTHOR = 'Author',
      AUTHOR_PLURAL = 'Authors',
      REVIEWER = 'Reviewer',
      REVIEWER_PLURAL = 'Reviewers',
      countMessage = '',
      reviewersCount = state.activeEditors.length - state.activeEditingCount;

    if (state.activeEditingCount) {
      countMessage = state.activeEditingCount + ' ' + (state.activeEditingCount > 1 ? AUTHOR_PLURAL : AUTHOR);
    }

    if (reviewersCount) {
      countMessage += (state.activeEditingCount ? ' | ' : '') + reviewersCount + ' ' + (reviewersCount > 0 ? REVIEWER : REVIEWER_PLURAL);
    }

    return countMessage;
  };

  const openPopup = (activeEditor: ActiveEditorType | null, targetRef: React.ReactInstance) => {
    if (!activeEditorPopup.current?.getPopup()?.isOpen()) {
      activeEditorPopup.current?.showEditor(activeEditor);
      activeEditorPopup.current?.open(targetRef);
    }
  };

  const { activeEditors, loading } = state;

  return (
    <div className="activeeditors-list-container">
      {loading && <Avatar className="popup-avatar" />}
      {activeEditors.length > 1 ? (
        <Avatar
          className="popup-avatar"
          src="/assets/images/user_avatars/user_group_icon.svg"
          onClick={() => activeEditorAvatarGroup.current && openPopup(null, activeEditorAvatarGroup.current)}
          ref={activeEditorAvatarGroup}
        />
      ) : undefined}

      {activeEditors.length <= 1 &&
        !loading &&
        activeEditors.map((activeEditor, activeEditorIndex) => {
          return (
            <Avatar
              key={activeEditorIndex}
              className="popup-avatar"
              src={activeEditor.avatarUrl}
              ref={(ref) => ref && activeEditorAvatar.current?.push(ref)}
              onClick={() => openPopup(activeEditor, activeEditorAvatar.current[activeEditorIndex])}
            />
          );
        })}
      <div className="popup-counts">{getEditorCounts()}</div>
      <ActiveEditorPopup ref={activeEditorPopup} activeEditors={state.activeEditors} />
    </div>
  );
};

export default ActiveEditors;
