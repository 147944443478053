import React from 'react';
import Tooltip from 'material-ui/internal/Tooltip';

const MemoTooltip = React.memo(Tooltip);

const defaultStyles = {
  detailItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  detailHeader: {
    fontSize: '12px',
    marginBottom: '4px',
    color: '#616061'
  },
  detailValue: {
    marginBottom: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
    paddingRight: '15%',
    maxWidth: '100%',
    textAlign: 'left'
  }
};

const WorkflowActionItemDetail = ({
  title,
  value,
  dataQa,
  isOpen
}: {
  title: string;
  value?: string | number;
  dataQa: string;
  isOpen: boolean;
}) => {
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const tooltipRef = React.useRef<Tooltip | null>(null);
  const [show, setShow] = React.useState<boolean>(false);

  return (
    <div style={{ ...(defaultStyles.detailItem as React.CSSProperties), width: title === 'WFS USER COMMENT' ? '20%' : '25%' }}>
      <span style={{ ...defaultStyles.detailHeader }}>{title}</span>
      <span
        style={{
          ...(defaultStyles.detailValue as React.CSSProperties),
          whiteSpace: isOpen ? undefined : 'nowrap',
          maxHeight: title === 'WFS USER COMMENT' ? 241 : 'inherit',
          overflow: title === 'WFS USER COMMENT' && isOpen ? 'scroll' : 'hidden'
        }}
        data-qa={dataQa}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        ref={spanRef}
      >
        {!value || value === '' ? '-' : value}
      </span>
      {spanRef.current && spanRef.current.clientWidth < spanRef.current.scrollWidth && !isOpen && (
        <span style={{ position: 'relative', width: 1, height: 1 }}>
          <MemoTooltip
            label={value}
            show={show}
            style={{
              fontSize: 10,
              lineHeight: '22px',
              padding: '0 8px',
              boxSizing: 'border-box',
              marginLeft: -22,
              top: -40
            }}
            ref={tooltipRef}
          />
        </span>
      )}
    </div>
  );
};

export default WorkflowActionItemDetail;
