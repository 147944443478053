import { Cancelled, mm } from './base-clients';
import { IActionDocTeam, IActionResponse, IActivity, IActivityDescription, IProjectActivity, IUnit, TaskAuthor } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import UnitSpecialInterestTagStore from '../flux/editor/UnitSpecialInterestTagStore';
import affectedPart from '../components/editor/utils/units/AffectedPartUtil';
import dateUtil from '../utils/DateUtil';
import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

let getAllSource: CancelTokenSource | null = null;
let getActionsSource: CancelTokenSource | null = null;

export type GetOptions = {
  pageSize: number;
  includeChildren: boolean;
  unitUid: string;
  operation: '';
  cursor: string | null;
};

export type ProjectActivitiesOptions = {
  indexUid: string;
  taskUid: string | null;
  filter: string[];
  pageSize: number;
  cursor: string | null;
};

export type IGetAllResponse = {
  activityEntries: IActivity[];
  previousCursor: string;
  nextCursor: string;
};

export type IProjectActivitiesResponse = {
  activities: IProjectActivity[];
  previousCursor: string;
  nextCursor: string;
};

/**
 * Gets all the activities for a project
 */
export async function getAllProjectActivities(
  projectUid: string,
  options?: Partial<ProjectActivitiesOptions>
): Promise<Cancelled | IProjectActivitiesResponse> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];
  query.push('pageSize=' + (options && options.pageSize ? options.pageSize.toString() : '40'));

  if (options && options.indexUid) {
    if (options.indexUid === 'docLevel') {
      query.push('indexUid=');
    } else if (options.indexUid !== '-1') {
      query.push('indexUid=' + options.indexUid);
    }
  }

  if (options && options.taskUid) {
    query.push('taskUid=' + options.taskUid);
  }
  if (options && options.filter) {
    query.push(options.filter.map((f) => 'filter=' + f).join('&'));
  }
  if (options && options.cursor) {
    query.push('cursor=' + options.cursor);
  }

  try {
    const response = await mm.get<IProjectActivitiesResponse>(`/projects/${projectUid}/activities?` + query.join('&'), {
      cancelToken: getAllSource.token
    });

    getAllSource = null;

    const logs = response.data.activities;
    for (let i = 0; i < logs.length; i++) {
      if (logs[i].projectActivityType === 'WORKFLOW_TRANSITION') {
        if (logs[i + 1] && logs[i + 1].projectActivityType === 'WORKFLOW_REVIEW') {
          logs[i].workflowTransition.reviewResult = logs[i + 1].assignment.reviewResult;
        }

        if (!logs[i].workflowTransition.activeStageTitle) {
          logs[i].WorkflowFinished = true;
        }

        if (!logs[i].workflowTransition.fromStageTitle) {
          logs[i].WorkflowStarted = true;
        }
      }
    }

    for (const activity of response.data.activities) {
      parseAdminActivity(activity);
    }

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function saveAction(projectUid: string, indexUid: string, userUid: string, activityUid?: string) {
  const query: string[] = [];

  if (activityUid) {
    query.push('activityUid=' + activityUid);
  }

  const response = await mm.post<{ units: IUnit[] }>(
    `/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/actions/goto?` + query.join('&')
  );
  const affected = affectedPart.affected(response.headers);
  return { ...affected, units: response.data.units };
}

export async function getActions(projectUid: string, indexUid: string, userUid: string): Promise<Cancelled | IActionResponse> {
  if (getActionsSource) {
    getActionsSource.cancel();
  }

  getActionsSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IActionResponse>(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/actions`, {
      cancelToken: getActionsSource.token
    });

    getActionsSource = null;
    parseActions(response.data);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

function parseActions(r: IActionResponse) {
  if (r.activityEntries !== undefined) {
    for (let i = r.activityEntries.length - 1; i >= 0; i--) {
      if (r.activityEntries.length > 0 && r.activityEntries[i].uid === r.pointer) {
        for (let x = i - 1; x >= 0; x--) {
          r.activityEntries[x].unDone = true;
          r.activityEntries[x].redo = 'redo';
        }
      }
      r.activityEntries[i].formatted = dateUtil(r.activityEntries[i].timestamp).formatDateTime();

      const title: string | null | undefined = r.activityEntries[i].entityType
        ? ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(r.activityEntries[i].entityType)
            ?.displayName
        : null;
      r.activityEntries[i].disablePopup = false;

      switch (r.activityEntries[i].operation) {
        case 'new':
          r.activityEntries[i].icon = 'create';
          r.activityEntries[i].display = 'New Document';
          break;
        case 'added':
          r.activityEntries[i].icon = 'create';
          r.activityEntries[i].display = 'Created: ' + title;
          break;
        case 'readded':
          r.activityEntries[i].icon = 'create';
          r.activityEntries[i].display = 'Recreated: ' + title;
          break;
        case 'updated':
        case 'tocableMove':
          r.activityEntries[i].icon = 'update';
          r.activityEntries[i].display = 'Update: ' + title;
          break;
        case 'deleted':
          r.activityEntries[i].icon = 'delete';
          r.activityEntries[i].display = 'Delete: ' + title;
          break;
        case 'promotion':
          r.activityEntries[i].icon = 'promote';
          r.activityEntries[i].display = 'Heading Promotion';
          break;
        case 'demotion':
          r.activityEntries[i].icon = 'demote';
          r.activityEntries[i].display = 'Heading Demotion';
          break;
        case 'batch_added':
          r.activityEntries[i].icon = 'create';
          r.activityEntries[i].display = 'Multiple Created';
          break;
        case 'batch_deleted':
          r.activityEntries[i].icon = 'delete';
          r.activityEntries[i].display = 'Multiple Deleted';
          break;
        case 'batch_updated':
          r.activityEntries[i].icon = 'update';
          r.activityEntries[i].display = 'Multiple Updated';
          break;
        case 'batch_converted':
          r.activityEntries[i].icon = 'unit-conversion';
          r.activityEntries[i].display = 'Multiple Converted';
          break;
        case 'unit_converted':
          r.activityEntries[i].icon = 'unit-conversion';
          r.activityEntries[i].display = 'Convert';
          break;
        case 'shared_index_inserted':
          r.activityEntries[i].icon = 'create';
          r.activityEntries[i].display = 'Shared Content Inserted';
          break;
        case 'shared_index_removed':
          r.activityEntries[i].icon = 'delete';
          r.activityEntries[i].display = 'Shared Content Deleted';
          break;
        case 'shared_index_updated':
          r.activityEntries[i].icon = 'update';
          r.activityEntries[i].display = 'Shared Content Updated';
          break;

        case 'unit_concept_tagged': {
          const conceptDescription = JSON.parse(r.activityEntries[i].description as any);
          r.activityEntries[i].display = 'Search Tag Added';
          r.activityEntries[i].disablePopup = true;
          (r.activityEntries[i].description as Partial<IActivityDescription>) = { text: '', name: '' };

          if (conceptDescription.concepts.searchTerms.length > 0) {
            r.activityEntries[i].description.text = conceptDescription.text;
            r.activityEntries[i].description.name = conceptDescription.concepts.searchTerms[0].name;
            r.activityEntries[i].icon = 'label';
          } else {
            r.activityEntries[i].display = 'Variant Tag Added';
            r.activityEntries[i].description.text = conceptDescription.text;
            r.activityEntries[i].description.name = conceptDescription.concepts.variants[0].name;
            r.activityEntries[i].icon = 'variant';
          }

          break;
        }

        case 'unit_concept_untagged': {
          const unTagDescription = JSON.parse(r.activityEntries[i].description as any);
          r.activityEntries[i].display = 'Search Tag Removed';
          r.activityEntries[i].disablePopup = true;

          (r.activityEntries[i].description as Partial<IActivityDescription>) = { text: '', name: '' };

          if (unTagDescription.concepts.searchTerms.length > 0) {
            if (r.activityEntries[i].description) {
              r.activityEntries[i].description.text = unTagDescription.text;
              r.activityEntries[i].description.name = unTagDescription.concepts.searchTerms[0].name;
            }
            r.activityEntries[i].icon = 'label-outline';
          } else {
            r.activityEntries[i].display = 'Variant Tag Removed';
            r.activityEntries[i].description.name = unTagDescription.concepts.variants[0].name;
            r.activityEntries[i].icon = 'variant';
          }
          break;
        }

        default:
          r.activityEntries[i].display = 'Unknown operation: ' + title;
          break;
      }
    }

    if (r.pointer === undefined) {
      for (let i = r.activityEntries.length - 1; i >= 0; i--) {
        r.activityEntries[i].unDone = true;
        r.activityEntries[i].redo = 'redo';
      }
    }
  }

  return r.activityEntries;
}

/**
 * Gets all the activities
 */
export async function getAll(projectUid: string, indexUid: string, options?: Partial<GetOptions>): Promise<Cancelled | IGetAllResponse> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];
  query.push('pageSize=' + (options && options.pageSize ? options.pageSize.toString() : '40'));

  if (options && options.includeChildren) {
    query.push('includeChildren=' + options.includeChildren);
  }
  if (options && options.unitUid) {
    query.push('unitUid=' + options.unitUid);
  }
  if (options && options.cursor) {
    query.push('cursor=' + options.cursor);
  }
  if (options && options.operation) {
    query.push('operation=' + options.operation);
  }

  try {
    const response = await mm.get<IGetAllResponse>(`/projects/${projectUid}/indexes/${indexUid}/activities?` + query.join('&'), {
      cancelToken: getAllSource.token
    });

    getAllSource = null;

    for (const activity of response.data.activityEntries) {
      parse(activity);
    }

    if (!response) {
      return new Cancelled();
    }

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getUserEditActions(projectUid: string, indexUid: string, userUid: string, action: 'undo' | 'redo') {
  const response = await mm.post<{ units: IUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/users/${userUid}/actions/${action}`);
  const affected = affectedPart.affected(response.headers);
  return { ...affected, units: response.data.units };
}

export async function getOne(projectUid?: string | null, indexUid?: string | null, activityUid?: string | null) {
  if (projectUid && indexUid && activityUid) {
    const response = await mm.get<IActivity>(`/projects/${projectUid}/indexes/${indexUid}/activities/${activityUid}`);
    const activity = response.data;
    parse(activity);
    return activity;
  } else {
    throw new Error('activities.ts - getOne() - missing param');
  }
}

function parseAdminActivity(log: IProjectActivity) {
  if (log.projectActivityType) {
    log.iconType = 'fontello';
    log.formatted = dateUtil(log.timestamp).formatDateTimeNoSecs();

    if (log.user) {
      log.username = log.user.displayName!;
    } else {
      log.username = '';
    }

    const taskRole = function (participant: TaskAuthor) {
      return participant.author ? 'author' : 'reviewer';
    };

    const getLogDescription = function (docTeam: IActionDocTeam) {
      if (docTeam.user) {
        return docTeam.user.displayName;
      } else if (docTeam.group) {
        return docTeam.group.name;
      } else {
        return 'unknown';
      }
    };

    // Common Workflow props
    if (
      [
        'WORKFLOW',
        'WORKFLOW_TRANSITION',
        'WORKFLOW_REVIEW',
        'WORKFLOW_ASSIGNMENT_ADDED',
        'WORKFLOW_ASSIGNMENT_UPDATE',
        'WORKFLOW_ASSIGNMENT_REMOVED',
        'WORKFLOW_AUTOMATICALLY_SKIPPED'
      ].indexOf(log.projectActivityType) > -1
    ) {
      log.description = dateUtil(log.timestamp).formatDateTimeNoSecs();
      log.expandable = false;
    }

    switch (log.projectActivityType) {
      case 'SETTINGS':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Settings';
        log.expandable = false;
        break;
      case 'INDEX_SETTINGS':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Metadata Settings';
        log.expandable = false;
        break;
      case 'EXT_UPLOAD':
        log.expandable = false;
        log.icon = 'icon-activity-unit-upload';
        log.title = 'Uploads';
        log.iconColor = '#e98316';
        break;
      case 'EXT_DOWNLOAD':
        log.expandable = false;
        log.icon = 'icon-activity-unit-download';
        log.title = 'Downloads';
        log.iconColor = '#e98316';
        break;
      case 'REVISION_SETTINGS':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Revision Settings';
        log.expandable = false;
        break;
      case 'PROJECT_SETTINGS':
        log.icon = 'icon-activity-update';
        log.title = 'Update: General Settings';
        log.expandable = false;
        break;
      case 'PROJECT_CREATED':
        log.icon = 'icon-activity-create';
        log.title = 'Create: Project';
        log.expandable = false;
        break;
      case 'DOC_TEAM':
        log.icon = 'icon-activity-create';
        log.title = 'Add: Doc Team';
        log.expandable = false;
        break;
      case 'DOC_TEAM_MEMBER_ADD':
        log.icon = 'icon-activity-create';
        log.title = 'Add: Doc Team Member';
        log.expandable = false;

        if (log.docTeam) {
          log.description = getLogDescription(log.docTeam);
          log.role = log.docTeam.role.name;
        } else {
          log.description = 'unknown';
        }

        break;
      case 'DOC_TEAM_MEMBER_UPDATE':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Doc Team Member';
        log.expandable = false;
        if (log.docTeam) {
          log.description = getLogDescription(log.docTeam);
          log.role = log.docTeam.role.name;
        } else {
          log.description = 'unknown';
        }
        break;
      case 'DOC_TEAM_MEMBER_DELETE':
        log.icon = 'icon-activity-delete';
        log.title = 'Delete: Doc Team Member';
        log.expandable = false;
        if (log.docTeam) {
          log.description = getLogDescription(log.docTeam);
          log.role = log.docTeam.role.name;
        } else {
          log.description = 'unknown';
        }
        break;
      case 'DOC_TEAM_UPDATE':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Doc Team';
        log.description = '';
        log.expandable = false;
        break;
      case 'APPROVAL_INFO_UPDATE':
        log.icon = 'icon-activity-update';
        log.title = 'Update: Approval Information';
        log.expandable = false;
        break;
      case 'WORKFLOW':
        log.expandable = false;
        log.title = 'Workflow : Stage Action';
        log.icon = 'icon-activity-workflow-approve';
        break;
      case 'WORKFLOW_TRANSITION':
        log.expandable = false;
        if (log.workflowTransition.activeStageTitle) {
          log.title = `Workflow: Stage Transition`;
          log.icon = 'icon-activity-workflow-approve';
        }
        if (!log.workflowTransition.activeStageTitle) {
          log.title = `Workflow: Stage Transition`;
          log.icon = 'icon-activity-workflow-approve';
          log.workflowTransition.activeStageTitle = 'Workflow completed';
        }
        if (log.workflowTransition.reviewResult && log.workflowTransition.reviewResult === 'SKIP') {
          log.icon = 'icon-activity-workflow-skip';
        }
        if (log.workflowTransition.reviewResult && log.workflowTransition.reviewResult === 'REJECT') {
          log.icon = 'icon-activity-workflow-reject';
        }
        if (log.WorkflowStarted) {
          log.icon = 'icon-activity-workflow-start-revision';
          log.title = `Workflow: Started`;
        }
        if (log.WorkflowFinished) {
          log.icon = 'icon-activity-workflow-approve';
          log.title = `Workflow: Completed`;
        }
        break;
      case 'WORKFLOW_REVIEW':
        if (log.assignment.reviewResult === 'SKIP') {
          log.icon = 'icon-activity-workflow-skip';
          log.action = 'Skip';
        } else if (log.assignment.reviewResult === 'REJECT') {
          log.action = log.assignment.previousStageActionTitle;
          log.icon = 'icon-activity-workflow-reject';
        } else {
          log.icon = 'icon-activity-workflow-approve';
          log.action = log.assignment.nextStageActionTitle;
        }
        log.expandable = false;
        log.title = 'Workflow: Stage Action';
        break;
      case 'WORKFLOW_STAGE_SETTINGS_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Workflow Settings Updated';
        break;
      case 'SYSTEM_WORKFLOW_STAGE_SETTINGS_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Workflow Stage Settings Updated';
        break;
      case 'SYSTEM_WORKFLOW_SETTINGS_UPDATED':
        log.expandable = true;
        log.icon = 'icon-activity-update';
        log.title = 'Workflow Settings Updated';
        break;
      case 'WORKFLOW_ASSIGNMENT_ADDED':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        log.title = 'Workflow: User Assigned';
        break;
      case 'WORKFLOW_ASSIGNMENT_UPDATE':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Workflow: User Updated';
        break;
      case 'WORKFLOW_ASSIGNMENT_REMOVED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Workflow: User Removed';
        break;
      case 'WORKFLOW_AUTOMATICALLY_SKIPPED':
        log.expandable = false;
        log.icon = 'icon-activity-workflow-skip';
        log.title = 'Workflow: Stage skipped';
        break;
      case 'WORKFLOW_APPROVE_SKIP':
        log.expandable = false;
        log.icon = 'icon-activity-workflow-approve';
        log.title = 'Workflow: skipped approved';
        break;
      case 'WORKFLOW_STAGE_AUTOMATICALLY_SKIPPED':
        log.expandable = false;
        log.icon = 'icon-activity-workflow-skip';
        log.title = 'Workflow: stage automatically skipped';
        break;
      case 'INDEX_LOCKED_STATUS_UPDATED':
        if (log.indexLockedStatus === 'UNLOCKED') {
          log.title = 'Document: Unlocked by Admin';
          log.icon = 'icon-activity-workflow-unlock';
        } else {
          log.title = 'Document: Locked by Admin';
          log.icon = 'icon-activity-workflow-lock';
        }
        log.description = dateUtil(log.timestamp).formatDateTimeNoSecs();
        log.expandable = false;
        break;
      case 'PUBLICATION':
        log.icon = 'icon-activity-publication';
        log.title = 'Publication';
        log.expandable = false;
        break;

      case 'TASK_ADD':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        if (log.childrenActivities) {
          log.taskNumber = `Task ${log.childrenActivities[0].task.identifier}: `;
          log.taskName = `${log.childrenActivities[0].currentTaskValues.title}`;
          log.taskAction = ` - Added`;
          log.iconColor = log.childrenActivities[0].currentTaskValues.colorHex;
        } else {
          log.taskNumber = `Task ${log.task.identifier}: `;
          log.taskName = `${log.currentTaskValues.title}`;
          log.taskAction = ` - Added`;
          log.iconColor = log.currentTaskValues.colorHex;
        }
        break;
      case 'TASK_UPDATE':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        if (log.childrenActivities) {
          log.taskNumber = `Task ${log.childrenActivities[0].currentTaskValues.identifier}: `;
          log.taskName = `${log.childrenActivities[0].currentTaskValues.title}`;
          log.taskAction = ` - Updated`;
          log.iconColor = log.childrenActivities[0].currentTaskValues.colorHex;
        } else {
          log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
          log.taskName = `${log.currentTaskValues.title}`;
          log.taskAction = ` - Updated`;
          log.iconColor = log.currentTaskValues.colorHex;
        }
        break;
      case 'TASK_DELETE':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        if (log.childrenActivities) {
          log.taskNumber = `Task ${log.childrenActivities[0].currentTaskValues.identifier}: `;
          log.taskName = `${log.childrenActivities[0].currentTaskValues.title}`;
          log.taskAction = ` - Deleted`;
          log.iconColor = log.childrenActivities[0].currentTaskValues.colorHex;
        } else {
          log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
          log.taskName = `${log.currentTaskValues.title}`;
          log.taskAction = ` - Deleted`;
          log.iconColor = log.currentTaskValues.colorHex;
        }
        break;

      case 'TASK_PARTICIPANT_ADD':
        log.expandable = false;
        log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
        log.taskName = `${log.currentTaskValues.title}`;
        log.taskAction = ` - ${taskRole(log.participant)}  Added`;
        log.iconColor = log.currentTaskValues.colorHex;
        log.icon = 'icon-activity-create';
        break;
      case 'TASK_PARTICIPANT_UPDATE':
        log.expandable = false;
        log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
        log.taskName = `${log.currentTaskValues.title}`;
        log.taskAction = ` - ${taskRole(log.participant)}  Updated`;
        log.iconColor = log.currentTaskValues.colorHex;
        log.icon = 'icon-activity-update';
        break;
      case 'TASK_PARTICIPANT_DELETE':
        log.expandable = false;
        log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
        log.taskName = `${log.currentTaskValues.title}`;
        log.taskAction = ` - ${taskRole(log.participant)}  Deleted`;
        log.icon = 'icon-activity-delete';
        break;
      case 'GROUPED_TASK_UPDATES':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Update: Grouped Task';
        break;
      case 'PROJECT_DELETED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Deleted: Project';
        break;
      case 'TASK_NUDGE_REMINDER':
        log.expandable = false;
        log.icon = 'icon-alarm';
        if (log.currentTaskValues) {
          log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
          log.taskName = `${log.currentTaskValues.title}`;
          log.title = log.taskNumber + log.taskName + ' Nudge Reminder';
        }
        break;
      case 'TASK_POKE_REMINDER':
        log.expandable = false;
        log.icon = 'icon-alarm';
        if (log.currentTaskValues) {
          log.taskNumber = `Task ${log.currentTaskValues.identifier}: `;
          log.taskName = `${log.currentTaskValues.title}`;
          log.title = log.taskNumber + log.taskName + ' Poke Reminder';
        }
        break;
      case 'WORKFLOW_NUDGE_REMINDER':
        log.expandable = false;
        log.icon = 'icon-activity-alarm';
        log.title = 'Workflow: Nudge Reminder';
        break;
      case 'WORKFLOW_POKE_REMINDER':
        log.expandable = false;
        log.icon = 'icon-activity-alarm';
        log.title = 'Workflow: Poke Reminder';
        break;
      case 'PROJECT_TRASHED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Trashed: Project';
        break;
      case 'PROJECT_RESTORED':
        log.expandable = false;
        log.icon = 'icon-activity-undo';
        log.title = 'Restored: Project';
        break;
      case 'INTERIM_CREATED':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        log.title = 'Interim: Created';
        break;
      case 'INTERIM_DELETED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Interim: Deleted';
        break;
      case 'SHARED_INDEX_CREATED':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        log.title = 'Shared Origin: Created';
        break;
      case 'SHARED_INDEX_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Shared Origin: Updated Parameters ';
        break;
      case 'REG_CREATED':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        log.title = 'Regulation: Created';
        break;
      case 'REG_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Regulation: Updated';
        break;
      case 'SHARED_INDEX_CONTENT_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Shared Origin: Updated Content';
        break;
      case 'SHARED_INDEX_DELETED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Shared Origin: Deleted';
        break;
      case 'SHARED_INDEX_USAGE_CREATED':
        log.expandable = false;
        log.icon = 'icon-activity-create';
        log.title = 'Shared Usage: Created';
        break;
      case 'SHARED_INDEX_USAGE_ACTION':
        log.expandable = false;
        log.title = 'Shared Usage Action:';
        if (log.sharedIndexUsage.updateStrategy === 'MANUAL_DEFER' || log.sharedIndexUsage.updateStrategy === 'NONE_DEFER') {
          log.icon = 'icon-activity-update';
          log.title = 'Shared Usage Action: Deferred';
        } else if (log.sharedIndexUsage.updateStrategy === 'AUTOMATIC') {
          log.icon = 'icon-activity-workflow-approve';
          log.title = 'Shared Usage Action: Automatic';
        } else if (log.sharedIndexUsage.updateStrategy === 'MANUAL' || log.sharedIndexUsage.updateStrategy === 'MANUAL_NOW') {
          log.icon = 'icon-activity-workflow-approve';
          log.title = 'Shared Usage Action: Accepted';
        } else if (log.sharedIndexUsage.updateStrategy === 'NONE_REFERENCE') {
          log.icon = 'icon-activity-workflow-approve';
        } else if (log.sharedIndexUsage.updateStrategy === 'NONE' || log.sharedIndexUsage.updateStrategy === 'MANUAL_REJECT') {
          log.title = 'Shared Usage Action: Rejected';
          log.icon = 'icon-activity-workflow-reject';
        } else if (log.sharedIndexUsage.updateStrategy === 'NONE_DONE') {
          log.icon = 'icon-activity-workflow-approve';
          log.title = 'Shared Usage Action: Done';
        }
        break;
      case 'SHARED_INDEX_USAGE_UNITS_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Shared Usage: Updated Content';
        break;
      case 'SHARED_INDEX_USAGE_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Shared Usage: Updated';
        break;
      case 'SHARED_INDEX_USAGE_DELETED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Shared Usage: Deleted';
        break;
      case 'SPECIAL_INTEREST_GROUPS_UPDATED':
        log.expandable = false;
        log.icon = 'icon-activity-update';
        log.title = 'Special Interest Group: Updated';
        break;
      case 'SPECIAL_INTEREST_GROUPS_DELETED':
        log.expandable = false;
        log.icon = 'icon-activity-delete';
        log.title = 'Special Interest Group: Deleted';
        break;
      case 'DRAFT_DISCARDED':
        log.title = 'Document Revision Discarded';
        log.icon = 'cancel';
        log.iconType = 'material-ui';
        break;
      case 'ATTACHMENT_CREATED':
        log.title = `${log.attachment.filename}`;
        log.icon = 'icon-activity-attachments';
        log.iconColor = '#47D579';
        log.attachmentAction = 'Added';
        break;
      case 'ATTACHMENT_UPDATED':
        log.title = `${log.attachment.filename}`;
        log.icon = 'icon-activity-attachments';
        log.iconColor = '#EA8317';
        log.attachmentAction = 'Updated';
        break;
      case 'ATTACHMENT_DELETED':
        log.title = `${log.attachment.filename}`;
        log.icon = 'icon-activity-attachments';
        log.iconColor = '#D0021B';
        log.attachmentAction = 'Deleted';
        break;
      case 'FRONTMATTER_MOVED':
        log.title = 'Frontmatter Moved';
        log.icon = 'icon-activity-update';
        break;
      case 'AAT_UPDATED':
        log.title = 'Aircraft Allocation Table';
        log.icon = 'icon-activity-effect-added';
        break;
      default:
        log.title = 'Unknown Operation';
        break;
    }
    if (log.childrenActivities) {
      log.childrenActivities.forEach((childActivity) => {
        childActivity.isChild = true;
        parseAdminActivity(childActivity);
      });
    }

    return log;
  } else {
    return null;
  }
}

function parse(entry: IActivity) {
  entry.formatted = dateUtil(entry.timestamp).formatDateTimeNoSecs();

  const title = entry.entityType
    ? ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(entry.entityType)?.displayName
    : null;
  entry.disablePopup = false;

  switch (entry.operation) {
    case 'new':
      entry.icon = 'create';
      entry.display = 'New Document';
      entry.title = `${title}`;
      break;
    case 'added':
      entry.icon = 'create';
      entry.display = 'Created';
      entry.title = `${title}`;
      break;
    case 'readded':
      entry.icon = 'create';
      entry.display = 'Recreated';
      entry.title = `${title}`;
      break;
    case 'updated':
    case 'tocableMove':
      entry.icon = 'update';
      entry.display = 'Update';
      entry.title = `${title}`;
      break;
    case 'unit_converted':
      entry.icon = 'unit-conversion';
      entry.display = 'Converted';
      entry.title = `${title}`;
      break;
    case 'deleted':
      entry.icon = 'delete';
      entry.display = 'Delete';
      entry.title = `${title}`;
      break;
    case 'promotion':
      entry.icon = 'promote';
      entry.display = 'Heading Promotion';
      entry.title = `${title}`;
      break;
    case 'demotion':
      entry.icon = 'demote';
      entry.display = 'Heading Demotion';
      entry.title = `${title}`;
      break;
    case 'batch_added':
      entry.icon = 'create';
      entry.display = 'Multiple Created';
      entry.title = `${title}`;
      break;
    case 'batch_updated':
      entry.icon = 'update';
      entry.display = 'Multiple Updated';
      entry.title = `${title}`;
      break;
    case 'batch_deleted':
      entry.icon = 'delete';
      entry.display = 'Multiple Deleted';
      entry.title = `${title}`;
      break;
    case 'batch_converted':
      entry.icon = 'unit-conversion';
      entry.display = 'Multiple Converted';
      entry.title = `${title}`;
      break;
    case 'shared_index_inserted':
      entry.icon = 'create';
      entry.display = 'Shared Content Inserted';
      entry.title = `${title}`;
      break;
    case 'shared_index_removed':
      entry.icon = 'delete';
      entry.display = 'Shared Content Deleted';
      entry.title = `${title}`;
      break;
    case 'shared_index_updated':
      entry.icon = 'update';
      entry.display = 'Shared Content Updated';
      entry.title = `${title}`;
      break;
    case 'unit_concept_tagged': {
      entry.display = 'Search Tag Added';
      entry.disablePopup = true;
      entry.title = `${title}`;
      const conceptDescription = JSON.parse(entry.description as string);
      entry.description = {} as IActivityDescription;

      if (conceptDescription.concepts && conceptDescription.concepts.searchTerms.length > 0) {
        entry.description.text = conceptDescription.text;
        entry.description.name = conceptDescription.concepts.searchTerms[0].name;
        entry.icon = 'label';
      } else {
        entry.display = 'Variant Tag Added';
        entry.description.text = conceptDescription.text;
        entry.description.name = conceptDescription.concepts.variants[0].name;
        entry.icon = 'effectivity';
      }
      break;
    }
    case 'unit_concept_untagged': {
      entry.display = 'Search Tag Removed';
      entry.disablePopup = true;
      entry.title = `${title}`;
      const unTagDescription = JSON.parse(entry.description as string);
      entry.description = {} as IActivityDescription;

      if (unTagDescription.concepts && unTagDescription.concepts.searchTerms.length > 0) {
        entry.description.text = unTagDescription.text;
        entry.description.name = unTagDescription.concepts.searchTerms[0].name;
        entry.icon = 'label-tag-removed';
      } else {
        entry.display = 'Variant Tag Removed';
        entry.description.name = unTagDescription.concepts.variants[0].name;
        entry.icon = 'effectivity';
      }
      break;
    }
    case 'unit_interest_group_multiple_update':
      entry.disablePopup = true;
      entry.icon = 'update';
      entry.display = ' Multi Special Interest Tags Updated';
      entry.title = `${title}`;
      break;
    case 'unit_interest_group_update': {
      const description = JSON.parse(entry.description as string);
      entry.disablePopup = true;

      if (description.remove) {
        entry.display = 'Special Interest Tag Deleted';
        entry.icon = 'delete';
        entry.title = `${title}`;
      } else if (description.add) {
        entry.display = 'Special Interest Tag Added';
        entry.icon = 'create';
        entry.title = `${title}`;
      }

      const SpecialInterestGroups = UnitSpecialInterestTagStore.getSpecialInterestGroups();
      if (SpecialInterestGroups) {
        const uid = description.remove ? description.remove[0].uid : description.add[0].uid;
        SpecialInterestGroups.forEach((group) => {
          if (group.uid === uid) {
            entry.description = group.name;
          }
        });
      }
      break;
    }
    case 'revbar_cleared':
      entry.display = 'Revision Bar Removed By Admin';
      entry.title = `${title}`;
      break;
    case 'effectivity_added':
      entry.display = 'Effectivity';
      entry.title = 'Added ' + entry.affectedMsns.join(',');
      entry.icon = 'effect-added';
      break;
    case 'effectivity_removed':
      entry.display = 'Effectivity';
      entry.title = 'Removed ' + entry.affectedMsns.join(',');
      entry.icon = 'effect-removed';
      break;
    default:
      entry.display = 'Unknown operation';
      entry.title = `${title}`;
      break;
  }

  if (entry.childrenActivities) {
    entry.childrenActivities.forEach((childActivity) => {
      parse(childActivity);
    });
  }

  return entry;
}
