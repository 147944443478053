import * as React from 'react';
import IconMenu from 'material-ui/IconMenu';
import FontIcon from 'material-ui/FontIcon';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';
import Settings from 'material-ui/svg-icons/action/settings';
import Delete from 'material-ui/svg-icons/action/delete';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';
import Restore from 'material-ui/svg-icons/content/undo';
import { transitionTo } from '../../utils/Navigation';
import ProjectPropsStore, { ProjectPropsStoreEvent } from '../../flux/projects/ProjectPropsStore';
import { IRevision, ISettings, IUserPermissions } from 'mm-types';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import ProjectStore from '../../flux/editor/ProjectStore';

export type Props = {
  open?: boolean;
  isExternal?: boolean;
  permissions: null | Partial<IUserPermissions>;
  projectUid?: null | string;
  interimIndexUid?: null | string;
  onSelected: (actionKey: string, projectUid: string) => void;
  projectSettings?: ISettings | null;
  hideInterim?: boolean;
  getRevisions?: boolean;
  workspaceUid?: null | string;
  onClose: () => void;
  isReadOnly?: boolean;
};

export type State = {
  revisions: IRevision[] | null;
  locked: boolean;
};

export default class ProjectItemMenu extends React.Component<Props, State> {
  private unsubscribe: Function;
  private _menuItemStyle: React.CSSProperties;

  constructor(props: Props) {
    super(props);
    this._menuItemStyle = { paddingLeft: '52px', height: '37px' };
    this.unsubscribe = ProjectPropsStore.listen(this.onPropsStoreChange, this);

    this.state = {
      revisions: null,
      locked: false
    };
  }

  static defaultProps: Partial<Props> = {
    open: false,
    isExternal: false,
    permissions: null,
    projectUid: null,
    interimIndexUid: null,
    workspaceUid: null,
    getRevisions: true
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount(): void {
    if (this.props.getRevisions) {
      const project = ProjectStore.getProject()!;
      if (project) {
        ProjectPropsStore.getRevisions(project, true, true);
      }
    }
  }

  onPropsStoreChange(event: ProjectPropsStoreEvent) {
    if (event.error) {
      transitionTo('/teamspaces');
    } else {
      if (event.state.revisions) {
        this.setState({ revisions: event.state.revisions as IRevision[] });
      }
      this.setState({ locked: event.state.locked });
    }
  }

  _canExportEdb(): boolean | undefined {
    return this.props.permissions?.canExportEdb;
  }

  _canExportAirbus(): boolean | undefined {
    return this.props.permissions?.canExportAirbus;
  }

  _canExportHelp(): boolean | undefined {
    return this.props.permissions?.canExportHelp;
  }

  _getMenuItems() {
    let divider: JSX.Element | null = null;
    let interim: JSX.Element | null = null;
    let discard: JSX.Element | null = null;
    let viewOrOpen: JSX.Element[] = [];
    let midSection: JSX.Element[] = [];

    if (this.props.isReadOnly && this.props.isExternal) {
      return null;
    }
    if (this.props.isReadOnly) {
      return (
        <>
          <IconMenu
            open={this.props.open}
            onRequestChange={this.props.onClose}
            iconButtonElement={
              <IconButton id="doc-prop-btn" style={{ zIndex: 0 }} iconClassName="material-icons">
                more_vert
              </IconButton>
            }
          >
            <MenuItem
              className="menuItem"
              key="0"
              primaryText="Open"
              leftIcon={
                <IconButton
                  className="itemMenuButton"
                  iconClassName="material-icons icon-open"
                  disabled={!this.props.permissions?.canRead}
                />
              }
              innerDivStyle={this._menuItemStyle}
              disabled={!this.props.permissions?.canRead}
              onClick={(e) => this._selectedMenuItem(e, 'Open')}
            />
          </IconMenu>
        </>
      );
    } else {
      const isExternal = this.props.isExternal;
      if (!this.props.hideInterim && !isExternal) {
        let disabled = true;
        if (
          this.state.revisions &&
          this.state.revisions.length > 0 &&
          (ActiveUserStore.isAdminOfSelectedProjectTeamspace(this.props.workspaceUid!) || ActiveUserStore.isAdmin())
        ) {
          disabled = false;
        }
        divider = <Divider />;
        if (this.props.projectSettings?.allowInterim) {
          interim = (
            <MenuItem
              className="menuItem"
              key="6"
              primaryText={this.props.interimIndexUid ? 'Delete Interim Revision' : 'Create Interim Revision'}
              innerDivStyle={this._menuItemStyle}
              disabled={disabled || (this.state.locked && !this.props.interimIndexUid)}
              leftIcon={
                <IconButton
                  className="itemMenuButton"
                  iconClassName="higher-icon material-icons icon-interim-revision"
                  disabled={disabled}
                />
              }
              onClick={(e) => this._selectedMenuItem(e, this.props.interimIndexUid ? 'Delete Interim Revision' : 'Create Interim Revision')}
              data-qa={'interim-action-menu-item'}
            />
          );
        }

        discard = (
          <MenuItem
            className="menuItem"
            key="7"
            primaryText="Discard Current Draft"
            innerDivStyle={this._menuItemStyle}
            disabled={disabled || this.state.locked}
            leftIcon={
              <IconButton className="itemMenuButton" disabled={disabled}>
                <FontIcon className="material-icons">cancel</FontIcon>
              </IconButton>
            }
            onClick={(e) => this._selectedMenuItem(e, 'Discard Current Draft')}
            data-qa={'discard-draft-action-menu-item'}
          />
        );
      }

      if (isExternal) {
        midSection = [
          <MenuItem
            className="menuItem"
            key="4"
            primaryText="Upload New Draft"
            innerDivStyle={this._menuItemStyle}
            leftIcon={<IconButton className="itemMenuButton" iconClassName="higher-icon material-icons icon-import" disabled={false} />}
            onClick={(e) => this._selectedMenuItem(e, 'Upload New Draft')}
          />,
          <MenuItem
            className="menuItem"
            key="5"
            primaryText="Download Current Draft"
            innerDivStyle={this._menuItemStyle}
            leftIcon={<IconButton className="itemMenuButton" iconClassName="higher-icon material-icons icon-export" disabled={false} />}
            onClick={(e) => this._selectedMenuItem(e, 'Download Current Draft')}
          />
        ];
      } else {
        viewOrOpen = [
          <MenuItem
            className="menuItem"
            key="0"
            primaryText="Open"
            leftIcon={
              <IconButton className="itemMenuButton" iconClassName="material-icons icon-open" disabled={!this.props.permissions?.canRead} />
            }
            innerDivStyle={this._menuItemStyle}
            disabled={!this.props.permissions?.canRead}
            onClick={(e) => this._selectedMenuItem(e, 'Open')}
          />,
          <MenuItem
            className="menuItem"
            key="1"
            primaryText="Copy"
            leftIcon={
              <IconButton
                className="itemMenuButton"
                iconClassName="material-icons icon-copy-doc"
                disabled={!this.props.permissions?.canRead}
              />
            }
            innerDivStyle={this._menuItemStyle}
            disabled={!this.props.permissions?.canRead}
            onClick={(e) => this._selectedMenuItem(e, 'Copy')}
          />
        ];

        midSection.push(
          <MenuItem
            className="menuItem"
            key="5"
            primaryText="Export to ArcML"
            innerDivStyle={this._menuItemStyle}
            leftIcon={
              <IconButton
                className="itemMenuButton"
                iconClassName="higher-icon material-icons icon-export"
                disabled={!this.props.permissions?.canExportArcXml}
              />
            }
            disabled={!this.props.permissions?.canExportArcXml}
            onClick={(e) => this._selectedMenuItem(e, 'Export to ArcML')}
          />
        );

        if (this._canExportEdb()) {
          midSection.push(
            <MenuItem
              className="menuItem"
              key="8"
              primaryText="Export to EDB"
              innerDivStyle={this._menuItemStyle}
              leftIcon={
                <IconButton
                  className="itemMenuButton"
                  iconClassName="higher-icon material-icons icon-export"
                  disabled={!this.props.permissions?.canExportEdb}
                />
              }
              disabled={!this.props.permissions?.canExportEdb}
              onClick={(e) => this._selectedMenuItem(e, 'Export to EDB')}
            />
          );
        }

        if (this._canExportAirbus()) {
          midSection.push(
            <MenuItem
              className="menuItem"
              key="9"
              primaryText="Export to Airbus"
              innerDivStyle={this._menuItemStyle}
              leftIcon={
                <IconButton
                  className="itemMenuButton"
                  iconClassName="higher-icon material-icons icon-export"
                  disabled={!this.props.permissions?.canExportAirbus}
                />
              }
              disabled={!this.props.permissions?.canExportAirbus}
              onClick={(e) => this._selectedMenuItem(e, 'Export to Airbus')}
            />
          );
        }

        if (this._canExportHelp()) {
          midSection.push(
            <MenuItem
              className="menuItem"
              key="9"
              primaryText="Export to Help"
              innerDivStyle={this._menuItemStyle}
              leftIcon={
                <IconButton
                  className="itemMenuButton"
                  iconClassName="higher-icon material-icons icon-export"
                  disabled={!this.props.permissions?.canExportHelp}
                />
              }
              disabled={!this.props.permissions?.canExportHelp}
              onClick={(e) => this._selectedMenuItem(e, 'Export to Help')}
            />
          );
        }

        midSection.push(
          <MenuItem
            className="menuItem"
            key="10"
            primaryText="Load Manual"
            innerDivStyle={this._menuItemStyle}
            leftIcon={
              <IconButton
                className="itemMenuButton"
                iconClassName="higher-icon material-icons icon-import"
                disabled={!this.props.permissions?.canExportArcXml}
              />
            }
            disabled={!this.props.permissions?.canExportArcXml}
            onClick={(e) => this._selectedMenuItem(e, 'Load Manual')}
          />
        );
      }

      return (
        <>
          <IconMenu
            open={this.props.open}
            onRequestChange={this.props.onClose}
            iconButtonElement={
              <IconButton id="doc-prop-btn" style={{ zIndex: 0 }} iconClassName="material-icons">
                more_vert
              </IconButton>
            }
            data-qa={'project-item-menu'}
          >
            {viewOrOpen}
            <MenuItem
              className="menuItem"
              key="2"
              primaryText="Settings"
              innerDivStyle={this._menuItemStyle}
              leftIcon={<Settings className="itemMenuButtonFontello" disabled={!this.props.permissions?.canEditSettings} />}
              disabled={!this.props.permissions?.canEditSettings}
              onClick={(e) => this._selectedMenuItem(e, 'Settings')}
            />
            <MenuItem
              className="menuItem"
              key="3"
              primaryText="Delete"
              leftIcon={<Delete className="itemMenuButtonFontello" disabled={!this.props.permissions?.canEditSettings} />}
              innerDivStyle={this._menuItemStyle}
              disabled={!this.props.permissions?.canEditSettings}
              onClick={(e) => this._selectedMenuItem(e, 'Delete')}
            />
            <Divider />
            {midSection}
            {divider}
            {interim}
            {discard}
          </IconMenu>
        </>
      );
    }
  }

  _getTrashMenuItems() {
    return (
      <>
        <IconMenu
          open={this.props.open}
          onRequestChange={this.props.onClose}
          iconButtonElement={
            <IconButton style={{ zIndex: 0 }} iconClassName="material-icons">
              more_vert
            </IconButton>
          }
        >
          <MenuItem
            className="menuItem"
            key="0"
            primaryText="Open"
            leftIcon={
              <IconButton className="itemMenuButton" iconClassName="material-icons icon-open" disabled={!this.props.permissions?.canRead} />
            }
            innerDivStyle={this._menuItemStyle}
            disabled={!this.props.permissions?.canRead}
            onClick={(e) => this._selectedMenuItem(e, 'Open')}
          />
          <MenuItem
            className="menuItem"
            key="1"
            primaryText="Restore"
            leftIcon={<Restore className="itemMenuButtonFontello" disabled={!this.props.permissions?.canRead} />}
            innerDivStyle={this._menuItemStyle}
            disabled={!this.props.permissions?.canEditSettings}
            onClick={(e) => this._selectedMenuItem(e, 'Restore')}
          />
          <MenuItem
            className="menuItem"
            key="2"
            primaryText="Delete permanently"
            innerDivStyle={this._menuItemStyle}
            leftIcon={<DeleteForever className="itemMenuButtonFontello" disabled={!this.props.permissions?.canEditSettings} />}
            disabled={!this.props.permissions?.canEditSettings}
            onClick={(e) => this._selectedMenuItem(e, 'Delete permanently')}
          />
        </IconMenu>
      </>
    );
  }

  render() {
    return this.props.workspaceUid === 'trash' ? this._getTrashMenuItems() : this._getMenuItems();
  }

  _selectedMenuItem(e: React.SyntheticEvent<{}>, actionKey: string) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelected(actionKey, this.props.projectUid!);
  }
}
