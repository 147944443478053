import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import classNameHelper from '../../../../utils/classNameHelper';

export interface MenuActionIconProps {
  enabled: boolean;
  icon: string;
  tooltip: string;
  inClassNameIcon?: boolean;
}

const MenuActionIcon = ({ enabled, tooltip, icon, inClassNameIcon }: MenuActionIconProps) => {
  const iconClassName = classNameHelper.merge('material-icons', {
    [icon]: !!inClassNameIcon
  });
  const style: React.CSSProperties = !enabled ? { color: `rgba(0, 0, 0, 0.1) !important`, pointerEvents: 'none' } : {};
  return (
    <IconButton
      disabled={!enabled}
      iconClassName={iconClassName}
      tooltipPosition="bottom-center"
      tooltip={tooltip}
      iconStyle={style}
      style={style}
    >
      {!inClassNameIcon ? icon : null}
    </IconButton>
  );
};

export default MenuActionIcon;
