import { Features } from 'mm-types';

export const SET_FEATURES = 'SET_FEATURES';
export const SET_FEATURE_ON = 'SET_FEATURE_ON';
export const SET_FEATURE_OFF = 'SET_FEATURE_OFF';

export function setFeatures(features: Features) {
  return <const>{
    type: SET_FEATURES,
    features
  };
}

export function setFeatureOn(name: keyof Features) {
  return <const>{
    type: SET_FEATURE_ON,
    name
  };
}

export function setFeatureOff(name: keyof Features) {
  return <const>{
    type: SET_FEATURE_OFF,
    name
  };
}
