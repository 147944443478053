import React from 'react';
import { DocSettingsRoute } from '../../../documents/settingsMenu';
import { FlatButton, FontIcon } from 'material-ui';
import { MenuElement } from '../EditorMenu/MenuElements';
import withTooltip from '../../../hoc/withTooltip';

export const WORKFLOW_LABEL_STYLE: any = {
  textTransform: 'capitalize !important',
  fontSize: '0.9rem !important',
  fontWeight: '400 !important'
};

interface Props {
  label: string;
  destination: DocSettingsRoute;
  onClick(e: React.MouseEvent<any>, destination: DocSettingsRoute): void;
  icon?: JSX.Element;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
}

const Tooltip = withTooltip<{}>(({ children }) => <>{children}</>);
const ButtonContainer = (props: { tooltip?: string; children: React.ReactNode }) => {
  if (!props.tooltip) {
    return <span>{props.children}</span>;
  }
  return <Tooltip tooltipText={props.tooltip ?? ''}>{props.children}</Tooltip>;
};

export function GoToSettingsButton({ label, destination, onClick, disabled = false, className, icon, tooltip }: Props) {
  return (
    <MenuElement onClick={(e) => !disabled && onClick(e, destination)} dataQa={`menu-element-${destination}`}>
      <ButtonContainer tooltip={tooltip}>
        <FlatButton
          className={`menu-button ${className ?? ''}`}
          label={label}
          labelPosition="after"
          labelStyle={WORKFLOW_LABEL_STYLE}
          disabled={disabled}
        >
          {icon ?? <FontIcon className="material-icons">settings</FontIcon>}
        </FlatButton>
      </ButtonContainer>
    </MenuElement>
  );
}
