import * as React from 'react';
import { IDataAttribute, IDefinition, IUnit } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { clearDependableDataAttribute, DataAttributesValues, defaultValue } from '../../../../../../utils/DataAttributesUtil';
import { RenderDataAttributes } from '../../renderDataAttributes/RenderDataAttributes';
import { DEFAULT_SETTINGS } from './PrintOutputData';

export type Props = {
  withinToggleSection?: string;
  dataAttributes: IDataAttribute[];
  targetElement: HTMLElement;
  definition: IDefinition;
  inline?: boolean;
  units?: IUnit[];
};

const DataAttributesProps = ({ withinToggleSection, dataAttributes, targetElement, definition, inline, units }: Props) => {
  const [dataAttributesValues, setDataAttributesValues] = React.useState<DataAttributesValues>({});
  React.useEffect(() => {
    if (!!targetElement) {
      updateDataAttributes();
    }
    return () => {};
  }, [targetElement]);

  function updateDataAttributes() {
    let newValues: DataAttributesValues = {};
    for (let i = 0; i < dataAttributes.length; i++) {
      if (targetElement) {
        const attributeValue = targetElement.getAttribute(dataAttributes[i].dataV);
        if (!!attributeValue) {
          newValues[dataAttributes[i].id] = attributeValue;
        } else {
          newValues[dataAttributes[i].id] = defaultValue(dataAttributes[i]);
        }
      }
    }
    setDataAttributesValues(newValues);
  }

  function handleAction(dataAttribute: IDataAttribute, newValue: string, isValid = true) {
    let newDataAttributesValues = { ...dataAttributesValues, [dataAttribute.id]: newValue };
    newDataAttributesValues = clearDependableDataAttribute(dataAttributes, newDataAttributesValues, dataAttribute, (dataV: string) =>
      targetElement.removeAttribute(dataV)
    );

    if (Object.keys(newDataAttributesValues).includes('columnCount')) {
      newDataAttributesValues.colCount = newDataAttributesValues.columnCount;
    }

    setDataAttributesValues(newDataAttributesValues);

    if (isValid) {
      if (withinToggleSection == 'Print Output') {
        EditorStore.updatePrintOutput({ ...DEFAULT_SETTINGS, ...newDataAttributesValues });
      } else {
        EditorStore.getEditor().getActiveEditorFacade()?.applyDataAttribute(targetElement, dataAttribute.dataV, newValue);
        EditorStore.getEditor().getActiveEditorFacade()?.applyStyleFromDataAttribute(dataAttribute, targetElement, newValue, definition);
      }
    }
  }

  return (
    <div className="data-attributes-container">
      <div className={'selected-unit-props-section' + (inline ? ' selected-unit-props-section-inline' : '')}>
        {dataAttributes.map((dataAttribute, key) => {
          return (
            <RenderDataAttributes
              key={key}
              dataAttribute={dataAttribute}
              dataAttributes={dataAttributes}
              dataAttributesValues={dataAttributesValues}
              handleAction={handleAction}
              units={units}
              elmNid={targetElement.getAttribute('data-nid')}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(DataAttributesProps);
