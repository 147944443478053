import * as React from 'react';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import { RadioButtonGroup } from 'material-ui/RadioButton';

interface RefFocusProps {
  ref: React.RefObject<any>;
  focusImmediately?: boolean;
}

export default ({ ref, focusImmediately = false }: RefFocusProps): Promise<undefined | void> => {
  return new Promise<undefined | void>((resolve, reject) => {
    setTimeout(
      () => {
        const el = ref.current;
        let targetElement: any;

        if (!el) {
          return reject('refFocus.ts - no element to focus');
        }
        if (el instanceof TextField) {
          targetElement = el;
        } else if (el instanceof IconButton && !el.props.disabled) {
          targetElement = (el as any).button.button;
        } else if (el instanceof RadioButtonGroup) {
          let selected = (el as any).state.selected;
          if (!selected) {
            const keys = Object.keys(el.refs);

            if (keys.length) {
              selected = keys[0];
            }
          }
          if (selected) {
            targetElement = (el as any).refs[selected].refs.enhancedSwitch.refs.checkbox;
          }
        }

        if (targetElement) {
          targetElement.focus();
          resolve();
        } else {
          reject('refFocus.ts - no element to focus');
        }
      },
      focusImmediately ? 0 : 300
    );
  });
};
