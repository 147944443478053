import moment from 'moment';
import ServerSettingsStore from '../flux/common/ServerSettingsStore';

const DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY';
const DEFAULT_DATE_TIME_FORMAT = 'DD-MMM-YYYY HH:mm';
const DEFAULT_TIME_FORMAT = 'H:mm:ss';
const HH_MM_FORMAT = 'H:mm';

export interface DateUtil {
  moment: moment.Moment | null;
  format(dateFormat: string): string;
  formatDate(): string;
  formatDateTime(comma?: boolean): string;
  formatDateTimeNoSecs(comma?: boolean): string;
  formatTime(): string;
  formatTimeHourMinute(): string;
  fromNow(): string;
  utc(): DateUtil;
  addDays(days: number): Date | undefined;
  convertFromLocalTimezoneToUtc(): Date;
  convertFromUtcToLocalTimezone(): Date;
}

type DateUtilInput = Date | 'now' | string | number | moment.Moment;

const dateUtil = (dateAsString?: DateUtilInput): DateUtil => {
  const momentDate = getDateObject(dateAsString);
  const dateSettings = getDateTimeSettings();
  const dateFormat = dateSettings.dateFormat ?? DEFAULT_DATE_FORMAT;
  const dateTimeFormat = dateSettings.dateTimeFormat ?? DEFAULT_DATE_TIME_FORMAT;
  const dateTimeFormatLessSecs =
    dateTimeFormat.indexOf('s') !== -1 ? dateTimeFormat.slice(0, dateTimeFormat.indexOf('s') - 1) : dateTimeFormat;

  function format(dateFormat?: string): string {
    if (!momentDate) {
      return '';
    }
    return momentDate.format(dateFormat);
  }

  function formatDate(): string {
    return format(dateFormat ?? DEFAULT_DATE_FORMAT);
  }

  function formatDateTime(comma = false): string {
    return format(comma ? dateTimeFormat.replace(' ', ', ') : dateTimeFormat);
  }

  function formatDateTimeNoSecs(comma = false): string {
    return format(comma ? dateTimeFormatLessSecs.replace(' ', ', ') : dateTimeFormatLessSecs);
  }

  function formatTime(): string {
    return format(DEFAULT_TIME_FORMAT);
  }

  function formatTimeHourMinute(): string {
    return format(HH_MM_FORMAT);
  }

  function utc(): DateUtil {
    if (!momentDate) {
      return dateUtil();
    }
    return dateUtil(momentDate.utc());
  }

  function fromNow(): string {
    if (!momentDate) {
      return '';
    }
    return momentDate?.fromNow();
  }

  function getDateObject(date?: DateUtilInput): moment.Moment | null {
    if (typeof date === 'undefined' || date === null) {
      return null;
    }
    if (date === 'now') {
      return moment();
    }
    return moment(date);
  }

  function addDays(days: number): Date | undefined {
    return momentDate?.add(days, 'd').toDate();
  }

  /**
   * Given a local date that looks like: 2020/12/30 00:00:00 (GMT -07:00), transform this date such that if we
   * get the UTC date, we would get the exact same date 2020/12/30 00:00:00 (UTC) by making it 2020/12/29 00:17:00 (GMT -07:00)
   * Basically generate a new date object by appending the offset between local timezone and UTC.
   */
  function convertFromLocalTimezoneToUtc(): Date {
    const date = momentDate!.toDate();
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - offset * 60000);
  }

  /**
   * Given a local date that looks like: 2020/12/29 00:17:00 (GMT -07:00), transform this date by adding the offset from UTC.
   * The given local date therefore masquerades as a UTC date 2020/12/30 00:00:00 (GMT -07:00).
   */
  function convertFromUtcToLocalTimezone(): Date {
    const date = momentDate!.toDate();
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() + offset * 60000);
  }

  return {
    moment: momentDate,
    format,
    formatDate,
    formatDateTime,
    formatDateTimeNoSecs,
    formatTime,
    formatTimeHourMinute,
    utc,
    fromNow,
    addDays,
    convertFromLocalTimezoneToUtc,
    convertFromUtcToLocalTimezone
  };
};

function getDateTimeSettings() {
  const settings = { ...ServerSettingsStore.getServerSettings() };
  return { dateFormat: settings.jsDateFormat, dateTimeFormat: settings.jsDateTimeFormat };
}

export default dateUtil;
