import { mm } from './base-clients';
import { IProject, INotification } from 'mm-types';

export type ImportOptions = {
  isExternalFile: boolean;
  workspaceUid: string;
  projectDefinition: string;
};

export type AsyncImportOptions = {
  workspaceUid: string | null;
  projectDefinition: string;
};

export type ImportSnapshotOptions = {
  ancestorSnapshotUid: string;
  snapshotTitle: string;
  snapshotDescription: string;
};

export async function uploadExternal(file: File, projectUid: string, comment: string) {
  const data = new FormData();
  data.set('file', file);
  data.set('comment', comment);
  data.set('projectUid', projectUid);

  const result = await mm.post<IProject>(`/projects/upload`, data);
  return result.data;
}

export async function importProject(file: File, options: Partial<ImportOptions>) {
  const data = new FormData();
  data.set('content', file);

  if (options.isExternalFile) {
    data.set('isExternalFile', options.isExternalFile.toString());
  }

  if (options.workspaceUid) {
    data.set('workspaceUid', options.workspaceUid);
  }

  if (options.projectDefinition) {
    data.set('projectDefinition', options.projectDefinition);
  }

  const result = await mm.post<IProject>(`/import/project`, data);
  return result.data;
}

export async function importProjectAsync(file: File, options: Partial<AsyncImportOptions>) {
  const data = new FormData();
  data.set('content', file);

  if (options.projectDefinition) {
    data.set('projectDefinition', options.projectDefinition);
  }

  if (options.workspaceUid) {
    data.set('workspaceUid', options.workspaceUid);
  }

  const response = await mm.post<INotification>(`/import/project/async`, data);
  return response.data;
}

export async function importSnapshoyAsync(projectUid: string, file: File, options: Partial<ImportSnapshotOptions>) {
  const data = new FormData();
  data.set('content', file);

  if (options.ancestorSnapshotUid) {
    data.set('ancestorSnapshotUid', options.ancestorSnapshotUid.toString());
  }

  if (options.snapshotTitle) {
    data.set('snapshotTitle', options.snapshotTitle);
  }

  if (options.snapshotDescription) {
    data.set('snapshotDescription', options.snapshotDescription);
  }

  const response = await mm.post<INotification>(`/import/project/${projectUid}/async`, data);
  return response.data;
}
