import EditorModes from '../EditorModes';
import SmartContentStore from '../SmartContentStore';
import ProjectStore from '../ProjectStore';
import * as scClient from '../../../clients/shared-content';
import IndexEventStore from '../../events/IndexEventStore';
import ActiveUserStore from '../../common/ActiveUserStore';
import PublishStore from '../PublishStore';
import { EditorStore } from '../EditorStore';
import { IShareDetails, ISharedIndexUsages, IUnit, ISharedIndex, IIndex, ISharedIndexOrigin, IEditorStoreEvent } from 'mm-types';
import SharedUnitSelection, { RecalculateOptions } from './sharedContent/SharedUnitSelection';
import UnitTaskStore from '../UnitTaskStore';
import DiffModeHandler from './DiffModeHandler';
import { AxiosError } from 'axios';
import { DocUnitWrapper } from '../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { ShareEditMode } from './modes/ShareEditMode';

interface IPosition {
  isUp: boolean;
  isStart: boolean;
}

export default class SharedContentAddon {
  private editorStore: EditorStore;
  private readonly diffMode: DiffModeHandler;

  constructor(editorStore: EditorStore) {
    this.editorStore = editorStore;
    this.diffMode = new DiffModeHandler(editorStore);
  }

  async selectShareOrigin({ index, shareDetails }: IUnit, position: IPosition) {
    if (shareDetails && this.isActionAllowed() && !this.editorStore.isReadOnly() && this.canSelectShare(shareDetails)) {
      if (this.diffMode.inDiffMode(shareDetails.uid)) {
        const { startIndex, endIndex } = this.diffMode.closeDiffMode(shareDetails.uid!);
        index = position.isStart ? startIndex : endIndex;
      }
      this.closeDiffMode();

      await SharedUnitSelection.select(this.editorStore.getDocUnits(), index, position);
      this.triggerSelectShareOriginEvent(shareDetails.uid);
    }
  }

  async recalculateSharedContentSelectionIfApplicable(options: RecalculateOptions) {
    const recalculated = await SharedUnitSelection.recalculateSelection(options, this.editorStore.getDocUnitsCollection());
    if (recalculated) {
      this.triggerSelectShareOriginEvent(null);
    }
  }

  requestSharedContentInsert(sharedIndex: ISharedIndex) {
    if (this.isActionAllowed()) {
      const selectedUnit = this.editorStore.getSelectedUnit();
      if (selectedUnit && (!selectedUnit.shareDetails || (selectedUnit.shareDetails && selectedUnit.shareDetails.isShareEndUnit))) {
        this.triggerRequestSharedContentInsertEvent(sharedIndex, this.editorStore.getSelectedUnit());
      }
    }
  }

  async acceptSharedContentInsert(sharedIndexUid: string, updateStrategy: string, insertType: string, ordinalLevelStrategy: string) {
    if (this.isActionAllowed()) {
      this.closeDiffMode();
      this.editorStore.setBusy(true, 'Inserting shared elements...');
      const { uid, index } = this.editorStore.getSelectedUnit()!;
      const sharedIndexModel = this.getSharedIndexUsage(sharedIndexUid, updateStrategy, insertType, ordinalLevelStrategy);
      try {
        const { units } = await scClient.createIndexUsage(sharedIndexModel, { afterUnitUid: uid });
        this.editorStore.triggerUnitsChanged(this.editorStore._insertUnitsInMemory(units, index + 1));
        UnitTaskStore.init(this.editorStore.getDocParams()).then(() => {
          this.triggerAcceptSharedContentInsertEvent(sharedIndexUid);
          this.broadcastSharedContentPublishedEvent();
        });
        this.editorStore.setBusy(false);
      } catch (err) {
        this.editorStore._handleError(err as AxiosError, [400, 403, 404, 412]);
      }
    }
  }

  requestRejectSharedContentUpdate(unitUid: string, sharedIndexUid: string) {
    if (this.isActionAllowed()) {
      this.closeDiffMode();
      this.triggerRequestRejectSharedContentUpdateEvent(unitUid, sharedIndexUid);
    }
  }

  async acceptSharedContentUpdate(unitUid: string, sharedIndexUid: string, acceptStrategy: string) {
    await this.updateSharedUsage(unitUid, sharedIndexUid, acceptStrategy, 'Accepting shared content...');
  }

  async rejectSharedContentUpdate(unitUid: string, sharedIndexUid: string) {
    await this.updateSharedUsage(unitUid, sharedIndexUid, 'NONE', 'Rejecting shared content...');
  }

  diffSharedContentUpdate(unitUid: string, unitShareDetails: IShareDetails) {
    const { uid, inDiffMode, origin } = unitShareDetails;
    if (!this.editorStore.isBusy() && this.editorStore.doesModeAllow(EditorModes.attributes.editing)) {
      this.editorStore.setBusy(true, 'Getting diff information...');

      if (!inDiffMode) {
        this.closeDiffMode();
      } else {
        if (origin) {
          SmartContentStore.retrieveSharedOriginUnits(uid!, inDiffMode).then((units) =>
            this.diffMode.openDiffMode(unitUid, unitShareDetails, units)
          );
        } else {
          SmartContentStore.retrieveSharedUsage(unitUid, this.editorStore.getDocParams(), inDiffMode).then((sharedIndexUsage) =>
            this.diffMode.openDiffMode(unitUid, unitShareDetails, sharedIndexUsage.units)
          );
        }
      }
    }
  }

  async publishSharedOrigin({ uid, sharedIndexUid }: IShareDetails) {
    if (this.isActionAllowed()) {
      this.editorStore.setBusy(true, 'Publishing shared content...');

      const indexUid = sharedIndexUid || uid!;

      this.closeDiffMode();

      try {
        const indexSharedOrigin: Partial<ISharedIndexOrigin> = {
          uid: indexUid,
          sharedIndex: { uid: indexUid }
        };

        const { sharedIndex, startUnit, endUnit }: ISharedIndexOrigin = await scClient.updateOrigin(indexUid, indexSharedOrigin);

        const originBoundaryUpdates = {
          startUnitUid: startUnit.uid,
          endUnitUid: endUnit.uid,
          uid: sharedIndex.uid
        };

        await scClient.updateIndex(originBoundaryUpdates.uid!, originBoundaryUpdates);
        this.editorStore.setBusy(false);

        this.triggerPublishSharedOriginEvent();
        this.broadcastSharedContentPublishedEvent();
        await this.checkIfCanPublish();
      } catch (err) {
        this.editorStore.triggerShowEditorError({ axiosErr: err as AxiosError });
      }
    }
  }

  async revertSharedOrigin({ uid, sharedIndexUid }: IShareDetails) {
    if (this.isActionAllowed()) {
      const inShareEditMode = this.editorStore.isShareEditMode();
      const shareEditMode = EditorModes.getProperties('SHARE_EDIT') as ShareEditMode;
      let shareEditParams;
      let indexUid = sharedIndexUid || uid!;

      this.closeDiffMode();

      this.editorStore.setBusy(true, 'Reverting shared content...');
      if (inShareEditMode) {
        shareEditParams = shareEditMode.getParams();
        indexUid = shareEditParams.shareUid;
        shareEditMode.deActivate(indexUid);
      }

      try {
        const { units } = await scClient.revertOrigin(indexUid);
        this.editorStore.setBusy(false);
        this.editorStore.triggerUnitsChanged(units);
        this.broadcastSharedContentRevertedEvent(units);
        await this.checkIfCanPublish();

        if (inShareEditMode) {
          shareEditMode.activate(
            units.map((unit) => new DocUnitWrapper(unit)),
            shareEditParams
          );
        }
      } catch (err) {
        if (inShareEditMode) {
          shareEditMode.deActivate();
        }
        this.editorStore.triggerShowEditorError({ axiosErr: err as AxiosError });
      }
    }
  }

  closeDiffMode() {
    this.diffMode.closeOtherDiffMode();
  }

  // Helpers

  private getSharedIndexUsage(
    sharedIndexUid: string,
    updateStrategy: string,
    insertType: string,
    ordinalLevelStrategy: string
  ): Partial<ISharedIndexUsages> {
    const sharedIndex: Partial<ISharedIndex> = {
      uid: sharedIndexUid,
      originProjectUid: this.editorStore.getDocParams().projectUid as string,
      originProjectName: ''
    };
    const index: Partial<IIndex> = { uid: this.editorStore.getDocParams().indexUid as string };
    const sharedIndexModel: Partial<ISharedIndexUsages> = {
      sharedIndex,
      index: index as IIndex, // hacky
      updateStrategy,
      ordinalLevelStrategy
    };
    if (insertType === 'replace' || insertType === 'none') {
      sharedIndexModel.units = [];
      this.editorStore.getSelectedUnits().forEach(({ uid }) => {
        sharedIndexModel.units!.push({ uid } as IUnit);
      });
      if (insertType === 'none') {
        sharedIndexModel.updateStrategy = 'NONE_REFERENCE';
      }
    }
    return sharedIndexModel;
  }

  private getShareUid() {
    return EditorModes.getProperties(this.editorStore.getMode())!.getParams().shareUid;
  }

  private canSelectShare({ uid }) {
    return (this.editorStore.isShareEditMode() && uid === this.getShareUid()) || !this.editorStore.isShareEditMode();
  }

  private async updateSharedUsage(unitUid: string, sharedIndexUid: string, updateStrategy: string, message?: string) {
    if (this.isActionAllowed()) {
      this.closeDiffMode();

      this.editorStore.setBusy(true, message);

      const sharedIndexUsage: Partial<ISharedIndexUsages> = {
        uid: sharedIndexUid,
        sharedIndex: { uid: sharedIndexUid },
        updateStrategy
      };
      await SmartContentStore.updateSharedUsage(unitUid, sharedIndexUsage, this.editorStore.getDocParams());
      this.editorStore.setBusy(false);
    }
  }

  private async checkIfCanPublish() {
    const { uid, masterIndexUid } = ProjectStore.getProject()!;
    await PublishStore.publishCheck(uid, masterIndexUid);
  }

  private isActionAllowed(): boolean {
    return (
      !this.editorStore.isBusy() &&
      ProjectStore.getProject()!.currentUserPermissions?.canManageShares &&
      !!this.editorStore.doesModeAllow(EditorModes.attributes.editing)
    );
  }

  // Events

  private triggerSelectShareOriginEvent(sharedIndexUid) {
    const changeEvent: IEditorStoreEvent<'selectShareOrigin'> = {
      type: 'selectShareOrigin',
      data: {
        isNew: sharedIndexUid === null,
        sharedIndexUid
      }
    };
    this.editorStore.trigger(changeEvent);
  }

  private triggerRequestSharedContentInsertEvent(sharedIndex, selectedUnit) {
    const changeEvent: IEditorStoreEvent<'requestSharedContentInsert'> = {
      type: 'requestSharedContentInsert',
      data: { sharedIndex, selectedUnit }
    };
    this.editorStore.trigger(changeEvent);
  }

  private triggerAcceptSharedContentInsertEvent(sharedIndexUid) {
    const changeEvent: IEditorStoreEvent<'acceptSharedContentInsert'> = {
      type: 'acceptSharedContentInsert',
      data: { sharedIndexUid }
    };
    this.editorStore.trigger(changeEvent);
  }

  private triggerRequestRejectSharedContentUpdateEvent(unitUid, sharedIndexUid) {
    const changeEvent: IEditorStoreEvent<'requestRejectSharedContentUpdate'> = {
      type: 'requestRejectSharedContentUpdate',
      data: { unitUid, sharedIndexUid }
    };

    this.editorStore.trigger(changeEvent);
  }

  private triggerPublishSharedOriginEvent() {
    const changeEvent: IEditorStoreEvent<'publishSharedOrigin'> = {
      type: 'publishSharedOrigin'
    };

    this.editorStore._triggerChangedEvent(changeEvent);
  }

  private broadcastSharedContentPublishedEvent() {
    const { indexUid, projectUid } = this.editorStore.getDocParams();
    IndexEventStore.broadcastToIndex({
      userUid: ActiveUserStore.getUser()!.uid,
      activity: 'sharedContentPublished',
      data: {
        indexUid: indexUid!,
        projectUid: projectUid!
      }
    });
  }

  private broadcastSharedContentRevertedEvent(affectedUnits: IUnit[]) {
    const { indexUid, projectUid } = this.editorStore.getDocParams();
    IndexEventStore.broadcastToIndex({
      userUid: ActiveUserStore.getUser()!.uid,
      activity: 'sharedContentReverted',
      data: {
        indexUid: indexUid!,
        projectUid: projectUid!,
        affectedUnits
      }
    });
  }
}
