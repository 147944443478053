import * as React from 'react';
import { IProjectActivity } from 'mm-types';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';
import * as client from '../../../../../clients/change-tasks';

type PropsType = {
  entry: IProjectActivity;
};

export const TaskUpdate = ({ entry }: PropsType) => {
  const taskDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber && !entry.isChild) {
    taskDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }

  if (entry.task) {
    const newList = [
      'number',
      'title',
      'colorHex',
      'description',
      'reason',
      'safetyParam',
      'effort',
      'expectedCompletionDate',
      'riskAssessment',
      'authorStatus',
      'reviewerStatus',
      'reviewerDisposition',
      'status',
      'active',
      'resolved'
    ];

    newList.forEach((key) => {
      const val = entry.task[key];

      if (val !== undefined && val !== null) {
        if (['uid', 'projectUid', 'indexUid'].indexOf(key) === -1 && !key.endsWith('Code')) {
          let name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
            return str.toUpperCase();
          });
          let valueText = val;

          if (_.isBoolean(val)) {
            valueText = val ? 'Yes' : 'No';
          }

          if (key === 'expectedCompletionDate') {
            name = 'Date';
            valueText = dateUtil(val).formatDateTimeNoSecs();
          } else if (key === 'reason') {
            name = 'Type';
            valueText = _.isObject(val) && 'title' in val ? (val.title as string).toLowerCase().replace('_', ' ') : val; // TODO @aaron - temp fix to handle new reason object
          } else if (key === 'colorHex') {
            name = 'Color';
            valueText = <div style={{ height: 15, width: 15, display: 'inline-block', backgroundColor: val }} />;
          } else if (['authorStatus', 'reviewerStatus', 'status'].indexOf(key) !== -1) {
            valueText = client.taskStates.userTaskStates[val];
          } else if (key === 'reviewerDisposition') {
            valueText = client.taskStates.reviewerDispositionStates[val];
          } else if (key === 'effort') {
            valueText = `${valueText} days`;
          } else if (key === 'riskAssessment') {
            name = 'Risk';
            valueText = client.taskStates.riskAssessmentStates[val];
          } else if (key === 'safetyParam') {
            name = 'Safety';
            valueText = client.taskStates.safetyParamStates[val];
          }

          taskDom.push(
            <div key={key}>
              <label>{name}:</label> <span>{valueText}</span>
            </div>
          );
        }
      }
    });
  }
  return <div className="settings-details">{taskDom}</div>;
};
