import * as Reflux from 'reflux';
import * as _ from 'lodash';
import ServerSettingsStore from '../../flux/common/ServerSettingsStore';
import * as mediaClient from '../../clients/media';
import { MediaResponse, MediaSort, SearchMediaParams, UploadMediaResponse } from '../../clients/media';
import Store from '../Store';
import { Cancelled } from '../../clients/base-clients';
import { IMedia } from 'mm-types';
import { AxiosError } from 'axios';
import { PaginationProps } from '../../components/general/Paging';
import pagingUtil from '../../utils/pagingUtil';
import { MediaUploadUtil } from './MediaStoreAddons/MediaUploadUtil';
import SystemInfoStore from '../common/SystemInfoStore';
import AppStateStore from '../common/AppStateStore';
import { InsertInfo } from '../../components/editor/EditorPage';
import ProjectDefinitionStore from '../common/ProjectDefinitionStore';
import { MEDIA_FILTER_TYPES, MediaFilterType, MediaTypesContants } from '../../components/editor/medialib/types';

export type MediaStoreEventType =
  | 'refreshMediaAttachmentIcon'
  | 'openMediaLib'
  | 'openMediaLibViewOnly'
  | 'applyChanges'
  | 'storeInitialised';

export type MediaStoreEvent<T extends MediaStoreEventType> = {
  error?: { code: string; message: string } | null;
  type: T;
  data: T extends 'openMediaLib'
    ? InsertInfo
    : T extends 'openMediaLibViewOnly'
    ? null
    : T extends 'refreshMediaAttachmentIcon'
    ? null
    : T extends 'applyChanges'
    ? State
    : T extends 'storeInitialised'
    ? { storeInitialised: boolean }
    : undefined;
};

export type State = {
  media: IMedia[];
  filterText?: string;
  pagination: PaginationProps;
  preservedPagination: boolean;
  isFetching: boolean;
  isUploading: boolean;
  projectUid: string;
  searchParams: SearchMediaParams;
  loadingThumbs: number;
  allowedMediaTypesToTypeFilter: MediaFilterType[];
};

export class MediaStore extends Store<State> {
  private mediaUploadUtil: MediaUploadUtil;
  private scrollTopPosition: number;

  constructor() {
    super();
    this.scrollTopPosition = 0;
    this.state = {
      media: [],
      projectUid: '',
      isFetching: false,
      isUploading: false,
      pagination: pagingUtil.getInitialValue(),
      preservedPagination: false,
      searchParams: this.getInitialListMediaParams(),
      loadingThumbs: 0,
      allowedMediaTypesToTypeFilter: []
    };
  }

  getInitialState() {
    return this.state;
  }

  setup(projectUid: string, filterType: MediaFilterType) {
    this.state.projectUid = projectUid;
    this.mediaUploadUtil = new MediaUploadUtil();
    this.state.searchParams = { ...this.state.searchParams, type: filterType };
    this.state.allowedMediaTypesToTypeFilter = this.filterAllowedMediaTypesToMediaTypeOptions();
    this.applyChanges();
    this.trigger({ type: 'storeInitialised', data: { storeInitialised: true } } as MediaStoreEvent<'storeInitialised'>);
  }

  clear() {
    this.state = {
      media: [],
      pagination: pagingUtil.getInitialValue(),
      preservedPagination: false,
      isFetching: false,
      isUploading: false,
      projectUid: this.state.projectUid,
      searchParams: this.getInitialListMediaParams(),
      loadingThumbs: 0,
      allowedMediaTypesToTypeFilter: this.filterAllowedMediaTypesToMediaTypeOptions()
    };
    AppStateStore.updateLastSelectedMediaItems([]);
    this.applyChanges();
  }

  preservePagination(preserve = true, scrollTopPosition?: number) {
    this.scrollTopPosition = scrollTopPosition || 0;
    this.applyChanges({
      preservedPagination: preserve
    });
  }

  onDropRejectedFiles(files: File[]) {
    if (!this.validateFiles(files)) {
      this.applyChanges({ isUploading: false });
    }
  }

  getScrollTopPosition(): number {
    return this.scrollTopPosition;
  }

  triggerOpenMediaLib(viewMode: boolean, insertInfo?: InsertInfo): void {
    this.trigger({ type: viewMode ? 'openMediaLibViewOnly' : 'openMediaLib', data: insertInfo } as MediaStoreEvent<'openMediaLib'>);
  }

  filterAllowedMediaTypesToMediaTypeOptions(): MediaFilterType[] {
    const allowedMediaTypes = ProjectDefinitionStore.getSupportedMediaTypesForIndex().toString();

    const filterTypes: MediaFilterType[] = MEDIA_FILTER_TYPES.map((type) => {
      switch (type) {
        case MediaTypesContants.image:
          return allowedMediaTypes.includes(MediaTypesContants.image) ? MediaTypesContants.image : null;
        case MediaTypesContants.video:
          return allowedMediaTypes.includes(MediaTypesContants.video) ? MediaTypesContants.video : null;
        case MediaTypesContants.symbol:
          return !!ProjectDefinitionStore.getCurrentIndexDefinition()?.mediaLibraryHasSymbols ? MediaTypesContants.symbol : null;
        case MediaTypesContants.audio:
          return allowedMediaTypes.includes('audio') ? MediaTypesContants.audio : null;
        case MediaTypesContants.other:
          return allowedMediaTypes.includes('application') || allowedMediaTypes.includes('text') ? MediaTypesContants.other : null;
        default:
          return MediaTypesContants.all;
      }
    }).filter((type) => type !== null) as MediaFilterType[];
    return filterTypes;
  }

  getMediaFilterTypes() {
    return this.state.allowedMediaTypesToTypeFilter;
  }

  // ASYNC

  async retrieveMedia(params: Partial<SearchMediaParams>) {
    if (this.shouldTakeMediaFromMemory()) {
      this.applyChanges();
      this.preservePagination(false);
    } else {
      this.setLoadingThumbs(0);
      await this.fetchData(params);
    }
  }

  async goToPage(pageNumber = 0) {
    this.calculateNumberOfLoadingThumbs(pageNumber);
    await this.fetchData({ pageNumber }, false);
  }

  async sortMedia(sort: MediaSort) {
    this.calculateNumberOfLoadingThumbs();
    await this.fetchData({ pageNumber: 0, sort });
  }

  async changeType(type?: MediaFilterType) {
    this.setLoadingThumbs(0);
    await this.fetchData({ pageNumber: 0, type });
  }

  async filterMedia(query?: string) {
    this.setLoadingThumbs(0);
    await this.fetchData({ pageNumber: 0, query });
  }

  async getDetails(mediaUid: string) {
    const response = await mediaClient.getDetails(this.state.projectUid, mediaUid);
    if (response instanceof Cancelled) {
      return;
    }
    return response as IMedia;
  }

  async deleteMedia(mediaUid: string, filename: string, sourceMediaUid: null | string = null) {
    try {
      await mediaClient.remove(this.state.projectUid, sourceMediaUid!);
      this.preservePagination(false);
      this.showAlert(`Media "${filename}" deleted`);
      return true;
    } catch (err) {
      const axiosErr = err as AxiosError;
      const alreadyInDocError = axiosErr.response && axiosErr.response.status !== 412;
      await this.fetchData({ pageNumber: 0 });
      return !!alreadyInDocError;
    }
  }

  async uploadFiles(files: File[]) {
    if (files.length === 0 || !this.validateFiles(files)) {
      this.applyChanges({ isUploading: false });
      return;
    }
    this.applyChanges({
      isUploading: true,
      loadingThumbs: 0
    });
    const response = await mediaClient.upload(this.state.projectUid, files, this.state.searchParams.type === 'symbol');
    this.handleUploadResponse(response);
  }

  async updateMedia(updatedMedia: IMedia, onSuccess?: (media: IMedia) => void) {
    try {
      const result = await mediaClient.editMedia(this.state.projectUid, updatedMedia.uid, updatedMedia);
      this.updateMediaInStore(result.data);
      this.applyChanges();
      this.fetchData({});
      if (onSuccess) {
        onSuccess(updatedMedia);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadSource(media: IMedia, files: File[]) {
    const response = await mediaClient.upload(this.state.projectUid, files, false, media.uid);
    this.handleUploadResponse(response, false);
  }

  private async fetchData(params: Partial<SearchMediaParams>, clearPagination = true) {
    let response: MediaResponse | Cancelled;
    const listMediaParams = this.getListMediaParams(params);
    this.applyChanges({
      isFetching: true,
      pagination: clearPagination ? pagingUtil.getInitialValue() : this.state.pagination
    });
    try {
      response = await mediaClient.getAll(listMediaParams);

      if (response instanceof Cancelled) {
        return;
      }
      AppStateStore.updateLastSelectedMediaItems([]);
      this.applyChanges({
        searchParams: listMediaParams,
        media: response.media,
        pagination: pagingUtil.extractFromResponse(response),
        isFetching: false,
        preservedPagination: false
      });
    } catch {
      this.applyChanges({ isFetching: false, media: [], preservedPagination: false, searchParams: listMediaParams });
    }
  }

  private async handleUploadResponse(response: UploadMediaResponse, fetchList = true) {
    const maxVideoHeight = ServerSettingsStore.getServerSettings()!.maxVideoHeight!;
    const maxImageSize = 20000;
    if (!response.allCompleted) {
      if (response.errors.length > 1) {
        this.showAlert(`${response.errors.length} files failed to upload...`);
      } else {
        const { code } = response.errors[0];
        if (code === 400184) {
          this.showAlert(`Your video exceeds the maximum height allowed. Please upload a video of less than ${maxVideoHeight} Pixels`);
        } else if (code === 400182) {
          this.showAlert(`Max image size must be under ${maxImageSize} pixels`);
        } else {
          // this.showAlert(message);
          // SystemStore._triggerLegacySystemError( { } );
        }
      }
    } else {
      this.showAlert(`Upload complete`);
    }
    this.applyChanges({ isUploading: false });
    if (fetchList && response.completed.length) {
      await this.fetchData({ pageNumber: 0 });
      this.setLoadingThumbs(0);
    }
  }

  private validateFiles(files: File[]): boolean {
    const error = this.mediaUploadUtil.validate(files);
    if (error) {
      const { type, value } = error[0];
      const message =
        type === null
          ? `Expected image or video but got ${value}`
          : `Your ${type} exceeds the maximum size allowed. Please upload a ${type} of less than ${value} Mbytes`;
      this.showAlert(message);
      return false;
    }
    return true;
  }

  private shouldTakeMediaFromMemory(): boolean {
    return this.state.preservedPagination;
  }

  private getListMediaParams(params: Partial<SearchMediaParams>): SearchMediaParams {
    const p = this.state.searchParams;
    return {
      projectUid: this.state.projectUid,
      type: this.getParamValue(params.type, p.type),
      pageNumber: this.getParamValue(params.pageNumber, this.state.pagination.pageNumber),
      query: this.getParamValue(params.query, p.query),
      sort: this.getParamValue(params.sort, p.sort),
      selectedMediaItemUid: this.getParamValue(params.selectedMediaItemUid, p.selectedMediaItemUid)
    };
  }

  private updateMediaInStore(media: IMedia) {
    const m: IMedia | undefined = this.getMediaByUid(media.uid);
    if (m) {
      m.caption = media.caption;
      m.description = media.description;
      m.type = media.type;
    }
  }

  private getMediaByUid(mediaUid: string): IMedia | undefined {
    return _.find(this.state.media, { uid: mediaUid });
  }

  private getInitialListMediaParams(): SearchMediaParams {
    return {
      projectUid: this.state.projectUid || '',
      pageNumber: 0,
      sort: 'created',
      type: MediaTypesContants.all,
      query: '',
      selectedMediaItemUid: undefined
    };
  }

  private getParamValue<T>(value: T | undefined, backupValue: T): T {
    return value !== undefined ? value : backupValue;
  }

  private calculateNumberOfLoadingThumbs(pageNumber = 0, paginationProps: PaginationProps = this.state.pagination) {
    this.setLoadingThumbs(pagingUtil.getNumberOfItems(pageNumber, paginationProps));
  }

  private setLoadingThumbs(no: number) {
    this.applyChanges({
      media: no === 0 ? this.state.media : [],
      loadingThumbs: no
    });
  }

  private applyChanges(newState: Partial<State> = {}) {
    const changeEvent: MediaStoreEvent<'applyChanges'> = { type: 'applyChanges', data: Object.assign(this.state, newState) };
    this.trigger(changeEvent);
  }

  private showAlert(message: string) {
    SystemInfoStore.showAlert(message);
  }
}

const singleton = Reflux.initStore<MediaStore>(MediaStore);
export default singleton;
