import { isSuccess } from '../../../../clients/base-clients';
import * as projectClient from '../../../../clients/project';
import * as projectsClient from '../../../../clients/projects';
import { IProject } from 'mm-types';

export async function deleteGebPersonalProjects(
  setFetching: Function,
  fetchCompleted: Function,
  setCompleted: Function,
  showMessage: Function
) {
  showMessage('Deleting projects...');
  setFetching(true);
  try {
    setCompleted(1);
    const response = await projectsClient.getAll();
    if (isSuccess(response)) {
      const gebProjects: IProject[] = response.projects.filter(
        (project) =>
          project.name?.startsWith('MM_SPA_') || project.name?.startsWith('abcdefgh') || project.name?.startsWith('TestSpecialIntere')
      );
      const total = gebProjects.length;
      if (!gebProjects.length) {
        fetchCompleted();
        showMessage('No projects to delete');
      } else {
        for (const project of gebProjects) {
          const index = gebProjects.indexOf(project);
          setCompleted(((index + 1) * 100) / total);
          try {
            await projectClient.remove(project.uid);
          } catch (e) {
            showMessage(e);
          }
        }
      }
      fetchCompleted();
      showMessage('All done!');
    }
  } catch (e) {
    showMessage(e);
    fetchCompleted();
  }
}
