import * as _ from 'lodash';
import UnitSharedOriginCreations from '../../../UnitSharedOriginCreations';
import UnitConceptStore from '../../../UnitConceptStore';
import SmartContentStore from '../../../SmartContentStore';
import { Position, markUnitsWithinSameSharedIndexAsChanged, canIgnoreUnit, createNewShareOriginUnit } from '../sharedUnitSelectionUtils';
import { IShareDetails, ISharedIndexOriginUnit, ISharedIndexOrigin, IUnit } from 'mm-types';
import ProjectDefinitionStore from '../../../../common/ProjectDefinitionStore';

export async function moveShareSelectionFromShareBody(
  position: Position,
  currentStartUnit: IUnit,
  newStartSharedUnit: IUnit,
  canIgnoreNewUnit: boolean,
  docUnitCollection: IUnit[]
) {
  const sharedIndexUid: string = currentStartUnit.shareDetails?.uid!;
  let actualNewSharedUnit: IUnit | null = null;

  markUnitsWithinSameSharedIndexAsChanged(currentStartUnit, docUnitCollection);
  if (canIgnoreNewUnit) {
    const currentUnitIndex = currentStartUnit.index;

    if (position.isStart) {
      for (let i = currentUnitIndex - 1; i >= 0; i--) {
        const siblingUnit = docUnitCollection[i];

        if (!siblingUnit) {
          return false;
        }

        if (!canIgnoreUnit(siblingUnit)) {
          const siblingUnitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
            siblingUnit.definitionId
          );
          if (siblingUnitProfile?.inlineOptions?.readonly || siblingUnit.shareDetails) {
            return false; // previous-most unit found is readonly, abandon selection, run out of road
          } else {
            for (let z = i; z <= currentUnitIndex - 1; z++) {
              const u = docUnitCollection[z];
              u.shareDetails = createNewShareOriginUnit(sharedIndexUid) as IShareDetails;
              UnitSharedOriginCreations.add(u.uid, u.shareDetails);
            }

            actualNewSharedUnit = docUnitCollection[i];
            actualNewSharedUnit.shareDetails = _.extend(createNewShareOriginUnit(sharedIndexUid) as IShareDetails, {
              isShareStartUnit: true
            });

            UnitSharedOriginCreations.add(actualNewSharedUnit.uid, actualNewSharedUnit.shareDetails);

            break;
          }
        }
      }
    } else {
      for (let i = currentUnitIndex + 1; i <= 9999; i++) {
        const siblingUnit = docUnitCollection[i];

        if (!siblingUnit) {
          return false;
        }

        if (!canIgnoreUnit(siblingUnit)) {
          const siblingUnitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
            siblingUnit.definitionId
          );
          if (siblingUnitProfile?.inlineOptions?.readonly || siblingUnit.shareDetails) {
            return false; // previous-most unit found is readonly, abandon selection, run out of road
          } else {
            for (let z = currentUnitIndex + 1; z < i; z++) {
              const u = docUnitCollection[z];
              u.shareDetails = createNewShareOriginUnit(sharedIndexUid) as IShareDetails;
              UnitSharedOriginCreations.add(u.uid, u.shareDetails);
            }

            actualNewSharedUnit = docUnitCollection[i];
            actualNewSharedUnit.shareDetails = _.extend(createNewShareOriginUnit(sharedIndexUid) as IShareDetails, {
              isShareEndUnit: true
            });

            UnitSharedOriginCreations.add(actualNewSharedUnit.uid, actualNewSharedUnit.shareDetails);

            break;
          }
        }
      }
    }
  } else {
    const newUnitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
      newStartSharedUnit.definitionId
    );

    if (newUnitProfile?.inlineOptions?.readonly || newStartSharedUnit.shareDetails) {
      return false;
    }

    // If the unit has variants - then disallow it
    if (UnitConceptStore.getUnitHasVariants(newStartSharedUnit.uid)) {
      return false;
    }

    actualNewSharedUnit = newStartSharedUnit;
    actualNewSharedUnit.shareDetails = _.extend(createNewShareOriginUnit(sharedIndexUid) as IShareDetails, {
      isShareStartUnit: position.isStart,
      isShareEndUnit: !position.isStart,
      sharedIndexUid: currentStartUnit.shareDetails?.sharedIndexUid
    });

    UnitSharedOriginCreations.add(actualNewSharedUnit.uid, actualNewSharedUnit.shareDetails);
  }

  currentStartUnit!.shareDetails![position.isStart ? 'isShareStartUnit' : 'isShareEndUnit'] = false;

  if (sharedIndexUid) {
    const originChange: ISharedIndexOriginUnit = { sharedIndex: { uid: sharedIndexUid } };
    originChange[position.isStart ? 'startUnit' : 'endUnit'] = { uid: actualNewSharedUnit!.uid };

    await SmartContentStore.updateSharedOrigin(originChange);
    return true;
  }
  // its new: so just stored in memory
  else {
    return true;
  }
}

export async function moveShareSelectionIntoShareBody(
  position: Position,
  currentStartUnit: IUnit,
  newStartSharedUnit: IUnit,
  canIgnoreNewUnit: boolean,
  docUnitCollection: IUnit[]
) {
  const sharedIndexUid = currentStartUnit.shareDetails?.uid;
  let newBoundarySharedUnit: IUnit | null = null;
  markUnitsWithinSameSharedIndexAsChanged(currentStartUnit, docUnitCollection);

  if (canIgnoreNewUnit) {
    const currentUnitIndex = currentStartUnit.index;

    if (position.isStart) {
      for (let i = currentUnitIndex + 1; i <= 9999; i++) {
        const siblingUnit = docUnitCollection[i];
        if (!canIgnoreUnit(siblingUnit)) {
          const siblingUnitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
            siblingUnit.definitionId
          );
          if (siblingUnitProfile?.inlineOptions?.readonly || !siblingUnit.shareDetails) {
            return false; // next-most unit found is readonnly, abandon selection, run out of road
          } else {
            for (let z = currentUnitIndex; z < i; z++) {
              const u = docUnitCollection[z];
              delete u.shareDetails;
              UnitSharedOriginCreations.remove(u.uid);
            }

            // set new start/end element
            newBoundarySharedUnit = docUnitCollection[i];
            newBoundarySharedUnit.shareDetails![position.isStart ? 'isShareStartUnit' : 'isShareEndUnit'] = true;
            UnitSharedOriginCreations.add(newBoundarySharedUnit.uid, newBoundarySharedUnit.shareDetails);

            break;
          }
        }
      }
    } else {
      for (let i = currentUnitIndex - 1; i >= 0; i--) {
        const siblingUnit = docUnitCollection[i];
        if (!canIgnoreUnit(siblingUnit)) {
          const siblingUnitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
            siblingUnit.definitionId
          );
          if (siblingUnitProfile?.inlineOptions?.readonly || !siblingUnit.shareDetails) {
            return false; // next-most unit found is readonnly, abandon selection, run out of road
          } else {
            for (let z = i + 1; z <= currentUnitIndex; z++) {
              const u = docUnitCollection[z];
              delete u.shareDetails;
              UnitSharedOriginCreations.remove(u.uid);
            }

            // set new start/end element
            newBoundarySharedUnit = docUnitCollection[i];
            newBoundarySharedUnit.shareDetails![position.isStart ? 'isShareStartUnit' : 'isShareEndUnit'] = true;
            UnitSharedOriginCreations.add(newBoundarySharedUnit.uid, newBoundarySharedUnit.shareDetails);

            break;
          }
        }
      }
    }
  } else {
    markUnitsWithinSameSharedIndexAsChanged(currentStartUnit, docUnitCollection);

    // if down onto unit that is not shared, or is shared but not our share, we've gone to far, abort selection!
    const newShareUnitShareDetails = newStartSharedUnit.shareDetails;
    if (!newShareUnitShareDetails || newShareUnitShareDetails.uid !== currentStartUnit.shareDetails?.uid) {
      return false;
    }

    newBoundarySharedUnit = newStartSharedUnit;
    newBoundarySharedUnit!.shareDetails![position.isStart ? 'isShareStartUnit' : 'isShareEndUnit'] = true;
    delete currentStartUnit.shareDetails;

    UnitSharedOriginCreations.remove(currentStartUnit.uid);
    UnitSharedOriginCreations.add(newBoundarySharedUnit!.uid, newBoundarySharedUnit!.shareDetails);
  }

  if (sharedIndexUid) {
    const originChange: Partial<ISharedIndexOrigin> = { sharedIndex: { uid: sharedIndexUid } };
    if (position.isStart) {
      originChange.startUnit = { uid: newBoundarySharedUnit!.uid };
    } else {
      originChange.endUnit = { uid: newBoundarySharedUnit!.uid };
    }

    await SmartContentStore.updateSharedOrigin(originChange);
    return true;
  }
  // its new: so just stored in memory
  else {
    return true;
  }
}
