import { isSuccess } from '../../../../clients/base-clients';
import * as projectClient from '../../../../clients/project';
import * as projectsClient from '../../../../clients/projects';
import * as workspacesClient from '../../../../clients/workspaces';
import { IWorkspace } from 'mm-types';

async function deleteWorkspace(uid: string) {
  const response = await projectsClient.getAll({ workspaceUid: uid });
  if (isSuccess(response)) {
    if (!response.projects.length) {
      return await workspacesClient.remove(uid);
    } else {
      await deleteDocuments(response.projects.map((project) => project.uid));
      return await workspacesClient.remove(uid);
    }
  } else {
    return Promise.resolve();
  }
}

async function deleteDocuments(uids: string[]) {
  await Promise.all(
    uids.map(async (uid) => {
      return await projectClient.remove(uid);
    })
  );
}

export async function deleteGebWorkspaces(setFetching: Function, fetchCompleted: Function, setCompleted: Function, showMessage: Function) {
  showMessage('Deleting workspaces...');
  setFetching(true);
  try {
    const workspaces = await workspacesClient.getAll({ canAdministerOnly: true });
    const gebWorkspaces: IWorkspace[] = workspaces.filter((space) => space.name.startsWith('MM_SPA_'));
    if (!gebWorkspaces.length || gebWorkspaces.length === 0) {
      fetchCompleted();
    } else {
      const total = gebWorkspaces.length;
      for (const workspace of gebWorkspaces) {
        const index = gebWorkspaces.indexOf(workspace);
        setCompleted(((index + 1) * 100) / total);
        try {
          await deleteWorkspace(workspace.uid);
        } catch (e) {
          showMessage(e);
        }
      }
      fetchCompleted();
      showMessage('All done!');
    }
  } catch (e) {
    showMessage(e);
    fetchCompleted();
  }
}
