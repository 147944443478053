export default class TwoWayLookup {
  private map: {};
  private rMap: {};

  constructor(map: {}) {
    this.map = map;
    this.rMap = {};

    for (const key in map) {
      const value = map[key];
      this.rMap[value] = key;
    }
  }

  lookup(key) {
    return this.map[key];
  }

  rLookup(key) {
    return this.rMap[key];
  }
}
