import * as Reflux from 'reflux';
import * as _ from 'lodash';
import EditorStore from './EditorStore';
import TocStore from './TocStore';
import * as client from '../../clients/units';
import Store from '../Store';
import { IHighlight } from 'mm-types';
import PublishStore from '../../flux/editor/PublishStore';
import ProjectStore from './ProjectStore';
import { AxiosError } from 'axios';
import RevisionStore from './RevisionStore';
import { Dom } from '../../components/editor/utils/tinyFacade/DomUtil';
import { UNIT_ELEMENT_ATTRIBUTE_NAME } from '../../components/editor/utils/units/const/UnitElementSelectors';

export type UnitHighlightStoreEvent = {
  state: State;
  type: 'error' | 'get-toc-highlight-details' | 'update-unit-highlight' | 'retrieve-toc-highlight';
};

export interface TocHighlightDetails {
  tocLevelOrdinal: string;
  tocLevelHeading: string;
  tocId: string;
  highlights: IHighlight[];
  selected: IHighlight | null;
}

export type State = {
  highlight: null | IHighlight;
  highlightDetails: TocHighlightDetails | null;
  rootHighlight: null | IHighlight;
  allHighlights: IHighlight[];
  error?: AxiosError;
};

export type ContextType = {
  unitUid: string;
  projectUid: string;
  indexUid: string;
};

export class UnitHighlightStore extends Store<State> {
  private currentTocUid: string | null = null;

  constructor() {
    super();
    this.state = {
      highlight: null,
      highlightDetails: null,
      rootHighlight: null,
      allHighlights: []
    };
  }

  getInitialState() {
    return this.state;
  }

  async retrieveTocHighlight(): Promise<void | IHighlight> {
    if (!RevisionStore.isPublishedRevision()) {
      return;
    }
    this.currentTocUid = TocStore.getFirstSelectedTocableUnit()?.uid ?? null;
    const docParams = EditorStore.getDocParams();

    if (!this.currentTocUid) {
      return;
    }

    const params: ContextType = {
      projectUid: docParams.projectUid!,
      indexUid: docParams.indexUid!,
      unitUid: this.currentTocUid
    };

    try {
      const highlight = await client.getHighlights(params.projectUid, params.indexUid, this.currentTocUid);
      this.triggerEvent.retrieveTocHighlight(highlight);
      return highlight;
    } catch (e) {
      this.triggerEvent.error(e as AxiosError);
      return;
    }
  }

  async toggleTocHighlight(unitUid: string) {
    if (!EditorStore.isReadOnly() && !EditorStore.isBusy()) {
      EditorStore.setBusy(true, 'Changing Rev Bar');
      const docParams = EditorStore.getDocParams();

      try {
        const highlight = await client.getHighlights(docParams.projectUid!, docParams.indexUid!, unitUid);
        await client.saveHighlights(docParams.projectUid!, docParams.indexUid!, unitUid, {
          ...highlight,
          ignored: !highlight.ignored
        });
        EditorStore.setBusy(false);
        await this.retrieveTocHighlight();
      } catch (e) {
        EditorStore.setBusy(false);
        this.triggerEvent.error(e as AxiosError);
        return;
      }
    }
  }

  async unitHighlightChange(tocUid: string | null | undefined, unitUid: string, highlight: Partial<IHighlight>) {
    const docParams = EditorStore.getDocParams();
    let updatedHighlight: IHighlight;
    try {
      updatedHighlight = await client.saveHighlights(docParams.projectUid!, docParams.indexUid!, unitUid, highlight);
      this.triggerEvent.updateHighlight(updatedHighlight);
    } catch (e) {
      this.triggerEvent.error(e as AxiosError);
      return;
    }

    PublishStore.workflowCheck(ProjectStore.getIndex()?.workflowUid ?? undefined);
  }

  async onUnitUpdate(unitUid: string, newHtml?: string) {
    if (!newHtml || !RevisionStore.isPublishedRevision()) {
      return;
    }
    const unitHighlight = this.findHighlightByUnitId(unitUid);
    const hasNoHighlight = !unitHighlight || !unitHighlight.type;
    if (
      hasNoHighlight ||
      (unitHighlight &&
        !this.isChangeOnlyInExistingHighlight(unitHighlight, $(newHtml).wrap('<div/>').parent().find('[class*="arc-revision-"]')))
    ) {
      return await this.retrieveTocHighlight();
    }
  }

  private isChangeOnlyInExistingHighlight(unitHighlight: IHighlight, newElements: JQuery): boolean {
    const existingElementDataNids: Set<string> = new Set(unitHighlight.elementHighlights?.map((elH) => elH.nid) ?? [unitHighlight.unitUid]);

    if (existingElementDataNids.size !== newElements.length) {
      return false;
    }
    const changedNids = new Set<string>();
    newElements.each((index, newElement) => {
      changedNids.add(Dom.getAttributeValue(newElement, UNIT_ELEMENT_ATTRIBUTE_NAME.NID) ?? '');
    });
    return _.isEqual(existingElementDataNids, changedNids);
  }

  async getHighlightForToc(tocUid: string | null | undefined = this.currentTocUid, unitUid: string) {
    if (!this.findHighlightByUnitId(unitUid)) {
      await this.retrieveTocHighlight();
    }
    const tocHighlight = this.findHighlightByUnitId(tocUid ?? '');

    if (tocHighlight && tocUid) {
      this.triggerEvent.getTocHighlightDetails(tocHighlight, unitUid);
    }
  }

  getTocHighlightsFlatMap(): IHighlight[] {
    return this.state.allHighlights;
  }

  getHighlightDetails(): TocHighlightDetails | null {
    return this.state.highlightDetails;
  }

  private findHighlightByUnitId(unitUid?: string): IHighlight | null {
    return this.state.allHighlights?.find((highlight) => highlight.unitUid === unitUid || highlight.tocLevelUid === unitUid) ?? null;
  }

  private containsUnitUid(highlights: IHighlight[], unitUid: string): boolean {
    return highlights.findIndex((h) => (h.unitUid ?? h.tocLevelUid) === unitUid) !== -1;
  }

  private flatHighlights(highlight: IHighlight | null, excludeParentsWithoutHighlight = false): IHighlight[] {
    if (!highlight) {
      return [];
    }
    const result: IHighlight[] = [highlight];

    if (!highlight.tocLevelHighlights?.length) {
      return result;
    }

    const currentHighlights: IHighlight[] = [...highlight.tocLevelHighlights];
    let uh: IHighlight = currentHighlights.shift()!;

    do {
      if (!this.containsUnitUid(result, uh.unitUid)) {
        result.push(uh);
      }
      if (uh.tocLevelHighlights?.length) {
        currentHighlights.unshift(...uh.tocLevelHighlights);
      }
    } while (currentHighlights.length && (uh = currentHighlights.shift()!) !== undefined);
    if (excludeParentsWithoutHighlight) {
      return result.filter((highlight) => !!highlight.type);
    }
    return result;
  }

  private get triggerEvent() {
    const self = this;

    const getSelectedUnit = (highlights: IHighlight[], unitUid): IHighlight | null => {
      return highlights.find((highlight) => highlight.unitUid === unitUid && !!highlight.type) ?? null;
    };

    const getHighlightDetails = (tocUid: string, unitUid: string, highlight?: IHighlight): TocHighlightDetails | null => {
      const tocNode = TocStore.getTocItem(tocUid);
      const highlights = this.flatHighlights(highlight ?? this.findHighlightByUnitId(tocUid), true);

      return {
        tocLevelOrdinal: tocNode?.ordinal,
        tocLevelHeading: tocNode?.heading,
        tocId: tocUid,
        highlights,
        selected: getSelectedUnit(highlights, unitUid)
      };
    };

    const retrieveTocHighlight = (responseHighlight: IHighlight | null) => {
      const selectedUnitUid = EditorStore.getSelectedUnit()?.uid;
      self.state.rootHighlight = responseHighlight;
      self.state.allHighlights = this.flatHighlights(responseHighlight);
      if (self.state.highlightDetails && selectedUnitUid) {
        self.state.highlightDetails = getHighlightDetails(self.state.highlightDetails.tocId, selectedUnitUid);
      }
      self.trigger(<UnitHighlightStoreEvent>{
        type: 'retrieve-toc-highlight',
        state: self.state
      });
    };

    const getTocHighlightDetails = (responseHighlight: IHighlight | null, unitUid?: string) => {
      if (responseHighlight && unitUid) {
        self.state.highlightDetails = getHighlightDetails(
          responseHighlight.unitUid ?? responseHighlight.tocLevelUid,
          unitUid,
          responseHighlight
        );
      } else {
        self.state.highlightDetails = null;
      }

      setTimeout(() => {
        self.trigger(<UnitHighlightStoreEvent>{
          type: 'get-toc-highlight-details',
          state: { ...self.state }
        });
      }, 0);
    };

    const updateHighlight = (responseHighlight: IHighlight) => {
      if (self.state.highlightDetails) {
        const details = { ...self.state.highlightDetails };
        const highlight = details.highlights.find((highlight) => highlight.unitUid === responseHighlight.unitUid);
        if (highlight) {
          highlight.comment = responseHighlight.comment;
          highlight.includedInWhatsNew = responseHighlight.includedInWhatsNew;
          highlight.privateFlag = responseHighlight.privateFlag;
          highlight.displayName = responseHighlight.displayName;
        }
        if (details.selected?.unitUid === responseHighlight.unitUid) {
          details.selected = responseHighlight;
        }
        self.state.highlightDetails = { ...details };
      }
      self.trigger(<UnitHighlightStoreEvent>{
        type: 'update-unit-highlight',
        state: { ...self.state } // make sure that state has a new object instance ( React hooks needs this to detect changes )
      });
    };

    const error = (axiosError: AxiosError) => {
      console.error(axiosError);
      self.state.error = axiosError;

      self.trigger(<UnitHighlightStoreEvent>{
        type: 'error',
        state: { ...self.state }
      });
    };

    return {
      retrieveTocHighlight,
      getTocHighlightDetails,
      updateHighlight,
      error
    };
  }
}

const singleton = Reflux.initStore<UnitHighlightStore>(UnitHighlightStore);
export default singleton;
