import { CustomEditor } from './EditorInstanceManager';
import { Dom } from './DomUtil';
import { dom } from 'tinymce';
import { UNIT_ELEMENT_CLASS_NAME } from '../units/const/UnitElementSelectors';
import { DomAssertions } from './DomAssertionsUtil';

export interface SelectedElementInDom {
  element: HTMLElement | Text | Comment | Document;
  partialHTMLElement: boolean;
  isTextNode: boolean;
  isHTMLElement: boolean;
  classList: DOMTokenList | null;
}

export default class SelectionUtil {
  protected tinySelection: dom.Selection;

  constructor(editor: CustomEditor) {
    this.tinySelection = editor.selection;
  }

  getHTMLElement(selected: SelectedElementInDom): HTMLElement | Text | Comment | Document {
    return selected.element;
  }

  getFilteredElementsByClass(className: string): SelectedElementInDom[] {
    return this.allElements().filter(
      (element) => element.classList && DomAssertions.hasClassName(element.element as HTMLElement, className)
    );
  }

  getFilteredElementsByAttr(attrName: string): SelectedElementInDom[] {
    return this.allElements().filter(
      (element) => (element.element as Element).attributes && DomAssertions.hasAttribute(element.element as Element, attrName)
    );
  }

  allElements(commonAncestor?: Element | Node): SelectedElementInDom[] {
    const result: SelectedElementInDom[] = [];
    const ancestorNode = commonAncestor || this.commonAncestor;

    Dom.getNodesIn(ancestorNode)
      .toArray()
      .forEach((element) => {
        if (this.selection.containsNode(element, true)) {
          result.push({
            element,
            partialHTMLElement: !this.selection.containsNode(element, false), // doesnt work with textNodes
            isTextNode: DomAssertions.isTextNode(element),
            isHTMLElement: DomAssertions.isHTMLElement(element),
            classList: DomAssertions.isHTMLElement(element) ? element.classList : null
          });
        }
      });
    return result;
  }

  areAllElementsInsideEntirelySelected(element: Element, elementToTest: Element[]): boolean {
    let allSelected = true;
    const range = this.range;
    const textNodes = Dom.getTextNodesIn($(elementToTest));

    if (range.startOffset !== this.countLeadingSpaces(range.startContainer) || range.endOffset !== range.endContainer.textContent!.length) {
      return false;
    }
    textNodes.forEach((txt) => {
      if (allSelected && !this.selection.containsNode(txt, false)) {
        allSelected = false;
      }
    });
    return allSelected;
  }

  selectElement(element: Element, content?: boolean) {
    this.tinySelection.select(element, content);
  }

  collapse(toStart?: boolean) {
    this.tinySelection.collapse(toStart);
  }

  selectRange(rng: Range) {
    this.tinySelection.setRng(rng);
  }

  get selection(): Selection {
    return (this.tinySelection.getSel as any)();
  }

  get range(): Range {
    return this.tinySelection.getRng(true);
  }

  get commonAncestor(): Element {
    return this.tinySelection.getNode();
  }

  isEntireNodeSelected(node: Element | Node): boolean {
    const range = this.range;
    const allSelectedElements = this.allElements(node);
    const allElementsInCommonAncestor = Dom.getNodesIn(node);
    const rangeStartsAtBeginning = range.startOffset === this.countLeadingSpaces(range.startContainer);

    if (allSelectedElements.length !== allElementsInCommonAncestor.length || !rangeStartsAtBeginning) {
      return false;
    }
    return range.endOffset === (range.endContainer.textContent ? range.endContainer.textContent.length : 0);
  }

  countLeadingSpaces(node: Text | Node): number {
    const text = DomAssertions.isTextNode(node) ? node.wholeText : node.textContent || '';
    return text.length - text.trimLeft().length;
  }

  getEditableTextNodesInSelection(): SelectedElementInDom[] {
    return this.allElements().filter(
      (element) => element.isTextNode && !DomAssertions.hasClassName(element.element.parentElement!, UNIT_ELEMENT_CLASS_NAME.NOT_EDITABLE)
    );
  }
}
