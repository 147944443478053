const toRet = {
  calc_offset_top: function (el, className) {
    let total = 0,
      child = el;

    while (child && !(child.classList && child.classList.contains(className))) {
      total += child.offsetTop;
      child = child.offsetParent;
    }

    return total;
  }
};

export default toRet;
