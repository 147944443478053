import * as React from 'react';
import * as Reflux from 'reflux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

const ACTION_UNIT_TYPES = [
  { name: 'Action Text', value: 'action' },
  { name: 'Challenge/Response', value: 'crm' }
];

const ACTION_UNIT_FORMATS = [
  { name: 'Normal', value: 'normal' },
  { name: 'Transit', value: 'transit' },
  { name: 'Memory', value: 'memory' },
  { name: 'Custom', value: 'custom' }
];

export type State = {
  type: string;
  format: string;
};

export default class ActionEditProps extends Reflux.Component<IGenericEditProps, State> {
  private unsubscribe: Function;
  private _userOpened: boolean;

  constructor(props: IGenericEditProps) {
    super(props);
    this.state = {
      type: ACTION_UNIT_TYPES[1].value,
      format: ACTION_UNIT_FORMATS[0].value
    };
  }

  UNSAFE_componentWillMount() {
    this._updateState();
    this.unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  _onEditStoreUpdate(e) {
    if (e.type === 'nestedUnitFocusChange') {
      // component stays mounted as we want it to persist on internal unit changes (e.g. table cells) otherwise would be mounted and unmounted on cursor changes
      if (!(e.data.focused === null && e.data.parent === null)) {
        this._updateState();
      }
    }
  }

  _updateState() {
    if (this.props.unitElement) {
      const dataSet = this.props.unitElement.dataset;
      this.setState({ format: dataSet.actionFormat!, type: dataSet.actionType! });
    }
  }

  _handleChange(e, field, value) {
    this.props.unitElement.setAttribute('data-action-' + field, value);
    this._updateState();

    setTimeout(() => {
      EditorStore.getEditor().silentReFocus();
    }, 300);
  }

  render() {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        <ToggleSection
          title="Action Properties"
          id={'unitProps_' + this.props.index}
          defaultOpen={false}
          forceClose={!this.props.isLeaf}
          forceOpen={this.props.isLeaf || this._userOpened}
          onToggled={(isOpened) => {
            this._userOpened = isOpened;
          }}
        >
          <div className="selected-unit-props-section action-edit">
            <SelectField
              className="typeInput"
              value={this.state.type}
              floatingLabelText="Type"
              fullWidth={true}
              onChange={(e, index, value) => this._handleChange(e, 'type', value)}
            >
              {ACTION_UNIT_TYPES.map((type) => (
                <MenuItem key={type.value} value={type.value} primaryText={type.name} />
              ))}
            </SelectField>

            <SelectField
              className="formatInput"
              value={this.state.format}
              floatingLabelText="Format"
              fullWidth={true}
              onChange={(e, index, value) => this._handleChange(e, 'format', value)}
            >
              {ACTION_UNIT_FORMATS.map((format) => (
                <MenuItem key={format.value} value={format.value} primaryText={format.name} />
              ))}
            </SelectField>
          </div>

          {UnitUtils.isFormatingAvailable(this.props.unitElement) && (
            <MultiFormatting formatNode={this.props.index === 0 ? null : this.props.unitElement} unitProfile={this.props.unitProfile} />
          )}
          <DataAttributesPropsWrapper {...this.props} />
        </ToggleSection>
      </div>
    );
  }
}
