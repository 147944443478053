import { EditorMode } from './EditorMode';
import { SynchronizationQueue } from './SynchronizationQueue';
import { EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';

export class EditingMode extends EditorMode {
  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.submenuTabs | attributes.mainmenuTabs | attributes.editing | attributes.shareTab, syncQueue);
  }

  activateTransition(previousMode: EditorModes) {
    return this._getFlushSyncQueueRefreshType('NONE');
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    return 'NONE';
  }
}
