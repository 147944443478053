import { docUnitProfile as list } from '../list';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  ...list,
  displayName: 'Bullet List',
  getLabel: () => 'LIST',
  identifyingClasses: ['arc-ul', /* "arc-li",*/ 'arc-unli'],
  identifyingElements: ['ul'],
  outerClass: 'arc-ul',
  outerElement: 'ul',
  isEncapsulatingElement: true,
  template: ''
};
