import * as React from 'react';
import ContentTypes from './SharedContentLibTypes';
import RegulationTocPopup from './RegulationTocPopup';
import { ISharedIndex } from 'mm-types';
import { Expanded } from './SmartContentLib';

export type Props = {
  item: ISharedIndex;
  openRegDocumentInline: (e: { projectUid: string; lastPublishedIndexUid: string; startUnitUid: string }) => void;
  onError: (error: Error) => void;
  expanded?: Expanded;
};

export type State = {};

export default class RegulatoryItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static defaultProps: Partial<Props> = {
    expanded: {
      loading: false,
      uid: null,
      content: null,
      usages: null
    }
  };

  _openTocPopup() {
    (this.refs.regTocPopup as RegulationTocPopup).open();
  }

  _tocItemSelectedCallback(section) {
    if (section) {
      this.props.openRegDocumentInline({
        projectUid: this.props.item.uid,
        lastPublishedIndexUid: this.props.item.lastPublishedIndexUid,
        startUnitUid: section.uid
      });
    }
  }

  _tocItemSelectedError(r) {
    this.props.onError(r);
  }

  _getItemType() {
    const itemType = ContentTypes.findByKey('regulation');
    return (
      <span>
        <span className="material-icons">{itemType.icon}</span>
        <span className="sc-item-footer__type-title">{itemType.title}</span>
      </span>
    );
  }

  render() {
    const itemCls = 'sc-item-outer sc-item-reference-outer';

    return (
      <div className={itemCls}>
        <div className="sc-item-inner" onClick={(e) => this._openTocPopup()}>
          <div className="sc-item-top">
            <div className="sc-item-header">{this.props.item.name}</div>
            <div className="sc-item-buttons">
              <a className="sc-item-button">
                <span className="material-icons">toc</span>
              </a>
            </div>

            <RegulationTocPopup
              ref="regTocPopup"
              projectUid={this.props.item.uid}
              documentIndexUid={this.props.item.lastPublishedIndexUid}
              onSelected={(e) => this._tocItemSelectedCallback(e)}
              onSelectionError={(error) => this._tocItemSelectedError(error)}
            />
          </div>

          <div className="sc-item-sub-header">
            Teamspace: {this.props.item.workspaceName}, Owner: {this.props.item.owner.displayName}
          </div>
        </div>

        <div className={'sc-item-footer-outer'}>
          <div className="sc-item-footer">
            <div className="sc-item-footer__type">{this._getItemType()}</div>

            <div className="sc-item-footer__extra">
              <span className="tagcount-counter counter">{this.props.item.totalUnitTagCount}</span>
              <span className="tagcount-title">TAGS</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
