import { EditorMode } from './EditorMode';
import { IVariant } from 'mm-types';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import * as baseClient from '../../../../clients/base-clients';
import ProjectStore from '../../ProjectStore';
import { Banner, EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class VariantMode extends EditorMode {
  private _variant: IVariant | null = null;

  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.submenuTabs | attributes.mainmenuTabs | attributes.editing | attributes.shareTab, syncQueue);
  }

  getParams() {
    return {
      variant: this._variant
    };
  }

  activate(currentPageUnits: DocUnitWrapper[], params: any) {
    if (params) {
      this._variant = params.variant;

      if (this._variant) {
        baseClient.setVariantHeader(this._variant.uid!);
      }

      $(document).ajaxSend((event, jqXHR, ajaxOptions) => {
        if ((jqXHR as any).variantOverriden) {
          return;
        }

        if (this._variant) {
          ProjectStore.setSelectedVariantUid(this._variant.uid);
          jqXHR.setRequestHeader('X-Index-Variant', this._variant.uid!);
        }
      });
    }
  }

  deActivate() {
    this._variant = null;
    baseClient.removeVariantHeader();

    $(document).ajaxSend((event, jqXHR, ajaxOptions) => {
      delete jqXHR['X-Index-Variant'];
    });
  }

  getBanner(): Banner {
    if (this._variant) {
      return {
        title: 'Variant: ' + this._variant.name,
        className: 'variant'
      };
    } else {
      return null;
    }
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'RELOAD_TOC';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    return 'RELOAD_TOC';
  }

  queueSynchronization(syncData) {
    return this.syncQueue.queue(syncData);
  }
}
