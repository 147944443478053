import * as React from 'react';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';
import Divider from 'material-ui/Divider';
import FontIcon from 'material-ui/FontIcon';
import DocumentsStore from '../../flux/projects/DocumentsStore';
import WorkspaceStore, { WorkspaceStoreEvent } from '../../flux/common/WorkspaceStore';
import { IWorkspace } from 'mm-types';
import { transitionTo } from '../../utils/Navigation';
import AppStateStore from '../../flux/common/AppStateStore';

export type Props = {
  workspaceUid?: string;
};

export type State = {
  workspaces: IWorkspace[];
  privateTsVisible: boolean;
  publicTsVisible: boolean;
};

export default class DocsMenu extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this.unsubscribe = WorkspaceStore.listen(this._onWorkspaceStoreChange, this);
    this.state = {
      workspaces: [],
      privateTsVisible: true,
      publicTsVisible: false
    };
  }

  componentDidMount() {
    if (this.props.workspaceUid) {
      AppStateStore.updateLastAccessedWorkspace(this.props.workspaceUid);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // TODO this sucks as causes loads of re-renders
  _onWorkspaceStoreChange(state: WorkspaceStoreEvent) {
    const workspaceUid = this.props.workspaceUid;
    if (workspaceUid && (workspaceUid !== 'workspace' || (workspaceUid as string) !== 'shared' || (workspaceUid as string) !== 'all')) {
      this.setState(state, () => {
        setTimeout(() => {
          const elUid = 'ws-menu-item-' + this.props.workspaceUid!;
          if (document.getElementById(elUid)) {
            document.getElementById(elUid)!.scrollIntoView(true);
          }
        }, 100);
      });
    }
  }

  _handleClick(workspaceUid: string) {
    DocumentsStore.updateProjectSelect(null);
    transitionTo('/teamspaces/' + workspaceUid);
  }

  _toggleHeader(hdr: string) {
    if (hdr === 'teamspace') {
      this.setState({ privateTsVisible: !this.state.privateTsVisible });
    } else {
      this.setState({ publicTsVisible: !this.state.publicTsVisible });
    }
  }

  _getTeamspaces(isPublic = false) {
    const workspacesItems: JSX.Element[] = [];

    let i = 0;
    this.state.workspaces.forEach((workspace) => {
      if (workspace.type === 'TEAM' && workspace.public === isPublic) {
        workspacesItems.push(
          <ListItem
            key={workspace.uid}
            onClick={() => {
              this._handleClick(workspace.uid);
            }}
            primaryText={workspace.name}
            id={'ws-menu-item-' + workspace.uid}
            title={workspace.name}
            className={'corenav-item teamspace-item' + (this.props.workspaceUid === workspace.uid ? ' selected' : '')}
            style={{ borderLeft: '8px solid ' + workspace.colorHex }}
          />
        );
        workspacesItems.push(<Divider key={`div${i++}`} />);
      }
    });

    return workspacesItems;
  }

  render() {
    const privateTeamspaces = this._getTeamspaces();
    const publicTeamspaces = this._getTeamspaces(true);
    let personalWs: Partial<IWorkspace> = WorkspaceStore.getPersonalWorkspace()!;
    personalWs = personalWs ? personalWs : { uid: '' }; // for initial render

    return (
      <div className={'corenav-container workspaces-menu'}>
        <div className="corenav-outer" key="workspaces">
          <List className="corenav-inner">
            <ListItem
              onClick={() => {
                this._handleClick('all');
              }}
              primaryText="All Documents"
              className={'corenav-item' + (this.props.workspaceUid === 'all' ? ' selected all ' : ' header-item')}
              leftIcon={<FontIcon className="material-icons">assignment</FontIcon>}
            />
            <ListItem
              onClick={() => {
                this._handleClick(personalWs.uid!);
              }}
              primaryText="Personal"
              className={
                'corenav-item' +
                (!this.props.workspaceUid || this.props.workspaceUid === personalWs.uid || this.props.workspaceUid === 'workspace'
                  ? ' selected workspace'
                  : ' header-item')
              }
              leftIcon={<FontIcon className="material-icons">person</FontIcon>}
            />
            <Divider />
            <ListItem
              onClick={() => {
                this._handleClick('shared');
              }}
              primaryText="Shared with me"
              className={'corenav-item' + (this.props.workspaceUid === 'shared' ? ' selected shared' : ' header-item')}
              leftIcon={<FontIcon className="material-icons">folder_shared</FontIcon>}
            />
            <Divider />
          </List>
          <div
            className={
              'teamspaces-list-outer' +
              (this.state.publicTsVisible ? ' public-ts-visible' : '') +
              (this.state.privateTsVisible ? ' private-ts-visible' : '')
            }
            ref="privateTeamspaces"
            key="privateTeamspaces"
          >
            {publicTeamspaces.length !== 0 && (
              <List className="corenav-inner">
                <ListItem
                  onClick={() => this._toggleHeader('public-teamspace')}
                  primaryText="Public Teamspaces"
                  className={
                    'corenav-item header-item' + (publicTeamspaces.length && this.state.publicTsVisible ? ' header-item-opened' : '')
                  }
                  leftIcon={<FontIcon className="material-icons">public</FontIcon>}
                  rightIcon={<FontIcon className="material-icons">{this.state.publicTsVisible ? 'expand_more' : 'expand_less'}</FontIcon>}
                />
              </List>
            )}

            {publicTeamspaces.length !== 0 && this.state.publicTsVisible && (
              <List className="corenav-inner public-teamspace-list">{publicTeamspaces}</List>
            )}

            {privateTeamspaces.length !== 0 && (
              <List className="corenav-inner">
                <ListItem
                  onClick={() => this._toggleHeader('teamspace')}
                  primaryText="Teamspaces"
                  className={
                    'corenav-item header-item' + (privateTeamspaces.length && this.state.privateTsVisible ? ' header-item-opened' : '')
                  }
                  leftIcon={<FontIcon className="material-icons">people</FontIcon>}
                  rightIcon={<FontIcon className="material-icons">{this.state.privateTsVisible ? 'expand_more' : 'expand_less'}</FontIcon>}
                />
              </List>
            )}

            {privateTeamspaces.length !== 0 && this.state.privateTsVisible && (
              <List className="corenav-inner private-teamspace-list">{privateTeamspaces}</List>
            )}
          </div>
        </div>

        <div className="corenav-outer" key="trash">
          <List className="corenav-inner">
            <ListItem
              onClick={() => {
                this._handleClick('trash');
              }}
              primaryText="Trash"
              className={'corenav-item' + (this.props.workspaceUid === 'trash' ? ' selected trash' : ' header-item')}
              leftIcon={<FontIcon className="material-icons">delete</FontIcon>}
            />
            <Divider />
          </List>
        </div>
      </div>
    );
  }
}
