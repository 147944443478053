import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Chapter Frontmatter',
  inlineOptions: {
    readonly: true
  },
  nonSelectable: false,
  template: ''
};
