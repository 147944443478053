import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../appStore';
import { Features } from 'mm-types';
import { Dispatch } from 'redux';
import { Action } from './types';
import { useCallback } from 'react';
import { setFeatureOff, setFeatureOn } from './actions';

export const useFeatureSwitches = () => {
  const features = useSelector<AppState, AppState['features']>((state) => state.features);
  const dispatch = useDispatch<Dispatch<Action>>();

  const setFeature = useCallback(
    (name: keyof Features, on: boolean) => {
      return dispatch(on ? setFeatureOn(name) : setFeatureOff(name));
    },
    [dispatch]
  );

  function isFeatureOn(name: keyof Features): boolean {
    return !!features[name];
  }

  return {
    features,
    setFeature,
    isFeatureOn
  };
};
