import * as React from 'react';
import UnitConceptStore, { UnitConceptStoreEvent } from '../../../../flux/editor/UnitConceptStore';
import { IAssignedSearchTerm, IUnit } from 'mm-types';
import Tag from '../../sidetabs/sub/facetedTags/facetTag';
import CircularProgress from 'material-ui/CircularProgress';
import EditorStore from '../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../flux/editor/ProjectStore';
import { SvgDropdownIcon } from '../../../misc/icons/SvgDropdownIcon';
import useListenToStore from '../../../hooks/useListenToStore';

type Props = {
  title?: string;
  unit: IUnit;
  onClose: any;
};

const FacetTagDropDown = ({ title = 'Search Tags', unit, onClose }: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const onStoreChange = (event: UnitConceptStoreEvent) => {
    switch (event.type) {
      case 'retrieved-unit-facet-map':
        setLoading(false);
        break;
    }
  };

  useListenToStore({ store: UnitConceptStore, eventListener: onStoreChange });

  const removeTag = (deletedTag: IAssignedSearchTerm) => {
    const docParams = EditorStore.getDocParams();
    setLoading(true);
    UnitConceptStore.removeConceptFromUnit(deletedTag, docParams, [unit.uid]);
  };

  const isInRevMode = EditorStore.isReadOnly();
  const isInterim = ProjectStore.isInterim();
  const editableTags: IAssignedSearchTerm[] = [];
  const readOnlyTags: IAssignedSearchTerm[] = [];
  const terms: IAssignedSearchTerm[] = [];

  const a = UnitConceptStore.getUnitConceptMap(unit.uid);
  if (a) {
    a.searchTerms.forEach((tag) => {
      if (tag.editable) {
        editableTags.push(tag);
      } else {
        readOnlyTags.push(tag);
      }
    });
    terms.push(
      ...editableTags.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.uid).indexOf(obj.uid) === pos;
      })
    );
    terms.push(
      ...readOnlyTags.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.uid).indexOf(obj.uid) === pos;
      })
    );
  }

  return (
    <div className="facet-dropdown">
      <SvgDropdownIcon onClick={onClose} />
      <div className="facet-dropdown-title">
        {title}
        {loading ? <CircularProgress className="facets-loading" size={16} thickness={3} /> : null}
      </div>
      <div className="facet-dropdown-inner">
        <div className="faceted-tags">
          <div className={`tag-list`}>
            {terms.map((tag) => {
              return (
                <Tag
                  term={tag}
                  inRevisionMode={isInRevMode || isInterim}
                  key={`${tag.uid!}${tag.editable ? '-editable' : '-readOnly'}`}
                  onRemoveTag={(tag) => {
                    if (loading) {
                      return;
                    }
                    removeTag(tag);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { FacetTagDropDown };
