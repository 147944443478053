import * as React from 'react';
import service from './linkService';
import TextField from 'material-ui/TextField';

export type Props = {
  onChange: (text: string) => void;
  showValidation: boolean;
  value?: string;
  type?: string;
};

export type LinkDestinationInputType = {
  isValid: () => boolean;
};

const LinkDestinationInput = React.forwardRef(
  ({ type = '', value = '', showValidation, onChange }: Props, ref: React.Ref<LinkDestinationInputType>) => {
    React.useImperativeHandle(ref, () => ({
      isValid
    }));

    const getForType = (name: string) => {
      return service.webTypes.find((lt) => {
        return lt.name === type;
      })![name];
    };

    const isValid = () => {
      return getForType('validate').test(value);
    };

    const errorText = () => {
      if (isValid() || !showValidation) {
        return null;
      } else {
        return getForType('msg');
      }
    };

    const onChangeHandler = (e, text: string) => {
      if (onChange) {
        if (type === 'mail') {
          text = text.trim();
        }
        onChange(text);
      }
    };

    return (
      <TextField
        floatingLabelText="Link Destination"
        id="link-destination-input"
        onChange={onChangeHandler}
        value={value}
        errorText={errorText()}
        errorStyle={{ bottom: '3px' }}
      />
    );
  }
);

export default LinkDestinationInput;
