import { IUnit, IUnitConceptMap } from 'mm-types';
import * as _ from 'lodash';
import { CustomEditor } from '../../utils/tinyFacade/EditorInstanceManager';
import DocumentVisualStates, { VisualStates } from '../../utils/DocumentVisualStates';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../flux/editor/EditorStore';
import { ActionEvent } from '../../docUnit/DocUnit';
import * as keyIdentifier from '../../utils/keyIdentifier';
import SystemStore from '../../../../flux/common/SystemStore';
import { SESSION_STORAGE_KEYS } from '../../../../utils';
import SpellCheckStore from '../../../../flux/editor/SpellcheckStore';
import FindReplaceStore from '../../../../flux/editor/FindReplaceStore';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

export namespace DocumentUtils {
  export const replaceZoom = (node, zoomValue: number) => {
    node
      .removeClass(function (index, css) {
        return (css.match(/(^|\s)editing-zoom-\S+/g) || []).join(' ');
      })
      .addClass('editing-zoom-' + zoomValue * 100);
  };

  export const isTableEditorInstance = (activeEditorInstance: CustomEditor) => {
    return activeEditorInstance.bodyElement?.nodeName === 'TABLE';
  };

  export const getActiveVisualStateClasses = (docVisualStates: VisualStates[]) => {
    let activeVisualStateClasses = 'editing-stage-page-inner';

    docVisualStates.forEach((vState) => {
      const className = DocumentVisualStates.props.get(vState)?.className;
      if (className) {
        activeVisualStateClasses += ' ' + className;
      }
    });

    return activeVisualStateClasses;
  };

  export const getFacetedTagsCount = (conceptMap: { [name: string]: IUnitConceptMap } | undefined, docUnitUid: string) => {
    let facetedTagsCount:
      | {
          activeTagCount: number;
          inActiveTagCount: number;
        }
      | undefined = undefined;

    if (conceptMap && conceptMap[docUnitUid]) {
      const unitFacets = conceptMap[docUnitUid].searchTerms;
      let activeTagCount = 0,
        inActiveTagCount = 0;
      if (unitFacets) {
        unitFacets.forEach((unitTag) => {
          if (unitTag.editable) {
            activeTagCount++;
          } else {
            inActiveTagCount++;
          }
        });
      }

      facetedTagsCount = {
        activeTagCount: activeTagCount,
        inActiveTagCount: inActiveTagCount
      };
    }
    return facetedTagsCount;
  };

  export const canSelect = (docVisualStates: VisualStates[], unit: IUnit | null) => {
    // diff mode units
    if (!unit || (unit && !(unit.hasOwnProperty('type') && unit.hasOwnProperty('definitionId')))) {
      return false;
    }

    if (unit.shareDetails && unit.shareDetails.inDiffMode) {
      return false;
    }

    // if marked as unselectable or its a non-origin share
    const dup = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles();
    const unitOrElementId = unit.definitionId || (unit.definition ? unit.definition!.id : unit.type || '');
    const profile = dup.getUnitProfileByDefinitionId(unitOrElementId);
    if (unit && profile && profile.nonSelectable) {
      return false;
    }

    // work this into DocUnitEditProfiles without leaking deletedElements state

    if (unit && docVisualStates.indexOf('SHOW_DELETED') === -1 && (unit.type === 'removed' || unit.type === 'ghost')) {
      return false;
    }

    return true;
  };

  export const handleInlineAction = (e: ActionEvent) => {
    EditorStore.triggerInlineUnitAction(e);
  };

  const handleShortcutKeys = (e: React.KeyboardEvent, action: ActionEvent['action'], unit: IUnit | INestedUnitFocusChangeEvent) => {
    handleInlineAction({
      action,
      src: 'shortcutKeys',
      data: { unit }
    });
    e.stopPropagation();
    return false;
  };

  export const onUnitOperationKey = (e: React.KeyboardEvent, unit: IUnit | null) => {
    if (!EditorStore.isReadOnly() && unit) {
      if (keyIdentifier.isCopyKeys(e)) {
        return handleShortcutKeys(e, 'copy-docunit', unit);
      }

      if (keyIdentifier.isPasteKeys(e)) {
        return handleShortcutKeys(e, 'paste-docunit', unit);
      }

      if (keyIdentifier.isCutKeys(e)) {
        return handleShortcutKeys(e, 'cut-docunit', unit);
      }

      if (keyIdentifier.isMergeKeys(e)) {
        // merge multi select
        EditorStore.mergeSelectedUnits();
        e.preventDefault();
        return false;
      }

      if (keyIdentifier.isDeleteKey(e)) {
        return handleShortcutKeys(e, 'delete-docunit', unit);
      }

      if (keyIdentifier.isBackspaceKey(e)) {
        return e.preventDefault();
      }
    }
  };

  export const onUndoRedoKey = (e: React.KeyboardEvent) => {
    if (keyIdentifier.isUndoKeys(e)) {
      EditorStore.undo();
      return e.preventDefault();
    }
    if (keyIdentifier.isRedoKeys(e)) {
      EditorStore.redo();
      return e.preventDefault();
    }
    // sink all cmd-s calls when not focused to prevent browser save dialog
    else if (keyIdentifier.isSaveKeys(e)) {
      return e.preventDefault();
    }
  };

  export const focusedUnitKeyHandler = (e: React.KeyboardEvent) => {
    const isNested = EditorStore.isNestedUnitFocused();
    const isSelectionCollapsed = EditorStore.getEditor()!.getActiveEditorInstance()!.selection.getRng(true).collapsed;
    const nestedUnit = EditorStore.getLastFocusedNestedUnit()!;

    let action: ActionEvent['action'] | null = null;

    if (keyIdentifier.isCopyKeys(e)) {
      action = 'copy-docunit';
    } else if (keyIdentifier.isCutKeys(e)) {
      action = 'cut-docunit';
    }

    // only cut/copy element if selection is collapsed, nested and userCreatable, altKey overrides nonCollapsed
    if (!!action && isNested && nestedUnit.focused.definition?.userCreatable && (isSelectionCollapsed || keyIdentifier.isAltKey(e))) {
      return handleShortcutKeys(e, action, nestedUnit);
    }
  };

  export const getDocumentVisualStates = (onVisualStateChange: (newState: VisualStates, newStateOn: boolean) => void) => {
    let docVisualStates: VisualStates[] | null = null;

    try {
      if (localStorage.getItem('view-settings')) {
        let storedStates: VisualStates[] | null = null;
        if (localStorage.getItem('view-settings')) {
          storedStates = JSON.parse(localStorage.getItem('view-settings')!);
        }

        if (storedStates && _.isArray(storedStates)) {
          // note: used to be stored as an object, ignore old data

          storedStates = storedStates.filter((state) => DocumentVisualStates.props.has(state));
          docVisualStates = storedStates;
          if (docVisualStates.indexOf('PAGE_OUTLINE') !== -1) {
            onVisualStateChange('PAGE_OUTLINE', true);
          }
          if (docVisualStates.indexOf('HIGHLIGHT_LAYER2') !== -1) {
            onVisualStateChange('HIGHLIGHT_LAYER2', true);
          }
          if (docVisualStates.indexOf('HIGHLIGHT_LAYER3') !== -1) {
            onVisualStateChange('HIGHLIGHT_LAYER3', true);
          }
        }
      }
    } catch (e) {
      console.log('Error retrieving view settings - use defaults');
    }

    // get defaults
    if (!docVisualStates) {
      docVisualStates = [];
      DocumentVisualStates.props.forEach((vState, key) => {
        if (DocumentVisualStates.props.get(key)?.defaultOn) {
          docVisualStates!.push(key);
        }
      });
    }

    return docVisualStates;
  };

  export const getUnitSelectionInfo = (selectedUnits: IUnit[], docUnitUid: string) => {
    const len = selectedUnits.length;
    return {
      isSelected: len > 0 && !_.isUndefined(selectedUnits.find((du) => du.uid === docUnitUid)),
      isPrimary: len > 0 && selectedUnits[len - 1].uid === docUnitUid
    };
  };

  export const pageUnloadHandler = (e) => {
    if (
      !SystemStore.isBackendDisconnected() &&
      EditorStore.getEditor().isFocused() &&
      !localStorage.getItem(SESSION_STORAGE_KEYS.FORCE_RELOAD)
    ) {
      e.returnValue = 'o/';
      return e.returnValue;
    }
  };

  export const highlightTextAfterRendering = (isComponentizationChange: boolean): void => {
    if (!(EditorStore.isMode('SPELLCHECK') && isComponentizationChange)) {
      setTimeout(() => {
        // using requestAnimationFrame to ensure that highlighting code is run after the painting of the render
        window.requestAnimationFrame(function () {
          SpellCheckStore.docUnitsRenderUpdateComplete();
          FindReplaceStore.docUnitsRenderUpdateComplete({ isComponentizationChange: isComponentizationChange });
        });
      });
    }
  };

  export const performScrollTo = ($pageContainer, $unitEl, $elementEl) => {
    // if element hidden make visible to get coords
    $elementEl.css({ display: 'block' });
    const $scrollEl = $elementEl.length && $elementEl.length > 0 ? $elementEl : $unitEl;

    // can only get coords of hidden element by making visible first
    $unitEl.css({ display: 'block' });
    const unitTopPosition = $scrollEl!.offset()!.top;
    $unitEl.css({ display: '' });
    $elementEl.css({ display: '' });

    $pageContainer.stop().scrollTop(unitTopPosition - $pageContainer!.offset()!.top + $pageContainer!.scrollTop()! - 100);
  };
}
