import { styleMap } from './styleGuard';

export const DISPLAY = styleMap({
  INLINE: {
    display: 'inline'
  }
});

export const OVERFLOW = styleMap({
  HIDDEN: {
    overflow: 'hidden'
  }
});
