import * as _ from 'lodash';
import { Dom } from './DomUtil';
import { UNIT_ELEMENT_CLASS_NAME } from '../units/const/UnitElementSelectors';

export namespace DomAssertions {
  export function hasAnyClass(element: Element | null, classes: string[]): boolean {
    if (!element) {
      throw 'Current element is null';
    }
    const elClasses = element.classList;
    if (classes.length === 0) {
      return true; // all elements have empty class
    }
    if (elClasses.length === 0 && classes.length > 0) {
      return false;
    }
    for (let i = 0, max = elClasses.length; i < max; i += 1) {
      if (classes.indexOf(elClasses[i]) !== -1) {
        return true;
      }
    }
    return false;
  }

  export function hasNoText(element: Element): boolean {
    return _.words(_.trim(Dom.getText(element))).length === 0;
  }

  export function isTextNode(node?: { nodeName: string } | null): node is Text {
    if (!node) {
      return false;
    }
    return !!node.nodeName && node.nodeName === '#text';
  }

  export function hasChildNodes(node: HTMLElement): boolean {
    return !!node && node.childNodes.length > 0;
  }

  export function hasInnerText(node: HTMLElement | Text | Comment): node is HTMLElement {
    return typeof (node as HTMLElement).innerText !== 'undefined';
  }

  export function isHTMLElement(node: HTMLElement | Text | Comment | ChildNode | Document): node is HTMLElement {
    return hasInnerText(node as HTMLElement);
  }

  export function isPartOfGraphic(node: Element): boolean {
    return isNodeName(node, ['figure', 'figcaption', 'img']);
  }

  export function hasElements(node: HTMLElement, querySelectorAll: string): boolean {
    return !!node.querySelectorAll(querySelectorAll).length;
  }

  export function isNodeName(node: { nodeName: string } | undefined | null, nodeNames: string | string[]): boolean {
    if (!node) {
      return false;
    }
    if (nodeNames && Array.isArray(nodeNames)) {
      return (
        _.indexOf(
          nodeNames.map((name) => name.toLowerCase()),
          node.nodeName.toLowerCase()
        ) !== -1
      );
    }
    return node.nodeName.toLowerCase() === nodeNames.toLowerCase();
  }

  export function hasClassName(el: Element | JQuery<Element> | null, className: string): boolean {
    if (el) {
      if (isJQueryElement(el)) {
        return el.hasClass(className);
      }
      return el.classList.contains(className);
    }
    return false;
  }

  export function hasClassNames(el: Element, classNames: string[]): boolean {
    return classNames.every((name) => el.classList.contains(name));
  }

  export function hasAttributeValue(el: Element, attrName: string, attrValue: string): boolean {
    return Dom.getAttributeValue(el, attrName)?.toLowerCase() === attrValue.toLowerCase();
  }

  export function hasAttribute(el: Element, attrName: string): boolean {
    return !!Dom.getAttributeValue(el, attrName);
  }

  export function isJQueryElement<T extends Element>(el: Object): el is JQuery<T> {
    return el instanceof jQuery;
  }

  export function isAttachedToDom(element: Element | Text): boolean {
    return document.body.contains(element);
  }

  export function isEmptyElementWithoutNotEditableContent(element: HTMLElement): boolean {
    const textNodes2 = Array.from(
      Dom.getTextNodesIn(element, (textNode) => !DomAssertions.hasClassName(textNode.parentElement!, UNIT_ELEMENT_CLASS_NAME.NOT_EDITABLE))
    ).map((txt) => txt.nodeValue);

    return !encodeURIComponent(textNodes2.join('').replace(/\s+/g, '')).replace(/%E2%81%A0/g, ''); // encoded ZERO;
  }

  export function isUnit(element?: Element): boolean {
    if (!element) {
      return false;
    }
    return hasClassName(element, UNIT_ELEMENT_CLASS_NAME.UNIT);
  }

  export function isVisible(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const efp = (x, y) => document.elementFromPoint(x, y);

    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
      return false;
    }

    return (
      element.contains(efp(rect.left, rect.top)) ||
      element.contains(efp(rect.right, rect.top)) ||
      element.contains(efp(rect.right, rect.bottom)) ||
      element.contains(efp(rect.left, rect.bottom))
    );
  }
}
