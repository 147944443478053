import * as React from 'react';
import { ITag } from 'mm-types';

export type Props = {
  searchTags: ITag[];
  selection: { item: number };
  valueField: string;
  onAdd: (tag: ITag) => void;
  input: string;
};

export type State = {};

export default class Panel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  isSelected(i: number) {
    return this.props.selection && i === this.props.selection.item;
  }

  _onAdd(tag: ITag) {
    this.props.onAdd(tag);
  }

  searchTagContent(text: string) {
    const content: JSX.Element[] = [];
    const startIndex = text.trim().toLowerCase().indexOf(this.props.input.trim().toLowerCase());
    const endIndex = startIndex + this.props.input.length;

    if (startIndex === -1) {
      content.push(
        <span key={1} className="tf__search-tag-content--regular">
          {text}
        </span>
      );
    } else {
      if (startIndex > 0) {
        content.push(
          <span key={1} className="tf__search-tag-content--regular">
            {text.substring(0, startIndex)}
          </span>
        );
      }

      content.push(
        <span key={2} className="tf__search-tag-content--match">
          {text.substring(startIndex, endIndex)}
        </span>
      );

      if (endIndex < text.length) {
        content.push(
          <span key={3} className="tf__search-tag-content--regular">
            {text.substring(endIndex)}
          </span>
        );
      }
    }

    return content;
  }

  getSearchTags() {
    return this.props.searchTags.map((searchTag, index) => {
      const cls = 'tf__search-tag' + (this.isSelected(index) ? ' tf__search-tag--selected' : '');
      return (
        <div className={cls} key={index} onClick={(e) => this._onAdd(searchTag)}>
          {this.searchTagContent(searchTag[this.props.valueField])}
        </div>
      );
    });
  }

  render() {
    if (this.props.searchTags && this.props.searchTags.length > 0) {
      return <div className="tf__panel">{this.getSearchTags()}</div>;
    } else {
      return <div></div>;
    }
  }
}
