import { ISnackbarMessage } from 'mm-types';
import appStore from '../../../appStore';
import { hideSystemSnackbarMessage, showSystemSnackbarMessage } from './thunks';

export const makeSimpleSnackbar = (message: string) => {
  const snackbarMessage: ISnackbarMessage = {
    open: true,
    message: message,
    autoHideDuration: 5000,
    onRequestClose: () => {
      appStore.dispatch<any>(hideSystemSnackbarMessage());
    }
  };
  appStore.dispatch<any>(showSystemSnackbarMessage(snackbarMessage));
};
