import * as React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

export type Props = {
  onChange: (text: string) => void;
  value?: string;
  isDuRefLink?: boolean;
};

export type menuItemsType = { text: string; payload: string; id: string; isDuRefLink: boolean }[];

const MENU_ITEMS: menuItemsType = [
  { text: 'Internal Cross Reference', payload: 'document', id: '', isDuRefLink: true },
  { text: 'External Cross Reference', payload: 'cross-document', id: 'link-type-external-cross-reference', isDuRefLink: true },
  { text: 'Web Link', payload: 'http', id: 'link-type-web-link', isDuRefLink: false },
  { text: 'Call Link', payload: 'tel', id: '', isDuRefLink: false },
  { text: 'SMS Link', payload: 'sms', id: '', isDuRefLink: false },
  { text: 'Email Link', payload: 'mail', id: '', isDuRefLink: false }
];

const LinkTypeSelector = ({ value = '', isDuRefLink, onChange }: Props) => {
  const menuItems = isDuRefLink ? MENU_ITEMS.filter((item) => isDuRefLink && item.isDuRefLink) : MENU_ITEMS;

  return (
    <span id={'link-type-selector'}>
      <SelectField onChange={(e, index, value) => onChange && onChange(value)} value={value} floatingLabelText="Link Type">
        {menuItems.map((item) => (
          <MenuItem id={item.id} key={item.payload} value={item.payload} primaryText={item.text} />
        ))}
      </SelectField>
    </span>
  );
};

export default LinkTypeSelector;
