import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import ProjectUsersStore from '../../../flux/editor/ProjectUsersStore';
import CommentStore, { CommentStoreEvent } from '../../../flux/editor/CommentStore';
import CommentStatusStore from '../../../flux/editor/CommentStatusStore';
import MergeRevisionsStore from '../../../flux/editor/MergeRevisionsStore';
import CommentForm from '../sidetabs/sub/commentForm/CommentForm';
import Comment from '../sidetabs/sub/comment/Comment';
import CircularProgress from 'material-ui/CircularProgress';
import { IComment, IUnit } from 'mm-types';

let _clearNextNewComment = true;

export type Props = {
  mergePanel?: boolean;
};

export type State = {
  unitUid: string;
  comments: null | IComment[];
  newCommentUnit: null | IUnit;
  selectedComment: null | IComment;
  loading: boolean;
};

export default class UnitComments extends React.Component<Props, State> {
  private unsub: Function;
  constructor(props: Props) {
    super(props);

    this.unsub = CommentStore.listen(this._onCommentStoreUpdate, this);

    this.state = {
      unitUid: MergeRevisionsStore.state!.selectedActivity!.unitUid,
      comments: null,
      newCommentUnit: null,
      selectedComment: null,
      loading: true
    };
  }

  componentWillUnmount() {
    this.unsub();
  }

  componentDidMount() {
    _clearNextNewComment = false;

    ProjectUsersStore.retrieve({ projectUid: EditorStore.getDocParams()!.projectUid! }).then(() => {
      this._retrieveUnitComments().then(() => {
        this._selectForUnit(this.state.unitUid);
      });
    });
  }

  // don't clear create comment on initial retrieve (as its mounting we don;t want it to be removed)
  _retrieveUnitComments() {
    const state: Partial<State> = { loading: true };

    if (_clearNextNewComment) {
      state.newCommentUnit = null;
    }

    const params = EditorStore.getDocParams();

    return new Promise<void>((resolve, reject) => {
      this.setState(state as State, () => {
        CommentStore.retrieveComments(
          params.projectUid!,
          params.indexUid!,
          { unitUid: this.state.unitUid },
          {
            externalUserType: 'ALL',
            fromDateTime: null,
            status: 'ALL',
            unread: 'all',
            users: null
          }
        );

        resolve();
      });
    });
  }

  _retrieveComments() {
    const params = EditorStore.getDocParams();

    CommentStore.retrieveComments(
      params.projectUid!,
      params.indexUid!,
      { unitUid: this.state.unitUid },
      {
        externalUserType: 'ALL',
        fromDateTime: null,
        status: 'ALL',
        unread: 'all',
        users: null
      }
    );
  }

  _canWriteComment() {
    return ProjectStore.canWriteComment();
  }

  _selectForUnit(unitUid: string) {
    const unitComments = this.state.comments ? this.state.comments : [];

    if (unitComments.length) {
      const selectedComment = unitComments[0];
      const state: Partial<State> = {
        selectedComment: selectedComment
      };

      if (_clearNextNewComment) {
        state.newCommentUnit = null;
      }

      this.setState(state as State);
    } else {
      const state: Partial<State> = { selectedComment: null };
      if (_clearNextNewComment) {
        state.newCommentUnit = null;
      }

      this.setState(state as State);
    }

    _clearNextNewComment = true;
  }

  _handleCommentSelect(comment: IComment) {
    this.setState({ selectedComment: comment, newCommentUnit: null });
  }

  _onCommentStoreUpdate(e: CommentStoreEvent) {
    if (e.type === 'comments') {
      this.setState({ loading: false }, () => {
        const state: Partial<State> = { comments: e.state.comments };

        if (_clearNextNewComment) {
          state.newCommentUnit = null;
        }

        this.setState(Object.assign({}, this.state, state as State));
      });
    } else if (e.type === 'commentIntent') {
      _clearNextNewComment = false;
      this.setState({ newCommentUnit: e.unit! });
    }
  }

  _handleCommentStatusUpdated(comment: IComment) {
    CommentStatusStore.readOrUnreadComment(ProjectStore.getProject()?.uid!, ProjectStore.getProject()?.masterIndexUid!, comment);
  }

  _handleCreated(unitUid) {
    this.setState({ newCommentUnit: null }, () => {
      this._selectForUnit(unitUid);
    });
  }

  _handleCancelCreate() {
    this.setState({ newCommentUnit: null });
  }

  _refreshComment(comment) {
    const params = EditorStore.getDocParams();
    CommentStore.refreshComment(params.projectUid!, params.indexUid!, comment.uid).then((state) => {
      this.setState({ comments: state.comments });
    });
  }

  render() {
    return (
      <div className="comments-subaction subaction-list-container">
        <h5>Comments</h5>
        <div className="comments-list-container">
          {this.state.newCommentUnit && this._canWriteComment() ? (
            <CommentForm
              className="new-comment"
              unitUid={this.state.newCommentUnit.uid}
              unitType={this.state.newCommentUnit.type}
              onCreated={(unitUid) => {
                this._handleCreated(unitUid);
              }}
              onCancel={() => {
                this._handleCancelCreate();
              }}
            />
          ) : undefined}

          {!this.state.loading && this.state.comments && this.state.comments.length > 0 ? (
            <ul className="comments-list" ref="commentsList">
              {this.state.comments.map((comment) => {
                return (
                  <Comment
                    key={comment.uid}
                    mergePanel={this.props.mergePanel!}
                    comment={comment}
                    isWriteable={this._canWriteComment()}
                    onRetrieveComments={() => this._retrieveComments()}
                    onRequestRefresh={() => {
                      this._refreshComment(comment);
                    }}
                    onSelected={() => this._handleCommentSelect(comment)}
                    isSelected={this.state.selectedComment && this.state.selectedComment.uid === comment.uid}
                    isHighlighted={true}
                    onHandleCommentStatusUpdated={(comment) => {
                      this._handleCommentStatusUpdated(comment);
                    }}
                  />
                );
              })}
            </ul>
          ) : undefined}

          {this.state.comments && this.state.comments.length === 0 ? (
            <div className="narrow-panel-empty-list">
              <img src={'/assets/images/comments_panel_info.svg'} />
              <span className="empty-title">No Comments</span>
              <span className="empty-details">
                Post a comment to start a discussion <br />
                <span className="mention-text">@mention</span> someone to notify them
              </span>
              <br />
              You can also <br />
              <span className="mention-text">~link</span> a file from your list of attachments
            </div>
          ) : undefined}
          {this.state.loading ? (
            <div className="loading">
              <CircularProgress mode="indeterminate" size={17.85} />
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}
