import { DomAssertions } from './DomAssertionsUtil';
import { ZERO_LENGTH_WORD_JOINER } from './key_listeners/keyBehaviourUtils';
import { AbstractElementFamily, AbstractElementDefinitionIds } from '../units/UnitTypes';
import { CSSSelector } from './CssSelectorUtil';
import { UNIT_ELEMENT_ATTRIBUTE_NAME, UNIT_ELEMENT_CLASS_NAME } from '../units/const/UnitElementSelectors';
import { CustomEditor } from './EditorInstanceManager';
import { isInlineElement } from '../units/unit/inlineElementsLookup';
import { NNCChecklistAsserts } from '../units/unit/non-normal-checklist-level1/asserts';

export namespace Dom {
  import editTarget = CSSSelector.editTarget;
  import hasIndexMetadata = NNCChecklistAsserts.hasIndexMetadata;
  import isNodeName = DomAssertions.isNodeName;
  import isUnit = DomAssertions.isUnit;

  export function getText(element: Element): string {
    const text = element.textContent;
    if (text === null) {
      return '';
    }
    return text.replace(/^\s+|\s+$/g, ''); // remove all whitespaces
  }

  export function removeEmptyNodes($element: JQuery<HTMLElement>, ignoredNodes: string[] = ['hr', 'br']) {
    $element.find('*:empty').each(function () {
      if (ignoredNodes.indexOf(this.nodeName.toLowerCase()) === -1) {
        $(this).remove();
      }
    });
  }

  export function getNodesIn(element: Node | HTMLElement | Text | Comment | Document): JQuery<HTMLElement | Text | Comment | Document> {
    return $(element).find(':not(iframe)').addBack().contents();
  }

  export function getFilteredElementsIn(
    element: Element,
    whatToShow = NodeFilter.SHOW_ELEMENT,
    acceptNode: (node: Element) => number
  ): Element[] {
    const result: Element[] = [];
    let currentNode: any;
    const nodeIterator = document.createNodeIterator(element, whatToShow, {
      acceptNode
    });
    while ((currentNode = nodeIterator.nextNode())) {
      result.push(currentNode);
    }
    return result;
  }

  export function getTextNodesIn(
    element: JQuery<Element | Text | Comment | Document> | HTMLElement | Text | Comment | Document,
    filter?: (textNode: Text) => boolean
  ): Set<Text> {
    const resultTextNodes: Set<Text> = new Set();
    let currentNode: any;
    const root = DomAssertions.isJQueryElement(element) ? element[0] : element;
    if (!root) {
      return resultTextNodes;
    }
    const nodeIterator = document.createNodeIterator(root as Element, NodeFilter.SHOW_TEXT);

    while ((currentNode = nodeIterator.nextNode())) {
      if (!filter || filter(currentNode)) {
        resultTextNodes.add(currentNode);
      }
    }
    return resultTextNodes;
  }

  export function getTextNodeForGivenOffset(element: HTMLElement, offset: number): { node: Text | HTMLElement; relativeOffset: number } {
    const textNodes = Array.from(getTextNodesIn(getNodesIn(element)));
    if (!textNodes.length) {
      const textNode = document.createTextNode('');
      element.appendChild(textNode);
      return {
        node: textNode,
        relativeOffset: 0
      };
    }

    let i = 0;
    let node: Text = textNodes[i];
    let relativeOffset = offset;

    while (!!node && node.data.length < relativeOffset) {
      relativeOffset -= node.data.length;
      node = textNodes[(i += 1)];
    }
    return {
      node,
      relativeOffset
    };
  }

  export function getEndOffsetForPrevTextNode(parentElement: HTMLElement | Node, element: HTMLElement | Node | Text): number | null {
    const textNodes = Array.from(getTextNodesIn(parentElement as HTMLElement));
    const textNodeToFind = DomAssertions.isTextNode(element) ? [element] : Array.from(getTextNodesIn(element as HTMLElement));

    if (!textNodes.length || !textNodeToFind.length) {
      return null;
    }

    let i = 0;
    let offset = 0;
    let node: Text = textNodes[i];

    while (!!node && node !== textNodeToFind[0]) {
      offset += node.data.length;
      node = textNodes[(i += 1)];
    }
    return offset;
  }

  export function scrollToElementAndHighlight(el: Element | null) {
    if (el) {
      try {
        setTimeout(() => {
          el.classList.add('animation-highlight');
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 50);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.error('No element to scroll to');
    }
  }

  export function scrollElementIntoView(el: Element | null, block: ScrollLogicalPosition = 'center') {
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block
      });
      // Sometimes smooth scrolling doesn't work. Fallback to jump scrolling
      if (!isScrolledIntoView(el)) {
        el.scrollIntoView({
          behavior: 'auto',
          block
        });
      }
    }
  }

  export function isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;

    // Only completely visible elements return true:
    let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  export function closestElement(element: Element | null | undefined, selector: string, includeSelf = true): Element | null {
    if (!element) {
      return null;
    }
    if (!includeSelf) {
      const parent = element.parentElement;
      return parent ? parent.closest(selector) : null;
    }
    return element.closest(selector);
  }

  export function closestUnitElement(element: Element): Element | null {
    const abstractElementFamily: AbstractElementFamily[] = [
      AbstractElementFamily.CHALLENGE,
      AbstractElementFamily.CHALLENGE_RESPONSE,
      AbstractElementFamily.CHALLENGE_RESPONSE_MESSAGE
    ];
    const abstractElementDefinitionIds: AbstractElementDefinitionIds[] = [
      AbstractElementDefinitionIds.SYS,
      AbstractElementDefinitionIds.TITLE,
      AbstractElementDefinitionIds.SUBTITLE
    ];

    const hasDefinitionID = CSSSelector.attribute(UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_DEFINITION_ID);
    let hasNoFamily = '';
    for (const abstractFamily of abstractElementFamily) {
      hasNoFamily += `:not(${CSSSelector.attribute([UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, abstractFamily])})`;
    }
    let hasNoDef = '';
    for (const abstractDefinitionId of abstractElementDefinitionIds) {
      hasNoFamily += `:not(${CSSSelector.attribute([UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, abstractDefinitionId])})`;
    }
    return element.closest(`${hasDefinitionID}${hasNoFamily}${hasNoDef}`);
  }

  // for inline element goes up and finds closest edit target otherwise goes down and does the same
  export function findClosestEditableElement(element?: Element): Element | null {
    const isBlacklisted = element && (isUnit(element) || hasIndexMetadata(element) || isNodeName(element, 'table'));
    if (!element || isBlacklisted) {
      return null;
    }
    const editTargetClass = editTarget();
    return isInlineElement(element) || getAttributeValue(element, UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY) === 'EffectivityTag'
      ? closestElement(element, editTargetClass, false)
      : isEditableEditTarget(element) || isEmptyCell(element)
      ? element
      : element.querySelectorAll(
          `*:not(.arc-editor-not-editable) > * > p${editTargetClass},*:not(.arc-editor-not-editable) > * > span${editTargetClass},*:not(.arc-editor-not-editable) > * > pre${editTargetClass}, div.element-content${editTargetClass}:not(.editor-instance-container)`
        )[0];
  }

  function isEditableEditTarget(element: Element) {
    return element.classList.contains(UNIT_ELEMENT_CLASS_NAME.EDIT_TARGET) && isNodeName(element, ['p', 'span', 'pre']);
  }

  function isEmptyCell(element: Element) {
    return element.tagName === 'TD' && element.childElementCount === 0;
  }

  export function getFirstAttachedToDomElement(elements: HTMLElement[]): HTMLElement | null {
    if (!elements) {
      return null;
    }
    let result: Element | null = null;
    elements.forEach((element) => {
      if (!result && DomAssertions.isAttachedToDom(element)) {
        result = element;
      }
    });
    return result;
  }

  export function insertZeroLengthCharToEmptyElement(element: HTMLElement | Text, replace = false) {
    if (DomAssertions.isTextNode(element)) {
      if (!element.nodeValue || replace) {
        element.nodeValue = ZERO_LENGTH_WORD_JOINER;
      }
    } else {
      if (!element.textContent || replace) {
        element.innerHTML = ZERO_LENGTH_WORD_JOINER;
      }
    }
  }

  export function replaceContentByZeroLengthChar(element: HTMLElement | Text) {
    insertZeroLengthCharToEmptyElement(element, true);
  }

  export function insertZeroLengthCharsToEmptyEditTargets(elements: HTMLElement[]) {
    if (!elements) {
      return;
    }
    elements.forEach((element) => {
      if (DomAssertions.isAttachedToDom(element)) {
        insertZeroLengthCharToEmptyElement(element);
      }
    });
  }

  export function removeEmptyChildElements(unitElements: HTMLElement[]) {
    if (!unitElements || !unitElements.length) {
      return;
    }
    unitElements.filter(DomAssertions.isEmptyElementWithoutNotEditableContent).forEach((element) => {
      if (DomAssertions.isAttachedToDom(element)) {
        removeElement(element);
      }
    });
  }

  export function removeElement(element?: Node) {
    element?.parentElement?.removeChild(element);
  }

  export function getHiddenElementHeight(el: HTMLElement): number {
    let result = 0;
    const el_style = window.getComputedStyle(el),
      el_display = el_style.display,
      el_position = el_style.position,
      el_visibility = el_style.visibility,
      el_max_height = el_style.maxHeight!.replace('px', '').replace('%', '');

    if (el_display !== 'none' && el_max_height !== '0') {
      return el.offsetHeight;
    }
    el.style.position = 'absolute';
    el.style.visibility = 'hidden';
    el.style.display = 'block';
    result = el.offsetHeight;
    el.style.display = el_display;
    el.style.position = el_position;
    el.style.visibility = el_visibility;

    return result;
  }

  export function getTableElement(element: Element, editor?: CustomEditor): HTMLTableElement | null {
    if (DomAssertions.isNodeName(element, 'table')) {
      return element as HTMLTableElement;
    }
    if (!editor) {
      return null;
    }
    return (editor.dom.getParent(element as Node, 'table') as HTMLTableElement) || null;
  }

  export function closestGraphic(element: Element): Element | null {
    return closestElement(element, 'figure');
  }

  export function getAttributeValue(element: Element | undefined | null, attrName: string): string | null {
    if (!element) {
      return null;
    }
    return element.attributes.getNamedItem(attrName)?.value ?? null;
  }

  export function createElement<K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    attrs?: Map<string, string | number>
  ): HTMLElementTagNameMap[K] {
    const element = document.createElement(tagName);
    if (attrs) {
      for (const [key, value] of attrs.entries()) {
        element.setAttribute(key, value + '');
      }
    }
    return element;
  }

  export function wrap(element: Node, wrapper: Node) {
    if (!element.parentNode) {
      return;
    }
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  }

  export function insertAfter(newChild: Node, refChild: Node) {
    refChild.parentNode?.insertBefore(newChild, refChild.nextSibling);
  }
}
