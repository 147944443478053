import { indexOf } from 'lodash';

const _tags = ['br', 'span', 'strong', 'em', 'bold', 'i', 'sup', 'sub', 'ins', 'u'],
  _tagsJoined = _tags.join(',');

const nestingElementTags = ['li', 'td'];

const tags = _tags;

const getActualElement = function ($el): JQuery<HTMLElement> {
  if (elementIsElementContent($el)) {
    return getActualContentUnitElement($el);
  }

  const isMetaElement = indexOf(_tags, $el[0].nodeName.toLowerCase()) !== -1;
  return isMetaElement ? $el.closest(':not(' + _tagsJoined + ')') : $el;
};

const getActualElementAmended = function ($el, extra): JQuery<HTMLElement> {
  if (elementIsParagraph($el)) {
    return getParagraphActualParentUnit($el);
  }
  const amended = _tags.concat(extra);
  const isMetaElement = indexOf(amended, $el[0].nodeName.toLowerCase()) !== -1;
  return isMetaElement ? $el.closest(':not(' + amended.join(',') + ')') : $el;
};

const getParagraphActualParentUnit = function ($el): JQuery<HTMLElement> {
  if (!elementIsParagraph($el)) {
    return getActualElement($el);
  }

  const paragraphContainer: JQuery<HTMLElement> = $el.closest('.arc-paragraph');
  const actualElement = paragraphContainer.parent(nestingElementTags.join(','));

  return actualElement.length ? actualElement : $el;
};

const getActualContentUnitElement = (el: JQuery<HTMLElement>): JQuery<HTMLElement> => {
  return findAmongstChildren(el, `:not( ${_tagsJoined} )`);
};

const elementIsParagraph = (el: JQuery<HTMLElement>) => el.length && el[0].nodeName.toLowerCase() === 'p';

const elementIsElementContent = (el: JQuery<HTMLElement>) =>
  el.length && el[0].nodeName.toLowerCase() === 'div' && el.hasClass('.element-content');

const findAmongstChildren = (el: JQuery<HTMLElement>, selectorString: string): JQuery<HTMLElement> => {
  return el.children(selectorString);
};

export default {
  tags,
  getActualElement,
  getActualElementAmended
};
