import ActivityList from './ActivityList';
import UndoList from './UndoList';

export type ActivityListViewTypes = 'ACTIVITIES_UNIT_SELECTED' | 'ACTIVITIES_TOC_SELECTED' | 'ACTIVITIES_ALL' | 'UNDO_HISTORY';

const properties = {
  ACTIVITIES_UNIT_SELECTED: {
    title: null,
    emptyDescription: 'No element activity: future edits to this element will be logged here, for review or further action.',
    listComponent: ActivityList
  },

  ACTIVITIES_TOC_SELECTED: {
    title: null,
    emptyDescription: 'There has been no activity. Future edits will be logged here, for review or further action.',
    listComponent: ActivityList
  },

  ACTIVITIES_ALL: {
    title: 'All Document',
    emptyDescription: 'There has been no activity. Future edits will be logged here, for review or further action.',
    listComponent: ActivityList
  },

  UNDO_HISTORY: {
    title: 'Undo History',
    emptyDescription: 'There is no undo history. Future undos will be logged here, for review or further action.',
    listComponent: UndoList
  }
};
const toRet = {
  props: properties
};

export default toRet;
