import * as polyfill from 'cache-polyfill';
import { clearSelectedSessionStorageItems } from './sessionStorageKeys';

const CACHE_NAME = 'MM-UI-CACHE';

const isSupported: boolean =
  'caches' in self &&
  (() => {
    for (const a in self) {
      if (a.match(/cdc_.*_.*/)) {
        return false;
      }
    }
    return true;
  })();

export namespace CacheClient {
  const MIN_URL_FRAGMENT_LENGTH = 5;
  export interface CacheParams {
    $$useCache?: boolean;
    $$clearCache?: ClearCacheType;
  }

  export enum ClearCacheType {
    CURRENT = 'CURRENT',
    LISTING = 'LISTING',
    ROOT = 'ROOT'
  }

  export const params = {
    useCache: (): CacheParams => ({
      $$useCache: true
    }),
    clearCurrent: (): CacheParams => ({
      $$clearCache: ClearCacheType.CURRENT
    }),
    clearListing: (): CacheParams => ({
      $$clearCache: ClearCacheType.LISTING
    }),
    clearRoot: (): CacheParams => ({
      $$clearCache: ClearCacheType.ROOT
    })
  };

  function cacheStorage(): CacheStorage {
    return isSupported ? caches : polyfill.caches;
  }

  let cache: Cache;
  async function Cache(): Promise<Cache> {
    if (cache) {
      return Promise.resolve(cache);
    }
    return await cacheStorage().open(CACHE_NAME);
  }

  export async function get(requestUrl?: string): Promise<Response | undefined> {
    if (!requestUrl) {
      return Promise.reject();
    }
    const cache = await Cache();

    const keys = await cache.keys(requestUrl);
    if (!keys.length) {
      return undefined;
    }
    return await cache.match(getUrlForMatcher(requestUrl));
  }

  export async function set(requestUrl: string, responseData: Object): Promise<void> {
    const entries = await get(requestUrl);
    if (entries) {
      await clear(requestUrl);
    }
    return (await Cache()).put(requestUrl, new Response(JSON.stringify(responseData)));
  }

  export async function clear(requestUrl: string): Promise<boolean> {
    return (await Cache()).delete(requestUrl);
  }

  export async function clearStartsWith(targetUrl: string, clearCacheType: ClearCacheType): Promise<boolean> {
    const cache = await Cache();
    const keys = await cache.keys();
    keys.forEach(({ url }) => {
      let urlWithoutQueryParam = targetUrl.split('?')[0];
      const parentListing: string[] = urlWithoutQueryParam.split('/');
      if (clearCacheType === ClearCacheType.LISTING) {
        if (parentListing.length > MIN_URL_FRAGMENT_LENGTH) {
          parentListing.pop();
        }
        urlWithoutQueryParam = parentListing.join('/');
      }
      if (clearCacheType === ClearCacheType.ROOT) {
        if (parentListing.length > MIN_URL_FRAGMENT_LENGTH) {
          parentListing.pop();
        }
        if (parentListing.length > MIN_URL_FRAGMENT_LENGTH) {
          parentListing.pop();
        }
        urlWithoutQueryParam = parentListing.join('/');
      }
      if (clearCacheType === ClearCacheType.CURRENT) {
        urlWithoutQueryParam = parentListing.join('/');
      }
      if (url.startsWith(urlWithoutQueryParam)) {
        clear(url);
      }
    });

    return Promise.resolve(true);
  }

  export async function keys(): Promise<ReadonlyArray<Request>> {
    return (await Cache()).keys();
  }

  export async function clearAll(): Promise<boolean> {
    clearSelectedSessionStorageItems();
    return cacheStorage().delete(CACHE_NAME);
  }

  function getUrlForMatcher(requestUrl: string): string {
    return `${!isSupported ? location.origin : ''}${requestUrl}`;
  }
}
