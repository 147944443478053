import React from 'react';
import { IProjectActivity } from 'mm-types';

export type Props = {
  handleScroll: (current: HTMLUListElement | null) => void;
  activities: IProjectActivity[];
  selectedItem: (uid: string) => void;
  opened: string | null;
  titleBuilder: (entry: IProjectActivity) => string | JSX.Element;
  getLogDetails: (entry: IProjectActivity) => JSX.Element;
};

export default ({ handleScroll, activities, selectedItem, opened, titleBuilder, getLogDetails }: Props) => {
  const documentRef = React.useRef<HTMLUListElement>(null);
  return (
    <ul
      className={`documentLog-list listView`}
      onScroll={() => handleScroll(documentRef?.current)}
      data-testid="documentLog-list"
      ref={documentRef}
    >
      {activities.map((entry) => {
        return (
          <div key={entry.uid}>
            <li
              onClick={() => selectedItem(entry.uid)}
              className={`${entry.childrenActivities && entry.childrenActivities.length ? 'parent-item' : ''} ${
                opened === entry.uid && entry.childrenActivities && entry.childrenActivities.length ? 'parent-open' : ''
              } expand`}
            >
              <div>
                <div className="icon-documentLog-outer">
                  {entry.iconType === 'fontello' && <div className={`icon-documentLog ${entry.icon}`} style={{ color: entry.iconColor }} />}
                  {entry.iconType === 'material-ui' ? (
                    <div>
                      <i className={`material-icons icon-activity-${entry.icon}`}>{entry.icon}</i>
                    </div>
                  ) : undefined}
                </div>
                <div className="documentLog-item">
                  <div className="documentLog-item-name">{titleBuilder(entry)}</div>
                  <div className="documentLog-item-time">{entry.formatted}</div>
                </div>
                <div className="documentLog-item">
                  <div className="documentLog-item-title">{entry.username}</div>
                </div>

                <div className="documentLog-item items">
                  <div className="documentLog-item-details">{getLogDetails(entry)}</div>
                </div>
              </div>
            </li>

            {entry.childrenActivities && entry.childrenActivities.length ? (
              <div className={`children-items ${opened === entry.uid ? 'open' : ''}`}>
                {entry.childrenActivities.map((child) => {
                  return (
                    <li onClick={() => selectedItem(child.uid)} key={child.uid} className="expand">
                      <div className="icon-documentLog-outer">
                        {entry.iconType === 'fontello' && (
                          <div className={`icon-documentLog ${child.icon}`} style={{ color: entry.iconColor }} />
                        )}
                      </div>

                      <div className="documentLog-item">
                        <div className="documentLog-item-name">{titleBuilder(child)}</div>
                        <div className="documentLog-item-time">{child.formatted}</div>
                      </div>
                      <div className="documentLog-item">
                        <div className="documentLog-item-title">{entry.username}</div>
                      </div>

                      <div className="documentLog-item items">
                        <div className="documentLog-item-details">{getLogDetails(child)}</div>
                      </div>
                    </li>
                  );
                })}
              </div>
            ) : undefined}
          </div>
        );
      })}
    </ul>
  );
};
