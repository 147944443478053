type TAction = {
  id: string;
  action: string;
  tooltip: string;
  icon: string;
};

type TContent = {
  title: string;
  actions: TAction[];
};

export const CONTENTS: TContent[] = [
  {
    title: 'Insert',
    actions: [
      { id: 'insertRowAbove', action: 'mceTableInsertRowBefore', tooltip: 'Insert row before', icon: 'icon-insert-row-above' },
      { id: 'insertRowBelow', action: 'mceTableInsertRowAfter', tooltip: 'Insert row after', icon: 'icon-insert-row-below' },
      { id: 'insertCellRight', action: 'mceTableInsertColAfter', tooltip: 'Insert column after', icon: 'icon-insert-row-right' },
      { id: 'insertCellLeft', action: 'mceTableInsertColBefore', tooltip: 'Insert column before', icon: 'icon-insert-row-left' }
    ]
  },
  {
    title: 'Copy, Cut & Paste',
    actions: [
      { id: 'copyRow', action: 'arcTableCopyRow', tooltip: 'Copy row', icon: 'icon-copy-row' },
      { id: 'cutRow', action: 'mceTableCutRow', tooltip: 'Cut row', icon: 'icon-cut-row' },
      { id: 'pasteRowBelow', action: 'arcTablePasteRowBefore', tooltip: 'Paste row before', icon: 'icon-paste-row-above' },
      { id: 'pasteRowAbove', action: 'arcTablePasteRowAfter', tooltip: 'Paste row after', icon: 'icon-paste-row-below' }
    ]
  },
  {
    title: 'Delete',
    actions: [
      { id: 'deleteRow', action: 'mceTableDeleteRow', tooltip: 'Delete row', icon: 'icon-delete-row' },
      { id: 'deleteCell', action: 'mceTableDeleteCol', tooltip: 'Delete column', icon: 'icon-delete-column' },
      { id: 'deleteTable', action: 'arcTableDelete', tooltip: 'Delete table', icon: 'icon-delete-table' }
    ]
  },
  {
    title: 'Split & Merge',
    actions: [
      { id: 'split', action: 'mceTableSplitCells', tooltip: 'Split', icon: 'icon-split' },
      { id: 'merge', action: 'mceTableMergeCells', tooltip: 'Merge', icon: 'icon-merge' }
    ]
  }
];
