import * as React from 'react';
import { IComment } from 'mm-types';

type ConfirmOverlayType = {
  comment: null | IComment;
  onConfirm?: (e: any) => void;
  onCancel?: (e: any) => void;
  text: string;
  confirmBtnText: string;
  cancelBtnText: string;
};

export type CommentState = {
  isThreadVisible: boolean;
  isEditing: null | IComment;
  showPermissionModal: boolean;
  anchorElPermission?: HTMLElement;
  confirmOverlay: ConfirmOverlayType;
};

type CommentActions =
  | { type: 'setIsThreadVisible'; payload: boolean }
  | { type: 'setShowPermissionModal'; payload: boolean }
  | { type: 'setShowPermissionModalWithAnchor'; payload: Partial<CommentState> }
  | { type: 'setIsEditing'; payload: null | IComment }
  | { type: 'setConfirmOverlay'; payload: ConfirmOverlayType };

export const CommentReducer: React.Reducer<CommentState, CommentActions> = (state, action) => {
  switch (action.type) {
    case 'setShowPermissionModalWithAnchor':
      return { ...state, showPermissionModal: action.payload.showPermissionModal!, anchorElPermission: action.payload.anchorElPermission };
    case 'setShowPermissionModal':
      return { ...state, showPermissionModal: action.payload };
    case 'setIsThreadVisible':
      return { ...state, isThreadVisible: action.payload };
    case 'setIsEditing':
      return { ...state, isEditing: action.payload };
    case 'setConfirmOverlay':
      return { ...state, confirmOverlay: action.payload };
  }
};
