import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import React from 'react';
import SystemStore from '../../../../../../flux/common/SystemStore';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import { dateUtil } from '../../../../../../utils';

type Props = {
  showDialog: boolean;
  onPreview(): void;
  onCancel(): void;
};
const MenuFileDialog = (props: Props) => {
  if (props.showDialog) {
    const project = ProjectStore.getProject();
    const sso = SystemStore.getSSO();
    let actions: JSX.Element[];
    let body;

    if (sso && project && sso.account) {
      actions = [
        <FlatButton key={1} label="Cancel" onClick={() => props.onCancel()} />,
        <FlatButton key={2} label="Preview" onClick={() => props.onPreview()} />
      ];

      const nowDate = dateUtil(new Date()).formatDate();

      body = (
        <div>
          <p>You are about to generate a preview of your document, which is still in draft mode.</p>
          <p>The preview of your document will be listed on the viewer as:</p>
          <br />
          <p>
            <strong>
              {project.name} - PREVIEW {nowDate} (Along with a formatted date [hh:mm:ss])
            </strong>
          </p>
        </div>
      );
    } else {
      actions = [<FlatButton key={3} label="Cancel" onClick={() => props.onCancel()} />];
      body = (
        <div>
          <p>In order to generate a preview, Viewer access is required.</p>
          <p>A system administrator can give you this access.</p>
        </div>
      );
    }

    return (
      <Dialog
        style={{ zIndex: 999 }}
        contentStyle={{ lineHeight: '1.5', color: 'rgba(0,0,0,0.87)' }}
        title="Preview Draft in Viewer"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        actions={actions}
        open={props.showDialog}
        modal={true}
      >
        {body}
      </Dialog>
    );
  } else {
    return null;
  }
};

export default MenuFileDialog;
