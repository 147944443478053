import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as mediaClient from '../../clients/media';
import EditorStore from '../../flux/editor/EditorStore';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { IMedia } from 'mm-types';
import { EditorRouteParams } from '../ReactRoutes';
import { RouteComponentProps, withRouter } from 'react-router';

export interface Props extends RouteComponentProps<EditorRouteParams> {
  onuploaded: (media: IMedia, notSure: any) => void;
  isInEditor?: boolean;
  projectUid: string;
}

export type State = {
  disabled: boolean;
  isBusy: boolean;
  error: null | string;
  display: boolean;
};

export class UploadImageDialogComponent extends React.Component<Props, State> {
  private _focusId: null | string;
  private _uploadedCallbackArgs: null | string;

  constructor(props: Props) {
    super(props);

    this._focusId = null;
    this._uploadedCallbackArgs = null;
    this.state = {
      isBusy: false,
      disabled: true,
      error: null,
      display: false
    };
  }

  static defaultProps: Partial<Props> = {
    isInEditor: false
  };

  UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
    $('body').toggleClass('dom-overlaid', nextState.display);
  }

  getDialog() {
    return this.refs.uploadImageDialog;
  }

  show(uploadedCallbackArgs: string) {
    this._uploadedCallbackArgs = uploadedCallbackArgs;
    this.setState({ isBusy: false, error: null, display: true }, () => {
      this._toggleEditor(false);
    });
  }

  isShown() {
    return this.state.display;
  }

  _toggleEditor(enable) {
    if (this.props.isInEditor) {
      if (enable) {
        if (this._focusId) {
          EditorStore.getEditor().getActiveEditorFacade()!.setContentEditable(true);
        }
      } else {
        this._focusId = $(document.activeElement!).attr('id')!;
        if (this._focusId) {
          EditorStore.getEditor().getActiveEditorFacade()!.setContentEditable(false);
        }
      }
    }
  }

  _getActions() {
    return [
      <FlatButton key={1} data-qa="cancel-upload" label="Cancel" onClick={() => this.cancel()} primary={true} />,
      <FlatButton
        key={2}
        data-qa="submit-upload"
        label="Upload"
        primary={true}
        keyboardFocused={true}
        onClick={() => this._tryUpload()}
        disabled={this.state.disabled}
        ref="submit"
      />
    ];
  }

  _sinkClicks(e) {
    if (!$(e.target).is('input')) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  cancel() {
    this._toggleEditor(true);
    this.setState({ display: false, disabled: true });
  }

  _imageSelected() {
    const formData = ReactDOM.findDOMNode(this.refs.uploadImageForm) as HTMLFormElement;
    if (formData!.content.value) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  async _tryUpload() {
    const formData = ReactDOM.findDOMNode(this.refs.uploadImageForm) as HTMLFormElement;
    if (formData.content.value) {
      const file = formData.content.files[0];

      if (file.type.indexOf('image/') === -1) {
        this.setState({ error: 'Could not upload: Only image files can be uploaded' });
      } else {
        this.setState({ isBusy: true, error: null });
        try {
          const files: File[] = [];
          files.push(file);
          const response = await mediaClient.upload(this.props.projectUid || this.props.match.params.projectUid, files);
          const media = response.completed[0].data;

          if (this.props.onuploaded) {
            this.props.onuploaded(media, this._uploadedCallbackArgs);
          }

          const showModal = !this.props.onuploaded;
          this.setState({ isBusy: false, error: null, display: showModal });
        } catch {
          this.setState({ isBusy: false, error: 'Could not upload this image' });
        } finally {
          this._toggleEditor(true);
        }
      }
    }
  }

  render() {
    return (
      <div onMouseDown={(e) => this._sinkClicks(e)} onClick={(e) => this._sinkClicks(e)}>
        <Dialog
          style={{ zIndex: 999 }}
          open={this.state.display}
          title="Upload Graphic"
          actions={this._getActions()}
          actionFocus="submit"
          onRequestClose={() => this.cancel()}
          ref="uploadImageDialog"
        >
          <div className="upload-image-dialog">
            {!this.state.isBusy ? (
              <form
                id="graphic-modal-upload-form"
                encType="multipart/form-data"
                method="post"
                className="image-upload-form"
                ref="uploadImageForm"
              >
                <input
                  onChange={() => this._imageSelected()}
                  type="file"
                  name="content"
                  data-prevent-editing-exit="true"
                  id="graphic-modal-file"
                />
              </form>
            ) : undefined}
            {this.state.error ? <div className="upload-image-error">{this.state.error}</div> : undefined}
            {this.state.isBusy ? (
              <div className="upload-busy">
                <CircularProgress mode="indeterminate" />
              </div>
            ) : undefined}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(UploadImageDialogComponent);
