import { IDocUnitProfile } from 'mm-types';
import NNCIndexMetadataProps from '../../NNCIndexMetadata/components/NNCIndexMetadataProps';
import EditorStore from '../../../../../../flux/editor/EditorStore';

export const docUnitProfile: IDocUnitProfile = {
  displayName: `Non-Normal Checklist`,
  getLabel: () => `NNC`,
  identifyingClasses: ['arc-tocable'],
  outerClass: 'arc-tocable',
  openEditPaneOnSelect: true,
  allowInefficientUpdateWhileSelected: true,
  editPropsComponent: NNCIndexMetadataProps,
  featureTag: 'NNCIndexMetadata',
  template: '',
  disableInsertContentMenu: true,
  arcMLDefaults: {
    root: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  menu: {},
  menuTextSelected: {},

  sanitize: function (tinyMCEContent, rawHtmlContent = '') {
    const $header = $(rawHtmlContent);
    if (!$header.length) {
      return $('<div>' + tinyMCEContent + '</div>').html();
    }
    return $header.find('.arc-tocable-heading').html();
  },

  editor: {
    plugins: [],
    forced_root_block: '',
    formats: {
      bold: { inline: 'span', classes: 'donothing' },
      italic: { inline: 'span', classes: 'donothing' },
      underline: { inline: 'span', classes: 'donothing' },
      superscript: { inline: 'span', classes: 'donothing' },
      subscript: { inline: 'span', classes: 'donothing' },
      code: { inline: 'span', classes: 'donothing' },
      forecolor: { inline: 'span', classes: 'donothing' },
      hilitecolor: { inline: 'span', classes: 'donothing' }
    },
    valid_elements: 'span[class|style],div[class],bold,strong',
    init_instance_callback: function () {
      EditorStore.getEditor().getActiveEditorFacade()!.setContentEditable(false);
    }
  }
};
