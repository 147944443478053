import React from 'react';
import { Divider, Menu, MenuItem, SubMenu } from '../../../EditorMenu';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import { IIndex, IRevision } from 'mm-types';

type Props = {
  revisions?: (IRevision | IIndex)[] | null;
  documentIndexUid: string;
  handleDropdownClick(e: { key: string }): void;
  syncOpenKeys(openKeys: string[]): void;
  openKeys?: string[];
};

const OpenMenuElements = (props: Props) => {
  let revisions: JSX.Element[] = [];
  const project = ProjectStore.getProject();

  if (project && props.revisions) {
    revisions = props.revisions.map((revision) => {
      const isCurrentRevision = (revision as IRevision).indexUid === props.documentIndexUid;
      return (
        <MenuItem
          key={(revision as IRevision).indexUid}
          value={(revision as IRevision).indexUid}
          className={isCurrentRevision ? 'highlight-menu-item' : ''}
        >
          {(revision as IRevision).revision}
        </MenuItem>
      );
    });

    if (revisions.length) {
      revisions.unshift(<Divider key="pubdiv" />);

      if (project.interimIndexUid) {
        revisions.unshift(
          <MenuItem
            key={project.interimIndexUid}
            value={project.interimIndexUid}
            className={project.interimIndexUid === props.documentIndexUid ? 'highlight-menu-item' : ''}
          >
            Interim Revision
          </MenuItem>
        );
      }

      revisions.unshift(
        <MenuItem
          key={project.masterIndexUid}
          value={project.masterIndexUid}
          className={project.masterIndexUid === props.documentIndexUid ? 'highlight-menu-item' : ''}
        >
          Draft Revision
        </MenuItem>
      );
    }
  }

  return (
    <Menu
      onClick={(e) => props.handleDropdownClick(e)}
      onOpenChange={(e) => props.syncOpenKeys(e)}
      openKeys={props.openKeys}
      tabIndex="0"
      title="Open"
    >
      <SubMenu key="open" value="open">
        <SubMenu title="Revisions" disabled={revisions.length === 0} key="revisions" value="revisions">
          {revisions}
        </SubMenu>
      </SubMenu>
    </Menu>
  );
};

export default OpenMenuElements;
