import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as baseClient from '../../clients/base-clients';
import * as projectClient from './../../clients/project';
import * as settingsClient from './../../clients/settings';
import * as indexClient from './../../clients/index';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';
import IndexLockStatuses from '../../flux/editor/IndexLockStatuses';
import WorkflowStore from '../../flux/settings/WorkflowStore';
import {
  DocParams,
  IComment,
  IElementDefinition,
  IIndex,
  IProject,
  IProjectDefinition,
  IUnitDefinition,
  IUserPermissions,
  IWorkFlow
} from 'mm-types';
import Store from '../Store';
import RevisionStore from './RevisionStore';
import LinkStore from './LinkStore';
import ActiveUserStore from '../common/ActiveUserStore';
import { getProjectSettings, setProjectSettings } from '../../components/editor/settings';
import dateUtil from '../../utils/DateUtil';
import TocStore from './TocStore';
import UnitConceptStore from './UnitConceptStore';
import SmartContentLibraryStore from './SmartContentLibraryStore';
import { Cancelled } from '../../clients/base-clients';
import EditorStore from './EditorStore';

export type ProjectStoreEvent = State;

type State = {
  project?: IProject;
  workflowInstance?: IWorkFlow;
  index?: IIndex;
  type?: legacyType;
  projectDefinition?: IProjectDefinition;
};

type legacyType = {
  followRules: string;
  unitData: { [name: string]: IUnitDefinition };
  supported: { unitTypes: string };
};

export class ProjectStore extends Store<State> {
  private _currentRevisionUid: string;
  private params: {};
  private _isStoreBusy: boolean;
  private _variantUid: string | null;

  constructor() {
    super();
    this.clear();
    this._isStoreBusy = false;
  }

  getInitialState() {
    return this.state;
  }

  isBusy() {
    return this._isStoreBusy;
  }

  setBusy(isBusy: boolean) {
    this._isStoreBusy = isBusy;
  }

  isReadOnly() {
    if (this.state.project) {
      const hasWritePermissions =
        this.state.project.currentUserPermissions?.canAuthor || this.state.project.currentUserPermissions?.canManageComplianceTags;
      // Check if there's a Workflow - derive readonly from it.
      let canDoWorkflowAction = true;
      if (this.state.workflowInstance && this.state.workflowInstance.activeStage) {
        const currentUser = ActiveUserStore.getUser()!;
        // User has to be assigned to the Workflow Stage or has to be a teamspace admin
        canDoWorkflowAction = this.state.workflowInstance.isCurrentUserAssignedToStage || currentUser.teamspaceAdmin!;
      }
      const trashed = this.state.project.status === 'TRASHED';
      // Author doesn't have to be able to do workflow action
      return (!hasWritePermissions && !canDoWorkflowAction) || !hasWritePermissions || this.isIndexLocked() || trashed;
    }
  }

  isIndexLocked() {
    return this.state.index!.isLocked;
  }

  isInterim() {
    if (this.state.index) {
      return this.state.index.status === 'INTERIM_DRAFT';
    } else {
      return false;
    }
  }

  canWriteComment() {
    if (this.getProject()) {
      const permissions: IUserPermissions = this.getProject()!.currentUserPermissions;
      return permissions.canComment && permissions.canRead && !this.state.index!.isPublished;
    } else {
      return false;
    }
  }

  canManageAttachments() {
    if (this.getProject()) {
      const permissions: IUserPermissions = this.getProject()!.currentUserPermissions;
      return permissions.canManageAttachments && !this.state.index!.isPublished;
    } else {
      return false;
    }
  }

  canDeleteComment(comment: IComment) {
    const user = ActiveUserStore.getUser()!;

    if (user.uid === comment.commenter.uid) {
      return true;
    } else if (ActiveUserStore.isAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  canReadComment() {
    if (this.getProject()) {
      const permissions: IUserPermissions = this.getProject()!.currentUserPermissions;
      return permissions.canComment && permissions.canRead;
    } else {
      return false;
    }
  }

  isProjectAdmin() {
    if (this.getProject()) {
      const permissions: IUserPermissions = this.getProject()!.currentUserPermissions;
      return permissions.canEditSettings && permissions.canManageMemberships;
    } else {
      return false;
    }
  }

  getCurrentRevisionUid() {
    return this._currentRevisionUid;
  }

  retrieveWorkflowInstance(index: IIndex) {
    return new Promise<void>((resolve, reject) => {
      WorkflowStore.retrieveWorkflowForIndex(index.uid, { assignments: true, indexUid: index.uid }).then((wf) => {
        this.state.workflowInstance = wf ? wf.workflow : undefined;
        resolve();
      });
    });
  }

  reInitProject(reInitParams?: DocParams) {
    EditorStore.resetMode();
    return this.initProject(_.extend(this.params, reInitParams));
  }

  async indexToUpdate() {
    if (!this.isBusy()) {
      this.setBusy(true);
      if (this.state.index) {
        this.state.index.lockedStatus = IndexLockStatuses.props[this.state.index.isLocked ? 'UNLOCKED' : 'LOCKED_BY_ADMIN'].code;
        this.state.index = await indexClient.update(this.getProject()!.uid, this.state.index.uid, this.state.index);
        this.setBusy(false);

        await this.reInitProject();
      } else {
        this.setBusy(false);
      }
    }
  }

  private loadUnitConcepts(params: DocParams) {
    TocStore.loadingComplete();
    if (params.tocableUnitUid) {
      const tocParent = TocStore.getFirstSelectedTocableUnit(params.tocableUnitUid);
      const availableTocableUnit = tocParent ? tocParent.uidnid : params.tocableUnitUid;
      return UnitConceptStore.init(params, availableTocableUnit);
    } else {
      const tocSelect = TocStore.getSelectedItem();
      if (tocSelect) {
        return UnitConceptStore.init(params, tocSelect.uid);
      }
    }
  }

  async onTocChange(params: DocParams) {
    return this.loadUnitConcepts(params);
  }

  async initProject(params: DocParams) {
    if (!this.isBusy()) {
      this.setBusy(true);
      this.clear();
      ProjectDefinitionStore.clear();
      RevisionStore.clear();

      this._variantUid = null;

      baseClient.removeVariantHeader();

      $(document).ajaxSend((event, jqXHR, ajaxOptions) => {
        delete jqXHR['X-Index-Variant'];
      });

      this._currentRevisionUid = params.documentIndexUid!;

      this.params = params;
      const projectUid = params.projectUid ?? '';
      const documentIndexUid = params.documentIndexUid ?? '';
      const projectSettings = getProjectSettings(projectUid, documentIndexUid);

      const responses = await Promise.all([
        ProjectDefinitionStore.initProject(),
        projectClient.getProject(projectUid),
        projectSettings ? Promise.resolve(projectSettings) : settingsClient.get(projectUid, documentIndexUid),
        indexClient.get(projectUid, documentIndexUid)
      ]);

      this.state.project = responses[1];
      this.state.index = responses[3] instanceof Cancelled ? undefined : responses[3];

      if (!projectSettings) {
        try {
          setProjectSettings(responses[2], projectUid, documentIndexUid);
        } catch (e) {
          console.error(e);
        }
      }

      if (this.state.index) {
        this.state.index.revisionDateFormatted = dateUtil(this.state.index.revisionDate).formatDateTimeNoSecs();
      }

      if (this.state.index!.workflowUid) {
        this.retrieveWorkflowInstance(this.state.index!).then(() => {
          this.trigger(this.state);
        });
      }

      // Update profiles based on project type, have this here rather then in editor store init
      await ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().updateProfiles(this.state.project?.definitionName);
      this.state.projectDefinition = ProjectDefinitionStore.getProjectDefinition(this.state.project.definitionName);
      this.trigger(this.state);

      RevisionStore.onProjectStoreUpdate().then(() => {});
      LinkStore.onProjectUpdate();

      this.trigger(this.state);
      SmartContentLibraryStore.initProject();
      this.setBusy(false);
    }
  }

  getProject() {
    return this.state.project;
  }

  getType() {
    return this.state.type ? this.state.type : this.state.projectDefinition;
  }

  getProjectDefinitionType(): string | undefined {
    return this.state.projectDefinition?.name;
  }

  getTypeValidElementConverts(type) {
    const elementDefinition: IElementDefinition | null = this._findElementDefinition(type);
    return elementDefinition ? elementDefinition.validConverts || [] : [];
  }

  getTypeValidConverts(type) {
    const unitDefinition: IUnitDefinition | null = this._findUnitDefinition(type);
    return unitDefinition ? unitDefinition.validConverts || [] : [];
  }

  getTypeValidMerges(type) {
    const unitDefinition: IUnitDefinition | null = this._findUnitDefinition(type);
    return unitDefinition ? unitDefinition.validMerges || [] : [];
  }

  _findElementDefinition(type): IElementDefinition | null {
    if (this.state && this.state.projectDefinition && this.state.projectDefinition.indexDefinition.elementDefinitions) {
      return _.find(this.state.projectDefinition.indexDefinition.elementDefinitions, { id: type })!;
    } else {
      return null;
    }
  }

  _findUnitDefinition(type): IUnitDefinition | null {
    if (this.state && this.state.projectDefinition && this.state.projectDefinition.indexDefinition.unitDefinitions) {
      return _.find(this.state.projectDefinition.indexDefinition.unitDefinitions, { id: type })!;
    } else {
      return null;
    }
  }

  getIndex() {
    return this.state.index;
  }

  getWorkflowInstance() {
    return this.state.workflowInstance;
  }

  getState() {
    return this.state;
  }

  isLoaded() {
    return this.getProject();
  }

  // don't like doing this on a store, but must be cleared, otherwise .isLoaded() logic won't work when revisiting a page (it will always be true) (e.g. nav back and then fwd)
  clear() {
    this.state = {
      project: undefined,
      workflowInstance: undefined,
      index: undefined,
      type: undefined,
      projectDefinition: undefined
    };
  }

  setSelectedVariantUid(variantUid: string | null) {
    this._variantUid = variantUid;
  }

  getSelectedVariantUid() {
    return this._variantUid;
  }
}

const singleton = Reflux.initStore<ProjectStore>(ProjectStore);
export default singleton;
