import * as React from 'react';
import * as _ from 'lodash';
import DocumentsStore, {
  DEFAULT_PAGE_SIZE,
  DocumentsStoreEvent,
  Page,
  Sort,
  State as DocumentsStoreState
} from '../../flux/projects/DocumentsStore';
import WorkspaceStore, { WorkspaceStoreEvent } from '../../flux/common/WorkspaceStore';
import AppStateStore from '../../flux/common/AppStateStore';
import Log from '../../utils/Log';
import config from '../../utils/config';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import { grey500 } from 'material-ui/styles/colors';
import { FormattedMessage } from 'react-intl';
import StringUtil from '../../utils/StringUtil';
import textUtils from '../../utils/StringUtil';
import DocsToolbar from './DocsToolBar';
import ImportDocument, { UploadType } from './ImportDocument';
import DocsMenu from './DocsMenu';
import Documents from './Documents';
import { Dialog, LinearProgress } from 'material-ui';
import { transitionTo } from '../../utils/Navigation';
import { saveCompliance } from '../../clients/projects';
import * as projectClient from '../../clients/project';
import { IProject, IRouteParams, IWorkspace } from 'mm-types';
import { match } from 'react-router';
import fileDownloadUtil from '../../utils/fileDownloadUtil';
import NotificationsStore from '../../flux/events/NotificationsStore';
import asyncExportUtil, { FileExtension } from '../../utils/asyncExportUtil';
import * as H from 'history';
import appStore from '../../appStore';
import { showSystemSnackbarMessage } from '../misc/SystemSnackbar/thunks';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';

const styles: { floatingLabelStyle: React.CSSProperties } = {
  floatingLabelStyle: {
    color: grey500
  }
};

export type Props = {
  match: match<IRouteParams>;
  location: H.Location<{ preserveSorting: boolean }>;
};

export type State = {
  externalFileModalOpened: boolean;
  isLoading: boolean;
  showProgress: null | { title: string; message: string };
  showModal: null | {
    title: string;
    message: string | JSX.Element;
    actions?: JSX.Element[];
  };
  uploadType: UploadType;
  workspaces?: IWorkspace[];
  selectedWorkspace: Partial<IWorkspace>;
  selected: null | IProject[];
  sort: Sort;
  page?: Page;
  single?: null | Partial<IProject>;
  strings: null;
  response: { projects: IProject[]; errors: string[] };
  discardDraftReason: string;
  targetProject: null | IProject;
  contents?: IProject[];
  isBusy?: boolean;
} & Partial<DocumentsStoreState>;

export type StatusChangeType = 'TRASHED' | 'DELETED' | 'ACTIVE';

// noinspection JSMethodCanBeStatic
export default class TeamspacesPage extends React.Component<Props, State> {
  private docUnsubs: Function;
  private workspaceUnsubs: Function;

  constructor(props: Props) {
    super(props);

    this.state = {
      externalFileModalOpened: false,
      uploadType: 'aerodocs',
      isLoading: true,
      showProgress: null,
      showModal: null,
      selectedWorkspace: { name: 'Personal' },
      selected: null,
      strings: null,
      response: { errors: [], projects: [] },
      discardDraftReason: '',
      targetProject: null,
      sort: { sortBy: 'TITLE', sortOrder: 'asc' },
      page: { pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE, numOfPages: 0, totalElements: 0 }
    };
  }

  componentDidMount() {
    this.docUnsubs = DocumentsStore.listen(this.onDocStoreChange, this);
    this.workspaceUnsubs = WorkspaceStore.listen(this.onWorkspaceStoreChange, this);
    ProjectDefinitionStore.init();
  }

  onWorkspaceStoreChange(state: WorkspaceStoreEvent) {
    this.setState({
      workspaces: state.workspaces,
      selectedWorkspace: WorkspaceStore.getWorkspace(this.props.match.params.workspaceUid)!
    });
  }

  onDocStoreChange(state: DocumentsStoreEvent) {
    if (state.error) {
      switch (state.error) {
        case 'PROJECT_EXISTS':
          this._showSnackbar('Project name already exists');
          break;
        case 'PROJECT_GONE':
          this._showSnackbar('Project no longer exists');
          break;
        case 'INDEX_EXISTS':
          this._showSnackbar('Interim revision already exists for this document');
          break;
        case 'INDEX_GONE':
          this._showSnackbar('Interim revision no longer exists');
          break;
        case 'DRAFT_INDEX_DISCARD_ERROR':
          this._showSnackbar('Cannot discard revision change');
          break;
        case 'NO_PERMISSION':
          this._showSnackbar('Insufficient permissions');
          break;
      }
    } else if (state.isNewProject) {
      transitionTo('doc-general-settings', {
        projectUid: state.project!.uid,
        indexUid: state.project!.masterIndexUid
      });
    } else if (state.statusChange) {
      this.updateLastSelectedProject(state);
      state.strings = this._getStrings(state);

      this.setState(state as State, () => {
        if (_.isEmpty(this.state.response.errors)) {
          // happy path snackbars
          switch (state.statusChange) {
            case 'ACTIVE':
              this._showSnackbar(StringUtil.text('projectsRestored', this.state.strings));
              break;
            case 'TRASHED':
              this._showSnackbar(StringUtil.text('projectsDeleted', this.state.strings));
              break;
            case 'DELETED':
              this._showSnackbar(StringUtil.text('projectsDestroyed', this.state.strings));
              break;
          }
        } else {
          // problems, raise dialog
          this.setState({
            showModal: {
              title: 'An issue occured during processing',
              message: this._getBatchResponseBody(this.state.response.projects, this.state.response.errors, state.statusChange!),
              actions: [
                <FlatButton
                  key={1}
                  label="Ok"
                  onClick={() => {
                    this.setState({ showModal: null });
                  }}
                />
              ]
            }
          });
        }
      });
    } else {
      this.updateLastSelectedProject(state);
      if (state.type === 'single-project-loading') {
        this.setState({ selected: state.selected });
      }
      if (state.type === 'projects-loaded') {
        state.selectedWorkspace = WorkspaceStore.getWorkspace(this.props.match.params.workspaceUid);
        state.isLoading = false;
        state.strings = this._getStrings(state);
        this.setState(state as State);
      } else if (state.type === 'other') {
        this.setState({ selected: state.selected });
      } else if (state.type === 'update-page-size') {
        this.setState({ page: { ...this.state.page, pageSize: state.page.pageSize } as Page, isLoading: true, contents: [] }, () => {
          DocumentsStore.retrieveDocs(
            AppStateStore.getLastAccessedWorkspaceUid(),
            AppStateStore.getLastSort(),
            this.state.page,
            AppStateStore.getLastSelectedProjectUid()
          );
        });
      }
    }
  }

  private updateLastSelectedProject(state: DocumentsStoreEvent) {
    if (state.selected && state.selected.length === 1) {
      AppStateStore.updateLastSelectedProject(state.selected[0].uid);
    }
  }

  _getStrings(state: DocumentsStoreEvent | State) {
    let selectedCount = 0;
    let selectedName = '';

    if (state.selected && state.selected.length > 1) {
      selectedCount = state.selected.length;
      if (state.selected[0]) {
        selectedName = state.selected[0].name!;
      }
    } else if ((state as State).single) {
      selectedCount = 1;
      selectedName = (state as State).single!.name!;
    }

    return {
      selectedCount: (
        <FormattedMessage
          id="multi-select-count"
          defaultMessage={`{multiCount, plural, one {Document} other {Documents}} Selected`}
          values={{ multiCount: state.selected ? state.selected.length : 0 }}
        />
      ),
      deleteTitle: (
        <FormattedMessage
          id="delete-title"
          defaultMessage={`{multiCount, plural, one {Delete document: {projName}} other {Delete all selected documents?}}`}
          tagName="h3"
          values={{ multiCount: selectedCount, projName: selectedName }}
        />
      ),
      deleteBody: (
        <FormattedMessage
          id="delete-body"
          defaultMessage={`{multiCount, plural,
            one {This document will be moved to Trash. From Trash, it can be restored or permanently deleted.}
            other {These documents will be moved to Trash. From Trash, they can be restored or permanently deleted.}}`}
          values={{ multiCount: selectedCount }}
        />
      ),
      deleteForeverTitle: (
        <FormattedMessage
          id="delete-forever-title"
          defaultMessage={`{multiCount, plural, one {Delete {projName} forever?} other {Delete all selected documents forever?}}`}
          tagName="h3"
          values={{ multiCount: selectedCount, projName: selectedName }}
        />
      ),
      deleteForeverBody: (
        <FormattedMessage
          id="delete-forever-body"
          defaultMessage={`{multiCount, plural,
            one {This document will be deleted permanently and can not be restored back into the system.}
            other {These documents will be deleted permanently and can not be restored back into the system.}}`}
          values={{ multiCount: selectedCount }}
        />
      ),
      projectsRestored: (
        <FormattedMessage
          id="projectsRestored"
          defaultMessage={`{multiCount, plural,
            one {Project}
            other {Projects}} restored`}
          values={{
            multiCount: (state as State).response && (state as State).response.projects ? (state as State).response.projects.length : 0
          }}
        />
      ),
      projectsDeleted: (
        <FormattedMessage
          id="projectsRestored"
          defaultMessage={`{multiCount, plural,
            one {Project}
            other {Projects}} deleted`}
          values={{
            multiCount: (state as State).response && (state as State).response.projects ? (state as State).response.projects.length : 0
          }}
        />
      ),
      projectsDestroyed: (
        <FormattedMessage
          id="projectsRestored"
          defaultMessage={`{multiCount, plural,
            one {Project}
            other {Projects}} destroyed`}
          values={{
            multiCount: (state as State).response && (state as State).response.projects ? (state as State).response.projects.length : 0
          }}
        />
      )
    };
  }

  UNSAFE_componentWillMount() {
    const routerMatch: match<IRouteParams> = this.props.match;
    const routerLocation = this.props.location;

    AppStateStore.clearEditorState();

    Log.info('Teamspace Page - mounted for workspace: ' + routerMatch.params.workspaceUid);
    const workspaceUid = routerMatch.params.workspaceUid;
    const projectUid = routerMatch.params.projectUid;

    this.setState({ isLoading: true, contents: [] }, () => {
      WorkspaceStore.initProjectDocs()
        .then(() => {
          return DocumentsStore.retrieveCanAdminWorkspaces();
        })
        .then(() => {
          // Check if requested workspace exists, if not then move to the last accessed one
          const workspace = WorkspaceStore.getWorkspace(routerMatch.params.workspaceUid);
          if (!workspace) {
            transitionTo('/teamspaces/' + AppStateStore.getLastAccessedWorkspaceUid());
          }

          if (
            workspaceUid === 'workspace' ||
            workspaceUid === 'all' ||
            workspaceUid === 'shared' ||
            workspaceUid === 'trash' ||
            workspace
          ) {
            if (routerLocation.state && routerLocation.state.preserveSorting) {
              this._retrieveDocsForLastAccessedWorkspace();
            } else {
              const selected = DocumentsStore.getSelectedProjects();
              this._retrieveDocs(workspaceUid, projectUid ?? (selected?.length === 1 ? selected[0].uid : undefined));
            }
          }
        });
    });
  }

  componentWillUnmount() {
    DocumentsStore.clearFilter();
    this.docUnsubs();
    this.workspaceUnsubs();
  }

  // only called on page props change (i.e. new query on url)
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this._retrieveDocs(nextProps.match.params.workspaceUid, nextProps.match.params.projectUid);
  }

  _retrieveDocs(workspaceUid: string, projectUid?: string) {
    this.setState({ isLoading: true, contents: [] }, () => {
      DocumentsStore.retrieveDocs(workspaceUid, undefined, undefined, projectUid);
      // TODO: gg, might need to update sort & page in the AppStateStore
      AppStateStore.updateLastAccessedWorkspace(workspaceUid);
      if (projectUid) {
        DocumentsStore.updateProjectSelect([projectUid], true);
      }
    });
  }

  _retrieveDocsForLastAccessedWorkspace() {
    this.setState({ isLoading: true, contents: [] }, () => {
      DocumentsStore.retrieveDocs(
        AppStateStore.getLastAccessedWorkspaceUid(),
        AppStateStore.getLastSort(),
        AppStateStore.getLastPage(),
        AppStateStore.getLastSelectedProjectUid()
      );
    });
  }

  _performSortingAndPaging(sort: Sort, page?: Partial<Page>) {
    this.setState({ isLoading: true, contents: [], selected: [] }, () => {
      DocumentsStore.retrieveDocs(this.props.match.params.workspaceUid, sort, page);
      // TODO: gg, might need to update sort & page in the AppStateStore
      AppStateStore.updateLastAccessedWorkspace(this.props.match.params.workspaceUid);
    });
  }

  _canAdminCurrentlySelectedWorkspace() {
    const workspaceSelected = WorkspaceStore.getWorkspace(this.props.match.params.workspaceUid)!;
    return this._canAdminWorkspace(workspaceSelected);
  }

  _canAdminProjectWorkspaceForComplianceReport(project: IProject) {
    if (project.workspaceType && project.workspaceType.toUpperCase() === 'TEAM') {
      if (project.lastPublishedIndexUid == undefined) {
        project.reportRejectReason = 'Must be published.';
        return false;
      }

      const canAdminWS = DocumentsStore.getCanAdminWorkspaces();
      let isTeamspaceAdmin = !_.isUndefined(_.find(canAdminWS, { uid: project.workspaceUid }));

      if (!isTeamspaceAdmin && !project.currentUserPermissions.canManageComplianceTags) {
        project.reportRejectReason = 'Must be Teamspace admin or have manage compliance references permission.';
        return false;
      }
      return true;
    } else {
      project.reportRejectReason = 'Must be in a non-personal Teamspace.';
      return false;
    }
  }

  _canAdminWorkspace(workspace: Partial<IWorkspace>) {
    if (workspace && workspace.type && workspace.type.toUpperCase() === 'TEAM') {
      const canAdminWS = DocumentsStore.getCanAdminWorkspaces();
      return !_.isUndefined(_.find(canAdminWS, { uid: workspace.uid }));
    } else {
      return false;
    }
  }

  _canCreateDocument() {
    const workspaceSelected = WorkspaceStore.getWorkspace(this.props.match.params.workspaceUid)!;

    if (workspaceSelected && workspaceSelected.type !== 'TEAM') {
      return true;
    } else {
      return this._canAdminWorkspace(workspaceSelected);
    }
  }

  /*
   *   HANDLE EVENTS ON FROM CHILD COMPONENTS
   *
   *
   */

  _handleDocOpened(projectUid: string) {
    const project = DocumentsStore.getProjectItem(projectUid);

    if (project) {
      transitionTo('editor-edit', {
        projectUid: project.uid,
        indexUid: project.masterIndexUid
      });
    }
  }

  _handleDeleteRemoveModalCancel() {
    this.setState({ showModal: null, single: null });
  }

  _handleRemoveModalDelete(selectedProjects: IProject[]) {
    DocumentsStore.updateProjectStatus(selectedProjects, this.props.match.params.workspaceUid, 'TRASHED');
    this.setState({ showModal: null, single: null });
  }

  _handleDeleteModalDelete(selectedProjects: IProject[]) {
    DocumentsStore.updateProjectStatus(selectedProjects, this.props.match.params.workspaceUid, 'DELETED');
    this.setState({ showModal: null, single: null });
  }

  _saveDiscardDraftReason(e: React.FocusEvent<{}>) {
    this.setState({ discardDraftReason: (e.target as HTMLInputElement).value });
  }

  async _handleDiscardDraft(project: IProject) {
    this.setState({
      showModal: null,
      showProgress: { title: 'Discarding draft in progress', message: '' }
    });

    await DocumentsStore.discardDraft(project, this.state.discardDraftReason);
    this._showSnackbar('Draft version discarded');
  }

  async _handleDeleteModalDeleteInterim(project: IProject) {
    this.setState({
      showModal: null,
      showProgress: { title: 'Deleting interim revision in progress', message: '' }
    });
    await DocumentsStore.deleteInterim(project).then(() => this._showSnackbar('Interim deleted'));
  }

  _onExternalModalCancel() {
    this.setState({ externalFileModalOpened: false });
  }

  _onExternalModalSuccess(project?: IProject) {
    this.setState({ externalFileModalOpened: false }, () => {
      if (project) {
        DocumentsStore.openProjectConfig(project);
      }
    });
  }

  _onInternalModalSuccess() {
    this.setState({ externalFileModalOpened: false });
  }

  _getWorkspace() {
    if (
      this.props.match.params.workspaceUid === null ||
      this.props.match.params.workspaceUid === 'all' ||
      this.props.match.params.workspaceUid === 'shared'
    ) {
      return WorkspaceStore.getPersonalWorkspace();
    } else {
      return WorkspaceStore.getWorkspace(this.props.match.params.workspaceUid);
    }
  }

  _handleSelectedAction(actionId: string, selected?: IProject | null) {
    const selectedProjects = this._anyProjectSelected() ? DocumentsStore.getSelectedProjects()! : selected ? [selected] : [];

    if (actionId === 'importDoc') {
      this.setState({ targetProject: null, externalFileModalOpened: true, uploadType: 'aerodocs' });
    } else if (actionId === 'importExternalDoc') {
      this.setState({ targetProject: null, externalFileModalOpened: true, uploadType: 'external' });
    } else if (actionId === 'showBulkUpload') {
      transitionTo('bulk-upload');
    } else if (actionId === 'uploadExternal') {
      this.setState({
        targetProject: selectedProjects[selectedProjects.length - 1],
        externalFileModalOpened: true,
        uploadType: 'external-revision'
      });
    } else if (actionId === 'createInterim') {
      this._showSnackbar('Creating interim revision in progress');
      DocumentsStore.createInterim(selectedProjects[0]);
    } else if (actionId === 'deleteInterim') {
      this.setState({
        showModal: {
          title: 'Delete Interim Revision',
          message: 'Are you sure?',
          actions: [
            <FlatButton key={1} label="Cancel" onClick={() => this._handleDeleteRemoveModalCancel()} data-qa={'cancel-btn'} />,
            <FlatButton
              key={2}
              label="Delete"
              onClick={() => {
                this._handleDeleteModalDeleteInterim(selectedProjects[0]);
              }}
              data-qa={'delete-btn'}
            />
          ]
        }
      });
    } else if (actionId === 'downloadNonCdmsCurrentDraft') {
      fileDownloadUtil.withDefaultNotification(config.apiRoot + '/projects/' + selectedProjects[0].uid + '/download');
    } else if (actionId === 'discardDraft') {
      this.setState({
        showModal: {
          title: 'Discard Current Draft: ' + selectedProjects[0].name,
          message: (
            <TextField
              className="discardDraftDialog"
              multiLine={true}
              fullWidth={true}
              rows={2}
              hintText="Comments (required):"
              id="comments-input"
              floatingLabelFixed={true}
              floatingLabelText="All changes in the current document draft will be discarded and cannot be restored."
              floatingLabelStyle={styles.floatingLabelStyle}
              onBlur={(e) => this._saveDiscardDraftReason(e)}
            />
          ),
          actions: [
            <FlatButton key={1} label="Cancel" onClick={() => this._handleDeleteRemoveModalCancel()} data-qa={'cancel-btn'} />,
            <FlatButton
              key={2}
              label="Discard"
              onClick={() => {
                this._handleDiscardDraft(selectedProjects[0]);
              }}
              data-qa={'delete-btn'}
            />
          ]
        }
      });
    } else if (actionId === 'create') {
      const workspace = this._getWorkspace()!;

      DocumentsStore.createProject(workspace as IWorkspace);
    } else if (actionId === 'copyDoc') {
      this._showSnackbar('Copy document submitted');
      DocumentsStore.cloneProject({
        cloneFromProjectUid: selectedProjects[0].uid,
        clonedProjectTitle: 'Copy Of ' + selectedProjects[0].name
      });
    } else {
      // batchable operations
      this._permissionCheck(selectedProjects, actionId, (projects, actionId) => this._handleBatchOpertions(projects, actionId));
    }
  }

  _verifyProjectPermission(actionId: string, project: IProject) {
    let ret = false;

    if (actionId && project.currentUserPermissions) {
      if (actionId === 'exportPDF') {
        ret = project.currentUserPermissions?.canExportPdf;
      } else if (actionId === 'exportArcml') {
        ret = project.currentUserPermissions?.canExportArcXml;
      } else if (actionId === 'exportEdb') {
        ret = project.currentUserPermissions?.canExportEdb;
      } else if (actionId === 'exportAirbus') {
        ret = project.currentUserPermissions?.canExportAirbus;
      } else if (actionId === 'exportHelp') {
        ret = project.currentUserPermissions?.canExportHelp;
      } else if (actionId === 'restore' || actionId === 'delete_forever' || actionId === 'delete') {
        ret = project.currentUserPermissions?.canEditSettings;
      } else if (actionId === 'compliance') {
        ret = this._canAdminProjectWorkspaceForComplianceReport(project);
      }
    }

    return ret;
  }

  _getBatchTitle(actionId: string) {
    const titles = {
      compliance: 'Generate Compliance Reports for the selected projects',
      exportPDF: 'Export selected projects in PDF format',
      exportArcml: 'Export selected projects in ArcXml format',
      exportEdb: 'Export selected projects in EDB format',
      exportAirbus: 'Export selected projects in Airbus format',
      restore: 'Restore selected projects',
      delete_forever: 'Delete selected projects permanently',
      delete: 'Delete selected projects'
    };

    return titles.hasOwnProperty(actionId) ? titles[actionId] : 'Unknown Batch Operation';
  }

  _getBatchNotPermittedMessage(actionId: string) {
    const messages = {
      exportEdb: 'Invalid document type. EDB Export applies to Boeing FTID documents only.',
      exportAirbus: 'Invalid document type. Airbus export applies to Airbus documents only.',
      compliance: 'Compliance report not allowed.'
    };

    return messages.hasOwnProperty(actionId) ? messages[actionId] : 'Invalid Permissions';
  }

  _makeStatusList(projects: IProject[] | null, header: string, statusMessage: string, granted?: boolean) {
    if (projects && projects.length > 0) {
      return (
        <div className="perm-section-list">
          <span>{header}</span>
          {projects.map((project) => {
            return (
              <div key={project.uid} className="perm-body-item">
                <span className={'perm-body-item-project-name qa-action-' + (granted ? 'granted' : 'denied')}>{project.name}</span>
                {this._makeStatus(project.reportRejectReason ? project.reportRejectReason : statusMessage, granted!)}
              </div>
            );
          })}
        </div>
      );
    }
  }

  _makeStatus(message: string, granted: boolean) {
    return (
      <div className="perm-body-item-message">
        <span>{message}</span>
        <span className={'perm-status perm-' + (granted ? 'GRANTED' : 'DENIED')} />
      </div>
    );
  }

  _getBatchPermissionBody(actionId: string, selectedProjects: IProject[], permittedSelectedProjects: IProject[]) {
    const deniedSelectedProjects = _.difference(selectedProjects, permittedSelectedProjects);

    return (
      <div className="perm-body-outer">
        <div className="perm-body-inner">
          {this._makeStatusList(
            deniedSelectedProjects,
            "This task won't be completed for documents below:",
            this._getBatchNotPermittedMessage(actionId),
            false
          )}
          {this._makeStatusList(permittedSelectedProjects, 'This task will be completed for documents below:', '', true)}
        </div>
      </div>
    );
  }

  _getBatchResponseBody(goodProjects: IProject[], errorProjects: string[], statusChange: string) {
    const headings: { [key in StatusChangeType]: string } = {
      ACTIVE: 'Sorry all Restores could not be carried out at this time',
      DELETED: 'Sorry all Delete Permanently operations could not be carried out at this time',
      TRASHED: 'Sorry all Deletes could not be carried out at this time'
    };

    const heading = headings.hasOwnProperty(statusChange)
      ? headings[statusChange]
      : 'Sorry unknown batch operation could not be carried out at this time';

    return (
      <div className="perm-body-outer">
        <span>{heading}</span>
        {this._makeStatusList(this.state.selected, '', 'Error processing')}
      </div>
    );
  }

  _permissionCheck(selectedProjects: IProject[], actionId: string, doAction?: (selectedProjects: IProject[], actionId: string) => void) {
    if (doAction) {
      // remove project you don't have permission
      const permittedSelectedProjects = selectedProjects.filter((project) => {
        return this._verifyProjectPermission(actionId, project);
      });

      if (permittedSelectedProjects.length !== selectedProjects.length) {
        // show dialog
        this.setState({
          showModal: {
            title: this._getBatchTitle(actionId),
            message: this._getBatchPermissionBody(actionId, selectedProjects, permittedSelectedProjects),
            actions: [
              <FlatButton key={1} label="Cancel" onClick={() => this._handleDeleteRemoveModalCancel()} />,
              <FlatButton
                key={2}
                label="Continue"
                disabled={!permittedSelectedProjects || permittedSelectedProjects.length === 0}
                onClick={() => {
                  this.setState({ showModal: null });
                  doAction(permittedSelectedProjects, actionId);
                }}
              />
            ]
          }
        });
      } else {
        doAction(selectedProjects, actionId);
      }
    }
  }

  _handleBatchOpertions(projects: IProject[], actionId: string) {
    if (actionId === 'exportPDF') {
      projects.forEach((projects) => {
        setTimeout(() => {
          NotificationsStore.startNotifProgress();
          projectClient.exportPdf(projects.uid, projects.masterIndexUid);
        }, 300); // delay allows popup menu to hide first, otherwise notif popup and menu blur clash causing popup to close
      });
    } else if (actionId === 'exportArcml') {
      this._exportAsync(projects, FileExtension.ZIP);
    } else if (actionId === 'exportEdb') {
      this._exportAsync(projects, FileExtension.EDB);
    } else if (actionId === 'exportAirbus') {
      this._exportAsync(projects, FileExtension.AIRBUS);
    } else if (actionId === 'restore') {
      DocumentsStore.updateProjectStatus(projects, this.props.match.params.workspaceUid, 'ACTIVE');
    } else if (actionId === 'delete') {
      this.setState(
        {
          single: this._manyProjectsSelected() ? null : projects[0]
        },
        () => {
          const strings = this._getStrings(this.state);
          this.setState({
            showModal: {
              title: StringUtil.text('deleteTitle', strings),
              message: StringUtil.text('deleteBody', strings),
              actions: [
                <FlatButton key={1} label="Cancel" onClick={() => this._handleDeleteRemoveModalCancel()} data-qa={'cancel-btn'} />,
                <FlatButton
                  key={2}
                  label="Delete"
                  onClick={() => {
                    this._handleRemoveModalDelete(projects);
                  }}
                  data-qa={'delete-btn'}
                />
              ]
            }
          });
        }
      );
    } else if (actionId === 'delete_forever') {
      this.setState({ single: this._manyProjectsSelected() ? null : projects[0] }, () => {
        const strings = this._getStrings(this.state);
        this.setState({
          showModal: {
            title: StringUtil.text('deleteForeverTitle', strings),
            message: StringUtil.text('deleteForeverBody', strings),
            actions: [
              <FlatButton key={1} label="Cancel" onClick={() => this._handleDeleteRemoveModalCancel()} data-qa={'cancel-btn'} />,
              <FlatButton
                key={2}
                label="Delete"
                onClick={() => {
                  this._handleDeleteModalDelete(projects);
                }}
                data-qa={'delete-btn'}
              />
            ]
          }
        });
      });
    } else if (actionId === 'compliance') {
      this._generateComplianceReport(projects);
    }
  }

  private _exportAsync(projects: IProject[], extension: FileExtension) {
    const data: { projectUid: string; indexUid: string }[] = projects.map((project) => {
      return { projectUid: project.uid, indexUid: project.masterIndexUid };
    });
    asyncExportUtil.export(extension, data);
  }

  _anyProjectSelected(): boolean {
    return !!this.state.selected && this.state.selected.length > 0;
  }

  _manyProjectsSelected(): boolean {
    return !!this.state.selected && this.state.selected.length > 1;
  }

  _showSnackbar(msg: string) {
    this.setState({ showProgress: null });
    appStore.dispatch<any>(
      showSystemSnackbarMessage({
        message: msg,
        open: true,
        className: 'custom-snackbar',
        style: { bottom: '38px' },
        bodyStyle: { marginLeft: '30px' },
        autoHideDuration: 3000
      })
    );
  }

  async _generateComplianceReport(projects: IProject[]) {
    NotificationsStore.startNotifProgress(); // will open notif popup to show progress

    if (this._allSelected() && this.state.selectedWorkspace.name !== 'All Documents') {
      await saveCompliance({ workspaceUid: this.state.selectedWorkspace.uid });
    } else {
      const projectUids = projects.map((project) => {
        return project.uid;
      });

      await saveCompliance({ projectUids: projectUids });
    }

    DocumentsStore.updateProjectSelect(null);
  }

  _allSelected() {
    return this.state.contents && this.state.selected && this.state.contents.length === this.state.selected.length;
  }

  render() {
    return (
      <div className="page-container-projects page">
        <nav className="page-header">
          <div className="logo" />
        </nav>
        <div className="page-body projects-container row dark-icons">
          <nav className="page-body-header icon-header projects-edit-menu ">
            <div className="nav-wrapper">
              <h1>
                {this.state.selectedWorkspace
                  ? this.state.selectedWorkspace.type === 'PERSONAL'
                    ? 'Personal'
                    : this.state.selectedWorkspace.name
                  : ''}
              </h1>

              {this.state.selected && this.state.selected.length ? (
                <div className="multi-selected">
                  <div className="popup-counts">{this.state.selected.length}</div>
                  <div>{textUtils.text('selectedCount', this.state.strings)}</div>
                </div>
              ) : undefined}

              {this._canCreateDocument() ? (
                <DocsToolbar
                  onselected={(actionId) => this._handleSelectedAction(actionId)}
                  selected={this.state.selected}
                  workspaceUid={this.props.match.params.workspaceUid}
                />
              ) : undefined}
            </div>
          </nav>

          <div className="row page-inner page-inner-menu-content">
            <DocsMenu workspaceUid={this.props.match.params.workspaceUid} />

            <Documents
              contents={this.state.contents!}
              sort={this.state.sort}
              page={this.state.page}
              snackBar={(msg) => this._showSnackbar(msg)}
              selected={this.state.selected}
              isLoading={this.state.isLoading}
              onOpened={(e) => this._handleDocOpened(e)}
              onDocPropsAction={(actionId, selected) => this._handleSelectedAction(actionId, selected)}
              onRetrieveDocs={(workspaceUid) => this._retrieveDocs(workspaceUid)}
              onPerformSortingAndPaging={(sort, page) => this._performSortingAndPaging(sort, page)}
              workspaceUid={this.props.match.params.workspaceUid}
              activeTab={this.props.match.params.activeTab ?? 'properties'}
            />
          </div>
        </div>

        <div className="delete-popup">
          {this.state.showModal ? (
            <Dialog
              className="name-modal"
              modal={true}
              open={true}
              actions={this.state.showModal.actions}
              contentClassName="delete-modal-actions"
              title={this.state.showModal.title}
            >
              {this.state.showModal.message}
            </Dialog>
          ) : undefined}
        </div>

        {this.state.showProgress ? (
          <Dialog style={{ zIndex: 999 }} title={this.state.showProgress.title} open={true} modal={true}>
            {this.state.showProgress.message}
            <LinearProgress mode="indeterminate" data-qa={'teamspace-page-show-progress-modal'} />
          </Dialog>
        ) : undefined}

        <ImportDocument
          workspaceUid={this.state.selectedWorkspace ? this.state.selectedWorkspace.uid! : null}
          uploadType={this.state.uploadType}
          selectedWorkspace={this.state.selectedWorkspace}
          projectUid={this.state.targetProject ? this.state.targetProject.uid : null}
          open={this.state.externalFileModalOpened}
          onExternalSuccess={(e) => this._onExternalModalSuccess(e)}
          onInternalSuccess={() => this._onInternalModalSuccess()}
          onCancel={() => this._onExternalModalCancel()}
        />
      </div>
    );
  }
}
