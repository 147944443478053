import * as React from 'react';
import FileImporter from './modals/FileImporter';
import { IProject, IWorkspace } from 'mm-types';
import * as importClient from '../../clients/import-export';
import { AxiosError } from 'axios';

export type UploadType = 'aerodocs' | 'external' | 'attachment' | 'external-revision';

export type Props = {
  workspaceUid: string | null;
  uploadType: UploadType;
  selectedWorkspace: Partial<IWorkspace>;
  projectUid: string | null;
  open: boolean;
  onCancel: () => void;
  onExternalSuccess: (project?: IProject) => void;
  onInternalSuccess: () => void;
};

export type State = {
  errorMsg: string | null;
  isBusy: boolean;
  fileName: string | null;
  fileType: string | null;
  files: null | FileList;
  showProgress: boolean;
  showModal: boolean;
};

export default class ImportDocument extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showProgress: false,
      showModal: false,
      errorMsg: null,
      isBusy: false,
      fileName: null,
      fileType: null,
      files: null
    };
  }

  async _onExternalImport(files: File[], comment: string) {
    if (!files) {
      return;
    }

    if (this.props.projectUid) {
      this.setState({ isBusy: true, errorMsg: null });

      try {
        await importClient.uploadExternal(files[0], this.props.projectUid, comment);
        this.setState({ isBusy: false });
        if (this.props.onExternalSuccess) {
          this.props.onExternalSuccess();
        }
      } catch (err) {
        const axiosErr = err as AxiosError;
        this.setState({ isBusy: false });

        if (axiosErr.response && (axiosErr.response.status === 400 || axiosErr.response.status === 404)) {
          if (axiosErr.response.data.errors[0].code === 40401) {
            this.setState({ errorMsg: 'Error: User does not have permissions to upload a file' });
          } else if (axiosErr.response.data.errors[0].code === 404402) {
            this.setState({ errorMsg: 'Error: Invalid project type' });
          } else if (axiosErr.response.data.errors[0].message) {
            this.setState({ errorMsg: axiosErr.response.data.errors[0].message });
          } else {
            this.setState({ errorMsg: 'Error: Could not upload file' });
          }
        } else {
          this.setState({ errorMsg: 'Error: Could not upload file' });
        }
      }
    } else {
      const file = files[0];
      if (!file) {
        return;
      }

      this.setState({ isBusy: true, errorMsg: null });

      try {
        const project = await importClient.importProject(file, {
          isExternalFile: true,
          workspaceUid: this.props.workspaceUid ? this.props.workspaceUid : undefined
        });

        this.setState({ isBusy: false }, () => {
          if (this.props.onExternalSuccess) {
            this.props.onExternalSuccess(project);
          }
        });
      } catch (err) {
        const axiosErr = err as AxiosError;
        let errorMsg = 'Error: Could not upload file';
        if (
          axiosErr.response &&
          axiosErr.response.status === 400 &&
          axiosErr.response.data.errors &&
          axiosErr.response.data.errors[0].code === 404402
        ) {
          errorMsg = 'Error: Invalid project type';
        } else if (axiosErr.response?.data.errors[0].message) {
          errorMsg = axiosErr.response.data.errors[0].message;
        }
        this.setState({ errorMsg: errorMsg, isBusy: false });
      }
    }
  }

  async _onAerodocsImport(files: File[]) {
    let file;
    if (files) {
      file = files[0];
    }
    if (!file) {
      return;
    }

    this.setState({ isBusy: true, errorMsg: null });

    try {
      await importClient.importProjectAsync(file, { workspaceUid: this.props.workspaceUid });
      this.setState({ isBusy: false }, () => {
        this.props.onInternalSuccess();
      });
    } catch (err) {
      const axiosErr = err as AxiosError;
      this.setState({ isBusy: false });
      const { message, code } = this.getErrorResponse(axiosErr);

      if (axiosErr.response && axiosErr.response.status === 400) {
        if (message) {
          this.setState({ errorMsg: message });
        } else if (code === 40011) {
          this.setState({ errorMsg: 'Invalid Teamspace' });
        } else if (code === 40305) {
          this.setState({ errorMsg: 'Insufficient Teamspace permissions' });
        } else {
          this.setState({ errorMsg: 'Error: Could not upload file' });
        }
      } else {
        this.setState({ errorMsg: message || 'Error: Could not upload file' });
      }
    }
  }

  onCancel() {
    this.setState({ errorMsg: null, isBusy: false });
    this.props.onCancel();
  }

  private getErrorResponse(axiosErr: AxiosError): { code?: number; message?: string } {
    const response = axiosErr.response && axiosErr.response.data.errors && axiosErr.response.data.errors[0];
    if (!response) {
      return {};
    }
    return {
      code: response.code || undefined,
      message: response.message || undefined
    };
  }

  render() {
    return (
      <div>
        <FileImporter
          workspaceUid={this.props.selectedWorkspace ? this.props.selectedWorkspace.uid! : null}
          uploadType={this.props.uploadType}
          projectUid={this.props.projectUid}
          isBusy={this.state.isBusy}
          open={this.props.open}
          importError={this.state.errorMsg}
          onAerodocsImport={(files: File[]) => this._onAerodocsImport(files)}
          onExternalImport={(files: File[], comment) => this._onExternalImport(files, comment)}
          onSuccess={() => this.props.onExternalSuccess()}
          onCancel={() => this.onCancel()}
        />
      </div>
    );
  }
}
