import * as React from 'react';
import { ApiAction, ApiResponseState } from '../../types/useApi';
import axios from 'axios';

export const initialState = {
  isFetching: false,
  isError: false,
  response: undefined
};

export const responseReducer: React.Reducer<ApiResponseState<any>, ApiAction<any>> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        response: undefined,
        isFetching: true,
        isError: false
      };
    case 'FETCH_SUCCESS':
      return {
        isFetching: false,
        isError: false,
        response: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        response: undefined,
        isFetching: false,
        isError: true
      };
    default:
      throw new Error();
  }
};

export function dispatchApiRequestInit<T>(dispatch: React.Dispatch<ApiAction<T>>) {
  dispatch({ type: 'FETCH_INIT' });
}

export function dispatchApiRequestSuccess<T>(dispatch: React.Dispatch<ApiAction<T>>, mounted: boolean, result: T) {
  if (mounted) {
    dispatch({ type: 'FETCH_SUCCESS', payload: result });
  }
}

export function dispatchApiRequestFailure<T>(dispatch: React.Dispatch<ApiAction<T>>, mounted: boolean, e) {
  if (mounted) {
    dispatch({ type: 'FETCH_FAILURE' });
  }
}

export function useApiHook<T extends (...args: any[]) => any>(
  client: T,
  startNow = true,
  ...args: Parameters<T>
): { state: ApiResponseState<ReturnType<T>>; callApi: () => void } {
  const [start, setStart] = React.useState<boolean>(startNow);
  const [state, dispatch] = React.useReducer<React.Reducer<ApiResponseState<ReturnType<T>>, ApiAction<ReturnType<T>>>>(responseReducer, {
    isError: false,
    isFetching: false
  });

  function callApi() {
    setStart(true);
  }

  React.useEffect(() => {
    if (!start) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    dispatchApiRequestInit(dispatch);
    client(args, cancelTokenSource)
      .then(dispatchApiRequestSuccess.bind(this, dispatch, mounted))
      .catch(dispatchApiRequestFailure.bind(this, dispatch, mounted));

    return () => {
      mounted = false;
      cancelTokenSource.cancel('Cancelling in cleanup');
    };
  }, [start]);

  return {
    state,
    callApi
  };
}
