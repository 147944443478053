import * as React from 'react';
import { LineType } from './mergeTypes';

export const defaultLine: LineType = {
  from: { x: 0.0, y: 0.0, color: 'transparent' },
  to: { x: 0.0, y: 0.0, color: 'transparent' },
  color: 'transparent'
};
export default ({ to, from, color }: LineType = defaultLine) => {
  const svgStyle: React.CSSProperties = {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
    height: '100%',
    width: to.x - from.x,
    left: `${from.x}`,
    pointerEvents: 'none'
  };
  return (
    <svg id="interim-merge-diff-line-container" style={svgStyle}>
      <line id="interim-merge-diff-line" x1={0} y1={from.y} x2={to.x - from.x} y2={to.y} strokeWidth="4" stroke={color || 'black'} />
    </svg>
  );
};
