import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import PowerPasteHeader from './PowerPasteHeader';
import PowerPasteContent from './PowerPasteContent';
import FlatButton from 'material-ui/FlatButton';
import { IEditorStoreEvent } from 'mm-types';

export type Props = {
  onRequestClose: () => void;
};

export type State = {
  isAnalyzePhase: boolean;
  isContentPopulated: boolean;
  unitError: boolean;
};

export default class PowerPasteModal extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this.unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
    this.state = this.getIntialState();
  }

  getIntialState() {
    return {
      unitError: false,
      isAnalyzePhase: true,
      isContentPopulated: false
    };
  }

  componentWillUnmount() {
    this._close(true);
    this.unsubscribe();

    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      editor.getActiveEditorFacade()!.setContentEditable(true);
      editor.silentReFocus();
    }
  }

  UNSAFE_componentWillMount() {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      editor.getActiveEditorFacade()?.setContentEditable(false);
    }
  }

  _refocusEditor(e: React.MouseEvent<HTMLElement>, type?) {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      if (type === 'click') {
        if (!$(e.target).is('input[type=file]')) {
          e.stopPropagation();
          e.preventDefault();
        }
      }

      if (
        !/input/i.test((e.target as HTMLDivElement).tagName) &&
        !/textarea/i.test((e.target as HTMLDivElement).tagName) &&
        !/force-focus/i.test((e.target as HTMLDivElement).className)
      ) {
        if (type === 'mousedown') {
          // only stop mousedowns if not in an input, as we ALLOW mouse down on input
          e.stopPropagation();
          e.preventDefault();
        }

        editor.silentReFocus();
      }
    }
  }

  onContentChange(e) {
    this.setState({
      isContentPopulated: e.content ? true : false
    });
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<'pasteHtmlParsed'>) {
    if (e.type === 'pasteHtmlParsed') {
      (this.refs.content as PowerPasteContent).setUnits(e.docUnits);
      this._errorInUnit(e.docUnits);
      this.setState({ isAnalyzePhase: false });
      (this.refs.content as PowerPasteContent).showInsert();
    }
  }

  _errorInUnit(units) {
    let unitError = false;
    units?.map(function (unit) {
      if (unit.html?.match('UNABLE TO CONVERT ELEMENT')) {
        unitError = true;
      }
    });
    this.setState({ unitError: unitError });
  }

  _handleFooterAction(action: 'cancel' | 'analyze' | 'insert') {
    if (action === 'cancel') {
      this._close();
    } else if (action === 'analyze') {
      (this.refs.content as PowerPasteContent).setLoading(true);
      this.setState({ isAnalyzePhase: false });

      const pasteContent = '<div>' + (this.refs.content as PowerPasteContent).getContent() + '</div>';
      EditorStore.powerPaste(pasteContent);
    } else if (action === 'insert') {
      EditorStore.createBatchUnits((this.refs.content as PowerPasteContent).getUnits());
      this._close();
    }
  }

  _close(isSilent?: boolean) {
    this.setState({ isAnalyzePhase: true });
    (this.refs.content as PowerPasteContent).close();

    if (!isSilent) {
      this.props.onRequestClose();
    }
  }

  reset() {
    (this.refs.content as PowerPasteContent).disableInsert();
    this.setState(this.getIntialState());
  }

  render() {
    return (
      <div className="editor-fullpage-modal powerpaste-container-outer" onClick={(e) => this._refocusEditor(e)}>
        <div className="powerpaste-content-container-outer">
          <PowerPasteHeader />
          <div className="row page-inner page-inner-menu-content">
            <PowerPasteContent
              ref="content"
              errorInUnit={(e) => this._errorInUnit(e)}
              onContentChange={(e) => this.onContentChange(e)}
              reset={() => this.reset()}
            />
          </div>
        </div>

        <div className="actions-footer">
          <div className="buttons">
            <FlatButton
              label="Cancel"
              onClick={(e) => {
                this._handleFooterAction('cancel');
              }}
            />
            <FlatButton
              label="Analyze"
              onClick={(e) => {
                this._handleFooterAction('analyze');
              }}
              disabled={!this.state.isAnalyzePhase || (this.state.isAnalyzePhase && !this.state.isContentPopulated)}
            />
            <FlatButton
              label="Insert"
              onClick={(e) => {
                this._handleFooterAction('insert');
              }}
              disabled={this.state.isAnalyzePhase || this.state.unitError}
            />
          </div>
        </div>
      </div>
    );
  }
}
