import * as React from 'react';
import { useContext } from 'react';
import baseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

const custom = {
  appBackground: '#FFFFFF',
  subheaderBackground: '#d8f0f9',
  text: {
    size: {
      xl: 24,
      ll: 18,
      l: 16,
      m: 14,
      s: 12,
      xs: 10
    },
    color: {
      warning: 'red',
      info: 'yellow'
    }
  },
  spacing: {
    xl: 32,
    ll: 24,
    l: 16,
    m: 8,
    s: 4,
    xs: 2,
    none: 0
  },
  button: {
    standard: {
      textColor: 'rgba(0, 0, 0, 0.87)',
      disabledTextColor: 'rgba(0, 0, 0, 0.3)'
    },
    gray: {
      textColor: 'rgba(151, 151, 151, 1)',
      disabledTextColor: 'rgba(151, 151, 151, 0.3)'
    },
    primary: {
      textColor: 'rgba(5, 173, 222, 1)',
      disabledTextColor: 'rgba(5, 173, 222, 0.3)'
    }
  }
};

const rootTheme: AppTheme = {
  palette: {
    primary1Color: 'rgba(5, 173, 222, 1)',
    accent1Color: 'rgba(5, 173, 222, 1)'
  },
  custom
};

export type AppTheme = __MaterialUI.Styles.MuiTheme & { custom: typeof custom };
export const defaultTheme = getMuiTheme(baseTheme, rootTheme) as AppTheme;

export const ThemeContext = React.createContext<AppTheme>(defaultTheme);

export const useTheme = () => {
  return useContext(ThemeContext);
};
