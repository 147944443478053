import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import Alignment, { ActionType } from '../controls/Alignment';
import Border from '../controls/Border';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export type State = {
  isCaptionOn: boolean;
  align: ActionType['action'];
  border: string;
  borderStyle: string;
  caption: string | null;
  focusElm: null | Element;
  errorMessage?: string | null;
};

export default class VideoEditProps extends React.Component<IGenericEditProps, State> {
  private unsubscribe: Function;
  private _userOpened: boolean;
  captionRef = React.createRef<TextField>();

  constructor(props: IGenericEditProps) {
    super(props);
    this.state = {
      isCaptionOn: false,
      align: 'JustifyLeft',
      border: 'BorderNone',
      borderStyle: 'BorderStyleSolid',
      focusElm: null,
      caption: ''
    };
  }

  _handleKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 27) {
      // return or esc

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      e.target.blur();
      EditorStore.getEditor().silentReFocus();
    } else if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      // cmd/ctrl-s

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      EditorStore.getEditor().blur();
    }
  }

  UNSAFE_componentWillMount() {
    this._updateState();
    this.unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  _onEditStoreUpdate(e) {
    if (e.type === 'unitStyleChange' || e.type === 'nestedUnitFocusChange') {
      if (e.type === 'unitStyleChange') {
        if (['justify', 'border'].indexOf(e.data.styleType) > -1) {
          this._updateState();
        }
      } else {
        // nestedUnitFocusChange
        if (!(e.data.focused === null && e.data.parent === null)) {
          this._updateState();
        }
      }
    }
  }

  _updateState(callback?: () => void) {
    const editor = EditorStore.getEditor().getActiveEditorFacade()!;
    const { enabled, value } = editor.getCaption('figure');
    const figure = editor.getSelectedFigure();

    this.setState(
      {
        isCaptionOn: enabled,
        caption: value,
        align: editor.getJustification('figure'),
        border: editor.getBorder('figure'),
        borderStyle: editor.getBorderStyle('figure'),
        focusElm: figure
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  _handleCaptionToggle() {
    const editor = EditorStore.getEditor();
    editor.getActiveEditorInstance()?.selection.select(this.state.focusElm!);
    editor.getActiveEditorFacade()?.execCommand('arcToggleCaption', 'figure');

    const { enabled, value } = EditorStore.getEditor().getActiveEditorFacade()!.getCaption('figure');

    this.setState({ isCaptionOn: enabled, caption: value }, () => {
      if (enabled) {
        setTimeout(() => this.captionRef.current?.focus(), 300);
      }
    });
  }

  _onVideoAlignmentChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);
  }

  _onBorderChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);
    facade.execCommand(e.styleAction, null);
  }

  _onBorderStyleChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);

    setTimeout(() => {
      EditorStore.getEditor().silentReFocus();
    }, 300);
  }

  _handleCaptionChange(e) {
    if (e.target.value.length > 499) {
      this.setState({ errorMessage: 'Maximum characters reached' });
    } else {
      this.setState({ errorMessage: null });
    }
    this.setState({ caption: e.target.value }, () => {
      EditorStore.getEditor()
        .getActiveEditorFacade()!
        .setCaption('figure', this.state.caption, this.state.focusElm!.querySelector('figcaption'));
    });
  }

  render() {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        <ToggleSection
          title="Video Properties"
          id={'unitProps_' + this.props.index}
          defaultOpen={false}
          forceClose={!this.props.isLeaf}
          forceOpen={this.props.isLeaf || this._userOpened}
          onToggled={(isOpened) => (this._userOpened = isOpened)}
        >
          <div className="selected-unit-props-section image-edit image-edit-caption">
            <div className="selected-unit-props-subsection">
              <div className="caption-toggle-container" onClick={(e) => this._handleCaptionToggle()}>
                <Toggle name="graphiccaption" label="Include video caption" toggled={this.state.isCaptionOn} />
              </div>

              {this.state.isCaptionOn && (
                <TextField
                  onKeyDown={(e) => this._handleKeyDown(e)}
                  onChange={(e) => this._handleCaptionChange(e)}
                  errorText={this.state.errorMessage}
                  className="image-edit-textfield"
                  value={this.state.caption as string}
                  floatingLabelText="Caption Text"
                  id="caption-text-input"
                  ref={this.captionRef}
                />
              )}
            </div>
          </div>

          <div className="selected-unit-props-section image-edit">
            <div className="selected-unit-props-subsection">
              <Alignment title="Horizontal video align" onChange={(e) => this._onVideoAlignmentChange(e)} align={this.state.align} />

              <Border
                title="Video Border"
                onBorderChange={(e) => this._onBorderChange(e)}
                onBorderStyleChange={(e) => this._onBorderStyleChange(e)}
                border={this.state.border}
                borderStyle={this.state.borderStyle}
              />
            </div>
          </div>

          {UnitUtils.isFormatingAvailable(this.props.unitElement) && (
            <MultiFormatting formatNode={this.props.index === 0 ? null : this.props.unitElement} unitProfile={this.props.unitProfile} />
          )}
          <DataAttributesPropsWrapper {...this.props} />
        </ToggleSection>
      </div>
    );
  }
}
