import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { IDocUnitProfile } from 'mm-types';

interface Props {
  formatNode?: HTMLElement | null;
  unitProfile?: IDocUnitProfile;
}

const MultiFormatting = (props: Props) => {
  const changeFormat = (isIncrease, formatType) => {
    if ((EditorStore as any).isEditorFocused()) {
      const editor = (EditorStore as any).getEditor().getActiveEditorFacade()!;
      editor.format(isIncrease, formatType, props.unitProfile, props.formatNode);
    } else {
      EditorStore.formatSelectedUnits(isIncrease, formatType);
    }
  };

  const formattingValues = ProjectDefinitionStore.getFormattingValues()!;
  const isUnitOrdinaled =
    EditorStore.isEditorFocused() &&
    EditorStore.getSelectedUnit() &&
    EditorStore.getSelectedUnit()!.hasOrdinal &&
    !EditorStore.isNestedUnitFocused();
  const label = (EditorStore as any).isEditorFocused() ? <label>Formatting</label> : null;

  return (
    <div className="selected-unit-props-section multi-format-section">
      {label}
      {formattingValues.map((format) => {
        const formatType = format.propertyName.toLowerCase();
        const ordinalOutdent = formatType === 'ordinal';
        if (!ordinalOutdent || (ordinalOutdent && isUnitOrdinaled)) {
          return (
            <div key={formatType}>
              <label>{format.propertyName} </label>
              <IconButton onClick={() => changeFormat(true, formatType)} iconClassName="material-icons">
                add
              </IconButton>
              <IconButton onClick={() => changeFormat(false, formatType)} iconClassName="material-icons">
                remove
              </IconButton>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default MultiFormatting;
