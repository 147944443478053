import * as React from 'react';
import { useState } from 'react';
import MenuActionItem from '../../EditorMenu/MenuActionItem';
import { MenuActionItemContentProps } from '../../EditorMenu/MenuActionItemContent';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { EventStoreEventType, IEditorStoreEvent, IUnit } from 'mm-types';
import useListenToStore from '../../../../hooks/useListenToStore';
import { useClipboard } from '../../../../../utils';

interface Props {
  action: MenuActionItemContentProps['action'];
  onAction: MenuActionItemContentProps['onAction'];
}

export function PasteElementAction({ action, onAction }: Props) {
  const [isPasteEnabled, setIsPasteEnabled] = useState(false);
  const { currentClipboard } = useClipboard();

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate, update: [currentClipboard] });

  React.useEffect(() => {
    if (isSelectedUnitInView(EditorStore.getSelectedUnit())) {
      setIsPasteEnabled(true);
    } else {
      setIsPasteEnabled(false);
    }
  }, []);

  React.useEffect(() => {
    if (currentClipboard) {
      setIsPasteEnabled(true);
    } else {
      setIsPasteEnabled(false);
    }
  }, [currentClipboard]);

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'nestedUnitFocusChange' && currentClipboard) {
      setIsPasteEnabled(true);
    } else if (e.type === 'unitsSelected' || e.type === 'loadMoreUnits') {
      if (
        isSelectedUnitInView(
          e.type === 'loadMoreUnits' ? EditorStore.getSelectedUnit() : (e as IEditorStoreEvent<'unitsSelected'>).data?.initialSelectedUnit
        ) &&
        currentClipboard
      ) {
        setIsPasteEnabled(true);
      } else {
        setIsPasteEnabled(false);
      }
    }
  }

  const isSelectedUnitInView = (selectedUnit?: IUnit | null): boolean => {
    const selectedUnitInView = $(`[data-nid="${selectedUnit?.uid}"]`)[0];
    return !!selectedUnitInView;
  };

  return <MenuActionItem action={action} onAction={onAction} enabled={isPasteEnabled} inClassNameIcon={true} />;
}
