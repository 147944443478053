import { Action, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import snackbarReducer from './components/misc/SystemSnackbar/reducers';
import thunk, { ThunkAction } from 'redux-thunk';
import systemAlertReducer from './components/misc/SystemAlert/reducers';
import projectSettingsReducer from './components/editor/settings/reducer';
import featureSwitchReducer from './components/featureSwitch/reducer';

const { NODE_ENV } = process.env;
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

const appReducer = combineReducers({
  snackbar: snackbarReducer,
  systemAlert: systemAlertReducer,
  features: featureSwitchReducer,
  projectSettings: projectSettingsReducer
});

export type AppState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, AppState, null, Action<string>>;

export const createAppStore = (initialState: Partial<AppState> = {}) => {
  return createStore(
    appReducer,
    initialState,
    NODE_ENV !== 'production' ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
  );
};

const appStore = createAppStore();
export default appStore;
