import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as client from '../../clients/toc';
import ProjectStore from './ProjectStore';
import RevisionStore from './RevisionStore';
import EditorModes from './EditorModes';
import { UnitTypes } from '../../components/editor/utils/units/UnitTypes';
import * as unitsClient from '../../clients/units';
import Log from '../../utils/Log';
import Store from '../Store';
import EditorStore from './EditorStore';
import { DocParams, IFrontMatter, IToc, ITocNode, IUnit, IUser } from 'mm-types';
import { AxiosError } from 'axios';
import { Cancelled } from '../../clients/base-clients';
import ProjectDefinitionStore from '../common/ProjectDefinitionStore';

export type DragNode = null | ITocNode;

export type State = {
  loading: boolean;
  tableOfContents: ITocNode[];
  frontMatter: ITocNode[];
  appendices: ITocNode[];
  tocDiffMap: { [name: string]: number };
  selected: null | ITocNode;
  movableFrontmatter?: boolean;
  draggingNode?: DragNode;
  error?: AxiosError | null;
};

export type RetrieveOptions = {
  isDiffOn?: boolean;
  projectUid: string;
  documentIndexUid: string;
  depth?: number;
  includeGraphics?: boolean;
  includeParagraphs?: boolean;
  includeCaptionUnits?: boolean;
  includeTables?: boolean;
  variant?: string | null;
};

export type ManipulationOptions = {
  nativeEvent?: string;
  node?: ITocNode;
  afterTocableUnitUid?: string;
  beforeTocableUnitUid?: string;
  selected?: ITocNode | null;
};

export type RenderDirective = {
  refreshAllUnits?: boolean;
  doNothing?: boolean;
};

export type SyncOptions = {
  tocReloadPage?: boolean;
  user?: null | IUser;
  pageRenderDirective: RenderDirective;
};

export type RefreshOptions = {
  snackMessage?: string;
  forceDocRefresh?: boolean;
  removedUnit?: Partial<IUnit | ITocNode> | null;
  selectUnit?: Partial<IUnit | ITocNode> | null;
  expandTree?: Partial<IUnit | ITocNode> | null;
};

export type ActionsType = 'startDrag' | 'copy' | 'delete' | 'promote' | 'demote' | 'move';

export type TocStoreEvent = {
  type:
    | 'tocManipulation'
    | 'initToc'
    | 'highlightTocItem'
    | 'selectTocItem'
    | 'refreshToc'
    | 'startDrag'
    | 'error'
    | 'refresh-started'
    | 'tocWithSharedOriginDelete';
  data: State;
  snackMessage?: string;
  expandTree?: Partial<IUnit> | null;
  forceDocRefresh?: boolean;
  shareOriginDeleteInfo?: { projectUid: string; indexUid: string; tocableUnitUid: string; refreshOptions: RefreshOptions };
};

export const DEFAULT_TOC_DEPTH = 10;
export const INCREASED_TOC_DEPTH = 100;

export class TocStore extends Store<State> {
  private params: DocParams;
  private startUnit: null | ITocNode;

  private _frontmatter: ITocNode[];
  private _appendices: ITocNode[];
  private _children: ITocNode[];
  private _isSingleVolume: boolean;
  private _movableFrontmatter: boolean;
  private _tocDiffMap: null | { [id: string]: number };
  private _appendicesUnitUid: string;

  constructor() {
    super();

    this._frontmatter = [];
    this._appendices = [];
    this._children = [];
    this._isSingleVolume = true;
    this._movableFrontmatter = false;

    this.state = {
      loading: true,
      tableOfContents: [],
      frontMatter: [],
      appendices: [],
      tocDiffMap: {},
      selected: null,
      draggingNode: null,
      movableFrontmatter: false
    };
  }

  getInitialState() {
    return this.state;
  }

  // ------------------------------------------------------------------------------
  // Event Handlers
  // ------------------------------------------------------------------------------

  async initToc(params: DocParams) {
    this.clear();

    this.params = params;

    await this._fetch();

    this.state.tableOfContents = this._children;
    this.state.movableFrontmatter = this._movableFrontmatter;
    this.state.appendices = this.getAppendices() || [];
    this.state.frontMatter = this.getFrontMatter() || [];
    this._selectFirstTocItem();
  }

  loadingComplete() {
    this.state.loading = false;
    this.trigger({ type: 'initToc', data: this.state } as TocStoreEvent);
  }

  async synchronizeToc(e: any) {
    if (this._children) {
      if (EditorModes.getProperties(EditorStore.getMode()).queueSynchronization(e)) {
        Log.info('Editor updated by another user: queued for future hard reload when switched to an appropriate mode');
        return null;
      }

      // get tocables and potentially removed tocables
      const changedTocableUnits = e.data.filter(
        (unit: ITocNode) => unit.type === 'tocable' || unit.type === 'ghost' || unit.type === 'removed'
      );
      let updatedSelectedUnit: ITocNode | null = null;
      const updatedTocables: { previous: ITocNode; latest: ITocNode }[] = [];
      const newTocables: ITocNode[] = [];

      for (const inx in changedTocableUnits) {
        const changedUnit: ITocNode = changedTocableUnits[inx];

        if (changedUnit.uid === (this.state.selected ? this.state.selected.uid : null)) {
          updatedSelectedUnit = changedUnit;
          break; // no need to do anything else as this state is priority over any others
        } else {
          const tocInTree = this.getTocItem(changedUnit.uid);
          if (tocInTree) {
            updatedTocables.push({ previous: tocInTree, latest: changedUnit });
          } else {
            if (changedUnit.type !== 'ghost' && changedUnit.type !== 'removed') {
              newTocables.push(changedUnit);
            }
          }
        }
      }

      if (updatedSelectedUnit) {
        const params = EditorStore.getDocParams();
        const response = await unitsClient.fetchBatch(params.projectUid!, params.indexUid!, [updatedSelectedUnit.uid]);

        const latestSelectedTocUnit = response.units[0];

        if (latestSelectedTocUnit.type === 'ghost' || latestSelectedTocUnit.type === 'removed') {
          const directive = await this._syncRefresh({
            tocReloadPage: true,
            user: e.user,
            pageRenderDirective: { doNothing: true }
          });
          return directive;
        } else {
          const directive = await this._syncRefresh();
          return directive;
        }

        // let docUnitBatch = new DocumentUnitBatch( EditorStore.getDocParams() );
        // await docUnitBatch.fetch( { data: { unitUids: [ updatedSelectedUnit.uid ] } } );

        // let latestSelectedTocUnit: ITocNode = docUnitBatch.get( "units" )[ 0 ];

        // if ( latestSelectedTocUnit.type === 'ghost' || latestSelectedTocUnit.type === 'removed' ) {
        //   const directive = await this._syncRefresh( { tocReloadPage: true, user: e.user, pageRenderDirective: { doNothing: true } } );
        //   return directive;
        // }
        // else {
        //   const directive = await this._syncRefresh();
        //   return directive;
        // }
      } else if (newTocables.length) {
        await this.refreshToc({ forceDocRefresh: false });

        let isNewTocItemAfterSelected = false;

        newTocables.forEach((newTocUnit) => {
          const selectedToc = this.state.selected ? this.state.selected : { uid: null, type: null };
          const newTocUnitInTree = this.getTocItem(newTocUnit.uid);
          // handle synchronization problem - may happen in GEBs when we are creating TOCs synchronously but frontend has race condition problem when parsing them
          if (!newTocUnitInTree) {
            window.location.reload();
          }
          const newTocUnitPreviousSibling = this.findPreviousTocItem(newTocUnitInTree);

          // level of new toc is same as selected and immediately after it - hard reload!!
          if (
            newTocUnitPreviousSibling.uid === selectedToc.uid &&
            newTocUnitPreviousSibling.type === newTocUnitInTree.type &&
            newTocUnitPreviousSibling.type.indexOf('LEVEL') === -1
          ) {
            isNewTocItemAfterSelected = true;
          }
        });

        if (isNewTocItemAfterSelected) {
          const directive = await this._syncRefresh({ pageRenderDirective: { refreshAllUnits: true } });
          return directive;
        } else {
          const directive = await this._syncRefresh();
          return directive;
        }
      } else if (updatedTocables.length) {
        const directive = await this._syncRefresh();
        return directive;
      } else {
        // EditorActions.synchronizeToc.completed!();
        return null;
      }
    }

    return null;
  }

  // highlight: same as select, but driven from outside the TOC
  highlightTocItem(tocItem: ITocNode | null, callback?: () => void) {
    EditorStore.execWhenReady(() => {
      this.state.selected = tocItem;
      this.trigger({ type: 'highlightTocItem', data: this.state } as TocStoreEvent);

      if (callback) {
        callback();
      }
    });
  }

  // select: same as highlight, but driven from inside the TOC (user click)
  selectTocItem(tocItem: ITocNode) {
    EditorStore.execWhenReady(() => {
      this.state.selected = tocItem;
      this.trigger({ type: 'selectTocItem', data: this.state } as TocStoreEvent);
    });
  }

  async refreshToc(options: RefreshOptions) {
    // need to grab the previous before refresh
    let previousTocItem: Partial<IUnit | ITocNode> | null = null;
    let removedUnit: Partial<IUnit | ITocNode> | null = null;

    if (options.removedUnit) {
      removedUnit = options.removedUnit;

      if (options.removedUnit.parent === undefined) {
        // Lookup unit in TOC, options.removedUnit is a normal doc unit without toc details
        removedUnit = this.findInTOC(options.removedUnit.uid!);
      }
      previousTocItem = this.findPreviousTocItem(removedUnit as ITocNode);
    }

    this.state.loading = true;
    this.trigger({ type: 'refresh-started', data: this.state } as TocStoreEvent);

    await this._fetch();

    this.state.tableOfContents = this._children;
    this.state.movableFrontmatter = this._movableFrontmatter;
    this.state.appendices = this.getAppendices() || [];
    this.state.frontMatter = this.getFrontMatter() || [];
    this.state.tocDiffMap = this.getTocDiffMap() || {};

    if (removedUnit && this.state.selected && removedUnit.uid === this.state.selected.uid) {
      this.state.selected = previousTocItem as ITocNode;
      // TODO: break something with VOLUME?
      // this._triggerSelect (newSelected, newSelected.type === 'VOLUME' ? 1 : 0);
    } else if (options.selectUnit) {
      this.state.selected = this.getTocItem(options.selectUnit.uid!);
    }

    // if we still don't have a selected toc item
    if (!this.state.selected) {
      this._selectFirstTocItem();
    } else {
      // still need to refresh selected toc whatever updates have happened
      this.state.selected = this.getTocItem(this.state.selected.uid);
    }

    this.state.loading = false;

    // complete any promise made
    this.trigger({
      type: 'refreshToc',
      forceDocRefresh: options.forceDocRefresh,
      expandTree: options.expandTree,
      data: this.state,
      snackMessage: options.snackMessage
    } as TocStoreEvent);
  }

  canDeleteUnit(unit: IUnit) {
    const selectedItem = this.getSelectedItem();
    return !!(
      unit &&
      unit.definitionId &&
      selectedItem &&
      !(selectedItem.index === 0 && this.isSingleVolume() && ['chapter', 'volume', 'psl'].indexOf(unit.definitionId) > -1)
    );
  }

  private canDeleteTocUnit(unit: ITocNode) {
    if (!unit || !unit.definitionId) {
      return false;
    }

    if (unit.level !== 'chapter' && unit.level !== 'volume') {
      return true;
    }

    // always allow to remove appendix
    if (unit.level === 'chapter' && unit.type === 'appendix-chapter') {
      return true;
    }

    const tocs = this._children;
    if (this.isSingleVolume()) {
      return tocs.length !== 1;
    } else {
      if (unit.level === 'volume' && tocs.length === 1) {
        return false;
      } else if (unit.level === 'chapter') {
        for (const volume of tocs) {
          for (const chapter of volume.children) {
            if (chapter.uid === unit.uid) {
              if (volume.children.length === 1) {
                return false;
              }
            }
          }
        }

        return true;
      } else {
        return true;
      }
    }
  }

  async tocManipulation(actionName: ActionsType, actionParams: ManipulationOptions = {}) {
    if (actionName === 'startDrag') {
      // only updates state no server call
      this.state.draggingNode = actionParams!.node;
      this.trigger({
        type: 'startDrag',
        data: this.state
      } as TocStoreEvent);
    } else {
      const editorStore = EditorStore;

      if (!editorStore.isBusy()) {
        editorStore.setBusy(true, 'Changing...');

        const newActionParams = actionParams!.nativeEvent === undefined ? actionParams : undefined;
        const selected = (newActionParams && newActionParams.selected) || this.state.selected!;

        const refreshOptions: RefreshOptions = {
          forceDocRefresh: true,
          selectUnit: selected,
          snackMessage: `${_.capitalize(selected.type)} has been ${actionName + 'd'}`
        };

        if (actionName === 'delete') {
          if (!this.canDeleteTocUnit(selected)) {
            this.state.loading = false;
            editorStore.setBusy(false);
            const deleteTocErrorTocEvent: TocStoreEvent = {
              type: 'error',
              snackMessage: 'Cannot perform deletion, as it results in an invalid structure',
              data: this.state
            };
            this.trigger(deleteTocErrorTocEvent);
            return;
          }
          refreshOptions['removedUnit'] = this.state.selected;
        }

        this.state.loading = true;
        const projectUid = ProjectStore.getProject()!.uid;
        const indexUid = ProjectStore.getCurrentRevisionUid();
        const unitUid = selected.uid;

        try {
          if (actionName === 'copy') {
            await client.copyOrdinableUnit(projectUid, indexUid, unitUid, actionParams);
          } else {
            if (actionName === 'move') {
              await client.moveOrdinableUnit(projectUid, indexUid, unitUid, actionParams);
            } else if (actionName === 'delete') {
              const tocSharedOrigins = await client.getTocSharedOrigins(projectUid, indexUid, unitUid);
              if (tocSharedOrigins > 0) {
                this.triggerTocWithSharedOriginsDelete(projectUid, indexUid, unitUid, refreshOptions);
                return;
              } else {
                await client.deleteOrdinableUnit(projectUid, indexUid, unitUid);
              }
            } else if (actionName === 'demote') {
              await client.demoteOrdinableUnit(projectUid, indexUid, unitUid);
            } else if (actionName === 'promote') {
              await client.promoteOrdinableUnit(projectUid, indexUid, unitUid);
            }
            this.reloadAndNotifyUserOfTocManipulationEvent({ type: 'tocManipulation', data: this.state } as TocStoreEvent, refreshOptions);
          }
        } catch (err) {
          const event: TocStoreEvent = {
            type: 'error',
            snackMessage: err?.response?.data?.errors?.[0]?.message ?? '',
            data: this.state
          };
          this.reloadAndNotifyUserOfTocManipulationEvent(event, null);
        }
      }
    }
  }

  public async reloadAndNotifyUserOfTocManipulationEvent(e: TocStoreEvent, refreshOptions: RefreshOptions | null) {
    if (EditorStore.isBusy()) {
      EditorStore.setBusy(false);
    }
    this.state.loading = false;
    if (refreshOptions !== null) {
      await this.refreshToc(refreshOptions);
    }
    this.trigger(e);
  }

  // Retrieves TableOfContents without storing it in the local state
  async retrieveToc(options: RetrieveOptions) {
    if (options.isDiffOn) {
      const response = await client.getTocDiff(
        {
          diffTimestamp: RevisionStore.getDiffIndexUid()!,
          diffIndexUid: RevisionStore.getDiffTimestampFormatted()
        },
        options.projectUid,
        options.documentIndexUid
      );

      return client.getChildren(response.toc);
    } else {
      const response = await client.getToc(
        {
          depth: options.depth,
          includeCaptionUnits: options.includeCaptionUnits,
          includeGraphics: options.includeGraphics,
          includeParagraphs: options.includeParagraphs,
          includeTables: options.includeTables,
          variant: options.variant
        },
        options.projectUid,
        options.documentIndexUid
      );

      if (response instanceof Cancelled) {
        return { appendices: [], children: [] };
      }

      return client.getChildren(response);
    }
  }

  // ------------------------------------------------------------------------------
  // public api
  // ------------------------------------------------------------------------------

  getDroppedItem() {
    return this.state.draggingNode;
  }

  getSelectedItem() {
    return this.state.selected;
  }

  getStartUnit() {
    return this.startUnit;
  }

  setStartUnit(unit: ITocNode) {
    this.startUnit = unit;
  }

  getSelectedTocPath() {
    const tocSelectedUnit = this.getSelectedItem()!;
    const selectedTocPath = [tocSelectedUnit.uid];

    const flattenTocs = (tocUnit: ITocNode) => {
      if (tocUnit.children) {
        tocUnit.children.forEach((child) => {
          selectedTocPath.push(child.uid);
          flattenTocs(child);
        });
      }
    };

    flattenTocs(tocSelectedUnit);

    return selectedTocPath;
  }

  getTocAncestor(tocSelectedUnit = this.getSelectedItem()!, level = 'chapter') {
    let current: ITocNode | null = this.getTocItem(tocSelectedUnit?.uid);
    while (current && current.level !== level) {
      current = current.parent;
    }
    return current;
  }

  getFirstSelectedTocableUnit(uid?: string) {
    const tocableUnits: (UnitTypes | '')[] = [
      'appendix-chapter',
      'chapter',
      'section',
      'volume',
      'frontmatter',
      'frontmatter_header',
      'dynamic',
      ''
    ];
    let it: ITocNode | null;
    if (uid && this._children) {
      it = this.getTocItem(uid);
    } else {
      it = this.state.selected;
    }

    while (it && it.level && tocableUnits.indexOf(it.level) === -1) {
      it = it.parent;
    }
    return it;
  }

  isInAppendix() {
    let it = this.state.selected;

    while (it) {
      if (it.type === 'appendix-chapter') {
        return true;
      }
      it = it.parent;
    }

    return false;
  }

  getError() {
    return this.state.error;
  }

  getState() {
    return this.state;
  }

  isLoaded() {
    return this.state.tableOfContents || this.getError();
  }

  clear() {
    // reset data
    this.state = {
      tableOfContents: [],
      frontMatter: [],
      appendices: [],
      tocDiffMap: {},
      selected: null,
      loading: false
    };
  }

  private isInAppendices(uid: string) {
    return !!this.getTocItemFromAppendices(uid);
  }

  findNextTocableOfSameLevelOrLower(uid: string) {
    let nextSibling: ITocNode | undefined,
      current: ITocNode | null = this.getTocItem(uid);

    // find that nodes next sibling or move up and find its next sibling
    while (!nextSibling && current) {
      nextSibling = this.getSiblings(current.uid).nextSibling;
      current = current.parent;
    }

    if (!nextSibling) {
      return this.isInAppendices(uid) ? null : this.getAppendicesUnitUid();
    } else {
      return nextSibling.uid;
    }
  }

  getSiblings(uid: string): { prevSibling: ITocNode | undefined; nextSibling: ITocNode | undefined } {
    function findArrayWithChild(arr: ITocNode[], uid: string): ITocNode[] | undefined {
      if (arr.find((el) => el.uid === uid)) {
        return arr;
      }

      for (let i = 0; i < arr.length; i++) {
        const found = findArrayWithChild(arr[i].children, uid);
        if (found) {
          return found;
        }
      }
    }

    const array = findArrayWithChild(this.state.tableOfContents, uid) || findArrayWithChild(this.state.appendices, uid);
    const index = array!.findIndex(function (el) {
      return el.uid === uid;
    });
    return {
      prevSibling: array![index - 1],
      nextSibling: array![index + 1]
    };
  }

  private findInTOC(uid: string): ITocNode {
    // returns element from the Array (or it's children) which matches uid
    function find(arr, uid) {
      const result = arr.find(function (el) {
        return el.uid === uid;
      });

      if (result) {
        return result;
      }

      // Check recursively children
      for (let i = 0; i < arr.length; i++) {
        const found = find(arr[i].children, uid);
        if (found) {
          return found;
        }
      }
    }
    const ret = find(this.state.tableOfContents, uid);
    return ret ? ret : find(this.state.appendices, uid);
  }

  // ------------------------------------------------------------------------------
  // Helper Functions
  // ------------------------------------------------------------------------------
  private _syncRefresh(options: SyncOptions = { tocReloadPage: false, user: null, pageRenderDirective: {} }) {
    return new Promise<RenderDirective>((resolve, reject) => {
      if (options.tocReloadPage) {
        // abandon any editing, refresh toc (back to first toc item), and force hard refresh of page (all we can do as entire structure of doc could have changed)
        this.state.selected = null;

        EditorStore.blurEditor(
          () => {
            EditorStore.execWhenReady(() => {
              this.refreshToc({
                forceDocRefresh: true,
                snackMessage: 'Reloading: ' + options.user!.displayName + ' has changed the structure of this page'
              }).then(() => {
                resolve(options.pageRenderDirective);
              });
            });
          },
          { ignoreChanges: true }
        );
      } else {
        const execSync = () => {
          EditorStore.execWhenReady(() => {
            this.refreshToc({
              selectUnit: this.state.selected!,
              forceDocRefresh: false
            }).then(() => {
              resolve(options.pageRenderDirective);
            });
          });
        };

        if (options && options.pageRenderDirective && options.pageRenderDirective.refreshAllUnits) {
          EditorStore.blurEditor(
            () => {
              execSync();
            },
            { ignoreChanges: true }
          );
        }
        // note: not kick user out of any editing, as in some cases we wish to allow them to continue
        else {
          execSync();
        }
      }
    });
  }

  private get firstTocItem(): ITocNode | undefined {
    const frontmatter = this.getFrontMatter() || [];

    if (this._movableFrontmatter) {
      return this.state.tableOfContents[0];
    } else if (frontmatter?.length) {
      return frontmatter[0];
    } else if (this.state.tableOfContents?.length) {
      // Dynamically flatten nodes to have all TOC nodes in single depth array
      const flattenArray = (topNodeArray: ITocNode[]): ITocNode | undefined => {
        const tmpArray: ITocNode[] = [];
        let moreRuns = false;
        let found: ITocNode | undefined = undefined;
        topNodeArray.every((node) => {
          if (node.level !== 'volume') {
            moreRuns = false;
            found = node;
            return false;
          }

          if (node.children.length) {
            moreRuns = true;
            tmpArray.push(...node.children);
          } else {
            tmpArray.push(node);
          }
        });

        if (moreRuns) {
          // Recurisve flatten call
          return flattenArray(tmpArray);
        } else {
          return found;
        }
      };

      return flattenArray(this.state.tableOfContents);
    }

    return undefined;
  }

  private _selectFirstTocItem() {
    const toc = this.firstTocItem;

    if (toc) {
      this.state.selected = toc;
      this.highlightTocItem(this.state.selected);
    }
  }

  async _fetch() {
    try {
      if (EditorStore.isMode('DIFF')) {
        const response = await client.getTocDiff(
          {
            diffIndexUid: RevisionStore.getDiffIndexUid()!,
            diffTimestamp: RevisionStore.getDiffTimestampFormatted()
          },
          this.params.projectUid!,
          this.params.documentIndexUid!
        );
        this.parseDiff(response);
        this.state.error = null;
      } else {
        const response = await client.getToc(
          {
            variant: ProjectStore.getSelectedVariantUid(),
            depth: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() ? INCREASED_TOC_DEPTH : DEFAULT_TOC_DEPTH
          },
          this.params.projectUid!,
          this.params.documentIndexUid!
        );
        if (response instanceof Cancelled) {
          return;
        }

        this.parse(response);
      }
    } catch (err) {
      this.state.error = err as AxiosError;
      if (this.state.error.response && this.state.error.response.statusText !== 'abort') {
        this.trigger({ type: 'error', data: this.state });
      }
    }
  }

  getFrontMatter() {
    return this._frontmatter;
  }

  getAppendices() {
    return this._appendices;
  }

  isSingleVolume() {
    return this._isSingleVolume;
  }

  getChildren() {
    return this._children;
  }

  getTocDiffMap() {
    return this._tocDiffMap;
  }

  private getAppendicesUnitUid() {
    return this._appendicesUnitUid;
  }

  private parseDiff(response: { toc: IToc; tocDiffMap: { [id: string]: number } }) {
    this._tocDiffMap = response.tocDiffMap;
    return this.parse(response.toc);
  }

  private parse(toc: IToc) {
    this._appendicesUnitUid = toc.appendicesUnitUid;
    this._frontmatter = toc.frontmatter?.items ? client.concatChildrenIds(toc.frontmatter.items) : [];

    const { children, appendices, frontmatter } = client.getChildren(toc);
    this._appendices = appendices;
    this._children = children;
    this._movableFrontmatter = !!toc.movableFrontmatter;

    if (!this._frontmatter.length && !!toc.movableFrontmatter) {
      if (frontmatter) {
        this._children.unshift(frontmatter);
      }
      this.parseMovableFrontmatter();
    }

    if (toc.singleVolume) {
      this._isSingleVolume = true;
      return children;
    } else {
      this._isSingleVolume = false;
      return toc.children || [];
    }
  }

  private parseMovableFrontmatter() {
    let frontmatterObj: IFrontMatter = this._children.find((obj) => obj.subType == 'frontmatter-placeholder') as IFrontMatter;
    if (!frontmatterObj) {
      return;
    }

    this._frontmatter = frontmatterObj.items ? client.concatChildrenIds(frontmatterObj.items) : [];
    frontmatterObj.children = this._frontmatter;

    // if items are present then replace the frontmatter uid with first child's uid to avoid loading screen by showing first frontmatter item in editor
    frontmatterObj.uidnid = frontmatterObj.uid;
    frontmatterObj.level = 'chapter';
    frontmatterObj.uid = this._frontmatter.length > 0 ? this._frontmatter[0].uid : frontmatterObj.uid;
  }

  private getTocItemFromAppendices(uid: string) {
    return this._findInTree(uid, this._appendices);
  }

  getTocItem(uid: string): ITocNode {
    const tableOfContents: ITocNode[] = this._children;
    let tocItem = this._findInTree(uid, tableOfContents);

    // try appendix
    if (tocItem === null) {
      tocItem = this._findInTree(uid, this._appendices);
    }

    // try frontmatter
    if (tocItem === null) {
      tocItem = this._findInTree(uid, this._frontmatter);
    }

    return tocItem;
  }

  /*
   * Given a unit it will find the previous unit in the toc hierarchy (a sibling or a parent)
   */
  findPreviousTocItem(unit: ITocNode | null) {
    if (!unit) {
      return null;
    }
    const parent = unit.parent;

    if (parent) {
      const index = parent.children.findIndex((c) => c.uid === unit.uid);
      if (index === 0) {
        return parent;
      } else {
        return parent.children![index - 1];
      }
    } else {
      let list, index;
      const collections = ['_frontmatter', '_children', '_appendices'];

      for (let i = 0; i < collections.length; i++) {
        list = collections[i];

        index = this[list].findIndex((c) => c.uid === unit.uid);
        if (index !== -1) {
          break;
        }
      }

      if (index <= 0) {
        return null;
      }
      // fall back to first
      else {
        return this[list][index - 1];
      }
    }
  }

  findNextTocItem(currentToc: ITocNode | null): ITocNode | null {
    if (!currentToc) {
      return null;
    }
    const currentTocSiblings = this.getSiblings(currentToc.uid);
    if (!currentTocSiblings.nextSibling) {
      return this.findNextTocItem(currentToc.parent);
    } else {
      return currentTocSiblings.nextSibling;
    }
  }

  private _findInTree(uid: string, tree: ITocNode[]) {
    let tocItem: ITocNode | null = null;

    for (const inx in tree) {
      const item = tree[inx];
      if (item.uid === uid) {
        tocItem = item;
        break;
      } else {
        tocItem = this._findInTree(uid, item.children);
        if (tocItem) {
          break;
        }
      }
    }

    return tocItem;
  }

  triggerTocWithSharedOriginsDelete(projectUid: string, indexUid: string, tocableUnitUid: string, refreshOptions: RefreshOptions) {
    this.trigger({
      type: 'tocWithSharedOriginDelete',
      data: this.state,
      shareOriginDeleteInfo: { projectUid, indexUid, tocableUnitUid, refreshOptions }
    } as TocStoreEvent);
  }
}

const singleton = Reflux.initStore<TocStore>(TocStore);
export default singleton;
