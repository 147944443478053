import { useEffect, useRef } from 'react';
import { IsKeyFn } from '../editor/utils/keyIdentifier';

export function useKeyListener(
  target: HTMLElement | Element | Document,
  callback: (e: KeyboardEvent) => void,
  filter?: IsKeyFn,
  eventType: 'keydown' | 'keyup' = 'keydown'
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    target.addEventListener(eventType, onKeyUp);
    return () => {
      target.removeEventListener(eventType, onKeyUp);
    };
  }, [target]);

  function isMatched(e: KeyboardEvent): boolean {
    if (!filter) {
      return true;
    }
    return filter(e as any);
  }

  function onKeyUp(e: KeyboardEvent) {
    if (!filter || !filter.length || isMatched(e)) {
      callbackRef.current(e);
    }
  }
}
