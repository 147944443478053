/* FRAMEWORK */
import * as React from 'react';
import { useState } from 'react';
/* COMPONENTS */
import ToggleSection from '../../../../../misc/ToggleSection';
/* TYPES */
import { IGenericEditProps } from './GenericEditProps';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export default (props: IGenericEditProps) => {
  const [userOpened, setUserOpened] = useState(false);
  const hasDataAttributes = hasDefinitionDataAttributes(
    props.definition,
    props.parentDefinition.id,
    getElmDef(props.selectedUnit?.definitionId)
  );
  return (
    <div className="toggle-section-outer selected-unit-props-container subaction-edit">
      <ToggleSection
        title={props.definition?.displayName + ' Properties'}
        id={'unitProps_' + props.index}
        defaultOpen={false}
        forceClose={!props.isLeaf}
        forceOpen={props.isLeaf || userOpened}
        onToggled={(isOpened) => {
          setUserOpened(isOpened);
        }}
        enabled={hasDataAttributes}
      >
        <div className="selected-unit-props-section p-0">
          <DataAttributesPropsWrapper {...props} />
        </div>
      </ToggleSection>
    </div>
  );
};
