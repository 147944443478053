import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { UploadType } from '../ImportDocument';
import ModalDropzone from '../../general/ModalDropzone';

export type Props = {
  maxFiles?: number;
  projectUid?: string | null;
  workspaceUid?: string | null;
  uploadType: UploadType;
  blacklistTypes?: string[];
  attachmentTypes?: string[];
  maxFileAttachmentSize?: number | undefined;
  open: boolean;
  isBusy: boolean;
  onAerodocsImport?: (files: File[] | null) => void;
  onExternalImport?: (files: File[] | null, comment: string) => void;
  onCancel: () => void;
  importError?: string | null;
  onSuccess: (files: File[]) => void;
};

export type State = {
  errorMsg: string | null;
  fileName: string | null;
  fileType: string | null;
  files: File[];
  comment: string;
};

/**
 *  A modal dialogue box that allows users to upload external files to the server.
 *  Each file that is sent over can optionally have comment associated with it.
 */
export default class FileImporter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMsg: null,
      fileName: null,
      fileType: null,
      files: [],
      comment: ''
    };
  }

  static defaultProps: Partial<Props> = {
    workspaceUid: null,
    projectUid: null,
    open: false,
    attachmentTypes: []
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.open !== nextProps.open && nextProps.open) {
      this.setState({
        errorMsg: null,
        fileName: null,
        fileType: null,
        files: [],
        comment: ''
      });
    }

    if (nextProps.importError) {
      this.setState({
        errorMsg: nextProps.importError
      });
    }
  }

  _onDropFiles(files: File[]) {
    const sourceFiles = files.concat(this.state.files);
    const maxAttachmentSize = this.props.maxFileAttachmentSize;
    const blacklistTypes = this.props.blacklistTypes;

    if (files.length === 0) {
      return;
    }

    let allowedFiles: File[] = [];
    const maxSizeFiles: string[] = [];

    const maxSizeLimitReached = files.some((file) => {
      const fileSizeMB = file.size / 1048576;
      if (maxAttachmentSize && fileSizeMB > maxAttachmentSize) {
        maxSizeFiles.push(file.name);
        return true;
      }
      return false;
    });

    if (blacklistTypes) {
      const blacklistedFiles: string[] = [];

      const blacklistedFileMatch: boolean = files.some((file) => {
        if (blacklistTypes.indexOf(file.type) >= 0) {
          blacklistedFiles.push(file.name);
          return true;
        }
        return false;
      });

      // Multiple files with one or more being blacklisted
      if (blacklistedFileMatch) {
        allowedFiles = sourceFiles.filter((file) => blacklistedFiles.indexOf(file.name) === -1);
      } else {
        allowedFiles = sourceFiles;
      }
    } else {
      allowedFiles = sourceFiles;
    }

    if (allowedFiles.length === 0) {
      this.setState({
        files: [],
        fileName: null,
        fileType: null,
        errorMsg: 'Error: Cannot upload invalid file type.'
      });
    } else if (maxSizeLimitReached) {
      allowedFiles = sourceFiles.filter((file) => maxSizeFiles.indexOf(file.name) === -1);
      this.setState({
        files: allowedFiles,
        errorMsg: `Error: One or more files failed to upload due to ${maxAttachmentSize}MB file limit.`,
        fileName: files[0].name,
        fileType: files[0].type
      });
    } else {
      this.setState({
        files: allowedFiles,
        errorMsg: allowedFiles.length !== files.length ? 'Error: One or more files failed to upload due to invalid file type.' : null,
        fileName: files[0].name,
        fileType: files[0].type
      });
    }
  }

  _onUpload() {
    if (this.props.uploadType === 'aerodocs') {
      if (this.props.onAerodocsImport) {
        this.props.onAerodocsImport(this.state.files);
      }
    }

    if (this.props.uploadType === 'external' || this.props.uploadType === 'external-revision') {
      if (this.props.onExternalImport) {
        this.props.onExternalImport(this.state.files, this.state.comment);
      }
    }

    if (this.props.uploadType === 'attachment') {
      if (this.props.onSuccess) {
        this.props.onSuccess(this.state.files);
      }
    }
  }

  getActionButtons() {
    const noFiles = this.state.files.length < 1;
    const cancelButton =
      this.props.uploadType === 'attachment' ? (
        <span className="margin-right-l">
          <FlatButton label="Cancel" onClick={() => this.props.onCancel()} />
        </span>
      ) : (
        <span className="margin-right-l">
          <FlatButton label="Cancel" disabled={this.props.isBusy} onClick={() => this.props.onCancel()} />
        </span>
      );

    if (this.props.uploadType === 'attachment') {
      return (
        <div>
          {cancelButton}
          <FlatButton
            className={`upload-button ${this.state.files.length < 1 ? 'importTaskButtonDisabled' : 'importTaskButtonActive'}`}
            hoverColor="#438741"
            disabled={noFiles}
            label="OK"
            onClick={() => this._onUpload()}
          />
        </div>
      );
    } else {
      return (
        <div>
          {cancelButton}
          <FlatButton
            className={`upload-button ${noFiles || this.props.isBusy ? 'importTaskButtonDisabled' : 'importTaskButtonActive'}`}
            disabled={noFiles || this.props.isBusy}
            backgroundColor="#59b957"
            hoverColor="#438741"
            label="Upload Document"
            onClick={() => this._onUpload()}
          />
        </div>
      );
    }
  }

  getHeaderText() {
    const uploadType = this.props.uploadType;

    if (uploadType === 'aerodocs') {
      return 'Upload Document';
    }

    if (uploadType === 'external') {
      return 'Upload External Document';
    }

    if (uploadType === 'external-revision') {
      return 'Upload New Draft';
    }

    if (uploadType === 'attachment') {
      return 'Select Files';
    }
  }

  _onDropRejectedFiles() {
    this.setState({
      errorMsg: 'Error: Cannot upload invalid file type',
      fileName: null,
      files: []
    });
  }

  private removeFile(fileIndex: number) {
    if (this.props.uploadType === 'attachment' || !this.props.isBusy) {
      this.setState({
        files: this.state.files.filter((file, i) => i !== fileIndex),
        errorMsg: null
      });
    }
  }

  render() {
    let acceptedType = this.props.attachmentTypes!;
    if (this.props.uploadType === 'aerodocs') {
      acceptedType = ['.zip'];
    } else if (this.props.uploadType === 'external' || this.props.uploadType === 'external-revision') {
      acceptedType = ['.pdf', '.zip', '.xml'];
    }

    return (
      <Dialog
        bodyStyle={{ padding: '0' }}
        contentStyle={{ width: 701 + 'px', minWidth: '250px', overflow: 'auto' }}
        className="external-upload-modal external-upload-modal-file-importer"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        open={this.props.open}
      >
        <div className="position-relative">
          <div className="dialogue-header">
            <div className="close-button right" onClick={() => this.props.onCancel()}>
              <FontIcon className="material-icons icon-close" />
            </div>
            <h5> {this.getHeaderText()} </h5>
          </div>
          <div className="inner-content">
            <ModalDropzone
              multiple={this.props.uploadType === 'attachment'}
              blacklistedFiles={this.props.blacklistTypes}
              onDropFiles={(e) => this._onDropFiles(e)}
              accept={acceptedType.join(', ')}
              onDropRejected={() => this._onDropRejectedFiles()}
              onRemove={(index) => this.removeFile(index)}
              disableClick={false}
              uploadType={this.props.uploadType}
              files={this.state.files}
              isBusy={this.props.isBusy}
              errorMsg={this.state.errorMsg}
            />

            {this.props.projectUid ? (
              <div className="comment-section">
                <h6>Add Comment: </h6>
                <textarea value={this.state.comment} onChange={(e) => this.setState({ comment: e.currentTarget.value })} />
              </div>
            ) : null}
            <div className="buttons-section">{this.getActionButtons()}</div>
          </div>
        </div>
      </Dialog>
    );
  }
}
