import * as React from 'react';
import { IVariant } from 'mm-types';
import RadioButton, { RadioButtonGroup } from 'material-ui/RadioButton';
import DialogSection from '../../dialogs/DialogSection';
import { getValidVariantUid } from './exportDialogUtils';

interface Props {
  variants: IVariant[];
  togglable?: boolean;
  selected: string | null;
  onSelectVariant(variantUid: string);
}

export function VariantsSection(props: Props) {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  React.useEffect(() => {
    onSelectedValue(props.selected || '');
    setDisabled(!!getValidVariantUid(props.selected));
  }, [props.selected]);

  const onSelectedValue = (value: string) => {
    props.onSelectVariant(value);
    setSelectedValue(value);
  };

  const getRadioButtons = () => {
    return props.variants.map((v, index) => {
      const key = 'variant-' + index;
      return <RadioButton key={key} className={'variant ' + key} value={v.uid} label={v.name} disabled={disabled} />;
    });
  };

  return (
    <DialogSection label="Variants" id="variants" togglable={false}>
      <div className="export-row" id="variants-section">
        <RadioButtonGroup
          className="variants-list"
          onChange={(e, value) => {
            onSelectedValue(value);
          }}
          name="exportRange"
          valueSelected={selectedValue}
        >
          {getRadioButtons()}
        </RadioButtonGroup>
      </div>
    </DialogSection>
  );
}
