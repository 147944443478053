import * as React from 'react';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import ValidatableTextInput from '../../../../../general/ValidatableTextInput';
import { DataAttributePropsType } from '../DataAttributePropsType';
import { IDataAttribute } from 'mm-types';

type PropsType = DataAttributePropsType & {
  onValidation: (hasError: boolean, dataAttribute: IDataAttribute) => void;
  type: string;
};

export const TextInputAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, isRequired, handleAction, onValidation } = props;

  return (
    <div data-qa="data-attribute">
      <div data-qa="data-attribute-string">
        <ValidatableTextInput
          disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
          errorStyle={{ bottom: '2px' }}
          nullable={!isRequired(dataAttribute)}
          value={dataAttributesValues[dataAttribute.id]}
          floatingLabelText={dataAttribute.displayName}
          regex={!!dataAttribute.validationPattern ? new RegExp(dataAttribute.validationPattern) : /.*/}
          errorMessage={dataAttribute.validationMessage}
          onChange={(e, text, isValid) => {
            handleAction(dataAttribute, text, isValid);
          }}
          onValidation={(hasError) => {
            onValidation && onValidation(hasError, dataAttribute);
          }}
        />
      </div>
    </div>
  );
};
