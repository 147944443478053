import { IConcept } from 'mm-types';
import React from 'react';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import { Divider, Menu, MenuItem, SubMenu } from '../../../EditorMenu';

type Props = {
  handleDropdownVariantClick(e: { key: string }): void;
  syncOpenKeysStructure(openKeys: string[]): void;
  openKeysStructure: string[];
  isIndexVariantFamilySet(): boolean;
  getIndexVariants(): IConcept[];
};
const VariantMenuElements = (props: Props) => {
  const project = ProjectStore.getProject();
  const currentVariantUid = ProjectStore.getSelectedVariantUid();

  if (project) {
    return (
      <Menu
        onClick={(e) => props.handleDropdownVariantClick(e)}
        onOpenChange={(e) => props.syncOpenKeysStructure(e)}
        openKeys={props.openKeysStructure}
        tabIndex="0"
        title="Variant"
        iconBeforeText="icon-effectivity"
        disabled={!props.isIndexVariantFamilySet()}
      >
        <SubMenu key="variantsubmenu" value="variantsubmenu" className="doc-variant">
          <MenuItem key="showAll" value="showAll" className={currentVariantUid ? '' : 'highlight-menu-item-with-color'}>
            Show All
          </MenuItem>
          <Divider />
          {props.isIndexVariantFamilySet()
            ? props.getIndexVariants().map((variant) => {
                return (
                  <MenuItem
                    key={variant.uid!}
                    value={variant.uid!}
                    className={variant.uid === currentVariantUid ? 'highlight-menu-item-with-color' : ''}
                  >
                    {variant.name}
                  </MenuItem>
                );
              })
            : null}
        </SubMenu>
      </Menu>
    );
  }

  return null;
};

export default VariantMenuElements;
