import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import * as ReactDOM from 'react-dom';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import EditorModes, { EditorModes as EditorModesTypes } from '../../../../../flux/editor/EditorModes';
import SmartContentStore, { SmartContentStoreEvent } from '../../../../../flux/editor/SmartContentStore';
import UnitVariantStore from '../../../../../flux/editor/UnitConceptStore';
import TagStore, { State as TagStoreState } from '../../../../../flux/editor/TagStore';
import { default as PopupMenu } from '../../../../misc/PopupMenu';
import SharedContentListTypes, { SharedContentListViewTypes } from './SharedContentListTypes';
import { CircularProgress } from 'material-ui';
import RaisedButtonWithTooltip from '../../../../general/RaisedButtonWithTooltip';
import SmartContentList from './SmartContentList';
import {
  EventStoreEventType,
  IEditorStoreEvent,
  IProject,
  IRegulation,
  ISharedIndexOrigin,
  ISharedIndexUsages,
  ISpecialInterestGroup,
  ITag
} from 'mm-types';
import { DefaultSharedIndex } from '../../../../../clients/shared-content';
import SmartContentLibraryStore from '../../../../../flux/editor/SmartContentLibraryStore';
import ContentTypes from '../../../sharedcontentlib/SharedContentLibTypes';
import RevisionStore, { RevisionStoreEvent } from '../../../../../flux/editor/RevisionStore';
import useListenToStore from '../../../../hooks/useListenToStore';
import ListSwitchMenu from './ListSwitchMenu';
import useForceUpdate from '../../../../hooks/useForceUpdate';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

const NEW_SHARE_UID = 'newShare';
const NEW_REG_UID = 'newReg';

// persists last accessed list across unmounts
const persistForIndex: {
  projectUid: null | string;
  indexUid: null | string;
  lastListViewingSelected: null | SharedContentListViewTypes;
} = {
  projectUid: null,
  indexUid: null,
  lastListViewingSelected: null
};

export type State = {
  listViewing: SharedContentListViewTypes;
  lockList?: boolean;
  smartContents: (IRegulation | ISpecialInterestGroup | ISharedIndexOrigin | ISharedIndexUsages)[];
  showExistingRegNotInList: boolean;
  selectedUid: null | string;
  contentTags: null | ITag[];
  showDeleteConfirm: boolean;
  isReadOnly: boolean;
  loading: boolean;
  openedUid: string | null;
  sharedContentCount?: number;
  newRegulation?: IRegulation;
};

const SharedContent = () => {
  const [state, setState] = useState<State>({
    listViewing: 'SHAREDCONTENT_ALL',
    smartContents: [],
    openedUid: null,
    showExistingRegNotInList: false,
    selectedUid: null,
    contentTags: null,
    showDeleteConfirm: false,
    isReadOnly: isReadOnly()!,
    loading: false
  });

  const forceUpdate = useForceUpdate();

  useListenToStore({
    store: EditorStore,
    eventListener: onEditStoreUpdate,
    update: [state.openedUid, state.smartContents, state.listViewing, state.selectedUid, state.isReadOnly, state.loading, state.lockList]
  });
  useListenToStore({ store: RevisionStore, eventListener: onRevisionStoreUpdate });
  useListenToStore({ store: SmartContentStore, eventListener: onSmartContentStoreUpdate, update: [state.openedUid, state.smartContents] });

  const smartContentListRef = useRef<HTMLDivElement | null>(null);
  const displayOptionsMenuRef = useRef<PopupMenu | null>(null);
  const displayOptionsBtnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, listViewing: getDefaultListViewing(), loading: true }));
    retrieveSmartContent();
  }, []);

  function isReadOnly(): boolean {
    const project = ProjectStore.getProject();
    return (
      (EditorStore.isReadOnly() ||
        (project &&
          (project.interimIndexUid === EditorStore.getDocParams().indexUid || !project.currentUserPermissions?.canManageShares))) ??
      false
    );
  }

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;

      // after going into edit an existing share mode: this selects the appropriate unit in the editor
      if (EditorStore.isMode('SHARE_EDIT')) {
        const shareParams = EditorModes.getProperties(EditorStore.getMode()).getParams();
        if (!shareParams.isNew) {
          setTimeout(() => {
            EditorStore.openDocumentLink(shareParams.initUnitUid);
          }, 100); // allow changemode to settle as other listeners have stuff to do (super-fast non api stuff, but still)
        }
      }

      const from: EditorModesTypes | undefined = castEvent.data?.from;
      const to: EditorModesTypes | undefined = castEvent.data?.to;

      if (from === 'SHARE_EDIT') {
        setState((prevState) => ({ ...prevState, openedUid: null }));
      } else if (from === 'REGULATIONSELECTION') {
        setState((prevState) => ({ ...prevState, lockList: false, openedUid: null, selectedUid: null, showExistingRegNotInList: false }));
      } else if (to === 'REGULATIONSELECTION') {
        setState((prevState) => ({ ...prevState, openedUid: null, selectedUid: null, showExistingRegNotInList: false }));
        handleListViewSwitch('SHAREDCONTENT_REGULATIONS', true);
      } else {
        forceUpdate();
      }
    } else if (e.type === 'unitsSelected') {
      if (!isTagList()) {
        const castEvent = e as IEditorStoreEvent<'unitsSelected'>;

        // editor unit selected: select share
        const selectedShareDetails = castEvent.data?.initialSelectedUnit ? castEvent.data?.initialSelectedUnit?.shareDetails : null;

        if (selectedShareDetails && state.smartContents.find((s) => s.uid === selectedShareDetails?.uid)) {
          if (state.selectedUid !== selectedShareDetails.uid) {
            setState((prevState) => ({ ...prevState, selectedUid: selectedShareDetails.uid }));
            scrollToItem(selectedShareDetails.uid);
          }
        } else {
          // force update to determine if editing/creation is allowed on selected units (i.e. enable/disable create)
          if (!EditorStore.isMode('SHARE_EDIT')) {
            forceUpdate();
          }
        }
      }
    } else if (e.type === 'shareSelection') {
      if (!isTagList()) {
        let shareUid: string | null = null;
        const selectedShareDetails = e.unit?.shareDetails;

        if (selectedShareDetails) {
          shareUid = selectedShareDetails.origin ? selectedShareDetails.uid : selectedShareDetails.uid + '_' + e.unit?.uid;
        }

        if (shareUid && state.smartContents.find((s) => s.uid === shareUid)) {
          if (state.selectedUid !== shareUid) {
            setState((prevState) => ({ ...prevState, selectedUid: shareUid }));
            scrollToItem(shareUid);
          }
        }
      }
    } else if (e.type === 'editorStoresInitialized') {
      setState((prevState) => ({
        ...prevState,
        isReadOnly: isReadOnly(),
        lockList: false,
        openedUid: null,
        selectedUid: null,
        showExistingRegNotInList: false,
        showDeleteConfirm: false
      }));
      handleListViewSwitch(getDefaultListViewing(), false, true);
    }

    // why? because usages can be undone/redone in editor :)
    // todo this process inefficient as results in unneccesary api calls.. tweak when time
    else if (e.type === 'documentChanged') {
      retrieveSmartContent();
    } else if (e.type === 'selectShareOrigin') {
      if (state.openedUid !== NEW_SHARE_UID && !isTagList()) {
        // if editing new item no need to update list on selection as nothing in store changed
        retrieveSmartContent();
      }
    }
  }

  function onRevisionStoreUpdate(event: RevisionStoreEvent) {
    if (event.revisions) {
      setState((prevState) => ({ ...prevState, isReadOnly: isReadOnly()! }));
    }
  }

  function onSmartContentStoreUpdate(event: SmartContentStoreEvent) {
    if (
      event.type === 'sharedUsageUpdateChange' ||
      event.type === 'retrieveSharedContent' ||
      event.type === 'retrieveRegulations' ||
      event.type === 'retrieveUnitSpecialInterestTagMap'
    ) {
      let closeCurrentShareEditing = false;

      if (
        EditorStore.isMode('SHARE_EDIT') && // only for share content
        state.openedUid !== NEW_SHARE_UID
      ) {
        // if share open is no longer in store, close it!
        if (
          !event.state!.smartContents.find((s) => {
            return (
              state.openedUid ===
              ((s as ISharedIndexUsages).origin
                ? (s as ISharedIndexUsages).sharedIndex.uid
                : (s as ISharedIndexOrigin).sharedIndex.uid + '_' + (s as ISharedIndexUsages).units[0].uid)
            );
          })
        ) {
          closeCurrentShareEditing = true;
        }
      }

      // refresh from store
      setState((prevState) => ({
        ...prevState,
        loading: false,
        smartContents: event.state!.smartContents,
        openedUid: closeCurrentShareEditing ? null : state.openedUid,
        showExistingRegNotInList: false,
        sharedContentCount: event.state!.sharedContentCount
      }));
      if (closeCurrentShareEditing) {
        EditorStore.changeModeStart('EDITING'); // cancel edit share mode (won't affect reg mode)
      }
      if (event.type === 'retrieveRegulations') {
        SmartContentLibraryStore.onContentTypeChange(ContentTypes.props.TYPE_REGULATIONS.key);
      } else if (event.type === 'retrieveSharedContent') {
        SmartContentLibraryStore.onContentTypeChange(ContentTypes.props.TYPE_SHARED.key);
      }
    } else if (event.type === 'retrieveDefaultRegModel') {
      retrieveDefaultRegModel(event);
    }
  }

  const handleListViewSwitch = (type: SharedContentListViewTypes, lockList = false, force = false) => {
    if (state.listViewing !== type || force) {
      persistForIndex.projectUid = EditorStore.getDocParams().projectUid!;
      persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
      persistForIndex.lastListViewingSelected = type;

      setState((prevState) => ({ ...prevState, listViewing: type, loading: true, lockList: lockList }));
      retrieveSmartContent();
    } else {
      setState((prevState) => ({ ...prevState, lockList: lockList }));
    }
  };

  const retrieveSmartContent = () => {
    SmartContentStore.retrieveSmartContent(EditorStore.getDocParams(), { type: getDefaultListViewing() });
  };

  function getDefaultListViewing() {
    const projectUid = EditorStore.getDocParams().projectUid ?? null;
    const indexUid = EditorStore.getDocParams().indexUid ?? null;
    const project = ProjectStore.getProject();
    const isDraft = project && project.masterIndexUid === EditorStore.getDocParams().indexUid;
    let selectedList: SharedContentListViewTypes | null = null;

    if (persistForIndex.indexUid === indexUid) {
      selectedList = persistForIndex.lastListViewingSelected;
    } else {
      persistForIndex.projectUid = projectUid;
      persistForIndex.indexUid = indexUid;
      persistForIndex.lastListViewingSelected = null;

      if (state.isReadOnly && !isDraft) {
        persistForIndex.lastListViewingSelected = selectedList = 'SHAREDCONTENT_TARGET';
      }
    }

    return selectedList === null ? 'SHAREDCONTENT_ALL' : selectedList;
  }
  const isTagList = () => {
    return isRegulationsList() || isSpecialInterestList();
  };
  const isRegulationsList = () => {
    return state.listViewing === 'SHAREDCONTENT_REGULATIONS';
  };

  const isSpecialInterestList = () => {
    return state.listViewing === 'SHAREDCONTENT_SPECIALINTEREST';
  };
  const scrollToItem = (shareUid) => {
    const listEl = (ReactDOM.findDOMNode(smartContentListRef.current) as Element).querySelectorAll('ul')[0];

    if (listEl) {
      const shareItemEl = listEl.querySelectorAll('li#itemuid_' + shareUid)[0];

      if (shareItemEl) {
        const $shareItemEl = $(shareItemEl);
        const $listEl = $(listEl);
        $listEl.animate(
          {
            scrollTop: $shareItemEl.offset()!.top - $listEl.offset()!.top + $listEl.scrollTop()!
          },
          300
        );
      }
    }
  };

  const retrieveDefaultRegModel = (event: SmartContentStoreEvent) => {
    // already in reg mode, and default reg info has been requested

    // when "opening" reg from document unit selection, reg either:
    // exists already (so open it for update)
    // exists but is not in doc list (i.e. nothing in doc tagged with it yet) (so insert into list and open for update)
    // brand new (open a new item in list)

    const defaultRegulation = event.regulationDraft!;
    const regInList = (state.smartContents as IRegulation[]).find((elem) => elem.uid === defaultRegulation.uid);
    let showExistingRegNotInList = false;
    const openRegUid = defaultRegulation.uid ? defaultRegulation.uid : NEW_REG_UID;

    if (defaultRegulation.uid) {
      // if reg exists
      if (!regInList) {
        // exists but not in our list, use defaultRegulation as reg to render
        showExistingRegNotInList = true;
      } else {
        // exists in list, update with its originals
        regInList.original = _.cloneDeep(defaultRegulation.original);
      }
    }

    const setOpenRegState = () => {
      setState((prevState) => ({
        ...prevState,
        openedUid: openRegUid,
        selectedUid: openRegUid,
        showExistingRegNotInList: showExistingRegNotInList,
        newRegulation: defaultRegulation
      }));
      if (state.selectedUid !== NEW_REG_UID) {
        scrollToItem(state.selectedUid);
      } else {
        scrollListToTop();
      }
    };

    SmartContentStore.retrieveRegProject(defaultRegulation.projectUid)
      .then((project: IProject) => {
        defaultRegulation.isProjectAuthor = project.currentUserPermissions?.canAuthor;
        if (regInList) {
          regInList.isProjectAuthor = project.currentUserPermissions?.canAuthor;
        }
        setOpenRegState();
      })
      .catch(() => {
        defaultRegulation.isProjectAuthor = false;
        if (regInList) {
          regInList.isProjectAuthor = false;
        }
        setOpenRegState();
      });
  };

  const scrollListToTop = () => {
    if (smartContentListRef.current) {
      const el = (ReactDOM.findDOMNode(smartContentListRef.current) as Element).querySelectorAll('ul')[0];
      if (el) {
        el.scrollTop = 0;
      }
    }
  };

  const blurEditor = () => {
    EditorStore.getEditor().blur();
  };

  const allowOpenSmartLib = () => {
    return !(isReadOnly() && isTagList()) && !(EditorStore.isMode('SMARTCONTENTLIB') || EditorStore.isMode('REGULATIONSELECTION'));
  };

  const openSmartLib = () => {
    EditorStore.toggleMode(isTagList() ? 'REGULATIONSELECTION' : 'SMARTCONTENTLIB');
  };
  const allowCreateShare = () => {
    const isInEditShareMode = EditorStore.isMode('SHARE_EDIT');

    if (!isInEditShareMode) {
      const selected = EditorStore.getSelectedUnits();
      let hasVariants = false;

      for (let i = 0, l = selected.length; i < l; i++) {
        if (UnitVariantStore.getUnitHasVariants(selected[i].uid)) {
          hasVariants = true;
          break;
        }
      }

      if (hasVariants) {
        return false;
      }

      const invalidCount = selected.filter((u) => {
        const DUP = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(u.definitionId);
        return u.shareDetails || (DUP?.inlineOptions && DUP?.inlineOptions.readonly);
      }).length;
      const project = ProjectStore.getProject();
      return (
        !isTagList() && selected.length > 0 && invalidCount === 0 && !state.isReadOnly && project && project.workspaceType !== 'personal'
      );
    }
  };
  const createSharedContent = () => {
    TagStore.retrieveContentTags().then((state: TagStoreState) => {
      setState((prevState) => ({ ...prevState, contentTags: state.contentTags, openedUid: NEW_SHARE_UID, selectedUid: NEW_SHARE_UID }));
      EditorStore.reorderSelectedByIndex();

      const selectedUnits = EditorStore.getContiguousSelectedUnits();

      if (selectedUnits.length) {
        EditorStore.toggleMode('SHARE_EDIT', {
          activateParams: {
            initUnits: selectedUnits
          }
        });
      }

      scrollListToTop();
    });
  };
  const getListViewingTitle = () => {
    return SharedContentListTypes.props[state.listViewing].title;
  };

  const toggleSharedContentMenu = () => {
    if (displayOptionsMenuRef && displayOptionsBtnRef) {
      (displayOptionsMenuRef.current as PopupMenu).setTriggerButton(displayOptionsBtnRef.current as HTMLDivElement).open();
    }
  };

  const handleDropDownAction = (action: string, smartContent: Partial<ISharedIndexOrigin | ISharedIndexUsages | IRegulation>) => {
    if (action === 'edit') {
      if (isRegulationsList()) {
        const regInList = (state.smartContents as IRegulation[]).find((elem) => elem.uid === (smartContent as IRegulation).uid)!;

        SmartContentStore.retrieveRegProject((smartContent as IRegulation).projectUid)
          .then((project: IProject) => {
            SmartContentStore.retrieveDefaultRegModel({
              projectUid: (smartContent as IRegulation).projectUid,
              indexUid: project.lastPublishedIndexUid,
              unitUid: (smartContent as IRegulation).unitUid,
              silent: true
            }).then((defaultReg: IRegulation) => {
              regInList.original = _.cloneDeep(defaultReg.original);
              regInList.isProjectAuthor = project.currentUserPermissions?.canAuthor;

              setState((prevState) => ({
                ...prevState,
                openedUid: (smartContent as IRegulation).uid,
                selectedUid: (smartContent as IRegulation).uid
              }));
            });
          })
          .catch(() => {
            regInList.isProjectAuthor = false;

            setState((prevState) => ({
              ...prevState,
              openedUid: (smartContent as IRegulation).uid,
              selectedUid: (smartContent as IRegulation).uid
            }));
          });
      } else {
        TagStore.retrieveContentTags().then((state: TagStoreState) => {
          setState((prevState) => ({
            ...prevState,
            contentTags: state.contentTags,
            openedUid: (smartContent as ISharedIndexOrigin | ISharedIndexUsages).uid,
            selectedUid: (smartContent as ISharedIndexOrigin | ISharedIndexUsages).uid
          }));
          // editing origin or usage: change mode to: SHARE_EDIT
          if ((smartContent as ISharedIndexOrigin | ISharedIndexUsages).origin) {
            EditorStore.toggleMode('SHARE_EDIT', {
              activateParams: {
                initUnitUid: (smartContent as ISharedIndexOrigin | ISharedIndexUsages).sharedIndex!.startUnitUid!,
                shareUid: (smartContent as ISharedIndexOrigin | ISharedIndexUsages).uid
              }
            });
          } else {
            EditorStore.changeModeStart('SHARE_EDIT');
            EditorStore.openDocumentLink((smartContent as ISharedIndexUsages).units[0].uid);
          }
        });
      }
    } else if (action === 'remove') {
      if (!isRegulationsList()) {
        // del may not be in SHARE_EDIT: its needs to be: so force it:
        EditorStore.changeModeStart('SHARE_EDIT');
      }

      setState((prevState) => ({ ...prevState, openedUid: (smartContent as IRegulation).uid, showDeleteConfirm: true }));
    } else if (action === 'openInSCL') {
      // shares only

      const selectItemUid: string = (smartContent as ISharedIndexOrigin | ISharedIndexUsages).origin
        ? (smartContent as ISharedIndexOrigin | ISharedIndexUsages).uid
        : (smartContent as ISharedIndexOrigin | ISharedIndexUsages).sharedIndex.uid!;
      if (EditorStore.isMode('SMARTCONTENTLIB')) {
        SmartContentLibraryStore.selectSmartContentLibItem(selectItemUid);
      } else {
        EditorStore.changeModeStart('SMARTCONTENTLIB', {
          activateParams: { selectItemUid: selectItemUid }
        });
      }
    } else if (action === 'tagSelectedUnits') {
      SmartContentStore.complianceTagUnits(
        (smartContent as ISharedIndexOrigin | ISharedIndexUsages).uid,
        EditorStore.getSelectedUnits(),
        EditorStore.getDocParams()!.indexUid!
      );
    }
  };

  const handleFormAction = async (
    action: 'save' | 'cancel',
    smartContent: Partial<IRegulation | ISharedIndexOrigin | ISharedIndexUsages>
  ) => {
    if (action === 'save') {
      if (isRegulationsList()) {
        if (_.isUndefined((smartContent as IRegulation).uid)) {
          const result = await SmartContentStore.addRegulation(
            smartContent as IRegulation,
            EditorStore.getSelectedUnits(),
            EditorStore.getDocParams()
          );
          if (result) {
            onSmartContentOpCompleted(result.regulation.uid);
          }
        } else {
          SmartContentStore.updateRegulation(smartContent as IRegulation, EditorStore.getSelectedUnits(), EditorStore.getDocParams()).then(
            () => {
              onSmartContentOpCompleted((smartContent as IRegulation).uid);
            }
          );
        }
      } else {
        if ((smartContent as DefaultSharedIndex).isNew) {
          const result = await SmartContentStore.addSharedContent(smartContent as DefaultSharedIndex, EditorStore.getDocParams());
          if (result) {
            onSmartContentOpCompleted(result.sharedContent.uid);
          }
        } else {
          SmartContentStore.updateSharedContent(smartContent as ISharedIndexUsages, EditorStore.getDocParams()).then(() => {
            onSmartContentOpCompleted();
          });
        }
      }
    } else if (action === 'cancel') {
      setState((prevState) => ({ ...prevState, openedUid: null, selectedUid: null, showExistingRegNotInList: false }));
      if (!EditorStore.isMode('REGULATIONSELECTION')) {
        EditorStore.changeModeStart('EDITING');
      }
    }
  };

  const handleOverlayAction = (e, action) => {
    if (action === 'cancel') {
      setState((prevState) => ({ ...prevState, openedUid: null, showDeleteConfirm: false }));
      if (!isRegulationsList()) {
        EditorStore.changeModeStart('EDITING');
      }
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else if (action === 'delete') {
      if (isRegulationsList()) {
        let previousRegulation = state.smartContents.filter((s) => s.uid === state.openedUid)[0] as IRegulation;
        previousRegulation = _.cloneDeep(previousRegulation);
        setState((prevState) => ({ ...prevState, openedUid: null, selectedUid: null, showDeleteConfirm: false }));
        SmartContentStore.removeRegulation(previousRegulation, EditorStore.getDocParams());
      } else {
        // shared content

        let previousSharedContent = state.smartContents.filter((s) => s.uid === state.openedUid)[0];
        previousSharedContent = _.cloneDeep(previousSharedContent);
        setState((prevState) => ({ ...prevState, openedUid: null, selectedUid: null, showDeleteConfirm: false }));
        SmartContentStore.removeSharedContent(previousSharedContent as ISharedIndexOrigin | ISharedIndexUsages, EditorStore.getDocParams());
      }
      return false;
    }
  };

  const onSmartContentOpCompleted = (selectedUid = state.selectedUid) => {
    setState((prevState) => ({ ...prevState, openedUid: null, selectedUid: selectedUid, showExistingRegNotInList: false }));
    if (!isTagList()) {
      EditorStore.changeModeStart('EDITING');
    } else {
      if (state.selectedUid) {
        scrollToItem(state.selectedUid);
      }
    }
  };

  const handleSmartContentSelect = (shareUid: string) => {
    setState((prevState) => ({ ...prevState, selectedUid: shareUid }));
  };

  const getListEmptyDescription = () => {
    return SharedContentListTypes.props[state.listViewing].emptyDescription;
  };
  return (
    <div
      className="smart-content-subaction subaction-list-container"
      onClick={() => {
        blurEditor();
      }}
    >
      <div>
        <h5>Smart Content</h5>
        <div className="smart-content-actions">
          <div className="smart-content-actions-inner">
            <RaisedButtonWithTooltip
              iconClass="library_add"
              tooltipEnabled={allowOpenSmartLib()}
              tooltipText="Smart Content Library"
              className="rhs-icon-button"
              secondary={true}
              disabled={!allowOpenSmartLib() || EditorStore.isShareDiffActive()}
              onClick={() => {
                openSmartLib();
              }}
            />
            <RaisedButtonWithTooltip
              iconClass="add"
              dataQa="create-smart-content-button"
              tooltipEnabled={allowCreateShare()}
              tooltipText="Create Smart Content"
              className="rhs-icon-button"
              secondary={true}
              disabled={!allowCreateShare() || EditorStore.isShareDiffActive()}
              onClick={() => {
                createSharedContent();
              }}
            />
          </div>
        </div>
      </div>

      {!state.loading ? (
        <h6>
          <div>View: {getListViewingTitle()}</div>
          {!state.lockList ? (
            <div className="icon-display-options tiny material-icons" onClick={() => toggleSharedContentMenu()}>
              arrow_drop_down
            </div>
          ) : undefined}
          {!state.lockList ? (
            <div className="icon-display-options tiny material-icons" ref={displayOptionsBtnRef} onClick={() => toggleSharedContentMenu()}>
              filter_list
            </div>
          ) : undefined}
          {!isTagList() ? (
            <div>
              <div>Share Origins</div>
              <div className="popup-counts">{state.sharedContentCount}</div>
            </div>
          ) : undefined}
        </h6>
      ) : undefined}

      <ListSwitchMenu
        isReadOnly={state.isReadOnly}
        listViewing={state.listViewing}
        handleListViewSwitch={handleListViewSwitch.bind(this)}
        ref={displayOptionsMenuRef}
      />

      {state.listViewing ? (
        <SmartContentList
          loading={state.loading}
          isReadOnly={state.isReadOnly}
          smartContents={state.smartContents}
          openedUid={state.openedUid}
          showExistingRegNotInList={state.showExistingRegNotInList}
          selectedUid={state.selectedUid}
          showDeleteConfirm={state.showDeleteConfirm}
          contentTags={state.contentTags!}
          newRegulation={state.newRegulation!}
          listType={state.listViewing}
          onDropDownAction={(action, smartContent) => handleDropDownAction(action, smartContent)}
          onFormAction={(action, smartContent) => handleFormAction(action, smartContent)}
          onSelect={(shareId) => handleSmartContentSelect(shareId)}
          onOverlayAction={(e, action) => handleOverlayAction(e, action)}
          ref={smartContentListRef}
        />
      ) : undefined}

      {!state.loading && state.smartContents && state.smartContents.length === 0 ? (
        <div className="narrow-panel-empty-list panel-sizing">
          <img src={'/assets/images/activity_panel_info.svg'} />
          <span className="empty-title">No Smart Content</span>
          <span className="empty-details">{getListEmptyDescription()}</span>
        </div>
      ) : undefined}

      {state.loading ? (
        <div className="loading">
          <CircularProgress mode="indeterminate" size={17.85} />
        </div>
      ) : undefined}
    </div>
  );
};

export default SharedContent;
