import LinearProgress from 'material-ui/LinearProgress';
import React from 'react';
import { INotificationListItemProps } from './INotificationListItemProps';

const WorkflowReview = ({ notif, getName, getVariantName, handleNotifAction }: INotificationListItemProps) => {
  return (
    <div className="notif-item-container notif-item-workflow-review">
      <div className="left-side">
        <i className="material-icons icon-workflow" />
      </div>

      <div className="middle-side">
        {!notif.processingStatus && (
          <div className="popup-title-container">
            <h1>{notif.isError ? 'Workflow Review Error' : 'Workflow Reviewed'}</h1>

            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {notif.processingStatus && (
          <div className="popup-title-container">
            <h1>Workflow Review in Progress</h1>

            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            {notif.isReady && (
              <span className="info">
                Workflow review {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} successful
              </span>
            )}

            {notif.isError && (
              <span className="info">
                Workflow review {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} failed
              </span>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            <span className="info">{notif.processingStatus.friendlyName}</span>
          </div>
        )}
      </div>

      <div className="right-side">
        <div className="popup-date-container">
          <h5>{notif.receivedFormatAgo}</h5>
        </div>

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            <span
              className={'notif-status-toggle'}
              title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
              onClick={() => {
                if (handleNotifAction) {
                  handleNotifAction(notif, 'toggleReadUnread');
                }
              }}
            />
            <i
              className="material-icons"
              title="Link to document"
              onClick={() => {
                if (handleNotifAction) {
                  handleNotifAction(notif, 'link');
                }
              }}
            >
              link
            </i>
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            {notif.status === 'IN_PROGRESS' && <LinearProgress mode="determinate" max={6} min={0} value={notif.processingStatus.step} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkflowReview;
