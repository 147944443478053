import * as React from 'react';
import * as _ from 'lodash';
import ComplianceTagsDropdown, { ComplianceTag } from '../../../../components/misc/ComplianceTagsDropdown';
import EditorStore from '../../../../flux/editor/EditorStore';
import SmartContentStore from '../../../../flux/editor/SmartContentStore';
import alphaNumSort from '../../../../utils/alphaNumSort';
import { IUnit } from 'mm-types';
import { SvgDropdownIcon } from '../../../misc/icons/SvgDropdownIcon';

type Props = {
  unit: IUnit;
};

const parseUnitTagMapping = (unit: IUnit): ComplianceTag[] => {
  const complianceTags = SmartContentStore.getInitialState().regulations;
  const mappedTagsUids = SmartContentStore.getUnitComplianceTags(unit.uid);
  const mappedTags: ComplianceTag[] = [];

  mappedTagsUids.forEach((tagUid) => {
    const mappedTag = complianceTags.find((t) => t.uid === tagUid);
    if (mappedTag) {
      mappedTags.push(_.cloneDeep(mappedTag));
    }
  });

  mappedTags.sort((a, b) => {
    return alphaNumSort(a['name'], b['name']);
  });

  return mappedTags;
};

export const DocUnitComplianceTagMenu = ({ unit }: Props) => {
  const [unitTags, setUnitTags] = React.useState<ComplianceTag[]>(parseUnitTagMapping(unit));
  const dropDownActivateBtn = React.useRef<SVGSVGElement>(null);
  const unitTagsDropdown = React.useRef<ComplianceTagsDropdown>(null);

  React.useEffect(() => {
    setUnitTags(parseUnitTagMapping(unit));
  }, [unit]);

  const openDropdown = () => {
    if (unitTagsDropdown.current && dropDownActivateBtn.current) {
      unitTagsDropdown.current.open(dropDownActivateBtn.current);
    }
  };

  const handleDropdownClose = (checkedAssignments: string[]) => {
    const previousAssignment = SmartContentStore.getUnitComplianceTags(unit.uid);
    const removedAssignment = _.difference(previousAssignment, checkedAssignments);
    const indexUid = EditorStore.getDocParams().indexUid!;

    if (removedAssignment.length > 0) {
      SmartContentStore.removeComplianceTags(removedAssignment, unit, indexUid);
    }
  };

  if (!unitTags.length) {
    return null;
  }

  return (
    <div className="du-inline-item du-inline-actions du-inline-dropdown du-inline-compliancetags" onClick={() => openDropdown()}>
      <div className="material-icons">verified_user</div>
      <SvgDropdownIcon ref={dropDownActivateBtn} />
      <ComplianceTagsDropdown
        assignedTags={unitTags}
        onClose={(assignments) => handleDropdownClose(assignments)}
        isReadOnly={EditorStore.isReadOnly()}
        ref={unitTagsDropdown}
      />
    </div>
  );
};
