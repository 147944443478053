import * as React from 'react';

import { IInvalidElement, IInvalidUnit } from 'mm-types';
import InvalidElementsList from './InvalidElementsList';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { defaultValidationError, formatGroups, getReasonTextAbbreviated } from '../../../../../utils/InvalidDTDUtils';
import ReasonBox from './ReasonBox';
import { ExpandedUnitElement } from './InvalidUnitsList';

export type Props = {
  invalidUnit: IInvalidUnit;
  expanded?: ExpandedUnitElement;
  onExpand: (unit?: IInvalidUnit, element?: IInvalidElement) => void;
};

const InvalidUnit = (props: Props) => {
  const [reason, setReason] = React.useState<string>('');

  const expandReason = (e: React.MouseEvent<HTMLButtonElement>, invalidUnit: IInvalidUnit) => {
    e.stopPropagation();
    setReason(formatGroups(invalidUnit.reason ?? defaultValidationError, props.invalidUnit.closestTocableUid));
    if (isExpanded()) {
      props.onExpand();
    } else {
      props.onExpand(props.invalidUnit);
    }
  };

  const openUnit = (e: React.MouseEvent<HTMLDivElement>, invalidUnit: IInvalidUnit) => {
    e.stopPropagation();

    EditorStore.openDocument({ uid: invalidUnit.unitUid }, undefined, undefined, invalidUnit.closestTocableUid);
  };

  const isExpanded = () => {
    return props.expanded?.unit && props.expanded?.unit?.unitUid === props.invalidUnit.unitUid;
  };

  return (
    <div className="invalid-unit" onClick={(e) => openUnit(e, props.invalidUnit)}>
      <div className="invalid-unit-heading">{props.invalidUnit.heading}</div>
      <div className="invalid-unit-closest-tocable-heading">{props.invalidUnit.closestTocableHeading}</div>
      {props.invalidUnit.reason ? (
        <div className="invalid-unit-reason">
          <span className="invalid-unit-reason-text">{getReasonTextAbbreviated(props.invalidUnit.reason)}</span>
          <button onClick={(e) => expandReason(e, props.invalidUnit)} className="invalid-unit-reason-show">
            {isExpanded() ? 'Collapse' : 'Expand'}
          </button>
          <ReasonBox isOpen={!!isExpanded()}>
            <div dangerouslySetInnerHTML={{ __html: reason }} />
          </ReasonBox>
        </div>
      ) : undefined}

      {props.invalidUnit.elements ? (
        <InvalidElementsList expanded={props.expanded} onExpand={props.onExpand} invalidUnit={props.invalidUnit} />
      ) : undefined}
    </div>
  );
};

export default InvalidUnit;
