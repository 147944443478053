import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import CircularProgress from 'material-ui/CircularProgress';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { DatePicker, FlatButton, FontIcon, MenuItem, SelectField, TimePicker } from 'material-ui';
import { IComment, IEditorStoreEvent, ISnackbarMessage, IUser } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';
import UserEventStore, { UserEventStoreEvent } from '../../../../../flux/events/UserEventStore';
import TocStore from '../../../../../flux/editor/TocStore';
import CommentStore, { CommentStatus, CommentStoreEvent, ExternalUserType } from '../../../../../flux/editor/CommentStore';
import CommentStatusStore, { CommentStatusStoreEvent } from '../../../../../flux/editor/CommentStatusStore';
import ProjectUsersStore from '../../../../../flux/editor/ProjectUsersStore';
import appStore from '../../../../../appStore';
import { hideSystemSnackbarMessage, showSystemSnackbarMessage } from '../../../../misc/SystemSnackbar/thunks';
import CommentTypes from './CommentTypes';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import { dateUtil } from '../../../../../utils';
import SystemStore from '../../../../../flux/common/SystemStore';
import UserFilterList from './UserFilterList';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import CommentForm from '../commentForm/CommentForm';
import Comment from '../comment/Comment';
import useListenToStore from '../../../../hooks/useListenToStore';
import { FilterType, CommentsState, INITIAL_COMMENT_STATE, CommentsReducer } from './reducers';

const DEFAULT_DOC_FILTER = 'tocSelected';
const DEFAULT_COMMENT_FILTER = 'ALL';

let _currentDocFilter: string;
let _currentCommentFilter: CommentStatus;
let _currentUserIdsFilter: string[];
let _currentExternalAuthFilter: ExternalUserType;
let _ignoreNextEditorSelectUpdate = false;

// persists last accessed list across unmounts
const _persistForIndex: {
  indexUid: null | string;
  docFilter: null | string;
  commentFilter: string;
  authorIdFilter: null | string[];
  authorFilter: IUser[];
  externalAuthFilter: null | string;
  commentsUnreadFilter: 'all' | 'read' | 'unread';
  filterSinceDateTime: null | number;
  date: null | Date;
  time: null | Date;
} = {
  indexUid: null,
  docFilter: null,
  commentFilter: 'ALL',
  authorIdFilter: null,
  authorFilter: [],
  commentsUnreadFilter: 'all',
  externalAuthFilter: null,
  filterSinceDateTime: null,
  date: null,
  time: null
};

export type Props = {
  onAttachmentClick: (uid: string) => void;
};

const Comments = (props: Props) => {
  const commentsListRef = React.useRef(null);
  const [state, dispatch] = React.useReducer(CommentsReducer, getInitialState());

  function getInitialState(override?: Partial<CommentsState>): CommentsState {
    return { ...INITIAL_COMMENT_STATE, ...override };
  }
  useListenToStore({ store: UserEventStore, eventListener: onUserEvent });
  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });
  useListenToStore({ store: TocStore, eventListener: onTocStoreUpdate });
  useListenToStore({ store: CommentStore, eventListener: onCommentStoreUpdate });
  useListenToStore({ store: CommentStatusStore, eventListener: onCommentStatusStoreUpdate });

  React.useEffect(() => {
    updateStates();

    _ignoreNextEditorSelectUpdate = false;

    const projectUid = EditorStore.getDocParams()!.projectUid!;
    const indexUid = EditorStore.getDocParams()!.indexUid!;

    CommentStore.getAllAuthors(projectUid, indexUid);
    ProjectUsersStore.retrieve({ projectUid }).then(() => {
      // select when opened
      retrieveComments().then((comments) => {
        const selectedUnit = EditorStore.getSelectedUnit();
        if (selectedUnit) {
          // editor may not be loaded
          selectForUnit(selectedUnit.uid);
        }
      });
    });
  }, []);

  React.useEffect(() => {
    if (!!state.selectedComment) {
      scrollToCommentIndex(state.selectedComment!.index);
    }
  }, [state.selectedComment]);

  React.useEffect(() => {}, [state.filter.time]);

  function getCurrentFilteredAuthors() {
    const authorList: IUser[] = [];
    if (_persistForIndex.authorFilter.length > 0) {
      _persistForIndex.authorFilter.forEach((author) => {
        if (_persistForIndex.authorIdFilter!.length > 0) {
          _persistForIndex.authorIdFilter!.forEach((uid) => {
            if (author.uid === uid) {
              authorList.push(author);
            }
          });
        }
      });
    }
    return authorList;
  }

  function onUserEvent(e: UserEventStoreEvent) {
    if (e.type === 'COMMENT' && state.comments?.length === 0) {
      retrieveComments();
    } else if (e.type === 'COMMENT' && e.index.uid === EditorStore.getDocParams().indexUid) {
      let message;
      switch (e.notificationReason) {
        case 'COMMENT_RESOLVED':
          message = 'resolved';
          break;
        case 'COMMENT_CREATE':
          message = 'created';
          break;
        case 'COMMENT_REPLY':
          message = 'replied';
          break;
        case 'COMMENT_DELETED':
          message = 'deleted';
          break;
        default:
          message = 'updated';
      }
      showRefreshSnackbar(message);
    }
  }

  function showRefreshSnackbar(message: string) {
    const snackbarMessage: ISnackbarMessage = {
      open: true,
      message: `Comments have been ${message}, refresh to view updated comments`,
      onRequestClose: () => {
        appStore.dispatch<any>(hideSystemSnackbarMessage());
      },
      action: 'refresh',
      onActionClick: () => retrieveComments().then(() => appStore.dispatch<any>(hideSystemSnackbarMessage()))
    };
    appStore.dispatch<any>(showSystemSnackbarMessage(snackbarMessage));
  }

  function updateStates() {
    const indexUid = EditorStore.getDocParams().indexUid!;
    let selectedDocFilter: string | null = null;
    let selectedCommentFilter: string | null = null;
    let selectedAuthorsIdFilter: string[] | null = null;
    let selectedAuthorsFilter: IUser[] | null = null;
    let selectedExternalAuthFilter: string | null = null;

    if (_persistForIndex.indexUid === indexUid) {
      selectedDocFilter = _persistForIndex.docFilter;
      selectedCommentFilter = _persistForIndex.commentFilter;
      selectedAuthorsIdFilter = _persistForIndex.authorIdFilter;
      selectedAuthorsFilter = getCurrentFilteredAuthors();
      selectedExternalAuthFilter = _persistForIndex.externalAuthFilter;
    } else {
      _persistForIndex.indexUid = indexUid;
      _persistForIndex.docFilter = null;
      _persistForIndex.commentFilter = 'ALL';
      _persistForIndex.authorIdFilter = [];
      _persistForIndex.authorFilter = [];
      _persistForIndex.commentsUnreadFilter = 'all';
      _persistForIndex.externalAuthFilter = null;
      _persistForIndex.filterSinceDateTime = null;
      _persistForIndex.date = null;
      _persistForIndex.time = null;
    }

    if (isTocFrontMatter()) {
      _currentDocFilter = 'all';
      _currentCommentFilter = 'ALL';
      _currentUserIdsFilter = [];
      _currentExternalAuthFilter = CommentTypes.external.ALL as ExternalUserType;
    } else {
      let filterActive = false;
      _currentDocFilter = selectedDocFilter ? selectedDocFilter : DEFAULT_DOC_FILTER;
      _currentCommentFilter = selectedCommentFilter ? (selectedCommentFilter as CommentStatus) : (DEFAULT_COMMENT_FILTER as CommentStatus);
      _currentExternalAuthFilter = selectedExternalAuthFilter
        ? (selectedExternalAuthFilter as ExternalUserType)
        : (CommentTypes.external.ALL as ExternalUserType);
      _currentUserIdsFilter = selectedAuthorsIdFilter || [];

      let status: null | string = _currentCommentFilter;
      if (state.filter.status === 'ALL' || state.filter.status === 'all') {
        status = 'allcomments';
      }
      let filter: FilterType = {
        range: _currentDocFilter,
        status: status,
        visibility: _currentExternalAuthFilter,
        commentsUnreadFilter: _persistForIndex.commentsUnreadFilter,
        filterSinceDateTime: _persistForIndex.filterSinceDateTime,
        date: _persistForIndex.date,
        time: _persistForIndex.time,
        selectedAuthors: selectedAuthorsFilter || []
      };

      if (
        state.filter.range !== 'tocSelected' ||
        state.filter.status !== 'allcomments' ||
        state.filter.visibility !== 'ALL' ||
        _currentUserIdsFilter.length > 0 ||
        state.filter.commentsUnreadFilter === 'unread' ||
        state.filter.filterSinceDateTime
      ) {
        filterActive = true;
      }
      dispatch({ type: 'setFilter', payload: filter });
      dispatch({ type: 'setFilterActive', payload: filterActive });
    }
  }

  function canWriteComment() {
    return ProjectStore.canWriteComment();
  }

  function canDeleteComment(comment: IComment) {
    return ProjectStore.canDeleteComment(comment);
  }

  function isProjectAdmin() {
    return ProjectStore.isProjectAdmin();
  }

  function loaded() {
    return ProjectStore.isLoaded();
  }

  // toc has changed
  function onTocStoreUpdate() {
    updateStates();
    retrieveComments();
  }

  function onEditStoreUpdate(e: IEditorStoreEvent<'unitsSelected'>) {
    if (e.type === 'unitsSelected') {
      if (e.data!.selectedUnits.length === 1) {
        if (!_ignoreNextEditorSelectUpdate) {
          selectForUnit(e.data!.initialSelectedUnit!.uid);
        }
        _ignoreNextEditorSelectUpdate = false;
      }
    }
  }

  function showSnackbar(msg: string, action?: () => void) {
    const snackbarMessage: ISnackbarMessage = {
      open: true,
      message: msg,
      onRequestClose: () => {
        appStore.dispatch<any>(hideSystemSnackbarMessage());
      }
    };
    appStore.dispatch<any>(showSystemSnackbarMessage(snackbarMessage));
  }

  function resetPersistForIndex() {
    _persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
    _persistForIndex.docFilter = null;
    _persistForIndex.commentFilter = 'ALL';
    _persistForIndex.authorIdFilter = [];
    _persistForIndex.authorFilter = [];
    _persistForIndex.externalAuthFilter = null;
    _persistForIndex.commentsUnreadFilter = 'all';
    _persistForIndex.filterSinceDateTime = null;
    _persistForIndex.date = null;
    _persistForIndex.time = null;
    _currentDocFilter = 'tocSelected';
    _currentCommentFilter = 'ALL';
    _currentUserIdsFilter = [];
    _currentExternalAuthFilter = CommentTypes.external.ALL as ExternalUserType;
  }

  function selectForUnit(unitUid: string, selectLast?: boolean) {
    let unitComments: IComment[] = [];
    if (state.comments) {
      unitComments = state.comments.filter((c) => c.unitUid === unitUid);

      if (state.selectedCommentUid) {
        let selectedCommentHasBeenDeleted = true;
        if (unitComments.length) {
          unitComments.forEach((s) => {
            if (s.uid === state.selectedCommentUid) {
              selectedCommentHasBeenDeleted = false;
            }
          });
        }
        if (selectedCommentHasBeenDeleted) {
          // comment has been deleted , dont highlight anything
          showSnackbar('Comment no longer exists');
        } else {
          // highlight the correct comment
          let selectedComment: IComment | null = null;
          let state: Partial<CommentsState> = {};
          state.comments?.forEach((s) => {
            if (s.uid === state.selectedCommentUid) {
              selectedComment = s;
              dispatch({ type: 'setSelectedComment', payload: selectedComment });
              dispatch({ type: 'setHighlightedComments', payload: unitComments });
              dispatch({ type: 'setFollowingSelectedComment', payload: state.comments![selectedComment.index + 1] });
            }
          });
        }
      } else if (unitComments.length) {
        const selectedComment = unitComments[selectLast ? unitComments.length - 1 : 0];

        dispatch({ type: 'setSelectedComment', payload: selectedComment });
        dispatch({ type: 'setHighlightedComments', payload: unitComments });
        dispatch({ type: 'setFollowingSelectedComment', payload: state.comments![selectedComment.index + 1] });
      }
    } else {
      dispatch({ type: 'setSelectedComment', payload: null });
      dispatch({ type: 'setHighlightedComments', payload: [] });
      dispatch({ type: 'setFollowingSelectedComment', payload: null });
    }
    dispatch({ type: 'setSelectedCommentUid', payload: null });
  }

  function scrollToCommentIndex(index: number) {
    const $commentsList = $(ReactDOM.findDOMNode(commentsListRef.current) as Element),
      $commentEl = $commentsList.find('li').eq(index);

    if ($commentEl.length) {
      const unitTopPosition = $commentEl.offset()!.top!;
      $commentsList.animate(
        {
          scrollTop: unitTopPosition - $commentsList.offset()!.top! + $commentsList.scrollTop()!
        },
        300
      );
    }
  }

  function isEditorUnitSelected(comment) {
    return state.highlightedComments.filter((c) => c.uid === comment.uid).length > 0;
  }

  // don't clear create comment on initial retrieve (as its mounting we don't want it to be removed)
  function retrieveComments() {
    dispatch({ type: 'setLoading', payload: true });
    return new Promise<IComment[]>((resolve, reject) => {
      const startingUnitUid = getFilterUnitUid();
      const dataParams = startingUnitUid ? { tocUnitUid: startingUnitUid } : null;
      const docParams = EditorStore.getDocParams();

      CommentStore.retrieveComments(docParams.projectUid!, docParams.indexUid!, dataParams, {
        externalUserType: _currentExternalAuthFilter,
        users: _currentUserIdsFilter,
        fromDateTime: state.filter.filterSinceDateTime,
        status: _currentCommentFilter,
        unread: state.filter.commentsUnreadFilter!
      }).then((result) => {
        resolve(result);
      });
    });
  }

  function downloadCommentsToCSV() {
    const startingUnitUid = getFilterUnitUid();
    const dataParams = startingUnitUid ? { tocUnitUid: 'tocUnitUid=' + startingUnitUid + '&' } : { tocUnitUid: '' };

    const docParams = EditorStore.getDocParams();
    let url =
      '/api/projects/' + docParams.projectUid + '/indexes/' + docParams.indexUid + '/comments?' + dataParams.tocUnitUid + 'attachment=true';

    if (_currentCommentFilter) {
      url += '&status=' + _currentCommentFilter;
    }

    if (_currentExternalAuthFilter) {
      url += '&commentType=' + _currentExternalAuthFilter;
    }

    if (_currentUserIdsFilter) {
      url += '&userUids=' + _currentUserIdsFilter.join(',');
    }

    location.href = url;
  }

  function onCommentStatusStoreUpdate(e: CommentStatusStoreEvent) {
    if (e.type === 'comment-read-unread') {
      dispatch({ type: 'setCommentReadUnread', payload: e.state.comment! });
    } else if (e.type === 'marked-all-to-read') {
      dispatch({ type: 'markAllToRead', payload: null });
    }
  }

  function onCommentStoreUpdate(e: CommentStoreEvent) {
    if (e.type === 'selectedComment' && !e.comment!.threadUid) {
      dispatch({ type: 'setSelectedComment', payload: e.comment! });
      dispatch({ type: 'setSelectedCommentUid', payload: e.comment!.uid });
      dispatch({ type: 'setHighlightedComments', payload: [e.comment!] });
    } else if (e.type === 'selectedCommentUid') {
      dispatch({ type: 'setSelectedCommentUid', payload: e.uid! });
    } else if (e.type === 'retrieved-authors') {
      dispatch({ type: 'setAuthors', payload: e.authors! });
    }
    if (e.type === 'comments') {
      dispatch({ type: 'setLoading', payload: false });
      dispatch({ type: 'setComments', payload: e.state.comments });
    } else if (e.type === 'commentIntent') {
      dispatch({ type: 'setNewCommentUnit', payload: e.unit! });
    }
  }

  function getFilterUnitUid() {
    if (isTocFrontMatter()) {
      return null;
    }

    const selectedToc = TocStore.getSelectedItem();
    return _currentDocFilter === 'all' ? null : selectedToc ? selectedToc.uid : null;
  }

  function isTocFrontMatter() {
    const selectedToc = TocStore.getSelectedItem();
    return selectedToc && selectedToc.type.indexOf('frontmatter') !== -1;
  }

  function getSelectedTocAsString() {
    const selectedToc = TocStore.getSelectedItem();

    if (selectedToc && selectedToc.level) {
      let typeName = selectedToc.level.toLowerCase();
      typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1);

      if (selectedToc.ordinal) {
        return typeName + ': ' + selectedToc.ordinal;
      } else if (!selectedToc.ordinal) {
        return typeName;
      }
    } else {
      return ''; // in situations where comments load before main toc (when linking to doc to open comments)
    }
  }

  function getCommentCount() {
    if (state.filterActive) {
      return '(Filtered: ' + state.comments!.length + ')';
    } else {
      return '(' + state.comments!.length + ')';
    }
  }

  function toggleMenu() {
    dispatch({ type: 'setCommentsFilterMenuSelected', payload: true });
  }

  function handleCommentSelect(comment) {
    let unitComments: IComment[] = [];

    if (state.comments) {
      unitComments = state.comments.filter((c) => c.unitUid === comment.unitUid);

      const following = state.comments![comment.index + 1];
      dispatch({ type: 'setSelectedComment', payload: comment });
      dispatch({ type: 'setFollowingSelectedComment', payload: following });
      dispatch({ type: 'setHighlightedComments', payload: unitComments });
      dispatch({ type: 'setNewCommentUnit', payload: null });

      _ignoreNextEditorSelectUpdate = true; // we're the ones in control here
      EditorStore.openDocumentWithLink(comment.link);
    }
  }

  function handleCreated(unitUid: string) {
    dispatch({ type: 'setNewCommentUnit', payload: null });
    selectForUnit(unitUid);
  }

  function handleCancelCreate() {
    dispatch({ type: 'setNewCommentUnit', payload: null });
  }

  function getListViewingTitle() {
    return _currentDocFilter === 'all' || isTocFrontMatter() ? 'All Document' : getSelectedTocAsString();
  }

  function handleCommentStatusUpdated(comment: IComment) {
    CommentStatusStore.readOrUnreadComment(ProjectStore.getProject()!.uid, EditorStore.getDocParams().indexUid!, comment);
  }

  function handleMenu(action: string) {
    if (action === 'readAll') {
      CommentStatusStore.markAllToRead(ProjectStore.getProject()!.uid, EditorStore.getDocParams().indexUid!);
    } else if (action === 'export') {
      downloadCommentsToCSV();
    }
  }

  function handleListFilter(type) {
    if (type === 'ALL') {
      _currentExternalAuthFilter = CommentTypes.external.ALL as ExternalUserType;
    } else if (type === 'INTERNAL') {
      _currentExternalAuthFilter = CommentTypes.external.INTERNAL_COMMENTORS as ExternalUserType;
    } else if (type === 'PUBLIC') {
      _currentExternalAuthFilter = CommentTypes.external.EXTERNAL_COMMENTORS as ExternalUserType;
    } else {
      const typeProp = _.filter(CommentTypes.props, { key: type });
      if (typeProp.length > 0) {
        _currentCommentFilter = typeProp[0].value as CommentStatus;
      } else {
        _currentDocFilter = type;
      }
    }
  }

  function refreshComment(comment: IComment) {
    const params = EditorStore.getDocParams();
    CommentStore.refreshComment(params.projectUid!, params.indexUid!, comment.uid).then((state) => {
      dispatch({ type: 'setComments', payload: state.comments });
    });
  }

  function isExportDisabled() {
    return !!(state.comments && state.comments.length === 0);
  }

  function closeFilter() {
    dispatch({ type: 'setCommentsFilterMenuSelected', payload: false });
    updateStates();
  }

  function getRange() {
    return [
      { text: 'All Document', payload: 'all' },
      { text: getSelectedTocAsString(), payload: DEFAULT_DOC_FILTER }
    ];
  }

  function getStatus() {
    return [
      { text: 'All Comments', payload: 'allcomments' },
      { text: 'Active Comments', payload: 'active' },
      { text: 'Resolved Comments', payload: 'resolved' }
    ];
  }

  function getVisibility() {
    return [
      { text: 'All Comments', payload: 'ALL' },
      { text: 'Internal Comments', payload: 'INTERNAL' },
      { text: 'Public Comments', payload: 'PUBLIC' }
    ];
  }

  function isUserAvailableToAdd(user: IUser) {
    if (state.filter.selectedAuthors) {
      return !state.filter.selectedAuthors.find((u) => {
        return u.uid === user.uid;
      });
    }
    return false;
  }

  function handleParticipantChange(e) {
    _currentUserIdsFilter = [];
    const currentUserList = state.filter.selectedAuthors;
    if (e.type === 'add') {
      currentUserList.push(e.data);
    } else if (e.type === 'remove') {
      currentUserList.splice(
        currentUserList.findIndex(function (u) {
          return u.uid === e.userUid;
        }),
        1
      );
    }
    currentUserList.forEach((user) => {
      _currentUserIdsFilter.push(user.uid);
    });
    state.filter.selectedAuthors = currentUserList;
    dispatch({ type: 'setFilter', payload: state.filter });
  }

  function formatDate(date) {
    return dateUtil(date).formatDate();
  }

  function enableScriptExceptionHandling() {
    setTimeout(() => {
      SystemStore.disableScriptExceptionHandling(false);
    }, 750);
  }

  function dateChanged(prop: string, e: any, date: Date) {
    if (state.filter.date && prop === 'filterTime') {
      const diffDate = moment(state.filter.date);
      const newTime = moment(date);
      diffDate.set({ hour: newTime.hour(), minute: newTime.minutes() });
      const dateText = moment(diffDate);
      dateText.format('DD-MMM-YYYY HH:mm');
      state.filter.filterSinceDateTime = Date.parse(dateText.toString());
    } else {
      if (!state.filter.time) {
        date.setHours(0, 0, 0, 0);
      }
      const dateText = moment(date);
      dateText.format('DD-MMM-YYYY HH:mm');
      state.filter.filterSinceDateTime = Date.parse(dateText.toString());
    }
    prop === 'filterDate' ? (state.filter.date = date) : (state.filter.time = date);
    dispatch({ type: 'setFilter', payload: state.filter });
    enableScriptExceptionHandling();
  }

  function clearDate() {
    state.filter.date = null;
    state.filter.time = null;
    state.filter.filterSinceDateTime = null;
    dispatch({ type: 'setFilter', payload: state.filter });
  }

  function clearTime() {
    state.filter.time = null;
    dispatch({ type: 'setFilter', payload: state.filter });
    dateChanged('filterTime', null, state.filter.date!);
  }

  function handleFormChange(e, field, value) {
    state.filter[field] = value;
    dispatch({ type: 'setFilter', payload: state.filter });
    handleListFilter(value);
  }

  function handleFilterButton(action: string) {
    let filterActive = false;
    if (action === 'apply') {
      _persistForIndex.indexUid = EditorStore.getDocParams().indexUid!;
      _persistForIndex.docFilter = _currentDocFilter;
      _persistForIndex.commentFilter = _currentCommentFilter;
      _persistForIndex.externalAuthFilter = _currentExternalAuthFilter;
      _persistForIndex.authorIdFilter = _currentUserIdsFilter;
      _persistForIndex.commentsUnreadFilter = state.filter.commentsUnreadFilter;
      _persistForIndex.authorFilter = state.filter!.selectedAuthors;
      _persistForIndex.filterSinceDateTime = state.filter.filterSinceDateTime;
      _persistForIndex.date = state.filter.date;
      _persistForIndex.time = state.filter.time;

      if (
        state.filter.range !== 'tocSelected' ||
        state.filter.status !== 'allcomments' ||
        state.filter.visibility !== 'ALL' ||
        _currentUserIdsFilter.length > 0 ||
        state.filter.commentsUnreadFilter === 'unread' ||
        state.filter.filterSinceDateTime
      ) {
        filterActive = true;
      }
      dispatch({ type: 'setLoading', payload: true });
      dispatch({ type: 'setCommentsFilterMenuSelected', payload: false });
      dispatch({ type: 'setFilterActive', payload: filterActive });
      retrieveComments();
    } else if (action === 'reset') {
      dispatch({
        type: 'resetState',
        payload: getInitialState({ loading: true, commentsFilterMenuSelected: true, authors: state.authors })
      });
      resetPersistForIndex();
      retrieveComments();
    }
  }

  function commentsUnreadFilter(value: 'all' | 'unread' | 'read') {
    state.filter.commentsUnreadFilter = value;
    dispatch({ type: 'setFilter', payload: state.filter });
  }

  function returnFilterMenu() {
    return (
      <div className="commentFilterMenu subaction-list-container">
        <div className="header">
          <h5>Show Comments</h5>
          <span id="closeFilter" onClick={(e) => closeFilter()} className="close-icon">
            <IconButton iconClassName="material-icons">clear</IconButton>
          </span>
        </div>
        <div className="filterMenu">
          <div className="unreadToggle">
            <RadioButtonGroup
              name=""
              valueSelected={state.filter.commentsUnreadFilter}
              onChange={(e, value: 'all' | 'unread' | 'read') => {
                commentsUnreadFilter(value);
              }}
            >
              <RadioButton id="unread" value="unread" label="Unread" />
              <RadioButton id="all" value="all" label="All " />
            </RadioButtonGroup>
          </div>

          <div className="selectField-comment-filter">
            <label>Show</label>
            <SelectField
              id="range"
              menuStyle={{ right: '22px' }}
              value={state.filter.range}
              className="filter-select"
              fullWidth={true}
              onChange={(e, i, action) => {
                handleFormChange(e, 'range', action);
              }}
            >
              {getRange().map((item) => {
                return <MenuItem key={item.payload!} value={item.payload!} primaryText={item.text} />;
              })}
            </SelectField>
          </div>

          <div className="selectField-comment-filter">
            <label>Status</label>
            <SelectField
              id="status"
              menuStyle={{ right: '22px' }}
              value={state.filter.status}
              className="filter-select"
              fullWidth={true}
              onChange={(e, i, action) => {
                handleFormChange(e, 'status', action);
              }}
            >
              {getStatus().map((item) => {
                return <MenuItem key={item.payload!} value={item.payload!} primaryText={item.text} />;
              })}
            </SelectField>
          </div>

          <div className="selectField-comment-filter">
            <label>Visibility</label>
            <SelectField
              id="visibility"
              menuStyle={{ right: '22px' }}
              value={state.filter.visibility}
              className="filter-select"
              fullWidth={true}
              onChange={(e, i, action) => {
                handleFormChange(e, 'visibility', action);
              }}
            >
              {getVisibility().map((item) => {
                return <MenuItem key={item.payload!} value={item.payload!} primaryText={item.text} />;
              })}
            </SelectField>
          </div>
          <div className="autoSuggest-author">
            <label>Added by</label>
            <UserFilterList
              commenters={state.authors}
              users={state.filter.selectedAuthors}
              userAvailabilityCheck={(user) => isUserAvailableToAdd(user)}
              onChange={(e) => {
                handleParticipantChange(e);
              }}
            />
          </div>
          <div className="date-time-filter">
            <label>Since</label>
            <span className="icon material-icons date-time-icon">date_range</span>
            <DatePicker
              hintText="Set date"
              value={state.filter.date!}
              formatDate={(date) => formatDate(date)}
              onChange={(e, date) => dateChanged('filterDate', e, date)}
              autoOk={true}
              onFocus={() => {
                SystemStore.disableScriptExceptionHandling(true);
              }}
            />
            {state.filter.date ? (
              <FontIcon
                className="material-icons clear-icon clickable"
                style={{ fontSize: '15px' }}
                onClick={() => clearDate()}
                color="#bdbdbd"
              >
                clear
              </FontIcon>
            ) : undefined}
          </div>
          <div className="date-time-filter">
            <span className="icon material-icons date-time-icon">access_time</span>
            <TimePicker
              hintText="Set time"
              format="24hr"
              value={state.filter.time!}
              disabled={!state.filter.date}
              onChange={(e, date) => dateChanged('filterTime', e, date)}
              autoOk={true}
              onFocus={() => {
                SystemStore.disableScriptExceptionHandling(true);
              }}
            />
            {state.filter.date && state.filter.time ? (
              <FontIcon
                className="material-icons clear-icon clickable"
                style={{ fontSize: '15px' }}
                onClick={() => clearTime()}
                color="#bdbdbd"
              >
                clear
              </FontIcon>
            ) : undefined}
          </div>
          <div className="filter-item-buttons">
            <FlatButton
              id="reset"
              label="Reset"
              secondary={true}
              onClick={() => {
                handleFilterButton('reset');
              }}
            />
            <FlatButton
              id="apply"
              label="Apply"
              secondary={true}
              onClick={() => {
                handleFilterButton('apply');
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function returnCommentsList() {
    return (
      <div className="comments-subaction subaction-list-container">
        <h5 className="commentCount">Comments</h5>
        {state.comments ? <span>{getCommentCount()}</span> : undefined}
        {!state.loading ? (
          <DropDownIcon
            icon="more_vert"
            className="comment-dropdown-menu-list"
            containerClassName="comment-dropdown-menu-container-inner comments-dropdown"
            onSelect={(action) => handleMenu(action)}
          >
            <DropDownOption value="readAll">Mark all as Read</DropDownOption>
            <DropDownOption value="export" show={!isExportDisabled()}>
              Export Comments
            </DropDownOption>
          </DropDownIcon>
        ) : undefined}
        {!state.loading ? (
          <IconButton
            iconClassName="material-icons"
            className={`commentsFilter ${state.filterActive ? ' active' : ''}`}
            id="commentsFilter"
            onClick={() => toggleMenu()}
            tooltipPosition="bottom-left"
            tooltipStyles={{ top: '25px' }}
            tooltip="Filter Comments"
          >
            filter_list
          </IconButton>
        ) : undefined}
        {!state.loading ? (
          <h6 data-qa="comments-list-viewing-title">
            <span>{getListViewingTitle()}</span>
          </h6>
        ) : undefined}

        <div className="comments-list-container">
          {state.newCommentUnit && canWriteComment() ? (
            <CommentForm
              filterSinceDateTime={state.filter.filterSinceDateTime}
              commentsUnreadFilter={state.filter.commentsUnreadFilter}
              currentCommentFilter={_currentCommentFilter}
              currentUserIdsFilter={_currentUserIdsFilter}
              currentExternalAuthFilter={_currentExternalAuthFilter}
              className="new-comment"
              unitUid={state.newCommentUnit.uid}
              unitType={state.newCommentUnit.type}
              onCreated={(unitUid) => {
                handleCreated(unitUid);
              }}
              onCancel={() => {
                handleCancelCreate();
              }}
            />
          ) : undefined}

          {!state.loading && state.comments && state.comments.length > 0 ? (
            <ul className="comments-list" ref={commentsListRef}>
              {state.comments.map((comment) => {
                return (
                  <Comment
                    key={comment.uid}
                    comment={comment}
                    filterSinceDateTime={state.filter.filterSinceDateTime}
                    commentsUnreadFilter={state.filter.commentsUnreadFilter}
                    currentCommentFilter={_currentCommentFilter}
                    currentUserIdsFilter={_currentUserIdsFilter}
                    currentExternalAuthFilter={_currentExternalAuthFilter}
                    isWriteable={canWriteComment()}
                    isDeletable={canDeleteComment(comment)}
                    isProjectAdmin={isProjectAdmin()}
                    onSelected={() => handleCommentSelect(comment)}
                    onAttachmentClick={(uid: string) => {
                      props.onAttachmentClick(uid);
                    }}
                    onRequestRefresh={() => {
                      refreshComment(comment);
                    }}
                    onRetrieveComments={() => retrieveComments()}
                    onHandleCommentStatusUpdated={(comment) => {
                      handleCommentStatusUpdated(comment);
                    }}
                    isSelected={!!(state.selectedComment && state.selectedComment.uid === comment.uid)}
                    isHighlighted={isEditorUnitSelected(comment)}
                    className={
                      state.followingSelectedComment && state.followingSelectedComment.uid === comment.uid ? 'following-selected' : ''
                    }
                  />
                );
              })}
            </ul>
          ) : undefined}

          {state.comments && state.comments.length === 0 ? (
            <div className="narrow-panel-empty-list">
              <img src={'/assets/images/comments_panel_info.svg'} />

              <span className="empty-title">No Comments</span>
              {loaded() && canWriteComment() ? (
                <span className="empty-details">
                  Post a comment to start a discussion <br />
                  <span className="mention-text">@mention</span> someone to notify them
                  <br />
                  You can also <br />
                  <span className="mention-text">~link</span> a file from your list of attachments
                </span>
              ) : undefined}
            </div>
          ) : undefined}

          {state.loading ? (
            <div className="loading">
              <CircularProgress mode="indeterminate" size={17.85} />
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }

  return state.commentsFilterMenuSelected ? returnFilterMenu() : returnCommentsList();
};

export default Comments;
