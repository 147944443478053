import * as React from 'react';
import Checkbox from 'material-ui/Checkbox';

export type ChecklistProps = {
  title: string;
  items: ChecklistItemValue[];
  onChange(selection: string);
  selectedItems: string[];
};

export type ChecklistItemValue = {
  title: string;
  value: string;
};

const Checklist: React.FC<ChecklistProps> = ({ title, items, onChange, selectedItems }: ChecklistProps) => {
  const isChecked = (value: string) => {
    return selectedItems.findIndex((item) => item === value) > -1;
  };

  return (
    <div data-qa="data-attribute">
      <div data-qa="data-attribute-checklist">
        <div className="checklist">
          <div className="checklist-title">{title}:</div>
          <div className="checklist-options">
            {items.map((item, index) => {
              return (
                <Checkbox
                  checked={isChecked(item.value)}
                  key={`checklist-${item.value}`}
                  data-checkbox-name={item.value}
                  data-checkbox-selected={isChecked(item.value)}
                  label={item.title}
                  onClick={(e) => {
                    onChange(item.value);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
