import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import React from 'react';

export type DropdownOption = { val: string; text?: string };

export type Props = {
  options: DropdownOption[];
  title: string;
  onChange: (val: string) => void;
  val: string;
  dataQa: string;
};

const DropdownProp = ({ options, title, onChange, val, dataQa }: Props) => {
  return (
    <div>
      <SelectField floatingLabelText={title} value={val} onChange={(e, i, val) => onChange(val)} fullWidth={true} data-qa={dataQa}>
        {options.map((opt) => {
          return <MenuItem key={opt.val} value={opt.val} primaryText={opt.text ?? opt.val} />;
        })}
      </SelectField>
    </div>
  );
};

export default DropdownProp;
