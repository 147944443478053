import * as React from 'react';
import * as _ from 'lodash';
import MergeRevisionsStore, { MergeRevisionsStoreEvent } from '../../../flux/editor/MergeRevisionsStore';
import { IIndexMergeActivity } from 'mm-types';
import classNameHelper from '../../../utils/classNameHelper';

export type Props = {};

export type State = {
  activities: IIndexMergeActivity[];
  selected: null | string;
};

export default class MergeActivityPanel extends React.Component<Props, State> {
  private unsub: Function;

  constructor(props: Props) {
    super(props);

    this.unsub = MergeRevisionsStore.listen(this._onStoreUpdate, this);

    const storeState = MergeRevisionsStore.getInitialState();

    this.state = {
      activities: _.cloneDeep(storeState.activities) as IIndexMergeActivity[],
      selected: null
    };
  }

  componentWillUnmount() {
    this.unsub();
  }

  componentDidMount() {
    const storeState = MergeRevisionsStore.getInitialState();
    let isCurrentSelectedUnchanged: boolean | undefined = false;
    const currentSelectedActivity = storeState.selectedActivity;

    // may have changed due to a merge approve/reject while comment tab was mounted, then this tab mounts and we must ensure to select next item on list
    if (currentSelectedActivity) {
      const lastestCurrentSelectedActivity = this.state.activities.find(
        (activity: IIndexMergeActivity) => activity.activityUid === currentSelectedActivity!.activityUid
      );
      isCurrentSelectedUnchanged =
        lastestCurrentSelectedActivity &&
        lastestCurrentSelectedActivity.activityUid === currentSelectedActivity.activityUid &&
        lastestCurrentSelectedActivity.status === currentSelectedActivity.status;
    }

    if (isCurrentSelectedUnchanged) {
      // i.e. switch back from comments tab, and no diff
      this._handleActivitySelect(storeState.selectedActivity!, false);
    } else {
      // initial select activity is first unmerged one
      const nextUnMergedActivity = this._findNextUnmergedActivity();
      if (nextUnMergedActivity) {
        // will be empty if no changes!!
        this._handleActivitySelect(nextUnMergedActivity, true);
      }
    }
  }

  _findNextUnmergedActivity(): IIndexMergeActivity | undefined {
    return this.state.activities.find(
      (activity: IIndexMergeActivity) => activity.status === 'UNMERGED' || activity.status === 'INAPPLICABLE'
    );
  }

  _onStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'mergeComplete') {
      this.setState({ activities: _.cloneDeep(MergeRevisionsStore.getInitialState().activities) }, () => {
        const nextActivityForMerge = this._findNextUnmergedActivity();
        if (nextActivityForMerge) {
          this._handleActivitySelect(nextActivityForMerge, true);
        } else {
          // no more: finish merge
          MergeRevisionsStore.finishMerge(() => {
            location.reload();
          });
        }
      });
    }
  }

  _handleActivitySelect(activityListEntry: IIndexMergeActivity, retrieveIndexChanges: boolean) {
    if (activityListEntry.status === 'UNMERGED' || activityListEntry.status === 'INAPPLICABLE') {
      this.setState({ selected: activityListEntry.activityUid }, () => {
        if (retrieveIndexChanges) {
          MergeRevisionsStore.retrieveActivity(activityListEntry);
        }
      });
    }
  }

  _getActivityName(activity: IIndexMergeActivity) {
    if (activity.status === 'INAPPLICABLE' || activity.status === 'INAPPLICABLE_ACKNOWLEDGED') {
      return 'Unmergeable Unit';
    } else {
      return activity.display;
    }
  }

  _getActivityDescription(activity: IIndexMergeActivity) {
    if (activity.status === 'INAPPLICABLE' || activity.status === 'INAPPLICABLE_ACKNOWLEDGED') {
      return 'Unit cannot be merged';
    } else {
      return (activity.unitActivity.entityOrdinal ? activity.unitActivity.entityOrdinal + ' ' : '') + activity.unitActivity.description;
    }
  }

  render() {
    return (
      <div className="activity-subaction subaction-list-container">
        <h5>Merge change history</h5>
        <h6>
          <span>All merge changes</span>
        </h6>
        <div>
          {this.state.activities && this.state.activities.length > 0 ? (
            <ul className="activity-list">
              {this.state.activities.map((entry) => {
                const className = classNameHelper.merge('', {
                  selected: this.state.selected === entry.activityUid,
                  'activity-greyout': entry.status !== 'UNMERGED' && entry.status !== 'INAPPLICABLE',
                  'activity-inapplicable': entry.status === 'INAPPLICABLE'
                });
                return (
                  <li
                    key={entry.activityUid}
                    data-activity-uid={entry.activityUid}
                    className={className}
                    onClick={(e) => this._handleActivitySelect(entry, true)}
                  >
                    <div>
                      <div className="icon-activity-outer">
                        {entry.status === 'UNMERGED' ? <div className={`icon-activity icon-activity-${entry.icon}`}></div> : undefined}
                        {entry.status === 'APPLIED' ? <i className="icon-activity material-icons">check</i> : undefined}
                        {entry.status === 'IGNORED' ? <i className="icon-activity material-icons">close</i> : undefined}
                        {entry.status === 'INAPPLICABLE' ? (
                          <i className="icon-activity icon-activity-inapplicable material-icons">error_outline</i>
                        ) : undefined}
                        {entry.status === 'INAPPLICABLE_ACKNOWLEDGED' ? (
                          <i className="icon-activity icon-activity-inapplicable acknowledged material-icons">error_outline</i>
                        ) : undefined}
                      </div>
                      <div className="activity-item">
                        <div className="activity-item-name">{this._getActivityName(entry)}</div>
                        <div className="activity-item-time">{entry.formatted}</div>
                      </div>

                      <div className="activity-item">
                        <div className="activity-item-description">{this._getActivityDescription(entry)}</div>
                        <div className="activity-item-user">{entry.unitActivity.user ? entry.unitActivity.user.displayName : ''}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : undefined}
        </div>

        {this.state.activities && this.state.activities.length === 0 ? (
          <div className="narrow-panel-empty-list">
            <span className="empty-title">No Items Require Merge</span>
            <span className="empty-details">There are no new changes that require a merge. Click Accept to complete the merge.</span>
          </div>
        ) : undefined}
      </div>
    );
  }
}
