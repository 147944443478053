import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { ACTIONS, STYLE_ACTIONS } from './BorderActions';

export type Props = {
  title: string;
  border: string;
  borderStyle: string;
  onBorderChange: (e: { action: string; styleAction: string }) => void;
  onBorderStyleChange: (e: { action: string }) => void;
};

const Border = ({ title = '', border = 'BorderNone', borderStyle = 'BorderStyleSolid', onBorderChange, onBorderStyleChange }: Props) => {
  const handleClick = (action: string) => {
    onBorderChange({ action: action, styleAction: borderStyle });
  };

  const handleStyleChange = (action: string) => {
    onBorderStyleChange({ action: action });
  };

  const getBorderStyleMenuItems = () => {
    return STYLE_ACTIONS.map((style) => {
      return <MenuItem key={style.action} value={style.action} primaryText={style.primaryText} />;
    });
  };

  return (
    <div className="border-style">
      <div className="manip-group">
        <h6>{title}</h6>
        <span className="controls">
          <ul className="icon-action-buttons">
            {ACTIONS.map((item) => {
              const iconClassName = 'material-icons ' + item.icon;

              return (
                <li
                  key={item.action}
                  className={border === item.action ? 'selected' : ''}
                  onClick={() => {
                    handleClick(item.action);
                  }}
                >
                  <IconButton
                    iconClassName={iconClassName}
                    tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                    tooltipPosition="bottom-left"
                    tooltip={item.tooltip}
                  />
                </li>
              );
            })}
          </ul>
        </span>
      </div>

      {border !== 'BorderNone' ? (
        <div className="manip-group">
          <h6>Border Style</h6>
          <span className="controls">
            <SelectField
              value={borderStyle}
              onChange={(e, action, payload) => handleStyleChange(payload)}
              style={{ width: '100%' }}
              id="border-style-dropdown"
            >
              {getBorderStyleMenuItems()}
            </SelectField>
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Border;
