import * as React from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import * as _ from 'lodash';
import { IMedia, ILink, ISource } from 'mm-types';
import Sources from './components/MediaProps/Sources';
import Usages from './components/MediaProps/Usages';
import QueryUtil from '../../../utils/QueryUtil';
import Header from './components/MediaProps/Header';
import Dimensions from './components/MediaProps/Dimensions';
import Uploader from './components/MediaProps/Uploader';
import Description from './components/MediaProps/Description';
import Toggle from 'material-ui/Toggle';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';

export type Props = {
  media: IMedia;
  userAvatarUrl: string | null;
  isEditing: boolean;
  isFetching: boolean;
  isUploadingSource: boolean;
  onHideForm: () => void;
  onSaveForm: (updatedMedia: IMedia) => void;
  onRemoveSource: (removedMedia: ISource) => void;
  onAddSource: (addedMedia: IMedia, files: FileList) => void;
  onDelete: () => void;
  onEdit: () => void;
  onModalCloseRequest: () => void;
};

export type Media = {
  uid: string;
  caption: string;
  description: string;
  filename: string;
  location: string;
  type: string;
};

export type State = {};

export default class MediaProps extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.downloadSource = this.downloadSource.bind(this);
    this.removeSource = this.removeSource.bind(this);
    this.addSource = this.addSource.bind(this);
    this.linkToReference = this.linkToReference.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.resetStateFromProperties(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(nextProps, this.props)) {
      this.resetStateFromProperties(nextProps);
    }
  }

  private resetStateFromProperties(props: Props) {
    const newState = {
      descriptionForm: props.media.description,
      allowReset: false,
      confirmOverlay: false
    };
    this.setState(newState);
  }

  private downloadSource(source?: ISource | undefined) {
    if (source) {
      location.href = `${source.location}${QueryUtil.hasParams(source.location) ? '&' : '?'}attachment=true`;
    } else {
      location.href = `${this.props.media.location}${QueryUtil.hasParams(this.props.media.location) ? '&' : '?'}attachment=true`;
    }
  }

  private removeSource(source: ISource) {
    if (this.props.onRemoveSource) {
      this.props.onRemoveSource(source);
    }
  }

  private addSource(files: FileList) {
    this.props.onAddSource(this.props.media, files);
  }

  private linkToReference(ref: ILink) {
    // TODO note currently links only *within* project - will need to tweak once cross project media is available
    this.props.onModalCloseRequest();
    EditorStore.openDocumentWithLink(ref);
  }

  render() {
    const { media, userAvatarUrl, isEditing, isUploadingSource, isFetching } = this.props;

    return (
      <div className="media-details-props side-slidein-container">
        <Header media={media} deleteMedia={this.props.onDelete} onDownloadSource={this.downloadSource} />

        <div className="col s12 side-container-content">
          <div className="toggle-section-outer side-slidein-content-container">
            <div className="side-slidein-content-namevalueslist">
              <Dimensions media={media} />
              <div className="media-extra-info">
                <label className="margin-bottom-m">Uploaded</label>
                <Uploader media={media} userAvatarUrl={userAvatarUrl} />
                {(media.type === 'image' || media.type === 'symbol') &&
                  ProjectDefinitionStore.getCurrentIndexDefinition()?.mediaLibraryHasSymbols === true && (
                    <div>
                      <Toggle
                        name={'Symbol'}
                        label={`Symbol ${media.references && media.references.length > 0 ? '(delete references before changing)' : ''}`}
                        toggled={media.type === 'symbol'}
                        onToggle={(e, inputChecked) => this.props.onSaveForm({ ...media, type: inputChecked ? 'symbol' : 'image' })}
                        disabled={media.references && media.references.length > 0}
                        data-qa="media-type-toggle"
                      />
                    </div>
                  )}
                <Description
                  media={media}
                  onEdit={this.props.onEdit}
                  isEditing={isEditing}
                  onFormSubmit={this.props.onSaveForm}
                  onFormCancel={this.props.onHideForm}
                />
              </div>
            </div>
            <div className="side-slidein-list-container-outer refs-container">
              <Usages media={media} onLinkClick={this.linkToReference} isFetching={isFetching} />
            </div>
            <div className="side-slidein-list-container-outer sources-container">
              <Sources
                media={media}
                onRemoveSource={this.removeSource}
                onDownloadSource={this.downloadSource}
                onUploadSource={this.addSource}
                isUploadingSource={isUploadingSource}
                isFetching={isFetching}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
