import * as React from 'react';
import EditorStore from '../../../../flux/editor/EditorStore';
import EditorModes from '../../../../flux/editor/EditorModes';
import { PostUnitRenderActionType } from '../../../../flux/editor/PostUnitRenderActions';
import ResizeHandle from '../ResizeHandle';

import TocContainer from './toc/TocContainer';
import Tasks from './changetasks/Tasks';
import DocumentLinksContainer from './documentlinks/DocumentLinksContainer';
import FindReplaceContainer from './findreplace/FindReplaceContainer';
import IconButton from 'material-ui/IconButton';
import { IProject, IEditorStoreEvent, EventStoreEventType } from 'mm-types';
import InvalidUnitsContainer from './invalidUnits/InvalidUnitsContainer';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import useListenToStore from '../../../hooks/useListenToStore';

const _MIN_CONTAINER_SIZE = 320;
const _MAX_CONTAINER_SIZE = 600;
let _subActionsContainerEl: HTMLElement | null = null;

export type Props = {
  onTocSelected?: () => void;
  project: IProject | null;
};

const MainActionsContainer = (props: Props) => {
  const [activeTab, setActiveTab] = React.useState<string>('toc');
  const [viewState, setViewState] = React.useState<string>('opened');

  useListenToStore({ store: EditorStore, eventListener: _onEditStoreUpdate });
  React.useEffect(() => {
    // revert to *real* DOM for this
    _subActionsContainerEl = document.querySelector('.editing-actions-container')! as HTMLElement;
  }, []);

  function _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'initEditor') {
      _handleTab('toc');
    } else if (e.type === 'postUnitRender') {
      const castEvent = e as IEditorStoreEvent<'postUnitRender'>;
      const query: Partial<{ action: PostUnitRenderActionType }> = castEvent.data! || {};
      if (query.action === 'OPEN_CHANGETASKS') {
        _handleTab('changetasks');
      }
    } else if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;
      const modeDefaultTab = EditorModes.getProperties(castEvent.data!.to!).getActivatedMenuTabSelections().main;

      if (modeDefaultTab) {
        _handleTab(modeDefaultTab, undefined, true);
      } else {
        const deactivatedModeTab = EditorModes.getProperties(castEvent.data!.from!).getDeactivatedMenuTabSelections().main;
        if (deactivatedModeTab) {
          _handleTab(deactivatedModeTab, undefined, true);
        }
      }
    }
  }

  function _handleTab(tabId: string, onTabbed?: () => void, force = false) {
    if (EditorStore.doesModeAllow(EditorModes.attributes.mainmenuTabs) || force) {
      setActiveTab(tabId);
      onTabbed && onTabbed();
    }
  }

  function isClosed() {
    return viewState === 'closed';
  }

  function toggleSidebar() {
    setViewState(viewState === 'opened' ? 'closed' : 'opened');
  }

  function getResizeHandle() {
    return (
      <ResizeHandle
        name="lhs"
        containerEl={_subActionsContainerEl!}
        isContainerOpened={viewState === 'opened'}
        minContainerSize={_MIN_CONTAINER_SIZE}
        maxContainerSize={_MAX_CONTAINER_SIZE}
        isLeftAttached={true}
      />
    );
  }

  return (
    <div className={`col s2 side-container editing-actions-container ${viewState}`}>
      <div className="row editing-actions-tabs">
        <div className="col s12">
          <ul className="tabs icon-action-buttons ieTooltip">
            <li id="lhs-tab-toc" className={`tab col s2 ${activeTab === 'toc' ? ' active' : ''}`} onClick={() => _handleTab('toc')}>
              <IconButton
                iconClassName="material-icons"
                tooltipPosition="top-center"
                tooltipStyles={{ zIndex: 9999, top: '0' }}
                tooltip="Table of Contents"
              >
                toc
              </IconButton>
            </li>

            <li
              id="lhs-tab-search"
              className={`tab editOnly col s2 ${activeTab === 'search' ? ' active' : ''}`}
              onClick={() => _handleTab('search')}
            >
              <IconButton
                iconClassName="material-icons"
                tooltipPosition="top-center"
                tooltipStyles={{ zIndex: 9999, top: '0' }}
                tooltip="Search Document"
              >
                search
              </IconButton>
            </li>

            <li
              id="lhs-tab-links"
              className={`tab editOnly col s2 ${activeTab === 'link' ? ' active' : ''}`}
              onClick={() => _handleTab('link')}
            >
              <IconButton
                iconClassName="material-icons"
                tooltipStyles={{ zIndex: 9999, top: '0' }}
                tooltipPosition="top-center"
                tooltip="Link"
              >
                link
              </IconButton>
            </li>

            <li
              id="lhs-tab-tasks"
              className={`tab editOnly col s2 ${activeTab === 'changetasks' ? ' active' : ''}`}
              onClick={() => _handleTab('changetasks')}
            >
              <IconButton iconClassName="material-icons" tooltipPosition="top-center" tooltipStyles={{ top: '0' }} tooltip="Tasks">
                assignment_turned_in
              </IconButton>
            </li>

            {ProjectDefinitionStore.showInvalidUnitsPanel() ? (
              <li
                id="lhs-tab-invalid-units"
                className={`tab editOnly col s2 ${activeTab === 'invalid-units' ? ' active' : ''}`}
                onClick={() => _handleTab('invalid-units')}
              >
                <IconButton
                  iconClassName="material-icons"
                  tooltipPosition="top-center"
                  tooltipStyles={{ top: '0' }}
                  tooltip="Invalid Units"
                >
                  feedback
                </IconButton>
              </li>
            ) : undefined}
          </ul>
        </div>

        {activeTab === 'toc' ? (
          <div className="col s12 side-container-content">
            <TocContainer project={props.project} onToggleSidebar={() => toggleSidebar()} />
          </div>
        ) : undefined}
        {activeTab === 'search' ? (
          <div className="col s12 side-container-content">
            <FindReplaceContainer />
          </div>
        ) : undefined}
        {activeTab === 'link' ? (
          <div className="col s12 side-container-content">
            <DocumentLinksContainer />
          </div>
        ) : undefined}
        {activeTab === 'invalid-units' ? (
          <div className="col s12 side-container-content">
            <InvalidUnitsContainer />
          </div>
        ) : undefined}

        {activeTab === 'changetasks' ? (
          <div className="col s12 side-container-content">
            <Tasks />
          </div>
        ) : undefined}

        {activeTab !== 'toc' || isClosed() ? (
          <div className="col s12 side-container-content-footer">
            <i onClick={() => toggleSidebar()} className="tiny material-icons mirrored">
              slideshow
            </i>
          </div>
        ) : undefined}
      </div>
      {getResizeHandle()}
    </div>
  );
};

export default MainActionsContainer;
