import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Header',
  inlineOptions: {
    readonly: true
  },
  nonSelectable: true,
  template: ''
};
