import * as React from 'react';
import { CSSProperties } from 'react';
import { isFeatureOn } from '../../featureSwitch/featureSwitchUtils';
import { Features } from 'mm-types';

export type ItemType = {
  label: string;
  container: React.ReactElement;
  dataqa?: string;
  featureFlag?: keyof Features;
};

interface Props {
  items: ItemType[];
  style?: React.CSSProperties;
  lhsStyle?: React.CSSProperties;
  rhsStyle?: React.CSSProperties;
}

export type State = {
  selectedItem: ItemType;
};

function menuItemStyle(isLast: boolean, isSelected: boolean): CSSProperties {
  return {
    padding: '20px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    borderBottom: isLast ? 'none' : '1px solid #d4d4d4',
    backgroundColor: isSelected ? '#3aadde' : 'white'
  };
}

function menuItemIconStyle(isSelected: boolean): CSSProperties {
  return {
    color: isSelected ? '#fff !important' : '#000',
    fontSize: '17px'
  };
}

function menuItemTitleStyle(isSelected: boolean): CSSProperties {
  return {
    fontSize: '16px',
    marginLeft: '15px',
    flexGrow: 1,
    color: isSelected ? '#fff' : '#101010'
  };
}

function ContainerWithSubList(props: Props) {
  const [state, setState] = React.useState<State>({
    selectedItem: props.items && props.items[0]
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        ...props.style
      }}
    >
      <div
        style={{
          ...props.lhsStyle,
          width: '400px',
          border: '1px solid #d4d4d4'
        }}
      >
        {props.items &&
          props.items.map((item, index) => {
            if (item.featureFlag && !isFeatureOn(item.featureFlag!)) {
              return undefined;
            }

            const isSelected: boolean = state.selectedItem && item.label === state.selectedItem.label;

            return (
              <div
                key={index}
                style={menuItemStyle(index === props.items.length - 1, isSelected)}
                data-qa={item.dataqa}
                className={`container-with-sublist__menu-item ${isSelected ? ' container-with-sublist__menu-item--selected' : ''}`}
                onClick={() => setState({ ...state, selectedItem: item })}
              >
                <i className="material-icons icon-reports" style={menuItemIconStyle(isSelected)} />
                <span style={menuItemTitleStyle(isSelected)}>{item.label}</span>
                {isSelected && <i className="material-icons icon-right-open-big" style={menuItemIconStyle(isSelected)} />}
              </div>
            );
          })}
      </div>

      <div style={{ ...props.rhsStyle, flexGrow: 1, marginLeft: '30px' }}>{state.selectedItem && state.selectedItem.container}</div>
    </div>
  );
}

export default ContainerWithSubList;
