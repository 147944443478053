import React from 'react';
import { FontIcon } from 'material-ui';

interface CloseIconProps {
  onClick?: (event: React.MouseEvent<{}, MouseEvent>) => void;
  style?: React.CSSProperties;
}

const DEFAULT_COLOR = '#d4d4d4';
const HOVER_COLOR = '#000000';

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  iconClose: {
    fontSize: '1rem',
    cursor: 'pointer'
  }
};

const CloseIcon = ({ onClick, style }: CloseIconProps) => {
  return (
    <FontIcon
      className="icon-close"
      style={{ ...defaultStyles.iconClose, ...style }}
      color={DEFAULT_COLOR}
      hoverColor={HOVER_COLOR}
      onClick={onClick}
    />
  );
};

export default CloseIcon;
