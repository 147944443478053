import * as React from 'react';
import * as _ from 'lodash';
import Input from './Input';
import Panel from './Panel';
import * as key from '../../misc/keyboard';
import { ITag } from 'mm-types';

export type Props = {
  placeholder: string;
  caption?: string;
  valueField?: string;
  type: string;
  showTags?: ITag[];
  searchTags?: ITag[];
  allowAdd?: boolean;
  allowDelete?: boolean;
  allowCreate?: boolean;
  onChange?: (newTags: ITag[]) => void;
  onSearch?: (val: string) => void;
};

export type State = {
  value: string;
  selection: { item: number };
  searchTags: ITag[];
  focused: boolean;
};

export default class TagField extends React.Component<Props, State> {
  private defaultSelectionIndex: number;
  private timeout: number | undefined;

  constructor(props: Props) {
    super(props);
    this.defaultSelectionIndex = -1;

    if (!this.props.allowAdd) {
      this.defaultSelectionIndex = 0;
    }
    this.state = {
      value: '',
      selection: { item: this.defaultSelectionIndex },
      searchTags: [],
      focused: false
    };
  }

  static defaultProps: Partial<Props> = {
    allowAdd: true,
    allowDelete: true,
    allowCreate: true,
    valueField: 'value',
    showTags: []
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchTags) {
      this.setState({ searchTags: nextProps.searchTags.filter(this.filterAlreadySelected(this.props.showTags)) });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  filterAlreadySelected(existingTags) {
    const valueField = this.props.valueField!;
    return function (option) {
      if (_.findIndex(existingTags, { [valueField]: option[valueField] }) > -1) {
        return false;
      } else {
        return true;
      }
    };
  }

  openPanel() {
    this.setState({ focused: true }, () => {
      (this.refs.input as Input).focusInput();
    });
  }

  closePanel() {
    // Prevent the panel from hiding before the click action takes place
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.timeout = undefined;
      this.setState({ focused: false });
    }, 150);
  }

  onValueChange(e) {
    const value = e.target.value;

    if (this.props.onSearch) {
      this.props.onSearch(value);
    }

    this.setState({
      value,
      focused: value.trim().length > 0 || !isNaN(Number(value.trim())),
      selection: { item: this.defaultSelectionIndex }
    });
  }

  onTagDeleted(i) {
    const newTags = this.props.showTags!.slice();
    newTags.splice(i, 1);

    if (this.props.onChange) {
      this.props.onChange(newTags);
    }
  }

  onAdd(newTag: any) {
    if (_.find(this.props.searchTags, { [this.props.valueField!]: newTag[this.props.valueField!] }) || this.props.allowCreate) {
      newTag.type = this.props.type;
      const newTags = this.props.showTags!.concat([newTag]);
      this.setState({
        value: '',
        focused: true
      });
      (this.refs.input as Input).focusInput();

      if (this.props.onChange) {
        this.props.onChange(newTags);
      }
    }
  }

  onBlur() {
    // Let onClick event of the Panel to be handled first, then close it by setting focused to false
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.timeout = undefined;
      this.setState({ focused: false });
    }, 150);
  }

  onKeyDown(e: { keyCode: number }) {
    switch (e.keyCode) {
      case key.ENTER:
        if (this.state.selection.item > -1 && this.state.searchTags[this.state.selection.item]) {
          this.onAdd(this.state.searchTags[this.state.selection.item]);
        } else if (this.props.allowAdd) {
          this.onAdd({ [this.props.valueField!]: this.state.value });
        }
        break;
      case key.UP:
        this.handleArrowUp();
        break;
      case key.DOWN:
        this.handleArrowDown();
        break;
      case key.BACKSPACE:
        this.handleBackspace(e);
        break;
      case key.ESCAPE:
        this.handleEscape();
        break;
    }
  }

  handleArrowUp() {
    const result = this.state.selection.item - 1;
    this.setState({
      selection: {
        item: result > 0 ? result : 0
      }
    });
  }

  handleArrowDown() {
    const result = this.state.selection.item + 1;
    this.setState({
      selection: {
        item: result < this.state.searchTags.length ? result : this.state.searchTags.length - 1
      }
    });
  }

  handleBackspace(e) {
    if (this.state.value.trim().length === 0) {
      e.preventDefault();
      this.onTagDeleted(this.props.showTags!.length - 1);
    }
  }

  handleEscape() {
    this.setState({
      value: '',
      selection: { item: -1 },
      focused: false
    });
  }

  blockEvent(e) {
    e.preventDefault();
  }

  _getPlaceholder() {
    return this.props.showTags && this.props.showTags.length > 0 ? '' : this.props.placeholder;
  }

  render() {
    return (
      <div className="tf__root">
        {this.props.caption ? <div className="tf_caption">Content Tags:</div> : ''}
        <Input
          ref="input"
          placeholder={this._getPlaceholder()}
          value={this.state.value}
          valueField={this.props.valueField!}
          focused={this.state.focused}
          showTags={this.props.showTags!}
          openPanel={() => this.openPanel()}
          closePanel={() => this.closePanel()}
          onValueChange={(val) => this.onValueChange(val)}
          onTagDeleted={(tag) => this.onTagDeleted(tag)}
          onKeyDown={(e) => this.onKeyDown(e)}
          onBlur={(e) => this.onBlur()}
          allowAdd={this.props.allowAdd!}
          allowDelete={this.props.allowDelete!}
          allowCreate={this.props.allowCreate!}
        />
        {this.state.focused && this.state.value.length > 0 ? (
          <Panel
            searchTags={this.state.searchTags}
            valueField={this.props.valueField!}
            selection={this.state.selection}
            onAdd={(text) => this.onAdd(text)}
            input={this.state.value}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
