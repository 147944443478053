import * as React from 'react';
import * as _ from 'lodash';
import SmartContentStore, { SmartContentStoreEvent } from '../../../../../flux/editor/SmartContentStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { FlatButton, FontIcon } from 'material-ui';
import ValidatableTextInput from '../../../../general/ValidatableTextInput';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import ComplianceTagUsages from './ComplianceTagUsages';
import { IRegulation, IComplianceTag } from 'mm-types';

export type Props = {
  regulation: Partial<IRegulation>;
  onMenuAction?: (action: string, regulation: Partial<IRegulation>) => void;
  onFormAction: (type: 'save' | 'cancel', regulation: Partial<IRegulation>) => void;
  onSelect?: (regulationUid: string) => void;
  isReadOnly?: boolean;
  open: boolean;
  selected: boolean;
  buttonLabel?: null | string;
};

export type State = {
  formContent: Partial<IRegulation>;
  errors: {
    type: null | string;
    subType: null | string;
    reference: null | string;
  };
  usages: IComplianceTag[];
};

export default class RegItem extends React.Component<Props, State> {
  private unsub: Function;

  constructor(props: Props) {
    super(props);
    this.unsub = SmartContentStore.listen(this._onStoreChange, this);

    this.state = {
      formContent: {},
      errors: {
        type: null,
        subType: null,
        reference: null
      },
      usages: []
    };
  }

  static defaultProps: Partial<Props> = {
    regulation: SmartContentStore.getEmptyRegulation(),
    isReadOnly: false,
    open: false,
    selected: false,
    buttonLabel: null
  };

  componentWillUnmount() {
    this.unsub();
  }

  UNSAFE_componentWillMount() {
    this._createContentState();
  }

  _getValue(e: any, selectValue: any) {
    if (e !== null && typeof e === 'object') {
      return e.target.value || e.target.value === '' ? e.target.value : selectValue;
    } else {
      return e;
    }
  }

  updateField(obj: string, name: string, e: any, index?: number, selectValue?: any) {
    const stateObj: any = {};
    stateObj[obj] = _.clone(this.state[obj]);

    if (name.indexOf('.') !== -1) {
      // allow dot notation in name

      const objPath = name.split('.');
      let stateObjPath = stateObj[obj];

      objPath.forEach((n, inx) => {
        stateObjPath = stateObjPath[n];

        if (inx === objPath.length - 2) {
          stateObjPath[objPath[inx + 1]] = this._getValue(e, selectValue);
        }
      });
    } else {
      stateObj[obj][name] = this._getValue(e, selectValue);
    }

    if (stateObj.hasOwnProperty('dirty')) {
      stateObj.dirty = true;
    }

    this.setState(stateObj);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this._createContentState(nextProps.regulation);
    if ((!this.props.selected && nextProps.selected) || (this.props.open && nextProps.selected && !nextProps.open)) {
      this._retrieveUsages(nextProps);
    }
  }

  _isReadOnly() {
    return !this.props.regulation.isProjectAuthor || this.props.isReadOnly;
  }

  _canSubmit() {
    // if user is tagging: allow "save" (backend won't update contents, but tag will occur)
    if (EditorStore.isMode('REGULATIONSELECTION')) {
      return true;
    } else {
      return !this._isReadOnly();
    }
  }

  _onStoreChange(event: SmartContentStoreEvent) {
    if (event.type === 'retrieveUnitComplianceTagMap') {
      this._retrieveUsages(this.props);
    }
  }

  _retrieveUsages(props: Props) {
    if (props.selected && !props.open) {
      SmartContentStore.retrieveComplianceTags(this.props.regulation.uid!, EditorStore.getDocParams()!.indexUid!).then(
        (usages: IComplianceTag[]) => {
          this.setState({ usages: usages });
        }
      );
    }
  }

  _deleteUsageGroup(usage: Partial<IComplianceTag>) {
    SmartContentStore.removeRegulationUsageGroup(usage, EditorStore.getDocParams()!.indexUid!);
  }

  _select(force?: boolean) {
    if (!this.props.open || force) {
      if (this.props.onSelect) {
        this.props.onSelect(this.props.regulation.uid!);
      }
    }
  }

  _isMenuVisible() {
    return !this._isNew() && !this.props.open && !EditorStore.isMode('REGULATIONSELECTION');
  }

  _getClassNames() {
    return 'smart-content-item ' + (this.props.open ? 'opened' : '') + (this.props.selected ? ' selected' : '');
  }

  _getButtonLabel() {
    if (this._isNew()) {
      return EditorStore.isMode('REGULATIONSELECTION') ? 'Save & Tag' : 'Save';
    } else {
      return EditorStore.isMode('REGULATIONSELECTION') ? 'Update & Tag' : 'Update';
    }
  }

  _getRegName() {
    const typeModified = this.state.formContent.type ? this.state.formContent.type + '-' : '-';
    const typeValues = _.reduce(_.compact([typeModified, this.state.formContent.subType]), (name, value) => name + value);
    const refValues = _.reduce(
      _.compact([this.state.formContent.reference, this.state.formContent.subReference]),
      (name, value) => name + ' ' + value
    );

    return typeValues + ' ' + (refValues ? refValues : '');
  }

  _createContentState(regulation: Partial<IRegulation> | null = null) {
    this.setState({ formContent: _.cloneDeep(regulation ? regulation : this.props.regulation) });
  }

  _isNew() {
    return _.isUndefined(this.props.regulation.uid);
  }

  _isFieldUserModified(field: string) {
    if (this._isReadOnly()) {
      return false; // don;t bother showing this info for readonly view
    } else if (this.props.regulation.original) {
      // new items have no original
      return this.props.regulation.original[field] !== this.state.formContent[field];
    } else {
      return false;
    }
  }

  _revertField(field: string) {
    this.setState({ ...this.state, formContent: { ...this.state.formContent, [field]: this.props.regulation.original![field] } });
  }

  _handleDropDownAction(action: string) {
    if (this.props.onMenuAction) {
      this.props.onMenuAction(action, this.props.regulation);
    }
  }

  _save() {
    const errors = _.cloneDeep(this.state.errors);
    _.keys(errors).forEach((field) => {
      const mandatoryFieldVal = _.trim(this.state.formContent[field]);
      errors[field] = mandatoryFieldVal.length === 0 ? 'Required field' : null;
    });

    if (_.values(errors).filter((e) => e !== null).length === 0) {
      this.props.onFormAction('save', this.state.formContent);
    } else {
      this.setState({ errors: errors });
    }
  }

  render() {
    return (
      <li
        id={'itemuid_' + this.props.regulation.uid}
        className={this._getClassNames()}
        onClick={() => {
          this._select();
        }}
      >
        <div className="smart-content-header">
          {this._isMenuVisible() ? (
            <DropDownIcon
              icon="more_horiz"
              className="smart-content-dropdown-menu"
              containerClassName="smart-content-dropdown-menu-container-inner"
              onSelect={(e) => {
                this._handleDropDownAction(e);
              }}
            >
              <DropDownOption show={!this.props.isReadOnly} value="tagSelectedUnits">
                Tag selected element(s)
              </DropDownOption>
              <DropDownOption show={!this.props.isReadOnly} value="edit">
                Edit regulation
              </DropDownOption>
              <DropDownOption show={this.props.isReadOnly} value="edit">
                View regulation
              </DropDownOption>
            </DropDownIcon>
          ) : undefined}

          <div className="form-container">
            <div className="textfieldro name">{this._getRegName()}</div>
          </div>

          <div className="form-container">
            {this.props.open && !this._isReadOnly() ? (
              <ValidatableTextInput
                inputId="reg-description"
                fullWidth={true}
                className="textfield"
                value={this.state.formContent.description}
                maxChars={256}
                multiLine={false}
                hintText="Description"
                onChange={(e) => this.updateField('formContent', 'description', e)}
              />
            ) : undefined}
            {!(this.props.open && !this._isReadOnly()) ? (
              <div className="textfieldro">{this.state.formContent.description}</div>
            ) : undefined}
            {!this._isNew() && !this.props.open ? (
              <div className="usage-count">{this.props.regulation.compactedUsageCount}</div>
            ) : undefined}
          </div>
        </div>

        <div className="icon-tag">
          <div className="icon-container elements icon-display-options tiny material-icons regulation">verified_user</div>
          <div className="info">Regulation</div>
        </div>

        {this.props.open ? (
          <div className="smart-content-form-body">
            <div className="smart-content-form-body-inner">
              <hr />
              <div className="form-container">
                {this.props.open ? (
                  <ValidatableTextInput
                    inputId="reg-type"
                    fullWidth={true}
                    className={'textfield' + (this._isFieldUserModified('type') ? ' user-modified' : '')}
                    value={this.state.formContent.type}
                    maxChars={256}
                    multiLine={false}
                    floatingLabelText="TYPE"
                    disabled={this._isReadOnly()}
                    nullable={false}
                    customError={this.state.errors.type}
                    onChange={(e) => this.updateField('formContent', 'type', e)}
                  />
                ) : undefined}

                {this._isFieldUserModified('type') ? (
                  <i
                    className="material-icons revert-user-modified"
                    onClick={() => {
                      this._revertField('type');
                    }}
                  >
                    undo
                  </i>
                ) : undefined}
              </div>

              <div className="form-container">
                {this.props.open ? (
                  <ValidatableTextInput
                    inputId="reg-subType"
                    fullWidth={true}
                    className={'textfield' + (this._isFieldUserModified('subType') ? ' user-modified' : '')}
                    value={this.state.formContent.subType}
                    maxChars={256}
                    multiLine={false}
                    floatingLabelText="SUB-TYPE"
                    disabled={this._isReadOnly()}
                    nullable={false}
                    customError={this.state.errors.subType}
                    onChange={(e) => this.updateField('formContent', 'subType', e)}
                  />
                ) : undefined}

                {this._isFieldUserModified('subType') ? (
                  <i
                    className="material-icons revert-user-modified"
                    onClick={() => {
                      this._revertField('subType');
                    }}
                  >
                    undo
                  </i>
                ) : undefined}
              </div>

              <div className="form-container">
                {this.props.open ? (
                  <ValidatableTextInput
                    inputId="reg-reference"
                    fullWidth={true}
                    className={'textfield' + (this._isFieldUserModified('reference') ? ' user-modified' : '')}
                    value={this.state.formContent.reference}
                    maxChars={256}
                    multiLine={false}
                    floatingLabelText="REFERENCE"
                    disabled={this._isReadOnly()}
                    nullable={false}
                    customError={this.state.errors.reference}
                    onChange={(e) => this.updateField('formContent', 'reference', e)}
                  />
                ) : undefined}

                {this._isFieldUserModified('reference') ? (
                  <i
                    className="material-icons revert-user-modified"
                    onClick={() => {
                      this._revertField('reference');
                    }}
                  >
                    undo
                  </i>
                ) : undefined}
              </div>

              <div className="form-container">
                {this.props.open ? (
                  <ValidatableTextInput
                    inputId="reg-subReference"
                    fullWidth={true}
                    className={'textfield' + (this._isFieldUserModified('subReference') ? ' user-modified' : '')}
                    value={this.state.formContent.subReference}
                    maxChars={256}
                    multiLine={false}
                    floatingLabelText="SUB-REFERENCE"
                    disabled={this._isReadOnly()}
                    onChange={(e) => this.updateField('formContent', 'subReference', e)}
                  />
                ) : undefined}

                {this._isFieldUserModified('subReference') ? (
                  <i
                    className="material-icons revert-user-modified"
                    onClick={() => {
                      this._revertField('subReference');
                    }}
                  >
                    undo
                  </i>
                ) : undefined}
              </div>

              <div className="form-buttons">
                <FlatButton
                  className="action-button reg-cancel btn-cancel"
                  label="Cancel"
                  icon={React.createElement(FontIcon, { className: 'icon-close' })}
                  labelPosition="after"
                  secondary={true}
                  onClick={() => this.props.onFormAction('cancel', this.state.formContent)}
                />
                {this._canSubmit() ? (
                  <FlatButton
                    label={this._getButtonLabel()}
                    className="action-button reg-save btn-save"
                    icon={React.createElement(FontIcon, { className: 'icon-tick' })}
                    labelPosition="after"
                    secondary={true}
                    onClick={() => this._save()}
                  />
                ) : undefined}
              </div>
            </div>
          </div>
        ) : undefined}

        {this.props.selected && !this.props.open ? (
          <ComplianceTagUsages
            unit={this.props.regulation}
            usages={this.state.usages}
            isReadOnly={this._isReadOnly()}
            onDeleteUsageGroup={(usage) => this._deleteUsageGroup(usage)}
          />
        ) : undefined}
      </li>
    );
  }
}
