import * as React from 'react';
import { ITocNode } from 'mm-types';
import { Divider, Checkbox } from 'material-ui';
import { ForeignEntryValues } from '../..';

export interface ForeignEntryFormProps {
  tocs: ITocNode[];
  selectedTocs: ForeignEntryValues;
  onCheck(uid: string, checked: boolean);
}

interface SelectedObject {
  [prop: string]: true;
}

function mapToObject(selected: ForeignEntryValues): SelectedObject {
  const result = {};
  for (let i = 0, max = selected.length; i < max; i++) {
    result[selected[i].uid] = true;
  }
  return result;
}

export function ForeignEntryForm({ onCheck, tocs, selectedTocs = [] }: ForeignEntryFormProps) {
  const [selectedUid, setSelectedUid] = React.useState<SelectedObject>(mapToObject(selectedTocs));

  React.useEffect(() => {
    setSelectedUid(mapToObject(selectedTocs));
  }, [selectedTocs]);

  return (
    <div className="position-relative">
      <div className="inner-content">
        <ul>
          {tocs.map((toc) => {
            return (
              <li key={toc.uid}>
                <div className="padding-m" data-qa="checkbox-container">
                  <Checkbox
                    label={toc.ordinal + ' ' + toc.heading}
                    labelStyle={{ fontSize: 14 }}
                    iconStyle={{ transform: 'scale(.95)' }}
                    onCheck={(e, checked) => onCheck(toc.uid, checked)}
                    checked={!!selectedUid[toc.uid]}
                    data-qa="index checkbox"
                    data-qa-checked={'' + !!selectedUid[toc.uid]}
                  />
                </div>
                <Divider />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
