import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import { IShortcutMenuItem } from 'mm-types';

interface Props {
  handleInsertClick(e: React.MouseEvent<HTMLElement>, action: string): void;
  menuItem: IShortcutMenuItem;
  disabled: boolean;
  menuItemIndex: number;
  allowInsertAfterSelected: boolean;
}

const ShortcutIcon = (props: Props) => {
  return (
    <li
      key={props.menuItemIndex}
      id={'menu-insert-btn-' + props.menuItem.id}
      onClick={(e) => props.handleInsertClick(e, props.menuItem.action)}
      className={props.disabled ? 'disabled' : ''}
    >
      <IconButton disabled={props.disabled} iconClassName="material-icons" tooltipPosition="bottom-center" tooltip={props.menuItem.tooltip}>
        {props.menuItem.icon}
      </IconButton>
    </li>
  );
};

export default React.memo(ShortcutIcon);
