import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

export type Props = {};

export type State = {};

export default class LinkSpinner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="link-loading-modal">
        <div className="link-loading-modal-content">
          <CircularProgress mode="indeterminate" size={17.85} />
        </div>
      </div>
    );
  }
}
