import * as React from 'react';
import TextField from 'material-ui/TextField';
import FontIcon from 'material-ui/FontIcon';
import LinkSpinner from './LinkSpinner';
import EditorStore from '../../../../../flux/editor/EditorStore';
import linkService from '../../../../editor/links/generic/linkService';
import LinkStore, { LinkStoreEvent } from '../../../../../flux/editor/LinkStore';
import DocumentLinkFilterPopup, { FilterOptions } from './DocumentLinkFilterPopup';
import { FlatListType } from '../../../../../flux/editor/LinkStore';
export type Props = {};

export type State = {
  searchTxt: string;
  filterOptions: FilterOptions;
  links?: FlatListType[];
  loading?: boolean;
};

export default class DocumentLinksContainer extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchTxt: '',
      filterOptions: {
        allTypes: true
      },
      links: []
    };

    this.unsubscribe = LinkStore.listen(this.onLinkStoreUpdate, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onLinkStoreUpdate(status: LinkStoreEvent) {
    this.setState({
      links: status.flatList,
      loading: status.loading
    });
  }

  _handleInputChange(e: React.FormEvent<{}>) {
    this.setState({ searchTxt: (e.target as HTMLInputElement).value });
  }

  componentDidMount() {
    const { flatList, loading } = LinkStore.getState();
    this.setState({
      links: flatList,
      loading: loading
    });
  }

  renderImageLink(l: FlatListType) {
    if (l.direction === 'OUTBOUND') {
      return <FontIcon className="material-icons">link</FontIcon>;
    } else {
      return <FontIcon className="material-icons icon-inbound-link" />;
    }
  }

  filteredLinks(): FlatListType[] {
    if (!this.state.links) {
      return [];
    }

    const search = new RegExp(this.state.searchTxt, 'gi');
    let results = this.state.links.filter((l) => (l.text + l.type).match(search));
    if (!this.state.filterOptions.allTypes) {
      results = results.filter((l) => {
        return (
          (this.state.filterOptions.outbound && this.state.filterOptions.inbound) ||
          (!this.state.filterOptions.outbound && !this.state.filterOptions.inbound) ||
          (this.state.filterOptions.outbound && l.direction === 'OUTBOUND') ||
          (this.state.filterOptions.inbound && l.direction === 'INBOUND')
        );
      });

      results = results.filter((l) => {
        return (
          (this.state.filterOptions.internal && this.state.filterOptions.external) ||
          (!this.state.filterOptions.internal && !this.state.filterOptions.external) ||
          (this.state.filterOptions.internal && l.type === 'INTERNAL') ||
          (this.state.filterOptions.external && (l.type === 'EXTERNAL' || l.type === 'INTER_DOC'))
        );
      });

      if (this.state.filterOptions.invalid) {
        results = results.filter((l) => l.broken);
      }
    }
    return results;
  }

  onClick(l) {
    EditorStore.openDocumentLink(l.unitUid, l.nid, true);
  }

  humanLinkType(l) {
    if (l.type === 'EXTERNAL') {
      switch (linkService.identifyWebLink(l.externalLink)) {
        case 'http':
          return 'Web link';
        case 'tel':
          return 'Telephone link';
        case 'sms':
          return 'Sms link';
        case 'mail':
          return 'Email link';
      }
    } else if (l.type === 'INTERNAL') {
      return 'Internal';
    } else {
      return 'External';
    }
  }

  _setFilterOptions(filterOptions) {
    this.setState({ filterOptions });
  }

  _renderLinkList() {
    if (this.state.loading) {
      return <LinkSpinner />;
    } else {
      return this.filteredLinks().map((l, i) => {
        return (
          <div key={i} className={'link ' + (l.broken ? 'broken' : '')} onClick={(e) => this.onClick(l)}>
            <div className="link-image">{this.renderImageLink(l)}</div>
            <div className="link-body">
              <div className="link-title" dangerouslySetInnerHTML={{ __html: l.text ? l.text : l.linkAnchorTitle ?? '' }} />
              <div className="link-text">{this.humanLinkType(l)}</div>
            </div>
          </div>
        );
      });
    }
  }

  render() {
    const invalidLinkCount = this.state.links ? this.state.links.filter((l) => l.broken).length : 0;

    return (
      <div className="doc-links-container">
        <h1>Links</h1>

        <div className="filter-box">
          <div>Document Links</div>
          <DocumentLinkFilterPopup
            filterOptions={this.state.filterOptions}
            setFilterOptions={(options) => this._setFilterOptions(options)}
          />
        </div>

        <div className="search-box">
          <TextField
            style={{ width: '280px' }}
            hintText="Search"
            id="search-box-input"
            value={this.state.searchTxt}
            onChange={(e) => this._handleInputChange(e)}
          />
        </div>

        <div className="validate-box">
          <div className={'invalid-count ' + (invalidLinkCount === 0 ? 'blue-count' : 'red-count')}>
            Invalid links <span className={'counter'}>{invalidLinkCount}</span>
          </div>
        </div>

        <div className="link-list">{this._renderLinkList()}</div>
      </div>
    );
  }
}
