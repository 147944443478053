import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { ISpecialInterestGroup, IComplianceTag } from 'mm-types';

export type Props = {
  unit: Partial<ISpecialInterestGroup>;
  isReadOnly?: boolean;
  usages?: Partial<IComplianceTag>[];
  onDeleteUsageGroup?: (usage: Partial<IComplianceTag>) => void;
};

export type State = {};

export default class ComplianceTagUsages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    usages: [],
    isReadOnly: false
  };

  _openInDoc(unitUid: string) {
    EditorStore.openDocumentLink(unitUid);
  }

  _deleteUsageGroup(e: React.MouseEvent<{}>, usage: Partial<IComplianceTag>) {
    if (this.props.onDeleteUsageGroup) {
      this.props.onDeleteUsageGroup(usage);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <div className="activity-subaction subaction-list-container">
        <ul className="activity-list">
          {this.props.usages!.map((usage, index) => {
            return (
              <li key={index} onClick={() => this._openInDoc(usage.unit!.uid!)}>
                <div className="activity-item">
                  <div className="activity-item-name">{usage.unit!.type}</div>
                  <div className="activity-item-time">{usage.unit!.timestampFormatted}</div>
                </div>
                {usage.link ? (
                  <div className="activity-item">
                    <div className="activity-item-description">
                      {usage!.link!.tocableUnitOrdinal} {usage.link.tocableUnitHeading}
                    </div>
                    <div className="activity-item-user">
                      <IconButton
                        className="delete-reg-usage-group"
                        iconClassName="material-icons"
                        onClick={(e) => this._deleteUsageGroup(e, usage)}
                      >
                        delete
                      </IconButton>
                    </div>
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
