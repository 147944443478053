import ServerSettingsStore from '../../common/ServerSettingsStore';
import ProjectDefinitionStore from '../../common/ProjectDefinitionStore';

export type MediaUploadValidationError = {
  type: 'video' | 'image' | null;
  value: number | string | null;
};

export class MediaUploadUtil {
  private acceptedMedia: string;
  private maxVideoSizeMB: number;
  private maxImageSizeMB: number;

  constructor() {
    const serverSettings = ServerSettingsStore.getServerSettings();
    this.acceptedMedia = ProjectDefinitionStore.getSupportedMediaTypesForIndex();
    this.maxVideoSizeMB = serverSettings.maxVideoSizeMB || 50;
    this.maxImageSizeMB = serverSettings.maxImageSizeMB || 10;
  }

  validate(files: File[]): null | MediaUploadValidationError[] {
    const errors: MediaUploadValidationError[] = [];
    files.forEach((file) => {
      const fileSizeMB = this.getFileSizeMB(file);

      if (this.isVideo(file) && fileSizeMB > this.maxVideoSizeMB) {
        errors.push({
          type: 'video',
          value: this.maxVideoSizeMB
        });
        return;
      }
      if (this.isImage(file) && fileSizeMB > this.maxImageSizeMB) {
        errors.push({
          type: 'image',
          value: this.maxImageSizeMB
        });
      } else if (this.isNotAcceptedMedia(file)) {
        errors.push({
          type: null,
          value: file.type
        });
        return;
      }
    });
    return errors.length ? errors : null;
  }

  private isVideo(file: File): boolean {
    return file.type.includes('video/') || file.type.includes('mp4');
  }

  private isImage(file: File): boolean {
    return file.type.includes('image/');
  }

  private isNotAcceptedMedia(file: File): boolean {
    return this.acceptedMedia.indexOf(file.type) === -1;
  }

  private getFileSizeMB(file: File): number {
    return file.size / 1048576;
  }
}
