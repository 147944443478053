import { IDocUnitProfile } from 'mm-types';
import AdmonitionEditProps from '../../../../sidetabs/sub/editComponent/components/AdmonitionEditProps';
import { admonitionMenuOverrides, admonitionMenuSelectedOverrides } from '../../const/profileMenuOverrides';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Warning',
  getLabel: () => 'WARN',
  identifyingClasses: ['arc-warning', 'arc-warning-body'],
  outerClass: 'arc-warning',
  editPropsComponent: AdmonitionEditProps,
  template: '',

  arcMLDefaults: {
    root: { top: 1, right: 0, bottom: 1, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  menu: admonitionMenuOverrides,
  menuTextSelected: admonitionMenuSelectedOverrides,

  editor: {},

  blockOverridesFromParent: true
};
