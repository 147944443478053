import * as React from 'react';
import FindReplaceStore, { Match, Word, FindReplaceStoreEvent } from '../../../../../flux/editor/FindReplaceStore';
import TocStore from '../../../../../flux/editor/TocStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import FindInput from './FindInput';
import ReplaceInput from './ReplaceInput';
import SearchResults from './SearchResults';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import CircularProgress from 'material-ui/CircularProgress';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { SESSION_STORAGE_KEYS } from '../../../../../utils/sessionStorageKeys';

const scopes = [
  { payload: 'current', text: 'Current Unit' },
  { payload: 'global', text: 'Whole Document' }
];

export type Props = {};

export type State = {
  findWord: null | string;
  replaceWord: null;
  options: {
    preserveCase: boolean;
  };
  scope: 'current' | 'global';
  findInProgress: boolean;
  numberOfHits?: number;
  chapterMatchesCount?: number;
  globalMatches?: Match[];
  globalMatchesCount?: number;
  replacing?: boolean;
  current?: Word;
  findErrorText?: string | null;
};

export default class FindReplaceContainer extends React.Component<Props, State> {
  private unsub: Function;

  constructor(props: Props) {
    super(props);

    this.unsub = FindReplaceStore.listen(this.onFindReplaceStoreChange, this);

    this.state = {
      findWord: null,
      replaceWord: null,
      options: this.loadFindReplaceOptions(),
      scope: 'current',
      findInProgress: false
    };
  }

  onFindReplaceStoreChange(status: FindReplaceStoreEvent) {
    this.setState({
      chapterMatchesCount: FindReplaceStore.getTotalMatches(),
      globalMatches: FindReplaceStore.getGlobalMatches(),
      globalMatchesCount: FindReplaceStore.getGlobalMatchesCount(),
      replacing: FindReplaceStore.isReplacing(),
      numberOfHits: FindReplaceStore.getTotalMatches(),
      current: FindReplaceStore.currentWord()!,
      findInProgress: FindReplaceStore.isBusy()
    });
  }

  loadFindReplaceOptions() {
    try {
      const options = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.FIND_REPLACE_OPTIONS)!);
      if (options && typeof options === 'object') {
        return options;
      }
    } catch (e) {
      console.error('Found invalid session storage for findReplaceOptions falling back to default');
    }
    return { matchCase: true, wholeWord: false, preserveCase: false };
  }

  sectionTitle() {
    const unit = TocStore.getFirstSelectedTocableUnit();
    if (unit) {
      return `${unit.ordinal ? unit.ordinal + ' ' : ''}${unit.heading}`;
    }
  }

  update(e, name) {
    this.state[name] = e.target.value;
    this.setState(this.state);
  }

  componentWillUnmount() {
    this.unsub();
    EditorStore.changeModeStart('EDITING');
  }

  UNSAFE_componentWillMount() {
    FindReplaceStore.clear();
  }

  componentDidMount() {
    EditorStore.changeModeStart('FINDREPLACE');
  }

  resetErrors() {
    this.setState({ findErrorText: null });
  }

  onFind() {
    if (this.state.findWord) {
      if (this.state.findWord.length < 3 && this.state.scope !== 'current') {
        this.setState({ findErrorText: 'Minimum 3 characters for document search' });
        return;
      }
      this.setState({ findInProgress: true }, () => {
        EditorStore.onFind(this.state.findWord!, this.state.options);
        FindReplaceStore.find(this.state.findWord!, this.state.options, this.state.scope, true);
      });
      this.resetErrors();
    }
  }

  onReplace() {
    FindReplaceStore.replaceWord(this.state.replaceWord);
    this.resetErrors();
  }

  onReplaceAll() {
    FindReplaceStore.replaceAllWord(this.state.replaceWord);
    this.resetErrors();
  }

  toggleOptions(key) {
    this.state.options[key] = !this.state.options[key];
    this.setState({ options: this.state.options }, () => {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.FIND_REPLACE_OPTIONS, JSON.stringify(this.state.options));
    });
    this.resetErrors();
  }

  getChapterName(uid: string): string {
    const element = TocStore.getTocItem(uid);
    if (element) {
      return `${element.ordinal} ${element.heading}`;
    }
    return '';
  }

  renderReplaceInput() {
    if (ProjectStore.isReadOnly()) {
      return null;
    }

    return (
      <ReplaceInput
        replaceWord={this.state.replaceWord!}
        onUpdate={(e, name) => this.update(e, name)}
        onReplace={(e) => this.onReplace()}
        onReplaceAll={(e) => this.onReplaceAll()}
        toggleOption={(e) => this.toggleOptions(e)}
        isReplacing={this.state.replacing!}
        options={this.state.options}
      />
    );
  }

  renderSearchResults() {
    if (this.state.findInProgress) {
      return (
        <div className="search-results-progress-bar">
          <CircularProgress mode="indeterminate" size={60} />
        </div>
      );
    } else if (this.state.globalMatches) {
      return (
        <SearchResults
          selected={this.state.current!}
          globalMatchesCount={this.state.globalMatchesCount!}
          globalMatches={this.state.globalMatches}
          onGlobalSearchClick={(l, indexWithinParent) =>
            FindReplaceStore.globalSearchResultSelect({ l: l, indexWithinParent: indexWithinParent })
          }
          getChapterName={(uid: string) => this.getChapterName(uid)}
        />
      );
    } else {
      return (
        <div className="search-results">
          <div className="header">
            <div className="title">Total Matches</div>
            <div className="matches">{this.state.numberOfHits}</div>
          </div>
        </div>
      );
    }
  }

  updateScope(event, index, value) {
    this.setState({ scope: value });
  }

  render() {
    const items = scopes.map((item) => {
      return <MenuItem key={item.payload} value={item.payload} primaryText={item.text} />;
    });

    return (
      <div className="find-replace-component">
        <div className="topContent">
          <h1>Find and Replace</h1>
          <h2>{this.sectionTitle()}</h2>

          <SelectField
            style={{ marginTop: '10px', fontSize: '1em' }}
            floatingLabelText="Search within"
            value={this.state.scope}
            onChange={(e, index, value) => this.updateScope(e, index, value)}
          >
            {items}
          </SelectField>
          <FindInput
            findWord={this.state.findWord!}
            onUpdate={(e, name) => this.update(e, name)}
            onFind={(e) => this.onFind()}
            options={this.state.options}
            toggleOption={(e) => this.toggleOptions(e)}
            onNext={() => FindReplaceStore.nextFoundWord()}
            onPrevious={() => FindReplaceStore.previousFoundWord()}
            errorText={this.state.findErrorText!}
            findInProgress={this.state.findInProgress}
            matches={this.state.globalMatchesCount}
          />
          {this.renderReplaceInput()}
        </div>

        <div className="clear"></div>

        {this.renderSearchResults()}
      </div>
    );
  }
}
