import * as React from 'react';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import NoProperties from '../../selectedComponent/components/NoProperties';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

const PreEditProps = (props: IGenericEditProps) => {
  const project = ProjectStore.getProject();
  if (!project) {
    return null;
  }

  if (!ProjectDefinitionStore.allowUnitIndentation()) {
    return <NoProperties />;
  } else {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        {UnitUtils.isFormatingAvailable(props.unitElement) && (
          <ToggleSection title="Pre Formatting">
            <MultiFormatting unitProfile={props.unitProfile} />
            <DataAttributesPropsWrapper {...props} />
          </ToggleSection>
        )}
      </div>
    );
  }
};

export default PreEditProps;
