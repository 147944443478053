export function parseMsnRange(msnRange: string, len: number) {
  let buildString = '';
  let counter = 0;
  msnRange
    .trim()
    .split(' ')
    .forEach((part) => {
      if (part.toUpperCase() === 'ALL') {
        buildString = 'All';
      } else if (part.length === len) {
        buildString += (counter !== 0 ? ', ' : '') + part;
      } else if (part.length === len * 2) {
        buildString += (counter !== 0 ? ', ' : '') + part.substring(0, len) + '-' + part.substring(len);
      }
      counter++;
    });
  return buildString;
}
