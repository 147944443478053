import { CSSProperties } from 'react';

// to avoid common type: { [prop: string]: React.CSSProperties } without autocomplete
// you can use these methods to create a nice typed CSS Style map / single style

export function style<T extends CSSProperties>(style: T): T {
  return style;
}

type CssRecord = Record<string, CSSProperties>;

export function styleMap<T extends Record<string, CSSProperties | CssRecord>>(styles: T): T {
  return styles;
}
