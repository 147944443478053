import { NotifStep, INotification, INotificationType } from 'mm-types';

export default class NotificationUtil {
  static isCurrentStepOfType(notif: INotification, type: NotifStep): boolean {
    const step = this.getCurrentStep(notif);
    return step ? step === type : false;
  }

  static getCurrentStep(notif: INotification): NotifStep | null {
    return notif.steps && notif.steps.length ? notif.steps[notif.steps.length - 1] : null;
  }

  static isInProgress(notif: INotification): boolean {
    return notif.status === 'IN_PROGRESS';
  }

  static isOfType(notif: INotification, type: INotificationType): boolean {
    return notif.type === type;
  }

  static isAppliedToProject(notif: INotification, projectUid: string): boolean {
    return !!notif.project && !!notif.project.uid && notif.project.uid === projectUid;
  }
}
