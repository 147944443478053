import * as React from 'react';
import { IMedia, ISource } from 'mm-types';
import AnimatedToggleSection from '../../../../misc/AnimatedToggleSection';
import { FontIcon, FlatButton } from 'material-ui';
import AreaProgress from '../../../../general/AreaProgress';
import SourceItem from './SourceItem';

interface Props {
  media: IMedia;
  isUploadingSource: boolean;
  isFetching: boolean;
  onRemoveSource(source: ISource): void;
  onDownloadSource(source?: ISource): void;
  onUploadSource(files: FileList | null): void;
}

const Sources = ({ media, onDownloadSource, onRemoveSource, onUploadSource, isUploadingSource, isFetching }: Props) => {
  return (
    <AnimatedToggleSection
      title={`SOURCE FILE (${media.sources.length || 0})`}
      subtitle="File that contains original or essential data for this media"
      id="details-sources"
    >
      <div className="position-relative">
        <ul>
          {media.sources.map((source) => (
            <SourceItem
              key={source.uid}
              filename={source.filename}
              onDownloadSource={() => {
                onDownloadSource(source);
              }}
              onDeleteSource={() => onRemoveSource(source)}
            />
          ))}
          {isUploadingSource && <SourceItem key="uploadingSource" filename="Uploading..." uploading />}
        </ul>
        <div style={{ marginLeft: 80 }}>
          <input
            type="file"
            id="sourceUpload"
            onChange={(e) => {
              onUploadSource((e.currentTarget as HTMLInputElement).files!);
            }}
            disabled={isUploadingSource}
            style={{ display: 'none' }}
          />
          <FlatButton
            className="media-src-upload"
            onClick={() => {
              $('#sourceUpload').trigger('click');
            }}
            label="Upload file"
            primary={true}
            icon={<FontIcon className="material-icons">file_upload</FontIcon>}
          />
        </div>
        <AreaProgress show={isFetching} opacity={1} />
      </div>
    </AnimatedToggleSection>
  );
};

export default Sources;
