import { ITocNode } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import { Cancelled, mm } from '../../../clients/base-clients';

export interface IDisplayNameResponse {
  text: string;
}

let getDisplayNameSource: CancelTokenSource | null = null;

async function displayName(
  projectUid: string,
  revisionUid: string,
  unit: ITocNode,
  isInternal?: boolean,
  variantUid?: string
): Promise<Cancelled | IDisplayNameResponse> {
  if (getDisplayNameSource) {
    getDisplayNameSource.cancel();
  }

  getDisplayNameSource = axios.CancelToken.source();

  const headers: any = {};
  if (variantUid) {
    headers['X-Index-Variant'] = variantUid;
  }

  try {
    const response = await mm.get<IDisplayNameResponse>(`/projects/${projectUid}/indexes/${revisionUid}/metadata/linkText`, {
      headers: headers,
      cancelToken: getDisplayNameSource.token,
      params: {
        unitUid: unit.uid,
        elementNid: unit.elementNid,
        type: isInternal ? 'internal' : 'inter-doc'
      }
    });

    getDisplayNameSource = null;
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

const toExport = { displayName: displayName };
export default toExport;
