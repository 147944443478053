import EditorStore from '../../flux/editor/EditorStore';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';
import { IUnit } from 'mm-types';

export function getSelectedClosestTocable(selectedUnit: IUnit | null): IUnit | null {
  if (!selectedUnit) {
    return null;
  }
  const docUnits = EditorStore.getDocUnits();
  let docUnitIndex = selectedUnit.index;
  do {
    const currentUnit = docUnits[docUnitIndex];
    if (currentUnit && ProjectDefinitionStore.isTocableType(currentUnit.definitionId, currentUnit.type)) {
      return currentUnit;
    }
    docUnitIndex--;
  } while (docUnitIndex >= 0);
  // Fallthrough: The topmost-unit, docUnits[0] should always be tocable.
  // In the event that it somehow isn't, this fn would otherwise return undefined, where null is expected if there is no selectedUnit or tocable unit.
  return null;
}
