import { DocProfileMenu } from './docUnitProfileMenuTypes';

export const admonitionMenuOverrides: DocProfileMenu = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  textActions: [
    { action: 'bold', enabled: false },
    { action: 'italic', enabled: false },
    { action: 'underline', enabled: false },
    { action: 'superscript', enabled: false },
    { action: 'subscript', enabled: false },
    { action: 'forecolor', enabled: false },
    { action: 'hilitecolor', enabled: false }
  ],
  linkActions: [{ action: 'insertLink', enabled: false }],
  justifyActions: [
    { action: 'JustifyLeft', enabled: false },
    { action: 'JustifyCenter', enabled: false },
    { action: 'JustifyRight', enabled: false },
    { action: 'JustifyFull', enabled: false }
  ],
  specialActions: [{ action: 'SpecialCharacter', enabled: true }],
  editActions: [
    { action: 'Paste', enabled: true },
    { action: 'Indent', enabled: false },
    { action: 'Outdent', enabled: false }
  ]
};

export const admonitionMenuSelectedOverrides: DocProfileMenu = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  textActions: [
    { action: 'bold', enabled: false },
    { action: 'italic', enabled: false },
    { action: 'underline', enabled: false },
    { action: 'superscript', enabled: false },
    { action: 'subscript', enabled: false },
    { action: 'forecolor', enabled: false },
    { action: 'hilitecolor', enabled: false }
  ],
  linkActions: [{ action: 'insertLink', enabled: false }],
  justifyActions: [
    { action: 'JustifyLeft', enabled: false },
    { action: 'JustifyCenter', enabled: false },
    { action: 'JustifyRight', enabled: false },
    { action: 'JustifyFull', enabled: false }
  ],
  editActions: [
    { action: 'Paste', enabled: true },
    { action: 'Indent', enabled: false },
    { action: 'Outdent', enabled: false }
  ]
};

export const listMenuOverrides: DocProfileMenu = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  editActions: [
    { action: 'Paste', enabled: true },
    { action: 'arcPasteElement', enabled: true }
  ],
  justifyActions: [
    { action: 'JustifyLeft', enabled: false },
    { action: 'JustifyCenter', enabled: false },
    { action: 'JustifyRight', enabled: false },
    { action: 'JustifyFull', enabled: false }
  ],
  specialActions: [{ action: 'SpecialCharacter', enabled: true }],
  linkActions: [{ action: 'insertLink', enabled: true }]
};

export const listMenuSelectedOverrides: DocProfileMenu = {
  textActions: [
    { action: 'bold', enabled: true },
    { action: 'italic', enabled: true },
    { action: 'underline', enabled: true },
    { action: 'superscript', enabled: true },
    { action: 'subscript', enabled: true },
    { action: 'forecolor', enabled: true },
    { action: 'hilitecolor', enabled: true }
  ],
  justifyActions: [
    { action: 'JustifyLeft', enabled: false },
    { action: 'JustifyCenter', enabled: false },
    { action: 'JustifyRight', enabled: false },
    { action: 'JustifyFull', enabled: false }
  ],
  linkActions: [{ action: 'insertLink', enabled: true }]
};

export const multiPartiteMenuOverrides: DocProfileMenu = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  editActions: [
    { action: 'Paste', enabled: false },
    { action: 'arcPasteElement', enabled: false }
  ],
  textActions: [],
  specialActions: [],
  justifyActions: [],
  linkActions: [{ action: 'insertLink', enabled: true }]
};

export const multiPartiteMenuSelectedOverrides: DocProfileMenu = {
  textActions: [
    { action: 'bold', enabled: true },
    { action: 'italic', enabled: true },
    { action: 'underline', enabled: true },
    { action: 'superscript', enabled: true },
    { action: 'subscript', enabled: true },
    { action: 'forecolor', enabled: true },
    { action: 'hilitecolor', enabled: true }
  ],
  linkActions: [{ action: 'insertLink', enabled: true }]
};

export const defaultMenuOverrides: DocProfileMenu = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  editActions: [
    { action: 'Paste', enabled: true },
    { action: 'arcPasteElement', enabled: true },
    { action: 'Indent', enabled: true },
    { action: 'Outdent', enabled: true }
  ],
  textActions: [{ action: 'arcFontSize', enabled: false, visible: true }],
  justifyActions: [
    { action: 'JustifyLeft', enabled: true },
    { action: 'JustifyCenter', enabled: true },
    { action: 'JustifyRight', enabled: true },
    { action: 'JustifyFull', enabled: true }
  ],
  specialActions: [{ action: 'SpecialCharacter', enabled: true }],
  linkActions: [{ action: 'insertLink', enabled: true }]
};

export const defaultMenuSelectedOverrides: DocProfileMenu = {
  textActions: [
    { action: 'FontSize', enabled: false, visible: false },
    { action: 'bold', enabled: true },
    { action: 'code', enabled: true },
    { action: 'italic', enabled: true },
    { action: 'arcTextTransform', enabled: true },
    { action: 'underline', enabled: true },
    { action: 'overline', enabled: true },
    { action: 'superscript', enabled: true },
    { action: 'subscript', enabled: true },
    { action: 'forecolor', enabled: true },
    { action: 'hilitecolor', enabled: true }
  ],
  linkActions: [{ action: 'insertLink', enabled: true }]
};
