import * as React from 'react';
import { AttachFileButton, VisibilityButton } from './index';
import { IComment } from 'mm-types';

export type AdditionalFormActionsProps = {
  mode: {
    edit: boolean;
    reply: boolean;
    internal: boolean;
  };
  comment?: IComment | null;
  permissions: {
    canCommentInternal: boolean;
    canManageAttachments: boolean;
  };
  onAttachFile: () => any;
  onVisibilityChange: () => any;
};

const AdditionalFormActions = (props: AdditionalFormActionsProps) => {
  const comment = props.comment || { type: 'INTERNAL' };
  const showFileAttachment = props.permissions.canManageAttachments;
  // show for new comments, not replies and when editing internal comments
  const showVisibilityToggle = props.permissions.canCommentInternal && !props.mode.reply && !(props.mode.edit && comment.type === 'PUBLIC');
  return (
    <span>
      {showFileAttachment && <AttachFileButton onClick={() => props.onAttachFile()} />}
      {showVisibilityToggle && (
        <VisibilityButton onClick={() => props.onVisibilityChange()} isPublic={!props.mode.internal} disabled={props.mode.edit} />
      )}
    </span>
  );
};

export default AdditionalFormActions;
