import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { ForeignEntryValues } from '../index';

export type ForeignEntryValue = { uid: string };

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.FOREIGN_ENTRY> = {
  id: NNCIndexMetadataID.FOREIGN_ENTRY,
  toMetadataHTML: (values) => {
    let entries = '';
    values.forEach(({ uid }) => {
      entries += `<div class="foreign-entry" uid="${uid}"></div>`;
    });
    return `<div class="${metadataDefinition.id}">${entries}</div>`;
  },
  toJSON: ($element) => {
    const result: ForeignEntryValues = [];
    $element.children().each(function () {
      const $el = $(this);
      result.push({
        uid: $el.attr('uid')!
      });
    });
    return result;
  }
};
