import { mm, exemptStatusCodes, ClientError, Cancelled } from './base-clients';
import { IWorkFlow, IPublishCheck, IWorkflowSummary, IWorkflowAssignments, DocParams, INotification } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;
let publishCheckSource: CancelTokenSource | null = null;
let projectCheckSource: CancelTokenSource | null = null;
let summarySource: CancelTokenSource | null = null;
let regDiffSource: CancelTokenSource | null = null;

export type WorkflowDecisionModel = 'APPROVE' | 'APPROVE_SKIP_NEXT' | 'REJECT' | 'SKIP' | 'SUMMARY' | 'NOT_REQUIRED';

export type WorkflowActionData = {
  decisionModel: WorkflowDecisionModel;
  comment: string;
};

/**
 * Gets all workflows
 */
export async function getAll(options?: { indexUid: string }): Promise<IWorkFlow[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];
  if (options && options.indexUid) {
    query.push('indexUid=' + options.indexUid);
  }

  try {
    const response = await mm.get<{ workflows: IWorkFlow[] }>(`/workflow?${query.join('&')}`, {
      cancelToken: getAllSource.token
    });

    let workflows = response?.data?.workflows || [];

    workflows.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    for (const workflow of workflows) {
      parseWorkflow(workflow);
    }

    return workflows;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

function parseWorkflow(workflow: IWorkFlow) {
  if (workflow.stages) {
    let postPublish = false;

    workflow.stages.forEach((stage, inx) => {
      stage.index = inx;

      // Ensure certain fields are always defined so will be rendered as empty as default
      stage.previousStage = stage.previousStage === undefined ? null : stage.previousStage;
      stage.previousStageActionTitle = stage.previousStageActionTitle === undefined ? null : stage.previousStageActionTitle;
      stage.nextStage = stage.nextStage === undefined ? null : stage.nextStage;
      stage.nextStageActionTitle = stage.nextStageActionTitle === undefined ? null : stage.nextStageActionTitle;

      if (postPublish) {
        stage.step = 'post-publish';
        delete stage.allowIndexEdits;
        delete stage.mustUploadApprovalArtifacts;
      } else if (stage.publishStep) {
        stage.step = 'publish';
        delete stage.skippableStage;
        delete stage.mustUploadApprovalArtifacts;
        postPublish = true;
      } else {
        stage.step = 'pre-publish';
      }
    });
  }

  return workflow;
}

/**
 * Gets a single workflow
 */
export async function getOne(uid: string) {
  const response = await mm.get<IWorkFlow>(`/workflow/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [404])
  });

  if (response.status === 404) {
    throw new ClientError(response.status, 'Workspace not found');
  }

  parseWorkflow(response.data);

  return response.data;
}

/**
 * Removes an workflow by id
 */
export async function remove(uid: string) {
  const response = await mm.delete(`/workflow/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [400, 412])
  });

  if (response.status === 412 || response.status === 400) {
    throw new ClientError(response.status, 'Workflow in use');
  }
}

/**
 * Creates a new workflow
 */
export async function create(token: Partial<IWorkFlow>) {
  const response = await mm.post<IWorkFlow>(`/workflow`, token);
  return response.data;
}

/**
 * Updates a given workflow
 */
export async function update(uid: string, token: Partial<IWorkFlow>) {
  const response = await mm.put<IWorkFlow>(`/workflow/${uid}`, token);
  return response.data;
}

export async function saveAction(workflowUid: string, token: WorkflowActionData): Promise<INotification> {
  const response = await mm.post<INotification>(`/workflow/${workflowUid}/review`, token);
  return response.data;
}

export async function projectCheck(projectUid: string, indexUid: string): Promise<IPublishCheck | Cancelled> {
  if (projectCheckSource) {
    projectCheckSource.cancel();
  }

  projectCheckSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IPublishCheck>(`/projects/${projectUid}/indexes/${indexUid}/publish/check`, {
      cancelToken: projectCheckSource.token
    });
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function workflowCheck(workflowUid: string): Promise<IPublishCheck | Cancelled> {
  if (publishCheckSource) {
    publishCheckSource.cancel();
  }

  publishCheckSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IPublishCheck>(`/workflow/${workflowUid}/check`, { cancelToken: publishCheckSource.token });
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export function cancelPendingWorkflowCheck() {
  if (publishCheckSource) {
    publishCheckSource.cancel();
  }
}

export async function startWorkflow(indexUid: string) {
  const response = await mm.post<void>(
    `/workflow/${indexUid}/start`,
    {},
    {
      validateStatus: (status) => exemptStatusCodes(status, [404, 403, 400])
    }
  );

  if ([404, 403, 400].indexOf(response.status) !== -1) {
    throw new ClientError(response.status, 'An Error occurred', response.data);
  }

  return response.data;
}

export async function getSummary(indexUid: string): Promise<IWorkflowSummary | Cancelled> {
  if (summarySource) {
    summarySource.cancel();
  }

  summarySource = axios.CancelToken.source();

  try {
    const response = await mm.get<IWorkflowSummary>(`/workflow/${indexUid}/summary`, {
      cancelToken: summarySource.token,
      validateStatus: (status) => exemptStatusCodes(status, [404, 403, 400])
    });

    if ([404].indexOf(response.status) !== -1) {
      throw new ClientError(response.status, 'Not found');
    }

    const summary = response.data;
    summary.displayName = summary.workflow.name;
    summary.code = summary.workflow.uid;
    return summary;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getStageAssignments(workflowUid: string, options: Partial<DocParams>) {
  const query: string[] = [];
  if (options && options.projectUid) {
    query.push('projectUid=' + options.projectUid);
  }
  if (options && options.indexUid) {
    query.push('indexUid=' + options.indexUid);
  }

  const response = await mm.get<IWorkflowAssignments>(`/workflow/${workflowUid}/stageAssignments?${query.join('&')}`, {
    // cancelToken: summarySource.token,
    validateStatus: (status) => exemptStatusCodes(status, [404])
  });

  if ([404].indexOf(response.status) !== -1) {
    throw new ClientError(response.status, 'Not found');
  }

  return response.data;
}

export async function saveStageAssignments(workflowUid: string, token: Partial<IWorkflowAssignments>) {
  const response = await mm.post(`/workflow/${workflowUid}/stageAssignments`, token);
  return response.data;
}

export async function getRegDiff(indexUid: string): Promise<IWorkFlow | Cancelled> {
  if (regDiffSource) {
    regDiffSource.cancel();
  }

  regDiffSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IWorkFlow>(`/workflow/${indexUid}/regDiff`, {
      cancelToken: regDiffSource.token,
      validateStatus: (status) => exemptStatusCodes(status, [404, 403, 400])
    });

    if ([404, 400, 403].indexOf(response.status) !== -1) {
      throw new ClientError(response.status, 'An error occurred', response.data);
    }

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}
