import { LockedStatus } from 'mm-types';

export type IndexLockModes =
  | 'UNLOCKED'
  | 'LOCKED_AFTER_PUBLISH'
  | 'LOCKED_FOR_MERGE'
  | 'LOCKED_FOR_WORKFLOW_TO_START'
  | 'LOCKED_WORKFLOW_STAGE_PREVENTS_EDITS'
  | 'LOCKED_BY_ADMIN';

const properties = {
  UNLOCKED: {
    code: 'UNLOCKED' as LockedStatus
  },

  LOCKED_AFTER_PUBLISH: {
    code: 'LOCKED_AFTER_PUBLISH' as LockedStatus
  },

  LOCKED_FOR_MERGE: {
    code: 'LOCKED_FOR_MERGE' as LockedStatus
  },

  LOCKED_FOR_WORKFLOW_TO_START: {
    code: 'LOCKED_FOR_WORKFLOW_TO_START' as LockedStatus
  },

  LOCKED_BY_ADMIN: {
    code: 'LOCKED_BY_ADMIN' as LockedStatus
  }
};

export default {
  props: properties
};
