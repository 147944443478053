import * as React from 'react';
import { AeroIconButtonDelete, AeroIconButtonDownload } from '../../../../general/AeroIconButton';
import { IMedia } from 'mm-types';

interface Props {
  media: IMedia;
  deleteMedia(): any;
  onDownloadSource(): any;
}

const Header = (props: Props) => {
  function onDownload() {
    props.onDownloadSource();
  }
  return (
    <div className="side-slidein-info-container">
      <h1 id={props.media.filename}>{props.media.filename}</h1>
      <div className="info">
        <span>Edited {props.media.modifiedFormatted}</span>
        <div className="media-details-delete">
          <AeroIconButtonDownload onClick={onDownload} id="download" tooltip="Download" tooltipPosition="top-center" />
          <AeroIconButtonDelete onClick={props.deleteMedia} id="delete" tooltip="Delete" tooltipPosition="top-center" />
        </div>
      </div>
    </div>
  );
};

export default Header;
