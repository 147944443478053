import React, { useState } from 'react';
import ColorPicker from './ColorPicker';
import EditorStore from '../../../../../../../flux/editor/EditorStore';
import { Color, TinyAction } from 'mm-types';
import TinyFacade from '../../../../../utils/tinyFacade/TinyFacade';
import useListenToStore from '../../../../../../hooks/useListenToStore';
import ProjectDefinitionStore from '../../../../../../../flux/common/ProjectDefinitionStore';

export type Props = {
  icon: 'format_color_text' | 'border_color' | string;
  cmd: 'forecolor' | 'hilitecolor';
  style: 'color' | 'backgroundColor';
  tooltip?: string;
};

export type State = {
  color: string;
};

function Color(props: Props) {
  const [color, setColor] = useState<Color | undefined>();

  useListenToStore({ store: EditorStore, eventListener: subscribeToTinyEvent, update: [color] });

  function getColor(): Color | null {
    const tinyFacade: TinyFacade | null = EditorStore.getEditor().getActiveEditorFacade();
    const selectionNode: Element | undefined = EditorStore.getEditor().getActiveEditorInstance()?.selection?.getNode();

    let colorId = tinyFacade?.getSelectionColor(props.style, true, selectionNode as HTMLElement) ?? '';
    let color = ProjectDefinitionStore.getColorById(colorId, true, getColorSource(props.cmd));

    // Color not found in the index definition palette. Use a default color.
    if (!color) {
      color = { day: { id: '', name: '', value: '#808080' }, night: { id: '', name: '', value: '#808080' } };
    }

    return color;
  }

  function subscribeToTinyEvent(e = { type: null }) {
    if (e.type === 'nestedUnitFocusChange') {
      const color = getColor();
      !!color && setColor(color);
    }
  }

  function applyColor(newColor: Color) {
    EditorStore.setColorData({
      dayColor: newColor.day,
      nightColor: newColor.night,
      elementDefinitionId: getColorSource(props.cmd)
    });
    EditorStore.getEditor()
      .getActiveEditorFacade()
      ?.execCommand(props.cmd as TinyAction, newColor.day.id);
    setColor(newColor);
  }

  function getColorSource(cmd: string): 'ColorText' | 'BackgroundColorText' {
    return cmd === 'forecolor' ? 'ColorText' : 'BackgroundColorText';
  }

  return (
    <ColorPicker
      color={color}
      source={getColorSource(props.cmd)}
      onChange={(c) => applyColor(c)}
      icon={props.icon}
      tooltip={props.tooltip}
    />
  );
}

export default Color;
