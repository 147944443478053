import { IBackendErrorDetails } from 'mm-types';
import NotificationsStore from '../flux/events/NotificationsStore';

class RootErrorHandler {
  handle(error: IBackendErrorDetails) {
    switch (error.code) {
      // media is stale, but user wants to download it
      case 400185: {
        NotificationsStore.retrieveNotifs();
        break;
      }
    }
  }
}

const rootErrorHandler = new RootErrorHandler();
export default rootErrorHandler;
