import { IDocUnitProfile } from 'mm-types';
import PreEditProps from '../../../../sidetabs/sub/editComponent/components/PreEditProps';
import TinyCustomKeyListener from '../../../tinyFacade/TinyCustomKeyListener';
import { CustomEditor } from '../../../tinyFacade/EditorInstanceManager';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'PRE',
  getLabel: () => 'PRE',
  targetEditContainerSelector: '>.arc-unit>.arc-pre',
  template: '',
  editPropsComponent: PreEditProps,
  editor: {
    setup: function (editor) {
      TinyCustomKeyListener.applyBreakLine(editor as CustomEditor);
    },
    plugins: 'nonbreaking paste',
    forced_root_block: '',
    preserve_br: true // custom manual-manager settings
  }
};
