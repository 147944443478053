import { metadataDefinition as title } from './title/metadataDefinition';
import { metadataDefinition as altTitle } from './alt-title/metadataDefinition';
import { metadataDefinition as lights, LightsValue } from './lights/metadataDefinition';
import { metadataDefinition as symbol } from './symbol/metadataDefinition';
import { metadataDefinition as effectivity, EffectivityValue } from './effectivity/metadataDefinition';
import { metadataDefinition as foreignEntry, ForeignEntryValue } from './foreign-entry/metadataDefinition';
import { NNCIndexMetadataID, NNCIndexMetadataDefinitions } from './NNCIndexMetadataTypes';

const NNC_INDEX_METADATA_DEFINITION: NNCIndexMetadataDefinitions = {
  [NNCIndexMetadataID.TITLE]: title,
  [NNCIndexMetadataID.ALT_TITLES]: altTitle,
  [NNCIndexMetadataID.LIGHTS]: lights,
  [NNCIndexMetadataID.SYMBOL]: symbol,
  [NNCIndexMetadataID.EFFECTIVITY]: effectivity,
  [NNCIndexMetadataID.FOREIGN_ENTRY]: foreignEntry
};

export type EffectivityValues = EffectivityValue[];
export type ForeignEntryValues = ForeignEntryValue[];
export type LightsValues = LightsValue[];
export interface SymbolValue {
  value: '[]' | '>' | 'null';
}
export interface TitleValues {
  eicas: boolean;
  fontbold: boolean;
  fontlarge: boolean;
  qai: boolean;
  unann: boolean;
  text: string;
}

export interface AltTitleValues extends TitleValues {
  hide: boolean;
  hidetoc: boolean;
}

export type AltTitlesValues = AltTitleValues[];

export { NNC_INDEX_METADATA_DEFINITION };
