import { IProject } from 'mm-types';
import * as React from 'react';

type PropTypes = {
  showHeader: boolean;
  project?: IProject;
};

const Header = (props: PropTypes) => {
  if (!props.showHeader) {
    return null;
  }
  return (
    <div className="editing-header">
      <div className="editing-header-inner">
        <h5>{props.project ? props.project.name : ''}</h5>
      </div>
    </div>
  );
};

export default Header;
