import * as React from 'react';

interface UseArrayValuesResult<T> {
  localValues: T;
  addValue: (value: T) => void;
  updateValue: (value: T, index: number) => void;
  removeValue: (index: number) => void;
  setValues: (values: T) => void;
}

export function useArrayValue<T>(values: T, onChangeFn: (data: T) => void): UseArrayValuesResult<T> {
  const [localValues, setLocalValues] = React.useState<T>(values);

  function addValue(value: T) {
    const val: any = [...(localValues as any), ...(value as any)];
    setValues(val);
  }

  function removeValue(index: number) {
    // @ts-ignore
    if (!localValues.length || !localValues[index]) {
      return;
    }
    const val: any = [...(localValues as any)];
    val.splice(index, 1);

    setValues(val);
  }
  function updateValue(value: T, index: number) {
    const val: any = [...(localValues as any)];
    val.splice(index, 1, value[0]);
    setValues(val);
  }

  function setValues(values: T) {
    setLocalValues(values);
    onChangeFn(values);
  }

  return {
    localValues,
    updateValue,
    setValues,
    addValue,
    removeValue
  };
}
