import { IAccount, IConfig } from 'mm-types';
import config from './config';
import { scriptLoader } from './scriptLoader';
import Log from './Log';

declare let ENV: Partial<IConfig>;

export class CustomConfigProvider {
  private configResolver: (data: IConfig) => void;
  private scriptLoaded = new Promise<IConfig>((resolver) => {
    this.configResolver = resolver;
  });

  constructor() {
    this.resolveCustomConfig = this.resolveCustomConfig.bind(this);
    this.rejectCustomConfig = this.rejectCustomConfig.bind(this);
  }

  load(airlineUid?: IAccount['airlineUid']) {
    if (!airlineUid || airlineUid === 'arconics-air') {
      return this.rejectCustomConfig(false);
    }
    scriptLoader('custom-config', `${config.aerodocsCustomUrl}options-${airlineUid}.js`, this.resolveCustomConfig, this.rejectCustomConfig);
  }

  rejectCustomConfig(logError = true) {
    this.configResolver(config);
    if (logError) {
      Log.error('Custom config cannot be loaded ');
    }
  }

  resolveCustomConfig() {
    this.configResolver({
      ...config,
      ...ENV
    });
  }

  async getConfig(): Promise<IConfig> {
    return this.scriptLoaded;
  }
}

export const customConfigProvider = new CustomConfigProvider();
