import { EffectivityValues, ForeignEntryValues, LightsValues, SymbolValue, TitleValues, AltTitlesValues, AltTitleValues } from '.';
import { IElementDefinition } from 'mm-types';

export enum NNCIndexMetadataID {
  TITLE = 'title-data',
  ALT_TITLES = 'alt-titles-data',
  LIGHTS = 'lights-data',
  SYMBOL = 'symbol-data',
  EFFECTIVITY = 'effectivity-data',
  FOREIGN_ENTRY = 'foreign-entry-data'
}

export type MetadataValue<T> = T extends NNCIndexMetadataID.TITLE
  ? TitleValues | AltTitleValues
  : T extends NNCIndexMetadataID.ALT_TITLES
  ? AltTitlesValues
  : T extends NNCIndexMetadataID.LIGHTS
  ? LightsValues
  : T extends NNCIndexMetadataID.SYMBOL
  ? SymbolValue
  : T extends NNCIndexMetadataID.EFFECTIVITY
  ? EffectivityValues
  : T extends NNCIndexMetadataID.FOREIGN_ENTRY
  ? ForeignEntryValues
  : 'object';

export interface NNCIndexMetadataWithHeaderDefinition<T> extends NNCIndexMetadataWithoutHeaderDefinition<T> {
  toHeaderHTML(values: MetadataValue<T>, definitions?: IElementDefinition[]): string;
}

export interface NNCIndexMetadataWithoutHeaderDefinition<T> {
  id: T;
  toMetadataHTML(values: MetadataValue<T>): string;
  toJSON($element: JQuery<HTMLElement>): MetadataValue<T>;
}

export type NNCIndexMetadataDefinition<T> = T extends
  | NNCIndexMetadataID.TITLE
  | NNCIndexMetadataID.ALT_TITLES
  | NNCIndexMetadataID.SYMBOL
  | NNCIndexMetadataID.EFFECTIVITY
  ? NNCIndexMetadataWithHeaderDefinition<T>
  : NNCIndexMetadataWithoutHeaderDefinition<T>;

export interface NNCIndexMetadataValues {
  [NNCIndexMetadataID.TITLE]: MetadataValue<NNCIndexMetadataID.TITLE>;
  [NNCIndexMetadataID.ALT_TITLES]: MetadataValue<NNCIndexMetadataID.ALT_TITLES>;
  [NNCIndexMetadataID.LIGHTS]: MetadataValue<NNCIndexMetadataID.LIGHTS>;
  [NNCIndexMetadataID.SYMBOL]: MetadataValue<NNCIndexMetadataID.SYMBOL>;
  [NNCIndexMetadataID.EFFECTIVITY]: MetadataValue<NNCIndexMetadataID.EFFECTIVITY>;
  [NNCIndexMetadataID.FOREIGN_ENTRY]: MetadataValue<NNCIndexMetadataID.FOREIGN_ENTRY>;
}

export interface NNCIndexMetadataDefinitions {
  [NNCIndexMetadataID.TITLE]: NNCIndexMetadataDefinition<NNCIndexMetadataID.TITLE>;
  [NNCIndexMetadataID.ALT_TITLES]: NNCIndexMetadataDefinition<NNCIndexMetadataID.ALT_TITLES>;
  [NNCIndexMetadataID.LIGHTS]: NNCIndexMetadataDefinition<NNCIndexMetadataID.LIGHTS>;
  [NNCIndexMetadataID.SYMBOL]: NNCIndexMetadataDefinition<NNCIndexMetadataID.SYMBOL>;
  [NNCIndexMetadataID.EFFECTIVITY]: NNCIndexMetadataDefinition<NNCIndexMetadataID.EFFECTIVITY>;
  [NNCIndexMetadataID.FOREIGN_ENTRY]: NNCIndexMetadataDefinition<NNCIndexMetadataID.FOREIGN_ENTRY>;
}
