import Divider from 'material-ui/Divider';
import FlatButton from 'material-ui/FlatButton';
import RadioButton from 'material-ui/RadioButton';
import RadioButtonGroup from 'material-ui/RadioButton/RadioButtonGroup';
import React, { useState } from 'react';
import { Tree } from '../utils/qrhStructureInsertRules';

export type Props = {
  unitToInsert: string;
  onCancel: () => void;
  onSave: (option?: string) => void;
  insertOptions: Tree[];
};

const QrhStructurePopupOptions = ({ unitToInsert, onCancel, onSave, insertOptions }: Props) => {
  const [option, setOption] = useState<string>();
  return (
    <div style={{ width: '100%', float: 'left' }}>
      <div data-qa={'qrh-insert-title'}>
        Please choose insert option for <b>{unitToInsert.toUpperCase()}</b>
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }} data-qa={'qrh-structure-popup-radio-group'}>
        <RadioButtonGroup
          name="structureOptions"
          onChange={(e, val) => setOption(val)}
          valueSelected={option}
          style={{ width: 400, textAlign: 'left' }}
        >
          {insertOptions.map((opt) => {
            // at root level
            if (opt.current) {
              return (
                <RadioButton
                  label={`As sibling of ${opt.current.ordinal} ${opt.current.heading}`}
                  value={undefined}
                  style={{ marginTop: 8 }}
                  key={'sibling'}
                />
              );
            } else if (opt.parent) {
              return (
                <RadioButton
                  label={`As child of ${opt.parent.ordinal} ${opt.parent.heading}`}
                  value={opt.parent.uid}
                  style={{ marginTop: 8 }}
                  key={opt.parent.uid}
                />
              );
            }
          })}
        </RadioButtonGroup>
      </div>
      <Divider />
      <div style={{ float: 'right' }}>
        <FlatButton
          label="Cancel"
          primary={true}
          onClick={() => {
            onCancel();
          }}
          data-qa={'qrh-insert-option-cancel-button'}
        />
        <FlatButton
          label="Save"
          onClick={() => {
            onSave(option);
          }}
          data-qa={'qrh-insert-option-save-button'}
        />
      </div>
    </div>
  );
};

export default QrhStructurePopupOptions;
