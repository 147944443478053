import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as projectClient from '../../clients/project';
import {
  ColorInfo,
  DocParams,
  EditBlurData,
  EventStoreEventType,
  IActivity,
  IDocUnitProfile,
  IEditingNestedChangeDOM,
  IEditorStoreEvent,
  IElementDefinition,
  IElementStyle,
  ILink,
  IPageBreakSettingsData,
  IPrintOutputSettingsData,
  IShareDetails,
  ISharedIndex,
  ISpecialChar,
  ITocNode,
  IUnit,
  IUnitDefinition,
  IUnitDetails,
  IVariant,
  LinkSource,
  ModeSyncData
} from 'mm-types';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';
import * as activityClient from '../../clients/activities';
import * as unitsClient from '../../clients/units';
import { GetUnitOptions } from '../../clients/units';
import TocStore, { RenderDirective } from './TocStore';
import ActiveUserStore from '../common/ActiveUserStore';
import ChangeTasksStore from './ChangeTasksStore';
import UnitTaskStore from './UnitTaskStore';
import UnitSpecialInterestTagStore from './UnitSpecialInterestTagStore';
import AppStateStore from '../common/AppStateStore';
import CommentStore from './CommentStore';
import AutoTagStore from './AutoTagStore';
import * as specialChars from '../../clients/special-chars';
import * as spellCheck from '../../clients/spell-check';
import ProjectStore from './ProjectStore';
import * as tocClient from '../../clients/toc';
import SharedUnitSelection from './EditorStoreAddons/sharedContent/SharedUnitSelection';
import IndexEventStore, { IndexEventStoreEvent } from '../events/IndexEventStore';
import UserEventStore, { TocDuplicateData, UserEventStoreEvent } from '../events/UserEventStore';
import Log from '../../utils/Log';
import { UnitUtils } from '../../components/editor/utils/units/UnitUtils';
import EditorModes, { EditorModeProperties, EditorModes as EditorModeTypes, MenuTypes } from './EditorModes';
import SmartContentStore from './SmartContentStore';
import { UnitTypes } from '../../components/editor/utils/units/UnitTypes';
import { ConversionUtil } from '../../utils';
import Store from '../Store';
import { getSelectedClosestTocable } from '../../components/hoc/common';
import { InsertRulesFactory } from '../../components/editor/menus/insert/utils/InsertRulesFactory';
import { getElementIsInsertable } from '../../components/hoc/withInsertRules';
import EditorInstanceManager from '../../components/editor/utils/tinyFacade/EditorInstanceManager';
import { EditorManager } from 'tinymce';
import { InsertAction } from '../../components/editor/menus/insert/content/ContentMenuContainer';
import SpellCheckStore from './SpellcheckStore';
import NotificationsStore from '../events/NotificationsStore';
import LinkStore from './LinkStore';
import { convertElement } from '../../clients/converts';
import SharedContentAddon from './EditorStoreAddons/SharedContentAddon';
import { ScaleInfo } from '../../components/editor/sidetabs/sub/editComponent/components/ImageEditProps';
import { AxiosError } from 'axios';
import { DocUnitWrapper } from '../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { ActionEvent } from '../../components/editor/docUnit/DocUnit';
import { UnitIdentifier } from '../../components/editor/utils/tinyFacade/UnitIdentifier';

import { showSystemSnackbarMessageWithDefaults } from '../../components/misc/SystemSnackbar/thunks';
import appStore from '../../appStore';
import { CreateUnitAddon, InsertPosition } from './EditorStoreAddons/CreateUnitAddon';
import UnitHighlightStore from './UnitHighlightStore';
import { propsAreEqual } from '../../utils/prop-compare';
import ServerSettingsStore from '../common/ServerSettingsStore';
import { ElementDetails } from '../../components/editor/utils/units/ElementDetails';
import { DataAttributeValidationErrors } from '../../components/editor/sidetabs/sub/renderDataAttributes/DataAttributePropsType';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;

declare const tinymce: EditorManager;

export type DataError = {
  xhr: JQueryXHR;
  type: string;
  axiosErr: AxiosError;
  additional?: string | null;
  editorUpdate: any;
  completeCallback: Function;
  unit: IUnit | null;
};

export type INestedUnitFocusChangeEvent = {
  focused: IUnitDetails;
  nestedTree: IUnitDetails[];
  nestedDOM: IEditingNestedChangeDOM[];
  parent: IUnitDetails;
};

export type State = {
  selectedUnits: IUnit[];
  specialChars: ISpecialChar[];
  activeEditors: { [name: string]: any };
  isShareDiffActive: boolean;
  dataAttributesValidationErrors: DataAttributeValidationErrors;
};

export type RetrieveUnitParams = {
  includeHeader: boolean;
  tocableUnitUid?: string;
  offsetUnitUid?: string;
  unitsRequired?: number;
  takeUnitHtmlFromServer?: boolean;
  changedTocDisplayName?: string | null;
};

export type UnitChangedOptions = {
  unitUids: string[];
  parts: string[];
  refreshView: boolean;
  newUnitWrapper: DocUnitWrapper;
  previousUnits: IUnit[];
  renderBehaviour?: Partial<RenderOptions>;
};

export type RenderOptions = {
  isCreate: boolean;
  launchEditor: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  confirmMessage: string | null;
  scrollToAffectedUnit: boolean;
  multipleChanged: boolean;
  openEditPaneOnAffectedUnit: boolean;
  takeUnitHtmlFromServer: boolean;
};

export type ChangeOptions = {
  silent?: boolean;
  activateParams?: {
    initUnits?: IUnit[];
    initUnitUid?: string;
    shareUid?: string;
    selectItemUid?: string;
    word?: string;
    options?: string;
    variant?: IVariant | null;
  };
};

export type DocumentTargets = {
  targetUnitUid?: string;
  targetElementNid?: string | null;
  indexUid?: string;
  projectUid?: string;
  tocableUnitUid?: string;
  targetSectionUid?: string;
  targetVolumeUid?: string;
  targetChapterUid?: string;
};

export type SharedIndexModel = {
  sharedIndex: {
    uid: string;
    originProjectName: string;
    originProjectUid: string;
  };
  units?: { uid: string }[];
  index: {
    uid: string;
  };

  updateStrategy: string;
  ordinalLevelStrategy: string;
};

export interface UpdateUnitOptions {
  forceVersionOverwrite?: boolean;
  forceRefreshView?: boolean;
  openEditPaneOnAffectedUnit?: boolean;
  replaceHtml?: boolean;
  alreadyUpdated?: boolean;
}

export type ColorData = {
  dayColor: ColorInfo;
  nightColor: ColorInfo;
  elementDefinitionId: string;
};

type UpdateUnitCallback = (unit: { unitUid: string; wasDirty: boolean; unit?: IUnit }) => void;

/*
 * TODO This is being implemented as a work in progress refactoring
 * Editor related Actions and data are being moved into this
 * For now it behaves more like a proxy and passes on events onto EditorInstanceManager
 *
 * Future refactoring will move api calls from EditorPage into this method, which will be available through more actions
 * (which can be called directly from menu's and so on, drastically reducing code / simplifying EditorPage)
 *
 * This will also involve merging this class with DocUnitsService (which also behaves like a pre-Flux store)
 */

export class EditorStore extends Store<State> {
  private _isStoreBusy: boolean;
  private _isStoreBusyRetrievingPage: boolean;
  private _isInitialized: boolean;
  private _editorInstanceManager: null | EditorInstanceManager;
  private _lastFocusedInnerUnit: null | INestedUnitFocusChangeEvent;
  private _docParams: null | DocParams;
  private _execWhenReady: (() => void)[];
  private _mode: EditorModeTypes; // EditorModes.modes;
  private _colorData?: ColorData;
  // private xhrRetrieveUnits: JQueryXHR;
  private sharedContentAddon: SharedContentAddon;
  private createUnitAddon: CreateUnitAddon;
  _docUnitCollection: DocUnitWrapper[];
  _docUnitSearchIndex: number;
  private _lastLevel: UnitTypes | null;
  private _lastShareDetailsParsed: IShareDetails | null;
  private _userUnitChange: boolean;
  unitsRequiredPerRequest: number;

  constructor() {
    super();

    this.state = this.initialState();
    this.sharedContentAddon = new SharedContentAddon(this);
    this.createUnitAddon = new CreateUnitAddon(this);
    this._isStoreBusy = false;
    this._isStoreBusyRetrievingPage = false;
    this._docUnitCollection = []; // units on "page" at any point in time
    this._docUnitSearchIndex = -1;
    this._isInitialized = false;
    this._editorInstanceManager = null;
    this._lastFocusedInnerUnit = null;
    this._docParams = null;
    this._execWhenReady = [];
    this._mode = 'EDITING';
    this._colorData = undefined;
    this._lastLevel = null;
    this._lastShareDetailsParsed = null;
    this._userUnitChange = false;
    this.unitsRequiredPerRequest = 100;

    this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
    this.listenTo(UserEventStore as any, this.onUserEventStoreUpdate);

    LinkStore.initializeListener(this);
  }

  initialState(): State {
    return {
      selectedUnits: [], // mirrors Document selectedUnits state: mirroring this data here allows for decoupling of UX components as this data has many interested parties
      specialChars: [],
      activeEditors: {},
      isShareDiffActive: false,
      dataAttributesValidationErrors: {}
    };
  }

  getColorData(): ColorData | undefined {
    return this._colorData;
  }

  setColorData(value: ColorData) {
    this._colorData = value;
  }

  triggerDataAttributeApplied(appliedByUser: boolean) {
    this.trigger({
      type: 'dataAttributeApplied',
      data: { appliedByUser: appliedByUser }
    } as IEditorStoreEvent<'dataAttributeApplied'>);
  }

  getEditor() {
    this._editorInstanceManager = this._editorInstanceManager ? this._editorInstanceManager : new EditorInstanceManager();
    return this._editorInstanceManager;
  }

  // get most recent (last) selected unit
  // TODO: Convert type to getSelectedUnit(): IUnit | undefined
  getSelectedUnit() {
    const length = this.state.selectedUnits.length;
    return this.state.selectedUnits[length - 1];
  }

  // get all selected units
  getSelectedUnits() {
    return this.state.selectedUnits;
  }

  // get all selected units (but also including all those hidden units user didn't explicitly select and other unselected units between first and last selected unit on the page)
  getContiguousSelectedUnits() {
    const indexSortedSelected: IUnit[] = _.sortBy(this.state.selectedUnits, ['index']);
    const startIndex = indexSortedSelected[0].index;
    const endIndex = indexSortedSelected[indexSortedSelected.length - 1].index;
    const allUnits = this.getDocUnits();

    return allUnits.filter((u) => u.index >= startIndex && u.index <= endIndex);
  }

  getPreviousToSelectedUnit() {
    const selectedUnit = this.getSelectedUnit();
    const previousVisibleUnits = this.getDocUnits().filter((u) => u.index < selectedUnit.index && u.isVisibleOnEdit);
    return previousVisibleUnits[previousVisibleUnits.length - 1];
  }

  getMostSeniorSelectedUnit() {
    return this._extractMostSeniorUnit(_.sortBy(this.getSelectedUnits(), ['index'], ['asc']));
  }

  getLastFocusedNestedUnit(): INestedUnitFocusChangeEvent | null {
    return this._lastFocusedInnerUnit;
  }

  isNestedUnitFocused() {
    return (
      this._lastFocusedInnerUnit !== null &&
      this._lastFocusedInnerUnit.focused !== null &&
      this._lastFocusedInnerUnit.parent !== null &&
      this._lastFocusedInnerUnit.nestedDOM.length > 0 &&
      !this._lastFocusedInnerUnit.parent.unitElement?.className?.includes('arc-unit')
    );
  }

  _selectedUnitUid() {
    const selectedUnit = this.getSelectedUnit();
    return selectedUnit ? selectedUnit.uid : null;
  }

  isEditorFocused(focusedOnUnitUid?: string) {
    const isFocused = this._editorInstanceManager && this._editorInstanceManager.isFocused();
    return isFocused && (!focusedOnUnitUid || focusedOnUnitUid === this._selectedUnitUid());
  }

  getFocusedUnitProfile(): IDocUnitProfile | undefined {
    const dup = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles();

    if (this.isNestedUnitFocused() && this._lastFocusedInnerUnit?.focused?.profile) {
      const profile = this._lastFocusedInnerUnit.focused.profile;
      return profile;
    } else {
      // get root type
      return dup.getUnitProfileByDefinitionId(this.getSelectedUnit()!.definitionId);
    }
  }

  getFocusedUnitDefinition(): IUnitDefinition {
    return ProjectDefinitionStore.getProjectDefinedUnitById(this.getSelectedUnit()!.definitionId)!;
  }

  getFocusedElementDefinition(): IElementDefinition | undefined {
    const elm = this.getFocusedUnitElement();
    if (elm) {
      const elmId = getDataElementDefinitionId(elm);
      if (elmId) {
        return ProjectDefinitionStore.getElementDefinitionById(elmId);
      }
    }
  }

  getFocusedTreeUnits() {
    let nestedTree: IUnitDetails[] = [];
    if (this._lastFocusedInnerUnit) {
      nestedTree = this._lastFocusedInnerUnit.nestedTree ? this._lastFocusedInnerUnit.nestedTree : [];
    }

    if (nestedTree.length === 0 && !!this.getSelectedUnit()) {
      const focusedEl = this.getFocusedUnitElement();
      const profile = this.getFocusedUnitProfile();
      if (focusedEl && profile) {
        nestedTree.push({
          type: this.getSelectedUnit()?.type,
          subtype: this.getSelectedUnit()?.subType,
          profile,
          definition: this.getFocusedUnitDefinition(),
          targetElement: focusedEl,
          unitElement: focusedEl
        });
      }
    }
    return nestedTree;
  }

  getFocusedUnitElement(): HTMLElement | null {
    const focusedNestedUnit = this.getLastFocusedNestedUnit();
    return this.isNestedUnitFocused() &&
      focusedNestedUnit?.focused.targetElement &&
      document.body.contains(focusedNestedUnit.focused.targetElement)
      ? focusedNestedUnit.focused.targetElement
      : tinymce.activeEditor && document.body.contains(tinymce.activeEditor.getElement() as HTMLElement)
      ? (tinymce.activeEditor.getElement() as HTMLElement)
      : this._editorInstanceManager?.getActiveEditorFacade()?.refreshTargetElmIfNeeded(focusedNestedUnit?.focused.targetElement) ?? null;
  }

  isMode(mode: EditorModeTypes) {
    return this._mode === mode;
  }

  isShareEditMode() {
    return this.isMode('SHARE_EDIT');
  }

  getMode() {
    return this._mode;
  }

  getModeProperties<T extends EditorModeTypes>(): EditorModeProperties<T> {
    return EditorModes.getProperties(this._mode) as EditorModeProperties<T>;
  }

  doesModeAllow(modeAttr) {
    return EditorModes.getProperties(this._mode).isAllowed(modeAttr);
  }

  doesModeAllowMenuItem(menuItemAttr: MenuTypes) {
    return EditorModes.getProperties(this._mode).isMenuItemAllowed(menuItemAttr);
  }

  getDocParams(): DocParams {
    return {
      indexUid: this._docParams ? this._docParams.documentIndexUid! : null,
      projectUid: this._docParams ? this._docParams.projectUid! : null
    };
  }

  isReadOnly() {
    return ProjectStore.isReadOnly() || !this.doesModeAllow(EditorModes.attributes.editing);
  }

  isInitialized() {
    return this._isInitialized;
  }

  clear() {
    this.state = this.initialState();
    this._isInitialized = false;
  }

  getDocUnits() {
    return this._docUnitCollection.map((wrapper) => wrapper.unit);
  }

  getDocUnitsCollection() {
    return this._docUnitCollection;
  }

  findFirstTocableUnitForSelectedUnit(): IUnit | null {
    const allUnits = [...this.getDocUnits()].reverse();
    const givenUidIndex = allUnits.findIndex((unit) => unit.uid === this.state.selectedUnits[0]?.uid);
    if (givenUidIndex < 0) {
      return null;
    }
    for (let i = givenUidIndex, max = allUnits.length; i < max; i += 1) {
      if (allUnits[i].istocable) {
        return allUnits[i];
      }
    }
    return null;
  }

  getDocUnitModel(uid: string): DocUnitWrapper | undefined {
    return this._docUnitCollection.find((wrapper) => wrapper.unit.uid === uid);
  }

  getDocUnitModelWithSimillarUid(uid: string) {
    return this._docUnitCollection.find((wrapper) => !!wrapper.unit.uid.match(uid));
  }

  getDocUnitModelByIndex(index: number) {
    return this._docUnitCollection[index];
  }

  getSpecialChars() {
    return this.state.specialChars;
  }

  getNextVisibleUnitFromIndex(index: number) {
    return this._docUnitCollection.slice(index + 1).find((wrapper) => wrapper.unit.isVisibleOnEdit);
  }

  getSharedUnitsSelectionInfo() {
    const sharedUnitInfo = SharedUnitSelection.getSelectedShareInfo(
      this._docUnitCollection.map((w) => w.unit),
      this.isShareEditMode()
    );
    let topMostUnit = this._extractMostSeniorUnit(sharedUnitInfo!.sharedUnits);

    // we need to look outside the share range for tocable item
    if (!topMostUnit.isstructural && !topMostUnit.istocable) {
      for (let i = sharedUnitInfo!.start.index; i >= 0; i--) {
        const wrapper = this._docUnitCollection[i];
        if (wrapper.unit.istocable) {
          topMostUnit = wrapper.toJSON();
          break;
        }
      }
    }
    let defaultTitle = '';
    const tocItem = TocStore.getTocItem(topMostUnit.uid);
    if (!!tocItem) {
      defaultTitle = tocItem.ordinal + ' ' + tocItem.heading;
    } else {
      const isOrdinableButNotTocable = !topMostUnit.istocable && topMostUnit.level && topMostUnit.isordinable;
      defaultTitle = _.startCase(topMostUnit.type + (isOrdinableButNotTocable ? ' ' + topMostUnit.level : ''));
    }
    return _.extend(sharedUnitInfo, { defaultTitle: defaultTitle });
  }

  getActiveEditors() {
    return this.state.activeEditors;
  }

  async getLatestDocUnitModel(unitUid: string) {
    const response = await unitsClient.getDocUnit(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, unitUid);
    this._triggerChangedEvent();
    return response;
  }

  isBusy() {
    return this._isStoreBusy;
  }

  isStoreBusyRetrievingPage() {
    return this._isStoreBusyRetrievingPage;
  }

  setBusy(isBusy: boolean, message?: string) {
    this._isStoreBusy = isBusy;
    const event: IEditorStoreEvent<'saving'> = {
      type: 'saving',
      show: isBusy,
      message: message
    };
    this.trigger(event);

    if (!isBusy) {
      this._execWhenReady.forEach((c) => c());
      this._execWhenReady = [];
    }
  }

  execWhenReady(callback: () => void) {
    if (this.isBusy()) {
      this._execWhenReady.push(callback);
    } else {
      callback();
    }
  }

  destroy() {
    this.blurEditor(() => {
      this._editorInstanceManager = null; // ensure on next edit view its gets re-new'ed
      this._docUnitCollection = [];
    });
  }

  abandonEditing() {
    if (this._editorInstanceManager) {
      this._editorInstanceManager.abandon();
    }
  }

  canDeleteUnit(unit: IUnit) {
    const isShareOriginOnBoundary =
      unit.shareDetails && unit.shareDetails.origin && (unit.shareDetails.isShareStartUnit || unit.shareDetails.isShareEndUnit);
    return !isShareOriginOnBoundary && unit.isVisibleOnEdit && TocStore.canDeleteUnit(unit);
  }

  getInsertableElementRule(
    familyType: string,
    insertPosition: InsertAction
  ): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null } {
    // determine if snippet is insertable in current context
    const selectedUnit = this.getSelectedUnit();
    const rules = InsertRulesFactory({
      editingDUProfile: ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
        selectedUnit.definitionId
      ),
      selectedUnit: selectedUnit,
      selectedUnitClosestTocable: getSelectedClosestTocable(selectedUnit)!,
      editingNestedChange: this.getLastFocusedNestedUnit()!,
      isSelected: true,
      isSelectedNotEditing: false,
      isActivelyEditing: true
    });

    const insertable = rules.currentlyInsertableElements(this.getLastFocusedNestedUnit());
    const isElementInsertable = getElementIsInsertable(insertable, null);
    // translate pasteType to element if from a unit..... if in unit def list?
    const elemDefinitionId = ProjectDefinitionStore.toElemDefinitionId(familyType);
    return isElementInsertable(elemDefinitionId, insertPosition === 'insert_inside' ? 0 : 1);
  }

  replaceUnitsWithinCollection(startIndex: number, deleteCount: number, ...newUnits: DocUnitWrapper[]) {
    this._docUnitCollection.splice(startIndex, deleteCount, ...newUnits);
    this._reIndexUnits();
  }

  removeUnitsFromCollection(wrappers: DocUnitWrapper[]) {
    for (const wrapper of wrappers) {
      const unitIndex = this._docUnitCollection.findIndex(({ unit }) => !!wrapper.unit.uid.match(unit.uid));
      this._docUnitCollection.splice(unitIndex, 1); // remove unit
    }
  }

  /** ***********************************************************************************************************
   *
   * Action handlers
   *
   */

  async initEditor(params: DocParams) {
    TocStore.clear();
    SpellCheckStore.clear();
    this.clear();

    this._docParams = params;
    this._docUnitCollection = [];

    this.changeModeStart('EDITING', { silent: true }); // clear any previous mode, silent as don't want to change editor state (mode will be set again in editor state on each render to be sure)

    const docParams = this.getDocParams();
    const loaded = Promise.all([
      this.retrieveSpecialChars(),
      CommentStore.retrieveUnitCommentsMap(docParams, true),
      SmartContentStore.retrieveRegulations(docParams),
      SmartContentStore.retrieveUnitComplianceTagMap(docParams, { silent: true }),
      UnitTaskStore.init(docParams, { silent: true }),
      UnitSpecialInterestTagStore.init(docParams, true),
      ChangeTasksStore.retrieveTasks(docParams),
      TocStore.initToc(params),
      AutoTagStore.retrieveTasks(docParams)
    ]);

    await loaded;
    TocStore.loadingComplete();

    this._isInitialized = true;
    const changeEvent: IEditorStoreEvent<'initEditor'> = {
      type: 'initEditor',
      data: docParams
    };
    this.trigger(changeEvent);

    this.triggerUnitsSelection([]);
  }

  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.activity === 'unitEditBegin' || e.activity === 'unitEditEnd') {
      this.execWhenReady(() => {
        // if on page
        if (this._docUnitCollection.find((w) => w.unit.uid === e.data.unitUid)) {
          const changeEvent: IEditorStoreEvent<'synchronizeUnitEditAction'> = {
            type: 'synchronizeUnitEditAction',
            data: e
          };
          this.trigger(changeEvent);
        }
      });
    } else if (e.activity === 'joined' || e.activity === 'left') {
      this._retrieveActiveEditors().then(() => {
        const changeEvent: IEditorStoreEvent<'activeEditorChange'> = {
          type: 'activeEditorChange',
          data: e
        };

        this.trigger(changeEvent);
      });
    } else if (!e.isUserMe && (e.activity === 'sharedContentPublished' || e.activity === 'sharedContentReverted')) {
      if (e.activity === 'sharedContentPublished') {
        this.setBusy(false);
        this._triggerChangedEvent({ type: 'publishSharedOrigin' });
      } else {
        this.setBusy(false);
        this.triggerUnitsChanged(e.data.affectedUnits);
      }
    } else if (e.activity === 'discarded') {
      if (!e.isUserMe) {
        const changeEvent: IEditorStoreEvent<'indexDiscarded'> = {
          type: 'indexDiscarded',
          data: e
        };

        this.trigger(changeEvent);
      }
    } else if (e.activity === 'locked') {
      if (!e.isUserMe) {
        const changeEvent: IEditorStoreEvent<'indexLocked'> = {
          type: 'indexLocked',
          data: e
        };

        this.trigger(changeEvent);
      }
    } else if (e.activity === 'unlocked') {
      if (!e.isUserMe) {
        const changeEvent: IEditorStoreEvent<'indexUnlocked'> = {
          type: 'indexUnlocked',
          data: e
        };

        this.trigger(changeEvent);
      }
    }
  }

  onUserEventStoreUpdate(e: UserEventStoreEvent) {
    if (e?.type === 'TOC_COPY') {
      if (e.index.uid === this._docParams!.documentIndexUid && TocStore.state.loading === true) {
        const tocDuplicateData = e.data as TocDuplicateData;
        if (tocDuplicateData.steps.length > 0) {
          const refreshOptions = {
            forceDocRefresh: true,
            selectUnit: TocStore.getSelectedItem(),
            snackMessage: `${tocDuplicateData.tocableHeading} has been duplicated`
          };
          if (tocDuplicateData.steps[tocDuplicateData.steps.length - 1] === 'SUCCESS') {
            TocStore.reloadAndNotifyUserOfTocManipulationEvent({ type: 'tocManipulation', data: TocStore.state }, refreshOptions);
          } else if (tocDuplicateData.steps[tocDuplicateData.steps.length - 1] === 'FAILED') {
            let friendlyMessage = tocDuplicateData.tocableHeading.trim();
            if (friendlyMessage.length > 50) {
              friendlyMessage = friendlyMessage.substring(0, 50) + '...';
            }
            TocStore.reloadAndNotifyUserOfTocManipulationEvent(
              { type: 'tocManipulation', snackMessage: 'Cannot duplicate: ' + friendlyMessage, data: TocStore.state },
              null
            );
          }
        }
      }
    } else if (e?.type === 'COMMENT') {
      if (this._docParams && e.index.uid === this._docParams.documentIndexUid) {
        CommentStore.retrieveUnitCommentsMap(this.getDocParams(), true);
      }
    }
  }

  synchronizeEditor(e: any, pageRenderDirectives: Partial<RenderDirective> = {}) {
    if (pageRenderDirectives && pageRenderDirectives.doNothing) {
      return;
    }

    this.execWhenReady(() => {
      if (pageRenderDirectives && pageRenderDirectives.refreshAllUnits) {
        const changeEvent: IEditorStoreEvent<'synchronizeEditor'> = {
          type: 'synchronizeEditor',
          data: {
            affectedUnits: [],
            affectedParts: [],
            refreshView: false,
            user: e.user,
            notifyUser: true
          }
        };

        this.trigger(changeEvent);
        return;
      }

      const tocSelectedUnit = TocStore.getSelectedItem()!;

      const pageTocableUids = [tocSelectedUnit.uid];
      const flattenTocs = (tocUnit) => {
        if (tocUnit.children) {
          tocUnit.children.forEach((child) => {
            pageTocableUids.push(child.uid);
            flattenTocs(child);
          });
        }
      };

      // TODO SB verif
      const mostSeniorTocOnPage = tocSelectedUnit.definitionId.indexOf('LEVEL') === -1 ? tocSelectedUnit : tocSelectedUnit.parent!;
      if (mostSeniorTocOnPage.children) {
        flattenTocs(mostSeniorTocOnPage);
      }

      const affectedUnitsOnPage: IUnit[] = e.data.filter((unit: IUnit) => {
        const unitOnPage = this._docUnitCollection.find((u) => u.unit.uid === unit.uid);

        // unit is on page if: in memory and NOT a CHAP or VOL that is not in tree path which is in memory as REMOVED or GHOST, and being turned back into itself
        // i.e. don't bring trailing removed high level toc elements back to life at end of page!!
        if (unitOnPage) {
          if (
            unit.type === 'tocable' && // new type
            (unitOnPage.unit.definitionId === 'ghost' || unitOnPage.unit.definitionId === 'removed') && // in memory type
            pageTocableUids.indexOf(unit.uid) === -1
          ) {
            // doesn't belong on this page
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });

      // check if toc selected unit has been updated: hard reload
      affectedUnitsOnPage.forEach((unit, uInx) => {
        const affectedPageUnit = affectedUnitsOnPage[uInx];

        // toc selected has changed by another user (but not deleted)
        if (affectedPageUnit.uid === tocSelectedUnit.uid && affectedPageUnit.type !== 'ghost' && affectedPageUnit.type !== 'removed') {
          const dup = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles();
          const tocUnitProfile: IDocUnitProfile | undefined = dup.getUnitProfileByDefinitionId(tocSelectedUnit.definitionId);
          const changeEvent: IEditorStoreEvent<'synchronizeEditor'> = {
            type: 'synchronizeEditor',
            data: {
              affectedUnits: [],
              affectedParts: [],
              user: e.user,
              notifyUser: false,
              changedTocDisplayName: tocUnitProfile ? tocUnitProfile.displayName : 'Unknown'
            }
          };

          this.trigger(changeEvent);
          return;
        }
      });

      // check for new units on this page, if yes: hard reload
      const potentialNewUnitsOnPage = e.data.filter((unit) => this._docUnitCollection.find((u) => u.unit.uid === unit.uid) === undefined);

      // check if potential new units are new child tocables (or children of new/existing tocables)
      const newPageUnits = potentialNewUnitsOnPage.filter(
        (unit) => pageTocableUids.indexOf(unit.uid) !== -1 || pageTocableUids.indexOf(unit.tocableUid) !== -1
      );
      if (newPageUnits.length) {
        const changeEvent: IEditorStoreEvent<'synchronizeEditor'> = {
          type: 'synchronizeEditor',
          data: {
            affectedUnits: [],
            affectedParts: [],
            user: e.user,
            notifyUser: true
          }
        };

        this.trigger(changeEvent);
        return;
      }

      // only updates: update affected units in memory
      const unitUids = affectedUnitsOnPage.map((u) => u.uid);

      if (unitUids?.length > 0) {
        this.setBusy(true, 'Updating document...');
        this._updateMemoryWithLatestUnits(unitUids).then(() => {
          this.setBusy(false);
          if (unitUids.length) {
            const changeEvent: IEditorStoreEvent<'synchronizeEditor'> = {
              type: 'synchronizeEditor',
              data: {
                affectedUnits: unitUids,
                affectedParts: [],
                user: e.user,
                notifyUser: true
              }
            };

            this.trigger(changeEvent);
          }
        });
      }
    });
  }

  onFind(word: string, options) {
    this.changeModeStart('FINDREPLACE', {
      activateParams: { word: word, options: options }
    });
  }

  // same as onChangeModeStart, but if mode is already in specified state reverts back to default mode (EDITING)
  toggleMode(newMode: EditorModeTypes, options?: ChangeOptions) {
    if (this.isMode(newMode)) {
      this.changeModeStart('EDITING');
    } else {
      this.changeModeStart(newMode, options);
    }
  }

  resetMode() {
    if (!this.isMode('EDITING')) {
      this.changeModeStart('EDITING');
    }
  }

  // changeModeStart is a 2 step process, start: deactivate existing and refresh units accordingly, complete: activate new and refresh units accordingly

  changeModeStart(newMode: EditorModeTypes, options?: ChangeOptions) {
    this.getEditor().blur();

    this.execWhenReady(() => {
      const oldMode = this._mode;
      this._mode = newMode;

      EditorModes.getProperties(oldMode).deActivate();

      if (!(options && options.silent)) {
        const changeEvent: IEditorStoreEvent<'changeModeStart'> = {
          type: 'changeModeStart',
          data: {
            from: oldMode,
            to: this._mode,
            options: options
          }
        };

        this.trigger(changeEvent);
      }
    });
  }

  changeModeComplete(data: Partial<ModeSyncData>) {
    EditorModes.getProperties(this._mode).activate(this._docUnitCollection, data.options ? data.options.activateParams : null);

    const changeEvent: IEditorStoreEvent<'changeModeComplete'> = {
      type: 'changeModeComplete',
      data: data
    };

    this.trigger(changeEvent);
  }

  async publishPreview() {
    const project = ProjectStore.getProject()!;

    if (project.currentUserPermissions?.canRead) {
      const indexUid = this._docParams!.documentIndexUid;

      try {
        await projectClient.createPreview(project.uid, indexUid!);
        NotificationsStore.startNotifProgress(); // will open notif popup to show progress
      } catch (err) {
        const axiosError = err as AxiosError;
        this.triggerShowEditorError({ axiosErr: axiosError });
      }
    }
  }

  async retrieveSpecialChars() {
    this.state.specialChars = await specialChars.getCharacters();
    const changeEvent: IEditorStoreEvent<'specialCharsUpdated'> = {
      type: 'specialCharsUpdated',
      data: this.state.specialChars
    };

    this.trigger(changeEvent);
  }

  _updateFullyLoadedUnits(unitWrappers: DocUnitWrapper[], initial = false) {
    if (!unitWrappers || unitWrappers.length <= 0) {
      return;
    }

    // Sometimes first units are a generated ones (i.e. a header, frontmatter)
    const firstNotGeneratedUnitIndex = unitWrappers.findIndex((docUnitWrapper) => !UnitUtils.isGeneratedUnit(docUnitWrapper.unit));
    if (firstNotGeneratedUnitIndex === -1 || initial) {
      // no real units, only generated ones OR ensure collection reset on new toc load. Just replace _docUnitCollection
      this._docUnitCollection = unitWrappers;
    } else {
      const generatedUnits = unitWrappers.slice(0, firstNotGeneratedUnitIndex);
      const unitsToUpdate = unitWrappers.slice(firstNotGeneratedUnitIndex);

      const insertUnitUid = unitsToUpdate[0].unit.uid;
      let insertionStartIndex = this._docUnitCollection.findIndex((docUnitWrapper) => docUnitWrapper.unit.uid === insertUnitUid);

      if (insertionStartIndex < 0) {
        console.error('Cannot find a unit in the list, unit uid = ' + insertUnitUid);
      }

      // In case of generated units: insert them before the real units
      if (firstNotGeneratedUnitIndex > 0) {
        this._docUnitCollection.splice(insertionStartIndex, 0, ...generatedUnits);
        insertionStartIndex += generatedUnits.length;
      }

      let offsetAdjust = 0; // adjust offset for all the skipped units (with no html)
      unitsToUpdate.forEach((unitWrapper: DocUnitWrapper, offset: number) => {
        const insertionPoint = insertionStartIndex + offset + offsetAdjust;

        if (this._docUnitCollection.length <= insertionPoint) {
          if (!!unitWrapper.unit.html) {
            // end of collection, add extra unit at the end
            this._docUnitCollection.push(unitWrapper);
          } else {
            // skip the unit, wasn't on the list and it has no html (most likely 'removed' or 'ghost' from the previous publication)
            offsetAdjust--;
          }
        } else {
          if (this._docUnitCollection[insertionPoint]?.unit.uid === unitWrapper.unit.uid) {
            // right unit at the right index
            this._docUnitCollection[insertionPoint] = unitWrapper;
          } else {
            // unit not found at the insertionPoint position, try and search whole _docUnitCollection
            const index = this._docUnitCollection.findIndex((docUnitWrapper) => docUnitWrapper.unit.uid === unitWrapper.unit.uid);
            const specialUnitType = UnitUtils.isVirtualUnitUid(unitWrapper.unit.uid);

            if (index > -1 && !specialUnitType) {
              // Unit was found at a different location. Can't do much about it apart from logging it as an error.
              Log.error(
                `Got a unit with uid: ${unitWrapper.unit.uid}, definitionId: ${unitWrapper.unit.definitionId},
                which is at a different location in unitInfos and units api endpoint results. 
                unitInfos endpoint position: ${index}
                , units endpoint position: ${insertionPoint}`
              );
            } else {
              // it's a new unit not found in _docUnitCollection
              if (!!unitWrapper.unit.html) {
                // if unit has an html then insert it at the position and shift whole array to the right
                this._docUnitCollection.splice(insertionPoint, 0, unitWrapper);
              } else {
                // skip the unit, wasn't on the list and it has no html (most likely 'removed' or 'ghost' from the previous publication)
                offsetAdjust--;
              }
            }
          }
        }
      });
    }

    this._reIndexUnits();

    return this._docUnitCollection;
  }

  updateDocUnitSearchIndex(index: number) {
    this._docUnitSearchIndex = index;
  }

  _findFirstNotFullyLoadedUnit(): number {
    const startingPosition: number = this._docUnitSearchIndex;

    let returnIndex;
    if (startingPosition > -1) {
      const secondHalf = this._docUnitCollection.slice(startingPosition);
      let index: number = secondHalf.findIndex((docUnit: DocUnitWrapper) => !docUnit.isFullyLoaded());

      if (index > -1) {
        // found a unit without html below the startingPosition
        returnIndex = startingPosition + index;
      } else {
        // try and find a unit in the set above the startingPosition
        const firstHalf = this._docUnitCollection.slice(0, startingPosition).reverse();

        // find first not loaded unit counting from startingPosition upwards
        let reverseIndex: number = firstHalf.findIndex((docUnit: DocUnitWrapper) => !docUnit.isFullyLoaded());

        if (reverseIndex > -1) {
          reverseIndex = reverseIndex + (ServerSettingsStore.getServerSettings().ivsUnitsPageSize ?? this.unitsRequiredPerRequest) - 1;

          index = startingPosition - reverseIndex;
          // safe guard
          if (index < 0) {
            index = 0;
          }

          returnIndex = index;
        } else {
          returnIndex = -1;
        }
      }
    } else {
      returnIndex = this._docUnitCollection.findIndex((docUnit: DocUnitWrapper) => !docUnit.isFullyLoaded());
    }

    const firstNotGeneratedUnitIndex = this._docUnitCollection.findIndex(
      (docUnitWrapper) => !UnitUtils.isGeneratedUnit(docUnitWrapper.unit)
    );

    // adjust returnIndex by subtracting any virtual units which were returned by /units endpoint but not by /unit-infos one. (e.g. compliance-reg-tag or table-of-contents-chapter-frontmatter)
    // calculate how many virtual units are before returnIndex.
    const noOfVirtualUnits: number = [...this._docUnitCollection]
      .slice(0, returnIndex)
      .reduce((noOfVirtualUnits, docUnit) => (UnitUtils.isVirtualUnitUid(docUnit?.unit?.uid) ? noOfVirtualUnits + 1 : noOfVirtualUnits), 0);

    for (let i = returnIndex; i >= returnIndex - noOfVirtualUnits - 1; i--) {
      if (!UnitUtils.isVirtualUnitUid(this._docUnitCollection[i]?.unit?.uid)) {
        returnIndex = i;
        break;
      }
    }

    return firstNotGeneratedUnitIndex > -1 ? returnIndex - firstNotGeneratedUnitIndex : returnIndex;
  }

  // First try on loading units in pages - no jumping ahead etc.
  async _loadNextPageOfUnits(docParams: DocParams, params: Partial<RetrieveUnitParams & GetUnitOptions>) {
    // find the start unit uid
    const notLoadedIndex = this._findFirstNotFullyLoadedUnit();

    // if start unit uid found then get a page of units:
    if (notLoadedIndex >= 0) {
      params.offsetUnitUid = this._docUnitCollection[notLoadedIndex - 1]?.unit?.uid;
      params.includeHeader = notLoadedIndex === 0;

      await unitsClient.getUnits(docParams.projectUid!, docParams.indexUid!, params).then(
        (result) => {
          if (result.units?.length > 0) {
            this._updateFullyLoadedUnits(result.units);
            this.trigger({ type: 'loadMoreUnits' });
            this._loadNextPageOfUnits(docParams, params);
          }
        },
        () => {
          console.error("Couldn't retrieve units, most probably previous call was cancelled.");
        }
      );
    }
  }

  async retrieveUnits(params: RetrieveUnitParams) {
    let currentToc: ITocNode | null = null;
    this.setBusy(true, 'Retrieving Document...');
    this._isStoreBusyRetrievingPage = true;
    this._docUnitSearchIndex = -1;

    // cancel any previous GET units requests
    unitsClient.cancelGetUnits();

    this.triggerBusyEvent(this._isStoreBusyRetrievingPage);

    const editFocusedUnit = this.isEditorFocused()
      ? this._docUnitCollection.find((u) => u.unit.uid === this.getSelectedUnit()!.uid)!.toJSON()
      : null;
    let retrieveParams: Partial<RetrieveUnitParams & GetUnitOptions> = _.cloneDeep(params);
    retrieveParams = _.extend({}, retrieveParams, EditorModes.getProperties(this._mode).getRetrieveUnitParams());
    const docParams = this.getDocParams();

    if (retrieveParams.tocableUnitUid) {
      currentToc = TocStore.getTocItem(retrieveParams.tocableUnitUid);
    }

    const { uid } = TocStore.getFirstSelectedTocableUnit()!;

    this._docUnitCollection = await unitsClient.getUnitInfos(docParams.projectUid!, docParams.indexUid!, uid);

    if (!!params.offsetUnitUid) {
      let offset = this._docUnitCollection.findIndex((u) => u.unit.uid === params.offsetUnitUid) - 50;
      if (offset < 0) {
        offset = 0;
      }
      retrieveParams.offsetUnitUid = this._docUnitCollection[offset - 1]?.unit?.uid;
    }

    const isLargeContent = this._docUnitCollection.length > (ServerSettingsStore.getServerSettings().ivsLargeContentThreshold ?? 300);
    if (isLargeContent) {
      this._reIndexUnits();
      this.triggerBusyEvent(false);
      this.trigger({ type: 'retrieveUnitInfos' });

      if (!!params.offsetUnitUid) {
        this.scrollToUnit(params.offsetUnitUid);
      }
    }

    try {
      if (isLargeContent) {
        await Promise.all([
          SmartContentStore.retrieveRegulations(docParams),
          SmartContentStore.retrieveTocSharedUsages(currentToc?.uid ?? retrieveParams.tocableUnitUid, currentToc, docParams)
        ]);

        await this._loadNextPageOfUnits(docParams, retrieveParams);
      } else {
        retrieveParams.unitsRequired = undefined;
        const response = await Promise.all([
          SmartContentStore.retrieveRegulations(docParams),
          SmartContentStore.retrieveTocSharedUsages(currentToc?.uid ?? retrieveParams.tocableUnitUid, currentToc, docParams),
          unitsClient.getUnits(docParams.projectUid!, docParams.indexUid!, retrieveParams)
        ]);

        this._updateFullyLoadedUnits(response[2].units);
      }

      this._reIndexUnits();

      // if editing was occuring when page data changes: if unit still in memory: ensure it exists in original (pre-edited) form in memory
      // any subsequent save may get versioning issues etc if changed by other users
      if (editFocusedUnit && this._docUnitCollection.find((u) => u.unit.uid === editFocusedUnit.uid)) {
        // Merge the server object and focus object, but remove any properties that were
        // removed from the server
        const serverObj = this._docUnitCollection.find((u) => u.unit.uid === editFocusedUnit.uid)!.toJSON();
        for (const i in editFocusedUnit) {
          if (!serverObj.hasOwnProperty(i)) {
            delete editFocusedUnit[i];
          }
        }

        if (params.takeUnitHtmlFromServer && editFocusedUnit.html && serverObj.html) {
          editFocusedUnit.html = serverObj.html;
        }

        const wrapper = new DocUnitWrapper(editFocusedUnit);
        const index = this._docUnitCollection.findIndex((u) => u.unit.uid === editFocusedUnit.uid);
        const isFound = index !== -1;
        if (params.takeUnitHtmlFromServer && isFound) {
          this._docUnitCollection.splice(index, 1, wrapper);
        } else if (!isFound) {
          this._docUnitCollection.push(wrapper);
        }
        this._reIndexUnits();

        // Corner case when another user updated a selected toc unit.
        // In that case we need to do page hard refresh and inform user about it.
        // All current changes done by the user in the active editor will be lost.
        if (params.takeUnitHtmlFromServer && isFound && params.changedTocDisplayName) {
          appStore.dispatch<any>(
            showSystemSnackbarMessageWithDefaults(
              `Page refreshed. Another user updated unit ${params.changedTocDisplayName} on the screen.`
            )
          );
          this.getEditor().blur({ ignoreChanges: true });
        }
      }

      // allow mode decoration of units if required
      EditorModes.getProperties(this._mode).onActivationUnitsRetrieved(this._docUnitCollection);

      // complete any promise made
      this._isStoreBusyRetrievingPage = false;
      this.triggerBusyEvent(this._isStoreBusyRetrievingPage);
      this.setBusy(false);
    } catch (err) {
      const axiosErr = err as AxiosError;

      if (axiosErr.response && axiosErr.response.data.errors && axiosErr.response.data.errors.length > 0) {
        if (axiosErr.response.data.errors[0].code === 40406) {
          // refreshToc since currently selected toc item has been removed, select first tocable
          TocStore.refreshToc({
            removedUnit: TocStore.getSelectedItem()!,
            forceDocRefresh: true
          }).then(() => {
            this._isStoreBusyRetrievingPage = false;
            this.triggerBusyEvent(this._isStoreBusyRetrievingPage);
            this.setBusy(false);
            this._handleError(axiosErr, [404]);
          });
        }
      } else {
        this._isStoreBusyRetrievingPage = false;

        this.triggerBusyEvent(this._isStoreBusyRetrievingPage);
      }
      this.setBusy(false);
    }
  }

  public triggerBusyEvent(busy = true) {
    this.trigger({
      type: 'editorIsBusy',
      busy
    });
  }

  async retrieveUnit(uid: string) {
    const docUnit = this.getDocUnitModel(uid);

    if (docUnit) {
      const newWrapper = await unitsClient.getDocUnit(docUnit.unit.projectUid!, docUnit.unit.indexUid!, uid);
      docUnit.unit = newWrapper.unit;

      const event: IEditorStoreEvent<'retrieveUnit'> = {
        type: 'retrieveUnit',
        data: newWrapper.toJSON()
      };

      this.trigger(event);
    }
  }

  async revertUnit(uid: string, activityUid: string, revertHtml: string) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Reverting...');
      const docUnit = this.getDocUnitModel(uid);

      if (docUnit) {
        docUnit.unit.html = revertHtml;
        const response = await unitsClient.updateDocUnit(docUnit.unit.projectUid!, docUnit.unit.indexUid!, docUnit.unit, activityUid);
        UnitHighlightStore.retrieveTocHighlight();

        if (!response.wrapper.unit.highlight) {
          delete docUnit.unit.highlight;
        } else {
          docUnit.unit.highlight = response.wrapper.unit.highlight;
        }
        this.triggerUnitsChanged([response.wrapper.unit], { parse: false }, { confirmMessage: 'reverted' });
        this.setBusy(false);
      }
    } else {
      console.log('EditorStore: onRevertUnit: ignoring revert request, as store is busy');
    }
  }

  async backendReplace(options: Partial<spellCheck.ReplaceOptions>) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Replacing elements...');

      try {
        const res = await spellCheck.replace(ProjectStore.getProject()!.uid, ProjectStore.getCurrentRevisionUid(), options);
        UnitHighlightStore.retrieveTocHighlight();

        res.units
          .filter((unit) => {
            const docCollection = this._docUnitCollection;
            return (
              docCollection
                .map((m) => {
                  return m.unit.uid;
                })
                .indexOf(unit.uid!) !== -1
            );
          })
          .forEach((unit) => {
            const wrapper = new DocUnitWrapper(unit as IUnit);
            const index = this._docUnitCollection.findIndex((u) => u.unit.uid === unit.uid);
            this._docUnitCollection.splice(index, 1, wrapper);
          });

        this._reIndexUnits();

        this._triggerChangedEvent({ type: 'replaceUnit', data: { units: res.units } });
        this.setBusy(false);
        return;
      } catch (err) {
        this._handleError(err as AxiosError, [403, 412]);
      }
    }
  }

  async createUnit(
    unit: { type?: UnitTypes; template?: string; definitionId?: string },
    siblingUnitUid: string,
    createOptions: {
      launchEditor?: boolean;
      above?: boolean;
      scrollToUnit?: boolean;
      openEditPaneOnUnit?: boolean;
      isPaste?: boolean;
    } = {},
    parentUnitUid?: string
  ) {
    if (!this.isBusy()) {
      return this.createUnitAddon.createUnit(
        this.getDocParams(),
        {
          type: unit.type,
          definitionId: unit.definitionId,
          templateHtml: unit.template,
          siblingUnitUid,
          parentUnitUid
        },
        {
          insertPosition: createOptions.above ? InsertPosition.BEFORE_SELECTED : InsertPosition.AFTER_SELECTED,
          launchEditor: createOptions.launchEditor,
          scrollToUnit: createOptions.scrollToUnit,
          openEditPaneOnUnit: createOptions.openEditPaneOnUnit,
          isPaste: createOptions.isPaste
        }
      );
    }
    console.log('EditorStore: onCreateUnit: ignoring create request, as store is busy');
  }

  async createBatchUnits(units: IUnit[], createOptions: { above: boolean } = { above: false }) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Creating new elements...');
      const docParams = this.getDocParams();
      const selectedUnits = this.getSelectedUnits();
      const selectedOrdered = _.sortBy(selectedUnits, ['index'], ['asc']);
      const afterSiblingUnitUid = selectedOrdered[selectedOrdered.length - 1].uid;
      const beforeSiblingUnitUid = selectedOrdered[0].uid;
      const siblingUnit = this.getDocUnitModel(createOptions.above ? beforeSiblingUnitUid : afterSiblingUnitUid);

      try {
        const response = await unitsClient.createBatch(
          docParams.projectUid!,
          docParams.indexUid!,
          units,
          createOptions.above ? { beforeUnitUid: beforeSiblingUnitUid } : { afterUnitUid: afterSiblingUnitUid }
        );
        UnitHighlightStore.retrieveTocHighlight();
        const newParsedUnits = this._insertUnitsInMemory(
          response.units,
          (siblingUnit ? siblingUnit.unit.index : 0) + (createOptions.above ? 0 : 1)
        );
        this._performBatchDocChangeMemoryUpdate(
          response.unitUids,
          response.parts,
          response.refreshView,
          newParsedUnits,
          'createBatchUnits',
          { isCreate: true }
        );
      } catch (err) {
        this._handleError(err as AxiosError, [400, 403, 412]);
      }
    }
  }

  mergeSelectedUnitToPrevious() {
    const selectedUnit = this.getSelectedUnit()!;
    const previousUnit = this.getPreviousToSelectedUnit();

    if (
      this._editorInstanceManager &&
      previousUnit &&
      ProjectStore.getTypeValidMerges(previousUnit.definitionId).indexOf(selectedUnit.definitionId) !== -1
    ) {
      this._editorInstanceManager.blur({}, () => {
        this.execWhenReady(() => {
          this._mergeUnits([{ uid: selectedUnit.uid }, { uid: previousUnit.uid }]);
        });
      });
    }
  }

  mergeSelectedUnits() {
    const selectedUnits = this.getSelectedUnits();
    if (selectedUnits.length > 1) {
      this._mergeUnits(
        selectedUnits.map((u) => {
          return { uid: u.uid };
        })
      );
    }
  }

  splitSelectedUnits() {
    const editor = this.getEditor().getActiveEditorFacade()!;
    const splitMarker = editor.insertSplitMarker();
    const selectedUnit = this.getSelectedUnit();

    if (selectedUnit) {
      const details = { ...this.getEditor().getEditingDetails() };
      editor.removeSplitMarker(splitMarker);
      this._splitUnit(selectedUnit, details.html!);
    }
  }

  // all client side split (simple units like P and list-item for now)
  splitUnitFromKeypress() {
    const tinyFacade = this.getEditor().getActiveEditorFacade()!;
    const splitContent = tinyFacade.cutTextAfterCursor();

    setTimeout(() => {
      this.blurEditor(() => {
        this.execWhenReady(() => {
          try {
            const selectedUnit = this.getSelectedUnit()!;
            const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
              selectedUnit.definitionId
            );

            const selectedDom = $('<div>' + selectedUnit.html + '</div>');
            if ((profile?.type === 'paragraph' || profile?.type === 'pre') && !profile.subType) {
              const split = $(profile.template!).find('.edit-target').html(splitContent).html() === '' ? '' : splitContent;
              selectedDom.find('.edit-target').empty().append(split);
            } else if (profile?.template) {
              selectedDom
                .children()
                .empty()
                .append(profile?.template?.replace(/[\u2060]/, $('<div>' + splitContent + '</div>').text() === '' ? '' : splitContent));
            }

            selectedDom.removeAttr('data-nid').find('*').removeAttr('data-nid');

            const newUnitContent = profile?.sanitize!(selectedDom.html(), undefined, profile);
            this.createUnit({ template: newUnitContent, definitionId: selectedUnit.definitionId }, selectedUnit.uid);
          } catch (e) {
            // restore deleted text if something goes wrong
            this.undo();
          }
        });
      });
    }, 10);
  }

  async formatSelectedUnits(isIncrease: boolean, formatType: string) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Formatting elements...');

      const selectedUnits = this.getSelectedUnits();
      const positionClassPrefix = formatType[0];
      const formatter = ProjectDefinitionStore.getFormattingValues().find((format) => format.propertyName.toLowerCase() === formatType)!;
      const validSelectedUnits = selectedUnits.filter((unit) => unit.isVisibleOnEdit);

      validSelectedUnits.forEach((selectedUnit) => this._formatUnit(selectedUnit, positionClassPrefix, formatType, isIncrease, formatter));

      const params = this.getDocParams();
      try {
        const response = await unitsClient.convertBatch(params.projectUid!, params.indexUid!, validSelectedUnits);
        UnitHighlightStore.retrieveTocHighlight();
        this._onUnitsSave(response.units, response.unitUids, response.parts, response.refreshView);
      } catch (err) {
        this._handleError(err as AxiosError, [400, 403, 412]);
      }

      // let docUnitBatch = new DocumentUnitBatch( this.getDocParams() );

      // if ( validSelectedUnits.length > 0 ) {
      //   docUnitBatch.save( { units: validSelectedUnits }, { convertingUnits: true } as any )
      //     .then( () => this._onUnitsSave( docUnitBatch ) )
      //     .fail( error => this._handleError( error, [ 400, 403, 412 ] ) );
      // }
    }
  }

  _formatUnit(unit: IUnit, positionClassPrefix: string, formatType: string, isIncrease: boolean, formatter: IElementStyle): void {
    const arcMLDefaults = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(unit.definitionId)
      ?.arcMLDefaults?.root;
    const $selectedDom = $('<div>' + unit.html + '</div>');
    const $unitElement = $selectedDom.find('>.arc-unit');
    const currentClassNames = $unitElement.attr('class') || '';
    const formatClassName = currentClassNames
      .split(' ')
      .filter((className) => className.match(/[t|r|b|l][0-9][0-9]/) && className[0] === positionClassPrefix);

    let currentFormatClass = positionClassPrefix + '0' + arcMLDefaults?.[formatType];
    let currentFormatValue = arcMLDefaults?.[formatType];
    if (formatClassName.length > 0) {
      currentFormatClass = formatClassName[0];
      currentFormatValue = parseInt(currentFormatClass.split(positionClassPrefix)[1], 10);
    }

    currentFormatValue += isIncrease ? +1 : -1;

    if (currentFormatValue >= formatter.minValue && currentFormatValue <= formatter.maxValue) {
      const paddedNumber = currentFormatValue <= 9 ? '0' + currentFormatValue : currentFormatValue;
      $unitElement.removeClass(currentFormatClass).addClass(positionClassPrefix + paddedNumber);
    }

    unit.html = $selectedDom.html();
  }

  async _onUnitsSave(docUnitBatch: DocUnitWrapper[], affectedUnits: string[], affectedParts: string[], refreshView: boolean) {
    const unitBatchJSON = docUnitBatch.map((u) => u.toJSON());

    if (unitBatchJSON.length > 0) {
      // just a format so no need to do refresh dance (i.e. ordinals will not be changed)
      await this._checkUnitTagMapUpdate(this._updateUnitsInMemory(unitBatchJSON));

      this.setBusy(false);

      this._triggerChangedEvent({
        type: 'formatSelectedUnits',
        data: {
          affectedUnits: affectedUnits,
          affectedParts: affectedParts,
          refreshView: refreshView,
          renderBehaviour: {
            isUpdate: true
          }
        }
      });
    }
  }

  convertSelectedUnits(toUnitDefinitionId: string) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Converting elements...');

      const selectedUnits = this.getSelectedUnits();
      if (selectedUnits && selectedUnits.length > 1) {
        this._convertBatchUnits(selectedUnits, toUnitDefinitionId);
      } else if (selectedUnits && selectedUnits.length === 1) {
        this._convertUnit(selectedUnits[0], toUnitDefinitionId);
      }
    }
  }

  convertSelectedElement(toElementDefinitionId: string) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Converting elements...');

      const selectedUnits = this.getSelectedUnits();
      const { type, subType } = ProjectDefinitionStore.getElementDefinitionById(toElementDefinitionId.replace(/^el_/, ''))!;

      this._convertElement(selectedUnits[0], type, subType);
    }
  }

  async _convertBatchUnits(selectedUnits: IUnit[], toUnitDefinitionId: string) {
    const multiUnits = _.cloneDeep(selectedUnits);

    const toProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(toUnitDefinitionId);
    if (!toProfile) {
      return;
    }

    multiUnits.forEach((u) => {
      u.type = toProfile.type as UnitTypes;

      if (toProfile.subType) {
        u.subType = toProfile.subType;
      } else {
        delete u.subType;
      }

      if (toProfile.level) {
        u.level = toProfile.level as UnitTypes;
      } else {
        delete u.level;
      }
    });

    try {
      const docParams = this.getDocParams();
      const response = await unitsClient.convertBatch(docParams.projectUid!, docParams.indexUid!, multiUnits);
      UnitHighlightStore.retrieveTocHighlight();

      // let unitBatchJSON = docUnitBatch.toJSON();
      if (response.units && response.units.length > 0) {
        this._performBatchDocChangeMemoryUpdate(
          response.unitUids,
          response.parts,
          response.refreshView,
          this._updateUnitsInMemory(response.units.map((u) => u.unit)),
          'convertBatchUnits',
          { isUpdate: true }
        );
      }
    } catch (err) {
      this._handleError(err as AxiosError, [400, 403, 412]);
    }
  }

  async _convertUnit(selectedUnit: IUnit, toUnitDefinitionId: string) {
    const convertUnit = _.cloneDeep(selectedUnit);

    const toProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(toUnitDefinitionId);
    if (!toProfile) {
      return;
    }

    if (toProfile.type) {
      convertUnit.type = toProfile.type;
    }

    if (toProfile.subType) {
      convertUnit.subType = toProfile.subType;
    } else {
      delete convertUnit.subType;
    }

    if (toProfile.level) {
      convertUnit.level = toProfile.level;
    } else {
      delete convertUnit.level;
    }

    const docParams = this.getDocParams();
    try {
      const response = await unitsClient.convertDocUnit(docParams.projectUid!, docParams.indexUid!, convertUnit);
      UnitHighlightStore.retrieveTocHighlight();
      this._performDocChangeMemoryUpdate(
        {
          newUnitWrapper: response.wrapper,
          parts: response.parts,
          unitUids: response.unitUids,
          refreshView: response.refreshView,
          previousUnits: this._updateUnitsInMemory([response.wrapper.unit]),
          renderBehaviour: {
            isUpdate: true
          }
        },
        'convertBatchUnits'
      );
    } catch (err) {
      this._handleError(err as AxiosError, [400, 403, 412, 409], null, convertUnit);
    }
  }

  async _convertElement(selectedUnit: any, targetType: string, targetSubType: string | null = null) {
    const project = ProjectStore.getProject()!;
    const unitUid = selectedUnit.uid;
    const projectUid = project.uid;
    const indexUid = project.masterIndexUid;
    const selectedElement = $(tinymce.activeEditor.selection.getNode()).closest('[data-element-definition-id]');
    const selectedElementHTML = selectedElement[0].outerHTML;
    try {
      const convertedElementHTML = await convertElement(projectUid, indexUid, unitUid, targetType, targetSubType, selectedElementHTML);
      UnitHighlightStore.retrieveTocHighlight();
      $(selectedElement).replaceWith(convertedElementHTML);
      tinymce.activeEditor.nodeChanged();
    } catch (err) {
      console.log(err);
    } finally {
      this.setBusy(false);
    }
  }

  deleteUnitSelectedUnit() {
    this.deleteUnit(this.getSelectedUnit());
  }
  deleteUnit(unit: IUnit) {
    if (!this.isBusy() && this.canDeleteUnit(unit)) {
      const event: IEditorStoreEvent<'deleteUnit'> = {
        type: 'deleteUnit',
        data: unit
      };

      this.trigger(event);
    } else {
      console.log('EditorStore: onDeleteUnit: ignoring delete request, as store is busy, or this unit cannot be deleted');
    }
  }

  deleteSelectedUnits() {
    const unDeletable = this.getSelectedUnits().filter((u) => !this.canDeleteUnit(u));

    if (!this.isBusy() && unDeletable.length === 0) {
      const event: IEditorStoreEvent<'deleteSelectedUnits'> = {
        type: 'deleteSelectedUnits'
      };

      this.trigger(event);
    } else {
      console.log(
        'EditorStore: onDeleteSelectedUnits: ignoring delete request, as store is busy, or one or more of these units cannot be deleted'
      );
    }
  }

  async deleteSelectedUnitsConfirm(
    busyMessage = 'Deleting Multiple...',
    confirmMessage: RenderOptions['confirmMessage'] = `Deleted ${this.getSelectedUnits().length} elements`
  ) {
    if (!this.isBusy() && this.getSelectedUnits().filter((u) => !this.canDeleteUnit(u)).length === 0) {
      this.setBusy(true, busyMessage);
      const docParams = this.getDocParams();
      try {
        const response = await unitsClient.deleteBatch(
          docParams.projectUid!,
          docParams.indexUid!,
          this.getSelectedUnits().map((u) => {
            return { uid: u.uid, lastVersionUid: u.versionUid! };
          })
        );
        const fetchBatchOrPayload: boolean | IUnit[] = response.units.length === response.unitUids.length ? response.units : true;
        UnitHighlightStore.retrieveTocHighlight();
        await this._performBatchDocChangeMemoryUpdate(
          response.unitUids,
          response.parts,
          response.refreshView,
          null,
          'deleteSelectedUnitsConfirm',
          {
            isDelete: true,
            confirmMessage
          },
          fetchBatchOrPayload
        );
      } catch (err) {
        this._handleError(err as AxiosError, [400, 403, 412]);
      }
    } else {
      console.log(
        'EditorStore: onDeleteSelectedUnitsConfirm: ignoring multi delete request, as store is busy, or these units cannot be deleted'
      );
    }
  }

  async deleteUnitConfirm(unit: IUnit, busyMessage = 'Deleting...', confirmMessage: RenderOptions['confirmMessage'] = 'deleted') {
    if (!this.isBusy() && this.canDeleteUnit(unit)) {
      this.setBusy(true, busyMessage);
      this.getEditor().blur({ ignoreChanges: true });
      const docUnit = this.getDocUnitModel(unit.uid)!;

      try {
        const response = await unitsClient.deleteUnit(docUnit.unit.projectUid!, docUnit.unit.indexUid!, docUnit.unit.uid);
        UnitHighlightStore.retrieveTocHighlight();
        const originalUnitJSON = docUnit.toJSON();

        // destroy removes it from list: add back in as we want to show ghost!
        docUnit.unit = response.unit;
        await this._performDocChangeMemoryUpdate(
          {
            newUnitWrapper: docUnit,
            parts: response.parts,
            unitUids: response.unitUids,
            refreshView: response.refreshView,
            previousUnits: [originalUnitJSON],
            renderBehaviour: {
              isDelete: true,
              confirmMessage
            }
          },
          'deleteUnitConfirm'
        );
      } catch (err) {
        if (!this._handleError(err as AxiosError, [400, 403, 412])) {
          // no removed JSON, delete error occurred: if not found on remove
          // (as another user already deleted it for example), that's ok, just clear off screen

          const response = await unitsClient.getDocUnit(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, docUnit.unit.uid);
          await this._performDocChangeMemoryUpdate(
            {
              newUnitWrapper: response,
              parts: [],
              unitUids: [],
              refreshView: false,
              previousUnits: [response.unit],
              renderBehaviour: {
                isDelete: true,
                confirmMessage
              }
            },
            'deleteUnitConfirm'
          );

          this.setBusy(false);
        }
      }
    } else {
      console.log('EditorStore: onDeleteUnitConfirm: ignoring delete request, as store is busy, or this unit cannot be deleted');
    }
  }
  async eventualUpdateUnit(editorUpdate: EditBlurData, completeCallback?: UpdateUnitCallback, options: UpdateUnitOptions = {}) {
    if (this._isStoreBusyRetrievingPage || !this.isBusy()) {
      await this.updateUnit(editorUpdate, completeCallback, options);
    } else {
      setTimeout(() => {
        this.eventualUpdateUnit(editorUpdate, completeCallback, options);
      }, 300);
    }
  }

  async refreshUnit(unitUid: string) {
    if (this._docParams?.projectUid && this._docParams.documentIndexUid) {
      const docUnit: DocUnitWrapper = await unitsClient.getDocUnit(this._docParams.projectUid, this._docParams.documentIndexUid, unitUid);

      await this._performDocChangeMemoryUpdate(
        {
          newUnitWrapper: docUnit,
          parts: [],
          unitUids: [unitUid],
          previousUnits: [docUnit.unit],
          refreshView: false,
          renderBehaviour: { isUpdate: true }
        },
        'updateUnit'
      );
    }
  }

  async updateUnit(editorUpdate: EditBlurData, completeCallback?: UpdateUnitCallback, options: UpdateUnitOptions = {}) {
    if (this._isStoreBusyRetrievingPage || !this.isBusy()) {
      // more nuanced: its ok to update a unit if a page is loading, i.e. editing unit, toc/activity clicked, should allow update thru'
      try {
        this.getEditor().getActiveEditorFacade()?.clearSelection();
      } catch (e) {
        Log.info('Exception when looking up active editor facade');
      }

      if (editorUpdate.isDirty) {
        const docUnit = this.getDocUnitModel(editorUpdate.uid!);

        Log.info('EditorStore: onUpdateUnit: performing update for uid: ' + editorUpdate.uid);

        if (this._isStoreBusyRetrievingPage) {
          console.log('EditorStore: onUpdateUnit: performing update even though page is loading another set of units');
        }

        this.setBusy(true, 'Updating...');

        // unit not found on page it means doc has moved on as user has clicked on a toc or something
        // *silently* continue with the intended save
        if (!docUnit) {
          this.setBusy(false);

          const docIndexUid = this._docParams!.documentIndexUid!;

          try {
            const docUnit = await unitsClient.getDocUnit(this._docParams!.projectUid!, docIndexUid, editorUpdate.uid!);
            await unitsClient.updateDocUnit(this._docParams!.projectUid!, docIndexUid, {
              ...docUnit.unit,
              html: editorUpdate.html,
              indexUid: docIndexUid
            });
            UnitHighlightStore.retrieveTocHighlight();
          } catch (err) {
            console.log('Could not retrieve doc unit to save it - probably as its been removed from the system - this is ok!');
          }
        } else {
          if (options.forceVersionOverwrite) {
            docUnit.unit.versionUid = null;
          }
          const hasAutoTagTasks = !!AutoTagStore.getTasks().length;

          try {
            const docParams = this.getDocParams();
            const response = await unitsClient.updateDocUnit(docParams.projectUid!, docParams.indexUid!, {
              ...docUnit.unit,
              html: editorUpdate.html
            });
            UnitHighlightStore.onUnitUpdate(editorUpdate.uid!, response.wrapper.unit.html);
            const isOrdinableOrOtherUnitsAffected = !!response.wrapper.unit.isordinable || response.unitUids.length > 1;
            await this._performDocChangeMemoryUpdate(
              {
                newUnitWrapper: response.wrapper,
                parts: response.parts,
                unitUids: response.unitUids,
                refreshView: response.refreshView || !!options.forceRefreshView,
                previousUnits: [response.wrapper.unit],
                renderBehaviour: {
                  isUpdate: !options.alreadyUpdated,
                  openEditPaneOnAffectedUnit: !!options.openEditPaneOnAffectedUnit,
                  takeUnitHtmlFromServer: !!options.replaceHtml
                }
              },
              'updateUnit',
              hasAutoTagTasks,
              isOrdinableOrOtherUnitsAffected
            );

            if (completeCallback) {
              completeCallback({
                unit: response.wrapper.unit,
                unitUid: editorUpdate.uid!,
                wasDirty: editorUpdate.isDirty
              });
            }
          } catch (err) {
            const axiosErr = err as AxiosError;

            if (axiosErr.response && axiosErr.response.status === 400) {
              const errCode: number = axiosErr.response.data.errors?.[0] ? axiosErr.response.data.errors[0].code : null;

              // if a unit specific error (in this case no change tags), ensure unit DOM reverts back to before edited
              if ([400108, 40015].indexOf(errCode) !== -1) {
                this.getEditor().resetSelectedUnitDOMContents();
              }
            }

            this.triggerShowEditorError({
              unit: docUnit.unit,
              editorUpdate: editorUpdate,
              axiosErr: axiosErr,
              completeCallback: completeCallback
            });

            this.setBusy(false);
          }
        }
      } else {
        if (completeCallback) {
          completeCallback({ unitUid: editorUpdate.uid!, wasDirty: editorUpdate.isDirty });
        }

        console.log('EditorStore: onUpdateUnit: do not perform save - no change detected');
      }
    } else {
      console.log('EditorStore: onUpdateUnit: ignoring update request, as store is busy');
    }
  }

  async overrideUnitOrdinal(
    unitUids: string[],
    strategy: string,
    ordinalOverride: string,
    renderLevel: number,
    level: number,
    regulationOverride: string
  ) {
    if (!this.isBusy()) {
      if (unitUids.length === 1) {
        const docUnit = this.getDocUnitModel(unitUids[0])!;

        if (docUnit.unit.isordinable) {
          this.setBusy(true, 'Updating unit numbering...');
          docUnit.setOrdinalSettings({
            strategy: strategy,
            override: ordinalOverride,
            renderLevel: renderLevel,
            level: level,
            regulationOverride: regulationOverride
          });

          this.setBusy(false);

          this.updateUnit({
            isDirty: true,
            type: docUnit.unit.definitionId as UnitTypes,
            uid: docUnit.unit.uid,
            html: docUnit.unit.html
          });
        }
      } else {
        this.setBusy(true, 'Updating unit numbering...');

        const units = unitUids.map((unitUid) => {
          const docUnit = this.getDocUnitModel(unitUid)!;
          docUnit.setOrdinalSettings({
            strategy: strategy,
            override: ordinalOverride,
            renderLevel: renderLevel,
            level: level,
            regulationOverride: regulationOverride
          });

          return docUnit.toJSON();
        });

        if (units.length > 0) {
          const params = this.getDocParams();

          try {
            const response = await unitsClient.convertBatch(params.projectUid!, params.indexUid!, units);

            if (response.units && response.units.length > 0) {
              await this._checkUnitTagMapUpdate(this._updateUnitsInMemory(response.units.map((u) => u.unit)));

              this.setBusy(false);
              this._triggerChangedEvent({
                type: 'replaceUnit',
                data: {
                  affectedUnits: response.unitUids,
                  affectedParts: response.parts,
                  refreshView: response.refreshView,
                  renderBehaviour: {
                    isUpdate: true
                  }
                }
              });
            }
          } catch (err) {
            this._handleError(err as AxiosError, [400, 403, 412]);
          }
        }
      }
    }
  }

  async updateRepeaterHeader() {
    const selectedUnit = this.getSelectedUnit()!;
    const docUnit = this.getDocUnitModel(selectedUnit.uid)!;

    if (docUnit && docUnit.unit.isRepeaterHeading) {
      await this.updateUnit({
        isDirty: true,
        type: docUnit.unit.definitionId as UnitTypes,
        uid: docUnit.unit.uid,
        html: docUnit.unit.html
      });
    }
  }

  async updatePrintOutput(settings: IPrintOutputSettingsData & IPageBreakSettingsData, targetElement?: HTMLElement | null) {
    const selectedUnits = this.getSelectedUnits();

    if (selectedUnits.length > 1) {
      selectedUnits.forEach((unit) => {
        const docUnit = this.getDocUnitModel(unit.uid)!;
        docUnit.setPrintOutputSettings(settings);
        docUnit.setPageBreakSettings(settings);
        unit.html = docUnit.unit.html;
      });

      const params = this.getDocParams();
      try {
        const response = await unitsClient.convertBatch(params.projectUid!, params.indexUid!, selectedUnits);

        if (response.units && response.units.length > 0) {
          await this._checkUnitTagMapUpdate(this._updateUnitsInMemory(response.units.map((u) => u.unit)));
          this.setBusy(false);

          this._triggerChangedEvent({
            type: 'replaceUnit',
            data: {
              affectedUnits: response.unitUids,
              affectedParts: response.parts,
              refreshView: response.refreshView,
              renderBehaviour: {
                isUpdate: true
              }
            }
          });
        }
      } catch (err) {
        this._handleError(err as AxiosError, [400, 403, 412]);
      }
    } else {
      const docUnit = this.getDocUnitModel(selectedUnits[0].uid);

      if (docUnit && docUnit.unit.canHavePrintOutput) {
        docUnit.setPageBreakSettings(settings, targetElement);

        if (!targetElement) {
          docUnit.setPrintOutputSettings(settings);
          this.updateUnit({
            isDirty: true,
            type: docUnit.unit.definitionId as UnitTypes,
            uid: docUnit.unit.uid,
            html: docUnit.unit.html
          });
        }
      }
    }
  }

  updateUnitSubtypeFlags(unitUid: string, value: string) {
    const docUnit = this.getDocUnitModel(unitUid)!;
    docUnit.setUnitSubTypeFlags(value);

    this.updateUnit({
      isDirty: true,
      type: docUnit.unit.definitionId as UnitTypes,
      uid: docUnit.unit.uid,
      html: docUnit.unit.html
    });
  }

  // note: currently for focused unit instances (in time expand to cater for any allowed unit conversion)
  convertUnitInEditor(unit: IUnit, newType: string) {
    Log.info('Converting unit from ' + unit.type + ', to: ' + newType);

    // for now, just one convertor, but in future this should come from a factory
    const unitTemplateConvertor = (unitType: string, htmlContent: string) => {
      if (/list/.test(unitType)) {
        return htmlContent.replace(/data-subtype=".*?"(.*?)data-subtype=".*?"/, `data-subtype="${newType}"$1data-subtype="${newType}"`); // .replace( /data-subtype=".*?"/, `data-subtype="${newType}"` );
      } else {
        return htmlContent;
      }
    };

    if (this.isEditorFocused()) {
      this.getEditor().blur({ applyHTMLContentConvertor: unitTemplateConvertor }, () => {
        this.execWhenReady(() => {
          setTimeout(() => {
            this.triggerLaunchEditor({ uid: unit.uid });
          }, 100); // gives chance for editor DOM to update from memory
        });
      });
    }
  }

  async undo() {
    if (!this.doesModeAllow(EditorModes.attributes.editing)) {
      return;
    }

    const result = await this._doUserEditAction('undo');

    if (result) {
      this._handleUndoRedo(result.changedUnits, result.affectedUnits, result.affectedParts, result.refreshView);
    } else {
      const event: IEditorStoreEvent<'undoredoError'> = {
        type: 'undoredoError',
        data: 'undo'
      };

      this.trigger(event);
      console.log('EditorStore: onUndo: ignoring undo request, as nothing to undo (or cannot undo any further)');
    }
  }

  variantSelectionError(message: string, code?: number, err?: any) {
    if (code && code === 400203) {
      const event: IEditorStoreEvent<'variantTaggingError'> = {
        type: 'variantTaggingError',
        data: { linkSource: { unitUid: err.unitUid, nid: err.elementNid, tocableUnitUid: err.tocableUnitUid } as LinkSource },
        message: err.message
      };

      this.trigger(event);
    } else {
      const event: IEditorStoreEvent<'variantPreviewError'> = {
        type: 'variantPreviewError',
        data: message
      };
      this.trigger(event);
    }
  }

  async redo() {
    if (!this.doesModeAllow(EditorModes.attributes.editing)) {
      return;
    }

    const result = await this._doUserEditAction('redo');

    if (result) {
      this._handleUndoRedo(result.changedUnits, result.affectedUnits, result.affectedParts, result.refreshView);
    } else {
      const event: IEditorStoreEvent<'undoredoError'> = {
        type: 'undoredoError',
        data: 'redo'
      };

      this.trigger(event);
      console.log('EditorStore: onRedo: ignoring redo request, as nothing to undo (or cannot undo any further)');
    }
  }

  async batchUndoRedo(activityEntry: Partial<IActivity>) {
    if (!this.isBusy()) {
      // let errs = [ 400, 403, 412 ];
      this.setBusy(true, 'Updating document...');

      try {
        const response = await activityClient.saveAction(
          this._docParams!.projectUid!,
          this._docParams!.documentIndexUid!,
          ActiveUserStore.getUser()!.uid,
          activityEntry.uid!
        );

        this.setBusy(false);
        this._handleUndoRedo(response.units, response.unitUids, response.parts, response.refreshView);
      } catch (err) {
        this._handleError(err as AxiosError);
      }
    }
  }

  isDocUnitFullyLoaded(docUnitUid: string) {
    return this._docUnitCollection.findIndex((u) => u.unit.uid === docUnitUid && !!u.unit.html) > -1;
  }

  /** *********
   * The following action handlers open a document from a tocableUnitUid, and scroll to a specific targetUnitUid
   * All methods trigger openDocumentPage
   */

  // this methods same as once below, but it won't always have a tocableuid, so it looks it up first
  async openDocumentLink(targetUnitUid: string, targetElementNid?: string, searchPastRevisionsIfNotFound = true) {
    const docUnit = this.getDocUnitModel(targetUnitUid);

    if (docUnit) {
      this.scrollToUnit(targetUnitUid, targetElementNid);
    } else {
      const response = await unitsClient.getDocUnit(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, targetUnitUid, {
        searchPastRevisionsIfNotFound: searchPastRevisionsIfNotFound
      });
      this.openDocumentWithUnit(response.unit, targetElementNid);
    }
  }

  openDocument(unit: Partial<IUnit>, projectUid?: string, indexUid?: string, tocableUnitUid?: string) {
    // TODO: gg AER-8047 - remove below once all tests pass (I'm not sure why we were stripping uid from the unit):
    // const { uid, ...withoutUid } = unit;
    this.openDocumentWithUnit(unit, null, projectUid, indexUid, tocableUnitUid);
  }

  openDocumentWithUnit(
    unit: Partial<IUnit>,
    elementNid?: string | null,
    projectUid?: string,
    indexUid?: string,
    tocableUnitUid?: string,
    allowGhostScroll?: boolean
  ) {
    this.getEditor().blur();

    const docUnit = this.getDocUnitModel(unit.uid!);

    if (docUnit && (docUnit.unit.type !== 'ghost' || allowGhostScroll)) {
      this.scrollToUnit(unit.uid!, elementNid);
    } else {
      const e: DocumentTargets = {
        targetUnitUid: unit.uid ?? '',
        targetElementNid: elementNid
      };

      if (indexUid) {
        e.indexUid = indexUid;
      }

      if (projectUid) {
        e.projectUid = projectUid;
      }

      const id = unit.definitionId || (unit.definition ? unit.definition.id : unit.type || '');

      // determine the correct owning parent
      if (id === 'volume') {
        e.targetVolumeUid = unit.uid;
      } else if (id === 'chapter' || id === 'appendix-chapter') {
        e.targetChapterUid = unit.uid;
      } else if (id === 'section') {
        e.targetSectionUid = unit.uid;
      } else {
        if ((unit as IUnit).sectionUid) {
          e.targetSectionUid = (unit as IUnit).sectionUid;
        } else if ((unit as IUnit).chapterUid) {
          e.targetChapterUid = (unit as IUnit).chapterUid;
        } else if ((unit as IUnit).volumeUid) {
          e.targetVolumeUid = (unit as IUnit).volumeUid;
        }
        if (tocableUnitUid) {
          e.tocableUnitUid = tocableUnitUid;
        }
      }

      this.openDocumentWithTargets(e);
    }
  }

  // opens a document passing the new backend linkmodel
  openDocumentWithLink(link: ILink) {
    this.getEditor().blur();

    const docUnit = this.getDocUnitModel(link.unitUid);

    if (!docUnit && ProjectStore.getSelectedVariantUid() && TocStore.getSelectedTocPath().indexOf(link.tocableUnitUid) !== -1) {
      // TODO better handle non ajax related snackbar
      this.triggerShowEditorError({
        xhr: {
          status: 418,
          responseJSON: {
            errors: [
              {
                code: 41800
              }
            ]
          }
        } as any
      });
    } else if (docUnit) {
      this.scrollToUnit(link.unitUid);
    } else {
      const isTocableUnitOfTypeVolume = link.volumeUid && link.tocableUnitUid === link.volumeUid;
      const event: IEditorStoreEvent<'openDocumentPage'> = {
        type: 'openDocumentPage',
        data: {
          projectUid: this._docParams!.projectUid!,
          indexUid: this._docParams!.documentIndexUid!,
          targetUnitUid: link.unitUid,
          tocableUnitUid: isTocableUnitOfTypeVolume ? link.unitUid : link.tocableUnitUid,
          targetElementNid: null
        }
      };

      this.trigger(event);
    }
  }

  openDocumentWithTargets(targets: DocumentTargets) {
    const event: IEditorStoreEvent<'openDocumentPage'> = {
      type: 'openDocumentPage',
      data: {
        projectUid: targets.projectUid ? targets.projectUid : this._docParams!.projectUid!,
        indexUid: targets.indexUid ? targets.indexUid : this._docParams!.documentIndexUid!,
        targetUnitUid: targets.targetUnitUid ?? '',
        tocableUnitUid:
          targets.tocableUnitUid ??
          targets.targetVolumeUid ??
          targets.targetChapterUid ??
          targets.targetSectionUid ??
          targets.targetUnitUid ??
          '',
        targetElementNid: targets.targetElementNid
      }
    };

    this.trigger(event);
  }

  async openAnotherDocument(
    projectUid: string,
    unitUid: string | undefined,
    elementNid: string | null,
    openLastPublished = true,
    indexUid: string | null = null,
    searchPastRevisionsIfNotFound = true
  ) {
    try {
      if (unitUid) {
        if (indexUid) {
          const response = await unitsClient.getDocUnit(projectUid, indexUid, unitUid, {
            searchPastRevisionsIfNotFound: searchPastRevisionsIfNotFound
          });
          this.openDocumentWithUnit(response.unit, elementNid, projectUid, indexUid);
        } else if (openLastPublished) {
          const revisions = await projectClient.getRevisions(projectUid);
          const response = await unitsClient.getDocUnit(projectUid, revisions[0].indexUid, unitUid, {
            searchPastRevisionsIfNotFound: searchPastRevisionsIfNotFound
          });
          this.openDocumentWithUnit(response.unit, elementNid, projectUid, revisions[0].indexUid);
        } else {
          // get draft
          const p = await projectClient.getProject(projectUid);
          const response = await unitsClient.getDocUnit(projectUid, p.masterIndexUid, unitUid, {
            searchPastRevisionsIfNotFound: searchPastRevisionsIfNotFound
          });
          this.openDocumentWithUnit(response.unit, elementNid, projectUid, p.masterIndexUid);
        }
      }
    } catch (err) {
      this._handleError(err as AxiosError, [403], 'access_from_another_doc');
    }
  }

  /*
   * End of opendoc handlers
   *************/

  async getUnitTocableUnitUid(unitUid: string, docParams: DocParams) {
    const response = await unitsClient.getDocUnit(docParams.projectUid!, docParams.indexUid!, unitUid);

    const targets: DocumentTargets = {
      targetUnitUid: response.unit.uid
    };

    if (response.unit.definitionId === 'volume') {
      targets.targetVolumeUid = response.unit.uid;
    } else if (response.unit.definitionId === 'chapter' || response.unit.definitionId === 'appendix-chapter') {
      targets.targetChapterUid = response.unit.uid;
    } else if (response.unit.definitionId === 'section') {
      targets.targetSectionUid = response.unit.uid;
    } else {
      if (response.unit.sectionUid) {
        targets.targetSectionUid = response.unit.sectionUid;
      } else if (response.unit.chapterUid) {
        targets.targetChapterUid = response.unit.chapterUid;
      } else if (response.unit.volumeUid) {
        targets.targetVolumeUid = response.unit.volumeUid;
      }
    }

    const targetVolumeUid = targets.targetVolumeUid
      ? targets.targetVolumeUid
      : targets.targetChapterUid
      ? targets.targetChapterUid
      : targets.targetSectionUid
      ? targets.targetSectionUid
      : targets.targetUnitUid;

    return {
      targetVolumeUid: targetVolumeUid ?? '',
      unit: response.unit
    };
  }

  editFocus(unit: IUnit, isEditorAlreadyFocused: boolean) {
    if (unit.definitionId === 'table' && !isEditorAlreadyFocused) {
      this.getEditor().getActiveEditorFacade()!.clearSelection();
    }

    if (!isEditorAlreadyFocused) {
      // only trigger if new focus, not re-focus
      this.broadcast('unitEditBegin', { unitUid: unit.uid });

      const event: IEditorStoreEvent<'editFocus'> = {
        type: 'editFocus',
        unit: unit
      };

      this.trigger(event);
    }
  }

  editBlur(editDetails: EditBlurData, blurCompleteCallback?: (unit: { wasDirty: boolean; unitUid: string }) => void) {
    this._lastFocusedInnerUnit = null;

    this.updateUnit(editDetails, (args) => {
      if (blurCompleteCallback) {
        blurCompleteCallback(args);
      }

      const event: IEditorStoreEvent<'editBlur'> = {
        type: 'editBlur',
        editDetails: editDetails
      };

      this.broadcast('unitEditEnd', { unitUid: editDetails.uid });
      this.trigger(event);
    });
  }

  /*
   * Selection of unit performed internally in Document component.
   * Reason for 2 steps (select and onselected): Document component may reject selection, and unitSelected will not be triggered
   */

  selectUnit(unit: IUnit, callback: () => void, options: { force: boolean } = { force: false }) {
    const changeEvent: IEditorStoreEvent<'selectUnit'> = {
      type: 'selectUnit',
      data: {
        unit: unit,
        callback: callback,
        options: options
      }
    };

    this.trigger(changeEvent);
  }

  reorderSelectedByIndex() {
    // note: in memory only: won't be reflected in document until subsequent document re-render
    // ensures primary selected item (this.getSelectedUnit()) is always the *first* unit on the page
    this.state.selectedUnits = _.orderBy(this.state.selectedUnits, ['index'], ['desc']);
  }

  nestedUnitFocusChange(event: INestedUnitFocusChangeEvent) {
    this._lastFocusedInnerUnit = event;

    // Make sure to set arconicsUnitType on the editor to reflect currently nested element
    // This will activate appropriate key listener (e.g. handle keys for lists nested in non-list units)
    // We use unitElement of the focused element cause we're interested in the main element e.g. a list for focused li
    if (event.focused && event.focused.unitElement) {
      try {
        const unitType = UnitIdentifier.getType(event.focused.unitElement);
        this.getEditor()!.getActiveEditorInstance()!.arconicsUnitType = unitType;
        Log.debug('Editor Store - Nested element key handler: ' + unitType);
      } catch (e) {
        Log.error(`Editor Store - ${e}`);
      }
    }

    const changeEvent: IEditorStoreEvent<'nestedUnitFocusChange'> = {
      type: 'nestedUnitFocusChange',
      data: event
    };

    this.trigger(changeEvent);
  }

  scrollToUnit(unitUid: string, elementNid?: string | null) {
    console.info(`Editor Store - trigger scroll to unit uid: ${unitUid}${elementNid ? ', element nid: ' + elementNid : ''}`);
    const changeEvent: IEditorStoreEvent<'scrollToUnit'> = {
      type: 'scrollToUnit',
      data: { unitUid, elementNid }
    };

    this.trigger(changeEvent);
  }

  editTextSelected(e: any) {
    const changeEvent: IEditorStoreEvent<'editTextSelected'> = {
      type: 'editTextSelected',
      data: e
    };

    this.trigger(changeEvent);
  }

  editTextSelectedEnd(e: any) {
    const changeEvent: IEditorStoreEvent<'editTextSelectedEnd'> = {
      type: 'editTextSelectedEnd',
      data: e
    };

    this.trigger(changeEvent);
  }

  insertUnit(e: {
    inline: boolean;
    unit?: string;
    unitType: UnitTypes;
    insertPoint?: HTMLElement;
    insertPosition?: InsertAction;
    parentUnitUid?: string;
  }) {
    let inline = e.inline;
    const facade = this.getEditor().getActiveEditorFacade();

    if (!facade?.canInsertInlineInTable()) {
      inline = false; // force unit append
    }

    if (inline) {
      facade?.insertUnitInline(e);
    } else {
      // if not inserted inline, inform everyone that it was inserted!!!
      const changeEvent: IEditorStoreEvent<'insertUnit'> = {
        type: 'insertUnit',
        data: {
          unitType: e.unitType,
          parentUnitUid: e.parentUnitUid
        }
      };

      this.trigger(changeEvent);
    }
  }

  async powerPaste(content: string) {
    try {
      const units = await unitsClient.savePastedString(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, content);

      const changeEvent: IEditorStoreEvent<'pasteHtmlParsed'> = {
        type: 'pasteHtmlParsed',
        docUnits: units
      };

      this.trigger(changeEvent);
    } catch (err) {
      const changeEvent: IEditorStoreEvent<'pasteHtmlParsed'> = {
        type: 'pasteHtmlParsed',
        message: "Can't parse the html"
      };

      this.trigger(changeEvent);
    }
  }

  async moveUnit(type: 'demote' | 'promote', unit: IUnit) {
    try {
      if (type === 'demote') {
        await tocClient.demoteOrdinableUnit(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, unit.uid);
      } else {
        await tocClient.promoteOrdinableUnit(this._docParams!.projectUid!, this._docParams!.documentIndexUid!, unit.uid);
      }

      const changeEvent: IEditorStoreEvent<'unitMoved'> = {
        type: 'unitMoved',
        data: { unit: unit, renderBehaviour: { isDelete: false, multipleChanged: false } }
      };

      this.trigger(changeEvent);
    } catch (err) {
      this.triggerShowEditorError({ axiosErr: err as AxiosError, type: 'unitMoved' });
    }
  }

  // want to perform an action, but ensure the editor blurs and saves first:
  // when changing page and we are focused in editor, useful to set performBlurCleanupTimeout which gives time for page to un/remount after editor blur save
  blurEditor(callback: () => void, blurOptions = {}, performBlurCleanupTimeout = false) {
    const editorFocused = this.isEditorFocused();
    const performTimeout = editorFocused && performBlurCleanupTimeout;

    if (editorFocused) {
      this._editorInstanceManager!.blur(blurOptions, () => {
        if (callback) {
          if (performTimeout) {
            setTimeout(callback, 300);
          } else {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  docBusy() {
    const changeEvent: IEditorStoreEvent<'editorIsBusy'> = {
      type: 'editorIsBusy',
      busy: true
    };

    this.trigger(changeEvent);
  }

  stopBusy() {
    const changeEvent: IEditorStoreEvent<'editorIsBusy'> = {
      type: 'editorIsBusy',
      busy: false
    };

    this.trigger(changeEvent);
  }

  // //////////////////////////////////////////////////////////////////////////////////
  // start: shared content

  selectShareOrigin(startUnit: IUnit, position: { isUp: boolean; isStart: boolean }) {
    this.sharedContentAddon.selectShareOrigin(startUnit, position);
  }

  requestSharedContentInsert(sharedIndex: ISharedIndex) {
    this.sharedContentAddon.requestSharedContentInsert(sharedIndex);
  }

  acceptSharedContentInsert(sharedIndexUid: string, updateStrategy: string, insertType: string, ordinalLevelStrategy: string) {
    this.sharedContentAddon.acceptSharedContentInsert(sharedIndexUid, updateStrategy, insertType, ordinalLevelStrategy);
  }

  acceptSharedContentUpdate(unitUid: string, sharedIndexUid: string, acceptStrategy: string) {
    this.sharedContentAddon.acceptSharedContentUpdate(unitUid, sharedIndexUid, acceptStrategy);
  }

  requestRejectSharedContentUpdate(unitUid: string, sharedIndexUid: string) {
    this.sharedContentAddon.requestRejectSharedContentUpdate(unitUid, sharedIndexUid);
  }

  rejectSharedContentUpdate(unitUid: string, sharedIndexUid: string) {
    this.sharedContentAddon.rejectSharedContentUpdate(unitUid, sharedIndexUid);
  }

  diffSharedContentUpdate(unitUid: string, unitShareDetails: IShareDetails) {
    this.sharedContentAddon.diffSharedContentUpdate(unitUid, unitShareDetails);
  }

  publishSharedOrigin(unitShareDetails: IShareDetails) {
    this.sharedContentAddon.publishSharedOrigin(unitShareDetails);
  }

  revertSharedOrigin(unitShareDetails: IShareDetails) {
    this.sharedContentAddon.revertSharedOrigin(unitShareDetails);
  }

  // end: shared content
  // //////////////////////////////////////////////////////////////////////////////////

  // //////////////////////////////////////////////////////////////////////////////////
  // start: trigger actions

  triggerUnitStyleChange(styleType: string, styleProperties?: ScaleInfo) {
    const changeEvent: IEditorStoreEvent<'unitStyleChange'> = {
      type: 'unitStyleChange',
      data: { styleType: styleType, styleProperties: styleProperties }
    };

    this.trigger(changeEvent);
  }

  triggerLaunchEditor(e: any) {
    const changeEvent: IEditorStoreEvent<'launchEditor'> = {
      type: 'launchEditor',
      data: e
    };

    this.trigger(changeEvent);
  }

  triggerUnitsSelection(selectedUnits: IUnit[], onUnitsSelected?: () => void) {
    const lastSelectedUnit = this.getSelectedUnit();
    this.state.selectedUnits = selectedUnits;

    if (onUnitsSelected) {
      // fire after setting so data state above
      onUnitsSelected();
    }

    const changeEvent: IEditorStoreEvent<'unitsSelected'> = {
      type: 'unitsSelected',
      data: {
        initialSelectedUnit: selectedUnits.length ? selectedUnits[selectedUnits.length - 1] : null,
        selectedUnits: selectedUnits
      }
    };

    ProjectDefinitionStore.updateDefsAndIdsOnUnitSelectedIfNestedDefs(changeEvent.data?.initialSelectedUnit, lastSelectedUnit);
    this.trigger(changeEvent);
  }

  triggerUnitDomChange(data?: IEditorStoreEvent<'unitDomChange'>['data']) {
    const changeEvent: IEditorStoreEvent<'unitDomChange'> = {
      type: 'unitDomChange',
      data
    };

    this.trigger(changeEvent);
  }

  triggerInlineUnitAction(e: ActionEvent) {
    const changeEvent: IEditorStoreEvent<'inlineUnitAction'> = {
      type: 'inlineUnitAction',
      data: e
    };

    this.trigger(changeEvent);
  }

  triggerChangeInsertPosition(e: InsertAction) {
    this.trigger({
      type: 'changeInsertPosition',
      data: { position: e }
    } as IEditorStoreEvent<'changeInsertPosition'>);
  }

  triggerOpenGenericDateModal(clientId: string, modalProps: any) {
    const changeEvent: IEditorStoreEvent<'openGenericDateModal'> = {
      type: 'openGenericDateModal',
      clientId: clientId,
      modalProps: modalProps
    };

    this.trigger(changeEvent);
  }

  triggerOpenHotspotsModal() {
    const changeEvent: IEditorStoreEvent<'modalAction'> = {
      type: 'modalAction',
      modalProps: { type: 'hotspots', show: true }
    };

    this.trigger(changeEvent);
  }

  triggerCloseHotspotsModal() {
    const changeEvent: IEditorStoreEvent<'modalAction'> = {
      type: 'modalAction',
      modalProps: { type: 'hotspots', show: false }
    };

    this.trigger(changeEvent);
  }

  triggerOpenRefIntModal() {
    const changeEvent: IEditorStoreEvent<'modalAction'> = {
      type: 'modalAction',
      modalProps: { type: 'refint', show: true }
    };
    this.trigger(changeEvent);
  }

  triggerCloseRefIntModal() {
    const changeEvent: IEditorStoreEvent<'modalAction'> = {
      type: 'modalAction',
      modalProps: { type: 'refint', show: false }
    };
    this.trigger(changeEvent);
  }

  triggerOpenLinkModal(isDuRef = false, templateHtml?: HTMLElement) {
    const changeEvent: IEditorStoreEvent<'modalAction'> = {
      type: 'modalAction',
      modalProps: { type: 'link', isDuRef, show: true, templateHtml }
    };
    this.trigger(changeEvent);
  }

  triggerUnitsChanged(
    changedUnits: IUnit[],
    options: { parse: boolean } = { parse: false },
    renderBehaviour: Partial<RenderOptions> = { isDelete: false }
  ) {
    console.info('EditorStore - performing optimum page load');

    const units: IUnit[] = _.cloneDeep(changedUnits);

    if (options.parse) {
      // let docs: DocUnitWrapper[] = [];
      const docParams = this.getDocParams();
      for (const unit of units) {
        unitsClient.parse(unit, docParams.projectUid!, docParams.indexUid!);
      }
    }

    renderBehaviour.multipleChanged = changedUnits.length > 1;
    this._triggerChangedEvent({
      type: 'unitsChanged',
      data: { unit: this._extractMostSeniorUnit(units), renderBehaviour: renderBehaviour as any }
    });
  }

  triggerEditorStoresInitializing() {
    const changeEvent: IEditorStoreEvent<'editorStoresInitializing'> = {
      type: 'editorStoresInitializing'
    };

    this.trigger(changeEvent);
    this.setBusy(true, 'Initializing Editor Store...');
    this.docBusy();
  }

  // inform the world that editor has all data loaded and is ready for action
  // like a superset of "initEditor" encompassing all editor stores
  triggerEditorStoresInitialized() {
    AppStateStore.updateLastAccessProject(ProjectStore.getProject()!.uid);
    IndexEventStore.joinNewIndex(this.getDocParams().indexUid!);
    const changeEvent: IEditorStoreEvent<'editorStoresInitialized'> = {
      type: 'editorStoresInitialized'
    };

    this.trigger(changeEvent);
    this.setBusy(false, 'Initialized Editor Store...');

    this._retrieveActiveEditors().then(() => {
      const changeEvent: IEditorStoreEvent<'activeEditorChange'> = {
        type: 'activeEditorChange'
      };

      this.trigger(changeEvent);
    });
  }

  // inform world that a collection of units have been rendered
  triggerPostUnitRender(query: { [prop: string]: string | undefined }) {
    const changeEvent: IEditorStoreEvent<'postUnitRender'> = {
      type: 'postUnitRender',
      data: query
    };

    this.trigger(changeEvent);
  }

  triggerShareSelection(unit: IUnit) {
    const changeEvent: IEditorStoreEvent<'shareSelection'> = {
      type: 'shareSelection',
      unit: unit
    };

    this.trigger(changeEvent);
  }

  triggerShowEditorError(data: Partial<DataError>) {
    this.setBusy(false);

    const changeEvent: IEditorStoreEvent<'editorError'> = {
      type: 'editorError',
      data: data
    };

    this.trigger(changeEvent);
  }

  // end: trigger actions
  // //////////////////////////////////////////////////////////////////////////////////

  _triggerChangedEvent(e?: IEditorStoreEvent<EventStoreEventType>) {
    const changeEvent: IEditorStoreEvent<'documentChanged'> = {
      type: 'documentChanged'
    };

    this.trigger(changeEvent); // some UX will be interested that the doc has changed, e.g. activity logs

    if (e) {
      this.trigger(e);
    } // the actual event
  }

  async _doUserEditAction(action: 'undo' | 'redo') {
    if (!this.isBusy()) {
      this.setBusy(true, action === 'undo' ? 'Undo...' : 'Redo...');

      try {
        const response = await activityClient.getUserEditActions(
          this._docParams!.projectUid!,
          this._docParams!.documentIndexUid!,
          ActiveUserStore.getUser()!.uid,
          action
        );
        this.setBusy(false);
        return {
          changedUnits: response.units,
          affectedUnits: response.unitUids,
          affectedParts: response.parts,
          refreshView: response.refreshView
        };
      } catch (err) {
        if (!this._handleError(err as AxiosError, [412, 403])) {
          return null;
        }
      }
    } else {
      console.log('EditorStore: _doUserEditAction: ignoring action request, as store is busy');
    }

    return null;
  }

  async _handleUndoRedo(changedUnits: IUnit[], affectedUnits: string[], affectedParts: string[], refreshView: boolean) {
    const changedUnitsParsed: IUnit[] = [];

    changedUnits.forEach((changedUnit) => {
      // transform previous type to something more meaningful
      if (changedUnit.previousType && changedUnit.previousType === 'tocable') {
        const tocItem = TocStore.getTocItem(changedUnit.uid);
        // level1 just a marked for hdr for now so gets correct props on model parse
        changedUnit.previousType = tocItem ? (tocItem.type.toLowerCase() as UnitTypes) : 'level1';
      }

      changedUnitsParsed.push(unitsClient.parse(changedUnit, this._docParams!.projectUid!, this._docParams!.documentIndexUid!));
    });

    const actionUnit = this._extractMostSeniorUnit(changedUnitsParsed);
    UnitHighlightStore.retrieveTocHighlight();
    if (actionUnit) {
      const isTocable = actionUnit.istocable ? actionUnit.istocable : null;
      const doesCauseRemove = !actionUnit.isVisibleOnEdit;
      const actionUnitOnPage = this.getDocUnitModel(actionUnit.uid);

      // a unit on the current page
      if (actionUnitOnPage) {
        const actionUnitOnPageJSON = actionUnitOnPage.toJSON();

        // replace changed in memory (may overlap with _updateMemoryWithAffectedUnits but thats ok)
        const newWrappers = changedUnitsParsed.map((u) => new DocUnitWrapper(u));
        this._docUnitCollection.concat(newWrappers);
        this._reIndexUnits();

        const unitJSON = doesCauseRemove
          ? actionUnitOnPageJSON
          : this._docUnitCollection.find((u) => u.unit.uid === actionUnit.uid)!.toJSON();

        if (isTocable) {
          unitJSON.istocable = isTocable;
        }

        const affected = await this._updateMemoryWithAffectedUnits(
          actionUnitOnPage,
          {
            updateTriggeringModel: true,
            affectedParts,
            affectedUnits,
            refreshView
          },
          !!changedUnits
        );

        this._triggerChangedEvent({
          type: 'undoredo',
          data: {
            unit: unitJSON,
            affectedUnits: affected.unitUids,
            affectedParts: affected.parts,
            refreshView: affected.refreshView,
            renderBehaviour: {
              isDelete: doesCauseRemove,
              isCreate: !doesCauseRemove,
              scrollToAffectedUnit: true,
              multipleChanged: changedUnitsParsed.length > 1
            }
          }
        });

        this.setBusy(false);

        if (this.isShareEditMode()) {
          this.sharedContentAddon.recalculateSharedContentSelectionIfApplicable({
            eventType: 'undoredo',
            units: changedUnits.map((u) => new DocUnitWrapper(u))
          });
        }
      }
      // a structural unit, not on this page
      else if (actionUnit.isstructural) {
        this._triggerChangedEvent({
          type: 'undoredo',
          data: {
            unit: actionUnit,
            affectedUnits: [],
            affectedParts: [],
            renderBehaviour: {
              isDelete: doesCauseRemove,
              isCreate: !doesCauseRemove,
              scrollToAffectedUnit: true,
              multipleChanged: changedUnitsParsed.length > 1
            }
          }
        });

        this.setBusy(false);
        if (this.isShareEditMode()) {
          this.sharedContentAddon.recalculateSharedContentSelectionIfApplicable({
            eventType: 'undoredo',
            units: changedUnits.map((u) => new DocUnitWrapper(u))
          });
        }
      }

      // a non-structural unit not on this page: open page and scroll to it (no need to trigger: 'undoredo' as this will load whole page)
      else {
        this._triggerChangedEvent();
        this.openDocumentWithUnit(actionUnit);
      }
    }
  }

  async _mergeUnits(units: { uid: string }[]) {
    if (!this.isBusy()) {
      // prevent breaking apart a share usage/origin from a merge (allow internally in share no probs)
      // bit simplistic and limiting but will cover us for now
      if (
        this.getSelectedUnits().filter((u) => u.shareDetails && (u.shareDetails.isShareStartUnit || u.shareDetails.isShareEndUnit)).length >
        0
      ) {
        return;
      }

      this.setBusy(true, 'Merging elements...');

      const docParams = this.getDocParams();

      try {
        const response = await unitsClient.mergeUnits(docParams.projectUid!, docParams.indexUid!, units);
        UnitHighlightStore.retrieveTocHighlight();
        this.triggerUnitsChanged(this._updateUnitsInMemory(response.units));
        this.setBusy(false);
      } catch (err) {
        this._handleError(err as AxiosError, [400, 403]);
      }
    }
  }

  async _splitUnit(unit: IUnit, html: string) {
    if (!this.isBusy()) {
      this.setBusy(true, 'Splitting element...');
      const docParams = this.getDocParams();

      try {
        const response = await unitsClient.splitUnit(docParams.projectUid!, docParams.indexUid!, unit.uid, unit.versionUid!, html);
        UnitHighlightStore.retrieveTocHighlight();
        this.setBusy(false);

        return new Promise<void>((resolve) => {
          this.getEditor().blur({ ignoreChanges: true }, () => {
            // insert second part of split into correct index location
            const affectedUnitModel = new DocUnitWrapper(unit);

            const splitUnitJSON = response.units[1];

            this._insertUnitsInMemory([splitUnitJSON], affectedUnitModel.unit.index + 1);

            this._performDocChangeMemoryUpdate(
              {
                newUnitWrapper: affectedUnitModel,
                parts: response.parts,
                unitUids: response.unitUids,
                refreshView: response.refreshView,
                previousUnits: [splitUnitJSON],
                renderBehaviour: {
                  isUpdate: true
                }
              },
              'splitSelectedUnits'
            );

            resolve();
          });
        });
      } catch (err) {
        this._handleError(err as AxiosError, [403, 400]);
      }
    }
  }

  async _performDocChangeMemoryUpdate(
    options: UnitChangedOptions,
    eventType: EventStoreEventType,
    checkUnitTagMap = true,
    fetchBatch = true
  ) {
    if (checkUnitTagMap) {
      this._checkUnitTagMapUpdate(options.previousUnits);
    }

    const affected = await this._updateMemoryWithAffectedUnits(
      options.newUnitWrapper,
      {
        updateTriggeringModel: true,
        affectedUnits: options.unitUids,
        refreshView: options.refreshView,
        affectedParts: options.parts
      },
      fetchBatch
    );

    if (eventType === 'deleteUnitConfirm' && SmartContentStore.getUnitComplianceTags(options.newUnitWrapper.unit.uid).length > 0) {
      affected.refreshView = true;
    }

    this._triggerChangedEvent({
      type: eventType,
      data: {
        unit: options.previousUnits[0],
        affectedUnits: affected.unitUids,
        affectedParts: affected.parts,
        refreshView: affected.refreshView,
        renderBehaviour: options.renderBehaviour
      }
    });

    this.setBusy(false);

    if (this.isShareEditMode()) {
      this.sharedContentAddon.recalculateSharedContentSelectionIfApplicable({
        eventType,
        units: options.newUnitWrapper
      });
    }
  }

  async _performBatchDocChangeMemoryUpdate(
    affectedUnits: string[],
    parts: string[],
    isRefresh: boolean,
    updatedUnits: IUnit[] | null,
    eventType: EventStoreEventType,
    renderBehaviour: Partial<RenderOptions>,
    fetchBatchOrPayload: boolean | IUnit[] = true
  ) {
    const unit = updatedUnits ? this._extractMostSeniorUnit(updatedUnits) : this.getMostSeniorSelectedUnit();
    const affectedUnitModel = new DocUnitWrapper(unit);

    const originalUnitJSON = affectedUnitModel.toJSON();

    const completeUpdate = async () => {
      const response = await this._updateMemoryWithAffectedUnits(
        affectedUnitModel,
        {
          updateTriggeringModel: true,
          affectedParts: parts,
          affectedUnits: affectedUnits,
          refreshView: isRefresh
        },
        fetchBatchOrPayload
      );

      this._triggerChangedEvent({
        type: eventType,
        data: {
          unit: originalUnitJSON,
          affectedUnits: response.unitUids,
          affectedParts: response.parts,
          refreshView: response.refreshView,
          renderBehaviour
        }
      });

      this.setBusy(false);

      if (this.isShareEditMode()) {
        this.sharedContentAddon.recalculateSharedContentSelectionIfApplicable({
          eventType,
          units: affectedUnitModel
        });
      }
    };

    if (updatedUnits) {
      await this._checkUnitTagMapUpdate(updatedUnits).then(async () => {
        await completeUpdate();
      });
    } else {
      await completeUpdate();
    }
  }

  _handleError(r: Error | AxiosError, handleErrorStatuses?, additional: string | null = null, editorUpdate: IUnit | null = null) {
    const axiosErr = r as AxiosError;

    if (axiosErr.response && handleErrorStatuses && handleErrorStatuses.indexOf(axiosErr.response.status) !== -1) {
      this.triggerShowEditorError({
        axiosErr: axiosErr,
        additional: additional,
        editorUpdate: editorUpdate
      });
      return true;
    } else {
      this.setBusy(false);
      return false;
    }
  }

  async _updateMemoryWithAffectedUnits(
    changeTriggeringUnitModel: DocUnitWrapper,
    options: { affectedUnits: string[]; affectedParts: string[]; refreshView: boolean; updateTriggeringModel: boolean },
    fetchBatchOrPayload: boolean | IUnit[] = true
  ) {
    const affectedUnits = options.affectedUnits;
    const affectedParts = options.affectedParts;
    const refreshView = options.refreshView;
    const affectedUnitsOnPage: string[] = [];

    affectedUnits.forEach((unitUid) => {
      if (this._docUnitCollection.find((u) => u.unit.uid === unitUid) && changeTriggeringUnitModel.unit.uid !== unitUid) {
        affectedUnitsOnPage.push(unitUid);
      }
    });

    if (options.updateTriggeringModel) {
      affectedUnitsOnPage.push(changeTriggeringUnitModel.unit.uid);
    }

    // if this is a share origin, add other units in the share to affectedUnitsOnPage
    const changedUnitShare = changeTriggeringUnitModel.unit.shareDetails;
    if (changedUnitShare?.origin) {
      this._docUnitCollection.forEach((wrapper, index) => {
        const pageUnitShare = wrapper.unit.shareDetails;
        if (pageUnitShare && pageUnitShare.origin && pageUnitShare.sharedIndexUid === changedUnitShare.sharedIndexUid) {
          affectedUnitsOnPage.push(wrapper.unit.uid);
        }
      });
    }
    if (fetchBatchOrPayload === true || changedUnitShare?.origin) {
      await this._updateMemoryWithLatestUnits(affectedUnitsOnPage, affectedParts);
    } else {
      this.applyChangesForAffectedUnits(Array.isArray(fetchBatchOrPayload) ? fetchBatchOrPayload : [changeTriggeringUnitModel.unit]);
    }
    return { unitUids: affectedUnitsOnPage, parts: affectedParts, refreshView: refreshView };
  }

  _updateModelIndex(model: IUnit, index: number, visibleUnitIndex: number | null) {
    const type = model.definitionId as UnitTypes;

    model.index = index;
    model.visibleUnitIndex = visibleUnitIndex;

    if (type) {
      // we may not be starting on a volume, therefore we hardcode to start off, we know these to be always valid
      if (type === 'chapter') {
        this._lastLevel = 'volume';
      } else if (type === 'section') {
        this._lastLevel = 'chapter';
      }
      const isTocableType = ProjectDefinitionStore.isTocableType(type, model.type);

      if (isTocableType) {
        this._lastLevel = type;
      }

      model.tocLevel = this._lastLevel!;
      model.istocable = isTocableType;
      model.isstructural = ProjectDefinitionStore.isStructuralType(type);
    }

    // each share must have a reference to its shareStartUnitUid for share usage uniqueness
    const shareDetails: IShareDetails | undefined = model.shareDetails;
    if (shareDetails) {
      if (shareDetails.isShareStartUnit) {
        shareDetails.shareStartUnitUid = model.uid;
        this._lastShareDetailsParsed = shareDetails;
      } else {
        if (this._lastShareDetailsParsed) {
          shareDetails.shareStartUnitUid = this._lastShareDetailsParsed.shareStartUnitUid;
        }
      }
    }

    model.uid = model.uid ? model.uid : 'generated_' + index; // some "virtual readonly units" will have no uid, so create one
  }

  _reIndexUnits() {
    let visibleUnitIndex: number | null = null;
    this._lastLevel = null;

    this._docUnitCollection.forEach((wrapper, index) => {
      const isVisibleOnEdit = wrapper.unit.isVisibleOnEdit!;
      visibleUnitIndex = visibleUnitIndex === null ? 0 : visibleUnitIndex;

      this._updateModelIndex(wrapper.unit, index, isVisibleOnEdit ? visibleUnitIndex : null);

      if (isVisibleOnEdit) {
        visibleUnitIndex++;
      }
    });
  }

  async _updateMemoryWithLatestUnits(unitUids: string[], parts?: string[]) {
    const docParams = this.getDocParams();
    let headerUnitUid: string | undefined = undefined;
    if (parts && parts.indexOf('header') !== -1) {
      headerUnitUid = TocStore.getSelectedItem()!.uid!;
    }

    const response = await unitsClient.fetchBatch(docParams.projectUid!, docParams.indexUid!, unitUids, headerUnitUid);

    const affectedUnit = response.header;

    if (affectedUnit) {
      affectedUnit.uid = 'generated_0';

      const wrapper = new DocUnitWrapper(affectedUnit);
      unitsClient.parse(wrapper.unit, docParams.projectUid!, docParams.indexUid!);

      const index = this._docUnitCollection.findIndex((u) => u.unit.uid === wrapper.unit.uid);
      if (index !== -1) {
        this._docUnitCollection.splice(index, 1, wrapper);
      } else {
        this._docUnitCollection.push(wrapper);
      }

      this._reIndexUnits();
    }

    // merge changed units into memory
    this.applyChangesForAffectedUnits(response.units);
  }

  private applyChangesForAffectedUnits(units: IUnit[]) {
    units.forEach((affectedUnit) => {
      const wrapper = new DocUnitWrapper(affectedUnit);

      if (!this.isEditorFocused(wrapper.unit.uid)) {
        // leave focused units untouched (version error on save: good!)

        const index = this._docUnitCollection.findIndex((u) => u.unit.uid === wrapper.unit.uid);
        if (index !== -1) {
          this._docUnitCollection.splice(index, 1, wrapper);
        } else {
          this._docUnitCollection.push(wrapper);
        }

        this._reIndexUnits();

        // update any changed selected in memory
        const selectedIndex = this.state.selectedUnits.findIndex((s) => s.uid === wrapper.unit.uid);
        if (selectedIndex !== -1) {
          this.state.selectedUnits[selectedIndex] = this.getDocUnitModel(wrapper.unit.uid)!.toJSON();
        }
      }
    });
  }

  async _checkUnitTagMapUpdate(units: IUnit[]) {
    const BreakException = {};
    const autoTagTasks = AutoTagStore.getTasksUids();
    let triggerUnitMapReload = false;

    if (autoTagTasks.length) {
      try {
        units.forEach((unit, uInx) => {
          const unitCurrentTasks = UnitTaskStore.getUnitTasks(units[uInx].uid);
          const assignTasks = _.difference(autoTagTasks, unitCurrentTasks);
          if (assignTasks.length > 0) {
            triggerUnitMapReload = true;
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) {
          throw e;
        }
      }

      if (triggerUnitMapReload) {
        await UnitTaskStore.init(this.getDocParams());
        IndexEventStore.broadcastToIndex({
          userUid: ActiveUserStore.getUser()!.uid,
          activity: 'unitTaskChanged',
          data: {
            indexUid: this.getDocParams().indexUid!
          }
        });
      }
    }
  }

  _insertUnitsInMemory(units: IUnit[], atIndex: number) {
    const docParams = this.getDocParams();
    const parsedUnits: DocUnitWrapper[] = [];

    units.forEach((unit) => {
      const wrapper = new DocUnitWrapper(unit);
      unitsClient.parse(unit, docParams.projectUid!, docParams.indexUid!);
      parsedUnits.push(wrapper);
    });

    this._docUnitCollection.splice(atIndex, 0, ...parsedUnits);
    this._reIndexUnits();
    return units;
  }

  _updateUnitsInMemory(units: IUnit[]) {
    const docParams = this.getDocParams();

    units.forEach((unit) => {
      const wrapper = new DocUnitWrapper(unit);
      unitsClient.parse(unit, docParams.projectUid!, docParams.indexUid!);
      const index = this._docUnitCollection.findIndex((du) => du.unit.uid === unit.uid);
      this._docUnitCollection.splice(index, 1, wrapper);
    });

    this._reIndexUnits();
    return units;
  }

  onModalsClosed() {
    const changeEvent: IEditorStoreEvent<'editor-modals-closed'> = {
      type: 'editor-modals-closed'
    };
    this.trigger(changeEvent);
  }

  // possible misnamed: perhaps topmostunit?!
  _extractMostSeniorUnit(units: IUnit[]) {
    // ordering of units is relevant here, ensure to order as to your requirements before calling _extractMostSeniorUnit
    // i.e. if 2 sections first one found will be considered more senior (earlier on in the chapter)

    let topLevelUnit = units.filter((u) => u.isstructural)[0];

    if (!topLevelUnit) {
      topLevelUnit = units.filter((u) => u.istocable)[0];
    }

    if (!topLevelUnit) {
      topLevelUnit = units.filter((u) => u.hasOrdinal || u.canHaveOrdinal)[0];
    }

    // still no top level unit: just use first one which is just an ordinary unit
    topLevelUnit = topLevelUnit ? topLevelUnit : units[0];

    return topLevelUnit;
  }

  async _retrieveActiveEditors() {
    const docParams = this.getDocParams();
    const result = await projectClient.getActiveEditors(docParams.projectUid!, docParams.indexUid!);
    this.state.activeEditors = {
      list: result.activeEditors,
      authorCount: result.editorUserCount
    };
  }

  broadcast(broadcastMessageType, data) {
    const user = ActiveUserStore.getUser()!;
    const messageData = _.extend(
      {
        user: _.pick(user, ['uid', 'displayName', 'avatarUrl'])
      },
      data
    );

    IndexEventStore.broadcastToIndex({ userUid: user.uid, activity: broadcastMessageType, data: messageData });
  }

  areDataAttributesValidationErrors(): boolean {
    return Object.keys(this.state.dataAttributesValidationErrors).length > 0;
  }

  getDataAttributesValidationErrors(): DataAttributeValidationErrors {
    return this.state.dataAttributesValidationErrors;
  }

  setDataAttributesValidationErrors(errors: DataAttributeValidationErrors) {
    this.state.dataAttributesValidationErrors = errors;
  }

  isShareDiffActive() {
    return this.state.isShareDiffActive;
  }

  toggleShareDiff(isShareDiffActive = !this.state.isShareDiffActive) {
    this.state.isShareDiffActive = isShareDiffActive;
  }

  closeDiffMode() {
    this.sharedContentAddon.closeDiffMode();
  }

  userUnitChanged() {
    this._userUnitChange = true;
    setTimeout(() => {
      this._userUnitChange = false;
    }, 500);
  }

  getUserUnitChanged() {
    return this._userUnitChange;
  }

  updateTableCellsDimensions(event: IEditorStoreEvent<'unitDomChange'>, callback?: () => void) {
    if (event.data && !this.getUserUnitChanged()) {
      const { mutations, node } = event.data;

      if (node && mutations && ['TD', 'TABLE'].indexOf(node.nodeName) > -1) {
        const oldStyle = ConversionUtil.styleStrToObject(mutations?.oldValue ?? '');
        const newStyle = ConversionUtil.styleStrToObject($(mutations?.target ?? node).attr('style') || '');

        const changed: Partial<CSSStyleDeclaration>[] = _.reduce(
          newStyle,
          function (result: Partial<CSSStyleDeclaration>[], value, key) {
            return propsAreEqual(value, oldStyle[key]) ? result : result.concat(key);
          },
          []
        );

        if (changed.indexOf('width') !== -1) {
          const { lengthNumber: oldLength, lengthUnit: oldUnit } = ConversionUtil.parseLengthStyle(oldStyle['width']);
          const { lengthNumber: newLength, lengthUnit: newUnit } = ConversionUtil.parseLengthStyle(newStyle['width']);

          if ((!oldLength && newLength && newUnit === 'px') || (oldLength && oldUnit === '%' && newLength && newUnit === 'px')) {
            const conv = ConversionUtil.changeUnitTo('%', newLength, node.nodeName === 'TD', node);
            const $target = $(mutations?.target ?? node);
            $target.css('width', conv + '%');
            const newStyle = $target.attr('style')!;
            $target.attr('data-mce-style', newStyle);

            if (callback) {
              callback();
            }
          }
        }
      }
    }
  }
}

const singleton = Reflux.initStore<EditorStore>(EditorStore);
export default singleton;
