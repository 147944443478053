import { Cancelled, mm } from './base-clients';
import axios, { CancelTokenSource } from 'axios';
import { IInvalidUnit } from 'mm-types';

let getAllSource: CancelTokenSource | null = null;

export async function getAll(projectUid: string, indexUid: string): Promise<IInvalidUnit[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ units: IInvalidUnit[] }>(`/projects/${projectUid}/indexes/${indexUid}/metadata/dtd/invalid`, {
      cancelToken: getAllSource.token
    });

    return response.data.units;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}
