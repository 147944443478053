import { ITask } from 'mm-types';
import * as React from 'react';

type Props = {
  task: ITask;
};

export const TagComponent = (props: Props) => {
  const { task } = props;
  if (!task) {
    return null;
  }
  return (
    <div className={'task-tag' + (task.active ? '' : ' task-disabled')} style={{ backgroundColor: task.colorHex }} key={task.identifier}>
      <span className="icon-tasks"></span>
      <span>{task.identifier}</span>
      <span className="icon-task-tag-background"></span>
    </div>
  );
};
