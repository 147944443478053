import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import ShareUsageUpdateStrategy from './ShareUsageUpdateStrategy';
import ContentTypes from './SharedContentLibTypes';
import EditorStore from '../../../flux/editor/EditorStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import { ISharedIndex } from 'mm-types';
import { Expanded } from './SmartContentLib';
import withTooltip from '../../hoc/withTooltip';

export type Props = {
  item: ISharedIndex;
  expanded?: Expanded | null;
  onExpand: (sharedIndex: ISharedIndex, usages?: null | ISharedIndex, onComplete?: () => void, forceOpen?: boolean) => void;
  onLookupUsages: (item: ISharedIndex) => void;
  insertShare: (item: ISharedIndex) => void;
  contentType: string;
  openDocumentInline: (sharedIndexId: string, e: { projectUid: string; startUnitUid: string }) => void;
};

export type State = {};

export default class SharedContentItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static defaultProps: Partial<Props> = {
    expanded: {
      loading: false,
      uid: null,
      content: null,
      usages: null
    }
  };

  _toggleExpanded(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onExpand(this.props.item);
  }

  _toggleUsages(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (!this._isExpanded()) {
      this.props.onExpand(this.props.item, null, () => {
        this.props.onLookupUsages(this.props.item);
      });
    } else {
      this.props.onLookupUsages(this.props.item);
    }
  }

  _isExpanded() {
    if (this.props.item && this.props.expanded && this.props.expanded.uid) {
      return this.props.item.uid === this.props.expanded.uid;
    }
  }

  _isUsagesExpanded() {
    return this._isExpanded() && this.props.expanded!.usages !== null;
  }

  _insertShare(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    this.props.insertShare(this.props.item);
  }

  _openOriginProjectInline(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDocumentInline(this.props.item.uid, {
      projectUid: this.props.item.originProjectUid!,
      startUnitUid: this.props.item.startUnitUid
    });
  }

  _openSourceProjectInline(e: React.MouseEvent<HTMLElement>, usage) {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDocumentInline(this.props.item.uid, {
      projectUid: usage.link.projectUid,
      startUnitUid: usage.link.unitUid
    });
  }

  _getItemType() {
    // by default show Shared Content item type
    let icon = 'filter_list',
      title: string = this.props.item.displayType;

    // for Regulations hardcode title
    if (this.props.contentType === ContentTypes.props.TYPE_REGULATIONS.key) {
      icon = 'verified_user';
      title = 'Regulations';
    }

    return (
      <span>
        <span className="material-icons">{icon}</span>
        <span className="sc-item-footer__type-title">{title}</span>
      </span>
    );
  }

  _getItemContent() {
    if (this._isExpanded() && this.props.expanded!.loading) {
      return <CircularProgress mode="indeterminate" size={17.85} />;
    } else {
      if (this._isExpanded() && this.props.expanded!.content) {
        return (
          <div>
            {this.props.expanded!.content!.map((unit) => {
              return (
                <div className="document-unit-outer" key={unit.uid}>
                  <div className="document-unit-inner">
                    <div dangerouslySetInnerHTML={{ __html: unit.html }} />
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else {
        return <div dangerouslySetInnerHTML={{ __html: this.props.item.startUnit ? this.props.item.startUnit.html : '' }} />;
      }
    }
  }

  _getUsages() {
    if (this._isExpanded() && this.props.expanded!.usages) {
      const usages = this.props.expanded!.usages!.map((usage, index) => {
        const updatedCls =
          'sc-item-usage-status ' +
          (usage.updateDeferred
            ? 'sc-item-usage-status__deferred'
            : usage.updateAvailable
            ? 'sc-item-usage-status__outdated'
            : 'sc-item-usage-status__up-to-date');

        const updateStrategyName = ShareUsageUpdateStrategy.props[usage.updateStrategy].shortTitle;

        return (
          <div className="sc-item-usage" key={index}>
            <div>
              <div className="sc-item-usage-project" title={usage.link.projectName}>
                {usage.link.projectName}
              </div>
              <div className="sc-item-usage-open">
                <span className="material-icons" onClick={(e) => this._openSourceProjectInline(e, usage)}>
                  open_in_new
                </span>
              </div>
            </div>
            <div>
              <div className="sc-item-usage-toc">{usage.link.tocableUnitOrdinal + ' ' + usage.link.tocableUnitHeading}</div>
              <div className={updatedCls}>
                <span className="icon-shared-content-indicator"></span>
                <span>
                  {usage.updateDeferred ? 'Deferred' : usage.updateAvailable ? 'Outdated' : 'Up-to-date'} ({updateStrategyName})
                </span>
              </div>
            </div>
          </div>
        );
      });
      return (
        <div className="sc-item-usages">
          <div className="sc-item-usages-title">SHARED USAGES</div>
          {usages}
        </div>
      );
    }
  }

  _getCounterClassNames() {
    const sharedIndex = this.props.item;
    let classNames = 'counter ';

    if (sharedIndex.usageDeferredCount === sharedIndex.usageUpdateAvailableCount && sharedIndex.usageDeferredCount > 0) {
      classNames += 'share-usages-deferred';
    } else if (sharedIndex.usageUpdateAvailableCount > 0) {
      classNames += 'share-usages-outdated';
    } else if (sharedIndex.usageDeferredCount > 0) {
      classNames += 'share-usages-deferred';
    } else {
      classNames += 'share-usages-uptodate';
    }

    return classNames;
  }

  isFromDifferentProjectType(): boolean {
    return this.props.item.projectDefinitionName !== ProjectStore.getProjectDefinitionType();
  }

  render() {
    const itemCls = 'sc-item-outer' + (this._isExpanded() ? ' sc-item-outer--expanded' : '');
    const expandIcon = this._isUsagesExpanded() ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    const contentCls = 'sc-item-sub-content' + (this._isExpanded() ? ' sc-item-sub-content--expanded' : '');
    const isReadOnly = EditorStore.isReadOnly();
    const project = ProjectStore.getProject()!;
    const DogEarWithTooltip = withTooltip(() => <div className="share-warning" />);

    return (
      <div className={itemCls} onClick={(e) => this._toggleExpanded(e)} id={'scl_item_' + this.props.item.uid}>
        {this.isFromDifferentProjectType() && (
          <DogEarWithTooltip
            tooltipText={`This share comes from another document type [ ${this.props.item.projectDefinitionName} ]`}
            tooltipHorizontalPosition="right"
          />
        )}
        <div className="sc-item-inner" style={{ paddingTop: this.isFromDifferentProjectType() ? '0px' : '1rem' }}>
          <div className="sc-item-top">
            <div className="sc-item-header">{this.props.item.name}</div>
            <div className="sc-item-buttons">
              <a className="sc-item-button" onClick={(e) => this._openOriginProjectInline(e)}>
                <span className="material-icons">open_in_new</span>
              </a>

              {!isReadOnly ? (
                <a className="sc-item-button" onClick={(e) => this._insertShare(e)}>
                  <span className={'material-icons' + (project.currentUserPermissions?.canManageShares ? '' : ' disabled')}>
                    add_circle
                  </span>
                </a>
              ) : null}
            </div>
          </div>

          <div className="sc-item-sub-header">{this.props.item.originProjectName}</div>

          <div className={contentCls}>{this._getItemContent()}</div>
        </div>

        {this._getUsages()}

        <div
          className={
            'sc-item-footer-outer' + (this.props.item.sharedIndexDiffersFromOriginLastPublished ? ' index-differs-from-last-published' : '')
          }
          onClick={(e) => this._toggleUsages(e)}
        >
          <div className="sc-item-footer">
            <div className="sc-item-footer__type">{this._getItemType()}</div>

            {/* TODO: introduce scoring component */}
            <div className="sc-item-footer__score">
              {/*
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star_border</span>
                            */}
            </div>

            <div className="sc-item-footer__extra">
              <span className="material-icons sc-item-footer__extra-item toggle-usage-open-icon">{expandIcon}</span>
              <span className={this._getCounterClassNames()}>{this.props.item.usageCount}</span>
              <span className="material-icons sc-item-footer__extra-item">share</span>
              <span className="material-icons sc-item-footer__extra-item usage-ref-icon">
                {this.props.item.allowDerivatives ? 'lock_open' : 'lock'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
