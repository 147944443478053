import * as React from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../flux/editor/EditorStore';
import { IEditorStoreEvent } from 'mm-types';
import BreadcrumbElement from './BreadcrumbElement';
import useListenToStore from '../../../hooks/useListenToStore';

export type Path = {
  index: number;
  element: HTMLElement;
  friendlyName: string;
};

export type Props = {
  root: string;
  onSelect: (elm: HTMLElement | null, isDoubleClick: boolean) => void;
};

export type State = {
  path: Path[];
  showingAll: boolean;
};

const EditorBreadcrumb = ({ root, onSelect }: Props) => {
  const [path, setPath] = React.useState<Path[]>([]);
  const [showingAll, setShowingAll] = React.useState(true);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate, update: [root] });

  function onEditStoreUpdate(e: IEditorStoreEvent<'nestedUnitFocusChange' | 'editBlur'>) {
    if (e) {
      if (e.type === 'nestedUnitFocusChange') {
        update(e.data!.nestedDOM);
      } else if (e.type === 'editBlur') {
        update([]);
      }
    }
  }

  const update = (path: Path[]) => {
    let bcPath: Path[] = [];

    if (path && path.length) {
      if (root === path[0].friendlyName && path[0].element.getAttribute('data-element-family') !== 'CollectionContent') {
        bcPath = path.slice(1, path.length);
      } else {
        bcPath = path;
      }
    }

    if (bcPath && bcPath.length) {
      const visiblePath = _.takeRight(bcPath, bcPath.length);
      setPath(visiblePath);
      setShowingAll(true);
    } else {
      setPath([]);
      setShowingAll(true);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, element: HTMLElement | null) => {
    e.stopPropagation();
    e.preventDefault();

    if (onSelect) {
      onSelect(element, false);
    }
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLElement>, element: HTMLElement | null) => {
    e.stopPropagation();
    e.preventDefault();

    if (onSelect) {
      setTimeout(() => {
        onSelect(element, true); // gives DOM a change to catch up after single click
      }, 100);
    }
  };

  return (
    <div className="dom-path-container">
      <div key="bc-root" className="dom-path-root" onClick={(e) => handleClick(e, null)} onDoubleClick={(e) => handleDoubleClick(e, null)}>
        <span style={path.length === 0 ? { backgroundColor: '#bff6ff', boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.2)' } : {}}>{root}</span>
      </div>

      {!showingAll ? <div key="bc-all">...</div> : null}

      {path.map((pathItem, index) => {
        return (
          <BreadcrumbElement
            pathItem={pathItem}
            handleClick={handleClick}
            handleDoubleClick={handleDoubleClick}
            isLast={index === path.length - 1}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default EditorBreadcrumb;
