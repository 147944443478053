import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import AppStateStore, { AppStateStoreEvent } from '../../flux/common/AppStateStore';
import * as React from 'react';
import * as _ from 'lodash';
import { RaisedButton, IconButton } from 'material-ui';
import BatchActionMenu from './BatchActionMenu';
import { IProject, IProjectDefinition } from 'mm-types';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import ProjectDefinitionStore, { ProjectDefinitionStoreEvent } from '../../flux/common/ProjectDefinitionStore';

export type Props = {
  onselected: (action: ActionType) => void;
  selected: IProject[] | null;
  workspaceUid: string;
};

export type State = {
  actions: Action[];
  openImportMenu: boolean;
  creationProjectDisabled: boolean;
};

export type ActionType =
  | 'create'
  | 'importDoc'
  | 'importExternalDoc'
  | 'copyDoc'
  | 'showBulkUpload'
  | 'delete_forever'
  | 'restore'
  | 'delete'
  | 'exportEdb'
  | 'exportAirbus'
  | 'exportArcml'
  | 'compliance';

export type Action = {
  fontelloName: string;
  name: ActionType;
  tooltip: string;
  enabled: null | boolean;
};

export default class DocsToolbar extends React.Component<Props, State> {
  private _actions: { actions: Action[] };
  private unsubscribe: Function;
  private unsubscribeProjectDefinitionStore: Function;

  constructor(props: Props) {
    super(props);

    this._actions = {
      actions: [
        {
          fontelloName: 'icon-copy-doc',
          name: 'copyDoc',
          tooltip: 'Copy Document',
          enabled: false
        }
      ]
    };

    this.state = _.extend(this._actions, {
      openImportMenu: false,
      creationProjectDisabled: !this._haveDefinitions(ProjectDefinitionStore.getProjectDefinitions())
    });
  }

  componentDidMount() {
    this.unsubscribe = AppStateStore.listen(this.onAppStoreChange, this);
    this.unsubscribeProjectDefinitionStore = ProjectDefinitionStore.listen(this.onProjectDefinitionStoreChange, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeProjectDefinitionStore();
  }

  static defaultProps: Partial<Props> = {
    selected: null,
    workspaceUid: 'personal'
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this._actions.actions[0].enabled = this.props.workspaceUid !== 'trash' && nextProps.selected && nextProps.selected.length === 1;
    this.setState(Object.assign({}, this._actions, { creationProjectDisabled: this.state.creationProjectDisabled }));
  }

  onProjectDefinitionStoreChange(e: ProjectDefinitionStoreEvent) {
    if (e.state && e.state.projectDefinitions && this._haveDefinitions(e.state.projectDefinitions)) {
      this.setState({ creationProjectDisabled: false });
    }
  }

  onAppStoreChange(e: AppStateStoreEvent) {
    if (e.type === 'menuChange') {
      if (e.data.openedMenu !== 'IMPORT') {
        this.setState({ openImportMenu: false });
      }
    }
  }

  _importFile(key: string) {
    if (key === 'aerodoc') {
      this.props.onselected('importDoc');
    } else if (key === 'external') {
      this.props.onselected('importExternalDoc');
    } else if (key === 'bulkExternal') {
      this.props.onselected('showBulkUpload');
    }
  }

  handleImportOnRequestChange(value: boolean) {
    this.setState({
      openImportMenu: value
    });
    if (value) {
      AppStateStore.openMenu('IMPORT');
    }
  }

  _haveDefinitions(definitions: IProjectDefinition[] | null): boolean {
    return !!definitions && definitions.length > 0;
  }

  _getImportMenu() {
    return (
      <li className={this.props.workspaceUid !== 'trash' ? 'enabled' : 'disabled'}>
        <IconMenu
          iconButtonElement={
            <IconButton
              iconStyle={{ marginTop: '16px' }}
              tooltip="Import Document"
              tooltipPosition="top-left"
              iconClassName="higher-icon material-icons icon-import"
            />
          }
          onRequestChange={(value) => this.handleImportOnRequestChange(value)}
          open={this.state.openImportMenu}
        >
          <MenuItem
            className="menuItem"
            key="0"
            primaryText="Aerodocs Document"
            disabled={false}
            onClick={() => {
              this._importFile('aerodoc');
            }}
          />
          <MenuItem
            className="menuItem"
            key="1"
            primaryText="External Document"
            disabled={false}
            onClick={() => {
              this._importFile('external');
            }}
          />
          {this._showBulkUpload() ? (
            <MenuItem
              className="menuItem"
              key="3"
              primaryText="Bulk External Documents"
              onClick={() => {
                this._importFile('bulkExternal');
              }}
            />
          ) : null}
        </IconMenu>
      </li>
    );
  }

  _showBulkUpload() {
    return ActiveUserStore.isAdmin();
  }

  render() {
    return (
      <ul className="icon-action-buttons">
        {!this.state.creationProjectDisabled && (
          <li className="raisedButton" key="create" onClick={() => this.props.onselected('create')}>
            <RaisedButton id="createProjectBtn" label="Create" primary={true} backgroundColor="#40C7FF" style={{ display: 'inline-flex' }}>
              <li className="material-icons icon-new-doc" />
            </RaisedButton>
          </li>
        )}
        {this._getImportMenu()}
        {this.state.actions.map((action) => {
          return (
            <li
              key={action.name}
              onClick={() => {
                this.props.onselected(action.name);
              }}
              className={`lower-icon ieTooltip ${action.enabled ? 'enabled' : 'disabled'}`}
            >
              {!action.fontelloName ? (
                <IconButton iconClassName="higher-icon material-icons mui-bigger" tooltip={action.tooltip} tooltipPosition="top-left">
                  {action.name}
                </IconButton>
              ) : undefined}
              {action.fontelloName ? (
                <IconButton
                  iconClassName={'higher-icon material-icons ' + action.fontelloName}
                  iconStyle={{ marginTop: '16px' }}
                  tooltip={action.tooltip}
                  tooltipPosition="top-left"
                />
              ) : undefined}
            </li>
          );
        })}
        <li>
          <div className="projectDropdown">
            <BatchActionMenu
              workspaceUid={this.props.workspaceUid}
              selected={this.props.selected}
              onselected={(e) => this.props.onselected(e as ActionType)}
            />
          </div>
        </li>
      </ul>
    );
  }
}
