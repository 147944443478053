import * as React from 'react';
import { CircularProgress } from 'material-ui';

type PropTypes = {
  visible?: boolean;
  label?: string | null;
  style?: React.CSSProperties;
  size?: number;
  className?: string;
};

const CircularProgressLoader = ({ visible = true, label, style, size, className = 'loading' }: PropTypes) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={className} style={style}>
      {label && <span>{label}</span>}
      <CircularProgress mode="indeterminate" size={size} />
    </div>
  );
};

export { CircularProgressLoader };
