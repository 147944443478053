import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { IProjectMembership } from 'mm-types';
import InProgress from '../InProgress';
import ProjectPropsStore from '../../../../flux/projects/ProjectPropsStore';

interface Props {
  team: {
    [prop: string]: IProjectMembership[];
  } | null;
  projectUid: string;
}

const GROUP_AVATAR = '/assets/images/user_avatars/user_group_icon.svg';

const NoTeamMembers = () => (
  <div className="empty-list">
    <h4>No team members in this document?</h4>
    <h5>Click the plus button to add members to this document. You can assign them author or read-only access.</h5>
  </div>
);

export function ProjectTeamDetails({ team, projectUid }: Props) {
  useEffect(() => {
    ProjectPropsStore.retrieveTeam(projectUid);
  }, [projectUid]);

  if (team === null) {
    return <InProgress />;
  }
  if (_.isEmpty(team)) {
    return <NoTeamMembers />;
  }
  const byRole = Object.keys(team!);
  return (
    <div className="document-info-team-list">
      {byRole.map((roleName) => {
        return (
          <div key={roleName} className="team-role-members" data-qa-role-name={roleName}>
            <div className="role-header">{roleName}</div>
            <ul>
              {_.map(team![roleName], (member) => {
                const userName = member.group ? member.group.name : member.user?.displayName;
                return (
                  <li key={member.uid}>
                    <img src={member.group ? GROUP_AVATAR : member.user?.avatarUrl} alt={userName} />
                    <span className="user-name">{userName}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
