import React from 'react';
import { Media } from '../../MediaLibContent';
import { MediaTypesContants } from '../../types';
import { MediaInsertUtils } from '../../utils/mediaInsertUtils';
import FileIcon from '../../../../misc/icons/FileIcon';

export function getThumbUrl(media: Media, size = 300): string {
  if (media.location) {
    const match = '/content';
    return `${media.location.substr(0, media.location.indexOf(match) + match.length)}?recipeName=thumb${size}&attachment=true`;
  }
  return '';
}

export default (props: { media: Media; caption: string }) => {
  switch (props.media.type) {
    case MediaTypesContants.image:
    case MediaTypesContants.symbol:
      return (
        <img
          src={getThumbUrl(props.media)}
          alt={props.caption}
          data-qa={`${MediaInsertUtils.isImage(props.media) ? 'image-thumb' : 'symbol-thumb'}`}
        />
      );
    case MediaTypesContants.video:
    case MediaTypesContants.audio:
    case MediaTypesContants.other:
      return (
        <div
          className="media-thumb"
          data-qa={`${
            MediaInsertUtils.isVideo(props.media) ? 'video-thumb' : MediaInsertUtils.isAudio(props.media) ? 'audio-thumb' : 'other-thumb'
          }`}
        >
          <FileIcon
            fileType={props.media.type}
            mimeType={props.media.mimeType}
            className={`${props.media.type == MediaTypesContants.other ? 'other-icon' : ''}`}
            style={{ fontSize: 200 }}
          />
        </div>
      );
  }
};
