import * as Reflux from 'reflux';
import * as _ from 'lodash';
import EditorStore from './EditorStore';
import { EditorModes as EditorModeTypes } from './EditorModes';
import TocStore from './TocStore';
import ProjectStore from './ProjectStore';
import { fetchWords, highlight, parseSnippet } from '../../utils/wordlist';
import { Dom } from '../../components/editor/utils/tinyFacade/DomUtil';
import * as client from '../../clients/spell-check';
import Store from '../Store';
import { IFindModel, ILink } from 'mm-types';

const SELECTOR = '.arc-matched-text';

export type Word = {
  parent: string;
  indexWithinParent: number;
  word: string;
  globalIndex: number;
  globalOccurrence?: number;
};

export type Options = {
  preserveCase: boolean;
  matchCase: boolean;
  wholeWord: boolean;
  replacement: string;
  target: string;
};

export type Match = {
  chapter: string;
  matches: IFindModel[];
};

interface LastOpenedView {
  chapterUid: string;
  sectionUid: string;
  projectUid: string;
  indexUid: string;
}

export type State = {
  findWord: null | string;
  wordList: Word[];
  current: number;
  scope?: 'global' | 'current';
  globalMatches?: Match[];
  globalMatchesFlat?: IFindModel[];
  currentOnRefresh: number;
  replacing?: boolean;
  word?: Word;
  options?: Options;
  globalMatchesCount?: number;
};

export type FindReplaceStoreEvent = State;

export class FindReplaceStore extends Store<State> {
  private _isStoreBusy: boolean;
  private openedView: LastOpenedView | null = null;
  private currentLink: ILink | null = null;
  private currentIndexWithinParent?: number;
  private scrollPending: boolean;
  constructor() {
    super();

    this._isStoreBusy = false;
    this.scrollPending = true;
    this.state = this.initialState();
  }

  getInitialState() {
    return this.state;
  }

  initialState(): State {
    return {
      findWord: null, // the last word we searched for
      wordList: [], // the found words (see utils/wordlist)
      current: 0, // the index of the word which the user is currently looking at,
      currentOnRefresh: 0
    };
  }

  docUnitsRenderUpdateComplete(updateProps = { isComponentizationChange: false }) {
    if (EditorStore.isMode('FINDREPLACE')) {
      const highlighted = highlight(SELECTOR, this.currentLink?.unitUid || '', this.currentIndexWithinParent ?? 0);

      // ensure highlighted element is in view (for large units where scrolling to unit insufficient)
      if (this.currentLink && !!highlighted && this.scrollPending && !Dom.isScrolledIntoView(highlighted)) {
        const closestHighlightedElement = highlighted.closest('[data-nid]')?.getAttribute('data-nid');
        this.scrollPending = false;
        EditorStore.scrollToUnit(this.currentLink.unitUid, closestHighlightedElement);
      }

      if (!updateProps.isComponentizationChange) {
        this.state.wordList = fetchWords(SELECTOR, (data, e) => this.onClickWord(data, e));
        this.state.current = this.state.currentOnRefresh && this.state.currentOnRefresh >= 0 ? this.state.currentOnRefresh : 0;
        this._triggerIndexUpdate();
      }
    }
  }

  findGlobalMatchIndex(unitUidToFind: string, indexWithinParentToFind: number) {
    return this.state.globalMatchesFlat?.findIndex((match) => {
      const { indexWithinParent } = parseSnippet(match.snippet),
        matchUnitUid = match.link.unitUid;

      return unitUidToFind === matchUnitUid && indexWithinParent === indexWithinParentToFind;
    });
  }

  globalSearchResultSelect(data: { l?: ILink; indexWithinParent?: number }, prev = false) {
    if (data.l) {
      this.currentLink = data.l;
      this.currentIndexWithinParent = data.indexWithinParent;

      const newViewParams = {
        chapterUid: data.l.chapterUid,
        sectionUid: data.l.sectionUid,
        projectUid: data.l.projectUid,
        indexUid: data.l.indexUid
      };

      // re-find selected match
      this.state.current = this.state.currentOnRefresh =
        this.findGlobalMatchIndex(data.l.unitUid, data.indexWithinParent ?? 0) ?? this.state.current;
      this.currentLink = data.l;

      if (this.isDifferentTocableOpen(newViewParams)) {
        this.openedView = { ...newViewParams };
        this.scrollPending = true;
        EditorStore.openDocument(
          {
            chapterUid: data.l.chapterUid,
            sectionUid: data.l.sectionUid,
            uid: data.l.unitUid
          },
          data.l.projectUid,
          data.l.indexUid,
          data.l.tocableUnitUid
        );
      } else {
        this.openedView = { ...newViewParams };
        this.scrollPending = true;
        EditorStore.openDocument(
          {
            chapterUid: data.l.chapterUid,
            sectionUid: data.l.sectionUid,
            uid: data.l.unitUid
          },
          data.l.projectUid,
          data.l.indexUid,
          data.l.tocableUnitUid
        );
      }
    }
  }

  isDifferentTocableOpen(newView: LastOpenedView) {
    if (!this.openedView && newView.projectUid) {
      return false;
    }
    const isEqual = _.isEqual(this.openedView, newView) as Boolean;
    return !isEqual;
  }

  onChangeMode(newMode: EditorModeTypes) {
    if (newMode !== 'FINDREPLACE') {
      this.clear();
      this.trigger(this.state);
    }
  }

  /*
  Also listened to by editorStore
   */
  async find(word?: string, options?: any, scope?: 'current' | 'global', gotoFirstResult = false) {
    this.state.findWord = word || this.state.findWord;
    this.state.options = options || this.state.options;
    this.state.scope = scope || this.state.scope;
    this._isStoreBusy = true;

    this.trigger(this.state);

    let parentUid: string | null = null;
    if (this.state.scope === 'current') {
      parentUid = TocStore.getFirstSelectedTocableUnit()!.uid;
    }

    const matches = await client.find(ProjectStore.getProject()!.uid, ProjectStore.getCurrentRevisionUid(), {
      findText: this.state.findWord,
      matchCase: this.state.options!.matchCase,
      wholeWord: this.state.options!.wholeWord,
      startUnitUid: parentUid
    });

    this._isStoreBusy = false;
    this.state.globalMatches = matches.globalMatches;
    this.state.globalMatchesFlat = matches.globalMatchesFlat;
    this.state.globalMatchesCount = matches.globalMatchesCount;
    this.trigger(this.state);

    if (this.state.globalMatches.length > 0 && gotoFirstResult) {
      const docParams = EditorStore.getDocParams();
      const globalMatch = this.state.globalMatches[0];
      this.currentLink = globalMatch.matches[0].link ?? null;
      EditorStore.openDocumentWithUnit(
        { chapterUid: globalMatch.chapter, uid: globalMatch.matches[0].link.unitUid },
        null,
        docParams.projectUid!,
        docParams.indexUid!,
        globalMatch.matches[0].link.tocableUnitUid
      );
    }
  }

  isBusy() {
    return this._isStoreBusy;
  }

  getGlobalMatches() {
    return this.state.globalMatches;
  }

  getGlobalMatchesCount() {
    return this.state.globalMatchesCount;
  }

  onClickWord(word: Word, e: JQuery.ClickEvent) {
    const index = this.findGlobalMatchIndex(word.parent, word.indexWithinParent);

    if (!!index && index >= 0) {
      this.state.current = index;
      this.currentLink = this.state.globalMatchesFlat?.[index].link ?? null;
      this._triggerIndexUpdate();
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  nextFoundWord() {
    const currentIndex = this.currentLink ? this.currentLink.globalMatchIndex : 0;
    let nextIndex = currentIndex + 1;
    if (nextIndex >= this.state.globalMatchesFlat!.length) {
      nextIndex = 0;
    }
    this.currentLink = this.state.globalMatchesFlat?.[nextIndex]?.link ?? null;
    this.state.current = nextIndex;
    const { indexWithinParent } = parseSnippet(this.state.globalMatchesFlat?.[nextIndex]?.snippet ?? '');
    this.globalSearchResultSelect({ l: this.state.globalMatchesFlat?.[nextIndex]?.link, indexWithinParent: indexWithinParent });
  }

  previousFoundWord() {
    const currentIndex = this.currentLink ? this.currentLink.globalMatchIndex : 0;
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = this.state.globalMatchesFlat!.length - 1;
    }
    this.state.current = prevIndex;
    const { indexWithinParent } = parseSnippet(this.state.globalMatchesFlat?.[prevIndex]?.snippet ?? '');
    this.globalSearchResultSelect({ l: this.state.globalMatchesFlat?.[prevIndex]?.link, indexWithinParent: indexWithinParent }, true);
  }

  currentWord() {
    if (this.state.globalMatchesFlat) {
      const currentGlobalMatch: IFindModel = this.state.globalMatchesFlat[this.state.current];

      if (currentGlobalMatch) {
        const { indexWithinParent } = parseSnippet(currentGlobalMatch.snippet),
          unitUid = currentGlobalMatch.link.unitUid;

        // Find word on the screen based on the indexWithinParent and unitUid of currently selected global match
        const index = this.state.wordList.findIndex((w) => w.parent === unitUid && w.indexWithinParent === indexWithinParent);
        return index > -1 && index <= this.state.wordList.length ? this.state.wordList[index] : undefined;
      }
    }
  }

  getTotalMatches() {
    return this.state.wordList ? this.state.wordList.length : 0;
  }

  async replaceWord(word: string | null) {
    if (!this.currentWord()) {
      return;
    }

    this.state.currentOnRefresh = this.state.current;
    this.state.replacing = true;
    this.trigger(this.state);

    await EditorStore.backendReplace({
      replaceChildren: false,
      unitUid: this.currentWord()?.parent,
      preserveCase: this.state.options!.preserveCase,
      matchCase: this.state.options!.matchCase,
      wholeWord: this.state.options!.wholeWord,
      index: this.currentWord()?.indexWithinParent,
      replacement: word || '',
      target: this.currentWord()?.word
    });

    this.state.replacing = false;
    this.trigger(this.state);
    this.find();

    this.nextFoundWord();
  }

  isReplacing() {
    return !!this.state.replacing;
  }

  async replaceAllWord(word: string | null) {
    this.state.currentOnRefresh = this.state.current;
    this.state.replacing = true;
    this.trigger(this.state);

    const apiOptions: Options = {
      preserveCase: this.state.options!.preserveCase,
      matchCase: this.state.options!.matchCase,
      wholeWord: this.state.options!.wholeWord,
      replacement: word || '',
      target: this.state.findWord!
    };

    if (this.state.scope === 'current') {
      apiOptions['replaceChildren'] = true;
      apiOptions['unitUid'] = TocStore.getFirstSelectedTocableUnit()!.uid;
    } else {
      apiOptions['replaceAll'] = true;
    }

    await EditorStore.backendReplace(apiOptions);
    this.state.replacing = false;
    this.trigger(this.state);
    this.find();
  }

  findWord() {
    return this.state.findWord;
  }

  clear() {
    this.scrollPending = true;
    this.state = this.initialState();
  }

  // private helpers

  _triggerIndexUpdate(isSameView = false) {
    if (!this.state.wordList || !EditorStore.isMode('FINDREPLACE') || this.state.wordList.length === 0) {
      this.trigger({} as FindReplaceStoreEvent);
    }

    this.state.word = this.currentWord(); // computed field but still handy to have
    // this.state.word = this.state.wordList[this.state.current]; // computed field but still handy to have
    this.trigger({
      wordList: this.state.wordList,
      current: this.state.current,
      word: this.state.word
    } as FindReplaceStoreEvent);

    setTimeout(
      () => {
        highlight(SELECTOR, this.state.word?.parent || '', this.state.word?.indexWithinParent ?? 0);
      },
      isSameView ? 0 : 500
    );
  }
}

const singleton = Reflux.initStore<FindReplaceStore>(FindReplaceStore);
export default singleton;
