/* FRAMEWORK */
import * as React from 'react';
import { useEffect, useState } from 'react';
/* HOC */
import withInsertRules, { WithInsertRulesProps } from '../../../hoc/withInsertRules';
import withClosestTocable from '../../../hoc/withClosestTocable';
import withSelectedUnit, { WithSelectedUnitProps, WithSelectedUnitState } from '../../../hoc/withSelectedUnit';
import compose from '../../../../utils/compose';
/* COMPONENTS */
import ContentMenuContainer, { getInsertAtLevel, InsertAction } from './content/ContentMenuContainer';
import StructureMenu from './components/StructureMenu';
import InsertShortCuts from './components/InsertShortCuts';
import SpecialCharMenuItem from '../SpecialCharMenuItem';
import { elementDefinitionToMenuItem, unitDefinitionToMenuItem } from './components/InsertMenuItem';
import EditorStore from '../../../../flux/editor/EditorStore';
/* TYPES */
import { UnitTypes } from '../../utils/units/UnitTypes';
import { ElementTypes } from '../../utils/units/ElementTypes';
import { areMenuPropsEqual } from '../MenuMemoUtil';

export type DefinitionToMenuPropsType = {
  insertAtLevel: number;
  insertPosition: InsertAction;
  validConverts: string[];
  parentUnitUid?: string;
};

export type MenuInsertOnSelectParams = {
  menu: 'insert';
  unitType?: UnitTypes | ElementTypes | null;
  insertPosition: InsertAction;
  isShortcut: boolean;
  isInsertInline: boolean;
  insertPoint: HTMLElement | null;
  parentUnitUid?: string;
};

export interface Props {
  isSingleVol: boolean;
  onselected(e: MenuInsertOnSelectParams): void;
}

export interface ComposedProps extends Props, WithSelectedUnitProps, WithSelectedUnitState, WithInsertRulesProps {}

function MenuInsert(props: ComposedProps) {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [openKeysStructure, setOpenKeysStructure] = useState<any[]>([]);
  const [insertPosition, setInsertPosition] = useState<InsertAction>('insert_inside');

  useEffect(() => {
    EditorStore.triggerChangeInsertPosition(insertPosition);
  }, []);

  const updateInsertPosition = (insertPositionToUpdate: InsertAction) => {
    console.log('updating insertPosition to: ' + insertPositionToUpdate);
    setInsertPosition(insertPositionToUpdate);
    EditorStore.triggerChangeInsertPosition(insertPositionToUpdate);
  };

  const isDefinitionAllowed = (definitionId: string, insertPositionToCheck: InsertAction): boolean => {
    if (props.isActivelyEditing) {
      const insertAtLevel: number = getInsertAtLevel(insertPositionToCheck);
      let key: string = definitionId;
      key = key.startsWith('el') ? key.replace('el_', '') : key;
      const { disabled } = props.isElementInsertable(key, insertAtLevel);
      return !disabled;
    } else {
      return props.rules.selectedUnitCanBeFollowedByThis(definitionId);
    }
  };

  const triggerOnSelected = (
    unitType: string,
    insertAtLevel: number,
    insertPositionOnSelected: InsertAction,
    isShortcut = false,
    parentUnitUid?: string
  ) => {
    let key: string = unitType;
    key = key.startsWith('el') ? key.replace('el_', '') : key;
    const { insertElement, insertElementDirectChild } = props.isElementInsertable(key, insertAtLevel);
    const insertPoint: HTMLElement | null = insertPositionOnSelected === 'insert_inside' ? insertElement : insertElementDirectChild;

    if (props.onselected) {
      props.onselected({
        menu: 'insert',
        unitType: key.toLowerCase() as UnitTypes | ElementTypes,
        isInsertInline: props.isActivelyEditing,
        insertPosition: insertPositionOnSelected,
        insertPoint: insertPoint || null,
        isShortcut: isShortcut,
        parentUnitUid
      });
    }
  };

  const handleDropdownClick = (e: { key: string }, definitionToMenuProps: DefinitionToMenuPropsType) => {
    setOpenKeys([]);
    setOpenKeysStructure([]);

    triggerOnSelected(
      e.key,
      definitionToMenuProps.insertAtLevel,
      definitionToMenuProps.insertPosition,
      false,
      definitionToMenuProps.parentUnitUid
    );
  };

  const handleInsertClick = (e, unitType) => {
    triggerOnSelected(unitType, getInsertAtLevel(insertPosition), insertPosition, true);

    e.stopPropagation();
    e.preventDefault();
  };

  const disabled: boolean = EditorStore.isMode('TOCMAN') || !!(props.editingDUProfile && props.editingDUProfile.disableInsertContentMenu);
  return (
    <nav className="page-body-header icon-header editor-menu editor-insert-menu no-box-shadow">
      <div className="nav-wrapper">
        <ul className="icon-action-buttons ieTooltip">
          <li className="icon-menu-container insert-structure-menu">
            <div className="content-menu-container">
              <StructureMenu
                structuralMenuItems={props.structuralMenuItems}
                unitDefinitionToMenuItem={unitDefinitionToMenuItem(props.rules.isTocableTypeInsertable)}
                selectedUnit={props.selectedUnit}
                handleDropdownClick={handleDropdownClick}
                syncOpenKeys={setOpenKeys}
                openKeys={openKeys}
                isSingleVol={props.isSingleVol}
                isActivelyEditing={props.isActivelyEditing}
              />
            </div>
          </li>

          <li className="seperator" />

          <li className="icon-menu-container">
            <ContentMenuContainer
              currentAction={insertPosition}
              updateInsertPosition={(newAction: InsertAction) => updateInsertPosition(newAction)}
              disabled={disabled}
              action="insert"
              definitions={props.isActivelyEditing ? props.contentMenuElements : props.contentMenuUnits}
              definitionToMenuItem={
                props.isActivelyEditing
                  ? elementDefinitionToMenuItem(props.isElementInsertable)
                  : unitDefinitionToMenuItem(props.rules.selectedUnitCanBeFollowedByThis)
              }
              disableContentMenu={disabled}
              handleDropdownClick={handleDropdownClick}
              syncOpenKeysStructure={setOpenKeysStructure}
              openKeysStructure={openKeysStructure}
              isActivelyEditing={props.isActivelyEditing}
            />
          </li>

          <li className="seperator" />

          <InsertShortCuts
            isDefinitionAllowed={isDefinitionAllowed}
            menuContents={props.shortcutIcons}
            insertPosition={insertPosition}
            disabled={disabled}
            handleInsertClick={handleInsertClick}
            isActivelyEditing={props.isActivelyEditing}
          />

          <li className="seperator" />

          <SpecialCharMenuItem enabled={props.shortcutIcons.specialActions[0].enabled} />

          {/* Current not working
            <MeasureInsertMenu enabled={props.isActivelyEditing} />*/}
        </ul>
      </div>
    </nav>
  );
}

export default compose(withSelectedUnit, withClosestTocable, withInsertRules)(React.memo(MenuInsert, areMenuPropsEqual));
