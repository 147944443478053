import * as React from 'react';
import * as _ from 'lodash';
import FlatButton from 'material-ui/FlatButton';
import { ITag } from 'mm-types';

export type Props = {
  onSearch?: (e: { contentTags: ITag[] }) => void;
  onClose?: () => void;
  contentTags?: ITag[];
  contentTagsSelected: ITag[];
};

export type State = {
  selectedContentTags: {};
};

export default class TagsSharedLibPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContentTags: props.contentTagsSelected
        ? this._getSelectedContentTags(this.props.contentTagsSelected, this.props.contentTags)
        : {}
    };
  }

  static defaultProps: Partial<Props> = {
    onSearch: () => {},
    onClose: () => {},
    contentTags: []
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.contentTagsSelected && nextProps.contentTags) {
      this.setState({
        selectedContentTags: this._getSelectedContentTags(nextProps.contentTagsSelected, nextProps.contentTags)
      });
    }
  }

  _getSelectedContentTags(selectedTags, allTags) {
    // Traverse allTags and check which one is in selectedTags array
    const selectedContentTags = {};
    for (let i = 0; i < allTags.length; i++) {
      selectedContentTags[i] = selectedTags.map((tag) => tag.uid).indexOf(allTags[i].uid) >= 0;
    }

    return selectedContentTags;
  }

  _toggleContentTag(index: number) {
    const selectedContentTags = _.cloneDeep(this.state.selectedContentTags);
    selectedContentTags[index] = selectedContentTags[index] ? false : true;

    this.setState({ selectedContentTags: selectedContentTags }, () => {
      const filtered = this.props.contentTags!.filter((contentTag, index) => {
        return this.state.selectedContentTags[index];
      });

      this.props.onSearch!({ contentTags: filtered });
    });
  }

  _isSelected(index) {
    return this.state.selectedContentTags[index];
  }

  _getContentTags() {
    const contentTags = this.props.contentTags!.map((contentTag, index) => {
      const contentTagCls = this._isSelected(index) ? 'shared-content-tag shared-content-tag--selected' : 'shared-content-tag';

      return (
        <span className={contentTagCls} key={index} onClick={() => this._toggleContentTag(index)}>
          {contentTag.value}
        </span>
      );
    });
    return contentTags;
  }

  _clear() {
    this.props.onClose!();
  }

  render() {
    return (
      <div className="sub-panel shared-content__tags-panel">
        <div className="shared-content__tags-panel-main">{this._getContentTags()}</div>

        <div className="shared-content__tags-panel-actions">
          <FlatButton label="CLEAR" primary={true} keyboardFocused={false} onClick={() => this._clear()} ref="clear" />
        </div>
      </div>
    );
  }
}
