import * as React from 'react';
import * as _ from 'lodash';
import ColorPicker from './color/ColorPicker';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';
import { isEnterKey } from '../../../../utils/keyIdentifier';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { Color, IElementSettings } from 'mm-types';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import useListenToStore from '../../../../../hooks/useListenToStore';

/*
 UTILS --

 These are just used to transform a styles object from
 {border-bottom-width: "20px"} to {borderBottomWidth: "20px"}
 So that we can interchange the same object between react and
 tinymce.
 */
function capitalize(val: string) {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

function dashesToCamelCase(obj) {
  const newObj = {};
  for (const k in obj) {
    const name = _.map(k.split('-'), capitalize).join('');
    newObj[name.charAt(0).toLowerCase() + name.slice(1)] = obj[k];
  }
  return newObj;
}

export type Props = { settings?: IElementSettings[] };

export type BorderBlock = {
  position: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
  edges: ('top' | 'left' | 'right' | 'vertical' | 'horizontal' | 'bottom')[];
};

export type BorderButton = {
  tooltip: string;
  icon: string;
};

export type BorderStyle = {
  style: string;
  width: string;
  color: Color | null;
};

export type State = {
  maxWidth: number;
  style: string;
  color: Color;
  colorUpdated: boolean;
  width: number;
  borderBlocks: BorderBlock[];
  borderButtons: BorderButton[];
  selectedBorder: string;
} & Border &
  InnerBorder;

export type Border = {
  top: null | BorderStyle | string;
  left: null | BorderStyle | string;
  bottom: null | BorderStyle | string;
  right: null | BorderStyle | string;
  horizontal: null | BorderStyle | string;
  vertical: null | BorderStyle | string;
};

export type InnerBorder = {
  isHorizontal: null | boolean;
  isVertical: boolean;
};

type TableBorderActions =
  | { type: 'setState'; payload: Partial<State> }
  | { type: 'setWidth'; payload: number }
  | { type: 'setColor'; payload: Color }
  | { type: 'setColorWithoutColorUpdated'; payload: Color }
  | { type: 'setColorUpdated'; payload: boolean };

const TableBorderReducer: React.Reducer<State, TableBorderActions> = (state, action) => {
  switch (action.type) {
    case 'setState':
      return { ...state, ...action.payload };
    case 'setWidth':
      return { ...state, width: action.payload };
    case 'setColor':
      return { ...state, colorUpdated: true, color: action.payload };
    case 'setColorWithoutColorUpdated':
      return { ...state, color: action.payload };
    case 'setColorUpdated':
      return { ...state, colorUpdated: action.payload };
  }
};

enum BorderTypes {
  BORDER_CLEAR = 'border_clear',
  BORDER_ALL = 'border_all',
  BORDER_OUTER = 'border_outer'
}

export const INITIAL_BLACK: Color = {
  day: { id: 'black', name: 'Black', value: '#000000' },
  night: { id: 'white', name: 'White', value: '#FFFFFF' }
};

function TableBorder(props: Props) {
  useListenToStore({ store: EditorStore, eventListener: tinyUpdateEvent });

  const [state, dispatch] = React.useReducer(TableBorderReducer, getInitialState());

  function getInitialState(): State {
    return {
      maxWidth: 10,
      style: 'solid',
      color: INITIAL_BLACK,
      colorUpdated: false,
      width: 2,
      top: null,
      left: null,
      bottom: null,
      right: null,
      horizontal: null,
      vertical: null,
      isHorizontal: false,
      isVertical: false,
      borderBlocks: [
        { position: 'top-left', edges: ['top', 'left', 'vertical', 'horizontal'] },
        { position: 'top-right', edges: ['top', 'vertical', 'right', 'horizontal'] },
        { position: 'bottom-left', edges: ['horizontal', 'left', 'vertical', 'bottom'] },
        { position: 'bottom-right', edges: ['horizontal', 'vertical', 'right', 'bottom'] }
      ],
      borderButtons: [
        { tooltip: 'Clear borders', icon: BorderTypes.BORDER_CLEAR },
        { tooltip: 'All borders', icon: BorderTypes.BORDER_ALL },
        { tooltip: 'Outer borders', icon: BorderTypes.BORDER_OUTER }
      ],
      selectedBorder: BorderTypes.BORDER_ALL
    };
  }

  React.useEffect(() => {
    _updateBorders();
  }, []);

  React.useEffect(() => {
    if (state.colorUpdated) {
      _updateBorder();
      dispatch({ type: 'setColorUpdated', payload: false });

      // set Black colour as a default when ClearColor is selected
      if (state.color.day.id === 'ClearColor') {
        const color = ProjectDefinitionStore.getColorById('black');
        dispatch({ type: 'setColorWithoutColorUpdated', payload: color ? color : state.color });
      }
    }
  }, [state.color]);

  function tinyUpdateEvent(e) {
    if (e.type === 'unitDomChange' || e.type === 'nestedUnitFocusChange') {
      _updateBorders();
    }
  }

  function _updateBorders() {
    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
    const borders = tinyFacade.getCommonBorderStyles();
    const innerBorders = tinyFacade.areInnerBordersAvailable();
    dispatch({ type: 'setState', payload: _.merge(borders, innerBorders) });
  }

  function _clickShortcutBorderButton(action) {
    let currentStyle: BorderStyle = {
      style: state.style,
      width: state.width + 'px',
      color: state.color
    };
    let sides: string[] = [];

    if (action === BorderTypes.BORDER_CLEAR) {
      currentStyle = { style: '', width: '0', color: null };
      sides = ['top', 'right', 'bottom', 'left', 'horizontal', 'vertical'];
    } else if (action === BorderTypes.BORDER_ALL) {
      currentStyle = { style: 'solid', width: currentStyle.width, color: currentStyle.color ?? INITIAL_BLACK };
      sides = ['top', 'right', 'bottom', 'left', 'horizontal', 'vertical'];
    } else if (action === BorderTypes.BORDER_OUTER) {
      currentStyle = { style: 'solid', width: currentStyle.width, color: currentStyle.color ?? INITIAL_BLACK };
      sides = ['top', 'right', 'bottom', 'left'];
    }

    const stateUpdate = sides.reduce((stateUpdate, side) => {
      stateUpdate[side] = currentStyle;
      return stateUpdate;
    }, {});

    dispatch({ type: 'setState', payload: { ...stateUpdate, selectedBorder: action } });

    // this needs to be executed after state is set ...
    const styles = sides.reduce((styles: any[], side: string) => {
      styles.push(calcStyle(side, stateUpdate));
      return styles;
    }, []);
    const facade = EditorStore.getEditor().getActiveEditorFacade() as any;
    facade.execCommand('arcCellSetStyle', { style: styles });
    facade.clearSelection();
  }

  function _clickBorder(side) {
    // Refuse actions on horizontal or vertical borders when not available
    if ((side === 'horizontal' && !state.isHorizontal) || (side === 'vertical' && !state.isVertical)) {
      return;
    }

    const stateUpdate = {};
    stateUpdate[side] = {
      style: state.style,
      width: state.width + 'px',
      color: state.color
    };

    // [state.width + 'px', state.style, state.color.day.value.replace('a', '').replace(', 1)', ')')].join(' ');

    if (
      !!state[side] &&
      state[side].style === stateUpdate[side].style &&
      state[side].width === stateUpdate[side].width &&
      state[side].color.day.id == stateUpdate[side].color.day.id
    ) {
      // if effect is the same user wants to turn border off
      stateUpdate[side] = {
        width: '0',
        style: ''
      };
    }

    dispatch({ type: 'setState', payload: { ...stateUpdate, selectedBorder: side } });

    const facade = EditorStore.getEditor().getActiveEditorFacade() as any;
    facade.execCommand('arcCellSetStyle', { style: [calcStyle(side, stateUpdate)] });
    facade.clearSelection();
  }

  function calcStyle(side: string, stateUpdate: Partial<State>) {
    const styles = {};
    styles['border-' + side] = stateUpdate[side] !== null ? stateUpdate[side] : state[side];
    return styles;
  }

  function calcStyleForBlock(position) {
    const blockEdgesBorders = {
      'top-left': { top: 'top', right: 'vertical', bottom: 'horizontal', left: 'left' },
      'top-right': { top: 'top', right: 'right', bottom: 'horizontal', left: null },
      'bottom-left': { top: null, right: 'vertical', bottom: 'bottom', left: 'left' },
      'bottom-right': { top: null, right: 'right', bottom: 'bottom', left: null }
    };

    const blockEdges = ['top', 'bottom', 'left', 'right'];

    const styles = blockEdges.reduce((styles, blockEdge) => {
      // Make sure there are no double borders (horizontal & vertical can be doubled)
      const border = blockEdgesBorders[position][blockEdge];
      if (border) {
        let colorValue;

        if (!!state[border] && !!state[border].color) {
          const color = ProjectDefinitionStore.getColorById(state[border].color.day.id);
          if (!!color) {
            colorValue = color.day.value;
          }
        }

        // state[border] holds BorderStyle object and we recognize color in it
        if (!!colorValue) {
          styles['border-' + blockEdge] = `${state[border].width} ${state[border].style} ${colorValue}`;
        }
        // state[border] holds BorderStyle object but color is missing (borders were cleared)
        else if (!!state[border] && state[border].color === null) {
          styles['border-' + blockEdge] = 'none';
        }
        // state[border] is simply a string. Must be coming from the older way of applying colours to tables (inline style instead of data attributes).
        else {
          styles['border-' + blockEdge] = state[border];
        }

        // For horizontal & vertical check if selection allows for them
        if (['horizontal', 'vertical'].indexOf(border) > -1) {
          if ((border === 'horizontal' && !state.isHorizontal) || (border === 'vertical' && !state.isVertical)) {
            styles['border-' + blockEdge] = 'none';
          }
        }
      } else {
        styles['border-' + blockEdge] = 'none';
      }
      return styles;
    }, {});

    return styles;
  }

  function _updateWidth(e) {
    let value = e.target.value;

    if (value > state.maxWidth) {
      value = 10;
    } else if (!/^[0-9][0-9]?$/.test(value)) {
      value = value.slice(0, -1);
    }

    if (value || value === '') {
      dispatch({ type: 'setWidth', payload: value });
    }
  }

  function _updateColor(color) {
    dispatch({ type: 'setColor', payload: color });
  }

  function _updateBorder() {
    const { selectedBorder } = state;
    if (selectedBorder === BorderTypes.BORDER_ALL || selectedBorder === BorderTypes.BORDER_OUTER) {
      _clickShortcutBorderButton(selectedBorder);
    } else if (
      selectedBorder === 'top' ||
      selectedBorder === 'bottom' ||
      selectedBorder === 'left' ||
      selectedBorder === 'right' ||
      selectedBorder === 'horizontal' ||
      selectedBorder === 'vertical'
    ) {
      _clickBorder(selectedBorder);
    }
  }

  return (
    <div className="border-edit-container">
      <h6>BORDER</h6>

      <div className="manip-group">
        <div className="border-left-side">
          <h6>Border none / all / outer</h6>
        </div>

        <div className="border-right-side">
          <ul className="icon-action-buttons">
            {state.borderButtons.map((button) => {
              const disabled = !settingEnabled('borders', props.settings);
              return (
                <li
                  key={button.icon}
                  data-borderaction={button.icon}
                  onClick={() => {
                    _clickShortcutBorderButton(button.icon);
                  }}
                  className={disabled ? 'disabled' : ''}
                >
                  <IconButton
                    iconClassName={'material-icons '}
                    tooltipStyles={{ top: '70%', pointerEvents: 'none', zIndex: 999 }}
                    tooltipPosition="bottom-left"
                    tooltip={button.tooltip}
                    disabled={disabled}
                  >
                    {button.icon}
                  </IconButton>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div>
        {!ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
          <div className="border-left-side">
            <div className="border-input-field">
              <h6>Width</h6>
              <TextField
                id="text-border-width"
                className="text-field"
                value={'' + state.width}
                style={{ fontSize: '0.7em' }}
                onChange={(e) => _updateWidth(e)}
                onBlur={() => _updateBorder()}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (isEnterKey(e)) {
                    _updateBorder();
                  }
                }}
                disabled={!settingEnabled('borderWidth', props.settings)}
              />
            </div>

            <div className="border-input-field">
              <h6>Colour</h6>
              <ColorPicker
                color={state.color}
                onChange={(e) => _updateColor(e)}
                disabled={!settingEnabled('borderColor', props.settings)}
                tooltip={'Border Color'}
              />
            </div>
          </div>
        )}
        <div className="border-right-side border-drawing-container">
          {state.borderBlocks.map((block) => {
            return (
              <div
                key={block.position}
                className={`border-drawing ${settingEnabled('borders', props.settings) ? '' : 'disabled'}`}
                style={dashesToCamelCase(calcStyleForBlock(block.position))}
              >
                <div onClick={() => _clickBorder(block.edges[0])} className="topBorderZone" />
                <div onClick={() => _clickBorder(block.edges[1])} className="leftBorderZone" />
                <div onClick={() => _clickBorder(block.edges[2])} className="rightBorderZone" />
                <div onClick={() => _clickBorder(block.edges[3])} className="bottomBorderZone" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="clear" />
    </div>
  );
}

export default TableBorder;
