import CollectionEditProps from '../../../../sidetabs/sub/editComponent/components/CollectionEditProps';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Collection',
  getLabel: () => 'COLL',
  identifyingClasses: ['arc-collection'],
  outerClass: 'arc-collection',
  editPropsComponent: CollectionEditProps,
  isEncapsulatingElement: true,
  outerElement: 'div.arc-collection',
  editor: {
    forced_root_block: ''
  },
  blockOverridesFromParent: true
};
