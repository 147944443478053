import { IProjectActivity } from 'mm-types';
import React from 'react';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const SettingsDetails = ({ entry }: PropsType) => {
  const settingsDom: JSX.Element[] = [];

  if (entry.projectActivityType === 'INDEX_SETTINGS') {
    _.each(entry.indexSettings, (setting, key) => {
      const settingValue = key == 'value' ? setting : setting ? 'On' : 'Off';
      const settingName = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
        return str.toUpperCase();
      });

      settingsDom.push(
        <div key={key}>
          <label>{settingName}:</label> <span>{settingValue}</span>
        </div>
      );
    });
  } else if (entry.projectActivityType === 'PROJECT_SETTINGS') {
    if (entry.projectSettings.description) {
      settingsDom.push(
        <div key="description">
          <label>Description:</label> <span>{entry.projectSettings.description}</span>
        </div>
      );
    }

    if (entry.projectSettings.name) {
      settingsDom.push(
        <div key="name">
          <label>Name:</label> <span>{entry.projectSettings.name}</span>
        </div>
      );
    }

    if (entry.projectSettings.owner) {
      settingsDom.push(
        <div key="owner">
          <label>Document Owner</label> <span>{entry.projectSettings.owner.displayName}</span>
        </div>
      );
    }

    _.each(entry.projectSettings.org, (setting, key) => {
      const settingValue = setting;
      const settingName = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
        return str;
      });

      settingsDom.push(
        <div key={'Org-' + settingName}>
          <label>Org-{settingName}:</label> <span>{settingValue}</span>
        </div>
      );
    });

    _.each(entry.projectSettings.department, (setting, key) => {
      const settingValue = setting;
      const settingName = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
        return str;
      });

      settingsDom.push(
        <div key={'Org-' + settingName}>
          <label>Dep-{settingName}:</label> <span>{settingValue}</span>
        </div>
      );
    });

    _.each(entry.projectSettings.workflow, (setting, key) => {
      const settingValue = setting;
      const settingName = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
        return str;
      });

      settingsDom.push(
        <div key={'Org-' + settingName}>
          <label>Workflow-{settingName}:</label> <span>{settingValue}</span>
        </div>
      );
    });
  } else if (entry.projectActivityType === 'PROJECT_CREATED') {
    if (entry.projectSettings.description) {
      settingsDom.push(
        <div key="description">
          <label>Description:</label> <span>{entry.projectSettings.description}</span>
        </div>
      );
    }

    if (entry.projectSettings.name) {
      settingsDom.push(
        <div key="name">
          <label>Name:</label> <span>{entry.projectSettings.name}</span>
        </div>
      );
    }
  } else if (entry.projectActivityType === 'REVISION_SETTINGS') {
    if (entry.indexRevisionNumber && !entry.isChild) {
      settingsDom.push(
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      );
    }
    _.each(entry.revisionSettings, (setting, key) => {
      const settingValue = key === 'description' || key === 'revision' ? setting : dateUtil(setting).formatDate();
      const settingName = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
        return str.toUpperCase();
      });

      settingsDom.push(
        <div key={key}>
          <label>{settingName}:</label> <span>{settingValue}</span>
        </div>
      );
    });
  }
  return <div className="settings-details">{settingsDom}</div>;
};
