import React, { CSSProperties } from 'react';
import { Table, TableHeader, TableRow, TableHeaderColumn, TableBody, TableRowColumn, CircularProgress } from 'material-ui';
import WorkflowTasksRow from './WorkflowTasksRow';
import { IKPIWorkflowTask } from 'mm-types';

type WorkflowTasksTileProps = {
  style?: CSSProperties;
  loading: boolean;
  workflowTasks: IKPIWorkflowTask[] | null;
  onClickCallback?: (projectUid: string, indexUid: string, workspaceUid: string, external: boolean) => void;
};

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  wrapper: { flex: '1', display: 'flex', flexDirection: 'column' },
  table: { margin: '0' },
  tableHeader: { margin: '0', border: 'none' },
  tableHeaderRow: { border: 'none' },
  tableHeaderColumn: { border: 'none', padding: '0.4rem 0.8rem', height: 'inherit', verticalAlign: 'middle' },
  tableHeaderWrapper: { zIndex: 1 },
  tableBodyWrapper: { marginTop: '-4rem', paddingTop: '4rem', paddingBottom: '5px' },
  tableRowColumnNoResults: { textAlign: 'center' },
  loading: { marginTop: '100px' },
  loadingText: { fontSize: '1.5rem', margin: '10px 0' }
};

const WorkflowTasksTile = ({ style, workflowTasks, onClickCallback, loading }: WorkflowTasksTileProps) => {
  return (
    <div style={style}>
      {loading ? (
        <div style={defaultStyles.loading}>
          <div style={defaultStyles.loadingText}>Loading</div>
          <CircularProgress mode="indeterminate" size={119} />
        </div>
      ) : (
        <Table
          data-qa="workflow-tasks-tile__table"
          style={defaultStyles.table}
          bodyStyle={defaultStyles.tableBodyWrapper}
          headerStyle={defaultStyles.tableHeaderWrapper}
          wrapperStyle={defaultStyles.wrapper}
        >
          <TableHeader style={defaultStyles.tableHeader} adjustForCheckbox={false} displaySelectAll={false}>
            <TableRow style={defaultStyles.tableHeaderRow}>
              <TableHeaderColumn style={{ ...defaultStyles.tableHeaderColumn, width: '45%' }}>Document Title</TableHeaderColumn>
              <TableHeaderColumn style={{ ...defaultStyles.tableHeaderColumn, width: '15%' }}>Workflow Due Date</TableHeaderColumn>
              <TableHeaderColumn style={{ ...defaultStyles.tableHeaderColumn, width: '20%' }}>Reference</TableHeaderColumn>
              <TableHeaderColumn style={{ ...defaultStyles.tableHeaderColumn, width: '20%' }}>Workflow Stage</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {workflowTasks?.length ? (
              workflowTasks.map((workflowTaskProps, index) => (
                <WorkflowTasksRow key={index} {...workflowTaskProps} onClickCallback={onClickCallback} />
              ))
            ) : (
              <TableRow key="no-results-row">
                <TableRowColumn style={{ ...defaultStyles.tableRowColumn, ...defaultStyles.tableRowColumnNoResults }} colSpan={4}>
                  No documents found
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default WorkflowTasksTile;
