import { IDataAttribute, IUnit } from 'mm-types';
import { DataAttributesValues } from '../../../../../utils/DataAttributesUtil';

export type DataAttributeType =
  | 'boolean'
  | 'enumAsToggle'
  | 'enum'
  | 'enumAsDropdown'
  | 'enumAsToggleWithDropdown'
  | 'number'
  | 'checklist'
  | string;

export type DataAttributePropsType = {
  dataAttribute: IDataAttribute;
  dataAttributesValues: DataAttributesValues;
  isRequired: (dataAttribute: IDataAttribute) => boolean | undefined;
  handleAction: (dataAttribute: IDataAttribute, newValue: string, isValid?: boolean) => void;
  units?: IUnit[];
};

export type MenuItemPropsType = {
  style?: React.CSSProperties;
  innerDivStyle?: React.CSSProperties;
};

export type EnumMenuItemOptionType = { key: string; value: string; primaryText: string; disabled?: boolean; tooltip?: string };

export const BASE_STYLE: React.CSSProperties = { width: '25px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };

export const ENUM_DROPDOWN_MENU_ITEM_PROPS: MenuItemPropsType = {
  style: { ...BASE_STYLE, width: '100%' },
  innerDivStyle: { ...BASE_STYLE, paddingLeft: '5px', paddingRight: '5px', paddingTop: '3px' }
};

export type DataAttributeValidationErrors = {
  [key: string]: IDataAttribute[];
};
