import ProjectDefinitionStore from '../flux/common/ProjectDefinitionStore';

const expectedChildrenPrefix = 'Expected children order: ';

const formatGroups = (text: string, closestTocableUid: string, isUnit = true, level = 0): string => {
  let openBracketCounter = 0;
  let firstOpenBracketIndex = -1,
    lastCloseBracketIndex = -1,
    howManyGroups = 0;
  let lastMultiplierAdjust = 0;

  // Take out prefix for the root level
  if (level === 0 && text?.startsWith(expectedChildrenPrefix)) {
    text = text.substring(expectedChildrenPrefix.length).trim();
  }
  // return unfromatted text if text doesn't start with a prefix for the root level
  else if (level === 0 && !text?.startsWith(expectedChildrenPrefix)) {
    return text;
  }

  // add () around if not there for the root level
  if (level === 0 && !text.startsWith('(')) {
    text = '(' + text + ')';
  }

  let formattedGroups = '';

  for (let currentIndex = 0; currentIndex < text.length; currentIndex++) {
    if (text.charAt(currentIndex) === '(') {
      openBracketCounter++;
      howManyGroups++;
      if (openBracketCounter === 1) {
        firstOpenBracketIndex = currentIndex;
      }
    } else if (text.charAt(currentIndex) === ')') {
      openBracketCounter--;
      if (openBracketCounter === 0) {
        // a group has closed, deal with the contents of it
        lastCloseBracketIndex = currentIndex;

        let multiplierAdjust = 2;
        let hasMultiplier = true;
        if (text.charAt(lastCloseBracketIndex + 1) === '(') {
          multiplierAdjust = 1;
          hasMultiplier = false;
        } else {
          // there's a multiplier char, move on to the next index
          currentIndex++;
        }

        lastMultiplierAdjust = multiplierAdjust;

        const groupText = text.substring(firstOpenBracketIndex, lastCloseBracketIndex + multiplierAdjust);

        if (howManyGroups > 1) {
          // format groups inside
          formattedGroups =
            formattedGroups +
            (formattedGroups.length === 0 && '<br/>') +
            '(' +
            formatGroups(groupText.substring(1, groupText.length - multiplierAdjust), closestTocableUid, isUnit, level + 1) + // remove brackets and multiplier which is last
            '<br/>)';
        } else {
          // lookup ids in the group and replace them with a displayName
          const stringIds = getStringIds(groupText, multiplierAdjust, isUnit, closestTocableUid);
          const multiplier = hasMultiplier ? getMultiplier(groupText) : '';

          // add 2 spaces to each level a group is at (to distinguish visually nested groups)
          formattedGroups =
            formattedGroups +
            (formattedGroups.length > 0 ? '<br/>' + '&nbsp&nbsp&nbsp&nbsp'.repeat(level) : '') +
            '( ' +
            stringIds +
            ' ) ' +
            multiplier;
        }

        howManyGroups = 0;
      }
    }
  }

  // handling base case
  if (lastCloseBracketIndex < text.length - 1 && openBracketCounter == 0) {
    const residueString = text.substring(lastCloseBracketIndex + lastMultiplierAdjust - 1);
    formattedGroups =
      formattedGroups + '<br/>&nbsp&nbsp&nbsp&nbsp'.repeat(level) + getStringIds(residueString, 0, isUnit, closestTocableUid);
  }

  formattedGroups = (level == 1 ? '<br/>&nbsp&nbsp&nbsp&nbsp' : '') + formattedGroups;

  return formattedGroups;
};

const getStringIds = (groupText: string, multiplierAdjust: number, isUnit: boolean, closestTocableUid: string) => {
  const ids = groupText.substring(1, groupText.length - multiplierAdjust).split(','); // remove brackets and multiplier which is last then split it
  return ids.reduce((acc, id, index) => {
    id = id.trim();
    if (id === '') {
      return acc;
    }

    let isOR = false;
    if (id.startsWith('|')) {
      id = id.substring(1, id.length);
      isOR = true;
    }

    if (id.endsWith('\\d')) {
      id = id.substring(0, id.length - 2);
    }

    let displayName = id;
    // 3 hardcoded ids/definitions which are a numbered ones
    if (id === 'procItem') {
      displayName = 'Proc Item';
    } else if (id === 'perfItem') {
      displayName = 'Perf Item';
    } else if (id === 'descItem') {
      displayName = 'Desc Item';
    } else {
      const definition = isUnit
        ? ProjectDefinitionStore.getUnitDefinition(closestTocableUid, id)
        : ProjectDefinitionStore.getElementDefinition(closestTocableUid, id);
      displayName = definition?.displayName ?? displayName;
    }

    // wrap the displayName into a span to visually distinguish
    displayName = '<span class="invalid-unit-dtd-item">' + displayName + '</span>';
    // re introduce OR statement
    displayName = (isOR ? 'OR ' : '') + displayName;
    // re introduce spacing between ids/names
    displayName = (index > 0 ? ' ' : '') + displayName;

    return acc + displayName;
  }, '');
};

const getMultiplier = (groupText: string) => {
  // replace multiplier with more readable representation
  const multiplier = groupText.substring(groupText.length - 1, groupText.length);
  switch (multiplier) {
    case '*':
      return '[0 .. *]';
    case '+':
      return '[1 .. *]';
    case '?':
      return '[0 .. 1]';
    default:
      return '';
  }
};

const getReasonTextAbbreviated = (reasonText: string, maxChars = 30) => {
  if (reasonText.startsWith(expectedChildrenPrefix)) {
    return expectedChildrenPrefix;
  } else {
    reasonText.substring(0, maxChars);
  }
};

const defaultValidationError = 'Unknown validation error';

export { formatGroups, getReasonTextAbbreviated, defaultValidationError };
