import * as React from 'react';
import NotificationsStore from '../../../flux/events/NotificationsStore';
import EditorStore from '../../../flux/editor/EditorStore';
import { PostUnitRenderActionType } from '../../../flux/editor/PostUnitRenderActions';
import NotificationTemplates from './NotificationTemplates';
import { transitionTo } from '../../../utils/Navigation';
import userUtil from '../../documents/team/util/user';
import { FlatButton } from 'material-ui';
import { INotification, NotifAction } from 'mm-types';
import fileDownloadUtil from '../../../utils/fileDownloadUtil';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';
import config from '../../../utils/config';
import PdfPublication from './notificationListItems/PdfPublication';
import AerosyncPublicationPreview from './notificationListItems/AerosyncPublicationPreview';
import ExtAerosyncPublicationPreview from './notificationListItems/ExtAerosyncPublicationPreview';
import ComplianceReport from './notificationListItems/ComplianceReport';
import ProjectImport from './notificationListItems/ProjectImport';
import ProjectCopy from './notificationListItems/ProjectCopy';
import ExtLinkDeleted from './notificationListItems/ExtLinkDeleted';
import TocCopy from './notificationListItems/TocCopy';
import CreateInterim from './notificationListItems/CreateInterim';
import WorkflowReview from './notificationListItems/WorkflowReview';

export type Props = {
  notifications: INotification[];
  onRequestMoreNotifs: () => void;
  postNotifActionCallback?: () => void;
  notificationInfo?: { latestResponseLength: number };
  isTile?: boolean;
  isOpen?: boolean;
};

export type State = {};

const ALL_EXPORT_TYPES = [
  'PDF_EXPORT',
  'PDF_PUBLICATION',
  'PROJECT_EXPORT',
  'FEEDBACK_EXPORT',
  'READCONFIRM_EXPORT',
  'WORKFLOW_EXPORT',
  'QUIZ_EXPORT',
  'DOCUMENT_EXPORT',
  'WORKFLOW_ACTIONS_EXPORT'
];
export default class NotificationList extends React.Component<Props, State> {
  private _refreshing: boolean;
  private notifListRef: React.RefObject<HTMLUListElement>;

  constructor(props: Props) {
    super(props);
    this._refreshing = false;
    this.notifListRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      this.scrollTop();
    }
  }
  scrollTop(offset = 0) {
    if (this.notifListRef.current) {
      this.notifListRef.current.scrollTop = offset;
    }
  }

  _getMyAvatarUrl() {
    return (
      <div className="left-side">
        <img className="popup-avatar" src={userUtil.imagePath(ActiveUserStore.getUser()!)} />
      </div>
    );
  }

  _gotoDeletedLink(notif) {
    transitionTo('editor-edit', {
      projectUid: notif.link.projectUid,
      indexUid: notif.link.indexUid,
      targetUnitUid: notif.link.unitUid
    });
  }

  _generateNotifTemplate(notif) {
    const notifClientProps = NotificationTemplates.templates[notif.type];
    let linkHtml = (
      <i
        className="material-icons"
        title="Link to document"
        onClick={() => {
          this._handleNotifAction(notif, 'link');
        }}
      >
        link
      </i>
    );

    if (
      notif.type === 'DELETED_PROJECT_MEMBERSHIP' ||
      ((notif.type === 'UPDATED_PROJECT_MEMBERSHIP' || notif.type === 'NEW_PROJECT_MEMBERSHIP') && notif.project.isExternal)
    ) {
      linkHtml = <div></div>;
    }
    if (notifClientProps) {
      const staleClassName = notif.media && notif.media.stale ? 'arc-media-stale notif-item-stale' : '';
      return (
        <div
          className={`notif-item-container ${notifClientProps.className || 'notif-item-newprojmember'} ${staleClassName}`}
          key={notif.type}
        >
          {notifClientProps.getNotifIcon ? notifClientProps.getNotifIcon(notif) : this._getMyAvatarUrl()}

          {notifClientProps.generateDescription(notif)}

          {notifClientProps.generateRightSide ? (
            notifClientProps.generateRightSide(notif, this._handleNotifAction.bind(this))
          ) : (
            <div className="right-side">
              <div className="popup-date-container">
                <h5>{notif.receivedFormatAgo}</h5>
              </div>
              <div className="notif-item notif-item-done">
                <span
                  className="notif-status-toggle"
                  title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
                  onClick={() => {
                    this._handleNotifAction(notif, 'toggleReadUnread');
                  }}
                />
                {linkHtml}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  _handleNotifAction(notif: INotification, action: NotifAction, disableCallback = false, callback?: () => void) {
    this._handleAction(notif, action, () => {
      this.props.postNotifActionCallback && !disableCallback && this.props.postNotifActionCallback();
      if (callback) {
        callback();
      }
    });
  }

  documentPath(notif) {
    let query = '';
    if (typeof notif.manualUid !== 'undefined') {
      query = query + 'documents/' + notif.manualUid;
      if (typeof notif.index.revisionName !== 'undefined') {
        query = query + '?revision=' + notif.index.revisionName;
      }
    }
    return query;
  }

  _handleAction(notif: INotification, action: NotifAction, callback) {
    const notifClientProps = NotificationTemplates.templates[notif.type];

    const actionHandler = () => {
      if (action === 'download') {
        if (notif.status === 'UNREAD') {
          NotificationsStore.setAsRead([notif]);
        }

        if (ALL_EXPORT_TYPES.indexOf(notif.type) !== -1) {
          if (callback) {
            callback();
          }

          let location: string = notif.media!.location;
          if (location.indexOf('?') === -1) {
            location += '?attachment=true';
          } else {
            location += '&attachment=true';
          }

          fileDownloadUtil.withDefaultNotification(location);
        }
      } else if (action === 'link') {
        if (callback) {
          callback();
        }

        if (notif.status === 'UNREAD') {
          NotificationsStore.setAsRead([notif]);
        }

        if (
          notif.type === 'AEROSYNC_PUBLICATION' ||
          notif.type === 'AEROSYNC_PREVIEW' ||
          notif.type === 'EXT_AEROSYNC_PUBLICATION' ||
          notif.type === 'EXT_AEROSYNC_PREVIEW'
        ) {
          window.open(config.readerUrl + this.documentPath(notif), '_blank');
        } else if (notif.type === 'WHATS_NEW_DOC_UPDATE') {
          transitionTo('editor-edit', {
            projectUid: notif.project!.uid,
            indexUid: notif.index.uid
          });
        } else if (notif.type === 'WORKFLOW_EXT_STAGE_TRANSITION') {
          transitionTo('/teamspaces/' + notif.workspaceUid + '/' + notif.project!.uid);
        } else if (notif.type === 'PROJECT_IMPORT' || notif.type === 'PROJECT_COPY') {
          if (notif.isSnapshot) {
            transitionTo('editor-edit', {
              projectUid: notif.project!.uid,
              indexUid: notif.index.uid
            });
          } else {
            transitionTo('doc-general-settings', {
              projectUid: notif.project!.uid,
              indexUid: notif.index.uid
            });
          }
        } else if (notifClientProps && notifClientProps.superType === 'comment') {
          transitionTo(
            'editor-edit',
            {
              projectUid: notif.project!.uid,
              indexUid: notif.index.uid,
              targetUnitUid: notif.comment.unitUid
            },
            { action: 'OPEN_COMMENT' as PostUnitRenderActionType, commentUid: notif.comment.uid }
          );
        } else if (notifClientProps && notifClientProps.superType === 'share') {
          transitionTo(
            'editor-edit',
            {
              projectUid: notif.project!.uid,
              indexUid: notif.index.uid,
              targetUnitUid: notif.unitUid
            },
            { action: 'OPEN_SHARE' as PostUnitRenderActionType }
          );
        } else if (notifClientProps && notifClientProps.superType === 'task') {
          transitionTo(
            'editor-edit',
            {
              projectUid: notif.project!.uid,
              indexUid: notif.index.uid
            },
            { action: 'OPEN_CHANGETASKS' as PostUnitRenderActionType }
          );
        } else if (notif.type === 'TOC_COPY') {
          transitionTo('editor-edit', {
            projectUid: notif.project!.uid,
            indexUid: notif.index.uid,
            tocableUnitUid: notif.copiedTocableUid,
            targetUnitUid: notif.copiedTocableUid
          });
        } else if (notif.type === 'CREATE_INTERIM') {
          transitionTo('editor-edit', {
            projectUid: notif.project!.uid,
            indexUid: notif.index!.uid
          });
        } else {
          transitionTo('editor-edit', {
            projectUid: notif.project!.uid,
            indexUid: notif.index ? notif.index.uid : notif.link!.indexUid
          });
        }
      } else if (action === 'retry') {
        NotificationsStore.retryNotif(notif.uid).then(() => {
          callback();
        });
      } else if (action === 'toggleReadUnread') {
        if (notif.isNew) {
          notif.isNew = false;
        }
        NotificationsStore.setNotifState({
          uid: notif.uid,
          isNew: notif.isNew,
          status: notif.status === 'UNREAD' ? 'READ' : 'UNREAD'
        });
      }
    };

    EditorStore.blurEditor(() => {
      NotificationsStore.clearNew().then(() => {
        actionHandler();
      });
    });
  }

  _requestMoreNotifs() {
    this.props.onRequestMoreNotifs();
  }

  _getVariantName(notif) {
    return NotificationTemplates.getVariantName(notif);
  }

  _getName(notif) {
    return NotificationTemplates.generateTitle(notif);
  }

  _setRefresh(e: boolean) {
    this._refreshing = e;
  }

  render() {
    return (
      <ul ref={this.notifListRef} className="notification-list">
        {this.props.notifications.length > 0 &&
          (this.props.isOpen || this.props.isTile) &&
          this.props.notifications.map((notif, notifIndex) => {
            return (
              <li
                key={notifIndex}
                className={
                  'notif-status-' + notif.status + (notif.isNew ? ' new-notif' : '') + (notif.isError ? ' notif-item-iserror' : '')
                }
              >
                {this._generateNotifTemplate(notif)}

                {notif.type === 'PDF_PUBLICATION' && (
                  <PdfPublication
                    notif={notif}
                    getName={this._getName}
                    getVariantName={this._getVariantName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                    refreshing={this._refreshing}
                    setRefreshing={(e) => this._setRefresh(e)}
                  />
                )}

                {(notif.type === 'AEROSYNC_PUBLICATION' || notif.type === 'AEROSYNC_PREVIEW') && (
                  <AerosyncPublicationPreview
                    notif={notif}
                    getName={this._getName}
                    getVariantName={this._getVariantName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                    refreshing={this._refreshing}
                    setRefreshing={(e) => this._setRefresh(e)}
                  />
                )}

                {(notif.type === 'EXT_AEROSYNC_PUBLICATION' || notif.type === 'EXT_AEROSYNC_PREVIEW') && (
                  <ExtAerosyncPublicationPreview
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                    refreshing={this._refreshing}
                    setRefreshing={(e) => this._setRefresh(e)}
                  />
                )}

                {notif.type === 'COMPLIANCE_REPORT' && (
                  <ComplianceReport
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                  />
                )}

                {notif.type === 'PROJECT_IMPORT' && (
                  <ProjectImport
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                  />
                )}

                {notif.type === 'PROJECT_COPY' && (
                  <ProjectCopy
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                  />
                )}

                {notif.type === 'EXT_LINK_DELETED' && (
                  <ExtLinkDeleted notif={notif} goToDeletedLink={(notif) => this._gotoDeletedLink(notif)} />
                )}

                {notif.type === 'TOC_COPY' && (
                  <TocCopy
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                  />
                )}

                {notif.type === 'CREATE_INTERIM' && (
                  <CreateInterim
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action, disableCallback, callback) =>
                      this._handleNotifAction(notif, action, disableCallback, callback)
                    }
                  />
                )}

                {notif.type === 'WORKFLOW_REVIEW' && (
                  <WorkflowReview
                    notif={notif}
                    getName={this._getName}
                    handleNotifAction={(notif, action) => this._handleNotifAction(notif, action)}
                  />
                )}
                <div className="seperator" />
              </li>
            );
          })}

        {this.props.notificationInfo && this.props.notificationInfo.latestResponseLength === 9 && (
          <li key="more" className="notif-more">
            <FlatButton
              label="MORE"
              secondary={true}
              onClick={(e) => {
                this._requestMoreNotifs();
              }}
            />
          </li>
        )}

        {this.props.notifications.length === 0 && (
          <li>
            <div className="popup-empty-container">
              <h5>No Notifications</h5>
            </div>
          </li>
        )}
      </ul>
    );
  }
}
