import * as React from 'react';

export const KeyCode = {
  enter: 13,
  a: 65,
  s: 83,
  esc: 27,
  backspace: 8,
  del: 46,
  space: 32,
  tab: 9,
  u: 85,
  i: 73,
  b: 66,
  c: 67,
  v: 86,
  x: 88,
  z: 90,
  k: 75,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
  arrowLeft: 37,
  slash: 220
};

export const nukePropagation = function (e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  e.arconicsEventStopped = true;
};

// Note: e.key introduced because e.keyCode is deprecated but we still have to support it. More reading:
// https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values

type KeyboardEvent = React.KeyboardEvent;
export type IsKeyFn<T = KeyboardEvent> = (e: T) => boolean;

const isMac = () => navigator.platform.match('Mac');

export const isKeyByName = (e: KeyboardEvent, keyName): boolean => keyName.toLowerCase() === e.key.toLowerCase();
export const isKey = (e: KeyboardEvent, keyCode: number, keyName?: string): boolean =>
  e.keyCode === keyCode || (keyName ? isKeyByName(e, keyName) : false);

export const isCommandCtrlKey: IsKeyFn<{ metaKey: boolean; ctrlKey: boolean }> = (e) => (isMac() ? e.metaKey : e.ctrlKey);
export const isShiftKey: IsKeyFn<{ shiftKey: boolean }> = (e) => e.shiftKey;
export const isAltKey: IsKeyFn<{ altKey: boolean }> = (e) => e.altKey;
export const isModifierKey: IsKeyFn = (e) => isCommandCtrlKey(e) || isAltKey(e) || isShiftKey(e);

export const isDeleteKey: IsKeyFn = (e) => isKey(e, KeyCode.del);
export const isBackspaceKey: IsKeyFn = (e) => isKey(e, KeyCode.backspace);
export const isDeleteKeys: IsKeyFn = (e) => isDeleteKey(e) || isBackspaceKey(e);
export const isEscKey: IsKeyFn = (e) => isKey(e, KeyCode.esc);
export const isSaveKeys: IsKeyFn = (e) => isKey(e, KeyCode.s) && isCommandCtrlKey(e);
export const isTabKey: IsKeyFn = (e) => isKey(e, KeyCode.tab, 'Tab');
export const isNoShiftTabKey: IsKeyFn = (e) => isTabKey(e) && !isShiftKey(e);
export const isShiftTabKeys: IsKeyFn = (e) => isTabKey(e) && isShiftKey(e);
export const isShiftBackspaceKeys: IsKeyFn = (e) => isBackspaceKey(e) && isShiftKey(e);
export const isEnterKey: IsKeyFn = (e) => isKey(e, KeyCode.enter);
export const isShiftEnterKeys: IsKeyFn = (e) => isEnterKey(e) && isShiftKey(e);
export const isShiftDeleteKeys: IsKeyFn = (e) => isDeleteKey(e) && isShiftKey(e);
export const isTextFormattingKeys: IsKeyFn = (e) =>
  isCommandCtrlKey(e) && (isKey(e, KeyCode.b, 'b') || isKey(e, KeyCode.u, 'u') || isKey(e, KeyCode.i, 'i'));

export const isReplaceKey: IsKeyFn = (e) =>
  (!!e.key && e.key.toLowerCase().length === 1 && !isCommandCtrlKey(e)) || isDeleteKeys(e) || isPasteKeys(e);
export const isSingleKey: IsKeyFn = (e) => !!e.key && e.key.toLowerCase().length === 1 && !isModifierKey(e);
export const isComboKey: IsKeyFn = (e) => !!e.key && e.key.toLowerCase().length === 1 && isModifierKey(e);
export const isSelectAllKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isKey(e, KeyCode.a, 'a');
export const isCopyKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isKey(e, KeyCode.c, 'c');
export const isCutKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isKey(e, KeyCode.x, 'x');
export const isPasteKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isKey(e, KeyCode.v, 'v');
export const isMergeKeys: IsKeyFn = (e) => isShiftKey(e) && isKey(e, KeyCode.slash);
export const isUndoKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isKey(e, KeyCode.z);
export const isRedoKeys: IsKeyFn = (e) => isCommandCtrlKey(e) && isShiftKey(e) && isKey(e, KeyCode.z);
export const isShiftSelect: IsKeyFn = (e) => isShiftKey(e) && isArrowKey(e);
export const isSpaceKey: IsKeyFn = (e) => isKey(e, KeyCode.space, ' ');

// Internet Explorer, Edge (16 and earlier), and Firefox (36 and earlier) use "Left", "Right", "Up", and "Down" instead of "ArrowLeft", "ArrowRight", "ArrowUp", and "ArrowDown".
export const isUpArrowKey: IsKeyFn = (e) => isKey(e, KeyCode.arrowUp, 'ArrowUp') || isKeyByName(e, 'Up');
export const isRightArrowKey: IsKeyFn = (e) => isKey(e, KeyCode.arrowRight, 'ArrowRight') || isKeyByName(e, 'Right');
export const isDownArrowKey: IsKeyFn = (e) => isKey(e, KeyCode.arrowDown, 'ArrowDown') || isKeyByName(e, 'Down');
export const isLeftArrowKey: IsKeyFn = (e) => isKey(e, KeyCode.arrowLeft, 'ArrowLeft') || isKeyByName(e, 'Left');
export const isArrowKey: IsKeyFn = (e) => isUpArrowKey(e) || isRightArrowKey(e) || isDownArrowKey(e) || isLeftArrowKey(e);

export const isLetterKey = (letter: number, keyName?: string): IsKeyFn => {
  return (e) => {
    return isKey(e, letter, keyName);
  };
};

export const combineKeys = (...keyFn: IsKeyFn[]): IsKeyFn => {
  return (e) => {
    let result = true;
    keyFn.forEach((fn) => {
      if (result) {
        result = fn(e);
      }
    }, true);
    return result;
  };
};

export const isAlphaNumericKey = (e: KeyboardEvent): boolean => {
  return /^[a-zA-Z0-9 ]{1}$/.test(e.key);
};

const macShortcuts = {
  bold: 'meta+b',
  italic: 'meta+i',
  underline: 'meta+u'
};

const pcShortcuts = {
  bold: 'ctrl+b',
  italic: 'ctrl+i',
  underline: 'ctrl+u'
};

export const shortcutMap = isMac() ? macShortcuts : pcShortcuts;
