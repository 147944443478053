import { IProjectActivity } from 'mm-types';
import React from 'react';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const WorkflowReview = ({ entry }: PropsType) => {
  const workflowReviewDom: JSX.Element[] = [];
  if (entry.childrenActivities && entry.indexRevisionNumber) {
    workflowReviewDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  } else {
    if (entry.indexRevisionNumber && !entry.isChild) {
      workflowReviewDom.push(
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      );
    }
    if (entry.assignment) {
      if (entry.user) {
        workflowReviewDom.push(
          <div key="user">
            <label>User:</label> <span>{entry.user.displayName}</span>
          </div>
        );
      }

      if (entry.assignment) {
        if (entry.assignment.stageTitle) {
          workflowReviewDom.push(
            <div key="stage">
              <label>Stage:</label> <span>{entry.assignment.stageTitle}</span>
            </div>
          );
        }
        if (entry.assignment.userDepartment) {
          workflowReviewDom.push(
            <div key="department">
              <label>Department:</label> <span>{entry.assignment.userDepartment}</span>
            </div>
          );
        }
        if (entry.assignment.userOrganisation) {
          workflowReviewDom.push(
            <div key="org">
              <label>Organization:</label> <span>{entry.assignment.userOrganisation}</span>
            </div>
          );
        }
        if (entry.assignment.userStaffRole) {
          workflowReviewDom.push(
            <div key="role">
              <label>Staff Role:</label> <span>{entry.assignment.userStaffRole}</span>
            </div>
          );
        }
        if (entry.action) {
          workflowReviewDom.push(
            <div key="action">
              <label>Action:</label> <span>{entry.action}</span>
            </div>
          );
        }
        if (entry.assignment.comment) {
          workflowReviewDom.push(
            <div key="comment">
              <label>Comment:</label> <span>{entry.assignment.comment}</span>
            </div>
          );
        }
        if (entry.assignment.completionDate) {
          workflowReviewDom.push(
            <div key="completion">
              <label>Completion Date:</label>
              <span>{dateUtil(entry.assignment.completionDate).formatDateTimeNoSecs()}</span>
            </div>
          );
        }
      }
    }
  }

  return <div className="settings-details">{workflowReviewDom}</div>;
};
