import * as React from 'react';
import { SvgIcon, SvgIconProps } from 'material-ui';

const ReadConfirmGroupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width={24} height={24} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M16.59,7.58 L10,14.17 L7.41,11.59 L6,13 L10,17 L18,9 L16.59,7.58 Z"
            fill="#737373"
            fillRule="nonzero"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default ReadConfirmGroupIcon;
