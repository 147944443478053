import { IProjectActivity } from 'mm-types';
import * as React from 'react';
type PropsType = {
  entry: IProjectActivity;
};

type FsnType = { oldFsn?: string; newFsn?: string };

export const AATProjectActivity = ({ entry }: PropsType) => {
  if (!entry?.aat) {
    return null;
  }

  const airNumber = entry?.aat.oldAircraftNumber
    ? entry.aat?.oldAircraftNumber + ' -> ' + entry.aat?.aircraftNumber
    : entry.aat?.aircraftNumber || '-';

  const airModel = entry.aat.oldAircraftModel
    ? entry.aat?.oldAircraftModel + ' -> ' + entry.aat?.aircraftModel
    : entry.aat?.aircraftModel || '-';

  const fsn: FsnType = {};
  if (entry.aat?.fsn || (entry.aat?.oldFsn && entry.aat?.operation === 'EDITED')) {
    fsn.oldFsn = entry.aat.oldFsn ? (entry.aat.oldFsn[0].fleetSerialNumber || '-') + ' ' + (entry.aat.oldFsn[0].customerCode || '-') : '-';
    fsn.newFsn = entry.aat.fsn ? (entry.aat.fsn[0].fleetSerialNumber || '=') + ' ' + (entry.aat.fsn[0].customerCode || '-') : '-';
  }
  return (
    <div className="settings-details">
      <div key="operation">
        <label>Operation:</label>
        <span> {entry.aat.operation}</span>
      </div>

      <div key="msn">
        <label>MSN:</label>
        <span> {entry.aat.msn}</span>
      </div>

      <div key="aircraft-number">
        <label>Aircraft Number:</label>
        <span>{airNumber}</span>
      </div>

      <div key="aircraft-model">
        <label>Aircraft Model:</label>
        <span>{airModel}</span>
      </div>

      {!!fsn?.oldFsn && !!fsn?.newFsn && (
        <div key="fsn">
          <label>Fleet Serial Number:</label>
          <span>{fsn.oldFsn + ' -> ' + fsn.newFsn}</span>
        </div>
      )}
    </div>
  );
};
