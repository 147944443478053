import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const TaskReminder = ({ entry }: PropsType) => {
  const taskReminderDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    taskReminderDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  if (entry.task.reason?.title) {
    const reason = entry.task.reason.title.replace(/_|-/g, ' ');
    taskReminderDom.push(
      <div key="reason">
        <label>Reason:</label>
        <span>{reason.charAt(0).toUpperCase() + reason.slice(1).toLowerCase()}</span>
      </div>
    );
  }
  return <div className="settings-details">{taskReminderDom}</div>;
};
