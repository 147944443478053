import * as _ from 'lodash';
import { ProjectDefinitionStore } from '../../../../flux/common/ProjectDefinitionStore';
import { IDefinition, IDocUnitProfile, IElementDefinition, IUnitDefinition, IUnitDetails, TinyAction } from 'mm-types';
import { UnitTypes } from '../units/UnitTypes';
import { CustomEditor } from './EditorInstanceManager';
import MetaTags from '../units/MetaTags';
import { isReplaceKey } from '../keyIdentifier';
import { EditorManager } from 'tinymce';
import SelectionUtil from './SelectionUtil';
import { DomAssertions } from './DomAssertionsUtil';
import { generateDataNid } from '../../../../utils/UUIDUtil';
import EditorStore from '../../../../flux/editor/EditorStore';
import { isInlineMarkupAction } from '../units/unit/inlineElementsLookup';
import { ElementDetails } from '../units/ElementDetails';
import isTextNode = DomAssertions.isTextNode;
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;

declare const tinymce: EditorManager;

export const getFamily = ($element: JQuery<HTMLElement>): UnitTypes => {
  return ($element.attr('data-element-family') as UnitTypes) || ($element.attr('data-unit-family') as UnitTypes);
};
export const getSubtype = ($element: JQuery<HTMLElement>): string => {
  return $element.attr('data-subtype') || '';
};
export const getListType = ($element: JQuery<HTMLElement>): 'unordered-list' | 'ordered-list' | '' => {
  return ($element.attr('data-list-type') as 'unordered-list' | 'ordered-list') || '';
};
export const getDefinitionId = ($element: JQuery<HTMLElement>): string => {
  return $element.attr('data-element-definition-id') || '';
};
export const getHasIndexMetadata = ($element: JQuery<HTMLElement>): boolean => {
  return !!$element.find('.arc-checklist-content').length;
};
export const getLevel = ($element: JQuery<HTMLElement>): string => {
  return $element.attr('data-ordinal-level') || '';
};

// Given a DOM element, extract the family, subtype and list-type properties, if they exist.
export const readUnitTypeFromDOM = (element: HTMLElement) => {
  const $element = $(element);
  return {
    family: getFamily($element),
    subtype: getSubtype($element),
    listType: getListType($element),
    definitionId: getDefinitionId($element),
    hasIndexMetadata: getHasIndexMetadata($element),
    level: getLevel($element)
  };
};

function isElementDefinition(definition: IUnitDefinition | IElementDefinition): definition is IElementDefinition {
  return !!(<IElementDefinition>definition).expectedParents;
}

export const elementOrParentIdFromDefinition = (element: HTMLElement, definition: IUnitDefinition | IElementDefinition): string => {
  if (!definition) {
    return '';
  }
  if (isElementDefinition(definition) && definition.expectedParents.length) {
    const familyEl = element.closest('[data-element-family],[data-unit-family]');
    return definition.expectedParents.length > 0 && !familyEl?.isSameNode(element) ? definition.expectedParents[0] : definition.type;
  } else {
    return definition.id;
  }
};

export const getParentElementDefinitions = (el: Element): string[] => {
  const definitions: string[] = [];
  let currentEl: Element | null = el;

  while (currentEl && !currentEl.classList.contains('arc-unit')) {
    const elDef = currentEl.getAttribute('data-element-definition-id');
    if (elDef) {
      definitions.push(elDef);
    }
    currentEl = currentEl.parentElement;
  }
  return definitions;
};

export const getUnitElement = (el: HTMLElement, unitOrElementDefinition: IUnitDefinition | IElementDefinition) => {
  const $el = $(el);
  const actualType = elementOrParentIdFromDefinition(el, unitOrElementDefinition);
  let unitElement = $el.find(`[data-element-definition-id='${actualType}']`)[0];

  if (!unitElement && isElementDefinition(unitOrElementDefinition)) {
    // in some cases expected parent for given element is not at position 0 in unitOrElementDefinition.expectedParents i.g. ConfirmAction (we get that from elementOrParentIdFromDefinition())
    const parentDefinitions = getParentElementDefinitions(el);

    if (parentDefinitions && parentDefinitions.length) {
      const elementType = unitOrElementDefinition.type || unitOrElementDefinition.id;
      const parentType = parentDefinitions[0] === elementType ? parentDefinitions[1] : parentDefinitions[0];

      if (unitOrElementDefinition.expectedParents.indexOf(parentType) !== -1) {
        unitElement = $el.closest(`[data-element-definition-id='${parentType}']`)[0];
      }
    }
  }

  if (!unitElement) {
    unitElement = $el.closest(`[data-element-definition-id='${actualType}']`)[0];
  }

  return unitElement
    ? unitElement
    : $el.closest(
        `[data-element-definition-id='${unitOrElementDefinition.id}'],[data-element-definition-id*='${unitOrElementDefinition.id}']`
      )[0];
};

export const stringToElement = (content: string) => {
  // construct Node from content html string
  const template = document.createElement('template')!;
  template.innerHTML = content;
  return template.content.firstElementChild!;
};

/**
 * A DOM element has focus.
 * That element can be part of:
 *  Either a project-defined Unit
 *  Or a project-defined Element
 *  Or neither of these, for example if the element is a structural part of a Paragraph unit, but not the actual Paragraph container.
 *
 * Find and return
 * @param element A HTMLElement which is not a tiny-specific bogus element.
 * @param projectDefinitionStore reference to ProjectDefinitionStore
 * @param getElementDetails there can be differences in element profile vs unit profile, so this decides whether we want to get element or unit profile. Currently this is only referenced when we are getting the focused element profile of nested tree/dom, more scenarios may need to added
 * @returns { profile: a DocEditProfile matching this element, type: the project-defined type of this element, subtype: project-defined subtype targetElement: reference to the DOMELement itself, unitElement: reference to the unit level div.edit-target which contains this element}
 */
export const htmlElementToDocEditProfile = (
  element: HTMLElement,
  projectDefinitionStore: ProjectDefinitionStore,
  getElementDetails = false
): IUnitDetails => {
  const unitTypeDetails = readUnitTypeFromDOM(element);
  const { subtype, listType, definitionId, level, hasIndexMetadata } = unitTypeDetails;
  const dup = projectDefinitionStore.projectDefinitionDocUnitEditProfiles();
  let { family } = unitTypeDetails;
  let profile: IDocUnitProfile | null = null;
  let definition: IDefinition | undefined | null = null;
  let unitElement = element;
  let targetElement = element;

  if (element.nodeName === 'LI' && !isEditableListItemElement(element)) {
    family = 'list-item';
    profile = dup.getUnitProfileByTypes(family, '');
    definition = dup.getElementProfileByDefinitionId('ListItem');
    unitElement = $(element).closest(`[data-element-family='list']`)[0];
  } else if (listType) {
    family = listType;
    profile = dup.getUnitProfileByTypes(listType, '');
    definition = dup.getElementProfileByDefinitionId(definitionId ?? family);
  } else {
    const unitDefFamily = projectDefinitionStore.getUnitDefinitionsByType(family, subtype);
    let unitDef = level ? unitDefFamily.filter((tocable) => tocable.level === level).shift() : unitDefFamily.shift();
    const elementDef = definitionId
      ? projectDefinitionStore.getElementDefinitionById(definitionId)
      : projectDefinitionStore.getElementDefinitionsByType(family, subtype).shift();

    if (elementDef && (!unitDef || getElementDetails)) {
      // some subtypes of unit profiles only exist as elements, pluck out appropriate family edit profile
      const profileType = projectDefinitionStore.toElemDefinitionId(elementDef.id);
      profile = dup.getElementProfileByDefinitionId(profileType) ?? null;
      definition = elementDef;
      unitElement = getUnitElement(element, elementDef);
      targetElement = element;
    } else if (unitDef && !elementDef) {
      profile = dup.getUnitProfileByTypes(unitDef.type, unitDef.subType)!;
      definition = unitDef;
      unitElement = getUnitElement(element, unitDef);
      targetElement = element;
    } else if (unitDef && elementDef) {
      if (hasIndexMetadata) {
        unitDef = unitDefFamily.pop()!;
        profile = dup.getUnitProfileByDefinitionId(unitDef.id)!;
        definition = elementDef;
        unitElement = getUnitElement(element, elementDef);
        targetElement = unitElement;
      } else {
        profile = dup.getUnitProfileByTypes(unitDef.type, unitDef.subType)!;
        definition = elementDef;
        unitElement = getUnitElement(element, elementDef);
        targetElement = element;
      }
    }
  }

  return {
    type: family,
    subtype: subtype,
    profile: profile!,
    definition: definition!,
    unitElement: unitElement,
    targetElement: targetElement
  };
};

interface RemoveFormatOptions {
  command: string;
  editor: CustomEditor;
}

export const removeFormatWithinSelection = ({ command, editor }: RemoveFormatOptions) => {
  const selectionUtil = new SelectionUtil(editor);
  const oppositeElements = selectionUtil.getFilteredElementsByClass(command === 'superscript' ? 'arc-sub-script' : 'arc-super-script');
  if (oppositeElements.length > 0) {
    oppositeElements.forEach((el, index) => {
      if (el.isHTMLElement) {
        const $el = $(el.element);
        if (!el.partialHTMLElement) {
          $el.replaceWith($el.html());
        } else {
          const $target = $el.find(command === 'superscript' ? '.arc-super-script' : '.arc-sub-script');
          if ($target.length) {
            if (index === 0) {
              $el.after($target);
            } else {
              $el.before($target);
            }
          }
        }
      }
    });
  }
};
export const isEmptyOfElements = (elContents: JQuery<HTMLElement | Text | Comment | Document>) => {
  let paraCount = 0;
  let onlyMetaEls = true;

  const metaTags = MetaTags.tags.concat('p'); // allow P as this is placed as a default tag by tinymce
  _.each(elContents, function (node) {
    if (node.nodeName) {
      if (node.nodeName === 'P') {
        paraCount++;
      }
      if (_.indexOf(metaTags, node.nodeName.toLowerCase()) === -1) {
        onlyMetaEls = false;
      }
    }
  });

  return onlyMetaEls && paraCount === 0;
};

export const isRangeSelection = function (editor): boolean {
  const { type } = editor.selection.getSel();
  return type.toLowerCase() === 'range';
};

export const isSelectedRangeReplaceAction = (editor, e): boolean => isRangeSelection(editor) && isReplaceKey(e);

export const removeEmptyPara = function ($paraNode) {
  let removed = false;
  const pContents = $paraNode.contents();
  let onlyMetaEls = true;

  _.each(pContents, function (node) {
    if (node.nodeName) {
      if (_.indexOf(MetaTags.tags, node.nodeName.toLowerCase()) === -1) {
        onlyMetaEls = false;
      }
    }
  });

  if (pContents.length === 0 || ($paraNode.text().length === 0 && onlyMetaEls)) {
    // if P empty node
    removed = true;
    setTimeout(function () {
      tinymce.activeEditor.undoManager.transact(function () {
        tinymce.activeEditor.dom.remove($paraNode[0]);
      });
    }, 10);
  }

  return removed;
};

export const isCursorAtTheEndOfNode = (editor: CustomEditor) => {
  const { textContent } = editor.selection.getNode();
  const { focusOffset } = editor.selection.getSel();
  return focusOffset >= textContent!.length - 1;
};

export const isCursorAtTheEndOfGivenNode = (node: Element) => {
  const lastChild = node.lastChild as Element;
  const range = window.getSelection()?.getRangeAt(0);
  return isTextNode(lastChild) && range?.startContainer === lastChild && range?.endOffset >= lastChild.length;
};

export const stripEmptyParagraphs = (insertTarget: JQuery<HTMLElement>): void => {
  const $nestedParagraphs = insertTarget.find('.arc-paragraph');
  $nestedParagraphs.each(function (index, el) {
    const paraText = el.textContent || '';
    if (paraText.match(/\w|\d|\s/gi) === null) {
      const $paraUnit = $(el);
      $paraUnit.closest("[data-element-family='Paragraph']")[0].remove();
    }
  });
};

export const withContentNotEditableAttribute = (html: string): JQuery<HTMLElement> => {
  const $insertedContent = $(html);

  // Make sure the content we're adding has the appropriate content editable attributes
  $insertedContent.find('.arc-editor-not-editable').each((index, elm) => elm.setAttribute('contenteditable', 'false'));

  if ($insertedContent.hasClass('arc-editor-not-editable')) {
    $insertedContent.attr('contenteditable', 'false');
  }

  return $insertedContent;
};

export const replaceTemplateDataNidsWithNewOnes = ($html: JQuery<HTMLElement>): JQuery<HTMLElement> => {
  if ($html.attr('data-nid')) {
    $html.attr('data-nid', generateDataNid());
  }
  $html.find('[data-nid]').each((index, elm) => elm.setAttribute('data-nid', generateDataNid()));
  return $html;
};

export const getClosestFigureNode = (editor: CustomEditor) => {
  let figureNode: HTMLElement | null = null;
  let selectedNode = editor.selection.getNode();

  // probably focused outside the editor (this is a tinymce edge case bug), force focus back in
  if (selectedNode && selectedNode.nodeName === 'DIV') {
    selectedNode = editor.getElement();
  }

  if (selectedNode.getAttribute('data-mce-bogus') !== null || selectedNode.classList.contains('mce-content-body')) {
    // if selection is an injected tiny bogus element, it will always be a sibling for fake cursor in readonly figure
    // currently this only occurs for root graphic elements
    let searchFromEl = selectedNode.getAttribute('data-mce-bogus') !== null ? selectedNode.parentNode : selectedNode;
    if (searchFromEl === null) {
      searchFromEl = editor.getElement();
    }
    figureNode = (searchFromEl as HTMLElement).querySelectorAll('figure')[0];
  } else {
    figureNode = $(selectedNode as HTMLElement).closest('figure')[0];
  }

  return figureNode;
};

export const getActiveFormats = (editor: CustomEditor): TinyAction[] => {
  if (editor && editor.selection && !editor.selection.getRng(true).collapsed) {
    return editor.formatter.matchAll([
      'bold',
      'underline',
      'overline',
      'italic',
      'subscript',
      'superscript',
      'code',
      'uppercase',
      'lowercase',
      'capitalize'
    ]);
  } else {
    return [];
  }
};

export const hasMultipleSelectedElements = (editorBody: HTMLBodyElement): boolean => {
  return $(editorBody).find('[data-mce-selected=1]').length > 1;
};

export const getFirstMatchingActionElementFromStartNode = (action: string, startNode: Element): Element => {
  const element = action === 'bold' ? 'strong' : action === 'code' ? 'code' : action === 'italic' ? 'em' : 'span.arc-underline';

  let $parentTD;
  if (startNode.nodeName === 'TABLE') {
    $parentTD = $(startNode).find('[data-mce-selected=1]').first();
  }
  if (!$parentTD || $parentTD[0].nodeName !== 'TD') {
    $parentTD = $(startNode).parents().closest('.arc-table-data');
  }
  return $parentTD.find(element).first()[0];
};

export function getIsActionOfType(action): (ofTypes: TinyAction | TinyAction[]) => boolean {
  return isActionOfType.bind(null, action);
}

export function isActionOfType(action: TinyAction, ofTypes: TinyAction | TinyAction[]): boolean {
  if (ofTypes && Array.isArray(ofTypes)) {
    return _.indexOf(ofTypes, action) !== -1;
  }
  return action === ofTypes;
}

export function isSelection(): boolean {
  const selection = tinymce.activeEditor.selection.getRng(true);
  return selection.startContainer !== selection.endContainer;
}

export function isEditableListItemElement(element: HTMLElement): boolean {
  return (
    element.getAttribute('data-element-definition-id') === 'ListParagraph' ||
    element.getAttribute('data-element-definition-id') === 'ListTitle'
  );
}

export function deleteElementAndRecordTransaction(element: HTMLElement) {
  EditorStore.getEditor().selectEditorNode(element.closest('[data-element-definition-id]'));
  tinymce.activeEditor.undoManager.transact(function () {
    tinymce.activeEditor.dom.remove(element);
  });
  updateDataAttributeValidationErrorsOnElementDelete(element);
  EditorStore.getEditor().silentReFocus();
}

export function updateDataAttributeValidationErrorsOnElementDelete(element: HTMLElement) {
  if (EditorStore.areDataAttributesValidationErrors() && element.getAttribute('data-nid')) {
    const errors = EditorStore.getDataAttributesValidationErrors();
    const dataNid = element.getAttribute('data-nid') as string;
    if (errors[dataNid]) {
      delete errors[dataNid];
      EditorStore.setDataAttributesValidationErrors(errors);
    }
  }
}

export function getFirstMostNestedEditTarget($editTarget: JQuery<HTMLElement>): HTMLElement {
  let mostFirstMostNested = $editTarget[0];
  for (let i = 0; i < $editTarget.length - 1; i++) {
    if ($editTarget[i].contains($editTarget[i + 1])) {
      mostFirstMostNested = $editTarget[i + 1];
    }
  }
  return mostFirstMostNested;
}

export function getPrevEditTarget(elm: Element): Element | undefined {
  const siblingEditTargets = elm.parentElement?.querySelectorAll('.edit-target');
  const currentElmEditTargetIndex =
    siblingEditTargets && Array.from(siblingEditTargets)?.findIndex((editTarget) => editTarget.isEqualNode(elm));
  return currentElmEditTargetIndex && siblingEditTargets && currentElmEditTargetIndex - 1 >= 0
    ? siblingEditTargets[currentElmEditTargetIndex - 1]
    : undefined;
}

export function handleDuRefInlineStyleRemoval(editor: CustomEditor, action: TinyAction) {
  if (selectionIsInDuRefButIsNotDuRef(editor, action)) {
    removeDuRefStyledText(editor);
  }
}

function selectionIsInDuRefButIsNotDuRef(editor: CustomEditor, action: TinyAction) {
  return (
    getDataElementDefinitionId(EditorStore.getFocusedUnitElement()) == 'DuRef' &&
    isInlineMarkupAction(action) &&
    getDataElementDefinitionId(editor.selection.getNode()) !== 'DuRef'
  );
}

function removeDuRefStyledText(editor: CustomEditor) {
  const paddedText = document.createElement('template');
  if (editor.selection.getNode().textContent) {
    paddedText.innerHTML = editor.selection.getNode().textContent as string;
    editor.selection.getNode().replaceWith(paddedText.content.firstChild as ChildNode);
  }
}
