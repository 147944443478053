import { metadataDefinition as titleMetadataDefinition } from '../title/metadataDefinition';
import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { AltTitlesValues } from '../index';

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.ALT_TITLES> = {
  ...titleMetadataDefinition,
  id: NNCIndexMetadataID.ALT_TITLES,
  toMetadataHTML: (values) => {
    let altTitles = '';
    values.forEach((val) => {
      if (val.text) {
        altTitles += `<div 
        class="alt-title-data" 
        qai="${val.qai}"
        unann="${val.unann}"
        eicas="${val.eicas}"
        fontbold="${val.fontbold}"
        fontlarge="${val.fontlarge}"
        hide="${val.hide}"
        hidetoc="${val.hidetoc}"
        >${val.text}</div>`;
      }
    });
    return `<div class="${metadataDefinition.id}">${altTitles}</div>`;
  },
  toHeaderHTML: (values, definitions) => {
    const br = definitions?.find((def) => def.id === 'LineBreak')?.templateHtml ?? '<br nid="nid">';
    if (values.length > 0) {
      return values
        .map((val) => {
          return val.text.length ? `${br}or${br}${val.text}` : null;
        })
        .join('');
    } else {
      return '';
    }
  },
  toJSON: ($element) => {
    const results: AltTitlesValues = [];
    $element.children().each(function () {
      results.push({
        eicas: $(this).attr('eicas') === 'true',
        fontbold: $(this).attr('fontbold') === 'true',
        fontlarge: $(this).attr('fontlarge') === 'true',
        qai: $(this).attr('qai') === 'true',
        unann: $(this).attr('unann') === 'true',
        text: $(this).text(),
        hide: $(this).attr('hide') === 'true',
        hidetoc: $(this).attr('hidetoc') === 'true'
      });
    });
    return results;
  }
};
