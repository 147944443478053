import { mm } from './base-clients';
import { IComplianceTag, ISharedIndex } from 'mm-types';
import { dateUtil } from '../utils';

export type DefaultSharedIndex = {
  origin: boolean;
  isNew: boolean;
  sharedIndex: Partial<ISharedIndex> | null;
};

export type SharedIndexMap = {
  count: number | undefined;
  className: string;
};

export async function getAll(indexUid: string) {
  const response = await mm.get<{ complianceTags: IComplianceTag[] }>(`/complianceTags/indexes/${indexUid}`);
  return response.data.complianceTags;
}

export async function create(indexUid: string, token: Partial<IComplianceTag>[]) {
  const response = await mm.post<IComplianceTag>(`/complianceTags/indexes/${indexUid}`, { complianceTags: token });
  return response.data;
}

export async function getForRegulation(indexUid: string, regUid: string) {
  const response = await mm.get<{ complianceTags: IComplianceTag[] }>(`/complianceTags/indexes/${indexUid}/regulations/${regUid}`);
  const complianceTags = response.data.complianceTags;
  complianceTags.forEach((tag) => {
    tag.unit.timestampFormatted = dateUtil(tag.unit.timestamp).formatDateTimeNoSecs();
  });
  return complianceTags;
}

export async function remove(indexUid: string, unitUid: string, tags: string[]) {
  const query: string[] = [];
  for (const tag of tags) {
    query.push(`regUid=${tag}`);
  }

  await mm.delete(`/complianceTags/indexes/${indexUid}/units/${unitUid}?` + query.join('&'));
}

export async function getComplianceMap(indexUid: string) {
  const response = await mm.get<{ unitRegMap: { [id: string]: string[] } }>(`/regulations/indexes/${indexUid}/unitTagMap`);
  return response.data.unitRegMap;
}
