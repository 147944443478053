import * as React from 'react';
import { ISortedFacetFamily, IComparableSearchTerm } from 'mm-types';

export type Props = {
  title?: string;
  family: ISortedFacetFamily | null;
  expanded?: boolean;
  changeMode?: boolean;
  addTag: (tag: IComparableSearchTerm) => void;
  enabled?: boolean;
  groupSelectedConceptUids: string[];
  matchedConcepts: IComparableSearchTerm[] | null;
  onToggled: (uid: string, isOpen: boolean) => void;
  children?: React.ReactNode;
};

export type State = {};

export default class TagFamily extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    title: '',
    enabled: true
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public close() {
    this.setState({ opened: false });
  }

  private _toggle() {
    this.props.onToggled(this.props.family!.uid, !this.props.expanded);
  }

  private addTag(tag: IComparableSearchTerm) {
    this.props.addTag(tag);
  }

  render() {
    let concepts = this.props.family!.concepts;
    const matches = this.props.matchedConcepts;

    if (matches) {
      concepts = concepts.filter((c) => matches!.indexOf(c) !== -1);
      if (concepts.length === 0) {
        return null;
      }
    }

    return (
      <div className={`tag-family ${this.props.expanded ? 'fam-opened' : 'fam-closed'}`}>
        <div onClick={() => this._toggle()}>
          <i className="material-icons facet-collapse-btn">{this.props.expanded ? 'expand_more' : 'chevron_right'}</i>

          <h1 className="facet-family-header">{this.props.family ? this.props.family.name : null}</h1>

          <span className="facet-tag-count"> {concepts.length} </span>
          {this.props.expanded ? this.props.children : null}
        </div>

        <div className={`facet-family-tags`}>
          {this.props.expanded
            ? concepts.map((concept, index) => {
                const isSelected = this.props.groupSelectedConceptUids.indexOf(concept.uid!) !== -1;
                return (
                  <div
                    key={`concept-${index}`}
                    className="bread-crumb"
                    onClick={!isSelected && this.props.enabled ? () => this.addTag(concept) : undefined}
                  >
                    <span className="alias">
                      {concept.alias}
                      {concept.alias && concept.alias.trim() !== '' ? ' > ' : ''}
                    </span>
                    <div className={`facet ${isSelected ? 'added' : ''}`}>{concept.name}</div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}
