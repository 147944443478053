import * as _ from 'lodash';
import ServerSettingsStore from '../../flux/common/ServerSettingsStore';
import { Features } from 'mm-types';
import { SESSION_STORAGE_KEYS } from '../../utils';

function defaultOff(features: Features, name: keyof Features): boolean {
  return !_.isUndefined(features[name]) ? !!features[name] : false;
}

export function getFeatures(): Features {
  return { ...ServerSettingsStore.getFeatures() };
}

export function isFeatureOn(name: keyof Features): boolean {
  return defaultOff(getFeatures(), name);
}

export function getWithTurnedOnOffFeature(features: Features, name: keyof Features, on: boolean): Features {
  const newFeatures = { ...features };
  newFeatures[name] = on;
  return newFeatures;
}

export function featuresToBase64String(features: Features, enabled = true): string {
  let result = '';
  Object.keys(features).forEach((name) => {
    if (features[name] === enabled) {
      result += btoa(name) + ';';
    }
  });
  return result;
}

export function setSessionFeature(name: keyof Features, on: boolean, existingFeatures: Features) {
  const newFeatures = getWithTurnedOnOffFeature(existingFeatures, name, on);
  sessionStorage.setItem(btoa(SESSION_STORAGE_KEYS.FEATURES_ON), featuresToBase64String(newFeatures));
  sessionStorage.setItem(btoa(SESSION_STORAGE_KEYS.FEATURES_OFF), featuresToBase64String(newFeatures, false));
}
