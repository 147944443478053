import { EventStoreEventType, IEditorStoreEvent, IUnit } from 'mm-types';
import * as React from 'react';
import { PrintOutputUtils } from '../../../utils/PrintOutputUtils';
import { ElementPrintOutput, ElementPrintOutputProps } from './ElementPrintOutput';
import { TablePrintOutput, TablePrintOutputProps } from './TablePrintOutput';
import useListenToStore from '../../hooks/useListenToStore';
import EditorStore from '../../../flux/editor/EditorStore';

export type Props = {
  unit: IUnit;
};

type PrintOutputSummary = ElementPrintOutputProps | TablePrintOutputProps;

type PrintOutputIndicators = PrintOutputSummary[];

export const ElementPrintLabels = (props: Props) => {
  const [elementIndicators, setElementIndicators] = React.useState<ElementPrintOutputProps[]>([]);
  const [tableIndicators, setTableIndicators] = React.useState<TablePrintOutputProps[]>([]);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });

  React.useEffect(() => {
    setUnitElementBreaks();
  }, []);

  React.useEffect(() => {
    setUnitElementBreaks();
  }, [props.unit.html]);

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'nestedUnitFocusChange' || e.type === 'editBlur') {
      PrintOutputUtils.updateElementPrintOutputInUnit();
    } else if (e.type === 'dataAttributeApplied' && PrintOutputUtils.currentSelectedUnitIsSelectedUnitInTree(props.unit.uid)) {
      setUnitElementBreaks();
    }
  }
  const setUnitElementBreaks = () => {
    let { elementsWithPageBreaks } = PrintOutputUtils.getElementsWithPageBreaks(props.unit);
    // execute only if any elements present with print output data attributes
    if (elementsWithPageBreaks.length > 0) {
      let indicators: ElementPrintOutputProps[] = [];
      elementsWithPageBreaks.forEach((element) => {
        const { tagName } = element;

        if (tagName?.toUpperCase() == 'TR') {
          setTablePrintOutput(element.closest('table:not(.po-table-page-break)')!);
        } else {
          const { dataRefNid, style } = PrintOutputUtils.updatePrintOutputLabelPosition(element);
          const printOutputSummary: ElementPrintOutputProps = {
            dataRefNid: dataRefNid,
            style: style,
            ...PrintOutputUtils.getPrintOutputAttributes(element)
          };
          updateIndicatorArray(printOutputSummary, indicators);
        }
      });
      setElementIndicators(() => [...indicators]);
    }
  };

  const setTablePrintOutput = (tableWithHiddenPrintOutput: HTMLTableElement | null) => {
    const isNotNestedTable = !tableWithHiddenPrintOutput?.parentElement?.closest('table');
    if (tableWithHiddenPrintOutput?.getAttribute('data-element-family') == 'Table' && isNotNestedTable) {
      const dataRefNid = tableWithHiddenPrintOutput.getAttribute('data-nid')!;

      const printOutputSummary: TablePrintOutputProps = { dataRefNid: dataRefNid, unitHtml: tableWithHiddenPrintOutput };
      let tableIndicators: TablePrintOutputProps[] = [];
      updateIndicatorArray(printOutputSummary, tableIndicators);
      setTableIndicators(() => [...tableIndicators]);
      tableWithHiddenPrintOutput.classList.add('po-table-page-break');
    }
  };

  const updateIndicatorArray = (printOutputSummary: PrintOutputSummary, indicators: PrintOutputIndicators) => {
    const indicatorIndex = indicators?.findIndex((obj: PrintOutputSummary) => obj.dataRefNid == printOutputSummary.dataRefNid) ?? -1;
    if (indicatorIndex >= 0) {
      indicators[indicatorIndex] = printOutputSummary;
    } else {
      indicators.push(printOutputSummary);
    }
  };

  return (
    <>
      {elementIndicators.map((elementIndicator) => (
        <ElementPrintOutput key={elementIndicator.dataRefNid} {...elementIndicator} />
      ))}
      {tableIndicators.map((tableIndicator) => (
        <TablePrintOutput key={tableIndicator.dataRefNid} {...tableIndicator} />
      ))}
    </>
  );
};
