import * as React from 'react';
import { AeroIconButtonDownload } from '../../../general/AeroIconButton';

interface Props {
  fileName: string;
  created: string;
  canDownload: boolean;
  onDownloadClick: () => void;
}

const ExternalRevisionSubentry = ({ created, canDownload, fileName, onDownloadClick }: Props) => {
  return (
    <li>
      <div>
        <label className="draftEntry">{fileName}</label>
        <span className="dateSpan">{created}</span>
        <AeroIconButtonDownload
          style={{ marginTop: '-6px' }}
          disabled={!canDownload}
          id="downloadRevision"
          onClick={onDownloadClick}
          tooltip="Download"
          tooltipPosition="top-left"
        />
      </div>
    </li>
  );
};

export default ExternalRevisionSubentry;
