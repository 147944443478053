import * as CONSTANTS from '../../keyIdentifier';
import * as keyIdentifier from '../../keyIdentifier';
import { EditorManager } from 'tinymce';
import { checkElementKeyBehaviors } from './elementKeyBehaviors/elementKeyBehaviors';
import MetaTags from '../../units/MetaTags';
import { getPrevEditTarget, isCursorAtTheEndOfNode, removeEmptyPara } from '../TinyFacadeHelpers';
import { isNotInlineElement } from '../../units/unit/inlineElementsLookup';
import { ElementDetails } from '../../units/ElementDetails';
import { ZERO_LENGTH_WORD_JOINER, isLineBreakAllowed } from './keyBehaviourUtils';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;

declare const tinymce: EditorManager;

/**
    Hacky stuff to overcome tinymce looser definition of our requirements!
    applies to all units and their nested units.
 * @param {*} editor an EditorInstance
 * @param {*} e an event...
 */
export const HackyStuffListener = function (editor, e) {
  if (e.arconicsEventStopped) {
    return;
  }

  const originalSelectedNode = editor.selection ? editor.selection.getNode() : null;

  if (!originalSelectedNode) {
    return;
  }

  if (!isLineBreakAllowed() && keyIdentifier.isShiftEnterKeys(e)) {
    CONSTANTS.nukePropagation(e);
    return;
  }

  const selectedNode = MetaTags.getActualElement($(originalSelectedNode))[0];

  // console.log('ORIGINAL NODE: ' + originalSelectedNode.nodeName);
  // console.log('SELECTED NODE: ' + selectedNode.nodeName);
  // console.log('SELECTED CLASSNAMES: ' + selectedNode.className);

  checkElementKeyBehaviors(selectedNode, e);

  if (['arc-effectivity-tag-value', 'arc-effectivity-tag'].indexOf(selectedNode.className) > -1) {
    if (e.keyCode === CONSTANTS.KeyCode.enter) {
      CONSTANTS.nukePropagation(e);
      return true;
    }
  } else if (
    CONSTANTS.isDeleteKeys(e) &&
    selectedNode.className.indexOf('edit-target') !== -1 &&
    selectedNode.className.indexOf('arc-paragraph') !== -1 &&
    (selectedNode.textContent === '' || selectedNode.textContent === ZERO_LENGTH_WORD_JOINER)
  ) {
    // delete empty decorated element as long as it's not
    const container = selectedNode.closest('.element-container');
    if (container && getDataElementDefinitionId(container) !== 'TableCollection') {
      setTimeout(function () {
        tinymce.activeEditor.undoManager.transact(function () {
          tinymce.activeEditor.dom.remove(container as Element);
        });
      }, 10);
      CONSTANTS.nukePropagation(e);
      return false;
    } else {
      const prevEditTarget = getPrevEditTarget(selectedNode);
      // Directly manage deletion tinymce makes a dogs dinner of it taking parent TD as well
      setTimeout(function () {
        tinymce.activeEditor.undoManager.transact(function () {
          tinymce.activeEditor.dom.remove(selectedNode as Element);
        });
      }, 10);
      CONSTANTS.nukePropagation(e);
      prevEditTarget && editor.selection.setCursorLocation(prevEditTarget, 1);
      return false;
    }
  } else if (['arc-warning-body', 'arc-caution-body', 'arc-note-body'].indexOf(selectedNode.className) > -1) {
    if (e.keyCode === CONSTANTS.KeyCode.enter) {
      if (e.shiftKey) {
        const endId = (tinymce as any).DOM.uniqueId();
        editor.dom.insertAfter(
          editor.dom.create('P', { id: endId }),
          editor.dom.getParent(editor.selection.getNode(), function (el) {
            return editor.dom.hasClass(el, 'arc-note') || editor.dom.hasClass(el, 'arc-warning') || editor.dom.hasClass(el, 'arc-caution');
          })
        );

        const newNode = editor.dom.select('p#' + endId);
        editor.selection.select(newNode[0]);

        CONSTANTS.nukePropagation(e);
        return false;
      } else {
        editor.execCommand('InsertLineBreak');
        CONSTANTS.nukePropagation(e);
        return false;
      }
    }
  } else if (selectedNode.nodeName === 'P') {
    if (e.keyCode === CONSTANTS.KeyCode.backspace) {
      if (selectedNode.parentElement!.className === 'arc-warning-body' && editor.selection.getRng().startOffset === 0) {
        CONSTANTS.nukePropagation(e);
        return false;
      }

      const paraNode = $(selectedNode),
        figureNode = paraNode.prev();

      // if backspace into a figure element
      if (editor.selection.getRng().startOffset === 0) {
        // first position

        if (figureNode.length && figureNode[0].nodeName === 'FIGURE') {
          editor.selection.setCursorLocation(figureNode[0], 1); // position cursor at end of figure element
          removeEmptyPara(paraNode);
          CONSTANTS.nukePropagation(e);
          return false;
        }
      } else if (
        ['arc-warning-body', 'arc-note-body', 'arc-caution-body'].indexOf(paraNode.parent()[0].className) !== -1 &&
        editor.selection.getRng().startOffset === 1
      ) {
        // allow last char deletion without removing admonition
        if (paraNode[0].textContent === ZERO_LENGTH_WORD_JOINER) {
          return true;
        } else {
          paraNode[0].textContent = ZERO_LENGTH_WORD_JOINER;
          CONSTANTS.nukePropagation(e);
          return false;
        }
      }
    } else if (e.keyCode === CONSTANTS.KeyCode.enter) {
      // prevent a list breaking return if parent of this P is an LI (and LI is empty and not last one)
      if (selectedNode.parentElement!.nodeName === 'LI') {
        const $listNode = $(selectedNode.parentNode!);
        if ($listNode.text().length === 0 && $listNode.index() < $listNode.parent().find('li').length - 1) {
          CONSTANTS.nukePropagation(e);
        }
      }

      // handle SHIFT + ENTER situation in the ConceptLabel
      if (keyIdentifier.isShiftEnterKeys(e)) {
        if (ElementDetails.getDataElementDefinitionId(selectedNode as Element) === 'ConceptLabel') {
          editor.execCommand('InsertLineBreak');
          CONSTANTS.nukePropagation(e);
        }
      }
    }
  } else if (selectedNode.nodeName === 'CAPTION') {
    // if deleting back at the start, or pressing return

    if (
      (e.keyCode === CONSTANTS.KeyCode.backspace && editor.selection.getRng().startOffset === 0) ||
      e.keyCode === CONSTANTS.KeyCode.enter
    ) {
      CONSTANTS.nukePropagation(e);
      return false;
    }
  } else if (selectedNode.nodeName === 'TABLE') {
    if (e.keyCode === CONSTANTS.KeyCode.del) {
      CONSTANTS.nukePropagation(e);
      editor.execCommand('arcTableDelete', false, null);
    } else if (e.keyCode === CONSTANTS.KeyCode.v && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      // prevent pasting chicanary
      CONSTANTS.nukePropagation(e);
      return false;
    }
  } else if (selectedNode.nodeName === 'TR') {
    if (e.keyCode === CONSTANTS.KeyCode.v && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      // prevent pasting chicanary
      tinymce.activeEditor.selection.setCursorLocation(selectedNode.firstChild as any);
    }
  } else if (selectedNode.nodeName === 'TD') {
    if (CONSTANTS.isDeleteKeys(e)) {
      CONSTANTS.nukePropagation(e);
      return false;
    }
  } else if (selectedNode.nodeName === 'LI') {
    const $selectedNode = $(selectedNode);
    if ($selectedNode.text().length === 0) {
      if (e.keyCode === CONSTANTS.KeyCode.enter) {
        CONSTANTS.nukePropagation(e);
        const editTarget = $selectedNode.find('.edit-target')[0];
        if (editTarget) {
          tinymce.activeEditor.selection.setCursorLocation(editTarget as any);
        }
      } else if (CONSTANTS.isDeleteKeys(e)) {
        CONSTANTS.nukePropagation(e);
        const prev = $selectedNode.prev()[0];
        if (prev) {
          const selected = prev.children.length > 0 ? $(prev).find('.edit-target')[0] || prev : prev;
          const offset = selected.innerText.length === 0 ? 0 : 1;
          tinymce.activeEditor.dom.remove(selectedNode);
          tinymce.activeEditor.selection.setCursorLocation(selected as any, offset);
        } else {
          tinymce.activeEditor.dom.remove($selectedNode[0]);
        }
      }
    }
  } else if (selectedNode.nodeName === 'DIV') {
    const $selectedNode = $(selectedNode);
    if ($selectedNode.hasClass('du-label')) {
      CONSTANTS.nukePropagation(e);
    }
  } else if (selectedNode.nodeName === 'UL' || selectedNode.nodeName === 'OL') {
    if (CONSTANTS.isDeleteKeys(e)) {
      CONSTANTS.nukePropagation(e);
    }
  }

  if (
    e.keyCode === CONSTANTS.KeyCode.enter &&
    isCursorAtTheEndOfNode(editor) &&
    originalSelectedNode.nodeName !== 'P' &&
    originalSelectedNode.nodeName !== 'LI' &&
    isNotInlineElement(originalSelectedNode) &&
    (selectedNode.nodeName === 'P' || selectedNode.nodeName === 'LI')
  ) {
    let cursorNode = selectedNode;
    if (selectedNode.nodeName === 'P' && selectedNode.parentNode && selectedNode.parentNode.nodeName === 'LI') {
      cursorNode = selectedNode.parentNode as HTMLElement;
    }
    editor.selection.setCursorLocation(cursorNode, cursorNode.childElementCount);
  }
};
