import * as Reflux from 'reflux';
import * as _ from 'lodash';
import IndexEventStore, { IndexEventStoreEvent } from '../events/IndexEventStore';
import ActiveUserStore from '../common/ActiveUserStore';
import EditorStore from '../../flux/editor/EditorStore';
import Store from '../Store';
import {
  IVariantFamily,
  DocParams,
  IUnitConceptMap,
  IUnit,
  ISortedFacetFamily,
  IConcept,
  IEditorStoreEvent,
  EventStoreEventType,
  IConceptFamily
} from 'mm-types';
import {
  getUnitMap,
  removeConceptFromUnit,
  assignConceptToUnit,
  getSearchFacets,
  getUnitMapByUnit,
  IUnitConceptMapResponse,
  IConceptMap
} from '../../clients/concepts';

export type State = {
  facetFamilies: ISortedFacetFamily[] | null;
  variantFamily: IVariantFamily | null;
  unitConceptMaps: { [name: string]: IUnitConceptMap };
  isBusy: boolean;
  activeVariants: Set<string>;
};

export type UnitConceptStoreEvent = {
  type: 'retrieving-unit-facet-map' | 'retrieved-unit-facet-map' | 'retrieved-search-facets' | 'variant-added' | 'variant-removed';
  state: State;
  concept?: IConcept;
  unitUids?: string[];
};

export class UnitConceptStore extends Store<State> {
  private _storesSetup: boolean;
  private _targetUnitUid: string | undefined;

  constructor() {
    super();
    this.state = {
      variantFamily: null,
      unitConceptMaps: {},
      isBusy: false,
      facetFamilies: null,
      activeVariants: new Set<string>()
    };
    this._storesSetup = false;
  }

  getInitialState() {
    return this.state;
  }

  getFamilyName() {
    return this.state.variantFamily ? this.state.variantFamily.name : '';
  }

  isBusy() {
    return this.state.isBusy;
  }

  getFamilyVariants() {
    return this.state.variantFamily ? this.state.variantFamily.concepts : [];
  }

  getVariantFamilyForIndexCurrentIndex(): IConceptFamily<IConcept> | undefined {
    return this.state.variantFamily ?? undefined;
  }

  getUnitEditable(unit: IUnit) {
    if (!this.state.variantFamily) {
      return false;
    }

    const unitMap = this.state.unitConceptMaps[unit.uid];
    if (!unitMap) {
      return true;
    }

    return unitMap.editable;
  }

  getUnitVariants(unit: IUnit) {
    if (!this.state.variantFamily) {
      return [];
    }

    const unitMap = this.state.unitConceptMaps[unit.uid];
    return unitMap && unitMap.variants ? unitMap.variants : [];
  }

  getUnitHasVariants(unitUid: string) {
    if (!this.state.variantFamily) {
      return false;
    }

    const unitMap = this.state.unitConceptMaps[unitUid];
    return unitMap && unitMap.variants && unitMap.variants.length > 0;
  }

  getUnitVariantMap(unitUid: string) {
    if (!this.state.variantFamily) {
      return null;
    }

    const unitMap = this.state.unitConceptMaps[unitUid];
    return unitMap || null;
  }

  getUnitConceptMap(unitUid: string) {
    const unitMap = this.state.unitConceptMaps[unitUid];
    return unitMap || null;
  }

  isAtLeastOneUnitTaggedWithThisVariant(variantUid: string) {
    const isTagged = false;
    if (!this.state.unitConceptMaps) {
      return isTagged;
    }
    return this.state.activeVariants.has(variantUid);
  }

  // Event Handlers

  _onEditorStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    this.state.isBusy = true;

    const docParams = EditorStore.getDocParams();
    const hasConcepts = !!Object.keys(this.state.unitConceptMaps ?? {}).length;

    switch (e.type) {
      case 'createBatchUnits':
      case 'deleteUnitConfirm':
      case 'deleteSelectedUnitsConfirm':
      case 'undoredo':
      case 'createUnit':
        if (hasConcepts) {
          this.init(docParams);
        }
        break;
    }
    this.state.isBusy = false;
  }

  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.isUserMe) {
      return;
    }

    if (e.activity === 'unitVariantTagChanged') {
      this.init({ indexUid: e.data.indexUid, projectUid: e.data.projectUid });
    }
  }

  async init(docParams: DocParams, unitUid?: string) {
    let unitMapsArray: IConceptMap[] | null;
    let conceptUnitMap: IUnitConceptMapResponse | null;

    if (unitUid) {
      this._targetUnitUid = unitUid;
    }

    const docIndexUid = docParams.indexUid ? docParams.indexUid : docParams.documentIndexUid!;

    if (!this._storesSetup) {
      this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
      this.listenTo(EditorStore as any, this._onEditorStoreUpdate);
      this._storesSetup = true;
    }
    const preparingDataEvent: UnitConceptStoreEvent = {
      type: 'retrieving-unit-facet-map',
      state: this.state
    };
    this.trigger(preparingDataEvent);

    this.state.isBusy = true;

    if (this._targetUnitUid) {
      conceptUnitMap = await getUnitMapByUnit(docIndexUid, this._targetUnitUid);
      unitMapsArray = conceptUnitMap.conceptTags;
    } else {
      conceptUnitMap = await getUnitMap(docIndexUid);
      unitMapsArray = conceptUnitMap.conceptTags;
    }

    const variantFamily = conceptUnitMap.variantFamily;

    const unitMap: { [name: string]: IUnitConceptMap } = {};
    const activeVariants = new Set<string>();
    if (unitMapsArray) {
      for (let i = 0, l = unitMapsArray.length; i < l; i++) {
        const variants = unitMapsArray[i].variants;
        variants.forEach((variant) => (variant.uid ? activeVariants.add(variant.uid) : null));
        unitMap[unitMapsArray[i].unitUid] = {
          variants: variants,
          searchTerms: unitMapsArray[i].searchTermsTag,
          editable: unitMapsArray[i].variantEditable
        };
      }
    }

    this.state = {
      variantFamily,
      unitConceptMaps: unitMap,
      isBusy: false,
      facetFamilies: null,
      activeVariants: activeVariants
    };

    const event: UnitConceptStoreEvent = {
      type: 'retrieved-unit-facet-map',
      state: this.state
    };
    this.trigger(event);
  }

  async retrieveSearchFacets() {
    const docParams = EditorStore.getDocParams();
    const sortedFamilies = await getSearchFacets(docParams.projectUid);
    this.state.facetFamilies = sortedFamilies;
    this.state.isBusy = false;

    const event: UnitConceptStoreEvent = {
      type: 'retrieved-search-facets',
      state: this.state
    };
    this.trigger(event);
  }

  async removeConceptFromUnit(concept: IConcept, docParams: DocParams, unitUids: string[]) {
    try {
      const promises: Promise<void>[] = [];
      for (const unitUid of unitUids) {
        promises.push(removeConceptFromUnit(docParams.indexUid!, unitUid, concept.uid!));
      }

      await Promise.all(promises);

      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'unitVariantTagChanged',
        data: {
          indexUid: docParams.indexUid!,
          projectUid: docParams.projectUid!
        }
      });

      this.init(docParams);

      const event: UnitConceptStoreEvent = {
        type: 'variant-removed',
        concept: concept,
        unitUids: unitUids,
        state: this.state
      };

      this.trigger(event);
    } catch (err) {
      this.init(docParams);
      EditorStore.variantSelectionError(err.message);
    }
  }

  async addConceptToUnit(concept: IConcept, docParams: DocParams, unitUids: string[]) {
    try {
      const promises: Promise<void>[] = [];

      for (const u of unitUids) {
        promises.push(assignConceptToUnit(docParams.indexUid!, u, concept.uid!));
      }

      await Promise.all(promises);

      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'unitVariantTagChanged',
        data: {
          indexUid: docParams.indexUid!,
          projectUid: docParams.projectUid!
        }
      });

      this.init(docParams);

      const event: UnitConceptStoreEvent = {
        type: 'variant-added',
        concept: concept,
        unitUids: unitUids,
        state: this.state
      };

      this.trigger(event);
    } catch (err) {
      this.init(docParams);
      EditorStore.variantSelectionError(err.message, err.code, err);
    }
  }
}

const singleton = Reflux.initStore<UnitConceptStore>(UnitConceptStore);
export default singleton;
