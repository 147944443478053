import * as workspaces from '../../clients/workspaces';
import { IUser } from 'mm-types';
import Store from '../Store';
import * as Reflux from 'reflux';
import { getUser } from '../../clients/users';
import { IWorkspace } from 'mm-types';

let _activeUser: IUser | null = null;

export type ActiveUserStoreEvent = IUser;

export class ActiveUserStore extends Store<any> {
  constructor() {
    super();
  }

  setUser(user: IUser) {
    _activeUser = user;
    this.trigger(_activeUser);
  }
  getUser() {
    return _activeUser;
  }

  isTeamspaceAdmin() {
    return _activeUser!.teamspaceAdmin || false;
  }

  isTeamspaceMember() {
    return (_activeUser?.memberTeamspaces && _activeUser?.memberTeamspaces.length > 0) ?? false;
  }

  isDistributorAdmin() {
    return _activeUser?.account?.aerosyncAdmin || _activeUser?.account?.aerosyncReadAdmin || false;
  }

  isAdminOfSelectedProjectTeamspace(workspaceUid: string) {
    const teamspaces = _activeUser!.adminTeamspaces!;
    return teamspaces.find((t) => t.uid === workspaceUid) ? true : false;
  }

  isAdmin() {
    return _activeUser!.globalAdmin;
  }

  async persistActiveUserAndGetTeamspaces(user: IUser) {
    _activeUser = { ...user };
    this.trigger(_activeUser as ActiveUserStoreEvent);
    await this.updateActiveUserTeamspaces();
  }

  async updateActiveUser(uid?: string): Promise<IUser> {
    return getUser(uid).then((activeUser) => {
      _activeUser = activeUser;
      this.trigger(_activeUser);
      this.updateActiveUserTeamspaces();
      return _activeUser;
    });
  }

  async updateActiveUserTeamspaces() {
    const adminSpaces: IWorkspace[] = await workspaces.getAll({ canAdministerOnly: true });
    const memberSpaces: IWorkspace[] = await workspaces.getAll({ isMemberOnly: true });
    const adminTeamspaces = adminSpaces.filter((w) => w.type === 'TEAM');
    _activeUser!.teamspaceAdmin = adminTeamspaces.length > 0;
    _activeUser!.adminTeamspaces = adminTeamspaces;
    _activeUser!.memberTeamspaces = memberSpaces.filter((w) => w.type === 'TEAM');

    this.trigger(_activeUser as ActiveUserStoreEvent);
  }
}

const singleton = Reflux.initStore<ActiveUserStore>(ActiveUserStore);
export default singleton;
