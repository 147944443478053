import * as React from 'react';
import { IFileAttachment } from 'mm-types';

type CommentFormState = {
  commentTextValue: string;
  showFilesError: boolean;
  attachedFiles: Partial<IFileAttachment>[];
  attachmentMode: boolean;
  isPublic: boolean;
  maxLengthExceeded: boolean;
};

type CommentFormActions =
  | { type: 'setCommentTextValue'; payload: string }
  | { type: 'setShowFilesError'; payload: boolean }
  | { type: 'setAttachedFiles'; payload: Partial<IFileAttachment>[] }
  | { type: 'setAttachmentMode'; payload: boolean }
  | { type: 'setIsPublic'; payload: boolean }
  | { type: 'setMaxLengthExceeded'; payload: boolean };

export const CommentFormReducer: React.Reducer<CommentFormState, CommentFormActions> = (state, action) => {
  switch (action.type) {
    case 'setCommentTextValue':
      return { ...state, commentTextValue: action.payload };
    case 'setShowFilesError':
      return { ...state, showFilesError: action.payload };
    case 'setAttachedFiles':
      return { ...state, attachedFiles: action.payload };
    case 'setAttachmentMode':
      return { ...state, attachmentMode: action.payload };
    case 'setIsPublic':
      return { ...state, isPublic: action.payload };
    case 'setMaxLengthExceeded':
      return { ...state, maxLengthExceeded: action.payload };
  }
};
