import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import Dimension from '../controls/Dimension';
import Alignment, { ActionType } from '../controls/Alignment';
import Border from '../controls/Border';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export type State = {
  isCaptionOn: boolean;
  caption: string | null;
  align: ActionType['action'];
  border: string;
  borderStyle: string;
  imageWidth: null | ImageDimension;
  imageMaxWidth: null | ImageDimension;
  imageHeight: null | ImageDimension;
  focusElm: null | Element;
  errorMessage: string | null;
  naturalDimensions: { width: number; height: number } | null;
};

export type ScaleInfo = {
  unit?: UnitType;
  scaleType?: 'width' | 'maxWidth' | 'height';
  scaleWidth?: number | null;
  scaleHeight?: number | null;
  maxUnit?: UnitType;
  scaleMaxWidth?: number | null;
};

export type ImageDimension = {
  unit: UnitType;
  value: number | null;
  disabled?: boolean;
};

export type UnitType = 'px' | '%';

export default class ImageEditProps extends React.Component<IGenericEditProps, State> {
  private unsubscribe: Function;
  private _userOpened: boolean;
  captionRef = React.createRef<TextField>();

  constructor(props: IGenericEditProps) {
    super(props);
    this.state = {
      isCaptionOn: false,
      caption: '',
      align: 'JustifyLeft',
      border: 'BorderNone',
      borderStyle: 'BorderStyleSolid',
      imageWidth: null,
      imageMaxWidth: null,
      imageHeight: null,
      focusElm: null,
      errorMessage: null,
      naturalDimensions: null
    };
  }

  _handleKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 27) {
      // return or esc

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      e.target.blur();
      EditorStore.getEditor().silentReFocus();
    } else if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      // cmd/ctrl-s

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      EditorStore.getEditor().blur();
    }
  }

  UNSAFE_componentWillMount() {
    this._updateState(() => {
      this._updateDimensionState();
    });

    this.unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  _onEditStoreUpdate(e) {
    if (e.type === 'unitStyleChange' || e.type === 'nestedUnitFocusChange') {
      if (e.type === 'unitStyleChange') {
        if (['justify', 'border'].indexOf(e.data.styleType) > -1) {
          this._updateState();
        } else if (e.data.styleType === 'imageScale') {
          this._updateDimensionState(e.data.styleProperties);
        }
      } else {
        // nestedUnitFocusChange (i.e. click from one nested image to another - the component stays mounted)
        // component stays mounted as we want it to persist on internal unit changes (e.g. table cells) otherwise would be mounted and unmounted on cursor changes
        if (!(e.data.focused === null && e.data.parent === null)) {
          this._updateState(() => {
            this._updateDimensionState();
          });
        }
      }
    }
  }

  _updateState(callback?: () => void) {
    const editor = EditorStore.getEditor().getActiveEditorFacade()!;
    const { enabled, value } = editor.getCaption('figure');
    const figure = editor.getSelectedFigure();

    const img = figure.querySelector('img');
    const width: number = img ? img.naturalWidth : 0;
    const height: number = img ? img.naturalHeight : 0;

    this.setState(
      {
        isCaptionOn: enabled,
        caption: value,
        align: editor.getJustification('figure'),
        border: editor.getBorder('figure'),
        borderStyle: editor.getBorderStyle('figure'),
        focusElm: figure,
        naturalDimensions: { width: width, height: height }
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  _updateDimensionState(scaleInfo?: ScaleInfo) {
    const localScaleInfo: ScaleInfo | null = scaleInfo ? scaleInfo : EditorStore.getEditor().getActiveEditorFacade()!.getFigureScale();

    if (localScaleInfo) {
      const width = {};
      if (localScaleInfo.hasOwnProperty('scaleWidth')) {
        width['imageWidth'] = {
          unit: localScaleInfo!.unit,
          value: localScaleInfo!.scaleWidth
        };
      }
      if (localScaleInfo.hasOwnProperty('scaleMaxWidth')) {
        width['imageMaxWidth'] = {
          unit: localScaleInfo!.maxUnit!,
          value: localScaleInfo!.scaleMaxWidth!
        };
      }

      const state: Partial<State> = Object.assign(
        {},
        { imageHeight: this.state.imageHeight },
        { imageWidth: this.state.imageWidth },
        { imageMaxWidth: this.state.imageMaxWidth },
        width,
        {
          imageHeight: {
            unit: 'px' as UnitType,
            value: localScaleInfo!.scaleHeight,
            disabled: false
          }
        }
      );

      // sanity behaviour
      if (localScaleInfo!.scaleHeight === null || localScaleInfo!.unit === '%') {
        state.imageHeight!.disabled = true;
        state.imageHeight!.value = null;
      }

      this.setState(state as State);
    }
  }

  _handleCaptionToggle() {
    const editor = EditorStore.getEditor();
    editor.getActiveEditorInstance()?.selection.select(this.state.focusElm!);
    editor.getActiveEditorFacade()?.execCommand('arcToggleCaption', 'figure');
    const { enabled, value } = editor.getActiveEditorFacade()!.getCaption('figure');
    this.setState({ isCaptionOn: enabled, caption: value }, () => {
      if (enabled) {
        setTimeout(() => this.captionRef.current?.focus(), 300);
      }
    });
  }

  _handleCaptionChange(e) {
    const msg = e.target.value.length > 499 ? 'Maximum characters reached' : null;

    if (this.state.caption !== e.target.value) {
      this.setState(
        {
          caption: e.target.value,
          errorMessage: msg
        },
        () => {
          EditorStore.getEditor()
            .getActiveEditorFacade()!
            .setCaption('figure', this.state.caption, this.state.focusElm!.querySelector('figcaption'));
        }
      );
    }
  }

  _onAlignmentChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);
  }

  _onBorderChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);
    facade.execCommand(e.styleAction, null);
  }

  _onBorderStyleChange(e) {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(e.action, null);

    setTimeout(() => {
      EditorStore.getEditor().silentReFocus();
    }, 300);
  }

  _onDimensionWidthChange(e) {
    const resetWidth = e.unit === this.state.imageMaxWidth!.unit && this.state.imageMaxWidth!.value ? true : false;
    const resetHeight = this.state.imageMaxWidth!.value && this.state.imageWidth!.value ? true : false;
    EditorStore.getEditor()
      .getActiveEditorFacade()!
      .scaleFigure({ type: 'width', value: e.value, unit: e.unit }, resetWidth, resetHeight, this.state.naturalDimensions);
  }

  _onDimensionMaxWidthChange(e) {
    const resetWidth = e.unit === this.state.imageWidth!.unit && this.state.imageWidth!.value ? true : false;
    const resetHeight = this.state.imageMaxWidth!.value && this.state.imageWidth!.value ? true : false;
    EditorStore.getEditor()
      .getActiveEditorFacade()!
      .scaleFigure({ type: 'maxWidth', value: e.value, unit: e.unit }, resetWidth, resetHeight, this.state.naturalDimensions);
  }

  _handleHotspotClick() {
    EditorStore.triggerOpenHotspotsModal();
  }

  render() {
    return (
      <div className="toggle-section-outer selected-unit-props-container">
        <ToggleSection
          title="Graphic Properties"
          id={'unitProps_' + this.props.index}
          defaultOpen={false}
          forceClose={!this.props.isLeaf}
          forceOpen={this.props.isLeaf || this._userOpened}
          onToggled={(isOpened) => (this._userOpened = isOpened)}
        >
          <div className="selected-unit-props-section image-edit">
            <div className="manip-group">
              <h6>Native Dimensions</h6>
              <span className="dim controls">
                <h6>
                  {this.state.naturalDimensions
                    ? `${this.state.naturalDimensions.width} x ${this.state.naturalDimensions.height}`
                    : undefined}
                </h6>
              </span>
            </div>
          </div>

          <div className="selected-unit-props-section image-edit image-edit-caption">
            <div className="selected-unit-props-subsection">
              <div className="caption-toggle-container" onClick={(e) => this._handleCaptionToggle()}>
                <Toggle name="graphiccaption" label="Include graphic caption" toggled={this.state.isCaptionOn} />
              </div>

              {this.state.isCaptionOn ? (
                <TextField
                  onKeyDown={(e) => this._handleKeyDown(e)}
                  onChange={(e) => this._handleCaptionChange(e)}
                  errorText={this.state.errorMessage}
                  className="image-edit-textfield"
                  id="caption-text-input"
                  value={this.state.caption!}
                  floatingLabelText="Caption Text"
                  ref={this.captionRef}
                />
              ) : undefined}
            </div>
          </div>

          <DataAttributesPropsWrapper inline={true} {...this.props} />

          <div className="selected-unit-props-section image-edit">
            <div className="selected-unit-props-subsection">
              <Dimension
                onChange={(e) => this._onDimensionWidthChange(e)}
                type="width"
                title="Width"
                selectUnit={this.state.imageWidth ? this.state.imageWidth.unit : '%'}
                value={this.state.imageWidth ? this.state.imageWidth.value! : undefined}
                units={[
                  { payload: 'px', text: 'px', min: 1, max: 10000 },
                  { payload: '%', text: '%', min: 1, max: 100 }
                ]}
              />

              <Dimension
                onChange={(e) => this._onDimensionMaxWidthChange(e)}
                type="maxWidth"
                title="Max Width"
                selectUnit={this.state.imageMaxWidth ? this.state.imageMaxWidth.unit : 'px'}
                value={this.state.imageMaxWidth ? this.state.imageMaxWidth.value! : undefined}
                units={[
                  { payload: 'px', text: 'px', min: 1, max: 10000 },
                  { payload: '%', text: '%', min: 1, max: 100 }
                ]}
              />

              <Alignment title="Horizontal graphic align" onChange={(e) => this._onAlignmentChange(e)} align={this.state.align} />

              <Border
                title="Graphic Border"
                onBorderChange={(e) => this._onBorderChange(e)}
                onBorderStyleChange={(e) => this._onBorderStyleChange(e)}
                border={this.state.border}
                borderStyle={this.state.borderStyle}
              />

              <hr className="light" />

              <div className="manip-group">
                <h6>Manage Hotspots</h6>

                <ul className="icon-action-buttons">
                  <li key={'hotspot'} data-action={'hotspot'} onClick={() => this._handleHotspotClick()}>
                    <IconButton
                      id={'hotspot-modal-button'}
                      iconClassName={'material-icons icon-hotspot'}
                      tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                      tooltipPosition="bottom-left"
                      tooltip={'hotspot'}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {UnitUtils.isFormatingAvailable(this.props.unitElement) && (
            <MultiFormatting formatNode={this.props.index === 0 ? null : this.props.unitElement} unitProfile={this.props.unitProfile} />
          )}
        </ToggleSection>
      </div>
    );
  }
}
