import React, { forwardRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const withRouterForwardRef = (Component) => {
  const WithRouter = withRouter(
    ({
      forwardedRef,
      ...props
    }: RouteComponentProps & { forwardedRef: ((instance: unknown) => void) | React.MutableRefObject<unknown> | null } & {
      children?: React.ReactNode;
    }) => {
      return <Component ref={forwardedRef} {...props} />;
    }
  );

  return forwardRef((props: any, ref) => {
    return <WithRouter {...props} forwardedRef={ref} />;
  });
};
export default withRouterForwardRef;
