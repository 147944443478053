/* FRAMEWORK */
import * as React from 'react';
import * as _ from 'lodash';
import { groupBy, forEach } from 'lodash';

/* UTILS */
import StringUtil from '../../../../../utils/StringUtil';
import alphaNumSort from '../../../../../utils/alphaNumSort';
import { hideDisabledMenuItems } from '../utils/utils';

/* TYPES */
import { IUnitDefinition, IElementDefinition } from 'mm-types';
import { Menu, SubMenu } from '../../EditorMenu';
import { DefinitionToMenuPropsType } from '../MenuInsert';

interface Props {
  disabled: boolean;
  action: 'insert' | 'convert';
  definitions: Definition[];
  definitionToMenuItem: (definition: Definition, definitionToMenuProps: Partial<DefinitionToMenuPropsType>) => JSX.Element;
  disableContentMenu: boolean;
  handleDropdownClick(e: { key: string; currentTarget: Element }, definitionToMenuProps: Partial<DefinitionToMenuPropsType>): void;
  syncOpenKeysStructure(openKeys: any): void;
  hoverOff?(): void;
  openKeysStructure: any[];
  isHovered?: boolean;
  definitionToMenuProps: Partial<DefinitionToMenuPropsType>;
}

export type State = {
  hideDisabled: boolean;
  open: boolean;
};

type Definition = IUnitDefinition | IElementDefinition;

class ContentDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hideDisabled: true,
      open: false
    };
    this.onToggleChange = this.onToggleChange.bind(this);
  }

  getOpenKeysStructure() {
    if (this.props.isHovered) {
      const result = _.clone(this.props.openKeysStructure);

      // make sure first item in the array is "content-menu
      if (result.length === 0) {
        result.push('content-menu');
      } else if (result[0] !== 'content-menu') {
        result.unshift('content-menu');
      }

      return result;
    }

    return this.props.openKeysStructure;
  }

  handleDropdownClick(e: { key: string; currentTarget: Element }) {
    this.props.handleDropdownClick(e, this.props.definitionToMenuProps);
    this.props.hoverOff && this.props.hoverOff();
  }

  onToggleChange(open: boolean) {
    this.setState({
      open
    });
  }

  private renderSubMenu(definitions: Definition[], title: string, disabled: boolean): JSX.Element {
    let menuItems: JSX.Element[] = definitions
      .sort((a, b) => {
        return alphaNumSort(a['displayName'], b['displayName']);
      })
      .map((definition) => this.props.definitionToMenuItem(definition, this.props.definitionToMenuProps));
    const isEnabled: boolean = menuItems && menuItems.findIndex((menuItem) => !menuItem.props.disabled) > -1;

    if (this.state.hideDisabled) {
      menuItems = menuItems.filter((item) => {
        return item.props.disabled === false;
      });
    }

    // subMenu is not disabled if at least one child is not disabled
    return (
      <SubMenu
        key={title}
        value={title}
        title={StringUtil.formatForScreen(title)}
        disabled={disabled}
        className={`submenu-${title.replace(' ', '')}` + (!isEnabled ? ' submenu-show-as-disabled' : '')}
      >
        {menuItems}
      </SubMenu>
    );
  }

  private buildMenu(items, menuItems): JSX.Element[] {
    forEach(items, (list: Definition[], unitType: string) => {
      if (list.length === 1) {
        const definition = list[0];
        menuItems.push(this.props.definitionToMenuItem(definition, this.props.definitionToMenuProps));
      } else {
        const label = list[0].hasOwnProperty('displayGroup') && list[0].type !== 'tocable' ? list[0].displayGroup! : unitType;
        menuItems.push(this.renderSubMenu(list, label, this.props.disableContentMenu));
      }
    });
    return menuItems;
  }

  private removeFamilyLevelWithNoSubtype(unitDefinitionsByLevel): { [index: string]: Definition[] } {
    Object.keys(unitDefinitionsByLevel).forEach((level) => {
      if (unitDefinitionsByLevel[level].length <= 1) {
        delete unitDefinitionsByLevel[level];
      }
    });
    return unitDefinitionsByLevel;
  }

  private renderProjectDefinedMode(definition: Definition[], unitType = '') {
    const nonTocDefsGroup = definition.filter((def) => def.type !== 'tocable' && def.hasOwnProperty('displayGroup'));
    const nonTocDefs = definition.filter((def) => def.type !== 'tocable' && !def.hasOwnProperty('displayGroup'));
    const tocDefs = definition.filter((def) => def.type === 'tocable');

    let unitDefinitionsByLevel: { [index: string]: Definition[] } = groupBy(tocDefs, 'level');
    unitDefinitionsByLevel = this.removeFamilyLevelWithNoSubtype(unitDefinitionsByLevel);

    const unitDefinitionsByType: { [index: string]: Definition[] } = groupBy(nonTocDefs, 'type');
    const unitDefinitionsByGroup: { [index: string]: Definition[] } = groupBy(nonTocDefsGroup, 'displayGroup');

    const newUnitDefs = _.mergeWith(unitDefinitionsByType, unitDefinitionsByGroup, (src, dest) => {
      if (src && dest) {
        return _.concat(src, dest);
      }
      return src ? src : dest;
    });

    let menuItems: JSX.Element[] = [];

    menuItems = this.buildMenu(unitDefinitionsByLevel, menuItems);
    menuItems = this.buildMenu(newUnitDefs, menuItems);

    if (this.state.hideDisabled || this.props.disableContentMenu) {
      menuItems = hideDisabledMenuItems(menuItems, this.props.action === 'insert' ? 'No Valid Inserts' : 'No Valid Converts');
    }

    menuItems = menuItems.sort((a, b) => {
      // subMenu or item handling
      const aValue = a.props.title || a.props.children;
      const bValue = b.props.title || b.props.children;
      return alphaNumSort(aValue, bValue);
    });

    return (
      <SubMenu
        key="content-menu"
        value="content-menu"
        className={`content-element insert-${unitType}`}
        disabled={this.props.disableContentMenu}
      >
        {menuItems}
      </SubMenu>
    );
  }

  private handleTopMenuClick() {
    this.setState({ hideDisabled: !this.state.hideDisabled });
  }

  render() {
    return (
      <Menu
        disabled={this.props.disabled}
        onClick={(e) => this.handleDropdownClick(e)}
        onOpenChange={this.props.syncOpenKeysStructure}
        openKeys={this.getOpenKeysStructure()}
        onButtonClick={() => this.handleTopMenuClick()}
        onToggleChange={this.onToggleChange}
        title={this.props.action === 'insert' ? 'Content' : 'Format element'}
        hideDisabled={this.state.hideDisabled}
      >
        {this.state.open && this.renderProjectDefinedMode(this.props.definitions)}
      </Menu>
    );
  }
}

export default ContentDropdown;
