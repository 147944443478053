import * as Reflux from 'reflux';
import * as clients from './../../clients/spell-check';
import EditorStore from './EditorStore';
import TocStore from './TocStore';
import Store from '../Store';
import { ISpellcheckHit } from 'mm-types';
import { fetchWords } from '../../utils/wordlist';

export type SpellCheckStoreEvent = State;

const SELECTOR = '.arc-mispelled-word';

export type Word = {
  globalOccurrence: number;
  word: string;
  globalIndex: number;
  parent: string;
  indexWithinParent: number;
  ignored: boolean;
};

export type State = {
  initialWordList: null | Word[];
  filteredWordList: Word[];
  ignoredWords: Word[];
  ignoredGlobals: string[];
  current: number;
  suggestions: null | string[];
  replacing?: boolean;
  nextWord?: Word;
  loading: boolean;
};

export class SpellCheckStore extends Store<State> {
  constructor() {
    super();

    this.state = this.initialState();
  }

  executeSpellcheck() {
    const selectedTocItem = TocStore.getFirstSelectedTocableUnit()!;
    const indexUid = EditorStore.getDocParams().indexUid;
    if (!!indexUid) {
      clients.findMisspells(indexUid, selectedTocItem.uid).then((r: ISpellcheckHit[]) => {
        // preserviing ignoredWords, will be updated later
        this.state.initialWordList = [];
        this.state.current = 0;
        this.state.suggestions = null;
        this.state.filteredWordList = [];

        r.forEach((spellcheckHit, index) => {
          const obj = {
            globalIndex: index,
            word: spellcheckHit.word,
            parent: spellcheckHit.unitUid,
            indexWithinParent: spellcheckHit.replacementIndex,
            globalOccurrence: 0,
            ignored: false
          };
          this.state.initialWordList!.push(obj);
        });

        this.updateIgnored();
        this.state.filteredWordList = this.calculateFilteredWordList();
        this._triggerIndexUpdate();
      });
    }
  }

  updateIgnored() {
    // rebuild ignoreword list
    if (this.state.initialWordList && this.state.initialWordList.length > 0) {
      this.state.ignoredWords.forEach((ignored, index) => {
        const found = this.state.initialWordList?.find(
          (word) => word.parent === ignored.parent && word.word === ignored.word && word.indexWithinParent === ignored.indexWithinParent
        );
        if (!!found) {
          ignored.globalIndex = found.globalIndex;
          ignored.globalOccurrence = found.globalOccurrence;
        } else {
          this.state.ignoredWords.splice(index);
        }
      });
    }
  }

  highlightIgnored(jquerySelector, words: Word[]) {
    $('.editing-stage-page-inner ' + jquerySelector).removeClass('ignored');
    words.forEach((w) => {
      const sel = `.editing-stage-page-inner #_${w.parent} ${SELECTOR}:eq(${w.indexWithinParent})`;
      $(sel).addClass('ignored').off('click');
    });
  }

  highlight(jquerySelector, word: Word) {
    $('.editing-stage-page-inner ' + jquerySelector).removeClass('highlight');
    const sel = `.editing-stage-page-inner #_${word.parent} ${SELECTOR}:eq(${word.indexWithinParent})`;
    $(sel).addClass('highlight');
  }

  initialState(): State {
    return {
      loading: false,
      initialWordList: null, // the wordList of all the remaining words
      filteredWordList: [],
      ignoredWords: [],
      ignoredGlobals: [],
      current: 0, // the index of the current word in the *filtered* wordList
      suggestions: null // suggestions for the current word
    };
  }

  getState() {
    return {
      wordList: this.state.filteredWordList,
      word: this.currentWord() ? this.currentWord().word : '',
      suggestions: this.state.suggestions,
      loading: this.state.loading
    };
  }

  onHighlightTocItem() {
    this._reset();
  }

  selectTocItem() {
    this._reset();
  }

  docUnitsRenderUpdateComplete() {
    if (!EditorStore.isMode('SPELLCHECK')) {
      return;
    }

    this.state.initialWordList = fetchWords(SELECTOR, this.onClickWord.bind(this));
    this.updateIgnored();
    this.state.filteredWordList = this.calculateFilteredWordList();

    if (this.currentWord()) {
      this.highlight(SELECTOR, this.currentWord());
    }

    if (this.state.nextWord) {
      const wordToRefind = this.state.nextWord;
      const i = this.state.filteredWordList.findIndex((w) => w.globalIndex === wordToRefind.globalIndex);

      if (i >= 0) {
        this.state.current = i;
      } else {
        this.state.current = 0;
      }
    }

    this._triggerIndexUpdate();
  }

  calculateFilteredWordList() {
    if (!this.state.initialWordList) {
      return [];
    }

    this.state.initialWordList.forEach((word) => {
      // check if a given word is globally ingored or individually
      word.ignored =
        this.state.ignoredGlobals.indexOf(word.word) > -1 ||
        this.state.ignoredWords.findIndex((ignoredWord) => ignoredWord.globalIndex === word.globalIndex) > -1;
    });

    const ignoredWords = this.state.initialWordList.filter((word) => word.ignored);
    this.highlightIgnored(SELECTOR, ignoredWords);

    return this.state.initialWordList.filter((word) => !word.ignored);
  }

  onClickWord(word: Word, e: Event) {
    const index = this.state.filteredWordList.findIndex((w) => w.globalIndex === word.globalIndex);
    if (index >= 0) {
      this.state.current = index;
      this._triggerIndexUpdate();
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  nextSpellWord() {
    this.state.current++;
    this._triggerIndexUpdate(true);
  }

  previousSpellWord() {
    this.state.current--;
    this._triggerIndexUpdate(true);
  }

  ignoreWord() {
    this.state.ignoredWords.push(this.currentWord());
    this.state.filteredWordList = this.calculateFilteredWordList();
    this._triggerIndexUpdate(true);
  }

  ignoreAll() {
    this.state.ignoredGlobals.push(this.currentWord().word);
    this.state.filteredWordList = this.calculateFilteredWordList();
    this._triggerIndexUpdate(true);
  }

  getCurrent() {
    return this.state.current || 0;
  }

  async correctWord(word: string) {
    this.state.replacing = true;
    this.state.loading = true;

    this.trigger(this.state);

    await EditorStore.backendReplace({
      replaceChildren: false,
      wholeWord: true,
      spellCheck: true,
      matchCase: true,
      unitUid: this.currentWord().parent,
      index: this.currentWord().indexWithinParent,
      replacement: word,
      target: this.currentWord().word
    });

    this.state.replacing = false;
    this.state.loading = false;
    this.state.suggestions = [];
    this.trigger(this.state);
    this.state.nextWord = this.state.filteredWordList[this.state.current];
  }

  async correctAll(word: string, global = false) {
    this.state.replacing = true;
    this.state.loading = true;
    this.trigger(this.state);

    const options: Partial<clients.ReplaceOptions> = {
      replaceChildren: true,
      wholeWord: true,
      spellCheck: true,
      matchCase: false,
      preserveCase: true,
      unitUid: TocStore.getFirstSelectedTocableUnit()!.uid as string | null,
      replacement: word,
      target: this.currentWord().word
    };

    if (global) {
      options['replaceChildren'] = false;
      options['replaceAll'] = true;
      options['unitUid'] = null;
    }

    await EditorStore.backendReplace(options);
    this.state.replacing = false;
    // this.state.loading = false;
    this.state.suggestions = [];
    this.trigger(this.state);

    this.state.nextWord = this.state.filteredWordList[this.state.current];
  }

  clear() {
    this.state = this.initialState();
  }

  currentWord() {
    return this.state.filteredWordList[this.state.current];
  }

  hasWords() {
    return this.state.filteredWordList.length > 0;
  }

  // private helpers

  _reset() {
    if (EditorStore.isMode('SPELLCHECK')) {
      this.state.initialWordList = [];
      this.state.current = 0;
      this.state.suggestions = null;
      this.state.filteredWordList = [];
      this.state.ignoredWords = [];
    }
  }

  _triggerNonToggle() {
    this.trigger(this.getState());
  }

  private async _triggerIndexUpdate(scroll = false) {
    if (this.state.filteredWordList.length === 0 || !EditorStore.isMode('SPELLCHECK')) {
      this.trigger({});
      return;
    }

    if (this.state.current >= this.state.filteredWordList.length) {
      // past the end start back at 0
      this.state.current = 0;
    }
    if (this.state.current < 0) {
      // before the start roll back to last.
      this.state.current = this.state.filteredWordList.length - 1;
    }

    this.state.loading = true;

    this._triggerNonToggle();

    const suggestions = await clients.getSuggestions(encodeURI(this.currentWord().word.replace(/\//g, '%2F')));
    this.state.suggestions = suggestions;
    this.state.loading = false;
    this._triggerNonToggle();

    if (scroll) {
      EditorStore.openDocument({ uid: this.currentWord().parent });
    }
    this.highlight(SELECTOR, this.currentWord());
  }
}

const singleton = Reflux.initStore<SpellCheckStore>(SpellCheckStore);
export default singleton;
