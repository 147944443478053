import { CustomTinyEventTypes } from '../CustomTinyEventTypes';
import MetaTags from '../../units/MetaTags';
import Log from '../../../../../utils/Log';
import * as keyIdentifier from '../../keyIdentifier';
import * as CONSTANTS from '../../keyIdentifier';
import { isDeleteKeys, isEnterKey, isTabKey } from '../../keyIdentifier';
import * as _ from 'lodash';
import { CommonKeyListeners } from './CommonKeyListeners';
import TableUtils from '../../../../../utils/TableUtils';
import { CustomEditor } from '../EditorInstanceManager';
import { IElementProfile, IUnit } from 'mm-types';
import { isEmptyOfElements, isSelectedRangeReplaceAction } from '../TinyFacadeHelpers';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../../flux/editor/EditorStore';
import { ActionEvent } from '../../../docUnit/DocUnit';
import { ElementDetails } from '../../units/ElementDetails';
import { ListItemKeyBehavior } from '../../units/unit/list-item/keyBehavior';
import { UnitTypes } from '../../units/UnitTypes';
import {
  BLANK_CHAR,
  handleListDemotion,
  handleListNodeParaBehaviour,
  handleListPromotion,
  handleTableNodeParagraphBehaviour,
  hasNoSiblingsAfter,
  hasNoText,
  insertPara,
  isCursorAtFirstColumn,
  isDateInput,
  isDIVNode,
  isEqualLeftNode,
  isEqualRightNode,
  isFirstChild,
  isImmediateParentAUnit,
  isInlineDataEnum,
  isLINode,
  isListNode,
  isNextFocusNodeEcam,
  isNextFocusNodeInline,
  isSameNodeSelection,
  isSelectionOutsideParagraph,
  isTableNode,
  isTableRefIntInSelectedNode,
  isTextNode,
  isWholeArcUnitTextSelected,
  isWholeNodeSelected,
  manageInlineLeftArrowKey,
  manageInlineRightArrowKey,
  removeLastLI,
  removeSelectedStructure,
  replaceZeroLengthZeroWidthCharacters,
  setContent,
  tableCellTabBehaviour,
  ZERO_LENGTH_WORD_JOINER,
  ZERO_WIDTH_NO_BREAK_SPACE
} from './keyBehaviourUtils';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

const manageEqualLeftKeys = function (editor: CustomEditor, e): boolean {
  const { focusNode } = editor.selection.getSel();
  if (!focusNode) {
    return false;
  }

  if (keyIdentifier.isTabKey(e)) {
    const parentNode: Node | null | undefined = focusNode?.parentNode?.parentNode;
    if (!!parentNode) {
      const right: JQuery<HTMLElement> = $(parentNode).find("[data-subtype='rightEql'] p");
      if (!!right) {
        editor.selection.setCursorLocation(right.get(0), 0);
      }
    }
    keyIdentifier.nukePropagation(e);
  } else if (keyIdentifier.isShiftTabKeys(e)) {
    keyIdentifier.nukePropagation(e);
  }

  return false;
};

const manageEqualRightKeys = function (editor: CustomEditor, e): boolean {
  const { focusNode } = editor.selection.getSel();
  if (!focusNode) {
    return false;
  }
  const { startOffset } = editor.selection.getRng(true);
  if (keyIdentifier.isBackspaceKey(e) && startOffset === 0) {
    const { endOffset } = editor.selection.getRng(true);
    // if cursor is positioned at the first character and there's no text selected
    if (startOffset === 0 && startOffset === endOffset) {
      keyIdentifier.nukePropagation(e);
    }
  } else if (keyIdentifier.isShiftTabKeys(e)) {
    const parentNode: Node | null | undefined = focusNode?.parentNode?.parentNode?.parentNode;
    if (parentNode) {
      const left: JQuery<HTMLElement> = $(parentNode).find("[data-subtype='leftEql']");
      if (!!left) {
        editor.selection.setCursorLocation(left.get(0), 0);
      }
    }
    keyIdentifier.nukePropagation(e);
  } else if (keyIdentifier.isTabKey(e)) {
    keyIdentifier.nukePropagation(e);
  }
  return false;
};

export const manageParagraphKeys = function (editor: CustomEditor, e: React.KeyboardEvent) {
  const selectedNode = editor.selection ? (editor.selection.getNode() as HTMLElement) : null;
  const { parent } = ElementDetails.getFocusedDetails();

  if (selectedNode) {
    if (isEqualLeftNode(selectedNode)) {
      return manageEqualLeftKeys(editor, e);
    } else if (isEqualRightNode(selectedNode)) {
      return manageEqualRightKeys(editor, e);
    } else if (isDateInput(editor?.selection?.getNode() as HTMLElement)) {
      return disableDateInlineKeys(editor, e);
    }
    if (isNextFocusNodeInline(editor, e) && !isNextFocusNodeEcam(editor, e)) {
      return manageInlineKeys(editor, e);
    }
    if (isTableRefIntInSelectedNode(editor, e)) {
      return manageTableRefIntKeys(editor);
    }
    if (parent && ElementDetails.isOfType(parent, 'list-item')) {
      handleListNodeParaBehaviour(e, selectedNode, editor);
    } else if (isTableNode(parent)) {
      return handleTableNodeParagraphBehaviour(e, selectedNode);
    }

    ListItemKeyBehavior.placeCursorInsideNewListItem(e);

    const { anchorNode, focusNode } = editor.selection.getSel();
    if (isSelectedRangeReplaceAction(editor, e) && !isSameNodeSelection(anchorNode, focusNode) && !isWholeNodeSelected(editor)) {
      editor.undoManager.transact(() => {
        removeSelectedStructure(editor, e);
      });
      keyIdentifier.nukePropagation(e);
      return true;
    }
    if (isWholeNodeSelected(editor)) {
      if (isWholeArcUnitTextSelected(editor)) {
        if (keyIdentifier.isSingleKey(e) || keyIdentifier.isDeleteKeys(e) || keyIdentifier.isPasteKeys(e)) {
          editor.undoManager.transact(() => {
            setContent(editor, e);
            const { startContainer } = editor.selection.getRng(true);
            // remove extra li at the end if present
            removeLastLI(startContainer);
            editor.selection.setCursorLocation(startContainer, 0);
          });
        }
      } else if (keyIdentifier.isDeleteKeys(e)) {
        const { startContainer, endContainer } = editor.selection.getRng(true);
        let selectedContainer;
        if (isTextNode(startContainer)) {
          selectedContainer = startContainer;
        } else if (isTextNode(endContainer)) {
          selectedContainer = endContainer;
        }
        if (selectedContainer) {
          editor.undoManager.transact(() => {
            selectedContainer.parentElement.innerText = ZERO_LENGTH_WORD_JOINER;
            editor.selection.setCursorLocation(selectedContainer, 0);
          });
          keyIdentifier.nukePropagation(e);
          return true;
        }
      }
    }
  }
  return false;
};

export const manageListKeysOnKeyUp = function (editor, e) {
  const originalSelectedNode = editor.selection ? editor.selection.getNode() : null;
  if (originalSelectedNode) {
    const selectedNode = $(originalSelectedNode)!;
    if (selectedNode[0] && selectedNode[0].firstChild) {
      // Check if the list's first child isn't an LI
      const firstChildNodeName = selectedNode[0].firstChild;
      if (isListNode(selectedNode) && !isLINode(firstChildNodeName)) {
        // Wrap existing content (text) into an LI
        const selectedNodeElem = $(selectedNode[0]);
        const content = selectedNodeElem.text();

        selectedNodeElem.html(BLANK_CHAR);
        const liElem = editor.dom.add(editor.getBody(), 'li', {}, content);

        if (content) {
          editor.selection.setCursorLocation(liElem, 1);
        }
      }
    }
  }
};

export const manageListKeys = function (editor, e) {
  const originalSelectedNode: HTMLElement | null = editor.selection ? editor.selection.getNode() : null;

  if (originalSelectedNode) {
    const $selectedNode = MetaTags.getActualElementAmended($(originalSelectedNode), ['p']);

    const rng: Range = editor.selection.getRng(true);
    const isLiTriple: boolean =
      rng.startOffset === 0 && (rng.endOffset === 0 || rng.endOffset === rng.startContainer.textContent!.length) && !rng.collapsed;

    if (isLiTriple && (keyIdentifier.isDeleteKeys(e) || keyIdentifier.isShiftBackspaceKeys(e))) {
      keyIdentifier.nukePropagation(e);
      if (rng.startContainer && rng.startContainer.parentNode && rng.startContainer.parentNode.nodeName === 'LI') {
        rng.startContainer.parentElement!.remove();
      }
      return true;
    } else if (isLINode($selectedNode)) {
      if (isImmediateParentAUnit($selectedNode) && isFirstChild($selectedNode)) {
        if (keyIdentifier.isShiftBackspaceKeys(e) && isCursorAtFirstColumn(editor)) {
          keyIdentifier.nukePropagation(e);
          editor.fire('ARC_MERGE_TO_PREVIOUS' as CustomTinyEventTypes, { blur: true });
          return true;
        } else if (keyIdentifier.isDeleteKeys(e)) {
          if (hasNoText($selectedNode)) {
            if (hasNoSiblingsAfter($selectedNode) && isEmptyOfElements($selectedNode.contents())) {
              keyIdentifier.nukePropagation(e);
              editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
              return true;
            } else {
              keyIdentifier.nukePropagation(e);
              $selectedNode.remove();
              return true;
            }
          }
        }
      }

      if (originalSelectedNode.nodeName === 'P' && isSelectionOutsideParagraph(editor)) {
        const { length } = originalSelectedNode.childNodes;
        if (length) {
          const childNode = originalSelectedNode.childNodes[length - 1];
          const childNodeTextLength = childNode.textContent!.length;
          setTimeout(() => {
            editor.selection.setCursorLocation(childNode, childNodeTextLength);
          }, 0);
        }
      } else if (keyIdentifier.isNoShiftTabKey(e)) {
        if (handleListDemotion(editor, $selectedNode)) {
          keyIdentifier.nukePropagation(e);
          return true;
        }
      } else if (keyIdentifier.isShiftTabKeys(e)) {
        if (handleListPromotion(editor, $selectedNode)) {
          keyIdentifier.nukePropagation(e);
          return true;
        }
      } else if (originalSelectedNode.nodeName !== 'P' && keyIdentifier.isSingleKey(e) && isEmptyOfElements($selectedNode.contents())) {
        insertPara(editor, originalSelectedNode);
      }
    } else if (
      isDIVNode($selectedNode) &&
      !editor.selection.getRng().collapsed &&
      ['arc-warning-body', 'arc-note-body', 'arc-caution-body'].indexOf($selectedNode[0].className) !== -1 &&
      editor.selection.getRng().commonAncestorContainer.length &&
      editor.selection.getRng().commonAncestorContainer.length ===
        editor.selection.getRng().endOffset - editor.selection.getRng().startOffset
    ) {
      // empty content body for nested note, caution or warning
      originalSelectedNode.textContent = ZERO_LENGTH_WORD_JOINER;
    }
  }
  return false;
};

// note: covers P and Admonitions, ul/ol/table is more complex, handled in their custom methods
export const mergeUnitWithShiftBackspace = function (editor, e) {
  if (keyIdentifier.isShiftBackspaceKeys(e)) {
    // on first column
    if (editor.selection && editor.selection.getRng().startOffset === 0) {
      // on first line
      const currentNode = editor.selection.getNode();
      const currentRange = editor.selection.getRng();
      const firstLineNode = $(editor.getElement()).contents()[0];
      const firstLineTextNodes: Node[] = [];

      if (firstLineNode.nodeType === 3) {
        firstLineTextNodes.push(firstLineNode);
      } else {
        for (const i in firstLineNode.childNodes) {
          if (firstLineNode.childNodes[i].nodeType === 3) {
            firstLineTextNodes.push(firstLineNode.childNodes[i]);
          }
        }
      }

      const preRange = document.createRange();
      preRange.selectNodeContents(currentNode);
      preRange.setEnd(currentRange.startContainer, currentRange.startOffset);

      let isPreRangeOnFirstLine = false;

      firstLineTextNodes.forEach((firstLineTextNode) => {
        if (preRange.endContainer === firstLineTextNode) {
          isPreRangeOnFirstLine = true;
        }
      });

      if (isPreRangeOnFirstLine) {
        keyIdentifier.nukePropagation(e);
        editor.fire('ARC_MERGE_TO_PREVIOUS' as CustomTinyEventTypes, { blur: true });
      }
    }
  }
  return false;
};

export const deleteRootUnitWithDelKeys = function (editor, e) {
  if (keyIdentifier.isDeleteKeys(e)) {
    const textContent = $.trim(editor.getContent({ format: 'text' }));

    if (textContent.length === 0 && isEmptyOfElements($(editor.bodyElement).contents())) {
      keyIdentifier.nukePropagation(e);
      editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
      return true;
    }
  }

  return false;
};

export const manageInlineKeys = function (editor: CustomEditor, e, isCollection = false, structureDepth = 1) {
  if (editor.selection) {
    if (keyIdentifier.isLeftArrowKey(e)) {
      manageInlineLeftArrowKey(editor, e, isCollection, structureDepth);
    } else if (keyIdentifier.isRightArrowKey(e)) {
      manageInlineRightArrowKey(editor, e, isCollection, structureDepth);
    }
    if (keyIdentifier.isBackspaceKey(e)) {
      const { focusNode } = editor.selection.getSel();
      const rng = editor.selection?.getRng(true);
      const offset = rng.startOffset;
      if (offset == 1 && (focusNode?.previousSibling as Element)?.classList?.contains('nested-wc')) {
        keyIdentifier.nukePropagation(e);
      }
    }
  }
};

export const manageSummaryItemInlineKeys = function (editor, e) {
  if (editor.selection) {
    const node = editor.selection.getNode();
    if (keyIdentifier.isRightArrowKey(e)) {
      let sel = editor.selection.getSel();
      let caretPos = sel.anchorOffset;
      let txtData = sel.anchorNode.data;
      if (txtData.length - 1 < caretPos) {
        keyIdentifier.nukePropagation(e);
      }
    }
    if (node.classList.contains('arc-transient')) {
      if (
        !keyIdentifier.isRightArrowKey(e) &&
        !keyIdentifier.isLeftArrowKey(e) &&
        !keyIdentifier.isDownArrowKey(e) &&
        !keyIdentifier.isUpArrowKey(e)
      ) {
        keyIdentifier.nukePropagation(e);
      }
    }
  }
};

export const disableDateInlineKeys = function (editor, e) {
  if (editor.selection) {
    if (
      !keyIdentifier.isRightArrowKey(e) &&
      !keyIdentifier.isLeftArrowKey(e) &&
      !keyIdentifier.isDownArrowKey(e) &&
      !keyIdentifier.isUpArrowKey(e)
    ) {
      keyIdentifier.nukePropagation(e);
    }
  }
};

export const manageLinkKeys = function (editor, e) {
  if (keyIdentifier.isDeleteKeys(e)) {
    let selectedNode = editor.selection ? editor.selection.getNode() : null;
    selectedNode = MetaTags.getActualElement($(selectedNode))[0];
    if (selectedNode.nodeName === 'A') {
      editor.dom.remove(selectedNode);
      keyIdentifier.nukePropagation(e);
      return true;
    }
  } else {
    return false;
  }
};

export const manageDuRefLinkKeys = function (editor: CustomEditor, e) {
  const selectedNode = editor.selection ? editor.selection.getNode() : null;
  if (
    selectedNode?.classList.contains('arc-link') &&
    selectedNode?.attributes['data-element-definition-id'].value == 'DuRef' &&
    selectedNode?.attributes['data-link-text-managed'].value === 'full' &&
    (isDeleteKeys(e) || e.key.length === 1)
  ) {
    selectedNode.attributes['data-link-text-managed'].value = 'none';
  }
};

export const manageVideoKeys = function (editor: CustomEditor, e) {
  if (keyIdentifier.isDeleteKeys(e) && editor.selection) {
    let selectedNode: Element = editor.selection.getNode();
    selectedNode = MetaTags.getActualElement($(selectedNode))[0];

    if (
      selectedNode &&
      selectedNode.parentElement &&
      selectedNode.parentElement.classList.contains('arc-unit') &&
      (selectedNode.nodeName === 'FIGURE' || selectedNode.nodeName === 'VIDEO')
    ) {
      // delete the video: delete the unit!!!
      editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
    } else {
      return false;
    }
  } else {
    CommonKeyListeners.detectReturnAndSaveExitKey(editor, e);
  }

  keyIdentifier.nukePropagation(e);
  return false;
};

export const manageChapterKeys = function (editor, e) {
  return CommonKeyListeners.detectReturnAndSaveExitKey(editor, e);
};

export const handleShortcutKey = function (
  e: React.KeyboardEvent,
  action: ActionEvent['action'],
  unit: IUnit | INestedUnitFocusChangeEvent
) {
  this.handleInlineAction({
    action,
    src: 'shortcutKeys',
    data: { unit }
  });
  e.stopPropagation();
  return false;
};

export const handleInlineAction = function (e: ActionEvent) {
  EditorStore.triggerInlineUnitAction(e);
};

export const manageGraphicKeys = function (editor: CustomEditor, e) {
  const unit = EditorStore.getLastFocusedNestedUnit();
  if (keyIdentifier.isCopyKeys(e) && unit) {
    return this.handleShortcutKey(e, 'copy-docunit', unit);
  } else if (keyIdentifier.isDeleteKeys(e) || (keyIdentifier.isCutKeys(e) && editor.selection)) {
    let selectedNode: Element = editor.selection.getNode();

    selectedNode = MetaTags.getActualElement($(selectedNode))[0];

    if (
      selectedNode &&
      selectedNode.parentElement &&
      selectedNode.parentElement.classList.contains('arc-unit') &&
      (selectedNode.nodeName === 'FIGURE' || selectedNode.nodeName === 'IMG')
    ) {
      // delete the graphic: delete the unit!!!
      editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
    } else {
      return false;
    }
  } else {
    CommonKeyListeners.detectReturnAndSaveExitKey(editor, e);
  }

  keyIdentifier.nukePropagation(e);
  return false;
};

export const manageCollectionKeys = function (editor, e) {
  const selectedNode = editor.selection ? (editor.selection.getNode() as HTMLElement) : null;

  if (selectedNode) {
    const $selectedNode = $(selectedNode);
    const isNotActionOrEcamOrApproved =
      _.indexOf(
        ['Action', 'Challenge', 'ChallengeResponse', 'ChallengeResponseMessage'],
        $selectedNode.closest('[data-element-family]').attr('data-element-family')
      ) === -1 &&
      _.indexOf(
        ['EcamData', 'EcamSys', 'EcamTitle', 'EcamSubtitle'],
        $selectedNode.closest('[data-element-definition-id]').attr('data-element-definition-id')
      ) === -1 &&
      _.indexOf(
        ['ApprovedBy', 'Name', 'JobTitle', 'ApprovalComment'],
        $selectedNode.closest('[data-element-definition-id]').attr('data-element-definition-id')
      ) === -1;
    if (isNotActionOrEcamOrApproved) {
      if (isEmptyOfElements($selectedNode.contents())) {
        insertPara(editor, selectedNode);
      }
      if (keyIdentifier.isSelectAllKeys(e)) {
        // this is usually the case when all text is selected
        const firstCell = $selectedNode.find('.edit-target')[0];
        editor.selection.setCursorLocation(firstCell, 0);
        return true;
      } else if (e.keyCode === CONSTANTS.KeyCode.v && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        // we might in the future handle this situation, leaving it for now.
      }
    }
  }
};

export const manageTableKeys = function (editor: CustomEditor, e: React.KeyboardEvent) {
  const selectedNode = editor.selection?.getNode() as HTMLElement;
  const isNested = EditorStore.isNestedUnitFocused();
  const nestedUnit = EditorStore.getLastFocusedNestedUnit()!;

  if (selectedNode && getDataElementDefinitionId(selectedNode) !== 'NonEditableCell') {
    // prevent a paste outside of a table (overcomes tinymce issue where cursor can be placed outside of a table unit)
    if (keyIdentifier.isPasteKeys(e)) {
      if (
        selectedNode.classList.contains('document-unit-outer') ||
        selectedNode.classList.contains('arc-unit') ||
        selectedNode.classList.contains('mce-edit-focus')
      ) {
        keyIdentifier.nukePropagation(e);
        return true;
      }
    } else if (keyIdentifier.isShiftBackspaceKeys(e)) {
      // first col in first line
      if (editor.selection && editor.selection.getRng(false).startOffset === 0) {
        let colNode = editor.selection.getNode() as HTMLElement;

        if (colNode.nodeName === 'P') {
          colNode = colNode.parentNode as HTMLElement;
        }

        if (colNode.nodeName === 'TD') {
          if (editor.dom.is(colNode, 'td:first-child') && editor.dom.is(colNode.parentNode as HTMLElement, 'tr:first-child')) {
            keyIdentifier.nukePropagation(e);
            editor.fire('ARC_MERGE_TO_PREVIOUS' as CustomTinyEventTypes, { blur: true });
            return true;
          }
        }
      }
    } else if (isTabKey(e)) {
      tableCellTabBehaviour(selectedNode, e, EditorStore.getEditor().getActiveEditorFacade());
    } else if (selectedNode.nodeName === 'TD' && !keyIdentifier.isDeleteKeys(e)) {
      const $selectedNode = $(selectedNode);
      if (isEmptyOfElements($selectedNode.contents()) && e.key) {
        $selectedNode.find('.arconics-bogus').remove();
        insertPara(editor, selectedNode);
      }
    }

    if (selectedNode.nodeName === 'TABLE' && !editor.selection.getRng(false).collapsed) {
      // root table selected and selection not collapsed
      if ((selectedNode?.parentNode as HTMLElement)?.className === 'arc-unit') {
        Log.debug('Dangerous all table non collapsed selection, deselecting');
        editor.selection.setCursorLocation(TableUtils.getFirstTableCellFromActiveEditor(editor, selectedNode), 0);
      }
    }

    if (keyIdentifier.isDeleteKeys(e)) {
      if (isNested && !nestedUnit.focused.definition?.userCreatable) {
        keyIdentifier.nukePropagation(e);
        return true;
      }
    }
  }

  return false;
};

export const handleInlineDataMeasureDelete = function deleteInlineDataMeasure(editor, e) {
  const selectedNode = editor.selection ? editor.selection.getNode() : null;

  if (selectedNode && isInlineDataEnum(selectedNode)) {
    if (e.keyCode === keyIdentifier.KeyCode.backspace || e.keyCode === keyIdentifier.KeyCode.del) {
      editor.dom.remove(selectedNode);
      editor.dom.remove(editor.selection.getEnd());
      keyIdentifier.nukePropagation(e);
      return true;
    }
  }
  return false;
};

export const deleteAttentionNode = (editor: CustomEditor, e: React.KeyboardEvent) => {
  const selectedNode = editor.selection ? editor.selection.getNode() : null;

  if (selectedNode && isDeleteKeys(e)) {
    // We don't have the parent node
    const rootNode = getAttentionParentNode(selectedNode);
    const siblingOrParentNode = rootNode.previousElementSibling ?? rootNode.parentElement;
    if (siblingOrParentNode) {
      editor.selection.setCursorLocation(siblingOrParentNode);
    }
    editor.dom.remove(rootNode);
  }
};

const getAttentionParentNode = (selectedNode: Element) => {
  if (!selectedNode.attributes['data-element-definition-id'] && selectedNode.parentElement) {
    return getAttentionParentNode(selectedNode.parentElement);
  }
  return selectedNode;
};

export const manageTableRefIntKeys = (editor: CustomEditor) => {
  const selectedNode = editor.selection ? (editor.selection.getNode() as HTMLElement) : null;
  if (selectedNode && selectedNode?.innerHTML.indexOf(ZERO_WIDTH_NO_BREAK_SPACE) >= 0) {
    const bookmark = editor.selection.getBookmark();
    selectedNode.innerHTML = replaceZeroLengthZeroWidthCharacters(selectedNode, ZERO_WIDTH_NO_BREAK_SPACE, ZERO_WIDTH_NO_BREAK_SPACE);
    editor.selection.moveToBookmark(bookmark);
  }
};

export const manageMelCdlItemNumberKeys = (editor: CustomEditor, e: React.KeyboardEvent) => {
  if (isEnterKey(e) && ElementDetails.getDataElementDefinitionId(editor.selection.getNode()) === 'ItemNumber') {
    CONSTANTS.nukePropagation(e);
    const selectedUnit = EditorStore.getSelectedUnit();
    const profile:
      | IElementProfile
      | undefined = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getElementProfileByDefinitionId('ItemNumber');
    if (profile) {
      if (selectedUnit.definitionId === 'itemNumberGroup') {
        // insert below current item number
        EditorStore.insertUnit({
          inline: true,
          unitType: profile.id as UnitTypes,
          insertPoint: editor.selection.getNode() as HTMLElement,
          insertPosition: 'insert_after'
        });
      } else if (selectedUnit.definitionId === 'itemNumber') {
        // create new item number
        EditorStore.getEditor().blur({}, () => EditorStore.createUnit({ definitionId: profile.id }, selectedUnit.uid));
      }
    }
  }
};
