import React, { useState } from 'react';
import * as _ from 'lodash';
import { IRevision, IRevisionPdfMedia } from 'mm-types';
import { IconProps, MaterialIconListCheckbox } from '../../general/MaterialIcon';
import { transitionTo } from '../../../utils/Navigation';
import config from '../../../utils/config';
import { Divider, IconButton, IconMenu, MenuItem } from 'material-ui';
import InProgress from './InProgress';
import withTooltip from '../../hoc/withTooltip';

const IconListCheckboxWithTooltip = withTooltip<IconProps>(MaterialIconListCheckbox);

interface RevisionListProps {
  projectUid?: string;
  revisions: IRevision[] | null;
  canOpen: boolean;
}

interface PdfButtonProps {
  revision: IRevision;
  onGeneratePDFClick: (pdfMedia: IRevisionPdfMedia) => void;
  onGeneratePDFForVariantClick: (revision: IRevision, variantName: string) => void;
}

const NoRevisions = () => <div className="empty-list">NO REVISIONS</div>;

const PdfButton = ({ revision, onGeneratePDFClick, onGeneratePDFForVariantClick }: PdfButtonProps) => {
  if (!revision.interim && revision.pdfMedia && revision.pdfMedia.length === 1) {
    return (
      <IconButton
        iconClassName="material-icons icon-export-as-pdf"
        data-qa="icon-export-as-pdf"
        tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
        tooltipPosition="bottom-left"
        tooltip="Download PDF"
        onClick={onGeneratePDFClick.bind(null, revision.pdfMedia[0])}
      />
    );
  } else if (!revision.interim && revision.pdfMedia && revision.pdfMedia.length > 0) {
    return (
      <IconMenu
        className="variant-pdf-menu"
        iconButtonElement={
          <IconButton
            iconClassName="material-icons icon-export-as-pdf"
            data-qa="icon-export-as-pdf"
            tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
            tooltipPosition="bottom-left"
            tooltip="Download PDF"
          />
        }
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div className="variant-menu-header">Export Variant To PDF</div>
        <Divider />
        {revision.pdfMedia.map((v, index) => (
          <MenuItem
            key={`v-${index}`}
            primaryText={v.variantName}
            onClick={onGeneratePDFForVariantClick.bind(null, revision, v.variantName)}
          />
        ))}
      </IconMenu>
    );
  }
  return null;
};

const RevisionList = ({ projectUid, revisions, canOpen }: RevisionListProps) => {
  const [expandedRevision, setExpandedRevision] = useState<string | null>(null);

  function expandRevision(indexUid: string) {
    setExpandedRevision(indexUid);
  }

  function openRevision(indexUid: string) {
    transitionTo('editor-edit', {
      projectUid: projectUid,
      indexUid: indexUid
    });
  }

  function generatePDF(pdfMediaItem: { mediaUid: string }) {
    location.href = config.apiRoot + '/projects/' + projectUid + '/media/' + pdfMediaItem.mediaUid + '/content?attachment=true';
  }

  function generatePDFForVariant(revision: IRevision, variantName: string) {
    const variantPdf = _.find(revision.pdfMedia, { variantName: variantName });
    variantPdf && generatePDF(variantPdf);
  }

  if (!revisions) {
    return <InProgress />;
  }

  if (revisions.length === 0) {
    return <NoRevisions />;
  }

  return (
    <ul className="document-info-revisions-list" data-qa="revisions-list">
      {revisions?.length &&
        revisions.map((revision, revisionIndex) => {
          return (
            <li key={revisionIndex}>
              <div>
                <label className={revision.interim ? 'interim' : ''} onClick={expandRevision.bind(null, revision.indexUid)}>
                  {revision.interim ? 'Interim ' : ''}Revision {revision.revision}
                </label>

                {revision.quizUid && (
                  <div className="quiz-published">
                    <IconListCheckboxWithTooltip className="material-icons" tooltipText="Quiz" />
                  </div>
                )}

                <span>{revision.revisionDateFormattedShort}</span>

                <IconButton
                  iconClassName={`material-icons ${revision.interim ? 'icon-interim-revision' : 'icon-new-doc'}`}
                  disabled={!canOpen}
                  tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                  onClick={openRevision.bind(null, revision.indexUid)}
                  tooltipPosition="bottom-left"
                  tooltip="Open Revision"
                />

                <PdfButton revision={revision} onGeneratePDFClick={generatePDF} onGeneratePDFForVariantClick={generatePDFForVariant} />
              </div>

              {expandedRevision === revision.indexUid && (
                <div className="revision-extra-info">
                  <span>{revision.description}</span>
                  <span>Publication Date</span>
                  <span>{revision.distributionDateFormatted}</span>
                </div>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default RevisionList;
