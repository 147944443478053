declare let window: Window;
require('../less/main.less');
import { Provider } from 'react-redux';
import { browserHistory } from './browserHistory';
import jquery from 'jquery';
import lodash from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import config from './utils/config';
import agentUtil from './utils/AgentUtil';
import ComponentHistory from './utils/ComponentHistory';
import routes from './components/ReactRoutes';
import Log from './utils/Log';
import BodyCSSClassUtil from './utils/BodyCSSClassUtil';
import appNavigator from './utils/AppNavigator';
import SystemStore, { SystemStoreEvent } from './flux/common/SystemStore';
import { defaultTheme, ThemeContext } from './components/misc/ThemeProvider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import appStore from './appStore';

window.jQuery = jquery;
window.$ = jquery;
window._ = lodash;
window.config = config;

if (!window.console) {
  // @ts-ignore
  window.console = {};
}
if (!window.console.log) {
  window.console.log = function () {};
}
if (!window.console.group) {
  window.console.group = function () {};
}
if (!window.console.groupEnd) {
  window.console.groupEnd = function () {};
}

// MuiThemeProvider - required by material-ui ( use deprecated Context API )
// ThemeContext - to use in our components
const App = () => (
  <Provider store={appStore}>
    <MuiThemeProvider muiTheme={defaultTheme}>
      <ThemeContext.Provider value={defaultTheme}>
        <IntlProvider locale="en">
          <Router history={browserHistory}>{routes}</Router>
        </IntlProvider>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  </Provider>
);

function runApp() {
  ReactDOM.render(<App />, document.getElementById('app'));
  Log.info('Manual Manager - app initialized successfully');
}

document.addEventListener('DOMContentLoaded', function () {
  SystemStore.listen((e: SystemStoreEvent) => {
    if (e.initialized) {
      browserHistory.listen(ComponentHistory.update);
      if (!agentUtil.isEditorSupported()) {
        appNavigator.goTo.loginWithRedirectBack();
      } else {
        runApp();
      }
    }
  }, null);
  SystemStore.initApp(browserHistory);
  BodyCSSClassUtil.toggleClassNameOnDragEvent();
});
