export enum SelectedPdfRange {
  SELECTION = 'exportPdfSelection',
  ENTIRE = 'exportPdf',
  REVISION_HIGHLIGHTS = 'exportPdfRevisionHighlights',
  NONE = ''
}

export type RevisionHighlightsType = 'ALL' | 'STARRED' | 'UNSTARRED';

export interface RevisionHighlightsOption {
  checked: boolean;
  value: RevisionHighlightsType;
}
