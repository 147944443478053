import * as React from 'react';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const containerStyles: React.CSSProperties = {
  color: '#75010f',
  background: 'rgb(255, 225, 229)',
  border: '1px solid rgb(220, 175, 181)',
  padding: '4px 8px',
  borderRadius: 3
};

const contentStyles: React.CSSProperties = {
  color: '#d0021b',
  verticalAlign: 'middle'
};

const ErrorMessage = (props: Props) => {
  return (
    <div style={{ ...containerStyles, ...props.style }}>
      <span style={contentStyles}>{props.children}</span>
    </div>
  );
};

export default ErrorMessage;
