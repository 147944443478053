import * as React from 'react';
import Tag from './Tag';
import * as key from '../../misc/keyboard';
import { ITag } from 'mm-types';

export type Props = {
  openPanel: () => void;
  closePanel: () => void;
  valueField: string;
  allowDelete: boolean;
  allowAdd: boolean;
  onValueChange: (e: React.ChangeEvent<HTMLElement>) => void;
  onTagDeleted: (index: number) => void;
  onKeyDown: (e: { keyCode: number }) => void;
  value: string;
  showTags: ITag[];
  placeholder: string;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  focused: boolean;
  allowCreate: boolean;
};

export type State = {};

export default class Input extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  focusInput() {
    if (this.refs.input) {
      (this.refs.input as HTMLInputElement).focus();
    }
  }

  getTags() {
    return this.props.showTags.map((tag, i) => {
      return (
        <Tag
          selected={false}
          input=""
          text={tag[this.props.valueField]}
          addable={false}
          allowDelete={this.props.allowDelete}
          key={tag[this.props.valueField] + '_' + i}
          onDelete={() => this.props.onTagDeleted(i)}
        />
      );
    });
  }

  onBlur(e: React.FocusEvent<HTMLElement>) {
    this.props.closePanel();
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  getAddBtn() {
    return (
      <div className="tf__input__add" onClick={(e) => this.props.onKeyDown({ keyCode: key.ENTER })}>
        <span className="material-icons icon-tick"></span>
      </div>
    );
  }

  onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    this.props.onKeyDown(e);
    // prevent from handling UP & DOWN keys
    if ([key.UP, key.DOWN].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  render() {
    const placeholder = this.props.placeholder || '';
    const size = this.props.value.length === 0 ? placeholder.length : this.props.value.length;

    const inputInputCls = 'tf__input__input ' + (this.props.value.length > 0 ? 'tf__input__input--active' : '');
    const wrapperCls =
      'tf__input__wrapper ' +
      (this.props.value.length > 0 ? 'tf__input__wrapper--active ' : '') +
      (!this.props.allowAdd ? 'tf__input__wrapper--no-add ' : '');
    const inputCls =
      'tf__input ' +
      (this.props.focused ? 'tf__input--focused ' : '') +
      (!this.props.allowAdd && !this.props.allowDelete ? 'tf__input--disabled' : '');

    return (
      <div className={inputCls} onClick={(e) => this.focusInput()}>
        {this.getTags()}

        <div className={wrapperCls}>
          <input
            ref="input"
            type="text"
            value={this.props.value}
            size={size + 2}
            onFocus={(e) => this.props.openPanel()}
            onBlur={(e) => this.onBlur(e)}
            onChange={(e) => this.props.onValueChange(e)}
            onKeyDown={(e) => this.onKeyDown(e)}
            placeholder={placeholder}
            aria-label={placeholder}
            className={inputInputCls}
            disabled={!this.props.allowAdd}
          />
          {this.props.allowAdd && this.props.value.length > 0 && this.props.allowCreate ? this.getAddBtn() : null}
        </div>
      </div>
    );
  }
}
