/* FRAMEWORK */
import * as React from 'react';
import IconButton from 'material-ui/IconButton';
/* TYPES */
import { TTabType } from 'mm-types';

export interface ITab {
  tab: TTabType;
  tooltip: 'Table Layout' | 'Cell Formatting';
  icon: 'border_all' | 'border_clear';
  isActive: (activeTab: TTabType) => boolean;
}
interface TabToggleButtonsProps {
  tabs: ITab[];
  onTabClick: (tab: TTabType) => void;
  activeTab: TTabType;
}

const TabToggleButtons = React.memo((props: TabToggleButtonsProps) => {
  return (
    <ul className="tabs icon-action-buttons ieTooltip">
      {props.tabs.map((tab) => {
        return (
          <li
            id={tab.tab}
            key={tab.tab}
            className={`tab col s3 ${tab.isActive(props.activeTab) ? 'active' : ''}`}
            onClick={() => props.onTabClick(tab.tab)}
          >
            <IconButton
              iconClassName="material-icons"
              tooltipPosition="bottom-center"
              tooltip={tab.tooltip}
              tooltipStyles={{ zIndex: 9999 }}
            >
              {tab.icon}
            </IconButton>
          </li>
        );
      })}
    </ul>
  );
});

export default TabToggleButtons;
