import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import ListType from '../controls/ListType';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import IconButton from 'material-ui/IconButton';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

const ListEditProps = (props: IGenericEditProps) => {
  const [userOpened, setUserOpened] = React.useState<boolean>();
  const [listType, setListType] = React.useState<string>('unordered');

  React.useEffect(() => {
    updateState();
  }, [props?.unitElement]);

  const updateState = () => {
    if (EditorStore.getEditor()!.getActiveEditorFacade()) {
      const type = EditorStore.getEditor()!.getActiveEditorFacade()!.getListType(props.unitElement);
      const listType = type !== null ? type.type : 'unordered';
      setListType(listType);
    }
  };

  const onListTypeChange = (e) => {
    if (EditorStore.getEditor()!.getActiveEditorFacade()) {
      const editor = EditorStore.getEditor();
      const { isRoot } = editor.getActiveEditorFacade()!.getListType(props.unitElement);

      if (isRoot && EditorStore.getSelectedUnit()!.type === 'list') {
        EditorStore.convertUnitInEditor(EditorStore.getSelectedUnit()!, e.unitType);
      } else {
        editor.getActiveEditorFacade()!.convertNestedList(props.unitElement, e.unitType);
      }
    }
  };

  const moveListItem = (type) => {
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand(type, null);
  };

  return (
    <div className="toggle-section-outer selected-unit-props-container">
      <ToggleSection
        title="List Properties"
        id={'unitProps_' + props.index}
        defaultOpen={false}
        forceClose={!props.isLeaf}
        forceOpen={props.isLeaf || userOpened}
        onToggled={(isOpened) => setUserOpened(isOpened)}
      >
        <div className="selected-unit-props-section list-edit">
          <ListType title="Type" onChange={(e) => onListTypeChange(e)} listType={listType} />
        </div>
        <div className="selected-unit-props-section list-edit">
          <div className="promote-demote-actions">
            <label>Indent &amp; Outdent</label>
            <IconButton
              onClick={() => {
                moveListItem('ListIndent');
              }}
              iconClassName="material-icons"
              tooltipPosition="bottom-center"
              tooltip="Indent"
            >
              format_indent_increase
            </IconButton>

            <IconButton
              onClick={() => {
                moveListItem('ListOutdent');
              }}
              iconClassName="material-icons"
              tooltipPosition="bottom-center"
              tooltip="Outdent"
            >
              format_indent_decrease
            </IconButton>
          </div>
        </div>
        <DataAttributesPropsWrapper {...props} />
        {UnitUtils.isFormatingAvailable(props.unitElement) && (
          <MultiFormatting formatNode={props.index === 0 ? null : props.unitElement} unitProfile={props.unitProfile} />
        )}
      </ToggleSection>
    </div>
  );
};

export default ListEditProps;
