import * as Reflux from 'reflux';
import Store from '../Store';
import { IFileAttachment } from 'mm-types';
import ProjectStore from './ProjectStore';
import * as fileAttachmentClient from '../../clients/file-attachments';
import config from '../../utils/config';
import { AxiosError } from 'axios';
import { CancelTokenSource } from 'axios';
import { Cancelled } from '../../clients/base-clients';
import Log from '../../utils/Log';

export type State = {
  activeUploads: IFileAttachment[];
  activeXhrs: XMLHttpRequest[];
  downloadedFiles: Partial<IFileAttachment>[];
};

export type Event = {
  type: 'progress' | 'project-updated';
  activeUploads?: IFileAttachment[];
  projectUpdated?: { attachedFiles: Partial<IFileAttachment>[] };
};
let token: CancelTokenSource | null = null;

export class FileAttachmentStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      activeUploads: [],
      activeXhrs: [],
      downloadedFiles: []
    };
  }

  /**
   * Cancels an upload from completing
   */
  public cancelUpload(upload: IFileAttachment) {
    if (token) {
      token.cancel();
    }
    this.state.activeUploads.splice(this.state.activeUploads.indexOf(upload), 1);
    this.progressEvent();
  }

  /**
   * Trigger store progress/change event
   */
  private progressEvent() {
    const event: Event = {
      type: 'progress',
      activeUploads: this.state.activeUploads
    };

    this.trigger(event);
  }

  /**
   * Trigger store progress/change event
   */
  private projectEvent() {
    const event: Event = {
      type: 'project-updated',
      projectUpdated: { attachedFiles: this.state.downloadedFiles }
    };

    this.trigger(event);
  }

  private progressStatus(e: number, attachment: Partial<IFileAttachment>) {
    attachment.progress = e * 100;
    this.progressEvent();
  }

  /**
   * Add the default events
   */
  private async addModelEvents(attachment: Partial<IFileAttachment>) {
    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };

    try {
      this.progressEvent();
      const res: IFileAttachment | Cancelled = await fileAttachmentClient.upload(
        project,
        attachment,
        (progressNumber) => {
          this.progressStatus(progressNumber, attachment);
        },
        (axiosToken) => (token = axiosToken)
      );
      this.state.activeUploads.splice(this.state.activeUploads.indexOf(attachment as IFileAttachment), 1);
      this.state.downloadedFiles.push(res);
      this.progressEvent();
      this.projectEvent();
    } catch (error) {
      const axiosErr = error as AxiosError;
      attachment.error = new Error('Attachment not uploaded');
      attachment.progress = 0;

      if (axiosErr && axiosErr.response && axiosErr.response.data.errors[0].code === 41502) {
        attachment.error = new Error(`Unsupported attachment type: ${attachment.filename}`);
      }

      this.progressEvent();
    }
  }

  /**
   * Triggers the download process of retrieving files for the current document & index
   */
  async getIndexAttachments() {
    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };

    const res = await fileAttachmentClient.getAll(project);
    if (res instanceof Cancelled) {
      return;
    }
    this.state.downloadedFiles = res;
    this.projectEvent();
    return this.state.downloadedFiles;
  }

  /**
   * Opens a new window and downloads the attachment's file
   */
  downloadContent(attachment: Partial<IFileAttachment>) {
    (window.location.href =
      config.apiRoot +
      `/projects/${
        ProjectStore.getProject()?.uid
      }/indexes/${ProjectStore.getCurrentRevisionUid()}/attachments/${attachment.uid!}/content?attachment=true`),
      '_blank';
  }

  /**
   * Updates the details of an attachment
   */
  async updateAttachment(attachment: Partial<IFileAttachment>) {
    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };

    try {
      delete attachment.content;
      const res = await fileAttachmentClient.set(project, attachment);
      const existing = this.state.downloadedFiles.find((item) => item.uid === attachment.uid)!;
      this.state.downloadedFiles = this.state.downloadedFiles.map((file) => (existing.uid === file.uid ? res : file));
      this.projectEvent();
      return this.state.downloadedFiles;
    } catch (error) {
      Log.info('Exception when dealing with attachments');
    }
  }

  /**
   * Removes an attachment from thge document
   */
  async removeAttachment(attachment: Partial<IFileAttachment>) {
    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };
    delete attachment.content;
    await fileAttachmentClient.removeAttachment(project, attachment.uid);

    this.state.downloadedFiles = this.state.downloadedFiles.filter((item) => item.uid !== attachment.uid);
    this.projectEvent();
  }

  /**
   * Upload a list of files to the server
   */
  async uploadFiles(attachments: Partial<IFileAttachment>[]) {
    for (const attachment of attachments) {
      this.state.activeUploads.push(attachment as IFileAttachment);
      this.addModelEvents(attachment);
    }
  }
}

const singleton = Reflux.initStore<FileAttachmentStore>(FileAttachmentStore);
export default singleton;
