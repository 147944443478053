import * as React from 'react';
import FileIcon from '../../../../misc/icons/FileIcon';
import { IFileAttachment } from 'mm-types';
import { getTypeFromMimeType } from '../../../../../utils/FileIcon';

interface Props {
  file: Partial<IFileAttachment>;
}

const FilePreview = ({ file }: Props) => {
  return (
    <div>
      <div className="external-upload-icon attachment-summary display-flex">
        <FileIcon className="flex-align-center-vertical" fileType={getTypeFromMimeType(file.content!)} style={{ marginRight: 15 + 'px' }} />
        <span className="file-name flex-align-center-vertical display-flex break-word">
          <span className="vertical-align-middle">{file.filename}</span>
        </span>
      </div>
    </div>
  );
};

export default FilePreview;
