import * as React from 'react';
import DialogSection from '../../dialogs/DialogSection';
import DiffExportRow from '../components/DiffExportRow';
import { RevisionHighlightsOption, RevisionHighlightsType } from './exportDialogTypes';

interface Props {
  privateOptions: RevisionHighlightsOption;
  generalOptions: RevisionHighlightsOption;
  onPrivateOptionChange(value: RevisionHighlightsOption);
  onGeneralOptionChange(value: RevisionHighlightsOption);
}

const RADIO_BUTTONS = [
  { value: 'ALL', label: 'All' },
  { value: 'STARRED', label: 'Starred' },
  { value: 'UNSTARRED', label: 'Unstarred' }
];

const ROW_STYLE: React.CSSProperties = {
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '33% 67%'
};

export function RevisionHighlightsSection({ privateOptions, generalOptions, onPrivateOptionChange, onGeneralOptionChange }: Props) {
  return (
    <DialogSection label="Revision Highlights" id="revision-highlights">
      <div style={{ marginTop: 10 }}>
        <DiffExportRow
          name="Private"
          className="export-row margin-top-none"
          id="private-revision-highlights"
          valueName="privateRevisionHighlightsValue"
          checked={privateOptions.checked}
          style={ROW_STYLE}
          value={privateOptions.value}
          onCheck={() => onPrivateOptionChange({ ...privateOptions, checked: !privateOptions.checked })}
          onValueChanged={(value: RevisionHighlightsType) => onPrivateOptionChange({ ...privateOptions, value })}
          radioButtons={RADIO_BUTTONS}
        />
      </div>
      <div className="padding-top-l">
        <DiffExportRow
          name="General"
          className="export-row margin-top-none"
          id="general-revision-highlights"
          valueName={'generalRevisionHighlightsValue'}
          checked={generalOptions.checked}
          style={ROW_STYLE}
          value={generalOptions.value}
          onCheck={() => onGeneralOptionChange({ ...generalOptions, checked: !generalOptions.checked })}
          onValueChanged={(value: RevisionHighlightsType) => onGeneralOptionChange({ ...generalOptions, value })}
          radioButtons={RADIO_BUTTONS}
        />
      </div>
    </DialogSection>
  );
}
