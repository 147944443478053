import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SecondaryDocumentStore, { SecondaryDocumentStoreEvent } from '../../../flux/editor/SecondaryDocumentStore';
import EditorStore from '../../../flux/editor/EditorStore';
import { GetUnitOptions } from '../../../clients/units';
import SmartContentStore from '../../../flux/editor/SmartContentStore';
import { CircularProgressLoader } from '../../misc/Loaders';
import RegulationTocPopup from '../sharedcontentlib/RegulationTocPopup';
import { IUnit, IProject, SecondaryDocumentModes } from 'mm-types';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';

export type Props = {
  projectUid: string;
  indexUid: string;
  startUnitUid?: string;
  unitUid?: string;
  isStartUnitVolume?: string;
  mode: SecondaryDocumentModes;
  onClose: () => void;
  onOpenNew: (e: { projectUid: string; indexUid: string; startUnitUid: string }) => void;
};

export type State = {
  units: IUnit[] | null;
  project: IProject | null;
  selected: string | null;
};

export default class SecondaryDocument extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      units: null,
      project: null,
      selected: null
    };

    this.unsubscribe = SecondaryDocumentStore.listen(this._onStoreUpdate, this);
  }

  componentDidMount() {
    this._retrieveUnits(this.props);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // for now assume a new lod is based on unit inside current project & index when mounted
    if (this.props?.startUnitUid !== nextProps?.startUnitUid || this.props.unitUid !== nextProps.unitUid) {
      this._retrieveUnits(nextProps);
    }
  }

  _retrieveUnits(props: Props) {
    const params: Partial<GetUnitOptions> = { tocableUnitUid: props?.startUnitUid, includeHeader: true };

    if (props.isStartUnitVolume && props.isStartUnitVolume === 'true') {
      params.unitsRequired = 1;
    }

    SecondaryDocumentStore.retrieveUnits(props.projectUid, props.indexUid, params);
  }

  _onStoreUpdate(storeState: SecondaryDocumentStoreEvent) {
    // select nothing in share mode
    const initialSelected = !this._isShareMode() && this.props.unitUid ? this.props.unitUid : null;

    this.setState({ units: storeState.units, project: storeState.project, selected: initialSelected }, () => {
      if (this.props.unitUid) {
        const $pageContainer = $(ReactDOM.findDOMNode(this.refs.editingScrollContainer) as Element),
          $unitEl = $('.editing-stage-page-secondary #_' + this.props.unitUid)!;

        if ($unitEl.length) {
          const unitTopPosition = $unitEl.offset()!.top;
          $pageContainer.animate(
            {
              scrollTop: unitTopPosition - $pageContainer.offset()!.top + $pageContainer.scrollTop()! - 100
            },
            100
          );
        }
      }
    });
  }

  _isShareMode() {
    return this.props.mode === 'SHARE_SELECT_MODE';
  }

  _getShareBoundary(unit, isFirst) {
    if (this._isShareMode() && unit.shareDetails && unit.shareDetails.origin && unit.shareDetails[isFirst ? 'isFirst' : 'isLast']) {
      return (
        <div className={'unit-shared-menu ' + (isFirst ? 'start' : 'end')}>
          <div className="actions">
            <i className="material-icons view-mode-icon">share</i>
          </div>
          <span className="icon-shared-content-marker-point"></span>
          <div className="text">{isFirst ? 'START' : 'END'}</div>
        </div>
      );
    }

    return null;
  }

  _getInsertToDocMenu(unit) {
    const isReadOnly = EditorStore.isReadOnly();
    if (!isReadOnly) {
      // SHARE MODE
      if (this._isShareMode() && unit.shareDetails && unit.shareDetails.origin && unit.shareDetails.isShareStartUnit) {
        return (
          <div className="du-inline-menu du-inline-secondarydoc-menu">
            <ul
              className="du-inline-item du-inline-actions"
              onClick={() => {
                SmartContentStore.retrieveSharedIndex(unit.shareDetails.uid).then((sharedIndex) => {
                  EditorStore.requestSharedContentInsert(sharedIndex);
                });
              }}
            >
              <li>
                <i className="material-icons">add_circle</i>
              </li>
            </ul>
          </div>
        );
      }

      // REG DOC MODE
      else if (!this._isShareMode()) {
        if (unit.hasOrdinal && unit.hasRegableOrdinal) {
          return (
            <div className="du-inline-menu du-inline-secondarydoc-menu">
              <ul
                className="du-inline-item du-inline-actions"
                onClick={() => {
                  this.setState({ selected: unit.uid }, () => {
                    SmartContentStore.retrieveDefaultRegModel({
                      projectUid: this.props.projectUid,
                      indexUid: this.props.indexUid,
                      unitUid: unit.uid
                    });
                  });
                }}
              >
                <li>
                  <i className="material-icons">add_circle</i>
                </li>
              </ul>
            </div>
          );
        }
      }
    }

    return null;
  }

  _getUnitClassNames(unit) {
    let additionalClassNames = '';

    if (this._isShareMode() && unit.shareDetails) {
      additionalClassNames = ' unit-shared unit-shared-origin';
      additionalClassNames += unit.shareDetails.isShareStartUnit ? ' unit-shared-start' : '';
      additionalClassNames += unit.shareDetails.isShareEndUnit ? ' unit-shared-end' : '';
    } else {
      // reg mode
      additionalClassNames = this.state.selected === unit.uid ? ' primary' : '';
    }

    return 'document-unit-outer document-unit-outer-' + unit.type + additionalClassNames + ' readonly-document readonly-unit';
  }

  _handleClose() {
    this.props.onClose();
  }

  _handleTocOpen() {
    (this.refs.regTocPopup as RegulationTocPopup).open();
  }

  _handleDocumentOpen() {
    EditorStore.openAnotherDocument(this.props.projectUid, this.props?.startUnitUid, null, false);
  }

  _onTocItemSelected(section) {
    if (section) {
      this.props.onOpenNew({
        projectUid: this.props.projectUid,
        indexUid: this.props.indexUid,
        startUnitUid: section.uid
      });
    }
  }

  render() {
    const docUnitDOM = this.state.units
      ? this.state.units.map((docUnit, mapIndex) => {
          const key = docUnit.uid ? docUnit.uid : docUnit.definitionId + mapIndex;
          const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(docUnit.definitionId);

          return (
            <div
              className={this._getUnitClassNames(docUnit)}
              key={key}
              style={{ display: docUnit.isVisibleOnEdit ? 'block' : 'none' }}
              id={'_' + docUnit.uid}
            >
              {profile?.inlineOptions?.readonly ? null : <div className="du-label">{profile?.getLabel?.(docUnit)}</div>}
              {this._getShareBoundary(docUnit, true)}
              {this._getInsertToDocMenu(docUnit)}
              <div
                className={'document-unit-inner document-unit-inner-' + docUnit.type}
                id={'_' + docUnit.uid}
                dangerouslySetInnerHTML={{ __html: docUnit.html }}
              ></div>
              {this._getShareBoundary(docUnit, false)}
            </div>
          );
        })
      : [];

    return (
      <div className={'editing-stage-page editing-stage-page-secondary'}>
        <div className="editing-header">
          <div className="editing-header-inner">
            <h5>{this.state.project ? this.state.project.name : ''}</h5>

            <i onClick={(e) => this._handleClose()} className="material-icons qa-icon-close">
              close
            </i>
            {this._isShareMode() && this.props.indexUid !== EditorStore.getDocParams().indexUid ? (
              <i onClick={(e) => this._handleDocumentOpen()} className="material-icons qa-icon-open-in-new" title="Open Document">
                open_in_new
              </i>
            ) : null}
            {!this._isShareMode() ? (
              <i onClick={(e) => this._handleTocOpen()} className="material-icons qa-icon-toc">
                toc
              </i>
            ) : null}

            {!this._isShareMode() ? (
              <RegulationTocPopup
                ref="regTocPopup"
                projectUid={this.props.projectUid}
                documentIndexUid={this.props.indexUid}
                classNames="reg-doc-popup--constrained"
                onSelected={(section) => this._onTocItemSelected(section)}
              />
            ) : null}
          </div>
        </div>

        <div className={'editing-stage-page-inner visual-override-outline'} ref="editingScrollContainer">
          <div className="editing-page">{docUnitDOM.length ? docUnitDOM : <CircularProgressLoader label={'Loading Document...'} />}</div>
        </div>

        <div className="col s12 editing-footer"></div>
      </div>
    );
  }
}
