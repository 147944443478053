import * as React from 'react';
import { EventStoreEventType, IDefinition, IEditorStoreEvent, IUnit, IUnitDetails } from 'mm-types';
import { IEditProps } from '../Edit';
import useListenToStore from '../../../../hooks/useListenToStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { updateDataAttributeMinimumLayer } from '../../../../../utils/DataAttributesUtil';

export type Props = {
  nestedTreeUnitInfo: IUnitDetails[];
  selectedUnit: IUnit | null;
  onToggle: (isOpen: boolean, ref: React.RefObject<HTMLDivElement>) => any;
  setEditingUnitsLength: (length: number) => any;
};

const EditingUnitProperties = ({ nestedTreeUnitInfo, selectedUnit, onToggle, setEditingUnitsLength }: Props) => {
  const [editingUnitProps, setEditingUnitProps] = React.useState<(IEditProps & IUnitDetails)[]>([]);
  const [isDataAttributeUpdated, setIsDataAttributeUpdated] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (nestedTreeUnitInfo.length) {
      getEditingUnitComponents();
    }
  }, [nestedTreeUnitInfo, selectedUnit]);

  React.useEffect(() => {
    if (isDataAttributeUpdated) {
      const editingUnitEditProps = updatedEditingUnitComponents(editingUnitProps, selectedUnit, onToggle);
      setEditingUnitProps(editingUnitEditProps);
      setEditingUnitsLength(editingUnitEditProps.length);
      setIsDataAttributeUpdated(false);
    }
  }, [isDataAttributeUpdated]);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e?.type === 'dataAttributeApplied') {
      const castEvent = e as IEditorStoreEvent<'dataAttributeApplied'>;
      castEvent?.data?.appliedByUser && setIsDataAttributeUpdated(castEvent?.data?.appliedByUser);
    }
  }

  const getEditingUnitComponents = () => {
    let editingUnitEditProps: (IEditProps & IUnitDetails)[] = updatedEditingUnitComponents(nestedTreeUnitInfo, selectedUnit, onToggle);
    editingUnitEditProps = editingUnitEditProps.filter((editProps) => editProps.unitProfile && editProps.unitProfile.editPropsComponent);

    // last edit props is the leaf
    if (editingUnitEditProps[editingUnitEditProps.length - 1]) {
      editingUnitEditProps[editingUnitEditProps.length - 1].isLeaf = true;
    }
    editingUnitEditProps = disableNestedTableEditingUnitComponents(editingUnitEditProps);
    setEditingUnitProps(editingUnitEditProps);
    setEditingUnitsLength(editingUnitEditProps.length);
  };

  const updatedEditingUnitComponents = (
    nestedTreeUnitInfo: IUnitDetails[],
    selectedUnit: IUnit | null,
    onToggle: (isOpen: boolean, ref: React.RefObject<HTMLDivElement>) => any
  ) => {
    let editingUnitEditProps: (IEditProps & IUnitDetails)[] = [];
    let parentDefinition;
    let displayPrintOutput = true;
    let parentLayer = '1';

    const rowNodeIndex = nestedTreeUnitInfo.findIndex((node) => node.type == 'TableRow');
    if (rowNodeIndex > -1 && rowNodeIndex < nestedTreeUnitInfo.length - 1) {
      displayPrintOutput = false;
    }

    nestedTreeUnitInfo.forEach((n, i) => {
      const editProps: IEditProps & IUnitDetails = {
        key: i,
        index: i,
        isLeaf: false,
        isOneOfMultiple: nestedTreeUnitInfo.length > 1,
        unitProfile: n.profile,
        definition: {
          dataAttributes: updateDataAttributeMinimumLayer(parentLayer, n?.definition?.dataAttributes),
          ...n.definition
        } as IDefinition,
        parentDefinition: { ...parentDefinition },
        selectedUnit: selectedUnit,
        subType: n.subtype,
        onToggle: onToggle,
        displayPrintOutput: displayPrintOutput,
        ...n
      };

      // remember current definition as a parent one for the next element in the tree
      parentDefinition = { ...n.definition };

      parentLayer = editProps?.targetElement?.getAttribute('data-layer') ?? parentLayer;
      editingUnitEditProps.push(editProps);
    });
    return editingUnitEditProps;
  };

  // TODO: AER-8544, improvements to rhs properties
  const disableNestedTableEditingUnitComponents = (editingUnitEditProps: (IEditProps & IUnitDetails)[]) => {
    const tableIndexes = editingUnitEditProps
      .map((editProps, i) => {
        if (editProps.definition?.id === 'Table') {
          return i;
        }
      })
      .filter((val) => val !== undefined);
    if (tableIndexes.length > 1) {
      tableIndexes.pop();
      tableIndexes.map(
        (index: number) =>
          (editingUnitEditProps[index] = {
            ...editingUnitEditProps[index],
            disabled: true
          })
      );
    }
    return editingUnitEditProps;
  };

  return (
    <>
      {editingUnitProps.map((editProps, index) => {
        const Component = editProps.unitProfile.editPropsComponent;
        const { key, ...restProps } = editProps;
        if (Component) {
          return <Component key={editProps.key} {...restProps} />;
        }
      })}
    </>
  );
};

export default EditingUnitProperties;
