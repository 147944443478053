import React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import useListenToStore from '../../../../../hooks/useListenToStore';
import { EventStoreEventType, IEditorStoreEvent } from 'mm-types';

export const useUpdateChildRhsAttributes = (updateDataAttributes: () => void) => {
  const [isDataAttributeUpdated, setIsDataAttributeUpdated] = React.useState<boolean>(false);
  const [layerPropertySettingElement, setLayerPropertySettingElement] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (isDataAttributeUpdated && !layerPropertySettingElement) {
      updateDataAttributes();
      setLayerPropertySettingElement(null);
      setIsDataAttributeUpdated(false);
    }
  }, [isDataAttributeUpdated]);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e?.type === 'dataAttributeApplied') {
      const castEvent = e as IEditorStoreEvent<'dataAttributeApplied'>;
      if (!castEvent?.data?.appliedByUser) {
        setIsDataAttributeUpdated(true);
      }
    }
  }

  return {
    setLayerPropertySettingElement
  };
};
