import * as _ from 'lodash';

export function propsAreEqual(a: any, b: any) {
  if (!a && !b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  return _.isEqualWith(a, b, (a, b) => {
    if (typeof a === 'function' && typeof b === 'function') {
      return true;
    }
    return undefined;
  });
}
