import React from 'react';
import MenuActionItemContent from '../../EditorMenu/MenuActionItemContent';
import MenuActionIcon from '../../EditorMenu/MenuActionIcon';
import EditorStore from '../../../../../flux/editor/EditorStore';

type Props = {
  handleSelected: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isReadOnly: boolean;
};

const SpellCheckItem = ({ handleSelected, isReadOnly }: Props) => {
  const isSpellCheckEnabled = () => {
    return EditorStore.isMode('SPELLCHECK');
  };

  return (
    <MenuActionItemContent
      onAction={handleSelected}
      enabled={!isReadOnly}
      action={{
        action: 'spellCheck',
        icon: 'spellcheck',
        tooltip: 'Spell Checker',
        active: true,
        visible: true,
        enabled: true
      }}
    >
      <div className={'itemWrapper ' + (isSpellCheckEnabled() ? 'on' : '')}>
        <MenuActionIcon enabled={true} icon="spellcheck" tooltip="Spell Checker" />
      </div>
    </MenuActionItemContent>
  );
};

export default SpellCheckItem;
