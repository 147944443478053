import * as React from 'react';

interface Props {
  children?: any;
  autoRows?: React.CSSProperties['gridAutoRows'];
  templateColumns?: React.CSSProperties['gridTemplateColumns'];
  gap?: React.CSSProperties['gap'];
  alignItems?: React.CSSProperties['alignItems'];
  style?: React.CSSProperties;
  dataQa?: string;
}
export function Grid(props: Props) {
  const { children, autoRows = 'auto', templateColumns, style = {}, ...rest } = props;
  const rootStyle: React.CSSProperties = {
    display: 'grid',
    gridAutoRows: autoRows,
    gridTemplateColumns: templateColumns,
    ...rest,
    ...style
  };

  return (
    <div style={rootStyle} data-qa={rest.dataQa}>
      {children}
    </div>
  );
}
