export type ActionType = {
  id: string;
  className: string;
  title: string;
};

export const initialActions: ActionType[] = [
  {
    id: 'dashboard',
    className: 'dashboard',
    title: 'Dashboard'
  },
  {
    id: 'docs',
    className: 'docs',
    title: 'Documents'
  },
  {
    id: 'webviewer',
    className: 'icon-webviewer-disabled closed',
    title: 'Web Viewer'
  },
  {
    id: 'distribution',
    className: 'icon-distribution-disabled closed',
    title: 'Distributor'
  },
  {
    id: 'settings',
    className: 'settings-disabled closed',
    title: 'Accounts'
  }
];
