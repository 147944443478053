import React from 'react';
import { ShortcutKeyProps } from 'mm-types';

interface ShortcutProps {
  keys: ShortcutKeyProps[];
}

export const Shortcutkeys = (props: ShortcutProps) => {
  return (
    <>
      {props.keys.map((obj, index: React.Key) => (
        <span className="keyboard" key={index}>
          {!!obj.type && obj.type === 'material-icons' && <i className={obj.type}>{obj.label}</i>}
          {!obj.type && obj.label}
        </span>
      ))}
    </>
  );
};
