import * as React from 'react';
import MenuActionItemContent, { MenuActionItemContentProps } from './MenuActionItemContent';
import MenuActionIcon, { MenuActionIconProps } from './MenuActionIcon';
export type MenuActionItemProps = MenuActionItemContentProps & Partial<MenuActionIconProps>;

const MenuActionItem = ({ onAction, action, enabled, actionName, inClassNameIcon }: MenuActionItemProps) => {
  return (
    <MenuActionItemContent enabled={enabled} action={action} onAction={onAction} actionName={actionName}>
      <MenuActionIcon enabled={enabled} icon={action.icon} tooltip={action.tooltip} inClassNameIcon={inClassNameIcon} />
    </MenuActionItemContent>
  );
};

export default MenuActionItem;
