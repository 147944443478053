import * as Reflux from 'reflux';
import * as client from '../../clients/change-tasks';
import EditorStore from '../../flux/editor/EditorStore';
import Store from '../Store';
import UnitTaskStore from '../../flux/editor/UnitTaskStore';
import { ITaskImportResponse } from 'mm-types';

export type ImportTasksStoreEvent = State & { error?: string };

export type State = {
  taskImportResponse: ITaskImportResponse | null;
};

export class ImportTasksStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      taskImportResponse: null
    };
  }

  getInitialState() {
    return this.state;
  }

  async importChangeTaskCSV(file: File, indexUid: string) {
    try {
      const response = await client.importTasks(file, indexUid);
      this.state.taskImportResponse = response;
      this.trigger(this.state as ImportTasksStoreEvent);
      const docParams = EditorStore.getDocParams();
      UnitTaskStore.init(docParams);
    } catch (err) {
      this.state.taskImportResponse = null;
    }
  }
}

const singleton = Reflux.initStore<ImportTasksStore>(ImportTasksStore);
export default singleton;
