import * as React from 'react';
import MenuActionItem, { MenuActionItemProps } from '../../EditorMenu/MenuActionItem';
import { MenuAction } from './defaultMenuContents';
import { PasteElementAction } from './PasteElementAction';

interface Props {
  onAction: MenuActionItemProps['onAction'];
  actions: MenuAction[];
}

const EditActionItems = ({ onAction, actions }: Props) => {
  return (
    <>
      {actions.map((menuItem, menuItemIndex) => {
        if (menuItem.action === 'arcPasteElement') {
          return <PasteElementAction key={menuItemIndex} action={menuItem} onAction={onAction} />;
        }
        return <MenuActionItem key={menuItemIndex} action={menuItem} onAction={onAction} enabled={menuItem.enabled} />;
      })}
    </>
  );
};

export default EditActionItems;
