import { IShareDetails, IUnit } from 'mm-types';

export type Position = { isUp: boolean; isStart: boolean };

export interface ISharedSelection {
  sharedIndexUid: string;
  sharedUnits: IUnit[];
  start: IUnit;
  end: IUnit;
  visibleStart: IUnit;
  visibleEnd?: IUnit | null;
}

function _markUnitAsChanged(currentUnitShareDetails: IShareDetails, docUnit: IUnit) {
  const details = docUnit.shareDetails;
  if (docUnit && details && (details.type === 'ORIGIN' || !details.type) && details.uid === currentUnitShareDetails.uid) {
    details['originDiffersFromSharedIndex'] = true;
  }
}

export function markUnitsWithinSameSharedIndexAsChanged(currentStartUnit: IUnit, docUnitCollection: IUnit[]) {
  const currentUnitIndex = currentStartUnit.index;
  const currentUnitShareDetails = currentStartUnit.shareDetails;
  if (currentUnitShareDetails?.isShareStartUnit) {
    for (let i = currentUnitIndex; i < docUnitCollection!.length; i += 1) {
      _markUnitAsChanged(currentUnitShareDetails, docUnitCollection[i]);
    }
  } else if (currentUnitShareDetails) {
    for (let i = currentUnitIndex; i >= 0; i -= 1) {
      _markUnitAsChanged(currentUnitShareDetails, docUnitCollection[i]);
    }
  }
}

export function createNewShareOriginUnit(
  shareIndexUid: string | null,
  isShareStartUnit = false,
  isShareEndUnit = false
): Partial<IShareDetails> {
  return {
    uid: shareIndexUid,
    origin: true,
    type: 'ORIGIN',
    isShareStartUnit: isShareStartUnit,
    isShareEndUnit: isShareEndUnit,
    originDiffersFromSharedIndex: true
  };
}

export function canIgnoreUnit(unit: IUnit) {
  return !unit || unit.definitionId === 'ghost' || unit.definitionId === 'removed' || unit.definitionId === 'compliance-reg-tag';
}
