import HeaderProps from '../../../../sidetabs/sub/editComponent/components/HeaderProps';
import { IDocUnitProfile } from 'mm-types';
// TODO: should not be here, requires a fallback for tocables
export const docUnitProfile: IDocUnitProfile = {
  displayName: '',
  template: '',
  editPropsComponent: HeaderProps,
  editor: {
    formats: {
      uppercase: { inline: 'span', classes: 'arc-uppercase', exact: true },
      lowercase: { inline: 'span', classes: 'arc-lowercase', exact: true },
      capitalize: { inline: 'span', classes: 'arc-capitalize', exact: true }
    },
    forced_root_block: ''
  }
};
