import * as Reflux from 'reflux';
import * as scClient from '../../clients/shared-content';
import Store from '../Store';
import { ITag } from 'mm-types';

export type TagStoreEvent = {
  type: 'contentTagsRetrieved';
  state: State;
};

export type State = {
  contentTags: ITag[];
};

export class TagStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      contentTags: []
    };
  }

  async retrieveContentTags() {
    try {
      this.state.contentTags = await scClient.getTags();
      const event: TagStoreEvent = {
        type: 'contentTagsRetrieved',
        state: this.state
      };
      this.trigger(event);
      return this.state;
    } catch (err) {
      return this.state;
    }
  }
}

const singleton = Reflux.initStore<TagStore>(TagStore);
export default singleton;
