import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

interface Props {
  show: boolean;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  height?: number;
  opacity?: number;
}

const PROGRESS_STYLE: React.CSSProperties = {
  top: '50%',
  transform: 'translateY(-50%)'
};

const AreaProgress = (props: Props) => {
  if (!props.show) {
    return null;
  }
  const { top, bottom, left, right, opacity, height } = props;
  const containerStyle: React.CSSProperties = {
    position: 'absolute',
    top: top || 0,
    right: right || 0,
    bottom: bottom || 0,
    height: height || 'auto',
    left: left || 0,
    textAlign: 'center',
    backgroundColor: `rgba(255,255,255, ${opacity || 0.5})`
  };
  if (height) {
    delete containerStyle.bottom;
  }

  return (
    <div className="area-progress" style={containerStyle}>
      <CircularProgress style={PROGRESS_STYLE} />
    </div>
  );
};

export default AreaProgress;
