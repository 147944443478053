/* FRAMEWORK */
import * as React from 'react';
import * as Reflux from 'reflux';
/* COMPONENTS */
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import StyleOverrides from '../../selectedComponent/components/StyleOverrides';
import ParaSubTypes, { ParagraphSubStype } from '../../../../utils/units/ParaSubTypes';
import ToggleSection from '../../../../../misc/ToggleSection';
import NoProperties from '../../selectedComponent/components/NoProperties';
/* STORES */
import EditorStore from '../../../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
/* TYPES */
import { EditBlurData, IProject } from 'mm-types';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import GenericEditProps, { IGenericEditProps } from './GenericEditProps';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export type State = {
  paraSubType: ParagraphSubStype;
};

export default class ParagraphEditProps extends Reflux.Component<IGenericEditProps, State> {
  private unsubscribes: Function[] = [];
  private _userOpened: boolean;

  constructor(props: IGenericEditProps) {
    super(props);

    this.state = {
      paraSubType: 'default'
    };
  }

  componentWillUnmount() {
    this.unsubscribes.forEach((unsubfn) => unsubfn());
  }

  UNSAFE_componentWillMount() {
    this.unsubscribes.push(EditorStore.listen(this._onEditStoreUpdate, this));
    this._updateState();
  }

  _onEditStoreUpdate(e) {
    if (e.type === 'nestedUnitFocusChange') {
      // component stays mounted as we want it to persist on internal unit changes (e.g. table cells) otherwise would be mounted and unmounted on cursor changes
      if (!(e.data.focused === null && e.data.parent === null)) {
        this._updateState();
      }
    }
  }

  updateEditorOnInlineDataUnitSelect = (inlineSpanClass: string) => (selectedValue: string, index: number) => {
    const mutatedUnitHtml = this.mutateParagraphInlineData(inlineSpanClass, selectedValue, index);
    const updateUnitParams: EditBlurData = {
      isDirty: true,
      uid: this.props.selectedUnit?.uid,
      type: 'paragraph',
      html: mutatedUnitHtml
    };
    EditorStore.updateUnit(updateUnitParams, () => EditorStore.triggerUnitDomChange());
  };

  private mutateParagraphInlineData(inlineSpanClass: string, selectedValue: string, index: number) {
    const $unitEl = $(this.props.selectedUnit?.html ?? '');

    if (inlineSpanClass === 'arc-inline-enum') {
      $unitEl.find('span.arc-inline-enum').eq(index).attr('data-unit', selectedValue).text(selectedValue);
      $unitEl.find('span.arc-inline').eq(index).attr('data-unit', selectedValue);
    }

    if (inlineSpanClass === 'arc-inline') {
      $unitEl.find('span.arc-inline').eq(index).text(selectedValue);
    }
    return $unitEl[0].outerHTML;
  }

  _updateState() {
    this.setState({
      paraSubType: this._getUnitSubType($(this.props.targetElement))
    });
  }

  _getUnitSubType($el): ParagraphSubStype {
    return (Object.keys(ParaSubTypes.props).filter((t) => $el.hasClass(t))[0] as ParagraphSubStype) || 'default';
  }

  render() {
    const project: IProject | undefined = ProjectStore.getProject();
    const hasDataAttributes = hasDefinitionDataAttributes(
      this.props.definition,
      this.props.parentDefinition.id,
      getElmDef(this.props.selectedUnit?.definitionId)
    );
    if (project && this.props.unitProfile) {
      const profileId = this.props.unitProfile!.subType
        ? this.props.unitProfile!.subType + this.props.unitProfile!.type!
        : this.props.unitProfile!.type!;
      // FIXME replumb elementStyle overrides from new home in projectDefinition
      const elementProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getElementProfileByDefinitionId(
        profileId.toLowerCase()
      );
      if (ProjectDefinitionStore.allowUnitIndentation() || (elementProfile && elementProfile.styles && elementProfile.overridable)) {
        return (
          <div className="toggle-section-outer selected-unit-props-container subaction-edit">
            <ToggleSection
              title="Paragraph Properties"
              id={'unitProps_' + this.props.index}
              defaultOpen={false}
              forceClose={!this.props.isLeaf}
              forceOpen={this.props.isLeaf || this._userOpened}
              onToggled={(isOpened) => {
                this._userOpened = isOpened;
              }}
            >
              {elementProfile && elementProfile.styles && elementProfile.overridable ? (
                <div key="tabFormatting" className="edit-props-section">
                  <div>
                    <div className="edit-props-subsection">
                      <StyleOverrides
                        unitElement={this.props.unitElement}
                        targetElement={this.props.targetElement}
                        elementProfile={elementProfile}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {UnitUtils.isFormatingAvailable(this.props.unitElement) && (
                <MultiFormatting formatNode={this.props.index === 0 ? null : this.props.unitElement} unitProfile={this.props.unitProfile} />
              )}
              <DataAttributesPropsWrapper {...this.props} />
            </ToggleSection>
          </div>
        );
      } else {
        if (!this.props.isOneOfMultiple && !hasDataAttributes) {
          return <NoProperties />;
        } else if (hasDataAttributes) {
          return <GenericEditProps {...this.props} />;
        } else {
          return (
            <div className="toggle-section-outer selected-unit-props-container">
              <ToggleSection
                title="Paragraph Properties"
                id={'unitProps_' + this.props.index}
                defaultOpen={false}
                forceClose={true}
                forceOpen={false}
                enabled={false}
              />
            </div>
          );
        }
      }
    } else {
      return null;
    }
  }
}
