import * as React from 'react';
import MediaStore, { MediaStoreEvent, MediaStoreEventType, State as MediaStoreState } from '../../../flux/editor/MediaStore';
import AppStateStore from '../../../flux/common/AppStateStore';
import * as _ from 'lodash';
import Dropzone from 'react-dropzone';
import { IMedia } from 'mm-types';
import Paging, { PaginationProps } from '../../general/Paging';
import MediaItemThumb from './components/MediaLibContent/MediaItemThumb';
import MediaItemLoadingThumbs from './components/MediaLibContent/MediaItemLoadingThumbs';
import withMediaStoreSubscription from './hoc/dropzoneWithMediaStoreSubscription';
import MediaUploadDialog from './MediaUploadDialog';
import AreaProgress from '../../general/AreaProgress';
import DropzoneExpandingDropArea from '../../general/DropzoneExpandingDropArea';
import ScrollableDiv from '../../general/ScrollableDiv';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import useListenToStore from '../../hooks/useListenToStore';
import { MediaTypes } from './types';

export type MediaLibContentType = {
  getSelectedMedia(): IMedia[];
};

export type Props = {
  onOpen: (media: IMedia) => void;
  onSelected: (media: IMedia[]) => void;
  hasSymbols: boolean;
  selectedMediaItemUid?: string;
};

export type Media = {
  uid: string;
  caption: string;
  description: string;
  filename: string;
  location: string;
  type: MediaTypes;
  mimeType: string;
};

export type State = {
  isFetching: boolean;
  isUploading: boolean;
  expandSnackBarWidth: boolean;
  selected: IMedia[];
  media: IMedia[];
  pagination: PaginationProps;
  loadingThumbs: number;
  initialTopPosition: number;
  lastTopPosition: number;
};

const DropzoneWithMediaStore = withMediaStoreSubscription(Dropzone);

const MediaLibContent = (props: Props, ref: React.Ref<MediaLibContentType>) => {
  const containerRef = useRef<ScrollableDiv>(null);
  const [state, setState] = useState<State>({
    expandSnackBarWidth: false,
    isFetching: true,
    isUploading: false,
    selected: [],
    media: [],
    pagination: MediaStore.getInitialState().pagination,
    loadingThumbs: MediaStore.getInitialState().loadingThumbs,
    initialTopPosition: MediaStore.getScrollTopPosition(),
    lastTopPosition: 0
  });

  useListenToStore({ store: MediaStore, eventListener: onMediaStoreChange });

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    AppStateStore.updateLastSelectedMediaItems(state.selected);
    props.onSelected(state.selected);
  }, [state.selected]);

  useImperativeHandle(ref, () => ({
    getSelectedMedia
  }));

  function onMediaStoreChange(event: MediaStoreEvent<MediaStoreEventType>) {
    if (event.type === 'applyChanges') {
      const storeState = event.data as MediaStoreState;
      const fetchingProps = {
        isFetching: storeState.isFetching,
        isUploading: storeState.isUploading,
        loadingThumbs: storeState.loadingThumbs,
        selected: getCurrentMediaFromReturnedMediaIfOnFirstPage(storeState)
      };
      props.onSelected(fetchingProps.selected);

      if (storeState.isFetching) {
        setState((prevState) => ({ ...prevState, ...fetchingProps, pagination: storeState.pagination }));
      } else if (storeState.isUploading) {
        setState((prevState) => ({ ...prevState, ...fetchingProps }));
      } else {
        setState((prevState) => ({ ...prevState, ...fetchingProps, ...event.data }));
        if (containerRef.current) {
          containerRef.current.scrollTop();
        }
      }
    }
  }

  const getCurrentMediaFromReturnedMediaIfOnFirstPage = ({ searchParams, media, pagination }: MediaStoreState) => {
    if (searchParams.selectedMediaItemUid && pagination.pageNumber === 0) {
      return [media[0]];
    } else {
      return AppStateStore.getLastSelectedMediaItems();
    }
  };

  const getList = () => MediaStore.retrieveMedia({ pageNumber: 0, selectedMediaItemUid: props.selectedMediaItemUid });

  const getSelectedMedia = () => {
    return state.selected;
  };

  const isMediaSelected = (mediaUid: string): boolean => {
    return _.find(state.selected, { uid: mediaUid }) ? true : false;
  };

  const handleSelect = (media: Media) => {
    const mediaUid = media.uid;
    let selectedMediaItems = state.selected;
    let selectedMedia = _.find(selectedMediaItems, (m) => m.uid === mediaUid);

    if (props.hasSymbols) {
      const media = _.find(state.media, (m) => m.uid === mediaUid);
      if (media) {
        selectedMediaItems = [media];
      }
    } else {
      if (!selectedMedia) {
        const media = _.find(state.media, (m) => m.uid === mediaUid);
        media && selectedMediaItems.push(media);
      } else {
        _.remove(selectedMediaItems, (m) => m.uid === mediaUid);
      }
    }

    setState((prevState) => ({ ...prevState, selected: [...selectedMediaItems] }));
  };

  const onScrolled = (topPosition: number) => {
    setState((prevState) => ({ ...prevState, lastTopPosition: topPosition }));
  };

  const handleOpen = (media: Media) => {
    MediaStore.preservePagination(true, state.lastTopPosition);
    const mediaUid = media.uid;
    // ensure its *always* selected after a double click
    const selectedMediaItems = state.selected;
    let selectedMedia: IMedia | undefined = _.find(selectedMediaItems, (m) => m.uid === mediaUid);

    if (!selectedMedia) {
      selectedMedia = _.find(state.media, (m) => m.uid === mediaUid);
      selectedMedia && selectedMediaItems.push(selectedMedia);
    }
    setState((prevState) => ({ ...prevState, selected: [...selectedMediaItems] }));
    selectedMedia && props.onOpen(selectedMedia);
  };

  const handlePageChange = (pageNumber: number) => {
    setState((prevState) => ({ ...prevState, pagination: Object.assign(state.pagination, { pageNumber }) }));
    MediaStore.goToPage(pageNumber);
  };

  const { isFetching, media, loadingThumbs, isUploading, pagination, initialTopPosition } = state;
  const generalLoading = isFetching && loadingThumbs === 0;
  const pagingLoading = isFetching && loadingThumbs > 0;
  return (
    <ScrollableDiv
      className="medialib-content-container animation-fade-in"
      initialTopPosition={initialTopPosition}
      onScrolled={onScrolled}
      ref={containerRef}
    >
      <ul className="medialib-content-thumbs">
        <li className="upload-thumb" key="dropuploader">
          <DropzoneWithMediaStore className="upload-dropzone">
            <i className="material-icons">add_circle</i>
            <label>
              Drop files anywhere, or <br /> click to upload
            </label>
            <DropzoneExpandingDropArea />
          </DropzoneWithMediaStore>
        </li>
        <MediaItemLoadingThumbs show={pagingLoading} numberOfItems={loadingThumbs} />

        {media &&
          media.length > 0 &&
          media.map((media) => {
            return (
              <MediaItemThumb
                key={media.uid}
                media={media}
                selected={isMediaSelected(media.uid)}
                onItemSelect={handleSelect}
                onItemOpen={handleOpen}
              />
            );
          })}
      </ul>
      <div className="clear-both">
        <Paging page={pagination} moveToPage={handlePageChange} />
      </div>
      <AreaProgress show={generalLoading} opacity={0.8} />
      <MediaUploadDialog show={isUploading} />
    </ScrollableDiv>
  );
};

export default React.forwardRef(MediaLibContent);
