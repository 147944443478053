import * as _ from 'lodash';
import AppStateStore from '../../../../flux/common/AppStateStore';
import { CustomTinyEventTypes } from './CustomTinyEventTypes';
import TinyFacade from './TinyFacade';
import TableUtils from '../../../../utils/TableUtils';

function _toggleFormat(name, editor, tinyFacade: TinyFacade | null, isCell = false) {
  editor.undoManager.transact(function () {
    if (tinyFacade) {
      const elm = isCell ? TableUtils.getSelectedCells() : tinyFacade.getSelectedTable();
      'left center right justify'.split(' ').forEach(function (align) {
        if (isCell) {
          (elm as Array<Element>).map((cellElm) => {
            editor.formatter.remove('align' + align, {}, cellElm);
          });
        } else {
          editor.formatter.remove('align' + align, {}, elm);
        }
      });
      if (isCell) {
        (elm as Array<Element>).map((cellElm) => {
          editor.formatter.apply(name, {}, cellElm);
        });
      } else {
        editor.formatter.apply(name, {}, elm);
      }
    } else {
      editor.formatter.toggle(name);
    }
    editor.nodeChanged();
  });
}

function _configPasteBuffer(editor) {
  // check current instance buffer
  const clipboardRows = editor.plugins.table.getClipboardRows();
  // if empty check our store buffer and use it if set
  if (!clipboardRows || (clipboardRows && clipboardRows.length === 0)) {
    const storedClipboardRows = AppStateStore.getTableClipboardRows();
    if (storedClipboardRows && storedClipboardRows.length > 0) {
      try {
        editor.plugins.table.setClipboardRows(storedClipboardRows);
      } catch (e) {
        // TinyMCE update pending
      }
    }
  }
}

const commandLoader = function (editor, tinyFacade: TinyFacade) {
  editor.addCommand('arcCellSetStyle', function (ui, params) {
    if (params) {
      editor.undoManager.transact(function () {
        _.each(params.style, function (style) {
          if (style) {
            const selection = tinyFacade.getActionSelection(style);
            let styleName = Object.keys(style)[0];

            if (styleName.match(/^border-.*/)) {
              const table = $(selection[0]).closest('TABLE')[0];
              if (table) {
                const selectionWithMeta = tinyFacade.getDeSpannedTableSelectionWithMetaData(table, selection);
                let elements: any[] = [];

                if (styleName === 'border-horizontal') {
                  styleName = 'border-top';
                  style['border-top'] = style['border-horizontal'];
                  style['border-horizontal'] = null;
                  elements = tinyFacade.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, styleName);
                } else if (styleName === 'border-vertical') {
                  styleName = 'border-right';
                  style['border-right'] = style['border-vertical'];
                  style['border-vertical'] = null;
                  elements = tinyFacade.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, styleName);
                } else {
                  elements = tinyFacade.getTableEdgeSelectionWithMetaData(selectionWithMeta, styleName);
                }

                _.each(elements, function (itemWithMeta: any) {
                  const top = itemWithMeta.neighbours.top,
                    right = itemWithMeta.neighbours.right,
                    bottom = itemWithMeta.neighbours.bottom,
                    left = itemWithMeta.neighbours.left;

                  // Remove neighbour's border
                  if (styleName === 'border-top' && top && top.elem !== itemWithMeta.elem) {
                    editor.dom.setStyles(top.elem, { 'border-bottom': 'none' });
                    top.elem.removeAttribute('data-border-color-bottom-day');
                    top.elem.removeAttribute('data-border-color-bottom-night');
                  } else if (styleName === 'border-right' && right && right.elem !== itemWithMeta.elem) {
                    editor.dom.setStyles(right.elem, { 'border-left': 'none' });
                    right.elem.removeAttribute('data-border-color-left-day');
                    right.elem.removeAttribute('data-border-color-left-night');
                  } else if (styleName === 'border-bottom' && bottom && bottom.elem !== itemWithMeta.elem) {
                    editor.dom.setStyles(bottom.elem, { 'border-top': 'none' });
                    bottom.elem.removeAttribute('data-border-color-top-day');
                    bottom.elem.removeAttribute('data-border-color-top-night');
                  } else if (styleName === 'border-left' && left && left.elem !== itemWithMeta.elem) {
                    editor.dom.setStyles(left.elem, { 'border-right': 'none' });
                    left.elem.removeAttribute('data-border-color-right-day');
                    left.elem.removeAttribute('data-border-color-right-night');
                  }

                  let borderStyleWithoutColor = {};
                  const colorDataAttributePosition = styleName.substr(7, styleName.length - 7);

                  if (style[styleName]) {
                    if (style[styleName].width === '0') {
                      borderStyleWithoutColor[styleName] = '';

                      // clearing style so remove data color attributes as well
                      itemWithMeta.elem.removeAttribute(`data-border-color-${colorDataAttributePosition}-day`);
                      itemWithMeta.elem.removeAttribute(`data-border-color-${colorDataAttributePosition}-night`);
                    } else if (style[styleName].color.day.id === 'ClearColor') {
                      borderStyleWithoutColor[styleName] = `${style[styleName].width} ${style[styleName].style}`;

                      // clearing style so remove data color attributes as well
                      itemWithMeta.elem.removeAttribute(`data-border-color-${colorDataAttributePosition}-day`);
                      itemWithMeta.elem.removeAttribute(`data-border-color-${colorDataAttributePosition}-night`);
                    } else {
                      borderStyleWithoutColor[styleName] = `${style[styleName].width} ${style[styleName].style} transparent`;

                      itemWithMeta.elem.setAttribute(`data-border-color-${colorDataAttributePosition}-day`, style[styleName].color.day.id);
                      if (style[styleName].color.day.id !== style[styleName].color.night.id) {
                        itemWithMeta.elem.setAttribute(
                          `data-border-color-${colorDataAttributePosition}-night`,
                          style[styleName].color.night.id
                        );
                      }
                    }

                    // Set border on the selected element
                    editor.dom.setStyles(itemWithMeta.elem, borderStyleWithoutColor);
                  }
                });
              }
            } else {
              if (selection && Array.isArray(selection) && selection[0] !== null && selection[0].length !== 0) {
                let dayStyle, nightStyle;
                _.each(selection, function (el) {
                  if (el) {
                    if (styleName === 'background' || styleName === 'color') {
                      const prefix = styleName === 'background' ? 'bg' : '';
                      dayStyle = style[styleName]?.day ?? dayStyle;
                      nightStyle = style[styleName]?.night ?? nightStyle;
                      if (dayStyle?.id === 'ClearColor') {
                        el.removeAttribute(`data-${prefix}color-day`);
                        el.removeAttribute(`data-${prefix}color-night`);
                      } else {
                        el.setAttribute(`data-${prefix}color-day`, dayStyle.id);
                        if (dayStyle.id !== nightStyle.id) {
                          el.setAttribute(`data-${prefix}color-night`, nightStyle.id);
                        }
                      }
                      // Also remove older way of setting colors through the inline styles
                      style[styleName] = 'none';
                      editor.dom.setStyles(el, style);
                      // don't set style for the color/background anymore
                      return;
                    }

                    if (style[styleName].match(/^(px|%)$/i)) {
                      if (styleName === 'height') {
                        // unset row height as well
                        const tr = $(el).closest('tr')[0];
                        editor.dom.setStyle(tr, styleName, '');
                      }
                      editor.dom.setStyle(el, styleName, '');
                    } else {
                      if (styleName === 'height') {
                        // containing row set to match
                        const tr = $(el).closest('tr')[0];
                        editor.dom.setStyles(tr, style);
                      }
                      editor.dom.setStyles(el, style);
                    }
                  }
                });
              }
            }
          }
        });
      });
    }
  });
  editor.addCommand('arcTableSetStyle', function (ui, params) {
    if (params) {
      const focusElm = (params.focusElm ? params.focusElm : tinyFacade.getSelectedTable()) as Element;
      editor.undoManager.transact(function () {
        const styleName = Object.keys(params.style)[0];
        if (params.style[styleName].match(/^(px|%)$/i)) {
          editor.dom.setStyle(focusElm, styleName, '');
        } else {
          editor.dom.setStyles(focusElm, params.style);
        }
      });
    }
  });
  editor.addCommand('arcTableAlignLeft', function () {
    _toggleFormat('alignleft', editor, tinyFacade);
  });
  editor.addCommand('arcTableAlignCentre', function () {
    _toggleFormat('aligncenter', editor, tinyFacade);
  });
  editor.addCommand('arcTableAlignRight', function () {
    _toggleFormat('alignright', editor, tinyFacade);
  });
  editor.addCommand('arcVAlignTop', function () {
    _toggleFormat('valigntop', editor, null);
  });
  editor.addCommand('arcVAlignMiddle', function () {
    _toggleFormat('valignmiddle', editor, null);
  });
  editor.addCommand('arcVAlignBottom', function () {
    _toggleFormat('valignbottom', editor, null);
  });
  editor.addCommand('arcHAlignLeft', function () {
    _toggleFormat('alignleft', editor, tinyFacade, true);
  });
  editor.addCommand('arcHAlignCenter', function () {
    _toggleFormat('aligncenter', editor, tinyFacade, true);
  });
  editor.addCommand('arcHAlignRight', function () {
    _toggleFormat('alignright', editor, tinyFacade, true);
  });
  editor.addCommand('arcHAlignJustify', function () {
    _toggleFormat('alignjustify', editor, tinyFacade, true);
  });
  editor.addCommand('arcHAlignChar', function () {
    _toggleFormat('', editor, tinyFacade, true);
  });
  editor.addCommand('arcTableDelete', function () {
    if (tinyFacade.isRootTable()) {
      editor.fire('ARC_DELETE' as CustomTinyEventTypes, { blur: true });
    } else {
      editor.undoManager.transact(function () {
        if (editor.selection.getNode().nodeName === 'TABLE') {
          // mceTableDelete gets in a twist if focus is not within actual table cell
          editor.selection.setCursorLocation(editor.dom.select('td:first', editor.selection.getNode())[0], 0);
        }
        editor.execCommand('mceTableDelete', false, null);
      });
    }
  });
  editor.addCommand('arcRotation0', function () {
    editor.undoManager.transact(function () {
      _.each(TableUtils.getSelectedCells(), function (el) {
        if (editor.formatter.canApply('vertical90') && editor.formatter.canApply('vertical270')) {
          editor.formatter.remove('vertical90', {}, el);
          editor.formatter.remove('vertical270', {}, el);
        }
      });
    });
  });
  editor.addCommand('arcRotation90', function () {
    editor.undoManager.transact(function () {
      _.each(TableUtils.getSelectedCells(), function (el) {
        if (editor.formatter.canApply('vertical90')) {
          editor.formatter.remove('vertical270', {}, el);
          editor.formatter.apply('vertical90', {}, el);
        }
      });
    });
  });
  editor.addCommand('arcRotation270', function () {
    editor.undoManager.transact(function () {
      _.each(TableUtils.getSelectedCells(), function (el) {
        if (editor.formatter.canApply('vertical270')) {
          editor.formatter.remove('vertical90', {}, el);
          editor.formatter.apply('vertical270', {}, el);
        }
      });
    });
  });
  editor.addCommand('arcToggleCaption', function (ui, type) {
    if (type) {
      editor.undoManager.transact(function () {
        tinyFacade.toggleCaption(type);
      });
    }
  });
  editor.addCommand('arcFontSize', function (ui, size) {
    if (size) {
      editor.undoManager.transact(function () {
        _.each(TableUtils.getSelectedCells(), function (el) {
          editor.formatter.apply('fontsize', { value: size }, el);
        });
      });
    }
  });
  editor.addCommand('arcTextTransform', function (ui, styleName) {
    if (styleName) {
      editor.formatter.toggle(styleName);
    }
  });
  editor.addCommand('overline', function () {
    editor.undoManager.transact(function () {
      editor.formatter.toggle('overline');
    });
  });
  editor.addCommand('code', function () {
    editor.undoManager.transact(function () {
      editor.formatter.toggle('code');
    });
  });
  editor.addCommand('arcClearFontSize', function () {
    editor.undoManager.transact(function () {
      _.each(TableUtils.getSelectedCells(), function (el) {
        editor.formatter.remove('fontsize', {}, el);
      });
    });
  });
  editor.addCommand('arcTableCopyRow', function () {
    // call normal row copy
    editor.execCommand('mceTableCopyRow', false, null);
    // store row buffer
    try {
      const clipboardRows = editor.plugins.table.getClipboardRows();
      AppStateStore.updateTableClipboardRows(clipboardRows);
    } catch (e) {
      // TinyMCE update pending
    }
  });
  editor.addCommand('arcTablePasteRowBefore', function () {
    _configPasteBuffer(editor);
    editor.execCommand('mceTablePasteRowBefore', false, null);
  });
  editor.addCommand('arcTablePasteRowAfter', function () {
    _configPasteBuffer(editor);
    editor.execCommand('mceTablePasteRowAfter', false, null);
  });
};

export default commandLoader;
