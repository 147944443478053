import { IElementSettings } from 'mm-types';

/**
 * @param {string}  id - id of the setting.
 * @param {IElementSettings[]} elmSettings - list of settings
 * @returns {boolean} `false` when setting is found on the list and it's false, `true` - if setting is found on the list and it's true OR setting is not found on the list OR id or elmSettings is undefined
 */
export function settingEnabled(id?: string, elmSettings?: IElementSettings[]): boolean {
  if (elmSettings && id) {
    return elmSettings.find((setting) => setting.id === id)?.enabled ?? true;
  }
  return true;
}

/**
 * @returns {IElementSettings[]} combined array of settings. Each setting value has to be true for combined setting to be true.
 * @param {IElementSettings[][]} settings - an array of settings to combine.
 */
export function combineSettings(settings: IElementSettings[][]): IElementSettings[] {
  return settings.reduce((existingSettings: IElementSettings[], nextSettings) => {
    if (!nextSettings) {
      return existingSettings;
    }

    // if first settings then just return it
    if (!existingSettings || existingSettings.length === 0) {
      return [...nextSettings];
    }

    // update existing settings with values from currentDefinitionSettings
    let combinedSettings = existingSettings.map((setting) => {
      // return setting if it's already disabled
      if (!setting.enabled) {
        return setting;
      }
      // otherwise, check if it's disabled in the currentDefinitionSettings
      else {
        setting.enabled = settingEnabled(setting.id, nextSettings);
      }
      return { ...setting };
    });

    // add any nextSettings missing in combinedSettings
    nextSettings.forEach((setting) => {
      const isNotFound = combinedSettings.findIndex((set) => set.id === setting.id) === -1;
      if (isNotFound) {
        combinedSettings = [...combinedSettings, setting];
      }
    });

    return combinedSettings;
  }, []);
}
