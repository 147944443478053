import { IDocUnitProfile } from 'mm-types';
import { Settings } from 'tinymce';
import _ from 'lodash';

const BORDER_STYLES = [
  'border-top',
  'border-top-color',
  'border-top-width',
  'border-top-style',
  'border-bottom',
  'border-bottom-color',
  'border-bottom-width',
  'border-bottom-style',
  'border-left',
  'border-left-color',
  'border-left-width',
  'border-left-style',
  'border-right',
  'border-right-color',
  'border-right-width',
  'border-right-style'
];
const PADDING_STYLES = ['padding-top', 'padding-right', 'padding-left', 'padding-bottom', 'padding'];

export default function baseEditor(unit: IDocUnitProfile): Settings {
  return {
    inline: true,
    toolbar: '',
    menubar: false,
    encoding: 'xml',
    indentation: '1em',
    convert_urls: false,

    noneditable_noneditable_class: 'arc-editor-not-editable',

    valid_styles: {
      svg: 'display,color,fill,height,width,user-select,transition',
      figure: 'text-align',
      figcaption: 'text-align',
      span: 'width,height,text-decoration,white-space,-webkit-writing-mode,-ms-writing-mode,transform,color,background-color',
      div: 'top,left,width,max-width,height',
      p: 'text-align,padding-left,width',
      img: 'width,height,text-align,transform',
      video: 'width,height,text-align',
      table: _.flatten([
        'background,width,height,float,margin-left,margin-right,border,border-width,border-style,border-color,table-layout',
        BORDER_STYLES
      ]).join(','),
      tr: 'height',
      td: _.flatten([
        'text-align,background,color,width,height,vertical-align,font-size,border,border-width,border-style,border-color',
        BORDER_STYLES,
        PADDING_STYLES
      ]).join(',')
    },

    valid_children: '+div[style],+span[style],+p[style],+a[class|href]',
    valid_elements:
      'svg[*],path[*],hr[class],style,figure[class|style],figcaption[class|style],img[src|class|style],video[class|style|preload|controls|muted],audio[class|style|preload|controls|muted|src],source[src],table[class|style],thead[class],tfoot[class],tbody[class],caption[class],td[class|style|rowspan|colspan],tr[class|style],li[class],ul[class|data-list-type],ol[class],br[class],p[class|style],span[class|style],div[class|style|left-label],bold,strong,em,i,u,sup,sub,ins,a[class|href],code[class],pre[class],source[src|type]',
    extended_valid_elements: '+*[data-*]',

    // these belong to the paste plugin: but leave here for now as we use this everywhere currently
    paste_as_text: false, // essential if pasting content that has loads of junk tags etc in it
    paste_auto_cleanup_on_paste: true,
    paste_block_drop: true,
    paste_postprocess: function (pl, o) {
      o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/gi, ' ').replace(/\r?\n|\r/g, ' ');

      const elm = $(o.node);

      // Remove all elements that are not supported from a paste
      let allowedElements = 'b,strong,a,span,em,code';
      if (unit.editor && unit.editor.preserve_br) {
        allowedElements += ',br';
      }
      const $elements = elm.find('*').not(allowedElements);
      for (let i = $elements.length - 1; i >= 0; i--) {
        const e = $elements[i];
        $(e).replaceWith(e.innerHTML);
      }

      // Go through each of the anchors and remove the nid,
      // and  if an inter-doc or internal type, then also remove the href (re-generated from the server)
      elm.find('a').each(function () {
        const anchor = $(this);
        const dataType = anchor.attr('data-type');

        anchor.removeAttr('data-nid');
        if ((dataType && dataType === 'inter-doc') || dataType === 'internal') {
          anchor.removeAttr('href');
        }
      });
    },
    // custom span for text formatting of underline, subscript and superscript also uppercase, lowercase & capitalize
    formats: {
      underline: { inline: 'span', classes: 'arc-underline', exact: true },
      overline: { inline: 'span', classes: 'arc-overline', exact: true },
      subscript: { inline: 'span', classes: 'arc-sub-script', exact: true },
      superscript: { inline: 'span', classes: 'arc-super-script', exact: true },
      forecolor: {
        inline: 'span',
        classes: 'arc-color-text',
        attributes: {
          'data-tinymce-placeholder': 'placeholder'
        },
        // a custom match format logic for attributes and styles. Match regardless of attributes or styles for forecolor.
        onmatch: () => true,
        links: true,
        remove_similar: false,
        clear_child_styles: true,
        exact: true,
        // merge_siblings has to be false otherwise applying a color Green to test2 will use a color Yellow from test1 in below example:
        // <span class='arc-color-text' data-color-day='Yellow'>test1</span>test2
        // explanation: test1 color yellow format and just to be applied color green format are the same according to format matching logic
        // if merge_siblings was true then tinymce would extend <span> around test1 to test2 so test2 would have Yellow not Green applied
        merge_siblings: false
      },
      hilitecolor: {
        inline: 'span',
        classes: 'arc-bg-color-text',
        attributes: {
          'data-tinymce-placeholder': 'placeholder'
        },
        // a custom match format logic for attributes and styles. Match regardless of attributes or styles for hilitecolor.
        onmatch: () => true,
        links: true,
        remove_similar: false,
        clear_child_styles: true,
        exact: true,
        // see explanation for similar in forecolor
        merge_siblings: false
      },
      uppercase: { inline: 'span', classes: 'arc-uppercase', exact: true },
      lowercase: { inline: 'span', classes: 'arc-lowercase', exact: true },
      capitalize: { inline: 'span', classes: 'arc-capitalize', exact: true },
      vertical90: { inline: 'span', wrapper: true, classes: 'arc-rotate-right', exact: true },
      vertical270: { inline: 'span', wrapper: true, classes: 'arc-rotate-left', exact: true }
    }
  };
}
