import * as React from 'react';
import { MenuItem as Item } from 'material-ui';
import menuHelper from './MenuHelper';
import classNameHelper from '../../../../utils/classNameHelper';
import { PrimaryTextWithTooltip } from '../../../misc/MenuItemPrimaryText/PrimaryText';

export interface MenuItemProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  description?: string;
  key?: string;
  value: string;
  children?: React.ReactNode;
}

const MenuItem = (props: MenuItemProps) => {
  const [selected, setSelected] = React.useState<EventTarget | null>();
  const onItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    menuHelper.selectItem(props.value, event.currentTarget);
  };

  const onItemWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isEnabled()) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const isEnabled = () => {
    return !props.disabled;
  };

  const className = classNameHelper.merge(`editor-menu-item ${props.className || ''}`, {
    'item-disabled': !!props.disabled
  });
  const title = props.children as string;
  return (
    <div onClick={onItemWrapperClick}>
      <Item
        disabled={props.disabled}
        value={props.value}
        primaryText={
          !props.description || props?.description === title ? (
            title
          ) : (
            <PrimaryTextWithTooltip
              selectedMenuItem={selected}
              tooltip={props.description}
              id={'textWithPopoverToolTip_' + props.id}
              className="popover-tooltip"
              disabled={props.disabled}
              popoverTooltip={true}
            >
              <span>{title}</span>
            </PrimaryTextWithTooltip>
          )
        }
        onMouseEnter={(e) => setSelected(e.currentTarget)}
        onMouseLeave={() => setSelected(null)}
        onClick={onItemClick}
        className={className}
        data-qa="editor-menu-item"
      />
    </div>
  );
};

export default MenuItem;
