import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import { CSSSelector } from './CssSelectorUtil';

export namespace UnitIdentifier {
  export function getType(element: HTMLElement | Element): string {
    const parentUnit = findParent(element);
    const result: string | null = parentUnit ? getElementType(parentUnit) : getUnitType(element.closest(CSSSelector.unit()));
    if (!result) {
      throw getErrorMessage(element);
    }
    return result;
  }
}

function getUnitType(element: Element | null): string | null {
  if (!element) {
    return null;
  }
  return getDefinition(element, 'data-unit-family');
}

function getElementType(element: Element | null): string | null {
  if (!element) {
    return null;
  }
  const result = getDefinition(element, 'data-element-family');

  if (!result) {
    return getUnitType(element.closest(CSSSelector.unit()));
  }
  return result;
}

function getDefinition(element: Element, family: string): string | null {
  const elementFamily = element.getAttribute(family);
  if (elementFamily) {
    const parentUnitFamily = ProjectDefinitionStore.toUnitDefinitionId(elementFamily!);
    return parentUnitFamily === 'collectioncontent' ? 'collection' : parentUnitFamily;
  }
  return null;
}

function findParent(element: HTMLElement | Element): Element | null {
  const arcElements =
    "[class*='arc-']:not([class^='arc-table-']):not([class^='arc-tocable-']):not(.arc-underline):not(.arc-action-challenge):not(.arc-action-challenge-response):not(.arc-part)";
  return element.closest(`.element-container, .arc-paragraph, ${arcElements}`);
}

function getErrorMessage(element: HTMLElement | Element): string {
  const attrs: string[] = [];
  for (let i = 0; i < element.attributes.length; i++) {
    attrs.push(`${element.attributes[i].name}="${element.attributes[i].value}"`);
  }
  return `No unit type for given element <${element.nodeName.toLowerCase()} ${attrs.join(' ')} />`;
}
