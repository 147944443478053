import * as React from 'react';
import ColorPicker from './color/ColorPicker';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import useListenToStore from '../../../../../hooks/useListenToStore';
import { Color } from 'mm-types';
import TableUtils from '../../../../../../utils/TableUtils';

interface Props {
  type: PickerType;
  color?: string;
  icon?: 'format_color_fill' | 'format_color_text' | string;
  disabled?: boolean;
  tooltip: string;
}

type PickerType = 'background' | 'color';

function TableCellColor(props: Props) {
  useListenToStore({ store: EditorStore, eventListener: subscribeToTinyEvent });

  const defaultBgColor = {
      day: { id: '', name: '', value: 'rgb(255, 255, 255)' },
      night: { id: '', name: '', value: 'rgb(255, 255, 255)' }
    },
    defaultColor = { day: { id: '', name: '', value: 'rgb(0,0,0)' }, night: { id: '', name: '', value: 'rgb(0,0,0)' } };

  const [color, setColor] = React.useState<Color>(props.type === 'background' ? defaultBgColor : defaultColor);

  function subscribeToTinyEvent(e = { type: null }) {
    if (e.type === 'nestedUnitFocusChange') {
      const tinyFacade = (EditorStore as any).getEditor().getActiveEditorFacade()!;
      let color: Color;
      const isBackground = props.type === 'background';
      const defaultLocalColor: Color = isBackground ? defaultBgColor : defaultColor;

      const commonColor: Color | string = tinyFacade.getCommonColor(TableUtils.getSelectedCells(), isBackground ? 'background' : 'color');

      // Handle older version of applying colours through the inline styles (string)
      if (typeof commonColor === 'string') {
        if (!!commonColor) {
          color = { day: { id: '', name: '', value: commonColor }, night: { id: '', name: '', value: commonColor } };
        } else {
          color = defaultLocalColor;
        }
      } else {
        color = commonColor;
      }

      setColor(color ? color : defaultLocalColor);
      return { color: !!color ? color : defaultLocalColor };
    }
  }

  function changeColor(color: Color): void {
    setColor(color);

    const style = {};
    style[props.type] = color;
    (EditorStore as any)
      .getEditor()
      .getActiveEditorFacade()!
      .execCommand('arcCellSetStyle', { style: [style] });
    // remove selection so can see colour change immediately
    if (props.type === 'background') {
      (EditorStore as any).getEditor().getActiveEditorFacade()?.clearSelection({ clearAll: true });
    }
  }

  let title: JSX.Element | null = props.type === 'background' ? <h6>Background Colour</h6> : <h6>Font Color</h6>;

  return (
    <div className={'cell-background-component ' + props.type + 'tool'}>
      {title}
      <ColorPicker
        color={color}
        onChange={(c) => changeColor(c)}
        icon={props.icon}
        disabled={props.disabled}
        tooltip={props.tooltip}
        iconStyle={{ marginTop: '-10px' }}
      />
    </div>
  );
}

export default TableCellColor;
