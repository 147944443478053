import { CustomEditor } from './EditorInstanceManager';
import { ElementDetails } from '../units/ElementDetails';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;
import EditorStore from '../../../../flux/editor/EditorStore';
import LinkStore, { LinkStoreEvent } from '../../../../flux/editor/LinkStore';
import { Cancelled } from '../../../../clients/base-clients';
import linkService from '../../links/generic/linkService';

export const ATTRIBUTES = [
  'data-element-definition-id',
  'data-element-family',
  'data-type',
  'href',
  'data-nid',
  'class',
  'title',
  'rel',
  'target',
  'data-target-unit-uid',
  'data-target-variant-uid',
  'data-target-element-nid',
  'data-link-text-managed',
  'data-link-text',
  'data-target-index-uid',
  'data-target-project-uid',
  'data-arc-broken',
  'data-arc-link-stale',
  'data-product',
  'data-subtype',
  'data-role'
] as const;

export type Link = typeof ATTRIBUTES[number];

export type LinkHelperData = {
  text?: string;
  onlyText?: string;
  href?: string;
  target?: string;
};

export function linkHelper(editor?: Partial<CustomEditor>, elmToGetDataFrom?: HTMLElement) {
  const data: LinkHelperData = {},
    selection = editor?.selection,
    dom = editor?.dom;
  let selectedElm, anchorElm, initialText;
  let onlyText;

  selectedElm = selection?.getNode() ?? elmToGetDataFrom;
  anchorElm = dom?.getParent(selectedElm, 'a');
  onlyText = isOnlyTextSelected();

  data.text = initialText = anchorElm ? anchorElm.innerText || anchorElm.textContent : selection?.getContent({ format: 'text' });
  data.href = anchorElm ? dom?.getAttrib(anchorElm, 'href', '') : '';

  if (anchorElm) {
    data.target = dom?.getAttrib(anchorElm, 'target', '');
  } else if ((editor?.settings as any)?.default_link_target!) {
    data.target = (editor?.settings as any)?.default_link_target;
  }

  ATTRIBUTES.forEach(function (attr) {
    data[attr] = dom?.getAttrib(anchorElm, attr, '');
  });
  data.onlyText = onlyText;

  function isOnlyTextSelected(anchorElm?) {
    const html = selection?.getContent();

    // Partial html and not a fully selected anchor element
    if (html && /</.test(html) && (!/^<a [^>]+>[^<]+<\/a>$/.test(html) || html.indexOf('href=') === -1)) {
      return false;
    }

    if (anchorElm) {
      const nodes = anchorElm.childNodes;

      if (nodes.length === 0) {
        return false;
      }

      for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].nodeType !== 3) {
          return false;
        }
      }
    }

    return true;
  }

  function addLink(data) {
    const linkAttrs = {};

    ATTRIBUTES.forEach(function (attr) {
      linkAttrs[attr] = data[attr];
    });
    // Add class when creating link
    if (!linkAttrs['class']) {
      linkAttrs['class'] = 'arc-link';
    }
    if (!linkAttrs['data-element-family']) {
      linkAttrs['data-element-family'] = 'Link';
    }

    if (anchorElm) {
      // editor.focus();

      if (onlyText && data.text !== initialText) {
        if ('innerText' in anchorElm) {
          anchorElm.innerText = data.text;
        } else {
          anchorElm.textContent = data.text;
        }
      }

      dom?.setAttribs(anchorElm, linkAttrs);

      selection?.select(anchorElm);
      editor?.undoManager?.add();
    } else {
      if (onlyText) {
        const anchor = dom?.createHTML('a', linkAttrs, dom.encode(data.text));
        if (anchor) {
          editor?.selection?.setContent(anchor);
        }
      } else {
        if (editor?.execCommand) {
          editor?.execCommand('mceInsertLink', false, linkAttrs);
        }
      }
    }
  }

  async function populateTemplateLinkInfoIfNeeded(template: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (template.includes('arc-link')) {
        const $template = $(template);
        const link: HTMLElement = $template.find('.arc-link')[0];
        const linkType = getDataElementDefinitionId(link);
        EditorStore.triggerOpenLinkModal(linkType === 'DuRef', $template[0]);
        LinkStore.listen((event: LinkStoreEvent) => {
          if (event.type === 'close-link-modal') {
            if (event.templateHtmlModel) {
              const linkAttr = linkService.fromModelToLink(event.templateHtmlModel, linkType === 'DuRef');
              Object.keys(linkAttr).map((key) => {
                link.setAttribute(key, linkAttr[key]);
              });
              $template.find('.arc-link')[0].replaceWith(link);
              const updatedTemplateString = $template[0].outerHTML;
              return resolve(updatedTemplateString);
            }
            return reject(new Cancelled());
          }
        }, this);
      } else {
        return resolve(template);
      }
    });
  }

  function getLinkTypeIfAny(): string | boolean {
    return data['data-element-definition-id'] ?? false;
  }

  return {
    data: data,
    addLink: addLink,
    populateUnitLinkInfoIfNeeded: populateTemplateLinkInfoIfNeeded,
    getLinkTypeIfAny: getLinkTypeIfAny
  };
}
