import SockJS from 'sockjs-client';
import { Client, messageCallbackType } from '@stomp/stompjs';
import * as Reflux from 'reflux';
import Store from '../Store';
import { IUser } from 'mm-types';
import { StompSubscription } from '@stomp/stompjs/esm5/stomp-subscription';
import { SESSION_STORAGE_KEYS } from '../../utils';

const _WS_URL = '/events/editor';

export type EventClientStatus = {
  status: 'connecting' | 'connected' | 'disconnected';
};

export type EventStoreEvent = {
  type: 'connected' | 'connection-failed' | 'beforeDestroy' | 'connected-after-retry';
};

export type EventDestination = string | ((userUid: string) => string);
export type EventSubscriptionConfig = { destination: EventDestination; callback: messageCallbackType };
export type EventUnsubscribeFn = () => void;
export interface StoredEventSubscription extends EventSubscriptionConfig {
  subscription?: StompSubscription;
}

enum ConnectionType {
  WEBSOCKET = 'websocket',
  XHR = 'xhr-polling'
}

const STOMP_CONFIG = {
  SWITCH_CONNECTION_TYPE_AFTER: 3,
  RECONNECT_DELAY: 5000,
  MAX_ATTEMPTS: (15 * 60) / 5,
  HEARTBEAT: 5000,
  DEBUG: false
};

const CONNECTION_TYPES = [ConnectionType.WEBSOCKET, ConnectionType.XHR];

export class EventClient extends Store<void> {
  private client: Client | null;
  private connectionAttempts = 0;
  private clientConnected: Function;
  private clientNotConnected: Function;
  public onConnected = new Promise<any>((resolve, reject) => {
    this.clientConnected = resolve;
    this.clientNotConnected = reject;
  });
  private wasConnected = false;
  private subscribers = new Map<string, StoredEventSubscription>();
  private user: null | IUser;
  private initialSubscriptions?: EventSubscriptionConfig[];

  constructor() {
    super();
    this.user = null;
    this.connectionAttempts = 0;
  }

  get connectedUser() {
    return this.user;
  }

  getConnectionType(attempt: number): ConnectionType {
    const takeFirst = attempt % (STOMP_CONFIG.SWITCH_CONNECTION_TYPE_AFTER * 2) < STOMP_CONFIG.SWITCH_CONNECTION_TYPE_AFTER;
    return CONNECTION_TYPES[takeFirst ? 0 : 1];
  }

  destroyClient() {
    if (this.client) {
      this.unsubscribeAll();
      this.client.deactivate();
      this.client = null;
    }
  }

  unsubscribe(eventDestination: EventDestination) {
    const destination = this.getDestination(eventDestination);
    this.subscribers.get(destination)?.subscription?.unsubscribe();
    this.subscribers.delete(destination);
  }

  unsubscribeAll() {
    this.subscribers.forEach(({ destination }) => {
      this.unsubscribe(destination);
    });
  }

  initClient(user: IUser, initialSubscriptions?: EventSubscriptionConfig[]) {
    this.resetClientStats();
    this.createConfigPromise();
    this.user = user;
    this.initialSubscriptions = initialSubscriptions;
    this.destroyClient();

    this.client = new Client({
      brokerURL: `wss://${location.host}${_WS_URL}`,
      connectHeaders: {
        userUid: user.uid
      },
      logRawCommunication: true,
      debug(str) {
        if (
          localStorage.getItem(SESSION_STORAGE_KEYS.WEBSOCKET_DEBUG) &&
          str !== '<<< PONG' &&
          str !== '>>> PING' &&
          str !== 'Received data' &&
          !str.startsWith('<<<')
        ) {
          console.log(`%c${str}`, 'color: #027386; font-weight: bold');
        }
      },
      reconnectDelay: STOMP_CONFIG.RECONNECT_DELAY,
      heartbeatIncoming: STOMP_CONFIG.HEARTBEAT,
      heartbeatOutgoing: STOMP_CONFIG.HEARTBEAT,
      webSocketFactory: () => {
        const type = this.getConnectionType(this.connectionAttempts);
        this.sendEvent('connecting');
        if (this.connectionAttempts >= STOMP_CONFIG.MAX_ATTEMPTS) {
          this.sendEvent('disconnected');
          this.clientNotConnected();
          throw '[EventClient]: Max connection attempts reached. Giving up...';
        }
        this.connectionAttempts += 1;
        if (this.connectionAttempts >= 1) {
          this.log(`Connecting (${type}) ... ${this.connectionAttempts + ''}`);
        }
        return new SockJS(_WS_URL, null, {
          transports: type
        });
      },
      onStompError: () => {
        this.sendEvent('disconnected');
        this.logDisconnected();
      },
      onDisconnect: () => {
        this.sendEvent('disconnected');
        this.logDisconnected();
      },
      onWebSocketError: () => {
        this.sendEvent('disconnected');
        this.logDisconnected();
      },
      onWebSocketClose: () => {
        if (this.wasConnected) {
          this.sendEvent('disconnected');
        }
      }
    });

    this.client.onConnect = () => {
      this.sendEvent('connected');
      const type = this.getConnectionType(this.connectionAttempts);
      this.wasConnected = true;
      this.setStartingConnectionType();
      this.log(`Connected (${type})`);
      this.resetClientStats();

      if (this.client && initialSubscriptions?.length) {
        initialSubscriptions.forEach((config) => {
          this.client!.subscribe(this.getDestination(config.destination), config.callback);
        });
      }

      if (this.client && this.subscribers.size > 0) {
        this.subscribers.forEach((subscriber) => {
          subscriber.subscription?.unsubscribe();
          subscriber.subscription = this.client!.subscribe(this.getDestination(subscriber.destination), subscriber.callback);
        });
      }
      this.clientConnected(true);
    };

    this.client.activate();
  }

  reconnect(): Promise<void> {
    this.initClient(this.user!, this.initialSubscriptions);
    return this.onConnected;
  }

  subscribe(subscriber: EventSubscriptionConfig): EventUnsubscribeFn {
    if (this.client) {
      const { callback } = subscriber;
      const destination = this.getDestination(subscriber.destination);

      this.subscribers.get(destination)?.subscription?.unsubscribe();
      this.subscribers.set(destination, {
        callback,
        destination,
        subscription: this.client.subscribe(destination, callback)
      });
      return () => {
        this.unsubscribe(subscriber.destination);
      };
    } else {
      return () => {};
    }
  }

  publish(destination: string, body = '{}') {
    this.onConnected
      .then(() => {
        if (this.client) {
          this.client.publish({ destination, body, headers: { userUid: this.user?.uid ?? '' } });
        }
      })
      .catch(() => {
        this.log(`Event "${destination}" cannot be published`);
      });
  }

  log(message: string) {
    console.log(`%c[EventClient]: ${message}`, 'color: #666');
  }

  private getDestination(eventDestination: EventDestination): string {
    return typeof eventDestination === 'string' ? eventDestination : eventDestination(this.user?.uid ?? '');
  }

  private setStartingConnectionType() {
    const successfulConnectionType = this.getConnectionType(this.connectionAttempts - 1);
    if (successfulConnectionType !== CONNECTION_TYPES[0]) {
      CONNECTION_TYPES.reverse();
    }
  }

  private sendEvent(status: EventClientStatus['status']) {
    this.trigger({
      status
    });
  }

  private resetClientStats() {
    this.connectionAttempts = 0;
  }

  private logDisconnected() {
    this.log('Disconnected');
  }

  private createConfigPromise() {
    this.onConnected = new Promise<any>((resolve, reject) => {
      this.clientConnected = resolve;
      this.clientNotConnected = reject;
    });
  }
}

const singleton = Reflux.initStore<EventClient>(EventClient);
export default singleton;
