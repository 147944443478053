import * as React from 'react';
import * as ReactDOM from 'react-dom';
import EditorStore from '../../flux/editor/EditorStore';
import WorkflowStore, { WorkflowStoreEvent } from '../../flux/settings/WorkflowStore';
import ProjectStore from '../../flux/editor/ProjectStore';
import AppStateStore from '../../flux/common/AppStateStore';
import ProjectDefinitionStore from '../../flux/common/ProjectDefinitionStore';

import MenuFile, { MenuFileOnSelectParams } from './menus/file/MenuFile';
import MenuEdit, { MenuEditOnSelectParams } from './menus/edit/MenuEdit';
import MenuInsert, { MenuInsertOnSelectParams } from './menus/insert/MenuInsert';
import MenuWorkflow, { MenuWorkflowOnSelectParams } from './menus/MenuWorkflow';
import { IRevision, IEditorStoreEvent, EventStoreEventType } from 'mm-types';
import ActiveEditors from './activeEditors/ActiveEditors';
import { Grid } from '../general/layouts/Grid';
import { defaultTheme } from '../misc/ThemeProvider';
import MergeRevisionsStore, { MergeRevisionsStoreEvent } from '../../flux/editor/MergeRevisionsStore';

export type Props = {
  onActionSelected: (e: MenuFileOnSelectParams | MenuEditOnSelectParams | MenuInsertOnSelectParams | MenuWorkflowOnSelectParams) => void;
  onTabSelected: (tabId: string) => void;
  showActiveEditors: boolean;
  revisions?: IRevision[];
  showSubMenu?: boolean;
};

export type EditorNavTabs = 'file' | 'edit' | 'insert' | 'workflow';

export type Tab = {
  id: EditorNavTabs;
  title: string;
  menuComponent: any;
};

export type State = {
  tabs: Tab[];
  visibleTabs: string[];
  selected: string;
  hideTabsOnInterimMerge: boolean;
};

export default class EditorNav extends React.Component<Props, State> {
  private editorUnsub: Function;
  private workflowUnsub: Function;
  private mergeRevisionsUnsub: Function;

  private _parentPageNode: JQuery<Element>;

  static defaultProps: Partial<Props> = {
    showSubMenu: true
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      tabs: [
        { id: 'file', title: 'File', menuComponent: MenuFile },
        { id: 'edit', title: 'Edit', menuComponent: MenuEdit },
        { id: 'insert', title: 'Insert', menuComponent: MenuInsert },
        { id: 'workflow', title: 'Workflow', menuComponent: MenuWorkflow }
      ],

      visibleTabs: [],
      selected: 'edit',
      hideTabsOnInterimMerge: false
    };
  }

  componentWillUnmount() {
    this.editorUnsub();
    this.workflowUnsub();
    this.mergeRevisionsUnsub();
  }

  componentDidMount() {
    this.editorUnsub = EditorStore.listen(this._onEditStoreUpdate, this);
    this.workflowUnsub = WorkflowStore.listen(this._onWorkflowStoreUpdate, this);
    this.mergeRevisionsUnsub = MergeRevisionsStore.listen(this._onMergeRevisionStoreUpdate, this);
    this._parentPageNode = $(ReactDOM.findDOMNode(this) as Element).parent()!;
    this._parentPageNode.addClass('tab-selected-' + this.state.selected);
  }

  _visibleTabs() {
    const visibleTabs = ['file'];
    const project = ProjectStore.getProject();
    if (project) {
      const userPermissionPublish = project.currentUserPermissions?.canPublish && project.workspaceType !== 'personal';
      const userPermissionCanAuthor = project.currentUserPermissions?.canAuthor || project.currentUserPermissions?.canManageComplianceTags;

      const projectDefinition = ProjectDefinitionStore.getProjectDefinition(project.definitionName);
      const teamspaceWorkfow = projectDefinition && projectDefinition.teamspaceProjectFieldRequirements.workflow;
      const teamspaceCanPublish =
        teamspaceWorkfow && (teamspaceWorkfow === 'optional' || teamspaceWorkfow === 'disabled') && userPermissionPublish;

      const isNonPersonalWorkflow = project.workspaceType !== 'personal' && project.workflow;

      const workflowItem = this.state.tabs.find((item) => item.id === 'workflow')!;
      workflowItem.title = project.workflow ? 'Workflow' : 'Publish';
      // workflow and publish tab has the same content, just different title
      if ((!project.isDeleted && (isNonPersonalWorkflow || teamspaceCanPublish)) || userPermissionPublish) {
        visibleTabs.push('workflow');
      }

      if (ProjectStore.isIndexLocked() || !userPermissionCanAuthor) {
        return visibleTabs;
      }

      visibleTabs.push('edit');

      if (!ProjectStore.isReadOnly()) {
        visibleTabs.push('insert');
      }
    }

    return visibleTabs;
  }

  _onWorkflowStoreUpdate(e: WorkflowStoreEvent) {
    if (e.type === 'workflow' || e.type === 'workflowInstance') {
      const initialState = { visibleTabs: this._visibleTabs() };

      this.setState(initialState, () => {
        if (ProjectStore.isReadOnly()) {
          // use last access tab or 'file'
          const lastAccessedTabId = AppStateStore.getLastAccessEditorTab();
          this._handleNav(lastAccessedTabId ? lastAccessedTabId : 'file', true);
        }
      });
    }
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'initEditor') {
      const initialState = { visibleTabs: this._visibleTabs() };

      this.setState(initialState, () => {
        // if edit tab is available, select it, else use file tab
        const isEditTabVisible = this.state.visibleTabs.indexOf('edit') !== -1;
        const lastAccessedTabId = AppStateStore.getLastAccessEditorTab();
        this._handleNav(lastAccessedTabId ? lastAccessedTabId : isEditTabVisible ? 'edit' : 'file', true);
      });
    } else if (e.type === 'editor-modals-closed') {
      this.setState({ hideTabsOnInterimMerge: false });
    }
  }

  _onMergeRevisionStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'continueMerge' || e.type === 'startMerge') {
      this.setState({ hideTabsOnInterimMerge: true });
    } else if (e.type === 'mergeComplete') {
      this.setState({ hideTabsOnInterimMerge: false });
    }
  }

  _findTabInVisible(tabId: string, visibleTabs: string[]) {
    let tab = visibleTabs.find((visibleTabId) => visibleTabId === tabId);

    // If tab not found pick the first visible one
    if (!tab && visibleTabs && visibleTabs[0]) {
      tab = visibleTabs[0];
    }

    return tab;
  }

  _getSelectedMenuComponent() {
    if (this.props.showSubMenu === false) {
      return null;
    }

    const tab: Tab = this.state.tabs.find((tab) => tab.id === this.state.selected)!;

    return (
      <tab.menuComponent
        onselected={(action: MenuFileOnSelectParams | MenuEditOnSelectParams | MenuInsertOnSelectParams | MenuWorkflowOnSelectParams) =>
          this._handleMenuSelect(action)
        }
        revisions={this.props.revisions}
      />
    );
  }

  _handleNav(tabId: string, isSilent?: boolean) {
    // Make sure tabId is in the visible tabs, otherwise pick the first visible one
    tabId = this._findTabInVisible(tabId, this.state.visibleTabs)!;
    this.setState({ selected: tabId }, () => {
      this._parentPageNode
        .removeClass((index, css) => {
          return (css.match(/(^|\s)tab-selected-\S+/g) || []).join(' ');
        })
        .addClass('tab-selected-' + tabId);

      if (this.props.onTabSelected && !isSilent) {
        // isSilent is for non user tab selection where we don't want an event
        this.props.onTabSelected(tabId);
      }
      AppStateStore.updateLastAccessEditorTab(tabId);
    });
  }

  // simple pass thru' for now...
  _handleMenuSelect(action: MenuFileOnSelectParams | MenuEditOnSelectParams | MenuInsertOnSelectParams | MenuWorkflowOnSelectParams) {
    if (this.props.onActionSelected) {
      this.props.onActionSelected(action);
    }
  }

  _focusEditor(e) {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      e.stopPropagation();
      e.preventDefault();
      editor.silentReFocus();
    }
  }

  render() {
    return (
      <div className="editor-nav-wrapper" onClick={(e) => this._focusEditor(e)}>
        <nav className="page-header editor-nav">
          <div className="nav-wrapper">
            <div className="logo logoEditor" />
            <ul className="main-nav-list">
              {this.state.tabs.map((tab) => {
                return (
                  <li
                    onClick={() => this._handleNav(tab.id)}
                    key={tab.id}
                    className={
                      this.state.hideTabsOnInterimMerge
                        ? 'hide'
                        : (this.state.selected === tab.id ? ' active' : '') + (this.state.visibleTabs.indexOf(tab.id) !== -1 ? '' : ' hide')
                    }
                  >
                    <a>{tab.title}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
        <Grid templateColumns="1fr max-content" style={{ backgroundColor: defaultTheme.custom.subheaderBackground }}>
          {this._getSelectedMenuComponent()}
          <ActiveEditors />
        </Grid>
      </div>
    );
  }
}
