import { mm } from './base-clients';
import { IProjectDefinition, IIndexDefinition, IIndexStyleDefinition, IDocumentElementReformat, IDocumentUnitReformat } from 'mm-types';
import { CacheClient } from '../utils/CacheClient';

export async function getProjectDefinition(uid: string) {
  const response = await mm.get<IProjectDefinition>(`/projects/definitions/${uid}`);
  return response.data;
}

export async function getElementReformats() {
  const response = await mm.get<IDocumentElementReformat>(`/projects/0/indexes/0/units/ignored/elements/convert`, {
    params: CacheClient.params.useCache()
  });
  return response.data;
}

export async function getUnitReformats() {
  const response = await mm.get<IDocumentUnitReformat>(`/projects/0/indexes/0/refactor/definitions`, {
    params: CacheClient.params.useCache()
  });
  return response.data;
}

export async function removeProjectDefinition(uid: string) {
  const response = await mm.delete(`/projects/definitions/${uid}`, { params: CacheClient.params.clearRoot() });
  return response.data;
}

export async function getProjectDefinitions() {
  const response = await mm.get<{ projectDefinitions: IProjectDefinition[] }>(`/projects/definitions`, {
    params: CacheClient.params.useCache()
  });
  return response.data.projectDefinitions;
}

export async function createProjectDefinition(token: Partial<IProjectDefinition>) {
  const response = await mm.post<IProjectDefinition>(`/projects/definitions`, token);
  return response.data;
}

export async function updateProjectDefinition(uid: string, token: Partial<IProjectDefinition>) {
  const response = await mm.put<IProjectDefinition>(`/projects/definitions/${uid}`, token, {
    params: CacheClient.params.clearRoot()
  });
  return response.data;
}

export async function getIndexDefinitions(definitionUid: string) {
  const response = await mm.get<{ indexDefinitions: IIndexDefinition[] }>(`/indexes/definitions?projectDefinitionUid=${definitionUid}`);
  return response.data.indexDefinitions;
}

export async function createIndexDefinitions(token: FormData) {
  const response = await mm.post<IIndexDefinition>(`/indexes/definitions`, token, {
    params: CacheClient.params.clearRoot()
  });
  return response.data;
}

export async function updateIndexDefinition(uid: string, token: Partial<IIndexDefinition>) {
  const response = await mm.put<IIndexDefinition>(`/indexes/definitions/${uid}`, token, {
    params: CacheClient.params.clearRoot()
  });
  return response.data;
}

export async function createIndexStyleDefinitions(projectDefinitionUid: string, token: FormData) {
  const response = await mm.post<IIndexStyleDefinition>(`/projects/definitions/${projectDefinitionUid}/styles`, token, {
    params: CacheClient.params.clearRoot()
  });
  return response.data;
}
