import { EditorMode } from './EditorMode';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { EditorModes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class SmartContentLibMode extends EditorMode {
  private _selectItemUid: string | null = null;

  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.breadcrumb | attributes.dualDocsDisplay | attributes.editing | attributes.shareTab, syncQueue);
  }

  activate(currentPageUnits: DocUnitWrapper[], params: any) {
    this._selectItemUid = params && params.selectItemUid ? params.selectItemUid : null;
  }

  getParams() {
    return {
      selectItemUid: this._selectItemUid
    };
  }

  getActivatedMenuTabSelections() {
    return { main: 'toc', sub: 'shared' };
  }

  activateTransition(previousMode: EditorModes) {
    return this._getFlushSyncQueueRefreshType('NONE');
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    if (nextMode === 'EDITING') {
      return 'HARD_REFRESH';
    }
    return 'NONE';
  }
}
