import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const WorkflowAssignment = ({ entry }: PropsType) => {
  const workflowAssignmentDom: JSX.Element[] = [];
  if (entry.childrenActivities && entry.indexRevisionNumber) {
    workflowAssignmentDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  } else if (entry.indexRevisionNumber) {
    workflowAssignmentDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );

    if (entry.assignment) {
      if (entry.assignment) {
        if (entry.assignment.user) {
          workflowAssignmentDom.push(
            <div key="user">
              <label>User:</label> <span>{entry.assignment.user.username}</span>
            </div>
          );
        }
        if (entry.assignment.stageTitle) {
          workflowAssignmentDom.push(
            <div key="stage">
              <label>Stage Title:</label> <span>{entry.assignment.stageTitle}</span>
            </div>
          );
        }
      }

      const types = {
        WORKFLOW_ASSIGNMENT_ADDED: 'Added',
        WORKFLOW_ASSIGNMENT_UPDATE: 'Updated',
        WORKFLOW_ASSIGNMENT_REMOVED: 'Removed'
      };

      workflowAssignmentDom.push(
        <div key="operation">
          <label>Operation:</label> <span>{types[entry.projectActivityType]}</span>
        </div>
      );
    }
  }

  return <div className="settings-details">{workflowAssignmentDom}</div>;
};
