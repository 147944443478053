import * as React from 'react';
import Popover from 'material-ui/Popover';
import { SvgDropdownIcon } from '../../misc/icons/SvgDropdownIcon';

export type Props = {
  tagColor?: string;
  labelTitle?: string;
  icon?: string;
  endIcon?: string;
  tag?: string;
  color?: string;
  disabled?: boolean;
  onChange?: (color: string) => void;
};

export type State = {
  selected: string;
  colors: string[];
  open?: boolean;
  anchorEl?: HTMLElement;
};

export default class ColorSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: '#c62828',
      colors: [
        '#f44336',
        '#c62828',
        '#f06292',
        '#e91e63',
        '#d500f9',
        '#9575cd',
        '#9c27b0',
        '#4527a0',
        '#7986cb',
        '#6200ea',
        '#2196f3',
        '#304ffe',
        '#01579b',
        '#009688',
        '#004d40',
        '#43a047',
        '#33691e',
        '#ef6c00',
        '#ff5722',
        '#a1887f',
        '#795548',
        '#757575',
        '#607d8b',
        '#263238'
      ]
    };
  }

  defaultProps: Partial<Props> = {
    labelTitle: '',
    icon: '',
    endIcon: '',
    tag: '',
    color: '#c62828',
    disabled: false
  };

  UNSAFE_componentWillMount() {
    this.setState({ selected: this.props.tagColor! });
  }

  close() {
    if (this.state.open) {
      // important, only close *if* open (manages visibility state in its container)
      this._handleClose();
    }
  }

  _handleSelect(e) {
    const color = e.currentTarget.getAttribute('data-color');

    this.setState({ selected: color, open: false }, () => {
      if (this.props.onChange) {
        this.props.onChange(color);
      }
    });
  }

  _handleOpen(e) {
    if (!this.props.disabled) {
      this.setState({ open: true, anchorEl: e.currentTarget });
    }
  }

  _handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div
        className="color-selector"
        onClick={(e) => {
          this._handleOpen(e);
        }}
      >
        <div className="color-selector-inner">
          <label>{this.props.labelTitle}</label>

          <svg viewBox="0 0 24 24" className="icon-dropdown-arrow">
            <path d="M7 10l5 5 5-5z"></path>
          </svg>

          <div className="color-selector-tag color-selector-tag__fullsize" style={{ backgroundColor: this.props.color }}>
            <span className={this.props.icon}></span>
            <span>{this.props.tag}</span>
            <span className={this.props.endIcon}></span>
          </div>
        </div>

        <Popover
          animated={false}
          autoCloseWhenOffScreen={true}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={(e) => this._handleClose()}
        >
          <div className="color-selector-inner popup">
            <label>{this.props.labelTitle}</label>
            <SvgDropdownIcon onClick={this._handleClose} />
            <div
              className="color-selector-tag color-selector-tag__fullsize"
              style={{ backgroundColor: this.props.color }}
              onClick={() => {
                this._handleClose();
              }}
            >
              <span className={this.props.icon}></span>
              <span>{this.props.tag!}</span>
              <span className={this.props.endIcon}></span>
            </div>

            <ul>
              {this.state.colors.map((color) => {
                return (
                  <li
                    key={color}
                    onClick={(e) => {
                      this._handleSelect(e);
                    }}
                    style={{ backgroundColor: color }}
                    data-color={color}
                    className={color === this.state.selected ? 'selected' : ''}
                  ></li>
                );
              })}
            </ul>
          </div>
        </Popover>
      </div>
    );
  }
}
