export type MenuAction = {
  action: string;
  tooltip: string;
  icon: string;
  enabled: boolean;
  visible: boolean;
  active?: boolean;
  inClassNameIcon?: boolean;
};

export type DefaultEditMenuSettings = {
  docActions: MenuAction[];
  editActions: MenuAction[];
  convertActions: MenuAction[];
  textActions: MenuAction[];
  justifyActions: MenuAction[];
  linkActions: MenuAction[];
  mergeSplitActions?: MenuAction[];
};

export const DEFAULT_EDIT_MENU_CONTENTS: DefaultEditMenuSettings = {
  docActions: [
    { action: 'Undo', tooltip: 'Undo', icon: 'undo', enabled: true, visible: true },
    { action: 'Redo', tooltip: 'Redo', icon: 'redo', enabled: true, visible: true }
  ],

  editActions: [
    {
      action: 'arcPasteElement',
      tooltip: 'Paste Element',
      icon: 'icon-paste-element',
      enabled: true,
      visible: true
    }
  ],

  convertActions: [{ action: 'convertUnits', tooltip: 'Format element', icon: '', enabled: false, visible: true, active: false }],

  textActions: [
    {
      action: 'arcFontSize',
      tooltip: 'Font Size',
      icon: '',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'arcTextTransform',
      tooltip: 'Text Transform',
      icon: '',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'bold',
      tooltip: 'Bold',
      icon: 'format_bold',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'code',
      tooltip: 'Code Block',
      icon: 'icon-code',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'italic',
      tooltip: 'Italic',
      icon: 'format_italic',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'underline',
      tooltip: 'Underline',
      icon: 'format_underlined',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'overline',
      tooltip: 'Overline',
      icon: 'icon-overline',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'superscript',
      tooltip: 'Superscript',
      icon: 'icon-superscript',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'subscript',
      tooltip: 'Subscript',
      icon: 'icon-subscript',
      enabled: false,
      visible: true,
      active: false
    },
    {
      action: 'forecolor',
      tooltip: 'Font Color',
      icon: 'format_color_text',
      enabled: false,
      visible: false,
      active: false
    },
    {
      action: 'hilitecolor',
      tooltip: 'Font Highlight',
      icon: 'border_color',
      enabled: false,
      visible: false,
      active: false
    }
  ],

  justifyActions: [
    { action: 'JustifyLeft', tooltip: 'Left align', icon: 'format_align_left', enabled: false, visible: true },
    { action: 'JustifyCenter', tooltip: 'Center', icon: 'format_align_center', enabled: false, visible: true },
    { action: 'JustifyRight', tooltip: 'Right align', icon: 'format_align_right', enabled: false, visible: true },
    { action: 'JustifyFull', tooltip: 'Justify', icon: 'format_align_justify', enabled: false, visible: true }
  ],

  linkActions: [
    { action: 'insertLink', tooltip: 'Link', icon: 'link', enabled: false, visible: true },
    {
      action: 'duRefLink',
      tooltip: 'DuRef Link',
      icon: 'link',
      enabled: false,
      visible: false
    },
    { action: 'insertLinkAnchor', tooltip: 'Anchor', icon: 'icon-inbound-link', enabled: false, visible: true, inClassNameIcon: true }
  ]
};
