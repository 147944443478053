import React from 'react';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import { ElementDetails } from '../../utils/units/ElementDetails';
import { deleteElementAndRecordTransaction } from '../../utils/tinyFacade/TinyFacadeHelpers';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;
import { AbstractElementDefinitionIds, AbstractElementFamily } from '../../utils/units/UnitTypes';

type BreadcrumbElementType = {
  canDelete: Boolean;
  setDeleteIconVisible: (iconVisible: Boolean) => void;
  preventEditorBlurAndDeleteElement: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const useBreadcrumbElement = (element: HTMLElement, isLast: Boolean): BreadcrumbElementType => {
  const [iconVisible, setDeleteIconVisible] = React.useState<Boolean>(false);
  const [canDelete, setCanDelete] = React.useState<Boolean>(false);

  React.useEffect(() => {
    updateCanDelete();
  }, [element, isLast, iconVisible]);

  const updateCanDelete = () => {
    const elmDefId = getDataElementDefinitionId(element);
    const elmIsUserCreatable = ProjectDefinitionStore.getElementDefinitionById(elmDefId)?.userCreatable;

    const isChallengeResponsePart = elmDefId === AbstractElementFamily.CHALLENGE_PART;
    const isEcamSubtitle = elmDefId === AbstractElementDefinitionIds.SUBTITLE;
    const isOnlyChallengeResponsePart =
      isChallengeResponsePart &&
      getDataElementDefinitionId(element.previousElementSibling?.previousElementSibling) !== AbstractElementFamily.CHALLENGE_PART &&
      getDataElementDefinitionId(element.nextElementSibling?.nextElementSibling) !== AbstractElementFamily.CHALLENGE_PART;

    // If element is user createable we should be able to delete it if it's the last elm in breadcrumb.
    // Challenge response part is special case where it's not user creatable but if it's not the last challenge response part we should be able to delete it.
    const canDelete =
      isLast && iconVisible && !isOnlyChallengeResponsePart && (elmIsUserCreatable || isChallengeResponsePart || isEcamSubtitle);
    setCanDelete(canDelete);
  };

  const preventEditorBlurAndDeleteElement = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    deleteElementAndRecordTransaction(element);
  };

  return {
    canDelete: canDelete,
    setDeleteIconVisible: setDeleteIconVisible,
    preventEditorBlurAndDeleteElement: preventEditorBlurAndDeleteElement
  };
};
