import * as React from 'react';

import { IInvalidElement, IInvalidUnit } from 'mm-types';
import InvalidElement from './InvalidElement';
import { ExpandedUnitElement } from './InvalidUnitsList';

export type Props = {
  invalidUnit: IInvalidUnit;
  expanded?: ExpandedUnitElement;
  onExpand: (unit?: IInvalidUnit, element?: IInvalidElement) => void;
};

const InvalidElementsList = (props: Props) => {
  return (
    <>
      <div>
        {props.invalidUnit.elements?.map((invalidElement, index) => {
          return (
            <InvalidElement
              key={index}
              expanded={props.expanded}
              onExpand={props.onExpand}
              invalidElement={invalidElement}
              invalidUnit={props.invalidUnit}
            />
          );
        })}
      </div>
    </>
  );
};

export default InvalidElementsList;
