import { mm, exemptStatusCodes, ClientError } from './base-clients';
import { IDepartment } from 'mm-types';
import QueryUtil from '../utils/QueryUtil';
import { CancelTokenSource } from 'axios';

const getByOrgSource: CancelTokenSource | null = null;

export async function getOne(orgUid: string, uid: string) {
  const response = await mm.get<IDepartment>(`/orgs/${orgUid}/departments/${uid}`);
  return response.data;
}

export type GetByOrgAndMemberOptions = {
  activeOnly: boolean;
  orgUid: string;
  isMemberOf: boolean;
};

/**
 * Gets all departments of an org
 */
export async function getByOrg({ orgUid, activeOnly, isMemberOf }: Partial<GetByOrgAndMemberOptions>) {
  if (getByOrgSource) {
    getByOrgSource.cancel();
  }

  const url = QueryUtil.getUrlWithParams(`/orgs/${orgUid}/departments`, {
    activeOnly: activeOnly?.toString() ?? 'false',
    isMemberOf: isMemberOf?.toString() ?? 'false'
  });

  const response = await mm.get<{ departments: IDepartment[] }>(url);
  return response.data.departments;
}

/**
 * Gets all departments
 */
export async function getAll(query: string) {
  const response = await mm.get<{ departments: IDepartment[] }>(`/orgs/all/departments?query=${encodeURIComponent(query)}&activeOnly=true`);
  return response.data.departments;
}

/**
 * Removes a department by org and id
 */
export async function remove(orgUid: string, uid: string) {
  const response = await mm.delete(`/orgs/${orgUid}/departments/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412])
  });

  if (response.status === 412) {
    throw new ClientError(response.status, 'Department in use');
  }
}

/**
 * Creates a department
 */
export async function create(orgUid: string, department: Partial<IDepartment>) {
  const response = await mm.post<IDepartment>(`/orgs/${orgUid}/departments`, department, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Department already exists');
  }

  return response.data;
}

/**
 * Updates a department by org and id
 */
export async function update(orgUid: string, department: Partial<IDepartment>) {
  const response = await mm.put<IDepartment>(`/orgs/${orgUid}/departments/${department.uid}`, department, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Department in use');
  }

  return response.data;
}
