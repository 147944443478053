import * as React from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';

type DropDownMenuWithBaseStyleProps = {
  value: string;
  onChange: (value) => void;
  baseStyles: React.CSSProperties;
  labelStyle: React.CSSProperties;
  menuStyle: { [key: string]: string };
  children: React.ReactNode;
  dataQa?: string;
  disabled?: boolean;
};

export const DropDownMenuWithBaseStyle = ({
  value,
  onChange,
  baseStyles,
  labelStyle,
  menuStyle,
  children,
  dataQa,
  disabled = false
}: DropDownMenuWithBaseStyleProps) => {
  return (
    <DropDownMenu
      value={value}
      style={{ ...baseStyles, float: 'right', marginRight: '5px' }}
      labelStyle={{ ...baseStyles, ...labelStyle }}
      menuStyle={menuStyle}
      iconStyle={{ ...baseStyles, top: '0', left: '8px' }}
      listStyle={{ paddingTop: 0, paddingBottom: 0 }}
      onChange={(e, index, value) => onChange(value)}
      data-qa={dataQa}
      disabled={disabled}
    >
      {children}
    </DropDownMenu>
  );
};
