import React from 'react';
import Toggle from 'material-ui/Toggle';

export type Props = {
  onChange: (val: boolean) => void;
  val: boolean;
  title: string;
  dataQa: string;
};

const ToggleProp = ({ val, onChange, title, dataQa }: Props) => {
  return (
    <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', marginBottom: '4px' }}>
      <div>{title}</div>
      <div onClick={() => onChange(!val)}>
        <Toggle style={{ width: '' }} toggled={val} data-qa={dataQa} />
      </div>
    </div>
  );
};

export default ToggleProp;
