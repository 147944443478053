import { IDocReference } from 'mm-types';
import * as refsClient from '../../../../clients/doc-refs';
import { isSuccess } from '../../../../clients/base-clients';
import Log from '../../../../utils/Log';

export async function deleteGebDocRefs(setFetching: Function, fetchCompleted: Function, setCompleted: Function, showMessage: Function) {
  showMessage('Deleting doc references...');
  setFetching(true);
  try {
    setCompleted(1);
    const response = await refsClient.getAll();
    if (isSuccess(response)) {
      console.log(response);
      const gebReference: IDocReference[] = response.filter(
        (ref) => ref.description.startsWith('MM_SPA_') || ref.description.startsWith('abcdefgh')
      );
      const total = gebReference.length;
      if (!gebReference.length || gebReference.length === 0) {
        fetchCompleted();
        showMessage('No references to delete');
        return;
      } else {
        for (const ref of gebReference) {
          const index = gebReference.indexOf(ref);
          setCompleted(((index + 1) * 100) / total);
          try {
            if (ref.uid && ref.org) {
              if (typeof ref.org === 'string') {
                await refsClient.remove(ref.org as string, ref.uid);
              } else if (ref.org.uid) {
                await refsClient.remove(ref.org.uid, ref.uid);
              }
            }
          } catch (e) {
            Log.info('Exception when deleting GEB references');
          }
        }
      }
      fetchCompleted();
      showMessage('All done!');
    }
  } catch (e) {
    showMessage(e);
    fetchCompleted();
  }
}
