import SystemInfoStore from '../flux/common/SystemInfoStore';
import axios, { AxiosResponse, AxiosError } from 'axios';

export interface FileDownloadOptions {
  onStart?: () => void;
  onProgress?: (progress: number) => void;
  onSuccess?: (filename?: string) => void;
  onError?: (errorText?: string) => void;
}

const snackbarHandler: FileDownloadOptions = {
  onStart: () => SystemInfoStore.showSystemInfo('Downloading: 0%'),
  onProgress: (progress: number) => SystemInfoStore.showSystemInfo(`Downloading: ${progress}%`),
  onSuccess: () => SystemInfoStore.hideSystemInfo('Download complete'),
  onError: () => SystemInfoStore.hideSystemInfo('Download failed')
};

const snackbarHandlerForMany: FileDownloadOptions = {
  onStart: () => SystemInfoStore.showSystemInfo('Downloading files'),
  onSuccess: () => SystemInfoStore.hideSystemInfo('Download complete'),
  onError: () => SystemInfoStore.hideSystemInfo('Download failed')
};

class FileDownloadUtil {
  private downloadLink: HTMLAnchorElement;

  async withDefaultNotification(url: string) {
    await this.download(url, snackbarHandler);
  }

  async manyWithDefaultNotification(url: string[]) {
    const promises: Promise<any>[] = [];
    if (url.length && url.length === 1) {
      this.withDefaultNotification(url[0]);
    } else {
      snackbarHandlerForMany.onStart!();
      for (let i = 0; i < url.length; i++) {
        promises.push(this.download(url[i], {}));
      }
      await axios
        .all(promises)
        .then(() => {
          snackbarHandlerForMany.onSuccess!();
        })
        .catch(() => {
          snackbarHandlerForMany.onError!();
        });
    }
  }

  async download(url: string, options: FileDownloadOptions = {}): Promise<void> {
    if (options.onStart) {
      options.onStart();
    }

    return await axios
      .get<Blob>(url, {
        responseType: 'blob',
        onDownloadProgress: (e: ProgressEvent) => {
          if (options.onProgress && e.lengthComputable) {
            options.onProgress(Math.round((e.loaded / e.total) * 100));
          }
        }
      })
      .then((result) => {
        try {
          this.triggerBlobDownload(result, options.onSuccess);
        } catch (e) {
          console.error(e);
          window.open(url, '_blank');
        }
      })
      .catch((error: AxiosError) => {
        if (options.onError) {
          options.onError(error.response ? error.response.statusText : '');
        }
      });
  }

  private getTypeAndFilenameFromHttpHeader(headers: { [prop: string]: string }): { filename: string; type: string } {
    let filename = '';
    let type = 'application/pdf';
    const disposition = headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = decodeURIComponent(escape(matches[1].replace(/['"]/g, '')));
      }
    }
    if (headers['content-type']) {
      type = headers['content-type'].split(';')[0];
    }
    return {
      filename,
      type
    };
  }

  private triggerBlobDownload(response: AxiosResponse<Blob>, callback?: FileDownloadOptions['onSuccess']) {
    const { filename, type } = this.getTypeAndFilenameFromHttpHeader(response.headers);
    const newBlob = new Blob([response.data], { type });
    const data = window.URL.createObjectURL(newBlob);

    if (!this.downloadLink) {
      this.downloadLink = document.createElement('a');
    }

    this.downloadLink.href = data;
    this.downloadLink.download = filename || 'file.pdf';
    this.downloadLink.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      if (callback) {
        callback(filename);
      }
    }, 100);
  }
}

const fileDownloadUtil = new FileDownloadUtil();
export default fileDownloadUtil;
