import * as Reflux from 'reflux';
import Log from '../utils/Log';
import { EventEmitter } from 'events';

function DebugEventEmitter() {
  const realEmitter = new EventEmitter();
  realEmitter.setMaxListeners(Infinity);
  const origEmit = realEmitter.emit;
  realEmitter.emit = (...args: any[]) => {
    Log.trackEvent(args);
    return origEmit.apply(realEmitter, args);
  };

  return realEmitter;
}

export default class Store<T> extends Reflux.Store {
  constructor() {
    super();
    // No need to intercept event emitter on production
    process.env.NODE_ENV !== 'production' && Reflux.setEventEmitter(DebugEventEmitter);
  }

  state: T;
  setState: (val: T) => void;
}
