import * as React from 'react';

const _DRAGEVENT_CLASSNAME = 'undergoing-drag-event';

export type Props = {
  name: string;
  containerEl: Element;
  isContainerOpened: boolean;
  isLeftAttached: boolean;
  minContainerSize: number;
  maxContainerSize: number;
};

export type State = {};

export default class ResizeHandle extends React.Component<Props, State> {
  private _startX: number;
  private _startWidth: number;
  private _resizeHandleEl: null | HTMLElement;
  private _proxyDown: any;

  private _proxyDoDrag: any;
  private _proxyStopDrag: any;

  constructor(props: Props) {
    super(props);

    this._startX = 0;
    this._startWidth = 0;
    this._resizeHandleEl = null;

    this._proxyDown = this._initContainerResize.bind(this);
    this._proxyDoDrag = this._doDrag.bind(this);
    this._proxyStopDrag = this._stopDrag.bind(this);
  }

  componentDidMount() {
    // revert to *real* DOM for this
    this._resizeHandleEl = document.querySelector('.resize-handle-' + this.props.name)! as HTMLElement;
    this._resizeHandleEl!.addEventListener('mousedown', this._proxyDown, false);
  }

  componentWillUnmount() {
    this._resizeHandleEl!.removeEventListener('mousedown', this._proxyDown, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const isInlineStyleSet = nextProps.containerEl ? nextProps.containerEl.hasAttribute('style') : null;
    if (!nextProps.isContainerOpened && isInlineStyleSet) {
      nextProps.containerEl.removeAttribute('style');
    }
  }

  _doDrag(e) {
    if (this.props.isContainerOpened) {
      if (document.body.classList) {
        document.body.classList.add(_DRAGEVENT_CLASSNAME);
      } else {
        document.body.className += ' ' + _DRAGEVENT_CLASSNAME;
      }

      const offset = this.props.isLeftAttached ? e.clientX - this._startX : this._startX - e.clientX;
      const newWidth = this._startWidth + offset;

      if (newWidth >= this.props.minContainerSize && newWidth <= this.props.maxContainerSize) {
        this.props.containerEl.setAttribute('style', 'width: ' + newWidth + 'px!important;');
      }
    }
  }

  _stopDrag() {
    if (this.props.isContainerOpened) {
      if (document.body.classList) {
        document.body.classList.remove(_DRAGEVENT_CLASSNAME);
      } else {
        document.body.className = document.body.className.replace(
          new RegExp('(^|\\b)' + _DRAGEVENT_CLASSNAME.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' '
        );
      }
    }
    if (document.documentElement) {
      document.documentElement.removeEventListener('mousemove', this._proxyDoDrag, false);
      document.documentElement.removeEventListener('mouseup', this._proxyStopDrag, false);
    }
  }

  _initContainerResize(e: React.MouseEvent<HTMLElement>) {
    if (!this.props.containerEl) {
      return;
    }

    this._startX = e.clientX;
    this._startWidth = parseInt(document.defaultView!.getComputedStyle(this.props.containerEl).width!, 10);

    if (document.documentElement) {
      document.documentElement.addEventListener('mousemove', this._proxyDoDrag, false);
      document.documentElement.addEventListener('mouseup', this._proxyStopDrag, false);
    }
  }

  // Toggles container size (if current width === min/max, otherwise goes back to min width)
  _resetContainerSize() {
    if (!this.props.containerEl) {
      return;
    }

    const containerWidth = parseInt(document.defaultView!.getComputedStyle(this.props.containerEl).width!, 10);
    const newWidth = containerWidth === this.props.minContainerSize ? this.props.maxContainerSize : this.props.minContainerSize;
    this.props.containerEl.setAttribute('style', 'width: ' + newWidth + 'px!important;');
  }

  render() {
    const cls = 'resize-handle resize-handle-' + this.props.name;
    return (
      <span className={cls} onDoubleClick={(e) => this._resetContainerSize()}>
        <span className="icon-resize-handle"></span>
      </span>
    );
  }
}
