import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ProjectPropsStore, { ProjectPropsStoreEvent, State as ProjectPropsStoreState } from '../../../../flux/projects/ProjectPropsStore';
import * as client from '../../../../clients/change-tasks';
import { transitionTo } from '../../../../utils/Navigation';
import { CircularProgress, MenuItem, SelectField } from 'material-ui';
import { default as PopupMenu, Option, Options } from '../../../misc/PopupMenu';
import { IExternalRevisions, IProject, IProjectActivity, IRevision } from 'mm-types';
import DocumentLogList from './DocumentLogList';
import { getComponentName } from './utils/DocumentLogDomUtils';

const SCROLLREQUEST_THRESHOLD = 700; // higher the number the sooner the next request will be made on scroll down
const DEFAULT_REVISION = '-1';
const ALL_FILTER = [''];

const filterOptions = {
  all: { value: 'all', title: 'All' },
  settings: { value: 'settings', title: 'Settings' },
  attachment: { value: 'attachment', title: 'Attachment' },
  'doc-team': { value: 'doc-team', title: 'Doc team' },
  'revision-settings': { value: 'revision-settings', title: 'Revision' },
  workflow: { value: 'workflow', title: 'Workflow' },
  publication: { value: 'publication', title: 'Publication' },
  'change-tasks': { value: 'change-tasks', title: 'Tasks' },
  'ext-upload': { value: 'ext-upload', title: 'External Uploads', externalDocsOnly: true },
  'ext-download': { value: 'ext-download', title: 'External Downloads', externalDocsOnly: true }
};

const DEFAULT_FILTER = filterOptions['all'];

export type Props = {
  projectUid: null | string;
  project: IProject;
  indexUid: string;
};

export type State = {
  filterableTasks: { payload: string | null; text: string }[];
  listView: string;
  currentProjectUid: null | string;
  revisions: null | IExternalRevisions | IRevision[];
  currentIndexUid: string;
  selectedIndexUid: string;
  opened: null | string;
  currentTaskUid: null | string;
  listViewing: string;
  selectedFilter: string;
  loading?: boolean;
  isLoading?: boolean;
  selectedTaskIndex?: string;
} & ProjectPropsStoreState;

export default class DocumentLog extends React.Component<Props, State> {
  private unsubscribe: Function;
  constructor(props: Props) {
    super(props);

    this.state = Object.assign(
      {
        filterableTasks: [],
        documentLog: null,
        listView: '',
        currentProjectUid: null,
        revisions: null,
        currentIndexUid: DEFAULT_REVISION,
        selectedIndexUid: DEFAULT_REVISION,
        opened: null,
        currentTaskUid: null,
        isLoading: true,
        listViewing: DEFAULT_FILTER.title,
        selectedFilter: DEFAULT_FILTER.value
      },
      ProjectPropsStore.getInitialState()
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.setState(
      {
        currentProjectUid: this.props.projectUid
      },
      () => {
        ProjectPropsStore.retrieveDocumentLog({ projectUid: this.props.projectUid! }, ALL_FILTER);
      }
    );
    this.unsubscribe = ProjectPropsStore.listen(this.onPropsStoreChange, this);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.project?.uid !== this.props.project?.uid) {
      this.setState({
        isLoading: true,
        listViewing: DEFAULT_FILTER.title,
        selectedFilter: DEFAULT_FILTER.value
      });
      ProjectPropsStore.retrieveDocumentLog({ projectUid: this.props.projectUid! }, ALL_FILTER);
    }
  }

  _getOptions() {
    const toRet: JSX.Element[] = [];
    let index = 0;
    for (const i in filterOptions) {
      index++;
      if (!filterOptions[i].externalDocsOnly) {
        toRet.push(
          <Option key={'option-' + index} dataKey={i}>
            {filterOptions[i].title}
          </Option>
        );
      }
      if (filterOptions[i].externalDocsOnly && this.state.isExternal) {
        toRet.push(
          <Option key={'option-' + index} dataKey={i}>
            {filterOptions[i].title}
          </Option>
        );
      }
    }
    return toRet;
  }

  onPropsStoreChange(event: ProjectPropsStoreEvent) {
    if (event.error) {
      transitionTo('/teamspaces');
    } else if (event.type === 'revisions') {
      this.setState({
        revisions: event.state.revisions,
        isLoading: false
      });
    } else {
      if (this.props.projectUid !== this.state.currentProjectUid) {
        this.setState({
          isExternal: event.state.isExternal,
          currentProjectUid: this.props.projectUid,
          currentIndexUid: DEFAULT_REVISION,
          selectedIndexUid: DEFAULT_REVISION,
          selectedTaskIndex: '0',
          currentTaskUid: null,
          revisions: event.state.revisions,
          isLoading: false
        });
      }
      if (event.state.documentLog === null) {
        this._handleListViewSwitch(this.state.selectedFilter, this.state.selectedIndexUid);
        return;
      } else {
        this.setState({ documentLog: event.state.documentLog, isLoading: false });
      }
    }
  }

  // this is to call the api whenever the user scrolls down a list
  _handleScroll(ref: HTMLUListElement | null) {
    if (ref) {
      const $listEl = $(ReactDOM.findDOMNode(ref) as Element);
      let type: string;
      let taskUid: string | null = null;
      if (this.state.selectedFilter === 'change-tasks') {
        type = 'task';
        taskUid = this.state.currentTaskUid!;
      } else if (this.state.selectedFilter === 'all') {
        type = '';
      } else {
        type = this.state.selectedFilter;
      }

      if ($listEl.scrollTop()! + $listEl.innerHeight()! >= $listEl[0].scrollHeight - SCROLLREQUEST_THRESHOLD) {
        ProjectPropsStore.retrieveDocumentLog(
          {
            next: true,
            projectUid: this.props.projectUid!,
            indexUid: this.state.currentIndexUid
          },
          type === '' ? [] : [type],
          taskUid
        );
      }
    }
  }

  // not sure where this goes Own store ?
  async fetchTasks(indexUid: string) {
    this.setState({ isLoading: true });

    try {
      const tasks = await client.getAllTasks(indexUid);

      const toRet: { payload: null | string; text: string }[] = tasks.map((t) => {
        return {
          payload: t.uid,
          text: t.title
        };
      });

      toRet.unshift({ payload: null, text: 'All Tasks' });
      return toRet;
    } catch (err) {
      console.error('Error fetching tasks');
      return [];
    }
  }

  _titleBuilder(entry: IProjectActivity) {
    let attachmentAction = '';

    if (
      entry.taskName &&
      entry.projectActivityType.indexOf('POKE') === -1 &&
      entry.projectActivityType.indexOf('NUDGE') === -1 &&
      (entry.projectActivityType.indexOf('TASK_') === 0 || entry.projectActivityType.indexOf('TASK_PARTICIPANT') === 0)
    ) {
      return (entry.title = (
        <div title={entry.taskName.charAt(0).toUpperCase() + entry.taskName.slice(1)}>
          {entry.taskNumber}
          <div className="taskName">{entry.taskName}</div>
          {entry.taskAction}
        </div>
      ));
    } else if (entry.attachmentAction) {
      attachmentAction = entry.attachmentAction;
      return (
        <div>
          <span id="document-log-title">{entry.title}</span>
          <div className={`${entry.uid} attachment-action`} style={{ display: 'inline' }}>
            - {attachmentAction}
          </div>
        </div>
      );
    } else {
      return entry.title;
    }
  }

  _toggleActivityMenu() {
    (this.refs.displayFilter as PopupMenu).setTriggerButton(this.refs.displayFilterBtn).open();
  }

  _setFilteredTask(selectedIndex, taskUid) {
    this.setState(
      {
        selectedTaskIndex: selectedIndex,
        currentTaskUid: taskUid
      },
      () =>
        ProjectPropsStore.retrieveDocumentLog(
          {
            projectUid: this.props.projectUid!,
            indexUid: this.state.currentIndexUid
          },
          ['task'],
          taskUid
        )
    );
  }

  _handleRevisionChange(indexUid) {
    this.setState({ currentIndexUid: indexUid, selectedIndexUid: indexUid }, () => {
      this._handleListViewSwitch(this.state.selectedFilter, this.state.selectedIndexUid, true);
    });
  }

  _downloadWorkflowToCSV() {
    let index = '';
    if (this.state.selectedIndexUid !== '-1') {
      index = '&indexUid=' + this.state.selectedIndexUid;
    }
    location.href = '/api/projects/' + this.props.projectUid + '/activities?taskUid=&filter=workflow' + index + '&attachment=true';
  }

  _handleListViewSwitch(type, indexUid, force = false) {
    this.setState({ isLoading: true, documentLog: null });
    if (!indexUid) {
      this.setState({ selectedIndexUid: '-1' });
      indexUid = '-1';
    }

    if (type === 'doc-team') {
      this.setState({ selectedFilter: type }, () => {
        ProjectPropsStore.retrieveDocumentLog({ projectUid: this.props.projectUid!, indexUid: indexUid }, [type]);
      });
    } else if (type === 'change-tasks') {
      this.setState({ listView: 'listView' });
      this.fetchTasks(this.props.indexUid).then((tasks) => {
        this.setState(
          {
            selectedFilter: type,
            filterableTasks: tasks
          },
          () => {
            ProjectPropsStore.retrieveDocumentLog(
              {
                projectUid: this.props.projectUid!,
                indexUid: indexUid
              },
              ['task'],
              this.state.currentTaskUid!
            );
          }
        );
      });
    } else {
      if (type === 'workflow') {
        this.setState({ listView: 'listView' });
      }
      const filter = type === 'all' ? ALL_FILTER : ([type] as string[]);
      if (
        force ||
        this.state.selectedFilter !== type ||
        this.state.currentProjectUid !== this.props.projectUid ||
        this.state.currentIndexUid !== indexUid
      ) {
        this.setState({ selectedFilter: type }, () => {
          if (this._showRevisions() && !this.hasRevisions()) {
            ProjectPropsStore.getRevisions(this.props.project, true);
          }
          ProjectPropsStore.retrieveDocumentLog({ projectUid: this.props.projectUid!, indexUid: indexUid }, filter);
        });
      }
    }
    this.setState({ currentIndexUid: indexUid, listViewing: filterOptions[type].title });
  }

  _selectedItem(uid: string) {
    this.setState({ opened: this.state.opened === uid ? null : uid });
  }

  _getLogDetails(entry: IProjectActivity) {
    const Component = getComponentName(entry.projectActivityType);
    return <Component entry={entry} />;
  }

  _getRevisions() {
    let docRevisions: IRevision[] = [];

    if (!this.state.revisions) {
      return [];
    }

    if ((this.state.revisions as IExternalRevisions).revisions) {
      docRevisions = (this.state.revisions as IExternalRevisions).revisions;
    } else {
      docRevisions = this.state.revisions as IRevision[];
    }

    const revisions = docRevisions.map((revision) => {
      return { name: revision.interim ? 'Interim Revision' : revision.revision, value: revision.indexUid };
    });

    revisions.unshift({ name: 'Draft Revision', value: this.props.indexUid });

    if (['doc-team'].indexOf(this.state.selectedFilter) > -1) {
      revisions.unshift({ name: 'Document Level', value: '-1' });
    } else {
      revisions.unshift({ name: 'All', value: DEFAULT_REVISION });
    }

    return revisions;
  }

  _showRevisions() {
    if (['workflow', 'revision-settings', 'change-tasks', 'attachment'].indexOf(this.state.selectedFilter) > -1) {
      return true;
    }
  }

  hasRevisions(): boolean {
    return !!this.state.revisions;
  }

  render() {
    const isLoading = this.state.isLoading;
    return (
      <div className="documentLog">
        <h5 data-qa="documentLog-header">Document log</h5>
        <h6>
          <span>{this.state.listViewing}</span>

          <div className="icon-display-options tiny material-icons" onClick={(e) => this._toggleActivityMenu()}>
            arrow_drop_down
          </div>
          <div className="icon-display-options tiny material-icons" onClick={(e) => this._toggleActivityMenu()} ref="displayFilterBtn">
            filter_list
          </div>
        </h6>

        <PopupMenu
          ref="displayFilter"
          onSelect={(type, indexUid) => this._handleListViewSwitch(type, indexUid)}
          className="display-options-popup"
        >
          <Options>
            <div className="popup-arrow" />
            <div className="popup-menu-title">Show activity:</div>
            {this._getOptions()}
          </Options>
        </PopupMenu>

        {isLoading && (
          <div className="document-log-loading" style={{ textAlign: 'center' }}>
            <CircularProgress style={{ margin: 'auto', paddingTop: 50 + 'px' }} />
          </div>
        )}

        {!isLoading && this._showRevisions() && (
          <div className="revisions-wrapper">
            <SelectField
              className="typeInput"
              value={this.state.selectedIndexUid}
              floatingLabelText="Revision"
              onChange={(e, i, value) => {
                this._handleRevisionChange(value);
              }}
              style={{ width: '100%' }}
            >
              {this.hasRevisions() &&
                this._getRevisions().map((item) => {
                  return <MenuItem key={item.value} value={item.value} primaryText={item.name} style={{ paddingLeft: '5px' }} />;
                })}
            </SelectField>
            {this.state.listViewing === 'Workflow' && this.state.documentLog && this.state.documentLog.length > 0 && (
              <div
                className="icon-export-as-csv"
                onClick={() => {
                  this._downloadWorkflowToCSV();
                }}
              />
            )}
          </div>
        )}

        {!isLoading && this.state.listViewing === 'Tasks' ? (
          <div className="changeTaskFilter">
            <div className="changeTaskFilter-text">Filter by Task:</div>
            <SelectField
              id="change-task-filter-text-dropdown"
              onChange={(e, i, value) => {
                this._setFilteredTask(i, value);
              }}
              value={this.state.currentTaskUid}
            >
              {this.state.filterableTasks.map((item, index) => {
                return <MenuItem key={index.toString()} value={item.payload} primaryText={item.text} />;
              })}
            </SelectField>
          </div>
        ) : undefined}

        {!isLoading && this.state.documentLog && this.state.documentLog.length > 0 ? (
          <DocumentLogList
            activities={this.state.documentLog}
            handleScroll={(ref) => this._handleScroll(ref)}
            selectedItem={(uid) => this._selectedItem(uid)}
            opened={this.state.opened}
            titleBuilder={this._titleBuilder}
            getLogDetails={(entry) => this._getLogDetails(entry)}
          />
        ) : undefined}

        {!isLoading && this.state.documentLog && this.state.documentLog.length === 0 ? (
          <div className="documentLog-empty narrow-panel-empty-list">
            <img src={`${'/assets/images/activity_panel_info.svg'}`} />
            <span className="empty-title">No Activity</span>
            <span className="empty-details">
              There has been no activity. Edits to associated metadata and workflows will be logged here, for review or further action.
            </span>
          </div>
        ) : undefined}
      </div>
    );
  }
}
