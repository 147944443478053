import * as React from 'react';
import { SvgIcon, SvgIconProps } from 'material-ui';

const SpecialInterestGroupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width={24} height={24} {...props}>
      <g fill="none">
        <polygon points="0 0 24 0 24 24 0 24" />
        <g transform="translate(2 2)">
          <circle cx="10" cy="10" r="10" fill="#737373" />
          <g fill="#FFF" transform="translate(4 6)">
            <path d="M3.29411765 3.95294118C4.41411765 3.95294118 5.27058824 3.09647059 5.27058824 1.97647059 5.27058824.856470588 4.41411765 1.33226763e-15 3.29411765 1.33226763e-15 2.17411765 1.33226763e-15 1.31764706.856470588 1.31764706 1.97647059 1.31764706 3.09647059 2.17411765 3.95294118 3.29411765 3.95294118L3.29411765 3.95294118zM7.90588235 3.95294118C9.02588235 3.95294118 9.88235294 3.09647059 9.88235294 1.97647059 9.88235294.856470588 9.02588235 1.33226763e-15 7.90588235 1.33226763e-15 6.78588235 1.33226763e-15 5.92941176.856470588 5.92941176 1.97647059 5.92941176 3.09647059 6.78588235 3.95294118 7.90588235 3.95294118L7.90588235 3.95294118zM3.29411765 4.61176471C2.21176471 4.61176471 5.59552404e-14 5.13882353 5.59552404e-14 6.14901961L5.59552404e-14 7.24705882 6.58823529 7.24705882 6.58823529 6.14901961C6.58823529 5.13882353 4.37647059 4.61176471 3.29411765 4.61176471L3.29411765 4.61176471zM7.24705882 7.24705882L7.24705882 5.8745098C7.24705882 5.44831632 6.94471409 5.09091877 6.49417835 4.80442802 7.00757474 4.67661773 7.52621393 4.61176471 7.90588235 4.61176471 8.98823529 4.61176471 11.2 5.13882353 11.2 6.14901961L11.2 7.24705882 7.24705882 7.24705882z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default SpecialInterestGroupIcon;
