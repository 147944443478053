import { Action } from './types';
import { Features } from 'mm-types';
import { SET_FEATURE_OFF, SET_FEATURE_ON, SET_FEATURES } from './actions';
import { setSessionFeature } from './featureSwitchUtils';

export function featureSwitchReducer(state: Features = {}, action: Action): Features {
  switch (action.type) {
    case SET_FEATURES: {
      return {
        ...action.features
      };
    }
    case SET_FEATURE_ON: {
      setSessionFeature(action.name, true, state);
      return {
        ...state,
        [action.name]: true
      };
    }

    case SET_FEATURE_OFF: {
      setSessionFeature(action.name, false, state);
      return {
        ...state,
        [action.name]: false
      };
    }
    default:
      return state;
  }
}

export default featureSwitchReducer;
