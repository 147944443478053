import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import MediaStore from '../../../flux/editor/MediaStore';
import { IMedia, ISource } from 'mm-types';
import MediaProps from './MediaProps';
import { Dialog, FlatButton } from 'material-ui';
import { getUser } from '../../../clients/users';
import ImagePan from './components/MediaLibDetail/ImagePan';
import Zoom from './components/MediaLibDetail/Zoom';
import MediaDetailThumb from './components/MediaLibDetail/MediaDetailThumb';

export type Props = {
  media: IMedia;
  onModalCloseRequest: () => void;
  onSelfCloseRequest: () => void;
};

export type Media = {
  uid: string;
  caption: string;
  description: string;
  filename: string;
  location: string;
  type: string;
};

export type State = {
  detailedMedia: IMedia;
  mediaLength: number | null;
  scale: number;
  showDeleteModal: boolean | string;
  showErrorModal: boolean | null;
  isEditing: boolean;
  isUploadingSource: boolean;
  isFetching: boolean;
  avatarUrl: string | null;
};

const MediaLibDetail = (props: Props) => {
  const [state, setState] = useState<State>({
    mediaLength: 0,
    scale: 1,
    showDeleteModal: false,
    showErrorModal: false,
    isUploadingSource: false,
    isFetching: true,
    isEditing: false,
    avatarUrl: null,
    detailedMedia: props.media
  });
  const imagePanRef = useRef<ImagePan | null>(null);

  useEffect(() => {
    getDetails();
    getAvatar();
  }, []);

  const getDetails = async () => {
    const detailedMedia = await MediaStore.getDetails(props.media.uid);
    setState((prevState) => ({ ...prevState, detailedMedia: detailedMedia!, isFetching: false }));
  };

  const getAvatar = async () => {
    const user = await getUser(props.media.metadata.uploaderUserUid);
    setState((prevState) => ({ ...prevState, avatarUrl: user.avatarUrl }));
  };

  const onDeleteDialogCancel = () => {
    setState((prevState) => ({ ...prevState, showDeleteModal: false }));
  };

  const onDeleteDialogConfirm = () => {
    if (!props.media) {
      return;
    }
    const isSourceFile = typeof state.showDeleteModal === 'string';
    const deleteMediaUid = isSourceFile ? state.showDeleteModal : props.media.uid;
    setState((prevState) => ({ ...prevState, showDeleteModal: false }));
    deleteMedia(deleteMediaUid as string, isSourceFile);
  };

  const deleteMedia = async (sourceMediaUid: string | null = null, isSourceFile: boolean) => {
    const success = await MediaStore.deleteMedia(props.media.uid, props.media.filename, sourceMediaUid);
    if (success) {
      if (!isSourceFile) {
        props.onSelfCloseRequest();
      } else {
        _.remove(state.detailedMedia.sources, (source: ISource) => source.uid === sourceMediaUid);
        setState((prevState) => ({ ...prevState, showDeleteModal: false }));
      }
    } else {
      setState((prevState) => ({ ...prevState, showErrorModal: true }));
    }
  };

  const onErrorDialogCancel = () => {
    setState((prevState) => ({ ...prevState, showErrorModal: false }));
  };

  const handleZoom = (value) => {
    const zoomIn = value > state.scale;
    setState((prevState) => ({ ...prevState, scale: value }));
    imagePanRef.current?.setScroll(zoomIn);
  };

  const doZoom = (isOut) => {
    const currentScale = state.scale;
    if (isOut) {
      setState((prevState) => ({ ...prevState, scale: state.scale > 1 ? state.scale - 1 : state.scale }));
      if (currentScale !== state.scale) {
        imagePanRef.current?.setScroll(false);
      }
    } else {
      setState((prevState) => ({ ...prevState, scale: state.scale < 5 ? state.scale + 1 : state.scale }));
      if (currentScale !== state.scale) {
        imagePanRef.current?.setScroll();
      }
    }
  };

  const onDelete = () => {
    setState((prevState) => ({ ...prevState, showDeleteModal: true }));
  };

  const onEdit = () => {
    setState((prevState) => ({ ...prevState, isEditing: true }));
  };

  const onHideForm = () => {
    setState((prevState) => ({ ...prevState, isEditing: false }));
  };

  const onSaveForm = (updatedMedia: IMedia) => {
    MediaStore.updateMedia(updatedMedia, () => {
      setState((prevState) => ({
        ...prevState,
        isEditing: false,
        detailedMedia: {
          ...state.detailedMedia,
          caption: updatedMedia.caption,
          description: updatedMedia.description,
          type: updatedMedia.type
        }
      }));
    });
  };

  const onRemoveSource = (sourceMedia) => {
    setState((prevState) => ({ ...prevState, showDeleteModal: sourceMedia.uid }));
  };

  const onAddSource = async (updatedMedia, fileList: FileList) => {
    const file = fileList.item(0);
    if (!file) {
      return;
    }
    setState((prevState) => ({ ...prevState, isUploadingSource: true }));
    await MediaStore.uploadSource(updatedMedia, [file]);
    await getDetails();
    setState((prevState) => ({ ...prevState, isUploadingSource: false }));
  };

  return (
    <div className="medialib-detail-container">
      <Dialog
        style={{ zIndex: 999 }}
        modal={true}
        open={!!state.showDeleteModal}
        className="medialib-detail-delete-dialog"
        actions={[
          <FlatButton key={1} label="Cancel" data-qa="cancel" onClick={onDeleteDialogCancel.bind(this)} />,
          <FlatButton key={2} label="Delete" data-qa="confirm" onClick={onDeleteDialogConfirm.bind(this)} />
        ]}
        onRequestClose={onDeleteDialogCancel.bind(this)}
        title="Delete Media"
      >
        Are you sure you wish to delete this media item?
      </Dialog>

      <Dialog
        style={{ zIndex: 999 }}
        id="MediaModal"
        modal={true}
        className="medialib-detail-error-dialog"
        open={!!state.showErrorModal}
        actions={[<FlatButton key={3} label="Ok" data-qa="confirm" onClick={onErrorDialogCancel.bind(this)} />]}
        onRequestClose={onErrorDialogCancel.bind(this)}
        title="Media In Use"
      >
        This media item is already in use in your document, and therefore cannot be deleted.
      </Dialog>

      {state.detailedMedia.type === 'image' && <Zoom doZoom={doZoom.bind(this)} handleZoom={handleZoom.bind(this)} scale={state.scale} />}

      <div className="medialib-detail-body animation-fade-in">
        {state.detailedMedia && (
          <React.Fragment>
            <div className="medialib-detail-fullsize-img">
              <MediaDetailThumb detailedMedia={state.detailedMedia} media={props.media} scale={state.scale} imagePanRef={imagePanRef} />
            </div>
            <MediaProps
              key="item-details"
              media={state.detailedMedia}
              userAvatarUrl={state.avatarUrl}
              onModalCloseRequest={props.onModalCloseRequest}
              onDelete={onDelete.bind(this)}
              onEdit={onEdit.bind(this)}
              onHideForm={onHideForm.bind(this)}
              onSaveForm={onSaveForm.bind(this)}
              onRemoveSource={onRemoveSource.bind(this)}
              onAddSource={onAddSource.bind(this)}
              isEditing={state.isEditing}
              isFetching={state.isFetching}
              isUploadingSource={state.isUploadingSource}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default MediaLibDetail;
