import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import ModalDropzone from '../../../../general/ModalDropzone';

export type Props = {
  onClose: () => void;
  importCSV: (files) => void;
  onUpload: (file: File) => void;
};

export type State = {
  open: boolean;
  showValidation: boolean;
  file: File | null;
  currentFile: File | null;
  disableDrop: boolean;
  fileNames: string[];
  fileDropped: boolean;
  fileError: boolean;
  errorMsg: string | undefined;
  dropBoxContents: JSX.Element | undefined;
  fileUploading: boolean;

  model: {
    isUpdate: boolean;
    isUploading: boolean;
    type: string;
  };
};

export default class TaskModal extends React.Component<Props, State> {
  private _keypressProxy: any;

  constructor(props: Props) {
    super(props);

    this._keypressProxy = this._keypress.bind(this);

    this.state = {
      dropBoxContents: undefined,
      fileError: false,
      open: false,
      currentFile: null,
      fileUploading: false,
      disableDrop: false,
      fileNames: [],
      errorMsg: undefined,
      fileDropped: false,
      file: null,
      showValidation: false,
      model: { isUpdate: false, type: '', isUploading: false }
    };
  }

  _stopDefault(e) {
    let i = 0,
      target = e.target;

    while (target && i++ < 10) {
      if (target.classList.contains('allowClick')) {
        // must implement their own prevent, stop
        return;
      }
      target = target.parentElement;
    }
    e.stopPropagation();
    e.preventDefault();
  }

  upload() {
    if (this.state.currentFile) {
      this.props.onUpload(this.state.currentFile);
    }
  }

  componentDidMount() {
    // need slight delay with open modal for selection above to take hold
    setTimeout(() => {
      this.setState(
        {
          showValidation: false,
          open: true
        },
        () => {
          document.addEventListener('keyup', this._keypressProxy);
        }
      );
    }, 100);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this._keypressProxy);
  }

  dismiss() {
    setTimeout(() => {
      // need a slight delay here or clicklistener will be removed before final click
      this.setState({ open: false }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }, 100);
  }

  _onUpdateModel(name, val) {}

  onFileCancel() {
    this.setState({
      file: null,
      fileNames: [],
      fileDropped: false,
      disableDrop: false
    });
  }

  _keypress(e) {
    if (e.keyCode === 13) {
      this.upload();
    } else if (e.keyCode === 27) {
      this.dismiss();
    }
  }

  _onDropRejectedFiles() {
    this.setState({
      errorMsg: 'Error: Cannot upload invalid file type',
      fileError: true,
      file: null
    });
  }

  _onDropFiles(files: File[]) {
    this.setState({
      file: files[0],
      fileDropped: true,
      disableDrop: true,
      fileError: false,
      errorMsg: ''
    });
  }

  _importToCSV(e: React.SyntheticEvent<{}>) {
    this.props.importCSV(this.state.file);
    e.stopPropagation();
    e.preventDefault();
    this.setState({ fileUploading: true });
  }

  getWidth() {
    if (this.state.model.type === 'cross-document') {
      return '1200px';
    } else {
      return '850px';
    }
  }

  render() {
    if (!this.state || !this.state.model) {
      return <span />;
    }
    const files = this.state.file ? [this.state.file] : [];
    return (
      <Dialog
        ref="dialog"
        className="task-dialog"
        style={{ zIndex: 999, width: '100%' }}
        titleStyle={{ fontSize: 19 + 'px', fontWeight: 600 }}
        actionsContainerStyle={{ padding: 25 + 'px', border: 'none', paddingTop: 0 }}
        contentStyle={{ height: '98%', top: '50%', transform: 'translateY(calc(50% - 200px))' }}
        title={this.state.model.isUpdate ? 'Edit a Link' : 'Import Change Tasks'}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            key={1}
            label="Cancel"
            onClick={() => {
              this.dismiss();
            }}
            style={{ padding: 15 + 'px', height: 40 + 'px', lineHeight: 10 + 'px', marginRight: 20 + 'px' }}
          />,
          <FlatButton
            key={2}
            id="import-task-btn"
            label="Import Tasks"
            className={this.state.fileDropped ? 'importTaskButtonActive' : 'importTaskButtonDisabled'}
            disabled={(!this.state.fileDropped && !this.state.fileError) || this.state.fileUploading}
            style={{ color: 'white', padding: 15 + 'px', height: 40 + 'px', lineHeight: 10 + 'px' }}
            onClick={(e) => {
              this._importToCSV(e);
            }}
          />
        ]}
        open={this.state.open}
      >
        <div className="task-modal">
          <span onClick={() => this.props.onClose()} className="close-thin" />
          <div className="user-settings-column">
            <ModalDropzone
              onDropFiles={(files: File[]) => this._onDropFiles(files)}
              onDropRejected={() => this._onDropRejectedFiles()}
              disableClick={this.state.disableDrop}
              multiple={false}
              files={files}
              uploadType="aerodocs"
              onRemove={() => this.onFileCancel()}
              errorMsg={this.state.errorMsg}
              accept=".csv"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
