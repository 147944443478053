import { mm, Cancelled } from '../../../clients/base-clients';
import axios, { CancelTokenSource } from 'axios';
import { IKPIWorkflowTask, IKPIWorkflowSummary, IKPIProjectSummary } from 'mm-types';
import moment from 'moment';
import { dateUtil } from '../../../utils';
import { WorkflowActionsExportParams } from '../../dashboard/WorkflowActionsTile/WorkflowActionsTileContainer';

let getWorkflowsSource: CancelTokenSource | null = null;
let getMyWorkflowsSource: CancelTokenSource | null = null;
let getWorkflowSummaryWorkspaceSource: CancelTokenSource | null = null;
let getWorkflowSummaryDepartmentSource: CancelTokenSource | null = null;
let getDepartmentProjectSummarySource: CancelTokenSource | null = null;
let getWorkspaceProjectSummarySource: CancelTokenSource | null = null;
let exportWorkflowActionsSource: CancelTokenSource | null = null;
let getTotalDocumentsCSVSource: CancelTokenSource | null = null;
export type WorkflowTasksQueryOptions = {
  organisationUid?: string;
  departmentUid?: string;
  direction?: 'ASC' | 'DESC';
  orderBy?: 'TITLE' | 'REFERENCE' | 'WORKFLOW_NAME' | 'CURRENT_WORKFLOW_STAGE' | 'DUE_DATE' | 'DAYS_OVERDUE' | 'TEAMSPACE';
  overdueRecords?: boolean;
  pageNumber?: number;
  pageSize?: number;
  pendingApprovalRecords?: boolean;
  projectName?: string;
  reference?: string;
  workspaceUid?: string;
};

export type WorkflowTasksPromiseResponse = {
  workflowTasks: IKPIWorkflowTask[];
  numOfPages: number;
  totalElements: number;
};

export type WorkflowSummaryQueryOptions = {
  workspaceUid?: string;
  departmentUid?: string;
};

export type DepartmentProjectSummaryQueryOptions = {
  departmentUid?: string;
  organisationUid?: string;
};

export type WorkspaceProjectSummaryQueryOptions = {
  workspaceUid?: string;
};

export type WorkflowActionFilterInfo = { uid: string; name: string };

export type WorkflowActionUser = WorkflowActionFilterInfo & { staffId?: string };

export type WorkflowActionDepartment = WorkflowActionFilterInfo & { orgUid: string };

export type WorkflowActionOrg = WorkflowActionFilterInfo & { departments: WorkflowActionDepartment[] };

export type WorkflowActionWorkspace = WorkflowActionFilterInfo;

export type WorkflowActionFilters = {
  departments?: WorkflowActionDepartment[];
  orgs?: WorkflowActionOrg[];
  workspaces?: WorkflowActionWorkspace[];
  users?: WorkflowActionUser[];
};

export type WorkflowAction = {
  userName: string;
  org: string;
  department: string;
  workspace: string;
  docRef: string;
  projectName: string;
  revNumber: string;
  revDate: string;
  action: string;
  actionDate: string;
  workflowStageAssignmentDuration: number;
  staffId?: string;
  workflowStageName?: string;
  workflowStageCreated?: string;
  workflowStageAssigned?: string;
  workflowStageDuration?: number;
  reviewResult?: string;
  workflowStageDueDate?: string;
  comment?: string;
};

export type WorkflowActionResponse = {
  actions: WorkflowAction[];
  total: number;
};

export async function generateWorkflowReport(queryOptions?: Partial<WorkflowTasksQueryOptions>, isWorkspaceView = false) {
  const isMyWorkflowsRequest = !(queryOptions?.organisationUid || isWorkspaceView);
  if (queryOptions?.organisationUid && !isWorkspaceView) {
    queryOptions['orgUid'] = queryOptions?.organisationUid;
  }
  await mm.post(`/kpi/reports/${isMyWorkflowsRequest ? 'my-workflows' : 'workflows'}`, queryOptions);
}

export async function getMyWorkflows(options?: WorkflowTasksQueryOptions): Promise<WorkflowTasksPromiseResponse | Cancelled> {
  if (getMyWorkflowsSource && options?.departmentUid) {
    console.log('Invalid param departmentUuid, use /kpi/workflow endpoint instead');
    getMyWorkflowsSource.cancel();
  }

  getMyWorkflowsSource = axios.CancelToken.source();

  const queryString: string = getQueryString(options);

  try {
    const response = await mm.get<WorkflowTasksPromiseResponse>(`/kpi/my-workflows${queryString}`, {
      cancelToken: getMyWorkflowsSource.token
    });

    return parseWorkflowTasks(response.data);
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }
    throw err;
  }
}

export async function getWorkflows(options?: WorkflowTasksQueryOptions): Promise<WorkflowTasksPromiseResponse | Cancelled> {
  if (getWorkflowsSource) {
    getWorkflowsSource.cancel();
  }

  getWorkflowsSource = axios.CancelToken.source();

  const queryString: string = getQueryString(options);

  try {
    const response = await mm.get<WorkflowTasksPromiseResponse>(`/kpi/workflows${queryString}`, {
      cancelToken: getWorkflowsSource.token
    });

    return parseWorkflowTasks(response.data);
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }
    throw err;
  }
}

function getQueryString(options?: WorkflowTasksQueryOptions) {
  const query: string[] = [];

  if (options) {
    Object.keys(options).forEach((option) => {
      if (typeof options[option] !== 'undefined') {
        query.push(`${option}=${options[option]}`);
      }
    });
  }

  return query.length ? `?${query.join('&')}` : '';
}

function parseWorkflowTasks(response: WorkflowTasksPromiseResponse): WorkflowTasksPromiseResponse {
  const { workflowTasks, numOfPages, totalElements } = response;
  if (workflowTasks?.length) {
    for (let i = 0; i < workflowTasks.length; i++) {
      const task = workflowTasks[i];
      const { dueDate, indexEntity } = task;
      const { status: indexStatus } = indexEntity;

      task.formattedDueDate = dueDate ? dateUtil(dueDate).formatDate() : '-';
      task.isOverdue = !!dueDate && moment(dueDate).isBefore(moment().toISOString());
      task.isPublished = indexStatus === 'MASTER_PUBLISHED' || indexStatus === 'INTERIM_PUBLISHED';
    }
  }

  return { workflowTasks, numOfPages, totalElements };
}

export async function getWorkflowSummaryWorkspace(options?: WorkflowSummaryQueryOptions): Promise<IKPIWorkflowSummary | Cancelled> {
  const query: string[] = [];

  if (getWorkflowSummaryWorkspaceSource) {
    getWorkflowSummaryWorkspaceSource.cancel();
  }

  getWorkflowSummaryWorkspaceSource = axios.CancelToken.source();

  if (options && options.workspaceUid) {
    query.push('workspaceUid=' + options.workspaceUid);
  }

  try {
    const response = await mm.get<{ summary }>(`/kpi/workflow-summary?${query.join('&')}`, {
      cancelToken: getWorkflowSummaryWorkspaceSource.token
    });

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getWorkflowSummaryDepartment(options?: WorkflowSummaryQueryOptions): Promise<IKPIWorkflowSummary | Cancelled> {
  const query: string[] = [];

  if (getWorkflowSummaryDepartmentSource) {
    getWorkflowSummaryDepartmentSource.cancel();
  }

  getWorkflowSummaryDepartmentSource = axios.CancelToken.source();

  if (options?.departmentUid) {
    query.push('departmentUid=' + options.departmentUid);
  }

  try {
    const response = await mm.get<{ summary }>(`/kpi/workflow-summary?${query.join('&')}`, {
      cancelToken: getWorkflowSummaryDepartmentSource.token
    });

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getDepartmentProjectSummary(options?: DepartmentProjectSummaryQueryOptions): Promise<IKPIProjectSummary | Cancelled> {
  const query: string[] = [];

  if (getDepartmentProjectSummarySource) {
    getDepartmentProjectSummarySource.cancel();
  }

  getDepartmentProjectSummarySource = axios.CancelToken.source();

  if (options?.organisationUid) {
    query.push('organisationUid=' + options.organisationUid);
  }
  if (options?.departmentUid) {
    query.push('departmentUid=' + options.departmentUid);
  }

  try {
    const response = await mm.get(`kpi/project-summary?${query.join('&')}`, {
      cancelToken: getDepartmentProjectSummarySource.token
    });
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }
    throw err;
  }
}

export async function getWorkspaceProjectSummary(options?: WorkspaceProjectSummaryQueryOptions): Promise<IKPIProjectSummary | Cancelled> {
  const query: string[] = [];

  if (getWorkspaceProjectSummarySource) {
    getWorkspaceProjectSummarySource.cancel();
  }

  getWorkspaceProjectSummarySource = axios.CancelToken.source();

  if (options?.workspaceUid) {
    query.push('workspaceUid=' + options.workspaceUid);
  }

  try {
    const response = await mm.get(`kpi/project-summary?${query.join('&')}`, {
      cancelToken: getWorkspaceProjectSummarySource.token
    });
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }
    throw err;
  }
}

export async function exportWorkflowActions(params: WorkflowActionsExportParams): Promise<Cancelled | void> {
  if (exportWorkflowActionsSource) {
    exportWorkflowActionsSource.cancel();
  }
  exportWorkflowActionsSource = axios.CancelToken.source();

  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  try {
    await mm.post('kpi/reports/workflow-actions', {
      ...params,
      from: params.from.getTime(),
      to: params.to.getTime(),
      cancelToken: exportWorkflowActionsSource.token
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return new Cancelled();
    }
    throw e;
  }
}

export async function getTotalDocumentsCSV(
  options?: WorkspaceProjectSummaryQueryOptions & DepartmentProjectSummaryQueryOptions
): Promise<Cancelled | void> {
  if (getTotalDocumentsCSVSource) {
    getTotalDocumentsCSVSource.cancel();
  }

  getTotalDocumentsCSVSource = axios.CancelToken.source();
  try {
    await mm.post('kpi/reports/total-documents', { ...options, cancelToken: getTotalDocumentsCSVSource.token });
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getWorkflowActionFilters(params: WorkflowActionsExportParams): Promise<WorkflowActionFilters> {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await mm.post<WorkflowActionFilters>('kpi/workflow-filters', {
    ...params,
    from: params.from.getTime(),
    to: params.to.getTime()
  });
  return res.data;
}

export async function getWorkflowActions(params: WorkflowActionsExportParams): Promise<WorkflowActionResponse> {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  const res = await mm.post<WorkflowActionResponse>('kpi/workflow-actions', {
    ...params,
    from: params.from.getTime(),
    to: params.to.getTime()
  });
  return res.data;
}
