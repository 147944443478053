import * as React from 'react';

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  subLineStyles: {
    marginTop: '0.6rem',
    fontSize: '1rem',
    color: '#757575',
    width: '70%',
    display: 'inline-block'
  },
  messageContainer: { marginTop: '15px' },
  message: { fontStyle: 'italic' },
  description: { marginTop: '15px' }
};

const NoDocumentsToView = () => {
  return (
    <div>
      <div className="graphic">
        <img src="/assets/images/empty-state-my-workflow-task.svg" />
      </div>
      <div style={defaultStyles.messageContainer}>
        <div style={{ ...defaultStyles.subLineStyles, ...defaultStyles.message }}>No documents to view</div>
        <div style={{ ...defaultStyles.subLineStyles, ...defaultStyles.description }}>
          There are no outstanding Workflow Tasks assigned to you
        </div>
      </div>
    </div>
  );
};

export default NoDocumentsToView;
