import * as React from 'react';
import { DropDownMenu, MenuItem } from 'material-ui';

type MetaDataDropdownProps = {
  value: string;
  title: string;
  isDisabled?: boolean;
  getDefaultValuesByTitle: (title: string) => string[];
};

const MetaDataDropdown = ({ value, title, isDisabled, getDefaultValuesByTitle }: MetaDataDropdownProps) => {
  const baseStyles = { width: '25px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
  return (
    <div className="dimension column" key={'arc-dropdown_' + title}>
      <h6>{title}</h6>
      <DropDownMenu
        disabled={isDisabled}
        style={Object.assign({}, baseStyles, { float: 'right', marginRight: '5px' }) as React.CSSProperties}
        labelStyle={
          Object.assign({}, baseStyles, {
            paddingLeft: '0px',
            paddingRight: '3px',
            paddingTop: '3px',
            right: '60px',
            textAlign: 'right',
            width: '70px'
          }) as React.CSSProperties
        }
        iconStyle={Object.assign({}, baseStyles, { top: '0', left: '8px' }) as React.CSSProperties}
        menuStyle={{ width: '100px' }}
        listStyle={{ paddingTop: 0, paddingBottom: 0 }}
        value={value}
      >
        {getDefaultValuesByTitle(title).map((item) => {
          return <MenuItem key={'arc-menuitem_' + title + item} value={item} primaryText={item} />;
        })}
      </DropDownMenu>
    </div>
  );
};

export default MetaDataDropdown;
