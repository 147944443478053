import { AxiosPromise } from 'axios';
import NotificationsStore from '../flux/events/NotificationsStore';
import { INotification } from 'mm-types';
import { mm } from '../clients/base-clients';

export enum FileExtension {
  ZIP,
  EDB,
  AIRBUS,
  HELP
}

export interface ExportData {
  projectUid: string;
  indexUid: string;
}

class AsyncExportUtil {
  async export(extension: FileExtension, data: ExportData[]) {
    NotificationsStore.startNotifProgress();
    data.forEach((element) => {
      this.createRequest(extension, element);
    });
  }

  private getUrl(extension: FileExtension, data: ExportData): string {
    let ext = 'async';
    if (extension === FileExtension.EDB) {
      ext = 'edbAsync';
    }
    if (extension === FileExtension.AIRBUS) {
      ext = 'airbusAsync';
    }
    if (extension === FileExtension.HELP) {
      ext = 'help';
    }
    return `/projects/${data.projectUid}/indexes/${data.indexUid}/export/${ext}`;
  }

  private createRequest(extension: FileExtension, data: ExportData): AxiosPromise<INotification> {
    return mm.get<INotification>(this.getUrl(extension, data), {});
  }
}

const asyncExportUtil = new AsyncExportUtil();
export default asyncExportUtil;
