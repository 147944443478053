import * as React from 'react';

export type Props = {
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
  dataQa?: string;
};

function ReasonBox(props: Props) {
  return (
    <div
      className={'invalid-dtd-reason-box ' + props.className + (props.isOpen ? ' open' : ' closed')}
      data-qa={props.dataQa ?? 'invalid-dtd-reason-box'}
      tabIndex={-1}
      style={{ display: props.isOpen ? 'block' : 'none' }}
    >
      <div className="invalid-dtd-reason-box__content">{props.children}</div>
    </div>
  );
}

export default ReasonBox;
