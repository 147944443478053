import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children: JSX.Element | JSX.Element[];
  parentComponentId: string;
  display: boolean;
};

const Portal = ({ children, parentComponentId, display }: Props) => {
  const portalParent = useRef<HTMLDivElement>(document.createElement('div'));
  useEffect(() => {
    if (display) {
      portalParent.current.id = parentComponentId;
      document.body.appendChild(portalParent.current);
      return () => {
        if (document.getElementById(parentComponentId)) {
          document.body.removeChild(portalParent.current);
        }
      };
    }
  }, [display]);

  return display ? ReactDOM.createPortal(children, portalParent.current) : null;
};

export default Portal;
