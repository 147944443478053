import * as React from 'react';

import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import { List, ListItem } from 'material-ui/List';

export type Props = {
  alerts: string[];
};

export type State = {
  open: boolean;
  anchorEl: null | React.ReactInstance;
};

export default class WorkflowAlerts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  static defaultProps: Partial<Props> = {
    alerts: []
  };

  handleClick(event: React.MouseEvent<{}>) {
    // This prevents ghost click.
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      open: true,
      anchorEl: event.currentTarget as HTMLElement
    });
  }

  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  _getAlertListItems() {
    if (this.props.alerts) {
      return this.props.alerts.map((alert) => {
        return <ListItem key={alert} primaryText={alert} />;
      });
    }
  }

  render() {
    if (this.props.alerts && this.props.alerts.length > 0) {
      return (
        <div className="workflow-alerts">
          <IconButton onClick={(e) => this.handleClick(e)} className="workflow-alerts-button">
            <FontIcon className="material-icons alert">error</FontIcon>
          </IconButton>
          <Popover
            open={this.state.open}
            anchorEl={this.state.anchorEl!}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={() => this.handleRequestClose()}
          >
            <List>{this._getAlertListItems()}</List>
          </Popover>
        </div>
      );
    } else {
      return <div className="workflow-alerts" />;
    }
  }
}
