import React, { CSSProperties, useMemo } from 'react';
import { styleMap } from '../../../../styles/styleGuard';
import { classNameHelper } from '../../../../utils';

const styles = styleMap({
  menuElement: {
    height: 64
  },
  info: {
    paddingRight: 16,
    paddingLeft: 16
  },
  separator: {
    display: 'inline-block',
    borderLeft: '1px solid #c7e4ec',
    height: 41,
    marginTop: 12
  },
  itemSpace: {
    marginRight: '13rem',
    height: 64
  }
});

interface Props {
  id?: string;
  dataQa?: string;
  onRight?: boolean;
  editingOverride?: boolean;
  exportButtons?: boolean;
  publishAction?: boolean;
  actionButtons?: boolean;
  info?: boolean;
  hoverable?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?(e: React.MouseEvent<HTMLLIElement>): void;
}

function getClassName(props: Props) {
  return classNameHelper.merge(props.className, {
    right: !!props.onRight,
    editingOverride: !!props.editingOverride,
    info: !!props.info,
    'export-buttons': !!props.exportButtons,
    'action-button': !!props.actionButtons,
    'publish-action': !!props.publishAction,
    'with-hover cursor-pointer': !!props.onClick
  });
}

export function MenuInfoElement({ style, children, ...rest }: Omit<Props, 'info'>) {
  return (
    <MenuElement style={{ ...styles.info, ...style }} {...rest} info={true}>
      {children}
    </MenuElement>
  );
}

export function MenuElement(props: Props) {
  const className = useMemo(() => getClassName(props), [props]);
  return (
    <li
      className={className}
      onClick={props.onClick}
      style={{ ...styles.menuElement, ...props.style }}
      id={props.id}
      data-qa={props.dataQa ?? 'menu-element'}
    >
      {props.children}
    </li>
  );
}

export function MenuContainer(props: Pick<Props, 'children'>) {
  return (
    <ul className={`icon-action-flatbuttons`} data-qa="workflow-menu-container">
      {props.children}
    </ul>
  );
}

export function MenuSeparator(props: Pick<Props, 'onRight'>) {
  const className = useMemo(() => getClassName(props), [props]);
  return <li style={styles.separator} className={className} />;
}

export function MenuItemSpace(props: Pick<Props, 'onRight' | 'exportButtons'>) {
  const className = useMemo(() => getClassName(props), [props]);
  return <li style={styles.itemSpace} className={className} />;
}
