import * as React from 'react';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import AppStateStore, { AppStateStoreEvent } from '../../flux/common/AppStateStore';
import OrgAdminStore, { OrgAdminStoreEvent } from '../../flux/settings/OrgAdminStore';
import AgentUtil from '../../utils/AgentUtil';
import { transitionTo } from '../../utils/Navigation';
import { Avatar, IconButton, IconMenu, MenuItem } from 'material-ui';
import Notifications from './Notifications';
import { IUser } from 'mm-types';
import FileAttachmentNotifications from './FileAttachmentNotifications';
import appNavigator from '../../utils/AppNavigator';
import * as _ from 'lodash';

export type Props = {
  location: { pathname: string };
  isEditorSupported?: boolean;
};

export type State = {
  open: boolean;
  user: IUser | null;
  isOrgAdmin: boolean;
};

export default class UserMenu extends React.Component<Props, State> {
  private activeUsersUnsub: Function;
  private appUnsub: Function;
  private orgAdminUnsub: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      user: null,
      open: false,
      isOrgAdmin: false
    };
  }

  static defaultProps: Partial<Props> = {
    isEditorSupported: AgentUtil.isEditorSupported()
  };

  onUpdateUser() {
    this.setState({ user: ActiveUserStore.getUser()! });
  }

  onAppStoreChange(e: AppStateStoreEvent) {
    if (e.type === 'menuChange') {
      if (e.data.openedMenu !== 'USER') {
        this.setState({ open: false });
      }
    }
  }

  onOrgAdminStoreChange(e: OrgAdminStoreEvent) {
    this.setState({
      isOrgAdmin: OrgAdminStore.isOrgAdmin()
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({ user: ActiveUserStore.getUser()! });
    OrgAdminStore.currentUserOrgAdminList();
  }

  componentWillUnmount() {
    this.appUnsub();
    this.activeUsersUnsub();
    this.orgAdminUnsub();
  }

  componentDidMount() {
    this.activeUsersUnsub = ActiveUserStore.listen(this.onUpdateUser, this);
    this.appUnsub = AppStateStore.listen(this.onAppStoreChange, this);
    this.orgAdminUnsub = OrgAdminStore.listen(this.onOrgAdminStoreChange, this);

    this.setState({ user: ActiveUserStore.getUser()! });
  }

  _openSettingsMenu() {
    this.setState({ open: true });
  }

  _closeSettingsMenu(callback?: () => void) {
    this.setState({ open: false }, () => {
      if (callback) {
        callback();
      }
    });
  }

  _handleMenuChange(open: boolean) {
    if (!open) {
      this._closeSettingsMenu();
    } else {
      AppStateStore.openMenu('USER');
    }
  }

  _isDisabled(enableSettings: boolean, isOrgAdmin: boolean) {
    if (isOrgAdmin) {
      return false;
    } else if (enableSettings) {
      return false;
    } else {
      return true;
    }
  }

  _getIconButton() {
    return (
      <IconButton iconClassName="material-icons" id="open-user-menu-dropdown">
        &#xE313;
      </IconButton>
    );
  }

  _getMenuOptions() {
    const style = { fontSize: '15px' };
    const isOrgAdmin = this.state.isOrgAdmin;
    const enableSettings = (ActiveUserStore.isAdmin() || ActiveUserStore.isTeamspaceAdmin()) && this.props.isEditorSupported!;
    return [
      <MenuItem
        key="my-settings"
        primaryText="My Settings"
        innerDivStyle={style}
        onClick={(e) => this._handleMenuClick(e, 'my-settings')}
      />,
      <MenuItem
        key="settings"
        primaryText="Settings"
        innerDivStyle={style}
        disabled={this._isDisabled(enableSettings!, isOrgAdmin)}
        onClick={(e) => this._handleMenuClick(e, 'settings')}
      />,
      <MenuItem key="logout" primaryText="Logout" innerDivStyle={style} onClick={(e) => this._handleMenuClick(e, 'logout')} />
    ];
  }

  _currentRoute() {
    return _.last(location.pathname.split('/'));
  }

  _openHelp() {
    let helpLink = '/help/2_1/';
    if (this._currentRoute() === 'dashboard') {
      helpLink = '/help/';
    }
    window.open(helpLink, '_blank');
  }

  _handleMenuClick(e: React.SyntheticEvent<{}>, key: string) {
    const user = ActiveUserStore.getUser()!;

    if (key === 'my-settings') {
      this._closeSettingsMenu(() => {
        transitionTo('/my-settings/profile');
      });
    } else if (key === 'settings') {
      this._closeSettingsMenu(() => {
        if (OrgAdminStore.isOrgAdmin() && !(ActiveUserStore.isAdmin() && ActiveUserStore.isTeamspaceAdmin())) {
          transitionTo('/user/' + user.uid + '/configure/form/docrefs');
        } else {
          transitionTo('/user/' + user.uid + '/configure/form/groups');
        }
      });
    } else if (key === 'logout') {
      appNavigator.goTo.logout();
    }
  }

  render() {
    return (
      <ul className="user-menu-container top-right-navigation">
        <li>
          <span onClick={() => this._openHelp()} className="material-icons help">
            help
          </span>
        </li>
        <li>
          <FileAttachmentNotifications />
        </li>
        <li>
          <Notifications />
        </li>
        <li>
          <h5 onClick={() => this._openSettingsMenu()}>{this.state.user ? this.state.user.displayName : ''}</h5>
          <IconMenu
            open={this.state.open}
            onClick={() => this._openSettingsMenu()}
            onRequestChange={(open) => this._handleMenuChange(open)}
            iconButtonElement={this._getIconButton()}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            id={'user-menu-dropdown'}
            ref={'userDropDown'}
          >
            {this._getMenuOptions()}
          </IconMenu>
        </li>
        <li>
          <Avatar className="profile-picture" src={this.state.user!.avatarUrl} />
        </li>
      </ul>
    );
  }
}
