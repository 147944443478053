const AFFECTED_UNITS_HTTP_HDR = 'X-Affected-UnitUids';
const AFFECTED_PARTS_HTTP_HDR = 'X-Affected-Parts';
const AFFECTED_REFRESH_VIEW_HTTP_HDR = 'X-Refresh-View';

function _getHeaderCsv(options: any, headerName: string) {
  let values: string[];
  if (options.xhr) {
    const responseHeader = options.xhr.getResponseHeader(headerName);
    values = responseHeader ? responseHeader.replace(/ /g, '').split(',') : [];
  } else {
    const value = options[headerName.toLowerCase()];
    values = value ? value.replace(/ /g, '').split(',') : [];
  }
  return values;
}

function _getHeaderBoolean(options: any, headerName: string) {
  let value = false;

  if (options.xhr) {
    const responseHeader = options.xhr.getResponseHeader(headerName);
    value = responseHeader ? 'true' === responseHeader : false;
  } else if (options[headerName.toLowerCase()]) {
    const v = options[headerName.toLowerCase()] as string;
    value = v ? 'true' === v : false;
  }
  return value;
}

function affected(headers: any) {
  const unitUids = _getHeaderCsv(headers, AFFECTED_UNITS_HTTP_HDR);
  const parts = _getHeaderCsv(headers, AFFECTED_PARTS_HTTP_HDR);
  const refreshView = _getHeaderBoolean(headers, AFFECTED_REFRESH_VIEW_HTTP_HDR);
  return { unitUids: unitUids, parts: parts, refreshView: refreshView };
}

export default {
  affected: affected
};
