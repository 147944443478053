import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../appStore';
import { Action, IProjectSettingsState } from './index';

interface Props {
  uid?: string;
  masterIndexUid?: string;
}

export function useProjectSettings(props: Props | null = {}): { settings: IProjectSettingsState | null; fetchSettings: () => void } {
  const projectSettings = useSelector<AppState, IProjectSettingsState>((state) => state.projectSettings);
  const dispatch = useDispatch();
  let settings: IProjectSettingsState | null = null;

  function fetchSettings() {
    if (props?.uid && props.masterIndexUid) {
      dispatch(Action.fetchProjectSettings(props.uid, props.masterIndexUid));
    }
  }

  if (props) {
    const { uid, masterIndexUid } = props;
    const {
      selected: { projectUid, masterIndexUid: indexUid, fetching }
    } = projectSettings;
    if (projectUid === uid && indexUid === masterIndexUid && !fetching) {
      settings = { ...projectSettings };
    }
  }

  return {
    settings,
    fetchSettings
  };
}
