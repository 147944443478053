import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as client from '../../clients/comments';
import CommentStatusStore from './CommentStatusStore';
import Store from '../Store';
import { IComment, IUnit, DocParams, IUser } from 'mm-types';
import { mm, Cancelled } from '../../clients/base-clients';

export type CommentStatus = 'ALL' | 'ACTIVE' | 'RESOLVED';
export type ExternalUserType = 'ALL' | 'PUBLIC' | 'INTERNAL';
export type DataParams = { tocUnitUid: string; unitUid: string };

export type State = {
  comments: IComment[];
  unitCommentMap: { [unitId: string]: string[] };
};

export type CommentStoreEvent = {
  uid?: string;
  comment?: IComment;
  unit?: IUnit;
  type: 'unitCommentMap' | 'comments' | 'comment-users' | 'selectedComment' | 'selectedCommentUid' | 'commentIntent' | 'retrieved-authors';
  state: State;
  authors?: IUser[];
};

export class CommentStore extends Store<State> {
  private _lastCommentListDataParams: Partial<DataParams> | null;

  constructor() {
    super();
    this._lastCommentListDataParams = null;
    this.state = {
      comments: [],
      unitCommentMap: {}
    };
  }

  getInitialState() {
    return this.state;
  }

  getComment(commentUid: string) {
    return this.state.comments.find((c) => c.uid === commentUid);
  }

  // Event Handlers

  async retrieveUnitCommentsMap(docParams: DocParams, silent = false) {
    const unitMap = await client.getUnitCommentMap(docParams.projectUid!, docParams.indexUid!);
    if (unitMap instanceof Cancelled) {
      return;
    }
    this.state = Object.assign(this.state, { unitCommentMap: unitMap });

    if (!silent) {
      this.trigger({ type: 'unitCommentMap', state: this.state } as CommentStoreEvent);
    }
  }

  async getAllAuthors(projectUid: string, indexUid: string) {
    try {
      const response = await mm.get<{ users: IUser[] }>(`/projects/${projectUid}/indexes/${indexUid}/comments/authors`);
      this.trigger({ type: 'retrieved-authors', authors: response.data.users, state: this.state } as CommentStoreEvent);
    } catch (err) {
      this.trigger({ type: 'retrieved-authors', authors: [], state: this.state } as CommentStoreEvent);
    }
  }

  async retrieveComments(
    projectUid: string,
    indexUid: string,
    dataParams?: Partial<DataParams> | null,
    options?: Partial<client.GetOptions>,
    silent = false
  ): Promise<IComment[]> {
    this._lastCommentListDataParams = dataParams || null;

    try {
      let comments = await client.getAll(projectUid, indexUid, options, dataParams?.tocUnitUid);
      if (comments instanceof Cancelled) {
        return [];
      }

      // Reindex
      for (let i = 0, l = (comments as IComment[]).length; i < l; i++) {
        comments[i].index = i;
      }

      this.state = Object.assign({}, this.state, { comments: comments as IComment[] });

      if (!silent) {
        this.trigger({ type: 'comments', state: this.state } as CommentStoreEvent);
      }

      return comments as IComment[];
    } catch (err) {
      this.state = Object.assign({}, this.state, { comments: [] });
      this.trigger({ type: 'comments', state: this.state } as CommentStoreEvent);
      return [];
    }
  }

  async comment(projectUid: string, indexUid: string, newComment: Partial<IComment>, options: Partial<client.GetOptions>) {
    newComment = await client.create(projectUid, indexUid, newComment);
    this.trigger({ type: 'selectedComment', comment: newComment });
    this._updateUnitCommentMap(newComment as IComment, 'add');

    return await this.retrieveComments(projectUid, indexUid, this._lastCommentListDataParams!, options);
  }

  async removeComment(
    projectUid: string,
    indexUid: string,
    options: client.GetOptions,
    deletedComment: Partial<IComment>,
    parentCommentUid: string
  ) {
    try {
      const comment = this._getCommentModel(deletedComment.uid!, parentCommentUid)!;
      await client.update(projectUid, indexUid, comment.uid, deletedComment);
      this._updateUnitCommentMap(comment, 'remove');
      await this.retrieveComments(projectUid, indexUid, this._lastCommentListDataParams, options);
      CommentStatusStore.listAllUnreadComments(projectUid, indexUid);
    } catch (err) {
      await this.retrieveComments(projectUid, indexUid, this._lastCommentListDataParams, options);
    }
  }

  async updateComment(projectUid: string, indexUid: string, options: client.GetOptions, updatedComment: IComment) {
    // filterSinceDateTime: number, unreadFilter: string, currentCommentFilter: CommentStatus, currentUserIdsFilter: string[], currentExternalAuthFilter: ExternalUserType, updatedComment: IComment, docParams: DocParams, onSuccess?: ( comments: IComment[] ) => void ) {

    try {
      const comment = this._getCommentModel(updatedComment.uid, updatedComment.threadUid)!;
      await client.update(projectUid, indexUid, comment.uid, updatedComment);
      // lazy should really update in memory, but will do for now
      return this.retrieveComments(projectUid, indexUid, this._lastCommentListDataParams, options);
    } catch (err) {
      return [];
    }
  }

  // note: this event doesn't change store state, as its for a single comment retrieval
  // note2: for a top level comment only (not a reply)
  async refreshComment(projectUid: string, indexUid: string, commentUid: string) {
    const commentIndex = this.state.comments.findIndex((c) => c.uid === commentUid);
    if (commentIndex !== -1) {
      const comment = await client.getOne(projectUid, indexUid, commentUid, { fullThread: true });
      this.state.comments[commentIndex] = comment;
      return this.state;
    }

    return this.state;
  }

  commentIntent(unit: IUnit | null) {
    this.trigger({ type: 'commentIntent', unit: unit } as CommentStoreEvent);
  }

  commentUid(uid: string) {
    this.trigger({ type: 'selectedCommentUid', uid: uid } as CommentStoreEvent);
  }

  // find comment from top level in collection and internal replies
  _getCommentModel(commentUid: string, rootCommentUid: string) {
    const rootComment = this.state.comments.find((c) => c.uid === (rootCommentUid ? rootCommentUid : commentUid));
    if (rootCommentUid) {
      const replies = rootComment!.replies;
      return replies.find((r) => r.uid === commentUid) || null;
    } else {
      return rootComment || null;
    }
  }

  // in memory update to the map
  private _updateUnitCommentMap(comment: IComment, type: 'add' | 'remove') {
    if (!comment.threadUid) {
      this.state.unitCommentMap = this.state.unitCommentMap || {}; // ensure theres always something to deal with
      const unitMap = this.state.unitCommentMap[comment.unitUid] || [];

      if (type === 'remove') {
        const removeIndex = unitMap.indexOf(comment.uid);
        if (removeIndex !== -1) {
          unitMap.splice(removeIndex, 1);
          this.state.unitCommentMap[comment.unitUid] = unitMap;
        }
      } else if (type === 'add') {
        unitMap.push(comment.uid);
        this.state.unitCommentMap[comment.unitUid] = unitMap;
      }

      this.trigger({ type: 'unitCommentMap', state: this.state } as CommentStoreEvent);
    }
  }
}

const singleton = Reflux.initStore<CommentStore>(CommentStore);
export default singleton;
