import * as React from 'react';
import isEqual from 'react-fast-compare';
import { IDataAttribute } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import {
  clearDependableDataAttribute,
  DataAttributesValues,
  defaultValue,
  getElmDef,
  handleConditionalProperty,
  handleLayerProperty,
  isConditionalProperty,
  isDataAttributeApplicable,
  isLayerProperty
} from '../../../../../../utils/DataAttributesUtil';
import { IGenericEditProps } from './GenericEditProps';
import { Dom } from '../../../../utils/tinyFacade/DomUtil';
import { RenderDataAttributes } from '../../renderDataAttributes/RenderDataAttributes';
import usePrevious from '../../../../../hooks/usePrevious';
import { useUpdateChildRhsAttributes } from '../hooks/useUpdateChildRhsAttributes';

export type Props = {
  inline?: boolean;
  dataAttributes: IDataAttribute[];
} & IGenericEditProps;

const DataAttributesProps = (props: Props) => {
  const prevProps: Props = usePrevious<Props>(props);
  const { dataAttributes, targetElement, definition, selectedUnit, parentDefinition, inline } = props;
  const [dataAttributesValues, setDataAttributesValues] = React.useState<DataAttributesValues>({});
  const { setLayerPropertySettingElement } = useUpdateChildRhsAttributes(updateDataAttributes);

  React.useEffect(() => {
    if (!!targetElement || !isEqual(prevProps.dataAttributes, dataAttributes)) {
      updateDataAttributes();
    }
    return () => {};
  }, [targetElement, dataAttributes]);

  function updateDataAttributes() {
    let newValues: DataAttributesValues = {};
    for (let i = 0; i < dataAttributes.length; i++) {
      if (targetElement) {
        const attributeValue = targetElement.getAttribute(dataAttributes[i].dataV);
        if (!!attributeValue) {
          newValues[dataAttributes[i].id] = attributeValue;
        } else if (dataAttributes[i].inheritDisplayValueFromParent) {
          newValues[dataAttributes[i].id] = getParentDisplayValueIfPresentOrDefault(dataAttributes[i]);
        } else {
          newValues[dataAttributes[i].id] = defaultValue(dataAttributes[i]);
        }
      }
    }
    setDataAttributesValues(newValues);
  }

  function getParentDisplayValueIfPresentOrDefault(dataAttribute: IDataAttribute) {
    const parentValue = Dom.closestElement(
      targetElement,
      `[data-element-definition-id="${dataAttribute.inheritDisplayValueFromParent}"]`
    )?.getAttribute(dataAttribute.dataV);
    return parentValue ?? defaultValue(dataAttribute);
  }

  function handleAction(dataAttribute: IDataAttribute, newValue: string, isValid = true) {
    const oldValue = targetElement.getAttribute(dataAttribute.dataV) ?? undefined;
    let newDataAttributesValues = { ...dataAttributesValues, [dataAttribute.id]: newValue };
    newDataAttributesValues = clearDependableDataAttribute(dataAttributes, newDataAttributesValues, dataAttribute, (dataV: string) =>
      targetElement.removeAttribute(dataV)
    );
    setDataAttributesValues(newDataAttributesValues);

    if (isValid) {
      EditorStore.getEditor().getActiveEditorFacade()?.applyDataAttribute(targetElement, dataAttribute.dataV, newValue);
      EditorStore.getEditor().getActiveEditorFacade()?.applyStyleFromDataAttribute(dataAttribute, targetElement, newValue, definition);

      if (isLayerProperty(dataAttribute.id)) {
        handleLayerProperty(targetElement, dataAttribute, newValue, oldValue);
        setLayerPropertySettingElement(targetElement?.getAttribute('data-nid'));
      }

      if (isConditionalProperty(dataAttribute.id)) {
        const dataAttributeValuesToUpdate: DataAttributesValues | undefined = handleConditionalProperty(
          dataAttribute.id,
          newDataAttributesValues
        );

        if (!!dataAttributeValuesToUpdate) {
          setDataAttributesValues({ ...newDataAttributesValues, ...dataAttributeValuesToUpdate });
          for (let [key, value] of Object.entries(dataAttributeValuesToUpdate)) {
            const dataAttribute: IDataAttribute | undefined = dataAttributes.find((da) => da.id === key);
            if (!!dataAttribute) {
              EditorStore.getEditor()
                .getActiveEditorFacade()
                ?.applyDataAttribute(targetElement, dataAttribute.dataV, value ?? '');
            }
          }
        }
      }
    }
  }

  return (
    <div className="data-attributes-container">
      <div className={'selected-unit-props-section' + (inline ? ' selected-unit-props-section-inline' : '')}>
        {dataAttributes.map((dataAttribute, key) => {
          if (!isDataAttributeApplicable(dataAttribute, parentDefinition.id, getElmDef(selectedUnit?.definitionId))) {
            return null;
          }
          return (
            <RenderDataAttributes
              key={key}
              dataAttribute={dataAttribute}
              dataAttributes={dataAttributes}
              dataAttributesValues={dataAttributesValues}
              handleAction={handleAction}
              elmNid={props.unitElement.getAttribute('data-nid')}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DataAttributesProps;
