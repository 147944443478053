export default function alphanum(a: string, b: string): number {
  // leading zero
  const ore = /^0/;
  // whitespace
  const sre = /\s+/g;
  // leading and trailing whitespaces only
  const tre = /^\s+|\s+$/g;
  // numeric
  const nre = /(0x[\da-fA-F]+|(^[\+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|\d+)/g;

  const GREATER = 1;
  const SMALLER = -GREATER;
  const normalize = (s: string | number) => `${s}`.replace(tre, '');

  function tokenize(s: string): string[] {
    return s.replace(nre, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
  }

  function parse(s: string, l: number) {
    // normalize spaces; find floats not starting with '0',
    // string or 0 if not defined
    return ((!s.match(ore) || l === 1) && parseFloat(s)) || s.replace(sre, ' ').replace(tre, '') || 0;
  }

  // trim leading and trailing whitespaces
  const aa = normalize(a);
  const bb = normalize(b);

  // return immediately if at least one of the values is empty.
  // empty string < any others
  if (!aa && !bb) {
    return 0;
  }

  if (!aa && bb) {
    return SMALLER;
  }

  if (aa && !bb) {
    return GREATER;
  }

  // tokenize: split numeric strings and default strings
  const aArr = tokenize(aa);
  const bArr = tokenize(bb);

  const al = aArr.length;
  const bl = bArr.length;

  // handle numeric strings and default strings
  for (let i = 0, l = Math.max(al, bl); i < l; i += 1) {
    const af = parse(aArr[i] || '', al);
    const bf = parse(bArr[i] || '', bl);

    // handle numeric vs string comparison.
    // numeric < string
    if (isNaN(af as number) !== isNaN(bf as number)) {
      return isNaN(af as number) ? GREATER : SMALLER;
    }

    if (af < bf) {
      return SMALLER;
    }

    if (af > bf) {
      return GREATER;
    }

    if (`${af}` < `${bf}`) {
      return SMALLER;
    }

    if (`${af}` > `${bf}`) {
      return GREATER;
    }
  }

  return 0;
}
