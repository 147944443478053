export const SHORTCUTS = [
  {
    title: 'Navigation: Select previous:',
    keys: [
      {
        label: 'keyboard_arrow_up',
        type: 'material-icons'
      }
    ]
  },
  {
    title: 'Navigation: Select next:',
    keys: [
      {
        label: 'keyboard_arrow_down',
        type: 'material-icons'
      }
    ]
  },
  {
    title: 'Navigation: Select top element:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'keyboard_arrow_up',
        type: 'material-icons'
      }
    ]
  },
  {
    title: 'Navigation: Select bottom element:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'keyboard_arrow_down',
        type: 'material-icons'
      }
    ]
  },
  {
    title: 'Navigation: Edit selected:',
    keys: [
      {
        label: 'keyboard_return',
        type: 'material-icons'
      }
    ]
  },

  {
    title: 'Selection: Selected Multiple (with mouse):',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'click'
      }
    ]
  },
  {
    title: 'Selection: Selected Multiple (with mouse):',
    keys: [
      {
        label: 'Shift'
      },
      {
        label: 'click'
      }
    ]
  },
  {
    title: 'Selection: Selected Multiple:',
    keys: [
      {
        label: 'Shift'
      },
      {
        label: 'keyboard_arrow_up',
        type: 'material-icons'
      },
      {
        label: 'keyboard_arrow_down',
        type: 'material-icons'
      }
    ]
  },

  {
    title: 'Selection: Delete selected:',
    keys: [
      {
        label: 'Del'
      }
    ]
  },
  {
    title: 'Selection: Copy selected:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'c'
      }
    ]
  },
  {
    title: 'Selection: Cut selected:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'x'
      }
    ]
  },
  {
    title: 'Selection: Paste:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'v'
      }
    ]
  },
  {
    title: 'Selection: Undo:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'z'
      }
    ]
  },
  {
    title: 'Selection: Redo:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'Shift'
      },
      {
        label: 'z'
      }
    ]
  },
  {
    title: 'Selection: Merge:',
    keys: [
      {
        label: 'Shift'
      },
      {
        label: '\\'
      }
    ]
  },
  {
    title: 'Editing: Save:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 's'
      }
    ]
  },
  {
    title: 'Editing: Save and exit:',
    keys: [
      {
        label: 'Ctrl'
      },
      {
        label: 'keyboard_return',
        type: 'material-icons'
      }
    ]
  },
  {
    title: "Editing: Don't save and exit:",
    keys: [
      {
        label: 'ESC'
      }
    ]
  },
  {
    title: 'Editing: Add a newline:',
    keys: [
      {
        label: 'Shift'
      },
      {
        label: 'keyboard_return',
        type: 'material-icons'
      }
    ]
  },
  {
    title: 'Editing: Merge to previous:',
    keys: [
      {
        label: 'Shift'
      },
      {
        label: '\\'
      }
    ]
  },

  {
    title: 'Editing: Copy element:',
    keys: [
      {
        label: 'Alt'
      },
      {
        label: 'Ctrl'
      },
      {
        label: 'C'
      }
    ]
  },
  {
    title: 'Editing: Cut element:',
    keys: [
      {
        label: 'Alt'
      },
      {
        label: 'Ctrl'
      },
      {
        label: 'X'
      }
    ]
  },
  {
    title: 'Editing: Paste element action modifer (insert after):',
    keys: [
      {
        label: 'Ctrl'
      }
    ]
  },
  {
    title: 'Editing: Paste element action modifer (insert before):',
    keys: [
      {
        label: 'Shift'
      }
    ]
  }
];
