/* STORES */
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../../flux/editor/EditorStore';
/* UTILS */
import { intersection, union } from '../../../../../utils/set_utils';
import * as _ from 'lodash';
/* TYPES */
import { IElementDefinition, IInsertRules, InsertRulesMeta, IUnit, IUnitDetails } from 'mm-types';
import appStore from '../../../../../appStore';
import TocStore from '../../../../../flux/editor/TocStore';
import { tocHasNoNonPslChildren } from './qrhStructureInsertRules';
import { WithSelectedUnitProps, WithSelectedUnitState } from '../../../../hoc/withSelectedUnit';
import { canDisplayInContentMenu } from './utils';

export const InsertRulesFactory = ({
  isSelected,
  isActivelyEditing,
  editingDUProfile,
  selectedUnit,
  selectedUnitClosestTocable,
  dtdValidatedChildUnits
}: Partial<WithSelectedUnitProps & WithSelectedUnitState>): IInsertRules => {
  return {
    // requirement: cannot insert any content in between vols and sects
    selectedUnitAllowsFollowAtAll: () => {
      if (isNothing(selectedUnit) || isNothing(editingDUProfile)) {
        return true;
      }
      if (isTOCMode()) {
        return false;
      }

      const insertingUnitProfile = ProjectDefinitionStore.getProjectDefinedUnitById(selectedUnit?.definitionId);
      if (!insertingUnitProfile) {
        console.log('Project-defined insert rules could not find a definition for unit type:', selectedUnit?.definitionId);
        return false;
      }
      return true;
    },

    isTocableTypeInsertable: (unitId) => {
      if (isActivelyEditing) {
        // Can never insert a tocable type within another unit.
        return false;
      }

      if (isSelected && !isActivelyEditing && !!selectedUnit) {
        const withinStructure = selectedUnitClosestTocable
          ? ProjectDefinitionStore.canFollowTocable(unitId, selectedUnitClosestTocable.definitionId) &&
            qrhTocInsertPointValid(unitId, selectedUnitClosestTocable, selectedUnit)
          : false;
        const userCreatable = ProjectDefinitionStore.getProjectDefinedUnitById(unitId)!.userCreatable;

        switch (userCreatable) {
          case 'ALWAYS':
            return true;

          case 'WITHIN_STRUCTURE':
            return withinStructure;

          case 'WITHIN_STRUCTURE_ONLY_MULTI_VOLUME':
            return withinStructure && !appStore.getState().projectSettings.singleVolume.enabled;

          default:
            // incl. "NEVER"
            return false;
        }
      } else {
        return false;
      }
    },

    selectedUnitCanBeFollowedByThis: (unitDefId) => {
      if (isNothing(selectedUnit)) {
        return true;
      }
      const childUnitDefAllowedParents = ProjectDefinitionStore.getAllowedStructuralParentNames(unitDefId);
      if (dtdValidatedChildUnits) {
        return dtdValidatedChildUnits.indexOf(unitDefId) > -1;
      } else {
        return selectedUnit?.definitionId ? childUnitDefAllowedParents.indexOf(selectedUnit.definitionId) > -1 : false;
      }
    },

    currentlyInsertableElements: (editingNestedChange: INestedUnitFocusChangeEvent | null): InsertRulesMeta => {
      if (!editingNestedChange || !editingNestedChange.nestedTree || editingNestedChange.nestedTree.length === 0) {
        return {
          tree: [],
          unionRootWhitelists: new Set<string>(),
          intersectionElementWhitelists: new Set<string>(),
          rootBlacklist: new Set<string>(),
          aggregationElementBlacklists: new Set<string>()
        };
      }
      // Include parent when it exists and when it's different than first element on the nestedTree and nested tree has multiple elements
      const includeParent: boolean =
        editingNestedChange.parent &&
        editingNestedChange.nestedTree &&
        editingNestedChange.nestedTree.length > 1 &&
        editingNestedChange.nestedTree[0].targetElement !== editingNestedChange.parent.targetElement;

      const nestingTreeDesc = includeParent
        ? [editingNestedChange.parent, ...editingNestedChange.nestedTree]
        : [...editingNestedChange.nestedTree];
      const accTree: Array<{
        profile: IUnitDetails;
        elementDefinition: IElementDefinition;
        intersectionElementWhitelists: Set<string>;
        aggregationElementBlacklists: Set<string>;
      }> = [];
      const accumulator: InsertRulesMeta = {
        unionRootWhitelists: new Set<string>(),
        intersectionElementWhitelists: new Set<string>(),
        rootBlacklist: new Set<string>(),
        aggregationElementBlacklists: new Set<string>(),
        tree: accTree
      };
      return nestingTreeDesc.reduce(accumulateInsertRulesMeta, accumulator);
    }
  };
};

const isNothing = (thing) => thing === null || thing === undefined;

const isTOCMode = () => {
  return EditorStore.isMode('TOCMAN');
};

const accumulateInsertRulesMeta = (acc: InsertRulesMeta, treeUnitData: IUnitDetails) => {
  const elementDefinition = nestedTreeProfileToElementDefinition(treeUnitData);

  acc = accWhitelistsAreOpenIfEmpty(acc, elementDefinition);
  acc = accBlacklists(acc, elementDefinition);

  acc.tree.push({
    profile: treeUnitData,
    elementDefinition: elementDefinition!,
    intersectionElementWhitelists: _.cloneDeep(acc.intersectionElementWhitelists),
    aggregationElementBlacklists: _.cloneDeep(acc.aggregationElementBlacklists)
  });
  return acc;
};

const accWhitelistsAreOpenIfEmpty = (acc: InsertRulesMeta, ed: IElementDefinition) => {
  if (ed && ed.rootElementWhitelist && ed.elementWhitelist) {
    // If rootElementWhitelist is empty then unionRootWhitelists has all possible element types
    if (ed.rootElementWhitelist.length === 0) {
      const allElementTypes = ProjectDefinitionStore.getAllElementDefinitions((ed) => canDisplayInContentMenu(ed)).reduce(
        (acc, ed) => acc.add(ed.id),
        new Set<string>()
      );
      acc.unionRootWhitelists = allElementTypes;
    }

    // Otherwise accumulate the insertable elements it explicitly permits.
    if (ed.rootElementWhitelist.length) {
      acc.unionRootWhitelists = union(acc.unionRootWhitelists, new Set(ed.rootElementWhitelist));
    }

    if (ed.elementWhitelist.length) {
      const currentWhitelist = new Set(ed.elementWhitelist);
      acc.intersectionElementWhitelists = acc.intersectionElementWhitelists.size
        ? intersection(acc.intersectionElementWhitelists, currentWhitelist)
        : currentWhitelist;
    }
    // If elementWhitelist is empty, add all elements as allowed
    // but only when there is no intersectionElementWhitelists yet
    else if (acc.intersectionElementWhitelists.size === 0) {
      const allElementTypes = ProjectDefinitionStore.getAllElementDefinitions((ed) => ed.userCreatable).reduce(
        (acc, ed) => acc.add(ed.id),
        new Set<string>()
      );
      acc.intersectionElementWhitelists = allElementTypes;
    }
  }
  return acc;
};

const accBlacklists = (acc: InsertRulesMeta, ed: IElementDefinition) => {
  if (ed) {
    if (ed.rootElementBlacklist && ed.rootElementBlacklist.length) {
      acc.rootBlacklist = new Set(ed.rootElementBlacklist);
    }

    if (ed.elementBlacklist && ed.elementBlacklist.length) {
      const currentBlacklist = new Set(ed.elementBlacklist);
      acc.aggregationElementBlacklists = acc.aggregationElementBlacklists.size
        ? union(acc.aggregationElementBlacklists, currentBlacklist)
        : currentBlacklist;
    }
  }

  return acc;
};

const nestedTreeProfileToElementDefinition = (nestedTreeData: IUnitDetails): IElementDefinition => {
  const elementDefinitionByType = ProjectDefinitionStore.getElementDefinitionsByType(nestedTreeData.type, nestedTreeData.subtype).shift()!;
  const elementDefinitionById = nestedTreeData.profile
    ? ProjectDefinitionStore.getElementDefinitionById(nestedTreeData.definition?.id || nestedTreeData.type)
    : null;
  return elementDefinitionById || elementDefinitionByType;
};

const qrhTocInsertPointValid = (unitId: string, selectedUnitClosestTocable: IUnit, selectedUnit: IUnit): boolean => {
  if (ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) {
    const nextVisibleUnit = EditorStore.getNextVisibleUnitFromIndex(selectedUnit.index);
    const currentToc = TocStore.getTocItem(selectedUnitClosestTocable.uid);

    // if insert unit is psl/group/invariant and next visible unit isn't tocable, don't allow insert
    if (['psl', 'group', 'invariant'].indexOf(unitId) !== -1 && nextVisibleUnit && !nextVisibleUnit.unit.istocable) {
      return false;
    }
    if (
      ['group', 'invariant'].indexOf(unitId) !== -1 &&
      currentToc.definitionId === 'psl' &&
      tocHasNoNonPslChildren(currentToc.children) &&
      currentToc.children.length !== 0
    ) {
      return false;
    }
    // If next visible unit is tocable but can't follow unit to insert don't allow,
    if (nextVisibleUnit && nextVisibleUnit.unit.istocable) {
      return ProjectDefinitionStore.canFollowTocable(nextVisibleUnit.unit.definitionId, unitId);
    } else if ((nextVisibleUnit && !nextVisibleUnit.unit.istocable) || !nextVisibleUnit) {
      // If inserting after general content or after last visible unit in toc, check that next toc def id can follow unit to insert
      const nextToc = TocStore.findNextTocItem(currentToc);
      if (nextToc) {
        return ProjectDefinitionStore.canFollowTocable(nextToc.definitionId, unitId);
      }
    }
    return true;
  } else {
    return true;
  }
};

export const elementAllowedByWhitelist = (elmId: string, elmDef?: IElementDefinition): boolean => {
  return (
    (elmDef?.elementWhitelist?.length === 0 && elmDef?.rootElementWhitelist?.length === 0) ||
    union(new Set(elmDef?.rootElementWhitelist), new Set(elmDef?.elementWhitelist)).has(elmId) ||
    (!!elmDef?.rootElementBlacklist &&
      !!elmDef?.elementBlacklist &&
      !union(new Set(elmDef?.rootElementBlacklist), new Set(elmDef?.elementBlacklist)).has(elmId))
  );
};
