import * as React from 'react';
import * as _ from 'lodash';
import isEqual from 'react-fast-compare';
import DocumentEmitter from '../document/components/DocumentEmitter';
import ElementRevBar from './ElementRevBar';
import * as client from '../../../clients/units';
import ProjectStore from '../../../flux/editor/ProjectStore';
import EditorStore from '../../../flux/editor/EditorStore';
import UnitHighlightStore from '../../../flux/editor/UnitHighlightStore';
import { IElementHighlight, IUnit, IUnitHighlight } from 'mm-types';
import { EditorRouteParams } from '../../ReactRoutes';
import { RouteComponentProps, withRouter } from 'react-router';

export interface Props extends RouteComponentProps<EditorRouteParams> {
  highlight: IUnitHighlight;
  unit: IUnit;
}

export type State = {
  height?: number;
  hasPosition?: boolean;
};

class RevBar extends React.Component<Props, State> {
  private _rerenderProxy;

  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
      hasPosition: true
    };

    this._rerenderProxy = this._rerender.bind(this);
  }

  componentDidMount() {
    this._initPosition();
    DocumentEmitter.on('resize', this._rerenderProxy);
  }

  componentWillUnmount() {
    DocumentEmitter.removeListener('resize', this._rerenderProxy);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // prevent update for unncessary parent changes, e.g. simple unit selection would cause re-render otherwise
    return !isEqual(nextState, this.state) || !isEqual(nextProps.highlight, this.props.highlight);
  }

  _rerender() {
    this._initPosition();
    this.forceUpdate();
  }

  _initPosition() {
    const $offsetNode = this._getOffsetNode();
    let offsetNode: HTMLElement | undefined;
    if (this._hasPageBreak($offsetNode)) {
      offsetNode = $offsetNode?.parent()[0];
    } else {
      offsetNode = $offsetNode[0];
    }

    const stateUpdate: Partial<State> = {};
    if (offsetNode) {
      stateUpdate.height = offsetNode.clientHeight;
      if (!this.state.hasPosition) {
        stateUpdate.hasPosition = true;
      }
    } else {
      if (this.state.hasPosition) {
        stateUpdate.hasPosition = false;
      }
    }

    this.setState(stateUpdate);
  }

  _hasPageBreak($offsetNode: JQuery<HTMLElement>) {
    return $offsetNode.siblings('.page-break-settings.before')[0];
  }

  _getOffsetNode() {
    return $(`#_${this.props.unit.uid} div[data-nid="${this.props.unit.uid}"]`).parent();
  }

  _getOverlappingRevNids(currentNid: string, currentRevBarPosition: { top: number; height: number }) {
    const overlappingNids: string[] = [];

    _.keys(this.refs).forEach((key) => {
      const currentBottom = currentRevBarPosition.top + currentRevBarPosition.height;

      if (key.indexOf('elementRevBar_') !== -1 && key !== 'elementRevBar_' + currentNid) {
        const siblingElementRevBarState = (this.refs[key] as ElementRevBar).state;

        if (siblingElementRevBarState.hasPosition) {
          const siblingBottom = siblingElementRevBarState.top + siblingElementRevBarState.height;
          const siblingNid: string = key.split('_')[1];

          // encompases current revbar
          if (siblingElementRevBarState.top <= currentRevBarPosition.top && siblingBottom >= currentBottom) {
            overlappingNids.push(siblingNid);
          }
          // current revbar encompases sibling
          else if (currentRevBarPosition.top <= siblingElementRevBarState.top && currentBottom >= siblingBottom) {
            overlappingNids.push(siblingNid);
          }
          // current revbar top or bottom intersects with sibling
          else if (
            (currentBottom >= siblingElementRevBarState.top && currentBottom <= siblingBottom) ||
            (currentRevBarPosition.top >= siblingElementRevBarState.top && currentRevBarPosition.top <= siblingBottom)
          ) {
            overlappingNids.push(siblingNid);
          }
        }
      }
    });

    return overlappingNids;
  }

  // TODO move all this out to UnitHighlightStore
  async _toggleElement(toggledNid: string, position: { top: number; height: number }) {
    const projectUid: string = this.props.match.params.projectUid;
    const indexUid: string = this.props.match.params.documentIndexUid;
    const unitUid: string = this.props.unit.uid;

    if (!EditorStore.isReadOnly() && !EditorStore.isBusy()) {
      EditorStore.setBusy(true, 'Changing Rev Bar');

      try {
        const highlight = await client.getElementHighlights(projectUid, indexUid, unitUid, toggledNid);
        const masterToggleValue = !highlight.ignored;
        await client.saveElementHighlights(projectUid, indexUid, unitUid, toggledNid, {
          ...highlight,
          ignored: masterToggleValue
        });
        await this._toggleOverlappingRevBars(this._getOverlappingRevNids(toggledNid, position), masterToggleValue);
      } catch (err) {
        EditorStore.setBusy(false);
      }
    }
  }

  async _toggleOverlappingRevBars(overlappingNids: string[], forceToggleValue: boolean) {
    const projectUid: string = this.props.match.params.projectUid;
    const indexUid: string = this.props.match.params.documentIndexUid;
    const unitUid: string = this.props.unit.uid;

    // if revbars overlap, do a deep toggle
    const promises: Promise<IElementHighlight>[] = [];

    overlappingNids.forEach((nid) => {
      promises.push(
        client.saveElementHighlights(projectUid, indexUid, unitUid, nid, {
          nid: nid,
          unitUid: unitUid,
          ignored: forceToggleValue
        })
      );
    });

    if (overlappingNids.length) {
      await Promise.all(promises);
      await UnitHighlightStore.retrieveTocHighlight();
      EditorStore.setBusy(false);
    } else {
      await UnitHighlightStore.retrieveTocHighlight();
      EditorStore.setBusy(false);
    }
  }

  _toggleUnit() {
    UnitHighlightStore.toggleTocHighlight(this.props.unit.uid);
  }

  _addUnitRevbar() {
    const canAuthor = ProjectStore.getProject()
      ? ProjectStore.getProject()?.currentUserPermissions?.canAuthor ||
        ProjectStore.getProject()?.currentUserPermissions.canManageComplianceTags
      : false;

    return (
      <span
        className={'revbar-unit' + (this.props.highlight.ignored ? ' ignored' : '')}
        style={canAuthor ? undefined : { cursor: 'default !important;' }}
        onClick={(e) => this._toggleUnit()}
      />
    );
  }

  _renderRevBars() {
    if (this.props.highlight.elementHighlights) {
      const removedPre182: IElementHighlight[] = this.props.highlight.elementHighlights.filter((elementHighlight) => {
        return elementHighlight?.type == 'REMOVED';
      });
      // if at least one REMOVED elem highlight exists, then draw revbar on the whole unit.
      if (removedPre182.length) {
        return this._addUnitRevbar();
      }

      const removed: IElementHighlight[] = this.props.highlight.elementHighlights.filter((highlight) => highlight.type === 'REMOVED_V1');
      const addedChanged: IElementHighlight[] = this.props.highlight.elementHighlights.filter((elemHigh) => {
        return elemHigh.type !== 'REMOVED_V1' && elemHigh.type !== 'REMOVED';
      });

      // if only removed, ignore, if mix of removed and changed/added, only passes changed/added
      const revbars = removed.length && !addedChanged.length ? null : addedChanged;

      return revbars
        ? revbars.map((eleHL) => {
            return (
              <ElementRevBar
                elementHighlight={Object.assign(eleHL, { unitUid: this.props.unit.uid })}
                toggleElement={(id, position) => this._toggleElement(id, position)}
                key={eleHL.nid}
                ref={'elementRevBar_' + eleHL.nid}
              />
            );
          })
        : null;
    } else {
      if (this.props.highlight?.type !== 'REMOVED_V1' && this.props.highlight?.type !== 'REMOVED') {
        return this._addUnitRevbar();
      } else {
        return null;
      }
    }
  }

  render() {
    if (!this.state.hasPosition) {
      return <span />;
    }
    const style = {
      height: this.state.height + 'px'
    };

    return (
      <span
        className="revcontainer"
        style={style}
        // title={this.props.highlight.comment} // SB INVESTIGATE usage
      >
        {this._renderRevBars()}
      </span>
    );
  }
}

export default withRouter(RevBar);
