import * as React from 'react';
import isEqual from 'react-fast-compare';
export function useObjectValue<T>(
  values: T,
  onChangeFn: (data: T) => void
): { localValues: T; updateValues: (values?: Partial<T>, emitValues?: boolean) => void } {
  const [localValues, setLocalValues] = React.useState<T>(values);

  React.useEffect(() => {
    setLocalValues(values);
  }, [values]);

  function updateValues(values: Partial<T> = {}, emitValues = true) {
    const val = {
      ...localValues,
      ...values
    };
    if (!isEqual(val, localValues)) {
      setLocalValues(val);
      if (emitValues) {
        onChangeFn(val);
      }
    }
  }

  return {
    localValues,
    updateValues
  };
}
