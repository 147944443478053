// import * as Reflux from 'reflux';
import * as React from 'react';

const storeUpdatePassesThroughToState = (data) => data;

const withStore = (...subscriptions) => (WrappedComponent) => {
  return class WithStore extends React.Component<any, any> {
    private unsubscribes: Function[] = [];

    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
      this.unsubscribes = subscriptions.map((subscription) => {
        const listenable = subscription.listenable || subscription;
        let listener = subscription.listener || storeUpdatePassesThroughToState;
        listener = listener.bind(this);
        return listenable.listen(listener.bind(this));
      });
    }

    componentWillUnmount() {
      this.unsubscribes.forEach((unsub) => unsub && unsub());
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
};

export const connect = (listenable, listener) => ({ listenable, listener });

export default withStore;
