import * as React from 'react';
import { useContext } from 'react';
import FontIcon from 'material-ui/FontIcon';
import { ThemeContext } from '../misc/ThemeProvider';
import SvgIcon from 'material-ui/SvgIcon';

interface Props extends MMIcon {
  iconName: string;
  iconNameOnlyInClassname?: boolean;
  style?: React.CSSProperties;
}

export interface IconOutline {
  outline?: boolean;
}

export interface MMIcon {
  gray?: boolean;
  className?: string;
}

export type MaterialIconProps = Props & IconOutline & React.HTMLAttributes<HTMLSpanElement>;
export type IconProps = IconOutline & MMIcon & React.HTMLAttributes<HTMLSpanElement>;

export const MaterialIcon = React.forwardRef((props: MaterialIconProps, ref: any) => {
  const { iconName, iconNameOnlyInClassname, gray, outline, style, ...restProps } = props;
  const theme = useContext(ThemeContext);

  return (
    <FontIcon
      style={{ color: props.gray ? theme.custom.button.gray.textColor : undefined, ...style }}
      {...restProps}
      className={`aero-icon material-icons ${restProps.className || ''} ${iconNameOnlyInClassname ? iconName : ''}`}
      ref={ref}
    >
      {iconNameOnlyInClassname ? '' : iconName + (outline ? '_outline' : '')}
    </FontIcon>
  );
});

export function MaterialIconEdit(props: IconProps) {
  return <MaterialIcon {...props} iconName="edit" />;
}

export function MaterialIconDelete(props: IconProps) {
  return <MaterialIcon {...props} iconName="delete" />;
}

export function MaterialIconDownload(props: IconProps) {
  return <MaterialIcon {...props} iconName="file_download" />;
}

export function MaterialIconRemove(props: IconProps) {
  return <MaterialIcon {...props} iconName="remove_circle" />;
}
export function MaterialIconAdd(props: IconProps) {
  return <MaterialIcon {...props} iconName="add_circle" />;
}
export function MaterialIconClose(props: IconProps) {
  return <MaterialIcon {...props} iconName="close" />;
}

export function MaterialIconPreview(props: IconProps) {
  const { className, ...rest } = props;
  return (
    <MaterialIcon
      className={`icon icon-preview-in-viewer ${className || ''}`}
      {...rest}
      iconName="icon-preview-in-viewer"
      iconNameOnlyInClassname={true}
    />
  );
}

export function MaterialIconConfirm(props: IconProps) {
  return <MaterialIcon {...props} iconName="done" />;
}

export function MaterialIconDropdown(props: IconProps) {
  return <MaterialIcon {...props} iconName="arrow_drop_down" />;
}

export const MaterialIconCheck = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
    </SvgIcon>
  );
});

export const MaterialIconCloseOutlined = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
    </SvgIcon>
  );
});

export const MaterialIconListCheckbox = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
    </SvgIcon>
  );
});

export const MaterialIconHTML = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="m4.1865,0.18204c-0.22559,0.03725 -0.45117,0.17273 -0.58512,0.34545c-0.23264,0.30142 -0.21501,-0.62316 -0.20796,11.60983c0.01057,10.2619 0.0141,11.13568 0.0705,11.24745c0.09164,0.19643 0.31018,0.40641 0.51462,0.49447l0.18681,0.0779l7.97311,0c7.86384,0 7.97663,-0.00339 8.1282,-0.06774c0.24674,-0.10838 0.41593,-0.26078 0.52167,-0.46737l0.09869,-0.19305l0,-8.73785c0,-7.70489 -0.00705,-8.74801 -0.05287,-8.82591c-0.0282,-0.0508 -1.12089,-1.29374 -2.42859,-2.76021c-1.73773,-1.95755 -2.40392,-2.68232 -2.48499,-2.7128c-0.12689,-0.04403 -11.44856,-0.05419 -11.73407,-0.01016l0,-0.00001zm11.11371,3.35628c0,2.69248 -0.00705,2.59088 0.22206,2.72296c0.10927,0.06435 0.19386,0.06774 2.2453,0.06774l2.13251,0.00339l0.01057,8.33822l0.00705,8.33822l-7.78982,0l-7.78982,0l0,-10.95619l0,-10.95619l5.48107,0l5.48107,0l0,2.44186l0.00001,-0.00001z" />
        <path d="m6.16838,17.77629l0,1.81192l0.29961,0l0.29961,0l0,-0.77896l0,-0.77896l0.65209,0l0.65209,0l0,0.77896l0,0.77896l0.31723,0l0.31723,0l0,-1.81192l0,-1.81192l-0.31723,0l-0.31723,0l0,0.72815l0,0.72815l-0.64151,-0.00677l-0.64504,-0.01016l-0.01057,-0.72138l-0.00705,-0.71799l-0.29961,0l-0.29961,0l0,1.81192l-0.00001,0z" />
        <path d="m9.29375,16.25224l0,0.28788l0.49347,0l0.49347,0l0,1.52404l0,1.52404l0.29961,0l0.29961,0l0,-1.52404l0,-1.52404l0.47585,0l0.47585,0l0,-0.28788l0,-0.28788l-1.26893,0l-1.26893,0l0,0.28788z" />
        <path d="m12.50841,17.77629l0,1.81192l0.28551,0l0.28551,0l-0.0141,-1.4597c-0.00705,-1.12779 0,-1.42921 0.0282,-1.31406c0.02115,0.08128 0.16919,0.66042 0.32781,1.28358c0.15862,0.62316 0.31371,1.21585 0.34191,1.31406l0.0564,0.1795l0.21149,-0.01016l0.21501,-0.01016l0.25731,-0.96523c0.14099,-0.53172 0.30666,-1.17859 0.36658,-1.43938c0.05992,-0.26078 0.11279,-0.47753 0.11632,-0.48092c0.00352,-0.00339 0,0.65026 -0.00705,1.44954l-0.01057,1.45292l0.28551,0l0.28551,0l0,-1.81192l0,-1.81192l-0.41593,0l-0.41593,0l-0.28903,1.14134c-0.15862,0.62994 -0.31371,1.23617 -0.33838,1.34793c-0.05287,0.22353 -0.04582,0.23369 -0.14452,-0.20321c-0.0282,-0.13208 -0.17272,-0.69767 -0.32428,-1.26326l-0.26789,-1.0228l-0.41945,0l-0.41593,0l0,1.81192l-0.00001,0.00001z" />
        <path d="m16.40923,17.77629l0,1.81192l1.09269,0l1.09269,0l0,-0.28788l0,-0.28788l-0.79308,0l-0.79308,0l0,-1.52404l0,-1.52404l-0.29961,0l-0.29961,0l0,1.81192z" />
      </g>
    </SvgIcon>
  );
});

export const MaterialIconRTF = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="m4.52486,0.17434c-0.21047,0.096 -0.35206,0.25109 -0.47069,0.50217c-0.07271,0.15508 -0.07654,0.36924 -0.07654,11.33209c0,12.37705 -0.02296,11.35055 0.24874,11.65333c0.07271,0.08493 0.2143,0.18462 0.32145,0.22893l0.19134,0.07754l7.87934,-0.00738l7.88317,-0.01108l0.2143,-0.14401c0.16073,-0.10708 0.23726,-0.18831 0.31763,-0.35078l0.10715,-0.20678l0.00765,-8.67723c0.00765,-6.8347 0,-8.71415 -0.03827,-8.85077c-0.03827,-0.1477 -0.37886,-0.55756 -2.38026,-2.86902c-1.64169,-1.89422 -2.36877,-2.70656 -2.44531,-2.7324c-0.07654,-0.02954 -1.84451,-0.04062 -5.83584,-0.04062l-5.72487,0.00369l-0.199,0.09231l0,0.00001zm11.12445,3.26412c0,2.68809 -0.00382,2.62532 0.23726,2.76933c0.12246,0.07016 0.18751,0.07385 2.22336,0.07385l2.09325,0l0,8.36337l0,8.36337l-7.65356,0l-7.65356,0l0,-11.00347l0,-11.00347l5.37663,0l5.37663,0l0,2.43701l-0.00001,0.00001z" />
        <path d="m8.24428,17.7245l0,1.84991l0.34249,0l0.34249,0l0,-0.70156l0,-0.70526l0.38359,0.01108l0.38359,0.01108l0.36304,0.69049l0.35962,0.69418l0.37332,0l0.36989,0l-0.04795,-0.08493c-0.1096,-0.18462 -0.78088,-1.42159 -0.78088,-1.44005c0,-0.01108 0.0685,-0.05539 0.15412,-0.0997c0.19522,-0.10339 0.37674,-0.32863 0.44866,-0.56125c0.06507,-0.21785 0.07192,-0.5871 0.01027,-0.80495c-0.06507,-0.23262 -0.30139,-0.49479 -0.53771,-0.59817c-0.18495,-0.07754 -0.25002,-0.08493 -1.17817,-0.096l-0.98638,-0.01108l0,1.84622l0.00001,-0.00001zm1.87343,-1.16681c0.29797,0.17354 0.28769,0.72372 -0.0137,0.93788c-0.07535,0.05539 -0.18152,0.07016 -0.63361,0.07754l-0.54114,0.01477l0,-0.56125l0,-0.56125l0.54114,0.01477c0.43154,0.01108 0.55826,0.02585 0.64731,0.07754z" />
        <path d="m11.29246,16.17737l0,0.2954l0.51374,0l0.51374,0l0.00685,1.53975l0.01027,1.54344l0.31852,0.01108l0.31509,0.01108l0,-1.55452l0,-1.55082l0.51374,0l0.51374,0l0,-0.2954l0,-0.2954l-1.35284,0l-1.35284,0l0,0.2954l-0.00001,-0.00001z" />
        <path d="m14.34748,17.71712l0.01027,1.83883l0.32537,0l0.32537,0l0.01027,-0.67572l0.00685,-0.67202l0.80486,0l0.80486,0l0,-0.2954l0,-0.2954l-0.80486,0l-0.80486,0l0,-0.57233l0,-0.57233l0.82198,0l0.82198,0l0,-0.2954l0,-0.2954l-1.16447,0l-1.16447,0l0.00685,1.83514l0,0.00003z" />
      </g>
    </SvgIcon>
  );
});

export const MaterialIconXHTML = React.forwardRef((props: IconProps, ref: any) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="m4.1865,0.18204c-0.22559,0.03725 -0.45117,0.17273 -0.58512,0.34545c-0.23264,0.30142 -0.21501,-0.62316 -0.20796,11.60983c0.01057,10.2619 0.0141,11.13568 0.0705,11.24745c0.09164,0.19643 0.31018,0.40641 0.51462,0.49447l0.18681,0.0779l7.97311,0c7.86384,0 7.97663,-0.00339 8.1282,-0.06774c0.24674,-0.10838 0.41593,-0.26078 0.52167,-0.46737l0.09869,-0.19305l0,-8.73785c0,-7.70489 -0.00705,-8.74801 -0.05287,-8.82591c-0.0282,-0.0508 -1.12089,-1.29374 -2.42859,-2.76021c-1.73773,-1.95755 -2.40392,-2.68232 -2.48499,-2.7128c-0.12689,-0.04403 -11.44856,-0.05419 -11.73407,-0.01016zm11.11371,3.35628c0,2.69248 -0.00705,2.59088 0.22206,2.72296c0.10927,0.06435 0.19386,0.06774 2.2453,0.06774l2.13251,0.00339l0.01057,8.33822l0.00705,8.33822l-7.78982,0l-7.78982,0l0,-10.95619l0,-10.95619l5.48107,0l5.48107,0l0,2.44186z" />
        <path d="m4.86678,15.97792c0,0.00677 0.20796,0.38948 0.46175,0.85346l0.46175,0.8433l-0.21149,0.38948c-0.11632,0.21337 -0.34896,0.64349 -0.51462,0.95507l-0.30666,0.56898l0.33133,0l0.33133,0l0.35601,-0.71122c0.19739,-0.39286 0.36658,-0.71122 0.37715,-0.71122c0.01057,0 0.18329,0.31836 0.38773,0.71122l0.3701,0.71122l0.31723,0c0.17624,0 0.31723,-0.00677 0.31723,-0.01693c0,-0.01016 -0.21854,-0.44028 -0.48995,-0.95845l-0.48995,-0.93813l0.43708,-0.82976c0.23616,-0.45721 0.43355,-0.83992 0.43355,-0.85685c0.00352,-0.01355 -0.13394,-0.02371 -0.30666,-0.02371l-0.30666,0l-0.33133,0.63333l-0.32781,0.63671l-0.33133,-0.63671l-0.33133,-0.63333l-0.31723,0c-0.17624,0 -0.31723,0.00677 -0.31723,0.01355z" />
        <path d="m7.89811,17.77629l0,1.81192l0.29961,0l0.29961,0l0,-0.77896l0,-0.77896l0.65209,0l0.65209,0l0,0.77896l0,0.77896l0.31723,0l0.31723,0l0,-1.81192l0,-1.81192l-0.31723,0l-0.31723,0l0,0.72815l0,0.72815l-0.64151,-0.00677l-0.64504,-0.01016l-0.01057,-0.72138l-0.00705,-0.71799l-0.29961,0l-0.29961,0l0,1.81192z" />
        <path d="m10.75321,16.25224l0,0.28788l0.49347,0l0.49347,0l0,1.52404l0,1.52404l0.29961,0l0.29961,0l0,-1.52404l0,-1.52404l0.47585,0l0.47585,0l0,-0.28788l0,-0.28788l-1.26893,0l-1.26893,0l0,0.28788z" />
        <path d="m13.64354,17.77629l0,1.81192l0.28551,0l0.28551,0l-0.0141,-1.4597c-0.00705,-1.12779 0,-1.42921 0.0282,-1.31406c0.02115,0.08128 0.16919,0.66042 0.32781,1.28358c0.15862,0.62316 0.31371,1.21585 0.34191,1.31406l0.0564,0.1795l0.21149,-0.01016l0.21501,-0.01016l0.25731,-0.96523c0.14099,-0.53172 0.30666,-1.17859 0.36658,-1.43938c0.05992,-0.26078 0.11279,-0.47753 0.11632,-0.48092c0.00352,-0.00339 0,0.65026 -0.00705,1.44954l-0.01057,1.45292l0.28551,0l0.28551,0l0,-1.81192l0,-1.81192l-0.41593,0l-0.41593,0l-0.28903,1.14134c-0.15862,0.62994 -0.31371,1.23617 -0.33838,1.34793c-0.05287,0.22353 -0.04582,0.23369 -0.14452,-0.20321c-0.0282,-0.13208 -0.17272,-0.69767 -0.32428,-1.26326l-0.26789,-1.0228l-0.41945,0l-0.41593,0l0,1.81192z" />
        <path d="m17.27409,17.77629l0,1.81192l1.09269,0l1.09269,0l0,-0.28788l0,-0.28788l-0.79308,0l-0.79308,0l0,-1.52404l0,-1.52404l-0.29961,0l-0.29961,0l0,1.81192z" />
      </g>
    </SvgIcon>
  );
});
