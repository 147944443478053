import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const DiscardedDraft = ({ entry }: PropsType) => {
  return (
    <div className="settings-details">
      {entry.indexRevisionNumber && !entry.isChild && (
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      )}
      {entry.projectSettings.description && (
        <div key="comment">
          <label>Comment:</label>
          <span>{entry.comment}</span>
        </div>
      )}
    </div>
  );
};
