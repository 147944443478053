import * as React from 'react';
import { IPrintOutputSettingsData, IPageBreakSettingsData } from 'mm-types';

type Props = {
  printOutputSettings: (IPrintOutputSettingsData & IPageBreakSettingsData) | null;
};

export const PrintOutputMenu = ({ printOutputSettings }: Props) => {
  if (!printOutputSettings) {
    return null;
  }
  const { pageType, pageSize, colCount } = printOutputSettings;
  const hasPageType = pageType !== 'unspecified';
  const hasPageSize = pageSize !== 'unspecified';
  const hasColCount = !!colCount;

  if (!hasPageType && !hasPageSize && !hasColCount) {
    return null;
  }

  const printOutputText = `${hasPageType ? pageType : ''} ${hasPageSize ? pageSize : ''}`.trim();
  return (
    <div className="du-inline-item print-output">
      {printOutputText + ' '}
      {hasColCount && (
        <div className="col-count">
          <i className="material-icons icon-print-2-col" />
          {colCount}
        </div>
      )}
    </div>
  );
};
