import * as React from 'react';
import IconButton from 'material-ui/IconButton';

export type Props = {
  title: string;
  align: 'JustifyLeft' | 'JustifyCenter' | 'JustifyRight' | 'JustifyFull';
  onChange: (action: { action: ActionType }) => void;
};

export type ActionType = {
  action: 'JustifyLeft' | 'JustifyCenter' | 'JustifyRight' | 'JustifyFull';
  tooltip: string;
  icon: string;
};

const ACTIONS: ActionType[] = [
  { action: 'JustifyLeft', tooltip: 'Left align', icon: 'icon-align-table-left' },
  { action: 'JustifyCenter', tooltip: 'Center align', icon: 'icon-align-table-centre' },
  { action: 'JustifyRight', tooltip: 'Right align', icon: 'icon-align-table-right' }
];

const Alignment = (props: Props) => {
  const handleClick = (action) => {
    props.onChange({ action: action });
  };

  return (
    <div className="alignment">
      <div className="manip-group">
        <h6>{props.title}</h6>
        <span className="controls">
          <ul className="icon-action-buttons">
            {ACTIONS.map((item) => {
              return (
                <li
                  key={item.action}
                  className={props.align === item.action ? 'selected' : ''}
                  onClick={() => {
                    handleClick(item.action);
                  }}
                >
                  <IconButton
                    iconClassName={'material-icons ' + item.icon}
                    tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                    tooltipPosition="bottom-left"
                    tooltip={item.tooltip}
                    data-qa={item.icon}
                  />
                </li>
              );
            })}
          </ul>
        </span>
      </div>
    </div>
  );
};

export default Alignment;
