import { Toggle } from 'material-ui';
import React from 'react';

type Props = {
  visible: boolean;
  html: string;
  diffHtml?: string;
  setHtml: (html: string) => void;
};

export const DiffToggle = (props: Props) => {
  const [isActivityDiffOn, setIsActivityDiffOn] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsActivityDiffOn(getIsActivityDiffOn());
  }, []);

  React.useLayoutEffect(() => {
    const updatedHtml = isActivityDiffOn && props.diffHtml ? getDiffedHTML(props.html, props.diffHtml) : props.html;
    props.setHtml(updatedHtml);
  }, [isActivityDiffOn, props.diffHtml]);

  const getIsActivityDiffOn = (): boolean => {
    const activityDiffSettings = localStorage.getItem('activity-diff-settings');
    if (activityDiffSettings) {
      return JSON.parse(activityDiffSettings);
    }
    return false;
  };

  const handleDiffToggle = () => {
    const isOn = !getIsActivityDiffOn();
    localStorage.setItem('activity-diff-settings', isOn.toString());
    setIsActivityDiffOn(isOn);
  };

  const getDiffedHTML = (html: string, diffHtml: string): string => {
    const $diff = $(diffHtml);
    const $f = $diff.find('.arc-diff-content');
    $f.each((index, el) => {
      const $el = $(el);
      const background = $el.css('background-color');
      const className = $el.attr('class') || '';
      const $children = $el.children();
      if ($children.length) {
        $children.css('background-color', background).addClass(className);
        $el.replaceWith($children);
      } else {
        $el.css('display', '');
      }
    });
    $diff.find('br').next('br').prev('br').remove();
    return $(html).html($diff.html())[0].outerHTML;
  };

  if (!props.visible) {
    return null;
  }

  return (
    <div className="diff-toggle" onClick={(e) => handleDiffToggle()}>
      <Toggle label="Show Diff" toggled={isActivityDiffOn} />
    </div>
  );
};
