import { ISnackbarMessage } from 'mm-types';
import { HideSystemSnackbarAction, ShowSystemSnackbarAction, SHOW_SYSTEM_SNACKBAR, HIDE_SYSTEM_SNACKBAR } from './types';

export function showSystemSnackbar(payload: ISnackbarMessage): ShowSystemSnackbarAction {
  return {
    type: SHOW_SYSTEM_SNACKBAR,
    payload
  };
}

export function hideSystemSnackbar(): HideSystemSnackbarAction {
  return {
    type: HIDE_SYSTEM_SNACKBAR,
    payload: null
  };
}
