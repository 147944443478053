import * as React from 'react';
import { AttachmentType, AttachmentVisibility, IEditorStoreEvent, IFileAttachment } from 'mm-types';
import { CircularProgress, FlatButton, IconButton, Menu, MenuItem, Popover, RaisedButton } from 'material-ui';
import LockIcon from 'material-ui/svg-icons/action/lock';
import LockOpenIcon from 'material-ui/svg-icons/action/lock-open';
import ArrowDownward from 'material-ui/svg-icons/navigation/arrow-downward';
import ArrowUpward from 'material-ui/svg-icons/navigation/arrow-upward';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import FontDownload from 'material-ui/svg-icons/file/file-download';
import FontEdit from 'material-ui/svg-icons/image/edit';
import FontDelete from 'material-ui/svg-icons/action/delete';
import FontClose from 'material-ui/svg-icons/navigation/close';
import FontTick from 'material-ui/svg-icons/toggle/check-box';
import FileAttachmentStore, { Event as FileAttachmentStoreEvent } from '../../../../../flux/editor/FileAttachmentStore';
import AttachmentPicker from './AttachmentPicker';
import SearchBar from '../searchbar/Searchbar';
import * as _ from 'lodash';
import FileIcon from '../../../../misc/icons/FileIcon';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { dateUtil } from '../../../../../utils';
import useListenToStore from '../../../../hooks/useListenToStore';

export type Props = {
  /** The uid of an attachment you want to scroll to when the attachments load */
  attachmentJumpId?: string;
};

export type State = {
  openPicker: boolean;
  expandedAttachments: Partial<IFileAttachment>[];
  projectAttachments: Partial<IFileAttachment>[];
  selectedAttachment: Partial<IFileAttachment> | null;
  attachmentToDelete: Partial<IFileAttachment> | null;
  jumpStyleUid: string | null;
  loading: boolean;
  anchorEl?: Element;
  filterOpen: boolean;
  filterType: FilterType;
  filterDir: 'asc' | 'desc';
  currentDescription: string;
  descriptionCount: number;
  currentVisibility: AttachmentVisibility;
  showDelete: boolean;
  searchMode: boolean;
  searchTerm: string;
  attachmentJump?: string | null;
};

export type FilterType = 'filename' | 'fileType' | 'date' | 'visibility';

const FilterOptions: { val: FilterType; label: string }[] = [
  { val: 'filename', label: 'File Name' },
  { val: 'fileType', label: 'File Type' },
  { val: 'date', label: 'Date' },
  { val: 'visibility', label: 'Visibility' }
];

type AttachmentsActions =
  | { type: 'setPickerOpen'; payload: boolean }
  | { type: 'setExpandedAttachments'; payload: Partial<IFileAttachment>[] }
  | { type: 'setProjectAttachments'; payload: Partial<IFileAttachment>[] }
  | { type: 'setSelectedAttachment'; payload: Partial<IFileAttachment> | null }
  | { type: 'setAttachmentToDelete'; payload: Partial<IFileAttachment> | null }
  | { type: 'setJumpStyleUid'; payload: string | null }
  | { type: 'setLoading'; payload: boolean }
  | { type: 'setAnchorEl'; payload?: Element }
  | { type: 'setFilterOpen'; payload: boolean }
  | { type: 'setFilterType'; payload: FilterType }
  | { type: 'setFilterDir'; payload: 'asc' | 'desc' }
  | { type: 'setCurrentDescription'; payload: string }
  | { type: 'setDescriptionCount'; payload: number }
  | { type: 'setCurrentVisibility'; payload: AttachmentVisibility }
  | { type: 'setShowDelete'; payload: boolean }
  | { type: 'setSearchMode'; payload: boolean }
  | { type: 'setSearchTerm'; payload: string }
  | { type: 'setAttachmentJump'; payload?: string | null };

const attachmentsReducer: React.Reducer<State, AttachmentsActions> = (state, action) => {
  switch (action.type) {
    case 'setPickerOpen':
      return { ...state, openPicker: action.payload };
    case 'setExpandedAttachments':
      return { ...state, expandedAttachments: action.payload };
    case 'setProjectAttachments':
      return { ...state, projectAttachments: action.payload };
    case 'setSelectedAttachment':
      return { ...state, selectedAttachment: action.payload };
    case 'setAttachmentToDelete':
      return { ...state, attachmentToDelete: action.payload };
    case 'setJumpStyleUid':
      return { ...state, jumpStyleUid: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setAnchorEl':
      return { ...state, anchorEl: action.payload };
    case 'setFilterOpen':
      return { ...state, filterOpen: action.payload };
    case 'setFilterType':
      return { ...state, filterType: action.payload };
    case 'setFilterDir':
      return { ...state, filterDir: action.payload };
    case 'setCurrentDescription':
      return { ...state, currentDescription: action.payload };
    case 'setDescriptionCount':
      return { ...state, descriptionCount: action.payload };
    case 'setCurrentVisibility':
      return { ...state, currentVisibility: action.payload };
    case 'setShowDelete':
      return { ...state, showDelete: action.payload };
    case 'setSearchMode':
      return { ...state, searchMode: action.payload };
    case 'setSearchTerm':
      return { ...state, searchTerm: action.payload };
    case 'setAttachmentJump':
      return { ...state, attachmentJump: action.payload };
    default:
      return { ...state };
  }
};
const Attachments = (props: Props) => {
  const MAX_CHARS = 500;
  const maxDescSize = 50;
  const [state, dispatch] = React.useReducer(attachmentsReducer, {
    openPicker: false,
    expandedAttachments: [],
    descriptionCount: 0,
    jumpStyleUid: null,
    projectAttachments: [],
    loading: false,
    filterOpen: false,
    filterType: 'filename',
    filterDir: 'asc',
    selectedAttachment: null,
    currentDescription: '',
    currentVisibility: 'INTERNAL',
    showDelete: false,
    attachmentToDelete: null,
    searchMode: false,
    searchTerm: '',
    attachmentJump: props.attachmentJumpId
  });

  useListenToStore({ store: FileAttachmentStore, eventListener: onFileAttachmentStoreUpdate });
  useListenToStore({ store: EditorStore, eventListener: onEditorStoreUpdate });

  React.useEffect(() => {
    dispatch({ type: 'setLoading', payload: true });
    FileAttachmentStore.getIndexAttachments();
  }, []);

  function onFileAttachmentStoreUpdate(e: FileAttachmentStoreEvent) {
    if (e.type === 'project-updated') {
      dispatch({ type: 'setProjectAttachments', payload: e.projectUpdated?.attachedFiles || [] });
      dispatch({ type: 'setLoading', payload: false });
    }
  }

  function onEditorStoreUpdate(e: IEditorStoreEvent<'editorStoresInitializing' | 'activeEditorChange'>) {
    if (e.type === 'editorStoresInitializing') {
      dispatch({ type: 'setLoading', payload: true });
    }
    if (e.type === 'activeEditorChange') {
      FileAttachmentStore.getIndexAttachments();
    }
  }

  const onMoreClick = (item: Partial<IFileAttachment>) => {
    dispatch({ type: 'setExpandedAttachments', payload: state.expandedAttachments.concat(item) });
  };

  const onLessClick = (item: Partial<IFileAttachment>) => {
    let tempExpandedAttachments = state.expandedAttachments;
    tempExpandedAttachments.splice(tempExpandedAttachments.indexOf(item), 1);
    dispatch({ type: 'setExpandedAttachments', payload: tempExpandedAttachments });
  };

  const onClose = () => {
    dispatch({ type: 'setPickerOpen', payload: false });
  };

  const onItemDelete = () => {
    dispatch({ type: 'setShowDelete', payload: false });
    dispatch({ type: 'setAttachmentToDelete', payload: null });
    dispatch({ type: 'setLoading', payload: true });

    FileAttachmentStore.removeAttachment(_.extend(state.attachmentToDelete!, { status: 'DELETED' }));
  };

  const onSaveSelectedItem = (item: Partial<IFileAttachment>) => {
    dispatch({ type: 'setLoading', payload: true });
    dispatch({ type: 'setSelectedAttachment', payload: null });

    FileAttachmentStore.updateAttachment({
      ...item,
      visibility: state.currentVisibility,
      description: state.currentDescription!
    });
  };

  const filterByTerm = () => {
    if (!state.projectAttachments || !state.projectAttachments.filter) {
      console.error(new Error('Project attachments do not have a filter function. Project attachments: ' + state.projectAttachments));
      console.error(JSON.stringify(state.projectAttachments));
      return [];
    }
    return state.projectAttachments.filter((item) => {
      return (
        state.searchTerm === '' ||
        item.description!.toLowerCase().indexOf(state.searchTerm.toLowerCase()) !== -1 ||
        item.filename!.toLowerCase().indexOf(state.searchTerm.toLowerCase()) !== -1
      );
    });
  };

  const sortArray = (arr: Partial<IFileAttachment>[]) => {
    return arr.sort((a, b) => {
      if (state.filterType === 'filename') {
        if (a.filename!.toLowerCase() < b.filename!.toLowerCase()) {
          return -1;
        }
        if (a.filename!.toLowerCase() > b.filename!.toLowerCase()) {
          return 1;
        }

        return 0;
      } else if (state.filterType === 'date') {
        return (new Date(b.created!) as any) - (new Date(a.created!) as any);
      } else if (state.filterType === 'fileType') {
        if (a.mimeType!.toLowerCase() < b.mimeType!.toLowerCase()) {
          return -1;
        }
        if (a.mimeType!.toLowerCase() > b.mimeType!.toLowerCase()) {
          return 1;
        }

        return 0;
      }
      // Visibility
      else {
        if (a.visibility!.toLowerCase() < b.visibility!.toLowerCase()) {
          return -1;
        }
        if (a.visibility!.toLowerCase() > b.visibility!.toLowerCase()) {
          return 1;
        }

        return 0;
      }
    });
  };

  const onDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const descriptionText = e.target.value;
    const descriptionCount = descriptionText.length;
    dispatch({
      type: 'setCurrentDescription',
      payload: descriptionText.length > MAX_CHARS ? descriptionText.substr(0, MAX_CHARS) : descriptionText
    });
    dispatch({ type: 'setDescriptionCount', payload: descriptionCount > MAX_CHARS ? MAX_CHARS : descriptionCount });
  };

  const isItemExpanded = (item: Partial<IFileAttachment>) => {
    return state.expandedAttachments.indexOf(item) !== -1;
  };

  let filteredAttachments = filterByTerm();
  filteredAttachments = sortArray(filteredAttachments);

  if (state.filterDir === 'desc') {
    filteredAttachments = filteredAttachments.reverse();
  }

  return (
    <div
      className={`attachments-subaction subaction-list-container attachments file-attachment-sidebar ${
        !ProjectStore.canManageAttachments() ? 'cannot-manage-attachments' : ''
      } ${filteredAttachments.length > 0 ? 'has-attachments' : ''}`}
    >
      <div className="attachment-sidebar-header">
        {state.searchMode ? (
          <SearchBar
            hasCancel={true}
            onSearch={(term) => dispatch({ type: 'setSearchTerm', payload: term })}
            onClose={() => {
              dispatch({ type: 'setSearchMode', payload: false });
              dispatch({ type: 'setSearchTerm', payload: '' });
            }}
          />
        ) : (
          <div>
            <h5>Attachments</h5>
            <div className="attachment-header">
              <i onClick={() => dispatch({ type: 'setSearchMode', payload: true })} className="attachment-search-btn material-icons search">
                search
              </i>
              <i onClick={() => dispatch({ type: 'setPickerOpen', payload: true })} className="attachment-add-btn material-icons">
                add_circle
              </i>
            </div>
          </div>
        )}
        <div>
          <div>
            <div className="attachment-filter-btns">
              <div
                className="attachment-filter-drop"
                onClick={(e) => {
                  dispatch({ type: 'setAnchorEl', payload: e.currentTarget });
                  dispatch({ type: 'setFilterOpen', payload: true });
                }}
              >
                {FilterOptions.find((item) => item.val === state.filterType)!.label}
                <ArrowDropDown style={{ verticalAlign: 'middle' }} />
              </div>
              <span
                onClick={(e) => {
                  dispatch({ type: 'setFilterDir', payload: state.filterDir === 'asc' ? 'desc' : 'asc' });
                }}
                className="attachment-filter-dir"
              >
                {state.filterDir === 'asc' ? (
                  <ArrowDownward style={{ color: '#28ace3', verticalAlign: 'middle', height: '14px', width: '14px' }} />
                ) : (
                  <ArrowUpward style={{ color: '#28ace3', verticalAlign: 'middle', height: '14px', width: '14px' }} />
                )}
              </span>
            </div>

            <Popover
              open={state.filterOpen}
              anchorEl={state.anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={() => dispatch({ type: 'setFilterOpen', payload: false })}
            >
              <Menu
                onChange={(e, value) => {
                  dispatch({ type: 'setFilterType', payload: value });
                  dispatch({ type: 'setFilterOpen', payload: false });
                }}
              >
                {FilterOptions.map((item, index) => {
                  return (
                    <MenuItem
                      className={`attachment-filter-option-${item.val}`}
                      key={`attachment-filter-${index}`}
                      value={item.val}
                      primaryText={item.label}
                    />
                  );
                })}
              </Menu>
            </Popover>
          </div>
        </div>
      </div>
      <div className={`attachment-sidebar-content ${state.selectedAttachment ? 'edit-mode' : ''}`}>
        {!state.loading && filteredAttachments.length === 0 ? (
          <div style={{ margin: '20px 0', textAlign: 'center' }}>
            <img src={'/assets/images/no-attachments.svg'} />
            <div className="no-attachments-text">
              <div className="no-attachments-header">No Attachments</div>
              <div>{"You have no attachments. To add an attachment click on the '+' icon. All attachments will appear here."}</div>
            </div>
          </div>
        ) : undefined}

        {state.loading ? (
          <div style={{ textAlign: 'center', margin: '10px 0' }}>
            <CircularProgress className="loading-bar" />
          </div>
        ) : undefined}

        {!state.loading
          ? filteredAttachments.map((item, index) => {
              if (state.attachmentJump && state.attachmentJump === item.uid) {
                dispatch({ type: 'setJumpStyleUid', payload: state.attachmentJump });
              }

              const description = isItemExpanded(item)
                ? item.description
                : item.description?.substr(0, maxDescSize)
                ? item.description.substr(0, maxDescSize)
                : '';

              const isSelected = state.selectedAttachment === item;

              const showMore = item.description && item.description.length > maxDescSize && !isItemExpanded(item);
              const showLess = item.description && item.description.length > maxDescSize && isItemExpanded(item);

              const iconDimension = 18;
              const iconStyle: React.CSSProperties = {
                width: iconDimension,
                height: iconDimension,
                color: '#8a8a8a'
              };
              const style: React.CSSProperties = {
                width: iconDimension * 2,
                height: iconDimension * 2
              };

              return (
                <div
                  key={`attachment-${index}`}
                  className={`file-attachment ${state.jumpStyleUid === item.uid || isSelected ? 'selected' : ''}`}
                  onClick={() => dispatch({ type: 'setJumpStyleUid', payload: null })}
                  id={`file-attachment-${item.uid}`}
                  ref={
                    state.attachmentJump && state.attachmentJump === item.uid
                      ? (elm) => {
                          if (!elm) {
                            return;
                          }

                          dispatch({ type: 'setAttachmentJump', payload: null });
                          elm.scrollIntoView();
                        }
                      : undefined
                  }
                >
                  {state.showDelete && state.attachmentToDelete === item ? (
                    <div className="file-attachment-delete">
                      <div className="file-attachment-delete-inner">
                        <div>Do you want to delete this attachment?</div>
                        <div className="file-attachment-btns">
                          <FlatButton
                            labelStyle={{ color: '#fff' }}
                            label="Cancel"
                            onClick={() => dispatch({ type: 'setShowDelete', payload: false })}
                            className="cancel-attachment-btn"
                          />
                          <RaisedButton label="Delete" onClick={() => onItemDelete()} className="delete-attachment-btn" />
                        </div>
                      </div>
                    </div>
                  ) : undefined}
                  <div className="file-attachment-inline-options" style={{ display: isSelected ? 'none' : '' }}>
                    <IconButton
                      onClick={() => FileAttachmentStore.downloadContent(item)}
                      style={style}
                      iconStyle={iconStyle}
                      tooltip="Download"
                      className="file-attachment-inline-download"
                    >
                      <FontDownload />
                    </IconButton>
                    <div className="file-attachment-inline-divider" />
                    <IconButton
                      onClick={() => {
                        dispatch({ type: 'setSelectedAttachment', payload: item });
                        dispatch({ type: 'setCurrentDescription', payload: item.description! });
                        dispatch({ type: 'setCurrentVisibility', payload: item.visibility! });
                      }}
                      style={style}
                      iconStyle={iconStyle}
                      tooltip="Edit"
                      className="file-attachment-inline-edit"
                    >
                      <FontEdit />
                    </IconButton>
                    <div className="file-attachment-inline-divider" />
                    <IconButton
                      onClick={() => {
                        dispatch({ type: 'setAttachmentToDelete', payload: item });
                        dispatch({ type: 'setShowDelete', payload: true });
                      }}
                      style={style}
                      iconStyle={iconStyle}
                      tooltip="Delete"
                      className="file-attachment-inline-delete"
                    >
                      <FontDelete />
                    </IconButton>
                  </div>
                  <div className="file-attachment-icon">
                    <FileIcon fileType={item.attachmentType as AttachmentType} />
                  </div>
                  <div className="file-attachment-content">
                    <div
                      className="file-attachment-heading"
                      dangerouslySetInnerHTML={{
                        __html:
                          state.searchTerm !== ''
                            ? item.filename!.replace(new RegExp(state.searchTerm, 'ig'), (sub) => {
                                return `<span class='highlight'>${sub}</span>`;
                              })
                            : item.filename!
                      }}
                    />
                    {isSelected ? (
                      <div className="file-attachment-description">
                        <textarea
                          name="file-attachment-description-input"
                          cols={30}
                          rows={10}
                          value={state.currentDescription}
                          onChange={(e) => onDescChange(e)}
                        />
                      </div>
                    ) : (
                      <div className="file-attachment-description">
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              state.searchTerm !== ''
                                ? description!.replace(new RegExp(state.searchTerm, 'igs'), (sub) => {
                                    return `<span class='highlight'>${sub}</span>`;
                                  })
                                : description!
                          }}
                        />
                        {showMore ? (
                          <span onClick={(e) => onMoreClick(item)} className="file-attachment-more" data-qa="file-attachment-more">
                            ...more
                          </span>
                        ) : undefined}
                        {showLess ? (
                          <span onClick={(e) => onLessClick(item)} className="file-attachment-less" data-qa="file-attachment-less">
                            {' '}
                            (less)
                          </span>
                        ) : undefined}
                      </div>
                    )}
                    {isSelected ? (
                      <div className="file-attachment-edit-confirmation">
                        <div className="file-attachment-edit-btns">
                          <IconButton
                            iconStyle={{ color: '#8a8a8a' }}
                            tooltip="Cancel"
                            style={{ width: 30, height: 30, padding: '0' }}
                            onClick={() => dispatch({ type: 'setSelectedAttachment', payload: null })}
                            className="cancel"
                          >
                            <FontClose />
                          </IconButton>
                          <IconButton
                            iconStyle={{ color: '#2393c2' }}
                            tooltip="Save"
                            onClick={() => onSaveSelectedItem(item)}
                            style={{ width: 30, height: 30, padding: '0' }}
                            className="confirm"
                          >
                            <FontTick />
                          </IconButton>
                        </div>
                        {ProjectStore.getProject()?.currentUserPermissions?.canReadAttachmentsInternal ? (
                          <div
                            className="file-attachment-edit-visibility"
                            onClick={() =>
                              dispatch({
                                type: 'setCurrentVisibility',
                                payload: state.currentVisibility === 'INTERNAL' ? 'PUBLIC' : 'INTERNAL'
                              })
                            }
                          >
                            {state.currentVisibility === 'PUBLIC' ? (
                              <span>
                                <LockOpenIcon color="#8a8a8a" style={{ height: '12px', width: '12px' }} /> PUBLIC
                              </span>
                            ) : (
                              <span>
                                <LockIcon color="#8a8a8a" style={{ height: '12px', width: '12px' }} /> INTERNAL
                              </span>
                            )}
                            <span className="file-attachment-edit-note"> (Tap lock to set visibility)</span>
                          </div>
                        ) : undefined}
                        <div style={{ clear: 'both' }} />
                      </div>
                    ) : undefined}
                    <div className="file-attachment-details">
                      <div className="file-attachment-uploader file-attachment-detail">{item.metadata!.uploaderDisplayName}</div>
                      <div className="file-attachment-date file-attachment-detail">{dateUtil(item.created).formatDateTimeNoSecs()}</div>
                      {ProjectStore.getProject()?.currentUserPermissions?.canReadAttachmentsInternal ? (
                        <div className="file-attachment-visibility file-attachment-detail">
                          {!isSelected && item.visibility === 'PUBLIC' ? (
                            <span>
                              <LockOpenIcon color="#8a8a8a" style={{ height: '12px', width: '12px' }} /> PUBLIC
                            </span>
                          ) : undefined}
                          {!isSelected && item.visibility === 'INTERNAL' ? (
                            <span>
                              <LockIcon color="#8a8a8a" style={{ height: '12px', width: '12px' }} /> INTERNAL
                            </span>
                          ) : undefined}
                        </div>
                      ) : undefined}
                    </div>
                  </div>
                  <div style={{ clear: 'both' }} />
                  <div className="file-attachment-divider" />
                </div>
              );
            })
          : undefined}
      </div>

      {state.openPicker ? (
        <AttachmentPicker onUploadAttachment={(file) => FileAttachmentStore.uploadFiles([file])} onClose={() => onClose()} />
      ) : undefined}
    </div>
  );
};

export default Attachments;
