import * as React from 'react';
import { MenuItem, TextField, SelectField } from 'material-ui';
import { EffectivityValue } from '../../effectivity/metadataDefinition';
import { AeroIconButtonRemove } from '../../../../../../general/AeroIconButton';
import { IElementDefinition } from 'mm-types';
import classNameHelper from '../../../../../../../utils/classNameHelper';
import keyDown from '../../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
import refFocus from '../../../../tinyFacade/refFocus';
import { NNCMetadataComponentProps } from '../MetadataComponentTypes';
import { NNCIndexMetadataID } from '../../NNCIndexMetadataTypes';
const RemoveButton = React.memo(AeroIconButtonRemove);

interface Props {
  value: EffectivityValue;
  definitions: IElementDefinition[];
  index: number;
  onRemove(index: number);
  onChange(value: EffectivityValue, index: number): void;
  isLastEffectivity: boolean;
  onTab: NNCMetadataComponentProps<NNCIndexMetadataID.EFFECTIVITY>['onTab'];
  currentRef: React.RefObject<any> | null;
}
const EffectivityMetadataForm = (
  { value, definitions, onChange, onRemove, index, isLastEffectivity, onTab, currentRef }: Props,
  ref: React.Ref<TextField>
) => {
  const [text, setText] = React.useState<string>(value.text);
  const [type, setType] = React.useState<string>(value.type);
  React.useEffect(() => {
    if (value.text !== text) {
      setText(value.text);
    }
    if (value.type !== type) {
      setType(value.type);
    }
  }, [value.text, value.type]);

  function onTypeChange(type: string) {
    onChange(
      {
        text,
        type
      },
      index
    );
  }
  function onTextChange(text: string) {
    setText(text);
    onChange(
      {
        text,
        type
      },
      index
    );
  }

  function onRemoveClick() {
    onRemove(index);
  }

  const className = classNameHelper.merge('effectivity-metadata-form padding-left-l', {
    'empty-text': !text
  });

  return (
    <div className={className} style={{ display: 'grid', gridTemplateColumns: '1fr 48px' }}>
      <div>
        <div>
          <TextField
            hintText="Effectivity Name"
            onChange={(e, text) => onTextChange(text)}
            fullWidth={true}
            multiLine
            value={text}
            ref={ref}
            className="native-mui-no-padding"
            data-qa="effectivity-form-name"
          />
        </div>
        <div>
          <SelectField
            value={type}
            onChange={(e, key, value) => {
              onTypeChange(value);
              if (currentRef) {
                refFocus({
                  ref: currentRef
                }).catch((e) => console.warn(e));
              }
            }}
            fullWidth={true}
            data-qa="effectivity-form-type"
          >
            {definitions.map((def) => {
              return <MenuItem key={def.id} value={def.subType} primaryText={def.displayName} />;
            })}
          </SelectField>
        </div>
      </div>
      <div>
        <RemoveButton
          onClick={onRemoveClick}
          id="remove-effectivity-button"
          data-qa="remove-effectivity-button"
          tooltip="Remove Effectivity"
          tooltipPosition="top-left"
          outline={true}
          onKeyDown={(e) => {
            if (isLastEffectivity) {
              keyDown(e, onTab, currentRef, false, true);
            }
          }}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(EffectivityMetadataForm);
