import { EditorStore } from '../EditorStore';
import { UnitTypes } from '../../../components/editor/utils/units/UnitTypes';
import { DocParams } from 'mm-types';
import { saveDocUnit } from '../../../clients/units';
import { EditorEventType } from './EditorEventType';
import * as _ from 'lodash';
import ProjectDefinitionStore from '../../common/ProjectDefinitionStore';
import TocStore from '../TocStore';
import { AxiosError } from 'axios';
import { linkHelper } from '../../../components/editor/utils/tinyFacade/tinyLinkHelper';
import { Cancelled } from '../../../clients/base-clients';

export enum InsertPosition {
  BEFORE_SELECTED = 'beforeUnitUid',
  AFTER_SELECTED = 'afterUnitUid'
}

interface UnitParams {
  type?: UnitTypes;
  definitionId?: string;
  templateHtml?: string;
  siblingUnitUid: string;
  parentUnitUid?: string;
}

interface BehaviorParams {
  launchEditor?: boolean;
  insertPosition?: InsertPosition;
  scrollToUnit?: boolean;
  openEditPaneOnUnit?: boolean;
  isPaste?: boolean;
}

interface FetchParams {
  siblingUnitUid: string;
  behavior: BehaviorParams;
  insertIndex: number;
  html: string;
  parentUnitUid?: string;
  isPaste?: boolean;
}

const BUSY_EDITOR_MESSAGE = 'Creating...';
const BEHAVIOR_OVERRIDE = new Map<string, BehaviorParams | null>([
  ['non-normal-checklist-level1', { scrollToUnit: true, openEditPaneOnUnit: true }],
  ['paragraph-level1', { scrollToUnit: true, openEditPaneOnUnit: true }]
]);

export class CreateUnitAddon {
  private editorStore: EditorStore;

  constructor(editorStore: EditorStore) {
    this.editorStore = editorStore;
  }

  async createUnit(
    { projectUid, indexUid }: Pick<DocParams, 'projectUid' | 'indexUid'>,
    unitParams: UnitParams,
    behaviorParams: BehaviorParams
  ) {
    console.info(`%c[Unit]: Creating ${behaviorParams.insertPosition} (${unitParams.type ?? unitParams.definitionId})`, 'color: #666');
    if ((!unitParams.type && !unitParams.definitionId) || !projectUid || !indexUid) {
      throw `At least one of given attributes is missing: unit type or definitionId (${
        unitParams.type ?? unitParams.definitionId
      }) , projectUid (${projectUid}) or indexUid (${indexUid})`;
    }
    return this.fetchCreateUnit(projectUid, indexUid, this.getPayload(unitParams, behaviorParams));
  }

  private async fetchCreateUnit(projectUid: string, indexUid: string, params: FetchParams) {
    this.setBusy();

    try {
      const templateHtml = params.isPaste ? params.html : await linkHelper().populateUnitLinkInfoIfNeeded(params.html);
      const response = await saveDocUnit(
        projectUid,
        indexUid,
        { html: templateHtml },
        { [params.behavior.insertPosition as string]: params.siblingUnitUid, parentUid: params.parentUnitUid }
      );
      this.editorStore._docUnitCollection.splice(params.insertIndex, 0, response.unit);
      this.editorStore._reIndexUnits();
      await this.editorStore._performDocChangeMemoryUpdate(
        {
          newUnitWrapper: response.unit,
          parts: response.parts,
          unitUids: response.unitUids,
          refreshView: response.refreshView,
          previousUnits: [response.unit.unit],
          renderBehaviour: {
            isCreate: true,
            launchEditor: params.behavior.launchEditor,
            scrollToAffectedUnit: params.behavior.scrollToUnit,
            openEditPaneOnAffectedUnit: params.behavior.openEditPaneOnUnit
          }
        },
        EditorEventType.createUnit,
        false,
        response.unitUids.length > 1
      );
    } catch (err) {
      if (err instanceof Cancelled) {
        this.setBusy(false);
      } else {
        this.editorStore._handleError(err as AxiosError, [400, 403, 412, 409]);
      }
    }
  }

  private setBusy(isBusy = true) {
    this.editorStore.setBusy(isBusy, BUSY_EDITOR_MESSAGE);
  }

  private getPayload(unitParams: UnitParams, behaviorParams: BehaviorParams): FetchParams {
    const inTocmanAndIsTocable = this.editorStore.isMode('TOCMAN') && ProjectDefinitionStore.isTocableType(unitParams.type!);
    const tocableParams = inTocmanAndIsTocable ? this.getParamsForTocable(unitParams.siblingUnitUid, unitParams.type!) : null;
    const siblingUid = tocableParams?.siblingUnitUid ?? unitParams.siblingUnitUid;
    if (tocableParams?.insertPosition) {
      behaviorParams.insertPosition = tocableParams.insertPosition;
    }

    const unitTypeOrDefinitionId = unitParams.definitionId ?? unitParams.type!;
    let behavior: BehaviorParams = _.merge({ insertPosition: InsertPosition.AFTER_SELECTED, launchEditor: true }, behaviorParams);
    const siblingUnit = this.editorStore.getDocUnitModel(siblingUid)?.unit;

    // handle NNC Section edge case to insert after section FM, if more FM related quirks arise will need a more scalable strategy
    const siblingIndex = siblingUnit?.tocLevel === 'non-normal-checklists-section' ? siblingUnit.index + 1 : siblingUnit?.index ?? 0;

    const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(unitTypeOrDefinitionId);
    if (BEHAVIOR_OVERRIDE.has(unitTypeOrDefinitionId)) {
      behavior = { ...behavior, ...BEHAVIOR_OVERRIDE.get(unitTypeOrDefinitionId) };
    }
    return {
      behavior,
      siblingUnitUid: siblingUid,
      insertIndex: siblingIndex + (behavior.insertPosition === InsertPosition.BEFORE_SELECTED ? 0 : 1),
      html: unitParams.templateHtml ?? profile?.template!,
      parentUnitUid: unitParams.parentUnitUid,
      isPaste: behaviorParams.isPaste
    };
  }

  private getParamsForTocable(
    initialSiblingUid: string,
    type: UnitTypes
  ): { siblingUnitUid: string | null; insertPosition?: InsertPosition } {
    const firstUnit = this.editorStore._docUnitCollection!.find((wrapper) => {
      return !wrapper.unit.uid.startsWith('generated');
    });
    if (
      firstUnit &&
      ((firstUnit.unit.uid === initialSiblingUid && firstUnit.unit.definitionId === type) || initialSiblingUid !== firstUnit.unit.uid)
    ) {
      const newTarget = TocStore.findNextTocableOfSameLevelOrLower(initialSiblingUid);
      if (newTarget) {
        return {
          siblingUnitUid: newTarget,
          insertPosition: InsertPosition.BEFORE_SELECTED
        };
      }
      return {
        siblingUnitUid: firstUnit.unit.uid
      };
    }
    return {
      siblingUnitUid: null
    };
  }
}
