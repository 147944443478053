import * as React from 'react';
import NotificationList from '../general/notifications/NotificationList';
import NotificationsStore, { NotificationInfo } from '../../flux/events/NotificationsStore';
import { FlatButton } from 'material-ui';
import NoNewNotifications from './NoNewNotifications';
import { INotification } from 'mm-types';

export type NotificationsTileProps = {
  info: NotificationInfo;
  notifications: INotification[];
};

const NotificationsTile = ({ info, notifications }: NotificationsTileProps) => {
  return (
    <div id="notifications-tile">
      {notifications.length === 0 ? (
        <NoNewNotifications />
      ) : (
        <>
          <NotificationList
            onRequestMoreNotifs={() => {
              NotificationsStore.nextNotifs();
            }}
            notifications={notifications}
            notificationInfo={info}
            isTile={true}
          />
          <div className="dashboard-notif-read-action">
            <FlatButton
              id="allToRead"
              secondary={true}
              label="Set all to read"
              onClick={() => {
                NotificationsStore.setAsRead();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationsTile;
