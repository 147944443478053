import * as React from 'react';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import ToggleSection from '../../../../../misc/ToggleSection';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import NoProperties from '../../selectedComponent/components/NoProperties';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';
import { getElmDef, hasDefinitionDataAttributes } from '../../../../../../utils/DataAttributesUtil';

const HeaderProps = (props: IGenericEditProps) => {
  const [userOpened, setUserOpened] = React.useState<boolean>(false);

  const hasDataAttributes = hasDefinitionDataAttributes(
    props.definition,
    props.parentDefinition?.id,
    getElmDef(props.selectedUnit?.definitionId)
  );

  const noProps = !ProjectDefinitionStore.allowUnitIndentation() && !props.definition?.dataAttributes;

  return (
    <div className="toggle-section-outer selected-unit-props-container">
      <ToggleSection
        title={props.definition?.displayName + ' Properties'}
        id={'unitProps_' + props.index}
        defaultOpen={false}
        forceClose={!props.isLeaf}
        forceOpen={hasDataAttributes && (props.isLeaf || userOpened)}
        enabled={hasDataAttributes}
        onToggled={(isOpened) => {
          setUserOpened(isOpened);
        }}
      >
        <div className="selected-unit-props-section p-0">
          {ProjectDefinitionStore.allowUnitIndentation() && <MultiFormatting unitProfile={props.unitProfile} />}
          <DataAttributesPropsWrapper {...props} />
          {noProps && <NoProperties short={true} />}
        </div>
      </ToggleSection>
    </div>
  );
};

export default HeaderProps;
