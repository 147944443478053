import ParagraphEditProps from '../../../../sidetabs/sub/editComponent/components/ParagraphEditProps';
import TinyCustomKeyListener from '../../../tinyFacade/TinyCustomKeyListener';
import { CustomEditor } from '../../../tinyFacade/EditorInstanceManager';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Paragraph',
  getLabel: (unit) => {
    let pLabel = '';
    if (unit && unit.level && unit.level.indexOf('level') !== -1) {
      pLabel = 'P' + parseInt(unit.level.match(/\d+$/)![0], 10);
    } else {
      pLabel = 'P';
    }

    return pLabel;
  },
  identifyingClasses: ['arc-paragraph'],
  identifyingElements: ['p'],
  template: '',
  outerClass: 'arc-paragraph',
  splittable: true,

  editPropsComponent: ParagraphEditProps,

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    editActions: [
      { action: 'Paste', enabled: true },
      { action: 'arcPasteElement', enabled: true }
    ],
    textActions: [
      { action: 'arcFontSize', enabled: false },
      { action: 'arcTextTransform', enabled: false, visible: true }
    ],
    justifyActions: [
      { action: 'JustifyLeft', enabled: true },
      { action: 'JustifyCenter', enabled: true },
      { action: 'JustifyRight', enabled: true },
      { action: 'JustifyFull', enabled: true }
    ],
    specialActions: [
      { action: 'SpecialCharacter', enabled: true },
      { action: 'InlineDataMeasure', enabled: true }
    ],
    linkActions: [
      { action: 'insertLink', enabled: true },
      { action: 'duRefLink', enabled: true }
    ]
  },
  editor: {
    convert_urls: false,
    relative_urls: false,
    setup: function (editor) {
      TinyCustomKeyListener.applyBreakLine(editor as CustomEditor);
    },
    plugins: ['nonbreaking paste noneditable'],
    valid_elements:
      'style,figure[class],figcaption[class],img[src|class|style],br,p[class|style],span[class|style],div[class],bold,strong,em,i,sup,sub,u,b,ins,a[class|href],code'
  }
};
