import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const TaskParticipantUpdate = ({ entry }: PropsType) => {
  const taskParticipantUpdateDom: JSX.Element[] = [];
  if (entry.projectActivityType === 'TASK_PARTICIPANT_ADD' || entry.projectActivityType === 'TASK_PARTICIPANT_UPDATE') {
    if (entry.indexRevisionNumber && !entry.isChild) {
      taskParticipantUpdateDom.push(
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      );
    }
    if (entry.participant.user.username) {
      taskParticipantUpdateDom.push(
        <div key="name">
          <label>Name:</label> <span>{entry.participant.user.username}</span>
        </div>
      );
    }
    if (entry.participant.reviewerDisposition) {
      const reviewerDisposition =
        typeof entry.participant.reviewerDisposition !== 'string'
          ? entry.participant.reviewerDisposition?.title.toLowerCase()
          : entry.participant.reviewerDisposition.toLowerCase();
      taskParticipantUpdateDom.push(
        <div key="disposition">
          <label>Disposition:</label> <span>{reviewerDisposition}</span>
        </div>
      );
    }
    if (entry.participant.status) {
      const key = entry.participant.status.replace(/_/g, ' ');
      const status = key.toLowerCase();
      taskParticipantUpdateDom.push(
        <div key="status">
          <label>Status:</label> <span>{status}</span>
        </div>
      );
    }
  } else if (entry.projectActivityType === 'TASK_PARTICIPANT_DELETE') {
    if (entry.participant.user.username) {
      taskParticipantUpdateDom.push(
        <div key="name">
          <label>Name:</label> <span>{entry.participant.user.username}</span>
        </div>
      );
    }
  }
  return <div className="settings-details">{taskParticipantUpdateDom}</div>;
};
