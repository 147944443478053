import TinyCustomKeyListener from '../tinyFacade/TinyCustomKeyListener';
import * as _ from 'lodash';
import { EditorManager } from 'tinymce';
declare const tinymce: EditorManager;

/*
 *  Configuration for single line titles
 *  e.g. caution, section start, level1-8=6 headers...
 *
 */

const _titleMenuOverrides = {
  docActions: [
    { action: 'Undo', enabled: true },
    { action: 'Redo', enabled: true }
  ],
  editActions: [{ action: 'Paste', enabled: true }]
};

const _titleMenuSelectedOverrides = {
  editActions: []
};

const _titleEditorConfig = {
  // don't allow any elements
  invalid_elements: '*,',
  forced_root_block: '',

  // shortcut key formatting options still get applied: force meaningless options to be inserted which will get stripped out on transform
  // Allow superscript and subscript for tocables
  formats: {
    subscript: { inline: 'span', classes: 'arc-sub-script', exact: true },
    superscript: { inline: 'span', classes: 'arc-super-script', exact: true },
    bold: { inline: 'span', classes: 'donothing' },
    italic: { inline: 'span', classes: 'donothing' },
    underline: { inline: 'span', classes: 'donothing' },
    forecolor: { inline: 'span', classes: 'donothing' },
    hilitecolor: { inline: 'span', classes: 'donothing' }
  }
};

const _tocableEditorConfig = _.extend(
  {
    plugins: [],
    setup: function (editor) {
      TinyCustomKeyListener.applySingleLine(editor);
    }
  },
  _titleEditorConfig
);

const _structuralEditorConfig = _.extend(
  {
    plugins: [],
    setup: function (editor) {
      TinyCustomKeyListener.applySingleLineNoDelete(editor);
    }
  },
  _titleEditorConfig
);

export default {
  tocable: {
    targetEditContainerSelector: '>.arc-unit div.arc-tocable div.arc-tocable-heading',
    menu: _titleMenuOverrides,
    menuTextSelected: _titleMenuSelectedOverrides,
    editor: _tocableEditorConfig
  },

  structural: {
    targetEditContainerSelector: '>.arc-unit div.arc-tocable div.arc-tocable-heading',
    menu: _titleMenuOverrides,
    menuTextSelected: _titleMenuSelectedOverrides,
    editor: _structuralEditorConfig
  },

  sanitize: {
    sanitize: function () {
      return $('<div/>')
        .text(tinymce.activeEditor.getContent({ format: 'text' }))
        .html()
        .replace(/&nbsp;/gi, ' ');
    }
  }
};
