import { mapProps } from './mapProps';
import compose from '../../utils/compose';
import { getSelectedClosestTocable } from './common';
import { IUnit } from 'mm-types';

const withClosestTocable = (componentToWrap) =>
  compose(
    mapProps(({ selectedUnit, ...rest }: { selectedUnit: IUnit | null }) => {
      return {
        ...rest,
        selectedUnit,
        selectedUnitClosestTocable: getSelectedClosestTocable(selectedUnit)
      };
    })
  )(componentToWrap);

export default withClosestTocable;
