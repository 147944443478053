import { readUnitTypeFromDOM } from '../../../tinyFacade/TinyFacadeHelpers';

export namespace EcamAsserts {
  export function isEcamElement(element: Element): boolean {
    const focusInfo = readUnitTypeFromDOM(element as HTMLElement);
    return ['EcamData', 'EcamSys', 'EcamTitle', 'EcamSubtitle'].indexOf(focusInfo.definitionId) !== -1;
  }
}

export namespace MeasureAsserts {
  export function isMeasureElement(element: Element): boolean {
    const focusInfo = readUnitTypeFromDOM(element as HTMLElement);
    return ['Measure'].indexOf(focusInfo.definitionId) !== -1;
  }
}
