export type TableAlignmentType = 'tablealign' | 'valign' | 'rotation' | 'halign';

export type TAction = {
  action: string;
  tooltip: string;
  icon: string;
  type: TableAlignmentType;
  dataAttribute?: string;
  settingId?: string;
};

export const CONTENTS: TAction[] = [
  {
    action: 'arcTableAlignLeft',
    tooltip: 'Left table align',
    icon: 'icon-align-table-left',
    type: 'tablealign',
    settingId: 'tableAlignLeft'
  },
  {
    action: 'arcTableAlignCentre',
    tooltip: 'Center table align',
    icon: 'icon-align-table-centre',
    type: 'tablealign',
    settingId: 'tableAlignCentre'
  },
  {
    action: 'arcTableAlignRight',
    tooltip: 'Right table align',
    icon: 'icon-align-table-right',
    type: 'tablealign',
    settingId: 'tableAlignRight'
  },
  {
    action: 'arcVAlignTop',
    tooltip: 'Vertical align top',
    icon: 'icon-align-top',
    type: 'valign',
    dataAttribute: 'top',
    settingId: 'valignTop'
  },
  {
    action: 'arcVAlignMiddle',
    tooltip: 'Vertical align middle',
    icon: 'icon-align-middle',
    type: 'valign',
    dataAttribute: 'middle',
    settingId: 'valignMiddle'
  },
  {
    action: 'arcVAlignBottom',
    tooltip: 'Vertical align bottom',
    icon: 'icon-align-bottom',
    type: 'valign',
    dataAttribute: 'bottom',
    settingId: 'valignBottom'
  },
  { action: 'arcHAlignLeft', tooltip: 'Horizontal align left', icon: 'icon-align-table-left', type: 'halign', dataAttribute: 'left' },
  {
    action: 'arcHAlignCenter',
    tooltip: 'Horizontal align center',
    icon: 'icon-align-table-centre',
    type: 'halign',
    dataAttribute: 'center'
  },
  {
    action: 'arcHAlignRight',
    tooltip: 'Horizontal align right',
    icon: 'icon-align-table-right',
    type: 'halign',
    dataAttribute: 'right'
  },
  {
    action: 'arcHAlignJustify',
    tooltip: 'Horizontal align justify',
    icon: 'format_align_justify',
    type: 'halign',
    dataAttribute: 'justify'
  },
  { action: 'arcHAlignChar', tooltip: 'Horizontal align char', icon: 'spellcheck', type: 'halign', dataAttribute: 'char' },
  { action: 'arcRotation0', tooltip: 'Normal', icon: 'icon-text-rotation-0', type: 'rotation', settingId: 'textRotation' },
  { action: 'arcRotation90', tooltip: 'Rotate 90°', icon: 'icon-text-rotation-90', type: 'rotation', settingId: 'textRotation' },
  { action: 'arcRotation270', tooltip: 'Rotate 270°', icon: 'icon-text-rotation-270', type: 'rotation', settingId: 'textRotation' }
];
