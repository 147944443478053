/* FRAMEWORK */
import * as React from 'react';
/* COMPONENTS */
import PropsTab from './PropsTab';
import FormattingTab from './FormattingTab';
/* TYPES */
import { TableEditPropsTabsProps } from 'mm-types';
import { UnitUtils } from '../../../../utils/units/UnitUtils';

const TableEditPropsTabs = (props: TableEditPropsTabsProps) => {
  if (props.activeTab === 'props') {
    return <PropsTab {...props} />;
  }

  if (props.activeTab === 'formatting') {
    const renderMultiFormatting = UnitUtils.isFormatingAvailable(props.unitElement);
    return <FormattingTab renderMultiFormatting={renderMultiFormatting!} {...props} />;
  }

  return <div>Error state -&gt; no activeTab has been set</div>;
};

export default TableEditPropsTabs;
