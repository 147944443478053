import React, { useEffect, useState } from 'react';
import { useStorage } from '../useStorage';
import { SESSION_STORAGE_KEYS } from '../../../utils/sessionStorageKeys';
import { Grid } from '../layouts/Grid';
import { AeroCancelButton, AeroConfirmButton } from '../AeroButton';
import { AeroIconButtonDelete } from '../AeroIconButton';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import Log from '../../../utils/Log';

interface MockResponse {
  url: string;
  value: string;
}
interface MockResponseWithId extends MockResponse {
  id: string;
}

const URL_PLACEHOLDER = 'url e.g. /accounts/api/auth/sso';
const RESPONSE_PLACEHOLDER = 'valid Json e.g. { "test": 1 }';

interface Props {
  style?: React.CSSProperties;
  onSave?(text: string): void;
}

export function MockResponseManager({ style, onSave }: Props) {
  const [responses, setResponses] = useStorage(btoa(SESSION_STORAGE_KEYS.MOCKED_RESPONSES));
  const [form, setForm] = useState<MockResponseWithId[]>([]);

  useEffect(() => {
    if (responses === null) {
      setForm([]);
    } else {
      const res: MockResponse[] = JSON.parse(responses as string);
      setForm(res.map((mock) => ({ ...mock, id: _.uniqueId() })));
    }
  }, [responses]);

  function isValidJSON(value: string): boolean {
    let result = false;
    try {
      JSON.parse(value);
      result = true;
    } catch (e) {
      Log.info('Exception when parsing json value');
    }
    return result;
  }

  function save() {
    const mockResponses: MockResponse[] = [];
    form.forEach((res) => {
      if (res.url && res.value && isValidJSON(res.value)) {
        mockResponses.push(res);
      }
    });
    setResponses(JSON.stringify(mockResponses));
    onSave?.('Saved. Invalid values were omitted.');
  }

  function changeUrl(url: string, index: number) {
    const newForm = [...form];
    newForm[index].url = url;
    setForm(newForm);
  }

  function changeUrlResponse(response: string, index: number) {
    const newForm = [...form];
    newForm[index].value = response;
    setForm(newForm);
  }

  function removeMock(index: number) {
    const newForm = [...form];
    newForm.splice(index, 1);
    setForm(newForm);
  }

  function addNew() {
    setForm([...form, { url: '', value: '', id: _.uniqueId() }]);
  }

  return (
    <div className="padding-l" style={style}>
      <h5 className="margin-none font-size-16 font-bold">Mock responses</h5>
      <div className="font-gray padding-vertical-l">
        If you need to change response of certain API call you can use this module. <br />
        Just insert <b>exact</b> url without protocol and host e.g. <b>/account/sso</b> and expected result in valid JSON format. <br />
        Mock errors in the following json with any error code above 400{' '}
        {JSON.stringify({ status: 400, response: { error: { code: '123', message: 'ERROR' } } })}
      </div>
      {form.length > 0 &&
        form.map((res, index) => (
          <Grid key={res.id} templateColumns="33% 50% 16%" gap="16px">
            <div>
              <TextField
                fullWidth={true}
                className="reset-mui-padding"
                hintText={URL_PLACEHOLDER}
                value={res.url}
                multiLine={false}
                onChange={(e, text: string) => changeUrl(text, index)}
              />
            </div>
            <div>
              <TextField
                fullWidth={true}
                className="reset-mui-padding"
                hintText={RESPONSE_PLACEHOLDER}
                value={res.value}
                multiLine={true}
                onChange={(e, text: string) => changeUrlResponse(text, index)}
              />

              {!!res.value && !isValidJSON(res.value) && <div className="color-error">Invalid JSON {res.value}</div>}
            </div>
            <div>
              <AeroIconButtonDelete onClick={() => removeMock(index)} />
            </div>
          </Grid>
        ))}

      <div className="padding-vertical-l">
        <AeroCancelButton onClick={addNew} key="AddMockButton" label="Add new mock" style={{ marginRight: 24 }} />
        <AeroConfirmButton onClick={save} key="SaveButton" label="Save" />
      </div>
    </div>
  );
}
