export enum MediaTypesContants {
  video = 'video',
  image = 'image',
  audio = 'AUDIO',
  other = 'OTHER_TEXT_DOC',
  symbol = 'symbol',
  all = 'all'
}

export const MEDIA_TYPES = [
  MediaTypesContants.video,
  MediaTypesContants.image,
  MediaTypesContants.audio,
  MediaTypesContants.other,
  MediaTypesContants.symbol
] as const;

export type MediaTypes = typeof MEDIA_TYPES[number];

export const MEDIA_FILTER_TYPES = [
  MediaTypesContants.all,
  MediaTypesContants.video,
  MediaTypesContants.image,
  MediaTypesContants.audio,
  MediaTypesContants.other,
  MediaTypesContants.symbol
] as const;

export type MediaFilterType = typeof MEDIA_FILTER_TYPES[number];
