import * as React from 'react';
import { Dialog, FlatButton } from 'material-ui';
import AutoTagStore from '../../../../../flux/editor/AutoTagStore';
import { ITask } from 'mm-types';

export type Props = {};

export type State = {
  open: boolean;
  task?: ITask;
};

export default class ChangeTaskNotAssignedModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  open(task: ITask) {
    this.setState({ open: true, task: task });
  }

  handleNo() {
    this.setState({ open: false });
  }

  handleYes() {
    this.setState({ open: false }, () => AutoTagStore.addTask(this.state.task!.uid));
  }

  render() {
    const actions: JSX.Element[] = [
      <FlatButton key={1} label="Yes" primary={true} onClick={(e) => this.handleYes()} />,
      <FlatButton key={2} label="No" primary={true} onClick={(e) => this.handleNo()} />
    ];

    const title = this.state.task ? this.state.task.title : '';

    return (
      <div>
        <Dialog title="This change task is not assigned." actions={actions} modal={true} open={this.state.open}>
          This change task is not assigned to you, are you sure you want to enable auto tagging for {title} ?
        </Dialog>
      </div>
    );
  }
}
