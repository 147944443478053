import * as Reflux from 'reflux';
import * as _ from 'lodash';
import * as activitiesClient from './../../clients/activities';
import * as userClient from './../../clients/users';
import * as indexClient from './../../clients/index';
import * as projectClient from './../../clients/project';
import Store from '../Store';
import { IWorkFlow, IProject, IIndex, IRevision, IProjectActivity, IExternalRevisions, IProjectMembership } from 'mm-types';
import { Cancelled } from '../../clients/base-clients';

export type ProjectPropsStoreEvent = {
  type: 'team' | 'documentLog' | 'revisions' | null;
  state: State;
} & { error?: ErrorTypes };

export type State = {
  revisions: null | IExternalRevisions | IRevision[];
  documentLog: IProjectActivity[] | null;
  project: Partial<IProject>;
  team: {
    [prop: string]: IProjectMembership[];
  } | null;
  reference: string;
  department: string;
  workflowSummary: Partial<IWorkFlow>;
  locked: boolean;
  isExternal: boolean;
  index: IIndex | null;
  nextCursor: string | null;
};

export type ErrorTypes = 'REVISIONS_GONE';

export const PROJECT_PROPS_INITIAL_STATE = {
  revisions: null,
  documentLog: [],
  project: {},
  team: {},
  reference: '',
  department: '',
  nextCursor: null,
  workflowSummary: {},
  locked: false,
  index: null,
  isExternal: false
};

export class ProjectPropsStore extends Store<State> {
  private lastTeamUid = '';
  private lastRevisionUid = '';

  constructor() {
    super();
    this.state = { ...PROJECT_PROPS_INITIAL_STATE };
  }

  resetRevisions(
    document: IProject,
    index: IIndex | null = null,
    revisions: IExternalRevisions | IRevision[] | null = null,
    type: ProjectPropsStoreEvent['type'] = null
  ) {
    if (document.interimIndexUid && revisions) {
      const newRevision: Partial<IRevision> = {
        interim: true,
        description: '',
        distributionDateFormatted: '',
        indexUid: document.interimIndexUid,
        pdfMediaUid: '',
        revision: '',
        revisionDateFormattedShort: ''
      };
      (revisions as IRevision[]).unshift(newRevision as IRevision);
    }

    this.state = {
      isExternal: document.isExternal,
      team: this.state.team,
      documentLog: this.state.documentLog,
      project: document,
      index: index,
      revisions: revisions,
      reference: index?.reference ?? '',
      department: index?.department ?? '',
      workflowSummary: {},
      locked: index?.isLocked ?? false,
      nextCursor: null
    };

    const event: ProjectPropsStoreEvent = {
      type,
      state: this.state
    };

    this.trigger(event);
  }

  getCurrentState() {
    return this.state;
  }

  getInitialState() {
    return { ...PROJECT_PROPS_INITIAL_STATE };
  }

  clearAndGetRevisions(document: IProject) {
    if (document.uid === this.lastRevisionUid) {
      this.trigger({
        type: 'revisions',
        state: this.state
      });
      return;
    }
    this.resetRevisions(document);
    this.getRevisions(document);
  }

  async getRevisions(document: IProject, withType = false, force = false) {
    this.state.locked = true;

    if (!!this.state.project && document.uid === this.state.project.uid && !!this.state.revisions && !force) {
      const event: ProjectPropsStoreEvent = {
        type: 'revisions',
        state: this.state
      };
      this.trigger(event);
    } else {
      try {
        const [index] = await Promise.all([indexClient.get(document.uid, document.masterIndexUid)]);

        let revisions: IExternalRevisions | IRevision[];

        if (!document.isExternal) {
          revisions = await projectClient.getRevisions(document.uid, document.masterIndexUid);
        } else {
          revisions = await projectClient.getExternalRevisions(document.uid);
        }

        this.resetRevisions(document, index instanceof Cancelled ? null : index, revisions, withType ? 'revisions' : null);
        this.lastRevisionUid = document.uid;
      } catch (err) {
        this.trigger({ error: 'REVISIONS_GONE' } as ProjectPropsStoreEvent);
      }
    }
  }
  clearCache() {
    this.lastTeamUid = '';
    this.lastRevisionUid = '';
  }

  async retrieveTeam(projectUid: string) {
    if (this.lastTeamUid === projectUid) {
      return;
    }
    this.sendTeamEvent(null);

    try {
      const team = await userClient.getMembers(projectUid);

      if (team instanceof Cancelled) {
        return;
      }

      this.sendTeamEvent(_.groupBy(team, 'role'));
      this.lastTeamUid = projectUid;
    } catch (err) {
      this.sendTeamEvent({});
    }
  }

  sendTeamEvent(team: State['team']) {
    this.state.team = team;
    const event: ProjectPropsStoreEvent = {
      type: null,
      state: this.state
    };
    this.trigger(event);
  }

  async retrieveDocumentLog(
    options: { next?: 'next' | 'fetch' | boolean; projectUid: string; indexUid?: string },
    filter: string[],
    taskUid?: string | null
  ) {
    if (!options.next || options.next === 'fetch') {
      this.state.nextCursor = null;
      this.state.documentLog = [];
    }

    const response = await activitiesClient.getAllProjectActivities(options.projectUid, {
      indexUid: options.indexUid!,
      taskUid: taskUid,
      cursor: this.state.nextCursor,
      filter: filter
    });

    if (response instanceof Cancelled) {
      return;
    }

    if (response.nextCursor !== this.state.nextCursor) {
      const documentLog = response.activities;
      this.state.nextCursor = response.nextCursor;

      for (let i = 0; i < documentLog.length; i++) {
        if (documentLog[i].projectActivityType === 'PROJECT_SETTINGS') {
          if (documentLog[i].projectSettings.status) {
            documentLog.splice(i, 1);
          }
        }
      }

      this.state.documentLog = this.state.documentLog ? this.state.documentLog.concat(documentLog) : documentLog;
    }

    const event: ProjectPropsStoreEvent = {
      type: 'documentLog',
      state: this.state
    };

    this.trigger(event);
  }
}

const singleton = Reflux.initStore<ProjectPropsStore>(ProjectPropsStore);
export default singleton;
