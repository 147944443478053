import React, { useState } from 'react';
import { FlatButton, Toggle } from 'material-ui';
import RaisedButtonWithTooltip from '../../../../general/RaisedButtonWithTooltip';
import { ActionType } from '../../MenuWorkflow';
import PublishStore from '../../../../../flux/editor/PublishStore';
import { IStage, IWorkFlow } from 'mm-types';
import StringUtil from '../../../../../utils/StringUtil';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import { MenuElement, MenuInfoElement, MenuItemSpace, MenuSeparator } from '../../EditorMenu/MenuElements';
import { Cancelled } from '../../../../../clients/base-clients';

interface Props {
  doesModeAllowWorkflow: boolean;
  isUserWorkflowAdmin: boolean;
  isWorkflowFinished: boolean;
  onPerformAction(action: ActionType, label: string): void;
  onActionClick(e: React.MouseEvent<{}>, action: ActionType, label: string): void;
  activeStage: null | IStage;
  workflow?: IWorkFlow;
  showOverrideEditButton: boolean;
  isEditingOverride: boolean;
  canPerformAction: boolean;
  canPerformReject: boolean;
  onChecking(isChecking: boolean): void;
  indicators: JSX.Element;
}

const BUTTON_TEXT_MAX_LENGTH = 25;

export function WorkflowStartedStage(props: Props) {
  const [busyForLock, setBusyForLock] = useState(false);
  const activeIndex = ProjectStore.getIndex();
  const showPreviousButton = props.activeStage?.previousStage !== null && !isNaN(props.activeStage?.previousStage as number);
  const showSkipButton = props.activeStage?.skippableStage && props.isUserWorkflowAdmin;

  function getText(field: string) {
    if (!(props.activeStage && props.workflow?.stages)) {
      return '';
    }
    const workflow = props.workflow;
    const activeStage = props.activeStage;

    let idx = -1;

    do {
      idx++;
    } while (idx < workflow.stages!.length && workflow.stages![idx].uid !== activeStage.uid);

    switch (field) {
      case 'currentStageTitle':
        return activeStage ? activeStage.title : '';
      case 'nextStageActionTitle':
        return activeStage.autoStagesSkip
          ? workflow.stages![activeStage.nextStage!].nextStageActionTitle
          : activeStage.nextStageActionTitle
          ? activeStage.nextStageActionTitle
          : activeStage.publishStep
          ? 'Publish'
          : idx < workflow.stages!.length - 1
          ? 'Next'
          : 'Finish';
      case 'previousStepTitle':
        return activeStage.previousStageActionTitle ? activeStage.previousStageActionTitle : 'Back';
      case 'completionDate':
        return '';
      case 'nextStageTitle': {
        const nextStage = workflow.stages![activeStage.nextStage!];
        if (activeStage.autoStagesSkip) {
          return 'Automatically skipped';
        }
        return nextStage ? nextStage.title : 'No Next Stage';
      }
    }
  }
  function shortenText(text: string) {
    return StringUtil.shortenText(text, BUTTON_TEXT_MAX_LENGTH);
  }

  function isLongerThanMax(text: string) {
    const shortText = StringUtil.shortenText(text, BUTTON_TEXT_MAX_LENGTH);
    return text.length !== shortText.length;
  }

  async function handleEditingOverrideToggle() {
    setBusyForLock(true);
    await ProjectStore.indexToUpdate();
    setBusyForLock(false);
  }

  return (
    <>
      {props.indicators}

      <MenuSeparator />

      {!props.isWorkflowFinished && (
        <MenuInfoElement
          onClick={(e) => props.onActionClick(e, 'SUMMARY', 'Summary')}
          style={{ paddingTop: 4, paddingLeft: 0 }}
          dataQa="menu-element-stage-info"
        >
          <div className="stack">
            <div className="title">Current Stage:</div>
            <span>{getText('currentStageTitle')}</span>
          </div>
          <div className="stack">
            <div className="title">Next Stage:</div>
            <span>{getText('nextStageTitle')}</span>
          </div>
        </MenuInfoElement>
      )}

      {props.isWorkflowFinished && (
        <MenuInfoElement dataQa="menu-element-workflow-finished" onClick={() => props.onPerformAction('SUMMARY', 'Summary')}>
          <div className="title">Workflow Finished</div>
        </MenuInfoElement>
      )}

      {!props.showOverrideEditButton ? (
        <MenuItemSpace onRight={true} />
      ) : (
        <MenuElement onRight={true} editingOverride={true} dataQa="menu-element-allow-editing">
          <div onClick={handleEditingOverrideToggle}>
            <Toggle
              id={'workflow-allow-editing-toggle'}
              name="editingtoggle"
              label="Allow Editing"
              disabled={props.isWorkflowFinished || !props.doesModeAllowWorkflow || busyForLock}
              toggled={props.isEditingOverride}
            />
          </div>
        </MenuElement>
      )}

      {!!props.activeStage && (
        <MenuElement id="next-stage" exportButtons={true} actionButtons={true} onRight={true} dataQa="menu-element-next-stage">
          <RaisedButtonWithTooltip
            label={shortenText(getText('nextStageActionTitle')!)}
            labelColor="#FFFFFF"
            backgroundColor="#009688"
            onClick={(e) => {
              e.persist();
              props.onChecking(true);
              PublishStore.workflowCheck(activeIndex ? activeIndex.workflowUid : undefined).then((result) => {
                props.onChecking(false);
                if (result) {
                  if (result instanceof Error || result instanceof Cancelled || (result.errors && result.errors.length > 0)) {
                    return;
                  }
                }

                props.onActionClick(e, 'APPROVE', getText('nextStageActionTitle')!);
              });
            }}
            disabled={!props.canPerformAction}
            iconClass="done"
            tooltipEnabled={isLongerThanMax(getText('nextStageActionTitle')!)}
            tooltipText={getText('nextStageActionTitle')!}
          />
        </MenuElement>
      )}

      {showPreviousButton && (
        <MenuElement id="reject-workflow" exportButtons={true} actionButtons={true} onRight={true} dataQa="menu-element-prev-stage">
          <RaisedButtonWithTooltip
            label={shortenText(getText('previousStepTitle')!)}
            labelColor="#FFFFFF"
            backgroundColor="#a2a2a2"
            onClick={(e) => props.onActionClick(e, 'REJECT', getText('previousStepTitle')!)}
            disabled={!props.canPerformReject}
            iconClass="close"
            tooltipEnabled={isLongerThanMax(getText('previousStepTitle')!)}
            tooltipText={getText('previousStepTitle')!}
          />
        </MenuElement>
      )}

      {showSkipButton && (
        <MenuElement exportButtons={true} onRight={true} dataQa="menu-element-skip-stage">
          <FlatButton id={'workflow-skip-button'} backgroundColor="#d8f0f9" onClick={(e) => props.onActionClick(e, 'SKIP', 'SKIP')}>
            <span className="skip-button material-icons">skip_next</span> Skip
          </FlatButton>
        </MenuElement>
      )}
    </>
  );
}
