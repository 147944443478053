import { IProjectActivity } from 'mm-types';
import React from 'react';

type PropsType = {
  entry: IProjectActivity;
};

export const Project = ({ entry }: PropsType) => {
  const projectDom: JSX.Element[] = [];
  if (entry.projectSettings.status) {
    const status = entry.projectSettings.status.charAt(0).toUpperCase() + entry.projectSettings.status.slice(1).toLowerCase();
    projectDom.push(
      <div key="status">
        <label>Status:</label> <span>{status}</span>
      </div>
    );
  }
  return <div className="settings-details">{projectDom}</div>;
};
