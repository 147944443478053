import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import { IElementSettings } from 'mm-types';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import { CONTENTS } from './TableFormattingContents';

interface Props {
  onselected(e): void;
  settings?: IElementSettings[];
}

const Formatting = (props: Props) => {
  const handleClick = (e) => {
    props.onselected && props.onselected(e);
  };

  return (
    <div className="formatting">
      {CONTENTS.map((group) => (
        <div key={group.title} className="manip-group">
          <h6>{group.title}</h6>
          <ul className="icon-action-buttons">
            {group.actions.map(({ id, icon, action, tooltip }) => {
              const disabled = !settingEnabled(id, props.settings);
              return (
                <li key={icon} data-action={action} onClick={(e) => handleClick(e)} className={disabled ? 'disabled' : ''}>
                  <IconButton
                    iconClassName={'material-icons ' + icon}
                    tooltipStyles={{ top: '70%', pointerEvents: 'none' }}
                    tooltipPosition="bottom-left"
                    tooltip={tooltip}
                    disabled={disabled}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Formatting;
