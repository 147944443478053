import { SynchronizationQueue } from './EditorStoreAddons/modes/SynchronizationQueue';
import { EditingMode } from './EditorStoreAddons/modes/EditingMode';
import { ShareEditMode } from './EditorStoreAddons/modes/ShareEditMode';
import { DiffMode } from './EditorStoreAddons/modes/DiffMode';
import { TocManMode } from './EditorStoreAddons/modes/TocManMode';
import { RegulationSelectionMode } from './EditorStoreAddons/modes/RegulationSelectionMode';
import { SpellCheckMode } from './EditorStoreAddons/modes/SpellCheckMode';
import { LayerMode } from './EditorStoreAddons/modes/LayerMode';
import { EditingBlockedMode } from './EditorStoreAddons/modes/EditingBlockedMode';
import { FindReplaceMode } from './EditorStoreAddons/modes/FindReplaceMode';
import { SmartContentLibMode } from './EditorStoreAddons/modes/SmartContentLibMode';
import { VariantMode } from './EditorStoreAddons/modes/VariantMode';

export type Banner = {
  title: string;
  className?: string;
  showClose?: boolean;
} | null;

// none, softRefresh (from memory), hardRefresh (), reload (with spinner), reloadToc ()

export type RefreshTypes =
  | 'NONE'
  | 'SOFT_REFRESH' // from memory
  | 'HARD_REFRESH' // hard reload but no page clear + spinner
  | 'RELOAD' // reload with pafe clear and spinner
  | 'RELOAD_TOC'; // toc reloads and triggers hard reload with spinner

export type MenuTypes = 'DOC_SETTINGS' | 'PREVIEW' | 'WORKFLOW';

export enum FileExportType {
  PDF,
  ZIP
}

export interface ModeAttributes {
  breadcrumb: number;
  submenuTabs: number;
  mainmenuTabs: number;
  editing: number;
  dualDocsDisplay: number;
  shareTab: number;
}

export type EditorModes =
  | 'EDITING'
  | 'EDITING_BLOCKED'
  | 'DIFF'
  | 'LAYERS'
  | 'SPELLCHECK'
  | 'FINDREPLACE'
  | 'TOCMAN'
  | 'SMARTCONTENTLIB'
  | 'SHARE_EDIT'
  | 'REGULATIONSELECTION'
  | 'VARIANT';

export type EditorModeProperties<T extends EditorModes> = T extends 'EDITING'
  ? EditingMode
  : T extends 'EDITING_BLOCKED'
  ? EditingBlockedMode
  : T extends 'DIFF'
  ? DiffMode
  : T extends 'LAYERS'
  ? LayerMode
  : T extends 'SPELLCHECK'
  ? SpellCheckMode
  : T extends 'FINDREPLACE'
  ? FindReplaceMode
  : T extends 'TOCMAN'
  ? TocManMode
  : T extends 'SMARTCONTENTLIB'
  ? SmartContentLibMode
  : T extends 'REGULATIONSELECTION'
  ? RegulationSelectionMode
  : T extends 'SHARE_EDIT'
  ? ShareEditMode
  : T extends 'VARIANT'
  ? VariantMode
  : null;

const attributes: ModeAttributes = {
  breadcrumb: 1,
  submenuTabs: 2,
  mainmenuTabs: 4,
  editing: 8,
  dualDocsDisplay: 16,
  shareTab: 32
};

const syncQueue = new SynchronizationQueue();

const _modeProperties: {
  [prop: string]: any;
} = {};

const exportModes = {
  attributes,

  getProperties<T extends EditorModes>(mode: T): EditorModeProperties<T> {
    if (!_modeProperties[mode]) {
      if (mode === 'EDITING') {
        _modeProperties[mode] = new EditingMode(attributes, syncQueue);
      } else if (mode === 'EDITING_BLOCKED') {
        _modeProperties[mode] = new EditingBlockedMode(attributes, syncQueue);
      } else if (mode === 'DIFF') {
        _modeProperties[mode] = new DiffMode(attributes, syncQueue);
      } else if (mode === 'LAYERS') {
        _modeProperties[mode] = new LayerMode(attributes, syncQueue);
      } else if (mode === 'SPELLCHECK') {
        _modeProperties[mode] = new SpellCheckMode(attributes, syncQueue);
      } else if (mode === 'FINDREPLACE') {
        _modeProperties[mode] = new FindReplaceMode(attributes, syncQueue);
      } else if (mode === 'TOCMAN') {
        _modeProperties[mode] = new TocManMode(attributes, syncQueue);
      } else if (mode === 'SMARTCONTENTLIB') {
        _modeProperties[mode] = new SmartContentLibMode(attributes, syncQueue);
      } else if (mode === 'REGULATIONSELECTION') {
        _modeProperties[mode] = new RegulationSelectionMode(attributes, syncQueue);
      } else if (mode === 'SHARE_EDIT') {
        _modeProperties[mode] = new ShareEditMode(attributes, syncQueue);
      } else if (mode === 'VARIANT') {
        _modeProperties[mode] = new VariantMode(attributes, syncQueue);
      }
    }
    return _modeProperties[mode];
  }
};

export default exportModes;
