import EffectivityProps from '../../../../sidetabs/sub/editComponent/components/EffectivityProps';
import TinyCustomKeyListener from '../../../tinyFacade/TinyCustomKeyListener';
import { CustomEditor } from '../../../tinyFacade/EditorInstanceManager';
import { EditorManager } from 'tinymce';
import { IDocUnitProfile } from 'mm-types';
declare const tinymce: EditorManager;

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Effectivity',
  getLabel: () => 'EFFECT',
  identifyingClasses: ['arc-effectivity-tag', 'arc-effectivity-tag-value'],
  outerClass: 'arc-effectivity-tag',

  targetEditContainerSelector: '>.arc-unit>.arc-effectivity-tag>div.arc-effectivity-tag-value',
  editPropsComponent: EffectivityProps,
  template: '',

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    editActions: [{ action: 'Paste', enabled: true }],
    linkActions: [{ action: 'insertLink', enabled: false }]
  },

  menuTextSelected: {
    linkActions: [{ action: 'insertLink', enabled: false }]
  },

  arcMLDefaults: {
    root: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  editor: {
    plugins: [],
    invalid_elements: '*,',
    forced_root_block: '',
    formats: {
      bold: { inline: 'span', classes: 'donothing' },
      italic: { inline: 'span', classes: 'donothing' },
      underline: { inline: 'span', classes: 'donothing' },
      forecolor: { inline: 'span', classes: 'donothing' },
      hilitecolor: { inline: 'span', classes: 'donothing' }
    },
    setup: function (editor) {
      TinyCustomKeyListener.applySingleLine(editor as CustomEditor);
    }
  },

  sanitize: function () {
    return $('<div/>')
      .text(
        tinymce.activeEditor.getContent({
          format: 'text'
        })
      )
      .html()
      .replace(/&nbsp;/gi, ' ');
  }
};
