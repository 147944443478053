import * as React from 'react';
import TextField from 'material-ui/TextField';

export type Props = {
  value?: string;
  showValidation: boolean;
  name?: string;
  readOnly?: boolean;
  onChange: (text: string) => void;
  dataQa?: String;
};

export type State = {};

export default class LinkDisplayInput extends React.Component<Props, State> {
  private _minLength: number;
  private _maxLength: number;

  constructor(props: Props) {
    super(props);
    this._minLength = 0;
    this._maxLength = 249;
  }

  isValid() {
    return this.props.value ? this.props.value.length > this._minLength : false;
  }

  errorText() {
    if (this.props.value && this.props.value.length > this._maxLength) {
      return 'Maximum characters reached';
    }
    if (this.isValid() || !this.props.showValidation) {
      return null;
    } else {
      return 'Field is mandatory';
    }
  }

  onChange(text: string) {
    if (this.props.onChange) {
      this.props.onChange(text);
    }
  }

  render() {
    return (
      <TextField
        value={this.props.value}
        ref="input"
        id="link-display-input"
        multiLine={true}
        disabled={this.props.readOnly}
        floatingLabelText={this.props.name || 'Link Display Text'}
        errorText={this.errorText()}
        onChange={(e, text) => this.onChange(text)}
        data-qa={this.props.dataQa}
      />
    );
  }
}
