import * as Reflux from 'reflux';
import * as _ from 'lodash';

import * as usersClient from '../../clients/users';
import Store from '../Store';
import { IUser } from 'mm-types';
import { Cancelled } from '../../clients/base-clients';
import { GetAllOptions } from '../../clients/users';

export type State = {
  users: IUser[];
};

export type ProjectUsersStoreEvent = State;

export class ProjectUsersStore extends Store<State> {
  private lastFetch: { projectUid: string | null; time: Date | null };

  constructor() {
    super();
    this.lastFetch = { projectUid: null, time: null };
    this.state = {
      users: []
    };
  }

  getInitialState() {
    return this.state;
  }

  getAsNamesList() {
    return _.map(this.state.users, 'displayName');
  }

  _useCache(projectUid: string) {
    return (
      projectUid === this.lastFetch.projectUid && this.lastFetch.time && Date.now() - this.lastFetch.time.getMilliseconds() < 1000 * 120
    );
  }

  // Event Handlers

  async retrieve({ projectUid, query, page, size }: Partial<GetAllOptions>) {
    if (this._useCache(projectUid!)) {
      console.debug('Overriding request for new Users call (Less than 2 minutes) and returning previous');
      const e: ProjectUsersStoreEvent = this.state;
      this.trigger(e);
    } else {
      const users = await usersClient.getUsers({ projectUid, query, page, size });
      if (users instanceof Cancelled) {
        return;
      }

      this.lastFetch.time = new Date();
      this.lastFetch.projectUid = projectUid!;
      this.state = { users: users };
      const e: ProjectUsersStoreEvent = this.state;
      this.trigger(e);
      return this.state.users;
    }
  }
}

const singleton = Reflux.initStore<ProjectUsersStore>(ProjectUsersStore);
export default singleton;
