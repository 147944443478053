import React from 'react';
import Slider from 'material-ui/Slider';
import DocumentVisualStates, { VisualStates, VisualStatesID } from '../utils/DocumentVisualStates';
import { IconMenu, MenuItem, Divider, FlatButton, Popover } from 'material-ui';
import { isFeatureOn } from '../../featureSwitch/featureSwitchUtils';
import EditorStore from '../../../flux/editor/EditorStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import { PROJECT_DEFINITION_TYPES } from '../../../flux/common/ProjectDefinitionStore';
import { NavigationArrowDropRight } from 'material-ui/svg-icons';
import { isEscKey } from '../utils/keyIdentifier';

export type Props = {
  onAction: (type: VisualStates, isChecked: boolean) => void;
  onZoom: (num: number) => void;
  docVisualStates: VisualStates[];
};

type Actions = (VisualStates | null)[];

type SubMenuOpenState = {
  name: string;
  anchorEl: React.ReactInstance;
  subMenuKeys: Actions;
};

export function FooterActions({ onAction, onZoom, docVisualStates }: Props) {
  const [isMenuShowing, setIsMenuShowing] = React.useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = React.useState<SubMenuOpenState | null>();
  const actions: Actions = makeActions();

  const currentMode = EditorStore.getMode();

  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown, false);
    return () => window.removeEventListener('keydown', onKeyDown, false);
  }, []);

  React.useEffect(() => {
    if (currentMode !== 'EDITING') {
      onAction(VisualStatesID.HIGHLIGHT_LAYER2, false);
      onAction(VisualStatesID.HIGHLIGHT_LAYER3, false);
    }
  }, [currentMode]);

  function onKeyDown(e) {
    if (isEscKey(e)) {
      setIsMenuShowing(false);
      setSubMenuOpen(null);
    }
  }

  function makeActions(): Actions {
    const project = ProjectStore.getProject();
    const baseActions = DocumentVisualStates.keys.filter((baseAction) => {
      if (project?.definitionName !== PROJECT_DEFINITION_TYPES.airbus && baseAction == VisualStatesID.SHOW_LAYERS) {
        return false;
      }
      if (project?.definitionName === PROJECT_DEFINITION_TYPES.airbus && baseAction == VisualStatesID.SHOW_ELEMENT_PRINT_OUTPUT) {
        return false;
      }
      return true;
    });

    return isFeatureOn('searchTags') && !baseActions.indexOf(VisualStatesID.SHOW_SEARCH_TAGS)
      ? [...baseActions, VisualStatesID.SHOW_SEARCH_TAGS]
      : baseActions;
  }

  const handleZoom = (e: React.MouseEvent<{}>, value: number) => {
    onZoom(value / 2);
  };

  const preventEditorBlurAndSilentRefocus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    EditorStore.getEditor().silentReFocus();
  };

  const isMenuDisabled = (visualState: String) => {
    return currentMode !== 'EDITING' && visualState == 'SHOW_LAYERS';
  };

  const onMenuItemClick = (e, visualState) => {
    const isSubMenuHandled = handleSubMenu(e, visualState);
    if (isSubMenuHandled) {
      return;
    }
    const selected = docVisualStates.indexOf(visualState) !== -1;
    onAction(visualState, !selected);
  };

  const handleSubMenu = (e, visualState) => {
    const documentVisualStateProps = DocumentVisualStates.props.get(visualState);
    if (documentVisualStateProps?.subMenu) {
      if (subMenuOpen?.name == documentVisualStateProps?.title) {
        setSubMenuOpen(null);
      } else {
        setSubMenuOpen({
          name: documentVisualStateProps?.title,
          anchorEl: e.currentTarget as React.ReactInstance,
          subMenuKeys: [...documentVisualStateProps?.subMenu?.keys()]
        });
      }
      return true;
    }
    return false;
  };

  const getActionMenuItems = (visualStates?: Actions) => {
    if (!visualStates) {
      return null;
    }

    return visualStates?.map((visualState, index) => {
      if (visualState === null) {
        return <Divider key={`divider-${index}`} />;
      }
      if (typeof visualState === 'undefined') {
        return null;
      }

      const documentVisualStateProps = DocumentVisualStates.props.get(visualState);

      return (
        <MenuItem
          onClick={(e) => onMenuItemClick(e, visualState)}
          onMouseEnter={(e) => handleSubMenu(e, visualState)}
          style={{ fontSize: '15px', minHeight: '40px', margin: '', lineHeight: '44px' }}
          id={visualState.toLocaleLowerCase()}
          insetChildren={true}
          className={`footer-menu-action`}
          key={`option-${index}`}
          value={visualState}
          innerDivStyle={{ padding: '0px 16px 0px 60px' }}
          checked={docVisualStates.indexOf(visualState) !== -1}
          primaryText={documentVisualStateProps?.title}
          rightIcon={documentVisualStateProps?.subMenu ? <NavigationArrowDropRight /> : <></>}
          disabled={isMenuDisabled(visualState)}
        />
      );
    });
  };

  return (
    <div className="footer-actions" style={{ zIndex: isMenuShowing ? 999 : 9 }} onClick={preventEditorBlurAndSilentRefocus}>
      <div className="footer-zoom">
        <i className="tiny material-icons">zoom_out</i>
        <Slider
          name="zoom-slider"
          defaultValue={2}
          step={0.5}
          min={1.5}
          max={2.5}
          style={{ float: 'left', width: '177px' }}
          sliderStyle={{ marginTop: 0 }}
          onChange={(e, value) => handleZoom(e, value)}
        />
        <i className="tiny material-icons">zoom_in</i>
      </div>

      <IconMenu
        style={{ lineHeight: '32px', float: 'right', width: '90px' }}
        onRequestChange={(e, reason) => {
          if (reason !== 'itemTap') {
            setIsMenuShowing(e);
          }
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={isMenuShowing}
        useLayerForClickAway={true}
        iconButtonElement={
          <FlatButton hoverColor="transparent">
            <i className="icon-display-options tiny material-icons" onClick={() => setIsMenuShowing(true)}>
              remove_red_eye
            </i>
            Show
          </FlatButton>
        }
      >
        {getActionMenuItems(actions)}
      </IconMenu>
      <Popover
        open={!!subMenuOpen}
        anchorEl={subMenuOpen?.anchorEl}
        anchorOrigin={{ horizontal: 'middle', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={(e) => {
          setSubMenuOpen(null);
        }}
        style={{ marginLeft: '115px' }}
      >
        {getActionMenuItems(subMenuOpen?.subMenuKeys)}
      </Popover>
    </div>
  );
}
