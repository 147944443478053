import * as React from 'react';
import { useRef } from 'react';
import * as ReactDOM from 'react-dom';
import TocSiblingDropPoint, { TocManipulationEvent } from './TocSiblingDropPoint';
import TocChildDropPoint from './TocChildDropPoint';
import EditorStore from '../../../../../flux/editor/EditorStore';
import TocStore from '../../../../../flux/editor/TocStore';
import SmartContentStore from '../../../../../flux/editor/SmartContentStore';
import { isChildOf } from './tocutils';
import { ITocNode } from 'mm-types';
import { SharedIndexMap } from '../../../../../clients/shared-content';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

export type Props = {
  node: ITocNode;
  selectedNodeUid: string | null;
  openNodes: Set<string>;
  level: number;
  editMode: boolean;
  maxDepth: number;
  onToggle: (node: ITocNode) => void;
  onToggleTree: (node: ITocNode) => void;
  onSelect: (node) => void;
  onTocManipulation: TocManipulationEvent;
};

const TocTree = (props: Props) => {
  const timerRef = useRef<number>();
  const tocEntryRef = useRef<HTMLDivElement>(null);

  function getVariantIconType() {
    const node = props.node;
    if (node) {
      if (node.variantType === 'OWNER') {
        return 'icon-variant-indicator';
      } else if (node.variantType === 'INHERITED') {
        return 'icon-variant';
      }
    }
  }

  const isSelected = () => {
    return props.selectedNodeUid === props.node.uid && !props.node.neverHighlightOnSelect;
  };

  const onClick = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => props.onSelect(props.node), 250);
  };

  const onDoubleClick = () => {
    clearTimeout(timerRef.current);
    props.onToggleTree(props.node);
  };

  const canDrag = () => {
    const isFrontmatterMovable = ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition?.allowMovableFrontmatter;
    return props.editMode && (!isFrontMatter() || isFrontmatterMovable) && !ProjectDefinitionStore.isCurrentProjectDefinitionAirbus();
  };

  const isFrontMatter = () => {
    return props.node.type.indexOf('frontmatter') > -1;
  };

  const onDrag = (action: 'start' | 'end', e: React.DragEvent<HTMLElement>) => {
    if (canDrag()) {
      if (action === 'start') {
        props.onTocManipulation('startDrag', { node: props.node });
        (e.target as HTMLElement).classList.add('toc-dragging');
      } else {
        (e.target as HTMLElement).classList.remove('toc-dragging');
      }
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    if (props.editMode) {
      const droppedItem = TocStore.getDroppedItem();
      const dropTarget = props.node;
      if (isChildOf(droppedItem, dropTarget) && !isOpen()) {
        props.onToggle(props.node);
      }
    }
  };

  const isOpen = () => {
    return props.openNodes.has(props.node.uid);
  };

  const hover = (on) => {
    if (on) {
      (ReactDOM.findDOMNode(tocEntryRef.current) as Element).classList?.add('hover');
    } else {
      (ReactDOM.findDOMNode(tocEntryRef.current) as Element).classList.remove('hover');
    }
  };

  const getBulletClass = () => {
    const base = 'toc-bullet';

    if (props.node.children && props.node.children.length > 0) {
      return base + ' icon-menu-bullet-' + (isOpen() ? 'opened' : 'closed');
    } else {
      return base;
    }
  };

  const getAdditionalClass = () => {
    if (props.node.definitionId === 'psl') {
      return ' psl';
    }
    if (props.node.definitionId === 'group') {
      return ' group';
    }
    if (props.node.definitionId === 'invariant') {
      return ' invariant';
    }
    return '';
  };

  const titleMargin = () => {
    if (getNodeUpdateCountInfo() && getVariantIconType()) {
      return '2%';
    } else if (getNodeUpdateCountInfo() || getVariantIconType()) {
      return '4%';
    } else {
      return '0%';
    }
  };

  const getNodeUpdateCountInfo = (nodeUid?: string) => {
    let toReturn: { [name: string]: SharedIndexMap } = {};

    if (EditorStore.isMode('DIFF')) {
      const tocMap = TocStore.getTocDiffMap() || {};

      Object.keys(tocMap).forEach((key) => {
        toReturn![key] = { count: tocMap[key], className: '' };
      });
    } else {
      toReturn = SmartContentStore.getUpdatedUsagesTocMap();
    }

    const updateInfo = toReturn[nodeUid ? nodeUid : props.node.uid];
    return !!updateInfo ? updateInfo : false;
  };

  const getName = () => {
    const ordinal = props.node.ordinal ? props.node.ordinal + ' ' : '';
    const heading = props.node.parsedHeading ? props.node.parsedHeading : props.node.heading ? props.node.heading : 'untitled';
    return `${ordinal} ${heading}`;
  };

  const renderUpdateCount = () => {
    const updateCountInfo = getNodeUpdateCountInfo();

    if (updateCountInfo) {
      if (!isOpen()) {
        // closed node
        return <span className={'toc-update-count ' + updateCountInfo.className}>{updateCountInfo.count}</span>;
      } else if (props.node.children && props.node.children.length > 0) {
        // Open Node with children - compare Node's update count to children's sum of update counts
        const childrenUpdateCount = props.node.children.reduce((totalUpdateCount, child) => {
          const info = getNodeUpdateCountInfo(child.uid);
          return info ? info.count! + totalUpdateCount : totalUpdateCount;
        }, 0);

        if (updateCountInfo && updateCountInfo.count! > childrenUpdateCount) {
          // Parent has changes not reflected by children's update counts
          return <span className={'toc-update-count ' + updateCountInfo.className}>{updateCountInfo.count! - childrenUpdateCount}</span>;
        }
      } else {
        // Opened Node with no children - just show update count
        return <span className={'toc-update-count ' + updateCountInfo.className}>{updateCountInfo.count}</span>;
      }
    }
  };

  const renderVariantIcon = () => {
    const variantIcon = getVariantIconType();

    if (variantIcon) {
      return <i className={'variant-tag material-icon ' + variantIcon} />;
    }
  };

  const renderDropPoints = () => {
    if (props.editMode) {
      let childDropPoint: JSX.Element | null = null;
      if (props.node.children && props.node.children.length === 0) {
        childDropPoint = <TocChildDropPoint {...props} />;
      }
      return (
        <div>
          <TocSiblingDropPoint {...props} position="above" />
          <TocSiblingDropPoint {...props} position="below" />
          {childDropPoint}
        </div>
      );
    }
  };

  const renderChildren = () => {
    const shouldRenderChildren = props.editMode || props.maxDepth >= props.level;

    if (shouldRenderChildren && props.node.children && props.node.children.length > 0 && isOpen()) {
      return props.node.children.map((childNode) => {
        return <TocTree {...props} key={childNode.uid} node={childNode} level={props.level + 1} />;
      });
    } else {
      return '';
    }
  };

  return (
    <div key={props.node.uid} data-uid={props.node.uid} className={'toc-entry-container ' + (isSelected() ? 'selected' : '')}>
      <div
        className="toc-entry"
        ref={tocEntryRef}
        style={{ paddingLeft: `${props.level * 4}px` }}
        onClick={() => onClick()}
        onDoubleClick={() => onDoubleClick()}
        draggable={canDrag()}
        onDragStart={(e) => onDrag('start', e)}
        onDragEnd={(e) => onDrag('end', e)}
        onDragOver={(e) => onDragOver(e)}
        onMouseOver={() => hover(true)}
        onMouseOut={() => hover(false)}
      >
        <div
          className={getBulletClass()}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onToggle(props.node);
          }}
        />
        <div className="toc-title">
          <div className={'toc-name' + getAdditionalClass()} style={{ marginRight: titleMargin() }}>
            <div dangerouslySetInnerHTML={{ __html: getName() }}></div>
          </div>
          {renderUpdateCount()}
          {renderVariantIcon()}
        </div>
      </div>
      {renderDropPoints()}
      {renderChildren()}
    </div>
  );
};

export default TocTree;
