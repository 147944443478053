import * as React from 'react';
import { NNCIndexMetadataID } from '../../NNCIndexMetadataTypes';
import { NNCMetadataComponentProps } from '../MetadataComponentTypes';
import { useArrayValue } from '../../useArrayValues';
import EffectivityMetadataForm from './EffectivityMetadataForm';
import { AeroIconButtonAdd } from '../../../../../../general/AeroIconButton';
import { useEffectivityElementDefinition } from '../../useEffectivityElementDefinition';
import { Divider } from 'material-ui';
import { EffectivityValue } from '../../effectivity/metadataDefinition';
import { TextField } from 'material-ui';
import keyDown from '../../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
import refFocus from '../../../../tinyFacade/refFocus';
const AddButton = React.memo(AeroIconButtonAdd);

type Props = NNCMetadataComponentProps<NNCIndexMetadataID.EFFECTIVITY>;

export function EffectivityMetadata({ values, onChange, onTab, currentRef }: Props) {
  const { localValues, addValue, updateValue, removeValue } = useArrayValue<Props['values']>(values, onChange);
  const effectivityDefinitions = useEffectivityElementDefinition();
  const firstInputRef = React.useRef<TextField | null>(null);
  const lastInputRef = React.useRef<TextField | null>(null);

  function addNewValue() {
    addValue([
      {
        text: '',
        type: effectivityDefinitions[0].subType!
      }
    ]);
    focusLastInput();
  }

  function removeValueAtIndex(index: number) {
    removeValue(index);
    if (!localValues.length && currentRef) {
      refFocus({
        ref: currentRef
      }).catch((e) => console.warn(e));
    }
  }

  function updateValueAtIndex(value: EffectivityValue, index: number) {
    updateValue([value], index);
  }

  function focusLastInput() {
    refFocus({
      ref: lastInputRef
    }).catch((e) => console.warn(e));
  }

  return (
    <div>
      <div className="show-form-action">
        <AddButton
          onKeyDown={(e) => keyDown(e, onTab, currentRef, !!localValues.length)}
          onClick={addNewValue}
          id="add-effectivity-button"
          data-qa="add-effectivity-button"
          tooltip="Add Effectivity"
          tooltipPosition="top-left"
          ref={currentRef}
        />
      </div>
      <ul className="position-relative margin-top-none">
        <li data-qa="text">
          {!localValues.length && (
            <div className="font-gray font-size-12" onClick={addNewValue}>
              {"Select '+' to add Effectivity."}
            </div>
          )}
        </li>

        {localValues.map((value, index) => (
          <li key={index}>
            <Divider />
            <div className="margin-vertical-m">
              <EffectivityMetadataForm
                onChange={updateValueAtIndex}
                definitions={effectivityDefinitions}
                value={value}
                index={index}
                onRemove={removeValueAtIndex}
                ref={(ref) => {
                  if (index === 0) {
                    firstInputRef.current = ref!;
                  }
                  if (index === localValues.length - 1) {
                    lastInputRef.current = ref!;
                  }
                }}
                isLastEffectivity={index === localValues.length - 1}
                onTab={onTab}
                currentRef={currentRef}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
