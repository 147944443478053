import * as React from 'react';
import { AeroIconButtonEdit } from '../../../../general/AeroIconButton';
import { IMedia } from 'mm-types';

interface Props {
  media: IMedia;
  editMedia(): any;
}

const DescriptionPreview = (props: Props) => {
  return (
    <div className="position-relative" onClick={props.editMedia}>
      <div className="description-preview">
        <label>Description</label>
        {props.media.description ? <span>{props.media.description}</span> : <span className="color-gray">Add a description</span>}
        <AeroIconButtonEdit id="edit" tooltip="Edit" tooltipPosition="top-center" />
      </div>
    </div>
  );
};

export default DescriptionPreview;
