import * as React from 'react';
import { ILink, IMedia } from 'mm-types';
import AnimatedToggleSection from '../../../../misc/AnimatedToggleSection';
import AreaProgress from '../../../../general/AreaProgress';

interface Props {
  media: IMedia;
  isFetching: boolean;
  onLinkClick(ref: ILink): void;
}

const Usages = (props: Props) => {
  const { media, onLinkClick, isFetching } = props;
  const hasInstances = media.references && media.references.length;
  return (
    <AnimatedToggleSection
      title={`USAGES (${hasInstances ? media.references.length : 0})`}
      subtitle="All usages of this media in this revision"
      id="details-instances"
    >
      <div className="position-relative">
        <div style={{ paddingTop: 12 }}>
          {!hasInstances ? (
            <div className="empty-list">NO USAGES</div>
          ) : (
            <ul style={{ margin: 0 }}>
              {media.references.map((ref, refIndex) => {
                return (
                  <li
                    key={refIndex}
                    onClick={() => {
                      onLinkClick(ref);
                    }}
                  >
                    {ref.tocableUnitOrdinal} {ref.tocableUnitHeading}: {ref.caption ? ref.ordinal + ' ' + ref.caption : '(no caption)'}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <AreaProgress show={isFetching} opacity={1} />
      </div>
    </AnimatedToggleSection>
  );
};

export default Usages;
