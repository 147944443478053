import * as React from 'react';
import * as _ from 'lodash';
import { IMedia } from 'mm-types';
import DescriptionPreview from './DescriptionPreview';
import DescriptionForm from './DescriptionForm';
import VerticalSlide from '../../../../general/animations/VerticalSlide';

interface Props {
  media: IMedia;
  isEditing: boolean;
  onEdit(): any;
  onFormCancel(): any;
  onFormSubmit(media: IMedia): any;
}

interface State {
  formValue: string;
  startAnimationHeight: number;
}

export default class Description extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formValue: '',
      startAnimationHeight: 42
    };
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
  }

  componentDidMount() {
    this.resetStateFromProperties();
  }

  private handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      formValue: e.target.value
    });
  }

  private handleFormSubmit(el: Element | null) {
    this.setAnimationHeight(el);
    if (this.props.onFormSubmit) {
      this.props.onFormSubmit(this.updatedMedia);
    }
  }

  private handleFormCancel(el: Element | null) {
    this.setAnimationHeight(el);
    this.resetStateFromProperties();
    if (this.props.onFormCancel) {
      this.props.onFormCancel();
    }
  }

  private resetStateFromProperties() {
    this.setState({
      formValue: this.props.media.description
    });
  }
  private setAnimationHeight(el: Element | null) {
    if (el) {
      this.setState({
        startAnimationHeight: el.clientHeight || 42
      });
    }
  }

  private get updatedMedia(): IMedia {
    return _.extend(JSON.parse(JSON.stringify(this.props.media)), { description: this.state.formValue });
  }

  render() {
    const { isEditing, media } = this.props;
    const { formValue, startAnimationHeight } = this.state;
    return (
      <div className="description-section">
        {!isEditing ? (
          <div style={{ marginTop: 15 }}>
            <VerticalSlide fromHeight={startAnimationHeight}>
              <DescriptionPreview media={media} editMedia={this.props.onEdit} />
            </VerticalSlide>
          </div>
        ) : (
          <DescriptionForm
            value={formValue}
            onFormChange={this.handleDescriptionChange}
            onFormSubmit={this.handleFormSubmit}
            onFormCancel={this.handleFormCancel}
          />
        )}
      </div>
    );
  }
}
