import * as React from 'react';
import * as _ from 'lodash';
// import tocSelectorService from './tocSelectorService';
import { ITocNode, IUnitConceptMap } from 'mm-types';
import { IConceptMap } from '../../../clients/concepts';
import FontIcon from 'material-ui/FontIcon';

export type Props = {
  section: ITocNode;
  conceptTags?: IConceptMap[];
  selectedVariantsMap?: IUnitConceptMap; // { [ name: string ]: IUnitVariantsMap };
  opened?: boolean;
  selectedUid?: string;
  allowVariantSelection?: boolean;
  variantsMustMatch?: boolean;
  onSelect: (toc: ITocNode, hasVariantsApplied: boolean) => void;
  isDuRefLink?: boolean;
};

export type State = {
  opened: boolean;
};

export default class TocSelectorSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const opened = this._childrenContainSelectedUnit() && this.props.selectedUid !== this.props.section.uidnid;
    this.state = { opened: !!opened };
  }

  _childrenContainSelectedUnit(start: ITocNode = this.props.section) {
    if (this.props.selectedUid === start.uidnid) {
      return true;
    }
    if (start.children) {
      return _.find(start.children, this._childrenContainSelectedUnit.bind(this));
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.opened === true) {
      this.setState({
        opened: true
      });
    }
  }

  componentDidMount() {
    if (this.props.selectedUid === this.props.section.uidnid) {
      if (this.props.onSelect) {
        this.props.onSelect(this.props.section, this.hasVariantsApplies());
      }
    }
  }

  _onClickBullet(e: React.MouseEvent<HTMLElement>) {
    this.setState({ opened: !this.state.opened });
    e.preventDefault();
    e.stopPropagation();
  }

  _displayName() {
    let type = '';
    if (['TABLE', 'GRAPHIC', 'PARAGRAPH'].indexOf(this.props.section.type) !== -1) {
      type = this.props.section.type + ': ';
    }
    return type + (this.props.section.heading || 'Unnamed');
  }

  _onClickText(e: React.MouseEvent<HTMLElement>) {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.section, this.hasVariantsApplies());
    }

    e.preventDefault();
    e.stopPropagation();
  }

  // name() {
  //   return tocSelectorService.displayName( this.props.section, true );
  // }

  hasVariantsApplies() {
    if (!this.props.conceptTags) {
      return false;
    }

    const val = this.props.conceptTags.find((conceptMap: IConceptMap) => {
      if (conceptMap.unitUid === this.props.section.uid) {
        return true;
      } else {
        return false;
      }
    });

    return val ? true : false;
  }

  hasSameVariantsApplies(sectionUid: string) {
    const sourceVariantMap = this.props.selectedVariantsMap;

    if (!this.props.conceptTags && !sourceVariantMap) {
      return true;
    }

    let unitDoesHaveVariants = false;

    const val = this.props.conceptTags!.find((conceptMap: IConceptMap) => {
      // Get the variants for the selected section/chapter
      if (conceptMap.unitUid === sectionUid) {
        let selectedChapterHasVariant = false;
        unitDoesHaveVariants = true;

        if (conceptMap.variants.length === 0) {
          return true;
        }

        if (!sourceVariantMap && conceptMap.variants.length > 0) {
          return false;
        }

        if (conceptMap.variants.length === 0) {
          return true;
        }

        // Now make sure section has the same variants applied
        // as the source units variants
        for (let i = 0, l = sourceVariantMap!.variants.length; i < l; i++) {
          const selectedVariantMap = sourceVariantMap!.variants[i];
          const sectionVariant = conceptMap.variants.filter((e) => e.uid === selectedVariantMap.uid!);
          if (sectionVariant && sectionVariant.length > 0) {
            selectedChapterHasVariant = true;
            break;
          }
        }

        return selectedChapterHasVariant;
      } else {
        return false;
      }
    });

    if (!unitDoesHaveVariants) {
      return true;
    }

    return val ? true : false;
  }

  render() {
    let children: React.ReactNode[] = [];
    if (this.state.opened && this.props.section.children) {
      children = this.props.section.children.map((ts) => {
        return (
          <TocSelectorSection
            conceptTags={this.props.conceptTags}
            allowVariantSelection={this.props.allowVariantSelection}
            variantsMustMatch={this.props.variantsMustMatch}
            selectedVariantsMap={this.props.selectedVariantsMap}
            onSelect={(toc, hasVariantsApplied) => this.props.onSelect(toc, hasVariantsApplied)}
            selectedUid={this.props.selectedUid}
            section={ts}
            key={ts.uidnid}
            isDuRefLink={this.props.isDuRefLink}
          />
        );
      });
    }

    let img: JSX.Element | null = null;
    if (
      this.props.section.type.toLowerCase() !== 'graphic' &&
      this.props.section.type.toLowerCase() !== 'table' &&
      this.props.section.children &&
      this.props.section.children.length > 0
    ) {
      img = (
        <img src={'/assets/images/icon-menu-bullet-' + (this.state.opened ? 'opened.png' : 'closed.png')} className="allowClick" alt="" />
      );
    }

    const sectionType = this.props.section.level ? this.props.section.level!.toUpperCase() : this.props.section.type.toUpperCase();
    let iconClass = '';
    switch (sectionType) {
      case 'TABLE': {
        iconClass = 'border_all';
        break;
      }
      case 'ANCHOR': {
        iconClass = 'icon-inbound-link';
        break;
      }
      case 'GRAPHIC':
      case 'VIDEO': {
        iconClass = 'insert_photo';
        break;
      }
      default: {
        // intentionally nothing
        break;
      }
    }

    let ordinalText = '';
    if (this.props.section.ordinal !== undefined && this.props.section.ordinal !== null) {
      ordinalText = this.props.section.ordinal;
    }

    const selected = this.props.selectedUid === this.props.section.uidnid;
    let disabled = false;

    if (this.props.allowVariantSelection) {
      if (this.props.variantsMustMatch && !this.hasSameVariantsApplies(this.props.section.uid)) {
        disabled = true;
      }
    }

    if (this.props.isDuRefLink && this.props.section.level !== 'chapter') {
      disabled = true;
    }

    return (
      <div className="toc-entry-container">
        {/* single row */}
        <div className={'toc-entry allowClick' + (selected ? ' selected' : '') + (' section-type-' + sectionType)} ref="tocEntry">
          {/* bullet img: 'v' or '>' */}
          <div className="bullet-arrow" onClick={(e) => this._onClickBullet(e)}>
            {img}
          </div>

          {/* link icon */}
          {iconClass.length > 0 ? (
            <FontIcon className={'material-icons ' + iconClass} style={{ color: '#aaaaaa', fontSize: 14, marginRight: 5, marginTop: 3 }}>
              {iconClass.startsWith('icon') ? '' : iconClass}
            </FontIcon>
          ) : (
            ''
          )}

          {/* clickable link selector, could wrap multiple lines */}
          <div className={'toc-row-clickable' + (disabled ? ' disabled' : '')} onClick={(e) => this._onClickText(e)}>
            {/* ordinal number */}
            {ordinalText.length > 0 ? <span className="ordinalText">{ordinalText} </span> : ''}
            {/* title text */}
            <span className="nameText" dangerouslySetInnerHTML={{ __html: this._displayName() }} />
          </div>
        </div>
        {/* children subtree */}
        {children}
      </div>
    );
  }
}
