import * as React from 'react';
import Document from './Document';
import EditorModes from '../../../flux/editor/EditorModes';
import SecondaryDocumentContainer from './SecondaryDocumentContainer';
import EditorStore from '../../../flux/editor/EditorStore';
import ProjectStore from '../../../flux/editor/ProjectStore';
import { IUnit, IEditorStoreEvent } from 'mm-types';
import { VisualStates } from '../utils/DocumentVisualStates';

const _DRAGEVENT_CLASSNAME = 'undergoing-drag-event';

export type Props = {
  docUnits: IUnit[] | null;
  startUnitUid: string;
  preventKeyDetection: boolean;
  isStyleLoaded: boolean;
  onVisualStateChange: (newState: VisualStates, newStateOn: boolean) => void;
  openLinkModal: (isDuRef: boolean) => void;
  openLinkAnchorModal: () => void;
};

export type State = {};

export default class DocumentStage extends React.Component<Props, State> {
  private _primaryDocContainerEl: HTMLElement | null;
  private _secondaryDocContainerEl: HTMLElement | null;
  private _resizeHandleEl: HTMLElement | null;
  private _startX: number;
  private _startWidth: number;
  private _unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this._primaryDocContainerEl = null;
    this._secondaryDocContainerEl = null;
    this._resizeHandleEl = null;
    this._startX = 0;
    this._startWidth = 0;

    this._unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
    this._initContainerResize = this._initContainerResize.bind(this);
    this._doDrag = this._doDrag.bind(this);
    this._stopDrag = this._stopDrag.bind(this);
    this.state = {};
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<'changeModeComplete'>) {
    if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;

      if (EditorModes.getProperties(castEvent.data!.from!).isAllowed(EditorModes.attributes.dualDocsDisplay)) {
        if (this._primaryDocContainerEl) {
          this._primaryDocContainerEl.removeAttribute('style');
        }
        this._removeDomDragListeners();
      }
    } else if (e.type === 'initEditor') {
      // ensure on any new launches (revision switching) style is removed (as on init changemode is silent)
      if (this._primaryDocContainerEl) {
        this._primaryDocContainerEl.removeAttribute('style');
      }
    }
  }

  componentWillUnmount() {
    this._removeDomDragListeners();
    this._unsubscribe();
  }

  componentDidMount() {
    this._resetDomDragListeners();
  }

  componentDidUpdate() {
    this._resetDomDragListeners();
  }

  allowInefficientUpdate(unitUid?: string) {
    (this.refs.primaryDocumentEditor as Document).allowInefficientUpdate(unitUid);
  }

  allowInefficientPartUpdate(partType) {
    (this.refs.primaryDocumentEditor as Document).allowInefficientUpdate(partType, 'unitComponentType');
  }

  _initContainerResize(e) {
    this._startX = e.clientX;
    this._startWidth = parseInt(document.defaultView!.getComputedStyle(this._primaryDocContainerEl!).width!, 10);
    if (document.documentElement) {
      document.documentElement.addEventListener('mousemove', this._doDrag, false);
      document.documentElement.addEventListener('mouseup', this._stopDrag, false);
    }
  }

  _isDualDisplay() {
    return EditorStore.doesModeAllow(EditorModes.attributes.dualDocsDisplay);
  }

  _resetDomDragListeners() {
    // revert to *real* DOM for this so no re-rendering overhead on editor to worry about
    this._removeDomDragListeners();

    this._resizeHandleEl = document.querySelector('.editing-stage-drag-bar .dragbar')! as HTMLElement;
    if (this._resizeHandleEl) {
      this._primaryDocContainerEl = document.querySelector('.editing-stage-page-primary')! as HTMLElement;
      this._secondaryDocContainerEl = document.querySelector('.editing-stage-page-secondary')! as HTMLElement;
      this._resizeHandleEl.addEventListener('mousedown', this._initContainerResize, false);
    }
  }

  _removeDomDragListeners() {
    if (this._resizeHandleEl) {
      this._resizeHandleEl.removeEventListener('mousedown', this._initContainerResize, false);
      this._resizeHandleEl = null;
    }
  }

  _doDrag(e) {
    if (document.body.classList) {
      document.body.classList.add(_DRAGEVENT_CLASSNAME);
    } else {
      document.body.className += ' ' + _DRAGEVENT_CLASSNAME;
    }

    const newWidth = this._startWidth + e.clientX - this._startX;

    // set absolute size and drop default 50% css rule

    this._primaryDocContainerEl!.setAttribute(
      'style',
      'width: ' + newWidth + 'px!important; max-width: ' + this._maxWidth() + 'px !important; min-width: 200px; flex-shrink: 0 !important'
    );
    this._secondaryDocContainerEl!.setAttribute('style', 'max-width: none!important; min-width: 200px;');
  }

  _maxWidth(): number {
    return this._primaryDocContainerEl!.offsetWidth + this._secondaryDocContainerEl!.offsetWidth - 200;
  }

  _stopDrag() {
    if (document.body.classList) {
      document.body.classList.remove(_DRAGEVENT_CLASSNAME);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + _DRAGEVENT_CLASSNAME.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }

    if (document.documentElement) {
      document.documentElement.removeEventListener('mousemove', this._doDrag, false);
      document.documentElement.removeEventListener('mouseup', this._stopDrag, false);
    }

    (this.refs.primaryDocumentEditor as Document).notifyOfContentResize();
  }

  _resetContainerSize() {
    this._primaryDocContainerEl!.removeAttribute('style');
    this._secondaryDocContainerEl!.removeAttribute('style');
  }

  render() {
    return (
      <div className="col s8 editing-stage">
        <Document
          docUnits={this.props.docUnits}
          startUnitUid={this.props.startUnitUid}
          project={ProjectStore.getProject()}
          isStyleLoaded={this.props.isStyleLoaded}
          preventKeyDetection={this.props.preventKeyDetection}
          onVisualStateChange={(newState, newStateOn) => this.props.onVisualStateChange(newState, newStateOn)}
          showHeader={this._isDualDisplay() ? true : false}
          ref="primaryDocumentEditor"
          openLinkModal={this.props.openLinkModal}
          openLinkAnchorModal={this.props.openLinkAnchorModal}
        />
        {this._isDualDisplay() ? (
          <div className="editing-stage-page editing-stage-drag-bar" onDoubleClick={(e) => this._resetContainerSize()}>
            <div className="dragbar icon-resize-handle" />
            <div className="col s12 editing-footer" />
          </div>
        ) : null}
        {EditorStore.isMode('SMARTCONTENTLIB') || EditorStore.isMode('REGULATIONSELECTION') ? (
          <SecondaryDocumentContainer
            onChange={() => {
              this._resetDomDragListeners();
            }}
          />
        ) : null}
      </div>
    );
  }
}
