import { mm, exemptStatusCodes, ClientError, Cancelled } from './base-clients';
import { IOrganisation } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import { CacheClient } from '../utils/CacheClient';

let getAllSource: CancelTokenSource | null = null;

export type GetAllOptions = {
  activeOnly: true;
  canAdministerOnly: boolean;
  isMemberOf: boolean;
};

export async function getOne(uid: string) {
  const response = await mm.get<IOrganisation>(`/orgs/${uid}`);
  return response.data;
}

/**
 * Gets all organisations for given options
 */
export async function getAll(options: Partial<GetAllOptions>): Promise<IOrganisation[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const query: string[] = [];
    if (options.activeOnly) {
      query.push('activeOnly=true');
    }
    if (options.canAdministerOnly) {
      query.push('canAdministerOnly=true');
    }
    if (options.isMemberOf) {
      query.push('isMemberOf=true');
    }

    const response = await mm.get<{ orgs: IOrganisation[] }>(`/orgs?${query.join('&')}`);
    let orgs = response.data.orgs || [];
    getAllSource = null;

    // Create the users array on the each org
    orgs = orgs.map((g) => {
      return { ...g, users: [] };
    });
    return orgs;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Updates an organisation
 */
export async function updateOne(uid: string, token: Partial<IOrganisation>) {
  const response = await mm.put<IOrganisation>(`/orgs/${uid}`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Org already exists');
  }

  return response.data;
}

/**
 * Creates a new organisation
 */
export async function create(token: Partial<IOrganisation>) {
  const response = await mm.post<IOrganisation>(`/orgs`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Org already exists');
  }

  return response.data;
}

/**
 * Removes an organisation by id
 */
export async function remove(uid: string) {
  const response = await mm.delete(`/orgs/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 412) {
    throw new ClientError(response.status, 'Org in use');
  }
}
