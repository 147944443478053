import { IDocUnitProfile } from 'mm-types';
import GenericEditProps from '../../../../sidetabs/sub/editComponent/components/GenericEditProps';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Action',
  getLabel: () => 'ACT',
  identifyingClasses: [
    'arc-action',
    'arc-action-challenge',
    'arc-action-challenge-response',
    'arc-action-challenge-response-message',
    'arc-part'
  ],
  outerClass: 'arc-action',
  openEditPaneOnSelect: true,
  targetEditContainerSelector: '>.arc-unit>.arc-action',
  editPropsComponent: GenericEditProps,
  template: '',

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    editActions: [
      { action: 'Paste', enabled: false },
      { action: 'arcPasteElement', enabled: false }
    ],
    textActions: [],
    specialActions: [{ action: 'SpecialCharacter', enabled: true }],
    justifyActions: [],
    linkActions: []
  },

  menuTextSelected: {
    textActions: [
      { action: 'bold', enabled: true },
      { action: 'italic', enabled: true },
      { action: 'underline', enabled: true },
      { action: 'superscript', enabled: true },
      { action: 'subscript', enabled: true }
    ],
    linkActions: []
  },

  arcMLDefaults: {
    root: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  editor: {
    plugins: ['paste', 'noneditable'],
    forced_root_block: '',
    valid_elements: 'style,span[class|style],div[class|data-action-type|data-action-format],bold,strong,sup,sub,b,ins,a[class|href]'
  }
};
