import * as React from 'react';
import * as ReactDOM from 'react-dom';
import NotificationsStore from '../../flux/events/NotificationsStore';
import PopupContainer from '../misc/PopupContainer';
import NotificationList from '../general/notifications/NotificationList';
import FlatButton from 'material-ui/FlatButton';
import { INotification } from 'mm-types';

export type Props = {
  notifications: INotification[];
  newCount: number;
  notificationInfo: { latestResponseLength: number };
  popupOpen: boolean;
  setPopup: (e: boolean) => void;
};

export default class NotificationsPopup extends React.Component<Props, {}> {
  private allToReadButtonRef: FlatButton;

  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    notifications: [],
    newCount: 0
  };

  _handlePopupClosed() {
    NotificationsStore.clearNew();
  }

  _setAllRead() {
    NotificationsStore.setAsRead();
  }

  _onRequestMoreNotifications() {
    NotificationsStore.nextNotifs();
    $(ReactDOM.findDOMNode(this.allToReadButtonRef) as Element).focus(); // overcomes strange MORE pagination / focus / popup close issue
  }

  private setAllToReadButtonRef(ref: FlatButton) {
    this.allToReadButtonRef = ref;
  }

  render() {
    return (
      <PopupContainer
        className="popup-list"
        onClose={() => {
          this._handlePopupClosed();
        }}
        onBlur={() => {
          /* Do nothing*/
        }}
        isOpen={this.props.popupOpen}
      >
        {this.props.notifications.length ? (
          <div className="notif-item-outer">
            <div className="popup-title">
              <h1>NOTIFICATIONS</h1>
            </div>
            {this.props.newCount > 0 ? <div className="new-notif-count-badge">New {this.props.newCount}</div> : undefined}
          </div>
        ) : undefined}

        <NotificationList
          onRequestMoreNotifs={() => this._onRequestMoreNotifications()}
          notificationInfo={this.props.notificationInfo}
          notifications={this.props.notifications}
          postNotifActionCallback={() => this.props.setPopup(false)}
          isOpen={this.props.popupOpen}
        />

        {this.props.notifications.length ? (
          <div className="notif-item-outer">
            <FlatButton
              style={{ backgroundColor: 'transparent', textTransform: 'none' }}
              label="Set all notifications to read"
              onClick={() => {
                this._setAllRead();
              }}
              ref={(ref: FlatButton) => this.setAllToReadButtonRef(ref)}
            />
          </div>
        ) : undefined}
      </PopupContainer>
    );
  }
}
