import * as React from 'react';
import classNameHelper from '../../../utils/classNameHelper';

export interface SimpleAnimationProps {
  show?: boolean;
  transitionAnimation?: boolean;
  enterDuration?: number;
  exitDuration?: number;
  children: React.ReactNode | React.ReactNode[];
}

export type CssTransformationFn = (props: SimpleAnimationProps, state: SimpleAnimationState) => React.CSSProperties;

export interface RequiredAnimationProps extends SimpleAnimationProps {
  cssTransformationFn: CssTransformationFn;
}

export interface SimpleAnimationState {
  mounted: boolean;
  renderContent: boolean;
  animationStarted: boolean;
  enterAnimation: boolean;
  enterDuration: number;
  exitDuration: number;
  styles?: React.CSSProperties;
}

const DEFAULT_TRANSITION_DURATION = 150;
interface WithSimpleAnimationProp {
  cssTransformationFn: CssTransformationFn;
  componentName?: string;
  transitionAnimation?: boolean;
}

export const withSimpleAnimation = ({ cssTransformationFn, componentName, transitionAnimation }: WithSimpleAnimationProp) => {
  class WithSimpleAnimation extends React.Component<SimpleAnimationProps, SimpleAnimationState> {
    static displayName: string;
    render() {
      const { children, ...rest } = this.props;
      return (
        <SimpleAnimation cssTransformationFn={cssTransformationFn} transitionAnimation={transitionAnimation} {...rest}>
          {children}
        </SimpleAnimation>
      );
    }
  }
  WithSimpleAnimation.displayName = componentName ? `${componentName}Animation` : `WithSimpleAnimation`;
  return WithSimpleAnimation;
};

class SimpleAnimation extends React.Component<RequiredAnimationProps, SimpleAnimationState> {
  private animationEndTimeout: number | null;
  constructor(props: RequiredAnimationProps) {
    super(props);
    this.state = {
      mounted: false,
      enterAnimation: false,
      enterDuration: props.enterDuration || DEFAULT_TRANSITION_DURATION,
      exitDuration: props.exitDuration || DEFAULT_TRANSITION_DURATION,
      renderContent: props.show !== undefined ? props.show : props.transitionAnimation ? false : true,
      animationStarted: props.show !== undefined ? props.show : props.transitionAnimation ? false : true
    };
    this.onAnimationDone = this.onAnimationDone.bind(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    this.setState({
      renderContent: false,
      mounted: false
    });
  }

  componentDidUpdate(prevProps: Readonly<RequiredAnimationProps>) {
    if (prevProps.show && !this.props.show) {
      this.handleAnimationStart(false);
    } else if (!prevProps.show && this.props.show) {
      this.handleAnimationStart(true);
    }
  }

  private handleAnimationStart(enterAnimation: boolean) {
    if (this.props.transitionAnimation) {
      this.startTransitionAnimation(enterAnimation);
    } else {
      this.startCssAnimation(enterAnimation);
    }
  }

  private startTransitionAnimation(enterAnimation = true) {
    if (enterAnimation) {
      this.setState(
        {
          renderContent: true,
          styles: {
            transform: this.props.cssTransformationFn({ ...this.props, show: false } as SimpleAnimationProps, this.state).transform
          }
        },
        () => {
          setTimeout(() => {
            this.setStartTransitionAnimationState(enterAnimation);
          });
        }
      );
      return;
    }
    this.setStartTransitionAnimationState(enterAnimation);
  }

  private setStartTransitionAnimationState(enterAnimation: boolean) {
    this.setState(this.getStartAnimationState(enterAnimation), () => {
      this.handleTransitionAnimationDone(enterAnimation);
    });
  }

  private startCssAnimation(enterAnimation = true) {
    const startState = this.getStartAnimationState(enterAnimation);
    if (enterAnimation) {
      startState['renderContent'] = true;
    }
    this.setState(startState);
  }

  private handleTransitionAnimationDone(enterAnimation = true) {
    if (!this.state.animationStarted) {
      return false;
    }
    if (this.animationEndTimeout) {
      clearTimeout(this.animationEndTimeout);
      this.animationEndTimeout = null;
    }

    this.animationEndTimeout = window.setTimeout(
      () => {
        this.onAnimationDone();
      },
      enterAnimation ? this.props.enterDuration : this.props.exitDuration
    );
  }

  private onAnimationDone() {
    this.setState({
      renderContent: this.props.show ? true : this.state.enterAnimation,
      animationStarted: false
    });
  }

  private getStartAnimationState(enterAnimation: boolean) {
    return {
      animationStarted: true,
      enterAnimation,
      styles: this.props.cssTransformationFn(this.props, this.state)
    };
  }

  render() {
    if (!this.state.renderContent) {
      return null;
    }
    const containerClassName = classNameHelper.merge('animation-container position-relative', {
      'animation-inprogress': this.state.animationStarted,
      'animation-done': !this.state.animationStarted
    });

    return (
      <div className={containerClassName}>
        <div style={this.state.styles} onAnimationEnd={this.onAnimationDone}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
