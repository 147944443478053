import { createFactory } from 'react';

export type PropsMapper<Attributes, InputProps, DerivedProps> = (input: InputProps) => Attributes & InputProps & DerivedProps;

function mapProps<InputProps, DerivedProps>(propsMapper: PropsMapper<React.Attributes, InputProps, DerivedProps>) {
  return (Wrapped: React.ComponentClass<React.Attributes & InputProps & DerivedProps>) => (props: InputProps) =>
    createFactory<React.Attributes & InputProps & DerivedProps>(Wrapped)(propsMapper(props));
}

export { mapProps };
