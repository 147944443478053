import React, { FC, useState, CSSProperties, useRef } from 'react';
import Tooltip from 'material-ui/internal/Tooltip';

const MemoTooltip = React.memo(Tooltip);
const MARGINS = 16;
const DEFAULT_TEXT_COLOUR = 'rgba(255,255,255)';
type HorizontalPosition = 'left' | 'right' | 'center';
export interface WithTooltipProps {
  tooltipText: string;
  tooltipEnabled?: boolean;
  tooltipVerticalPosition?: 'top' | 'bottom';
  tooltipHorizontalPosition?: HorizontalPosition;
}

// make sure that disabled button has pointer-events: none!
export default function withTooltip<P>(Component): FC<P & WithTooltipProps> {
  return function WithTooltip({
    tooltipEnabled = true,
    tooltipText,
    tooltipVerticalPosition = 'bottom',
    tooltipHorizontalPosition = 'center',
    ...componentProps
  }: P & WithTooltipProps) {
    const [show, setShow] = useState<boolean>(false);
    const [colour, setColour] = useState<string | null>(null);
    const [hPosition, setHPosition] = useState<HorizontalPosition>(tooltipHorizontalPosition);
    const tooltipRef = useRef<Tooltip | null>(null);

    function showTooltip() {
      if (!show) {
        setShow(true);

        if (!colour) {
          setColour(getTooltipColour());
        }
        setTooltipColour(colour ?? DEFAULT_TEXT_COLOUR);
        if (tooltipRef.current) {
          repositionTooltip(tooltipRef.current.refs.tooltip as HTMLDivElement);
        }
      }
    }

    function hideTooltip() {
      if (show) {
        setShow(false);
        setTooltipColour('transparent');
        setHPosition(tooltipHorizontalPosition);
      }
    }

    function setTooltipColour(color: string) {
      if (tooltipRef.current) {
        (tooltipRef.current.refs.tooltip as HTMLDivElement).style.color = color;
      }
    }

    function getTooltipColour(): string | null {
      if (tooltipRef.current) {
        return (tooltipRef.current.refs.tooltip as HTMLDivElement).style.color;
      }
      return null;
    }

    function repositionTooltip(tooltip: HTMLDivElement) {
      const tooltipPos = tooltip.getBoundingClientRect();
      const docWidth = document.body.getBoundingClientRect().right - MARGINS / 2;
      const halfTooltipWidth = tooltipPos.width / 2;
      if (tooltipHorizontalPosition === 'center') {
        if (tooltipPos.x < 0) {
          setHPosition('right');
        } else if (docWidth < tooltipPos.right - MARGINS / 2) {
          setHPosition('left');
        } else {
          setHPosition('center');
        }
      } else if (tooltipHorizontalPosition === 'right') {
        if (tooltipPos.right < docWidth) {
          setHPosition('right');
        } else if (tooltipPos.right - halfTooltipWidth < docWidth) {
          setHPosition('center');
        } else {
          setHPosition('left');
        }
      } else {
        if (tooltipPos.left >= 0) {
          setHPosition('left');
        } else if (tooltipPos.left + MARGINS + halfTooltipWidth >= 0) {
          setHPosition('center');
        } else {
          setHPosition('right');
        }
      }
    }

    const containerPosition: CSSProperties = tooltipVerticalPosition === 'top' ? { top: 0 } : { bottom: 0 };
    const tooltipPosition: CSSProperties = tooltipVerticalPosition === 'top' ? { top: -20 } : { top: 0 };
    return (
      <span style={{ position: 'relative', boxSizing: 'border-box', display: 'inline-block' }}>
        <span
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          onBlur={hideTooltip}
          style={{ boxSizing: 'border-box', display: 'inline-block' }}
        >
          <Component {...componentProps} />
        </span>
        <span style={{ position: 'absolute', width: 1, height: 1, left: '50%', ...containerPosition }}>
          <MemoTooltip
            label={tooltipText}
            show={tooltipEnabled && show}
            style={{
              fontSize: 10,
              lineHeight: '22px',
              padding: '0 8px',
              boxSizing: 'border-box',
              marginLeft: -22,
              ...tooltipPosition
            }}
            verticalPosition={tooltipVerticalPosition}
            horizontalPosition={hPosition}
            ref={tooltipRef}
          />
        </span>
      </span>
    );
  };
}

export const TooltipWrapper = withTooltip<{}>(({ children }) => <>{children}</>);
