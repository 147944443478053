import { EditorStore } from '../EditorStore';
import { IUnit, IShareDetails, IEditorStoreEvent } from 'mm-types';
import { DocUnitWrapper } from '../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import * as unitsClient from '../../../clients/units';

export default class DiffModeHandler {
  private editorStore: EditorStore;
  private readonly data: {
    [prop: string]: {
      origin: DocUnitWrapper[];
      diff: DocUnitWrapper[];
      startIndex: number;
      endIndex: number;
    };
  };

  constructor(editorStore: EditorStore) {
    this.editorStore = editorStore;
    this.data = {};
  }

  openDiffMode(unitUid: string, unitShareDetails: IShareDetails, diffedUnits: Partial<IUnit>[]) {
    const { uid, sharedIndexUid } = unitShareDetails;
    const { removedUnitsWrappers, startCurrentUnit, endCurrentUnit, insertIndex } = this.getShareDetails(unitUid, unitShareDetails);

    const sharedUid = sharedIndexUid || uid!;

    this.setOriginEndIndex(sharedUid);

    // already looking at a diff, toggle it off
    if (startCurrentUnit && startCurrentUnit.definitionId === 'diff') {
      this.triggerDiffSharedContentUpdate(false, uid);
    } else {
      this.closeOtherDiffMode();

      this.editorStore.removeUnitsFromCollection(removedUnitsWrappers);

      const newParsedDiffUnits: DocUnitWrapper[] = [];
      diffedUnits.forEach((unit: IUnit, index) => {
        const parsedDiffedUnitWrapper = this.getParsedDiffedUnitWrapper(
          startCurrentUnit,
          unit,
          endCurrentUnit,
          index,
          diffedUnits.length - 1,
          unitShareDetails
        );
        this.addDiffUnit(sharedUid, parsedDiffedUnitWrapper);
        newParsedDiffUnits.push(parsedDiffedUnitWrapper);
      });

      this.editorStore.replaceUnitsWithinCollection(insertIndex, 0, ...newParsedDiffUnits);

      this.triggerDiffSharedContentUpdate(true, uid);
      this.editorStore.setBusy(false);
    }
  }

  closeDiffMode(sharedIndexUid: string): { startIndex: number; endIndex: number } {
    const indexes = { startIndex: 0, endIndex: 0 };
    const data = this.data[sharedIndexUid];
    if (data && data.diff.length) {
      this.editorStore.removeUnitsFromCollection(data.diff);

      if (data.origin.length) {
        indexes.startIndex = data.startIndex;
        indexes.endIndex = data.endIndex;
        this.editorStore.replaceUnitsWithinCollection(data.startIndex, 0, ...data.origin);
      }

      this.triggerDiffSharedContentUpdate(false, sharedIndexUid, true);
    }
    this.clearBackup(sharedIndexUid);

    this.editorStore.setBusy(false);

    return indexes;
  }

  closeOtherDiffMode(sharedUid?: string) {
    const activeDiffUid = this.getActiveDiffUid(sharedUid);
    if (activeDiffUid[0] && activeDiffUid[0].uid) {
      this.closeDiffMode(activeDiffUid[0].uid);
    }
  }

  getActiveDiffUid(sharedUid?: string) {
    return Object.keys(this.data)
      .map((uid) => ({ uid, ...this.data[uid] }))
      .filter(({ uid, diff }) => (!sharedUid || (sharedUid && uid !== sharedUid)) && diff.length > 0);
  }

  inDiffMode(sharedIndexUid: string | null): boolean {
    return (
      !!sharedIndexUid && this.data[sharedIndexUid] && !!(this.data[sharedIndexUid].origin.length || this.data[sharedIndexUid].diff.length)
    );
  }

  // Helpers

  private getParsedDiffedUnitWrapper(
    startUnit: IUnit,
    diffedUnit: IUnit,
    endUnit: IUnit,
    index: number,
    length: number,
    unitShareDetails: IShareDetails
  ) {
    const isShareStartUnit = index === 0;
    const isShareEndUnit = index === length;

    const parsedUnit = isShareStartUnit ? startUnit : isShareEndUnit ? endUnit : diffedUnit;

    const shareDetails = isShareStartUnit && isShareEndUnit ? parsedUnit.shareDetails : unitShareDetails;

    parsedUnit.shareDetails = {
      ...shareDetails!,
      isShareStartUnit: isShareStartUnit,
      isShareEndUnit: isShareEndUnit,
      shareEnd: isShareEndUnit,
      position: index,
      inDiffMode: true
    };

    if (!isShareStartUnit) {
      parsedUnit.uid = diffedUnit.uid + '_' + index;
    }

    parsedUnit.html = diffedUnit.html;
    parsedUnit.type = 'diff';

    if (!isShareStartUnit && !isShareEndUnit) {
      unitsClient.parse(parsedUnit);
    }
    return new DocUnitWrapper(parsedUnit);
  }

  private getShareDetails(
    unitUid,
    { uid, origin, sharedIndexUid }: IShareDetails
  ): { removedUnitsWrappers: DocUnitWrapper[]; startCurrentUnit: IUnit; endCurrentUnit: IUnit; insertIndex: number } {
    // get info on current shared units, and remove all from memory
    const removedUnitsWrappers: DocUnitWrapper[] = [];
    let startCurrentUnit;
    let endCurrentUnit;
    let shareBlockFound = false; // i.e. if > 1 usage on same page we muct first detect the correct block
    let insertIndex = 0;

    this.editorStore.getDocUnitsCollection().forEach((unit, index) => {
      if (!(startCurrentUnit && endCurrentUnit)) {
        const { shareDetails } = unit.unit;

        if (origin || unit.unit.uid === unitUid) {
          shareBlockFound = true;
        }

        if (shareBlockFound && shareDetails && shareDetails.uid === uid) {
          removedUnitsWrappers.push(unit);
          this.addOriginUnit(sharedIndexUid || uid!, unit);

          if (shareDetails.isShareStartUnit) {
            insertIndex = index;
            startCurrentUnit = unit.toJSON();
          }
          if (shareDetails.isShareEndUnit) {
            endCurrentUnit = unit.toJSON();
          }
        }
      }
    });
    return { removedUnitsWrappers, startCurrentUnit, endCurrentUnit, insertIndex };
  }

  private addOriginUnit(sharedIndexUid: string, unit: DocUnitWrapper) {
    this.createBackupIfDoesNotExist(sharedIndexUid);
    const data = this.data[sharedIndexUid];
    const details = unit.unit.shareDetails;
    if (details?.isShareStartUnit) {
      data.startIndex = unit.unit.index;
    }
    data.origin.push(unit);
  }

  private setOriginEndIndex(sharedIndexUid: string) {
    const data = this.data[sharedIndexUid];
    data.endIndex = data.startIndex + data.origin.length - 1;
  }

  private addDiffUnit(sharedIndexUid: string, unit: DocUnitWrapper) {
    this.createBackupIfDoesNotExist(sharedIndexUid);
    const data = this.data[sharedIndexUid];
    data.diff.push(unit);
  }

  private clearBackup(sharedIndexUid: string) {
    if (this.data[sharedIndexUid]) {
      this.data[sharedIndexUid] = { diff: [], origin: [], startIndex: 0, endIndex: 0 };
    }
  }

  private createBackupIfDoesNotExist(sharedIndexUid: string) {
    if (!this.data[sharedIndexUid]) {
      this.data[sharedIndexUid] = { diff: [], origin: [], startIndex: 0, endIndex: 0 };
    }
  }

  // Events

  private triggerDiffSharedContentUpdate = (isDiffOn: boolean, shareUid: string | null, isNew?: boolean) => {
    const data: { isDiffOn: boolean; shareUid: string | null; isNew?: boolean } = { isDiffOn, shareUid };
    if (typeof isNew !== 'undefined') {
      data.isNew = isNew;
    }
    const changeEvent: IEditorStoreEvent<'diffSharedContentUpdate'> = {
      type: 'diffSharedContentUpdate',
      data
    };
    this.editorStore.trigger(changeEvent);
  };
}
