import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { isEscKey } from '../editor/utils/keyIdentifier';

export type Props = {
  onClose?: () => void;
  className?: string;
  dataQa?: string;
  closeOnBlur?: boolean;
  handleClose?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => any;
  mountOnBody?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
};

export type State = {
  active: boolean;
  hPlacement: 'left';
  vPlacement: 'bottom';
  style: {
    popup: React.CSSProperties;
    pointer: React.CSSProperties;
  };
};

/*
 * Simple popup container: its placement isn't calculated dyanamically
 * (as its use is intended in some really custom places!)
 * Position it using styles.
 * Note: mountOnBody very useful if u need to use body level absolute positioning - handy for zindex issues
 */
export default class PopupContainer extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    className: '',
    closeOnBlur: true,
    handleClose: true,
    mountOnBody: false
  };

  public displayName: 'PopupContainer';
  private _triggerBtnRef: null | React.ReactInstance;
  private _bodyMountedContainerEl: null | HTMLDivElement;
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      active: this.props.isOpen ?? false,
      hPlacement: 'left',
      vPlacement: 'bottom',
      style: {
        popup: {},
        pointer: {}
      }
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.mountOnBody) {
      this._updateBodyMount();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mountOnBody) {
      this._updateBodyMount();
    }
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({ active: !!this.props.isOpen }, () => this.containerRef.current?.focus());
      if (!this.props.isOpen && this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.mountOnBody && this._bodyMountedContainerEl) {
      ReactDOM.unmountComponentAtNode(this._bodyMountedContainerEl);
      if (this._bodyMountedContainerEl.parentNode) {
        this._bodyMountedContainerEl.parentNode.removeChild(this._bodyMountedContainerEl);
      }
    }
  }
  _createBodyMount() {
    this._bodyMountedContainerEl = document.createElement('div');
    this._bodyMountedContainerEl.className = 'bodymounted-container';
    document.body.appendChild(this._bodyMountedContainerEl);
  }
  _updateBodyMount() {
    if (!this._bodyMountedContainerEl) {
      this._createBodyMount();
    }
    ReactDOM.render(this._getReactDOM(), this._bodyMountedContainerEl);
    this._setRefFocus();
  }

  _setRefFocus() {
    if (this.state.active && this.containerRef.current) {
      setTimeout(() => {
        if (this.containerRef.current) {
          this.containerRef.current.focus();
        }
      }, 200);
    }
  }

  setTriggerButton(triggerBtnRef: React.ReactInstance | null) {
    this._triggerBtnRef = triggerBtnRef;
    return this;
  }

  open(onOpenCallback?: Function) {
    this.setState({ active: true }, () => {
      this._setRefFocus();
      if (onOpenCallback) {
        onOpenCallback();
      }
    });
    return this;
  }

  setStyle(styleFn: (element: Element) => { popup?: React.CSSProperties; pointer?: React.CSSProperties }) {
    const styled = styleFn(this.containerRef.current! as Element);
    this.setState({
      style: {
        popup: styled.popup || {},
        pointer: styled.pointer || {}
      }
    });
  }

  isOpen() {
    return this.state.active;
  }

  close(onCloseCallback?: Function) {
    this.setState({ active: false }, () => {
      if (this._triggerBtnRef) {
        (ReactDOM.findDOMNode(this._triggerBtnRef) as HTMLElement).focus();
      }
      if (this.props.onClose) {
        this.props.onClose();
      }
      if (onCloseCallback) {
        onCloseCallback();
      }
    });
  }

  _handleBlur(e: React.FocusEvent<HTMLElement>) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    } else if (!this.props.mountOnBody || e.relatedTarget === null) {
      if (this.props.handleClose) {
        if (this.props.closeOnBlur) {
          setTimeout(() => {
            if (!(ReactDOM.findDOMNode(this) as Element).contains(document.activeElement) && this.state.active) {
              this.close();
            }
          }, 1);
        } else {
          setTimeout(() => {
            this.close();
          }, 150);
        }
      }
    }
  }

  _handleKeys(e: React.KeyboardEvent<HTMLElement>) {
    if (isEscKey(e)) {
      this.close();
    }
  }
  _getReactDOM() {
    const { popup, pointer } = this.state.style;

    return (
      <div
        className={'popup-container ' + this.props.className + (this.state.active ? ' open' : ' closed')}
        data-qa={this.props.dataQa ?? 'popup-container'}
        tabIndex={-1}
        style={{ display: this.state.active ? 'block' : 'none', ...popup }}
        ref={this.containerRef}
        onKeyDown={(e) => this._handleKeys(e)}
        onBlur={(e) => this._handleBlur(e)}
      >
        <div className="popup-container-pointer" style={pointer} />
        {this.props.children}
      </div>
    );
  }
  render() {
    return this.props.mountOnBody ? <div /> : this._getReactDOM();
  }
}
