import EditorStore from './../flux/editor/EditorStore';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosAdapter } from 'axios';
import { SESSION_STORAGE_KEYS } from '../utils';
import { isFeatureOn } from '../components/featureSwitch/featureSwitchUtils';
import { CacheClient } from '../utils/CacheClient';
import appStore from '../appStore';
import { showSystemAlert } from '../components/misc/SystemAlert/thunks';
import findMessageByErrorCode from './errorMessages';

type BaseClientsInterceptor = (client: AxiosInstance) => AxiosInstance;
const clientInterceptor: {
  errorHandler: BaseClientsInterceptor;
  gebHelper: BaseClientsInterceptor;
  mockResponse: BaseClientsInterceptor;
  cache: BaseClientsInterceptor;
} = {
  errorHandler: (client: AxiosInstance): AxiosInstance => {
    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const err = getErrorDetails(error);

        switch (err.status) {
          case 403:
          case 404:
            _handleError({ serverErrorCode: err.serverErrorCode, error: error as AxiosError, serverErrorMessage: err.serverErrorMessage });
            break;
        }
        return Promise.reject(error);
      }
    );
    return client;
  },
  gebHelper: (client: AxiosInstance): AxiosInstance => {
    if (!(window as any).numberOfAjaxCallPending) {
      (window as any).numberOfAjaxCallPending = 0;
    }
    client.interceptors.request.use(
      function (config) {
        (window as any).numberOfAjaxCallPending++;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    client.interceptors.response.use(
      (response) => {
        (window as any).numberOfAjaxCallPending--;
        return response;
      },
      (error) => {
        (window as any).numberOfAjaxCallPending--;
        if (error.response) {
          console.error('base-client-interceptor - GebHelper:', error.response.status, JSON.stringify(error.response.data, undefined, 2));
          if ([401, 403].indexOf(error.response.status) !== -1) {
            EditorStore.getEditor().destroyActiveEditor();
          }
        }
        return Promise.reject(error);
      }
    );
    return client;
  },
  mockResponse: (client: AxiosInstance): AxiosInstance => {
    function replaceResponse(response: AxiosResponse): { response: AxiosResponse; matched: boolean } {
      let matched = false;
      try {
        const mocked = sessionStorage.getItem(btoa(SESSION_STORAGE_KEYS.MOCKED_RESPONSES));
        if (!mocked || mocked === '[]') {
          return { response, matched };
        }
        const mocks: { url: string; value: string }[] = JSON.parse(mocked);
        const seperator = response?.config?.url?.startsWith('/') ? '' : '/';
        const matchedMock = mocks.find((m) => {
          return m.url === `${response?.config?.baseURL}${seperator}${response?.config?.url}`;
        });
        if (matchedMock && response) {
          const mockedDataAndStatus: { status: number; response: AxiosResponse } = JSON.parse(matchedMock.value);
          response.data = mockedDataAndStatus.response;
          response.status = mockedDataAndStatus.status;
          matched = true;
        }
      } catch (e) {
        console.error(`MOCK RESPONSE: Check mock response data. Expecting JSON. URL: ${response?.config?.url ?? ''}`);
      }
      return { response, matched };
    }

    client.interceptors.response.use(
      (response) => {
        if (isFeatureOn('mockResponse')) {
          const replacedRes: AxiosResponse = replaceResponse(response).response;
          if (replacedRes.status >= 400) {
            return Promise.reject({ response: replacedRes });
          } else {
            return replacedRes;
          }
        } else {
          return response;
        }
      },
      (error) => {
        if (!isFeatureOn('mockResponse')) {
          return Promise.reject(error);
        }
        const possibleResponse = replaceResponse(error);
        return possibleResponse.matched ? Promise.resolve(possibleResponse.response) : Promise.reject(error);
      }
    );
    return client;
  },
  cache: (client: AxiosInstance): AxiosInstance => {
    client.interceptors.request.use(
      async function (config) {
        const isGetMethod = config.method === 'get';
        const url = config.baseURL + (config.url ?? '');
        const result = isGetMethod ? await CacheClient.get(url) : undefined;
        const useCache = isGetMethod ? (config.params ?? {}).$$useCache : false;
        const clearCache = !isGetMethod ? (config.params ?? {}).$$clearCache : false;

        if (useCache) {
          config.$$useCache = useCache;
          if (config.params.$$useCache) {
            delete config.params.$$useCache;
          }
        }
        if (clearCache) {
          config.$$clearCache = clearCache;
          if (config.params?.$$clearCache) {
            delete config.params.$$clearCache;
          }
        }

        if (result) {
          const data = await result?.clone().json();
          console.log(`"${url}" served from cache`);
          config.data = data;
          config.$$fromCache = true;
          config.adapter = (async (config: AxiosRequestConfig) => {
            return Promise.resolve({
              data,
              status: 200,
              statusText: 'CACHED',
              headers: config.headers,
              config: config,
              request: config
            });
          }) as AxiosAdapter;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // On response, set or delete the cache
    client.interceptors.response.use(
      async (response) => {
        const useCache = response.config.$$useCache;
        const clearCache = response.config.$$clearCache;
        const url = response.config.url;
        const fullUrl = location.origin + url;

        if (response.config.method === 'get') {
          if (useCache && !!url) {
            await CacheClient.set(url, response.data);
          }
        } else {
          if (clearCache && response.status >= 200 && response.status < 300) {
            await CacheClient.clearStartsWith(fullUrl ?? '', clearCache);
          }
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return client;
  }
};

export function getErrorDetails(error?: Error | AxiosError) {
  if (error) {
    if ((error as AxiosError).response) {
      return {
        status: (error as AxiosError).response?.status,
        serverErrorCode: (error as AxiosError).response?.data.errors ? (error as AxiosError).response?.data.errors[0].code : null,
        serverErrorMessage: (error as AxiosError).response?.data.errors ? (error as AxiosError).response?.data.errors[0].message : null
      };
    } else {
      return {
        status: 0,
        serverErrorCode: null,
        serverErrorMessage: null
      };
    }
  } else {
    return {
      status: 0,
      serverErrorCode: null,
      serverErrorMessage: null
    };
  }
}

function _handleError(payload: { error?: AxiosError; serverErrorCode: number; serverErrorMessage?: string; additional?: string | null }) {
  const message = findMessageByErrorCode({ errorCode: payload.serverErrorCode, serverMessage: payload.serverErrorMessage });
  appStore.dispatch<any>(showSystemAlert(message));
}

export default clientInterceptor;
export function combineInterceptors(...interceptors: BaseClientsInterceptor[]): BaseClientsInterceptor {
  if (interceptors.length === 0) {
    throw 'No axios interceptors. Please add one';
  }

  if (interceptors.length === 1) {
    return interceptors[0];
  }

  return interceptors.reduce((a, b) => (arg) => a(b(arg)));
}
