import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import FontIcon from 'material-ui/FontIcon';
import TextField from 'material-ui/TextField';
import useListenToStore from '../../../../../hooks/useListenToStore';
import { parseIntWithPx } from '../../../../../../utils/NumberUtil';
import TableUtils from '../../../../../../utils/TableUtils';

export type Props = {
  tooltip: string;
  icon: string;
  side: 'left' | 'bottom' | 'right' | 'top';
  disabled: boolean;
};

function CellPadding(props: Props) {
  useListenToStore({ store: EditorStore, eventListener: tinyUpdateEvent });

  const [padding, setPadding] = React.useState<number>(0);

  React.useEffect(() => {
    _updateCellPadding();
  }, []);

  function tinyUpdateEvent(e) {
    if (e.type === 'unitDomChange' || e.type === 'nestedUnitFocusChange') {
      _updateCellPadding();
    }
  }

  function _updateCellPadding() {
    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
    const paddings = tinyFacade.getCommonPaddingStyles(TableUtils.getSelectedCells());
    const padding = paddings[props.side];
    const parsed = parseIntWithPx(padding);
    if (shouldUpdate(parsed)) {
      setPadding(parsed);
    }
  }

  function _onChange(e) {
    setPadding(e.target.value);
  }

  function _applyPadding() {
    const style = {};
    style['padding-' + props.side] = padding + 'px';
    const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
    facade.execCommand('arcCellSetStyle', { style: [style] });
    facade.clearSelection();
  }

  function _keyUp(e) {
    if (e.keyCode === 13 && isValid()) {
      _applyPadding();
    }
  }

  function _onBlur() {
    if (isValid()) {
      _applyPadding();
    }
  }

  function isValid() {
    return !isNaN(parseIntWithPx(padding.toString()));
  }

  function shouldUpdate(paddingToSet: number): boolean {
    return padding !== paddingToSet;
  }

  const baseStyle = { width: '30px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
  return (
    <div className="padding-component">
      <FontIcon color="#757575" className={'material-icons ' + props.icon} />
      <TextField
        id={'side-text-' + props.side}
        type="number"
        value={'' + padding}
        style={baseStyle}
        onChange={(e) => _onChange(e)}
        onKeyUp={(e) => _keyUp(e)}
        onBlur={() => _onBlur()}
        disabled={props.disabled}
      />
    </div>
  );
}

export default CellPadding;
