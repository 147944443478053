import * as React from 'react';
import NotificationsStore, { NotificationInfo, NotificationsStoreEvent } from '../../flux/events/NotificationsStore';
import RecentStore, { RecentStoreEvent } from '../../flux/common/RecentStore';
import { INotification, IRecentIndex } from 'mm-types';
import DashboardPanel from './DashboardPanel/DashboardPanel';
import DashboardPanelHeader from './DashboardPanel/DashboardPanelHeader';
import DashboardPanelHeaderTitle from './DashboardPanel/DashboardPanelHeaderTitle';
import NotificationCountBadge from './NotificationsCountBadge';
import NotificationsTile from './NotificationsTile';
import DocumentTile from './DocumentTile';
import WorkflowTasksTileContainer from './WorkflowTasksTile/WorkflowTasksTileContainer';
import { isFeatureOn } from '../featureSwitch/featureSwitchUtils';
import TeamspaceWorkflowSummaryContainer from './WorkflowTasksTile/TeamspaceWorkflowSummaryContainer';
import DepartmentWorkflowSummaryContainer from './WorkflowTasksTile/DepartmentWorkflowSummaryContainer';
import { AdminAndMemberOrgs } from '../hooks/useOrgAdminAndMembers';
import WorkflowActionsTileContainer from './WorkflowActionsTile/WorkflowActionsTileContainer';

export type Props = {
  isAdmin: boolean;
  isTeamspaceAdmin: boolean;
  isTeamspaceMember: boolean;
  adminAndMemberOrgs: AdminAndMemberOrgs;
};

function EditorMetrics({ isAdmin, isTeamspaceAdmin, isTeamspaceMember, adminAndMemberOrgs }: Props) {
  const initialRecentDocuments: IRecentIndex[] = RecentStore.getInitialState().recentDocuments;

  const [notificationInfo, setNotificationInfo] = React.useState<NotificationInfo>(NotificationsStore.getInitialState().info);
  const [notifications, setNotifications] = React.useState<INotification[]>(NotificationsStore.getInitialState().notifications);
  // Don't show spinner if there's an initial recentDocuments
  const [recentDocumentsLoading, setRecentDocumentsLoading] = React.useState<boolean>(
    !(initialRecentDocuments && initialRecentDocuments.length > 0)
  );
  const [recentDocuments, setRecentDocuments] = React.useState<IRecentIndex[]>(initialRecentDocuments);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  React.useEffect(() => {
    const notificationUnsubscribe: Function = NotificationsStore.listen(onNotificationsUpdate, this);
    const recentUnsubscribe: Function = RecentStore.listen(onRecentUpdate, this);

    RecentStore.retrieveRecentIndexes();
    NotificationsStore.retrieveNotifs();

    return () => {
      NotificationsStore.retrieveNotifs(); // reclear

      notificationUnsubscribe();
      recentUnsubscribe();
    };
  }, []);

  let ticking = false;
  const handleScrollDebounced = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const ev = new Event('scroll');
        window.dispatchEvent(ev);
        ticking = false;
      });
      ticking = true;
    }
  };

  const onNotificationsUpdate = (eventState: NotificationsStoreEvent) => {
    setNotifications(eventState.notifications);
    setNotificationInfo(eventState.info);
  };

  const onRecentUpdate = (eventState: RecentStoreEvent) => {
    if (eventState.data) {
      setRecentDocuments(eventState.data);
      setRecentDocumentsLoading(false);
      setErrorMsg(null);
    } else {
      setErrorMsg(eventState.errorMsg || 'Cannot retrieve recent documents.');
      setRecentDocumentsLoading(false);
    }
  };

  return (
    <div className="dashboard-inner-container" onScroll={handleScrollDebounced}>
      <DashboardPanel style={{ flex: '1 1 45%' }}>
        <DashboardPanelHeader>
          <DashboardPanelHeaderTitle>Notifications</DashboardPanelHeaderTitle>
          <NotificationCountBadge>{notificationInfo && notificationInfo.totalUnseenCount}</NotificationCountBadge>
        </DashboardPanelHeader>
        <NotificationsTile info={notificationInfo} notifications={notifications} />
      </DashboardPanel>

      <DashboardPanel style={{ flex: '1 1 45%' }}>
        <DashboardPanelHeader>
          <DashboardPanelHeaderTitle>Recent Documents</DashboardPanelHeaderTitle>
        </DashboardPanelHeader>
        <DocumentTile errorMsg={errorMsg} recentDocuments={recentDocuments} recentDocumentsLoading={recentDocumentsLoading} />
      </DashboardPanel>

      {isFeatureOn('kpi') && (
        <>
          <DashboardPanel style={{ flex: '1 1 90%' }}>
            <DashboardPanelHeader>
              <DashboardPanelHeaderTitle>My Workflow Tasks</DashboardPanelHeaderTitle>
            </DashboardPanelHeader>
            <WorkflowTasksTileContainer />
          </DashboardPanel>

          <DepartmentWorkflowSummaryContainer adminOrgs={adminAndMemberOrgs.adminOrgs} memberOrgs={adminAndMemberOrgs.memberOrgs} />
          <TeamspaceWorkflowSummaryContainer />
        </>
      )}
      {isFeatureOn('workflowActions') &&
        (isAdmin ||
          isTeamspaceMember ||
          isTeamspaceAdmin ||
          adminAndMemberOrgs.memberOrgs.length > 0 ||
          adminAndMemberOrgs.adminOrgs.length > 0) && <WorkflowActionsTileContainer />}
    </div>
  );
}

export default EditorMetrics;
