import FlatButton from 'material-ui/FlatButton';
import React from 'react';
import appStore from '../../../appStore';
import { hideSystemAlert, showSystemAlert } from './thunks';
import { ISnackbarMessage } from 'mm-types';
import { showSystemSnackbar } from '../SystemSnackbar/actions';

export const dispatchDefaultErrorSystemAlert = (isServerError: boolean, userMessage = '', errorMessageDetails = '') => {
  const reminderToRefreshSnackbarMessage: ISnackbarMessage = {
    open: true,
    message: 'Please remember to refresh page as some functionality will not work',
    autoHideDuration: 60000,
    bodyStyle: { backgroundColor: 'red' }
  };

  appStore.dispatch<any>(
    showSystemAlert({
      errorTitle: isServerError ? 'A Server Error Occurred' : 'An Error Occurred',
      errorMessage: (
        <div>
          Aerodocs could not complete this action.
          <br />
          <br />
          <b>{userMessage}</b>
          <br />
          <br />
          If you don&apos;t need to save changes simply click Reload.
          <br />
          Otherwise click Close, copy your changes to a text editor and then refresh your browser.
        </div>
      ),
      errorMessageDetails,
      errorActions: [
        <FlatButton
          key={1}
          label="Close"
          className="error-ok"
          onClick={() => {
            appStore.dispatch<any>(showSystemSnackbar(reminderToRefreshSnackbarMessage));
            appStore.dispatch<any>(hideSystemAlert());
          }}
        />,
        <FlatButton
          primary={true}
          key={2}
          label="Reload"
          className="error-ok"
          onClick={() => {
            window.location.reload();
          }}
        />
      ]
    })
  );
};
