import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import TocSelectorSection from './TocSelectorSection';
import { ITocNode, IUnitConceptMap } from 'mm-types';
import { IConceptMap } from '../../../clients/concepts';

export type Props = {
  tocs: ITocNode[];
  variantTags?: IConceptMap[];
  allowVariantSelection?: boolean;
  variantsMustMatch?: boolean;
  selectedVariantsMap?: IUnitConceptMap; // { [ name: string ]: IUnitVariantsMap };
  tocsLoading?: boolean;
  selectedUid?: string;
  documentIndexUid?: string;
  onSelect: (section: ITocNode, hasVariantsApplied: boolean) => void;
  isDuRefLink?: boolean;
};

export type State = {
  loading: boolean;
  error: null | string;
  tocs: ITocNode[];
};

export default class TocSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      tocs: []
    };
  }

  static defaultProps: Partial<Props> = {
    tocsLoading: false
  };

  onSelectSection(section: ITocNode, hasVariantsApplied: boolean) {
    if (this.props.onSelect) {
      this.props.onSelect(section, hasVariantsApplied);
    }
  }

  renderBody() {
    if (this.props.tocsLoading) {
      return (
        <div className="loader">
          <CircularProgress mode="indeterminate" />
        </div>
      );
    } else {
      return (
        <div>
          {this.props.tocs.map((ts) => {
            return (
              <TocSelectorSection
                conceptTags={this.props.variantTags}
                allowVariantSelection={this.props.allowVariantSelection}
                variantsMustMatch={this.props.variantsMustMatch}
                selectedVariantsMap={this.props.selectedVariantsMap}
                onSelect={(section, hasVariantsApplied) => this.onSelectSection(section, hasVariantsApplied)}
                selectedUid={this.props.selectedUid}
                section={ts}
                key={ts.uid}
                isDuRefLink={this.props.isDuRefLink}
              />
            );
          })}
        </div>
      );
    }
  }

  render() {
    return <div className="toc-selector">{this.renderBody()}</div>;
  }
}
