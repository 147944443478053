export type CommentViewTypes = 'COMMENTS_ALL' | 'COMMENTS_ACTIVE' | 'COMMENTS_RESOLVED';

const externalAuthors = {
  ALL: 'ALL',
  EXTERNAL_COMMENTORS: 'PUBLIC',
  INTERNAL_COMMENTORS: 'INTERNAL'
};

const properties = {
  COMMENTS_ALL: {
    title: 'All Comments',
    key: 'allcomments',
    value: 'all'
  },
  COMMENTS_ACTIVE: {
    title: 'Active Comments',
    key: 'active',
    value: 'active'
  },
  COMMENTS_RESOLVED: {
    title: 'Resolved Comments',
    key: 'resolved',
    value: 'resolved'
  }
};

const toRet = {
  external: externalAuthors,
  props: properties
};

export default toRet;
