import { ComposedProps as MenuInsertProps } from './insert/MenuInsert';
import { ComposedProps as MenuEditProps } from './edit/MenuEdit';

export function areMenuPropsEqual(prevProps: MenuInsertProps | MenuEditProps, newProps: MenuInsertProps | MenuEditProps) {
  return (
    newProps.currentEditorStoreAction === prevProps.currentEditorStoreAction &&
    !(!newProps.currentEditorStoreAction && !prevProps.currentEditorStoreAction) &&
    newProps.selectedUnit?.uid === prevProps.selectedUnit?.uid &&
    newProps.selectedUnitClosestTocable?.definitionId === prevProps.selectedUnitClosestTocable?.definitionId &&
    newProps.editingNestedChange?.focused.definition?.id === prevProps.editingNestedChange?.focused.definition?.id &&
    newProps.currentInsertPosition === prevProps.currentInsertPosition
  );
}
