import { GetUnitOptions, GetOptions } from '../../../../clients/units';
import { VisualStates } from '../../../../components/editor/utils/DocumentVisualStates';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';
import { Banner, EditorModes, MenuTypes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class EditorMode {
  constructor(private attributes: number, protected syncQueue: SynchronizationQueue) {}

  getBanner(): Banner {
    return null;
  }

  // params returned here will be used during editor page load process
  getRetrieveUnitParams(): null | Partial<GetUnitOptions> | Partial<GetOptions> {
    return null;
  }

  isAllowed(modeAttr) {
    return modeAttr & this.attributes;
  }

  getDisabledMenuItems(): string[] {
    return [];
  }

  isMenuItemAllowed(menuItemAttr: MenuTypes) {
    return true;
  }

  // do stuff when this mode is activated
  activate(currentPageUnits: DocUnitWrapper[], options: any) {}

  // do stuff when this mode is deactivated
  deActivate() {}

  // when this mode transitions in or out, inform editor on how to reload:
  // none, softRefresh (from memory), hardRefresh (hard reload but no spinner), reload (with spinner), reloadToc (toc reloads and triggers hard reload with spinner)

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'RELOAD';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    return 'RELOAD';
  }

  // On entry of mode override listed VisualStates with value specified
  overrideVisualStates(): { state: VisualStates; value: boolean }[] {
    return [];
  }

  // specify lhs/rhs tabs to select when this mode is activated
  getActivatedMenuTabSelections(): { main: null | string; sub: null | string } {
    return { main: null, sub: null };
  }

  // specify lhs/rhs tabs to select when this mode is de-activated (assuming next mode has no getActivatedMenuTabSelections)
  getDeactivatedMenuTabSelections(): { main: null | string; sub: null | string } {
    return { main: null, sub: null };
  }

  // editor will call this when units are retrieved in this mode
  onActivationUnitsRetrieved(unitCollection: DocUnitWrapper[]) {}

  // allows access to whatever internal object data u want
  getParams(): any {
    return null;
  }

  // if another user modifies document in a mode, the mods will be will be queued here - default not queued
  // certain modes will have responsibility to exec the queue when they are activated (currently super simple RELOAD_TOC when they are switched)
  queueSynchronization(syncData) {
    return false;
  }

  _getFlushSyncQueueRefreshType(noQueueRefreshType: RefreshTypes): RefreshTypes {
    if (this.syncQueue.hasItems()) {
      this.syncQueue.clear();
      return 'RELOAD_TOC';
    } else {
      return noQueueRefreshType;
    }
  }
}
