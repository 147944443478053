import * as React from 'react';

export type DashboardPanelViewProps = {
  style?: React.CSSProperties;
  dataQa?: string;
  children: JSX.Element[];
};

const DashboardPanelView = ({ children, style, dataQa }: DashboardPanelViewProps) => {
  return (
    <div className="dashboard-tile" style={{ ...style }} data-qa={dataQa}>
      {children}
    </div>
  );
};

export default DashboardPanelView;
