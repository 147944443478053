import * as React from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import TextInput from './TextInput';
import { IconButton, Toggle, SelectField, MenuItem } from 'material-ui';
import { IUnit, IEditorStoreEvent } from 'mm-types';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { UNIT_LEVELS } from '../../../../../general/format-options/FormatOptions';
import useListenToStore from '../../../../../hooks/useListenToStore';
import usePrevious from '../../../../../hooks/usePrevious';

export type Props = {
  units: IUnit[];
};

export type State = {
  unitNumberingInfo: {
    hasAlternativeSetting: boolean;
    strategy?: null | string;
    override?: null | string;
    renderLevel?: null;
    regulationOverride?: string;
  };
  enableNumberInMemory: boolean;
};

const Numbering = (props: Props) => {
  const prevProps: Props = usePrevious<Props>(props);
  const [state, setState] = React.useState<State>({
    unitNumberingInfo: { hasAlternativeSetting: false, strategy: null, override: null, renderLevel: null },
    enableNumberInMemory: false
  });

  React.useEffect(() => {
    initialRender();
  }, [props.units]);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });

  const initialRender = () => {
    if (!props.units[0].uid) {
      setState({
        ...state,
        unitNumberingInfo: { hasAlternativeSetting: false, strategy: null, override: null, renderLevel: null },
        enableNumberInMemory: false
      });
    } else {
      if (props.units.length === 1) {
        const unit = EditorStore.getDocUnitModel(props.units[0].uid);

        if (unit) {
          // on page change unt may be gone
          const numberingInfo = getOrdinalSettings(unit.unit);

          const prevPropsUnits: IUnit[] = prevProps?.units ?? [];

          // efficiency
          if (!(_.differenceBy(props.units, prevPropsUnits, 'uid').length === 0 && _.isEqual(state.unitNumberingInfo, numberingInfo))) {
            updateOrdinalState(numberingInfo);
          }
        }
      } else {
        const allAlt = _.every(
          props.units.map((unit) => {
            const unitModel = EditorStore.getDocUnitModel(unit.uid);
            return getOrdinalSettings(unitModel!.unit)!.hasAlternativeSetting;
          })
        );

        setState({
          ...state,
          unitNumberingInfo: {
            hasAlternativeSetting: allAlt
          },
          enableNumberInMemory: false
        });
      }
    }
  };

  const getOrdinalSettings = (unit: IUnit) => {
    if (unit.isordinable) {
      const $unitDom = $('<div>' + unit.html + '</div>').find('.arc-unit');
      const strategy = $unitDom.attr('data-ordinal-strategy');
      const override = $unitDom.attr('data-ordinal-override')!;
      const renderLevel = $unitDom.attr('data-ordinal-render-start-level') || 'none';
      const level = $unitDom.attr('data-ordinal-level') || null;
      const regulationOverride = $unitDom.find('.arc-title').attr('data-reg-reference');

      if (unit.istocable) {
        return {
          hasAlternativeSetting: (!_.isUndefined(override) && override.length > 0) || renderLevel !== 'none',
          override: override,
          strategy: strategy,
          renderLevel: renderLevel,
          level: level,
          regulationOverride: regulationOverride
        };
      } else {
        // just P for now
        return {
          hasAlternativeSetting: strategy !== null && !_.isUndefined(strategy),
          override: override,
          strategy: strategy,
          renderLevel: renderLevel,
          level: level
        };
      }
    } else {
      return null;
    }
  };

  function onEditStoreUpdate(e: IEditorStoreEvent<'editorError'>) {
    if (e.type === 'editorError') {
      const err = e.data;
      let status = 0;
      let errorCode = 0;

      if (err && err.xhr) {
        status = err.xhr.status;
        errorCode = err.xhr.responseJSON && err.xhr.responseJSON.errors ? err.xhr.responseJSON.errors[0].code : null;
      } else if (err) {
        status = err.axiosErr!.response!.status;
        errorCode =
          err.axiosErr!.response!.data && err.axiosErr!.response!.data.errors ? err.axiosErr!.response!.data.errors[0].code : null;
      }

      // detect cannot turn numbering on for unit as will cause structural issue (can only on switching on non-tocable numbering)
      if (
        status === 400 &&
        (errorCode === 400171 || errorCode === 40032 || errorCode === 400326) &&
        e.data &&
        e.data.type !== 'unitMoved'
      ) {
        // revert unit and state back to original
        props.units.forEach((unit) => {
          const unitModel = EditorStore.getDocUnitModel(unit.uid)!;
          unitModel.setOrdinalSettings({ strategy: null, override: null });
          setState({
            ...state,
            unitNumberingInfo: { hasAlternativeSetting: false, strategy: null, override: null, renderLevel: null },
            enableNumberInMemory: false
          });
        });
      }
    }
  }

  const handleOrdStrategyChange = (value) => {
    let override = state.unitNumberingInfo.override;
    if (value !== 'ABS') {
      override = override ? override.slice(0, 7) : override;
    }
    updateAndSaveState({ strategy: value, override });
  };

  const handleLevelChange = (value) => {
    updateAndSaveState({ renderLevel: value });
  };

  const handleOrdinalTextChange = (newTxt) => {
    if (_.isUndefined(state.unitNumberingInfo.override) || newTxt !== state.unitNumberingInfo.override) {
      updateAndSaveState({ override: newTxt.trim() });
    }
  };

  const handleRegRefOverrideTextChange = (newTxt) => {
    if (_.isUndefined(state.unitNumberingInfo.regulationOverride) || newTxt !== state.unitNumberingInfo.regulationOverride) {
      updateAndSaveState({ regulationOverride: newTxt.trim() });
    }
  };

  const updateOrdinalState = (ordinalState, callback?: () => void) => {
    setState({ ...state, unitNumberingInfo: ordinalState, enableNumberInMemory: false });
    !!callback && callback();
  };

  const isValid = (unitNumberingInfo) => {
    return (
      unitNumberingInfo.strategy === 'NUM' ||
      unitNumberingInfo.strategy === null ||
      (unitNumberingInfo.override && unitNumberingInfo.override.length > 0)
    );
  };

  const updateAndSaveState = (unitNumberingInfoChanges: { [key: string]: string | number | null | undefined } = {}, update = true) => {
    const unitNumberingInfo = update ? _.assign(state.unitNumberingInfo, unitNumberingInfoChanges) : unitNumberingInfoChanges;

    if (!isValid(unitNumberingInfo)) {
      setState((prevState) => ({ ...prevState, ...unitNumberingInfo }));
    } else {
      updateOrdinalState(unitNumberingInfo, () => {
        EditorStore.overrideUnitOrdinal(
          _.map(props.units, 'uid'),
          unitNumberingInfo.strategy as string,
          unitNumberingInfo.override as string,
          unitNumberingInfo.renderLevel as number,
          unitNumberingInfo.level as number,
          unitNumberingInfo.regulationOverride as string
        );
      });
    }
  };

  const moveUnit = (move: 'demote' | 'promote') => {
    EditorStore.moveUnit(move, props.units[0]);
  };

  const handleNumberingToggle = () => {
    if (!_.every(props.units, 'istocable')) {
      if (isAltNumberOn()) {
        // disable
        updateAndSaveState({ strategy: null, renderLevel: null, override: null, level: null }, false);
      } else {
        // enable
        updateAndSaveState({ strategy: 'NUM' }, false);
      }
    } else {
      if (isAltNumberOn()) {
        // disable
        updateAndSaveState(
          {
            strategy: 'NUM',
            regulationOverride: state.unitNumberingInfo.regulationOverride
          },
          false
        );
      } else {
        // enable
        setState({ ...state, enableNumberInMemory: true }); // enables the form without backend state having to be enabled
      }
    }
  };

  const isAltNumberOn = () => {
    return state.enableNumberInMemory || state.unitNumberingInfo.hasAlternativeSetting;
  };

  const isParagraphSection = () => {
    const unitType = String(props.units[0].tocLevel);
    return props.units[0].type === 'paragraph' && unitType.indexOf('chapter') !== -1;
  };
  const isRegTocUnit = () => {
    const project = ProjectStore.getProject();
    return (
      project &&
      project.definitionName === 'regulation' &&
      props.units[0].istocable &&
      props.units[0].type !== 'chapter' &&
      props.units[0].type !== 'volume'
    );
  };

  if (!state.unitNumberingInfo) {
    return null;
  }

  const unitDefinition = ProjectDefinitionStore.getAllUnitDefinitions().find((ud) => ud.id === props.units[0].definitionId);
  return (
    <div className="selected-unit-props-section numbering-section">
      {ProjectDefinitionStore.getCurrentIndexDefinition()?.allowPromotionDemotion !== false &&
      props.units &&
      props.units.length === 1 &&
      props.units[0].istocable ? (
        <div className="promote-demote-actions">
          <label>Promote & Demote</label>
          <div className="promote-demote-buttons">
            <div className={unitDefinition?.canBePromoted === false ? 'disabled' : ''}>
              <IconButton
                onClick={() => {
                  moveUnit('promote');
                }}
                iconClassName="material-icons"
                tooltipPosition="top-left"
                tooltip="Promote"
                disabled={unitDefinition?.canBePromoted === false}
              >
                format_indent_decrease
              </IconButton>
            </div>
            <div className={unitDefinition?.canBeDemoted === false ? 'disabled' : ''}>
              <IconButton
                onClick={() => {
                  moveUnit('demote');
                }}
                iconClassName="material-icons"
                tooltipPosition="top-left"
                tooltip="Demote"
                disabled={unitDefinition?.canBeDemoted === false}
              >
                format_indent_increase
              </IconButton>
            </div>
          </div>
        </div>
      ) : undefined}

      {isRegTocUnit() ? (
        <TextInput
          name="regRefOverride"
          id="regRefOverride"
          value={state.unitNumberingInfo.regulationOverride!}
          onEnter={(e) => handleRegRefOverrideTextChange(e)}
          maxChars={99}
          fullWidth={true}
          className="editor-side-panel-text"
          floatingLabelText="Regulation Reference Override"
        />
      ) : undefined}
      <div className="editor-side-panel-toggle" onClick={() => handleNumberingToggle()}>
        <Toggle
          name="numbertoggle"
          id="numberingPanel"
          label={_.every(props.units, 'istocable') ? 'Alternative numbering' : 'Enable numbering'}
          disabled={isParagraphSection()}
          data-istoggled={isAltNumberOn()}
          toggled={isAltNumberOn()}
        />
      </div>
      {props.units.length === 1 ? (
        <div>
          <TextInput
            name="altNumber"
            id="alternateNumber"
            value={state.unitNumberingInfo.override!}
            onEnter={(e) => handleOrdinalTextChange(e)}
            maxChars={state.unitNumberingInfo.strategy === 'ABS' ? 99 : 7}
            fullWidth={true}
            className="editor-side-panel-text"
            floatingLabelText="Alternate Number"
            disabled={!isAltNumberOn()}
          />

          <div className="editor-side-panel-select">
            <SelectField
              id="numberingRule"
              floatingLabelText="Alternative Numbering Rule"
              value={state.unitNumberingInfo.strategy}
              onChange={(e, i, value) => {
                handleOrdStrategyChange(value);
              }}
              fullWidth={true}
              disabled={!isAltNumberOn()}
            >
              {[
                { text: 'Override', payload: 'NUM' },
                { text: 'Sibling Suffix', payload: 'SS' },
                { text: 'Absolute', payload: 'ABS' }
              ].map((item) => {
                return (
                  <MenuItem
                    key={item.payload}
                    value={item.payload}
                    primaryText={item.text}
                    className={`num-rule-item-${item.payload.toLowerCase()}`}
                  />
                );
              })}
            </SelectField>
          </div>

          <div className="editor-side-panel-select">
            <SelectField
              id="displayOrdinal"
              floatingLabelText="Display Ordinal from Level"
              value={state.unitNumberingInfo.renderLevel}
              onChange={(e, i, value) => {
                handleLevelChange(value);
              }}
              fullWidth={true}
              disabled={!isAltNumberOn()}
            >
              {UNIT_LEVELS.map((item) => {
                return <MenuItem key={item.payload} value={item.payload} primaryText={item.text} />;
              })}
            </SelectField>
          </div>

          {props.units && !props.units[0].istocable ? (
            <div className="promote-demote-actions">
              <label className={!isAltNumberOn() ? 'disabled' : ''}>Promote & Demote</label>
              <IconButton
                onClick={() => {
                  moveUnit('demote');
                }}
                disabled={!isAltNumberOn()}
                iconClassName="material-icons"
                tooltipPosition="bottom-center"
                tooltip="Demote"
              >
                format_indent_increase
              </IconButton>
              <IconButton
                onClick={() => {
                  moveUnit('promote');
                }}
                disabled={!isAltNumberOn()}
                iconClassName="material-icons"
                tooltipPosition="bottom-center"
                tooltip="Promote"
              >
                format_indent_decrease
              </IconButton>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
    </div>
  );
};

export default Numbering;
