import { IUnit } from 'mm-types';
import * as React from 'react';
import appStore from '../../../../appStore';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

export type Props = {
  unit: IUnit;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const DocUnitHTML = React.forwardRef((props: Props, ref: React.RefObject<HTMLDivElement>) => {
  const getContentMargin = () => {
    const contentMarginExemptTypes = ['header', 'frontmatter', 'chapter-frontmatter', 'tocable'];

    if (props.unit.hasOrdinal || contentMarginExemptTypes.indexOf(props.unit.type) !== -1) {
      return {};
    } else {
      const pageMargin = ProjectDefinitionStore.getPageMargin();

      let leftPaddingPercent = pageMargin ? pageMargin.EDITOR_PAGE_COL_WIDTH : 6.25;
      leftPaddingPercent *= appStore.getState().projectSettings?.contentMargin.value ?? leftPaddingPercent;

      return { paddingLeft: 'calc(' + leftPaddingPercent + '% + ' + pageMargin.APP_UNIT_PADDING_LEFT + ')' }; // ensure padding is additive to @app-unit-padding-left
    }
  };

  function lazyLoadImages(html: string) {
    // Use a regular expression to match all occurrences of '<img'
    const modifiedString = html.replace(/<img/g, '<img loading="lazy"');
    return modifiedString;
  }

  const levelOrType = props.unit.level || props.unit.type;
  const additionalClassName = props.unit.type === 'removed' && levelOrType !== 'removed' ? 'document-unit-inner-removed' : '';

  return (
    <div
      key={props.unit.uid + props.unit.versionUid}
      dangerouslySetInnerHTML={{ __html: lazyLoadImages(props.unit.html) }}
      id={'_' + props.unit.uid}
      className={`document-unit-inner document-unit-inner-${levelOrType} ${additionalClassName}`}
      style={getContentMargin()}
      onClick={props.onClick}
      ref={ref}
    ></div>
  );
});
