import * as React from 'react';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { IActivity, IActivityDescription } from 'mm-types';

export type Props = {
  onNextPageScroll: () => void;
  onListRefreshRequest: () => void;
  activityEntries: IActivity[] | null;
  loading: boolean;
  loadingScroll: boolean;
  ref: string;
};

const UndoList = (props: Props) => {
  const handleUndoRedo = (selectedEntry: IActivity) => {
    const editor = EditorStore.getEditor();

    // ensure we save any editing changed before undo-ing!
    if (editor.isFocused()) {
      editor.blur({}, () => {
        performBatchUndoRedo(selectedEntry);
      });
    } else {
      performBatchUndoRedo(selectedEntry);
    }
  };

  const performBatchUndoRedo = (selectedEntry: IActivity) => {
    let entry: IActivity | null = null;
    const activityEntries = props.activityEntries ?? [];
    if (selectedEntry.unDone) {
      entry = selectedEntry;
    } else {
      const selectedIndex = activityEntries.findIndex(function (a) {
        return selectedEntry.uid === a.uid;
      });

      if (selectedIndex === activityEntries.length - 1) {
        entry = activityEntries[selectedIndex];
        entry = entry ? entry : selectedEntry;
        entry!.uid = null;
      } else {
        entry = activityEntries[selectedIndex + 1];
        entry = entry ? entry : selectedEntry;
      }
    }

    if (entry) {
      const e: {
        targetUnitUid: string;
        targetVolumeUid?: string;
        targetChapterUid?: string;
        targetSectionUid?: string;
      } = {
        targetUnitUid: entry.unitUid
      };

      // determine the correct owning parent
      if (entry.type === 'volume') {
        e.targetVolumeUid = entry.unitUid;
      } else if (entry.type === 'chapter' || entry.type === 'appendix-chapter') {
        e.targetChapterUid = entry.unitUid;
      } else if (entry.type === 'section') {
        e.targetSectionUid = entry.unitUid;
      } else {
        if (entry.sectionUid) {
          e.targetSectionUid = entry.sectionUid;
        } else if (entry.chapterUid) {
          e.targetChapterUid = entry.chapterUid;
        } else if (entry.volumeUid) {
          e.targetVolumeUid = entry.volumeUid;
        }
      }

      EditorStore.batchUndoRedo(entry);
    } else {
      console.log('Cannot perform redo...');
    }
  };

  const getName = (child: IActivity) => {
    const name = child.description
      ? typeof child.description === 'string' && child.description
        ? child.description
        : (child.description as IActivityDescription).name
      : '';
    if (child.display === 'Variant Tag Added') {
      return (
        <div className="activity-item-info">
          <div>Variant Label:</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    }

    if (child.display === 'Variant Tag Removed') {
      return (
        <div className="activity-item-info">
          <div>Variant Label:</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    } else if (child.operation === 'unit_concept_tagged' || child.operation === 'unit_concept_untagged') {
      return (
        <div className="activity-item-info">
          <div>Tag Label</div>
          <span className="activity-item-unit"> {name} </span>
        </div>
      );
    }
  };

  return (
    <div>
      {!props.loading && props.activityEntries && props.activityEntries.length > 0 ? (
        <ul className="activity-list">
          {props.activityEntries.map((entry) => {
            return (
              <li key={entry.uid} className={entry.unDone ? 'activity-greyout' : ''} onClick={(e) => handleUndoRedo(entry)}>
                <div>
                  <div className="icon-activity-outer">
                    <div className={`icon-activity icon-activity-${entry.unDone ? entry.redo : entry.icon}`}></div>
                  </div>

                  <div className=" activity-items-container">
                    <div className="activity-item">
                      <div className="activity-item-name">{entry.display}</div>
                      <div className="activity-item-time">{entry.formatted}</div>
                    </div>

                    <div className="activity-item">
                      <div className="activity-item-description" style={{ marginTop: 1 + 'px', marginBottom: 1 + 'px' }}>
                        {entry.entityOrdinal ? entry.entityOrdinal + ' ' : ''}
                        {entry.description && entry.description.hasOwnProperty('text')
                          ? (entry.description as IActivityDescription).text
                          : entry.description}
                      </div>
                      {entry.description && (entry.description as IActivityDescription).name ? (
                        <div className="activity-item-label"> {getName(entry)}</div>
                      ) : null}
                      <div className="activity-item-user">{entry.user ? entry.user.displayName : ''}</div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : undefined}
    </div>
  );
};

export default UndoList;
