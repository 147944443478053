import * as React from 'react';
import { useRef, useState } from 'react';
import EditorStore from '../../../../../../../flux/editor/EditorStore';
import { IconButton } from 'material-ui';
import ProjectDefinitionStore, {
  ProjectDefinitionStoreEvent,
  ProjectDefinitionStoreEventType
} from '../../../../../../../flux/common/ProjectDefinitionStore';
import useListenToStore from '../../../../../../hooks/useListenToStore';
import Portal from '../../../../../../misc/Portal';
import { Color } from 'mm-types';
import ColorSwatch from './ColorSwatch';

interface Props {
  onChange(color: Color): void;
  color?: Color;
  source?: 'ColorText' | 'BackgroundColorText';
  icon?: 'format_color_fill' | 'format_color_text' | string;
  tooltip?: string;
  disabled?: boolean;
  iconStyle?: React.CSSProperties;
}

const ColorPicker = ({ onChange, color, source, icon = 'format_color_fill', tooltip, disabled, iconStyle }: Props) => {
  const [colors, setColors] = useState<Color[]>([]);
  const [display, setDisplay] = useState<boolean>(false);

  const pickerModalRef = useRef<HTMLDivElement | null>(null);
  const swatchRef = useRef<HTMLDivElement | null>(null);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  useListenToStore({ store: ProjectDefinitionStore, eventListener: onProjectStoreUpdate, update: [source] });
  useListenToStore({ store: EditorStore, eventListener: subscribeToTinyEvent });

  React.useEffect(() => {
    setColors(ProjectDefinitionStore.getElementColors(source));
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    if (pickerModalRef.current) {
      pickerModalRef.current.style.display = display ? '' : 'none';
    }
  }, [display]);

  function subscribeToTinyEvent(e = { type: null }) {
    if (e.type === 'nestedUnitFocusChange') {
      setDisplay(false);
    }
  }

  function onProjectStoreUpdate(e: ProjectDefinitionStoreEvent) {
    if (e.type === ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS) {
      setColors(ProjectDefinitionStore.getElementColors(source));
    }
  }

  const popupPosition = () => {
    const pickerSize = 324;

    if (swatchRef.current?.getBoundingClientRect()) {
      const boundingRect = swatchRef.current.getBoundingClientRect();
      const left = boundingRect.left + pickerSize > document.body.clientWidth ? document.body.clientWidth - pickerSize : boundingRect.left;
      const top =
        boundingRect.top + pickerSize > document.body.clientHeight
          ? document.body.clientHeight - pickerSize
          : boundingRect.top + boundingRect.height;
      return {
        position: 'absolute',
        zIndex: '254', // needs to be above hovered unit effects
        left: left,
        top: top
      };
    } else {
      return {};
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisplay(!display);
  };

  const handleChange = (color: Color) => {
    const editor = (EditorStore as any).getEditor();
    if (editor.isFocused()) {
      editor.silentReFocus();
    }
    setDisplay(false);
    onChange(color);
  };

  const handleOutsideClick = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };

  return (
    <div className={`color-picker-component force-focus ${disabled ? 'disabled' : ''}`}>
      <div className={'swatch '} onClick={(e) => handleClick(e)} ref={swatchRef}>
        <IconButton
          iconClassName={'material-icons'}
          tooltipPosition="bottom-center"
          tooltip={tooltip}
          tooltipStyles={{ marginTop: '8px' }}
          disabled={disabled}
          style={iconStyle}
        >
          {icon}
        </IconButton>
        <div className="color" style={{ backgroundColor: color?.day?.value }} />
      </div>
      <Portal parentComponentId={'color-picker'} display={display}>
        <div id="p-color-picker" style={{ display: display ? '' : 'none' }} ref={pickerModalRef}>
          <div className="cover">
            <div style={popupPosition() as React.CSSProperties} ref={colorPickerRef}>
              <ColorSwatch onChange={(color) => handleChange(color)} colors={colors} clearColor={true} />
            </div>
          </div>
        </div>
      </Portal>
    </div>
  );
};

export default ColorPicker;
