import * as React from 'react';
import { LinearProgress, Dialog } from 'material-ui';

interface Props {
  show: boolean;
}

const MediaUploadDialog = (props: Props) => {
  if (!props.show) {
    return null;
  }
  return (
    <Dialog className="upload-dialog" style={{ zIndex: 999 }} title="Uploading Files... please wait..." open={true} modal={true}>
      <LinearProgress mode="indeterminate" />
    </Dialog>
  );
};

export default MediaUploadDialog;
