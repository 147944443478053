import * as React from 'react';
import { isEnterKey, isEscKey, isShiftTabKeys, isTabKey } from '../../keyIdentifier';

export default (
  e: React.KeyboardEvent<any>,
  onTab: (e: React.KeyboardEvent<any>, shiftKeyPressed?: boolean) => void,
  currentRef,
  ignoreTab = false,
  ignoreShiftTab = false
) => {
  e.persist();
  if (isEnterKey(e) || isEscKey(e)) {
    currentRef!.current!.blur();
  } else if (isShiftTabKeys(e) && !ignoreShiftTab) {
    onTab(e, true);
  } else if (isTabKey(e) && !isShiftTabKeys(e) && !ignoreTab) {
    onTab(e, false);
  }
};
