import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: '',
  inlineOptions: {
    readonly: true
  },
  template: '',
  nonSelectable: true
};
