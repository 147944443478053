import * as React from 'react';
import Toggle from 'material-ui/Toggle';
import VerticalSlide from '../../../general/animations/VerticalSlide';

interface Props {
  togglable?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  defaultOpen?: boolean;
  label: string;
  id: string;
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="dialog-section" style={{ paddingBottom: 24 }}>
    {children}
  </div>
);
const SectionToggle = ({ children, id }: { children: React.ReactNode; id: string }) => (
  <div data-qa={id} id={id} className="section-toggle">
    {children}
  </div>
);
const SectionContent = ({ children, id }: { children: React.ReactNode; id: string }) => (
  <div data-qa={id} id={id} className="section-content">
    {children}
  </div>
);

const LABEL_STYLE: React.CSSProperties = {
  color: '#000',
  margin: '5px 0',
  fontWeight: 'bold',
  fontSize: '1rem'
};

const DialogSection = ({ togglable, children, label, id, disabled, defaultOpen }: Props) => {
  const [visible, setVisible] = React.useState<boolean>(!!defaultOpen);
  const toggleId = id + '-section-toggle';
  const contentId = id + '-section-content';

  if (!togglable) {
    return (
      <Container>
        <SectionToggle id={toggleId}>
          <h6 style={LABEL_STYLE}>{label}</h6>
        </SectionToggle>
        <SectionContent id={contentId}>{children}</SectionContent>
      </Container>
    );
  }

  return (
    <Container>
      <SectionToggle id={toggleId}>
        <Toggle
          name={id + '-toggle'}
          label={label}
          labelStyle={LABEL_STYLE}
          disabled={!!disabled}
          value=""
          toggled={visible}
          onToggle={() => setVisible(!visible)}
        />
      </SectionToggle>
      <SectionContent id={contentId}>
        <VerticalSlide open={visible}>{children}</VerticalSlide>
      </SectionContent>
    </Container>
  );
};

export default DialogSection;
