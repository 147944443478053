import ComplianceEditProps from '../../../../sidetabs/sub/editComponent/components/ComplianceEditProps';
import TinyCustomKeyListener from '../../../tinyFacade/TinyCustomKeyListener';
import { CustomEditor } from '../../../tinyFacade/EditorInstanceManager';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Compliance',
  getLabel: () => 'COMP REF',
  identifyingClasses: ['arc-compliance-tag'],
  outerClass: 'arc-compliance-tag',
  openEditPaneOnSelect: true,

  editPropsComponent: ComplianceEditProps,
  template: '',

  arcMLDefaults: {
    root: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  menu: {},
  menuTextSelected: {},

  sanitize: function (tinyMCEContent) {
    const content = $('<div>' + tinyMCEContent + '</div>');
    const $complianceTypeEl = content.find('.arc-compliance-tag-type');
    const complianceType = $complianceTypeEl.text();

    if (complianceType.charAt(complianceType.length - 1) === '-') {
      $complianceTypeEl.text(complianceType.substr(0, complianceType.length - 1));
    }
    return content.html();
  },

  editor: {
    plugins: [],
    forced_root_block: '',
    formats: {
      bold: { inline: 'span', classes: 'donothing' },
      italic: { inline: 'span', classes: 'donothing' },
      underline: { inline: 'span', classes: 'donothing' },
      superscript: { inline: 'span', classes: 'donothing' },
      subscript: { inline: 'span', classes: 'donothing' },
      code: { inline: 'span', classes: 'donothing' },
      forecolor: { inline: 'span', classes: 'donothing' },
      hilitecolor: { inline: 'span', classes: 'donothing' }
    },
    valid_elements: 'div[class]',
    setup: function (editor) {
      TinyCustomKeyListener.applySingleLineNoDelete(editor as CustomEditor);
    },
    init_instance_callback: function (editor) {
      EditorStore.getEditor().getActiveEditorFacade()!.setContentEditable(false);
    }
  }
};
