import * as React from 'react';

export type Props = {
  onClose?: () => void;
  showClose?: boolean;
};

const CloseModeBar = ({ showClose = false, onClose }: Props) => {
  if (!showClose) {
    return null;
  }

  return (
    <div
      className="right-side-buttons"
      onClick={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <span className="icon-close cursor-pointer"></span>
    </div>
  );
};

export default CloseModeBar;
