import * as React from 'react';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import { DataAttributePropsType } from '../DataAttributePropsType';
import { IDataAttribute } from 'mm-types';
import { DateTimeInput, DateTimeInputType } from '../../../../../general/DateTimeInput';
import moment from 'moment';

type PropsType = DataAttributePropsType & {
  onValidation: (hasError: boolean, dataAttribute: IDataAttribute) => void;
  type: string;
};

export const DateTimeAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, isRequired, handleAction, onValidation } = props;
  const [dateTime, setDateTime] = React.useState<Date | null>();
  const dateTimeInputRef = React.useRef<DateTimeInputType>();

  React.useEffect(() => {
    const dateString = dataAttributesValues?.[dataAttribute.id];
    if (dateString == '') {
      setDateTime(null);
    } else if (dateString) {
      const date = new Date(dateString);
      setDateTime(date);
    }
  }, [dataAttribute, Object.keys(dataAttributesValues).length]);

  const onDateChange = (date: Date | null) => {
    if (date) {
      const newDate = moment(date);
      const oldTime = moment(dateTime);
      newDate.set({ hour: oldTime.hour(), minute: oldTime.minutes() });
      setDateTime(newDate.toDate());
      handleAction(dataAttribute, newDate.toDate().toISOString().slice(0, -5), true);
    }
  };

  const onChangeTime = (date: Date) => {
    if (date) {
      setDateTime(date);
      handleAction(dataAttribute, date.toISOString().slice(0, -5), true);
    }
  };

  const onClearDateTime = () => {
    setDateTime(null);
    handleAction(dataAttribute, '', true);
  };

  return (
    <div data-qa="data-attribute">
      <div data-qa="data-attribute-string">
        {typeof dateTime !== 'undefined' && (
          <DateTimeInput
            ref={dateTimeInputRef}
            value={dateTime}
            selectedLabel={dateTime?.toISOString().slice(0, -5)}
            isDisabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
            onClearDateTime={isRequired(dataAttribute) ? undefined : onClearDateTime}
            onUpdateDate={onDateChange}
            onUpdateTime={onChangeTime}
            isRequired={isRequired(dataAttribute)}
            errorMessage={dataAttribute.validationMessage}
            onValidation={(hasError) => {
              onValidation && onValidation(hasError, dataAttribute);
            }}
            classPrefix="date-attribute_"
          />
        )}
      </div>
    </div>
  );
};
