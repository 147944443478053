import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';

import Toggle from 'material-ui/Toggle';
import FontIcon from 'material-ui/FontIcon';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import DocUnit from '../docUnit/DocUnit';
import { GetOneOptions } from '../../../clients/units';
import ProjectStore from '../../../flux/editor/ProjectStore';
import MergeRevisionsStore, { MergeRevisionsStoreEvent } from '../../../flux/editor/MergeRevisionsStore';
import { IIndex, IUnit, IIndexMergeActivity } from 'mm-types';
import { Point } from './mergeTypes';
import revutil from '../revisionbars/revutil';

export type Props = {
  onAction?: (action: 'exit' | 'accept' | 'reject') => void;
  onShowDialog?: () => void;
  masterIndex: IIndex;
  mergeIndex: IIndex;
  isMasterDraft?: boolean;
  onUnitPositionChange: (type: string, position: Point) => void;
};

export type State = {
  units: IUnit[];
  selectedActivity: null | IIndexMergeActivity;
  selectedUnitUid: null | string;
  selectedUnitShareIndexUid: null | string;
  position: null | number;
  isDiffOn: boolean;
  busy: boolean;
};

export default class RevisionDocument extends React.Component<Props, State> {
  private _pageContainerRef: HTMLElement | null;
  private _$pageContainer: JQuery<HTMLElement> | null;
  private _$revisionPageUnits: JQuery | null;
  private unsub: Function;

  constructor(props: Props) {
    super(props);

    this._pageContainerRef = null;
    this._$pageContainer = null;
    this._$revisionPageUnits = null;
    this.unsub = MergeRevisionsStore.listen(this._onStoreUpdate, this);

    const hasActivities = MergeRevisionsStore.state.activities && MergeRevisionsStore.state.activities.length > 0;

    this.state = {
      units: [],
      selectedActivity: null,
      selectedUnitUid: null,
      selectedUnitShareIndexUid: null,
      position: null,
      isDiffOn: true,
      busy: hasActivities ? true : false
    };
  }

  componentWillUnmount() {
    this.unsub();
  }

  componentDidMount() {
    this._storeElReferences();
  }

  triggerUnitPositionCalc() {
    if (this.state.selectedActivity) {
      const $unitEl = this._getSelectedUnitEl();
      const navBarHeight = 65;

      if ($unitEl) {
        const position = $unitEl.offset()!;
        const unitWidth = this._$revisionPageUnits!.width()!;
        const color = this.state.selectedActivity.listEntry.color;
        const lineWidthOffset = 2;
        const selectedUnitPosition = {
          x: position.left + (this.props.isMasterDraft ? lineWidthOffset : unitWidth - lineWidthOffset),
          y: position.top - navBarHeight + $unitEl.height()! / 2,
          color: color
        };

        this.props.onUnitPositionChange(this.props.isMasterDraft ? 'to' : 'from', selectedUnitPosition);
      }
    }
  }

  _storeElReferences() {
    this._pageContainerRef! = ReactDOM.findDOMNode(this.refs.editingScrollContainer) as HTMLElement;
    this._$pageContainer = $(this._pageContainerRef!);
    this._$revisionPageUnits = this._$pageContainer!.find('.revision-page-units');
  }

  _onStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'retrieveActivity') {
      let units: IUnit[] | undefined = undefined;
      let selectedUnitUid = e.state.selectedActivity!.unitUid;
      let selectedUnitShareIndexUid;
      let position;

      if (this.props.isMasterDraft) {
        units = e.state.selectedActivity?.masterUnits;
      } else {
        units = e.state.selectedActivity?.interimRevisionUnits;
      }

      if (
        this.props.isMasterDraft &&
        e.state.selectedActivity?.status === 'INAPPLICABLE' &&
        e.state.selectedActivity?.operation === 'DELETED_UNIT'
      ) {
        const selectedUnit = e.state.selectedActivity?.interimRevisionUnits?.find((unit: IUnit) => unit.uid === selectedUnitUid);
        if (selectedUnit?.shareDetails) {
          selectedUnitShareIndexUid = selectedUnit!.shareDetails.sharedIndexUid;
          position = selectedUnit?.shareDetails.position;
        }
      }
      if (this.props.isMasterDraft && _.isUndefined(units?.find((unit: IUnit) => unit.uid === selectedUnitUid))) {
        selectedUnitUid = 'temporaryCreatedUnitPlaceholder';
      }

      this._storeElReferences();

      // clear first as docunits don't deal with permissions well on props change
      this.setState({ units: [] }, () => {
        this.setState(
          {
            units: units!,
            selectedUnitUid: selectedUnitUid,
            selectedActivity: e.state.selectedActivity,
            selectedUnitShareIndexUid: selectedUnitShareIndexUid,
            position: position,
            busy: false
          },
          () => {
            const $unitEl = this._getSelectedUnitEl()!;
            if ($unitEl) {
              this._pageContainerRef!.scrollTop = 0;

              // animate to same position
              setTimeout(() => {
                if ($unitEl.length) {
                  const unitTopPosition = $unitEl.offset()!.top;
                  this._$pageContainer![0].scrollTop = unitTopPosition - this._$pageContainer!.offset()!.top - 57;
                }
              }, 150);

              this._showDiffForSelectedUnit(this.state.isDiffOn);

              // ensure page is loaded!!
              setTimeout(() => {
                this.triggerUnitPositionCalc();
              }, 300);
            }
          }
        );
      });
    }
  }

  _getSelectedUnitEl() {
    const selector = ' .document-unit-outer.selected .document-unit-inner';
    const $unitEl = $((this.props.isMasterDraft ? '.revision-page-draft' : '.revision-page-interim') + selector);
    return $unitEl.length ? $unitEl : null;
  }

  _handleFooterAction(action: 'exit' | 'accept' | 'reject') {
    this.setState({ busy: true }, () => {
      if (this.props.onAction) {
        this.props.onAction(action);
      }
    });
  }

  _temp() {}

  _toggleDiff() {
    this._showDiffForSelectedUnit(!this.state.isDiffOn);
  }

  async _showDiffForSelectedUnit(isDiffOn: boolean) {
    let selectedUnit;
    if (this.state.selectedUnitShareIndexUid) {
      selectedUnit = this._getSelectedSharedUnit();
    } else {
      selectedUnit = this.state.units.find((unit: IUnit) => unit.uid === this.state.selectedUnitUid);
    }

    // note: there may be no unit in case of created
    if (selectedUnit) {
      if (!isDiffOn) {
        selectedUnit.html = selectedUnit.unDiffedHtml ? selectedUnit.unDiffedHtml : selectedUnit.html;
        this.setState({ isDiffOn: isDiffOn, units: this.state.units });
      } else {
        const data: Partial<GetOneOptions> = {
          diffUnitActivityUid: this.state.selectedActivity!.activityUid,
          reverseDiff: true
        };

        selectedUnit.unDiffedHtml = selectedUnit.unDiffedHtml ? selectedUnit.unDiffedHtml : selectedUnit.html;

        if (this.props.isMasterDraft) {
          const diffedUnit = await MergeRevisionsStore.getDiffedUnit(selectedUnit.uid, data);
          selectedUnit.html = diffedUnit.html;
        }

        this.setState({ isDiffOn: isDiffOn, units: this.state.units });
      }
    } else {
      this.setState({ isDiffOn: isDiffOn });
    }
  }

  _getSelectedUnitHtml(type: 'interimRevisionUnits' | 'masterUnits') {
    if (this.state.selectedActivity) {
      const units: IUnit[] =
        type === 'interimRevisionUnits' ? this.state.selectedActivity.interimRevisionUnits : this.state.selectedActivity.masterUnits;

      const unit = units.find((unit: IUnit) => unit.uid === this.state.selectedActivity!.unitUid);
      return unit ? unit.html : '';
    }
    return '';
  }

  _getOffsetNode() {
    return $(`#_${this.state.selectedActivity!.unitUid} div[data-nid="${this.state.selectedActivity!.unitUid}"]`).parent();
  }

  _isSelectedUnit(docUnit) {
    if (this.state.selectedUnitShareIndexUid && docUnit.shareDetails!) {
      return docUnit.shareDetails.sharedIndexUid === this.state.selectedUnitShareIndexUid;
    } else {
      return docUnit.uid === this.state.selectedUnitUid;
    }
  }

  _getSelectedSharedUnit() {
    let selectedUnit;
    for (const unit of this.state.units) {
      if (
        unit.shareDetails &&
        unit.shareDetails!.sharedIndexUid === this.state.selectedUnitShareIndexUid &&
        unit.shareDetails!.position === this.state.position
      ) {
        selectedUnit = unit;
        break;
      }
    }
    return selectedUnit ? selectedUnit : null;
  }

  _hasPageBreak($offsetNode) {
    return $offsetNode.siblings('.page-break-settings.before')[0];
  }

  _getInapplicableIcon(): undefined | JSX.Element {
    const selectedActivity: null | IIndexMergeActivity = this.state.selectedActivity;

    if (!this.props.isMasterDraft && selectedActivity && selectedActivity.status === 'INAPPLICABLE') {
      let $offsetNode = this._getOffsetNode();
      if (this._hasPageBreak($offsetNode)) {
        $offsetNode = $offsetNode.parent();
      }

      const style: React.CSSProperties = {
        top: revutil.calc_offset_top($offsetNode[0], 'revision-page') - 6
      };

      return (
        <span className={'inapplicable-merge-item-icon'} style={style} onClick={() => this.props.onShowDialog && this.props.onShowDialog()}>
          <FontIcon color={'#f67d04'} className={'material-icons'}>
            error_outline
          </FontIcon>
        </span>
      );
    }
  }

  _isNotActiveActivity(): boolean {
    const $firstActivity = $('[data-activity-uid]:not(.activity-greyout)').first();
    return (
      !!$firstActivity.length &&
      !!this.state.selectedActivity &&
      $firstActivity.data('activity-uid') !== this.state.selectedActivity.activityUid
    );
  }

  // TODO if enhancing any more (e.g. with comments etc) may be simpler to first refactor below to and break into more components
  // and therefore simplify this components responsibilities...
  render() {
    const mergeRevNumber = this.props.mergeIndex.revisionNumber;
    const inapplicableMergeIcon: undefined | JSX.Element = this._getInapplicableIcon();
    const isNotActiveActivity: boolean = this._isNotActiveActivity();

    return (
      <div className={'revision-page ' + (this.props.isMasterDraft ? 'revision-page-draft' : 'revision-page-interim')}>
        <div className="revision-banner-label">{this.props.isMasterDraft ? 'Draft' : 'Interim ' + mergeRevNumber}</div>
        <div className="mode-bar">
          {this.props.isMasterDraft ? <div className="message">Draft</div> : <div className="message">Interim Changes</div>}
        </div>

        <div
          className="revision-page-units-outer"
          ref="editingScrollContainer"
          onScroll={_.throttle(() => this.triggerUnitPositionCalc(), 300)}
        >
          <div
            className={'revision-page-units' + (this.state.selectedActivity ? ' ' + this.state.selectedActivity.listEntry.colorClass : '')}
          >
            {inapplicableMergeIcon}

            {this.state.units.map((docUnit) => {
              const selected = this._isSelectedUnit(docUnit);
              const unitDOM: JSX.Element[] = [];

              // UNIT OF INTEREST IS DELETED: rather than render ghost, show red unit with draft/interim contents (whichever has the contents)
              if (selected && docUnit.type === 'ghost') {
                unitDOM.push(
                  <div
                    id={docUnit.uid}
                    className="document-unit-outer document-unit-outer-ghost selected readonly-unit mock-unit interim-revision-deleted-mock-unit"
                  >
                    <div className="document-unit-inner document-unit-inner-ghost">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this._getSelectedUnitHtml(this.props.isMasterDraft ? 'masterUnits' : 'interimRevisionUnits')
                        }}
                      ></div>
                    </div>
                  </div>
                );
              } else {
                const isUnitCommentable = selected && this.props.isMasterDraft && ProjectStore.canReadComment();

                unitDOM.push(
                  <DocUnit
                    key={docUnit.uid}
                    unit={docUnit}
                    forceInefficientUpdate={true}
                    readOnlyOverride={true}
                    permissionsOverride={{
                      isUnitInsertableAfter: false,
                      isChangeTasksVisible: false,
                      isUnitEditable: false,
                      isUnitCommentable: isUnitCommentable,
                      deletionAllowed: false
                    }}
                    selected={selected}
                    onClicked={(e) => this._temp()}
                    onFocused={(e) => this._temp()}
                    onAction={(e) => this._temp()}
                    ref={'unitComponent_' + docUnit.uid}
                  />
                );
              }

              // NEW DRAFT UNIT: item that doesn't exist in draft: render as green unit and insert interim contents
              if (this.state.selectedUnitUid === 'temporaryCreatedUnitPlaceholder') {
                if (
                  this.state.selectedActivity!.status !== 'INAPPLICABLE' &&
                  this.state.selectedActivity!.afterMasterUnitUid === docUnit.uid
                ) {
                  unitDOM.push(
                    <div
                      id="_temporaryCreatedUnitPlaceholder"
                      className="document-unit-outer selected readonly-unit mock-unit interim-revision-created-mock-unit"
                    >
                      <div className="document-unit-inner">
                        <div dangerouslySetInnerHTML={{ __html: this._getSelectedUnitHtml('interimRevisionUnits') }}></div>
                      </div>
                    </div>
                  );
                }
              }

              return unitDOM;
            })}

            {this.state.units.length === 0 ? <div></div> : null}
          </div>
        </div>

        <div className="actions-footer">
          {this.props.isMasterDraft ? (
            <div className="diffing-mode">
              <i className="material-icons">compare</i>

              <div className={'diffing-toggle-container ' + (this.state.isDiffOn ? 'diff-on' : 'diff-off')}>
                <Toggle
                  label={'Show Diff'}
                  labelStyle={{ width: 'calc(100% - 90px)' }}
                  toggled={this.state.isDiffOn}
                  onToggle={() => this._toggleDiff()}
                />
              </div>
            </div>
          ) : null}

          {this.props.isMasterDraft ? (
            <div className="buttons">
              {this.state.busy ? (
                <div className="waiting">
                  <CircularProgress mode="indeterminate" size={17.85} />
                </div>
              ) : null}

              {this.state.selectedActivity !== null && this.state.selectedActivity.status !== 'INAPPLICABLE' ? (
                <RaisedButton
                  disabled={
                    isNotActiveActivity ||
                    this.state.busy ||
                    (this.state.selectedActivity && !this.state.selectedActivity.listEntry.isMergable)
                  }
                  className={
                    'reject-button ' + (this.state.selectedActivity && !this.state.selectedActivity.listEntry.isMergable ? 'disabled' : '')
                  }
                  label="Reject"
                  labelPosition="after"
                  onClick={() => {
                    this._handleFooterAction('reject');
                  }}
                  style={{ background: '#a2a2a2' }}
                >
                  <FontIcon className="material-icons">close</FontIcon>
                </RaisedButton>
              ) : null}

              {this.state.selectedActivity === null ||
              (this.state.selectedActivity !== null && this.state.selectedActivity.status !== 'INAPPLICABLE') ? (
                <RaisedButton
                  disabled={
                    isNotActiveActivity ||
                    this.state.busy ||
                    (this.state.selectedActivity && !this.state.selectedActivity.listEntry.isMergable)
                      ? true
                      : false
                  }
                  className={
                    'accept-button ' + (this.state.selectedActivity && !this.state.selectedActivity.listEntry.isMergable ? 'disabled' : '')
                  }
                  label="Accept"
                  labelPosition="after"
                  secondary={true}
                  onClick={() => {
                    this._handleFooterAction('accept');
                  }}
                >
                  <FontIcon className="material-icons">check</FontIcon>
                </RaisedButton>
              ) : undefined}

              {this.state.selectedActivity !== null && this.state.selectedActivity.status === 'INAPPLICABLE' ? (
                <RaisedButton
                  disabled={isNotActiveActivity || this.state.busy}
                  className="acknowledge-button"
                  label="Acknowledge"
                  labelPosition="after"
                  secondary={true}
                  onClick={() => {
                    this._handleFooterAction('accept');
                  }}
                ></RaisedButton>
              ) : undefined}

              <RaisedButton
                className="exit-button"
                label="Exit"
                onClick={() => {
                  this._handleFooterAction('exit');
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
