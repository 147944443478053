import * as React from 'react';
import { IElementDefinition } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';

export function useEffectivityElementDefinition(): IElementDefinition[] {
  const [effectivityDefinitions, setEffectivityDefinitions] = React.useState<IElementDefinition[]>(
    ProjectDefinitionStore.getContentMenuElements().filter((element) => element.type === 'EffectivityTag' && !!element.subType)
  );

  React.useEffect(() => {
    setEffectivityDefinitions(
      ProjectDefinitionStore.getContentMenuElements().filter((element) => element.type === 'EffectivityTag' && !!element.subType)
    );
  }, []);

  return effectivityDefinitions;
}
