import * as React from 'react';
import { IProjectActivity } from 'mm-types';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const SharedIndex = ({ entry }: PropsType) => {
  const sharedIndexDom: JSX.Element[] = [];
  const contentList = ['name', 'description', 'allowDerivatives', 'isPublic'];

  if (entry.sharedIndex) {
    if (entry.projectActivityType === 'SHARED_INDEX_UPDATED') {
      contentList.forEach((key) => {
        if (entry.sharedIndex && entry.sharedIndex[key]) {
          let name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
            return str.toUpperCase();
          });
          const val = entry.sharedIndex[key];
          let valueText = val;
          if (_.isBoolean(val)) {
            valueText = val ? 'Yes' : 'No';
          }

          if (key === 'isPublic') {
            name = 'Public';
            valueText = val ? 'Yes' : 'No';
          }
          sharedIndexDom.push(
            <div key={key}>
              <label>{name}:</label> <span>{valueText}</span>
            </div>
          );
        }
      });
    } else {
      contentList.forEach((key) => {
        if (key === 'description') {
          const name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
            return str.toUpperCase();
          });
          let val = entry.sharedIndex && entry.sharedIndex[key];
          if (!val) {
            val = 'No description';
          }
          sharedIndexDom.push(
            <div key={name}>
              <label>{name}:</label> <span>{val}</span>
            </div>
          );
        } else {
          let name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
            return str.toUpperCase();
          });
          const val = entry.sharedIndex && entry.sharedIndex[key];
          let valueText = val;
          if (_.isBoolean(val)) {
            valueText = val ? 'Yes' : 'No';
          }

          if (key === 'isPublic') {
            name = 'Public';
            valueText = val ? 'Yes' : 'No';
          }
          sharedIndexDom.push(
            <div key={key}>
              <label>{name}:</label> <span>{valueText}</span>
            </div>
          );
        }
      });
    }
  }
  return <div className="settings-details">{sharedIndexDom}</div>;
};
