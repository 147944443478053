import { FlatButton, Tab, Tabs, Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui';
import React, { CSSProperties, useState, DragEvent } from 'react';
import { useDispatch } from 'react-redux';
import { SESSION_STORAGE_KEYS } from '../../utils';
import { combineKeys, isAltKey, isCommandCtrlKey, isLetterKey, isShiftKey, KeyCode } from '../editor/utils/keyIdentifier';
import FeatureManager from './devTools/FeatureManager';
import { useFeatureSwitches } from '../featureSwitch/useFeatureSwitches';
import { hideSystemSnackbarMessage, showSystemSnackbarMessage } from '../misc/SystemSnackbar/thunks';
import { AeroIconButtonClose } from './AeroIconButton';
import DevScripts from './devTools/scripts/DevScripts';
import { MaterialIcon } from './MaterialIcon';
import { MockResponseManager } from './devTools/MockResponseManager';
import { useDebouncedCallback } from './useDebouncedCallback';
import { useKeyListener } from './useKeyListener';
import { useStorage } from './useStorage';
import OtherSettings from './devTools/OtherSettings';

const styles: { [prop: string]: CSSProperties } = {
  container: {
    background: '#fff',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
    fontSize: 12
  },
  fullScreen: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    height: 'auto'
  },
  fullScreenContent: {
    maxHeight: '100%'
  },
  resizeBar: {
    height: 4,
    cursor: 'ns-resize',
    backgroundColor: '#d0d0d0'
  },
  bar: {
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: '#d0d0d0'
  },
  icon: {
    margin: 0,
    padding: 0,
    width: 24,
    height: 24,
    float: 'right'
  },
  content: {
    overflow: 'auto'
  },
  tab: {
    display: 'none'
  },
  marginLeft16: {
    marginLeft: 16
  },
  devTab: {
    marginLeft: 0,
    marginRight: 0
  }
};

const getViewportHeight = (): number => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

type DevTabs = 'features' | 'scripts' | 'mockResponse' | 'others';

export function AeroDevTools() {
  const [showLocalDevTools, setShowLocalDevTools] = useStorage(SESSION_STORAGE_KEYS.SHOW_DEV_TOOLS, 'local');
  const [visible, setVisible] = useState<boolean>(showLocalDevTools === 'true');
  const [activeTab, setActiveTab] = useState<DevTabs>('others');
  const [reloadMessage, setReloadMessage] = useState<boolean>(false);
  const [fullScreen, setFullscreen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(350);
  const { features } = useFeatureSwitches();
  const dispatch = useDispatch();

  useKeyListener(document, toggleComponent, combineKeys(isAltKey, isShiftKey, isCommandCtrlKey, isLetterKey(KeyCode.z)));

  function toggleComponent() {
    setVisible(!visible);
    setShowLocalDevTools(!visible + '');
  }

  function showReloadMessage() {
    setReloadMessage(true);
  }

  function changeTab(tab: DevTabs) {
    setActiveTab(tab);
  }

  function showSystemMessage(message: string) {
    dispatch(
      showSystemSnackbarMessage({
        open: true,
        message,
        autoHideDuration: 2000,
        style: { zIndex: 99999 },
        onRequestClose: () => {
          dispatch(hideSystemSnackbarMessage());
        }
      })
    );
  }

  function toggleFullScreen() {
    setFullscreen(!fullScreen);
  }

  function reload() {
    location.reload();
  }

  const dragDebounced = useDebouncedCallback((clientY) => {
    setHeight(getViewportHeight() - clientY);
  }, 25);

  function drag(e: DragEvent<HTMLDivElement>) {
    if (e.clientY) {
      dragDebounced(e.clientY);
    }
  }

  function dragEnd(e: DragEvent<HTMLDivElement>) {
    setHeight(getViewportHeight() - e.clientY);
  }

  if (!visible) {
    return null;
  }

  return (
    <div style={{ ...styles.container, height, ...(fullScreen ? styles.fullScreen : {}) }}>
      <div style={styles.resizeBar} onDrag={drag} onDragEnd={dragEnd} draggable={true} />
      <div style={styles.bar}>
        <Toolbar>
          <ToolbarGroup firstChild={true}>
            <MaterialIcon
              style={styles.marginLeft16}
              onClick={toggleFullScreen}
              iconName={fullScreen ? 'fullscreen_exit' : 'fullscreen'}
              className="vertical-align-middle margin-right-m cursor-pointer"
              title={fullScreen ? 'Exit fullscreen' : 'Fullscreen'}
            />
            <ToolbarTitle text="Aerodocs DevTools" style={styles.marginLeft16} />
            <ToolbarSeparator style={{ ...styles.marginLeft16, marginRight: 16 }} />
            <FlatButton
              style={styles.devTab}
              label="Features"
              primary={activeTab === 'features'}
              onClick={changeTab.bind(null, 'features')}
            />
            <FlatButton style={styles.devTab} label="Scripts" primary={activeTab === 'scripts'} onClick={changeTab.bind(null, 'scripts')} />
            <FlatButton style={styles.devTab} label="Others" primary={activeTab === 'others'} onClick={changeTab.bind(null, 'others')} />
            {features.mockResponse && (
              <FlatButton
                style={styles.devTab}
                label="Mock Backend Response"
                primary={activeTab === 'mockResponse'}
                onClick={changeTab.bind(null, 'mockResponse')}
              />
            )}
            <ToolbarSeparator style={styles.marginLeft16} />
            {reloadMessage && (
              <span className="font-bold margin-left-l">
                Some of your changes may need reload page to take effect.{' '}
                <a onClick={reload} className="cursor-pointer">
                  Reload now
                </a>
              </span>
            )}
          </ToolbarGroup>
          <ToolbarGroup>
            <AeroIconButtonClose onClick={toggleComponent} buttonStyle={styles.icon} />
          </ToolbarGroup>
        </Toolbar>
      </div>
      <div style={{ ...styles.content, height: height - 56, ...(fullScreen ? styles.fullScreenContent : {}) }}>
        <Tabs value={activeTab} onChange={changeTab}>
          <Tab label="Features" value="features" style={styles.tab}>
            <FeatureManager onChange={showReloadMessage} />
          </Tab>
          <Tab label="Scripts" value="scripts" style={styles.tab}>
            <DevScripts onShowMessage={showSystemMessage} />
          </Tab>
          <Tab label="Mock Response" value="mockResponse" style={styles.tab}>
            <MockResponseManager onSave={showSystemMessage} />
          </Tab>
          <Tab label="Others" value="others" style={styles.tab}>
            <OtherSettings />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
