import * as Reflux from 'reflux';
import { EditorStore } from './../../flux/editor/EditorStore';
import ProjectStore from './../../flux/editor/ProjectStore';
import Store from '../Store';
import * as linksClient from './../../clients/links';
import * as projectsClient from '../../clients/projects';
import * as projectClient from '../../clients/project';
import { IEditorStoreEvent, ILink, IProject, LinkTarget } from 'mm-types';
import { Cancelled } from '../../clients/base-clients';
import * as _ from 'lodash';
import { Page, Sort } from '../projects/DocumentsStore';
import { ELEMENT_CLASS_NAME } from '../../components/editor/utils/units/const/UnitElementSelectors';
import { LinkModel } from '../../components/editor/links/generic/linkService';

export type State = {
  links: ILink[];
  loading?: boolean;
  flatList: FlatListType[];
  externalProjects: IProject[];
  page: Page;
};

export type FlatListType = {
  type: 'INTER_DOC' | 'INTERNAL' | 'EXTERNAL';
  direction: 'INBOUND' | 'OUTBOUND';
  text?: string;
  externalLink?: string;
  broken?: boolean;
  projectUid?: string;
  destinationUnitUid?: string;
  anchor;
} & Partial<LinkTarget>;

export type LinkStoreEvent = State & {
  type?: 'projects-retrieved' | 'projects-retrieved-edit' | 'close-link-modal';
  targetProjectName?: string;
  templateHtmlModel?: Partial<LinkModel>;
};

export class LinkStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      links: [],
      externalProjects: [],
      flatList: [],
      page: { pageNumber: 0, pageSize: 30, numOfPages: 0, totalElements: 0 }
    };
  }

  initializeListener(editor: EditorStore) {
    this.listenTo(editor as any, this.onStatusChange);
  }

  getState() {
    return this.state;
  }

  onProjectUpdate() {
    return this.fetchLinks();
  }

  containsLinks(htmlString: string): boolean {
    const html = document.createElement('div');
    html.innerHTML = htmlString;
    const hotspots = html.querySelectorAll(`div.${ELEMENT_CLASS_NAME.HOT_SPOT}`);
    const links = html.querySelectorAll('a');
    return hotspots.length + links.length > 0;
  }

  onStatusChange(e: IEditorStoreEvent<'updateUnit' | 'deleteUnitConfirm' | 'deleteSelectedUnitsConfirm' | 'undoredo'>) {
    // if multi unit change or ((single unit change or single unit delete) and unit contains a link)
    if (
      ['deleteSelectedUnitsConfirm', 'undoredo'].indexOf(e.type) >= 0 ||
      (e.data &&
        e.data.unit &&
        ['updateUnit', 'deleteUnitConfirm'].indexOf(e.type) >= 0 &&
        ((e.data.unit.html && this.containsLinks(e.data.unit.html)) || e.data.unit.type === 'graphic'))
    ) {
      this.fetchLinks();
    }
  }

  triggerCloseLinkModal(linkModel?: Partial<LinkModel>) {
    this.trigger({ type: 'close-link-modal', templateHtmlModel: linkModel } as LinkStoreEvent);
  }

  async getExternalDocs(hideExternal: boolean, page: Partial<Page>, title = '') {
    const sort: Partial<Sort> = { sortBy: 'TITLE', sortOrder: 'asc' };
    page = { pageNumber: 0, pageSize: 30, numOfPages: 0, ...page };

    const response = await projectsClient.getAllPublished({
      hideExternal: hideExternal,
      sort: sort,
      page: page,
      title: title
    });

    if (response instanceof Cancelled) {
      return;
    }

    this.state = Object.assign({}, this.state, {
      externalProjects: response.projects,
      page: response.page
    });

    this.trigger({
      ...this.state,
      type: 'projects-retrieved'
    } as LinkStoreEvent);
  }

  async getExternalDocsForTargetProject(targetProjectUid: string) {
    const sort: Partial<Sort> = { sortBy: 'TITLE', sortOrder: 'asc' };
    const page: Partial<Page> = { pageNumber: 0, pageSize: 30 };

    const projectResponse: IProject = await projectClient.getProject(targetProjectUid);

    const response = await projectsClient.getAllPublished({
      hideExternal: true,
      sort: sort,
      page: page,
      title: projectResponse.name
    });

    if (response instanceof Cancelled) {
      return;
    }

    this.state = Object.assign({}, this.state, {
      externalProjects: response.projects,
      page: response.page,
      targetProjectName: projectResponse.name
    });

    this.trigger({
      ...this.state,
      type: 'projects-retrieved-edit'
    } as LinkStoreEvent);
  }

  getInboundLinksTo(uid: string) {
    return this.state.flatList!.filter((l) => l.type === 'INTER_DOC' && l.direction === 'INBOUND' && l.destinationUnitUid === uid);
  }

  getInboundUnitsLinksTo(units) {
    let linksTo: FlatListType[] = [];

    units.forEach((u) => {
      const found = this.state.flatList!.filter(
        (l) => l.type === 'INTER_DOC' && l.direction === 'INBOUND' && l.destinationUnitUid === u.uid
      );
      if (found) {
        linksTo = linksTo.concat(found);
      }
    });
    return linksTo;
  }

  fetchLinks() {
    this.state.loading = true;
    this.state.links = [];
    this.trigger(this.state as LinkStoreEvent);

    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };

    linksClient.getAll(project.projectUid, project.indexUid).then((response) => {
      this.state.loading = false;
      if (response instanceof Cancelled) {
        return;
      }
      this.state.links = response;
      this.state.flatList = this.getFlatList(response, project.projectUid);
      this.trigger(this.state as LinkStoreEvent);
    });
  }

  getFlatList(links: ILink[], currentProjectUid?: string) {
    const flatList: FlatListType[] = [];

    links?.forEach((link) => {
      const l: ILink = link;

      // source always added
      const sourceModel: FlatListType = _.extend(
        {
          direction: 'OUTBOUND',
          broken: l.broken,
          type: l.type
        } as FlatListType,
        l.source
      );

      // external
      if (l.type === 'EXTERNAL' && l.externalTarget && l.externalTarget.href) {
        sourceModel.externalLink = l.externalTarget.href;
      }

      // dest only for interdoc or cross doc
      const dest = l.interDocLinkTarget || l.internalTarget;
      let destModel: (FlatListType & Partial<LinkTarget>) | null = null;
      if (dest && dest.projectUid) {
        destModel = _.extend(
          {
            direction: 'INBOUND',
            broken: l.broken,
            type: l.type,
            text: l.broken
              ? `DELETED ${l.source.text}`
              : dest.ordinal || dest.caption
              ? `${dest.ordinal ?? ''} ${dest.caption ?? ''}`
              : undefined
          } as FlatListType,
          dest
        );
      }

      if (l.type === 'EXTERNAL') {
        flatList.push(sourceModel);
      } else if (l.type === 'INTERNAL') {
        flatList.push(sourceModel);
        flatList.push(destModel!);
      } else if (l.type === 'INTER_DOC') {
        if (!l.outbound) {
          flatList.push(destModel!);
        } else {
          flatList.push(sourceModel);
        }
      }
    });

    return flatList;
  }
}

const singleton = Reflux.initStore<LinkStore>(LinkStore);
export default singleton;
