import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TocStore from '../../../../../flux/editor/TocStore';
import { isDirectChildOf } from './tocutils';
import { ITocNode } from 'mm-types';
import { TocManipulationEvent } from './TocSiblingDropPoint';

/**
 * TODO some duplication with TocSiblingDropPoint but I would prefer to keep separate for now
 * as I feel they will grow apart rather than together.
 */

export type Props = {
  node: ITocNode;
  onTocManipulation: TocManipulationEvent;
};

export type State = {};

export default class TocChildDropPoint extends React.Component<Props, State> {
  private _counter: number;

  constructor(props: Props) {
    super(props);
    this._counter = 0;
  }

  validDropTarget() {
    const droppedItem = TocStore.getDroppedItem();
    const dropTarget = this.props.node;
    return droppedItem && isDirectChildOf(droppedItem, dropTarget);
  }

  onDragEnter(e: React.DragEvent<HTMLElement>) {
    this._counter++;
    (ReactDOM.findDOMNode(this.refs.droppableZone) as Element).classList.add('active');
  }

  onDragLeave(e: React.DragEvent<HTMLElement>) {
    this._counter--;
    if (this._counter === 0) {
      (ReactDOM.findDOMNode(this.refs.droppableZone) as Element).classList.remove('active');
    }
  }

  onDrop(e: React.DragEvent<HTMLElement>) {
    this.props.onTocManipulation('move', {
      afterTocableUnitUid: this.props.node.uid,
      selected: TocStore.getDroppedItem()
    });

    this.onDragLeave(e);
  }

  onDragOver(e: React.DragEvent<HTMLElement>) {
    e.preventDefault(); // this is needed to indicate we are a droppable zone
    // https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Drag_operations#droptargets
  }

  render() {
    if (this.validDropTarget()) {
      return (
        <div
          ref="droppableZone"
          className="toc-child-drop-point"
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e)}
          style={{ zIndex: 20 }}
        >
          <div className="inner"></div>
        </div>
      );
    } else {
      return <span />;
    }
  }
}
