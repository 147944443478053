import { IPrintOutputSettingsData, IPageBreakSettingsData, IUnit } from 'mm-types';
import EditorStore from '../../../../flux/editor/EditorStore';
import { parse } from '../../../../clients/units';

export class DocUnitWrapper {
  public unit: IUnit;

  constructor(unit: IUnit) {
    this.unit = unit;
  }

  toJSON() {
    return { ...this.unit } as IUnit;
  }

  isFullyLoaded(): boolean {
    return this.unit && !!this.unit.html;
  }

  getPrintOutputSettings(targetHtml?: string): (IPrintOutputSettingsData & IPageBreakSettingsData) | null {
    if (this.unit.canHavePrintOutput && this.unit.html) {
      const html = targetHtml ? targetHtml : this.unit.html;

      let pageType = 'unspecified';
      let pageSize = 'unspecified';
      let breakBefore = '';
      let breakAfter = '';
      let avoidBreakInside = '';
      let colCount = '';
      let colGap = '';

      const elemTag = html.match(/^<(.*?)>/)![1];

      if (!targetHtml) {
        const pageTypeReg = elemTag.match(/data-page-type=["']([a-zA-Z0-9]*)["']/i);
        const pageSizeReg = elemTag.match(/data-page-size=["']([a-zA-Z0-9]*)["']/i);
        const colCountReg = elemTag.match(/data-column-count=["']([0-9]*)["']/i);
        const colGapReg = html.match(/data-column-gap=["']([0-9]*)["']/i);

        if (pageTypeReg) {
          pageType = pageTypeReg[1];
        }
        if (pageSizeReg) {
          pageSize = pageSizeReg[1];
        }
        if (colCountReg) {
          colCount = colCountReg[1];
        }
        if (colGapReg) {
          colGap = colGapReg[1];
        }
      }
      // page breaking control (for unit and element contexts)
      const breakBeforeRes = elemTag.match(/data-page-break-before=["']([a-zA-Z]*)["']/);
      const breakAfterRes = elemTag.match(/data-page-break-after=["']([a-zA-Z]*)["']/);
      const avoidBreakInsideRes = elemTag.match(/data-avoid-break-inside=["']([a-zA-Z]*)["']/);

      if (breakBeforeRes) {
        breakBefore = breakBeforeRes[1];
      }
      if (breakAfterRes) {
        breakAfter = breakAfterRes[1];
      }
      if (avoidBreakInsideRes) {
        avoidBreakInside = avoidBreakInsideRes[1];
      }

      return {
        pageType: pageType,
        pageSize: pageSize,
        colCount: colCount,
        colGap: colGap,
        breakBefore: breakBefore,
        breakAfter: breakAfter,
        avoidBreakInside: avoidBreakInside
      };
    } else {
      return null;
    }
  }

  setOrdinalSettings(settings) {
    if (this.unit.isordinable) {
      const $wrapperDom = $('<div>' + this.unit.html + '</div>');
      const $unitDom = $wrapperDom.find('.arc-unit');

      if (!settings.override || settings.override.length === 0) {
        $unitDom.removeAttr('data-ordinal-override');
      } else {
        $unitDom.attr('data-ordinal-override', settings.override);
      }

      if (!settings.regulationOverride || settings.regulationOverride.length === 0) {
        $unitDom.find('.arc-title').removeAttr('data-reg-reference');
      } else {
        $unitDom.find('.arc-title').attr('data-reg-reference', settings.regulationOverride);
      }

      if (settings.strategy === null) {
        $unitDom.removeAttr('data-ordinal-strategy');
      } else if (settings.strategy) {
        $unitDom.attr('data-ordinal-strategy', settings.strategy);
      }

      if (!settings.renderLevel || settings.renderLevel === 'none') {
        $unitDom.removeAttr('data-ordinal-render-start-level');
      } else if (settings.renderLevel) {
        $unitDom.attr('data-ordinal-render-start-level', settings.renderLevel);
      }

      if (settings.level === null) {
        $unitDom.removeAttr('data-ordinal-level');
      }

      this.unit.html = $wrapperDom.html();
    }
  }

  setPrintOutputSettings(settings: IPrintOutputSettingsData & IPageBreakSettingsData) {
    if (this.unit.canHavePrintOutput) {
      const $wrapperDom = $('<div>' + this.unit.html + '</div>');
      const $unitDom = $wrapperDom.find('.arc-unit');

      if (!settings.pageSize || settings.pageSize === 'unspecified') {
        $unitDom.removeAttr('data-page-size');
      } else {
        $unitDom.attr('data-page-size', settings.pageSize);
      }

      if (!settings.pageType || settings.pageType === 'unspecified') {
        $unitDom.removeAttr('data-page-type');
      } else {
        $unitDom.attr('data-page-type', settings.pageType);
      }

      if (!settings.colCount || settings.colCount === '') {
        $unitDom.removeAttr('data-column-count');
      } else {
        $unitDom.attr('data-column-count', settings.colCount);
      }

      if (!settings.colGap || settings.colGap === '') {
        $unitDom.removeAttr('data-column-gap');
      } else {
        $unitDom.attr('data-column-gap', settings.colGap);
      }

      this.unit.html = $wrapperDom.html();
    }
  }

  setPageBreakSettings(settings: IPrintOutputSettingsData & IPageBreakSettingsData, targetHtml?: HTMLElement | null) {
    if (targetHtml) {
      // inline DOM update
      const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
      tinyFacade.updatePageBreakSettings(targetHtml, settings);
    } else {
      const $wrapperDom = $('<div>' + this.unit.html + '</div>');
      const $unitDom = $wrapperDom.find('.arc-unit');

      if (!settings.breakAfter || settings.breakAfter === '') {
        $unitDom.removeAttr('data-page-break-after');
      } else {
        $unitDom.attr('data-page-break-after', settings.breakAfter);
      }

      if (this.unit.isPageBreakBeforeControlEnabled) {
        if (!settings.breakBefore || settings.breakBefore === '') {
          $unitDom.removeAttr('data-page-break-before');
        } else {
          $unitDom.attr('data-page-break-before', settings.breakBefore);
        }
      }

      if (!settings.avoidBreakInside || settings.avoidBreakInside === '') {
        $unitDom.removeAttr('data-avoid-break-inside');
      } else {
        $unitDom.attr('data-avoid-break-inside', settings.avoidBreakInside);
      }

      this.unit.html = $wrapperDom.html();
    }
  }

  _setUnitSubTypeFlags(attribute, value) {
    const $wrapperDom = $('<div>' + this.unit.html + '</div>');
    const $unitDom = $wrapperDom.find('.arc-unit');

    $unitDom.attr(attribute, value);

    this.unit.html = $wrapperDom.html();
  }

  _getUnitSubTypeFlags(attribute) {
    const $unitDom = $('<div>' + this.unit.html + '</div>').find('.arc-unit');
    return $unitDom.attr(attribute);
  }

  _getUnitMetaData() {
    const $unitDom = $('<div>' + this.unit.html + '</div>').find(`.arc-unit > div:first-child`);
    return $unitDom.data();
  }

  setUnitSubTypeFlags(value) {
    this._setUnitSubTypeFlags('data-subtype-flags', value);
  }

  getUnitSubTypeFlags() {
    return this._getUnitSubTypeFlags('data-subtype-flags');
  }

  getUnitMetaData() {
    return this._getUnitMetaData();
  }

  getRepeaterFields() {
    const $wrapperDom = $('<div>' + this.unit.html + '</div>');
    const $unitDom = $wrapperDom.find('.arc-unit .arc-header-repeater');
    const fields = {
      displayOrdinal: false,
      suppressInAdx: false,
      label: ''
    };
    if ($unitDom.attr('data-display-ordinal')) {
      fields.displayOrdinal = $unitDom.attr('data-display-ordinal')! === 'true';
    }
    if ($unitDom.attr('data-suppress-in-adx')) {
      fields.suppressInAdx = $unitDom.attr('data-suppress-in-adx')! === 'true';
    }
    if ($unitDom.attr('data-optional-label')) {
      fields.label = $unitDom.attr('data-optional-label')!;
    }
    return fields;
  }

  setRepeaterFields({ displayOrdinal, suppressInAdx, label }: { displayOrdinal: boolean; suppressInAdx: boolean; label: string }) {
    const $wrapperDom = $('<div>' + this.unit.html + '</div>');
    const $unitDom = $wrapperDom.find('.arc-unit .arc-header-repeater');
    $unitDom.attr('data-display-ordinal', displayOrdinal.toString());
    $unitDom.attr('data-suppress-in-adx', suppressInAdx.toString());
    $unitDom.attr('data-optional-label', label.toString());
    this.unit.html = $wrapperDom.html();
  }

  parse(projectUid: string, indexUid: string): IUnit {
    return parse(this.unit, projectUid, indexUid);
  }
}
