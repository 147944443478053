import React from 'react';
import { MenuActions } from '../../MenuFile';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import { IRevision } from 'mm-types';
import { getFileMenuInterimShortcuts, getFileMenuShortcuts, getMediaLibShortcut } from './GetFileMenuShortcuts';

type Props = {
  isDraft?: () => boolean;
  revisions?: IRevision[] | null;
  handleSelected(e: any, action: string): void;
  type: 'pre' | 'post';
};

const Shortcuts = ({ type = 'pre', ...props }: Props) => {
  const isDraft = props?.isDraft ? props.isDraft() : false;
  const [menuActions, setMenuActions] = React.useState<MenuActions[]>([]);

  React.useEffect(() => {
    const menuActions: MenuActions[] = [];
    if (type == 'pre') {
      menuActions.push(...getFileMenuShortcuts(isDraft));
      menuActions.push(...getFileMenuInterimShortcuts(props.revisions));
    }
    if (type == 'post') {
      menuActions.push(...getMediaLibShortcut());
    }
    setMenuActions(menuActions);
  }, [isDraft, props.revisions, type]);

  return (
    <>
      {menuActions
        .filter((menuItem) => !menuItem.hidden)
        .map((menuItem, menuItemIndex) => {
          return (
            <li
              key={menuItemIndex}
              data-qa={menuItem?.dataQa}
              onClick={(e) => {
                if (menuItem.enabled) {
                  props.handleSelected(e, menuItem.action!);
                }
              }}
              className={(menuItem.seperator ? 'seperator' : 'file-' + menuItem.action + '-menu') + (menuItem.pressed ? ' on' : '')}
            >
              {!menuItem.seperator && (
                <FlatButton
                  hoverColor="rgba(0, 0, 0, 0)"
                  className={!menuItem.enabled ? 'disabled' : ''}
                  label={menuItem.tooltip}
                  labelPosition="after"
                  disabled={!menuItem.enabled}
                >
                  {menuItem.action !== 'preview' ? (
                    <FontIcon className={menuItem.className ? menuItem.className : 'material-icons'} disabled={!menuItem.enabled}>
                      {menuItem.icon}
                    </FontIcon>
                  ) : undefined}
                  {menuItem.action === 'preview' ? (
                    <FontIcon
                      className={`${menuItem.className ? menuItem.className : 'material-icons'} ${menuItem.icon} ${
                        !menuItem.enabled ? 'disabled' : ''
                      }`}
                      disabled={!menuItem.enabled}
                    />
                  ) : undefined}
                </FlatButton>
              )}
            </li>
          );
        })}
    </>
  );
};
export default Shortcuts;
