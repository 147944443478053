import { mm, ClientError, exemptStatusCodes } from './base-clients';
import { IWorkspace } from 'mm-types';
import { CacheClient } from '../utils/CacheClient';

/**
 * Gets all departments
 */
export async function getAll(options?: { canAdministerOnly?: boolean; isMemberOnly?: boolean }) {
  const query: string[] = [];
  if (options && options.canAdministerOnly) {
    query.push('canAdministerOnly=true');
  }

  if (options && options.isMemberOnly) {
    query.push('isMemberOnly=true');
  }

  const response = await mm.get<{ workspaces: IWorkspace[] }>(`/workspaces?${query.join('&')}`);
  let workspaces: IWorkspace[] = response.data.workspaces || [];

  workspaces = workspaces.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return workspaces;
}

/**
 * Gets a single workspace
 */
export async function getOne(uid: string) {
  const response = await mm.get<IWorkspace>(`/workspaces/${uid}`);
  return response.data;
}

/**
 * Removes an workspace by id
 */
export async function remove(uid: string) {
  const response = await mm.delete(`/workspaces/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 412) {
    throw new ClientError(response.status, 'Workspace in use');
  }
}

/**
 * Creates a new workspace
 */
export async function create(token: Partial<IWorkspace>) {
  const response = await mm.post<IWorkspace>(`/workspaces`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Workspace already exists');
  }

  return response.data;
}

/**
 * Updates a given workspace
 */
export async function update(uid: string, token: Partial<IWorkspace>) {
  const response = await mm.put<IWorkspace>(`/workspaces/${uid}`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409]),
    params: CacheClient.params.clearListing()
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Workspace already exists');
  }

  return response.data;
}
