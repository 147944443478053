import * as React from 'react';
import { FlatButton } from 'material-ui';

type Props = {
  onCancel: () => any;
  cid: string;
};

const CancelButton = (props: Props) => {
  const buttonStyle = {
    width: 28,
    height: 28,
    lineHeight: '28px',
    minWidth: '',
    margin: '0 5px 0 0',
    verticalAlign: 'middle'
  };
  const labelStyle = { padding: 0 };

  return (
    <FlatButton
      label="&#10005;"
      id={props.cid}
      data-qa={props.cid}
      secondary={false}
      style={buttonStyle}
      labelStyle={labelStyle}
      onClick={() => props.onCancel()}
    />
  );
};

export default CancelButton;
