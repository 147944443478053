import * as React from 'react';
import { LinearProgress } from 'material-ui';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { default as DropzoneFilePreview, DropzoneFilePreviewProps } from './DropzoneFilePreview';
import BodyCSSClassUtil from '../../utils/BodyCSSClassUtil';
import { Omit } from '../../types/type-fixes/helpers';

const EVENT_NAME = 'event-dropzone-dragover';

interface Props extends Omit<DropzoneProps, 'children'>, DropzoneFilePreviewProps {
  children?: React.ReactNode;
  className?: string;
  isBusy?: boolean;
  errorMsg?: string | null;
  onDropFiles: (file: File[]) => void;
  blacklistedFiles?: string[];
}
type ModalDropzoneProps = Omit<Props, 'onDrop'>;
// don't forget to add "position: relative" to first child of <Dialog /> element
export default class ModalDropzone extends React.Component<ModalDropzoneProps> {
  private _dropzoneRef;
  private isActive = false;
  constructor(props: ModalDropzoneProps) {
    super(props);
  }
  componentDidMount() {}
  setRef(el) {
    this._dropzoneRef = el;
  }
  onDragEnter() {
    BodyCSSClassUtil.addClass(EVENT_NAME);
  }
  onDragLeave() {
    BodyCSSClassUtil.removeClass(EVENT_NAME);
    this.isActive = false;
  }
  onDrop(e) {
    BodyCSSClassUtil.removeClass(EVENT_NAME);
    if (this.props.onDropFiles) {
      this.props.onDropFiles(e);
    }
  }

  render() {
    const { isBusy, files, uploadType, onRemove, errorMsg, onDropFiles, blacklistedFiles, children, className, ...restProps } = this.props;
    return (
      <div className={`modal-dropzone-component ${this.isActive ? 'active' : ''}`}>
        <Dropzone
          {...restProps}
          ref={(el) => this.setRef(el)}
          onDrop={(e) => this.onDrop(e)}
          onDragEnter={(e) => this.onDragEnter()}
          onDragLeave={(e) => this.onDragLeave()}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div className={`dropzone-component ${className}`} {...getRootProps()}>
                <input {...getInputProps()} />
                {children}
              </div>
            );
          }}
        </Dropzone>
        <div
          onClick={() => {
            this._dropzoneRef.open();
          }}
        >
          <DropzoneFilePreview
            onRemove={(index, e) => (onRemove ? onRemove(index, e) : null)}
            files={files}
            uploadType={uploadType}
            multiple={restProps.multiple}
          />
        </div>
        {errorMsg && <div className="error color-error animation-fade-in">{errorMsg}</div>}
        {isBusy && <LinearProgress mode="indeterminate" />}
      </div>
    );
  }
}
