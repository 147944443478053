import { CancelTokenSource } from 'axios';
import {
  IActiveEditor,
  IApproval,
  IExternalRevisions,
  IPDFExportOptions,
  IProject,
  IProjectDistributionSettings,
  IRevision
} from 'mm-types';
import userUtil from '../components/documents/team/util/user';
import { dateUtil } from '../utils';
import { mm } from './base-clients';

export async function getProject(uid: string): Promise<IProject> {
  const response = await mm.get<IProject>(`/projects/${uid}`);
  const project = response.data;
  parse(project);
  return project;
}

function parseRevisions(revisions: IRevision[]) {
  for (const r of revisions) {
    r.revisionDateFormatted = dateUtil(r.revisionDate).formatDateTimeNoSecs(true);
    r.revisionDateFormattedShort = dateUtil(r.revisionDate).formatDate();
    r.distributionDateFormatted = dateUtil(r.distributionDate).formatDateTimeNoSecs(true);
  }

  return revisions;
}

export async function getApprovals(projectUid: string, indexUid: string) {
  const response = await mm.get<IApproval>(`/projects/${projectUid}/indexes/${indexUid}/metadata/approvals`);
  return response.data;
}

export async function saveApproval(projectUid: string, indexUid: string, approval: Partial<IApproval>) {
  await mm.put(`/projects/${projectUid}/indexes/${indexUid}/metadata/approvals`, approval);
}

export async function getDistributions(projectUid: string, indexUid: string) {
  const response = await mm.get<IProjectDistributionSettings>(`/projects/${projectUid}/distributions/${indexUid}`);
  return response.data;
}

export async function setDistributions(projectUid: string, indexUid: string, settings: Partial<IProjectDistributionSettings>) {
  const response = await mm.post<IProjectDistributionSettings>(`/projects/${projectUid}/distributions/${indexUid}`, settings);
  return response.data;
}

export async function getRevisions(projectUid: string, indexUid?: string, cancelToken?: CancelTokenSource) {
  if (!indexUid) {
    const response = await mm.get<{ revisions: IRevision[] }>(`/projects/${projectUid}/metadata/revisions`, {
      cancelToken: cancelToken?.token
    });
    return parseRevisions(response.data.revisions);
  } else {
    const response = await mm.get<{ revisions: IRevision[] }>(`/projects/${projectUid}/indexes/${indexUid}/metadata/revisions`, {
      cancelToken: cancelToken?.token
    });
    return parseRevisions(response.data.revisions);
  }
}

export async function getFutureRevisions(projectUid: string, indexUid: string) {
  const response = await mm.get<{ revisionType: string; revisionNumber: string }[]>(
    `/projects/${projectUid}/indexes/${indexUid}/metadata/future/revisions`
  );
  return response.data;
}

export async function getExternalRevisions(projectUid: string, cancelToken?: CancelTokenSource) {
  const response = await mm.get<IExternalRevisions>(`/projects/${projectUid}/externalRevisions`, {
    cancelToken: cancelToken?.token
  });
  return response.data;
}

export async function publish(projectUid: string, indexUid?: string) {
  const response = await mm.post<{ projectUid: string; masterIndexUid: string }>(
    `/projects/${projectUid}/publish` + indexUid ? '?indexUid=' + indexUid : '',
    { projectUid }
  );
  return response.data;
}

export async function remove(uid: string) {
  await mm.delete(`/projects/${uid}`);
}

export async function updateProject(uid: string, token: Partial<IProject>) {
  const response = await mm.put<IProject>(`/projects/${uid}`, token);
  const project = response.data;
  parse(project);
  return project;
}

export function parse(project: IProject) {
  project.modifiedFormatted = dateUtil(project.modified).formatDateTimeNoSecs();
  project.createdFormatted = dateUtil(project.created).formatDateTimeNoSecs();
  project.revisionDate = dateUtil(project.revisionDate).formatDateTimeNoSecs();

  // flatten to use on sortby
  project.referenceDisplayName = project.docReference ? project.docReference.displayName : '-';
  project.ownerDisplayName = project.owner ? project.owner.displayName : '';

  project.isDeleted = project.status === 'TRASHED';
  return project;
}

/**
 * Gets the active users in a doc session
 */
export async function getActiveEditors(projectUid: string, indexUid: string) {
  const response = await mm.get<{ activeEditors: IActiveEditor[] }>(`/projects/${projectUid}/indexes/${indexUid}/activeEditors`);
  const activeEditors = response.data.activeEditors || [];
  let editorUserCount = 0;

  for (const activeEditor of activeEditors) {
    activeEditor.avatarUrl = userUtil.imagePath(activeEditor.user);
    activeEditor.activeSinceFormattedAgo = dateUtil(activeEditor.activeSince).fromNow();

    if (activeEditor.permissions.canAuthor || activeEditor.permissions.canManageComplianceTags) {
      editorUserCount++;
    }
  }

  return { activeEditors, editorUserCount };
}

export async function clone(sourceProjectUid: string, token: { name: string }) {
  const response = await mm.post<IProject>(`/projects/${sourceProjectUid}/copyAsync`, token);
  const project = response.data;
  parse(project);
  return project;
}

export async function create(token: Partial<IProject>) {
  const response = await mm.post<IProject>(`/projects`, token);
  const project = response.data;
  parse(project);
  return project;
}

export async function createPreview(projectUid: string, indexUid: string) {
  await mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/publish/preview`, { projectUid, indexUid });
}

export async function exportPdf(projectUid: string, indexUid: string, options?: IPDFExportOptions) {
  const query: string[] = [];

  options = options || {};

  if (options.generator) {
    query.push('generator=' + options.generator);
  }

  if (options.selectionUid) {
    query.push('selectionUid=' + options.selectionUid);
  }

  if (options.publicCommentsChecked && options.publicCommentsValue) {
    query.push('publicCommentFilter=' + options.publicCommentsValue);
  }

  if (options.internalCommentsChecked && options.internalCommentsValue) {
    query.push('internalCommentFilter=' + options.internalCommentsValue);
  }

  if (options.privateRevisionHighlightsChecked && options.privateRevisionHighlightsValue) {
    query.push('privateHighlightFilter=' + options.privateRevisionHighlightsValue);
  }

  if (options.generalRevisionHighlightsChecked && options.generalRevisionHighlightsValue) {
    query.push('generalHighlightFilter=' + options.generalRevisionHighlightsValue);
  }

  if (options.diffIndexTimestamp) {
    query.push('diffIndexTimestamp=' + options.diffIndexTimestamp.toString());
  }

  if (options.diffIndexUid) {
    query.push('diffIndexUid=' + options.diffIndexUid.toString());
  }

  const customHeaders = options.variantUid
    ? {
        'X-Index-Variant': options.variantUid
      }
    : undefined;

  await mm.get<void>(`/projects/${projectUid}/indexes/${indexUid}/export/pdf?` + query.join('&'), {
    headers: customHeaders
  });
}

// Not sure this belongs here
export function settingDefaults(): Partial<IProject> {
  return {
    status: 'TEMPLATE',
    workspaceUid: ''
  };
}
