import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'InlineStyle',
  getLabel: () => 'Inline Style',
  identifyingClasses: ['arc-inline'],
  identifyingElements: ['span'],
  template: '',
  splittable: false
};
