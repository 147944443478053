import React from 'react';
import { INotificationListItemProps } from './INotificationListItemProps';
import LinearProgress from 'material-ui/LinearProgress';

const AerosyncPublicationPreview = ({
  notif,
  getName,
  getVariantName,
  handleNotifAction,
  refreshing,
  setRefreshing
}: INotificationListItemProps) => {
  return (
    <div className="notif-item-container notif-item-aerosyncpublish">
      <div className="left-side">
        {notif.type === 'AEROSYNC_PUBLICATION' && <i className="material-icons icon">send</i>}
        {notif.type === 'AEROSYNC_PREVIEW' && <i className="material-icons icon icon-preview-in-viewer" />}
      </div>

      <div className="middle-side">
        {!notif.processingStatus && (
          <div className="popup-title-container">
            {notif.type === 'AEROSYNC_PUBLICATION' && <h1>{notif.isError ? 'Publish Error' : 'Published'}</h1>}
            {notif.type === 'AEROSYNC_PREVIEW' && <h1>{notif.isError ? 'Preview Error' : 'Preview'}</h1>}

            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {notif.processingStatus && (
          <div className="popup-title-container">
            {notif.type === 'AEROSYNC_PUBLICATION' && <h1>Publishing Aerosync</h1>}
            {notif.type === 'AEROSYNC_PREVIEW' && <h1>Previewing Aerosync</h1>}

            {getName ? getName(notif) : ''}
            {getVariantName ? getVariantName(notif) : ''}
          </div>
        )}

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            {notif.isReady && notif.type === 'AEROSYNC_PUBLICATION' && (
              <span className="info">
                Aerosync published {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} successfully
              </span>
            )}

            {notif.isError && notif.type === 'AEROSYNC_PUBLICATION' && (
              <span className="info">
                Aerosync publish {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} failed
                {notif.retryable ? ', retry?' : '.'}
              </span>
            )}

            {notif.isReady && notif.type === 'AEROSYNC_PREVIEW' && (
              <span className="info">
                Aerosync preview {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} successfully
              </span>
            )}

            {notif.isError && notif.type === 'AEROSYNC_PREVIEW' && (
              <span className="info">
                Aerosync preview {notif.index.revisionNumber ? 'revision ' + notif.index.revisionNumber : ''} failed
                {notif.retryable ? ', retry?' : '.'}
              </span>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            <span className="info">{notif.processingStatus.friendlyName}</span>
          </div>
        )}
      </div>

      <div className="right-side">
        <div className="popup-date-container">
          <h5>{notif.receivedFormatAgo}</h5>
        </div>

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            <span
              className={'notif-status-toggle'}
              title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
              onClick={() => {
                if (handleNotifAction) {
                  handleNotifAction(notif, 'toggleReadUnread');
                }
              }}
            />

            {notif.isError && notif.retryable && (
              <i
                className="material-icons"
                onClick={() => {
                  if (refreshing) {
                    return;
                  }
                  if (setRefreshing && handleNotifAction) {
                    setRefreshing(true);
                    handleNotifAction(notif, 'retry', false, () => setRefreshing(false));
                  }
                }}
              >
                refresh
              </i>
            )}

            {notif.isReady && (
              <i
                className="material-icons"
                onClick={() => {
                  if (handleNotifAction) {
                    handleNotifAction(notif, 'link');
                  }
                }}
              >
                link
              </i>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            {notif.status === 'IN_PROGRESS' && <LinearProgress mode="determinate" max={6} min={0} value={notif.processingStatus.step} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default AerosyncPublicationPreview;
