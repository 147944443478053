import * as React from 'react';
import { withSimpleAnimation, SimpleAnimationProps, SimpleAnimationState } from './SimpleAnimation';

function transformStyles(props: SimpleAnimationProps, state: SimpleAnimationState): React.CSSProperties {
  const base: React.CSSProperties = {
    animationFillMode: 'forwards',
    animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  };
  if (props.show) {
    return {
      ...base,
      animationDuration: state.enterDuration + 'ms',
      animationName: 'bounceIn'
    };
  }
  return {
    ...base,
    animationDuration: state.exitDuration + 'ms',
    animationName: 'bounceOut'
  };
}
const Bounce = withSimpleAnimation({
  componentName: 'Bounce',
  cssTransformationFn: transformStyles
});
export default Bounce;
