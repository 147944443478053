import * as _ from 'lodash';
import EditorModes from '../../EditorModes';
import { canIgnoreUnit, ISharedSelection } from './sharedUnitSelectionUtils';
import { moveShareSelectionFromShareBody, moveShareSelectionIntoShareBody } from './actions/moveShareSelection';
import { onUndoRedo, onUnitCreation, onUnitDeletion } from './actions/recalculateShareSelection';
import { IUnit, EventStoreEventType } from 'mm-types';
import { DocUnitWrapper } from '../../../../components/editor/utils/tinyFacade/DocUnitWrapper';

let shareEditSelection: ISharedSelection | null = null;
let _docUnitCollection: IUnit[];

export type RecalculateOptions = { eventType: EventStoreEventType; units: DocUnitWrapper[] | DocUnitWrapper | null };

const SharedUnitSelection = {
  async select(unitsCollection: IUnit[], index: number, position: { isUp: boolean; isStart: boolean }) {
    _docUnitCollection = unitsCollection;

    const currentStartUnit = _docUnitCollection[index];
    const newStartSharedUnit = _docUnitCollection[index + (position.isUp ? -1 : 1)];

    if (!currentStartUnit || !newStartSharedUnit) {
      return false;
    }

    const canIgnoreNewUnit = canIgnoreUnit(newStartSharedUnit);

    // at start go up OR at end go down
    if ((position.isStart && position.isUp) || (!position.isStart && !position.isUp)) {
      return moveShareSelectionFromShareBody(position, currentStartUnit, newStartSharedUnit, canIgnoreNewUnit, _docUnitCollection);
    }

    // at start go down OR at end go up
    else if ((position.isStart && !position.isUp) || (!position.isStart && position.isUp)) {
      return moveShareSelectionIntoShareBody(position, currentStartUnit, newStartSharedUnit, canIgnoreNewUnit, _docUnitCollection);
    }

    return true;
  },

  recalculateSelection(options: RecalculateOptions, unitsCollection: DocUnitWrapper[]): Promise<boolean> {
    if (!shareEditSelection) {
      return Promise.resolve(false);
    }

    const type = options.eventType;
    let recalculated = false;
    if (_isOneOfType(type, 'deleteUnitConfirm', 'deleteSelectedUnitsConfirm')) {
      recalculated = onUnitDeletion(options.units, unitsCollection, shareEditSelection);
    } else if (_isOneOfType(type, 'createUnit', 'updateUnit', 'createBatchUnits', 'splitSelectedUnits')) {
      recalculated = onUnitCreation(options.units, unitsCollection, shareEditSelection);
    } else if (_isOneOfType(type, 'undoredo')) {
      recalculated = onUndoRedo(unitsCollection, shareEditSelection);
    }

    return Promise.resolve(recalculated);
  },

  getSelectedShareInfo: function (unitsCollection: IUnit[], isShareEditMode = false) {
    _docUnitCollection = unitsCollection;
    const currentShareUid = EditorModes.getProperties('SHARE_EDIT').getParams().shareUid;

    const sharedUnits = _docUnitCollection.filter((unit) => {
      return unit.shareDetails && unit.shareDetails.uid === currentShareUid;
    });

    const sharedUnitsAsJSON = sharedUnits.map((unit) => {
      return unit;
    });

    if (sharedUnitsAsJSON.length) {
      const result = {
        sharedIndexUid: currentShareUid!,
        sharedUnits: sharedUnitsAsJSON,
        start: sharedUnitsAsJSON[0],
        end: sharedUnitsAsJSON[sharedUnitsAsJSON.length - 1],
        visibleStart: sharedUnitsAsJSON.find((unit) => (unit.visibleUnitIndex ? true : false))!,
        visibleEnd: _.findLast(sharedUnitsAsJSON, (unit) => unit.visibleUnitIndex) as IUnit | null
      };

      if (isShareEditMode) {
        shareEditSelection = Object.assign({}, result);
      }
      return result;
    } else {
      return null;
    }
  }
};

function _isOneOfType(currentType: EventStoreEventType, ...expectedTypes: EventStoreEventType[]): boolean {
  return expectedTypes.indexOf(currentType) !== -1;
}

export default SharedUnitSelection;
