export enum UNIT_ELEMENT_CLASS_NAME {
  EDIT_TARGET = 'edit-target',
  NOT_EDITABLE = 'arc-editor-not-editable',
  ELEMENT_CONTENT = 'element-content',
  UNIT = 'arc-unit'
}

export enum ELEMENT_CLASS_NAME {
  HOT_SPOT = 'arc-hot-spot'
}

export enum UNIT_ELEMENT_ATTRIBUTE_NAME {
  ELEMENT_DEFINITION_ID = 'data-element-definition-id',
  ELEMENT_FAMILY = 'data-element-family',
  NID = 'data-nid'
}
