import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import { useStorage } from '../useStorage';
import { SESSION_STORAGE_KEYS } from '../../../utils';

const SETTINGS = [
  {
    label: 'Force browser reload when editing',
    storageKey: SESSION_STORAGE_KEYS.FORCE_RELOAD
  },
  {
    label: 'Show EventClient logs in Chrome devTools',
    storageKey: SESSION_STORAGE_KEYS.WEBSOCKET_DEBUG
  },
  {
    label: 'Show Reflux event trigger logs',
    storageKey: SESSION_STORAGE_KEYS.DEBUG_REFLUX_EVENTS
  }
];

interface CheckboxProps {
  storageKey: string;
  label: string;
}

const OtherSettingsCheckbox = (props: CheckboxProps) => {
  const [storageKey, setStorageKey] = useStorage(props.storageKey, 'local');

  return (
    <Checkbox
      label={props.label}
      labelStyle={{ fontSize: 14 }}
      checked={!!storageKey}
      onCheck={(e) => setStorageKey(!!storageKey ? null : 'true')}
    />
  );
};

const OtherSettings = () => {
  return (
    <div className="padding-l">
      <h5 className="margin-none font-size-16 font-bold">Others</h5>
      <ul className="margin-bottom-none">
        {SETTINGS.map((setting: CheckboxProps) => (
          <li key={setting.storageKey} className="margin-vertical-l">
            <OtherSettingsCheckbox {...setting} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OtherSettings;
