import * as React from 'react';
import { IMsnRange } from 'mm-types';
import { parseMsnRange } from '../../../../../utils/EffectivitiesUtil';

export type Props = {
  msnRange: IMsnRange | null;
};

const ManufacturerRange = (props: Props) => {
  const [msnRange, setMsnRange] = React.useState<string>('');

  React.useEffect(() => {
    if (!!props.msnRange) {
      setMsnRange(parseMsnRange(props.msnRange.serializedRange, props.msnRange.len));
    } else {
      setMsnRange('');
    }
    return () => {};
  }, [props.msnRange]);

  return (
    <div className="manufacturer-range-container">
      <h5>Manufacturer Range</h5>
      {!!props.msnRange && (
        <>
          <div className="manufacturer-range-row">{msnRange}</div>
        </>
      )}
    </div>
  );
};

export default ManufacturerRange;
