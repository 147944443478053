import * as React from 'react';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import { PropsTabProps } from 'mm-types';
import DropdownProp, { DropdownOption } from './QRHProps/DropdownProp';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import Dimension from '../controls/TableDimension';
import TableAlignment from '../controls/TableAlignment';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import { DataAttributesPropsWrapper } from '../components/DataAttributesPropsWrapper';
import { getElmDef } from '../../../../../../utils/DataAttributesUtil';

export const tabStyleOpts: DropdownOption[] = [{ val: '' }, { val: 'shading-alternate-row' }];

const PropsTab = (props: PropsTabProps) => {
  const tableIsNotNested = () => {
    return !getElmDef(props.selectedUnit?.definitionId)?.includes('Table');
  };

  return (
    <div key="tabProps" className="edit-props-section">
      <div>
        <div key="tabProps" className="edit-props-section">
          <div>
            <div className="edit-props-subsection">
              {!ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
                <>
                  <div
                    data-action="arcToggleCaption"
                    data-param="table"
                    className="caption-toggle-container"
                    onClick={props.handleCaptionToggle}
                  >
                    <Toggle
                      name="tablecaption"
                      label="Include table caption"
                      toggled={props.isCaptionOn}
                      data-qa="arcToggleCaption"
                      disabled={!settingEnabled('caption', props.settings)}
                    />
                  </div>
                  <CaptionField
                    isCaptionOn={props.isCaptionOn}
                    caption={props.caption}
                    handleCaptionClick={props.handleCaptionClick}
                    handleCaptionChange={props.handleCaptionChange}
                    handleKeyDown={props.handleKeyDown}
                    errorMessage={props.errorMessage!}
                    captionRef={props.captionRef}
                  />
                  <Dimension
                    type="width"
                    title="Width"
                    display="table"
                    onChangeCallback={props.onUserUnitChange}
                    settings={props.settings}
                  />
                  <Dimension
                    type="height"
                    title="Height"
                    display="table"
                    onChangeCallback={props.onUserUnitChange}
                    settings={props.settings}
                  />
                  <TableAlignment type="tablealign" title="Horizontal table align" settings={props.settings} />
                </>
              )}
              {ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() && (
                <DropdownProp
                  options={tabStyleOpts}
                  title={'Tab Style'}
                  onChange={(val) => props.setDataAttribute(val, 'tab-style', 'props')}
                  val={props.dataAttributes.find((attr) => attr.id === 'data-tab-style')?.val ?? ''}
                  dataQa={'table-edit-props-tab-style'}
                />
              )}
              <DataAttributesPropsWrapper {...props} inline={true} displayPrintOutput={tableIsNotNested()} tableEditTabType={'props'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CaptionFieldProps {
  isCaptionOn: boolean;
  handleCaptionClick(): void;
  handleCaptionChange(e): void;
  handleKeyDown(): void;
  errorMessage: string;
  caption: string;
  captionRef?: React.RefObject<TextField>;
}

function CaptionField(props: CaptionFieldProps) {
  if (props.isCaptionOn) {
    return (
      <div onFocus={props.handleCaptionClick}>
        <TextField
          ref={props.captionRef}
          id="table-caption-text"
          onKeyDown={props.handleKeyDown}
          onChange={(e) => props.handleCaptionChange(e)}
          errorText={props.errorMessage}
          className="image-edit-textfield"
          value={props.caption}
          floatingLabelText="Caption Text"
          data-qa="props-tab-caption-text"
        />
      </div>
    );
  } else {
    return <div />;
  }
}

export default PropsTab;
