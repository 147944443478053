import * as React from 'react';
import * as keyIdentifier from '../../keyIdentifier';
import { isBackspaceKey, isDeleteKey } from '../../keyIdentifier';
import { Dom } from '../../tinyFacade/DomUtil';
import { CustomEditor } from '../../tinyFacade/EditorInstanceManager';
import { isInlineElement } from './inlineElementsLookup';
import {
  deleteElementAndRecordTransaction,
  isSelectedRangeReplaceAction,
  replaceTemplateDataNidsWithNewOnes
} from '../../tinyFacade/TinyFacadeHelpers';
import { DomAssertions } from '../../tinyFacade/DomAssertionsUtil';
import { EditorManager } from 'tinymce';
import { ElementFamily } from '../UnitTypes';
import UnitElementSelectionUtil, { SelectionDetails } from '../../tinyFacade/UnitElementSelectionUtil';
import { IElementDefinition, TinyPastePreProcessEvent } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { CSSSelector } from '../../tinyFacade/CssSelectorUtil';
import { UnitToInlineElementReducer } from '../UnitToInlineElementReducer';
import { EcamAsserts } from './ecam/asserts';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import { ElementDetails } from '../ElementDetails';
import SelectionUtil from '../../tinyFacade/SelectionUtil';
import { ZERO_LENGTH_WORD_JOINER } from '../../tinyFacade/key_listeners/keyBehaviourUtils';
import { generateDataNid } from '../../../../../utils/UUIDUtil';
import getDataElementDefinitionId = ElementDetails.getDataElementDefinitionId;

declare const tinymce: EditorManager;

export const keyBehavior = {
  beforeCommonKeys,
  onPastePreProcess
};

function onPastePreProcess(editor: CustomEditor, e: TinyPastePreProcessEvent) {
  try {
    tinymce.activeEditor.selection.setContent(UnitToInlineElementReducer(e.content));
  } catch (e) {
    console.error(e);
  }
  keyIdentifier.nukePropagation(e);
}

function beforeCommonKeys(editor: CustomEditor, e: React.KeyboardEvent, elementFamily: ElementFamily) {
  const isCopy = keyIdentifier.isCopyKeys(e);
  const selectedNode = getSelectedNode();
  if (isCopy || !selectedNode) {
    return;
  }
  const selectionUtil = new UnitElementSelectionUtil(tinymce.activeEditor as CustomEditor, elementFamily);
  const selectionDetails = selectionUtil.getSelectionDetailsForSelectedNode(selectedNode as HTMLElement);

  if (isSelectedRangeReplaceAction(tinymce.activeEditor, e) || keyIdentifier.isCutKeys(e)) {
    return onTextSelection(e, selectedNode as HTMLElement, selectionUtil, selectionDetails);
  }
  return onKeyDown(e, selectedNode as HTMLElement, selectionUtil, selectionDetails, EcamAsserts.isEcamElement(selectedNode), editor);
}

function onKeyDown(
  e: React.KeyboardEvent,
  selectedNode: HTMLElement,
  selectionUtil: UnitElementSelectionUtil,
  selectionDetails: SelectionDetails,
  isEcam: boolean,
  editor: CustomEditor
) {
  if (keyIdentifier.isDeleteKeys(e)) {
    return onDeleteKeys(e, selectedNode, selectionUtil, selectionDetails, editor);
  }
  if (keyIdentifier.isTabKey(e) || keyIdentifier.isEnterKey(e)) {
    return onTabOrEnterKeys(e, selectedNode, selectionUtil, isEcam);
  }
  if (keyIdentifier.isRightArrowKey(e) || keyIdentifier.isLeftArrowKey(e)) {
    return onArrowKeys(e, selectedNode, selectionUtil);
  }
}

function onArrowKeys(e: React.KeyboardEvent, selectedNode: HTMLElement, selectionUtil: UnitElementSelectionUtil) {
  const currentElmDef = ProjectDefinitionStore.getElementDefinitionById(getDataElementDefinitionId(selectedNode));
  const anchorOffset = selectionUtil.selection.anchorOffset;
  if (keyIdentifier.isRightArrowKey(e)) {
    if (
      currentElmDef?.id === 'Challenge' &&
      currentElmDef.subType === 'wrap' &&
      selectedNode.nextElementSibling &&
      window.getComputedStyle(selectedNode.nextElementSibling).display !== 'none'
    ) {
      const currentTextLength = selectionUtil.range.startContainer.textContent?.length;
      if (currentTextLength && currentTextLength - 1 === anchorOffset) {
        e.preventDefault();
        selectionUtil.selection.collapse(selectionUtil.range.startContainer, currentTextLength);
      } else if (currentTextLength === anchorOffset && selectionUtil.selection.anchorNode?.nextSibling === null) {
        selectionUtil.goToEditTarget(e, selectedNode.closest(CSSSelector.unit()) as Element).next(false, 'start');
      }
    }
  } else if (keyIdentifier.isLeftArrowKey(e)) {
    if (currentElmDef?.id === 'ChallengePart' && anchorOffset === 0) {
      selectionUtil.goToEditTarget(e, selectedNode.closest(CSSSelector.unit()) as Element).back(false, 'end');
    }
  }
}

function onTabOrEnterKeys(e: React.KeyboardEvent, selectedNode: HTMLElement, selectionUtil: UnitElementSelectionUtil, isEcam: boolean) {
  const unitElement = selectedNode.closest(CSSSelector.unit());
  if (unitElement) {
    const goTo = selectionUtil.goToEditTarget(e, unitElement);
    const currentElmDef = ProjectDefinitionStore.getElementDefinitionById(getDataElementDefinitionId(selectedNode));

    if (
      (currentElmDef?.id === 'ChallengePart' || currentElmDef?.subType === 'wrap') &&
      (keyIdentifier.isShiftEnterKeys(e) || keyIdentifier.isEnterKey(e))
    ) {
      e.preventDefault();
      handleEnterKeysInsertElm(e, selectedNode, currentElmDef);
    } else {
      if (keyIdentifier.isShiftTabKeys(e)) {
        goTo.back(isEcam);
      } else {
        // tab or enter pressed
        goTo.next(isEcam);
      }
    }
  }
}

function handleEnterKeysInsertElm(e: React.KeyboardEvent, selectedNode: HTMLElement, currentElmDef?: IElementDefinition) {
  if (currentElmDef?.id === 'ChallengePart') {
    insertChallengePart();
  } else {
    const dataNid = generateDataNid();
    EditorStore.getEditor().getActiveEditorInstance()?.insertContent(`<br data-nid="${dataNid}">${ZERO_LENGTH_WORD_JOINER}`);
  }
}

function insertChallengePart() {
  // Basically creating challenge part html, replacing the data nid, converting back to a string and replacing the closing span so that when we insert we end up with 2 parts <span></span><br><span></span>.
  const template = document.createElement('template');
  template.innerHTML = ProjectDefinitionStore.getElementDefinitionById('ChallengePart')?.templateHtml as string;
  const templateWithNewNid = replaceTemplateDataNidsWithNewOnes($(template.content.firstElementChild as HTMLElement));
  const insertedNid = templateWithNewNid[0].getAttribute('data-nid');
  const challengePartHTML = templateWithNewNid[0].outerHTML.replace('</div>', '').trim();
  const activeEditor = EditorStore.getEditor().getActiveEditorInstance();
  activeEditor?.execCommand('mceInsertRawHtml', true, `</div><br data-nid="">${challengePartHTML}`);
  EditorStore.getEditor().setCursorLocation($(`[data-nid="${insertedNid}"]`)[0]);
}

function onDeleteKeys(
  e: React.KeyboardEvent,
  selectedNode: HTMLElement,
  selectionUtil: UnitElementSelectionUtil,
  selectionDetails: SelectionDetails,
  editor: CustomEditor
) {
  const offset = selectionUtil.selection.anchorOffset;
  const currentElmDef = ProjectDefinitionStore.getElementDefinitionById(getDataElementDefinitionId(selectedNode));
  if (currentElmDef?.id === 'ChallengePart') {
    handleChallengePartDeleteAction(e, selectedNode, selectionUtil, editor);
  } else {
    if ((offset === 0 || offset === 1 || offset === 2) && isBackspaceKey(e) && DomAssertions.hasNoText(selectedNode)) {
      if (
        selectionDetails.unitElements.closest &&
        DomAssertions.isEmptyElementWithoutNotEditableContent(selectionDetails.unitElements.closest)
      ) {
        removeElement(selectionDetails);
        return keyIdentifier.nukePropagation(e);
      }
    }
    if (offset === 0 && selectedNode.innerText.length === 1 && isDeleteKey(e)) {
      Dom.replaceContentByZeroLengthChar(selectedNode);
      keyIdentifier.nukePropagation(e);
    }
    if (offset === 1 && selectedNode.innerText.length === 1 && isBackspaceKey(e)) {
      Dom.replaceContentByZeroLengthChar(selectedNode);
      return keyIdentifier.nukePropagation(e);
    }
  }
}

function handleChallengePartDeleteAction(
  e: React.KeyboardEvent,
  selectedNode: HTMLElement,
  selectionUtil: SelectionUtil,
  editor: CustomEditor
) {
  /* 2 cases to handle here
   * - At first or last position in a part and previous/next sibling is a part so need to merge them
   * - At first position in a part and no previous sibling or at last position in a part and no next sibling do nothing
   */
  const offset = selectionUtil.selection.anchorOffset;
  const characterAtZero = selectionUtil.selection.anchorNode?.textContent?.[0].trim();

  const isMergeIntoPrevPart =
    isBackspaceKey(e) && (offset === 0 || (offset === 1 && characterAtZero === '')) && !!selectedNode.previousElementSibling;

  const isMergeIntoNextPart = isDeleteKey(e) && offset === selectedNode.innerHTML.length && !!selectedNode.nextElementSibling;

  if (isMergeIntoPrevPart || isMergeIntoNextPart) {
    const currentNodeHTML = selectedNode.innerHTML;
    // If we have previous/next element sibling it will be line break so get the one after/before that
    const partToMergeInto = isMergeIntoPrevPart
      ? selectedNode.previousElementSibling?.previousElementSibling
      : selectedNode.nextElementSibling?.nextElementSibling;

    if (partToMergeInto) {
      e.preventDefault();

      const cursorLocation = partToMergeInto.textContent?.length;
      partToMergeInto.innerHTML = isMergeIntoPrevPart
        ? partToMergeInto.innerHTML + currentNodeHTML
        : currentNodeHTML + partToMergeInto.innerHTML;

      isMergeIntoPrevPart ? selectedNode.previousElementSibling?.remove() : selectedNode.nextElementSibling?.remove();
      selectedNode.remove();

      setTimeout(() => editor.selection?.setCursorLocation(partToMergeInto?.firstChild ?? partToMergeInto, cursorLocation), 200);
    }
  } else if (
    selectedNode.innerHTML.length === 1 &&
    ((isBackspaceKey(e) && offset === 1 && !selectedNode.previousElementSibling) ||
      (isDeleteKey(e) && offset === selectedNode.innerHTML.length - 1 && !selectedNode.nextElementSibling))
  ) {
    e.preventDefault();
    selectedNode.innerHTML = `${ZERO_LENGTH_WORD_JOINER}`;
    setTimeout(() => editor.selection.setCursorLocation(selectedNode), 200);
  } else if (
    (isBackspaceKey(e) && offset === 0 && !selectedNode.previousElementSibling) ||
    (isDeleteKey(e) && offset === selectedNode.innerHTML.length && !selectedNode.nextElementSibling)
  ) {
    e.preventDefault();
  }
}

function onTextSelection(
  e: React.KeyboardEvent,
  selectedNode: Element,
  selectionUtil: UnitElementSelectionUtil,
  selectionDetails: SelectionDetails
) {
  if (keyIdentifier.isDeleteKeys(e) && (selectionDetails.selectedNode.isElement || selectionDetails.selectedNode.isUnit)) {
    removeElement(selectionDetails);
    return keyIdentifier.nukePropagation(e);
  }

  if (keyIdentifier.isPasteKeys(e)) {
    selectionUtil.replaceTextInsideSelection(selectionDetails, e);
    return; // leave it to onPastePreProcess event handler
  }

  if (keyIdentifier.isCutKeys(e)) {
    if (selectionUtil.isSameTextNode(selectionDetails)) {
      if (selectionUtil.range.startOffset !== selectionUtil.range.endOffset) {
        return;
      }
    }
    return selectionUtil.replaceTextInsideSelection(selectionDetails, e);
  }

  if (selectionUtil.isSameTextNode(selectionDetails)) {
    if (!keyIdentifier.isDeleteKeys(e) || !selectionDetails.selectedNode.isEntirelySelected) {
      return; // leave it to tinymce
    }
    selectionUtil.replaceFirstTextNodeByZeroLengthChar(selectionDetails);
    return keyIdentifier.nukePropagation(e);
  }
  selectionUtil.replaceTextInsideSelection(selectionDetails, e);
  return keyIdentifier.nukePropagation(e);
}

function getSelectedNode(): Element | null {
  const node = tinymce.activeEditor.selection ? tinymce.activeEditor.selection.getNode() : null;
  if (!node) {
    return null;
  }
  if (isInlineElement(node)) {
    return node.closest('.edit-target');
  }
  return node;
}

function removeElement(selectionDetails: SelectionDetails) {
  const element = selectionDetails.unitElements.closest;
  if (!element) {
    return;
  }
  const elementParent = element.parentElement!;

  if (element && element.parentElement && DomAssertions.isUnit(elementParent)) {
    EditorStore.deleteUnitSelectedUnit();
  } else if (element && element.parentElement) {
    deleteElementAndRecordTransaction(element);
  }
}
