import React, { useEffect, useState } from 'react';
import Checkbox from 'material-ui/Checkbox';
import { IHighlight } from 'mm-types';
import { styleMap } from '../../../../../../styles/styleGuard';

export type Props = {
  canEdit: boolean;
  unitHighlight: IHighlight;
  onWhatsNewChanged: () => void;
  onClick: (uhm: IHighlight) => void;
  selected?: boolean;
};

export type State = {
  active: boolean;
};

const styles = styleMap({
  selectedHighlightStyle: {
    backgroundColor: '#eaeaea'
  },
  hiddenCheckbox: {
    display: 'none'
  }
});

const HighlightListItem = (props: Props) => {
  const [active, setActive] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(props.unitHighlight.includedInWhatsNew ?? false);

  useEffect(() => {
    setChecked(props.unitHighlight.includedInWhatsNew ?? false);
  }, [props.unitHighlight.includedInWhatsNew]);

  const hiddenStyle = !active && !checked ? styles.hiddenCheckbox : undefined;

  return (
    <div
      className="comment"
      onMouseLeave={() => setActive(false)}
      onMouseOver={() => setActive(true)}
      onClick={() => {
        props.onClick(props.unitHighlight);
      }}
      style={props.selected ? styles.selectedHighlightStyle : undefined}
    >
      <div className="whats-new">
        <Checkbox
          disabled={!props.canEdit}
          style={hiddenStyle}
          checked={checked}
          onClick={(e) => {
            e.stopPropagation();
            props.onWhatsNewChanged();
          }}
        />
      </div>

      <div className="comment-body">{props.unitHighlight.comment}</div>

      <div className="comment-right">
        <span className="comment-user-name">{props.unitHighlight.displayName}</span>
      </div>
    </div>
  );
};

export default HighlightListItem;
