import * as Reflux from 'reflux';
import * as unitsClient from '../../clients/units';
import { GetOptions } from '../../clients/units';
import * as projectClient from '../../clients/project';
import Store from '../Store';
import { IUnit, IProject } from 'mm-types';

export type State = {
  units: IUnit[];
  project: null | IProject;
};

export type SecondaryDocumentStoreEvent = State;

export class SecondaryDocumentStore extends Store<State> {
  private _project: IProject | null;

  constructor() {
    super();
    this._project = null;
    this.state = {
      units: [],
      project: null
    };
  }

  getInitialState() {
    return this.state;
  }

  // Event Handlers

  async retrieveUnits(projectUid: string, indexUid: string, params: Partial<GetOptions>) {
    const response = await unitsClient.getUnits(projectUid, indexUid, params);

    if (!(this.state.project && this.state.project.uid === projectUid)) {
      this._project = await projectClient.getProject(projectUid);
    }

    this.state.project = this._project;
    this.state.units = response.units.map((u) => u.unit);
    this.trigger(this.state as SecondaryDocumentStoreEvent);
  }

  async retrieveProject(projectUid: string) {
    try {
      if (!(this.state.project && this.state.project.uid === projectUid)) {
        this._project = await projectClient.getProject(projectUid);
      }

      this.state.project = this._project;
      return this.state;
    } catch {
      return this.state;
    }
  }
}

const singleton = Reflux.initStore<SecondaryDocumentStore>(SecondaryDocumentStore);
export default singleton;
