import * as events from 'events';
import { IUnit } from 'mm-types';

class UnitComponentization {
  private COMPONENTIZE_RANGE: number;
  private VISIBLE_UNIT_BUFFER: number;
  private _eventEmitter: events.EventEmitter;
  private _docContainerRef: null | Element;
  private _scrollingPageContainerPosition: null | ClientRect;
  private _unitInitialPositions: { top: number; bottom: number }[];

  constructor() {
    this.COMPONENTIZE_RANGE = 80; // no. of units before and after (combined value) visible unit on page that should be made mounted as DocUnits
    // no. of additional units on top and bottom of COMPONENTIZE_RANGE where we won't trigger a new set of unit un/mounts (allows for a page of scroll before we get un/mount units):
    this.VISIBLE_UNIT_BUFFER = 15; // should represent a conservative typical max units on a screen, higher value more efficient (should be < COMPONENTIZE_RANGE/4)

    this._docContainerRef = null;
    this._scrollingPageContainerPosition = null;
    this._unitInitialPositions = [];

    this._eventEmitter = new events.EventEmitter();
    this._eventEmitter.setMaxListeners(0);
  }

  on(event, fn) {
    this._eventEmitter.removeAllListeners(event); // simple for now, but only allow one listener per instance
    this._eventEmitter.on(event, fn);
  }

  get componentizeRange() {
    return this.COMPONENTIZE_RANGE;
  }

  get event() {
    return this._eventEmitter;
  }

  set docContainer(d: Element) {
    this._docContainerRef = d;
  }

  resetUnitInitialPositions() {
    this._unitInitialPositions = [];
  }

  recalculateComponentizedUnits(currentInitialComponentizedUnitIndex, docUnits) {
    if (this._docContainerRef) {
      this._scrollingPageContainerPosition = this._docContainerRef.getBoundingClientRect()!;
      this.updateInitialComponentizedUnitIndex(currentInitialComponentizedUnitIndex, docUnits, {
        forcePositionCalc: true
      });
    }
  }

  updateInitialComponentizedUnitIndex(
    currentInitialComponentizedUnitIndex: number,
    docUnits: IUnit[],
    options: { isZoomed?: boolean; forcePositionCalc?: boolean; areNewUnitsAdded?: boolean } = {}
  ) {
    // TODO for some reason require to drop this inefficiency when isZoomed - needs investigation but will do for now
    if (this._unitInitialPositions.length === 0 || options.isZoomed || options.forcePositionCalc) {
      this._storeUnitInitialPositions(docUnits);
    }

    let initialComponentizedUnitIndex: null | number = null;

    if (this._docContainerRef) {
      const maxPosition =
        this._unitInitialPositions.length - this.COMPONENTIZE_RANGE > 0 ? this._unitInitialPositions.length - this.COMPONENTIZE_RANGE : 0;

      for (let i = 0; i < this._unitInitialPositions.length; ++i) {
        const unitPosition = this._unitInitialPositions[i];

        // adjust unit position values based on current scroll position
        const currentUnitTop = unitPosition.top;
        const currentUnitBottom = unitPosition.bottom;
        /*
        console.log(
          'unit index = ' +
            i +
            ', height = ' +
            (currentUnitBottom - currentUnitTop) +
            ', currentUnitTop = ' +
            currentUnitTop +
            ', currentUnitBottom = ' +
            currentUnitBottom
        );
         */
        // if first unit found is in visible window
        if (
          this._scrollingPageContainerPosition &&
          currentUnitBottom > this._scrollingPageContainerPosition.top &&
          currentUnitTop < this._scrollingPageContainerPosition.bottom
        ) {
          // ensure there is a range either side of the unit
          if (i > maxPosition) {
            initialComponentizedUnitIndex = maxPosition + 1;
          } else {
            initialComponentizedUnitIndex = i - this.COMPONENTIZE_RANGE / 2;
          }
          initialComponentizedUnitIndex = initialComponentizedUnitIndex < 0 ? 0 : initialComponentizedUnitIndex;
          // console.log(' new initialComponentizedUnitIndex = ' + initialComponentizedUnitIndex);
          break;
        }
      }

      // if initialComponentizedUnitIndex unit has changed
      if (initialComponentizedUnitIndex !== null && currentInitialComponentizedUnitIndex !== initialComponentizedUnitIndex) {
        const bottomRange = initialComponentizedUnitIndex - this.VISIBLE_UNIT_BUFFER;
        const topRange = initialComponentizedUnitIndex + this.VISIBLE_UNIT_BUFFER;
        const isInRangeOfStart = initialComponentizedUnitIndex < this.VISIBLE_UNIT_BUFFER * 2;

        // this is where we detect if initial unit is within a visible window range to justify changing state (and thereby mounting appropriate units)
        if (
          // if suggested initial unit is in vicinity of start of page ignore VISIBLE_UNIT_BUFFER
          isInRangeOfStart ||
          options.areNewUnitsAdded ||
          !(currentInitialComponentizedUnitIndex > bottomRange && currentInitialComponentizedUnitIndex < topRange) // if suggested initial unit is outside VISIBLE_UNIT_BUFFER range
        ) {
          // console.log('UPDATE COMPONENTIZED UNITS: ' + currentInitialComponentizedUnitIndex + ' to ' + initialComponentizedUnitIndex);
          this._eventEmitter.emit('componentizedUnitsChanged', initialComponentizedUnitIndex);
        }
      }
    }
  }

  // cache positions for efficiency
  _storeUnitInitialPositions(docUnits: IUnit[]) {
    if (this._docContainerRef) {
      // may be null if page unloading

      this._unitInitialPositions = [];

      const unitEls = this._docContainerRef.firstElementChild!.children;

      for (let i = 0; i < unitEls.length; ++i) {
        // if unit for corresponding html element is intended to be visible, store a position for it
        // TODO: bref: there may be an issue with ghost items they can be made visible through state.deletedElements which
        // could break the paging algorithm if there were a *lot* displaying on the one screen
        // needs revisit: but edge case I think for now
        if (docUnits && docUnits[i] && docUnits[i].isVisibleOnEdit) {
          const unitEl = unitEls[i];
          const unitBoundingRect = unitEl.getBoundingClientRect();
          /*
          console.log(
            'unit index = ' +
              i +
              ', height = ' +
              (unitBoundingRect.bottom - unitBoundingRect.top) +
              ', currentUnitTop = ' +
              unitBoundingRect.top +
              ', currentUnitBottom = ' +
              unitBoundingRect.bottom
          );
           */
          // adjust units positions to 0 scroll based (i.e. their original positions)
          this._unitInitialPositions.push({
            top: unitBoundingRect.top,
            bottom: unitBoundingRect.bottom
          });
        }
      }
    }
  }
}

export default UnitComponentization;
