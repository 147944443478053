import { InsertAction } from '../../menus/insert/content/ContentMenuContainer';
import { EditorStore, INestedUnitFocusChangeEvent } from '../../../../flux/editor/EditorStore';
import { isInlineMarkupAction } from '../units/unit/inlineElementsLookup';
import {
  getActiveFormats,
  getClosestFigureNode,
  getFirstMatchingActionElementFromStartNode,
  getFirstMostNestedEditTarget,
  getIsActionOfType,
  handleDuRefInlineStyleRemoval,
  hasMultipleSelectedElements,
  isActionOfType,
  removeFormatWithinSelection,
  replaceTemplateDataNidsWithNewOnes,
  stripEmptyParagraphs,
  withContentNotEditableAttribute
} from './TinyFacadeHelpers';
import Log from '../../../../utils/Log';
import { dom, EditorManager } from 'tinymce';
import { _BORDER_STYLE_VALUES, _BORDER_VALUES, _JUSTIFY_VALUES } from './TinyFacade';
import { CustomEditor } from './EditorInstanceManager';
import * as _ from 'lodash';
import { IUnit, TinyAction } from 'mm-types';
import { UnitTypes } from '../units/UnitTypes';
import { DomAssertions } from './DomAssertionsUtil';
import { ZERO_LENGTH_WORD_JOINER } from './key_listeners/keyBehaviourUtils';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import { ElementDetails } from '../units/ElementDetails';
import { linkHelper } from './tinyLinkHelper';
import getDataElementFamily = ElementDetails.getDataElementFamily;

declare const tinymce: EditorManager;

interface BespokeExecExists {
  bespokeExecExists: boolean;
}

export class TinyFacadeExecCommand {
  constructor(
    private editor: CustomEditor,
    private editorStore: EditorStore,
    private unitKeyBehaviors: typeof import('./key_listeners/UnitKeyBehaviors')
  ) {}

  private async execInsertContent(data: string, insertPoint: HTMLElement, insertPosition?: InsertAction) {
    if (!insertPosition) {
      Log.error('No insert position');
      return void 0;
    }
    let $insertedContent: JQuery<HTMLElement> | null = withContentNotEditableAttribute(data);
    $insertedContent = replaceTemplateDataNidsWithNewOnes($insertedContent);
    // Ensure &noBreak inserted into .edit-target
    if ($insertedContent.hasClass('arc-ecam-data')) {
      _.each($insertedContent.find('.edit-target:empty'), (elm) => (elm.innerText = ZERO_LENGTH_WORD_JOINER));
    }
    if ($insertedContent.attr('data-subtype') === 'approved-by') {
      _.each($insertedContent.find('.edit-target:empty'), (elm) => (elm.innerText = ZERO_LENGTH_WORD_JOINER));
    }

    // Make sure ZERO_LENGTH_WORD_JOINER is inside measure. Using .addBack() to include measure itself as well.
    if ($insertedContent.hasClass('arc-measure')) {
      _.each($insertedContent.find('.edit-target').addBack('.edit-target'), (elm) => {
        elm.innerText = ZERO_LENGTH_WORD_JOINER;
      });
    }
    if ($insertedContent.hasClass('arc-refint')) {
      this.editorStore.triggerOpenRefIntModal();
    }

    if ($insertedContent.find('.arc-link')[0]) {
      try {
        const updatedTemplateHtmlString = await linkHelper().populateUnitLinkInfoIfNeeded($insertedContent[0].outerHTML);
        $insertedContent = $(updatedTemplateHtmlString);
      } catch (e) {
        return void 0;
      }
    }
    const $insertTarget = $(insertPoint);
    const selection = this.selection;

    if (insertPosition === 'insert_inside') {
      stripEmptyParagraphs($insertTarget);
      const $editTarget = this.getEditTarget($insertTarget);

      if (DomAssertions.isNodeName($insertedContent[0], 'BR')) {
        this.editor.execCommand('mceInsertRawHTML', false, $insertedContent[0].outerHTML);
      } else if (
        DomAssertions.isNodeName(selection.getNode(), 'P') ||
        DomAssertions.hasClassName(selection.getNode(), 'arc-tocable-heading') ||
        DomAssertions.hasClassName($editTarget, 'arc-action-challenge') ||
        (DomAssertions.hasClassName($editTarget, 'arc-action-challenge-response') &&
          ProjectDefinitionStore.isCurrentProjectDefinitionAirbus())
      ) {
        selection.getRng(true).insertNode($insertedContent[0]);
      } else {
        $insertedContent.appendTo($editTarget);
      }
    } else if (insertPosition === 'insert_before') {
      $insertedContent.insertBefore($insertTarget);
    } else if (insertPosition === 'insert_after') {
      $insertedContent.insertAfter($insertTarget);
    }

    if ($insertedContent && $insertedContent.length) {
      this.setCursorInsideInsertedContent($insertedContent);
    }
    return void 0;
  }

  private execListIndentOutdent(outdent: boolean) {
    const keyboardEvent = new KeyboardEvent('KeyboardEvent', {
      key: 'Tab',
      shiftKey: outdent,
      cancelable: true,
      bubbles: true
    });
    this.unitKeyBehaviors.manageListKeys(tinymce.activeEditor, keyboardEvent);
    return void 0;
  }

  private execRootParagraph(action: TinyAction, options: BespokeExecExists) {
    const customActions = {
      JustifyLeft: { 'text-align': 'left' },
      JustifyCenter: { 'text-align': 'center' },
      JustifyRight: { 'text-align': 'right' },
      JustifyFull: { 'text-align': 'justify' }
    };

    if (isActionOfType(action, _.keys(customActions) as TinyAction[])) {
      $(tinymce.activeEditor.getElement()).css(customActions[action]);
      options.bespokeExecExists = true;
    }
  }

  private execGraphicVideo(action: TinyAction, options: BespokeExecExists) {
    const actionOfType = getIsActionOfType(action);

    const triggerUnitStyleChange = (styleType: string, values: { [prop: string]: string }) => {
      const figureNode = getClosestFigureNode(this.editor);
      if (figureNode) {
        const allValuesClasses = _.values(values).reduce((styles, value) => styles + ' ' + value, '');
        $(figureNode).removeClass(allValuesClasses);
        $(figureNode).addClass(values[action]);
      }
      options.bespokeExecExists = true;
      this.editorStore.triggerUnitStyleChange(styleType);
    };

    if (actionOfType(_.keys(_JUSTIFY_VALUES) as TinyAction[])) {
      triggerUnitStyleChange('justify', _JUSTIFY_VALUES);
    }

    if (actionOfType(_.keys(_BORDER_VALUES) as TinyAction[])) {
      triggerUnitStyleChange('border', _BORDER_VALUES);
    }

    if (actionOfType(_.keys(_BORDER_STYLE_VALUES) as TinyAction[])) {
      triggerUnitStyleChange('border', _BORDER_STYLE_VALUES);
    }
  }

  private execSuperscriptSubscript(action: TinyAction, data: string | null | undefined, options: BespokeExecExists) {
    // apply other format
    try {
      const shouldRemoveFormat = !isActionOfType(action, getActiveFormats(this.editor));
      this.editor.execCommand(action, false, data || null);
      if (shouldRemoveFormat) {
        removeFormatWithinSelection({
          command: action,
          editor: this.editor
        });
      }
    } catch (e) {
      Log.error(e);
    }
    options.bespokeExecExists = true;
  }

  exec(action: TinyAction, data?: string | null, insertPoint?: HTMLElement | null, insertPosition?: InsertAction) {
    const actionType = getIsActionOfType(action);
    // important: on completion of tinymce execCommands - focus doesn't get called on the editor
    // so don;t do silent focus as will leave editor in an invalid state (silent focus will never get cleared)
    const options: BespokeExecExists = {
      bespokeExecExists: false
    };

    // Clear any previous colors when applying a new color - it's a workaround to prevent toggling when applying a color on top of the other color.
    if (actionType('forecolor')) {
      this.editor.formatter.remove('forecolor');
      // Don't do anything else if it was only removing of the color
      if (data === 'ClearColor') {
        return;
      }
    } else if (actionType('hilitecolor')) {
      this.editor.formatter.remove('hilitecolor');
      // Don't do anything else if it was only removing of the color
      if (data === 'ClearColor') {
        return;
      }
    }

    if (actionType('mceInsertContent') && insertPoint && data) {
      return this.execInsertContent(data, insertPoint, insertPosition);
    }

    if (actionType(['ListIndent', 'ListOutdent'])) {
      return this.execListIndentOutdent(actionType('ListOutdent'));
    }

    if (this.selectedRootUnit.type === 'paragraph') {
      this.execRootParagraph(action, options);
    } else if (this.isFocused('graphic') || this.isFocused('video')) {
      this.execGraphicVideo(action, options);
    }

    if (actionType(['superscript', 'subscript'])) {
      this.execSuperscriptSubscript(action, data, options);
    }

    // DEFAULT execCommand
    if (!options.bespokeExecExists) {
      // TinyMCE sometimes gets undesirable focus on a tr when there's
      // no explicit selection, causes nop for formatting actions
      if (action.startsWith('mceTable')) {
        return this.handleInsertTableRowOrCol(action, data, insertPoint, insertPosition);
      }
      if (
        actionType(['bold', 'code', 'italic', 'underline', 'overline', 'forecolor', 'hilitecolor'] as TinyAction[]) &&
        DomAssertions.isNodeName(this.selection.getNode(), 'TABLE') &&
        hasMultipleSelectedElements(tinymce.activeEditor.getBody())
      ) {
        this.selectParentNode(action);
      }

      if (isInlineMarkupAction(action)) {
        // ensure to not apply markup across table cell selection
        this.editorStore.getEditor().getActiveEditorFacade()!.clearSelection();
      }

      if (
        actionType(['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyFull']) &&
        getDataElementFamily(this.editor.selection.getNode()) === 'TableData'
      ) {
        return ((this.editor.selection.getNode() as HTMLTableCellElement).style.textAlign =
          action.indexOf('Full') !== -1 ? 'justify' : action.replace('Justify', '').toLowerCase());
      }

      handleDuRefInlineStyleRemoval(this.editor, action);

      this.editor.execCommand(action, false, data ? data : null);

      // when insert OL/UL, ensure to position cursor ready for entry (defaults to after the new block)
      if (actionType('mceInsertContent')) {
        this.setCursorInsideList();
      }
    }
  }

  private setCursorInsideInsertedContent($insertedContent: JQuery<HTMLElement>) {
    const selection = this.selection;

    if (DomAssertions.isNodeName($insertedContent![0], 'BR')) {
      const brDataNid = $insertedContent![0].getAttribute('data-nid');
      if (brDataNid) {
        const br = this.editor.dom.select(`br[data-nid=${brDataNid}]`);
        selection.setCursorLocation(br[0] as any);
      }
    } else {
      const $editTarget = $insertedContent?.find('.edit-target');
      if ($editTarget.length) {
        const paragraphEditTarget = $insertedContent?.find('p.edit-target')[0];
        if (paragraphEditTarget) {
          paragraphEditTarget.appendChild(document.createElement('BR'));
          selection.select(paragraphEditTarget.childNodes[0] as any);
        } else {
          let editTargetFocus = getFirstMostNestedEditTarget($editTarget);
          if (['GraphRef'].indexOf($insertedContent[0].getAttribute('data-element-definition-id') ?? '') !== -1) {
            // get nested most edit target if graphref so we are displaying all data attributes
            editTargetFocus = $editTarget[$editTarget.length - 1];
          }
          // bullet proof cursor focusing
          selection.select(editTargetFocus);
          selection.collapse();
          const position = ElementDetails.getDataElementDefinitionId($insertedContent[0]) === 'EcamData' ? 1 : 0;
          selection.setCursorLocation(editTargetFocus, position);
        }
      } else {
        if ($insertedContent.children().length) {
          selection.select($insertedContent![0]);
        } else {
          if ($insertedContent[0].innerText.length === 0 || $insertedContent[0].innerText === ZERO_LENGTH_WORD_JOINER) {
            selection.select($insertedContent[0] as any, true);
            this.editorStore.getEditor().setCursorLocation($insertedContent[0], true);
          } else {
            selection.setCursorLocation($insertedContent[0] as any, 0);
          }
        }
      }
      this.editorStore.getEditor().getActiveEditorFacade()?.nodeChanged();
    }
  }

  private setCursorInsideTableRow() {
    const currentSelection = $(this.selection.getRng(true).startContainer)[0];
    if (currentSelection && DomAssertions.isNodeName(currentSelection, 'TR')) {
      this.selection.setCursorLocation(currentSelection.firstChild as any);
    }
  }

  private selectParentNode(action: TinyAction) {
    const startParentNode = getFirstMatchingActionElementFromStartNode(action, this.selection.getStart());
    if (startParentNode) {
      this.selection.select(startParentNode as any, true);
    }
  }

  private setCursorInsideList() {
    const insertedNode = $(this.selection.getRng(true).startContainer).prev()[0];
    if (insertedNode && DomAssertions.isNodeName(insertedNode, ['UL', 'OL'])) {
      this.selection.setCursorLocation(insertedNode as any);
    }
  }

  private getEditTarget($el: JQuery<HTMLElement>): JQuery<HTMLElement> {
    const $editorInstanceContainer = $el.find('.editor-instance-container'); // check if we're outside the instantiated editor, if so move to that editor
    if ($editorInstanceContainer.length > 0) {
      return $($editorInstanceContainer[0]) as JQuery<HTMLElement>; // move down to the editor, so newly inserted element is inside editor
    } else {
      // Use first edit target or  check if $insertTarget has an .element-container class, if so then set $editTarget to the inner element with class .edit-target
      // otherwise leave it as it is, can't be smart enough to find a better insertion point
      if ($el.find('.edit-target').get(0) || $el.hasClass('element-container')) {
        return $($el.find('.edit-target')[0]) as JQuery<HTMLElement>;
      }
      return $el;
    }
  }

  private get selection(): dom.Selection {
    return tinymce.activeEditor.selection;
  }

  private get selectedRootUnit(): IUnit {
    return this.editorStore.getSelectedUnit()!;
  }

  private isFocused(type: UnitTypes): boolean {
    const nestedUnitFocusChangeEvent: INestedUnitFocusChangeEvent = this.editorStore.getLastFocusedNestedUnit()!;
    return (
      this.selectedRootUnit.type === type ||
      (nestedUnitFocusChangeEvent && nestedUnitFocusChangeEvent.focused && nestedUnitFocusChangeEvent.focused.type.toLowerCase() === type)
    );
  }

  applyRootElementDefaultsToElementInnerHtml(element: Element, defaultTemplate?: string) {
    const rootElementDefaults = ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(element))
      ?.rootElementDefaults;
    if (rootElementDefaults && rootElementDefaults.length > 0) {
      element.innerHTML = '';
      rootElementDefaults.forEach((defaultElmId) => {
        element.innerHTML = element.innerHTML + ProjectDefinitionStore.getElementDefinitionById(defaultElmId)?.templateHtml ?? '';
      });
    } else {
      element.innerHTML = defaultTemplate ?? '';
    }
  }

  handleInsertTableRowOrCol(action: TinyAction, data?: string | null, insertPoint?: HTMLElement | null, insertPosition?: InsertAction) {
    const actionType = getIsActionOfType(action);
    this.setCursorInsideTableRow();
    // When inserting rows/cols TinyMCE clones the current row/col and elements in it, after insert we need to clean that row/col
    if (actionType(['mceTableInsertRowBefore', 'mceTableInsertRowAfter', 'mceTableInsertColBefore', 'mceTableInsertColAfter'])) {
      // Get closest td currently. TODO: AER-9031 will ensure that insert command can only happen with valid insert point
      const currentInsertPoint = <HTMLElement>$(this.selection.getRng(true).startContainer).closest('td')[0];

      if (actionType(['mceTableInsertRowBefore', 'mceTableInsertRowAfter'])) {
        // If template is present insert using template or otherwise do as it would normally do
        if (!!data) {
          return this.handleInsertRowOrColWithTemplate(data, insertPosition);
        }

        this.editor.execCommand(action, false, null);
        const newlyInsertedRow = actionType(['mceTableInsertRowBefore'])
          ? currentInsertPoint.parentElement?.previousElementSibling
          : currentInsertPoint.parentElement?.nextElementSibling;
        if (ElementDetails.getDataNid(newlyInsertedRow) === null && newlyInsertedRow) {
          Array.from(newlyInsertedRow.children).forEach((cell) => {
            this.applyRootElementDefaultsToElementInnerHtml(
              cell,
              ProjectDefinitionStore.getElementDefinitionById('Paragraph')?.templateHtml
            );
          });
        }
      } else if (actionType(['mceTableInsertColBefore', 'mceTableInsertColAfter'])) {
        this.editor.execCommand(action, false, null);
        const newlyInsertedCol = <HTMLTableCellElement>(
          (actionType(['mceTableInsertColAfter']) ? currentInsertPoint.nextElementSibling : currentInsertPoint.previousElementSibling)
        );
        if (ElementDetails.getDataNid(newlyInsertedCol) === null && newlyInsertedCol) {
          const cellsAtColIndex = newlyInsertedCol.closest('table')?.querySelectorAll(`td:nth-child(${newlyInsertedCol.cellIndex + 1})`);
          cellsAtColIndex?.forEach((cell: HTMLTableCellElement) => {
            this.applyRootElementDefaultsToElementInnerHtml(
              cell,
              ProjectDefinitionStore.getElementDefinitionById('Paragraph')?.templateHtml
            );
          });
        }
      }
    } else {
      this.editor.execCommand(action, false, data ? data : null);
    }
    return 0;
  }

  handleInsertRowOrColWithTemplate(data: string, insertPosition?: InsertAction) {
    let derivedInsertPoint = <HTMLElement>$(this.selection.getRng(true).startContainer).closest('tr')[0];
    this.execInsertContent(data, derivedInsertPoint, insertPosition);
    return this.setCursorInsideTableRow();
  }
}
