import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const Reg = ({ entry }: PropsType) => {
  const regDom: JSX.Element[] = [];

  if (entry.reg) {
    const regList = ['name', 'description', 'type', 'subType', 'reference', 'subReference'];
    regList.forEach((key) => {
      let name;
      let val;
      if (entry.reg && (entry.reg[key] === '' || entry.reg[key] === null)) {
        name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
          return str.toUpperCase();
        });
        val = 'None';
      } else {
        name = key.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
          return str.toUpperCase();
        });
        val = entry.reg && entry.reg[key];
      }
      regDom.push(
        <div key={key}>
          <label>{name}:</label> <span>{val}</span>
        </div>
      );
    });
  }
  return <div className="settings-details">{regDom}</div>;
};
