import * as React from 'react';
import TextField from 'material-ui/TextField';
import LinkStore, { LinkStoreEvent } from '../../../../flux/editor/LinkStore';
import { IProject } from 'mm-types';
import { CircularProgress } from 'material-ui';
import { Page } from '../../../../flux/projects/DocumentsStore';
import * as key from '../../../misc/keyboard';
import FontIcon from 'material-ui/FontIcon';
import Paging from '../../../general/Paging';
import { PROJECT_DEFINITION_TYPES } from '../../../../flux/common/ProjectDefinitionStore';
import { useEffect, useImperativeHandle, useState } from 'react';
import useListenToStore from '../../../hooks/useListenToStore';

export type Props = {
  onSelectProject: (project: IProject) => void;
  selectedProjectUid?: string;
  hideExternal: boolean;
  clearSelectedProject: () => void;
  onFetching(isFetching: boolean);
  isDuRefLink?: boolean;
};

export type State = {
  projects: IProject[];
  searchText: string;
  page: Page;
  loading: boolean;
  selectedProjectUid?: string | null;
};

export type DocumentPickerType = {
  getSelectedProject(): IProject;
};

const DocumentPicker = (props: Props, ref: React.RefObject<DocumentPickerType>) => {
  const [state, setState] = useState<State>({
    projects: [],
    searchText: '',
    selectedProjectUid: null,
    loading: true,
    page: { pageNumber: 0, pageSize: 30, numOfPages: 0, totalElements: 0 }
  });

  useImperativeHandle(ref, () => ({
    getSelectedProject: () => getSelectedProject()
  }));

  useListenToStore({ store: LinkStore, eventListener: onLinkStoreUpdate, update: [state.projects, state.selectedProjectUid] });

  useEffect(() => {
    props.onFetching(true);
    // if props.selectedProjectUid then 1. lookup project 2. take project title 3. search for docs with that title 4. use a switch so event type is projects-retrieved-edit
    if (props.selectedProjectUid) {
      LinkStore.getExternalDocsForTargetProject(props.selectedProjectUid).catch((e) =>
        console.warn(`DocumentPicker.ts - useEffect() - getExternalDocsForTargetProject(): ${e}`)
      );
    } else {
      LinkStore.getExternalDocs(props.hideExternal, state.page, state.searchText).catch((e) =>
        console.warn(`DocumentPicker.ts - useEffect() - getExternalDocs(): ${e}`)
      );
    }
  }, []);

  useEffect(() => {
    props.onSelectProject(getSelectedProject());
  }, [props.selectedProjectUid, state.projects]);

  function onLinkStoreUpdate(e: LinkStoreEvent) {
    if (e?.type === 'projects-retrieved') {
      if (props.selectedProjectUid) {
        props.clearSelectedProject();
      }
      props.onFetching(false);
      setState((prevState) => ({ ...prevState, projects: e.externalProjects, page: e.page, loading: false }));
    } else if (e?.type === 'projects-retrieved-edit') {
      props.onFetching(false);
      setState((prevState) => ({
        ...prevState,
        projects: e.externalProjects,
        page: e.page,
        selectedProjectUid: props.selectedProjectUid,
        loading: false,
        searchText: e.targetProjectName ? e.targetProjectName : ''
      }));
    }
  }

  const getSelectedProject = () => {
    return state.projects.find((p) => p.uid === state.selectedProjectUid) as IProject;
  };

  const onKeyDown = (e: { keyCode: number }) => {
    switch (e.keyCode) {
      case key.ENTER:
        props.onFetching(true);
        setState((prevState) => ({ ...prevState, loading: true }));
        LinkStore.getExternalDocs(props.hideExternal, Object.assign({}, state.page, { pageNumber: 0 }), state.searchText).catch((e) =>
          console.warn(`DocumentPicker.ts - onKeyDown() - getExternalDocs(): ${e}`)
        );
        break;
    }
  };

  const updateSearchText = (text: string) => {
    setState((prevState) => ({ ...prevState, searchText: text }));
  };

  const clearFilter = () => {
    if (state.searchText) {
      props.onFetching(true);
      setState((prevState) => ({ ...prevState, searchText: '', loading: true }));
      LinkStore.getExternalDocs(props.hideExternal, Object.assign({}, state.page, { pageNumber: 0 }), '').catch((e) =>
        console.warn(`DocumentPicker.ts - clearFilter() - getExternalDocs(): ${e}`)
      );
    }
  };

  const canSelect = (p: IProject) => {
    if (props.isDuRefLink) {
      return p.definitionName === PROJECT_DEFINITION_TYPES.airbus && p.lastPublishedIndexUid !== undefined;
    } else {
      return p.definitionName !== PROJECT_DEFINITION_TYPES.airbus && p.lastPublishedIndexUid !== undefined;
    }
  };

  const selectProject = (e: React.MouseEvent<HTMLElement>, project: IProject) => {
    if (canSelect(project)) {
      setState((prevState) => ({ ...prevState, selectedProjectUid: project.uid }));
      props.onSelectProject(project);
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const moveToPage = (pageNumber: number) => {
    props.onFetching(true);
    setState((prevState) => ({ ...prevState, loading: true }));
    LinkStore.getExternalDocs(props.hideExternal, Object.assign({}, state.page, { pageNumber: pageNumber }), state.searchText).catch((e) =>
      console.warn(`DocumentPicker.ts - moveToPage() - getExternalDocs(): ${e}`)
    );
  };

  return (
    <div className="document-picker">
      <TextField
        style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', display: 'inline-block' }}
        value={state.searchText}
        id="document-picker-input"
        floatingLabelText="Filter Documents"
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e, text) => updateSearchText(text)}
      />
      <FontIcon className="material-icons clear-icon clickable" style={{ fontSize: '15px' }} onClick={() => clearFilter()} color="#bdbdbd">
        clear
      </FontIcon>

      <div className="document-list" style={{ textAlign: state.loading ? 'center' : 'inherit' }}>
        {state.loading ? <CircularProgress style={{ margin: '20px' }} /> : undefined}
        {state.loading
          ? undefined
          : state.projects.map((project) => {
              return (
                <div
                  key={project.uid}
                  className={
                    'document allowClick' +
                    (project.uid === state.selectedProjectUid ? ' selected' : '') +
                    (canSelect(project) ? '' : ' disabled')
                  }
                  onClick={(e) => selectProject(e, project)}
                >
                  <span className="document-icon fontello-menu-item material-icons icon-new-doc" />
                  <div className="document-left">
                    <div className="document-title">{project.name}</div>
                    <div className="document-subtitle">{project.description}</div>
                  </div>
                </div>
              );
            })}

        {!state.loading ? (
          <Paging compact={true} page={state.page} moveToPage={(pageNumber: number) => moveToPage(pageNumber)} />
        ) : undefined}
      </div>
    </div>
  );
};

export default React.forwardRef(DocumentPicker);
