import * as React from 'react';
import LinkTypeSelector from './generic/LinkTypeSelector';
import LinkDisplayInput from './generic/LinkDisplayInput';
import LinkDestinationInput, { LinkDestinationInputType } from './generic/LinkDestinationInput';
import { LinkModel } from './generic/linkService';

export type Props = {
  showValidation: boolean;
  model: Partial<LinkModel>;
  onModelUpdated: (partial: Partial<LinkModel>) => void;
  isHotspot?: boolean;
};

export type ExternalLinkComponentType = {
  isValid: () => boolean;
};

const ExternalLinkComponent = React.forwardRef((props: Props, ref: React.Ref<ExternalLinkComponentType>) => {
  const displayInput = React.useRef<LinkDisplayInput>(null);
  const destinationInput = React.useRef<LinkDestinationInputType>(null);

  React.useImperativeHandle(ref, () => ({
    isValid
  }));

  const isValid = () => {
    return ((props.isHotspot || displayInput.current?.isValid()) && destinationInput.current?.isValid()) ?? false;
  };

  const title: string = props.isHotspot ? 'Select link type and link destination' : 'Select link type and link display text';
  return (
    <div className="link-modal-body-external">
      <h3>{title}</h3>
      <div>
        <div className="input">
          <LinkTypeSelector value={props.model?.type} onChange={(type) => props.onModelUpdated({ type: type })} />
        </div>

        {!props.isHotspot && (
          <div className="input">
            <LinkDisplayInput
              value={props.model?.text}
              ref={displayInput}
              showValidation={props.showValidation}
              onChange={(text) => props.onModelUpdated({ text: text })}
            />
          </div>
        )}

        <div className="input">
          <LinkDestinationInput
            value={props.model?.href}
            ref={destinationInput}
            showValidation={props.showValidation}
            onChange={(text) => props.onModelUpdated({ href: text })}
            type={props.model?.type}
          />
        </div>
      </div>
    </div>
  );
});

export default ExternalLinkComponent;
