import * as React from 'react';
import { IconButton } from 'material-ui';
import FontSearch from 'material-ui/svg-icons/action/search';
import FontCancel from 'material-ui/svg-icons/navigation/cancel';
import { useDebouncedCallback } from '../../../../general/useDebouncedCallback';

export type Props = {
  onSearch: (term: string) => void;
  onClose?: () => void;
  hasCancel?: boolean;
  placeholder?: string;
  triggerOnChange?: boolean;
  dataqa?: string;
};

const Searchbar = ({ onSearch, onClose, hasCancel, triggerOnChange = false, placeholder = 'Search', dataqa }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const performSearchDebounced = useDebouncedCallback((term: string) => {
    performSearch(term);
  }, 300);

  function updateSearchTerm(term: string, forceSearch?: boolean) {
    setSearchTerm(term);
    if (triggerOnChange) {
      performSearchDebounced(term);
    } else if (forceSearch) {
      performSearch(term);
    }
  }

  function performSearch(term: string) {
    onSearch(term);
  }

  return (
    <div className="search-bar">
      <div>
        <IconButton
          className="search-btn"
          onClick={(e) => performSearch(searchTerm)}
          iconStyle={{ color: '#8a8a8a' }}
          style={{ padding: '0', height: 30, width: 30 }}
        >
          <FontSearch />
        </IconButton>
      </div>
      <div>
        <input
          value={searchTerm}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              performSearch(searchTerm);
            }
          }}
          onChange={(e) => updateSearchTerm((e.target as HTMLInputElement).value)}
          placeholder={placeholder}
          data-qa={dataqa}
        />
      </div>
      <div>
        <IconButton
          onClick={() => updateSearchTerm('', true)}
          iconStyle={{ height: 15, width: 15, color: '#8a8a8a' }}
          style={{
            padding: '0',
            height: 15,
            width: 15,
            verticalAlign: 'middle',
            margin: `${hasCancel ? '0 5px 0 0' : '7px 8px 0 0'}`,
            visibility: searchTerm.length > 0 ? 'initial' : 'hidden'
          }}
          className="search-clear"
        >
          <FontCancel />
        </IconButton>

        {hasCancel ? (
          <div onClick={() => onClose && onClose()} className="search-cancel">
            Cancel
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Searchbar;
