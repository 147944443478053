import React from 'react';
import { INotificationListItemProps } from './INotificationListItemProps';
import LinearProgress from 'material-ui/LinearProgress';

const CreateInterim = ({ notif, getName, handleNotifAction }: INotificationListItemProps) => {
  return (
    <div className="notif-item-container notif-item-container-create-interim">
      <div className="left-side">
        <span className={'icon icon-import-' + (notif.isError ? 'error' : notif.isReady ? 'success' : 'progress')} />
      </div>

      <div className="middle-side">
        <div className="popup-title-container">
          <h1>Creating Interim Revision for {notif.project?.name}</h1>
          {getName ? getName(notif) : ''}
        </div>

        {notif.isReady && (
          <div className="notif-item notif-item-done">
            <span className="info success">Created Interim Revision for {notif.project?.name}</span>
          </div>
        )}
        {notif.isError && (
          <div className="notif-item notif-item-done">
            <span className="info error">{notif.error ? notif.error.message : 'Error Creating Interim Revision'}</span>
          </div>
        )}
      </div>

      <div className="right-side">
        <div className="popup-date-container">
          <h5>{notif.receivedFormatAgo}</h5>
        </div>

        {(notif.isReady || notif.isError) && (
          <div className="notif-item notif-item-done">
            <span
              className={'notif-status-toggle'}
              title={notif.status === 'UNREAD' ? 'Set to READ' : 'Set to UNREAD'}
              onClick={() => {
                if (handleNotifAction) {
                  handleNotifAction(notif, 'toggleReadUnread');
                }
              }}
            />

            {notif.isReady && (
              <i
                className="material-icons"
                onClick={() => {
                  if (handleNotifAction) {
                    handleNotifAction(notif, 'link');
                  }
                }}
              >
                link
              </i>
            )}
          </div>
        )}

        {notif.processingStatus && (
          <div className="notif-item notif-item-inprogress">
            <LinearProgress mode="indeterminate" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateInterim;
