import * as React from 'react';
import UserStore, { UserStoreEvent } from '../../../../../flux/settings/UserStore';
import EditorStore from '../../../../../flux/editor/EditorStore';

import UserAutoSuggest from '../../../../misc/UserAutoSuggest';
import { SelectField, MenuItem } from 'material-ui';
import { IUser, TaskAuthor, TaskAuthorComponent } from 'mm-types';
import ActiveUserStore from '../../../../../flux/common/ActiveUserStore';

export type UserListData = { status?: string; reviewerDisposition?: string };

export type Props = {
  reviewer?: boolean;
  title: string;
  editPermission: string;
  emptyText: string;
  users: null | TaskAuthor[];
  disabled?: boolean;
  onChange: (e: { type: string; userUid?: string; data?: string | UserListData }) => void;
  userAvailabilityCheck: (user: IUser) => boolean;
};

export type State = {
  userList: IUser[];
  setSuggestions?: (suggestion: IUser) => void;
};

export default class UserList extends React.Component<Props, State> {
  private unsub: Function;
  // private _getSuggestions: any;

  constructor(props: Props) {
    super(props);
    this.unsub = UserStore.listen(this._onUserStoreChange, this);

    this.state = {
      userList: []
    };
  }

  static defaultProps: Partial<Props> = {
    emptyText: '',
    users: null,
    disabled: false
  };

  componentWillUnmount() {
    this.unsub();
  }

  UNSAFE_componentWillMount() {
    // this._getSuggestions = _.throttle( this._getUserSuggestions.bind( this ), 500, { leading: false } );
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.users) {
      this.setState({ userList: [] });
    }
  }

  _onUserStoreChange(state: UserStoreEvent) {
    if (state.users) {
      const filtered = state.users.filter((u) => this.props.userAvailabilityCheck(u));
      this.setState({ userList: filtered });
    }
  }

  // start: auto suggest support
  _getUserSuggestions(value: string) {
    const PAGE = 0;
    const PAGE_SIZE = 100;
    UserStore.retrieveUsers(value.trim().toLowerCase(), EditorStore.getDocParams().projectUid, PAGE, PAGE_SIZE);
  }

  _renderSuggestion(suggestion: IUser) {
    return (
      <div className="user-autosuggest-entry">
        <img src={suggestion.avatarUrl} />
        <span className="user-name">{suggestion.displayName}</span>
      </div>
    );
  }

  _handleUserSelection(data: { suggestion: string }) {
    this.props.onChange({ type: 'add', data: data.suggestion });
  }

  _handleSelectChange(u: TaskAuthor, action: string) {
    this.props.onChange({ type: 'update', userUid: u.user.uid, data: { status: action } });
  }

  _handleRemoveUserChange(user: IUser) {
    this.props.onChange({ type: 'remove', userUid: user.uid });
  }

  _getProgressStatusMenuItems() {
    return [
      { text: 'Not Started', payload: 'INCOMPLETE' },
      { text: 'In Progress', payload: 'IN_PROGRESS' },
      { text: 'Complete', payload: 'COMPLETE' }
    ];
  }

  _onClicKReviewerDisposition(u: TaskAuthor, name: string) {
    if (this._canEditUser(u)) {
      this.props.onChange({ type: 'update', userUid: u.user.uid, data: { reviewerDisposition: name } });
    }
  }

  _getReviewerDispositionClass(u: TaskAuthor, name: string) {
    const disposition = u.reviewerDisposition;
    let dispositionStr: string = disposition as string;

    if (disposition && (disposition as TaskAuthorComponent).state) {
      dispositionStr = (disposition as TaskAuthorComponent).state;
    }

    if (!dispositionStr || dispositionStr === 'NONE') {
      return `${name}-inactive`;
    }

    const pending = dispositionStr.indexOf('PENDING') > 0;
    return `${name}-${dispositionStr.startsWith(name) ? (pending ? 'pending' : 'active') : 'inactive'}`;
  }

  _canEditUser(userSettings) {
    if (this.props.editPermission === 'none') {
      return false;
    }
    if (this.props.editPermission === 'all') {
      return true;
    }
    return ActiveUserStore.getUser()!.uid === userSettings.user.uid;
  }

  render() {
    return (
      <div className="user-autocomplete-container-outer">
        {this.props.editPermission === 'all' ? (
          <UserAutoSuggest
            placeholder={this.props.title + ' (type to find)'}
            getSuggestions={(term) => this._getUserSuggestions(term)}
            onClear={() => UserStore.clear()}
            users={this.state.userList}
            renderSuggestion={(suggestion) => this._renderSuggestion(suggestion)}
            onSelected={(data) => this._handleUserSelection(data)}
          />
        ) : undefined}

        {this.props.editPermission !== 'all' ? <div className="participant-hdr-disabled">{this.props.title}</div> : undefined}

        <div className="user-autocomplete-container-inner">
          {this.props.users === null || this.props.users.length === 0 ? (
            <div className="empty-text">{this.props.emptyText}</div>
          ) : undefined}

          <ul>
            {this.props.users !== null
              ? this.props.users.map((u) => {
                  return (
                    <li key={u.user.uid}>
                      <div className="first-row">
                        <div className="user-pill">
                          <img src={u.user.avatarUrl} />
                          <span className="user-name">{u.user.displayName}</span>
                          {this.props.editPermission === 'all' ? (
                            <span
                              className="material-icons"
                              onClick={() => {
                                this._handleRemoveUserChange(u.user as IUser);
                              }}
                            >
                              remove_circle
                            </span>
                          ) : undefined}
                        </div>

                        <span className={`task-status task-status-${u.status}`}></span>

                        <SelectField
                          disabled={!this._canEditUser(u)}
                          value={u.status}
                          underlineStyle={{ color: '#ffffff' }}
                          underlineFocusStyle={{ color: '#ffffff' }}
                          className="user-status-select"
                          id="user-status-dropdown"
                          style={{
                            width: '86px',
                            float: 'right',
                            height: '30px',
                            fontSize: '0.9rem',
                            paddingLeft: '0',
                            paddingRight: '0'
                          }}
                          menuStyle={{ width: '86px' }}
                          onChange={(e, i, action) => {
                            this._handleSelectChange(u, action);
                          }}
                        >
                          {this._getProgressStatusMenuItems().map((item) => {
                            return (
                              <MenuItem
                                key={item.payload}
                                value={item.payload}
                                primaryText={item.text}
                                style={{
                                  width: '106px',
                                  fontSize: '0.9rem',
                                  paddingLeft: '0',
                                  paddingRight: '0',
                                  marginLeft: '-10px'
                                }}
                              />
                            );
                          })}
                        </SelectField>
                      </div>
                      {this.props.reviewer ? (
                        <div className="task-reviewer-status">
                          <div
                            className={
                              'task-reviewer-status-btn ' +
                              this._getReviewerDispositionClass(u, 'OK') +
                              (this._canEditUser(u) ? '' : ' disabled')
                            }
                            onClick={() => this._onClicKReviewerDisposition(u, 'OK')}
                          >
                            <span className="material-icons">done</span>
                            OK
                          </div>
                          <div
                            className={
                              'task-reviewer-status-btn ' +
                              this._getReviewerDispositionClass(u, 'NOT_OK') +
                              (this._canEditUser(u) ? '' : ' disabled')
                            }
                            onClick={() => this._onClicKReviewerDisposition(u, 'NOT_OK')}
                          >
                            <span className="material-icons">clear</span>
                            Not OK
                          </div>
                        </div>
                      ) : undefined}
                    </li>
                  );
                })
              : undefined}
          </ul>
        </div>
      </div>
    );
  }
}
