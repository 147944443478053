import * as React from 'react';
import axios from 'axios';
import { dispatchApiRequestFailure, dispatchApiRequestInit, dispatchApiRequestSuccess, responseReducer } from '../useApi';
import { ApiAction, ApiResponseState } from '../../../types/useApi';
import { checkHighlights } from '../../units';
import { IHighlightCheckResponse } from 'mm-types';

export const useRevisionHighlightsCheckApi = (params: { projectUid: string; indexUid: string }, start = true) => {
  const [state, dispatch] = React.useReducer<React.Reducer<ApiResponseState<IHighlightCheckResponse>, ApiAction<IHighlightCheckResponse>>>(
    responseReducer,
    {
      isError: false,
      isFetching: false
    }
  );

  React.useEffect(() => {
    if (!start) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    dispatchApiRequestInit(dispatch);
    checkHighlights(params, cancelTokenSource)
      .then(dispatchApiRequestSuccess.bind(this, dispatch, mounted))
      .catch(dispatchApiRequestFailure.bind(this, dispatch, mounted));

    return () => {
      mounted = false;
      cancelTokenSource.cancel('Cancelling in cleanup');
    };
  }, [start]);

  return state;
};
