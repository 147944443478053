import * as React from 'react';
import EditorBreadcrumb from './components/EditorBreadcrumb';
import EditorStore from '../../../flux/editor/EditorStore';
import EditorModes from '../../../flux/editor/EditorModes';
import { IUnit } from 'mm-types';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';

export type Props = {
  selectedUnits: IUnit[];
};

const FooterBreadcrumb = (props: Props) => {
  const { selectedUnits } = props;
  const [breadClick, setBreadClick] = React.useState<boolean>();
  const [breakClickTimer, setBreakClickTimer] = React.useState<NodeJS.Timer | null>();

  React.useEffect(() => {
    if (breakClickTimer) {
      return () => clearTimeout(breakClickTimer);
    }
  }, [breakClickTimer]);

  const handleChangeDocNode = (node: HTMLElement | null, isDoubleClick: boolean) => {
    // single click events are fired before the resultant doubleclick is generated
    EditorStore.getEditor().silentReFocus();
    if (isDoubleClick) {
      EditorStore.getEditor().selectEditorNode(node);
      if (breakClickTimer) {
        clearTimeout(breakClickTimer);
        setBreakClickTimer(null);
      }
      setBreadClick(false);
    } else {
      if (!breakClickTimer) {
        let timer = setTimeout(() => {
          if (!breadClick) {
            setBreadClick(true);
            EditorStore.getEditor().setCursorLocation(node, true);
          }
        }, 400);
        setBreakClickTimer(timer);
      }
    }
  };

  if (!EditorStore.doesModeAllow(EditorModes.attributes.breadcrumb)) {
    return null;
  }
  if (selectedUnits.length === 0) {
    return null;
  }

  const dup = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles();
  const definitionId = selectedUnits[selectedUnits.length - 1].definitionId;
  const profile = dup.getUnitProfileByDefinitionId(definitionId);

  return (
    <>
      <div className="selected-du-info" style={{ maxWidth: '35px' }}>
        <span>Selected:</span>
      </div>
      <div className="selected-du-info">
        {selectedUnits.length > 1 ? (
          <span className="selection-count-container">{selectedUnits.length + ' elements'}</span>
        ) : (
          <EditorBreadcrumb
            root={profile ? profile.displayName : ''}
            onSelect={(elm, isDoubleClick) => handleChangeDocNode(elm, isDoubleClick)}
          />
        )}
      </div>
    </>
  );
};

export { FooterBreadcrumb };
