import * as React from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../../flux/editor/EditorStore';
import CommentStore, { CommentStatus, ExternalUserType } from '../../../../../flux/editor/CommentStore';
import UserEventStore, { CommentData, UserEventStoreEvent } from '../../../../../flux/events/UserEventStore';
import CommentForm from '../commentForm/CommentForm';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import Popover from 'material-ui/Popover';
import ConfirmationOverlay from '../../../../general/ConfirmationOverlay';
import FontIcon from 'material-ui/FontIcon';
import { IComment } from 'mm-types';
import { ReplyCount, VisibilityButton } from '../comments/components';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import ActiveUserStore from '../../../../../flux/common/ActiveUserStore';
import useListenToStore from '../../../../hooks/useListenToStore';
import { CommentReducer, CommentState } from './reducers';

let _overlayActive: boolean;

export type Props = {
  mergePanel?: boolean;
  filterSinceDateTime?: number | null;
  commentsUnreadFilter?: 'read' | 'unread' | 'all';
  comment: IComment;
  isProjectAdmin?: boolean;
  isDeletable?: boolean;
  isWriteable: boolean;
  className?: string;
  isSelected: boolean | null;
  isHighlighted: boolean;
  onRequestRefresh: () => void;
  onHandleCommentStatusUpdated: (comment: IComment) => void;
  onRetrieveComments: () => void;
  onSelected: (comment: IComment) => void;
  currentCommentFilter?: CommentStatus;
  currentUserIdsFilter?: string[];
  onAttachmentClick?: (uid: string) => void;
  currentExternalAuthFilter?: ExternalUserType;
};

const Comment = (props: Props) => {
  const [state, dispatch] = React.useReducer(CommentReducer, getInitialState());

  function getInitialState(override?: Partial<CommentState>): CommentState {
    const initial: CommentState = {
      confirmOverlay: {
        comment: null,
        onConfirm: undefined,
        onCancel: undefined,
        text: 'Do you want to delete this comment?',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Cancel'
      },
      showPermissionModal: false,
      isThreadVisible: false,
      isEditing: null
    };
    return { ...initial, ...override };
  }

  useListenToStore({ store: UserEventStore, eventListener: onUserEvent });

  React.useEffect(() => {
    _overlayActive = false;
  }, []);

  React.useEffect(() => {
    if (state.confirmOverlay?.comment) {
      _overlayActive = true;
    }
    return () => {};
  }, [state.confirmOverlay?.comment]);

  React.useEffect(() => {
    if (!props.isSelected) {
      dispatch({ type: 'setIsThreadVisible', payload: false });
    }
    return () => {};
  }, [props.isSelected]);

  function onUserEvent(e: UserEventStoreEvent) {
    if (e && e.type === 'COMMENT') {
      if (props.comment.uid === (e.data as CommentData).comment.threadUid) {
        // a reply has arrived for this on screen comment, so force an update
        props.onRequestRefresh();
      }
    }
  }

  function onNoPermissionFileClick(e) {
    if (e.target) {
      const currentTarget = e.currentTarget;
      const dataUid = e.target.dataset.attachmentid;
      const hasNoPermission = e.target.className.includes('no-permission');

      if (hasNoPermission) {
        dispatch({ type: 'setShowPermissionModalWithAnchor', payload: { showPermissionModal: true, anchorElPermission: currentTarget } });
      } else if (dataUid) {
        if (props.onAttachmentClick) {
          props.onAttachmentClick(dataUid);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  function renderTextWithLinks(comment: IComment) {
    let alteredText = $('<div/>').text(comment.text).html(); // must encode ourselves
    comment.mentions.users.forEach((user) => {
      alteredText = alteredText.replace('@' + user.uid, "<span class='mention'>" + user.displayName + '</span>');
    });

    // Split by blank spaces and keep items with tilda symbol
    let attachmentUids: string[] = alteredText.split(' ').filter((uid) => uid.includes('~'));

    // Remove tilda symbol from uids so it can be compared to our attachments media array
    attachmentUids = attachmentUids.map((uid) => uid.replace('~', ''));

    const errorIcon = '<i class="icon-alert_cannot_view_doc"></i>';

    if (comment.attachments) {
      const attachments = comment.attachments;
      let attachment;
      let attachmentExists;

      attachmentUids.forEach((attachmentUid) => {
        const aUid = $.trim(attachmentUid);
        attachment = attachments.media.find((media) => media.uid === aUid);
        attachment ? (attachmentExists = true) : (attachmentExists = false);

        const isUid = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/.test(aUid);
        if (isUid) {
          alteredText = alteredText.replace(new RegExp(`~${aUid}`), (uid) => {
            return `<span
                        class='file-highlight ${attachmentExists && attachment.status === 'DELETED' ? 'striked ' : ''}${
              !attachmentExists ? 'no-permission' : ''
            }'
                        data-attachmentid='${aUid}'>${!attachmentExists ? errorIcon : ''}${
              attachmentExists ? attachment.filename : 'Restricted File'
            }
                    </span>`;
          });
        }
        // if not UID
        else {
          return `<span class='file-highlight'>${aUid}</span>`;
        }
      });
    }
    return { __html: alteredText };
  }

  function isResolved() {
    return props.comment.status === 'RESOLVED';
  }

  function canEdit() {
    return props.comment.allowCommentChange ? true : false;
  }

  function canDelete() {
    if (props.isDeletable) {
      if (props.comment.allowCommentChange) {
        return true;
      } else if (!props.comment.allowCommentChange && props.isProjectAdmin) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function canAccessOptions() {
    if (props.comment.allowCommentChange) {
      return true;
    } else if (canDelete()) {
      return true;
    } else {
      return false;
    }
  }

  function toggleThread() {
    if (props.isSelected) {
      dispatch({ type: 'setIsThreadVisible', payload: !state.isThreadVisible });

      if (state.isEditing) {
        dispatch({ type: 'setIsEditing', payload: null });
      }
    }
  }

  function handleCommentSelect(comment: IComment) {
    if (!_overlayActive) {
      if (props.onSelected) {
        props.onSelected(comment);
      }
      if (props.comment.commenter.uid !== ActiveUserStore.getUser()?.uid) {
        if (comment.unread) {
          props.onHandleCommentStatusUpdated(comment);
        }
      }
    }
  }

  function handleMenu(action: string, comment?: IComment) {
    comment = comment ? comment : props.comment;

    if (action === 'resolve') {
      const params = EditorStore.getDocParams();
      CommentStore.updateComment(
        params.projectUid!,
        params.indexUid!,
        {
          fromDateTime: props.filterSinceDateTime!,
          unread: props.commentsUnreadFilter!,
          status: props.currentCommentFilter!,
          users: props.currentUserIdsFilter!,
          externalUserType: props.currentExternalAuthFilter!
        },
        { ...comment, status: 'RESOLVED' }
      );
    } else if (action === 'edit') {
      dispatch({ type: 'setIsEditing', payload: comment });
    } else if (action === 'remove') {
      dispatch({
        type: 'setConfirmOverlay',
        payload: {
          ...state.confirmOverlay,
          comment: comment,
          onConfirm: (e) => {
            comment && confirmDelete(e, comment);
          },
          onCancel: hideOverlay
        }
      });
    }
  }

  function adminDeleteComment(e, comment?: IComment) {
    e.preventDefault();
    e.stopPropagation();
    comment = comment ? comment : props.comment;
    dispatch({
      type: 'setConfirmOverlay',
      payload: {
        ...state.confirmOverlay,
        comment: comment,
        onConfirm: (e) => {
          comment && confirmDelete(e, comment);
        },
        onCancel: hideOverlay
      }
    });
  }

  function handleEdit() {
    dispatch({ type: 'setIsEditing', payload: null });
  }

  function handleCancelEdit() {
    dispatch({ type: 'setIsEditing', payload: null });
  }

  function confirmDelete(e, comment: IComment) {
    const params = EditorStore.getDocParams();
    CommentStore.removeComment(
      params.projectUid!,
      params.indexUid!,
      {
        fromDateTime: props.filterSinceDateTime!,
        unread: props.commentsUnreadFilter!,
        status: props.currentCommentFilter!,
        users: props.currentUserIdsFilter!,
        externalUserType: props.currentExternalAuthFilter!
      },
      { ...comment, status: 'DELETED' },
      comment!.threadUid
    );

    hideOverlay(e);
  }

  function hideOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    _overlayActive = false;
    dispatch({ type: 'setConfirmOverlay', payload: { ...state.confirmOverlay, comment: null } });
  }

  function handleRequestClose() {
    dispatch({ type: 'setShowPermissionModal', payload: false });
  }

  function renderOptionsMenu(canEdit: boolean, canDelete: boolean, isResolved: boolean) {
    if (canAccessOptions() && ProjectStore.canWriteComment() && !props?.mergePanel) {
      return (
        <DropDownIcon
          icon="more_vert"
          className="comment-dropdown-menu"
          containerClassName="comment-dropdown-menu-container-inner"
          onSelect={(action) => handleMenu(action)}
        >
          <DropDownOption show={canEdit} value="edit">
            Edit
          </DropDownOption>
          <DropDownOption show={canDelete} value="remove" className="comment-delete-menu-option">
            Delete
          </DropDownOption>
          <DropDownOption show={!isResolved && canEdit} value="resolve">
            Resolve
          </DropDownOption>
        </DropDownIcon>
      );
    } else {
      return null;
    }
  }

  function renderRepliesOptionsMenu(threadComment: IComment) {
    if (props.isWriteable && threadComment.allowCommentChange && ProjectStore.canWriteComment() && !props?.mergePanel) {
      return (
        <div className="comment-inline-menu">
          <i
            className="material-icons"
            onClick={() => {
              handleMenu('remove', threadComment);
            }}
          >
            delete
          </i>
          <i
            className="material-icons"
            onClick={() => {
              handleMenu('edit', threadComment);
            }}
          >
            edit
          </i>
        </div>
      );
    } else {
      return null;
    }
  }

  const isEditingComment = state.isEditing && props.comment.uid === state.isEditing.uid && canEdit;
  const canWriteComment = ProjectStore.canWriteComment();
  const isInternal = props.comment.type === 'INTERNAL';

  return (
    <li
      key={props.comment.uid}
      className={
        (props.isSelected ? 'selected' : '') +
        ' ' +
        props.className +
        ' ' +
        (props.isHighlighted ? 'highlighted' : '') +
        ' ' +
        (props.comment.unread ? 'unread' : '')
      }
    >
      {state.confirmOverlay.comment && state.confirmOverlay.comment.uid === props.comment.uid ? (
        <ConfirmationOverlay {...state.confirmOverlay} />
      ) : undefined}

      <div onClick={() => handleCommentSelect(props.comment)} className={props.comment.unread ? 'unread' : 'read'}>
        {isResolved() ? (
          <div className="resolved-icon">
            <div className="resolved-icon-inner">
              <span className="material-icons">done</span>
            </div>
          </div>
        ) : undefined}

        <div className="comment-author comment-section">
          <img className="comment-avatar" src={props.comment.commenter.avatarUrl} />
          <div className={`comment-info ${props.comment.unread ? 'comment-unread' : ''}`}>
            <h1>{props.comment.commenter.displayName}</h1>
            <span className="datetime">{props.comment.modifiedFormatAgo}</span>
          </div>
          {canWriteComment &&
          ActiveUserStore.isAdmin() &&
          props.comment.commenter.uid !== ActiveUserStore.getUser()?.uid &&
          !props?.mergePanel ? (
            <span className="adminDelete">
              <FontIcon
                onClick={(e) => {
                  adminDeleteComment(e, props.comment);
                }}
                className="material-icons"
              >
                delete
              </FontIcon>
            </span>
          ) : undefined}
          {props.comment.commenter.uid !== ActiveUserStore.getUser()?.uid && !props?.mergePanel ? (
            <div className="notif-item notif-item-done">
              <span
                className="notif-status-toggle"
                title={props.comment.unread ? 'Set to READ' : 'Set to UNREAD'}
                onClick={() => {
                  if (!props.comment.unread) {
                    props.onHandleCommentStatusUpdated(props.comment);
                  }
                }}
              />
            </div>
          ) : (
            renderOptionsMenu(canEdit(), canDelete(), isResolved())
          )}
        </div>
        <div className="comment-tag comment-section" />
        {isEditingComment && (
          <CommentForm
            className="edit-comment"
            filterSinceDateTime={props.filterSinceDateTime}
            commentsUnreadFilter={props.commentsUnreadFilter}
            currentCommentFilter={props.currentCommentFilter}
            currentUserIdsFilter={props.currentUserIdsFilter}
            currentExternalAuthFilter={props.currentExternalAuthFilter}
            unitUid={props.comment.unitUid}
            closeReplies={() => toggleThread()}
            onCreated={() => handleEdit()}
            onCancel={() => handleCancelEdit()}
            comment={props.comment}
          />
        )}
        {!(state.isEditing && props.comment.uid === state.isEditing.uid) ? (
          <div
            className="comment-msg comment-section"
            onClick={(e) => onNoPermissionFileClick(e)}
            dangerouslySetInnerHTML={renderTextWithLinks(props.comment)}
          />
        ) : undefined}

        <Popover
          open={state.showPermissionModal}
          className={'permission-popover'}
          anchorEl={state.anchorElPermission}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          style={{ overflow: 'hidden' }}
          onRequestClose={() => handleRequestClose()}
        >
          <div>
            <div id="permission-popover" className="permission-modal">
              <h1>Visibility is restricted.</h1>
              <span>Contact the System Administrator for permission to access this file.</span>
              <p>
                <button onClick={() => handleRequestClose()}>OK</button>
              </p>
            </div>
          </div>
        </Popover>
        {!isEditingComment && (
          <div className={`comment-thread-actions comment-section ${state.isEditing ? 'edit' : ''}`}>
            <ReplyCount onClick={() => toggleThread()} isThreadVisible={state.isThreadVisible} comment={props.comment} />
            {isInternal && !state.isThreadVisible && <VisibilityButton isPublic={false} disabled={true} />}
          </div>
        )}
      </div>
      {props.isSelected && state.isThreadVisible ? (
        <ul className="comments-thread-list">
          {props.comment.replies.map((threadComment) => {
            return (
              <li
                key={threadComment.uid}
                className={threadComment.unread ? 'unread' : 'read'}
                onClick={() => {
                  if (threadComment.commenter.uid !== ActiveUserStore.getUser()?.uid && threadComment.unread) {
                    props.onHandleCommentStatusUpdated(threadComment);
                  }
                }}
              >
                {state.confirmOverlay.comment && state.confirmOverlay.comment?.uid === threadComment.uid ? (
                  <ConfirmationOverlay {...state.confirmOverlay} />
                ) : undefined}

                <div className="comment-author comment-section">
                  <img className="comment-avatar" src={threadComment.commenter.avatarUrl} />
                  <div className={`comment-info ${threadComment.unread ? 'comment-unread' : ''}`}>
                    <h1>{threadComment.commenter.displayName}</h1>
                    <span className="datetime">{threadComment.modifiedFormatAgo}</span>
                  </div>
                  {canWriteComment &&
                  ActiveUserStore.isAdmin() &&
                  threadComment.commenter.uid !== ActiveUserStore.getUser()?.uid &&
                  !props?.mergePanel ? (
                    <span className="adminDelete">
                      <FontIcon
                        onClick={(e) => {
                          adminDeleteComment(e, threadComment);
                        }}
                        className="material-icons"
                      >
                        delete
                      </FontIcon>
                    </span>
                  ) : undefined}
                  {threadComment.commenter.uid !== ActiveUserStore.getUser()?.uid && !props?.mergePanel ? (
                    <div className="notif-item notif-item-done">
                      <span
                        className="notif-status-toggle"
                        title={threadComment.unread ? 'Set to READ' : 'Set to UNREAD'}
                        onClick={() => (!threadComment.unread ? props.onHandleCommentStatusUpdated(threadComment) : null)}
                      />
                    </div>
                  ) : (
                    renderRepliesOptionsMenu(threadComment)
                  )}
                </div>

                {state.isEditing && threadComment.uid === state.isEditing.uid && threadComment.allowCommentChange && !props?.mergePanel && (
                  <CommentForm
                    filterSinceDateTime={props.filterSinceDateTime}
                    commentsUnreadFilter={props.commentsUnreadFilter}
                    currentCommentFilter={props.currentCommentFilter}
                    currentUserIdsFilter={props.currentUserIdsFilter}
                    currentExternalAuthFilter={props.currentExternalAuthFilter}
                    closeReplies={() => toggleThread()}
                    className="edit-comment"
                    unitUid={threadComment.unitUid}
                    threadUid={threadComment.uid}
                    onCreated={() => handleEdit()}
                    onCancel={() => handleCancelEdit()}
                    comment={threadComment}
                  />
                )}
                {!(state.isEditing && threadComment.uid === state.isEditing.uid) ? (
                  <div
                    className="comment-msg comment-section"
                    onClick={(e) => onNoPermissionFileClick(e)}
                    dangerouslySetInnerHTML={renderTextWithLinks(threadComment)}
                  />
                ) : undefined}

                <Popover
                  open={state.showPermissionModal}
                  anchorEl={state.anchorElPermission}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                  onRequestClose={handleRequestClose}
                />
              </li>
            );
          })}

          {props.isWriteable && !props?.mergePanel && (
            <CommentForm
              className="blaa"
              filterSinceDateTime={props.filterSinceDateTime}
              commentsUnreadFilter={props.commentsUnreadFilter}
              currentCommentFilter={props.currentCommentFilter}
              currentUserIdsFilter={props.currentUserIdsFilter}
              currentExternalAuthFilter={props.currentExternalAuthFilter}
              closeReplies={() => toggleThread()}
              unitUid={props.comment.unitUid}
              threadUid={props.comment.uid}
            />
          )}
        </ul>
      ) : undefined}
    </li>
  );
};

export default Comment;
