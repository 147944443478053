import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import { hideSystemSnackbarMessage } from './thunks';
import { ISnackbarMessage } from 'mm-types';
import { AppState } from '../../../appStore';
import Avatar from 'material-ui/Avatar';

export function SystemSnackbar() {
  const snackbar: ISnackbarMessage | null = useSelector((state: AppState) => state.snackbar.snackbar);
  const dispatch = useDispatch();

  const hideMessage = (reason: string) => {
    dispatch(hideSystemSnackbarMessage());
  };

  if (snackbar?.user) {
    const stringMessage = snackbar.message;
    snackbar.message = (
      <div className="avatar-snackbar">
        <Avatar src={snackbar.user.avatarUrl} size={30} style={{ marginTop: 8, marginRight: 12 }} />
        <div className="avatar-snackbar-message">
          <span>{snackbar.user.displayName}</span>
          {stringMessage}
        </div>
      </div>
    );
  }

  return snackbar ? <Snackbar {...snackbar} onRequestClose={hideMessage} data-qa="snackbar" /> : null;
}
