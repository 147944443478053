import { mm, Cancelled } from '../../../clients/base-clients';
import axios, { CancelTokenSource, AxiosResponse } from 'axios';

let exportFeedbackReportSource: CancelTokenSource | null = null;
let exportReadConfirmReportSource: CancelTokenSource | null = null;
let exportQuizReportSource: CancelTokenSource | null = null;

export async function exportFeedbackReport(distributionGroupUid: string): Promise<AxiosResponse<void> | Cancelled> {
  if (exportFeedbackReportSource) {
    exportFeedbackReportSource.cancel();
  }

  exportFeedbackReportSource = axios.CancelToken.source();

  try {
    return await mm.post<void>(
      `/kpi/reports/feedbacks`,
      { distributionGroupUid: distributionGroupUid },
      {
        cancelToken: exportFeedbackReportSource.token
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function exportReadConfirmReport(distributionGroupUid: string, allManuals: boolean): Promise<AxiosResponse<void> | Cancelled> {
  if (exportReadConfirmReportSource) {
    exportReadConfirmReportSource.cancel();
  }

  exportReadConfirmReportSource = axios.CancelToken.source();

  try {
    return await mm.post<void>(
      `/kpi/reports/read-confirms`,
      { distributionGroupUid: distributionGroupUid, allManuals: allManuals },
      {
        cancelToken: exportReadConfirmReportSource.token
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function exportQuizReport(distributionGroupUid: string): Promise<AxiosResponse<void> | Cancelled> {
  if (exportQuizReportSource) {
    exportQuizReportSource.cancel();
  }

  exportQuizReportSource = axios.CancelToken.source();

  try {
    return await mm.post<void>(
      `/kpi/reports/quiz`,
      { distributionGroupUid: distributionGroupUid },
      {
        cancelToken: exportQuizReportSource.token
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}
