import * as _ from 'lodash';
import { ActionType } from '../../sidetabs/sub/editComponent/controls/Alignment';
import { ScaleInfo } from '../../sidetabs/sub/editComponent/components/ImageEditProps';
import { ATTRIBUTES, linkHelper, LinkHelperData } from './tinyLinkHelper';
import ProjectDefinitionStore, {
  ProjectDefinitionStore as ProjectDefinitionStoreType
} from '../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../flux/editor/EditorStore';
import { UnitUtils } from '../units/UnitUtils';
import { CustomEditor } from './EditorInstanceManager';
import * as TinyHelpers from './TinyFacadeHelpers';
import { getClosestFigureNode, isCursorAtTheEndOfGivenNode, replaceTemplateDataNidsWithNewOnes } from './TinyFacadeHelpers';
import { dom, EditorManager } from 'tinymce';
import { InsertAction } from '../../menus/insert/content/ContentMenuContainer';
import {
  Color,
  Hotspot,
  IDataAttribute,
  IDefinition,
  IDocUnitProfile,
  IEditingNestedChangeDOM,
  IEditorStoreEvent,
  IElementDefinition,
  IElementProfile,
  IPageBreakSettingsData,
  IUnitDetails,
  TinyAction,
  TinyNodeChangeEvent
} from 'mm-types';
import { ZERO_LENGTH_WORD_JOINER } from './key_listeners/keyBehaviourUtils';
import { UnitTypes } from '../units/UnitTypes';
import FriendlyDOM from './FriendlyDOM';

import { Border, BorderStyle, InnerBorder } from '../../sidetabs/sub/editComponent/controls/TableBorder';
import { TinyFacadeExecCommand } from './TinyFacadeExecCommand';
import { Dom } from './DomUtil';
import { UNIT_CLASS as PARA_UNIT_CLASS } from '../units/unit/paragraph';
import { DomAssertions } from './DomAssertionsUtil';
import { DataAttribute } from '../../sidetabs/sub/editComponent/components/TableEditProps/TableEditProps';
import { TableUtils } from '../../../../utils';
import hasElements = DomAssertions.hasElements;
import isNodeName = DomAssertions.isNodeName;
import createElement = Dom.createElement;
import isTextNode = DomAssertions.isTextNode;
import removeElement = Dom.removeElement;

declare const tinymce: EditorManager;
const _UNIT_FOCUSCLASS = 'document-unit-focus'; // unit level
const _EDITOR_FOCUSCLASS = 'editor-unit-focus'; // page level
export const _EDITOR_PAGECLASS = 'editing-page';

type TableMeta = {
  elem: Element;
  rowIndex: number;
  colIndex: number;
  neighbours?: {
    left?: TableMeta;
    right?: TableMeta;
    top?: TableMeta;
    bottom?: TableMeta;
  };
};

type TableCaption = { enabled: boolean; value: string | null; node: Element | null };
const _SIDES = ['top', 'bottom', 'left', 'right'];

export const _JUSTIFY_VALUES = {
  JustifyLeft: 'left-align',
  JustifyCenter: 'center-align',
  JustifyRight: 'right-align',
  JustifyFull: 'full-align'
};

export const _BORDER_VALUES = {
  BorderNone: 'border-none',
  BorderThin: 'border-thin',
  BorderMedium: 'border-medium',
  BorderThick: 'border-thick'
};

export const _BORDER_STYLE_VALUES = {
  BorderStyleSolid: 'border-style-solid',
  BorderStyleDashed: 'border-style-dashed'
};

/**
 * Various helper methods to do get interact with the tiny this.editor.
 */

export default class TinyFacade {
  private editor: CustomEditor;
  private editorStore: typeof EditorStore;
  private projectDefinitionStore: ProjectDefinitionStoreType;
  private tinyFacadeExecCommand: TinyFacadeExecCommand;

  constructor(
    editor: CustomEditor,
    editorStore: typeof EditorStore,
    projectDefinitionStore: ProjectDefinitionStoreType,
    unitKeyBehaviors: typeof import('./key_listeners/UnitKeyBehaviors')
  ) {
    this.editor = editor;
    this.editorStore = editorStore;
    this.projectDefinitionStore = projectDefinitionStore;
    this.tinyFacadeExecCommand = new TinyFacadeExecCommand(editor, editorStore, unitKeyBehaviors);
  }

  _collectOnlyIfBorderCommonToAll(selection, styleName) {
    const side = styleName.substr(7, styleName.length - 7);

    const parseStyle = (style: string): BorderStyle | string => {
      const styleParts = style.split(' ');
      if (styleParts.length === 1) {
        return { width: '', style: styleParts[0], color: null };
      } else if (styleParts.length === 2) {
        return { width: styleParts[0], style: styleParts[1], color: null };
      } else if (styleParts.length === 3) {
        // handle 'border color = transparent' in a special way, as there is no color set
        if (styleParts[2] === 'transparent') {
          return { width: styleParts[0], style: styleParts[1], color: null };
        } else {
          return style;
        }
      } else {
        // No point in parsing there's more than 2 elements in the style. Just return whole style.
        return style;
      }
    };

    const styles: (BorderStyle | string)[] = _.map(selection, (itemWithMeta: any) => {
      let setStyle = (this.editor.dom as any).getStyle(itemWithMeta.elem, styleName, false);

      let colorId = itemWithMeta.elem.getAttribute(`data-border-color-${side}-day`);

      if (!setStyle || setStyle === 'none' || setStyle === '0px none') {
        // Border style not found on the selected cell, check neighbour.
        if (styleName === 'border-top' && itemWithMeta.neighbours.top) {
          setStyle = (this.editor.dom as any).getStyle(itemWithMeta.neighbours.top.elem, 'border-bottom');
          colorId = itemWithMeta.neighbours.top.elem.getAttribute(`data-border-color-bottom-day`);
        } else if (styleName === 'border-right' && itemWithMeta.neighbours.right) {
          setStyle = (this.editor.dom as any).getStyle(itemWithMeta.neighbours.right.elem, 'border-left');
          colorId = itemWithMeta.neighbours.right.elem.getAttribute(`data-border-color-left-day`);
        } else if (styleName === 'border-bottom' && itemWithMeta.neighbours.bottom) {
          setStyle = (this.editor.dom as any).getStyle(itemWithMeta.neighbours.bottom.elem, 'border-top');
          colorId = itemWithMeta.neighbours.bottom.elem.getAttribute(`data-border-color-top-day`);
        } else if (styleName === 'border-left' && itemWithMeta.neighbours.left) {
          setStyle = (this.editor.dom as any).getStyle(itemWithMeta.neighbours.left.elem, 'border-right');
          colorId = itemWithMeta.neighbours.left.elem.getAttribute(`data-border-color-right-day`);
        }
      }
      const borderStyle: BorderStyle | string = parseStyle(setStyle);
      // Color data attribute was found and style isn't a string so we were able to parse it

      if (typeof borderStyle !== 'string') {
        // make sure to default to black if there's no color data attribute. That's the default in css.
        if (!colorId) {
          colorId = 'Black';
        }
        const color = this.projectDefinitionStore.getColorById(colorId);
        return { ...borderStyle, color: color };
      } else {
        return borderStyle;
      }
    });

    // Stringify for easier check of uniqueness
    const stylesStringified: string[] = styles.map((style) => {
      return JSON.stringify(style);
    });

    if (_.uniq(stylesStringified).length === 1) {
      // all selected cells (or their neighbours) have the same style (apart from none)
      return styles[0];
    } else {
      return '';
    }
  }

  collectDataAttributes(selection?: Element[] | null[]) {
    if (selection) {
      let attributes: DataAttribute[] = [];
      selection.map((elm) => {
        if (elm instanceof Element) {
          Array.from(elm.attributes).map((attribute) => {
            if (attribute.nodeName.startsWith('data')) {
              attributes.push({ id: attribute.nodeName, val: attribute.value });
            }
          });
        }
      });
      return _.uniqWith(attributes, (i, j) => {
        return i.id === j.id && i.val === j.val;
      });
    }
    return [];
  }

  _collectDataAttributeOnlyIfCommonToAll(selection: Element[] | Node[], attributeName): string {
    const attributeValues = _.map(selection, (el: any) => {
      if (el) {
        return el.getAttribute(attributeName);
      }
    });

    if (_.uniq(attributeValues).length === 1) {
      // all selected cells have the same attribute value
      return attributeValues[0];
    } else {
      return '';
    }
  }

  _collectOnlyIfCommonToAll(selection: Element[] | Node[], styleName): string {
    const styles = _.map(selection, (el: any) => {
      if (el) {
        if (
          (['width', 'height'].indexOf(styleName) === -1 && el.nodeName !== 'TD') ||
          ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'].indexOf(styleName) === -1
        ) {
          return (this.editor as any).dom.getStyle(el, styleName);
        } else {
          const setStyle = (this.editor as any).dom.getStyle(el, styleName);
          if (setStyle.lastIndexOf('%') === -1) {
            const computedStyle = this.editor.dom.getStyle(el, styleName, true);
            if (el.nodeName === 'TD') {
              return setStyle || computedStyle; // for TD use setStyle before computedStyle as TinyMce returns outdated computed data
            }
            return setStyle === computedStyle ? setStyle : computedStyle;
          } else {
            // computed style is returned in px regardless of actual set value, don't compare
            return setStyle;
          }
        }
      }
    });
    if (_.uniq(styles).length === 1) {
      // all selected cells have the same style
      return styles[0];
    } else {
      return '';
    }
  }

  setContentEditable(editable) {
    if (tinymce && tinymce.activeEditor) {
      (tinymce.activeEditor as CustomEditor).bodyElement?.setAttribute('contentEditable', editable);
    }
  }

  _collectOnlyIfRotateClassCommonToAllChildren(selection: Element[]) {
    const classes = ['arc-rotate-left', 'arc-rotate-right'];

    const styles: (0 | 90 | 270)[][] = selection.map((el: Element) => {
      let elClasses: (false | 270 | 90)[] = classes.map((elClass) => {
        const rotated = (this.editor.selection as any).dom.select('.' + elClass, el);
        if ('length' in rotated && rotated.length >= 1) {
          return elClass === 'arc-rotate-left' ? 270 : 90;
        } else {
          return false;
        }
      });

      // return the present "rotation" angle, none mean not rotated so return [0]
      const compactedElClasses: (90 | 270)[] = _.compact(elClasses);
      return (compactedElClasses.length === 1 ? compactedElClasses : [0]) as (0 | 90 | 270)[];
    });

    // combine into array of present rotations across selection
    const uniqFlatStyles = _.uniq(_.flatten(styles));

    if (_.uniq(uniqFlatStyles).length === 1) {
      // all selected cells have the same class
      return uniqFlatStyles[0].toString();
    } else {
      return '';
    }
  }

  insertSpecialChar(specialChar) {
    this.editor.execCommand('mceInsertContent', false, specialChar && specialChar.html ? specialChar.html : null);
  }

  insertInlineMeasureUnit(measureUnit) {
    // FIXME: this should be driven by appropiate indexDefinition element templateHtml
    const spanMeasureValue = `<span data-unit='${measureUnit}' data-element-family='measure' data-show-unit='true' class='arc-inline'>${ZERO_LENGTH_WORD_JOINER}</span>`;
    const spanMeasureUnit = `<span class='arc-inline-enum' data-element-family='measureUnit'>${measureUnit}</span>`;
    const measureUnitHtml = spanMeasureValue + spanMeasureUnit + ZERO_LENGTH_WORD_JOINER;

    this.editor.execCommand('mceInsertContent', false, measureUnitHtml);

    const insertedInlineEnumSpan: any = tinymce.activeEditor.selection.getRng(true).startContainer.previousSibling;
    tinymce.activeEditor.selection.setCursorLocation(insertedInlineEnumSpan, 0);
  }

  insertAdditionalElement(elementDefinition: IElementDefinition) {
    // create HTMLElement from passed templateHtml string
    const template = document.createElement('template');
    template.innerHTML = elementDefinition.templateHtml.trim();
    let templateElement = template.content.firstElementChild;

    // insert selected content into templateElement
    const contentToStyle = tinymce.activeEditor.selection.getContent();
    if (templateElement) {
      templateElement = replaceTemplateDataNidsWithNewOnes($(templateElement as HTMLElement))[0];
      templateElement.innerHTML = ZERO_LENGTH_WORD_JOINER + contentToStyle + ZERO_LENGTH_WORD_JOINER;
      const contentToInsert = ZERO_LENGTH_WORD_JOINER + templateElement.outerHTML + ZERO_LENGTH_WORD_JOINER;

      tinymce.activeEditor.selection.setContent(contentToInsert);

      tinymce.activeEditor.selection.collapse(true);
      const insertedNid = templateElement.getAttribute('data-nid');
      const insertedElm = tinymce.activeEditor.selection.getNode().querySelector(`[data-nid="${insertedNid}"]`) as HTMLElement;
      this.setCursorLocation(insertedElm, undefined, 1);
    }
  }

  execCommand(action: TinyAction, data?: string | null, insertPoint?: HTMLElement | null, insertPosition?: InsertAction) {
    this.tinyFacadeExecCommand.exec(action, data, insertPoint, insertPosition);
  }

  updatePageBreakSettings(element: HTMLElement, settings: IPageBreakSettingsData) {
    this.editor.undoManager.transact(() => {
      if (settings.avoidBreakInside) {
        element.setAttribute('data-avoid-break-inside', settings.avoidBreakInside);
      } else {
        element.removeAttribute('data-avoid-break-inside');
      }

      if (settings.breakAfter) {
        element.setAttribute('data-page-break-after', settings.breakAfter);
      } else {
        element.removeAttribute('data-page-break-after');
      }

      if (settings.breakBefore) {
        element.setAttribute('data-page-break-before', settings.breakBefore);
      } else {
        element.removeAttribute('data-page-break-before');
      }
    });
  }

  convertNestedList(listNode, listType) {
    this.editor.undoManager.transact(() => {
      const selectedNode = this.editor.selection.getNode();
      const domUtils = this.editor.dom;

      domUtils.setAttrib(selectedNode, 'arc-elem-selected', 'true');

      const switchListType = (originalListNode, newListType) => {
        const profile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getProfileFromElSubType(newListType);
        const newListNode = UnitUtils.createListElementContainer(profile, '', null);

        // replace old list with the new list and keep children
        domUtils.replace(newListNode!, originalListNode, true);

        return newListNode;
      };

      // listType can be UL or OL
      const newListNode = switchListType(listNode, listType);

      // re-select original list element
      const toReSelect = $(newListNode as HTMLElement)
        .find('[arc-elem-selected=true]')
        .get()[0];
      domUtils.setAttrib(toReSelect, 'arc-elem-selected', '');

      this.editor.selection.setCursorLocation(toReSelect as any);
      this.nodeChanged();
    });
  }

  nodeChanged() {
    this.editor.nodeChanged();
  }

  // certain nodes can't get a cursor focus unless they have an element: this creates a temporary fake element inside a node called 'arconics-bogus'
  enhanceNode(node: HTMLElement) {
    let brNode: HTMLBRElement | null = null!;
    if (node) {
      brNode = document.createElement('br');
      if (node.nodeName === 'LI') {
        const $node = $(node);

        if (this.isImmediateULChild($node.contents())) {
          $(brNode).prependTo($node);
        } else {
          brNode = this.prependBogusBRToNode($node, brNode);
        }
      } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
        let $node = $(node);
        $node = $node.find('li:first-child .element-content > *:not(br)');
        brNode = this.prependBogusBRToNode($node, brNode);
      } else if (node.nodeName === 'DIV') {
        // note: only if forced_root_block: '' (don't do on complex units!)
        let $node = $(node);
        if ($node.hasClass('arc-list-item-content')) {
          brNode = this.prependBogusBRToNode($node, brNode);
        } else if ($node.hasClass('element-container')) {
          // general element fallback
          $node = $node.find('.element-content');
          brNode = this.prependBogusBRToNode($node, brNode);
        } else {
          brNode = null;
        }
      } else if (node.nodeName === 'P') {
        node.innerHTML = node.innerText.length === 0 ? ZERO_LENGTH_WORD_JOINER : node.innerHTML + ZERO_LENGTH_WORD_JOINER;
        const $node = $(node);
        brNode = this.prependBogusBRToNode(
          $node.hasClass('arc-paragraph') ? $node : $(node && node.parentNode ? node.parentNode : node),
          brNode
        );
      } else if (node.nodeName === 'SPAN') {
        node.innerHTML = node.innerText.length === 0 ? ZERO_LENGTH_WORD_JOINER : node.innerHTML + ZERO_LENGTH_WORD_JOINER;
        const $node = $(node);
        brNode = this.appendBogusBRToNode($node, brNode);
      } else {
        const $node = $(node);
        brNode = this.prependBogusBRToNode($node, brNode);
      }
    }
    return brNode;
  }

  isImmediateULChild($nodeChildren): boolean {
    return $nodeChildren.length === 1 && ($nodeChildren[0].nodeName === 'UL' || $nodeChildren[0].nodeName === 'OL');
  }

  bogusBRToNode($node, brNode, append = false): HTMLBRElement {
    const $existingBr = $node.find('>br.arconics-bogus');
    if ($existingBr.length === 0) {
      brNode.setAttribute('class', 'arconics-bogus');
      if (append) {
        $(brNode).appendTo($node);
      } else {
        $(brNode).prependTo($node);
      }
    } else {
      brNode = $existingBr[0];
    }
    return brNode;
  }

  prependBogusBRToNode($node, brNode): HTMLBRElement {
    return this.bogusBRToNode($node, brNode, false);
  }

  appendBogusBRToNode($node, brNode): HTMLBRElement {
    return this.bogusBRToNode($node, brNode, true);
  }

  insertBreakLine(attrs?: Map<string, string | number>) {
    const parentNode = tinymce.activeEditor.selection.getNode();
    const firstChild = parentNode.firstChild as Element;
    const lastChild = parentNode.lastChild as Element;
    const newBr = createElement('br', attrs);

    if (isNodeName(parentNode, 'BR')) {
      tinymce.activeEditor.dom.insertAfter(newBr, parentNode);
    } else if (firstChild && firstChild === lastChild && isNodeName(firstChild, 'BR')) {
      tinymce.activeEditor.dom.insertAfter(newBr, firstChild);
    } else {
      if (isTextNode(lastChild) && isCursorAtTheEndOfGivenNode(parentNode)) {
        tinymce.activeEditor.dom.insertAfter(newBr, lastChild);
      }
      tinymce.activeEditor.selection.setContent(newBr.outerHTML);
    }
  }

  getLinkData(): LinkHelperData {
    return linkHelper(this.editor).data;
  }

  getLinkTypeIfAny(): boolean | string {
    return linkHelper(this.editor).getLinkTypeIfAny();
  }
  insertLink(linkAttributes) {
    linkHelper(this.editor).addLink(linkAttributes);
  }

  updateHotspotLinks(hotspots: Hotspot[]) {
    const dom = this.editor.dom;

    // convert all hotspots into existingHotspotNodes
    const hotspotNodes: (Node | null)[] = hotspots.map((hotspot) => {
      const linkAttrs = {};

      ATTRIBUTES.forEach(function (attr) {
        linkAttrs[attr] = hotspot.linkData[attr];
      });

      const hotspotStyle = `top: ${hotspot.top}%; left: ${hotspot.left}%; width: ${hotspot.width}%; height: ${hotspot.height}%;`;
      const hotspotHTML: string = dom.createHTML(
        'div',
        {
          class: 'arc-hot-spot',
          style: hotspotStyle,
          'data-nid': hotspot.nid
        },
        dom.createHTML('a', linkAttrs)
      );
      const div = document.createElement('div');
      div.innerHTML = hotspotHTML;
      return div.firstChild;
    });

    const figure: HTMLElement = getClosestFigureNode(this.editor);
    const imgOverlay: Node = figure.querySelector('div.arc-img-overlay')!;

    // remove existing hotspot nodes
    const existingHotspotNodes: NodeList | null = figure.querySelectorAll('div.arc-img-overlay .arc-hot-spot');
    existingHotspotNodes &&
      existingHotspotNodes.forEach((node: Node) => {
        imgOverlay.removeChild(node);
      });

    // append hotspot existingHotspotNodes to the image overlay
    hotspotNodes.forEach((hotspotNode) => {
      imgOverlay.appendChild(hotspotNode!);
    });
  }

  // TODO only being used by table for now to overcome nid issue => don;t reuse out side of this unless refactored, as this is the wrong route to force a save
  triggerSaveEvent() {
    this.editor.fire('ARC_SAVE', { blur: false });
  }

  triggerEscapeEvent() {
    this.editor.fire('ARC_ESCAPE');
  }

  _isParentTable() {
    if (this.isRootTable()) {
      return true;
    } else {
      // is the selected table not an ancestor of another table
      const currentTable = $(this.getSelectedTable());
      const parents = currentTable.parentsUntil(this.editor.getElement().parentElement!);
      return !_.some(parents, (parent) => parent.nodeName === 'TABLE');
    }
  }

  toggleCaption(type: 'figure' | 'table') {
    let captionElm: HTMLCollectionOf<HTMLElement | HTMLTableCaptionElement> | null = null;
    let tableNode: HTMLElement | null = null;
    let figureNode: HTMLElement | null = null;

    if (type === 'figure') {
      figureNode = getClosestFigureNode(this.editor);
      if (figureNode) {
        captionElm = figureNode.getElementsByTagName('figcaption');
      }
    } else {
      tableNode = this.getSelectedTable({ reselect: false })! as HTMLElement;
      if (tableNode) {
        captionElm = tableNode.getElementsByTagName('caption');
      }
    }
    // insert caption element
    if (captionElm && captionElm.length === 0) {
      let caption: Element | null = null;

      if (type === 'table') {
        // only permit "root" table captions, either table docunit root or parent nested tables
        if (this._isParentTable()) {
          caption = this.editor.dom.create(
            'caption',
            {
              class: 'arc-table-caption arc-editor-not-editable',
              'data-mce-contenteditable': false
            },
            'UNTITLED'
          );

          const theadNode = tableNode!.getElementsByTagName('thead')[0];
          const tbodyNode = tableNode!.getElementsByTagName('tbody')[0];
          if (theadNode && tableNode) {
            tableNode.insertBefore(caption, theadNode);
          } else if (tbodyNode && tableNode) {
            tableNode.insertBefore(caption, tbodyNode);
          }
        }
      } else {
        caption = this.editor.dom.create('figcaption', { class: 'arc-figure-caption' }, 'UNTITLED');
        figureNode!.appendChild(caption);
      }
    }
    // remove caption element
    else {
      this.editor.dom.remove(captionElm as any);
      if (type === 'figure') {
        this.editor.selection.select(figureNode!);
      } else {
        // refocus on a cell
        this.editor.selection.select(TableUtils.getSelectedCells()[0]);
        this.clearSelection({ refocus: true });
      }
    }
  }

  getCaption(type: string, selectedTable?: HTMLTableElement): TableCaption {
    const caption: TableCaption = {
      enabled: false,
      value: null,
      node: null
    };
    const typeCaptionMap = {
      table: (element) => {
        return element.getElementsByTagName('caption');
      },
      figure: (element) => {
        return element.getElementsByTagName('figcaption');
      }
    };

    try {
      if (type === 'table' && !this._isParentTable()) {
        // not parent table, returning empty
        return { enabled: false, value: null, node: null };
      }

      // Make sure that editor's last range & selection.lastFocusBookmark is null
      // otherwise this.editor.selection.lastFocusBookmark will have wrong data
      // and FocusManager & Selection will throw exception about lastFocusBookmark endOffset
      this.editor.lastRng = null as any;
      (this.editor.selection as any).lastFocusBookmark = null;

      let selectedNode: HTMLElement = this.editor.selection.getNode() as HTMLElement;
      let closest: Node | null = null;

      // probably focused outside the editor (this is a tinymce edge case bug), force focus back in
      // or if selected node is placeholder bogus caret
      if (
        (selectedNode && selectedNode.nodeName === 'DIV' && selectedNode.className.indexOf('arc-unit') === -1) ||
        (selectedNode.nodeName === 'P' && selectedNode.getAttributeNames().indexOf('data-mce-bogus'))
      ) {
        if (type === 'table') {
          selectedNode = this.getSelectedTable({ reselect: false }, selectedTable) as HTMLElement;
        } else {
          selectedNode = this.getStart().querySelector('figcaption') || (this.editor.getElement() as HTMLElement);
        }
      }

      if (type.toUpperCase() === selectedNode.tagName.toUpperCase()) {
        // selectedNode is already a table or figure
        closest = selectedNode;
      } else if (selectedNode.classList.contains('mce-content-body') || selectedNode.getAttribute('data-mce-bogus') !== null) {
        // if selection is an injected tiny bogus element, it will always be a sibling for fake cursor in readonly figure - currently this only occurs for root graphic elements
        closest = this.editor.getElement().querySelectorAll(type)[0];
      } else {
        closest = $(this.editor.selection.getNode()).closest(type)[0];
      }

      if (closest) {
        const captionEl = typeCaptionMap[type](closest);
        caption.enabled = captionEl.length > 0;
        caption.value = caption.enabled ? captionEl[0].textContent : null;
        caption.node = captionEl;
      }
      return caption;
    } catch (e) {
      // being crazy cautious here!
      return { enabled: false, value: null, node: null };
    }
  }

  setCaption(type: string, caption: string | null, passedNode: null | HTMLElement = null, selectedTable?: HTMLTableElement) {
    const { node } = this.getCaption(type, selectedTable) as any;
    if (node || passedNode) {
      this.editor.dom.setHTML(
        (passedNode ? passedNode : node) as Element,
        $('<div/>')
          .text(caption ? caption : '')
          .html()
      );
    }
  }

  setHTML(id: string, html: string) {
    this.editor.undoManager.transact(() => {
      this.editor.dom.setHTML($(`#${id}`)[0], html);
      this.nodeChanged();
    });
  }

  focusCaption(type) {
    if (type === 'table') {
      const captionChk = this.editor.dom.getParent(this.getStart(), 'table');
      if (!captionChk) {
        const container = $(this.editor.selection.getNode()).closest(type);
        this.editor.selection.select(container.length && (container[0].firstChild as any), false);
        this.clearSelection();
      }
    } else {
      if (this.editor.selection.getNode().nodeName !== 'figcaption') {
        this.editor.selection.select(this.getStart().querySelector('figcaption')!, false);
      }
    }
  }

  format(isIncrease: boolean, formatType: string, unitProfile: IDocUnitProfile, focusedNestedNode: HTMLElement) {
    let $unitElement: any = null;

    // for nested item: ensure to use appropriate container
    if (focusedNestedNode) {
      $unitElement = $(focusedNestedNode);
      $unitElement = $unitElement[0].nodeName === 'LI' ? $unitElement.parent() : $unitElement;
    }

    // for root units: format classes are applied to .arc-unit container (so we can handle ordinals), else on the core unit html for nested
    else {
      $unitElement = $(tinymce.activeEditor.getElement()).closest('.document-unit-inner').find('>.arc-unit');
    }

    const applyFormatting = ($el, classRegex) => {
      const formatter = this.projectDefinitionStore
        .getFormattingValues()
        .find((format) => format.propertyName.toLowerCase() === formatType)!;
      const positionClassPrefix = formatType[0];
      const arcMLDefaults = unitProfile.arcMLDefaults![focusedNestedNode ? 'nested' : 'root'];

      const formatClassName = ($el.attr('class') || '')
        .split(' ')
        .filter((className) => className.match(classRegex) && className[0] === positionClassPrefix);

      let currentFormatClass = positionClassPrefix + '0' + arcMLDefaults[formatType];
      let currentFormatValue = arcMLDefaults[formatType];
      if (formatClassName.length > 0) {
        currentFormatClass = formatClassName[0];
        currentFormatValue = parseInt(currentFormatClass.split(positionClassPrefix)[1], 10);
      }

      currentFormatValue += isIncrease ? +1 : -1;

      if (currentFormatValue >= formatter.minValue && currentFormatValue <= formatter.maxValue) {
        const paddedNumber = currentFormatValue <= 9 ? '0' + currentFormatValue : currentFormatValue;
        $el.removeClass(currentFormatClass).addClass(positionClassPrefix + paddedNumber);
      }
    };

    // currently ordinals are root only
    if (formatType === 'ordinal') {
      applyFormatting($unitElement.find('.arc-tocable-ordinal,.arc-ordinal').eq(0), /o[0-9][0-9]/);
    } else {
      applyFormatting($unitElement, /[t|r|b|l][0-9][0-9]/);
    }
  }

  getListType(listNode: Element): { type: string; isRoot: boolean } {
    if (listNode) {
      return {
        type: listNode.getAttribute('data-subtype')!,
        isRoot: (listNode.parentNode && listNode.parentNode.nodeName === 'DIV') || !listNode.parentNode ? true : false
      };
    } else {
      // Make sure we're returning something default when there's no listNode
      return {
        type: 'unordered',
        isRoot: true
      };
    }
  }

  // Find and index in the selection for a give style. E.g. for 'border-top' find an index of a cell which is at the top.
  _findIndexToFilter(selectionWithMeta, styleName) {
    const result = {
      indexValue: -1,
      indexAttr: 'colIndex'
    };

    // Find min/max row/column
    if (styleName === 'border-top') {
      result.indexValue = selectionWithMeta.reduce(function (minRowIndex, cellWithMeta) {
        return cellWithMeta.rowIndex < minRowIndex ? cellWithMeta.rowIndex : minRowIndex;
      }, 99999);
      result.indexAttr = 'rowIndex';
    } else if (styleName === 'border-right') {
      result.indexValue = selectionWithMeta.reduce(function (maxColIndex, cellWithMeta) {
        return cellWithMeta.colIndex > maxColIndex ? cellWithMeta.colIndex : maxColIndex;
      }, -1);
      result.indexAttr = 'colIndex';
    } else if (styleName === 'border-bottom') {
      result.indexValue = selectionWithMeta.reduce(function (maxRowIndex, cellWithMeta) {
        return cellWithMeta.rowIndex > maxRowIndex ? cellWithMeta.rowIndex : maxRowIndex;
      }, -1);
      result.indexAttr = 'rowIndex';
    } else if (styleName === 'border-left') {
      result.indexValue = selectionWithMeta.reduce(function (minColIndex, cellWithMeta) {
        return cellWithMeta.colIndex < minColIndex ? cellWithMeta.colIndex : minColIndex;
      }, 99999);
      result.indexAttr = 'colIndex';
    }

    return result;
  }

  getTableEdgeSelectionWithMetaData(selectionWithMeta, styleName) {
    const indexToFilter = this._findIndexToFilter(selectionWithMeta, styleName);

    // find only those selected cell from the left/right top/bottom edge
    return selectionWithMeta.filter((cellWithMeta) => {
      return cellWithMeta[indexToFilter.indexAttr] === indexToFilter.indexValue;
    });
  }

  getTableNonEdgeSelectionWithMetaData(selectionWithMeta, styleName) {
    const indexToFilter = this._findIndexToFilter(selectionWithMeta, styleName);

    // find only those selected cell from the left/right top/bottom edge
    return selectionWithMeta.filter((cellWithMeta) => {
      return cellWithMeta[indexToFilter.indexAttr] !== indexToFilter.indexValue;
    });
  }

  getDeSpannedTableSelectionWithMetaData(table, selection) {
    const tableBaseWithMeta = this.deSpanTableWithMetaData(table);

    return tableBaseWithMeta.reduce((tableResult, row) => {
      const selectedRowCells = row.reduce((rowResult: any[], cellWithMeta) => {
        const foundCell = selection.find((selectedCell) => {
          return cellWithMeta.elem.isSameNode(selectedCell);
        });

        if (foundCell) {
          rowResult.push(cellWithMeta);
        }

        return rowResult;
      }, []);

      return tableResult.concat(selectedRowCells);
    }, []);
  }

  deSpanTableWithMetaData(table) {
    const tableBase = this.deSpanTable(table);

    // Build tableBaseWithMeta and fill any gaps
    const tableBaseWithMeta: TableMeta[][] = [tableBase.length] as any;
    for (let rowIndex = 0; rowIndex < tableBase.length; rowIndex++) {
      const row = tableBase[rowIndex];
      tableBaseWithMeta[rowIndex] = [row.length];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        let elem = tableBase[rowIndex][colIndex];

        // Make sure there's a cell elem. If there isn't then copy first non null cell elem from the left (colspan situation)
        if (!elem) {
          // try to find first non empty elem towards left
          for (let i = colIndex - 1; i >= 0; i--) {
            if (tableBase[rowIndex][i]) {
              elem = tableBase[rowIndex][i];
              break;
            }
          }
        }

        tableBaseWithMeta[rowIndex][colIndex] = {
          elem: elem,
          rowIndex: rowIndex,
          colIndex: colIndex
        };
      }
    }

    // Update Table Meta Data representation with neighbours for each cell
    for (let rowIndex = 0; rowIndex < tableBase.length; rowIndex++) {
      const row = tableBaseWithMeta[rowIndex];
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const itemWithMeta = tableBaseWithMeta[rowIndex][colIndex];
        itemWithMeta.neighbours = {};
        itemWithMeta.neighbours.left = tableBaseWithMeta[rowIndex][colIndex - 1];
        itemWithMeta.neighbours.right = tableBaseWithMeta[rowIndex][colIndex + 1];

        if (tableBaseWithMeta[rowIndex - 1]) {
          itemWithMeta.neighbours.top = tableBaseWithMeta[rowIndex - 1][colIndex];
        }

        if (tableBaseWithMeta[rowIndex + 1]) {
          itemWithMeta.neighbours.bottom = tableBaseWithMeta[rowIndex + 1][colIndex];
        }
      }
    }

    return tableBaseWithMeta;
  }

  deSpanTable(table) {
    const rows = table.querySelectorAll('* > tr');
    rows.forEach((row) => {
      if (row.childElementCount === 0) {
        rows[row.sectionRowIndex].remove();
      }
    });

    const rowsLength = rows.length;
    let colsLength = 1;

    // know the row dimension, init with row size
    let tableBase = new Array(rowsLength);
    tableBase = _.map(tableBase, function (row) {
      return [];
    });

    // each row
    for (let i = 0; i < rowsLength; i++) {
      const cols = rows[i].children;
      colsLength = cols.length;

      // each column
      for (let j = 0; j < colsLength; j++) {
        const cell = cols[j];
        const colspan = parseInt(cell.getAttribute('colspan')) || 1;
        const rowspan = parseInt(cell.getAttribute('rowspan')) || 1;
        const segment: any[] = [];
        let actualRowLen = 0;
        for (let curspan = 0; curspan < colspan; curspan++) {
          segment.push(curspan === 0 ? cell : null);
        }

        // for each rowspan add segment with prior pseudo cell slots as calculated
        for (let l = 0; l < rowspan; l++) {
          if (l === 0) {
            // check if already padded
            actualRowLen = tableBase[i].length;
            if (j === actualRowLen) {
              tableBase[i] = tableBase[i].concat(segment);
            } else {
              // find if padded from prior rowspan
              const padStart = tableBase[i].indexOf(false);
              const start = padStart !== -1 ? padStart : actualRowLen;
              // add segment
              for (let m = 0, segLen = segment.length; m < segLen; m++) {
                tableBase[i][start + m] = segment[m];
              }
            }
          } else {
            try {
              // pad for next row iteration
              const nextRowLen = tableBase[i + l].length;
              const padding = actualRowLen - nextRowLen;
              if (padding > 0) {
                let pad = new Array(padding);
                pad = _.map(pad, function (padItem) {
                  return false;
                });
                tableBase[i + l] = tableBase[i + l].concat(pad);
              }
              // add segment
              tableBase[i + l] = tableBase[i + l].concat(segment);
            } catch (e) {
              // Ignoring non existent row inferred by rowspan value
            }
          }
        }
      }
    }
    return tableBase;
  }

  getActionCells(tableBase: any, selection) {
    const rowsLength = tableBase.length;
    const colsLength = tableBase[0].length;

    return _.map(selection, (cell: any) => {
      const columns: number[] = [];
      const actionCells: any[] = [];

      // determine actionable columns
      looping: {
        for (let i = 0; i < rowsLength; i++) {
          for (let j = 0; j < colsLength; j++) {
            if (cell.isSameNode(tableBase[i][j])) {
              if (columns.indexOf(j) === -1) {
                columns.push(j);
              }
              break looping;
            }
          }
        }
      }
      // assemble actionable cells
      for (let i = 0, resColumns = columns.length; i < resColumns; i++) {
        for (let j = 0; j < rowsLength; j++) {
          if (tableBase[j][columns[i]]) {
            actionCells.push(tableBase[j][columns[i]]);
          }
        }
      }
      return actionCells;
    });
  }

  getActionSelection(style) {
    const selection = TableUtils.getSelectedCells();
    let actionSelection: any[] = [];

    if (style.hasOwnProperty('width')) {
      // column(s)
      const table = $(selection[0]).closest('TABLE')[0];
      // TODO: table needs to have been saved before this operation, current we trigger a save on table insert
      let tableNid = '';
      if (table && (tableNid = table.getAttribute('data-nid')!)) {
        if (table.querySelectorAll('table[data-nid="' + tableNid + '"] > * > tr > td[colspan]').length > 0) {
          const tableBase = this.deSpanTable(table);
          actionSelection = this.getActionCells(tableBase, selection)!;
        } else {
          // use straight css selector
          actionSelection = selection.map((cell: HTMLTableCellElement) => {
            const columnIndex = cell.cellIndex + 1;
            return table.querySelectorAll('table[data-nid="' + tableNid + '"] > * > tr > td:nth-child(' + columnIndex + ')');
          });

          actionSelection = _.union(...actionSelection);
        }
      } else {
        // for the moment worst case just apply to current cell
        actionSelection = selection;
      }
    } else if (style.hasOwnProperty('height')) {
      // row(s)
      actionSelection = selection.map((cell) => {
        return cell ? $(cell).closest('TR')[0].children : [];
      });
    } else {
      actionSelection = selection;
    }

    return actionSelection;
  }

  canInsertInlineInTable() {
    // if table instead of cell has focus and we have nowhere to insert new element ( i.e we're not in a nested table, the body element and selection are the same data-nid)  force append of element
    if (
      this.editor.bodyElement?.nodeName === 'TABLE' &&
      (TableUtils.getSelectedCells()[0] === null ||
        (this.editor.selection.getNode().nodeName === 'TABLE' &&
          this.editor.selection.getNode().getAttribute('data-nid') === this.editor.bodyElement?.getAttribute('data-nid')))
    ) {
      return false;
    } else {
      return true;
    }
  }

  getSelectedFigure() {
    const figure = this.editor.selection.getNode().closest('figure');
    return figure || this.editor.getElement();
  }

  getSelectedTable(options = { reselect: true }, selectedTable?: HTMLTableElement) {
    const reselect = options && options.reselect ? options.reselect : false;

    let table: Node | null = null;
    const selectedCells = TableUtils.getSelectedCells();
    if (selectedCells[0]) {
      table = selectedCells[0].closest('table');
    } else {
      // caption focus case
      if (this.editor.selection) {
        if (selectedTable) {
          table = selectedTable;
        } else {
          table = this.editor.dom.getParent(this.getStart(), 'table');
        }

        if (!table && reselect) {
          // sometimes selection is in odd places, return to some sanity
          this.editor.selection.select(this.editor.getElement(), false);
        }
      }
    }
    // last resort is getElement, likely wrong for nested items
    return table ? table : this.editor.getElement();
  }

  isRootTable() {
    const currentTable = this.getSelectedTable({ reselect: false });
    if (currentTable) {
      return currentTable.isSameNode(this.editor.getElement());
    } else {
      return false;
    }
  }

  areInnerBordersAvailable() {
    const result: InnerBorder = { isHorizontal: false, isVertical: false };

    ['horizontal', 'vertical'].forEach((side) => {
      let borderStyle = 'border-' + side;

      const selection = TableUtils.getSelectedCells();

      if (selection && selection.length > 0 && selection[0] !== null) {
        const table = $(selection[0]).closest('TABLE')[0];
        if (table) {
          const selectionWithMeta = this.getDeSpannedTableSelectionWithMetaData(table, selection);
          let elementsWithMeta: any[] = [];
          // Only if despanned elements are not actually the same element, otherwise there's no horizontal or vertical borders
          if (!this.areTheSame(selectionWithMeta)) {
            if (side === 'horizontal') {
              borderStyle = 'border-top';
              elementsWithMeta = this.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, borderStyle);
              if (elementsWithMeta.length > 0) {
                result.isHorizontal = true;
              }
            } else if (side === 'vertical') {
              borderStyle = 'border-right';
              elementsWithMeta = this.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, borderStyle);
              if (elementsWithMeta.length > 0) {
                result.isVertical = true;
              }
            }
          }
        }
      }
    });

    return result;
  }

  getCommonBorderStyles() {
    const borders: Border = _SIDES.concat(['horizontal', 'vertical']).reduce((acc, side: keyof Border) => {
      return _.merge(acc, { [side]: null });
    }, {}) as Border;

    _SIDES.concat(['horizontal', 'vertical']).forEach((side) => {
      let borderStyle = 'border-' + side;

      const selection = TableUtils.getSelectedCells();

      if (selection && selection.length > 0 && selection[0] !== null) {
        const table = $(selection[0]).closest('TABLE')[0];
        if (table) {
          const selectionWithMeta = this.getDeSpannedTableSelectionWithMetaData(table, selection);
          let elementsWithMeta: any[] = [];

          if (side === 'horizontal') {
            borderStyle = 'border-top';
            if (this.areTheSame(selectionWithMeta)) {
              elementsWithMeta = [];
            } else {
              elementsWithMeta = this.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, borderStyle);
            }
          } else if (side === 'vertical') {
            borderStyle = 'border-right';
            if (this.areTheSame(selectionWithMeta)) {
              elementsWithMeta = [];
            } else {
              elementsWithMeta = this.getTableNonEdgeSelectionWithMetaData(selectionWithMeta, borderStyle);
            }
          } else {
            elementsWithMeta = this.getTableEdgeSelectionWithMetaData(selectionWithMeta, borderStyle);
          }

          borders[side] = this._collectOnlyIfBorderCommonToAll(elementsWithMeta, borderStyle);
        }
      }
    });

    return borders;
  }

  areTheSame(selectionWithMeta) {
    if (!selectionWithMeta || selectionWithMeta.length === 0) {
      return false;
    }
    const elem = selectionWithMeta[0].elem;
    for (let i = 1; i < selectionWithMeta.length; i++) {
      if (elem !== selectionWithMeta[i].elem) {
        return false;
      }
    }
    return true;
  }

  getCommonColor(selection: Element[], colorSelector: String): Color | null | string {
    const prefix = colorSelector === 'background' ? 'bg' : '';

    // first lookup data attribute
    const colorAttributeValue = this._collectDataAttributeOnlyIfCommonToAll(selection, `data-${prefix}color-day`);
    if (!!colorAttributeValue) {
      return this.projectDefinitionStore.getColorById(colorAttributeValue);
    } else {
      // if not found then lookup style
      return this._collectOnlyIfCommonToAll(selection, colorSelector);
    }
  }

  getCommonDimension(selection: Element[] | Node[], dimension) {
    return this._collectOnlyIfCommonToAll(selection, dimension);
  }

  getCommonFontSize(selection: Element[]) {
    return this._collectOnlyIfCommonToAll(selection, 'font-size');
  }

  getCommonPaddingStyles(selection: Element[]) {
    const padding: string[] = [];
    _SIDES.forEach((side) => {
      padding[side] = this._collectOnlyIfCommonToAll(selection, 'padding-' + side);
    });
    return padding;
  }

  getCommonRotation(selection) {
    return this._collectOnlyIfRotateClassCommonToAllChildren(selection);
  }

  getCommonVerticalAlignStyles(selection) {
    return this._collectOnlyIfCommonToAll(selection, 'vertical-align');
  }

  getCommonHorizontalAlignStyles(selection) {
    return this._collectOnlyIfCommonToAll(selection, 'text-align');
  }

  getAlignStyle(selection) {
    const float = this.editor.dom.getStyle(selection, 'float', false);
    const center = this.editor.dom.getStyle(selection, 'margin-left', false);
    if (float) {
      return float;
    } else if (center) {
      return 'centre';
    } else {
      return '';
    }
  }

  getAlignment() {
    if (!tinymce.activeEditor) {
      return 'JustifyLeft';
    }

    let alignment = '';
    const selectedRootUnit = this.editorStore.getSelectedUnit()!;
    const focusedInnerUnit = this.editorStore.getLastFocusedNestedUnit()!;

    // PARAGRAPH (root only)
    if (selectedRootUnit.type === 'paragraph') {
      switch ((tinymce.activeEditor.getElement() as HTMLElement).style.textAlign) {
        case 'right':
          alignment = 'JustifyRight';
          break;
        case 'justify':
          alignment = 'JustifyFull';
          break;
        case 'center':
          alignment = 'JustifyCenter';
          break;
        default:
          alignment = 'JustifyLeft';
          break;
      }
    }

    // GRAPHIC (including nested)
    else if (
      selectedRootUnit.type === 'graphic' ||
      (focusedInnerUnit && focusedInnerUnit.focused && focusedInnerUnit.focused.type === 'graphic')
    ) {
      if (this.editor.selection) {
        const figureNode = getClosestFigureNode(this.editor);
        if (figureNode) {
          const classes = $(figureNode).attr('class')!.split(' ');
          for (let i = 0, l = classes.length; i < l; i++) {
            const key = _.findKey(_JUSTIFY_VALUES, (o) => {
              return o === classes[i];
            });
            if (key) {
              alignment = key;
              break;
            }
          }

          if (alignment === '') {
            alignment = 'JustifyLeft';
          }
        }
      }
    } else if (this.editor.selection) {
      const node = this.editor.selection.getNode() as HTMLElement;
      if (node) {
        switch (node.style.textAlign) {
          case 'right':
            alignment = 'JustifyRight';
            break;
          case 'justify':
            alignment = 'JustifyFull';
            break;
          case 'center':
            alignment = 'JustifyCenter';
            break;
          default:
            alignment = 'JustifyLeft';
            break;
        }
      }
    }

    if (alignment === '') {
      alignment = 'JustifyLeft';
    }

    return alignment;
  }

  clearSelection(options: { clearAll?: boolean; refocus?: boolean } = { clearAll: false, refocus: false }) {
    const clearAll = options && options.clearAll ? options.clearAll : false;
    const refocus = options && options.refocus ? options.refocus : false;
    const selected: HTMLElement[] = this.editor.dom.select('td[data-mce-selected],th[data-mce-selected]');

    if (selected.length === 1 || clearAll) {
      selected.forEach((sel) => {
        sel.removeAttribute('data-mce-selected');
      });
    }
    if (selected && Array.isArray(selected) && selected[0] && refocus) {
      selected[0].focus();
    }
  }

  getTableSelectionStartElements(): {
    dom: null | dom.DOMUtils;
    table: null | HTMLTableElement;
    tableHead: null | Element;
    tableBody: null | Element;
    row: null | Element;
    cell: null | Node;
  } {
    if (this.editor.selection === null) {
      return { dom: null, table: null, tableHead: null, tableBody: null, row: null, cell: null };
    }
    const tableElm = this.editor.dom.getParent(this.getStart(), 'table');
    const cellElm = this.editor.dom.getParent(this.getStart(), 'td,th');
    const tableHeadElm = this.editor.dom.getParent(this.getStart(), 'thead');
    const tableBodyElm = this.editor.dom.getParent(this.getStart(), 'tbody');

    let rowElm: Element | null = null;
    if (this.getStart().tagName === 'TR') {
      if (this.getStart().getAttribute('contenteditable') === 'false') {
        rowElm = this.getStart();
      }
    }

    return {
      dom: this.editor.dom,
      table: tableElm as HTMLTableElement,
      tableHead: tableHeadElm as Element,
      tableBody: tableBodyElm as Element,
      row: rowElm,
      cell: cellElm
    };
  }

  getTableCellsSelectedCount() {
    const { table: tableElm, cell: cellElm } = this.getTableSelectionStartElements();

    let cellsSelectedCount = 0;

    if (tableElm) {
      _.each((tableElm as HTMLTableElement).rows, (row) => {
        _.each(row.cells, (cell) => {
          if (cell.hasAttribute('data-mce-selected') || cell === cellElm) {
            cellsSelectedCount++;
          }
        });
      });
    }

    return cellsSelectedCount;
  }

  // given an editor DOM node, will return best guess details as to its document unit
  getUnitDetailsFromNode(element: HTMLElement, getElementDetails = false): IUnitDetails | null {
    let unitDetails: IUnitDetails | null = null;

    if (element) {
      // Given a dom element, it may be tiny-specific element, bearing no relation to any defined element or unit.
      // Right off the bat skip over those.
      let $cursorEl = element.nodeType === 1 && element.nodeName === 'BR' ? $(element).parent() : $(element); // overcome tinymce bogus tags
      $cursorEl = $cursorEl.attr('data-mce-bogus') ? $cursorEl.parent() : $cursorEl; // prevent access to tinymce hidden elements for table resizing etc
      unitDetails = TinyHelpers.htmlElementToDocEditProfile($cursorEl[0], this.projectDefinitionStore, getElementDetails);
    }

    return unitDetails;
  }

  scaleFigure(
    scale: { type: 'width' | 'maxWidth' | 'height'; value: number; unit: 'px' | '%' },
    resetWidth: boolean,
    resetHeight: boolean,
    naturalDimensions: { width: number; height: number } | null
  ) {
    const figureNode = getClosestFigureNode(this.editor);

    if (figureNode) {
      let width: number | null = naturalDimensions ? naturalDimensions.width : 0;
      let height: number | null = naturalDimensions ? naturalDimensions.height : 0;

      const scaleValue = scale.value;

      const $imageOverlay: JQuery<HTMLImageElement> = $(figureNode.querySelector('div.arc-img-overlay')!) as JQuery<HTMLImageElement>;

      // % can only be applied to widths, remove height
      if (scale.unit === '%') {
        $imageOverlay.css(scale.type === 'width' ? 'width' : 'max-width', scaleValue + (scaleValue ? '%' : ''));
        $imageOverlay.css('height', ''); // remove height
        width = scaleValue;
        const newStyle = $imageOverlay.attr('style')!; // preserve other width attrib if present
        $imageOverlay.attr('data-mce-style', newStyle);
        height = null;
      }

      // setting max-width can change effective width of unit if width is already set and scaled using px, re-scale
      if (scale.type === 'maxWidth' || scale.unit === 'px') {
        // pixel lands, scale width and height if one of those changed
        if (scale.type === 'maxWidth') {
          // no scaling of max-width or changes
          $imageOverlay.css({ 'max-width': scaleValue + (scaleValue ? scale.unit : ''), height: '' });
          height = null;
        } else {
          if (scale.type === 'width') {
            height = height! * (scaleValue / width);
            width = scaleValue;
          } else if (scale.type === 'height') {
            width = width * (scaleValue / height!);
            height = scaleValue;
          }

          if (width === 0) {
            width = null;
            height = null;
          }
          if (height === 0) {
            height = null;
          }
          $imageOverlay.css({ width: width ? width + scale.unit : '', height: height ? height + scale.unit : '' });
        }

        const newStyle = $imageOverlay.attr('style')!;
        $imageOverlay.attr('data-mce-style', newStyle);
      }

      let unitStyle: ScaleInfo;
      if (scale.type !== 'maxWidth') {
        unitStyle = { scaleType: scale.type, scaleWidth: width, scaleHeight: height, unit: scale.unit };
        if (resetWidth) {
          $imageOverlay.css('max-width', '');
          const newStyle = $imageOverlay.attr('style')!;
          $imageOverlay.attr('data-mce-style', newStyle);
          Object.assign(unitStyle, { scaleMaxWidth: null, maxUnit: scale.unit === '%' ? 'px' : '%' });
        }
      } else {
        unitStyle = { scaleType: scale.type, scaleMaxWidth: scaleValue, maxUnit: scale.unit };
        if (resetWidth) {
          $imageOverlay.css('width', '');
          const newStyle = $imageOverlay.attr('style')!;
          $imageOverlay.attr('data-mce-style', newStyle);
          Object.assign(unitStyle, { scaleWidth: null, unit: scale.unit === '%' ? 'px' : '%' });
        }
      }

      if (resetHeight) {
        $imageOverlay.css('height', '');
        const newStyle = $imageOverlay.attr('style')!;
        $imageOverlay.attr('data-mce-style', newStyle);
        Object.assign(unitStyle, { scaleHeight: null });
      }

      this.editorStore.triggerUnitStyleChange('imageScale', unitStyle);
    }

    return null;
  }

  getActiveFormats(): string[] {
    if (this.editor && this.editor.selection && !this.editor.selection.getRng(true).collapsed) {
      return this.editor.formatter.matchAll([
        'bold',
        'underline',
        'overline',
        'italic',
        'subscript',
        'superscript',
        'code',
        'uppercase',
        'lowercase',
        'capitalize',
        'forecolor',
        'hilitecolor'
      ]);
    } else {
      return [];
    }
  }

  getColorFromSelfAndParents(
    el: HTMLElement,
    propName: string,
    defaultValue: string,
    getColorAttribute: (el: HTMLElement) => string | null
  ) {
    // Only interested in the inline elements. Stop search for parents if other than inline.
    if (['SPAN', 'STRONG', 'EM'].indexOf(el.nodeName) > -1) {
      const attributeValue = getColorAttribute(el);
      if (!!attributeValue) {
        return attributeValue;
      } else if (!!el.parentElement) {
        return this.getColorFromSelfAndParents(el.parentElement, propName, defaultValue, getColorAttribute);
      }
    }

    return defaultValue;
  }

  getSelectionColor(style: 'color' | 'backgroundColor', isDayMode = true, selection?: HTMLElement) {
    if (selection) {
      const getColorAttribute = (el: HTMLElement): string | null => {
        const attributeName = `data-${style === 'color' ? 'color' : 'bgcolor'}-${isDayMode ? 'day' : 'night'}`;
        return el.getAttribute(attributeName);
      };
      return this.getColorFromSelfAndParents(selection, style, 'rgba(0, 0, 0, 0)', getColorAttribute);
    }
    return '';
  }

  getFigure(): HTMLElement {
    return getClosestFigureNode(this.editor);
  }

  getFigureScale(): ScaleInfo | null {
    const figureNode = getClosestFigureNode(this.editor);

    if (figureNode) {
      const imageOverlay = figureNode.querySelector('div.arc-img-overlay')! as HTMLElement;
      let width: string | null = '';
      let height: string | null = '';
      let maxWidth: string | null = '';
      let unit: ScaleInfo['unit'] = '%';
      let maxUnit: ScaleInfo['maxUnit'] = 'px';

      width = imageOverlay.style.width;
      maxWidth = imageOverlay.style.maxWidth;
      height = imageOverlay.style.height;

      let widthNum = 0;
      let heightNum = 0;
      let maxWidthNum = 0;

      if (width) {
        unit = width.indexOf('px') !== -1 ? 'px' : '%';
        widthNum = parseInt(width.replace('px', '').replace('%', ''), 10);
        maxUnit = unit === 'px' ? '%' : 'px';
      }

      if (maxWidth) {
        maxUnit = maxWidth.indexOf('px') !== -1 ? 'px' : '%';
        maxWidthNum = parseInt(maxWidth.replace('px', '').replace('%', ''), 10);
        unit = maxUnit === 'px' ? '%' : 'px';
      }

      if (height) {
        heightNum = parseInt(height.replace('px', '').replace('%', ''), 10);
      }

      if (imageOverlay) {
        return {
          scaleWidth: widthNum ? widthNum : null,
          scaleMaxWidth: maxWidthNum ? maxWidthNum : null,
          scaleHeight: heightNum ? heightNum : null,
          unit: unit,
          maxUnit: maxUnit
        };
      } else {
        return null;
      }
    }

    return null;
  }

  getJustification(elementType) {
    let justification: ActionType['action'] | null = 'JustifyLeft';

    if (elementType === 'figure') {
      // currently figure only

      const figureNode = getClosestFigureNode(this.editor);

      if (figureNode) {
        _.each(_JUSTIFY_VALUES, (alignClass, value) => {
          if ($(figureNode).hasClass(alignClass)) {
            justification = value as ActionType['action'] | null;
          }
        });
      }
    }

    return justification;
  }

  getBorder(elementType) {
    let borderStyle = 'BorderNone';

    if (elementType === 'figure') {
      // currently figure only

      const figureNode = getClosestFigureNode(this.editor);

      if (figureNode) {
        _.each(_BORDER_VALUES, (borderClass, value) => {
          if ($(figureNode).hasClass(borderClass)) {
            borderStyle = value;
          }
        });
      }
    }

    return borderStyle;
  }

  getBorderStyle(elementType) {
    let borderStyle = 'BorderStyleSolid';

    if (elementType === 'figure') {
      // currently figure only

      const figureNode = getClosestFigureNode(this.editor);

      if (figureNode) {
        _.each(_BORDER_STYLE_VALUES, (borderClass, value) => {
          if ($(figureNode).hasClass(borderClass)) {
            borderStyle = value;
          }
        });
      }
    }

    return borderStyle;
  }

  insertSplitMarker() {
    let splitMarker: Element | null = null;

    if (this.editor.selection) {
      const range = this.editor.selection.getRng(false);
      splitMarker = this.editor.dom.create('span', { class: 'arc-unit-break' }, '');
      range.insertNode(splitMarker);
    }

    return splitMarker;
  }

  removeSplitMarker(splitMarker) {
    if (splitMarker) {
      this.editor.dom.remove(splitMarker);
    }
  }

  setCursorLocation(node: HTMLElement | null, enhanceNode?: boolean, offset = 0) {
    // node is null | undefined: place cursor at tinymce instance root
    node = node == null ? (tinymce.activeEditor.getElement() as HTMLElement) : node;

    // certain nodes can't get a cursor focus unless they have an element: this creates a temporary fake element
    if (enhanceNode) {
      // Use br node only when it's created
      const brNode: HTMLElement | null = this.enhanceNode(node);
      if (brNode) {
        node = brNode;
      }
    }

    tinymce.activeEditor.selection.setCursorLocation(node as any, offset);
  }

  setSelection(node: HTMLElement | null) {
    node = node == null ? (tinymce.activeEditor.getElement() as HTMLElement) : node;
    tinymce.activeEditor.selection.select(node);
  }

  insertUnitInline(e: { inline: boolean; unit?: string; unitType: UnitTypes; insertPoint?: HTMLElement; insertPosition?: InsertAction }) {
    let html = '';
    const dup = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles();
    const elementProfile = dup.getElementProfileByDefinitionId(e.unitType);
    if (e.unit) {
      // passed unit from paste element
      html = e.unit;
    } else if (elementProfile) {
      html = elementProfile.templateHtml; // .replace( /[\u2060]/, '{$caret}' );
    }

    if (html) {
      const editor = tinymce.activeEditor;
      const { focusNode } = editor.selection.getSel();
      let selectedElement: HTMLElement | null = focusNode.parentElement;
      if (
        !(focusNode?.textContent && [...focusNode.textContent].some((char) => char.charCodeAt(0) > 127)) ||
        html.indexOf('arc-refint') < 0
      ) {
        selectedElement = editor.selection ? (editor.selection.getNode() as HTMLElement) : null; // tinymce Element is not the same as HTMLElement...
      } else if (selectedElement) {
        // Setting the range again to avoid invalid range
        editor.selection.select(selectedElement);
        const rng = editor.selection.getRng(false);
        rng.setStart(rng.startContainer, rng.startOffset);
        rng.setEnd(selectedElement, selectedElement.childNodes.length);
        editor.selection.setRng(rng);
      }

      if (selectedElement?.nodeName === 'P' && e.unitType === 'paragraph') {
        e.insertPoint = e.insertPoint ? e.insertPoint : $(selectedElement).parents('.element-container').first()[0];
        e.insertPosition = e.insertPosition ? e.insertPosition : 'insert_after';
      }
      this.execCommand('mceInsertContent', html, e.insertPoint || null, e.insertPosition || null);
      // TODO refactor (table nid currently needed to avoid selecting nested tables)
      // This is also convenient to force recreation of observe nodes on entering edit mode
      if (e.unitType === 'table' || e.unitType === 'Table') {
        this.triggerSaveEvent();
      } else if (selectedElement) {
        this.nodeChanged();
      }
    }
  }

  isEmptyTableCell(element: HTMLElement): boolean {
    return element && element.nodeName === 'TD' && element.innerText.length === 0 && $(element).children().not('br').length === 0;
  }

  getProfileFromSubType(element) {
    const subType = element.getAttribute('data-subType');
    return subType
      ? ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getProfileFromElSubType(subType)
      : ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph');
  }

  addMissingParaToListItem(newBlock: HTMLElement) {
    const p = Dom.createElement('div');
    p.innerHTML = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId('paragraph')?.template ?? '';
    const element = p.querySelector('p');
    if (element) {
      newBlock.append(element);
      this.editor.selection.setCursorLocation(element);
    }
  }

  addWrapperContainer({ newBlock, node }: { newBlock: HTMLElement; node: HTMLElement }) {
    if (isNodeName(newBlock, 'li') && !hasElements(newBlock, 'p.arc-paragraph')) {
      newBlock.querySelectorAll('br[data-mce-bogus]').forEach((el) => {
        removeElement(el);
      });
      this.addMissingParaToListItem(newBlock);
    } else if (isNodeName(newBlock, 'p')) {
      if (!(newBlock as HTMLElement).classList.contains('arc-paragraph')) {
        let node;
        const splitContents = newBlock.innerHTML; // copy split element contents
        const elementContainer: HTMLElement = newBlock.closest('.element-container') || newBlock;
        const elementContainerParent: HTMLElement = elementContainer.parentElement!;

        // In case of an empty TD cell prevent tinymce from inserting extra P
        const parentElementSibling: Element | null = elementContainer.nextElementSibling || elementContainer.previousElementSibling;
        if (parentElementSibling && parentElementSibling.nodeName === 'P') {
          if (!_.isEmpty(parentElementSibling.textContent)) {
            const profile = this.getProfileFromSubType(elementContainer);
            const siblingNode = UnitUtils.createElementContainer(profile, parentElementSibling.innerHTML!);
            if (elementContainer.previousSibling) {
              elementContainerParent!.insertBefore(siblingNode as any, elementContainer);
            } else {
              elementContainerParent!.appendChild(siblingNode as any);
            }
          }
          $(parentElementSibling).remove();
        }
        // If current element-container is directly under list item
        // and this is the last element-container within that list item
        if (elementContainerParent!.nodeName === 'LI' && $(elementContainer).next('.element-container').length === 0) {
          node = document.createElement('LI');
          const beforeNode = elementContainerParent!.nextSibling;
          elementContainerParent!.parentNode!.insertBefore(node, beforeNode); // parents chain used at the beginning: element-container > LI > List (OL/UL)
          UnitUtils.ensureNewListAttributes(node, splitContents);
        } else {
          const profile = this.getProfileFromSubType(elementContainer);
          node = UnitUtils.createElementContainer(profile, splitContents);
          if (node) {
            if (elementContainerParent!.nodeName === 'TD') {
              elementContainerParent!.insertBefore(node, elementContainer.nextSibling);
            } else {
              elementContainerParent!.appendChild(node);
            }
          }
        }
        this.editor.selection.setCursorLocation(node as any); // tinymce expects html.Node, cant find a way to cast to it
        $(newBlock).remove(); // remove P created by tinyMCE
      } else {
        // p lite
        newBlock.removeAttribute('data-nid');
        $(newBlock).addClass(PARA_UNIT_CLASS);
      }
    }
    if (newBlock) {
      newBlock.removeAttribute('data-nid');
    } else if (node && node.nodeName) {
      // sometimes the newcell event raises with an invalid node, ignore
      node.removeAttribute('data-nid');
    }
  }

  setFocused(focused: boolean) {
    // note: why not do below through state you say: performance: editor should "render" only when totally necessary
    this.getSelectedUnitElement().toggleClass(_UNIT_FOCUSCLASS, focused);
    $('.' + _EDITOR_PAGECLASS).toggleClass(_EDITOR_FOCUSCLASS, focused);
  }

  // returns the real DOM container representing the DU element the editor has selected on (or inside)
  getSelectedUnitElement(selectedUnit = this.editorStore.getSelectedUnit()!) {
    return $('.' + _EDITOR_PAGECLASS).find('#_' + selectedUnit.uid);
  }

  /*
   *  Important: always get content thru' this and *not* through editor.getContent() => we enforce filtering and sanitization on the output
   * _getContent gets content from tinyMce instance:
   * 1. sanitizes existing content based on unit type
   * 2. wrap the original parent DOM as defined by backend around it so content is what server expects
   */

  getContent(trustContent?: boolean) {
    const selectedUnit = this.editorStore.getSelectedUnit();
    const duProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
      selectedUnit!.definitionId
    );
    const rawHtmlContent = this.getSelectedUnitElement(selectedUnit!).html();
    const $rawContent = $('<div>' + rawHtmlContent + '</div>');
    const $rawContentEditableSection = $rawContent
      .find(duProfile?.targetEditContainerSelector ? duProfile.targetEditContainerSelector : '.edit-target, .element-content')
      .first();
    if ($rawContentEditableSection.length === 0) {
      return '';
    }
    let rawStyleAttrValues = $rawContentEditableSection.attr('style');

    rawStyleAttrValues = rawStyleAttrValues ? rawStyleAttrValues.replace('position: relative;', '') : '';
    const $rawContentTemplateContainer = $rawContent.find('>.arc-unit');
    const editableSectionRawDataValues = Array.prototype.slice
      .call($rawContentEditableSection[0].attributes)
      .filter((a) => a.name.indexOf('data-') !== -1 && a.name.indexOf('data-mce') === -1);
    const editableSectionRawClassValues = $rawContentEditableSection.attr('class')!;
    let templateContainerRawClassValues: string | null = null;

    // filter allowed class values using whitelist
    const editableSectionRawClassValuesArr = editableSectionRawClassValues.split(' ').filter((className) => {
      let identifyingClasses: RegExpMatchArray | null = null;
      if (duProfile?.identifyingClasses) {
        identifyingClasses = className.match(new RegExp(duProfile.identifyingClasses.join('|')));
      }

      // FIXME blacklist tinymce decorations or agree scheme for edit targets going forward
      return (
        className.match(/arc-.*|edit-target|element-content|collection/) || className.match(/[t|r|b|l][0-9][0-9]/) || identifyingClasses
      );
    });

    // if arc-unit container and instantiated element are different, we need to copy container classes too
    if ($rawContentEditableSection[0] !== $rawContentTemplateContainer[0]) {
      templateContainerRawClassValues = $rawContentTemplateContainer.attr('class')!;
      templateContainerRawClassValues = (templateContainerRawClassValues || ([] as any))
        .split(' ')
        .filter((className) => className.match(/arc-.*/) || className.match(/[t|r|b|l][0-9][0-9]/));
    }

    const templateContainerOrdinalRawClassValues = $rawContentTemplateContainer
      .find('.arc-tocable-ordinal, .arc-ordinal')
      .eq(0)
      .attr('class');

    // persist root Unit styling
    const currentStyleOverride = $rawContent.find('.arc-unit > .element-container > style');

    // apply type specific sanitization
    const unitContent = duProfile?.sanitize?.(this.editor.getContent({ format: 'raw' }), rawHtmlContent, duProfile, trustContent) ?? '';
    const $originalTmplClone = $('<div>' + selectedUnit!.html + '</div>');
    const $originalTmplEditableSectionClone = $originalTmplClone
      .find(duProfile?.targetEditContainerSelector ? duProfile.targetEditContainerSelector : '.edit-target')
      .first();

    const originalHasOverride = $originalTmplClone.find('.arc-unit > .element-container > style');

    if (currentStyleOverride.length) {
      if (originalHasOverride.length) {
        // replace styling
        $originalTmplClone.find('.arc-unit > .element-container > style').html(currentStyleOverride.html());
      } else {
        // add styling
        $originalTmplClone.find('.arc-unit > .element-container').append(currentStyleOverride.prop('outerHTML'));
      }
    } else {
      // override styling has been removed
      if (originalHasOverride.length) {
        originalHasOverride.remove();
      }
    }

    // remove margin presentation styles
    $originalTmplClone.find('.arc-tocable-ordinal, .arc-ordinal').removeAttr('style');

    $originalTmplEditableSectionClone.empty(); // remove its original contents
    if (rawStyleAttrValues?.length) {
      $originalTmplEditableSectionClone.attr('style', rawStyleAttrValues); // add any styles that were added to outer element
    } else {
      $originalTmplEditableSectionClone.removeAttr('style');
    }

    // add any classes / data attrs that were added to instantiated element
    $originalTmplEditableSectionClone.attr('class', editableSectionRawClassValuesArr.join(' '));
    editableSectionRawDataValues.forEach((d) => {
      $originalTmplEditableSectionClone.attr(d.name, d.nodeValue);
    });
    $originalTmplEditableSectionClone.html(unitContent); // inject new contents

    this.copyDataAttributesFromAboveEditor($rawContentEditableSection, $originalTmplEditableSectionClone);

    if (templateContainerRawClassValues) {
      $originalTmplClone.find('>.arc-unit').attr('class', (templateContainerRawClassValues as any).join(' ')); // add any classes that were added to outer element
    }

    if (templateContainerOrdinalRawClassValues) {
      $originalTmplClone.find('.arc-tocable-ordinal, .arc-ordinal').eq(0).attr('class', templateContainerOrdinalRawClassValues);
    }
    return this.cleanDuplicates($originalTmplClone.html());
  }

  copyDataAttributesFromAboveEditor(
    $rawContentEditableSection: JQuery<HTMLElement>,
    $originalTmplEditableSectionClone: JQuery<HTMLElement>
  ) {
    // Gather all data-attributes from the elements above the editor
    let dataAttributesContainer: { [key: string]: { [key: string]: string } } = {};
    _.each($rawContentEditableSection.parents('[data-element-definition-id]'), (el) => {
      const definitionId = el.getAttribute('data-element-definition-id') as string;
      const elementDefinition:
        | IElementProfile
        | undefined = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getElementProfileByDefinitionId(definitionId);
      let dataAttributes: { [key: string]: string } = {};
      if (!!elementDefinition?.dataAttributes) {
        dataAttributesContainer[definitionId] = {};
        elementDefinition.dataAttributes?.forEach((da) => {
          const daValue = el.getAttribute(da.dataV);
          if (!!daValue) {
            dataAttributes[da.dataV] = daValue;
          }
        });

        if (0 < Object.keys(dataAttributes).length) {
          dataAttributesContainer[definitionId] = dataAttributes;
        }
      }
    });

    // Copy over data-attributes from the elements above the editor into the template
    _.each($originalTmplEditableSectionClone.parents('[data-element-definition-id]'), (el) => {
      const definitionId = el.getAttribute('data-element-definition-id') as string;
      const elementDefinition:
        | IElementProfile
        | undefined = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getElementProfileByDefinitionId(definitionId);

      if (!!elementDefinition?.dataAttributes) {
        if (!!dataAttributesContainer[definitionId]) {
          const dataAttributeKeys = Object.keys(dataAttributesContainer[definitionId]);
          dataAttributeKeys.forEach((dataAttributeKey) => {
            el.setAttribute(dataAttributeKey, dataAttributesContainer[definitionId][dataAttributeKey]);
          });

          // remove all the rest of the data attributes
          elementDefinition.dataAttributes?.forEach((dataAttribute) => {
            // If not found in data attribute keys to copy then make sure it's removed
            if (dataAttributeKeys.indexOf(dataAttribute.dataV) < 0) {
              el.removeAttribute(dataAttribute.dataV);
            }
          });
        } else {
          // if no data attributes to be copied then make sure all data attributes are removed
          elementDefinition.dataAttributes?.forEach((da) => {
            el.removeAttribute(da.dataV);
          });
        }
      }
    });
  }

  getEventElementsFromEvent(e: TinyNodeChangeEvent, nestedTree: IUnitDetails[]) {
    let focused: IUnitDetails | null = null;
    let parent: IUnitDetails | null = null;

    // handle select selection change event edgecase
    if (e.command && e.command === 'SelectAll') {
      const unitElement = this.editor.selection.getNode() as HTMLElement;
      focused = parent = this.getUnitDetailsFromNode(unitElement)!;
      focused.targetElement = unitElement;
      parent.targetElement = unitElement;
      return { focused, parent };
    }

    if (nestedTree.length > 1) {
      const { unitElement, targetElement } = nestedTree[nestedTree.length - 1];
      focused = this.getUnitDetailsFromNode(unitElement, true)!;
      if (focused) {
        focused.targetElement = targetElement;
      }
    }
    if (e.element && (nestedTree.length === 0 || !focused)) {
      if ($(e.element).hasClass('arc-unit')) {
        focused = this.getUnitDetailsFromNode($(e.element).children('[data-element-family]')[0])!;
      } else {
        focused = this.getUnitDetailsFromNode($(e.element).closest('[data-element-family]')[0])!;
      }
      if (focused) {
        focused.targetElement = e.element;
      }
    }
    if (!focused) {
      focused = this.getUnitDetailsFromNode($(e.element).closest('[data-unit-family]')[0]);
    }
    if (nestedTree.length > 1) {
      parent = this.getUnitDetailsFromNode(nestedTree[0].unitElement!)!;
      parent.targetElement = nestedTree[0].targetElement;
    } else if (focused) {
      const $unitElement = $(focused.unitElement);
      parent = $unitElement.hasClass('arc-unit')
        ? this.getUnitDetailsFromNode(focused.unitElement)
        : this.getUnitDetailsFromNode($unitElement.parents('[data-element-family], [data-unit-family]')[0]);
      if (parent) {
        parent.targetElement = e.element;
      }
    }

    // ugly edge for if you click on the breadcrumb for the top-most nesting element
    if (!parent && focused) {
      parent = this.getUnitDetailsFromNode(focused.unitElement);
    }
    return { focused, parent };
  }

  getNestedTreeFromEvent({ parents, element }: { parents: HTMLElement[] | Element[] | null; element: HTMLElement | null }): IUnitDetails[] {
    let nestedTree: IUnitDetails[] = [];
    let includeSelf: Element[] = [];
    if (element && (!parents || (parents && parents.length === 0))) {
      includeSelf = (element as HTMLElement).getAttribute('data-unit-family')
        ? [element.querySelector('[data-element-family]') as HTMLElement]
        : [element];
    } else if (!!parents && parents.length !== 0) {
      includeSelf = [...parents, parents[parents.length - 1].parentElement?.closest('[data-element-family]') as Element];
    }

    if (includeSelf && includeSelf.length) {
      nestedTree = includeSelf.reduce(
        (accumulator: IUnitDetails[], element: HTMLElement) => this.getAccumulatedUnitsDetails(accumulator, element),
        []
      );
    }
    return nestedTree.reverse();
  }

  getAccumulatedUnitsDetails(accumulator: IUnitDetails[], element: HTMLElement): IUnitDetails[] {
    // don't build Unit Details for bogus elements
    if (
      element &&
      !element.classList.contains('arconics-bogus') &&
      ((this.editor && this.editor.getBody().isSameNode(element)) || FriendlyDOM.translate(element, this.projectDefinitionStore))
    ) {
      const isNNC = element.closest('.arc-unit')?.getAttribute('data-subtype') === 'ftidx-NonNormalChecklist';
      const familyNode = element.hasAttribute('data-element-definition-id')
        ? element
        : (element.closest(
            `.element-container, .arc-paragraph, ${isNNC ? '' : '[data-element-definition-id],'} .arc-unit `
          ) as HTMLElement);
      const unitDetails: IUnitDetails | null = this.getUnitDetailsFromNode(familyNode);
      accumulator = unitDetails && unitDetails.profile ? [...accumulator, unitDetails] : accumulator;
    }
    return accumulator;
  }

  getNestedDomFromEvent({ parents, element }): IEditingNestedChangeDOM[] {
    const nestedDOM: IEditingNestedChangeDOM[] = [];
    let includeSelf: HTMLElement[] = [];
    if (parents && parents.length === 0) {
      includeSelf = [element];
    } else {
      includeSelf = parents;
    }
    _.each(includeSelf, (element: HTMLElement, index) => {
      const friendlyName = FriendlyDOM.translate(element, this.projectDefinitionStore);

      if (friendlyName) {
        nestedDOM.push({ friendlyName: friendlyName, element: element, index: index } as IEditingNestedChangeDOM);
      }
    });

    return nestedDOM.reverse();
  }

  private cleanDuplicates(rawHtmlContent: string): string {
    rawHtmlContent = rawHtmlContent.replace(/(<strong(.*?)?>)\1(.*)(<\/strong>)<\/strong>/, function (match, $1, $2, $3, $4) {
      return $1 + $3 + $4;
    });
    rawHtmlContent = rawHtmlContent.replace(/(<em(.*?)?>)\1(.*)(<\/em>)<\/em>/, function (match, $1, $2, $3, $4) {
      return $1 + $3 + $4;
    });
    return rawHtmlContent;
  }

  removeRowspanTDHeight(event: IEditorStoreEvent<'unitDomChange'>) {
    // observer triggered event
    if (event.data?.mutations) {
      const node = event.data.node as HTMLTableCellElement;
      if (node.nodeName === 'TD' && node.rowSpan > 1 && node.style.height) {
        node.style.height = '';
      }
    }
  }

  cutTextAfterCursor() {
    const editor = tinymce.activeEditor;
    const rng = editor.selection.getRng(false);
    const endContainer = editor.getBody();

    // select from cursor position to end of content
    rng.setStart(rng.startContainer, rng.startOffset);
    rng.setEnd(endContainer, endContainer.childNodes.length);
    editor.selection.setRng(rng);

    const splitContent = editor.selection.getContent(); // get selected for passing to new p unit

    editor.selection.setContent(''); // clear selected in current

    return splitContent;
  }

  applyStyleFromDataAttribute(dataAttribute: IDataAttribute, element: HTMLElement, value: string, definition?: IDefinition) {
    if (definition?.id === 'Equal' && dataAttribute.id === 'width') {
      this.editor.undoManager.transact(function () {
        const leftElement: HTMLElement | null = element.querySelector('p[data-subtype="leftEql"]');
        leftElement!.style!.width = value + '%';
      });
    } else if (definition?.type === 'Graphic' || definition?.type === '') {
      const childImg: HTMLElement | null = element.querySelector('.arc-img-overlay') ?? element.querySelector('.arc-graphic');
      if ((dataAttribute.id === 'height' || dataAttribute.id === 'width') && childImg) {
        this.editor.undoManager.transact(function () {
          const unit = element.dataset['unit'];
          childImg.style[dataAttribute.id] = value + unit;
        });
      } else if (dataAttribute.id === 'unit' && childImg) {
        this.editor.undoManager.transact(function () {
          element.dataset['unit'] = value;
          childImg.style.width = element.dataset['width'] + value;
          childImg.style.height = element.dataset['height'] + value;
        });
      }
    }
  }

  applyDataAttribute(element: HTMLElement, dataV: string, newValue: string, appliedByUser = true) {
    element = this.refreshTargetElmIfNeeded(element) as HTMLElement;
    this.editor.undoManager.transact(function () {
      element.setAttribute(dataV, newValue);
    });
    EditorStore.triggerDataAttributeApplied(appliedByUser);
  }

  refreshTargetElmIfNeeded(targetElement?: HTMLElement) {
    if (!targetElement) {
      return null;
    } else if (document.body.contains(targetElement)) {
      return targetElement;
    } else {
      return $(`[data-nid="${targetElement.getAttribute('data-nid')}"]`)[0];
    }
  }

  // ignore table element page break
  private getStart() {
    const start = this.editor.selection.getStart();
    if (start.classList.contains('table-element-page-break')) {
      return $(`[data-nid="${start.getAttribute('data-ref-nid')?.split('-table')[0]}"]`)[0];
    }
    return start;
  }
}
