import * as React from 'react';
import { Media } from '../../MediaLibContent';
import { MediaInsertUtils } from '../../utils/mediaInsertUtils';
import MediaThumb from './MediaThumb';
import FileIcon from '../../../../misc/icons/FileIcon';
import { MediaTypesContants } from '../../types';

interface Props {
  media: Media;
  selected: boolean;
  onItemSelect: (media: Media) => any;
  onItemOpen: (media: Media) => any;
}

const MediaItemThumb = (props: Props) => {
  const { media, selected, onItemSelect, onItemOpen } = props;
  const className = 'thumb animation-fade-in' + (selected ? ' selected' : '');
  const caption = media.caption ? media.caption : media.filename;
  const description = media.description || 'No description';

  return (
    <li
      className={className}
      onClick={onItemSelect.bind(this, media)}
      onDoubleClick={onItemOpen.bind(this, media)}
      data-qa={'media-item-thumb'}
    >
      <div className="thumb-img">
        <MediaThumb media={media} caption={caption} />
      </div>
      <div className="thumb-info">
        <div className="thumb-info-name">
          <span title={media.filename}>{media.filename}</span>
          <span
            className={`icon-wrapper-dark ${
              MediaInsertUtils.isImage(media) ? 'image' : MediaInsertUtils.isSymbol(media) ? 'symbol' : 'video'
            }`}
          >
            <FileIcon
              fileType={props.media.type}
              mimeType={props.media.mimeType}
              iconNameOnlyInClassname={MediaInsertUtils.isSymbol(media)}
              className={`${props.media.type == MediaTypesContants.other ? 'other-icon' : ''}`}
            />
          </span>
        </div>
        <div title={description} className={`thumb-info-file ${!media.description ? 'color-gray font-italic' : ''}`}>
          {description}
        </div>
      </div>
    </li>
  );
};

export default MediaItemThumb;
