import FontIcon from 'material-ui/FontIcon';
import * as React from 'react';

type Props = {
  onClick: (e: any) => void;
};

const AttachFileButton = (props: Props) => {
  return (
    <span onClick={(e) => props.onClick(e)} className="attach-file-button-component cursor-pointer">
      <FontIcon className="component-icon material-icons">attach_file</FontIcon>
    </span>
  );
};

export default AttachFileButton;
