import { mm, exemptStatusCodes, ClientError } from './base-clients';
import { IDocReference } from 'mm-types';
import { AxiosResponse } from 'axios';

export type GetAllOptions = {
  availableOnly: boolean;
};

/**
 * Removes a doc reference
 */
export async function remove(orgUid: string, docRefUid: string) {
  await mm.delete(`/orgs/${orgUid}/docReferences/${docRefUid}`);
}

/**
 * Gets all doc refs
 */
export async function getAll(orgUid?: string, options: Partial<GetAllOptions> = { availableOnly: false }) {
  const query: string[] = [];
  if (options.availableOnly) {
    query.push('availableOnly=true');
  }

  let response: AxiosResponse<{ docReferences: IDocReference[] }>;

  if (orgUid) {
    response = await mm.get<{ docReferences: IDocReference[] }>(`/orgs/${orgUid}/docReferences?${query.join('&')}`);
  } else {
    response = await mm.get<{ docReferences: IDocReference[] }>(`/docReferences?${query.join('&')}`);
  }

  const docReferences = response.data.docReferences || [];

  for (const docReference of docReferences) {
    docReference.cdmsType = docReference.type && docReference.type === 'CONTROLLED' ? 'CDMS' : 'non-CDMS';
    docReference.status = 'Free';

    if (docReference.projectUid) {
      if (docReference.lastPublishedIndexUid) {
        docReference.status = 'Published';
      } else {
        docReference.status = 'Linked';
      }
    } else if (docReference.title) {
      docReference.status = 'Linked';
    }
  }

  return docReferences;
}

/**
 * Updates a doc reference
 */
export async function update(orgUid: string, refUid: string, token: Partial<IDocReference>) {
  const response = await mm.put(`/orgs/${orgUid}/docReferences/${refUid}`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Doc Ref already exists');
  }

  return response;
}

/**
 * Creates a new doc reference
 */
export async function create(orgUid: string, token: Partial<IDocReference>) {
  const response = await mm.post(`/orgs/${orgUid}/docReferences`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Doc Ref already exists');
  }

  return response;
}
