import * as React from 'react';

const NoNewNotifications = () => (
  <div>
    <div className="graphic">
      <img src={'/assets/images/dashboard_no_notifications.svg'} />
    </div>
    <div className="description">
      <div className="description__main-line">Nothing new to report at this time,</div>
      <div className="description__main-line">but please check back later!</div>
      <div className="description__sub-line">
        {
          "This is where you'll find all your notifications about document export, publication progress, compliance reports and doc team membership news."
        }
      </div>
    </div>
  </div>
);

export default NoNewNotifications;
