import { mm, Cancelled } from './base-clients';
import { ILink } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;

export async function getAll(projectUid: string, indexUid: string): Promise<ILink[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ links: ILink[] }>(`/projects/${projectUid}/indexes/${indexUid}/metadata/links`, {
      cancelToken: getAllSource.token
    });

    const links = response.data.links;

    return links;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}
