export type ParagraphSubStype = 'default' | 'arc-term' | 'arc-definition' | 'arc-comment' | 'arc-condition';

const props: { [key in ParagraphSubStype]: { title: string; unitLabel: string } } = {
  default: { title: 'Default', unitLabel: 'P' },
  'arc-term': { title: 'Term', unitLabel: 'TER' },
  'arc-definition': { title: 'Definition', unitLabel: 'DEF' },
  'arc-comment': { title: 'Comment', unitLabel: 'COM' },
  'arc-condition': { title: 'Condition', unitLabel: 'CON' }
};

export default { props };
