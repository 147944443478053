import * as React from 'react';
import SpecialInterestGroupTagsDropdown from '../../../../components/misc/SpecialInterestGroupTagsDropdown';
import EditorStore from '../../../../flux/editor/EditorStore';
import UnitSpecialInterestTagStore from '../../../../flux/editor/UnitSpecialInterestTagStore';
import alphaNumSort from '../../../../utils/alphaNumSort';
import { ISpecialInterestGroup, IUnit } from 'mm-types';
import useListenToStore from '../../../hooks/useListenToStore';
import { SvgDropdownIcon } from '../../../misc/icons/SvgDropdownIcon';

type Props = {
  unit: IUnit;
};

const parseUnitSpecialInterestTagMapping = (unit: IUnit): ISpecialInterestGroup[] => {
  const mappedSpecialInterestGroupTags = UnitSpecialInterestTagStore.getUnitSpecialInterestTagMaps([unit]);
  mappedSpecialInterestGroupTags.sort((a, b) => {
    return alphaNumSort(a['uid'], b['uid']);
  });
  return mappedSpecialInterestGroupTags;
};

export const DocUnitSpecialInterestGroupTagMenu = ({ unit }: Props) => {
  const [specialInterestGroupTags, setSpecialInterestGroupTags] = React.useState<ISpecialInterestGroup[]>(
    parseUnitSpecialInterestTagMapping(unit)
  );
  const dropDownActivateBtn = React.useRef<SVGSVGElement>(null);
  const specialInterestTagsDropdown = React.useRef<SpecialInterestGroupTagsDropdown>(null);

  useListenToStore({ store: UnitSpecialInterestTagStore, eventListener: onStoreChange });

  function onStoreChange() {
    setSpecialInterestGroupTags(parseUnitSpecialInterestTagMapping(unit));
  }

  React.useEffect(() => {
    setSpecialInterestGroupTags(parseUnitSpecialInterestTagMapping(unit));
  }, [unit]);

  const openDropDown = () => {
    if (specialInterestTagsDropdown.current && dropDownActivateBtn.current) {
      specialInterestTagsDropdown.current.open(dropDownActivateBtn.current);
    }
  };

  return (
    <>
      {specialInterestGroupTags.length ? (
        <div className="du-inline-item du-inline-actions du-inline-dropdown du-inline-specialinteresttags" onClick={() => openDropDown()}>
          <div className="special-interest-tag material-icons">people</div>
          <SvgDropdownIcon ref={dropDownActivateBtn} />
          <SpecialInterestGroupTagsDropdown
            assignedTags={specialInterestGroupTags}
            isReadOnly={EditorStore.isReadOnly()}
            ref={specialInterestTagsDropdown}
          />
        </div>
      ) : null}
    </>
  );
};
