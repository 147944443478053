import React from 'react';
import { useFeatureSwitches } from '../../featureSwitch/useFeatureSwitches';
import { Features } from 'mm-types';
import Checkbox from 'material-ui/Checkbox';

interface Props {
  onChange();
}

const FeatureManager = ({ onChange }: Props) => {
  const { features, setFeature } = useFeatureSwitches();

  function onFeatureChange(name: keyof Features) {
    onChange();
    setFeature(name, !features[name]);
  }

  return (
    <div className="padding-l">
      <h5 className="margin-none font-size-16 font-bold">Features</h5>
      <ul className="margin-bottom-none">
        {Object.keys(features).map((name: keyof Features) => {
          return (
            <li key={name} className="margin-vertical-l">
              <Checkbox label={name} labelStyle={{ fontSize: 14 }} checked={features[name]} onCheck={(e) => onFeatureChange(name)} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FeatureManager;
