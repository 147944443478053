import * as React from 'react';

interface Props {
  numberOfItems: number;
  show: boolean;
}

function generateComponents(no: number): number[] {
  return Array.from({ length: no }, (v, i) => i);
}

const MediaItemLoadingThumbs = (props: Props) => {
  if (!props.show) {
    return null;
  }
  const progressThumbs = generateComponents(props.numberOfItems);
  return (
    <React.Fragment>
      {progressThumbs.map((id) => {
        return (
          <li className="thumb loading-thumb" key={'fake-' + id}>
            <div className="thumb-img" />
            <div className="thumb-info">
              <div className="thumb-info-name">
                <span className="text-placeholder" />
                <span className="icon-wrapper-dark" />
              </div>
              <div className="thumb-info-file">
                <span className="text-placeholder" />
              </div>
            </div>
          </li>
        );
      })}
    </React.Fragment>
  );
};

export default MediaItemLoadingThumbs;
