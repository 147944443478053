import * as Reflux from 'reflux';
import * as scClient from '../../clients/shared-content';
import * as usersClient from '../../clients/units';
import * as projectsClients from '../../clients/projects';
import Store from '../Store';
import { IProject, ISharedIndex } from 'mm-types';
import { SearchParams } from '../../components/editor/sharedcontentlib/search/SearchSharedLibContainer';
import { Cancelled } from '../../clients/base-clients';
import ContentTypes from '../../components/editor/sharedcontentlib/SharedContentLibTypes';

const REQUEST_SIZE = 15;

export type State = {
  searchSharedContents: ISharedIndex[];
  regulatoryDocs: IProject[];
  isProjectTypeToggled: boolean;
  contentType: string;
  action: number;
};

export type SmartContentLibraryStoreEvent = {
  type:
    | 'retrieveRegulatoryDocs'
    | 'selectSmartContentLibItem'
    | 'shareSearch'
    | 'projectTypeToggleChanged'
    | 'contentTypeChanged'
    | 'actionChanged';
  state?: State;
  itemUid?: string;
};

export class SmartContentLibraryStore extends Store<State> {
  private _page: number;
  private _atEnd: boolean;

  constructor() {
    super();
    this._page = 0;
    this._atEnd = false;
    this.state = {
      searchSharedContents: [],
      regulatoryDocs: [],
      isProjectTypeToggled: false,
      contentType: ContentTypes.props.TYPE_SHARED.key,
      action: 0
    };
  }

  getInitialState() {
    return this.state;
  }

  initProject() {
    this.setState({ ...this.state, contentType: ContentTypes.props.TYPE_SHARED.key });
  }

  onProjectTypeToggleChange(value: boolean) {
    if (this.state.isProjectTypeToggled === value) {
      return;
    }
    this.state.isProjectTypeToggled = value;
    const e: SmartContentLibraryStoreEvent = { type: 'projectTypeToggleChanged', state: this.state };
    this.trigger(e);
  }

  onContentTypeChange(value: string) {
    if (this.state.contentType === value) {
      return;
    }
    this.state.contentType = value;
    const e: SmartContentLibraryStoreEvent = { type: 'contentTypeChanged', state: this.state };
    this.trigger(e);
  }

  onIconAction(value: number) {
    this.state.action = value;
    const e: SmartContentLibraryStoreEvent = { type: 'actionChanged', state: this.state };
    this.trigger(e);
  }

  async retrieveRegulatoryDocs() {
    try {
      const projectsResponse = await projectsClients.getAll({
        projectType: 'regulation'
      });

      if (projectsResponse instanceof Cancelled) {
        return;
      }

      this.state.regulatoryDocs = this._getProjectsAsJSON(projectsResponse.projects);
      const e: SmartContentLibraryStoreEvent = { type: 'retrieveRegulatoryDocs', state: this.state };
      this.trigger(e);
    } catch (err) {
      this.state.regulatoryDocs = [];
      const e: SmartContentLibraryStoreEvent = { type: 'retrieveRegulatoryDocs', state: this.state };
      this.trigger(e);
    }
  }

  async retrieveSharedIndexUnits(projectUid: string, sharedIndexUid: string) {
    const response = await usersClient.getUnits(projectUid, sharedIndexUid);
    return response.units.map((u) => u.unit);
  }

  async retrieveSharedIndexUsages(sharedIndexUid: string) {
    const response = await scClient.getIndexUsages({ sharedIndexUid: sharedIndexUid });
    return response.usages;
  }

  async searchSharedContents(searchParams?: Partial<SearchParams> | null, more = false) {
    if (this._atEnd && more) {
      return;
    }

    if (!more) {
      // initialise
      this.state.searchSharedContents = [];
      this._page = 0;
      this._atEnd = false;
    }

    if (!this._atEnd && more) {
      this._page = this._page + 1;
    }

    const data = {
      page: this._page,
      size: REQUEST_SIZE,
      contentTags: searchParams && searchParams.contentTags ? (searchParams.contentTags as string[]) : [],
      projectDefinitionName: searchParams ? searchParams.projectDefinitionName : undefined
    };

    const searchResults = await scClient.getSharedIndices(data);
    this.state = Object.assign(this.state, {
      searchSharedContents: this.state.searchSharedContents.concat(searchResults)
    });
    const e: SmartContentLibraryStoreEvent = { type: 'shareSearch', state: this.state };
    this.trigger(e);

    if (searchResults.length < REQUEST_SIZE) {
      this._atEnd = true;
    }
  }

  selectSmartContentLibItem(itemUid: string) {
    const e: SmartContentLibraryStoreEvent = { type: 'selectSmartContentLibItem', itemUid: itemUid };
    this.trigger(e);
  }

  _getProjectsAsJSON(projects: IProject[]) {
    return projects.filter((p) => {
      return p.status !== 'DELETED';
    });
  }
}

const singleton = Reflux.initStore<SmartContentLibraryStore>(SmartContentLibraryStore);
export default singleton;
