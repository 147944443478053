import * as React from 'react';

interface Props {
  expanded: boolean;
  onToggle();
}

export function PropertiesHeader({ expanded, onToggle }: Props) {
  const title = expanded ? 'Collapse All' : 'Expand All';
  const icon = expanded ? 'unfold_less' : 'unfold_more';

  return (
    <div className="properties-header">
      <h1>
        Properties
        <div className="expand" onClick={onToggle}>
          <i className="material-icons">{icon}</i>
          <span>{title}</span>
        </div>
      </h1>
    </div>
  );
}
