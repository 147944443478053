import * as Reflux from 'reflux';
import * as client from '../../clients/change-tasks';
import IndexEventStore, { IndexEventStoreEvent } from '../events/IndexEventStore';
import ActiveUserStore from '../common/ActiveUserStore';
import Store from '../Store';
import { DocParams, IUnit } from 'mm-types';
import { AxiosError } from 'axios';
import ChangeTasksStore, { ChangeTasksStoreEvent } from './ChangeTasksStore';

export type State = {
  unitTaskMap: { [name: string]: string[] };
};

export type UnitTaskStoreEvent = State & { snackbar?: string };

export class UnitTaskStore extends Store<State> {
  constructor() {
    super();

    this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
    this.listenTo(ChangeTasksStore as any, this.onChangeTasksStoreUpdate);
    this.state = {
      unitTaskMap: {}
    };
  }

  getInitialState() {
    return this.state;
  }

  getUnitTasks(unitUid: string) {
    const taskUids = this.state.unitTaskMap ? this.state.unitTaskMap[unitUid] : ([] as string[]);
    return taskUids ? taskUids : [];
  }

  // Event Handlers

  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.activity === 'unitTaskChanged') {
      this.init({ indexUid: e.data.indexUid });
    }
  }

  onChangeTasksStoreUpdate(e: ChangeTasksStoreEvent) {
    if (!!e.tasks) {
      // Transform e.tasks into taskLookup for more efficient search
      let taskLookup: { [key: string]: string } = {};
      e.tasks.forEach((task) => (taskLookup[task.uid] = task.uid));

      // go through unitTaskMap and filter out tasks which are not in e.tasks
      Object.keys(this.state.unitTaskMap).forEach((key) => {
        const unitTasks = this.state.unitTaskMap[key];
        if (Array.isArray(unitTasks)) {
          // filter out any unit tasks which are not anymore in the e.tasks
          const filtered = (unitTasks as string[]).filter((taskUid) => {
            return taskLookup[taskUid] === taskUid;
          });

          this.state.unitTaskMap[key] = [...filtered];
        }
      });
    } else {
      // No tasks -> empty unitTaskMap (leave unitUid keys though)
      Object.keys(this.state.unitTaskMap).forEach((key) => {
        if (Array.isArray(this.state.unitTaskMap[key])) {
          this.state.unitTaskMap[key] = [];
        }
      });
    }
  }

  async init(docParams: DocParams, options?: { silent?: boolean }) {
    const map = await client.getUnitMapping(docParams.indexUid!);
    this.state.unitTaskMap = map;

    if (!(options && options.silent)) {
      this.trigger(this.state as UnitTaskStoreEvent);
    }
  }

  async assignTasksToUnit(tasks: string[], unit: IUnit, indexUid: string, options?: { silent?: boolean }) {
    try {
      await client.addUnitTasks(unit.uid, indexUid, tasks);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'unitTaskChanged',
        data: { indexUid: indexUid }
      });

      await this.init({ indexUid: indexUid }, options);
      return;
    } catch (err) {
      await this.init({ indexUid: indexUid }, options);
      return;
    }
  }

  async removeTasksFromUnit(tasks: string[], unit: IUnit, indexUid: string) {
    try {
      await client.removeUnitTasks(unit.uid, indexUid, tasks);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'unitTaskChanged',
        data: { indexUid: indexUid }
      });
      await this.init({ indexUid: indexUid });
      return;
    } catch (err) {
      const axiosErr = err as AxiosError;
      const errCode = axiosErr?.response?.data?.errors?.[0]?.code ?? null;

      if (axiosErr.response?.status === 400 && errCode === 400108) {
        this.trigger({ snackbar: 'Cannot remove all task tags, auto tagging enforced' } as UnitTaskStoreEvent);
      } else {
        await this.init({ indexUid: indexUid });
      }
    }
  }
}

const singleton = Reflux.initStore<UnitTaskStore>(UnitTaskStore);
export default singleton;
