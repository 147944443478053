import * as React from 'react';
import { TableEditState, DataAttribute } from './TableEditProps';
import { IElementSettings, TTabType } from 'mm-types';

type TableEditActions =
  | { type: 'setDataAttributes'; payload: DataAttribute[] }
  | { type: 'setSettings'; payload: IElementSettings[] | undefined }
  | { type: 'setActiveTab'; payload: TTabType }
  | { type: 'setUserOpened'; payload: boolean }
  | { type: 'setSelectedTable'; payload: HTMLTableElement | undefined }
  | { type: 'setCaption'; payload: { isCaptionOn: boolean; caption: string | null; updateCaption: boolean } }
  | { type: 'setUpdateCaption'; payload: { updateCaption: boolean } }
  | { type: 'setCaptionWithErrorMsg'; payload: { caption: string | null; errorMsg: string | null } };

export const initialState: TableEditState = {
  isCaptionOn: false,
  caption: null,
  updateCaption: false,
  activeTab: 'formatting',
  dataAttributes: [],
  userOpened: false
};

export const TableEditReducer: React.Reducer<TableEditState, TableEditActions> = (state, action) => {
  switch (action.type) {
    case 'setDataAttributes':
      return { ...state, dataAttributes: action.payload };
    case 'setSettings':
      return { ...state, settings: action.payload };
    case 'setActiveTab':
      return { ...state, activeTab: action.payload };
    case 'setUserOpened':
      return { ...state, userOpened: action.payload };
    case 'setSelectedTable':
      return { ...state, selectedTable: action.payload };
    case 'setCaption':
      return {
        ...state,
        isCaptionOn: action.payload.isCaptionOn,
        caption: action.payload.caption,
        updateCaption: action.payload.updateCaption
      };
    case 'setUpdateCaption':
      return { ...state, updateCaption: action.payload.updateCaption };
    case 'setCaptionWithErrorMsg':
      return { ...state, caption: action.payload.caption, errorMessage: action.payload.errorMsg };
  }
};
