import { ITocNode, IUnit } from 'mm-types';
import TocStore from '../../../../../flux/editor/TocStore';
import { UnitTypes } from '../../../utils/units/UnitTypes';
import { ITocNodeWithDepth } from './TocContainer';

const TOC_LEVELS: Partial<UnitTypes>[] = ['volume', 'chapter', 'section', 'level1', 'level2', 'level3', 'level4', 'level5', 'level6'];

const aliases = {
  'APPENDIX-CHAPTER': 'chapter',
  'appendix-chapter': 'chapter'
};

function _lvl(node: ITocNode): UnitTypes {
  return aliases[node.level!] || node.level;
}

export function isDirectChildOf(typeChild: ITocNode, typeParent: ITocNode) {
  if (!typeChild || !typeParent) {
    return false;
  }
  return TOC_LEVELS.indexOf(_lvl(typeChild)) - TOC_LEVELS.indexOf(_lvl(typeParent)) === 1;
}

export function isChildOf(typeChild: ITocNode | null | undefined, typeParent: ITocNode) {
  if (!typeChild || !typeParent) {
    return false;
  }
  return TOC_LEVELS.indexOf(_lvl(typeChild)) - TOC_LEVELS.indexOf(_lvl(typeParent)) >= 1;
}

export function haveSameLevels(typeChild: ITocNode | null | undefined, typeParent: ITocNode | null | undefined) {
  if (!typeChild || !typeParent) {
    return false;
  }
  return _lvl(typeChild) === _lvl(typeParent);
}

export function isStartUnit(uid: string): boolean {
  return uid === TocStore.getStartUnit()!.uid;
}

export function shouldOpenNewViewOnCreate(node: ITocNode | IUnit): boolean {
  return isChapterLevel(node) || (isSectionLevel(node) && !isChapterLevel(TocStore.getStartUnit()!));
}

export function shouldOpenNewViewOnDelete(node: ITocNode | IUnit): boolean {
  return isStartUnit(node.uid) || (!isSectionLevel(node) && !isSubHeadingLevel(node));
}

export function isSubHeadingLevel(unit: ITocNode | IUnit): boolean {
  const lvl = unit.level;
  return lvl === 'level1' || lvl === 'level2' || lvl === 'level3' || lvl === 'level4' || lvl === 'level5' || lvl === 'level6';
}

export function isSectionLevel(node: ITocNode | IUnit): boolean {
  return node.level === 'section';
}

export function isChapterLevel(node: ITocNode | IUnit): boolean {
  return node.level === 'chapter';
}

/**
 * Stub a frontmatter parent node to contain the frontmatter children. This exists
 * in the frontend but not the backend
 */
export function fakeFrontmatterParentNode(children) {
  return {
    heading: 'FRONTMATTER',
    ordinal: '',
    index: 0,
    type: 'frontmatter_header',
    uid: children[0].uid,
    neverHighlightOnSelect: true,
    children: children
  };
}

// Dynamically flatten nodes to have all TOC nodes in single depth array
export function flattenArray(topNodeArray, outsideArray) {
  const tmpArray: ITocNode[] = [];
  let moreRuns = false;
  topNodeArray.forEach((node) => {
    if (node.children.length) {
      moreRuns = true;
      outsideArray.push(node);
      tmpArray.push(...node.children);
    } else {
      tmpArray.push(node);
    }
  });
  outsideArray = outsideArray.concat(tmpArray);

  if (moreRuns) {
    // Recurisve flatten call
    return flattenArray(tmpArray, outsideArray);
  } else {
    return outsideArray;
  }
}

export function getFlattenTocs(tocs: ITocNode[], depth = 2): ITocNodeWithDepth[] {
  const stack = tocs
    .filter((node) => !!node)
    .map<ITocNodeWithDepth>((node) => ({
      $depth: 0,
      ...node
    }));

  const result: ITocNodeWithDepth[] = [];
  let first: ITocNodeWithDepth;

  while (stack.length > 0) {
    if (stack[0].$depth >= depth) {
      stack.shift();
      continue;
    }
    first = stack[0];
    result.push(first);

    if (!first.children.length) {
      stack.shift();
    } else {
      stack.splice(
        0,
        1,
        ...first.children.map<ITocNodeWithDepth>((node) => ({
          $depth: first.$depth + 1,
          ...node
        }))
      );
    }
  }

  return result;
}
