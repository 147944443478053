import React, { useState, CSSProperties, useEffect } from 'react';
import { AutoComplete } from 'material-ui';

import CloseIcon from './CloseIcon';

export interface IDropDownItem {
  value: string;
  text: string;
  uid?: string;
}

interface DropDownSearchableProps {
  title?: string;
  placeholder?: string;
  items: IDropDownItem[];
  defaultText?: string;
  onChange?: (item?: IDropDownItem) => void;
  onReset?: () => void;
  style?: CSSProperties;
  autoCompleteStyle?: CSSProperties;
  fullWidth?: boolean;
  floatingLabelStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  dataQa?: string;
}

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  containerStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  hintStyle: {
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  inputStyle: {
    paddingRight: '50px',
    textOverflow: 'ellipsis'
  },
  iconsContainerStyle: {
    alignItems: 'center',
    alignSelf: 'flex-end',
    display: 'flex',
    marginBottom: '1rem'
  },
  closeIcon: {
    marginLeft: '-40px',
    marginRight: '23px',
    fontSize: '0.8em'
  },
  dropdownIcon: {
    marginLeft: '-21px',
    cursor: 'pointer',
    display: 'inline-block',
    color: '#e0e0e0',
    fill: '#e0e0e0',
    height: '24px',
    width: '24px',
    userSelect: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  underlineStyle: {
    borderBottomColor: 'rgb(224, 224, 224)'
  },
  underlineFocusStyle: {
    borderBottomColor: '#05adde'
  },
  menuStyle: {
    maxHeight: '300px',
    width: '256px'
  },
  textFieldStyle: {
    fontSize: '14px'
  }
};

const DropDownSearchable = ({
  title,
  items,
  defaultText = '',
  onChange,
  onReset,
  placeholder,
  style,
  fullWidth,
  autoCompleteStyle,
  floatingLabelStyle,
  menuStyle,
  dataQa
}: DropDownSearchableProps) => {
  const [searchText, setSearchText] = useState(defaultText);

  useEffect(() => {
    setSearchText(defaultText || '');
  }, [defaultText]);

  const onUpdateInput = (text: string) => {
    setSearchText(text);
  };

  const onNewRequest = (chosenRequest: IDropDownItem) => {
    if (onChange) {
      const item = items.find(({ value }) => value === chosenRequest.value);
      if (item) {
        onChange(item);
      }
    }
  };

  return (
    <div style={{ ...style }}>
      <div data-qa={`dropdown-searchable__${(dataQa || title || placeholder)?.toLowerCase()}`} style={{ ...defaultStyles.containerStyle }}>
        <AutoComplete
          dataSource={items}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          filter={AutoComplete.caseInsensitiveFilter}
          floatingLabelText={title}
          hintStyle={defaultStyles.hintStyle}
          hintText={placeholder}
          inputStyle={defaultStyles.inputStyle}
          menuStyle={{ ...defaultStyles.menuStyle, ...menuStyle }}
          onUpdateInput={onUpdateInput}
          onNewRequest={onNewRequest}
          openOnFocus={true}
          searchText={searchText}
          textFieldStyle={defaultStyles.textFieldStyle}
          underlineFocusStyle={defaultStyles.underlineFocusStyle}
          underlineStyle={defaultStyles.underlineStyle}
          popoverProps={{
            canAutoPosition: true,
            style: { width: '256px', overflow: 'scroll' }
          }}
          style={{ ...autoCompleteStyle }}
          fullWidth={fullWidth ?? false}
          floatingLabelStyle={floatingLabelStyle}
        />
        <div style={defaultStyles.iconsContainerStyle}>
          {searchText && (
            <CloseIcon
              style={defaultStyles.closeIcon}
              onClick={() => {
                setSearchText('');
                onReset && onReset();
              }}
            />
          )}
          <svg viewBox="0 0 24 24" style={defaultStyles.dropdownIcon}>
            <path d="M7 10l5 5 5-5z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DropDownSearchable;
