// only keeps a high level history of the previous base url, e.g /editor/

let _previous = '';
let _current = '';

function update(path) {
  const base = /[^/]+/.exec(path.pathname);
  if (base && base.length > 0) {
    if (_current !== base[0]) {
      _previous = _current;
      _current = base[0];
    }
  }
}

function last() {
  return _previous;
}

function current() {
  return _current;
}

export default {
  update: update,
  last: last,
  current: current
};
