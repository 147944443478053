import { IProjectActivity } from 'mm-types';
import React from 'react';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const SkippedWorkflow = ({ entry }: PropsType) => {
  const skippedWorkflowDom: JSX.Element[] = [];
  if (entry.childrenActivities && entry.indexRevisionNumber) {
    skippedWorkflowDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  } else {
    if (entry.indexRevisionNumber && !entry.isChild) {
      skippedWorkflowDom.push(
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      );
    }
    if (entry.assignment) {
      if (entry.user) {
        skippedWorkflowDom.push(
          <div key="user">
            <label>User:</label> <span>{entry.user.displayName}</span>
          </div>
        );
      }

      if (entry.assignment.stageTitle) {
        skippedWorkflowDom.push(
          <div key="stage">
            <label>Stage:</label> <span>{entry.assignment.stageTitle}</span>
          </div>
        );
      }
      if (entry.projectActivityType === 'WORKFLOW_STAGE_AUTOMATICALLY_SKIPPED') {
        skippedWorkflowDom.push(
          <div key="stage-from">
            <label>Stage:</label> <span>{entry.workflowTransition.fromStageTitle}</span>
          </div>
        );
      }
      if (entry.assignment.completionDate) {
        skippedWorkflowDom.push(
          <div key="completion">
            <label>Completion Date:</label>
            <span>{dateUtil(entry.assignment.completionDate).formatDateTimeNoSecs()}</span>
          </div>
        );
      }

      skippedWorkflowDom.push(
        <div key="comment">
          <label>Comment:</label> <span>Stage automatically skipped as no regulatory changes detected.</span>
        </div>
      );
    }
  }

  return <div className="settings-details">{skippedWorkflowDom}</div>;
};
