import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { IKPIProjectSummary, IKPIWorkflowSummary, IUser, IWorkspace } from 'mm-types';

import * as kpi from '../../documents/kpi/kpiClient';
import { Cancelled } from '../../../clients/base-clients';
import DashboardWidget from '../DashboardWidget';
import DashboardPanelHeaderTitle from '../DashboardPanel/DashboardPanelHeaderTitle';
import DashboardPanelHeader from '../DashboardPanel/DashboardPanelHeader';
import DashboardPanel from '../DashboardPanel/DashboardPanel';
import DropDownSearchable, { IDropDownItem } from '../../misc/DropDownSearchable';
import { useDebouncedCallback } from '../../general/useDebouncedCallback';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';
import { MessageType } from '../../../types/components/editor/MessageType';
import { useStorage } from '../../general/useStorage';
import { SESSION_STORAGE_KEYS } from '../../../utils';
import { getTotalDocumentsCSV } from '../../documents/kpi/kpiClient';
import { isFeatureOn } from '../../featureSwitch/featureSwitchUtils';

type WorkflowSummary = {
  total: number;
  pendingApproval: number;
  overdue: number;
  totalDocuments: number;
};

function getStyles(): { [prop: string]: React.CSSProperties } {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexFlow: 'wrap',
      margin: '50px 0px 50px 0px'
    },
    norecords: {
      fontStyle: 'italic'
    },
    error: {
      fontStyle: 'italic',
      color: 'red'
    },
    panel: {
      flex: '1 1 90%',
      position: 'relative',
      height: 'auto'
    },
    dropdownContainer: {
      position: 'absolute',
      right: '19px',
      top: '-9px' // errr...!!! Too much height from the MUI dropdown which is bigger the height needed for the title header
    }
  };
}

const getDefaultSummary = (): WorkflowSummary => {
  return {
    total: 0,
    pendingApproval: 0,
    overdue: 0,
    totalDocuments: 0
  };
};

const noResults = (s: IKPIWorkflowSummary, projectSummary: IKPIProjectSummary) => {
  return s.total + s.pendingApproval + s.overdue + projectSummary.total === 0;
};

const parseSummary = (summary: IKPIWorkflowSummary, projectSummary: IKPIProjectSummary): WorkflowSummary => {
  if (!summary) {
    return getDefaultSummary();
  }
  return { ...summary, totalDocuments: projectSummary.total };
};

const TeamspaceWorkflowSummaryContainer = () => {
  const styles = getStyles();

  const [showMessage, setShowMessage] = useState<MessageType>(MessageType.NONE); // 0 - none, 1 - no records, 2 - error
  const [loading, setLoading] = useState(false);
  const [teamspaces, setTeamspaces] = useState<IDropDownItem[]>([]);
  const [selectedTeamUid, setSelectedTeamUid] = useStorage(SESSION_STORAGE_KEYS.SELECTED_TEAMSPACE_UID);
  const [selectedTeamText, setSelectedTeamText] = useStorage(SESSION_STORAGE_KEYS.SELECTED_TEAMSPACE_TEXT);
  const [summary, setSummary] = useState<WorkflowSummary>(getDefaultSummary());
  const setLoadingDebounced = useDebouncedCallback((value) => setLoading(value), 250);
  const BASE_ROUTE = `/workflow-tasks/teamspaces/${selectedTeamUid}`;

  useEffect(() => {
    const user: IUser | null = ActiveUserStore.getUser();
    let teamspacesToSet: IWorkspace[] = [];

    if (user !== undefined && user?.globalAdmin) {
      teamspacesToSet = user.adminTeamspaces ?? teamspacesToSet;
    } else if (user !== undefined) {
      if (user?.memberTeamspaces !== undefined) {
        teamspacesToSet = [...teamspacesToSet, ...user.memberTeamspaces];
      }
      if (user?.adminTeamspaces !== undefined) {
        teamspacesToSet = [...teamspacesToSet, ...user.adminTeamspaces];
      }
    }
    teamspacesToSet = _.uniqBy(teamspacesToSet, 'uid');
    setTeamspaces(
      teamspacesToSet
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map(({ uid: value, name: text }) => ({
          value,
          text
        }))
    );
    if (!selectedTeamUid) {
      if (teamspacesToSet.length > 0) {
        const { uid, name } = teamspacesToSet[0];
        setSelectedTeamUid(uid);
        setSelectedTeamText(name);
      }
    }
  }, []);

  useEffect(() => {
    let cancelRequest = false;
    const getSummary = async (workspaceUid) => {
      try {
        const response = await kpi.getWorkflowSummaryWorkspace({ workspaceUid });
        const projectSummaryRes = await kpi.getWorkspaceProjectSummary({ workspaceUid });
        if (cancelRequest) {
          return;
        }
        if (response instanceof Cancelled || projectSummaryRes instanceof Cancelled) {
          setLoadingDebounced(false);
          setShowMessage(MessageType.ERROR);
          return;
        }
        if (response) {
          setSummary(parseSummary(response, projectSummaryRes));
          setLoadingDebounced(false);
          if (noResults(response, projectSummaryRes)) {
            setShowMessage(MessageType.NO_RECORDS);
          } else {
            setShowMessage(MessageType.NONE);
          }
        } else {
          setShowMessage(MessageType.ERROR);
        }
      } catch {
        if (cancelRequest) {
          return;
        }
        setLoadingDebounced(false);
        setShowMessage(MessageType.ERROR);
      }
    };
    if (selectedTeamUid) {
      setLoadingDebounced(true);
      getSummary(selectedTeamUid);
    }
    return () => {
      cancelRequest = true;
    };
  }, [selectedTeamUid]);

  return (
    <>
      {teamspaces.length > 0 && (
        <DashboardPanel style={styles.panel} dataQa="team-workflow-summary-container">
          <DashboardPanelHeader dataQa="team-workflow-summary-header">
            <DashboardPanelHeaderTitle>Teamspace Workflow Tasks</DashboardPanelHeaderTitle>
            <div
              style={styles.dropdownContainer}
              className="team-workflow-summary-header-dep-dropdown"
              data-qa="team-workflow-summary-header-dep-dropdown"
            >
              <DropDownSearchable
                title="Teamspace"
                placeholder="Filter Teamspace"
                items={teamspaces}
                onChange={(item) => {
                  setSelectedTeamUid(item?.value || null);
                  setSelectedTeamText(item?.text || null);
                }}
                onReset={() => {
                  setSummary(getDefaultSummary);
                  setShowMessage(MessageType.NONE);
                }}
                defaultText={selectedTeamText || ''}
                style={{ fontWeight: 500 }}
                autoCompleteStyle={{ width: '200px' }}
                fullWidth={true}
              />
            </div>
          </DashboardPanelHeader>
          <div style={styles.container} data-qa="team-workflow-summary-body">
            {isFeatureOn('workflowActions') && (
              <DashboardWidget
                title="Total Documents"
                icon="total-docs"
                value={`${summary.totalDocuments}`}
                action={() => getTotalDocumentsCSV({ workspaceUid: selectedTeamUid ?? undefined })}
                label="Export Publication History"
                loading={loading}
                dataQa="team-workflow-summary-body-totaldocuments"
              />
            )}
            <DashboardWidget
              title="Active Documents"
              icon="workflow"
              value={`${summary?.total}`}
              route={`${BASE_ROUTE}`}
              loading={loading}
              dataQa="team-workflow-summary-body-activedocuments"
            />
            <DashboardWidget
              title="Pending Documents"
              icon="clock"
              value={`${summary?.pendingApproval}`}
              route={`${BASE_ROUTE}?pendingApprovalRecords=true`}
              loading={loading}
              dataQa="team-workflow-summary-body-pendingapproval"
            />
            <DashboardWidget
              title="Overdue Documents"
              icon="stopwatch"
              value={`${summary?.overdue}`}
              route={`${BASE_ROUTE}?overdueRecords=true`}
              loading={loading}
              dataQa="team-workflow-summary-body-overduedocuments"
              alert
            />
          </div>
          {showMessage === MessageType.NO_RECORDS && (
            <div style={styles.norecords} data-qa="team-workflow-summary-body-noresults">
              No results, please refine your filter
            </div>
          )}
          {showMessage === MessageType.ERROR && (
            <div style={styles.error} data-qa="team-workflow-summary-body-error">
              An error occurred, please try again.
            </div>
          )}
        </DashboardPanel>
      )}
    </>
  );
};

export default TeamspaceWorkflowSummaryContainer;
