import * as React from 'react';
import Tooltip from 'material-ui/internal/Tooltip';
import RaisedButton from 'material-ui/RaisedButton';

export type Props = {
  fullWidth?: boolean;
  label?: string;
  primary?: boolean;
  backgroundColor?: string;
  className?: string;
  buttonClassName?: string;
  iconClass?: string;
  labelColor?: string;
  buttonStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<{}>) => void;
  tooltipEnabled?: boolean;
  secondary?: boolean;
  tooltipText?: string;
  labelPosition?: 'after';
  dataQa?: string;
};

export type State = {
  hovered: boolean;
};

export default class RaisedButtonWithTooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hovered: false
    };
  }

  static defaultProps: Partial<Props> = {
    className: '',
    buttonClassName: '',
    labelStyle: {},
    buttonStyle: {},
    fullWidth: false,
    labelPosition: 'after',
    primary: false,
    secondary: false,
    label: '',
    onClick: () => {},
    disabled: false
  };

  handleMouseOver() {
    if (!this.state.hovered) {
      this.setState({ hovered: true });
    }
  }

  handleMouseOut() {
    if (this.state.hovered) {
      this.setState({ hovered: false });
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ display: 'inline-flex' }}
        onMouseOver={(e) => this.handleMouseOver()}
        onMouseOut={(e) => this.handleMouseOut()}
      >
        <RaisedButton
          className={this.props.className}
          fullWidth={this.props.fullWidth}
          labelPosition={this.props.labelPosition}
          primary={this.props.primary}
          secondary={this.props.secondary}
          buttonStyle={this.props.buttonStyle}
          label={this.props.label}
          labelColor={this.props.labelColor}
          labelStyle={this.props.labelStyle}
          backgroundColor={this.props.backgroundColor}
          onClick={(e) => this.props.onClick!(e)}
          disabled={this.props.disabled}
          data-qa={this.props.dataQa}
          style={{ display: 'inline-flex' }}
        >
          <li className="material-icons">{this.props.iconClass}</li>
        </RaisedButton>

        {this.props.tooltipEnabled ? (
          <Tooltip
            label={this.props.tooltipText}
            show={this.props.tooltipEnabled && this.state.hovered}
            verticalPosition="bottom"
            horizontalPosition="center"
            className="raised-button-tooltip"
            touch={true}
          />
        ) : null}
      </div>
    );
  }
}
