import * as React from 'react';
import AppStateStore, { AppStateStoreEvent } from '../../flux/common/AppStateStore';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';
import Delete from 'material-ui/svg-icons/action/delete';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';
import Restore from 'material-ui/svg-icons/content/undo';
import { IProject } from 'mm-types';
import * as _ from 'lodash';
import ActiveUserStore from '../../flux/common/ActiveUserStore';
import { ActionType } from './DocsToolBar';

export type Props = {
  workspaceUid: string | null;
  selected: null | IProject[];
  onselected: (item: ActionType, selected: IProject[] | null) => void;
};

export type State = {
  openMenu: boolean;
};

export default class BatchActionMenu extends React.Component<Props, State> {
  private _menuItemStyle: React.CSSProperties;
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this._menuItemStyle = {
      paddingLeft: '52px',
      height: '37px'
    };

    this.unsubscribe = AppStateStore.listen(this.onAppStoreChange, this);
    this.state = {
      openMenu: false
    };
  }

  static defaultProps: Partial<Props> = {
    selected: null,
    workspaceUid: null
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onAppStoreChange(e: AppStateStoreEvent) {
    if (e.type === 'menuChange') {
      if (e.data.openedMenu !== 'TOOLBAR') {
        this.setState({ openMenu: false });
      }
    }
  }

  _isComplianceDisabled() {
    if (!this._isProjectSelected()) {
      return true;
    } else if (this.props.workspaceUid === 'all') {
      return false;
    } else {
      return !(ActiveUserStore.isAdminOfSelectedProjectTeamspace(this.props.workspaceUid!) || ActiveUserStore.isAdmin());
    }
  }

  _isProjectSelected() {
    return !_.isEmpty(this.props.selected);
  }

  handleOnRequestChange(value) {
    this.setState({
      openMenu: value
    });
    if (value) {
      AppStateStore.openMenu('TOOLBAR');
    }
  }

  _canExportEdb() {
    if (this.props.selected && !_.isEmpty(this.props.selected)) {
      for (let i = 0; i < this.props.selected.length; i++) {
        if (this.props.selected[i].currentUserPermissions.canExportEdb === true) {
          return true;
        }
      }
    }
    return false;
  }

  _canExportAirbus() {
    if (this.props.selected && !_.isEmpty(this.props.selected)) {
      for (let i = 0; i < this.props.selected.length; i++) {
        if (this.props.selected[i].currentUserPermissions.canExportAirbus === true) {
          return true;
        }
      }
    }
    return false;
  }

  _getBatchItems() {
    const complianceDisabled = this._isComplianceDisabled();
    const projectSelected = this._isProjectSelected();

    return (
      <IconMenu
        animated={(window as any).runningGebs as boolean}
        iconButtonElement={
          <IconButton style={{ zIndex: 0 }} iconClassName="material-icons">
            more_vert
          </IconButton>
        }
        onRequestChange={(e) => this.handleOnRequestChange(e)}
        open={this.state.openMenu}
      >
        <MenuItem
          className={'menuItem'}
          key="0"
          primaryText="Compliance Report"
          innerDivStyle={this._menuItemStyle}
          leftIcon={
            <IconButton
              id="compliance"
              className={'itemMenuButton ' + (complianceDisabled ? ' disabled' : '')}
              iconClassName="higher-icon material-icons icon-compliance-report"
            />
          }
          disabled={complianceDisabled}
          onClick={(e) => this._selectedMenuItem(e, 'compliance')}
        />
        <Divider />
        <MenuItem
          className="menuItem"
          key="2"
          primaryText="Export to ArcML"
          innerDivStyle={this._menuItemStyle}
          leftIcon={
            <IconButton
              id="arcml"
              className={'itemMenuButton ' + (!projectSelected ? ' disabled' : '')}
              iconClassName="higher-icon material-icons icon-export"
            />
          }
          disabled={!projectSelected}
          onClick={(e) => this._selectedMenuItem(e, 'exportArcml')}
        />
        <MenuItem
          className="menuItem"
          key="4"
          primaryText="Export to EDB"
          innerDivStyle={this._menuItemStyle}
          leftIcon={
            <IconButton
              id="edb"
              className={'itemMenuButton ' + (!this._canExportEdb() ? ' disabled' : '')}
              iconClassName="higher-icon material-icons icon-export"
            />
          }
          disabled={!this._canExportEdb()}
          onClick={(e) => this._selectedMenuItem(e, 'exportEdb')}
        />
        <MenuItem
          className="menuItem"
          key="4"
          primaryText="Export to Airbus"
          innerDivStyle={this._menuItemStyle}
          leftIcon={
            <IconButton
              id="export-to-airbus"
              className={'itemMenuButton ' + (!this._canExportAirbus() ? ' disabled' : '')}
              iconClassName="higher-icon material-icons icon-export"
            />
          }
          disabled={!this._canExportAirbus()}
          onClick={(e) => this._selectedMenuItem(e, 'exportAirbus')}
        />
        <Divider />
        <MenuItem
          className="menuItem"
          key="5"
          primaryText="Delete"
          innerDivStyle={this._menuItemStyle}
          leftIcon={<Delete id="delete-btn" className={'itemMenuButtonFontello material-ui' + (!projectSelected ? ' iconDisabled' : '')} />}
          disabled={!projectSelected}
          onClick={(e) => this._selectedMenuItem(e, 'delete')}
        />
      </IconMenu>
    );
  }

  _getTrashBatchItems() {
    const projectSelected = this._isProjectSelected();

    return (
      <IconMenu iconButtonElement={<IconButton iconClassName="material-icons">more_vert</IconButton>}>
        <MenuItem
          className="menuItem"
          key="0"
          primaryText="Restore"
          leftIcon={<Restore id="restore" className={'itemMenuButtonFontello ' + (!projectSelected ? ' iconDisabled' : '')} />}
          innerDivStyle={this._menuItemStyle}
          disabled={!projectSelected}
          value="restore"
          onClick={(e) => this._selectedMenuItem(e, 'restore')}
        />
        <MenuItem
          className="menuItem"
          key="1"
          primaryText="Delete permanently"
          innerDivStyle={this._menuItemStyle}
          leftIcon={<DeleteForever id="delete-perm" className={'itemMenuButtonFontello ' + (!projectSelected ? ' iconDisabled' : '')} />}
          disabled={!projectSelected}
          value="delete_forever"
          onClick={(e) => this._selectedMenuItem(e, 'delete_forever')}
        />
      </IconMenu>
    );
  }

  render() {
    return this.props.workspaceUid === 'trash' ? this._getTrashBatchItems() : this._getBatchItems();
  }

  _selectedMenuItem(e: React.SyntheticEvent<{}>, value: ActionType) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onselected(value, this.props.selected);
  }
}
