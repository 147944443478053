import { ISnackbarMessage } from 'mm-types';
import { HideSystemSnackbarAction } from './types';
import { hideSystemSnackbar, showSystemSnackbar } from './actions';
import { AppThunk } from '../../../appStore';
import { ReactFragment } from 'react';

export function showSystemSnackbarMessage(snackbar: ISnackbarMessage): AppThunk {
  return async (dispatch) => {
    dispatch(showSystemSnackbar(snackbar));
  };
}

export function hideSystemSnackbarMessage(): AppThunk<HideSystemSnackbarAction> {
  return async (dispatch) => {
    return dispatch(hideSystemSnackbar());
  };
}

export function showSystemSnackbarMessageWithDefaults(message: string | Element | ReactFragment): AppThunk {
  return async (dispatch) => {
    dispatch(
      showSystemSnackbar({
        message: message,
        open: true,
        autoHideDuration: 5000,
        onRequestClose: () => {
          dispatch(hideSystemSnackbar());
        }
      })
    );
  };
}
