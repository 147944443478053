import * as React from 'react';
import WorkflowSummary from '../../general/WorkflowSummary';
import ProjectPropsStore, { ProjectPropsStoreEvent } from '../../../flux/projects/ProjectPropsStore';
import WorkflowStore, { WorkflowStoreEvent } from '../../../flux/settings/WorkflowStore';
import DocumentsStore from '../../../flux/projects/DocumentsStore';
import ExternalApprovalStore from '../../../flux/settings/ExternalApprovalStore';
import WorkflowUtil from '../../../utils/WorkflowUtil';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { IIndex, IProject, IRevision, IUser, IWorkFlow, IWorkflowSummary } from 'mm-types';
import { transitionTo } from '../../../utils/Navigation';
import { CircularProgress } from 'material-ui';
import { WorkflowActionData } from '../../../clients/workflows';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';
import useListenToStore from '../../hooks/useListenToStore';

export type Props = {
  project: IProject | null;
  index?: null | IIndex;
};

export type WorkflowInfoState = {
  indexUid?: string;
  isLoading: boolean;
  notFound: boolean;
  workflowInstance?: null | IWorkFlow;
  approvalInfo: null | string;
  workflowSummary?: Partial<IWorkflowSummary>;
  index?: null | IIndex;
  refresh: boolean;
  revisions?: IRevision[];
  currentUser?: IUser;
};

type WorkflowActions =
  | { type: 'setIndexUid'; payload?: string }
  | { type: 'setIsLoading'; payload: boolean }
  | { type: 'setIsNotFound'; payload: boolean }
  | { type: 'setWorkflowInstance'; payload?: null | IWorkFlow }
  | { type: 'setApprovalInfo'; payload: null | string }
  | { type: 'setWorkflowSummary'; payload?: Partial<IWorkflowSummary> }
  | { type: 'setIndex'; payload?: null | IIndex }
  | { type: 'setDoRefresh'; payload: boolean }
  | { type: 'setRevisions'; payload?: IRevision[] }
  | { type: 'setCurrentUser'; payload?: IUser };

const WorkflowReducer: React.Reducer<WorkflowInfoState, WorkflowActions> = (state, action) => {
  switch (action.type) {
    case 'setIndexUid':
      return { ...state, indexUid: action.payload };
    case 'setIsLoading':
      return { ...state, isLoading: action.payload };
    case 'setIsNotFound':
      return { ...state, notFound: action.payload };
    case 'setWorkflowInstance':
      return { ...state, workflowInstance: action.payload };
    case 'setApprovalInfo':
      return { ...state, approvalInfo: action.payload };
    case 'setWorkflowSummary':
      return { ...state, workflowSummary: action.payload };
    case 'setIndex':
      return { ...state, index: action.payload };
    case 'setDoRefresh':
      return { ...state, refresh: action.payload };
    case 'setRevisions':
      return { ...state, revisions: action.payload };
    case 'setCurrentUser':
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
};

const WorkflowInfo = (props: Props) => {
  const [state, dispatch] = React.useReducer(WorkflowReducer, {
    indexUid: props.project?.masterIndexUid,
    isLoading: true,
    notFound: false,
    workflowInstance: undefined,
    approvalInfo: null,
    workflowSummary: undefined,
    index: props.index,
    refresh: false,
    revisions: undefined,
    currentUser: undefined
  });

  useListenToStore({ store: ProjectPropsStore, eventListener: onProjectPropsStoreChange });
  useListenToStore({ store: WorkflowStore, eventListener: onWorkflowStoreChange });
  useListenToStore({ store: ExternalApprovalStore, eventListener: onExternalApprovalStoreChange });

  React.useEffect(() => {
    dispatch({ type: 'setIndexUid', payload: props.project?.masterIndexUid });
    dispatch({ type: 'setDoRefresh', payload: true });
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'setIndexUid', payload: props.project?.masterIndexUid });
  }, [props.project?.uid]);

  React.useEffect(() => {
    ProjectPropsStore.getRevisions(props.project!);
    dispatch({ type: 'setDoRefresh', payload: true });
  }, [props.project?.masterIndexUid]);

  React.useEffect(() => {
    if (state.refresh) {
      dispatch({ type: 'setIsLoading', payload: true });
      dispatch({ type: 'setCurrentUser', payload: ActiveUserStore.getUser()! });
      dispatch({ type: 'setRevisions', payload: ProjectPropsStore.getCurrentState().revisions as IRevision[] });
      async function retrieveWorkflowInfo() {
        // Workflow Summary
        await WorkflowStore.retrieveWorkflowSummary(state.indexUid!);

        // Workflow Instance
        if (props.project && props.project.workflow) {
          await WorkflowStore.retrieveWorkflowForIndex(state.indexUid!, { assignments: true });
        }

        // Index - we might have started Workflow, index might have changed
        await WorkflowStore.retrieveIndex(props.project!.uid, state.indexUid!);
        await ExternalApprovalStore.init(props.project!.uid, state.indexUid!);
      }

      retrieveWorkflowInfo().finally(() => {
        dispatch({ type: 'setIsLoading', payload: false });
        dispatch({ type: 'setDoRefresh', payload: false });
      });
    }
  }, [state.refresh]);

  function onWorkflowStoreChange(event: WorkflowStoreEvent) {
    if (event.type === 'workflowSummary') {
      dispatch({ type: 'setIsNotFound', payload: false });
      dispatch({ type: 'setWorkflowSummary', payload: event.state?.workflowSummary });
    } else if (event.type === 'workflowSummaryNotFound') {
      dispatch({ type: 'setIsNotFound', payload: true });
    } else if (event.type === 'workflowInstance') {
      dispatch({ type: 'setWorkflowInstance', payload: event.workflow });
    } else if (event.type === 'index') {
      dispatch({ type: 'setIndex', payload: event.index });
    }
  }

  function onExternalApprovalStoreChange(extApprovalStorestate) {
    if (extApprovalStorestate.approval) {
      dispatch({ type: 'setApprovalInfo', payload: extApprovalStorestate.approval });
    }
  }

  function onProjectPropsStoreChange(event: ProjectPropsStoreEvent) {
    if (event.error) {
      transitionTo('/teamspaces');
    } else {
      if (props.project) {
        // make sure current revision is set when project is changed
        dispatch({ type: 'setIndexUid', payload: event.state.project.masterIndexUid });
        dispatch({ type: 'setRevisions', payload: event.state.revisions as IRevision[] });
        dispatch({ type: 'setDoRefresh', payload: true });
      }
    }
  }

  const getRevisions = () => {
    let revisionsToFormat = state.revisions;

    if (!revisionsToFormat) {
      return null;
    }

    // This is essentially a check for external documents, that have a different
    // strucuture
    if (revisionsToFormat && (revisionsToFormat as any).revisions) {
      revisionsToFormat = (state.revisions as any).revisions;
    }

    const revisionsToRet = revisionsToFormat?.map(function (revision) {
      return { name: revision.interim ? 'Interim Revision' : revision.revision, value: revision.indexUid };
    });
    revisionsToRet?.unshift({ name: 'Draft Revision', value: props.project!.masterIndexUid });
    return revisionsToRet;
  };

  const handleChange = (indexUid: string) => {
    dispatch({ type: 'setIndexUid', payload: indexUid });
    dispatch({ type: 'setDoRefresh', payload: true });
  };

  const showWorkflowActions = () => {
    return (
      props.project &&
      props.project.isExternal &&
      (WorkflowUtil.isWorkflowBeforeStart(state.index!) || WorkflowUtil.isWorkflowActive(state.workflowInstance!))
    );
  };

  const handleWorkflowStart = (index: IIndex, callback: () => void) => {
    WorkflowStore.startWorkflow(index).then(() => {
      if (callback) {
        callback();
      }

      dispatch({ type: 'setDoRefresh', payload: true });
    });
  };

  const getRevisionsAndUpdateProjectSelect = () => {
    ProjectPropsStore.getRevisions(props.project!, true, true);
    DocumentsStore.updateProjectSelect([props.project!.uid]);
  };

  const allRemainingStagesSkippable = () => {
    const stages = state.workflowInstance!.stages!;
    const activeStageUid = state.workflowInstance!.activeStageUid;
    let curIndex = 0;

    for (let i = 0, l = stages.length; i < l; i++) {
      if (stages[i].uid === activeStageUid) {
        curIndex = i;
        break;
      }
    }

    for (let i = curIndex + 1, l = stages.length; i < l; i++) {
      if (!stages[i].skippableStage) {
        return false;
      }
    }

    return true;
  };

  const openApprovalSettings = () => {
    transitionTo('doc-external-approval-settings', {
      projectUid: props.project!.uid,
      indexUid: props.project!.masterIndexUid
    });
  };

  const handleWorkflowAction = (workflowUid: string, workflowActionData: WorkflowActionData) => {
    if (workflowActionData.decisionModel !== 'NOT_REQUIRED' && workflowActionData.decisionModel !== 'SUMMARY') {
      dispatch({ type: 'setIsLoading', payload: true });
    }

    WorkflowStore.workflowAction(workflowActionData, workflowUid)
      .then(() => {
        if (state.workflowInstance && !state.workflowInstance.activeStage.nextStage) {
          getRevisionsAndUpdateProjectSelect();
        } else {
          const isSkipping = workflowActionData.decisionModel === 'APPROVE_SKIP_NEXT';
          if (isSkipping && state.workflowInstance && allRemainingStagesSkippable()) {
            getRevisionsAndUpdateProjectSelect();
          } else {
            dispatch({ type: 'setDoRefresh', payload: true });
          }
        }
      })
      .catch(() => {
        dispatch({ type: 'setDoRefresh', payload: true });
      });
  };

  const items = getRevisions()?.map((item) => {
    return <MenuItem key={item.value} value={item.value} primaryText={item.name} style={{ paddingLeft: '0px' }} />;
  });

  if (!state.revisions) {
    return null;
  }

  if (state.isLoading) {
    return (
      <div className="workflow-info">
        <h2>Workflow</h2>
        <div className="workflow-summary-loading">
          <CircularProgress style={{ margin: '20px' }} />
        </div>
      </div>
    );
  }

  return (
    <div className="workflow-info">
      <h2>Workflow</h2>

      <SelectField
        className="typeInput"
        value={state.indexUid}
        floatingLabelText="Revision"
        onChange={(event, index, value) => {
          handleChange(value);
        }}
        style={{ width: '100%' }}
      >
        {items}
      </SelectField>

      {state.notFound ? (
        'Not found'
      ) : (
        <WorkflowSummary
          data={state.workflowSummary!.workflow}
          performActions={showWorkflowActions()}
          user={state.currentUser!}
          project={props.project!}
          index={state.index!}
          approvalInfo={state.approvalInfo!}
          workflow={state.workflowInstance!}
          onAction={(workflowUid, workflowActionData) => handleWorkflowAction(workflowUid, workflowActionData)}
          onWorkflowStart={(index, callback) => handleWorkflowStart(index, callback)}
          onOpenApprovalSettings={() => openApprovalSettings()}
        />
      )}
    </div>
  );
};

export default WorkflowInfo;
