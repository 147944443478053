import { ActionName, ProjectSettingsActionTypes, IProjectSettingsState } from './index';
import { initialProjectSettings } from './initialProjectSettings';
export function projectSettingsReducer(
  state: IProjectSettingsState = initialProjectSettings,
  action: ProjectSettingsActionTypes
): IProjectSettingsState {
  switch (action.type) {
    case ActionName.SET_PROJECT_SETTINGS: {
      const { settings, masterIndexUid, projectUid } = action;
      return {
        ...state,
        ...settings,
        selected: {
          projectUid,
          masterIndexUid,
          fetching: false
        }
      };
    }
    case ActionName.RESET_PROJECT_SETTINGS: {
      return {
        ...initialProjectSettings
      };
    }
    case ActionName.FETCHING_PROJECT_SETTINGS: {
      const { masterIndexUid, projectUid } = action;
      return {
        ...state,
        selected: {
          projectUid,
          masterIndexUid,
          fetching: true
        }
      };
    }
    default:
      return { ...state };
  }
}

export default projectSettingsReducer;
