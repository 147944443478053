import React from 'react';
import { SharePublishMenu } from './components/SharePublishMenu';
import { ShareUsageMenu } from './components/ShareUsageMenu';
import { ShareMenu } from './components/ShareMenu';
import { IUnit } from 'mm-types';
import ProjectStore from '../../../flux/editor/ProjectStore';
import { PermissionsOverride } from '../docUnit/DocUnit';
import { DocUnitUtils } from '../docUnit/utils/docUnitUtils';

type Props = {
  unit: IUnit;
  permissionsOverride?: Partial<PermissionsOverride> | null;
  position: DocUnitShareDetailsPosition;
};

export enum DocUnitShareDetailsPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}

const DocUnitShareDetails = (props: Props) => {
  const [isShareActionAllowed, setIsShareActionAllowed] = React.useState<boolean>(false);

  React.useEffect(() => {
    const project = ProjectStore.getProject();

    if (!!project) {
      const userPermissions = project.currentUserPermissions;

      typeof props.permissionsOverride?.isShareActionAllowed != 'undefined'
        ? setIsShareActionAllowed(props.permissionsOverride.isShareActionAllowed)
        : setIsShareActionAllowed(userPermissions.canManageShares);
    }
  }, [props.permissionsOverride?.isShareActionAllowed]);
  const getUsageMenuInfo = () => {
    const unit = props.unit;
    if (unit.shareDetails && unit.shareDetails.isShareStartUnit) {
      return DocUnitUtils.getUpdatedSharedUsage(unit);
    }
    return null;
  };

  if (props.unit.shareDetails && props.position == DocUnitShareDetailsPosition.TOP) {
    return <ShareMenu unit={props.unit} position={props.position} />;
  } else if (props.unit.shareDetails && props.position == DocUnitShareDetailsPosition.BOTTOM) {
    return (
      <>
        <SharePublishMenu key="SharePublishMenu" unit={props.unit} isShareActionAllowed={isShareActionAllowed} />
        <ShareUsageMenu
          key="ShareUsageMenu"
          sharedIndexUsage={getUsageMenuInfo()}
          unit={props.unit}
          isShareActionAllowed={isShareActionAllowed!}
        />
        <ShareMenu key="ShareMenu" unit={props.unit} position={props.position} />
      </>
    );
  }

  return null;
};

export default DocUnitShareDetails;
