import { EditorMode } from './EditorMode';
import { GetOptions } from '../../../../clients/units';
import { Banner, MenuTypes, ModeAttributes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';

export class EditingBlockedMode extends EditorMode {
  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.submenuTabs | attributes.mainmenuTabs, syncQueue);
  }

  getBanner(): Banner {
    return { title: 'Editing Blocked', className: 'editing-blocked red darken-4', showClose: false };
  }

  getRetrieveUnitParams() {
    return { editingBlockedMode: true } as Partial<GetOptions>;
  }

  getDisabledMenuItems() {
    return ['DOC_SETTINGS', 'PREVIEW', 'WORKFLOW'] as MenuTypes[];
  }

  getActivatedMenuTabSelections() {
    return { main: '', sub: '' };
  }

  isMenuItemAllowed(menuItemAttr: MenuTypes) {
    return this.getDisabledMenuItems().indexOf(menuItemAttr) <= -1;
  }

  queueSynchronization(syncData) {
    return this.syncQueue.queue(syncData);
  }
}
