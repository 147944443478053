import * as React from 'react';
import { IProjectActivity } from 'mm-types';
import { dateUtil } from '../../../../../utils';

type PropsType = {
  entry: IProjectActivity;
};

export const RevisionUpload = ({ entry }: PropsType) => {
  return (
    <div className="settings-details">
      <div key="comment">
        <label>Comment:</label> <span>{entry.comment}</span>
      </div>

      <div key="date">
        <label>Date:</label> <span>{dateUtil(entry.timestamp).formatDateTimeNoSecs()}</span>
      </div>

      <div key="user">
        <label>User:</label> <span>{entry.user.displayName}</span>
      </div>

      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    </div>
  );
};
