import { Divider, MenuItem, SelectField, Subheader } from 'material-ui';
import React from 'react';
import { IDiffProperties } from 'mm-types';
import { useAttributeActivityLog } from '../hooks/useAttributeActivityLog';
import { PrimaryTextRenderer } from './PrimaryTextRenderer';

type Props = {
  visible: boolean;
  html: string;
  setHtml: (html: string) => void;
  diffProperties?: IDiffProperties[];
};

export enum DiffPropertyActivityType {
  CHANGED = 'Updated',
  ADDED = 'Added',
  REMOVED = 'Removed'
}

export type GroupedPropertiesLogType = {
  nid: string;
  elementName: string;
  diffProperties: IDiffProperties[];
};

export const AttributeActivityDropdown = (props: Props) => {
  const { groupedPropertiesLog, getUpdatedHtml, removeHighlights } = useAttributeActivityLog(props?.diffProperties);
  const [selectedDiffProperty, setSelectedDiffProperty] = React.useState<IDiffProperties | null>(null);
  const [hoveredDiffPropertyNid, setHoveredDiffPropertyNid] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (selectedDiffProperty || hoveredDiffPropertyNid) {
      props.setHtml(getUpdatedHtml(props.html, hoveredDiffPropertyNid, selectedDiffProperty?.nid));
    } else {
      removeHighlights(document.body);
    }
  }, [selectedDiffProperty?.nid, hoveredDiffPropertyNid, props.html]);

  React.useLayoutEffect(() => {
    document.getElementsByClassName('highlight-selected') &&
      document.getElementsByClassName('highlight-selected')[0]?.scrollIntoView({ behavior: 'smooth' });
  }, [props.html]);

  if (!props.visible) {
    return null;
  }

  const getMenuItems = (groupedPropertyLog: GroupedPropertiesLogType) => {
    return groupedPropertyLog?.diffProperties.map((diffProperty, index) => {
      return (
        <MenuItem
          key={diffProperty.nid + index}
          value={diffProperty}
          innerDivStyle={{ padding: '0px 15px' }}
          onMouseEnter={(e) => setHoveredDiffPropertyNid(diffProperty?.nid)}
          onMouseLeave={(e) => setHoveredDiffPropertyNid(null)}
          primaryText={<PrimaryTextRenderer diffProperty={diffProperty} />}
          style={index > 0 ? { borderTop: '1px dotted #e0e0e0e3' } : {}}
        />
      );
    });
  };

  return (
    <SelectField
      id="diff-properties-dropdown"
      value={selectedDiffProperty}
      onChange={(event, index, value) => {
        setSelectedDiffProperty(value);
      }}
      style={{ width: 'initial' }}
      selectionRenderer={() => 'Properties Changed'}
      disabled={!props?.diffProperties || props.diffProperties.length <= 0}
      autoWidth={true}
      menuStyle={{ minWidth: '200px' }}
      menuItemStyle={{ minWidth: '200px' }}
    >
      <MenuItem onClick={(e) => setSelectedDiffProperty(null)} value={null} primaryText={<PrimaryTextRenderer />} />
      <Divider />
      {groupedPropertiesLog?.map((groupedPropertyLog, groupedPropertyLogIndex) => [
        groupedPropertyLogIndex > 0 && <Divider key={groupedPropertyLog?.nid + '_divider'} />,
        <Subheader
          key={groupedPropertyLog?.nid + '_subheader'}
          style={{
            color: '#555555',
            fontSize: '0.8rem',
            lineHeight: 1.75
          }}
        >
          {groupedPropertyLog.elementName}
        </Subheader>,
        getMenuItems(groupedPropertyLog)
      ])}
    </SelectField>
  );
};
