import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSystemAlert } from './thunks';
import { AppState } from '../../../appStore';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { AlertState } from './reducers';

export function SystemAlert() {
  const systemAlert: AlertState = useSelector((state: AppState) => state.systemAlert);
  const dispatch = useDispatch();

  const hideMessage = () => {
    dispatch(hideSystemAlert());
  };
  return (
    <Dialog
      style={{ zIndex: 999 }}
      open={systemAlert.open}
      className="generic-error-dialog"
      actions={
        systemAlert.payload?.errorActions
          ? systemAlert.payload?.errorActions
          : [
              <FlatButton
                key={1}
                label="Ok"
                primary={true}
                onClick={() => {
                  systemAlert.payload?.onClose ? systemAlert.payload.onClose() : null;
                  hideMessage();
                }}
              />
            ]
      }
      title={systemAlert.payload?.errorTitle}
      onRequestClose={() => {}}
    >
      {(typeof systemAlert.payload?.errorMessage === 'string' || systemAlert.payload?.errorMessage instanceof String) &&
      (systemAlert.payload?.errorMessage as String).indexOf('href=') != -1 ? (
        <div
          className="message-header"
          data-qa="system-alert-message-header"
          dangerouslySetInnerHTML={{ __html: `${systemAlert.payload?.errorMessage}` }}
        ></div>
      ) : (
        <div className="message-header" data-qa="system-alert-message-header">
          {systemAlert.payload?.errorMessage}
        </div>
      )}
      <div data-qa="system-alert-user-message"> {systemAlert.payload?.userMessage}</div>
      <div className="divider" />
      <i data-qa="error-message-details">{systemAlert.payload?.errorMessageDetails ? systemAlert.payload?.errorMessageDetails : ''}</i>
    </Dialog>
  );
}
