import axios, { CancelTokenSource } from 'axios';
import { mm, ClientError, exemptStatusCodes } from './base-clients';
import { ISpecialChar } from 'mm-types';
import { CacheClient } from '../utils/CacheClient';

let source: CancelTokenSource | null = null;

/**
 *Gets a list of characters
 */
export async function getCharacters() {
  if (source) {
    source.cancel();
  }

  source = axios.CancelToken.source();

  try {
    const respoonse = await mm.get<{ characters: ISpecialChar[] }>(`/system/properties/characters`, {
      cancelToken: source.token,
      params: CacheClient.params.useCache()
    });
    return respoonse.data.characters;
  } catch (err) {
    return [];
  }
}

/**
 * Sets the special characters
 */
export async function addCharacters(chars: ISpecialChar[]) {
  const data = { characters: chars };
  const response = await mm.put(`/system/properties/characters`, data, {
    validateStatus: (status) => exemptStatusCodes(status, [409]),
    params: CacheClient.params.clearCurrent()
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Character already exists');
  }
}

/**
 * Uploads a list of special characters
 */
export async function uploadFile(file: File) {
  const data = new FormData();
  data.append('file', file);
  await mm.post(`/system/properties/characters/import`, data, { params: CacheClient.params.clearListing() });
}
