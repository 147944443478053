import * as React from 'react';

export type Props = {
  short?: boolean;
};

const NoProperties = (props: Props) => {
  return (
    <div className="narrow-panel-empty-list">
      {!props.short && <img src={'/assets/images/no_properties_graphic.svg'} />}
      <span className="empty-title">No Properties Information</span>
      {!props.short && (
        <span className="empty-details">
          There is no element selected. <br />
          To edit your document, select any <br />
          element to view or modify its <br />
          properties.
        </span>
      )}
    </div>
  );
};

export default NoProperties;
