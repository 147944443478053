import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const WorkflowTransition = ({ entry }: PropsType) => {
  const workflowDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber && !entry.isChild) {
    workflowDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }

  if (entry.workflowTransition) {
    if (entry.workflowTransition.fromStageTitle) {
      workflowDom.push(
        <div key="from-stage">
          <label>From:</label> <span>{entry.workflowTransition.fromStageTitle}</span>
        </div>
      );
    }
    if (entry.workflowTransition.activeStageTitle) {
      workflowDom.push(
        <div key="to-stage">
          <label>To:</label> <span>{entry.workflowTransition.activeStageTitle}</span>
        </div>
      );
    }
  }
  if (entry.user) {
    workflowDom.push(
      <div key="user">
        <label>User:</label> <span>{entry.user.displayName}</span>
      </div>
    );
  }

  return <div className="settings-details">{workflowDom}</div>;
};
