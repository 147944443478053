import { PaginationProps } from '../components/general/Paging';

type Response = PaginationProps;

function getInitialValue(): PaginationProps {
  return {
    totalElements: null,
    pageSize: 0,
    pageNumber: 0,
    numOfPages: 0
  };
}

function extractFromResponse(response: Response): PaginationProps {
  return {
    numOfPages: response.numOfPages || 0,
    pageNumber: response.pageNumber || 0,
    pageSize: response.pageSize || 0,
    totalElements: response.totalElements || 0
  };
}

function getNumberOfItems(pageNumber: number, page: PaginationProps): number {
  const total = page.totalElements;
  if (total === 0 || total === null) {
    return 0;
  }
  return pageNumber + 1 < page.numOfPages ? page.pageSize : total - page.pageSize * (page.numOfPages - 1);
}

const pagingUtil = {
  getInitialValue,
  extractFromResponse,
  getNumberOfItems
};
export default pagingUtil;
