import * as React from 'react';
import * as _ from 'lodash';
import PopupContainer from '../../misc/PopupContainer';
import TocSelector from '../../general/toc-selector/TocSelector';
import { ITocNode } from 'mm-types';
import TocStore, { INCREASED_TOC_DEPTH } from '../../../flux/editor/TocStore';
import { AxiosError } from 'axios';

export type Props = {
  projectUid: string;
  documentIndexUid: string;
  classNames?: string;
  onSelected: (section: ITocNode) => void;
  onSelectionError?: (e: AxiosError) => void;
  selectedUid?: string;
  onClose?: () => void;
};

export type State = {
  tocs: ITocNode[];
  tocsLoading: boolean;
};

export default class RegulationTocPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tocs: [],
      tocsLoading: false
    };
  }

  _fetchTocs() {
    this.setState({ tocsLoading: true }, async () => {
      try {
        const { children, appendices } = await TocStore.retrieveToc({
          projectUid: this.props.projectUid,
          documentIndexUid: this.props.documentIndexUid,
          depth: INCREASED_TOC_DEPTH,
          includeParagraphs: true,
          includeCaptionUnits: true
        });

        this.setState({
          tocsLoading: false,
          tocs: _.flatten([children, appendices])
        });
      } catch (err) {
        this.setState(
          {
            tocsLoading: false
          },
          () => {
            this._selectError(err as AxiosError);
          }
        );
      }
    });
  }

  getPopup() {
    return this.refs.regTocPopup as PopupContainer;
  }

  open() {
    this._fetchTocs();
    (this.refs.regTocPopup as PopupContainer).open();
  }

  close() {
    (this.refs.regTocPopup as PopupContainer).close();
  }

  _sectionSelect(section: ITocNode, variantsMustMatch: boolean) {
    (this.refs.regTocPopup as PopupContainer).close();
    if (this.props.onSelected) {
      this.props.onSelected(section);
    }
  }

  _selectError(e: AxiosError) {
    (this.refs.regTocPopup as PopupContainer).close();
    if (this.props.onSelectionError) {
      this.props.onSelectionError(e);
    }
  }

  render() {
    const popupCls = 'reg-doc-popup ' + this.props.classNames;
    return (
      <PopupContainer
        ref="regTocPopup"
        className={popupCls}
        closeOnBlur={true}
        onClose={() => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
      >
        <div className="popup-container-pointer"></div>
        <div>
          <div className="special-characters-list">
            <TocSelector
              ref="tocSectionPicker"
              tocs={this.state.tocs}
              tocsLoading={this.state.tocsLoading}
              onSelect={(selection, variantsMustMatch) => this._sectionSelect(selection, variantsMustMatch)}
              selectedUid={this.props.selectedUid}
              documentIndexUid={this.props.documentIndexUid}
            />
          </div>
        </div>
      </PopupContainer>
    );
  }
}
