import { docUnitProfile as tocable } from '../tocable';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  ...tocable,
  displayName: 'Appendix',
  getLabel: () => 'APX',
  allowInsertContentElementsAfter: false,
  targetEditContainerSelector: '.arc-unit>.arc-title>.arc-title-heading',
  editor: {
    formats: { ...tocable.editor?.formats },
    forced_root_block: ''
  }
};
