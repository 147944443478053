import * as React from 'react';
import SpecialCharPopup from './SpecialCharPopup';
import IconButton from 'material-ui/IconButton';
import PopupContainer from '../../misc/PopupContainer';

export type Props = {
  enabled: boolean;
};

export type State = {
  isOpened: boolean;
};

const SpecialCharMenuItem = (props: Props) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const specialCharDetails = React.createRef<PopupContainer>();

  const handleOpen = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    e.preventDefault();
    toggle();
  };

  const toggle = () => {
    if (!isOpened) {
      open();
    } else {
      close();
    }
  };

  const open = () => {
    specialCharDetails.current?.open();
    setIsOpened(true);
  };

  const close = () => {
    specialCharDetails.current?.close();
    setIsOpened(false);
  };

  const closeCallback = () => {
    setIsOpened(false);
  };

  return (
    <li id={'menu-insert-btn-special-character'} key="specialchars" className={`special-characters ${props.enabled ? '' : 'disabled'}`}>
      <IconButton
        iconClassName="material-icons icon-special-character"
        tooltipPosition="bottom-center"
        tooltip="Special Characters"
        onClick={(e) => handleOpen(e)}
      ></IconButton>
      <SpecialCharPopup ref={specialCharDetails} onClose={() => closeCallback()} />
    </li>
  );
};

export default SpecialCharMenuItem;
