import * as Reflux from 'reflux';
import * as _ from 'lodash';
import ChangeTasksStore from './ChangeTasksStore';
import * as client from '../../clients/change-tasks';
import Store from '../Store';
import { DocParams, ITask } from 'mm-types';
import ActiveUserStore from '../common/ActiveUserStore';
import { AxiosError } from 'axios';

export type ErrorTypes = 'TAG_INUSE';

export type State = {
  taskUids: string[];
  tasks: ITask[];
};

export type AutoTagStoreEvent = State & { error?: ErrorTypes };

export class AutoTagStore extends Store<State> {
  private _lastDocParams: DocParams | null;
  private _localTaskUids: string[];

  constructor() {
    super();
    this._lastDocParams = null;
    this._localTaskUids = [];
    this.state = {
      taskUids: [],
      tasks: [] // task objects for taskUids
    };
  }

  getInitialState() {
    return this.state;
  }

  getTasks() {
    return this.state.tasks;
  }

  getTasksUids() {
    return this.state.taskUids;
  }

  // Event Handlers

  async retrieveTasks(docParams: DocParams, onComplete?: (state: State) => void) {
    this._lastDocParams = docParams;

    const response = await client.getAllAutoTasks(docParams.projectUid!, docParams.indexUid!);

    this.state.taskUids = response.taskUids;
    this._localTaskUids = this._readFromStorage();
    this.state.taskUids = _.union(this.state.taskUids, this._localTaskUids);
    this._retrieveTasks(onComplete);
  }

  _retrieveTasks(onComplete?: (state: State) => void) {
    const taskObjects = ChangeTasksStore.getInitialState().tasks;
    this.state.tasks = [];

    this.state.taskUids.forEach((taskUid) => {
      const taskObject = taskObjects.find(function (t) {
        return t.uid === taskUid;
      })!;
      if (taskObject) {
        this.state.tasks.push(taskObject);
      }
    });

    if (onComplete) {
      onComplete(this.state);
    } else {
      this.trigger(this.state as AutoTagStoreEvent);
    }
  }

  addTask(taskUid: string) {
    const taskUids = this.state.taskUids;

    if (taskUids.indexOf(taskUid) === -1) {
      taskUids.push(taskUid);
      this._saveTaskUids(taskUids);
    }
  }

  removeTask(taskUid: string) {
    const taskUids = this.state.taskUids;
    const removeIndex = taskUids.indexOf(taskUid);

    if (removeIndex !== -1) {
      taskUids.splice(removeIndex, 1);
      this._saveTaskUids(taskUids);
    }
  }

  async _saveTaskUids(taskUids: string[]) {
    try {
      await client.saveAutoTasks(this._lastDocParams!.projectUid!, this._lastDocParams!.indexUid!, taskUids);

      this.state.taskUids = taskUids;
      if (this._localTaskUids.length > 0) {
        this._removeStorage();
        this._localTaskUids = [];
      }
      this.retrieveTasks(this._lastDocParams!);
    } catch (err) {
      const axiosErr = err as AxiosError;
      if (axiosErr.response && axiosErr.response.status === 400) {
        this.trigger({ error: 'TAG_INUSE' } as AutoTagStoreEvent);
      } else {
        this.retrieveTasks(this._lastDocParams!);
      }
    }
  }

  _readFromStorage() {
    let userAutoTagTasks = localStorage.getItem('userautotagtasks_' + ActiveUserStore.getUser()!.uid);

    try {
      userAutoTagTasks = userAutoTagTasks ? JSON.parse(userAutoTagTasks) : {};
    } catch (e) {
      userAutoTagTasks = null;
    }

    if (userAutoTagTasks !== null) {
      return userAutoTagTasks[this._lastDocParams!.indexUid!] ? userAutoTagTasks[this._lastDocParams!.indexUid!] : [];
    } else {
      return [];
    }
  }

  _removeStorage() {
    localStorage.removeItem('userautotagtasks_' + ActiveUserStore.getUser()!.uid);
  }
}

const singleton = Reflux.initStore<AutoTagStore>(AutoTagStore);
export default singleton;
