import { Cancelled, mm } from './base-clients';
import { IIndex, IRecentIndex } from 'mm-types';
import moment from 'moment';
import ActiveUserStore from '../flux/common/ActiveUserStore';
import axios, { CancelTokenSource } from 'axios';

let getSource: CancelTokenSource | null = null;
let getAllSource: CancelTokenSource | null = null;

export async function get(projectUid: string, indexUid: string): Promise<Cancelled | IIndex> {
  if (getSource) {
    getSource.cancel();
  }

  getSource = axios.CancelToken.source();

  try {
    const response = await mm.get<IIndex>(`/projects/${projectUid}/indexes/${indexUid}`, {
      cancelToken: getSource.token
    });

    const index = response.data;
    parse(index);
    return index;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getAll(projectUid: string): Promise<IIndex[] | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ indexes: IIndex[] }>(`/projects/${projectUid}/indexes`, {
      cancelToken: getAllSource.token
    });

    const indexes = response.data.indexes;
    for (const index of indexes) {
      parse(index);
    }

    return indexes;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function remove(projectUid: string, indexUid: string) {
  await mm.delete(`/projects/${projectUid}/indexes/${indexUid}`);
}

export async function discardDraft(projectUid: string, indexUid: string, index: Partial<IIndex>) {
  await mm.post<IIndex>(`/projects/${projectUid}/indexes/${indexUid}/discardDraft`, index);
}

export async function createInterim(projectUid: string, indexUid: string) {
  return await mm.post<IIndex>(`/projects/${projectUid}/indexes/interim/async`, {
    indexUid: indexUid,
    projectUid: projectUid,
    status: 'INTERIM_DRAFT'
  });
}

export async function update(projectUid: string, indexUid: string, token: Partial<IIndex>) {
  const toSave = { ...token };

  if (toSave.revisionDate) {
    toSave.revisionDate = moment(toSave.revisionDate).utc().format();
  }
  if (toSave.distributionDate) {
    toSave.distributionDate = moment(toSave.distributionDate).utc().format();
  }
  if (toSave.expiryDate) {
    toSave.expiryDate = moment(toSave.expiryDate).utc().format();
  }

  const response = await mm.put<IIndex>(`/projects/${projectUid}/indexes/${indexUid}`, toSave);
  const index = response.data;
  parse(index);
  return index;
}

function parse(index: IIndex) {
  index.revisionDate = index.revisionDate ? moment.utc(index.revisionDate).toDate() : undefined;
  index.distributionDate = index.distributionDate ? moment.utc(index.distributionDate).toDate() : undefined;
  index.expiryDate = index.expiryDate ? moment.utc(index.expiryDate).toDate() : moment('2099-12-31').toDate();
  index.transformationDate = index.transformationDate ? moment(index.transformationDate).toDate() : undefined;
  index.isInterim = index.status === 'INTERIM_DRAFT';
  index.isPublished = index.status === 'MASTER_PUBLISHED' || index.status === 'INTERIM_PUBLISHED';
  index.isLocked = index.lockedStatus !== 'UNLOCKED';
  index.isLockedForMerge = index.lockedStatus === 'LOCKED_FOR_MERGE';
  index.isLockedForWorkflowToStart = index.lockedStatus === 'LOCKED_FOR_WORKFLOW_TO_START';
  index.isLockedForMergeByMe = false;
  index.isMasterDraft = index.status === 'MASTER_DRAFT';

  index.displayName = index.isMasterDraft ? 'Draft Revision' : index.isInterim ? 'Interim Revision' : index.revisionNumber;

  if (index.isLockedForMerge) {
    const user = ActiveUserStore.getUser()!;
    index.isLockedForMergeByMe = index.merger.uid === user.uid;
  }

  return index;
}

// Not sure this belongs here...
export function settingDefaults(): Partial<IIndex> {
  return {
    lockedStatus: 'UNLOCKED',
    revisionNumber: '0.0.0',
    revisionDate: '',
    distributionDate: '',
    expiryDate: ''
  };
}

export async function publish(projectUid: string, indexUid: string) {
  await mm.post<void>(`/projects/${projectUid}/indexes/${indexUid}/publish`, {});
}

export async function sendIndexUsage(indexUid: string, userUid: string) {
  return mm.post<void>(`/users/${userUid}/recent/index/${indexUid}`);
}

export async function getRecent(userUid: string) {
  const response = await mm.get<{ recentIndexes: IRecentIndex[] }>(`/users/${userUid}/recent/index`);
  const indices = response.data.recentIndexes;
  for (const recent of indices) {
    parseRecentIndex(recent);
  }
  return indices;
}

function parseRecentIndex(recentIndex: IRecentIndex) {
  recentIndex.created = new Date(recentIndex.projectModel.created);
  recentIndex.type = recentIndex.projectModel.workspaceType === 'team' ? 'NON_PERSONAL' : 'PERSONAL';
  recentIndex.isPublished = recentIndex.workflowModel && recentIndex.workflowModel.type === 'ARCHIVED' ? true : false;
  recentIndex.title = recentIndex.projectModel.name!;
  recentIndex.projectUid = recentIndex.projectModel.uid;
  recentIndex.indexUid = recentIndex.indexModel.uid;
  recentIndex.revisionNumber = recentIndex.indexModel.revisionNumber;
  recentIndex.dueDate =
    recentIndex.workflowModel && recentIndex.workflowModel.activeStage && recentIndex.workflowModel.activeStage.dueDate
      ? new Date(recentIndex.workflowModel.activeStage.dueDate)
      : null;
  recentIndex.activeStage =
    recentIndex.workflowModel && recentIndex.workflowModel.activeStage ? recentIndex.workflowModel.activeStage.title : null;
}
