import * as React from 'react';
import Scroller from '../../utils/Scroller';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  initialTopPosition?: number;
  onScrolled?(topPosition: number): any;
}

export default class ScrollableDiv extends React.Component<Props, {}> {
  private ref = React.createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.initialTopPosition && this.ref.current) {
        this.ref.current.scrollTop = this.props.initialTopPosition;
      }
    }, 0);
  }

  scrollTop() {
    Scroller.scrollTop(this);
  }

  private onScroll() {
    if (this.ref.current && this.props.onScrolled) {
      this.props.onScrolled(this.ref.current.scrollTop);
    }
  }

  render() {
    const { initialTopPosition, onScrolled, ...restProps } = this.props;
    return <div {...restProps} ref={this.ref} onScroll={this.onScroll} />;
  }
}
