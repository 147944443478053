/* FRAMEWORK */
import * as React from 'react';

/* UTILS */
import StringUtil from '../../../../../utils/StringUtil';
/* TYPES */
import { IUnit, IUnitDefinition } from 'mm-types';
import { Menu, SubMenu } from '../../EditorMenu';

import { hideDisabledMenuItems } from '../utils/utils';
import { DefinitionToMenuPropsType } from '../MenuInsert';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { shouldPresentOption } from '../utils/qrhStructureInsertRules';
import appStore from '../../../../../appStore';
import { hideSystemAlert, showSystemAlert } from '../../../../misc/SystemAlert/thunks';
import QrhStructurePopupOptions from './QrhStructurePopupOptions';
import { useState } from 'react';

interface Props {
  structuralMenuItems: IUnitDefinition[];
  isActivelyEditing: boolean;
  selectedUnit: IUnit | null;
  handleDropdownClick(e: { key: string }, definitionToMenuProps: Partial<DefinitionToMenuPropsType>): void;
  syncOpenKeys(openKeys: any): void;
  openKeys: any;
  isSingleVol: boolean;
  unitDefinitionToMenuItem: (ud: IUnitDefinition) => JSX.Element;
}

export type State = {
  hideDisabled: boolean;
};

const StructureMenu = ({
  handleDropdownClick,
  syncOpenKeys,
  openKeys,
  structuralMenuItems,
  unitDefinitionToMenuItem,
  isActivelyEditing
}: Props) => {
  const [hideDisabled, setHideDisabled] = useState(true);

  const handleQrhStructuralInsert = (e: { key: string; currentTarget: Element }) => {
    if (ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) {
      // Get currently selected unit
      const selectedUnit = EditorStore.getSelectedUnit();
      const presentOptions = shouldPresentOption(e.key, selectedUnit);
      if (presentOptions && presentOptions?.length > 1) {
        appStore.dispatch<any>(
          showSystemAlert({
            errorActions: [],
            errorTitle: 'Please Choose Insert Position',
            errorMessage: (
              <QrhStructurePopupOptions
                unitToInsert={e.key}
                onCancel={() => appStore.dispatch<any>(hideSystemAlert())}
                onSave={(option) => {
                  handleDropdownClick(e, {
                    insertAtLevel: 0,
                    insertPosition: 'insert_inside',
                    parentUnitUid: option
                  });
                  appStore.dispatch<any>(hideSystemAlert());
                }}
                insertOptions={presentOptions}
              />
            )
          })
        );
      } else {
        handleDropdownClick(e, { insertAtLevel: 0, insertPosition: 'insert_inside' });
      }
    } else {
      handleDropdownClick(e, { insertAtLevel: 0, insertPosition: 'insert_inside' });
    }
  };

  const handleTopMenuClick = () => {
    setHideDisabled(!hideDisabled);
  };

  const renderPDMode = (unitDefinitions: IUnitDefinition[], subMenuType: string, className?: string) => {
    if (!subMenuType) {
      if (unitDefinitions.length) {
        subMenuType = unitDefinitions[0].type ? unitDefinitions[0].type : '';
      }
    }

    let menuItems: JSX.Element[] = [];
    for (let i = 0; i < unitDefinitions.length; i++) {
      const ud = unitDefinitions[i];

      // this unitDefinition is either in the top level menu, or another member of the current sub-menu
      if (ud.type === subMenuType) {
        menuItems = [...menuItems, unitDefinitionToMenuItem(ud)] as JSX.Element[];
      } else {
        // this unitDefinition is of a type which will be listed in a new sub-menu -> recursive call to renderPDMode
        menuItems = [...menuItems, renderPDMode(unitDefinitions.slice(i), ud.type, `${ud.type}`)] as JSX.Element[];
        // fast forward through unitDefinitions list to point where recursive call returns...
        unitDefinitions = unitDefinitions.slice(menuItems.length - 1);
        i = menuItems.length - 1;
      }
    }

    if (hideDisabled) {
      menuItems = hideDisabledMenuItems(menuItems, 'No Valid Inserts');
    }

    return (
      <SubMenu
        key={className}
        value={className || ''}
        title={className ? StringUtil.formatForScreen(className) : undefined}
        className={className}
        disabled={isActivelyEditing}
      >
        {menuItems}
      </SubMenu>
    );
  };

  return (
    <Menu
      onClick={(e) => handleQrhStructuralInsert(e)}
      onButtonClick={() => handleTopMenuClick()}
      onOpenChange={syncOpenKeys}
      openKeys={openKeys}
      tabIndex="0"
      title="Doc Structure"
      hideDisabled={hideDisabled}
    >
      {renderPDMode(structuralMenuItems, 'tocable')}
    </Menu>
  );
};

export default StructureMenu;
