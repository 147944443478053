import { Dialog, FlatButton, TextField } from 'material-ui';
import React from 'react';
import { linkAnchorHelper } from '../utils/tinyFacade/tinyLinkAnchorHelper';
import EditorStore from '../../../flux/editor/EditorStore';

type Props = {
  onClose: () => void;
  title?: string | null;
};
const LinkAnchorModal = ({ onClose, title }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [titleValue, setTitle] = React.useState(title ?? '');
  React.useEffect(() => {
    setOpen(true);
    document.addEventListener('keyup', handleEscKey);
    if (title) {
      setIsUpdate(true);
    }
  }, []);

  function dismiss() {
    document.removeEventListener('keyup', handleEscKey);
    EditorStore.getEditor().silentReFocus({ preventCursorReposition: true });
    onClose();
    setOpen(false);
  }

  function handleEscKey(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      dismiss();
    }
  }

  function save() {
    EditorStore.getEditor().silentReFocus();
    if (isUpdate) {
      linkAnchorHelper().editTitle(titleValue);
    } else {
      linkAnchorHelper().addLinkAnchor(titleValue);
    }
    dismiss();
  }

  function remove() {
    linkAnchorHelper().removeLinkAnchor();
    dismiss();
  }
  function actions() {
    let actions = [
      <FlatButton key={1} label="Cancel" onClick={dismiss} data-qa="link-anchor-modal-cancel" />,
      <FlatButton key={3} label={'Save'} disabled={false} onClick={save} data-qa="link-anchor-modal-save" />
    ];
    if (isUpdate) {
      actions.splice(1, 0, <FlatButton key={2} label="Remove" onClick={remove} data-qa="link-anchor-modal-remove" />);
    }
    return actions;
  }

  return (
    <Dialog
      className={'link-dialog'}
      title={isUpdate ? 'Edit a Link Anchor' : 'Insert a Link Anchor'}
      open={open}
      modal={true}
      contentStyle={{ width: '500px', display: 'flex', justifyContent: 'center' }}
      bodyStyle={{ width: '400px', padding: '24px 0px 24px 0px' }}
      actions={actions()}
    >
      <div
        style={{
          padding: '24px',
          borderTop: '1px solid rgb(244,244,244)',
          borderBottom: '1px solid rgb(244,244,244)',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <TextField
          value={titleValue}
          onChange={(e, val) => setTitle(val)}
          floatingLabelText={'Anchor Title'}
          data-qa="link-anchor-modal-title-input"
        />
      </div>
    </Dialog>
  );
};

export default LinkAnchorModal;
