import * as React from 'react';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';
import DocumentVisualStates from '../utils/DocumentVisualStates';
import { IElementDefinition } from 'mm-types';
import { getDefaultValueByElementDefinition } from '../../../utils/DataAttributesUtil';
import { ActionPrint } from 'material-ui/svg-icons';
import { PrintOutputUtils } from '../../../utils/PrintOutputUtils';

type Style = {
  marginTop?: string;
  top?: string;
};

export type ElementPrintOutputProps = {
  definitionId: string;
  breakBefore: string | null;
  keepTogether: string | null;
  keepWithNext: string | null;
  dataRefNid?: string | null;
  style?: Style;
};

export const ElementPrintOutput = ({
  definitionId,
  breakBefore,
  keepTogether,
  keepWithNext,
  dataRefNid,
  style
}: ElementPrintOutputProps) => {
  let showBreaks: boolean = DocumentVisualStates.props.get('SHOW_ELEMENT_PRINT_OUTPUT')?.defaultOn ?? false;

  if (localStorage.getItem('view-settings')) {
    const storedStates = JSON.parse(localStorage.getItem('view-settings')!);
    showBreaks = storedStates.indexOf('SHOW_ELEMENT_PRINT_OUTPUT') > -1;
  }

  const elementDefinition: IElementDefinition | undefined = ProjectDefinitionStore.getElementDefinitionById(definitionId);

  const breakBeforeDefault: string | null = getDefaultValueByElementDefinition('breakBefore', elementDefinition);
  const keepTogetherDefault: string | null = getDefaultValueByElementDefinition('avoidBreakInside', elementDefinition);
  const keepWithNextDefault: string | null = getDefaultValueByElementDefinition('breakAfter', elementDefinition);

  // Do not show Print Output label
  // if the print output types are either not set or set to default values
  if (
    PrintOutputUtils.isNotSetOrSetToDefault(breakBefore, breakBeforeDefault, 'unspecified') &&
    PrintOutputUtils.isNotSetOrSetToDefault(keepTogether, keepTogetherDefault, 'false') &&
    PrintOutputUtils.isNotSetOrSetToDefault(keepWithNext, keepWithNextDefault, 'unspecified')
  ) {
    return null;
  }

  const showkeepTogether = PrintOutputUtils.showIndicator(keepTogether, keepTogetherDefault, 'false');
  const showBreakBefore = PrintOutputUtils.showIndicator(breakBefore, breakBeforeDefault, 'unspecified');
  const showkeepWithNext = PrintOutputUtils.showIndicator(keepWithNext, keepWithNextDefault, 'unspecified');

  const breakBeforeLabel = breakBefore && breakBefore != 'always' ? `(${breakBefore})` : '';

  return (
    <span
      data-ref-nid={dataRefNid ? dataRefNid + '-element' : null}
      className={`element-page-break
        ${showBreakBefore ? 'po-border-top' : ''} ${breakBefore == 'unspecified' ? 'po-border-top-striked' : ''}
        ${showkeepTogether ? 'po-border-right' : ''} ${keepTogether == 'false' ? 'po-border-right-striked' : ''}
        ${showkeepWithNext ? 'po-border-bottom' : ''} ${keepWithNext == 'unspecified' ? 'po-border-bottom-striked' : ''}
        ${showBreaks ? '' : 'display-none'}
      `}
      data-qa="element-page-break"
      style={style}
    >
      <ActionPrint className="material-icons" data-testid="PrintIcon" style={{ width: '14px', height: '14px' }} />
      <span className="tooltiptext">
        <span className="tooltip-row title">{elementDefinition?.displayName}</span>
        {showBreakBefore && (
          <span className={`tooltip-row pagebreak ${breakBefore == 'unspecified' ? 'striked' : ''}`}>Page Break {breakBeforeLabel}</span>
        )}
        {showkeepTogether && <span className={`tooltip-row ${keepTogether == 'false' ? 'striked' : ''}`}>Keep Together</span>}
        {showkeepWithNext && <span className={`tooltip-row ${keepWithNext == 'unspecified' ? 'striked' : ''}`}>Keep With Next</span>}
      </span>
    </span>
  );
};
