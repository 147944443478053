import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import DatePicker from 'material-ui/DatePicker';
import IconButton from 'material-ui/IconButton';
import UserAutoSuggest from '../misc/UserAutoSuggest';
import DocumentsStore, { DocumentsStoreEvent, DocFilter, DocType } from '../../flux/projects/DocumentsStore';
import UserStore, { UserStoreEvent } from '../../flux/settings/UserStore';
import { IUser } from 'mm-types';
import { FlatButton, Checkbox } from 'material-ui';
import { dateUtil } from '../../utils';

export type Props = {
  onRetrieveDocs: () => void;
  workspaceUid: null | string;
};

export type State = {
  users: IUser[];
  filter: Partial<DocFilter>;
  projectTypes?: DocType[];
  filterProjectTypes?: string[];
};

export default class DocumentsFilter extends React.Component<Props, State> {
  private _filterField: null | TextField;
  private _refField: null | TextField;
  private _docsUnsubscribe: Function;
  private _userUnsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this._filterField = null;
    this._refField = null;

    this._docsUnsubscribe = DocumentsStore.listen(this.onDocStoreChange, this);
    this._userUnsubscribe = UserStore.listen(this._onUserStoreChange, this);

    this.state = {
      users: [],
      filter: DocumentsStore.getDocsFilter(),
      projectTypes: DocumentsStore.getProjectTypes(),
      filterProjectTypes: DocumentsStore.getFilterProjectTypes()
    };
  }

  static defaultProps: Partial<Props> = {
    workspaceUid: null
  };

  componentWillUnmount() {
    this._docsUnsubscribe();
    this._userUnsubscribe();
  }

  onDocStoreChange(data: DocumentsStoreEvent) {
    const stateObj: Partial<State> = {};
    if (data.filter) {
      stateObj.filter = data.filter;
    }

    if (data.projectTypes) {
      stateObj.projectTypes = data.projectTypes;
    }

    if (data.filterProjectTypes) {
      stateObj.filterProjectTypes = data.filterProjectTypes;
    }

    this.setState(stateObj as State);
  }

  _getSuggestionValue(user) {
    return user.displayName;
  }

  private _getSuggestions(value: string) {
    UserStore.retrieveUsers(value.trim().toLowerCase());
  }

  private _getUserInputValue(data: { suggestion: { displayName: string } }) {
    return data.suggestion.displayName ? data.suggestion.displayName : '';
  }

  private _renderSuggestion(suggestion: IUser): JSX.Element {
    return (
      <div className="user-autosuggest-entry">
        <img src={suggestion.avatarUrl} />
        <span className="user-name">{suggestion.displayName}</span>
      </div>
    );
  }

  _onUserStoreChange(data: UserStoreEvent) {
    if (data.users) {
      this.setState({ users: data.users });
    }
  }

  _handleFilterChange(filter: Partial<DocFilter>) {
    const filterState = { ...this.state.filter, ...filter };
    UserStore.clear();
    this.setState({ filter: filterState }, () => {
      DocumentsStore.updateDocsFilter(this.state.filter);
      this._executeSearch();
    });
  }

  _handleTitleChange(field: string, value: string) {
    if (this.state.filter[field] !== value) {
      const filterState = _.cloneDeep(this.state.filter);
      filterState[field] = value;
      this.setState({ filter: filterState });
    }
  }

  _getField(fieldName: string, defaultValue: string) {
    if (this.state.filter[fieldName]) {
      if (fieldName === 'projectType') {
        const selectedField = this.state.filter.projectType!;
        if (selectedField !== defaultValue) {
          const isAvailableProjectType = this.state.filterProjectTypes && this.state.filterProjectTypes.indexOf(selectedField) !== -1;
          if (!isAvailableProjectType) {
            this._handleFilterChange({ projectType: defaultValue });
            return defaultValue;
          }
        }
      }
      return this.state.filter[fieldName];
    } else {
      return defaultValue;
    }
  }

  _executeSearch() {
    if (this.props.onRetrieveDocs) {
      const filterState: DocFilter = _.cloneDeep(this.state.filter as DocFilter);

      if (this._filterField!.getValue()) {
        filterState.title = this._filterField!.getValue();
      }

      if (this._refField!.getValue()) {
        filterState.reference = this._refField!.getValue();
      }

      this.setState({ filter: filterState }, () => {
        DocumentsStore.updateDocsFilter(this.state.filter, this.props.onRetrieveDocs);
      });
    }
  }

  _resetFilter() {
    DocumentsStore.updateDocsFilter({}, () => {
      if (this.props.onRetrieveDocs) {
        this.props.onRetrieveDocs();
      }
    });
    this.setState({ filter: DocumentsStore.getDocsFilter() });
  }

  _openDatePicker() {
    if (this.refs.datePicker) {
      (this.refs.datePicker as DatePicker).openDialog();
    }
  }

  _onChangeDP(date: Date) {
    // Small hack to make date in utc
    const m = moment(date);
    const dateWithTimezoneOffset = moment(m).utc().add(m.utcOffset(), 'm');

    const startOfDay = moment(dateWithTimezoneOffset).startOf('day');
    this._handleFilterChange({ modifiedDate: startOfDay.toDate() });
  }

  _getDateFilterText() {
    if (this.state.filter && this.state.filter.modifiedDate) {
      return dateUtil(this.state.filter.modifiedDate).formatDate();
    } else {
      return 'Modified';
    }
  }

  _handleKeyDown(e: React.KeyboardEvent<{}>) {
    if (e.key === 'Enter') {
      this._executeSearch();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  _getProjectTypeMenuItems() {
    const projectTypeMenuItems = [<MenuItem key="All" value={'All'} primaryText={'All'} />];
    if (this.state.projectTypes && this.state.filterProjectTypes) {
      this.state.projectTypes
        .filter((it) => this.state.filterProjectTypes!.indexOf(it.id) !== -1)
        .forEach((projectType) => {
          projectTypeMenuItems.push(
            <MenuItem key={projectType.id + projectType.name} value={projectType.id} primaryText={projectType.name} />
          );
        });
    }
    return projectTypeMenuItems;
  }

  render() {
    const projectTypeMenuItems = this._getProjectTypeMenuItems();

    return (
      <table className="table table-hover table-mc-light-blue selection-table documents-filter">
        <thead>
          <tr>
            <th />
            <th>
              <TextField
                className="documents-filter-field documents-filter-material-components"
                hintText="Filter Title"
                id="filter-title-input"
                value={this.state.filter.title ? this.state.filter.title : ''}
                onChange={(e, key) => this._handleTitleChange('title', (e.target as HTMLInputElement).value)}
                onKeyDown={(e) => this._handleKeyDown(e)}
                ref={(e) => (this._filterField = e)}
              />
            </th>
            <th>
              <SelectField
                className="documents-filter-field documents-filter-field-dropdown documents-filter-material-components"
                floatingLabelText=""
                id="documents-filter-field"
                style={{ lineHeight: '14px' }}
                labelStyle={{ lineHeight: '25px', paddingRight: '0px', top: '12px' }}
                value={this._getField('projectType', 'All')}
                onChange={(e, key, payload) => this._handleFilterChange({ projectType: payload })}
              >
                {projectTypeMenuItems}
              </SelectField>
            </th>
            <th>
              <TextField
                className="documents-filter-field documents-filter-material-components"
                hintText="Reference"
                id="reference-input"
                value={this.state.filter.reference ? this.state.filter.reference : ''}
                onChange={(e, key) => this._handleTitleChange('reference', (e.target as HTMLInputElement).value)}
                ref={(e) => (this._refField = e)}
                onKeyDown={(e) => this._handleKeyDown(e)}
              />
            </th>
            <th>
              <div className="documents-filter-field documents-filter-field-dropdown" style={{ lineHeight: '14px' }}>
                <UserAutoSuggest
                  placeholder="Owner (type to find)"
                  users={this.state.users}
                  displayName={this._getField('username', '')}
                  getSuggestions={(value) => this._getSuggestions(value)}
                  getInputValue={(data) => this._getUserInputValue(data)}
                  renderSuggestion={(suggestion) => this._renderSuggestion(suggestion)}
                  reset={this.state.filter.username ? false : true}
                  onClear={() => {
                    this._handleFilterChange({ ownerUid: null, username: null });
                  }}
                  onSelected={(e, username) => {
                    this._handleFilterChange({ ownerUid: e.suggestion.uid, username: e.suggestion.username });
                  }}
                  value={this._getField('ownerUid', 'All')}
                />
              </div>
            </th>
            <th>
              <div className="documents-filter-field documents-filter-field-date" onClick={() => this._openDatePicker()}>
                <div className="documents-filter-field-date-label">{this._getDateFilterText()}</div>
                <IconButton
                  onClick={(e) => this._openDatePicker()}
                  iconClassName=""
                  style={{ zIndex: 0 }}
                  iconStyle={{ color: '#6f6f6f', fontSize: '16px', zIndex: 0 }}
                >
                  <FontIcon className="material-icons documents-filter-field-date-icon">date_range</FontIcon>
                </IconButton>

                <DatePicker
                  ref="datePicker"
                  id="documents-filter-field-date-date-picker"
                  textFieldStyle={{ display: 'none' }}
                  onChange={(e, date) => this._onChangeDP(date)}
                />
              </div>
            </th>
            <th colSpan={2}>
              <Checkbox
                label="Workflow Pending"
                labelPosition="left"
                checked={this.state.filter.pendingForApproval || false}
                labelStyle={{ fontWeight: 'normal' }}
                iconStyle={{ color: '#08adde' }}
                onClick={(e) => {
                  this.setState({ filter: { ...this.state.filter, pendingForApproval: !this.state.filter.pendingForApproval } }, () =>
                    this._executeSearch()
                  );
                }}
                style={{
                  display: 'inline-block',
                  whiteSpace: 'normal',
                  verticalAlign: 'middle',
                  width: ''
                }}
                className="documents-filter-field-workflow-checkbox"
              />
              <div className="field-divider" />
              <FlatButton
                label="Reset"
                labelPosition="after"
                primary={false}
                onClick={(e) => this._resetFilter()}
                backgroundColor=""
                style={{
                  minWidth: '',
                  verticalAlign: 'middle',
                  zIndex: 0
                }}
                labelStyle={{ padding: '0 12px', fontSize: '1rem', lineHeight: '38px' }}
              />
              <RaisedButton
                className="documents-filter-button"
                label="Search"
                labelPosition="after"
                onClick={(e) => this._executeSearch()}
                backgroundColor="#08adde"
                style={{
                  minWidth: '',
                  verticalAlign: 'middle',
                  display: 'inline-flex'
                }}
                buttonStyle={{ color: 'white', zIndex: 0 }}
                labelStyle={{ padding: '0 12px', fontSize: '1rem', lineHeight: '38px' }}
                labelColor="white"
              />
            </th>
          </tr>
        </thead>
      </table>
    );
  }
}
