import * as React from 'react';
import { AdditionalFormActions, CancelButton, SaveButton, MaxLengthExceededMsg } from './index';
import { ICommentFormButtonsProps } from './ReplyButtons';

const CommentButtons = (props: ICommentFormButtonsProps) => {
  return (
    <div className="comment-post-actions">
      <div className="comment-post-actions-inner">
        <div className="padding-top-s">
          <AdditionalFormActions {...props} />
          {props.maxLengthExceeded && <MaxLengthExceededMsg maxLength={props.maxLength} />}
        </div>
        <div>
          <CancelButton onCancel={() => props.onCancel()} cid="comment-form-cancel" />
          <SaveButton onCreate={() => props.onCreate()} cid="comment-form-create" />
        </div>
      </div>
    </div>
  );
};

export default CommentButtons;
