import { IDistributionGroup, IDistributionGroupTree } from 'mm-types';
import { Cancelled, mm } from './base-clients';

export async function getDistributionGroups(): Promise<IDistributionGroup[] | Cancelled> {
  const response = await mm.get<{ distributionGroups: IDistributionGroup[] }>(`/distributionGroups`);
  const groups: IDistributionGroup[] = response.data.distributionGroups || [];

  groups.sort(function ({ name: groupAName = '' }, { name: groupBName = '' }) {
    if (groupAName < groupBName) {
      return -1;
    }
    if (groupAName > groupBName) {
      return 1;
    }

    return 0;
  });

  groups.forEach((group) => {
    const parts = group.name.split('/');
    group.parent = parts[0];
    group.path = parts.slice(1).join('/');
  });

  return groups;
}

function getQueryParams() {
  const query: string[] = [];
  query.push('showDeleted=false');
  query.push('showGlobal=false');
  query.push('showSpecialInterest=false');
  query.push('type=normal');
  return query;
}

const queryParams: string = getQueryParams().join('&');

export async function getDistributionGroupsTree(): Promise<IDistributionGroupTree[] | Cancelled> {
  const response = await mm.get<{ children: IDistributionGroupTree[] }>(`/distributionGroups/tree?${queryParams}`);
  return response.data.children || [];
}

export async function getDistributionGroupsTreeByAccount(accountUid: string | undefined): Promise<IDistributionGroupTree[] | Cancelled> {
  const response = await mm.get<{ children: IDistributionGroupTree[] }>(`/distributionGroups/tree?accountUid=${accountUid}&${queryParams}`);
  return response.data.children || [];
}
