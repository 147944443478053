import * as React from 'react';
import { useRef, useState } from 'react';
import * as _ from 'lodash';
import LinkModal from '../links/LinkModal';
import { FlatButton, IconButton } from 'material-ui';
import { Hotspot } from 'mm-types';

import * as ObjectUtil from '../../../utils/ObjectUtil';
import Dialog from 'material-ui/Dialog';

export type Props = {
  onClose: () => void;
  onSave: (hotspotLinks: any) => void;
  src: string | undefined;
  hotspots: Hotspot[];
};

export type State = {
  isResizing: boolean;
  hotspots: (Hotspot | null)[];
  currentHotspot: Hotspot | null;
  editingHotspotIndex: number;
  isEditing: boolean;
  showLinkModal: boolean;
  imgWidth: number;
  imgHeight: number;
  showAlert?: null | {
    props: {
      actions: JSX.Element[];
      title: string;
      modal?: null | boolean;
      onRequestClose: () => void;
      contentClassName?: string;
    };
    message: string | JSX.Element;
  };
};

const HotspotsModal = (props: Props) => {
  const [state, setState] = useState<State>({
    isResizing: false,
    isEditing: false,
    currentHotspot: null,
    editingHotspotIndex: -1,
    hotspots: _.cloneDeep(props.hotspots), // deep clone of props hotspots
    showLinkModal: false,
    imgWidth: 0,
    imgHeight: 0,
    showAlert: null
  });

  const imageRef = useRef<HTMLImageElement | null>(null);

  const onMouseDown = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    // handle only when event happens over:
    // - a hotspot
    // - or an img element
    const srcEl: EventTarget & HTMLImageElement = e.currentTarget;
    if (srcEl && srcEl.classList && !srcEl.classList.contains('hotspot') && !(srcEl.nodeName === 'IMG')) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    // don't create hotspot when click is on the hotspot's menu
    if (srcEl.classList?.contains('hotspot-menu-button')) {
      return;
    }

    const { positionXPercent, positionYPercent } = calcPosition(e.clientX, e.clientY, imageRef.current);

    if (!isWithinImage(positionXPercent, positionYPercent)) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      currentHotspot: {
        nid: null,
        left: positionXPercent,
        top: positionYPercent,
        width: 0,
        height: 0,
        linkData: {}
      },
      isResizing: true
    }));
  };

  const isWithinImage = (x: number, y: number) => {
    return x >= 0 && x <= 100 && y >= 0 && y <= 100;
  };

  const calcPosition = (clickX: number, clickY: number, parent: any) => {
    if (!parent) {
      return { positionXPercent: 0, positionYPercent: 0 };
    }

    const rect = parent.getBoundingClientRect();
    const x = clickX - rect.left; // x position within the element.
    const y = clickY - rect.top; // y position within the element.

    const positionXPercent: number = (x / parent.width) * 100;
    const positionYPercent: number = (y / parent.height) * 100;

    return {
      positionXPercent: positionXPercent,
      positionYPercent: positionYPercent
    };
  };

  const onMouseUp = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    // handle only when event happens over:
    // - a hotspot
    // - or an img element
    const srcEl: EventTarget & HTMLImageElement = e.currentTarget;
    if (srcEl && srcEl!.classList && !srcEl.classList.contains('hotspot') && !(srcEl.nodeName === 'IMG')) {
      setState((prevState) => ({ ...prevState, currentHotspot: null, isResizing: false }));
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (state.isResizing && state.currentHotspot) {
      const { positionXPercent, positionYPercent } = calcPosition(e.clientX, e.clientY, imageRef.current);

      // only handle hotspot creating when within an image
      if (!isWithinImage(positionXPercent, positionYPercent)) {
        setState((prevState) => ({ ...prevState, currentHotspot: null, isResizing: false }));
      }

      // assure min width & height (40px by 40px)
      const minWidth = (40 / state.imgWidth) * 100;
      const minHeight = (40 / state.imgHeight) * 100;

      if (positionXPercent - state.currentHotspot?.left < minWidth) {
        state.currentHotspot.width = minWidth;
      }

      if (positionYPercent - state.currentHotspot?.top < minHeight) {
        state.currentHotspot.height = minHeight;
      }

      // when assuring min width & height make sure hotspot is within the image boundaries
      if (
        state.currentHotspot?.left + state.currentHotspot?.width <= 100 &&
        state.currentHotspot?.top + state.currentHotspot?.height <= 100
      ) {
        setState((prevState) => ({ ...prevState, isResizing: false, showLinkModal: true }));
      }
      // don't add the hotspot if it extends outside of the image (bottom right corner within 100% of the image)
      else {
        setState((prevState) => ({ ...prevState, currentHotspot: null, isResizing: false }));
      }
    } else {
      setState((prevState) => ({ ...prevState, currentHotspot: null, isResizing: false }));
    }
  };

  const onMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (state.isResizing) {
      const { positionXPercent, positionYPercent } = calcPosition(e.clientX, e.clientY, imageRef.current);

      if (!isWithinImage(positionXPercent, positionYPercent)) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        currentHotspot: {
          nid: state.currentHotspot!.nid,
          left: state.currentHotspot!.left,
          top: state.currentHotspot!.top,
          width: positionXPercent - state.currentHotspot!.left,
          height: positionYPercent - state.currentHotspot!.top,
          linkData: state.currentHotspot!.linkData
        },
        isResizing: true
      }));
    }
  };

  const updateDimensions = () => {
    setState((prevState) => ({ ...prevState, imgWidth: imageRef.current?.width ?? 0, imgHeight: imageRef.current?.height ?? 0 }));
  };

  const getHotspotMenuStyle = (hotspot: Hotspot | null) => {
    const result: { top: number; left?: number } = {
      top: -34
    };

    if (state.imgWidth && state.imgHeight) {
      const hotspotTop: number = state.imgHeight * (hotspot!.top / 100);

      // move hotspot's menu below if above not enough space (3 is for an adjustment)
      if (hotspotTop < 34) {
        result.top = state.imgHeight * (hotspot!.height / 100) - 3;
      }

      const hotspotLeft: number = state.imgWidth * (hotspot!.left / 100);

      if (hotspotLeft < 85) {
        // shift hotspot's menu by an appropriate amount of pixels from right if not enough space on the right
        const hotspotWidth: number = state.imgWidth * (hotspot!.width / 100);
        result.left = 85 - (hotspotWidth - 40);
      }
    }

    return result;
  };

  const editHotspot = (e: React.MouseEvent<{}>, hotspotIndex: number) => {
    e.preventDefault();
    e.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      currentHotspot: state.hotspots[hotspotIndex],
      editingHotspotIndex: hotspotIndex,
      isEditing: true,
      showLinkModal: true
    }));
  };

  const deleteHotspot = (e: React.MouseEvent<{}>, hotspotIndex: number) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({ ...prevState, hotspots: [...state.hotspots.filter((val, index) => index !== hotspotIndex)] }));
  };

  const moveToFrontHotspot = (e: React.MouseEvent<{}>, hotspotIndex: number) => {
    e.preventDefault();
    e.stopPropagation();

    const toMove: (Hotspot | null)[] = state.hotspots.splice(hotspotIndex, 1);
    setState((prevState) => ({ ...prevState, hotspots: [...state.hotspots, ...toMove] }));
  };

  const moveToBackHotspot = (e: React.MouseEvent<{}>, hotspotIndex: number) => {
    e.preventDefault();
    e.stopPropagation();

    const toMove: (Hotspot | null)[] = state.hotspots.splice(hotspotIndex, 1);
    setState((prevState) => ({ ...prevState, hotspots: [...toMove, ...state.hotspots] }));
  };

  const onCancel = (e: React.MouseEvent<{}>) => {
    e.preventDefault();
    e.stopPropagation();

    if (areUnsavedChanges()) {
      // show dialog
      setState((prevState) => ({
        ...prevState,
        showAlert: {
          props: {
            actions: [
              <FlatButton
                key={1}
                label="Cancel"
                id="cancelHotspotModal"
                onClick={() => {
                  setState((prevState) => ({ ...prevState, showAlert: null }));
                }}
              />,
              <FlatButton
                key={2}
                label="Discard"
                id="discardHotspotModal"
                onClick={() => {
                  setState((prevState) => ({ ...prevState, showAlert: null }));
                  props.onClose();
                }}
              />
            ],
            title: 'Unsaved Changes',
            onRequestClose: () => {},
            contentClassName: 'hotspot-modal-actions'
          },

          message: 'Your changes have not been saved. Do you want to discard changes?'
        }
      }));
    } else {
      props.onClose();
    }
  };

  const onSave = (e: React.MouseEvent<{}>) => {
    e.preventDefault();
    e.stopPropagation();

    props.onSave(state.hotspots);
  };

  const closeLinkModal = () => {
    setState((prevState) => ({ ...prevState, showLinkModal: false, currentHotspot: null, editingHotspotIndex: -1, isEditing: false }));
  };

  const onSaveLinkModal = (linkData: any) => {
    const stateToUpdate: State = {
      currentHotspot: null,
      editingHotspotIndex: -1,
      showLinkModal: false,
      isEditing: false,
      isResizing: false,
      hotspots: state.hotspots,
      imgHeight: state.imgHeight,
      imgWidth: state.imgWidth,
      showAlert: null
    };

    // make sure newly created hotspot link is not a broken link
    linkData['data-arc-link-stale'] = null;

    const currentHotspot = state.currentHotspot;
    // round position of the hotspot
    if (currentHotspot && state.currentHotspot) {
      currentHotspot.left = Math.round(state.currentHotspot.left * 10) / 10;
      currentHotspot.top = Math.round(state.currentHotspot.top * 10) / 10;
      currentHotspot.width = Math.round(state.currentHotspot.width * 10) / 10;
      currentHotspot.height = Math.round(state.currentHotspot.height * 10) / 10;
    }

    // saving a new hotspot
    if (currentHotspot && state.editingHotspotIndex === -1) {
      currentHotspot.linkData = linkData;
      stateToUpdate.hotspots = state.isEditing ? [...state.hotspots] : [...state.hotspots, currentHotspot];
    }
    // update exiting hotspot's link data
    else if (state.editingHotspotIndex >= 0) {
      stateToUpdate.hotspots[state.editingHotspotIndex] = Object.assign({}, currentHotspot, {
        linkData: linkData
      });
    }

    setState(stateToUpdate);
  };

  const areUnsavedChanges = () => {
    if (state.hotspots.length !== props.hotspots.length) {
      return true;
    }

    for (let i = 0; i < state.hotspots.length; i++) {
      if (ObjectUtil.sortedStringify(state.hotspots[i]!.linkData) !== ObjectUtil.sortedStringify(props.hotspots[i]!.linkData)) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className={'hotspots-container-outer'}>
      <div className={'hotspots-container-inner'}>
        <div className={'hotspots-header'}>
          <div className={'hotspots-header-main'}>Hotspots</div>
          <div className={'hotspots-header-sub'}>Click and drag over graphic to create hotspot</div>
        </div>

        <div className={'hotspots-content'}>
          <div style={{ position: 'relative', display: 'inline-block', margin: 'auto' }}>
            <img
              ref={imageRef}
              style={{ display: 'inline-block' }}
              onLoad={() => {
                updateDimensions();
              }}
              src={props.src}
              onMouseDown={(e) => onMouseDown(e)}
              onMouseUp={(e) => onMouseUp(e)}
              onMouseMove={(e) => onMouseMove(e)}
            />

            {state.hotspots.map((hotspot: Hotspot | null, index: number) => {
              const isStale: boolean = hotspot!.linkData!['data-arc-link-stale'] !== null;

              return (
                <div
                  className={'hotspot ' + (isStale ? 'stale' : '')}
                  key={index}
                  style={{
                    position: 'absolute',
                    top: hotspot!.top + '%',
                    left: hotspot!.left + '%',
                    width: hotspot!.width + '%',
                    height: hotspot!.height + '%',
                    backgroundColor: isStale ? 'rgba(255, 76, 78, 0.5)' : 'rgb(55, 187, 255, 0.375)'
                  }}
                >
                  <div className={'hotspot-menu'} style={getHotspotMenuStyle(hotspot)}>
                    <IconButton
                      className={'hotspot-menu-edit'}
                      onClick={(e) => editHotspot(e, index)}
                      iconClassName="material-icons"
                      tooltipPosition="top-center"
                      tooltipStyles={{ zIndex: 9999, top: '-10px' }}
                      tooltip="Edit"
                    >
                      edit
                    </IconButton>

                    <span className={'hotspot-menu-button-divider'} />

                    <IconButton
                      className={'hotspot-menu-delete'}
                      onClick={(e) => deleteHotspot(e, index)}
                      iconClassName="material-icons"
                      tooltipPosition="top-center"
                      tooltipStyles={{ zIndex: 9999, top: '-10px' }}
                      tooltip="Delete"
                    >
                      delete
                    </IconButton>

                    <span className={'hotspot-menu-button-divider'} />

                    <IconButton
                      className={'hotspot-menu-send-to-back'}
                      onClick={(e) => moveToBackHotspot(e, index)}
                      iconClassName="material-icons"
                      tooltipPosition="top-center"
                      tooltipStyles={{ zIndex: 9999, top: '-10px' }}
                      tooltip={(window as any).runningGebs ? null : 'Send to Back'}
                    >
                      flip_to_back
                    </IconButton>

                    <span className={'hotspot-menu-button-divider'} />

                    <IconButton
                      className={'hotspot-menu-bring-to-front'}
                      onClick={(e) => moveToFrontHotspot(e, index)}
                      iconClassName="material-icons"
                      tooltipPosition="top-center"
                      tooltipStyles={{ zIndex: 9999, top: '-10px' }}
                      tooltip={(window as any).runningGebs ? null : 'Bring to Front'}
                    >
                      flip_to_front
                    </IconButton>
                  </div>
                </div>
              );
            })}

            {state.currentHotspot && !state.isEditing && (
              <div
                className={'hotspot'}
                style={{
                  position: 'absolute',
                  top: state.currentHotspot.top + '%',
                  left: state.currentHotspot.left + '%',
                  width: state.currentHotspot.width + '%',
                  height: state.currentHotspot.height + '%',
                  backgroundColor: 'rgb(55, 187, 255, 0.375)'
                }}
              ></div>
            )}
          </div>
        </div>

        <div className={'hotspots-footer'}>
          <FlatButton id={'hotspots-cancel-button'} label="Cancel" primary={false} onClick={(e) => onCancel(e)} />
          <FlatButton id={'hotspots-save-button'} label="Save" primary={false} keyboardFocused={true} onClick={(e) => onSave(e)} />
        </div>
      </div>

      {state.showLinkModal ? (
        <LinkModal
          onClose={() => closeLinkModal()}
          onSave={(linkData) => onSaveLinkModal(linkData)}
          linkData={state.currentHotspot?.linkData}
          isHotspot={true}
        />
      ) : undefined}

      {state.showAlert ? (
        <Dialog {...Object.assign({ actions: [], open: true, modal: true }, state.showAlert.props)} style={{ zIndex: 999 }}>
          {state.showAlert.message}
        </Dialog>
      ) : undefined}
    </div>
  );
};

export default HotspotsModal;
