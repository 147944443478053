import { IElementDefinition, IUnit, IUnitDefinition } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../../flux/editor/EditorStore';
import _ from 'lodash';
import { InsertAction } from '../content/ContentMenuContainer';
import { getSelectedClosestTocable } from '../../../../hoc/common';

export type DtdValidationInsertInfo = { childrenDefinitionIds: string[]; insertIndex: number; dtdValidationString: string };

export function dtdAllowedUnits(currentUnit: IUnit): string[] | null {
  const currentUnitTocDefId = getSelectedClosestTocable(currentUnit)?.definitionId;
  if (!currentUnitTocDefId) {
    return null;
  }

  const unitInsertInfo = getUnitInsertInfo(currentUnit, currentUnitTocDefId as string);
  if (unitInsertInfo === null) {
    return null;
  }

  let allowedUnits = validateAllowableInserts(unitInsertInfo);

  allowedUnits = modifyAllowedElementsIfNeededDueToFwsPage(currentUnit, allowedUnits);

  return allowedUnits;
}

/** We have one scenario with abnormal proc where basically we can only have one fws page at the end,
 * So if section is abnormal proc, and we don't have an fwspage at the end of the section, and it isnt' in allowed elements, add it.
 * But also if the current element is an fws page disallow all elements.
 * */
function modifyAllowedElementsIfNeededDueToFwsPage(currentUnit: IUnit, allowedElements: string[]): string[] {
  const currentUnitParentSectionId = ProjectDefinitionStore.getParentWithNestedDefinitionsDefId(
    currentUnit,
    ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition.unitDefinitions
  );
  if (
    currentUnitParentSectionId === 'abnormalproc' &&
    allowedElements.findIndex((elm) => elm === 'fwspage') === -1 &&
    currentUnit.definitionId !== 'fwspage'
  ) {
    const docUnits = EditorStore.getDocUnits();
    let nextSectionIndex = docUnits.findIndex((unit) => unit.level === 'section' && unit.index > currentUnit.index);
    // no next section
    if (nextSectionIndex === -1) {
      nextSectionIndex = docUnits.length - 1;
    }

    let lastVisibleUnit: IUnit | null = null;
    for (let i = nextSectionIndex - 1; i >= currentUnit.index; i--) {
      if (docUnits[i].definitionId !== 'removed' && docUnits[i].definitionId !== 'ghost') {
        lastVisibleUnit = docUnits[i];
        break;
      }
    }

    if (lastVisibleUnit?.definitionId !== 'fwspage' && lastVisibleUnit?.index === currentUnit.index) {
      allowedElements.push('fwspage');
    }
  } else if (currentUnit.definitionId === 'fwspage') {
    allowedElements = [];
  }
  return allowedElements;
}

export function dtdAllowedElements(insertPosition: InsertAction, nestedInfo?: INestedUnitFocusChangeEvent): string[] | null {
  if (nestedInfo) {
    const insertInfo = getElementInsertInfo(insertPosition, nestedInfo);
    if (!insertInfo) {
      return null;
    }
    const allowedElements = validateAllowableInserts(insertInfo);
    return allowedElements;
  } else {
    return null;
  }
}

function validateAllowableInserts({ childrenDefinitionIds, insertIndex, dtdValidationString }: DtdValidationInsertInfo) {
  const regex = new RegExp(dtdValidationString, 'g');
  const allowedInserts: string[] = getListOfAllowedInserts(dtdValidationString);

  // For each allowed unit, insert after current unit and check if that regex is allowed
  const dtdValidatedInsertables = allowedInserts.map((insertable) => {
    // nothing in current insertable so allow everything
    if (childrenDefinitionIds.length === 0) {
      return insertable;
    }
    if (isStructureValidDtd(childrenDefinitionIds, regex, insertIndex, insertable)) {
      return insertable;
    } else {
      return undefined;
    }
  });
  const filteredDtdValidatedInsertables = dtdValidatedInsertables.filter((e) => e !== undefined) as string[];
  // If structure valid return filtered inserts (this can be empty array) or if not dtd valid but we have valid inserts return inserts
  if (
    isStructureValidDtd(childrenDefinitionIds, regex) ||
    (!isStructureValidDtd(childrenDefinitionIds, regex) && filteredDtdValidatedInsertables.length > 0)
  ) {
    return filteredDtdValidatedInsertables;
  } else {
    return allowedInserts;
  }
}

export function getUnitInsertInfo(currentUnit: IUnit, currentUnitTocId: string): DtdValidationInsertInfo | null {
  const parent: IUnitDefinition | undefined = ProjectDefinitionStore.getProjectDefinedUnitById(currentUnitTocId);
  if (!parent?.dtdChildUnitValidation) {
    return null;
  }

  const { unitsInTocIds, currentUnitIndex } = getUnitsInCurrentTocByIdAndCurrentUnitIndex(currentUnit);

  return { childrenDefinitionIds: unitsInTocIds, insertIndex: currentUnitIndex, dtdValidationString: parent.dtdChildUnitValidation };
}

/*  Depending on insert position we get different dtd rules
    if insert inside focused element get element and child elements, insert index in list of child elements is zero
    if above or below get parent element and siblings and current element index in list of siblings
    */
export function getElementInsertInfo(
  insertPosition: InsertAction,
  nestedInfo: INestedUnitFocusChangeEvent
): DtdValidationInsertInfo | null {
  if (insertPosition === 'insert_inside') {
    const dtdValidationString = (nestedInfo.nestedTree[nestedInfo.nestedTree.length - 1]?.definition as IElementDefinition)
      ?.dtdChildElementValidation;
    if (dtdValidationString) {
      // if inserting inside the focused element might not have a direct child has a data element definition id so find that first child, get it's siblings
      const currentFocusedElementsChildrenWithDataElementDefinitionIDsImmediateParent:
        | HTMLElement
        | null
        | undefined = nestedInfo.focused.unitElement.querySelector('[data-element-definition-id]')?.parentElement;

      if (currentFocusedElementsChildrenWithDataElementDefinitionIDsImmediateParent) {
        const currentElementChildren: Element[] = Array.from(
          currentFocusedElementsChildrenWithDataElementDefinitionIDsImmediateParent.children
        );
        const currentElementChildrenDefIds: string[] = currentElementChildren
          .map((elm) => elm.attributes['data-element-definition-id']?.value)
          .filter((elm) => elm !== undefined);
        const editor = EditorStore.getEditor().getActiveEditorInstance();
        let currentCursorNode = editor?.selection.getNode();
        // current cursor is somewhere in current selection
        if (currentCursorNode === nestedInfo.focused.unitElement) {
          currentCursorNode = editor?.selection.getRng(true).startContainer.previousSibling as Element;
        }
        const insertIndex = currentCursorNode ? currentElementChildren.indexOf(currentCursorNode) : -1;
        return { childrenDefinitionIds: currentElementChildrenDefIds, insertIndex, dtdValidationString };
      }
    }
  } else if (insertPosition === 'insert_after' || insertPosition === 'insert_before') {
    const immediateParentInNestedTree =
      nestedInfo.nestedTree.length > 1 ? nestedInfo.nestedTree[nestedInfo.nestedTree.length - 2] : nestedInfo.nestedTree[0];
    const dtdValidationString = (immediateParentInNestedTree.definition as IElementDefinition).dtdChildElementValidation;
    if (dtdValidationString && nestedInfo.focused.targetElement.parentElement?.children) {
      const currentElementChildren: Element[] = Array.from(nestedInfo.focused.targetElement.parentElement?.children);
      const currentElementChildrenDefIds: string[] = currentElementChildren
        .map((elm) => elm.attributes['data-element-definition-id']?.value)
        .filter((elm) => elm !== undefined);
      const insertIndex = currentElementChildren.indexOf(nestedInfo.focused.targetElement);
      return {
        childrenDefinitionIds: currentElementChildrenDefIds,
        insertIndex: insertPosition === 'insert_before' ? insertIndex - 1 : insertIndex,
        dtdValidationString
      };
    }
  }
  return null;
}

function getUnitsInCurrentTocByIdAndCurrentUnitIndex(currentUnit: IUnit): { unitsInTocIds: string[]; currentUnitIndex: number } {
  const docUnits = EditorStore.getDocUnits();
  let unitsInToc = getCurrentTocUnitsAndCurrentUnitIndex(docUnits, currentUnit.index);
  unitsInToc = unitsInToc.filter((u) => u.definitionId !== 'removed').filter((u) => u.definitionId !== 'ghost');
  const currentUnitIndex = unitsInToc.findIndex((u) => u.uid === currentUnit.uid);
  const unitsInTocIds = unitsInToc.map((u) => u.definitionId);
  return { unitsInTocIds, currentUnitIndex };
}

/**
 * Basically go from toc to next doc based on if the current unit index is in that toc and get the doc units in that doc.
 * As we are working from total doc units each time the current unit index is after the next toc, remove all the units before that, update current unit index to be relative to new doc units length
 * unit we have the units in the current toc that contains the selected unit
 * */
function getCurrentTocUnitsAndCurrentUnitIndex(docUnits: IUnit[], currentUnitIndex: number): IUnit[] {
  let nextTocIndex = docUnits.findIndex((u) => u.istocable);

  const currentUnitIndexIsAfterNextToc = currentUnitIndex > nextTocIndex && nextTocIndex !== -1;
  const currentUnitIsBeforeNextToc = currentUnitIndex < nextTocIndex && nextTocIndex !== -1;
  const currentUnitIsToc = currentUnitIndex === nextTocIndex && nextTocIndex !== -1;

  // current unit index is after next toc, just remove anything before next toc as not needed and repeat
  if (currentUnitIndexIsAfterNextToc) {
    const reducedUnits = docUnits.slice(nextTocIndex + 1);
    return getCurrentTocUnitsAndCurrentUnitIndex(reducedUnits, currentUnitIndex - (nextTocIndex + 1));
  } else if (currentUnitIsBeforeNextToc) {
    return docUnits.slice(0, nextTocIndex);
  } else if (currentUnitIsToc) {
    // currently is toc, remove it and find next
    const reducedUnits = docUnits.slice(nextTocIndex + 1);
    nextTocIndex = reducedUnits.findIndex((u) => u.istocable);
    if (nextTocIndex === -1) {
      return reducedUnits;
    } else {
      return reducedUnits.slice(0, nextTocIndex);
    }
  } else {
    return docUnits;
  }
}

function getListOfAllowedInserts(dtd: string) {
  return dtd.replace(/[^a-z^A-z-,1]/g, '').split(',');
}

function isStructureValidDtd(defs: string[], dtd: RegExp, insertIndex = 0, insertable?: string): boolean {
  let predictedStructure: string[] | string = _.clone(defs);
  if (!!insertable) {
    (predictedStructure as string[]).splice(insertIndex === -1 ? 0 : insertIndex + 1, 0, insertable);
  }
  predictedStructure = (predictedStructure as string[]).join(',') + ',';
  const matches = predictedStructure.match(dtd);
  return !!matches && matches.indexOf(predictedStructure) !== -1;
}
