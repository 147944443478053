import { mm } from './base-clients';
import { IUnitSpecialInterestTag, ISpecialInterestGroup } from 'mm-types';

export type SpecialInterestUpdateModel = {
  [id: string]: {
    add?: Partial<IUnitSpecialInterestTag>[];
    remove?: Partial<IUnitSpecialInterestTag>[];
  };
};

export type SpecialInterestMapModel = {
  [id: string]: {
    specialInterestGroups: IUnitSpecialInterestTag[];
  };
};

export async function getSpecialInterestUnitMap(projectUid: string, indexUid: string) {
  const response = await mm.get<{ units: SpecialInterestMapModel }>(`/projects/${projectUid}/indexes/${indexUid}/specialInterestGroups`);
  return response.data.units;
}

export async function getSpecialInterests() {
  const response = await mm.get<{ groups: ISpecialInterestGroup[] }>(`/specialInterestGroups`);
  return response.data.groups;
}

export async function addRemove(projectUid: string, indexUid: string, groups: SpecialInterestUpdateModel) {
  const response = await mm.post<string>(`/projects/${projectUid}/indexes/${indexUid}/specialInterestGroups`, {
    units: groups
  });
  return response.data;
}

export function settingDefaults() {
  return {
    uid: '',
    name: ''
  } as Partial<ISpecialInterestGroup>;
}
