import { IProjectActivity } from 'mm-types';
import * as React from 'react';

type PropsType = {
  entry: IProjectActivity;
};

export const DocTeamDetails = ({ entry }: PropsType) => {
  if (entry.projectActivityType === 'DOC_TEAM_UPDATE') {
    return (
      <div>
        <label /> <span />
      </div>
    );
  }

  return (
    <div className="settings-details">
      {entry.description && (
        <div key="name">
          <label>Name:</label> <span>{entry.description}</span>
        </div>
      )}
      {entry.role && (
        <div key="role">
          <label>Role:</label> <span>{entry.role}</span>
        </div>
      )}
    </div>
  );
};
