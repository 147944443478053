import { SystemSnackbarActionTypes, HIDE_SYSTEM_SNACKBAR, SHOW_SYSTEM_SNACKBAR } from './types';
import { ISnackbarMessage } from 'mm-types';

// State
export type SnackBarState = {
  snackbar: ISnackbarMessage | null;
};

export const initialState: SnackBarState = {
  snackbar: null
};

export function snackbarReducer(state: SnackBarState = initialState, action: SystemSnackbarActionTypes): SnackBarState {
  let partialState: Partial<SnackBarState> | undefined;

  switch (action.type) {
    case SHOW_SYSTEM_SNACKBAR:
      partialState = {
        snackbar: action.payload
      };
      break;
    case HIDE_SYSTEM_SNACKBAR:
      partialState = {
        snackbar: null
      };
      break;
  }

  return { ...state, ...partialState };
}

export default snackbarReducer;
