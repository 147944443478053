import * as React from 'react';
import * as _ from 'lodash';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import Colors from '../../../utils/Colors';
import IconButton from 'material-ui/IconButton';

export type Props = {
  className?: string;
  popoverClassname?: string;
  disabled?: boolean;
  onSelect: (action: string) => void;
  selected?: string[];
  options?: { label: string; value: string; disabled?: boolean; icon?: string; style?: { [index: string]: string } }[];
  title?: string;
  minWidth?: number | string;
  children?: React.ReactNode;
};

export type State = {
  opened: boolean;
  anchorEl?: HTMLElement;
};

export default class ToolbarMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  static defaultProps: Partial<Props> = {
    disabled: false,
    selected: [],
    options: [],
    title: '',
    minWidth: 'auto'
  };

  handleTouchTap(event) {
    this.setState({
      opened: !this.state.opened,
      anchorEl: event.currentTarget
    });
  }

  handleRequestClose() {
    this.setState({
      opened: false
    });
  }

  _onChange(action: string) {
    this.props.onSelect(action);
    setTimeout(() => this.handleRequestClose(), 1);
  }

  _createMenuItem(text: string, action: string, disabled = false, actAsEnabled = false, icon: string | null = null, index = 0, _style?) {
    const isActionSelected = this.props.selected && this.props.selected.indexOf(action) > -1;
    const incomingStyles = _style || { fontSize: '12px' };
    let style = {};

    if (isActionSelected) {
      style = { color: Colors.headerBackground };
    }

    if (disabled && actAsEnabled) {
      style = _.extend(style, { color: 'rgba(0, 0, 0, 0.870588)' });
    }

    return (
      <MenuItem
        key={'menu-item-' + index}
        className={'menu-item-' + text.toLowerCase().replace(' ', '-') + (disabled ? ' disabled' : ' enabled')}
        leftIcon={
          icon ? (
            <IconButton style={{ padding: '0' }} disabled={disabled} iconClassName="material-icons">
              {icon}
            </IconButton>
          ) : undefined
        }
        primaryText={text}
        disabled={disabled}
        style={_.extend(style, incomingStyles)}
        onClick={() => this._onChange(action)}
      />
    );
  }

  _getToolbarMenu() {
    const { minWidth } = this.props;
    return (
      <div className={'menu-dropdown ' + this.props.className}>
        <div onClick={(e) => this.handleTouchTap(e)}>{this.props.children}</div>
        <Popover
          open={this.state.opened}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={() => this.handleRequestClose()}
          animated={false}
          className={this.props.popoverClassname}
        >
          <Menu width={minWidth}>
            {this.props.title ? this._createMenuItem(this.props.title, 'disabled', true, true, null, 99) : null}
            {this.props.title ? <Divider /> : null}
            {this.props.options!.map((option, index) => {
              return this._createMenuItem(option.label, option.value, option.disabled, false, option.icon, index, option.style);
            })}
          </Menu>
        </Popover>
      </div>
    );
  }

  render() {
    return this._getToolbarMenu();
  }
}
