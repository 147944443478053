import React from 'react';
import LinkDisplayInput from './LinkDisplayInput';
import { LinkModel } from './linkService';
import { Toggle } from 'material-ui';

export type Props = {
  model: Partial<LinkModel>;
  readOnly: () => boolean;
  onUpdateModel: (partial: Partial<LinkModel>) => void;
  toggleManaged: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  refs: React.RefObject<LinkDisplayInput>;
  showValidation: boolean;
  isHotspot?: boolean;
  isExternal?: boolean;
};

const CrossReferenceInputs = ({ model, readOnly, onUpdateModel, toggleManaged, refs, showValidation, isHotspot, isExternal }: Props) => {
  return (
    <>
      <div className="input">
        <LinkDisplayInput
          name={model.managed ? 'Link Display Prefix' : 'Link Display Text'}
          value={model.text!}
          ref={refs}
          readOnly={readOnly()}
          showValidation={isExternal ? showValidation : model.managed ? false : showValidation}
          onChange={(text) => onUpdateModel({ text: text })}
        />
      </div>
      <div className="input" style={{ width: '200px', marginTop: '40px' }}>
        <div className="allowClick" onClick={(e) => toggleManaged(e)}>
          <Toggle toggled={isHotspot ? false : model.managed} label="Managed Link" disabled={isHotspot} />
        </div>
      </div>
    </>
  );
};

export default CrossReferenceInputs;
