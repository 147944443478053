import * as React from 'react';
/* TYPES */
import { IUnitDefinition, IElementDefinition } from 'mm-types';

import ContentDropdown from './ContentDropdown';
import InsertPositionContainer from './InsertPositionContainer';
import { DefinitionToMenuPropsType } from '../MenuInsert';

interface Props {
  action: 'insert' | 'convert';
  disabled: boolean;
  definitions: Definition[];
  definitionToMenuItem: (definition: Definition, definitionToMenuProps: DefinitionToMenuPropsType) => JSX.Element;
  disableContentMenu: boolean;
  handleDropdownClick(e: { key: string }, definitionToMenuProps: DefinitionToMenuPropsType): void;
  syncOpenKeysStructure(openKeys: any): void;
  openKeysStructure: any[];
  isActivelyEditing: boolean;
  updateInsertPosition: (insertPosition: InsertAction) => void;
  currentAction: InsertAction;
}

export const getInsertAtLevel = (insertPosition: InsertAction) => {
  return insertPosition === 'insert_inside' ? 0 : 1;
};

export type InsertAction = 'insert_before' | 'insert_inside' | 'insert_after' | null;

interface State {
  isHover: boolean;
}

type Definition = IUnitDefinition | IElementDefinition;

class ContentMenuContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false
    };
  }

  hoverOn() {
    this.setState({
      isHover: true
    });
  }

  hoverOff() {
    this.setState({
      isHover: false
    });
  }

  render() {
    const insertPosition: InsertAction = this.props.currentAction;
    /* 0 - at current focused level, 1 - at parent level */
    const insertAtLevel: number = this.props.currentAction === 'insert_inside' ? 0 : 1;
    const definitionToMenuProps: Partial<DefinitionToMenuPropsType> = {
      insertPosition,
      insertAtLevel
    };

    return (
      <div className={'content-menu-container'}>
        <div>
          <InsertPositionContainer
            currentAction={this.props.currentAction}
            onActionUpdate={(newAction: InsertAction) => this.props.updateInsertPosition(newAction)}
            isActivelyEditing={this.props.isActivelyEditing}
          />
        </div>

        <div className="insert-content-menu" onMouseEnter={() => this.hoverOn()} onMouseLeave={() => this.hoverOff()}>
          <ContentDropdown
            action={this.props.action}
            disabled={this.props.disabled}
            definitions={this.props.definitions}
            definitionToMenuItem={this.props.definitionToMenuItem}
            disableContentMenu={this.props.disableContentMenu}
            syncOpenKeysStructure={this.props.syncOpenKeysStructure}
            openKeysStructure={this.props.openKeysStructure}
            hoverOff={() => this.hoverOff()}
            isHovered={this.state.isHover}
            handleDropdownClick={this.props.handleDropdownClick}
            definitionToMenuProps={definitionToMenuProps}
          />
        </div>
      </div>
    );
  }
}

export default ContentMenuContainer;
