import { Dom } from './DomUtil';
import { UnitTypes } from '../units/UnitTypes';
import { CSSSelector } from './CssSelectorUtil';
import { DomAssertions } from './DomAssertionsUtil';
import { EditorManager } from 'tinymce';

declare const tinymce: EditorManager;

export namespace UnitElementFocusUtil {
  import dataNid = CSSSelector.dataNid;
  import isPartOfGraphic = DomAssertions.isPartOfGraphic;

  export function getElementNid(element: Element): string | null {
    const target = Dom.closestElement(element, '[data-nid]:not(table)');
    return target ? Dom.getAttributeValue(target, 'data-nid') : null;
  }

  export function focusElement(unitContainer: Element, initElementUid: string | null, family?: UnitTypes) {
    let customFocus = false;
    const dataNidElement = initElementUid ? unitContainer.querySelector(dataNid(initElementUid)) : null;
    if (dataNidElement) {
      if (isPartOfGraphic(dataNidElement)) {
        customFocus = focusGraphicElement(dataNidElement);
      } else {
        customFocus = focusEditableElement(dataNidElement);
      }
    }
    if (!customFocus && family === 'collection') {
      customFocus = focusCollectionElement(unitContainer);
    }
    if (!customFocus && family === 'table') {
      customFocus = focusEditableElement(unitContainer);
    }
    tinymce.activeEditor.focus(customFocus);
  }

  function focusEditableElement(element: Element): boolean {
    const target = Dom.findClosestEditableElement(element);
    if (target) {
      tinymce.activeEditor.selection.setCursorLocation(target);
      if (!DomAssertions.isVisible(target)) {
        Dom.scrollElementIntoView(target);
      }
      return true;
    }
    return false;
  }

  function focusCollectionElement(unitContainer: Element): boolean {
    const para = unitContainer.querySelector('p.edit-target, span.edit-target'); // para, span first
    const all = unitContainer.querySelectorAll('.edit-target');
    const target = para ?? all[all.length - 1];
    if (target) {
      tinymce.activeEditor.selection.setCursorLocation(target);
    }
    return !!target;
  }

  function focusGraphicElement(element: Element): boolean {
    const target = Dom.closestGraphic(element);
    if (target) {
      tinymce.activeEditor.selection.select(target);
      return true;
    }
    return false;
  }
}
