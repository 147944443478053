export const union = <T>(...sets: Array<Set<T>>) => {
  if (!sets.length) {
    return new Set<T>();
  }
  return sets.reduce((union, set) => unionOfTwoSets(union, set), new Set<T>());
};

const unionOfTwoSets = <T>(p: Set<T>, q: Set<T>): Set<T> => {
  const { larger, smaller } = nameTwoSetsBySize(p, q);
  const union = new Set<T>(larger);
  smaller.forEach((el) => union.add(el));
  return union;
};

export const intersection = <T>(...sets: Array<Set<T>>) => {
  if (!sets.length) {
    return new Set<T>();
  }
  const [smallest, ...rest]: Set<T>[] = sortSetsBySizeAsc(...sets);
  return rest.reduce((intersection, set) => intersectionOfTwoSets(intersection, set), smallest);
};

const sortSetsBySizeAsc = <T>(...sets: Array<Set<T>>): Array<Set<T>> => {
  const arr = [...sets]; // Array.prototype.sort sorts in place.
  return arr.sort((a, b) => (a.size > b.size ? 1 : -1));
};

const intersectionOfTwoSets = <T>(p: Set<T>, q: Set<T>): Set<T> => {
  const { larger, smaller } = nameTwoSetsBySize(p, q);
  if (smaller.size === 0) {
    return new Set<T>();
  }
  const intersection = new Set<T>();
  larger.forEach((el) => {
    if (smaller.has(el)) {
      intersection.add(el);
    }
  });
  return intersection;
};

const nameTwoSetsBySize = <T>(a: Set<T>, b: Set<T>): { larger: Set<T>; smaller: Set<T> } => {
  let larger, smaller;
  if (a.size > b.size) {
    larger = a;
    smaller = b;
  } else {
    larger = b;
    smaller = a;
  }
  return { larger, smaller };
};

export const equals = <T>(...sets: Array<Set<T>>): boolean => {
  const [first, ...rest] = sets;
  return first && !!first.size && rest && !!rest.length && rest.every((set) => equalityOfTwoSets(first, set));
};

const equalityOfTwoSets = <T>(a: Set<T>, b: Set<T>): boolean => a.size === b.size && Array.from(a).every((el) => b.has(el));
