import * as React from 'react';
import { useState } from 'react';
import ContainerWithSubList, { ItemType } from '../../misc/ContainerWithSubList/ContainerWithSubList';
import DistributionGroupReports, { ReportType } from './DistributionGroupReports/DistributionGroupReports';
import { exportFeedbackReport, exportQuizReport, exportReadConfirmReport } from './distributionReportsExport';
import { AxiosResponse } from 'axios';
import { Dialog, FlatButton } from 'material-ui';
import { Cancelled } from '../../../clients/base-clients';
import LoadingSpinner from '../../documents/kpi/TableContainer/LoadingSpinner';

function DistributionReports() {
  const [isExportInProgress, setExportInProgress] = useState(false);
  const [isDialogOpen, setShowDialog] = useState(false);
  const [groupName, setGroupName] = useState<string>('');

  const items: ItemType[] = [
    {
      label: 'Read Confirm Report',
      dataqa: 'menu-item__read-confirm-report',
      container: (
        <DistributionGroupReports key="read-confirm" reportType={ReportType.READ_CONFIRM} onExport={onExport} title="Read Confirm" />
      )
    },
    {
      label: 'Feedback Report',
      dataqa: 'menu-item__feedback-report',
      container: <DistributionGroupReports key="feedback" reportType={ReportType.FEEDBACK} onExport={onExport} title="Feedback" />
    },
    {
      label: 'Quiz Report',
      dataqa: 'menu-item__quiz-report',
      featureFlag: 'quiz',
      container: <DistributionGroupReports key="quiz" reportType={ReportType.QUIZ} onExport={onExport} title="Quiz" />
    }
  ];

  function onExport(
    groupUid: string,
    groupName: string,
    readConfirmAllManuals: boolean,
    reportType: ReportType,
    onSuccess: () => void,
    onError: () => void
  ) {
    setGroupName(groupName);
    if (reportType === ReportType.READ_CONFIRM) {
      setExportInProgress(true);
      exportReadConfirmReport(groupUid, readConfirmAllManuals)
        .then((response) => {
          onExportSuccess(response, onSuccess);
        })
        .catch(onError)
        .finally(() => setExportInProgress(false));
    } else if (reportType === ReportType.FEEDBACK) {
      setExportInProgress(true);
      exportFeedbackReport(groupUid)
        .then((response) => {
          onExportSuccess(response, onSuccess);
        })
        .catch(onError)
        .finally(() => setExportInProgress(false));
    } else if (reportType === ReportType.QUIZ) {
      setExportInProgress(true);
      exportQuizReport(groupUid)
        .then((response) => {
          onExportSuccess(response, onSuccess);
        })
        .catch(onError)
        .finally(() => setExportInProgress(false));
    }
  }

  function onExportSuccess(response: AxiosResponse<void> | Cancelled, onSuccess: () => void) {
    if (response instanceof Cancelled) {
      setShowDialog(false);
    } else if (response.status === 204) {
      setShowDialog(true);
    }
    onSuccess();
  }

  function okAction() {
    setShowDialog(false);
  }

  return (
    <div className="dashboard-distribution">
      {isExportInProgress && <LoadingSpinner />}

      <div className="dashboard-distribution-title">Distribution Reports</div>
      <div className="dashboard-distribution-container">
        <ContainerWithSubList items={items} rhsStyle={{ height: '80%' }} />
      </div>
      <Dialog
        contentStyle={{ width: '400px' }}
        className="dialog-no-data"
        actions={[
          <FlatButton key={1} backgroundColor="#f5f5f5" fullWidth={true} label="Ok" data-qa="dialog-action-cancel" onClick={okAction} />
        ]}
        modal={false}
        open={isDialogOpen}
        onRequestClose={okAction}
      >
        <div className="text-align-center">Group &apos;{groupName}&apos; contains no relevant data.</div>
        <div className="text-align-center">A report will not be generated.</div>
      </Dialog>
    </div>
  );
}

export default DistributionReports;
