import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import { CircularProgressLoader } from '../../misc/Loaders';
import { VisualStates } from '../utils/DocumentVisualStates';
import UnitHighlightStore from '../../../flux/editor/UnitHighlightStore';
import UnitComponentization from '../../mixins/UnitComponentization';
import UnitImageLoader from '../../mixins/UnitImageLoader';
import DocUnit from '../docUnit/DocUnit';
import { Footer } from '../documentFooter/Footer';
import { DocumentOverlay } from './components/DocumentOverlay';
import DocumentEmitter from './components/DocumentEmitter';
import EditorStore from '../../../flux/editor/EditorStore';
import Log from '../../../utils/Log';
import { IEditorStoreEvent, IHighlight, IProject, IUnit, IUnitConceptMap } from 'mm-types';
import { propsAreEqual } from '../../../utils/prop-compare';
import UnitConceptStore, { UnitConceptStoreEvent } from '../../../flux/editor/UnitConceptStore';
import TocStore, { TocStoreEvent } from '../../../flux/editor/TocStore';
import SmartContentStore, { SmartContentStoreEvent } from '../../../flux/editor/SmartContentStore';
import ModeBarContainer from '../modebar/ModeBarContainer';
import * as keyIdentifier from '../utils/keyIdentifier';
import RevisionStore from '../../../flux/editor/RevisionStore';
import { EditorEventType } from '../../../flux/editor/EditorStoreAddons/EditorEventType';
import EditorModes from '../../../flux/editor/EditorModes';
import { DocumentUtils } from './utils/DocumentUtils';
import DocUnitDom from './components/DocUnitDom';
import Header from '../documentHeader/Header';
import { UnitUtils } from '../utils/units/UnitUtils';

export type Props = {
  docUnits: IUnit[] | null;
  onVisualStateChange: (newState: VisualStates, newStateOn: boolean) => void;
  project?: IProject;
  startUnitUid: string;
  preventKeyDetection: boolean;
  showHeader: boolean;
  isStyleLoaded: boolean;
  openLinkModal: (isDuRef: boolean) => void;
  openLinkAnchorModal: () => void;
};

export type State = {
  selectedUnits: IUnit[];
  loadingUnits: boolean;
  initialComponentizedUnitIndex: number;
  highlights: IHighlight[];
  docVisualStates: VisualStates[];
  savedDocVisualStates: VisualStates[];
  isLoading: boolean;
  isZoomed: boolean;
  showDocumentOverlay: { type: 'rejectShareConfirm' | 'insertShareConfirm'; data: any } | null;
  conceptMap?: { [name: string]: IUnitConceptMap };
  diffUnitsUids?: string[];
};

// TODO further cleanup: a lot of the scrollTo and select logic could be handed off to another helper module

export default class Document extends React.Component<Props, State> {
  private _scrollToUnitOnImagesLoad: {
    unitUid: string;
    options: { elementNid?: string | null; deleted?: boolean };
  } | null;
  private _unitsRenderedHaveChanged: boolean;
  private _windowResizeThrottleFunction: () => void;
  private _handleScrollComponentization: () => void;
  private _unitImageLoader: UnitImageLoader;
  private _unitComponentizer: UnitComponentization;
  private proxy: (...args: any[]) => void;
  private componentProxy: (...args: any[]) => void;
  private imagesProxy: (...args: any[]) => void;
  private editingScrollContainerRef: React.RefObject<HTMLDivElement>;
  private editingPageRef: React.RefObject<HTMLDivElement>;
  private rerunScrollToUnit?: Function;
  private canScrollToUnit: Promise<boolean> | null;
  private subscriptions: Function[];
  private startUnitCompInterval: number | null;

  constructor(props: Props) {
    super(props);

    this.subscriptions = [];
    this.startUnitCompInterval = null; // SB CHECK
    this._scrollToUnitOnImagesLoad = null;
    this.editingScrollContainerRef = React.createRef<HTMLDivElement>();
    this.editingPageRef = React.createRef<HTMLDivElement>();

    this._handleScrollComponentization = _.throttle(() => {
      if (this._unitComponentizer) {
        this._unitComponentizer.updateInitialComponentizedUnitIndex(this.state.initialComponentizedUnitIndex, this.props.docUnits!, {
          isZoomed: this.state.isZoomed,
          forcePositionCalc: true
        });
      }
    }, 200);

    this.state = {
      // note: selectedUnits is different from focused (selected infers cursor selected, focused infers clicked in edit mode)
      // therefore a focused unit will always be === the selectedUnits[selectedUnits.top-1] unit, but selectedUnits will have a value if nothing is focused
      selectedUnits: [],
      loadingUnits: false,
      initialComponentizedUnitIndex: 0,
      highlights: [],
      isZoomed: false,
      showDocumentOverlay: null,
      docVisualStates: [],
      savedDocVisualStates: [],
      isLoading: false,
      diffUnitsUids: []
    };
    this._selectOnUnitClicked = this._selectOnUnitClicked.bind(this);
    this.launchEditor = this.launchEditor.bind(this);

    this._handleFooterActions = this._handleFooterActions.bind(this);
    this._closeOverlay = this._closeOverlay.bind(this);
  }

  componentDidMount() {
    this.proxy = this._editorKeyBinds.bind(this);
    $(document).keydown(this.proxy);
    this.componentProxy = this._onComponentizedUnitsChanged.bind(this);
    this.imagesProxy = this._onImagesLoaded.bind(this);
    this.subscriptions.push(EditorStore.listen(this._onEditStoreUpdate, this));
    this.subscriptions.push(UnitConceptStore.listen(this.onConceptStoreUpdate, this));
    this.subscriptions.push(SmartContentStore.listen(this.onSmartContentStoreUpdate, this));
    this.subscriptions.push(TocStore.listen(this.onTocStoreUpdate, this));

    this._unitComponentizer = new UnitComponentization();
    this._unitComponentizer.docContainer = (this.editingScrollContainerRef.current as Element) ?? null;
    this._unitComponentizer.on('componentizedUnitsChanged', this.componentProxy);
    this._unitImageLoader = new UnitImageLoader();
    this._unitImageLoader.reset().on('unitImagesLoaded', this.imagesProxy);

    this._unitsRenderedHaveChanged = true;

    // store document position on window resize and mount
    this._windowResizeThrottleFunction = _.throttle(() => {
      this._unitComponentizer.recalculateComponentizedUnits(
        this.state.initialComponentizedUnitIndex,
        this.props.docUnits ? this.props.docUnits : []
      );
      DocumentEmitter.emit('resize');
    }, 300);

    window.addEventListener('resize', this._windowResizeThrottleFunction);
    this.startUnitCompInterval = window.setInterval(() => {
      // start unitComponentization once fully loaded
      if (!this.state.isLoading && this.props.isStyleLoaded) {
        // on mount: initialComponentizedUnitIndex === 0 which is just what we want
        window.clearInterval(this.startUnitCompInterval!);
        this._windowResizeThrottleFunction();
      }
    }, 500);

    (window as any).arcLeaveHandler = DocumentUtils.pageUnloadHandler;
    window.addEventListener('beforeunload', (window as any).arcLeaveHandler);

    this.setState(
      {
        docVisualStates: DocumentUtils.getDocumentVisualStates(this.props.onVisualStateChange) // must be set here and not on constructor 'cos es6/react!
      },
      () => {
        // if mounted with units in state (i.e. exit from fullpage modal overlay) ensure to get highlights
        if (this.props.docUnits && this.props.docUnits.length) {
          UnitHighlightStore.retrieveTocHighlight();
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.startUnitCompInterval) {
      window.clearInterval(this.startUnitCompInterval);
    }
    this._unitComponentizer.resetUnitInitialPositions();
    this._unitImageLoader.event.removeListener('unitImagesLoaded', this.imagesProxy);
    this._unitComponentizer.event.removeListener('componentizedUnitsChanged', this.componentProxy);

    $(document).unbind('keydown', this.proxy);
    EditorStore.getEditor().destroyActiveEditor();
    window.removeEventListener('resize', this._windowResizeThrottleFunction);

    this.subscriptions.forEach((unsubFn) => unsubFn());
    this.subscriptions = [];
    TocStore.clear();
  }

  // Lifecycle order: componentWillReceiveProps => componentWillUpdate => render => componentDidUpdate
  // a page of units is considered re-rendered when docUnits props have changed
  // this is expensive so we do this once only in componentWillReceiveProps and store in: this._unitsRenderedHaveChanged
  // componentWillUpdate and componentDidUpdate can then make use of this before componentDidUpdate sets back to false

  // componentWillReceiveProps will typically select unit(s) in state, then get followed by render which will highlight them appropriate
  // setting state in componentWillReceiveProps will not trigger an additional render

  // further performance improvement if ever needed: on every state in parent (think simple modal display etc) componentWillReceiveProps get executed
  // and this._unitsRenderedHaveChanged is calculated: this is expensive, but doesn't cause issues for now...

  // TODO in a future iteration break the link between EditorPage and this component by getting this component to listen to EditorStore to change itself...
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this._unitsRenderedHaveChanged = !propsAreEqual(nextProps.docUnits, this.props.docUnits);
    const newPageLoad = this.props.startUnitUid !== nextProps.startUnitUid;

    if (newPageLoad) {
      (ReactDOM.findDOMNode(this.editingScrollContainerRef.current) as Element).scrollTop = 0;
      EditorStore.getEditor().destroyActiveEditor();

      // starting a new page, so clear position, init component unit index, and select first possible item
      this._unitComponentizer.resetUnitInitialPositions();
      this.setState({ initialComponentizedUnitIndex: 0, showDocumentOverlay: null });
      this._selectFirstAvailableUnit(nextProps.docUnits! || this.props.docUnits);
    }

    // one or more units have changed on the page
    else if (this._unitsRenderedHaveChanged && nextProps.docUnits) {
      const previousPrimarySelectedUnit = EditorStore.getSelectedUnit();

      if (!previousPrimarySelectedUnit) {
        this._selectFirstAvailableUnit(nextProps.docUnits);
      } else {
        const latestPrimarySelectedUnit = nextProps.docUnits.find((u) => u.uid === previousPrimarySelectedUnit.uid);

        // previous primary unit selected is still no this page, so:
        // reselect the current unit(s) where possible, but with latest data from nextProps.docUnits instead: this will refresh selectedUnits memory with the same updated version as in EditorStore equivalent)
        if (latestPrimarySelectedUnit && DocumentUtils.canSelect(this.state.docVisualStates, latestPrimarySelectedUnit)) {
          const selectedUnits = EditorStore.getSelectedUnits();
          if (selectedUnits.length > 0) {
            // re-select units but take values from nextProps.docUnits, EditorStore.getSelectedUnits() might contain outdated values
            const selectedUnitsUids = selectedUnits.map((selectedUnit) => selectedUnit.uid);
            this._selectMultipleUnits(nextProps.docUnits.filter((docUnit) => selectedUnitsUids.indexOf(docUnit.uid) > -1));
          } else {
            this._selectUnit(latestPrimarySelectedUnit);
          }
        }
        // unit is gone, so select previous available
        else {
          this._selectPreviousAvailableUnit(nextProps.docUnits, previousPrimarySelectedUnit);
        }
      }
    }
    // else: something else changed props, but nothing we should care about :)
  }

  shouldComponentUpdate(nextProps: Props) {
    // detect if there're new fully loaded docUnits which are in the viewport
    if (this.props.docUnits && nextProps.docUnits) {
      if (this.props.docUnits.length !== nextProps.docUnits.length) {
        return true;
      }

      const newDocUnits: IUnit[] = [];
      for (let i = 0; i < this.props.docUnits.length; i++) {
        if (this.props.docUnits[i].uid !== nextProps.docUnits[i].uid) {
          Log.error('different units at index position: ' + i);
        }
        // current unit is not fully loaded (doesn't have html) but the new one is fully loaded - means new docUnit
        if (!this.props.docUnits[i].html && !!nextProps.docUnits[i].html) {
          newDocUnits.push(nextProps.docUnits[i]);
        }
      }

      // if there's any new docUnits coming but none of them are in the viewport then don't update/render
      if (newDocUnits.length > 0) {
        // is any new docUnit in visible range?
        return newDocUnits?.findIndex((newDocUnit) => this._isUnitInVisibleRange(newDocUnit)) > -1;
      }
    }

    return true;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isComponentizationChange = this.state.initialComponentizedUnitIndex !== prevState.initialComponentizedUnitIndex;
    DocumentUtils.highlightTextAfterRendering(isComponentizationChange);

    // if unit available (i.e. null is spinner), do some post rendering recalc of unit positions for componentization adjustments
    if (this.props.docUnits && !this.state.isLoading && this.props.isStyleLoaded) {
      if (this._unitsRenderedHaveChanged) {
        const prevDocUnits =
          prevProps.docUnits?.filter((docUnit) => !UnitUtils.isGeneratedUnit(docUnit) && !UnitUtils.isVirtualUnitUid(docUnit.uid)) ?? [];
        const currentDocUnits =
          this.props.docUnits?.filter((docUnit) => !UnitUtils.isGeneratedUnit(docUnit) && !UnitUtils.isVirtualUnitUid(docUnit.uid)) ?? [];
        this._unitComponentizer.updateInitialComponentizedUnitIndex(this.state.initialComponentizedUnitIndex, this.props.docUnits, {
          forcePositionCalc: true,
          isZoomed: this.state.isZoomed,
          areNewUnitsAdded: prevDocUnits.length != currentDocUnits.length
        });
        this._unitImageLoader.detectImagesLoad(this.editingScrollContainerRef.current);
      }
    }

    // we are done updating component: reset in case it was as a result of docUnit changes...
    this._unitsRenderedHaveChanged = false;
    if (!!this.props.docUnits && this.rerunScrollToUnit) {
      this.rerunScrollToUnit(true);
    }

    // check only if there's a selected unit and new props contain docUnits
    if (this.state.selectedUnits.length > 0 && this.props.docUnits!) {
      const firstSelectedUnitUid = this.state.selectedUnits[0].uid;
      let isSelectedUnitInPreviousProps = false;

      if (!!prevProps.docUnits) {
        isSelectedUnitInPreviousProps =
          prevProps?.docUnits?.findIndex((docUnit) => !!docUnit.html && docUnit.uid === firstSelectedUnitUid) > -1;
      }

      let isSelectedUnitInCurrentProps = false;
      if (!!this.props.docUnits) {
        isSelectedUnitInCurrentProps =
          this.props.docUnits?.findIndex((docUnit) => !!docUnit.html && docUnit.uid === firstSelectedUnitUid) > -1;
      }

      // jump/scroll to a selected unit only when the selected unit appeared in the props.docUnits
      if (!isSelectedUnitInPreviousProps && isSelectedUnitInCurrentProps) {
        this._jumpScrollToUnit(firstSelectedUnitUid, {});
      }
    }

    if (this.state.isZoomed != prevState.isZoomed) {
      this._unitComponentizer.updateInitialComponentizedUnitIndex(this.state.initialComponentizedUnitIndex, this.props.docUnits!, {
        forcePositionCalc: true,
        isZoomed: this.state.isZoomed
      });
    }

    // on units change, before render: remove listeners for graphic loads
    if (this._unitsRenderedHaveChanged) {
      this._scrollToUnitOnImagesLoad = null;
      this._unitImageLoader.clearLoadedImages(this.editingScrollContainerRef.current);
    }
  }

  launchEditor(unit: Partial<IUnit>, elementNid?: string | null) {
    if (this.state.selectedUnits.length === 1) {
      const editor = EditorStore.getEditor();
      const launchForUnit = EditorStore.getDocUnitModel(unit.uid!)!.toJSON();
      const isShareUsageReadonly =
        launchForUnit.shareDetails && !launchForUnit.shareDetails.origin && launchForUnit.shareDetails.updateStrategy !== 'NONE';
      const isEditorBusy = EditorStore.isBusy();
      const isEditorInitializing = editor.isInitializing();

      if (!isShareUsageReadonly && !isEditorBusy && !isEditorInitializing) {
        const clickedUnit = this.props.docUnits!.find((du) => du.uid === unit.uid)!;
        editor.create(clickedUnit, elementNid, (completeCreation: () => void) => {
          // ensure in selected state first (so any cursors will begin from this one...)
          // then create new instance
          this._selectUnit(clickedUnit, null, () => {
            completeCreation();
          });
        });
      } else {
        Log.info(
          'Document: not launching editor, as: isShareUsageReadonly:' +
            isShareUsageReadonly +
            ', isEditorBusy: ' +
            isEditorBusy +
            ', isEditorInitializing: ' +
            isEditorInitializing
        );
      }
    } else {
      const clickedUnit = this.props.docUnits!.find((du) => du.uid === unit.uid)!;
      this._selectUnit(clickedUnit, { scrollTo: false });
    }
  }

  onScroll() {
    this._handleScrollComponentization();
  }

  onScrollToUnit(unitUid: string, elementNid?: string | null) {
    if (!this.props.docUnits || !_.isArray(this.props.docUnits) || !this.props.isStyleLoaded) {
      this.canScrollToUnit = new Promise<boolean>((resolve) => (this.rerunScrollToUnit = resolve));
      this.canScrollToUnit.then(() => {
        this.scrollToUnit(unitUid, elementNid);
        this.rerunScrollToUnit = undefined;
      });
    } else {
      this.scrollToUnit(unitUid, elementNid);
      this.rerunScrollToUnit = undefined;
    }
  }

  scrollToUnit(unitUid: string, elementNid?: string | null) {
    if (!this.props.docUnits || !_.isArray(this.props.docUnits)) {
      console.error(new Error(`No doc units in props: ${this.props.docUnits}`));
      return;
    }
    const newSelected: IUnit = this.props.docUnits.find((du) => du.uid === unitUid)!;

    if (!newSelected) {
      Log.info("Document - failed to select unit - doesn't exist on the current page");
      return;
    }

    if (!this._selectUnit(newSelected, { scrollTo: true, sequentialScroll: false, elementNid })) {
      this._jumpScrollToUnit(unitUid, {
        deleted: newSelected && (newSelected.type === 'removed' || newSelected.type === 'ghost'), // if can't select it, at least scroll to it
        elementNid: elementNid
      });
    }
  }

  allowInefficientUpdate(unitUid, type = 'unitComponent') {
    if (!_.isUndefined(unitUid)) {
      if (this.refs[type + '_' + unitUid]) {
        // may have been unmounted
        if (!EditorStore.isEditorFocused(unitUid)) {
          (this.refs[type + '_' + unitUid] as any).allowInefficientUpdate();
        }
      }
    } else {
      _.keys(this.refs).forEach((key) => {
        if (key.indexOf(type + '_') !== -1) {
          // prevent allowed update to a unit you are currently editing
          // (scenario can only happen where another user updates this "page" - i.e. collaboration)
          const refUnitUid = key.split(type + '_')[1];
          if (!EditorStore.isEditorFocused(refUnitUid)) {
            (this.refs[key] as any).allowInefficientUpdate();
          }
        }
      });
    }
  }

  notifyOfContentResize() {
    setTimeout(() => {
      this._windowResizeThrottleFunction();
    }, 350);

    // this will scroll selected unit to be visible on container resize
    if (EditorStore.getSelectedUnit()) {
      this._sequentialScrollToUnit(EditorStore.getSelectedUnit()!.uid);
    }
  }

  _onComponentizedUnitsChanged(newInitialComponentizedUnitIndex) {
    this.setState({ initialComponentizedUnitIndex: newInitialComponentizedUnitIndex });
    // TODO: gg - inform EditorStore to load next units page from initialComponentizedUnitIndex
    EditorStore.updateDocUnitSearchIndex(newInitialComponentizedUnitIndex);
  }

  _onImagesLoaded() {
    this._unitComponentizer.updateInitialComponentizedUnitIndex(this.state.initialComponentizedUnitIndex, this.props.docUnits!, {
      forcePositionCalc: true,
      isZoomed: this.state.isZoomed
    });

    if (this._scrollToUnitOnImagesLoad) {
      Log.debug('Scrollto unit on gfx download complete');
      this._jumpScrollToUnit(this._scrollToUnitOnImagesLoad.unitUid, this._scrollToUnitOnImagesLoad.options);
      this._scrollToUnitOnImagesLoad = null;
    }

    // for RevBar update
    DocumentEmitter.emit('resize');
  }

  _selectFirstAvailableUnit(units: IUnit[]) {
    const BreakException = {};
    // select first selectable on page (if any) as a default
    let initSelectableIndex = 1;
    if (units) {
      try {
        units.forEach((unit, uInx) => {
          if (DocumentUtils.canSelect(this.state.docVisualStates, unit)) {
            initSelectableIndex = uInx;
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) {
          throw e;
        }
      }
    }

    let unit: IUnit | null = null;
    if (units && units.length > 1) {
      unit = units[initSelectableIndex];
    }

    this._selectUnit(unit);
  }

  _selectPreviousAvailableUnit(units, startFromUnit) {
    // starting from same index position as removed, work your way back to find the previous non-removed item
    if (units.length) {
      const currentIndex = startFromUnit.index;
      let newSelected = units[0]; // default to first unit on page

      for (let i = currentIndex; i > 0; i--) {
        const nextUnit = units[i];
        if (nextUnit && DocumentUtils.canSelect(this.state.docVisualStates, nextUnit)) {
          newSelected = nextUnit;
          break;
        }
      }

      this._selectUnit(newSelected);
    } else {
      this._selectUnit(null); // select nothing
    }
  }

  private onTocStoreUpdate(e: TocStoreEvent) {
    if (e.type === 'highlightTocItem') {
      if (RevisionStore.isPublishedRevision()) {
        UnitHighlightStore.retrieveTocHighlight();
      }
    }
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EditorEventType>) {
    if (e.type === 'selectUnit') {
      const castEvent = e as IEditorStoreEvent<'selectUnit'>;
      this._selectUnit(castEvent.data!.unit, null, castEvent.data!.callback, castEvent.data!.options.force);
    } else if (e.type === 'editorIsBusy') {
      this._unitsRenderedHaveChanged = this.state.isLoading && !e.busy;
      this.setState({ isLoading: e.busy ?? true });
    } else if (e.type === 'scrollToUnit') {
      const castEvent = e as IEditorStoreEvent<'scrollToUnit'>;
      this.onScrollToUnit(castEvent.data!.unitUid, castEvent.data!.elementNid);
    } else if (e.type === 'launchEditor') {
      const castEvent = e as IEditorStoreEvent<'launchEditor'>;
      this.launchEditor(castEvent.data as IUnit);
    } else if (e.type === 'changeModeStart') {
      const castEvent = e as IEditorStoreEvent<'changeModeStart'>;
      this.handleChangeModeStart(castEvent);
    } else if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;
      this._closeOverlay();
      if (castEvent) {
        this.handleChangeModeComplete(castEvent);
        this.notifyOfContentResize();
      }
    } else if (e.type === 'requestSharedContentInsert') {
      const castEvent = e as IEditorStoreEvent<'requestSharedContentInsert'>;

      if (castEvent.data!.selectedUnit) {
        this._sequentialScrollToUnit(castEvent.data!.selectedUnit.uid);
      }
      this.setState({ showDocumentOverlay: { type: 'insertShareConfirm', data: castEvent.data!.sharedIndex } });
    } else if (e.type === 'requestRejectSharedContentUpdate') {
      this.setState({ showDocumentOverlay: { type: 'rejectShareConfirm', data: e.data } });
    }
  }

  onConceptStoreUpdate(e: UnitConceptStoreEvent) {
    this.setState({ conceptMap: e.state.unitConceptMaps });
  }

  onSmartContentStoreUpdate(e: SmartContentStoreEvent) {
    if (e.type === 'retrieveSharedOriginUnits') {
      if (e.units) {
        this.setState({
          ...this.state,
          diffUnitsUids: e.units.map(({ uid }) => uid)
        });
      }
    }
  }

  _closeOverlay() {
    if (this.state.showDocumentOverlay !== null) {
      this.setState({ showDocumentOverlay: null });
    }
  }

  private handleChangeModeStart(castEvent: IEditorStoreEvent<'changeModeStart'>) {
    const stateModeOverrides = EditorModes.getProperties(castEvent.data?.to!).overrideVisualStates();
    if (stateModeOverrides.length) {
      this.setState({ savedDocVisualStates: DocumentUtils.getDocumentVisualStates(this.props.onVisualStateChange) }, () => {
        stateModeOverrides.forEach((override) => {
          this._handleFooterActions(override.state, override.value);
        });
      });
    }
  }

  private handleChangeModeComplete(castEvent: IEditorStoreEvent<'changeModeComplete'>) {
    const stateModeOverrides = EditorModes.getProperties(castEvent.data?.from!).overrideVisualStates();
    if (stateModeOverrides.length) {
      const visualStates: VisualStates[] = [];
      Object.assign(visualStates, this.state.docVisualStates);
      stateModeOverrides.forEach(({ state, value }) => {
        if (!this.state.docVisualStates.includes(state) && this.state.savedDocVisualStates.includes(state)) {
          visualStates.push(state);
        } else if (!this.state.savedDocVisualStates.includes(state)) {
          let i = -1;
          if ((i = visualStates.indexOf(state)) !== -1) {
            visualStates.splice(i, 1);
          }
        }
      });
      this.setState({ docVisualStates: visualStates, savedDocVisualStates: [] });
    }
  }

  private get selectedUnit(): IUnit | null {
    return this.state.selectedUnits && this.state.selectedUnits.length
      ? this.state.selectedUnits[this.state.selectedUnits.length - 1]
      : null;
  }

  _editorKeyBinds(e) {
    const preventKeyDetection = this.props.preventKeyDetection;
    const isEditorFocused = EditorStore.getEditor().isFocused();
    const isKeyTargetOnEditor = !(e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA');

    if (isKeyTargetOnEditor && !preventKeyDetection) {
      const unit: IUnit | null = this.selectedUnit;
      if (!isEditorFocused) {
        return this.selectedUnitKeyHandler(e, unit);
      }
      return DocumentUtils.focusedUnitKeyHandler(e);
    }
    // editor isn't editing and its ok that we may be in a textbox elsewhere outside of the editor, block cmd-s
    if (!isEditorFocused && keyIdentifier.isSaveKeys(e)) {
      return e.preventDefault();
    }
  }

  private selectedUnitKeyHandler(e: React.KeyboardEvent, unit: IUnit | null) {
    if (!unit) {
      return false;
    }
    DocumentUtils.onUnitOperationKey(e, unit);
    this.onNavigationKey(e);
    this.onEnterKey(e, unit);
    DocumentUtils.onUndoRedoKey(e);
  }

  private onEnterKey(e: React.KeyboardEvent, unit: IUnit) {
    if (keyIdentifier.isEnterKey(e)) {
      this.launchEditor({ uid: unit.uid });
      return e.preventDefault();
    }
  }

  private onNavigationKey(e: React.KeyboardEvent) {
    if (keyIdentifier.isUpArrowKey(e) || keyIdentifier.isDownArrowKey(e)) {
      const isUp = keyIdentifier.isUpArrowKey(e);

      if (keyIdentifier.isShiftKey(e)) {
        this._selectToSiblingDocUnit(!isUp);
      } else if (keyIdentifier.isCommandCtrlKey(e)) {
        this._selectPageDocUnit(!isUp);
      } else {
        this._selectSiblingDocUnit(!isUp);
      }
      return e.preventDefault();
    }
  }

  _selectUnit(
    unit: IUnit | null,
    scrollOptions?: { scrollTo?: boolean; elementNid?: string | null; sequentialScroll?: boolean } | null,
    onUnitsSelected?,
    force = false
  ) {
    if ((!force && !DocumentUtils.canSelect(this.state.docVisualStates, unit)) || !unit) {
      return false;
    }

    this.setState({ selectedUnits: unit ? [unit] : [] }, () => {
      EditorStore.triggerUnitsSelection(this.state.selectedUnits, onUnitsSelected);

      if (unit && scrollOptions?.scrollTo) {
        if (scrollOptions.sequentialScroll) {
          this._sequentialScrollToUnit(unit.uid);
        } else {
          this._jumpScrollToUnit(unit.uid, { elementNid: scrollOptions.elementNid });
        }
      }
    });

    return true;
  }

  _selectMultipleUnits(units) {
    this.setState({ selectedUnits: units.filter((u) => DocumentUtils.canSelect(this.state.docVisualStates, u)) }, () => {
      EditorStore.triggerUnitsSelection(this.state.selectedUnits);
    });
  }

  _selectToUnit(toUnit) {
    if (this.state.selectedUnits.length) {
      const sortedSelected = _.sortBy(this.state.selectedUnits, ['index'], ['asc']);
      const earliestSelectedUnit = sortedSelected[0];
      const latestSelectedUnit = sortedSelected[sortedSelected.length - 1];
      const newSelectedUnits: IUnit[] = [];

      if (earliestSelectedUnit.index <= toUnit.index) {
        for (let i = earliestSelectedUnit.index; i <= toUnit.index; i++) {
          const selectUnit = this.props.docUnits![i];
          if (DocumentUtils.canSelect(this.state.docVisualStates, selectUnit)) {
            newSelectedUnits.push(selectUnit);
          }
        }
      } else {
        for (let i = toUnit.index; i <= latestSelectedUnit.index; i++) {
          const selectUnit = this.props.docUnits![i];
          if (DocumentUtils.canSelect(this.state.docVisualStates, selectUnit)) {
            newSelectedUnits.push(selectUnit);
          }
        }
      }

      this.setState({ selectedUnits: newSelectedUnits }, () => {
        EditorStore.triggerUnitsSelection(this.state.selectedUnits);
      });
    }
  }

  _toggleUnitSelection(unit, isUnitSelected) {
    if (EditorStore.isReadOnly()) {
      return;
    }

    if (!DocumentUtils.canSelect(this.state.docVisualStates, unit) || (isUnitSelected && this.state.selectedUnits.length <= 1)) {
      // don't allow unselect of single selected unit
      return false;
    }

    if (isUnitSelected) {
      this.state.selectedUnits.splice(
        this.state.selectedUnits.findIndex((u) => u.uid === unit.uid),
        1
      );
    } else {
      this.state.selectedUnits.push(unit);
    }

    this.setState({ selectedUnits: this.state.selectedUnits }, () => {
      EditorStore.triggerUnitsSelection(this.state.selectedUnits);
    });
  }

  _selectSiblingDocUnit(nextSibling: boolean, startUnit?: IUnit) {
    if (this.props.docUnits && this.state.selectedUnits.length) {
      const currentSelectedDocUnit = startUnit || this.state.selectedUnits[this.state.selectedUnits.length - 1];
      const siblingDocUnit = this.props.docUnits[parseInt(currentSelectedDocUnit.index.toString()) + (nextSibling ? +1 : -1)];

      if (siblingDocUnit) {
        if (!DocumentUtils.canSelect(this.state.docVisualStates, siblingDocUnit)) {
          this._selectSiblingDocUnit(nextSibling, siblingDocUnit);
        }
        this._selectUnit(siblingDocUnit, { scrollTo: true, sequentialScroll: true });
      }
    }
  }

  _selectToSiblingDocUnit(nextSibling: boolean, recursiveDocUnit?: IUnit) {
    if (this.props.docUnits && !EditorStore.isReadOnly() && this.state.selectedUnits.length) {
      let currentSelectedDocUnit = this.state.selectedUnits[this.state.selectedUnits.length - 1];

      if (recursiveDocUnit) {
        currentSelectedDocUnit = recursiveDocUnit;
      }

      const siblingDocUnit = this.props.docUnits[parseInt(currentSelectedDocUnit.index.toString()) + (nextSibling ? +1 : -1)];
      if (siblingDocUnit) {
        if (!DocumentUtils.canSelect(this.state.docVisualStates, siblingDocUnit)) {
          this._selectToSiblingDocUnit(nextSibling, siblingDocUnit);
        } else {
          this._selectToUnit(siblingDocUnit);
          this._sequentialScrollToUnit(siblingDocUnit.uid);
        }
      }
    }
  }

  _selectPageDocUnit(toBottom: boolean) {
    if (this.props.docUnits) {
      const selectableUnits = this.props.docUnits.filter((u) => DocumentUtils.canSelect(this.state.docVisualStates, u));
      const selectUnit = toBottom ? selectableUnits[selectableUnits.length - 1] : selectableUnits[0];
      if (selectUnit) {
        this._selectUnit(selectUnit, { scrollTo: true, sequentialScroll: false });
      }
    }
  }

  _sequentialScrollToUnit(unitUid: string) {
    // on cursor key presses

    const $pageContainer = $(ReactDOM.findDOMNode(this.editingScrollContainerRef.current) as Element),
      $unitEl = $('#_' + unitUid);

    if ($unitEl.length) {
      const unitTopPosition = $unitEl.offset()!.top;
      $pageContainer.scrollTop(unitTopPosition - $pageContainer.offset()!.top + $pageContainer.scrollTop()! - 100);
    } else {
      Log.info('Document - failed to find unit to scroll to');
    }
  }

  // this occurs on jump to another unit (or deleted unit) on a page that is perhaps just loaded, so extra steps needed
  _jumpScrollToUnit(unitUid: string, options: { elementNid?: string | null; deleted?: boolean }) {
    const $pageContainer = $(ReactDOM.findDOMNode(this.editingScrollContainerRef.current) as Element),
      $unitEl = $('#_' + unitUid),
      $elementEl = $("[data-nid='" + unitUid + "'] [data-nid='" + options.elementNid + "']");

    if ($unitEl.length) {
      if (this._unitImageLoader.areImagesLoading()) {
        Log.debug('Cannot scroll to unit as gfx loading, mark for scrollto on load');
        DocumentUtils.performScrollTo($pageContainer, $unitEl, $elementEl); // do a "best shot" scroll anyway as less jarring for user
        this._scrollToUnitOnImagesLoad = {
          unitUid,
          options
        };
      } else {
        Log.debug('Scroll to unit immediately as gfx loaded');
        DocumentUtils.performScrollTo($pageContainer, $unitEl, $elementEl);
      }
    } else {
      Log.info('Document - failed to find unit to scroll to');
    }
  }

  _selectOnUnitClicked(unit: IUnit, isUnitSelected: boolean, clickInfo) {
    const isEditing = EditorStore.getEditor().isFocused();
    const selectedUnit = EditorStore.getSelectedUnit();
    const isPrimaryUnit = !selectedUnit || unit.uid !== selectedUnit.uid;

    if (clickInfo.isCtrlClick) {
      if (!isEditing) {
        this._toggleUnitSelection(unit, isUnitSelected);
      } else if (isPrimaryUnit) {
        EditorStore.getEditor().blur({}, () => {
          // force a blur/save
          this._toggleUnitSelection(unit, isUnitSelected);
        });
      }
    } else if (clickInfo.isShiftClick) {
      if (!isEditing) {
        this._selectToUnit(unit);
      } else if (isPrimaryUnit) {
        EditorStore.getEditor().blur({}, () => {
          // force a blur/save
          this._selectToUnit(unit);
        });
      } else {
        const editor = EditorStore.getEditor()!;
        const activeEditorInstance = editor.getActiveEditorInstance()!;
        const selection = activeEditorInstance.selection;
        const start = selection.getStart();
        const end = selection.getEnd();
        if (DocumentUtils.isTableEditorInstance(activeEditorInstance) && start !== end) {
          selection.select(start);
        }
      }
    } else {
      if (!EditorStore.getEditor().isFocused()) {
        this._selectUnit(unit, { scrollTo: false });
      } else {
        EditorStore.getEditor().blur({}, () => {
          // force a blur/save
          this._selectUnit(unit, { scrollTo: false });
        });
      }
    }
  }

  _handleFooterActions(visualStateChanged: VisualStates, newStateOn: boolean) {
    if (visualStateChanged === 'SHOW_ELEMENT_PRINT_OUTPUT') {
      const elementPageBreakLabels = Array.from(document.getElementsByClassName('element-page-break') as HTMLCollectionOf<HTMLElement>);
      elementPageBreakLabels.forEach((child) => {
        newStateOn ? child.classList.remove('display-none') : child.classList.add('display-none');
      });
    }
    const isCurrentStateOn = this.state.docVisualStates.indexOf(visualStateChanged) !== -1;

    if (isCurrentStateOn && !newStateOn) {
      this.state.docVisualStates.splice(this.state.docVisualStates.indexOf(visualStateChanged), 1);
    } else if (!isCurrentStateOn && newStateOn) {
      this.state.docVisualStates.push(visualStateChanged);
    }

    this.setState({ docVisualStates: this.state.docVisualStates }, () => {
      if (localStorage) {
        localStorage.setItem('view-settings', JSON.stringify(this.state.docVisualStates));
      }

      this.props.onVisualStateChange(visualStateChanged, newStateOn);
    });
  }

  _isUnitInVisibleRange(docUnit: IUnit) {
    if (this._unitComponentizer) {
      return (
        docUnit.visibleUnitIndex !== undefined &&
        docUnit.visibleUnitIndex! >= this.state.initialComponentizedUnitIndex &&
        docUnit.visibleUnitIndex! <= this.state.initialComponentizedUnitIndex + this._unitComponentizer.componentizeRange
      );
    }

    return false;
  }

  _getDocUnitDom(isDocReadOnly: boolean, showRevBars: boolean, docUnits: IUnit[] | null): JSX.Element[] {
    const docUnitDom = docUnits
      ? docUnits.map((docUnit, mapIndex) => {
          const { isSelected, isPrimary } = DocumentUtils.getUnitSelectionInfo(this.state.selectedUnits, docUnit.uid);
          const isNextSelected =
            docUnits && DocumentUtils.getUnitSelectionInfo(this.state.selectedUnits, docUnits[mapIndex + 1]?.uid)?.isSelected;
          const facetedTagsCount = DocumentUtils.getFacetedTagsCount(this.state.conceptMap, docUnit.uid);
          const key = docUnit.uid ? docUnit.uid + docUnit.versionUid + mapIndex : docUnit.definitionId + mapIndex;
          return (
            <DocUnitDom
              key={key}
              uniqueKey={key}
              isSelected={isSelected}
              isNextSelected={isNextSelected ?? false}
              docUnit={docUnit}
              isUnitInVisibleRange={this._isUnitInVisibleRange(docUnit)}
            >
              <DocUnit
                key={key}
                unit={docUnit}
                selected={isSelected}
                primary={isPrimary}
                facetedTagsCount={facetedTagsCount}
                isDocReadOnly={isDocReadOnly}
                isStyleLoaded={this.props.isStyleLoaded}
                onClicked={this._selectOnUnitClicked}
                onFocused={this.launchEditor}
                onAction={DocumentUtils.handleInlineAction}
                diffUnitsUids={this.state.diffUnitsUids}
                showRevBars={showRevBars}
                ref={docUnit.definitionId === 'header' ? 'unitComponentType_' + docUnit.definitionId : 'unitComponent_' + docUnit.uid}
                openLinkModal={this.props.openLinkModal}
                openLinkAnchorModal={this.props.openLinkAnchorModal}
              />
            </DocUnitDom>
          );
        })
      : [];
    return docUnitDom;
  }

  render() {
    const isDocReadOnly = EditorStore.isReadOnly(); // performance fix: now called once on store (was inside DocUnit)
    const showRevBars: boolean =
      !!this.props.docUnits?.length &&
      RevisionStore.isPublishedRevision() &&
      !EditorStore.isMode('DIFF') &&
      this.state.docVisualStates.indexOf('SHOW_REVISIONBARS') !== -1;

    const docUnitDOM = this._getDocUnitDom(isDocReadOnly, showRevBars, this.props.docUnits);
    const isLoading = this.state.isLoading || !this.props.isStyleLoaded;

    return (
      <div className={'editing-stage-page editing-stage-page-primary'}>
        <Header showHeader={this.props.showHeader} project={this.props.project} />
        <ModeBarContainer />

        <div
          className={DocumentUtils.getActiveVisualStateClasses(this.state.docVisualStates)}
          onScroll={() => {
            !isLoading && this.onScroll();
          }}
          ref={this.editingScrollContainerRef}
        >
          <div
            ref={this.editingPageRef}
            className={'editing-page' + (this.props.project ? ' doctype-' + this.props.project.definitionName : '')}
          >
            <CircularProgressLoader visible={isLoading} label={'Loading Document...'} />
            {!isLoading && docUnitDOM.length > 0 && docUnitDOM}
          </div>
        </div>

        <Footer
          ref={this.editingPageRef}
          selectedUnits={this.state.selectedUnits}
          // pass thru' to FooterActions:
          onAction={this._handleFooterActions}
          onZoom={(isZoomed) => this.setState({ isZoomed: isZoomed })}
          docVisualStates={this.state.docVisualStates}
        />
        <DocumentOverlay showDocumentOverlay={this.state.showDocumentOverlay} onCloseRequest={this._closeOverlay} />
      </div>
    );
  }
}
