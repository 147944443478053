import * as React from 'react';
import { IMedia } from 'mm-types';

interface Props {
  media: IMedia;
}

const Dimensions = (props: Props) => {
  return (
    <ul className="info">
      <li>
        <label>Media type</label>
      </li>
      <li>{props.media.mimeType}</li>
      <li>
        <label>File size</label>
      </li>
      <li>{props.media.friendlySize}</li>
      <li>
        <label>File dimensions</label>
      </li>
      <li>
        {props.media.metadata.width} x {props.media.metadata.height}
      </li>
    </ul>
  );
};

export default Dimensions;
