/* FRAMEWORK */
import * as React from 'react';
import { IconButton } from 'material-ui';
/* STORES */
import ProjectDefinitionStore, { ProjectDefinitionStoreEventType } from '../../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
/* LOCAL DEPENDENCIES */
import ToolbarMenu from '../../ToolbarMenu';
/* UTILS */
import StringUtil from '../../../../../utils/StringUtil';
import { arrayToMap } from '../../../../../utils';
/* TYPES */
import { IElementDefinition, ElementStyleDefinition } from 'mm-types';
import SvgIcon from 'material-ui/SvgIcon';

interface Props {
  disabled: boolean;
  isElementInsertable(
    elementId: string,
    insertAtLevel: number
  ): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null };
}
interface State {
  elementStylesByElementId: Map<string, ElementStyleDefinition>;
  editRibbonAdditionalElements: IElementDefinition[];
}

export default class AdditionalElements extends React.Component<Props, State> {
  private unsubs: Function[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      elementStylesByElementId: new Map<string, ElementStyleDefinition>(),
      editRibbonAdditionalElements: []
    };
  }

  onProjectDefinitionStoreUpdate(e) {
    if (e.type && e.type === ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS) {
      const { elementStylesByElementId, editRibbonAdditionalElements } = this.getStateValues();
      this.setState({
        elementStylesByElementId,
        editRibbonAdditionalElements
      });
    }
  }

  public componentDidMount() {
    this.unsubs.push(ProjectDefinitionStore.listen(this.onProjectDefinitionStoreUpdate, this));
    const { elementStylesByElementId, editRibbonAdditionalElements } = this.getStateValues();
    this.setState({
      elementStylesByElementId,
      editRibbonAdditionalElements
    });
  }

  public componentDidUpdate() {
    const { elementStylesByElementId, editRibbonAdditionalElements } = this.getStateValues();

    this.setState({
      elementStylesByElementId,
      editRibbonAdditionalElements
    });
  }

  public componentWillUnmount() {
    this.unsubs.forEach((cb) => cb());
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextState.editRibbonAdditionalElements.length !== this.state.editRibbonAdditionalElements.length ||
      nextProps.disabled !== this.props.disabled
    );
  }

  render() {
    const { anyItemEnabled, additionalElements } = this.renderAdditionalElements();
    const disabled = this.props.disabled || !anyItemEnabled;
    if (!additionalElements.length) {
      return null;
    }
    return (
      <li
        key="additional-elements-dropdown"
        data-action="additionalElementsDropdown"
        className={'additional-elements-dropdown ' + (disabled ? 'disabled' : '')}
      >
        <ToolbarMenu
          title="Additional Elements"
          popoverClassname="additional-elements-popover"
          onSelect={(element) => {
            this.insertAdditionalElement(element);
          }}
          options={additionalElements}
          disabled={disabled}
          minWidth={168}
        >
          <div>
            <IconButton disabled={this.props.disabled} style={{ padding: 0 }} iconClassName="material-icons" tooltip="Additional Elements">
              create
            </IconButton>
            <SvgIcon>
              <path d="M7 10l5 5 5-5z" />
            </SvgIcon>
          </div>
        </ToolbarMenu>
      </li>
    );
  }

  private insertAdditionalElement(element) {
    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
    if (tinyFacade !== null) {
      const def = this.state.editRibbonAdditionalElements.find((def) => def.id === element);
      if (def && def.templateHtml) {
        tinyFacade.insertAdditionalElement(def);
      }
    }
  }

  private getStateValues(): State {
    const elementStylesByElementId = this.getElementStylesByElementId();
    const editRibbonAdditionalElements = ProjectDefinitionStore.getEditRibbonAdditionalElements();
    return { elementStylesByElementId, editRibbonAdditionalElements };
  }

  private getElementStylesByElementId() {
    return ProjectDefinitionStore.state.currentProjectDefinition && ProjectDefinitionStore.state.currentProjectDefinition.styleDefinition
      ? arrayToMap<ElementStyleDefinition, 'elementId'>(
          ProjectDefinitionStore.state.currentProjectDefinition!.styleDefinition.elementStyles,
          'elementId'
        )
      : new Map();
  }

  private renderAdditionalElements(): {
    anyItemEnabled: boolean;
    additionalElements: { label: string; value: string; disabled: boolean }[];
  } {
    let anyItemEnabled = false;
    const additionalElements = this.state.editRibbonAdditionalElements.map((inlineElementDefinition) => {
      const elementStyles = this.state.elementStylesByElementId.get(inlineElementDefinition.id);

      inlineElementDefinition.styles = elementStyles ? elementStyles.styles : [];
      const disabled = this.props.isElementInsertable(inlineElementDefinition.id, 0).disabled;
      if (!disabled) {
        anyItemEnabled = true;
      }

      return {
        label: StringUtil.formatForScreen(inlineElementDefinition.displayName),
        value: inlineElementDefinition.id || '',
        disabled: this.props.disabled || disabled
      };
    });
    return { anyItemEnabled, additionalElements };
  }
}
