import * as React from 'react';
import * as _ from 'lodash';
import { transitionTo } from '../../utils/Navigation';
import { UnitTypes } from './utils/units/UnitTypes';
import SmartContentStore, { SmartContentStoreEvent } from '../../flux/editor/SmartContentStore';
import { VisualStates, VisualStatesID } from './utils/DocumentVisualStates';
import { PostUnitRenderActionType } from '../../flux/editor/PostUnitRenderActions';
import EditorStore, { RenderOptions, RetrieveUnitParams } from '../../flux/editor/EditorStore';
import UnitTaskStore, { UnitTaskStoreEvent } from '../../flux/editor/UnitTaskStore';
import MergeRevisionsStore, { MergeRevisionsStoreEvent } from '../../flux/editor/MergeRevisionsStore';
import EditorModes, { EditorModes as EditorModesTypes, RefreshTypes } from '../../flux/editor/EditorModes';
import TocStore, { TocStoreEvent } from '../../flux/editor/TocStore';
import RevisionStore, { RevisionStoreEvent } from '../../flux/editor/RevisionStore';
import ProjectStore from '../../flux/editor/ProjectStore';
import ChangeTasksStore, { ChangeTasksStoreEvent } from '../../flux/editor/ChangeTasksStore';
import SystemStore from '../../flux/common/SystemStore';
import LinkStore from '../../flux/editor/LinkStore';
import FindReplaceStore from '../../flux/editor/FindReplaceStore';
import SpellCheckStore from '../../flux/editor/SpellcheckStore';
import ProjectDefinitionStore, {
  ProjectDefinitionStoreEvent,
  ProjectDefinitionStoreEventType
} from '../../flux/common/ProjectDefinitionStore';
import Log from '../../utils/Log';
import AgentUtil from '../../utils/AgentUtil';
import RecentStore from '../../flux/common/RecentStore';
import { DatePicker, FlatButton } from 'material-ui';
import SubActionsContainer from './sidetabs/sub/SubActionsContainer';
import MainActionsContainer from './sidetabs/main/MainActionsContainer';
import DocumentStage from './document/DocumentStage';
import EditorNav from './EditorNav';
import { WorkflowData } from './workflowaction/WorkflowActionModal';
import DocumentEmitter from './document/components/DocumentEmitter';
import {
  EventStoreEventType,
  Hotspot,
  HotspotsModalData,
  IEditorStoreEvent,
  IIndex,
  IProject,
  IRevision,
  IRouteParams,
  ISharedIndexUsages,
  ISnackbarMessage,
  ITocNode,
  IUnit,
  LinkSource,
  ModalActionProps,
  OpenDocData,
  TinyAction
} from 'mm-types';
import { InsertAction } from './menus/insert/content/ContentMenuContainer';
import { match } from 'react-router';
import IndexEventStore, { IndexEventStoreEvent } from '../../flux/events/IndexEventStore';
import { ATTRIBUTES } from './utils/tinyFacade/tinyLinkHelper';
import QueryUtil, { QueryParams } from '../../utils/QueryUtil';
import { AxiosError } from 'axios';
import { DefaultErrorBoundary } from '../general/DefaultErrorBoundary';
import { getErrorDetails } from '../../clients/base-clients-interceptors';
import { handle } from './utils/EditorErrorHandler';
import ServerSettingsStore from '../../flux/common/ServerSettingsStore';
import { ElementTypes } from './utils/units/ElementTypes';
import { MenuInsertOnSelectParams } from './menus/insert/MenuInsert';
import { MenuWorkflowOnSelectParams } from './menus/MenuWorkflow';
import { MenuFileOnSelectParams } from './menus/file/MenuFile';
import { MenuEditOnSelectParams } from './menus/edit/MenuEdit';
import appStore from '../../appStore';
import { showSystemSnackbarMessage } from '../misc/SystemSnackbar/thunks';
import { ActionEvent } from './docUnit/DocUnit';
import { CutCopyPasteUtil } from '../../utils/CutCopyPasteUtil';
import MediaStore, { MediaStoreEvent, MediaStoreEventType } from '../../flux/editor/MediaStore';
import EditorModals from './EditorModals';
import { MediaLibModalInfo } from './medialib/MediaLibModal';
import { MediaInsertUtils } from './medialib/utils/mediaInsertUtils';
import { Link } from 'react-router-dom';

/*
    TODO note that this component and its DocumentStage/Document is in transition
    for CS3 it will support dual display (but not dual editing yet)
    as part of this process it will be slowly refactored to allow multiple editing for CS3+
    This will mean moving a lot of the functionality in here out to Document which will then become re-usable
    first step to this approach will be to drop props passed thru to DocumentStage/Document from this component
    and instead rely on EditorStore events. For now the UX will be capable of rendering a "mock" dual mode
    as a means of getting there step by step.

    Also: the hard/soft/handleChanged refresh concepts can be moved into the EditorStore, with simple re-rendering the responsibility of EditorPage/Document
    i.e. responding to a simple EditorStore "refresh" event - no reason for any of this logic to sit inside EditorPage anymore...
 */

window['testData'] = { editorLastRefreshType: '' };

type ExtendedLocation = Location & {
  state: {
    lastComponent: string;
  };
};

export type Props = {
  match: match<IRouteParams>;
  location: ExtendedLocation;
};

export type State = {
  project?: IProject;
  index?: IIndex;
  docUnits: null | IUnit[];
  startUnitUid?: string;
  selectedTocItem: null | ITocNode;
  isSingleVol: boolean;
  revisions?: IRevision[];
  showAlert?: null | {
    props: {
      actions: JSX.Element[];
      title: string;
      modal?: null | boolean;
      onRequestClose: () => void;
      contentClassName?: string;
    };
    message: string | JSX.Element;
  };
  showGlobalDatePicker?: null | {
    clientId?: string;
    props: {};
    ref: React.RefObject<DatePicker>;
  };
  showFullpageModal?: FullPageModalOptions;
  mediaLibModalInfo: MediaLibModalInfo;
  linkModal: {
    open: boolean;
    isDuRefLink: boolean;
    templateHtml?: HTMLElement;
  };
  showLinkAnchorModal: boolean;
  showHotspotsModal: boolean;
  showRefIntModal: boolean;
  hotspotsData: HotspotsModalData | null;
  editingMode: string;
  editorPageOutline: boolean;
  showDiffOverlay: boolean;
  styleLoaded: boolean;
};

export type InsertInfo = {
  inline: boolean;
  insertPoint?: HTMLElement | null;
  insertPosition?: InsertAction;
  type?: UnitTypes | ElementTypes | null;
  selectedMediaItemUid?: string;
};

export type InlineOptions = {
  affectedUnit?: null | IUnit;
  affectedUnits?: null | string[];
  affectedParts?: null | string[];
  affectsAllUnits?: boolean;
};

export type FullPageModalOptions = null | {
  mergerevision?: boolean;
  unmountEditor?: boolean;
  type: 'medialib' | 'mergerevision' | 'powerpaste' | 'workflowaction' | '';
  data?: Partial<WorkflowData>;
  isShortcut?: boolean;
};

export default class EditorPage extends React.Component<Props, State> {
  private _postUnitsRender: null | {
    scrollToUnitUid: string;
    scrollToElementNid?: string;
    highlightTocUid?: string;
    query?: { [prop: string]: string | undefined } | null;
  };

  private unsubscribes: Function[];
  private _defaultUnitsRequired: number;

  globalDatepickerRef: React.RefObject<DatePicker>;

  constructor(props: Props) {
    super(props);

    this._postUnitsRender = null;
    this._defaultUnitsRequired = ServerSettingsStore.getServerSettings().ivsUnitsPageSize ?? EditorStore.unitsRequiredPerRequest;
    this.globalDatepickerRef = React.createRef();

    this.state = {
      project: undefined,
      index: undefined,
      docUnits: null,
      selectedTocItem: null,
      isSingleVol: false,
      mediaLibModalInfo: { insertInfo: null, viewMode: true },
      linkModal: { open: false, isDuRefLink: false },
      showHotspotsModal: false,
      showLinkAnchorModal: false,
      showRefIntModal: false,
      hotspotsData: null,
      editingMode: EditorStore.getMode(),
      showDiffOverlay: false,
      styleLoaded: false,
      editorPageOutline: false // document/editor visual setting that applies to whole page
    };
  }

  /** *****************************************************************
   Mount/Unmount lifecycle
   */

  UNSAFE_componentWillMount() {
    if (!AgentUtil.isEditorSupported()) {
      this._exitEditor();
    }
  }

  componentDidMount() {
    this.unsubscribes = [
      RevisionStore.listen(this._onRevisionStoreEvent, this),
      ProjectDefinitionStore.listen(this._onProjectDefinitionStoreEvent, this),
      TocStore.listen(this._onTocStoreUpdate, this),
      EditorStore.listen(this._onEditStoreUpdate, this),
      SmartContentStore.listen(this._onShareContentUpdate, this),
      MergeRevisionsStore.listen(this._onMergeStoreUpdate, this),
      IndexEventStore.listen(this._onIndexEvent, this),
      UnitTaskStore.listen(this._onUnitTaskStoreEvent, this),
      ChangeTasksStore.listen(this._onChangeTasksStoreEvent, this),
      MediaStore.listen(this._onMediaStoreEvent, this)
    ];
    this._loadPage(this.props, { isNewInit: true });
  }

  // important: below assumes only prop changes that can occur on doc are route changes,
  // thus always requiring document load of some kind on props change (except if script/disconnection error triggered)
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!SystemStore.isPendingErrorReload() && !EditorStore.isBusy()) {
      // different doc to current:
      if (
        this.props.match.params.projectUid !== nextProps.match.params.projectUid ||
        this.props.match.params.documentIndexUid !== nextProps.match.params.documentIndexUid
      ) {
        this._loadPage(nextProps, { isReInit: true });
      } else {
        const query: QueryParams | null = _.isEmpty(nextProps.location.search) ? null : QueryUtil.fromString(nextProps.location.search);

        // ignore for editor and go straight to post render trigger
        if (query && query.target === 'secondarydoc') {
          EditorStore.triggerPostUnitRender(_.cloneDeep(query));
        } else {
          this._loadPage(nextProps, { isNoInit: true }); // in same doc, no need to init editor
        }
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    IndexEventStore.leaveCurrentIndex();
    // this is  last ditch territory: we need to ensure editor has killed tiny instance when route is left
    // when user driven url change we can ensure any tiny instance is saved, and editor shut down,
    // however for browser driven url change (back/next) we must abandon editing in sync fashion (async attempt to save will cause component mount issues)
    EditorStore.abandonEditing();
    EditorStore.destroy();
  }

  /** *****************************************************************
   Doc load and (re) initialization
   */

  private _loadPage(
    props: Props,
    loadType: { isNewInit?: boolean; isNoInit?: boolean; isReInit?: boolean; action?: PostUnitRenderActionType } = {
      isNewInit: true
    },
    query?: { action: PostUnitRenderActionType }
  ) {
    if (_.filter(props.match.params, (v, key) => key === 'documentIndexUid' || key === 'projectUid').length) {
      console.info('Editor Page - loadPage');

      // only a unit uid is available, we need tocableUnitUid
      if (!props.match.params.tocableUnitUid && props.match.params.targetUnitUid) {
        EditorStore.getUnitTocableUnitUid(props.match.params.targetUnitUid, {
          projectUid: props.match.params.projectUid,
          indexUid: props.match.params.documentIndexUid
        }).then((result) => {
          props.match.params.tocableUnitUid = result.targetVolumeUid;
          this._loadPage(props, loadType);
        });
      }

      // we have everything we need - init the editor
      else {
        const search = props.location.search.substring(1);
        let queryJSON = query || {};
        if (search.length > 0) {
          queryJSON = QueryUtil.fromString(search);
        }

        this._setPostUnitsRender(
          props.match.params.targetUnitUid,
          props.match.params.targetElementNid,
          props.match.params.tocableUnitUid,
          queryJSON
        );

        if (loadType.isNewInit) {
          Log.info('Editor Page - open new project: ' + props.match.params.projectUid + ', index: ' + props.match.params.documentIndexUid);
          this._initialize(props.match.params);
        } else if (loadType.isReInit) {
          Log.info(
            'Editor Page - open new project while editor mounted: ' +
              props.match.params.projectUid +
              ', props: ' +
              props.match.params.documentIndexUid
          );
          this._reInitialize(props.match.params);
        } else if (loadType.isNoInit) {
          Log.info('Editor Page - navigate to toc unit: ' + props.match.params.tocableUnitUid);
          this._loadTocSelectedUnits(props.match.params.targetUnitUid);
        }
      }
    } else {
      this._exitEditor();
    }
  }

  private async _initialize(params: any) {
    try {
      EditorStore.triggerEditorStoresInitializing();
      RecentStore.sendIndexUsage(params.documentIndexUid);

      await ProjectStore.initProject(params);
      await EditorStore.initEditor(params);

      EditorStore.triggerEditorStoresInitialized();
    } catch (error) {
      Log.info('Editor Page - error init project');
      handle(error);
    }
  }

  private _reInitialize(params: any) {
    IndexEventStore.leaveCurrentIndex();

    this.setState({ docUnits: [], startUnitUid: undefined }, () => {
      this._initialize(params);
    });
  }

  private _setPostUnitsRender(
    scrollToUnitUid: string,
    scrollToElementNid?: string,
    highlightTocUnitUid?: string,
    query?: { [prop: string]: string | undefined } | null
  ) {
    query = _.isEmpty(query) ? null : query;

    if (!scrollToUnitUid && !scrollToElementNid && !highlightTocUnitUid && !query) {
      this._postUnitsRender = null;
    } else {
      this._postUnitsRender = {
        scrollToUnitUid: scrollToUnitUid,
        scrollToElementNid: scrollToElementNid,
        highlightTocUid: highlightTocUnitUid,
        query: query
      };
    }
  }

  // loads to page whatever is selected in the TOC
  private _loadTocSelectedUnits(offsetUnitUid?: string): Promise<void> {
    return new Promise((resolve) => {
      const load = () => {
        Log.info('Editor Page - load units given selected toc item...');

        if (TocStore.getSelectedItem()) {
          let additionalParams = {};
          const selectedTocItem = TocStore.getFirstSelectedTocableUnit()!;

          // if h1+ toc item selected (and nothing else is using _postUnitsRender) ensure we scroll to the header and not its default toc item
          if (this._postUnitsRender === null && selectedTocItem !== TocStore.getSelectedItem()) {
            this._setPostUnitsRender(TocStore.getSelectedItem()!.uid);
            additionalParams = {
              offsetUnitUid: offsetUnitUid
            };
          }

          const params: RetrieveUnitParams = Object.assign(
            {
              includeHeader: true,
              tocableUnitUid: selectedTocItem.uid,
              unitsRequired: this._defaultUnitsRequired
            },
            additionalParams
          );

          const type = selectedTocItem.type.toLowerCase();
          if (type === 'volume' || type === 'frontmatter') {
            params.unitsRequired = 1;
          }

          window.testData.editorLastRefreshType = 'hardReloadPageCleared';

          this.setState(
            {
              // ensure latest MODE is set
              editingMode: EditorStore.getMode(),

              // clear any pre-exiting modals
              showFullpageModal: null,
              showGlobalDatePicker: null,
              linkModal: { open: false, isDuRefLink: false },
              showHotspotsModal: false,
              hotspotsData: null,
              showAlert: null,

              // show a spinner before loading
              docUnits: null,
              startUnitUid: undefined
            },
            () => {
              ProjectStore.onTocChange(this.props.match.params);
              EditorStore.retrieveUnits(params).then(() => {
                this._renderDocumentUnits();
                resolve();
              });
            }
          );
        }
      };

      if (this._postUnitsRender && this._postUnitsRender.highlightTocUid) {
        const tocItem = TocStore.getTocItem(this._postUnitsRender.highlightTocUid);

        if (!tocItem) {
          Log.info("Editor Page - post unit render information found - highlight toc not possible as doesn't exist, defaulting to first");
        }

        TocStore.highlightTocItem(tocItem ?? TocStore.getFirstSelectedTocableUnit(), () => {
          load();
        });
      } else {
        TocStore.highlightTocItem(TocStore.getFirstSelectedTocableUnit(), () => {
          load();
        });
      }
    });
  }

  private _renderDocumentUnits() {
    const selectedTocItem = TocStore.getFirstSelectedTocableUnit();

    if (!selectedTocItem) {
      return;
    }

    TocStore.setStartUnit(selectedTocItem);
    this.setState(
      {
        project: ProjectStore.getProject(),
        index: ProjectStore.getIndex(),
        docUnits: EditorStore.getDocUnits(),
        startUnitUid: selectedTocItem.uid,
        selectedTocItem: selectedTocItem,
        isSingleVol: TocStore.isSingleVolume()!
      },
      () => {
        if (this._postUnitsRender) {
          if (this._postUnitsRender.scrollToUnitUid) {
            EditorStore.scrollToUnit(this._postUnitsRender.scrollToUnitUid, this._postUnitsRender.scrollToElementNid);
          }

          this._postUnitsRender.query && EditorStore.triggerPostUnitRender(_.cloneDeep(this._postUnitsRender.query));
          this._postUnitsRender = null;
        }
      }
    );
  }

  private _getRevisions() {
    let revisions = [...(RevisionStore.getRevisions() || [])];

    // sort chronologically descending
    revisions = revisions.sort((a, b) => {
      if (typeof a.revisionDate === 'string' && typeof b.revisionDate === 'string') {
        const aD = new Date(a.revisionDate);
        const bD = new Date(b.revisionDate);
        return aD > bD ? -1 : aD < bD ? 1 : 0;
      }
      return 0;
    });

    return revisions;
  }

  /** *****************************************************************
   Store event listening
   */

  private _onProjectDefinitionStoreEvent(event: ProjectDefinitionStoreEvent) {
    if (event.snackBarMessage) {
      this._makeSnackbar({ message: event.snackBarMessage });
    } else if (event.type === ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS) {
      EditorPage._updatePageOutlineWidth(this.state.editorPageOutline);
    }
  }

  private _onRevisionStoreEvent(event: RevisionStoreEvent) {
    if (event.error) {
      handle(event.error);
    }

    if (event.revisions) {
      this.setState({ revisions: this._getRevisions() });
    }
  }

  public transitionTo(path: string) {
    transitionTo(path);
  }

  private _onTocStoreUpdate(stateUpdate: TocStoreEvent) {
    // deal with any store errors
    let error: AxiosError | null = null;

    if (stateUpdate.type === 'error' && stateUpdate.data) {
      error = stateUpdate.data.error!;
    }

    if (stateUpdate.snackMessage) {
      this._makeSnackbar({ message: stateUpdate.snackMessage });
    }

    if (error) {
      handle(error);
    }

    if (stateUpdate.type) {
      if (stateUpdate.type === 'selectTocItem') {
        if (EditorStore.isMode('SPELLCHECK')) {
          SpellCheckStore.executeSpellcheck();
        }
        transitionTo('editor-edit', {
          projectUid: EditorStore.getDocParams().projectUid,
          indexUid: EditorStore.getDocParams().indexUid,
          targetUnitUid: stateUpdate.data.selected?.uid,
          tocableUnitUid: stateUpdate.data.selected?.uid
        });
      } else if (stateUpdate.type === 'refreshToc') {
        if (stateUpdate.forceDocRefresh) {
          if (EditorStore.isMode('SPELLCHECK')) {
            SpellCheckStore.executeSpellcheck();
          }
          transitionTo('editor-edit', {
            projectUid: EditorStore.getDocParams().projectUid,
            indexUid: EditorStore.getDocParams().indexUid,
            targetUnitUid: stateUpdate.data.selected?.uid,
            tocableUnitUid: stateUpdate.data.selected?.uid
          });
        }
      }
    }
  }

  private _onShareContentUpdate(event: SmartContentStoreEvent) {
    if (event.type === 'sharedUsageDeleted' || event.type === 'sharedUsageUpdated') {
      EditorStore.triggerUnitsChanged(
        (event.sharedContent as ISharedIndexUsages)?.units,
        { parse: true },
        { isDelete: event.type === 'sharedUsageDeleted' }
      );
    } else if (event.type === 'complianceTagUnits') {
      this._makeSnackbar({ message: 'Element(s) tagged successfully' });
    } else if (event.type === 'retrieveUnitComplianceTagMap' || event.type === 'updateRegulation') {
      this._hardInlineRefreshCurrentPage();
    } else if (event.type === 'regulationTaggingError') {
      this._makeSnackbar({ message: 'Volumes, Chapters & Sections cannot be tagged with a regulation' });
    }
  }

  private _onIndexEvent(e: IndexEventStoreEvent) {
    if (e.activity === 'publishCompleted') {
      // you are looking at a draft which has just been published, reload as read-only,
      // and if publishing user is not ME, give them a dialog of options, otherwise just reload read-only published version

      const dismissPublishNotif = (transitionToDraft) => {
        this.setState({ showAlert: null }, () => {
          if (transitionToDraft) {
            transitionTo('editor-edit', {
              projectUid: this.props.match.params.projectUid,
              indexUid: e.data.masterIndexUid
            });
          }
        });
      };

      const showPublishNotif = () => {
        if (!e.isUserMe) {
          this.setState({
            showAlert: {
              props: {
                actions: [
                  <FlatButton
                    key={1}
                    label="Open as new draft"
                    onClick={() => {
                      dismissPublishNotif(true);
                    }}
                  />,
                  <FlatButton
                    key={2}
                    label="Stay in published"
                    onClick={() => {
                      this._reInitialize(this.props.match.params);
                    }}
                  /> // reload as read-only
                ],
                title: 'Document Published',
                onRequestClose: () => {
                  dismissPublishNotif(false);
                }
              },

              message: 'This document has just been published by ' + e.user.displayName + '.'
            }
          });
        }
      };

      EditorStore.blurEditor(() => showPublishNotif(), { ignoreChanges: true });
    } else if (e.activity === 'affectedUnits') {
      // ordering essential here as TOCStore must be synchronized before EditorStore (executed thru' actions as store to store comms couldn't enforce ordering)
      const result = TocStore.synchronizeToc(e);

      result.then((pageRenderDirectives) => {
        EditorStore.synchronizeEditor(e, pageRenderDirectives || {});
      });
    } else if (!e.isUserMe && (e.activity === 'sharedContentAdded' || e.activity === 'sharedContentRemoved')) {
      this._refreshPage('HARD_REFRESH');
    }
  }

  private _onUnitTaskStoreEvent(e: UnitTaskStoreEvent) {
    if (e.snackbar) {
      this._makeSnackbar({ message: e.snackbar });
    }
  }

  private _onChangeTasksStoreEvent(e: ChangeTasksStoreEvent) {
    if (e.snackbar) {
      this._makeSnackbar({ message: e.snackbar });
    }
    if (e.error) {
      handle(e.error);
    }
  }

  private _onMediaStoreEvent(e: MediaStoreEvent<MediaStoreEventType>) {
    if (e.type === 'openMediaLib' || e.type === 'openMediaLibViewOnly') {
      const event = e as MediaStoreEvent<'openMediaLib' | 'openMediaLibViewOnly'>;
      this._openMediaLibModal(event.data, e.type === 'openMediaLibViewOnly', false);
    }
  }

  private _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'scrollToUnit' || e.type === 'nestedUnitFocusChange') {
      EditorStore.toggleShareDiff(false);
    } else if (e.type === 'retrieveUnitInfos') {
      Log.info('Editor Page - initial render on unit infos');
      this._renderDocumentUnits();
    } else if (e.type === 'editorStoresInitialized') {
      Log.info('Editor Page - (re)initialized successfully...');

      this._loadTocSelectedUnits();
    } else if (e.type === 'modalAction') {
      const castEvent = e as IEditorStoreEvent<'modalAction'>;
      if (castEvent.modalProps?.type === 'link') {
        this.setState({
          linkModal: {
            open: castEvent.modalProps.show ?? false,
            isDuRefLink: castEvent.modalProps.isDuRef ?? false,
            templateHtml: castEvent.modalProps?.templateHtml
          }
        });
      } else if (castEvent.modalProps?.type === 'hotspots') {
        if (castEvent.modalProps?.show !== this.state.showHotspotsModal) {
          const figureElement = EditorStore.getEditor().getActiveEditorFacade()?.getFigure();
          if (figureElement) {
            const $figureEl = $(figureElement);
            const $hotspotElements = $figureEl?.find('div.arc-hot-spot');
            const hotspots: Hotspot[] = [];

            if ($hotspotElements) {
              $hotspotElements.each((index) => {
                // get hotspot's string coordinates
                const topStr: string | null = $hotspotElements[index]?.style.top,
                  leftStr: string | null = $hotspotElements[index]?.style.left,
                  widthStr: string | null = $hotspotElements[index]?.style.width,
                  heightStr: string | null = $hotspotElements[index]?.style.height;

                // parse hotspot's strings coordinates into numbers, strip off last character %
                const top: number = topStr ? parseFloat(topStr.substr(0, topStr.length - 1)) : 0,
                  left: number = leftStr ? parseFloat(leftStr.substr(0, leftStr.length - 1)) : 0,
                  width: number = widthStr ? parseFloat(widthStr.substr(0, widthStr.length - 1)) : 0,
                  height: number = heightStr ? parseFloat(heightStr.substr(0, heightStr.length - 1)) : 0;

                // copy all anchor ATTRIBUTES into a linkData
                const linkData: any = {};
                const anchorElm: Element = $hotspotElements[index].children[0];

                ATTRIBUTES.forEach(function (attr) {
                  linkData[attr] = anchorElm.getAttribute(attr);
                });
                linkData.target = anchorElm.getAttribute('target');

                const hotspot: Hotspot = {
                  top: top,
                  left: left,
                  width: width,
                  height: height,
                  linkData: linkData,
                  nid: $hotspotElements[index].getAttribute('data-nid')
                };
                hotspots.push(hotspot);
              });
            }

            const hotspotsData: HotspotsModalData = {
              src: $figureEl.find('img').attr('src'),
              hotspots: hotspots
            };

            this.setState({ showHotspotsModal: castEvent.modalProps.show, hotspotsData: hotspotsData });
          }
        }
      } else if (castEvent?.modalProps?.type === 'refint') {
        this.setState({ showRefIntModal: castEvent?.modalProps.show });
      }
    } else if (e.type === 'replaceUnit') {
      this._softInlineRefreshCurrentPage({ affectsAllUnits: true });
    } else if (e.type === 'loadMoreUnits') {
      this._softInlineRefreshCurrentPage({ affectsAllUnits: true }, true);
    } else if (e.type === 'publishSharedOrigin') {
      this._hardInlineRefreshCurrentPage();
    } else if (e.type === 'diffSharedContentUpdate') {
      const castEvent = e as IEditorStoreEvent<'diffSharedContentUpdate'>;
      EditorStore.toggleShareDiff(castEvent.data!.isDiffOn);
      this.setState({
        showDiffOverlay: true
      });
      if (castEvent.data!.isDiffOn || castEvent.data!.isNew) {
        this._softInlineRefreshCurrentPage({ affectsAllUnits: true });
      } else {
        this._hardInlineRefreshCurrentPage();
      }
    } else if (e.type === 'selectShareOrigin') {
      this._softInlineRefreshCurrentPage({ affectsAllUnits: true });
    } else if (e.type === 'synchronizeEditor') {
      const castEvent = e as IEditorStoreEvent<'synchronizeEditor'>;
      let refreshAction: Promise<void> | null;

      if ((castEvent.data!.affectedUnits.length || castEvent.data!.affectedParts.length) && !castEvent.data!.refreshView) {
        refreshAction = this._softInlineRefreshCurrentPage({
          affectedUnits: castEvent.data!.affectedUnits,
          affectedParts: castEvent.data!.affectedParts
        });
      } else {
        refreshAction = this._hardInlineRefreshCurrentPage(
          this.state.selectedTocItem && this.state.selectedTocItem.type === 'volume' ? true : false,
          true,
          castEvent.data!.changedTocDisplayName
        );
      }

      refreshAction.then(() => {
        if (castEvent.data!.notifyUser) {
          this._makeSnackbar({
            message: ' has changed content on this page',
            user: castEvent.data!.user,
            autoHideDuration: 4000
          });
        }
      });
    } else if (e.type === 'activeEditorChange') {
      const castEvent = e as IEditorStoreEvent<'activeEditorChange'>;
      if (e.data) {
        this._makeSnackbar({
          message: castEvent.data!.activity === 'joined' ? ' has Joined' : ' has Left',
          user: castEvent.data!.user
        });
      }
    } else if (e.type === 'indexDiscarded') {
      if (e.data) {
        EditorStore.changeModeStart('EDITING_BLOCKED');
      }
    } else if (e.type === 'indexLocked') {
      const castEvent = e as IEditorStoreEvent<'activeEditorChange'>;
      if (e.data) {
        this._makeSnackbar({
          message: ' locked index for modifications temporarily',
          user: castEvent.data!.user
        });
      }
    } else if (e.type === 'indexUnlocked') {
      if (e.data) {
        this._makeSnackbar({
          message: 'Index is now unlocked'
        });
      }
    } else if (
      e.type === 'convertBatchUnits' ||
      e.type === 'formatSelectedUnits' ||
      e.type === 'deleteUnitConfirm' ||
      e.type === 'updateUnit' ||
      e.type === 'splitSelectedUnits' ||
      e.type === 'createUnit' ||
      e.type === 'deleteSelectedUnitsConfirm' ||
      e.type === 'createBatchUnits' ||
      e.type === 'undoredo'
    ) {
      const castEvent = e as IEditorStoreEvent<
        | 'convertBatchUnits'
        | 'formatSelectedUnits'
        | 'deleteUnitConfirm'
        | 'updateUnit'
        | 'splitSelectedUnits'
        | 'createUnit'
        | 'deleteSelectedUnitsConfirm'
        | 'createBatchUnits'
        | 'undoredo'
      >;

      this._handleDocumentChanged(
        castEvent.data!.unit as IUnit,
        castEvent.data!.affectedUnits!,
        castEvent.data!.affectedParts!,
        castEvent.data!.refreshView!,
        castEvent.data!.renderBehaviour
      );
    }

    // TODO inefficient version of _handleDocumentChanged, in time api's that rely on this rendering should update to provide affectedUnit info so optimum rendering can be applied as efficiently as possible:
    else if (e.type === 'unitsChanged' || e.type === 'unitMoved') {
      const castEvent = e as IEditorStoreEvent<'unitsChanged' | 'unitMoved'>;
      this._handleDocumentChangedComplex(castEvent.data!.unit, castEvent.data!.renderBehaviour as RenderOptions);
    }

    // changeModeStart is a 2 step process, start: deactivate existing and refresh units accordingly, complete: activate new and refresh units accordingly
    else if (e.type === 'changeModeStart') {
      const castEvent = e as IEditorStoreEvent<'changeModeStart'>;
      console.info('EditorPage: handle change mode: from: ' + castEvent.data!.from + ', to: ' + castEvent.data!.to);

      // TODO if changed to EDITING mode: do some specialist behaviour: would be nice not to have to do this...
      if (EditorStore.isMode('EDITING')) {
        if (castEvent.data!.from === 'SPELLCHECK' && SpellCheckStore.currentWord()) {
          this._setPostUnitsRender(SpellCheckStore.currentWord().parent);
        } else if (castEvent.data!.from === 'FINDREPLACE' && FindReplaceStore.currentWord()) {
          this._setPostUnitsRender(FindReplaceStore.currentWord()!.parent);
        }
      }

      this.setState({ editingMode: castEvent.data!.to! }, () => {
        const activateRefreshType = EditorModes.getProperties(castEvent.data!.to as EditorModesTypes).activateTransition(
          castEvent.data!.from as EditorModesTypes
        );
        let deactivateRefreshType = EditorModes.getProperties(castEvent.data!.from as EditorModesTypes).deactivateTransition(
          castEvent.data!.to as EditorModesTypes
        );
        deactivateRefreshType = deactivateRefreshType === activateRefreshType ? 'NONE' : deactivateRefreshType; // i.e. don't perform initial refresh if u don't need to

        const result = this._refreshPage(deactivateRefreshType);

        result.then(() => {
          EditorStore.changeModeComplete(castEvent.data!);
          this._refreshPage(activateRefreshType);
        });
      });
    } else if (e.type === 'deleteUnit' || e.type === 'deleteSelectedUnits') {
      if (e.type === 'deleteUnit') {
        const castEvent = e as IEditorStoreEvent<'deleteUnit'>;
        let message: string | JSX.Element = 'Are you sure you wish to delete this element?';
        const inboundLinks = LinkStore.getInboundLinksTo(castEvent.data!.uid);
        if (inboundLinks.length > 0) {
          message = (
            <span>
              Are you sure you wish to delete this element?
              <div>
                There are <b>{inboundLinks.length}</b> link(s) from other documents linking to this element
              </div>
            </span>
          );
        }

        this._showDeleteConfirmation(
          'Delete ' +
            ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
              castEvent.data?.type === 'tocable' ? castEvent.data?.tocLevel! : castEvent.data!.type
            )?.displayName +
            '?',
          message,
          castEvent.data
        );
      } else {
        const selectedUnitsCount = EditorStore.getSelectedUnits().length;
        let message: string | JSX.Element = 'Are you sure you wish to delete these ' + selectedUnitsCount + ' elements?';

        const inboundLinks = LinkStore.getInboundUnitsLinksTo(EditorStore.getSelectedUnits());
        if (inboundLinks.length > 0) {
          message = (
            <span>
              Are you sure you wish to delete these {selectedUnitsCount} elements?
              <div>
                There are <b>{inboundLinks.length}</b> link(s) from other documents linking to some of these element
              </div>
            </span>
          );
        }

        this._showDeleteConfirmation('Confirm Multiple Deletion', message);
      }
    } else if (e.type === 'openDocumentPage') {
      transitionTo('editor-edit', e.data as OpenDocData);
    } else if (e.type === 'insertUnit') {
      const castEvent = e as IEditorStoreEvent<'insertUnit'>;
      const selected = EditorStore.getSelectedUnit();
      if (selected) {
        EditorStore.createUnit({ type: castEvent.data!.unitType }, selected.uid, {}, castEvent.data?.parentUnitUid);
      }
    } else if (e.type === 'inlineUnitAction') {
      this._handleInlineUnitAction(e.data as ActionEvent);
    } else if (e.type === 'postUnitRender') {
      const castEvent = e as IEditorStoreEvent<'postUnitRender'>;
      const query: Partial<{ action: PostUnitRenderActionType }> = castEvent.data! || {};
      if (query.action === 'START_MERGE') {
        this._openFullPageModal({ type: 'mergerevision', unmountEditor: true });
      }
    } else if (e.type === 'openGenericDateModal') {
      // why? because MUI and date pickers inside complex DOM is a nightmare, this makes available to anywhere in app for use thru' EditorActions
      const castEvent = e as IEditorStoreEvent<'openGenericDateModal'>;
      this.setState(
        {
          showGlobalDatePicker: {
            clientId: castEvent.clientId,
            props: castEvent.modalProps as ModalActionProps,
            ref: this.globalDatepickerRef
          }
        },
        () => {
          this.globalDatepickerRef.current?.openDialog();
        }
      );
    }

    // handle editing error scenarios
    else if (e.type === 'editorError') {
      const castEvent = e as IEditorStoreEvent<'editorError'>;

      const err = getErrorDetails(castEvent.data!.axiosErr);

      if (err.status === 409 && err.serverErrorCode === 40906) {
        this._handleUnitVersionConflictFailure(castEvent.data!.xhr, castEvent.data!.editorUpdate, castEvent.data!.completeCallback);
      } else if (err.status === 400 && err.serverErrorCode === 400329) {
        this._makeSnackbar({
          message: err.serverErrorMessage ?? 'Invalid Element insertion target',
          contentStyle: { lineHeight: '24px', paddingTop: '8px', paddingBottom: '8px' },
          bodyStyle: { minHeight: '48px', height: undefined }
        });
      } else if (err.status === 400 && err.serverErrorCode === 400330) {
        this._makeSnackbar({ message: 'Cannot perform deletion, as it results in an invalid structure' });
      } else {
        handle(castEvent.data?.axiosErr);
      }
    } else if (e.type === 'undoredoError') {
      this._makeSnackbar({ message: e.data === 'undo' ? 'Nothing to Undo' : 'Nothing to Redo' });
    } else if (e.type === 'variantPreviewError') {
      if (e.data) {
        const castEvent = e as IEditorStoreEvent<'variantPreviewError'>;
        this._makeSnackbar({ message: castEvent.data! });
      }
    } else if (e.type === 'variantTaggingError') {
      const event = e as IEditorStoreEvent<'variantTaggingError'>;
      this._showVariantTaggingError('Unable to tag content', event.data?.linkSource, event.message);
    }
  }

  private _onMergeStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'startMerge' || e.type === 'continueMerge') {
      // merge started: project and index will have changed, so full re-init of editor, and place an action for execution when doc is ready
      this._loadPage(this.props, { isReInit: true }, { action: 'START_MERGE' });
    }

    if (e.type === 'error' && e.snackMessage) {
      this._makeSnackbar({ message: e.snackMessage, autoHideDuration: 8000, style: { left: 280 } });
    }
  }

  private _refreshPage(refreshType: RefreshTypes) {
    Log.info('EditorPage: refresh page using refresh type: ' + refreshType);

    let refreshPromise: Promise<void> | null = null;

    if (refreshType === 'SOFT_REFRESH') {
      refreshPromise = this._softInlineRefreshCurrentPage({ affectsAllUnits: true });
    } else if (refreshType === 'HARD_REFRESH') {
      refreshPromise = this._hardInlineRefreshCurrentPage(
        this.state.selectedTocItem && this.state.selectedTocItem.type === 'volume' ? true : false
      );
    } else if (refreshType === 'RELOAD') {
      refreshPromise = this._loadTocSelectedUnits();
    } else if (refreshType === 'RELOAD_TOC') {
      refreshPromise = TocStore.refreshToc({ forceDocRefresh: true });
    } else {
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
    }

    return refreshPromise;
  }

  /** ***
   Full page modal lifecycle
   */

  private _openFullPageModal(modal: FullPageModalOptions) {
    this.setState({ showFullpageModal: modal });
  }

  private _closeFullPageModal(done?: () => void) {
    if (this.state.showFullpageModal) {
      // prevent an unneccesary editor re-render and all that implies on Document lifecycle
      this.setState({ showFullpageModal: null }, () => {
        if (done) {
          done();
        }
        EditorStore.onModalsClosed();
      });
    } else {
      if (done) {
        done();
      }
    }
  }

  private _openMediaLibModal(insertInfo: InsertInfo | null, viewMode: boolean, isShortcut: boolean) {
    this.setState({ showFullpageModal: { type: 'medialib', isShortcut }, mediaLibModalInfo: { insertInfo, viewMode } });
  }

  private _handleTopMenuTabSelect() {
    this._closeFullPageModal();
  }

  private _mountEditor() {
    return !this.state.showFullpageModal || (this.state.showFullpageModal && !this.state.showFullpageModal.unmountEditor);
  }

  private _closeLinkModal() {
    this.setState({ linkModal: { open: false, isDuRefLink: false, templateHtml: undefined } });
  }

  private _closeLinkAnchorModal() {
    this.setState({ showLinkAnchorModal: false });
  }

  private _closeHotspotModal() {
    this.setState({ showHotspotsModal: false }, () => {
      EditorStore.getEditor().silentReFocus();
    });
  }

  private _saveHotspotLinks(hotspotLinks: any) {
    this.setState({ showHotspotsModal: false }, () => {
      EditorStore.getEditor().getActiveEditorFacade()!.updateHotspotLinks(hotspotLinks);
      EditorStore.getEditor().silentReFocus();
    });
  }

  private _closeDatePicker() {
    this.setState({ showGlobalDatePicker: null });
  }

  private closeMergeRevisionModal() {
    this._closeFullPageModal(() => this._reInitialize(this.props.match.params));
  }

  private _hideDiffOverlay() {
    EditorStore.closeDiffMode();
    this.setState({
      showDiffOverlay: false
    });
  }
  /*
   Full page modal lifecycle - end
   *****/

  private _makeSnackbar({
    message = '',
    user = null,
    autoHideDuration = 5000,
    style = {},
    contentStyle = {},
    bodyStyle = {}
  }: Partial<ISnackbarMessage>) {
    appStore.dispatch<any>(
      showSystemSnackbarMessage({
        open: true,
        message: message,
        user: user,
        style: { bottom: '38px', ...style },
        autoHideDuration: autoHideDuration,
        contentStyle: contentStyle,
        bodyStyle: bodyStyle
      })
    );
  }

  private _showVariantTaggingError(title: string, linkSource: LinkSource | undefined, message: string | undefined) {
    this.setState({
      showAlert: {
        props: {
          actions: [
            <FlatButton
              key={1}
              label="Ok"
              onClick={() => {
                this.setState({ showAlert: null });
              }}
            />
          ],
          title: title,
          modal: null,
          onRequestClose: () => {
            this.setState({ showAlert: null });
          },
          contentClassName: 'delete-modal-actions'
        },
        message: this._getVariantTaggingErrorMessage(linkSource, message)
      }
    });
  }

  private _getVariantTaggingErrorMessage(linkSource: LinkSource | undefined, message: string | undefined) {
    if (linkSource) {
      return this.getVariantErrorMessageWithLink(message, linkSource);
    }
    return this.getGenericVariantErrorMessage();
  }

  private getVariantErrorMessageWithLink(message: string | undefined, linkSource: LinkSource) {
    return (
      <div>
        <p>{message}</p>
        <p>
          To add the same variant to the link, go to the link
          <Link to="#" onClick={this.getOnClickVariantErrorLink(linkSource)}>
            &nbsp;source
          </Link>
          .
        </p>
      </div>
    );
  }

  private getGenericVariantErrorMessage() {
    const liStyle: React.CSSProperties = {
      listStyleType: 'disc',
      listStylePosition: 'inside',
      marginLeft: '30px',
      textIndent: '-1.35em'
    };
    return (
      <div>
        This tagging operation would result in an invalid document as it would break an internal link. When tagging content with links
        please check:
        <br />
        <ul style={liStyle}>
          <li style={liStyle}>There are no restrictions when linking from variant tagged content to untagged content.</li>
          <li style={liStyle}>
            When linking to variant tagged content the destination of the link must have the same tags that the source does.
          </li>
          <li style={liStyle}>
            If you would like to add or remove variant tags at both the source and destination of an existing link, change the variant tag
            list of the source first.
          </li>
          <li style={liStyle}>There are no restrictions on link types other than internal.</li>
        </ul>
      </div>
    );
  }

  private getOnClickVariantErrorLink(linkSource: LinkSource) {
    return () =>
      EditorStore.openDocumentWithTargets({
        projectUid: EditorStore.getDocParams().projectUid!,
        indexUid: EditorStore.getDocParams().indexUid!,
        tocableUnitUid: linkSource.tocableUnitUid,
        targetUnitUid: linkSource.unitUid,
        targetElementNid: linkSource.nid
      });
  }

  private _showDeleteConfirmation(title: string, message: string | JSX.Element, unit?: IUnit) {
    this.setState({
      showAlert: {
        props: {
          actions: [
            <FlatButton
              key={1}
              label="Cancel"
              onClick={() => {
                this.setState({ showAlert: null });
              }}
            />,

            <FlatButton
              key={2}
              label="Delete"
              primary={true}
              keyboardFocused={true}
              onClick={() => {
                this.setState({ showAlert: null }, () => {
                  unit ? EditorStore.deleteUnitConfirm(unit) : EditorStore.deleteSelectedUnitsConfirm();
                });
              }}
            />
          ],
          title: title,
          modal: null,
          onRequestClose: () => {
            this.setState({ showAlert: null });
          },
          contentClassName: 'delete-modal-actions'
        },
        message: message
      }
    });
  }

  // if conflict occurs editing a unit (due to another users change), inform user and update unit with latest
  private _handleUnitVersionConflictFailure(r, editorUpdate, updateCompleteCallback) {
    let conflictChoice: null | ((accept: boolean) => void) = null; // lint

    this.setState({
      showAlert: {
        props: {
          title: 'Content has been changed by another user',
          actions: [
            <FlatButton
              key={1}
              label="Overwrite Modifications"
              onClick={() => {
                conflictChoice!(false);
              }}
            />,
            <FlatButton
              key={2}
              label="Accept Modifications"
              onClick={() => {
                conflictChoice!(true);
              }}
            />
          ],
          onRequestClose: () => {}
        },
        message:
          'Your recent content changes have also been modified by another user. Choose to overwrite with your modifications or accept the other users modifications'
      }
    });

    conflictChoice = (acceptChanges) => {
      this.setState({ showAlert: null }, () => {
        // make sure that other users know that the current user stopped editing this unit
        EditorStore.broadcast('unitEditEnd', { unitUid: editorUpdate.uid });
        if (acceptChanges) {
          EditorStore.getLatestDocUnitModel(editorUpdate.uid).then((docUnit) => {
            const unit: IUnit = docUnit.toJSON();

            if (unit.isstructural || unit.istocable) {
              TocStore.refreshToc({ forceDocRefresh: false }); // refresh toc, and *don't* force it to reload this page (as done using _hardInlineRefreshCurrentPage)
            }

            this._hardInlineRefreshCurrentPage(unit.definitionId === 'volume');
          });
        } else {
          // reject changes and overwrite
          EditorStore.updateUnit(editorUpdate, updateCompleteCallback, { forceVersionOverwrite: true });
        }
      });
    };
  }

  /*
   * Listen to EditorMenu component events
   */

  private _handleTopMenuSelect(e: MenuFileOnSelectParams | MenuEditOnSelectParams | MenuInsertOnSelectParams | MenuWorkflowOnSelectParams) {
    if ((e as MenuEditOnSelectParams).action === 'insertLink' || (e as MenuEditOnSelectParams).action === 'duRefLink') {
      EditorStore.triggerOpenLinkModal((e as MenuEditOnSelectParams).action === 'duRefLink');
    } else if ((e as MenuEditOnSelectParams).action === 'insertLinkAnchor') {
      this.setState({ showLinkAnchorModal: true });
    } else if (e.menu === 'file') {
      e = e as MenuFileOnSelectParams;
      if (e.action === 'preview') {
        EditorStore.publishPreview();
        return;
      } else if (e.action === 'diff') {
        EditorStore.toggleMode('DIFF');
        return;
      } else if (e.action === 'layers') {
        EditorStore.toggleMode('LAYERS');
        return;
      }
    } else if (e.menu === 'edit') {
      e = e as MenuEditOnSelectParams;
      if (e.action === 'mergeUnits') {
        EditorStore.mergeSelectedUnits();
      } else if (e.action === 'splitUnits') {
        EditorStore.splitSelectedUnits();
      } else if (e.action === 'spellCheck') {
        EditorStore.toggleMode('SPELLCHECK');
        SpellCheckStore.executeSpellcheck();
        return;
      } else if (e.action === 'convertUnits') {
        EditorStore.convertSelectedUnits((e as MenuEditOnSelectParams).unitType!);
      } else if (e.action === 'convertElements') {
        // need to refactor unitTypes to types
        EditorStore.convertSelectedElement((e as MenuEditOnSelectParams).unitType!);
      } else if (!EditorStore.getEditor().isFocused() && (e.action === 'Redo' || e.action === 'Undo')) {
        if (e.action === 'Redo') {
          EditorStore.redo();
        } else if (e.action === 'Undo') {
          EditorStore.undo();
        }
      } else if (e.action === 'arcPasteElement') {
        let insertPosition: InsertAction | null = null;

        if (EditorStore.getEditor().isFocused()) {
          insertPosition = 'insert_inside';
          if ((e as MenuEditOnSelectParams).metaModifier?.shift) {
            insertPosition = 'insert_before';
          } else if ((e as MenuEditOnSelectParams).metaModifier?.ctrl || (e as MenuEditOnSelectParams).metaModifier?.meta) {
            insertPosition = 'insert_after';
          }
        }

        if (insertPosition) {
          CutCopyPasteUtil.pasteAsElement(insertPosition);
        } else {
          CutCopyPasteUtil.pasteUnits();
        }
      } else if (['arcFontSize', 'arcTextTransform', 'hilitecolor', 'forecolor'].indexOf(e.action) === -1) {
        _.throttle(() => {
          EditorStore.getEditor()
            .getActiveEditorFacade()!
            .execCommand((e as MenuEditOnSelectParams).action as TinyAction, '');
        }, 500)();
      }
    } else if (e.menu === 'insert') {
      e = e as MenuInsertOnSelectParams;
      if (MediaInsertUtils.ELEMENT_TYPES_TO_OPEN_MEDIA_LIB.indexOf(e.unitType as UnitTypes | ElementTypes) > -1) {
        this._openMediaLibModal(
          {
            inline: e.isInsertInline,
            insertPoint: e.insertPoint || null,
            insertPosition: e.insertPosition,
            type: e.unitType
          },
          false,
          e.isShortcut
        );
      } else if (e.unitType === 'powerpaste') {
        this._openFullPageModal({ type: 'powerpaste' });
      } else {
        EditorStore.insertUnit({
          inline: e.isInsertInline,
          unitType: e.unitType as UnitTypes,
          insertPoint: e.insertPoint === null ? undefined : e.insertPoint,
          insertPosition: e.insertPosition,
          parentUnitUid: e.parentUnitUid
        });
      }
    } else if (e.menu === 'workflow') {
      this._openFullPageModal({ type: 'workflowaction', data: (e as MenuWorkflowOnSelectParams).workflowData });
    } else {
      console.log('An event from editor menu, ignored for now, from: ' + e.menu + ', for item: ' + (e as any).action);
    }
  }

  private _handleInlineUnitAction(e: ActionEvent) {
    const selectedUnits = EditorStore.getSelectedUnits();
    const selectedUnitsCount = selectedUnits.length;
    const isSingleUnitOperation = e.src === 'inlineMenu' || selectedUnitsCount === 1;

    if (e.action === 'insert-docunit') {
      EditorStore.selectUnit(
        e.data.unit as IUnit,
        () => {
          const toTest: UnitTypes = 'graphic';

          if (e.data.insertType === toTest) {
            this._openMediaLibModal(null, false, false);
          } else {
            EditorStore.insertUnit({ inline: false, unitType: e.data.insertType as UnitTypes });
          }
        },
        { force: true }
      ); // only force in this exact scenario: as selection is temporary, inserted unit will get new selection immediately (allows insertion after unselctable units)
    } else if (e.action === 'delete-docunit') {
      if (isSingleUnitOperation) {
        EditorStore.deleteUnit(e.data.unit as IUnit);
      } else if (selectedUnitsCount > 1) {
        // keypress or master menu for batch
        EditorStore.deleteSelectedUnits();
      }
    } else if (e.action === 'copy-docunit') {
      CutCopyPasteUtil.cutOrCopy(isSingleUnitOperation, e, false);
    } else if (e.action === 'cut-docunit') {
      CutCopyPasteUtil.cutOrCopy(isSingleUnitOperation, e, true);
    } else if (e.action === 'paste-docunit') {
      CutCopyPasteUtil.pasteUnits();
    }
  }

  private _handleDocumentVisualStateChange(change: VisualStates, active: boolean) {
    if (change === VisualStatesID.PAGE_OUTLINE) {
      this.setState({ editorPageOutline: active }, () => DocumentEmitter.emit('resize'));
      EditorPage._updatePageOutlineWidth(active);
    }
  }

  private static _updatePageOutlineWidth(active: boolean) {
    const editorPageEl = document.querySelector('.editor-body-inner .editing-stage-page .editing-page')!;
    if (active) {
      (editorPageEl as HTMLElement).style.width = ProjectDefinitionStore.getDefaultPageWidth();
    } else {
      (editorPageEl as HTMLElement).style.width = '100%';
    }
  }

  private _collectAffectedUnitUids(options: InlineOptions): Set<IUnit['uid']> {
    const affectedUnitUids = new Set<IUnit['uid']>();
    if (options.affectedUnit) {
      affectedUnitUids.add(options.affectedUnit.uid);
    }

    if (options.affectedUnits) {
      options.affectedUnits.forEach((unitUid) => {
        affectedUnitUids.add(unitUid);
      });
    }
    return affectedUnitUids;
  }

  /*
   * Handle DocUnitsService events - i.e. data change events
   *
   *   A general description of why/what happens in the _handle functions when the editor triggers a document change:
   *   Each unit *won't* be updated in the usual react way due to an efficiency built into each DocUnit (see _hardInlineRefreshCurrentPage and DocuUnit for more)
   *
   *   1. Its structural, therefore we refresh the toc: if hardrefresh: true, then entire pages gets reloaded (its cleared first to show spinner, then reloads entirely fresh), if hardrefresh: false, just the toc loads - if you want the page to change you must do another seperate step and *not* rely on plain react property change to drive the update
   *   2. If the _hardInlineRefreshCurrentPage is called, allowInefficientUpdate is applied to *all* units on the next document load, and we set the state with new units causing a document re-render (with no spiiner etc so page stays in same position with just mods applied reactstyle)
   *   3. If _softInlineRefreshCurrentPage is called, this means we just want to update the specific unit on the screen - by far the most efficient call
   *
   *   We have to do these steps as using react without this efficiency makes the editing process un-usably slow on longer paged docs...
   *   These full reload, hard reload, and soft reload scenarios are all covered by GEB tests
   */

  // in memory re-render for EditorStore units - uber efficient
  private _softInlineRefreshCurrentPage(options: InlineOptions, loadMoreUnits?: boolean): Promise<void> {
    (window as any).testData.editorLastRefreshType = loadMoreUnits ? 'softReloadPageLoadMoreUnits' : 'softReloadPageMaintained';

    return new Promise((resolve, reject) => {
      const docStage = this.refs.documentStage as DocumentStage;

      if (options.affectsAllUnits && docStage) {
        docStage.allowInefficientUpdate();
      }

      const affectedUnitUids = this._collectAffectedUnitUids(options);

      if (affectedUnitUids && docStage) {
        affectedUnitUids.forEach((unitUid) => docStage.allowInefficientUpdate(unitUid));

        if (options.affectedParts) {
          options.affectedParts.forEach((type) => docStage.allowInefficientPartUpdate(type));
        }
      }
      this.setState({ docUnits: EditorStore.getDocUnits() }, () => {
        resolve();
      });
    });
  }

  // forces a new retrieval of a currently viewed page and corresponding render, ensuring react and our DocUnit efficiencies are unused for internal unit html
  // note: the refresh is hidden from the user - i.e. not cleared first with spinner, so can be used during the editing process when not switching page
  // switching page is handled in: _triggerFullPageLoad, and we don't need to "allowInefficientUpdate" as its a new page where all contents will be new anyway
  // why hard refresh during editing? Typically because of ordinal changes in one unit affecting other units, easiest solution is to refresh the page, magically updating units thanks to react
  private _hardInlineRefreshCurrentPage(
    isVolume?: boolean,
    takeUnitHtmlFromServer = false,
    changedTocDisplayName: string | null = null
  ): Promise<void> {
    (window as any).testData.editorLastRefreshType = 'hardReloadPageMaintained';

    return new Promise((resolve, reject) => {
      const params: RetrieveUnitParams = {
        tocableUnitUid: this.state.startUnitUid,
        includeHeader: true,
        unitsRequired: this._defaultUnitsRequired,
        takeUnitHtmlFromServer,
        changedTocDisplayName
      };

      if (isVolume) {
        params.unitsRequired = 1;
      }

      EditorStore.retrieveUnits(params).then(() => {
        (this.refs.documentStage as DocumentStage).allowInefficientUpdate();
        this.setState({ docUnits: EditorStore.getDocUnits() }, () => {
          resolve();
        });
      });
    });
  }

  private _handleDocumentChanged(
    affectedUnit: IUnit,
    affectedUnits: string[],
    affectedParts: string[],
    refreshView: boolean,
    renderBehaviour: Partial<RenderOptions> = {
      isDelete: false,
      isUpdate: false,
      isCreate: false,
      launchEditor: false,
      confirmMessage: null,
      scrollToAffectedUnit: false
    }
  ) {
    const postRenderActions = () => {
      if (renderBehaviour.confirmMessage) {
        const confirmMessage = renderBehaviour.confirmMessage ? ' ' + renderBehaviour.confirmMessage : '';
        this._makeSnackbar({
          message:
            ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(affectedUnit.definitionId)
              ?.displayName + confirmMessage
        });
      }
      if (renderBehaviour.launchEditor && this._isUnitAllowedToLaunchEditor(affectedUnit)) {
        EditorStore.triggerLaunchEditor({ uid: affectedUnit.uid });
      }
      if (renderBehaviour.scrollToAffectedUnit) {
        EditorStore.scrollToUnit(affectedUnit.uid);
      }
      if (renderBehaviour.openEditPaneOnAffectedUnit) {
        EditorStore.triggerLaunchEditor({ uid: affectedUnit.uid });
      }
    };

    const softRefresh = () =>
      this._softInlineRefreshCurrentPage({
        affectedUnit,
        affectedUnits,
        affectedParts
      }).then(() => postRenderActions());
    const hardRefresh = () =>
      this._hardInlineRefreshCurrentPage(false, renderBehaviour.takeUnitHtmlFromServer).then(() => postRenderActions());
    if (refreshView) {
      hardRefresh();
    } else {
      const isPostRenderOnly =
        (renderBehaviour.isDelete || renderBehaviour.isCreate) &&
        affectedUnit.isstructural &&
        affectedUnit.definitionId.indexOf('paragraph-level') < 0 &&
        !affectedUnit.istocable;
      if (isPostRenderOnly) {
        postRenderActions();
      } else if (renderBehaviour.isDelete || renderBehaviour.isCreate || renderBehaviour.isUpdate) {
        softRefresh();
      }
    }
  }

  private _isUnitAllowedToLaunchEditor(unit: IUnit) {
    return this._isUnitNotStructural(unit) || this._isUnitOfTypeSection(unit) || this._isUnitOfSubtypeHeading(unit);
  }

  private _isUnitNotStructural({ isstructural }: IUnit) {
    return !isstructural;
  }

  private _isUnitOfTypeSection({ definitionId }: IUnit) {
    return definitionId === 'section';
  }

  private _isUnitOfSubtypeHeading({ definitionId }: IUnit) {
    return (
      definitionId === 'level1' ||
      definitionId === 'level2' ||
      definitionId === 'level3' ||
      definitionId === 'level4' ||
      definitionId === 'level5' ||
      definitionId === 'level6'
    );
  }

  // unit updated in document from an undo/redo or a move (promo/demo), and others
  // below seems complex, but its job is to find the most optimum way of re-rendering the doc (last resort: full page reloads driven by toc)
  // TODO in time move everything over to _handleDocumentChanged (algo of which is based on affectedUnit info coming from the backend after a unit update)
  private _handleDocumentChangedComplex(seniorMostUnit: IUnit, renderBehaviour: RenderOptions) {
    const showConfirmation = () => {
      this._makeSnackbar({
        message:
          ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(seniorMostUnit.definitionId)
            ?.displayName + (renderBehaviour.confirmMessage ? ' ' + renderBehaviour.confirmMessage : '')
      });
    };

    // refresh toc, which will force reload of this doc at the appropriate place (which may be a new page) - assume create so it gets selected
    if (seniorMostUnit.isstructural) {
      this.handleStructuralUnitChange(seniorMostUnit, renderBehaviour, showConfirmation);
      return;
    }

    // seniorMostUnit can have an ordinal value internally, so if its not structural (which is dealt with above), do a hard reload so we get any updates on the page
    if (seniorMostUnit.hasOrdinal || seniorMostUnit.isordinable || seniorMostUnit.canHaveOrdinal) {
      this.handleOrdinalUnitChange(seniorMostUnit, renderBehaviour, showConfirmation);
      return;
    }

    // its an ordinary unit, so do a simple in memory update which causes re-render (with exception of sharedUnits)
    const isHardRefresh = seniorMostUnit.shareDetails || (renderBehaviour.multipleChanged && renderBehaviour.isDelete);
    const refreshMethod = isHardRefresh
      ? this._hardInlineRefreshCurrentPage()
      : this._softInlineRefreshCurrentPage({
          affectedUnit: seniorMostUnit,
          affectsAllUnits: renderBehaviour.multipleChanged
        });

    refreshMethod.then(() => {
      if (renderBehaviour.confirmMessage) {
        showConfirmation();
      } else {
        EditorStore.scrollToUnit(seniorMostUnit.uid);
      }
    });
  }

  private handleStructuralUnitChange(seniorMostUnit: IUnit, renderBehaviour: RenderOptions, showConfirmation: () => void) {
    if (seniorMostUnit.type === 'paragraph') {
      this._hardInlineRefreshCurrentPage().then(() => {
        EditorStore.scrollToUnit(seniorMostUnit.uid);
      });
    } else if (
      // subtle improvement: if changed section is on page no need for full toc driven re-render
      !renderBehaviour.isDelete &&
      (seniorMostUnit.definitionId === 'section' || seniorMostUnit.previousType === 'section') &&
      seniorMostUnit.chapterUid === TocStore.getSelectedItem()!.uid
    ) {
      TocStore.refreshToc({ forceDocRefresh: false, selectUnit: { uid: seniorMostUnit.chapterUid } });
      this._hardInlineRefreshCurrentPage().then(() => {
        EditorStore.scrollToUnit(seniorMostUnit.uid);
      });
    } else {
      const refreshOptions = { forceDocRefresh: true };
      refreshOptions[renderBehaviour.isDelete ? 'removedUnit' : 'selectUnit'] = seniorMostUnit;
      TocStore.refreshToc(refreshOptions);
    }

    if (renderBehaviour.confirmMessage) {
      showConfirmation();
    }
  }

  private handleOrdinalUnitChange(seniorMostUnit: IUnit, renderBehaviour: RenderOptions, showConfirmation: () => void) {
    this._hardInlineRefreshCurrentPage().then(() => {
      const latestUnitOnPage = EditorStore.getDocUnitModel(seniorMostUnit.uid);

      // seniorMostUnit no longer on page: was probably a move request, so make toc drive its render
      if (!renderBehaviour.isDelete && !latestUnitOnPage) {
        TocStore.refreshToc({ forceDocRefresh: true, selectUnit: seniorMostUnit });
      }
      // seniorMostUnit deleted
      else if (renderBehaviour.isDelete) {
        if (seniorMostUnit.istocable) {
          // l1-6
          TocStore.refreshToc({ forceDocRefresh: false, removedUnit: seniorMostUnit });
        }
      }
      // in page update
      else if (!renderBehaviour.isDelete) {
        if (seniorMostUnit.istocable) {
          // l1-6
          // we want to allow batch undo/redo stay on same page as best we can, but if !isInitialUnitStructural, force toc to reload page again
          const initialUnit = EditorStore.getDocUnitModelByIndex(1);
          const tocDrivenRefresh = !(initialUnit && initialUnit.unit.isstructural);
          TocStore.refreshToc({ forceDocRefresh: tocDrivenRefresh, selectUnit: seniorMostUnit });

          if (!tocDrivenRefresh) {
            EditorStore.scrollToUnit(seniorMostUnit.uid);
          }
        } else {
          EditorStore.scrollToUnit(seniorMostUnit.uid);
        }
      }

      if (renderBehaviour.confirmMessage) {
        showConfirmation();
      }
    });
  }

  private _isDualDisplay() {
    return EditorStore.doesModeAllow(EditorModes.attributes.dualDocsDisplay);
  }

  private _exitEditor() {
    location.href = '/';
  }

  private _blurEditor(e) {
    if (!this.state.showHotspotsModal) {
      const $tgt = $(e.target);
      if (!$tgt.data('preventEditingExit') && !$tgt.is('input[type=file]')) {
        EditorStore.getEditor().blur();
      }
    }
  }
  private onStyleLoaded() {
    this.setState({
      styleLoaded: true
    });
  }

  render() {
    const hasExternalCss = !!this.state.project?.uid && !!ProjectStore.getIndex()?.definition;
    const styleLink = hasExternalCss ? (
      <link
        rel="stylesheet"
        onLoad={() => this.onStyleLoaded()}
        href={'/api/styles/definitions/' + ProjectStore.getIndex()!.definition.uid + '/style.css'}
      />
    ) : null;
    const isStyleLoaded = (hasExternalCss && this.state.styleLoaded) || !hasExternalCss;
    return (
      <div
        id="editor-page-content"
        className={`page-container-editor page ${'editor-mode-' + this.state.editingMode} ${
          this._isDualDisplay() ? 'editor-dual-display' : ''
        } ${this.state.showFullpageModal ? 'dom-overlaid' : ''}`}
        onClick={(e) => this._blurEditor(e)}
      >
        {styleLink}
        <EditorNav
          onActionSelected={(e) => this._handleTopMenuSelect(e)}
          onTabSelected={() => this._handleTopMenuTabSelect()}
          revisions={this.state.revisions}
          showSubMenu={!this.state.showFullpageModal}
          showActiveEditors={this._mountEditor()}
        />

        {this._mountEditor() && (
          <div className="page-body editor-body row">
            <div className={'editor-body-inner' + (this.state.editorPageOutline ? ' visual-override-pageoutline' : '')}>
              <div className="row page-inner">
                <DefaultErrorBoundary>
                  <MainActionsContainer project={this.state.project ? this.state.project : null} />
                </DefaultErrorBoundary>
                <DefaultErrorBoundary>
                  <DocumentStage
                    docUnits={this.state.docUnits}
                    startUnitUid={this.state.startUnitUid!}
                    isStyleLoaded={isStyleLoaded}
                    preventKeyDetection={!!(this.state.showAlert || this.state.showFullpageModal)}
                    onVisualStateChange={(change, active) => this._handleDocumentVisualStateChange(change, active)}
                    ref="documentStage"
                    openLinkModal={(isDuRef: boolean) =>
                      this.setState({ linkModal: { open: true, isDuRefLink: isDuRef, templateHtml: undefined } })
                    }
                    openLinkAnchorModal={() => this.setState({ showLinkAnchorModal: true })}
                  />
                </DefaultErrorBoundary>
                <DefaultErrorBoundary>
                  <SubActionsContainer project={this.state.project} index={this.state.index} />
                </DefaultErrorBoundary>
              </div>
            </div>
          </div>
        )}
        <EditorModals
          state={this.state}
          closeLinkModal={() => this._closeLinkModal()}
          closeLinkAnchorModal={() => this._closeLinkAnchorModal()}
          closeHotspotModal={() => this._closeHotspotModal()}
          saveHotspotLinks={(hotspotLinks) => this._saveHotspotLinks(hotspotLinks)}
          closeDatePicker={() => this._closeDatePicker()}
          closeMergeRevisionModals={() => this.closeMergeRevisionModal()}
          closeFullPageModal={() => this._closeFullPageModal()}
          hideDiffOverlay={() => this._hideDiffOverlay()}
        />
      </div>
    );
  }
}
