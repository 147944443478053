import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'MeasureUnit',
  getLabel: () => 'MeasureUnit',
  identifyingClasses: ['arc-inline-enum'],
  identifyingElements: ['span'],
  template: '',
  splittable: false
};
