import * as _ from 'lodash';
import { UnitTypes } from '../units/UnitTypes';

const _structuralUnits: UnitTypes[] = ['volume', 'chapter', 'appendix-chapter', 'section'];
const _tocableUnits: UnitTypes[] = [
  'volume',
  'chapter',
  'appendix-chapter',
  'section',
  'level1',
  'level2',
  'level3',
  'level4',
  'level5',
  'level6'
];

// units that may have ordinals: used by canHaveOrdinal, but really needs replacement in future: See DocumentUnit comment
const _ordinalUnits: UnitTypes[] = [
  'volume',
  'chapter',
  'appendix-chapter',
  'section',
  'level1',
  'level2',
  'level3',
  'level4',
  'level5',
  'level6',
  'table',
  'graphic',
  'video',
  'paragraph',
  'note'
];

// units that can have ordinal applied and/or overridden: append-chapter intentionally not in here
const _ordinableUnits: UnitTypes[] = [
  'volume',
  'chapter',
  'section',
  'level1',
  'level2',
  'level3',
  'level4',
  'level5',
  'level6',
  'paragraph',
  'note'
];

const _repeaterUnits: UnitTypes[] = [
  'header-repeater',
  'header-repeater-section',
  'header-repeater-level1',
  'header-repeater-level2',
  'header-repeater-level3',
  'header-repeater-level4',
  'header-repeater-level5',
  'header-repeater-level6'
];

export default {
  getTocableUnits() {
    return _tocableUnits;
  },

  getTocableLevel(type) {
    return _.indexOf(_tocableUnits, type);
  },

  isTocableType(type) {
    return _.indexOf(_tocableUnits, type) !== -1;
  },

  isStructuralType(type) {
    return _.indexOf(_structuralUnits, type) !== -1;
  },

  isOrdinable(type) {
    return _.indexOf(_ordinableUnits, type) !== -1;
  },

  isRepeaterHeadingType(type: UnitTypes) {
    return _.indexOf(_repeaterUnits, type) !== -1;
  },

  canHavePrintOutput(type: UnitTypes) {
    return type !== 'volume';
  },

  canHaveVariantOutput(type) {
    // By default the variants are on for every unit - but this might change
    return true;
  },

  canShowPdfSetting(type: UnitTypes) {
    const toTest: UnitTypes[] = ['chapter', 'section', 'page-break'];
    return _.indexOf(toTest, type) !== -1;
  },

  canHaveOrdinal(type: UnitTypes) {
    return _.indexOf(_ordinalUnits, type) !== -1;
  },

  getTocableParentType(type: UnitTypes) {
    return _tocableUnits[_.indexOf(_tocableUnits, type) - 1];
  }
};
