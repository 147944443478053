import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import TextField from 'material-ui/TextField';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';

export type Props = {
  nullable?: boolean;
  disabled?: boolean;
  regex?: RegExp;
  maxChars?: number;
  min?: number;
  max?: number;
  type?: string;
  step?: number;
  customError?: string | null;
  errorMessage?: string | null;
  inputId?: string | null;
  floatingLabelFixed?: boolean;
  multiLine?: boolean;
  value?: string;
  hintText?: string;
  onChange?: (e: React.FormEvent<{}>, text: string, isValid?: boolean) => void;
  onValidation?: (hasError: boolean) => void;
  onFocus?: React.FocusEventHandler<{}>;
  floatingLabelText?: string;
  style?: React.CSSProperties;
  className?: string;
  errorStyle?: React.CSSProperties;
  fullWidth?: boolean;
  defaultValue?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
};

export type State = {
  valid: boolean;
  value: string;
  errorMessage: string | null;
};

export type ValidatableTextInputType = {
  isValid: () => boolean;
  focus: () => void;
  value: string;
};

export default React.forwardRef(function ValidatableTextInput(
  {
    nullable = true,
    disabled = false,
    regex = /.*/,
    maxChars = 999,
    customError = null,
    floatingLabelFixed = false,
    errorMessage = null,
    inputId = null,
    value = '',
    ...props
  }: Props,
  ref: React.Ref<any>
) {
  const [stateValue, setStateValue] = useState<string>(value || '');
  const [stateErrorMessage, setStateErrorMessage] = useState<string | null | undefined>(null);
  const [valid, setValid] = useState(true);

  const [callbackState, setCallbackState] = useState<{
    formEvent?: React.FormEvent<{}>;
    text?: string;
    valid?: boolean;
    execute?: boolean;
  }>({});

  const textInputField = useRef<TextField>(null);

  useEffect(() => {
    if (customError) {
      setValid(false);
      setStateErrorMessage(customError);
    } else {
      validateNewValue(value!, disabled!, nullable!);
      if (props.autoFocus) {
        focusInput();
      }
    }
  }, []);

  useEffect(() => {
    if (callbackState.execute) {
      if (props.onChange) {
        props.onChange(callbackState.formEvent!, callbackState.text!, callbackState.valid!);
      }
      if (props.onValidation) {
        props.onValidation(callbackState.valid!);
      }
      setCallbackState({ execute: false });
    }
  }, [callbackState.execute]);

  useEffect(() => {
    if (customError) {
      setValid(false);
      setStateErrorMessage(customError);
    } else {
      if (customError !== stateErrorMessage || value !== stateValue) {
        validateNewValue(value!, disabled!, nullable!);
        if (props.autoFocus) {
          focusInput();
        }
      }
    }
  }, [customError, value, disabled, nullable]);

  useImperativeHandle(ref, () => ({
    isValid() {
      return valid;
    },
    focus() {
      focusInput();
    },
    value: stateValue
  }));

  const validateNewValue = (text: string, disabled: boolean, nullable: boolean) => {
    let errMessage: string | null = null;
    let error = false;

    if (text === undefined) {
      text = '';
    }

    const isMinAvailable = props.min === 0 || !!props?.min,
      isMaxAvailable = props.max === 0 || !!props?.max;

    if (disabled) {
      errMessage = '';
      error = false;
    } else if (!nullable && !text) {
      errMessage = 'Required field';
      error = true;
    } else if (nullable && !text) {
      // allow for no text if nullable (not required), explicit but clearer to read.
      errMessage = '';
      error = false;
    } else if (props.type == 'datetime-local') {
      if (!text || text.length <= 0 || isNaN(Date.parse(text))) {
        errMessage = 'Invalid date';
        error = true;
      }
    } else if (isMinAvailable || isMaxAvailable) {
      if (isNaN(Number(text))) {
        errMessage = 'Must be a number';
        error = true;
      } else {
        const textNumber = Number(text);
        if (isMinAvailable && isMaxAvailable) {
          if ((props?.min || props?.min === 0) && props.max && (textNumber < props?.min || textNumber > props?.max)) {
            errMessage = `Needs to be between ${props.min} and ${props.max}`;
            error = true;
          }
        } else if (isMinAvailable) {
          if ((props?.min || props?.min === 0) && textNumber < props?.min) {
            errMessage = `Value cannot be smaller than ${props.min}`;
            error = true;
          }
        } else if (isMaxAvailable && typeof props?.max == 'number') {
          if (props?.max && textNumber > props?.max) {
            errMessage = `Value cannot be bigger than ${props.max}`;
            error = true;
          }
        }
      }
    } else if (text.length === maxChars) {
      errMessage = 'Maximum characters reached';
      text = text.substring(0, maxChars);
      error = false;
    } else if (!text.match(regex!)) {
      errMessage = errorMessage ?? "Can't contain special characters";
      error = true;
    }

    setStateErrorMessage(errMessage);
    setStateValue(text);
    setValid(!error);
  };

  const update = (e: React.FormEvent<{}>, text: string) => {
    if (props.readOnly) {
      e.preventDefault();
    } else {
      e.persist();
      validateNewValue((e.target as HTMLInputElement).value, disabled!, nullable!);
      setCallbackState({ formEvent: e, text: text, execute: true });
    }
  };

  const focusInput = () => {
    if (textInputField.current) {
      (ReactDOM.findDOMNode((textInputField.current as any).input) as HTMLInputElement).focus();
    }
  };

  const passthroughProps = _.omit(props, [
    'maxLength',
    'nullable',
    'maxChars',
    'customError',
    'regex',
    'errorMessage',
    'inputId',
    'onValidation',
    'readOnly'
  ]);
  return (
    <TextField
      {...passthroughProps}
      ref={textInputField}
      id={inputId!}
      className={props.className}
      floatingLabelFixed={floatingLabelFixed}
      floatingLabelText={<div>{props.floatingLabelText}</div>}
      style={props.style}
      maxLength={maxChars}
      errorText={stateErrorMessage}
      value={stateValue}
      onChange={(e, text) => update(e, text)}
      hintText={props.hintText}
      disabled={disabled}
    />
  );
});
