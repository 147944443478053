import React, { useEffect, useState } from 'react';

import WorkflowTasksTile from './WorkflowTasksTile';
import { FlatButton } from 'material-ui';
import { transitionTo } from '../../../utils/Navigation';

import * as kpi from '../../documents/kpi/kpiClient';
import { Cancelled } from '../../../clients/base-clients';
import { IKPIWorkflowTask } from 'mm-types';
import NoDocumentsToView from './NoDocumentsToView';
import RequestError from '../../documents/kpi/RequestError';

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  container: { display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100%', position: 'relative' },
  tile: { flex: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' },
  buttonContainer: { textAlign: 'center', paddingTop: '15px' }
};

const onDocumentSelect = (projectUid: string, indexUid: string, workspaceUid: string, external: boolean) => {
  if (document) {
    if (external) {
      transitionTo(`teamspaces/${workspaceUid}/${projectUid}/workflow`);
    } else {
      transitionTo('editor-edit', { projectUid, indexUid });
    }
  }
};

const WorkflowTasksTileContainer = () => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workflowTasks, setWorkflowTasks] = useState<IKPIWorkflowTask[] | null>(null);

  useEffect(() => {
    let isNotCancelled = true;
    setLoading(true);
    (async function () {
      try {
        const workflowTasksResponse = await kpi.getMyWorkflows({ orderBy: 'DUE_DATE', pageSize: 10, direction: 'ASC' });
        if (!(workflowTasksResponse instanceof Cancelled) && isNotCancelled) {
          setWorkflowTasks(workflowTasksResponse.workflowTasks);
          setError(undefined);
        }
      } catch (error) {
        console.log(error);
        if (isNotCancelled) {
          setWorkflowTasks([]);
          setError(error);
        }
      }
      if (isNotCancelled) {
        setLoading(false);
      }
    })();
    return () => {
      isNotCancelled = false;
    };
  }, []);

  return (
    <div style={defaultStyles.container}>
      {workflowTasks?.length ? (
        <>
          <WorkflowTasksTile
            style={defaultStyles.tile}
            onClickCallback={(projectUid: string, indexUid: string, workspaceUid: string, external: boolean) => {
              onDocumentSelect(projectUid, indexUid, workspaceUid, external);
            }}
            workflowTasks={workflowTasks}
            loading={loading}
          />
          <div style={defaultStyles.buttonContainer}>
            <FlatButton
              id="view-my-workflow-tasks"
              label="VIEW ALL WORKFLOW TASKS"
              secondary={true}
              onClick={() => {
                transitionTo('/workflow-tasks');
              }}
            />
          </div>
        </>
      ) : error ? (
        <RequestError />
      ) : (
        <NoDocumentsToView />
      )}
    </div>
  );
};

export default WorkflowTasksTileContainer;
