import * as React from 'react';
import { Divider } from 'material-ui';
import { AeroIconButtonDelete } from '../../../../../../general/AeroIconButton';
import { ForeignEntryValueWithName } from './ForeignEntryMetadata';

const DeleteButton = React.memo(AeroIconButtonDelete);

interface Props {
  entry: ForeignEntryValueWithName;
  onRemove();
}
export function ForeignEntryItem({ entry, onRemove }: Props) {
  return (
    <li className="margin-bottom-l" data-qa="section-index">
      {entry.name ? (
        <div className={`padding-bottom-s font-size-16`} data-qa="foreign-entry-name">
          {entry.name}
        </div>
      ) : (
        <div className={`padding-bottom-s font-size-16 font-red`} data-qa="foreign-entry-deleted">
          Section has been deleted.
          <DeleteButton
            style={{
              padding: '0 !important',
              height: 24,
              width: 24,
              transform: 'scale(.8)',
              verticalAlign: 'text-bottom',
              float: 'right'
            }}
            tooltipPosition="top-left"
            tooltip="Remove Entry"
            onClick={onRemove}
          />
        </div>
      )}
      <Divider />
    </li>
  );
}
