import * as React from 'react';
import { ITocNode, IUnit } from 'mm-types';
import TocStore from '../../../../../flux/editor/TocStore';

function filterByNNCChapter(tocs: ITocNode[]): ITocNode[] {
  return filterBy(tocs, 'non-normal-checklists-chapter');
}

function filterByNNCSection(tocs: ITocNode[]): ITocNode[] {
  return filterBy(tocs, 'non-normal-checklists-section');
}

function filterBy(tocs: ITocNode[], definitionId: string): ITocNode[] {
  const result: ITocNode[] = [];
  for (let i = 0, max = tocs.length; i < max; i++) {
    // faster then [].filter
    if (tocs[i].definitionId === definitionId) {
      result.push(tocs[i]);
    }
  }
  return result;
}

function flattenTocs(tocs: ITocNode[], deep = 2, result?: ITocNode[]): ITocNode[] {
  result = result || [];
  const childDeep = deep - 1;
  for (let i = 0, max = tocs.length; i < max; i++) {
    result.push(tocs[i]);
    if (deep > 0 && tocs[i].children && tocs[i].children.length) {
      flattenTocs(tocs[i].children, childDeep, result);
    }
  }
  return result;
}

function excludeToc(tocs: ITocNode[], tocToExclude: ITocNode | null): ITocNode[] {
  if (!tocToExclude) {
    return tocs;
  }
  return tocs.filter((toc) => toc.uid !== tocToExclude.uid);
}

function getParentSection(unit: IUnit): ITocNode | null {
  const nncLevel1 = TocStore.getTocItem(unit.uid);
  return nncLevel1 && nncLevel1.parent && nncLevel1.parent.definitionId === 'non-normal-checklists-section' ? nncLevel1.parent : null;
}

export function useNNCTocEntries(unit: IUnit): ITocNode[] {
  const [nncTocEntries, setNNCTocEntries] = React.useState<ITocNode[]>([]);

  React.useEffect(() => {
    setNNCTocEntries(excludeToc(filterByNNCSection(flattenTocs(filterByNNCChapter(TocStore.getChildren()))), getParentSection(unit)));
  }, []);

  return nncTocEntries;
}
