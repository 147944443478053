import * as React from 'react';
import SystemStore, { SystemStoreEvent } from '../../flux/common/SystemStore';
import FlatButton from 'material-ui/FlatButton';
import MainMenu from '../user/MainMenu';
import UserMenu from '../user/UserMenu';
import SystemInfo from './SystemInfo';
import backendResponseUtil from '../../utils/backendResponseUtil';
import { match, withRouter } from 'react-router';
import { IRouteParams } from 'mm-types';
import * as H from 'history';
import rootErrorHandler from '../../utils/RootErrorHandler';

import { SystemSnackbar } from './SystemSnackbar/SystemSnackbar';
import { SystemAlert } from './SystemAlert/SystemAlert';
import { AeroDevTools } from '../general/AeroDevTools';
import { useStorage } from '../general/useStorage';
import { SESSION_STORAGE_KEYS } from '../../utils';
import { isInternalEnv } from '../../utils/Navigation';

import { useDispatch, useSelector } from 'react-redux';
import { showSystemAlert } from './SystemAlert/thunks';
import { AlertState } from './SystemAlert/reducers';
import { AppState } from '../../appStore';
import { DefaultErrorBoundary } from '../general/DefaultErrorBoundary';
import useListenToStore from '../hooks/useListenToStore';
import { AxiosError } from 'axios';
import { dispatchDefaultErrorSystemAlert } from './SystemAlert/util';

export type Props = {
  match: match<IRouteParams>;
  history: H.History;
  location: H.Location<string>;
  children?: any;
};

const Root = (props: Props) => {
  const systemAlert: AlertState = useSelector((state: AppState) => state.systemAlert);
  const [showSessionDevTools] = useStorage(SESSION_STORAGE_KEYS.SHOW_DEV_TOOLS);
  const [showLocalDevTools] = useStorage(SESSION_STORAGE_KEYS.SHOW_DEV_TOOLS, 'local');
  const showDevTools = isInternalEnv() && (!!showSessionDevTools || !!showLocalDevTools);

  const dispatch = useDispatch();

  useListenToStore({ store: SystemStore, eventListener: onSystemStoreEvent });

  function onSystemStoreEvent(e: SystemStoreEvent) {
    if (e.systemError) {
      if ((e.errorResponse as AxiosError).response) {
        const errorResponse = e.errorResponse as AxiosError;
        const err = backendResponseUtil.getErrorDetails(errorResponse.response);

        rootErrorHandler.handle(err);
        dispatchDefaultErrorSystemAlert(true, err.message);
      } else {
        dispatchDefaultErrorSystemAlert(true, e.errorResponse?.name, e.errorResponse?.message);
      }
    } else if (e.scriptError) {
      dispatchDefaultErrorSystemAlert(false, '', e.scriptErrorMsg);
    } else if (e.connectivityError) {
      let details = 'We can no longer communicate with the server.';

      if (e.connectivityDetails) {
        if (e.connectivityDetails.reconnectionSuccess !== null) {
          details += e.connectivityDetails.reconnectionSuccess ? ' Connected successfully - reloading' : ' Failed to connect';
        } else if (e.connectivityDetails.secondsUntilRetry) {
          e.connectivityDetails.secondsUntilRetry =
            e.connectivityDetails.secondsUntilRetry <= 0 ? 1 : e.connectivityDetails.secondsUntilRetry;
          details += ' Reconnecting in ' + e.connectivityDetails.secondsUntilRetry + ' seconds...';
        }
      }
      dispatch(
        showSystemAlert({
          errorTitle: 'Server Connection Lost',
          errorMessage: details,
          errorActions: [
            <FlatButton
              key={1}
              label="Retry Now?"
              className="error-ok"
              onClick={() => {
                if (e.connectivityDetails && e.connectivityDetails.retryRef) {
                  e.connectivityDetails.retryRef();
                }
              }}
            />
          ]
        })
      );
    } else if (e.permissionChangeError) {
      const details = 'Your permissions have changed, the application will refresh';
      setTimeout(() => window.location.reload(), 5 * 1000);
      dispatch(showSystemAlert({ errorTitle: 'Permissions Change', errorMessage: details, errorActions: [] }));
    }
  }

  const isEditorRoute = () => {
    if (props.location && props.location.pathname) {
      return props.location.pathname.startsWith('/editor');
    }
  };

  return (
    <div className={'root-container ' + (isEditorRoute() ? ' route-editor ' : '') + (systemAlert.open ? ' app-inerror' : '')}>
      <MainMenu />
      <UserMenu />
      <SystemInfo />
      {showDevTools && <AeroDevTools />}
      <DefaultErrorBoundary>{props.children}</DefaultErrorBoundary>
      <SystemSnackbar />
      <SystemAlert />
    </div>
  );
};

export default withRouter(Root);
