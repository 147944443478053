import * as React from 'react';
import Toggle from 'material-ui/Toggle';
import { IDefinition, IDocUnitProfile, IUnit } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import TextInput from '../../selectedComponent/components/TextInput';
import { DocUnitWrapper } from '../../../../utils/tinyFacade/DocUnitWrapper';
import isEqual from 'react-fast-compare';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';
import { IGenericEditProps } from './GenericEditProps';

export type State = {
  docUnitWrapper: DocUnitWrapper;
};

export type Props = {
  unit: IUnit;
  selectedUnit: IUnit;
  unitProfile: IDocUnitProfile;
  definition: IDefinition;
  targetElement: HTMLElement;
} & IGenericEditProps;

type Fields = {
  displayOrdinal: boolean;
  suppressInAdx: boolean;
  label: string;
};

const initialFields: Fields = {
  displayOrdinal: false,
  suppressInAdx: true,
  label: ''
};

const RepeaterHeadingEditProps = (props: Props) => {
  const { unit } = props;
  const getDocUnitWrapper = () => EditorStore.getDocUnitModel(unit.uid);
  const [ordinalDisabled, setOrdinalDisabled] = React.useState<boolean>(false);
  const [adxDisabled, setAdxDisabled] = React.useState<boolean>(false);
  const [fields, setFields] = React.useState<Fields>(initialFields);
  const [docUnitWrapper, setDocUnitWrapper] = React.useState<DocUnitWrapper | undefined>(getDocUnitWrapper());

  const getDomFieldAttrs = () => unit && (docUnitWrapper as DocUnitWrapper).getRepeaterFields();
  const setDomFieldAttrs = (fields: Fields) => unit && (docUnitWrapper as DocUnitWrapper).setRepeaterFields(fields);

  React.useEffect(() => {
    if (unit) {
      setDocUnitWrapper(getDocUnitWrapper());
    }
  }, [unit]);

  React.useEffect(() => {
    if (docUnitWrapper) {
      const domFields: Fields = getDomFieldAttrs();
      setFields(domFields);
      setAdxDisabled(false);
      setOrdinalDisabled(false);
    }
  }, [docUnitWrapper]);

  const updateDisplayOrdinal = (value: boolean) => updateFields({ ...fields, displayOrdinal: value }, () => setOrdinalDisabled(true));
  const updateLabel = (value: string) => updateFields({ ...fields, label: value });
  const updateSuppressInAdx = (value: boolean) => updateFields({ ...fields, suppressInAdx: value }, () => setAdxDisabled(true));

  const updateFields = (newFields: Fields, disableUI?: () => void) => {
    if (!isEqual(fields, newFields)) {
      if (disableUI) {
        disableUI();
      }
      setDomFieldAttrs(newFields);
      EditorStore.updateRepeaterHeader();
      return true;
    }
    return false;
  };

  return (
    <div className="selected-unit-props-section">
      <div className="selected-unit-props-subsection">
        <div className="dimension column">
          <Toggle
            id="repeater-display-ordinal"
            name="repeaterordinal"
            data-toggle-enabled={fields.displayOrdinal}
            label="Display heading number"
            onToggle={(e, value) => updateDisplayOrdinal(value)}
            toggled={fields.displayOrdinal}
            disabled={ordinalDisabled}
          />
        </div>
        <div className="editor-side-panel-text">
          <TextInput id="repeaterlabel" value={fields.label} floatingLabelText="Label title" onEnter={(value) => updateLabel(value)} />
        </div>
        <div className="dimension column">
          <Toggle
            id="repeater-suppress-adx"
            name="repeatersuppress"
            data-toggle-enabled={fields.suppressInAdx}
            label="Suppress heading in ADX"
            onToggle={(e, value) => updateSuppressInAdx(value)}
            toggled={fields.suppressInAdx}
            disabled={adxDisabled}
          />
        </div>
      </div>
      <DataAttributesPropsWrapper {...props} />
    </div>
  );
};

export default RepeaterHeadingEditProps;
