import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import TextField from 'material-ui/TextField';
import MultiFormatting from '../../selectedComponent/components/MultiFormatting';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import SystemStore from '../../../../../../flux/common/SystemStore';
import { CustomEditor } from '../../../../utils/tinyFacade/EditorInstanceManager';
import { UnitUtils } from '../../../../utils/units/UnitUtils';
import { IGenericEditProps } from './GenericEditProps';
import { DataAttributesPropsWrapper } from './DataAttributesPropsWrapper';

export type State = {
  type: string;
  subtype: string;
  reference: string;
  subreference: string;
};

const ComplianceEditProps = (props: IGenericEditProps) => {
  let timedSetComplianceFields: number | null;

  const [state, setState] = useState<State>({
    type: '',
    subtype: '',
    reference: '',
    subreference: ''
  });

  useEffect(() => {
    SystemStore.disableScriptExceptionHandling(true);
    getComplianceFields();
    return () => {
      SystemStore.disableScriptExceptionHandling(false);
      cancelTimedSetComplianceFields();
    };
  }, []);

  useEffect(() => {
    if (timedSetComplianceFields !== null) {
      cancelTimedSetComplianceFields();
      timedSetComplianceFields = window.setTimeout(() => setComplianceFields(), 150);
    }
  }, [state.type, state.subtype, state.reference, state.subreference]);

  const cancelTimedSetComplianceFields = () => {
    if (timedSetComplianceFields) {
      clearTimeout(timedSetComplianceFields);
      timedSetComplianceFields = null;
    }
  };

  const setComplianceFields = () => {
    const $selectedNode = getSelectedComplianceNode();
    if ($selectedNode) {
      const fields = Object.keys(state);
      fields.forEach((field) => {
        setFieldValue($selectedNode, field, state[field]);
      });
    }
  };

  const getSelectedComplianceNode = () => {
    const tinyInstance: CustomEditor | null = EditorStore.getEditor().getActiveEditorInstance();
    if (tinyInstance) {
      let $selectedNode = $(tinyInstance.selection.getNode() as HTMLElement);
      if ($selectedNode.hasClass('arc-unit')) {
        $selectedNode = $selectedNode.children();
      } else if (
        $selectedNode.hasClass('arc-compliance-tag-type') ||
        $selectedNode.hasClass('arc-compliance-tag-subtype') ||
        $selectedNode.hasClass('arc-compliance-tag-reference') ||
        $selectedNode.hasClass('arc-compliance-tag-subreference')
      ) {
        $selectedNode = $selectedNode.parent('.arc-compliance-tag');
      }
      if ($selectedNode.hasClass('arc-compliance-tag')) {
        return $selectedNode;
      }
    }
    return null;
  };

  const getComplianceFields = () => {
    const $selectedNode = getSelectedComplianceNode();
    if ($selectedNode) {
      const newState = _.cloneDeep(state);
      const fields = Object.keys(newState);
      fields.forEach((field) => {
        newState[field] = getFieldValue($selectedNode, field);
      });
      setState((prevState) => ({ ...prevState, ...newState }));
    }
  };

  const getFieldValue = ($selectedNode, field) => {
    let value = $selectedNode.children(`.arc-compliance-tag-${field}`).text();
    if (isTypeHasHyphen(field, value)) {
      value = removeHyphen(value);
    }
    return value;
  };

  const setFieldValue = ($selectedNode: JQuery<HTMLElement>, field, value) => {
    let text = value;
    if (isTypeHasNoHyphen(field, value) && complianceHasSubtype()) {
      text = `${value}-`;
    }
    $selectedNode.children(`.arc-compliance-tag-${field}`).text(text);
  };

  const removeHyphen = (value) => {
    return value.substring(0, value.length - 1);
  };

  const isTypeHasHyphen = (field, value) => {
    return field === 'type' && value && value[value.length - 1] === '-';
  };

  const isTypeHasNoHyphen = (field, value) => {
    return field === 'type' && value && value[value.length - 1] !== '-';
  };

  const complianceHasSubtype = () => {
    return state.subtype.trim().length > 0;
  };

  const renderField = (field) => {
    return (
      <div key={field}>
        <div className="panel-title">{field}</div>
        <TextField
          id={`compliance-text-${field}`}
          value={state[field]}
          onKeyDown={(e) => handleKeyDown(e)}
          fullWidth={true}
          onChange={(e, newValue) => handleChange(field, newValue)}
          data-qa={`compliance-text-${field}`}
        />
      </div>
    );
  };

  const renderFields = () => {
    return Object.keys(state).map((field) => renderField(field));
  };

  const handleChange = (field, newValue) => {
    const newState = _.cloneDeep(state);
    newState[field] = newValue;
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const handleKeyDown = (e) => {
    const returnKey = e.keyCode === 13;
    const escKey = e.keyCode === 27;
    const saveActionKeys = e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey);

    if (returnKey || escKey || saveActionKeys) {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }
    if (escKey) {
      EditorStore.getEditor().blur({ ignoreChanges: true, ignoreChangesAndRevert: true });
    } else if (returnKey) {
      e.target.blur();
      EditorStore.getEditor().silentReFocus();
    } else if (saveActionKeys) {
      EditorStore.getEditor().blur();
    }
  };

  return (
    <div className="subaction-edit compliance-edit" data-qa="compliance-ref-edit-rhs-panel">
      <div className="panel-heading">Compliance Properties</div>

      {renderFields()}

      {UnitUtils.isFormatingAvailable(props.unitElement) && (
        <MultiFormatting formatNode={props.index === 0 ? null : props.unitElement} unitProfile={props.unitProfile} />
      )}
      <DataAttributesPropsWrapper {...props} />
    </div>
  );
};

export default ComplianceEditProps;
