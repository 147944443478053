import * as React from 'react';
import * as _ from 'lodash';
import { EventStoreEventType, IEditorStoreEvent, IInvalidUnit } from 'mm-types';
import { getAll } from '../../../../../clients/invalidUnits';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import { Cancelled } from '../../../../../clients/base-clients';
import InvalidUnitsList from './InvalidUnitsList';
import { CircularProgress } from 'material-ui';
import EditorStore from '../../../../../flux/editor/EditorStore';
import useListenToStore from '../../../../hooks/useListenToStore';

const InvalidUnitsContainer = () => {
  const [invalidUnits, setInvalidUnits] = React.useState<IInvalidUnit[]>([]);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useListenToStore({ store: EditorStore, eventListener: _onEditorStoreUpdate });

  React.useEffect(() => {
    loadDtdErrors();
  }, []);

  const loadDtdErrors = _.debounce(() => {
    const project = {
      projectUid: ProjectStore.getProject()!.uid,
      indexUid: ProjectStore.getCurrentRevisionUid()
    };
    setIsLoading(true);
    getAll(project.projectUid, project.indexUid)
      .then((r) => {
        if (r instanceof Cancelled) {
          return;
        }
        setInvalidUnits(r);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, 500);

  function _onEditorStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (
      e.type === 'deleteUnitConfirm' ||
      e.type === 'deleteSelectedUnitsConfirm' ||
      e.type === 'updateUnit' ||
      e.type === 'retrieveUnit' ||
      e.type === 'unitMoved' ||
      e.type === 'insertUnit' ||
      e.type === 'createUnit' ||
      e.type === 'synchronizeUnitEditAction'
    ) {
      loadDtdErrors();
    }
  }

  const renderInvalidUnits = () => {
    if (invalidUnits.length === 0) {
      return (
        <div className="narrow-panel-empty-list">
          <img src={'/assets/images/comments_panel_info.svg'} />
          <span className="empty-title">No Invalid Units</span>
        </div>
      );
    } else {
      return <InvalidUnitsList invalidUnits={invalidUnits} />;
    }
  };

  return (
    <div className="invalid-units-container">
      {isLoading ? (
        <div className="loading">
          <CircularProgress mode="indeterminate" size={50} />
        </div>
      ) : (
        renderInvalidUnits()
      )}
    </div>
  );
};

export default InvalidUnitsContainer;
