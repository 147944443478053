import * as React from 'react';
import UserAutoSuggest from '../../../../misc/UserAutoSuggest';
import { IUser } from 'mm-types';

export type UserListData = { status?: string; reviewerDisposition?: string };

export type Props = {
  users: null | IUser[];
  commenters: null | IUser[];
  disabled?: boolean;
  onChange: (e: { type: string; userUid?: string; data?: string | UserListData }) => void;
  userAvailabilityCheck: (user: IUser) => boolean;
};

const UserFilterList = (props: Props) => {
  const [filter, setFilter] = React.useState<string>('');

  function renderSuggestion(suggestion: IUser) {
    return (
      <div className="user-autosuggest-entry">
        <img src={suggestion.avatarUrl} />
        <span className="user-name">{suggestion.displayName}</span>
      </div>
    );
  }

  function handleUserSelection(data: { suggestion: string }) {
    props.onChange({ type: 'add', data: data.suggestion });
  }

  function handleRemoveUserChange(user: IUser) {
    props.onChange({ type: 'remove', userUid: user.uid });
  }

  let filtered = props.commenters ? props.commenters.filter((u) => props.userAvailabilityCheck(u)) : [];
  filtered = filtered.filter((f) => f.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1);

  return (
    <div className="user-autocomplete-container-outer">
      <div className="user-autocomplete-container-inner">
        <ul>
          {props.users !== null
            ? props.users.map((u) => {
                return (
                  <li key={u.uid}>
                    <div className="first-row">
                      <div className="user-pill">
                        <img src={u.avatarUrl} />
                        <span className="user-name">{u.displayName}</span>
                        <span
                          className="material-icons"
                          onClick={() => {
                            handleRemoveUserChange(u as IUser);
                          }}
                        >
                          remove_circle
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })
            : undefined}
        </ul>
      </div>
      <UserAutoSuggest
        placeholder="Type to select author"
        users={filtered}
        onClear={() => setFilter('')}
        getSuggestions={(value) => setFilter(value)}
        renderSuggestion={(suggestion) => renderSuggestion(suggestion)}
        onSelected={(data) => handleUserSelection(data)}
      />
    </div>
  );
};

export default UserFilterList;
