import * as React from 'react';
import OrgAdminStore, { OrgAdminStoreEvent } from '../../flux/settings/OrgAdminStore';
import AppStateStore from '../../flux/common/AppStateStore';
import { IOrganisation } from 'mm-types';
import useListenToStore from './useListenToStore';

export type AdminAndMemberOrgs = {
  adminOrgs: IOrganisation[];
  memberOrgs: IOrganisation[];
};

export const useOrgAdminAndMembers = (): AdminAndMemberOrgs => {
  const [adminAndMembersOrgs, setAdminAndMembersOrgs] = React.useState<AdminAndMemberOrgs>({
    adminOrgs: [],
    memberOrgs: []
  });

  useListenToStore({ store: OrgAdminStore, eventListener: onOrgAdminStoreChange });

  React.useEffect(() => {
    OrgAdminStore.currentUserOrgAdminList();
    return () => {
      AppStateStore.clearEditorState();
    };
  }, []);

  function onOrgAdminStoreChange(event: OrgAdminStoreEvent) {
    setAdminAndMembersOrgs({
      adminOrgs: event.state.currentAdminOrgs ?? [],
      memberOrgs: event.state.currentMemberOrgs ?? []
    });
  }

  return adminAndMembersOrgs;
};
