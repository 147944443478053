import * as React from 'react';
import * as _ from 'lodash';
import { PostUnitRenderActionType } from '../../../flux/editor/PostUnitRenderActions';
import SmartContentLib from '../sharedcontentlib/SmartContentLib';
import SmartContentStore from '../../../flux/editor/SmartContentStore';
import SecondaryDocument from './SecondaryDocument';
import EditorStore from '../../../flux/editor/EditorStore';
import EditorModes from '../../../flux/editor/EditorModes';
import { IRouteParams, SecondaryDocumentModes, IEditorStoreEvent, EventStoreEventType } from 'mm-types';
import { match } from 'react-router';
import * as H from 'history';
import { withRouter } from 'react-router';

export type Props = {
  onChange: () => void;
  match: match<IRouteParams>;
  history: H.History;
  location: H.Location<string>;
};

export type State = {
  secondaryDocumentClosed: boolean;
  secondaryDocument: SecondaryDocumentType;
};

type SecondaryDocumentType = null | {
  projectUid: string;
  indexUid: string;
  startUnitUid?: string;
  unitUid?: string;
  isStartUnitVolume?: string;
  mode: SecondaryDocumentModes;
};

class SecondaryDocumentContainer extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      secondaryDocument: null,
      secondaryDocumentClosed: false
    };

    this.unsubscribe = EditorStore.listen(this._onEditStoreUpdate, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;
      if (EditorModes.getProperties(castEvent.data!.to!).isAllowed(EditorModes.attributes.dualDocsDisplay)) {
        this.setState({ secondaryDocument: null }, () => {
          this.props.onChange();
        });
      }
    } else if (e.type === 'postUnitRender') {
      const castEvent = e as IEditorStoreEvent<'postUnitRender'>;
      const query: Partial<{ action: PostUnitRenderActionType; [props: string]: string }> = castEvent.data! || {};
      if (
        query.action === 'OPEN_IN_SECONDARY_DOC' &&
        (EditorStore.isMode('SMARTCONTENTLIB') || EditorStore.isMode('REGULATIONSELECTION')) &&
        query?.projectUid &&
        query?.indexUid
      ) {
        const secondaryDocument: SecondaryDocumentType = {
          projectUid: query.projectUid,
          indexUid: query.indexUid,
          startUnitUid: query?.startUnitUid,
          unitUid: query?.unitUid,
          isStartUnitVolume: query?.isStartUnitVolume,
          mode: query?.mode as SecondaryDocumentModes
        };

        this.setState({ secondaryDocument: secondaryDocument }, () => {
          this.props.onChange();
        });
      }
    }
  }

  _openNewSecondaryRegDoc(doc: { projectUid: string; indexUid: string; startUnitUid: string }) {
    SmartContentStore.retrieveDefaultRegModel({
      projectUid: doc.projectUid,
      indexUid: doc.indexUid,
      unitUid: doc.startUnitUid
    }).then(() => {
      return EditorStore.getUnitTocableUnitUid(doc.startUnitUid, {
        projectUid: doc.projectUid,
        indexUid: doc.indexUid
      }).then((result) => {
        this.transitionToParams({
          target: 'secondarydoc',
          action: 'OPEN_IN_SECONDARY_DOC' as PostUnitRenderActionType,
          mode: 'REG_SELECT_MODE' as SecondaryDocumentModes,
          projectUid: doc.projectUid,
          indexUid: doc.indexUid,
          startUnitUid: result.targetVolumeUid,
          unitUid: doc.startUnitUid,
          isStartUnitVolume: result.unit.definitionId === 'volume'
        });
      });
    });
  }

  _handleDocClose() {
    this.setState({ secondaryDocument: null, secondaryDocumentClosed: true }, () => {
      this.props.onChange();
    });
  }

  // transitions to the current url but with new GET params
  transitionToParams(getParams) {
    const urlParams = getParams ? '?' + jQuery.param(getParams) : '';
    EditorStore.blurEditor(
      () => {
        this.props.history.push(location.pathname + urlParams, {});
      },
      {},
      true
    );
  }

  render() {
    return this.state.secondaryDocument ? (
      <SecondaryDocument
        {...this.state.secondaryDocument}
        onClose={() => this._handleDocClose()}
        onOpenNew={(doc) => this._openNewSecondaryRegDoc(doc)}
      />
    ) : (
      <SmartContentLib showPreviousSearch={this.state.secondaryDocumentClosed} />
    );
  }
}

export default withRouter(SecondaryDocumentContainer);
