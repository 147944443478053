import { MenuItem } from '../../EditorMenu';
import * as React from 'react';
import { IElementDefinition } from 'mm-types';

export function hideDisabledMenuItems(menuItems: JSX.Element[], noItemsLabel: string): JSX.Element[] {
  menuItems = menuItems.filter((item) => {
    return item.props.title ? item.props.className.indexOf('submenu-show-as-disabled') === -1 : item.props.disabled === false;
  });

  if (menuItems.length === 0) {
    menuItems.push(
      <MenuItem key="noInserts" value="noInserts" disabled={true} className="insert-none">
        {noItemsLabel}
      </MenuItem>
    );
  }
  return menuItems;
}

export function canDisplayInContentMenu(ed: IElementDefinition) {
  return ed.userCreatable && !ed.editRibbonElement;
}
