import { IUser } from 'mm-types';
import config from '../../../../utils/config';

function _decorateRecipe(url: string, recipe: string) {
  const recipeParam = 'recipeName=' + recipe;
  let uploadUrl: string = url;

  if (url.indexOf('recipeName=thumb40') !== -1 && recipe !== 'thumb40') {
    uploadUrl = url.replace('recipeName=thumb40', '');
  }

  return uploadUrl + (url.indexOf('recipeName=thumb40') !== -1 ? '' : (url.indexOf('?') !== -1 ? '&' : '?') + recipeParam);
}

function imagePath(user: IUser | { uid: string }, recipe = 'thumb40') {
  return (user as IUser).avatarUrl
    ? _decorateRecipe((user as IUser).avatarUrl, recipe)
    : `${config.apiRoot}/users/${user.uid}/avatars/content?seed=${Math.random()}&recipeName=${recipe}`;
}

function imagePathGroup() {
  // TODO once icons available allow for similar bucket selection to user avatar
  return '/assets/images/user_avatars/user_group_icon.svg';
}

const toRet = {
  imagePath: imagePath,
  imagePathGroup: imagePathGroup
};

export default toRet;
