import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Mention, MentionsInput } from 'react-mentions';
import * as _ from 'lodash';
import userUtil from '../../../../documents/team/util/user';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import ProjectUsersStore from '../../../../../flux/editor/ProjectUsersStore';
import CommentStore from '../../../../../flux/editor/CommentStore';
import FileIcon from '../../../../misc/icons/FileIcon';
import { IComment, CommentType } from 'mm-types';
import FileAttachmentStore from '../../../../../flux/editor/FileAttachmentStore';
import { IFileAttachment, IUser } from 'mm-types';
import { CommentStatus, ExternalUserType } from '../../../../../flux/editor/CommentStore';
import { CommentButtons, ReplyButtons } from '../comments/components';
import { ICommentFormButtonsProps } from '../comments/components/ReplyButtons';
import ActiveUserStore from '../../../../../flux/common/ActiveUserStore';
import { CommentFormReducer } from './reducers';

const MAX_LENGTH = 1000;
let _mentionUsers: Partial<IUser>[] = [];
const _mentionAttachments: Partial<IFileAttachment>[] = [];

export type RenderMention = [
  {
    id: string;
    display: string;
  }
];

export type Props = {
  filterSinceDateTime?: number | null;
  commentsUnreadFilter?: 'unread' | 'read' | 'all';
  currentCommentFilter?: CommentStatus;
  currentUserIdsFilter?: string[];
  currentExternalAuthFilter?: ExternalUserType;
  className?: string;
  unitUid: string;
  unitType?: string;
  threadUid?: string;
  closeReplies?: () => void;
  onCreated?: (commentUid: string) => void;
  onCancel?: () => void;
  comment?: IComment | null;
};

const CommentForm = (props: Props) => {
  let searchUsers = _.throttle(innerSearchUsers.bind(this), 500, { leading: false });
  let searchAttachments = _.throttle(innerAttachments.bind(this), 500, { leading: false });
  let componentRef = React.useRef(null);

  const [state, dispatch] = React.useReducer(CommentFormReducer, {
    commentTextValue: props.comment ? renderTextWithMentions(props.comment) : '',
    showFilesError: false,
    isPublic: !ProjectStore.getProject()?.currentUserPermissions?.canCommentInternal,
    attachedFiles: props.comment ? props.comment.attachments.media : [],
    maxLengthExceeded: false,
    attachmentMode: false
  });

  React.useEffect(() => {
    searchUsers = _.throttle(innerSearchUsers.bind(this), 500, { leading: false });
    searchAttachments = _.throttle(innerAttachments.bind(this), 500, { leading: false });
    return () => {};
  }, []);

  function renderTextWithMentions(comment) {
    const regex = /~([a-zA-Z0-9]){8}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){12}/gm;
    let alteredText = $('<div/>').text(comment.text).html(); // must encode ourselves
    comment.mentions.users.forEach((user) => {
      // populate mentionsUsers
      handleUserAdd(user.uid, user.displayName);
      // react-mentions
      alteredText = alteredText.replace('@' + user.uid, '@%' + user.displayName + ':' + 'user' + ':' + user.uid + '%');
    });

    if (comment.attachments) {
      const attachments = alteredText.match(regex);
      if (attachments) {
        attachments.forEach((attachment) => {
          attachment = attachment.replace('~', '');
          const index = comment.attachments.media.findIndex((i) => i.uid === attachment);
          if (index > -1) {
            alteredText = attachmentInsideCommentsRender(comment, alteredText);
          } else {
            alteredText = restrictedAttachmentInsideCommentsRender(alteredText);
          }
        });
      }
    }
    return alteredText;
  }

  function attachmentInsideCommentsRender(comment, alteredText) {
    comment.attachments.media.forEach((attachment) => {
      handleAttachmentAdd(attachment.uid, attachment.filename);
      alteredText = alteredText.replace('~' + attachment.uid, '@%' + attachment.filename + ':' + 'attachment' + ':' + attachment.uid + '%');
    });

    return alteredText;
  }

  function restrictedAttachmentInsideCommentsRender(alteredText) {
    const regex = /~([a-zA-Z0-9]){8}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){12}/gm;
    const restrictedAttachments = alteredText.match(regex);
    if (restrictedAttachments) {
      restrictedAttachments.forEach((restrictedAttachment) => {
        const attachmentUid = restrictedAttachment.replace('~', '');
        handleAttachmentAdd(attachmentUid, 'Restricted File');
        alteredText = alteredText.replace('~' + attachmentUid, '@%' + 'Restricted File' + ':' + 'attachment' + ':' + attachmentUid + '%');
      });
    }

    return alteredText;
  }

  function innerSearchUsers(search, callback: (users: Partial<IUser>[] | RenderMention) => void) {
    ProjectUsersStore.retrieve({
      projectUid: ProjectStore.getProject()?.uid,
      query: search
    }).then((users) => {
      const searchUsers =
        users?.map((u) => {
          return _.extend(u, { id: u.uid, display: u.displayName });
        }) ?? [];
      callback(searchUsers);
    });
  }

  function innerAttachments(search, callback: (files: Partial<IFileAttachment>[] | RenderMention) => void) {
    FileAttachmentStore.getIndexAttachments().then((files: IFileAttachment[]) => {
      files.forEach((f) => {
        _.extend(f, { id: f.uid, display: f.filename });
      });
      dispatch({ type: 'setShowFilesError', payload: files.length < 1 });
      const filteredFiles = filterAttachments(files, search);
      callback(filteredFiles);
      dispatch({ type: 'setAttachedFiles', payload: filteredFiles });
    });
  }

  function filterAttachments(files: Partial<IFileAttachment>[], search: string) {
    let filteredFiles;
    if (files.length > 0) {
      filteredFiles = files.filter((file) => file.filename?.toLowerCase().includes(search.toLowerCase()));
    } else {
      filteredFiles = [{ id: '', display: 'no-files' }];
    }
    return filteredFiles;
  }

  function getCurrentUser() {
    return ActiveUserStore.getUser();
  }

  function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  function cancelReply() {
    if (props.closeReplies) {
      props.closeReplies();
    }
  }

  function create() {
    let text = state.commentTextValue.trim();
    const commentUnitUid = props.unitUid;
    const latestMentionedUsers: Partial<IUser>[] = [];
    const latestAttachedFiles: Partial<IFileAttachment>[] = [];
    const docParams = EditorStore.getDocParams();

    if (text.length) {
      if (text.includes(':user:')) {
        text = text.split(/user:/).join('');
        // there is no way to detect removed items as you edit, so iterate through added and remove as required based on textual content
        _mentionUsers.forEach((user) => {
          const matchInlineUserText = '@%' + user.displayName + ':' + user.uid + '%';
          if (text.includes(matchInlineUserText)) {
            latestMentionedUsers.push({ uid: user.uid });
            text = text.replace(matchInlineUserText, '@' + user.uid); // prep for server
          }
        });
      }
      if (text.includes('attachment:')) {
        if (text.includes('\n')) {
          text = text.replace('\n', ' ');
        }

        text = text.replace(new RegExp('attachment:', 'g'), '');

        // there is no way to detect removed items as you edit, so iterate through added and remove as required based on textual content
        _mentionAttachments.forEach((attachment) => {
          const matchInlineUserText = '@%' + attachment.filename + ':' + attachment.uid + '%';

          // check for uid for strike through style
          if (attachment.uid) {
            if (text.indexOf(matchInlineUserText) !== -1) {
              latestAttachedFiles.push({ uid: attachment.uid });
              text = text.replace(matchInlineUserText, '~' + attachment.uid); // prep for server
            }
          }
        });
      }

      if (props.comment) {
        const updatedComment: IComment = {
          ...props.comment,
          uid: props.comment.uid,
          threadUid: props.comment.threadUid,
          text: text,
          mentions: { users: latestMentionedUsers },
          attachments: { media: latestAttachedFiles },
          status: props.comment.status
        };

        // comment update
        CommentStore.updateComment(
          docParams.projectUid!,
          docParams.indexUid!,
          {
            fromDateTime: props.filterSinceDateTime!,
            unread: props.commentsUnreadFilter!,
            status: props.currentCommentFilter!,
            users: props.currentUserIdsFilter!,
            externalUserType: props.currentExternalAuthFilter!
          },
          updatedComment
        ).then((result) => {
          _mentionUsers = [];
          dispatch({ type: 'setCommentTextValue', payload: '' });
          if (props.onCreated) {
            props.onCreated(commentUnitUid);
          }
        });
      } else {
        // comment creation
        if (props.threadUid) {
          const newComment: Partial<IComment> = {
            text: text,
            attachments: { media: latestAttachedFiles },
            unitUid: props.unitUid,
            threadUid: props.threadUid,
            mentions: { users: latestMentionedUsers }
          };

          CommentStore.comment(docParams.projectUid!, docParams.indexUid!, newComment, {
            fromDateTime: props.filterSinceDateTime!,
            unread: props.commentsUnreadFilter!,
            status: props.currentCommentFilter!,
            users: props.currentUserIdsFilter!,
            externalUserType: props.currentExternalAuthFilter!
          }).then((result) => {
            _mentionUsers = [];
            dispatch({ type: 'setCommentTextValue', payload: '' });
          });
        } else {
          const commentType: CommentType = state.isPublic ? 'PUBLIC' : 'INTERNAL';
          const newComment: Partial<IComment> = {
            text: text,
            type: commentType,
            unitUid: commentUnitUid,
            attachments: { media: latestAttachedFiles },
            mentions: { users: latestMentionedUsers }
          };

          CommentStore.comment(docParams.projectUid!, docParams.indexUid!, newComment, {
            fromDateTime: props.filterSinceDateTime!,
            unread: props.commentsUnreadFilter!,
            status: props.currentCommentFilter!,
            users: props.currentUserIdsFilter!,
            externalUserType: props.currentExternalAuthFilter!
          }).then(() => {
            _mentionUsers = [];
            if (props.onCreated) {
              props.onCreated(commentUnitUid);
            }
          });
        }
      }
    }
  }

  function getTypeFromMimeType(file: IFileAttachment) {
    if (file.mimeType === 'application/pdf') {
      return 'pdf';
    }
    if (file.mimeType === 'application/zip') {
      return 'archive';
    }
    if (
      file.mimeType === 'video' ||
      file.mimeType === 'video/mp4' ||
      file.mimeType === 'video/webm' ||
      file.mimeType === 'video/ogg' ||
      file.mimeType === 'video/quicktime'
    ) {
      return 'video';
    }
    if (file.mimeType === 'text/plain') {
      return 'text';
    }

    if (file.mimeType === 'audio/mp3' || file.mimeType === 'audio/mpeg') {
      return 'audio';
    }

    if (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'doc';
    }
    if (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'spreadsheet';
    }

    if (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return 'presentation';
    }

    if (
      file.mimeType === 'image/jpg' ||
      file.mimeType === 'image/jpeg' ||
      file.mimeType === 'image/png' ||
      file.mimeType === 'image/bmp' ||
      file.mimeType === 'image/gif'
    ) {
      return 'image';
    } else {
      return 'other';
    }
  }

  function handleTextChange(e, value) {
    if (value.length >= MAX_LENGTH) {
      dispatch({ type: 'setMaxLengthExceeded', payload: true });
    } else {
      dispatch({ type: 'setCommentTextValue', payload: value });
      dispatch({ type: 'setMaxLengthExceeded', payload: false });
    }
  }

  function renderSuggestion(item, search, highlightedDisplay, type) {
    if (type === 'user') {
      return (
        <div className="mention-user-listitem">
          <img src={userUtil.imagePath(item)} />
          <div className="user-name">{highlightedDisplay}</div>
        </div>
      );
    }

    return (
      <div>
        {item && item.display === 'no-files' ? (
          <div className="file-attachment-content">
            <div className="file-attachment-heading">No existing files</div>
          </div>
        ) : (
          <div>
            <div className="file-attachment-icon">
              <FileIcon fileType={getTypeFromMimeType(item)} />
            </div>

            <div className="file-attachment-content">
              <div id="attachment-text" className={`file-attachment-heading`}>
                <p>{highlightedDisplay}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function handleUserAdd(uid, displayName) {
    _mentionUsers.push({ uid: uid, displayName: displayName });
  }

  function handleAttachmentAdd(uid, displayName) {
    _mentionAttachments.push({ uid: uid, filename: displayName });
  }

  function clickLock() {
    dispatch({ type: 'setIsPublic', payload: !state.isPublic });
  }

  function onClickAttachFileButton() {
    dispatch({ type: 'setCommentTextValue', payload: state.commentTextValue + '~' });
    if (componentRef) {
      $(ReactDOM.findDOMNode(componentRef.current) as Element)
        .find('textarea')
        .focus();
    }
  }

  let avatar: JSX.Element | JSX.Element[] = [];
  const noFiles = state.showFilesError;

  if (!props.comment) {
    avatar = <img className="comment-avatar" src={getCurrentUser()?.avatarUrl} />;
  }
  let placeholder = '@ ~ Comment...';
  if (ProjectStore.getProject()?.currentUserPermissions?.canManageAttachments) {
    if (props.threadUid) {
      placeholder = '@ | ~ Reply on ' + (props.unitType ? props.unitType : '') + '...';
    } else if (props.unitType) {
      placeholder = '@ | ~ Comment on ' + props.unitType + '...';
    }
  } else {
    placeholder = '@ Comment...';
    if (props.threadUid) {
      placeholder = '@ Reply on ' + (props.unitType ? props.unitType : '') + '...';
    } else if (props.unitType) {
      placeholder = '@ Comment on ' + props.unitType + '...';
    }
  }

  const commentForm = (
    <div className="comment-post-inner comment-section" ref={componentRef}>
      {avatar}
      <div>
        <div>
          <MentionsInput
            value={state.commentTextValue}
            onChange={(e, val) => handleTextChange(e, val)}
            className={`comment-textarea textarea-attachment ${noFiles ? 'no-files' : ''}`}
            markup="@%__display__:__type__:__id__%"
            allowSpaceInQuery={true}
            placeholder={placeholder}
          >
            <Mention
              type="user"
              trigger="@"
              className="comment-textarea-mention"
              data={searchUsers}
              appendSpaceOnAdd={true}
              renderSuggestion={(user, search, highlight) => renderSuggestion(user, search, highlight, 'user')}
              onAdd={(uid, displayName) => handleUserAdd(uid, displayName)}
            />

            {ProjectStore.getProject()?.currentUserPermissions?.canManageAttachments ? (
              <Mention
                type="attachment"
                trigger="~"
                id="attachment-mention"
                className={`textarea-attachment`}
                data={searchAttachments}
                style={{ background: '#EBF6FE' }}
                appendSpaceOnAdd={true}
                renderSuggestion={(attachment, search, highlight) => renderSuggestion(attachment, search, highlight, 'attachment')}
                onAdd={(uid, displayName) => handleAttachmentAdd(uid, displayName)}
              />
            ) : (
              <Mention
                type="attachment"
                id="attachment-mention"
                className={`textarea-attachment`}
                data={() => []}
                style={{ background: '#EBF6FE' }}
              />
            )}
          </MentionsInput>
        </div>
      </div>
    </div>
  );
  const masterClassName = 'comment-post ' + (props.comment ? ' edit' : '');

  const buttonProps: ICommentFormButtonsProps = {
    maxLength: MAX_LENGTH,
    maxLengthExceeded: state.maxLengthExceeded,
    mode: {
      edit: !!props.comment,
      reply: !!props.threadUid,
      internal: !state.isPublic
    },
    comment: props.comment,
    permissions: {
      canCommentInternal: !!ProjectStore.getProject()?.currentUserPermissions?.canCommentInternal,
      canManageAttachments: !!ProjectStore.getProject()?.currentUserPermissions?.canManageAttachments
    },
    onCreate: () => create(),
    onAttachFile: () => onClickAttachFileButton(),
    onVisibilityChange: () => clickLock(),
    onCancel: () => cancel(),
    onCancelReply: () => cancelReply()
  };

  if (props.threadUid) {
    // a reply

    return (
      <li className={masterClassName}>
        {commentForm}
        <ReplyButtons {...buttonProps} />
      </li>
    );
  } else {
    // original post

    return (
      <div className={props.className}>
        <div className={masterClassName}>
          {commentForm}
          <CommentButtons {...buttonProps} />
        </div>
      </div>
    );
  }
};

export default CommentForm;
