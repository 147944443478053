import * as React from 'react';
import WorkflowUtil from '../../../utils/WorkflowUtil';
import IndexLockStatuses from '../../../flux/editor/IndexLockStatuses';
import RaisedButtonWithTooltip from '../RaisedButtonWithTooltip';
import WorkflowAlerts from './WorkflowAlerts';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import { IProject, IWorkFlow, IIndex, IUser } from 'mm-types';
import { WorkflowActionData } from '../../../clients/workflows';

const _buttonLabelStyle = { paddingLeft: '5px', fontSize: '12px' };
const _buttonStyle = { padding: '0' };

const _buttonTextMaxLength = 15;
const _buttonTextMaxLengthWithSkip = 8;

export type Props = {
  user?: IUser;
  index?: IIndex;
  approvalInfo: string;
  project?: IProject;
  workflow?: IWorkFlow | null;
  isMultiSkip: boolean;
  onAction: (workflowUid: string, e: WorkflowActionData) => void;
  onWorkflowStart: (index: IIndex, callback: () => void) => void;
  onOpenApprovalSettings: () => void;
};

export type State = {
  comment: string;
  isPerformingAction: boolean;
  alerts: string[];
  isApprovalRequired: boolean;
  isApprovalMissing: boolean;
};

export default class WorkflowActionArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comment: '',
      isPerformingAction: false,
      alerts: [],
      isApprovalRequired: false,
      isApprovalMissing: false
    };
  }

  static defaultProps: Partial<Props> = {
    workflow: null
  };

  componentDidMount() {
    this.setState({
      alerts: WorkflowUtil.findAlerts(this.props.project!, this.props.workflow, this.props.index),
      isApprovalRequired: WorkflowUtil.isApprovalRequired(this.props.workflow!),
      isApprovalMissing: WorkflowUtil.showApprovalWarningMsg(this.props.workflow!, this.props.approvalInfo)
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.workflow || nextProps.approvalInfo) {
      const workflow = nextProps.workflow ? nextProps.workflow : this.props.workflow,
        index = nextProps.index ? nextProps.index : this.props.index,
        approvalInfo = nextProps.approvalInfo ? nextProps.approvalInfo : this.props.approvalInfo;

      this.setState({
        alerts: WorkflowUtil.findAlerts(this.props.project!, workflow, index, approvalInfo),
        isApprovalRequired: WorkflowUtil.isApprovalRequired(workflow!),
        isApprovalMissing: WorkflowUtil.showApprovalWarningMsg(workflow!, approvalInfo)
      });
    }
  }

  _handleCommentChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ comment: event.target.value.substr(0, 999) });
  }

  _canUserPerformAction() {
    let result = WorkflowUtil.canUserPerformAction(this.props.user, this.props.workflow, this.props.project, this.props.index);
    if (this._isCommentRequired() && this.state.comment === '') {
      result = false;
    }
    if (this.state.isApprovalRequired && this.state.isApprovalMissing) {
      result = false;
    }
    return result;
  }

  _canUserPerformReject() {
    let result = WorkflowUtil.canUserPerformReject(this.props.user!, this.props.workflow!);
    if (this._isCommentRequired() && this.state.comment === '') {
      result = false;
    }
    return result;
  }

  _shortenText(text) {
    return WorkflowUtil.shortenText(text, this._isSkipEnabled() ? _buttonTextMaxLengthWithSkip : _buttonTextMaxLength);
  }

  _isTooltipEnabledForField(field) {
    return WorkflowUtil.isLongerThanMax(
      WorkflowUtil.getText(field!, this.props.workflow!)!,
      this._isSkipEnabled() ? _buttonTextMaxLengthWithSkip : _buttonTextMaxLength
    );
  }

  _getText(field) {
    return WorkflowUtil.getText(field, this.props.workflow!);
  }

  _showPreviousButton() {
    return WorkflowUtil.showPreviousButton(this.props.workflow!);
  }

  _showNextButton() {
    return WorkflowUtil.showNextButton(this.props.workflow!);
  }

  _handleActionClick(e, action) {
    if (this.props.workflow && this.props.workflow.activeStage) {
      this.props.onAction(this.props.workflow.uid, { decisionModel: action, comment: this.state.comment });
    }

    this.setState({ comment: '' });

    e.stopPropagation();
    e.preventDefault();
  }

  _isCommentRequired() {
    if (this.props.workflow) {
      return WorkflowUtil.isCommentRequired(this.props.workflow.activeStage);
    }
  }

  _isSkipEnabled() {
    if (this.props.workflow && this.props.user && this.props.project) {
      return WorkflowUtil.isSkippable(this.props.workflow) && WorkflowUtil.isUserWorkflowAdmin(this.props.user, this.props.project);
    }
    return false;
  }

  _getPreviousButton() {
    if (this._showPreviousButton()) {
      return (
        <RaisedButtonWithTooltip
          label={this._shortenText(this._getText('previousStepTitle'))}
          labelColor="#FFFFFF"
          backgroundColor="#a2a2a2"
          className="workflow-area__action-button workflow-area__action-button-prev"
          buttonClassName="workflow-area__action-button-internal"
          onClick={(e) => this._handleActionClick(e, 'REJECT')}
          disabled={!this._canUserPerformReject()}
          buttonStyle={_buttonStyle}
          labelStyle={_buttonLabelStyle}
          iconClass="close"
          tooltipEnabled={this._isTooltipEnabledForField('previousStepTitle')}
          tooltipText={this._getText('previousStepTitle')!}
        ></RaisedButtonWithTooltip>
      );
    }
  }

  _getNextButton() {
    if (this._showNextButton()) {
      return (
        <RaisedButtonWithTooltip
          label={this.props.isMultiSkip ? 'Proceed' : this._shortenText(this._getText('nextStageActionTitle'))}
          labelColor="#FFFFFF"
          backgroundColor="#009688"
          className="workflow-area__action-button workflow-area__action-button-next"
          buttonClassName="workflow-area__action-button-internal"
          onClick={(e) => this._handleActionClick(e, 'APPROVE')}
          disabled={!this._canUserPerformAction()}
          buttonStyle={_buttonStyle}
          labelStyle={_buttonLabelStyle}
          iconClass="done"
          tooltipEnabled={this._isTooltipEnabledForField('nextStageActionTitle')}
          tooltipText={this.props.isMultiSkip ? 'Proceed' : this._getText('nextStageActionTitle')!}
        ></RaisedButtonWithTooltip>
      );
    }
  }

  _getStartWorkflowButton() {
    if (this._isUserWorkflowAdmin()) {
      return (
        <RaisedButtonWithTooltip
          label="Start Revision"
          primary={true}
          backgroundColor="#009688"
          className="workflow-area__action-button workflow-area__action-button-next"
          buttonClassName="workflow-area__action-button-internal"
          buttonStyle={_buttonStyle}
          labelStyle={_buttonLabelStyle}
          onClick={(e) => this._startWorkflow()}
          disabled={!this._canStartRevision()}
        ></RaisedButtonWithTooltip>
      );
    }
  }

  _startWorkflow() {
    // Starting only an index passed through props
    this.setState({ isPerformingAction: true }, () => {
      this.props.onWorkflowStart(this.props.index!, () => {
        this.setState({ isPerformingAction: false });
      });
    });
  }

  _canStartRevision() {
    return !this.state.isPerformingAction && this._isUserWorkflowAdmin();
  }

  _isUserWorkflowAdmin() {
    return WorkflowUtil.isUserWorkflowAdmin(this.props.user!, this.props.project!);
  }

  // NEW CONDITIONS:
  _noWorkflow() {
    const result = this.props.project && !this.props.project.workflow;
    return result;
  }

  _workflowNotStarted() {
    const result = this.props.index && this.props.index.lockedStatus === IndexLockStatuses.props.LOCKED_FOR_WORKFLOW_TO_START.code;
    return result;
  }

  _workflowStarted() {
    const result = this.props.workflow && this.props.index!.lockedStatus !== IndexLockStatuses.props.LOCKED_FOR_WORKFLOW_TO_START.code;
    return result;
  }

  _workflowNotLoaded() {
    const result = this.props.index && this.props.index.workflowUid && !this.props.workflow;
    return result;
  }

  // FINISH NEW CONDITIONS

  render() {
    let workflowArea;

    if (this._noWorkflow()) {
      workflowArea = <div />;
    } else if (this._workflowNotStarted()) {
      workflowArea = (
        <div className="workflow-area">
          <div className="workflow-area__action">{this._getStartWorkflowButton()}</div>
        </div>
      );
    } else if (this._workflowStarted()) {
      workflowArea = (
        <div className="workflow-area">
          <div className="workflow-area__comment">
            {this._isCommentRequired() ? <div className="workflow-area__comment-required">Comment required</div> : null}
            <textarea placeholder="Add comment: " value={this.state.comment} onChange={(e) => this._handleCommentChange(e)} />
          </div>
          <div className="workflow-area__action">
            {this._getPreviousButton()}
            {this._getNextButton()}
            {this._isSkipEnabled() && this.props.isMultiSkip === false ? (
              <div>
                <FlatButton className="workflow-area__action-button" onClick={(e) => this._handleActionClick(e, 'SKIP')}>
                  <span className="skip-button material-icons">skip_next</span> Skip
                </FlatButton>
              </div>
            ) : null}
          </div>

          <div className="workflow-area__info">
            <WorkflowAlerts alerts={this.state.alerts} />

            {this.state.isApprovalRequired ? (
              <div className="workflow-area__upload-artifacts">
                {this.state.isApprovalMissing ? (
                  <span>
                    Regulation info required{' '}
                    <a href="" onClick={() => this.props.onOpenApprovalSettings()}>
                      click here
                    </a>
                  </span>
                ) : (
                  <span>
                    Regulation info{' '}
                    <a href="" onClick={() => this.props.onOpenApprovalSettings()}>
                      view
                    </a>
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      );
    } else if (this._workflowNotLoaded()) {
      workflowArea = <CircularProgress mode="indeterminate" size={50} />;
    }

    return workflowArea;
  }
}
