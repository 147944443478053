import { IWorkspace } from 'mm-types';
import Store from '../Store';
import * as workspaces from '../../clients/workspaces';
import * as Reflux from 'reflux';
import Log from '../../utils/Log';

export type State = {
  workspaces: IWorkspace[];
};

export type WorkspaceStoreEvent = State;

export class WorkspaceStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      workspaces: []
    };
  }

  getInitialState() {
    return this.state;
  }

  getWorkspaces() {
    return this.state.workspaces;
  }

  getWorkspace(wsUid: string): Partial<IWorkspace> | undefined {
    if (!wsUid || wsUid === 'workspace') {
      return this.getPersonalWorkspace();
    } else if (wsUid === 'all') {
      return { name: 'All Documents' };
    } else if (wsUid === 'shared') {
      return { name: 'Shared with me' };
    } else if (wsUid === 'trash') {
      return { name: 'Trash' };
    } else {
      return this.state.workspaces.find(function (w) {
        return w.uid === wsUid;
      });
    }
  }

  getPersonalWorkspace() {
    return this.state.workspaces.find(function (w) {
      return w.type === 'PERSONAL';
    });
  }

  async initProjectDocs() {
    try {
      const workspacesReturned = await workspaces.getAll();
      this.state = { workspaces: workspacesReturned };
      this.trigger(this.state as WorkspaceStoreEvent);
    } catch (err) {
      Log.info('Exception when getting all workspaces');
    }
  }
}

const singleton = Reflux.initStore<WorkspaceStore>(WorkspaceStore);
export default singleton;
