import React, { useEffect, useState } from 'react';
import { FontIcon } from 'material-ui';
import Searchbar from '../../../editor/sidetabs/sub/searchbar/Searchbar';

import TreeView, { ExpandStatus, TreeElement } from '../../../general/tree-view/TreeView';
import { getDistributionGroupsTree, getDistributionGroupsTreeByAccount } from '../../../../clients/distributionGroups';
import { Cancelled } from '../../../../clients/base-clients';
import { useDebouncedCallback } from '../../../general/useDebouncedCallback';
import { MessageType } from '../../../../types/components/editor/MessageType';
import { IDistributionGroupTree } from 'mm-types';
import ReadConfirmGroupIcon from '../../../misc/icons/ReadConfirmGroupIcon';
import SpecialInterestGroupIcon from '../../../misc/icons/SpecialInterestGroupIcon';
import ActiveUserStore from '../../../../flux/common/ActiveUserStore';
import ReadConfirmDialog from '../../../documents/kpi/ReadConfirmDialog';

export enum ReportType {
  READ_CONFIRM,
  FEEDBACK,
  QUIZ
}

interface Props {
  title: string;
  reportType: ReportType;
  onExport: (
    groupUid: string,
    groupName: string,
    readConfirmAllManuals: boolean,
    reportType: ReportType,
    onSuccess: () => void,
    onError: () => void
  ) => void;
}

const DistributionGroupReports = (props: Props) => {
  const [showMessage, setShowMessage] = useState<MessageType>(MessageType.NONE);
  const [treeExpandStatus, setTreeExpandStatus] = useState<ExpandStatus>(ExpandStatus.partiallyExpanded);
  const [distributionGroups, setDistributionGroups] = useState<TreeElement[] | undefined>();
  const [isExportEnabled, setExportEnabled] = useState<boolean>(true);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<TreeElement>({ uid: '', name: '' });
  const setLoadingDebounced = useDebouncedCallback((value) => setLoading(value), 500);
  const accountUid = ActiveUserStore.getUser()?.account.uid;
  const isDistributorAdmin = ActiveUserStore.isDistributorAdmin();
  useEffect(() => {
    if (!distributionGroups) {
      setLoadingDebounced(true);
      const distributionPromise = isDistributorAdmin ? getDistributionGroupsTree() : getDistributionGroupsTreeByAccount(accountUid);
      distributionPromise
        .then((response) => {
          if (response instanceof Cancelled) {
            setLoadingDebounced(false);
            return;
          }
          setDistributionGroups(parseDistributionGroups(response));
          setLoadingDebounced(false);
        })
        .catch(() => {
          setShowMessage(MessageType.ERROR);
          setLoadingDebounced(false);
        });
    }
  }, []);

  const parseDistributionGroups = (
    groups: IDistributionGroupTree[],
    parent: TreeElement | undefined = undefined
  ): TreeElement[] | undefined => {
    if (groups) {
      return groups.map((group) => {
        return parseDistributionGroup(group, parent);
      });
    } else {
      return undefined;
    }
  };

  const parseDistributionGroup = (group: IDistributionGroupTree, parent: TreeElement | undefined): TreeElement => {
    const types: string[] = [];

    group.type === 'email' && types.push('email');
    group.readConfirm && types.push('readConfirm');
    group.global && types.push('global');
    group.specialInterest && types.push('specialInterest');
    group.distribution && types.push('distribution');

    const parsedGroup: TreeElement = {
      uid: group.uid,
      name: group.name,
      types: types,
      parent: parent
    };
    parsedGroup.children = parseDistributionGroups(group.children, parsedGroup);
    return parsedGroup;
  };

  const toggleTreeExpandStatus = () => {
    setTreeExpandStatus(showExpand() ? ExpandStatus.fullyExpanded : ExpandStatus.fullyCollapsed);
  };

  const showExpand = (): boolean => {
    return treeExpandStatus === 'fully_collapsed' || treeExpandStatus === 'partially_expanded';
  };

  const renderExportButtons = (element: TreeElement) => {
    return (
      <div className="tree-view-row__actions">
        {props.reportType === ReportType.READ_CONFIRM && (
          <span
            data-qa="tree-view-row__export-button"
            onClick={(e) => onExport(e, element, true)}
            className={
              'tree-view-row__export-button tree-view-row__leftButton ' + (isExportEnabled ? '' : 'tree-view-row__export-button--disabled')
            }
          >
            Export All
          </span>
        )}
        <span
          data-qa="tree-view-row__export-button"
          onClick={(e) => onExport(e, element, false)}
          className={
            'tree-view-row__export-button tree-view-row__rightButton ' + (isExportEnabled ? '' : 'tree-view-row__export-button--disabled')
          }
        >
          {props.reportType === ReportType.READ_CONFIRM ? 'Export Confirm Required' : 'Export CSV'}
        </span>
      </div>
    );
  };

  const onExport = (e: React.MouseEvent<HTMLElement>, element: TreeElement, readConfirmAllManuals: boolean) => {
    e.stopPropagation();
    if (isExportEnabled) {
      if (readConfirmAllManuals) {
        setSelectedGroup(element);
        setModalOpen(true);
      } else {
        exportDistributionGroupReport(element, readConfirmAllManuals);
      }
    }
  };

  const exportDistributionGroupReport = (group: TreeElement, readConfirmAllManuals: boolean) => {
    setExportEnabled(false);
    cleanDialog();
    const onSuccess = () => setTimeout(() => setExportEnabled(true), 1000);
    const onError = () => setExportEnabled(true);

    props.onExport(group.uid, group.name, readConfirmAllManuals, props.reportType, onSuccess, onError);
  };

  const cleanDialog = () => {
    setModalOpen(false);
  };

  const renderIcons = (types: string[]): JSX.Element => {
    return (
      <span style={{ marginRight: '5px', display: 'inline-flex' }}>
        {types.indexOf('email') > -1 && <FontIcon className="material-icons">email</FontIcon>}
        {types.indexOf('readConfirm') > -1 && <ReadConfirmGroupIcon />}
        {types.indexOf('global') > -1 && <FontIcon className="material-icons">language</FontIcon>}
        {types.indexOf('specialInterest') > -1 && <SpecialInterestGroupIcon />}
      </span>
    );
  };

  const canExport = (element: TreeElement): boolean => {
    function findElementTypeAmongChildren(element: TreeElement, elementType: string): boolean {
      if (element.children) {
        return !!element.children.find((element) => {
          return element.types && element.types?.indexOf(elementType) > -1;
        });
      }
      return false;
    }

    return !element.children?.length || findElementTypeAmongChildren(element, 'readConfirm');
  };

  return (
    <div className="dgr-container" data-qa="dgr-container">
      <div className="dgr-header-area">
        <div className="dgr-header-title">Select Distribution Group for Exports {props.title}</div>
        <div className="dgr-expand-button" data-qa="dgr-expand-button" onClick={toggleTreeExpandStatus}>
          <FontIcon className="material-icons">{showExpand() ? 'unfold_more' : 'unfold_less'}</FontIcon>
          <span className="dgr-expand-button-label" data-qa="dgr-expand-button-label">
            {showExpand() ? 'EXPAND ALL' : 'COLLAPSE ALL'}
          </span>
        </div>
      </div>
      <div className="dgr-search-area">
        <Searchbar dataqa="dgr-search-input" placeholder="Filter Groups" onSearch={setFilterTerm} triggerOnChange={true} />
      </div>
      <div className="dgr-tree-area">
        <TreeView
          data={distributionGroups || []}
          filterTerm={filterTerm}
          expandStatus={treeExpandStatus}
          onTreeExpandStatus={setTreeExpandStatus}
          renderButtons={renderExportButtons}
          isActionEnabled={isExportEnabled}
          canPerformActionOnElement={canExport}
          isLoading={isLoading}
          renderIcons={renderIcons}
        />
      </div>
      {showMessage === MessageType.ERROR && <div className="dgr-error-message">An error occurred, please try again.</div>}
      <ReadConfirmDialog
        onCancel={cleanDialog}
        onConfirm={() => {
          exportDistributionGroupReport(selectedGroup, true);
        }}
        open={isModalOpen}
      />
    </div>
  );
};

export default DistributionGroupReports;
