import * as React from 'react';
import * as _ from 'lodash';
import { IUnit } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import useListenToStore from '../../../../../hooks/useListenToStore';
import MetaDataSwitch from '../sub/MetaDataSwitch';
import MetaDataDropdown from '../sub/MetaDataDropdown';
import MetaDataInput from '../sub/MetaDataInput';

export type Props = {
  unit: IUnit;
};

// TODO: will require custom components, values, or default values:
// TODO: remove someinterestingtitle /update. this is just an example of META_DATA_DROPDOWN_DEFAULT_VALUES
const META_DATA_DROPDOWN_DEFAULT_VALUES = [
  {
    title: 'someinterestingtitle',
    values: ['', '1', '2', '3']
  }
];

const MetaData = (props: Props) => {
  const [metaData, setMetaData] = React.useState<any>({});

  React.useEffect(() => {
    loadMetaData();
  }, [props.unit]);

  // once we start updating data from metadata we'll need this
  const onEditorStoreUpdate = (e) => {};

  useListenToStore({ store: EditorStore, eventListener: onEditorStoreUpdate });

  const loadMetaData = (): void => {
    const docUnit = EditorStore.getDocUnitModel(props.unit.uid)!;
    let metaData = docUnit.getUnitMetaData();
    metaData = _.reduce(
      metaData,
      (accumulator: object, value: string | boolean, key: string) => {
        if (isVDashKey(key)) {
          const keyWithoutV = key.slice(1);
          accumulator[keyWithoutV] = value;
        }
        return accumulator;
      },
      {}
    );
    setMetaData(metaData);
  };

  const isVDashKey = (key: string): boolean => {
    return _.indexOf(key, 'v') === 0;
  };

  const getDefaultValuesByTitle = (title: string): string[] => {
    const container = _.find(META_DATA_DROPDOWN_DEFAULT_VALUES, ['title', title]);
    return container ? container.values : [];
  };

  const getMetaDataTypeByValue = (value: string | boolean, title: string): string => {
    let metaDataType = 'input';
    if (typeof value === 'boolean') {
      metaDataType = 'switch';
    } else if (isSelectMenu(title)) {
      metaDataType = 'select';
    }
    return metaDataType;
  };

  const isSelectMenu = (title: string): boolean => {
    return getDefaultValuesByTitle(title) != null;
  };

  const hasMetaData = (): boolean => {
    return Object.keys(metaData).length > 0;
  };

  const renderMetaDataItem = (value: string | boolean, title: string) => {
    const metaDataType = getMetaDataTypeByValue(value, title);
    switch (metaDataType) {
      case 'switch':
        return <MetaDataSwitch value={value as boolean} title={title} isDisabled={true} />;
      case 'select':
        return (
          <MetaDataDropdown value={value as string} title={title} isDisabled={true} getDefaultValuesByTitle={getDefaultValuesByTitle} />
        );
      default:
        return <MetaDataInput value={value as string} title={title} isDisabled={true} />;
    }
  };

  return (
    <div className="selected-unit-props-section metadata-section">
      {hasMetaData() ? _.map(metaData, renderMetaDataItem) : 'No Metadata Available'}
    </div>
  );
};

export default MetaData;
