import * as Reflux from 'reflux';
import ServerSettingsStore from './ServerSettingsStore';
import EventClient from '../events/EventClient';
import ActiveUserStore from './ActiveUserStore';
import * as systemClient from '../../clients/system';
import { accountsSso, ping, sso } from '../../clients/system';
import Log from '../../utils/Log';
import Store from '../Store';
import { IUser } from 'mm-types';
import axios, { AxiosError } from 'axios';
import NotificationsStore from '../events/NotificationsStore';
import fileReader from '../../utils/fileReader';
import * as clients from '../../clients/base-clients';
import appNavigator from '../../utils/AppNavigator';
import QueryUtil from '../../utils/QueryUtil';
import DocTeamStore from '../settings/DocTeamStore';
import { customConfigProvider } from '../../utils';
import UserEventStore from '../events/UserEventStore';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isFeatureOn } from '../../components/featureSwitch/featureSwitchUtils';
import clientInterceptor from '../../clients/base-clients-interceptors';

const _COMMS_POLLINTERVAL = 60; // seconds

export type State = {
  user: IUser | null;
  isOTP?: boolean;
  isCaptcha?: boolean;
};

export type SystemStoreEvent = {
  initialized?: boolean;
  permissionChangeError?: boolean;
  scriptError?: boolean;
  scriptErrorMsg: string;
  systemError?: boolean;
  connectivityError?: boolean;
  state?: State;
  // xhr?: JQueryXHR;
  errorResponse?: AxiosError | Error;
  connectivityDetails: {
    retryRef?: () => void;
    reconnectionSuccess: null | boolean;
    secondsUntilRetry: number;
  };
};

const AUTH_ERRORS = [401, 403];

export class SystemStore extends Store<State> {
  private _comms_currentSeconds: number;
  private _commsPollTimer: number | null;
  private _sso: IUser | null;
  private _isNetworkDisconnected: boolean;
  private _isGoingToCommsErrorState: boolean;
  private _hasScriptErrorState: boolean;
  private _hasSystemErrorState: boolean;
  private _tempDisableExceptionHandling: boolean;

  constructor() {
    super();
    this._comms_currentSeconds = 1;
    this._commsPollTimer = null;
    this._sso = null;
    this._isNetworkDisconnected = false;
    this._isGoingToCommsErrorState = false;
    this._hasScriptErrorState = false;
    this._tempDisableExceptionHandling = false;
    this.state = {
      user: null
    };
  }

  getInitialState() {
    return this.state;
  }

  private _redirect(authToken) {
    const expDate = new Date(new Date().getTime() + 86400000);
    document.cookie = 'userUid=' + authToken.userUid + '; expires=' + expDate + '; path=/';
    (window as any).location = authToken.redirectLocation;
  }

  _triggerSystemError(error?: AxiosError | Error) {
    this._hasSystemErrorState = true;
    this.trigger({ systemError: true, errorResponse: error } as SystemStoreEvent);
  }

  private _triggerConnectivityError() {
    this._isGoingToCommsErrorState = true;

    Log.debug('Connectivity error occurred: begin reconnection cycle...');

    if (this._commsPollTimer === null) {
      const retry = () => {
        this._comms_currentSeconds = _COMMS_POLLINTERVAL;
      };

      this._commsPollTimer = window.setInterval(async () => {
        this._comms_currentSeconds++;
        this.trigger({
          connectivityError: true,
          connectivityDetails: {
            retryRef: retry,
            reconnectionSuccess: null,
            secondsUntilRetry: _COMMS_POLLINTERVAL - this._comms_currentSeconds
          }
        } as SystemStoreEvent);

        if (this._comms_currentSeconds >= _COMMS_POLLINTERVAL) {
          this._comms_currentSeconds = 0;
          try {
            await ping();

            // shutdown timer
            if (this._commsPollTimer !== null) {
              clearInterval(this._commsPollTimer);
              this._commsPollTimer = null;
            }

            // reconnect
            this.trigger({
              connectivityError: true,
              connectivityDetails: { reconnectionSuccess: true }
            } as SystemStoreEvent);

            console.log('Server ping: succeeded... reconnecting');
            location.reload();
          } catch {
            this.trigger({
              connectivityError: true,
              connectivityDetails: {
                reconnectionSuccess: false
              }
            } as SystemStoreEvent);
            console.log('Server ping: failed...');
          }
        }
      }, 1000);
    }

    this.trigger({ connectivityError: true } as SystemStoreEvent);
  }

  private async handleAxiosError(error: AxiosError) {
    if (error.response) {
      console.error(error);

      // If we have a 500 error show a system error
      if (error.response.status >= 500 && error.response.status < 600) {
        this._triggerSystemError(error);
      }
      // Handle redirect
      else if (error.response.status === 307 || error.response.status === 302) {
        this._redirect(JSON.parse(error.response.statusText));
      }
      // Handle lack of authorization
      else if (error.response.status === 401) {
        appNavigator.goTo.loginWithRedirectBack();
      }

      if (error.response.config.responseType === 'blob') {
        const { data } = error.response!;
        const file = await fileReader(data);
        const response = JSON.parse(file);
        error.response.data = response;
      }
    }

    if (axios.isCancel(error)) {
      console.debug('Request cancelled| ', error.message ?? '');
    }
    return Promise.reject(error);
  }

  private _initAPIErrorListener() {
    clientInterceptor.gebHelper(axios);
    clients.mm.interceptors.response.use(
      (response) => response,
      (error) => this.handleAxiosError(error)
    );
    clients.accounts.interceptors.response.use(
      (response) => response,
      (error) => this.handleAxiosError(error)
    );
    clients.aerosync.interceptors.response.use(
      (response) => response,
      (error) => this.handleAxiosError(error)
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => this.handleAxiosError(error)
    );
  }

  private _initNetConnectivityListener() {
    $(window)
      .on('online', () => {
        if (this._isNetworkDisconnected) {
          // if we were in an offline state
          location.reload();
        }
      })
      .on('offline', () => {
        this._isNetworkDisconnected = true;
        this._triggerConnectivityError();
      });
  }

  private _setupSentry(history: History) {
    Sentry.init({
      dsn: isFeatureOn('sentry') ? process.env.SENTRY_DSN : undefined,
      release: `manual-manager-ui:${process.env.BUILD_VERSION || 'development'}`,
      environment: ServerSettingsStore.getServerSettings().siteUrl?.replace(/http[s]?:\/\//, '') || process.env.NODE_ENV,
      integrations: [
        new Integrations.BrowserTracing({
          idleTimeout: 10000,
          routingInstrumentation: Sentry.reactRouterV4Instrumentation(history)
        })
      ],
      tracesSampleRate: 0.2,
      normalizeDepth: 10,
      beforeSend(event, hint) {
        const error = hint.originalException as AxiosError;
        if (error?.response?.status && error.response.status === 401) {
          return null;
        } else {
          return event;
        }
      }
    });
  }

  private _setupHTTP() {
    this._initAPIErrorListener();
    this._initNetConnectivityListener();
  }

  private _setupExceptionLogger() {
    // when developing, and u want to see script errors in the console regardless of env settings, in console: localStorage.exposeScriptErrors = true;
    if (window.localStorage && localStorage.getItem('exposeScriptErrors') === 'true') {
      return;
    }

    // store *all* script errors on the weblog
    window.onerror = (errorMsg, url, lineNumber, colNumber, error) => {
      // AER-5037 - not sure where ResizeObserver is being used. It looks like it may
      // from babel - but cant confirm it. The error is supposedly benign:
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      // So we might need to come back to this
      if (errorMsg === 'ResizeObserver loop limit exceeded') {
        return;
      }

      if (!this._hasScriptErrorState && !this._tempDisableExceptionHandling) {
        // don't trigger error again if its already being displayed or if disabled

        Log.error(
          'Client Runtime Error: ' +
            errorMsg +
            ' Script: ' +
            url +
            ' Line: ' +
            lineNumber +
            ' Col: ' +
            lineNumber +
            ' Stacktrace:' +
            (error ? error.stack : 'no stacktrace available')
        );

        try {
          systemClient.saveLogs(Log.getPersisted());
          Log.clearPersisted();

          // Don't throw any errors if script error is from tinymce
          if (!url?.includes('assets/js/tinymce')) {
            this._hasScriptErrorState = true;
            this.trigger({ scriptError: true, scriptErrorMsg: errorMsg } as SystemStoreEvent);
          }
        } catch (err) {
          // for certain script errors, the very act of triggering SystemStore.trigger may cause another script error, in this case just redir to root of app and hope for best
          if (errorMsg !== 'ResizeObserver loop limit exceeded') {
            // ignore that msg as it is a chrome problem not our app https://github.com/WICG/ResizeObserver/issues/38
            location.href = '/';
          }
        }
      }
    };
  }

  // in rare scenarios when dealing with 3rd party code there is no way to avoid a
  // script error (which may not be damaging for the user),
  // in this case you can disable temporarily - but ensure to re-enable immediately after
  disableScriptExceptionHandling(disable) {
    this._tempDisableExceptionHandling = disable;
  }

  // //////////////////////////////////////////////////////////////////////////////////
  // Action event handlers

  async initApp(history) {
    await this.serverSettingsSetup();
    this._setupSentry(history);
    this._setupHTTP();
    this._setupExceptionLogger();
    this.fetchUserInfoFromSSO();
  }

  getCurrentUserUid() {
    return this._sso!.uid;
  }

  async serverSettingsSetup() {
    try {
      await ServerSettingsStore.fetchServerSettings();
    } catch (e) {
      this.handleAxiosError(e as AxiosError);
    }
  }

  async fetchUserInfoFromSSO() {
    if (QueryUtil.getParam('logout', true)) {
      appNavigator.goTo.logout();
    }

    try {
      const accountsSsoCall = accountsSso();
      const ssoCall = sso();
      const accountModel = await accountsSsoCall;
      customConfigProvider.load(accountModel.airlineUid);
      const userModel = await ssoCall;
      this._sso = userModel;

      if (userModel.redirectLocation && userModel.redirectLocation !== '') {
        appNavigator.redirect(userModel.redirectLocation);
      }

      DocTeamStore.fetchRoles(userModel);
      await ActiveUserStore.persistActiveUserAndGetTeamspaces(userModel);
      EventClient.initClient(userModel, [UserEventStore.subscriptionConfig]);
      NotificationsStore.init(userModel);
      this.state.user = userModel;
      this.checkPeriodicallySso();
      this.trigger({ initialized: true, state: this.state } as SystemStoreEvent);
    } catch (error) {
      this.handleSsoFailure(error);
    }
  }

  private checkPeriodicallySso() {
    setInterval(() => sso().catch((error) => this.handleSsoFailure(error)), 1000 * 60 * 5);
  }

  private handleSsoFailure(error: AxiosError) {
    if (error.response && error.response.status && AUTH_ERRORS.indexOf(error.response.status) > -1) {
      appNavigator.goTo.loginWithRedirectBack();
    } else {
      appNavigator.goTo.login({ error: 'sso', status: `${error.response?.status ?? 500}` });
    }
  }

  showSystemError(err?: AxiosError | Error) {
    if (!this._isGoingToCommsErrorState) {
      this._triggerSystemError(err);
    }
  }

  // //////////////////////////////////////////////////////////////////////////////////
  // public interface

  isBackendDisconnected() {
    return this._isGoingToCommsErrorState;
  }

  isPendingErrorReload() {
    return this._hasScriptErrorState || this._isGoingToCommsErrorState || this._hasSystemErrorState;
  }

  getSSO() {
    return this._sso;
  }
}

const singleton = Reflux.initStore<SystemStore>(SystemStore);
export default singleton;
