import { EditorMode } from './EditorMode';
import RevisionStore from '../../RevisionStore';
import { GetOptions } from '../../../../clients/units';
import { Banner, EditorModes, FileExportType, MenuTypes, ModeAttributes, RefreshTypes } from '../../EditorModes';
import { SynchronizationQueue } from './SynchronizationQueue';
import { VisualStates } from '../../../../components/editor/utils/DocumentVisualStates';

export class DiffMode extends EditorMode {
  private disabledFileExportTypes: Set<FileExportType> = new Set();

  constructor(attributes: ModeAttributes, syncQueue: SynchronizationQueue) {
    super(attributes.submenuTabs | attributes.mainmenuTabs, syncQueue);
  }

  getBanner(): Banner {
    if (RevisionStore.getRevisions() && RevisionStore.getRevisions()![0]) {
      return {
        title: 'Diffing Mode: Current Draft / Revision ' + RevisionStore.getRevisions()?.[0]?.revision,
        className: 'diff dark'
      };
    } else {
      return {
        title: 'Diffing Mode',
        className: 'diff dark'
      };
    }
  }

  getActivatedMenuTabSelections() {
    return { main: 'toc', sub: 'edit' };
  }

  getRetrieveUnitParams() {
    const diffIndexUid = RevisionStore.getDiffIndexUid();
    const diffTimestamp: string | undefined = RevisionStore.getDiffTimestampFormatted();

    return {
      diff: true,
      diffIndexUid: diffIndexUid,
      diffTimestamp: diffTimestamp
    } as Partial<GetOptions>;
  }

  getDisabledMenuItems() {
    return ['DOC_SETTINGS', 'PREVIEW', 'WORKFLOW'] as MenuTypes[];
  }

  isMenuItemAllowed(menuItemAttr: MenuTypes) {
    return this.getDisabledMenuItems().indexOf(menuItemAttr) <= -1;
  }

  setFileExportTypeAllowed(exportType: FileExportType, allowed: boolean) {
    if (allowed) {
      this.disabledFileExportTypes.delete(exportType);
    } else {
      this.disabledFileExportTypes.add(exportType);
    }
  }

  isFileExportTypeAllowed(exportType: FileExportType) {
    return !this.disabledFileExportTypes.has(exportType);
  }

  activateTransition(previousMode: EditorModes): RefreshTypes {
    return 'RELOAD_TOC';
  }

  deactivateTransition(nextMode: EditorModes): RefreshTypes {
    return 'RELOAD_TOC';
  }

  overrideVisualStates() {
    return [{ state: 'SHOW_DELETED' as VisualStates, value: true }];
  }

  queueSynchronization(syncData) {
    return this.syncQueue.queue(syncData);
  }
}
