import { IWorkflowOptions } from 'mm-types';

export const FORMAT_OPTIONS: IWorkflowOptions[] = [
  { text: 'Volume', payload: 'volume' },
  { text: 'Chapter', payload: 'chapter' },
  { text: 'Section', payload: 'section' },
  { text: 'H1 - Heading 1', payload: 'level1' },
  { text: 'H2 - Heading 2', payload: 'level2' },
  { text: 'H3 - Heading 3', payload: 'level3' },
  { text: 'H4 - Heading 4', payload: 'level4' },
  { text: 'H5 - Heading 5', payload: 'level5' },
  { text: 'H6 - Heading 6', payload: 'level6' }
];

export const UNIT_LEVELS: IWorkflowOptions[] = [{ text: 'N/A', payload: 'none' }].concat(FORMAT_OPTIONS);
