import * as React from 'react';
import { IComment } from 'mm-types';

type Props = {
  onClick?: (e: any) => void;
  isThreadVisible: boolean;
  comment?: IComment;
};

function handleUnreadReplies(replies?: IComment[]) {
  let unreadReply = false;
  replies!.forEach((comment) => (comment.unread ? (unreadReply = true) : null));
  return unreadReply ? 'unreadReply' : '';
}

const ReplyCount = (props: Props = { isThreadVisible: false }) => {
  const hasReplies = props.comment && props.comment.replies;

  return (
    <span className="reply-count-component" onClick={(e) => (props.onClick ? props.onClick(e) : '')}>
      {!props.isThreadVisible && (
        <span className={`${hasReplies ? handleUnreadReplies(props.comment!.replies) : ''}`}>
          {props.comment!.replies.length} {props.comment!.replies.length === 1 ? ' Reply' : ' Replies'}
        </span>
      )}
      {props.isThreadVisible && <span>Hide replies</span>}
    </span>
  );
};

export default ReplyCount;
