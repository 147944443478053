import * as _ from 'lodash';

function fromObject(props: { [prop: string]: boolean } = {}): string {
  return _.reduce(props, (result, value, key) => (result += value ? ` ${key}` : ''), '');
}

function merge(baseClassName = '', props: { [prop: string]: boolean }): string {
  return baseClassName + fromObject(props);
}

const classNameHelper = {
  merge,
  fromObject
};

export default classNameHelper;
