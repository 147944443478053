import * as React from 'react';
import { IMedia } from 'mm-types';
import ElementPan from 'react-element-pan';
import { getThumbUrl } from '../MediaLibContent/MediaThumb';

export interface Props {
  scale: number;
  media: IMedia;
}

interface State {
  imgLoaded: boolean;
}
export default class ImagePan extends React.Component<Props, State> {
  private panRef = React.createRef<any>();
  private currentScrollLeft: number;
  private currentScrollTop: number;
  private imgProps: {
    originalWidth: number;
    originalHeight: number;
    initialHeight: number;
    height: number;
    containerHeight: number;
    containerWidth: number;
    marginTop: number;
    width: number;
    initialWidth: number;
    ratio: number;
    thumb: string;
  };

  constructor(props) {
    super(props);
    this.onPanMove = this.onPanMove.bind(this);
    this.onLoadEnd = this.onLoadEnd.bind(this);
    this.state = {
      imgLoaded: false
    };
  }

  setScroll(zoomIn = true) {
    const elementPanEl: any = this.panRef.current.el;

    const elementPanHalfWidth = elementPanEl.clientWidth / 2;
    const elementPanHalfHeight = elementPanEl.clientHeight / 2;
    const currentWidth = this.imgProps.width;
    const currentHeight = this.imgProps.height;
    const currentScrollLeft = this.currentScrollLeft || 0;
    const currentScrollTop = this.currentScrollTop || 0;

    if (zoomIn) {
      if (this.verticalScrollbarWillAppear(currentHeight, elementPanEl.clientHeight, this.props.scale)) {
        elementPanEl.scrollTop = (currentHeight - elementPanEl.clientHeight) / 2;
      } else {
        elementPanEl.scrollTop = this.getScrollTopPosition(currentHeight, this.props.scale - 1, elementPanHalfHeight, currentScrollTop);
      }
      if (this.horizontalScrollbarWillAppear(currentWidth, elementPanEl.clientWidth, this.props.scale)) {
        elementPanEl.scrollLeft = (currentWidth - elementPanEl.clientWidth) / 2;
      } else {
        elementPanEl.scrollLeft = this.getScrollLeftPosition(currentWidth, this.props.scale - 1, elementPanHalfWidth, currentScrollLeft);
      }
    } else {
      if (this.props.scale === 1) {
        // for scale == 1 we dont have any scrollbars
        elementPanEl.scrollTop = 0;
        elementPanEl.scrollLeft = 0;
      } else {
        elementPanEl.scrollTop = this.getScrollTopPosition(currentHeight, this.props.scale + 1, elementPanHalfHeight, currentScrollTop);
        elementPanEl.scrollLeft = this.getScrollLeftPosition(currentWidth, this.props.scale + 1, elementPanHalfWidth, currentScrollLeft);
      }
    }
    this.setNewScrollValues(elementPanEl.scrollTop, elementPanEl.scrollLeft);
  }

  private horizontalScrollbarWillAppear(currentWidth: number, containerWidth: number, scale: number): boolean {
    return this.getImageWidthInScale(scale - 1) <= containerWidth && currentWidth > containerWidth;
  }

  private verticalScrollbarWillAppear(currentHeight: number, containerHeight: number, scale: number): boolean {
    return this.getImageHeightInScale(scale - 1) <= containerHeight && currentHeight > containerHeight;
  }

  private getScrollLeftPosition(currentWidth: number, scale: number, elementPanHalfWidth: number, currentScrollLeft: number): number {
    return currentWidth / (this.getImageWidthInScale(scale) / (elementPanHalfWidth + currentScrollLeft)) - elementPanHalfWidth;
  }

  private getScrollTopPosition(currentHeight: number, scale: number, elementPanHalfHeight: number, currentScrollTop: number): number {
    return currentHeight / (this.getImageHeightInScale(scale) / (elementPanHalfHeight + currentScrollTop)) - elementPanHalfHeight;
  }

  private setNewScrollValues(top: number, left: number) {
    this.currentScrollTop = top;
    this.currentScrollLeft = left;
  }

  private getImageStyles(): React.CSSProperties {
    if (!this.panRef || !this.panRef.current || !this.panRef.current.el) {
      return {};
    }
    if (!this.imgProps || this.imgProps.height === 0) {
      this.imgProps = this.getInitialImgProps();
    }
    this.updateImgProps();
    return {
      height: this.imgProps.height,
      width: this.imgProps.width,
      marginTop: this.imgProps.marginTop,
      backgroundImage: this.imgProps.thumb
    };
  }

  private updateImgProps() {
    this.imgProps.height = this.imgProps.initialHeight * this.props.scale;
    this.imgProps.width = this.imgProps.initialWidth * this.props.scale;
    this.imgProps.marginTop = this.getImageMarginTopValue();
  }

  private getInitialImgProps() {
    const originalWidth = this.props.media.metadata.width;
    const originalHeight = this.props.media.metadata.height;
    const containerHeight = this.panRef.current.el.clientHeight;
    const containerWidth = this.panRef.current.el.clientWidth;
    const backgroundImage = `url(${getThumbUrl(this.props.media)})`;
    let height: number;
    let width: number;
    let ratio: number;
    // get highest ratio and scale current image. Also set initial width/height
    if (originalHeight >= containerHeight || originalWidth >= containerWidth) {
      const ratioHeight = originalHeight / (originalHeight < containerHeight ? originalHeight : containerHeight);
      const ratioWidth = originalWidth / (originalWidth < containerWidth ? originalWidth : containerWidth);
      ratio = ratioHeight >= ratioWidth ? ratioHeight : ratioWidth;
      height = originalHeight / ratio;
      width = originalWidth / ratio;
    } else {
      height = originalHeight;
      width = originalWidth;
      ratio = 1;
    }

    return {
      originalWidth,
      originalHeight,
      ratio,
      height,
      initialHeight: height,
      width,
      initialWidth: width,
      containerHeight,
      containerWidth,
      marginTop: height < containerHeight ? (containerHeight - height) / 2 : 0,
      thumb: backgroundImage
    };
  }

  private getImageMarginTopValue(): number {
    return this.imgProps.height < this.imgProps.containerHeight ? (this.imgProps.containerHeight - this.imgProps.height) / 2 : 0;
  }

  private getImageWidthInScale(scale: number) {
    return this.imgProps.initialWidth * scale;
  }
  private getImageHeightInScale(scale: number) {
    return this.imgProps.initialHeight * scale;
  }

  private onPanMove(coords: { x: number; y: number }) {
    this.currentScrollLeft = coords.x;
    this.currentScrollTop = coords.y;
  }

  private onLoadEnd() {
    this.setState({
      imgLoaded: true
    });
  }

  render() {
    return (
      // @ts-ignore
      <ElementPan className="medialib-element-pan" onPan={this.onPanMove} ref={this.panRef}>
        <img
          src={this.props.media.location}
          style={this.getImageStyles()}
          className={this.state.imgLoaded ? 'loaded' : 'loading'}
          onLoad={this.onLoadEnd}
          alt=""
        />
      </ElementPan>
    );
  }
}
