import { IDocUnitProfile } from 'mm-types';
import ActionEditProps from '../../../../sidetabs/sub/editComponent/components/ActionEditProps';
import { multiPartiteMenuOverrides, multiPartiteMenuSelectedOverrides } from '../../const/profileMenuOverrides';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'MultiPartite',
  getLabel: () => 'MULTI',
  identifyingClasses: ['arc-multipartite'], // TODO
  outerClass: 'arc-multipartite',
  openEditPaneOnSelect: true,
  // editPropsComponent: NoProperties, // TODO probably need an extended version of the current ActionEditProps
  editPropsComponent: ActionEditProps,
  menu: multiPartiteMenuOverrides,
  menuTextSelected: multiPartiteMenuSelectedOverrides,

  arcMLDefaults: {
    root: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 },
    nested: { top: 0, right: 0, bottom: 0, left: 0, ordinal: 0 }
  },

  editor: {
    plugins: [],
    forced_root_block: ''
    // TODO
    // valid_elements: "style,span[class|style],div[class|data-action-type|data-action-format],bold,strong,sup,sub,b,ins,a[class|href]",
  }
};
