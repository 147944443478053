import * as React from 'react';
import { TextField } from 'material-ui';

type MetaDataInputProps = { value: string; title: string; isDisabled?: boolean };

const MetaDataInput = ({ value, title, isDisabled }: MetaDataInputProps) => {
  const baseStyles = { width: '25px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
  return (
    <div className="dimension column" key={'arc-textfield_' + title}>
      <h6>{title}</h6>
      <TextField
        disabled={isDisabled}
        id={'arc-textfield_' + title}
        style={Object.assign({}, baseStyles, { float: 'right', width: '100px' }) as React.CSSProperties}
        inputStyle={
          Object.assign({}, baseStyles, {
            paddingLeft: '0px',
            textAlign: 'right',
            width: '100px'
          }) as React.CSSProperties
        }
        underlineStyle={Object.assign({}, { marginBottom: '-10px' }) as React.CSSProperties}
        value={value}
      />
    </div>
  );
};

export default MetaDataInput;
