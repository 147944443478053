import * as React from 'react';
import { useNNCIndexMetadata } from '../useNNCIndexMetadata';
import { IUnit, ITocNode } from 'mm-types';
import { MetadataValue, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { TitleMetadata } from './TitleMetatada';
import { LightsMetadata } from './LightsMetadata';
import { SymbolMetadata } from './SymbolMetatada';
import { EffectivityMetadata } from './EffectivityMetadata/EffectivityMetadata';
import { ForeignEntryMetadata } from './ForeignEntryMetadata/ForeignEntryMetadata';
import { withToggleSection } from '../../../../../misc/ToggleSection';
import { TextField, IconButton, RadioButtonGroup } from 'material-ui';
import { isEscKey, isSaveKeys } from '../../../keyIdentifier';
import useFocusLoop from './useFocusLoop';
import { useNNCTocEntries } from '../useNNCTocEntries';
import { AltTitlesMetadata } from './AltTitlesMetadata';

const TitleMetadataSection = withToggleSection(TitleMetadata);
const AltTitlesMetadataSection = withToggleSection(AltTitlesMetadata);
const LightsMetadataSection = withToggleSection(LightsMetadata);
const SymbolMetadataSection = withToggleSection(SymbolMetadata);
const EffectivityMetadataSection = withToggleSection(EffectivityMetadata);
const ForeignEntryMetadataSection = withToggleSection(ForeignEntryMetadata);

interface Props {
  unit: IUnit;
  onToggle(isOpen: boolean, ref: React.RefObject<HTMLDivElement>);
}
const TITLE = NNCIndexMetadataID.TITLE;
const ALT_TITLES = NNCIndexMetadataID.ALT_TITLES;
const LIGHTS = NNCIndexMetadataID.LIGHTS;
const SYMBOL = NNCIndexMetadataID.SYMBOL;
const EFFECTIVITY = NNCIndexMetadataID.EFFECTIVITY;
const FOREIGN_ENTRY = NNCIndexMetadataID.FOREIGN_ENTRY;

const NNCIndexMetadataContainer = React.memo(({ unit, onToggle }: Props) => {
  const { metadata, update, cancel, save } = useNNCIndexMetadata(unit);
  const titleRef = React.useRef<TextField>(null);
  const altTitleRef = React.useRef<TextField>(null);
  const lightRef = React.useRef<IconButton>(null)!;
  const effectivityRef = React.useRef<IconButton>(null);
  const foreignEntryRef = React.useRef<IconButton>(null);
  const symbolRef = React.useRef<RadioButtonGroup>(null);

  function updateState<T extends NNCIndexMetadataID>(id: T, data: MetadataValue<T>) {
    update({ [id]: data });
  }
  const nncTocEntries: ITocNode[] = useNNCTocEntries(unit);
  const { focusNext, setOpen, isOpen, setDisabled } = useFocusLoop([
    { id: TITLE, ref: titleRef, open: true },
    { id: ALT_TITLES, ref: altTitleRef },
    { id: LIGHTS, ref: lightRef },
    { id: SYMBOL, ref: symbolRef },
    { id: EFFECTIVITY, ref: effectivityRef },
    { id: FOREIGN_ENTRY, ref: foreignEntryRef }
  ]);

  const onToggled = (id: NNCIndexMetadataID, isOpen: boolean, ref: React.RefObject<HTMLDivElement>) => {
    setOpen(id, isOpen);
    onToggle(isOpen, ref);
  };

  React.useEffect(() => {
    setDisabled(FOREIGN_ENTRY, !nncTocEntries.length);
  }, [nncTocEntries.length]);

  function onKeyDown(e) {
    if (isEscKey(e)) {
      cancel();
    }
    if (isSaveKeys(e)) {
      save();
    }
  }

  return (
    <div id="nnc-index-metadata-container" onKeyDown={onKeyDown}>
      <TitleMetadataSection
        title="Title"
        id="title-metadata-section"
        values={metadata[TITLE]}
        onChange={(data) => updateState(TITLE, data)}
        defaultOpen={true}
        forceOpen={isOpen(TITLE)}
        onTab={(e, shift) => focusNext(TITLE, e, shift)}
        currentRef={titleRef}
        onToggled={(open, ref) => onToggled(TITLE, open, ref)}
      />
      <AltTitlesMetadataSection
        title="Alternative Title"
        id="alt-title-metadata-section"
        values={metadata[ALT_TITLES]}
        onChange={(data) => updateState(ALT_TITLES, data)}
        defaultOpen={false}
        forceOpen={isOpen(ALT_TITLES)}
        onTab={(e, shift) => focusNext(ALT_TITLES, e, shift)}
        currentRef={altTitleRef}
        onToggled={(open, ref) => onToggled(ALT_TITLES, open, ref)}
      />
      <LightsMetadataSection
        title="Lights Index Entries"
        id="lights-metadata-section"
        values={metadata[LIGHTS]}
        onChange={(data) => updateState(LIGHTS, data)}
        defaultOpen={false}
        forceOpen={isOpen(LIGHTS)}
        onTab={(e, shift) => focusNext(LIGHTS, e, shift)}
        currentRef={lightRef}
        onToggled={(open, ref) => onToggled(LIGHTS, open, ref)}
      />
      <SymbolMetadataSection
        title="Symbols"
        id="symbol-metadata-section"
        values={metadata[SYMBOL]}
        onChange={(data) => updateState(SYMBOL, data)}
        defaultOpen={false}
        forceOpen={isOpen(SYMBOL)}
        onTab={(e, shift) => focusNext(SYMBOL, e, shift)}
        currentRef={symbolRef}
        onToggled={(open, ref) => onToggled(SYMBOL, open, ref)}
      />
      <EffectivityMetadataSection
        title="Effectivity"
        id="effectivity-metadata-section"
        values={metadata[EFFECTIVITY]}
        contentContainerStyle={{ paddingLeft: 13, paddingRight: 1 }}
        onChange={(data) => updateState(EFFECTIVITY, data)}
        defaultOpen={false}
        forceOpen={isOpen(EFFECTIVITY)}
        onTab={(e, shift) => focusNext(EFFECTIVITY, e, shift)}
        currentRef={effectivityRef}
        onToggled={(open, ref) => onToggled(EFFECTIVITY, open, ref)}
      />
      <ForeignEntryMetadataSection
        title="Other Sections Index"
        id="foreign-entry-metadata-section"
        values={metadata[FOREIGN_ENTRY]}
        onChange={(data) => updateState(FOREIGN_ENTRY, data)}
        defaultOpen={false}
        forceOpen={isOpen(FOREIGN_ENTRY)}
        onTab={(e, shift) => focusNext(FOREIGN_ENTRY, e, shift)}
        currentRef={foreignEntryRef}
        onToggled={(open, ref) => onToggled(FOREIGN_ENTRY, open, ref)}
        unit={unit}
      />
    </div>
  );
});

export { NNCIndexMetadataContainer };
