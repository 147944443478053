import React from 'react';
import LinkDisplayInput from './LinkDisplayInput';
import { LinkModel } from './linkService';

export type Props = { model: Partial<LinkModel>; onUpdateModel: (partial: Partial<LinkModel>) => void };

const DuRefLinkOptions = ({ model, onUpdateModel }: Props) => {
  return (
    <div data-qa="du-ref-link-options">
      <div className="input">
        <LinkDisplayInput
          name={'Role'}
          value={model.duRefRole ?? ''}
          readOnly={false}
          showValidation={false}
          onChange={(text) => onUpdateModel({ duRefRole: text })}
          dataQa="du-ref-link-role-input"
        />
      </div>
    </div>
  );
};

export default DuRefLinkOptions;
