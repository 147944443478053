import * as React from 'react';

interface Props {
  fromHeight?: number;
  open?: boolean;
  duration?: number;
  contentStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

interface State {
  height: number | undefined;
  open: boolean;
  shouldRender: boolean;
  animationStarted: boolean;
  childChange: boolean;
  duration: number;
}

const DEFAULT_TRANSITION_DURATION = 150;

function getStyles(props: Props, state: State): React.CSSProperties {
  const styles: React.CSSProperties = {
    transition: state.childChange ? 'none' : `height ${state.duration}ms ease-in-out, opacity ${state.duration * 2}ms ease-in-out`
  };
  if (state.open) {
    return {
      ...styles,
      height: state.height,
      overflow: state.animationStarted ? 'hidden' : undefined,
      opacity: 1
    };
  }
  return {
    ...styles,
    overflow: 'hidden',
    height: 0,
    visibility: 'hidden',
    opacity: 0
  };
}

export default class VerticalSlide extends React.Component<Props, State> {
  private childrenRef: React.RefObject<HTMLDivElement>;
  private heightTimeout: number | null;
  private animationEndTimeout: number | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      height: props.fromHeight || 0,
      open: props.open !== undefined ? props.open : true,
      duration: props.duration || DEFAULT_TRANSITION_DURATION,
      shouldRender: props.open !== undefined ? props.open : true,
      animationStarted: props.open !== undefined ? props.open : true,
      childChange: true
    };
  }

  UNSAFE_componentWillMount() {
    this.childrenRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    if (this.props.fromHeight) {
      setTimeout(() => {
        this.openWithAnimation();
      }, 0);
    }
  }

  componentWillUnmount() {
    this.setState({
      shouldRender: false,
      open: false,
      height: 0,
      childChange: false
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.open && !this.props.open) {
      this.closeWithAnimation();
    } else if (!prevProps.open && this.props.open) {
      this.openWithAnimation();
    } else if (this.state.shouldRender) {
      this.recalculateHeight();
    }
  }

  private openWithAnimation() {
    return this.setState(
      {
        open: true,
        shouldRender: true,
        animationStarted: true,
        height: this.getHeight(),
        childChange: false
      },
      () => {
        this.stopAnimation();
      }
    );
  }

  private closeWithAnimation() {
    return this.setState(
      {
        open: false,
        animationStarted: true,
        height: 0,
        childChange: false
      },
      () => {
        this.stopAnimation(false);
      }
    );
  }

  private recalculateHeight() {
    if (this.heightTimeout) {
      clearTimeout(this.heightTimeout);
      this.heightTimeout = null;
    }
    this.heightTimeout = window.setTimeout(() => {
      this.setHeight();
    }, 10);
  }

  private getHeight(): number {
    if (!this.childrenRef.current) {
      return 0;
    }
    return this.childrenRef.current.clientHeight;
  }

  private setHeight() {
    if (this.state.height !== this.getHeight()) {
      this.setState({
        height: this.getHeight(),
        childChange: !this.state.animationStarted && this.state.childChange !== undefined
      });
    }
  }

  private stopAnimation(shouldRender = true) {
    if (!this.state.animationStarted) {
      return false;
    }
    if (this.animationEndTimeout) {
      clearTimeout(this.animationEndTimeout);
      this.animationEndTimeout = null;
    }

    this.animationEndTimeout = window.setTimeout(() => {
      this.setState({
        shouldRender,
        animationStarted: false,
        childChange: false
      });
    }, this.state.duration);
  }

  render() {
    const styles = getStyles(this.props, this.state);
    return (
      <div className="vertical-slide-container position-relative">
        <div style={styles}>
          <div ref={this.childrenRef}>
            <div style={this.props.contentStyle || undefined}>{this.state.shouldRender ? this.props.children : null}</div>
          </div>
        </div>
      </div>
    );
  }
}
