import { mm, Cancelled, ClientError, exemptStatusCodes } from './base-clients';
import { LookupTypes, ILookup, IChangeTaskReason, ITask, IUser, TaskAuthor, IActionTaskEntry, ITaskImportResponse } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import userUtil from '../components/documents/team/util/user';
import { dateUtil } from '../utils';

let reasonsSource: CancelTokenSource | null = null;

export type GetActivityEntries = {
  taskUid: string[];
  pageSize: number;
  pageNumber: number;
};

export const taskStates = {
  userTaskStates: {
    INCOMPLETE: 'Not Started',
    IN_PROGRESS: 'In Progress',
    NOT_STARTED: 'Not Started',
    COMPLETE: 'Complete',
    UNASSIGNED: 'Unassigned'
  },
  reviewerDispositionStates: {
    NONE: 'None',
    OK: 'Ok',
    NOT_OK: 'Not Ok',
    OK_WITH_PENDING: 'Ok pending',
    NOT_OK_WITH_PENDING: 'Not Ok pending'
  },
  riskAssessmentStates: {
    NOT_APPLICABLE: 'Not Applicable',
    INCOMPLETE: 'Incomplete',
    COMPLETE: 'Complete'
  },
  safetyParamStates: {
    'Non-Safety': 'Non Safety',
    Safety: 'Safety'
  }
};

export async function saveAutoTasks(projectUid: string, indexUid: string, taskUids: string[]) {
  await mm.put(`/projects/${projectUid}/indexes/${indexUid}/usersettings/autoTasks`, { taskUids: taskUids });
}

export async function getAllAutoTasks(projectUid: string, indexUid: string) {
  const response = await mm.get<{ taskUids: string[] }>(`/projects/${projectUid}/indexes/${indexUid}/usersettings/autoTasks`);
  return response.data;
}

export async function getUnitMapping(indexUid: string) {
  const response = await mm.get<{ unitTaskMap: { [name: string]: string[] } }>(`/tasks/indexes/${indexUid}/unitTagMap`);
  return response.data.unitTaskMap;
}

export async function addUnitTasks(unitUid: string, indexUid: string, taskUids: string[]) {
  const query: string[] = taskUids.map((uid) => `taskUid=${uid}`);
  await mm.post(`/tasks/indexes/${indexUid}/units/${unitUid}?${query.join('&')}`);
}

export async function removeUnitTasks(unitUid: string, indexUid: string, taskUids: string[]) {
  const query: string[] = taskUids.map((uid) => `taskUid=${uid}`);
  await mm.delete(`/tasks/indexes/${indexUid}/units/${unitUid}?${query.join('&')}`);
}

export async function getAllActivityEntries(indexUid: string, options: Partial<GetActivityEntries>) {
  const queries: string[] = [];
  if (options.taskUid) {
    queries.push(options.taskUid.map((t) => 'taskUid=' + t).join('&'));
  }
  if (options.pageSize) {
    queries.push('pageSize=' + options.pageSize);
  } else {
    queries.push('pageSize=' + 40);
  }
  if (options.pageNumber) {
    queries.push('pageNumber=' + options.pageNumber);
  }

  const response = await mm.get<{ unitTags: IActionTaskEntry[] }>(`/tasks/indexes/${indexUid}/unitTagList?` + queries.join('&'));

  // flatten out JSON structure
  response.data.unitTags.forEach((unitTag) => {
    unitTag.key = unitTag.unitUid + '_' + unitTag.taskUid;
    unitTag.uid = unitTag.unitUid;
    unitTag.createdTimeFormatted = dateUtil(unitTag.created).formatDateTimeNoSecs();
  });

  return response.data.unitTags;
}

export async function importTasks(file: File, indexUid: string) {
  const formData = new FormData();
  formData.set('indexUid', indexUid);
  formData.set('content', file);

  const response = await mm.post<ITaskImportResponse>(`/tasks/import`, formData);
  return response.data;
}

export async function getAllTasks(indexUid: string) {
  const response = await mm.get<{ tasks: ITask[] }>(`/tasks?indexUid=${indexUid}`);
  const tasks = response.data.tasks || [];

  for (const task of tasks) {
    parseTask(task);
  }

  return tasks;
}

/**
 * Creates a new task
 */
export async function addTask(token: Partial<ITask>) {
  const response = await mm.post<ITask>(`/tasks`, token);
  return response.data;
}

/**
 * Updates a task
 */
export async function updateTask(uid: string, token: Partial<ITask>, patch = false) {
  if (patch) {
    const response = await mm.post<ITask>(`/tasks/${uid}`, token);
    return response.data;
  } else {
    const response = await mm.put<ITask>(`/tasks/${uid}`, token);
    return response.data;
  }
}

/**
 * Removes a task
 */
export async function removeTask(uid: string) {
  const response = await mm.delete(`/tasks/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412])
  });

  if (response.status === 412) {
    throw new ClientError(response.status, 'Task in use');
  }
}

export async function updateTaskParticipant(taskUid: string, userUid: string, token: Partial<TaskAuthor>) {
  await mm.put(`/tasks/${taskUid}/participants/${userUid}`, token);
}

/**
 * Gets a single change task
 */
export async function getOneTask(uid: string) {
  const response = await mm.get<ITask>(`/tasks/${uid}`);
  const task = response.data;
  parseTask(task);
  return task;
}

function parseTask(task: ITask) {
  // eslint-disable-next-line id-blacklist
  task.number = (parseInt(task.number!, 10) < 10 ? '0' : '') + task.number;

  // Split participants into authors and reviewers for the sake of the UX
  task.authors = [];
  task.reviewers = [];

  task.participants.forEach((participant) => {
    participant.user.avatarUrl = userUtil.imagePath(participant.user as IUser);

    if (participant.author) {
      task.authors!.push(participant);
    } else if (participant.reviewer) {
      task.reviewers!.push(participant);
    }
  });

  task.statusSummary = {
    author: { state: task.authorStatus, title: taskStates.userTaskStates[task.authorStatus] },
    reviewer: { state: task.reviewerStatus, title: taskStates.userTaskStates[task.reviewerStatus] },
    final: { state: task.status, title: taskStates.userTaskStates[task.status] },
    reviewerDisposition: {
      state: task.reviewerDisposition,
      title: taskStates.reviewerDispositionStates[task.reviewerDisposition]
    }
  };

  return task;
}

/**
 * Gets all lookups
 */
export async function getAllSafetyLookups(type: LookupTypes) {
  const response = await mm.get<{ lookups: ILookup[] }>(`/lookups/types/${type}`);
  const lookups = response.data.lookups || [];
  return lookups;
}

/**
 * Gets all change task reasons
 */
export async function getAllReasons(): Promise<Cancelled | IChangeTaskReason[]> {
  if (reasonsSource) {
    reasonsSource.cancel();
  }

  reasonsSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ taskReasons: IChangeTaskReason[] }>(`/tasks/reasons`, {
      cancelToken: reasonsSource.token
    });
    const taskReasons = response.data.taskReasons || [];
    return taskReasons;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Removes an workspace by id
 */
export async function removeReason(uid: string) {
  const response = await mm.delete(`/tasks/reasons/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Task Reason in use');
  }
}

/**
 * Creates a new workspace
 */
export async function addReason(token: Partial<IChangeTaskReason>) {
  const response = await mm.post<IChangeTaskReason>(`/tasks/reasons`, token);
  return response.data;
}

/**
 * Updates a given workspace
 */
export async function updateReason(uid: string, token: Partial<IChangeTaskReason>) {
  const response = await mm.put<IChangeTaskReason>(`/tasks/reasons/${uid}`, token, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  if (response.status === 409) {
    throw new ClientError(response.status, 'Task reason already exists');
  }

  return response.data;
}
