import * as React from 'react';
import { IAssignedSearchTerm } from 'mm-types';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';

export type Props = {
  onRemoveTag: (name: IAssignedSearchTerm) => void;
  inRevisionMode: boolean;
  term: IAssignedSearchTerm;
};

export type State = {
  tags: Array<string>;
};

export default class Tag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className={`tag ${this.props.term.editable ? 'editable-tag' : 'readonly-tag'}`}>
        <div className="tag-name">{this.props.term.name}</div>
        {this.props.term.editable ? (
          !this.props.inRevisionMode ? (
            <span className="tag-close" onClick={() => this.props.onRemoveTag(this.props.term)}>
              <CancelIcon style={{ color: 'inherit', height: '18px', width: '18px' }} />
            </span>
          ) : null
        ) : null}
      </div>
    );
  }
}
