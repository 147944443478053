import * as React from 'react';
import MenuItem from './MenuItem';
import { SubpageType } from '../DashboardPage';

interface Props {
  subpage: string;
  onSelectSubpage: (subpage: SubpageType) => void;
}

const Container = (props: Props) => {
  return (
    <div className="dashboard-left-panel">
      <MenuItem
        label="Editor Metrics"
        dataqa="dashboard-left-panel__editor-metrics"
        onSelect={() => props.onSelectSubpage('editor-metrics')}
        icon="icon-editor-metrics"
        selected={props.subpage === 'editor-metrics'}
      />

      <MenuItem
        label="Distributor Reports"
        dataqa="dashboard-left-panel__distributor-reports"
        onSelect={() => props.onSelectSubpage('distribution-reports')}
        icon="icon-reports"
        selected={props.subpage === 'distribution-reports'}
      />
    </div>
  );
};

export default Container;
