import * as React from 'react';
import moment from 'moment';
import { IRecentIndex } from 'mm-types';
import { getWorkflowTasksStageDetails } from '../../dashboard/WorkflowTasksTile/WorkflowTasksStage';
import { dateUtil } from '../../../utils';

export type Props = {
  document: IRecentIndex;
  onDocumentSelect: (doc: IRecentIndex) => void;
};

export type State = {};

export default class Document extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const dueDate = this.props.document.dueDate ? moment(this.props.document.dueDate) : null;
    let dueDateText: string | null;

    if (this.props.document.isPublished) {
      const lastStage = this.props.document.workflowModel.stages?.slice(-1)[0];
      dueDateText = 'Published on ' + dateUtil(lastStage?.completionDate).formatDate();
    } else {
      dueDateText = dueDate ? 'Due Date ' + dateUtil(dueDate).formatDate() + ' (' + dueDate.fromNow() + ')' : null;
    }

    const documentTitle = this.props.document.title + ' - Rev ' + this.props.document.revisionNumber;

    const workspaceType = this.props.document.type === 'PERSONAL' ? 'personal' : 'team';
    const { icon, documentStage } = getWorkflowTasksStageDetails(
      workspaceType,
      this.props.document.isPublished,
      this.props.document.revisionNumber,
      this.props.document.activeStage
    );

    return (
      <div className="doc" onClick={() => this.props.onDocumentSelect(this.props.document)}>
        <div className="doc__row">
          <div className="doc__text">
            <div className="doc__text-main">{documentTitle}</div>
            <div className="doc__text-sub">{dueDateText}</div>
          </div>

          <div className="doc__visual">
            <div className="doc__workflow-stage">
              {icon} <span className="doc__workflow-stage-text">{documentStage}</span>
            </div>
          </div>
        </div>

        <div className="doc__row--sub" />
      </div>
    );
  }
}
