import * as Reflux from 'reflux';
import * as client from '../../clients/change-tasks';
import Store from '../Store';
import { IChangeTaskReason } from 'mm-types';
import { Cancelled, ClientError } from '../../clients/base-clients';

export type State = {
  reasons: IChangeTaskReason[];
};

export type DocSettingsStoreEvent = {
  type: 'changeTaskReasonsRetrieved';
  state: State;
};

export type ChangeTaskReasonStoreEvent = {
  type: 'changeTaskReasonsRetrieved';
  state: State;
};

export class ChangeTaskReasonStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      reasons: []
    };
  }

  getInitialState() {
    return this.state;
  }

  async retrieveChangeTaskReasons() {
    const reasons = await client.getAllReasons();

    if (reasons instanceof Cancelled) {
      return;
    }

    this.state.reasons = reasons;
    this.trigger({ type: 'changeTaskReasonsRetrieved', state: this.state } as ChangeTaskReasonStoreEvent);
  }

  async addChangeTaskReason(reason: IChangeTaskReason) {
    try {
      await client.addReason(reason);
      await this.retrieveChangeTaskReasons();
      return 'Change Task Reason added.';
    } catch (err) {
      await this.retrieveChangeTaskReasons();
      return 'Problem occurred when creating Change Task Reason.';
    }
  }

  async removeChangeTaskReason(reason: IChangeTaskReason) {
    try {
      await client.removeReason(reason.uid);
      await this.retrieveChangeTaskReasons();
      return 'Change Task Reason removed.';
    } catch (err) {
      await this.retrieveChangeTaskReasons();
      if (err instanceof ClientError && err.status === 409) {
        return 'Cannot Delete.Change Task Reason is currently in use';
      } else {
        return 'This system task reason cannot be modified';
      }
    }
  }

  async updateChangeTaskReason(reason: IChangeTaskReason) {
    try {
      await client.updateReason(reason.uid, reason);
      await this.retrieveChangeTaskReasons();
      return 'Change Task Reason updated.';
    } catch (err) {
      await this.retrieveChangeTaskReasons();
      return 'Problem occurred when updating Change Task Reason.';
    }
  }
}

const singleton = Reflux.initStore<ChangeTaskReasonStore>(ChangeTaskReasonStore);
export default singleton;
