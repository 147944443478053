import * as React from 'react';

type Props = {
  type: 'before' | 'after';
  side?: string;
  pageBreakText: string;
};

const PageBreakSettings = (props: Props) => {
  if (props.pageBreakText) {
    return (
      <div className={'page-break-settings ' + props.type}>
        <span>{props.pageBreakText + (props.side ? ` (${props.side})` : '')}</span>
      </div>
    );
  } else {
    return null;
  }
};

export { PageBreakSettings };
