import * as React from 'react';
import { IDocUnitProfile, IUnit } from 'mm-types';
import UnitLoader from '../../../misc/Loaders/UnitLoader';
import { UnitUtils } from '../../utils/units/UnitUtils';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';

export type PropsType = {
  uniqueKey: string;
  docUnit: IUnit;
  isSelected: boolean;
  isNextSelected: boolean;
  isUnitInVisibleRange: boolean;
  children: React.ReactNode;
};

const DocUnitDom = (props: PropsType) => {
  const { docUnit, uniqueKey, isSelected, isNextSelected, isUnitInVisibleRange, children } = props;
  const isDoubleHeight = ['table', 'graphic', 'list', 'step'].indexOf(docUnit.definitionId) > -1;

  if (
    !!docUnit.html &&
    // render if: its already selected (so we don't unmount tiny initialized editing units that scroll away!)
    (isSelected ||
      // render if: a hidden element (removed/ghost) which is ignored by this algo so should be rendered
      !docUnit.isVisibleOnEdit ||
      //   // render if: unit index is inside visible range
      isUnitInVisibleRange)
  ) {
    return (
      <div key={'w' + uniqueKey} style={isNextSelected ? { overflowAnchor: 'none' } : {}}>
        {children}
      </div>
    );
  } else if (
    !docUnit.html &&
    //   // render if: unit index is inside visible range
    isUnitInVisibleRange
  ) {
    const unitProfile:
      | IDocUnitProfile
      | undefined = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(docUnit.definitionId);
    const label: string | null = unitProfile?.getLabel?.(docUnit) ?? null;

    return (
      <div key={'w' + uniqueKey}>
        <div
          className="document-unit-outer"
          style={{ height: docUnit.type === 'removed' ? '0px' : isDoubleHeight ? '200px' : '100px' }}
          key={uniqueKey}
        >
          <div
            className={'document-unit-inner document-unit-inner-' + docUnit.definitionId}
            id={'_' + docUnit.uid}
            style={{ padding: '0' }}
          >
            {label && (
              <div className="du-label" style={{ display: 'block' }}>
                {label}
              </div>
            )}
            <UnitLoader isDoubleHeight={isDoubleHeight} />
          </div>
        </div>
      </div>
    );
  } else if (UnitUtils.isGeneratedUnit(docUnit) || UnitUtils.isVirtualUnitUid(docUnit.uid)) {
    // make the generated units 0px if they are not in visible range
    // once in viisble range they will be loaded normally
    return (
      <div key={'w' + uniqueKey}>
        <div className="document-unit-outer" style={{ visibility: 'hidden', height: '70px' }} key={uniqueKey}>
          <div className={'document-unit-inner document-unit-inner-' + docUnit.definitionId} id={'_' + docUnit.uid}>
            <div data-unit-family={docUnit.type} />
          </div>
        </div>
      </div>
    );
  } else if (!!docUnit.html) {
    return (
      <div key={'w' + uniqueKey}>
        <div
          className="document-unit-outer"
          style={{ visibility: 'hidden', height: docUnit.type === 'removed' ? '0px' : isDoubleHeight ? '200px' : '100px' }}
          key={uniqueKey}
        >
          <div className={'document-unit-inner document-unit-inner-' + docUnit.definitionId} id={'_' + docUnit.uid}>
            <div data-unit-family={docUnit.type} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div key={'w' + uniqueKey}>
        <div
          className="document-unit-outer"
          style={{ height: docUnit.type === 'removed' ? '0px' : isDoubleHeight ? '200px' : '100px' }}
          key={uniqueKey}
        >
          <div className={'document-unit-inner document-unit-inner-' + docUnit.definitionId} id={'_' + docUnit.uid} />
        </div>
      </div>
    );
  }
};

export default DocUnitDom;
