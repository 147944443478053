import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Deleted Element',
  inlineOptions: {
    readonly: true
  },
  template: '',
  nonSelectable: false
};
