import * as _ from 'lodash';
import { IShareDetails } from 'mm-types';

class UnitSharedOriginCreations {
  private _newShareOriginDetails: { [name: string]: IShareDetails | undefined };

  constructor() {
    this._newShareOriginDetails = {};
  }

  clear() {
    this._newShareOriginDetails = {};
  }

  add(unitUid: string, newShareDetails?: IShareDetails) {
    this._newShareOriginDetails[unitUid] = newShareDetails;
  }

  get(unitUid: string) {
    return this._newShareOriginDetails[unitUid];
  }

  remove(unitUid: string) {
    delete this._newShareOriginDetails[unitUid];
  }

  getFirstUnitUid() {
    return _.keys(this._newShareOriginDetails)[0];
  }
}

const exportInstance = new UnitSharedOriginCreations();
export default exportInstance;
