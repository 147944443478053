import * as _ from 'lodash';

export class SynchronizationQueue {
  private _queue: string[];

  constructor() {
    this._queue = [];
  }

  queue(data) {
    // queuing: some day we may do something better than just hard refresh after mode
    this._queue.push(_.cloneDeep(data));
    return true;
  }

  hasItems() {
    return this._queue.length > 0;
  }

  clear() {
    this._queue = [];
  }
}
