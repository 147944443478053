import * as _ from 'lodash';
import StringUtil from './StringUtil';
import IndexLockStatuses from '../flux/editor/IndexLockStatuses';
import { IWorkFlow, IProject, IIndex, IUser, IStage, IWorkflowAssignments } from 'mm-types';
import ActiveUserStore from '../flux/common/ActiveUserStore';

export default {
  // Returns label text for a given Workflow field
  getText(field: string, workflow: IWorkFlow) {
    if (workflow && workflow.activeStage) {
      const activeStage = workflow.activeStage;
      switch (field) {
        case 'currentStageTitle':
          return activeStage ? activeStage.title : '';
        case 'nextStageActionTitle':
          return activeStage.autoStagesSkip
            ? workflow.stages![activeStage.nextStage!].nextStageActionTitle
            : activeStage.nextStageActionTitle
            ? activeStage.nextStageActionTitle
            : activeStage.publishStep
            ? 'Publish'
            : 'Finish';
        case 'previousStepTitle':
          return activeStage.previousStageActionTitle;
        case 'completionDate':
          return '';
        case 'nextStageTitle': {
          const nextStage = workflow.stages![activeStage.nextStage!];
          if (activeStage.autoStagesSkip) {
            return 'Automatically skipped';
          }
          return nextStage ? nextStage.title : 'No Next Stage';
        }
      }
    }
  },

  shortenText(text: string, buttonTextMaxLength = 25) {
    if (text) {
      return StringUtil.shortenText(text, buttonTextMaxLength);
    }
  },

  isLongerThanMax(text: string, buttonTextMaxLength = 25) {
    if (text) {
      const shortText = StringUtil.shortenText(text, buttonTextMaxLength);
      return text.length !== shortText.length;
    }
  },

  showPreviousButton(workflow: IWorkFlow) {
    if (workflow && workflow.activeStage) {
      return workflow.activeStage && workflow.activeStage.previousStage !== null && !isNaN(workflow.activeStage.previousStage);
    }
  },

  showNextButton(workflow: IWorkFlow) {
    if (workflow && workflow.activeStage) {
      return workflow.activeStage;
    }
  },

  isSkippable(workflow: IWorkFlow) {
    return workflow?.activeStage?.skippableStage;
  },

  isRevisionSettingsValid(index: IIndex) {
    return !['distributionDate', 'revisionDate', 'expiryDate'].find((fieldName) => index[fieldName] === undefined);
  },

  findAlerts(project: IProject, workflow?: IWorkFlow | null, index?: IIndex, approvalInfo?: string) {
    const alerts: string[] = [];

    if (workflow) {
      if (workflow.activeStage && !workflow.isCurrentUserAssignedToStage) {
        alerts.push('You are not assigned to the Workflow Stage.');
      }

      if (workflow.activeStage && workflow.activeStage.publishStep) {
        if (workflow.isCurrentUserAssignedToStage && !project.currentUserPermissions?.canPublish) {
          alerts.push('You need a Publish Permission to progress.');
        } else if (index && !this.isRevisionSettingsValid(index)) {
          alerts.push('Some revision information is missing, document is not valid for publishing.');
        }
      }

      if (approvalInfo && this.showApprovalWarningMsg(workflow, approvalInfo)) {
        alerts.push('Regulation Information is missing.');
      }
    }

    return alerts;
  },

  isUserWorkflowAdmin(user: IUser, project: IProject) {
    let canManageWorkflow = false,
      isTSAdmin = false,
      isGlobalAdmin = false;

    if (project) {
      canManageWorkflow = project.currentUserPermissions?.canManageWorkflow;
    }

    if (user) {
      isTSAdmin = ActiveUserStore.isAdminOfSelectedProjectTeamspace(project.workspaceUid);

      if (ActiveUserStore.isAdmin()) {
        isGlobalAdmin = ActiveUserStore.isAdmin();
      }
    }

    return isGlobalAdmin || isTSAdmin || canManageWorkflow;
  },

  canUserPerformReject(user: IUser, workflow: IWorkFlow) {
    if (workflow && workflow.activeStage && workflow.activeStage.assignments) {
      if (this.didUserPerformAction(workflow.activeStage.assignments, user.uid)) {
        return false;
      }

      return workflow.isCurrentUserAssignedToStage;
    }

    return false;
  },

  canUserPerformAction(user?: IUser, workflow?: IWorkFlow | null, project?: IProject, index?: IIndex) {
    if (user && workflow && project && workflow.activeStage && workflow.activeStage.assignments) {
      if (this.didUserPerformAction(workflow.activeStage.assignments, user.uid)) {
        return false;
      }

      if (workflow.activeStage.publishStep && index) {
        return this.isRevisionSettingsValid(index) && workflow.isCurrentUserAssignedToStage && project.currentUserPermissions?.canPublish;
      } else {
        return workflow.isCurrentUserAssignedToStage;
      }
    }
  },

  didUserPerformAction(assignments: IWorkflowAssignments[], userUid: string) {
    // Find out if user already has performed action
    const userAssignment = assignments.find((assignment) => assignment.uid === userUid);
    if (userAssignment && userAssignment.reviewResult) {
      return true;
    }

    return false;
  },

  isPublishing(workflow: IWorkFlow) {
    if (workflow && workflow.activeStage) {
      return workflow.activeStage && workflow.activeStage.publishStep;
    }

    return false;
  },

  isWorkflowFinished(workflow: IWorkFlow) {
    if (workflow) {
      return workflow && workflow.type === 'ARCHIVED';
    }

    return false;
  },

  // Default action is APPROVE
  isCommentRequired(stage: IStage, actionType = 'APPROVE') {
    const isSkip = actionType === 'SKIP';
    const isCommentRequired = stage && stage.transitionRequiresComment;
    return isSkip || isCommentRequired;
  },

  showUploadApprovalArtifacts(workflow: IWorkFlow, index: IIndex) {
    return (
      workflow && workflow.activeStage && workflow.activeStage.mustUploadApprovalArtifacts && this.showApprovalWarningMsg(workflow, index)
    );
  },

  // APPROVAL INFO HELPERS
  showApprovalWarningMsg(workflow: IWorkFlow, approvalInfo: string, callback?: (result) => void) {
    if (workflow && workflow.activeStage && approvalInfo) {
      const result =
        this.willCauseTransition(workflow.activeStage) && this.isApprovalMissing(approvalInfo) && this.isApprovalRequired(workflow);

      if (callback) {
        callback(result);
      }

      return result;
    }
  },

  willCauseTransition(activeStage: IStage) {
    return activeStage.approvalMethod === 'SINGLE' || this.isLastUser(activeStage);
  },

  isLastUser(activeStage: IStage) {
    if (activeStage) {
      const usersWithoutReview = activeStage.assignments.filter((a) => !a.reviewResult);
      return usersWithoutReview.length === 1;
    }
  },

  isApprovalMissing(approval) {
    if (approval) {
      const props = ['approvalOrg', 'approvalReferenceNumber', 'approvalDate', 'approverName', 'approverTitle'];

      const empty = props.filter((prop) => !approval[prop]);
      const isEmpty = empty.length > 0;
      return isEmpty;
    }
  },

  isApprovalRequired(workflow: IWorkFlow): boolean {
    if (workflow && workflow.activeStage && workflow.activeStage.mustUploadApprovalArtifacts) {
      return true;
    } else {
      return false;
    }
  },

  isWorkflowBeforeStart(index: IIndex) {
    return index && index.lockedStatus === IndexLockStatuses.props.LOCKED_FOR_WORKFLOW_TO_START.code;
  },

  isWorkflowActive(workflow: IWorkFlow) {
    return workflow && workflow.type === 'ACTIVE';
  }
};
