import { IBackendError, IBackendErrorDetails } from 'mm-types';
import { AxiosResponse } from 'axios';

class BackendResponseUtil {
  getErrorDetails(response: AxiosResponse<IBackendError> | undefined): IBackendErrorDetails {
    if (response && response.data.error) {
      return response.data.error;
    } else if (response && response.data.errors && response.data.errors.length) {
      return response.data.errors[0];
    }
    return {
      code: 0,
      message: response ? response.statusText : ''
    };
  }
}

const backendResponseUtil = new BackendResponseUtil();

export default backendResponseUtil;
