import React from 'react';
import { ShortcutkeysDialog } from '../general/shortcutkeys-dialog/ShortcutkeysDialog';
import { SHORTCUTS } from '../general/shortcutkeys-dialog/EditorShortcuts';
import LinkModal from './links/LinkModal';
import LinkAnchorModal from './links/LinkAnchorModal';
import HotspotsModal from './hotspots/HotspotsModal';
import { RefIntModal } from './links/RefInt/RefIntModal';
import refIntService from './links/RefInt/refIntService';
import MergeRevisionModal from './merge/MergeRevisionModal';
import MediaLibModal from './medialib/MediaLibModal';
import PowerPasteModal from './powerpaste/PowerPasteModal';
import WorkflowActionModal from './workflowaction/WorkflowActionModal';
import Dialog from 'material-ui/Dialog';
import { State } from './EditorPage';
import _ from 'lodash';
import EditorStore from '../../flux/editor/EditorStore';
import DatePicker from 'material-ui/DatePicker';

type Props = {
  state: State;
  closeLinkModal(): void;
  closeLinkAnchorModal(): void;
  closeHotspotModal(): void;
  saveHotspotLinks(hotspotLinks: any): void;
  closeDatePicker(): void;
  closeMergeRevisionModals(): void;
  closeFullPageModal(): void;
  hideDiffOverlay(): void;
};
const EditorModals = (props: Props) => {
  const { state } = props;

  const propogateMouseScrollEvent = (e) => {
    if (e && e.target) {
      const $target = $(e.target);
      const pointerEvents = $target.css('pointer-events');
      if (!pointerEvents || pointerEvents !== 'none') {
        $target.css('pointer-events', 'none');
        setTimeout(() => {
          $target.css('pointer-events', 'auto');
        }, 100);
      }
    }
  };

  return (
    <>
      {state.showAlert ? (
        <Dialog
          contentClassName={'editor-page-alert-content'}
          actionsContainerClassName={'editor-page-alert-actions'}
          titleClassName={'editor-page-alert-title'}
          {..._.extend({ actions: [], open: true, modal: true }, state.showAlert.props)}
          style={{ zIndex: 999 }}
        >
          {state.showAlert.message}
        </Dialog>
      ) : undefined}
      <ShortcutkeysDialog shortcuts={SHORTCUTS} />
      {state.linkModal.open ? (
        <LinkModal
          onClose={() => props.closeLinkModal()}
          isDuRefLink={state.linkModal.isDuRefLink}
          templateHtml={state.linkModal.templateHtml}
        />
      ) : undefined}
      {state.showLinkAnchorModal && (
        <LinkAnchorModal
          onClose={() => props.closeLinkAnchorModal()}
          title={EditorStore.getEditor()._getCurrentEditingNode()?.getAttribute('data-title')}
        />
      )}
      {state.showHotspotsModal ? (
        <HotspotsModal
          src={state.hotspotsData!.src}
          hotspots={state.hotspotsData!.hotspots}
          onClose={() => props.closeHotspotModal()}
          onSave={(hotspotLinks) => props.saveHotspotLinks(hotspotLinks)}
        />
      ) : undefined}
      {state.showRefIntModal && (
        <RefIntModal
          footnoteInfo={refIntService.getFootnoteNids(EditorStore.getEditor())}
          selectedNid={refIntService.getRefIntTargetNid(EditorStore.getEditor())}
          onDismiss={() => EditorStore.triggerCloseRefIntModal()}
          onSave={(nid, index) => refIntService.updateRefInt(EditorStore.getEditor(), nid, index)}
        />
      )}
      {state.showGlobalDatePicker ? (
        <div className="date-picker-container global-datepicker">
          <DatePicker
            {...state.showGlobalDatePicker.props}
            ref={state.showGlobalDatePicker.ref}
            id="gbp"
            autoOk={true}
            onDismiss={() => props.closeDatePicker()}
          />
        </div>
      ) : undefined}
      {state.showFullpageModal && state.showFullpageModal.type === 'mergerevision' ? (
        <MergeRevisionModal onRequestClose={() => props.closeMergeRevisionModals()} />
      ) : undefined}
      {state.showFullpageModal && state.showFullpageModal.type === 'medialib' ? (
        <MediaLibModal
          onRequestClose={() => props.closeFullPageModal()}
          mediaLibModalInfo={state.mediaLibModalInfo}
          isShortcut={state.showFullpageModal.isShortcut}
        />
      ) : undefined}
      {state.showFullpageModal && state.showFullpageModal.type === 'powerpaste' ? (
        <PowerPasteModal onRequestClose={() => props.closeFullPageModal()} />
      ) : undefined}
      {state.showFullpageModal && state.showFullpageModal.type === 'workflowaction' ? (
        <WorkflowActionModal
          project={state.project}
          data={state.showFullpageModal.data!}
          onRequestClose={() => props.closeFullPageModal()}
        />
      ) : undefined}
      {state.showDiffOverlay ? (
        <div className="diff-overlay" onWheel={(e) => propogateMouseScrollEvent(e)} onClick={() => props.hideDiffOverlay()} />
      ) : undefined}
    </>
  );
};

export default EditorModals;
