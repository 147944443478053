import { SESSION_STORAGE_KEYS } from '../../utils';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';

export function useStorage(
  key: SESSION_STORAGE_KEYS | string,
  storageType: 'session' | 'local' = 'session'
): [string | null, Dispatch<SetStateAction<string | null>>] {
  const storage = storageType === 'session' ? sessionStorage : localStorage;
  const [value, setValue] = useState<string | null>(getItem());

  function getItem(): string | null {
    return storage.getItem(key);
  }

  function setItem(value: string) {
    storage.setItem(key, value);
  }

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setItem(value);
    } else {
      storage.removeItem(key);
    }
  }, [value]);

  return [value, setValue];
}
