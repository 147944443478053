import * as React from 'react';
import ChangeTaskDropdown from '../../components/misc/ChangeTaskDropdown';
import { ITask } from 'mm-types';

export type Props = {
  zIndex?: number;
  headerTitle?: null | string;
  title?: string;
  emptyText?: string;
  tasks?: ITask[];
  onChange: (e: { taskUid: string; assigned: boolean; task?: ITask }) => void;
};

export type State = {
  isPopupOpen: boolean;
};

export default class ChangeTaskFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
  }

  static defaultProps: Partial<Props> = {
    headerTitle: null,
    title: '',
    emptyText: 'No tasks selected',
    tasks: []
  };

  private _openDropdown() {
    if (!this.state.isPopupOpen) {
      this.setState({ isPopupOpen: true }, () => {
        (this.refs.taskTagsDropdown as ChangeTaskDropdown).open(this.refs.dropDownActivateBtn);
      });
    }
  }

  private _handleDropDownChange(e) {
    this.props.onChange(e);
  }

  private _handleDropDownClose() {
    setTimeout(() => {
      // ensure won't re-open in _openDropdown
      this.setState({ isPopupOpen: false });
    }, 200);
  }

  private _removeTask(e: React.MouseEvent<HTMLElement>, task: ITask) {
    this.props.onChange({ taskUid: task.uid, assigned: false });

    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  }

  render() {
    let changeTasksDropdownComponent: JSX.Element | null = null;

    const titleHtml = this.props.headerTitle ? <h6>{this.props.headerTitle}</h6> : null;
    let tasksHtml: JSX.Element | (JSX.Element | undefined)[] = this.props.tasks!.map((task) => {
      if (task) {
        return (
          <div
            className={'task-tag' + (task.active ? '' : ' task-disabled')}
            style={{ backgroundColor: task.colorHex }}
            key={task.identifier}
          >
            <span className="icon-changetask-check"></span>
            <span id="task-identifier">{task.identifier}</span>
            <span
              className="material-icons"
              onClick={(e) => {
                this._removeTask(e, task);
              }}
            >
              remove_circle
            </span>
          </div>
        );
      }
    });

    if ((tasksHtml as JSX.Element[]).length === 0) {
      tasksHtml = <div className={'empty-text'}>{this.props.emptyText}</div>;
    }

    if (this.state.isPopupOpen) {
      changeTasksDropdownComponent = (
        <ChangeTaskDropdown
          title={this.props.title}
          assignedTasks={this.props.tasks}
          allowChangeDeactivatedTasks={true}
          onChange={(e) => {
            this._handleDropDownChange(e);
          }}
          onClose={(e) => {
            this._handleDropDownClose();
          }}
          ref="taskTagsDropdown"
        />
      );
    }

    return (
      <div className="changetask-filter-container" style={{ zIndex: this.props.zIndex }}>
        {titleHtml}

        <div
          className="changetask-filter-container-outer"
          onClick={(e) => {
            this._openDropdown();
          }}
        >
          <div className="changetask-filter-container-inner">{tasksHtml}</div>

          <svg viewBox="0 0 24 24" className="icon-dropdown-arrow" ref="dropDownActivateBtn">
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
        </div>
        {changeTasksDropdownComponent}
      </div>
    );
  }
}
