import * as React from 'react';

export type Props = {
  projectUid: string;
  mediaUid: string;
};

export type State = {};

export default class MediaImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getUrl() {
    return '/api/projects/' + this.props.projectUid + '/media/' + this.props.mediaUid + '/content';
  }

  render() {
    return <img src={this.getUrl()} />;
  }
}
