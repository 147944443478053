import * as Reflux from 'reflux';
import Store from '../Store';
import { IPublishCheck } from 'mm-types';
import * as workflows from '../../clients/workflows';
import { Cancelled } from '../../clients/base-clients';

export type IError = { error?: JQueryXHR; errors?: JQueryXHR[] };
export type Callback = (publishCheck: IError | IPublishCheck) => void;

export type State = {};

export type PublishStoreEvent = {
  type: 'publishCheck';
  data: IPublishCheck & { error?: Error };
};

export class PublishStore extends Store<State> {
  constructor() {
    super();
  }

  async publishCheck(projectUid: string, indexUid: string) {
    try {
      const result = await workflows.projectCheck(projectUid, indexUid);
      this.trigger({ type: 'publishCheck', data: result } as PublishStoreEvent);
      return result;
    } catch (err) {
      this.trigger({ type: 'publishCheck', data: { error: err as Error } } as PublishStoreEvent);
      return null;
    }
  }

  cancelPendingWorkflowCheck() {
    workflows.cancelPendingWorkflowCheck();
  }

  async workflowCheck(workflowUid: string | undefined): Promise<IPublishCheck | Cancelled | null> {
    if (!workflowUid) {
      this.trigger({ type: 'publishCheck', data: { errors: [] } } as PublishStoreEvent);
      return null;
    }

    try {
      const result = await workflows.workflowCheck(workflowUid);
      this.trigger({ type: 'publishCheck', data: result } as PublishStoreEvent);
      return result;
    } catch (err) {
      this.trigger({ type: 'publishCheck', data: { error: err } } as PublishStoreEvent);
      return err as Error;
    }
  }
}

const singleton = Reflux.initStore<PublishStore>(PublishStore);
export default singleton;
