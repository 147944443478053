import React, { Component } from 'react';
import WarningsPopup from '../../../general/WarningsPopup';
import { FontIcon } from 'material-ui';
import { styleMap } from '../../../../styles/styleGuard';
import { CURSOR } from '../../../../styles/cursor';
import { OVERFLOW } from '../../../../styles/display';
import { MenuElement } from '../EditorMenu/MenuElements';

const EL_WIDTH = 56;

const styles = styleMap({
  li: {
    transition: 'width 0.15s ease-in-out, opacity 0.25s ease-in-out 0.15s',
    ...OVERFLOW.HIDDEN,
    ...CURSOR.POINTER
  },
  icon: {
    paddingLeft: 0
  }
});

interface State {
  width: number;
  opacity: number;
}

interface Props {
  messages: string[];
  isInformationButton: boolean;
}

export class WarningInformationButton extends Component<Props, State> {
  private warningPopup: WarningsPopup | null;
  private button: FontIcon | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      width: 0,
      opacity: 0
    };
    this.openPopup = this.openPopup.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        width: EL_WIDTH,
        opacity: 1
      });
    }, 0);
  }

  openPopup() {
    if (!this.warningPopup?.getPopup()?.isOpen() ?? false) {
      this.warningPopup?.showMessages(this.props.messages).open(this.button, 35);
    }
  }
  render() {
    const { isInformationButton } = this.props;
    return (
      <>
        <MenuElement
          className="warning-item text-align-center"
          style={{ ...styles.li, width: this.state.width, opacity: this.state.opacity }}
          onClick={this.openPopup}
          dataQa={isInformationButton ? 'menu-element-informations' : 'menu-element-warnings'}
        >
          <span data-qa="warnings-popup-button" className={`menu-button ${isInformationButton ? 'information' : 'warning'}`}>
            <FontIcon className="material-icons" ref={(ref) => (this.button = ref)} style={styles.icon}>
              {isInformationButton ? 'info' : 'error'}
            </FontIcon>
          </span>
          <WarningsPopup ref={(ref) => (this.warningPopup = ref)} />
        </MenuElement>
      </>
    );
  }
}
