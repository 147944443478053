import * as React from 'react';

interface Props {
  hidden?: boolean;
  onClick?: () => void;
}

const SvgDropdownIcon = React.forwardRef(({ hidden = false, onClick = () => {} }: Props, ref: React.RefObject<SVGSVGElement>) => {
  const localRef = React.useRef<SVGSVGElement>(null);
  const svgRef = ref || localRef;

  if (hidden) {
    return null;
  }

  return (
    <svg
      viewBox="0 0 24 24"
      className="icon-dropdown-arrow"
      onClick={() => {
        onClick && onClick();
      }}
      ref={svgRef}
    >
      <path d="M7 14l5-5 5 5z"></path>
    </svg>
  );
});

export { SvgDropdownIcon };
