import { findDOMNode } from 'react-dom';

const Scroller = {
  scrollTo(element: Element, target: number, duration: number) {
    target = Math.round(target);
    duration = Math.round(duration);
    if (duration < 0) {
      return Promise.reject('bad duration');
    }
    if (duration === 0) {
      element.scrollTop = target;
      return Promise.resolve();
    }

    const start_time = Date.now();
    const end_time = start_time + duration;

    const start_top = element.scrollTop;
    const distance = target - start_top;

    // based on http://en.wikipedia.org/wiki/Smoothstep
    const smooth_step = function (start, end, point) {
      if (point <= start) {
        return 0;
      }
      if (point >= end) {
        return 1;
      }
      const x = (point - start) / (end - start); // interpolation
      return x * x * (3 - 2 * x);
    };

    return new Promise<void>(function (resolve, reject) {
      // This is to keep track of where the element's scrollTop is
      // supposed to be, based on what we're doing
      let previous_top = element.scrollTop;

      // This is like a think function from a game loop
      const scroll_frame = function () {
        if (element.scrollTop !== previous_top) {
          reject('Scroller.ts scroll_frame() - interrupted');
          return;
        }

        // set the scrollTop for this frame
        const now = Date.now();
        const point = smooth_step(start_time, end_time, now);
        const frameTop = Math.round(start_top + distance * point);
        element.scrollTop = frameTop;

        // check if we're done!
        if (now >= end_time) {
          resolve();
          return;
        }

        // If we were supposed to scroll but didn't, then we
        // probably hit the limit, so consider it done; not
        // interrupted.
        if (element.scrollTop === previous_top && element.scrollTop !== frameTop) {
          resolve();
          return;
        }
        previous_top = element.scrollTop;

        // schedule next frame for execution
        setTimeout(scroll_frame, 0);
      };

      // boostrap the animation process
      setTimeout(scroll_frame, 0);
    });
  },

  scrollToElement(parentElement: Element, targetElement: Element, duration: number) {
    const position =
      targetElement.getBoundingClientRect().top +
      document.body.scrollTop -
      (parentElement.getBoundingClientRect().top + document.body.scrollTop);
    return (this as typeof Scroller).scrollTo(parentElement, position + parentElement.scrollTop, duration);
  },

  scrollTop(component: React.Component, duration = 150) {
    const el = findDOMNode(component) as Element;
    if (el.scrollTop > 0) {
      $(el).animate({ scrollTop: 0 }, duration);
    }
  }
};

export default Scroller;
