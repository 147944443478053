import * as React from 'react';
import VerticalSlide from '../general/animations/VerticalSlide';
import Fade from '../general/animations/Fade';

export type Props = {
  title?: string;
  subtitle?: string;
  id?: string;
  defaultOpen?: boolean;
  subsection?: boolean;
  children?: React.ReactNode;
};

export type State = {
  opened: boolean;
};

export default class AnimatedToggleSection extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    title: '',
    subtitle: '',
    id: '',
    defaultOpen: false,
    subsection: false
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      opened: this.props.defaultOpen!
    };
    this._toggle = this._toggle.bind(this);
  }

  private _toggle() {
    this.setState({ opened: !this.state.opened });
  }

  render() {
    const iconClassName = `toc-bullet with-transition icon-menu-bullet-opened ${!this.state.opened ? 'rotate-90' : ''}`;
    const containerClassName = `animated-toggle-section ${this.state.opened ? 'opened' : 'closed'}`;
    const subsection = this.props.subsection;

    const containerStyles = {
      borderBottom: '1px solid #EAEAEA'
    };

    const headerStyles: React.CSSProperties = {
      paddingLeft: '1.5rem',
      paddingTop: subsection ? 0 : '1rem',
      fontSize: '1rem',
      height: subsection ? 'auto' : '66px',
      fontWeight: 500,
      color: 'black',
      cursor: 'pointer',
      position: 'relative',
      lineHeight: '40px',
      margin: 0,
      borderTop: 'none'
    };

    const subtitleStyles: React.CSSProperties = {
      position: 'absolute',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '22px',
      height: '22px',
      left: '1.5rem',
      top: 44
    };

    const iconStyles: React.CSSProperties = {
      cursor: 'pointer',
      display: 'inline-block',
      height: 20,
      width: 10,
      float: subsection ? 'left' : 'right',
      top: 11,
      position: 'relative',
      marginRight: '1rem'
    };

    return (
      <div
        className={containerClassName}
        id={this.props.id}
        style={containerStyles}
        data-qa="animated-toggle-section"
        data-qa-open={this.state.opened.toString()}
      >
        <div onClick={this._toggle}>
          <h1 style={headerStyles}>
            <span className="toggle-title">
              {this.props.title}
              <span className={iconClassName} style={iconStyles} />
            </span>
            <span className="toggle-subtitle font-gray" style={subtitleStyles}>
              {this.props.subtitle && <Fade show={this.state.opened}>{this.props.subtitle}</Fade>}
            </span>
          </h1>
        </div>
        <VerticalSlide open={this.state.opened} contentStyle={{ padding: '0 0 1rem 1.5rem' }}>
          {this.props.children}
        </VerticalSlide>
      </div>
    );
  }
}

export function withAnimatedToggleSection<T>(
  Component: React.ComponentType<T>,
  initProps: Partial<Props> = {}
): React.ComponentType<T & Props> {
  return (props: Partial<Props>) => {
    const {
      defaultOpen = initProps.defaultOpen,
      id = initProps.id,
      subsection = initProps.subsection,
      subtitle = initProps.subtitle,
      title = initProps.title,
      ...rest
    } = props;

    return (
      <AnimatedToggleSection title={title} id={id} subsection={true} defaultOpen={defaultOpen}>
        <Component {...(rest as T & Props)} />
      </AnimatedToggleSection>
    );
  };
}
