import * as Reflux from 'reflux';
import * as client from './../../clients/organiasations';
import Store from '../Store';
import { IOrganisation } from 'mm-types';
import { Cancelled } from '../../clients/base-clients';

export type OrgAdminStoreEvent = {
  state: State;
};

export type State = {
  currentAdminOrgs?: IOrganisation[];
  currentMemberOrgs?: IOrganisation[];
};

export class OrgAdminStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      currentAdminOrgs: [],
      currentMemberOrgs: []
    };
  }

  getInitialState() {
    return this.state;
  }

  isOrgAdmin() {
    return this.state.currentAdminOrgs!.length > 0;
  }

  getCurrentAdminOrgs() {
    return this.state.currentAdminOrgs;
  }

  async currentUserOrgAdminList(options?: { silent: boolean }) {
    const adminOrgs = await client.getAll({ activeOnly: true, canAdministerOnly: true, isMemberOf: false });
    if (adminOrgs instanceof Cancelled) {
      return;
    }

    this.state.currentAdminOrgs = adminOrgs;

    const memberOrgs = await client.getAll({ activeOnly: true, canAdministerOnly: false, isMemberOf: true });
    if (memberOrgs instanceof Cancelled) {
      return;
    }

    this.state.currentMemberOrgs = memberOrgs;

    if (!(options && options.silent)) {
      const event: OrgAdminStoreEvent = {
        state: this.state
      };
      this.trigger(event);
    }
  }
}

const singleton = Reflux.initStore<OrgAdminStore>(OrgAdminStore);
export default singleton;
