import * as React from 'react';
import { CircularProgress } from 'material-ui';

export type Props = {
  suggestions: string[] | null;
  loading: boolean;
  onDoubleClickSuggestion: (suggestion: string) => void;
  onClickSuggestion: (e: React.MouseEvent<HTMLElement>, suggestion: string) => void;
  children?: React.ReactNode;
};

export type State = {};

export default class SpellCheckSuggestions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.props.suggestions) {
      return (
        <div className="suggestion-box">
          <div className="smallText">Suggestions</div>
          <div className="input">
            {this.props.loading ? (
              <div className="spell-checker-loading" style={{ margin: '20px' }}>
                <CircularProgress thickness={2.5} size={30} />
              </div>
            ) : (
              <select className="spell-check-words-list" size={6}>
                {this.props.suggestions.map((s) => (
                  <option
                    data-key={s}
                    key={s}
                    onDoubleClick={(e) => this.props.onDoubleClickSuggestion(s)}
                    onClick={(e) => this.props.onClickSuggestion(e, s)}
                    value={s}
                  >
                    {s}
                  </option>
                ))}
              </select>
            )}
          </div>
          {this.props.children}
        </div>
      );
    } else {
      return <span />;
    }
  }
}
