import { mm, accounts } from './base-clients';
import { IServerSettings, IUser, IEmailGroup, IAccount } from 'mm-types';
import { ILogMessage } from '../utils/Log';
import { CacheClient } from '../utils/CacheClient';

/**
 * Pings the server to check its health
 */
export async function ping() {
  await mm.get<void>(`/status?dataType=text`);
  return true;
}

/**
 * Gets the server settings
 */
export async function serverSettings(): Promise<Partial<IServerSettings>> {
  const response = await mm.get<IServerSettings>(`/config/web`, {
    params: CacheClient.params.useCache()
  });
  return response.data;
}

/**
 * Gets the logged in user
 */
export async function sso() {
  const response = await mm.get<IUser>(`/auth/sso`);
  return response.data;
}

export async function accountsSso() {
  const response = await accounts.get<IAccount>(`/auth/sso`);
  return response.data;
}

export async function getEmailGroups() {
  const response = await accounts.get<{ groups: IEmailGroup[] }>(`/groups?type=email`);
  const groups = response.data.groups || [];

  groups.sort(function (a, b) {
    const nameA = a.name ? a.name.toLowerCase() : '';
    const nameB = b.name ? b.name.toLowerCase() : '';

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  return groups;
}

export async function saveLogs(logs: { entries: ILogMessage[] }) {
  await mm.post(`/debug/web/log`, logs);
}
