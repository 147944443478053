import React from 'react';
import SharedContentListTypes, { SharedContentListViewTypes } from './SharedContentListTypes';
import PopupMenu, { Option, Options } from '../../../../misc/PopupMenu';
import EditorStore from '../../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';

type Props = { isReadOnly: boolean; listViewing: SharedContentListViewTypes; handleListViewSwitch };

const ListSwitchMenu = ({ isReadOnly, listViewing, handleListViewSwitch }: Props, ref: React.Ref<PopupMenu>) => {
  const project = ProjectStore.getProject();
  const isDraft = project && project.masterIndexUid === EditorStore.getDocParams().indexUid;
  const disable = isReadOnly && !isDraft;
  const regulationDisabled = (project && project.workspaceType === 'personal') || !project;

  const menuItems: {
    isDisabled?: boolean;
    key: SharedContentListViewTypes;
    title: string;
    checked: boolean;
  }[] = [
    {
      key: 'SHAREDCONTENT_ALL',
      title: SharedContentListTypes.props.SHAREDCONTENT_ALL.title,
      checked: listViewing === 'SHAREDCONTENT_ALL',
      isDisabled: disable
    },
    {
      key: 'SHAREDCONTENT_SOURCE',
      title: SharedContentListTypes.props.SHAREDCONTENT_SOURCE.title,
      checked: listViewing === 'SHAREDCONTENT_SOURCE',
      isDisabled: disable
    },
    {
      key: 'SHAREDCONTENT_TARGET',
      title: SharedContentListTypes.props.SHAREDCONTENT_TARGET.title,
      checked: listViewing === 'SHAREDCONTENT_TARGET'
    },
    {
      key: 'SHAREDCONTENT_PENDING',
      title: SharedContentListTypes.props.SHAREDCONTENT_PENDING.title,
      checked: listViewing === 'SHAREDCONTENT_PENDING',
      isDisabled: disable
    },
    {
      key: 'SHAREDCONTENT_REGULATIONS',
      title: SharedContentListTypes.props.SHAREDCONTENT_REGULATIONS.title,
      checked: listViewing === 'SHAREDCONTENT_REGULATIONS',
      isDisabled: regulationDisabled
    },
    {
      key: 'SHAREDCONTENT_SPECIALINTEREST',
      title: SharedContentListTypes.props.SHAREDCONTENT_SPECIALINTEREST.title,
      checked: listViewing === 'SHAREDCONTENT_SPECIALINTEREST',
      isDisabled: regulationDisabled
    }
  ];

  return (
    <PopupMenu
      ref={ref}
      onSelect={(e) => handleListViewSwitch(e as SharedContentListViewTypes)}
      className="display-options-popup"
      disabled={EditorStore.isMode('SHARE_EDIT')}
    >
      <Options>
        <div className="popup-arrow"></div>
        <div className="popup-menu-title">Show:</div>
        {menuItems.map((menuItem) => {
          return (
            <Option
              canCheckOff={false}
              disabled={menuItem.isDisabled}
              hasCheck={menuItem.checked}
              defaultChecked={menuItem.checked}
              dataKey={menuItem.key}
              key={menuItem.key}
            >
              {menuItem.title}
            </Option>
          );
        })}
      </Options>
    </PopupMenu>
  );
};

export default React.forwardRef(ListSwitchMenu);
