import * as React from 'react';
import * as _ from 'lodash';
import { DatePicker, IconButton, SvgIcon, TimePicker } from 'material-ui';
import Popover from './PopoverPatched';
import moment from 'moment';
import ValidatableTextInput from './ValidatableTextInput';

type Props = {
  value?: Date | null;
  isDateDropdownVisible?: boolean;
  classPrefix?: string;
  onClearDateTime?: () => void;
  isDisabled: boolean;
  onUpdateDate: (date: Date) => void;
  onUpdateTime: (date: Date) => void;
  datePickerId?: string;
  timePickerId?: string;
  selectedLabel?: string;
  minDate?: Date;
  maxDate?: Date;
  children?: React.ReactNode;
  iconStyle?: React.CSSProperties;
  isRequired?: boolean;
  errorMessage?: string;
  onValidation?: (hasError: boolean) => void;
};

export type State = {
  opened: boolean;
  anchorEl?: Element;
};

export type DateTimeInputType = {
  openDatePicker: () => void;
  openTimePicker: () => void;
  handleRequestClose: () => void;
};

export const DateTimeInput = React.forwardRef(
  ({ classPrefix = '', isDateDropdownVisible = false, ...props }: Props, ref: React.Ref<any>) => {
    const [state, setState] = React.useState<State>({
      opened: false
    });
    const datePickerRef = React.useRef<DatePicker>(null);
    const timePickerRef = React.useRef<TimePicker>(null);

    React.useImperativeHandle(ref, () => ({
      openDatePicker() {
        openDatePicker();
      },
      openTimePicker() {
        openTimePicker();
      },
      handleRequestClose() {
        handleRequestClose();
      }
    }));

    const handleTouchTap = (event) => {
      setState((prevProps) => ({
        ...prevProps,
        opened: !state.opened,
        anchorEl: event.currentTarget
      }));
    };

    const handleRequestClose = () => {
      setState((prevProps) => ({
        ...prevProps,
        opened: false
      }));
    };

    const isDateSelected = () => {
      return !!props.value;
    };

    const openDatePicker = () => {
      datePickerRef.current?.openDialog();
    };

    const onChangeDP = (date: Date) => {
      const endOfDay = moment(date).startOf('day');
      props.onUpdateDate(endOfDay.toDate());
    };

    const openTimePicker = () => {
      timePickerRef.current?.openDialog();
    };

    const onChangeTime = (date: Date) => {
      if (props?.value) {
        const newDate = moment(props.value);
        const newTime = moment(date);
        newDate.set({ hour: newTime.hour(), minute: newTime.minutes() });
        props.onUpdateTime(newDate.toDate());
      }
    };

    return (
      <div className={classPrefix + 'compare-to'} ref={ref}>
        {!!isDateDropdownVisible && (
          <div className={classPrefix + 'revisions'}>
            <div className="menu-dropdown">
              <div className="menu-dropdown__selected cursor-pointer" onClick={(e) => handleTouchTap(e)}>
                {props?.selectedLabel}
                <SvgIcon>
                  <path d="M7 10l5 5 5-5z" />
                </SvgIcon>
              </div>
              <Popover
                open={state.opened}
                anchorEl={state.anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                onRequestClose={() => handleRequestClose()}
                animated={false}
              >
                {props.children}
              </Popover>
            </div>
          </div>
        )}

        {!isDateDropdownVisible && (
          <div className="display-inline-block" style={{ maxWidth: '60%' }}>
            <ValidatableTextInput
              data-qa="datetime-input-field"
              errorStyle={{ bottom: '2px' }}
              floatingLabelText={'Date Time'}
              value={props?.selectedLabel}
              nullable={!props?.isRequired}
              onFocus={openDatePicker}
              onChange={(e) => e.preventDefault()}
              style={{ width: 'fit-content' }}
              readOnly={true}
              errorMessage={props?.errorMessage}
              onValidation={props?.onValidation}
            />
          </div>
        )}

        <div className={classPrefix + 'datetime display-inline'}>
          <div className={classPrefix + 'datetime-element display-inline-block'}>
            <IconButton
              onClick={(e) => openDatePicker()}
              disabled={props.isDisabled}
              className={`lock-member separate ${props.isDisabled ? 'opacity-05' : ''}`}
              iconClassName="material-icons"
              iconStyle={props?.iconStyle}
            >
              date_range
            </IconButton>

            <DatePicker
              ref={datePickerRef}
              minDate={props.minDate}
              maxDate={props.maxDate}
              defaultDate={props.value ?? new Date()}
              dialogContainerStyle={{ color: 'fuchsia' }}
              textFieldStyle={{ display: 'none' }}
              id={props?.datePickerId ?? 'datePicker'}
              onChange={(e, date) => onChangeDP(date)}
            />
          </div>

          <div className={classPrefix + 'datetime-element display-inline-block'}>
            <IconButton
              onClick={(e) => openTimePicker()}
              disabled={props.isDisabled || !isDateSelected()}
              className={`lock-member separate with-transition ${props.isDisabled || !isDateSelected() ? 'opacity-05' : ''}`}
              iconClassName="material-icons"
              iconStyle={props?.iconStyle}
            >
              access_time
            </IconButton>

            <TimePicker
              ref={timePickerRef}
              textFieldStyle={{ display: 'none' }}
              id={props?.timePickerId ?? 'timePicker'}
              onChange={(e, date) => onChangeTime(date)}
            />
          </div>

          {props?.onClearDateTime && (
            <div className={classPrefix + 'datetime-element display-inline-block'}>
              <IconButton
                data-qa="datetime-input-clear-btn"
                onClick={(e) => {
                  props.onClearDateTime && props.onClearDateTime();
                }}
                disabled={!props.value}
                className={`lock-member separate with-transition ${props.isDisabled || !isDateSelected() ? 'opacity-05' : ''}`}
                iconClassName="material-icons"
                iconStyle={{ ...props?.iconStyle, color: 'red' }}
              >
                clear
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  }
);
