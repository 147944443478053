import * as React from 'react';
import { ReactInstance } from 'react';
import * as ReactDOM from 'react-dom';

export type Props = {
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  positionUpperOffset?: number;
  onSelect: (e: string, isChecked: boolean) => void;
  onClose?: () => void;
  children?: React.ReactNode;
};

export type State = {
  id: string;
  active: boolean;
  horizontalPlacement: 'left' | 'right';
  verticalPlacement: 'top' | 'bottom';
  selectedIndex: number;
};

export default class PopupMenu extends React.Component<Props, State> {
  private count: number;
  private _triggerBtnRef: React.ReactInstance | null;

  constructor(props: Props) {
    super(props);
    this.count = 0;
    this._triggerBtnRef = null;
    this.state = {
      id: this._getUuid(),
      active: false,
      selectedIndex: 0,
      horizontalPlacement: 'left', // only 'right' || 'left'
      verticalPlacement: 'bottom' // only 'top' || 'bottom'
    };
  }

  public displayName: 'PopupMenu';

  static defaultProps: Partial<Props> = {
    disabled: false,
    style: {}
  };

  buildClassName(baseName: string) {
    let name = baseName;
    if (this.props.className) {
      name += ' ' + this.props.className;
    }

    return name;
  }

  setTriggerButton(triggerBtnRef: React.ReactInstance) {
    this._triggerBtnRef = triggerBtnRef;
    return this;
  }

  open() {
    if (!this.props.disabled) {
      this.setState({ active: true }, this._afterTriggerToggle);
    }
  }

  isOpen() {
    return this.state.active;
  }

  toggle() {
    this.setState({ active: !this.state.active }, this._afterTriggerToggle);
  }

  close() {
    if (this.state.active) {
      this.setState({ active: false }, () => {
        (ReactDOM.findDOMNode(this._triggerBtnRef as ReactInstance) as HTMLElement).focus();

        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }
  }

  _afterTriggerToggle() {
    if (this.state.active) {
      (this.refs.options as Options).focusOption(0);
      this._updatePositioning();
    }
  }

  _updatePositioning() {
    const triggerRect = (ReactDOM.findDOMNode(this._triggerBtnRef as ReactInstance) as Element).getBoundingClientRect(),
      optionsRect = (ReactDOM.findDOMNode(this.refs.options) as Element).getBoundingClientRect(),
      positionState: Partial<State> = {};

    // horizontal = left if it wont fit on left side
    if (triggerRect.left + optionsRect.width > window.innerWidth) {
      positionState.horizontalPlacement = 'left';
    } else {
      positionState.horizontalPlacement = 'right';
    }
    if (triggerRect.top + optionsRect.height > window.innerHeight + (this.props.positionUpperOffset ? this.props.positionUpperOffset : 0)) {
      positionState.verticalPlacement = 'top';
    } else {
      positionState.verticalPlacement = 'bottom';
    }

    this.setState(positionState as State);
  }

  _handleBlur(e: React.FocusEvent<HTMLElement>) {
    setTimeout(() => {
      if (!(ReactDOM.findDOMNode(this) as Element).contains(document.activeElement) && this.state.active) {
        this.close();
      }
    }, 1);
  }

  _handleKeys(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  _verifyTwoChildren() {
    const ok = React.Children.count(this.props.children) === 1;
    if (!ok) {
      throw 'react-menu can only take two children, a Trigger, and a Options';
    }
    return ok;
  }

  _getUuid() {
    return 'react-menu-' + this.count++;
  }

  _triggerOnSelect(e: string, isChecked: boolean) {
    if (this.props.onSelect) {
      this.props.onSelect(e, isChecked);
    }
    this.close();
  }

  _renderOptions() {
    let options;
    if (this._verifyTwoChildren()) {
      React.Children.forEach(this.props.children, (child) => {
        if ((child as any).type.constructor === Options.constructor) {
          options = React.cloneElement(child as any, {
            ref: 'options',
            horizontalPlacement: this.state.horizontalPlacement,
            verticalPlacement: this.state.verticalPlacement,
            id: this.state.id,
            active: this.state.active,
            onSelectionMade: (e, isChecked) => this._triggerOnSelect(e, isChecked),
            onCloseRequest: () => this.close()
          });
        }
      });
    }
    return options;
  }

  render() {
    return (
      <div
        className={this.buildClassName('popup-menu')}
        onKeyDown={(e) => this._handleKeys(e)}
        onBlur={(e) => this._handleBlur(e)}
        style={this.props.style}
      >
        {this._renderOptions()}
      </div>
    );
  }
}

export type OptionsProps = {
  className?: string;
  horizontalPlacement?: 'left' | 'right';
  verticalPlacement?: 'top' | 'bottom';
  onCloseRequest?: () => void;
  onSelectionMade?: (key: string, isChecked: boolean) => void;
  id?: string;
  active?: boolean;
  children?: React.ReactNode;
};

export type OptionsState = {
  activeIndex: number;
};

export class Options extends React.Component<OptionsProps, OptionsState> {
  private selectedIndex: number;

  constructor(props: OptionsProps) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  buildClassName(baseName: string) {
    let name = baseName;
    if (this.props.className) {
      name += ' ' + this.props.className;
    }

    return name;
  }

  focusOption(index: number) {
    this.selectedIndex = index;
    this._updateFocusIndexBy(0);
  }

  _moveSelectionUp() {
    this._updateFocusIndexBy(-1);
  }

  _moveSelectionDown() {
    this._updateFocusIndexBy(1);
  }

  _handleKeys(e: React.KeyboardEvent<HTMLElement>) {
    const options = {
      ArrowDown: (e) => this._moveSelectionDown(),
      ArrowUp: (e) => this._moveSelectionUp(),
      Escape: (e) => this._triggerCloseRequest()
    };

    if (options[e.key]) {
      options[e.key].call(this);
    }

    e.nativeEvent.stopImmediatePropagation(); // essential to prevent jquery events getting triggered: see http://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events
    e.stopPropagation();
    e.preventDefault();
  }

  _triggerCloseRequest() {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  }

  _triggerSelectionMade(key: string, isChecked: boolean, props: OptionsProps) {
    if (this.props.onSelectionMade) {
      this.props.onSelectionMade(key, isChecked);
    }
  }

  _updateFocusIndexBy(delta) {
    const optionNodes = (ReactDOM.findDOMNode(this) as Element).querySelectorAll('.popup-menu-option');
    this._normalizeSelectedBy(delta, optionNodes.length);
    this.setState({ activeIndex: this.selectedIndex }, function () {
      (optionNodes[this.selectedIndex] as HTMLElement).focus();
    });
  }

  _normalizeSelectedBy(delta, numOptions) {
    this.selectedIndex += delta;
    if (this.selectedIndex > numOptions - 1) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex < 0) {
      this.selectedIndex = numOptions - 1;
    }
  }

  _renderOptions() {
    let index = 0;
    return React.Children.map(this.props.children, (c) => {
      let clonedOption = c;

      if ((c as any).type.constructor === Option.constructor) {
        const active = this.state.activeIndex === index;

        clonedOption = React.cloneElement(c as any, {
          active: active,
          index: index,
          _internalFocus: (index) => this.focusOption(index),
          _internalSelect: (key, isChecked, props) => this._triggerSelectionMade(key, isChecked, props)
        });
        index++;
      }
      return clonedOption;
    });
  }

  _getClassName() {
    let cn = this.buildClassName('popup-menu-options');
    cn += ' popup-menu-option--horizontal-' + this.props.horizontalPlacement;
    cn += ' popup-menu-option--vertical-' + this.props.verticalPlacement;
    return cn;
  }

  render() {
    return (
      <div
        id={this.props.id}
        role="menu"
        tabIndex={-1}
        aria-expanded={this.props.active}
        style={{ visibility: this.props.active ? 'visible' : 'hidden' }}
        className={this._getClassName()}
        onKeyDown={(e) => this._handleKeys(e)}
      >
        {this._renderOptions()}
      </div>
    );
  }
}

export type OptionProps = {
  active?: boolean;
  onSelect?: (key: string, hasCheck: boolean, props: OptionProps) => void;
  _internalSelect?: (key: string, hasCheck: boolean, props: OptionProps) => void;
  onDisabledSelect?: () => void;
  disabled?: boolean;
  hasCheck?: boolean;
  defaultChecked?: boolean;
  canCheckOff?: boolean;
  popup?: JSX.Element | null;
  showPopup?: boolean;
  className?: string;
  _internalFocus?: (index: number) => void;
  index?: number;
  children?: React.ReactNode;
  dataKey: string;
};

export type OptionState = {};

export class Option extends React.Component<OptionProps, OptionState> {
  constructor(props: OptionProps) {
    super(props);
  }

  static defaultProps: Partial<OptionProps> = {
    popup: null,
    showPopup: false,
    canCheckOff: true // i.e. checkbox like behaviour (where more than one item can be checked, if false only one item on at a time
  };

  buildClassName(baseName: string) {
    let name = baseName;
    if (this.props.className) {
      name += ' ' + this.props.className;
    }

    return name;
  }

  _triggerDisabledSelect() {
    if (this.props.onDisabledSelect) {
      this.props.onDisabledSelect();
    }
  }

  _triggerOnSelect(key: string) {
    if (this.props.disabled) {
      this._triggerDisabledSelect();
      return;
    }

    if (this.props.onSelect) {
      this.props.onSelect(key, this.props.hasCheck!, this.props);
    }

    this.props._internalSelect!(key, this.props.hasCheck!, this.props);
  }

  _handleKeyUp(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === ' ') {
      this._triggerOnSelect($(e.currentTarget).data('key'));
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  _handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Enter') {
      this._triggerOnSelect($(e.currentTarget).data('key'));
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  _handleClick(e: React.MouseEvent<HTMLElement>) {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    this._triggerOnSelect($(e.currentTarget).data('key'));
  }

  _handleHover(e: React.MouseEvent<HTMLElement>) {
    this.props._internalFocus!(this.props.index!);
  }

  _getClassName() {
    let name = this.buildClassName('popup-menu-option scrolling-menu');
    if (this.props.active) {
      name += ' popup-menu-option--active';
    }
    if (this.props.disabled) {
      name += ' popup-menu-option--disabled';
    }

    if (this.props.hasCheck || (this.props.hasCheck === null && this.props.defaultChecked)) {
      name += ' popup-check-on';
    }

    return name;
  }

  render() {
    let checkHtml: JSX.Element | null = null;
    let popup: JSX.Element | null = null;

    if (this.props.hasCheck || (this.props.hasCheck === null && this.props.defaultChecked)) {
      checkHtml = <i className="tiny material-icons popup-check">check</i>;
    }

    if (this.props.active && this.props.popup) {
      popup = <div className="menu-popup">{this.props.popup}</div>;
    }

    return (
      <div
        onClick={(e) => this._handleClick(e)}
        onKeyUp={(e) => this._handleKeyUp(e)}
        onKeyDown={(e) => this._handleKeyDown(e)}
        onMouseOver={(e) => this._handleHover(e)}
        className={this._getClassName()}
        data-key={this.props.dataKey}
        id={this.props.dataKey}
        aria-disabled={this.props.disabled}
        role="menuitem"
        tabIndex={-1}
      >
        {checkHtml}
        {this.props.children}
        {popup}
      </div>
    );
  }
}
