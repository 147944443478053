import * as React from 'react';
import refFocus from '../../../tinyFacade/refFocus';

interface FocusLoopItem {
  id: string;
  ref: React.RefObject<any>['current'];
  open?: boolean;
  disabled?: boolean;
}

const useFocusLoop = (sections: FocusLoopItem[]) => {
  const [items, setItems] = React.useState<FocusLoopItem[]>(sections);

  React.useEffect(() => {
    const initSection = sections.find((section) => section.open);
    if (initSection) {
      refFocus({
        ref: initSection.ref
      }).catch((e) => console.warn(e));
    }
  }, []);

  const setOpen = (id: string, isOpen: boolean) => {
    const index = findIndex(id);
    if (index !== null) {
      items[index].open = isOpen;
      applyChanges();

      if (isOpen) {
        refFocus({
          ref: items[index].ref
        }).catch((e) => console.warn(e));
      }
    }
  };

  const applyChanges = () => {
    setItems([...items]);
  };

  const isOpen = (id: string): boolean => {
    const index = findIndex(id);
    if (index === null) {
      return false;
    }
    return items[index].open || false;
  };

  const findIndex = (id: string): number | null => {
    const index = items.findIndex((item) => item.id === id);
    return index !== -1 ? index : null;
  };

  const isGoingOutsidePanel = (isShiftKeyPressed: boolean, currentIndex: number): boolean => {
    return (isShiftKeyPressed && currentIndex === 0) || (!isShiftKeyPressed && currentIndex === items.length - 1);
  };

  const focusNext = (id: string, e: React.KeyboardEvent, isShiftKeyPressed = false, skipped = false) => {
    const index = findIndex(id);
    if (index === null) {
      return;
    }
    const newIndex = isShiftKeyPressed ? getPrevIndex(index) : getNextIndex(index);
    if (items[newIndex]) {
      const newItem = items[newIndex];
      const isOpen = newItem.open || false;

      if (!isOpen) {
        setOpen(newItem.id, true);
        e.preventDefault();
      }
      const shouldInterceptFocusEvent = isGoingOutsidePanel(isShiftKeyPressed, index) || skipped || newItem.disabled;
      if (shouldInterceptFocusEvent) {
        e.preventDefault();
      }
      if (newItem.disabled) {
        focusNext(newItem.id, e, isShiftKeyPressed, true);
      } else if (shouldInterceptFocusEvent) {
        refFocus({
          ref: newItem.ref,
          focusImmediately: isOpen
        }).catch(() => focusNext(newItem.id, e, isShiftKeyPressed, true));
      }
    }
  };

  const setDisabled = (id: string, disabled: boolean) => {
    const index = findIndex(id);
    if (index === null) {
      return;
    }
    items[index].disabled = disabled;
    applyChanges();
  };

  const getNextIndex = (index: number): number => {
    return index + 1 < items.length ? index + 1 : 0;
  };

  const getPrevIndex = (index: number): number => {
    return index - 1 < 0 ? items.length - 1 : index - 1;
  };

  return {
    setOpen,
    isOpen,
    focusNext,
    setDisabled
  };
};

export default useFocusLoop;
