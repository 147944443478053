import { exemptStatusCodes, mm } from './base-clients';
import { IAssignedSearchTerm, IConcept, IConceptFamily, ISearchFacetFamily, ISortedFacetFamily, IVariant, IVariantFamily } from 'mm-types';

export type IConceptMap = {
  variantEditable: boolean;
  unitUid: string;
  variants: IVariant[];
  searchTermsTag: IAssignedSearchTerm[];
};

export type IUnitConceptMapResponse = {
  variantFamily: IVariantFamily;
  searchFacets: ISearchFacetFamily;
  conceptTags: IConceptMap[];
};

export type AddRemoveFailedResponse = {
  errors?: {
    code: number;
    message: string;
  }[];
};

/**
 * Removes a concept from a unit
 */
export async function removeConceptFromUnit(indexUid: string, unitUid: string, conceptUid: string) {
  try {
    await mm.delete(`/conceptTags/indexes/${indexUid}/units/${unitUid}?conceptUid=${conceptUid}`);
  } catch (err) {
    // Throw a better error message
    const response: AddRemoveFailedResponse = err.response.data;
    if (response.errors) {
      for (const e of response.errors) {
        throw new Error(e.message);
      }
    }
  }
}

/**
 * Adds a concept to a unit
 */
export async function assignConceptToUnit(indexUid: string, unitUid: string, conceptUid: string) {
  try {
    await mm.post<void>(`/conceptTags/indexes/${indexUid}/units/${unitUid}?conceptUid=${conceptUid}`);
  } catch (err) {
    // Throw a better error message
    const response: AddRemoveFailedResponse = err.response.data;
    if (response.errors) {
      for (const e of response.errors) {
        throw e;
      }
    }
  }
}

/**
 * Gets all concepts of a given family
 */
export async function getConcepts<T extends IConcept>(familyUid: string) {
  const response = await mm.get<{ concepts: T[] }>(`/conceptfamilies/${encodeURIComponent(familyUid)}/concepts`);
  return response.data.concepts;
}

/**
 * Creates a new concept for a given family
 */
export async function createConcept<T extends IConcept>(familyUid: string, concept: T) {
  const response = await mm.post<T>(`/conceptfamilies/${encodeURIComponent(familyUid)}/concepts`, concept, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  return {
    concept: response.data,
    error:
      response.status === 409 && concept.type === 'search-term'
        ? new Error('Tag in use')
        : response.status === 409
        ? new Error('Concept in use')
        : null
  };
}

/**
 * Updates a concept
 */
export async function updateConcept<T extends IConcept>(familyUid: string, concept: T) {
  const response = await mm.put<T>(
    `/conceptfamilies/${encodeURIComponent(familyUid)}/concepts/${encodeURIComponent(concept.uid!)}`,
    concept,
    {
      validateStatus: (status) => exemptStatusCodes(status, [409])
    }
  );

  return {
    concept: response.data,
    error:
      response.status === 409 && concept.type === 'search-term'
        ? new Error('Tag in use')
        : response.status === 409
        ? new Error('Concept in use')
        : null
  };
}

/**
 * Removes a concept from a given family
 */
export async function removeConcept(familyUid: string, conceptUid: string) {
  const response = await mm.delete(`/conceptfamilies/${encodeURIComponent(familyUid)}/concepts/${conceptUid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412])
  });

  return response.status === 412 ? new Error('Concept in use') : null;
}

/**
 * Returns an object that describes the relationship between units and the
 * concepts applied to them
 */
export async function getUnitMap(indexUid: string) {
  const result = await mm.get<IUnitConceptMapResponse>(`/conceptTags/indexes/${indexUid}`);
  return result.data;
}

export async function getUnitMapByUnit(indexUid: string, unitUid: string) {
  const result = await mm.get<IUnitConceptMapResponse>(`/conceptTags/indexes/${indexUid}?unitUid=${unitUid}`);
  return result.data;
}

/**
 * Gets all variable families available to the user
 */
export async function getConceptFamilies(
  retrieveInactiveFamilies: 'true' | 'false' = 'false',
  type: 'search-facet' | 'variant-family' | null = null
) {
  const result = await mm.get<{ families: (IVariantFamily | ISearchFacetFamily)[] }>(
    `/conceptfamilies?includeDeleted=${retrieveInactiveFamilies}&includeInactiveConcepts=true${type ? `&type=${type}` : ''}`
  );

  // Optional data processing can go here - though this would likely move to redux
  const sorted = result.data.families.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return sorted;
}

/**
 * Gets a single concept resource
 */
export async function getConceptFamily<T extends IConceptFamily<IConcept>>(
  id: string,
  retrieveInactiveFamilies: 'true' | 'false' = 'false',
  type?: 'search-facet' | 'variant-family'
) {
  const result = await mm.get<T>(
    `/conceptfamilies/${id}?includeInactiveConcepts=${retrieveInactiveFamilies}&includeDeleted=true${type ? `&type=${type}` : ''}`
  );
  return result.data;
}

export async function getSearchFacets(projectUid?: string | null) {
  const result = await mm.get<{ families: ISortedFacetFamily[] }>(`/searchFacets/${projectUid ? projectUid : ''}`);
  return result.data.families;
}

/**
 * Creates a new concept family
 * @param data The family data we're creating
 */
export async function createFamily<Y extends IConcept, T extends IConceptFamily<Y>>(data: Partial<T>) {
  const result = await mm.post<T>(`/conceptfamilies`, data, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  return {
    family: result.data,
    error: result.status === 409 ? new Error('A family already exists with that name') : undefined
  };
}

/**
 * Updates a concept family
 */
export async function updateFamily<Y extends IConcept, T extends IConceptFamily<Y>>(data: Partial<T>) {
  const result = await mm.put<T>(`/conceptfamilies/${data.uid}`, data, {
    validateStatus: (status) => exemptStatusCodes(status, [409])
  });

  return {
    updatedFamily: result.data,
    error: result.status === 409 ? new Error('A family already exists with that name') : undefined
  };
}

/**
 * Removes a concept family
 */
export async function removeFamily(uid: string) {
  const result = await mm.delete(`/conceptfamilies/${uid}`, {
    validateStatus: (status) => exemptStatusCodes(status, [412])
  });

  return {
    error: result.status === 412 ? new Error('You cannot set a Search Family to Inactive while family is in use') : undefined
  };
}
