import { IDiffProperties } from 'mm-types';
import React from 'react';
import { RaisedButton } from 'material-ui';
import { DiffToggle } from './components/DiffToggle';
import { AttributeActivityDropdown } from './components/AttributeActivityDropdown';

export enum ActivityContainerType {
  ORIGINAL = 'Original',
  UPDATED = 'Updated'
}

export type Props = {
  type: ActivityContainerType;
  hidden?: boolean;
  html: string;
  diffHtml?: string;
  scaleDown: string;
  diffProperties?: IDiffProperties[];
  allowRevertHtml: boolean;
  handleRevert: (type: ActivityContainerType) => void;
  children?: React.ReactNode;
};

const ActivityContainer = (props: Props) => {
  const [html, setHtml] = React.useState<string>(props.html);

  return (
    <div className={`compare-html-outer popup-resize ${props.type == ActivityContainerType.ORIGINAL ? 'before' : 'after'}`}>
      <div className="title">{props.type}</div>
      <div
        className={`compare-html-contents ${props.scaleDown}`}
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
      {!props.hidden && (
        <div className={`actions-container ${props.type == ActivityContainerType.ORIGINAL ? 'revert' : ''}`}>
          <div>
            <DiffToggle
              visible={props.type == ActivityContainerType.UPDATED}
              html={props.html}
              diffHtml={props.diffHtml}
              setHtml={(html: string) => setHtml(html)}
            />
          </div>
          <div>
            <AttributeActivityDropdown
              visible={props.type == ActivityContainerType.UPDATED}
              html={html}
              setHtml={(html: string) => setHtml(html)}
              diffProperties={props.diffProperties}
            />
          </div>
          <div>
            {props.allowRevertHtml && (
              <RaisedButton
                labelPosition="after"
                label="Revert"
                primary={true}
                labelColor="white"
                backgroundColor="#28ace3"
                onClick={() => props.handleRevert(props.type)}
                className="revert-btn"
              >
                <li className="material-icons">reply</li>
              </RaisedButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityContainer;
