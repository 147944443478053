type nonEmptyString = string & { __nonEmptyStr: never };

export type UnitTypes =
  | 'diff'
  | 'list'
  | 'ordered-list' // client abstraction
  | 'unordered-list' // client abstraction
  | 'list-item'
  | 'numbered-list-item' // client abstraction
  | 'unordered-list-item' // client abstraction
  | 'header'
  | 'frontmatter'
  | 'frontmatter_header'
  | 'volume'
  | 'chapter'
  | 'appendix-chapter'
  | 'section'
  | 'heading'
  | 'level1'
  | 'level2'
  | 'level3'
  | 'level4'
  | 'level5'
  | 'level6'
  | 'paragraph'
  | 'measure-unit'
  | 'inline-style'
  | 'collection'
  | 'note'
  | 'warning'
  | 'caution'
  | 'compliance-tag'
  | 'multipartite'
  | 'action'
  | 'hr'
  | 'page-break'
  | 'table'
  | 'video'
  | 'graphic'
  | 'ghost'
  | 'removed'
  | 'header-repeater'
  | 'header-repeater-section'
  | 'header-repeater-level1'
  | 'header-repeater-level2'
  | 'header-repeater-level3'
  | 'header-repeater-level4'
  | 'header-repeater-level5'
  | 'header-repeater-level6'
  | 'chapter-frontmatter'
  | 'compliance-reg-tag'
  | 'effectivity-tag'
  | 'legacy-effectivity-tag'
  | 'pre'
  | 'numbered-list'
  // unit specific hacks
  | 'non-normal-checklist-level1'
  // Not insertable as a unit, needed for some type sanity currently
  // when dealing with callout markup
  | 'InlineDataElement'
  // Might not belong
  | 'tocable'
  | 'structural-placeholder'
  | 'graphref'
  | 'description'
  | 'performance'
  | 'abnormalproc'
  | 'emergencyproc'
  | 'normalproc'
  | 'summaryItem'
  | 'synthesisItem'
  | 'procsynthesis'
  | 'approbation'
  | 'dynamic'
  | 'itemNumber'
  | nonEmptyString;

export enum ElementFamily {
  ACTION = 'Action',
  ECAM = 'InlineDataElement'
}

export enum AbstractElementFamily {
  CHALLENGE = 'Challenge',
  CHALLENGE_RESPONSE = 'ChallengeResponse',
  CHALLENGE_RESPONSE_MESSAGE = 'ChallengeResponseMessage',
  CHALLENGE_PART = 'ChallengePart'
}

export enum AbstractElementDefinitionIds {
  SYS = 'EcamSys',
  TITLE = 'EcamTitle',
  SUBTITLE = 'EcamSubtitle'
}
