import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import clientInterceptor, { combineInterceptors } from './base-clients-interceptors';
import { IServerError } from 'mm-types';

/**
 * Use this function to allow for certain status codes that normally cause an
 * exception in axios to pass.
 * @param status The status to check
 * @param codes The codes to excempt
 */
export function exemptStatusCodes(status: number, codes: number[]) {
  if (codes.indexOf(status) !== -1) {
    return true;
  } else if (status >= 400 && status <= 600) {
    return false;
  }
  return true;
}

export class Cancelled {
  constructor() {}
}

export function isSuccess<T>(response: T | Cancelled): response is T {
  return !(response instanceof Cancelled);
}

export function isCancelled<T>(response: T | Cancelled): response is Cancelled {
  return response instanceof Cancelled;
}

export function getServerErrors(response: AxiosResponse | string): IServerError[] {
  if (typeof response === 'string') {
    return [
      {
        code: 500,
        message: response
      }
    ];
  }
  if (typeof response.data === 'string') {
    return [
      {
        code: 500,
        message: response.data
      }
    ];
  }
  if (response.data.error) {
    return [response.data.error];
  }
  if (response.data.errors && response.data.errors.length) {
    if (typeof response.data.errors[0] === 'string') {
      return [{ code: 500, message: response.data.errors[0] }];
    }
    return response.data.errors;
  }
  return [];
}

export function cancelAndCreateNewCancelToken(token: CancelTokenSource | null) {
  if (token) {
    token.cancel();
  }
  return axios.CancelToken.source();
}

export class ClientError {
  public status: number;
  public message: string;
  public data: any;
  constructor(status: number, message: string, data?: any) {
    this.message = message;
    this.status = status;
    this.data = data;
  }
}

/**
 * The main http client for dealing with the mm API
 */
const { gebHelper, mockResponse, cache, errorHandler } = clientInterceptor;
const allInterceptors = combineInterceptors(gebHelper, mockResponse, cache, errorHandler);

export const mm = allInterceptors(
  axios.create({
    baseURL: '/api',
    headers: {
      Accept: 'application/vnd.arconics.mm.v3.0+json',
      'Content-Type': 'application/vnd.arconics.mm.v3.0+json'
    }
  })
);

/**
 * The client for account
 */
export const accounts = allInterceptors(
  axios.create({
    baseURL: '/accounts/api',
    headers: {
      Accept: 'application/vnd.arconics.acs.v1.0+json',
      'Content-Type': 'application/vnd.arconics.acs.v1.0+json'
    }
  })
);

/**
 * The client for aerosyn
 */
export const aerosync = allInterceptors(
  axios.create({
    baseURL: '/aerosync/api',
    headers: {
      Accept: 'application/vnd.arconics.acs.v2.0+json',
      'Content-Type': 'application/vnd.arconics.acs.v2.0+json'
    }
  })
);

export function setVariantHeader(variantUid: string) {
  mm.defaults.headers.common = {
    ...mm.defaults.headers.common,
    'X-Index-Variant': variantUid
  };
}

export function removeVariantHeader() {
  delete mm.defaults.headers.common['X-Index-Variant'];
}
