import * as _ from 'lodash';

export interface QueryParams {
  [prop: string]: string | undefined;
}

class QueryUtil {
  static fromString(url: string): QueryParams {
    const urlParams: { [param: string]: string } = {};
    if (!url) {
      return urlParams;
    }
    let queryString = url.split('?')[1];

    if (!queryString) {
      if (url.search('=') !== -1) {
        queryString = url;
      }
    }
    if (queryString) {
      const keyValuePairs = queryString.split('&');
      for (let i = 0; i < keyValuePairs.length; i++) {
        const keyValuePair = keyValuePairs[i].split('=');
        const paramName = keyValuePair[0];
        const paramValue = keyValuePair[1] || '';
        urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
      }
    }
    return urlParams;
  }

  static fromObject(queryParams: QueryParams, queryPrefix = '?'): string {
    return _.reduce(queryParams, (result, value, key) => (result += value ? (!result ? queryPrefix : '&') + key + '=' + value : ''), '');
  }

  static getUrlWithParams(url: string, queryParams?: QueryParams): string {
    return url + this.fromObject(queryParams || {}, this.hasParams(url) ? '&' : '?');
  }

  static hasParams(url: string): boolean {
    return url.indexOf('?') !== -1;
  }

  static getParam(name: string, checkPresence = false): string | undefined {
    const n = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${n}(=([^&#]*)|&|#|$)`);
    const url = window.location.href;
    const results = regex.exec(url);
    if (!results) {
      return undefined;
    }
    if (checkPresence) {
      return 'true';
    }
    if (!results[2]) {
      return undefined;
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}

export default QueryUtil;
