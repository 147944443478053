import * as React from 'react';
import * as _ from 'lodash';
import { ForeignEntryForm, ForeignEntryFormProps } from './ForeignEntryForm';

import withDialog from '../../../../../../hoc/withDialog';
import { ForeignEntryValues } from '../..';
import { AeroCancelButton, AeroConfirmButton } from '../../../../../../general/AeroButton';
import { CountSelected } from './CountSelected';

const ForeignEntryFormDialog = React.memo(withDialog<ForeignEntryFormProps>(ForeignEntryForm));

interface Props extends Omit<ForeignEntryFormProps, 'onCheck'> {
  show: boolean;
  onCancel();
  onConfirm(selected: ForeignEntryValues);
}

export function ForeignEntryDialog({ tocs, selectedTocs, onConfirm, onCancel, show }: Props) {
  const [selected, setSelected] = React.useState<ForeignEntryValues>(selectedTocs);
  const isEdit = !!selectedTocs.length;

  function onCheck(uid: string, checked: boolean) {
    if (checked) {
      setSelected([...selected, { uid }]);
    } else {
      const s = [...selected];
      _.remove(s, function (value) {
        return value.uid === uid;
      });
      setSelected(s);
    }
  }

  const cancel = React.useCallback(() => {
    setSelected([...selectedTocs]);
    onCancel();
  }, [selectedTocs, setSelected, onCancel]);

  const save = React.useCallback(() => {
    onConfirm(selected);
  }, [selected, selectedTocs, onConfirm]);

  return (
    <ForeignEntryFormDialog
      show={show}
      title="NNC Sections"
      onRequestClose={cancel}
      tocs={tocs}
      selectedTocs={selected}
      onCheck={onCheck}
      actions={[
        <CountSelected count={selected.length} className="float-left padding-top-m" key="CountSelected" />,
        <AeroCancelButton label="Cancel" data-qa="dialog-action-cancel" onClick={cancel} key="CancelButton" />,
        <AeroConfirmButton
          label={isEdit ? 'Save' : 'Add'}
          data-qa="dialog-action-submit"
          disabled={!isEdit && !selected.length}
          onClick={save}
          key="SaveButton"
        />
      ]}
    />
  );
}
