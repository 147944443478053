import { ElementFamily, UnitTypes } from '../../units/UnitTypes';
import { AIRBUS_INLINE_ELEMENT, ElementTypes } from '../../units/ElementTypes';
import * as UnitKeyBehaviors from './UnitKeyBehaviors';
import * as CONSTANTS from '../../keyIdentifier';
import { CustomEditor } from '../EditorInstanceManager';
import { unitKeyBehavior as nncLevel1KeyBehavior } from '../../units/unit/non-normal-checklist-level1/unitKeyBehavior';
import { NNCChecklistAsserts } from '../../units/unit/non-normal-checklist-level1/asserts';
import { EcamAsserts, MeasureAsserts } from '../../units/unit/ecam/asserts';
import { UNITS } from '../../units';
import { TinyPastePreProcessEvent } from 'mm-types';
import { keyBehavior as collectedElementKeyBehaviour } from '../../units/unit/collectedElementKeyBehaviour';
import { SummaryItemAsserts } from '../../units/unit/tocable/asserts';

type KeyProfile = {
  [key in UnitTypes | ElementTypes]: {
    beforeCommonKeys?: (editor, e) => void;
    afterCommonKeys?: (editor, e) => void;
    onKeyUp?: (editor, e) => void;
    onPastePreProcess?: (editor, e: TinyPastePreProcessEvent) => void;
  };
};

/**
    DOCUNIT SPECIFIC KEY LISTENERS
    If you require custom key detection for a specific root unit, add an entry to _unitSpecificKeyDetection
*/
// Note: if you stop an event here that is defined in a beforeCommonKeys, it will not be made available to any subsequent CommonKeyListeners handler

const _unitSpecificKeyDetection: Partial<KeyProfile> = {
  action: {
    beforeCommonKeys: function (editor, e) {
      UNITS.action.keyBehavior.beforeCommonKeys(editor, e, ElementFamily.ACTION);
    },
    onPastePreProcess: function (editor, e) {
      UNITS.action.keyBehavior.onPastePreProcess(editor, e);
    }
  },
  graphic: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageGraphicKeys(editor, e);
    }
  },

  video: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageVideoKeys(editor, e);
    }
  },

  chapter: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  section: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  heading: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level1: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level2: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level3: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level4: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level5: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  level6: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageChapterKeys(editor, e);
    }
  },

  table: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageTableKeys(editor, e);
    }
  },

  collection: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageCollectionKeys(editor, e);
    }
  },

  note: {
    afterCommonKeys: function (editor, e) {
      if (!UnitKeyBehaviors.deleteRootUnitWithDelKeys(editor, e)) {
        if (!UnitKeyBehaviors.mergeUnitWithShiftBackspace(editor, e)) {
          UnitKeyBehaviors.deleteAttentionNode(editor, e);
        }
      }
    }
  },

  warning: {
    afterCommonKeys: function (editor, e) {
      if (!UnitKeyBehaviors.deleteRootUnitWithDelKeys(editor, e)) {
        if (!UnitKeyBehaviors.mergeUnitWithShiftBackspace(editor, e)) {
          UnitKeyBehaviors.deleteAttentionNode(editor, e);
        }
      }
    }
  },

  caution: {
    afterCommonKeys: function (editor, e) {
      if (!UnitKeyBehaviors.deleteRootUnitWithDelKeys(editor, e)) {
        if (!UnitKeyBehaviors.mergeUnitWithShiftBackspace(editor, e)) {
          UnitKeyBehaviors.deleteAttentionNode(editor, e);
        }
      }
    }
  },

  'non-normal-checklist-level1': nncLevel1KeyBehavior,

  'compliance-tag': {
    afterCommonKeys: function (editor, e) {
      CONSTANTS.nukePropagation(e);
    }
  },

  'legacy-effectivity-tag': {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageInlineKeys(editor, e);
    }
  },

  InlineDataElement: {
    beforeCommonKeys: function (editor: CustomEditor, e: Event) {
      if (EcamAsserts.isEcamElement(editor?.selection?.getNode() as HTMLElement)) {
        UnitKeyBehaviors.manageInlineKeys(editor, e, true);
      } else if (MeasureAsserts.isMeasureElement(editor?.selection?.getNode() as HTMLElement)) {
        UnitKeyBehaviors.manageInlineKeys(editor, e, true, 0);
      }
    },
    afterCommonKeys: function (editor: CustomEditor, e) {
      if (
        EcamAsserts.isEcamElement(editor?.selection?.getNode() as HTMLElement) ||
        MeasureAsserts.isMeasureElement(editor?.selection?.getNode() as HTMLElement)
      ) {
        collectedElementKeyBehaviour.beforeCommonKeys(editor, e, ElementFamily.ECAM);
      } else if (SummaryItemAsserts.isSummaryItem(editor?.selection?.getNode() as HTMLElement)) {
        UnitKeyBehaviors.manageSummaryItemInlineKeys(editor, e);
      } else {
        UnitKeyBehaviors.manageInlineKeys(editor, e);
      }
    }
  },
  tocable: {
    beforeCommonKeys: function (editor: CustomEditor, e: Event) {
      if (NNCChecklistAsserts.hasIndexMetadata(e.target as Element)) {
        nncLevel1KeyBehavior.beforeCommonKeys(editor, e);
      }
    }
  },
  list: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageListKeys(editor, e);
    },
    onKeyUp: function (editor, e) {
      UnitKeyBehaviors.manageListKeysOnKeyUp(editor, e);
    }
  },
  paragraph: {
    beforeCommonKeys: function (editor, e) {
      UnitKeyBehaviors.handleInlineDataMeasureDelete(editor, e);
      UnitKeyBehaviors.manageMelCdlItemNumberKeys(editor, e);
      UnitKeyBehaviors.manageParagraphKeys(editor, e);
    },
    afterCommonKeys: function (editor, e) {
      if (!UnitKeyBehaviors.manageLinkKeys(editor, e)) {
        UnitKeyBehaviors.mergeUnitWithShiftBackspace(editor, e);
      }
    }
  },
  pre: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.mergeUnitWithShiftBackspace(editor, e);
    }
  },
  link: {
    afterCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageDuRefLinkKeys(editor, e);
    }
  }
};

AIRBUS_INLINE_ELEMENT.map((type: ElementTypes) => {
  _unitSpecificKeyDetection[type] = {
    beforeCommonKeys: function (editor, e) {
      UnitKeyBehaviors.manageInlineKeys(editor, e);
    }
  };
});

export function detectBeforeCommonKeys(editor: CustomEditor, e) {
  const detector = _unitSpecificKeyDetection[editor.arconicsUnitType];
  if (detector && detector.beforeCommonKeys) {
    detector.beforeCommonKeys(editor, e);
  }
}

export function detectAfterCommonKeys(editor: CustomEditor, e) {
  const detector = _unitSpecificKeyDetection[editor.arconicsUnitType];
  if (detector && detector.afterCommonKeys) {
    detector.afterCommonKeys(editor, e);
  }
}

export function detectOnKeyUp(editor: CustomEditor, e) {
  const detector = _unitSpecificKeyDetection[editor.arconicsUnitType];
  if (detector && detector.onKeyUp) {
    detector.onKeyUp(editor, e);
  }
}
export function detectOnPastePreProcess(editor: CustomEditor, e: TinyPastePreProcessEvent) {
  const detector = _unitSpecificKeyDetection[editor.arconicsUnitType];
  if (detector && detector.onPastePreProcess) {
    detector.onPastePreProcess(editor, e);
  }
}
