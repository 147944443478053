import { findElementInHtml } from '../../../../utils/ElementUtils';
import { Dom } from '../../utils/tinyFacade/DomUtil';
import MediaStore from '../../../../flux/editor/MediaStore';
import { MediaInsertUtils } from '../../medialib/utils/mediaInsertUtils';
import EditorStore from '../../../../flux/editor/EditorStore';
import SmartContentStore from '../../../../flux/editor/SmartContentStore';
import { IUnit } from 'mm-types';
import { DocUnitProps, PermissionsOverride } from '../DocUnit';
import ProjectStore from '../../../../flux/editor/ProjectStore';
import LinkStore from '../../../../flux/editor/LinkStore';
import { replaceZeroLengthZeroWidthCharacters } from '../../utils/tinyFacade/key_listeners/keyBehaviourUtils';

export namespace DocUnitUtils {
  export const classContainsMediaClasses = (classList: string): boolean => {
    let classListContainsMediaClass = false;
    const mediaClasses = ['arc-graphic', 'arc-media-file', 'arc-video'];
    mediaClasses.forEach((mediaClass) => {
      if (classList.includes(mediaClass)) {
        classListContainsMediaClass = true;
      }
    });
    return classListContainsMediaClass;
  };

  export const triggerOpenMediaLibModal = (target: HTMLElement) => {
    const type = MediaInsertUtils.getCurrentInsertPointType(target);
    MediaStore.triggerOpenMediaLib(false, {
      inline: true,
      insertPoint: MediaInsertUtils.insertPointTarget(target),
      insertPosition: 'insert_inside',
      type,
      selectedMediaItemUid: MediaInsertUtils.getCurrentMediaDataUid(target)
    });
  };

  export const canTriggerMediaLibModalOnElmDoubleClick = (
    target: HTMLElement,
    targetNid: string | null,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    let targetElement = Array.from(target.classList).indexOf('file-icon') >= 0 ? (target.parentElement as HTMLElement) : target;

    return (
      EditorStore.isEditorFocused() &&
      DocUnitUtils.getTargetNidInSelectedUnit(targetNid) &&
      e.detail === 2 &&
      DocUnitUtils.classContainsMediaClasses(`${targetElement.className} ${targetElement.parentElement?.className}`)
    );
  };

  export const getTargetNidInSelectedUnit = (targetNid: string | null) => {
    const currentFocusedElm = EditorStore.getFocusedUnitElement();
    const currentSelectedUnit = Dom.closestElement(currentFocusedElm, `.arc-unit`);
    const targetInSelectedUnit = findElementInHtml(currentSelectedUnit?.innerHTML ?? '', `[data-nid="${targetNid}"]`);
    return targetInSelectedUnit;
  };

  export const getUpdatedSharedUsage = (unit: IUnit) => {
    // important: this is potentially expensive call, so only if a share ref, check to see if that share ref is "updated"
    // no point making more efficient for now as will be called rarely
    if (
      unit.shareDetails &&
      !unit.shareDetails.origin &&
      (unit.shareDetails.updateStrategy === 'MANUAL' || unit.shareDetails.updateStrategy === 'NONE')
    ) {
      return SmartContentStore.getUsage(unit.shareDetails.shareStartUnitUid, unit.shareDetails.sharedIndexUid);
    } else {
      return null;
    }
  };

  export const getUnitPermissions = (props: DocUnitProps) => {
    let permissions: Partial<PermissionsOverride> = {
      isChangeTasksVisible: false,
      isUnitCommentable: false,
      isUnitDeletable: false,
      isUnitInsertableAfter: false,
      isUnitEditable: false,
      isShareActionAllowed: false
    };
    const project = ProjectStore.getProject();

    if (!!project) {
      const unit = props.unit;
      const userPermissions = project.currentUserPermissions;
      const isDocReadOnly = props.readOnlyOverride !== null ? props.readOnlyOverride : props.isDocReadOnly;
      const isEditable = (userPermissions.canAuthor || userPermissions.canManageComplianceTags) && !isDocReadOnly;
      const isShareOriginOnBoundary =
        unit.shareDetails && unit.shareDetails.origin && (unit.shareDetails.isShareStartUnit || unit.shareDetails.isShareEndUnit);
      const isShareUsageReadonly = unit.shareDetails && !unit.shareDetails.origin && unit.shareDetails.updateStrategy !== 'NONE';
      const isShareUsageEndUnit = unit.shareDetails && !unit.shareDetails.origin && unit.shareDetails.isShareEndUnit;
      permissions = {
        isChangeTasksVisible: true,
        isUnitCommentable: ProjectStore.canReadComment(),
        isUnitDeletable: !isShareUsageReadonly && !isShareOriginOnBoundary && isEditable && EditorStore.canDeleteUnit(props.unit),
        isUnitInsertableAfter: (!isShareUsageReadonly || isShareUsageEndUnit) && isEditable,
        isUnitEditable: !isShareUsageReadonly && isEditable,
        isShareActionAllowed: userPermissions.canManageShares
      };

      // TODO overrides used by powerpaste and merge - could be more fine grained but will do for our needs now...
      if (props.permissionsOverride) {
        permissions = { ...permissions, ...props.permissionsOverride };
      }
    }

    return permissions;
  };

  export const handleArcLink = ($linkNode: JQuery<HTMLElement>) => {
    const targetData = $linkNode.data();
    if (targetData.targetUnitUid) {
      if (targetData.type === 'inter-doc') {
        const links = LinkStore.state.links;
        if (links.length !== 0) {
          const link = links.find((link) => link.source.nid === targetData.nid);
          if (link && link.interDocLinkTarget.indexUid) {
            EditorStore.openAnotherDocument(
              targetData.targetProjectUid,
              targetData.targetUnitUid,
              targetData.targetElementNid,
              false,
              link.interDocLinkTarget.indexUid
            );
          } else {
            EditorStore.openAnotherDocument(targetData.targetProjectUid, targetData.targetUnitUid, targetData.targetElementNid);
          }
        }
        EditorStore.openAnotherDocument(targetData.targetProjectUid, targetData.targetUnitUid, targetData.targetElementNid);
      } else {
        EditorStore.openDocumentLink(targetData.targetUnitUid, targetData.targetElementNid);
      }
    } else {
      window.open($linkNode.attr('href'), '_blank');
    }
  };

  export const updateEcamSubtitle = (htmlElement: HTMLDivElement | null) => {
    if (htmlElement) {
      const elements: HTMLElement[] = Array.from(htmlElement.querySelectorAll('.arc-ecam-subtitle')) as HTMLElement[];
      elements.map((element: HTMLElement) => {
        if (!element.querySelector('span.ecam-bracket')) {
          element.innerHTML = `<span class="ecam-bracket nested-wc"></span>&NoBreak;` + replaceZeroLengthZeroWidthCharacters(element);
        }
      });
    }
  };
}
