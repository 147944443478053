import React, { useState } from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import RevBar from './RevBar';
import { IUnitHighlight, IUnit } from 'mm-types';
import UnitHighlightStore, { UnitHighlightStoreEvent } from '../../../flux/editor/UnitHighlightStore';
import useListenToStore from '../../hooks/useListenToStore';
import { DocUnitWrapper } from '../utils/tinyFacade/DocUnitWrapper';

interface Props {
  unit: IUnit;
  highlight: IUnitHighlight | null;
  diffUnitsUids?: string[];
}

function isDiffUnitNotInsideOrigin(unit: IUnit, diffUnitsUids?: string[]): boolean {
  if (diffUnitsUids) {
    return (unit.definitionId === 'diff' || unit.type === 'diff') && diffUnitsUids.indexOf(unit.uid) === -1;
  }
  return true;
}

const RevBarContainer = ({ unit, highlight, diffUnitsUids }: Props) => {
  const [currentHighlight, setCurrentHighlight] = useState(highlight);
  useListenToStore({
    store: UnitHighlightStore,
    eventListener: (e: UnitHighlightStoreEvent) => {
      if (e.type === 'retrieve-toc-highlight' && e.state.allHighlights) {
        const hl = e.state.allHighlights.find((hl) => {
          return hl.unitUid === unit.uid;
        });
        const uhl =
          hl &&
          ({
            ignored: hl.ignored,
            elementHighlights: hl.elementHighlights,
            ...(hl.type && { type: hl.type })
          } as IUnitHighlight);
        if (uhl) {
          setCurrentHighlight(uhl);
        }
      }
    }
  });

  if (!highlight && !currentHighlight) {
    return null;
  }

  const unitModel: DocUnitWrapper | undefined = unit.type && EditorStore.getDocUnitModel(unit.uid);
  const isNotDiffUnit = unitModel?.unit.definitionId !== 'diff' && unitModel?.unit.type !== 'diff';
  if (unitModel && (isNotDiffUnit || isDiffUnitNotInsideOrigin(unitModel.unit, diffUnitsUids))) {
    return <RevBar highlight={(currentHighlight ?? highlight)!} unit={unitModel.unit} key={unitModel.unit.uid} />;
  }

  return null;
};

export default RevBarContainer;
