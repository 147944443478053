import React from 'react';
import { IDiffProperties } from 'mm-types';
import { DiffPropertyActivityType } from './AttributeActivityDropdown';

export type Props = {
  diffProperty?: IDiffProperties;
  style?: React.CSSProperties;
};

export const PrimaryTextRenderer = (props: Props) => {
  const { diffProperty, style } = props;
  if (!diffProperty) {
    return <span className="diff-properties-clear-all">Clear Selection</span>;
  }

  const getDiffPropertyActivityType = (
    oldValue?: string,
    newValue?: string
  ): {
    diffPropertyActivityType: DiffPropertyActivityType;
    icon: string;
  } => {
    if (!oldValue) {
      return { diffPropertyActivityType: DiffPropertyActivityType.ADDED, icon: 'create' };
    } else if (!newValue) {
      return { diffPropertyActivityType: DiffPropertyActivityType.REMOVED, icon: 'delete' };
    }
    return { diffPropertyActivityType: DiffPropertyActivityType.CHANGED, icon: 'update' };
  };

  const { diffPropertyActivityType, icon } = getDiffPropertyActivityType(diffProperty?.oldValue, diffProperty?.newValue);

  return (
    <div className="diff-props-primary-text font-s" style={{ ...style }}>
      <div>
        <span className="icon-activity-outer" style={{ left: '15px' }}>
          <span className={`icon-activity icon-activity-${icon}`} />
        </span>
        <span className="diff-props-property-name">{diffProperty?.propertyName}</span>{' '}
        <span className="diff-props-activity-type">{diffPropertyActivityType}</span>
      </div>
      <div className="diff-props-value">
        <span>{diffProperty?.oldValue ?? ''}</span>
        {diffProperty?.oldValue && diffProperty?.newValue && ' changed to '}
        <span>{diffProperty?.newValue ?? ''}</span>
      </div>
    </div>
  );
};
