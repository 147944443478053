import React from 'react';
import { RaisedButton } from 'material-ui';
import { MenuElement, MenuItemSpace } from '../../EditorMenu/MenuElements';

interface Props {
  doesModeAllowWorkflow: boolean;
  isInitializing: boolean;
  isUserWorkflowAdmin: boolean;
  canStartRevision: boolean;
  onStartWorkflow(): void;
}
export function PreWorkFlowStage(props: Props) {
  return (
    <>
      <MenuItemSpace onRight={true} exportButtons={true} />

      {!props.isInitializing && props.doesModeAllowWorkflow && props.isUserWorkflowAdmin && (
        <MenuElement onRight={true} exportButtons={true} dataQa="menu-element-start-revision">
          <RaisedButton
            id={'workflow-start-revision-button'}
            label="Start Revision"
            primary={true}
            backgroundColor="#009688"
            onClick={props.onStartWorkflow}
            disabled={!props.canStartRevision}
            style={{ display: 'inline-flex' }}
          >
            <li className="material-icons">done</li>
          </RaisedButton>
        </MenuElement>
      )}
      {!props.isUserWorkflowAdmin && (
        <MenuElement onRight={true} exportButtons={true} dataQa="menu-element-workflow-not-started">
          Workflow not started.
        </MenuElement>
      )}
    </>
  );
}
