import React from 'react';
import { IUserPermissions, IUser } from 'mm-types';
import { Grid } from '../../../general/layouts/Grid';
import { styleMap } from '../../../../styles/styleGuard';

export type ActiveEditorType = {
  avatarUrl: string;
  displayName?: string;
  user: IUser;
  activeSinceFormattedAgo: string;
  permissions: IUserPermissions;
};

const styles = styleMap({
  grid: {
    margin: 8
  },
  userName: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1rem',
    marginTop: 6,
    marginBottom: 0
  },
  role: {
    fontSize: '1rem',
    lineHeight: '20px',
    color: '#555555',
    margin: 0
  },
  avatar: {
    display: 'inline-block',
    height: 38,
    width: 38,
    borderRadius: '50%',
    border: 'solid 1px rgba(0, 0, 0, 0.08)'
  },
  lastActive: {
    fontSize: '0.8rem',
    color: '#979797',
    textAlign: 'right',
    width: '100%',
    marginTop: 6
  }
});

export const ActiveEditorDetails = ({ activeEditor }: { activeEditor: ActiveEditorType }) => {
  return (
    <Grid templateColumns="48px 1fr 1fr" gap="8px" style={styles.grid} dataQa="active-editor">
      <div data-qa="avatar">
        <img className="popup-avatar" style={styles.avatar} src={activeEditor.avatarUrl} />
      </div>
      <div className="popup-info-container" data-qa="info">
        <h1 style={styles.userName}>{activeEditor.user.displayName}</h1>
        <h5 style={styles.role}>
          {activeEditor.permissions && (activeEditor.permissions.canAuthor || activeEditor.permissions.canManageComplianceTags)
            ? 'Author'
            : 'Reviewer'}
        </h5>
      </div>
      <div className="popup-date-container" data-qa="date">
        <h5 style={styles.lastActive}>Last active {activeEditor.activeSinceFormattedAgo}</h5>
      </div>
    </Grid>
  );
};
