import * as _ from 'lodash';
/* FRAMEWORK */
import * as React from 'react';
/* STORES */
import ProjectDefinitionStore, { UserCreatableType } from '../../flux/common/ProjectDefinitionStore';
/* HOC */
/* LOCAL DEPS */
import { InsertRulesFactory } from '../editor/menus/insert/utils/InsertRulesFactory';
import { getShortcutIconsWithRulesApplied } from '../editor/menus/insert/components/ShortcutIconsFactory';
import EditorStore from '../../flux/editor/EditorStore';
import { ElementMeta, IElementDefinition, IInsertMenuShortCutItems, IInsertRules, InsertRulesMeta, IUnitDefinition } from 'mm-types';
import { WithSelectedUnitProps, WithSelectedUnitState } from './withSelectedUnit';

export type WithInsertRulesProps = {
  rules: IInsertRules;
  shortcutIcons: IInsertMenuShortCutItems;
  contentMenuUnits: IUnitDefinition[];
  contentMenuElements: IElementDefinition[];
  structuralMenuItems: IUnitDefinition[];
  isElementInsertable(
    elementId: string,
    insertAtLevel: number
  ): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null };
  unionRootWhitelists: Set<string>;
};

const withRules = (Wrapped: React.ComponentClass<WithInsertRulesProps>) =>
  class WithInsertRules extends React.Component<WithSelectedUnitProps & WithSelectedUnitState, {}> {
    render() {
      const rules = InsertRulesFactory(this.props);

      const [structuralUnitDefinitions, contentUnitDefinitions] = getMenuItems();

      const contentMenuElementDefinitions = ProjectDefinitionStore.getContentMenuElements();

      const insertRulesMeta: InsertRulesMeta = rules.currentlyInsertableElements(this.props.editingNestedChange);

      const isElementInsertable = getElementIsInsertable(insertRulesMeta, this.props.dtdValidatedElements);

      const shortcutIcons = getShortcutIconsWithRulesApplied(this.props, rules, isElementInsertable);

      return (
        <Wrapped
          rules={rules}
          shortcutIcons={shortcutIcons}
          structuralMenuItems={structuralUnitDefinitions}
          contentMenuUnits={contentUnitDefinitions}
          contentMenuElements={contentMenuElementDefinitions}
          unionRootWhitelists={insertRulesMeta.unionRootWhitelists}
          isElementInsertable={isElementInsertable}
          {...this.props}
        />
      );
    }
  };

export const getElementIsInsertable = (insertRulesMeta: InsertRulesMeta, dtdValidatedElements: string[] | null) => (
  elementId: string,
  insertAtLevel: number
): { disabled: boolean; insertElement: HTMLElement | null; insertElementDirectChild: HTMLElement | null } => {
  let permittingElement: HTMLElement | null = null,
    childElement: HTMLElement | null = null;

  // If there is less elements in the nested tree than levels specified in the insertAtLevel then there's no point in going forward.
  // Most probably user wants to insert an element on the highest level which we don't allow for. Only one root elements are allowed.
  if (insertRulesMeta.tree.length <= insertAtLevel) {
    return {
      disabled: true,
      insertElement: null,
      insertElementDirectChild: null
    };
  }

  const treeAsc = [...insertRulesMeta.tree].reverse();

  let isExpectedParent = true; // setting this to true by default so that if there are no expected parents then all are permitted

  const insertableElementDefinition = ProjectDefinitionStore.getElementDefinitionById(elementId);
  const expectedParents = insertableElementDefinition && insertableElementDefinition.expectedParents;
  if (treeAsc.length && expectedParents && expectedParents.length) {
    const { elementDefinition } = treeAsc[insertAtLevel];
    if (elementDefinition) {
      const parentElementId = elementDefinition.id;
      isExpectedParent = _.indexOf(expectedParents, parentElementId) !== -1;
    }
  }

  const canInsert: boolean =
    isExpectedParent &&
    checkIfCanInsert(elementId, treeAsc[insertAtLevel]) &&
    (!!dtdValidatedElements ? dtdValidatedElements.indexOf(elementId) !== -1 : true);

  if (canInsert) {
    permittingElement = treeAsc[insertAtLevel].profile.targetElement;
    childElement = insertAtLevel > 0 ? treeAsc[insertAtLevel - 1].profile.targetElement : null;
  }

  return {
    disabled: permittingElement === null,
    insertElement: permittingElement,
    insertElementDirectChild: childElement
  };
};

const checkIfCanInsert = (elementId: string, elementMeta: ElementMeta) => {
  let enableCurrentElement = true;
  if (elementId === 'TableRefInt') {
    enableCurrentElement = checkIfFootnotesPresent();
  }
  // Incoming element it insertable here if it is either:
  return checkWhitelistElements(elementId, elementMeta) && checkBlacklistElements(elementId, elementMeta) && enableCurrentElement;
};

const checkIfFootnotesPresent = (): boolean => {
  let footnotesPresent = false;
  let focusedUnitElement = EditorStore.getFocusedUnitElement();
  if (focusedUnitElement) {
    let tableCollectionElement = focusedUnitElement.closest('[data-element-definition-id="TableCollection"]');
    if (tableCollectionElement) {
      return !!$(tableCollectionElement).find('[data-element-definition-id="Footnote"]').length;
    }
  }
  return footnotesPresent;
};

const checkWhitelistElements = (elementId: string, elementMeta: ElementMeta) => {
  if (elementMeta && _.has(elementMeta, 'intersectionElementWhitelists') && _.has(elementMeta, 'elementDefinition.rootElementWhitelist')) {
    const { elementDefinition, intersectionElementWhitelists } = elementMeta!;
    const { rootElementWhitelist } = elementDefinition!;

    // elementWhitelist intersection at this level is empty and element's rootElementWhitelist allows to insert
    if (intersectionElementWhitelists.size === 0 && rootElementWhitelist!.length > 0 && rootElementWhitelist!.indexOf(elementId) > -1) {
      return true;
    }

    // rootElementWhitelist is empty and elementWhitelist intersection at this level allows to insert
    if (rootElementWhitelist!.length === 0 && intersectionElementWhitelists.size > 0 && intersectionElementWhitelists.has(elementId)) {
      return true;
    }

    // Or this element has no elementWhitelist, no intersectionElementWhitelists and no rootElementWhitelist -> it is completely open.
    if (
      !elementDefinition.elementWhitelist ||
      (elementDefinition.elementWhitelist!.length === 0 && intersectionElementWhitelists.size === 0 && rootElementWhitelist!.length === 0)
    ) {
      return true;
    }

    // Expressly permitted by the elementWhitelist & rootElementWhitelist
    if (
      intersectionElementWhitelists.size > 0 &&
      intersectionElementWhitelists.has(elementId) &&
      rootElementWhitelist!.length &&
      rootElementWhitelist!.indexOf(elementId) > -1
    ) {
      return true;
    }
  }
  // Otherwise it cannot be inserted here.
  return false;
};

const checkBlacklistElements = (elementId: string, elementMeta: ElementMeta) => {
  if (!elementMeta) {
    return false;
  }

  let aggregationElementBlacklists = new Set<string>();
  let rootElementBlacklist = new Set<string>();

  if (_.has(elementMeta, 'aggregationElementBlacklists')) {
    aggregationElementBlacklists = elementMeta.aggregationElementBlacklists;
  }
  if (_.has(elementMeta, 'elementDefinition.rootBlacklist')) {
    const { elementDefinition } = elementMeta!;
    rootElementBlacklist = new Set<string>(elementDefinition.rootElementBlacklist);
  }

  return !aggregationElementBlacklists.has(elementId) && !rootElementBlacklist.has(elementId);
};

const getMenuItems = () => {
  const allUnitDefinitions = ProjectDefinitionStore.getAllUnitDefinitions();
  const structuralDefinitions: IUnitDefinition[] = [];
  const contentDefinitions: IUnitDefinition[] = [];

  allUnitDefinitions.forEach((ud) => {
    if (ud.userCreatable !== UserCreatableType.NEVER && ['ghost', 'removed'].indexOf(ud.type) === -1) {
      if (ud.structural && ud.type === 'tocable') {
        structuralDefinitions.push(ud);
      } else if (!ud.structural) {
        contentDefinitions.push(ud);
      }
    }
  });

  return [structuralDefinitions, contentDefinitions];
};

const withInsertRules = (componentToWrap) => withRules(componentToWrap);

export default withInsertRules;
