import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import LookupStore from '../../../../../flux/common/LookupStore';
import ChangeTaskReasonStore from '../../../../../flux/settings/ChangeTaskReasonStore';
import ChangeTasksStore, { ChangeTasksStoreEvent } from '../../../../../flux/editor/ChangeTasksStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import UserList from './UserList';
import ColorSelector from '../../../../general/color-selector/ColorSelector';
import ConfirmationOverlay from '../../../../general/ConfirmationOverlay';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import { FlatButton, MenuItem, SelectField, TextField, Toggle } from 'material-ui';
import { ITask, IUser, TaskAuthor, TaskAuthorComponent } from 'mm-types';
import ActiveUserStore from '../../../../../flux/common/ActiveUserStore';
import appStore from '../../../../../appStore';
import { dateUtil } from '../../../../../utils';

export type Props = {
  zIndex: number;
  task?: Partial<ITask>;
  editPermission: string;
  onRequestOpenChange: (e: { requestToOpen: boolean; taskUid: string }) => void;
  isOpened: boolean;
  isNewTask?: boolean;
};

export type State = {
  showDeleteConfirm?: boolean;
  form: Partial<ITask>;
  authors?: TaskAuthor[];
  reviewers?: TaskAuthor[];
  errors: {
    description?: string;
    title: string | null;
    identifier?: string;
    reasonUid: string | null;
    safetyParamCode: string | null;
    effort: string | null;
    riskAssessment?: string | null;
  };
};

export default class TaskItem extends React.Component<Props, State> {
  private changeTaskUnsub: Function;

  constructor(props: Props) {
    super(props);
    this.changeTaskUnsub = ChangeTasksStore.listen(this._onTaskStoreChange, this);

    this.state = {
      errors: {
        title: null,
        reasonUid: null,
        safetyParamCode: null,
        effort: null
      },
      form: {}
    };
  }

  static defaultProps: Partial<Props> = {
    task: {
      colorHex: '#c62828',
      title: '',
      description: '',
      effort: '',
      expectedCompletionDate: moment(new Date()).toDate(),
      reason: null,
      active: true,
      identifier: ''
    },
    isOpened: false,
    isNewTask: false
  };

  _hideOverlay(e: React.MouseEvent<HTMLElement>) {
    this.setState({ showDeleteConfirm: false });
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  _confirmDelete(e: React.MouseEvent<HTMLElement>) {
    ChangeTasksStore.removeTask(this.props.task?.uid!, EditorStore.getDocParams());
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  UNSAFE_componentWillMount() {
    this._resetStateFromProperties(this.props);
  }

  componentWillUnmount() {
    this.changeTaskUnsub();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // A cancel:
    if (this.props.isOpened && !nextProps.isOpened) {
      this._resetStateFromProperties(nextProps);
    }
    // an open
    else if (!this.props.isOpened && nextProps.isOpened) {
      this._resetStateFromProperties(nextProps);
    } // a toggle
    else if (this.props.task?.active !== nextProps.task?.active) {
      this._resetStateFromProperties(nextProps);
    }
  }

  _onTaskStoreChange(state: ChangeTasksStoreEvent) {
    if (state.error && !this.state.errors.identifier) {
      this.setState({
        errors: {
          ...this.state.errors,
          identifier: 'Task ID exists'
        }
      });
    }
  }

  // props represent the original task
  _resetStateFromProperties(props: Props) {
    const newState: Partial<State> = {
      form: {
        identifier: props.task?.identifier,
        effort: '' + props.task?.effort,
        expectedCompletionDate: props.task?.expectedCompletionDate,
        title: props.task?.title,
        riskAssessment: props.task?.riskAssessment || 'NOT_APPLICABLE',
        description: props.task?.description,
        reasonUid: props.task?.reason ? props.task?.reason?.uid : null,
        safetyParamCode: props.task?.safetyParamCode,
        colorHex: props.task?.colorHex,
        active: props.task?.active
      }
    };

    if (props.task) {
      newState.authors = props.task.authors ? _.clone(props.task.authors) : [];
      newState.reviewers = props.task.reviewers ? _.clone(props.task.reviewers) : [];
    }

    this.setState(newState as State);
  }

  _resetState() {
    const newState: Partial<State> = {
      errors: {
        title: null,
        reasonUid: null,
        safetyParamCode: null,
        effort: null
      }
    };

    this.setState(newState as State);
  }

  _requestOpenStateChange() {
    /** This seems to be a bug in Mui.Popover not cleaning itself up, need to give it some time
     * resolved in latest MUI?
     */

    setTimeout(() => {
      this.props.onRequestOpenChange({ requestToOpen: !this.props.isOpened, taskUid: this.props.task?.uid! });
    }, 50);

    if (this.refs.colorSelector) {
      (this.refs.colorSelector as ColorSelector).close();
    }
    if (this.refs.menuSelector) {
      (this.refs.menuSelector as DropDownIcon).close();
    }
  }

  _handleItemBodyClick(e) {
    if (this.refs.colorSelector) {
      (this.refs.colorSelector as ColorSelector).close();
    }

    if (!this.state.showDeleteConfirm) {
      if (!this.props.isOpened) {
        this._requestOpenStateChange();
      }
    }
  }

  _handleTagColorChange(color: string) {
    this.setState({
      form: {
        ...this.state.form,
        colorHex: color
      }
    });
  }

  _isResolved() {
    return this.props.task?.resolved;
  }

  _handleTaskMenu(action) {
    if (action === 'edit') {
      this._requestOpenStateChange();
    } else if (action === 'remove') {
      this.setState({ showDeleteConfirm: true });
      // ChangeTaskActions.removeTask(this.props.task.uid, EditorStore.getDocParams());
    } else if (action === 'activeToggle') {
      ChangeTasksStore.toggleActive(this.props.task!, EditorStore.getDocParams());
    } else if (action === 'resolvedToggle') {
      ChangeTasksStore.toggleResolved(this.props.task!, EditorStore.getDocParams());
    }
  }

  _handleFormButton(action: string) {
    if (action === 'cancel') {
      this._resetStateFromProperties(this.props);
      this._requestOpenStateChange();
      this._resetState();
    } else {
      // SAVE

      if (this._validate()) {
        // prep data for saving
        const updatedTask: Partial<ITask> = this.props.isNewTask ? this.state.form : _.extend(this.props.task, this.state.form);

        updatedTask.reason = { uid: updatedTask.reasonUid! };

        const participants = this.state.authors?.concat(this.state.reviewers!);
        const participantsReformatted: Partial<TaskAuthor>[] = [];
        if (participants) {
          participants.forEach((p) => {
            participantsReformatted.push({
              author: p.author,
              reviewer: p.reviewer,
              status: p.status,
              reviewerDisposition: p.reviewer ? p.reviewerDisposition : null,
              user: { uid: p.user.uid }
            });
          });
        }

        updatedTask.participants = participantsReformatted as TaskAuthor[];

        delete updatedTask.authors;
        delete updatedTask.reviewers;

        if (this.props.editPermission === 'user') {
          const userTask: Partial<ITask> = {
            uid: updatedTask.uid,
            participants: participantsReformatted.filter((p) => {
              return p.user?.uid === ActiveUserStore.getUser()?.uid;
            }) as TaskAuthor[]
          };

          ChangeTasksStore.updateTaskParticipant(userTask, EditorStore.getDocParams());
        } else if (updatedTask.uid) {
          ChangeTasksStore.updateTask(updatedTask, EditorStore.getDocParams());
        } else {
          ChangeTasksStore.createTask(updatedTask, EditorStore.getDocParams());
        }
      }
    }
  }

  _isUserAvailableToAdd(user: IUser) {
    const selectedUsers = this.state.authors?.concat(this.state.reviewers!);
    if (selectedUsers) {
      return !selectedUsers.find((u) => {
        return u.user.uid === user.uid;
      });
    }
    return false;
  }

  _handleParticipantChange(e, participantType: string) {
    if (this.props.editPermission !== 'none') {
      const currentParticipants = this.state[participantType];

      if (e.type === 'add') {
        currentParticipants.push({
          user: e.data,
          reviewer: participantType === 'reviewers',
          author: participantType === 'authors',
          status: 'INCOMPLETE'
        });
      } else if (e.type === 'update') {
        let participant = currentParticipants.find(function (p) {
          return p.user.uid === e.userUid;
        });
        participant = _.extend(participant, e.data);
        if (e.data.reviewerDisposition && e.data.reviewerDisposition !== 'NONE') {
          participant.status = 'COMPLETE';
        }
        if (e.data.status && e.data.status !== 'COMPLETE') {
          participant.reviewerDisposition = 'NONE';
        }
      } else if (e.type === 'remove') {
        currentParticipants.splice(
          _.findIndex(currentParticipants, function (p: Partial<TaskAuthor>) {
            return p?.user?.uid === e.userUid;
          }),
          1
        );
      }
      this.setState({ [participantType]: currentParticipants } as State);
    }
  }

  _getReasonMenuItems() {
    const reasonItems: { text: string; payload: string | null }[] = [];
    const reasonLookupCodes = ChangeTaskReasonStore.getInitialState().reasons;

    if (reasonLookupCodes) {
      reasonLookupCodes.forEach((r) => {
        reasonItems.push({ text: r.title, payload: r.uid });
      });

      if (this.props.isNewTask) {
        reasonItems.unshift({ text: '', payload: null });
      }
    }

    return reasonItems;
  }

  _getRiskAssessmentItems() {
    return [
      { text: 'Not Applicable', payload: 'NOT_APPLICABLE' },
      { text: 'Incomplete', payload: 'INCOMPLETE' },
      { text: 'Complete', payload: 'COMPLETE' }
    ];
  }

  _getSafetyParamMenuItems() {
    const safetyItems: { text: string; payload: string | null }[] = [];
    const safetyParamLookupCodes = LookupStore.getLookupData('safety_params');

    if (safetyParamLookupCodes) {
      safetyParamLookupCodes.forEach((r) => {
        safetyItems.push({ text: r.description, payload: r.code });
      });

      if (this.props.isNewTask) {
        safetyItems.unshift({ text: '', payload: null });
      }
    }

    return safetyItems;
  }

  _getWFActionMenuItems() {
    const wfActions: { text: string; payload: string | null }[] = [
      { text: 'Marked as complete before next stage', payload: '1' },
      { text: 'Show warning if tasks are incomplete', payload: '2' }
    ];

    if (this.props.isNewTask) {
      wfActions.unshift({ text: '', payload: null });
    }

    return wfActions;
  }

  _riskAssessmentEnabled() {
    return this.props.editPermission === 'all' && appStore.getState().projectSettings.riskAssessmentRequiredForTasks.enabled;
  }

  _handleFormChange(e, field, value, max) {
    const form = Object.assign({}, this.state.form);
    if (field === 'effort') {
      const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);

      if (isNumeric || value.length === 0) {
        form.effort = value;
      }
    } else {
      form[field] = value;
    }
    this.setState({ form });

    this._validate(field, value, max);
  }

  _validate(field?: string, value?: string, max?: number) {
    let isValid = true;
    const { form } = this.state;
    const errors = Object.assign({}, this.state.errors);

    if (field) {
      errors[field] =
        (value === '' || value === null || value === undefined) &&
        field !== 'identifier' &&
        field !== 'description' &&
        field !== 'riskAssessment'
          ? 'Required field'
          : null;

      if (field === 'identifier' && value !== '' && new RegExp('[<>]').test(value as string)) {
        errors.identifier = '< > Not allowed in task identifier.';
      }
      if (value && max && value.length >= max) {
        if (field === 'effort') {
          errors[field] = 'Maximum days';
        } else {
          errors[field] = 'Maximum characters reached';
        }
      }
    } else {
      _.each(errors, (v, name) => {
        if (name !== 'identifier') {
          (form[name] === '' || form[name] === undefined || form[name] === null) && name !== 'description' && name !== 'riskAssessment'
            ? (errors[name] = 'Required Field')
            : (errors[name] = null);
        }
      });
    }
    this.setState({ errors });

    _.each(errors, (err) => {
      if (err) {
        isValid = false;
      }
    });
    if (!isValid) {
      this._scrollToInvalid('title');
    }

    return isValid;
  }

  _scrollToInvalid(id) {
    document.getElementById(id)?.scrollIntoView(true);
  }

  _openDateModal() {
    if (this.props.editPermission === 'all') {
      EditorStore.triggerOpenGenericDateModal('changetask', {
        defaultDate: moment(
          this.state.form?.expectedCompletionDate ? (this.state.form?.expectedCompletionDate as Date) : new Date()
        ).toDate(),
        formatDate: this._formatDate,
        autoOk: true,
        onChange: (e, date) => {
          this.setState({
            form: {
              ...this.state.form,
              expectedCompletionDate: date.toISOString()
            }
          });
        }
      });
    }
  }

  _formatDate(date: Date) {
    return dateUtil(date ?? new Date()).formatDate();
  }

  render() {
    if (!this.props.task) {
      return null;
    }
    const passthroughProps = _.omit(this.props, ['maxLength']);
    return (
      <li
        className={`${
          (this.props.isOpened ? 'task-item-opened' : '') +
          (this.state.form?.active ? '' : ' task-item-inactive') +
          (this.props.editPermission !== 'all' ? ' task-item-readonly' : '')
        }`}
        style={{ zIndex: this.props.zIndex }}
        onClick={(e) => {
          this._handleItemBodyClick(e);
        }}
      >
        {this._isResolved() ? (
          <div className="resolved-icon">
            <div className="resolved-icon-inner">
              <span className="material-icons">done</span>
            </div>
          </div>
        ) : null}

        {this.state.showDeleteConfirm ? (
          <ConfirmationOverlay
            text="Do you want to delete this task?"
            confirmBtnText="Delete"
            cancelBtnText="Cancel"
            onConfirm={(e) => this._confirmDelete(e)}
            onCancel={(e) => this._hideOverlay(e)}
          />
        ) : undefined}

        <div className="task-list-item-container task-list-title">
          {!this.props.isOpened ? <h6>{this.state.form?.title}</h6> : undefined}

          {this.props.isOpened ? (
            <TextField
              {...passthroughProps}
              className="task-title-textinput"
              id="title"
              disabled={this.props.editPermission !== 'all'}
              value={this.state.form?.title}
              maxLength={250}
              errorText={this.state.errors.title}
              hintText={this.props.isNewTask ? 'Task title' : 'Edit task title'}
              onChange={(e, text) => {
                this._handleFormChange(e, 'title', text, 250);
              }}
            />
          ) : undefined}

          {this.props.editPermission === 'all' ? (
            <DropDownIcon
              className="task-menu"
              containerClassName="task-menu-container-inner"
              onSelect={(action) => this._handleTaskMenu(action)}
              ref="menuSelector"
            >
              <DropDownOption value="edit">{this.props.isOpened ? 'Cancel' : 'Edit'}</DropDownOption>
              <DropDownOption show={!this.props.isNewTask} value="remove" className="task-item-delete-menu-option">
                Delete
              </DropDownOption>
              <DropDownOption
                show={!this.props.isNewTask}
                value="activeToggle"
                delayCloseOnSelect={500}
                className={`task-menu-active-toggle ${this.state.form?.active ? 'active' : ''} `}
              >
                <Toggle label="Active" defaultToggled={this.state.form?.active} />
              </DropDownOption>
              <DropDownOption
                show={!this.props.isNewTask}
                value="resolvedToggle"
                delayCloseOnSelect={500}
                className={`task-menu-active-toggle ${this._isResolved() ? 'resolved' : ''} `}
              >
                <Toggle label="Resolved" defaultToggled={this._isResolved()} />
              </DropDownOption>
            </DropDownIcon>
          ) : undefined}

          {!this.props.isOpened ? (
            <div className="task-tag" style={{ backgroundColor: this.state.form?.colorHex }}>
              <span className="icon-tasks" />
              <span title={this.props.task.identifier}>{this.props.task.identifier}</span>
              <span className="icon-task-tag-background" />
            </div>
          ) : undefined}

          <div className="task-list-item-container task-list-title">
            {this.props.isOpened ? (
              <div className="task-form-container">
                <TextField
                  {...passthroughProps}
                  maxLength={250}
                  className="task-title-textinput"
                  hintText={'Task ID'}
                  id="task-id-input"
                  value={this.state.form?.identifier}
                  errorText={this.state.errors.identifier}
                  onChange={(e, text) => {
                    if (this.props.editPermission === 'all' || this.props.isNewTask) {
                      this._handleFormChange(e, 'identifier', text, 250);
                    }
                  }}
                />
              </div>
            ) : undefined}
          </div>

          <div className="task-form-container">
            {this.props.isOpened ? (
              <ColorSelector
                ref="colorSelector"
                tag={this.state.form?.identifier}
                labelTitle="Label"
                icon="icon-tasks"
                endIcon="icon-task-tag-background"
                color={this.state.form?.colorHex}
                onChange={(color) => this._handleTagColorChange(color)}
                disabled={this.props.editPermission !== 'all'}
              />
            ) : undefined}
          </div>
        </div>
        {!this.props.isOpened ? (
          <div className="task-list-item-container task-list-summary">
            <span>Authoring</span>
            <span className={`task-status task-status-${this.props.task.statusSummary?.author?.state}`} />
            <span>{this.props.task.statusSummary?.author?.title}</span>
          </div>
        ) : undefined}

        {!this.props.isOpened ? (
          <div className="task-list-item-container task-list-summary">
            <span>Reviewing</span>
            <span className={`task-status task-status-${this.props.task.statusSummary?.reviewer?.state}`} />
            <span>{this.props.task.statusSummary?.reviewer?.title}</span>
          </div>
        ) : undefined}

        {!this.props.isOpened && (
          <div className="task-list-item-container task-list-summary">
            <span>Outcome</span>
            <span
              className={`task-status task-status-${(this.props.task.statusSummary?.reviewerDisposition as TaskAuthorComponent).state}`}
            />
            <span>{(this.props.task.statusSummary?.reviewerDisposition as TaskAuthorComponent).title}</span>
          </div>
        )}
        {this.props.isOpened ? (
          <div className="task-list-item-container task-list-open-contents">
            <div className="task-form-container">
              <label>Description / Reason</label>
              <TextField
                {...passthroughProps}
                disabled={this.props.editPermission !== 'all'}
                className="task-description-textinput"
                id="description"
                rowsMax={3}
                maxLength={1000}
                multiLine={true}
                value={this.state.form?.description}
                errorText={this.state.errors.description}
                onChange={(e, text) => {
                  this._handleFormChange(e, 'description', text, 1000);
                }}
              />
            </div>
            <div className="task-form-container contains-select">
              <label>Task Reason</label>
              <SelectField
                id="reasonUid"
                disabled={this.props.editPermission !== 'all'}
                value={this.state.form?.reasonUid}
                errorText={this.state.errors.reasonUid}
                className="task-form-select"
                fullWidth={true}
                onChange={(e, i, action) => {
                  this._handleFormChange(e, 'reasonUid', action, null);
                }}
              >
                {this._getReasonMenuItems().map((item) => {
                  return <MenuItem key={item.payload} value={item.payload!} primaryText={item.text!} />;
                })}
              </SelectField>
            </div>
            <div className="task-form-container contains-select">
              <label>Safety Parameter</label>
              <SelectField
                id="safetyParamCode"
                disabled={this.props.editPermission !== 'all'}
                value={this.state.form?.safetyParamCode}
                errorText={this.state.errors.safetyParamCode}
                className="task-form-select"
                fullWidth={true}
                onChange={(e, i, action) => {
                  this._handleFormChange(e, 'safetyParamCode', action, null);
                }}
              >
                {this._getSafetyParamMenuItems().map((item) => {
                  return <MenuItem key={item.payload} value={item.payload!} primaryText={item.text} />;
                })}
              </SelectField>
            </div>

            <div className="task-form-container task-form-container-2-col">
              <div className="contains-select">
                <label>Effort (days)</label>
                <TextField
                  {...passthroughProps}
                  id="effort"
                  disabled={this.props.editPermission !== 'all'}
                  className="task-effort-textinput"
                  value={this.state.form?.effort}
                  errorText={this.state.errors.effort}
                  maxLength={6}
                  onChange={(e, text) => {
                    this._handleFormChange(e, 'effort', text, 6);
                  }}
                />
              </div>

              <div>
                <label>Completion Date:</label>

                <div
                  className="dummy-date-field"
                  onClick={() => {
                    this._openDateModal();
                  }}
                >
                  <div>{this._formatDate(this.state.form?.expectedCompletionDate as Date)}</div>
                  <svg viewBox="0 0 24 24" className="icon-dropdown-arrow">
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className={`${'task-form-container contains-select ' + (this._riskAssessmentEnabled() ? '' : ' disabled')}`}>
              <label>Risk Assessment</label>
              <SelectField
                disabled={this.props.editPermission !== 'all' || !this._riskAssessmentEnabled()}
                value={this.state.form?.riskAssessment}
                errorText={this.state.errors.riskAssessment}
                className="task-form-select"
                fullWidth={true}
                onChange={(e, i, action) => {
                  this._handleFormChange(e, 'riskAssessment', action, null);
                }}
              >
                {this._getRiskAssessmentItems().map((item) => {
                  return <MenuItem key={item.payload} value={item.payload} primaryText={item.text} />;
                })}
              </SelectField>
            </div>

            <UserList
              title="Authors"
              users={this.state.authors!}
              emptyText="No authors assigned"
              editPermission={this.props.editPermission}
              userAvailabilityCheck={(user) => this._isUserAvailableToAdd(user)}
              onChange={(e) => {
                this._handleParticipantChange(e, 'authors');
              }}
            />
            <UserList
              title="Reviewers"
              reviewer={true}
              users={this.state.reviewers!}
              emptyText="No reviewers assigned"
              editPermission={this.props.editPermission}
              userAvailabilityCheck={(user) => this._isUserAvailableToAdd(user)}
              onChange={(e) => {
                this._handleParticipantChange(e, 'reviewers');
              }}
            />

            {this.props.editPermission !== 'none' ? (
              <div className="task-list-item-buttons">
                <FlatButton
                  label={this.props.isNewTask ? 'Create' : 'Save'}
                  secondary={true}
                  onClick={() => {
                    this._handleFormButton('save');
                  }}
                />
                <FlatButton
                  label="Cancel"
                  secondary={true}
                  onClick={() => {
                    this._handleFormButton('cancel');
                  }}
                />
              </div>
            ) : undefined}

            {this.props.editPermission === 'none' ? (
              <div className="task-list-item-buttons">
                <FlatButton
                  label="Close"
                  secondary={true}
                  onClick={() => {
                    this._handleFormButton('cancel');
                  }}
                />
              </div>
            ) : undefined}
          </div>
        ) : undefined}
      </li>
    );
  }
}
