import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import { SymbolValue } from '../index';

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.SYMBOL> = {
  id: NNCIndexMetadataID.SYMBOL,
  toMetadataHTML: ({ value }) => {
    return `<div class="${metadataDefinition.id}" value="${value}"></div>`;
  },
  toHeaderHTML: ({ value }) => {
    return value !== 'null' ? value + ' ' : '';
  },
  toJSON: ($element) => {
    return {
      value: $element.attr('value') || 'null'
    } as SymbolValue;
  }
};
