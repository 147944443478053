function arrayToMap<T, K extends keyof T>(arr: Array<T>, key: K): Map<T[K], T> {
  const m = new Map<T[K], T>();
  return arr.reduce(mapOneToOne(key), m);
}

function arrayToMapOfLists<T, K extends keyof T>(arr: Array<T>, key: K): Map<T[K], T[]> {
  const m = new Map<T[K], T[]>();
  return arr.reduce(mapOneToMany(key), m);
}

type OneToOneMap<T, K extends keyof T> = Map<T[K], T>;
function mapOneToOne<T, K extends keyof T>(key: K) {
  return (map: OneToOneMap<T, K>, el: T) => map.set(el[key], el);
}

type OneToMany<T, K extends keyof T> = Map<T[K], T[]>;
function mapOneToMany<T, K extends keyof T>(key: K) {
  return (map: OneToMany<T, K>, el: T) => {
    const hit = map.get(el[key]);
    if (hit && Array.isArray(hit)) {
      hit.push(el);
      map.set(el[key], hit);
    } else {
      map.set(el[key], [el]);
    }
    return map;
  };
}

export { arrayToMap, arrayToMapOfLists };
