import * as Reflux from 'reflux';
// import RecentIndex from '../../models/RecentIndex';
import * as indexClient from '../../clients/index';
import Store from '../Store';
import { IRecentIndex } from 'mm-types';
import ActiveUserStore from './ActiveUserStore';

export type State = {
  recentDocuments: IRecentIndex[];
};

export type RecentStoreEvent = {
  data: IRecentIndex[];
  errorMsg?: string;
};

export class RecentStore extends Store<State> {
  constructor() {
    super();
    this.state = {
      recentDocuments: []
    };
  }

  getInitialState() {
    return this.state;
  }

  sendIndexUsage(indexUid: string) {
    return indexClient.sendIndexUsage(indexUid, ActiveUserStore.getUser()!.uid);
  }

  async retrieveRecentIndexes() {
    try {
      const results = await indexClient.getRecent(ActiveUserStore.getUser()!.uid);
      this.trigger({ data: results } as RecentStoreEvent);
      this.state.recentDocuments = results;
    } catch (err) {
      this.trigger({ errorMsg: 'Cannot retrieve recent documents.' } as RecentStoreEvent);
    }
  }

  getRecentDocuments() {
    return this.state.recentDocuments;
  }
}

const singleton = Reflux.initStore<RecentStore>(RecentStore);
export default singleton;
