import { Cancelled, mm } from './base-clients';
import { IMedia, IServerError } from 'mm-types';
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { PaginationProps } from '../components/general/Paging';
import QueryUtil from '../utils/QueryUtil';
import { dateUtil } from '../utils';
import { MEDIA_TYPES, MediaFilterType, MediaTypesContants } from '../components/editor/medialib/types';

let getAllSource: CancelTokenSource | null = null;
export const DEFAULT_PAGE_SIZE = 50;

export interface MediaResponse extends PaginationProps {
  createdFormatted: string;
  friendlySize: string;
  media: IMedia[];
  modifiedFormatted: string;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface UploadMediaResponse {
  completed: AxiosResponse<IMedia>[];
  errors: IServerError[];
  allCompleted: boolean;
}

export type MediaSort = 'created' | 'filename,DESC' | 'filename,ASC';

export interface SearchMediaParams {
  projectUid: string;
  type: MediaFilterType;
  pageNumber: number;
  query: string;
  sort: MediaSort;
  selectedMediaItemUid?: string;
}

function getSortParam(sort: MediaSort): string {
  switch (sort) {
    case 'created':
      return 'created,DESC';
    default:
      return sort;
  }
}

export async function getAll(data: SearchMediaParams): Promise<MediaResponse | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }
  getAllSource = axios.CancelToken.source();

  const params: string = QueryUtil.fromObject({
    pageNumber: data.pageNumber + '',
    pageSize: DEFAULT_PAGE_SIZE + '',
    type: data.type === MediaTypesContants.all ? MEDIA_TYPES.toString() : data.type,
    sort: getSortParam(data.sort),
    name: data.query,
    selectedMediaItemUid: data.selectedMediaItemUid
  });

  try {
    const response = await mm.get<MediaResponse>(`/projects/${data.projectUid}/media${params}`, {
      cancelToken: getAllSource.token
    });
    getAllSource = null;
    response.data.media.forEach((media) => {
      parse(media);
    });

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getDetails(projectUid: string, mediaUid: string): Promise<IMedia | Cancelled> {
  try {
    const response = await mm.get<IMedia>(`/projects/${projectUid}/media/${mediaUid}`);
    parse(response.data);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

/**
 * Edits media by id
 */
export async function upload(uid: string, files: File[], isSymbol = false, sourceMediaUid?: string): Promise<UploadMediaResponse> {
  const requests: Promise<AxiosResponse<IMedia> | AxiosError>[] = [];

  files.forEach(async (file) => {
    const data = new FormData();
    data.set('content', file);
    data.set('filename', file.name);
    if (sourceMediaUid) {
      data.set('sourceOfMediaUid', sourceMediaUid);
    }
    if (isSymbol) {
      data.set('symbol', 'true');
    }
    requests.push(
      mm.post<IMedia>(`projects/${uid}/media`, data).catch((error: AxiosError) => {
        return error;
      })
    );
  });

  return axios.all(requests).then((responses: (AxiosResponse<IMedia> | AxiosError)[]) => {
    const completed: AxiosResponse<IMedia>[] = [];
    const errors: IServerError[] = [];
    responses.forEach((res) => {
      if (isResponse(res)) {
        completed.push(res);
      } else {
        errors.push(res.response!.data.errors[0] || { code: 0, message: 'Failure' });
      }
    });
    return {
      completed,
      errors,
      allCompleted: errors.length === 0
    };
  });
}

/**
 * Edits media by id
 */
export async function editMedia(projectUid: string, uid: string, updatedMedia: IMedia) {
  return await mm.post<IMedia>(`projects/${projectUid}/media/${uid}`, updatedMedia);
}

/**
 * Removes an media by id
 */
export async function remove(projectUid: string, uid: string) {
  const response = await mm.delete(`projects/${projectUid}/media/${uid}`);
  return response.data;
}

const _formatBytes = (bytes: number, decimals: number) => {
  const k = 1000; // or 1024 for binary
  const dm = decimals + 1 || 3;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

function parse(response: IMedia) {
  response.createdFormatted = dateUtil(response.created).formatDateTimeNoSecs();
  response.modifiedFormatted = dateUtil(response.modified).formatDateTimeNoSecs();
  response.friendlySize = _formatBytes(response.size, 1);

  return response;
}

function isResponse(response: AxiosResponse | AxiosError): response is AxiosResponse {
  return (<AxiosResponse>response).data !== undefined;
}
