import Slider from 'material-ui/Slider';
import React from 'react';

type Props = {
  doZoom(boolean): void;
  scale: number;
  handleZoom(value: any): void;
};

export default (props: Props) => {
  return (
    <div className="media-control-outer">
      <div className="media-control">
        <i
          className="material-icons"
          onClick={() => {
            props.doZoom(true);
          }}
        >
          zoom_out
        </i>
        <Slider
          className="zoom-slider"
          name="zoom-slider"
          defaultValue={1}
          step={1}
          min={1}
          max={5}
          value={props.scale}
          style={{ float: 'left', width: '177px' }}
          onChange={(e, change) => props.handleZoom(change)}
        />
        <i
          className="material-icons"
          onClick={() => {
            props.doZoom(false);
          }}
        >
          zoom_in
        </i>
      </div>
    </div>
  );
};
