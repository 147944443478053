import * as React from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../../../../flux/editor/EditorStore';
import TabToggleButtons, { ITab } from '../../TableEditProps/TabToggleButtons';
import TableEditPropsTabs from '../../TableEditProps/TableEditPropsTabs';
import ToggleSection from '../../../../../../misc/ToggleSection';
import { IElementSettings, IRowType, TTabType } from 'mm-types';
import TableUtils from '../../../../../../../utils/TableUtils';
import EditorInstanceManager from '../../../../../utils/tinyFacade/EditorInstanceManager';
import { TextField } from 'material-ui';
import ProjectDefinitionStore from '../../../../../../../flux/common/ProjectDefinitionStore';
import useListenToStore from '../../../../../../hooks/useListenToStore';
import { initialState, TableEditReducer } from './TableEditReducer';
import { IGenericEditProps } from '../GenericEditProps';
import { ElementDetails } from '../../../../../utils/units/ElementDetails';

export type Props = {
  disabled?: boolean;
} & IGenericEditProps;

export type TableEditState = {
  isCaptionOn: boolean;
  caption: string | null;
  updateCaption: boolean;
  activeTab: TTabType;
  errorMessage?: string | null;
  dataAttributes: DataAttribute[];
  settings?: IElementSettings[];
  selectedTable?: HTMLTableElement;
  userOpened: boolean;
};

export type DataAttribute = { id: string; val: string };

export const TableEditProps = (props: Props) => {
  const _editFocusTimer: number | null = null;
  const captionRef = React.useRef<TextField | null>(null);

  const _tabs: ITab[] = [
    {
      tab: 'props',
      tooltip: 'Table Layout',
      icon: 'border_all',
      isActive: (activeTab) => activeTab === 'props'
    },
    {
      tab: 'formatting',
      tooltip: 'Cell Formatting',
      icon: 'border_clear',
      isActive: (activeTab) => activeTab === 'formatting'
    }
  ];

  const [state, dispatch] = React.useReducer(TableEditReducer, initialState);

  useListenToStore({ store: EditorStore, eventListener: _onEditorStoreUpdate, update: [state] });

  function _onEditorStoreUpdate(e) {
    if (e.type === 'unitDomChange') {
      if (state.activeTab === 'props' && e.data) {
        _updateCaption();
      }
      _getDataAttributes();
      _getSettings();
    } else if (e.type === 'nestedUnitFocusChange') {
      EditorStore.getEditor().getActiveEditorFacade()!.clearSelection();
      _getDataAttributes();
      _getSettings();
      _triggerStateRefresh(state.activeTab);
      _updateCaption();
    }
  }

  React.useEffect(() => {
    _updateCaption();
    return () => {
      if (!!_editFocusTimer) {
        clearInterval(_editFocusTimer);
      }

      let caption = (EditorStore.getEditor()
        .getActiveEditorFacade()
        ?.getSelectedTable({ reselect: false }) as HTMLElement)?.getElementsByTagName('caption')[0];
      caption?.removeEventListener('dblclick', () => dispatch({ type: 'setActiveTab', payload: 'props' }));
      caption?.removeEventListener('click', () => dispatch({ type: 'setActiveTab', payload: 'formatting' }));
    };
  }, []);

  React.useEffect(() => {
    EditorStore.getEditor().getActiveEditorFacade()!.setCaption('table', state.caption, null, state.selectedTable);
  }, [state.caption]);

  React.useEffect(() => {
    _triggerStateRefresh(state.activeTab);
  }, [state.activeTab]);

  function _triggerStateRefresh(tab) {
    const editor = EditorStore.getEditor();
    const tinyFacade = editor.getActiveEditorFacade()!;

    const node = tab === 'props' ? tinyFacade.getSelectedTable({ reselect: false }) : TableUtils.getSelectedCells();
    if ((node && !Array.isArray(node)) || (node && Array.isArray(node) && node[0] !== null)) {
      EditorStore.triggerUnitDomChange({ node: node as Node }); // dirty
    }
  }

  function _handleTab(tab) {
    dispatch({ type: 'setActiveTab', payload: tab });
  }

  function _getDataAttributes() {
    let dataAttributes: DataAttribute[];
    if (state.activeTab === 'props') {
      dataAttributes = Array.from((props.targetElement as HTMLTableElement).attributes)
        .map((attribute) => {
          if (attribute.nodeName.startsWith('data')) {
            return { id: attribute.nodeName, val: attribute.value };
          }
          return undefined;
        })
        .filter((attr) => attr !== undefined) as DataAttribute[];
    } else {
      dataAttributes = EditorStore.getEditor()
        .getActiveEditorFacade()
        ?.collectDataAttributes(TableUtils.getSelectedCells()) as DataAttribute[];
    }

    dispatch({ type: 'setDataAttributes', payload: dataAttributes });
  }

  function _updateCaption() {
    let editor = EditorStore.getEditor().getActiveEditorFacade()!;
    let { enabled, value } = editor.getCaption('table');
    if (enabled) {
      let caption = (editor.getSelectedTable({ reselect: false }) as HTMLElement)?.getElementsByTagName('caption')[0];
      caption?.addEventListener('dblclick', () => dispatch({ type: 'setActiveTab', payload: 'props' }));
      caption?.addEventListener('click', () => dispatch({ type: 'setActiveTab', payload: 'formatting' }));
    }
    if (enabled !== state.isCaptionOn || value !== state.caption) {
      dispatch({ type: 'setCaption', payload: { isCaptionOn: enabled, caption: value, updateCaption: false } });
    }
  }

  function _handleClick(e) {
    let tgt = e.currentTarget;
    let action = tgt.getAttribute('data-action');
    let param: string | null | undefined = tgt.getAttribute('data-param');
    const insertPosition = action.replace('mceTableInsertRow', 'insert_').toLocaleLowerCase();

    if (['mceTableInsertRowBefore', 'mceTableInsertRowAfter'].includes(action)) {
      param = ProjectDefinitionStore.getElementDefinitionById(props.definition?.id + 'Row')?.templateHtml;
    }

    if (action) {
      EditorStore.getEditor().getActiveEditorFacade()!.execCommand(action, param, null, insertPosition);
    }
  }

  function _onToggle(isOpened: boolean) {
    // if associated table not focused on toggle open, focus on first cell before triggering RHS props update
    if (
      isOpened &&
      props.targetElement &&
      props.targetElement.getAttribute('data-nid') !==
        EditorStore.getEditor().getActiveEditorInstance()!.selection.getNode().closest('table')!.getAttribute('data-nid')
    ) {
      _focusFirstCell(props.targetElement);
      _triggerStateRefresh(state.activeTab);
    }
  }

  function _handleCaptionToggle() {
    EditorStore.getEditor().getActiveEditorFacade()!.execCommand('arcToggleCaption', 'table');
    let { enabled, value } = EditorStore.getEditor().getActiveEditorFacade()!.getCaption('table');

    dispatch({ type: 'setCaption', payload: { isCaptionOn: enabled, caption: value, updateCaption: true } });
  }

  React.useEffect(() => {
    if (state.updateCaption) {
      _handleCaptionClick();
      if (state.isCaptionOn) {
        setTimeout(() => {
          captionRef.current?.focus();
        }, 300);
      }
    }
  }, [state.updateCaption]);

  function _handleCaptionChange(e) {
    let msg = e.target.value.length > 499 ? 'Maximum characters reached' : null;

    if (state.caption !== e.target.value) {
      dispatch({ type: 'setCaptionWithErrorMsg', payload: { caption: e.target.value, errorMsg: msg } });
    }
  }

  function getActiveTableNid(editor: any) {
    // Keep track of which table we are editing - this can be lost if the table
    // is in a list with other tables. Tiny cant find the specific table as the focus gets lost
    const table = editor.dom.getParent(editor.selection.getStart(), 'table') as HTMLTableElement;
    if (table) {
      dispatch({ type: 'setSelectedTable', payload: table });
    }
  }

  function _handleCaptionClick() {
    let editor = EditorStore.getEditor().getActiveEditorInstance()!;
    if (!editor.dom.getParent(editor.selection.getStart(), 'caption')) {
      getActiveTableNid(editor);

      // get TinyMCEs house in order
      EditorStore.getEditor().getActiveEditorFacade()!.focusCaption('table');
      setTimeout(() => {
        EditorStore.getEditor().getActiveEditorFacade()!.clearSelection();
      }, 10);
    } else {
      getActiveTableNid(editor);
    }
  }

  function _focusFirstCell(table: HTMLElement | null = null) {
    let editor: EditorInstanceManager = EditorStore.getEditor();
    if (editor.isFocused()) {
      const activeEditorInstance = editor.getActiveEditorInstance()!;
      const selectedNode = table ? table : activeEditorInstance.selection.getNode();
      editor.setCursorLocation(TableUtils.getFirstTableCellFromActiveEditor(activeEditorInstance, selectedNode) as HTMLElement, false);
      if (_isTableEditorInstance(activeEditorInstance)) {
        activeEditorInstance.nodeChanged();
      }
    }
  }

  function _isTableEditorInstance(activeEditorInstance: any) {
    return activeEditorInstance.bodyElement?.nodeName === 'TABLE';
  }

  function _getRowTypes(): IRowType[] {
    let rowTypes: IRowType[] = [
      { payload: 'noneditable', text: 'Non Editable', disabled: true },
      { payload: '', text: 'Mixed', disabled: true },
      { payload: 'thead', text: 'Header' },
      { payload: 'tbody', text: 'Body' },
      { payload: 'tfoot', text: 'Footer' }
    ];
    if (ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) {
      rowTypes.splice(4, 1);
    }
    return rowTypes;
  }

  /** Collect settings from currently selected element and parents, currently selected element setting should override parent settings (AER-9000) */
  function _getSettings() {
    // Get table selections
    let { table, tableHead, tableBody } = EditorStore.getEditor().getActiveEditorFacade()!.getTableSelectionStartElements();

    // Get current selected rows and cells combined settings
    const combinedSelectedCellSettings = TableUtils.combineSelectedCellSettings();
    const combinedSelectedRowSettings = TableUtils.combineSelectedRowSettings();

    let hierarchicalCollectedSettings: IElementSettings[] = TableUtils.collectSettingsFromOrderedArray([
      combinedSelectedCellSettings,
      combinedSelectedRowSettings,
      ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(tableBody))?.settings ?? [],
      ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(tableHead))?.settings ?? [],
      ProjectDefinitionStore.getElementDefinitionById(ElementDetails.getDataElementDefinitionId(table))?.settings ?? []
    ]);

    dispatch({ type: 'setSettings', payload: hierarchicalCollectedSettings });
  }

  function _getRowChoice(): IRowType {
    const { row } = EditorStore.getEditor().getActiveEditorFacade()!.getTableSelectionStartElements();

    // Row is only returned when non editable part of the table has been selected
    if (!!row) {
      const rowDefinitionId = row.getAttribute('data-element-definition-id');
      return { ..._getRowTypes()[0], definitionIds: !!rowDefinitionId ? [rowDefinitionId] : [] };
    }

    const selectedRowsElements = TableUtils.getSelectedRows();

    const selectedRowsTypes = selectedRowsElements.reduce((result: { nodeName: string; definitionId: string }[], value) => {
      const dataElementDefinitionId = value.getAttribute('data-element-definition-id');
      if (value?.parentNode?.nodeName && dataElementDefinitionId) {
        if (result.length === 0) {
          return [...result, { nodeName: value.parentNode.nodeName, definitionId: dataElementDefinitionId }];
        } else {
          if (result.findIndex((res) => res.nodeName === value?.parentNode?.nodeName) === -1) {
            return [...result, { nodeName: value.parentNode.nodeName, definitionId: dataElementDefinitionId }];
          }
        }
      }
      return result;
    }, []);

    if (selectedRowsTypes.length === 1) {
      const containerName = selectedRowsTypes[0].nodeName.toLowerCase();
      const index = _.findIndex(_getRowTypes(), (rowType) => {
        return rowType.payload == containerName;
      });
      const rowType = index === -1 ? _getRowTypes()[1] : _getRowTypes()[index];
      return { ...rowType, definitionIds: [selectedRowsTypes[0].definitionId] }; // default to mixed
    } else if (selectedRowsTypes.length === 0) {
      return _getRowTypes()[0]; // empty cell, default to NonEditable
    } else {
      // multiple rows selected
      return { ..._getRowTypes()[1], definitionIds: selectedRowsTypes.map((rowType) => rowType.definitionId) };
    }
  }

  function _applyRowTypeChoice(menuItem) {
    let { dom } = EditorStore.getEditor().getActiveEditorFacade()!.getTableSelectionStartElements();
    let rows = TableUtils.getSelectedRows();
    let toType = menuItem.payload;
    // let rowElm = rows[ 0 ];
    if (!(rows && rows.length && dom)) {
      return;
    }

    // row type changes
    rows.forEach(function (rowElm) {
      let parentNode = rowElm.parentNode!;
      let fromType = parentNode.nodeName.toLowerCase();

      if (toType && toType !== fromType) {
        let tableElm = dom!.getParent(rowElm, 'table');

        let parentElm: Node = dom!.select(toType, tableElm)[0] as Node;
        if (!parentElm) {
          parentElm = dom!.create(toType);
          if (tableElm.firstChild) {
            tableElm.insertBefore(parentElm, tableElm.firstChild);
          } else {
            tableElm.appendChild(parentElm);
          }
        }

        if (fromType === 'thead' || (fromType === 'tbody' && toType === 'tfoot')) {
          parentElm.insertBefore(rowElm, parentElm.firstChild);
          // If thead/tfoot is is the row converted then has no children just remove it
          if (parentNode.children.length === 0) {
            (parentNode as Element).remove();
          }
        } else {
          parentElm.appendChild(rowElm);
        }
      }
    });

    EditorStore.getEditor().getActiveEditorFacade()!.clearSelection();
  }

  function _setDataAttribute(val: string | boolean, id: string, tab: 'props' | 'formatting') {
    let element: HTMLTableElement | Element[] | undefined;
    // get table or cell depending
    if (tab === 'props') {
      element = props.targetElement as HTMLTableElement;
    } else {
      element = TableUtils.getSelectedCells();
    }
    if (Array.isArray(element)) {
      element?.map((elm) => {
        elm.setAttribute(`data-${id}`, val.toString());
      });
    } else if (element instanceof HTMLTableElement) {
      element.setAttribute(`data-${id}`, val.toString());
    }
    EditorStore.triggerUnitDomChange();
  }

  return (
    <div className="toggle-section-outer selected-unit-props-container subaction-edit table-edit ieTooltip">
      <ToggleSection
        title={props.definition?.displayName + ' Properties'}
        id={'unitProps_' + props.index}
        defaultOpen={false}
        forceClose={!props.isLeaf}
        forceOpen={props.isLeaf || state.userOpened}
        onToggled={(isOpened) => {
          dispatch({ type: 'setUserOpened', payload: isOpened });
          _onToggle(isOpened);
        }}
        dataQa={'table-edit-toggle-section'}
        enabled={!props.disabled}
      >
        <div className="col s12" data-qa="table-properties">
          <TabToggleButtons tabs={_tabs} onTabClick={(tab: TTabType) => _handleTab(tab)} activeTab={state.activeTab} />
          <TableEditPropsTabs
            {...props}
            key={state.activeTab}
            activeTab={state.activeTab}
            isCaptionOn={state.isCaptionOn}
            caption={state.caption || ''}
            handleCaptionToggle={() => _handleCaptionToggle()}
            handleCaptionClick={() => _handleCaptionClick()}
            handleCaptionChange={(e) => _handleCaptionChange(e)}
            handleKeyDown={() => void 0}
            errorMessage={state.errorMessage}
            getRowTypes={() => _getRowTypes()}
            getRowType={() => _getRowChoice()}
            applyRowTypeChoice={(e) => _applyRowTypeChoice(e)}
            handleClick={(e) => _handleClick(e)}
            onUserUnitChange={() => EditorStore.userUnitChanged()}
            captionRef={captionRef}
            setDataAttribute={(val, id, tab) => _setDataAttribute(val, id, tab)}
            dataAttributes={state.dataAttributes}
            settings={state.settings}
          />
        </div>
      </ToggleSection>
    </div>
  );
};
