import { AbstractElementFamily, AbstractElementDefinitionIds, ElementFamily } from '../units/UnitTypes';
import { UNIT_ELEMENT_ATTRIBUTE_NAME, UNIT_ELEMENT_CLASS_NAME } from '../units/const/UnitElementSelectors';
import { DomAssertions } from './DomAssertionsUtil';
import { CSSSelector } from './CssSelectorUtil';
import SelectionUtil from './SelectionUtil';
import { CustomEditor } from './EditorInstanceManager';
import * as React from 'react';
import * as keyIdentifier from '../keyIdentifier';
import { Dom } from './DomUtil';
import { isNotInlineElement } from '../units/unit/inlineElementsLookup';

export interface SelectionDetails {
  selectedNode: {
    element: HTMLElement;
    isEditTarget: boolean;
    isUnit: boolean;
    isElement: boolean;
    isEntirelySelected: boolean;
  };
  editTargets: {
    inside: HTMLElement[];
    isEveryEntirelySelected: boolean;
  };
  unitElements: {
    inside: HTMLElement[];
    closest: HTMLElement | null;
  };
  textNodes: {
    first: {
      element: Text;
      offset: number;
    };
    last: {
      element: Text;
      offset: number;
    };
    between: {
      elements: Text[];
    };
  };
}

export default class UnitElementSelectionUtil extends SelectionUtil {
  constructor(public editor: CustomEditor, public family: ElementFamily) {
    super(editor);
  }

  getSelectionDetailsForSelectedNode(selectedNode: HTMLElement): SelectionDetails {
    function isNotAbstractElement(element: HTMLElement): boolean {
      const abstractElementFamily: AbstractElementFamily[] = [
        AbstractElementFamily.CHALLENGE,
        AbstractElementFamily.CHALLENGE_RESPONSE,
        AbstractElementFamily.CHALLENGE_RESPONSE_MESSAGE
      ];
      const abstractElementDefinitionIds: AbstractElementDefinitionIds[] = [
        AbstractElementDefinitionIds.SYS,
        AbstractElementDefinitionIds.TITLE,
        AbstractElementDefinitionIds.SUBTITLE
      ];
      for (const abstractFamily of abstractElementFamily) {
        if (DomAssertions.hasAttributeValue(element, UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, abstractFamily)) {
          return false;
        }
      }
      for (const abstractDefinitionId of abstractElementDefinitionIds) {
        if (DomAssertions.hasAttributeValue(element, UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_DEFINITION_ID, abstractDefinitionId)) {
          return false;
        }
      }
      return true;
    }
    const editTargetsInside = this.getFilteredElementsByClass(UNIT_ELEMENT_CLASS_NAME.EDIT_TARGET).map(this.getHTMLElement) as Element[];
    const unitElementsInside = (this.getFilteredElementsByAttr(UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_DEFINITION_ID).map(
      this.getHTMLElement
    ) as Element[])
      .filter(isNotAbstractElement)
      .filter(isNotInlineElement);
    const isUnit = DomAssertions.hasClassName(selectedNode.parentElement!, UNIT_ELEMENT_CLASS_NAME.UNIT);
    const isElement = !isUnit && DomAssertions.hasAttributeValue(selectedNode, UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, this.family);

    return {
      selectedNode: {
        element: selectedNode,
        isEditTarget: DomAssertions.hasClassNames(selectedNode, [
          UNIT_ELEMENT_CLASS_NAME.EDIT_TARGET,
          UNIT_ELEMENT_CLASS_NAME.ELEMENT_CONTENT
        ]),
        isUnit,
        isElement,
        isEntirelySelected: this.isEntireNodeSelected(selectedNode)
      },
      editTargets: {
        inside: editTargetsInside as HTMLElement[],
        isEveryEntirelySelected: this.areAllElementsInsideEntirelySelected(selectedNode, editTargetsInside)
      },
      unitElements: {
        inside: Array.from(unitElementsInside) as HTMLElement[],
        closest: isNotAbstractElement(selectedNode)
          ? (selectedNode.closest(CSSSelector.attribute([UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, this.family])) as HTMLElement)
          : selectedNode.parentElement &&
            DomAssertions.hasAttributeValue(selectedNode.parentElement, UNIT_ELEMENT_ATTRIBUTE_NAME.ELEMENT_FAMILY, this.family)
          ? selectedNode.parentElement
          : null
      },
      textNodes: {
        first: {
          element: this.range.startContainer as Text,
          offset: this.range.startOffset
        },
        last: {
          element: this.range.endContainer as Text,
          offset: this.range.endOffset
        },
        between: {
          elements: this.getEditableTextNodesInSelection()
            .filter((selected) => selected.element !== this.range.startContainer && selected.element !== this.range.endContainer)
            .map((selected) => selected.element as Text)
        }
      }
    };
  }

  removeTextNodesInSelection(textNodes: SelectionDetails['textNodes']) {
    const firstNodeText = textNodes.first.element.textContent || '';
    const lastNodeText = textNodes.last.element.textContent || '';
    try {
      textNodes.first.element.nodeValue = firstNodeText.substring(0, textNodes.first.offset);
      textNodes.last.element.nodeValue = lastNodeText.substring(textNodes.last.offset, lastNodeText.length);
      textNodes.between.elements.forEach((element) => {
        if (element.parentElement) {
          element.parentElement!.removeChild(element);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  selectElementAndCollapseAfter(element: HTMLElement | Text | null) {
    if (!element) {
      return;
    }
    try {
      this.tinySelection.select(element as any);
      this.tinySelection.collapse();
    } catch (e) {
      console.error(e);
    }
  }

  addKeyValueToTextNodeIfNotDeleteKeys(textNode: Text, e: React.KeyboardEvent) {
    if (keyIdentifier.isDeleteKeys(e) || keyIdentifier.isPasteKeys(e) || keyIdentifier.isCutKeys(e)) {
      Dom.insertZeroLengthCharToEmptyElement(textNode);
    } else {
      textNode.nodeValue += e.key;
    }
  }

  replaceTextInsideSelection(selectionDetails: SelectionDetails, e: React.KeyboardEvent) {
    const { textNodes, editTargets, unitElements } = selectionDetails;
    const firstTextNode = textNodes.first.element;
    this.removeTextNodesInSelection(textNodes);
    this.addKeyValueToTextNodeIfNotDeleteKeys(firstTextNode, e);
    Dom.removeEmptyChildElements(unitElements.inside);
    Dom.insertZeroLengthCharsToEmptyEditTargets(editTargets.inside);
    this.selectElementAndCollapseAfter(
      DomAssertions.isAttachedToDom(firstTextNode) ? firstTextNode : Dom.getFirstAttachedToDomElement(editTargets.inside)
    );
  }

  isSameTextNode(selectionDetails: SelectionDetails): boolean {
    const nodes = selectionDetails.textNodes;
    return nodes.first.element === nodes.last.element;
  }

  replaceFirstTextNodeByZeroLengthChar(selectionDetails: SelectionDetails) {
    Dom.replaceContentByZeroLengthChar(selectionDetails.textNodes.first.element);
  }

  goToEditTarget(e: React.KeyboardEvent, unitElement: Element) {
    const self = this;
    function filter(node: Element) {
      return DomAssertions.hasClassName(node, UNIT_ELEMENT_CLASS_NAME.EDIT_TARGET) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
    }
    const editTargets = Dom.getFilteredElementsIn(unitElement, NodeFilter.SHOW_ELEMENT, filter).reverse();
    let cursorEditTarget: Element | null;
    try {
      cursorEditTarget = Dom.closestElement(this.selection.anchorNode! as Element, CSSSelector.editTarget());
    } catch (e) {
      cursorEditTarget = Dom.closestElement(this.selection.anchorNode!.parentElement, CSSSelector.editTarget());
    }
    let targetIndex = 0;
    let i = 0;

    for (let max = editTargets.length; i < max; i += 1) {
      if (editTargets[i] === cursorEditTarget || editTargets[i].contains(cursorEditTarget)) {
        targetIndex = i;
        break;
      }
    }
    return {
      next(isEcam = false, cursorPosition?: 'start' | 'end') {
        if (cursorEditTarget?.contains(editTargets[targetIndex - 1]) && editTargets[targetIndex - 2]) {
          return self.selectContentAndNukePropagation(editTargets[targetIndex - 2], e, isEcam, cursorPosition);
        } else if (editTargets[targetIndex - 1]) {
          return self.selectContentAndNukePropagation(editTargets[targetIndex - 1], e, isEcam, cursorPosition);
        }
      },
      back(isEcam = false, cursorPosition?: 'start' | 'end') {
        if (editTargets[targetIndex + 1]) {
          return self.selectContentAndNukePropagation(editTargets[targetIndex + 1], e, isEcam, cursorPosition);
        }
      }
    };
  }

  selectContentAndNukePropagation(element: Element, e: React.KeyboardEvent, isEcam = false, cursorPosition?: 'start' | 'end') {
    this.selectElement(element, true);
    if (DomAssertions.hasNoText(element) || cursorPosition === 'start' || cursorPosition === 'end') {
      if (cursorPosition) {
        this.collapse(cursorPosition === 'start');
      } else {
        this.collapse(!isEcam);
      }
    }
    return keyIdentifier.nukePropagation(e);
  }
}
