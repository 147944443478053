import * as Reflux from 'reflux';
import * as _ from 'lodash';
import MediaStore from '../editor/MediaStore';
import Store from '../Store';
import { Page, Sort } from '../projects/DocumentsStore';
import { IMedia } from 'mm-types';
import { AppMenuTypes } from './AppMenus';

export type State = {
  placeOnDocumentClose: 'teamspace' | 'workflow-tasks';
  lastWorkflowTasksUrl: string;
  lastAccessedWorkspaceUid: string;
  lastAccessedProjectUid: string | null;
  lastSelectedProjectUid: string | null;
  lastSelectedMediaItems: IMedia[];
  mylibs: {
    sort: Partial<Sort>;
    page: Partial<Page>;
  };
};

export type AppStateStoreEvent = {
  type: 'menuChange';
  data: { openedMenu: AppMenuTypes };
};

export class AppStateStore extends Store<State> {
  private _editorState: {
    lastAccessEditorTab: null | string;
    tableClipboardRows: { nodeName: string }[];
  };

  private _menuState: {
    openedMenu: string;
  };

  constructor() {
    super();

    this.state = {
      placeOnDocumentClose: 'teamspace',
      lastWorkflowTasksUrl: 'workflow-tasks',
      lastAccessedWorkspaceUid: 'workspace',
      lastAccessedProjectUid: null,
      lastSelectedProjectUid: null,
      lastSelectedMediaItems: [],
      mylibs: {
        sort: {
          sortBy: 'TITLE',
          sortOrder: 'asc'
        },
        page: {
          pageNumber: 0,
          pageSize: 30,
          numOfPages: 0
        }
      }
    };

    this._editorState = {
      lastAccessEditorTab: null,
      tableClipboardRows: []
    };

    this._menuState = {
      openedMenu: ''
    };

    MediaStore.listen(this.onClear, this);
  }

  init() {
    // on url entry / reload get workspace route state from pathname
    let match: RegExpMatchArray | null = null;
    if ((match = location.pathname.match(/^\/teamspaces\/(.*)$/)) !== null) {
      this.state.lastAccessedWorkspaceUid = match[1];
    }
  }

  openMenu(menuName: AppMenuTypes) {
    this._menuState.openedMenu = menuName;
    this.trigger({ type: 'menuChange', data: this._menuState } as AppStateStoreEvent);
  }

  // use getter on a field instead of this.state to enforce using actions to update AppState
  // instead of the direct this.state operations
  getLastAccessedWorkspaceUid() {
    return this.state.lastAccessedWorkspaceUid;
  }

  updateLastWorkflowTasksUrl(workflowTasksUrl: string) {
    this.state.lastWorkflowTasksUrl = workflowTasksUrl;
    this.state.placeOnDocumentClose = 'workflow-tasks';
  }

  getPlaceOnDocumentClose(): 'teamspace' | 'workflow-tasks' {
    return this.state.placeOnDocumentClose;
  }

  getLastWorkflowTasksUrl() {
    return this.state.lastWorkflowTasksUrl;
  }

  getLastSort() {
    return this.state.mylibs.sort;
  }

  getLastPage() {
    return this.state.mylibs.page;
  }

  updateLastAccessedWorkspace(workspaceUid: string) {
    this.state.lastAccessedWorkspaceUid = workspaceUid;
    this.state.placeOnDocumentClose = 'teamspace';
  }

  getLastAccessedProjectUid() {
    return this.state.lastAccessedProjectUid;
  }

  updateLastAccessProject(projectUid: string) {
    this.state.lastAccessedProjectUid = projectUid;
  }

  updateLastSelectedProject(projectUid: string) {
    this.state.lastSelectedProjectUid = projectUid;
  }

  getLastSelectedProjectUid() {
    return this.state.lastSelectedProjectUid;
  }

  updateLastSelectedMediaItems(selectedMediaItems: IMedia[]) {
    this.state.lastSelectedMediaItems = selectedMediaItems;
  }

  getLastSelectedMediaItems() {
    return this.state.lastSelectedMediaItems;
  }

  onClear() {
    // TODO remove this listener because it's not needed but if you do it now, app going to be broken because of the order of importing stores
  }

  getMyLibsSettings() {
    return this.state.mylibs;
  }

  updateMyLibs(myLibsSettings: { sort: Partial<Sort>; page: Partial<Page> }) {
    _.extend(this.state.mylibs, myLibsSettings);
  }

  updateLastAccessEditorTab(tab: string) {
    this._editorState.lastAccessEditorTab = tab;
  }

  getLastAccessEditorTab() {
    return this._editorState.lastAccessEditorTab;
  }

  updateTableClipboardRows(clipboardRows: { nodeName: string }[]) {
    let isValid = false;
    if (Array.isArray(clipboardRows)) {
      isValid = _.every(clipboardRows, function (row) {
        return row.nodeName && row.nodeName === 'TR';
      });
    }
    if (isValid) {
      this._editorState.tableClipboardRows = _.cloneDeep(clipboardRows);
    }
  }

  getTableClipboardRows() {
    return this._editorState.tableClipboardRows;
  }

  clearEditorState() {
    this._editorState = { lastAccessEditorTab: null, tableClipboardRows: [] };
  }
}

const singleton = Reflux.initStore<AppStateStore>(AppStateStore);
export default singleton;
