import * as React from 'react';
import * as _ from 'lodash';
import ProjectStore from '../../../flux/editor/ProjectStore';
import EditorStore from '../../../flux/editor/EditorStore';
import WorkflowStore, { WorkflowStoreEvent } from '../../../flux/settings/WorkflowStore';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';
import ChangeTasksStore, { ChangeTasksStoreEvent } from '../../../flux/editor/ChangeTasksStore';
import SmartContentStore, { SmartContentStoreEvent } from '../../../flux/editor/SmartContentStore';
import PublishStore, { PublishStoreEvent } from '../../../flux/editor/PublishStore';
import WorkflowActionDialog from './dialogs/WorkflowActionDialog';
import { CircularProgress, Dialog, LinearProgress } from 'material-ui';
import { IIndex, IProject, IStage, IWorkFlow, IWorkflowAssignments } from 'mm-types';
import { transitionTo } from '../../../utils/Navigation';
import ActiveUserStore from '../../../flux/common/ActiveUserStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditorRouteParams } from '../../ReactRoutes';
import { PublishStage } from './workflow/stages/PublishStage';
import { DocSettingsRoute } from '../../documents/settingsMenu';
import { PreWorkFlowStage } from './workflow/stages/PreWorkflowStage';
import { WorkflowStartedStage } from './workflow/stages/WorkflowStartedStage';
import { SettingButtonsAndIndicators } from './workflow/SettingButtonsAndIndicators';
import { MenuContainer } from './EditorMenu/MenuElements';
import { WorkflowData } from '../workflowaction/WorkflowActionModal';
import { WorkflowDecisionModel } from '../../../clients/workflows';

export type MenuWorkflowOnSelectParams = {
  menu: string;
  workflowData: Partial<WorkflowData>;
};

export interface Props extends RouteComponentProps<EditorRouteParams> {
  onselected: (e: MenuWorkflowOnSelectParams) => void;
}

export type ActionType = 'SUMMARY' | 'SKIP' | 'APPROVE' | 'ALLOW_OPEN_WITH_WARNING' | 'CLOSED_ONLY' | 'REJECT';

type TabType = 'pre-workflow' | 'publish' | 'workflow-started';

export type State = {
  initializing: boolean;
  validForPublish: boolean;
  validRevisionSettings: boolean;
  isWarning: boolean;
  warningMessage: string;
  informations: string[];
  serverWarnings: string[];
  activeStage: null | IStage;
  stages: null | IStage[];
  editingOverride: boolean;
  showProgress: boolean;
  checking: boolean;
  workflowActionInProgress: boolean;
  isCurrentUserAssignedToStage: boolean;
  areOpenChangeTasks: boolean;
  isPerformingAction: boolean;
  tabType: TabType;
  workflow?: IWorkFlow;
  isPublish?: boolean;
  busyForLock: boolean;
};

class MenuWorkflow extends React.Component<Props, State> {
  private _workflowStoreProxy: Function;
  private _changeTasksStoreProxy: Function;
  private _publishStoreProxy: Function;
  private _smartContentStoreProxy: Function;
  private workflowActionDialog: WorkflowActionDialog | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      checking: false,
      initializing: true,
      validForPublish: false,
      validRevisionSettings: false,
      isWarning: true,
      warningMessage: '',
      informations: [],
      serverWarnings: [],
      activeStage: null,
      stages: null,
      editingOverride: false,
      showProgress: false,
      workflowActionInProgress: false,
      isCurrentUserAssignedToStage: false,
      areOpenChangeTasks: false,
      isPerformingAction: false,
      tabType: 'pre-workflow',
      busyForLock: false
    };
    this.openSettings = this.openSettings.bind(this);
    this.setChecking = this.setChecking.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.performAction = this.performAction.bind(this);
    this.publishWhatsNew = this.publishWhatsNew.bind(this);
    this.startWorkflow = this.startWorkflow.bind(this);
  }

  componentDidMount() {
    this._workflowStoreProxy = WorkflowStore.listen(this._onWorkflowStoreUpdate, this);
    this._changeTasksStoreProxy = ChangeTasksStore.listen(this._onChangeTasksStoreUpdate, this);
    this._publishStoreProxy = PublishStore.listen(this._onPublishStoreUpdate, this);
    this._smartContentStoreProxy = SmartContentStore.listen(this._onShareContentUpdate, this);

    const index = ProjectStore.getIndex();
    const project = ProjectStore.getProject();
    const promises: Promise<any>[] = [];
    if (index && project) {
      const options: Partial<IWorkflowAssignments> = { assignments: true, projectUid: project!.uid };
      promises.push(WorkflowStore.retrieveWorkflowForIndex(index.uid, options));
      if (!project.workflow) {
        this._updateWorkflowStatus();
      }
    }

    if (index) {
      promises.push(ChangeTasksStore.retrieveTasks({ indexUid: index.uid }, true));
    }

    this.setState({ initializing: true });

    Promise.all(promises).then(() => {
      this.setState({
        initializing: false,
        areOpenChangeTasks: ChangeTasksStore.getTasks().find((task) => !task.resolved) ? true : false
      });
    });
    if (WorkflowStore.getInitialState().workflow && WorkflowStore.getInitialState()!.workflow!.activeStage) {
      this.setState({
        workflow: WorkflowStore.getInitialState()!.workflow!,
        activeStage: WorkflowStore.getInitialState()!.workflow!.activeStage
      });
    }
  }

  componentWillUnmount() {
    PublishStore.cancelPendingWorkflowCheck();
    this._workflowStoreProxy();
    this._changeTasksStoreProxy();
    this._publishStoreProxy();
    this._smartContentStoreProxy();
  }

  setChecking(checking = true) {
    this.setState({
      checking
    });
  }

  _updateWorkflowStatus(workflow = ProjectStore.getWorkflowInstance()) {
    const index = ProjectStore.getIndex()!;
    const project = this._getCurrentProject()!;
    let stateUpdate: Partial<State> = { workflowActionInProgress: false };

    if (workflow) {
      stateUpdate.workflow = workflow;
      stateUpdate.activeStage = workflow.activeStage;

      if (workflow.activeStage) {
        stateUpdate.isPublish = workflow.activeStage.publishStep;
        stateUpdate.informations = [];

        if (stateUpdate.isPublish) {
          stateUpdate = this._getPublishStatus(stateUpdate, index, project);
        }
      }

      if (workflow.stages) {
        stateUpdate.stages = workflow.stages;
      }
    } else {
      stateUpdate.informations = [];
      stateUpdate = this._getPublishStatus(stateUpdate, index, project);
    }

    let tabType: TabType = 'pre-workflow';

    if (project) {
      const projectDefinition = ProjectDefinitionStore.getProjectDefinition(project.definitionName);
      const teamspaceWorkfow = projectDefinition && projectDefinition.teamspaceProjectFieldRequirements.workflow;
      const teamspaceCanPublish =
        teamspaceWorkfow && ((teamspaceWorkfow === 'optional' && !index.isLockedForWorkflowToStart) || teamspaceWorkfow === 'disabled');

      if (index && index.workflowUid && !index.isLockedForWorkflowToStart) {
        tabType = 'workflow-started';
      } else if (teamspaceCanPublish && !index.workflowUid) {
        if (!stateUpdate.activeStage || (stateUpdate.activeStage && stateUpdate.activeStage.publishStep)) {
          tabType = 'publish';
          stateUpdate.isPublish = true;
        }
      }
      const newState: State = {
        ...this.state,
        tabType: tabType,
        checking: true,
        ...stateUpdate
      };

      this.setState(newState, () => {
        if (
          workflow &&
          workflow.type !== 'ARCHIVED' &&
          workflow.stages &&
          workflow.stages.length &&
          workflow.stages[workflow.stages.length - 1].status !== 'COMPLETE'
        ) {
          const activeIndex = ProjectStore.getIndex();
          PublishStore.workflowCheck(activeIndex ? activeIndex.workflowUid : undefined).then(() => {
            this.setState({ checking: false });
          });
        } else {
          const params = EditorStore.getDocParams();
          PublishStore.publishCheck(params.projectUid!, params.indexUid!).then(() => {
            this.setState({ checking: false });
          });
        }
      });
    }
  }

  _getPublishStatus(stateUpdate: Partial<State>, index: IIndex, project: IProject) {
    const isInterimProject = this.props.match.params.documentIndexUid === project.interimIndexUid;
    // Below check explained:
    // - is this a draft that has a published interim item that needs merging
    // - is this an interim item that has not been published
    const outstandingInterim = index.interimIndexToMergeUid || project.interimIndexUid;

    const validRevisionSettings = !['distributionDate', 'revisionDate', 'expiryDate'].find((fieldName) => index[fieldName] === undefined);

    // block publication if interim exists and not on it currently
    const isValid = validRevisionSettings && (isInterimProject || !outstandingInterim);

    const informationsState = index.definitionUpdateAvailable
      ? [
          'There is an update to your current index definition. You can choose to update to the latest version or continue without updating.'
        ]
      : [];

    return {
      ...stateUpdate,
      validForPublish: isValid,
      validRevisionSettings: validRevisionSettings,
      informations: informationsState
    };
  }

  _onChangeTasksStoreUpdate(state: ChangeTasksStoreEvent) {
    if (state.tasks) {
      this.setState({
        areOpenChangeTasks: state.tasks.find((task) => !task.resolved) ? true : false
      });
    }
  }

  _onShareContentUpdate(e: SmartContentStoreEvent) {
    if (e.type === 'sharedUsageDeleted' || e.type === 'sharedUsageUpdated') {
      this._updateWorkflowStatus();
    }
  }

  _onWorkflowStoreUpdate(e: WorkflowStoreEvent) {
    if (e.type === 'workflowInstance' || e.type === 'workflow') {
      this._updateWorkflowStatus(e.workflow!);
    }
  }

  _onPublishStoreUpdate(e: PublishStoreEvent) {
    if (e.type === 'publishCheck') {
      if (e.data && e.data.errors) {
        const warnings = e.data.errors;
        if (warnings.length > 0) {
          this.setState({ serverWarnings: warnings.map((w) => w.message), validForPublish: false });
        } else if (warnings.length === 0) {
          this.setState({ serverWarnings: [], validForPublish: true });
        }
      } else if (e.data.error) {
        console.log(e);
      }
    }
  }

  performAction(action: ActionType, label: string) {
    const index = ProjectStore.getIndex();
    this.setState({ workflowActionInProgress: action === 'SUMMARY' ? this.state.workflowActionInProgress : true }, () => {
      this.props.onselected({
        menu: 'workflow',
        workflowData: {
          action: { type: action as WorkflowDecisionModel, label: label },
          activeStage: this.state.activeStage!,
          stages: this.state.stages!,
          workflowUid: this.state.workflow!.uid!,
          indexUid: index!.uid!
        }
      });
    });
  }

  handleActionClick(e: React.MouseEvent<{}>, action: ActionType, label: string) {
    e.preventDefault();
    e.stopPropagation();
    const editor = EditorStore.getEditor();
    if (editor) {
      editor.blur();
    }

    const onContinue = () => {
      this.performAction(action, label);
    };

    if (this.state.activeStage) {
      // Show warning if there're open tasks, action is a forward one & stage setting is set to do so
      const showWarning =
        this.state.areOpenChangeTasks &&
        ['SKIP', 'APPROVE'].indexOf(action) > -1 &&
        ['ALLOW_OPEN_WITH_WARNING', 'CLOSED_ONLY'].indexOf(this.state.activeStage.changeTaskEnforcementMethod) > -1;

      if (showWarning) {
        this.workflowActionDialog?.show({
          onContinue: onContinue,
          preventAction: this.state.activeStage.changeTaskEnforcementMethod === 'CLOSED_ONLY'
        });
      } else {
        onContinue();
      }
    } else {
      onContinue();
    }
    e.stopPropagation();
    e.preventDefault();
  }

  _getCurrentProject() {
    return ProjectStore.getProject();
  }

  openSettings(e: React.MouseEvent<any>, destination: DocSettingsRoute) {
    e.preventDefault();
    e.stopPropagation();

    EditorStore.blurEditor(() => {
      transitionTo(`doc-${destination}-settings`, {
        projectUid: this.props.match.params.projectUid,
        indexUid: ProjectStore.getIndex()?.uid
      });
    });
  }

  publishWhatsNew(e: React.MouseEvent<{}>) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isPerformingAction: true });

    WorkflowStore.publishWhatsNew(ProjectStore.getIndex()!, ProjectStore.getProject()!)
      .then(() => {
        return ProjectStore.reInitProject();
      })
      .then(() => {
        this.setState({ isPerformingAction: false });
        WorkflowStore.whatsNewPublished();
      });
  }

  startWorkflow() {
    this.setState({ isPerformingAction: true });

    WorkflowStore.startWorkflow(ProjectStore.getIndex()!)
      .then(() => {
        return ProjectStore.reInitProject();
      })
      .then(() => {
        const activeIndex = ProjectStore.getIndex();
        return PublishStore.workflowCheck(activeIndex ? activeIndex.workflowUid : undefined);
      })
      .then(() => {
        this.setState({ isPerformingAction: false });
      });
  }

  _showOverrideEditButton() {
    return (
      ActiveUserStore.isAdmin() ||
      (ProjectStore.getProject() && ActiveUserStore.isAdminOfSelectedProjectTeamspace(ProjectStore.getProject()!.workspaceUid))
    );
  }

  _isEditingOverride() {
    return !ProjectStore.getIndex()?.isLocked;
  }

  isUserWorkflowAdmin() {
    if (ActiveUserStore.isAdmin()) {
      return true;
    }

    const project = ProjectStore.getProject();
    if (!!project) {
      const canManageWorkflow = project.currentUserPermissions?.canManageWorkflow;
      const isTSAdmin = ActiveUserStore.isAdminOfSelectedProjectTeamspace(project.workspaceUid);

      return isTSAdmin || canManageWorkflow;
    }

    return false;
  }

  _canStartRevision() {
    return !this.state.isPerformingAction && this.doesModeAllowWorkflow() && this.isUserWorkflowAdmin();
  }

  canPerformReject() {
    if (this.state.checking) {
      return false;
    }

    if (!this.doesModeAllowWorkflow()) {
      return false;
    }
    if (this._userAlreadyPerformedAction()) {
      return false;
    }
    return !this._warningsAvailable();
  }

  canPerformAction() {
    if (this.state.checking) {
      return false;
    }

    if (!this.doesModeAllowWorkflow()) {
      return false;
    }

    if (this._userAlreadyPerformedAction()) {
      return false;
    }

    if (this.isPublishing()) {
      return this._enablePublishButton();
    }

    if (this._warningsAvailable()) {
      return false;
    }

    return true;
  }

  canChangeRevisionSettings() {
    return this.doesModeAllowWorkflow() && !!ProjectStore.getProject()?.currentUserPermissions?.canRead;
  }

  canChangeGeneralSettings() {
    return this.doesModeAllowWorkflow() && !!ProjectStore.getProject()?.currentUserPermissions?.canEditSettings;
  }

  doesModeAllowWorkflow() {
    return !!EditorStore.doesModeAllowMenuItem('WORKFLOW');
  }
  _userAlreadyPerformedAction() {
    if (this.state.activeStage && !this.state.workflowActionInProgress) {
      const user = ActiveUserStore.getUser()!;
      // Find out if user already has performed action
      const userAssignment = this.state.activeStage.assignments.find((assignment) => assignment.uid === user.uid);
      if (userAssignment && userAssignment.reviewResult) {
        return true;
      }
    }
    return false;
  }

  _enablePublishButton() {
    let validForPublish = false;
    const validRevisionSettings = this.state.validRevisionSettings;
    if (this.state.activeStage && this.state.activeStage.publishStep) {
      const project = ProjectStore.getProject()!;
      validForPublish = this.state.validForPublish && project?.currentUserPermissions?.canPublish;
    }
    return validForPublish && validRevisionSettings;
  }

  isPublishing(): boolean {
    if (this.state.tabType === 'publish') {
      return true;
    }

    return !!(this.state.activeStage && this.state.activeStage.publishStep);
  }

  isWorkflowFinished() {
    if (this.state.workflow) {
      if (this.state.workflow.type === 'ACTIVE' && typeof this.state.workflow.activeStage === 'undefined') {
        return true;
      } else {
        return this.state.workflow && this.state.workflow.type === 'ARCHIVED';
      }
    } else {
      if (this.state.tabType === 'publish') {
        return false;
      } else {
        return ProjectStore.getIndex()?.isPublished;
      }
    }
  }

  isReadOnly() {
    return EditorStore.isReadOnly();
  }

  _warningsAvailable() {
    return this.state.serverWarnings.length > 0;
  }

  indexLocked() {
    return ProjectStore.getIndex()?.lockedStatus === 'LOCKED_AFTER_PUBLISH';
  }

  updatedDefinitionAvailable() {
    return this.state.informations.length > 0;
  }

  commonButtonsAndIndicators() {
    return (
      <SettingButtonsAndIndicators
        canChangeGeneralSettings={this.canChangeGeneralSettings()}
        canChangeRevisionSettings={this.canChangeRevisionSettings()}
        isUpdatedDefinitionAvailable={this.updatedDefinitionAvailable()}
        isIndexLocked={this.indexLocked()}
        hasValidRevisionSettings={this.state.validRevisionSettings}
        doesModeAllowWorkflow={this.doesModeAllowWorkflow()}
        isPublishing={this.isPublishing()}
        isUserWorkflowAdmin={this.isUserWorkflowAdmin()}
        serverWarnings={this.state.serverWarnings}
        informations={this.state.informations}
        onOpenSettings={this.openSettings}
      />
    );
  }

  render() {
    return (
      <nav className="page-body-header icon-header editor-workflow-menu no-box-shadow">
        <div className="nav-wrapper">
          <MenuContainer>
            {this.state.tabType === 'publish' && (
              <PublishStage
                isWorkflowFinished={this.isWorkflowFinished() ?? false}
                isValidForPublish={this.state.validForPublish}
                isReadOnly={this.isReadOnly()}
                onPerformAction={this.performAction}
                onPublishWhatsNew={this.publishWhatsNew}
                indicators={this.commonButtonsAndIndicators()}
              />
            )}

            {this.state.tabType === 'pre-workflow' && (
              <PreWorkFlowStage
                doesModeAllowWorkflow={this.doesModeAllowWorkflow()}
                isUserWorkflowAdmin={this.isUserWorkflowAdmin()}
                isInitializing={this.state.initializing}
                canStartRevision={this._canStartRevision()}
                onStartWorkflow={this.startWorkflow}
              />
            )}

            {this.state.tabType === 'workflow-started' && (
              <WorkflowStartedStage
                doesModeAllowWorkflow={this.doesModeAllowWorkflow()}
                indicators={this.commonButtonsAndIndicators()}
                isUserWorkflowAdmin={this.isUserWorkflowAdmin()}
                onPerformAction={this.performAction}
                isWorkflowFinished={this.isWorkflowFinished() ?? false}
                activeStage={this.state.activeStage}
                canPerformAction={this.canPerformAction()}
                canPerformReject={this.canPerformReject()}
                isEditingOverride={this._isEditingOverride()}
                onActionClick={this.handleActionClick}
                showOverrideEditButton={this._showOverrideEditButton() ?? false}
                workflow={this.state.workflow}
                onChecking={this.setChecking}
              />
            )}
          </MenuContainer>

          {this.state.showProgress && (
            <Dialog style={{ zIndex: 999 }} title="Publishing Document..." open={true} modal={true}>
              <LinearProgress mode="indeterminate" />
            </Dialog>
          )}
          {this.state.initializing && (
            <div className="display-inline-block padding-top-m padding-left-xl">
              <CircularProgress />
            </div>
          )}
          <WorkflowActionDialog ref={(ref) => (this.workflowActionDialog = ref)} />
        </div>
      </nav>
    );
  }
}

export default withRouter(MenuWorkflow);
