import * as React from 'react';
import CloseModeBar from './CloseModeBar';

export type Props = {
  onClose?: () => void;
  message?: string;
  showClose?: boolean;
  className?: string;
};

export type State = {};

const DefaultModeBar = ({ message = '', showClose = false, className = '', onClose }: Props) => {
  React.useEffect(() => {
    document.body.classList.add('mode-bar-rendered');
    // returned function will be called on component unmount
    return () => {
      document.body.classList.remove('mode-bar-rendered');
    };
  }, []);

  return (
    <div className={'mode-bar-inner' + (className ? ' ' + className : '')}>
      <div className="message">{message}</div>
      <CloseModeBar showClose={showClose} onClose={onClose} />
    </div>
  );
};

export default DefaultModeBar;
