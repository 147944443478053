import { Cancelled, mm } from './base-clients';
import { INotification, INotificationSettings, INotificationType, NotifStatus } from 'mm-types';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import userUtil from '../components/documents/team/util/user';
import QueryUtil from '../utils/QueryUtil';

export type NotificationResponse = {
  nextCursor: string;
  notifications: INotification[];
  previousCursor: string;
  now: string;
  totalUnseenCount: number;
  totalUnreadCount: number;
  info: {
    totalUnseenCount: number;
    latestResponseLength: number;
    containsInProgress: boolean;
    containsInError: boolean;
  };
};

let getAllSource: CancelTokenSource | null = null;

const pdfGenStatuses = {
  QUEUED: { step: 0, friendlyName: 'Queued...' },
  STARTING_PDF: { step: 1, friendlyName: 'Starting...' },
  PREPARING_PDF: { step: 2, friendlyName: 'Preparing PDF...' },
  PENDING_PDF_GENERATION: { step: 3, friendlyName: 'Waiting in queue...' },
  GENERATING_PDF: { step: 4, friendlyName: 'Generating PDF...' },
  WATERMARKING_PDF: { step: 5, friendlyName: 'Watermarking PDF...' },
  STORING_PDF: { step: 6, friendlyName: 'Storing PDF...' }
};

const aerosyncPublishStatuses = {
  QUEUED: { step: 0, friendlyName: 'Queued...' },
  GENERATING_FILES_FOR_PUBLICATION: { step: 1, friendlyName: 'Starting...' },
  PREPARING_INDEX: { step: 2, friendlyName: 'Preparing Index...' },
  GENERATING_INDEX: { step: 3, friendlyName: 'Generating Index...' },
  GENERATING_SEARCH_INDEX: { step: 4, friendlyName: 'Generating Index...' },
  PACKAGING_INDEX: { step: 5, friendlyName: 'Packaging Index...' },
  PUBLISHING_IN_PROGRESS: { step: 6, friendlyName: 'Publishing in Progress...' },
  RECORDING_PUBLICATION_RESULTS: { step: 7, friendlyName: 'Recording Publication Results...' }
};

const aerosyncExtPublishStatuses = {
  QUEUED: { step: 0, friendlyName: 'Queued...' },
  STARTING_EXT_GENERATION: { step: 1, friendlyName: 'Starting...' },
  PREPARING_EXT: { step: 2, friendlyName: 'Preparing External manual...' },
  GENERATING_EXT: { step: 3, friendlyName: 'Generating External manual...' },
  PACKAGING_EXT: { step: 4, friendlyName: 'Packaging External manual...' },
  PUBLISHING_EXT: { step: 5, friendlyName: 'Publishing External manual...' },
  RECORDING_EXT_PUBLICATION: { step: 6, friendlyName: 'Recording External Publication...' }
};

const readConfirmsCsvExportStatuses = {
  QUEUED: { step: 0, friendlyName: 'Queued...' },
  STARTING_EXPORT: { step: 1, friendlyName: 'Generating Report...' }
};

const codeToMessage: {
  [code: number]: string;
} = {
  40090: 'Unknown zip format'
};

export async function getAll(userUid: string): Promise<NotificationResponse | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  try {
    const response = await mm.get<NotificationResponse>(`/notifications?userUid=${userUid}`, {
      cancelToken: getAllSource.token
    });
    getAllSource = null;
    parse(response.data);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export interface FindNotificationsParams {
  userUid: string;
  projectUid: string;
  status?: NotifStatus;
  type: INotificationType;
  creationTime?: number;
}

export async function find(
  { userUid, projectUid: metadataString, type, status, creationTime }: FindNotificationsParams,
  cancelToken: CancelTokenSource
): Promise<NotificationResponse | Cancelled> {
  const url = QueryUtil.getUrlWithParams('/notifications/find', {
    userUid,
    status,
    type,
    metadataString,
    creationTime: (creationTime || 1182139200000).toString()
  });

  try {
    const response = await mm.get<NotificationResponse>(url, {
      cancelToken: cancelToken.token
    });
    parse(response.data);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getNextSet(uid: string, nextCursor: string) {
  const response = await mm.get<NotificationResponse>(`/notifications?userUid=${uid}&cursor=${nextCursor}`);
  parse(response.data);
  return response.data;
}

/**
 * Edits an notification by id
 */
export async function edit(uid: string, notification?: Partial<INotification>) {
  const response = await mm.post(`/notifications/${uid}`, notification);
  return parse(response.data);
}

/**
 * Retry an notification by id
 */
export async function retry(uid: string) {
  const response = await mm.post(`/notifications/${uid}/retry`, {});
  return response.data;
}

/**
 * Removes an notification by id
 */
export async function remove(uid: string) {
  const response = await mm.delete(`/notifications/${uid}`);
  return response.data;
}

/**
 * Sets notification to be read or unread
 */
export async function setAsRead(notifs?: INotification[]) {
  const response = await mm.post(`/notifications/read`, notifs);
  return response.data;
}

/**
 * Sets notification to be read or unread
 */
export async function getSettings(userUid: string) {
  const response = await mm.get(`/users/${userUid}/settings`);
  return response.data;
}

/**
 * Sets notification to be read or unread
 */
export async function editSettings(settings: INotificationSettings, userUid: string) {
  const notifPrefs = settings.notificationPreferences;
  const response = await mm.post(`/users/${userUid}/settings`, { notificationPreferences: notifPrefs });
  return response.data;
}

function _formatDate(date) {
  // if yesterday is same day as notif date
  if (moment().subtract(1, 'day').isSame(moment(date), 'day')) {
    return 'yesterday';
  } else if (moment().diff(date, 'hours') <= 24) {
    return date.fromNow();
  } else {
    return date.format('DD-MMM');
  }
}

const progressBasedTypes: INotificationType[] = [
  'PDF_EXPORT',
  'PDF_PUBLICATION',
  'AEROSYNC_PUBLICATION',
  'AEROSYNC_PREVIEW',
  'COMPLIANCE_REPORT',
  'EXT_AEROSYNC_PUBLICATION',
  'EXT_AEROSYNC_PREVIEW',
  'PROJECT_IMPORT',
  'PROJECT_COPY',
  'PROJECT_EXPORT',
  'READCONFIRM_EXPORT',
  'WORKFLOW_EXPORT',
  'FEEDBACK_EXPORT',
  'QUIZ_EXPORT',
  'TOC_COPY',
  'CREATE_INTERIM',
  'DOCUMENT_EXPORT',
  'WORKFLOW_ACTIONS_EXPORT',
  'WORKFLOW_REVIEW'
];

function parse(response: NotificationResponse) {
  response.info = {
    totalUnseenCount: 0,
    latestResponseLength: 0,
    containsInProgress: false,
    containsInError: true
  };

  response.info.containsInProgress = false;
  response.info.containsInError = false;
  response.info.totalUnseenCount = response.totalUnseenCount;

  let unseenCount = response.totalUnseenCount === 0 ? null : response.totalUnseenCount;
  if (unseenCount !== null && unseenCount >= 0) {
    // calc relative isNew's for paging
    unseenCount = unseenCount - response.notifications.filter((n) => n.isNew).length;
  }

  response.info.latestResponseLength = response.notifications.length - 1;

  for (const notif of response.notifications) {
    if (notif.error) {
      notif.error.friendlyMessage = codeToMessage[notif.error.code];
      if (!notif.error.friendlyMessage && notif.error.code) {
        notif.error.friendlyMessage = notif.error.message;
      }
    }

    notif.isNew = false;
    if (notif.status === 'UNREAD' && unseenCount !== null && unseenCount > 0) {
      notif.isNew = true;
      unseenCount--;
    }

    notif.receivedFormatAgo = _formatDate(moment(notif.created));

    if (!response.info.containsInProgress && notif.status === 'IN_PROGRESS') {
      response.info.containsInProgress = true;
    }

    // multiple state, progress based notifications
    if (progressBasedTypes.indexOf(notif.type) !== -1) {
      const currentStatus = notif.steps[notif.steps.length - 1];

      if (notif.type === 'PDF_EXPORT' || notif.type === 'PDF_PUBLICATION') {
        notif.isReady = currentStatus === 'FINISHED_PDF';
        notif.isError = currentStatus === 'FAILED_PDF';
      } else if (
        notif.type === 'AEROSYNC_PUBLICATION' ||
        notif.type === 'AEROSYNC_PREVIEW' ||
        notif.type === 'EXT_AEROSYNC_PUBLICATION' ||
        notif.type === 'EXT_AEROSYNC_PREVIEW'
      ) {
        notif.isReady = currentStatus === 'FINISHED_PUBLICATION';
        notif.isError = currentStatus === 'FAILED_PUBLICATION';
      } else if (notif.type === 'COMPLIANCE_REPORT') {
        notif.isReady = currentStatus === 'FINISHED_COMPLIANCE_REPORT';
        notif.isError = currentStatus === 'FAILED_COMPLIANCE_REPORT';
      } else if (notif.type === 'PROJECT_IMPORT') {
        notif.isReady = currentStatus === 'FINISHED_IMPORT';
        notif.isError = currentStatus === 'FAILED_IMPORT' || currentStatus === 'EXPIRED_PROJECT';
      } else if (notif.type === 'PROJECT_COPY') {
        notif.isReady = currentStatus === 'FINISHED_COPY';
        notif.isError = currentStatus === 'FAILED_COPY' || currentStatus === 'EXPIRED_PROJECT';
      } else if (
        [
          'PROJECT_EXPORT',
          'FEEDBACK_EXPORT',
          'READCONFIRM_EXPORT',
          'WORKFLOW_EXPORT',
          'QUIZ_EXPORT',
          'DOCUMENT_EXPORT',
          'WORKFLOW_ACTIONS_EXPORT'
        ].indexOf(notif.type) !== -1
      ) {
        notif.isReady = currentStatus === 'FINISHED_EXPORT';
        notif.isError = currentStatus === 'FAILED_EXPORT';
      } else if (notif.type === 'TOC_COPY' || notif.type === 'CREATE_INTERIM') {
        notif.isReady = currentStatus === 'SUCCESS';
        notif.isError = currentStatus === 'FAILED';
      } else if (notif.type === 'WORKFLOW_REVIEW') {
        notif.isReady = currentStatus === 'FINISHED';
        notif.isError = currentStatus === 'FAILED_WORKFLOW_REVIEW';
      }
      if (currentStatus === 'EXPIRED_PROJECT') {
        notif.error = { code: 40091, message: 'Expired project', friendlyMessage: 'Expired project' };
      }
      if (!notif.isReady && notif.status === 'IN_PROGRESS') {
        if (notif.type === 'PDF_EXPORT' || notif.type === 'PDF_PUBLICATION') {
          notif.processingStatus = pdfGenStatuses[currentStatus];
        } else if (notif.type === 'AEROSYNC_PUBLICATION' || notif.type === 'AEROSYNC_PREVIEW') {
          notif.processingStatus = aerosyncPublishStatuses[currentStatus];
        } else if (notif.type === 'EXT_AEROSYNC_PUBLICATION' || notif.type === 'EXT_AEROSYNC_PREVIEW') {
          notif.processingStatus = aerosyncExtPublishStatuses[currentStatus];
        } else if (notif.type === 'COMPLIANCE_REPORT') {
          notif.processingStatus = { friendlyName: 'Generating report...' };
        } else if (notif.type === 'PROJECT_IMPORT') {
          notif.processingStatus = { friendlyName: 'Importing project...' };
        } else if (notif.type === 'PROJECT_COPY') {
          notif.processingStatus = { friendlyName: 'Copying project...' };
        } else if (notif.type === 'PROJECT_EXPORT') {
          notif.processingStatus = { friendlyName: 'Exporting project...' };
        } else if (notif.type === 'FEEDBACK_EXPORT') {
          notif.processingStatus = { friendlyName: 'Exporting feedbacks...' };
        } else if (notif.type === 'READCONFIRM_EXPORT') {
          notif.processingStatus = readConfirmsCsvExportStatuses[currentStatus];
        } else if (notif.type === 'WORKFLOW_EXPORT') {
          notif.processingStatus = { friendlyName: 'Exporting workflows...' };
        } else if (notif.type === 'QUIZ_EXPORT') {
          notif.processingStatus = { friendlyName: 'Exporting quiz...' };
        } else if (notif.type === 'TOC_COPY') {
          notif.processingStatus = { friendlyName: 'Duplicating ' + notif.tocableUid + '...' };
        } else if (notif.type === 'CREATE_INTERIM') {
          notif.processingStatus = { friendlyName: 'Creating Interim Revision...' };
        } else if (notif.type === 'DOCUMENT_EXPORT') {
          notif.processingStatus = { friendlyName: 'Exporting Publication History...' };
        } else if (notif.type === 'WORKFLOW_ACTIONS_EXPORT') {
          notif.processingStatus = { friendlyName: 'Export Workflow Actions...' };
        } else if (notif.type === 'WORKFLOW_REVIEW') {
          notif.processingStatus = { friendlyName: 'Workflow in Review...' };
        }
      } else {
        notif.processingStatus = null;
      }
    } else if (notif.type === 'COMMENT') {
      notif.title =
        notif.notificationReason === 'MENTION'
          ? 'Comment Mention'
          : notif.notificationReason === 'COMMENT_UPDATED'
          ? 'Comment Updated'
          : notif.notificationReason === 'COMMENT_DELETED'
          ? 'Comment Deleted'
          : notif.notificationReason === 'COMMENT_RESOLVED'
          ? 'Comment Resolved'
          : notif.notificationReason === 'COMMENT_REPLY'
          ? 'Comment Reply'
          : notif.notificationReason === 'COMMENT_CREATE'
          ? 'New Comment'
          : 'Comment';

      notif.comment.commenter.avatarUrl = userUtil.imagePath(notif.comment.commenter);
    }

    if (notif.isError && notif.isNew) {
      response.info.containsInError = true;
    }
  }
}
