import React from 'react';
import * as _ from 'lodash';
import { IDiffProperties } from 'mm-types';
import { GroupedPropertiesLogType } from '../components/AttributeActivityDropdown';

export type AttributeActivityType = {
  groupedPropertiesLog?: GroupedPropertiesLogType[];
  getUpdatedHtml: (html: string, hoveredDiffPropertyNid: string | null, selectedDiffPropertyNid?: string) => string;
  removeHighlights: (node: HTMLElement | Document) => void;
};

export const useAttributeActivityLog = (diffProperties?: IDiffProperties[]): AttributeActivityType => {
  const [groupedPropertiesLog, setGroupedPropertiesLog] = React.useState<GroupedPropertiesLogType[]>();

  React.useEffect(() => {
    if (diffProperties && diffProperties?.length > 0) {
      const diffPropertiesWithUniqueId: IDiffProperties[] = diffProperties.map((diffProperty) => ({ id: _.uniqueId(), ...diffProperty }));

      const groupsByNid: { [nid: string]: IDiffProperties[] } = diffPropertiesWithUniqueId.reduce(function (results, diffProperty) {
        (results[diffProperty.nid] = results[diffProperty.nid] || []).push(diffProperty);
        return results;
      }, {});

      const groupedPropertiesLog: GroupedPropertiesLogType[] = Object.values(groupsByNid).reduce(function (
        groupedPropertiesLog: any,
        org: any
      ) {
        groupedPropertiesLog.push({
          nid: org[0].nid,
          elementName: org[0].elementName,
          diffProperties: org
        });
        return groupedPropertiesLog;
      },
      []);

      setGroupedPropertiesLog(groupedPropertiesLog);
    } else {
      setGroupedPropertiesLog([]);
    }
  }, [diffProperties]);

  const getUpdatedHtml = (html: string, hoveredDiffPropertyNid: string | null = null, selectedDiffPropertyNid?: string) => {
    const parser = new DOMParser();
    let newHtml = parser.parseFromString(html, 'text/html');
    removeHighlights(newHtml);
    if (hoveredDiffPropertyNid) {
      newHtml.querySelector(`[data-nid="${hoveredDiffPropertyNid}"]`)?.classList.add('highlight-selected');
    } else if (selectedDiffPropertyNid) {
      newHtml.querySelector(`[data-nid="${selectedDiffPropertyNid}"]`)?.classList.add('highlight-selected');
    } else {
      document.body.classList.remove('highlight-selected');
    }
    return newHtml.body.innerHTML;
  };

  const removeHighlights = (node: HTMLElement | Document): void => {
    node.querySelectorAll(`.highlight-selected`).forEach((node) => node.classList.remove('highlight-selected'));
  };

  return {
    groupedPropertiesLog,
    getUpdatedHtml,
    removeHighlights
  };
};
