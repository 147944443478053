import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { INotification, NotificationTemplates } from 'mm-types';
import { PROJECT_EXPORT } from './notificationTemplates/PROJECT_EXPORT';
import { PDF_EXPORT } from './notificationTemplates/PDF_EXPORT';
import { FEEDBACK_EXPORT } from './notificationTemplates/FEEDBACK_EXPORT';
import { READCONFIRM_EXPORT } from './notificationTemplates/READCONFIRM_EXPORT';
import { WORKFLOW_EXPORT } from './notificationTemplates/WORKFLOW_EXPORT';
import { QUIZ_EXPORT } from './notificationTemplates/QUIZ_EXPORT';
import { DOCUMENT_EXPORT } from './notificationTemplates/DOCUMENT_EXPORT';
import { WORKFLOW_ACTIONS_EXPORT } from './notificationTemplates/WORKFLOW_ACTIONS_EXPORT';

function _showAsFromNow(timeAsString?: string) {
  const time = moment(timeAsString);
  if (time.diff(moment(new Date())) < 0) {
    // is in past
    return 'overdue';
  } else {
    return 'due ' + time.fromNow();
  }
}

function _generateMembershipDesc(entry: INotification) {
  if (entry.membership.role && _.isEmpty(entry.membership.permissions)) {
    return (
      <div className="info">
        You have been assigned the role of {entry.membership.role.name} for {entry.project!.name}.
      </div>
    );
  } else if (entry.membership.role && !_.isEmpty(entry.membership.permissions)) {
    return (
      <div className="info">
        You have been assigned the role of {entry.membership.role.name} for {entry.project!.name} with the following extra permissions:{' '}
        {_generatePermissionInfo(entry)}
      </div>
    );
  } else if (_.isEmpty(entry.membership.permissions) && !entry.membership.role) {
    return <div className="info">Your extra permission privileges have been removed on {entry.project!.name}</div>;
  } else if (!_.isEmpty(entry.membership.permissions) && !entry.membership.role) {
    return (
      <div className="info">
        Your permissions for {entry.project!.name} have been modified to: {_generatePermissionInfo(entry)}
      </div>
    );
  }
}

function _generatePermissionInfo(entry: INotification) {
  const permissionsDom: JSX.Element[] = [];
  _.each(entry.membership.permissions, (value, name) => {
    const permissionValue = value ? ': On' : ': Off';
    const permissionName = (name as any).replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/^./, function (str) {
      return str.toUpperCase();
    });

    permissionsDom.push(
      <div key={permissionName}>
        <label>{permissionName}</label> <span>{permissionValue}</span>
      </div>
    );
  });
  return permissionsDom;
}

const NON_PROJECT_EXPORTS = [
  'FEEDBACK_EXPORT',
  'WORKFLOW_EXPORT',
  'READCONFIRM_EXPORT',
  'QUIZ_EXPORT',
  'DOCUMENT_EXPORT',
  'WORKFLOW_ACTIONS_EXPORT'
];

export function _generateTitle(notif: INotification) {
  function genericTitle() {
    const name = notif.project && notif.project.name ? 'Doc: ' + notif.project!.name : 'Doc: ...';
    const rev = notif.index && notif.index.revisionNumber ? ' Rev ' + notif.index.revisionNumber : '';
    return rev ? (
      <h2 title={name + rev}>
        <span>{name}</span>
        <span>{rev}</span>
      </h2>
    ) : (
      <h2 title={name + rev}>
        <span>{name}</span>
      </h2>
    );
  }
  return NON_PROJECT_EXPORTS.indexOf(notif.type) !== -1 ? notif.media?.filename : genericTitle();
}

export function _getVariantName(notif) {
  if (!notif.variant) {
    return null;
  }

  return <h2>Variant: {notif.variant.name}</h2>;
}

// drive template and action behaviour based on preconfigured notification template/types
// TODO roll out to other types when have time (defined in template of list component)
const notificationTemplates: NotificationTemplates = {
  USAGE_AUTO_UPDATED: {
    superType: 'share',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Shared content automatically updated</h1>
          {_generateTitle(notif)}
          <div className="info">Shared content used in this document has been automatically updated.</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">share</span>
        </div>
      );
    }
  },

  USAGE_UPDATE_AVAILABLE: {
    superType: 'share',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Shared content updated pending approval</h1>
          {_generateTitle(notif)}
          <div className="info">Shared content used in this document has been updated and is pending your approval.</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">share</span>
        </div>
      );
    }
  },

  ASSIGNED_TO_TASK: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Assigned to task: {notif.task.title}</h1>
          {_generateTitle(notif)}
          <div className="info">
            You have been assigned to {notif.task.participants[0].author ? 'author' : 'review'} Task: {notif.task.title}
          </div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-tasks material-icons" />
        </div>
      );
    }
  },

  TASK_AUTHORING_COMPLETE: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Task authoring complete: {notif.task.title}</h1>
          {_generateTitle(notif)}
          <div className="info">All authors have completed authoring for {notif.task.title}</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-tasks material-icons" />
        </div>
      );
    }
  },

  TASK_DISPOSITION_NOT_OK: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>A Dispositioned Task: {notif.task.title} has been updated</h1>
          {_generateTitle(notif)}
          <div className="info">
            A user has made new authoring changes to Task: {notif.task.title} that was previously dispositioned as Not OK.
          </div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-tasks material-icons" />
        </div>
      );
    }
  },

  ADDITIONAL_CHANGES_ON_TASK: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Completed Task: {notif.task.title} has been updated</h1>
          {_generateTitle(notif)}
          <div className="info">
            {notif.task.participants[0].user.displayName} edited a completed task: {notif.task.title}
          </div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-tasks material-icons" />
        </div>
      );
    }
  },

  TASK_AN_AUTHOR_COMPLETE: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Task authoring complete: {notif.task.title}</h1>
          {_generateTitle(notif)}
          <div className="info">An author has completed authoring for {notif.task.title}</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-tasks material-icons" />
        </div>
      );
    }
  },

  COMMENT: {
    superType: 'comment',
    className: 'notif-item-comment',
    generateDescription(notif: INotification) {
      const comment = notif.comment.text.substr(0, 50) + '...';

      return (
        <div className="middle-side">
          <h1>{notif.title}</h1>
          {_generateTitle(notif)}
          <div className="info">
            From {notif.comment.commenter.displayName}: &quot;{comment}
          </div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <img className="popup-avatar" src={notif.comment.commenter.avatarUrl} />
        </div>
      );
    }
  },

  WORKFLOW_STAGE_TRANSITION: {
    superType: 'workflow',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Workflow stage change</h1>
          {_generateTitle(notif)}
          <div className="info">You are assigned to a stage that has just been activated</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-workflow material-icons" />
        </div>
      );
    }
  },

  WORKFLOW_EXT_STAGE_TRANSITION: {
    superType: 'workflow',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Workflow stage change</h1>
          {_generateTitle(notif)}
          <div className="info">You are assigned to a stage that has just been activated</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-workflow material-icons" />
        </div>
      );
    }
  },

  WORKFLOW_STAGE_REJECT: {
    superType: 'workflow',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Workflow stage change</h1>
          {_generateTitle(notif)}
          <div className="info">A workflow stage has been rejected</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon icon-workflow material-icons" />
        </div>
      );
    }
  },

  NEW_PROJECT_MEMBERSHIP: {
    superType: 'membership',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Membership Update</h1>
          {_generateTitle(notif)}
          <div className="info">
            You have been made a member of {notif.project!.name}, with a role of {notif.membership.role.name}
          </div>
        </div>
      );
    }
  },

  UPDATED_PROJECT_MEMBERSHIP: {
    superType: 'membership',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Membership Update</h1>
          {_generateTitle(notif)}
          {_generateMembershipDesc(notif)}
        </div>
      );
    }
  },

  DELETED_PROJECT_MEMBERSHIP: {
    superType: 'membership',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Membership Update</h1>
          {_generateTitle(notif)}
          {notif.membership.role ? (
            <div className="info">
              You have been removed as {notif.membership.role.name} from {notif.project!.name}.{' '}
            </div>
          ) : null}
          {!notif.membership.role ? <div className="info">You have been removed from {notif.project!.name}. </div> : null}
        </div>
      );
    }
  },

  TASK_NUDGE_REMINDER: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>Task Reminder: {notif.task.title}</h1>
          {_generateTitle(notif)}
          <div className="info">The task assigned to you is {_showAsFromNow(notif.task.expectedCompletionDate)}</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">access_time</span>
        </div>
      );
    }
  },

  TASK_POKE_REMINDER: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1 style={{ color: '#CD0000' }}>Task Reminder: {notif.task.title}</h1>
          {_generateTitle(notif)}
          <div className="info">
            The task assigned to you is <span style={{ color: '#CD0000' }}>{_showAsFromNow(notif.task.expectedCompletionDate)}</span>
          </div>
        </div>
      );
    },
    getNotifIcon(notif) {
      return (
        <div className="left-side">
          <span className="icon material-icons">access_alarm</span>
        </div>
      );
    }
  },

  WORKFLOW_STAGE_NUDGE_REMINDER: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1 style={{ color: '#CD0000' }}>Workflow Reminder</h1>
          {_generateTitle(notif)}
          <div className="info">The required activity assigned to you is {_showAsFromNow(notif.stage.dueDate)}</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">access_time</span>
        </div>
      );
    }
  },

  WORKFLOW_STAGE_POKE_REMINDER: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1 style={{ color: '#CD0000' }}>Workflow Reminder</h1>
          {_generateTitle(notif)}
          <div className="info">The required activity assigned to you is {_showAsFromNow(notif.stage.dueDate)}</div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">access_alarm</span>
        </div>
      );
    }
  },

  WHATS_NEW_DOC_UPDATE: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1>{"What's New Updated"}</h1>
          {_generateTitle(notif)}
          <div className="info"></div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className="icon material-icons">stars</span>
        </div>
      );
    }
  },

  WHATS_NEW_DOC_GENERATION_ERROR: {
    superType: 'task',
    generateDescription(notif: INotification) {
      return (
        <div className="middle-side">
          <h1 style={{ color: 'red' }}>{"What's New Generation Error"}</h1>
          {_generateTitle(notif)}
          <div className="info"></div>
        </div>
      );
    },
    getNotifIcon(notif: INotification) {
      return (
        <div className="left-side">
          <span className={'icon icon-pdf-export-' + (notif.isError ? 'error' : notif.isReady ? 'success' : 'progress')}>stars</span>
        </div>
      );
    }
  },
  PROJECT_EXPORT,
  PDF_EXPORT,
  FEEDBACK_EXPORT,
  READCONFIRM_EXPORT,
  WORKFLOW_EXPORT,
  QUIZ_EXPORT,
  DOCUMENT_EXPORT,
  WORKFLOW_ACTIONS_EXPORT
};

export default {
  templates: notificationTemplates,
  generateTitle: _generateTitle,
  getVariantName: _getVariantName
};
