import * as React from 'react';
import { IUnit } from 'mm-types';
import * as _ from 'lodash';
import { DefferedReducer } from '../../../../../utils';
import { DEFAULT_METADATA_VALUES, NNCIndexMetadataSerializer as Serializer } from './serializer';
import { NNCIndexMetadataID, NNCIndexMetadataValues } from './NNCIndexMetadataTypes';
import EditorStore from '../../../../../flux/editor/EditorStore';
import { useEffectivityElementDefinition } from './useEffectivityElementDefinition';
import { useLineBreakElementDefinition } from './useLineBreakElementDefinition';

function metadataReducer(data: NNCIndexMetadataValues[]): NNCIndexMetadataValues {
  return data.reduceRight((result, o) => {
    return { ...result, ...o };
  }, data[data.length - 1]);
}

function recreateRemovedHtmlFrom(metadata: NNCIndexMetadataValues) {
  let recreate = false;
  // if alt titles are hidden we need to recreate them and place in dom
  metadata[NNCIndexMetadataID.ALT_TITLES].forEach((val) => {
    if (val.hide) {
      recreate = true;
    }
  });
  return recreate;
}
export const useNNCIndexMetadata = (unit: IUnit) => {
  const $unitHtml = React.useRef<JQuery<HTMLElement> | null>(null);
  const [metadata, setMetadata] = React.useState<NNCIndexMetadataValues>(DEFAULT_METADATA_VALUES);
  const defferedMetadataJson = React.useRef(new DefferedReducer<NNCIndexMetadataValues, null>(metadataReducer, 50));
  const effectivityDefinitions = useEffectivityElementDefinition();
  const lineBreakDefinition = useLineBreakElementDefinition();

  const refreshCurrentUnitHtml = () => {
    $unitHtml.current = $(`div.arc-unit[data-nid="${unit.uid}"]`);
  };

  const getDefinitions = () => {
    const definitions = [...effectivityDefinitions];
    if (lineBreakDefinition) {
      definitions.push(lineBreakDefinition);
    }
    return definitions;
  };

  React.useEffect(() => {
    refreshCurrentUnitHtml();
    const metadata = Serializer.toJSON($unitHtml.current as JQuery<HTMLElement>);
    setMetadata(metadata);
    if (recreateRemovedHtmlFrom(metadata)) {
      const definitions = getDefinitions();
      const { html } = Serializer.toHTML(metadata, definitions, $unitHtml.current!);
      EditorStore.getEditor().getActiveEditorFacade()?.setHTML(`_${unit.uid}`, html);
    }
  }, [unit.uid]);

  React.useEffect(() => {
    const unsubscribe = defferedMetadataJson.current.subscribe({
      next: (partialMetadata) => {
        const data = { ...metadata, ...partialMetadata };
        const definitions = getDefinitions();
        setMetadata(data);
        refreshCurrentUnitHtml();
        const { html } = Serializer.toHTML(data, definitions, $unitHtml.current!);
        unit.html = html;
        EditorStore.getEditor().getActiveEditorFacade()?.setHTML(`_${unit.uid}`, html);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [unit, metadata, setMetadata, defferedMetadataJson.current, $unitHtml.current, effectivityDefinitions]);

  return {
    metadata,
    update: (partialMetadata) => {
      defferedMetadataJson.current.next(partialMetadata);
    },
    cancel: () => {
      EditorStore.getEditor().getActiveEditorFacade()?.triggerEscapeEvent();
    },
    save: () => {
      EditorStore.getEditor().getActiveEditorFacade()?.triggerSaveEvent();
    }
  };
};
