import React from 'react';
import FileIcon, { COLOR_ADX, COLOR_PDF, COLOR_AIRBUS, OtherFileTypes } from '../../misc/icons/FileIcon';
import IconAttachFile from 'material-ui/svg-icons/editor/attach-file';
import { AttachmentType } from 'mm-types';

interface Props {
  selected?: boolean;
  type: string;
  external?: boolean;
  hasAttachments?: boolean;
  containerStyle?: React.CSSProperties;
}

const DocumentIcon = ({ selected, type, external, hasAttachments = false, containerStyle }: Props) => {
  let color: string | null = null;
  let className = '';
  let fileType: AttachmentType | OtherFileTypes;

  if (selected) {
    fileType = 'selected';
    color = COLOR_ADX;
    if (type === 'pdf') {
      color = COLOR_PDF;
    } else if (type === 'airbus_xml') {
      color = COLOR_AIRBUS;
    }
  } else if (!external) {
    className = 'filetype-adx';
    fileType = 'adx';
  } else if (type === 'pdf') {
    className = 'filetype-pdf';
    fileType = 'pdf';
  } else {
    className = 'filetype-xml';
    fileType = 'airbus_xml';
  }
  return (
    <div style={containerStyle}>
      <FileIcon color={color} className={className} fileType={fileType}>
        {hasAttachments && <IconAttachFile className="project-has-attachment" data-qa="project-has-attachment" />}
      </FileIcon>
    </div>
  );
};

export default DocumentIcon;
