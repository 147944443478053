import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { IKPIProjectSummary, IKPIWorkflowSummary, IOrganisation } from 'mm-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import * as kpi from '../../documents/kpi/kpiClient';
import * as departApi from '../../../clients/departments';
import { Cancelled } from '../../../clients/base-clients';
import DashboardWidget from '../DashboardWidget';
import DashboardPanelHeaderTitle from '../DashboardPanel/DashboardPanelHeaderTitle';
import DashboardPanelHeader from '../DashboardPanel/DashboardPanelHeader';
import DashboardPanel from '../DashboardPanel/DashboardPanel';
import DropDownSearchable, { IDropDownItem } from '../../misc/DropDownSearchable';
import { useDebouncedCallback } from '../../general/useDebouncedCallback';
import { SESSION_STORAGE_KEYS } from '../../../utils';
import { useStorage } from '../../general/useStorage';
import { getTotalDocumentsCSV } from '../../documents/kpi/kpiClient';
import { isFeatureOn } from '../../featureSwitch/featureSwitchUtils';

type WorkflowSummary = {
  total: number;
  pendingApproval: number;
  overdue: number;
  totalDocuments: number;
};

enum MessageType {
  NONE,
  NO_RECORDS,
  ERROR
}

function getStyles(): { [prop: string]: React.CSSProperties } {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexFlow: 'wrap',
      margin: '50px 0px 50px 0px'
    },
    norecords: {
      fontStyle: 'italic'
    },
    error: {
      fontStyle: 'italic',
      color: 'red'
    },
    panel: {
      flex: '1 1 90%',
      position: 'relative',
      height: 'auto'
    },
    dropdownContainerOrg: {
      position: 'absolute',
      right: '300px',
      top: '-9px', // errr...!!! Too much height from the MUI dropdown which is bigger the height needed for the title header
      textAlign: 'left',
      minWidth: '100px',
      maxWidth: '256px'
    },
    dropdownContainer: {
      position: 'absolute',
      right: '19px',
      top: '-9px' // errr...!!! Too much height from the MUI dropdown which is bigger the height needed for the title header
    }
  };
}

const getDefaultSummary = (): WorkflowSummary => {
  return {
    total: 0,
    pendingApproval: 0,
    overdue: 0,
    totalDocuments: 0
  };
};

const noResults = (s: IKPIWorkflowSummary, projectSummary: IKPIProjectSummary) => {
  return s.total + s.pendingApproval + s.overdue + projectSummary.total === 0;
};

const parseSummary = (summary: IKPIWorkflowSummary, projectSummary: IKPIProjectSummary): WorkflowSummary => {
  if (!summary || !projectSummary) {
    return getDefaultSummary();
  }
  return { ...summary, totalDocuments: projectSummary.total };
};

export type Props = {
  adminOrgs: IOrganisation[];
  memberOrgs: IOrganisation[];
};

const simpleAlphaNumSort = (toBeSorted) => {
  return toBeSorted.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

function DepartmentWorkflowSummaryContainer(props: Props) {
  const styles = getStyles();

  const [showMessage, setShowMessage] = useState<MessageType>(MessageType.NONE); // 0 - none, 1 - no records, 2 - error
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState<IDropDownItem[]>([]);
  const [departments, setDepartments] = useState<IDropDownItem[]>([]);
  const [selectedOrgUid, setSelectedOrgUid] = useStorage(SESSION_STORAGE_KEYS.SELECTED_ORGANISATION_UID);
  const [selectedDepartmentUid, setSelectedDepartmentUid] = useStorage(SESSION_STORAGE_KEYS.SELECTED_DEPARTMENT_UID);
  const [selectedDepartmentText, setSelectedDepartmentText] = useStorage(SESSION_STORAGE_KEYS.SELECTED_DEPARTMENT_TEXT);
  const [summary, setSummary] = useState<WorkflowSummary>(getDefaultSummary());
  const setLoadingDebounced = useDebouncedCallback((value) => setLoading(value), 250);
  const BASE_ROUTE = `/workflow-tasks/organisations/${selectedOrgUid}/departments/${selectedDepartmentUid}`;

  useEffect(() => {
    const uniqueOrgs: IOrganisation[] = _.uniqBy([...props.adminOrgs, ...props.memberOrgs], 'uid');
    const organisations: IDropDownItem[] = simpleAlphaNumSort(uniqueOrgs).map((org: IOrganisation) => ({
      value: org.uid!,
      text: org.name
    }));

    setOrganisations(organisations);
    if (!selectedOrgUid) {
      if (organisations.length > 0) {
        const { value } = organisations[0];
        setSelectedOrgUid(value);
      }
    } else {
      updateSelectedOrgUid(selectedOrgUid);
    }
  }, [props.adminOrgs, props.memberOrgs]);

  function updateSelectedOrgUid(selectedOrgUid: string | null) {
    const getDepartments = async (selectedOrgUid) => {
      if (selectedOrgUid) {
        const isOrgAdmin: boolean = props.adminOrgs.findIndex((org) => org.uid === selectedOrgUid) > -1;
        const response = await departApi.getByOrg({
          orgUid: selectedOrgUid,
          activeOnly: true,
          isMemberOf: !isOrgAdmin
        });
        if (response) {
          const deps = simpleAlphaNumSort(response).map(({ uid: value, name: text }) => ({
            value: value || '',
            text
          }));
          setDepartments(deps);
          if ((!selectedDepartmentUid || selectedDepartmentUid === '') && deps.length > 0) {
            const { value, text } = deps[0];
            setSelectedDepartmentUid(value);
            setSelectedDepartmentText(text);
          }
        }
      }
    };
    if (selectedOrgUid) {
      getDepartments(selectedOrgUid);
    }
  }
  useEffect(() => {
    updateSelectedOrgUid(selectedOrgUid);
  }, [selectedOrgUid]);

  useEffect(() => {
    let cancelRequest = false;
    const getSummary = async (departmentUid, organisationUid?: string) => {
      try {
        const response = await kpi.getWorkflowSummaryDepartment({ departmentUid });
        const projectSummaryRes = await kpi.getDepartmentProjectSummary({ departmentUid, organisationUid });
        if (cancelRequest) {
          return;
        }
        if (response instanceof Cancelled || projectSummaryRes instanceof Cancelled) {
          setLoadingDebounced(false);
          setShowMessage(MessageType.ERROR);
          return;
        }
        if (response && projectSummaryRes) {
          setSummary(parseSummary(response, projectSummaryRes));
          setLoadingDebounced(false);
          if (noResults(response, projectSummaryRes)) {
            setShowMessage(MessageType.NO_RECORDS);
          } else {
            setShowMessage(MessageType.NONE);
          }
        } else {
          setShowMessage(MessageType.ERROR);
        }
      } catch {
        if (cancelRequest) {
          return;
        }
        setLoadingDebounced(false);
        setShowMessage(MessageType.ERROR);
      }
    };
    if (selectedDepartmentUid && selectedOrgUid) {
      setLoadingDebounced(true);
      getSummary(selectedDepartmentUid, selectedOrgUid);
    }
    return () => {
      cancelRequest = true;
    };
  }, [selectedDepartmentUid]);

  const organizationOptions = organisations.map((o) => <MenuItem key={o.value} value={o.value} primaryText={o.text} />);

  return (
    <>
      {organizationOptions.length > 0 && (
        <DashboardPanel style={styles.panel} dataQa="dep-workflow-summary-container">
          <DashboardPanelHeader dataQa="dep-workflow-summary-header">
            <DashboardPanelHeaderTitle>Department Workflow Tasks</DashboardPanelHeaderTitle>
            <div
              style={styles.dropdownContainerOrg}
              className="dep-workflow-summary-header-org-select"
              data-qa="dep-workflow-summary-header-org-select"
            >
              <SelectField
                floatingLabelText="Organisation"
                style={{ fontSize: '14px', fontWeight: 500, fill: '#000000' }}
                labelStyle={{ fontSize: '14px', top: '3px' }}
                fullWidth={true}
                value={selectedOrgUid}
                onChange={(e, key, value) => {
                  setSelectedOrgUid(value || null);
                  setSummary(getDefaultSummary);
                  setShowMessage(MessageType.NONE);
                  setSelectedDepartmentUid('');
                  setSelectedDepartmentText('');
                }}
                autoWidth={true}
                menuItemStyle={{ width: '256px' }}
              >
                {organizationOptions}
              </SelectField>
            </div>
            <div
              style={styles.dropdownContainer}
              data-qa="dep-workflow-summary-header-dep-dropdown"
              className="dep-workflow-summary-header-dep-dropdown"
            >
              <DropDownSearchable
                title="Department"
                placeholder="Filter Department"
                items={departments}
                onChange={(item) => {
                  setSelectedDepartmentUid(item?.value || null);
                  setSelectedDepartmentText(item?.text || null);
                }}
                onReset={() => {
                  setSummary(getDefaultSummary);
                  setShowMessage(MessageType.NONE);
                  setSelectedDepartmentUid('');
                  setSelectedDepartmentText('');
                }}
                style={{ fontWeight: 500 }}
                autoCompleteStyle={{ width: '200px' }}
                fullWidth={true}
                defaultText={selectedDepartmentText || ''}
              />
            </div>
          </DashboardPanelHeader>
          <div style={styles.container} data-qa="dep-workflow-summary-body">
            {isFeatureOn('workflowActions') && (
              <DashboardWidget
                title="Total Documents"
                icon="total-docs"
                value={`${summary.totalDocuments}`}
                action={() =>
                  getTotalDocumentsCSV({ departmentUid: selectedDepartmentUid ?? undefined, organisationUid: selectedOrgUid ?? undefined })
                }
                label="Export Publication History"
                loading={loading}
                dataQa="dep-workflow-summary-body-totaldocuments"
              />
            )}
            <DashboardWidget
              title="Active Documents"
              icon="workflow"
              value={`${summary?.total}`}
              route={`${BASE_ROUTE}`}
              loading={loading}
              dataQa="dep-workflow-summary-body-activedocuments"
            />
            <DashboardWidget
              title="Pending Documents"
              icon="clock"
              value={`${summary?.pendingApproval}`}
              route={`${BASE_ROUTE}?pendingApprovalRecords=true`}
              loading={loading}
              dataQa="dep-workflow-summary-body-pendingapproval"
            />
            <DashboardWidget
              title="Overdue Documents"
              icon="stopwatch"
              value={`${summary?.overdue}`}
              route={`${BASE_ROUTE}?overdueRecords=true`}
              loading={loading}
              dataQa="dep-workflow-summary-body-overduedocuments"
              alert
            />
          </div>
          {showMessage === MessageType.NO_RECORDS && (
            <div style={styles.norecords} data-qa="dep-workflow-summary-body-noresults">
              No results, please refine your filter
            </div>
          )}
          {showMessage === MessageType.ERROR && (
            <div style={styles.error} data-qa="dep-workflow-summary-body-error">
              An error occurred, please try again.
            </div>
          )}
        </DashboardPanel>
      )}
    </>
  );
}

export default DepartmentWorkflowSummaryContainer;
