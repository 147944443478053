import * as React from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import { FooterActions } from '../footerActions/FooterActions';
import { VisualStates } from '../utils/DocumentVisualStates';
import { IEditorStoreEvent, IUnit } from 'mm-types';
import { EditorEventType } from '../../../flux/editor/EditorStoreAddons/EditorEventType';
import EditorStore from '../../../flux/editor/EditorStore';
import useListenToStore from '../../hooks/useListenToStore';
import { FooterBreadcrumb } from '../footerBreadcrumb/FooterBreadcrumb';
import { DocumentUtils } from '../document/utils/DocumentUtils';

export type Props = {
  selectedUnits: IUnit[];
  onAction: (visualStateChanged: VisualStates, newStateOn: boolean) => void;
  onZoom: (zoomValue: boolean) => void;
  docVisualStates: VisualStates[];
};

export type State = {
  showSavingMessage: boolean;
  savingMessage: string;
};

const Footer = React.forwardRef((props: Props, ref: React.RefObject<HTMLDivElement>) => {
  const [state, setState] = React.useState<State>({
    showSavingMessage: false,
    savingMessage: 'Saving...'
  });

  const toggleSavingMessage = (show: boolean, message = 'Saving...') => {
    setState({
      showSavingMessage: show,
      savingMessage: message
    });
  };

  const onStoreChange = (e: IEditorStoreEvent<EditorEventType>) => {
    if (e.type === 'saving') {
      toggleSavingMessage(e.show!, e.message!);
    }
  };

  const onZoom = (zoomValue: number) => {
    // units
    const editingPage = $(ref.current as Element);
    DocumentUtils.replaceZoom(editingPage, zoomValue);

    // TinyMCE resizebars
    const docBody = $('body');
    docBody.off('DOMNodeInserted');

    if (zoomValue !== 1) {
      docBody.on('DOMNodeInserted', (e) => {
        const node = $(e.target);
        if (node.hasClass('mce-resize-bar')) {
          DocumentUtils.replaceZoom(node, zoomValue);
        }
      });
    }
    props.onZoom(zoomValue !== 1);
  };

  useListenToStore({ store: EditorStore, eventListener: onStoreChange });

  return (
    <div className="col s12 editing-footer">
      {state.showSavingMessage && (
        <div className="saving-progress" style={{ flex: 1 }}>
          <LinearProgress mode="indeterminate" />
          <span className={'saving-text'}>{state.savingMessage}</span>
        </div>
      )}
      <FooterBreadcrumb selectedUnits={props.selectedUnits} />
      <FooterActions
        docVisualStates={props.docVisualStates}
        onAction={(type, isChecked) => props.onAction(type, isChecked)}
        onZoom={(val) => onZoom(val)}
      />
    </div>
  );
});

export { Footer };
