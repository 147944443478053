import FontIcon from 'material-ui/FontIcon';
import React from 'react';
import { Path } from './EditorBreadcrumb';
import { useBreadcrumbElement } from '../hooks/useBreadcrumbElement';

export type Props = {
  pathItem: Path;
  handleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, elm: HTMLElement) => void;
  handleDoubleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, elm: HTMLElement) => void;
  isLast: boolean;
};

const BreadcrumbElement = ({ pathItem, handleClick, handleDoubleClick, isLast }: Props) => {
  const { canDelete, setDeleteIconVisible, preventEditorBlurAndDeleteElement } = useBreadcrumbElement(pathItem.element, isLast);

  return (
    <div onMouseOver={() => setDeleteIconVisible(true)} onMouseLeave={() => setDeleteIconVisible(false)}>
      <span
        key={pathItem.index}
        onClick={(e) => handleClick(e, pathItem.element)}
        onDoubleClick={(e) => handleDoubleClick(e, pathItem.element)}
        style={isLast ? { backgroundColor: '#bff6ff', boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.2)' } : {}}
      >
        {pathItem.friendlyName}
      </span>
      {canDelete && (
        <FontIcon
          style={{ position: 'absolute', marginLeft: '-6px', marginTop: '-10px', fontSize: 16, color: '#77858b' }}
          className="material-icons"
          data-qa="breadcrumb-element-delete-icon"
          onClick={preventEditorBlurAndDeleteElement}
        >
          delete
        </FontIcon>
      )}
    </div>
  );
};

export default BreadcrumbElement;
