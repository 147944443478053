import React from 'react';
import { MediaTypesContants } from '../../types';
import ImagePan from './ImagePan';
import { MaterialIcon } from '../../../../general/MaterialIcon';
import { IMedia } from 'mm-types';
import FileIcon from '../../../../misc/icons/FileIcon';

type Props = { detailedMedia: IMedia; media: IMedia; scale: number; imagePanRef: React.MutableRefObject<ImagePan | null> };

const MediaDetailThumb = (props: Props) => {
  switch (props.detailedMedia.type) {
    case MediaTypesContants.image:
    case MediaTypesContants.symbol:
      return <ImagePan media={props.media} scale={props.scale} ref={props.imagePanRef} />;
    case MediaTypesContants.video:
      return (
        <video autoPlay controls>
          <source src={props.detailedMedia.location} type={props.detailedMedia.mimeType} />
        </video>
      );
    case MediaTypesContants.audio:
      return (
        <div className="media-detail-thumb" data-qa={'media-detail-audio-thumb'}>
          <MaterialIcon iconName={'micro'} style={{ fontSize: 200 }} data-qa={'media-detail-audio-thumb-icon'} />
          <audio autoPlay controls data-qa={'media-detail-audio-playback'} src={props.detailedMedia.location}></audio>
        </div>
      );
    default:
      return (
        <div className="media-detail-thumb" data-qa={'media-detail-other-thumb'}>
          <FileIcon fileType={props.media.type} mimeType={props.media.mimeType} style={{ fontSize: 200 }} className="other-icon" />
          <span>Please download to see content</span>
        </div>
      );
  }
};

export default MediaDetailThumb;
