import { docUnitProfile as list } from '../list';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  ...list,
  displayName: 'Numbered List',
  getLabel: () => 'LIST',
  identifyingClasses: ['arc-ol', /* "arc-li",*/ 'arc-oli'],
  identifyingElements: ['ol'],
  outerClass: 'arc-ol',
  outerElement: 'ol',
  isEncapsulatingElement: true,
  template: ''
};
