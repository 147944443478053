import React from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import DocumentsStore from '../../flux/projects/DocumentsStore';

type Props = {
  pageSize: number;
};
const PageSizeDropdown = ({ pageSize }: Props) => {
  const handleChange = (value) => {
    DocumentsStore.setPageSize(value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ paddingTop: 20 }}>Page Size:</div>
      <DropDownMenu value={pageSize} onChange={(e, i, v) => handleChange(v)}>
        <MenuItem value={30} primaryText={30} />
        <MenuItem value={50} primaryText={50} />
        <MenuItem value={75} primaryText={75} />
        <MenuItem value={100} primaryText={100} />
      </DropDownMenu>
    </div>
  );
};

export default PageSizeDropdown;
