import React, { useState, useEffect } from 'react';
import Dialog from 'material-ui/Dialog';
import { FootnoteInfo } from './refIntService';
import RadioButton, { RadioButtonGroup } from 'material-ui/RadioButton';
import FlatButton from 'material-ui/FlatButton';

export function RefIntModal(props: {
  footnoteInfo: FootnoteInfo[];
  onDismiss: () => void;
  onSave: (nid: string, index: number) => void;
  selectedNid?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNid, setNid] = useState<string | undefined>(props.selectedNid);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const dismiss = () => {
    setIsOpen(false);
    props.onDismiss();
  };

  const save = () => {
    props.onSave(
      selectedNid as string,
      props.footnoteInfo.findIndex((val) => val.nid === selectedNid)
    );
    dismiss();
  };
  return (
    <Dialog
      open={isOpen}
      className="link-dialog"
      title={`${props.selectedNid ? 'Edit' : 'Add'} Reference Number`}
      contentStyle={{ width: '440px' }}
      actions={[
        <FlatButton
          key={1}
          label="Cancel"
          data-qa="refint-modal-cancel-button"
          onClick={() => {
            dismiss();
          }}
        />,
        <FlatButton
          key={2}
          label="Save"
          data-qa="refint-modal-save-button"
          disabled={!selectedNid}
          onClick={() => {
            save();
          }}
        />
      ]}
    >
      <span>Select Footnote</span>
      <RadioButtonGroup
        name="footnotes"
        labelPosition="left"
        style={{ width: '100%', marginTop: '16px', height: '250px', overflowY: 'scroll' }}
        onChange={(e, val) => {
          setNid(val);
        }}
        valueSelected={selectedNid}
      >
        {props.footnoteInfo.length > 0 &&
          props.footnoteInfo.map((footnote, i) => {
            return (
              <RadioButton
                value={footnote.nid}
                label={`(${i + 1}) ${footnote.text}`}
                labelStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '320px' }}
                key={footnote.nid}
                data-qa={'refint-modal-radio-button'}
              />
            );
          })}
      </RadioButtonGroup>
    </Dialog>
  );
}
