import * as React from 'react';

type Props = {
  maxLength: number;
};

const MaxLengthExceededMsg = (props: Props) => {
  return <span className="max-error">Maximum {props.maxLength} characters</span>;
};

export default MaxLengthExceededMsg;
