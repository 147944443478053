import * as React from 'react';

export type PaginationProps = {
  pageSize: number;
  pageNumber: number;
  numOfPages: number;
  totalElements: number | null;
};

export type Props = {
  compact?: boolean;
  page?: PaginationProps;
  moveToPage: (page: number) => void;
};

export type State = {};

export default class Paging extends React.Component<Props, State> {
  moveToPage(pageNumber: number, disabled = false) {
    if (!disabled) {
      this.props.moveToPage(pageNumber);
    }
  }

  render() {
    const pageNumber: number = this.props.page!.pageNumber;
    const numOfPages: number = this.props.page!.numOfPages;
    const isOnFirstPage: boolean = pageNumber === 0;
    const isOnLastPage: boolean = pageNumber === numOfPages - 1;

    let startPage = 0,
      endPage = 0;
    let showFirstPage = true,
      showLastPage = true,
      showLeftEllipse = false,
      showRightEllipse = false;

    if (numOfPages <= 5) {
      // less than 10 total pages so show all
      startPage = 0;
      endPage = numOfPages - 1;
      showFirstPage = false;
      showLastPage = false;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (pageNumber <= 2) {
        startPage = 0;
        endPage = 4;
        showFirstPage = false;
      } else if (pageNumber + 4 >= numOfPages) {
        startPage = numOfPages - pageNumber === 4 ? pageNumber - 2 : numOfPages - 5;
        endPage = numOfPages - 1;
        showLastPage = false;
      } else {
        startPage = pageNumber - 2;
        endPage = pageNumber + 2;
      }

      showLeftEllipse = pageNumber > 3;
      showRightEllipse = pageNumber < numOfPages - 4;
    }

    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

    return (
      <div className={'paging ' + (this.props.compact ? 'compact' : '')}>
        {!this.props.compact ? (
          <span className={'paging-total-items' + (numOfPages > 1 ? ' paging-total-items-border' : '')}>
            Total Items: <span className={'paging-total-items-count'}>{this.props.page!.totalElements}</span>
          </span>
        ) : undefined}

        {numOfPages > 1 ? (
          <span className={'pages'}>
            <span className={isOnFirstPage ? 'page-disabled' : ''} onClick={() => this.moveToPage(pageNumber - 1, isOnFirstPage)}>
              Prev
            </span>

            {showFirstPage ? (
              <span className={isOnFirstPage ? 'page-active' : ''} onClick={() => this.moveToPage(0, isOnFirstPage)}>
                1
              </span>
            ) : undefined}

            {showLeftEllipse ? <span className={'no-action'}>...</span> : undefined}

            {pages.map((page, index) => {
              return (
                <span
                  key={index}
                  className={pageNumber === page ? 'page-active' : ''}
                  onClick={() => this.moveToPage(page, pageNumber === page)}
                >
                  {page + 1}
                </span>
              );
            })}

            {showRightEllipse ? <span className={'no-action'}>...</span> : undefined}

            {showLastPage ? (
              <span className={isOnLastPage ? 'page-active' : ''} onClick={() => this.moveToPage(numOfPages - 1, isOnLastPage)}>
                {numOfPages}
              </span>
            ) : undefined}

            <span className={isOnLastPage ? 'page-disabled' : ''} onClick={() => this.moveToPage(pageNumber + 1, isOnLastPage)}>
              Next
            </span>
          </span>
        ) : undefined}
      </div>
    );
  }
}
