import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Header',
  getLabel: () => 'HEADING',
  targetEditContainerSelector: '.arc-unit>.arc-title>.arc-title-heading',
  editor: {
    forced_root_block: ''
  }
};
