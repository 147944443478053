import * as Reflux from 'reflux';
import Store from '../Store';
import { getUsers, getMembers } from '../../clients/users';
import { IUser } from 'mm-types';
import * as projectClient from '../../clients/project';
import { Cancelled } from '../../clients/base-clients';

export type State = {
  users: IUser[];
  query?: string;
  lastUpdateEmpty?: boolean;
  page?: number;
};

export type UserStoreEvent = State;

export class UserStore extends Store<State> {
  constructor() {
    super();

    this.state = {
      users: []
    };
  }

  getInitialState() {
    return this.state;
  }

  getUsers() {
    return this.state.users;
  }

  getNextPage(query: string) {
    return this.state.query === query ? (this.state.lastUpdateEmpty ? null : this.state.page! + 1) : 1;
  }

  async retrieveUsersByProject(username: string, projectUid: string) {
    const project = await projectClient.getProject(projectUid);
    const members = await getMembers(projectUid, { flatten: true });

    if (members instanceof Cancelled) {
      return;
    }

    this.state.users = members.map((m) => m.user!);
    this.state.page = 0;
    this.state.query = username;

    if (project && project.owner) {
      this.state.users.push(project.owner);
    }

    // Filter users based on keyword
    this.state.users = this.state.users.filter((u) => {
      if (u.username.toLowerCase().indexOf(username) !== -1) {
        return true;
      }
      if (u.displayName.toLowerCase().indexOf(username) !== -1) {
        return true;
      }
      return false;
    });

    // Remove duplicates
    this.state.users = this.state.users.filter(
      (user, index, self) => index === self.findIndex((otherUser) => otherUser.username === user.username)
    );

    this.trigger(this.state as UserStoreEvent);
    return this.state.users;
  }

  clear() {
    this.state.users = [];
    this.state.query = '';
    this.trigger(this.state as UserStoreEvent);
  }

  async retrieveUsers(query = '', projectUid: string | null = null, page = 0, size = 50) {
    const data: {
      query?: string;
      projectUid?: string;
      page?: number;
      size?: number;
    } = {};

    if (query !== undefined && query.trim().length > 0) {
      data.query = query.trim();
    }

    if (projectUid !== undefined && projectUid) {
      data.projectUid = projectUid;
    }

    if (page !== undefined && page > 0) {
      data.page = page;
    }

    if (size !== undefined && size > 0) {
      data.size = size;
    }

    // ignore existing
    if (this.state.query === query && this.state.page === page) {
      this.trigger(this.state as UserStoreEvent);
      return this.state.users;
    }

    try {
      const result = await getUsers(data);
      if (result === Cancelled) {
        return [];
      }

      const newUsers = result as IUser[];
      this.state.lastUpdateEmpty = newUsers.length === 0;

      if (this.state.query === undefined || this.state.query !== query || page === 0) {
        this.state.users = newUsers;
      } else if (this.state.page! < page) {
        this.state.users = this.state.users.concat(newUsers);
      } else if (this.state.page! > page) {
        this.state.users = newUsers.concat(this.state.users);
      }

      this.state.query = query;
      this.state.page = page;

      this.trigger(this.state as UserStoreEvent);
      return this.state.users;
    } catch (err) {
      return [];
    }
  }
}

const singleton = Reflux.initStore<UserStore>(UserStore);
export default singleton;
