import * as React from 'react';
import EditorStore from '../../../../../flux/editor/EditorStore';
import SpellcheckStore, { SpellCheckStoreEvent, State as SpellcheckStoreState } from '../../../../../flux/editor/SpellcheckStore';
import TocStore from '../../../../../flux/editor/TocStore';
import SpellCheckSuggestions from './SpellCheckSuggestions';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import { StringUtil } from '../../../../../utils';

const BGCOLOR = '#f9f9f9';

export type Props = {};

export type State = {
  wordList: string[];
  word: string;
} & SpellcheckStoreState;

export default class SpellChecker extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this.unsubscribe = SpellcheckStore.listen(this.onSpellStoreChange, this);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onSpellStoreChange(status: SpellCheckStoreEvent) {
    this.setState(status);
  }

  sectionTitle() {
    const unit = TocStore.getFirstSelectedTocableUnit();
    if (unit) {
      return unit.ordinal ? unit.ordinal + ' ' + StringUtil.stripHTML(unit.heading) : StringUtil.stripHTML(unit.heading);
    }
  }

  updateWord(newWord: string) {
    this.setState({ word: newWord });
  }

  _numberOfWordsRemaining() {
    return SpellcheckStore.hasWords() ? this.state.wordList.length : 0;
  }

  render() {
    if (!this.state) {
      return <div className="spell-checker-loading">loading</div>;
    } else {
      return (
        <div className="spellchecker-component">
          <div className="header">
            <h1>
              Spell Checker
              <span className={'words-remaining ' + (this._numberOfWordsRemaining() ? '' : 'empty')}>{this._numberOfWordsRemaining()}</span>
            </h1>
            <FlatButton
              secondary={true}
              backgroundColor={BGCOLOR}
              onClick={() => {
                EditorStore.toggleMode('SPELLCHECK');
              }}
              label="done"
            />
          </div>

          <div className="clear" />

          <h3 data-qa="spellcheck-toc-heading">{this.sectionTitle()}</h3>

          {this._renderBody()}

          <div className="clear" />

          {this._renderSuggestions()}

          <div className="clear"></div>

          {this._renderSearchResults()}
        </div>
      );
    }
  }

  _onKeyUp(e: React.FormEvent<{}>) {
    this.setState({
      word: (e.target as HTMLInputElement).value
    });
  }

  // todo break some of these into components like findreplace container does

  _renderSearchResults() {
    if (this._numberOfWordsRemaining() > 0) {
      return (
        <div className="search-results">
          <div className="header">
            <div className="title">Total</div>
            <div className="matches">
              {SpellcheckStore.getCurrent() + 1} of {this._numberOfWordsRemaining()}
            </div>
          </div>
        </div>
      );
    }
  }

  _renderBody() {
    if (!SpellcheckStore.hasWords()) {
      return <span>No spelling errors found</span>;
    } else {
      return (
        <div className="word-box">
          <div className="smallText">Change To</div>
          <div className="input">
            <TextField
              id="spellcheck-changeto"
              disabled={this.state.loading}
              style={{ width: '100%' }}
              onChange={(e) => this._onKeyUp(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  SpellcheckStore.correctWord(this.state.word);
                }
              }}
              value={this.state.word}
            />
          </div>
          <div className="buttons">
            <div className="buttons-inner-left">
              <FlatButton
                disabled={this.state.loading}
                className="spell-checker-prev-btn"
                backgroundColor="#e0e0e0"
                style={{ border: '1px solid #ccc', minWidth: '30px' }}
                onClick={(e) => SpellcheckStore.previousSpellWord()}
              >
                <span className="muidocs-icon-keyboard-arrow-left material-icons spellcheck-button-icon">keyboard_arrow_left</span>
              </FlatButton>
              <FlatButton
                disabled={this.state.loading}
                className="spell-checker-next-btn"
                backgroundColor="#e0e0e0"
                style={{ border: '1px solid #ccc', minWidth: '30px', marginLeft: '8px' }}
                onClick={(e) => SpellcheckStore.nextSpellWord()}
              >
                <span className="muidocs-icon-keyboard-arrow-right material-icons spellcheck-button-icon">keyboard_arrow_right</span>
              </FlatButton>
            </div>

            <div className="buttons-inner-right">
              <FlatButton
                className="spell-checker-ignore-all-btn"
                disabled={this.state.loading}
                backgroundColor={BGCOLOR}
                onClick={(e) => SpellcheckStore.ignoreAll()}
                label="IGNORE ALL"
              />

              <FlatButton
                className="spell-checker-ignore-btn"
                disabled={this.state.loading}
                backgroundColor={BGCOLOR}
                onClick={(e) => SpellcheckStore.ignoreWord()}
                label="IGNORE"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  _renderSuggestions() {
    if (SpellcheckStore.hasWords()) {
      return (
        <SpellCheckSuggestions
          loading={this.state.loading}
          suggestions={this.state.suggestions}
          onClickSuggestion={(e, suggestion) => this.updateWord(suggestion)}
          onDoubleClickSuggestion={(suggestion) => SpellcheckStore.correctWord(suggestion)}
        >
          <div className="buttons">
            <FlatButton
              className="spell-checker-btn-change-all"
              backgroundColor={BGCOLOR}
              onClick={() => SpellcheckStore.correctAll(this.state.word, false)}
              disabled={this.state.replacing || this.state.loading}
              label="CHANGE ALL"
            />

            <FlatButton
              className="spell-checker-btn-change"
              backgroundColor={BGCOLOR}
              onClick={() => SpellcheckStore.correctWord(this.state.word)}
              disabled={this.state.replacing || this.state.loading}
              label="CHANGE"
            />

            <FlatButton
              backgroundColor={BGCOLOR}
              onClick={() => SpellcheckStore.correctAll(this.state.word, true)}
              disabled={this.state.replacing || this.state.loading}
              label="CHANGE ALL DOCUMENT"
            />
            <FontIcon className="fontello-menu-item material-icons icon-new-doc" />
          </div>
        </SpellCheckSuggestions>
      );
    }
  }
}
