import * as Reflux from 'reflux';
import Store from '../Store';
export type State = {};

export type SystemInfoStoreEvent = {
  systemInfo?: { visible: boolean; message: string };
};

export class SystemInfoStore extends Store<State> {
  private postponeFn: number;

  constructor() {
    super();
  }

  showSystemInfo(message: string) {
    this.trigger({ systemInfo: { visible: true, message } } as SystemInfoStoreEvent);
  }
  hideSystemInfo(message: string) {
    this.showSystemInfo(message);

    if (this.postponeFn) {
      clearTimeout(this.postponeFn);
    }
    this.postponeFn = window.setTimeout(() => {
      this.trigger({ systemInfo: { visible: false, message } } as SystemInfoStoreEvent);
    }, 3000);
  }
  showAlert(message: string) {
    this.hideSystemInfo(message);
  }
}

const singleton = Reflux.initStore<SystemInfoStore>(SystemInfoStore);
export default singleton;
