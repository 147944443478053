import * as React from 'react';
import { CSSProperties } from 'react';
import * as _ from 'lodash';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import ConversionUtil from '../../../../../../utils/ConversionUtil';
import TextField from 'material-ui/TextField';
import { IElementSettings } from 'mm-types';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import useListenToStore from '../../../../../hooks/useListenToStore';
import TableUtils from '../../../../../../utils/TableUtils';
import TableDimensionUnit from './TableDimensionUnit';

export type Props = {
  type?: 'width' | 'height';
  title?: string;
  display?: 'table' | 'cell';
  onChangeCallback?: (() => void) | null;
  settings?: IElementSettings[];
};

export type State = {
  value: string;
  unit: '%' | 'px';
  cellWidthErrorTextColor: CSSProperties;
  cellWidthErrorMessage: null | string;
  focusElm: null | Element;
  isBusy: boolean;
};

type TableDimensionActions = { type: 'setState'; payload: Partial<State> } | { type: 'setIsBusy'; payload: boolean };

const TableDimensionReducer: React.Reducer<State, TableDimensionActions> = (state, action) => {
  switch (action.type) {
    case 'setState':
      return { ...state, ...action.payload };
    case 'setIsBusy':
      return { ...state, isBusy: action.payload };
  }
};

export type UnitType = {
  payload: '%' | 'px';
  text: '%' | 'px';
};

export type BaseStyle = { width: string; lineHeight: string; fontSize: string; height: string };

function TableDimension({ type = 'width', title = 'Width', display = 'table', onChangeCallback = null, settings }: Props) {
  useListenToStore({ store: EditorStore, eventListener: tinyUpdateEvent });

  const _units: UnitType[] = [
    { payload: '%', text: '%' },
    { payload: 'px', text: 'px' }
  ];

  const [state, dispatch] = React.useReducer(TableDimensionReducer, getInitialState());

  function getInitialState(): State {
    return {
      value: '',
      unit: getUnitByType(type),
      cellWidthErrorTextColor: {},
      cellWidthErrorMessage: null,
      focusElm: null,
      isBusy: false
    };
  }

  React.useEffect(() => {
    updateDimensions();
  }, []);

  function isCell() {
    return display === 'cell';
  }

  function tinyUpdateEvent(e) {
    if (e.type === 'unitDomChange' || e.type === 'nestedUnitFocusChange') {
      updateDimensions();
    }
  }

  function updateDimensions() {
    const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;

    const dimensionElm = isCell() ? TableUtils.getSelectedCells() : [tinyFacade.getSelectedTable()];
    const focusElm = { focusElm: tinyFacade.getSelectedTable() };

    const raw = tinyFacade.getCommonDimension(dimensionElm, type);

    if (raw) {
      const { lengthNumber, lengthUnit } = ConversionUtil.parseLengthStyle(raw);

      if (lengthNumber) {
        // should only set state if value or unit different
        if (state.value !== parseFloat(lengthNumber).toFixed(2) || state.unit !== lengthUnit) {
          dispatch({
            type: 'setState',
            payload: _.merge(focusElm, cellWidthErrorHandling((dimensionElm[0] as HTMLElement).offsetWidth), {
              value: isWidth() && lengthUnit === '%' ? parseFloat(lengthNumber).toFixed(2) : Math.round(parseInt(lengthNumber)),
              unit: lengthUnit
            }) as Partial<State>
          });
        }
      }
    } else {
      dispatch({ type: 'setState', payload: _.merge(focusElm, { value: '', unit: getUnitByType(type) }) as Partial<State> });
    }
  }

  function getUnitByType(type) {
    return type === 'width' ? '%' : 'px';
  }

  function getStyle() {
    return { [type!]: state.value + state.unit };
  }

  function applyStyle() {
    if (!state.isBusy) {
      dispatch({ type: 'setIsBusy', payload: true });
    }
  }

  React.useEffect(() => {
    if (state.isBusy) {
      if (onChangeCallback) {
        onChangeCallback();
      }

      if (isCell()) {
        const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
        facade.execCommand('arcCellSetStyle', {
          style: [getStyle()]
        });
      } else {
        const facade = EditorStore.getEditor().getActiveEditorFacade()! as any;
        facade.execCommand('arcTableSetStyle', {
          focusElm: state.focusElm,
          style: getStyle()
        });
      }

      setTimeout(() => {
        EditorStore.getEditor().getActiveEditorFacade()!.clearSelection();
        dispatch({ type: 'setIsBusy', payload: false });
      }, 800);
    }
  }, [state.isBusy]);

  function handleUnitChange(e, selectedIndex, selectedPayload) {
    let value = state.value;
    // let value = (this.refs['dimension' + type] as TextField).getValue();

    if (value) {
      const toUnit = state.unit === '%' ? 'px' : '%';
      value = ConversionUtil.changeUnitTo(toUnit, value, isCell());
    }

    dispatch({ type: 'setState', payload: { unit: selectedPayload, value: value } });
    applyStyle();
  }

  function makeValid(value: string) {
    const minMax = function (val, min: number, max: number) {
      if (value === '' || val < min) {
        return min;
      } else if (val > max) {
        return max;
      } else {
        return val;
      }
    };
    if (value === '') {
      return '';
    }
    if (state.unit === '%') {
      return minMax(value, 1, 100);
    } else {
      // TODO proper max width checking, dpi dependently as well eep, magic number for the moment
      if (isWidth()) {
        return minMax(parseInt(value), 1, 999999);
      } else {
        return minMax(parseInt(value), 1, 1500);
      }
    }
  }

  function isWidth() {
    return !!title && title === 'Width';
  }

  function getUnits() {
    return type === 'width'
      ? _units
      : _units.filter((el) => {
          return el.text === 'px';
        });
  }

  function cellWidthErrorHandling(px) {
    if (isWidth()) {
      const cellInput = { color: 'red' };
      return px > 1500
        ? { cellWidthErrorTextColor: cellInput, cellWidthErrorMessage: 'Warning: Value will cause scaling in pdf' }
        : { cellWidthErrorTextColor: {}, cellWidthErrorMessage: null };
    }
  }

  const checkValidInput = (val, allowDecimal = false) => {
    const parseFloatRegExp = new RegExp('^([0-9]*[.[0-9]{0,3}]?)$', 'i');
    const parseIntegerRegExp = new RegExp('^([0-9]*]?)$', 'i');
    if (val === '') {
      return true;
    }
    const stringTestVal = parseFloat(val).toFixed(2);
    if (
      stringTestVal == 'NaN' ||
      (!allowDecimal && !val.toString().match(parseIntegerRegExp)) ||
      (allowDecimal && !val.toString().match(parseFloatRegExp))
    ) {
      return false;
    }
    return true;
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checkValidInput(e.target.value, state.unit == '%')) {
      return;
    }
    dispatch({
      type: 'setState',
      payload: _.merge(cellWidthErrorHandling(e.target.value), {
        value: makeValid(e.target.value)
      })
    });
  };

  const base: BaseStyle = { width: '30px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
  const textFieldStyle: React.CSSProperties = { ...base, float: 'left', width: '36.5px' };
  const errorTextStyle = { width: '21rem' };
  const displayClass = display === 'table' ? 'tableDim' : 'cell';

  return (
    <div className={'dimension ' + displayClass + ' ' + type}>
      <h6 className={displayClass}>{title}</h6>
      <TextField
        id={'dim-text-' + type}
        value={state.value}
        style={textFieldStyle}
        inputStyle={state.cellWidthErrorTextColor}
        errorText={state.cellWidthErrorMessage}
        errorStyle={errorTextStyle}
        onChange={onChangeHandler}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            applyStyle();
          }
        }}
        onBlur={() => {
          applyStyle();
        }}
        onFocus={(e) => {
          (e.target as HTMLInputElement).select();
        }}
        disabled={!settingEnabled(type, settings)}
      />
      <TableDimensionUnit
        isWidth={isWidth()}
        base={base}
        type={type}
        settings={settings}
        units={getUnits()}
        selectedUnit={state.unit}
        handleUnitChange={handleUnitChange}
      />
    </div>
  );
}

export default TableDimension;
