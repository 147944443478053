import * as React from 'react';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import Toggle from 'material-ui/Toggle';
import { DropDownMenuWithBaseStyle } from '../../../../../misc/DropDownMenuWithBaseStyle';
import { BASE_STYLE, DataAttributePropsType, EnumMenuItemOptionType } from '../DataAttributePropsType';
import { MenuItem } from 'material-ui';

type PropsType = DataAttributePropsType & {
  enumMenuItems: EnumMenuItemOptionType[];
  type: 'enumAsToggleWithDropdown';
};

export const ToogleWithDropdownAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, handleAction, enumMenuItems, units } = props;
  return (
    <div>
      <div
        className="toggle"
        data-qa="data-attribute"
        onClick={() =>
          handleAction(
            dataAttribute,
            dataAttribute.enumValues.includes(dataAttributesValues[dataAttribute.id] ?? '') ? '' : dataAttribute.enumValues[0]
          )
        }
      >
        <div
          data-qa="data-attribute-boolean"
          className="dimension column"
          data-toggle-enabled={dataAttributesValues[dataAttribute.id] ? 'true' : 'false'}
        >
          <Toggle
            data-toggle-enabled={dataAttributesValues[dataAttribute.id] ? 'true' : 'false'}
            disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues, units)}
            name={dataAttribute.id}
            label={dataAttribute.displayName}
            toggled={dataAttribute.enumValues?.includes(dataAttributesValues[dataAttribute.id] ?? '')}
          />
        </div>
      </div>
      <div data-qa="data-attribute">
        {dataAttribute.enumValues?.includes(dataAttributesValues[dataAttribute.id] ?? '') && (
          <div data-qa="data-attribute-enum" className="dimension column" style={{ padding: '15px 0px' }}>
            <h6>{dataAttribute.id === 'breakBefore' ? 'Start Page Break On:' : ''}</h6>
            <DropDownMenuWithBaseStyle
              dataQa="data-attribute-toggle-with-dropdown"
              value={dataAttributesValues[dataAttribute.id] ?? dataAttribute.enumValues[0]}
              disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues, units)}
              onChange={(value) => {
                handleAction(dataAttribute, value);
              }}
              labelStyle={{
                paddingLeft: '0px',
                paddingRight: '3px',
                paddingTop: '3px',
                right: '60px',
                textAlign: 'right',
                width: '70px'
              }}
              menuStyle={{ width: '100px' }}
              baseStyles={BASE_STYLE}
            >
              {enumMenuItems.map((option) => (
                <MenuItem key={option.key} value={option.value} primaryText={option.primaryText} disabled={option.disabled} />
              ))}
            </DropDownMenuWithBaseStyle>
          </div>
        )}
      </div>
    </div>
  );
};
