import React from 'react';
import { useArrayValue } from '../useArrayValues';
import { NNCMetadataComponentProps } from './MetadataComponentTypes';
import { NNCIndexMetadataID } from '../NNCIndexMetadataTypes';
import keyDown from '../../../tinyFacade/key_listeners/TabKeySwitchRefFocus';
import { AeroIconButtonAdd, AeroIconButtonRemove } from '../../../../../general/AeroIconButton';
import { TitleMetadata } from './TitleMetatada';
import ProjectDefinitionStore from '../../../../../../flux/common/ProjectDefinitionStore';
import { AltTitleValues } from '../index';
import TextField from 'material-ui/TextField';
import refFocus from '../../../tinyFacade/refFocus';

type Props = NNCMetadataComponentProps<NNCIndexMetadataID.ALT_TITLES>;

const AddButton = React.memo(AeroIconButtonAdd);
const RemoveButton = React.memo(AeroIconButtonRemove);

export function AltTitlesMetadata({ values, onChange, onTab, currentRef }: Props) {
  const { localValues, addValue, updateValue, removeValue } = useArrayValue<Props['values']>(values, onChange);
  const lastInputRef = React.useRef<TextField>(null);
  React.useEffect(() => {
    // Default for ftid jal
    if (!ProjectDefinitionStore.allowSetting('allowMultipleAlternativeTitles', false) && localValues.length === 0) {
      submitNewValue();
    }
  }, []);

  function submitNewValue() {
    addValue([{ text: '', fontbold: false, fontlarge: false, qai: false, eicas: false, unann: false, hide: false, hidetoc: false }]);
    focusNewInput();
  }
  const focusNewInput = () => {
    refFocus({ ref: lastInputRef }).catch((e) => console.warn(e));
  };

  return (
    <div>
      {ProjectDefinitionStore.allowSetting('allowMultipleAlternativeTitles', false) && (
        <div className="show-form-action">
          <AddButton
            onClick={() => submitNewValue()}
            id="add-alt-title-button"
            data-qa="add-alt-title-button"
            tooltip="Add Alternative Titlte"
            tooltipPosition="top-left"
            onKeyDown={(e) => keyDown(e, onTab, currentRef, !!localValues.length)}
          />
        </div>
      )}
      <ul
        className="position-relative padding-right-l padding-bottom-s margin-top-none margin-bottom-none"
        data-qa="nnc-index-metadata-alt-title-entries-ul"
      >
        {!localValues.length ? (
          <li data-qa="nnc-alt-titles-list-default-text">
            <div className="font-gray font-size-12">{"Select '+' to add Alternative Titles."}</div>
          </li>
        ) : null}

        {localValues.map((value, index) => (
          <li key={index} style={{ display: 'flex' }} className="padding-bottom-s" data-qa={`nnc-index-metadata-alt-title-${index}`}>
            <div style={{ flex: 1 }}>
              <TitleMetadata
                values={value}
                alternativeTitle
                onChange={(data) => updateValue([data as AltTitleValues], index)}
                onTab={onTab}
                currentRef={index === 0 && localValues.length === 1 ? currentRef : index === localValues.length - 1 ? lastInputRef : null}
              />
            </div>
            {ProjectDefinitionStore.allowSetting('allowMultipleAlternativeTitles', false) && (
              <RemoveButton
                onClick={() => removeValue(index)}
                id="remove-alt-title-button"
                data-qa="remove-alt-title-button"
                tooltip="Remove Alternative Title"
                tooltipPosition="top-left"
                outline={true}
                onKeyDown={(e) => {
                  if (index === localValues.length - 1) {
                    keyDown(e, onTab, currentRef);
                  }
                }}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
