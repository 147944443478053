import * as Reflux from 'reflux';
import * as client from '../../clients/change-tasks';
import AutoTagStore from './AutoTagStore';
import IndexEventStore from '../events/IndexEventStore';
import { IndexEventStoreEvent } from '../events/IndexEventStore';
import Store from '../Store';
import { DocParams, ITask, IActionTaskEntry } from 'mm-types';
import { ClientError } from '../../clients/base-clients';
import ActiveUserStore from '../common/ActiveUserStore';
import { AxiosError } from 'axios';

export type ChangeTasksStoreEvent = State & {
  snackbar?: string;
  error?: AxiosError;
  pagination?: PaginationTypes;
};

let prevLogRetrieveLength = 0;

export type PaginationTypes = 'PAGINATION-END' | null;
export type State = {
  activityEntries: IActionTaskEntry[];
  tasks: ITask[];
};

export class ChangeTasksStore extends Store<State> {
  // private _xhrRetrieveLogs: JQueryXHR;
  private isBusy: boolean;

  constructor() {
    super();
    this.isBusy = false;
    this.state = {
      tasks: [],
      activityEntries: []
    };

    this.listenTo(IndexEventStore as any, this.onIndexEventStoreUpdate);
  }

  getInitialState() {
    return this.state;
  }

  getTask(taskUid) {
    return this.state.tasks.find(function (t) {
      return t.uid === taskUid;
    });
  }

  getTasks() {
    return this.state.tasks;
  }

  // Event Handlers

  onIndexEventStoreUpdate(e: IndexEventStoreEvent) {
    if (e.activity === 'tasksChanged') {
      this.retrieveTasks({ indexUid: e.data.indexUid });
    }
  }

  async retrieveLogs(docParams: DocParams, taskUids: string[]) {
    try {
      this.isBusy = true;
      const entries = await client.getAllActivityEntries(docParams.indexUid!, { taskUid: taskUids });

      // Set the tasks
      entries.forEach((e) => (e.task = this.getTask(e.taskUid)!));

      this.state.activityEntries = entries;
      prevLogRetrieveLength = entries.length;
      this.trigger(this.state as ChangeTasksStoreEvent);
      this.isBusy = false;
    } catch (err) {
      this.state.activityEntries = [];
    }
  }

  async retrieveLogsPage(pageNumber: number, docParams: DocParams, taskUids: string[]) {
    if (prevLogRetrieveLength / pageNumber === 40) {
      if (!this.isBusy) {
        try {
          const entries = await client.getAllActivityEntries(docParams.indexUid!, {
            pageNumber: pageNumber,
            taskUid: taskUids
          });

          // Set the tasks
          entries.forEach((e) => (e.task = this.getTask(e.taskUid)!));

          prevLogRetrieveLength = prevLogRetrieveLength + entries.length;
          entries.forEach((unit) => {
            this.state.activityEntries.push(unit);
          });

          this.trigger(this.state as ChangeTasksStoreEvent);
        } catch (err) {
          this.state.activityEntries = [];
        }
      }
    } else {
      this.trigger({ pagination: 'PAGINATION-END' } as ChangeTasksStoreEvent);
    }
  }

  async retrieveTasks(docParams: DocParams, silent = false) {
    this.state.tasks = await client.getAllTasks(docParams.indexUid!);
    if (!silent) {
      this.trigger(this.state as ChangeTasksStoreEvent);
    }
  }

  async createTask(newTask: Partial<ITask>, docParams: DocParams) {
    newTask = Object.assign(newTask, docParams);

    try {
      await client.addTask(newTask);

      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'tasksChanged',
        data: { indexUid: docParams.indexUid! }
      });

      await this.retrieveTasks(docParams);
    } catch (err) {
      this.trigger({ error: err as AxiosError } as ChangeTasksStoreEvent);
    }
  }

  toggleActive(task: Partial<ITask>, docParams: DocParams) {
    if (task.active) {
      // was active
      AutoTagStore.removeTask(task.uid!);
    }
    this.onPatchTask(task.uid!, { active: !task.active }, docParams);
  }

  toggleResolved(task: Partial<ITask>, docParams: DocParams) {
    this.onPatchTask(task.uid!, { resolved: !task.resolved }, docParams);
  }

  async onPatchTask(taskUid: string, body: Partial<ITask>, docParams: DocParams) {
    try {
      await client.updateTask(taskUid, body, true);
      await this.retrieveTasks(docParams);
    } catch (err) {
      await this.retrieveTasks(docParams);
    }
  }

  async updateTaskParticipant(updatedTask: Partial<ITask>, docParams: DocParams) {
    if (updatedTask.participants!.length === 0) {
      return;
    }

    const participant = updatedTask.participants![0];
    const [taskUid, userUid] = [updatedTask.uid, participant.user.uid];

    await client.updateTaskParticipant(taskUid!, userUid!, participant);
    await this.retrieveTasks(docParams);
  }

  async updateTask(updatedTask: Partial<ITask>, docParams: DocParams) {
    updatedTask = Object.assign(updatedTask, docParams);

    try {
      await client.updateTask(updatedTask.uid!, updatedTask);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'tasksChanged',
        data: { indexUid: docParams.indexUid! }
      });
      await this.retrieveTasks(docParams);
    } catch (err) {
      await this.retrieveTasks(docParams);
    }
  }

  async removeTask(taskUid: string, docParams: DocParams) {
    try {
      await client.removeTask(taskUid);
      IndexEventStore.broadcastToIndex({
        userUid: ActiveUserStore.getUser()!.uid,
        activity: 'tasksChanged',
        data: { indexUid: docParams.indexUid! }
      });
      await this.retrieveTasks(docParams);
    } catch (err) {
      if (err instanceof ClientError && err.status === 412) {
        this.trigger({
          snackbar: 'There are changes tagged to this task and it cannot be deleted'
        } as ChangeTasksStoreEvent);
      }

      this.retrieveTasks(docParams);
    }
  }

  importChangeTaskCSV(files: File[]) {
    // import csv file
    // if successful , get the input , store the data in relavent type , pass through the information
    // if fail trigger snackbar error
    // listen to changetask store for state update , go through the list - if everythign successfull trigger snackbar popup and changetask list refresh
    // else trigger the error module popup and display relavent data
  }

  async isUserAssignedToTask(taskUid: string, userUid: string) {
    const task = await this.retrieveTask(taskUid);
    return task.participants.find((p) => p.user.uid === userUid);
  }

  // note: this event doesn't change store state, as its for a single task retrieval
  async retrieveTask(taskUid: string) {
    return client.getOneTask(taskUid);
  }
}

const singleton = Reflux.initStore<ChangeTasksStore>(ChangeTasksStore);
export default singleton;
