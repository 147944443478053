import * as React from 'react';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import Toggle from 'material-ui/Toggle';
import { DataAttributePropsType } from '../DataAttributePropsType';

type PropsType = DataAttributePropsType & {
  type: 'boolean' | 'enumAsToggle';
};

export const ToggleAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, handleAction, type } = props;

  if (type == 'enumAsToggle') {
    return (
      <div
        className="toggle"
        data-qa="data-attribute"
        onClick={() =>
          handleAction(
            dataAttribute,
            dataAttributesValues[dataAttribute.id] === dataAttribute.enumValues[0] ? 'unspecified' : dataAttribute.enumValues[0]
          )
        }
      >
        <div data-qa="data-attribute-boolean">
          <Toggle
            data-toggle-enabled={dataAttributesValues[dataAttribute.id]}
            disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
            name="graphiccaption"
            label={dataAttribute.displayName}
            toggled={dataAttributesValues[dataAttribute.id] === dataAttribute.enumValues[0]}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="toggle"
      data-qa="data-attribute"
      onClick={() => handleAction(dataAttribute, dataAttributesValues[dataAttribute.id] === 'true' ? 'false' : 'true')}
    >
      <div data-qa="data-attribute-boolean">
        <Toggle
          data-toggle-enabled={dataAttributesValues[dataAttribute.id]}
          disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
          name="graphiccaption"
          label={dataAttribute.displayName}
          toggled={dataAttributesValues[dataAttribute.id] === 'true'}
        />
      </div>
    </div>
  );
};
