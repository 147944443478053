import React from 'react';
import { FileExportType } from '../../../../../../flux/editor/EditorModes';
import { Divider, Menu, MenuItem, SubMenu } from '../../../EditorMenu';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import ProjectDefinitionStore, { PROJECT_DEFINITION_TYPES } from '../../../../../../flux/common/ProjectDefinitionStore';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { IIndexDefinition, IRevision } from 'mm-types';

type Props = {
  revisions?: IRevision[] | null;
  isDraft(): boolean;
  canExport(): boolean;
  isIndexVariantFamilySet(): boolean;
  airbusPdfPermissions(index: IIndexDefinition | null): boolean;
  handleDropdownExportClick(e: { key: string }): void;
  syncOpenKeysStructure(openKeys: string[]): void;
  openKeysStructure: string[];
  canExportEdb(): boolean;
  canExportAirbus(): boolean;
  canExportHelp(): boolean;
};
const ExportMenuItems = (props: Props) => {
  const project = ProjectStore.getProject();
  const indexDefinition = ProjectDefinitionStore.getCurrentIndexDefinition();

  if (project && props.revisions) {
    const permissions = project.currentUserPermissions;
    let canExport = permissions.canExportPdf;
    if (!props.isDraft()) {
      canExport = props.canExport();
    } else if (EditorStore.isMode('DIFF')) {
      canExport = EditorStore.getModeProperties<'DIFF'>().isFileExportTypeAllowed(FileExportType.PDF);
    }
    // Temporarily disabling export menu item for diff mode if variants exists as it is not yet supported.
    if (props.isIndexVariantFamilySet() && EditorStore.isMode('DIFF')) {
      canExport = false;
    } else if (project.definitionName === PROJECT_DEFINITION_TYPES.airbus) {
      canExport = props.airbusPdfPermissions(indexDefinition);
    }
    return (
      <Menu
        onClick={(e) => props.handleDropdownExportClick(e)}
        onOpenChange={(e) => props.syncOpenKeysStructure(e)}
        openKeys={props.openKeysStructure}
        tabIndex="0"
        title="Export"
      >
        <SubMenu key="exportsubmenu" value="exportsubmenu">
          <MenuItem key="exportToPdf" id={'menu-item-export-to-pdf'} value="exportToPdf" disabled={!canExport}>
            Export to PDF
          </MenuItem>
          <Divider />
          <MenuItem key="exportArcml" id={'menu-item-export-to-arcml'} value="exportArcml" disabled={!permissions.canExportArcXml}>
            Export to ArcML
          </MenuItem>
          {props.canExportEdb() ? (
            <MenuItem key="exportEdb" id={'menu-item-export-to-edb'} value="exportEdb" disabled={!permissions.canExportEdb}>
              Export to EDB
            </MenuItem>
          ) : undefined}
          {props.canExportAirbus() ? (
            <MenuItem key="exportAirbus" id={'menu-item-export-to-airbus'} value="exportAirbus" disabled={!permissions.canExportAirbus}>
              Export to Airbus
            </MenuItem>
          ) : undefined}
          {props.canExportHelp() ? (
            <MenuItem key="exportHelp" id={'menu-item-export-to-help'} value="exportHelp" disabled={!permissions.canExportHelp}>
              Export to Help
            </MenuItem>
          ) : undefined}
        </SubMenu>
      </Menu>
    );
  }
  return null;
};

export default ExportMenuItems;
