import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import EditorStore from '../../../flux/editor/EditorStore';
import GraphicScaler from '../../../utils/GraphicScaler';
import MediaStore, { MediaStoreEvent, MediaStoreEventType } from '../../../flux/editor/MediaStore';
import MediaLibHeader from './MediaLibHeader';
import MediaLibContent, { MediaLibContentType } from './MediaLibContent';
import MediaLibDetail from './MediaLibDetail';
import { FlatButton } from 'material-ui';
import { IMedia, IUnit } from 'mm-types';
import { InsertInfo } from '../EditorPage';
import { ClipPasteUnit } from '../../../utils/clipboard/clipboard';
import EditorInstanceManager from '../utils/tinyFacade/EditorInstanceManager';
import ProjectDefinitionStore from '../../../flux/common/ProjectDefinitionStore';
import { showSystemAlert } from '../../misc/SystemAlert/thunks';
import LoadingSpinner from '../../documents/kpi/TableContainer/LoadingSpinner';
import useListenToStore from '../../hooks/useListenToStore';
import { MediaInsertUtils } from './utils/mediaInsertUtils';

import appStore from '../../../appStore';
import { MediaFilterType, MediaTypesContants } from './types';

export const DEFAULT_SUPPORTED_MEDIA =
  'image/tiff,image/jpeg,image/png,image/bmp,image/gif,image/x-ms-bmp,image/svg+xml,video/mp4,application/mp4,video/quicktime';

export type MediaLibModalInfo = {
  insertInfo: null | InsertInfo;
  viewMode: boolean;
};

export type Props = {
  showControls?: boolean;
  onRequestClose: () => void;
  mediaLibModalInfo: MediaLibModalInfo;
  isShortcut?: boolean;
};

export type State = {
  sortBy: 'created';
  mediaTypes: MediaTypesContants.all | undefined;
  mediaSelected: IMedia[];
  mediaList: IMedia[];
  mediaOpened: IMedia | null;
  isStoreInitialised: boolean;
};

const MediaLibModal = (props: Props) => {
  const mediaLibContentRef = useRef<MediaLibContentType>(null);
  const [state, setState] = useState<State>({
    sortBy: 'created',
    mediaTypes: MediaTypesContants.all,
    mediaSelected: [],
    mediaList: [],
    mediaOpened: null,
    isStoreInitialised: false
  });

  useListenToStore({ store: MediaStore, eventListener: onMediaStoreChange });

  useEffect(() => {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      editor.getActiveEditorFacade()?.setContentEditable(false);
    }
    mediaStoreSetup();

    return () => {
      const editor = EditorStore.getEditor();
      if (editor.isFocused()) {
        editor.getActiveEditorFacade()?.setContentEditable(true);
        editor.silentReFocus();
      }
      closeModal(true);
    };
  }, []);

  const mediaStoreSetup = () => {
    const projectUid = EditorStore.getDocParams().projectUid;
    if (projectUid) {
      MediaStore.setup(projectUid, getFilterTypeOnInsert());
    } else {
      throw new Error('Media Store setup error: project uid not present');
    }
  };

  function onMediaStoreChange(event: MediaStoreEvent<MediaStoreEventType>) {
    if (event.type === 'storeInitialised') {
      setState((prevState) => ({ ...prevState, isStoreInitialised: (event as MediaStoreEvent<'storeInitialised'>).data.storeInitialised }));
    }
  }

  const closeModal = (isSilent: boolean) => {
    MediaStore.clear();
    if (!isSilent) {
      props.onRequestClose();
    }
  };

  const getFilterTypeOnInsert = (): MediaFilterType => {
    if ((!props.isShortcut || ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) && props.mediaLibModalInfo.insertInfo?.type) {
      switch (props.mediaLibModalInfo.insertInfo.type) {
        case MediaTypesContants.symbol:
          return MediaTypesContants.symbol;
        case MediaTypesContants.video:
          return MediaTypesContants.video;
        case MediaTypesContants.image:
        case 'graphic':
        case 'graphref':
          return MediaTypesContants.image;
        default:
          return MediaTypesContants.all;
      }
    }
    return MediaTypesContants.all;
  };

  const refocusEditor = (e, type: string) => {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      if (type === 'click') {
        if (!$(e.target).is('input[type=file]')) {
          e.stopPropagation();
          e.preventDefault();
        }
      }

      if (!/input/i.test(e.target.tagName) && !/textarea/i.test(e.target.tagName) && !/force-focus/i.test(e.target.className)) {
        if (type === 'mousedown') {
          // only stop mousedowns if not in an input, as we ALLOW mouse down on input
          e.stopPropagation();
          e.preventDefault();
        }

        editor.silentReFocus();
      }
    }
  };

  const handleMediaSelected = (selected: IMedia[]) => {
    setState((prevState) => ({ ...prevState, mediaSelected: selected.length > 0 ? selected : [] }));
  };

  const handleMediaOpen = (media: IMedia) => {
    setState((prevState) => ({ ...prevState, mediaOpened: media }));
  };

  const handleFooterAction = (action: string) => {
    if (action === 'cancel') {
      closeModal(false);
    } else if (action === 'exitDetails') {
      setState((prevState) => ({ ...prevState, mediaOpened: null }));
    } else {
      handleMediaInsert();
      closeModal(false);
    }
  };

  const handleMediaInsert = () => {
    const selectedMedia = mediaLibContentRef.current?.getSelectedMedia();
    const editor = EditorStore.getEditor();
    if (selectedMedia) {
      if (MediaInsertUtils.insertPointIsExternalObject(props.mediaLibModalInfo.insertInfo?.type)) {
        handleExtObjectInsert(selectedMedia[0]);
      } else if (MediaInsertUtils.currentInsertPointIsGraphic(props.mediaLibModalInfo.insertInfo?.insertPoint)) {
        replaceCurrentMediaInfoWithSelectedMedia(selectedMedia[0], editor);
      } else if (MediaInsertUtils.isInsertingElement(editor, props.mediaLibModalInfo.insertInfo)) {
        handleElementInsert(editor, selectedMedia);
      } else {
        handleUnitInsert(selectedMedia);
      }
    } else {
      throw new Error('Selected media not present');
    }
  };

  const replaceCurrentMediaInfoWithSelectedMedia = (selectedMedia: IMedia, editor: EditorInstanceManager) => {
    if (props.mediaLibModalInfo.insertInfo?.insertPoint?.dataset) {
      props.mediaLibModalInfo.insertInfo.insertPoint.dataset['mceSrc'] = (props.mediaLibModalInfo.insertInfo
        ?.insertPoint as HTMLImageElement).src = selectedMedia.location;
      props.mediaLibModalInfo.insertInfo.insertPoint.dataset['mediaUid'] = selectedMedia.uid;
      editor.silentReFocus();
    } else {
      throw new Error('Insert Info insert point dataset not correct');
    }
  };

  const showIllustrationGraphicLimitError = () => {
    appStore.dispatch<any>(
      showSystemAlert({
        errorTitle: 'Image Limit Reached',
        errorMessage: 'Can only insert a maximum of 3 images in an Illustration',
        userMessage: 'Please delete 1 image before inserting another'
      })
    );
  };

  const isMediaSelected = () => {
    return state.mediaSelected && state.mediaSelected.length > 0;
  };

  const handleUnitInsert = async (selectedMedia: IMedia[]) => {
    if (selectedMedia[0].type === 'symbol') {
      // inserting symbol as unit not allowed
      showMediaInsertError('symbol');
    } else {
      const newUnitTypes = selectedMedia.map(async (m) => {
        if (m.type === 'video') {
          return {
            type: 'video',
            html: ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles()
              .getUnitProfileByDefinitionId('video')
              ?.template?.replace('%MEDIA_UID%', m.uid)
              .replace('%MEDIA_LOCATION%', m.location)
          } as ClipPasteUnit;
        } else if (props.mediaLibModalInfo.insertInfo?.type) {
          const imgHtml = await GraphicScaler.getScaledHTML(m, false, props.mediaLibModalInfo.insertInfo?.type);
          return {
            type: props.mediaLibModalInfo.insertInfo?.type,
            html: imgHtml
          } as ClipPasteUnit;
        } else {
          throw new Error(`No type specified for media with uid: ${m.uid} and name: ${m.name}`);
        }
      });

      const allLoaded = (await Promise.all(newUnitTypes)) as IUnit[];
      EditorStore.createBatchUnits(allLoaded);
    }
  };

  const handleElementInsert = (editor: EditorInstanceManager, selectedMedia: IMedia[]) => {
    editor.setCursorLocationToCurrent();

    if (MediaInsertUtils.illustrationsPerElementReached(editor)) {
      showIllustrationGraphicLimitError();
    } else {
      if (MediaInsertUtils.canInsertMediaElement(selectedMedia[0], props.mediaLibModalInfo.insertInfo?.insertPosition)) {
        MediaInsertUtils.insertMedia(selectedMedia, props.mediaLibModalInfo.insertInfo);
      } else {
        showMediaInsertError(selectedMedia[0].type);
      }
    }
  };

  const handleExtObjectInsert = (media: IMedia) => {
    if (MediaInsertUtils.isExtObjInsert(props.mediaLibModalInfo.insertInfo?.type)) {
      MediaInsertUtils.insertExtObjectMedia(media, props.mediaLibModalInfo.insertInfo);
    } else {
      MediaInsertUtils.replaceExtObject(media, props.mediaLibModalInfo.insertInfo);
    }
  };

  const showMediaInsertError = (type: string) => {
    appStore.dispatch<any>(
      showSystemAlert({
        errorTitle: `Unable To Insert ${type.charAt(0).toUpperCase() + type.slice(1)}`,
        errorMessage: `Invalid insert point for ${type}`,
        userMessage: 'Please select a valid insert point'
      })
    );
  };

  const isMediaOpened = state.mediaOpened && state.mediaOpened.uid;
  const filterType = getFilterTypeOnInsert();

  return (
    <div
      className="editor-fullpage-modal medialib-container-outer"
      onMouseDown={(e) => refocusEditor(e, 'mousedown')}
      onClick={(e) => refocusEditor(e, 'click')}
    >
      {state.isStoreInitialised ? (
        <div className={isMediaOpened ? 'medialib-detail-container-outer' : 'medialib-content-container-outer'}>
          <MediaLibHeader
            showControls={!isMediaOpened}
            filterType={filterType}
            hasSymbols={ProjectDefinitionStore.getCurrentIndexDefinition()?.mediaLibraryHasSymbols === true}
          />

          <div className="row page-inner page-inner-menu-content">
            {!isMediaOpened ? (
              <MediaLibContent
                onSelected={(e) => handleMediaSelected(e)}
                onOpen={(media) => handleMediaOpen(media)}
                hasSymbols={ProjectDefinitionStore.getCurrentIndexDefinition()?.mediaLibraryHasSymbols === true}
                ref={mediaLibContentRef}
                selectedMediaItemUid={props.mediaLibModalInfo.insertInfo?.selectedMediaItemUid}
              />
            ) : (
              <MediaLibDetail
                media={state.mediaOpened!}
                onModalCloseRequest={() => {
                  handleFooterAction('cancel');
                }}
                onSelfCloseRequest={() => {
                  handleFooterAction('exitDetails');
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
      <div className="actions-footer">
        <div className="buttons">
          {!isMediaOpened ? (
            <React.Fragment>
              <FlatButton
                data-qa="media-lib-modal-cancel-btn"
                label="Cancel"
                onClick={() => {
                  handleFooterAction('cancel');
                }}
              />
              {!props.mediaLibModalInfo.viewMode && (
                <FlatButton
                  data-qa="media-lib-modal-insert-btn"
                  label="Insert"
                  onClick={() => {
                    handleFooterAction('insert');
                  }}
                  disabled={!isMediaSelected()}
                />
              )}
            </React.Fragment>
          ) : (
            <FlatButton
              label="Exit"
              onClick={() => {
                handleFooterAction('exitDetails');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaLibModal;
