import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { AttachmentType } from 'mm-types';
import { MediaTypesContants } from '../../editor/medialib/types';
import { MaterialIcon, MaterialIconHTML, MaterialIconRTF, MaterialIconXHTML } from '../../general/MaterialIcon';
import { getTypeFromMimeType } from '../../../utils/FileIcon';

export type OtherFileTypes = 'selected' | 'adx' | 'airbus_xml';

export const COLOR_ADX = '#28ACE3';
export const COLOR_SELECTED = '#28ACE3';
export const COLOR_PDF = '#DF6B64';
export const COLOR_HTML = '#257f74';
export const COLOR_RTF = '#8f3a30';
export const COLOR_XHTML = '#0099a3';
export const COLOR_AIRBUS = '#25367F';
export const COLOR_AUDIO = '#F05E59';
export const COLOR_ARCHIVE = '#949EA1';
export const COLOR_DOC = '#3779B6';
export const COLOR_IMAGE = '#5CC693';
export const COLOR_VIDEO = '#DB4564';
export const COLOR_TEXT = '#A3B9C9';
export const COLOR_PRESENTATION = '#E24325';
export const COLOR_SPREADSHEET = '#019F6A';
export const COLOR_OTHER = '#A3B9C9';

export type Props = {
  className?: string;
  style?: React.CSSProperties;
  fileType: AttachmentType | OtherFileTypes | MediaTypesContants | 'xml';
  mimeType?: string;
  color?: string | null;
  iconNameOnlyInClassname?: boolean;
};

/**
 * A component to determine what font icon to render based on file type prop
 */
const FileIcon = (props: React.PropsWithChildren<Props>) => {
  const [type, setType] = React.useState<AttachmentType | OtherFileTypes | MediaTypesContants | 'xml'>(props.fileType);
  const className = `file-icon ${props.className || ''} `;

  React.useEffect(() => {
    if (props.fileType === MediaTypesContants.other) {
      setType(getTypeFromMimeType(undefined, props?.mimeType));
    }
  }, [props.fileType, props?.mimeType]);

  const customIconProps = {};
  if (props.style?.fontSize) {
    customIconProps['height'] = props.style?.fontSize;
    customIconProps['width'] = props.style?.fontSize;
  }

  switch (type) {
    case MediaTypesContants.image:
      return (
        <MaterialIcon
          style={{ ...props.style }}
          className={className}
          iconName={'insert_photo'}
          iconNameOnlyInClassname={props?.iconNameOnlyInClassname}
        >
          {props.children}
        </MaterialIcon>
      );
    case MediaTypesContants.video:
      return (
        <MaterialIcon
          style={{ ...props.style }}
          className={className}
          iconName={'videocam'}
          iconNameOnlyInClassname={props?.iconNameOnlyInClassname}
        >
          {props.children}
        </MaterialIcon>
      );
    case MediaTypesContants.symbol:
      return (
        <MaterialIcon
          style={{ ...props.style }}
          className={className}
          iconName={'icon-special-character'}
          iconNameOnlyInClassname={props?.iconNameOnlyInClassname}
        >
          {props.children}
        </MaterialIcon>
      );
    case MediaTypesContants.audio:
      return (
        <MaterialIcon
          style={{ ...props.style }}
          className={className}
          iconName={'micro'}
          iconNameOnlyInClassname={props?.iconNameOnlyInClassname}
        >
          {props.children}
        </MaterialIcon>
      );
    case 'html':
      return (
        <MaterialIconHTML
          style={{ ...props.style, color: props.color ? props.color : COLOR_HTML, ...customIconProps }}
          className={`${className} custom-svg-icon icon-doc-type-html`}
        />
      );
    case 'rtf':
      return (
        <MaterialIconRTF
          style={{ ...props.style, color: props.color ? props.color : COLOR_RTF, ...customIconProps }}
          className={`${className} custom-svg-icon icon-doc-type-rtf`}
        />
      );
    case 'xhtml':
      return (
        <MaterialIconXHTML
          style={{ ...props.style, color: props.color ? props.color : COLOR_XHTML, ...customIconProps }}
          className={`${className} custom-svg-icon icon-doc-type-xhtml`}
        />
      );
    case 'adx':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_ADX }} className={`${className} icon-doc-type-adx`}>
          {props.children}
        </FontIcon>
      );
    case 'xml':
    case 'airbus_xml':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_AIRBUS }} className={`${className} icon-doc-type-xml`}>
          {props.children}
        </FontIcon>
      );
    case 'selected':
      return (
        <FontIcon
          style={{ ...props.style, color: props.color ? props.color : COLOR_SELECTED }}
          className={`${className} icon-select-document`}
        >
          {props.children}
        </FontIcon>
      );

    case 'pdf':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_PDF }} className={`${className} icon-pdficon`}>
          {props.children}
        </FontIcon>
      );
    case 'audio':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_AUDIO }} className={`${className} icon-audiofileicon`}>
          {props.children}
        </FontIcon>
      );
    case 'archive':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_ARCHIVE }} className={`${className} icon-zipfileicon`}>
          {props.children}
        </FontIcon>
      );
    case 'doc':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_DOC }} className={`${className} icon-wordicon`}>
          {props.children}
        </FontIcon>
      );
    case 'image':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_IMAGE }} className={`${className} icon-imagefileicon`}>
          {props.children}
        </FontIcon>
      );
    case 'video':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_VIDEO }} className={`${className} icon-videofileicon`}>
          {props.children}
        </FontIcon>
      );
    case 'text':
      return (
        <FontIcon style={{ ...props.style, color: props.color ? props.color : COLOR_TEXT }} className={`${className} icon-textfileicon`}>
          {props.children}
        </FontIcon>
      );
    case 'presentation':
      return (
        <FontIcon
          style={{ ...props.style, color: props.color ? props.color : COLOR_PRESENTATION }}
          className={`${className} icon-powerpointicon`}
        >
          {props.children}
        </FontIcon>
      );
    case 'spreadsheet':
      return (
        <FontIcon
          style={{ ...props.style, color: props.color ? props.color : COLOR_SPREADSHEET }}
          className={`${className} icon-excelicon`}
        >
          {props.children}
        </FontIcon>
      );
    default:
      return (
        <FontIcon
          style={{ ...props.style, color: props.color ? props.color : COLOR_OTHER }}
          className={`${className} icon-genericfileicon`}
        >
          {props.children}
        </FontIcon>
      );
  }
};

export default FileIcon;
