import React, { useImperativeHandle, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import EditorStore from '../../../../../flux/editor/EditorStore';
import PopupContainer from '../../../../misc/PopupContainer';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { IActivity } from 'mm-types';
import { CircularProgressLoader } from '../../../../misc/Loaders';
import ActivityContainer, { ActivityContainerType } from '../activityContainer/ActivityContainer';

export type Props = {
  isMostRecentUnitSelected: boolean;
  isActivityDiffOn?: number;
};

export type State = {
  activity: null | IActivity;
  parentEntry?: IActivity | null;
  prevActivity?: IActivity;
  loading: boolean;
};

export type ActivityPopupType = {
  open(e: React.ReactInstance): void;
  getPopup(): PopupContainer | null;
  showDetails(activityEntry: IActivity, prevActivity?: IActivity, parentEntry?: IActivity): void;
};

const ActivityPopup = React.forwardRef((props: Props, ref: React.Ref<ActivityPopupType>) => {
  const [state, setState] = useState<State>({
    activity: null,
    parentEntry: null,
    loading: false
  });

  const activityPopupRef = useRef<PopupContainer>(null);

  useImperativeHandle(ref, () => ({
    open,
    getPopup,
    showDetails
  }));

  const open = (triggerRef: React.ReactInstance) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    activityPopupRef.current?.open().setStyle((popupEl: Element) => {
      const TOP_OFFSET = 255,
        $listItem = $(ReactDOM.findDOMNode(triggerRef) as Element),
        $popup = $(ReactDOM.findDOMNode(popupEl) as Element),
        popupH = $popup.outerHeight()!,
        windowH = $(window).height()!,
        listItemH = $listItem.height()!,
        listItemW = $listItem.width()! + 33,
        listItemTop = $listItem.offset()!.top,
        topPositionPlacement = parseInt((listItemTop + listItemH - TOP_OFFSET).toString());

      if (topPositionPlacement + popupH > windowH - TOP_OFFSET) {
        const popupRelativeTop = parseInt((windowH - popupH).toString());
        return {
          popup: { top: 'auto', bottom: 50, right: listItemW },
          pointer: { top: listItemTop - popupRelativeTop + listItemH + 20 }
        };
      } else {
        return {
          popup: { top: listItemTop - listItemH + 20, bottom: 'auto', right: listItemW },
          pointer: { top: listItemH }
        };
      }
    });
  };

  const handlePopupClosed = () => {
    $('body').toggleClass('dom-overlaid', false);
  };

  const close = () => {
    activityPopupRef.current?.close();
  };

  const scaleDown = () => {
    return state.activity?.entityType === 'table' || state.activity?.entityType === 'list';
  };

  const allowRevertBeforeHtml = () => {
    return !startsWith(state.parentEntry ? state.parentEntry?.operation : '', 'shared_') && !EditorStore.isReadOnly();
  };

  const allowRevertAfterHtml = () => {
    return (
      !EditorStore.isReadOnly() &&
      !startsWith(state.parentEntry ? state.parentEntry?.operation : '', 'shared_') &&
      !props.isMostRecentUnitSelected &&
      state.activity?.operation !== 'promotion' &&
      state.activity?.operation !== 'deleted' &&
      state.activity?.operation !== 'added'
    );
  };

  const startsWith = (str: string, searchString: string) => {
    return str.substring(0, searchString.length) === searchString;
  };

  const handleRevert = (type: ActivityContainerType) => {
    const activityUidToRevertTo =
      type === ActivityContainerType.ORIGINAL ? state.activity?.uid : state.prevActivity?.uid ?? state.parentEntry?.uid;
    const revertHtml: string | undefined = type == ActivityContainerType.ORIGINAL ? state.activity?.beforeHtml : state.activity?.afterHtml;

    if (!EditorStore.isBusy() && revertHtml && activityUidToRevertTo && state.activity?.unitUid) {
      setState((prevState) => ({ ...prevState, loading: true }));
      EditorStore.revertUnit(state.activity.unitUid, activityUidToRevertTo, revertHtml).catch((e) =>
        console.warn(`ActivityPopup.ts - handleRevert() - error: ${e}`)
      );
      activityPopupRef.current?.close();
    }
  };

  const getPopup = () => {
    return activityPopupRef.current;
  };

  const showDetails = (activityEntry: IActivity, prevActivity: IActivity, parentEntry?: IActivity) => {
    setState({
      loading: false,
      activity: activityEntry,
      parentEntry: parentEntry,
      prevActivity: prevActivity
    });
  };

  return (
    <PopupContainer mountOnBody={true} ref={activityPopupRef} className="activity-details-popup" onClose={() => handlePopupClosed()}>
      <MuiThemeProvider>
        <>
          {!state.loading && state.activity ? (
            <div>
              <div className="title">
                {state.activity.ordinal} {state.activity.heading}
              </div>
              <a className="close" onClick={() => close()}>
                CLOSE
              </a>
              <div className={`icon-activity-${state.activity.icon}`}>
                <div className="info">
                  {state.activity.display === 'Heading Promotion'
                    ? state.activity.display + 'to ' + state.activity?.entityType
                    : state.activity.display}
                  {state.activity.undoRedoAction ? ' (' + state.activity.undoRedoAction + ')' : ''}
                </div>
              </div>
              <div></div>

              <ActivityContainer
                type={ActivityContainerType.ORIGINAL}
                hidden={
                  state.activity.operation == 'promotion' || state.activity.operation == 'added' || state.activity.operation == 'readded'
                }
                html={state.activity.beforeHtml}
                scaleDown={scaleDown() ? 'scale' : ''}
                allowRevertHtml={allowRevertBeforeHtml()}
                handleRevert={(type) => handleRevert(type)}
              />

              <ActivityContainer
                type={ActivityContainerType.UPDATED}
                diffProperties={state.activity?.diffProperties}
                html={state.activity?.afterHtml}
                diffHtml={state.activity.afterDiffHtml}
                scaleDown={scaleDown() ? 'scale' : ''}
                allowRevertHtml={allowRevertAfterHtml()}
                handleRevert={(type) => handleRevert(type)}
              />
            </div>
          ) : null}
          <CircularProgressLoader visible={state.loading} size={59.5} />
        </>
      </MuiThemeProvider>
    </PopupContainer>
  );
});

export default ActivityPopup;
