import * as React from 'react';
import FileImporter from '../../../../../components/projects/modals/FileImporter';
import { IFileAttachment, AttachmentVisibility } from 'mm-types';
import ServerSettingsStore from '../../../../../flux/common/ServerSettingsStore';
import AttachmentSummary from './AttachmentSummary';
import { UploadType } from '../../../../projects/ImportDocument';

/**
 * A simple component that allows you to select specific users that have made
 * a comment in the project. The component calls the callback userListChange
 * only when the component is removed from the DOM.
 */
export type Props = {
  onClose: () => void;
  onUploadAttachment: (attachment: Partial<IFileAttachment>) => void;
};

export type State = {
  openFileModal: boolean;
  blacklistedAttachmentTypes: string[];
  maxAttachmentSize: number | undefined;
  openSummaryModal: boolean;
  uploadType: UploadType;
  isBusy: boolean;
  fileIndex: number;
  visibility: AttachmentVisibility;
  IFiles: Partial<IFileAttachment>[];
};

type AttachmentPickerActions =
  | { type: 'setFileModal'; payload: boolean }
  | { type: 'setBlacklistAttachmentTypes'; payload: string[] }
  | { type: 'setMaxAttachmentSize'; payload?: number }
  | { type: 'setSummaryModal'; payload: boolean }
  | { type: 'setUploadType'; payload: UploadType }
  | { type: 'setIsBusy'; payload: boolean }
  | { type: 'setFileIndex'; payload: number }
  | { type: 'setVisibility'; payload: AttachmentVisibility }
  | { type: 'setFiles'; payload: Partial<IFileAttachment>[] };

const attachmentPickerReducer: React.Reducer<State, AttachmentPickerActions> = (state, action) => {
  switch (action.type) {
    case 'setFileModal':
      return { ...state, openFileModal: action.payload };
    case 'setBlacklistAttachmentTypes':
      return { ...state, blacklistedAttachmentTypes: action.payload };
    case 'setMaxAttachmentSize':
      return { ...state, maxAttachmentSize: action.payload };
    case 'setSummaryModal':
      return { ...state, openSummaryModal: action.payload };
    case 'setUploadType':
      return { ...state, uploadType: action.payload };
    case 'setIsBusy':
      return { ...state, isBusy: action.payload };
    case 'setFileIndex':
      return { ...state, fileIndex: action.payload };
    case 'setVisibility':
      return { ...state, visibility: action.payload };
    case 'setFiles':
      return { ...state, IFiles: action.payload };
    default:
      return { ...state };
  }
};

const AttachmentPicker = (props: Props) => {
  const [state, dispatch] = React.useReducer(attachmentPickerReducer, {
    blacklistedAttachmentTypes: [],
    maxAttachmentSize: undefined,
    openFileModal: false,
    visibility: 'INTERNAL',
    openSummaryModal: false,
    uploadType: 'attachment',
    fileIndex: 0,
    isBusy: false,
    IFiles: []
  });

  React.useEffect(() => {
    dispatch({
      type: 'setBlacklistAttachmentTypes',
      payload: ServerSettingsStore.getServerSettings().blacklistedMediaTypes!.attachmentContentType
    });
    dispatch({
      type: 'setMaxAttachmentSize',
      payload: ServerSettingsStore.getServerSettings().maxAttachmentSizeMB
    });
  }, []);

  const onSuccess = (files: File[]) => {
    const tempFiles = files.map((file) => {
      return {
        content: file,
        visibility: 'INTERNAL',
        mimeType: file.type,
        filename: file.name
      } as Partial<IFileAttachment>;
    });

    dispatch({ type: 'setFiles', payload: tempFiles });
    dispatch({ type: 'setSummaryModal', payload: true });
  };

  const onImportCancel = () => {
    props.onClose();
  };

  const onSummaryCancel = (fileIndex: number) => {
    const tempFiles = state.IFiles;
    tempFiles.splice(fileIndex, 1);
    dispatch({ type: 'setFiles', payload: tempFiles });
    if (fileIndex < tempFiles.length) {
      dispatch({ type: 'setFileIndex', payload: fileIndex });
    } else {
      props.onClose();
    }
  };

  const onAdd = (description: string, visibility: AttachmentVisibility) => {
    const file = state.IFiles[state.fileIndex];
    file.description = description;
    file.visibility = visibility;

    props.onUploadAttachment(file);

    let currentIndex = state.fileIndex;
    currentIndex++;

    if (currentIndex < state.IFiles.length) {
      dispatch({ type: 'setFileIndex', payload: currentIndex });
    } else {
      props.onClose();
    }
  };
  return (
    <div>
      {!state.openSummaryModal ? (
        <FileImporter
          uploadType={state.uploadType}
          maxFileAttachmentSize={state.maxAttachmentSize}
          blacklistTypes={state.blacklistedAttachmentTypes}
          isBusy={state.isBusy}
          open={true}
          attachmentTypes={[]}
          onSuccess={(file: File[]) => onSuccess(file)}
          onCancel={() => onImportCancel()}
        />
      ) : (
        <AttachmentSummary
          file={state.IFiles[state.fileIndex]}
          currentIndex={state.fileIndex}
          fileCount={state.IFiles.length}
          open={true}
          onAdd={(description, visibility) => onAdd(description, visibility)}
          onCancel={() => {
            onImportCancel();
          }}
          onCancelFile={(fileIndex: number) => {
            onSummaryCancel(fileIndex);
          }}
          permission={'false'}
        />
      )}
    </div>
  );
};

export default AttachmentPicker;
