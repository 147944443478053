type ActionProps = {
  action: string;
  tooltip: string;
  icon: string;
};

type StyleActionProps = {
  action: string;
  primaryText: string;
};

export const ACTIONS: ActionProps[] = [
  { action: 'BorderNone', tooltip: 'No Border', icon: 'icon-image-border---none' },
  { action: 'BorderThin', tooltip: 'Thin Border', icon: 'icon-image-border---thin' },
  { action: 'BorderMedium', tooltip: 'Medium Border', icon: 'icon-image-border---medium-1' },
  { action: 'BorderThick', tooltip: 'Thick Border', icon: 'icon-image-border---thick' }
];

export const STYLE_ACTIONS: StyleActionProps[] = [
  { action: 'BorderStyleSolid', primaryText: 'Solid' },
  { action: 'BorderStyleDashed', primaryText: 'Dashed' }
];
