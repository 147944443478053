import * as React from 'react';
import { IDataAttribute, IUnit } from 'mm-types';
import { DataAttributeComponentMap, DataAttributesValues, getDataAttributeType } from '../../../../../utils/DataAttributesUtil';
import Checklist, { ChecklistItemValue } from './components/Checklist';
import { TextInputAttribute } from './components/TextInputAttribute';
import { DataAttributeType, EnumMenuItemOptionType } from './DataAttributePropsType';
import { useHandleDataAttributes } from './hooks/useHandleDataAttributes';

export type Props = {
  dataAttribute: IDataAttribute;
  dataAttributes: IDataAttribute[];
  dataAttributesValues: DataAttributesValues;
  handleAction: (dataAttribute: IDataAttribute, newValue: string, isValid?: boolean) => void;
  units?: IUnit[];
  elmNid: string | null;
};

export const RenderDataAttributes = ({ dataAttribute, dataAttributes, dataAttributesValues, handleAction, units, elmNid }: Props) => {
  const [dataAttributeType, setDataAttributeType] = React.useState<DataAttributeType>(dataAttribute.type);
  const [enumMenuItems, setEnumMenuItems] = React.useState<EnumMenuItemOptionType[]>([]);
  const { onValidation, validateEnumMenuItems, isRequired, handleOnChange } = useHandleDataAttributes(
    dataAttributes,
    dataAttributesValues,
    handleAction,
    elmNid
  );

  React.useEffect(() => {
    if (dataAttribute) {
      setDataAttributeType(getDataAttributeType(dataAttribute.id, dataAttribute.type));
      setEnumMenuItems(validateEnumMenuItems(dataAttribute));
    }
  }, [dataAttribute, dataAttributesValues, dataAttribute.minimumEnumIndexAllowed]);

  function getChecklistItems(dataAttribute: IDataAttribute) {
    let checklistItems: ChecklistItemValue[] = [];
    if (dataAttribute.uiTitles && dataAttribute.enumValues) {
      dataAttribute.uiTitles.forEach((uiTitle, index) => {
        checklistItems.push({
          title: uiTitle,
          value: dataAttribute.enumValues[index]
        });
      });
    }
    return checklistItems;
  }

  function getChecklistValues(dataAttribute: IDataAttribute): string[] {
    let checklistValue = dataAttributesValues[dataAttribute.id];
    return checklistValue ? checklistValue.split(',') : [];
  }

  if (Object.keys(DataAttributeComponentMap).includes(dataAttributeType)) {
    const Component = DataAttributeComponentMap[dataAttributeType];
    return (
      <Component
        dataAttribute={dataAttribute}
        dataAttributesValues={dataAttributesValues}
        isRequired={isRequired}
        handleAction={handleOnChange}
        enumMenuItems={enumMenuItems}
        type={dataAttributeType}
        units={units}
      />
    );
  } else if (dataAttributeType === 'checklist') {
    return (
      <Checklist
        title={dataAttribute.displayName}
        items={getChecklistItems(dataAttribute)}
        selectedItems={getChecklistValues(dataAttribute)}
        onChange={(newValue) => handleOnChange(dataAttribute, newValue, true, dataAttributeType)}
      />
    );
  } else if (['text', 'datetime'].includes(dataAttributeType)) {
    return (
      <TextInputAttribute
        dataAttribute={dataAttribute}
        dataAttributesValues={dataAttributesValues}
        isRequired={isRequired}
        handleAction={handleOnChange}
        onValidation={onValidation}
        type={dataAttributeType}
      />
    );
  } else {
    return null;
  }
};
