import * as React from 'react';

import { IInvalidElement, IInvalidUnit } from 'mm-types';
import InvalidUnit from './InvalidUnit';

export type ExpandedUnitElement = {
  unit?: IInvalidUnit;
  element?: IInvalidElement;
};

export type Props = {
  invalidUnits: IInvalidUnit[];
};

const InvalidUnitsList = (props: Props) => {
  const [expanded, setExpanded] = React.useState<ExpandedUnitElement | undefined>();

  const onExpand = (unit?: IInvalidUnit, element?: IInvalidElement) => {
    setExpanded({ unit: unit, element: element });
  };

  return (
    <>
      <h1>Invalid Units</h1>
      <div className="invalid-units-counter-row">
        <span className="invalid-units-counter">
          <span className="invalid-units-counter-label">Invalid Units</span>
          <span className="invalid-units-counter-number">{props.invalidUnits.length}</span>
        </span>
      </div>
      <div>
        {props.invalidUnits.map((invalidUnit, index) => {
          return <InvalidUnit expanded={expanded} onExpand={onExpand} key={index} invalidUnit={invalidUnit} />;
        })}
      </div>
    </>
  );
};

export default InvalidUnitsList;
