import { IValueLabel } from 'mm-types';
import Checkbox from 'material-ui/Checkbox';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import * as React from 'react';
import Fade from '../../../../general/animations/Fade';

type DiffExportRowProps = {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  radioStyle?: React.CSSProperties;
  checked: boolean;
  name: string;
  value: string;
  valueName: string;
  onCheck: () => void;
  onValueChanged: (value: string) => void;
  radioButtons: IValueLabel[];
};

const DiffExportRow = (props: DiffExportRowProps) => {
  return (
    <div id={props.id} className={props.className || ''} data-qa-selected-value={props.value} style={props.style || {}}>
      <Checkbox
        iconStyle={{ marginRight: '8px' }}
        style={{ width: '25%' }}
        label={props.name}
        checked={props.checked}
        onCheck={() => props.onCheck()}
      />

      <Fade show={props.checked}>
        <RadioButtonGroup
          className="range-group"
          onChange={(e, value) => props.onValueChanged(value)}
          name={props.valueName}
          defaultSelected="ALL"
        >
          {props.radioButtons.map((radioButton: IValueLabel) => (
            <RadioButton
              key={radioButton.value}
              iconStyle={{ marginRight: '8px' }}
              disabled={false}
              value={radioButton.value}
              label={radioButton.label}
              style={props.radioStyle || {}}
              className={'range-item range-item-' + radioButton.value}
            />
          ))}
        </RadioButtonGroup>
      </Fade>
    </div>
  );
};

export default DiffExportRow;
