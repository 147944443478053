import * as React from 'react';
import { useEffect, useState } from 'react';
/* HOC */
import withConvertRules, { WithConvertRulesProps } from '../../../hoc/withConvertRules';
import withSelectedUnit, { WithSelectedUnitProps, WithSelectedUnitState } from '../../../hoc/withSelectedUnit';
import compose from '../../../../utils/compose';

import EditorStore, { INestedUnitFocusChangeEvent } from '../../../../flux/editor/EditorStore';
import ProjectStore from '../../../../flux/editor/ProjectStore';
import { transitionTo } from '../../../../utils/Navigation';
/* Components */
import AdditionalElements from './components/AdditionalElements';

import { EventStoreEventType, IDocUnitProfile, IEditorStoreEvent, IElementDefinition, IUnit, IUnitDefinition } from 'mm-types';
import ProjectDefinitionStore, {
  PROJECT_DEFINITION_TYPES,
  ProjectDefinitionStoreEvent,
  ProjectDefinitionStoreEventType
} from '../../../../flux/common/ProjectDefinitionStore';
import { actionToDefinition } from '../../utils/units/unit/inlineElementsLookup';
import MenuActionItem from '../EditorMenu/MenuActionItem';
import DocActionItems from './components/DocActionItems';
import EditActionItems from './components/EditActionItems';
import ConvertActionItems from './components/ConvertActionItems';
import LinkActionItems from './components/LinkActionItems';
import TextActionItems from './components/TextActionItems';
import { MenuSeparator } from '../EditorMenu/MenuElements';
import { InsertRulesFactory } from '../insert/utils/InsertRulesFactory';
import { getSelectedClosestTocable } from '../../../hoc/common';
import { getElementIsInsertable } from '../../../hoc/withInsertRules';
import { MenuActions } from '../file/MenuFile';
import { DEFAULT_EDIT_MENU_CONTENTS, DefaultEditMenuSettings, MenuAction } from './components/defaultMenuContents';
import { areMenuPropsEqual } from '../MenuMemoUtil';
import SpellCheckItem from './components/SpellCheckItem';
import JustifyContentItem from './components/JustifyContentItem';
import useListenToStore from '../../../hooks/useListenToStore';

export type MenuEditOnSelectParams = {
  menu: string;
  unitType?: string;
  action: string;
  metaModifier?: { shift: boolean; alt: boolean; ctrl: boolean; meta: boolean };
};

export interface ComposedProps extends WithConvertRulesProps, WithSelectedUnitProps, WithSelectedUnitState {
  onselected?: (e: MenuEditOnSelectParams) => void;
  disabled: boolean;
}

export type State = {
  isEditingFocused: boolean;
  isEditingTextSelected: boolean;
  menuContents: DefaultEditMenuSettings;
  editingDUProfile: IDocUnitProfile | null;
  editingNestedChange: null | INestedUnitFocusChangeEvent;
  selectedUnits: IUnit[];
  selectedUnitsProfiles: IUnitDefinition[];
  justifyEnabled: boolean;
  selected?: number;
  openKeys: string[];
  openKeysStructure: any[];
  selectedTextIsLink?: string | boolean;
  overrideMenuTextSelected: boolean;
};

const MenuEdit = (props: ComposedProps) => {
  const [state, setState] = useState<State>({
    menuContents: DEFAULT_EDIT_MENU_CONTENTS,
    isEditingFocused: false,
    isEditingTextSelected: false,
    editingDUProfile: null, // current focused editing unit profile
    editingNestedChange: null, // current focused editing nested info change
    selectedUnits: [],
    selectedUnitsProfiles: [],
    justifyEnabled: false,
    openKeys: [],
    openKeysStructure: [],
    overrideMenuTextSelected: false
  });

  useListenToStore({
    store: EditorStore,
    eventListener: onEditStoreUpdate,
    update: [
      state.isEditingFocused,
      state.isEditingTextSelected,
      state.editingDUProfile?.id,
      state.editingNestedChange?.focused.definition?.id
    ]
  });

  useListenToStore({
    store: ProjectDefinitionStore,
    eventListener: onProjectStoreUpdate,
    update: [
      state.isEditingFocused,
      state.isEditingTextSelected,
      state.editingDUProfile?.id,
      state.editingNestedChange?.focused.definition?.id
    ]
  });

  useEffect(() => {
    const editor = EditorStore.getEditor();

    enableAirbusOptions(ProjectDefinitionStore.isCurrentProjectDefinitionAirbus());

    if (editor && editor.isFocused()) {
      const selectedUnit = EditorStore.getSelectedUnit()!;
      const initialState: Partial<State> = {
        isEditingFocused: true,
        editingDUProfile: ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
          selectedUnit.definitionId
        ),
        editingNestedChange: EditorStore.getLastFocusedNestedUnit(),
        isEditingTextSelected: editor.isFocusedTextSelected()
      };
      setState((prevState) => ({ ...prevState, ...initialState }));
    } else {
      const selectedUnits = EditorStore.getSelectedUnits();
      const initialState = {
        selectedUnits: selectedUnits,
        selectedUnitsProfiles: filterUnitsUniqueProfiles(selectedUnits)
      };
      setState((prevState) => ({ ...prevState, ...initialState }));
    }
  }, []);

  useEffect(() => {
    updateMenuState();
  }, [state.isEditingFocused, state.isEditingTextSelected, state.editingDUProfile?.id, state.editingNestedChange]);

  useEffect(() => {
    if (state.overrideMenuTextSelected) {
      updateMenuState();
    }
  }, [state.overrideMenuTextSelected]);

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    let updatedState: Partial<State> | null = null;

    if (e.type === 'editFocus') {
      const castEvent = e as IEditorStoreEvent<'editFocus'>;

      updatedState = {
        isEditingFocused: true,
        editingDUProfile: castEvent.unit
          ? ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(castEvent.unit.definitionId)
          : null,
        isEditingTextSelected: false,
        editingNestedChange: null,
        selectedUnits: [],
        selectedUnitsProfiles: []
      };
    } else if (e.type === 'editBlur') {
      const castEvent = e as IEditorStoreEvent<'editBlur'>;

      updatedState = {
        isEditingFocused: false,
        editingDUProfile: null,
        isEditingTextSelected: false,
        selectedUnits: castEvent.editDetails ? [castEvent.editDetails as IUnit] : [],
        selectedUnitsProfiles: castEvent.editDetails ? ProjectDefinitionStore.getUnitDefinitionsByType(castEvent.type) : [],
        selectedTextIsLink: false
      };
    } else if (e.type === 'editTextSelected') {
      updatedState = {
        isEditingTextSelected: true,
        selectedTextIsLink: getIfLinkSelected()
      };
    } else if (e.type === 'editTextSelectedEnd') {
      updatedState = { isEditingTextSelected: false, selectedTextIsLink: false };
    } else if (e.type === 'nestedUnitFocusChange') {
      const castEvent = e as IEditorStoreEvent<'editFocus'>;
      updatedState = {
        editingNestedChange: castEvent.data,
        selectedUnits: [],
        selectedUnitsProfiles: [],
        selectedTextIsLink: getIfLinkSelected()
      };
    } else if (e.type === 'unitsSelected') {
      const castEvent = e as IEditorStoreEvent<'unitsSelected'>;

      updatedState = {
        selectedUnits: castEvent.data?.selectedUnits,
        selectedUnitsProfiles: filterUnitsUniqueProfiles(castEvent.data?.selectedUnits)
      };
    }

    // Make sure to clear selected units
    // if state is being updated but no units were selected
    if (updatedState && !updatedState.selectedUnits) {
      updatedState.selectedUnits = [];
      updatedState.selectedUnitsProfiles = [];
    }

    if (updatedState) {
      setState((prevState) => ({ ...prevState, ...updatedState }));
    }
  }

  function onProjectStoreUpdate(e: ProjectDefinitionStoreEvent) {
    if (e.type === ProjectDefinitionStoreEventType.SET_CURRENT_PROJECT_DEFINITION_SUCCESS) {
      enableAirbusOptions(e.state?.currentProjectDefinition?.indexDefinition?.type === PROJECT_DEFINITION_TYPES.airbus);
      adjustTextActionsBasedOnIndexDefinition();
    }
  }

  const enableAirbusOptions = (enable = true) => {
    ['linkActions', 'textActions'].map((actionGroup) => {
      DEFAULT_EDIT_MENU_CONTENTS[actionGroup].map((action: MenuAction) => {
        if (['duRefLink', 'forecolor', 'hilitecolor'].indexOf(action.action) !== -1) {
          action.visible = enable;
        }
        if (['insertLink'].indexOf(action.action) !== -1) {
          action.visible = !enable;
        }
      });
    });
  };

  const adjustTextActionsBasedOnIndexDefinition = () => {
    ['textActions'].map((actionGroup) => {
      DEFAULT_EDIT_MENU_CONTENTS[actionGroup].map((action: MenuAction) => {
        if (['forecolor'].indexOf(action.action) !== -1) {
          action.visible = ProjectDefinitionStore.isForecolorVisible();
        }

        if (['hilitecolor'].indexOf(action.action) !== -1) {
          action.visible = ProjectDefinitionStore.isHilitecolorVisible();
        }
      });
    });
  };

  const getIfLinkSelected = (): string | boolean => {
    return EditorStore.getEditor().getActiveEditorFacade()?.getLinkTypeIfAny() ?? false;
  };

  const filterUnitsUniqueProfiles = (units?: IUnit[]) => {
    const selectedUnitsProfiles: IUnitDefinition[] = [];
    units?.forEach((selectedUnit) => {
      const profile = ProjectDefinitionStore.getProjectDefinedUnitById(selectedUnit.definitionId);

      const alreadyExists = selectedUnitsProfiles.find((existingProfile) => {
        return existingProfile.displayName === profile?.displayName;
      });
      if (!alreadyExists && profile) {
        selectedUnitsProfiles.push(profile);
      }
    });
    return selectedUnitsProfiles;
  };

  const updateMenuState = () => {
    // if editing is active
    if (state.editingDUProfile) {
      let profile: IDocUnitProfile | null = getProfile();

      if (profile?.menu) {
        if (state.isEditingTextSelected && state.overrideMenuTextSelected) {
          applyOverrides(profile?.menuTextSelected, false, false);
        } else {
          applyOverrides(profile.menu, true, true);
        }
      }
    } else {
      applyOverrides(null, true, false);
    }
  };

  const getProfile = (): IDocUnitProfile | null => {
    // always prioritise the nested profile selected
    let profile: IDocUnitProfile | null = state.editingDUProfile;
    if (state.editingNestedChange?.focused?.profile && permitNestedProfileChange()) {
      if (isInlineDataElement() || isUserCreatable()) {
        const focusedProfile = state.editingNestedChange.focused.profile;
        // .hasOwnProperty('menu') shouldn't be here as profiles are cludged and sometimes eg Paragraph has menu
        if (focusedProfile && focusedProfile.hasOwnProperty('menu')) {
          profile = focusedProfile;
        }

        // check if index definition allows for text actions
        for (const i in profile?.menuTextSelected?.textActions) {
          const action = profile?.menuTextSelected?.textActions[i];
          action.enabled = !props.isElementInsertable(actionToDefinition[action.action], 0).disabled;
        }
      }
    }
    return profile;
  };

  const permitNestedProfileChange = (): boolean => {
    if (!!state.editingDUProfile) {
      !isEditingDUProfileContainsHTMLElements([]) || isEditingFocusedProfileContainsHTMLElements(['UL', 'OL']);
    }
    return true;
  };

  // "CAUTION", "WARN" & "NOTE" contain paragraphs but we don't want paragraph granted editing settings
  const isEditingDUProfileContainsHTMLElements = (htmlElements: string[]): boolean => {
    return htmlElements.indexOf(state.editingDUProfile?.displayShort!) !== -1;
  };

  const isEditingFocusedProfileContainsHTMLElements = (htmlElements: string[]): boolean => {
    return htmlElements.indexOf(state.editingNestedChange?.focused.profile.displayShort!) !== -1;
  };

  const isUserCreatable = (): boolean => {
    return (state.editingNestedChange?.focused.profile as IElementDefinition).userCreatable ?? false;
  };

  const isInlineDataElement = (): boolean => {
    return (state.editingNestedChange?.focused.profile as IElementDefinition).type === 'InlineDataElement' ?? false;
  };

  const applyOverrides = (overrides, overrideDefaults: boolean, setOverrideMenuTextSelected: boolean) => {
    if (overrides) {
      const newMenuContents: DefaultEditMenuSettings = JSON.parse(
        JSON.stringify(overrideDefaults ? DEFAULT_EDIT_MENU_CONTENTS : state.menuContents)
      );

      ['docActions', 'editActions', 'textActions', 'specialActions', 'justifyActions', 'linkActions'].forEach((actionSection) => {
        if (overrides[actionSection]) {
          let activeFormats: string[] = [];

          // query applied formats for selection
          if (!overrideDefaults && actionSection === 'textActions') {
            activeFormats = EditorStore.getEditor().getActiveEditorFacade()!.getActiveFormats();
          }

          for (const i in overrides[actionSection]) {
            const action = overrides[actionSection][i];
            overrideAction(
              newMenuContents[actionSection],
              action.action,
              action.enabled,
              action.visible,
              activeFormats.indexOf(action.action) !== -1
            );
          }

          // Very Important: first let all actions go through _overrideAction() only then run them again through _limitOptions
          // otherwise _overrideAction() will revert changes made by _limitOptions for some subsequent actions
          for (const i in overrides[actionSection]) {
            const action = overrides[actionSection][i];
            limitOptions(
              newMenuContents[actionSection],
              action.action,
              action.enabled,
              action.visible,
              activeFormats.indexOf(action.action) !== -1
            );
          }
        }
      });
      if (!linkAnchorIsInsertable().disabled) {
        newMenuContents.linkActions.map((action) => {
          if (action.action === 'insertLinkAnchor') {
            action.enabled = true;
          }
        });
      }

      const justifyEnabled = newMenuContents.justifyActions.filter((action) => action.enabled).length > 0;

      setState((prevState) => ({
        ...prevState,
        justifyEnabled: justifyEnabled,
        menuContents: newMenuContents,
        overrideMenuTextSelected: setOverrideMenuTextSelected
      }));
    } else if (overrideDefaults) {
      setState((prevState) => ({
        ...prevState,
        menuContents: DEFAULT_EDIT_MENU_CONTENTS,
        justifyEnabled: false,
        overrideMenuTextSelected: setOverrideMenuTextSelected
      }));
    }
  };

  const overrideAction = (actions, changedAction: string, enabled: boolean, visible: boolean, active = false) => {
    for (const i in actions) {
      const action = actions[i].action;
      if (action === changedAction) {
        if (typeof enabled === 'boolean') {
          actions[i].enabled = enabled;
        }
        if (typeof visible === 'boolean') {
          actions[i].visible = visible;
        }
        if (typeof active === 'boolean') {
          actions[i].active = active;
        }
        break;
      }
    }
  };

  const limitOptions = (actions: MenuActions[], changedAction: string, enabled: boolean, visible: boolean, active = false) => {
    for (const i in actions) {
      const action = actions[i].action;
      if (
        (action === 'subscript' && changedAction === 'superscript' && active) ||
        (action === 'superscript' && changedAction === 'subscript' && active)
      ) {
        actions[i].enabled = false;
      }
      if (action === 'duRefLink' && !ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()) {
        actions[i].enabled = false;
      }
      if (
        (action === 'JustifyCenter' || action === 'JustifyLeft' || action === 'JustifyRight' || action === 'JustifyFull') &&
        ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition.allowHorizontalAlignment === false
      ) {
        actions[i].enabled = false;
      }
      if (state.selectedTextIsLink === 'DuRef' && action === 'insertLink') {
        actions[i].enabled = false;
      }
      if (state.selectedTextIsLink === 'Link' && action === 'duRefLink') {
        actions[i].enabled = false;
      }
    }
  };

  const linkAnchorIsInsertable = () => {
    let selectedUnit = EditorStore.getSelectedUnit();
    if (state.isEditingFocused && selectedUnit) {
      // get selected unit
      const rules = InsertRulesFactory({
        editingDUProfile: ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
          selectedUnit.definitionId
        ),
        selectedUnit: selectedUnit,
        selectedUnitClosestTocable: getSelectedClosestTocable(selectedUnit) ?? selectedUnit,
        editingNestedChange: state.editingNestedChange,
        isSelected: true,
        isSelectedNotEditing: false,
        isActivelyEditing: true
      });
      const insertable = rules.currentlyInsertableElements(state.editingNestedChange);
      const isInsertable = getElementIsInsertable(insertable, props.dtdValidatedElements);
      const elemDefinitionId = ProjectDefinitionStore.toElemDefinitionId('LinkAnchor');
      return isInsertable(elemDefinitionId, 0);
    }
    return { disabled: true };
  };

  const handleSelected = (e: React.MouseEvent<HTMLElement>) => {
    const action = e.currentTarget.dataset.action!;

    if (action === 'open-project') {
      transitionTo('/');
    } else if (props.onselected) {
      if (e.currentTarget.className.indexOf('disabled') === -1) {
        props.onselected({
          menu: 'edit',
          action: action,
          metaModifier: {
            ctrl: e.ctrlKey,
            alt: e.altKey,
            shift: e.shiftKey,
            meta: e.metaKey
          }
        });
      }
    }

    e.stopPropagation();
    e.preventDefault();
  };

  const isConvertingEnabled = () => {
    return (
      ((state.selectedUnitsProfiles && state.selectedUnitsProfiles.length > 0) || EditorStore.getEditor().getIsNativeFocused()) &&
      !ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()
    );
  };

  const handleDropdownClick = (e: { key: string; currentTarget: Element }) => {
    setState((prevState) => ({ ...prevState, openKeys: [], openKeysStructure: [] }));
    triggerOnSelected(e);
  };

  const triggerOnSelected = (e: { key: string; currentTarget: Element }) => {
    const key: string = e.key;
    const $tgt = $(e.currentTarget);
    if (props.onselected) {
      if (!$tgt.hasClass('item-disabled')) {
        props.onselected({
          menu: 'edit',
          unitType: key,
          action: props.isActivelyEditing ? 'convertElements' : 'convertUnits'
        });
      }
    }
  };

  const syncOpenKeysStructure = (openKeys) => {
    setState((prevState) => ({ ...prevState, openKeysStructure: openKeys }));
  };

  const allowSplit = (): boolean => {
    if (state.isEditingFocused && !state.isEditingTextSelected && EditorStore && EditorStore.getSelectedUnit()) {
      return (
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(
          EditorStore.getSelectedUnit().definitionId
        )?.splittable ?? false
      );
    }
    return false;
  };

  const allowMerge = () => {
    if (state.selectedUnits && state.selectedUnits.length > 1) {
      const initialUnitValidMerges = ProjectStore.getTypeValidMerges(state.selectedUnits[0].definitionId);
      return (
        state.selectedUnits.filter((u) => initialUnitValidMerges.indexOf(u.definitionId) === -1 || u.type === 'collection').length === 0
      );
    }
    return false;
  };

  const getCurrentTextFormats = (): string[] => {
    return EditorStore.getEditor().getActiveEditorFacade()?.getActiveFormats() ?? [];
  };

  const isReadOnly = EditorStore.isReadOnly();

  return (
    <nav className="page-body-header icon-header editor-menu editor-edit-menu no-box-shadow">
      <div className="nav-wrapper">
        <ul className="icon-action-buttons ieTooltip">
          <DocActionItems key="DocActionsItems" onAction={handleSelected} actions={state.menuContents.docActions} isReadOnly={isReadOnly} />
          <MenuSeparator />
          <EditActionItems key="EditActionsItems" onAction={handleSelected} actions={state.menuContents.editActions} />
          <MenuSeparator />
          <ConvertActionItems
            key="convertActions"
            actions={state.menuContents.convertActions}
            isConvertingEnabled={isConvertingEnabled()}
            isActivelyEditing={props.isActivelyEditing}
            editingNestedChange={state.editingNestedChange}
            selectedUnitsProfiles={state.selectedUnitsProfiles}
            contentMenuElements={props.contentMenuElements}
            contentMenuUnits={props.contentMenuUnits}
            isConvertable={props.isConvertable}
            isElementInsertable={props.isElementInsertable}
            handleDropdownClick={handleDropdownClick}
            syncOpenKeysStructure={syncOpenKeysStructure}
            openKeysStructure={state.openKeysStructure}
          />
          <MenuSeparator />
          <MenuActionItem
            key="mergeUnits"
            action={{
              tooltip: 'Merge elements',
              icon: 'call_merge',
              action: 'mergeUnits',
              enabled: true,
              visible: true
            }}
            enabled={!isReadOnly && allowMerge()}
            onAction={handleSelected}
          />
          <MenuActionItem
            key="splitUnits"
            action={{
              tooltip: 'Split elements',
              icon: 'call_split',
              action: 'splitUnits',
              enabled: true,
              visible: true
            }}
            enabled={!isReadOnly && allowSplit()}
            onAction={handleSelected}
          />
          <MenuSeparator />
          <TextActionItems
            actions={state.menuContents.textActions}
            onAction={handleSelected}
            isEditingFocused={state.isEditingFocused}
            currentTextFormats={getCurrentTextFormats()}
            disabled={props.disabled}
          />
          <AdditionalElements disabled={!state.isEditingTextSelected} isElementInsertable={props.isElementInsertable} />
          <MenuSeparator />
          <JustifyContentItem
            justifyEnabled={state.justifyEnabled}
            onSelected={props.onselected}
            getCurrentTextFormats={getCurrentTextFormats}
            justifyActions={state.menuContents.justifyActions}
          />
          <MenuSeparator />
          <LinkActionItems onAction={handleSelected} actions={state.menuContents.linkActions} />
          <MenuSeparator />
          <SpellCheckItem handleSelected={handleSelected} isReadOnly={isReadOnly} />
        </ul>
      </div>
    </nav>
  );
};

export default compose(withSelectedUnit, withConvertRules)(React.memo(MenuEdit, areMenuPropsEqual));
