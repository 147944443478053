import appStore from '../../../appStore';
import { IProjectSettingsState } from './index';
import { Action } from './actions';
import { ISettings } from 'mm-types';

export function setProjectSettings(settings: ISettings, projectUid: string, masterIndexUid: string) {
  appStore.dispatch(Action.setProjectSettingsAction(settings, projectUid, masterIndexUid));
}

export function getProjectSettings(projectUid: string, masterIndexUid: string): IProjectSettingsState | null {
  const projectSettings = appStore.getState().projectSettings;
  if (
    projectSettings?.selected?.projectUid === projectUid &&
    projectSettings.selected.masterIndexUid === masterIndexUid &&
    !projectSettings.selected.fetching
  ) {
    return projectSettings;
  }
  return null;
}
