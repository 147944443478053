import * as React from 'react';
import * as _ from 'lodash';
import ToggleSection from '../misc/ToggleSection';
import userUtil from '../documents/team/util/user';
import WorkflowActionArea from './workflow-action-area/WorkflowActionArea';
import { IIndex, IProject, IStage, IUser, IWorkFlow, IWorkflowAssignments } from 'mm-types';
import Checkbox from 'material-ui/Checkbox';
import WorkflowUtil from '../../utils/WorkflowUtil';
import { WorkflowActionData } from '../../clients/workflows';
import dateUtil from '../../utils/DateUtil';

let approveCodes = {
  COMPLETED: 'Approved',
  COMPLETE: 'Approved',
  APPROVE: 'Approved'
};

export const rejectCodes = {
  REJECT: 'Rejected',
  REJECTED: 'Rejected'
};

let skippedCodes = {
  SKIPPED: 'Skipped'
};

let allStatusCodes = {
  COMPLETED: 'Approved',
  COMPLETE: 'Approved',
  APPROVE: 'Approved',
  APPROVE_SKIP_NEXT: '',
  REJECT: 'Rejected',
  REJECTED: 'Rejected',
  IN_PROGRESS: 'Pending',
  NOT_STARTED: 'Pending',
  SKIPPED: 'Skipped',
  NOT_REQUIRED: 'Not Required',
  SKIP: 'Skipped'
};

export type Props = {
  data?: Partial<IWorkFlow>;
  highlightCurrentStage?: boolean;
  performActions?: boolean | null;
  user?: IUser;
  index?: IIndex;
  approvalInfo?: string | null;
  project?: IProject;
  toggleSkippableStages?: boolean;
  workflowPublishMode?: boolean;
  workflow?: IWorkFlow;
  onAction?: (workflowUid: string, workflowActionData: WorkflowActionData) => void;
  onWorkflowStart?: (index: IIndex, callback: () => void) => void;
  onOpenApprovalSettings?: () => void;
};

export type State = {
  skipToAvailableStage: boolean;
};

export default class WorkflowSummary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      skipToAvailableStage: false
    };
  }

  static defaultProps: Partial<Props> = {
    data: {},
    highlightCurrentStage: false
  };

  // **************************** JSX Helper methods ****************************
  // Those helper methods most likely will change, publish stage doesn't have to be the last one
  // Look up stage's type instead in future

  _getWorkflowStartedBy() {
    if (this.props.data && this.props.data.startedBy) {
      return this.props.data.startedBy.displayName;
    }
  }

  _getStageIconName(stage: IStage) {
    if (stage.publishStep && !rejectCodes[stage.status]) {
      return 'send';
    } else {
      let icons = {
        COMPLETED: 'check',
        COMPLETE: 'check',
        REJECTED: 'close',
        SKIPPED: 'remove',
        OTHER: 'more_horiz'
      };

      return icons[stage.status] ? icons[stage.status] : 'more_horiz';
    }
  }

  _getStageClassName(stage: IStage) {
    return 'material-icons workflow-icon ' + stage.status + (stage.publishStep && !rejectCodes[stage.status] ? ' oval' : ' square');
  }

  _getUserClassName(stage: IStage) {
    return 'material-icons user-status-icon ' + stage.status;
  }

  _getLineAfterIcon(stage: IStage) {
    if (this.props.data && this.props.data.stages) {
      let stageIndex = this.props.data.stages.indexOf(stage);

      if (stageIndex < this.props.data.stages.length - 1) {
        return <div className="after-icon"></div>;
      }
    }
  }

  _getMyAvatarUrl(user: IUser) {
    return userUtil.imagePath(user);
  }

  _getUserStatusDetails(user: IWorkflowAssignments, stage: IStage) {
    let iconClass = 'material-icons user-status-icon ' + (user.reviewResult ? user.reviewResult : 'PENDING');

    let icons = {
      APPROVE: 'check',
      PENDING: 'more_horiz',
      REJECT: 'close',
      NOT_REQUIRED: 'close',
      SKIP: 'close'
    };

    return (
      <div className="details__data">
        <div>
          <span className={iconClass}>{icons[user.reviewResult ? user.reviewResult : 'PENDING']}</span>
          <span className="status-name">{user.reviewResult ? this._getUserActionTitle(user.reviewResult, stage) : 'Pending'}</span>
        </div>
        <div>
          <span className="status-date">{this._getUserStatusDate(user)}</span>
        </div>
      </div>
    );
  }

  _getActiveStageTitle() {
    if (this.props.data!.activeStage) {
      return this.props.data!.activeStage!.title;
    } else if (this.props.data!.type === 'ARCHIVED') {
      return 'Workflow Finished';
    }
  }

  _getActiveStageStatus() {
    if (this.props.data!.activeStage) {
      return this._getStatusTitle(this.props.data!.activeStage!.status);
    }
  }

  _isWorkflowActiveOrFinished() {
    return this._getActiveStageStatus() || this.props.data!.type === 'ARCHIVED';
  }

  _getStageStatusTitle(stage: IStage) {
    if (approveCodes[stage.status]) {
      if (stage.nextStage! > -1) {
        return (
          <span>
            Moved to <b>{this.props.data!.stages![stage.nextStage!].title}</b>
          </span>
        );
      } else {
        return <span>Completed</span>;
      }
    } else if (rejectCodes[stage.status] && stage.previousStage! > -1) {
      return (
        <span>
          Moved to <b>{this.props.data!.stages![stage.previousStage!].title}</b>
        </span>
      );
    } else if (skippedCodes[stage.status] && stage.nextStage! > -1) {
      return (
        <span>
          Skipped to <b>{this.props.data!.stages![stage.nextStage!].title}</b>
        </span>
      );
    } else if (skippedCodes[stage.status] && typeof stage.nextStage! === 'undefined') {
      return <span>Skipped</span>;
    } else if (stage.autoStagesSkip) {
      return <span>Skipped</span>;
    } else {
      // Not Next / Previous Action so lookup standard title
      return '';
    }
  }

  _getStatusTitle(code: string) {
    return allStatusCodes[code] ? allStatusCodes[code] : 'Pending';
  }

  _getUserActionTitle(code: string, stage: IStage) {
    if (code === 'APPROVE_SKIP_NEXT') {
      return 'Approved';
    }
    // According to User's action return Next / Previous Button Title as an Action or other standard titles
    if (approveCodes[code] && stage.nextStageActionTitle) {
      return stage.nextStageActionTitle;
    } else if (rejectCodes[code] && stage.previousStageActionTitle) {
      return stage.previousStageActionTitle;
    } else {
      // Not Next / Previous Action so lookup standard title
      return allStatusCodes[code] ? allStatusCodes[code] : 'Pending';
    }
  }

  _getUserStatusDate(user: IWorkflowAssignments) {
    // Return formatted date only when there's a status & it's not NOT_REQUIRED (no point showing date in that case)
    if (user.reviewResult && !(['NOT_REQUIRED'].indexOf(user.reviewResult) > -1)) {
      return user.completionDateFormatted;
    }
  }

  _defaultOpenStage(stage: IStage) {
    if (this.props.data!.activeStage) {
      return stage.uid === this.props.data!.activeStage!.uid;
    } else {
      return false;
    }
  }

  private shouldStageBeGray(stage: IStage) {
    if (this.props.toggleSkippableStages === undefined && !this.state.skipToAvailableStage) {
      return false;
    }

    if (this.props.toggleSkippableStages === false) {
      return false;
    }

    if (!this.props.data!.activeStage) {
      return false;
    }

    const activeUid = this.props.data!.activeStage!.uid;

    // If its the active stage
    if (stage.uid === activeUid) {
      return false;
    }

    const stages = this.props.data!.stages!;
    let currentStageIndex = 0;

    for (let i = 0, l = stages.length; i < l; i++) {
      if (stages[i].uid === activeUid) {
        currentStageIndex = i;
        break;
      }
    }

    for (let i = 0, l = stages.length; i < l; i++) {
      if (i > currentStageIndex) {
        if (!stages[i].skippableStage) {
          return false;
        }

        if (stages[i].uid === stage.uid) {
          return true;
        }
      }
    }

    return false;
  }

  _valueOrDefault(value: number | string | undefined | JSX.Element, defaultValue?: string) {
    if (value || value === 0) {
      return value;
    } else {
      return defaultValue ? defaultValue : '-';
    }
  }

  _wrapWithDefault(value: number | string) {
    if (value || value === 0) {
      return value;
    } else {
      return '-';
    }
  }

  _createAvatar(user: IWorkflowAssignments) {
    if (user.isGroup) {
      return <span className="fontello-icon material-icons icon-group icon" />;
    } else {
      return <img className="popup-avatar" src={user.avatarUrl} />;
    }
  }

  render() {
    let canUserPerformAction = WorkflowUtil.canUserPerformAction(
      this.props.user,
      this.props.workflow,
      this.props.project,
      this.props.index
    );
    let showSkipCheckbox = false;

    if (canUserPerformAction) {
      showSkipCheckbox = this.props.project!.currentUserPermissions?.canSkipMultipleStages;

      if (this.props.data && this.props.data.activeStage && this.props.data.stages) {
        const activeUid = this.props.data.activeStage.uid;
        const stages = this.props.data.stages;

        for (let i = 0, l = stages.length; i < l; i++) {
          if (stages[i].uid === activeUid) {
            if (i + 1 < l) {
              // Is stage after active one not skippable? If so then disable
              if (!stages[i + 1].skippableStage) {
                showSkipCheckbox = false;
              }
            } else {
              showSkipCheckbox = false;
            }
            break;
          }
        }
      }
    }

    if (!this.props.data!.stages) {
      return <div />;
    } else {
      return (
        <div className="workflow-summary">
          <div className="main-section">
            {!this.props.workflowPublishMode ? (
              <div className="details">
                {this.props.highlightCurrentStage ? (
                  <div className="details__row highlighted">
                    <div className="details__label">Current Stage:</div>
                    <div className="details__data">{this._getActiveStageTitle()}</div>
                  </div>
                ) : (
                  <div />
                )}
                {!this.props.workflowPublishMode ? (
                  <div>
                    <div className="details__row">
                      <div className="details__label">Workflow:</div>
                      <div className="details__data">{this.props.data!.name}</div>
                    </div>
                    <div className="details__row">
                      <div className="details__label">Started:</div>
                      <div className="details__data" data-qa="workflow-summary-started-date">
                        {this._valueOrDefault(this.props.data!.startDateFormatted)}
                      </div>
                    </div>
                    {!this.props.highlightCurrentStage ? (
                      <div className="details__row">
                        <div className="details__label">Current Stage:</div>
                        <div className="details__data">{this._valueOrDefault(this._getActiveStageTitle()!)}</div>
                      </div>
                    ) : (
                      <div />
                    )}

                    {this.props.data!.activeStage ? (
                      <div className="details__row">
                        <div className="details__label">Status:</div>
                        <div className="details__data">{this._getActiveStageStatus()}</div>
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className="details__row">
                      {showSkipCheckbox ? (
                        <Checkbox
                          className="skip-multiple-workflow"
                          label={
                            this.state.skipToAvailableStage
                              ? 'Skip predefined stage(s) of the workflow. Greyed out stages of the workflow will be skipped.'
                              : 'Skip predefined stage(s) of the workflow.'
                          }
                          labelStyle={{ color: '#aaa', padding: '0 0 0 60px' }}
                          iconStyle={{ fill: this.state.skipToAvailableStage ? '#05adde' : '#888' }}
                          checked={this.state.skipToAvailableStage}
                          onCheck={(e) => this.setState({ skipToAvailableStage: !this.state.skipToAvailableStage })}
                        />
                      ) : undefined}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {this.props.performActions ? (
            <WorkflowActionArea
              user={this.props.user}
              project={this.props.project}
              index={this.props.index}
              isMultiSkip={this.props.toggleSkippableStages || this.state.skipToAvailableStage}
              approvalInfo={this.props.approvalInfo!}
              workflow={this.props.workflow}
              onAction={(workflowUid, workflowActionData) => {
                if (this.props.onAction) {
                  this.setState({ skipToAvailableStage: false });

                  if (
                    (this.state.skipToAvailableStage || this.props.toggleSkippableStages === true) &&
                    workflowActionData.decisionModel === 'APPROVE'
                  ) {
                    workflowActionData.decisionModel = 'APPROVE_SKIP_NEXT';
                  }

                  this.props.onAction(workflowUid, workflowActionData);
                }
              }}
              onWorkflowStart={(index, callback) => {
                if (this.props.onWorkflowStart) {
                  this.props.onWorkflowStart(index, callback);
                }
              }}
              onOpenApprovalSettings={() => {
                if (this.props.onOpenApprovalSettings) {
                  this.props.onOpenApprovalSettings();
                }
              }}
            />
          ) : null}

          <div>
            <div data-qa="workflow-stage" className="stage">
              <div className="left-side">
                <div className="before-icon empty" />
                <div className={'material-icons workflow-icon oval ' + (this._isWorkflowActiveOrFinished() ? ' COMPLETE' : ' NOT_STARTED')}>
                  check
                </div>
                <div className="after-icon" />
              </div>

              <div className="right-side toggle-section-outer">
                <ToggleSection title={'Stage: New Revision'} defaultOpen={false}>
                  <div className="details sub-section">
                    <div className="details__row">
                      <div className="details__label--sub">Started by:</div>
                      <div className="details__data">{this._valueOrDefault(this._getWorkflowStartedBy()!, 'Not Started')}</div>
                    </div>
                  </div>
                </ToggleSection>
              </div>
            </div>

            {this.props.data!.stages!.map((stage, index) => {
              let additionalStageTitle = stage.autoStagesSkip ? ' [Will be automatically skipped]' : '';
              return (
                <div key={index} data-qa="workflow-stage" className={`stage ${this.shouldStageBeGray(stage) ? 'SKIPPED' : ''}`}>
                  <div className="left-side">
                    <div className="before-icon" />
                    <div data-qa="workflow-stage-icon" className={this._getStageClassName(stage)}>
                      {this._getStageIconName(stage)}
                    </div>
                    {this._getLineAfterIcon(stage)}
                  </div>

                  <div className="right-side toggle-section-outer">
                    <ToggleSection
                      enabled={!this.shouldStageBeGray(stage)}
                      title={'Stage: ' + stage.title + additionalStageTitle}
                      defaultOpen={this._defaultOpenStage(stage)}
                    >
                      <div className="details sub-section">
                        <div className="details__row">
                          <div className="details__label--sub">Action:</div>
                          <div className="details__data">{this._valueOrDefault(this._getStageStatusTitle(stage), 'Pending')}</div>
                        </div>

                        <div className="details__row">
                          <div className="details__label--sub">Completed:</div>
                          <div className="details__data">{this._valueOrDefault(stage.completionDateFormatted)}</div>
                        </div>

                        <div className="details__row">
                          <div className="details__label--sub">Deadline:</div>
                          <div className="details__data" data-qa="workflow-summary-deadline">
                            {stage.dueDate ? dateUtil(stage.dueDate).formatDateTimeNoSecs() : ''}
                          </div>
                        </div>
                      </div>

                      {_.sortBy(stage.assignments, (a) => a.displayName?.toLowerCase()).map((user: IWorkflowAssignments, index) => {
                        return (
                          <div key={index} className="details sub-section">
                            <div className="details__row">
                              <div className="details__label--sub">{this._createAvatar(user)}</div>
                              <div className="details__data">
                                <div className="emphasized">{user.displayName}</div>
                              </div>
                            </div>
                            <div className="details__row">
                              <div className="details__label--sub">Action:</div>
                              {this._getUserStatusDetails(user, stage)}
                            </div>
                            <div className="details__row">
                              <div className="details__label--sub">Comment</div>
                              <div className="details__data">
                                <div>{this._valueOrDefault(user.comment)}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ToggleSection>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}
