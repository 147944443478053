import EditorStore from '../flux/editor/EditorStore';
import { TableUtils } from './index';

interface IParsedLengthStyleType {
  length: string | null;
  lengthNumber: string | null;
  lengthUnit: string | null;
}

const parseLengthStyleRegExp = new RegExp('^([0-9]+[.[0-9]*]?)(px|%)$', 'i');

const styleRulesReducer = (ruleMap, ruleString) => {
  const rulePair = ruleString.split(':');
  if (rulePair.length === 2) {
    ruleMap[rulePair[0].trim()] = rulePair[1].trim();
  }
  return ruleMap;
};

const parseLengthStyle = (lengthStyle?: string): IParsedLengthStyleType => {
  const [length = null, lengthNumber = null, lengthUnit = null] = (lengthStyle && lengthStyle.match(parseLengthStyleRegExp)) || [];
  return { length, lengthNumber, lengthUnit };
};

const getConvertedWidth = ($target: JQuery<Node>, $targetParent: JQuery<Node>, toLengthUnit: string) => {
  let convertedWidth = '';
  const computedWidth = parseLengthStyle($target.css('width')).lengthNumber;
  const parentComputedWidth = parseLengthStyle($targetParent.css('width')).lengthNumber;

  if (computedWidth && parentComputedWidth) {
    if (toLengthUnit === '%') {
      // px to %
      convertedWidth = `${Math.round((parseInt(computedWidth) * 100) / parseInt(parentComputedWidth))}`;
    } else if (toLengthUnit === 'px') {
      // % to px
      convertedWidth = computedWidth;
    }
  }
  return convertedWidth;
};

const changeCellUnitTo = (toLengthUnit: 'px' | '%', value = '', node?: Node, callback?: (value: string) => void): string => {
  const targets: Node[] = node ? [node] : TableUtils.getSelectedCells();
  if (targets.length === 1) {
    // convert between % and px
    const $target = $(targets[0]);
    const $targetParent = $target.closest('table');
    value = getConvertedWidth($target, $targetParent, toLengthUnit);
  }
  if (callback) {
    callback(value);
  }
  return value;
};

const changeTableUnitTo = (toLengthUnit: 'px' | '%', value = '', node?: Node, callback?: (value: string) => void): string => {
  const activeEditorFacade = EditorStore.getEditor().getActiveEditorFacade()!;
  const target: Node = node ?? activeEditorFacade.getSelectedTable();
  if (target) {
    // convert between % and px
    const $target = $(target);
    const $targetParent = $target.parent();
    value = getConvertedWidth($target, $targetParent, toLengthUnit);
  }
  if (callback) {
    callback(value);
  }
  return value;
};

const changeUnitTo = (toLengthUnit: 'px' | '%', value = '', isCell = false, node?: Node, callback?: (value: string) => void) => {
  return isCell ? changeCellUnitTo(toLengthUnit, value, node, callback) : changeTableUnitTo(toLengthUnit, value, node, callback);
};

const styleStrToObject = (styleString?: string | null): Partial<CSSStyleDeclaration> => {
  return (styleString && styleString.split(';').reduce(styleRulesReducer, {})) || {};
};

export default {
  changeUnitTo,
  parseLengthStyle,
  styleStrToObject
};
