import { NNCIndexMetadataDefinition, NNCIndexMetadataID } from '../NNCIndexMetadataTypes';

export const metadataDefinition: NNCIndexMetadataDefinition<NNCIndexMetadataID.TITLE> = {
  id: NNCIndexMetadataID.TITLE,

  toMetadataHTML: ({ eicas, fontbold, fontlarge, qai, unann, text }) => {
    return `<div 
        class="${metadataDefinition.id}" 
        qai="${qai.toString()}"
        unann="${unann.toString()}"
        eicas="${eicas.toString()}"
        fontbold="${fontbold.toString()}"
        fontlarge="${fontlarge.toString()}"
        >${text || 'New NNC Checklist'}</div>`;
  },
  toHeaderHTML: ({ text }) => {
    return text || 'New NNC Checklist';
  },
  toJSON: ($element) => {
    return {
      eicas: $element.attr('eicas') === 'true',
      fontbold: $element.attr('fontbold') === 'true',
      fontlarge: $element.attr('fontlarge') === 'true',
      qai: $element.attr('qai') === 'true',
      unann: $element.attr('unann') === 'true',
      text: $element.text()
    };
  }
};
