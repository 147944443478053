import * as React from 'react';
import {
  IconOutline,
  MaterialIconAdd,
  MaterialIconClose,
  MaterialIconDelete,
  MaterialIconDownload,
  MaterialIconEdit,
  MaterialIconPreview,
  MaterialIconRemove,
  MMIcon
} from './MaterialIcon';
import { IconButton, IconButtonProps } from 'material-ui';

interface ActionProp {
  action?: string;
}

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & IconButtonProps & MMIcon & { buttonStyle?: React.CSSProperties };
type ButtonPropsWithOutline = ButtonProps & IconOutline;

export const AeroIconButton = React.forwardRef((props: ButtonPropsWithOutline & ActionProp, ref: React.RefObject<IconButton>) => {
  const { className, action, buttonStyle, ...rest } = props;
  const actionName = action || 'icon';
  return (
    <IconButton
      data-qa={actionName}
      className={`aero-button action-${actionName} ${className || ''}`}
      style={buttonStyle}
      ref={ref}
      {...rest}
    />
  );
});

const AeroIconButtonMemo = React.memo(AeroIconButton);

export const AeroIconButtonDelete = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="delete" {...rest} ref={ref}>
      <MaterialIconDelete gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonEdit = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="edit" {...rest} ref={ref}>
      <MaterialIconEdit gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonDownload = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="download" {...rest} ref={ref}>
      <MaterialIconDownload gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonPreview = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="preview" {...rest} ref={ref}>
      <MaterialIconPreview gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonRemove = React.forwardRef((props: ButtonPropsWithOutline, ref: React.RefObject<IconButton>) => {
  const { outline, gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="remove" {...rest} ref={ref}>
      <MaterialIconRemove outline={outline} gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonClose = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButtonMemo action="close" {...rest} ref={ref}>
      <MaterialIconClose gray={gray} />
    </AeroIconButtonMemo>
  );
});

export const AeroIconButtonAdd = React.forwardRef((props: ButtonProps, ref: React.RefObject<IconButton>) => {
  const { gray, ...rest } = props;
  return (
    <AeroIconButton action="add" {...rest} ref={ref}>
      <MaterialIconAdd gray={gray} />
    </AeroIconButton>
  );
});
