import * as React from 'react';

import InsertPositionComponent from './InsertPositionComponent';
import { InsertAction } from './ContentMenuContainer';

interface Props {
  onActionUpdate: (newAction: InsertAction) => void;
  currentAction: InsertAction;
  isActivelyEditing: boolean;
}

class InsertPositionContainer extends React.Component<Props, unknown> {
  render() {
    return (
      <div className={`${this.props.isActivelyEditing ? '' : 'disabledInsert'} insert-position-container`}>
        <InsertPositionComponent
          icon={'vertical_align_top'}
          action={'insert_before'}
          label={'Insert Element Above'}
          onActionUpdate={(newAction: InsertAction) => this.props.onActionUpdate(newAction)}
          selected={this.props.currentAction === 'insert_before' && this.props.isActivelyEditing}
          enabled={this.props.isActivelyEditing}
        />
        <InsertPositionComponent
          icon={'vertical_align_center'}
          action={'insert_inside'}
          label={'Insert Element Inside'}
          onActionUpdate={(newAction: InsertAction) => this.props.onActionUpdate(newAction)}
          selected={this.props.currentAction === 'insert_inside' && this.props.isActivelyEditing}
          enabled={this.props.isActivelyEditing}
        />
        <InsertPositionComponent
          icon={'vertical_align_bottom'}
          action={'insert_after'}
          label={'Insert Element Below'}
          onActionUpdate={(newAction: InsertAction) => this.props.onActionUpdate(newAction)}
          selected={this.props.currentAction === 'insert_after' || !this.props.isActivelyEditing}
          enabled={this.props.isActivelyEditing}
        />
      </div>
    );
  }
}

export default InsertPositionContainer;
