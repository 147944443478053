import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PopupContainer from '../../../misc/PopupContainer';
import { ActiveEditorDetails, ActiveEditorType } from './ActiveEditorDetails';

export type Props = {
  activeEditors: ActiveEditorType[];
};

export type State = {
  showEditor: null;
  activeEditor?: ActiveEditorType | null;
  isOpen?: boolean;
};

export type ActiveEditorPopupType = {
  getPopup: () => PopupContainer | null;
  open: (triggerRef: React.ReactInstance) => void;
  showEditor: (activeEditor: ActiveEditorType | null) => void;
};

const ActiveEditorPopup = React.forwardRef((props: Props, ref: React.Ref<ActiveEditorPopupType>) => {
  const _AVATARWIDTHOFFSET = 50;
  let _triggerRef: null | React.ReactInstance = null;
  let activeEditorPopup: PopupContainer | null = null;

  const [state, setState] = React.useState<State>({
    showEditor: null
  });

  React.useEffect(() => {
    if (state.isOpen && !activeEditorPopup?.isOpen()) {
      setState((prevProps) => ({ ...prevProps, isOpen: false }));
    }
  }, [state.isOpen, activeEditorPopup]);

  React.useImperativeHandle(ref, () => ({
    getPopup,
    open,
    showEditor
  }));

  const getPopup = () => {
    return activeEditorPopup;
  };

  const open = (triggerRef: React.ReactInstance) => {
    _triggerRef = triggerRef;
    activeEditorPopup
      ?.setTriggerButton(triggerRef)
      .open(() => setState((prevState) => ({ ...prevState, isOpen: true })))
      .setStyle(repositionPointer.bind(this));
  };

  const showEditor = (activeEditor: ActiveEditorType | null) => {
    setState((prevState) => ({ ...prevState, activeEditor: activeEditor }));
  };

  const repositionPointer = () => {
    const $trigger = $(ReactDOM.findDOMNode(_triggerRef!) as Element);
    return {
      pointer: { right: $(window).width()! - ($trigger.offset()!.left + _AVATARWIDTHOFFSET) }
    };
  };

  return (
    <PopupContainer
      isOpen={state.isOpen}
      ref={(ref) => (activeEditorPopup = ref)}
      className="popup-list popup-list-active-editors"
      mountOnBody={true}
      onClose={() => setState((prevState) => ({ ...prevState, isOpen: false }))}
    >
      <div
        className={`lean-overlay ${state.isOpen ? 'display-block bg-none' : ''} `}
        onClick={() => setState((prevProps) => ({ ...prevProps, isOpen: false }))}
      />
      <div className="active-editior-popup">
        {state.activeEditor && <ActiveEditorDetails activeEditor={state.activeEditor} />}

        {state.activeEditor === null &&
          props.activeEditors.map((editor, editorIndex) => {
            return <ActiveEditorDetails activeEditor={editor} key={editorIndex} />;
          })}
      </div>
    </PopupContainer>
  );
});

export default ActiveEditorPopup;
