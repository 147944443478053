import * as React from 'react';
import Paper from 'material-ui/Paper';
import { transitions } from 'material-ui/styles';
import { ThemeContext } from '../../../misc/ThemeProvider';

function getStyles(
  props: MenuAnimationProps,
  state: MenuAnimationState,
  zIndex: number
): { root: React.CSSProperties; horizontal: React.CSSProperties; vertical: React.CSSProperties } {
  const { open } = state;
  const transitionDuration = `100ms`;
  const transitionDelay = `0ms`;
  const transitionProperty = ['opacity'];

  return {
    root: {
      position: 'fixed',
      zIndex,
      visibility: open ? 'visible' : 'hidden',
      opacity: open ? 1 : 0,
      transform: open ? 'scale(1, 1)' : 'scale(0, 0)',
      transition: transitions.easeOut(transitionDuration, transitionProperty, transitionDelay),
      maxHeight: '100%'
    },
    horizontal: {
      maxHeight: '100%',
      overflowY: 'auto',
      visibility: open ? 'visible' : 'hidden',
      opacity: open ? 1 : 0,
      transition: transitions.easeOut(transitionDuration, transitionProperty, transitionDelay)
    },
    vertical: {
      visibility: open ? 'visible' : 'hidden',
      opacity: open ? 1 : 0,
      transition: transitions.easeOut(transitionDuration, transitionProperty, transitionDelay)
    }
  };
}

export interface MenuAnimationProps {
  open: boolean;
  style?: React.CSSProperties;
  className?: string;
  zDepth?: number;
  children?: React.ReactNode;
}

export interface MenuAnimationState {
  open: boolean;
}

class MenuAnimation extends React.Component<MenuAnimationProps, MenuAnimationState> {
  static defaultProps = {
    style: {},
    zDepth: 1,
    className: 'root'
  };

  state = {
    open: false
  };

  componentDidMount() {
    this.setState({ open: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open
    });
  }

  render() {
    const { className, style, zDepth } = this.props;

    const styles = getStyles(this.props, this.state, this.context.zIndex.popover);

    return (
      <Paper
        style={Object.assign(styles.root, style)}
        zDepth={zDepth}
        data-qa="editor-menu-popup"
        className={`editor-menu-popup ${className || ''}`}
      >
        <div style={styles.horizontal}>
          <div style={styles.vertical}>{this.props.children}</div>
        </div>
      </Paper>
    );
  }
}

function withClassName(Component, additionalProps): any {
  return (props) => <Component {...props} {...additionalProps} />;
}
export const SubMenuAnimation = withClassName(MenuAnimation, { className: 'submenu' });
export default MenuAnimation;
MenuAnimation.contextType = ThemeContext;
