import * as React from 'react';
import * as _ from 'lodash';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

export type Props = {};

export type State = {
  preventAction?: boolean;
  showChangeTaskWarning?: boolean;
  onContinue?: () => void;
};

export default class WorkflowActionDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showChangeTaskWarning: false
    };
  }

  show(stateObj: State) {
    this.setState(_.extend({ showChangeTaskWarning: true }, stateObj));
  }

  _WorkflowActionCancel() {
    this.setState({ showChangeTaskWarning: false });
  }

  _WorkflowActionContinue() {
    this.setState({ showChangeTaskWarning: false });
    if (this.state.onContinue) {
      this.state.onContinue();
    }
  }

  _getOptions() {
    if (this.state.preventAction) {
      return [<FlatButton key={1} label="Ok" primary={true} keyboardFocused={true} onClick={() => this._WorkflowActionCancel()} />];
    } else {
      return [
        <FlatButton key={2} label="Cancel" primary={true} onClick={() => this._WorkflowActionCancel()} />,
        <FlatButton
          key={3}
          label="Continue"
          primary={true}
          keyboardFocused={true}
          onClick={() => this._WorkflowActionContinue()}
          ref="submit"
        />
      ];
    }
  }

  _getHeading() {
    if (this.state.preventAction) {
      return 'Open Tasks Blocking';
    } else {
      return 'Open Tasks Warning';
    }
  }

  _getBody() {
    if (this.state.preventAction) {
      return (
        'There are currently open tasks in the document. ' + 'You cannot proceed with the workflow change before resolving the open tasks.'
      );
    } else {
      return (
        'There are currently open tasks in the document. ' +
        'You can continue with the workflow change or cancel to modify the tasks before proceeding.'
      );
    }
  }

  render() {
    return (
      <div>
        <Dialog
          className={'workflow-dialog'}
          actions={this._getOptions()}
          style={{ zIndex: 999 }}
          title={this._getHeading()}
          open={this.state.showChangeTaskWarning!}
          modal={true}
        >
          <p className="workflow-dialog__body">{this._getBody()}</p>
        </Dialog>
      </div>
    );
  }
}
