import * as React from 'react';
import ColorPicker from './color/ColorPicker';
import { Color } from 'mm-types';

interface Props {
  title: string;
  defaultColor: Color;
  color?: Color;
  onChange: (color: Color) => void;
}

interface State {
  color: Color;
}

export default class FontColor extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color ? this.props.color : this.props.defaultColor
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ color: this.pickColor(nextProps) });
  }

  private pickColor(props: Props) {
    return props.color || this.props.color || this.props.defaultColor;
  }

  render() {
    const title: JSX.Element | null = <h6>{this.props.title}</h6>;
    return (
      <div className={'cell-background-component backgroundColortool'}>
        {title}
        <ColorPicker color={this.state.color} onChange={this.changeColor.bind(this)} icon={'format_color_text'} />
      </div>
    );
  }

  private changeColor(color: Color): void {
    this.setState({ color: color }, () => {
      this.props.onChange(color);
    });
  }
}
