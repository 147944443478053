import * as React from 'react';
import { Header } from '../Documents';
import { FontIcon } from 'material-ui';
import { Sort } from '../../../flux/projects/DocumentsStore';

interface Props {
  workspaceUid: string;
  headers: Header[];
  allSelected: boolean;
  sort: Sort;
  onSort(hdr: Header);
  onSelectAll();
}

const DocumentsHeader = ({ onSort, onSelectAll, workspaceUid, headers, allSelected, sort }: Props) => {
  function hidden(hdr): boolean {
    return hdr.hiddenOn && hdr.hiddenOn.indexOf(workspaceUid) !== -1;
  }

  return (
    <table className="table table-hover table-mc-light-blue selection-table documents-fixed-header-table">
      <thead>
        <tr>
          <th
            key="multi"
            onClick={() => {
              onSelectAll();
            }}
          >
            <FontIcon className={`fontello-menu-item material-icons select-all ${allSelected ? 'icon-select-document' : 'icon-new-doc'}`} />
          </th>
          {headers.map((hdr) => {
            return (
              <th
                key={hdr.title}
                onClick={() => {
                  onSort(hdr);
                }}
              >
                {!hidden(hdr) && <span>{hdr.title}</span>}
                {sort!.sortBy === hdr.sortById && (
                  <span className="material-icons">{sort!.sortOrder === 'asc' ? 'expand_less' : 'expand_more'}</span>
                )}
              </th>
            );
          })}
          <th key="empty-hdr">
            <span></span>
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default DocumentsHeader;
