import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const AutoSkippedWorkflow = ({ entry }: PropsType) => {
  const skippedWorkflowDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber && entry.projectActivityType === 'WORKFLOW_APPROVE_SKIP') {
    skippedWorkflowDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  } else if (entry.projectActivityType === 'WORKFLOW_STAGE_AUTOMATICALLY_SKIPPED') {
    if (entry.user) {
      skippedWorkflowDom.push(
        <div key="user">
          <label>User:</label> <span>{entry.user.displayName}</span>
        </div>
      );
    }
    if (entry.workflowTransition) {
      skippedWorkflowDom.push(
        <div key="stage">
          <label>Stage:</label> <span>{entry.workflowTransition.fromStageTitle}</span>
        </div>
      );
    }
    skippedWorkflowDom.push(
      <div key="comment">
        <label>Comment:</label> <span>Stage automatically skipped</span>
      </div>
    );
  }

  return <div className="settings-details">{skippedWorkflowDom}</div>;
};
