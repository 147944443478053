import React from 'react';
import { GoToSettingsButton } from './GoToSettingsButton';
import { WarningInformationButton } from './WarningInformationButton';
import { DocSettingsRoute } from '../../../documents/settingsMenu';
import ProjectStore from '../../../../flux/editor/ProjectStore';
import { verifyQuizAccess } from '../../../documents/quiz/quiz/utils/requirements';
import { MaterialIconListCheckbox } from '../../../general/MaterialIcon';
import { style } from '../../../../styles/styleGuard';
import { VERTICAL_ALIGN } from '../../../../styles/align';

interface Props {
  canChangeGeneralSettings: boolean;
  isUpdatedDefinitionAvailable: boolean;
  isIndexLocked: boolean;
  canChangeRevisionSettings: boolean;
  hasValidRevisionSettings: boolean;
  doesModeAllowWorkflow: boolean;
  isPublishing: boolean;
  isUserWorkflowAdmin: boolean;
  serverWarnings: string[];
  informations: string[];
  onOpenSettings(e: React.MouseEvent<any>, destination: DocSettingsRoute): void;
}

const quizIconStyle = style({
  ...VERTICAL_ALIGN.MIDDLE
});

export function SettingButtonsAndIndicators(props: Props) {
  const warningsAvailable = props.serverWarnings.length > 0;
  const showHasQuizIcon = !!ProjectStore.getIndex()?.quizUid && verifyQuizAccess(ProjectStore.getProject()).visible;

  return (
    <>
      {props.doesModeAllowWorkflow && !props.isPublishing && props.isUserWorkflowAdmin && (
        <GoToSettingsButton
          label="Settings"
          destination="workflow"
          disabled={!props.doesModeAllowWorkflow}
          onClick={props.onOpenSettings}
        />
      )}

      {props.isPublishing && props.isUserWorkflowAdmin && (
        <GoToSettingsButton
          label="Revision settings"
          destination="revision"
          className={props.hasValidRevisionSettings ? '' : 'error'}
          disabled={!props.canChangeRevisionSettings}
          onClick={props.onOpenSettings}
        />
      )}

      {warningsAvailable && !props.isIndexLocked && (
        <WarningInformationButton isInformationButton={false} messages={props.serverWarnings} />
      )}

      {props.isPublishing && props.isUserWorkflowAdmin && (
        <GoToSettingsButton
          label="General settings"
          destination="general"
          className={props.isUpdatedDefinitionAvailable ? 'information' : ''}
          disabled={!props.canChangeGeneralSettings}
          onClick={props.onOpenSettings}
        />
      )}

      {props.isUpdatedDefinitionAvailable && <WarningInformationButton isInformationButton={true} messages={props.informations} />}

      {showHasQuizIcon && (
        <GoToSettingsButton
          label="Quiz"
          destination="quiz"
          tooltip="This document contains a quiz that can be reviewed during the workflow process"
          icon={<MaterialIconListCheckbox style={quizIconStyle} />}
          onClick={props.onOpenSettings}
        />
      )}
    </>
  );
}
