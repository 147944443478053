import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const Approval = ({ entry }: PropsType) => {
  const approvalDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    approvalDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  if (entry.approval?.approverName) {
    approvalDom.push(
      <div key="approval-name">
        <label>Approval Name:</label> <span>{entry.approval.approverName}</span>
      </div>
    );
  }
  if (entry.approval?.approverTitle) {
    approvalDom.push(
      <div key="approval-title">
        <label>Approval Title:</label> <span>{entry.approval.approverTitle}</span>
      </div>
    );
  }
  if (entry.approval?.approvalOrg) {
    approvalDom.push(
      <div key="approval-org">
        <label>Approval Org:</label> <span>{entry.approval.approvalOrg}</span>
      </div>
    );
  }
  if (entry.approval?.approvalDate) {
    approvalDom.push(
      <div key="approval-date">
        <label>Approval Date:</label> <span>{entry.approval.approvalDate}</span>
      </div>
    );
  }
  if (entry.approval?.approvalRefNumber) {
    approvalDom.push(
      <div key="approval-ref">
        <label>Approval Ref Number:</label> <span>{entry.approval.approvalRefNumber}</span>
      </div>
    );
  }
  if (entry.approval?.approvalMediaUid) {
    approvalDom.push(
      <div key="approval-media">
        <label>Approval Media:</label> <span>{entry.approval.approvalMediaUid}</span>
      </div>
    );
  }
  if (entry.approval?.approvalText) {
    approvalDom.push(
      <div key="approval-text">
        <label>Approval Text:</label> <span>{entry.approval.approvalText}</span>
      </div>
    );
  }

  return <div className="settings-details">{approvalDom}</div>;
};
