import * as React from 'react';

const WorkflowTasksPage = React.lazy(() => import('./WorkflowTasksPage'));

function WaitingComponent(Component) {
  return (props) => (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </React.Suspense>
  );
}
const LazyWorkflowTasksPage = WaitingComponent(WorkflowTasksPage) as typeof WorkflowTasksPage;

const WorkflowTasksPageContainer = (props) => {
  return (
    <div className="page">
      <nav className="page-header">
        <div className="logo"></div>
      </nav>
      <div className="page-body row">
        <LazyWorkflowTasksPage {...props} />
      </div>
    </div>
  );
};

export default WorkflowTasksPageContainer;
