import * as Reflux from 'reflux';
import * as _ from 'lodash';
import ActiveUserStore from '../common/ActiveUserStore';
import * as indexClient from '../../clients/index';
import * as mergeClient from '../../clients/index-merge';
import * as unitsClient from '../../clients/units';
import { GetOneOptions } from '../../clients/units';

import Store from '../Store';
import { IIndex, IUnit, IIndexMergeActivity } from 'mm-types';
import Log from '../../utils/Log';
import { Cancelled } from '../../clients/base-clients';

export type MergeRevisionsStoreEvent = Response;

export type State = {
  activities: IIndexMergeActivity[];
  projectUid: null | string;
  masterDraftIndex: null | IIndex;
  mergingInterimIndex: null | IIndex;
  selectedActivity: null | IIndexMergeActivity;
};

export type Response = {
  type: 'startMerge' | 'retrieveActivity' | 'continueMerge' | 'mergeComplete' | 'error';
  state: State;
  snackMessage?: string;
  error?: ErrorTypes;
};

export interface IActions {
  startMerge(projectUid: string, masterDraftIndex: IIndex, callback?: (response: Response) => void);
  finishMerge(callback: () => void);
  continueMerge(projectUid: string, masterDraftIndex: IIndex, callback?: (response: Response) => void);
  retrieveActivity(activityListEntry);
  getDiffedUnit(unitUid: string, data, callback?: (docUnit: IUnit) => void);
  merge(isAccept: boolean);
}

export type ErrorTypes = 'ALREADY_MERGING';

export class MergeRevisionsStore extends Store<State> implements IActions {
  private _isMerging: boolean;

  constructor() {
    super();
    this.state = {
      activities: [],
      projectUid: null,
      masterDraftIndex: null,
      mergingInterimIndex: null,
      selectedActivity: null
    };

    this._isMerging = false;
  }

  getInitialState() {
    return this.state;
  }

  // Event Handlers
  async startMerge(projectUid: string, masterDraftIndex: IIndex, callback?: (response: Response) => void) {
    this.state.masterDraftIndex = masterDraftIndex;
    const user = ActiveUserStore.getUser()!;
    try {
      const interimIndex = await this._fetchIndex(projectUid, masterDraftIndex.interimIndexToMergeUid);
      this.state.mergingInterimIndex = interimIndex instanceof Cancelled ? null : interimIndex;
      this.state.projectUid = projectUid;
      const res = await mergeClient.create(projectUid, masterDraftIndex.uid, user.uid);
      this.state.activities = res;
      const response: Response = { type: 'startMerge', state: this.state };
      if (callback) {
        callback(response);
      }
      this.trigger(response);
    } catch (err) {
      this.state.activities = [];
      const response: Response = { type: 'startMerge', error: 'ALREADY_MERGING', state: this.state };
      if (callback) {
        callback(response);
      }
      this.trigger(response);
    }
  }

  async finishMerge(callback: () => void) {
    try {
      await mergeClient.deleteMerge(this.state.projectUid!, this.state.masterDraftIndex!.uid, ActiveUserStore.getUser()!.uid);
      callback();
    } catch (err) {
      callback();
    }
  }

  async continueMerge(projectUid: string, masterDraftIndex: IIndex, callback?: (response: Response) => void) {
    this.state.masterDraftIndex = masterDraftIndex;
    const interimIndex = await this._fetchIndex(projectUid, masterDraftIndex.interimIndexToMergeUid);
    this.state.mergingInterimIndex = interimIndex instanceof Cancelled ? null : interimIndex;
    this.state.projectUid = projectUid;
    this._fetchMergeActivities('continueMerge', callback);
  }

  async retrieveActivity(activityListEntry) {
    const user = ActiveUserStore.getUser()!;

    try {
      const selectedActivity = await mergeClient.getOne(
        this.state.projectUid!,
        this.state.masterDraftIndex!.uid,
        user.uid,
        activityListEntry.activityUid
      );
      if (selectedActivity instanceof Cancelled) {
        return;
      }
      this.state.selectedActivity = selectedActivity;

      this.state.selectedActivity!.listEntry = activityListEntry!;

      this.trigger({ type: 'retrieveActivity', state: this.state });
    } catch (err) {
      Log.info('Exception when retrieving Activity');
    }
  }

  async merge(isAccept: boolean) {
    if (!this._isMerging) {
      // ensures this.state.activities is uptodate with latest statuses before next merge call

      if (!this.state.selectedActivity) {
        this._fetchMergeActivities('mergeComplete');
        return;
      }

      // this.state.selectedActivity may not be updates on rapid onMerge calls, if its old ignore this request as already done
      const latestActivity = _.find(this.state.activities, { activityUid: this.state.selectedActivity.activityUid })!;

      if (latestActivity.status === 'UNMERGED' || latestActivity.status === 'INAPPLICABLE') {
        this._isMerging = true;

        const user = ActiveUserStore.getUser()!;
        const data = { uid: this.state.selectedActivity.activityUid, isAccept: false, isReject: false };
        if (isAccept) {
          data.isAccept = true;
        } else {
          data.isReject = true;
        }
        try {
          const res = await mergeClient.createActivity(this.state.projectUid!, this.state.masterDraftIndex!.uid, user.uid, data);

          if (!(res instanceof Cancelled)) {
            this.state.activities = this.state.activities.map((activity) =>
              activity.activityUid === res.activityUid ? { ...activity, ...res } : activity
            );
          }
          this.trigger({ type: 'mergeComplete', state: this.state });

          this._fetchMergeActivities('mergeComplete', () => {
            this._isMerging = false;
          });
        } catch (err) {
          this._fetchMergeActivities('mergeComplete', () => {
            this._isMerging = false;
          });

          const event: MergeRevisionsStoreEvent = {
            state: this.state,
            type: 'error',
            snackMessage:
              err.response?.data?.errors?.[0]?.code === 400393
                ? 'Shared content merge conflict - Please Reject this change and refer to the draft'
                : ''
          };

          this.trigger(event);
        }
      } else {
        console.log('Will not merge activity as already merged...');
      }
    } else {
      console.log('Will not merge activity as undergoing merge...');
    }
  }

  async getDiffedUnit(unitUid: string, data: Partial<GetOneOptions>) {
    const unit = await unitsClient.getDocUnit(this.state.projectUid!, this.state.masterDraftIndex!.uid, unitUid, data);
    return unit.unit;
  }

  clear() {
    this.state.selectedActivity = null;
  }

  _fetchIndex(projectUid: string, indexUid: string) {
    return indexClient.get(projectUid, indexUid);
  }

  async _fetchMergeActivities(eventType, callback?: (response: { type: string; state: State }) => void) {
    const user = ActiveUserStore.getUser()!;

    try {
      const res = await mergeClient.getAll(this.state.projectUid!, this.state.masterDraftIndex!.uid, user.uid);
      if (res instanceof Cancelled) {
        return;
      }
      this.state.activities = res;
      const response: MergeRevisionsStoreEvent = { type: eventType, state: this.state };
      if (callback) {
        callback(response);
      }

      this.trigger(response);
    } catch (err) {
      Log.info('Exception when fetching merge activities');
    }
  }
}

const singleton = Reflux.initStore<MergeRevisionsStore>(MergeRevisionsStore);
export default singleton;
