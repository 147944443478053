import { mm, Cancelled } from './base-clients';
import { parse } from './project';
import { IProject, IRole } from 'mm-types';
import moment from 'moment';
import { DocFilter, Page } from '../flux/projects/DocumentsStore';
import axios, { CancelTokenSource } from 'axios';

let getAllSource: CancelTokenSource | null = null;
let getRolesSource: CancelTokenSource | null = null;

export async function bulkUpdate(projects: { uid: string; status: string }[]) {
  const response = await mm.post<{
    projects: Partial<IProject[]>;
    errors?: { [projectUid: string]: { uid: string; message: string } };
  }>(`/projects/bulkupdate`, { projects });
  return response.data;
}

export async function saveCompliance(data: { projectUids?: string[]; workspaceUid?: string }) {
  const response = await mm.post(`/report/compliance`, data);
  return response.data;
}

export async function getRoles(): Promise<IRole[] | Cancelled> {
  if (getRolesSource) {
    getRolesSource.cancel();
  }

  getRolesSource = axios.CancelToken.source();

  try {
    const response = await mm.get<{ roles: IRole[] }>(`/projects/roles`, { cancelToken: getRolesSource.token });
    return response.data.roles;
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export type RetrieveProjectsResponse = {
  projectTypes: string[];
  projects: IProject[];
  page: Page;
};

export async function getAll(options: Partial<DocFilter> = {}): Promise<RetrieveProjectsResponse | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];

  if (options.sort && options.sort.sortBy) {
    query.push('orderBy=' + options.sort!.sortBy);
    query.push('direction=' + options.sort!.sortOrder);
  }

  if (options.page) {
    query.push('pageNumber=' + options.page!.pageNumber);
    query.push('pageSize=' + options.page!.pageSize);
  }

  if (options.title) {
    query.push('title=' + options.title);
  }

  if (options.workspaceUid) {
    query.push('workspaceUid=' + options.workspaceUid);
  }

  if (options.status) {
    query.push('status=' + options.status);
  }

  if (options.hideExteProjectStatus) {
    query.push('hideExteProjectStatus=true');
  }

  if (options.personalSharedWithMe) {
    query.push('personalSharedWithMe=true');
  }

  if (options.projectType && options.projectType !== 'All') {
    query.push('type=' + options.projectType);
  }

  if (options.reference) {
    query.push('reference=' + options.reference);
  }

  if (options.ownerUid && options.ownerUid !== 'All') {
    query.push('ownerUid=' + options.ownerUid);
  }

  if (options.modifiedDate) {
    query.push('modifiedDate=' + moment(options.modifiedDate).toISOString());
  }

  if (options.pendingForApproval) {
    query.push('pendingForApproval=' + options.pendingForApproval.toString());
  }

  if (options.hideExternal) {
    query.push('hideExternal=' + options.hideExternal);
  }

  if (options.firstProjectUid) {
    query.push('firstProjectUid=' + options.firstProjectUid);
  }

  try {
    const response = await mm.get<{
      projects: IProject[];
      projectTypes: string[];
      pageNumber: number;
      pageSize: number;
      numOfPages: number;
      totalElements: number;
    }>(`/projects?${query.join('&')}`, {
      cancelToken: getAllSource.token
    });

    const projectTypes = response.data.projectTypes;
    const projects = response.data.projects;
    for (const project of projects) {
      parse(project);
    }

    return {
      projectTypes,
      projects,
      page: {
        pageNumber: response.data.pageNumber,
        pageSize: response.data.pageSize,
        numOfPages: response.data.numOfPages,
        totalElements: response.data.totalElements
      }
    };
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}

export async function getAllPublished(options: Partial<DocFilter> = {}): Promise<RetrieveProjectsResponse | Cancelled> {
  if (getAllSource) {
    getAllSource.cancel();
  }

  getAllSource = axios.CancelToken.source();

  const query: string[] = [];

  if (options.page) {
    query.push('pageNumber=' + options.page!.pageNumber);
    query.push('pageSize=' + options.page!.pageSize);
  }

  if (options.title) {
    query.push('nameFilter=' + options.title);
  }

  if (options.hideExternal) {
    query.push('hideExternal=' + options.hideExternal);
  }

  try {
    const response = await mm.get<{
      projects: IProject[];
      pageNumber: number;
      pageSize: number;
      numOfPages: number;
      totalElements: number;
    }>(`/projects/published?${query.join('&')}`, {
      cancelToken: getAllSource.token
    });

    const projects = response.data.projects;
    for (const project of projects) {
      parse(project);
    }

    return {
      projects: projects,
      page: {
        pageNumber: response.data.pageNumber,
        pageSize: response.data.pageSize,
        numOfPages: response.data.numOfPages,
        totalElements: response.data.totalElements
      }
    };
  } catch (err) {
    if (axios.isCancel(err)) {
      return new Cancelled();
    }

    throw err;
  }
}
