import * as React from 'react';
import Clipboard, { Clip } from './clipboard';

export function useClipboard() {
  const [currentClipboard, setCurrentClipboard] = React.useState<Clip | null>(null);

  function onClipboardChange(e: StorageEvent) {
    if (e.type === 'storage') {
      setCurrentClipboard(Clipboard.getLastFromClipboard());
    }
  }

  React.useEffect(() => {
    setCurrentClipboard(Clipboard.getLastFromClipboard());
    window.addEventListener('storage', onClipboardChange);
    return () => {
      window.removeEventListener('storage', onClipboardChange);
    };
  }, []);

  return {
    currentClipboard: currentClipboard
  };
}
