import * as _ from 'lodash';
import * as React from 'react';
import ChangeTaskDropdown from '../../../../components/misc/ChangeTaskDropdown';
import EditorStore from '../../../../flux/editor/EditorStore';
import ChangeTasksStore from '../../../../flux/editor/ChangeTasksStore';
import UnitTaskStore from '../../../../flux/editor/UnitTaskStore';
import { IUnit, ITask } from 'mm-types';
import useListenToStore from '../../../hooks/useListenToStore';
import { TagComponent } from '../sub/TagComponent';
import { SvgDropdownIcon } from '../../../misc/icons/SvgDropdownIcon';

type Props = {
  unit: IUnit;
  readOnly: boolean;
};

const parseUnitTaskMapping = (unit: IUnit): ITask[] => {
  const tasks = ChangeTasksStore.getInitialState().tasks;
  const mappedTasksUids = UnitTaskStore.getUnitTasks(unit.uid) as string[];
  const mappedTasks: ITask[] = [];

  mappedTasksUids.forEach((taskUid) => {
    const mappedTask: ITask = tasks.find((t) => t.uid === taskUid)!;
    if (mappedTask) {
      mappedTasks.push(mappedTask);
    }
  });

  return mappedTasks;
};

export const DocUnitTaskTagMenu = ({ unit, readOnly = false }: Props) => {
  const [taskTags, setTaskTags] = React.useState<ITask[]>(parseUnitTaskMapping(unit));
  const [tagsVisible, setTagsVisible] = React.useState<boolean>(true);
  const dropDownActivateBtn = React.useRef<SVGSVGElement>(null);
  const taskTagsDropdown = React.useRef<ChangeTaskDropdown>(null);

  const onStoreChange = () => {
    setTaskTags(parseUnitTaskMapping(unit));
  };

  useListenToStore({ store: ChangeTasksStore, eventListener: onStoreChange });
  useListenToStore({ store: UnitTaskStore, eventListener: onStoreChange });

  React.useEffect(() => {
    if (!readOnly && !tagsVisible && taskTagsDropdown.current && dropDownActivateBtn.current) {
      taskTagsDropdown.current.open(dropDownActivateBtn.current);
    }
  }, [readOnly, tagsVisible]);

  const openDropdown = () => {
    if (!readOnly) {
      // hide visible tags so don't appear behind overlay, but also this is for performance, i.e.
      // don't ever render this popup component unnecessarily
      setTagsVisible(false);
    }
  };

  const handleDropDownClose = async (checkedAssignments: string[]) => {
    const previousAssignment = UnitTaskStore.getInitialState().unitTaskMap ? UnitTaskStore.getInitialState().unitTaskMap[unit.uid] : [];
    const newAssignments = _.difference(checkedAssignments, previousAssignment);
    const removedAssignment = _.difference(previousAssignment, checkedAssignments);
    const indexUid = EditorStore.getDocParams().indexUid!;

    if (newAssignments.length > 0) {
      await UnitTaskStore.assignTasksToUnit(newAssignments, unit, indexUid, removedAssignment.length > 0 ? { silent: true } : {});

      // important, if adding and deleting in same action, ensure add done first before deleting
      // TODO rework into single state set, no need for separate add and remove
      if (removedAssignment.length > 0) {
        await UnitTaskStore.removeTasksFromUnit(removedAssignment, unit, indexUid);
      }
    } else if (removedAssignment.length > 0) {
      await UnitTaskStore.removeTasksFromUnit(removedAssignment, unit, indexUid);
    }

    setTagsVisible(true);
  };

  const taskTagLength = taskTags.reduce((previousValue, currentValue) => {
    return previousValue + (!currentValue ? 0 : 1);
  }, 0);

  if (!ChangeTasksStore.getInitialState().tasks.length || (readOnly && (!readOnly || !taskTagLength))) {
    return null;
  }

  return (
    <div className="du-inline-item du-inline-dropdown du-inline-changetasks" onClick={(e) => openDropdown()}>
      <div className="du-inline-changetasks-tasklist" style={{ display: tagsVisible ? 'block' : 'none' }}>
        {taskTagLength > 0 && taskTags.map((task, index) => <TagComponent key={index} task={task} />)}
        {taskTagLength <= 0 && <span className="no-tags-title">Task Tag</span>}
      </div>
      <SvgDropdownIcon ref={dropDownActivateBtn} hidden={readOnly} />
      {!tagsVisible && (
        <ChangeTaskDropdown assignedTasks={taskTags} onClose={(assignments) => handleDropDownClose(assignments)} ref={taskTagsDropdown} />
      )}
    </div>
  );
};
