import * as React from 'react';
import SmartContentStore, { SmartContentStoreEvent } from '../../../../../flux/editor/SmartContentStore';
import UnitSpecialInterestTagStore, { UnitSpecialInterestTagStoreEvent } from '../../../../../flux/editor/UnitSpecialInterestTagStore';
import ComplianceTagUsages from './ComplianceTagUsages';
import { ISpecialInterestGroup, IComplianceTag } from 'mm-types';

export type Props = {
  selected: boolean;
  specialInterestTag: Partial<ISpecialInterestGroup>;
  open: boolean;
  onSelect: (uid: string) => void;
};

export type State = {
  usages: Partial<IComplianceTag>[];
};

export default class InterestItem extends React.Component<Props, State> {
  private smartContentUnsub: Function;
  private specialInterestUnsub: Function;

  static defaultProps: Partial<Props> = {
    specialInterestTag: SmartContentStore.getEmptySpecialInterest(),
    selected: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      usages: []
    };

    this.smartContentUnsub = SmartContentStore.listen(this._onStoreChange, this);
    this.specialInterestUnsub = UnitSpecialInterestTagStore.listen(this._onStoreChange, this);
  }

  componentWillUnmount() {
    this.smartContentUnsub();
    this.specialInterestUnsub();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.props.selected && nextProps.selected) {
      this._retrieveUsages(nextProps);
    }
  }

  _onStoreChange(event: SmartContentStoreEvent | UnitSpecialInterestTagStoreEvent) {
    if (event.type === 'retrieveUnitSpecialInterestTagMap') {
      this._retrieveUsages(this.props);
    }
  }

  _retrieveUsages(props: Props) {
    if (props.selected && !props.open) {
      this.setState({ usages: UnitSpecialInterestTagStore.getSpecialInterestTagUnits(props.specialInterestTag!.uid!) });
    }
  }

  _select(force?: boolean) {
    if (!this.props.open || force) {
      this.props.onSelect(this.props.specialInterestTag!.uid!);
    }
  }

  _getClassNames() {
    return 'smart-content-item ' + (this.props.selected ? 'selected' : '');
  }

  _getSpecialInterestTagName() {
    return this.props.specialInterestTag!.name!;
  }

  render() {
    return (
      <li
        id={'itemuid_' + this.props.specialInterestTag!.uid!}
        className={this._getClassNames()}
        onClick={() => {
          this._select();
        }}
      >
        <div className="smart-content-header">
          <div className="form-container">
            <div className="textfieldro name">{this._getSpecialInterestTagName()}</div>
          </div>
        </div>

        <div>
          <div className="icon-container elements icon-display-options tiny material-icons">people</div>
          <div className="info">Special interest</div>
        </div>

        {this.props.selected ? (
          <ComplianceTagUsages unit={this.props.specialInterestTag} usages={this.state.usages} isReadOnly={true} />
        ) : null}
      </li>
    );
  }
}
