import * as Reflux from 'reflux';
import * as _ from 'lodash';
import IndexEventStore, { IndexEventStoreEvent } from './IndexEventStore';
import UserEventStore, { UserEventStoreEvent } from './UserEventStore';
import * as client from '../../clients/users';
import * as notificationsClient from '../../clients/notifications';
import Log from '../../utils/Log';
import Store from '../Store';
import ActiveUserStore from '../common/ActiveUserStore';
import { INotification, IUser } from 'mm-types';
import { NotificationResponse } from '../../clients/notifications';
import { Cancelled } from '../../clients/base-clients';

type Info = {
  totalUnseenCount: number;
  latestResponseLength: number;
  containsInProgress: boolean;
  containsInError: boolean;
};

const _INPROGRESS_RETRY_FALLBACK = 30000; // 30 seconds

export type NotificationInfo = {
  totalUnseenCount: number;
  latestResponseLength: number;
  containsInProgress: boolean;
  containsInError: boolean;
};

export type NotificationsStoreEvent = State & { type?: 'START_NOTIF_PROGRESS' };

export type State = {
  notifications: INotification[];
  info: NotificationInfo;
};

export class NotificationsStore extends Store<State> {
  private _isUndergoingProgressRetry: boolean;
  private _currentUserUid: null | string;
  private nextCursor: string;
  public info: Info;

  constructor() {
    super();

    this._isUndergoingProgressRetry = false;
    this._currentUserUid = null;

    this.state = {
      notifications: [],
      info: {
        totalUnseenCount: 0,
        latestResponseLength: 0,
        containsInProgress: false,
        containsInError: false
      }
    };

    this.listenTo(IndexEventStore as any, (e: IndexEventStoreEvent) => {
      if (e.activity === 'published' || e.activity === 'publishCompleted') {
        this.debouncedRetrieveNotifications();
      }
    });

    this.listenTo(UserEventStore as any, (e: UserEventStoreEvent) => {
      this.debouncedRetrieveNotifications('user');
    });
  }

  debouncedRetrieveNotifications = _.debounce(this._retrieveNotifs, 150);

  getInitialState() {
    return this.state;
  }

  // Event Handlers

  init(user: IUser) {
    this._currentUserUid = user.uid;
  }

  // Opens popup to show progress
  startNotifProgress() {
    // trigger based on clone as temporary condition
    const clonedState = { ...this.state };
    clonedState.info.containsInProgress = true;
    this.trigger({ ...(clonedState as NotificationsStoreEvent), type: 'START_NOTIF_PROGRESS' });
  }

  retrieveNotifs() {
    return this._retrieveNotifs();
  }

  async deleteNotif(uid: string) {
    try {
      await notificationsClient.remove(uid);
      this.state.notifications.forEach((notification) => notification.uid !== uid);
      this.state.info = { ...this.state.info };
      this.trigger(this.state as NotificationsStoreEvent);
    } catch (err) {
      this._retrieveNotifs();
    }
  }

  // TODO this is a quickie: should really be a generic updateNotification action...
  async setNotifState(notification: Partial<INotification>) {
    try {
      const notif = this.state.notifications.find((currentNotification) => currentNotification.uid === notification.uid);
      const originalNotif: INotification | undefined = notif;

      if (originalNotif) {
        originalNotif.status = notification.status!;
      }

      await notificationsClient.edit(notification.uid!, notification);
    } catch (err) {
      this._retrieveNotifs();
    }
  }

  async setAsRead(notifs?: INotification[]) {
    try {
      await notificationsClient.setAsRead(notifs);
      this._retrieveNotifs();
    } catch {
      this._retrieveNotifs();
    }
  }

  async clearNew() {
    if (this.state.notifications.length && this.state.info.totalUnseenCount > 0) {
      const token = { lastSeenNotificationTimestamp: this.state.notifications[0].modified };
      const uid = ActiveUserStore.getUser()!.uid;

      try {
        await client.setSettings(uid, token);
        this.state.info.totalUnseenCount = 0;
        this.state.info.containsInError = false; // always clear this: its tied to isNew (i.e. only show when there are NEW isError notifs)
        this.state.notifications.forEach((notification) => (notification.isNew = false));
        this.trigger(this.state as NotificationsStoreEvent);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async retryNotif(notifUid: string) {
    const xhr = notificationsClient.retry(notifUid);
    await xhr;
  }

  async nextNotifs() {
    const response = await notificationsClient.getNextSet(this._currentUserUid!, this.nextCursor);
    this.state.notifications = [...this.state.notifications, ...response.notifications];
    this.nextCursor = response.nextCursor;
    this.trigger(this.state as NotificationsStoreEvent);
  }

  // an index or user websocket event occurred: refresh notifs list
  async _retrieveNotifs(type?: string) {
    if (this._currentUserUid) {
      const response = await notificationsClient.getAll(this._currentUserUid);

      if (response instanceof Cancelled) {
        return;
      } else {
        this.state.info = { ...(response as NotificationResponse).info };
        this.nextCursor = response.nextCursor;
        const currentNotifs = this.state.notifications;
        this.state.notifications = (response as NotificationResponse).notifications;
        const notifsAreRead = this.state.notifications.every((notif) => notif.status === 'READ');

        if (currentNotifs.length && !notifsAreRead && type === 'user') {
          // combine new notifs and remove duplicate
          this.state.notifications.concat(currentNotifs.filter((el) => this.state.notifications.indexOf(el) === -1));
        }
      }
      // something is in progress: launch a timer to reload notifs in 5 mins
      if (this.state.info.containsInProgress && !this._isUndergoingProgressRetry) {
        this.state.info.containsInProgress = true;
        this._isUndergoingProgressRetry = true;
        setTimeout(() => {
          if (this._isUndergoingProgressRetry) {
            // if still undergoing
            this._isUndergoingProgressRetry = false;
            Log.info('NotificationsStore: attempt to load notifs due to something in progress for a while');
            this._retrieveNotifs();
          }
        }, _INPROGRESS_RETRY_FALLBACK);
      } else if (!this.state.info.containsInProgress) {
        this._isUndergoingProgressRetry = false;
        Log.info('NotificationsStore: nothing in progress... stop any future reload attempt...');
      }
      this.trigger(this.state as NotificationsStoreEvent);

      return this.state.notifications;
    }
  }

  getNotification(uid: string): INotification | undefined {
    return this.state.notifications.find((notif) => notif.uid === uid);
  }
}

const singleton = Reflux.initStore<NotificationsStore>(NotificationsStore);
export default singleton;
