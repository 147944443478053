// State
import { SystemAlertActionTypes } from './types';
import { HIDE_SYSTEM_ALERT, SHOW_SYSTEM_ALERT } from './actions';

export type AlertPayload = {
  errorTitle: null | string;
  errorMessage: null | string | JSX.Element;
  errorActions?: JSX.Element[];
  userMessage?: string;
  errorMessageDetails?: null | string | JSX.Element;
  onClose?: () => void;
};

export type AlertState = {
  payload: AlertPayload | null;
  title?: string;
  content?: string | string[];
  open: boolean;
};

export const initialState: AlertState = {
  open: false,
  payload: null
};

export function systemAlertReducer(state: AlertState = initialState, action: SystemAlertActionTypes): AlertState {
  switch (action.type) {
    case SHOW_SYSTEM_ALERT:
      return {
        ...state,
        open: true,
        payload: action.payload
      };
    case HIDE_SYSTEM_ALERT:
      return {
        ...state,
        open: false,
        payload: null
      };
    default:
      return { ...state };
  }
}

export default systemAlertReducer;
