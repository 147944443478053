import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SelectedUnitProperties from './selectedComponent/SelectedUnitProperties';
import EditorStore from '../../../../flux/editor/EditorStore';
import { EventStoreEventType, IEditorStoreEvent, IUnit, IUnitDetails } from 'mm-types';
import Scroller from '../../../../utils/Scroller';
import useListenToStore from '../../../hooks/useListenToStore';
import EditingUnitProperties from './editComponent/EditUnitProperties';
import { IGenericEditProps } from './editComponent/components/GenericEditProps';

export interface IEditProps extends IGenericEditProps {
  key: number;
  disabled?: boolean;
  displayPrintOutput?: boolean;
}

const Edit = () => {
  const editRef = React.createRef<HTMLDivElement>();

  const [isEditorFocused, setIsEditorFocused] = React.useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = React.useState<null | IUnit>(null);
  const [nestedTreeUnitInfo, setNestedTreeUnitInfo] = React.useState<IUnitDetails[]>([]);
  const [editingUnitsLength, setEditingUnitsLength] = React.useState<number>(0);

  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });

  React.useEffect(() => {
    setSelectedUnit(EditorStore.getSelectedUnit());
    setEditingState(EditorStore.isEditorFocused());
  }, []);

  React.useEffect(() => {
    const editContainer = editRef.current ? (ReactDOM.findDOMNode(editRef.current) as Element) : null;
    const unitInfo = nestedTreeUnitInfo;
    const unitInfoLength = unitInfo.length;

    if (editContainer) {
      const tableAncestorsLength = unitInfo.filter((treeInfo: IUnitDetails) => {
        return !!treeInfo.profile && treeInfo.profile.type === 'table';
      }).length;
      if (unitInfoLength <= 1) {
        editContainer.scrollTop = 0;
      } else {
        const $editContainerEl = $(editContainer),
          $propComponentEl = $('#unitProps_' + (unitInfoLength - 1))!;
        if ($propComponentEl.length && !$propComponentEl.hasClass('disabled') && tableAncestorsLength === 0) {
          editContainer.scrollTop = $propComponentEl.offset()!.top - $editContainerEl.offset()!.top + $editContainerEl.scrollTop()!;
        }
      }
    }
  }, [nestedTreeUnitInfo]);

  // sets state and applies appropriate properties component for rendering
  function setEditingState(isEditorFocused: boolean | null) {
    if (isEditorFocused) {
      setNestedTreeUnitInfo(EditorStore.getFocusedTreeUnits());
    } else {
      setNestedTreeUnitInfo([]);
    }
    setIsEditorFocused(!!isEditorFocused);
  }

  function onToggle(isOpen: boolean, ref: React.RefObject<HTMLDivElement>) {
    if (isOpen && editRef.current && ref.current) {
      Scroller.scrollToElement(editRef.current, ref.current, 300).catch((e) => console.warn(e));
    }
  }

  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (!e) {
      return;
    }

    if (e.type === 'editFocus') {
      setEditingState(true);
    } else if (e.type === 'editBlur') {
      setEditingState(false);
    } else if (e.type === 'unitsSelected') {
      const castEvent = e as IEditorStoreEvent<'unitsSelected'>;
      let selectedUnit = castEvent.data!.initialSelectedUnit;

      if (selectedUnit && ['frontmatter', 'removed'].indexOf(selectedUnit.type) !== -1) {
        selectedUnit = null;
      }

      setEditingState(EditorStore.isEditorFocused());
      setSelectedUnit(selectedUnit);
    } else if (e.type === 'nestedUnitFocusChange') {
      setEditingState(true); // ensure to maintain focused state
    } else if (e.type === 'editTextSelected') {
      setEditingState(true);
    } else if (e.type === 'unitDomChange') {
      const event = e as IEditorStoreEvent<'unitDomChange'>;
      const tinyFacade = EditorStore.getEditor().getActiveEditorFacade()!;
      tinyFacade.removeRowspanTDHeight(event);
      EditorStore.updateTableCellsDimensions(event);
    }
  }

  return (
    <div className={`edit-subaction ${editingUnitsLength > 0 ? 'edit-unit-props-container edit-properties ' : ''}`} ref={editRef}>
      {isEditorFocused && nestedTreeUnitInfo.length && (
        <EditingUnitProperties
          nestedTreeUnitInfo={nestedTreeUnitInfo}
          selectedUnit={selectedUnit}
          onToggle={onToggle}
          setEditingUnitsLength={setEditingUnitsLength}
        />
      )}
      {!isEditorFocused && selectedUnit && <SelectedUnitProperties />}
    </div>
  );
};

export default Edit;
