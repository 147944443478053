import * as React from 'react';

interface Props {
  isInsertableAfter?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
}

export const ActionsMenu = ({ isInsertableAfter, isEditable, isDeletable }: Props) => {
  if (!isInsertableAfter && !isEditable && !isDeletable) {
    return null;
  }
  return (
    <ul className="du-inline-item du-inline-actions">
      {isEditable && (
        <li>
          <i data-action="copy-docunit" className="material-icons">
            content_copy
          </i>
        </li>
      )}
      {isEditable && isDeletable && (
        <li>
          <i data-action="cut-docunit" className="material-icons">
            content_cut
          </i>
        </li>
      )}
      {isDeletable && (
        <li id="deleteUnitFromEditor">
          <i data-action="delete-docunit" className="material-icons">
            delete
          </i>
        </li>
      )}
    </ul>
  );
};
