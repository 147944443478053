import * as React from 'react';
import { Toggle } from 'material-ui';

type MetaDataSwitchProps = { value: boolean; title: string; isDisabled?: boolean };

const MetaDataSwitch = ({ value, title, isDisabled }: MetaDataSwitchProps) => {
  return (
    <div className="dimension column" key={'arc-toggle_' + title}>
      <Toggle disabled={isDisabled} key={title} toggled={value} label={title} />
    </div>
  );
};

export default MetaDataSwitch;
