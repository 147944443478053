/* TYPES */
import { IInsertMenuShortCutItems } from 'mm-types';
import ProjectDefinitionStore from '../../../../../flux/common/ProjectDefinitionStore';
import { WithSelectedUnitProps, WithSelectedUnitState } from '../../../../hoc/withSelectedUnit';

const getDefaultShortcutIcons = ({
  isSelected,
  isSelectedNotEditing
}: Partial<WithSelectedUnitProps & WithSelectedUnitState>): IInsertMenuShortCutItems => {
  const shortCuts = [
    {
      id: 'paragraph',
      action: 'Paragraph',
      definitionId: { unit: 'paragraph', element: 'Paragraph' },
      doesMapToUnitType: true,
      tooltip: 'Insert Paragraph',
      icon: 'local_parking',
      enabled: !!isSelected
    },
    {
      id: 'list-unordered',
      action: 'unordered-list',
      definitionId: { unit: 'unordered-list', element: 'UnorderedList' },
      doesMapToUnitType: true,
      tooltip: 'Insert Bullet List',
      icon: 'format_list_bulleted',
      enabled: !!isSelected
    },
    {
      id: 'list-ordered',
      action: 'ordered-list',
      definitionId: { unit: 'ordered-list', element: 'OrderedList' },
      doesMapToUnitType: true,
      tooltip: 'Insert Numbered List',
      icon: 'format_list_numbered',
      enabled: !!isSelected
    },
    {
      id: 'table',
      action: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() ? 'tableCollection' : 'table',
      definitionId: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()
        ? { unit: 'tableCollection', element: 'TableCollection' }
        : { unit: 'table', element: 'Table' },
      doesMapToUnitType: true,
      tooltip: 'Insert Table',
      icon: 'border_all',
      enabled: !!isSelected
    },
    {
      id: 'graphic',
      action: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() ? 'graphref' : 'graphic',
      definitionId: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()
        ? { unit: 'graphRef', element: 'GraphRef' }
        : { unit: 'graphic', element: 'Graphic' },
      doesMapToUnitType: true,
      tooltip: 'Media Library',
      icon: 'collections',
      enabled: !!isSelected,
      hidden: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus()
    },
    {
      id: 'powerpaste',
      action: 'powerpaste',
      doesMapToUnitType: false,
      tooltip: 'Insert from external source',
      icon: 'input',
      enabled: ProjectDefinitionStore.isCurrentProjectDefinitionAirbus() ? false : !!isSelectedNotEditing
    }
  ].filter((shortCut) => !shortCut.hidden);

  const specialActions = [{ action: 'SpecialCharacter', tooltip: 'Insert special character', icon: null, enabled: false, visible: true }];

  const linkActions = [{ action: 'insertLink', tooltip: 'Link', icon: 'link', enabled: false, visible: !!isSelected }];

  return {
    shortCuts,
    specialActions,
    linkActions
  };
};

export const getShortcutIconsWithRulesApplied = (
  {
    isActivelyEditing,
    isSelected,
    isSelectedNotEditing,
    editingDUProfile,
    editingNestedChange
  }: Partial<WithSelectedUnitProps & WithSelectedUnitState>,
  rules,
  isElementInsertable
): IInsertMenuShortCutItems => {
  const menuContents: IInsertMenuShortCutItems = getDefaultShortcutIcons({ isSelected, isSelectedNotEditing });

  if (editingDUProfile) {
    menuContents.shortCuts.forEach((shortCut) => {
      if (shortCut.doesMapToUnitType) {
        const { disabled } = isElementInsertable(shortCut.definitionId!.element, 0);
        shortCut.enabled = !disabled;
        shortCut.action = 'el_' + shortCut.definitionId!.element;
      }
    });

    // always prioritise the nested profile selected
    let profile = editingDUProfile;

    if (editingNestedChange && editingNestedChange.focused) {
      profile = editingNestedChange.focused.profile;
    }

    if (profile && profile.menu && profile.menu.specialActions && profile.menu.specialActions[0]) {
      menuContents.specialActions[0].enabled = profile.menu.specialActions[0].enabled;
    }
  } else {
    menuContents.shortCuts.forEach((shortCut) => {
      if (shortCut.doesMapToUnitType) {
        shortCut.enabled = rules.selectedUnitCanBeFollowedByThis(shortCut.definitionId!.unit);
      }
    });
  }
  return menuContents;
};
