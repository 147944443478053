import * as React from 'react';
import { useSelector } from 'react-redux';
import EditorModes from '../../../../flux/editor/EditorModes';
import { PostUnitRenderActionType } from '../../../../flux/editor/PostUnitRenderActions';
import IconButton from 'material-ui/IconButton';
import Edit from './Edit';
import EditorStore from '../../../../flux/editor/EditorStore';
import CommentStore from '../../../../flux/editor/CommentStore';
import SystemStore from '../../../../flux/common/SystemStore';
import UserEventStore, { UserEventStoreEvent } from '../../../../flux/events/UserEventStore';
import CommentStatusStore, { CommentStatusStoreEvent } from '../../../../flux/editor/CommentStatusStore';
import Activity from './activitylog/Activity';
import Comments from './comments/Comments';
import FacetedTags from './facetedTags/facetedTags';
import Attachments from './attachments/Attachments';
import SpellChecker from './spellchecker/SpellChecker';
import SmartContent from './sharedcontent/SmartContent';
import ResizeHandle from '../ResizeHandle';
import { CircularProgress } from 'material-ui';
import { EventStoreEventType, IDocUnitProfile, IEditorStoreEvent, IIndex, IProject } from 'mm-types';
import { isFeatureOn } from '../../../featureSwitch/featureSwitchUtils';
import { AlertState } from '../../../misc/SystemAlert/reducers';
import { useProjectSettings } from '../../settings/useProjectSettings';
import { AppState } from '../../../../appStore';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import EffectivitiesContainer from './effectivities/EffectivitiesContainer';
import useListenToStore from '../../../hooks/useListenToStore';

const _MIN_CONTAINER_SIZE = 320;
const _MAX_CONTAINER_SIZE = 600;
let _subActionsContainerEl: HTMLElement | null = null;

export type SubActionContainerTab = {
  tabId?: string;
  tab: string;
  tooltip?: string;
  icon?: string;
  componentClass?: string;
  isActive?: (activeTab) => boolean;
  createComponent: (options?: CreateOptions) => JSX.Element;
  isVirtualTab?: boolean;
};

export type CreateOptions = {
  attachmentId?: string;
};

export type Props = {
  project?: IProject;
  index?: IIndex;
};

function SubActionsContainer(props: Props) {
  const [tabs, setTabs] = React.useState<SubActionContainerTab[]>([]);
  const [activeTab, setActiveTab] = React.useState<string>('edit');
  const [attachmentUid, setAttachmentUid] = React.useState<string>('');
  const [viewState, setViewState] = React.useState<string>('opened');
  const [unreadCommentsCount, setUnreadCommentsCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [settingsIndexUid, setSettingsIndexUid] = React.useState<string | undefined>();

  const { settings } = useProjectSettings({ uid: props.project?.uid, masterIndexUid: props.index?.uid });
  const systemAlert = useSelector<AppState, AlertState>((state) => state.systemAlert);

  const faceted = React.useRef(null);
  const callbackRef = React.useRef<() => void | undefined>();

  useListenToStore({ store: UserEventStore, eventListener: onUserEvent });
  useListenToStore({ store: EditorStore, eventListener: onEditStoreUpdate });
  useListenToStore({ store: CommentStore, eventListener: onCommentStoreUpdate });
  useListenToStore({ store: CommentStatusStore, eventListener: onCommentStatusStoreUpdate });

  React.useEffect(() => {
    // revert to *real* DOM for this
    _subActionsContainerEl = document.querySelector('.editing-subactions-container') as HTMLElement;
    setSettingsIndexUid(props.project?.masterIndexUid);
    generateTabs();
  }, []);

  React.useEffect(() => {
    if (!!settings && settingsIndexUid !== settings?.selected.masterIndexUid) {
      setSettingsIndexUid(settings?.selected.masterIndexUid);
      generateTabs();
    }
  }, [settings]);

  // A bit an upside down approach to invoking a callback on the setState
  React.useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = undefined;
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (props.project?.uid && props.project?.masterIndexUid && !SystemStore.isBackendDisconnected() && !systemAlert.open) {
      CommentStatusStore.listAllUnreadComments(EditorStore.getDocParams().projectUid!, EditorStore.getDocParams().indexUid!);
    }
  }, [props.project]);

  function onCommentStoreUpdate(e) {
    if (e.type === 'commentIntent') {
      if (activeTab !== 'comment') {
        handleTab('comment', () => {
          CommentStore.commentIntent(e.unit);
        });
      }
    }
  }

  function onUserEvent(e: UserEventStoreEvent) {
    if (e && e.type === 'COMMENT') {
      CommentStatusStore.listAllUnreadComments(EditorStore.getDocParams().projectUid!, EditorStore.getDocParams().indexUid!);
    }
  }

  function onCommentStatusStoreUpdate(e: CommentStatusStoreEvent) {
    if (e.type === 'unread-comments-retrieved') {
      setUnreadCommentsCount(e.state.unreadCommentsCount!);
    }
  }

  // originally these were set thru' props: now by ref for performance as reduces rendering load on parent component
  // TODO important: all child components should listen to events as below and not be passed data thru' this component
  // this will improve performance big time and needs to be refactored for existing components first ideally
  function onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'initEditor') {
      setLoading(false);
      // on editor init, must drop any previous mode display
      if (activeTab === 'spellCheck') {
        handleTab('edit');
      }
    } else if (e.type === 'editor-modals-closed') {
      setLoading(false);
    } else if (e.type === 'postUnitRender') {
      const castEvent = e as IEditorStoreEvent<'postUnitRender'>;
      const query: {
        action?: PostUnitRenderActionType;
        commentUid?: string;
      } = castEvent.data! || {};

      if (query.action === 'OPEN_COMMENT' || query.action === 'OPEN_SHARE') {
        EditorStore.execWhenReady(() => {
          handleTab(query.action === 'OPEN_COMMENT' ? 'comment' : 'shared', () => {
            CommentStore.commentUid(query.commentUid!);
          });
        });
      }
    } else if (e.type === 'changeModeComplete') {
      const castEvent = e as IEditorStoreEvent<'changeModeComplete'>;
      const modeDefaultTab = EditorModes.getProperties(castEvent.data!.to!).getActivatedMenuTabSelections().sub;
      if (modeDefaultTab) {
        handleTab(modeDefaultTab, undefined, true);
      } else {
        const deactivatedModeTab = EditorModes.getProperties(castEvent.data!.from!).getDeactivatedMenuTabSelections().sub;
        if (deactivatedModeTab) {
          handleTab(deactivatedModeTab, undefined, true);
        }
      }
    } else if (e.type === 'editFocus') {
      if (
        activeTab !== 'edit' &&
        ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(e.unit!.definitionId)
          ?.openEditPaneOnSelect
      ) {
        handleTab('edit');
      }
    } else if (e.type === 'nestedUnitFocusChange') {
      const castEvent = e as IEditorStoreEvent<'nestedUnitFocusChange'>;
      if (
        activeTab !== 'edit' &&
        castEvent.data!.focused &&
        castEvent.data!.focused.profile &&
        (castEvent.data!.focused.profile as IDocUnitProfile).openEditPaneOnSelect
      ) {
        handleTab('edit');
      }
    } else if (e.type === 'editTextSelected') {
      const castEvent = e as IEditorStoreEvent<'editTextSelected'>;
      const unit = castEvent.data!.unit;
      if (unit.definitionId === 'table' && EditorStore.getEditor().getActiveEditorFacade()!.getTableCellsSelectedCount() > 1) {
        const unitProfile = ProjectDefinitionStore.projectDefinitionDocUnitEditProfiles().getUnitProfileByDefinitionId(unit.definitionId);
        if (unitProfile?.openEditPaneOnSelect && activeTab !== 'edit') {
          handleTab('edit');
        }
      }
    }
  }

  function handleTab(tabId: string, onTabbed?: () => void, force = false) {
    if (EditorStore.doesModeAllow(EditorModes.attributes.submenuTabs) || force) {
      if (tabId === 'shared' && !EditorStore.doesModeAllow(EditorModes.attributes.shareTab)) {
        return;
      }

      let newTab = tabId;

      if (EditorStore.isMode('SPELLCHECK')) {
        newTab = 'spellCheck';
      } else if (tabId === 'spellCheck') {
        newTab = 'edit';
      }
      callbackRef.current = onTabbed;
      setActiveTab(newTab);
    }
  }

  function onAttachmentClick(uid: string) {
    handleTab('attachment');
    setAttachmentUid(uid);
    generateTabs();
  }

  function toggleSidebar() {
    setViewState(viewState === 'opened' ? 'closed' : 'opened');
  }

  function refocusEditor(e: React.MouseEvent<HTMLElement>, type: string) {
    const editor = EditorStore.getEditor();
    if (editor.isFocused()) {
      if (type === 'click') {
        e.stopPropagation();
        e.preventDefault();
      }

      if (
        !/input/i.test((e.target as HTMLDivElement).tagName) &&
        !/textarea/i.test((e.target as HTMLDivElement).tagName) &&
        !/force-focus/i.test((e.target as HTMLDivElement).className)
      ) {
        if (type === 'mousedown') {
          // only stop mousedowns if not in an input, as input won't be focusable otherwise!
          e.stopPropagation();
          e.preventDefault();
        }

        editor.silentReFocus();
      }
    }
  }

  function generateTabs() {
    const tabs: SubActionContainerTab[] = [];

    tabs.push({
      tabId: 'createTab',
      tab: 'edit',
      tooltip: 'Edit Properties',
      icon: 'create',
      isActive: (activeTab) => activeTab === 'edit' || activeTab === 'spellCheck',
      createComponent: () => <Edit />
    });
    tabs.push({
      tabId: 'commentsTab',
      tab: 'comment',
      tooltip: 'Comment',
      icon: 'insert_comment',
      isActive: (activeTab) => activeTab === 'comment',
      createComponent: () => (
        <Comments
          onAttachmentClick={(uid: string) => {
            onAttachmentClick(uid);
          }}
        />
      )
    });
    if (ProjectDefinitionStore.getCurrentProjectDefinition()?.indexDefinition?.showEffectivitiesPanel) {
      tabs.push({
        tabId: 'effectivitiesTab',
        tab: 'effectivities',
        tooltip: 'Effectivities',
        icon: 'flight',
        isActive: (activeTab) => activeTab === 'effectivities',
        createComponent: () => <EffectivitiesContainer />
      });
    }
    if (settings?.allowAttachments) {
      tabs.push({
        tabId: 'attachmentsTab',
        tab: 'attachment',
        tooltip: 'Attachment',
        icon: 'attach_file',
        isActive: (activeTab) => activeTab === 'attachment',
        createComponent: () => <Attachments attachmentJumpId={attachmentUid} />
      });
    }

    if (isFeatureOn('searchTags') && settings?.metadataAllowTagFamilies) {
      tabs.push({
        tabId: 'facetedTab',
        tab: 'faceted',
        tooltip: 'Tags',
        icon: 'label_outline',
        isActive: (activeTab) => activeTab === 'faceted',
        createComponent: () => <FacetedTags ref={faceted} />
      });
    }

    tabs.push({
      tabId: 'activityLogTab',
      tab: 'activity',
      tooltip: 'Activity Log',
      icon: 'av_timer',
      isActive: (activeTab) => activeTab === 'activity',
      createComponent: () => <Activity />
    });
    if (settings?.allowSharedContent) {
      tabs.push({
        tabId: 'sharedContentTab',
        tab: 'shared',
        tooltip: 'Smart Content',
        icon: 'share',
        isActive: (activeTab) => activeTab === 'shared',
        createComponent: () => <SmartContent />
      });
    }
    tabs.push({
      // this "tab" isVirtualTab, i.e. it relies on edit to render itself
      tab: 'spellCheck',
      isVirtualTab: true,
      componentClass: 'spellcheck',
      createComponent: () => <SpellChecker />
    });

    setTabs(tabs);
  }

  return (
    <div
      className={'col s2 side-container editing-subactions-container ' + viewState}
      onMouseDown={(e) => refocusEditor(e, 'mousedown')}
      onClick={(e) => refocusEditor(e, 'click')}
    >
      <ResizeHandle
        name="rhs"
        containerEl={_subActionsContainerEl!}
        isContainerOpened={viewState === 'opened'}
        minContainerSize={_MIN_CONTAINER_SIZE}
        maxContainerSize={_MAX_CONTAINER_SIZE}
        isLeftAttached={false}
      />
      {loading ? (
        <div className="editing-subactions-loading">
          <CircularProgress thickness={2.5} size={20} />
        </div>
      ) : (
        <div className="row editing-subactions-tabs">
          <div className="col s12">
            <ul className="tabs icon-action-buttons ieTooltip">
              {tabs.map(
                (tab) =>
                  !tab.isVirtualTab && (
                    <li
                      id={tab.tabId}
                      key={tab.tab}
                      className={'tab col s2 ' + (tab.isActive!(activeTab) ? ' active' : '')}
                      onClick={() => handleTab(tab.tab)}
                    >
                      <IconButton
                        iconClassName="material-icons"
                        tooltipPosition={'top-center'}
                        tooltip={tab.tooltip}
                        tooltipStyles={{ zIndex: 9999, top: '0', pointerEvents: 'none' }}
                      >
                        {tab.icon}
                      </IconButton>
                      {tab.tabId === 'commentsTab' && (
                        <div className={'popup-counts-comments' + (unreadCommentsCount === 0 ? ' no-new' : '')}>{unreadCommentsCount}</div>
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
          {tabs.map(
            (tab) =>
              tab.tab === activeTab && (
                <div className={'col s12 side-container-content' + (tab.componentClass ? ' ' + tab.componentClass : '')} key={tab.tab}>
                  {tab.createComponent({ attachmentId: attachmentUid })}
                </div>
              )
          )}

          <div className="col s12 side-container-content-footer">
            <i onClick={() => toggleSidebar()} className="tiny material-icons">
              slideshow
            </i>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubActionsContainer;
