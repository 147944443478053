import * as Reflux from 'reflux';
import { serverSettings } from '../../clients/system';
import Store from '../Store';
import { IServerSettings, Features } from 'mm-types';
import { SESSION_STORAGE_KEYS } from '../../utils/sessionStorageKeys';
import { setFeatures } from '../../components/featureSwitch/actions';
import appStore from '../../appStore';

export type State = {
  settings: Partial<IServerSettings>;
};

const DEFAULT_FEATURES: Features = {
  NNCIndexMetadata: true,
  kpi: false,
  quiz: false,
  mockResponse: false,
  duePeriod: false,
  workflowActions: false
};

export class ServerSettingsStore extends Store<State> {
  private resolve: (data: Partial<IServerSettings>) => void;
  private onLoadPromise: Promise<Partial<IServerSettings>>;

  constructor() {
    super();
    this.onLoadPromise = new Promise((resolve) => (this.resolve = resolve));
    this.state = {
      settings: {}
    };
  }

  async fetchServerSettings() {
    const settings: Partial<IServerSettings> = await serverSettings();

    if (settings.features) {
      // override UI defaults with server ones
      settings.features = { ...DEFAULT_FEATURES, ...settings.features };
    } else {
      settings.features = DEFAULT_FEATURES;
    }

    settings.features = { ...settings.features, ...this.sessionFeatures };
    this.state.settings = settings;
    appStore.dispatch(setFeatures(settings.features));
    this.resolve(settings);
    this.trigger(this.state.settings);
  }

  getServerSettings() {
    return this.state.settings;
  }

  onLoad() {
    return this.onLoadPromise;
  }

  getFeatures(): Features {
    return { ...this.state.settings.features, ...this.sessionFeatures };
  }

  get sessionFeatures(): Features {
    const featuresOn = sessionStorage.getItem(btoa(SESSION_STORAGE_KEYS.FEATURES_ON));
    const featuresOff = sessionStorage.getItem(btoa(SESSION_STORAGE_KEYS.FEATURES_OFF));
    const sessionFeatures: Features = {};
    if (featuresOn) {
      featuresOn.split(';').forEach((featureBase64) => {
        if (featureBase64) {
          sessionFeatures[atob(featureBase64)] = true;
        }
      });
    }
    if (featuresOff) {
      featuresOff.split(';').forEach((featureBase64) => {
        if (featureBase64) {
          sessionFeatures[atob(featureBase64)] = false;
        }
      });
    }
    return sessionFeatures;
  }
}

const singleton = Reflux.initStore<ServerSettingsStore>(ServerSettingsStore);
export default singleton;
