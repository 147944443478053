import * as _ from 'lodash';
import { IDocUnitProfile } from 'mm-types';

const UnitSanitizer = function (tinyMCEContent: string, rawHtmlContent?: string, unitProfile?: IDocUnitProfile, trustContent?: boolean) {
  let content: JQuery<HTMLElement>;
  let contentString = '';

  const removeElementWithClasses = ['.element-page-break', '.table-element-page-break', '.mce-resizehandle'];

  const permittedSpanClasses = [
    'arc-revision-element-added',
    'arc-revision-unit-added',
    'arc-revision-element-changed',
    'arc-revision-unit-changed',
    'arc-super-script',
    'arc-sub-script',
    'arc-underline',
    'arc-overline',
    'arc-uppercase',
    'arc-lowercase',
    'arc-capitalize',
    'arc-unit-break',
    'arc-rotate-right',
    'arc-rotate-left',
    'arc-inline',
    'arc-inline-enum',
    'arc-text',
    'element-top-text',
    'arc-effectivity-tag',
    'arc-effectivity-tag-value',
    'element-content',
    'arc-anchor',
    'arc-symbol',
    'arc-read-only',
    'ref-ordinal'
  ];
  // TABLES, ULs and OLs are special cases in DOM manipulation and have to be handled differently to DIVs, Ps etc (you can't query them in the same way)
  if (unitProfile && unitProfile.isEncapsulatingElement) {
    const parentContent = $('<div>' + rawHtmlContent + '</div>');
    const $encapsulatingEl = parentContent.find(unitProfile.outerElement!).first().clone();
    if (!$encapsulatingEl.attr('data-subType')) {
      $encapsulatingEl.attr('data-subtype', unitProfile.type!);
    }
    $encapsulatingEl.html(tinyMCEContent);
    content = $('<div />').append($encapsulatingEl);
  } else {
    content = $('<div>' + tinyMCEContent + '</div>');
  }

  // remove span for showing element page break details
  content.find(removeElementWithClasses.join(',')).each((i, el) => {
    el.remove();
  });

  // not editable class causes timymce to added attribute
  content.find('[contenteditable]').removeAttr('contenteditable');

  // TinyMCE empty node focus futzing
  content.find('br:not([data-nid])').each((index, el) => {
    if ($(el).is(':last-child') && !el.nextSibling) {
      el.remove();
    }
  });

  content.find('br:not([data-nid]), .arconics-bogus, br[data-mce-bogus="1"]').remove();

  // AER-6941
  content.find('br:last-child').each((index, el) => {
    // if null can remove last child line-break as prev sibling is text
    if (el.previousElementSibling === null && el.nextSibling === null && el.attributes['data-nid']?.value === '') {
      el.remove();
    }
  });

  if (!trustContent) {
    // new P, TABLE and OL/UL when added to document cannot have classes applied due to a limitation in tinymce
    // therefore we must ensure all new posted information decorates the html

    sanitizeList('ul', 'unordered-list', 'unordered');
    sanitizeList('ol', 'ordered-list', 'ordered');

    function setAttrIfNotExists($el: JQuery<HTMLElement>, attrName: string, value: string) {
      $el.attr(attrName, $el.attr(attrName) ?? value);
    }

    function sanitizeList(elementType: string, listType: string, subType: string) {
      content.find(elementType).each(function () {
        const $list = $(this);
        const $listItems = $list.find('>li');
        $list.addClass('arc-list');

        setAttrIfNotExists($list, 'data-element-family', 'list');
        setAttrIfNotExists($list, 'data-list-type', listType);
        setAttrIfNotExists($list, 'data-subtype', subType);

        if ($listItems.length === 0 && $list.children().length === 0) {
          // sometime tinymce inserts empty ULs, remove them on sanitization
          $list.remove();
        } else {
          $listItems.removeClass('arc-unli arc-oli').addClass('arc-li');
        }
      });
    }

    content.find('a').addClass('arc-link');
    content.find('table').each(function () {
      const $table = $(this);
      $table.addClass('arc-table');
      $table.find('tbody').addClass('arc-table-body');
      $table.find('tfoot').addClass('arc-table-foot');
      $table.find('thead').addClass('arc-table-head');
      $table.find('tr').addClass('arc-table-row');
      $table.find('td').addClass('arc-table-data');
      $table.find('td[rowspan]').css('height', '');
      $table.find('span:not([class])').addClass('arc-text');
      $table.find('caption').addClass('arc-table-caption');
    });

    content
      .find(
        `
      figure em, 
      figure span, 
      figure strong, 
      span#_mce_caret
    `
      )
      .replaceWith(function () {
        return $(this).contents();
      });

    content
      .find(
        `
      p:not([style]):not([class]), 
      div:not([style]):not([class])
    `
      )
      .replaceWith(function () {
        return $(this).contents();
      });

    // Within content, replace the non-breaking characters only where the element is empty.
    // Otherwise Tiny will swoop in the scatter BR elements about for the purpose of maybe having to focus on them.
    content = content
      .filter((index, el) => (el.innerHTML && el.innerHTML.length !== 0) as boolean)
      .each((index, el) => {
        el.innerHTML = el.innerHTML.replace('position: relative;', '').replace(/&nbsp;/gi, ' ');
        // If unit is list, don't remove zero width nbsp as causes the li marker to go to bottom of content
        if (unitProfile?.type !== 'list') {
          el.innerHTML = el.innerHTML.replace(/[\u2060\ufeff]/g, '');
        }
      });

    // AER-5441, if list remove word joiner and bom (zero width spaces) from non-empty paragraphs
    if (unitProfile?.type === 'list') {
      content.find('.paragraph').each((index, el) => {
        // remove all white space and if length is greater then 1 remove zero-width spaces, there is only one zero-width space added when the para is initialized
        if (el.innerHTML.trim().length > 1) {
          el.innerHTML = el.innerHTML.replace(/[\u2060\ufeff]/g, '');
        }
      });
    }
  }

  contentString = content.html();

  if (contentString && !trustContent) {
    const parsedContent = $.parseHTML(contentString),
      contentType = parsedContent[0];

    // if not single line text node (which doesn't need amendment as it'll be raw text)
    if (!(contentType.nodeType === 3 && parsedContent.length === 1)) {
      // apply generic filter
      content = $('<div>' + contentString + '</div>');

      // remove all spans that have no style or permitted class, i.e. their role is irrelevant and they will break backend, preserve formatting children
      content.find('span:not([style]), span[style]:not([class])').each(function () {
        const $span = $(this);
        if (
          !_.some(permittedSpanClasses, (spanClass) => {
            return ($span.attr('class') && $span.attr('class')!.indexOf('arc-') >= 0) || $span.hasClass(spanClass);
          })
        ) {
          const children = $span.children();
          if (children.length) {
            $span.replaceWith($span.contents() as any);
          } else {
            $span.replaceWith($span.text());
          }
        }
      });
    }
  }

  // return something that can be appendable to the DOM easily
  if (unitProfile && unitProfile.isEncapsulatingElement) {
    return content.find(unitProfile.outerElement!).html();
  } else {
    return content.html ? content.html() : contentString; // i.e. cater for if plain text
  }
};

export default UnitSanitizer;
