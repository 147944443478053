import { IDataAttribute, IDefinition, IElementDefinition, IUnit } from 'mm-types';
import ProjectDefinitionStore, { PROJECT_DEFINITION_TYPES } from '../flux/common/ProjectDefinitionStore';
import * as _ from 'lodash';
import { NumberAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/NumberAttribute';
import { LabelAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/LabelAttribute';
import { ToogleWithDropdownAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/ToogleWithDropdownAttribute';
import { DropdownAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/DropdownAttribute';
import { ToggleAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/ToggleAttribute';
import EditorStore from '../flux/editor/EditorStore';
import ProjectStore from '../flux/editor/ProjectStore';
import { DateTimeAttribute } from '../components/editor/sidetabs/sub/renderDataAttributes/components/DateTimeAttribute';

export type DataAttributesValues = {
  [key: string]: string | undefined;
};

export const updateDataAttributeMinimumLayer = (parentLayer: string, dataAttributes?: IDataAttribute[]) => {
  const project = ProjectStore.getProject();

  if (dataAttributes && project?.definitionName == PROJECT_DEFINITION_TYPES.airbus) {
    dataAttributes?.forEach((dataAttribute) => {
      if (dataAttribute.id == 'layer' && dataAttribute.enumValues) {
        dataAttribute.minimumEnumIndexAllowed = parseInt(parentLayer) - 1;
        return dataAttribute;
      }
      return dataAttribute;
    });
  }

  return dataAttributes;
};

export function defaultValue(dataAttribute: IDataAttribute): string {
  if (!!dataAttribute.defaultValue) {
    return dataAttribute.defaultValue;
  }
  if (dataAttribute.type === 'datetime') {
    const dateString = dataAttribute.defaultValue ?? '';
    if (dateString?.length !== 0 && !isNaN(Date.parse(dateString))) {
      return dataAttribute.defaultValue ?? '';
    }
    return '';
  }
  if (dataAttribute.type === 'boolean') {
    return 'false';
  }
  return '';
}

export function getDefaultValueByElementDefinition(id: string, elementDefinition: IElementDefinition | undefined): string | null {
  return elementDefinition?.dataAttributes?.find((obj) => obj.id == id)?.defaultValue ?? null;
}

// --- Conditional Attributes ---

export function isConditionalProperty(propName: string) {
  return propName === 'ecam';
}

export function handleConditionalProperty(
  propName: string,
  allDataAttributesValues: DataAttributesValues
): DataAttributesValues | undefined {
  if (propName === 'ecam') {
    if (allDataAttributesValues['ecam'] === 'true') {
      return { ecamimportance: 'A', ecamstrmsg: '' };
    } else {
      return { ecamimportance: '', ecamstrmsg: '' };
    }
  }
}

export function isLayerProperty(propName: string) {
  const project = ProjectStore.getProject();
  return project?.definitionName == PROJECT_DEFINITION_TYPES.airbus && propName === 'layer';
}

export function handleLayerProperty(targetElement: HTMLElement, dataAttribute: IDataAttribute, newValue: string, oldValue?: string) {
  const layeredChildren: Element[][] = [
    Array.from(targetElement.querySelectorAll("[data-layer='1']")),
    Array.from(targetElement.querySelectorAll("[data-layer='2']")),
    Array.from(targetElement.querySelectorAll("[data-layer='3']"))
  ];

  const parentLayerValue = parseInt(newValue);
  const oldParentLayerValue = oldValue ? parseInt(oldValue) : undefined;

  if (parentLayerValue >= 1 && parentLayerValue <= 3) {
    let children: Element[];
    if (oldParentLayerValue && oldParentLayerValue >= parentLayerValue) {
      children = Array.from(targetElement.querySelectorAll(`[data-layer='${oldParentLayerValue}']`));
    } else {
      // Flatten array of layer elements
      children = layeredChildren.slice(0, parentLayerValue).flat();
    }
    children.forEach((child) => {
      const childLayer = child.getAttribute('data-layer');
      if (childLayer) {
        EditorStore.getEditor()
          .getActiveEditorFacade()
          ?.applyDataAttribute(child as HTMLElement, dataAttribute.dataV, parentLayerValue.toString(), false);
      }
    });
  }
}

export const isEnumOptionDisabled = (dataAttributeId: string, index: number, minimumEnumIndexAllowed: number) => {
  if (dataAttributeId == 'layer' && minimumEnumIndexAllowed >= 0) {
    return minimumEnumIndexAllowed <= index ? false : true;
  }
  return false;
};

export const blankValueNotRequired = (dataAttribute: IDataAttribute) => {
  return ['layer'].includes(dataAttribute.id);
};

export function isDisabled(propName: string, allDataAttributesValues: DataAttributesValues, units: IUnit[] = []) {
  if (propName === 'ecamimportance' || propName === 'ecamstrmsg') {
    return allDataAttributesValues['ecam'] === 'false';
  }
  if (propName === 'breakBefore' && units.length !== 0) {
    return !isPageBreakBeforeEnabled(units);
  }
  return false;
}

const isPageBreakBeforeEnabled = (units) => {
  return _.some(units, (unit) => unit.isPageBreakBeforeControlEnabled);
};

// --- Hierarchical Attributes

export function isLeaf(dataAttributes: IDataAttribute[], dataAttribute?: IDataAttribute) {
  if (!dataAttributes) {
    return false;
  }
  const isParent = dataAttributes.find((da) => da.parent === dataAttribute?.id);

  return !!dataAttribute?.parent && !isParent;
}

export function findParentKey(
  dataAttributes: IDataAttribute[],
  dataAttributesValues: DataAttributesValues,
  parentDataAttributeId: string
): string | undefined {
  let parentKey;
  const dataAttribute = dataAttributes.find((da) => da.id === parentDataAttributeId);

  if (!!dataAttribute) {
    // parent key is that parent's value selected by the user
    parentKey = dataAttributesValues[dataAttribute.id];

    // there's another parent above the current parent, composite key joined by -
    if (!!parentKey && !!dataAttribute.parent) {
      parentKey = findParentKey(dataAttributes, dataAttributesValues, dataAttribute.parent) + '-' + parentKey;
    }
  }

  return parentKey;
}

export function findEnumValuesForParentKey(enumValues: string[], parentKey: string): string[] {
  const result: { [key: string]: string[] } = {};

  // each enum value is of format <KEY>:<POSSIBLE ENUM VALUE>
  //
  enumValues.forEach((entry) => {
    const entryParts = entry.split(':');
    // make sure that enumValue is of the appropriate format (has to have 2 parts split by ':')
    if (entryParts.length === 2) {
      const key = entryParts[0],
        enumValue = entryParts[1];

      // no enumValues array for a give key yet exist, create one.
      if (!result[key]) {
        result[key] = [enumValue];
      }
      // there's already an array of possible enumValues for a given key. Add extra one to it.
      else {
        result[key].push(enumValue);
      }
    }
  });

  return result[parentKey] ?? [];
}

export function clearDependableDataAttribute(
  dataAttributes: IDataAttribute[],
  dataAttributesValues: DataAttributesValues,
  dataAttribute: IDataAttribute,
  removeAttribute: (dataV: string) => void
) {
  const child = dataAttributes.find((da) => da.parent === dataAttribute.id);
  if (!!child) {
    delete dataAttributesValues[child.id];
    removeAttribute(dataAttribute.dataV);
    const newDataAttributesValues = { ...dataAttributesValues };
    return clearDependableDataAttribute(dataAttributes, newDataAttributesValues, child, removeAttribute);
  }
  return dataAttributesValues;
}

/**
 * Toggles value in the comma separated list of checklist values.
 * @constructor
 * @param {string | undefined} checklistValue - Existing list of comma separated checklist values list.
 * @param {string} newValue - checklist value which should be added or removed from the checklist values list.
 * @param {string[]} possibleValues - All possible values for the checklist.
 * @return {string} - New checklist values list
 */
export function toggleValueInChecklist(checklistValue: string | undefined, newValue: string, possibleValues: string[]): string {
  let newChecklistValue: string;
  if (!checklistValue) {
    // no values yet for this data attribute
    newChecklistValue = newValue;
  } else {
    // there are some values for this data attribute already
    let checklistValues = checklistValue.split(',');
    if (!checklistValues.find((checkListValue) => checkListValue === newValue)) {
      // not found so add to the list
      checklistValues.push(newValue);
    } else {
      // found so remove from the list
      checklistValues = checklistValues.filter((checklistValue) => checklistValue !== newValue);
    }

    // re-create list of checklist values in the order as they appear in the dataAttribute.possibleValues
    const sortedChecklistValues: string[] = [];
    possibleValues.forEach((possibleValue) => {
      // find out if possibleValue is in checklistValues
      if (
        checklistValues.findIndex((value) => {
          return value === possibleValue;
        }) > -1
      ) {
        sortedChecklistValues.push(possibleValue);
      }
    });
    newChecklistValue = sortedChecklistValues.join(',');
  }
  return newChecklistValue;
}

/**
 * Finds out if dataAttribute is applicable by checking if parentDefinitionId is in the array of dataAttribute.applicableWhenChildOf or that ancestorDefinitionId is in the array of dataAttribute.applicableWhenDescendantOfUnit.
 * @constructor
 * @param {IDataAttribute} dataAttribute - the data attribute whose applicableWhenChildOf array will be used to check if parentDefinitionId is in it.
 * @param {string | undefined} parentDefinitionId - id of the parent's definition which should be in the dataAttribute.applicableWhenChildOf array.
 * @param {string | undefined} unitDefinitionId - id of unit definition which should be in the dataAttribute.applicableWhenDescendantOfUnit array.
 * @return {string} - {false} when it is not in either array, or when dataAttribute.applicableWhenChildOf is defined but there's no parentDefinitionId or when dataAttribute.applicableWhenDescendantOfUnit is defined but there's no unitDefinitionId.  {true} Otherwise.
 */
export function isDataAttributeApplicable(dataAttribute: IDataAttribute, parentDefinitionId?: string, unitDefinitionId?: string): boolean {
  if (dataAttribute.applicableWhenChildOf) {
    // must have a parent which is on the list of dataAttribute.applicableWhenChildOf
    if (!parentDefinitionId || (!!parentDefinitionId && dataAttribute.applicableWhenChildOf.indexOf(parentDefinitionId) === -1)) {
      return false;
    }
  }
  if (dataAttribute.applicableWhenDescendantOfUnit) {
    // must have a ancestor unit which is on the list of dataAttribute.applicableWhenDescendantOfUnit
    if (!unitDefinitionId || (!!unitDefinitionId && dataAttribute.applicableWhenDescendantOfUnit.indexOf(unitDefinitionId) === -1)) {
      return false;
    }
  }
  return true;
}

export function getElmDef(id?: string) {
  if (id) {
    return ProjectDefinitionStore.toElemDefinitionId(id);
  }
  return undefined;
}

export function hasDefinitionDataAttributes(definition?: IDefinition, parentDefinitionId?: string, unitDefinitionId?: string) {
  if (!!definition?.dataAttributes) {
    const filteredDataAttributes = definition.dataAttributes.filter((dataAttribute) => {
      return isDataAttributeApplicable(dataAttribute, parentDefinitionId, unitDefinitionId);
    });
    return filteredDataAttributes.length > 0;
  }
  return false;
}

export function getDataAttributeType(id: string, type: string) {
  switch (id) {
    case 'breakBefore':
      return 'enumAsToggleWithDropdown';
    case 'breakAfter':
      return 'enumAsToggle';
    case 'columnCount':
      return 'enumAsDropdown';
    default:
      return type;
  }
}

export const DataAttributeComponentMap = {
  boolean: ToggleAttribute,
  enumAsToggle: ToggleAttribute,
  enum: DropdownAttribute,
  enumAsDropdown: DropdownAttribute,
  enumAsToggleWithDropdown: ToogleWithDropdownAttribute,
  label: LabelAttribute,
  number: NumberAttribute,
  datetime: DateTimeAttribute
};
