import { IProjectActivity } from 'mm-types';
import React from 'react';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const Publication = ({ entry }: PropsType) => {
  const publicationDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    publicationDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  if (entry.publish) {
    publicationDom.push(
      <div key="date">
        <label>Revision Date:</label>
        <span>{dateUtil(entry.publish.revisionDate).formatDate()}</span>
      </div>
    );
  }

  if (entry.user) {
    publicationDom.push(
      <div key="user">
        <label>User:</label> <span>{entry.user.displayName}</span>
      </div>
    );
  }

  return <div className="settings-details">{publicationDom}</div>;
};
