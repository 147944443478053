import * as _ from 'lodash';
import { UnitTypes } from '../../utils/units/UnitTypes';
import { Link } from '../../utils/tinyFacade/tinyLinkHelper';

export type LinkModel = {
  isUpdate: boolean;
  elementUid: string;
  elementNid: string;
  unitType: UnitTypes;
  href: string;
  selectedVariant: string;
  managed: boolean;
  stale: boolean;
  elementName: string;
  text: string;
  type: string;
  projectIndexUid: string;
  projectUid: string;
  duRefRole?: string;
  duRefProduct?: string;
};

export type LinkData = {
  href?: string;
  text?: string;
} & { [l in Link]?: string | boolean };

const WEB_TYPES = [
  {
    name: 'http',
    identify: /^(https?|ftp):/,
    prefix: '',
    validate: /^(https?|ftp):\/\/(.*)/,
    msg: 'Not a valid url, must begin with http/https/ftp/ i.e https://'
  },
  {
    name: 'tel',
    identify: /^tel:(.*)/,
    prefix: 'tel:',
    validate: /^\+[0-9]{10,15}$/,
    msg: 'Not a valid telephone number, must be in form +1123123123'
  },
  {
    name: 'sms',
    identify: /^sms:(.*)/,
    prefix: 'sms:',
    validate: /^\+[0-9]{10,15}$/,
    msg: 'Not a valid telephone number, must be in form +1123123123'
  },
  {
    name: 'mail',
    identify: /^mailto:(.*)/,
    prefix: 'mailto:',
    // https://html.spec.whatwg.org/multipage/forms.html#valid-e-mail-address
    validate: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    msg: 'Not a valid email address, must be in form test@test.com'
  }
];

/** Parse a link from its dom attributes into a model for use within the component **/
function fromLinkToModel(linkData) {
  function documentLinkToModel(linkData) {
    const model: Partial<LinkModel> = {};
    model.isUpdate = true;
    model.elementUid = linkData['data-target-unit-uid'];
    model.elementNid = linkData['data-target-element-nid'];
    if (linkData['data-target-variant-uid']) {
      model.selectedVariant = linkData['data-target-variant-uid'];
    }
    model.managed = _.includes(['partial', 'full'], linkData['data-link-text-managed']);
    model.stale = !!linkData['data-arc-link-stale'];

    if (linkData['data-link-text-managed'] === 'partial') {
      model.elementName = linkData.text.slice(linkData['data-link-text'].length + 1);
      model.text = linkData['data-link-text'];
    } else if (linkData['data-link-text-managed'] === 'full') {
      model.elementName = linkData.text;
      model.text = '';
    } else {
      model.text = linkData.text || '';
    }

    model.type = 'document';
    if (linkData['data-element-definition-id'] === 'DuRef') {
      model.duRefProduct = linkData['data-product'];
      model.duRefRole = linkData['data-role'];
    }
    return model;
  }

  function crossDocumentLinkToMode(linkData) {
    const model = documentLinkToModel(linkData);
    model.isUpdate = true;
    model.projectIndexUid = linkData['data-target-index-uid'];
    model.projectUid = linkData['data-target-project-uid'];
    model.type = 'cross-document';
    return model;
  }

  function webLinkToModel(linkData) {
    const model: {
      isUpdate?: boolean;
      text?: string;
      href?: string;
      type?: string;
    } = {};
    model.isUpdate = true;
    model.text = linkData.text;

    const webtype = WEB_TYPES.find((wt) => wt.identify.test(linkData.href));

    if (webtype) {
      model.href = linkData.href.slice(webtype.prefix.length);
      model.type = webtype.name;
    } else {
      // fallback
      model.href = '';
      model.type = 'http';
    }

    return model;
  }
  if (linkData['data-type'] === 'internal') {
    return documentLinkToModel(linkData);
  } else if (linkData['data-type'] === 'external') {
    return webLinkToModel(linkData);
  } else if (linkData['data-type'] === 'inter-doc') {
    return crossDocumentLinkToMode(linkData);
  } else {
    return { type: 'document', managed: true, text: linkData.text || '' }; // the default
  }
}

/** export a model from the component back to domAttributes to write to tinyMCE **/
function fromModelToLink(model: Partial<LinkModel>, isDuRefLink = false): LinkData {
  const linkData: LinkData = {};

  if (model.type === 'document' || model.type === 'cross-document') {
    linkData['data-type'] = 'internal';
    linkData['data-target-unit-uid'] = model.elementUid;
    if (model.selectedVariant) {
      linkData['data-target-variant-uid'] = model.selectedVariant;
    }

    if (['video', 'graphic', 'table', 'anchor'].indexOf(model.unitType!) >= 0) {
      linkData['data-target-element-nid'] = model.elementNid;
    }

    linkData.href = '#';

    if (model.managed && model.text) {
      linkData['data-link-text-managed'] = 'partial';
      linkData['data-link-text'] = model.text;
      linkData.text = model.text + ' ' + model.elementName;
    } else if (model.managed) {
      linkData['data-link-text-managed'] = 'full';
      linkData.text = model.elementName;
    } else {
      linkData['data-link-text-managed'] = 'none';
      linkData.text = model.text;
    }

    if (model.type === 'cross-document') {
      linkData['data-type'] = 'inter-doc';
      linkData['data-target-index-uid'] = model.projectIndexUid;
      linkData['data-target-project-uid'] = model.projectUid;
    }
    if (isDuRefLink) {
      linkData['data-element-definition-id'] = 'DuRef';
      linkData['data-product'] = 'QRH';
      linkData['data-role'] = model.duRefRole ?? '';
      linkData['data-subtype'] = 'duRef';
      linkData.class = 'arc-link';
    }

    linkData['data-arc-broken'] = false; // when we just saved it never broken;
  } else {
    const type = WEB_TYPES.find(function (wt) {
      return wt.name === model.type;
    })!;

    linkData.href = type.prefix + model.href;
    linkData.text = model.text;
    linkData['data-type'] = 'external';
  }

  return linkData;
}

function isLink($element: JQuery<HTMLElement>): { element: JQuery<HTMLElement> | null; isManaged: boolean; exists: boolean } {
  const link = $element.closest('a');
  if (!link.length) {
    return {
      isManaged: false,
      exists: false,
      element: null
    };
  }
  const managed = link.attr('data-link-text-managed');
  return {
    isManaged: managed === 'full' || managed === 'partial',
    element: link,
    exists: true
  };
}

const toRet = {
  fromLinkToModel: fromLinkToModel,
  fromModelToLink: fromModelToLink,
  isLink,
  webTypes: WEB_TYPES,
  identifyWebLink: (l) =>
    _.head(
      WEB_TYPES.filter(function (v) {
        return v.identify.test(l);
      }).map(function (t) {
        return t.name;
      })
    ) || 'meh'
};

export default toRet;
