import EditorStore from '../flux/editor/EditorStore';
import _ from 'lodash';
import { IUnit } from 'mm-types';
import { ElementDetails } from '../components/editor/utils/units/ElementDetails';
import getDataNid = ElementDetails.getDataNid;

export namespace PrintOutputUtils {
  export const isNotSetOrSetToDefault = (attributeValue: string | null, attributeDefaultValue: string | null, defaultValue?: string) => {
    return (
      (attributeDefaultValue && attributeValue == attributeDefaultValue) ||
      (!attributeDefaultValue && (!attributeValue || attributeValue == defaultValue))
    );
  };

  export const showIndicator = (indicator: string | null, indicatorDefault: string | null, defaultValue: string) => {
    if (indicator) {
      if (indicatorDefault && indicator == indicatorDefault) {
        return false;
      }
      if ((indicatorDefault && indicator !== indicatorDefault) || indicator !== defaultValue) {
        return true;
      }
    }
    return false;
  };

  export const getRowHeight = (row: Element) => {
    return document.querySelector<HTMLElement>("[data-nid='" + row.getAttribute('data-nid') + "']")?.offsetHeight || 0;
  };

  export const getPrintOutputAttributes = (element: Element) => {
    return {
      definitionId: element.getAttribute('data-element-definition-id')!,
      breakBefore: element.getAttribute('data-page-break-before'),
      keepTogether: element.getAttribute('data-avoid-break-inside'),
      keepWithNext: element.getAttribute('data-page-break-after')
    };
  };

  export const getElementsWithPageBreaks = (selectedUnit: IUnit) => {
    const selectedUnitCurrentDomHTML = currentSelectedUnitIsSelectedUnitInTree(selectedUnit.uid)
      ? EditorStore.getFocusedTreeUnits()?.[0]?.unitElement.parentElement?.outerHTML
      : null;
    const parser = new DOMParser();
    const newUnitHtml = parser.parseFromString(selectedUnitCurrentDomHTML ?? selectedUnit.html, 'text/html');
    return {
      html: newUnitHtml,
      elementsWithPageBreaks: newUnitHtml.querySelectorAll(
        `.arc-unit [data-avoid-break-inside], .arc-unit [data-page-break-after], .arc-unit [data-page-break-before]`
      )
    };
  };

  export const currentSelectedUnitIsSelectedUnitInTree = (selectedUnitUid: string): boolean => {
    return getDataNid(EditorStore.getFocusedTreeUnits()?.[0]?.unitElement.parentElement) === selectedUnitUid;
  };

  export const updateElementPrintOutputInUnit = _.debounce(() => {
    const selectedUnit = EditorStore.getSelectedUnit();
    if (selectedUnit.type == 'table') {
      updateRowPrintOutput();
      updateRowPrintOutput('Head');
    } else {
      const { html, elementsWithPageBreaks } = getElementsWithPageBreaks(selectedUnit);
      if (elementsWithPageBreaks && elementsWithPageBreaks.length > 0) {
        elementsWithPageBreaks.forEach((element: Element) => {
          const { tagName } = element;
          if (tagName?.toUpperCase() == 'TR') {
            updateRowPrintOutputHeight(element);
            updateRowPrintOutputHeight(element, 'Head');
          } else {
            const {
              dataRefNid,
              style: { top, marginTop }
            } = updatePrintOutputLabelPosition(element);
            const printOutputElement = document.querySelector<HTMLElement>(`[data-ref-nid="${dataRefNid}-element"]`);
            if (printOutputElement) {
              top && (printOutputElement.style.top = top);
              marginTop && (printOutputElement.style.marginTop = marginTop);
            }
          }
        });
      }

      const tablesWithBreaks = html.querySelectorAll('table');
      tablesWithBreaks.forEach((table: Element) => {
        const isNotNestedTable = !table?.parentElement?.closest('table');
        if (table?.getAttribute('data-element-family') == 'Table' && isNotNestedTable) {
          const {
            dataRefNid,
            style: { top }
          } = updatePrintOutputLabelPosition(table);
          const printOutputElement = document.querySelector<HTMLElement>(`[data-ref-nid="${dataRefNid}-table"]`);
          top && printOutputElement && (printOutputElement.style.top = top);
        }
      });
    }
  }, 500);

  export const updatePrintOutputLabelPosition = (element: Element) => {
    const dataRefNid = element.getAttribute('data-nid');
    const parentDiv = document.querySelector(`[data-nid="${dataRefNid}"`) as HTMLElement;
    const marginTop = element.getAttribute('data-element-definition-id') == 'DeferredItemsCollection' ? '0.6em' : '0em';
    const { top } = getOffset(parentDiv, parentDiv?.closest('.document-unit-outer'));
    const style: { marginTop?: string; top?: string } = { marginTop: marginTop, top: `${top}px` };
    return { dataRefNid, style };
  };

  export const updateRowPrintOutput = (type = 'Row') => {
    const nestedDOM = EditorStore.getLastFocusedNestedUnit()?.nestedDOM;
    const rowNodeIndex = nestedDOM?.findIndex((node) => node.friendlyName === type) ?? -1;
    if (nestedDOM && rowNodeIndex > -1) {
      const row = nestedDOM[rowNodeIndex]?.element;
      updateRowPrintOutputHeight(row, type);
    }
  };

  export const updateRowPrintOutputHeight = (row: Element, type = 'Row') => {
    const offsetHeightOfEditingRow = getRowHeight(row);

    if (row.getAttribute('data-nid')) {
      const printOutputElement = document.querySelector<HTMLElement>(`[data-ref-nid="${row.getAttribute('data-nid')}-${type}"]`);
      if (printOutputElement) {
        printOutputElement.style.height = `${offsetHeightOfEditingRow}px`;
      }
    }
  };

  const getOffset = (elm: HTMLElement, rootElm: Element | null): { left: number; top: number } => {
    let left = 0,
      top = 0,
      parent = elm;
    if (rootElm) {
      while (parent && parent != rootElm && parent.nodeType) {
        left += parent.offsetLeft || 0;
        top += parent.offsetTop || 0;
        parent = parent.offsetParent as HTMLElement;
      }
    }
    return { left, top };
  };
}
