import * as React from 'react';

export type Props = {
  show?: boolean;
  value: string;
  className?: string;
  delayCloseOnSelect?: number | null;
  onSelect?: (e: string, closeOnSelect) => void;
  children?: React.ReactNode;
};

export type State = {};

export default class DropDownOption extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    show: true,
    value: '',
    className: ''
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  _handleClick(e: React.MouseEvent<HTMLElement>) {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.value, this.props.delayCloseOnSelect);
    }
  }

  render() {
    return (
      <div className={this.props.className} onClick={(e) => this._handleClick(e)}>
        {this.props.children}
      </div>
    );
  }
}
