import { IDocUnitProfile } from 'mm-types';
import VideoEditProps from '../../../../sidetabs/sub/editComponent/components/VideoEditProps';

export const docUnitProfile: IDocUnitProfile = {
  displayName: 'Video',
  getLabel: () => 'VIDEO',
  identifyingClasses: ['arc-video', 'arc-figure-video', 'arc-figure-video-caption'],
  outerClass: 'arc-figure-video',
  openEditPaneOnSelect: true,
  editPropsComponent: VideoEditProps,

  targetEditContainerSelector: '>.arc-unit',
  template: '',

  menu: {
    docActions: [
      { action: 'Undo', enabled: true },
      { action: 'Redo', enabled: true }
    ],
    editActions: [{ action: 'Paste', enabled: true }],
    justifyActions: [
      { action: 'JustifyLeft', enabled: true },
      { action: 'JustifyCenter', enabled: true },
      { action: 'JustifyRight', enabled: true },
      { action: 'JustifyFull', enabled: false }
    ]
  },
  menuTextSelected: {
    editActions: []
  },

  blockOverridesFromParent: true
};
