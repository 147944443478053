import * as React from 'react';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';

export type Choice = { text: string; payload: string; disabled?: boolean };

export type Props = {
  title?: string;
  choices: Choice[];
  editing?: boolean | null;
  currentchoice: () => number;
  applychoice: (choice: Choice) => void;
  rootClass?: string;
  dataQa?: string;
  disabled?: boolean;
};

export type State = {
  selected: number;
};

export default class DropDown extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    title: '',
    editing: null,
    rootClass: ''
  };

  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: 0
    };
  }

  UNSAFE_componentWillMount() {
    this.unsubscribe = EditorStore.listen(this.tinyUpdateEvent, this);
    this._updateDropdown();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  tinyUpdateEvent(e) {
    if (e.type === 'unitDomChange' || e.type === 'nestedUnitFocusChange') {
      this._updateDropdown();
    }
  }

  _updateDropdown() {
    let type = 0;
    if (this.props.currentchoice) {
      type = this.props.currentchoice();
    }
    this.setState({ selected: type });
  }

  _applyChoice(menuItem: number) {
    if (this.props.applychoice) {
      this.props.applychoice(this.props.choices[menuItem]);
    }
  }

  _handleChange(e, selectedIndex: number, menuItem: number) {
    this.setState({ selected: selectedIndex }, () => this._applyChoice(menuItem));
  }

  _merge(base, obj) {
    if (typeof base === 'object' && typeof obj === 'object') {
      return $.extend({}, base, obj);
    }
    return base;
  }

  render() {
    const base = { width: '100%', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
    let rootClass = '';

    if (this.props.rootClass) {
      rootClass = this.props.rootClass;
    } else {
      rootClass = 'dropdown' + this.props.title!.replace(' ', '').toLowerCase();
    }

    const items = this.props.choices.map((item, index) => {
      return (
        <MenuItem
          disabled={item.hasOwnProperty('disabled') ? item.disabled : false}
          key={item.payload}
          value={index}
          primaryText={item.text}
          innerDivStyle={this._merge(base, { paddingLeft: '5px', paddingRight: '5px' })}
        />
      );
    });

    return (
      <div className={rootClass}>
        <h6>{this.props.title}</h6>
        <DropDownMenu
          autoWidth={false}
          onChange={(e, index, value) => this._handleChange(e, index, value)}
          value={this.state.selected}
          style={this._merge(base, { float: 'left', marginLeft: '0px', marginBottom: '2px' })}
          labelStyle={this._merge(base, { paddingLeft: '0px', paddingRight: '2px', fontWeight: '500' })}
          iconStyle={this._merge(base, { top: '0', right: '0', width: 'auto', fill: '#77858b' })}
          listStyle={{ paddingTop: 0, paddingBottom: 0 }}
          children={items}
          data-qa={this.props.dataQa}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
