import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const SpecialInterestGroups = ({ entry }: PropsType) => {
  const specialInterestGroupsUpdatesDom: JSX.Element[] = [];
  if (entry.indexRevisionNumber) {
    specialInterestGroupsUpdatesDom.push(
      <div key="revision">
        <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
      </div>
    );
  }
  return <div className="settings-details">{specialInterestGroupsUpdatesDom}</div>;
};
