import * as React from 'react';
import { default as PopupMenu, Options, Option } from '../../../../misc/PopupMenu';
import TextField from 'material-ui/TextField';
import FontIcon from 'material-ui/FontIcon';
import FlatButton from 'material-ui/FlatButton';

const BGCOLOR = '#f9f9f9';

export type Props = {
  findWord: string;
  onUpdate: (e: React.FormEvent<{}>, type: string) => void;
  onFind: (e: React.KeyboardEvent<{}> | React.MouseEvent<{}>) => void;
  onNext: (e: React.MouseEvent<{}>) => void;
  onPrevious: (e: React.MouseEvent<{}>) => void;
  options: {};
  toggleOption: (option: string) => void;
  errorText: string | null;
  findInProgress: boolean;
  matches: number;
};

export type State = {};

export default class FindInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    findWord: '',
    options: {},
    errorText: '',
    findInProgress: false
  };

  onOpenTocDepthMenu() {
    (this.refs.options as PopupMenu).setTriggerButton(this.refs.menuButton).open();
  }

  toggleOption(opt: string) {
    this.props.toggleOption(opt);
  }

  render() {
    let marginBottom = '0px';
    if (this.props.errorText) {
      marginBottom = '20px';
    }
    return (
      <div className="clear">
        <div className="text-with-button" style={{ marginBottom: marginBottom }}>
          <TextField
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.props.onFind(e);
              }
            }}
            id="fintdTextInput"
            value={this.props.findWord ? this.props.findWord : ''}
            floatingLabelText="Search for"
            hintText="search..."
            errorText={this.props.errorText}
            onChange={(e) => this.props.onUpdate(e, 'findWord')}
          />
          <FontIcon
            style={{ fontSize: '0.9em', cursor: 'pointer', marginLeft: '10px' }}
            className="material-icons"
            ref="menuButton"
            onClick={(e) => this.onOpenTocDepthMenu()}
          >
            settings
          </FontIcon>
        </div>
        <PopupMenu ref="options" className="display-options-popup" onSelect={(e) => this.toggleOption(e)}>
          <Options>
            <div className="popup-arrow"></div>
            <div className="popup-menu-title">Find Options:</div>
            <Option dataKey="matchCase" defaultChecked={true} hasCheck={this.props.options['matchCase']}>
              Match Case
            </Option>
            <Option dataKey="wholeWord" defaultChecked={true} hasCheck={this.props.options['wholeWord']}>
              Whole Word
            </Option>
          </Options>
        </PopupMenu>
        <div className="clear"></div>

        <div className="buttons-fr">
          <div className="buttons-inner-left">
            <FlatButton
              backgroundColor="#e0e0e0"
              style={{ border: '1px solid #ccc', minWidth: '27px' }}
              onClick={(e) => this.props.onPrevious(e)}
              disabled={this.props.findInProgress || this.props.matches === 0}
            >
              <span className="muidocs-icon-keyboard-arrow-left material-icons find-replace-button-icon">keyboard_arrow_left</span>
            </FlatButton>
            <FlatButton
              backgroundColor="#e0e0e0"
              style={{ border: '1px solid #ccc', minWidth: '27px', marginLeft: '8px' }}
              onClick={(e) => this.props.onNext(e)}
              disabled={this.props.findInProgress || this.props.matches === 0}
            >
              <span className="muidocs-icon-keyboard-arrow-right material-icons find-replace-button-icon">keyboard_arrow_right</span>
            </FlatButton>
          </div>

          <FlatButton backgroundColor={BGCOLOR} onClick={(e) => this.props.onFind(e)} disabled={this.props.findInProgress} label="find" />
        </div>
      </div>
    );
  }
}
