import React, { useEffect, useState } from 'react';
import { IHighlight } from 'mm-types';
import { CircularProgress, IconButton, RaisedButton } from 'material-ui';
import TextInput from './TextInput';
import Checkbox from 'material-ui/Checkbox';
import ProjectStore from '../../../../../../flux/editor/ProjectStore';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import { MaterialIconConfirm } from '../../../../../general/MaterialIcon';
import UnitHighlightStore, { TocHighlightDetails, UnitHighlightStoreEvent } from '../../../../../../flux/editor/UnitHighlightStore';
import useListenToStore from '../../../../../hooks/useListenToStore';
import { styleMap } from '../../../../../../styles/styleGuard';
import { Grid } from '../../../../../general/layouts/Grid';
import HighlightList from './HighlightList';

export type Props = {
  className: string;
  tocUid?: string;
  unitUid: string;
};

const styles = styleMap({
  actions: {
    height: 38
  },
  privateFlag: {
    float: 'left',
    width: '50px !important'
  },
  confirmButton: {
    container: {
      minWidth: 50
    },
    button: {
      color: 'white'
    }
  },
  componentContainer: {
    position: 'relative'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 3001, // cover material-ui elements
    background: 'rgba( 255, 255, 255, 0.8 )'
  },
  loadingComponent: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    right: 0,
    position: 'absolute'
  }
});

const HighlightProperties = ({ className, tocUid, unitUid }: Props) => {
  const [comment, setComment] = useState<string>('');
  const [privateFlag, setPrivateFlag] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [tocHighlight, setTocHighlight] = useState<TocHighlightDetails | null>(UnitHighlightStore.getHighlightDetails());

  useListenToStore({
    store: UnitHighlightStore,
    eventListener: (e: UnitHighlightStoreEvent) => {
      if (e.state.highlightDetails) {
        const { highlightDetails } = e.state;
        setTocHighlight(highlightDetails);
        setComment(highlightDetails?.selected?.comment ?? '');
        setPrivateFlag(!!highlightDetails?.selected?.privateFlag);
        setDisplayForm(
          !EditorStore.isReadOnly() &&
            !!ProjectStore.getProject()?.currentUserPermissions?.canComment &&
            !!highlightDetails?.selected?.unitUid
        );
      }
    }
  });

  useEffect(() => {
    UnitHighlightStore.getHighlightForToc(tocUid, unitUid);
  }, [tocUid, unitUid]);

  const togglePrivateFlag = () => {
    if (fetching) {
      return;
    }
    setPrivateFlag(!privateFlag);
    submit(!privateFlag);
  };

  const toggleWhatsNew = (unitHighlightModel: IHighlight) => {
    updateUnitHighlight(unitHighlightModel.unitUid, {
      comment: unitHighlightModel.comment,
      includedInWhatsNew: !unitHighlightModel.includedInWhatsNew,
      privateFlag: unitHighlightModel.privateFlag
    });
  };

  const submit = (customPrivateFlag?: boolean) => {
    if (fetching || !tocHighlight?.selected) {
      return;
    }
    updateUnitHighlight(unitUid, {
      comment,
      includedInWhatsNew: tocHighlight.selected.includedInWhatsNew ?? true,
      privateFlag: customPrivateFlag ?? privateFlag
    });
  };

  const highlightClick = (unitHighlight: IHighlight) => {
    EditorStore.scrollToUnit(unitHighlight.unitUid);
  };

  const updateUnitHighlight = (unitUid: string, uh: Partial<IHighlight>) => {
    setFetching(true);
    UnitHighlightStore.unitHighlightChange(tocUid, unitUid, uh).then(() => {
      setFetching(false);
    });
  };

  return (
    <div className={className} style={styles.componentContainer} data-qa="unit-highlight-form">
      {fetching && (
        <div style={styles.loadingContainer}>
          <CircularProgress style={styles.loadingComponent} />
        </div>
      )}
      <div className="revision-comment-list">
        <IconButton
          style={{ padding: '0', width: '24px', height: '24px', float: 'right', margin: '-5px 5px 0 0' }}
          iconStyle={{ cursor: 'pointer' }}
          tooltipPosition="top-left"
          tooltip="Include in What's New"
          disabled={true}
          className="icon material-icons"
        >
          <i className="whats-new-tt icon material-icons">stars</i>
        </IconButton>
        {!!tocHighlight?.tocLevelHeading && (
          <h2 style={{ marginBottom: 20 }}>
            <span dangerouslySetInnerHTML={{ __html: tocHighlight?.tocLevelOrdinal }} />
            <span dangerouslySetInnerHTML={{ __html: tocHighlight?.tocLevelHeading }} />
          </h2>
        )}

        <HighlightList
          highlights={tocHighlight?.highlights ?? []}
          onHighlightClick={highlightClick}
          onToggleWhatsNew={toggleWhatsNew}
          selectedUid={unitUid}
        />
      </div>
      {displayForm && (
        <div className="unit-comment">
          <TextInput
            className="reset-mui-padding"
            name="unitComment"
            value={comment}
            onChange={(e) => setComment(e)}
            onEnter={() => {}}
            onEnterKey={() => submit()}
            multiLine={true}
            fullWidth={true}
            floatingLabelStyle={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.54)' }}
            floatingLabelText="Comment"
            data-qa="unit-highlight-form-input"
            blockElement={true}
            maxChars={9999}
          />
          <Grid templateColumns="1fr 50px" alignItems="center">
            <div data-qa="unit-comment-actions" className="unit-comment-actions" data-qa-private-flag={privateFlag}>
              <Checkbox
                checked={privateFlag}
                onClick={togglePrivateFlag}
                label="PRIVATE"
                style={styles.privateFlag}
                data-qa="unit-highlight-form-private-flag"
                className="unit-comment-actions__private_flag"
              />
            </div>
            <div>
              <RaisedButton
                style={styles.confirmButton.container}
                buttonStyle={styles.confirmButton.button}
                icon={
                  fetching ? (
                    <CircularProgress style={{ marginTop: 6 }} size={24} />
                  ) : (
                    <MaterialIconConfirm style={{ lineHeight: 'inherit', marginTop: '-14px' }} />
                  )
                }
                onClick={() => submit()}
                disabled={fetching}
                secondary={true}
                data-qa="unit-highlight-form-confirm-button"
              />
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default React.memo(HighlightProperties);
