import * as React from 'react';
import { Color } from 'mm-types';

interface Props {
  colors: Color[];
  onChange(color: Color): void;
  clearColor?: boolean;
}

export const CLEAR_COLOR: Color = {
  day: {
    id: 'ClearColor',
    value: '',
    name: 'ClearColor'
  },
  night: {
    id: 'ClearColor',
    value: '',
    name: 'ClearColor'
  }
};

const ColorSwatch = (props: Props) => {
  const [hoverColor, setColorHover] = React.useState<string | undefined>();

  return (
    <div className="color-swatch">
      <span className="color-swatch__info-box flex-break-item">{hoverColor}</span>
      {props.colors.map((color, index) => {
        return (
          <span
            onMouseEnter={() => setColorHover(color.day.name)}
            onMouseLeave={() => setColorHover(undefined)}
            key={index}
            data-qa={color.day.id}
            onClick={() => props.onChange(color)}
            className="color-swatch__item"
            style={{ backgroundColor: color.day.value }}
          ></span>
        );
      })}
      {!!props.clearColor && (
        <span className="flex-break-item">
          <span
            key="clearColor"
            data-qa="clearColor"
            onClick={() => props.onChange(CLEAR_COLOR)}
            className="color-swatch__item color-swatch__item--clear"
          />
        </span>
      )}
    </div>
  );
};

export default ColorSwatch;
