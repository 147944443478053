import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import FileAttachmentStore, { Event as FileAttachmentEvent } from '../../flux/editor/FileAttachmentStore';
import { Popover, LinearProgress } from 'material-ui';
import { IFileAttachment } from 'mm-types';
import FileIcon from '../misc/icons/FileIcon';
import { getTypeFromMimeType } from '../../utils/FileIcon';

export type Props = {};

export type State = {
  open: boolean;
  anchorEl?: Element;
  activeUploads: IFileAttachment[];
};

export default class FileAttachmentNotifications extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = FileAttachmentStore.listen(this.onStoreUpdate, this);
    this.state = {
      open: false,
      activeUploads: []
    };
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  private onStoreUpdate(e: FileAttachmentEvent) {
    if (e.type === 'progress') {
      this.setState({ activeUploads: e.activeUploads! });
    }
  }

  render() {
    let notifIcon =
      this.state.activeUploads.length > 0 ? (
        <CircularProgress className="notif-inprogress" mode="indeterminate" size={17.85} thickness={3} color="#FFFFFF" />
      ) : undefined;

    for (const upload of this.state.activeUploads) {
      if (upload.error) {
        notifIcon = <div className="error-icon">!</div>;
      }
    }

    return (
      <div
        className="attachments-notification"
        onClick={(e) =>
          this.setState({
            open: !this.state.open,
            anchorEl: e.currentTarget
          })
        }
      >
        <Popover
          open={this.state.open && this.state.activeUploads.length > 0}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={(e) => this.setState({ open: false })}
        >
          <div className="attachments-notification-popup">
            <h5>Attachments Uploading...</h5>
            {this.state.activeUploads.map((upload, index) => {
              return (
                <div key={`attachment-${index}`} className={upload.error ? 'attachment-error' : ''}>
                  <div>
                    <div className="attachment-icon">
                      {upload.error ? <div className="error-icon">!</div> : <FileIcon fileType={getTypeFromMimeType(upload.content!)} />}
                    </div>
                    <div className="attachment-filename">{upload.filename}</div>
                  </div>
                  <div style={{ clear: 'both' }}>
                    <div className="attachment-progress">
                      {upload.error ? <div>{upload.error.message}</div> : <LinearProgress mode="determinate" value={upload.progress} />}
                    </div>
                    <div onClick={(e) => FileAttachmentStore.cancelUpload(upload)} className="attachment-cancel">
                      <div>X</div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        </Popover>
        {notifIcon}
      </div>
    );
  }
}
