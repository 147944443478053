import * as React from 'react';
import Effectivities from './Effectivities';
import * as client from '../../../../../clients/effectivities';
import { Cancelled } from '../../../../../clients/base-clients';
import { EventStoreEventType, IEditorStoreEvent, IEffectivity, IMsnRange, IUnit } from 'mm-types';
import EditorStore from '../../../../../flux/editor/EditorStore';

export type State = {
  selectedUnit: IUnit | null;
  assignedEffectivities: IEffectivity[];
  availableEffectivities: IEffectivity[];
  msnRange: IMsnRange | null;
  inProgress: boolean;
  inEditMode: boolean;
};

type EffectivitiesActions =
  | { type: 'setSelectedUnit'; payload: IUnit }
  | {
      type: 'setEffectivities';
      payload: { assignedEffectivities: IEffectivity[]; availableEffectivities: IEffectivity[]; msnRange: IMsnRange };
    }
  | { type: 'setInProgress'; payload: boolean }
  | { type: 'setInEditMode'; payload: boolean };

const effectivitiesReducer: React.Reducer<State, EffectivitiesActions> = (state, action) => {
  switch (action.type) {
    case 'setSelectedUnit':
      return {
        ...state,
        selectedUnit: action.payload
      };
    case 'setEffectivities':
      return {
        ...state,
        assignedEffectivities: action.payload.assignedEffectivities,
        availableEffectivities: action.payload.availableEffectivities,
        msnRange: action.payload.msnRange,
        inProgress: false
      };
    case 'setInProgress':
      return { ...state, inProgress: action.payload };
    case 'setInEditMode':
      return { ...state, inEditMode: action.payload };
    default:
      return { ...state };
  }
};

const EffectivitiesContainer = () => {
  const [state, dispatch] = React.useReducer(effectivitiesReducer, {
    selectedUnit: null,
    assignedEffectivities: [],
    availableEffectivities: [],
    msnRange: null,
    inProgress: false,
    inEditMode: false
  });

  React.useEffect(() => {
    const editorUnsub: Function = EditorStore.listen(onEditStoreUpdate, this);
    handleSelectedUnit(EditorStore.getSelectedUnit());
    if (EditorStore.isEditorFocused()) {
      dispatch({ type: 'setInEditMode', payload: true });
    }
    return () => {
      editorUnsub();
    };
  }, []);

  React.useEffect(() => {
    if (state.selectedUnit?.level === 'section') {
      loadEffectivities(state.selectedUnit.uid);
    }
    return () => {};
  }, [state.selectedUnit]);

  const onEditStoreUpdate = (e: IEditorStoreEvent<EventStoreEventType>) => {
    if (e.type === 'unitsSelected') {
      const castEvent = e as IEditorStoreEvent<'unitsSelected'>;
      handleSelectedUnit(castEvent.data!.initialSelectedUnit);
    } else if (e.type === 'editFocus') {
      dispatch({ type: 'setInEditMode', payload: true });
    } else if (e.type === 'editBlur') {
      dispatch({ type: 'setInEditMode', payload: false });
    }
  };

  const handleSelectedUnit = (unit: IUnit | null) => {
    if (unit) {
      dispatch({
        type: 'setSelectedUnit',
        payload: unit
      });
    }
  };

  const loadEffectivities = async (sectionUid: string, refreshUnit = false) => {
    const params = EditorStore.getDocParams();
    if (!!params.projectUid && !!params.indexUid) {
      dispatch({
        type: 'setInProgress',
        payload: true
      });

      if (refreshUnit) {
        await EditorStore.refreshUnit(EditorStore.getSelectedUnit().uid);
      }

      let effectivities = await client.getSectionEffectivities(params.projectUid, params.indexUid, sectionUid);

      if (effectivities instanceof Cancelled) {
        return [];
      }

      dispatch({
        type: 'setEffectivities',
        payload: {
          assignedEffectivities: effectivities.assignedEffectivities,
          availableEffectivities: effectivities.availableEffectivities,
          msnRange: effectivities.manufacturerEffec
        }
      });
    }
  };

  const add = async (effectivities: IEffectivity[]) => {
    const params = EditorStore.getDocParams();
    if (!!params.projectUid && !!params.indexUid && !!state.selectedUnit) {
      dispatch({
        type: 'setInProgress',
        payload: true
      });

      await client.addSectionEffectivities(
        params.projectUid,
        params.indexUid,
        state.selectedUnit.uid,
        effectivities.map((eff) => eff.msn)
      );

      await loadEffectivities(state.selectedUnit.uid, true);
    }
  };

  const remove = async (effectivities: IEffectivity[]) => {
    const params = EditorStore.getDocParams();
    if (!!params.projectUid && !!params.indexUid && !!state.selectedUnit) {
      dispatch({
        type: 'setInProgress',
        payload: true
      });

      await client.removeSectionEffectivities(
        params.projectUid,
        params.indexUid,
        state.selectedUnit.uid,
        effectivities.map((eff) => eff.msn)
      );

      await loadEffectivities(state.selectedUnit.uid, true);
    }
  };

  return (
    <div className="effectivities-subaction subaction-list-container">
      {state.selectedUnit && state.selectedUnit.level === 'section' ? (
        <>
          <Effectivities
            assignedEffectivities={state.assignedEffectivities}
            availableEffectivities={state.availableEffectivities}
            msnRange={state.msnRange}
            add={add}
            remove={remove}
            loading={state.inProgress}
            isReadOnly={EditorStore.isReadOnly() || state.inEditMode}
          />
        </>
      ) : (
        <div className="narrow-panel-empty-list">
          <img src={'/assets/images/comments_panel_info.svg'} />
          <span className="empty-title">Effectivities</span>
          <div className="empty-details">You cannot set effectivity at this level within the document content.</div>
          <div className="empty-details">To set effectivity, select the title of the document unit.</div>
        </div>
      )}
    </div>
  );
};

export default EffectivitiesContainer;
