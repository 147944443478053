class StringUtil {
  static shortenText(text: string, charPosition: number) {
    if (text.length <= charPosition) {
      return text;
    }

    const cutat = text.lastIndexOf(' ', charPosition);
    if (cutat !== -1) {
      return text.substring(0, cutat) + '...';
    } else {
      return text;
    }
  }

  static text(key, strings): string {
    if (strings) {
      return strings.hasOwnProperty(key) ? strings[key] : '';
    } else {
      return '';
    }
  }

  static truncateMiddle(fullStr: string, strLen = 10, separator = '...') {
    if (fullStr.length <= strLen) {
      return fullStr;
    }

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
  }

  static parseToArray(input: string, separator = ','): string[] {
    if (!input || !input.length) {
      return [];
    }
    return input.split(separator);
  }

  static parseFromArray(input: string[], separator = ','): string {
    if (!input) {
      return '';
    }
    return input.join(separator);
  }

  static formatForScreen(str = ''): string {
    return this.toSpacedSeperated(this.stripSeperators(str).map(this.toCapitalized));
  }

  static stripSeperators(str = '', seperators = /[_-]+/): string[] {
    return str.split(seperators);
  }

  static stripHTML(str?: string): string {
    if (str) {
      const div = document.createElement('div');
      div.innerHTML = str;
      return div.textContent || div.innerText || '';
    }
    return '';
  }

  static toCapitalized(str = ''): string {
    return str.replace(/(.)/, function ($1) {
      return $1.toUpperCase();
    });
  }

  static toSpacedSeperated(str: string[], spaceChar = ' '): string {
    return str.join(spaceChar);
  }

  static toCamelCase(str: string) {
    return str
      .split('')
      .map((word, index) => (index % 2 > 0 ? this.toCapitalized(word) : word))
      .join('');
  }

  static dashToCamelCase(str: string) {
    return this.stripSeperators(str)
      .map((word, index) => (index % 2 > 0 ? this.toCapitalized(word) : word))
      .join('');
  }

  static camelCaseToDash(str: string) {
    return this.stripSeperators(str, /(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('-');
  }

  static plainTextToDash(str: string) {
    return this.stripSeperators(str, /[\s_-]+/)
      .map((word) => word.toLowerCase())
      .join('-');
  }
}

export default StringUtil;
