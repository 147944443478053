import * as ReactDOM from 'react-dom';
import * as events from 'events';
import Log from '../../utils/Log';

class UnitImageLoader {
  private _eventEmitter: events.EventEmitter;
  private _pageUnitGraphicsCount: number;
  private _pageUnitGraphicsLoaded: number;
  private _pageUnitGraphicsLoading: boolean;
  public complete: boolean;

  constructor() {
    this._eventEmitter = new events.EventEmitter();
    this._eventEmitter.setMaxListeners(0);
    this.reset();
  }

  reset() {
    this._pageUnitGraphicsCount = 0;
    this._pageUnitGraphicsLoaded = 0;
    this._pageUnitGraphicsLoading = false;

    return this;
  }

  on(event, fn) {
    this._eventEmitter.removeAllListeners(event); // simple for now, but only allow one listener per instance
    this._eventEmitter.on(event, fn);
  }

  get event() {
    return this._eventEmitter;
  }

  clearLoadedImages(docContainerRef) {
    this.reset();
    const $unitGraphics = $(ReactDOM.findDOMNode(docContainerRef) as Element).find('.arc-unit .arc-graphic');
    $unitGraphics.off();
  }

  areImagesLoading() {
    return this._pageUnitGraphicsLoading;
  }

  detectImagesLoad(docContainerRef) {
    const $unitGraphics = $(ReactDOM.findDOMNode(docContainerRef) as Element).find('.arc-unit .arc-graphic');
    this._pageUnitGraphicsCount = $unitGraphics.length;
    this._pageUnitGraphicsLoaded = 0;
    this._pageUnitGraphicsLoading = true;

    // detect browser cached loaded
    $unitGraphics.each(() => {
      if (this.complete) {
        this._pageUnitGraphicsLoaded += 1;
      }
    });

    // graphics already available, do recalc
    if (this._pageUnitGraphicsLoaded >= this._pageUnitGraphicsCount) {
      Log.debug('Graphic download complete (from cache)');
      this._triggerDownloaded();
    } else {
      const self = this; // as jqy below needs the this!
      let isLoadTriggered = false;

      // note: load will *not always* trigger for items already in cache (hence use of .complete above), and isLoadTriggered gate mechanism
      $unitGraphics.on('load', () => {
        this._pageUnitGraphicsLoaded += 1;

        if (!isLoadTriggered && this._pageUnitGraphicsLoaded >= this._pageUnitGraphicsCount) {
          Log.debug('Graphic download complete');
          self._triggerDownloaded();
          isLoadTriggered = true;
        }
      });
    }
  }

  _triggerDownloaded() {
    this._pageUnitGraphicsLoading = false;
    this._eventEmitter.emit('unitImagesLoaded');
  }
}

export default UnitImageLoader;
