import { CommonKeyListeners } from './key_listeners/CommonKeyListeners';
import * as UnitKeyListeners from './key_listeners/UnitKeyListeners';
import { CustomEditor } from './EditorInstanceManager';
import { elementAllowsTextInputFromDataAttribute } from './key_listeners/keyBehaviourUtils';
import ProjectDefinitionStore from '../../../../flux/common/ProjectDefinitionStore';
import { isAlphaNumericKey, isModifierKey } from '../keyIdentifier';

export default {
  applyDefault: function (editor: CustomEditor) {
    editor.on('keydown', (e) => {
      const selectedNode = editor.selection?.getNode();
      const gateTextInputFromIndexDefAttr =
        ProjectDefinitionStore.getCurrentIndexDefinition()?.gateTextInputByAttributeAllowTextInput ?? false;

      if (
        selectedNode &&
        !elementAllowsTextInputFromDataAttribute(selectedNode) &&
        gateTextInputFromIndexDefAttr &&
        (isAlphaNumericKey(e) || isModifierKey(e))
      ) {
        e.preventDefault();
      }
      UnitKeyListeners.detectBeforeCommonKeys(editor, e);

      CommonKeyListeners.detectCommonShortcuts(editor, e);
      CommonKeyListeners.detectCmdReturnAndSaveExitKeys(editor, e);
      CommonKeyListeners.detectUnitKeyRestriction(editor, e);

      UnitKeyListeners.detectAfterCommonKeys(editor, e);
    });

    editor.on('keyup', (e) => {
      UnitKeyListeners.detectOnKeyUp(editor, e);
    });

    editor.on('PastePreProcess', (e) => {
      UnitKeyListeners.detectOnPastePreProcess(editor, e);
    });
  },

  applySingleLine: function (editor: CustomEditor) {
    editor.on('keydown', (e) => {
      CommonKeyListeners.detectUnitDeleteShortcut(editor, e);
      CommonKeyListeners.detectCommonShortcuts(editor, e);

      if (!CommonKeyListeners.detectCmdReturnAndSaveExitKeys(editor, e)) {
        return CommonKeyListeners.detectReturnAndSaveExitKey(editor, e);
      }
    });
  },

  applySingleLineNoDelete: function (editor: CustomEditor) {
    editor.on('keydown', (e) => {
      UnitKeyListeners.detectBeforeCommonKeys(editor, e);

      CommonKeyListeners.detectCommonShortcuts(editor, e);

      if (!CommonKeyListeners.detectCmdReturnAndSaveExitKeys(editor, e)) {
        if (!CommonKeyListeners.detectReturnAndSaveExitKey(editor, e)) {
          UnitKeyListeners.detectAfterCommonKeys(editor, e);
        }
      }
    });
  },

  applyBreakLine: function (editor: CustomEditor) {
    editor.on('keydown', (e) => {
      UnitKeyListeners.detectBeforeCommonKeys(editor, e);

      CommonKeyListeners.detectUnitDeleteShortcut(editor, e);
      CommonKeyListeners.detectCommonShortcuts(editor, e);

      if (!CommonKeyListeners.detectCmdReturnAndSaveExitKeys(editor, e)) {
        CommonKeyListeners.detectShiftReturnAndInsertBreakKeys(editor, e);
        UnitKeyListeners.detectAfterCommonKeys(editor, e);
      }
    });
  }
};
