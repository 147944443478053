import * as React from 'react';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import ValidatableTextInput from '../../../../../general/ValidatableTextInput';
import { DataAttributePropsType } from '../DataAttributePropsType';

type PropsType = DataAttributePropsType & {
  type: 'number';
};

export const NumberAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, isRequired, handleAction } = props;
  return (
    <div data-qa="data-attribute">
      <div data-qa="data-attribute-number">
        <ValidatableTextInput
          nullable={!isRequired(dataAttribute)}
          disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
          errorStyle={{ bottom: '2px' }}
          value={dataAttributesValues[dataAttribute.id]}
          floatingLabelText={dataAttribute.displayName}
          min={dataAttribute.min}
          max={dataAttribute.max}
          onChange={(e, text, isValid) => {
            handleAction(dataAttribute, text, isValid);
          }}
        />
      </div>
    </div>
  );
};
