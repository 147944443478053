import * as React from 'react';
import DashboardPanelView from './DashboardPanelView';

export type DashboardPanelProps = {
  style?: React.CSSProperties;
  dataQa?: string;
  children?: any;
};

const DashboardPanel = ({ children, style, dataQa }: DashboardPanelProps) => {
  return (
    <DashboardPanelView style={style} dataQa={dataQa}>
      {children}
    </DashboardPanelView>
  );
};

export default DashboardPanel;
