import * as React from 'react';
import { RaisedButton, FontIcon } from 'material-ui';

type Props = {
  onCreate: () => any;
  cid: string;
};

const SaveButton = (props: Props) => {
  const buttonStyle = {
    width: 28,
    height: 28,
    lineHeight: '28px',
    minWidth: '',
    margin: '2px 0 0 0',
    verticalAlign: 'middle'
  };

  return (
    <RaisedButton
      icon={React.createElement(FontIcon, {
        className: 'material-icons',
        children: 'done',
        style: { lineHeight: 'inherit', marginTop: '-10px' }
      })}
      id={props.cid}
      data-qa={props.cid}
      secondary={true}
      style={buttonStyle}
      onClick={() => props.onCreate()}
    />
  );
};

export default SaveButton;
