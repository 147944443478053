import * as React from 'react';
import { SharedContentListViewTypes } from './SharedContentListTypes';
import SharedContentItem from './SharedContentItem';
import RegItem from './RegItem';
import InterestItem from './InterestItem';
import { IRegulation, ISharedIndexOrigin, ISharedIndexUsages, ISpecialInterestGroup, ITag } from 'mm-types';

export type Props = {
  showExistingRegNotInList: boolean;
  loading: boolean;
  isReadOnly: boolean;
  listType: SharedContentListViewTypes;
  smartContents: (IRegulation | ISpecialInterestGroup | ISharedIndexOrigin | ISharedIndexUsages)[];
  newRegulation: IRegulation | null;
  contentTags: ITag[];
  onFormAction: (
    action: 'save' | 'cancel',
    sharedContent: Partial<IRegulation> | Partial<ISharedIndexOrigin> | Partial<ISharedIndexUsages>
  ) => void;
  onSelect: (sharedIndexUid: string) => void;
  onDropDownAction: (action: string, sharedContent: Partial<ISharedIndexUsages | IRegulation>) => void;
  onOverlayAction: (e: React.MouseEvent<HTMLElement>, action: string) => void;
  showDeleteConfirm: boolean;
  openedUid: string | null;
  selectedUid: string | null;
};

export type State = {};

const SmartContentList = (
  {
    loading = false,
    isReadOnly = false,
    listType = 'SHAREDCONTENT_ALL',
    smartContents = [],
    contentTags = [],
    newRegulation = null,
    showDeleteConfirm = false,
    openedUid = null,
    selectedUid = null,
    ...props
  }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const handleDropDownAction = (action: string, sharedContent: Partial<ISharedIndexUsages | IRegulation>) => {
    if (props.onDropDownAction) {
      props.onDropDownAction(action, sharedContent);
    }
  };

  const handleFormAction = (
    action: 'save' | 'cancel',
    sharedContent: Partial<IRegulation> | Partial<ISharedIndexOrigin> | Partial<ISharedIndexUsages>
  ) => {
    if (props.onFormAction) {
      props.onFormAction(action, sharedContent);
    }
  };

  const handleOverlayAction = (e: React.MouseEvent<HTMLElement>, action: string) => {
    if (props.onOverlayAction) {
      props.onOverlayAction(e, action);
    }
  };

  const getRegulations = () => {
    return (smartContents as IRegulation[]).sort((regA, regB) => {
      const nameA = regA.name.toUpperCase();
      const nameB = regB.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  const getSpecialInterestTags = () => {
    return smartContents;
  };

  const isType = (type: SharedContentListViewTypes) => {
    return listType === type;
  };

  return (
    <div ref={ref}>
      {!loading &&
      ((smartContents && smartContents.length > 0) ||
        openedUid === 'newShare' ||
        openedUid === 'newReg' ||
        props.showExistingRegNotInList) ? (
        <ul
          className={
            'smart-content-list' +
            (isType('SHAREDCONTENT_REGULATIONS') || isType('SHAREDCONTENT_SPECIALINTEREST') ? ' regulations-list' : '')
          }
        >
          {openedUid === 'newShare' ? (
            <SharedContentItem
              key="newShare"
              allTags={contentTags}
              open={true}
              selected={true}
              onFormAction={(action, shareContent) => handleFormAction(action, shareContent)}
            />
          ) : undefined}

          {!isType('SHAREDCONTENT_REGULATIONS') && !isType('SHAREDCONTENT_SPECIALINTEREST')
            ? smartContents.map((share: ISharedIndexOrigin | ISharedIndexUsages) => {
                return (
                  <SharedContentItem
                    key={share.uid}
                    share={share}
                    allTags={contentTags}
                    isReadOnly={isReadOnly}
                    showDeleteConfirm={openedUid === share.uid && showDeleteConfirm}
                    open={openedUid === share.uid && !showDeleteConfirm}
                    selected={selectedUid === share.uid}
                    onMenuAction={(action, smartContent) => handleDropDownAction(action, smartContent)}
                    onSelect={(id) => props.onSelect(id)}
                    onFormAction={(action, shareContent) => handleFormAction(action, shareContent)}
                    onOverlayAction={(e, action) => handleOverlayAction(e, action)}
                  />
                );
              })
            : undefined}

          {openedUid === 'newReg' && !props.showExistingRegNotInList ? (
            <RegItem
              key="newReg"
              regulation={newRegulation!}
              open={true}
              selected={true}
              onFormAction={(action, shareContent) => handleFormAction(action, shareContent)}
            />
          ) : undefined}

          {props.showExistingRegNotInList ? (
            <RegItem
              key="props.openedUid"
              regulation={newRegulation!}
              open={true}
              selected={true}
              onFormAction={(action, shareContent) => handleFormAction(action, shareContent)}
            />
          ) : undefined}

          {isType('SHAREDCONTENT_REGULATIONS')
            ? getRegulations().map((regulation) => {
                return (
                  <RegItem
                    key={regulation.uid}
                    regulation={regulation}
                    isReadOnly={isReadOnly}
                    open={openedUid === regulation.uid}
                    selected={selectedUid === regulation.uid}
                    onMenuAction={(action, smartContent) => handleDropDownAction(action, smartContent)}
                    onSelect={(id) => props.onSelect(id)}
                    onFormAction={(action, shareContent) => handleFormAction(action, shareContent)}
                  />
                );
              })
            : undefined}

          {isType('SHAREDCONTENT_SPECIALINTEREST')
            ? getSpecialInterestTags().map((specialInterestTag: ISpecialInterestGroup) => {
                return (
                  <InterestItem
                    key={specialInterestTag.uid}
                    specialInterestTag={specialInterestTag}
                    open={false}
                    selected={selectedUid === specialInterestTag.uid}
                    onSelect={(id) => props.onSelect(id)}
                  />
                );
              })
            : undefined}
        </ul>
      ) : undefined}
    </div>
  );
};

export default React.forwardRef(SmartContentList);
