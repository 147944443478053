import * as React from 'react';
import * as _ from 'lodash';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import { settingEnabled } from '../../../../../../utils/ElementSettingUtil';
import { IElementSettings } from 'mm-types';
import { BaseStyle, UnitType } from './TableDimension';

export type PropsType = {
  isWidth: boolean;
  base: BaseStyle;
  units: UnitType[];
  selectedUnit: '%' | 'px';
  type?: 'width' | 'height';
  settings?: IElementSettings[];
  handleUnitChange: (e, selectedIndex, selectedPayload) => void;
};

const TableDimensionUnit = ({ isWidth, base, type, settings, units, selectedUnit, handleUnitChange }: PropsType) => {
  if (!isWidth) {
    return <div>px</div>;
  }

  const items = units.map((item) => {
    return (
      <MenuItem
        key={item.payload}
        value={item.payload}
        primaryText={item.text}
        style={{ ...base, width: '100%' }}
        innerDivStyle={{ ...base, paddingLeft: '5px', paddingRight: '5px' }}
      />
    );
  });

  return (
    <DropDownMenu
      value={selectedUnit} // selectedUnit = state.unit
      disabled={type !== 'width' || !settingEnabled(type, settings)}
      style={{ ...base, float: 'left', marginLeft: '10px' }}
      labelStyle={{ ...base, paddingLeft: '5px' }}
      iconStyle={{ ...base, top: '0', left: '13px' }}
      menuStyle={{ width: '30px' }}
      listStyle={{ paddingTop: 0, paddingBottom: 0 }}
      onChange={(e, index, item) => handleUnitChange(e, index, item)}
      data-qa={`dimension-dropdown-${type}`}
    >
      {items}
    </DropDownMenu>
  );
};

export default TableDimensionUnit;
