import { docUnitProfile as tocable } from '../tocable';
import { IDocUnitProfile } from 'mm-types';

export const docUnitProfile: IDocUnitProfile = {
  ...tocable,
  displayName: 'Chapter',
  getLabel: () => 'CH',
  allowInsertContentElementsAfter: false,
  editor: {
    formats: { ...tocable.editor?.formats },
    forced_root_block: ''
  }
};
