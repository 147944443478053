import React from 'react';
import { FlatButton, FontIcon, CircularProgress } from 'material-ui';

import { transitionTo } from '../../utils/Navigation';

export type DashboardWidgetProps = {
  title: string;
  icon: string;
  value?: string;
  alert?: boolean;
  route?: string;
  loading?: boolean;
  dataQa?: string;
  action?: () => void;
  label?: string;
};

const ARROW = '\u2794'; // ➔ U+2794 &#10132;

const DashboardWidget = ({ icon, title, value, alert, route, dataQa, loading = false, action, label }: DashboardWidgetProps) => {
  const getAlertClass = () => (alert ? ' alert' : '');
  const isDisabled = value === '0';
  return (
    <div className="dashboard-widget" data-qa={dataQa}>
      <div data-qa="dashboard-widget-container" data-qa-disabled={isDisabled ? 'true' : 'false'} data-qa-alert={alert ? 'true' : 'false'}>
        <div className="container">
          <div className="title" data-qa="dashboard-widget-title">
            {title}
          </div>
          {loading ? (
            <CircularProgress size={40} className="loading" innerStyle={{ display: 'inline-flex' }} />
          ) : (
            <>
              <div className="value" data-qa="dashboard-widget-value">
                {value}
              </div>
              {(route || action) && (
                <FlatButton
                  label={`${label ?? 'View'} ${ARROW}`}
                  secondary={true}
                  disabled={isDisabled}
                  labelStyle={{
                    textTransform: 'none',
                    padding: '0px'
                  }}
                  onClick={() => {
                    if (route) {
                      transitionTo(route);
                    } else if (action) {
                      action();
                    }
                  }}
                  data-qa="dashboard-widget-link"
                />
              )}
            </>
          )}
        </div>
        <div className={`border${getAlertClass()}`} />
        <div className="circle">
          <div className={`border-circle${getAlertClass()}`} />
          <div className={`circle-inner${getAlertClass()}`}>
            <FontIcon className={`material-icons icon-${icon}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWidget;
