import { IProjectActivity } from 'mm-types';
import React from 'react';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const LockedStatus = ({ entry }: PropsType) => {
  entry.indexLockedStatus = entry.indexLockedStatus.replace(/_|-/g, ' ');
  return (
    <div>
      <label>Status:</label> <span>{entry.indexLockedStatus}</span>
    </div>
  );
};
