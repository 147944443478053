import { IProjectActivity } from 'mm-types';
import React from 'react';
import { dateUtil } from '../../../../../utils';
import _ from 'lodash';

type PropsType = {
  entry: IProjectActivity;
};

export const WorkflowSettings = ({ entry }: PropsType) => {
  const dom: JSX.Element[] = [];

  if (entry.user) {
    dom.push(
      <div key="user">
        <label>User:</label> <span>{entry.user.displayName}</span>
      </div>
    );
  }

  if (entry.workflowSettingsActivity) {
    if (entry.user) {
      dom.push(
        <div key="stageTitle">
          <label>Stage Title:</label> <span>{entry.workflowSettingsActivity.stageTitle}</span>
        </div>
      );
    }

    dom.push(
      <div key="changes-before">
        <label>
          <b>Before:</b>
        </label>
        <span> </span>
      </div>
    );
    dom.push(
      <div key="oldDueDate">
        <label>Due Date:</label>
        <span>{dateUtil(entry.workflowSettingsActivity.oldDueDate).formatDateTimeNoSecs()}</span>
      </div>
    );
    if (entry.workflowSettingsActivity.hasOwnProperty('oldAutoDueDate')) {
      dom.push(
        <div key="oldAutoDueDate">
          <label>Manual Due Date:</label>
          <span>{entry.workflowSettingsActivity.oldAutoDueDate ? 'no' : 'yes'}</span>
        </div>
      );
    }

    dom.push(
      <div key="changes-after">
        <label>
          <b>After:</b>
        </label>
        <span> </span>
      </div>
    );
    dom.push(
      <div key="newDueDate">
        <label>Due Date:</label>
        <span>{dateUtil(entry.workflowSettingsActivity.newDueDate).formatDateTimeNoSecs()}</span>
      </div>
    );
    if (entry.workflowSettingsActivity.hasOwnProperty('newAutoDueDate')) {
      dom.push(
        <div key="newAutoDueDate">
          <label>Manual Due Date:</label>
          <span>{entry.workflowSettingsActivity.newAutoDueDate ? 'no' : 'yes'}</span>
        </div>
      );
    }
  }

  return <div className="settings-details">{dom}</div>;
};
