import { IRevision, IVariant } from 'mm-types';
import { SelectedPdfRange, RevisionHighlightsOption } from './exportDialogTypes';

export function isSubmitDisabled(
  range: SelectedPdfRange,
  privateOptions: RevisionHighlightsOption,
  generalOptions: RevisionHighlightsOption,
  hasVariants: boolean,
  selectedVariant: string
): boolean {
  const noHighlightsOptionChecked = range === SelectedPdfRange.REVISION_HIGHLIGHTS && !privateOptions.checked && !generalOptions.checked;
  const noRangeSelected = range === SelectedPdfRange.NONE;
  const noVariantSelected = hasVariants && !getValidVariantUid(selectedVariant);
  return noRangeSelected || noHighlightsOptionChecked || noVariantSelected;
}

export const hasPdf = (revision: IRevision, selectedVariantInFileMenu: string | null): boolean => {
  return !!revision && revision.pdfMedia.length === 1 && !selectedVariantInFileMenu;
};

export const getValidVariantUid = (variant: string | null): string | null => {
  return !!variant && variant !== 'showAll' ? variant : null;
};

export const getRevision = (revisions: IRevision[] | null | undefined, documentIndexUid: string): IRevision | undefined => {
  if (!revisions || revisions.length === 0) {
    return undefined;
  }
  return revisions.find((rv: IRevision) => rv.indexUid === documentIndexUid);
};

export const getVariantUid = (variants: IVariant[], uidFromForm: string, uidFromFileMenu: string | null): string | undefined => {
  const fromFileMenu = getValidVariantUid(uidFromFileMenu);
  const fromForm = getValidVariantUid(uidFromForm);
  if (variants.length === 0) {
    return undefined;
  }
  if (fromForm) {
    return fromForm;
  }
  if (fromFileMenu) {
    return fromFileMenu;
  }
  return variants[0].uid!;
};
