import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';

interface Props {
  label: string;
  onClose();
}

export function DialogHeader({ label, onClose }: Props) {
  return (
    <div className="dialogue-header">
      <div className="close-button right" onClick={onClose}>
        <FontIcon className="material-icons icon-close" />
      </div>
      <h5>{label}</h5>
    </div>
  );
}
