import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import DropDownMenu from 'material-ui/DropDownMenu';
import EditorStore from '../../../../../../flux/editor/EditorStore';

export type Props = {
  type: 'width' | 'height' | 'maxWidth';
  title: string;
  selectUnit: '%' | 'px';
  value?: number | null;
  disabled?: boolean;
  units: UnitType[];
  onChange: ((e) => void) | null;
};

export type UnitType = {
  payload: string;
  text: string;
  min: number;
  max: number;
};

export type State = {
  value: number | '';
  unit: 'px' | '%';
};

export default class Dimension extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    title: 'Width',
    type: 'width',
    selectUnit: '%',
    disabled: false,
    value: undefined,
    units: [
      { payload: 'px', text: 'px', min: 1, max: 10000 },
      { payload: '%', text: '%', min: 1, max: 100 }
    ],
    onChange: null
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      unit: '%'
    };
  }

  _handleKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 27) {
      // return or esc

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      e.target.blur();
      EditorStore.getEditor().silentReFocus();
    } else if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      // cmd/ctrl-s

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      EditorStore.getEditor().blur();
    }
  }

  UNSAFE_componentWillMount() {
    const newValueWithBounds = this._makeValid(this.props.value); // value could be HUGE initially, need to force it into min/max bounds

    this.setState({ unit: this.props.selectUnit!, value: newValueWithBounds }, () => {
      // value supplied was out of bound for this component, so we trigger a mod to indicate change to expected
      if (this.props.value !== null && this.props.value !== undefined && newValueWithBounds !== this.props.value) {
        this._triggerChange();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const newValueWithBounds = this._makeValid(nextProps.value, nextProps.selectUnit); // value could be HUGE initially, need to force it into min/max bounds

    this.setState({ unit: nextProps.selectUnit ? nextProps.selectUnit : this.state.unit, value: newValueWithBounds }, () => {
      // value supplied was out of bound for this component, so we trigger a mod to indicate change to expected
      if (nextProps.value !== null && nextProps.value !== undefined && newValueWithBounds !== nextProps.value) {
        this._triggerChange();
      }
    });
  }

  _triggerChange() {
    this.setState({ value: this._makeValid((this.refs.dimensionText as TextField).getValue()) }, () => {
      if (this.props.onChange) {
        this.props.onChange({ value: this.state.value, unit: this.state.unit });
      }
    });
  }

  _handleUnitChange(e, selectedIndex, menuItem) {
    this.setState({ unit: menuItem }, () => {
      this._triggerChange();

      setTimeout(() => {
        (this.refs.dimensionText as any).input.focus();
      }, 150);
    });
  }

  _detectKeyDown(e) {
    if (e.keyCode === 38 || e.keyCode === 40) {
      // cursor up & dn

      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      const newVal = this._makeValid(parseInt((this.refs.dimensionText as TextField).getValue(), 10) + (e.keyCode === 38 ? 1 : -1));

      this.setState({ value: newVal }, () => {
        if (this.props.onChange) {
          this.props.onChange({ value: this.state.value, unit: this.state.unit });
        }
      });
    } else {
      // pass on to generic handler
      this._handleKeyDown(e);
    }
  }

  _makeValid(value, unit?: 'px' | '%') {
    if (value === null || value === undefined || value === '') {
      return '';
    }

    const minMax = (val, min, max) => {
      const testVal = parseFloat(val) || min;
      if (testVal < min) {
        return min;
      } else if (testVal > max) {
        return max;
      } else {
        return testVal;
      }
    };

    const { min: expectedMin, max: expectedMax } = this.props.units!.find((unitDetails) => {
      return unitDetails.payload === (unit ? unit : this.state.unit);
    })!;

    return minMax(value, expectedMin, expectedMax);
  }

  _merge(base, obj) {
    if (typeof base === 'object' && typeof obj === 'object') {
      return $.extend({}, base, obj);
    }
    return base;
  }

  render() {
    const base = { width: '30px', height: '20px', lineHeight: '20px', fontSize: '0.9em' };
    const textFieldStyle = this._merge(base, { float: 'left', width: '36.5px' });
    const dimensionValue = this.state.value === null ? '' : '' + (this.state.value ? Math.round(this.state.value) : this.state.value);
    let unit = {};

    const items = this.props.units.map((item) => {
      return <MenuItem key={item.payload} value={item.payload} primaryText={item.text} />;
    });

    if (this.props.type === 'width' || this.props.type === 'maxWidth') {
      unit = (
        <DropDownMenu
          value={this.state.unit}
          style={this._merge(base, { float: 'left', marginLeft: '10px' })}
          labelStyle={this._merge(base, { paddingLeft: '5px' })}
          iconStyle={this._merge(base, { top: '0', left: '13px' })}
          onChange={(e, index, item) => this._handleUnitChange(e, index, item)}
          disabled={this.props.disabled}
          ref="unitDropDown"
        >
          {items}
        </DropDownMenu>
      );
    } else {
      unit = <div>px</div>;
    }

    return (
      <div className={'edit-props-dimension-ctrl ' + this.props.type}>
        <h6>{this.props.title}</h6>
        <TextField
          id={'dim-text-' + this.props.type}
          value={dimensionValue}
          style={textFieldStyle}
          onChange={(e, text) => this._triggerChange()}
          onKeyDown={(e) => this._detectKeyDown(e)}
          onFocus={(e) => {
            (e.target as HTMLInputElement).select();
          }}
          disabled={this.props.disabled}
          ref="dimensionText"
        />
        {unit}
      </div>
    );
  }
}
