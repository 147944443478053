import * as React from 'react';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import UnitSharedOriginCreations from '../../../../../flux/editor/UnitSharedOriginCreations';
import SmartContentStore from '../../../../../flux/editor/SmartContentStore';
import ProjectStore from '../../../../../flux/editor/ProjectStore';
import EditorStore from '../../../../../flux/editor/EditorStore';
import IndexEventStore, { IndexEventStoreEvent } from '../../../../../flux/events/IndexEventStore';
import { FlatButton, FontIcon, MenuItem, SelectField, Toggle } from 'material-ui';
import TagField from '../../../../general/tag-field/TagField';
import ShareUsageUpdateStrategy, { ShareUsageUpdateStrategyTypes } from '../../../sharedcontentlib/ShareUsageUpdateStrategy';
import ValidatableTextInput from '../../../../general/ValidatableTextInput';
import ConfirmationOverlay from '../../../../general/ConfirmationOverlay';
import DropDownIcon from '../../../../misc/DropDownIcon';
import DropDownOption from '../../../../misc/DropDownOption';
import { EventStoreEventType, IEditorStoreEvent, ISharedIndex, ISharedIndexOrigin, ISharedIndexUsages, ITag } from 'mm-types';
import { DefaultSharedIndex } from '../../../../../clients/shared-content';
import StringUtil from '../../../../../utils/StringUtil';

const MIXED_SHARE_TYPE = 'mixed';

export type FormType = ISharedIndexOrigin | ISharedIndexUsages | DefaultSharedIndex;

export type Props = {
  share?: FormType;
  onMenuAction?: (action: string, sharedIndex: ISharedIndexUsages) => void;
  onFormAction: (action: 'save' | 'cancel', form: Partial<ISharedIndexOrigin | ISharedIndexUsages>) => void;
  onSelect?: (sharedIndexUid: string) => void;
  allTags: ITag[];
  onOverlayAction?: (e: React.MouseEvent<HTMLElement>, action: 'cancel' | 'delete') => void;
  showDeleteConfirm?: boolean;
  isReadOnly?: boolean;
  open: boolean;
  selected: boolean;
  wasDeleted?: boolean;
};

export type State = {
  formContent: FormType;
  searchTags: ITag[];
  wasDeleted?: boolean;
  errors: {
    name: null | string;
  };
};

export default class SharedContentItem extends React.Component<Props, State> {
  private editorUnsub: Function;
  private indexUnsub: Function;

  constructor(props: Props) {
    super(props);

    this.state = {
      formContent: {} as any,
      searchTags: [],
      errors: {
        name: null
      }
    };
  }

  static defaultProps: Partial<Props> = {
    share: SmartContentStore.getEmptySharedIndex(),
    allTags: [],
    showDeleteConfirm: false,
    isReadOnly: false,
    open: false,
    selected: false,
    wasDeleted: false
  };

  _getValue(e: any, selectValue: any) {
    if (e !== null && typeof e === 'object') {
      return e.target.value || e.target.value === '' ? e.target.value : selectValue;
    } else {
      return e;
    }
  }

  updateField(obj: string, name: string, e: any, index?: number, selectValue?: any) {
    const stateObj: any = {};
    stateObj[obj] = _.clone(this.state[obj]);

    if (name.indexOf('.') !== -1) {
      // allow dot notation in name

      const objPath = name.split('.');
      let stateObjPath = stateObj[obj];

      objPath.forEach((n, inx) => {
        stateObjPath = stateObjPath[n];

        if (inx === objPath.length - 2) {
          stateObjPath[objPath[inx + 1]] = this._getValue(e, selectValue);
        }
      });
    } else {
      stateObj[obj][name] = this._getValue(e, selectValue);
    }

    if (stateObj.hasOwnProperty('dirty')) {
      stateObj.dirty = true;
    }

    this.setState(stateObj);
  }

  componentDidMount() {
    this.editorUnsub = EditorStore.listen(this._onEditStoreUpdate, this);
    this.indexUnsub = IndexEventStore.listen(this._onIndexEvent, this);
  }

  componentWillUnmount() {
    this.editorUnsub();
    this.indexUnsub();
  }

  UNSAFE_componentWillMount() {
    this._createContentState();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // if being opened or close ensure it has latest from props
    if (this.props.open !== nextProps.open) {
      this._createContentState();
    } else if (this.props.share !== nextProps.share) {
      this._updateFormWithEditorChange(nextProps.share as ISharedIndexOrigin);
    }
  }

  _onIndexEvent(e: IndexEventStoreEvent) {
    if (!e.isUserMe && e.activity === 'sharedContentRemoved') {
      if ((this.props.share! as ISharedIndexOrigin | ISharedIndexUsages).uid === e.data.sharedContent.uid) {
        this.setState({ wasDeleted: true });
      }
    }
  }

  _getShareClassNames() {
    let shareColorStatus = '';
    if ((this.state.formContent as DefaultSharedIndex).origin) {
      if (this._isNewShare()) {
        shareColorStatus = ' shared-origin-unpushed';
      } else if ((this.props.share! as ISharedIndexOrigin).originDiffersFromSharedIndex) {
        shareColorStatus = ' shared-origin-unpushed';
      } else {
        shareColorStatus = (this.props.share as ISharedIndexOrigin)!.originDiffersFromLastPublished
          ? ' shared-origin-pushed-unpublished-index'
          : ' shared-origin-pushed-published-index';
      }
    } else {
      shareColorStatus = 'shared-usage';
    }

    return `smart-content-item ${this.props.open ? 'opened' : ''} ${this.props.selected ? ' selected' : ''} ` + shareColorStatus;
  }

  _createContentState() {
    let contentObj: Partial<DefaultSharedIndex> = { sharedIndex: null };
    if (this._isNewShare()) {
      contentObj = SmartContentStore.getEmptySharedIndex();
      contentObj.sharedIndex!.originProjectUid = ProjectStore.getProject()?.uid;
      contentObj.sharedIndex!.originProjectName = ProjectStore.getProject()?.name;
    } else {
      contentObj = _.cloneDeep(this.props.share) as DefaultSharedIndex;
    }

    this.setState({ formContent: contentObj as DefaultSharedIndex, errors: { name: null } });
  }

  _isNewShare() {
    return (this.props.share as DefaultSharedIndex).isNew;
  }

  _getElementsAndStatus() {
    const share = this.props.share as ISharedIndexUsages;
    let usageStatusClassName = '';
    let usageStatusMessage: string | null = null; // TODO get from enum

    if (!(this.state.formContent as ISharedIndexUsages).origin) {
      if (share.updateAvailable && !share.updateDeferred) {
        usageStatusClassName = 'share-usage-status-outdated';
        usageStatusMessage = 'OUTDATED';
      } else if (share.updateDeferred) {
        usageStatusClassName = 'share-usage-status-deferred';
        usageStatusMessage = 'DEFERRED';
      } else {
        usageStatusClassName = 'share-usage-status-uptodate';
        usageStatusMessage = 'UP-TO-DATE';
      }
    }

    return (
      <div>
        <div className={'icon-container elements icon-display-options tiny material-icons ' + usageStatusClassName}>menu</div>
        <div className="info">Elements: {this.state.formContent!.sharedIndex!.displayType!}</div>
        <div className="icon-container lock icon-display-options tiny material-icons">{this._allowRefs() ? 'lock_open' : 'lock'}</div>
        {usageStatusMessage ? <div className={'info ' + usageStatusClassName}>{usageStatusMessage}</div> : null}
      </div>
    );
  }

  _onEditStoreUpdate(e: IEditorStoreEvent<EventStoreEventType>) {
    if (e.type === 'changeModeComplete') {
      if (this.props.open && this._isNewShare() && EditorStore.isMode('SHARE_EDIT')) {
        this._updateFormWithEditorChange();
      }
    } else if (e.type === 'selectShareOrigin') {
      // i.e. a change while this is being edited in editor
      if (this.props.open) {
        this._updateFormWithEditorChange();
      }
    }
  }

  _updateFormWithEditorChange(share?: ISharedIndexOrigin) {
    if (share) {
      if (!share.startUnit || !share.endUnit) {
        return;
      }

      const formStateUpdate = {
        type: share.startUnit.uid === share.endUnit.uid ? share.startUnit.type : MIXED_SHARE_TYPE,
        startUnitUid: share.startUnit.uid,
        endUnitUid: share.endUnit.uid,
        name: StringUtil.stripHTML(share.name)
      };

      this._formContentUpdate(formStateUpdate);
    } else {
      const sharedUnitsInfo = EditorStore.getSharedUnitsSelectionInfo();
      const formStateUpdate = {
        type: sharedUnitsInfo.start.uid === sharedUnitsInfo.end.uid ? sharedUnitsInfo.start.type : MIXED_SHARE_TYPE,
        startUnitUid: sharedUnitsInfo.start.uid,
        endUnitUid: sharedUnitsInfo.end.uid,
        name: this._isNewShare()
          ? StringUtil.stripHTML(sharedUnitsInfo.defaultTitle)
          : StringUtil.stripHTML(this.state.formContent!.sharedIndex!.name)
      };

      this._formContentUpdate(formStateUpdate);
    }
  }

  _handleDropDownAction(action: string) {
    // if something is being edited: don't allow other actions occur
    if (!EditorStore.isMode('SHARE_EDIT')) {
      if (this.props.onMenuAction) {
        this.props.onMenuAction(action, this.props.share as ISharedIndexUsages);
      }
    }
  }

  _formContentUpdate(update: Partial<ISharedIndex>) {
    const formContentObj: FormType = _.cloneDeep(this.state.formContent);

    // delete old tags if new tags are being updated
    if (update.tags) {
      delete formContentObj.sharedIndex!.tags;
    }

    _.merge(formContentObj.sharedIndex, update);

    this.setState({ formContent: formContentObj });
  }

  _onToggle(e, toggle: string) {
    this._formContentUpdate({ [toggle]: !this.state.formContent!.sharedIndex![toggle] });
  }

  _allowRefs() {
    return this.state.formContent!.sharedIndex!.allowDerivatives;
  }

  _isPublic() {
    return this.state.formContent!.sharedIndex!.isPublic;
  }
  _selectShare(force?: boolean) {
    if (!this.props.showDeleteConfirm) {
      // clicking on an unopened doc will scroll to unit...(unless its forced)
      if (!this.props.open || force) {
        let firstUnitUid: string | null = null;

        if ((this.props.share as DefaultSharedIndex).origin) {
          firstUnitUid = (this.props.share as DefaultSharedIndex).sharedIndex!.startUnitUid!;
        } else {
          // usage or from newly created memory
          firstUnitUid = (this.props.share as ISharedIndexUsages)!.units
            ? (this.props.share! as ISharedIndexUsages).units[0].uid
            : UnitSharedOriginCreations.getFirstUnitUid();
        }

        if (this.props.onSelect) {
          this.props.onSelect((this.props.share as ISharedIndexOrigin | ISharedIndexUsages)!.uid);
        }
        if (firstUnitUid) {
          EditorStore.openDocumentLink(firstUnitUid);
        }
      }
    }
  }

  _getDeleteConfText() {
    const text: (string | JSX.Element)[] = [
      (this.state.formContent as ISharedIndexOrigin).origin
        ? 'Do you want to delete this share?'
        : 'Do you want to delete this share usage?'
    ];

    if (
      (this.state.formContent as ISharedIndexOrigin).origin &&
      (this.state.formContent as ISharedIndexOrigin).sharedIndex.usageCount! > 0
    ) {
      text.push(
        <FormattedMessage
          id="shared-conf-warning"
          defaultMessage={`Warning: This share has {usageCount, number} {usageCount, plural,
						  one {use}
						  other {uses}
						}.`}
          values={{ usageCount: this.state.formContent!.sharedIndex!.usageCount }}
        />
      );
    }

    return text;
  }

  _onTagChange(newTags: ITag[]) {
    this._formContentUpdate({ tags: newTags });
  }

  _onTagSearch(searchText: string) {
    const searchTags = this.props.allTags.filter((tag) => {
      return tag.value.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0;
    });

    this.setState({ searchTags: searchTags });
  }

  _save() {
    const errors = _.cloneDeep(this.state.errors);
    _.keys(errors).forEach((field) => {
      const mandatoryFieldVal = _.trim((this.state.formContent as ISharedIndexOrigin).sharedIndex![field]);
      errors[field] = mandatoryFieldVal.length === 0 ? 'Required field' : null;
    });

    if (_.values(errors).filter((e) => e !== null).length === 0) {
      this.setState({ errors: errors }, () => {
        this.props.onFormAction('save', this.state.formContent as ISharedIndexOrigin);
      });
    } else {
      this.setState({ errors: errors });
    }
  }

  render() {
    return (
      <li
        id={'itemuid_' + (this.props.share as ISharedIndexOrigin | ISharedIndexUsages)!.uid}
        className={this._getShareClassNames()}
        onClick={() => {
          this._selectShare();
        }}
      >
        {this.props.showDeleteConfirm ? (
          <ConfirmationOverlay
            text={this._getDeleteConfText() as string[]}
            confirmBtnText="Delete"
            cancelBtnText="Cancel"
            onConfirm={(e) => {
              if (this.props.onOverlayAction) {
                this.props.onOverlayAction(e, 'delete');
              }
            }}
            onCancel={(e) => {
              if (this.props.onOverlayAction) {
                this.props.onOverlayAction(e, 'cancel');
              }
            }}
          />
        ) : undefined}

        <div className="smart-content-header">
          {!this._isNewShare() && !this.props.open && !EditorStore.isReadOnly() ? (
            <DropDownIcon
              icon="more_horiz"
              className="smart-content-dropdown-menu"
              containerClassName="smart-content-dropdown-menu-container-inner"
              onSelect={(e) => {
                this._handleDropDownAction(e);
              }}
            >
              <DropDownOption show={true} value="edit">
                Edit
              </DropDownOption>
              <DropDownOption show={true} value="remove">
                Delete
              </DropDownOption>
              <DropDownOption show={true} value="openInSCL">
                Open in Content Library
              </DropDownOption>
            </DropDownIcon>
          ) : undefined}

          <div className="form-container">
            {this.props.open && !this.props.isReadOnly && this.state.formContent.origin ? (
              <ValidatableTextInput
                fullWidth={true}
                className="textfield textfield-share-name"
                defaultValue={this.state.formContent.sharedIndex!.name || ''}
                value={this.state.formContent.sharedIndex!.name}
                maxChars={256}
                multiLine={false}
                floatingLabelText=""
                floatingLabelFixed={true}
                customError={this.state.errors.name}
                onChange={(e, text) => {
                  this.updateField('formContent', 'sharedIndex.name', e);
                }}
                hintText="Shared origin title"
              />
            ) : undefined}

            {!(this.props.open && !this.props.isReadOnly) ? (
              <span
                className={(this.state.formContent.origin ? 'icon-shared-content-origen' : 'icon-shared-content-usage') + ' header-icon'}
              ></span>
            ) : undefined}
            {!(this.props.open && !this.props.isReadOnly) || !this.state.formContent.origin ? (
              <div className="textfieldro name">{this.state.formContent.sharedIndex!.name}</div>
            ) : undefined}
          </div>

          {!this.props.open ? (
            <div
              className="document-name"
              onClick={() => {
                this._selectShare(true);
              }}
            >
              {this.state.formContent.sharedIndex!.originProjectName}
            </div>
          ) : undefined}

          <div className="form-container">
            {this.props.open && !this.props.isReadOnly && this.state.formContent.origin ? (
              <ValidatableTextInput
                fullWidth={true}
                className="textfield"
                defaultValue={this.state.formContent.sharedIndex!.description || ''}
                value={this.state.formContent.sharedIndex!.description}
                maxChars={1024}
                multiLine={false}
                floatingLabelText=""
                floatingLabelFixed={true}
                onChange={(e, text) => {
                  this.updateField('formContent', 'sharedIndex.description', e);
                }}
                hintText="Shared origin description"
              />
            ) : undefined}
            {this.props.open && (this.props.isReadOnly || !this.state.formContent.origin) ? (
              <div className="textfieldro">{this.state.formContent.sharedIndex!.description}</div>
            ) : undefined}
          </div>

          {this.props.open ? (
            <div
              className="document-name"
              onClick={() => {
                this._selectShare(true);
              }}
            >
              {this.state.formContent.sharedIndex!.originProjectName}
            </div>
          ) : undefined}
        </div>

        {this._getElementsAndStatus()}

        {this.props.open ? (
          <div className="smart-content-form-body">
            <div className="smart-content-form-body-inner">
              <hr />
              {this.state.formContent.origin ? (
                <div className="form-container">
                  <div
                    onClick={(e) => {
                      this._onToggle(e, 'allowDerivatives');
                    }}
                  >
                    <Toggle
                      className={`${this._allowRefs() ? 'allow-refs' : ''}`}
                      name="editing"
                      label="Allow reference"
                      toggled={this._allowRefs()}
                      disabled={this.props.isReadOnly}
                    />
                  </div>
                </div>
              ) : undefined}

              {this.state.formContent.origin ? (
                <div className="form-container">
                  {this.state.formContent.origin ? (
                    <div
                      onClick={(e) => {
                        this._onToggle(e, 'isPublic');
                      }}
                    >
                      <Toggle
                        className={`${this._isPublic() ? 'is-public' : ''}`}
                        name="public"
                        label="Publicly available"
                        toggled={this._isPublic()}
                        disabled={this.props.isReadOnly}
                      />
                    </div>
                  ) : undefined}
                </div>
              ) : undefined}

              {!this.state.formContent.origin ? (
                <div className="form-container">
                  <div className="text">
                    {this._allowRefs() ? 'References Allowed,' : 'References Not Allowed,'}
                    {this._isPublic() ? 'Publicly Available' : 'Not Publicly Available'}
                  </div>
                </div>
              ) : undefined}

              {!this.state.formContent.origin ? (
                <div className="form-container editor-side-panel-select">
                  <SelectField
                    autoWidth={false}
                    fullWidth={true}
                    className="relationship"
                    value={(this.state.formContent as ISharedIndexUsages).updateStrategy}
                    floatingLabelText="Update strategy"
                    onChange={(e, index, text) => {
                      this.updateField('formContent', 'updateStrategy', e, index, text);
                    }}
                  >
                    {Object.keys(ShareUsageUpdateStrategy.props).map((update: ShareUsageUpdateStrategyTypes) => {
                      return (
                        <MenuItem
                          key={update}
                          value={update}
                          primaryText={ShareUsageUpdateStrategy.props[update].title}
                          disabled={'NONE' === update && !this._allowRefs()}
                        />
                      );
                    })}
                  </SelectField>
                </div>
              ) : undefined}

              {this.state.formContent.origin ? (
                <div className="form-container">
                  <div className="tags-label">Content Tags</div>
                  <TagField
                    placeholder={!this.props.isReadOnly ? 'Type to add tags' : ''}
                    allowAdd={!this.props.isReadOnly && this.props.share!.origin}
                    allowDelete={!this.props.isReadOnly && this.props.share!.origin}
                    showTags={this.state.formContent!.sharedIndex!.tags}
                    searchTags={this.state.searchTags}
                    onSearch={(text) => this._onTagSearch(text)}
                    onChange={(tags) => this._onTagChange(tags)}
                    valueField="value"
                    type="CONTENT"
                  />
                </div>
              ) : undefined}

              {!this.state.formContent.origin && this.state.formContent!.sharedIndex!.tags?.length ? (
                <div className="form-container">
                  <div className="tags-label">Content Tags</div>
                  <TagField
                    placeholder="."
                    allowAdd={false}
                    allowDelete={false}
                    showTags={this.state.formContent!.sharedIndex!.tags!}
                    valueField="value"
                    type="CONTENT"
                  />
                </div>
              ) : undefined}

              <div className="form-buttons">
                <FlatButton
                  className="action-button shared-content-cancel btn-cancel"
                  label="Cancel"
                  icon={React.createElement(FontIcon, { className: 'icon-close' })}
                  labelPosition="after"
                  secondary={true}
                  onClick={() => this.props.onFormAction('cancel', this.state.formContent as ISharedIndexOrigin | ISharedIndexUsages)}
                />
                {!this.state.wasDeleted ? (
                  <FlatButton
                    label="Save"
                    className="action-button shared-content-save btn-save"
                    icon={React.createElement(FontIcon, { className: 'icon-tick' })}
                    labelPosition="after"
                    secondary={true}
                    onClick={() => this._save()}
                  />
                ) : undefined}
              </div>
            </div>
          </div>
        ) : undefined}
      </li>
    );
  }
}
