import { ISharedSelection, createNewShareOriginUnit, canIgnoreUnit } from '../sharedUnitSelectionUtils';
import { IShareDetails } from 'mm-types';
import { DocUnitWrapper } from '../../../../../components/editor/utils/tinyFacade/DocUnitWrapper';

let selection: ISharedSelection | null;

export function onUnitDeletion(
  units: DocUnitWrapper[] | DocUnitWrapper | null,
  unitsCollection: DocUnitWrapper[],
  shareEditSelection: ISharedSelection | null
): boolean {
  if (!_deletedUnitWithinSelectionScope(units, shareEditSelection)) {
    return false;
  }
  _setSelectionReference(shareEditSelection);
  return _updateShareDetailsInsideSelection(unitsCollection);
}

export function onUnitCreation(
  units: DocUnitWrapper[] | DocUnitWrapper | null,
  unitsCollection: DocUnitWrapper[],
  shareEditSelection: ISharedSelection | null
): boolean {
  if (!_createdUnitWithinSelectionScope(units, shareEditSelection)) {
    return false;
  }
  _setSelectionReference(shareEditSelection);
  return _updateShareDetailsInsideSelection(unitsCollection);
}

export function onUndoRedo(unitsCollection: DocUnitWrapper[], shareEditSelection: ISharedSelection | null): boolean {
  // When undoRedo there is no easy way to check if change affects current selection so do calculation either way
  _setSelectionReference(shareEditSelection);
  return _updateShareDetailsInsideSelection(unitsCollection);
}

function _updateShareDetailsInsideSelection(unitsCollection: DocUnitWrapper[]): boolean {
  if (!selection) {
    return false;
  }
  let started = false;
  let ended = false;
  const shareIndexUid: string | null = selection ? selection.sharedIndexUid : null;
  const newSelectionUnits: DocUnitWrapper[] = [];

  unitsCollection.forEach((unit) => {
    if (!(started && ended) && !canIgnoreUnit(unit.unit)) {
      if (!started && _isStartUnit(unit)) {
        _setShareDetails(unit, shareIndexUid, true);
        newSelectionUnits.push(unit);
        started = true;
      }
      if (!ended && _isEndUnit(unit)) {
        _setShareDetails(unit, shareIndexUid, false, true);
        newSelectionUnits.push(unit);
        ended = true;
      }
      if (started && !ended && !_isStartUnit(unit) && !_isEndUnit(unit)) {
        _setShareDetails(unit, shareIndexUid);
        newSelectionUnits.push(unit);
      }
    }
  });
  _updateUnitsInsideSelection(newSelectionUnits);
  return true;
}

function _createdUnitWithinSelectionScope(units: DocUnitWrapper[] | DocUnitWrapper | null, selection: ISharedSelection | null): boolean {
  if (!units || !selection || !selection.visibleStart.visibleUnitIndex || !selection?.visibleEnd?.visibleUnitIndex) {
    return false;
  }
  const u = _getFirstUnit(units);
  const currentVisibleIndex = u.unit.visibleUnitIndex || 0;
  return currentVisibleIndex > selection.visibleStart.visibleUnitIndex && currentVisibleIndex < selection?.visibleEnd?.visibleUnitIndex;
}

function _deletedUnitWithinSelectionScope(units: DocUnitWrapper[] | DocUnitWrapper | null, selection: ISharedSelection | null): boolean {
  if (!units || !selection || !selection.sharedUnits) {
    return false;
  }
  const uid = _getFirstUnit(units).unit.uid;
  return !!selection.sharedUnits.find((unit) => unit.uid === uid);
}
function _getFirstUnit(units: DocUnitWrapper | DocUnitWrapper[]) {
  return Array.isArray(units) ? units[0] : units;
}

function _isStartUnit(unit: DocUnitWrapper): boolean {
  return !!selection && unit.unit.uid === selection.visibleStart.uid;
}
function _isEndUnit(unit: DocUnitWrapper): boolean {
  return !!selection && unit.unit.uid === selection?.visibleEnd?.uid;
}
function _setShareDetails(unit: DocUnitWrapper, shareIndexUid: string | null, isStartUnit = false, isEndUnit = false) {
  unit.unit.shareDetails = createNewShareOriginUnit(shareIndexUid, isStartUnit, isEndUnit) as IShareDetails;
}
function _setSelectionReference(shareEditSelection: ISharedSelection | null) {
  selection = shareEditSelection;
}
function _updateUnitsInsideSelection(units: DocUnitWrapper[]) {
  if (selection) {
    selection.sharedUnits = units.map((u) => u.unit);
  }
}
