/* FRAMEWORK */
import * as React from 'react';
/* COMPONENTS */
import ShortcutIcon from './ShortcutIcon';
/* TYPES */
import { IInsertMenuShortCutItems, IShortcutMenuItem } from 'mm-types';
import { InsertAction } from '../content/ContentMenuContainer';

interface Props {
  isDefinitionAllowed: (definitionId: string, insertPosition: InsertAction) => boolean;
  menuContents: IInsertMenuShortCutItems;
  insertPosition: InsertAction;
  disabled: boolean;
  handleInsertClick(e: any, action: any): void;
  isActivelyEditing: boolean;
}

// FIXME needs update life cycle to handle update on tab change independent of selection change
const InsertShortCuts = (props: Props) => {
  return (
    <div className="menu-insert-icon-container">
      {props.menuContents.shortCuts.map((menuItem: IShortcutMenuItem, menuItemIndex) => {
        const disabled: boolean = isDisabled(
          props.disabled,
          menuItem,
          props.isDefinitionAllowed,
          props.isActivelyEditing,
          props.insertPosition
        );
        return (
          <ShortcutIcon
            key={menuItemIndex}
            menuItem={menuItem}
            menuItemIndex={menuItemIndex}
            handleInsertClick={(e) => props.handleInsertClick(e, menuItem.action)}
            allowInsertAfterSelected={menuItem.enabled && !props.disabled}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

const isDisabled = (
  disabled: boolean,
  menuItem: IShortcutMenuItem,
  isDefinitionAllowed: (definitionId: string, insertPosition: InsertAction) => boolean,
  isActivelyEditing: boolean,
  insertPosition: InsertAction
): boolean => {
  if (disabled) {
    return true;
  } else {
    if (menuItem.doesMapToUnitType) {
      return !isDefinitionAllowed(isActivelyEditing ? menuItem.definitionId?.element! : menuItem.definitionId?.unit!, insertPosition);
    } else {
      return !menuItem.enabled;
    }
  }
};

export default React.memo(InsertShortCuts);
