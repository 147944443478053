import * as Reflux from 'reflux';
import * as client from '../../clients/project';
import Store from '../Store';
import { IApproval } from 'mm-types';

export type State = {
  approval: Partial<IApproval>;
};

export type ExternalApprovalStoreEvent = State;

export class ExternalApprovalStore extends Store<State> {
  constructor() {
    super();
  }

  // Event Handlers

  async init(projectUid: string, indexUid: string) {
    const approval = await client.getApprovals(projectUid, indexUid);
    this.trigger({ approval: approval } as ExternalApprovalStoreEvent);
    return approval;
  }

  async update(approval: Partial<IApproval>, projectUid: string, indexUid: string) {
    await client.saveApproval(projectUid, indexUid, approval);
  }
}

const singleton = Reflux.initStore<ExternalApprovalStore>(ExternalApprovalStore);
export default singleton;
