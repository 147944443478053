import * as React from 'react';
import { IProjectActivity } from 'mm-types';
type PropsType = {
  entry: IProjectActivity;
};

export const SharedIndexUsage = ({ entry }: PropsType) => {
  const sharedIndexUsageDom: JSX.Element[] = [];
  if (entry.sharedIndexUsage) {
    if (entry.sharedIndexUsage.sharedIndex) {
      if (entry.sharedIndexUsage.sharedIndex.name) {
        sharedIndexUsageDom.push(
          <div key="name">
            <label>Name:</label> <span>{entry.sharedIndexUsage.sharedIndex.name}</span>
          </div>
        );
      }
      if (entry.sharedIndexUsage.sharedIndex.description) {
        sharedIndexUsageDom.push(
          <div key="description">
            <label>Description:</label> <span>{entry.sharedIndexUsage.sharedIndex.description}</span>
          </div>
        );
      }
    }
    if (entry.indexRevisionNumber) {
      sharedIndexUsageDom.push(
        <div key="revision">
          <label>Revision Number:</label> <span>{entry.indexRevisionNumber}</span>
        </div>
      );
    }
    if (
      entry.sharedIndexUsage.updateStrategy &&
      (entry.projectActivityType === 'SHARED_INDEX_USAGE_UPDATED' || entry.projectActivityType === 'SHARED_INDEX_USAGE_CREATED')
    ) {
      if (entry.sharedIndexUsage.updateStrategy === 'MANUAL') {
        sharedIndexUsageDom.push(
          <div key="update-strategy">
            <label>Update strategy:</label> <span>Update with approval</span>
          </div>
        );
      } else if (entry.sharedIndexUsage.updateStrategy === 'AUTOMATIC') {
        sharedIndexUsageDom.push(
          <div key="update-strategy">
            <label>Update strategy:</label> <span>Update automatically</span>
          </div>
        );
      } else if (entry.sharedIndexUsage.updateStrategy === 'NONE') {
        sharedIndexUsageDom.push(
          <div key="update-strategy">
            <label>Update strategy:</label> <span>Referenced update</span>
          </div>
        );
      }
    }

    if (entry.projectActivityType === 'SHARED_INDEX_USAGE_ACTION' && entry.sharedIndexUsage.updateStrategy === 'MANUAL_REJECT') {
      sharedIndexUsageDom.push(
        <div key="update-strategy">
          <label>Update strategy:</label> <span>Referenced update</span>
        </div>
      );
    }
  }
  return <div className="settings-details">{sharedIndexUsageDom}</div>;
};
