import * as _ from 'lodash';
import * as React from 'react';
import ProjectStore from '../../../flux/editor/ProjectStore';
import MergeRevisionsStore, { MergeRevisionsStoreEvent, State as MergeRevisionsStoreState } from '../../../flux/editor/MergeRevisionsStore';
import RevisionDocument from './RevisionDocument';
import MergeActionsPanel from './MergeActionsPanel';
import Line, { defaultLine } from './Line';
import { LineType, Point } from './mergeTypes';
import InapplicableDialog from './InapplicableDialog';
import { IIndexMergeActivity } from 'mm-types';

export type Props = {
  onRequestClose: () => void;
};

export type State = {
  projectTitle?: string;
  line: LineType;
  showInapplicableDialog: boolean;
  selectedActivity: null | IIndexMergeActivity;
} & Partial<MergeRevisionsStoreState>;

export default class MergeRevisionModal extends React.Component<Props, State> {
  private _throttleFunction: any;
  private unsub: Function;

  constructor(props: Props) {
    super(props);

    this.unsub = MergeRevisionsStore.listen(this._onStoreUpdate, this);

    const initialStoreState: MergeRevisionsStoreState = MergeRevisionsStore.getInitialState();

    this.state = {
      ...initialStoreState,
      showInapplicableDialog:
        initialStoreState.selectedActivity && initialStoreState.selectedActivity.status === 'INAPPLICABLE' ? true : false,
      selectedActivity: null,
      projectTitle: ProjectStore.getProject()?.name!,
      line: defaultLine
    };
  }

  componentDidMount() {
    this._throttleFunction = _.throttle(() => {
      (this.refs.interimRevisionDoc as RevisionDocument).triggerUnitPositionCalc();
      (this.refs.draftRevisionDoc as RevisionDocument).triggerUnitPositionCalc();
    }, 300);

    window.addEventListener('resize', this._throttleFunction);
  }

  componentWillUnmount() {
    this.unsub();
    this._close(true);
    window.removeEventListener('resize', this._throttleFunction);
  }

  _onStoreUpdate(e: MergeRevisionsStoreEvent) {
    if (e.type === 'retrieveActivity') {
      this.setState({
        showInapplicableDialog: e.state.selectedActivity && e.state.selectedActivity.status === 'INAPPLICABLE' ? true : false,
        selectedActivity: e.state.selectedActivity
      });
    }
  }

  _handleComparisonAction(action: 'exit' | 'accept' | 'reject') {
    if (action === 'exit') {
      this._close();
    } else if (action === 'accept') {
      MergeRevisionsStore.merge(true);
    } else if (action === 'reject') {
      MergeRevisionsStore.merge(false);
    }
  }

  _handleUnitPositionChange(type: string, position: Point) {
    const line = {
      ...this.state.line,
      [type]: position,
      color: position.color
    };
    this.setState({ line });
  }

  _close(isSilent?: boolean) {
    MergeRevisionsStore.clear();
    if (!isSilent) {
      this.props.onRequestClose();
    }
  }

  _closeDialog() {
    this.setState({ showInapplicableDialog: false });
  }

  _showDialog() {
    this.setState({ showInapplicableDialog: true });
  }

  _hideLine() {
    return this.state.selectedActivity && this.state.selectedActivity.status === 'INAPPLICABLE';
  }

  render() {
    return (
      <div className="editor-fullpage-modal merge-container-outer">
        <nav className="page-body-header icon-header interim-merge-header">
          <div className="nav-wrapper">
            <h1>Merge Changes</h1>
            <h2>{this.state.projectTitle}</h2>
          </div>
        </nav>

        {this._hideLine() ? undefined : <Line color={this.state.line!.from.color} from={this.state.line!.from} to={this.state.line!.to} />}

        <div className="row page-inner page-inner-menu-content">
          {this.state.showInapplicableDialog ? <InapplicableDialog onClose={() => this._closeDialog()} /> : undefined}

          <RevisionDocument
            masterIndex={this.state.masterDraftIndex!}
            mergeIndex={this.state.mergingInterimIndex!}
            onShowDialog={() => this._showDialog()}
            onUnitPositionChange={(type, position) => this._handleUnitPositionChange(type, position)}
            ref="interimRevisionDoc"
          />

          <div className="revision-vertical-seperator">
            <i className="material-icons">merge_type</i>
            <div className="actions-footer"></div>
          </div>

          <RevisionDocument
            masterIndex={this.state.masterDraftIndex!}
            mergeIndex={this.state.mergingInterimIndex!}
            isMasterDraft={true}
            onAction={(action) => this._handleComparisonAction(action)}
            onUnitPositionChange={(type, position) => this._handleUnitPositionChange(type, position)}
            ref="draftRevisionDoc"
          />
          <MergeActionsPanel />
        </div>
      </div>
    );
  }
}
