import { IElementProfile } from 'mm-types';
import { MenuActions, MenuActionTitles } from '../const/docUnitProfileMenuTypes';

export function mapElementDefIndexSettingsToDocUnitProfileActions(elementDefinition: IElementProfile) {
  settingToActionMap.forEach((settingInfo) => {
    const settingToMap = elementDefinition.settings?.find((setting) => setting.id === settingInfo.settingId);
    if (settingToMap) {
      elementDefinition.menu?.[settingInfo.actionType]?.forEach((actionInfo) => {
        if (actionInfo.action === settingInfo.docUnitProfileActionId) {
          actionInfo.enabled = elementDefinition.settings?.find((setting) => setting.id === settingInfo.settingId)?.enabled ?? true;
        }
      });

      elementDefinition.menuTextSelected?.[settingInfo.actionType]?.forEach((actionInfo) => {
        if (actionInfo.action === settingInfo.docUnitProfileActionId) {
          actionInfo.enabled = elementDefinition.settings?.find((setting) => setting.id === settingInfo.settingId)?.enabled ?? true;
        }
      });
    }
  });
  return elementDefinition;
}

const settingToActionMap: Array<{ settingId: string; docUnitProfileActionId: MenuActions; actionType: MenuActionTitles }> = [
  { settingId: 'cellFontSize', docUnitProfileActionId: 'arcFontSize', actionType: 'textActions' }
];
