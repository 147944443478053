export type SharedContentListViewTypes =
  | 'SHAREDCONTENT_SOURCE'
  | 'SHAREDCONTENT_TARGET'
  | 'SHAREDCONTENT_PENDING'
  | 'SHAREDCONTENT_REGULATIONS'
  | 'SHAREDCONTENT_SPECIALINTEREST'
  | 'SHAREDCONTENT_ALL';

const properties = {
  SHAREDCONTENT_SOURCE: {
    title: 'Shared Origins',
    emptyDescription: 'No content has been shared from this document: future shared content created in this document will be listed here.'
  },

  SHAREDCONTENT_TARGET: {
    title: 'Shared Usages',
    emptyDescription:
      'No content has been inserted into this document: future inserted shared content in this document will be listed here.'
  },

  SHAREDCONTENT_PENDING: {
    title: 'Pending Origin Push',
    emptyDescription: 'No content is pending publication to the Smart Content library.'
  },

  SHAREDCONTENT_REGULATIONS: {
    title: 'Regulations',
    emptyDescription: 'There are no compliance tags in this document'
  },

  SHAREDCONTENT_SPECIALINTEREST: {
    title: 'Special Interest',
    emptyDescription: 'There are no special interest tags in this document'
  },

  SHAREDCONTENT_ALL: {
    title: 'All Shares',
    emptyDescription: 'There has been no content sharing activity in this document'
  }
};

const views = {
  props: properties
};

export default views;
