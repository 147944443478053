import * as React from 'react';
import { MaterialIcon } from '../../../general/MaterialIcon';
import { Popover } from 'material-ui';
import menuHelper from './MenuHelper';
import classNameHelper from '../../../../utils/classNameHelper';
import MenuAnimation from './MenuAnimation';

export interface MenuProps {
  className?: string;
  openKeys?: string[];
  onClick?(options: { key: string; currentTarget: Element }): any;
  onButtonClick?(event: React.MouseEvent<HTMLDivElement>): any;
  onOpenChange?(openKeys: string[]): void;
  onToggleChange?(open: boolean): void;
  triggerSubMenuAction?: 'hover' | 'click';
  subMenuOpenDelay?: number;
  subMenuCloseDelay?: number;
  title: string;
  iconBeforeText?: string;
  tabIndex?: string;
  id?: string;
  disabled?: boolean;
  ref?: string;
  style?: React.CSSProperties;
  hideDisabled?: boolean;
  materialIconName?: string;
  children?: React.ReactNode;
}

interface State {
  open: boolean;
  anchorEl: Element | undefined;
}

export default class Menu extends React.Component<MenuProps, State> {
  private buttonRef: React.RefObject<HTMLDivElement>;
  private popupRef: React.RefObject<Popover>;
  private mounted: boolean;
  id: string;

  constructor(props: MenuProps) {
    super(props);
    this.state = {
      open: false,
      anchorEl: undefined
    };
    this.onMouseClickButton = this.onMouseClickButton.bind(this);
    this.onMouseEnterButton = this.onMouseEnterButton.bind(this);
    this.onMouseOverButton = this.onMouseOverButton.bind(this);
    this.onMouseLeaveButton = this.onMouseLeaveButton.bind(this);
    this.onMouseEnterPopup = this.onMouseEnterPopup.bind(this);
    this.onMouseLeavePopup = this.onMouseLeavePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.buttonRef = React.createRef();
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.id = this.props.title;
    menuHelper.resetValues();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: Readonly<MenuProps>, prevState: Readonly<State>): void {
    if (prevState.open !== this.state.open) {
      if (this.props.onToggleChange) {
        this.props.onToggleChange(this.state.open);
      }
    }
  }

  onMouseClickButton(event: React.MouseEvent<HTMLDivElement>) {
    menuHelper.preventClosingMenu();
    event.preventDefault();
    event.stopPropagation();
    if (this.props.onButtonClick) {
      this.props.onButtonClick(event);
    }
  }

  onMouseEnterButton(event: React.MouseEvent<HTMLDivElement>) {
    if (this.isEnabled()) {
      menuHelper.openMenu(this, event.currentTarget);
    }
  }

  onMouseOverButton(event: React.MouseEvent<HTMLDivElement>) {
    if (this.isEnabled()) {
      menuHelper.openMenu(this, event.currentTarget);
    }
  }

  onMouseLeaveButton() {
    menuHelper.closeMenu();
  }

  onMouseEnterPopup() {
    menuHelper.preventClosingMenu();
  }

  onMouseLeavePopup() {
    menuHelper.closeMenu();
  }

  openPopup(el: Element | undefined) {
    this.setState({
      open: true,
      anchorEl: el
    });
  }

  closePopup() {
    if (this.mounted) {
      this.setState({
        open: false
      });
    }
  }

  triggerItemSelect(value: string, currentTarget: Element) {
    if (this.props.onClick) {
      this.props.onClick({
        key: value,
        currentTarget
      });
    }
  }

  private isEnabled() {
    return !this.props.disabled;
  }

  render() {
    const buttonClassName = classNameHelper.merge('editor-nav-menu-button', {
      active: this.state.open,
      disabled: !!this.props.disabled
    });
    return (
      <React.Fragment>
        <div
          className={buttonClassName}
          ref={this.buttonRef}
          onClick={this.onMouseClickButton}
          onMouseOver={this.onMouseOverButton}
          onMouseEnter={this.onMouseEnterButton}
          onMouseLeave={this.onMouseLeaveButton}
          data-qa="editor-menu-button"
          data-qa-hidedisabled={this.props.hideDisabled}
        >
          {this.props.iconBeforeText && (
            <MaterialIcon className={`icon-before-text ${this.props.iconBeforeText}`} iconName={this.props.materialIconName ?? ''} />
          )}
          <span className="button-label">{this.props.title}</span>
          <MaterialIcon iconName="arrow_drop_down" />
        </div>
        <Popover
          ref={this.popupRef}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.closePopup}
          useLayerForClickAway={false}
          animation={MenuAnimation}
        >
          <div onMouseEnter={this.onMouseEnterPopup} onMouseLeave={this.onMouseLeavePopup}>
            {this.props.children}
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}
