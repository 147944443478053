import { Action } from 'redux';
import { ISnackbarMessage } from 'mm-types';

export type SystemSnackbarActionTypes = ShowSystemSnackbarAction | HideSystemSnackbarAction;

export const SHOW_SYSTEM_SNACKBAR = 'ShowSystemSnackbarMessage';
export const HIDE_SYSTEM_SNACKBAR = 'HideSystemSnackbarMessage';

export interface ShowSystemSnackbarAction extends Action {
  type: typeof SHOW_SYSTEM_SNACKBAR;
  payload: ISnackbarMessage;
}

export interface HideSystemSnackbarAction extends Action {
  type: typeof HIDE_SYSTEM_SNACKBAR;
  payload: null;
}
