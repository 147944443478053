import * as React from 'react';
import { MaterialIcon } from '../general/MaterialIcon';
import { styleMap } from '../../styles/styleGuard';
import { useState } from 'react';
import { AeroCancelButton } from '../general/AeroButton';

interface Props {
  title: string;
  stack?: string;
}

const styles = styleMap({
  component: {
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    minWidth: 200
  },
  messageContainer: { maxWidth: 400 },
  message: {
    color: '#7F7F7F',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    background: '#f6f6f6',
    padding: 4,
    fontSize: 11
  },
  icon: { fontSize: 50, color: '#ca4141' }
});

const ScriptError = ({ title, stack }: Props) => {
  const [open, setOpen] = useState(false);
  if (!title.length) {
    return null;
  }
  return (
    <div className="animation-fade-in" data-qa="server-error">
      <section style={styles.component} className="animation-fade-in">
        <div>
          <div>
            <MaterialIcon iconName="report" style={styles.icon} />
          </div>
          <div style={styles.messageContainer}>
            <ul>
              <li key={title} data-qa="error-message">
                <h5>Oooops. Script error</h5>
                <h6>{title}</h6>
                {stack && (
                  <div>
                    <AeroCancelButton onClick={() => setOpen(!open)}>{open ? 'Hide' : 'Show'} details</AeroCancelButton>
                    {open && (
                      <div style={styles.message}>
                        <pre>{stack}</pre>
                      </div>
                    )}

                    <span style={{ display: 'block' }}>
                      <AeroCancelButton onClick={() => location.reload()}>Refresh page</AeroCancelButton>
                    </span>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScriptError;
