import * as React from 'react';
import * as _ from 'lodash';
import { isDisabled } from '../../../../../../utils/DataAttributesUtil';
import { DropDownMenuWithBaseStyle } from '../../../../../misc/DropDownMenuWithBaseStyle';
import { BASE_STYLE, DataAttributePropsType, ENUM_DROPDOWN_MENU_ITEM_PROPS, EnumMenuItemOptionType } from '../DataAttributePropsType';
import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui';
import { PrimaryTextWithTooltip } from '../../../../../misc/MenuItemPrimaryText/PrimaryText';

type PropsType = DataAttributePropsType & {
  enumMenuItems: EnumMenuItemOptionType[];
  type: 'enum' | 'enumAsDropdown';
};

export const DropdownAttribute = (props: PropsType) => {
  const { dataAttribute, dataAttributesValues, isRequired, handleAction, enumMenuItems, type } = props;

  if (type == 'enum') {
    return (
      <div data-qa="data-attribute">
        <div data-qa="data-attribute-enum">
          <SelectField
            id={dataAttribute.id}
            className="enumDataAttribute"
            style={{ width: '100%' }}
            disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
            value={dataAttributesValues[dataAttribute.id]}
            onChange={(e, index, value) => {
              handleAction(dataAttribute, value);
            }}
            errorText={isRequired(dataAttribute) && !dataAttributesValues[dataAttribute.id] ? 'Required Field' : undefined}
            floatingLabelText={dataAttribute.displayName}
            selectionRenderer={(value) => _.find(enumMenuItems, { value: value })?.primaryText}
          >
            {enumMenuItems.map((option, index) => (
              <MenuItem
                key={option.key}
                value={option.value}
                primaryText={
                  <PrimaryTextWithTooltip id={'enum_value_' + index} tooltip={option?.tooltip}>
                    {option.primaryText}
                  </PrimaryTextWithTooltip>
                }
                disabled={option.disabled}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }
  return (
    <div data-qa="data-attribute">
      <div data-qa="data-attribute-dropdown" className="dimension toggle">
        <h6>{dataAttribute.displayName}</h6>
        <DropDownMenuWithBaseStyle
          value={dataAttributesValues[dataAttribute.id] || ''}
          onChange={(value) => {
            handleAction(dataAttribute, value);
          }}
          disabled={dataAttribute.readOnly || isDisabled(dataAttribute.id, dataAttributesValues)}
          labelStyle={{
            paddingLeft: '0px',
            paddingRight: '3px',
            paddingTop: '3px'
          }}
          menuStyle={{ width: '25px' }}
          baseStyles={BASE_STYLE}
        >
          {enumMenuItems.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
              primaryText={option.primaryText}
              disabled={option.disabled}
              {...ENUM_DROPDOWN_MENU_ITEM_PROPS}
            />
          ))}
        </DropDownMenuWithBaseStyle>
      </div>
    </div>
  );
};
