import * as React from 'react';

export type DashboardPanelHeaderProps = {
  dataQa?: string;
  children?: any;
  style?: React.CSSProperties;
  className?: string;
};

const defaultStyles: { [prop: string]: React.CSSProperties } = {
  container: { display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', alignItems: 'center' }
};

function DashboardPanelHeader({ children, dataQa, style, className }: DashboardPanelHeaderProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={`dashboard-tile-header ${className}`} style={{ ...defaultStyles.container, ...style }} data-qa={dataQa}>
      {children}
    </div>
  );
}

export default DashboardPanelHeader;
