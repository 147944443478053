import * as React from 'react';

export type Props = {};

export type State = {};

export default class RouteNotFound extends React.Component<Props, State> {
  public displayName: 'RouteNotFound';

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="page-404">
        <h1>Sorry!</h1>
        <h2>Page not found...</h2>
        <span>
          Return to your <a href="#projects">documents?</a>
        </span>
      </div>
    );
  }
}
