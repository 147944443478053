import * as _ from 'lodash';
import { isFeatureOn } from '../../featureSwitch/featureSwitchUtils';
import { Features } from 'mm-types';

export enum VisualStatesID {
  PAGE_OUTLINE = 'PAGE_OUTLINE',
  UNIT_OUTLINE = 'UNIT_OUTLINE',
  SHOW_DELETED = 'SHOW_DELETED',
  SHOW_REVISIONBARS = 'SHOW_REVISIONBARS',
  SHOW_ELEMENT_PRINT_OUTPUT = 'SHOW_ELEMENT_PRINT_OUTPUT',
  SHOW_TASKS = 'SHOW_TASKS',
  SHOW_LAYERS = 'SHOW_LAYERS',
  HIGHLIGHT_LAYER2 = 'HIGHLIGHT_LAYER2',
  HIGHLIGHT_LAYER3 = 'HIGHLIGHT_LAYER3',
  SHOW_COMPLIANCETAGS = 'SHOW_COMPLIANCETAGS',
  SHOW_SPECIALINTERESTTAGS = 'SHOW_SPECIALINTERESTTAGS',
  SHOW_UNIT_VARIANT = 'SHOW_UNIT_VARIANT',
  SHOW_SEARCH_TAGS = 'SHOW_SEARCH_TAGS'
}

export type VisualStates = keyof typeof VisualStatesID;

interface VisualStateValue {
  title: string;
  className?: string;
  defaultOn: boolean;
  featureFlag?: keyof Features;
  subMenu?: Map<VisualStates, VisualStateValue>;
}

const STATE_MAP_LAYERS: Map<VisualStates, VisualStateValue> = new Map([
  [VisualStatesID.HIGHLIGHT_LAYER2, { title: 'Highlight Layer 2', className: 'visual-override-layer2', defaultOn: false }],
  [VisualStatesID.HIGHLIGHT_LAYER3, { title: 'Highlight Layer 3', className: 'visual-override-layer3', defaultOn: false }]
]);

const STATE_MAP_EDITOR: Map<VisualStates, VisualStateValue> = new Map([
  [VisualStatesID.PAGE_OUTLINE, { title: 'Page Outline', defaultOn: false }],
  [VisualStatesID.UNIT_OUTLINE, { title: 'Element Outline', className: 'visual-override-outline', defaultOn: false }],
  [VisualStatesID.SHOW_DELETED, { title: 'Deleted Elements', className: 'visual-override-deletedElements', defaultOn: false }],
  [VisualStatesID.SHOW_REVISIONBARS, { title: 'Revision Bars', defaultOn: true }],
  [VisualStatesID.SHOW_ELEMENT_PRINT_OUTPUT, { title: 'Element Print Output', defaultOn: false }],
  [VisualStatesID.SHOW_TASKS, { title: 'Tasks', className: 'visual-override-changetasks', defaultOn: false }],
  [VisualStatesID.SHOW_LAYERS, { title: 'Layers', className: 'visual-override-layers', defaultOn: false, subMenu: STATE_MAP_LAYERS }]
]);

const STATE_MAP_TAGS: Map<VisualStates, VisualStateValue> = new Map([
  [VisualStatesID.SHOW_COMPLIANCETAGS, { title: 'Compliance Tags', className: 'visual-override-compliancetags', defaultOn: true }],
  [
    VisualStatesID.SHOW_SPECIALINTERESTTAGS,
    { title: 'Special Interest Tags', className: 'visual-override-specialinteresttags', defaultOn: true }
  ],
  [VisualStatesID.SHOW_UNIT_VARIANT, { title: 'Variants Tags', className: 'visual-override-variants', defaultOn: true }],
  [
    VisualStatesID.SHOW_SEARCH_TAGS,
    { title: 'Search Tags', className: 'visual-override-searchtags', defaultOn: true, featureFlag: 'searchTags' }
  ]
]);

const DocumentVisualStates = {
  get keys() {
    return [...Array.from(STATE_MAP_EDITOR.keys()), null, ...Array.from(STATE_MAP_TAGS.keys())];
  },
  get props() {
    return new Map(
      [...STATE_MAP_EDITOR, ...STATE_MAP_LAYERS, ...STATE_MAP_TAGS].filter(([key, value]) =>
        value.featureFlag ? isFeatureOn(value.featureFlag) : true
      )
    );
  }
};

export default DocumentVisualStates;
