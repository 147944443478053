import React from 'react';
import { IDataAttribute } from 'mm-types';
import EditorStore from '../../../../../../flux/editor/EditorStore';
import {
  blankValueNotRequired,
  DataAttributesValues,
  findEnumValuesForParentKey,
  findParentKey,
  isEnumOptionDisabled,
  toggleValueInChecklist
} from '../../../../../../utils/DataAttributesUtil';
import { DataAttributeType, EnumMenuItemOptionType } from '../DataAttributePropsType';

type HandleDataAttributesType = {
  onValidation: (hasError: boolean, dataAttribute: IDataAttribute) => void;
  isRequired: (dataAttribute: IDataAttribute) => boolean;
  validateEnumMenuItems: (dataAttribute: IDataAttribute) => EnumMenuItemOptionType[];
  handleOnChange: (dataAttribute: IDataAttribute, newValue: string, isValid?: boolean, dataAttributeType?: DataAttributeType) => void;
};

export const useHandleDataAttributes = (
  dataAttributes: IDataAttribute[],
  dataAttributesValues: DataAttributesValues,
  handleAction: (dataAttribute: IDataAttribute, newValue: string, isValid?: boolean) => void,
  elmNid: string | null
): HandleDataAttributesType => {
  React.useEffect(() => {
    // Gather current state of data attributes validation
    if (dataAttributes) {
      dataAttributes.forEach((da) => {
        const hasError: boolean = (!da.readOnly && isRequired(da) && !dataAttributesValues[da.id]) ?? false;
        onValidation(hasError, da);
      });
    }

    return () => {};
  }, [dataAttributesValues]);

  const onValidation = (hasError: boolean, dataAttribute: IDataAttribute) => {
    let dataAttributesValidationErrors = EditorStore.getDataAttributesValidationErrors();
    if (elmNid) {
      const index = dataAttributesValidationErrors[elmNid]?.findIndex((da) => da.id === dataAttribute.id) ?? -1;

      // If error doesn't exist in dataAttributesValidationErrors but it is in the ValidatableTextInput, add it.
      if (hasError && index < 0) {
        dataAttributesValidationErrors[elmNid] = dataAttributesValidationErrors[elmNid]
          ? [...dataAttributesValidationErrors[elmNid], dataAttribute]
          : [dataAttribute];
        EditorStore.setDataAttributesValidationErrors(dataAttributesValidationErrors);
      }
      // If error exists in dataAttributesValidationErrors but it's not anymore in place, remove it
      else if (!hasError && index >= 0) {
        dataAttributesValidationErrors[elmNid].splice(index, 1);
        if (dataAttributesValidationErrors[elmNid].length === 0) {
          delete dataAttributesValidationErrors[elmNid];
        }
        EditorStore.setDataAttributesValidationErrors(dataAttributesValidationErrors);
      }
    }
  };

  const isRequired = (dataAttribute: IDataAttribute) => {
    return dataAttribute.required ?? false;
  };

  const validateEnumMenuItems = (dataAttribute: IDataAttribute) => {
    let enumValues: string[] = [],
      uiTitles: string[] | undefined = [],
      tooltips: string[] | undefined = [];

    if (dataAttribute.parent) {
      // lookup value for the parent and use it to filter out enumValues
      const parentKey = findParentKey(dataAttributes, dataAttributesValues, dataAttribute.parent);

      if (parentKey) {
        enumValues = findEnumValuesForParentKey(dataAttribute.enumValues, parentKey);
      } else {
        enumValues = [];
      }
    } else {
      // no parent for this data attribute, just list all possible options
      enumValues = dataAttribute?.enumValues;
      uiTitles = dataAttribute?.uiTitles ?? [];
      tooltips = dataAttribute?.tooltips ?? [];
    }

    if (!enumValues) {
      return [];
    }

    const isEnumValuesLengthMatching: boolean = uiTitles?.length === enumValues?.length;
    const isValuesDescriptionLengthMatching: boolean = tooltips?.length === enumValues?.length;

    const { minimumEnumIndexAllowed } = dataAttribute;
    const menuItems: EnumMenuItemOptionType[] = enumValues.map((option, index) => ({
      key: option,
      value: option,
      primaryText: !!uiTitles && isEnumValuesLengthMatching ? uiTitles[index] : option,
      disabled: isEnumOptionDisabled(dataAttribute.id, index, minimumEnumIndexAllowed ?? -1),
      tooltip: !!tooltips && isValuesDescriptionLengthMatching ? tooltips[index] : undefined
    }));

    if (!isRequired(dataAttribute) && !blankValueNotRequired(dataAttribute)) {
      menuItems.unshift({ key: 'empty', value: '', primaryText: '', disabled: false, tooltip: undefined });
    }
    return menuItems;
  };

  const handleOnChange = (dataAttribute: IDataAttribute, newValue: string, isValid?: boolean, dataAttributeType?: DataAttributeType) => {
    let value = newValue;
    switch (dataAttributeType) {
      case 'checklist':
        // update data attribute with the new checklist value
        value = toggleValueInChecklist(dataAttributesValues[dataAttribute.id], newValue, dataAttribute.enumValues);
        break;
      default:
        // edge cases for Break before and keep with next
        value = ['breakAfter', 'breakBefore'].includes(dataAttribute.id) && newValue == '' ? 'unspecified' : newValue;
        break;
    }

    handleAction(dataAttribute, value, isValid);
  };

  return { onValidation, isRequired, validateEnumMenuItems, handleOnChange };
};
