import * as React from 'react';
import { MenuItem } from 'material-ui';
import DropDownMenu from 'material-ui/DropDownMenu';
import ProjectDefinitionStore, {
  ProjectDefinitionStoreEvent,
  ProjectDefinitionStoreEventType
} from '../../../../../../flux/common/ProjectDefinitionStore';
import { IUnitDefinition } from 'mm-types';

export type Props = {
  title: string;
  listType: string;
  onChange: (e: { unitType: string }) => void;
};

export type State = {
  listTypes: {
    unitType: string;
    label: string;
  }[];
};

export default class ListType extends React.Component<Props, State> {
  private unsubscribe: Function;

  constructor(props: Props) {
    super(props);

    this.unsubscribe = ProjectDefinitionStore.listen(this._onSettingsStoreChange, this);

    const indexDefinitions = ProjectDefinitionStore.getIndexDefinitions();
    const defaultTypes: State['listTypes'] = [
      { unitType: 'unordered', label: 'Bullet List' },
      { unitType: 'ordered', label: 'Numbered List' }
    ];

    let listTypes: State['listTypes'] | null = null;
    if (indexDefinitions.length > 0) {
      listTypes = this._getListSubTypes(indexDefinitions[0].unitDefinitions!);
    }

    this.state = {
      listTypes: listTypes ? listTypes : defaultTypes
    };
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  _getListSubTypes(unitDefinitions: IUnitDefinition[]) {
    const listTypesUnits = unitDefinitions!.filter((unitDefinition) => {
      return unitDefinition.type === 'list';
    });
    return listTypesUnits.map((unit) => {
      return { unitType: unit.subType!, label: unit.title };
    });
  }

  _onSettingsStoreChange(event: ProjectDefinitionStoreEvent) {
    if (event.type === ProjectDefinitionStoreEventType.INDEX_DEFINITIONS_RETRIEVED && event.state!.indexDefinitions) {
      const listTypes = this._getListSubTypes(event.state!.indexDefinitions[0].unitDefinitions!);
      this.setState({ listTypes: listTypes! });
    }
  }

  _handleChange(listType: string) {
    this.props.onChange({ unitType: listType });
  }

  render() {
    const base = { height: '20px', lineHeight: '20px', fontSize: '0.9em' };

    return (
      <div className="dimension column list-type">
        <h6>{this.props.title}</h6>
        <DropDownMenu
          value={this.props.listType}
          style={Object.assign({}, base, { float: 'right', marginRight: '-9px' }) as React.CSSProperties}
          labelStyle={
            Object.assign({}, base, {
              paddingLeft: '0px',
              paddingRight: '65px',
              paddingTop: '3px'
            }) as React.CSSProperties
          }
          iconStyle={Object.assign({}, base, { top: '0', right: '5px' }) as React.CSSProperties}
          listStyle={{ paddingTop: 0, paddingBottom: 0 }}
          onChange={(e, index, item) => {
            this._handleChange(item);
          }}
        >
          {this.state.listTypes.map((item) => {
            return (
              <MenuItem
                key={item.unitType}
                value={item.unitType}
                primaryText={item.label}
                style={Object.assign({}, base, { width: '100%' })}
                innerDivStyle={Object.assign({}, base, { paddingLeft: '5px', paddingRight: '5px', paddingTop: '3px' })}
              />
            );
          })}
        </DropDownMenu>
      </div>
    );
  }
}
