import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardPage from './dashboard/DashboardPage';

import Root from './misc/Root';
import RouteNotFound from './misc/RouteNotFound';
import { IProject } from 'mm-types';
import EditorPage from './editor/EditorPage';
import TeamspacesPage from './projects/TeamspacesPage';
import WorkflowTasksPageContainer from './documents/kpi/WorkflowTasksPageContainer';

let path = '';
path = path && path.length ? path : '';

const DocumentSettingsPage = React.lazy(() =>
  import(/*
webpackChunkName: "document-settings-page",
webpackPrefetch: 2
*/ './documents/DocumentSettingsPage')
);
// const TeamspacesPage = React.lazy( () => import( /*
// webpackChunkName: "teamspaces-page",
// webpackPrefetch: 3
// */ './projects/TeamspacesPage' ) );
const UserSettingsPage = React.lazy(() => import(/*
webpackChunkName: "user-settings-page",
webpackPrefetch: 1
*/ './user/UserSettingsPage'));
const BulkUploadPage = React.lazy(() => import(/*
webpackChunkName: "bulk-upload-page",
webpackPrefetch: 0
*/ './bulkupload/BulkUploadPage'));
const UserProfilePage = React.lazy(() => import(/*
webpackChunkName: "my-settings-page",
webpackPrefetch: 0
*/ './mysettings/MySettingsPage'));

const WaitingRoute = () => (
  <div>
    <div style={{ height: 64, width: '100%', backgroundColor: '#05adde' }} />
    <div
      style={{
        height: 64,
        width: '100%',
        backgroundColor: '#d8f0f9',
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)'
      }}
    />
  </div>
);

function WaitingComponent<T>(Component) {
  return (props) => (
    <React.Suspense fallback={<WaitingRoute />}>
      <Component {...props} />
    </React.Suspense>
  );
}

const LazyDocumentSettingsPage = WaitingComponent(DocumentSettingsPage);
const LazyUserSettingsPage = WaitingComponent(UserSettingsPage);
const LazyUserProfilePage = WaitingComponent(UserProfilePage);
const LazyBulkUploadPage = WaitingComponent(BulkUploadPage);

export type Props = {
  project: IProject;
};

export type State = {
  loading: boolean;
};

export interface EditorRouteParams {
  documentIndexUid: string;
  projectUid: string;
  targetUnitUid?: string;
  tocableUnitUid?: string;
  targetElementNid?: string;
}

const toRet: JSX.Element = (
  <Root>
    <Switch>
      <Route path="/" exact component={DashboardPage} />
      <Route path={path + '/dashboard'} component={DashboardPage} />

      <Route path={'/teamspaces/:workspaceUid/:projectUid?/:activeTab?'} exact component={TeamspacesPage} />

      <Route path={'/workflow-tasks'} exact component={WorkflowTasksPageContainer} />
      <Route
        path={'/workflow-tasks/teamspaces/:workspaceUid'}
        exact
        render={(props) => <WorkflowTasksPageContainer {...props} isWorkspaceView={true} />}
      />
      <Route
        path={'/workflow-tasks/organisations/:organisationUid/departments/:departmentUid'}
        exact
        component={WorkflowTasksPageContainer}
      />

      {/* redirect base /teamspaces route to /teamspaces/workspace */}
      <Route path={'/teamspaces'} exact>
        <Redirect to={'/teamspaces/workspace'} />
      </Route>

      <Route
        path={'/editor/:projectUid/document/:documentIndexUid/edit/:tocableUnitUid/unit/:targetUnitUid/element/:targetElementNid'}
        component={EditorPage}
      />
      <Route path={'/editor/:projectUid/document/:documentIndexUid/edit/:tocableUnitUid/unit/:targetUnitUid'} component={EditorPage} />
      <Route path={'/editor/:projectUid/document/:documentIndexUid/edit/:targetUnitUid'} component={EditorPage} />
      <Route path={'/editor/:projectUid/document/:documentIndexUid/edit'} component={EditorPage} />

      <Route path={'/documents/:projectUid/:indexUid/configure'} component={LazyDocumentSettingsPage} />
      <Route path={'/user/:userUid/configure'} component={LazyUserSettingsPage} />
      <Route path={'/my-settings'} component={LazyUserProfilePage} />
      <Route path={'/bulk-upload'} component={LazyBulkUploadPage} />

      {/* Catch All route */}
      <Route>
        <RouteNotFound />
      </Route>
    </Switch>
  </Root>
);

export default toRet;
//            <Route name="user-preference-settings" path="preference" component={UserPreferenceSettings}/>
//            <Route name="user-profile-settings" path="profile" component={UserProfileSettings}/>
